/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'; 
import { useTranslation } from 'react-i18next'; 
import PageContent from '../../../../layouts/PageContent';  
import { Row, Col, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';   
import Survey180DegreeFeedback from './Survey180DegreeFeedback';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple'; 
import { config } from '../../../../utils/config';
import { callApi } from '../../../../utils/utils'; 
import FieldButton from '../../../../components/Controls/FieldButton/FieldButton.jsx';
import FieldDropdown from '../../../../components/Controls/FieldDropdown.jsx';
import {Steps} from 'primereact/steps'; 
import CultureSurveyFeedbackSetUp from '../CultureSurveyFeedbackSetUp.jsx';
import CultureSurveyGivingFeedback from '../CultureSurveyGivingFeedback.jsx';

const Survey180DegreeScreen = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);
  const [culture180DegreeFeedbackList, setCulture180DegreeFeedbackList] = useState([]);
  const [ClaAnio, setClaAnio] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0); 
  
  useEffect(() => {    
    
    const GetCfgPerformanceDevList = `${config.UrlApiProject}hr/Get180DegreeFeedbackList`;  
    const paramsToService = { };   
    callApi(GetCfgPerformanceDevList, 'GET', paramsToService, (response) => { 
      setCulture180DegreeFeedbackList(response.data.Anios180DegreeFeedback);
      if (response.data.Anios180DegreeFeedback.length>0){
        setClaAnio(response.data.Anios180DegreeFeedback[response.data.Anios180DegreeFeedback.length-1].Anio);
      }
    });


      
  }, []); 

  
  const onAnioChange = ( value ) => {    
     console.log(value);
     setClaAnio(value);
  }; 

  const panelHeaderTemplate = (options) => { 
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
    const className = `${options.className} ${options.collapsed ? ' collapsed-header' : ''}`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}> 
        <span className={titleClassName}> 
          <FieldDropdown 
            name="Anio"
            value={ClaAnio} 
            className="border-none background-none title info-dark-color"
            options={culture180DegreeFeedbackList} 
            onChange={(e) => onAnioChange(e.value)} 
            optionValue="Anio"
            optionLabel="Anio" 
            placeholder={t('common:SelectYear')} 
            showClear={false}
          /> 
        </span>
        <div className="p-panel-icons">
          <FieldButton className={options.togglerClassName} onClick={options.onTogglerClick}>
            <span className={toggleIcon}></span>
            <Ripple />
          </FieldButton>
        </div>
        
      </div>
    )
} 

  const onCultureSurveyFeedbackSetUpSaved = () => {
    setActiveIndex(1);
  };

const stepItems = [
  {
      label: 
  <> 
    <div className="text-center">
      <i className="fa fa-check light-green-color"></i>
    </div>
    <div> 
      {t('hr:CultureSurveySetUp')}
    </div> 
  </>  ,
      className: 'itemInicio',
      command: (event) => {  
      }
  },
  {
    label: 
  <> 
    <div className="text-center">
      <i className="fa fa-check light-green-color"></i>
    </div>
    <div> 
      {t('hr:GivingFeedback')}
    </div> 
  </>  ,
    className: '',
    command: (event) => {  
    }
},
{
  label: 
  <> 
    <div className="text-center">
      <i className="fa fa-check light-green-color"></i>
    </div>
    <div> 
      {t('hr:ReceiveFeedback')}
    </div> 
  </>  ,
  className: 'itemFinal',
  command: (event) => {  
  }
} 


  
];

const StepRender = (index) => { 
  switch(index+1) {
    case 1:
      return (<><CultureSurveyFeedbackSetUp Anio={ClaAnio} OnSubmit={onCultureSurveyFeedbackSetUpSaved}></CultureSurveyFeedbackSetUp></>)
    case 2:
      return <><CultureSurveyGivingFeedback Anio={ClaAnio}></CultureSurveyGivingFeedback></>
    case 3:
      return <><Survey180DegreeFeedback Anio={ClaAnio} ClaUsuario={null}></Survey180DegreeFeedback></>
    case 4:
        return <></>
    default:
      return <></>
  }
}

    return (
      <>  
        <PageContent title={t('hr:Survey180DegreeScreenTitle')}>  
          {
          /*
          culture180DegreeFeedbackList.map((element, index) => {  
                    return (  
                      <div key={element.Anio}>
                        <Row>
                          <Col lg={12} md={12} sm={12} xs={12}>   
                            <Panel headerTemplate={panelHeaderTemplate} header={element.Anio} className="panel-skyblue" collapsed={index!==0} toggleable>
                              <Row className="align-items-start mb-4">
                                <Col lg={12} md={12} sm={12} xs={12}>  
                                  <Survey180DegreeFeedback Anio={element.Anio} ClaUsuario={null}></Survey180DegreeFeedback>
                                </Col>  
                              </Row> 
                            </Panel> 
                          </Col> 
                        </Row> 
                        <Row>
                          <Col lg={12} md={12} sm={12} xs={12}>  
                          &nbsp;
                          </Col>
                        </Row>
                      </div>
                    );
                  })
                  */
            }  
          <div key={ClaAnio}>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>   
                <Panel headerTemplate={panelHeaderTemplate} header={ClaAnio} className="panel-skyblue" collapsed={false} toggleable>
                  <Row className="align-items-start mb-4">
                    <Col lg={12} md={12} sm={12} xs={12}>  
                      <Card> 
                        <CardBody className="">
                          <Steps
                            model={stepItems}
                            activeIndex={activeIndex} 
                            onSelect={(e) => setActiveIndex(e.index)}
                            readOnly={false}
                          />  
                        </CardBody>
                      </Card>
                      
                      <br></br>
                      { StepRender (activeIndex) }   
                    </Col>  
                  </Row> 
                </Panel> 
              </Col> 
            </Row> 
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>  
                          &nbsp;
              </Col>
            </Row>
          </div> 
        </PageContent>
      </>  
        ); 
};

export default Survey180DegreeScreen;

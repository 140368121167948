/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'; 
import { useTranslation } from 'react-i18next';  
import { Route, Switch, Redirect } from 'react-router-dom';
import AdminFooter from 'components/Footers/AdminFooter.js';
import Components  from 'externalRoutes.js'; 
import ExternalNavBar from '../components/Navbars/ExternalNavBar.jsx';
/* all the external pages must start with /r/ */
const ExternalPages = (props) => {  
   
    const { t } = useTranslation(['common']); 

    return (
      <>   
        <div className="main-content">  
          <ExternalNavBar></ExternalNavBar>
          <div>
            <Switch> 
              <Route path="/r/Survey/:CodigoUnicoEncuesta" component={Components.SurveyExternalRespondent} />  
              <Route path="/r/VisitLogMovilScreen/:ClaUbicacion" component={Components.VisitLogMovilScreen} />    
              <Route path="/r/VisitLogScreen/:ClaUbicacion" component={Components.VisitLogScreen} />
              <Route path="/r/AvailableCheckListByLocationScreen/:ClaUbicacion" component={Components.AvailableCheckListByLocationScreen} /> 
              <Route path="/layout/ResponderFormulario" component={Components.ResponderFormulario} /> 
              <Route path="/layout/DialogFormulario" component={Components.DialogFormulario} />
              <Route path="/r/MCSWNewsletter" component={Components.MCSWNewsletterScreen} /> 
              <Route path="/r/DnTNewsletter" component={Components.DnTNewsletterScreen} />
            </Switch>
          </div>
        </div> 
        <AdminFooter />  
      </>  
        ); 
};

export default ExternalPages;

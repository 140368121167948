/* eslint-disable react/button-has-type */
import React from 'react';
import './PDFViewer.scss'; 
import { useTranslation } from 'react-i18next'; 

const FIT_LABELS = {
    page: 'Original Size',
    width: 'Original Size'
};
const noop = () => {};
const nextFit = fitTo => (fitTo === 'page' ? 'width' : 'page');
 

const PDFToolbar =  ({ fitTo, onZoomToFit = noop, onZoomIn, onZoomOut, onDownloadDocument, onRotateLeft, onRotateRight, copyLink, downloadFile}) => {
    
    const { t } = useTranslation(['common']);

    return  (
      <div className="pdf-controls">
        <div className="pdf-controls-container">
          <button
            className="zoom-to-fit"
            onClick={() => onZoomToFit(nextFit(fitTo))}
            title={FIT_LABELS[nextFit(fitTo)]}
          >
            <span aria-hidden>&#9713;</span>
            <span className="sr-only">{FIT_LABELS[nextFit(fitTo)]}</span>
          </button>
            &nbsp;
          <button className="zoom-in" onClick={onZoomIn} title={t('common:ZoomIn')}>
            <span aria-hidden><i className="fa fa-search-plus"></i></span>
            <span className="sr-only">{t('common:ZoomIn')}</span>
          </button>
            &nbsp;
          <button className="zoom-out" onClick={onZoomOut} title={t('common:ZoomOut')}>
            <span aria-hidden><i className="fa fa-search-minus"></i></span>
            <span className="sr-only">{t('common:ZoomOut')}</span>
          </button>
            &nbsp;
          <button className="rotate-left" onClick={onRotateLeft} title={t('common:RotateLeft')}>
            <span aria-hidden><i className="fa fa-undo"></i></span>
            <span className="sr-only">{t('common:RotateLeft')}</span>
          </button>
            &nbsp;
          <button className="rotate-right" onClick={onRotateRight} title={t('common:RotateRight')}>
            <span aria-hidden><i className="fa fa-redo"></i></span>
            <span className="sr-only">{t('common:RotateRight')}</span>
          </button>
            &nbsp;
          <button className="Print" onClick={onDownloadDocument} title={t('common:Print')}>
            <span aria-hidden><i className="fa fa-print"></i></span>
            <span className="sr-only">{t('common:Print')}</span>
          </button>
          {copyLink !== undefined && copyLink !== null ? (
            <>
              &nbsp;
              <button className="copy-link" onClick={copyLink} title={t('common:CopyLink')}>
                <span aria-hidden><i className="fa fa-link"></i></span>
                <span className="sr-only">{t('common:CopyLink')}</span>
              </button>
            </>
            ) : <></>}
          {downloadFile !== undefined && downloadFile !== null ? (
            <>
              &nbsp;
              <button className="download-file" onClick={downloadFile} title={t('common:Download')}>
                <span aria-hidden><i className="fa fa-download"></i></span>
                <span className="sr-only">{t('common:Download')}</span>
              </button>
            </>
            ) : <></>}
        </div>
      </div>
      );

}


export default PDFToolbar;
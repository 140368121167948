/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'; 
import { useTranslation } from 'react-i18next'; 
import PageContent from '../../../../layouts/PageContent';  
import { Row, Col, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';   
import InstructionsCard from '../../../../components/Cards/InstructionsCard/InstructionsCard'; 
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../../utils/utils';   
import * as yup from 'yup';
import YupValidator from '../../../../utils/YupValidator'; 
import swal from '@sweetalert/with-react';
import SweatAlertCustomIcon from '../../../../components/SweatAlert/SweatAlertCustomIcon'
import moment from "moment";
import { Badge } from 'primereact/badge';
import Score from '../../../../components/Cards/Score/Score.jsx'
import FieldTextArea  from '../../../../components/Controls/FieldTextArea.jsx';
import FielButton from '../../../../components/Controls/FieldButton/FieldButton.jsx';
import FieldCarousel from '../../../../components/Carousel/Carousel';
import ListItem from '../../../../components/ListGroup/ListItem';
import HighlighterText from '../../../../components/Controls/HighlighterText/HighlighterText';
import FieldToogleSwitch from '../../../../components/Controls/FieldToogleSwitch/FieldToogleSwitch'; 
import { useHistory, Link } from "react-router-dom";
import { Dialog } from 'primereact/dialog'; 
import FieldDataTable from '../../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import { Column } from 'primereact/column';
import { ProgressBar } from 'primereact/progressbar';
import { idpStatus } from "../../IndividualDevelopment/IndividualDevelopmentPlanConstants.js";
import IndividualDevelopmentPlanDetailDialog from 'views/HR/IndividualDevelopment/IndividualDevelopmentPlanDetailDialog';

const ScoreCardSummaryDet = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);  
  
  const defaultAnio = props.Anio  !== undefined ? props.Anio : null;
  const defaultAnioCriteria = 2024;
  const defaultClaUsuario = props.ClaUsuario  !== undefined ? props.ClaUsuario : getSessionItem('ClaUsuarioMod', 0);;

  const usuarioConectado = getSessionItem('ClaUsuarioMod', 0);
    
  const [scoreCardSummary, setScoreCardSummary] = useState([]); 
  const [commentAvailable, setCommentAvailable] = useState(false);  
  const [esInformacionDisponible, setEsInformacionDisponible] = useState(false);
  const [estatusContribucionReview, setEstatusContribucionReview] = useState(0); 
  const [ComentariosScoreCard, setComentariosScoreCard] = useState(null);  
  const [ComentariosActionPlan, setComentariosActionPlan] = useState(null);  
  const [ComentariosProjectActivities, setComentariosProjectActivities] = useState(null);  
  const [esTienePermisoEdicion, setEsTienePermisoEdicion] = useState(0);  
  const [errors, setErrors] = useState({}); 
  const [idPerformanceDev, setIdPerformanceDev] = useState(null); 
  const [fechaIniciaAnnualReview, setFechaIniciaAnnualReview] = useState(null);
  const [EsDesactivaUpdateButton, setEsDesactivaUpdateButton] = useState(0);
  const [NombreUsuario, setNombreUsuario] = useState('');
  const [ScoreCardSummaryInfo, setScoreCardSummaryInfo] = useState({});  
  const [yesNoOptions, setYesNoOptions] = useState([`${t('common:Yes')}`, `${t('common:No')}`]);

  const [resultadosKPIs, setResultadosKPIs] = useState([]); 
  const [ActionPlansResultDet, setActionPlansResultDet] = useState([]); 
  const [ProjectActivitiesResultDet, setProjectActivitiesResultDet] = useState([]); 
  const [EsVersionSoloLectura, setEsVersionSoloLectura] = useState(false);
  const [EstatusAnnualReview, setEstatusAnnualReview] = useState(0);
  const [EsVerDetalle, setEsVerDetalle] = useState(false);
  const [DetalleScorecard, setDetalleScorecard] =  useState([]);
  const [TituloListaDetalle, setTituloListaDetalle] =  useState('');
  const [Encabezado, setEncabezado] =  useState({});
  const [IDPList, setIDPList] = useState([]); 
  const [IdIDP, setIdIDP] = useState(null); 
  const [IDPStatusName, setIDPStatusName] = useState(''); 
  const [ShowDetail, setShowDetail] = useState(false); 
    const [IDPVersion, setIDPVersion] = useState(null);
    const [ClaUsuarioOwner, setClaUsuarioOwner] = useState(null);
  const reportTypes = {
    MY_SELF: 'M',
    TEAM: 'T'
  };

  
  useEffect(() => {     
    loadEmployeeSelfReview();   
  }, [props.Anio, props.ClaUsuario]); 

  const loadEmployeeSelfReview = () => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}hr/GetSummaryScorecardUser`;  
    const paramsToService = {  
      ClaUsuario: defaultClaUsuario,
      Anio: defaultAnio
    };      

    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => { 
          
        setScoreCardSummary(response.data.ScoreCardSummary);    
        setIDPList(response.data.IDPResult);
        if (response.data.Encabezado.length>0){ 
          setCommentAvailable(response.data.Encabezado[0].EstatusEmployeeSelfReview === 1); 
          setEsInformacionDisponible(response.data.Encabezado[0].ActivaEmployeeSelfReview);
          setEstatusContribucionReview(response.data.Encabezado[0].EstatusContribucionReview);
          setIdPerformanceDev(response.data.Encabezado[0].IdPerformanceDev); 
          setFechaIniciaAnnualReview(response.data.Encabezado[0].FechaIniciaAnnualReview);
          setEsDesactivaUpdateButton(response.data.Encabezado[0].EsDesactivaUpdateButton);
          setNombreUsuario(response.data.Encabezado[0].NombreUsuario);
          setScoreCardSummaryInfo(response.data.Encabezado[0]); 
          setComentariosScoreCard(response.data.Encabezado[0].ComentariosScoreCard);
          setComentariosActionPlan(response.data.Encabezado[0].ComentariosActionPlan); 
          setComentariosProjectActivities(response.data.Encabezado[0].ComentariosProjectActivities);
          setEstatusAnnualReview(response.data.Encabezado[0].EstatusAnnualReview); 
          setResultadosKPIs(response.data.ScorecardKPIsResults);
          setActionPlansResultDet(response.data.ActionPlansResultDet);
          setProjectActivitiesResultDet(response.data.ProjectActivitiesResultDet);
          setEncabezado(response.data.Encabezado[0]);
        } 

        setEsTienePermisoEdicion (defaultClaUsuario === usuarioConectado);
        setErrors({});

        // varificamos si es solo lectura....
        if (typeof(props.ReadOnlyView) !== 'undefined')
        {
          setEsVersionSoloLectura(props.ReadOnlyView);
        } 
    });
  }


  /** FUNCIONALIDAD COMENTARIOS SCORECARD */
  const onChangeComentariosScoreCard = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.ComentariosScoreCard;   
    setErrors(newErrors) 
    setComentariosScoreCard(e.target.value)
  }

  const valitationSchemeComentariosScoreCard = yup.object().shape({
    ComentariosScoreCard: yup.string().trim().required(t('common:RequiredField')).nullable()
  }); 


  const onUpdateClickComentariosScoreCard =  async () => { 
      
    const value = {
      ComentariosScoreCard 
    }

    const validator = new YupValidator(valitationSchemeComentariosScoreCard);
    const esValido = await validator.validate(value);
     
    if (!esValido){ 
        setErrors(validator.errors); 
        return;
    }
    
    const paramsService = {
      IdPerformanceDev: idPerformanceDev,
      Anio : defaultAnio
     ,ClaUsuarioPerformanceDev : defaultClaUsuario 
     ,ComentariosScoreCard   
   };  
    

   const SaveEmployeeSelfReview = `${config.UrlApiProject}HR/UpdateComentariosScoreCard`;
 
   callApi(SaveEmployeeSelfReview, 'POST', paramsService, (response) => {  
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      //  loadEmployeeSelfReview();  
   }); 
  }
  /** ******************************************************************* */
  
  /** FUNCIONALIDAD COMENTARIOS ACTION PLAN */
  const onChangeComentariosActionPlan = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.ComentariosActionPlan;   
    setErrors(newErrors) 
    setComentariosActionPlan(e.target.value)
  }

  const valitationSchemeComentariosActionPlan = yup.object().shape({
    ComentariosActionPlan: yup.string().trim().required(t('common:RequiredField')).nullable()
  }); 


  const onUpdateClickComentariosActionPlan =  async () => { 
      
    const value = {
      ComentariosActionPlan 
    }

    const validator = new YupValidator(valitationSchemeComentariosActionPlan);
    const esValido = await validator.validate(value);
     
    if (!esValido){ 
        setErrors(validator.errors); 
        return;
    }
    
    const paramsService = {
      IdPerformanceDev: idPerformanceDev,
      Anio : defaultAnio
     ,ClaUsuarioPerformanceDev : defaultClaUsuario 
     ,ComentariosActionPlan   
   };  
    

   const SaveEmployeeSelfReview = `${config.UrlApiProject}HR/UpdateComentariosActionPlan`;
 
   callApi(SaveEmployeeSelfReview, 'POST', paramsService, (response) => {  
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      // loadEmployeeSelfReview();  
   }); 
  }
  /** ******************************************************************* */

  /** FUNCIONALIDAD COMENTARIOS PROJECT ACTIVITIES */
  const onChangeComentariosProjectActivities = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.ComentariosProjectActivities;   
    setErrors(newErrors) 
    setComentariosProjectActivities(e.target.value)
  }

  const valitationSchemeComentariosProjectActivities = yup.object().shape({
    ComentariosProjectActivities: yup.string().trim().required(t('common:RequiredField')).nullable()
  }); 

  const getSeverityClass = (severity) => {
       
    let severityClass = "";

      switch (severity) {
        case "blue":
            severityClass = "score-blue-gradient";
            break;
        case "green":
            severityClass = "score-green-gradient";
            break;
        case "yellow":
            severityClass = "score-yellow-gradient";
            break;
        case "red":
            severityClass = "score-red-gradient";
            break;        
        case "darkBlue":
          severityClass = "score-darkBlue-gradient";
          break;  
        default:
            severityClass = "score-default-gradient";
            break;
    }

    return severityClass;
  }

  const onUpdateClickComentariosProjectActivities =  async () => { 
      
    const value = {
      ComentariosProjectActivities 
    }

    const validator = new YupValidator(valitationSchemeComentariosProjectActivities);
    const esValido = await validator.validate(value);
     
    if (!esValido){ 
        setErrors(validator.errors); 
        return;
    }
    
    const paramsService = {
      IdPerformanceDev: idPerformanceDev,
      Anio : defaultAnio
     ,ClaUsuarioPerformanceDev : defaultClaUsuario 
     ,ComentariosProjectActivities   
   };  
    

   const SaveEmployeeSelfReview = `${config.UrlApiProject}HR/UpdateComentariosProjectActivities`;
 
   callApi(SaveEmployeeSelfReview, 'POST', paramsService, (response) => {  
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      //  loadEmployeeSelfReview();  
   }); 
  }
  /** ******************************************************************* */


  const onClickMarcarComoRevisado = (value) => {  
    
   const UpdateScoreCardAsReviewed = `${config.UrlApiProject}HR/UpdateEstatusContribucionReview`;
  
   const paramsService = {
      IdPerformanceDev: idPerformanceDev,
      Anio : defaultAnio
     ,ClaUsuarioPerformanceDev : defaultClaUsuario 
     ,EstatusContribucionReview: value ?  1  :  0    
  };  

   callApi(UpdateScoreCardAsReviewed, 'POST', paramsService, (response) => {  
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      loadEmployeeSelfReview();    
   }); 
  }


  const onViewDetailClick = (item) => { 
    setTituloListaDetalle(item.NomIndicador)
    setEsVerDetalle(true)

   const paramsService = { 
      Anio : defaultAnio
     ,ClaUsuario : defaultClaUsuario 
     ,ClaIndicador: item.ClaIndicador
     ,ClaTipoIndicador: item.ClaTipoIndicador
   };  

   const GetPuestoIndicadorResultados = `${config.UrlApiProject}HR/GetPuestoIndicadorResultados`;
 
   callApi(GetPuestoIndicadorResultados, 'POST', paramsService, (response) => {  
     setDetalleScorecard(response.data.PuestoIndocadorResultado);
   }); 

    
  }

  const CardScorecardResultadosTemplate = (product) => {
 console.table(product.PromedioCalificacion);
    return (
      <div className="product-item"> 
        <div className="product-item-content"> 
          <div className="product-item-header ">
            <div className="text-center">
              {
                product.ClaIndicador === null ?
                (
                  <> 
                    <div> 
                      <span className={`${getSeverityClass('blue')} p-badge pl-3 pr-3`} style={{fontSize: '12px'}}>{product.NomTipoIndocador}</span> 
                    </div> 
                  </>
                ):
                (
                  <>
                    <div className="product-item-numer"> 
                      <span className={`${getSeverityClass('blue')} p-badge pl-3 pr-3`} style={{fontSize: '12px'}}>{(product.PromedioCalificacion*100.0).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 1 })}</span>                    
                    </div>
                    <div> 
                      <small>{product.NomTipoIndocador} </small> 
                    </div> 
                  </>
)
              }  
            </div>
            <div className="pl-2">&nbsp;</div>
            <span className="product-item-title p-pl-1">{product.NomIndicador}</span>
          </div>
          <div className="product-item-description">
            {
                   typeof(product.DetalleResultado) !== 'undefined' && product.DetalleResultado.map((element, index) => {  
                      return (  
                        <div key={`item-det-${index}`} className="pb-3">
                          <ListItem 
                            title={(
                              <div>
                                <HighlighterText 
                                  searchWords={element.TextoResaltado.split('|')}
                                  textToHighlight={element.DescripcionResultado}
                                /> 
                              </div>
                                )}
                            color="#9cc1f9"
                          >
                          </ListItem> 
                        </div> 
                        
                        )
              })
            }   
            
            
          </div>
          { 
            product.ClaIndicador !== null ?
            (<div className="text-right Cursor" onClick={() => onViewDetailClick(product)}><i className="fas fa-search-plus"></i>&nbsp;View More</div>)
            :
            (<></>)
          }
          
        </div>
      </div>
    );
} 
 
const renderNumericValue = (value) => {  
  return (<>{ value === null ? '' : value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>);
}

const NomMesRender = (row) => {
  /* 1 RED
     2 YELLOW
     3 GREEN
  */
  return (
    <>
      <i className={`fas fa-flag ${ row.ColorEstatus === 1 ? "score-color-red" : row.ColorEstatus === 2 ? "score-color-yellow" : row.ColorEstatus === 3 ? "score-color-green" : "" }`}></i>
      &nbsp;{row.NomMes}
    </>
);
} 

const onViewDetailIDPClick = (item) => { 
  console.log('onViewDetailIDPClick',item)
  setIdIDP(item.IdIDP);
  setIDPStatusName(item.Estatus)
  setIDPVersion(item.Version);
  setClaUsuarioOwner(item.ClaUsuario);
  setShowDetail(true);
}


const goalTableColumnTemplate = (rowData) =>  {    
  return (
    <span
      className='Cursor text-underline info-color' 
      onClick={(e) => onViewDetailIDPClick(rowData)}
      title={rowData.ClaIDPEstatus === idpStatus.ACCEPTED? t('hr:NotParticipatingAtThisTime'): rowData.ObjetivoSmart}
    >
      {rowData.ClaIDPEstatus === idpStatus.ACCEPTED? t('hr:NotParticipatingAtThisTime'): rowData.ObjetivoSmart}        
    </span>
  );
};

const statusTableColumnTemplate = (rowData) =>  {    
  let className = '';
  if (
    (props.type === reportTypes.MY_SELF && rowData.ClaIDPEstatus === idpStatus.ADDITIONAL_INPUT_NEEDED) ||
    (props.type === reportTypes.TEAM && rowData.ClaIDPEstatus === idpStatus.PENDING_REVIEW) 
  ) {
    className = 'text-warning';
  }
  return (
    <>
      <span className={className}>
        {rowData.Estatus}
      </span>
    </>
  );
};  

const FechaRevisionTemplate = (rowData) => {
  return (
    <>
      <div hidden={rowData.FechaRevisado === null}>
        { moment(rowData.FechaRevisado).format('MM/DD/YYYY h:mm a') }
      </div> 
    </>
  );
}

const AccionesTemplate = (row) =>  {
  let className = '';
  switch(true) {
    case row.Porcentaje<50:
      className = "danger-color";
      break;
    case row.Porcentaje<75:
      className = "orange-color";
      break;
    case row.Porcentaje<99:
      className = "warning-color";
      break;
    case row.Porcentaje===100:
      className = "success-color";
      break;
    default:
      className = "default-color";
  }
  return (
    <>
      {
        row.ClaIDPEstatus !== idpStatus.ACCEPTED && (
          <>
            <div className="text-center">
              <small>
                <span>{row.AccionesTerminada}</span> &nbsp;
                <span className="bold">{t('hr:Of')}</span> &nbsp;
                <span>{row.Acciones}</span>&nbsp;
                <span className="bold">{t('hr:Activities')}</span> 
              </small>
            </div>
            <div>
              <ProgressBar className={className} value={row.Porcentaje}></ProgressBar>
            </div>
          </>
        )          
      }
    </>
  );
}
const onHideIDPDetail = () => {
  setShowDetail(false);
};

const detailIDPDialogTemplate = (
  <>
    <IndividualDevelopmentPlanDetailDialog
      isVisible={ShowDetail}       
      version={IDPVersion}        
      idIDP={IdIDP}
      iDPStatusName={IDPStatusName}
      reportType="M"
      year={defaultAnio}
      onHide={onHideIDPDetail} 
      claUsuarioOwner={ClaUsuarioOwner}      
      onSaved={null}
      onStatusSaved={null}        
    />    
  </>
);

  if(!esInformacionDisponible){
    return (
      <>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="text-center p-7">  
            <h2>{t('hr:ScoreCardPeriodClosed')}</h2> 
          </Col>
        </Row>
      </> 
    );
  }  

  return (
    <>   
      { EsVersionSoloLectura ? <></> : 
      (
        <>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>   
              <InstructionsCard>
                {
            esTienePermisoEdicion ? 
            estatusContribucionReview === 0 ? (
              <p>
                {t('hr:ScoreCardSummaryBeforeReviewed')} <b className="highlight-text"> { moment(Encabezado.FechaIniciaEmployeeSelfReview).format("LL") } - { moment(Encabezado.FechaTerminaEmployeeSelfReview).format("LL") }</b>.
              </p>
                        ) : (
                          <p><span className="highlight-text">{t('hr:ThanksPortionAnnualReviewPrework')}</span> 
                            {
                /* 
                {t('hr:ScoreCardSummaryAfterReviewed')} <span className="highlight-text"> { moment(fechaIniciaAnnualReview).format("LL") } </span>
                */
              }
                            .
                          </p>
            ) 
              
              : 
              estatusContribucionReview === 0 ? (
                <p>
                  {t('hr:ScoreCardSummaryBeforeSubmitManager')} <b className="highlight-text"> {NombreUsuario} </b>.
                </p>
              ) : <p>{t('hr:ScoreCardSummaryAfterSubmitManager')} <b className="highlight-text"> {NombreUsuario} </b>.</p> 
            }
              </InstructionsCard>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
        &nbsp;
            </Col> 
          </Row>  
        </>
      )} 
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}> 
        &nbsp;
        </Col> 
      </Row>  
      {
        defaultAnio >= defaultAnioCriteria 
        &&
        (
        <Row id="IDP">
          <Col lg={12} md={12} sm={12} xs={12}>
            <Card> 
              <CardBody className="">
                <Row className="align-items-start">
                  <Col lg={12} md={12} sm={12} xs={12} className="text-center"> 
                    <div>
                      <h1>{t('hr:IndividualDevelopmentPlan')}</h1>
                    </div> 
                  </Col> 
                </Row>
                <Row className="align-items-start h-100">   
                  <Col lg={12} md={12} sm={12} xs={12} className="h-100">  
                    <FieldDataTable 
                      value={IDPList}  
                      paginator={false}
                    >  
                      <Column body={goalTableColumnTemplate} columnKey="ObjetivoSmart" header={t('hr:Goal')} headerStyle={{ width: '200px' }} className='overflow-ellipsis'></Column> 
                      {/* <Column field="Version" columnKey="Version" header="V" headerStyle={{ width: '20px' }}></Column> */}
                      <Column body={statusTableColumnTemplate} header={t('hr:Status')} headerStyle={{ width: '90px' }}></Column>
                      <Column body={FechaRevisionTemplate} header={t('hr:FechaRevisado')} columnKey="FechaRevisado" headerStyle={{ width: '140px' }}></Column> 
                      <Column body={AccionesTemplate} style={{ textAlign: 'center' }} headerStyle={{ width: '100px' }}></Column> 
                    </FieldDataTable>
                  </Col>  
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        )
      }
      
      {detailIDPDialogTemplate}
      <Row id="ScorecardOverview">
        <Col lg={12} md={12} sm={12} xs={12}> 
          <Card className="d-block d-sm-none"> 
            <CardHeader className="light-blue">
              <CardTitle className="text-center p-2">
                <div className="p-panel-header">
                  <span className="p-panel-title p-pl-1">{t('hr:ScoreCardReview')}</span>
                  <span className="p-panel-title p-pl-1 text-right">
                    {
                      esTienePermisoEdicion && EsDesactivaUpdateButton === 0 && EsVersionSoloLectura === false && EstatusAnnualReview === 0 ?  (
                        <div>
                          {t('hr:ScorecardReviewed')}&nbsp;
                          <FieldToogleSwitch
                            id="estatusContribucionReview"
                            checked={estatusContribucionReview === 1}
                            onChange={onClickMarcarComoRevisado} 
                            optionLabels={yesNoOptions}
                          />
                        </div>
                      ) : (
                        <div>
                          {t('hr:ScorecardReviewed')}&nbsp;
                          <FieldToogleSwitch
                            id="estatusContribucionReview"
                            checked={estatusContribucionReview === 1}
                            onChange={onClickMarcarComoRevisado} 
                            optionLabels={yesNoOptions}
                            disabled
                          />
                        </div>
                      ) 
                  }  
                  </span> 
 

                </div>
              </CardTitle>
            </CardHeader>
            <CardBody>
             
              <Row className="align-items-start">
                <Col lg={6} md={6} sm={6} xs={6} className="dashed-border-right" style={{height:'100px'}}> 
                  <a href="#ScorecardDet" className="Cursor">  
                    <Score title={t('hr:ScoreCard')} range={`of ${typeof(ScoreCardSummaryInfo.PonderacionScoreCard) === 'undefined' ? '' : ScoreCardSummaryInfo.PonderacionScoreCard}`} score={ScoreCardSummaryInfo.ScoreCard} severity="blue"></Score>  
                  </a>
                  <i className="pi pi-plus primary-color font-weight-bold plus-icon-middle-right"></i>
                  <i className="pi pi-plus primary-color font-weight-bold plus-icon-center"></i>
                </Col>
                <Col lg={6} md={6} sm={6} xs={6} style={{height:'100px'}}> 
                  <a href="#ActionPlansDet" className="Cursor">  
                    <Score title={t('hr:ActionPlans')} range={`of ${typeof(ScoreCardSummaryInfo.PonderacionActionPlans) === 'undefined' ? '' : ScoreCardSummaryInfo.PonderacionActionPlans }`} score={ScoreCardSummaryInfo.ActionPlans} severity="blue"></Score>  
                  </a>
                </Col>
              </Row> 
              <Row className="align-items-start dashed-border-top">
                <Col lg={6} md={6} sm={6} xs={6} className="dashed-border-right">  
                 &nbsp;
                </Col>
                <Col lg={6} md={6} sm={6} xs={6}> 
                            
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6} sm={6} xs={6} className="dashed-border-right" style={{height:'100px'}}> 
                  <a href="#ProjectActivitiesDet" className="Cursor">  
                    <Score title={t('hr:ProjectActivities')} range={`of ${typeof(ScoreCardSummaryInfo.PonderacionProjectActivities) === 'undefined' ? '' : ScoreCardSummaryInfo.PonderacionProjectActivities }`} score={ScoreCardSummaryInfo.ProjectActivities} severity="blue"></Score> 
                  </a>
                  <i className="fas fa-equals primary-color font-weight-bold plus-icon-middle-right"></i>
                </Col>
                <Col lg={6} md={6} sm={6} xs={6} style={{height:'100px'}}> 
                  <Score title={t('hr:Points')} range="of 100" score={ScoreCardSummaryInfo.Score} severity="darkBlue"></Score> 
                </Col>
              </Row> 
            </CardBody>
          </Card>   
          <Card className="d-none d-sm-block d-md-block d-lg-block"> 
            <CardHeader className="light-blue">
              <CardTitle className="text-center p-2">
                <div className="p-panel-header">
                  <span className="p-panel-title p-pl-1">{t('hr:ScoreCardReview')}</span>
                  <span className="p-panel-title p-pl-1 text-right">
                    {
                      esTienePermisoEdicion && EsDesactivaUpdateButton === 0 && EsVersionSoloLectura === false && EstatusAnnualReview === 0 ?  (
                        <div>
                          {t('hr:ScorecardReviewed')}&nbsp;
                          <FieldToogleSwitch
                            id="estatusContribucionReview"
                            checked={estatusContribucionReview === 1}
                            onChange={onClickMarcarComoRevisado} 
                            optionLabels={yesNoOptions}
                          />
                        </div>
                      ) : (
                        <div>
                          {t('hr:ScorecardReviewed')}&nbsp;
                          <FieldToogleSwitch
                            id="estatusContribucionReview"
                            checked={estatusContribucionReview === 1}
                            onChange={onClickMarcarComoRevisado} 
                            optionLabels={yesNoOptions}
                            disabled
                          />
                        </div>
                      ) 
                  }  
                  </span> 
                </div>
              </CardTitle>
            </CardHeader>
            <CardBody>    
              <Row>
                <Col lg={3} md={3} sm={3} xs={12}> 
                  <a href="#ScorecardDet" className="Cursor">  
                    <Score title={t('hr:ScoreCard')} range={`of ${typeof(ScoreCardSummaryInfo.PonderacionScoreCard) === 'undefined' ? '' : ScoreCardSummaryInfo.PonderacionScoreCard}`} score={ScoreCardSummaryInfo.ScoreCard} severity="blue"></Score>  
                  </a>
                  <i className="pi pi-plus primary-color font-weight-bold plus-icon-middle-right"></i>
                </Col>
                <Col lg={3} md={3} sm={3} xs={12}> 
                  <a href="#ActionPlansDet" className="Cursor">
                    <Score title={t('hr:ActionPlans')} range={`of ${typeof(ScoreCardSummaryInfo.PonderacionActionPlans) === 'undefined' ? '' : ScoreCardSummaryInfo.PonderacionActionPlans }`} score={ScoreCardSummaryInfo.ActionPlans} severity="blue"></Score>  
                  </a>
                  <i className="pi pi-plus primary-color font-weight-bold plus-icon-middle-right"></i>
                </Col>  
                <Col lg={3} md={3} sm={3} xs={12}> 
                  <a href="#ProjectActivitiesDet" className="Cursor">
                    <Score title={t('hr:ProjectActivities')} range={`of ${typeof(ScoreCardSummaryInfo.PonderacionProjectActivities) === 'undefined' ? '' : ScoreCardSummaryInfo.PonderacionProjectActivities }`} score={ScoreCardSummaryInfo.ProjectActivities} severity="blue"></Score> 
                  </a>
                  <i className="fas fa-equals primary-color font-weight-bold plus-icon-middle-right"></i>
                </Col> 
                <Col lg={3} md={3} sm={3} xs={12}> 
                  <Score title={t('hr:Points')} range="of 100" score={ScoreCardSummaryInfo.Score} severity="darkBlue"></Score> 
                </Col> 
              </Row> 
               
            </CardBody>
          </Card>  
        </Col> 
      </Row>  
      <Row id="ScorecardDet" hidden={defaultAnio >= defaultAnioCriteria}>
        <Col lg={12} md={12} sm={12} xs={12}>
            &nbsp;
        </Col>
      </Row>
      <Row id="ScorecardDet" hidden={defaultAnio >= defaultAnioCriteria}>
        <Col lg={12} md={12} sm={12} xs={12}>
          <Card> 
            <CardBody className="">
              <Row className="align-items-start">
                <Col lg={12} md={12} sm={12} xs={12} className="text-center"> 
                  <div>
                    <h1>{t('hr:ScoreCard')}</h1>
                  </div> 
                </Col>
              </Row>
              <Row className="align-items-start h-100">  
              
                <Col lg={9} md={9} sm={12} xs={12} className="h-100">  
                  {
                    // primero validamos si tiene hijos para evitar pintarlo sin informacion
                    resultadosKPIs.length > 0 ? (
                      <FieldCarousel
                        value={resultadosKPIs}
                        numVisible={resultadosKPIs.length >=3 ? 3 : resultadosKPIs.length}
                        numScroll={resultadosKPIs.length >=3 ? 3 : 1} 
                        className="custom-carousel"
                        circular          
                        itemTemplate={CardScorecardResultadosTemplate}
                        header=""
                      />
                    )  :<></>
                  } 
                </Col> 
                <Col lg={3} md={3} sm={12} xs={12} className="h-100">
                  <Row className="align-items-start">
                    <Col lg={12} md={12} sm={12} xs={12} className="text-center">  
                      <div>
                        <Score 
                          title="" 
                          range={`of ${typeof(ScoreCardSummaryInfo.PonderacionScoreCard) === 'undefined' ? '' : ScoreCardSummaryInfo.PonderacionScoreCard}`} 
                          score={ScoreCardSummaryInfo.ScoreCard} 
                          severity="darkBlue"
                        >
                        </Score>  
                      </div> 
                      <div>
                        <label><b>{t('hr:Comments')}</b></label> 
                      </div> 
                      {
                      esTienePermisoEdicion && EsDesactivaUpdateButton === 0 && EsVersionSoloLectura === false && EstatusAnnualReview === 0 ? (
                        <div className="p-fluid">
                          <FieldTextArea 
                            name="ComentariosScoreCard"
                            rows={8}
                            placeholder=''
                            value={ComentariosScoreCard} 
                            onChange={onChangeComentariosScoreCard}
                            errors={errors}
                          /> 
                        </div>
                      ) : <label className="text-left">{ComentariosScoreCard}</label> 
                      } 
                      <div style={{ display: esTienePermisoEdicion && EsDesactivaUpdateButton === 0 && EsVersionSoloLectura === false && EstatusAnnualReview === 0  ? "block" : "none"}}>
                        <FielButton label={t('common:Update')} className="light-green no-border rounded-button p-3 pl-5 pr-5  font-weight-bold" onClick={onUpdateClickComentariosScoreCard} /> 
                      </div> 
                    </Col> 
                  </Row>
                  
                </Col> 
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row id="ActionPlansDet" hidden={defaultAnio >= defaultAnioCriteria}>
        <Col lg={12} md={12} sm={12} xs={12}>
            &nbsp;
        </Col>
      </Row>
      <Row id="ActionPlansDet" hidden={defaultAnio >= defaultAnioCriteria}>
        <Col lg={12} md={12} sm={12} xs={12}>
          <Card> 
            <CardBody className="">
              <Row className="align-items-start">
                <Col lg={12} md={12} sm={12} xs={12} className="text-center"> 
                  <div>
                    <h1>{t('hr:ActionPlans')}</h1>
                  </div>
                </Col>
              </Row>
              <Row className="align-items-start h-100">  
                <Col lg={9} md={9} sm={12} xs={12} className="h-100">  
                  <div className="Carousel">
                    {
                     CardScorecardResultadosTemplate({ 
                      "ClaIndicador":     null
                     ,"ClaTipoIndicador": null
                     ,"ClaveIndicador"  : null
                     ,"NomTipoIndocador" : "AP"
                     ,"NomIndicador"    : null
                     ,"NivelIndicador": `${ScoreCardSummaryInfo.ActionPlansNivel}`
                     ,"DetalleResultado": ActionPlansResultDet 
                     ,"PromedioCalificacion": null 
                   }) 
                  } 
                  </div> 
                </Col> 
                <Col lg={3} md={3} sm={12} xs={12} className="h-100">
                  <Row className="align-items-start">
                    <Col lg={12} md={12} sm={12} xs={12} className="text-center"> 
                     
                      <div>
                        <Score 
                          title=""
                          range={`of ${typeof(ScoreCardSummaryInfo.PonderacionActionPlans) === 'undefined' ? '' : ScoreCardSummaryInfo.PonderacionActionPlans}`} 
                          score={ScoreCardSummaryInfo.ActionPlans} 
                          severity="darkBlue"
                        >
                        </Score>  
                      </div> 
                      <div>
                        <label><b>{t('hr:Comments')}</b></label> 
                      </div> 
                      {
                      esTienePermisoEdicion && EsDesactivaUpdateButton === 0 && EsVersionSoloLectura === false && EstatusAnnualReview === 0  ? (
                        <div className="p-fluid">
                          <FieldTextArea 
                            name="ComentariosActionPlan"
                            rows={8}
                            placeholder=''
                            value={ComentariosActionPlan} 
                            onChange={onChangeComentariosActionPlan}
                            errors={errors}
                          /> 
                        </div>
                      ) : <label className="text-left">{ComentariosActionPlan}</label> 
                      } 
                      <div style={{ display: esTienePermisoEdicion && EsDesactivaUpdateButton === 0 && EsVersionSoloLectura === false  && EstatusAnnualReview === 0 ? "block" : "none"}}>
                        <FielButton label={t('common:Update')} className="light-green no-border rounded-button p-3 pl-5 pr-5  font-weight-bold" onClick={onUpdateClickComentariosActionPlan} /> 
                      </div> 
                    </Col> 
                  </Row>
                  
                </Col> 
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row id="ProjectActivitiesDet" hidden={defaultAnio >= defaultAnioCriteria}>
        <Col lg={12} md={12} sm={12} xs={12}>
            &nbsp;
        </Col>
      </Row>
      <Row id="ProjectActivitiesDet" hidden={defaultAnio >= defaultAnioCriteria}>
        <Col lg={12} md={12} sm={12} xs={12}>
          <Card> 
            <CardBody className="">
              <Row className="align-items-start">
                <Col lg={12} md={12} sm={12} xs={12} className="text-center"> 
                  <div>
                    <h1>{t('hr:ProjectActivities')}</h1>
                  </div>
                </Col>
              </Row>
              <Row className="align-items-start h-100">  
                <Col lg={9} md={9} sm={12} xs={12} className="h-100">  
                  <div className="Carousel">
                    {
                     CardScorecardResultadosTemplate({ 
                      "ClaIndicador":     null
                     ,"ClaTipoIndicador": null
                     ,"ClaveIndicador"  : null
                     ,"NomTipoIndocador" : "PA"
                     ,"NomIndicador"    : null
                     ,"NivelIndicador": `${ScoreCardSummaryInfo.ProjectActivitiesNivel}`
                     ,"DetalleResultado": ProjectActivitiesResultDet
                   }) 
                  } 
                  </div>
                </Col> 
                <Col lg={3} md={3} sm={12} xs={12} className="h-100">
                  <Row className="align-items-start">
                    <Col lg={12} md={12} sm={12} xs={12} className="text-center">  
                      <div>
                        <Score 
                          title=""
                          range={`of ${typeof(ScoreCardSummaryInfo.PonderacionProjectActivities) === 'undefined' ? '' : ScoreCardSummaryInfo.PonderacionProjectActivities}`} 
                          score={ScoreCardSummaryInfo.ProjectActivities} 
                          severity="darkBlue"
                        >
                        </Score>  
                      </div> 
                      <div>
                        <label><b>{t('hr:Comments')}</b></label> 
                      </div> 
                      {
                      esTienePermisoEdicion && EsDesactivaUpdateButton === 0 && EsVersionSoloLectura === false && EstatusAnnualReview === 0 ? (
                        <div className="p-fluid">
                          <FieldTextArea 
                            name="ComentariosProjectActivities"
                            rows={8}
                            placeholder=''
                            value={ComentariosProjectActivities} 
                            onChange={onChangeComentariosProjectActivities}
                            errors={errors}
                          /> 
                        </div>
                      ) : <label className="text-left">{ComentariosProjectActivities}</label> 
                      } 
                      <div style={{ display: esTienePermisoEdicion && EsDesactivaUpdateButton === 0 && EsVersionSoloLectura === false && EstatusAnnualReview === 0  ? "block" : "none"}}>
                        <FielButton label={t('common:Update')} className="light-green no-border rounded-button p-3 pl-5 pr-5  font-weight-bold" onClick={onUpdateClickComentariosProjectActivities} /> 
                      </div> 
                    </Col> 
                  </Row>
                  
                </Col> 
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>  
      <Dialog visible={EsVerDetalle} modal style={{width: '550px' }} header={t('hr:ScorecardDetail')} onHide={() => setEsVerDetalle(false)}>   
        <Row>
          <Col>
            <FieldDataTable 
              value={DetalleScorecard} 
              header={TituloListaDetalle} 
              className="p-datatable-striped"
              selectionMode="single"
            >    
              <Column field="NomMes" body={NomMesRender} header={t('hr:Mes')} columnKey="ClaUsuario" headerStyle={{ width: '70px' }}></Column> 
              <Column className="text-center" field="NomUnidadIndicador" columnKey="NomUnidadIndicador" header={t('hr:NomUnidadIndicador')} headerStyle={{ width: '60px' }}></Column>
              <Column className="text-center" body={(row) => renderNumericValue(row.EstatusPeriodo)} field="EstatusPeriodo" columnKey="EstatusPeriodo" header={t('hr:EstatusPeriodo')} headerStyle={{ width: '85px' }}></Column> 
              <Column className="text-center" body={(row) => renderNumericValue(row.ObjetivoMinimo)} field="ObjetivoMinimo" columnKey="ObjetivoMinimo" header={t('hr:ObjetivoMinimo')} headerStyle={{ width: '85px' }}></Column> 
              <Column className="text-center" body={(row) => renderNumericValue(row.ObjSatisfactorio)} field="ObjSatisfactorio" columnKey="ObjSatisfactorio" header={t('hr:ObjSatisfactorio')} headerStyle={{ width: '85px' }}></Column> 
              <Column className="text-center" body={(row) => renderNumericValue(row.ObjSobresaliente)} field="ObjSobresaliente" columnKey="ObjSobresaliente" header={t('hr:ObjSobresaliente')} headerStyle={{ width: '90px' }}></Column>  
            </FieldDataTable>
          </Col>
        </Row>
      </Dialog>  
    </>  
        ); 
};

export default ScoreCardSummaryDet;

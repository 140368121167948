import { React, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageContent from 'layouts/PageContent';
import GantryBoxLookup from './GantryBoxLookup';

const GantryBoxLookupScreen = (props) => {
  const {t, i18n} = useTranslation(['operations', 'common']);

  return (
    <PageContent title={t('operations:GantryBoxLookup')}>
      <GantryBoxLookup />
    </PageContent>
  );
}

export default GantryBoxLookupScreen;
import swal from '@sweetalert/with-react';
import InstructionsCard from 'components/Cards/InstructionsCard/InstructionsCard';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldNumber from 'components/Controls/FieldNumber';
import SweatAlertCustomIcon from 'components/SweatAlert/SweatAlertCustomIcon';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTranslation } from 'react-i18next';
import { Col, Nav, NavItem, Row, TabPane ,   NavLink, TabContent } from 'reactstrap'; 
import YupValidator from 'utils/YupValidator';
import { config } from 'utils/config';
import { callApi } from 'utils/utils';
import * as yup from 'yup';

/**
 * KPI Management by Position Relationship / KPI Composition Dialog
 * @param {*} props 
 * Props:
 * - IsVisible
 * - PuestoIndicador
 * - OnHide
 * - OnSaved
 * - OnRemoved
 * @returns 
 */
const KPIManagementByPositionCompositionDialog = (props) => {
  const {t, i18n} = useTranslation(['position', 'common']);  
  const [IndexPositionRelationship, setIndexPositionRelationship] = useState(null);
  const [KPICompositionDetailList, setKPICompositionDetailList] = useState([]);
  const [PositionRelationships, setPositionRelationships] = useState([]);
  const [PositionRelationship, setPositionRelationship] = useState(null);
  const [CompositionPositions, setCompositionPositions] = useState([]);  
  const [IndexTypes, setIndexTypes] = useState([]);  
  const [PeriodIndexes, setPeriodIndexes] = useState([]);
  const [TabActiveIndex, setTabActiveIndex] = useState(1);
  const [Errors, setErrors] = useState([]);  
  const [CurrentPeriod, setCurrentPeriod] = useState('');
  const toast = useRef(null);
  const saveValidationSchema = yup.object().shape({    
    KPICompositionList: yup.array().of(
      yup.object().shape({  
        ClaPuestoComposicion: yup.number().required(t('common:RequiredField')).nullable(),
        ClaTipoIndicadorComposicion: yup.number().required(t('common:RequiredField')).nullable(),
        ClaIndicadorComposicion: yup.number().required(t('common:RequiredField')).nullable(),        
        // Orden: yup.number().required(t('common:RequiredField')).nullable()       
      })
    )
  });

  // #region Methods 
  
  const getKPIManagementByPositionComposition = (    
    claDepartamento,
    claArea,
    claTipoIndicador,
    claIndicador,
    claPuesto,
    claPeriodo    
  ) => {
    const serviceURL = `${config.UrlApiProject}ScoreCard/GetKPIManagementByPositionComposition`;
    const serviceParameters = {
      ClaDepartamento: claDepartamento,
      ClaArea: claArea,
      ClaTipoIndicador: claTipoIndicador,
      ClaIndicador: claIndicador,
      ClaPuesto: claPuesto,
      ClaPeriodo: claPeriodo  
    };    
    callApi(serviceURL, 'POST', serviceParameters, (response) => {      
      console.log("Index Position Relationship", response.data.IndexPositionRelationship);          
      console.log("KPI Composition detail", response.data.KPICompositionDetail);          
      // Set Index position relationship
      setIndexPositionRelationship(response.data.IndexPositionRelationship);   
      setCurrentPeriod(response.data.IndexPositionRelationship.CurrentPeriodInfo)   
      // Set Follow up by Position Relationship
      if (response.data.IndexPositionRelationship) {
        setPositionRelationship(response.data.IndexPositionRelationship.ClaSeguimientoKPIPosicionRelacionada);
      }
      // Set KPI composition detail
      setKPICompositionDetailList(response.data.KPICompositionDetail);       
      
      // si tiene configurado detalle lo dejamos en ese tab por defaul, no se puede tener configruado ambos
      // asi que cuando se seleeciona por position relacionada se dan de baja los valores de KPI composition.
      if (response.data.KPICompositionDetail.length > 0){ 
        setTabActiveIndex(2);
      }
    }, (error) => {
      console.error('Error getting KPI Composition detail', error);
    });
  };

  const getFollowUpKPIPositionRelationships = () => {
    const serviceURL = `${config.UrlApiProject}ScoreCard/GetFollowUpKPIPositionRelationshipCmb`;
    const serviceParameters = {};
    callApi(serviceURL, 'GET', serviceParameters, (response) => {
      console.log("Position Relationships", response.data.Positions);
      setPositionRelationships(response.data.Positions);
    }, (error) => {
      console.error('Error getting follow up KPI position relationship list', error);
    });
  };

  const getCompositionPositions = () => {
    const serviceURL = `${config.UrlApiProject}ScoreCard/GetCompositionPositionsCmb`;
    const serviceParameters = {};
    callApi(serviceURL, 'GET', serviceParameters, (response) => {      
      console.log("Composition Positions", response.data.CompositionPositions);
      setCompositionPositions(response.data.CompositionPositions);
    }, (error) => {
      console.error('Error getting composition positions list', error);
    });
  };

  const getIndexTypes = (claPuesto) => {
    const showLoading = true;
    const serviceURL = `${config.UrlApiProject}ScoreCard/GetIndexTypesCmb`;
    const serviceParameters = {};
    callApi(serviceURL, 'GET', serviceParameters, (response) => {      
      console.log("Index Types", response.data.IndexTypes);
      setIndexTypes(response.data.IndexTypes);
    }, (error) => {
      console.error('Error getting Index Types list', error);
    }, false, showLoading);
  };

  const getPeriodIndexes = (claPeriodo, claPuesto, claTipoIndicador) => {       
    const showLoading = true; 
    const serviceURL = `${config.UrlApiProject}ScoreCard/GetPeriodIndexesCmb?claPeriodo=${claPeriodo}&claPuesto=${claPuesto}&claTipoIndicador=${claTipoIndicador}`;
    const serviceParameters = {};
    callApi(serviceURL, 'GET', serviceParameters, (response) => {      
      console.log("Period Indexes", response.data.PeriodIndexes);
      setPeriodIndexes(response.data.PeriodIndexes);
    }, (error) => {
      console.error('Error getting Period Indexes list', error);
    }, false, showLoading);
  };

  const validateData = async (data) => {
    const validator = new YupValidator(saveValidationSchema);
    const isValid = await validator.validate(data);
    if (!isValid) {
      console.warn("Data not valid", validator.errors);
      setErrors(validator.errors);       
    }
    return isValid;
  };

  const save = async (
    claPeriodo, 
    claPuesto, 
    claTipoIndicador, 
    claIndicador,
    claSeguimientoKPIPosicionRelacionada,
    kPICompositionList
  ) => {        
    const serviceParameters = {
      ClaPeriodo: claPeriodo, 
      ClaPuesto: claPuesto, 
      ClaTipoIndicador: claTipoIndicador, 
      ClaIndicador: claIndicador,
      ClaSeguimientoKPIPosicionRelacionada: claSeguimientoKPIPosicionRelacionada,
      KPICompositionList: kPICompositionList,
      TabSeleccionado: TabActiveIndex
    };
    
    const isValid = await validateData(serviceParameters);

    if (!isValid) {
      console.warn("Data not valid");      
      return;
    }

    const serviceURL = `${config.UrlApiProject}ScoreCard/SaveKPIManagementByPositionComposition`;
    callApi(serviceURL, 'POST', serviceParameters, (response) => {      
      console.log("KPI Management by Position/Composition saved", response);  
      toast.current.show({
        severity:'success', 
        summary: '', 
        detail: t('position:KPIManagementPositionCompositionSaved'), 
        life: 3000
      });    
      getKPIManagementByPositionComposition(      
        props.PuestoIndicador.ClaDepartamento,
        props.PuestoIndicador.ClaArea,
        props.PuestoIndicador.ClaTipoIndicador,
        props.PuestoIndicador.ClaIndicador,
        props.PuestoIndicador.ClaPuesto,
        props.PuestoIndicador.ClaPeriodo
      );
      if (props.OnSaved) props.OnSaved();
    }, (error) => {
      console.error('Error saving KPI Management by Position/Composition', error);
    });    
  };

  const confirmRemoval = (rowIndex) => {
    swal({ 
      buttons: {
        cancelar: {
          text: t('common:Cancel'),
          value: "cancel",
          className: "rounded-button danger"
        },
        continuar: {
          text: t('common:Continue'),
          value: "continue",
          className: "rounded-button light-green"
        }
      },
      content: (
        <Row>
          <Col>
            <SweatAlertCustomIcon 
              title={t('common:DeleteConfirmation')} 
              letter={t('common:QuestionMark')}
            />
            <p>
              {t('position:WantToRemoveKPIComposition')}
            </p>           
          </Col>
        </Row>        
      )
    }).then((value) => {
      switch (value) { 
        case "continue":   
          remove(
            KPICompositionDetailList[rowIndex].ClaPeriodo,
            KPICompositionDetailList[rowIndex].ClaPuesto,
            KPICompositionDetailList[rowIndex].ClaTipoIndicador,
            KPICompositionDetailList[rowIndex].ClaIndicador,
            KPICompositionDetailList[rowIndex].ClaPuestoComposicion,
            KPICompositionDetailList[rowIndex].ClaTipoIndicadorComposicion,
            KPICompositionDetailList[rowIndex].ClaIndicadorComposicion,
            rowIndex
          );       
          break; 
        default:
          break;
      }
    });
  };

  const remove = (
    claPeriodo, 
    claPuesto, 
    claTipoIndicador, 
    claIndicador,
    claPuestoComposicion,
    claTipoIndicadorComposicion,
    claIndicadorComposicion,
    localRowIndex
  ) => {      
    const serviceURL = `${config.UrlApiProject}ScoreCard/RemoveKPIComposition`;
    const serviceParameters = {
      ClaPeriodo: claPeriodo,
      ClaPuesto: claPuesto,
      ClaTipoIndicador: claTipoIndicador,
      ClaIndicador: claIndicador,
      ClaPuestoComposicion: claPuestoComposicion,
      ClaTipoIndicadorComposicion: claTipoIndicadorComposicion,
      ClaIndicadorComposicion: claIndicadorComposicion,
    };
    callApi(serviceURL, 'POST', serviceParameters, (response) => {      
      console.log("KPI composition removed", response);
      const updateKPICompositionDetailList = [...KPICompositionDetailList];
      updateKPICompositionDetailList.splice(localRowIndex, 1);
      setKPICompositionDetailList(updateKPICompositionDetailList);
      toast.current.show({
        severity:'success', 
        summary: '', 
        detail: t('position:KPIManagementPositionCompositionRemoved'), 
        life: 3000
      });         
      if (props.OnRemoved) props.OnRemoved();
    }, (error) => {
      console.error('Error removing KPI composition', error);
    });
  };

  // #endregion

  // #region Events 

  useEffect(() => {                     
    getFollowUpKPIPositionRelationships();   
    getCompositionPositions();         
    getKPIManagementByPositionComposition(      
      props.PuestoIndicador.ClaDepartamento,
      props.PuestoIndicador.ClaArea,
      props.PuestoIndicador.ClaTipoIndicador,
      props.PuestoIndicador.ClaIndicador,
      props.PuestoIndicador.ClaPuesto,
      props.PuestoIndicador.ClaPeriodo
    );
  }, []);

  const onHide = () => {
    if (props.OnHide) {
      props.OnHide();
    }
  };

  const onPositionChange = (e) => {    
    setPositionRelationship(e.value);
  };

  const onAddClick = (e) => {
    const errors = {...Errors};
    delete errors.KPICompositionList;
    setErrors(errors);
    const updateKpiCompositionDetailList = [...KPICompositionDetailList];
    updateKpiCompositionDetailList.unshift({
      ClaPeriodo: props.PuestoIndicador.ClaPeriodo,
      ClaTipoIndicador: props.PuestoIndicador.ClaTipoIndicador,
      ClaIndicador: props.PuestoIndicador.ClaIndicador,
      ClaPuesto: props.PuestoIndicador.ClaPuesto,
      ClaPuestoComposicion: null, 
      ClaTipoIndicadorComposicion: null, 
      ClaIndicadorComposicion: null, 
      NomPuestoComposicion: '',
      NomTipoIndicadorComposicion: '',
      NomIndicadorComposicion: '',
      Orden: null,
      IsNew: true
    });
    setKPICompositionDetailList(updateKpiCompositionDetailList); 
  };

  const onPositionColumnChange = (props, value) => {    
    // Reset error
    const errors = {...Errors};  
    const kPICompositionListError = { ...errors[`KPICompositionList[${props.rowIndex}]`] };    
    delete kPICompositionListError.ClaPuestoComposicion;
    errors[`KPICompositionList[${props.rowIndex}]`] = kPICompositionListError;   
    setErrors(errors);
    // Update row
    const updateKpiCompositionDetailList = [...KPICompositionDetailList];    
    // Reset Index Type
    setIndexTypes([]);
    updateKpiCompositionDetailList[props.rowIndex].ClaTipoIndicadorComposicion = null;
    updateKpiCompositionDetailList[props.rowIndex].NomTipoIndicadorComposicion = '';
    // Reset Period Index
    setPeriodIndexes([]);
    updateKpiCompositionDetailList[props.rowIndex].ClaIndicadorComposicion = null;
    updateKpiCompositionDetailList[props.rowIndex].NomIndicadorComposicion = '';
    // Update Position      
    const compositionPositionsFiltered = CompositionPositions.filter((position) => position.ClaPuesto === value);
    const positionName = compositionPositionsFiltered.length > 0? compositionPositionsFiltered[0].NomPuesto: '';
    updateKpiCompositionDetailList[props.rowIndex].ClaPuestoComposicion = value;
    updateKpiCompositionDetailList[props.rowIndex].NomPuestoComposicion = positionName;
    setKPICompositionDetailList(updateKpiCompositionDetailList); 
  };

  const onIndexTypeColumnChange = (props, value) => {    
    // Reset error
    const errors = {...Errors};  
    const kPICompositionListError = { ...errors[`KPICompositionList[${props.rowIndex}]`] };    
    delete kPICompositionListError.ClaTipoIndicadorComposicion;
    errors[`KPICompositionList[${props.rowIndex}]`] = kPICompositionListError;   
    setErrors(errors);
    // Update row
    const updateKpiCompositionDetailList = [...KPICompositionDetailList];
    // Reset Period Index
    setPeriodIndexes([]);
    updateKpiCompositionDetailList[props.rowIndex].ClaIndicadorComposicion = null;
    updateKpiCompositionDetailList[props.rowIndex].NomIndicadorComposicion = '';
    // Update Index Type
    const IndexTypesFiltered = IndexTypes.filter((indexType) => indexType.ClaTipoIndicador === value);
    const indexTypeName = IndexTypesFiltered.length > 0? IndexTypesFiltered[0].NomTipoIndicador: '';           
    updateKpiCompositionDetailList[props.rowIndex].ClaTipoIndicadorComposicion = value;
    updateKpiCompositionDetailList[props.rowIndex].NomTipoIndicadorComposicion = indexTypeName;
    setKPICompositionDetailList(updateKpiCompositionDetailList);     
  };

  const onPeriodIndexColumnChange = (props, value) => {    
    // Reset error
    const errors = {...Errors};  
    const kPICompositionListError = { ...errors[`KPICompositionList[${props.rowIndex}]`] };    
    delete kPICompositionListError.ClaIndicadorComposicion;
    errors[`KPICompositionList[${props.rowIndex}]`] = kPICompositionListError;   
    setErrors(errors);
    // Update row
    const updateKpiCompositionDetailList = [...KPICompositionDetailList];
    // Update Period Index  
    const PeriodIndexesFiltered = PeriodIndexes.filter((periodIndex) => periodIndex.ClaIndicador === value);
    const periodIndexName = PeriodIndexesFiltered.length > 0? PeriodIndexesFiltered[0].NomIndicador: '';    
    updateKpiCompositionDetailList[props.rowIndex].ClaIndicadorComposicion = value;
    updateKpiCompositionDetailList[props.rowIndex].NomIndicadorComposicion = periodIndexName;
    setKPICompositionDetailList(updateKpiCompositionDetailList); 
  };

  const onOrderColumnChange = (props, value) => {      
    // Reset error
    const errors = {...Errors};  
    const kPICompositionListError = { ...errors[`KPICompositionList[${props.rowIndex}]`] };    
    delete kPICompositionListError.Orden;
    errors[`KPICompositionList[${props.rowIndex}]`] = kPICompositionListError;   
    setErrors(errors);
    // Update row
    const updateKpiCompositionDetailList = [...KPICompositionDetailList];
    updateKpiCompositionDetailList[props.rowIndex].Orden = value;    
    setKPICompositionDetailList(updateKpiCompositionDetailList); 
  };

  const onIndexTypeColumnEditorInit = (e) => {    
    const { rowIndex, field, rowData } = e.columnProps;            
    const claPuestoComposicion = rowData.ClaPuestoComposicion;
    if (claPuestoComposicion)  {      
      getIndexTypes(claPuestoComposicion);
    }    
  };

  const onPeriodIndexColumnEditorInit = (e) => {    
    const { rowIndex, field, rowData } = e.columnProps;
    const claPuestoComposicion = rowData.ClaPuestoComposicion;
    const claTipoIndicadorComposicion = rowData.ClaTipoIndicadorComposicion;    
    if (claPuestoComposicion && claTipoIndicadorComposicion) {
      getPeriodIndexes(props.PuestoIndicador.ClaPeriodo, claPuestoComposicion, claTipoIndicadorComposicion);
    }    
  };

  const onRemoveClick = (rowData, props) => {    
    if (rowData.IsNew) {
      const updateKPICompositionDetailList = [...KPICompositionDetailList];
      updateKPICompositionDetailList.splice(props.rowIndex, 1);
      setKPICompositionDetailList(updateKPICompositionDetailList);
    } else {    
      confirmRemoval(props.rowIndex);  
    }
  };

  const onSaveClick = () => {
    save(
      props.PuestoIndicador.ClaPeriodo,
      props.PuestoIndicador.ClaPuesto,
      props.PuestoIndicador.ClaTipoIndicador,
      props.PuestoIndicador.ClaIndicador,
      PositionRelationship,
      KPICompositionDetailList
    );
  };

  // #endregion

  // #region Templates 

  const dialogHeaderTemplate = (
    <>
      <Row className='align-items-start'>
        <Col lg={8} md={8} sm={7} xs={7}>
          {
            props.PuestoIndicador && (
              <h1>            
                {props.PuestoIndicador.NomIndicador}
              </h1>
            )
          }          
          <h2>
            {t('position:KPIManagementByPositionComposition')}
          </h2>
        </Col>        
      </Row>
    </>
  ); 

  const byPositionRelationshipTemplate = (
    <> 
      <Row className='pt-3'>
        <Col xs={12} sm={8} md={6} lg={4} xl={3}>
          <div className="p-fluid">
            <FieldDropdown
              name="ClaSeguimientoKPIPosicionRelacionada"
              value={PositionRelationship}
              options={PositionRelationships}
              optionValue="ClaSeguimientoKPIPosicionRelacionada"
              optionLabel="NomSeguimientoKPIPosicionRelacionada"
              label={t('position:FollowUpByPosition')}
              onChange={onPositionChange} 
              showClear={true}              
            />
          </div>        
        </Col>
      </Row>
    </>
  );

  const kPICompositionDetailTableHeader = (
    <>
      <FieldButton
        label={t('position:AddKPIComposition')}
        icon="fas fa-plus"
        className="p-button-primary no-border p-mr-2 rounded-button"
        onClick={onAddClick}
      />
    </>
  );

  const actionsColumnTemplate = (rowData, props) => {    
    return (
      <> 
        <FieldButton 
          icon="pi pi-trash" 
          onClick={() => onRemoveClick(rowData, props)} 
          className="p-button-rounded p-button-warning" 
        /> 
      </>
    );
  }

  const positionColumnTemplate = (row, props) => {      
    let positionName;
    if (!row.ClaPuestoComposicion) {
      positionName = (
        <FieldButton 
          name="ClaPuestoComposicion"
          className='p-button-link link-text text-primary'
          label={`- ${t('position:SelectAPosition')} -`}  
          errors={Errors[`KPICompositionList[${props.rowIndex}]`]}            
        />
      );
    } else {
      positionName = row.NomPuestoComposicion;
    }     
    return positionName;
  };

  const indexTypeColumnTemplate = (row, props) => {    
    let indexTypeName;
    if (!row.ClaTipoIndicadorComposicion) {
      indexTypeName = (
        <FieldButton 
          name="ClaTipoIndicadorComposicion"
          className='p-button-link link-text text-primary'
          label={`- ${t('position:SelectAnIndexType')} -`}  
          errors={Errors[`KPICompositionList[${props.rowIndex}]`]}            
        />
      );      
    } else {
      indexTypeName = row.NomTipoIndicadorComposicion;
    }    
    return indexTypeName;
  };

  const periodIndexColumnTemplate = (row, props) => {    
    let periodIndexName;
    if (!row.ClaIndicadorComposicion) {    
      periodIndexName = (
        <FieldButton 
          name="ClaIndicadorComposicion"
          className='p-button-link link-text text-primary'
          label={`- ${t('position:SelectAPeriodIndex')} -`}  
          errors={Errors[`KPICompositionList[${props.rowIndex}]`]}            
        />
      );        
    } else {
      periodIndexName = row.NomIndicadorComposicion;
    }    
    return periodIndexName;
  };

  const orderColumnTemplate = (row, props) => {    
    let order = '';
    if (!row.Orden) {
      order = (
        <FieldButton 
          name="Orden"
          className='p-button-link link-text text-primary'
          label={`- ${t('position:EnterTheOrder')} -`}  
          errors={Errors[`KPICompositionList[${props.rowIndex}]`]}            
        />
      );         
    } else {
      order = row.Orden.toFixed(2);
    }    
    return order;
  };

  const positionColumnEditorTemplate = (props) => {
    return (
      <>         
        <FieldDropdown 
          placeholder={t('position:SelectAPosition')}
          value={props.rowData.ClaPuestoComposicion}
          options={CompositionPositions} 
          optionLabel="NomPuesto" 
          optionValue="ClaPuesto"          
          onChange={(e) => onPositionColumnChange(props, e.value)}           
          showClear
        />    
      </>        
    );
  };

  const indexTypeColumnEditorTemplate = (props) => {    
    return (
      <>         
        <FieldDropdown 
          placeholder={t('position:SelectAnIndexType')}
          value={props.rowData.ClaTipoIndicadorComposicion}
          options={IndexTypes} 
          optionLabel="NomTipoIndicador" 
          optionValue="ClaTipoIndicador"          
          onChange={(e) => onIndexTypeColumnChange(props, e.value)}           
          showClear
        />    
      </>        
    );
  };

  const periodIndexColumnEditorTemplate = (props) => {
    return (
      <>         
        <FieldDropdown 
          placeholder={t('position:SelectAPeriodIndex')}
          value={props.rowData.ClaIndicadorComposicion}
          options={PeriodIndexes} 
          optionLabel="NomIndicador" 
          optionValue="ClaIndicador"          
          onChange={(e) => onPeriodIndexColumnChange(props, e.value)}           
          showClear
        />    
      </>        
    );
  };

  const orderColumnEditorTemplate = (props) => {
    return (
      <>         
        <FieldNumber          
          value={props.rowData.Orden}          
          onChange={(e) => onOrderColumnChange(props, e.target.value)}
          min={-99999999.99}
          max={99999999.99}
          maxFractionDigits={2}
          className='text-right'              
        />
      </>        
    );
  };

  const kPICompositionDetailTemplate = (
    <>  
      <FieldDataTable        
        header={kPICompositionDetailTableHeader}
        value={KPICompositionDetailList}
        editMode='cell'        
      >
        {/* 
          Properties:
            ClaPeriodo: 0,
            ClaTipoIndicador: 0,
            ClaIndicador: 0,
            ClaPuesto: 0,
            ClaPuestoComposicion: 0, 
            ClaTipoIndicadorComposicion: 0, 
            ClaIndicadorComposicion: 0, 
            NomPuestoComposicion: '',
            NomTipoIndicadorComposicion: '',
            NomIndicadorComposicion: '',
            Orden: 0
        */}        
        <Column 
          body={(rowData, props) => actionsColumnTemplate(rowData, props)} 
          headerStyle={{ width: '35px' }}
        />
        <Column 
          field="NomPuestoComposicion" 
          header={t('position:PositionName')} 
          body={positionColumnTemplate}           
          editor={(props) => positionColumnEditorTemplate(props)}
          style={{ width: '300px' }}  
        />
        <Column 
          field="NomTipoIndicadorComposicion" 
          header={t('position:IndexType')} 
          body={indexTypeColumnTemplate} 
          editor={(props) => indexTypeColumnEditorTemplate(props)}
          onEditorInit={onIndexTypeColumnEditorInit}
          style={{ width: '300px' }}  
        />
        <Column 
          field="NomIndicadorComposicion" 
          header={t('position:IndexName')} 
          body={periodIndexColumnTemplate} 
          editor={periodIndexColumnEditorTemplate}        
          onEditorInit={onPeriodIndexColumnEditorInit}            
          style={{ width: '300px' }}  
        />
        <Column 
          field="Orden" 
          header={t('position:OrderBy')}           
          body={orderColumnTemplate}
          editor={orderColumnEditorTemplate}       
          className='text-right'   
          style={{ width: '300px' }}  
        />
      </FieldDataTable>        
    </>
  );

  const dialogFooterTemplate = (
    <>
      <Row>
        <Col>
          <FieldButton
            label={t('common:Save')}
            icon="pi pi-save"
            className="p-button-success no-border p-mr-2 rounded-button"
            onClick={onSaveClick}
            // disabled={!PositionRelationship && KPICompositionDetailList.length < 1}
          />
        </Col>
      </Row>
    </>
  );

  const switchTab = tab => {
    if(TabActiveIndex !== tab){
      setTabActiveIndex(tab);
    }
  }

  // #endregion
  
  return (
    <>
      <Dialog 
        header={dialogHeaderTemplate} 
        visible={props.IsVisible} 
        onHide={onHide} 
        style={{width: '80vw'}}      
        footer={dialogFooterTemplate}  
      >
        <Toast ref={toast} />
        <InstructionsCard className="instrucciones info">
          <p>Changes in the following configuration will apply only for the period <b>{CurrentPeriod}</b>.</p>
        </InstructionsCard>
        <div className="p-tabview p-component pb-3">
          <Nav className="p-tabview-nav">
            <NavItem className={TabActiveIndex === 1 ? "p-tabview-selected p-highlight p-unselectable-text nav-item flex-sm-fill" : "p-unselectable-text nav-item flex-sm-fill"}>
              <NavLink className="p-tabview-nav-link" onClick={() => { switchTab(1); }}>
                {t('position:ByPositionRelationship')}
              </NavLink>
            </NavItem>
            <NavItem className={TabActiveIndex === 2 ? "p-tabview-selected p-highlight p-unselectable-text nav-item flex-sm-fill" : "p-unselectable-text nav-item flex-sm-fill"}>
              <NavLink className="p-tabview-nav-link" onClick={() => { switchTab(2); }}>
                {t('position:KPICompositionDetail')}
              </NavLink>
            </NavItem>
          </Nav>  
        </div>
        
        <TabContent className="p-tabview-panels" activeTab={TabActiveIndex}>
          <TabPane tabId={1}> 
            <Row className="pt-2 pb-5">
              <Col lg={12} md={12} sm={12} xs={12}> 
                {byPositionRelationshipTemplate}
              </Col> 
            </Row>
          </TabPane>
          <TabPane tabId={2}>
            <Row className="pt-3">
              <Col lg={12} md={12} sm={12} xs={12}>
                {kPICompositionDetailTemplate}
              </Col>
            </Row> 
          </TabPane>
        </TabContent> 
      </Dialog>
    </>
  )
}

export default KPIManagementByPositionCompositionDialog
import React from 'react';
import { Row, Col } from 'reactstrap'
import Viz from 'components/Tableau/Viz';

const TableauDashboard = (props) => {

  return (
    <>
      <Row>
        <Col>
          <Viz url='https://tableau.deacero.com/#/views/ProduccionPB/Production?:iid=1' />
        </Col>
      </Row>
    </>
    
  )

}

export default TableauDashboard;
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect,useRef, useCallback } from 'react'; 
import { useTranslation } from 'react-i18next';  
import { Row,Col } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi} from '../../../utils/utils.js';
import Gantt, {ScaleTypeRange, Tasks, Dependencies, Resources, ResourceAssignments, Column, Editing, Toolbar, Item } 
from 'devextreme-react/gantt';
 
import moment from "moment";  
import FasesActividades from "./FasesActividades.jsx"; 
import FasesDetalle from "./FasesDetalle.jsx";
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import { Dialog } from 'primereact/dialog';
 


const ProjectGanttMaster = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['scoreCard', 'common']);
  const [ClaProyecto, setClaProyecto] = useState(props.ClaProyecto === undefined ? 0 : props.ClaProyecto);  
  const [Tareas, setTareas] = useState([]);
  const [Recursos, setRecursos] = useState([]);
  const [RecursosAsignacion, setRecursosAsignacion] = useState([]);
  const [isGanttFullScreenMode, setisGanttFullScreenMode] = useState(false);
  const ganttRef = useRef(null);
  
  useEffect(() => {    
    obtenerInformacionProyectoGantt();
  }, [props.refrescarInformacion]);  
 
  const obtenerInformacionProyectoGantt = () => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/GetProyectoGanttMaster`;  
    const paramsToService = { 
      ClaProyecto
    };   
    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => {
      const dataInfoGantt =  response.data.ProyectoGantt;
      const dataRecursosGantt =  response.data.ResourceGantt;
      const dataRecursosAsignacionGantt =  response.data.ResourceAssignmentGantt;
       console.log(dataInfoGantt);
       console.log(dataRecursosGantt);
       console.log(dataRecursosAsignacionGantt);
      setTareas(dataInfoGantt);
      setRecursos(dataRecursosGantt);
      setRecursosAsignacion(dataRecursosAsignacionGantt);
    });
  }  


const customizeTextDate= (e) => {
  return moment(e.value).format("MM/DD/YY");
}

const selectTask = (e, r) => {
    
   
  e.cancel = true;
}
 
const toggleFullScreen = () => {
 
  setisGanttFullScreenMode(!isGanttFullScreenMode);
  /*
  const element = ganttRef.current; // .getDOMNode()
  if (!document.fullscreenElement) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {  
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {  
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {  
      element.msRequestFullscreen();
    }
  } else if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();    
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
  */
};


  const fullScreenTemplate = () => {    
    if (isGanttFullScreenMode){
      return (
        <FieldButton
          type="button" 
          onClick={toggleFullScreen}
          icon="fas fa-compress"
          className="p-button-secondary no-border p-mr-2 p-button-rounded p-button-text devextreme-gantt-toolbar-button"
          style={{ marginRight: '.25em' }}
          tooltip="Turn Off Full Screen Mode"
        />  
      )
    }
    
    return (
      <>
        <FieldButton
          type="button" 
          onClick={toggleFullScreen}
          icon="fas fa-expand"
          className="p-button-secondary no-border p-mr-2 p-button-rounded p-button-text devextreme-gantt-toolbar-button"
          style={{ marginRight: '.25em' }}
          tooltip="Full Screen Mode"
        />    
      </>
    )
  }
  

  const screenRender = () => {
    return (
      <>
        <div style={{height:'100%'}} ref={ganttRef}> 
          <Row style={isGanttFullScreenMode? {height:'100%'}: null}>  
            <Col style={isGanttFullScreenMode? {height:'100%', padding: '0px'}: null}>
              { Tareas.length > 0 
               ? 
               (
                 <Gantt
                   taskListWidth={550}
                   scaleType="months"
                   height={isGanttFullScreenMode? '100%': 550}
                   firstDayOfWeek={1}
                   onTaskDblClick={selectTask}
                 >  
                   <Tasks
                     dataSource={Tareas}
                   /> 
                   <Dependencies dataSource={[]} />
                   <Resources dataSource={Recursos} />
                   <ResourceAssignments dataSource={RecursosAsignacion} />
                   <Toolbar> 
                     <Item name="collapseAll" />
                     <Item name="expandAll" />
                     <Item name="separator" />
                     <Item name="zoomIn" />
                     <Item name="zoomOut" />
                     <Item name="separator" />
                     <Item name="fullScreen" render={fullScreenTemplate} />
                   </Toolbar>
                   <Column headerCellTemplate="Task" dataField="title" caption="Subject" width={250} />
                   <Column 
                     dataField="NomEstatus"
                     caption={t('scoreCard:Status')}
                     width={100}
                   />
                   <Column
                     dataField="start"
                     caption={t('scoreCard:StartDate')}
                     width={90}
                     customizeText={customizeTextDate}
                   />
                   <Column 
                     dataField="end"
                     caption={t('scoreCard:EndDate')}
                     width={90}
                     customizeText={customizeTextDate}
                   /> 
                   <Editing enabled={false} />
                 </Gantt>
              )
              : (<></>)}
            </Col>
          </Row>           
        </div>
      </>
        );
  }
  

  if (isGanttFullScreenMode){ 
    return (
      <>
        <Dialog
          visible={isGanttFullScreenMode}
          header="Gantt View"
          modal 
          closable={false} 
          className="dialog-header-background-blue"
          maximized={true}
          draggable={false}
        >
          {screenRender()}
        </Dialog>
      </> 
    )
  }
  return (
    <>
      {screenRender()}
    </>
      ); 

};
export default ProjectGanttMaster;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from 'utils/config';
import { callApi } from 'utils/utils';
import { Panel } from 'primereact/panel';
import { Dialog } from 'primereact/dialog';
import { Row, Col, Label } from 'reactstrap';
import FieldText from 'components/Controls/FieldText';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldAutoComplete from 'components/Controls/FieldAutoComplete';

import AddITAsset from './AddITAsset';

const ITAssetsFilters = (props) => {
  const {t, i18n} = useTranslation(['it', 'common']);

  const [equipments, setEquipments] = useState([]); // Equipment types
  const [brands, setBrands] = useState([]); // Equipment brands
  const [idITEquipo, setIdITEquipo] = useState(null); // Filter by id
  const [claTipoEquipo, setClaTipoEquipo] = useState(null); // Filter by equipment
  const [claMarcaEquipo, setClaMarcaEquipo] = useState(null); // Filter by brand
  const [serialNumber, setSerialNumber] = useState(null); // Filter by serial number
  const [modeloEquipo, setModeloEquipo] = useState(null); // Filter by model
  const [idOrdenCompraRef, setIdOrdenCompraRef] = useState(null); // Filter by purchase order
  const [nomProveedor, setNomProveedor] = useState(null); // Filter by vendor
  const [assignedUser, setAssignedUser] = useState(null); // Filter by assigned to user
  const [isUnassigned, setShowUnassigned] = useState(false); // Filter by unassigned
  const [showAddITAsset, setShowAddITAsset] = useState(false); // Show Add IT Asset dialog
  const [errors, setErrors] = useState([]); // Errors
  const [users, setUsers] = useState([]); // User suggestions
  const [location, setLocation] = useState(null); // ClaUbicacion
  const [locations, setLocations] = useState(null);
  const [assignedTo, setAssignedTo] = useState(null); // Assigned to Description
  const [adpEmployees, setAdpEmployees] = useState([]);
  const [adpEmployee, setAdpEmployee] = useState(null);

  useEffect(() => {
    GetEquipments();
    GetBrands();
  }, [])

  useEffect(() => {
    const onKeyDown = (e) => {
      if(e.keyCode === 13) {
        updateFilters();
      }
    }
    
    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [idITEquipo, claTipoEquipo, claMarcaEquipo, serialNumber, modeloEquipo, idOrdenCompraRef, nomProveedor, assignedUser, location, assignedTo, adpEmployee])

  const filterAdpEmployee = (e) => {
    let valor = ''; 
    if (adpEmployee.FullName !== null && adpEmployee.FullName !== '' &&  e.query === "")
    {
     valor = adpEmployee.FullName;
    }
    else{
     valor = e.query;
    }
    const paramsServiceOwners = {};
    // event.query 
    const urlWebServiceOwners = `${config.UrlApiProject}HR/CmbADPEmployee?valor=${valor}`;
    // Get owner
    callApi(urlWebServiceOwners, 'GET', paramsServiceOwners, (response) => {  
       setAdpEmployees(response.data);
    });    
  }

  const onAdpEmployeeChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.adpEmployee;
    setErrors(newErrors);
    setAdpEmployee(e.value);
  }

  const GetEquipments = () => {
    const urlWebService = `${config.UrlApiProject}IT/GetEquipmentTypes`;
    const paramsService = {};

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setEquipments(response.data);
    });
  }

  const GetBrands = () => {
    const urlWebService = `${config.UrlApiProject}IT/GetEquipmentBrands`;
    const paramsService = {};

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setBrands(response.data);
    });
  }

  const onChangeIdFilter = (e) => {
    setIdITEquipo(e.target.value === undefined ? null : e.target.value);
  }

  const onChangeShowUnassignedFilter = (e) => {
    setShowUnassigned(e.checked);
  }

  const onChangeEquipmentFilter = (e) => {
    setClaTipoEquipo(e.value === undefined ? null : e.value);
  }

  const onChangeBrandFilter = (e) => {
    setClaMarcaEquipo(e.value === undefined ? null : e.value);
  }

  const onChangeSerialNumberFilter = (e) => {
    setSerialNumber(e.target.value === undefined ? null : e.target.value);
  }

  const onChangeModelFilter = (e) => {
    setModeloEquipo(e.target.value === undefined ? null : e.target.value);
  }

  const onChangePurchaseOrderFilter = (e) => {
    setIdOrdenCompraRef(e.target.value === undefined ? null : e.target.value);
  }

  const onChangeVendorFilter = (e) => {
    setNomProveedor(e.target.value === undefined ? null : e.target.value);
  }

  const onChangeAssignedUserFilter = (e) => {
    setAssignedUser(e.value);
  }

  // Filter for assigned user drop down
  const filterUser = (e) => {
    let valor = ''; 
    if (assignedUser.NomUsuario !== null && assignedUser.NomUsuario !== '' &&  e.query === "")
    {
     valor = assignedUser.NomUsuario;
    }
    else{
     valor = e.query;
    }
    const paramsServiceOwners = {};
    // event.query 
    const urlWebServiceOwners = `${config.UrlApiProject}IT/GetUsers?valor=${valor}`;
    // Get owner
    callApi(urlWebServiceOwners, 'GET', paramsServiceOwners, (response) => {  
       setUsers(response.data.Users);
    });    
  }


  const filterLocations = (e) => {
    let valor = ''; 
    if (location.NomUbicacion !== null && location.NomUbicacion !== '' &&  e.query === "")
    {
     valor = location.NomUbicacion;
    }
    else{
     valor = e.query;
    }
    const paramsServiceOwners = {};
    
    const urlWebServiceOwners = `${config.UrlApiProject}IT/GetUbicaciones?valor=${valor}`;

    callApi(urlWebServiceOwners, 'GET', paramsServiceOwners, (response) => {  
       setLocations(response.data.Locations);
    });    
  }

  const onLocationChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.location;
    setErrors(newErrors);
    setLocation(e.value);
  }

  const onChangeAssignedTo = (e) => {
    const newErrors = {...errors};
    delete newErrors.assignnedTo;
    setErrors(newErrors);
    setAssignedTo(e.target.value);
  }

  const updateFilters = () => {
    console.log(claMarcaEquipo);
    props.setFilters({
      IdITEquipo: idITEquipo,
      ClaTipoEquipo: claTipoEquipo,
      ClaMarcaEquipo: claMarcaEquipo,
      NomProveedor: nomProveedor,
      ModeloEquipo: modeloEquipo,
      SerialNumber: serialNumber,
      IdOrdenCompraRef: idOrdenCompraRef,
      AssignedUser: assignedUser !== null ? assignedUser.ClaUsuario : null,
      ClaUbicacion: location !== null ? location.ClaUbicacion : null,
      AssignedTo: assignedTo,
      ClaADPEmployee: adpEmployee !== null ? adpEmployee.ClaADPEmployee : null
    })
  }

  const clearFilters = () => {
    setShowUnassigned(false);
    setIdITEquipo(null);
    setClaTipoEquipo(null);
    setClaMarcaEquipo(null);
    setSerialNumber(null);
    setModeloEquipo(null);
    setIdOrdenCompraRef(null);
    setNomProveedor(null);
    setAssignedUser(null);
    setLocation(null);
    setAssignedTo(null);
    setAdpEmployee(null);

    props.setFilters({});
  }

  const onHideAddITAsset = () => {
    setShowAddITAsset(false);
    updateFilters();
  }

  const exportItAssets = () => {
    const urlWebService = `${config.UrlApiProject}IT/ExportItAssetsAsXlsx`;        
    const paramsService = {};
    callApi(urlWebService, 'GET', paramsService, (response) => {
      const linkSource = `data:application/${response.data.FileExtension};base64,${response.data.FileData}`;
      const downloadLink = document.createElement("a");
      const fileName = `${response.data.FileName}`;
      downloadLink.href = linkSource;
      downloadLink.download = `${fileName  }.${  response.data.FileExtension}`;
      downloadLink.click();
    });   
  }

  const filterHeaderTemplate = (options) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down text-white' : 'pi pi-chevron-up text-white';
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className='card-header'>
        <div className='p-toolbar p-component'>
          <div className='p-toolbar-group-left'>
            <div className='card-title'>
              <FieldButton
                className={options.togglerClassName}
                onClick={options.onTogglerClick}
              >
                <span className={toggleIcon}></span>
              </FieldButton>
              <span className={titleClassName}>
                {t('common:Filters')}
              </span>
            </div>
          </div>

          <div className='p-toolbar-group-right'>
            <FieldButton
              label={t('it:AddAsset')}
              className='p-button-success no-border p-mr-2'
              icon='pi pi-plus'
              onClick={() => setShowAddITAsset(true)}
            />
            <FieldButton className="p-button-secondary p-button-icon-only p-highlight mr-2" onClick={() => exportItAssets()}>
              <i className="fa fa-file-excel"></i>
            </FieldButton>
            <FieldButton className="p-button p-button-icon-only p-highlight mr-2" onClick={() => updateFilters()}>
              <i className="pi pi-search"></i>
            </FieldButton>
            <FieldButton className="p-button-danger p-button-icon-only p-highlight" onClick={() => clearFilters()}>
              <i className="pi pi-times"></i>
            </FieldButton>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Panel
        header={t('common:Filters')}
        toggleable
        headerTemplate={filterHeaderTemplate}
      >
        <Row className='p-fluid p-grid p-formgroup-inline align-items-end'>
          <Col xs={12} sm={12} md={6} lg={3}>
            <div className='p-field'>
              <Label>{t('it:ID')}</Label>
              <FieldText
                id='idITEquipo'
                name='idITEquipo'
                value={idITEquipo}
                onChange={onChangeIdFilter}
              >
              </FieldText>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={3}>
            <div className='p-field'>
              <Label>{t('it:Equipment')}</Label>
              <FieldDropdown
                id='tipoEquipo'
                name='tipoEquipo'
                value={claTipoEquipo}
                options={equipments}
                optionValue='ClaTipoEquipo'
                optionLabel='NomTipoEquipo'
                onChange={onChangeEquipmentFilter}
              >
              </FieldDropdown>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={3}>
            <div className='p-field'>
              <Label>{t('it:Brand')}</Label>
              <FieldDropdown
                id='marcaEquipo'
                name='marcaEquipo'
                value={claMarcaEquipo}
                options={brands}
                optionValue='ClaMarcaEquipo'
                optionLabel='NomMarcaEquipo'
                onChange={onChangeBrandFilter}
              >
              </FieldDropdown>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={3}>
            <div className='p-field'>
              <Label>{t('it:SerialNumber')}</Label>
              <FieldText
                id='serialNumber'
                name='serialNumber'
                value={serialNumber}
                onChange={onChangeSerialNumberFilter}
              >
              </FieldText>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={3}>
            <div className='p-field'>
              <Label>{t('it:Model')}</Label>
              <FieldText
                id='modeloEquipo'
                name='modeloEquipo'
                value={modeloEquipo}
                onChange={onChangeModelFilter}
              >
              </FieldText>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={3}>
            <div className='p-field'>
              <Label>{t('it:PurchaseOrder')}</Label>
              <FieldText
                id='idOrdenCompraRef'
                name='idOrdenCompraRef'
                value={idOrdenCompraRef}
                onChange={onChangePurchaseOrderFilter}
              >
              </FieldText>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={3}>
            <div className='p-field'>
              <Label>{t('it:Vendor')}</Label>
              <FieldText
                id='nomProveedor'
                name='nomProveedor'
                value={nomProveedor}
                onChange={onChangeVendorFilter}
              >
              </FieldText>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={3}>
            <div className='p-field'>
              <Label>{t('it:AssignedTo')}</Label>
              <FieldText
                id='assignedTo'
                name='assignedTo'
                value={assignedTo}
                onChange={onChangeAssignedTo}
                errors={errors}
              >
              </FieldText>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={3}>
            <div className='p-field'>
              <Label>{t('it:Location')}</Label>
              <FieldAutoComplete
                dropdown
                id="location"
                name="location"
                value={location}
                onChange={onLocationChange}
                suggestions={locations}
                field="NomUbicacion"
                completeMethod={filterLocations}
                errors={errors}
              />
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={3}>
            <div className='p-field'>
              <Label>{t('it:User')}</Label>
              <FieldAutoComplete
                dropdown
                name="assignedUser"
                value={assignedUser}
                onChange={onChangeAssignedUserFilter}
                suggestions={users}
                field="NomUsuario"
                completeMethod={filterUser}
                errors={errors}
              /> 
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={3}>
            <div className='p-field'>
              <Label>{t("it:EmployeeID")}</Label>
              <FieldAutoComplete
                dropdown
                id="adpEmployee"
                name="adpEmployee"
                value={adpEmployee}
                onChange={onAdpEmployeeChange}
                suggestions={adpEmployees}
                field="IdFullName"
                completeMethod={filterAdpEmployee}
                errors={errors}
              />
            </div>
          </Col>
        </Row>
      </Panel>
      <Dialog
        visible={showAddITAsset}
        onHide={() => {
          setShowAddITAsset(false);
          setErrors([]);
        }}
        header={t('it:AddAsset')}
        style={{ width: '50vw' }}
        breakpoints={{ '1700px': '75vw', '1000px': '100vw' }}
        blockScroll={true}
      >
        <AddITAsset onHide={onHideAddITAsset} />
      </Dialog>
    </>
  )
}

export default ITAssetsFilters;
import { React, UseState, UseEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PageContent from 'layouts/PageContent';
import YTDProgressDashboard from './YTDProgressDashboard';

const YTDProgressDashboardScreen = (props) => {
  const {t, i18n} = useTranslation(['salesPerformance', 'common']);

  return (
    <PageContent title={t('salesPerformance:YTDProgressDashboard')}>
      <YTDProgressDashboard />
    </PageContent>
  )
}

export default YTDProgressDashboardScreen;
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  ListGroupItem,
  ListGroup,
  Progress,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from 'reactstrap';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { Badge } from 'primereact/badge';
import { Link } from 'react-router-dom';
import ActionPlanScoreSquare from './ActionPlanScoreSquare.jsx';
import { Toolbar } from 'primereact/toolbar';
import Highcharts from 'highcharts';
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../../utils/utils';
import FieldButton from 'components/Controls/FieldButton/FieldButton.jsx';
import { Dialog } from 'primereact/dialog';
import MinutasMeeting from '../../Projects/MinutasMeeting.jsx';
import FieldDataTable from '../../../../components/Controls/FieldDataTable/FieldDataTable.jsx'; 
import { Column } from 'primereact/column';

const KPIPositionIndexRole = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'focusReport', 'common']);
  const [PosicionesRelacionadasList, setPosicionesRelacionadasList] = useState([]);
  const [ClaPeriodo, setClaPeriodo] = useState(null);
  const [ClaFechaReporte, setClaFechaReporte] = useState(null);
  const [IdCSF, setIdCSF] = useState(null);

  useEffect(() => { 
    setClaPeriodo(props.ClaPeriodo === undefined ? 0 : props.ClaPeriodo); 
    setClaFechaReporte(props.ClaFechaReporte === undefined ? 0 : props.ClaFechaReporte);
    setIdCSF(props.IdCSF === undefined ? 0 : props.IdCSF);
    GetKPIPositionIndexRole(
      props.ClaPeriodo === undefined ? 0 : props.ClaPeriodo,
      props.IdCSF === undefined ? 0 : props.IdCSF,
      props.ClaFechaReporte === undefined ? 0 : props.ClaFechaReporte
    ); 
 
  }, [props.IdCSF, props.ClaPeriodo, props.ClaFechaReporte, props.show]);

  const GetKPIPositionIndexRole = (ClaPeriodo, IdCSF, ClaFechaReporte) => {
    const GetKPIPositionIndexRole = `${config.UrlApiProject}ScoreCard/GetKPIPositionIndexRole`;
    const paramsToService = {
      IdCSF,
      ClaPeriodo,
      ClaFechaReporte 
    };

    callApi(GetKPIPositionIndexRole, 'POST', paramsToService, (response) => { 
        setPosicionesRelacionadasList(response.data.PosicionesRelacionadas); 
    });
  };

  const headerPosicionesRelacionadas = (
    <>
      <Row>
        <Col>{t('actionPlan:PosicionesRelacionadas')}</Col>
      </Row>
    </>
  );

  const BodyTemplatePositionName = (rowData, props) => {
    return (
      <> 
        <div className='p-toolbar-group-left'>
          <span className="bold">{rowData.PositionName}</span>
        </div>
        <div className='p-toolbar-group-right'>
          <small>{ rowData.Responsible}</small> 
        </div>
      </>
    );
  } 

  const EstatusPeriodoTemplate = (props) => {
    let className = '';

    switch (props.ColorEstatus) {
      case 1:
        className = 'danger-color';
        break;
      case 2:
        className = 'warning-color';
        break;
      case 3:
        className = 'success-color';
        break;
      default:
        className = 'default-color';
    }
    return (
      <>
        <span className={className}>
          <b>{props.CurrentStatus !== null? Highcharts.numberFormat(props.CurrentStatus, 2, '.', ','): null}</b>
        </span> 
      </>
    );
  };

  const renderHeader = () => {
    return (
      <div>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <span>{t('actionPlan:PosicionesRelacionadas')}</span>
          </Col> 
        </Row>
      </div>
    );
  };

  const onHide = () => { 
    if (props.onHide !== null && typeof props.onHide === 'function') {
      props.onHide();
    }
  };

  return (
    <> 
      <Dialog
        visible={props.show}
        modal  
        header={renderHeader} 
        onHide={onHide}
        closable={true}   
        blockScroll={true}
        style={{width: '80vw' }}
      >
        <Row>
          <Col lg={12}>
            <FieldDataTable
              value={PosicionesRelacionadasList}
              scrollable
              scrollHeight="330px"
                // className={`${headerColor}`}
              editMode="cell"
              selectionMode="single"
              header={headerPosicionesRelacionadas}
              paginator={false}
            >
              <Column
                field="IndexRole"
                header={t('actionPlan:IndexRole')}
                className="text-center"
                columnKey="IndexRole"
                headerStyle={{ width: '45px', textAlign: 'center' }}
              >
              </Column>
              <Column
                field="IndexDescription"
                header={t('actionPlan:IndexDescription')}
                headerStyle={{ width: '280px' }}
              >
              </Column>
              <Column
                field="PositionName"
                header={t('actionPlan:PositionName')}
                headerStyle={{ width: '250px' }}
                body={(rowData, props) => BodyTemplatePositionName(rowData, props)}
              >
              </Column> 
              <Column
                field="CurrentStatus"
                header={t('actionPlan:CurrentStatus')}
                headerStyle={{ width: '80px' }}
                className="text-center"
                body={EstatusPeriodoTemplate}
              >
              </Column> 
            </FieldDataTable>
          </Col>
        </Row>
      </Dialog>
    </>
  );
};

export default KPIPositionIndexRole;

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-const-assign */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next'; 
import FieldButton from 'components/Controls/FieldButton/FieldButton'; 
import { 
  Row,
  Col 
} from 'reactstrap';
import './GantryWarningBanner.scss';  
import { config } from 'utils/config';
import { callApi} from 'utils/utils'; 

const GantryWarningBanner = (props) => {
  const { t, i18n } = useTranslation(['tablets', 'common']);

  const [ListadoCeldasAfectadas, setListadoCeldasAfectadas] = useState(null);
  const [EsGantryEstatusActivo, setEsGantryEstatusActivo] = useState(true); 
  const [ListadoClaveCeldasAfectadas, setListadoClaveCeldasAfectadas] = useState(null);
  const interval = useRef();

  const getStatusGantry = () => {
    const GetStatusGantry = `${config.UrlApiProject}Operations/ObtieneEstatusGantry`;  
    const paramsToService = {};    
    
    // si detectamos en el navegador que no tiene internet
    try{
      if (!window.navigator.onLine){
        return ;
      }
    }catch(e){
      // 
    } 
 
    callApi(GetStatusGantry, 'GET', paramsToService, (response) => {   
        setEsGantryEstatusActivo(response.data.EsGantryActivo);
        setListadoCeldasAfectadas(response.data.ListadoCeldasAfectadas);     
        setListadoClaveCeldasAfectadas(response.data.ListadoClaveCeldasAfectadas); 
        // cuando recibimos una celda necesitamos confirmar que la celda este entre las involucradas con problemas.
        if (props.ClaCelda !== null && typeof(props.ClaCelda) !== 'undefined')
        {
            if (response.data.ListadoCeldasAfectadas !== null){
                // sacamos los valores separados por coma recortando los espacios para poder hacer una comparacion correcta.
                const valuesArray = response.data.ListadoCeldasAfectadas.split(',').map(item => item.trim());
                
                // si la celda la que esta mostrando el banner esta dentro del listado habilitamos el banner
                if (!valuesArray.includes(props.ClaCelda.toString())){
                    setEsGantryEstatusActivo(true);
                }
            }
        }
    }, () => {
    // no hacemos nada solo  para evitar de mandar el mensaje si algun momento se pierde la conexion...
    }, false, false);  
}

    useEffect(() => {    
        getStatusGantry();
        
        interval.current = setInterval(() => {
            getStatusGantry();
        }, 1500 * 60);
        
        return () => {
            if (interval.current) {
                clearTimeout(interval.current);  
            }  
         }   
    }, []); 
 
  if (EsGantryEstatusActivo){
    return (<></>);
  }

  return (
    <div className='GantryBanner'> 
      <Row className={`GantryWarningBanner ${ListadoCeldasAfectadas === null ? 'orange-dark' : 'warning'}`}>
        <Col lg={12} md={12} sm={12} xs={12} className='marquee'>      
          {t('tablets:GantryWarning')}{ ListadoClaveCeldasAfectadas !== null ? ` ${t('tablets:GantryWarningForCells')} ${  ListadoClaveCeldasAfectadas}` : ''}.
        </Col> 
      </Row>  
    </div>
  );
};

export default GantryWarningBanner;

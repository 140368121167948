import React, { useState, useEffect } from "react";
import {Row, Col, Label} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { callApi } from "utils/utils";
import { config } from 'utils/config';
import FieldSelectButton from "components/Controls/FieldSelectButton";
import FieldDataTable from "components/Controls/FieldDataTable/FieldDataTable";
import { Column } from "primereact/column";
import FieldToogleSwitch from "components/Controls/FieldToogleSwitch/FieldToogleSwitch";

const PagerOnBreak = (props) => {
  const {t, i18n} = useTranslation(['operations', 'common']);
  const MINUTES_MS = 60000; // 1 minute

  const [types, setTypes] = useState([]); // Pager types
  const [type, setType] = useState([]); // Selected type
  const [errors, setErrors] = useState([]); // Errors
  const [pagers, setPagers] = useState([]); // Pager list
  const [yesNoOptions, setYesNoOptions] = useState([`${t('operations:in')}`, `${t('operations:out')}`]) // Toggle text options

  // Get the pager list
  const getPagers = (claTipoPager) => {
    const urlWebService = `${config.UrlApiProject}Operations/GetPagerOnBreakList?claTipoPager=${claTipoPager}`; 
    const paramsService = null;

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setPagers(response.data.Pagers);
    });
  };

  // Get the pager types
  const getTypes = () => {
    const urlWebService = `${config.UrlApiProject}Operations/GetPagerTypes`; 
    const paramsService = null;

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setTypes(response.data.Types);
    });
  };

  // On pager type change
  const onPagerTypeChange = (e) => {
    if (e.value == null) {
      setType([]);
      getPagers([])
    }
    else {
      setType(e.value);
      getPagers(e.value);
    }
    
  };

  // Check in and out pagers on toggle
  const onPagerBreakChange = (e, ClaPager) => {
    if (e === false) {
      const urlWebService = `${config.UrlApiProject}Operations/PagerCheckOut`; 
      const paramsService = {
        ClaPager
      };

      callApi(urlWebService, 'POST', paramsService, (response) => {
        getPagers(type);
      }, () => {
        getPagers(type);
      });
    }
    else {
      const urlWebService = `${config.UrlApiProject}Operations/PagerCheckIn`; 
      const paramsService = {
        ClaPager
      };

      callApi(urlWebService, 'POST', paramsService, (response) => {
        getPagers(type);
      }, () => {
        getPagers(type);
      });
    }

  }

  // Toggle switch template for table
  const toggleBodyTemplate = (rowData) => {
    return (
      <>
        <FieldToogleSwitch
          id={rowData.ClaPager}
          checked={rowData.IsOnBreak === 0}
          onChange={(e) => onPagerBreakChange(e, rowData.ClaPager)}
          optionLabels={yesNoOptions}
        >
        </FieldToogleSwitch>
      </>
    )
  }

  useEffect(()=> {
    getTypes();
    getPagers(type);
  }, []);

  return (
    <>
      <Row>
        <Col>
          <Label>{t("operations:PagerTypeLabel")}</Label>
          <FieldSelectButton
            name="type"
            value={type}
            options={types}
            optionLabel="NomTipoPagerIngles"
            optionValue="ClaTipoPager"
            onChange={onPagerTypeChange}
            errors={errors}
          >
          </FieldSelectButton>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <FieldDataTable
            value={pagers}
          >
            <Column field="ClaPager" header={t('operations:pagerID')}></Column>
            <Column field="NomPagerIngles" header={t('operations:pagerName')}></Column>
            <Column field="EmployeeReference" header={t('operations:employeeReference')}></Column>
            <Column header={t('operations:checkedIn')} body={toggleBodyTemplate} />
          </FieldDataTable>
        </Col>
      </Row>
    </>
  )
}

export default PagerOnBreak;
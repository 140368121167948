
import React from 'react';

const StepsTourPocketMargin = {
    steps: [
      {
        target: '.PM-Step-1',
        content: (
          <>
            <h1>Filter Button</h1>
            Use this button to open <b><i>Filters Section</i></b> and customize your filters. 
            <br></br>
            <small>Do not forget to hit <b>Apply Filter</b> button before to close filters section.</small>
          </>
        ),
        disableBeacon: true
      },
      {
        target: '.PM-Step-2',
        content: (
          <>
            <h1>Summary</h1>
            This section will display a pivot table. You will be able to group the information in differents ways if you need it.
            <br></br> 
            <div className="text-left">
              Available Columns:
              <li>Tons Sold</li>
              <li>Tons Reliab</li>
              <li>% Reliab</li>
              <li>PM $/t</li>
              <li>PM %</li> 
              <br></br> 
              <table>
                <tr>
                  <td style={{verticalAlign:'top'}}><i className="dx-icon dx-icon-columnchooser"></i></td>
                  <td>Use this button to open a play with the avaiable fields.</td>
                </tr> 
              </table> 
            </div>
          </>
        )
      } ,
      {
        target: '.PM-Step-3',
        content: (
          <>
            <h1>BUCKET DETAIL</h1>
            Show a table with a panoramic view of the last 12 months. 
            <br></br>
            Use <i>filter icon</i> located in header of the column to delimitate the data showed in the table. 
          </>
        ),
      } ,
      {
        target: '.PM-Step-4',
        content: (
          <>
            <h1>INCONSISTENCIES</h1>
            Use this option when you need to compare buckets on one division for and specific year-month.
            <br></br>
            <br></br>  
            <small>Data will be filtered automaticaly once you select all filters.</small>
          </>
        ), 
      } ,
      {
        target: '.PM-Step-5',
        content: (
          <>
            <h1>DETAILED ANALYSIS AND FOLLOW UP</h1>
            <div className="text-left">
              
              If you want to analize in a detail way, this option can help you to: 
            
              <li>COMPARE each BUCKET</li> 
              <li>View the TREND of the BUCKET</li>
              <li>Identify your POTENTIAL CUSTOMERS <i>[WHALES]</i></li>
              <li>FOLLOW UP your OPPORTUNITIES</li>
            </div>
            <br></br>
            <br></br>  
            <small>Data will be filtered automaticaly once you select all filters inside of this option.</small>
          </>
        ), 
      } 
    ]
  };

  
export default StepsTourPocketMargin;
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';   
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source'; 
import '../../assets/css/PivotGridBase.css';  
import $ from 'jquery';
 
import PivotGrid, {
  FieldChooser  ,
  StateStoring
} from 'devextreme-react/pivot-grid';
 
import { useTranslation } from 'react-i18next'; 

const PocketMarginSummaryRDS =  (props) => { 
    
    
    const { t, i18n } = useTranslation(['formulario','common']); 
     
    const getPivotDataSource = () => {
        return {
            fields: [{
                caption: t('formulario:Division'),
                width: 250,
                dataField: 'NomGrupoEstadistico1',
                area: 'row', 
                isMeasure: false
            }, {
                caption: t('formulario:Segment'),
                dataField: 'NomSegmento',
                width: 250,
                area: 'row', 
                isMeasure: false
            }, {
                caption: t('formulario:StatisticalGpo3'),
                dataField: 'NomGrupoEstadistico3',
                width: 250,
                area: 'row', 
                isMeasure: false
                }/* , {
                caption: t('formulario:AccountName'),
                dataField: 'NomClienteCuenta', 
                width: 350, 
                isMeasure: false
                },  {
                caption: t('formulario:Zone'),
                dataField: 'NomZona', 
                width: 250, 
                isMeasure: false
                } */, {
                caption: t('formulario:ProducerLocation'),
                width: 250,
                dataField: 'NomUbicacionOrigen', 
                isMeasure: false
                },  {
                dataField: 'FechaFactura',
                dataType: 'date',
                area: 'column', 
                isMeasure: false
            }, {
                caption: t('formulario:ToneladasRDS'),
                dataField: 'Toneladas', 
                summaryType: 'sum', 
                dataType: 'number',
                format: 'fixedPoint',
                width: 50,
                area: 'data', 
                isMeasure: true
            }, {
                caption: t('formulario:Pocket Price'),
                dataField: 'PocketPrice', 
                summaryType: 'sum',
                dataType: 'number',
                format: 'fixedPoint',  
                visible:true,
                isMeasure: true,
                width: 50
                }, {
                caption: t('formulario:PocketMargin'),
                dataField: 'PocketMargin', 
                summaryType: 'sum',
                dataType: 'number',
                format: 'fixedPoint',  
                visible:true,
                isMeasure: true,
                width: 50
                }, {
                caption: t('formulario:TonsReliab'),
                dataField: 'TonConfiables', 
                summaryType: 'sum',
                dataType: 'number',
                format: 'fixedPoint',
                area: 'data',  
                width: 50 ,
                isMeasure: true
                }, {
                caption: t('formulario:PorcReliab'), 
                summaryType: 'sum', 
                area: 'data', 
                dataType: 'number', 
                format: 'percent',  
                isMeasure: true,  
                width: 50,
                calculateSummaryValue (e) { 
                    return e.value('TonConfiables', true) !== 0 ? e.value('TonConfiables', true) / e.value('Toneladas', true) : 0;
                }  
                }, {
                caption: t('formulario:PMPriceByton'), 
                summaryType: 'sum', 
                area: 'data', 
                dataType: 'number',
                format:'fixedPoint', 
                isMeasure: true,  
                width: 50,
                calculateSummaryValue (e) {
                    return e.value('TonConfiables', true) !== 0 ? e.value('PocketMargin', true) / e.value('TonConfiables', true) : 0;
                }   
                }, {
                caption: t('formulario:PMPorc'), 
                summaryType: 'sum', 
                area: 'data',
                dataType: 'number',
                format: 'percent', 
                isMeasure: true,  
                width: 50,
                calculateSummaryValue (e) {
                    return e.value('PocketPrice', true) !== 0 ? e.value('PocketMargin', true) / e.value('PocketPrice', true) : 0;
                }
                }
              ],
                store: []
            }
    } 

    const defaultValues = getPivotDataSource();
            
    defaultValues.store =  props.data; 
    
    const dataSource = new PivotGridDataSource(defaultValues) ;  

      

    function isHeaderCell(cell) {
        return (cell.area === 'column' && cell.type === 'D');
      }
  
      function isDataCell(cell) {
        return (cell.area === 'data' && cell.rowType === 'D' && cell.columnType === 'D');
      }
  
      function isRowCell(cell){
        return (cell.area === 'row' && cell.type === 'D');
      }

      

      function onCellPrepared(e) {  
        e.cell.area = e.area;
         

        // console.log('selectDx: ', selectDx );
  
        if(isDataCell(e.cell) || isTotalCell(e.cell) || isHeaderCell(e.cell) || isRowCell(e.cell)) { 
            e.cellElement.classList.add(getConditionalAppearance(e.cell)); 
        }
      }

  
      function isTotalCell(cell) {
        return (cell.type === 'T' || cell.type === 'GT' || cell.rowType === 'T' || cell.rowType === 'GT' || cell.columnType === 'T' || cell.columnType === 'GT');
      }
  
      function getConditionalAppearance(cell) {
          if(isTotalCell(cell)) {   
              return 'dx-total-row'
          } 
  
          if(isHeaderCell(cell)) {
              //  cuando es el encabezado principal 
              if (cell.isLast === true){
                  return 'dx-column-header'
              }
  
              return `dx-header-level-${ cell.path.length.toString() }`;
          }  
  
          if(isRowCell(cell)) {
              return `dx-row-level-${ cell.path.length.toString() }`;
          }  
          
          return 'dx-cell';
      } 
    
     

    return (  

      <>   
        <PivotGrid
          id="gridSummary" 
          dataSource={dataSource}
          allowSortingBySummary={false}
          allowFiltering={true}
          showBorders={true}
          showColumnTotals={false}
          showColumnGrandTotals={false}
          showRowTotals={true}
          showTotalsPrior="rows"
          showRowGrandTotals={false}  
          rowHeaderLayout="tree" 
          height={470} 
          width="100%" 
          texts={{noData:t('common:NoData')}}
          onCellPrepared={onCellPrepared}
        > 
          <StateStoring enabled={true} type="localStorage" storageKey="gPMSummaryRDSPersists" />
          <FieldChooser enabled={true} height={500} /> 
        </PivotGrid> 
             
      </> 
    )
    }   
     
        
    export default PocketMarginSummaryRDS; 

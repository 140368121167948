/* eslint-disable react/button-has-type */
import React, { useState, useEffect, useRef } from 'react'; 
import { config } from '../../../utils/config';
import { callApi, showSweetAlert, tracker} from '../../../utils/utils';
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon'  
import swal from '@sweetalert/with-react';
import { useTranslation } from 'react-i18next';  
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx'; 
import { Column } from 'primereact/column';
import { Row, Col } from 'reactstrap'; 
import FieldText from '../../../components/Controls/FieldText.jsx' 
import FieldDropdown from 'components/Controls/FieldDropdown.jsx'
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import FieldNumber from '../../../components/Controls/FieldNumber.jsx';
import KpisManagementDetTable from './KpisManagementDetTable';
import KPIFormulaSetUp from '../Scorecard/KPIsFormulaSetUp/KPIFormulaSetUp';

const KpisManagementTable = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['kpisManagement', 'common']);
  const [KPIManagementList, setKPIManagementList] = useState([]);
  const [ClaProyecto, setClaProyecto] = useState(props.ClaProyecto === undefined ? 0 : props.ClaProyecto);
  const [OpcionesResponsables, setOpcionesResponsables] = useState([]); 
  const [ProyectoFrecuencia, setProyectoFrecuencia] = useState([]); 
  const [ProyectoManagement, setProyectoManagement] = useState([]); 
  const [errors, setErrors] = useState({}); 
  const [Management, setManagement] = useState(null); 
  const [esMostrarKPISDetalle, setEsMostrarKPISDetalle] = useState(false);
  const [IdProyectoKPIManagementSeleccionado, setIdProyectoKPIManagementSeleccionado] = useState(false);
  const [esMostrarCfgFormula, setEsMostrarCfgFormula] = useState(false);
  const [IdKPIFormulaSelected, setIdKPIFormulaSelected] = useState(null);
  const [KPIDescriptionSelected, setKPIDescriptionSelected] = useState('');
  const [SentidoMejoraList, setSentidoMejoraList] = useState([]); 

  useEffect(() => {   
    obtenerInformacionKPIManagement();    
  }, [props.Refresh]); 
  
   
  const obtenerInformacionKPIManagement = () => { 
    const GetKPIManagementList = `${config.UrlApiProject}ScoreCard/GetKPIManagementList?IdClaProyecto=${ClaProyecto}`;  
    const paramsToService = { };   
    callApi(GetKPIManagementList, 'GET', paramsToService, (response) => {
      setKPIManagementList(response.data.ProyectoKPIManagementList);
      obtenerResponsablesProyecto();
      obtenerProyectoFrecuencia();      
      obtenerProyectoMangement();
      obtenerSentidoMejora();
      setErrors({});
    });
  }

 

  const NomProyectoKPIManagementEditor = ( props ) => {   
  return (
    <div className="p-fluid">
      <FieldText
        name="NomProyectoKPIManagement" 
        value={props.rowData.NomProyectoKPIManagement} 
        onChange={(e) => onNomProyectoKPIManagementChange(props, e.target.value)}
        errors={errors[`KPIManagementList[${props.rowIndex}]`]}
      />
    </div>
    )
  };
  const DescUnidadMedidadEditor = ( props ) => { 
    return (
      <div className="p-fluid">
        <FieldText
          name="DescUnidadMedidad"
          value={props.rowData.DescUnidadMedidad} 
          onChange={(e) => onNomProyectoKPIManagementChange(props, e.target.value)}
          errors={errors[`KPIManagementList[${props.rowIndex}]`]}
        />
      </div>
      )
    }; 
   const EstatusInicialProyectoEditor = ( props ) => { 
    return (
      <div className="p-fluid">
        <FieldNumber
          name="EstatusInicialProyecto"
          value={props.rowData.EstatusInicialProyecto} 
          onChange={(e) => onNomProyectoKPIManagementChange(props, e.target.value)}
          errors={errors[`KPIManagementList[${props.rowIndex}]`]}
          maxFractionDigits={2}
          min={-99999999.99}
          max={99999999.99}
        />
      </div>
      )
    };
   const EstatusObjetivoEditor = ( props ) => { 
      return (
        <div className="p-fluid">
          <FieldNumber
            name="EstatusObjetivo"
            value={props.rowData.EstatusObjetivo} 
            onChange={(e) => onNomProyectoKPIManagementChange(props, e.target.value)}
            errors={errors[`KPIManagementList[${props.rowIndex}]`]}
            maxFractionDigits={2}
            min={-99999999.99}
            max={99999999.99}
          />
        </div>
        )
      };
   const EstatusFinalEditor = ( props ) => { 
        return (
          <div className="p-fluid">
            <FieldNumber  
              name="EstatusFinal"
              value={props.rowData.EstatusFinal} 
              onChange={(e) => onNomProyectoKPIManagementChange(props, e.target.value)}
              maxFractionDigits={2}
              min={-99999999.99}
              max={99999999.99}
            />
          </div>
          )
        };
  const NomRefSeguimientoKPIManagementEditor = ( props ) => { 
    return (
      <div className="p-fluid">
        <FieldText
          value={props.rowData.NomRefSeguimientoKPIManagement} 
          onChange={(e) => onNomRefSeguimientoKPIManagementChange(props, e.target.value)}
          maxLength={150}
        />
      </div>
      )
    };  
  const onNomProyectoKPIManagementChange = (props, value) => {   
    const updatedNomProyectoKPIManagement = [...props.value];
    updatedNomProyectoKPIManagement[props.rowIndex][props.field] = value;
    
    setKPIManagementList(updatedNomProyectoKPIManagement);  
  }; 
  const onNomRefSeguimientoKPIManagementChange = (props, value ) => {
    const updatedNomProyectoKPIManagement = [...props.value];
    updatedNomProyectoKPIManagement[props.rowIndex][props.field] = value;
    setKPIManagementList(updatedNomProyectoKPIManagement);  
  }; 
  
  const getCurrentRowValues = (props) => {
    const updatedProducts = [...props.value];
    const RowValues ={
        IdProyectoKPIManagement: updatedProducts[props.rowIndex].IdProyectoKPIManagement, 
        ClaProyecto: updatedProducts[props.rowIndex].ClaProyecto,  
        NomProyectoKPIManagement: updatedProducts[props.rowIndex].NomProyectoKPIManagement,
        DescUnidadMedidad: updatedProducts[props.rowIndex].DescUnidadMedidad,
        EstatusInicialProyecto: updatedProducts[props.rowIndex].EstatusInicialProyecto,
        EstatusObjetivo: updatedProducts[props.rowIndex].EstatusObjetivo,
        EstatusFinal:updatedProducts[props.rowIndex].EstatusFinal,
        NomProyectoTipoKPIManagement:updatedProducts[props.rowIndex].NomProyectoTipoKPIManagement,
        NomRefSeguimientoKPIManagement: updatedProducts[props.rowIndex].NomRefSeguimientoKPIManagement,
        ClaUsuarioResponsable:updatedProducts[props.rowIndex].ClaUsuarioResponsable,
        NomUsuario: updatedProducts[props.rowIndex].NomUsuario, 
        ClaProyectoTipoKPIManagement: updatedProducts[props.rowIndex].ClaProyectoTipoKPIManagement, 
        ClaProyectoFrecuenciaKPIManagement: updatedProducts[props.rowIndex].ClaProyectoFrecuenciaKPIManagement, 
        NomProyectoFrecuenciaKPIManagement: updatedProducts[props.rowIndex].NomProyectoFrecuenciaKPIManagement,
        PorcPeso : updatedProducts[props.rowIndex].PorcPeso
    }
    return RowValues;
  } 
  const emptyValueValidator = (e) => {
    const { rowData, field } = e.columnProps;
    return rowData[field].trim().length > 0;
  }

  const onQuestionarioChange = (questionario) => {
    onChange(questionario)
  }
  const onEditClick = (rowData) => { 
    props.onEditClick(rowData);
  };
  const onNewClick = () => { 
    const newRow = {         
      IdProyectoKPIManagement	 : null	,
      ClaProyecto	,
      NomProyectoKPIManagement	 : ""	,
      DescUnidadMedidad	 : ""	,
      EstatusInicialProyecto	 : null	,
      EstatusObjetivo	 : null	,
      EstatusFinal	 : null	,
      ClaProyectoTipoKPIManagement	 :null	,
      NomProyectoTipoKPIManagement	 : ""	,
      NomRefSeguimientoKPIManagement	 : ""	,
      ClaUsuarioResponsable	 : null	,
      NomUsuario	 : ""	,
      ClaProyectoFrecuenciaKPIManagement	 : null	,
      NomProyectoFrecuenciaKPIManagement	 : ""	,
      BajaLogica: 0,
      PorcPeso: null,
      ClaSentidoMejora : null,
      NomSentidoMejora: null
    };
    setKPIManagementList([...KPIManagementList, newRow]);
  }
  const onSaveClick = async () => {    
    const value = {
      KPIManagementList
    }
     
    const validator = new YupValidator(valitationSchemeSave);
    const esValido = await validator.validate(value);
    if(!esValido)
    {
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
      return;
    }    
    const urlWebServiceSave = `${config.UrlApiProject}ScoreCard/SaveKPIManagement`; 
    const paramsService = {
      ClaProyecto: props.ClaProyecto,
      ProyectoKPIManagement:KPIManagementList
    }; 

    tracker.trackEvent({
      type: 'KpisManagementTable', /* component name */
      action: 'SAVE_KPIS_MANAGEMENT'
    }) 

    callApi(urlWebServiceSave, 'POST', paramsService, (response) => { 
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      obtenerInformacionKPIManagement();

      if (props.onChange !== null && props.onChange !== 'undefined' && typeof(props.onChange) === 'function'){
        props.onChange();
      }
    }); 
    
  }
 

  const saveKPIManagement = async (kpimanagement) => {    
    const urlWebServiceSave = `${config.UrlApiProject}ScoreCard/SaveKPIManagement`;
    const paramsService = {
      ClaProyecto,
      ProyectoKpiManagemnt: KPIManagementList
    }; 
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => { 
      obtenerInformacionKPIManagement();
    });
  }
  const bajakpiManagement = async (kpiManagement) => { 
    
    const urlWebServiceSave = `${config.UrlApiProject}ScoreCard/SaveBajaKPIManagement`;
    const paramsService = { 
        ClaProyecto,  
        ...kpiManagement
    }; 
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => { 
      obtenerInformacionKPIManagement();
      if (props.onChange !== null && props.onChange !== 'undefined' && typeof(props.onChange) === 'function'){
        props.onChange();
      }
    });
  }

  const confirmationMessageHTML = (
    <div>
      <SweatAlertCustomIcon title={t('common:SubmitConfirmation')} letter={t('common:QuestionMark')}></SweatAlertCustomIcon> 
      <div>{t('kpisManagement:SubmitCancelled')}.</div> 
    </div>  
   ) 
  const onConfirmBaja = (kpiManagement, props) => {
    
    /* AQUI  */
    if(kpiManagement.IdProyectoKPIManagement === null){ 
      // aqui lo borro de mi arreglo y hago el setKPIManaget
      const kpiManagementListnew = [...KPIManagementList];
      kpiManagementListnew.splice(props.rowIndex, 1);
      setKPIManagementList(kpiManagementListnew);
      return
    }
  

    
    swal({ 
      buttons: {
        cancelar: {
          text: t('common:Cancel'),
          value: "cancel",
          className: "rounded-button danger"
        },
        continuar: {
          text: t('common:Continue'),
          value: "continue",
          className: "rounded-button light-green"
        }
      },
      content: confirmationMessageHTML
    }).then((value) => {
      switch (value) { 
        case "continue":
          bajakpiManagement(kpiManagement);          
          break; 
        default:
          break;
      }
    });
  }

  const actionBodyTemplate = (rowData, itemProps) => {

    if (props.readOnly === true){
      return (<></>)
    }

    return (
      <> 
        <FieldButton icon="pi pi-trash" onClick={() => onConfirmBaja(rowData,itemProps)} className="p-button-rounded p-button-warning" /> 
      </>
    );
  } 
   

  // Llenado para el Combo de Responsable en la Grilla
  const ClaResponsableEditor = ( props ) => {
    return (
      <div className="p-fluid">
        <FieldDropdown  
          name="ClaUsuarioResponsable"
          value={props.rowData.ClaUsuarioResponsable}
          options={OpcionesResponsables}  
          optionValue="ClaColaborador"
          optionLabel="NomColaborador" 
          onChange={(e) => onResponsableChange(props,e)}
        />  
      </div>
    )
  };

  const obtenerResponsablesProyecto = () => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/GetMswBSCColaboradorProyectosTeam`;  
    const paramsToService = { 
      ClaProyecto
    };   
    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => { 
      if (response.data[0].length >0)
      {  
        setOpcionesResponsables(response.data[0]);
      }  
    });
  }

  const obtenerProyectoFrecuencia = () => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/MSWBSCProyectoFrecuencia`;  
    const paramsToService = { };   
    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => { 
      if (response.data[0].length >0)
      {  
        setProyectoFrecuencia(response.data[0]);
      }  
    });
  }

  const obtenerSentidoMejora = () => { 
    const GetBSCSentidoMejoraCmb = `${config.UrlApiProject}ScoreCard/GetBSCSentidoMejoraCmb`;  
    const paramsToService = { };   
    callApi(GetBSCSentidoMejoraCmb, 'GET', paramsToService, (response) => {  
      setSentidoMejoraList(response.data.SentidoMejoraList); 
    });
  } 

  const obtenerProyectoMangement = () => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/MSWBSCProyectoTipoKPIManagement`;  
    const paramsToService = { };   
    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => { 
      if (response.data[0].length >0)
      {  
        setProyectoManagement(response.data[0]);
      }  
    });
  }  

  const onResponsableChange = (props, e ) => {
    const saveArray= getCurrentRowValues(props);
    const updatedProducts = [...props.value];
    const colaborador = OpcionesResponsables.filter(item => item.ClaColaborador ===e.value)[0];
    if(colaborador!==undefined){
      updatedProducts[props.rowIndex].NomUsuario = colaborador.NomColaborador;
    }
    updatedProducts[props.rowIndex].ClaUsuarioResponsable = e.value;
    saveArray.ClaUsuarioResponsable = e.value; 
    setKPIManagementList(updatedProducts);
    // saveKPIManagement(saveArray);
  };

  const onPorcPesoChange = (props, value) => { 
    const updateValues = [...props.value];
     
    updateValues[props.rowIndex].PorcPeso = value; 
    setKPIManagementList(updateValues);
  };


  const ClaProyectoFrecuenciaKPIManagementEditor = ( props ) => {
    return (
      <div className="p-fluid">
        <FieldDropdown  
          name="NomProyectoFrecuenciaKPIManagement"
          value={props.rowData.ClaProyectoFrecuenciaKPIManagement}
          options={ProyectoFrecuencia}  
          optionValue="ClaProyectoFrecuenciaKPIManagement"
          optionLabel="NomProyectoFrecuenciaKPIManagement" 
          onChange={(e) => onFrecuenciaProyectoChange(props,e)}
        />  
      </div>
    )
  };

  const ClaSentidoMejoraEditor = ( props ) => {
    return (
      <div className="p-fluid">
        <FieldDropdown  
          name="NomSentidoMejora"
          value={props.rowData.ClaSentidoMejora}
          options={SentidoMejoraList}  
          optionValue="ClaSentidoMejora"
          optionLabel="NomSentidoMejora" 
          onChange={(e) => onSentidoMejoraChange(props,e)}
          errors={errors[`KPIManagementList[${props.rowIndex}]`]}
        />  
      </div>
    )
  };

  const onSentidoMejoraChange = (props, e ) => {
    const saveArray= getCurrentRowValues(props);
    const updatedProducts = [...props.value];
    const sentidoMejora = SentidoMejoraList.filter(item => item.ClaSentidoMejora ===e.value)[0];
    if(sentidoMejora!==undefined){
      updatedProducts[props.rowIndex].NomSentidoMejora = sentidoMejora.NomSentidoMejora;
    }else{
      updatedProducts[props.rowIndex].NomSentidoMejora = null;
    }
    updatedProducts[props.rowIndex].ClaSentidoMejora = e.value;
    saveArray.ClaSentidoMejora = e.value;    
    setKPIManagementList(updatedProducts);
  };

  const onFrecuenciaProyectoChange = (props, e ) => {
    const saveArray= getCurrentRowValues(props);
    const updatedProducts = [...props.value];
    const frecuencia = ProyectoFrecuencia.filter(item => item.ClaProyectoFrecuenciaKPIManagement ===e.value)[0];
    if(frecuencia!==undefined){
      updatedProducts[props.rowIndex].NomProyectoFrecuenciaKPIManagement = frecuencia.NomProyectoFrecuenciaKPIManagement;
    }
    updatedProducts[props.rowIndex].ClaProyectoFrecuenciaKPIManagement = e.value;
    saveArray.ClaProyectoFrecuenciaKPIManagement = e.value;    
    setKPIManagementList(updatedProducts);
  };
  
  const ClaProyectoTipoKPIManagementEditor = ( props ) => {
    return (
      <div className="p-fluid">
        <FieldDropdown  
          name="NomProyectoTipoKPIManagement"
          value={props.rowData.ClaProyectoTipoKPIManagement}
          options={ProyectoManagement}  
          optionValue="ClaProyectoTipoKPIManagement"
          optionLabel="NomProyectoTipoKPIManagement" 
          onChange={(e) => onFrecuenciaManagementChange(props,e)}
          errors={errors[`KPIManagementList[${props.rowIndex}]`]}
        />  
      </div>
    )
  };

  const onFrecuenciaManagementChange = (props, e ) => {
    const saveArray= getCurrentRowValues(props);
    const updatedProducts = [...props.value];
    const management = ProyectoManagement.filter(item => item.ClaProyectoTipoKPIManagement ===e.value)[0];
    if(management!==undefined){
      updatedProducts[props.rowIndex].NomProyectoTipoKPIManagement = management.NomProyectoTipoKPIManagement;
    }
    updatedProducts[props.rowIndex].ClaProyectoTipoKPIManagement = e.value;
    saveArray.ClaProyectoTipoKPIManagement = e.value;    
    setKPIManagementList(updatedProducts);
  };
 
  const valitationScheme = yup.object().shape({
    selfReviewFeedback: yup.array().of(
        yup.object().shape({
          NomProyectoKPIManagement: yup.string().trim().required(t('common:RequiredField')).nullable(),
          DescUnidadMedidad: yup.string().trim().required(t('common:RequiredField')).nullable(),
          EstatusInicialProyecto: yup.string().trim().required(t('common:RequiredField')).nullable(),
          EstatusObjetivo: yup.string().trim().required(t('common:RequiredField')).nullable() 
          // NomProyectoTipoKPIManagement: yup.string().trim().required(t('common:RequiredField')).nullable() 
      }))
  });

  const valitationSchemeSave = yup.object().shape({     
    KPIManagementList: yup.array().of(
        yup.object().shape({   
          NomProyectoKPIManagement: yup.string().when('BajaLogica', {is: 0,
            then: yup.string().trim().required(t('common:RequiredField')).nullable(),
            otherwise: yup.string().nullable()
          }),
          DescUnidadMedidad: yup.string().when('BajaLogica', {is: 0,
            then: yup.string().trim().required(t('common:RequiredField')).nullable(),
            otherwise: yup.string().nullable()
          }),
          EstatusInicialProyecto: yup.string().when('BajaLogica', {is: 0,
            then: yup.string().trim().required(t('common:RequiredField')).nullable(),
            otherwise: yup.string().nullable()
          }),
          EstatusObjetivo: yup.string().when('BajaLogica', {is: 0,
            then: yup.string().trim().required(t('common:RequiredField')).nullable(),
            otherwise: yup.string().nullable()
          }),
          NomSentidoMejora: yup.string().when('BajaLogica', {is: 0,
            then: yup.string().trim().required(t('common:RequiredField')).nullable(),
            otherwise: yup.string().nullable()
          }),
          NomProyectoTipoKPIManagement: yup.string().when('BajaLogica', {is: 0,
            then: yup.string().trim().required(t('common:RequiredField')).nullable(),
            otherwise: yup.string().nullable()
          }),
          PorcPeso: yup.string().when('BajaLogica', {is: 0,
            then: yup.string().trim().required(t('common:RequiredField')).nullable(),
            otherwise: yup.string().nullable()
          }), 
        })
      )
  });

   const onManagementChange =(e)=>{
     const newErrors = {...errors}
     delete newErrors.Management;
     setErrors(newErrors);
     setManagement(e);
   }

  const  NomProyectoKPIManagementSchema = yup.object().shape({
    NomProyectoKPIManagement: yup.string()
                              .required(t('common:RequiredField'))
  });
  
  const fieldsRequired = (rowData, props) =>  {  
    return (
      <>
        {errors[`KPIManagementList[${props.rowIndex}]`] != null ? (<i className="fas fa-exclamation-triangle invalid-color"></i>) : ""}
      </>
    );
  }

  const DefaultColumnTemplate = (rowData, props, columnName) => {
    
    if (errors[`KPIManagementList[${props.rowIndex}]`] !== undefined && 
       errors[`KPIManagementList[${props.rowIndex}]`][columnName] !== undefined
    )
    {
      return (
        <div className="p-invalid-column">
          { rowData[columnName] }  
        </div>
      );
    }
    return (
      <>
        { rowData[columnName] }  
      </>
    );
  }

  const headerKPIManagement =(
    <>
      <Row>
        <Col>
          {t('kpisManagement:KPIManagementList')}
        </Col>
        <Col className="text-right">
          {
            props.readOnly === true ?
            (<></>)
            :
            (
              <FieldButton className="p-button p-button-icon-only p-button-info rounded-button" onClick={onNewClick}>
                <i className="pi pi-plus"></i>
              </FieldButton>
            )
          }  
        </Col>
      </Row>
        
    </>
      );
  
    const PorcPesoEditor = (props) => {
        return (
          <div className="p-fluid">
            <FieldNumber
              name="PorcPeso"
              value={props.rowData.PorcPeso}
              onChange={(e) => onPorcPesoChange(props, e.target.value)}
              max={100}
              errors={errors[`KPIManagementList[${props.rowIndex}]`]}
            />
          </div>
        );
      };
  
  const onClickViewDetailKPI = (IdProyectoKPIManagement) => {
    setIdProyectoKPIManagementSeleccionado(IdProyectoKPIManagement);
    setEsMostrarKPISDetalle(true);
    tracker.trackEvent({
      type: 'KpisManagementTable', /* component name */
      action: 'VIEW_KPI_TARGETS_DETAIL'
    }) 
  }

  const onClickCfgFormulaKPI = (row) => {
    setIdProyectoKPIManagementSeleccionado(row.IdProyectoKPIManagement);
    setEsMostrarCfgFormula(true);
    setIdKPIFormulaSelected(row.IdKPIFormula);
    setKPIDescriptionSelected(row.NomProyectoKPIManagement);
    tracker.trackEvent({
      type: 'KpisManagementTable', /* component name */
      action: 'CONFIG_FORMULA_KPI'
    }) 
  }

  

  const targetByMonthTemplate = (rowData) => {
    
    // return (<><i className='fas fa-edit light-gray-color' title='View / Edit Monthly Target'></i></>);

    if (rowData.IdProyectoKPIManagement === null){
      return (<><i className='fas fa-edit light-gray-color' style={{cursor:'not-allowed'}} title='Option editable once KPI is saved'></i></>); 
    }

    return (<><i className='fas fa-edit light-blue-color' onClick={() => onClickViewDetailKPI(rowData.IdProyectoKPIManagement)} title='View / Edit Monthly Target'></i></>);
  } 
 
  const cfgFormulaTemplate = (rowData) => {
    
    // return (<><i className='fas fa-edit light-gray-color' title='View / Edit Monthly Target'></i></>);

    if (rowData.IdProyectoKPIManagement === null){
      return (<><i className='fas fa-calculator light-gray-color' style={{cursor:'not-allowed'}} title='Option editable once KPI is saved'></i></>); 
    }

    if (rowData.IdKPIFormula !== null){
      return (<><i className='fas fa-calculator info-color' onClick={() => onClickCfgFormulaKPI(rowData)} title='Edit Formula'></i></>);
    }

    return (<><i className='fas fa-calculator light-gray-color' onClick={() => onClickCfgFormulaKPI(rowData)} title='Click here to create a formula for this KPI'></i></>);
  } 

  const onFormulaCreated = (formula) => { 
     
    const RelacionarFormulaKPIProyecto = `${config.UrlApiProject}ScoreCard/RelacionarFormulaKPIProyecto`;  
    const paramsToService = {  
         IdKPIFormula: formula.IdKPIFormula,
         IdProyectoKPIManagement : IdProyectoKPIManagementSeleccionado
    };   
    
    callApi(RelacionarFormulaKPIProyecto, 'POST', paramsToService, (response) => {  
       showSweetAlert('success', t('common:SuccessOperation'), 'success'); 
       setEsMostrarCfgFormula(false);
       setIdProyectoKPIManagementSeleccionado(null);
       setIdKPIFormulaSelected(null);
       obtenerInformacionKPIManagement(); 
    });
    
  }

  const onFormulaRemoved = (formula) => { 
     
    const RelacionarFormulaKPIProyecto = `${config.UrlApiProject}ScoreCard/RelacionarFormulaKPIProyecto`;  
    const paramsToService = {  
         IdKPIFormula: null,
         IdProyectoKPIManagement : IdProyectoKPIManagementSeleccionado
    };   
    
    callApi(RelacionarFormulaKPIProyecto, 'POST', paramsToService, (response) => {  
       showSweetAlert('success', t('common:SuccessOperation'), 'success'); 
       setEsMostrarCfgFormula(false);
       setIdProyectoKPIManagementSeleccionado(null);
       setIdKPIFormulaSelected(null);
       obtenerInformacionKPIManagement(); 
    });
    
  }

  const footer  = ( 
    <> 
      <Row> 
        <Column></Column>  
        <Column></Column>  
        <Column></Column>  
        <Column></Column>  
        <Column></Column>  
        <Column></Column>  
        <Column></Column>  
        <Column></Column>   
        <Column></Column>   
        <Column footer={() => (<b> {t('common:Total')}</b>)}></Column>  
        <Column style={{textAlign:'right'}} footer={() => (<b>{ (KPIManagementList.reduce((a,v) =>  a += v.PorcPeso === null ? 0 : v.PorcPeso , 0 )).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) }</b>)}></Column>             
      </Row>
    </>
  )

  const dt = useRef(null);  
  return (
    <>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12} className="text-right">   
          {
            props.readOnly === true ?
            (<></>)
            :
            (
              <FieldButton label={t('common:Save')} icon="pi pi-save" className="p-button-success p-mr-2 no-border rounded-button" onClick={onSaveClick} /> 
        
            )
          }   
        </Col> 
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12} className="text-right"> 
          <i className='danger-color'>* Total Weight must be 100%</i>
        </Col> 
      </Row>   
      <FieldDataTable 
        value={KPIManagementList}
        scrollable 
        scrollHeight="330px" 
        className="editable-cells-table"  
        editMode="cell"
        selectionMode="single"
        header={headerKPIManagement}
        footerColumnGroup={footer}
      >
        
        <Column body={(rowData, props) => fieldsRequired(rowData, props)} headerStyle={{ width: '20px' }}></Column>
        <Column body={(rowData, props) => actionBodyTemplate(rowData, props)} headerStyle={{ width: '35px' }}></Column>
        <Column field="IdProyectoKPIManagement" header={t('kpisManagement:IdProyectoKPIManagement')} columnKey="IdProyectoKPIManagement" style={{ display :'none' }} headerStyle={{ width: '200px', display :'none' }}></Column> 
        <Column
          field="NomProyectoKPIManagement"
          header={t('kpisManagement:KPIsImpact')}
          headerStyle={{ width: '180px' }} 
          body={(rowData, props) => DefaultColumnTemplate(rowData, props, 'NomProyectoKPIManagement')}
          editor={(props) => NomProyectoKPIManagementEditor(props)}
        >
        </Column>
        <Column
          field="DescUnidadMedidad"
          header={t('kpisManagement:UoM')}  
          body={(rowData, props) => DefaultColumnTemplate(rowData, props, 'DescUnidadMedidad')}
          headerStyle={{ width: '100px' }}
          columnKey="DescUnidadMedidad"
          editor={(props) => DescUnidadMedidadEditor(props)}
        >
        </Column>
        <Column
          field="EstatusInicialProyecto"
          header={t('kpisManagement:Actual')}  
          body={(rowData, props) => DefaultColumnTemplate(rowData, props, 'EstatusInicialProyecto')}
          headerStyle={{ width: '75px' }}
          columnKey="EstatusInicialProyecto" 
          editor={(props) => EstatusInicialProyectoEditor(props)}
        >
        </Column>
        <Column
          field="EstatusObjetivo"   
          body={(rowData, props) => DefaultColumnTemplate(rowData, props, 'EstatusObjetivo')}
          header={t('kpisManagement:Target')}
          headerStyle={{ width: '70px' }}
          columnKey="EstatusObjetivo"
          editor={(props) => EstatusObjetivoEditor(props)}
        >
        </Column>
        <Column 
          header=""  
          body={targetByMonthTemplate}
          headerStyle={{ width: '30px' }} 
        >
        </Column>
        <Column 
          header=""  
          body={cfgFormulaTemplate}
          headerStyle={{ width: '30px' }} 
        >
        </Column>
        
        <Column field="EstatusFinal" header={t('kpisManagement:Final')} headerStyle={{ width: '70px' }} columnKey="EstatusFinal" editor={(props) => EstatusFinalEditor(props)}></Column>
        <Column
          field="NomSentidoMejora" 
          body={(rowData, props) => DefaultColumnTemplate(rowData, props, 'NomSentidoMejora')}
          header={t('kpisManagement:Flow')}
          headerStyle={{ width: '90px' }}
          columnKey="ClaSentidoMejora"
          editor={(props) => ClaSentidoMejoraEditor(props)}
        >
        </Column>

        <Column
          field="NomProyectoTipoKPIManagement" 
          body={(rowData, props) => DefaultColumnTemplate(rowData, props, 'NomProyectoTipoKPIManagement')}
          header={t('kpisManagement:Management')}
          headerStyle={{ width: '110px' }}
          columnKey="ClaProyectoTipoKPIManagement"
          editor={(props) => ClaProyectoTipoKPIManagementEditor(props)}
        >
        </Column>
        <Column field="NomRefSeguimientoKPIManagement" header={t('kpisManagement:Name')} headerStyle={{ width: '200px' }} columnKey="NomRefSeguimientoKPIManagement" editor={(props) => NomRefSeguimientoKPIManagementEditor(props)}></Column>
        <Column field="NomUsuario" header={t('kpisManagement:Responsible')} headerStyle={{ width: '230px' }} columnKey="ClaUsuarioResponsable" editor={(props) => ClaResponsableEditor(props)}></Column>
        <Column
          field="PorcPeso"
          header={t('kpisManagement:Peso')}
          headerStyle={{ width: '75px' }}
          columnKey="PorcPeso"
          body={(rowData, props) => DefaultColumnTemplate(rowData, props, 'PorcPeso')}
          editor={(props) => PorcPesoEditor(props)}
        >
        </Column>
        {
          /*
            <Column field="NomProyectoFrecuenciaKPIManagement" header={t('kpisManagement:Frecuency')} headerStyle={{ width: '110px' }} columnKey="ClaProyectoFrecuenciaKPIManagement" editor={(props) => ClaProyectoFrecuenciaKPIManagementEditor(props)}></Column>
          */
        }
      </FieldDataTable>
      {
          esMostrarKPISDetalle ?
          (<KpisManagementDetTable IdProyectoKPIManagement={IdProyectoKPIManagementSeleccionado} IsVisible={esMostrarKPISDetalle} onClose={() => setEsMostrarKPISDetalle(false)}></KpisManagementDetTable>
          ):
          (<></>)
        }

      {
        esMostrarCfgFormula && 
        (  
          <KPIFormulaSetUp show={esMostrarCfgFormula} onHide={() => setEsMostrarCfgFormula(false)} Description={KPIDescriptionSelected} onFormulaCreated={onFormulaCreated} onFormulaRemoved={onFormulaRemoved} IdKPIFormula={IdKPIFormulaSelected}></KPIFormulaSetUp>
        )
      }
    </>
  );
};

export default KpisManagementTable;



/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PageContent from 'layouts/PageContent';
import { Toolbar } from 'primereact/toolbar';
import FieldButton from 'components/Controls/FieldButton/FieldButton.jsx';
import { Row, Col } from 'reactstrap';
import { Panel } from 'primereact/panel';
import * as yup from 'yup';
import YupValidator from 'utils/YupValidator';
import FieldDropdown from 'components/Controls/FieldDropdown.jsx';
import { config } from 'utils/config';
import { callApi, getSessionItem, showSweetAlert } from 'utils/utils';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx';
import { Column } from 'primereact/column';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable.jsx';
import moment from 'moment';

const VisitLog = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['visitLog', 'common']);
  const [filters, setfilters] = useState({});
  const [errors, setErrors] = useState({});
  const [FechaInicio, setFechaInicio] = useState(null);
  const [FechaFin, setFechaFin] = useState(null); 
  const [IdEncuesta, setIdEncuesta] = useState(null);
  const [ClaUbicacion, setClaUbicacion] = useState(null);
  const [OpcionesUbicacion, setOpcionesUbicacion] = useState([]);
  const [RegistroVisitanteList, setRegistroVisitanteList] = useState([]);

  useEffect(() => {
    inicializaPantalla();
  }, []);

  const inicializaPantalla = () => {
    const date = new Date();
    date.setDate(date.getDate() - 30); 

    setFechaInicio(date);
    setFechaFin(new Date());
    obtenerUbicacion();
  };

  const ExportExcelSurvey = () => {
    const ExportarSurvey = `${config.UrlApiProject}VisitLog/ExportarVisitLog`;
    const paramsService = {
      IdEncuesta,
      IsCultureSurvey: 1,
    };
    callApi(ExportarSurvey, 'POST', paramsService, (response) => {
      const linkSource = `data:application/${response.data.FileExtension};base64,${response.data.FileData}`;
      const downloadLink = document.createElement('a');
      const fileName = `${response.data.FileName}`;
      downloadLink.href = linkSource;
      downloadLink.download = `${fileName}.${response.data.FileExtension}`;
      downloadLink.click();
    });
  };

  const obtenerUbicacion = () => {
    const urlWebService = `${config.UrlApiProject}VisitLog/GetMswUbicacionCmb`;
    const paramsToService = {};
    callApi(urlWebService, 'GET', paramsToService, (response) => {
      setOpcionesUbicacion(response.data[0]);
      console.log(response.data);
    });
  };

  const valitationScheme = yup.object().shape({
    FechaInicio: yup.date().required(t('')).nullable(),
    FechaFin: yup.date().required(t('')).nullable(),
  });

  const onApplyFilters = async () => {
    const value = {
      FechaInicio,
      FechaFin,
    };

    const validator = new YupValidator(valitationScheme);
    const esValido = await validator.validate(value);
    if (!esValido) {
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');

      return;
    }

    const urlWebService = `${config.UrlApiProject}VisitLog/GetRegistroVisitante`;
    const paramsToService = {
      ClaUbicacion,
      FechaEntrada: FechaInicio,
      FechaSalida: FechaFin,
      Accion: 1,
    };
    callApi(urlWebService, 'POST', paramsToService, (response) => {
        setRegistroVisitanteList(response.data.RegistroVisitante);
        setIdEncuesta(response.data.RegistroVisitante.length > 0 ? response.data.RegistroVisitante[0].IdEncuesta : null);
    });
  };

  const onChangeUbicacion = (e) => {
    const newErrors = { ...errors };
    delete newErrors.ClaUbicacion;
    setErrors(newErrors);
    setClaUbicacion(e.value);
  };

  const onChangeFechaInicio = (props, e) => {
    const newErrors = { ...errors };
    delete newErrors.FechaInicio;
    setErrors(newErrors);
    setFechaInicio(e.target.value);
  };
  const onChangeFechaFin = (props, e) => {
    const newErrors = { ...errors };
    delete newErrors.FechaFin;
    setErrors(newErrors);
    setFechaFin(e.target.value);
  };

  const FechaEntrada = (rowData) => {
    return (
      <>
        <div>{moment(rowData.FechaEntrada).format('MM/DD/YYYY')}</div>
      </>
    );
  };

  const FechaSalida = (rowData) => {
    return (
      <>
        <div>{moment(rowData.FechaSalida).format('MM/DD/YYYY')}</div>
      </>
    );
  };

  const filterHeaderTemplate = (options) => {
    const toggleIcon = options.collapsed
      ? 'pi pi-chevron-down text-white'
      : 'pi pi-chevron-up text-white';
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className="card-header">
        <div className="p-toolbar p-component">
          <div className="p-toolbar-group-left">
            <div className="card-title">
              <FieldButton className={options.togglerClassName} onClick={options.onTogglerClick}>
                <span className={toggleIcon}></span>
              </FieldButton>
              <span className={titleClassName}>{t('common:Filters')}</span>
            </div>
          </div>

          <div className="p-toolbar-group-right">
            <FieldButton
              label={t('common:Search')}
              icon="pi pi-search"
              className="p-button p-highlight"
              onClick={onApplyFilters}
            >
            </FieldButton>
            &nbsp; &nbsp;
            <FieldButton
              label={t('myactivities:Export')}
              icon="fa fa-file-excel"
              className="p-button p-button-rounded p-button-icon-only p-button-secondary"
              onClick={ExportExcelSurvey}
            >
            </FieldButton>
          </div>
        </div>
      </div>
    );
  };

  const NomTipoReunionTemplate = (rowData) => {
    return (
      <>
        <div className="overflow-ellipsis" title={rowData.NomTipoReunion}>
          {rowData.NomTipoReunion}
        </div>
      </>
    );
  };

  return (
    <>
      <PageContent title={t('visitLog:VisitLogHistory')}>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Panel header={t('common:Filters')} toggleable headerTemplate={filterHeaderTemplate}>
              <Row>
                <Col lg={3} md={3} sm={12} xs={12} className="p-3">
                  <div className="p-fluid">
                    <FieldDropdown
                      name="ClaUbicacion"
                      label={t('visitLog:Location')}
                      value={ClaUbicacion}
                      options={OpcionesUbicacion}
                      optionValue="ClaUbicacion"
                      optionLabel="NomUbicacion"
                      onChange={(e) => onChangeUbicacion(e)}
                      errors={errors}
                    />
                  </div>
                </Col>
                <Col lg={4} md={5} sm={12} xs={12} className="p-3">
                  <div className="p-float-label" style={{ top: -9 }}>
                    <label>{t('visitLog:MeetingDate')}</label>
                  </div>
                  <div className="p-fluid">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">{t('common:From')}</span>
                      <FieldCalendar
                        name="FechaInicio"
                        value={FechaInicio}
                        className="no-round no-round-btn-icon"
                        onChange={(e) => onChangeFechaInicio(props, e)}
                        errors={errors}
                      />
                      <span className="p-inputgroup-addon">{t('common:To')}</span>
                      <FieldCalendar
                        name="FechaFin"
                        value={FechaFin}
                        className="no-round"
                        onChange={(e) => onChangeFechaFin(props, e)}
                        errors={errors}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            &nbsp;
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <FieldDataTable
              value={RegistroVisitanteList}
              scrollable
              className="editable-cells-table"
              editMode="cell"
              selectionMode="single"
            >
              <Column
                field="NomUbicacion"
                header={t('visitLog:Location')}
                columnKey="NomUbicacion"
                headerStyle={{ width: '150px' }}
              >
              </Column>
              <Column
                field="GuestName"
                header={t('visitLog:GuestName')}
                columnKey="GuestName"
                headerStyle={{ width: '180px' }}
              >
              </Column>             
              <Column
                field="CheckedIn"
                header={t('visitLog:CheckIn')}
                columnKey="CheckedIn"
                headerStyle={{ width: '110px' }}
              >
              </Column>
              <Column
                field="CheckOut"
                header={t('visitLog:CheckOut')}
                columnKey="CheckOut"
                headerStyle={{ width: '110px' }}
              >
              </Column>
            </FieldDataTable>
          </Col>
        </Row>
      </PageContent>
    </>
  );
};

export default VisitLog;

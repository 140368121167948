/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next';   
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row,Col} from 'reactstrap';   
import { config } from '../../../utils/config';
import { callApi, showSweetAlert, tracker} from '../../../utils/utils'; 
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon' 
import FieldText from 'components/Controls/FieldText';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx' 
import FieldDropdown from 'components/Controls/FieldDropdown.jsx' 
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import moment from "moment"; 
import swal from '@sweetalert/with-react';
import PageContent from 'layouts/PageContent';
import { Toolbar } from 'primereact/toolbar';
import KpisManagementTable from './KpisManagementTable';
import FieldTextArea from 'components/Controls/FieldTextArea';
import FieldAutoComplete from '../../../components/Controls/FieldAutoComplete.jsx';
import FieldToogleSwitch from '../../../components/Controls/FieldToogleSwitch/FieldToogleSwitch.jsx';
import { Dialog } from 'primereact/dialog';
import ProjectAttachDocument from './ProjectAttachDocument.jsx';

const CaratulaProyecto = (props) => {
   /* ################## useState area start ################## */
   const { t } = useTranslation(['caratulaproyecto', 'common']);
   const [filters, setfilters] = useState({});   
   const [ClaProyecto, setClaProyecto] = useState(props.ClaProyecto === undefined ? 0 : props.ClaProyecto);
   const [PrioridadProyecto, setPrioridadProyecto] = useState({}); 
   const [LineaEstrategica, setLineaEstrategica] = useState({}); 
   const [IniciativaEstrategica, setIniciativaEstrategica] = useState({}); 
   const [TipoProyecto, setTipoProyecto] = useState({}); 
   const [IndicadorIniciativa, setIndicadorIniciativa] = useState({}); 
   const [Departamento, setDepartamento] = useState({});
   const [Area, setArea] = useState({});
   const [ProyectoCaratula, setProyectoCaratula] = useState([]);
   const [filteredResponsible, setfilteredResponsible] = useState([]);
   const [ClaLineaEstrategica, setClaLineaEstrategica]= useState(null);
   const [ClaIniciativaEstrategica, setClaIniciativaEstrategica]= useState(null);
   const [ClaTipoProyecto, setClaTipoProyecto]= useState(null);
   const [ClaIndicador, setClaIndicador]= useState(null);
   const [ClaPrioridad, setClaPrioridad]= useState(null);
   const [ClaDepartamento, setClaDepartamento]= useState(null);
   const [ClaArea, setClaArea]= useState(null);
   const [ClaColaborador, setClaColaborador] = useState(null);   
   const [ClaUsuarioResponsableOportunidad, setClaUsuarioResponsableOportunidad] = useState(null);
   const [Comentarios, setComentarios] = useState(null);
   const [SituacionEsperada, setSituacionEsperada] = useState(null);
   const [SituacionActual, setSituacionActual] = useState(null);
   const [Detonante, setDetonante] = useState(null);
   const [Objetivo, setObjetivo] = useState(null);
   const [Justificacion, setJustificacion] = useState(null);
   const urlWebServiceSaveCaratulaProyecto = `${config.UrlApiProject}ScoreCard/SaveCaratulaProyecto`;
   const [errors, setErrors] = useState({}); 
   const [hdInitiative, sethdInitiative] = useState(1);
   const [EstatusProyecto, setEstatusProyecto] = useState([]); 
   const [ClaEstatusProyecto, setClaEstatusProyecto]= useState(null);
   const [yesNoOptions, setYesNoOptions] = useState([`${t('common:On')}`, `${t('common:Off')}`]);
   const [IsNewOverviewAttachmentVisible, setIsNewOverviewAttachmentVisible] = useState(false);


   const [EsAdminScorecard, setEsAdminScorecard] = useState(0);
   const [EsOrdenarFaseManual, setEsOrdenarFaseManual] = useState(false);
   
   

   useEffect(() => {   
    LlenadoCombos();
    obtenerInformacionProyectoCaratula();    
  }, []); 

   const leftToolbarTemplate = () => {
    return (
      <></>
      )
  }
  const obtenerInformacionProyectoCaratula = () => { 
    const GetProyectoCaratula = `${config.UrlApiProject}ScoreCard/GetProyectoCaratula?ClaProyecto=${ClaProyecto}`;  
    const paramsToService = {  
    };   
    callApi(GetProyectoCaratula, 'POST', paramsToService, (response) => {
      let colaborador = null;

      if (response.data.ProyectoCaratula.length > 0){
        if (response.data.ProyectoCaratula[0].ClaColaborador !== null){
          colaborador = {
            ClaColaborador: response.data.ProyectoCaratula[0].ClaColaborador,
            NomColaborador: `${response.data.ProyectoCaratula[0].ClaveColaborador} - ${response.data.ProyectoCaratula[0].NomColaborador}`
          };
        }
        
      }
 

    
      setProyectoCaratula(response.data.ProyectoCaratula);    
      setClaDepartamento(response.data.ProyectoCaratula[0].ClaDepartamento);
      setClaArea(response.data.ProyectoCaratula[0].ClaArea);
      setClaLineaEstrategica(response.data.ProyectoCaratula[0].ClaLineaEstrategica);
      setClaIniciativaEstrategica(response.data.ProyectoCaratula[0].ClaIniciativaEstrategica);
      setClaTipoProyecto(response.data.ProyectoCaratula[0].ClaTipoProyecto);
      setClaIndicador(response.data.ProyectoCaratula[0].ClaIndicador);
      setClaPrioridad(response.data.ProyectoCaratula[0].ClaPrioridadProyecto);
      setComentarios(response.data.ProyectoCaratula[0].Comentarios);
      setSituacionActual(response.data.ProyectoCaratula[0].SituacionActual);
      setSituacionEsperada(response.data.ProyectoCaratula[0].SituacionEsperada);
      setDetonante(response.data.ProyectoCaratula[0].Detonante);
      setObjetivo(response.data.ProyectoCaratula[0].Objetivo);
      setJustificacion(response.data.ProyectoCaratula[0].Justificacion);
      setClaEstatusProyecto(response.data.ProyectoCaratula[0].ClaEstatusProyecto);
      setClaColaborador(colaborador);
      setEsAdminScorecard(response.data.ProyectoCaratula[0].EstatusEditable === 1);
      setEsOrdenarFaseManual(response.data.ProyectoCaratula[0].EsOrdenarFaseManual === 1);
      setErrors({});
      ObtenerIniciativaEstrategica(response.data.ProyectoCaratula[0].ClaLineaEstrategica);
      obtenerIndicadorIniciativa(response.data.ProyectoCaratula[0].ClaTipoProyecto);
      
    });
  }
  const LlenadoCombos = () => {   
     
    obtenerPrioridadProyecto();
    obtenerTipoProyecto();
    
    ObtenerLineaEstrategica();
    // ObtenerIniciativaEstrategica();
    ObtenerDepartamento();
    ObtenerArea(ClaDepartamento);
    obtenerEstatusProyecto();
  }  
  const obtenerPrioridadProyecto = () => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/MSWBSCPrioridadProyecto`;  
    const paramsToService = { 
      
    };   
    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => { 
      if (response.data[0].length >0)
      {  
        setPrioridadProyecto(response.data[0]);        
      }  
    });
  }

  const obtenerTipoProyecto = () => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/MSWBSCTipoProyecto`;  
    const paramsToService = { 
      
    };   
    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => { 
      if (response.data[0].length >0)
      {  
        setTipoProyecto(response.data[0]);
      }  
    });
  }

  const obtenerEstatusProyecto = () => { 
    const GetEstatusProyectoCmb = `${config.UrlApiProject}ScoreCard/GetEstatusProyectoCmb`;  
    const paramsToService = { 
      
    };   
    callApi(GetEstatusProyectoCmb, 'GET', paramsToService, (response) => {  
        setEstatusProyecto(response.data.EstatusProyecto); 
    });
  }

  const obtenerIndicadorIniciativa = (pnClaTipoProyecto) => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/MSWBSCIndicadorIniciativa`;  
    const paramsToService = { 
      
    };   
    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => {  
        setIndicadorIniciativa(response.data[0]);
         
        if (pnClaTipoProyecto === 1 || pnClaTipoProyecto === 4 || pnClaTipoProyecto === 5 || pnClaTipoProyecto === 6) {
          sethdInitiative(0);          
        } 
    });
  }
  const ObtenerLineaEstrategica = () => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/MSWBSCLineaEstrategica`;  
    const paramsToService = { 
      
    };   
    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => { 
      if (response.data[0].length >0)
      {  
        setLineaEstrategica(response.data[0]);    
      }  
    });
  }
  const ObtenerIniciativaEstrategica = (pnClaLineaEstrategica) => { 
    
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/MSWBSCIniciativaEstrategica?LineaEstrategicaFilter=${pnClaLineaEstrategica === null ? 0 : pnClaLineaEstrategica}`;  
    const paramsToService = { 
      
    };   
    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => { 
      if (response.data[0].length >0)
      {  
        setIniciativaEstrategica(response.data[0]);        
      }  
    });
  }
  const ObtenerDepartamento = () => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/MSWBSCDepartamento`;  
    const paramsToService = { 
      
    };   
    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => { 
      if (response.data[0].length >0)
      {  
        setDepartamento(response.data[0]);
      }  
    });
  }
  const ObtenerArea = (pClaDepartamento) => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/MSWBSCArea`;  
    const paramsToService = { 
      ClaDepartamento: pClaDepartamento
    };   
    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => { 
      if (response.data[0].length >0)
      {  
        console.log(response.data[0]);
        setArea(response.data[0]);
      }  
    });
  }
  const onPrioridadProyectoChange = (value) => {  
    setPrioridadProyecto(value);
  };
  const onTipoProyectoChange = (value) => {  
    setTipoProyecto(value);
  };
  const onIndicadorIniciativaChange = (value) => {  
    setIndicadorIniciativa(value);
  };    
  const filterResponsible = (event) => {
    let valor = ''; 
    if (ClaColaborador !== null && ClaColaborador !== '' &&  event.query === "")
    {
     valor = ClaColaborador.NomColaborador;
    }
    else{
     valor = event.query;
    }
    const paramsServiceOwners = {};
    // event.query 
    const urlWebServiceOwners = `${config.UrlApiProject}ScoreCard/GetColaboradorProyectosCmb?descriptionFilter=${valor}`;
    // Get owner
    callApi(urlWebServiceOwners, 'GET', paramsServiceOwners, (response) => {  
       setfilteredResponsible(response.data[0]);
    });    
    
  }
 const onChangeClaUsuarioResponsable = (e) => {  
   const newErrors = { ...errors }
   delete newErrors.ClaColaborador;   
   setErrors(newErrors)
  setClaColaborador(e.value)
}

const onChangeClaDepartamento = (e) => {  
  const newErrors = { ...errors }
  delete newErrors.ClaDepartamento;   
  setErrors(newErrors)
 setClaDepartamento(e.value);
 setClaArea(null);
 ObtenerArea(e.value);
}

const onSaveClick = async () => { 
  setErrors({});
  let paramsServiceOwners = {
    ClaColaborador,
    ClaProyecto,
    ClaDepartamento,
    ClaArea,
    ClaLineaEstrategica,
    ClaIniciativaEstrategica,
    ClaTipoProyecto,
    ClaIndicador,
    Objetivo,
    ClaPrioridad,
    Detonante,
    Justificacion,
    SituacionActual,
    SituacionEsperada,
    Comentarios,
    ClaEstatusProyecto, 
    EsOrdenarFaseManual: EsOrdenarFaseManual ? 1 : 0
  };
  const validator = new YupValidator(valitationScheme);
  const esValido = await validator.validate(paramsServiceOwners);
  
  if (!esValido){   
    setErrors(validator.errors);  
    showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
   
    return;
  }

  
  paramsServiceOwners = {
    ClaColaborador : ClaColaborador.ClaColaborador,
    ClaProyecto,
    ClaDepartamento,
    ClaArea,
    ClaLineaEstrategica,
    ClaIniciativaEstrategica,
    ClaTipoProyecto,
    ClaIndicador,
    Objetivo,
    ClaPrioridad,
    Detonante,
    Justificacion,
    SituacionActual,
    SituacionEsperada,
    Comentarios,
    ClaEstatusProyecto, 
    EsOrdenarFaseManual: EsOrdenarFaseManual ? 1 : 0
  };

  tracker.trackEvent({
    type: 'CaratulaProyecto', /* component name */
    action: 'UPDATE_PROJECT_OVERVIEW'
  }) 

  callApi(urlWebServiceSaveCaratulaProyecto, 'POST', paramsServiceOwners, (response) => {  
    showSweetAlert('success', t('common:SuccessOperation'), 'success');
    obtenerInformacionProyectoCaratula();
    if (props.onChange !== null && props.onChange !== 'undefined' && typeof(props.onChange) === 'function'){
      props.onChange();
    }
 });  
}
const onChangeClaLineaEstrategica = (e) => {  
  // const newErrors = { ...errors }
  // delete newErrors.ClaColaborador;   
  // setErrors(newErrors)
  setClaLineaEstrategica(e.value);
  ObtenerIniciativaEstrategica(e.value);
  setClaIniciativaEstrategica(null);

}
const onChangeClaTipoProyecto = (e) => {  
  // const newErrors = { ...errors }
  // delete newErrors.ClaColaborador;   
  // setErrors(newErrors)
  setClaTipoProyecto(e.value);
  sethdInitiative(1);
  if(e.value === 1 || e.value === 4 || e.value === 5){
    sethdInitiative(0);
  }
}
const onChangeObjetivo = (e) => {   
  const newErrors = { ...errors }
  delete newErrors.Objetivo;   
  setErrors(newErrors);
  setObjetivo(e.value);  
}

const onOpenNewOverviewAttachment = () => {  
  setIsNewOverviewAttachmentVisible(true);
  tracker.trackEvent({
    type: 'CaratulaProyecto', /* component name */
    action: 'ADD_NEW_ATTACHMENT'
  }) 
};

const onCloseNewOverviewAttachment = () => {  
  setIsNewOverviewAttachmentVisible(false);
};

const valitationScheme = yup.object().shape({
  Objetivo: yup.string().required(t('common:RequiredField')).nullable(), 
  // ClaIniciativaEstrategica: yup.string().required(t('common:RequiredField')).nullable(), 
  ClaDepartamento: yup.string().required(t('common:RequiredField')).nullable(), 
  ClaArea: yup.string().required(t('common:RequiredField')).nullable(), 
  ClaColaborador: yup.object({ ClaColaborador: yup.string().required(t('common:RequiredField')) }).required(t('common:RequiredField')).nullable(), 
  ClaTipoProyecto: yup.string().required(t('common:RequiredField')).nullable(), 
  ClaPrioridad: yup.string().required(t('common:RequiredField')).nullable(), 
  Detonante: yup.string().required(t('common:RequiredField')).nullable(), 
  ClaEstatusProyecto: yup.mixed().required(t('common:RequiredField')).nullable(), 
});

    return (
      <>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="text-right">   
            {
                  props.readOnly === true ? (<></>)
                  : 
                  (
                    <>
                      <FieldButton label={t('caratulaproyecto:AttachNewDocument')} icon="pi pi-plus" className="p-button-info no-border p-mr-2 rounded-button" onClick={onOpenNewOverviewAttachment}  /> 
                      <FieldButton label={t('common:Save')} icon="pi pi-save" className="p-button-success p-mr-2 no-border rounded-button" onClick={onSaveClick} />
                    </>                  
                  )
            }
          </Col> 
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
          &nbsp;
          </Col> 
        </Row>
        <Row>
          <Col lg={6} md={6} sm={12} xs={12}> 
            <div className="p-fluid"> 
              <FieldAutoComplete
                dropdown
                name="ClaColaborador"
                label={t('caratulaproyecto:Responsible')}
                value={ClaColaborador} 
                onChange={onChangeClaUsuarioResponsable}  
                suggestions={filteredResponsible}
                field="NomColaborador"
                completeMethod={filterResponsible}
                errors={errors}
                disabled={props.readOnly === true}
              /> 
            </div>           
          </Col>
          <Col lg={3} md={3} sm={12} xs={12}> 
            <div className="p-fluid">
              <FieldDropdown  
                name="ClaDepartamento" 
                label={t('caratulaproyecto:Departamento')}
                value={ClaDepartamento} 
                options={Departamento}  
                optionValue="ClaDepartamento"
                optionLabel="NomDepartamento" 
                onChange={onChangeClaDepartamento}
                errors={errors}
                disabled={props.readOnly === true}
              />  
            </div>
          </Col>
          <Col lg={3} md={3} sm={12} xs={12}> 
            <div className="p-fluid">
              <FieldDropdown  
                name="ClaArea" 
                label={t('caratulaproyecto:Area')}
                value={ClaArea} 
                options={Area}  
                optionValue="ClaArea"
                optionLabel="NomArea" 
                onChange={(e) => setClaArea(e.value)}
                errors={errors}
                disabled={props.readOnly === true}
              />  
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
          &nbsp;
          </Col> 
        </Row>
        { !EsAdminScorecard ? (<></>) : 
        (
          <Row>
            <Col lg={6} md={6} sm={12} xs={12}>   
              <div className="p-fluid">
                <FieldDropdown  
                  name="ClaLineaEstrategica" 
                  label={t('caratulaproyecto:LineaEstrategica')}
                  value={ClaLineaEstrategica} 
                  options={LineaEstrategica}  
                  optionValue="ClaLineaEstrategica"
                  optionLabel="NomLineaEstrategica" 
                  onChange={(e) => onChangeClaLineaEstrategica(e)}
                  disabled={props.readOnly === true ? true : EsAdminScorecard?"":"disabled"}
                />  
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>   
              <div className="p-fluid">
                <FieldDropdown  
                  name="ClaIniciativaEstrategica" 
                  label={t('caratulaproyecto:IniciativaEstrategica')}
                  value={ClaIniciativaEstrategica} 
                  options={IniciativaEstrategica}  
                  optionValue="ClaIniciativaEstrategica"
                  optionLabel="NomIniciativaEstrategica" 
                  onChange={(e) => setClaIniciativaEstrategica(e.value)}
                  errors={errors}
                  disabled={props.readOnly === true ? true : EsAdminScorecard?"":"disabled"}
                />  
              </div>
            </Col> 
          </Row>
        )}
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
          &nbsp;
          </Col> 
        </Row>
        <Row>
          <Col lg={6} md={6} sm={12} xs={12}>   
            <div className="p-fluid">
              <FieldDropdown  
                name="ClaTipoProyecto" 
                label={t('caratulaproyecto:TipoProyecto')}
                value={ClaTipoProyecto} 
                options={TipoProyecto}  
                optionValue="ClaTipoProyecto"
                optionLabel="NomTipoProyecto" 
                onChange={(e) => onChangeClaTipoProyecto(e)}
                errors={errors}
                disabled="disabled"
              />  
            </div>
          </Col>
          {  hdInitiative === 0 ? (
            <>
              <Col lg={6} md={6} sm={12} xs={12}>   
                <div className="p-fluid">
                  <FieldDropdown  
                    name="ClaIndicador" 
                    label={t('caratulaproyecto:Indicador')}
                    value={ClaIndicador} 
                    options={IndicadorIniciativa}  
                    optionValue="ClaIndicador"
                    optionLabel="NomIndicador" 
                    onChange={(e) => setClaIndicador(e.value)} 
                    disabled={props.readOnly === true ? true : EsAdminScorecard?"":"disabled"}
                  />  
                </div>
              </Col>  
            </>
                ) : (<></>)}
        </Row> 
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
          &nbsp; &nbsp; &nbsp;
          </Col> 
        </Row>
        <Row className="align-items-start">
          <Col lg={6} md={6} sm={12} xs={12}>  
            
            <div className="p-fluid"> 
              <FieldTextArea 
                name="Objetivo"
                rows={5}
                placeholder=''
                maxLength={1500}
                value={Objetivo}
                onChange={(e) => onChangeObjetivo(e.target)}
                label={t('caratulaproyecto:Objetivo')}
                errors={errors}
                disabled={props.readOnly === true}
              /> 
            </div> 
          </Col> 
          <Col lg={6} md={6} sm={12} xs={12}> 
            <Row>
              <Col>
                <div className="p-fluid">
                  <FieldDropdown  
                    name="ClaPrioridad" 
                    label={t('caratulaproyecto:PrioridadProyecto')} 
                    value={ClaPrioridad} 
                    options={PrioridadProyecto}  
                    optionValue="ClaPrioridadProyecto"
                    optionLabel="NomPrioridadProyecto" 
                    onChange={(e) => setClaPrioridad(e.value)}
                    errors={errors}
                    disabled={props.readOnly === true}
                  />  
                </div>
              </Col>
            </Row>  
            <Row>
              <Col> 
          &nbsp;
              </Col> 
            </Row>
            <Row>
              <Col lg={8} md={6} sm={12} xs={12}>
                <div className="p-fluid">
                  <FieldDropdown  
                    name="ClaEstatusProyecto" 
                    label={t('caratulaproyecto:EstatusProyecto')} 
                    value={ClaEstatusProyecto} 
                    options={EstatusProyecto}  
                    optionValue="ClaEstatusProyecto"
                    optionLabel="NomEstatusProyecto" 
                    onChange={(e) => setClaEstatusProyecto(e.value)}
                    errors={errors}
                    disabled={props.readOnly === true ? true : EsAdminScorecard?"":"disabled"}
                  />  
                </div>
              </Col>
              <Col lg={4} md={6} sm={12} xs={12}>
                <span>{t('caratulaproyecto:OrdenarFaseManual')}</span> 
                &nbsp;
                <FieldToogleSwitch
                  id="EsOrdenarFaseManual"
                  checked={EsOrdenarFaseManual}
                  onChange={setEsOrdenarFaseManual} 
                  optionLabels={yesNoOptions}
                  small
                  disabled={props.readOnly === true}
                />
              </Col>
            </Row>
            
          </Col> 
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
          &nbsp;
          </Col> 
        </Row>
        <Row>
          <Col lg={6} md={6} sm={12} xs={12}>   
            <label>{t('caratulaproyecto:RequestedBy')}  </label> 
            <div className="p-fluid"> 
              <FieldTextArea 
                name="Detonante"
                rows={5}
                placeholder=''
                maxLength={250}
                value={Detonante}
                onChange={(e) => setDetonante(e.target.value)}
                errors={errors}
                disabled={props.readOnly === true}
              /> 
            </div>
          </Col> 
          <Col lg={6} md={6} sm={12} xs={12}>   
            <label>{t('caratulaproyecto:Reason')}  </label> 
            <div className="p-fluid"> 
              <FieldTextArea 
                name="Reason"
                rows={5}
                placeholder=''
                maxLength={1500}
                value={Justificacion}
                onChange={(e) => setJustificacion(e.target.value)}
                disabled={props.readOnly === true}
              /> 
            </div>
          </Col> 
        </Row> 
        <Row>
          <Col lg={6} md={6} sm={12} xs={12}>   
            <label>{t('caratulaproyecto:SituacionActual')}  </label> 
            <div className="p-fluid"> 
              <FieldTextArea 
                name="SituacionActual"
                rows={5}
                placeholder=''
                maxLength={1500}
                value={SituacionActual}
                onChange={(e) => setSituacionActual(e.target.value)}
                disabled={props.readOnly === true}
              /> 
            </div>
          </Col> 
          <Col lg={6} md={6} sm={12} xs={12}>   
            <label>{t('caratulaproyecto:SituacionEsperada')}  </label> 
            <div className="p-fluid"> 
              <FieldTextArea 
                name="SituacionEsperada"
                rows={5}
                placeholder=''
                maxLength={1500}
                value={SituacionEsperada}
                onChange={(e) => setSituacionEsperada(e.target.value)}
                disabled={props.readOnly === true}
              /> 
            </div>
          </Col> 
        </Row> 
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
            <label>{t('caratulaproyecto:Comentarios')}  </label> 
            <div className="p-fluid"> 
              <FieldTextArea 
                name="Comentarios"
                rows={5}
                placeholder=''
                maxLength={1500}
                value={Comentarios}
                onChange={(e) => setComentarios(e.target.value)}
                disabled={props.readOnly === true}
              /> 
            </div>
          </Col>           
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
          &nbsp;
          </Col> 
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="text-right">   
            {
                  props.readOnly === true ? (<></>)
                  :
                  (
                    <>
                      <FieldButton label={t('caratulaproyecto:AttachNewDocument')} icon="pi pi-plus" className="p-button-info no-border p-mr-2 rounded-button" onClick={onOpenNewOverviewAttachment}  /> 
                      <FieldButton label={t('common:Save')} icon="pi pi-save" className="p-button-success p-mr-2 no-border rounded-button" onClick={onSaveClick} />
                    </>                    
                  )
            } 
          </Col> 
        </Row>        
        <Dialog
          visible={IsNewOverviewAttachmentVisible}
          header={t('scoreCard:AttachNewDocument')}
          style={{ width: '80vw' }} 
          modal 
          onHide={onCloseNewOverviewAttachment}
        >
          <ProjectAttachDocument 
            ClaProyecto={ClaProyecto} 
            ClaTipoDocumentoProyectoRep={6}
            onClose={onCloseNewOverviewAttachment}
          >
          </ProjectAttachDocument>
        </Dialog>    
      </>
      );
 
};
export default CaratulaProyecto;

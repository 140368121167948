/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next';  
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Row,Col, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';   
import { config } from '../../../utils/config';
import { callApi,showSweetAlert, tracker } from '../../../utils/utils';  
import FieldText from '../../../components/Controls/FieldText.jsx' 
import FieldCheckbox from '../../../components/Controls/FieldCheckbox.jsx' 
import swal from '@sweetalert/with-react';
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon'
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import moment from "moment"; 
import { Tooltip } from 'primereact/tooltip';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { Dialog } from 'primereact/dialog'; 
import ProjectAttachDocument from "./ProjectAttachDocument.jsx";

const Documentos = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['scoreCard', 'common']);
  const [ClaProyecto, setClaProyecto] = useState(props.ClaProyecto === undefined ? 0 : props.ClaProyecto); 
  const [TextoFilter, setTextoFilter] = useState(null);
  const [OpcionesTipoDocumento, setOpcionesTipoDocumento] = useState([]);
  const [ProyectoRepActTerminadaList, setProyectoRepActTerminadaList] = useState([]); 
  const [layout, setLayout] = useState('grid');
  const [IsAttachProjectDocument, setIsAttachProjectDocument] = useState(false);

  useEffect(() => {    
    obtenerTipoDocumentos();
  }, []); 

  const obtenerTipoDocumentos = () => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/GetMSWTipoDocumentoProyectoCmb`;  
    const paramsToService = {  };   
    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => { 
      const TiposDocumentosData = response.data[0];
      if (TiposDocumentosData !== undefined)
      {  
        setOpcionesTipoDocumento(TiposDocumentosData)
      }
      obtenerInformacionFasesProyecto();
    });
  }

  const SelectedTiposDocumento = (rows) => {
    let selectedString = "";
    if (rows.length>0) {
      rows.forEach((item) => {
        if (item.EsSeleccionado) {
          selectedString += `${item.ClaTipoDocumentoProyectoRep  },`;
        }
      });
    } else {
      return "";
    }
    return selectedString; 
  };

  const obtenerInformacionFasesProyecto = () => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/GetProyectoRepActTerminadaDocumentos`;  
    const paramsToService = { 
      ClaProyecto,
      TextoBusqueda:TextoFilter,
      TiposDocumento:SelectedTiposDocumento(OpcionesTipoDocumento)
    };   
 
    
    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => { 
      const AnexosData = response.data.Anexos;
      if (AnexosData !== undefined)
      {  
        setProyectoRepActTerminadaList(AnexosData)
      }
    }); 
  }

 const downloadFile = (rowData) => { 
  const GetFileData = `${config.UrlApiProject}ScoreCard/GetProyectoRepActTerminadaId`;  
  const paramsToService = { 
    IdProyectoRepActTerminada:rowData.IdProyectoRepActTerminada,
    ClaTipoDocumentoProyectoRep: rowData.ClaTipoDocumentoProyectoRep
  };   
  
  tracker.trackEvent({
    type: 'Documentos', /* component name */
    action: 'DOWNLOAD_ATTACHMENT'
  }) 

  callApi(GetFileData, 'POST', paramsToService, (response) => {
    const AnexoDetalle = response.data.AnexoDetalle[0];
    if(AnexoDetalle!==undefined){
      const linkSource = `data:application/${rowData.TipoExtension};base64,${AnexoDetalle.FileData}`;
      const downloadLink = document.createElement("a");
      const fileName = `${rowData.NomArchivo}`;
      downloadLink.href = linkSource;
      downloadLink.download = `${fileName  }.${  rowData.TipoExtension}`;
      downloadLink.click();
    }
  });
}

 const onConfirmRemoveSubmit = (nIdProyectoRepActTerminada) => {  
  swal({ 
    buttons: {
      cancelar: {
        text: "Cancel",
        value: "cancel",
        className: "rounded-button danger"
      },
      continuar: {
        text: "Continue",
        value: "continue",
        className: "rounded-button light-green"
      }
    },
    content: confirmationMessageHTML
  }).then((value) => {
    switch (value) { 
      case "continue":
        // run function
        tracker.trackEvent({
          type: 'Documentos', /* component name */
          action: 'REMOVE_ATTACHMENT'
        }) 

        onSubmit(nIdProyectoRepActTerminada);
        break; 
      default:
        break;
    }
  }); 
}

const onSubmit = async (nIdProyectoRepActTerminada) => { 
  const paramsService = {
    IdProyectoRepActTerminada:nIdProyectoRepActTerminada
 };   
 const SubmitBajaProyectoRep = `${config.UrlApiProject}ScoreCard/BajaProyectoRepActTerminada`;
 callApi(SubmitBajaProyectoRep, 'POST', paramsService, (response) => { 
  obtenerInformacionFasesProyecto(); 
 });   
} 

const onTextoFilterChange = (value) => {  
  setTextoFilter(value);
}

const onTipoDocumentoSelected = (e,index) => {  
   const opciones = [...OpcionesTipoDocumento];
   opciones[index].EsSeleccionado = e.checked;
   setOpcionesTipoDocumento(opciones);
}

const sClase = (nTipoDocumento) => {
  let sclass = '';
  switch (nTipoDocumento) {
    case 2:
      sclass = 'success'; 
      break;
    case 3:
      sclass = 'info'; 
      break;
    case 4:
      sclass = 'orange'; 
      break; 
    case 5:
      sclass = 'light-yellow'; 
      break;
    case 6:
      sclass = 'light-gray'; 
      break;
    default:
    sclass = 'default'; 
  }
  return (
    sclass
  );
}

const sColor = (nTipoDocumento) => {
  let sclass = '';
  switch (nTipoDocumento) {
    case 2:
      sclass = '#28a745'; 
      break;
    case 3:
      sclass = '#17a2b8'; 
      break;
    case 4:
      sclass = '#ff8248'; 
      break;
    case 5:
      sclass = '#fcd96f'; 
      break;
    case 6:
      sclass = '#c0c0c0'; 
      break;      
    default:
      sclass = '#9A9A9B'; 
  }
  return (
    sclass
  );
}

const confirmationMessageHTML = (
  <div>
    <SweatAlertCustomIcon title={t('common:SubmitConfirmation')} letter={t('common:QuestionMark')}></SweatAlertCustomIcon> 
    <div>{t('scoreCard:SubmitRemoveDocument')}. </div> 
  </div>  
)

const renderListItem = (data) => {
  return (
    <div className="p-col-12">
      <div style={{borderLeft: `solid 4px ${sColor(data.ClaTipoDocumentoProyectoRep)}`}} className="product-list-item">
        <div className="product-list-detail">
          <div className="product-name pb-1">{data.NomActividad}</div>
          <div className="product-description">{data.Comentarios}</div>
          <span className="product-category">{ `${data.NomArchivo}.${data.TipoExtension}` }</span>
        </div>
        <div className="product-list-action">
          <Row>
            <Col>
              <small>{t('scoreCard:Uploadedby')} </small>
                &nbsp;
              <small className="bold"> {data.NomResponsableActividad} </small>
            </Col>
          </Row>
          <Row>
            <Col>
              <small>{t('scoreCard:LastFileUploaded')} &nbsp; </small>
              <span className="pi pi-calendar bold">  </span>&nbsp; 
              <small> {moment(data.FechaUpdate).format("ll")}</small>
            </Col>
          </Row> 
          <Row className="pt-2">
            <Col className="text-right"> 
              {
                /*
                  <Tooltip target=".p-button-default" mouseTrack mouseTrackTop={10} />
                
                  <FieldButton
                    hidden={true}
                    type="button" 
                    data-pr-position="top" 
                    data-pr-tooltip={data.Comentarios}
                    icon="pi pi-comment"
                    className="p-button-rounded p-button-default p-button-outlined"
                  />  
                */
              }
              
              <Tooltip target=".p-button-info" mouseTrack mouseTrackTop={10} />
              <FieldButton
                type="button" 
                onClick={() => downloadFile(data)}
                data-pr-position="top" 
                data-pr-tooltip={t('scoreCard:Download')}
                icon="pi pi-paperclip"
                className="p-button-rounded p-button-info p-button-outlined"
              /> 
              &nbsp;
              <Tooltip target=".p-button-danger" mouseTrack mouseTrackTop={10} />
              <FieldButton
                type="button" 
                onClick={() => onConfirmRemoveSubmit(data.IdProyectoRepActTerminada)}
                data-pr-position="top" 
                data-pr-tooltip={t('scoreCard:Remove')}
                icon="pi pi-trash"
                className="p-button-rounded p-button-danger p-button-outlined"
                hidden={data.ClaTipoDocumentoProyectoRep === 5}
              /> 
              &nbsp;
            </Col>
          </Row>
        </div>
      </div>
      <div className="pb-2"></div>
    </div>
  );
}

const renderGridItem = (data) => {
  return (
    <div className="p-col-12 p-md-4">
      <Card>
        <CardHeader className={sClase(data.ClaTipoDocumentoProyectoRep)}>
          <CardTitle>
            <Row>
              <Col>
                {data.Origin} 
              </Col> 
            </Row>
          </CardTitle>
        </CardHeader>    
        <CardBody>   
          <Row>
            <Col className="bold">
              {data.NomActividad} 
            </Col>
          </Row>
          <Row>
            <Col>
              <small>{data.Comentarios}</small>
            </Col>
          </Row>
          <Row className="pt-3 pb-1">
            <Col lg={2} md={2} sm={2} xs={12}>
              <Tooltip target=".p-button-info" mouseTrack mouseTrackTop={10} />
              <FieldButton
                type="button" 
                onClick={() => downloadFile(data)}
                data-pr-position="top" 
                data-pr-tooltip={t('scoreCard:Download')}
                icon="pi pi-paperclip"
                className="p-button-rounded p-button-info p-button-outlined"
              /> 
            </Col>
            <Col lg={8} md={8} sm={8} xs={12}>
              <Row>
                <Col>
                  <small className="bold">
                    { `${data.NomArchivo}.${data.TipoExtension}` }
                  </small>
                </Col>
              </Row>
              <Row>
                <Col>
                  <small>{t('scoreCard:Uploadedby')} </small>
                                      &nbsp;
                  <small className="bold"> {data.NomResponsableActividad} </small>
                </Col>
              </Row>
              <Row>
                <Col>
                  <small>{t('scoreCard:LastFileUploaded')} &nbsp; </small>
                  <span className="pi pi-calendar"></span> &nbsp; 
                  <small>{moment(data.FechaUpdate).format("ll")} </small>
                </Col>
              </Row> 
            </Col>
            <Col lg={2} md={2} sm={2} xs={12}>
              {
                /*
                  <Row className="pb-2">
                    <Col>
                      <Tooltip target=".p-button-default" mouseTrack mouseTrackTop={10} />
                      <FieldButton
                        type="button" 
                        data-pr-position="top" 
                        data-pr-tooltip={data.Comentarios}
                        icon="pi pi-comment"
                        className="p-button-rounded p-button-default p-button-outlined"
                      /> 
                    </Col>
                  </Row> 
                */
              }
              
              <Row>
                <Col> 
                  <Tooltip target=".p-button-danger" mouseTrack mouseTrackTop={10} />
                  <FieldButton
                    type="button" 
                    onClick={() => onConfirmRemoveSubmit(data.IdProyectoRepActTerminada)}
                    data-pr-position="top" 
                    data-pr-tooltip={t('scoreCard:Remove')}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-button-outlined"
                    hidden={data.ClaTipoDocumentoProyectoRep === 5}
                  /> 
                </Col>
              </Row> 
            </Col>
          </Row> 
        </CardBody>
      </Card>  
    </div>
  );
}

const itemTemplate = (product, layout) => {
  if (!product) {
      return;
  }
  if (layout === 'list')
      return renderListItem(product);
  if (layout === 'grid')
      return renderGridItem(product);
}

const renderHeader = () => {
  return (
    <div className="p-grid p-nogutter">
      <div className="p-col-6" style={{textAlign: 'left'}}>
        &nbsp;
      </div>
      <div className="p-col-6" style={{textAlign: 'right'}}>
        <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />
      </div>
    </div>
  );
}

const header = renderHeader();

const filterHeaderTemplate = (options) => {
  const toggleIcon = options.collapsed ? 'pi pi-chevron-down text-white' : 'pi pi-chevron-up text-white';
  const className = `${options.className} p-jc-start`;
  const titleClassName = `${options.titleClassName} p-pl-1`;

  return (
    <div className="card-header">
      <div className="p-toolbar p-component">
        <div className="p-toolbar-group-left">
          <div className="card-title">
            <FieldButton className={options.togglerClassName} onClick={options.onTogglerClick}>
              <span className={toggleIcon}></span>
              <Ripple />
            </FieldButton>
            <span className={titleClassName}>
              {t('common:Filters')}
            </span>
          </div>  
        </div>
       
        <div className="p-toolbar-group-right">
          <FieldButton className="p-button p-button-icon-only p-highlight " onClick={obtenerInformacionFasesProyecto}>
            <i className="pi pi-search"></i>
          </FieldButton>  
          &nbsp;
          <FieldButton label={t('scoreCard:AttachNewDocument')} icon="pi pi-plus" className="light-green no-border p-mr-2" onClick={onClickNewDocument}  /> 
         
        </div>
      </div> 
    </div>
  )
}

const onClickNewDocument = () => {
  setIsAttachProjectDocument(true);

  tracker.trackEvent({
    type: 'Documentos', /* component name */
    action: 'ADD_NEW_ATTACHMENT'
  }) 
}

const onCloseAttachDocument = () => {
  setIsAttachProjectDocument(false);
  obtenerTipoDocumentos();
}

    return (
      <>     
        <Panel header="Filters" toggleable headerTemplate={filterHeaderTemplate}> 
          <Row className="align-items-start pb-3">
            <Col lg={4} md={4} sm={12} xs={12}> 
              <Row className="pb-2">
                <Col>
                  <div className="p-fluid"> 
                    <FieldText
                      value={TextoFilter} 
                      placeholder={t('scoreCard:FilterByText')}
                      onChange={(e) => onTextoFilterChange(e.target.value)} 
                      autoFocus
                    />  
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12} className="text-right"> 
              <Row className="align-items-start bold">
                {OpcionesTipoDocumento.map((element, index) => {  
                    return (  
                      <Col lg={4} key={index}> 
                        <Row className="align-items-start">
                          <Col>
                            <div className="p-grid small">
                              <div className="p-col-2">
                                <div style={{backgroundColor:`${sColor(element.ClaTipoDocumentoProyectoRep)}`}}>
                              &nbsp;
                                </div> 
                              </div>
                              <div className="p-col-10 text-left">
                                <label key={index} className="bold">
                                  <FieldCheckbox
                                    inputId={element.ClaTipoDocumentoProyectoRep}
                                    checked={element.EsSeleccionado} 
                                    onChange={(e) => onTipoDocumentoSelected(e,index)}
                                    label={element.NomTipoDocumentoProyectoRep}
                                  />
                                  &nbsp;
                                  &nbsp;
                                  {element.NomTipoDocumentoProyectoRep}
                                </label> 
                              </div> 
                            </div>
                          </Col>
                        </Row>
                      </Col> 
                    );
                })}  
              </Row>
            
            </Col>  
          </Row>
        </Panel>  
        <Row className="align-items-start">
          <Col>   
            <DataView
              value={ProyectoRepActTerminadaList}
              layout={layout}
              header={header}
              itemTemplate={itemTemplate}
              paginator
              rows={25}
              className="dataview-list"
            />
          </Col> 
        </Row>
        <Dialog
          visible={IsAttachProjectDocument}
          header={t('scoreCard:AttachNewDocument')}
          style={{ width: '80vw' }} 
          modal 
          onHide={onCloseAttachDocument}
        >
          <ProjectAttachDocument 
            ClaProyecto={props.ClaProyecto} 
            ClaTipoDocumentoProyectoRep={1}
            onClose={onCloseAttachDocument}
          >
          </ProjectAttachDocument>
        </Dialog>          
        
      </> 
        ); 
};
export default Documentos;
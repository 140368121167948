/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import React,{ useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardBody, Col,
  Row
} from 'reactstrap';
import { idpVersions } from './IndividualDevelopmentPlanConstants.js';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import FieldDropdown from '../../../components/Controls/FieldDropdown.jsx';
import PageContent from '../../../layouts/PageContent';
import { config } from '../../../utils/config';
import { callApi } from '../../../utils/utils';
import IndividualDevelopmentPlanDetailDialog from './IndividualDevelopmentPlanDetailDialog';
import IndividualDevelopmentPlanList from './IndividualDevelopmentPlanList';

const IndividualDevelopmentPlanScreen = (props) => {  
  const { t } = useTranslation(['hr', 'common']);      
  const [performanceDevelopmentInfo, setPerformanceDevelopmentInfo] = useState([]);  
  const [ClaAnio, setClaAnio] = useState(null);
  const [Anio, setAnio] = useState(null);
  const [TotalDependientesDirectos, setTotalDependientesDirectos] = useState(null);
  const [EstatusAnnualReview, setEstatusAnnualReview] = useState(null);
  const [ShowDetailDialog, setShowDetailDialog] = useState(false);
  const [Refresh, setRefresh] = useState(0);

  useEffect(() => {     
    loadTeamPerformanceDevelopment();   
  }, [props.Anio]); 

  const loadTeamPerformanceDevelopment = () => { 
    const GetPerformanceDevelopmentByUserList = `${config.UrlApiProject}hr/GetPerformanceDevelopmentByUserList`;  
    const paramsToService = { };   
    callApi(GetPerformanceDevelopmentByUserList, 'GET', paramsToService, (response) => { 
      setPerformanceDevelopmentInfo(response.data.PerformanceDevelopmentInfo);  
      if (response.data.PerformanceDevelopmentInfo.length>0){
        onAnioChange(response.data.PerformanceDevelopmentInfo[response.data.PerformanceDevelopmentInfo.length-1].Anio, response.data.PerformanceDevelopmentInfo);
      }
    });
  }

  const onAnioChange = (value, options) => {
    setClaAnio(value);   
    const selectedYear = options.filter( opc => opc.Anio === value); 
    const element = selectedYear.length > 0 ? selectedYear[0] : {};  

    setAnio(element.Anio);
    setTotalDependientesDirectos(element.TotalDependientesDirectos);
    setEstatusAnnualReview(element.EstatusAnnualReview);
  };

  const onAdd = () => {
    setShowDetailDialog(true);
  };

  const onDetailDialogHide = () => {
    setShowDetailDialog(false);
  };

  const onNewIDPSaved = () => {
    setRefresh(Refresh + 1);
    setShowDetailDialog(false);
  };

  const onNewIDPStatusSaved = () => {
    setRefresh(Refresh + 1);
    setShowDetailDialog(false);
  };

  const panelHeaderTemplate = (options) => { 
      const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
      const className = `${options.className} ${options.collapsed ? ' collapsed-header' : ''}`;
      const titleClassName = `${options.titleClassName} p-pl-1`;

      return (
        <div className={className}> 
          <span className={titleClassName}> 
            <FieldDropdown 
              name="Anio"
              value={ClaAnio} 
              className="border-none background-none title info-dark-color"
              options={performanceDevelopmentInfo} 
              onChange={(e) => onAnioChange(e.value, performanceDevelopmentInfo)} 
              optionValue="Anio"
              optionLabel="Anio" 
              placeholder={t('common:SelectYear')} 
              showClear={false}
            /> 
          </span>
          <div className="p-panel-icons">
            <FieldButton className={options.togglerClassName} onClick={options.onTogglerClick}>
              <span className={toggleIcon}></span>
              <Ripple />
            </FieldButton>
          </div>          
        </div>
      )
  }

  const mySelfIDPTableTemplate = (
    <>
      <Row>
        <Col>
          <h1>{t('hr:Myself')}</h1>
        </Col>
        { 
          Anio === moment().year() && (                             
            <Col className='text-right'>
              <FieldButton 
                className="p-button p-button-success" 
                onClick={onAdd}
              >
                <i className="pi pi-plus"></i>&nbsp;
                {t('hr:NewIDP')}
              </FieldButton> 
            </Col>          
          )
        }               
      </Row>   
      <Row>
        <Col>
          <IndividualDevelopmentPlanList
            year={Anio}
            refresh={Refresh}
            type="M"
          />                            
        </Col>  
      </Row>      
    </>    
  );

  const teamIDPTableTemplate = (
    <>
      <Row>
        <Col>
          <h1>{t('hr:Team')}</h1>                          
        </Col>
      </Row>   
      <Row>
        <Col>
          <IndividualDevelopmentPlanList
            year={Anio}
            refresh={Refresh}
            type="T"
          />                        
        </Col>  
      </Row>           
    </>    
  );

  const newIDPDialogTemplate = (
    <>
      <IndividualDevelopmentPlanDetailDialog
        isVisible={ShowDetailDialog}
        version={idpVersions.V2}
        isNew={true}        
        idIDP={null}
        year={Anio}
        onHide={onDetailDialogHide}        
        onSaved={onNewIDPSaved}
        onStatusSaved={onNewIDPStatusSaved}
      />           
    </>    
  );

  if (Anio === null)
  {
    return (<></>);
  }   

  return (
    <>  
      <PageContent title={t('hr:IndividualDevelopmentPlan')}>  
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>    
            <Panel key={`panel-${0}`} headerTemplate={panelHeaderTemplate} header={Anio} className="panel-skyblue mb-4" collapsed={false} toggleable>
              <Row className="align-items-start mb-4">
                <Col lg={12} md={12} sm={12} xs={12}>  
                  <Card> 
                    <CardBody>                      
                      <Row className="align-items-start mb-4">
                        <Col lg={TotalDependientesDirectos === 0? 12 : 6} md={12} sm={12} xs={12}>                     
                          {mySelfIDPTableTemplate}  
                        </Col> 
                        { 
                          TotalDependientesDirectos > 0 && (
                            <>
                              <Col lg={TotalDependientesDirectos === 0? 12 : 6} md={12} sm={12} xs={12}>  
                                {teamIDPTableTemplate}                                                            
                              </Col>                             
                            </>
                          )
                        }
                      </Row> 
                    </CardBody>
                  </Card>
                </Col>                                          
              </Row>  
            </Panel>  
          </Col> 
        </Row>        
        {newIDPDialogTemplate}
      </PageContent>
    </>  
  );
};

export default IndividualDevelopmentPlanScreen;
import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardHeader, CardBody, CardFooter, Label } from 'reactstrap';
import { callApi, showSweetAlert, formatDate } from "utils/utils";
import { config } from 'utils/config';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import ViewPODFilters from './ViewPODFilters';
import PDFViewer from 'components/Controls/PDFViewer/PDFViewer';
import ViewPODDetails from './ViewPODDetails';
// import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import { Panel } from 'primereact/panel';

const PODStatistics = (props) => {
  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
  ];

  const d = new Date();

  const {t, i18n} = useTranslation(['pod', 'common']);

  const [emailSeries, setEmailSeries] = useState([]);
  const [emailDomains, setEmailDomains] = useState([]);
  const [deaceroSeries, setDeaceroSeries] = useState([]);
  const [deaceroTypes, setDeaceroTypes] = useState([]);
  const [mcnSeries, setMCNSeries] = useState([]);
  const [mcnTypes, setMCNTypes] = useState([]);
  const [mcnMonthSeries, setMCNMonthSeries] = useState([]);
  const [mcnMonthTypes, setMCNMonthTypes] = useState([]);
  const [deaceroMonthSeries, setDeaceroMonthSeries] = useState([]);
  const [deaceroMonthTypes, setDeaceroMonthTypes] = useState([]);
  const [showDeaByMonth, setShowDeaByMonth] = useState(true);
  const [showMCSWByMoth, setShowMCSWByMonth] = useState(true);
  const [companySeries, setCompanySeries] = useState([]);
  const [companyNames, setCompanyNames] = useState([]);
  const [companyMonths, setCompanyMonths] = useState([]);
  const [dstSeries, setDSTSeries] = useState([]);
  const [dstTypes, setDSTTypes] = useState([]);
  const [dstMonthSeries, setDSTMonthSeries] = useState([]);
  const [dstMonthTypes, setDSTMonthTypes] = useState([]);
  const [showDSTByMonth, setShowDSTByMonth] = useState(true);

  useEffect(() => {
    getStats();
  }, []);

  const getStats = () => {
    const urlWebService = `${config.UrlApiProject}POD/GetPODProcessingStats`;

    const paramsService = {
    };

    callApi(urlWebService, 'GET', paramsService, (response) => {
      console.log(response.data);

      setEmailDomains(response.data.Emails.domains);
      setEmailSeries(response.data.Emails.series);

      setDeaceroTypes(response.data.Deacero.types);
      setDeaceroSeries(response.data.Deacero.series);

      setDeaceroMonthTypes(response.data.DeaceroByMonth.types);
      setDeaceroMonthSeries(response.data.DeaceroByMonth.series);

      setMCNTypes(response.data.MCN.types);
      setMCNSeries(response.data.MCN.series);

      setMCNMonthTypes(response.data.MCNByMonth.types);
      setMCNMonthSeries(response.data.MCNByMonth.series);

      setCompanySeries(response.data.CompaniesYear.series);
      setCompanyMonths(response.data.CompaniesYear.months);

      setDSTTypes(response.data.DSTYear.types);
      setDSTSeries(response.data.DSTYear.series);

      setDSTMonthTypes(response.data.DSTMonth.types);
      setDSTMonthSeries(response.data.DSTMonth.series);
    });
  }

  // Email Chart
  const emailChartOptions = {
      chart: {
          type: 'column'
      },
      title: {
          text: t('pod:EmailAttachmentsProcessed'),
          align: 'center'
      },
      subtitle: {
        text:`${monthNames[d.getMonth()]} ${d.getFullYear() - 1} to ${monthNames[d.getMonth()]} ${d.getFullYear()}` 
      },
      xAxis: {
          categories: emailDomains,
          min: 0,
          max: 10,
          scrollbar: {
            enabled: true
          }
      },
      yAxis: {
          min: 0,
          title: {
              text: t('pod:AttachmentsProcessed')
          },
          stackLabels: {
              enabled: true,
              style: {
                  fontWeight: 'bold',
                  color: ( // theme
                      Highcharts.defaultOptions.title.style &&
                      Highcharts.defaultOptions.title.style.color
                  ) || 'gray',
                  textOutline: 'none'
              }
          }
      },
      tooltip: {
          headerFormat: '<b>{point.x}</b><br/>',
          pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
      },
      plotOptions: {
          column: {
              stacking: 'normal',
              dataLabels: {
                  enabled: true
              }
          }
      },
      series: emailSeries
  }

  // Deacero Chart
  const deaceroChartOptions = {
    chart: {
        type: 'column',
        height: '50%'
    },
    title: {
        text: t('pod:CxPInvoicesProcessed'),
        align: 'center'
    },
    subtitle: {
      text:`${monthNames[d.getMonth()]} ${d.getFullYear() - 1} to ${monthNames[d.getMonth()]} ${d.getFullYear()}` 
    },
    xAxis: {
        categories: showDeaByMonth === true ? deaceroMonthTypes : deaceroTypes
    },
    yAxis: {
        min: 0,
        title: {
            text: t('pod:InvoicesProcessed')
        },
        stackLabels: {
            enabled: true,
            style: {
                fontWeight: 'bold',
                color: ( // theme
                    Highcharts.defaultOptions.title.style &&
                    Highcharts.defaultOptions.title.style.color
                ) || 'gray',
                textOutline: 'none'
            }
        }
    },
    tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
    },
    plotOptions: {
        column: {
            stacking: 'normal',
            dataLabels: {
                enabled: true
            }
        }
    },
    series: showDeaByMonth === true ? deaceroMonthSeries : deaceroSeries
  }

    // DST Chart
    const dstChartOptions = {
      chart: {
          type: 'column',
          height: '50%'
      },
      title: {
          text: t('pod:DSTPODsProcessed'),
          align: 'center'
      },
      subtitle: {
        text:`${monthNames[d.getMonth()]} ${d.getFullYear() - 1} to ${monthNames[d.getMonth()]} ${d.getFullYear()}` 
      },
      xAxis: {
          categories: showDSTByMonth === true ? dstMonthTypes : dstTypes
      },
      yAxis: {
          min: 0,
          title: {
              text: t('pod:PODsProcessed')
          },
          stackLabels: {
              enabled: true,
              style: {
                  fontWeight: 'bold',
                  color: ( // theme
                      Highcharts.defaultOptions.title.style &&
                      Highcharts.defaultOptions.title.style.color
                  ) || 'gray',
                  textOutline: 'none'
              }
          }
      },
      tooltip: {
          headerFormat: '<b>{point.x}</b><br/>',
          pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
      },
      plotOptions: {
          column: {
              stacking: 'normal',
              dataLabels: {
                  enabled: true
              }
          }
      },
      series: showDSTByMonth === true ? dstMonthSeries : dstSeries
    }

  // MCN Chart
  const mcnChartOptions = {
    chart: {
        type: 'column',
        height: '50%'
    },
    title: {
        text: t('pod:MCSWInvoicesProcessed'),
        align: 'center'
    },
    subtitle: {
      text:`${monthNames[d.getMonth()]} ${d.getFullYear() - 1} to ${monthNames[d.getMonth()]} ${d.getFullYear()}` 
    },
    xAxis: {
        categories: showMCSWByMoth === true ? mcnMonthTypes : mcnTypes
    },
    yAxis: {
        min: 0,
        title: {
            text: t('pod:InvoicesProcessed')
        },
        stackLabels: {
            enabled: true,
            style: {
                fontWeight: 'bold',
                color: ( // theme
                    Highcharts.defaultOptions.title.style &&
                    Highcharts.defaultOptions.title.style.color
                ) || 'gray',
                textOutline: 'none'
            }
        }
    },
    tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
    },
    plotOptions: {
        column: {
            stacking: 'normal',
            dataLabels: {
                enabled: true
            }
        }
    },
    series: showMCSWByMoth === true ? mcnMonthSeries : mcnSeries
  }
  
  // Company Chart
  const companyChartOptions = {
    chart: {
        type: 'column'
    },
    title: {
        text: t('pod:PODsIdentifiedByCompany'),
        align: 'center'
    },
    subtitle: {
      text:`January ${d.getFullYear()} to ${monthNames[d.getMonth()]} ${d.getFullYear()}` 
    },
    xAxis: {
      categories: companyMonths
    },
    yAxis: {
        min: 0,
        title: {
            text: t('pod:PODsIdentified')
        },
        stackLabels: {
            enabled: true,
            style: {
                fontWeight: 'bold',
                color: ( // theme
                    Highcharts.defaultOptions.title.style &&
                    Highcharts.defaultOptions.title.style.color
                ) || 'gray',
                textOutline: 'none'
            }
        }
    },
    tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
    },
    plotOptions: {
        column: {
            stacking: 'normal',
            dataLabels: {
                enabled: true
            }
        }
    },
    legend: {
      enabled: true
    },
    series: companySeries
  }

  const headerTemplate = (options) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down text-white' : 'pi pi-chevron-up text-white';
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className="card-header">
        <div className="p-toolbar p-component">
          <div className="p-toolbar-group-left">
            <div className="card-title">
              <FieldButton className={options.togglerClassName} onClick={options.onTogglerClick}>
                <span className={toggleIcon}></span> 
              </FieldButton>
              <span className={titleClassName}>
                {t('pod:DocumentStatistics')}
              </span>
            </div>  
          </div>
          <div className="p-toolbar-group-right">
          </div>
        </div> 
      </div>
    )
  }

  return (
    <>
      <Row className='mt-2'>
        <Col xs={12} sm={12} md={12} lg={6}>
          <Card>
            <CardBody>
              <HighchartsReact highcharts={Highcharts} options={emailChartOptions} />
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6}>
          <Card>
            <CardBody>
              <HighchartsReact highcharts={Highcharts} options={companyChartOptions} />
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6}>
          <Card>
            <CardBody>
              <FieldButton
                label={showDeaByMonth === true ? 'Show By Year' : 'Show By Month'}
                onClick={() => setShowDeaByMonth(!showDeaByMonth)}
                className='mb-1 ml-4'
              />
              <HighchartsReact highcharts={Highcharts} options={deaceroChartOptions} />
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6}>
          <Card>
            <CardBody>
              <FieldButton
                label={showMCSWByMoth === true ? 'Show By Year' : 'Show By Month'}
                onClick={() => setShowMCSWByMonth(!showMCSWByMoth)}
                className='mb-1 ml-4'
              />
              <HighchartsReact highcharts={Highcharts} options={mcnChartOptions} />
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6}>
          <Card>
            <CardBody>
              <FieldButton
                label={showDSTByMonth === true ? 'Show By Year' : 'Show By Month'}
                onClick={() => setShowDSTByMonth(!showDSTByMonth)}
                className='mb-1 ml-4'
              />
              <HighchartsReact highcharts={Highcharts} options={dstChartOptions} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default PODStatistics;
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import PageContent from 'layouts/PageContent';
import { Dialog } from 'primereact/dialog';
import { React, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DonwTimeDetail from './DonwTimeDetail';
import queryString from 'query-string';
import { config } from 'utils/config';
import { callApi } from 'utils/utils'; 
import PrincipalHeader from '../Common/Header/PrincipalHeader.jsx';
import { useHistory, Link } from "react-router-dom"; 
import HeaderTablet from '../Common/Header/HeaderTablet';

const DownTimeScreen = (props) => {
  const {t, i18n} = useTranslation(['downTime', 'common']); 
  const params = queryString.parse(props.location.search); 
  const {IdCelda, ClaAreaDepartamento} = params;
  const homeScreenLink = useRef();
  const [ClaveArticulo, setClaveArticulo] = useState(null);
  const [ClaArticulo, setClaArticulo] = useState(null);
  const [NomArticulo, setNomArticulo] = useState(null);
  const [Cajas, setCajas] = useState(null);
  const [ClaTurno, setClaTurno] = useState(null);
  const [NomTurno, setNomTurno] = useState(null);
  const [ClaAreaProduccion, setClaAreaProduccion] = useState(null);
  const [NomAreaProduccion, setNomAreaProduccion] = useState(null);
  const [NomCelda, setNomCelda] = useState(null);  
  const [OperationCheckInList, setOperationCheckInList] = useState([]);
  const [ClaveCelda, setClaveCelda] = useState(null);
  const [ClaCelda, setClaCelda] = useState(null); 

  useEffect(() => { 
    OnloadDownTimecreen(IdCelda);
  }, []);

  let IsNewHomeVersion = false;
  let ClaMaquina = null;

  if(props.location!==undefined){
    const params = queryString.parse(props.location.search);
    IsNewHomeVersion = params !== undefined ? params.isNewHomeVersion : false; 
    ClaMaquina = params !== undefined ? params.ClaMaquina !== undefined ? params.ClaMaquina : null : null;
  }

  const onSubmitDowntime = () => {
    homeScreenLink.current.click();
  } 

  const OnloadDownTimecreen = (pIdCelda) => {  
    setClaveArticulo(null);
    const paramsService = {};
    const urlWebService = `${config.UrlApiProject}Operations/GetOperationOnloadDownTime?IdCelda=${pIdCelda}`;
    callApi(urlWebService, 'GET', paramsService, (response) => {
      const operationDownTimeHead =
        response.data.OperationDownTimeHead.length > 0 ? response.data.OperationDownTimeHead[0] : {};            
        setNomArticulo(operationDownTimeHead.NomArticulo);
        setClaArticulo(operationDownTimeHead.ClaArticulo);        
        setClaveArticulo(operationDownTimeHead.ClaveArticulo);    
        setCajas(operationDownTimeHead.Cajas);        
        setClaTurno(operationDownTimeHead.ClaTurno);
        setNomTurno(operationDownTimeHead.NomTurno);
        setClaAreaProduccion(operationDownTimeHead.ClaAreaProduccion);
        setNomAreaProduccion(operationDownTimeHead.NomAreaProduccion);        
        setNomCelda(operationDownTimeHead.NomCelda);
        setOperationCheckInList(response.data.OperationCheckIn);
        setClaveCelda(operationDownTimeHead.ClaveCelda);
        setClaCelda(operationDownTimeHead.ClaCelda);
    });
  };

  const subHeader = (  
    <HeaderTablet
      ClaTurno={ClaTurno}
      NomTurno={NomTurno}
      ClaAreaProduccion={ClaAreaProduccion}
      NomAreaProduccion={NomAreaProduccion}
      IdCelda={IdCelda}
      NomCelda={NomCelda}
      ClaveCelda={ClaveCelda}
    >
    </HeaderTablet> 
  )

  if (IsNewHomeVersion){
    return (
      <>
        <PrincipalHeader IsHomeScreen={false}></PrincipalHeader>
        <PageContent title={t('downTime:downTimeScreenTitle')} subheader={subHeader}>  
          <DonwTimeDetail IdCelda={IdCelda} ClaAreaDepartamento={ClaAreaDepartamento} onSubmitDowntime={onSubmitDowntime} ClaTurno={ClaTurno} IsNewHomeVersion={IsNewHomeVersion} ClaMaquina={ClaMaquina} />
          <Link 
            ref={homeScreenLink} 
            to={
                          { pathname: "CellHomeMenuScreen", search: `?IsDeviceAssignedOnCell=true` }
                  }
          >
          </Link>  
        </PageContent> 
      </>
    );
  }


  return (
    <>
      <PageContent title={t('downTime:downTimeScreenTitle')}>
        <DonwTimeDetail IdCelda={IdCelda} ClaAreaDepartamento={ClaAreaDepartamento} />
      </PageContent>
    </>
  )
}

export default DownTimeScreen;
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-this-in-sfc */

import React, { useState } from 'react';     
import { ColorPicker } from 'primereact/colorpicker';
import FieldText from './FieldText';
import FieldButton from './FieldButton/FieldButton';
import { Card, CardBody } from 'reactstrap'

const FieldColorPicker =  (props) => {
    const [showColorPicker, setShowColorPicker] = useState(false);

    const hexRegex = new RegExp(String.raw`^#[0-9a-fA-F]{6}$`);
    const buttonBackground = hexRegex.test(props.value) ? props.value : '#0d89ec';

    const getIconColor = () => {
      if (hexRegex.test(props.value)) {
        const r = parseInt(props.value.substring(1,3),16);
        const g = parseInt(props.value.substring(3,5),16);
        const b = parseInt(props.value.substring(5,7),16);
        const yiq = ((r*299)+(g*587)+(b*114))/1000;
        return (yiq >= 128) ? 'black' : 'white';
      }

      return 'white';
    }

    const getBorderColor = () => {
      if (hexRegex.test(props.value)) {
        const r = parseInt(props.value.substring(1,3),16);
        const g = parseInt(props.value.substring(3,5),16);
        const b = parseInt(props.value.substring(5,7),16);
        const yiq = ((r*299)+(g*587)+(b*114))/1000;
        return (yiq >= 128) ? '#ced4da' : props.value;
      }

      return '#ced4da';
    }

    const onChange = (value) => { 
        if (props.onChange !== undefined) {
          props.onChange(value);
        }
    }

    const onTextChange = (e) => {
      e.value = e.target.value;
      onChange(e);
    }

    const isFormFieldInValid = (name) => !!(typeof(props.errors) !== 'undefined' && props.errors !== null && props.errors[name]);
    
    const getFormErrorMessage = (name) => {   
        return isFormFieldInValid(name) && <small className="p-error">{props.errors[name].message}</small>;
    };

    return (  
      <>  
        <span className="p-float-label">
          <div className={`p-inputgroup ${isFormFieldInValid(props.name) === true ? 'border border-danger rounded': ''}`}>
            <FieldButton
              icon='pi pi-palette'
              onClick={() => setShowColorPicker(!showColorPicker)}
              style={{backgroundColor: buttonBackground, borderColor: getBorderColor(), color: getIconColor()}}
            />
            <FieldText value={props.value} onChange={onTextChange} keyfilter='alphanum' />
          </div>
          <label className={isFormFieldInValid(props.name) === true ? 'p-error' : ''}>{props.label}</label>
        </span>
        {getFormErrorMessage(props.name)}
        { showColorPicker ? (
          <div className='mt-2'>
            <ColorPicker
              value={props.value}
              onChange={onChange}
              format={props.format}
              defaultColor={props.defaultColor}
              disabled={props.disabled}
              inline={props.inline}
            />
          </div>
          ) : <></> }
        {/* <OverlayPanel ref={op} dismissable>
          <ColorPicker
            value={props.value}
            onChange={onChange}
            format={props.format}
            defaultColor={props.defaultColor}
            className={`${props.className} ${isFormFieldInValid(props.name) === true ? 'p-invalid' : ''}`}
            disabled={props.disabled}
            inline={props.inline}
          />
        </OverlayPanel> */}
      </> 
    )
}   

    
export default FieldColorPicker; 



/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState }  from 'react';
import PropTypes from 'prop-types';
import "./Signature.css"; 
import SignaturePad from 'react-signature-canvas';
import { Dialog } from 'primereact/dialog'; 
import { Row, Col, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';  
import FieldText from '../Controls/FieldText.jsx';
import { useTranslation } from 'react-i18next'; 
import { Button } from 'primereact/button';
import * as yup from 'yup';
import YupValidator from '../../utils/YupValidator'; 
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { showLoad, hideLoad } from '../../utils/utils.js';


function Signature ({
     onClear
    ,onChange
    ,readonly
    ,value
  }) { 
    
    const { t } = useTranslation(['hr', 'common']);
    const SignatureRef = useRef({});
    const [EsShowKeyboardOption, setEsShowKeyboardOption] = useState(false); 
    const [errors, setErrors] = useState({}); 
    
    const divRef = useRef(null);
    const [firma, setFirma] = useState(null);
    const [imgFirma, setImgFirma] = useState('');

    const onClearManagerSignature = () => { 
        if (SignatureRef.current !== null){
          SignatureRef.current.clear();
        }
        
        setFirma(null);
        setImgFirma('');
        if (typeof(onClear)  === 'function')
        {
            onClear();
        }
    }

    const onSignatureChange = () => {  
        if (typeof(onChange)  === 'function')
        {
            onChange(SignatureRef.current.getTrimmedCanvas().toDataURL().replace('data:image/png;base64,',''));
        }
    }

    if (readonly){
        return (
          <>
            <div className="Signature">  
              <div className="react-sign-canvas-disabled">
                {
                typeof(value) !== 'undefined' 
                ? <img src={`data:image/jpeg;base64,${value}`} className="react-sign-canvas-img" alt="Sign"></img>
                : <></>
                }
                
              </div>
            </div>
          </>
);
    }

    const renderHeader = () => {
      return (
        <> 
          <div className="text-left">
            <i className="fas fa-font"></i>&nbsp;{t('hr:TitleEnterSignature')}
            <div>
              <small>{t('hr:TypeSignature')}</small> 
            </div>
          </div> 
        </>
      );
  }
  
  const renderFooter = () => {
    return (
      <> 
        <div className="text-right">
          <Button label={t('common:Cancel')} className="secondary no-border rounded-button p-3 pl-5 pr-5  font-weight-bold" onClick={() => setEsShowKeyboardOption(false)} /> 
          <Button label={t('hr:Sign')} className="light-green no-border rounded-button p-3 pl-5 pr-5  font-weight-bold" onClick={onSign} /> 
        </div> 
      </>
    );
}

  const valitationSchemeFirma = yup.object().shape({
    firma: yup.string().trim().required(t('common:RequiredField')).nullable()
  }); 

  const onSign =  async () => { 
      
    const value = {
      firma 
    }

    const validator = new YupValidator(valitationSchemeFirma);
    const esValido = await validator.validate(value);
     
    if (!esValido){ 
        setErrors(validator.errors); 
        return;
    } 
    
    // iniciamos cargando
    showLoad();

    htmlToImage.toPng(divRef.current)
    .then((dataUrl) => {   
      setEsShowKeyboardOption(false);
      if (typeof(onChange)  === 'function')
      {
          onChange(dataUrl.replace('data:image/png;base64,',''));
          setImgFirma(dataUrl);
      }
      hideLoad();
    })
    .catch((error) => {
       
    });
     

  }

  const onChangeFirma = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.firma;   
    setErrors(newErrors) 
    setFirma(e.target.value)
  }
  


 
    return (  
      <>  
        <div className="Signature">  
          { imgFirma === '' ?
          (
            <SignaturePad
              ref={SignatureRef}
              canvasProps={{ className: 'react-sign-canvas'}}
              onEnd={onSignatureChange}
              clearOnResize={false}
            />
          ) :
          (
            <>
              <div className="Signature d-block d-sm-none">  
                <div className="react-sign-canvas-disabled pt-5">
                  <img src={imgFirma} className="react-sign-canvas-img" alt="Sign" width="100%" height="100%"></img> 
                </div>
              </div> 
              <div className="Signature d-none d-sm-block d-md-block d-lg-block">  
                <div className="react-sign-canvas-disabled pt-5">
                  <img src={imgFirma} className="react-sign-canvas-img" alt="Sign"></img> 
                </div>
              </div>
            </>
) }
          <div className="Signature-clear" onClick={onClearManagerSignature}>Clear</div>
          <div className="Signature-keyboard" onClick={() => setEsShowKeyboardOption(true)}>{t('hr:EnterSignature')}</div>
        </div>
        <Dialog visible={EsShowKeyboardOption} modal style={{width: '450px' }} footer={renderFooter} header={renderHeader} onHide={() => setEsShowKeyboardOption(false)}>   
          <Row className="align-items-start">
            <Col lg={12} md={12} sm={12} xs={12}>  
              <div className="p-fluid">
                <FieldText  
                  name="firma" 
                  placeholder=''
                  value={firma} 
                  onChange={onChangeFirma} 
                  errors={errors} 
                /> 
              </div>
              <br></br>
              <div className="signature-box"> 
                <div className="signature-font" ref={divRef}>
                  {firma}
                </div>
              </div>
            </Col>
          </Row> 
        </Dialog>  
      </> 
    )
}    

export default Signature; 

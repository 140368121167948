/* eslint-disable react/button-has-type */
import React, { useState, useEffect, useRef } from 'react'; 
import { config } from '../../../utils/config';
import { callApi } from '../../../utils/utils'; 
import { useTranslation } from 'react-i18next'; 
import PageContent from '../../../layouts/PageContent'; 
import { Toolbar } from 'primereact/toolbar';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row, Col } from 'reactstrap';
import { Badge } from 'primereact/badge';
import moment from "moment"; 
import { Dialog } from 'primereact/dialog'; 
import { Dropdown } from 'primereact/dropdown';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';

const PerformanceDevSetUpTable = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);
  const [PerformanceDevList, setPerformanceDevList] = useState([]); 
  const [mostrarDetalleUsuarios, setMostrarDetalleUsuarios] = useState(false);
  const [EmployeeParticipationDet, setEmployeeParticipationDet] = useState([]);
  const [IdPeformanceDevSelected, setIdPeformanceDevSelected] = useState([]);  
  const dt = useRef(null); 
  

  useEffect(() => {  
    const GetCfgPerformanceDevList = `${config.UrlApiProject}hr/GetCfgPerformanceDevList`;  
    const paramsToService = { };   
    callApi(GetCfgPerformanceDevList, 'GET', paramsToService, (response) => {
        setPerformanceDevList(response.data.PerformanceDevList);
    });
     
  }, []); 

  const header  = ( 
    <>
      {t('hr:PerformanceDevTableTitle')}
    </>
  )

  const modalHeader  = () =>  {
    return (
      <>
        <div className="p-panel-header pr-3">
          <div>
            {t('hr:EmployeeParticipationDet')} 
          </div>
          <div>
            <FieldButton  
              className="p-button p-button-rounded p-button-icon-only p-button-secondary"
              onClick={onExportGrid}
            >
              <i className="pi pi-download"></i>
            </FieldButton>
          </div>
        </div> 
      </>
);
  }


  
  const onExportGrid = () => {  
     dt.current.exportCSV();
  }

  const checkMarkTempate = (rowData, columnName) => {
    return (<>{rowData[columnName] ? <i className="fa fa-check light-green-color"></i> : <></>}</>)
  }
  

  const employeeInfo = (rowData) => {
    return (
      <> 
        <Row className="border-bottom paddignBottom5"> 
          <Col lg={4} md={3} sm={12} xs={12}>
            <Row className="align-items-start paddignTop5">
              <Col className="bold">
                <b className="UpperCase">{rowData.NombreCompleto}</b>
              </Col>
            </Row> 
            <Row className="align-items-start paddignTop5">
              <Col>
                <small>{rowData.CorreoElectronico}</small> 
              </Col>
            </Row> 
          </Col>
          <Col lg={8} md={9} sm={12} xs={12}> 
            <> 
              <div className="text-left">
                <Row className="paddignBottom10 align-items-start">
                  <Col>
                    <table className="w100">
                      <tbody>
                        <tr>
                          <td><small>{t('hr:IdPTool')}</small></td>
                          <td>{rowData.EstatusIDPTool ? <i className="fa fa-check light-green-color"></i> : <></> }</td>
                        </tr>
                        <tr>
                          <td style={{width:'90%'}}><small>{t('hr:180Degree')}</small></td>
                          <td style={{width:'10%'}}>{rowData.Estatus180DegreeSelfReview ? <i className="fa fa-check light-green-color"></i> : <></> }</td>
                        </tr>
                      </tbody>
                    </table>  
                  </Col>
                  <Col>
                    <table className="w100">
                      <tbody>
                        <tr>
                          <td style={{width:'90%'}}><small>{t('hr:EmployeeSelfReview')}</small></td>
                          <td style={{width:'10%'}}>{rowData.EstatusEmployeeSelfReview ? <i className="fa fa-check light-green-color"></i> : <></> }</td>
                        </tr>
                        <tr>
                          <td><small>{t('hr:ScorecardReviewed')}</small></td>
                          <td>{rowData.EstatusContribucionReview ? <i className="fa fa-check light-green-color"></i> : <></> }</td>
                        </tr>
                      </tbody>
                    </table> 
                  </Col>
                  <Col>
                    <table className="w100">
                      <tbody>
                        <tr>
                          <td style={{width:'90%'}}><small>{t('hr:ManagerReview')}</small></td>
                          <td style={{width:'10%'}}>{rowData.EstatusManagerReview ? <i className="fa fa-check light-green-color"></i> : <></> }</td>
                        </tr>
                        <tr>
                          <td colSpan={2}><small><b> {rowData.NombreCompletoManager} </b></small></td>
                        </tr>
                      </tbody>
                    </table>   
                  </Col>
                  <Col>
                    <table className="w100">
                      <tbody>
                        <tr>
                          <td style={{width:'80%'}}><small>{t('hr:AnnualReview')}</small></td>
                          <td style={{width:'10%'}}>{rowData.EstatusAnnualReview ? <i className="fa fa-check light-green-color"></i> : <></> }</td>
                        </tr>
                      </tbody>
                    </table>    
                  </Col>
                </Row> 
              </div>
            </> 
          </Col>
        </Row>
      </>
    );
  }
  


  const OnParticipationDetail = (rowData) => { 
    const GetEmployeeParticipationDetail = `${config.UrlApiProject}hr/GetEmployeeParticipationDetail`;  
    const paramsToService = { 
      IdPerformanceDev : rowData.IdPerformanceDev,
      TipoDetalleParticipacion: 1 // 180-Degree Feedback
    };   

    setIdPeformanceDevSelected(rowData.IdPerformanceDev);
    callApi(GetEmployeeParticipationDetail, 'POST', paramsToService, (response) => {
        setEmployeeParticipationDet(response.data.EmployeeParticipationDet);
        setMostrarDetalleUsuarios(true);
    });

    
  }

  const DegreeTemplate = (rowData) => {
    return (
      <>
        <div className="Cursor" onClick={() => {OnParticipationDetail(rowData)}}> 
          <Badge value="" severity={rowData.Activa180DegreeReview === 1 ? "success" : "danger"}></Badge>&nbsp;<b>{rowData.Pendientes180Degree}</b>
          <div>
            <small> { moment(rowData.FechaInicia180Degree).format("LL") } - { moment(rowData.FechaTermina180Degree).format("LL") }</small>
          </div>
          {rowData.Anio < 2022 ? (<></>) 
            : 
            ( 
              <div>
                <FieldButton 
                  icon="fas fa-clipboard-check" 
                  label="Approve Responses"
                  onClick={() => onClickApproveResponses(rowData)} 
                  className="p-button-rounded light-yellow no-border"
                /> 
              </div>
            )}
          
        </div>
      </>
    );
  }

  const IDPToolTemplate = (rowData) => {
    return (
      <>
        <div className="Cursor" onClick={() => {OnParticipationDetail(rowData)}}> 
          <Badge value="" severity={rowData.ActivaIDPTool === 1 ? "success" : "danger"}></Badge>&nbsp;<b>{rowData.PendientesIDPTool}</b>
          <div>
            <small> { moment(rowData.FechaIniciaIDPTool).format("LL") } - { moment(rowData.FechaTerminaIDPTool).format("LL") }</small>
          </div>
        </div>
      </>
    );
  }

  const EmployeeSelfReview = (rowData) => {
    return (
      <>
        <div className="Cursor" onClick={() => {OnParticipationDetail(rowData)}}> 
          <Badge value="" severity={rowData.ActivaEmployeeSelfReview === 1 ? "success" : "danger"}></Badge>&nbsp;<b>{rowData.PendientesEmployeeSelfReview}</b>
          <div>
            <small> { moment(rowData.FechaIniciaEmployeeSelfReview).format("LL") } - { moment(rowData.FechaTerminaEmployeeSelfReview).format("LL") }</small>
          </div>
        </div>
      </>
    );
  }

  const ManagerReviewTemplate = (rowData) => {
    return (
      <>
        <div className="Cursor" onClick={() => {OnParticipationDetail(rowData)}}> 
          <Badge value="" severity={rowData.ActivaManagerReview === 1 ? "success" : "danger"}></Badge>&nbsp;<b>{rowData.PendientesManagerReview}</b>
          <div>
            <small> { moment(rowData.FechaIniciaManagerReview).format("LL") } - { moment(rowData.FechaTerminaManagerReview).format("LL") }</small>
          </div>
        </div>
      </>
    );
  }


  const AnnualReviewTemplate = (rowData) => {
    return (
      <>
        <div className="Cursor" onClick={() => {OnParticipationDetail(rowData)}}> 
          <Badge value="" severity={rowData.ActivaAnnualReview === 1 ? "success" : "danger"}></Badge>&nbsp;<b>{rowData.PendientesAnnualReview}</b>
          <div>
            <small> { moment(rowData.FechaIniciaAnnualReview).format("LL") } - { moment(rowData.FechaTerminaAnnualReview).format("LL") }</small>
          </div>
        </div>
      </>
    );
  }

  const onEditClick = (rowData) => { 
    props.onEditClick(rowData);
  };

  const onClickApproveResponses = (rowData) => {
    props.onClickApproveResponses(rowData);
  } 


  const actionBodyTemplate = (rowData) => {
    return (
      <> 
        <FieldButton icon="pi pi-pencil" onClick={() => onEditClick(rowData)} className="p-button-rounded p-button-primary" /> 
      </>
    );
  } 

 

  return (
    <> 
      <FieldDataTable 
        value={PerformanceDevList}   
        scrollable 
        scrollHeight="330px" 
        className="p-datatable-striped"  
        selectionMode="single"  
        metaKeySelection={false}  
      >   
        <Column field="Anio" header={t('hr:Anio')} headerStyle={{ width: '150px' }} columnKey="Anio"></Column>
        <Column header={t('hr:IdPTool')} headerStyle={{ width: '150px' }} body={IDPToolTemplate}></Column> 
        <Column header={t('hr:180Degree')} headerStyle={{ width: '150px' }} body={DegreeTemplate}></Column> 
        <Column header={t('hr:EmployeeSelfReview')} headerStyle={{ width: '150px' }} body={EmployeeSelfReview}></Column> 
        <Column header={t('hr:ManagerReview')} headerStyle={{ width: '150px' }} body={ManagerReviewTemplate}></Column>  
        <Column header={t('hr:AnnualReview')} headerStyle={{ width: '150px' }} body={AnnualReviewTemplate}></Column> 
        <Column body={actionBodyTemplate} headerStyle={{ width: '70px' }}></Column>
      </FieldDataTable> 
      <Dialog
        visible={mostrarDetalleUsuarios}
        modal
        style={{width: '100vw' }} 
        header={modalHeader} 
        onHide={() => { setMostrarDetalleUsuarios(false)}}
        maximized={true}
      >      
        {
          IdPeformanceDevSelected === 1 ?
          (
            <>
              <FieldDataTable
                refererence={dt}
                className="p-datatable-striped"  
                value={EmployeeParticipationDet}   
                metaKeySelection={false} 
                paginatorClassName="p-jc-end"
              >
                <Column field="NombreCompleto" header={t('hr:EmployeeName')} columnKey="NombreCompleto" headerStyle={{ width: '220px' }}></Column> 
                <Column field="CorreoElectronico" header={t('hr:Email')} columnKey="CorreoElectronico" headerStyle={{ width: '180px' }}></Column> 
                <Column field="NombreCompletoManager" header={t('hr:ManagerName')} columnKey="CorreoElectronico" headerStyle={{ width: '220px' }}></Column> 
                <Column field="EstatusIDPTool" body={(rowdata) => checkMarkTempate(rowdata, "EstatusIDPTool")} header={t('hr:IdPTool')} columnKey="EstatusIDPTool" headerStyle={{ width: '130px' }}></Column> 
                <Column field="Estatus180DegreeSelfReview" body={(rowdata) => checkMarkTempate(rowdata, "Estatus180DegreeSelfReview")} header={t('hr:180Degree')} columnKey="Estatus180DegreeSelfReview" headerStyle={{ width: '100px' }}></Column> 
                <Column field="EstatusEmployeeSelfReview" body={(rowdata) => checkMarkTempate(rowdata, "EstatusEmployeeSelfReview")} header={t('hr:EmployeeSelfReview')} columnKey="EstatusEmployeeSelfReview" headerStyle={{ width: '100px' }}></Column> 
                <Column field="EstatusContribucionReview" body={(rowdata) => checkMarkTempate(rowdata, "EstatusContribucionReview")} header={t('hr:ScorecardReviewed')} columnKey="EstatusContribucionReview" headerStyle={{ width: '100px' }}></Column>  
                <Column field="EstatusManagerReview" body={(rowdata) => checkMarkTempate(rowdata, "EstatusManagerReview")} header={t('hr:ManagerReview')} columnKey="EstatusManagerReview" headerStyle={{ width: '100px' }}></Column> 
                <Column field="EstatusAnnualReview" body={(rowdata) => checkMarkTempate(rowdata, "EstatusAnnualReview")} header={t('hr:AnnualReview')} columnKey="EstatusAnnualReview" headerStyle={{ width: '100px' }}></Column> 
          
                <Column body={employeeInfo} headerStyle={{ width: '220px', display :'none'  }} style={{ display :'none' }}></Column> 
              </FieldDataTable>
            </>
)
          :
          (
            <>
              <FieldDataTable
                refererence={dt}
                className="p-datatable-striped"  
                value={EmployeeParticipationDet}   
                metaKeySelection={false} 
                paginatorClassName="p-jc-end"
              >
                <Column field="NombreCompleto" header={t('hr:EmployeeName')} columnKey="NombreCompleto" headerStyle={{ width: '220px' }}></Column> 
                <Column field="CorreoElectronico" header={t('hr:Email')} columnKey="CorreoElectronico" headerStyle={{ width: '180px' }}></Column> 
                <Column field="NombreCompletoManager" header={t('hr:ManagerName')} columnKey="CorreoElectronico" headerStyle={{ width: '220px' }}></Column> 
                <Column field="EstatusIDPTool" body={(rowdata) => checkMarkTempate(rowdata, "EstatusIDPTool")} header={t('hr:IdPTool')} columnKey="EstatusIDPTool" headerStyle={{ width: '130px' }}></Column> 
                <Column field="Estatus180DegreeSelfReview" body={(rowdata) => checkMarkTempate(rowdata, "Estatus180DegreeSelfReview")} header={t('hr:180Degree')} columnKey="Estatus180DegreeSelfReview" headerStyle={{ width: '100px' }}></Column> 
                <Column field="EstatusEmployeeSelfReview" body={(rowdata) => checkMarkTempate(rowdata, "EstatusEmployeeSelfReview")} header={t('hr:EmployeeSelfReview')} columnKey="EstatusEmployeeSelfReview" headerStyle={{ width: '100px' }}></Column> 
                <Column field="EstatusManagerReview" body={(rowdata) => checkMarkTempate(rowdata, "EstatusManagerReview")} header={t('hr:ManagerReview')} columnKey="EstatusManagerReview" headerStyle={{ width: '100px' }}></Column> 
                <Column field="EstatusAnnualReview" body={(rowdata) => checkMarkTempate(rowdata, "EstatusAnnualReview")} header={t('hr:AnnualReview')} columnKey="EstatusAnnualReview" headerStyle={{ width: '100px' }}></Column> 
          
                <Column body={employeeInfo} headerStyle={{ width: '220px', display :'none'  }} style={{ display :'none' }}></Column> 
              </FieldDataTable>
            </>
)
        }
           
      </Dialog> 
    </>
  );
};

export default PerformanceDevSetUpTable;



/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../../utils/utils';
import SurveyManagementHeadSurveyLoad from './SurveyManagementHeadSurveyLoad';
import SurveyManagementPageLoad from './SurveyManagementPageLoad';
import SweatAlertCustomIcon from 'components/SweatAlert/SweatAlertCustomIcon';
import swal from '@sweetalert/with-react';

const SurveyManagementEdit = (props) => {
  const { t } = useTranslation(['survey', 'common']);
  const [IdEncuesta, setIdEncuesta] = useState(null);
  const [EsHabilitarEditarSurvey, setEsHabilitarEditarSurvey] = useState(0);
  const [Survey, setSurvey] = useState(0);
  const [SurveyPageList, setSurveyPageList] = useState(0);
  const [ForceRefresh, setForceRefresh] = useState(0);

  useEffect(() => {
    setIdEncuesta(props.IdEncuesta === undefined ? null : props.IdEncuesta);
    getHRSurveyManagementInfo();
  }, [props.IdEncuesta, props.ForceRefresh]);

  const getHRSurveyManagementInfo = () => {
    const GetHRSurveyManagementInfo = `${config.UrlApiProject}HR/GetHRSurveyManagementInfo`;
    const paramsToService = {
      IdEncuesta: props.IdEncuesta,
    };
    callApi(GetHRSurveyManagementInfo, 'POST', paramsToService, (response) => {  
      setSurvey(response.data.Encuesta[0]);
      setSurveyPageList(response.data.PageList);
    });
  };

  const onSearch = () => {
    setForceRefresh(ForceRefresh + 1);
    getHRSurveyManagementInfo();
  };

  const onNewPage = () => {
    const SaveSurvey = `${config.UrlApiProject}HR/SaveSurveyPagina`;
    const paramsService = {
      IdEncuesta,
      IdAccion: 1,
    };
    callApi(SaveSurvey, 'POST', paramsService, (response) => {
      onSearch();
    });
  };

  const onCopyPage = (item) => {
    console.log(item);
    const SaveSurvey = `${config.UrlApiProject}HR/SaveSurveyPaginaCopy`;
    const paramsService = {
      IdEncuesta,
      IdEncuestaPagina: item
    };
    callApi(SaveSurvey, 'POST', paramsService, (response) => {
      onSearch();
    });
  };

  const regresarListado = () => {
    if (props.onSearch) {
      props.onSearch();
    }
  }

  const onRemovePage = (pageInfo) => {

    swal({ 
      buttons: {
        cancelar: {
          text: t('common:Cancel'),
          value: "cancel",
          className: "rounded-button danger"
        },
        continuar: {
          text: t('common:Continue'),
          value: "continue",
          className: "rounded-button light-green"
        }
      },
      content: confirmationMessageHTML
    }).then((value) => {
      switch (value) { 
        case "continue":
          bajasurveyManagementPage(pageInfo);          
          break; 
        default:
          break;
      }
    });
  }

  const bajasurveyManagementPage = async (pageInfo) => { 
    
    const urlWebServiceSave = `${config.UrlApiProject}HR/SaveSurveyPagina`;
    const paramsService = { 
        IdEncuesta : pageInfo.IdEncuesta,  
        IdEncuestaPagina : pageInfo.IdEncuestaPagina,
        IdAccion: 3
    }; 
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {       
      setForceRefresh(ForceRefresh + 1);
      getHRSurveyManagementInfo();
    });
  }

  const confirmationMessageHTML = (
    <div>
      <SweatAlertCustomIcon title={t('common:SubmitConfirmation')} letter={t('common:QuestionMark')}></SweatAlertCustomIcon> 
      <div>{t('survey:RemovePageMessage')}.</div> 
    </div>  
   )

  return (
    <>
      <Row>
        <Col className="text-right"> 
          <FieldButton label={t('common:Back')} icon="pi pi-arrow-left" className="p-button-secondary p-mr-2" onClick={regresarListado} />
          &nbsp;
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
                    &nbsp;
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Row>
                    <Col xs={12} sm={12} ms={12} lg={12}>
                      <SurveyManagementHeadSurveyLoad
                        IdEncuesta={Survey.IdEncuesta}
                        Titulo={Survey.Titulo}
                        Descripcion={Survey.Descripcion}
                        DescripcionHtml={Survey.DescripcionHtml}
                        ForceRefresh={ForceRefresh}
                        onSearch={onSearch} 
                      >
                      </SurveyManagementHeadSurveyLoad>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                    &nbsp;
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {SurveyPageList.length >= 1 ? (
        SurveyPageList.map((element, index) => {
          return (
            <Card key={index}>
              <CardBody className="questionario-viewer"> 
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <SurveyManagementPageLoad
                      IdEncuestaPagina={element.IdEncuestaPagina}
                      IdEncuesta={element.IdEncuesta}
                      Titulo={element.Titulo}
                      Descripcion={element.Descripcion}
                      DescripcionHtml={element.DescripcionHtml}
                      Index={index}
                      ForceRefresh={ForceRefresh}
                      onSearch={onSearch}
                    >
                    </SurveyManagementPageLoad>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12} className="text-center">
                    <FieldButton
                      label={t('survey:AddNewPage')}
                      icon="pi pi-plus"
                      className="no-border p-mr-2 rounded-button"
                      onClick={onNewPage}
                    />
                      &nbsp;
                    <FieldButton
                      label={t('survey:CopyPage')}
                      icon="fas fa-paste"
                      className="secondary no-border p-mr-2 rounded-button"
                      onClick={(e) => onCopyPage(SurveyPageList[index].IdEncuestaPagina)}
                    />
                     &nbsp; 
                    <FieldButton
                      label={t('survey:RemovePage')}
                      icon="pi pi-trash"
                      className="danger no-border p-mr-2 rounded-button"
                      onClick={(e) => onRemovePage(element)}
                    />

                  </Col>
                </Row>
              </CardBody>
            </Card>
          );
        })
      ) : (
        <>
          <Card>
            <CardBody className="questionario-viewer">
              {/*
              <Row>
                <Col xs={12} sm={12} ms={12} lg={12}>
                  <Row className="header-dashboard-title">
                    <Col xs={12} sm={12} ms={12} lg={12}>
                      <SurveyManagementHeadSurveyLoad
                        IdEncuesta={Survey.IdEncuesta}
                        Titulo={Survey.Titulo}
                        Descripcion={Survey.Descripcion}
                        DescripcionHtml={Survey.DescripcionHtml}
                        ForceRefresh={ForceRefresh}
                        onSearch={onSearch}
                      >
                      </SurveyManagementHeadSurveyLoad>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                    &nbsp;
                </Col>
              </Row>
              */
              }
              <Row>
                <Col lg={12} md={12} sm={12} xs={12} className="text-center">
                  <FieldButton
                    label={t('survey:AddNewPage')}
                    icon="pi pi-plus"
                    className="no-border p-mr-2 rounded-button"
                    onClick={onNewPage}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </>
      )}
    </>
  );
};
export default SurveyManagementEdit;

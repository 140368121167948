/* eslint-disable no-const-assign */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { config } from 'utils/config';
import { callApi, showSweetAlert, showNotify } from 'utils/utils'; 
import FieldButton from 'components/Controls/FieldButton/FieldButton'; 
import { Dialog } from 'primereact/dialog'; 
import moment from 'moment'; 
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  ListGroupItem,
  ListGroup,
  Row,
  Col,
  FormGroup,
  Label,
} from 'reactstrap';
import { Badge } from 'primereact/badge';
import FieldDropdown from 'components/Controls/FieldDropdown';
import PageContent from 'layouts/PageContent';
import { set } from 'date-fns/esm';
import "../Header/HeaderTablet.css";
import HeaderTablet from '../Header/HeaderTablet';
import { Toolbar } from 'primereact/toolbar';

const ScrapDetail = (props) => {
  const { t, i18n } = useTranslation(['tablets', 'common']);
  const [errors, setErrors] = useState({});
  const [ScrapCellDetailList, setScrapCellDetailList] = useState([]);  
  const [ClaCelda, setClaCelda] = useState(null);  
  

  useEffect(() => {
    setClaCelda(props.ClaCelda === undefined ? 0 : props.ClaCelda); 
    OnloadScrapCellDetail(props.ClaCelda === undefined ? 0 : props.ClaCelda);    
    if (props.show) {
        document.body.classList.add('bodynotscroll');
      }
  }, [props.ClaCelda]);

  const OnloadScrapCellDetail = (pClaCelda) => {  
    const paramsService = {};
    const urlWebService = `${config.UrlApiProject}Operations/GetOperationScrapCellDetail?ClaCelda=${pClaCelda}`;
    callApi(urlWebService, 'GET', paramsService, (response) => {
        setScrapCellDetailList(response.data.ScrapCellDetail);
    });
  }; 

  const showActiveRows = (rows) => {
    const activeRows = [];
    if (rows !== undefined) {
      if (rows.length > 0) {
        rows.forEach((item, index) => {
          if (!item.BajaLogica || item.BajaLogica === 0 || item.BajaLogica === undefined) {
            item.BajaLogica = 0;
            item.RowIndex = index;
            activeRows.push({ ...item });
          }
        });
      } else {
        return [];
      }
    }

    return activeRows;
  };


  const onHideScrapDetail = () => {
    document.body.classList.remove('bodynotscroll');

    if (props.onHide !== null && typeof props.onHide === 'function') {
      props.onHide();
    }
  };

  const renderHeader = () => {
    return (
      <div>
        <Row>
          <Col lg={6} md={6} sm={6} xs={6}>            
            <span>{t('tablets:ScrapDetail')}</span>            
          </Col>     
          <Col lg={6} md={6} sm={6} xs={6} className="text-right">
            <span className="">{t('tablets:TotalScrap')}</span>: { (ScrapCellDetailList.reduce((a,v) =>  a += v.Scraplbs === null ? 0 : v.Scraplbs , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }
          </Col>    
        </Row>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <>
        <Toolbar
          className='pl-5'
          left={null}
          right={rightToolbarFooter}
        >
        </Toolbar>
      </>
    );
  };
  const rightToolbarFooter = () => {  
    return (
      <>         
        <FieldButton
          label={t('common:Close')}
          icon="fas fa-times"
          className="warning no-border p-mr-2 rounded-button"
          onClick={onHideScrapDetail}
        />
      </>
    );
  };

  const onRemoveScrap = (item)=>{
    const paramsService = {
        IdProduccionCeldaTabletScrap: item.IdProduccionCeldaTabletScrap,
      };
    const urlWebService = `${config.UrlApiProject}Operations/EliminarScrap`;
    callApi(urlWebService, 'POST', paramsService, (response) => { 
      showNotify(t('common:SaveSuccess'), 'centerTop', 'success'); 
      OnloadScrapCellDetail(ClaCelda);
    });  
  }

  return (
    <>
      <Dialog
        visible={props.show}
        modal
        style={{ width: '100vw' }}
        footer={renderFooter}
        header={renderHeader}
        onHide={onHideScrapDetail}
        closable={false}
        className="dialog-header-background-blue"
        maximized={true}
        draggable={false}
      >         
        <Row className='pt-3 text-right' hidden={true}>
          <Col lg={12} md={12} sm={12} xs={12}> 
            <h3><span className="info-color">{t('tablets:TotalScrap')}</span>: { (ScrapCellDetailList.reduce((a,v) =>  a += v.Scraplbs === null ? 0 : v.Scraplbs , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</h3>
          </Col>
        </Row>
        <Row>
                            &nbsp;
        </Row>
        
        {
            showActiveRows(ScrapCellDetailList).map((item, index) => {
              return (
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>            
                    <Card>
                      <CardBody>
                        <Row>
                          <Col lg={8} md={8} sm={6} xs={6}> 
                            <span className="tablet-label-content">{t('tablets:ScrapDate')}</span>         
                          </Col>
                          <Col lg={4} md={4} sm={6} xs={6} className="text-right">                             
                            <span className="tablet-label-value">{moment(item.FechaCreacion).format("MMMM Do YYYY, h:mm a")}</span>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={8} md={8} sm={6} xs={6}> 
                            <span className="tablet-label-content">{t('tablets:Type')}</span>         
                          </Col>
                          <Col lg={4} md={4} sm={6} xs={6} className="text-right"> 
                            <span className="tablet-label-value">{item.NomTipoScrapCelda}</span>         
                          </Col>
                        </Row>          
                        <Row>
                          <Col lg={8} md={8} sm={6} xs={6}> 
                            <span className="tablet-label-content">{t('tablets:Amount')}</span>         
                          </Col>
                          <Col lg={4} md={4} sm={6} xs={6} className="text-right"> 
                            <span className="tablet-label-value">{item.Scraplbs}</span>         
                          </Col>
                        </Row>  
                        <Row>
                          <Col lg={8} md={8} sm={6} xs={6}></Col>
                          <Col lg={4} md={4} sm={6} xs={6} className="text-right"> 
                            <FieldButton
                              label={t('tablets:Remove')}
                              className="button-rounded danger no-border btntablet"
                              onClick={(e) => onRemoveScrap(item)}
                            />
                          </Col>
                        </Row>          
                      </CardBody> 
                    </Card>                     
                  </Col>   
                </Row>   
              );
            })
          }       
      </Dialog>     
    </>
  );
};

export default ScrapDetail;

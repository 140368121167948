import { React, UseState, UseEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PageContent from 'layouts/PageContent';
import ProfitCube from './ProfitCube';

const ProfitCubeScreen = (props) => {
  const {t, i18n} = useTranslation(['salesPerformance', 'common']);
  
  return (
    <PageContent title={t('salesPerformance:ProfitCube')}>
      <ProfitCube />
    </PageContent>
  )
}

export default ProfitCubeScreen;
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row, Col, Card, CardBody, CardFooter } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi, showSweetAlert, getSessionItem } from '../../../utils/utils';
import { Checkbox } from 'primereact/checkbox';
import FieldText from '../../../components/Controls/FieldText.jsx';
import FieldTextArea from '../../../components/Controls/FieldTextArea.jsx';
import FieldNumber from '../../../components/Controls/FieldNumber.jsx';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx';
import FieldDropdown from 'components/Controls/FieldDropdown.jsx';
import { RadioButton } from 'primereact/radiobutton';
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import moment from 'moment';
import { Sidebar } from 'primereact/sidebar';
import swal from '@sweetalert/with-react';
import { Tag } from 'primereact/tag';
import PageContent from 'layouts/PageContent.jsx'; 
import { ColumnGroup } from 'primereact/columngroup';
import Highcharts from 'highcharts';  

const IncentiveProgramsGoalsSalesRep = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['salesPerformance', 'common']); 

  const [errors, setErrors] = useState({});
  const [IncentiveGoals, setIncentiveGoals] = useState([]);
  const [OpcionesDivision, setOpcionesDivision] = useState([]); 
  const [OpcionesGrupoEstadistico3, setOpcionesGrupoEstadistico3] = useState([]); 
  const [SalesRepInfo, setSalesRepInfo] = useState({});
  const [IsReadOnly, setIsReadOnly] = useState(false);
  
  
  
  useEffect(() => { 
    obtenerDivisiones();  
    obtenerGruposEstadisticos3();  setIsReadOnly(typeof(props.IsReadOnly) === 'undefined' || props.IsReadOnly === null ? false : props.IsReadOnly);
  }, [props.IsReadOnly]);

  useEffect(() => { 
    obtieneIncentiveSalesRepInfo(); 
    setIsReadOnly(typeof(props.IsReadOnly) === 'undefined' || props.IsReadOnly === null ? false : props.IsReadOnly);
  }, [props.ClaAnio, props.ClaAgenteVentas, props.IsReadOnly]); 

  const obtieneIncentiveSalesRepInfo = () => {
    const GetIncentiveProgramGoalSalesRep = `${config.UrlApiProject}SalesPerformanceSales/GetIncentiveProgramGoalSalesRep?ClaAnio=${props.ClaAnio === null || props.ClaAnio === undefined ? '' : props.ClaAnio}&ClaAgenteVentas=${props.ClaAgenteVentas === null || props.ClaAgenteVentas === undefined ? '' : props.ClaAgenteVentas}`;; 
 
    
    callApi(GetIncentiveProgramGoalSalesRep, 'GET', {}, (response) => {
        setSalesRepInfo   (response.data.SalesRepInfo.length > 0 ? response.data.SalesRepInfo[0] : {});
        setIncentiveGoals (response.data.IncentiveProgramGoals); 
    });  
  }

  const obtenerDivisiones = () => { 
    const GetDivisionCmb = `${config.UrlApiProject}SalesPerformanceSales/GetDivisionCmb`; 
 
    
    callApi(GetDivisionCmb, 'GET', {}, (response) => {
        setOpcionesDivision (response.data.Division); 
    });  
  }

  const obtenerGruposEstadisticos3 = () => { 
    const GetGrupoEstadistico3Cmb = `${config.UrlApiProject}SalesPerformanceSales/GetGrupoEstadistico3Cmb`; 
 
    
    callApi(GetGrupoEstadistico3Cmb, 'GET', {}, (response) => {
        setOpcionesGrupoEstadistico3 (response.data.GrupoEstadistico3List); 
    });  
  }


  const showActiveRows = (rows) => {
    const activeRows = [];
    if (rows.length > 0) {
      rows.forEach((item, index) => {
        if (item.BajaLogica === 0 || item.BajaLogica === undefined) {
          item.BajaLogica = 0;
          item.RowIndex = index;
          activeRows.push({ ...item });
        }
      });
    } else {
      return [];
    }
    return activeRows;
  };

  const headerScoreCardDetailGroup  = ( 
    <ColumnGroup>
      <Row>   
        {/* 
        <Column header="" className="text-center table-header-background-dark-green" colSpan={3}></Column>  
        */
        }     
        <Column header={t('salesPerformance:January')} className="text-center" colSpan={3}></Column> 
        <Column header={t('salesPerformance:February')} className="text-center table-header-background-dark-green" colSpan={3}></Column>       
        <Column header={t('salesPerformance:March')} className="text-center" colSpan={3}></Column>       
        <Column header={t('salesPerformance:April')} className="text-center table-header-background-dark-green" colSpan={3}></Column>       
        <Column header={t('salesPerformance:May')} className="text-center" colSpan={3}></Column>       
        <Column header={t('salesPerformance:June')} className="text-center table-header-background-dark-green" colSpan={3}></Column>       
        <Column header={t('salesPerformance:July')} className="text-center" colSpan={3}></Column>       
        <Column header={t('salesPerformance:August')} className="text-center table-header-background-dark-green" colSpan={3}></Column>       
        <Column header={t('salesPerformance:September')} className="text-center" colSpan={3}></Column>       
        <Column header={t('salesPerformance:Octuber')} className="text-center table-header-background-dark-green" colSpan={3}></Column>       
        <Column header={t('salesPerformance:November')} className="text-center" colSpan={3}></Column>       
        <Column header={t('salesPerformance:December')} className="text-center table-header-background-dark-green" colSpan={3}></Column>       
        
      </Row>
      <Row> 
        {
        /*
        <Column header="Division" className="text-center table-header-background-dark-green"></Column>    
        <Column header="Statistical Group 3" className="text-center table-header-background-dark-green"></Column>  
        <Column header="Sgement" className="text-center table-header-background-dark-green"></Column>
         */
        }
        <Column header={t('salesPerformance:TonsGoal')} className="text-center"></Column> 
        <Column header={t('salesPerformance:StrategicTonsGoal')} className="text-center"></Column> 
        <Column header={t('salesPerformance:SpreadGoal')} className="text-center"></Column>  

        
        <Column header={t('salesPerformance:TonsGoal')} className="text-center table-header-background-dark-green"></Column> 
        <Column header={t('salesPerformance:StrategicTonsGoal')} className="text-center table-header-background-dark-green"></Column> 
        <Column header={t('salesPerformance:SpreadGoal')} className="text-center table-header-background-dark-green"></Column>  
      
        
        <Column header={t('salesPerformance:TonsGoal')} className="text-center"></Column> 
        <Column header={t('salesPerformance:StrategicTonsGoal')} className="text-center"></Column> 
        <Column header={t('salesPerformance:SpreadGoal')} className="text-center"></Column>  

        <Column header={t('salesPerformance:TonsGoal')} className="text-center table-header-background-dark-green"></Column> 
        <Column header={t('salesPerformance:StrategicTonsGoal')} className="text-center table-header-background-dark-green"></Column> 
        <Column header={t('salesPerformance:SpreadGoal')} className="text-center table-header-background-dark-green"></Column>  

        <Column header={t('salesPerformance:TonsGoal')} className="text-center"></Column> 
        <Column header={t('salesPerformance:StrategicTonsGoal')} className="text-center"></Column> 
        <Column header={t('salesPerformance:SpreadGoal')} className="text-center"></Column>  

        <Column header={t('salesPerformance:TonsGoal')} className="text-center table-header-background-dark-green"></Column> 
        <Column header={t('salesPerformance:StrategicTonsGoal')} className="text-center table-header-background-dark-green"></Column> 
        <Column header={t('salesPerformance:SpreadGoal')} className="text-center table-header-background-dark-green"></Column>  

        <Column header={t('salesPerformance:TonsGoal')} className="text-center"></Column> 
        <Column header={t('salesPerformance:StrategicTonsGoal')} className="text-center"></Column> 
        <Column header={t('salesPerformance:SpreadGoal')} className="text-center"></Column>  

        <Column header={t('salesPerformance:TonsGoal')} className="text-center table-header-background-dark-green"></Column> 
        <Column header={t('salesPerformance:StrategicTonsGoal')} className="text-center table-header-background-dark-green"></Column> 
        <Column header={t('salesPerformance:SpreadGoal')} className="text-center table-header-background-dark-green"></Column>  

        <Column header={t('salesPerformance:TonsGoal')} className="text-center"></Column> 
        <Column header={t('salesPerformance:StrategicTonsGoal')} className="text-center"></Column> 
        <Column header={t('salesPerformance:SpreadGoal')} className="text-center"></Column>  

        <Column header={t('salesPerformance:TonsGoal')} className="text-center table-header-background-dark-green"></Column> 
        <Column header={t('salesPerformance:StrategicTonsGoal')} className="text-center table-header-background-dark-green"></Column> 
        <Column header={t('salesPerformance:SpreadGoal')} className="text-center table-header-background-dark-green"></Column>  

        <Column header={t('salesPerformance:TonsGoal')} className="text-center"></Column> 
        <Column header={t('salesPerformance:StrategicTonsGoal')} className="text-center"></Column> 
        <Column header={t('salesPerformance:SpreadGoal')} className="text-center"></Column>  

        <Column header={t('salesPerformance:TonsGoal')} className="text-center table-header-background-dark-green"></Column> 
        <Column header={t('salesPerformance:StrategicTonsGoal')} className="text-center table-header-background-dark-green"></Column> 
        <Column header={t('salesPerformance:SpreadGoal')} className="text-center table-header-background-dark-green"></Column>  

      </Row>
    </ColumnGroup>
  )

  const frozenHeaderColumnGroup  = (  
    <ColumnGroup>
      <Row> 
        <Column header="" rowSpan={2} headerStyle={{ height:'52px'}} className="table-header-background-dark-green"></Column> 
        <Column header="" rowSpan={2} headerStyle={{ height:'52px'}} className="table-header-background-dark-green"></Column>   
        <Column header={t('salesPerformance:Division')} rowSpan={2} headerStyle={{ height:'52px'}} className="table-header-background-dark-green"></Column>  
        <Column header={t('salesPerformance:StatisticalGroup3')} rowSpan={2} className="table-header-background-dark-green"></Column>  
        <Column header={t('salesPerformance:Segment')} rowSpan={2} className="table-header-background-dark-green boder-right"></Column>           
      </Row>
    </ColumnGroup> 
  ) 

  const footerFrozenColumnGroup  = ( 
    <> 
      <Row>
        <Column></Column>  
        <Column></Column>  
        <Column></Column> 
        <Column></Column> 
        <Column footer={<b>Total</b>}></Column>          
      </Row>
    </>
  )

  const footer  = ( 
    <> 
      <Row> 
        <Column className="text-right" footer={() => (<b>{ (showActiveRows(IncentiveGoals).reduce((a,v) =>  a += v.JanTons === null ? 0 : v.JanTons , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>  
        <Column className="text-right" footer={() => (<b>{ (showActiveRows(IncentiveGoals).reduce((a,v) =>  a += v.JanStrategicTons === null ? 0 : v.JanStrategicTons , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>
        <Column className="text-right" footer={() => ("")}></Column>

        <Column className="text-right" footer={() => (<b>{ (showActiveRows(IncentiveGoals).reduce((a,v) =>  a += v.FebTons === null ? 0 : v.FebTons , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>  
        <Column className="text-right" footer={() => (<b>{ (showActiveRows(IncentiveGoals).reduce((a,v) =>  a += v.FebStrategicTons === null ? 0 : v.FebStrategicTons , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>
        <Column className="text-right" footer={() => ("")}></Column>

        <Column className="text-right" footer={() => (<b>{ (showActiveRows(IncentiveGoals).reduce((a,v) =>  a += v.MarTons === null ? 0 : v.MarTons , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>  
        <Column className="text-right" footer={() => (<b>{ (showActiveRows(IncentiveGoals).reduce((a,v) =>  a += v.MarStrategicTons === null ? 0 : v.MarStrategicTons , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>
        <Column className="text-right" footer={() => ("")}></Column>

        <Column className="text-right" footer={() => (<b>{ (showActiveRows(IncentiveGoals).reduce((a,v) =>  a += v.AprTons === null ? 0 : v.AprTons , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>  
        <Column className="text-right" footer={() => (<b>{ (showActiveRows(IncentiveGoals).reduce((a,v) =>  a += v.AprStrategicTons === null ? 0 : v.AprStrategicTons , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>
        <Column className="text-right" footer={() => ("")}></Column>

        <Column className="text-right" footer={() => (<b>{ (showActiveRows(IncentiveGoals).reduce((a,v) =>  a += v.MayTons === null ? 0 : v.MayTons , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>  
        <Column className="text-right" footer={() => (<b>{ (showActiveRows(IncentiveGoals).reduce((a,v) =>  a += v.MayStrategicTons === null ? 0 : v.MayStrategicTons , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>
        <Column className="text-right" footer={() => ("")}></Column>

        <Column className="text-right" footer={() => (<b>{ (showActiveRows(IncentiveGoals).reduce((a,v) =>  a += v.JunTons === null ? 0 : v.JunTons , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>  
        <Column className="text-right" footer={() => (<b>{ (showActiveRows(IncentiveGoals).reduce((a,v) =>  a += v.JunStrategicTons === null ? 0 : v.JunStrategicTons , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>
        <Column className="text-right" footer={() => ("")}></Column>

        <Column className="text-right" footer={() => (<b>{ (showActiveRows(IncentiveGoals).reduce((a,v) =>  a += v.JulTons === null ? 0 : v.JulTons , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>  
        <Column className="text-right" footer={() => (<b>{ (showActiveRows(IncentiveGoals).reduce((a,v) =>  a += v.JulStrategicTons === null ? 0 : v.JulStrategicTons , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>
        <Column className="text-right" footer={() => ("")}></Column>

        <Column className="text-right" footer={() => (<b>{ (showActiveRows(IncentiveGoals).reduce((a,v) =>  a += v.AugTons === null ? 0 : v.AugTons , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>  
        <Column className="text-right" footer={() => (<b>{ (showActiveRows(IncentiveGoals).reduce((a,v) =>  a += v.AugStrategicTons === null ? 0 : v.AugStrategicTons , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>
        <Column className="text-right" footer={() => ("")}></Column>

        <Column className="text-right" footer={() => (<b>{ (showActiveRows(IncentiveGoals).reduce((a,v) =>  a += v.SepTons === null ? 0 : v.SepTons , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>  
        <Column className="text-right" footer={() => (<b>{ (showActiveRows(IncentiveGoals).reduce((a,v) =>  a += v.SepStrategicTons === null ? 0 : v.SepStrategicTons , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>
        <Column className="text-right" footer={() => ("")}></Column>

        <Column className="text-right" footer={() => (<b>{ (showActiveRows(IncentiveGoals).reduce((a,v) =>  a += v.OctTons === null ? 0 : v.OctTons , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>  
        <Column className="text-right" footer={() => (<b>{ (showActiveRows(IncentiveGoals).reduce((a,v) =>  a += v.OctStrategicTons === null ? 0 : v.OctStrategicTons , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>
        <Column className="text-right" footer={() => ("")}></Column>

        <Column className="text-right" footer={() => (<b>{ (showActiveRows(IncentiveGoals).reduce((a,v) =>  a += v.NovTons === null ? 0 : v.NovTons , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>  
        <Column className="text-right" footer={() => (<b>{ (showActiveRows(IncentiveGoals).reduce((a,v) =>  a += v.NovStrategicTons === null ? 0 : v.NovStrategicTons , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>
        <Column className="text-right" footer={() => ("")}></Column>

        <Column className="text-right" footer={() => (<b>{ (showActiveRows(IncentiveGoals).reduce((a,v) =>  a += v.DecTons === null ? 0 : v.DecTons , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>  
        <Column className="text-right" footer={() => (<b>{ (showActiveRows(IncentiveGoals).reduce((a,v) =>  a += v.DecStrategicTons === null ? 0 : v.DecStrategicTons , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>
        <Column className="text-right" footer={() => ("")}></Column>

      </Row>
    </>
  )

  const BotonesTemplate = (rowData, props) =>  {   

    if (IsReadOnly === true){
      return (<></>);
    }

    return (
      <>
        <FieldButton
          icon="pi pi-trash"  
          className="p-button-rounded p-button-warning"
          onClick={() => onConfirmBajaIncentiveGoal(rowData,props)}
        />
      </>
    );
  }  

  const onConfirmBajaIncentiveGoal = (row, props) => {    
    /* AQUI  */    
      // aqui lo borro de mi arreglo 
      const incentiveGoalsNew = [...IncentiveGoals];

      if (incentiveGoalsNew[row.RowIndex].IsNew === true){
        incentiveGoalsNew.splice(row.RowIndex, 1);
      }
      else { 
        incentiveGoalsNew[row.RowIndex].BajaLogica = 1;
        incentiveGoalsNew[row.RowIndex].IsRowEdited = true; 
      } 

      setIncentiveGoals(incentiveGoalsNew);    
  }
 
  const onNewIncentiveProgramGoal = () => { 
    /* vamos a setear algunos valores por default??? */
    const newRow = { 
        IsNew       : true,
        ClaDivision : SalesRepInfo.ClaDivision,
        NomDivision : SalesRepInfo.NomDivision,
        BajaLogica  : 0
    };
    console.log('new', newRow);
    setIncentiveGoals([...IncentiveGoals, newRow]);
  }

  const onClaDivisionChange = (props, e ) => {
    const updateRow = [...IncentiveGoals];
    const division = OpcionesDivision.filter(item => item.ClaDivision ===e.value)[0];
    updateRow[props.rowData.RowIndex].NomDivision = null;
    if(division!==undefined){
        updateRow[props.rowData.RowIndex].NomDivision = division.NomDivision;
    }
    updateRow[props.rowData.RowIndex].ClaDivision = e.value;
    updateRow[props.rowData.RowIndex].IsRowEdited = true; 
    setIncentiveGoals(updateRow);
  };

  const ClaDivisionEditor = ( props ) => {
    if (IsReadOnly === true){ 
      return NomDivisionTemplate(props.rowData);
    }

    return (
      <div className="p-fluid">
        <FieldDropdown  
          name="ClaDivision"
          value={props.rowData.ClaDivision}
          options={OpcionesDivision}  
          optionValue="ClaDivision"
          optionLabel="NomDivision" 
          onChange={(e) => onClaDivisionChange(props,e)}
          errors={errors[`IncentiveGoals[${props.rowData.RowIndex}]`]}   
        />  
      </div>
    )
  };

  const NomDivisionTemplate = (props) => {
    return (
      <> 
        <div className='overflow-ellipsis' title={props.NomDivision}>{props.NomDivision}</div> 
      </>
      );
  };
  
  const onClaGrupoEstadistico3Change = (props, e ) => {
    const updateRow = [...IncentiveGoals];
    const grupoEstadistico3 = OpcionesGrupoEstadistico3.filter(item => item.ClaGrupoEstadistico3 ===e.value)[0];
    updateRow[props.rowData.RowIndex].NomGrupoEstadistico3 = null;
    updateRow[props.rowData.RowIndex].ClaSegmento          = null;
    updateRow[props.rowData.RowIndex].NomSegmento          = null;
    if(grupoEstadistico3!==undefined){
        updateRow[props.rowData.RowIndex].NomGrupoEstadistico3 = grupoEstadistico3.NomGrupoEstadistico3;
        updateRow[props.rowData.RowIndex].ClaSegmento          = grupoEstadistico3.ClaSegmento;
        updateRow[props.rowData.RowIndex].NomSegmento          = grupoEstadistico3.NomSegmento;
    }
    updateRow[props.rowData.RowIndex].ClaGrupoEstadistico3 = e.value; 
    updateRow[props.rowData.RowIndex].IsRowEdited = true; 
    setIncentiveGoals(updateRow);
  };

  const ClaGrupoEstadistico3Editor = ( props ) => {
    if (IsReadOnly === true){ 
      return NomGrupoEstadistico3Template(props.rowData);
    }

    return (
      <div className="p-fluid">
        <FieldDropdown  
          name="ClaGrupoEstadistico3"
          value={props.rowData.ClaGrupoEstadistico3}
          options={OpcionesGrupoEstadistico3}  
          optionValue="ClaGrupoEstadistico3"
          optionLabel="NomGrupoEstadistico3" 
          onChange={(e) => onClaGrupoEstadistico3Change(props,e)}
          errors={errors[`IncentiveGoals[${props.rowData.RowIndex}]`]}   
        />  
      </div>
    )
  };

  const NomGrupoEstadistico3Template = (props) => {
    return (
      <> 
        <div className='overflow-ellipsis' title={props.NomGrupoEstadistico3}>{props.NomGrupoEstadistico3}</div> 
      </>
      );
  };

  const NomSegmentoTemplate = (props) => {
    return (
      <> 
        <div className='overflow-ellipsis' title={props.NomSegmento}>{props.NomSegmento}</div> 
      </>
      );
  };

  const onNumberChange = (props, value) => {
    const updatedRow = [...IncentiveGoals];
    updatedRow[props.rowData.RowIndex][props.field] = value;
    updatedRow[props.rowData.RowIndex].IsRowEdited = true; 
    setIncentiveGoals(updatedRow);
  };

  const NumberEditor = (columnName, props) => {
    if (IsReadOnly === true){ 
      return NumberTemplate(columnName, props.rowData);
    }

    return (
      <div className="p-fluid">
        <FieldNumber
          name={columnName}
          value={props.rowData[columnName]}
          onChange={(e) => onNumberChange(props, e.target.value)}
          min={-99999999.99}
          max={99999999.99}
          minFractionDigits={2}
          maxFractionDigits={2}
        />
      </div>
    );
  }; 

  const NumberTemplate = (columnName, props) => { 
    return <div className='text-right'>{props[columnName] !== null && props[columnName] !== undefined ?  Highcharts.numberFormat(props[columnName], 2, '.', ',') : null}</div>;
  };  

  const fieldRequiredFlag = (rowData, props) =>  {  
    return (
      <>
        {errors[`IncentiveGoals[${props.rowData.RowIndex}]`] != null ? (<i className="fas fa-exclamation-triangle invalid-color"></i>) : ""}
      </>
    );
  }

  const valitationScheme = yup.object().shape({ 
    IncentiveGoals: yup.array().of(
      yup.object().shape({

        ClaDivision: yup.string().when('BajaLogica', {is: 0,
          then: yup.string().trim().required(t('common:RequiredField')).nullable(),
          otherwise: yup.string().nullable()
        }),
        
        ClaGrupoEstadistico3: yup.string().when('BajaLogica', {is: 0,
            then: yup.string().trim().required(t('common:RequiredField')).nullable(),
            otherwise: yup.string().nullable()
        }) 
    })) 
  });

  const onSaveClick = async () => {
    setErrors({}); 

    const value = {    
        IncentiveGoals  
    };

    const validator = new YupValidator(valitationScheme);
    
    const esValido = await validator.validate(value);
    if (!esValido){ 
        setErrors(validator.errors); 
        showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
        return;
    }

    
    const incentiveGoalsModified = [];
    let row ={};

    for (let index = 0; index < IncentiveGoals.length; index++) {

        if (IncentiveGoals[index].IsRowEdited){ 
          row = {
            ClaAnio: props.ClaAnio,
            ClaAgenteVentas: props.ClaAgenteVentas,
            ...IncentiveGoals[index]
          };
          incentiveGoalsModified.push(row); 
        }
    }
    
    console.log('modified Rows:', incentiveGoalsModified);
    /* mandamos la informacion de los renglones que se modificaron para guardar la informacion */
    const paramsServiceOwners = {
      SpreadGoalByAgentList: incentiveGoalsModified,
    };
  
    const SaveIncentiveGoalsSalesRep = `${config.UrlApiProject}SalesPerformanceSales/SaveIncentiveGoalsSalesRep`;
  
    callApi(SaveIncentiveGoalsSalesRep, 'POST', paramsServiceOwners, (response) => {
        showSweetAlert('success', t('common:SuccessOperation'), 'success');
        // mandamos refrescar la informacion del grid....
        obtieneIncentiveSalesRepInfo(); 
    });

  }
 
  return (
    <>
      <Row>
        <Col>
            
          <Card>
            <CardBody>   
              <Row>
                <Col>
                  <h3><span className="score-square-ScoreCardInfo">{t('salesPerformance:SalesRepGoals')}&nbsp;{SalesRepInfo.ClaAnio}</span></h3>
                </Col> 
              </Row>     
              <Row className='pb-2'>
                <Col>
                  <span className='h2'>{SalesRepInfo.NomAgenteVentas}</span>
                </Col>
                <Col className="text-right"> 
                  {
                    IsReadOnly === true ?
                    (<></>)
                    :
                    (
                      <>
                        <FieldButton label={t('common:New')} icon="pi pi-plus" className="secondary no-border p-mr-2" onClick={onNewIncentiveProgramGoal}  /> 
                        <FieldButton label={t('common:Save')} icon="fas fa-save" className="light-green no-border p-mr-2" onClick={onSaveClick}  /> 
                
                      </>
                    )
                  }
                </Col>
              </Row>  
              <Row className="align-items-start ">
                <Col lg={12} md={12} sm={12} xs={12}>
                  <FieldDataTable
                    value={showActiveRows(IncentiveGoals)}
                    scrollable  
                    paginator={false}
                    className="table-header-background-ligth-green frozen-editable"  
                    headerColumnGroup={headerScoreCardDetailGroup}
                    frozenHeaderColumnGroup={frozenHeaderColumnGroup} 
                    footerColumnGroup={footer}
                    frozenFooterColumnGroup={footerFrozenColumnGroup}
                    frozenWidth="675px"   
                    scrollHeight="460px"
                  > 
                    <Column body={(rowData, props) => fieldRequiredFlag(rowData, props)} headerStyle={{ width: '20px' }} frozen={true}></Column>
                    <Column body={(rowData, props) => BotonesTemplate(rowData, props)} style={{ textAlign: 'center' }} headerStyle={{ width: '50px' }} frozen={true}></Column>  
                   
                    <Column
                      field="NomDivision" 
                      columnKey="NomDivision" 
                      headerStyle={{ width: '200px' }}   
                      frozen={true}
                      editor={(props) => ClaDivisionEditor(props)}
                      body={NomDivisionTemplate} 
                      className="table-header-background-dark-green"
                    >
                    </Column>
                    <Column
                      field="NomGrupoEstadistico3" 
                      columnKey="NomGrupoEstadistico3" 
                      headerStyle={{ width: '200px' }}    
                      frozen={true} 
                      editor={(props) => ClaGrupoEstadistico3Editor(props)}
                      body={NomGrupoEstadistico3Template}
                      className="table-header-background-dark-green"
                    >
                    </Column>
                    <Column
                      field="NomSegmento" 
                      columnKey="NomSegmento" 
                      headerStyle={{ width: '200px' }}   
                      className="table-header-background-dark-green border-right"
                      body={NomSegmentoTemplate}
                      frozen={true}
                    >
                    </Column>
                    <Column
                      field="JanTons" 
                      columnKey="JanTons"
                      headerStyle={{ width: '100px' }}   
                      body={(rowData, props) => NumberTemplate("JanTons", rowData)}
                      editor={(props) => NumberEditor("JanTons", props)}
                    >
                    </Column>
                    <Column
                      field="JanStrategicTons" 
                      columnKey="JanStrategicTons"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("JanStrategicTons", rowData)}
                      editor={(props) => NumberEditor("JanStrategicTons", props)}
                    >
                    </Column>
                    <Column
                      field="JanSpread" 
                      columnKey="JanSpread"
                      headerStyle={{ width: '100px' }}   
                      className="table-header-background-dark-green border-right text-center"
                      body={(rowData, props) => NumberTemplate("JanSpread", rowData)}
                      editor={(props) => NumberEditor("JanSpread", props)}
                    >
                    </Column>
                    <Column
                      field="FebTons" 
                      columnKey="FebTons"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("FebTons", rowData)}
                      editor={(props) => NumberEditor("FebTons", props)}
                    >
                    </Column>
                    <Column
                      field="FebStrategicTons" 
                      columnKey="FebStrategicTons"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("FebStrategicTons", rowData)}
                      editor={(props) => NumberEditor("FebStrategicTons", props)}
                    >
                    </Column>
                    <Column
                      field="FebSpread" 
                      columnKey="FebSpread"
                      headerStyle={{ width: '100px' }}   
                      className="table-header-background-dark-green border-right text-center"
                      body={(rowData, props) => NumberTemplate("FebSpread", rowData)}
                      editor={(props) => NumberEditor("FebSpread", props)}
                    >
                    </Column>
                    <Column
                      field="MarTons" 
                      columnKey="MarTons"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("MarTons", rowData)}
                      editor={(props) => NumberEditor("MarTons", props)}
                    >
                    </Column>
                    <Column
                      field="MarStrategicTons" 
                      columnKey="MarStrategicTons"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("MarStrategicTons", rowData)}
                      editor={(props) => NumberEditor("MarStrategicTons", props)}
                    >
                    </Column>
                    <Column
                      field="MarSpread" 
                      columnKey="MarSpread"
                      headerStyle={{ width: '100px' }}   
                      className="table-header-background-dark-green border-right text-center"
                      body={(rowData, props) => NumberTemplate("MarSpread", rowData)}
                      editor={(props) => NumberEditor("MarSpread", props)}
                    >
                    </Column>
                    <Column
                      field="AprTons" 
                      columnKey="AprTons"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("AprTons", rowData)}
                      editor={(props) => NumberEditor("AprTons", props)}
                    >
                    </Column>
                    <Column
                      field="AprStrategicTons" 
                      columnKey="AprStrategicTons"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("AprStrategicTons", rowData)}
                      editor={(props) => NumberEditor("AprStrategicTons", props)}
                    >
                    </Column>
                    <Column
                      field="AprSpread" 
                      columnKey="AprSpread"
                      headerStyle={{ width: '100px' }}   
                      className="table-header-background-dark-green border-right text-center"
                      body={(rowData, props) => NumberTemplate("AprSpread", rowData)}
                      editor={(props) => NumberEditor("AprSpread", props)}
                    >
                    </Column>
                    <Column
                      field="MayTons" 
                      columnKey="MayTons"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("MayTons", rowData)}
                      editor={(props) => NumberEditor("MayTons", props)}
                    >
                    </Column>
                    <Column
                      field="MayStrategicTons" 
                      columnKey="MayStrategicTons"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("MayStrategicTons", rowData)}
                      editor={(props) => NumberEditor("MayStrategicTons", props)}
                    >
                    </Column>
                    <Column
                      field="MaySpread" 
                      columnKey="MaySpread"
                      headerStyle={{ width: '100px' }}   
                      className="table-header-background-dark-green border-right text-center"
                      body={(rowData, props) => NumberTemplate("MaySpread", rowData)}
                      editor={(props) => NumberEditor("MaySpread", props)}
                    >
                    </Column>

                    <Column
                      field="JunTons" 
                      columnKey="JunTons"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("JunTons", rowData)}
                      editor={(props) => NumberEditor("JunTons", props)}
                    >
                    </Column>
                    <Column
                      field="JunStrategicTons" 
                      columnKey="JunStrategicTons"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("JunStrategicTons", rowData)}
                      editor={(props) => NumberEditor("JunStrategicTons", props)}
                    >
                    </Column>
                    <Column
                      field="JunSpread" 
                      columnKey="JunSpread"
                      headerStyle={{ width: '100px' }}   
                      className="table-header-background-dark-green border-right text-center"
                      body={(rowData, props) => NumberTemplate("JunSpread", rowData)}
                      editor={(props) => NumberEditor("JunSpread", props)}
                    >
                    </Column>

                    <Column
                      field="JulTons" 
                      columnKey="JulTons"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("JulTons", rowData)}
                      editor={(props) => NumberEditor("JulTons", props)}
                    >
                    </Column>
                    <Column
                      field="JulStrategicTons" 
                      columnKey="JulStrategicTons"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("JulStrategicTons", rowData)}
                      editor={(props) => NumberEditor("JulStrategicTons", props)}
                    >
                    </Column>
                    <Column
                      field="JulSpread" 
                      columnKey="JulSpread"
                      headerStyle={{ width: '100px' }}   
                      className="table-header-background-dark-green border-right text-center"
                      body={(rowData, props) => NumberTemplate("JulSpread", rowData)}
                      editor={(props) => NumberEditor("JulSpread", props)}
                    >
                    </Column>

                    <Column
                      field="AugTons" 
                      columnKey="AugTons"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("AugTons", rowData)}
                      editor={(props) => NumberEditor("AugTons", props)}
                    >
                    </Column>
                    <Column
                      field="AugStrategicTons" 
                      columnKey="AugStrategicTons"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("AugStrategicTons", rowData)}
                      editor={(props) => NumberEditor("AugStrategicTons", props)}
                    >
                    </Column>
                    <Column
                      field="AugSpread" 
                      columnKey="AugSpread"
                      headerStyle={{ width: '100px' }}   
                      className="table-header-background-dark-green border-right text-center"
                      body={(rowData, props) => NumberTemplate("AugSpread", rowData)}
                      editor={(props) => NumberEditor("AugSpread", props)}
                    >
                    </Column>

                    <Column
                      field="SepTons" 
                      columnKey="SepTons"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("SepTons", rowData)}
                      editor={(props) => NumberEditor("SepTons", props)}
                    >
                    </Column>
                    <Column
                      field="SepStrategicTons" 
                      columnKey="SepStrategicTons"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("SepStrategicTons", rowData)}
                      editor={(props) => NumberEditor("SepStrategicTons", props)}
                    >
                    </Column>
                    <Column
                      field="SepSpread" 
                      columnKey="SepSpread"
                      headerStyle={{ width: '100px' }}   
                      className="table-header-background-dark-green border-right text-center"
                      body={(rowData, props) => NumberTemplate("SepSpread", rowData)}
                      editor={(props) => NumberEditor("SepSpread", props)}
                    >
                    </Column>

                    <Column
                      field="OctTons" 
                      columnKey="OctTons"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("OctTons", rowData)}
                      editor={(props) => NumberEditor("OctTons", props)}
                    >
                    </Column>
                    <Column
                      field="OctStrategicTons" 
                      columnKey="OctStrategicTons"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("OctStrategicTons", rowData)}
                      editor={(props) => NumberEditor("OctStrategicTons", props)}
                    >
                    </Column>
                    <Column
                      field="OctSpread" 
                      columnKey="OctSpread"
                      headerStyle={{ width: '100px' }}   
                      className="table-header-background-dark-green border-right text-center"
                      body={(rowData, props) => NumberTemplate("OctSpread", rowData)}
                      editor={(props) => NumberEditor("OctSpread", props)}
                    >
                    </Column>

                    <Column
                      field="NovTons" 
                      columnKey="NovTons"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("NovTons", rowData)}
                      editor={(props) => NumberEditor("NovTons", props)}
                    >
                    </Column>
                    <Column
                      field="NovStrategicTons" 
                      columnKey="NovStrategicTons"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("NovStrategicTons", rowData)}
                      editor={(props) => NumberEditor("NovStrategicTons", props)}
                    >
                    </Column>
                    <Column
                      field="NovSpread" 
                      columnKey="NovSpread"
                      headerStyle={{ width: '100px' }}   
                      className="table-header-background-dark-green border-right text-center"
                      body={(rowData, props) => NumberTemplate("NovSpread", rowData)}
                      editor={(props) => NumberEditor("NovSpread", props)}
                    >
                    </Column>

                    <Column
                      field="DecTons" 
                      columnKey="DecTons"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("DecTons", rowData)}
                      editor={(props) => NumberEditor("DecTons", props)}
                    >
                    </Column>
                    <Column
                      field="DecStrategicTons" 
                      columnKey="DecStrategicTons"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("DecStrategicTons", rowData)}
                      editor={(props) => NumberEditor("DecStrategicTons", props)}
                    >
                    </Column>
                    <Column
                      field="DecSpread" 
                      columnKey="DecSpread"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("DecSpread", rowData)}
                      editor={(props) => NumberEditor("DecSpread", props)}
                    >
                    </Column>
                  </FieldDataTable> 
                </Col>
              </Row> 
            </CardBody>
            {
              typeof(props.showCloseButton) !== 'undefined'
              ?
              (
                <CardFooter hidden={!props.showCloseButton}>
                  <Row>
                    <Col className='text-center'>
                      <FieldButton
                        label={t('common:Close')}
                        icon="fas fa-times"
                        className="warning no-border p-mr-2 rounded-button"
                        onClick={typeof(props.onCloseClick) !== 'undefined' && typeof(props.onCloseClick) === 'function' ? props.onCloseClick : null}
                      />
                    </Col>
                  </Row>
                </CardFooter>
              )
              :
              (<></>)
            }
            
          </Card>
        </Col>
      </Row>  
    </>
  );
};
export default IncentiveProgramsGoalsSalesRep;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState }  from 'react';     
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'reactstrap'; 
import FieldKeyboard from "./Keyboard/FieldKeyboard";
import { InputText } from 'primereact/inputtext';

const FieldNumber =  (props) => {
    
    const [ShowKeyboard, setShowKeyboard] = useState(false);
    const onChange = (value) => {  
        if (props.onChange !== null){
            props.onChange(value);
        } 
    }  

    const onFocus = (event) => {
      event.target.select();
      if (props.onFocus !== null && typeof(props.onFocus) === 'function'){
         props.onFocus(event);
      } 
    }

    
    const isFormFieldInValid = (name) => !!(typeof(props.errors) !== 'undefined' && props.errors !== null && props.errors[name]);
    
    const getFormErrorMessage = (name) => { 
        return isFormFieldInValid(name) && <small className="p-error">{props.errors[name].message}</small>;
    };

    const onClickKeyboard = () => {
      setShowKeyboard(true);
    }

    const onChangekeyboard = (text) => {
      if (props.onChange !== null && props.onChange !== undefined){
        const e = {
          target: {
            value: text
          }
        }
 
        props.onChange(e);
      } 
    }
  

    if (props.IsShowKeyboard){
      return (
        <>
          <div className="p-inputgroup">
            
            {
              props.IsShowText ?
              (
                <>
                  <InputText 
                    value={props.value === null ? '' : props.value} 
                    onChange={onChange} 
                    className={isFormFieldInValid(props.name) === true ? 'p-invalid' : ''}
                    disabled={props.disabled} 
                    placeholder={props.placeholder}
                    ref={props.reference} 
                    onFocus={onClickKeyboard}
                  />  
                </>
)
              :
              (
                <>
                  <InputNumber
                    value={props.value === null ? null : props.value} 
                    onValueChange={onChange}
                    className={isFormFieldInValid(props.name) === true ? 'p-invalid' : ''}
                    mode="decimal"
                    locale="en-US"
                    minFractionDigits={props.minFractionDigits}
                    maxFractionDigits={props.maxFractionDigits}
                    showButtons={props.showButtons}
                    min={props.min}
                    max={props.max} 
                    useGrouping={props.useGrouping}
                    buttonLayout={props.buttonLayout}
                    decrementButtonClassName={props.decrementButtonClassName}
                    incrementButtonClassName={props.incrementButtonClassName}
                    incrementButtonIcon={props.incrementButtonIcon} 
                    decrementButtonIcon={props.decrementButtonIcon} 
                    disabled={props.disabled} 
                    onFocus={onClickKeyboard}
                  /> 
                </>
)
            }
             
             
            <Button
              disabled={props.disabled} 
              className={props.classGroup} 
              onClick={onClickKeyboard}
            > 
              <i className="fas fa-keyboard"></i>
            </Button>
          </div>
          <span className="p-float-label">
            <label className={isFormFieldInValid(props.name) === true ? 'p-error' : ''}>{props.label}</label>
          </span>
          { getFormErrorMessage(props.name) }   
          {
            ShowKeyboard ?
            (<FieldKeyboard show={ShowKeyboard} label={props.title} isNumeric={true} value={props.value} onHide={() => setShowKeyboard(!ShowKeyboard)} onChange={(text) => onChangekeyboard(text)} />)
            :
            (<></>)
          }          
        </> 
      )
    }


    if(props.isInputGroup){
      return ( 
        <> 
          <div className="p-inputgroup">
            <span hidden={!props.isGroupLeft} className="p-inputgroup-addon">
              <i className={props.classGroupLeft}>
                {props.labelGroupLeft}
              </i>
            </span>
            <InputNumber
              value={props.value === null ? null : props.value} 
              onValueChange={onChange}
              className={isFormFieldInValid(props.name) === true ? 'p-invalid' : ''}
              mode="decimal"
              locale="en-US"
              minFractionDigits={props.minFractionDigits}
              maxFractionDigits={props.maxFractionDigits}
              showButtons={props.showButtons}
              min={props.min}
              max={props.max}
              useGrouping={props.useGrouping}
              buttonLayout={props.buttonLayout}
              decrementButtonClassName={props.decrementButtonClassName}
              incrementButtonClassName={props.incrementButtonClassName}
              incrementButtonIcon={props.incrementButtonIcon} 
              decrementButtonIcon={props.decrementButtonIcon} 
              disabled={props.disabled}
              onFocus={onFocus}
            />   
            <span className="p-inputgroup-addon" hidden={!props.isGroupRight}>
              <i className={props.labelGroupRight}>
                {props.labelGroupRight}
              </i>
            </span>
          </div>
          <span className="p-float-label">
            <label className={isFormFieldInValid(props.name) === true ? 'p-error' : ''}>{props.label}</label>
          </span>
          { getFormErrorMessage(props.name) } 
        </> 
      )
    }
      return (
        <> 
          <span className="p-float-label">
            <InputNumber
              value={props.value === null ? null : props.value} 
              onValueChange={onChange}
              className={isFormFieldInValid(props.name) === true ? 'p-invalid' : ''}
              mode="decimal"
              locale="en-US"
              minFractionDigits={props.minFractionDigits}
              maxFractionDigits={props.maxFractionDigits}
              showButtons={props.showButtons}
              min={props.min}
              max={props.max} 
              useGrouping={props.useGrouping}
              buttonLayout={props.buttonLayout}
              decrementButtonClassName={props.decrementButtonClassName}
              incrementButtonClassName={props.incrementButtonClassName}
              incrementButtonIcon={props.incrementButtonIcon} 
              decrementButtonIcon={props.decrementButtonIcon} 
              disabled={props.disabled} 
              onFocus={onFocus}
            />  
            <label className={isFormFieldInValid(props.name) === true ? 'p-error' : ''}>{props.label}</label>
          </span>
          { getFormErrorMessage(props.name) } 
        </> 
      )
}   

    
export default FieldNumber; 

/* eslint-disable react/no-this-in-sfc */
/* eslint-disable object-shorthand */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  ListGroupItem,
  ListGroup,
  Progress,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from 'reactstrap';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { Badge } from 'primereact/badge';
import { Link } from 'react-router-dom';
import ActionPlanScoreSquare from './ActionPlanScoreSquare.jsx';
import { Toolbar } from 'primereact/toolbar';
import Highcharts from 'highcharts';
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert,tracker } from '../../../../utils/utils'; 
import HighchartsReact from 'highcharts-react-official';
import FieldButton from '../../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable.jsx';
import { Dialog } from 'primereact/dialog';
import moment from "moment"; 
import { ScrollPanel } from 'primereact/scrollpanel';
import ActionPlan from '../ActionPlans/ActionPlan.jsx';
import HighchartsExporting from 'highcharts-exporting';
import KPITrendGraphAttachment from './KPITrendGraphAttachment.jsx';
import { Tooltip } from 'primereact/tooltip';
import SweatAlertCustomIcon from 'components/SweatAlert/SweatAlertCustomIcon.jsx';
import swal from '@sweetalert/with-react';

HighchartsExporting(Highcharts);
const KPITrendGraph = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'focusReport', 'common']);
  const [PosicionesRelacionadasList, setPosicionesRelacionadasList] = useState([]);
  const [ClaPeriodo, setClaPeriodo] = useState(null);
  const [ClaFechaReporte, setClaFechaReporte] = useState(null);
  const [IdCSF, setIdCSF] = useState(null);
  const [Base64Image, setBase64Image] = useState(null);
  const [Chart, setChart] = useState(null);
  const [Titulo, setTitulo] = useState('');
  const [Subtitulo, setSubtitulo] = useState('');
  const [Serie1, setSerie1] = useState('');
  const [Serie2, setSerie2] = useState('');
  const [Serie3, setSerie3] = useState('');
  const [Serie4, setSerie4] = useState('');
  const [Serie5, setSerie5] = useState('');
  const [Serie6, setSerie6] = useState('');
  const [Serie7, setSerie7] = useState('');
  const [ColorSerie1, setColorSerie1] = useState('');
  const [ColorSerie2, setColorSerie2] = useState('');
  const [ColorSerie3, setColorSerie3] = useState('');
  const [ColorSerie4, setColorSerie4] = useState('');
  const [ColorSerie5, setColorSerie5] = useState('');
  const [ColorSerie6, setColorSerie6] = useState('');
  const [ColorSerie7, setColorSerie7] = useState('');
  const [SerieValues1, setSerieValues1] = useState([]);
  const [SerieValues2, setSerieValues2] = useState([]);
  const [SerieValues3, setSerieValues3] = useState([]);
  const [SerieValues4, setSerieValues4] = useState([]);
  const [SerieValues5, setSerieValues5] = useState([]);
  const [SerieValues6, setSerieValues6] = useState([]);
  const [SerieValues7, setSerieValues7] = useState([]);
  const [xAxisCategories, setxAxisCategories] = useState([]);
  const [xAxisName, setxAxisName] = useState('');
  const [ClaSentidoMejora, setClaSentidoMejora] = useState('');
  const [NoElem, setNoElem] = useState(0);
  const [MainCausesInfo, setMainCausesInfo] = useState([]);
  const [CompletedInfo, setCompletedInfo] = useState([]);
  const [PendingInfo, setPendingInfo] = useState([]);
  const [ActionPlanReviewLogList, setActionPlanReviewLogList] = useState([]);
  const [EsAbrirActionPlan, setEsAbrirActionPlan] = useState(false);
  const [selectedAPRow, setselectedAPRow] = useState({}); 
  const chartRef = useRef(null);
  const [EsSubirArchivos, setEsSubirArchivos] = useState(false);
  const [ClaPuesto, setClaPuesto] = useState(null);
  const [ClaTipoIndicador, setClaTipoIndicador] = useState(null);
  const [ClaIndicador, setClaIndicador] = useState(null); 
  const [IdPuestoIndicadorActionPlanReviewLog, setIdPuestoIndicadorActionPlanReviewLog] = useState(null); 

  useEffect(() => { 
    setClaPeriodo(props.ClaPeriodo === undefined ? 0 : props.ClaPeriodo); 
    setClaFechaReporte(props.ClaFechaReporte === undefined ? 0 : props.ClaFechaReporte);
    setIdCSF(props.IdCSF === undefined ? 0 : props.IdCSF);

    setTitulo('KPI - 415 - Team Performance Index');
    setSubtitulo('CSF - DANIEL GERARDO OROZCO MORALES');
    GetKPITendenciaChart(
      props.ClaPeriodo === undefined ? 0 : props.ClaPeriodo,
      props.IdCSF === undefined ? 0 : props.IdCSF,
      props.ClaFechaReporte === undefined ? 0 : props.ClaFechaReporte
    ); 
 
  }, [props.IdCSF, props.ClaPeriodo, props.ClaFechaReporte, props.show]);

  const GetKPITendenciaChart = (ClaPeriodo, IdCSF, ClaFechaReporte) => {
    const GetKPITendenciaChart = `${config.UrlApiProject}ScoreCard/GetKPITendenciaChart`;
    const paramsToService = {
      IdCSF,
      ClaPeriodo,
      ClaFechaReporte 
    };

    callApi(GetKPITendenciaChart, 'POST', paramsToService, (response) => {  
        setTitulo(response.data.ConfiguracionGrafica.Titulo);
        setSubtitulo(response.data.ConfiguracionGrafica.Subtitulo);
        setxAxisName(response.data.ConfiguracionGrafica.xAxisName);
        setNoElem(response.data.ConfiguracionGrafica.NoElem);
        setSerie1(response.data.ConfiguracionGrafica.Serie1);
        setSerie2(response.data.ConfiguracionGrafica.Serie2);
        setSerie3(response.data.ConfiguracionGrafica.Serie3);
        setSerie4(response.data.ConfiguracionGrafica.Serie4);
        setSerie5(response.data.ConfiguracionGrafica.Serie5);
        setSerie6(response.data.ConfiguracionGrafica.Serie6);
        setSerie7(response.data.ConfiguracionGrafica.Serie7);
        setColorSerie1(response.data.ConfiguracionGrafica.ColorSerie1);
        setColorSerie2(response.data.ConfiguracionGrafica.ColorSerie2);
        setColorSerie3(response.data.ConfiguracionGrafica.ColorSerie3);
        setColorSerie4(response.data.ConfiguracionGrafica.ColorSerie4);
        setColorSerie5(response.data.ConfiguracionGrafica.ColorSerie5);
        setColorSerie6(response.data.ConfiguracionGrafica.ColorSerie6);
        setColorSerie7(response.data.ConfiguracionGrafica.ColorSerie7);
        setSerieValues1(response.data.Serie1);
        setSerieValues2(response.data.Serie2);
        setSerieValues3(response.data.Serie3);
        setSerieValues4(response.data.Serie4);
        setSerieValues5(response.data.Serie5);
        setSerieValues6(response.data.Serie6);
        setSerieValues7(response.data.Serie7);
        setxAxisCategories(response.data.xAxisCategories);
        setClaSentidoMejora(response.data.ConfiguracionGrafica.ClaSentidoMejora);
        setMainCausesInfo(response.data.MainCauses);
        setCompletedInfo(response.data.Completed);
        setPendingInfo(response.data.Pending);
        setIdPuestoIndicadorActionPlanReviewLog(response.data.PuestoIndicador[0].IdPuestoIndicadorActionPlanReviewLog);
        setClaPuesto(response.data.PuestoIndicador[0].ClaPuesto);
        setClaTipoIndicador(response.data.PuestoIndicador[0].ClaTipoIndicador);
        setClaIndicador(response.data.PuestoIndicador[0].ClaIndicador);
        setActionPlanReviewLogList(response.data.PuestoIndicadorActionPlanReviewLog);
        // setPosicionesRelacionadasList(response.data.PosicionesRelacionadas); 
    });
  }; 

  const afterSaveAttachment = (pClaPuesto, pClaIndicador, pClaTipoIndicador) => {
    const GetKPITendenciaActionPlanReviewLog = `${config.UrlApiProject}ScoreCard/GetKPITendenciaActionPlanReviewLogLista`;
    const paramsToService = {
      ClaPuesto:pClaPuesto,
      ClaIndicador:pClaIndicador,
      ClaTipoIndicador:pClaTipoIndicador
    };
    callApi(GetKPITendenciaActionPlanReviewLog, 'POST', paramsToService, (response) => {
      setActionPlanReviewLogList(response.data.ActionPlanReviewLog);
    });
  };

  const GetKPITendenciaPrint = () => {
    if (chartRef.current && chartRef.current.chart) {
      const { chart } = chartRef.current;
      const svg = chart.getSVG();

      // Crear un nuevo elemento Image
      const img = new Image();
      const svgData = `data:image/svg+xml;base64,${btoa(svg)}`;

      // Configurar el src de la imagen con los datos SVG codificados en base64
      img.src = svgData;

      // Asegúrate de que la imagen esté cargada antes de intentar dibujarla en el canvas
      img.onload = function() {
          // Crear un canvas para dibujar la imagen
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          // Configura el canvas con el tamaño de la imagen
          canvas.width = img.width;
          canvas.height = img.height;
          // Dibuja la imagen en el canvas
          ctx.drawImage(img, 0, 0);
          // Obtén los datos de la imagen como un URI de datos en formato PNG
          const dataURL = canvas.toDataURL('image/png');
          // Extrae el base64 del URI de datos
          const base64 = dataURL.split(',')[1];

          /*
          const svgString = chartRef.current.chart.getSVG();
          const parser = new DOMParser();
          const svgElem = parser.parseFromString(svgString, "image/svg+xml").documentElement;
          const b64 = svgElem.toDataURL();
          */
          const GetKPITendenciaPrint = `${config.UrlApiProject}ScoreCard/GetKPITendenciaPrint`;
          const paramsToService = {
            IdCSF: props.IdCSF,
            ClaPeriodo: props.ClaPeriodo,
            ClaFechaReporte: props.ClaFechaReporte,
            FileData: base64
          };

          callApi(GetKPITendenciaPrint, 'POST', paramsToService, (response) => {
            const linkSource = `data:application/${response.data.FileExtension};base64,${response.data.FileData}`;
            const downloadLink = document.createElement("a");
            const fileName = `kpiTendencia_report_${props.IdCSF}`;
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
          });

      }; 
      
    } else {
      console.error('Chart not available');
    }
  };

  Highcharts.wrap(Highcharts.Series.prototype, 'drawPoints', function(p) {
    const {options} = this;
    const symbolCallback = options.marker && options.marker.symbolCallback;
    const {points} = this;
    if (symbolCallback && points.length) {
      points.forEach(point => {
        const symbol = symbolCallback.call(point);
        if (symbol) {
          point.update({
            marker: {
                fillColor: symbol
            }
          }, false)
        }
      })
    }
    p.call(this); 
  })


  const kpiTrendChart = {

    title: {
        text: Titulo
    },

    subtitle: {
        text: Subtitulo
    },  
    xAxis: {
        title: {
            text: ''
        },
        categories: xAxisCategories
    },   
    yAxis: {
        title: {
            text: xAxisName
        } 
    }, 
    tooltip: {
        crosshairs: true,
        shared: true
    },
    legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle'
    }, 

    series: [{
        type: NoElem <= 2 ? 'line' : 'line',
        name: Serie1,
        color: ColorSerie1,
        data: SerieValues1,
        zIndex: 1,
        marker: { 
            fillColor: 'white',
            lineWidth: 2,
            lineColor: ColorSerie1,
            symbolCallback: function() { 
                let minValue = null;
                let outstandingValue = null;

                if (SerieValues2.length > 0) {
                   minValue = SerieValues2[this.index];
                }

                if (SerieValues3.length > 0) {
                    outstandingValue = SerieValues3[this.index];
                }

                if (ClaSentidoMejora === 1) {
                    if (this.y < minValue){ 
                        return 'red';
                    }

                    if (this.y > outstandingValue){ 
                        return 'MediumSeaGreen';
                    }
                }

                if (ClaSentidoMejora === 2) {
                    if (this.y > minValue){ 
                        return 'red';
                    }

                    if (this.y < outstandingValue){ 
                        return 'MediumSeaGreen';
                    }   
                } 

                return 'white';
              }
        } 
    }, {
        type: 'arearange',
        name: Serie7, 
        data: SerieValues7,
        lineWidth: 0,
        linkedTo: ':previous',
        color:  '#7cb5ec',// Highcharts.getOptions().colors[0],
        fillOpacity: 0.2,
        zIndex: 0,
        marker: {
            enabled: false
        }
    } , {
        type: 'line',
        name: Serie2,
        color: ColorSerie2,
        data: SerieValues2,
        marker: {
            enabled: false
        },
        visible: false
    }, {
        type: 'line',
        name: Serie3,
        color: ColorSerie3,
        data: SerieValues3,
        marker: {
            enabled: false
        } ,
        visible: false
    }, {
        type: 'line',
        name: Serie4,
        color: ColorSerie4,
        data: SerieValues4,
        marker: {
            enabled: false
        } ,
        visible: false
    }, {
        type: 'line',
        name: Serie5,
        color: ColorSerie5,
        data: SerieValues5,
        marker: {
            enabled: false
        },
        visible: false
    }, {
        type: 'line',
        name: Serie6,
        color: ColorSerie6,
        data: SerieValues6,
        marker: {
            enabled: false
        },
        visible: false
    }
], 
  responsive: {
        rules: [{
            condition: {
                maxWidth: 500
            },
            chartOptions: {
                legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom'
                }
            }
        }]
    }  
}

const FechaCompromisoTemplate = (rowData) => {
  return (
    <>
      { rowData.FechaCompromiso !== null ? moment(rowData.FechaCompromiso).format("MM/DD/YYYY") : rowData.FechaCompromiso }           
    </>
  );
}

const FechaCompletadaTemplate = (rowData) => {
  return (
    <>
      { rowData.FechaCompletada !== null ? moment(rowData.FechaCompletada).format("MM/DD/YYYY") : rowData.FechaCompletada }           
    </>
  );
}

const AtrasadoTemplate = (rowData) => {
  return (
    <>
      { rowData.Atrasado }           
    </>
  );
}

console.log(Highcharts.getOptions().colors[0]);

const AccionesTemplateAp = (row) => {
  let className = '';

  switch (row.PlanAccionBandera) {  
    case 0:
      className = "fas fa-flag-checkered Cursor";
      break;
    case 1:
      className = "fa fa-flag danger-color Cursor";
      break;
    case 2:
      className = "fa fa-flag warning-color Cursor";
      break;
    case 3:
      className = "fa fa-flag success-color Cursor";        
      break;
    default:
      className = "fa fa-flag default-color Cursor";
  }
  return (
    <>
      <div onClick={() => onClickActionPlan(row)}>   
        <i className={className}></i>
      </div>
    </>
  );
}

const onClickActionPlan = (row) => { 
  setselectedAPRow(row);
  setClaPeriodo(row.ClaPeriodo);
  setClaFechaReporte(row.ClaFechaReporte);
  setIdCSF(row.IdCSF);
  setEsAbrirActionPlan(true);

  tracker.trackEvent({
    type: 'KPITrendGraph', /* component name */
    action: 'CLICK_ACTION_PLAN'
  }) 
}

const onHideActionPlan = () => {
  setEsAbrirActionPlan(false);
  // GetProjectActivitiesScoreCardFocusReport();
}

const MainCauseTemplate = (rowData) => {
  return (
    <> 
      <div className='overflow-ellipsis' title={rowData.MainCause}>{rowData.MainCause}</div> 
    </>
  );
}
const ActionCompletedTemplate = (rowData) => {
  return (
    <> 
      <div className='overflow-ellipsis' title={rowData.Accion}>{rowData.Accion}</div> 
    </>
  );
}

const ActionPendingTemplate = (rowData) => {
  return (
    <> 
      <div className='overflow-ellipsis' title={rowData.Accion}>{rowData.Accion}</div> 
    </>
  );
}

const MainCausesTemplate = () => {
  return (
    <>
      <Row className="align-items-start pt-2">
        <Col lg={12} md={12} sm={12} xs={12}>
          <h3>
            {t('focusReport:MainCauses')}
          </h3>
        </Col>
      </Row>
      <Row className="align-items-start">
        <Col lg={12} md={12} sm={12} xs={12}>
          <FieldDataTable
            value={MainCausesInfo}
            scrollable 
            paginator={false} 
            className="table-header-background-blue"
          >
            <Column
              field="Periodo" 
              header={t('focusReport:Month')}
              columnKey="Periodo"
              headerStyle={{ width: '80px' }}
            >
            </Column>
            <Column
              className="text-center"
              header={t('focusReport:Ap')}
              headerStyle={{ width: '25px', textAlign: 'center' }}                  
              body={AccionesTemplateAp}
            >
            </Column>
            <Column
              field="MainCause"
              header={t('focusReport:MainCause')}
              columnKey="MainCause"
              headerStyle={{ width: '400px' }}            
              body={MainCauseTemplate}
            >
            </Column>
          </FieldDataTable>  
        </Col>
      </Row>
    </>
  );
};

const ActionsCompletedInfoTemplate = () => {
  return (
    <>
      <Row className="align-items-start pt-2">
        <Col lg={12} md={12} sm={12} xs={12}>
          <h3>
            {t('focusReport:ActionsCompleted')}
          </h3>
        </Col>
      </Row>
      <Row className="align-items-start">
        <Col lg={12} md={12} sm={12} xs={12}>
          <FieldDataTable
            value={CompletedInfo}
            scrollable 
            paginator={false} 
            className="table-header-background-blue"
          >
            <Column
              field="Periodo" 
              header={t('focusReport:Month')}
              columnKey="Periodo"
              headerStyle={{ width: '80px' }}
            >
            </Column>
            <Column
              field="Accion" 
              header={t('focusReport:Action')}
              columnKey="Accion"
              headerStyle={{ width: '400px'}}           
              body={ActionCompletedTemplate}
            >
            </Column>
            <Column
              field="NomResponsable" 
              header={t('focusReport:Responsible')}
              columnKey="NomResponsable"
              headerStyle={{ width: '200px'}}
            >
            </Column>
            <Column
              field="FechaCompromiso" 
              className="text-center"
              header={t('focusReport:DueDate')}
              body={FechaCompromisoTemplate}
              columnKey="FechaCompromiso"
              headerStyle={{ width: '150px'}}
            >
            </Column>
            <Column
              field="FechaCompletada" 
              className="text-center"
              header={t('focusReport:CompletedDate')}
              body={FechaCompletadaTemplate}
              columnKey="FechaCompletada"
              headerStyle={{ width: '150px'}}
            >
            </Column>
          </FieldDataTable>  
        </Col>
      </Row>
    </>
  );
};

const ActionsPendingInfoTemplate = () => {
  return (
    <>
      <Row className="align-items-start pt-2">
        <Col lg={12} md={12} sm={12} xs={12}>
          <h3>
            {t('focusReport:ActionsPending')}
          </h3>
        </Col>
      </Row>
      <Row className="align-items-start">
        <Col lg={12} md={12} sm={12} xs={12}>
          <FieldDataTable
            value={PendingInfo}
            scrollable 
            paginator={false} 
            className="table-header-background-blue"
          >
            <Column
              field="Periodo" 
              header={t('focusReport:Month')}
              columnKey="Periodo"
              headerStyle={{ width: '80px' }}
            >
            </Column>
            <Column
              field="Accion" 
              header={t('focusReport:Action')}
              columnKey="Accion"
              headerStyle={{ width: '400px' }}       
              body={ActionPendingTemplate}
            >
            </Column>
            <Column
              field="NomResponsable" 
              header={t('focusReport:Responsible')}
              columnKey="NomResponsable"
              headerStyle={{ width: '200px'}}
            >
            </Column>
            <Column
              field="FechaCompromiso" 
              header={t('focusReport:DueDate')}
              body={FechaCompromisoTemplate}
              columnKey="FechaCompromiso"
              headerStyle={{ width: '150px'}}
            >
            </Column>
            <Column
              field="Atrasado" 
              className="text-center"
              header={t('focusReport:Overdue')}
              body={AtrasadoTemplate}
              columnKey="Atrasado"
              headerStyle={{ width: '150px'}}
            >
            </Column>
          </FieldDataTable>  
        </Col>
      </Row>
    </>
  );
};

const verActionPlanReviewLog = (row) => { 
  setIdPuestoIndicadorActionPlanReviewLog(row.IdPuestoIndicadorActionPlanReviewLog);
  setEsSubirArchivos(true);
}

const confirmationMessageHTML = (
  <div>
    <SweatAlertCustomIcon title={t('common:SubmitConfirmation')} letter={t('common:QuestionMark')}></SweatAlertCustomIcon> 
    <div>{t('actionPlan:SubmitRemoveDocument')}. </div> 
  </div>  
)

const onSubmitDelete = async (oRow) => { 
  const paramsService = {
    IdPuestoIndicadorActionPlanReviewLog: oRow.IdPuestoIndicadorActionPlanReviewLog
 };   
 const deleteActionPlanReviewLog = `${config.UrlApiProject}ScoreCard/DeleteActionPlanReviewLog`;
 callApi(deleteActionPlanReviewLog, 'POST', paramsService, (response) => { 
  afterSaveAttachment(oRow.ClaPuesto,oRow.ClaIndicador, oRow.ClaTipoIndicador)
 });   
} 

const onConfirmRemoveSubmit = (row) => {  
  swal({ 
    buttons: {
      cancelar: {
        text: "Cancel",
        value: "cancel",
        className: "rounded-button danger"
      },
      continuar: {
        text: "Continue",
        value: "continue",
        className: "rounded-button light-green"
      }
    },
    content: confirmationMessageHTML
  }).then((value) => {
    switch (value) { 
      case "continue": 
        onSubmitDelete(row);
        break; 
      default:
        break;
    }
  }); 
}

const AnexoInfo = (rowData) => {
  return (
    <>   
      <Col className="text-right">
        <Tooltip target=".p-button-danger" mouseTrack mouseTrackTop={10} />
        <FieldButton
          type="button" 
          onClick={() => verActionPlanReviewLog(rowData)}
          data-pr-position="top" 
          data-pr-tooltip={t('actionPlan:Remove')}
          icon="pi pi-pencil"
          className="p-button-rounded p-button-info p-button-outlined"
        />  
        &nbsp;
        <Tooltip target=".p-button-danger" mouseTrack mouseTrackTop={10} />
        <FieldButton
          type="button" 
          onClick={() => onConfirmRemoveSubmit(rowData)}
          data-pr-position="top" 
          data-pr-tooltip={t('actionPlan:Remove')}
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger p-button-outlined"
        />
      </Col>
    </>
  );
}

const ActionsPlanReviewLogListTemplate = () => {
  return (
    <>
      <Row className="align-items-start pt-2">
        <Col lg={12} md={12} sm={12} xs={12}>
          <h3>
            {t('focusReport:ActionsComments')}
          </h3>
        </Col>
      </Row>
      <Row className="align-items-start">
        <Col lg={12} md={12} sm={12} xs={12}>
          <FieldDataTable
            value={ActionPlanReviewLogList}
            scrollable 
            paginator={false}
            className="table-header-background-blue"
          >
            <Column field="Notas" columnKey="Notas" header={t('focusReport:Comments')} headerStyle={{ width: '300px' }}></Column>                     
            <Column field="NomUsuarioInsArchivo" columnKey="NomUsuarioInsArchivo" header={t('focusReport:User')} headerStyle={{ width: '120px' }}></Column>     
            <Column body={AnexoInfo} headerStyle={{ width: '90px' }}></Column> 
          </FieldDataTable>  
        </Col>
      </Row>
    </>
  );
};
  
const cerrarSubirArchivos = () => {
  setEsSubirArchivos(false);
};  

const AbrirSubirArchivos = () => {
  setIdPuestoIndicadorActionPlanReviewLog(0);
  setEsSubirArchivos(!EsSubirArchivos);
};

  return (
    <> 
      <Card>
        <CardBody>
          <ScrollPanel style={{ 
            width: '100%', 
            height: '500px'          
          }}
          >  
            <HighchartsReact highcharts={Highcharts} ref={chartRef} options={kpiTrendChart} /> 
            {MainCausesTemplate()} 
            {
              EsAbrirActionPlan ?
              (  
                <ActionPlan show={EsAbrirActionPlan} onHide={onHideActionPlan} IdCSF={IdCSF} ClaPeriodo={ClaPeriodo} ClaFechaReporte={ClaFechaReporte}></ActionPlan>
              )
              :
              (<></>)
            }
            {ActionsCompletedInfoTemplate()}
            {ActionsPendingInfoTemplate()}
            {ActionsPlanReviewLogListTemplate()}
            <Dialog
              visible={EsSubirArchivos}
              header={IdPuestoIndicadorActionPlanReviewLog>0?t('focusReport:EditComment'):t('focusReport:AddComment')}
              style={{width: '50vw'}}
              modal
              className="dialog-custom"
              onHide={cerrarSubirArchivos}
            >
              <Row>
                <Col>
                  <KPITrendGraphAttachment
                    onCloseClick={() => cerrarSubirArchivos()}
                    IdPuestoIndicadorActionPlanReviewLog={IdPuestoIndicadorActionPlanReviewLog} 
                    ClaPuesto={ClaPuesto} 
                    ClaTipoIndicador={ClaTipoIndicador}  
                    ClaIndicador={ClaIndicador}
                    AfterSaveAttachment={afterSaveAttachment} 
                  >
                  </KPITrendGraphAttachment>
                </Col>
              </Row>
            </Dialog>
          </ScrollPanel>
        </CardBody>
        <CardFooter hidden={!props.showCloseButton}>
          <Row>
            <Col className='text-center'>
              <FieldButton
                label={t('common:Close')}
                icon="fas fa-times"
                className="warning no-border p-mr-2 rounded-button"
                onClick={typeof(props.onCloseClick) !== 'undefined' && typeof(props.onCloseClick) === 'function' ? props.onCloseClick : null}
              />
              <FieldButton
                label={t('focusReport:AddComment')}
                icon="pi pi-paperclip"
                className="light-blue no-border p-mr-2 rounded-button"
                onClick={() => AbrirSubirArchivos()}
              >
              </FieldButton>  
              <FieldButton
                label={t('focusReport:Print')}
                icon="fas fa-print"
                className="purple no-border  p-mr-2 rounded-button"
                onClick={() => GetKPITendenciaPrint()}
              >
              </FieldButton>  
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  );
};

export default KPITrendGraph;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row, Col } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi, showSweetAlert, getSessionItem } from '../../../utils/utils';
import { Checkbox } from 'primereact/checkbox';
import FieldText from '../../../components/Controls/FieldText.jsx';
import FieldTextArea from '../../../components/Controls/FieldTextArea.jsx';
import FieldNumber from '../../../components/Controls/FieldNumber.jsx';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx';
import FieldDropdown from 'components/Controls/FieldDropdown.jsx';
import { RadioButton } from 'primereact/radiobutton';
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import moment from 'moment';
import { Sidebar } from 'primereact/sidebar';
import swal from '@sweetalert/with-react';
import { Tag } from 'primereact/tag';
import { Dialog } from 'primereact/dialog';

const IndividualIncentiveCompensationDetailByInvoice = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['indIncCompensation', 'common']);
  const [errors, setErrors] = useState({});
  const [IndIncetiveCompDetailHeader, setIndIncetiveCompDetailHeader] = useState({});
  const [IndIncetiveCompDetail, setIndIncetiveCompDetail] = useState([]);
  const [MtTons, setMtTons] = useState(null);
  const [TonsPresupuesto, setTonsPresupuesto] = useState(null);
  const [Spreadton, setSpreadton] = useState(null);
  const [SpreadPptoTon, setSpreadPptoTon] = useState(null);
  const [StratMtTons, setStratMtTons] = useState(null);
  const [TonsStrategicPpto, setTonsStrategicPpto] = useState(null);

  useEffect(() => {
    if (props.IsVisible) {
      GetIndIncetiveCompDetailByInvoice();
    }
  }, [props.IsVisible, props.ClaAnio, props.ClaAgenteVentas, props.ClaMes]);

  // useEffect(() => {}, [props.Filters]);

  const GetIndIncetiveCompDetailByInvoice = () => {
    const getIndividualIncetiveCompensation = `${config.UrlApiProject}SalesPerformanceSales/GetIndIncetiveCompDetailByInvoice`;

    const filters =  {
      ClaAnio: props.ClaAnio,
      ClaAgenteVentas: props.ClaAgenteVentas,
      ClaMes:props.ClaMes 
    }

    callApi(getIndividualIncetiveCompensation, 'Post', filters, (response) => {
      const indIncetiveCompDetailHeader =
        response.data.IndIncetiveCompDetailHeader.length > 0
          ? response.data.IndIncetiveCompDetailHeader[0]
          : {};

      setIndIncetiveCompDetailHeader(indIncetiveCompDetailHeader);
      setMtTons(indIncetiveCompDetailHeader.MtTons);
      setTonsPresupuesto(indIncetiveCompDetailHeader.TonsPresupuesto);
      setSpreadton(indIncetiveCompDetailHeader.Spreadton);
      setSpreadPptoTon(indIncetiveCompDetailHeader.SpreadPptoTon);
      setStratMtTons(indIncetiveCompDetailHeader.StratMtTons);
      setTonsStrategicPpto(indIncetiveCompDetailHeader.TonsStrategicPpto);
      setIndIncetiveCompDetail(response.data.IndIncetiveCompDetail);
    });
  };

  const renderFooter = () => {
    return (
      <>
        <div className="text-center">
          <FieldButton
            type="button"
            // onClick={save}
            label={t('scoreCard:Move')}
            className="p-button-success no-border p-mr-2 rounded-button"
            style={{ marginRight: '.25em' }}
          />
        </div>
      </>
    );
  };


  const close = async () => {
    setErrors({});
    if (props.onClickClose !== null) {
      props.onClickClose();
    }
  };

  const FechaFacturaTemplate = (rowData) => {
    return (
      <>
        {rowData.FechaFactura !== null ? moment(rowData.FechaFactura).format('MM/DD/YYYY') : null}
      </>
    );
  };
  const footer = (
    <>
      <Row>
        <Column></Column>
        <Column></Column>
        <Column></Column>
        <Column></Column>
        <Column></Column>
        <Column></Column>
        <Column></Column>
        <Column></Column>
        <Column></Column>
        <Column></Column>
        <Column></Column>
        <Column></Column>
        <Column></Column>
        <Column></Column>
        <Column></Column>
        <Column></Column>
        <Column
          className="text-right"
          footer={() => (
            <b>
              {MtTons.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </b>
          )}
        >
        </Column>
        <Column
          className="text-right"
          footer={() => (
            <b>
              {StratMtTons.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </b>
          )}
        >
        </Column>
        <Column
          className="text-right"
          footer={() => (
            <b>
              {Spreadton.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </b>
          )}
        >
        </Column>
      </Row>
    </>
  );
  const CantColumn = (row) => {
    return (
      <>
        {row.Cant === null
          ? ''
          : row.Cant.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
      </>
    );
  };

  const ImpMaterialNetoColumn = (row) => {
    return (
      <>
        {row.ImpMaterialNeto === null
          ? ''
          : row.ImpMaterialNeto.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
      </>
    );
  };

  const FleteNOAplicadoClienteColumn = (row) => {
    return (
      <>
        {row.FleteNOAplicadoCliente === null
          ? ''
          : row.FleteNOAplicadoCliente.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
      </>
    );
  };

  const ImporteFacturaColumn = (row) => {
    return (
      <>
        {row.ImporteFactura === null
          ? ''
          : row.ImporteFactura.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
      </>
    );
  };
  const MtonsColumn = (row) => {
    return (
      <>
        {row.Mtons === null
          ? ''
          : row.Mtons.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
      </>
    );
  };

  const StratMtonsColumn = (row) => {
    return (
      <>
        {row.StratMtons === null
          ? ''
          : row.StratMtons.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
      </>
    );
  };

  const SpreadMtonsColumn = (row) => {
    return (
      <>
        {row.SpreadMtons === null
          ? ''
          : row.SpreadMtons.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
      </>
    );
  };

  const ExportExcelIndIncetiveCompDetailByInvoice = () => { 
    const exportExcelIndIncetiveCompDetailByInvoice = `${config.UrlApiProject}SalesPerformanceSales/ExportExcelIndIncetiveCompDetailByInvoice`;        
    const paramsService = {
      ClaAnio: props.ClaAnio, 
      ClaAgenteVentas: props.ClaAgenteVentas, 
      ClaMes: props.ClaMes
    };
    callApi(exportExcelIndIncetiveCompDetailByInvoice, 'POST', paramsService, (response) => {
      const linkSource = `data:application/${response.data.FileExtension};base64,${response.data.FileData}`;
      const downloadLink = document.createElement("a");
      const fileName = `${response.data.FileName}`;
      downloadLink.href = linkSource;
      downloadLink.download = `${fileName  }.${  response.data.FileExtension}`;
      downloadLink.click();
    });    
  };

  const renderHeader = () => {
    return (
      <div>
        <Row>
          <Col lg={4} md={4} sm={12} xs={12}>
            <h2>
              <span>{t('indIncCompensation:DetailbyInvoice')}</span>
            </h2>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="text-center">
            &nbsp;
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="text-right pr-4">
            <FieldButton
              label={t('myactivities:Export')}
              icon="fa fa-file-excel"
              className="p-button p-button-rounded p-button-icon-only p-button-secondary"
              onClick={ExportExcelIndIncetiveCompDetailByInvoice}
            >
            </FieldButton>
          </Col> 
        </Row> 
      </div>
    );
  };

  return (
    <>
      <Dialog
        visible={props.IsVisible}
        style={{ width: '90vw' }}
        modal
        className="dialog-custom"
        onHide={close}
        header={renderHeader}
      >
        <Row className="align-items-start">
          <Col lg={12} md={12} sm={12} xs={12}>
            {IndIncetiveCompDetail.length > 0 ? (
              <>
                <FieldDataTable
                  value={IndIncetiveCompDetail}
                  scrollable
                  scrollHeight="330px"
                  className="editable-cells-table"
                  editMode="cell"
                  selectionMode="single"
                  footerColumnGroup={footer} 
                  // header={headerActivity}
                >
                  <Column
                    field="ClaveFactura"
                    header={t('indIncCompensation:Invoice')}
                    columnKey="ClaveFactura"
                    headerStyle={{ width: '65px', textAlign: 'center' }}
                  >
                  </Column>
                  <Column
                    field="FechaFactura"
                    className="text-center"
                    header={t('indIncCompensation:InvoiceDate')}
                    columnKey="FechaFactura"
                    headerStyle={{ width: '80px' }}
                    body={FechaFacturaTemplate}
                  >
                  </Column>
                  <Column
                    field="NomGrupoEstadistico3"
                    header={t('indIncCompensation:StatGroup3')}
                    columnKey="NomGrupoEstadistico3"
                    headerStyle={{ width: '200px' }}
                  >
                  </Column>
                  <Column
                    field="NomGrupoEstadistico4"
                    header={t('indIncCompensation:StatGroup4')}
                    columnKey="NomGrupoEstadistico4"
                    headerStyle={{ width: '200px' }}
                  >
                  </Column>
                  <Column
                    field="IdClienteCuenta"
                    className="text-center"
                    header={t('indIncCompensation:CustID')}
                    columnKey="IdClienteCuenta"
                    headerStyle={{ width: '75px', textAlign: 'center' }}
                  >
                  </Column>

                  <Column
                    field="NomClienteCuenta"                    
                    header={t('indIncCompensation:CustName')}
                    columnKey="NomClienteCuenta"
                    headerStyle={{ width: '225px' }}
                  >
                  </Column>
                  <Column
                    field="ClaveArticulo"
                    className="text-center"
                    header={t('indIncCompensation:ItemCode')}
                    columnKey="ClaveArticulo"
                    headerStyle={{ width: '95px', textAlign: 'center' }}
                  >
                  </Column>
                  <Column
                    field="NomArticulo"
                    header={t('indIncCompensation:ItemName')}
                    columnKey="NomArticulo"
                    headerStyle={{ width: '345px'}}
                  >
                  </Column>
                  <Column
                    field="ClaAgenteVenta"
                    className="text-center"
                    header={t('indIncCompensation:AgentCode')}
                    columnKey="ClaAgenteVenta"
                    headerStyle={{ width: '95px', textAlign: 'center'}}
                  >
                  </Column>
                  <Column
                    field="NomAgenteVenta"
                    header={t('indIncCompensation:AgentName')}
                    columnKey="NomAgenteVenta"
                    headerStyle={{ width: '225px' }}
                  >
                  </Column>
                  <Column
                    field="IdSalesOrder"
                    className="text-center"
                    header={t('indIncCompensation:SalesOrder')}
                    columnKey="IdSalesOrder"
                    headerStyle={{ width: '75px', textAlign: 'center' }}
                  >
                  </Column>
                  <Column
                    field="Cant"
                    className="text-right"
                    header={t('indIncCompensation:Qty')}
                    columnKey="Cant"
                    headerStyle={{ width: '75px', textAlign: 'right' }}
                    body={CantColumn}
                  >
                  </Column>
                  <Column
                    field="NomUnidadVenta"
                    className="text-center"
                    header={t('indIncCompensation:UOM')}
                    columnKey="NomUnidadVenta"
                    headerStyle={{ width: '85px' , textAlign: 'center'}}
                  >
                  </Column>
                  <Column
                    field="ImpMaterialNeto"
                    className="text-right"
                    header={t('indIncCompensation:NetAmount')}
                    columnKey="ImpMaterialNeto"
                    headerStyle={{ width: '125px' }}
                    body={ImpMaterialNetoColumn}
                  >
                  </Column>
                  <Column
                    field="FleteNOAplicadoCliente"
                    className="text-right"
                    header={t('indIncCompensation:Freight')}
                    columnKey="FleteNOAplicadoCliente"
                    headerStyle={{ width: '125px', textAlign: 'right' }}
                    body={FleteNOAplicadoClienteColumn}
                  >
                  </Column>
                  <Column
                    field="ImporteFactura"
                    className="text-right"
                    header={t('indIncCompensation:InvoiceAmount')}
                    columnKey="ImporteFactura"
                    headerStyle={{ width: '125px', textAlign: 'right' }}
                    body={ImporteFacturaColumn}
                  >
                  </Column>
                  <Column
                    field="Mtons"
                    className="text-right"
                    header={t('indIncCompensation:Mtons')}
                    columnKey="Mtons"
                    headerStyle={{ width: '125px', textAlign: 'right' }}
                    body={MtonsColumn}
                  >
                  </Column>
                  <Column
                    field="StratMtons"
                    className="text-right"
                    header={t('indIncCompensation:StratMtons')}
                    columnKey="StratMtons"
                    headerStyle={{ width: '125px', textAlign: 'right' }}
                    body={StratMtonsColumn}
                  >
                  </Column>
                  <Column
                    field="SpreadMtons"
                    className="text-right"
                    header={t('indIncCompensation:SpreadMtons')}
                    columnKey="SpreadMtons"
                    headerStyle={{ width: '125px', textAlign: 'right' }}
                    body={SpreadMtonsColumn}
                  >
                  </Column>
                </FieldDataTable>
              </>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </Dialog>
    </>
  );
};
export default IndividualIncentiveCompensationDetailByInvoice;

import PageContent from 'layouts/PageContent';
import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import ManageITTickets from './ManageITTickets';

const ViewITTicketsScreen = (props) => {
  const {t, i18n} = useTranslation(['it', 'common']);

  return (
    <PageContent title={t('it:ViewITTickets')}>
      <ManageITTickets />
    </PageContent>
  )
}

export default ViewITTicketsScreen
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from '../../../../components/Controls/FieldButton/FieldButton.jsx'; 
import { Row,Col, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';   
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../../utils/utils'; 
import * as yup from 'yup';
import YupValidator from '../../../../utils/YupValidator'; 
import FieldTextArea from '../../../../components/Controls/FieldTextArea'; 

const ActionPlanComments = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['actionPlan', 'common']);
  const [errors, setErrors] = useState({});

  // ActionPlan
  const [filteredResponsible, setfilteredResponsible] = useState([]);
  const [CausaRaizList, setCausaRaizList] = useState([]);
  const [ResponsablesList, setResponsablesList] = useState([]);
  const [HistorialPendientesList, setHistorialPendientesList] = useState([]);
  const [PickOneVal, setPickOneVal] = useState(null);
  const [ResFecha, setResFecha] = useState(null);
  const [NombreLider, setNombreLider] = useState(null);
  const [TopicoAnalisis, setTopicoAnalisis] = useState(null);
  const [CurrRes, setCurrRes] = useState(null);
  const [PlanRes, setPlanRes] = useState(null);
  const [ExplicacionResultado, setExplicacionResultado] = useState(null);
  const [IdCSF, setIdCSF] = useState(props.IdCSF === undefined ? 0 : props.IdCSF);
  const [ClaPeriodo, setClaPeriodo] = useState(props.ClaPeriodo === undefined ? 0 : props.ClaPeriodo);
  const [ClaFechaReporte, setClaFechaReporte] = useState(props.ClaFechaReporte === undefined ? 0 : props.ClaFechaReporte);
  const [IdPlanAccion, setIdPlanAccion] = useState(props.IdPlanAccion === undefined ? 0 : props.IdPlanAccion);
  const [IdCausa, setIdCausa] = useState(null); 
  const [IdCausaAux, setIdCausaAux] = useState(null); 
  const [DescCausaRaiz, setDescCausaRaiz] = useState(null);
  const [EsVerAnexo, setEsVerAnexo] = useState(false); 
  const [EsAbrirNotas, setEsAbrirNotas] = useState(false); 
  const [APCommPrevious, setAPCommPrevious] = useState(null);
  const [RowDataNotas, setRowDataNotas] = useState(props.RowDataNotas === undefined ? 0 : props.RowDataNotas);
  const [cComentarioTarea, setcComentarioTarea] = useState(props.RowDataNotas.cComentarioTarea === undefined ? null : props.RowDataNotas.cComentarioTarea);

  // End ActionPlan

  useEffect(() => {
    setErrors({});
    setIdCSF(props.IdCSF === undefined ? 0 : props.IdCSF);
    setIdPlanAccion(props.IdPlanAccion === undefined ? 0 : props.IdPlanAccion);
    setcComentarioTarea(props.RowDataNotas.cComentarioTarea === undefined ? null : props.RowDataNotas.cComentarioTarea);
  }, [props.IdCSF, props.ClaPeriodo, props.ClaFechaReporte, props.RowDataNotas]);

const onSave = async () => {

    const value = {    
        APCommPrevious
        };
    
      const validator = new YupValidator(valitationScheme);
      const esValido = await validator.validate(value);
    
      if (!esValido){   
        setErrors(validator.errors); 
        showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');   
        return;
      }

    const urlWebServiceSaveActionPlan = `${config.UrlApiProject}ScoreCard/SaveActionPlanComment`; 
    const paramsServiceOwners = {        
        IdPlanAccion: props.RowDataNotas.cIdPlanAccion,
        IdCausa: props.RowDataNotas.cIdCausa,
        ClaveTarea: props.RowDataNotas.cClaveTarea,
        IdCSF: props.IdCSF,
        ClaPeriodo: props.ClaPeriodo,
        ClaFechaReporte: props.ClaFechaReporte,
        APCommPrevious
    }
    callApi(urlWebServiceSaveActionPlan, 'POST', paramsServiceOwners, (response) => {
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      setcComentarioTarea(response.data);
      props.closeModalNotas();
      props.ObtenerResponsablesActionPlan !== undefined? props.ObtenerResponsablesActionPlan(props.RowDataNotas.cIdPlanAccion) : "";      
      props.ObtenerHistorialPendientesActionPlan !== undefined? props.ObtenerHistorialPendientesActionPlan(IdCSF, IdPlanAccion) : "";      
    }); 
}
 const onChangeAPCommPrevious = (e)=> {
    const newErrors = { ...errors }
    delete newErrors.APCommPrevious;   
    setErrors(newErrors);
    setAPCommPrevious(e.target.value);
 }
 const valitationScheme = yup.object().shape({
    APCommPrevious: yup.mixed().required(t('common:RequiredField')).nullable()
  });
  return (
    <>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12} className="text-right">
        &nbsp;
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <div className="p-fluid"> 
            <FieldTextArea 
              name="APCommPrevious"
              rows={5}
              placeholder=''
              maxLength={450}
              value={APCommPrevious}
              onChange={(e) => onChangeAPCommPrevious(e)}
              errors={errors}
            /> 
          </div> 
        </Col>
      </Row> 
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <div className="p-fluid"> 
            <FieldTextArea 
              name="cComentarioTarea"
              rows={5}
              placeholder=''              
              value={cComentarioTarea}
              readonly
            /> 
          </div> 
        </Col>
      </Row> 
      {
        props.IsReadOnly ? 
        (<></>)
        :
        (
          <Row>
            <Col className="text-right">
              <FieldButton
                type="button" 
                onClick={onSave}
                label={t('common:Save')}
                className="p-button-success no-border p-mr-2 rounded-button"
                style={{ marginRight: '.25em' }}
              /> 
            </Col>
          </Row>
    )
      }  
        
    </>
  );
};
export default ActionPlanComments;

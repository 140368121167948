/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React  from 'react';
import PropTypes from 'prop-types';
import "./HighlighterText.css"; 
import Highlighter from "react-highlight-words";
  


function HighlighterText ({ 
    searchWords,
    autoEscape,
    textToHighlight
  }) { 
    
    const highlight = ({ children, highlightIndex }) => (
      <span className="highlighted-text">{children}</span>
      );

      
    return (  
      <>   
        <Highlighter 
          searchWords={searchWords}
          autoEscape={autoEscape}
          highlightTag={highlight}
          textToHighlight={textToHighlight}
        /> 
      </> 
    )
}    

export default HighlighterText; 

import { React, UseState, UseEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PageContent from 'layouts/PageContent';
import WeeklySalesDash from './WeeklySalesDash';

const WeeklySalesScreen = (props) => {
  const {t, i18n} = useTranslation(['salesPerformance', 'common']);
  
  return (
    <PageContent title={t('salesPerformance:WeeklySalesDashboard')}>
      <WeeklySalesDash />
    </PageContent>
  )
}

export default WeeklySalesScreen;
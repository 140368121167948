/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useEffect, useState} from 'react';
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import './FieldPhone.scss'

const FieldPhone = (props) => {
  const defaultCountry = 'US';

  const onChange = (value) => {  
    if (props.onChange !== null && props.onChange !== undefined) {
      props.onChange(value);
    }
  }

  const onCountryChange = (value) => {    
    if (props.onCountryChange !== null && props.onCountryChange !== undefined) {
      let countryCallingCode = '';
      let country = '';
      if (value) {
        country = value;
        countryCallingCode = getCountryCallingCode(country);
      }
      props.onCountryChange(country, countryCallingCode);
    }
  };

  const isFormFieldInValid = (name) => !!(typeof (props.errors) !== 'undefined' && props.errors !== null && props.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInValid(name) && <small className="p-error">{props.errors[name].message}</small>;
  };

  return (
    <>
      {
        props.label &&
        (
          <label
            className={isFormFieldInValid(props.name) === true ? 'p-error' : ''}
            style={{ fontSize: "12px", paddingLeft: "7px" }}
          >
            {props.label}
          </label>
        )
      }
      <PhoneInput
        international
        defaultCountry={defaultCountry}
        onCountryChange={onCountryChange}
        countryCallingCodeEditable={false}
        onChange={onChange}
        placeholder={props.placeholder}
        value={props.value}
      />      
      {getFormErrorMessage(props.name)}      
    </>
  )
}


export default FieldPhone; 

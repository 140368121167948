/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React  from 'react';
import PropTypes from 'prop-types';
import "./Score.scss";
 
import { Badge } from 'primereact/badge'; 
import FieldButton from '../../Controls/FieldButton/FieldButton.jsx';
  

function Score ({ 
     title
    ,score 
    ,range
    ,severity
    ,small
  }) { 

    let severityClass = '';

    switch (severity) {
        case "blue":
            severityClass = "score-blue-gradient";
            break;
        case "green":
            severityClass = "score-green-gradient";
            break;
        case "yellow":
            severityClass = "score-yellow-gradient";
            break;
        case "red":
            severityClass = "score-red-gradient";
            break;        
        case "darkBlue":
              severityClass = "score-darkBlue-gradient";
              break;   
        default:
            severityClass = "score-default-gradient";
            break;
    }
    
    if (small){
      return ( 
        <span className={`${severityClass} p-badge score-circle-mini`}>
          {score}
        </span>
 );
    }

    return (  
      <>  
        <div className="text-center score-cursor">
          <div className="score-container ">
            <FieldButton label={score} className={`${severityClass} no-border score-circle font-weight-bold score-cursor`}> 
            </FieldButton> 
            <div className="center-badge"> 
              <Badge value={range} className="score-circle-badge" />
            </div>
          </div>
          <label className="score-title"><b>{title}</b></label>
        </div>
      </> 
    )
}   

Score.propTypes = { 
    title: PropTypes.string,
    score: PropTypes.any,
    range: PropTypes.string,
    severity: PropTypes.oneOf(['green', 'yellow', 'red', 'darkBlue', 'blue'])
}

export default Score; 

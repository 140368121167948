/* eslint-disable no-eval */
/* eslint-disable jsx-a11y/label-has-associated-control */
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import FieldTextArea from 'components/Controls/FieldTextArea';
import React, { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { config } from 'utils/config';
import { callApi, getSessionItem } from 'utils/utils';
import DAISeerQuestion from './DAISeerQuestion/DAISeerQuestion';
import './DAISeerConversation.scss';

// Props:
// - topic
// - onChooseNewTopicPress
// - onQuestionAdded
const DAISeerConversation = (props) => {
  const {t, i18n} = useTranslation(['daiSeer', 'common']);  
  const [claUsuario, setClaUsuario] = useState(null);
  const [conversation, setConversation] = useState(null)  
  const [question, setQuestion] = useState('');    
  const [questions, setQuestions] = useState([]);

  const reset = () => {
    setConversation(null);
    setQuestion('');
    setQuestions([]);
  };

  const loadConversation = (idConversation) => { 
    const url = `${config.UrlApiProject}DAISeer/GetConversation?idConversacion=${idConversation}`;
    callApi(url, 'GET', {}, (response) => { 
      setConversation(response.data.conversation);
      setQuestions(response.data.conversationDet); 
      
      // renderizamos los scripts que se cargan dinamicamente....
      const scriptElements = document.getElementById('conversacion_container').getElementsByTagName('script');

      for (const script of scriptElements) {
        if (script.innerText) {
          try {  
            eval(script.innerText); // Ejecuta el contenido del script
          } catch (e) {
            console.error('Error executing script:', e);
          }
        }
      }      
    }, () => {
        
    });
  };

  const ask = (question) => { 
    const url = `${config.UrlApiProject}DAISeer/SendDAISeerQuery`;
    const paramsService = {        
      Pregunta: question  
    };  
    callApi(url, 'POST', paramsService, (response) => { 
      converse(question, response.data.Answer);
    }, () => {  
    }); 
  };

  const converse = (question, answer) => { 
    if (!conversation) {
      const url = `${config.UrlApiProject}DAISeer/CreateDAISeerConversation`;
      const paramsService = {        
        ClaDAISeerTopic: props.topic.ClaDAISeerTopic,        
        ClaUsuario: claUsuario   
      };  
      callApi(url, 'POST', paramsService, (response) => { 
        setConversation(response.data.conversation); 
        saveDialog(response.data.conversation, question, answer);      
      }, () => { 
      });
    } else { 
      saveDialog(conversation, question, answer);
    }   
  };
  
  const saveDialog = (conversation, question, answer) => { 
    const url = `${config.UrlApiProject}DAISeer/SaveDAISeerQuestion`;

    let response = ''

    try{
      response = JSON.parse(JSON.stringify(answer));
    }
    catch(e){
      response = answer
    } 
    const paramsService = {        
      IdConversacion: conversation.IdConversacion,
      Pregunta: question,
      Respuesta: response
    };   

    callApi(url, 'POST', paramsService, (response) => { 
      const _question = response.data.conversacionDet;
      const _questions = [...questions];
      _questions.unshift(_question);
      setQuestions(_questions);  
      if (props.onQuestionAdded) {
        props.onQuestionAdded();
      } 
      setQuestion('');
      console.groupEnd();
    }, () => { 
      console.groupEnd();
    });
  };

  const likeAnswer = (idConversacionDet, meGustaRespuesta) => { 
    const url = `${config.UrlApiProject}DAISeer/LikeDAISeerAnswer`;
    const paramsService = {        
      IdConversacionDet: idConversacionDet,
      MeGustaRespuesta: meGustaRespuesta      
    };  
    callApi(url, 'POST', paramsService, (response) => { 
      const _questions = [...questions];
      const questionIndex = questions.findIndex((question) => {
        return question.IdConversacionDet === idConversacionDet
      });
      if (questionIndex !== -1) {
        _questions[questionIndex].MeGustaRespuesta = meGustaRespuesta;
      }
      setQuestions(_questions); 
    }, () => {  
    });
  };

  const onQuestionChange = (_question) => {
    if (
      question.trim() === '' && _question.trim() !== '' ||
      question.trim() !== ''
    ) {
      setQuestion(_question);
    } else {
      setQuestion('');
    }
  };

  const onAskKeyDown = (event) => {     
    if (event.keyCode === 13 && !event.shiftKey && question.trim() !== '') {
      ask(question);
      event.preventDefault();
    }
  };

  const onSendPress = () => {
    if (question.trim() !== '') {
      ask(question);
    }
  };

  const onChooseNewTopicPress = () => {
    reset();
    if (props.onChooseNewTopicPress) {
      props.onChooseNewTopicPress();      
    }    
  };

  const onLikeAnswerPress = (question) => {    
    const like = question.MeGustaRespuesta === true? null : true;
    likeAnswer(question.IdConversacionDet, like);
  };

  const onUnlikeAnswerPress = (question) => {    
    const like = question.MeGustaRespuesta === false? null : false;
    likeAnswer(question.IdConversacionDet, like);
  };

  const messageControlsTemplate = (
    <>
      <div className="p-fluid"> 
        <FieldTextArea
          name="questionText"     
          placeholder={t('daiSeer:HelloTypeYourQuestion')}
          className="pl-5 pr-5"
          style={{ maxHeight: '200px' }}
          rows={1}
          maxLength={4000}              
          autoResize
          value={question}
          onChange={(e) => onQuestionChange(e.target.value)}      
          onKeyDown={onAskKeyDown}
        />
      </div>            
      <FieldButton          
        icon="fa fa-microphone" 
        tooltip={t("daiSeer:Microphone")}          
        className="p-button-rounded p-button-text float-right"
        style={{
          position:'absolute', 
          left: '2em',
          bottom: '0.3em'
        }}
        onClick={() => {}}               
      />                    
      <FieldButton
        icon="fa fa-search" 
        tooltip={t("daiSeer:Send")}          
        className="p-button-rounded p-button-text float-right"
        style={{
          position:'absolute', 
          right: '2em',
          bottom: '0.3em'
        }}
        onClick={onSendPress}
      />    
    </>
  );

  const topbarTemplate = (
    <>
      <Row className='pt-3 pb-2'>
        <Col xs={9} sm={8} lg={10}>           
          {messageControlsTemplate}
        </Col> 
        <Col>
          <FieldButton
            label={t('daiSeer:ChooseNewTopic')}                  
            className="p-button-primary w-100"
            onClick={onChooseNewTopicPress} 
          />   
        </Col>
      </Row>  
    </>
  );

  const conversationTemplate = (
    <>
      {
        questions.length > 0 && (
          <>
            <Row>
              <Col>                
                {
                  questions.map((question) => {
                    return (
                      <>
                        <DAISeerQuestion
                          onLikePress={onLikeAnswerPress}
                          onUnlikePress={onUnlikeAnswerPress}
                          question={question}
                        />
                      </>
                    )
                  })
                }
              </Col>
            </Row>    
          </>
        )
      }
    </>
  );

  useEffect(() => {
    setClaUsuario(getSessionItem('ClaUsuarioMod', 0));
    // Dummy conversation
    // loadConversation(90);
  }, []);

  return (
    <div id="conversacion_container">
      {
        props.topic && (
          <>  
            <Row
              style={{
                position:'sticky', 
                top:'45px', 
                zIndex: '1',
                backgroundColor: '#F5F5F5',                
                borderBottomColor: '#ced4da',
                borderBottomStyle: 'solid',
                borderBottomWidth: 'thin'
              }}  
            >
              <Col>
                {topbarTemplate}              
              </Col>
            </Row>
            <Row>
              <Col>
                {conversationTemplate}
              </Col>
            </Row>
          </>
        )
      }
    </div>
  )
}

export default DAISeerConversation
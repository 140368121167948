/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React  from 'react';
import PropTypes from 'prop-types';
import "./Carousel.css";
import { Carousel } from 'primereact/carousel';
   
function FieldCarousel ({
     value
    ,numVisible
    ,numScroll 
    ,className
    ,autoplayInterval
    ,itemTemplate
    ,header
  }) { 

    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: value.length >=3 ? 3 : 1,
            numScroll: value.length >=3 ? 3 : 1
        },
        {
            breakpoint: '600px',
            numVisible: value.length >=2 ? 2 : 1,
            numScroll: value.length >=2 ? 2 : 1
        },
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1
        }
    ]; 
 
    return (  
      <>  
        <div className="Carousel">  
          <Carousel
            value={value}
            numVisible={numVisible}
            numScroll={numScroll === 0 || typeof(numScroll) === 'undefined' ? 1: numScroll}
            responsiveOptions={responsiveOptions}
            className={className}
            circular
            autoplayInterval={autoplayInterval}
            itemTemplate={itemTemplate}
            header={header}
          /> 
        </div>
      </> 
    )
}    

export default FieldCarousel; 

class FixPODType {
  static Manual = new FixPODType("Manual");

  static Upload = new FixPODType("Upload");

  static Email = new FixPODType("Email");

  static Deacero = new FixPODType("Deacero");

  static MCN = new FixPODType("MCN");

  constructor(name) {
    this.name = name;
  }
}

export default FixPODType;
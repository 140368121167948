/* eslint-disable object-shorthand */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef, useEffect } from 'react';   
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button'; 
import { Dropdown } from 'primereact/dropdown';  
import { ToggleButton } from 'primereact/togglebutton';
import { FormGroup, Label, Card, CardHeader, CardBody, Row, Col, CardTitle,  CardFooter, InputGroup, InputGroupText } from 'reactstrap'; 
import PocketMarginFilters from './PocketMarginFilters.js'; 
import { useTranslation } from 'react-i18next';
import SummaryReportsCharts from '../SummaryReportsCharts/SummaryReportsCharts';
import { config } from '../../utils/config';
import { callApi } from '../../utils/utils'; 
import FieldDropdown from '../../components/Controls/FieldDropdown.jsx'
import FieldSelectButton from '../../components/Controls/FieldSelectButton.jsx' 

const PocketMarginFollowupSegment =  (props) => {
    const { t } = useTranslation(['formulario','common']); 
    const [IsViewFollowUpDetail, setIsViewFollowUpDetail] = useState(false);
    const [ChartFilters, setChartFilters] = useState({}); 
    const [Anio, setAnio] = useState(props.Anio !== null ? props.Anio : null);
    const [Division, setDivision] = useState(props.Division !== null ? props.Division : null);
    const [Segmento, setSegmento] = useState(props.Segmento !== null ? props.Segmento : null);
    const [OpcionesAnios, setOpcionesAnios] = useState([]);
    const [Mes, setMes] = useState(props.Mes !== null ? props.Mes : null);
    const [OpcionesMeses, setOpcionesMeses] = useState([]);
    const [OpcionesDivision, setOpcionesDivision] = useState([]); 
    const [OpcionesSegmento, setOpcionesSegmento] = useState([]); 
    const [GrupoEstadistico3, setGrupoEstadistico3] = useState(props.GrupoEstadistico3 !== null ? props.GrupoEstadistico3 : null);
    const [OpcionesGrupoEstadistico3, setOpcionesGrupoEstadistico3] = useState([]); 
    const [DisabledButton, setDisabledButton] = useState(true);
    const [OpcionesYesNo] = useState([{ value: 1, name: "YES"}, { value: 0, name: "NO" }]);
    // de momento se requiere que EsMostrarSoloConfiables este apagado....
    const [EsMostrarSoloConfiables, setEsMostrarSoloConfiables] = useState(props.filters.EsMostrarSoloConfiables !== undefined ? props.filters.EsMostrarSoloConfiables : 0);
    
    useEffect(() => {
        console.log('PocketMarginFollowupSegment props');
        console.log(props);
        let urlWebService = `${config.UrlApiProject}Rentabilidad/GetAnios`;  
        let paramsService = null; 
        callApi(urlWebService, 'GET', paramsService, (response) => {
          setOpcionesAnios (response.data.Anios);
          paramsService = {Anio:null};
          if (response.data.Anios.length > 0){
            setAnio(response.data.Anios[0].ClaAnio);
            paramsService.Anio = response.data.Anios[0].ClaAnio;
          } 
            urlWebService = `${config.UrlApiProject}Rentabilidad/GetMeses`; 
            callApi(urlWebService, 'POST', paramsService, (response) => {
              setOpcionesMeses(response.data.Meses); 
              
              if (props.Mes !== null){
                urlWebService = `${config.UrlApiProject}Rentabilidad/GetDivisions`;  
                paramsService = {AnioMes:props.Mes, EsmostrarTodos : 1};
                callApi(urlWebService, 'POST', paramsService, (response) => {
                  setOpcionesDivision (response.data.Divisiones); 
                  
                  if (props.Division !== null){
                    urlWebService = `${config.UrlApiProject}Rentabilidad/GetSegment`;   
                    paramsService = {AnioMes:props.Mes, ClaGerenciaRegional:props.Division};
                    callApi(urlWebService, 'POST', paramsService, (response) => {
                      setOpcionesSegmento(response.data.Segment);
                      
                      if (props.Segmento !== null){
                        urlWebService = `${config.UrlApiProject}Rentabilidad/GetGroupEstadistic3`;   
                        paramsService = {AnioMes:props.Mes, ClaGerenciaRegional:props.Division,ClaMarketSegment:props.Segmento};
                        callApi(urlWebService, 'POST', paramsService, (response) => {
                          setOpcionesGrupoEstadistico3(response.data.GrupoEstadistico3);
                          setGrupoEstadistico3(props.GrupoEstadistico3); 
                          onViewDetailClick();
                        });
                      }
                      
                    });
                  } 
                }); 
              }
            });    
        });  
    }, []); 


    const onMonthChange = (anioMes) => {   
      let paramsService = null; 
      let urlWebService = ``;  
      paramsService = {AnioMes:anioMes, EsmostrarTodos : 1};
      urlWebService = `${config.UrlApiProject}Rentabilidad/GetDivisions`; 
      if (typeof(Division) === 'undefined' && Division === null
      || typeof(GrupoEstadistico3) === 'undefined' && GrupoEstadistico3 === null
      || typeof(Segmento) === 'undefined' && Segmento === null
      || typeof(anioMes) === 'undefined' && anioMes === null
      ){
        setIsViewFollowUpDetail(false); 
      }
        callApi(urlWebService, 'POST', paramsService, (response) => { 
        setOpcionesDivision (response.data.Divisiones);
        setMes(anioMes);
        
        let filters = {
            ClaGerenciaRegional: null, 
            ClaGrupoEstadistico3: null,
            Mes : anioMes,
            Anio : Anio
        }
         
        if (typeof(Division) !== 'undefined' && Division !== null
        && typeof(GrupoEstadistico3) !== 'undefined' && GrupoEstadistico3 !== null
        && typeof(Segmento) !== 'undefined' && Segmento !== null
        ){
          filters = {
              ClaGerenciaRegional: Division, 
              ClaGrupoEstadistico3: GrupoEstadistico3,
              Mes : anioMes,
              Anio : Anio,
              AnioMes: anioMes,
              ClaMarketSegment: Segmento,
              EsMostrarSoloConfiables:EsMostrarSoloConfiables
          }  
           
          setChartFilters(filters);
          setIsViewFollowUpDetail(true); 
        }
        else{
          setDivision(null);
          setSegmento(null);
          setOpcionesSegmento([]);
          setGrupoEstadistico3(null); 
          setOpcionesGrupoEstadistico3([]);
          setIsViewFollowUpDetail(false);

          filters = {
              ClaGerenciaRegional: null, 
              ClaGrupoEstadistico3: null,
              Mes : anioMes,
              Anio : Anio
          }
        }
         

        if (props.onSelectionChange !== null){
          props.onSelectionChange(filters); 
        }
        

      });    
      
    };

    const onYearChange = (yearId) => {   
        let paramsService = null; 
        let urlWebService = ``;  
        paramsService = {Anio:yearId};
        urlWebService = `${config.UrlApiProject}Rentabilidad/GetMeses`;  
        // Meses
        callApi(urlWebService, 'POST', paramsService, (response) => {
          setAnio(yearId);
          setMes(null);
          setDivision(null);
          setSegmento(null);
          setGrupoEstadistico3(null);
          setOpcionesMeses(response.data.Meses);  
          setOpcionesDivision([]);  
          setOpcionesSegmento([]);
          setOpcionesGrupoEstadistico3([]);
          setDisabledButton(true);
          setIsViewFollowUpDetail(false);

          const filters = {
            ClaGerenciaRegional: null, 
            ClaGrupoEstadistico3: null,
            Mes : null,
            Anio : yearId
        }

        if (props.onSelectionChange !== null){
          props.onSelectionChange(filters); 
        }
        });  
    };

    const onDivisionChange = (divisionId) => {   
      let paramsService = null; 
      let urlWebService = ``;  
      paramsService = {AnioMes:Mes, ClaGerenciaRegional:divisionId};
      urlWebService = `${config.UrlApiProject}Rentabilidad/GetSegment`;  
      setIsViewFollowUpDetail(false);
      callApi(urlWebService, 'POST', paramsService, (response) => { 
        setGrupoEstadistico3(null);
        setSegmento(null);
        setOpcionesSegmento(response.data.Segment);
        setDivision(divisionId);
        
        const filters = {
            ClaGerenciaRegional: divisionId, 
            ClaGrupoEstadistico3: null,
            Mes : Mes,
            Anio : Anio,
            ClaMarketSegment : Segmento,
            EsMostrarSoloConfiables:EsMostrarSoloConfiables
        }

        if (props.onSelectionChange !== null){
          props.onSelectionChange(filters); 
        }
      });    
    };

    const onSegmentoChange = (segmentoId) => {   
      let paramsService = null; 
      let urlWebService = ``;  
      paramsService = {AnioMes:Mes, ClaGerenciaRegional:Division,ClaMarketSegment:segmentoId};
      urlWebService = `${config.UrlApiProject}Rentabilidad/GetGroupEstadistic3`;  
      setIsViewFollowUpDetail(false);
      callApi(urlWebService, 'POST', paramsService, (response) => { 
        setGrupoEstadistico3(null);
        setSegmento(segmentoId);
        setOpcionesGrupoEstadistico3(response.data.GrupoEstadistico3);
        const filters = {
            ClaGerenciaRegional: Division, 
            ClaGrupoEstadistico3: null,
            Mes : Mes,
            Anio : Anio,
            ClaMarketSegment:segmentoId,
            EsMostrarSoloConfiables:EsMostrarSoloConfiables
        }

        if (props.onSelectionChange !== null){
          props.onSelectionChange(filters); 
        }
      });    
    };

    const onGrupoEstadistico3Change = (grupoEstaditico3Id) => {   
      setGrupoEstadistico3(grupoEstaditico3Id);
      setDisabledButton(false);
      const filters = {
          ClaGerenciaRegional: Division, 
          ClaGrupoEstadistico3: grupoEstaditico3Id,
          AnioMes: Mes,
          ClaMarketSegment: Segmento,
          EsMostrarSoloConfiables:EsMostrarSoloConfiables
      }

      setChartFilters(filters);
      setIsViewFollowUpDetail(true);

      if (props.onSelectionChange !== null){
        const filters = {
            ClaGerenciaRegional: Division, 
            ClaGrupoEstadistico3: grupoEstaditico3Id,
            Mes : Mes,
            Anio : Anio,
            ClaMarketSegment: Segmento,
            EsMostrarSoloConfiables:EsMostrarSoloConfiables
        }

        props.onSelectionChange(filters); 
      }
    };

    const onViewDetailClick = ( ) => {   
        const filters = {
            ClaGerenciaRegional: Division, 
            ClaGrupoEstadistico3: GrupoEstadistico3,
            AnioMes: Mes,
            ClaMarketSegment: Segmento,
            EsMostrarSoloConfiables:EsMostrarSoloConfiables
        }
        
        setIsViewFollowUpDetail(false);
        if (typeof(GrupoEstadistico3) !== 'undefined' && GrupoEstadistico3 !== null){

          setChartFilters(filters);
          setIsViewFollowUpDetail(true);
        }
     };

    return (  
      <>      
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <div className="p-fluid p-grid">
              <div className="p-field p-col-12 p-md-12">
                <h3>
                  {t('formulario:SelectedLabelFilter')}
                </h3>
              </div>
            </div>
          </Col>
        </Row>  
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>  
            <div className="p-fluid p-grid">
              <div className="p-grid p-field p-col-12 p-md-3"> 
                <div className="p-col-6">
                  <FieldDropdown  
                    value={Anio} 
                    options={OpcionesAnios}  
                    optionValue="ClaAnio"
                    optionLabel="NomAnio" 
                    onChange={(e) => onYearChange(e.value)}
                    label={t('common:Year')}
                  />   
                </div>
                <div className="p-col-6">
                  <FieldDropdown  
                    value={Mes} 
                    options={OpcionesMeses}  
                    optionValue="AnioMes"
                    optionLabel="NomMes" 
                    onChange={(e) => onMonthChange(e.value)}
                    label={t('common:Month')}
                  />   
                </div>
              </div>
              <div className="p-field p-col-12 p-md-3"> 
                <FieldDropdown  
                  value={Division} 
                  options={OpcionesDivision} 
                  onChange={(e) => onDivisionChange(e.value)} 
                  optionValue="ClaGerenciaRegional"
                  optionLabel="NomGerenciaRegional"
                  label={t('common:Division')}
                />   
              </div>
              <div className="p-field p-col-12 p-md-3"> 
                <FieldDropdown  
                  value={Segmento} 
                  options={OpcionesSegmento} 
                  onChange={(e) => onSegmentoChange(e.value)} 
                  optionValue="ClaMarketSegment"
                  optionLabel="NomMarketSegment"
                  label={t('common:Segment')}
                />   
              </div>
              <div className="p-field p-col-12 p-md-3"> 
                <FieldDropdown  
                  value={GrupoEstadistico3} 
                  options={OpcionesGrupoEstadistico3} 
                  onChange={(e) => onGrupoEstadistico3Change(e.value)} 
                  optionValue="ClaGrupoEstadistico3"
                  optionLabel="NomGrupoEstadistico3"
                  label={t('formulario:StatisticalGpo3')}
                />   
              </div>   
            </div>
          </Col>
        </Row>  
        <Row> 
          <Col lg={12} md={12} sm={12} xs={12}>  
            {IsViewFollowUpDetail === true ? <SummaryReportsCharts filters={ChartFilters} onclick={value => setIsViewFollowUpDetail(value)} /> :<></>}
          </Col>
        </Row>  
      </> 
    )
    }   
export default PocketMarginFollowupSegment; 

      
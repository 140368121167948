import React, { useEffect, useState } from 'react';
import { config } from '../../../utils/config';
import { callApi } from '../../../utils/utils';
import { 
  Row,
  Col, 
} from 'reactstrap';  
import ArrowUpwardRounded from '@material-ui/icons/ArrowUpwardRounded';
import ArrowDownwardRounded from '@material-ui/icons/ArrowDownwardRounded'; 
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import TimelineIcon from '@material-ui/icons/Timeline';
import IconButton from '@material-ui/core/IconButton'; 
import { useTranslation } from 'react-i18next';
import StandarVsRealChar from "./StandarVsRealChar.jsx";
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';

const InconsistenciasCostos = (props) => { 
    const { t } = useTranslation(['formulario','common']);
    const [rowsGrid, setRowsGrid] = useState([]); 
    const [chartTitle, setChartTitle] = useState('');
    const [SelectedElement, setSelectedElement] = useState(null); 
    const [ClaElemento, setClaElemento] = useState(null);
  
    useEffect(() => { 
      const GetInconsistenciasGridService = `${config.UrlApiProject}Costos/GetElementInconsistenciasGrid`;
      const paramsToService = { 
        anio: props.filters.Anio,
        anioMes: props.filters.AnioMes,
        claTipoElementoCosto: props.filters.ClaTipoElementoCosto,
        claDepartamento:props.filters.ClaDepartamento,
        claTipoCelda:props.filters.ClaTipoCelda,
        claCelda:props.filters.ClaCelda,
        claArticulo:props.filters.ClaArticulo,
        TipoConversionCost: props.filters.TipoConversionCost
      }; 
      callApi(GetInconsistenciasGridService, 'POST', paramsToService, (response) => { 
        setClaElemento(null);
        setRowsGrid(response.data[0]); 
        const GetCeldaTitleService = `${config.UrlApiProject}Costos/GetCeldaElementTitle`;  
        callApi(GetCeldaTitleService, 'POST', paramsToService, (response) => { 
          if(response.data.info!==undefined)
          {  
            setChartTitle(`${response.data.info[0].NomFecha }`);  
          }     
          });
      });
    }, [props.filters]);
    
    const header  = ( 
      <>
        {chartTitle}
      </>
    )
  
    const pirColumn = (row) => {  
        return (<>{ row.PIR === null ? '' : row.PIR.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</>);
    }
    
    const standardCostColumn = (row) => {  
          return (<>{row.StandarCost === null ? '' : row.StandarCost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>);
    }
    
    const realCostColumn = (row) => {  
        return (<>{ row.RealCost === null ? '' :row.RealCost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>);
    }
  
    const onRowSelect = (event) => {   
        setClaElemento(event.data.ClaElemento)
    }
  
    const onRowUnselect = (event) =>{  
      setClaElemento(null);
    }
    
    const chartColumn = (row) =>  {    
        return (<><IconButton size="small" color="primary"><TimelineIcon /></IconButton></>);
    }
  
    const trendColumn = (row) =>  {    
        return (<>{row.Trend ? <ArrowUpwardRounded className="red" /> : <ArrowDownwardRounded className="green" />}</>);
    } 

    const footer  = ( 
      <> 
        <Row>
          <Column> 
          </Column>
          <Column footer={() => (<b> {t('common:Total')}</b>)}> 
          </Column>
          <Column footer={() => (<b>{ (rowsGrid.reduce((a,v) =>  a += v.StandarCost === null ? 0 : v.StandarCost , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}> 
          </Column>
          <Column footer={() => (<b>{ (rowsGrid.reduce((a,v) =>  a += v.RealCost === null ? 0 : v.RealCost , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}> 
          </Column>
          <Column> 
          </Column>
          <Column>  
          </Column> 
        </Row>
      </>
    )
  
    if (props.showHide) {
      return (
        <>
          <Row className="align-items-start">
            <Col lg={6} md={6} sm={12} xs={12}>
              <FieldDataTable 
                value={rowsGrid} 
                header={header} 
                scrollable 
                scrollHeight="330px" 
                className="p-datatable-striped"
                selection={SelectedElement} 
                onSelectionChange={e => setSelectedElement(e.value)} 
                onRowSelect={onRowSelect} 
                onRowUnselect={onRowUnselect}
                selectionMode="single"
                metaKeySelection={false} 
                footerColumnGroup={footer}
              >
                <Column header="" headerStyle={{ width: '50px' }} body={chartColumn}></Column>
                <Column field="Descripcion" header={t('formulario:Description')} headerStyle={{ width: '150px' }} columnKey="ElementName"></Column> 
                <Column body={standardCostColumn} header={t('formulario:Standard')} headerStyle={{ width: '90px' }} columnKey="StandarCost"></Column> 
                <Column body={realCostColumn} header={t('formulario:Real')} headerStyle={{ width: '80px' }} columnKey="RealCost"></Column>   
                <Column body={pirColumn} header={t('formulario:PiR')} headerStyle={{ width: '60px' }} columnKey="PIR"></Column>  
                <Column header={t('formulario:Trend')} headerStyle={{ width: '70px' }} body={trendColumn}></Column>  
               
              </FieldDataTable> 
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              {
              ClaElemento !== null ?  <StandarVsRealChar ClaElemento={ClaElemento} filters={props.filters} />
              : <></>
            }
            </Col>
          </Row>  
        </>
      );
    }
    if (!props.showHide) {
      return <></>;
    }
  };
  
  export default InconsistenciasCostos;
  
/* eslint-disable react/no-danger */
import { React, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PageContent from 'layouts/PageContent'; 
import { config } from 'utils/config';
import { callApi, tracker } from 'utils/utils';
import { Card, CardBody, CardFooter, CardHeader, CardLink, CardSubtitle, CardText, CardTitle, Col, Row } from 'reactstrap';
import './BMTrackingDashboardsScreen.scss'; 
import Viz from 'components/Tableau/Viz';
import { Dialog } from 'primereact/dialog';
import { ScrollPanel } from 'primereact/scrollpanel';

const BMTrackingDashboardsScreen = (props) => {
  const {t, i18n} = useTranslation(['salesPerformance', 'common']);
  const [TableuBMTrakingReportsList, setTableuBMTrakingReportsList] = useState([]); 
  const [EsMostrarReporte, setEsMostrarReporte] = useState(false);
  const [reportSelectedTitle, setReportSelectedTitle] = useState('');

  const [viz, setViz] = useState(null);
  useEffect(() => { 
    OnloadBMTrackingDashboardScreen();    
  }, []);

  
  const OnloadBMTrackingDashboardScreen = (pIdCelda) => {   
    const paramsService = {};
    const GetBMTrackingDashboards = `${config.UrlApiProject}SalesPerformanceSales/GetBMTrackingDashboards`;  
     
    callApi(GetBMTrackingDashboards, 'GET', paramsService, (response) => {
        setTableuBMTrakingReportsList(response.data.TableuBMTrakingReportsList);
    }); 
  };

  const onViewReport =(url, title) => { 
    setEsMostrarReporte(true);
    setReportSelectedTitle(title);
    setViz(<Viz url={url}></Viz>);

    tracker.trackEvent({
      type: 'BMTrackingDashboardsScreen', /* component name */
      action: `VIEW_${  url}`
    }) 
  }

    
  return (
    <div className='BMTrackingDashboardScreen'>
      <PageContent title={t('salesPerformance:BMTrackingDashboardsScreen')}>
        <Row className='align-items-start'> 
          {
            TableuBMTrakingReportsList.length === 0 
            ?
            ( 
              <Col className='pt-10'> 
                <Card>  
                  <CardBody className="card-body text-center">
                    There is no report available for signed in user.
                    <div className='pt-3'>
                      This landing page was designed to contain Project tracking reports related to boldmoves. 
                    </div>
                    <div>
                      If you consider that you should have access to one of these, please make your request through the project leader.
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )
            :
            (
              <></>
            )
          }
          {
            TableuBMTrakingReportsList.map((item, index) => {
                return (  
                  <Col lg={6} key={index}>
                    <Card> 
                      <CardHeader className='light'>
                        <span className='h1'>{item.title}</span> {item.titleComplemento}
                      </CardHeader>
                      <CardBody className="card-body">
                        <ScrollPanel style={{width: '100%', height: '170px'}}>
                          <CardTitle className='pb-2'>
                            <b>{item.nomUsuario}</b> 
                          </CardTitle>
                          <CardSubtitle>
                            <i>{item.division}</i>
                          </CardSubtitle>
                          <CardText className='description'>
                            <div dangerouslySetInnerHTML={{ __html: item.description }}></div>  
                          </CardText> 
                        </ScrollPanel>
                        
                      </CardBody>
                      <CardFooter className='card-footer'>
                        <div className='text-right'>
                          <CardLink onClick={() => onViewReport(item.url, `${item.title  } ${  item.titleComplemento}`)} className='link'>
                            View Report
                          </CardLink>
                        </div>
                      </CardFooter>
                    </Card>
                  </Col> 
                )
                }
            )
        }

         
        </Row>
      </PageContent>
      {
        EsMostrarReporte && 
        (
        <Dialog
          visible={EsMostrarReporte}
          modal
          style={{ width: '100vw' }} 
          onHide={() => setEsMostrarReporte(false)}
          header={reportSelectedTitle}
          closable={true}
          className="dialog-header-background-blue"
          maximized={true}
          draggable={false}
          blockScroll={true}
        > 
            {viz}
        </Dialog>
        )
      } 
    </div>
    
  )
}

export default BMTrackingDashboardsScreen;
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react'; 
import { useTranslation } from 'react-i18next'; 
import PageContent from '../../../layouts/PageContent'; 
import { Toolbar } from 'primereact/toolbar'; 
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx'; 
import { Row, Col } from 'reactstrap'; 
import FieldText from '../../../components/Controls/FieldText.jsx';
import { Panel } from 'primereact/panel';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import FieldDropdown from '../../../components/Controls/FieldDropdown.jsx';
import { config } from '../../../utils/config';
import { callApi, getSessionItem, showSweetAlert, tracker} from '../../../utils/utils'; 
import FieldCalendar from '../../../components/Controls/FieldCalendar.jsx';
import FieldAutoComplete from '../../../components/Controls/FieldAutoComplete.jsx'; 
import { Column } from 'primereact/column';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import { ProgressBar } from 'primereact/progressbar';   
import moment from "moment"; 
import FieldTextArea from 'components/Controls/FieldTextArea'; 
import { Dialog } from 'primereact/dialog';
import FieldCheckbox from '../../../components/Controls/FieldCheckbox.jsx'
import MinutasMeeting from './MinutasMeeting.jsx';

const MeetingManagementScreen = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['minutameeting', 'common']);
  const [filters, setfilters] = useState({});   
  const [esModoConsulta, setEsModoConsulta] = useState(true);
  const [ListadoOpciones, setListadoOpciones] = useState([]);
  const [EsMostrarMeetingDetail, setEsMostrarMeetingDetail] = useState(false);
  const [TipoMinuta, setTipoMinuta] = useState(null); 
  const [ClaTipoMinuta, setClaTipoMinuta] = useState(null); 
  const [errors, setErrors] = useState({});     
  const [FechaInicio, setFechaInicio] = useState(null);
  const [FechaFin, setFechaFin] = useState(null);
  const [filteredResponsible, setfilteredResponsible] = useState([]);
  const [Responsable, setResponsable] = useState(null);
  const [EsAdminScorecard, setEsAdminScorecard] = useState(0);
   
  const [MinutaList, setMinutaList] = useState([]); 
  const [IdMinuta, setIdMinuta] = useState(null);
  const [openSendMail, setopenSendMail] = useState(false);
  const [ProjectDesc, setProjectDesc] = useState([]);
  const [Asunto, setAsunto] = useState([]);
  const [MeetingDay, setMeetingDay] = useState([]);
  const [ParticipantesMinutaList, setParticipantesMinutaList] = useState([]);
  const [CopiarA, setCopiarA] = useState([]);
  const [Mensaje, setMensaje] = useState([]);
  const [MinutaCorreo, setMinutaCorreo] = useState([]);
  const [MinutaSeleccionada, setMinutaSeleccionada] = useState([]);
  const [EsMinutaVisible, setEsMinutaVisible] = useState(false);
  const [ClaProyecto, setClaProyecto] = useState(null); 
   const [ProyectosList, setProyectosList] = useState([]); 
   const [ClaTipoMinutaSendEmail, setClaTipoMinutaSendEmail] = useState(null);

  useEffect(() => {   
    inicializaPantalla();
  }, []); 

  const inicializaPantalla = () => { 
    const GetMinutaDefaultValues = `${config.UrlApiProject}ScoreCard/GetMinutaDefaultValues`;  
    const paramsToService = {  
    };   

    callApi(GetMinutaDefaultValues, 'POST', paramsToService, (response) => {   
        const minutaDefaultValues = response.data.Minuta.length > 0 ? response.data.Minuta[0] : {};
 
        setFechaInicio(minutaDefaultValues.FechaInicio === undefined ? null : new Date(minutaDefaultValues.FechaInicio));
        setFechaFin(minutaDefaultValues.FechaFin === undefined ? null : new Date(minutaDefaultValues.FechaFin)); 
        obtenerTipoMinuta();  
        obtenerProyectos();
    });
  }

  const obtenerProyectos = () => { 
     
    const GetProyectoCmb = `${config.UrlApiProject}ScoreCard/GetProyectoCmb?ClaTipoProyecto=-1&EsIncluirTodosSN=0`;
    const paramsToService = {};
    callApi(GetProyectoCmb, 'GET', paramsToService, (response) => { 
      setProyectosList(response.data.Proyecto);
    });

  }


  const rightToolbarTemplate = () => {
    return (
      <>
        <FieldButton label={t('common:New')} icon="pi pi-plus" className="p-button-success p-mr-2" onClick={onNewClick} /> 
      </>
    )
  } 

  const onNewClick = () => { 
    setfilters({});
    setEsModoConsulta(false);

    tracker.trackEvent({
      type: 'MeetingManagementScreen', /* component name */
      action: 'NEW_MEETING'
    }) 
  } 

  const onEditClick = (rowClick) => {  
    setfilters({IdPerformanceDev:rowClick.IdPerformanceDev})
    setEsModoConsulta(false);
  } 

  const EsEnviarCorreoTemplate = (rowData, props) => {
    return (
      <>
        <FieldCheckbox
          inputId={rowData.EsEnviarCorreo}
          checked={rowData.EsEnviarCorreo}
          onChange={(e) => EsEnviarCorreoSelected(props, e)}
        />
      </>
    );
  }

  const EsEnviarCorreoSelected = (props, e) => {
    const newErrors = { ...errors } 
    delete newErrors.CopiarA;   
    setErrors(newErrors) 
    const participantesMinutaList = [...ParticipantesMinutaList];
    participantesMinutaList[props.rowIndex].EsEnviarCorreo = e.checked;
    setParticipantesMinutaList(participantesMinutaList);
  }

 
  const header  = ( 
    <>
      {t('minutameeting:AdministracionMinutas')}
    </>
  )

  const obtenerTipoMinuta = () => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/GetMswBSCTipoMinuta`;  
    const paramsToService = { 
      
    };   
    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => {  
        setTipoMinuta(response.data[0]);     
    });
  }

  const onCloseDetailView = (isSaveReturn) => { 
    setEsModoConsulta(true); 
  };

  const valitationScheme = yup.object().shape({
    FechaInicio: yup.date().required(t('')).nullable(),
    FechaFin: yup.date().required(t('')).nullable() 
  });

  const onApplyFilters = async () => {
    
    const value = {    
      FechaInicio ,
      FechaFin
    };

    const validator = new YupValidator(valitationScheme);
    const esValido = await validator.validate(value);
    if (!esValido){   
      setErrors(validator.errors); 
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
     
      return;
    }
     
    const GetMinutaList = `${config.UrlApiProject}ScoreCard/GetMinutasList`;
    const paramsToService = {
      ClaTipoMinuta, 
      ClaProyecto , 
      ClaResponsable : Responsable === null ? null : Responsable.ClaColaborador, 
      FechaInicia : FechaInicio, 
      FechaTermina : FechaFin
    };
    callApi(GetMinutaList, 'POST', paramsToService, (response) => {
      setMinutaList(response.data.MinutasList); 
    });
     
  };
 

  const modalFooter = () => {
    return (
      <>
        <FieldButton label={t('minutaproyecto:Send')} icon="fas fa-envelope" className="p-button-success no-border p-mr-2 rounded-button" onClick={() => onSendClick()} />
      </>
    );
  }

  const onSendClick = async () => {
    let partSel = false;
    for (let index = 0; index < ParticipantesMinutaList.length; index++) {
      const element = ParticipantesMinutaList[index].EsEnviarCorreo;
      if (element) {
          partSel = true;
      }
    }
    if (CopiarA === '' && !partSel) {
      const value = {
        CopiarA
      }
      const validator = new YupValidator(valitationSchemeAdd);
      const esValido = await validator.validate(value);
      if (!esValido){ 
        setErrors(validator.errors); 
        showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
        return;
      }
    }
    const paramsServiceOwners = {
      IdMinuta: MinutaSeleccionada,
      CopiarA,
      Asunto,
      Mensaje,
      MinutaParticipante: ParticipantesMinutaList
    };

    const urlWebServiceSaveMinutas = `${config.UrlApiProject}ScoreCard/SetEnviarMinuta`;
    
    callApi(urlWebServiceSaveMinutas, 'POST', paramsServiceOwners, (response) => {
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      setopenSendMail(false);
    });
    
  }
  const valitationSchemeAdd = yup.object().shape({
    CopiarA: yup.string().trim().required(t('common:RequiredField')).nullable()   
  });

const onClickNewMeeting = () => {  
  setEsMinutaVisible(true);
  setIdMinuta(null); 
} 

const onChangeMeetingType = (e) => {  
  const newErrors = { ...errors }
  delete newErrors.ClaTipoMinuta;   
  setErrors(newErrors);
  setClaTipoMinuta(e.value);  

  if (e.value !== 1){
    setClaProyecto(null);
  }
}
 
const onChangeFechaInicio = (props, e) => {
  const newErrors = { ...errors };
  delete newErrors.FechaInicio;
  setErrors(newErrors);
  setFechaInicio(e.target.value); 
};
const onChangeFechaFin = (props, e) => {
  const newErrors = { ...errors };
  delete newErrors.FechaFin;
  setErrors(newErrors);
  setFechaFin(e.target.value); 
};

const onChangeResponsable = (e) => {
  const newErrors = { ...errors };
  delete newErrors.Responsable;
  setErrors(newErrors); 
  setResponsable(e.value); 
};

const filterResponsible = (event) => {
  let valor = ''; 
  if (Responsable !== null && Responsable !== '' &&  event.query === "")
  {
   valor = Responsable.NomColaborador;
  }
  else{
   valor = event.query;
  }
  const paramsServiceOwners = {};
  // event.query  
  const urlWebServiceOwners = `${config.UrlApiProject}ScoreCard/GetColaboradorProyectosCmb?descriptionFilter=${valor}&EsMostrarSoloUsuarioMSW=${0}`;
   
  // Get owner
  callApi(urlWebServiceOwners, 'GET', paramsServiceOwners, (response) => {  
     setfilteredResponsible(response.data[0]);
  });    
  
}

const FechaReunion = (rowData) => {
    return (
      <>
        <div>
          {/* <small> { moment(rowData.FechaReunion).format("LL") } - { moment(rowData.FechaReunion).format("LL") }</small> */}
          {moment(rowData.FechaReunion).format("MM/DD/YYYY")}
        </div>
      </>
    );
  }

  const AccionesTemplate = (row) => {
    let className = '';
    const Porcentaje = (row.Task > 0 && row.OpenTask > 0 ? ((row.Task - row.OpenTask) / row.Task) : 1.0) * 100.0


    switch (true) {
      case Porcentaje < 50:
        className = "danger-color";
        break;
      case Porcentaje < 75:
        className = "orange-color";
        break;
      case Porcentaje < 99:
        className = "warning-color";
        break;
      case Porcentaje === 100:
        className = "success-color";
        break;
      default:
        className = "default-color";
    }
    return (
      <>
        {/* <div className="text-center">
                    <span>{row.OpenTask}</span> &nbsp;
                    <span className="bold">{t('hr:Of')}</span> &nbsp;
                    <span>{row.Task}</span>&nbsp;
                    <span className="bold">{t('hr:Activities')}</span> 
                  </div> */}
        <div>
          <ProgressBar className={className} value={Porcentaje.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}></ProgressBar>
        </div>
      </>
    );
  }

  const actionBodyTemplate = (rowData, props) => {
    return (
      <>
        <div>
          <FieldButton icon="pi pi-eye" className="p-button-rounded p-button-success" onClick={() => onClickViewDetailMeeting(rowData)} />
          &nbsp;
          <FieldButton icon="pi pi-envelope" className="p-button-rounded p-button-info" onClick={() => onOpenModal(rowData)} />
        </div>
      </>
    );
  }

  const onClickViewDetailMeeting = (rowData) => {
    setEsMinutaVisible(true);
    setIdMinuta(rowData.IdMinuta); 
    tracker.trackEvent({
      type: 'MeetingManagementScreen', /* component name */
      action: 'VIEW_MEETING_DETAIL'
    }) 
  }

  const onOpenModal = (rowData) => {
    const newErrors = { ...errors } 
    delete newErrors.CopiarA;   
    setErrors(newErrors) 
    obtenerInformacionMinutaCorreo(rowData.IdMinuta);
    obtenerParticipanes(rowData.IdMinuta);
    setopenSendMail(true)
    setMinutaSeleccionada(rowData.IdMinuta);
    tracker.trackEvent({
      type: 'MeetingManagementScreen', /* component name */
      action: 'OPEN_SEND_MEETING_BY_EMAIL'
    }) 
  }

  const obtenerParticipanes = (idMinutaParam) => {
    const GetParticipantesMinuta = `${config.UrlApiProject}ScoreCard/GetParticipantesMinuta?IdMinuta=${idMinutaParam}`;
    const paramsToService = {};
    callApi(GetParticipantesMinuta, 'GET', paramsToService, (response) => {
      setParticipantesMinutaList(response.data.ParticipantesMinuta);
    });
  }
  const obtenerInformacionMinutaCorreo = (idMinutaParam) => { 
    const GetMinutasCorreo = `${config.UrlApiProject}ScoreCard/GetMinutasCorreo?IdMinuta=${idMinutaParam}`;
    const paramsToService = { };
    callApi(GetMinutasCorreo, 'GET', paramsToService, (response) => {
      if (response.data.Minutas.length > 0) {
        setProjectDesc(response.data.Minutas[0].ProyectoSeleccionado);
        setAsunto(response.data.Minutas[0].AsuntoSeleccionado);
        setMeetingDay(response.data.Minutas[0].FechaReunionModal);
        setCopiarA(response.data.Minutas[0].CopiarA);
        setMensaje(response.data.Minutas[0].MensajeModal);
        setClaTipoMinutaSendEmail(response.data.Minutas[0].ClaTipoMinuta);
      }
    });
  }

  const closeModal = () => {
    setopenSendMail(false);
  }


  const filterHeaderTemplate = (options) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down text-white' : 'pi pi-chevron-up text-white';
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className="card-header">
        <div className="p-toolbar p-component">
          <div className="p-toolbar-group-left">
            <div className="card-title">
              <FieldButton className={options.togglerClassName} onClick={options.onTogglerClick}>
                <span className={toggleIcon}></span> 
              </FieldButton>
              <span className={titleClassName}>
                {t('common:Filters')}
              </span>
            </div>  
          </div>
         
          <div className="p-toolbar-group-right">
            <FieldButton label={t('common:Search')} icon="pi pi-search" className="p-button p-highlight" onClick={onApplyFilters}>              
            </FieldButton> 
            &nbsp; 
            &nbsp;
            <FieldButton label={t('minutameeting:NewMeeting')} icon="pi pi-plus" className="light-green no-border p-mr-2" onClick={onClickNewMeeting}  /> 
          </div>
        </div> 
      </div>
    )
}

const renderHeader = () => {
  return (
    <div>
      <Row>
        <Col lg={4} md={4} sm={12} xs={12}>
          <span>{ IdMinuta === null ? t('focusReport:NewMeeting') :  t('focusReport:EditMeeting') }</span>
        </Col>
        <Col lg={4} md={4} sm={12} xs={12} className="text-center">
          &nbsp;
        </Col>
        <Col lg={4} md={4} sm={12} xs={12} className="text-right">
          <div className="w-100 relative">
            <i className='fas fa-times fa-1x Cursor' title={t('common:Close')} onClick={onCloseMinuta}></i> 
          </div>
        </Col>
      </Row> 
    </div>
  );
};

const NomTipoReunionTemplate = (rowData) => {
  return (
    <> 
      <div className='overflow-ellipsis' title={rowData.NomTipoReunion}>{rowData.NomTipoReunion}</div> 
    </>
  );
}

const AsuntoTemplate = (rowData) => {
  return (
    <> 
      <div className='overflow-ellipsis' title={rowData.Asunto}>{rowData.Asunto}</div> 
    </>
  );
}

const NomResponsableTemplate = (rowData) => {
  return (
    <> 
      <div className='overflow-ellipsis' title={rowData.NomResponsable}>{rowData.NomResponsable}</div> 
    </>
  );
}

const onCloseMinuta = () => { 
  setEsMinutaVisible(false);   
  onApplyFilters();
};

const onChangeProyecto  = (e) => {  
  const newErrors = { ...errors }
  delete newErrors.ClaProyecto;
  setErrors(newErrors);
  setClaProyecto(e.value);    
}

const onChangeCopiarA = (e) => {
  const newErrors = { ...errors } 
  delete newErrors.CopiarA;   
  setErrors(newErrors) 
  setCopiarA(e.target.value);
}

const onChangeMensajeModal = (e) => {
  setMensaje(e.target.value);
}
 
    return (
      <>  
        <PageContent title={t('minutameeting:AdministracionMinutas')}>  
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
              <Panel
                header={t('common:Filters')}
                toggleable 
                headerTemplate={filterHeaderTemplate}
              >      
                <Row>
                  <Col lg={3} md={3} sm={12} xs={12} className="p-3">
                    <div className="p-fluid">
                      <FieldDropdown  
                        name="ClaTipoMinuta" 
                        label={t('minutameeting:MeetingType')}
                        value={ClaTipoMinuta} 
                        options={TipoMinuta}  
                        optionValue="ClaTipoMinuta"
                        optionLabel="NomTipoMinuta" 
                        onChange={(e) => onChangeMeetingType(e)} 
                        errors={errors}
                      />  
                    </div>
                  </Col>
                  <Col lg={4} md={5} sm={12} xs={12} className="p-3"> 
                    <div className="p-float-label" style={{top:-9}}>
                      <label>{t('minutameeting:MeetingDate')}</label>
                    </div>
                    <div className="p-fluid">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">{t('common:From')}</span>
                        <FieldCalendar
                          name="FechaInicio"
                          value={FechaInicio} 
                          className="no-round no-round-btn-icon" 
                          onChange={(e) => onChangeFechaInicio(props, e)}
                          errors={errors}
                        />
                        <span className="p-inputgroup-addon">{t('common:To')}</span>
                        <FieldCalendar
                          name="FechaFin"
                          value={FechaFin}
                          className="no-round" 
                          onChange={(e) => onChangeFechaFin(props, e)}
                          errors={errors}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={5} md={4} sm={12} xs={12} className="p-3">
                    <div className="p-fluid"> 
                      <FieldAutoComplete
                        dropdown
                        name="Responsable"
                        label={t('minutameeting:Responsible')}
                        value={Responsable} 
                        onChange={onChangeResponsable}  
                        suggestions={filteredResponsible}
                        field="NomColaborador"
                        completeMethod={filterResponsible}
                        errors={errors}
                      /> 
                    </div>    
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} md={3} sm={12} xs={12} className="p-3">
                    <div className="p-fluid" hidden={ClaTipoMinuta !== 1}>
                      <FieldDropdown  
                        name="ClaProyecto" 
                        label={t('minutameeting:Project')}
                        value={ClaProyecto} 
                        options={ProyectosList}  
                        optionValue="ClaProyecto"
                        optionLabel="NomProyecto" 
                        onChange={(e) => onChangeProyecto(e)} 
                        errors={errors}
                      />  
                    </div>
                  </Col>
                </Row>
              </Panel>
            </Col> 
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
            &nbsp;
            </Col> 
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>   
              <FieldDataTable
                value={MinutaList}
                scrollable  
                className="editable-cells-table"
                editMode="cell"
                selectionMode="single"
              >
                <Column field="IdMinuta" header={t('minutameeting:ID')} columnKey="IdMinuta" headerStyle={{ width: '30px' }}></Column>
                <Column field="FechaReunion" header={t('minutameeting:MeetingDate')} columnKey="FechaReunion" body={FechaReunion} headerStyle={{ width: '110px' }}></Column>
                <Column field="NomTipoReunion" header={t('minutameeting:MeetingType')} columnKey="NomTipoReunion" body={NomTipoReunionTemplate} headerStyle={{ width: '150px' }}></Column>
                <Column field="Asunto" header={t('minutameeting:Asunto')} columnKey="Asunto" body={AsuntoTemplate} headerStyle={{ width: '380px' }}></Column>
                <Column field="NomResponsable" header={t('minutaproyecto:MeetingResponsible')} body={NomResponsableTemplate} columnKey="NomResponsable" headerStyle={{ width: '250px' }}></Column>
                <Column field="Task" header={t('minutaproyecto:Task')} columnKey="Task" headerStyle={{ width: '60px' }}></Column>
                <Column field="OpenTask" header={t('minutaproyecto:OpenTask')} columnKey="OpenTask" headerStyle={{ width: '60px' }}></Column>
                <Column body={AccionesTemplate} style={{ textAlign: 'right' }} header={t('minutaproyecto:Progress')} headerStyle={{ width: '120px' }}></Column>
                <Column body={(rowData, props) => actionBodyTemplate(rowData, props)} headerStyle={{ width: '80px' }}></Column>

              </FieldDataTable>
            </Col> 
          </Row> 

          <Dialog
            visible={openSendMail}
            style={{ width: '600px' }}
            footer={modalFooter}
            modal
            className="p-fluid p-grid"
            onHide={closeModal}
            contentStyle={{maxHeight: "540px", overflow:"auto"}}
          >
            <Col lg={12} md={12} sm={12} xs={12}>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
                </Col>
              </Row>
              <Row hidden={ClaTipoMinutaSendEmail !== 1}>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className="p-fluid">
                    <FieldText
                      name="NomProyecto"
                      label={t('minutaproyecto:Project')}
                      value={ProjectDesc}
                      disabled={true}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className="p-fluid">
                    <FieldText
                      name="Asunto"
                      label={t('minutaproyecto:Subject')}
                      value={Asunto}
                      disabled={true}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className="p-fluid">
                    <FieldText
                      name="MeetingDay"
                      label={t('minutaproyecto:MeetingDay')}
                      value={moment(MeetingDay).format("MM/DD/YYYY")}
                      disabled={true}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className="p-fluid">
                    <FieldText
                      name="CopiarA"
                      label={t('minutaproyecto:CopyTo')}
                      onChange={(e) => onChangeCopiarA(e)}
                      value={CopiarA}
                      errors={errors}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <small>{t('minutaproyecto:AddMail')}</small>
                </Col>
              </Row> 
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className="p-fluid">
                    <FieldTextArea
                      name="Mensaje"
                      rows={3}
                      placeholder=''
                      maxLength={1500}
                      value={Mensaje}
                      onChange={(e) => onChangeMensajeModal(e)}
                      label={t('minutameeting:Message')}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
                </Col>
              </Row>
              <Row>
                <Col>
                  <FieldDataTable
                    value={ParticipantesMinutaList}
                    scrollable
                    scrollHeight="330px"
                    className="editable-cells-table"
                    editMode="cell"
                    selectionMode="single"
                  >
                    <Column field="EsEnviarCorreo" columnKey="EsEnviarCorreo" body={(rowData, props) => EsEnviarCorreoTemplate(rowData, props)} headerStyle={{ width: '75px' }}></Column>
                    <Column field="NomUsuario" header={t('minutaproyecto:Name')} columnKey="NomUsuario" headerStyle={{ width: '250px', textAlign: 'center' }}></Column>
                  </FieldDataTable>
                </Col>
              </Row>
            </Col>
          </Dialog>

          <Dialog
            visible={EsMinutaVisible}
            modal  
            header={renderHeader} 
            closable={false} 
            maximized={true}
            draggable={false} 
            blockScroll={true}
          >
            <MinutasMeeting IsHideBackButton={true} IdMinuta={IdMinuta} onCloseDetailView={onCloseMinuta}></MinutasMeeting>
          </Dialog>
        </PageContent>
      </>
      ); 

};

export default MeetingManagementScreen;

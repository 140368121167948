/* eslint-disable jsx-a11y/label-has-associated-control */
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import { Panel } from 'primereact/panel';
import React from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import DAISeerThought from './DAISeerThought/DAISeerThought';
import './DAISeerThoughtsPanel.scss';

// Props:
// - rawSteps
const DAISeerThoughtsPanel = (props) => {
  const {t, i18n} = useTranslation(['daiSeer', 'common']);  

  const thoughtsHeaderTemplate = (options) => {  
    return (
      <>
        <Row className='pt-2'>
          <Col>
            <FieldButton 
              icon='fa fa-brain'
              className="p-button-rounded p-button-text p-0" 
              label={t("daiSeer:Thoughts")}
              onClick={options.onTogglerClick}              
            />            
          </Col>
        </Row>
      </>
    )
  };

  const thoughTemplate = (
    <>
      {
        props.rawSteps && props.rawSteps.length > 0 ?
        (props.rawSteps.map((rawStep) => {
          return (
            <>
              <Row className='pb-4'>
                <Col>
                  <DAISeerThought 
                    rawStep={rawStep}
                  />
                </Col>
              </Row>
            </>
          )
        })) : <> </>
      }    
    </>
  );

  const thoughtsTemplate = (
    <>
      {
        props.rawSteps && props.rawSteps.length > 0 && (
          <>
            <Panel 
              headerTemplate={thoughtsHeaderTemplate} 
              className='DAISeerThoughtsPanelPanel'
              collapsed={true}
              style={{border: '0'}}
              toggleable         
            >
              {thoughTemplate}
            </Panel>    
          </>
        )
      }  
    </>
  );

  return (
    <>
      <Row className='DAISeerThoughtsPanel'>
        <Col> 
          {thoughtsTemplate}                           
        </Col>       
      </Row>
    </>
  )
}

export default DAISeerThoughtsPanel
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../../../utils/config';
import { callApi } from '../../../utils/utils';
import { Row, Col, Card, CardBody } from 'reactstrap';
import './DigitalViewDashboard.scss';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import DigitalViewDashboardMachineCardSkeleton from './DigitalViewDashboardMachineCardSkeleton';

const DigitalViewDashboardMachineCard = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);
  const [InformacionEncabezadoCelda, setInformacionEncabezadoCelda] = useState({});
  const [DetalleInformacionMaquinasCelda, setDetalleInformacionMaquinasCelda] = useState([]);
  const [CajasPorHoraMaquina, setCajasPorHoraMaquina] = useState([]);
  const [boxesByHrsChart, setboxesByHrsChart] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {        
    if (props.TipoPlano && props.ClaCelda) {
      GetDGTDigitalViewDashboardDetail(props.ClaCelda);
    }    
  }, [props.TipoPlano, props.ClaCelda, props.forceRefresh]);

  const GetDGTDigitalViewDashboardDetail = (ClaCelda) => { 
    setLoading(true);
    const url = `${config.UrlApiProject}DGT/GetDGTDigitalViewDashboardDetail?TipoPlano=${props.TipoPlano}&ClaCelda=${ClaCelda}`;  
    const paramsService= {};  
    callApi(url, 'GET', paramsService, (response) => {
      // const detail = response.data.DGTDashboardDetail[0];
      // const list = response.data.DGTDashboardDetailMachine;
      setInformacionEncabezadoCelda( response.data.InformacionEncabezadoCelda.length > 0 ? response.data.InformacionEncabezadoCelda[0] : {})
      setDetalleInformacionMaquinasCelda(response.data.DetalleInformacionMaquinasCelda);
      setCajasPorHoraMaquina(response.data.CajasPorHoraMaquina);
      
      const boxesByHr = []

      response.data.CajasPorHoraMaquina.forEach(element => {
        boxesByHr.push({
          y: element.CajasProducidas,
          data: element
        });
      });

      console.log('boxes', boxesByHr);

      setboxesByHrsChart({
        chart: { 
          type: 'column',
          height: 60,
          backgroundColor: 'rgba(0,0,0,0)', // Transparent background
        },
        title: {
            text: null
        }, 
        xAxis: {
            type: 'category',
            gridLineWidth: 0, // Hide x-axis grid lines
            lineWidth: 0, // Hide the x-axis line
            labels: {
              enabled: false, // Hide the xAxis labels
            }
        },
        yAxis: {
            allowDecimals: false,
            gridLineWidth: 0, // Hide x-axis grid lines
            title: {
                text: null
            },
            labels: {
              enabled: false, // Hide the xAxis labels
            }
        },
        tooltip: {
          formatter () {             
            return `    
              <p><b>Boxes Produced: ${this.y}</b></p><br>
              <p><b>Produced from ${this.point.data.TimeFrom} to ${this.point.data.TimeTo}</b></p>
            `;
          },
        },
        /*
        tooltip: {
          enabled: false, // Set to false to hide the tooltip
        },
        */
        series: [
          { 
            data: boxesByHr,
            showInLegend: false
          },
        ],
      })
      
      setLoading(false);
    }, (error) => {
      setLoading(false);
      console.error("Error getting machine detail", error);      
    }, false, false);
  }

  const calculaDescripcionTiempo = (tiempoHrs) => {

    if (tiempoHrs >= 1){
      return `${Highcharts.numberFormat(tiempoHrs,1, '.', ',')} Hrs`
    }

    return `${Highcharts.numberFormat(tiempoHrs*60.0,1, '.', ',')} Min`
  }

  const getStatusAsset = (StatusSenial) => {
    /*
			Status Senial 
			0 -> cuando no hay check in															   (gray-status)
			1 -> cuando hay check in y esta corriendo											   (green-status)
			2 -> cuando hay check in y esta detenida y no tiene paro y lleva menos de 30 minutos   (yellow-blink-status)
			3 -> cuando hay check in y esta detenida y no tiene paro y lleva mas   de 30 minutos   (red-blink-status)
			4 -> cuando hay check in y esta detenida y tiene paro								   (red-status)
			5 -> cuando hay check in y no tiene senial											   (blue-status) 
		  */   
    if (StatusSenial === 0){
        return 'gray-status'
    }
      
    if (StatusSenial === 1){
        return 'green-status'
    }
    
    if (StatusSenial === 2){
        return 'yellow-blink-status'
    }
  
    if (StatusSenial === 3){
        return 'red-blink-status'
    }
  
    if (StatusSenial === 4){
        return 'red-status'
    }
  
    if (StatusSenial === 5){
        return 'blue-status'
    }

    return '';
  }

  const getStatusCell = (EstatusProduccionCelda) => {
    /*
      EstatusProduccionCelda
      1 -> red-status
      2 -> yello-status
      3 -> green-status 
    */ 

    if (EstatusProduccionCelda === 1){
      return 'red-status'
    }
    
    if (EstatusProduccionCelda === 2){
      return 'yellow-status'
    }

    if (EstatusProduccionCelda === 3){
      return 'green-status'
    }

    return 'gray-status';
  }

  const cardTemplate = (
    <>
      <Row>
        <Col>
          <Card className='cell-card'>
            <CardBody>
              <Row className='align-items-start'>
                <Col xs={props.ShowAssetView? 6: 12}>
                  <div className={`card-cell-name ${getStatusAsset(InformacionEncabezadoCelda.OnOffCell)}`}>{InformacionEncabezadoCelda.NomAreaProduccion} # {InformacionEncabezadoCelda.ClaveCelda}</div>
                  <div className={`card-cell-sub-header ${getStatusAsset(InformacionEncabezadoCelda.OnOffCell)}`}>
                    <div className="box downtime">
                      <div className='title'>Downtime</div>
                      <div className='resultado'>{calculaDescripcionTiempo(InformacionEncabezadoCelda.DowntimeHrs)}</div>
                      <div className='resultado'>{`(${InformacionEncabezadoCelda.DowntimeQty})`}</div>
                    </div>
                    <div className="box uptime">
                      <div className='title'>Uptime</div>
                      <div className='resultado'>{calculaDescripcionTiempo(InformacionEncabezadoCelda.RunHrs)}</div>
                    </div>
                    <div className="box microstop">
                      <div className='title'>Microstops</div>
                      <div className='resultado'>{calculaDescripcionTiempo(InformacionEncabezadoCelda.MicrostopsHrs)}</div>
                      <div className='resultado'>{`(${InformacionEncabezadoCelda.MicroStopsQty})`}</div>
                    </div>
                  </div>
                  <div className={`card-cell-container ${getStatusCell(InformacionEncabezadoCelda.EstatusProduccionCelda)}`}>
                    <div className='leyenda-clave-producto' hidden={InformacionEncabezadoCelda.CurrentDowntimeHrs===null||InformacionEncabezadoCelda.CurrentDowntimeHrs<=0}>
                      <div><b>Current Downtime</b></div>
                      <i className='far fa-clock'></i>&nbsp;{calculaDescripcionTiempo(InformacionEncabezadoCelda.CurrentDowntimeHrs)}
                    </div> 
                    <div className='resultado'> 
                      {InformacionEncabezadoCelda.Unidades}
                    </div> 
                    <div className='leyenda-resultado'> 
                      BOXES
                    </div>
                    <br></br> 
                    <br></br> 
                    <div className='leyenda-clave-producto'> 
                      {InformacionEncabezadoCelda.ClaveArticulo}
                    </div>
                    <div className='leyenda-boxes-message'> 
                      {InformacionEncabezadoCelda.ProduccionObj}
                    </div>
                  </div>
                  <div className={`card-cell-boxes-by-time-frame ${getStatusAsset(InformacionEncabezadoCelda.OnOffCell)}`}>
                    <div className='chart-title'>
                      Boxes/Hr
                    </div>
                    <div id="box-production-chart" style={{ width: '100%', height: '60px' }}>
                      {
                        boxesByHrsChart !== null &&
                        (<HighchartsReact highcharts={Highcharts} options={boxesByHrsChart} />)
                      } 
                    </div>
                  </div>
                </Col>
                {
                  props.ShowAssetView && (
                    <>
                      <Col xs={6}>
                        <div className='card-assets-title'>Assets View</div>
                        <div className='assets-container'>
                          <div className='assets-list'>
                            { 
                              DetalleInformacionMaquinasCelda.map((item, index) => { 
                                  return (
                                    <>                                  
                                      <div key={index} className={`asset ${getStatusAsset(item.StatusSenial)}`}>
                                        <div className="box name">
                                          <div className='title'>{item.DisplayName}</div>
                                          <div className='resultado'>{
                                              item.StatusSenial === 1 ? 'Running'
                                            : item.StatusSenial === 2 || item.StatusSenial === 3 || item.StatusSenial === 4  ? 'Down'
                                            : item.StatusSenial === 5 ? 'Offline' 
                                            : ''
                                          }
                                          </div>
                                        </div> 
                                        <div className="box downtime">
                                          <div className='title'>Downtime</div>
                                          <div className='resultado'>{calculaDescripcionTiempo(item.DowntimeHrs)}</div>
                                          <div className='resultado'>{`(${item.DowntimeQty})`}</div>
                                        </div>
                                        <div className="box uptime">
                                          <div className='title'>Uptime</div>
                                          <div className='resultado'>{calculaDescripcionTiempo(item.RunHrs)}</div>
                                        </div>
                                        <div className="box microstop">
                                          <div className='title'>Microstops</div>
                                          <div className='resultado'>{calculaDescripcionTiempo(item.MicrostopsHrs)}</div>
                                          <div className='resultado'>{`(${item.MicroStopsQty})`}</div>
                                        </div>
                                      </div>
                                      <div style={{height:'0.5em'}}></div>
                                    </>

                                  );
                              })
                            }   
                          </div>
                        </div>                  
                      </Col>                    
                    </>
                  )
                }
              </Row>
            </CardBody>          
          </Card>
        </Col>
      </Row>    
    </>
  );
 
  return (
    <>
      {
        loading? (
          <DigitalViewDashboardMachineCardSkeleton
            ShowAssetView={props.ShowAssetView}
          />
        ) : (
          <>
            {cardTemplate}
          </>          
        )
      }
    </>  
  ); 
};

export default DigitalViewDashboardMachineCard;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row, Col, Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi, showSweetAlert, getSessionItem } from '../../../utils/utils';
import { Checkbox } from 'primereact/checkbox';
import FieldText from '../../../components/Controls/FieldText.jsx';
import FieldTextArea from '../../../components/Controls/FieldTextArea.jsx';
import FieldNumber from '../../../components/Controls/FieldNumber.jsx';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx';
import FieldDropdown from 'components/Controls/FieldDropdown.jsx';
import { RadioButton } from 'primereact/radiobutton';
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import moment from 'moment';
import { Sidebar } from 'primereact/sidebar';
import swal from '@sweetalert/with-react';
import { Tag } from 'primereact/tag';
import PageContent from 'layouts/PageContent.jsx';
import { Dialog } from 'primereact/dialog';
import PropType from 'prop-types';
import { Toolbar } from 'primereact/toolbar';
import FieldAutoComplete from 'components/Controls/FieldAutoComplete.jsx';
import SweatAlertCustomIcon from 'components/SweatAlert/SweatAlertCustomIcon.jsx';

const IncetiveProgramSetUpMonthStatus = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['salesPerformanceSales', 'common']);
  const [errors, setErrors] = useState({});
  const [AniosList, setAniosList] = useState([]);
  const [ClaAnio, setClaAnio] = useState(null);
  const [FechaCierre, setFechaCierre] = useState(null);
  const [MonthStatusList, setMonthStatusList] = useState([]);

  useEffect(() => {
    GetAniosCmb();
  }, [props.ClaAgenteVentas]);

  const GetAniosCmb = () => {
    const GetAniosCmb = `${config.UrlApiProject}SalesPerformanceSales/GetAniosCmb`;
    const paramsToService = {};
    callApi(GetAniosCmb, 'GET', paramsToService, (response) => {
      setAniosList(response.data.Anios);
      setClaAnio(response.data.Anios.length > 0 ?  response.data.Anios[0].ClaAnio : null);
      GetIncentiveProgramCompensationMonthStatus(response.data.Anios.length > 0 ?  response.data.Anios[0].ClaAnio : null);
    });
  };

  const close = async () => {
    setErrors({});
    if (props.onClickClose !== null) {
      props.onClickClose();
    }
  };

  const GetIncentiveProgramCompensationMonthStatus = (pClaAnio) => {
    const GetIncentiveProgramCompensationMonthStatus = `${config.UrlApiProject}SalesPerformanceSales/GetIncentiveProgramCompensationMonthStatus?ClaAnio=${pClaAnio}`;

    callApi(GetIncentiveProgramCompensationMonthStatus, 'GET', {}, (response) => {
      setMonthStatusList(response.data.MonthStatus);
    });
  };

  const onChangeAnio = (e) => {
    setClaAnio(e.value);
    console.log(e.value);
    if (e.value === null || e.value === undefined) {
      setMonthStatusList(null);
    } else {
      GetIncentiveProgramCompensationMonthStatus(e.value);
    }
  };

  const EsMesCerradoTemplate = (rowData) => {
    if (rowData.EsMesCerrado === 0) {
        return (
          <>
            <div className="text-center">
              <span>{t('common:Open')}</span>
            </div>  
          </>
          );    
    }
    return (
      <>
        <div className="text-center">
          <i className="fa fa-check light-green-color"></i>
        </div>
      </>
    );
  };

  const onConfirmaCierre = (rowData) => {
    swal({ 
      buttons: {
        cancelar: {
          text: t('common:Cancel'),
          value: "cancel",
          className: "rounded-button danger"
        },
        continuar: {
          text: t('common:Continue'),
          value: "continue",
          className: "rounded-button light-green"
        }
      },
      content: confirmationMessageHTML
    }).then((value) => {
      switch (value) { 
        case "continue":
            ClosedMonthStatus(rowData);
          break; 
        default:
          break;
      }
    });
  }

  const confirmationMessageHTML = (
    <div>
      <SweatAlertCustomIcon title={t('salesPerformance:MonthCancellation')} letter={t('common:QuestionMark')}></SweatAlertCustomIcon> 
      <div>{t('salesPerformance:MonthCloseConfirmation')}.</div> 
    </div>  
   )    

  const ClosedMonthStatus = async (rowData) => {
    
    const urlWebServiceSave = `${config.UrlApiProject}SalesPerformanceSales/ClosedMonthStatus`;
    const paramsService = {
        FechaCierre: rowData.AnioMes,
        ClaAgenteVentas: 5 // Se solicito que se mandara 5 por DAFAULT. "ServByAgen"
    }; 
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {
      showSweetAlert('success', t('common:SuccessOperation'), 'success'); 
      GetIncentiveProgramCompensationMonthStatus(ClaAnio);
    //   if (props.onChange !== null && props.onChange !== 'undefined' && typeof(props.onChange) === 'function'){
    //     props.onChange();
    //   }
    });
  }


  return (
    <>
      <Dialog
        visible={props.IsVisible}
        header={t('salesPerformance:MonthStatus')}
        style={{ width: '60vw' }}
        modal
        className="dialog-custom"
        onHide={close}
      >
        <Row className="align-items-start">
          <Col lg={6} md={6} sm={12} xs={12} className="p-3">
            <div className="p-fluid">
              <FieldDropdown
                name="ClaAnio"
                label={t('salesPerformance:Anio')}
                value={ClaAnio}
                options={AniosList}
                optionValue="ClaAnio"
                optionLabel="NomAnio"
                onChange={(e) => onChangeAnio(e)}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="p-3">
            <FieldDataTable
              value={MonthStatusList}
              scrollable
              scrollHeight="330px"
              className="editable-cells-table"
              editMode="cell"
              selectionMode="single"
            >
              <Column
                field="NomMes"
                header={t('salesPerformanceSales:Month')}
                columnKey="NomMes"
                headerStyle={{ width: '85px' }}
              >
              </Column>
              <Column
                body={EsMesCerradoTemplate}
                header=''
                style={{ textAlign: 'center' }}
                headerStyle={{ width: '30px' ,textAlign: 'center' }} 
              >
              </Column>
            </FieldDataTable>
          </Col>
        </Row>
      </Dialog>
    </>
  );
};

export default IncetiveProgramSetUpMonthStatus;


import React from 'react';

const StepsTourCostAnalysisDetail = {
    steps: [
      {
        target: '.AC-Step-1',
        content: (
          <>
            <h1>Filters Section</h1>
            Use this section to filter your data. Remember, you will need to fill in all required fields. 
            <br></br>
            <br></br>
            Do not forget to click the <b>Search Icon</b> when you are ready.  
          </>
        ),
        disableBeacon: true
      },
      {
        target: '.AC-Step-2',
        content: (
          <>
            <h1>Tab Section</h1>
            Click the tab to change between tabs. 
          </>
        )
        /* ,
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: true,
        placement: 'bottom',
        spotlightClicks: true,
        */
      } ,
      {
        target: '.AC-Step-3-2',
        content: (
          <>
            <h1>DEACERO / MSW</h1>
            Use this option to switch between Deacero and MSW groups
          </>
        ),
        styles: {
            options: {
              zIndex: 10000,
            },
          }
      } ,
      {
        target: '.AC-Step-4-2',
        content: (
          <>
            <h1>Options</h1>
            <div style={{ textAlign:'left'}}> 
              Here you can switch between different views to analyze the information.
            </div>  
          </>
        ),
        styles: {
          options: {
            zIndex: 10000,
          },
        } 
      } ,
      {
        target: '.AC-Step-4-3',
        content: (
          <>
            <h1>ELEMENTS</h1>
            <div style={{ textAlign:'left'}}> 
              Click here if you want to see the Actual cost by element.
            </div>  
          </>
        ),
        styles: {
          options: {
            zIndex: 10000,
          },
        } 
      }   ,
      {
        target: '.AC-Step-4-4',
        content: (
          <>
            <h1>ACTUAL VS STANDARD</h1>
            <div style={{ textAlign:'left'}}> 
              Click here if you want to compare Actual vs Standard Cost by element.
            </div>  
          </>
        ),
        styles: {
          options: {
            zIndex: 10000,
          },
        } 
      }   ,
      {
        target: '.AC-Step-4-5',
        content: (
          <>
            <h1>CELLS</h1>
            <div style={{ textAlign:'left'}}> 
              Click here if you want to see the information by Cell. 
              <br></br>
              <br></br>
              Select a row to analyze a specific cell.
            </div>   
            <br></br>
            <div style={{ textAlign:'left'}}>  
              To drill down deeper click the red square icon at the beginning of row to see which products were produced by that cell.
            </div>
          </>
        ),
        styles: {
          options: {
            zIndex: 10000,
          },
        } 
      }   
    ]
  };

  
export default StepsTourCostAnalysisDetail;
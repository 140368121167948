/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col, 
  Card,
  CardHeader,
  CardBody 
} from 'reactstrap'; 
// import FeedbackCoaching from './FeedbackCoaching.jsx'; 
import Highcharts from 'highcharts';
import { config } from '../../../../utils/config';
import { callApi } from '../../../../utils/utils'; 
import { TabPanel, TabView } from 'primereact/tabview'; 
import DailyOperationProductionTrend from './DailyOperationProductionTrend';
import { Dialog } from 'primereact/dialog';
import moment from 'moment';

const DailyOperationAssetDetail = ({
    ClaUbicacion,
    ClaArea,
    Fecha
}) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['wtw', 'common']); 
 
  const [ProduccionSeguimientoDiarioList, setProduccionSeguimientoDiarioList] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [EsDialog, setEsDialog] = useState(false);
  const [ClaCelda, setClaCelda] = useState(false);
  const [ClaTurno, setClaTurno] = useState('');
  const [NomMaquina, setNomMaquina] = useState('');
  const [NomTurno, setNomTurno] = useState('');
  
  useEffect(() => { 
    // Obtenemos la informacion del detalle de maquinas asociadas al area seleciconada, si compartieron todos los parametros al invocar el componente.
    if (ClaUbicacion && ClaArea && Fecha) {
        onSearch();
    }
  }, [ClaUbicacion, ClaArea, Fecha]);

  const onSearch = async () => {  
    const value = {
      ClaUbicacion,
      ClaArea, 
      Fecha
    } 
     
    const urlWebService = `${config.UrlApiProject}WTW/GetProduccionSeguimientoDiarioByArea`; 
    const paramsService = {
        ClaUbicacion,
        ClaArea,
        Fecha 
    }; 

    callApi(urlWebService, 'POST', paramsService, (response) => {  
            setProduccionSeguimientoDiarioList(response.data.ProduccionSeguimientoDiarioList); 
        }, () => { });
  };

  if (ProduccionSeguimientoDiarioList.length === 0){
    return (
      <div className='pt-3'>
        <Card className='card-produccion'>
          <CardBody className="" style={{paddingTop: "5px"}}>
            <Row className='pt-2 text-center'>
              <Col lg={12} md={12} sm={12} xs={12}>  
                {t('common:NoData')}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }

  const obtieneTurnosTabs = (infoProduccion) => { 
    const uniqueTurnos = infoProduccion.reduce((acc, current) => {
      // Check if the current combination of NomTurno and ClaTurno is already in the accumulator
      const exists = acc.find(item => 
          item.NomTurno === current.NomTurno && item.ClaTurno === current.ClaTurno
      );
  
      // If the combination doesn't exist, add it to the accumulator
      if (!exists) {
          acc.push({ NomTurno: current.NomTurno, ClaTurno: current.ClaTurno });
      }
      return acc;
    }, []); 

    return uniqueTurnos;
  }

    const onOpenDialog = (item) => {  
      setClaCelda(item.ClaCelda);
      setClaTurno(item.ClaTurno);
      setNomMaquina(item.NomMaquina);
      setNomTurno(item.NomTurno);
      setEsDialog(true);
  }
  const dialogStyle  = ( 
    {  
   }
  )

  const headerDialogProductionTrendDetail = () => {
    return (
      <>
        {t('wtw:ProductionTrendDetail')} - {moment(Fecha).format('MMMM, YYYY') } - {t('wtw:Shift')} : {NomTurno}  - {t('wtw:Cell')} : {NomMaquina}
      </>
    )
  }
 
  return (
    <> 
      <div> 
        {
          obtieneTurnosTabs(ProduccionSeguimientoDiarioList).length > 0 
          &&
          (
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}> 
              {
                        
                   obtieneTurnosTabs(ProduccionSeguimientoDiarioList).map((item,index)=>{ 
                          return( 
                            <TabPanel header={`Shift: ${item.NomTurno}`}>
                              {
                                  ProduccionSeguimientoDiarioList.filter(x => x.ClaTurno === item.ClaTurno).map((item, index) => { 
                                                return ( 
                                                  <Card key={index}>
                                                    <CardHeader className='bg-card-header-gray'>
                                                      <Row className="align-items-start p-3">
                                                        <Col>
                                                          <Row> 
                                                            <Col lg={3} md={3} sm={12} xs={12}>
                                                              <span className='bold'>{`${t('wtw:Cell')}:`}</span> 
                                                              <span
                                                                className='link-text' 
                                                                onClick={() => onOpenDialog(item)}
                                                              > {`${item.NomMaquina}`}
                                                              </span>
                                                            </Col>
                                                            <Col lg={9} md={9} sm={12} xs={12} className='text-right'>
                                                              <span className='bold'>{`${t('wtw:UnitsByHr')}:`}</span> 
                                                              <span>  {`${Highcharts.numberFormat(item.UnitsByHr, 2,'.', ',')}`}</span>
                                                              &nbsp;
                                                              <span className='bold'>{`${t('wtw:Utilizacion')}:`}</span> 
                                                              <span>  {`${Highcharts.numberFormat(item.Utilizacion,1, '.', ',')}`} %</span>
                                                              &nbsp;
                                                              <span className='bold'>{`${t('wtw:Efficiencia')}:`}</span> 
                                                              <span>  {`${Highcharts.numberFormat(item.Efficiencia, 1,'.', ',')}`} %</span>
                                                              &nbsp;
                                                              <span className='bold'>{`${t('wtw:Scrap')}:`}</span> 
                                                              <span>  {`${Highcharts.numberFormat(item.Scrap,1,'.', ',')}`} %</span>
                                                              &nbsp; 
                                                              <span className='bold'>{`${t('wtw:ScheduledHrs')}:`}</span> 
                                                              <span>  {`${Highcharts.numberFormat(item.HrsProgramadas,1, '.', ',')}`}</span>
                                                              &nbsp;
                                                              <span className='bold'>{`${t('wtw:RunningHrs')}:`}</span> 
                                                              <span>  {`${Highcharts.numberFormat(item.RunningHrs,1, '.', ',')}`}</span>
                                                              &nbsp;
                                                              <span className='bold'>{`${t('wtw:DowntimeHours')}:`}</span> 
                                                              <span>  {`${Highcharts.numberFormat(item.HorasParo,1, '.', ',')}`}</span>
                                                            </Col>
                                                          </Row> 
                                                        </Col>
                                                      </Row>
                                                    </CardHeader>
                                                    <CardBody className="" style={{ padding: "0px", paddingTop: "5px"}}>  
                                                      <Row className='p-2' hidden={true}> 
                                                        <Col>
                                                          <span className='info-color'>{`${t('wtw:ReasonsListedInfo')}`}</span>
                                                        </Col>
                                                      </Row>
                                                      <table width="100%"> 
                                                        <tbody>
                                                          <tr className='pb-3'>
                                                            <td style={{verticalAlign:"top"}}>
                                                              <b>{`${t('wtw:DowntimeReason')}`}</b>
                                                              <div>
                                                                <ul>
                                                                  {
                                                                  item.ProduccionParo.map((itemData, indexData) => { 
                                                                    return (<li key={indexData}> {`${itemData.NomRazon}`}</li>);
                                                                  })
                                                                }
                                                                </ul>
                                                              </div>
                                                            </td>
                                                            {/* <td><b>{`${t('wtw:Hours')}`}</b></td> */}
                                                            <td width="35%" style={{verticalAlign:"top"}}>
                                                              <b>{`${t('wtw:Problem')}`}</b>
                                                              <div className="p-fluid">
                                                                <span className='label'>
                                                                  {item.ExplicacionProblema}  
                                                                </span> 
                                                              </div>
                                                            </td>
                                                            <td width="35%" style={{verticalAlign:"top"}}>
                                                              <b>{`${t('wtw:Solution')}`}</b>
                                                              <div className="p-fluid">
                                                                <span className='label'>
                                                                  {item.PosibleSolucionProblema}  
                                                                </span>  
                                                              </div>
                                                            </td>
                                                          </tr>  
                                                        </tbody>
                                                      </table>
                                                    </CardBody>
                                                  </Card>
                                              );
                                  })
                                } 
                            </TabPanel>  
                          ) 
                  })  
              } 
            </TabView>
            
          )
        }
        {EsDialog ? (
          <>
            <Dialog
              visible={EsDialog}
              header={headerDialogProductionTrendDetail}
              style={dialogStyle} 
              draggable={false}
              modal 
              maximized={true}
              className="DailyOperationAssetDetailTree dialog-header-background-blue"
              onHide={() => setEsDialog(false)}
              position="top"
            > 
              <DailyOperationProductionTrend
                visible={EsDialog} 
                Fecha={Fecha}
                ClaUbicacion={ClaUbicacion}
                ClaArea={ClaArea}
                ClaCelda={ClaCelda}
                ClaTurno={ClaTurno}
                DisableFilters={true}
              >
              </DailyOperationProductionTrend>
            </Dialog>  
          </>
        ) : (
          <></>
        )}   
         
      </div>  
    </>
  );
};

export default DailyOperationAssetDetail;

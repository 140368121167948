import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardHeader, CardBody, CardFooter, Label } from 'reactstrap';
import { callApi, showSweetAlert, formatDate } from "utils/utils";
import { config } from 'utils/config';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import FixPODForm from './FixPODForm';
import FieldText from 'components/Controls/FieldText';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FixPODType from './FixPODTypes';

const FixUnidentifiedDocuments = (props) => {
  const docType = props.docType !== undefined ? props.docType : FixPODType.Email;

  const {t, i18n} = useTranslation(['pod', 'common']);

  const [unidentifiedDocuments, setUnidentifiedDocuments] = useState([]);
  const [document, setDocument] = useState([]);
  const [showFixDialog, setShowFixDialog] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [locations, setLocations] = useState(null);
  const [location, setLocation] = useState(null);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    getUnidentifiedDocuments();

    if (docType === FixPODType.Deacero) {
      getDeaceroLocations();
    }
  }, [searchText, location])

  // Add an ID field to each row
  const addIdToDocuments = (docs) => {
    const newDocs = [];
    for (let i = 0; i < docs.length; i++) {
      const row = docs[i];
      row.id = i;
      newDocs.push(row);
    }

    return newDocs;
  }

  // Get unidentified emails
  const getEmailPODsWithErrors = () => {
    const url = `${config.UrlApiProject}POD/GetEmailPODsWithErrors?searchText=${searchText}`;
    const paramsService = {};
    callApi(url, 'GET', paramsService, (response) => {
      const docs = addIdToDocuments(response.data);
      setUnidentifiedDocuments(docs);
    });
  }

  // Get the locations for unidentified Deacero docs
  const getDeaceroLocations = () => {
    const url = `${config.UrlApiProject}POD/GetUnidentifiedDeaceroDocLocations`;
    const paramsService = {};
    callApi(url, 'GET', paramsService, (response) => {
      setLocations(response.data);
    });
  }

  // Get the unidentified deacero docs
  const getUnidentifiedAutoProcessedDeaceroDocuments = () => {
    const url = `${config.UrlApiProject}POD/GetUnidentifiedAutoProcessedDeaceroDocuments?searchText=${searchText}${location !== null ? `&claUbicacion=${location}` : ''}`;
    const paramsService = {};
    callApi(url, 'GET', paramsService, (response) => {
      const docs = addIdToDocuments(response.data);
      setUnidentifiedDocuments(docs);
    });
  }

  // Call the correct method to get the docs based on docType
  const getUnidentifiedDocuments = () => {
    switch (docType) {
      case FixPODType.Email:
        getEmailPODsWithErrors();
        break;
      case FixPODType.Deacero:
        getUnidentifiedAutoProcessedDeaceroDocuments();
        break;
      default:
        break;
    }
  }

  // Update the search text
  const onSearchTextChange = (e) => {
    setSearchText(e.target.value === undefined ? '' : e.target.value === null ? '' : e.target.value);
  }

  // Get the document pdf and open the fix dialog
  const openFixPODDialog = (row) => {
    let url = '';
    switch (docType) {
      case FixPODType.Email:
          url = `${config.UrlApiProject}POD/GetEmailPODFile?IdMail=${row.IdMail}&IdAttachtment=${row.IdAttachtment}`;
        break;
      case FixPODType.Deacero:
        url = `${config.UrlApiProject}POD/GetDocumentFromCXPTraFacturaPOD?IdFactura=${row.IdFactura}&ClaUbicacion=${row.ClaUbicacion}`;
        break;
      default:
        break;
    }

    const paramsService= {};  
    callApi(url, 'GET', paramsService, (response) => {
      const doc = response.data;
      doc.id = row.id;
      setDocument(doc);
      setShowFixDialog(true);
    });    
  }

  // Get the next Deacero document
  const getNextDeaceroDocument = async () => {
    const nextDocRow = unidentifiedDocuments[document.id + 1]
    const idFactura = nextDocRow.IdFactura;
    const claUbicacion = nextDocRow.ClaUbicacion;

    const url = `${config.UrlApiProject}POD/GetDocumentFromCXPTraFacturaPOD?IdFactura=${idFactura}&ClaUbicacion=${claUbicacion}`;

    const doc = {
      Title: `${nextDocRow.NumFactura} | ${nextDocRow.NombreUbicacion} | ${formatDate(nextDocRow.Fecha)}`,
      ShowSubmitNext: unidentifiedDocuments.length > 0 && nextDocRow.id < unidentifiedDocuments.length - 1
    };

    const paramsService = {};
    await callApi(url, 'GET', paramsService, (response) => {
      const newDoc = response.data;
      newDoc.id = document.id;
      doc.Document = newDoc;
      
    });
    setDocument(unidentifiedDocuments[document.id + 1]);
    return doc;
  }

  // Get the next email document
  const getNextEmailDocument = async () => {
    const nextDocRow = unidentifiedDocuments[document.id + 1]
    const idMail = nextDocRow.IdMail;
    const idAttachtment = nextDocRow.IdAttachtment;

    const url = `${config.UrlApiProject}POD/GetEmailPODFile?IdMail=${idMail}&IdAttachtment=${idAttachtment}`;

    const doc = {
      Title: `Email: ${document.IdMail} | Attachment: ${document.IdAttachtment} | ${nextDocRow.Subject} | ${nextDocRow.FromAddress} | ${formatDate(nextDocRow.SentDate)}`,
      ShowSubmitNext: unidentifiedDocuments.length > 0 && nextDocRow.id < unidentifiedDocuments.length - 1
    };

    const paramsService = {};
    await callApi(url, 'GET', paramsService, (response) => {
      const newDoc = response.data;
      newDoc.id = document.id;
      doc.Document = newDoc;
      
    });
    setDocument(unidentifiedDocuments[document.id + 1]);
    return doc;
  }

  // Hide the Fix POD Dialog
  const onShowFixDialogHide = () => {
    setDocument([]);
    getUnidentifiedDocuments();
    setShowFixDialog(false);
  }


  // Update location
  const onLocationChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.location;
    setErrors(newErrors);
    setLocation(e.value !== undefined ? e.value : null);
  }

  // Fix button template
  const fixButton = (row) => {
    return (
      <>
        <FieldButton className='p-button-rounded p-button-success mr-1' icon='pi pi-pencil' tooltip={t('pod:FixDocument')} onClick={() => openFixPODDialog(row)} />
      </>
    )
  }

  // Delete button template
  const deleteButton = (row) => {
    return (
      <>
        <FieldButton className='p-button-rounded p-button-danger mr-1' icon='pi pi-trash' tooltip={t('pod:DeleteDocument')} onClick={() => onDeleteDocumentClick(row)} />
      </>
    )
  }

  // Date template
  const dateTemplate = (row) => {
    return (
      <>
        {
          docType === FixPODType.Email ? formatDate(row.SentDate)
          : docType === FixPODType.Deacero ? formatDate(row.Fecha)
          : 'ND'
        }
      </>
    )
  }

  // Render the document table
  const documentTable = () => {
    if (docType === FixPODType.Email) {
      return (
        <>
          <FieldDataTable
            value={unidentifiedDocuments} 
            header={t('pod:PODs')}  
            scrollable
            removableSort
          >
            <Column field="IdMail" header={t('pod:IdMail')} headerStyle={{ width: '75px' }} sortable />
            <Column field="IdAttachtment" header={t('pod:IdAttachment')} headerStyle={{ width: '125px' }} sortable />
            <Column field="Subject" header={t('pod:Subject')} headerStyle={{ width: '325px' }} />
            <Column field="FromAddress" header={t('pod:From')} headerStyle={{ width: '225px' }} />
            <Column body={dateTemplate} header={t('pod:Sent')} headerStyle={{ width: '150px' }} sortable sortField='SentDate' />
            <Column body={fixButton} header={t('pod:Fix')} headerStyle={{ width: '50px' }} />
            <Column body={deleteButton} header={t('pod:Delete')} />
          </FieldDataTable>
        </>
      )
    }

    if (docType === FixPODType.Deacero) {
      return (
        <>
          <FieldDataTable
            value={unidentifiedDocuments} 
            header={t('pod:PODs')}  
            scrollable
            removableSort
          >
            <Column field="NumFactura" header={t('pod:VendorInvoiceNum')} headerStyle={{ width: '150px' }} sortable />
            <Column field="NombreUbicacion" header={t('pod:Location')} headerStyle={{ width: '200px' }} />
            <Column body={dateTemplate} header={t('pod:InvoiceDate')} headerStyle={{ width: '150px' }} sortable sortField='Fecha' />
            <Column body={fixButton} header={t('pod:Fix')} headerStyle={{ width: '50px' }} />
            <Column body={deleteButton} header={t('pod:Delete')} />
          </FieldDataTable>
        </>
      )
    }

    if (docType === FixPODType.MCN) {
      return (
        <></>
      )
    }
  }

  // Render the FixPODForm
  const fixPODForm = () => {
    if (docType === FixPODType.Email) {
      return (
        <>
          <FixPODForm 
            document={document} 
            documentTitle={`Email: ${document.IdMail} | Attachment: ${document.IdAttachtment} | ${document.Subject} | ${document.FromAddress} | ${formatDate(document.SentDate)}`} 
            submitDocumentAsFixedToAPI={submitEmailDocumentAsFixed}
            showSubmitNext={unidentifiedDocuments.length > 0 && document.id < unidentifiedDocuments.length - 1}
            getNextDocument={getNextEmailDocument}
          />
        </>
      )
    }

    if (docType === FixPODType.Deacero) {
      return (
        <>
          <FixPODForm 
            document={document} 
            documentTitle={`${document.NumFactura} | ${document.NombreUbicacion} | ${formatDate(document.Fecha)}`} 
            submitDocumentAsFixedToAPI={submitAutomaticDeaceroDocumentAsFixed}
            showSubmitNext={unidentifiedDocuments.length > 0 && document.id < unidentifiedDocuments.length - 1}
            getNextDocument={getNextDeaceroDocument}
          />
        </>
      )
    }
  }

  // Submit an email document as fixed
  const submitEmailDocumentAsFixed = (newDocPages, goToNext = false) => {
    const completedDoc = {
      IdMail: document.IdMail,
      IdAttachtment: document.IdAttachtment,
      Pages: newDocPages
    }

    const url = `${config.UrlApiProject}POD/FixEmailPOD`;
    const paramsService = completedDoc; 
    callApi(url, 'POST', paramsService, (response) => {
      if (!goToNext && unidentifiedDocuments.length > 0 && document.id < unidentifiedDocuments.length) {
        showSweetAlert("Success", t('common:Success'), 'success');
        onShowFixDialogHide();
      }
    });   
  }

  // Submit a Deacero document as fixed
  const submitAutomaticDeaceroDocumentAsFixed = (newDocPages, goToNext = false) => {
    const completedDoc = {
      IdFactura: document.IdFactura,
      ClaUbicacion: document.ClaUbicacion,
      Pages: newDocPages
    }

    const url = `${config.UrlApiProject}POD/FixAutomaticDeaceroPOD`;
    const paramsService = completedDoc; 
    callApi(url, 'POST', paramsService, (response) => {
      if (!goToNext && unidentifiedDocuments.length > 0 && document.id < unidentifiedDocuments.length) {
        showSweetAlert("Success", t('common:Success'), 'success');
        onShowFixDialogHide();
      }
    });   
  }

  // Delete an Email Document
  const onDeleteDocumentClick = (row) => {
    if (docType === FixPODType.Email) {
      const url = `${config.UrlApiProject}POD/DeleteEmailPODWithError?IdMail=${row.IdMail}&IdAttachtment=${row.IdAttachtment}`;
      const paramsService = {}; 
      callApi(url, 'DELETE', paramsService, (response) => {
          showSweetAlert("Success", t('common:Success'), 'success');
          setDocument([]);
          getUnidentifiedDocuments();
      });
    }

    if (docType === FixPODType.Deacero) {
      const url = `${config.UrlApiProject}POD/DeleteCXPPODWithError?IdFactura=${row.IdFactura}&ClaUbicacion=${row.ClaUbicacion}`;
      const paramsService = {}; 
      callApi(url, 'DELETE', paramsService, (response) => {
          showSweetAlert("Success", t('common:Success'), 'success');
          setDocument([]);
          getUnidentifiedDocuments();
      });
    }
  }

  // Return
  return (
    <>
      <Row className='p-fluid'>
        <Col xs={12} sm={12} md={docType === FixPODType.Deacero ? 8 : 12} lg={docType === FixPODType.Deacero ? 8 : 12} className='p-field'>
          <Label className='mr-3'>Search</Label>
          <Label className='text-warning'>
            <i className='pi pi-info-circle mr-1' />
            {docType === FixPODType.Email ? t('pod:FixEmailPODSearchInfo') : docType === FixPODType.Deacero ? t('pod:FixDeaceroDocSearchInfo') : ''}
          </Label>
          <FieldText
            value={searchText}
            onChange={onSearchTextChange}
          />
        </Col>
        {docType === FixPODType.Deacero ? 
        (        
          <Col xs={12} sm={12} md={4} lg={4} className='p-field'>
            <Label>{t('pod:Location')}</Label>
            <FieldDropdown
              name='location'
              value={location}
              optionValue="ClaUbicacion"
              optionLabel="NombreUbicacion"
              options={locations}
              onChange={onLocationChange}
              errors={errors}
            />
          </Col>
        ) : <></>}
      </Row>
      <Row className='p-fluid'>
        <Col xs={12} sm={12} md={12} lg={12}>
          {documentTable()}
        </Col>
      </Row>
      <Dialog
        maximized={true}
        visible={showFixDialog}
        onHide={() => onShowFixDialogHide()}
        modal
        blockScroll
      >
        {fixPODForm()}
      </Dialog>
    </>
  )
}

export default FixUnidentifiedDocuments;
import React from 'react';
import { Route, Router, Redirect } from 'react-router-dom';
import classnames from 'classnames';
import env from '@beam-australia/react-env';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap';
import AuthHeader from 'components/Headers/AuthHeader';
import { config } from '../../utils/config';
import AdminLayout from '../../layouts/Admin';
import queryString from 'query-string' 
import NoMenu from '../../layouts/NoMenu';
import ExternalPages from '../../layouts/ExternalPages.jsx';
import ExternalPagesNoMenu from '../../layouts/ExternalPagesNoMenu.jsx';

import {
  setSessionData,
  sessionAlive,
  logOut,
  showSweetAlert,
  callApi,
  getSessionItem,
} from '../../utils/utils';
import logo from '../../assets/img/MSWLogo35.png';
import OneSignal from 'react-onesignal';

const REACT_APP_VAR = env('VAR');
const REACT_APP_OTRO = env('OTRO');

let user = '';
let pass = ''; 
let noMenu =  '0';
let redirectURL = ''; 
let IsDeviceAssignedOnCell = false;

class Login extends React.Component {

 

  constructor(props) { 
    super(props);  

    this.state = {
      username: '' ,
      password: '',
    };

    /* si recibimos la informacion de usuario y password intentamos logear en automatico y 
       redireccionamos al URL solicitada */
    const params=queryString.parse(props.location.search);
    user = params.usuario ? params.usuario : '';
    pass = params.password ? params.password : ''; 
    noMenu = params.nomenu ? params.nomenu : '0';
    IsDeviceAssignedOnCell = params.IsDeviceAssignedOnCell ? params.IsDeviceAssignedOnCell : false;

    delete params.usuario; 
    delete params.password; 
    delete params.nomenu; 
    const aditionalParams = queryString.stringify(params); 
    redirectURL =  props.location.pathname ? `${props.location.pathname  }?${  aditionalParams}` : '';

    setSessionData({
      url: redirectURL,
    });

    // si recibimos usuario y password entonces hacemos clear de la session para que inicie todo de nuevo
    if (user !== '' && pass !== ''){
      // clear 
      logOut();
    } 
    
    this.onChangeUsuario = this.onChangeUsuario.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onStartAutoLogin = this.onStartAutoLogin.bind(this);
    this.onLoginClick = this.onLoginClick.bind(this);
    this.submitButton = React.createRef(); 
  }

  handleSubmit(e) {
    e.preventDefault();
    this.onLoginClick();
  }

  onLoginClick() {
    let urlWebService = `${config.UrlLoginServer}/authenticate`;

    const data = {
      username: this.state.usuario,
      password: this.state.password,
    };

    if (data.username === '' || data.password === '') {
      showSweetAlert('Warning', 'User and password are required.', 'warning');
      return;
    }

    // When the user click log in button we get the current url, if the current url contains layout word we try to redirect to that page
    // automatically if not, we redirect to standard Home page
    let currentURL = window.location.href; 
    currentURL = currentURL.replace(`${window.location.protocol  }//${  window.location.host}`, '');
     
    if (currentURL.indexOf('layout') !== -1){
      setSessionData({  
        url: currentURL 
      });
    }
    else{
      setSessionData({  
        url: null
      });
    }
    


    callApi(urlWebService, 'POST', data, (res) => {
      if (!res.token) {
        showSweetAlert('Error', res.mensaje, 'error');
      } else {
        setSessionData({
          NomUsuario: res.nombreUsuario,
          Token: res.token,
          NumUsuario: data.username 
        });


        // ejecutamos el api de seguridad para obtener el usuario conectado y guardarlo en sesion 
        urlWebService = `${config.UrlApiProject}Seguridad/GetClientInfo`;
        
        callApi(urlWebService, 'GET', {}, (res2) => { 
            if (document.getElementById("onesignal-bell-container") !== null){
              document.getElementById("onesignal-bell-container").style.display = "block";
            }
            
            setSessionData({
              NomUsuario: res.nombreUsuario,
              Token: res.token,
              NumUsuario: data.username,
              ClaUsuarioMod: res2.data.ClaUsuario
            });
          }
       ); 

        this.setState({
          numUsuario: data.username,
        });
      }
    });
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.onLoginClick();
    }
  }


  onStartAutoLogin() {
    let urlWebService = `${config.UrlLoginServer}/authenticate`; 
 

    const data = {
      username: user,
      password: pass,
    };

    if (user === '' || pass === '') {
      showSweetAlert('Warning', 'User and password are required.', 'warning');
      return;
    }
    
    // limpiamos la informacion

    
    callApi(urlWebService, 'POST', data, (res) => {

      
      if (!res.token) {
        showSweetAlert('Error', res.mensaje, 'error');
      } else {
        setSessionData({
          NomUsuario: res.nombreUsuario,
          Token: res.token,
          NumUsuario: data.username,
        });

        // ejecutamos el api de seguridad para obtener el usuario conectado y guardarlo en sesion 
        urlWebService = `${config.UrlApiProject}Seguridad/GetClientInfo`;
        
        callApi(urlWebService, 'GET', {}, (res2) => { 
            setSessionData({
              NomUsuario: res.nombreUsuario,
              Token: res.token,
              NumUsuario: data.username,
              ClaUsuarioMod: res2.data.ClaUsuario
            });
          }
       ); 

       console.log('redirectURL url', redirectURL)      
        setSessionData({
          url: redirectURL,
        });


        this.setState({
          numUsuario: data.username,
        });
      }

      user = '';
      pass = ''; 
      redirectURL = '';
      

    });
  }


  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  onChangeUsuario(e) {
    this.setState({
      usuario: e.target.value,
    });
  }
 

  componentDidMount() { 
  }

  componentWillUnmount() { 
  }

  render() {  
    // si detectamos que el device esta configurado para ser tableta de
    // asignada a la celda le quitamos el menu siempre 
    if (getSessionItem('IsDeviceAssignedOnCell', false) || IsDeviceAssignedOnCell){
      noMenu = '1';
      setSessionData({IsDeviceAssignedOnCell: true}); 
      // si detectamos que se quedo sin sesion por alguna razon, lo redireccionamos a la pantalla del home....
      if (!sessionAlive()){ 
        user = 'GUEST10169';
        pass = 'password'; 
        console.log('window', window);
        redirectURL = getSessionItem('url', 'CellHomeMenuScreen?IsDeviceAssignedOnCell=true'); 
      }
    }

    // cuando no esta logeado quitamos la campanita de one signal
    if (!sessionAlive()){
      if (document.getElementById("onesignal-bell-container") !== null){
        document.getElementById("onesignal-bell-container").style.display = "none";
      }
    }
    
    /* si recibimos la informacion de usuario y password intentamos logear en automatico y 
       redireccionamos al URL solicitada */
    if (user !== '' && pass !== '' && !sessionAlive()){  
      this.onStartAutoLogin();
    }
    else{
     
    let url = getSessionItem('url', redirectURL);   

    // verificamos is no esta intentando entrar a una ruta externa...  
    if (url.includes("/r/") === true) {
      return (
        <Router history={this.props.history}>
          <Route path="/" render={(props) => <ExternalPages {...props} />} />
          <Redirect from="/" to={url} />
        </Router>
      );
    }

    // External no menu
    if (url.includes("/s/") === true) {
      return (
        <Router history={this.props.history}>
          <Route path="/" render={(props) => <ExternalPagesNoMenu {...props} />} />
          <Redirect from="/" to={url} />
        </Router>
      );
    }
    
    if (!getSessionItem('IsDeviceAssignedOnCell', false)){ 
      url = getSessionItem('url', '/layout/HomeHR');
    }
    else{ 
      url = getSessionItem('url', '/layout/CellHomeMenuScreen?IsDeviceAssignedOnCell=true');
    }

    // verificamos si la sesion esta activa
    if (sessionAlive()) {

      if (noMenu === '1'){
        // document.body.classList.remove('bg-default');
        // Primer componente al que se va a redirigir después de iniciar sesión
        return (
          <Router history={this.props.history}>
            <Route path="/" render={(props) => <NoMenu {...props} />} />
            <Redirect from="/" to={url} />
          </Router>
        );
      }
        // document.body.classList.remove('bg-default');
        // Primer componente al que se va a redirigir después de iniciar sesión
        return (
          <Router history={this.props.history}>
            <Route path="/" render={(props) => <AdminLayout {...props} />} />
            <Redirect from="/" to={url} />
          </Router>
        );
      
    }


    logOut();
 
    }
    // document.body.classList.add('bg-default');

    return (
      <>
        <div className="login-bg"></div>
        <AuthHeader />
        <Container className="mt--7 pb-5">
          <Row className="justify-content-center">
            <Col lg="6" md="7">
              <Card className="card-login bg-secondary border-0 mb-0 bg-color">                
                <CardBody className="card-login px-lg-5 py-lg-5 bg-color">
                  <div className="login-logo">
                    <img className="login-img" src={logo} alt="deaceroLogo"  />
                  </div>
                  <Form role="form">
                    <FormGroup
                      className={classnames('mb-3', {
                        focused: this.state.focusedEmail,
                      })}
                    >
                      <div className="mb-2 login-welcome">
                        <span className="kar-label mb-1 login-welcome-text">Welcome</span>
                      </div>
                      <div className="mb-2">
                        {/* <span className="kar-label">User</span> */}
                      </div>
                      
                      <InputGroup className="input-group-merge input-group-alternative login-form">
                        {/* <InputGroupAddon addonType="prepend">
                          <InputGroupText className="kar-input-login">
                            <i className="far fa-user kar-icon-color" />
                          </InputGroupText>
                        </InputGroupAddon> */}
                        <Input
                          type="text"
                          className="kar-input-login login-input"
                          onChange={this.onChangeUsuario}
                          placeholder="Username"
                          onKeyDown={this._handleKeyDown}
                          value={this.state.usuario}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: this.state.focusedPassword,
                      })}
                    >
                      {/* <div className="mb-2">
                        <span className="kar-label mb-1">Password</span>
                      </div> */}
                      <InputGroup className="input-group-merge input-group-alternative">
                        {/* <InputGroupAddon addonType="prepend">
                          <InputGroupText className="kar-input-login">
                            <i className="fas fa-unlock-alt kar-icon-color" />
                          </InputGroupText>
                        </InputGroupAddon> */}
                        <Input
                          type="password"
                          className="kar-input-login login-input"
                          onChange={this.onChangePassword}
                          style={{ marginTop: '5%' }}
                          placeholder="Password"
                          onKeyDown={this._handleKeyDown}
                          value={this.state.password}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      <Button
                        onClick={this.handleSubmit}
                        className="kar-btn-login login-button"
                        type="button"
                        style={{ width: '30%' }}
                        ref={this.submitButton}
                      >
                        Log in
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card> 
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Login;

/* eslint-disable prefer-destructuring */

import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardHeader, CardBody, CardFooter, Label } from 'reactstrap';
import { callApi, showSweetAlert } from "utils/utils";
import { config } from 'utils/config';
import FieldDataView from 'components/Controls/FieldDataView/FieldDataView';
import { DataViewLayoutOptions } from 'primereact/dataview';
import FieldDropdown from 'components/Controls/FieldDropdown';
import ToolPartsMappingFilters from './ToolsPartsMappingFilters';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import { Dialog } from 'primereact/dialog';
import FieldAutoComplete from 'components/Controls/FieldAutoComplete';
import YupValidator from 'utils/YupValidator';
import * as yup from 'yup';
import swal from '@sweetalert/with-react';
import 'views/Operations/ToolRoom/ToolsParts.scss';

const ToolsPartsMapping = (props) => {
  const {t, i18n} = useTranslation(['operations', 'common']); // Translation

  const [relation, setRelation] = useState([]); // Tool parts relation
  const [filters, setFilters] = useState([]); // Filters
  const [showEdit, setShowEdit] = useState(false); // Show edit dialog
  const [toolArticulos, setToolArticulos] = useState([]); // Tool articulos for autocomplete
  const [partArticulos, setPartArticulos] = useState([]); // Part articulos for autocomplete
  const [partArticulo, setPartArticulo] = useState([]); // Selected part articulo
  const [toolArticulo, setToolArticulo] = useState([]); // Selected tool articulo
  const [editRow, setEditRow] = useState([]); // Current selected row
  const [errors, setErrors] = useState([]); // Errors for fields
  const [layout, setLayout] = useState('list'); // DataView layout
  const [sortKey, setSortKey] = useState('ClaveArticuloPart') // DataView sort key
  const [sortOrder, setSortOrder] = useState(1) // DataView sort Order
  const [sortField, setSortField] = useState('ClaveArticuloPart') // Sort Field
  const [showAddToolPart, setShowAddToolPart] = useState(false); //  Show add tool part dialog

  // Fetch tool parts relation when filters change
  useEffect(() => {
    GetToolPartsRelation();
  }, [filters])

  // On filters change to pass to filter component
  const onFiltersChange = (newFilters) => {
    setFilters(newFilters);
  }

  // Get the tool parts relation
  const GetToolPartsRelation = () => {
    setRelation([]);
    if (filters.articulo !== null && filters.articulo !== undefined)
    {
      const urlWebService = `${config.UrlApiProject}Operations/GetToolPartsRelation?ClaArticulo=${filters.articulo.ClaArticulo3}`; 
      const paramsService = null;
  
      callApi(urlWebService, 'GET', paramsService, (response) => {
        setRelation(response.data.Relation);
      });
    }
  }

  // Tool articulo filter for autocomplete
  const filterToolArticulo = (e) => {
    let valor = '';
    if (toolArticulo !== null && toolArticulo.NomArticulo3 !== null && toolArticulo.NomArticulo3 !== '' && e.query === '') {
      valor = toolArticulo.NomArticulo3;
    }
    else {
      valor = e.query;
    }

    const paramsService = {};
    const urlWebService = `${config.UrlApiProject}Operations/GetArticulo3ToolParts?Valor=${valor}`;
    callApi(urlWebService, 'GET', paramsService, (response) => {
      setToolArticulos(response.data.Articulos);
    });
  }

  // Tool articulo autocomplete change
  const onToolArticuloChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.toolArticulo;
    setErrors(newErrors);

    let articulo = '';

    if (e.value !== null) {
      articulo = e.value;
    }

    setToolArticulo(articulo);
  }

  // Part Articulo autocomplete filter
  const filterPartArticulo = (e) => {
    let valor = '';
    if (partArticulo !== null && partArticulo.NomArticulo3 !== null && partArticulo.NomArticulo3 !== '' &&  e.query === '') {
      valor = partArticulo.NomArticulo3;
    }
    else {
      valor = e.query;
    }

    const paramsService = {};
    const urlWebService = `${config.UrlApiProject}Operations/GetArticulo3ToolParts?Valor=${valor}`;
    callApi(urlWebService, 'GET', paramsService, (response) => {
      setPartArticulos(response.data.Articulos);
    });
  }

  // Part Articulo autocomplete change
  const onPartArticuloChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.partArticulo;
    setErrors(newErrors);

    let articulo = '';

    if (e.value !== null) {
      articulo = e.value;
    }

    setPartArticulo(articulo);
  }

  // Edit button click
  const onEditClick = (row) => {
    setToolArticulo(
      {
        ClaArticulo3: row.ClaArticuloTool,
        NomArticulo3: `${row.ClaveArticuloTool} - ${row.NomArticuloTool}`
      }
    );
    setPartArticulo(
      {
        ClaArticulo3: row.ClaArticuloPart,
        NomArticulo3: `${row.ClaveArticuloPart} - ${row.NomArticuloPart}`
      }
    );
    setEditRow(row);
    setShowEdit(true);
  }

  // Edit dialog hide
  const onEditHide = () => {
    setPartArticulo([]);
    setToolArticulo([]);
    setEditRow([]);

    const newErrors = {...errors};
    delete newErrors.partArticulo;
    setErrors(newErrors);

    setShowEdit(false);
  }

  // Delete button click
  const onDeleteClick = (row) => {
    const paramsService = {
      ClaArticuloTool: row.ClaArticuloTool,
      ClaArticuloPart: row.ClaArticuloPart,
    };

    const urlWebService = `${config.UrlApiProject}Operations/DeleteToolPart`;

    swal("Are you sure you want to delete this entry?", {
      buttons: {
        cancel: {
          text: "Cancel",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        delete: {
          text: "Delete",
          value: "delete",
          visible: true,
          className: "danger",
          closeModal: true
        }
      },
      content: (
        <>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} className='mb-2'>
              {`Tool: ${row.ClaveArticuloTool}`}
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              {`Part: ${row.ClaveArticuloPart}`}
            </Col>
          </Row>
        </>
      )
    })
    .then((value) => {
      switch (value) { 
        case "delete":
          callApi(urlWebService, 'POST', paramsService, (response) => {
            showSweetAlert("Success", t('common:Success'), 'success');
            GetToolPartsRelation();
          });
          break;
        default:
      }
    });


    // swal({
    //   text: "Are you sure you want to delete this entry?",
    //   buttons: {
    //     cancel: {
    //       text: t('common:Cancel'),
    //       value: "cancel",
    //     },
    //     confirm: {
    //       text: t('common:Delete'),
    //       value: "confirm",
    //     }
    //   }
    // }).then((value) => {
    //     if (value === 'confirm')
    //     {
    //       const paramsService = {
    //         ClaArticuloTool: row.ClaArticuloTool,
    //         ClaArticuloPart: row.ClaArticuloPart,
    //       };
      
    //       const urlWebService = `${config.UrlApiProject}Operations/DeleteToolPart`;
    //       callApi(urlWebService, 'POST', paramsService, (response) => {
    //         showSweetAlert("Success", t('common:Success'), 'success');
    //         GetToolPartsRelation();
    //       });
    //     }
    // });


  }

  // Add New button click
  const onAddNewClick = () => {
    setPartArticulo('');
    setToolArticulo('');
    setShowAddToolPart(true);
  }

  // Add Tool Part dialog hide
  const onAddToolPartHide = () => {
    setPartArticulo([]);
    setToolArticulo([]);

    const newErrors = {...errors};
    delete newErrors.partArticulo;
    delete newErrors.toolArticulo;
    setErrors(newErrors);

    setShowAddToolPart(false);

  }

  // Edit dialog save button click
  const onSaveEditClick = async () => {
    const value = {
      partArticulo
    }

    const validationScheme = yup.object().shape({
      partArticulo: yup.object({ ClaArticulo3: yup.string().required(t('operations:InvalidPart')) }).required(t('operations:InvalidPart')).nullable()
    });

    const validator = new YupValidator(validationScheme);
    const isValid = await validator.validate(value);
    if (!isValid) {
      setErrors(validator.errors);
    }
    else {
      const paramsService = {
        ClaArticuloToolPrev: editRow.ClaArticuloTool,
        ClaArticuloPartPrev: editRow.ClaArticuloPart,
        ClaArticuloPartNew: partArticulo.ClaArticulo3
      };
      
      const urlWebService = `${config.UrlApiProject}Operations/UpdateToolPartEntry`;
      callApi(urlWebService, 'POST', paramsService, (response) => {
        showSweetAlert("Success", t('common:Success'), 'success');
        onEditHide();
        GetToolPartsRelation();
      });
    }
  }

  // Save add tool part button click
  const onSaveAddToolPartClick = async (andNew = false) => {
    const value = {
      toolArticulo,
      partArticulo
    }

    const validationScheme = yup.object().shape({
      toolArticulo: yup.object({ ClaArticulo3: yup.string().required(t('operations:InvalidTool')) }).required(t('operations:InvalidTool')).nullable(),
      partArticulo: yup.object({ ClaArticulo3: yup.string().required(t('operations:InvalidPart')) }).required(t('operations:InvalidPart')).nullable()
    });

    const validator = new YupValidator(validationScheme);
    const isValid = await validator.validate(value);
    if (!isValid) {
      setErrors(validator.errors);
    }
    else {
      const paramsService = {
        ClaArticuloTool: toolArticulo.ClaArticulo3,
        ClaArticuloPart: partArticulo.ClaArticulo3
      };

      const urlWebService = `${config.UrlApiProject}Operations/InsertToolPart`;
      callApi(urlWebService, 'POST', paramsService, (response) => {
        showSweetAlert("Success", t('common:Success'), 'success');
        GetToolPartsRelation();
        setPartArticulo('');

        if (!andNew) {
          onAddToolPartHide();
        }
      });
    }
  }

  // Edit Dialog Footer
  const editFooterTemplate = () => {
    return (
      <>
        <FieldButton label={t('common:Save')} icon='pi pi-save' className='p-button-success' onClick={onSaveEditClick} />
      </>
    )
  }

  // List Item
  const renderListItem = (row) => {
    return (
      <>
        <Row className='p-grid p-fluid mt-2'>
          <Col xs={12} sm={12} md={6} lg={6}>
            <h2>Tool</h2>
            <h3>{row.ClaveArticuloTool}</h3>
            <h3>{row.NomArticuloTool}</h3>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <h2>Part</h2>
            <h3>{row.ClaveArticuloPart}</h3>
            <h3>{row.NomArticuloPart}</h3>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} className='p-d-flex p-jc-end mb-2'>
            <FieldButton icon='pi pi-pencil' className='p-button-rounded mr-2' onClick={() => onEditClick(row)} />
            <FieldButton icon='pi pi-trash' className='p-button-rounded p-button-danger' onClick={() => onDeleteClick(row)} />
          </Col>
        </Row>
      </>
    )
  }

  // Grid Item
  const renderGridItem = (row) => {
    return (
      <Col xs={12} sm={6} md={6} lg={4} xl={3} className='mb-4'>
        <Card>
          <CardHeader>
            <span style={{fontWeight:'bold'}}>{row.ClaveArticuloTool}</span>
          </CardHeader>
          <CardBody>
            <Row className='p-grid p-fluid mt-2'>
              <Col xs={12} sm={12} md={12} lg={12}>
                <h2>Part</h2>
                <h3>{row.ClaveArticuloPart}</h3>
                <h3>{row.NomArticuloPart}</h3>
              </Col>
            </Row>
          </CardBody>
          <CardFooter className='p-d-flex p-jc-end'>
            <FieldButton icon='pi pi-pencil' className='p-button-rounded mr-2' onClick={() => onEditClick(row)} />
            <FieldButton icon='pi pi-trash' className='p-button-rounded p-button-danger' onClick={() => onDeleteClick(row)} />
          </CardFooter>
        </Card>
      </Col>
    )
  }

  // Sort Options for DataView
  const sortOptions = [
    {label: t('operations:LabelPartNumAsc'), value: 'ClaveArticuloPart'},
    {label: t('operations:LabelPartNumDesc'), value: '!ClaveArticuloPart'},
    {label: t('operations:LabelPartDescAsc'), value: 'NomArticuloPart'},
    {label: t('operations:LabelPartDescDesc'), value: '!NomArticuloPart'},
    {label: t('operations:LabelToolNumAsc'), value: 'ClaveArticuloTool'},
    {label: t('operations:LabelToolNumAsc'), value: '!ClaveArticuloTool'}
  ]

  // DataView Header
  const dataViewHeader = () => {
    return (
      <Row className='grid'>
        <Col xs={12} sm={12} md={7} lg={5} className='p-fluid'>
          <Label>{t('it:SortBy')}</Label>
          <FieldDropdown className='mb-1' options={sortOptions} value={sortKey} placeHolder="Sort by" onChange={onSortChange} showClear={false} />
        </Col>
        <Col xs={12} sm={12} md={5} lg={7} className='p-d-flex p-jc-end p-ai-center'>
          <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />
          <FieldButton icon='pi pi-plus' label='Add New' className='p-button-success ml-2' onClick={onAddNewClick} />
        </Col>
      </Row>
    )
  }

  // Handle DataView sort change
  const onSortChange = (event) => {
    const value = event.value;

    if (value.indexOf('!') === 0) {
        setSortOrder(-1);
        setSortField(value.substring(1, value.length));
        setSortKey(value);
    }
    else {
        setSortOrder(1);
        setSortField(value);
        setSortKey(value);
    }
  }

  // DataView item template
  const dataViewItemTemplate = (row, layout) => {
    if (layout === 'list') {
      return renderListItem(row);
    }

    if  (layout === 'grid') {
      return renderGridItem(row);
    }
  }

  // Add tool part dialog footer
  const addToolPartFooter = () => {
    return (
      <>
        <Row className=''>
          <Col>
            <FieldButton icon='pi pi-save' label={t('common:Save')} className='p-button-success' onClick={() => onSaveAddToolPartClick(false)} />
            <FieldButton icon='pi pi-plus' label={t('common:SaveNew')} className='p-button-secondary' onClick={() => onSaveAddToolPartClick(true)} />
          </Col>
        </Row>
      </>
    )
  }

  return (
    <>
      <ToolPartsMappingFilters onFiltersChange={onFiltersChange} />
      <Card className='mt-4 p-d-flex rounded-bottom'>
        <CardHeader className='tools-parts-card-white'>
          {dataViewHeader()}
        </CardHeader>
        <CardBody className='p-fluid'>
          <FieldDataView
            value={relation}
            layout={layout}
            itemTemplate={dataViewItemTemplate}
            className='tools-parts-dataview'
            sortOrder={sortOrder}
            sortField={sortField}
            paginator
          />
        </CardBody>
      </Card>
      <Dialog
        header={t('common:Edit')}
        footer={editFooterTemplate}
        visible={showEdit}
        onHide={onEditHide}
        breakpoints={{'1000px': '100vw'}}
        style={{width: '75vw'}}
        modal
        className='p-grid'
      >
        <Row className='p-grid p-fluid mt-1'>
          <Col xs={12} sm={12} md={12} lg={12} className='p-field'>
            <Label>{t('operations:Tool')}</Label>
            <h3>{`${toolArticulo.NomArticulo3}`}</h3>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} className='p-field'>
            <Label>{t('operations:Part')}</Label>
            <FieldAutoComplete
              dropdown
              name='partArticulo'
              value={partArticulo}
              onChange={onPartArticuloChange}
              suggestions={partArticulos}
              field='NomArticulo3'
              completeMethod={filterPartArticulo}
              errors={errors}
            />
          </Col>
        </Row>
      </Dialog>
      <Dialog
        header={t('operations:AddNew')}
        footer={addToolPartFooter}
        visible={showAddToolPart}
        onHide={onAddToolPartHide}
        breakpoints={{'1000px': '100vw'}}
        style={{width: '75vw'}}
        modal
        className='p-grid'
      >
        <Row className='p-fluid'>
          <Col xs={12} sm={12} md={6} lg={6} className='p-field'>
            <Label>{t('operations:Tool')}</Label>
            <FieldAutoComplete
              dropdown
              name='toolArticulo'
              value={toolArticulo}
              onChange={onToolArticuloChange}
              suggestions={toolArticulos}
              field='NomArticulo3'
              completeMethod={filterToolArticulo}
              errors={errors}
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className='p-field'>
            <Label>{t('operations:Part')}</Label>
            <FieldAutoComplete
              dropdown
              name='partArticulo'
              value={partArticulo}
              onChange={onPartArticuloChange}
              suggestions={partArticulos}
              field='NomArticulo3'
              completeMethod={filterPartArticulo}
              errors={errors}
            />
          </Col>
        </Row>
      </Dialog>
    </>
  )
};

export default ToolsPartsMapping;
import { React, useState, useEffect, useRef } from 'react';
import Viz from 'components/Tableau/Viz';
import { useTranslation } from 'react-i18next';
import { callApi, getSessionItem } from 'utils/utils';
import { config } from 'utils/config';

const WeeklySalesDash = (props) => {

  const [viz, setViz] = useState(null);

  useEffect(() => {
    createViz();
  }, [])

  const createViz = () =>  {
    const paramsService = {};
    const urlWebService = `${config.UrlApiProject}Tableau/GetSalesPerformanceDashboardAgente?ClaUsuario=${getSessionItem('ClaUsuarioMod', 0)}`;

    callApi(urlWebService, 'GET', paramsService, (response) => {
      // Tableau Dashboard parameters
      const params = {};

      const url = 'https://tableau.deacero.com/#/views/CPIInventoryFollowUp/WkFollowUpGM?:iid=1'

      setViz(<Viz url={url} parameters={params}></Viz>);
    });
  }

  return (
    <>
      <div>{viz}</div>
    </>
  )
}

export default WeeklySalesDash;
import PageContent from 'layouts/PageContent';
import { React, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ITAssetsFilters from './ITAssetsFilters';

import ITAssets from './ITAssets';

const ITAssetsScreen = (props) => {
  const {t, i18n} = useTranslation(['it', 'common']);
  const [filters, setFilters] = useState({})

  return (
    <>
      <PageContent title={t('it:ITAssets')}>
        <ITAssetsFilters setFilters={setFilters} />
        <div className='mt-2'>
          <ITAssets filters={filters} />
        </div>
      </PageContent>
    </>
  )
}

export default ITAssetsScreen;
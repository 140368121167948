import PageContent from 'layouts/PageContent';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ToolsPartsMapping from './ToolsPartsMapping';

const ToolsPartsMappingScreen = (props) => {
  const {t, i18n} = useTranslation(['operations', 'common']); // Translation

  return (
    <>
      <PageContent title={t('operations:ToolsPartsMapping')}>
        <ToolsPartsMapping />
      </PageContent>
    </>
  )
};

export default ToolsPartsMappingScreen;
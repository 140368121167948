/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-const-assign */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { config } from 'utils/config';
import { callApi, showSweetAlert, getSessionItem, showNotify, tracker } from 'utils/utils';  
import moment from 'moment';  
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  ListGroupItem,
  ListGroup,
  Row,
  Col,
  FormGroup,
  Label,
} from 'reactstrap'; 
import { useHistory, Link } from "react-router-dom";
import PageContent from 'layouts/PageContent'; 
import './CellHomeMenuScreen.scss';
import CheckIn from '../../../../assets/img/checkin.png';
import CheckOut from '../../../../assets/img/checkout.png';
import Downtime from '../../../../assets/img/alert.png';
import Break from '../../../../assets/img/break.png';
import ChangeOver from '../../../../assets/img/changeover.png';
import Lunch from '../../../../assets/img/lunch.png';
import MaterialRequest from '../../../../assets/img/material.png';
import Scrap from '../../../../assets/img/scrap.png';
import call_forklift from '../../../../assets/img/call_forklift.png';
import awaiting_forklift from '../../../../assets/img/awaiting_forklift.png';
import CheckList from '../../../../assets/img/checklist.png';
import HeaderTablet from '../Common/Header/HeaderTablet.jsx';
import PrincipalHeader from '../Common/Header/PrincipalHeader.jsx';
import ResponderFormulario from '../../../Formulario/ResponderFormulario.js';
import Gantry from '../../../../assets/img/gantry.png';
import { Dialog } from 'primereact/dialog';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import FieldSelectButton from 'components/Controls/FieldSelectButton'; 
import * as yup from 'yup';
import YupValidator from 'utils/YupValidator';

const CellHomeMenuScreen = (props) => {
  const { t, i18n } = useTranslation(['tablets', 'common']); 
  const [EsCeldaConfigurada, setEsCeldaConfigurada] = useState(false);
  const [EsCheckListPendiente, setEsCheckListPendiente] = useState(false);
  const [EsEnBreakOrLunch, setEsEnBreakOrLunch] = useState(false);
  const [EsCheckInPendiente, setEsCheckInPendiente] = useState(false);
  const [EsMostrarCheckOutAlerta, setEsMostrarCheckOutAlerta] = useState(false);
  const [EsWaitingForkLift, setEsWaitingForkLift] = useState(false);
  const [ClaAreaProduccion, setClaAreaProduccion] = useState(null);
  const [ClaTurno, setClaTurno] = useState(null); 
  const [ClaCelda, setClaCelda] = useState(null); 
  const [ClaMaquina, setClaMaquina] = useState(null); 
  const [EsCeldaControlaGantry, setEsCeldaControlaGantry] = useState(false);
  const [EsGantryActivo, setEsGantryActivo] = useState(true);
  const [EsMostrarDetenerGantry, setEsMostrarDetenerGantry] = useState(false);
  const [TipoAffectacion, setTipoAffectacion] =  useState(null);
  const [CeldaList, setCeldaList] = useState([]);
  const [ClaCeldas, setClaCeldas] =  useState([]);
  const [errors, setErrors] = useState([]);
  
  const [NomAreaProduccion, setNomAreaProduccion] = useState(null);
  const [NomTurno, setNomTurno] = useState(null); 
  const [NomCelda, setNomCelda] = useState(null); 
  const [ClaveCelda, setClaveCelda] = useState(null);
  const [ActiveDowntimes, setActiveDowntimes] = useState([]);

  const dailyOperationCheckListScreenLink = useRef();
  const solicitudMaterialScreenLink = useRef();
  const downtimeScreenLink = useRef();
  const checkInScreenLink = useRef();
  const scrapScreenLink = useRef();
  const checkOutScreenLink = useRef();
  const changeOverScreenLink = useRef();
  const downtimeScreenMachineLink = useRef(); 
  const dashboardUtilizationLink = useRef(); 
  const dashboardScrapLink = useRef();
  let IsDeviceAssignedOnCell = false;
  const refreshTimer = useRef();

  if(props.location!==undefined){
    const params = queryString.parse(props.location.search);
    IsDeviceAssignedOnCell = params !== undefined ? params.IsDeviceAssignedOnCell : null;
  }

  const validationSchemeTurnOffGantry = yup.object().shape({
    TipoAffectacion: yup.mixed().required(t('common:RequiredField')).nullable(),
    /* ClaCeldas: yup
              .array()
              .min(1, t('common:RequiredField'))
              .required(t('common:RequiredField'))
              .nullable() */
    ClaCeldas: yup
              .array()
              .nullable()
              .when('TipoAffectacion', {
                is: 2,
                then: yup.array().min(1, t('common:RequiredField')).required(t('common:RequiredField')),
                otherwise: yup.array().nullable(),
              })
  });

  useEffect(() => { 
    InicializaOpciones();
    GetCeldaCollated();
    refreshTimer.current = setInterval(() => { 
      InicializaOpciones();
    }, 1500 * 60);
    
    return () => {
      if (refreshTimer.current) {
        clearTimeout(refreshTimer.current);  
      }  
    }
  }, []);
  
  const InicializaOpciones = () => { 

    if (getSessionItem('ClaCelda', null) !== null){ 
      // mandamos llamar el servicio que nos ayuda a inicializar el home
      // verificamos si la celda ya realizo check list, si ya lo hizo sigue normal...
      const GetCellHomeScreenInitialValues = `${config.UrlApiProject}Operations/GetCellHomeScreenInitialValues?ClaCelda=${getSessionItem('ClaCelda', null)}`; 
    
      callApi(GetCellHomeScreenInitialValues, 'GET', {}, (response) => { 
        const info = response.data.CellHomeScreenInitialValues.length > 0 ? response.data.CellHomeScreenInitialValues[0] : {}
        
        if (response.data.CellHomeScreenInitialValues.length > 0){ 
          setEsCheckListPendiente(info.EsCheckListPendiente);
          setEsEnBreakOrLunch(info.EsEnBreakOrLunch); 
          setEsCheckInPendiente(info.EsCheckInPendiente);
          setEsMostrarCheckOutAlerta(info.EsMostrarCheckOutAlerta);
          setClaAreaProduccion(info.ClaAreaProduccion);
          setClaTurno(info.ClaTurno)
          setEsCeldaConfigurada(true);
          setClaCelda(info.ClaCelda);
          setEsWaitingForkLift(info.EsWaitingForkLift);
          setNomAreaProduccion(info.NomAreaProduccion);
          setNomTurno(info.NomTurno);
          setNomCelda(info.NomCelda);
          setClaveCelda(info.ClaveCelda);
          setEsCeldaControlaGantry(info.EsCeldaControlaGantry);
          setEsGantryActivo(info.EsGantryActivo); 
        }

        setActiveDowntimes(response.data.ActiveDowntimesInformation);
      },null, false, false );
 
     
    }else{
      setEsCeldaConfigurada(false);
    } 
   
  }

  const GetCeldaCollated = (pClaAreaDeartamento) => {
    const urlWebService = `${config.UrlApiProject}celda/GetCeldas?ClaDepartamento=2`;
    const paramsService = null;

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setCeldaList(response.data.Celdas);
    });
  };

  const onStartCheckList = () => {
    dailyOperationCheckListScreenLink.current.click();

    tracker.trackEvent({
      type: 'CellHomeMenuScreen', /* component name */
      action: 'BEGIN_CHECK_LIST'
    }) 
  }

  const onSolicitudMaterialClick = () => {
    solicitudMaterialScreenLink.current.click();

    tracker.trackEvent({
      type: 'CellHomeMenuScreen', /* component name */
      action: 'REQUEST_MATERIAL'
    }) 
  }

  const onDownTimeClick = (claMaquina) => {  
    tracker.trackEvent({
      type: 'CellHomeMenuScreen', /* component name */
      action: 'CREATE_DOWNTIME'
    }) 

    if (claMaquina !== null && typeof(claMaquina) !== 'undefined'){ 
      setClaMaquina(claMaquina);
      setTimeout(()=> {downtimeScreenMachineLink.current.click(); }, 10) 
    }else{
      downtimeScreenLink.current.click(); 
    }
  }
 
  const onCheckInClick = () => {
    checkInScreenLink.current.click();

    tracker.trackEvent({
      type: 'CellHomeMenuScreen', /* component name */
      action: 'CHECK_IN'
    }) 
  }

  const onChangeOverClick = () => {
    changeOverScreenLink.current.click();

    tracker.trackEvent({
      type: 'CellHomeMenuScreen', /* component name */
      action: 'CHANGE_OVER'
    }) 
  }  

  const onCheckOutClick = () => {
    checkOutScreenLink.current.click();
    tracker.trackEvent({
      type: 'CellHomeMenuScreen', /* component name */
      action: 'CHECK_OUT'
    }) 
  }

  const onScrapClick = () => {
    scrapScreenLink.current.click();
    tracker.trackEvent({
      type: 'CellHomeMenuScreen', /* component name */
      action: 'VIEW_SCRAP'
    }) 
  }

  const onProductionStatsClick = () => {
    dashboardUtilizationLink.current.click();
    tracker.trackEvent({
      type: 'CellHomeMenuScreen', /* component name */
      action: 'PRODUCTION_STATS'
    }) 
  }

  const onProductionStatsScrapClick = () => {
    dashboardScrapLink.current.click();
    tracker.trackEvent({
      type: 'CellHomeMenuScreen', /* component name */
      action: 'SCRAP_STATS'
    }) 
  }
  
  

  const onReturnWorkClick = () => {
    const ReturnToWork = `${config.UrlApiProject}Operations/ReturnToWork`; 
    const paramsService = {
      ClaDepartamento : ClaAreaProduccion, 
      ClaTurno,
      ClaCelda 
    };

    tracker.trackEvent({
      type: 'CellHomeMenuScreen', /* component name */
      action: 'RETURN_TO_WORK'
    }) 

    callApi(ReturnToWork, 'POST', paramsService, (response) => { 
      showNotify(t('common:SuccessOperation'), 'centerTop', 'success');
      InicializaOpciones();
    });
  }

  const onBreakTimeClick = () => {
    const ReturnToWork = `${config.UrlApiProject}Operations/BreakTime`; 
    const paramsService = {
      ClaDepartamento : ClaAreaProduccion, 
      ClaTurno,
      ClaCelda 
    };

    tracker.trackEvent({
      type: 'CellHomeMenuScreen', /* component name */
      action: 'START_BREAK_TIME'
    }) 

    callApi(ReturnToWork, 'POST', paramsService, (response) => { 
      showNotify(t('common:SuccessOperation'), 'centerTop', 'success');
      InicializaOpciones();
    });
  }

  const onLunchTimeClick = () => {
    const ReturnToWork = `${config.UrlApiProject}Operations/LunchTime`; 
    const paramsService = {
      ClaDepartamento : ClaAreaProduccion, 
      ClaTurno,
      ClaCelda 
    };

    tracker.trackEvent({
      type: 'CellHomeMenuScreen', /* component name */
      action: 'START_LUNCH_TIME'
    }) 

    callApi(ReturnToWork, 'POST', paramsService, (response) => { 
      showNotify(t('common:SuccessOperation'), 'centerTop', 'success');
      InicializaOpciones();
    });
  }

  const onCallForkliftClick = () => {
    const CallForklift = `${config.UrlApiProject}Operations/CallForklift`; 
    const paramsService = {
      ClaDepartamento : ClaAreaProduccion, 
      ClaTurno,
      ClaCelda 
    };

    tracker.trackEvent({
      type: 'CellHomeMenuScreen', /* component name */
      action: 'CALL_FORKLIFT'
    }) 

    callApi(CallForklift, 'POST', paramsService, (response) => { 
      showNotify(t('common:SuccessOperation'), 'centerTop', 'success');
      InicializaOpciones();
    });
  }

  const onClickGantry = () => {
    // 
    setEsMostrarDetenerGantry(true);
    setClaCeldas([]);
    setTipoAffectacion(null);
  }

  const onCompleteTurnOffGantry = async () => {
    //

    const value = {
      TipoAffectacion,
      ClaCeldas
    };

    const validator = new YupValidator(validationSchemeTurnOffGantry);
    const isValid = await validator.validate(value);
    if (!isValid) {
      setErrors(validator.errors); 
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      return;
    }

    // cuando esta afectando todo el gantry lo que hacemos es limpiar todas las celdas para enviar null

    const ActivateGantry = `${config.UrlApiProject}Operations/DeactivateGantry`; 
    const paramsService = {
      EsGantryActivo : 0, 
      ListadoCeldasAfectadas: TipoAffectacion === 1 ? null : ClaCeldas.join(", ") 
    };

    tracker.trackEvent({
      type: 'CellHomeMenuScreen', /* component name */
      action: 'DEACTIVATE_GANTRY'
    }) 

    callApi(ActivateGantry, 'POST', paramsService, (response) => { 
      showNotify(t('common:SuccessOperation'), 'centerTop', 'success');
      InicializaOpciones();
      setEsMostrarDetenerGantry(false);
    });

  }

  const onClickActivateGantry = () => {
    const CallForklift = `${config.UrlApiProject}Operations/ActivateGantry`; 
    const paramsService = { };

    tracker.trackEvent({
      type: 'CellHomeMenuScreen', /* component name */
      action: 'ACTIVATE_GANTRY'
    }) 

    callApi(CallForklift, 'POST', paramsService, (response) => { 
      showNotify(t('common:SuccessOperation'), 'centerTop', 'success');
      InicializaOpciones();
    });
  }

  const onAwaitingForkliftClick = () => {
    const AwaitingForklift = `${config.UrlApiProject}Operations/AwaitingForklift`; 
    const paramsService = {
      ClaDepartamento : ClaAreaProduccion, 
      ClaTurno,
      ClaCelda 
    };

    tracker.trackEvent({
      type: 'CellHomeMenuScreen', /* component name */
      action: 'AWAITING_FORKLIFT'
    }) 

    callApi(AwaitingForklift, 'POST', paramsService, (response) => { 
      showNotify(t('common:SuccessOperation'), 'centerTop', 'success');
      InicializaOpciones();
    });
  }

  const renderHeaderDetenerGantry = () => {
    return (
      <div>
        <Row>
          <Col lg={4} md={4} sm={12} xs={12}> 
            <span>{t('tablets:TurnOffGantry')}</span> 
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="text-center">
             
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="text-right">
             
          </Col>
        </Row>
      </div>
    );
  };

  const renderFooterDetenerGantry = () => {
    return (
      <div>
        <Row>
          <Col lg={4} md={4} sm={12} xs={12}>
             
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="text-center">
            <FieldButton
              label="Close" 
              className="p-button-secondary no-border p-mr-2 rounded-button btntablet"
              onClick={() => setEsMostrarDetenerGantry(false)}
            />
            &nbsp;
            <FieldButton
              label="Turn OFF Gantry" 
              className="p-button-danger no-border p-mr-2 rounded-button btntablet"
              onClick={onCompleteTurnOffGantry}
            />
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="text-right">
            
          </Col>
        </Row>
      </div>
    );
  };

  const EsSeleccionaTipoAffectacion = (value) => {

    const newErrors = { ...errors };
    delete newErrors.TipoAffectacion;
    setErrors(newErrors);  

    if (value === null){
      setTipoAffectacion(null);
      return;
    }

    setTipoAffectacion(value);
    
    if (value === 1) {
      // mandamos parar el gantry total...
    }
  };

  const onClaCeldaChange = (celdas) => {  
    // no se puede desmarcar el ultimo...
     

    const newErrors = { ...errors };
    delete newErrors.ClaCeldas;
    setErrors(newErrors);  

    setClaCeldas(celdas);   
  };

  
  const subHeader = (
    <HeaderTablet
      ClaTurno={ClaTurno}
      NomTurno={NomTurno}
      ClaAreaProduccion={ClaAreaProduccion}
      NomAreaProduccion={NomAreaProduccion}
      IdCelda={ClaCelda}
      NomCelda={NomCelda}
      ClaveCelda={ClaveCelda}
    >
    </HeaderTablet> 
  )
 

  if (!EsCeldaConfigurada){
    return (
      <>
        <PrincipalHeader onConfigurationChange={() => InicializaOpciones()}></PrincipalHeader>
        <PageContent title="Home">  
          {ActiveDowntimes.length >= 1 ? 
            (  
                ActiveDowntimes.map((element, index) => {
                  return ( 
                    <div className='Tablet-Field' onClick={() => onDownTimeClick(element.ClaMaquina)}> 
                      <Row>
                        <Col xs={8}>
                          Active Downtime on <b>{element.NomMaquina}</b>
                        </Col>
                        <Col xs={4} className="pb-3 text-right">
                          <i className='fas fa-circle danger-color fa-lg'></i>
                        </Col>
                      </Row>
                    </div> 
                  );
                }) 
          ) : (
            <></>
          )} 
          <Row>
            <Col className='pt-4 text-center'>
              <h1><b>Cell not configured on this device</b></h1>
              <br></br>
              <span className='no-cell-configured'>Please set the cell on this device by selecting the configuration button on the top right of the screen.</span> 
              <br></br>
              <br></br>
              <i className="fas fa-cogs fa-7x"></i>
            </Col>
          </Row>
          <Link 
            ref={downtimeScreenLink} 
            to={
                { pathname: "DownTime", search: `?isNewHomeVersion=true&IdCelda=${ClaCelda}&ClaAreaDepartamento=${ClaAreaProduccion}` }
              }
          >
          </Link>  
          <Link 
            ref={downtimeScreenMachineLink} 
            to={
                { pathname: "DownTime", search: `?isNewHomeVersion=true&IdCelda=${ClaCelda}&ClaAreaDepartamento=${ClaAreaProduccion}&ClaMaquina=${ClaMaquina}` }
              }
          >
          </Link> 
        </PageContent>
      </>
    )
  }

  if (EsCheckListPendiente){
    return (
      <>
        <PrincipalHeader onConfigurationChange={() => InicializaOpciones()}></PrincipalHeader>
        <PageContent title="Home" subheader={subHeader}>  
          {ActiveDowntimes.length >= 1 ? 
            (  
                ActiveDowntimes.map((element, index) => {
                  return ( 
                    <div className='Tablet-Field' onClick={() => onDownTimeClick(element.ClaMaquina)}> 
                      <Row>
                        <Col xs={8}>
                          Active Downtime on <b>{element.NomMaquina}</b>
                        </Col>
                        <Col xs={4} className="pb-3 text-right">
                          <i className='fas fa-circle danger-color fa-lg'></i>
                        </Col>
                      </Row>
                    </div> 
                  );
                }) 
          ) : (
            <></>
          )}         
          <Row>
            <Col className='pt-4'>
              <h1><b>Safety First</b></h1>
              <br></br>
              <span className='no-cell-configured'>Please tap the button below to complete the Daily Operations Safety Checklist.</span> 
              <br></br>
              <br></br>
              <div className="homemenu-tablet-button hommenu-square-button Cursor homemenu-break" onClick={onStartCheckList}>  
                <Row className='h-100'>
                  <Col className='text-center pt-2'>
                    <div> 
                      <img className='homemenu-tablet-buttonIcon-small' src={CheckList}></img>
                      <label className="homemenu-tablet-buttontitle">Start Check List</label> 
                    </div>
                  </Col> 
                </Row> 
              </div>  
              <Link 
                ref={dailyOperationCheckListScreenLink} 
                to={
                        { pathname: "DailyOperationCheckListScreen", search: `?isNewHomeVersion=true&IdCelda=${ClaCelda}&ClaAreaDepartamento=${ClaAreaProduccion}&ClaTurno=${ClaTurno}` }
                }
              >
              </Link>  
            </Col>
          </Row> 
          <Link 
            ref={downtimeScreenLink} 
            to={
                { pathname: "DownTime", search: `?isNewHomeVersion=true&IdCelda=${ClaCelda}&ClaAreaDepartamento=${ClaAreaProduccion}` }
              }
          >
          </Link>  
          <Link 
            ref={downtimeScreenMachineLink} 
            to={
                { pathname: "DownTime", search: `?isNewHomeVersion=true&IdCelda=${ClaCelda}&ClaAreaDepartamento=${ClaAreaProduccion}&ClaMaquina=${ClaMaquina}` }
              }
          >
          </Link>           
        </PageContent>
      </>
    )
  }

  if (EsCheckInPendiente) {
    return (
      <>
        <PrincipalHeader onConfigurationChange={() => InicializaOpciones()}></PrincipalHeader>
        <PageContent title="Home" subheader={subHeader}>                  
          {ActiveDowntimes.length >= 1 ? 
            (  
                ActiveDowntimes.map((element, index) => {
                  return ( 
                    <div className='Tablet-Field' onClick={() => onDownTimeClick(element.ClaMaquina)}> 
                      <Row>
                        <Col xs={8}>
                          Active Downtime on <b>{element.NomMaquina}</b>
                        </Col>
                        <Col xs={4} className="pb-3 text-right">
                          <i className='fas fa-circle danger-color fa-lg'></i>
                        </Col>
                      </Row>
                    </div> 
                  );
                }) 
          ) : (
            <></>
          )}   
          <Row>
            <Col className='pt-4'>
              <h1><b>Check In</b></h1>
              <br></br>
              <span className='no-cell-configured'>Please tap the button below to complete the Check In.</span> 
              <br></br>
              <br></br>
              <div className="homemenu-tablet-button hommenu-square-button Cursor homemenu-break" onClick={onCheckInClick}>  
                <Row className='h-100'>
                  <Col className='text-center pt-2'>
                    <div> 
                      <img className='homemenu-tablet-buttonIcon-small' src={CheckIn}></img>
                      <label className="homemenu-tablet-buttontitle">Start Check In</label> 
                    </div>
                  </Col> 
                </Row> 
              </div>  
              <Link 
                ref={checkInScreenLink} 
                to={
               { pathname: "CheckInScreen", search: `IdCelda=${ClaCelda}` }
            }
              >
              </Link>  
            </Col>
          </Row> 
          <Link 
            ref={downtimeScreenLink} 
            to={
                { pathname: "DownTime", search: `?isNewHomeVersion=true&IdCelda=${ClaCelda}&ClaAreaDepartamento=${ClaAreaProduccion}` }
              }
          >
          </Link>  
          <Link 
            ref={downtimeScreenMachineLink} 
            to={
                { pathname: "DownTime", search: `?isNewHomeVersion=true&IdCelda=${ClaCelda}&ClaAreaDepartamento=${ClaAreaProduccion}&ClaMaquina=${ClaMaquina}` }
              }
          >
          </Link> 
        </PageContent>
      </>
    )
  }

  if (EsEnBreakOrLunch) {
    return (
      <>
        <PrincipalHeader onConfigurationChange={() => InicializaOpciones()}></PrincipalHeader>
        <PageContent title="Home" subheader={subHeader}>  
          {ActiveDowntimes.length >= 1 ? 
            (  
                ActiveDowntimes.map((element, index) => {
                  return ( 
                    <div className='Tablet-Field' onClick={() => onDownTimeClick(element.ClaMaquina)}> 
                      <Row>
                        <Col xs={8}>
                          Active Downtime on <b>{element.NomMaquina}</b>
                        </Col>
                        <Col xs={4} className="pb-3 text-right">
                          <i className='fas fa-circle danger-color fa-lg'></i>
                        </Col>
                      </Row>
                    </div> 
                  );
                }) 
          ) : (
            <></>
          )} 
          <Row>
            <Col className='pt-4'>
              <h1><b>Welcome Back</b></h1>
              <br></br>
              <span className='no-cell-configured'>Please tap the button below to return to work.</span> 
              <br></br>
              <br></br>
              <div className="homemenu-tablet-button hommenu-square-button Cursor homemenu-return-work Cursor" onClick={onReturnWorkClick}>  
                <Row className='h-100'>
                  <Col className='text-center pt-4'>
                    <div> 
                      <i className='fas fa-history homemenu-tablet-buttontitle white-color'></i>&nbsp;
                      <label className="homemenu-tablet-buttontitle">Return to Work</label> 
                    </div>
                  </Col> 
                </Row> 
              </div>  
              <Link 
                ref={dailyOperationCheckListScreenLink} 
                to={
                        { pathname: "DailyOperationCheckListScreen", search: `?isNewHomeVersion=true&IdCelda=${ClaCelda}&ClaAreaDepartamento=${ClaAreaProduccion}&ClaTurno=${ClaTurno}` }
                }
              >
              </Link>  
            </Col>
          </Row> 
          <Link 
            ref={downtimeScreenLink} 
            to={
                { pathname: "DownTime", search: `?isNewHomeVersion=true&IdCelda=${ClaCelda}&ClaAreaDepartamento=${ClaAreaProduccion}` }
              }
          >
          </Link>  
          <Link 
            ref={downtimeScreenMachineLink} 
            to={
                { pathname: "DownTime", search: `?isNewHomeVersion=true&IdCelda=${ClaCelda}&ClaAreaDepartamento=${ClaAreaProduccion}&ClaMaquina=${ClaMaquina}` }
              }
          >
          </Link>           
        </PageContent>
      </>
    )
  }

  if (EsCeldaControlaGantry){
    return (
      <div className='tablet-screen'>
        <PrincipalHeader 
          onConfigurationChange={() => InicializaOpciones()}
        >
        </PrincipalHeader>
        <PageContent title="Home" subheader={subHeader}>   
          {ActiveDowntimes.length >= 1 ? 
            (  
                ActiveDowntimes.map((element, index) => {
                  return ( 
                    <div className='Tablet-Field' onClick={() => onDownTimeClick(element.ClaMaquina)}> 
                      <Row>
                        <Col xs={8}>
                          Active Downtime on <b>{element.NomMaquina}</b>
                        </Col>
                        <Col xs={4} className="pb-3 text-right">
                          <i className='fas fa-circle danger-color fa-lg'></i>
                        </Col>
                      </Row>
                    </div> 
                  );
                }) 
          ) : (
            <></>
          )} 
          <Row>
            <Col lg={4} md={4} sm={4} xs={4}> 
                   
            </Col>
            <Col lg={4} md={4} sm={4} xs={4}> 
              {
              EsGantryActivo ?
              ( 
                <div className="homemenu-tablet-button hommenu-square-button homemenu-gantry Cursor" onClick={onClickGantry}>  
                  <div className='text-center'>
                    <img className='homemenu-tablet-buttonIcon' src={Gantry}></img>
                  </div> 
                  <div className="text-center">
                    <label className="homemenu-tablet-buttontitle">Turn OFF<br></br>Gantry</label> 
                  </div>
                </div>  

              )
              :
              (
                <div className="homemenu-tablet-button hommenu-square-button homemenu-gantry-stopped Cursor" onClick={onClickActivateGantry}>  
                  <div className='text-center'>
                    <img className='homemenu-tablet-buttonIcon' src={Gantry}></img> 
                  </div> 
                  <div className="text-center">
                    <label className="homemenu-tablet-buttontitle">Turn ON<br></br>Gantry</label> 
                  </div>
                </div> 
              )
            }

            </Col>
            <Col lg={4} md={4} sm={4} xs={4}>
               
            </Col>
          </Row> 
          <Row className='pt-4'> 
            <Col lg={4} md={4} sm={4} xs={4}>
               
            </Col>
            <Col lg={4} md={4} sm={4} xs={4}>
              <div className="homemenu-tablet-button hommenu-square-button homemenu-downtime Cursor" onClick={() => onDownTimeClick(null)}>  
                <div className='text-center'>
                  <img className='homemenu-tablet-buttonIcon' src={Downtime}></img>
                </div> 
                <div className="text-center">
                  <label className="homemenu-tablet-buttontitle">Downtime</label> 
                </div>
              </div>  
            </Col>
            <Col lg={4} md={4} sm={4} xs={4}>
               
            </Col>
          </Row>
          <Row className='pt-4'> 
            <Col lg={4} md={4} sm={4} xs={4}>
              
            </Col>
            <Col lg={4} md={4} sm={4} xs={4}>
            
              {
              EsWaitingForkLift ?
              (
                <div className="homemenu-tablet-button hommenu-square-button homemenu-production-awaitingforklift Cursor" onClick={onAwaitingForkliftClick}>  
                  <div className='text-center'>
                    <img className='homemenu-tablet-buttonIcon' src={awaiting_forklift}></img> 
                  </div> 
                  <div className="text-center">
                    <label className="homemenu-tablet-buttontitle">Awaiting<br></br>Forklift</label> 
                  </div>
                </div> 
              )
              :
              (
                <div className="homemenu-tablet-button hommenu-square-button homemenu-production-forklift Cursor" onClick={onCallForkliftClick}>  
                  <div className='text-center'>
                    <img className='homemenu-tablet-buttonIcon' src={call_forklift}></img> 
                  </div> 
                  <div className="text-center">
                    <label className="homemenu-tablet-buttontitle">Call<br></br>Forklift</label> 
                  </div>
                </div> 
              )
            }

            </Col>
            <Col lg={4} md={4} sm={4} xs={4}>
               
            </Col>
          </Row> 
          <Link 
            ref={solicitudMaterialScreenLink} 
            to={
              { pathname: "SolicitudMaterialCeldaScreen", search: `?isNewHomeVersion=true&IdCelda=${ClaCelda}` }
          }
          >
          </Link>  
          <Link 
            ref={downtimeScreenLink} 
            to={
               { pathname: "DownTime", search: `?isNewHomeVersion=true&IdCelda=${ClaCelda}&ClaAreaDepartamento=${ClaAreaProduccion}` }
            }
          >
          </Link>  
          <Link 
            ref={downtimeScreenMachineLink} 
            to={
               { pathname: "DownTime", search: `?isNewHomeVersion=true&IdCelda=${ClaCelda}&ClaAreaDepartamento=${ClaAreaProduccion}&ClaMaquina=${ClaMaquina}` }
            }
          >
          </Link> 
          <Link 
            ref={checkInScreenLink} 
            to={
               { pathname: "CheckInScreen", search: `IdCelda=${ClaCelda}` }
            }
          >
          </Link> 
          <Link 
            ref={scrapScreenLink} 
            to={
               { pathname: "ScrapScreen", search: `IdCelda=${ClaCelda}` }
            }
          >
          </Link> 
          <Link 
            ref={checkOutScreenLink} 
            to={
               { pathname: "CheckOutScreen", search: `IdCelda=${ClaCelda}` }
            }
          >
          </Link> 
          <Link 
            ref={changeOverScreenLink} 
            to={
               { pathname: "ChangeOverScreen", search: `IdCelda=${ClaCelda}` }
            }
          >
          </Link> 
          <Link 
            ref={dashboardUtilizationLink} 
            to={
               { pathname: "DashboardUtilizationScreen", search: `IdCelda=${ClaCelda}` }
            }
          >
          </Link>
          <Link 
            ref={dashboardScrapLink} 
            to={
               { pathname: "DashboardScrapScreen", search: `IdCelda=${ClaCelda}` }
            }
          >
          </Link>
        </PageContent> 
        <Dialog
          visible={EsMostrarDetenerGantry}
          modal
          style={{ width: '100vw' }}
          footer={renderFooterDetenerGantry}
          header={renderHeaderDetenerGantry}
          onHide={() => setEsMostrarDetenerGantry(false)}
          closable={false}
          className="dialog-header-background-blue"
          maximized={true}
          draggable={false}
        > 
          <Row className='pt-3'>
            <Col>
              <div className='Tablet-Field'>
                <Row className='pb-1'>
                  <Col>
                    <span className="h3">{t('tablets:TurnOffAllCells')}</span>
                  </Col> 
                </Row>
                <Row className="pt-2 text-center">
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="p-fluid">
                      <FieldSelectButton
                        name="TipoAffectacion"
                        value={TipoAffectacion}
                        options={[
                          {
                            TipoAffectacion: 1,
                            NomTipoAffectacion: 'Gantry DOWN',
                          },
                          {
                            TipoAffectacion: 2,
                            NomTipoAffectacion: 'Disable Cells',
                          },
                        ]}
                        onChange={(e) => EsSeleccionaTipoAffectacion(e.value)}
                        optionValue="TipoAffectacion"
                        optionLabel="NomTipoAffectacion"
                        className="buttonListSurveyTablet"
                        multiple={false}
                        errors={errors}
                      />
                    </div>
                  </Col>
                </Row>
              </div> 
            </Col>
          </Row>
          <Row className='pt-3' hidden={TipoAffectacion!==2}>
            <Col>
              <div className='Tablet-Field'>
                <Row className='pb-1'>
                  <Col>
                    <span className="h3">{t('tablets:PleaseSelectCells')}</span>
                  </Col> 
                </Row>
                <Row className="pt-2 text-center">
                  <Col lg={12} md={12} sm={12} xs={12}> 
                    <FieldSelectButton
                      name="ClaCeldas"
                      value={ClaCeldas}
                      options={CeldaList}
                      className="cellList" 
                      optionValue="ClaCelda"
                      optionLabel="ClaveCelda"
                      errors={errors}
                      multiple={true}
                      onChange={(e) => onClaCeldaChange(e.value)}
                    /> 
                  </Col>
                </Row>
              </div> 
            </Col>
          </Row>
        </Dialog>
      </div>
    );
  }

  return (
    <div className='tablet-screen'>
      <PrincipalHeader 
        onConfigurationChange={() => InicializaOpciones()} 
      >
      </PrincipalHeader>
      <PageContent title="Home" subheader={subHeader}>   
        {ActiveDowntimes.length >= 1 ? 
            (  
                ActiveDowntimes.map((element, index) => {
                  return ( 
                    <div className='Tablet-Field' onClick={() => onDownTimeClick(element.ClaMaquina)}> 
                      <Row>
                        <Col xs={8}>
                          Active Downtime on <b>{element.NomMaquina}</b>
                        </Col>
                        <Col xs={4} className="pb-3 text-right">
                          <i className='fas fa-circle danger-color fa-lg'></i>
                        </Col>
                      </Row>
                    </div> 
                  );
                }) 
          ) : (
            <></>
          )} 
        <Row>
          <Col lg={4} md={4} sm={4} xs={4}> 
            <div className="homemenu-tablet-button hommenu-square-button homemenu-checkIn Cursor" onClick={onCheckInClick}>  
              <div className='text-center'>
                <img className='homemenu-tablet-buttonIcon' src={CheckIn}></img>
              </div> 
              <div className="text-center">
                <label className="homemenu-tablet-buttontitle">Check In</label> 
              </div>
            </div>       
          </Col>
          <Col lg={4} md={4} sm={4} xs={4}>
            <div className="homemenu-tablet-button hommenu-square-button homemenu-changeover Cursor" onClick={onChangeOverClick}>  
              <div className='text-center'>
                <img className='homemenu-tablet-buttonIcon' src={ChangeOver}></img>
              </div> 
              <div className="text-center">
                <label className="homemenu-tablet-buttontitle">Change Over</label> 
              </div>
            </div>  
          </Col>
          <Col lg={4} md={4} sm={4} xs={4}>
            <div className="homemenu-tablet-button hommenu-square-button homemenu-checkout Cursor" onClick={onCheckOutClick}>  
              <div className='text-center'>
                <img className='homemenu-tablet-buttonIcon' src={CheckOut}></img>
              </div> 
              <div className="text-center">
                <label className="homemenu-tablet-buttontitle">Check Out</label> 
              </div>
            </div>  
          </Col>
        </Row> 
        <Row className='pt-4'> 
          <Col lg={4} md={4} sm={4} xs={4}>
            <div className="homemenu-tablet-button hommenu-square-button homemenu-scrap Cursor" onClick={onScrapClick}>  
              <div className='text-center'>
                <img className='homemenu-tablet-buttonIcon' src={Scrap}></img>
              </div> 
              <div className="text-center">
                <label className="homemenu-tablet-buttontitle">Scrap</label> 
              </div>
            </div>  
          </Col>
          <Col lg={4} md={4} sm={4} xs={4}>
            <div className="homemenu-tablet-button hommenu-square-button homemenu-downtime Cursor" onClick={() => onDownTimeClick(null)}>  
              <div className='text-center'>
                <img className='homemenu-tablet-buttonIcon' src={Downtime}></img>
              </div> 
              <div className="text-center">
                <label className="homemenu-tablet-buttontitle">Downtime</label> 
              </div>
            </div>  
          </Col>
          <Col lg={4} md={4} sm={4} xs={4}>
            <div className="homemenu-tablet-button hommenu-square-button homemenu-materialrequest Cursor" onClick={onSolicitudMaterialClick}>  
              <div className='text-center'>
                <img className='homemenu-tablet-buttonIcon' src={MaterialRequest}></img>
              </div> 
              <div className="text-center">
                <label className="homemenu-tablet-buttontitle">Material Request</label> 
              </div>
            </div> 
          </Col>
        </Row>
        <Row className='pt-4'> 
          <Col lg={4} md={4} sm={4} xs={4}>
            <div className="homemenu-tablet-button hommenu-square-button homemenu-production-stats Cursor" onClick={onProductionStatsClick}>  
              <div className='text-center'>
                <i className='homemenu-tablet-buttonIcon-fontawesome fas fa-chart-line'></i>
              </div> 
              <div className="text-center">
                <label className="homemenu-tablet-buttontitle">Utilization Statistics</label> 
              </div>
            </div>  
          </Col>
          <Col lg={4} md={4} sm={4} xs={4}>
            
            {
              EsWaitingForkLift ?
              (
                <div className="homemenu-tablet-button hommenu-square-button homemenu-production-awaitingforklift Cursor" onClick={onAwaitingForkliftClick}>  
                  <div className='text-center'>
                    <img className='homemenu-tablet-buttonIcon' src={awaiting_forklift}></img> 
                  </div> 
                  <div className="text-center">
                    <label className="homemenu-tablet-buttontitle">Awaiting<br></br>Forklift</label> 
                  </div>
                </div> 
              )
              :
              (
                <div className="homemenu-tablet-button hommenu-square-button homemenu-production-forklift Cursor" onClick={onCallForkliftClick}>  
                  <div className='text-center'>
                    <img className='homemenu-tablet-buttonIcon' src={call_forklift}></img> 
                  </div> 
                  <div className="text-center">
                    <label className="homemenu-tablet-buttontitle">Call<br></br>Forklift</label> 
                  </div>
                </div> 
              )
            }

          </Col>
          <Col lg={4} md={4} sm={4} xs={4}>
            <div className="homemenu-tablet-button hommenu-square-button homemenu-production-scrap-stats Cursor" onClick={onProductionStatsScrapClick}>  
              <div className='text-center'>
                <i className='homemenu-tablet-buttonIcon-fontawesome fas fa-chart-pie '></i>
              </div> 
              <div className="text-center">
                <label className="homemenu-tablet-buttontitle">Scrap&nbsp; &nbsp;Statistics</label> 
              </div>
            </div>  
          </Col>
        </Row>
        <Row className='pt-4'>
          <Col>
            <Card>
              <CardBody>
                <Row className=''>
                  <Col lg={6} md={6} sm={6} xs={6}>
                    <div className="homemenu-tablet-button hommenu-square-button homemenu-break homebutton-left-boder Cursor" onClick={onBreakTimeClick}>  
                      <Row className='h-100'>
                        <Col className='text-right'> 
                          <img className='homemenu-tablet-buttonIcon' src={Break}></img> 
                        </Col>
                        <Col className="text-left">
                          <label className="homemenu-tablet-buttontitle">Break<br></br>Time</label> 
                        </Col>
                      </Row> 
                    </div>  
                  </Col>
                  <Col lg={6} md={6} sm={6} xs={6}>
                    <div className="homemenu-tablet-button hommenu-square-button homebutton-right-boder homemenu-lunch Cursor" onClick={onLunchTimeClick}>  
                      <Row className='h-100'>
                        <Col className='text-right'>
                          <div> 
                            <img className='homemenu-tablet-buttonIcon' src={Lunch}></img>
                          </div>
                        </Col>
                        <Col className="text-left">
                          <label className="homemenu-tablet-buttontitle">Lunch<br></br>Time</label> 
                        </Col>
                      </Row> 
                    </div>  
                  </Col>
                </Row>
                <Row className='pt-2'>
                  <Col className='text-center'><span className='homebutton-disclosure-text'>* Only use Break & Lunch time buttons if the machine is shutting down.</span></Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        
        <Link 
          ref={solicitudMaterialScreenLink} 
          to={
              { pathname: "SolicitudMaterialCeldaScreen", search: `?isNewHomeVersion=true&IdCelda=${ClaCelda}` }
          }
        >
        </Link>  
        <Link 
          ref={downtimeScreenLink} 
          to={
               { pathname: "DownTime", search: `?isNewHomeVersion=true&IdCelda=${ClaCelda}&ClaAreaDepartamento=${ClaAreaProduccion}` }
            }
        >
        </Link>  
        <Link 
          ref={downtimeScreenMachineLink} 
          to={
               { pathname: "DownTime", search: `?isNewHomeVersion=true&IdCelda=${ClaCelda}&ClaAreaDepartamento=${ClaAreaProduccion}&ClaMaquina=${ClaMaquina}` }
            }
        >
        </Link> 
        <Link 
          ref={checkInScreenLink} 
          to={
               { pathname: "CheckInScreen", search: `IdCelda=${ClaCelda}` }
            }
        >
        </Link> 
        <Link 
          ref={scrapScreenLink} 
          to={
               { pathname: "ScrapScreen", search: `IdCelda=${ClaCelda}` }
            }
        >
        </Link> 
        <Link 
          ref={checkOutScreenLink} 
          to={
               { pathname: "CheckOutScreen", search: `IdCelda=${ClaCelda}` }
            }
        >
        </Link> 
        <Link 
          ref={changeOverScreenLink} 
          to={
               { pathname: "ChangeOverScreen", search: `IdCelda=${ClaCelda}` }
            }
        >
        </Link> 
        <Link 
          ref={dashboardUtilizationLink} 
          to={
               { pathname: "DashboardUtilizationScreen", search: `IdCelda=${ClaCelda}` }
            }
        >
        </Link>
        <Link 
          ref={dashboardScrapLink} 
          to={
               { pathname: "DashboardScrapScreen", search: `IdCelda=${ClaCelda}` }
            }
        >
        </Link>
      </PageContent> 
    </div>
  );
};

export default CellHomeMenuScreen;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi, showSweetAlert, getSessionItem } from '../../../utils/utils';
import { Checkbox } from 'primereact/checkbox';
import FieldText from '../../../components/Controls/FieldText.jsx';
import FieldTextArea from '../../../components/Controls/FieldTextArea.jsx';
import FieldNumber from '../../../components/Controls/FieldNumber.jsx';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx';
import FieldDropdown from 'components/Controls/FieldDropdown.jsx';
import { RadioButton } from 'primereact/radiobutton';
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import moment from 'moment';
import { Sidebar } from 'primereact/sidebar';
import swal from '@sweetalert/with-react';
import { Tag } from 'primereact/tag';
import PageContent from 'layouts/PageContent.jsx'; 
import { ColumnGroup } from 'primereact/columngroup';
import IncentiveProgramsGoalsSalesRep from './IncentiveProgramsGoalsSalesRep.jsx';
import InstructionsCard from '../../../components/Cards/InstructionsCard/InstructionsCard.jsx'; 

const IncentiveProgramsGoalsScreen = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['salesPerformance', 'common']); 

  const [ClaAnio, setClaAnio] = useState(null);
  const [ClaAgenteVentas, setClaAgenteVentas] = useState(null);
  const [AniosList, setAniosList] = useState([]);
  const [AgenteVentasList, setAgenteVentasList] = useState([]);
 
  useEffect(() => { 
    GetAniosCmb();
    GetAgenteCmb(); 
  }, []);
  
  const GetAniosCmb = () => {
    const GetAniosCmb = `${config.UrlApiProject}SalesPerformanceSales/GetAniosCmb`;
    const paramsToService = {};
    callApi(GetAniosCmb, 'GET', paramsToService, (response) => {
      setAniosList(response.data.Anios); 
    });
  };

  const GetAgenteCmb = () => {
    const getAgenteCmb = `${config.UrlApiProject}SalesPerformanceSales/GetAgenteCmb`;
    const paramsToService = {};
    callApi(getAgenteCmb, 'GET', paramsToService, (response) => {
      setAgenteVentasList(response.data.Agente); 
    });
  };

  return (
    <>
      <PageContent title={t('salesPerformance:IncentiveProgramGoals')}> 
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="pb-2">   
            <InstructionsCard>
              <p>
                {t('salesPerformance:IncentiveProgramGoalsInstructions')}.
              </p>
            </InstructionsCard>
          </Col>
        </Row>
        <Row>
          <Col lg={3} md={3} sm={12} xs={12} className="p-3">
            <div className="p-fluid">
              <FieldDropdown  
                name="ClaAnio" 
                label={t('salesPerformance:Anio')}
                value={ClaAnio} 
                options={AniosList}  
                optionValue="ClaAnio"
                optionLabel="NomAnio" 
                onChange={(e) => setClaAnio(e.value === undefined ?  null : e.value)}  
              />  
            </div>
          </Col>
          <Col lg={9} md={9} sm={12} xs={12} className="p-3">
            <div className="p-fluid">
              <FieldDropdown  
                name="ClaAgenteVentas" 
                label={t('salesPerformance:SalesRep')}
                value={ClaAgenteVentas} 
                options={AgenteVentasList}  
                optionValue="ClaAgente"
                optionLabel="NomAgente" 
                onChange={(e) => setClaAgenteVentas(e.value === undefined ?  null : e.value)}  
              />  
            </div>
          </Col>
        </Row>
        <Row hidden={ClaAnio === null || ClaAgenteVentas === null}>
          <Col>
            <IncentiveProgramsGoalsSalesRep ClaAnio={ClaAnio} ClaAgenteVentas={ClaAgenteVentas}></IncentiveProgramsGoalsSalesRep>
          </Col>
        </Row> 
      </PageContent>
    </>
  );
};
export default IncentiveProgramsGoalsScreen;

/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import React, { useEffect, useRef, useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import DAISeerThoughtsDialog from './DAISeerThoughtsDialog/DAISeerThoughtsDialog';
import DAISeerThoughtsPanel from './DAISeerThoughtsPanel/DAISeerThoughtsPanel'; 

// Props:
// - question
// - onLikePress
// - onUnlikePress
const DAISeerAnswer = (props) => {
  const {t, i18n} = useTranslation(['daiSeer', 'common']);  
  const [isThoughtsDialogVisible, setIsThoughtsDialogVisible] = useState(false);
  const [answerDataType, setAnswerDataType] = useState('');
  const [answer, setAnswer] = useState('');
  const [rawSteps, setRawSteps] = useState([]); 
  const [isErrorMessageResponse, setIsErrorMessageResponse] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
    
  const parseAnswer = (_answerJSONString) => {
    try {        
      // console.group("Parsing answer")
      if (_answerJSONString && _answerJSONString !== '') {      
        

        if (_answerJSONString.indexOf('500 Internal Server Error') !== -1) { 
          setAnswerDataType('Internal Error');
          setAnswer(_answer);
        }
        else {
          const _answer = JSON.parse(_answerJSONString);
          console.log("Answer:", _answer);
          setAnswer(_answer.response);
          setAnswerDataType(_answer.data_type);
          setRawSteps(_answer.steps);   
          setIsErrorMessageResponse(typeof(_answer.error) !== 'undefined');
          setErrorMessage(_answer.error); 
        }
      }        
    } catch(exception) {
      console.error("Answer is not a JSON.");      
    } finally {
      // console.groupEnd();
    }     
  };

  const onLikePress = () => {
    if (props.onLikePress) {
      props.onLikePress();
    }    
  }

  const onUnlikePress = () => {
    if (props.onUnlikePress) {
      props.onUnlikePress();
    }    
  }

  const onThoughtsPress = () => {
    setIsThoughtsDialogVisible(true);
  }

  const onHideThoughtsDialog = () => {
    setIsThoughtsDialogVisible(false);
  }

  const avatarTemplate = (
    <>
      <p>
        <span 
          className='p-2'
          style={{
            backgroundColor: '#c0c0c0',
            color: 'white'
          }}
        >
          <b>
            {t("daiSeer:AnswerShortName")}
          </b>
        </span>            
      </p> 
    </>
  );  

  const answerTemplate = ( 
    <>
      {
        // a vaces regresan informacion de html, lo inyectamos para los scripts que se inyectan ver
        // DAISeerConversation.jsx que se encagra de correr los scripts una vez cargados.
        answerDataType === 'Table' || answerDataType === 'Plot Line' || answerDataType === 'Plot Bar' || answerDataType === 'Internal Error'  ?
        (  
          <>
            <div dangerouslySetInnerHTML={{ __html: answer }} style={{width:'100%', height:'100%'}} className='answerHTML'>
            </div> 
          </>
        )
        :
        (
          <span style={{whiteSpace: 'pre-line'}}>
            {
              isErrorMessageResponse ?
              (
                <>
                  {
                    errorMessage.indexOf('maximum context length') !== -1
                    ?
                    (
                      <span>
                        Hello! 👋 Copilot is here to help, but to get the best and quickest answers, it&apos;s best to keep your questions simple and specific. Feel free to ask straightforward questions, and we will do our best to assist you effectively. If you have a more complex query, consider breaking it down into smaller, more focused questions. Thanks for using our Copilot chat bot!
                      </span>
                    )
                    :
                    (<span className='text-red'>{errorMessage}</span>)
                  }
                </>
              )
              :
              (
                <>
                  {
                  answer && Array.isArray(answer) ? (
                    <ul>
                      {
                        answer.map((item) => {
                          return (
                            <>
                              <li>
                                <b>
                                  {item}
                                </b>
                              </li>
                            </>
                          )
                        })
                      }
                    </ul> 
                  )
                  :
                  (
                    <>{answer}</>
                  )
                }
                </>
              )
            } 
          </span>   
        )
      } 
    </>
  );

  const answerRightButtonsTemplate = (
    <>
      <FieldButton
        icon="fa fa-brain" 
        tooltip={t("daiSeer:Thoughts")}          
        onClick={onThoughtsPress} 
        className="float-right p-button-rounded p-button-text"
      />  
      <FieldButton
        icon="fa fa-thumbs-down" 
        tooltip={t("daiSeer:Unlike")}        
        onClick={onUnlikePress} 
        className={`float-right p-button-rounded p-button-text ${props.question.MeGustaRespuesta === null || props.question.MeGustaRespuesta? 'light-gray-color' : '' }`}
      />    
      <FieldButton
        icon="fa fa-thumbs-up" 
        tooltip={t("daiSeer:Like")}        
        onClick={onLikePress} 
        className={`float-right p-button-rounded p-button-text ${props.question.MeGustaRespuesta === null || !props.question.MeGustaRespuesta? 'light-gray-color' : '' }`}
      />           
    </>
  );

  const thoughtsPanelTemplate = (
    <>
      <DAISeerThoughtsPanel
        rawSteps={rawSteps}
      />
    </>
  );

  const componentTemplate = (
    <>
      <Row 
        className='DAISeerAnswer align-items-start p-3'
        style={{ backgroundColor: 'white'}}
      >
        <Col 
          xs={1} 
          className='text-right'
        >
          {avatarTemplate}   
        </Col>
        <Col xs={10}> 
          <Row>
            <Col>
              {answerRightButtonsTemplate}
            </Col>
          </Row> 
          <Row className='pb-2'>
            <Col> 
              {answerTemplate}
            </Col>
          </Row>
          <Row>
            <Col>
              {thoughtsPanelTemplate}
            </Col>
          </Row>                       
        </Col>       
      </Row>
      <DAISeerThoughtsDialog
        question={props.question}
        visible={isThoughtsDialogVisible}
        onHide={onHideThoughtsDialog}
      />      
    </>
  );

  useEffect(() => {
    parseAnswer(props.question.Respuesta);
  }, [props.question.Respuesta]);

  return (
    <>
      {componentTemplate}  
    </>
  )
}

export default DAISeerAnswer
/* eslint-disable object-shorthand */
import React, { useState, useEffect } from 'react';
import { config } from '../../../utils/config';
import { callApi } from '../../../utils/utils'; 
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTranslation } from 'react-i18next'; 

const StandarVsRealChar = (props) => {

    const { t } = useTranslation(['formulario', 'common']);
    const [dataForBuckets, setDataForBuckets] = useState({});
    const [chartTitle, setChartTitle] = useState('');
    const [standardCostData, setStandardCostData] = useState([]);
    const [realCostData, setRealCostData] = useState([]);
    const [xCategories, setXCategories] = useState([]);

    const lineChart = {
        chart: {
          height: 328,
          type: 'line',
        },
        title: {
          text: `<b>${chartTitle}</b><br/>${  t('formulario:TrendLast12Months')}`,
        },  
        xAxis: {  
          gridLineWidth:0,
          lineWidth: 0,
          categories: xCategories,
          title: {
            text: null,
          },
        },
        yAxis: { 
          gridLineWidth:1,
          title: {
            text: null,
          }, 
          labels: {
            overflow: 'justify',
            enabled:true
          },
        },
        tooltip: { 
            valuePrefix: ' $',
            valueDecimals: 2
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        legend: {
          layout: 'horizontal',
          align: 'left',
          verticalAlign: 'bottom', 
          borderWidth: 1,
          backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
          shadow: true,
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: 'Real',
            data: realCostData,  
            showInLegend: true, 
            color: '#ff7a3d',
            dataLabels:{
                enabled:false,  
                formatter: function() {  
                    const { x, y, point, series } = this; 
                    return `$ ${Highcharts.numberFormat(y,1)  }`;
                  }
            }
          },
          {
            name: 'Standard',
            data: standardCostData,  
            showInLegend: true, 
            color: '#26caf6',
            dataLabels:{
                enabled:false,  
                formatter: function() {  
                    const { x, y, point, series } = this; 
                    return `$ ${Highcharts.numberFormat(y,1)  }`;
                  }
            }
          }
        ], 
      };

      useEffect(() => {
    
        const GetInconsistenciasGridService = `${config.UrlApiProject}Rentabilidad/GetInconsistenciasLinesChart`;
    
        const paramsToService = {
          anioMes: props.filters.AnioMes,
          claGrupoEstadistico3: props.filters.ClaGrupoEstadistico3,
          claGerenciaRegional: props.filters.ClaGerenciaRegional,
          claclientecuenta: props.filters.ClaClienteCuenta,
          claBucket: props.ClaBucket,
          esMostrarSoloConfiables: props.filters.EsMostrarSoloConfiables,
          claMarketSegment: props.filters.ClaMarketSegment
        };  

        callApi(GetInconsistenciasGridService, 'POST', paramsToService, (response) => {
            
            setStandardCostData(response.data.Standard.data);
            setRealCostData(response.data.Real.data);
            setXCategories(response.data.Categories); 
        });

        const GetFollowUpTitle = `${config.UrlApiProject}Rentabilidad/GetBucketFollowUpTitle`; 
    
        callApi(GetFollowUpTitle, 'POST', paramsToService, (response) => {  
          console.log('StandarVsRealChar response');
          console.log(response);
          if (response.data.NomClienteCuenta !== ''){ 
            setChartTitle(`${response.data.NomClienteCuenta  } / ${  response.data.NomBucket }`);  
          }else{ 
            setChartTitle(response.data.NomBucket); 
          }  
        });
    
      }, [props.ClaBucket]);

    return  <HighchartsReact highcharts={Highcharts} options={lineChart} />;
}

export default StandarVsRealChar;

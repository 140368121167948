/* eslint-disable prefer-destructuring */

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from 'utils/config';
import { callApi, formatDate } from 'utils/utils';
import { Column } from 'primereact/column';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import ITAssetsDetails from './ITAssetsDetails';
import ITAssetsAssign from './ITAssetsAssign';
import ITAssetsDisable from './ITAssetsDisable';
import { Dialog } from 'primereact/dialog';
import { Sidebar } from 'primereact/sidebar';

const ITAssets = (props) => {
  const {t, i18n} = useTranslation(['it', 'common']);

  const [assets, setAssets] = useState([]); // IT Assets
  const [filters, setFilters] = useState({}); // Filters
  const [showDetails, setShowDetails] = useState(false); // Show details bar
  const [showAssign, setShowAssign] = useState(false); // Show assign dialog
  const [showDisable, setShowDisable] = useState(false); // Show disable dialog
  const [tableRow, setTableRow] = useState(null); // Details table data

  useEffect(() => {
    GetAssets();
  }, [props.filters])

  // Get list of assets with filters
  const GetAssets = () => {
    const filters = props.filters;

    const urlWebService = `${config.UrlApiProject}IT/GetITAssets`;
    
    const paramsService = {};
    if (props.filters !== undefined && props.filters !== null) {
      paramsService.IdITEquipo = filters.IdITEquipo;
      paramsService.ClaTipoEquipo = filters.ClaTipoEquipo;
      paramsService.ClaMarcaEquipo = filters.ClaMarcaEquipo;
      paramsService.NomProveedor = filters.NomProveedor;
      paramsService.ModeloEquipo = filters.ModeloEquipo;
      paramsService.SerialNumber = filters.SerialNumber;
      paramsService.IdOrdenCompraRef = filters.IdOrdenCompraRef;
      paramsService.ClaUsuarioAsignado = filters.AssignedUser;
      paramsService.ClaUbicacion = filters.ClaUbicacion;
      paramsService.AssignedTo = filters.AssignedTo;
      paramsService.ClaADPEmployee = filters.ClaADPEmployee;
    }

    console.log(paramsService);

    callApi(urlWebService, 'POST', paramsService, (response) => {
      setAssets(response.data.assets);
    });
  }

  // Show the details
  const onClickDetails = (row) => {
    setShowDetails(true);
    setTableRow(row);
  }

  // Assign a user
  const onClickAssign = (row) => {
    setTableRow(row);
    setShowAssign(true);
  }

  // Disable an asset
  const onClickDisable = (row) => {
    setTableRow(row);
    setShowDisable(true);
  }

  // Assign Dialog close
  const onHideAssign = () => {
    setShowAssign(false);
    GetAssets();
  }

  // Disable Dialog close
  const onHideDisable = () => {
    setShowDisable(false);
    GetAssets();
  }

  // Table purchase date
  const purchaseDateTemplate = (row) => {
    return (
      <>
        {row.FechaCompra !== null && row.FechaCompra !== undefined ? (
          <span>{formatDate(row.FechaCompra)}</span>
        ) : <></>}
      </>
    )
  }

  // Table options
  const optionsTemplate = (row) => {
    return (
      <>
        <FieldButton
          name='details'
          icon='pi pi-list'
          className='p-button-rounded mr-2'
          onClick={() => {onClickDetails(row)}}
          tooltip={t('it:Details')}
        />
        <FieldButton
          name='assign'
          icon='pi pi-user'
          className='p-button-rounded p-button-warning mr-2'
          onClick={() => {onClickAssign(row)}}
          tooltip={t('it:Reassign')}
        />
        <FieldButton
          name='disable'
          icon='pi pi-trash'
          className='p-button-rounded p-button-danger'
          onClick={() => {onClickDisable(row)}}
          tooltip={t('common:Disable')}
        />
      </>
    )
  }

  return (
    <>
      <FieldDataTable
        value={assets}
        scrollable
      >
        <Column header={t('it:ID')} sortable field='IdITEquipo' headerStyle={{ width: '60px' }} />
        <Column header={t('it:Equipment')} sortable field='NomTipoEquipo' headerStyle={{ width: '155px' }} />
        <Column header={t('it:Brand')} sortable field='NomMarcaEquipo' headerStyle={{ width: '125px' }} />
        <Column header={t('it:SerialNumber')} sortable field='SerialNumber' headerStyle={{ width: '145px' }} />
        <Column header={t('it:Model')} sortable field='ModeloEquipo' headerStyle={{ width: '200px' }} />
        {/* <Column header={t('it:PurchaseOrder')} sortable field='IdOrdenCompraRef' headerStyle={{ width: '120px' }} /> */}
        {/* <Column body={purchaseDateTemplate} header={t('it:PurchaseDate')} sortable sortField='FechaCompra' headerStyle={{ width: '120px' }} />
        <Column header={t('it:Vendor')} sortable field='NomProveedor' headerStyle={{ width: '130px' }} /> */}
        <Column header={t('it:Location')} sortable field='Location' headerStyle={{ width: '150px' }} />
        <Column header={t('it:AssignedTo')} sortable field='AssignedTo' headerStyle={{ width: '135px' }} />
        <Column header={t('it:Employee')} sortable field='Employee' headerStyle={{ width: '275px' }} />
        <Column header={t('it:User')} sortable field='AssignedUser' headerStyle={{ width: '325px' }} />
        <Column body={optionsTemplate} header={t('it:Options')} />
      </FieldDataTable>
      <Dialog
        visible={showAssign}
        modal
        onHide={() => onHideAssign()}
        header={t('it:Reassign')}
        style={{ width: '25vw' }}
        breakpoints={{ '1000px': '75vw', '500px': '100vw' }}
        blockScroll={true}
      >
        <ITAssetsAssign visible={showAssign} selectedAsset={tableRow} onHide={() => onHideAssign()} />
      </Dialog>
      <Sidebar
        className='p-sidebar-lg'
        position='right'
        baseZIndex={1000000}
        visible={showDetails}
        onHide={() => setShowDetails(false)}
        blockScroll={true}
        showCloseIcon={true}
        dismissable={true}
      >
        <ITAssetsDetails visible={showDetails} selectedAsset={tableRow} onHide={() => setShowDetails(false)} />
      </Sidebar>
      <Dialog
        visible={showDisable}
        onHide={() => onHideDisable()}
        header={t('it:Disable')}
        style={{ width: '50vw' }}
        breakpoints={{ '1700px': '75vw', '1000px': '100vw' }}
        blockScroll={true}
      >
        <ITAssetsDisable selectedAsset={tableRow} onHide={() => onHideDisable()} />
      </Dialog>
    </>
  )
}

export default ITAssets;
/* eslint-disable prefer-destructuring */

import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { callApi, showSweetAlert, getSessionItem, formatDate } from 'utils/utils';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import { config } from 'utils/config';
import moment from 'moment';
import { Dialog } from 'primereact/dialog';
import { Row, Col, Label, Card, CardBody, CardHeader, CardFooter } from 'reactstrap'
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldDataView from 'components/Controls/FieldDataView/FieldDataView';
import { DataViewLayoutOptions } from 'primereact/dataview';
import YupValidator from 'utils/YupValidator';
import * as yup from 'yup';
import ManageITTicketsFilters from './ManageITTicketsFilters';
import ITTicketDetails from './ITTicketDetails';
import './ManageITTickets.scss'
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import { Column } from 'primereact/column';

const ManageITTickets = (props) => {
  const {t, i18n} = useTranslation(['it', 'common']);

  const [tickets, setTickets] = useState([]); // IT Tickets
  const [currentTicket, setCurrentTicket] = useState([]); // Current ticket
  const [showDetails, setShowDetails] = useState(false); // Details dialog
  const [errors, setErrors] = useState([]); // Errors
  const [usuarioSolucionas, setUsuarioSolucionas] = useState([]); // IT Users
  const [showReassign, setShowReassign] = useState(false); // Show the reassign dialog
  const [usuarioSoluciona, setUsuarioSoluciona] = useState(null); // User to reassign to
  const [filters, setFiltersData] = useState({}); // Filters
  const [layout, setLayout] = useState('grid'); // DataView layout
  const [sortKey, setSortKey] = useState('FechaPromesa') // Sort key
  const [sortOrder, setSortOrder] = useState(1) // Sort Order
  const [sortField, setSortField] = useState('FechaPromesa') // Sort Field

  useEffect(() => {
    console.log(filters);
    if (!filters.isFirstRender && filters.isFirstRender !== null && filters.isFirstRender !== undefined) {
      getTickets(filters);
    }
    
  }, [filters])
  
  useEffect(() => {
    getITUsers();
  }, [])

  // Get the IT Ticket list
  const getTickets = (filters) => {
    console.log(`Requester=${filters.claUsuarioSolicita}`);
    console.log(`Assigned=${filters.claUsuarioSoluciona}`);

    const pnShowCompleted = filters.showCompleted ? 1 : 0;
    const pnShowCanceled = filters.showCanceled ? 1 : 0;
    let urlWebService = `${config.UrlApiProject}IT/GetITSupportTicketsUSA?showCompleted=${pnShowCompleted}&showCanceled=${pnShowCanceled}`;

    if (filters.claUsuarioSolicita !== null && filters.claUsuarioSolicita !== undefined) {
      urlWebService = `${urlWebService}&ClaUsuarioSolicita=${filters.claUsuarioSolicita}`;
    }

    if (filters.claUsuarioSoluciona !== null && filters.claUsuarioSoluciona !== undefined) {
      urlWebService = `${urlWebService}&ClaUsuarioSoluciona=${filters.claUsuarioSoluciona}`;
    }

    const paramsService = {
    };

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setTickets(response.data.tickets);
    });
  }

  // Get the list of IT users
  const getITUsers = () => {
    const urlWebService = `${config.UrlApiProject}IT/GetITTicketUsersUSA`;
    const paramsService = {};

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setUsuarioSolucionas(response.data.Users);
    });
  }

  // Handle reassign column button click
  const onReassignDialogClick = (ticket) => {
    setUsuarioSoluciona(ticket.ClaUsuarioSoluciona);
    setCurrentTicket(ticket);
    setShowReassign(true);
  }

  // Handle reassign dialog hide
  const onReassignDialogHide = () => {
    setShowReassign(false);
    setErrors([]);
    setUsuarioSoluciona(null);
  }

  // Handle detail button click
  const onDetailsClick = (ticket) => {
    const urlWebService = `${config.UrlApiProject}IT/GetITSupportTicketDetails?IdSolicitudSoporte=${ticket.IdSolicitudSoporte}`;
    const paramsService = {};

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setCurrentTicket(response.data.Details);
      setShowDetails(true);
    });
  }

  // Handle details dialog hide
  const onDetailsHide = () => {
    setShowDetails(false);
    getTickets(filters);
  }

  // Handle assigned user change
  const onChangeUsuarioSoluciona = (e) => {
    const newErrors = {...errors};
    delete newErrors.usuarioSoluciona;
    setErrors(newErrors);
    setUsuarioSoluciona(e.value);
  }

  // Handle user reassignment
  const onReassignClick = async () => {
    const validationScheme = yup.object().shape({
      usuarioSoluciona: yup.number().required(t('common:RequiredField')).integer().nullable()
    });

    const value = {
      usuarioSoluciona
    };
    const validator = new YupValidator(validationScheme);
    const isValid = await validator.validate(value);
    if (!isValid) {
      setErrors(validator.errors);
    }
    else {
      const urlWebService = `${config.UrlApiProject}IT/ReassignSolicitudSoporteUSA`;
      const paramsService = {
        IdSolicitudSoporte: currentTicket.IdSolicitudSoporte,
        ClaUsuarioSoluciona: usuarioSoluciona,
        ClaUsuarioReasigna: getSessionItem('ClaUsuarioMod', 0)
      };

      callApi(urlWebService, 'POST', paramsService, (response) => {
        showSweetAlert("Success", t('it:TicketReassigned'), 'success');
        onReassignDialogHide();
        getTickets(filters);
      });
    }
  }

  // Reassign dialog footer
  const reassignFooter = () => {
    return (
      <>
        <FieldButton
          icon='pi pi-user'
          label={t('it:Reassign')}
          onClick={onReassignClick}
          className='p-button-warning'
          disabled={currentTicket.ClaUsuarioSoluciona === usuarioSoluciona}
        >
        </FieldButton>
      </>
    )
  }

  // Handle filter change
  const onFiltersChange = (newFilters) => {
    setFiltersData(newFilters);
  }

  const getTextStyle = (claEstatusSolicitud) => {
    return claEstatusSolicitud === 6 ? 'it-text-red'
          : claEstatusSolicitud === 7 ? 'it-text-orange'
          : claEstatusSolicitud === 8 ? 'it-text-green'
          : '';
  }

  const priorityTemplate = (row) => {
    const style = getTextStyle(row.ClaEstatusSolicitud);
    
    return (
      <span className={style}>{`${row.NomPrioridadIncidenteHlp} - ${row.HorasSolucion} Hrs`}</span>
    )
  }

  const idTemplate = (row) => {
    const style = getTextStyle(row.ClaEstatusSolicitud);

    return (
      <span className={style}>{row.IdSolicitudSoporte}</span>
    )
  }

  const titleTemplate = (row) => {
    const style = getTextStyle(row.ClaEstatusSolicitud);

    return (
      <span className={style}>{row.TituloSoporte}</span>
    )
  }

  const statusTemplate = (row) => {
    const style = getTextStyle(row.ClaEstatusSolicitud);

    return (
      <span className={style}>{moment().isAfter(row.FechaPromesa) && row.ClaEstatusSolicitud === 6 ? t('it:Late') : row.NomEstatusSolicitudIng}</span>
    )
  }

  const requesterTemplate = (row) => {
    const style = getTextStyle(row.ClaEstatusSolicitud);

    return (
      <span className={style}>{row.NombreCompletoSolicita}</span>
    )
  }

  const supportTypeTemplate = (row) => {
    const style = getTextStyle(row.ClaEstatusSolicitud);

    return (
      <span className={style}>{row.NomTipoSoporte}</span>
    )
  }

  const assignedToTemplate = (row) => {
    const style = getTextStyle(row.ClaEstatusSolicitud);

    return (
      <span className={style}>{row.NombreCompletoSoluciona}</span>
    )
  }

  const optionsTemplate = (row) => {
    return (
      <>
        <FieldButton
          icon='pi pi-list'
          className='p-button-rounded mr-2'
          tooltip={t('it:Details')}
          onClick={() => {onDetailsClick(row)}}
        />
        {row.ClaEstatusSolicitud === 6 && filters.isITUser ?
          (
            <FieldButton
              icon='pi pi-user'
              className='p-button-rounded p-button-warning'
              tooltip={t('it:Reassign')}
              onClick={() => onReassignDialogClick(row)}
            />
          ): <></>}
      </>
    )
  }

  const promiseDateTemplate = (row) => {
    const style = getTextStyle(row.ClaEstatusSolicitud);

    return (
      <>
        <span className={style}>{moment(row.FechaPromesa).format("M/D/YYYY h:mm a")}</span>
      </>
    )
  }

  return (
    <>
      <ManageITTicketsFilters getTickets={getTickets} onFiltersChange={onFiltersChange} />
      <Card className='mt-4 p-fluid p-d-flex rounded-bottom'>
        <CardBody>
          <FieldDataTable
            value={tickets}
            scrollable
          >
            <Column body={idTemplate} header={t('it:ID')} sortable sortField='IdSolicitudSoporte' headerStyle={{ width: '60px' }} />
            <Column body={statusTemplate} header={t('it:Status')} sortable sortField='NomEstatusSolicitudIng' headerStyle={{ width: '80px' }} />
            <Column body={priorityTemplate} header={t('it:Priority')} sortable sortField='NomPrioridadIncidenteHlp' headerStyle={{ width: '125px' }} />
            <Column body={promiseDateTemplate} header={t('it:PromiseDate')} sortable sortField='FechaPromesa' headerStyle={{ width: '150px' }} />
            <Column body={titleTemplate} header={t('it:Title')} sortable sortField='TituloSoporte' headerStyle={{ width: '250px' }} />
            <Column body={requesterTemplate} header={t('it:Requester')} sortable sortField='NombreCompletoSolicita' headerStyle={{ width: '200px' }} />
            <Column body={supportTypeTemplate} header={t('it:SupportType')} sortable sortField='NomTipoSoporte' headerStyle={{ width: '145px' }} />
            <Column body={assignedToTemplate} header={t('it:AssignedTo')} sortable sortField='NombreCompletoSoluciona' headerStyle={{ width: '200px' }} />
            <Column body={optionsTemplate} header={t('it:Options')} headerStyle={{ width: '100px' }} />
          </FieldDataTable>
        </CardBody>
      </Card>
      {/* Details Dialog */}
      <Dialog
        visible={showDetails}
        modal
        onHide={onDetailsHide}
        header={t('common:Details')}
        maximized
      >
        <ITTicketDetails ticket={currentTicket} onDetailsHide={onDetailsHide} isITUser={filters.isITUser} />
      </Dialog>
      {/* Reassign Dialog */}
      <Dialog
        visible={showReassign}
        modal
        onHide={() => setShowReassign(false)}
        header={t('it:ReassignUser')}
        breakpoints={{'1000px': '75vw', '500px': '100vw'}}
        style={{width: '25vw'}}
        footer={reassignFooter}
      >
        <Row className='p-fluid'>
          <Col className='mt-1'>
            <FieldDropdown
              name="usuarioSoluciona"
              value={usuarioSoluciona}
              optionValue="ClaUsuario"
              optionLabel="NombreCompleto"
              options={usuarioSolucionas}
              onChange={onChangeUsuarioSoluciona}
              errors={errors}
            >
            </FieldDropdown>
          </Col>
        </Row>
      </Dialog>
    </>
  )
}

export default ManageITTickets;
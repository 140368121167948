import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { callApi, showSweetAlert, getSessionItem } from 'utils/utils';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import { config } from 'utils/config';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import { Column } from 'primereact/column';
import moment from 'moment';
import { Dialog } from 'primereact/dialog';
import { Row, Col, Label, FormGroup, Card, CardBody, CardHeader } from 'reactstrap'
import FieldText from 'components/Controls/FieldText';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldCalendar from 'components/Controls/FieldCalendar';
import FieldTextArea from 'components/Controls/FieldTextArea';
import FieldCheckbox from 'components/Controls/FieldCheckbox';
import FieldGalleria from 'components/Controls/FieldGalleria/FieldGalleria'
import FieldUpload from 'components/Controls/FieldUpload/FieldUpload';
import YupValidator from 'utils/YupValidator';
import * as yup from 'yup';

const ITTicketDetails = (props) => {
  const {t, i18n} = useTranslation(['it', 'common']);
  const [tipoSoportes, setTipoSoportes] = useState([]); // Support type
  const [hour, setHour] = useState(props.ticket.HorasSolucion); // Priority hour
  const [descripcionSolucion, setDescripcionSolucion] = useState(props.ticket.DescripcionSolucion); // Solution description
  const [descripcionSolucionInterna, setDescripcionSolucionInterna] = useState(props.ticket.DescSolucionInterna); // Internal solution description
  const [tipoSoporte, setTipoSoporte] = useState(props.ticket.ClaTipoSoporte); // Selected support type
  const [esRecurrente, setEsRecurrente] = useState(props.ticket.EsRecurrente === 1); // Is recurrent checkbox
  const [images, setImages] = useState([]); // Images
  const [showImages, setShowImages] = useState(false); // Images dialog
  const [archivoSolucion, setArchivoSolucion] = useState([]); // Solution Image and extension
  const [showSolucionImageUpload, setShowSolucionImageUpload] = useState(false); // Show support image upload dialog
  const [priorities, setPriorities] = useState([]); // Priorities list
  const [claPrioridadIncidenteHlp, setClaPrioridadIncidenteHlp] = useState(props.ticket.ClaPrioridadIncidenteHlp); // Selected priority
  const [fechaPromesa, setFechaPromesa] = useState(props.ticket.FechaPromesa); // Promise Date
  const [errors, setErrors] = useState([]); // Errors
  const [currentTicket, setCurrentTicket] = useState(props.ticket); // Ticket
  const [usuarioSolucionas, setUsuarioSolucionas] = useState([]); // IT Users
  const [isITUser, setIsITUser] = useState(props.isITUser);

  useEffect(() => {
    GetTipoSoporte();
    GetPrioridadIncidenteHlpUSA();
    getITUsers();
    getImages();
    console.log(props);
  }, [])

  // Get the list of IT users
  const getITUsers = () => {
    const urlWebService = `${config.UrlApiProject}IT/GetITTicketUsersUSA`;
    const paramsService = {};

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setUsuarioSolucionas(response.data.Users);
    });
  }

  // Get the ticket images
  const getImages = () => {
    const urlWebService = `${config.UrlApiProject}IT/GetITTicketImages?IdSolicitudSoporte=${props.ticket.IdSolicitudSoporte}`;
    const paramsService = {};

    callApi(urlWebService, 'GET', paramsService, (response) => {
      const newImages = response.data.Images;
      
      const ticketImages = [];

      if (newImages.ArchivoSoporte !== null) {
        const image = {};
        image.Image = newImages.ArchivoSoporte;
        image.ImageExt = newImages.ArchivoSoporteExt;
        ticketImages.push(image);
      }

      if (newImages.ArchivoSoporte2 !== null) {
        const image = {};
        image.Image = newImages.ArchivoSoporte2;
        image.ImageExt = newImages.ArchivoSoporteExt2;
        ticketImages.push(image);
      }

      if (newImages.ArchivoSoporte3 !== null) {
        const image = {};
        image.Image = newImages.ArchivoSoporte3;
        image.ImageExt = newImages.ArchivoSoporteExt3;
        ticketImages.push(image);
      }

      if (newImages.ArchivoSoporte4 !== null) {
        const image = {};
        image.Image = newImages.ArchivoSoporte4;
        image.ImageExt = newImages.ArchivoSoporteExt4;
        ticketImages.push(image);
      }

      if (newImages.ArchivoSolucion !== null) {
        const image = {};
        image.Image = newImages.ArchivoSolucion;
        image.ImageExt = newImages.ArchivoSolucionExt;
        ticketImages.push(image);

        currentTicket.ArchivoSolucion = image.Image;
        currentTicket.ArchivoSolucionExt = image.ImageExt;
      }
      else {
        currentTicket.ArchivoSolucion = null;
        currentTicket.ArchivoSolucionExt = null;
      }

      console.log(currentTicket);

      setImages(ticketImages);
    });
  }

  // Get Promise Date
  const getPromiseDate = (newPriority) => {
    const urlWebService = `${config.UrlApiProject}IT/GetPromiseDate?ClaPrioridadIncidenteHlp=${newPriority}`;
    const paramsService = {};

    callApi(urlWebService, 'GET', paramsService, (response) => {
      console.log(response.data.parameters.pdFechaPromesa)
      setFechaPromesa(response.data.parameters.pdFechaPromesa === undefined ? null : response.data.parameters.pdFechaPromesa);
    });
  }


  // Get the Priorities
  const GetPrioridadIncidenteHlpUSA = () => {
    const urlWebService = `${config.UrlApiProject}IT/GetPrioridadIncidenteHlpUSA`;
    const paramsService = {
    };

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setPriorities(response.data.PrioridadIncidenteHlp);
    });
  }

  // Get the support types
  const GetTipoSoporte = () => {
    const urlWebService = `${config.UrlApiProject}IT/GetTipoSoporteUSA`;
    const paramsService = {
    };

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setTipoSoportes(response.data.TipoSoporte);
    });
  }

  // Handle support image upload button click
  const onAddSolutionImageClick = () => {
    setShowSolucionImageUpload(true);
  }

  // Handle Is Recurrent click
  const esRecurrenteChanged = (e) => {
    const newErrors = {...errors};
    delete newErrors.esRecurrente;
    setErrors(newErrors);
    setEsRecurrente(e.checked);
  }

  // Handle the priority change
  const onPriorityChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.claPrioridadIncidenteHlp;
    setErrors(newErrors);
    setClaPrioridadIncidenteHlp(e.value);
    getHour(e.value)
    getPromiseDate(e.value);
  }

  // Handle support type change
  const onChangeTipoSoporte = (e) => {
    const newErrors = {...errors};
    delete newErrors.tipoSoporte;
    setErrors(newErrors);
    setTipoSoporte(e.value);
  }

  // Handle assigned user change
  const onChangeUsuarioSoluciona = (e) => {
    const newErrors = {...errors};
    delete newErrors.usuarioSoluciona;
    setErrors(newErrors);
    setUsuarioSoluciona(e.value);
  }

  // Handle description change
  const onDescripcionSolucionChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.descripcionSolucion;
    setErrors(newErrors);
    setDescripcionSolucion(e.target.value);
  }

  // Hand internal description change
  const onDescripcionSolucionInternaChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.descripcionSolucionInterna;
    setErrors(newErrors);
    setDescripcionSolucionInterna(e.target.value);
  }

  // Get the selected priority hours
  const getHour = (ClaPrioridadIncidenteHlp) => {
    if (ClaPrioridadIncidenteHlp === undefined) {
      setHour('');
    }
    else {
      priorities.forEach((p) => {
        if (p.ClaPrioridadIncidenteHlp === ClaPrioridadIncidenteHlp) {
          setHour(p.HorasSolucion);
        }
      });
    }

  }

  // Save the support ticket
  const onSaveClick = async (e) => {
    console.log(claPrioridadIncidenteHlp);

    const validationScheme = yup.object().shape({
      claPrioridadIncidenteHlp: yup.number().required(t('common:RequiredField')).integer().nullable(),
      tipoSoporte: yup.number().required(t('common:RequiredField')).integer().nullable()
    });

    const value = {
      descripcionSolucion,
      descripcionSolucionInterna,
      claPrioridadIncidenteHlp,
      tipoSoporte
    };
    const validator = new YupValidator(validationScheme);
    const isValid = await validator.validate(value);
    if (!isValid) {
      setErrors(validator.errors);
    }
    else {
      const urlWebService = `${config.UrlApiProject}IT/UpdateITSupportTicketUSA`;
      const paramsService = {
        IdSolicitudSoporte: currentTicket.IdSolicitudSoporte,
        ClaEstatusSolicitud: 6,
        DescripcionSolucion: descripcionSolucion,
        DescSolucionInterna: descripcionSolucionInterna,
        EsRecurrente: esRecurrente === true ? 1 : 0,
        ClaPrioridadIncidenteHlp: claPrioridadIncidenteHlp,
        ClaTipoSoporte: tipoSoporte,
      };

      callApi(urlWebService, 'POST', paramsService, (response) => {
        showSweetAlert("Success", t('it:TicketSaved'), 'success');
        props.onDetailsHide();
      });
    }

  }

  // Handle Reject button click
  const onRejectClick = async () => {
    const validationScheme = yup.object().shape({
      claPrioridadIncidenteHlp: yup.number().required(t('common:RequiredField')).integer().nullable(),
      tipoSoporte: yup.number().required(t('common:RequiredField')).integer().nullable(),
      descripcionSolucion: yup.string().trim().required(t('common:RequiredField')).nullable(),
      descripcionSolucionInterna: yup.string().trim().required(t('common:RequiredField')).nullable()
    });

    const value = {
      descripcionSolucion,
      descripcionSolucionInterna,
      claPrioridadIncidenteHlp,
      tipoSoporte
    };
    const validator = new YupValidator(validationScheme);
    const isValid = await validator.validate(value);
    if (!isValid) {
      setErrors(validator.errors);
    }
    else {
      const urlWebService = `${config.UrlApiProject}IT/UpdateITSupportTicketUSA`;
      const paramsService = {
        IdSolicitudSoporte: currentTicket.IdSolicitudSoporte,
        ClaEstatusSolicitud: 7,
        DescripcionSolucion: descripcionSolucion,
        DescSolucionInterna: descripcionSolucionInterna,
        EsRecurrente: esRecurrente === true ? 1 : 0,
        ClaPrioridadIncidenteHlp: claPrioridadIncidenteHlp,
        ClaTipoSoporte: tipoSoporte,
      };

      callApi(urlWebService, 'POST', paramsService, (response) => {
        showSweetAlert("Success", t('it:TicketRejected'), 'success');
        props.onDetailsHide();
      });
    }
  }

  // Handle Complete button click
  const onCompleteClick = async () => {
    console.log(claPrioridadIncidenteHlp)

    const validationScheme = yup.object().shape({
      claPrioridadIncidenteHlp: yup.number().required(t('common:RequiredField')).integer().nullable(),
      tipoSoporte: yup.number().required(t('common:RequiredField')).integer().nullable(),
      descripcionSolucion: yup.string().trim().required(t('common:RequiredField')).nullable(),
      descripcionSolucionInterna: yup.string().trim().required(t('common:RequiredField')).nullable()
    });

    const value = {
      descripcionSolucion,
      descripcionSolucionInterna,
      claPrioridadIncidenteHlp,
      tipoSoporte
    };
    const validator = new YupValidator(validationScheme);
    const isValid = await validator.validate(value);
    if (!isValid) {
      setErrors(validator.errors);
    }
    else {
      const urlWebService = `${config.UrlApiProject}IT/UpdateITSupportTicketUSA`;
      const paramsService = {
        IdSolicitudSoporte: currentTicket.IdSolicitudSoporte,
        ClaEstatusSolicitud: 8,
        DescripcionSolucion: descripcionSolucion,
        DescSolucionInterna: descripcionSolucionInterna,
        EsRecurrente: esRecurrente === true ? 1 : 0,
        ClaPrioridadIncidenteHlp: claPrioridadIncidenteHlp,
        ClaTipoSoporte: tipoSoporte,
      };

      console.log(paramsService);

      callApi(urlWebService, 'POST', paramsService, (response) => {
        showSweetAlert("Success", t('it:TicketCompleted'), 'success');
        props.onDetailsHide();
      });
    }
  }

  // Handle submitting solution image
  const onSubmitSolutionImageClick = () => {
    let solutionImage = currentTicket.ArchivoSolucion;
    let solutionExt = currentTicket.ArchivoSolucionExt;

    if (archivoSolucion.length > 0) {
      solutionImage = archivoSolucion[0].FileData;
      solutionExt = archivoSolucion[0].FileExtension.replace('.', '').trim();
    }

    const urlWebService = `${config.UrlApiProject}IT/UpdateArchivoSolucion`;
    const paramsService = {
      IdSolicitudSoporte: currentTicket.IdSolicitudSoporte,
      ArchivoSolucion: solutionImage,
      ArchivoSolucionExt: solutionExt
    };

    console.log(paramsService)

    callApi(urlWebService, 'POST', paramsService, (response) => {
      setShowSolucionImageUpload(false);
      getImages();
      showSweetAlert("Success", t('it:SolutionImageAdded'), 'success');

      setArchivoSolucion([])
    });
  }

  // Template for Galleria
  const imageTemplate = (item) => {
    if (item.ImageExt !== undefined && item.Image !== undefined) {
      let imgType = item.ImageExt.toLowerCase();

      switch (item.ImageExt.toLowerCase()) {
        case 'jpg':
        case 'jfif':
        case 'pjpeg':
        case 'pjp':
          imgType = 'jpeg'
          break;
        case 'svg':
          imgType = 'svg+xml';
          break;
        default:
          imgType = item.ImageExt.toLowerCase();
      }

      return (
        <img src={`data:image/${imgType};base64,${item.Image}`} alt='' style={{height: '32vw', maxWidth:'75vw', display: 'block'}} />
      )
    }

    return (
      <></>
    )
  }

  // Thumbnail for galleria
  const thumbNailTemplate = (item) => {
    if (item.ImageExt !== undefined && item.Image !== undefined) {
      let imgType = item.ImageExt.toLowerCase();

      switch (item.ImageExt.toLowerCase()) {
        case 'jpg':
        case 'jfif':
        case 'pjpeg':
        case 'pjp':
          imgType = 'jpeg'
          break;
        case 'svg':
          imgType = 'svg+xml';
          break;
        default:
          imgType = item.ImageExt.toLowerCase();
      }

      return (
        <img src={`data:image/${imgType};base64,${item.Image}`} alt='' style={{maxHeight: '50px', width: '50px', display: 'block'}} />
      )
    }

    return (
      <></>
    )
  }

  // Footer for solution image upload dialog
  const solucionImageUploadFooter = () => {
    return (
      <FieldButton 
        label={t('common:Submit')}
        icon='pi pi-check'
        className='p-button-success'
        onClick={() => onSubmitSolutionImageClick()}
        disabled={archivoSolucion.length === 0 || archivoSolucion === null || archivoSolucion === []}
      />
    )
  }

  return (
    <>
      <Row className="p-fluid p-formgrid p-ai-end">
        <Col xs={3} sm={3} md={2} lg={2}>
          <Label>{t('it:ID')}</Label>
          <FieldText id='IdSolicitudSoporte' disabled={true} value={currentTicket.IdSolicitudSoporte} />
        </Col>
        <Col xs={3} sm={3} md={2} lg={2}>
          <Label>{t('it:Status')}</Label>
          <FieldText
            id='IdSolicitudSoporte'
            disabled={true}
            value={moment().isAfter(currentTicket.FechaPromesa) 
                    && currentTicket.ClaEstatusSolicitud === 6 
                    ? t('it:Late') 
                    : currentTicket.NomEstatusSolicitudIng}
          />
        </Col>
        <Col xs={6} sm={6} md={4} lg={4} className='p-field'>
          <Label>{t('it:Requester')}</Label>
          <FieldText id='Requester' disabled={true} value={currentTicket.NombreCompletoSolicita} />
        </Col>
        <Col xs={6} sm={6} md={4} lg={4} className='p-field'>
          <Label>{t('it:AssignedTo')}</Label>
          <FieldText id='AssignedTo' disabled={true} value={currentTicket.NombreCompletoSoluciona} />
        </Col>
        <Col xs={6} sm={6} md={4} lg={4} className='p-field'>
          <Label>{t('it:SupportType')}</Label>
          <FieldDropdown
            id='tipoSoporte'
            name="tipoSoporte"
            value={tipoSoporte}
            optionValue="ClaTipoSoporte"
            optionLabel="NomTipoSoporte"
            options={tipoSoportes}
            onChange={onChangeTipoSoporte}
            errors={errors}
            disabled={(currentTicket.ClaEstatusSolicitud !== 6) || !props.isITUser}
          >
          </FieldDropdown>
        </Col>
        <Col xs={6} sm={6} md={4} lg={4} className='p-field'>
          <Label>{t('it:Priority')}</Label>
          <FieldDropdown
            id='Priority'
            name="claPrioridadIncidenteHlp"
            value={claPrioridadIncidenteHlp}
            optionValue="ClaPrioridadIncidenteHlp"
            optionLabel="NomPrioridadIncidenteHlp"
            options={priorities}
            onChange={onPriorityChange}
            errors={errors}
            disabled={(currentTicket.ClaEstatusSolicitud !== 6) || !props.isITUser}
          >
          </FieldDropdown>
        </Col>
        <Col xs={6} sm={6} md={4} lg={4} className='p-field'>
          <Label>{t('it:Hours')}</Label>
          <FieldText id='Hours' value={hour} disabled={true} />
        </Col>
        <Col xs={6} sm={6} md={4} lg={4} className='p-field'>
          <Label>{t("it:EntryDate")}</Label>
          <FieldText disabled={true} value={moment(currentTicket.FechaCaptura).format("MM/DD/YYYY h:mm a")} />
        </Col>
        <Col xs={6} sm={6} md={4} lg={4} className='p-field'>
          <Label>{t("it:PromiseDate")}</Label>
          <FieldText disabled={true} value={moment(fechaPromesa).format("MM/DD/YYYY h:mm a")} />
        </Col>
        <Col xs={6} sm={6} md={4} lg={4} className='p-field'>
          <Label>{t("it:CompletedDate")}</Label>
          <FieldText disabled={true} value={currentTicket.FechaTerminado !== null ? moment(currentTicket.FechaTerminado).format("MM/DD/YYYY h:mm a") : ''} />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} className='p-field'>
          <Label>{t('common:Title')}</Label>
          <FieldText id='tituloSoporte' disabled={true} value={currentTicket.TituloSoporte} />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} className='p-field'>
          <Label>{t('common:Description')}</Label>
          <FieldTextArea id='descripcionSoporte' disabled={true} value={currentTicket.DescripcionSoporte} />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} className='p-field'>
          <Label>{t('it:URL')}</Label>
          <FieldText id='url' disabled={true} value={currentTicket.PageUrl} />
        </Col>
        <Col xs={12} sm={12} md={12} lg={props.isITUser ? 6 : 12} className='p-field'>
          {props.isITUser ?
            (
              <Label>{`${t('it:Solution')} - `} <span className="text-danger">{t('it:UserViewable')}</span></Label>
            ) : (<Label>{t('it:Solution')}</Label>) }
          <FieldTextArea
            id='descripcionSolucion'
            name="descripcionSolucion"
            value={descripcionSolucion}
            onChange={onDescripcionSolucionChange}
            errors={errors}
            disabled={(currentTicket.ClaEstatusSolicitud !== 6) || !props.isITUser}
            autoResize
            rows={5}
          >
          </FieldTextArea>
        </Col>
        {props.isITUser ? 
          (
            <Col xs={12} sm={12} md={12} lg={6} className='p-field'>
              <Label>{`${t('it:InternalSolution')} - `} <span className="text-success">{t('it:UserNotViewable')}</span></Label>
              <FieldTextArea
                id='descripcionSolucionInterna'
                name="descripcionSolucionInterna"
                value={descripcionSolucionInterna}
                onChange={onDescripcionSolucionInternaChange}
                errors={errors}
                disabled={currentTicket.ClaEstatusSolicitud !== 6}
                autoResize
                rows={5}
              >
              </FieldTextArea>
            </Col>
          ) : <></> }
      </Row>
      <Row>
        <Col xs={12} sm={6} md={12} lg={12} className='p-field'>
          <FieldButton 
            label={t('it:ViewImages')} 
            onClick={() => setShowImages(true)} 
            icon='pi pi-images'
            disabled={images.length === 0}
          >
          </FieldButton>
          { currentTicket.ClaEstatusSolicitud === 6 && (props.isITUser) ?
          (
            <>
              <FieldButton
                label={currentTicket.ArchivoSolucion === null ? t('it:AddSolutionImage') : t('it:ChangeSolutionImage')} 
                onClick={onAddSolutionImageClick} 
                icon='pi pi-plus'
                className='ml-2'
              >
              </FieldButton>
            </>
          ) : <></> }
        </Col>
      </Row>
      { currentTicket.ClaEstatusSolicitud === 6 && (props.isITUser) ?
        (
          <div className='p-d-flex p-jc-start p-flex-wrap'>
            <FieldButton label={t('common:Save')} onClick={onSaveClick} classname='' icon='pi pi-save' />
            <FieldButton label={t('it:Complete')} onClick={onCompleteClick} className='p-button-success ml-2' icon='pi pi-check' />
            <FieldButton label={t('it:Reject')} onClick={onRejectClick} className='p-button-danger ml-2' icon='pi pi-trash' />
          </div>
        ) : <></> }
      <Dialog
        visible={showImages}
        modal
        onHide={() => setShowImages(false)}
        resizable={true}
        // breakpoints={{'1500px': '75vw', '1000px': '100vw'}}
        style={{width: '100vw', height: '100vw'}}
        header='Images'
        className=''
      >
        <div className='p-d-flex p-ai-center p-jc-center'>
          <FieldGalleria
            value={images}
            item={imageTemplate}
            thumbnail={thumbNailTemplate}
            circular
            showItemNavigatorsOnHover={true}
            showItemNavigators={true}
          >
          </FieldGalleria>
        </div>
      </Dialog>
      <Dialog
        visible={showSolucionImageUpload}
        modal
        onHide={() => setShowSolucionImageUpload(false)}
        resizable={true}
        header={t('it:SolutionImageUpload')}
        footer={solucionImageUploadFooter}
      >
        <Row>
          <Col>
            <FieldUpload name='archivoSolucion' files={archivoSolucion} onChange={(files) => setArchivoSolucion(files)} />
          </Col>
        </Row>
      </Dialog>
    </>
  )
};

export default ITTicketDetails;
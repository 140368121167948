/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React  from 'react';
import PropTypes from 'prop-types';
import "./ListGroup.scss";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardImg,
    CardImgOverlay,
    CardTitle,
    CardText,
    ListGroupItem,
    ListGroup,
    Row,
    Col
  } from "reactstrap";
  

function ListItem ({
     color
    ,title  
    ,children
    ,onClick
    ,icon
  }) {  


    function onItemClick() { 
        if (onClick !== undefined && typeof(onClick) === "function"){
            onClick();
        } 
    }

    return (  
      <> 
        <div className={`li-group${  onClick ? " li-group-button" : ""}`} onClick={onItemClick}>
          <div>
            {
                icon === null || typeof(icon) === 'undefined' ? (<i className="pi pi-angle-right" style={{color}}></i>) : icon
              }  
          </div> 
          <span className="li-group-item">{title}</span> 
        </div>  
        <div className="pl-4 pt-1">
          {children}
        </div> 
      </> 
    )
}   

ListItem.propTypes = {
    color: PropTypes.string,
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.node
    ]), 
    onClick: PropTypes.func
}

export default ListItem; 

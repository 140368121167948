/* eslint-disable react/no-danger */
import React, { useState, useRef, useEffect  } from 'react';  
import { useTranslation } from 'react-i18next'; 
import PageContent from '../../../layouts/PageContent';  
import { Row, Col  } from 'reactstrap';     

const BusinessEnablingServices2024 = (props) => { 

  
  const { t } = useTranslation(['common']);
   

  return (
    <>   
      <PageContent title="2024 - Business Enabling Services" className='content-no-margin'>  
        <iframe
          title="Embedded HTML"
          src="/2024-BusinessEnablingServices.html"
          width="100%"
          height="100%" 
          frameBorder={0}
        /> 
      </PageContent> 
    </>
  );
};

export default BusinessEnablingServices2024;

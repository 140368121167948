/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  ListGroupItem,
  ListGroup,
  Progress,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label
} from 'reactstrap';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { Badge } from 'primereact/badge';
// import FeedbackCoaching from './FeedbackCoaching.jsx';
import { Link } from 'react-router-dom';
import ActionPlanScoreSquare from './ActionPlanScoreSquare.jsx';
import { Toolbar } from 'primereact/toolbar';
import Highcharts from 'highcharts';
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../../utils/utils';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable.jsx';
import { Column } from 'primereact/column';
import FieldButton from '../../../../components/Controls/FieldButton/FieldButton.jsx';
import { Dialog } from 'primereact/dialog';

const ActionPlantMonthKPIsPrev = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['focusReport', 'common']);
  const [ClaPuesto, setClaPuesto] = useState(null); 
  const [ClaAnio, setClaAnio] = useState(null); 
  const [ClaMes, setClaMes] = useState(null);  
  const [ActionPlanResultadoMonthKPIsPrevList, setActionPlanResultadoMonthKPIsPrevList] = useState([]);  
  const [EsActionPlan, setEsActionPlan] = useState(false);
  const [TotalActionPlanDetail, setTotalActionPlanDetail] = useState(null);
  const [NomCumplimiento, setNomCumplimiento] = useState(null);
  const [DetailActionPlanList, setDetailActionPlanList] = useState([]);  
  const [NombreMes, setNombreMes] = useState(null);  

  useEffect(() => {
    setClaPuesto(props.ClaPuesto === undefined ? 0 : props.ClaPuesto);
    setClaAnio(props.ClaAnio === undefined ? 0 : props.ClaAnio);
    setClaMes(props.ClaMes === undefined ? 0 : props.ClaMes);
    GetActionPlanResultadoMonthKPIsPrev(); 
  }, [props.ClaPuesto, props.ClaAnio, props.ClaMes]);

  const GetActionPlanResultadoMonthKPIsPrev = () => {
    const getActionPlanResultadoMonthKPIsPrev = `${config.UrlApiProject}ScoreCard/GetActionPlanResultadoMonthKPIsPrev`;
    const paramsToService = {
      ClaPuesto: props.ClaPuesto === undefined ? 0 : props.ClaPuesto,
      ClaAnio: props.ClaAnio === undefined ? 0 : props.ClaAnio,
      ClaMes: props.ClaMes === undefined ? 0 : props.ClaMes,
    };
    callApi(getActionPlanResultadoMonthKPIsPrev, 'POST', paramsToService, (response) => {
      const NomMes = response.data.MonthKPIsPrev.length > 0 ? response.data.MonthKPIsPrev[0] : {};
      console.log(response.data);
      setNombreMes(NomMes.NombreMes);
      setActionPlanResultadoMonthKPIsPrevList(response.data.ActionPlanMonthKPIsPrev);
    });
  };

  const actionBodyEsReqPlanAccion = (rowData) => {
    return (
      <>
       
        { rowData.EsReqPlanAccion === 1  
            ?  <i className="fa fa-check light-green-color"></i>
            :  
            /* <i className="fas fa-times danger-color"></i> */
            <></>}
      </>
    );
  }

  const actionBodyEsTieneAP = (rowData) => {
    return (
      <>
       
        { rowData.EsTieneAP === 1  
            ?  <i className="fa fa-check light-green-color"></i>
            :  
            rowData.EsReqPlanAccion === 1 ? <i className="fas fa-times danger-color"></i> : <></>}
      </>
    );
  }

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col className="text-center">
              <h2 className="title-ActionPlan">{t('focusReport:KPIsPrev')} {NombreMes} (Previous)</h2>
            </Col>
          </Row>
          {/* <Row className="align-items-start ">
            <Col lg={12} md={12} sm={12} xs={12} className="text-right">
              <h3><span className="title-ActionPlan-Sub">{t('focusReport:TotalScore')}</span>: {ActionPlanMonthResult !== null && typeof(ActionPlanMonthResult) === 'number' ?  Highcharts.numberFormat(ActionPlanMonthResult, 2, '.', ',') : ActionPlanMonthResult} %</h3>
            </Col>
          </Row> */}
          <Row className="align-items-start ">
            <Col lg={12} md={12} sm={12} xs={12}>
              <FieldDataTable
                value={ActionPlanResultadoMonthKPIsPrevList}
                scrollable 
                paginator={false} 
                className="table-header-background-blue"
              >
                <Column
                  field="NomIndicador"
                  header={t('focusReport:NomIndicador')}
                  columnKey="NomIndicador"
                  headerStyle={{ width: '145px' }}
                >
                </Column>
                <Column
                  field="ClaveIndicador"
                  className="text-center"
                  header={t('focusReport:KPICode')}
                  columnKey="ClaveIndicador"
                  headerStyle={{ width: '60px', textAlign: 'center' }}                  
                > 
                </Column>
                <Column
                  field="EsReqPlanAccion"
                  body={actionBodyEsReqPlanAccion}
                  className="text-center"
                  header={t('focusReport:NeedAP')}
                  columnKey="EsReqPlanAccion"
                  headerStyle={{ width: '40px', textAlign: 'center' }}
                >
                 
                </Column>
                <Column
                  field="EsTieneAP"
                  body={actionBodyEsTieneAP}
                  className="text-center"
                  header={t('focusReport:HasAP')}
                  columnKey="EsTieneAP"
                  headerStyle={{ width: '60px', textAlign: 'center' }}
                >
                 
                </Column>
              </FieldDataTable>
            </Col>
          </Row>
        </CardBody>
        <CardFooter hidden={!props.showCloseButtonKPIsPrev}>
          <Row>
            <Col className='text-center'>
              <FieldButton
                label={t('common:Close')}
                icon="fas fa-times"
                className="warning no-border p-mr-2 rounded-button"
                onClick={typeof(props.onCloseClickKPIsPrev) !== 'undefined' && typeof(props.onCloseClickKPIsPrev) === 'function' ? props.onCloseClickKPIsPrev : null}
              />
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  );
};

export default ActionPlantMonthKPIsPrev;

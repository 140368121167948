import React, { useState, useEffect } from 'react';
import {
  Card, 
  CardBody, 
  Row,
  Col, 
} from 'reactstrap'; 
import Button from '@material-ui/core/Button';
import Buckets from './Buckets/Buckets';
import Inconsistencies from './Inconsistencies/Incosistencies';
import Ballenas from './Ballenas/Ballenas';
import Oportunities from './Oportunities/Oportunities'; 
import BarChart from '@material-ui/icons/BarChart';
import CheckCircle from '@material-ui/icons/CheckCircle';
import MultilineChart from '@material-ui/icons/MultilineChart';
import Help from '@material-ui/icons/Help';
import { config } from '../../utils/config';
import { callApi } from '../../utils/utils'; 
import { Tag } from 'primereact/tag';
import { useTranslation } from 'react-i18next';

const SummaryReportsCharts = (props) => { 

  /* ################## useState area start ################## */
  const { t } = useTranslation(['formulario', 'common']);
  const [showBuckets, setShowBuckets] = useState(true);
  const [showInconsistencies, setShowInconsistencies] = useState(false);
  const [showBallenas, setShowBallenas] = useState(false);
  const [showOportunities, setShowOportunities] = useState(false);
  const [headerTitle, setHeaderTitle] = useState();
  const [claGrupoEstadistico3Value, setClaGrupoEstadistico3Value] = useState(''); 
  const [filters, setfilters] = useState({ ...props.filters });

  /* ################## End useState area start ################## */

  const clickShowBuckets = () => {
    setShowBuckets(true);
    setShowInconsistencies(false);
    setShowBallenas(false);
    setShowOportunities(false); 
  };
  const clickShowInconsistencies = () => {
    setShowBuckets(false);
    setShowInconsistencies(true);
    setShowBallenas(false);
    setShowOportunities(false); 
  };
  const clickShowBallenas = () => {
    setShowBuckets(false);
    setShowInconsistencies(false);
    setShowBallenas(true);
    setShowOportunities(false); 
  };
  const clickShowOportunities = () => {
    setShowBuckets(false);
    setShowInconsistencies(false);
    setShowBallenas(false);
    setShowOportunities(true); 
  };

  useEffect(() => {
    const {ClaGrupoEstadistico3, AnioMes, ClaGerenciaRegional, EsMostrarSoloConfiables, ClaMarketSegment } = props.filters;
    const GetFollowUpTitle = `${config.UrlApiProject}Rentabilidad/GetBucketFollowUpTitle`;
    const paramsToService = {
      anioMes: AnioMes,
      claGrupoEstadistico3: ClaGrupoEstadistico3,
      claGerenciaRegional: ClaGerenciaRegional,
      esMostrarSoloConfiables:EsMostrarSoloConfiables,
      claMarketSegment:ClaMarketSegment
    };

    callApi(GetFollowUpTitle, 'POST', paramsToService, (response) => {  
      setHeaderTitle(`${response.data.Titulo}`);
      setClaGrupoEstadistico3Value(ClaGrupoEstadistico3); 
      setfilters({...props.filters, NomFecha: response.data.NomFecha})
    });
  }, [props.filters]);
 
  return (
    <>
      <Card>   
        <CardBody>  
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <Tag value={headerTitle}></Tag> 
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12} className="text-right">
              <Button
                style={{ marginRight: '10px' }}
                onClick={() => {
                  clickShowBuckets();
                }}
                variant="contained"
                color={showBuckets ? 'primary' : 'default'}
                size="large" 
                startIcon={<MultilineChart />}
              >
                {t('formulario:Buckets')}
              </Button>
              <Button
                style={{ marginRight: '10px' }}
                onClick={() => {
                  clickShowInconsistencies();
                }}
                color={showInconsistencies ? 'primary' : 'default'}
                variant="contained"
                size="large" 
                startIcon={<Help />}
              >
                {t('formulario:Inconsistencies')}
              </Button>
              <Button
                style={{ marginRight: '10px' }}
                onClick={() => {
                  clickShowBallenas();
                }}
                color={showBallenas ? 'primary' : 'default'}
                variant="contained"
                size="large" 
                startIcon={<BarChart />}
              >
                {t('formulario:Whales')}
              </Button>
              <Button
                style={{ marginRight: '10px' }}
                onClick={() => {
                  clickShowOportunities();
                }}
                color={showOportunities ? 'primary' : 'default'}
                variant="contained"
                size="large" 
                startIcon={<CheckCircle />} 
              >
                {t('formulario:Oportunities')}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>&nbsp;</Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <Buckets showHide={showBuckets} filters={filters} />
              <Inconsistencies showHide={showInconsistencies} filters={filters} />
              <Ballenas showHide={showBallenas} filters={filters} />
              <Oportunities showHide={showOportunities} filters={filters} />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default SummaryReportsCharts;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next';   
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row,Col, TabContent, TabPane , Nav, NavItem, NavLink} from 'reactstrap';   
import { config } from '../../../utils/config';
import { callApi,showSweetAlert} from '../../../utils/utils.js'; 
import FieldTextArea  from '../../../components/Controls/FieldTextArea.jsx';
import FieldText  from '../../../components/Controls/FieldText.jsx';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldUpload from '../../../components/Controls/FieldUpload/FieldUpload.jsx';
import FieldCalendar  from 'components/Controls/FieldCalendar.jsx' 
import moment from "moment"; 
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import { Link } from 'react-router-dom';

const MisActividadesFinalizar = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['misActividades', 'common']);
  const [errors, setErrors] = useState({}); 
  const [filters, setfilters] = useState({});  
  const [NomProyecto, setNomProyecto] = useState(null); 
  const [NomFase, setNomFase] = useState(null); 
  const [NomActividad, setNomActividad] = useState(null); 
  const [Descripcion, setDescripcion] = useState(null); 
  const [ClaResponsable, setClaResponsable] = useState(null); 
  const [NomResponsable, setNomResponsable] = useState(null); 
  const [ClaAutorizador, setClaAutorizador] = useState(null); 
  const [NomAutorizador, setNomAutorizador] = useState(null); 
  const [FechaEstimadaInicio, setFechaEstimadaInicio] = useState(null); 
  const [FechaEstimadaFin, setFechaEstimadaFin] = useState(null); 
  const [FechaRealInicio, setFechaRealInicio] = useState(null); 
  const [FechaRealFin, setFechaRealFin] = useState(null); 
  const [ClaEstatus, setClaEstatus] = useState(null); 
  const [UploadFile, setUploadFile] = useState(null); 
  const [Comentarios, setComentarios] = useState(null); 
  const [ComentariosPM, setComentariosPM] = useState(null); 
  const [LinkEvidencia, setLinkEvidencia] = useState(null); 
  const [datafiles, setdatafiles] = useState([]);
  const [OpcionesResponsables, setOpcionesResponsables] = useState([]);
  const [OpcionesFaseEntregable, setOpcionesFaseEntregable] = useState([]);
  const [IdProyectoFaseEntregable, setIdProyectoFaseEntregable] = useState(null); 
  const [ClaResponsableFaseEntregable, setClaResponsableFaseEntregable] = useState(null); 
  const [DescripcionFaseEntregable, setDescripcionFaseEntregable] = useState(null); 
  const [FechaCompromisoFaseEntregable, setFechaCompromisoFaseEntregable] = useState(null); 
  const [DisabledProyectoFaseEntregable, setDisabledProyectoFaseEntregable] = useState(true);
  const [TabActiveIndex, setTabActiveIndex] = useState(1);
  const [EsHabilitaTabEntregable, setEsHabilitaTabEntregable] = useState(false);
  const [EsHabilitaTabAnexo, setEsHabilitaTabAnexo] = useState(false);

  useEffect(() => {    
    obtenerResponsablesProyecto();
    obtenerProyectoFaseEntregable();
    obtenerInformacionModal();
  }, []); 

  useEffect(() => {    
    // Solo entra cuando se actualiza fileUpload
    if(datafiles.length>0){
      const newErrors = { ...errors } 
      delete newErrors.LinkEvidencia;   
      delete newErrors.UploadFile;   
      setErrors(newErrors) 
    }
    setUploadFile(datafiles.length>0?1:null);
  }, [datafiles]); 

  const obtenerResponsablesProyecto = () => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/GetMswBSCColaboradorProyectosTeam`;  
    const paramsToService = { 
      ClaProyecto:props.ClaProyecto
    };   
    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => { 
      setOpcionesResponsables(response.data[0]); 
    });
  }

  const obtenerProyectoFaseEntregable = () => { 
    const GetUrlFaseEntregable = `${config.UrlApiProject}ScoreCard/GetProyectoFaseEntregable`;  
    const paramsToService = { 
      ClaProyecto:props.ClaProyecto,
      ClaFase:props.ClaFase
    };   
    callApi(GetUrlFaseEntregable, 'POST', paramsToService, (response) => { 
      setOpcionesFaseEntregable(response.data[0]);  
    });
  }

  const obtenerInformacionModal = () => { 
    const GetActivityURL = `${config.UrlApiProject}ScoreCard/GetFinalizarPendienteModalInfo`;  
    const paramsToService = { 
      ClaProyecto:props.ClaProyecto,
      ClaFase:props.ClaFase, 
      ClaActividad:props.ClaActividad
    };   
    callApi(GetActivityURL, 'POST', paramsToService, (response) => { 
      const results = response.data.Info[0];
        setNomActividad(results.NomActividad); 
        setDescripcion(results.Descripcion); 
        setClaResponsable(results.ClaResponsable); 
        setNomResponsable(results.NomResponsable); 
        setClaAutorizador(results.ClaAutorizador); 
        setNomAutorizador(results.NomAutorizador); 
        setFechaEstimadaInicio((results.FechaEstimadaInicio!== null ? (new Date(results.FechaEstimadaInicio)): null)); 
        setFechaEstimadaFin((results.FechaEstimadaFin!== null ? (new Date(results.FechaEstimadaFin)): null)); 
        setFechaRealInicio((results.FechaRealInicio!== null ? (new Date(results.FechaRealInicio)): null)); 
        setFechaRealFin((results.FechaRealFin!== null ? (new Date(results.FechaRealFin)): null)); 
        setComentarios(results.Comentarios);
        setComentariosPM(results.ComentariosPM);
        setClaEstatus(results.ClaEstatus);
        setIdProyectoFaseEntregable(results.IdProyectoFaseEntregable);
        setNomProyecto(results.NomProyecto);
        setNomFase(results.NomFase); 
        setIdProyectoFaseEntregable(results.IdProyectoFaseEntregable);
        setClaResponsableFaseEntregable(results.ClaResponsableEntregable);
        setDescripcionFaseEntregable(results.DescripcionEntregable);
        setFechaCompromisoFaseEntregable((results.FechaCompromiso!== null ? (new Date(results.FechaCompromiso)): null));
        
        setEsHabilitaTabEntregable(props.ClaProyecto>0);
        setEsHabilitaTabAnexo(true);

        if(props.ClaProyecto<0){
          setTabActiveIndex(2);
        }
    });
  }

  const onChangeComentarios = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.Comentarios;   
    setErrors(newErrors) 
    setComentarios(e.target.value)
  }

  const onChangeComentariosPM = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.ComentariosPM;   
    setErrors(newErrors) 
    setComentariosPM(e.target.value)
  }


  const onFechaRealInicioChange = ( e ) => {  
    const newErrors = { ...errors }
    delete newErrors.FechaRealInicio;   
    setErrors(newErrors);
    setFechaRealInicio(e.value);
  }; 

  const onFechaRealFinChange = ( e ) => {  
    const newErrors = { ...errors }
    delete newErrors.FechaRealFin;   
    setErrors(newErrors);
    setFechaRealFin(e.value);
  }; 

  const onIdProyectoFaseEntregableChange = (e) => {
    const newErrors = { ...errors } 
    delete newErrors.IdProyectoFaseEntregable;   
    setErrors(newErrors) 
    setDisabledProyectoFaseEntregable(true);
    setIdProyectoFaseEntregable(e.value);
    if(e.value===undefined){
      setClaResponsableFaseEntregable(null);
      setDescripcionFaseEntregable(null);
      setFechaCompromisoFaseEntregable(null);
    }else{
      const GetUrlFaseEntregable = `${config.UrlApiProject}ScoreCard/GetProyectoFaseEntregableId`;  
      const paramsToService = { 
        IdProyectoFaseEntregable:e.value
      };   
      callApi(GetUrlFaseEntregable, 'POST', paramsToService, (response) => { 
        const proyectoFaseEntregable = response.data[0];
        if (proyectoFaseEntregable.length >0)
        {  
          if(proyectoFaseEntregable[0]!==undefined){
            setClaResponsableFaseEntregable(proyectoFaseEntregable[0].ClaResponsable);
            setDescripcionFaseEntregable(proyectoFaseEntregable[0].Descripcion);
            setFechaCompromisoFaseEntregable((proyectoFaseEntregable[0].FechaCompromiso!== null ? (new Date(proyectoFaseEntregable[0].FechaCompromiso)): null));
          }
        }  
      }); 
    }
  };

  const onClaResponsableFaseEntregableChange = (props, e ) => {
    const newErrors = { ...errors } 
    delete newErrors.ClaResponsableFaseEntregable;   
    setErrors(newErrors) 
    setClaResponsableFaseEntregable(e.value)
  }; 

  const onFechaCompromisoFaseEntregableChange = ( e ) => {  
    const newErrors = { ...errors } 
    delete newErrors.FechaCompromisoFaseEntregable;   
    setErrors(newErrors) 
    setFechaCompromisoFaseEntregable(e.value)
  }; 

  const onChangeLinkEvidencia = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.LinkEvidencia;   
    delete newErrors.UploadFile;   
    setErrors(newErrors) 
    setLinkEvidencia(e.target.value)
  } 
 
  const valitationSchemeFinish = yup.object().shape({
    FechaRealInicio:yup.date().required(t('common:RequiredField')).nullable(),
    FechaRealFin:yup.date().required(t('common:RequiredField')).nullable()
  });  

  const TerminarActividad = async () => {
    const value = {
      FechaRealInicio,
      FechaRealFin
    }
    const validator = new YupValidator(valitationSchemeFinish);
    const esValido = await validator.validate(value);
    if (!esValido){ 
      setErrors(validator.errors); 
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      if(FechaRealInicio === null || FechaRealFin === null){
        if(props.ClaProyecto<0){
          setTabActiveIndex(2);
        }else{
          setTabActiveIndex(1);
        }
      }
      else{
        setTabActiveIndex(2);
      }
      return;
    }
     // Envia a segundo Tab siempre y cuando haya capturado los requeridos del primer Tab
     // Requerido adjuntar archivo o link si hay un entregable
    if(IdProyectoFaseEntregable > 0 && (LinkEvidencia === null || LinkEvidencia === '')  && (datafiles.length === 0)){
      showSweetAlert(t('common:Warning'), t('misActividades:RequiredAttachmentField'), 'warning');
      setTabActiveIndex(2);
      return;
    }
    const urlFinalizarService = `${config.UrlApiProject}ScoreCard/FinalizarActividadesFases`;
    const paramsService = {
      ClaProyecto:props.ClaProyecto,
      ClaFase:props.ClaFase, 
      ClaActividad:props.ClaActividad,
      FechaRealInicio:(FechaRealInicio!== null ? (new Date(FechaRealInicio)).toDateString(): null),
      FechaRealFin:(FechaRealFin!== null ? (new Date(FechaRealFin)).toDateString(): null),
      FileData:(datafiles.length > 0 ? datafiles[0].FileData : null),
      NomArchivo:(datafiles.length > 0 ? datafiles[0].FileName: null),
      Extension:(datafiles.length > 0 ? datafiles[0].FileExtension: null),
      Comentarios,
      IdProyectoFaseEntregable,
      Link:LinkEvidencia
    };  

    callApi(urlFinalizarService, 'POST', paramsService, (response) => { 
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      if(props.onClickCloseFinalizar){
        props.onClickCloseFinalizar();
      }
    });
  }

  const switchTab = tab => {
    if(TabActiveIndex !== tab){
      setTabActiveIndex(tab);
    }
  }

    return (
      <>     
        <Row className="pt-1 bold">
          <Col lg={6} md={6} sm={6} xs={6}>
            <Row className="pt-1 bold">
              <Col lg={12} md={12} sm={12} xs={12}>
                {NomActividad} 
              </Col>   
            </Row> 
            <Row className="pt-1 bold">
              <Col lg={12} md={12} sm={12} xs={12}> 
                <span className="fa fa-folder"> </span> {NomProyecto} 
                <span hidden={NomFase===null || NomFase === ''}>- {NomFase}</span>
              </Col>  
            </Row> 
            <Row className="pt-1 bold">
              <Col lg={12} md={12} sm={12} xs={12}> 
                <span className="bold"> 
                  <i className="pi pi-calendar"></i> &nbsp; 
                  {moment(FechaEstimadaInicio).format("ll")} 
                </span>
                <span>
                  - {moment(FechaEstimadaFin).format("ll")}
                </span>
              </Col>  
            </Row> 
          </Col>  
          <Col lg={6} md={6} sm={6} xs={6}> 
            <Row className="pt-2">
              <Col lg={6} md={6} sm={6} xs={6} className="secondary-color">
                <div className="p-fluid">
                  <div className="p-field"> 
                    <FieldCalendar
                      name="FechaRealInicio" 
                      label={t('misActividades:StartDate')}
                      value={(FechaRealInicio!== null ? (new Date(FechaRealInicio)): null)} 
                      onChange={(e) => onFechaRealInicioChange(e)} 
                      errors={errors}
                    />
                  </div> 
                </div> 
              </Col>  
              <Col lg={6} md={6} sm={6} xs={6}> 
                <div className="p-fluid">
                  <div className="p-field"> 
                    <FieldCalendar
                      name="FechaRealFin" 
                      label={t('misActividades:FechaFin')}
                      value={(FechaRealFin!== null ? (new Date(FechaRealFin)): null)} 
                      onChange={(e) => onFechaRealFinChange(e)} 
                      errors={errors}
                    />
                  </div> 
                </div> 
              </Col>  
            </Row>
          </Col>  
        </Row>   
        <Row className="pt-1">
          <Col lg={6} md={6} sm={6} xs={6} className="secondary-color">
            <div className="p-fluid" hidden={props.ClaProyecto === -997 || props.ClaProyecto === -998}>
              <span className="pb-1">
                {t('misActividades:PMComments')}
              </span>
              <FieldTextArea 
                name="ComentariosPM"
                rows={2}
                value={ComentariosPM} 
                readonly={true}
                onChange={onChangeComentariosPM}
                errors={errors}
              />
            </div>
          </Col>  
          <Col lg={6} md={6} sm={6} xs={6}> 
            <div className="p-fluid">
              <span className="pb-1">
                {t('misActividades:Comments')}
              </span>
              <FieldTextArea 
                name="Comentarios"
                rows={2}
                value={Comentarios} 
                onChange={onChangeComentarios}
                errors={errors}
                maxLength={8000}
              /> 
            </div>
          </Col>  
        </Row>  
        <Row>
          <Col>
            <div className="p-tabview p-component pb-3">
              <Nav className="p-tabview-nav">
                <NavItem hidden={!EsHabilitaTabEntregable} className={TabActiveIndex === 1 ? "p-tabview-selected p-highlight p-unselectable-text nav-item flex-sm-fill" : "p-unselectable-text nav-item flex-sm-fill"}>
                  <NavLink className="p-tabview-nav-link" onClick={() => { switchTab(1); }}>
                    {t('misActividades:Deliverable')}
                  </NavLink>
                </NavItem>
                <NavItem hidden={!EsHabilitaTabAnexo} className={TabActiveIndex === 2 ? "p-tabview-selected p-highlight p-unselectable-text nav-item flex-sm-fill" : "p-unselectable-text nav-item flex-sm-fill"}>
                  <NavLink className="p-tabview-nav-link" onClick={() => { switchTab(2); }}>
                    {t('misActividades:Attachment')}
                  </NavLink>
                </NavItem>
              </Nav> 
            </div> 
            <TabContent className="p-tabview-panels" activeTab={TabActiveIndex}>
              <TabPane tabId={1} hidden={!EsHabilitaTabEntregable}> 
                <Row className="pt-1">
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="p-fluid">
                      <FieldDropdown
                        name="IdProyectoFaseEntregable"
                        value={IdProyectoFaseEntregable}
                        options={OpcionesFaseEntregable}  
                        optionValue="IdProyectoFaseEntregable"
                        optionLabel="Descripcion" 
                        placeholder={t('misActividades:Deliverable')}
                        onChange={(e) => onIdProyectoFaseEntregableChange(e)}
                        errors={errors}
                      />   
                    </div>
                  </Col>  
                </Row> 
                <Row hidden={IdProyectoFaseEntregable===0}>
                  <Col lg={12} md={12} sm={12} xs={12} className="secondary-color">
                  &nbsp;
                  </Col>
                </Row>
                <Row className="pt-2" hidden={IdProyectoFaseEntregable===0}>
                  <Col lg={6} md={6} sm={6} xs={6} className="secondary-color">
                    <div className="p-fluid">
                      <FieldCalendar
                        name="FechaCompromisoFaseEntregable" 
                        label={t('scoreCard:EstimatedDate')}
                        value={(FechaCompromisoFaseEntregable!== null ? (new Date(FechaCompromisoFaseEntregable)): null)}
                        onChange={(e) => onFechaCompromisoFaseEntregableChange(e)}
                        disabled={DisabledProyectoFaseEntregable}
                        errors={errors}
                      />  
                    </div>
                  </Col>  
                  <Col lg={6} md={6} sm={6} xs={6}> 
                    <div className="p-fluid">
                      <FieldDropdown  
                        name="ClaResponsableFaseEntregable"
                        value={ClaResponsableFaseEntregable}
                        options={OpcionesResponsables}  
                        optionValue="ClaColaborador"
                        optionLabel="NomColaborador" 
                        label={t('scoreCard:Responsible')}
                        disabled={DisabledProyectoFaseEntregable}
                        onChange={(e) => onClaResponsableFaseEntregableChange(props,e)}
                        errors={errors}
                      />
                    </div>
                  </Col>  
                </Row>
              </TabPane>
              <TabPane tabId={2} hidden={!EsHabilitaTabAnexo}>
                <Row>
                  <Col>
                    <span>
                      {t('misActividades:File')}
                    </span> 
                    <FieldUpload 
                      name="UploadFile"
                      files={datafiles} 
                      onChange={(files) => setdatafiles(files)}
                      errors={errors}
                    >
                    </FieldUpload>
                  </Col>
                </Row>
                <Row className="pt-1">
                  <Col>
                    <div className="p-fluid"> 
                      <FieldText   
                        placeholder={t('misActividades:Link')}
                        name="LinkEvidencia"
                        value={LinkEvidencia} 
                        onChange={onChangeLinkEvidencia}
                        maxLength={200}
                        errors={errors}
                      />
                    </div>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Col>
        </Row> 
        <Row className="pt-1">
          <Col>
            <div className="p-fluid"> 
             &nbsp;
            </div>
          </Col>
        </Row>
        <Row className="pt-1">
          <Col className="text-right">
            <FieldButton label={t('misActividades:Finish')} onClick={() => TerminarActividad()} className="success no-border pr-3 pl-3 btn-min-width"  /> 
              
          </Col>
        </Row>
      </> 
        ); 
};
export default MisActividadesFinalizar;

import React, { useState, useEffect } from 'react';
import {
  Card, 
  CardBody, 
  Row,
  Col, 
  label,
  TabContent, 
  TabPane, 
  Nav, 
  NavItem, 
  NavLink
} from 'reactstrap'; 
import Button from '@material-ui/core/Button';
import AppsIcon from '@material-ui/icons/Apps';
import Help from '@material-ui/icons/Help';
import { config } from '../../utils/config';
import { callApi } from '../../utils/utils'; 
import { Tag } from 'primereact/tag';
import { useTranslation } from 'react-i18next';
import AnalisisCostosFilters from './AnalisisCostosFilters.js';
import ElementosCostos from './ElementosCostos/ElementosCostos';
import InconsistenciasCostos from './InconsistenciasCostos/InconsistenciasCostos';
import CeldasCostos from './CeldasCostos/CeldasCostos'; 
import FieldSelectButton from '../../components/Controls/FieldSelectButton.jsx' 
import AnalisisCostosGeneral from './AnalisisCostosGeneral/AnalisisCostosGeneral';
import { TabView, TabPanel } from 'primereact/tabview';
import classnames from 'classnames';
import PageContent from '../../layouts/PageContent'; 

const AnalisisCostos = (props) => { 
  /* ################## useState area start ################## */
const { t } = useTranslation(['formulario', 'common']);
const [headerTitle, setHeaderTitle] = useState();
const [showGeneral, setshowGeneral] = useState(true);
const [showElements, setshowElements] = useState(false);
const [showInconsistencias, setshowInconsistencias] = useState(false);
const [showCells, setshowCells] = useState(false);
const [showContent, setshowContent] = useState(false);
const [filters, setfilters] = useState({});  
const [OpcionesCtaContable, setOpcionesCtaContable] = useState([{ value: 2, name: "DEACERO"}, { value: 1, name: "MSW" }]);
const [CuentaContable, setCuentaContable] = useState(1);
const [activeIndex, setActiveIndex] = useState(1);
const [TourType, setTourType] = useState(0);
 
  /* ################## End useState area start ################## */

  useEffect(() => { 
    const filtersData = {};
    filtersData.Anio = null;
    filtersData.Mes = null;
    filtersData.AnioMes = null;
    filtersData.ClaTipoElementoCosto = null;
    filtersData.ClaDepartamento = null;
    filtersData.ClaCelda = null;
    filtersData.ClaTipoCelda = null;
    filtersData.ClaArticulo = null;
    filtersData.Target = null;
    setfilters(filtersData); 

  }, []);

  const onSearch = (dataFilter) => {
      dataFilter.ClaTipoElementoCosto = CuentaContable;
      setfilters(dataFilter);
      setshowContent(true);
 
      setTourType(activeIndex);

      const GetCeldaTitleService = `${config.UrlApiProject}Costos/GetCeldaElementTitle`;  
      callApi(GetCeldaTitleService, 'POST', dataFilter, (response) => { 
        if(response.data.info!==undefined)
        {  
          setHeaderTitle(`${response.data.info[0].NomFecha }`);  
        }     
        });
  } 
   
  const setSelectCtaContable = ( value ) => {  
    setCuentaContable(value);  
    setfilters({ ...filters, ClaTipoElementoCosto : value});
  };  
 
  const onChangeFilter = () => { 
    setshowContent(false);

  }
 
  const clickShowElements = () => {
    setshowElements(true);
    setshowInconsistencias(false); 
    setshowCells(false); 
    // setshowGeneral(false); 
  };

  const clickShowInconsistencies = () => {
    setshowElements(false);
    setshowInconsistencias(true); 
    setshowCells(false); 
    // setshowGeneral(false); 
  };

 const clickShowCells = () => {
    setshowElements(false);
    setshowInconsistencias(false); 
    setshowCells(true); 
    // setshowGeneral(false); 
  };

  const clickShowGeneral = () => {
    setshowElements(false);
    setshowInconsistencias(false); 
    setshowCells(false); 
    // setshowGeneral(true); 
  };

  const clickTab = (index) => {
    setActiveIndex(index);
     
    if(index>0){
      clickShowElements();
    }else{
      clickShowGeneral();
    } 
     
  };

  const switchTab = tab => {
    if(activeIndex !== tab){
      setActiveIndex(tab);
      
      if(tab===2){
        setTourType(2);
        clickShowElements();
      }else{
        setTourType(1);
        clickShowGeneral();
      } 
    }
  }

  return (
    <>
      <PageContent title={t('formulario:CostoAnalisis')}> 
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>  
            <AnalisisCostosFilters onChangeFilter={onChangeFilter} TourType={TourType} onSearch={onSearch} showHide={showCells} filters={filters} /> 
          </Col> 
        </Row> 
        <Row>
          <Col>&nbsp;</Col>
        </Row> 
        <Card style={{ display: showContent ? 'block' : 'none' }}>   
          <CardBody>  
            <Row>
              <Col>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>  
                    <div className="card-header bg-card-header-grid">
                      <div className="p-toolbar p-component">
                        <div className="p-toolbar-group-left">
                          <div className="card-title"> 
                            <span>
                            &nbsp;
                            </span>
                          </div>  
                        </div>
                        <div className="p-toolbar-group-right">
                        &nbsp;
                        </div>
                      </div> 
                    </div>
                  </Col>
                </Row> 
                <div className="p-tabview p-component">
                  <Nav className="p-tabview-nav AC-Step-2">
                    <NavItem className={activeIndex === 1 ? "p-tabview-selected p-highlight p-unselectable-text nav-item flex-sm-fill" : "p-unselectable-text nav-item flex-sm-fill"}>
                      <NavLink className="p-tabview-nav-link" onClick={() => { switchTab(1); }}>
                        {t('formulario:Summary')}
                      </NavLink>
                    </NavItem>
                    <NavItem className={activeIndex === 2 ? "p-tabview-selected p-highlight p-unselectable-text nav-item flex-sm-fill" : "p-unselectable-text nav-item flex-sm-fill"}>
                      <NavLink className="p-tabview-nav-link" onClick={() => { switchTab(2); }}>
                        {t('formulario:Detailed')}
                      </NavLink>
                    </NavItem>
                  </Nav> 
                  <TabContent className="p-tabview-panels" activeTab={activeIndex}> 
                    <TabPane tabId={1}>
                      <AnalisisCostosGeneral filters={filters} />
                    </TabPane>
                    <TabPane tabId={2}>
                      <Row>
                        <Col lg={6} md={12} sm={12} xs={12}> 
                          <div className="AC-Step-3-2">
                            <FieldSelectButton 
                              name="CuentaContable"
                              value={CuentaContable} 
                              options={OpcionesCtaContable} 
                              onChange={(e) => setSelectCtaContable(e.value)} 
                              optionValue="value"
                              optionLabel="name"
                            /> 
                          </div>
                        
                        </Col>
                        <Col lg={6} md={12} sm={12} xs={12} className="text-right AC-Step-4-2">  
                          <Button
                            style={{ marginRight: '10px' }}
                            onClick={() => {
                          clickShowElements();
                          
                        }}
                            variant="contained"
                            color={showElements ? 'primary' : 'default'}
                            size="large" 
                            startIcon={<AppsIcon />}
                            className="AC-Step-4-3"
                          >
                            {t('formulario:Elements')}
                          </Button>
                          <Button
                            style={{ marginRight: '10px' }}
                            onClick={() => {
                          clickShowInconsistencies();
                        }}
                            color={showInconsistencias ? 'primary' : 'default'}
                            variant="contained"
                            size="large" 
                            startIcon={<Help />}
                            className="AC-Step-4-4"
                          >
                            {t('formulario:ActualVsStandard')}
                          </Button>
                          <Button
                            style={{ marginRight: '10px' }}
                            onClick={() => {
                          clickShowCells();
                        }}
                            variant="contained"
                            color={showCells ? 'primary' : 'default'}
                            size="large" 
                            startIcon={<AppsIcon />}
                            className="AC-Step-4-5"
                          >
                            {t('formulario:Cells')}
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                            &nbsp;
                      </Row>
                      <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <Card>
                            <CardBody>
                              <Row>
                                <Col lg={12} md={12} sm={12} xs={12}> 
                                  <div hidden={headerTitle===null}>
                                    <Tag value={headerTitle}></Tag> 
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                            &nbsp;
                              </Row>
                              <div>
                                <ElementosCostos showHide={showElements} filters={filters} />
                                <InconsistenciasCostos showHide={showInconsistencias} filters={filters} /> 
                                <CeldasCostos showHide={showCells} filters={filters} /> 
                              </div>          
                            </CardBody> 
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent> 
                </div>
              </Col>
            </Row>
        
          </CardBody>
        </Card>
      </PageContent>
    </>
  );
};

export default AnalisisCostos;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from 'utils/config';
import { callApi } from 'utils/utils';
import { Row, Col, Label} from 'reactstrap'
import FieldTextArea from 'components/Controls/FieldTextArea';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import YupValidator from 'utils/YupValidator';
import { object, string, number } from 'yup';

const ITAssetsDisable = (props) => {
  const {t, i18n} = useTranslation(['it', 'common']);

  const [errors, setErrors] = useState([]); // Errors
  const [disableReason, setDisableReason] = useState(''); // Reason

  const {selectedAsset} = props; // Selected Asset

  // Handle Disable reason change
  const onChangeDisableReason = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.disableReason;   
    setErrors(newErrors)  
    setDisableReason(e.target.value)
  }

    // Handle user assignment
    const onClickDisable = async () => {
      const validationScheme = object({
        disableReason: string().required(t('common:RequiredField')).nullable()
      });
      
      const value = {
        disableReason
      };
  
      const validator = new YupValidator(validationScheme);
      const isValid = await validator.validate(value);
  
      if (!isValid) {
        setErrors(validator.errors);
      }
      else {
        const urlWebService = `${config.UrlApiProject}IT/DisableITAsset`;
        const paramsService = {
          IdITEquipo: selectedAsset.IdITEquipo,
          DisableReason: disableReason
        };
    
        callApi(urlWebService, 'POST', paramsService, (response) => {
          props.onHide();
        })
      }
    }

  return (
    <>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} className='p-field p-fluid'>
          <Label>{t("it:DisableReason")}</Label>
          <FieldTextArea
            name="disableReason"
            rows={3}
            placeholder=''
            maxLength={200}
            value={disableReason}
            onChange={onChangeDisableReason}
            errors={errors}
          /> 
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} className='p-field p-d-flex p-jc-end'>
          <FieldButton
            icon='pi pi-user'
            label={t('common:Disable')}
            onClick={onClickDisable}
            className='p-button-danger'
          />
        </Col>
      </Row>
    </>
  )
}

export default ITAssetsDisable;
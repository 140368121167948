import { React, useState, useEffect, useRef } from 'react';
import Viz from 'components/Tableau/Viz';
import { useTranslation } from 'react-i18next';
import { callApi, getSessionItem } from 'utils/utils';
import { config } from 'utils/config';

const ConstructConnect = (props) => {

  const [viz, setViz] = useState(null);

  useEffect(() => {
    createViz();
  }, [])

  const createViz = () =>  {
    const params = {};
     
    const url = 'https://tableau.deacero.com/views/ConstructConnect_dash/ConstructConnect'

    setViz(<Viz url={url} parameters={params}></Viz>);
  }

  return (
    <>
      <div>{viz}</div>
    </>
  )
}

export default ConstructConnect;
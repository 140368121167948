import { Skeleton } from 'primereact/skeleton';
import React from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Row } from 'reactstrap';

const CompanyOverviewActiveCellsChartSkeleton = (props) => {
  const {t, i18n} = useTranslation(['companyOverview', 'common']);    

  const summaryTemplate = (
    <>
      <Row className='align-items-start'>
        <Col xs={8}>
          <h5>
            {t('companyOverview:ActiveCells')}
          </h5>        
          <Skeleton width="5rem" height="2rem" className="p-mb-2" />
        </Col>
        <Col 
          xs={4} 
          className='text-right'
        >
          <Skeleton className="p-mb-2" borderRadius="16px" />
        </Col>
      </Row>   
    </>
  );

  const chartTemplate = (
    <>
      <Skeleton shape="circle" size="12rem"></Skeleton>
    </>
  );

  const leyendsTemplate = (
    <>
      <Row className='pb-2'> 
        <Col xs={6}>
          {t('companyOverview:Active')}
        </Col>
        <Col xs={6}>          
          <Skeleton width="3rem" className="p-mb-2" borderRadius="16px" />
        </Col>
      </Row>
      <Row className='pb-2'>
        <Col xs={6}>
          {t('companyOverview:Down')}
        </Col>
        <Col xs={6}>          
          <Skeleton width="3rem" className="p-mb-2" borderRadius="16px" />
        </Col>
      </Row>
      <Row className='pb-2'>
        <Col xs={6}>
          {t('companyOverview:Other')}
        </Col>
        <Col xs={6}>          
          <Skeleton width="3rem" className="p-mb-2" borderRadius="16px" />
        </Col>
      </Row>      
    </>
  );

  return (
    <>
      <Card style={{borderRadius: '1%'}}>    
        <CardBody>
          <Row className='align-items-start'>
            <Col xs={12}> 
              {summaryTemplate}
            </Col>
          </Row>
          <Row className='align-items-start'>
            <Col xs={12}>
              {chartTemplate}                     
            </Col>
          </Row>
          <Row className='align-items-start'>
            <Col xs={12}>
              {leyendsTemplate}                     
            </Col>
          </Row>
        </CardBody>
      </Card>   
    </>
  )
};

export default CompanyOverviewActiveCellsChartSkeleton
/* eslint-disable no-template-curly-in-string */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap'; 
import { useTranslation } from 'react-i18next';
import { config } from '../../../utils/config';
import { callApi} from '../../../utils/utils.js'; 
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import FieldDropdown from '../../../components/Controls/FieldDropdown';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { Dialog } from 'primereact/dialog';
import PriceTrackerMap from './PriceTrackerMap';
import PriceTrackerDetailInvoices from './PriceTrackerDetailInvoices';
import FieldInputSwitch from '../../../components/Controls/FieldInputSwitch';
import { Slider } from 'primereact/slider';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import './PriceTrackerScreen.scss';   
import InfoNeedsToBeRefresh from 'components/Cards/InfoNeedsToBeRefresh/InfoNeedsToBeRefresh';

const PriceTrackerDetail = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['priceTracker', 'common']);
  const [errors, setErrors] = useState({}); 
  const [ClaUsuario, setClaUsuario] = useState(null);  
  const [IsLoading, setIsLoading] = useState(true);
  const [DivisionList, setDivisionList] = useState([]);
  const [ClaDivisionFilter, setClaDivisionFilter] = useState(null);
  const [NomDivisionFilter, setNomDivisionFilter] = useState(null);
  const [ClaZonaFilter, setClaZonaFilter] = useState(typeof(props.ClaZonaFilter) !== 'undefined' && props.ClaZonaFilter !== null ?  props.ClaZonaFilter : 'All');
  const [ClaStateFilter, setClaStateFilter] = useState(typeof(props.ClaStateFilter) !== 'undefined' && props.ClaStateFilter !== null ?  props.ClaStateFilter : 'All');
  const [ClaFactoryFilter, setClaFactoryFilter] = useState(null);
  const [ClaCuCostumerFilter, setClaCuCostumerFilter] = useState(null);
  const [ClaCostumerFilter, setClaCostumerFilter] = useState(null);
  const [ClaMileRangeFilter, setClaMileRangeFilter] = useState(null);
  const [ZonaList, setZonaList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [FactoryList, setFactoryList] = useState([]);
  const [CucCostumerList, setCucCostumerList] = useState([]);
  const [CostumerList, setCostumerList] = useState([]);
  const [LevelComparisonList, setLevelComparisonList] = useState([]);
  const [layoutLevelComparison, setlayoutLevelComparison] = useState('grid');
  const [MapMarkersList, setMapMarkersList] = useState([]);
  const [MapMarkersFilterList, setMapMarkersFilterList] = useState([]);
  const [MillasList, setMillasList] = useState([]);
  const [ChartHistorySeries, setChartHistorySeries] = useState([]);
  const [ChartRegressionSeries, setChartRegressionSeries] = useState([]);
  const [ChartRegressionSeriesData, setChartRegressionSeriesData] = useState([]);
  const [ChartHistoryCategories, setChartHistoryCategories] = useState([]);
  const [ChartLineSeries, setChartLineSeries] = useState([]);
  const [ChartLineCategories, setChartLineCategories] = useState([]); 
  const [TypeChartList, setTypeChartList] = useState([]);
  const [EsMostrarDialogDetail, setEsMostrarDialogDetail] = useState(false);
  const [ClaTypeChart, setClaTypeChart] = useState(null);
  const [chartMinMaxSeries, setchartMinMaxSeries] = useState([]);
  const [chartMinMaxCategories, setchartMinMaxCategories] = useState([]);
  const [chartInfoMinMaxSeries, setchartInfoMinMaxSeries] = useState([]);
  const [ChartBarSeries, setChartBarSeries] = useState([]);
  const [ChartBarCategories, setChartBarCategories] = useState([]);
  const [seriesAverageOfUnitList, setseriesAverageOfUnitList] = useState([]);
  const [seriesSumOfTonsList, setseriesSumOfTonsList] = useState([]);
  const [seriesSumOfRevenueList, setseriesSumOfRevenueList] = useState([]);
  const [productComparisonUnitPriceList, setproductComparisonUnitPriceList] = useState([]);
  const [showproductComparisonUnitPriceList, setshowproductComparisonUnitPriceList] = useState([]);
  const [showWholesale, setshowWholesale] = useState(false); 
  const [showIndustrialWire, setshowIndustrialWire] = useState(false); 
  const [showRetail, setshowRetail] = useState(false); 
  const [showKeySteelAccountSteel, setshowKeySteelAccountSteel] = useState(false); 
  const [showProductSpecialist, setshowProductSpecialist] = useState(false); 
  const [leyendasMapaList, setleyendasMapaList] = useState([]);
  const [LeyendaSelect, setLeyendaSelect] = useState({});
  const [NivelGrupo, setNivelGrupo] = useState(props.NivelProducto);
  const [EsMostrarDialogDetailParent, setEsMostrarDialogDetailParent] = useState(false); 
  const [ClaNivel, setClaNivel] = useState(null); 
  const [NivelProducto, setNivelProducto] = useState(null);  
  const [MinPriceRange, setMinPriceRange] = useState(null);
  const [MaxPriceRange, setMaxPriceRange] = useState(null);
  const [MinTonsRange, setMinTonsRange] = useState(null);
  const [MaxTonsRange, setMaxTonsRange] = useState(null);
  const [rangePrice, setrangePrice] = useState([]);
  const [rangeTons, setrangeTons] = useState([]);
  const [EsMostrarDialogDetailInvoice, setEsMostrarDialogDetailInvoice] = useState(false); 
  const [ClaCuCustomerInvoice, setClaCuCustomerInvoice] = useState(null);
  const [CustomerInvoice, setCustomerInvoice] = useState(null);
  const [NomDivisionInvoice, setNomDivisionInvoice] = useState(null);
  const [ClaPeriodoInvoice, setClaPeriodoInvoice] = useState(null);
  const [EsPrecioNetoFilter, setEsPrecioNetoFilter] = useState(props.EsPrecioNeto); 
  const [NeedsToClicSearchButton, setNeedsToClicSearchButton] = useState(false);
  const [ClaPriceFilter, setClaPriceFilter] = useState(!props.EsPrecioNeto?1:2);
  const [TipoPrecioList, setTipoPrecioList] = useState([]);
  const [refreshPriceTrackerDetail, setrefreshPriceTrackerDetail] = useState(0);
  const [minTons, setminTons] = useState(0); 
  const [maxTons, setmaxTons] = useState(0); 

  const gdpFormat = {
    type: 'fixedPoint',
    precision: 2,
  };
  Highcharts.setOptions({
    lang: {
      thousandsSep: ','
    }
  })
  

  useEffect(() => { 
    console.log('load',props);
    getFilters();
    setClaFactoryFilter(-1);
    setClaCuCostumerFilter(-1);
    setClaCostumerFilter(-1);
    setClaZonaFilter(typeof(props.ClaZonaFilter) !== 'undefined' && props.ClaZonaFilter !== null ?  props.ClaZonaFilter : 'All');
    setClaStateFilter(typeof(props.ClaStateFilter) !== 'undefined' && props.ClaStateFilter !== null ?  props.ClaStateFilter : 'All');
    getStateByZoneFilter(typeof(props.ClaZonaFilter) !== 'undefined' && props.ClaZonaFilter !== null ?  props.ClaZonaFilter : 'All');
    // ejecutamos para que refresque la informacion ya con los campos de filtros bien detectados
    setrefreshPriceTrackerDetail(refreshPriceTrackerDetail+1);
    // setClaZonaFilter('All') 
    // setClaStateFilter('All')
  }, [props]); 

  useEffect(
    () => {
      console.log('getPriceTrackerDetail();', refreshPriceTrackerDetail);
      if (refreshPriceTrackerDetail>0){
        getPriceTrackerDetail();
      }
       
    }, [refreshPriceTrackerDetail]
  );


  const getFilters = () => { 
    const url = `${config.UrlApiProject}USADatalake/GetPriceTrackerFilterDetail`;   
    const paramsToService = {
      ClaPeriodo:props.ClaPeriodo,
      ClaNivel:props.ClaNivel,
      ClaDivision:props.ClaDivision,
      ClaTipo:props.ClaTipo,
      NivelProducto:props.NivelProducto
    }
    callApi(url, 'POST', paramsToService, (response) => {
      const divisionList = response.data.DivisionList;
      const zonaList = response.data.ZonaList;
      const stateList = response.data.StateList;
      const factoryList = response.data.FactoryList;
      const cucCostumerList = response.data.CucCostumerList;
      const costumerList = response.data.CostumerList;
      const typeList = response.data.TypeList;
      const millasList = response.data.MillasList;
      const levelComparisonList = response.data.LevelComparisonList;
      const configuration = response.data.Configuration[0];
      const tipoPrecioList = response.data.TipoPrecioList;
      setDivisionList(divisionList);
      setZonaList(zonaList);
      // setStateList(stateList);
      setFactoryList(factoryList)
      setCucCostumerList(cucCostumerList);
      setCostumerList(costumerList);
      setTypeChartList(typeList);
      setMillasList(millasList);
      setClaTypeChart(1);
      setClaDivisionFilter(props.ClaDivision)
      setNomDivisionFilter(props.ClaDivision)
      setLevelComparisonList(levelComparisonList);
      setNivelGrupo(props.NivelProducto);
      setMinPriceRange(0)
      setMinTonsRange(0)
      setMaxPriceRange(configuration.MaxPriceRange);
      setMaxTonsRange(configuration.MaxTonsRange);
      setrangePrice([0,configuration.MaxPriceRange]);
      setrangeTons([0,configuration.MaxTonsRange]);
      setTipoPrecioList(tipoPrecioList);
    });
  }
  
  const getStateByZoneFilter = (pZona) => {
    const getStateCmb = `${config.UrlApiProject}USADatalake/GetPriceTrackerFilterStateByZone?ClaZona=${pZona}`;
    const paramsToService = {};
    callApi(getStateCmb, 'GET', paramsToService, (response) => {
      setStateList(response.data.StateList);
    });

    setNeedsToClicSearchButton(true);
  };

  const getCustomerByCucFilter = (pCuc) => {
    const getCustomerCmb = `${config.UrlApiProject}USADatalake/GetPriceTrackerFilterCustomerByCUC?ClaCuc=${pCuc}`;
    const paramsToService = {};
    callApi(getCustomerCmb, 'GET', paramsToService, (response) => {
      setCostumerList(response.data.CustomerList);
    });
    setNeedsToClicSearchButton(true);
  };
  
  
  const getNivelTracker = (claNivel, nomnivel) => { 
    setClaNivel(claNivel);
    setNivelProducto(nomnivel);
    setEsMostrarDialogDetailParent(true);
  }

  const getPriceTrackerDetail = () => {  
    const url = `${config.UrlApiProject}USADatalake/GetPriceTrackerDetail`;   
    const paramsToService = {
      ClaPeriodo:props.ClaPeriodo,
      ClaDivision:props.ClaDivision,
      ClaNivel:props.ClaNivel,
      ClaTipo:props.ClaTipo,
      ClaNivelProducto:props.ClaNivelProducto,
      NivelProducto:props.NivelProducto,
      ClaZona:ClaZonaFilter,
      ClaState:ClaStateFilter,
      ClaFactory:ClaFactoryFilter,
      ClaCuCostumer:ClaCuCostumerFilter,
      ClaCostumer:ClaCostumerFilter,
      ClaDivicion:null,
      Milles:ClaMileRangeFilter,
      minPriceRange:(rangePrice!==undefined ? rangePrice[0] : null),
      maxPriceRange:(rangePrice!==undefined ? rangePrice[1] : null),
      minTonsRange:(rangeTons!==undefined ? rangeTons[0] : null),
      maxTonsRange:(rangeTons!==undefined ? rangeTons[1] : null),
      EsPrecioNeto: EsPrecioNetoFilter 
    }
    callApi(url, 'POST', paramsToService, (response) => { 
      setMapMarkersList(response.data.MapaMarkersList);
      setMapMarkersFilterList(response.data.MapaMarkersList); 
      hiddeColumnsDivision(response.data.showproductComparisonUnitPriceList);
      setproductComparisonUnitPriceList(response.data.productComparisonUnitPriceList);
      setchartMinMaxSeries(response.data.seriesMinimoMaximoList);
      setchartMinMaxCategories(response.data.categoriesMinimoMaximoList);
      setchartInfoMinMaxSeries(response.data.seriesInfoMinimoMaximoList);
      setshowproductComparisonUnitPriceList(response.data.showproductComparisonUnitPriceList);
      setleyendasMapaList(response.data.leyendasMapa);
      getChartBarAverageOfUnit(response.data.seriesAverageOfUnitList,response.data.categoriesBarList);
      getChartBarSumOfTons(response.data.seriesSumOfTonsList)
      getChartBarRevenue(response.data.seriesSumOfRevenueList);
      getMinimoMaximoChartLine(props.ClaDivision,response.data.seriesMinimoMaximoList,response.data.categoriesMinimoMaximoList,response.data.seriesInfoMinimoMaximoList);
      getChartHistory(response.data.seriesHistList);  
      const infoList = response.data.seriesRegressionList.infoRegression; 
      const tons = getTons(infoList);
      setminTons(tons[0]);
      setmaxTons(tons[1]);
      getChartRegression(infoList,response.data.seriesRegressionData); 
      setIsLoading(false);
      setNeedsToClicSearchButton(false);
    });
  }

  const hiddeColumnsDivision = (divisionList) => {  
    divisionList.forEach((element)=>{
       if(element.ClaGrupoEstadistico1===477){
        setshowWholesale(true); 
       }
       if(element.ClaGrupoEstadistico1===478){
        setshowIndustrialWire(true);
       }
       if(element.ClaGrupoEstadistico1===479){
        setshowKeySteelAccountSteel(true);
       }
       if(element.ClaGrupoEstadistico1===480){
        setshowProductSpecialist(true);
       }
       if(element.ClaGrupoEstadistico1===481){
        setshowRetail(true);
       }
    })
    
  }

  const getChartHistory = (series) => { 
    setChartHistorySeries(series);
    setChartHistoryCategories([]);
  }

  const getChartRegression = (series,data) => { 
    setChartRegressionSeries(series); 
    setChartRegressionSeriesData(data); 
  }

  const getMinimoMaximoChartLine = (division, serieList,categoriesList, infoSeries) => { 
    const SerieDivisiones = Agrupar(serieList,"NomDivision");
    const SerieDivision = SerieDivisiones[division];
    const SerieList = SerieDivision === undefined ? null : Agrupar(SerieDivisiones[division],"Serie");
    const series = [];
      // Generamos la series
    if(SerieList!==null){
      infoSeries.forEach((element)=>{
        const serieValues = [];
        const item = {};
        const listValues = SerieList[element.Serie];
        listValues.forEach((itemElement)=>{
          serieValues.push(itemElement.ImporteVenta)
        });
        item.name = element.Serie;
        item.color = element.ColorHexadecimal;
        item.data  = serieValues;
        series.push(item);
      })
    }

    const listCategories = categoriesList;
    const dataValues = [];
    listCategories.forEach((itemElement)=>{
      dataValues.push(itemElement.Periodo)
    });

    setChartLineSeries(series);
    setChartLineCategories(dataValues);
  }

  const Agrupar = (Arr,key) => {
    return Arr.reduce((acum, x) => {
       
        (acum[x[key]] = acum[x[key]] || []).push(x);
        return acum;
      }, {});
}

  const getChartBarAverageOfUnit = (serie, categories) => {
      setseriesAverageOfUnitList(serie);
      setChartBarCategories(categories);
  }

  const getChartBarSumOfTons = (serie) => {
    setseriesSumOfTonsList(serie);
  }

  const getChartBarRevenue = (serie) => {
    setseriesSumOfRevenueList(serie);
  } 

  const getChartLineByDivision = (sDivision) => {
    getMinimoMaximoChartLine(sDivision,chartMinMaxSeries,chartMinMaxCategories,chartInfoMinMaxSeries);
  }

  const optionsChartHistory =  {
    chart: {
      zoomType: 'y'
    },
    title: {
        align: 'left',
        text: `${props.NivelProducto} ${t('-Historical Price')}`
    },
    xAxis: {
      labels: {
        overflow: 'justify'
      },
      lineColor: "#1E232A",
      tickColor: "#1E232A",
      type: "category" 
    },
    yAxis: [{
        title: { text: '' },
        labels: {
                format: "${value:,.0f}"
        }
    }],
    plotOptions: {
        histogram: {
            accessibility: {
                point: {
                    valueDescriptionFormat: '{index}. {point.x:.3f} to {point.x2:.3f}, {point.y}.'
                }
            }
        },
        series: {
            cursor: 'pointer',
            point: {
            events: {
              click(e){
                const info = e.point;
                console.log('ChartHistorySeries info',e);
                setClaCuCustomerInvoice(info.claClienteCuenta);
                setCustomerInvoice(info.customer);
                setNomDivisionInvoice(info.nomDivision);
                setClaPeriodoInvoice(info.anioMes);
                setEsMostrarDialogDetailInvoice(true);
              }
            }
          }
        }
    },
    series: ChartHistorySeries,
    tooltip: {
      useHTML: true,
      headerFormat: '',
      pointFormat:  `${'<table className="tblHistoryTooltip" width="100%">' + 
                    '<tr>' +
                      '<td style="color:#fff;text-align:center;background-color:{series.color}" colspan="2"><b>{point.nomDivision}</b></td>' +
                    '</tr>' +
                     '<tr>' +
                      '<td style="border:solid 1px #CDCDCD"><b>Periodo</b></td>' +
                      '<td style="border:solid 1px #CDCDCD;text-align:right;">{point.name}</td>' +
                    '</tr>' +
                    '<tr>' +
                      '<td style="border:solid 1px #CDCDCD"><b>Price</b></td>' +
                      '<td style="border:solid 1px #CDCDCD;text-align:right;">{point.unitPrice:,.2f}</td>' +
                    '</tr>' +
                    '<tr>' +
                    '<td style="border:solid 1px #CDCDCD"><b>Spread</b></td>' +
                    '<td style="border:solid 1px #CDCDCD;text-align:right;">{point.spread:,.2f}</td>' +
                    '</tr>' +
                    '<tr>' +
                    '<td style="border:solid 1px #CDCDCD"><b>'}${ t('priceTracker:Outbund') }</b></td>` +
                    `<td style="border:solid 1px #CDCDCD;text-align:right;">{point.outbound:,.2f}</td>` +
                    `</tr>` +
                    `<tr>` +
                      `<td style="border:solid 1px #CDCDCD"><b>Tons</b></td>` +
                      `<td style="border:solid 1px #CDCDCD;text-align:right;">{point.tons:,.2f}</td>` +
                    `</tr>` +
                    `<tr>` +
                      `<td style="border:solid 1px #CDCDCD"><b>Qty</b></td>` +
                      `<td style="border:solid 1px #CDCDCD;text-align:right;">{point.qty}</td>` +
                    `</tr>` +
                    `<tr>` +
                      `<td style="border:solid 1px #CDCDCD"><b>Revenue</b></td>` +
                      `<td style="border:solid 1px #CDCDCD;text-align:right;">{point.revenue:,.2f}</td>` +
                    `</tr>` +
                    `<tr>` +
                      `<td style="border:solid 1px #CDCDCD"><b>Number of invoices</b></td>` +
                      `<td style="border:solid 1px #CDCDCD;text-align:right;">{point.numberInvoices}</td>` +
                    `</tr>` +
                    `<tr>` +
                      `<td style="border:solid 1px #CDCDCD"><b>Customer</b></td>` +
                      `<td style="border:solid 1px #CDCDCD">{point.customer}</td>` +
                    `</tr>` +
                    `<tr>` +
                      `<td style="border:solid 1px #CDCDCD"><b>Location</b></td>` +
                      `<td style="border:solid 1px #CDCDCD">{point.locationCliente}</td>` +
                    `</tr>` +
                    `<tr>` +
                      `<td style="border:solid 1px #CDCDCD"><b>Agent</b></td>` +
                      `<td style="border:solid 1px #CDCDCD">{point.agent}</td>` +
                    `</tr>` +
                    `<tr>` +
                      `<td style="border:solid 1px #CDCDCD"><b>Factory</b></td>` +
                      `<td style="border:solid 1px #CDCDCD">{point.factory}</td>` +
                    `</tr>` +
                   
                  `</table>`
  }
  }

  const optionsChartLine =  {
    chart: {
        type: 'spline'
    },
    legend: {
        symbolWidth: 40
    },
    title: {
        text: ``,
        align: 'left'
    },
    subtitle: {
        text: '',
        align: 'left'
    },
    yAxis: {
        title: {
            text: ''
        },
        labels: {
            format: "${value:,.0f}"
        },
        accessibility: {
            description: ''
        }
    },
    xAxis: {
        title: {
            text: ''
        },
        accessibility: {
            description: ''
        },
        categories: ChartLineCategories
    },
    tooltip: {      
      pointFormat: '<b>${point.y:,.2f}</b>'            
    }, 
    plotOptions: {
        series: {
            point: {
                events: {
                    click () {
                       console.log('click');
                    }
                }
            },
            cursor: 'pointer',
            lineWidth: 2
        }
    },
    series: ChartLineSeries,
    responsive: {
        rules: [{
            condition: {
                maxWidth: 550
            },
            chartOptions: {
                chart: {
                    spacingLeft: 3,
                    spacingRight: 3
                },
                legend: {
                    itemWidth: 150
                },
                xAxis: {
                    categories: ChartLineCategories,
                    title: ''
                },
                yAxis: {
                    visible: false
                }
            }
        }]
    }
  }

  const optionsChartBarAverangeOfUnit =  {
    chart: {
        type: 'column'
    },
    title: {
        text: '',
        align: 'left'
    },
    subtitle: {
        text: '',
        align: 'left'
    },
    xAxis: {
        categories: ChartBarCategories,
        title: {
            text: null
        },
        gridLineWidth: 1,
        lineWidth: 0
    },
    yAxis: {
        min: 0,
        title: {
            text: '',
            align: 'high'
        },
        labels: {
                format: "${value:,.0f}",
                overflow: 'justify'
        },
        gridLineWidth: 0
    },
    tooltip: {      
      pointFormat: '{series.name} <b>${point.y:,.2f}</b>'    
    }, 
    plotOptions: {
        bar: {
            borderRadius: '50%',
            dataLabels: {
                enabled: true
            },
            groupPadding: 0.1
        }
    },
    series:seriesAverageOfUnitList
  }  

  const optionsChartBarSumOfTons =  {
    chart: {
        type: 'column'
    },
    title: {
        text: '',
        align: 'left'
    },
    subtitle: {
        text: '',
        align: 'left'
    },
    xAxis: {
        categories: ChartBarCategories,
        title: {
            text: null
        },
        gridLineWidth: 1,
        lineWidth: 0
    },
    yAxis: {
        min: 0,
        title: {
            text: '',
            align: 'high'
        },
        labels: {
          format: "{value:,.0f}",
          overflow: 'justify'
        },
        gridLineWidth: 0
    },
    tooltip: {      
      pointFormat: '{series.name} <b>{point.y:,.2f}</b>'            
    }, 
    plotOptions: {
        bar: {
            borderRadius: '50%',
            dataLabels: {
                enabled: true
            },
            groupPadding: 0.1
        }
    },
    series:seriesSumOfTonsList
  }  

  const optionsChartBarRevenue =  {
    chart: {
        type: 'column'
    },
    title: {
        text: '',
        align: 'left'
    },
    subtitle: {
        text: '',
        align: 'left'
    },
    xAxis: {
        categories: ChartBarCategories,
        title: {
            text: null
        },
        gridLineWidth: 1,
        lineWidth: 0
    },
    yAxis: {
        min: 0,
        title: {
            text: '',
            align: 'high'
        }, 
        labels: {
          format: "${value:,.0f}",
          overflow: 'justify'
        },
        gridLineWidth: 0
    },
    tooltip: {      
      pointFormat: '{series.name} <b>${point.y:,.2f}</b>'          
    }, 
    plotOptions: {
        bar: {
            borderRadius: '50%',
            dataLabels: {
                enabled: true
            },
            groupPadding: 0.1
        }
    },
    series:seriesSumOfRevenueList
  }  

  function getTons(data) {
    let minX = 0;
    let maxX = 0;
    const tonsList = [];
    if(data.length>0){
      const n = data.length;
      let sumX = 0;
      let sumY = 0;
      let sumXY = 0;
      let sumX2 = 0;
      // Calculate the sums needed for linear regression
      for (let i = 0; i < n; i++) {
          const [x, y] = data[i];
          sumX += x;
          sumY += y;
          sumXY += x * y;
          sumX2 += x ** 2;
      }
      minX = Math.min(...data.map(([x]) => x));
      maxX = Math.max(...data.map(([x]) => x));
    }
    tonsList.push(minX);
    tonsList.push(maxX);
    return tonsList;
  }

  function getTrendLine(data) {
    const trendline = []; // Array to store the trend line data points
    if(data.length>0){
      const n = data.length;
  
      let sumX = 0;
      let sumY = 0;
      let sumXY = 0;
      let sumX2 = 0;
  
      // Calculate the sums needed for linear regression
      for (let i = 0; i < n; i++) {
          const [x, y] = data[i];
          sumX += x;
          sumY += y;
          sumXY += x * y;
          sumX2 += x ** 2;
      }
      
      // Calculate the slope of the trend line
      const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX ** 2);
      // Calculate the intercept of the trend line
      const intercept = (sumY - slope * sumX) / n;
      // Find the minimum and maximum x-values from the scatter plot data
      const minX = Math.min(...data.map(([x]) => x));
      const maxX = Math.max(...data.map(([x]) => x));
      // Calculate the corresponding y-values for the trend line using the slope
      // and intercept
      trendline.push([minX, minX * slope + intercept]);
      trendline.push([maxX, maxX * slope + intercept]);
    }
    return trendline;
  }

const optionsChartRegression =   {
  title: {
      text: `${props.NomPeriodo} ${props.ClaDivision}`
  },
  xAxis: {
    title: { text: t('priceTracker:Tons') },
    labels: {
      format: "{value:,.0f}"
    },
    min: minTons,
    max: maxTons
  },
  yAxis: [{
      title: { text: t('priceTracker:PricePerTons')},
      labels: {
        format: "${value:,.0f}"
      },
      min: 0
  }],
  plotOptions: {
    histogram: {
      accessibility: {
          point: {
              valueDescriptionFormat: '{index}. {point.x:.3f} to {point.x2:.3f}, {point.y}.'
          }
      }
      },
      series: {
          cursor: 'pointer',
          point: {
            events: {
              click(e){
                const info = e.point;
                console.log('ChartRegressionSeries info',info);
                setClaCuCustomerInvoice(info.claClienteCuenta);
                setCustomerInvoice(info.customer);
                setNomDivisionInvoice(info.nomDivision);
                setClaPeriodoInvoice(null);
                setEsMostrarDialogDetailInvoice(true);
              }
            }
          }
        }
  },
  series: [{
      type: 'line',
      name: t('priceTracker:RegressionLine'),
      data: getTrendLine(ChartRegressionSeries),
      marker: {
          enabled: false
      },
      states: {
          hover: {
              lineWidth: 0
          }
      },
      enableMouseTracking: false
  }, {
      type: 'scatter',
      name: t('priceTracker:Customers'),
      data: ChartRegressionSeriesData,
      marker: {
          radius: 4
      }
  }],
  tooltip: {
    useHTML: true,
    headerFormat: '',
    pointFormat:  `${'<table className="tblHistoryTooltip" width="100%">' + 
                  '<tr>' +
                    '<td style="color:#fff;text-align:center;background-color:{point.color}" colspan="2"><b>{point.customer}</b></td>' +
                  '</tr>' +
                  '<tr>' +
                    '<td style="border:solid 1px #CDCDCD"><b>Price</b></td>' +
                    '<td style="border:solid 1px #CDCDCD;text-align:right;">{point.y:,.2f}</td>' +
                  '</tr>' +
                  '<tr>' +
                  '<td style="border:solid 1px #CDCDCD"><b>Spread</b></td>' +
                  '<td style="border:solid 1px #CDCDCD;text-align:right;">{point.spread:,.2f}</td>' +
                  '</tr>' +
                  '<tr>' +
                  '<td style="border:solid 1px #CDCDCD"><b>'}${ t('priceTracker:Outbund') }</b></td>` +
                  `<td style="border:solid 1px #CDCDCD;text-align:right;">{point.outbound:,.2f}</td>` +
                  `</tr>` +
                  `<tr>` +
                    `<td style="border:solid 1px #CDCDCD"><b>Tons</b></td>` +
                    `<td style="border:solid 1px #CDCDCD;text-align:right;">{point.x:,.2f}</td>` +
                  `</tr>` +
                  `<tr>` +
                    `<td style="border:solid 1px #CDCDCD"><b>Qty</b></td>` +
                    `<td style="border:solid 1px #CDCDCD;text-align:right;">{point.qty}</td>` +
                  `</tr>` +
                  `<tr>` +
                    `<td style="border:solid 1px #CDCDCD"><b>Revenue</b></td>` +
                    `<td style="border:solid 1px #CDCDCD;text-align:right;">{point.revenue:,.2f}</td>` +
                  `</tr>` +
                  `<tr>` +
                    `<td style="border:solid 1px #CDCDCD"><b>Number of invoices</b></td>` +
                    `<td style="border:solid 1px #CDCDCD;text-align:right;">{point.numberInvoices}</td>` +
                  `</tr>` +
                  `<tr>` +
                    `<td style="border:solid 1px #CDCDCD"><b>Location</b></td>` +
                    `<td style="border:solid 1px #CDCDCD">{point.locationCliente}</td>` +
                  `</tr>` +
                `</table>`
  }
};
   

const onChangeDivision = (e) => {   
  setClaDivisionFilter(e.value);   
  const divisionNameList = [...DivisionList];
  const divisionName = divisionNameList.filter((item) => item.ClaDivision === e.value)[0];
  setNomDivisionFilter(divisionName.NomDivision);
  getChartLineByDivision(divisionName.NomDivision);
  // setNeedsToClicSearchButton(true);
}


const onChangeLeyendaSelect = (element,index) => {  
  const opciones = [...leyendasMapaList];
  opciones[index].EsSeleccionado = (!element.EsSeleccionado);
  const markers = [];
  const markersMapList = [...MapMarkersList];
  // Se recorren todas las leyendas para filtrar solo las seleccionadas
  const markersPorDivisionList = Agrupar(markersMapList,"ClaDivision");
  opciones.forEach((element)=>{
    // Filtra solo las seleccionadas
    if(element.EsSeleccionado){
      const arrayMarkers = markersPorDivisionList[element.ID];
      if(arrayMarkers!==undefined){
        arrayMarkers.forEach((data)=>{
          markers.push(data);
        })
      }
    }
  })
  setleyendasMapaList(opciones);
  setMapMarkersFilterList(markers);
}


const onChangeZona = (e) => {   
  getStateByZoneFilter(e.value);
  setClaZonaFilter(e.value);   
  setNeedsToClicSearchButton(true);
}

const onChangeState = (e) => {   
  setClaStateFilter(e.value);   
  setNeedsToClicSearchButton(true);
}

const onChangeFactory = (e) => {   
  setClaFactoryFilter(e.value);  
  setNeedsToClicSearchButton(true); 
}

const onChangeCucCostumer = (e) => {   
  getCustomerByCucFilter(e.value);
  setClaCuCostumerFilter(e.value);  
  setNeedsToClicSearchButton(true); 
}

const onChangeCostumer = (e) => {   
  setClaCostumerFilter(e.value);   
  setNeedsToClicSearchButton(true);
}
 
const onChangeMileRangeFilter = (e) => {   
  setClaMileRangeFilter(e.value);  
  setNeedsToClicSearchButton(true); 
}

const onHideDialogDetail = () => {
  setEsMostrarDialogDetail(false);
}

const openDialogDetail = () => {
  setEsMostrarDialogDetail(true);
}

const titleDetail = (
  <> 
    <Row>
      <Col>
        {t('priceTracker:WorkOrderBacklogAdministrator')} 
      </Col>
      <Col className='text-right pr-5'>
        {t('priceTracker:CountWorkOrderBacklogAdministrator')}
      </Col>
    </Row>
  </>
);

const onChangeTypeChart = (e) => {   
    setClaTypeChart(e.value); 
}

 

if (IsLoading){
  return (<>Loading...</>);
}

const gettextColor = (val) => { 
  return 'text-default';
} 

const DescriptionTemplate = (item) => {
  return (
    <> 
      {
        item.data.Nivel!== null ? (
          <>
            <span
              className='alink' 
              onClick={() => getNivelTracker(item.data.Nivel,item.data.Descripcion)}
            > {item.data.Descripcion}
            </span> 
          </>
        ):(
          <>
            <span> {item.data.Descripcion}
            </span> 
          </>
        )
      }
    </>
    );
}; 
const DiffereceTemplate477 = (item) => {
  return (
    <> 
      <span className={gettextColor(item.data['477_Difference'])}> 
        {Highcharts.numberFormat(item.data['477_Difference'],2)}
      </span>
    </>
    );
};  
const DiffereceTemplate478 = (item) => {
  return (
    <> 
      <span className={gettextColor(item.data['478_Difference'])}> 
        {Highcharts.numberFormat(item.data['478_Difference'],2)}
      </span>
    </>
    );
};  
const DiffereceTemplate479 = (item) => {
  return (
    <> 
      <span className={gettextColor(item.data['479_Difference'])}> 
        {Highcharts.numberFormat(item.data['479_Difference'],2)}
      </span>
    </>
    );
}; 
const DiffereceTemplate480 = (item) => {
  return (
    <> 
      <span className={gettextColor(item.data['480_Difference'])}> 
        {Highcharts.numberFormat(item.data['480_Difference'],2)}
      </span>
    </>
    );
}; 
const DiffereceTemplate481 = (item) => {
  return (
    <> 
      <span className={gettextColor(item.data['481_Difference'])}> 
        {Highcharts.numberFormat(item.data['481_Difference'],2)}
      </span>
    </>
    );
}; 

const DiffereceTemplate477_2 = (item) => {
  return (
    <> 
      {
        item.data['477_Difference']>0 ? (
          <>
            <span className='text-danger'> 
              {Highcharts.numberFormat(item.data['477_Difference'],2)}
            </span> 
          </>
        ):(
          <>
            <span>  
              {Highcharts.numberFormat(item.data['477_Difference'],2)}
            </span> 
          </>
        )
      }
    </>
    );
};  
const DiffereceTemplate478_2 = (item) => {
  return (
    <> 
      {
        item.data['478_Difference']>0 ? (
          <>
            <span className='text-danger'>  
              {Highcharts.numberFormat(item.data['478_Difference'],2)}
            </span> 
          </>
        ):(
          <>
            <span>  
              {Highcharts.numberFormat(item.data['478_Difference'],2)}
            </span> 
          </>
        )
      }
    </>
    );
};  
const DiffereceTemplate479_2 = (item) => {
  return (
    <> 
      {
        item.data['479_Difference']>0 ? (
          <>
            <span className='text-danger'>  
              {Highcharts.numberFormat(item.data['479_Difference'],2)}
            </span> 
          </>
        ):(
          <>
            <span> 
              {Highcharts.numberFormat(item.data['479_Difference'],2)}
            </span> 
          </>
        )
      }
    </>
    );
};  
const DiffereceTemplate480_2 = (item) => {
  return (
    <> 
      {
        item.data['480_Difference']>0 ? (
          <>
            <span className='text-danger'> 
              {Highcharts.numberFormat(item.data['480_Difference'],2)}
            </span> 
          </>
        ):(
          <>
            <span> 
              {Highcharts.numberFormat(item.data['480_Difference'],2)}
            </span> 
          </>
        )
      }
    </>
    );
};  
const DiffereceTemplate481_2 = (item) => {
  return (
    <> 
      {
        item.data['481_Difference']>0 ? (
          <>
            <span className='text-danger'> 
              {Highcharts.numberFormat(item.data['481_Difference'],2)}
            </span> 
          </>
        ):(
          <>
            <span> 
              {Highcharts.numberFormat(item.data['481_Difference'],2)}
            </span> 
          </>
        )
      }
    </>
    );
};  

const onChangeNivelGrupo = (e) => {   
  setNivelGrupo(e.value);
  if(props.onChangePriceTrackerDetail!==undefined){
    props.onChangePriceTrackerDetail(e.value, EsPrecioNetoFilter);
  }
}

const onChangeTipoPrecio = (e) => {   
   
  if(e.value===1){
    setEsPrecioNetoFilter(false);
  }else{
    setEsPrecioNetoFilter(true);
  }  
  setClaPriceFilter(e.value);
   
  // ejecutamos consulta  automatico, se remueve por que se bloqueaba...
  setNeedsToClicSearchButton(true);
}

const templateHeaderDialog = (
  <> 
    <Row className='PriceTrackerScreen align-items-start'>
      <Col lg={10} md={10} sm={10} xs={10}>
        <div className='p-fluid p-2'>
          <table>
            <tr>
              <td>
                {props.NomPeriodo} {NomDivisionFilter} - 
              </td>
              <td>
                <FieldDropdown
                  showClear={false}
                  name="NomNivel"
                  value={NivelGrupo} 
                  options={LevelComparisonList}  
                  optionValue="NomNivel"
                  optionLabel="NomNivel" 
                  onChange={(e) => onChangeNivelGrupo(e)}
                  className="DashboardDropdownNivel"
                />  
              </td>
              <td> 
                <FieldDropdown  
                  name="ClaPrice" 
                  label={t('priceTracker:PriceFilter')}
                  value={ClaPriceFilter} 
                  options={TipoPrecioList}  
                  optionValue="ClaPrice"
                  optionLabel="NomPrice" 
                  onChange={(e) => onChangeTipoPrecio(e)}
                  errors={errors}
                  showClear={false}
                />    
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td colSpan={2}>
                <p className='text-black-50'>
                  <div><small className='info-color'>{t('priceTracker:DisclaimerInfo')}</small></div>
                  {
                    props.ClaTipo===1 ? (
                      <>
                        <small className='info-color'>{t('priceTracker:LeyendaDetailPriceTracker')}  {t('priceTracker:Tons')}</small>
                      </>
                    ):(
                      <>   
                        <small className='info-color'>{t('priceTracker:LeyendaDetailPriceTracker')}  {t('priceTracker:Units')}</small>
                      </>
                    )
                  }

                </p>
              </td>
            </tr>
          </table>
        </div>
      </Col>
      <Col className='text-right' lg={2} md={2} sm={2} xs={2}>
        <FieldButton
          icon="pi pi-arrow-left" 
          label={t('priceTracker:Back')}
          className="p-button p-button-warning p-highlight"
          onClick={() => onHideDialog()}
        >
        </FieldButton>
      </Col>
    </Row> 
    <Row className='PriceTrackerScreen'>
      <Col lg={4} md={4} sm={12} xs={12}>
        <div className='p-fluid p-2'>
          <table width="100%">
            <tr>
              <td width="50%">
                <FieldDropdown  
                  name="ClaZona" 
                  label={t('priceTracker:Zone')}
                  showClear={false}
                  value={ClaZonaFilter} 
                  options={ZonaList}  
                  optionValue="ClaZona"
                  optionLabel="NomZona" 
                  onChange={(e) => onChangeZona(e)}
                  errors={errors}
                />  
              </td>
              <td width="50%">
                <FieldDropdown  
                  name="ClaState" 
                  label={t('priceTracker:State')}
                  showClear={false}
                  value={ClaStateFilter} 
                  options={StateList}  
                  optionValue="ClaState"
                  optionLabel="NomState" 
                  onChange={(e) => onChangeState(e)}
                  errors={errors}
                />  
              </td>
            </tr>
          </table> 
        </div>
      </Col>
      <Col lg={4} md={4} sm={12} xs={12}>
        <div className='p-fluid p-2'>
          <table width="100%">
            <tr>
              <td width="50%">
                <FieldDropdown  
                  name="ClaFactory" 
                  label={t('priceTracker:Factory')}
                  showClear={false}
                  value={ClaFactoryFilter} 
                  options={FactoryList}  
                  optionValue="ClaFactory"
                  optionLabel="NomFactory" 
                  onChange={(e) => onChangeFactory(e)}
                  errors={errors}
                />  
              </td>
              <td width="50%">
                <FieldDropdown  
                  name="ClaCuCostumer" 
                  label={t('priceTracker:CUC')}
                  showClear={false}
                  value={ClaCuCostumerFilter} 
                  options={CucCostumerList}  
                  optionValue="ClaCuCostumer"
                  optionLabel="NomCuCostumer" 
                  onChange={(e) => onChangeCucCostumer(e)}
                  errors={errors}
                />  
              </td>
            </tr>
          </table> 
        </div>
      </Col>
      <Col lg={4} md={4} sm={12} xs={12}>
        <div className='p-fluid p-2'>
          <table width="100%">
            <tr> 
              {
                ClaCostumerFilter>0 ? (
                  <>
                    <td width="50%">
                      <FieldDropdown  
                        name="ClaCostumer" 
                        label={t('priceTracker:Customer')}
                        showClear={false}
                        value={ClaCostumerFilter} 
                        options={CostumerList}  
                        optionValue="ClaCostumer"
                        optionLabel="NomCostumer" 
                        onChange={(e) => onChangeCostumer(e)}
                        errors={errors}
                      />  
                    </td>
                    <td width="35%">
                      <FieldDropdown  
                        name="ClaMilla" 
                        label={t('priceTracker:MileRange')}
                        value={ClaMileRangeFilter}  
                        options={MillasList}  
                        optionValue="ClaMilla"
                        optionLabel="NomMilla" 
                        onChange={(e) => onChangeMileRangeFilter(e)}
                        errors={errors}
                      />  
                    </td>
                    <td width="15%">
                      <FieldButton
                        icon="pi pi-search" 
                        label={t('priceTracker:Search')}
                        className="p-button p-highlight"
                        onClick={getPriceTrackerDetail}
                      >
                      </FieldButton>
                    </td>
                  </>
                ):(
                  <>
                    <td width="85%">
                      <FieldDropdown  
                        name="ClaCostumer" 
                        label={t('priceTracker:Customer')}
                        showClear={false}
                        value={ClaCostumerFilter} 
                        options={CostumerList}  
                        optionValue="ClaCostumer"
                        optionLabel="NomCostumer" 
                        onChange={(e) => onChangeCostumer(e)}
                        errors={errors}
                      />  
                    </td>
                    <td width="15%">
                      <FieldButton
                        icon="pi pi-search" 
                        label={t('priceTracker:Search')}
                        className="p-button p-highlight"
                        onClick={getPriceTrackerDetail}
                      >
                      </FieldButton>
                    </td>
                  </>
                )
              }
            </tr>
          </table> 
        </div>
      </Col>
    </Row>
    <Row> 
      <Col lg={6} md={6} sm={12} xs={12}>  
        <div className='p-fluid p-2'>
          <table width="100%">
            <tr>
              <td width="100%">
                <h5>{t('priceTracker:RangePriceBetween')}: [{Highcharts.numberFormat(rangePrice[0],0,'.',',')}-{Highcharts.numberFormat(rangePrice[1],0,'.',',')}] </h5> 
                <Slider
                  min={MinPriceRange}
                  max={MaxPriceRange}
                  step={1}
                  value={rangePrice}
                  onChange={(e) => {
                    setrangePrice(e.value);
                    setNeedsToClicSearchButton(true);
                  }}
                  range
                />
              </td>
            </tr>
          </table>
        </div>
      </Col>
      <Col lg={6} md={6} sm={12} xs={12}>  
        <div className='p-fluid p-2'>
          <table width="100%">
            <tr>
              <td width="100%">
                <h5>{t('priceTracker:RangeTonsBetween')}: [{Highcharts.numberFormat(rangeTons[0],0,'.',',')}-{Highcharts.numberFormat(rangeTons[1],0,'.',',')}]</h5>
                <Slider
                  min={MinTonsRange}
                  max={MaxTonsRange}
                  step={1}
                  value={rangeTons}
                  onChange={(e) => {
                  setrangeTons(e.value);
                  setNeedsToClicSearchButton(true);
                }}
                  range
                />
              </td>
            </tr>
          </table>
        </div>
      </Col>
    </Row>
    <Row className='pb-2' style={{borderBottom:'Solid 1px #CDCDCD'}}>
      <Col>&nbsp;</Col>
    </Row>
  </>
  );

  const onHideDialog = () => { 
    if (props.onHide !== null && typeof props.onHide === 'function') {
      props.onHide();
    }
  };

  const onChangePriceTrackerDetail = (nomnivel) => {
    setNivelProducto(nomnivel);
    // setEsMostrarDialogDetailParent(true);
  }
 

  return (
    <>
      <Dialog
        className='PriceTrackerScreen'
        closable={false}
        visible={props.Visible}
        onHide={onHideDialog}  
        style={{ width: '100vw' }}
        maximized={true}
        header={templateHeaderDialog}
        blockScroll={true}
        draggable={false}
      > 
        {
          NeedsToClicSearchButton ?
          (<><InfoNeedsToBeRefresh onRefresh={getPriceTrackerDetail}></InfoNeedsToBeRefresh></>)
          :
          (
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}> 
                <div className='bgwhite PriceTrackerScreen'>
                  <Row className="align-items-start p-3">
                    <Col lg={12} md={12} sm={12} xs={12} className='text-center'>
                      <Row>
                        <Col>
                          <table width="100%">
                            <tbody>
                              <tr>
                                <td width="10%">
                            &nbsp;
                                </td>
                                {leyendasMapaList.map((element, index) => {  
                            return (  
                              <td key={index}>
                                <table>
                                  <tr>
                                    <td className='pt-2'>
                                      <svg
                                        width="18"
                                        height="18"  
                                        xmlns="http://www.w3.org/2000/svg"
                                      > 
                                        <circle
                                          cx="4"
                                          cy="4"
                                          r="4"  
                                          fill={element.EsSeleccionado? element.ColorHexadecimal : '#CCCCCC'}
                                          stroke="white"
                                          strokeWidth="1"
                                        /> 
                                      </svg> 
                                    </td>
                                    <td>
                                      <span className={element.EsSeleccionado? 'pointfiltermap' : 'pointfiltermapdisabled'} onClick={(e) => onChangeLeyendaSelect(element,index)}>
                                        {element.DescripcionLeyenda}
                                      </span>
                                    </td>
                                  </tr>
                                </table> 
                              </td>
                              );
                            })}  
                                <td width="10%">
                            &nbsp;
                                </td>
                              </tr>
                            </tbody>
                          </table> 
                        </Col>
                      </Row>
                      <div style={{ height: '500px', width: '100%'}}>
                        <PriceTrackerMap
                          markersList={MapMarkersFilterList}
                        >
                        </PriceTrackerMap>
                      </div>
                    </Col> 
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <Row className='pt-3'>
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <HighchartsReact 
                            containerProps={{ style: { height: "400px" } }}
                            highcharts={Highcharts} 
                            options={optionsChartHistory}
                          />      
                        </Col> 
                      </Row>   
                      <Row className='pt-3'>
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <HighchartsReact 
                            containerProps={{ style: { height: "400px" } }}
                            highcharts={Highcharts} 
                            options={optionsChartRegression}
                          />      
                        </Col> 
                      </Row> 
                      <Row className='pt-3'>
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <table>
                            <tbody>
                              <tr>
                                <td className='labelgrafica'>
                                  {t('priceTracker:MinimumAndMaximumPriceOf')}&nbsp;
                                  {props.NivelProducto}&nbsp;-
                                </td>
                                <td>
                                  <FieldDropdown  
                                    name="ClaDivision" 
                                    showClear={false}
                                    value={ClaDivisionFilter} 
                                    options={showproductComparisonUnitPriceList}  
                                    optionValue="NomGrupoEstadistico1"
                                    optionLabel="NomGrupoEstadistico1" 
                                    onChange={(e) => onChangeDivision(e)}
                                    className="DashboardDropdownNivel"
                                    errors={errors}
                                  />  
                                </td>
                              </tr>
                            </tbody>
                          </table> 
                          <HighchartsReact 
                            containerProps={{ style: { height: "400px" } }}
                            highcharts={Highcharts} 
                            options={optionsChartLine}
                          />    
                        </Col> 
                      </Row> 
                    </Col> 
                  </Row> 
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              {props.NivelProducto}&nbsp;-
                            </td>
                            <td>
                              <FieldDropdown
                                showClear={false}
                                name="NomTipo"
                                value={ClaTypeChart} 
                                options={TypeChartList}  
                                optionValue="ClaTipo"
                                optionLabel="NomTipo" 
                                onChange={(e) => onChangeTypeChart(e)}
                                className="DashboardDropdownNivel"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table> 
                      {
                  ClaTypeChart===1 ? (
                    <>
                      <HighchartsReact 
                        containerProps={{ style: { height: "400px" } }}
                        highcharts={Highcharts} 
                        options={optionsChartBarAverangeOfUnit}
                      />
                    </>
                  ):(
                    <>
                    </>
                  )
                }
                      {
                  ClaTypeChart===2 ? (
                    <>
                      <HighchartsReact 
                        containerProps={{ style: { height: "400px" } }}
                        highcharts={Highcharts} 
                        options={optionsChartBarRevenue}
                      />
                    </>
                  ):(
                    <>
                    </>
                  )
                }
                      {
                  ClaTypeChart===3 ? (
                    <>
                      <HighchartsReact 
                        containerProps={{ style: { height: "400px" } }}
                        highcharts={Highcharts} 
                        options={optionsChartBarSumOfTons}
                      />
                    </>
                  ):(
                    <>
                    </>
                  )
                }
                    </Col>
                  </Row> 
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <DataGrid
                        dataSource={productComparisonUnitPriceList}
                        columnAutoWidth={true}
                        allowColumnReordering={true}
                        showBorders={true}
                    // scrolling={true}
                    // paging={false}
                        width="100%"
                      >
                        <Column
                          caption="Description"
                          width={350}
                          cellRender={DescriptionTemplate}
                          fixed={true}
                        />
                        <Column alignment='center' caption={`${t('priceTracker:PorcDiference')} ${props.ClaDivision} ${t('priceTracker:VSDiference')}`}>
                          <Column
                            dataField="477_Difference"
                            caption="WHOLESALE"
                            format={gdpFormat}
                            cellRender={DiffereceTemplate477}
                            width={150}
                            style={{display:'none'}}
                            visible={showWholesale&&props.ClaDivision!=='WHOLESALE'}
                          />
                          <Column
                            dataField="478_Difference"
                            caption="INDUSTRIAL WIRE"
                            format={gdpFormat}
                            cellRender={DiffereceTemplate478}
                            width={150}
                            visible={showIndustrialWire&&props.ClaDivision!=='INDUSTRIAL WIRE'}
                          />
                          <Column
                            dataField="479_Difference"
                            caption="KEY STEEL ACCOUNTS & STEEL FAB"
                            format={gdpFormat}
                            cellRender={DiffereceTemplate479}
                            width={150}
                            style={{display:'none'}}
                            visible={showKeySteelAccountSteel&&props.ClaDivision!=='KEY STEEL ACCOUNTS & STEEL FAB'}
                          />
                          <Column
                            dataField="480_Difference"
                            caption="PRODUCT SPECIALIST"
                            format={gdpFormat}
                            cellRender={DiffereceTemplate480}
                            width={150}
                            style={{display:'none'}}
                            visible={showProductSpecialist&&props.ClaDivision!=='PRODUCT SPECIALIST'}
                          />
                          <Column
                            dataField="481_Difference"
                            caption="RETAIL"
                            format={gdpFormat}
                            cellRender={DiffereceTemplate481}
                            width={150}
                            visible={showRetail&&props.ClaDivision!=='RETAIL'}
                          />
                        </Column>
                        <Column alignment='center' caption="Price">
                          <Column
                            dataField="477_UnitPrice"
                            caption="WHOLESALE"
                            format={gdpFormat}
                            width={150}
                            visible={showWholesale}
                          />
                          <Column
                            dataField="478_UnitPrice"
                            caption="INDUSTRIAL WIRE"
                            format={gdpFormat}
                            width={150}
                            visible={showIndustrialWire}
                          />
                          <Column
                            dataField="479_UnitPrice"
                            caption="KEY STEEL ACCOUNTS & STEEL FAB"
                            format={gdpFormat}
                            width={150}
                            visible={showKeySteelAccountSteel}
                          />
                          <Column
                            dataField="480_UnitPrice"
                            caption="PRODUCT SPECIALIST"
                            format={gdpFormat}
                            width={150}
                            visible={showProductSpecialist}
                          />
                          <Column
                            dataField="481_UnitPrice"
                            caption="RETAIL"
                            format={gdpFormat}
                            width={150}
                            visible={showRetail}
                          />
                        </Column>
                        <Column alignment='center' caption="Tons">
                          <Column
                            dataField="477_Tons"
                            caption="WHOLESALE"
                            format={gdpFormat}
                            width={150}
                            visible={showWholesale}
                          />
                          <Column
                            dataField="478_Tons"
                            caption="INDUSTRIAL WIRE"
                            format={gdpFormat}
                            width={150}
                            visible={showIndustrialWire}
                          />
                          <Column
                            dataField="479_Tons"
                            caption="KEY STEEL ACCOUNTS & STEEL FAB"
                            format={gdpFormat}
                            width={150}
                            visible={showKeySteelAccountSteel}
                          />
                          <Column
                            dataField="480_Tons"
                            caption="PRODUCT SPECIALIST"
                            format={gdpFormat}
                            width={150}
                            visible={showProductSpecialist}
                          />
                          <Column
                            dataField="481_Tons"
                            caption="RETAIL"
                            format={gdpFormat}
                            width={150}
                            visible={showRetail}
                          />
                        </Column>
                        <Column alignment='center' caption="Spread">
                          <Column
                            dataField="477_Spread"
                            caption="WHOLESALE"
                            format={gdpFormat}
                            width={150}
                            visible={showWholesale}
                          />
                          <Column
                            dataField="478_Spread"
                            caption="INDUSTRIAL WIRE"
                            format={gdpFormat}
                            width={150}
                            visible={showIndustrialWire}
                          />
                          <Column
                            dataField="479_Spread"
                            caption="KEY STEEL ACCOUNTS & STEEL FAB"
                            format={gdpFormat}
                            width={150}
                            visible={showKeySteelAccountSteel}
                          />
                          <Column
                            dataField="480_Spread"
                            caption="PRODUCT SPECIALIST"
                            format={gdpFormat}
                            width={150}
                            visible={showProductSpecialist}
                          />
                          <Column
                            dataField="481_Spread"
                            caption="RETAIL"
                            format={gdpFormat}
                            width={150}
                            visible={showRetail}
                          />
                        </Column> 
                      </DataGrid>
                    </Col>
                  </Row> 
                  <Row>
                    <Col>
                      <p>&nbsp;</p>
                    </Col>
                  </Row>
                  {
                EsMostrarDialogDetailParent && 
                (
                  <PriceTrackerDetail
                    Visible={EsMostrarDialogDetailParent}
                    onHide={()=> setEsMostrarDialogDetailParent(false)}
                    onChangePriceTrackerDetail={onChangePriceTrackerDetail}
                    NomPeriodo={props.NomPeriodo} 
                    ClaPeriodo={props.ClaPeriodo} 
                    ClaDivision={props.ClaDivision} 
                    ClaNivel={ClaNivel}
                    ClaNivelProducto={props.ClaNivelProducto}
                    NivelProducto={NivelProducto}
                    ClaTipo={props.ClaTipo}
                    NomNivel={props.NomNivel}
                    EsPrecioNeto={props.EsPrecioNeto} 
                    ClaZonaFilter={ClaZonaFilter}
                    ClaStateFilter={ClaStateFilter}
                  >
                  </PriceTrackerDetail>
                )
              } 
                  {
                EsMostrarDialogDetailInvoice && 
                (
                  <PriceTrackerDetailInvoices
                    Visible={EsMostrarDialogDetailInvoice}
                    onHide={()=> setEsMostrarDialogDetailInvoice(false)}
                    NomPeriodo={props.NomPeriodo} 
                    AnioMes={ClaPeriodoInvoice}
                    ClaPeriodo={props.ClaPeriodo}  
                    ClaDivision={NomDivisionInvoice} 
                    ClaNivel={props.ClaNivel}
                    ClaNivelProducto={props.ClaNivelProducto}
                    NivelProducto={props.NivelProducto}
                    ClaTipo={props.ClaTipo}
                    NomNivel={props.NomNivel}
                    ClaCustumer={ClaCuCustomerInvoice}
                    CustomerInvoice={CustomerInvoice}
                    EsPrecioNeto={props.EsPrecioNeto}
                  >
                  </PriceTrackerDetailInvoices>
                )
              } 
                </div>
              </Col>
            </Row>
          )
        }
        
      </Dialog>   
    </>
  );
};
export default PriceTrackerDetail;

/* eslint-disable object-shorthand */
import React, { useState, useEffect } from 'react';
import { config } from '../../../utils/config';
import { callApi } from '../../../utils/utils';
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  CardTitle 
} from 'reactstrap';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official'; 
import SparkLine from '../../../components/Charts/SparkLine/SparkLine.jsx'
import { useTranslation } from 'react-i18next';
import { InputSwitch } from 'primereact/inputswitch';
import { ValueErrorBar } from 'devextreme-react/range-selector';

const ElementosCostos = (props) => { 
  const { t } = useTranslation(['formulario','common']);
  const [barCharCategories, setBarCharCategories] = useState({});
  const [barCharSeries, setBarCharSeries] = useState({});
  const [sparkLinesChartValues, setSparkLinesChartValues] = useState([]);
  const [chartTitle, setChartTitle] = useState('');

  
  const [esPorTonelada, setEsPorTonelada] = useState(true);
  const [byTonLabel, setByTonLabel] = useState(`${t('formulario:RealCost')} by MTon`);

  useEffect(() => {  
    const GetDetalleElementService = `${config.UrlApiProject}Costos/GetDetalleGraficoElement`;
    const GetDetalleElementMonthService = `${config.UrlApiProject}Costos/GetDetalleGraficoElementMonth`;
    const paramsToService = {
      anio: props.filters.Anio,
      anioMes: props.filters.AnioMes,
      claTipoElementoCosto: props.filters.ClaTipoElementoCosto,
      claDepartamento:props.filters.ClaDepartamento,
      claTipoCelda:props.filters.ClaTipoCelda,
      claCelda:props.filters.ClaCelda,
      claArticulo:props.filters.ClaArticulo,
      EsPorTonelada: esPorTonelada,
      TipoConversionCost: props.filters.TipoConversionCost
    }; 

    console.log(paramsToService);

    callApi(GetDetalleElementService, 'POST', paramsToService, (response) => {
      setBarCharCategories(response.data.categories); 
      setBarCharSeries(response.data.series.data); 
      callApi(GetDetalleElementMonthService, 'POST', paramsToService, (response) => {
        setSparkLinesChartValues(response.data.series); 
        const GetCeldaTitleService = `${config.UrlApiProject}Costos/GetCeldaElementTitle`;  
        callApi(GetCeldaTitleService, 'POST', paramsToService, (response) => { 
          if(response.data.info!==undefined)
          {  
            setChartTitle(`${response.data.info[0].NomFecha }`);  
          }     
          });
      });
    });
    
  }, [props.filters, esPorTonelada]); 

  const onChangeByton = (event) => {  
    console.log(event.value);
    setEsPorTonelada(event.value);

    if (event.value){
      setByTonLabel(`${t('formulario:RealCost')} by MTon`);
    }
    else{
      setByTonLabel(`${t('formulario:RealCost')} $ USD`);
    }
  } 

  const barChart = {
    chart: {
      height: 480,
      type: 'bar',
    },
    title: {
      text: '',
    },  
    xAxis: {  
      gridLineWidth:0,
      lineWidth: 1,
      lineColor: '#dddfe1',
      categories: barCharCategories,
      title: {
        text: null,
      },
      labels: {
      }
    },
    yAxis: { 
      gridLineWidth:0,
      lineWidth: 1,
      lineColor: '#dddfe1',
      title: {
        text: null,
      },
      /* min: 0, */ 
      labels: {
        overflow: 'justify',
        enabled:false,
      },
    },
    tooltip: {
      valuePrefix: ' $',
      valueDecimals: 1
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      x: -40,
      y: 80,
      floating: true,
      borderWidth: 1,
      backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
      shadow: true,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: '',
        data: barCharSeries,  
        showInLegend: false, 
        dataLabels:{
            enabled:true,  
            formatter: function() {  
                const { x, y, point, series } = this; 
                return `$ ${Highcharts.numberFormat(y,2)  }`;
              }
        }
      },
    ], 
  };
  if (props.showHide) {
    return (
      <>
        <Row className="align-items-start">
          <Col lg={5} md={5} sm={12} xs={12}>
            <Card>
              <CardHeader className="hard-blue">
                <CardTitle className="text-center">{chartTitle} -  {byTonLabel}</CardTitle>
              </CardHeader>    
              <CardBody>  
                <HighchartsReact highcharts={Highcharts} options={barChart} />
              </CardBody> 
            </Card>
          </Col>
          <Col lg={7} md={7} sm={12} xs={12}> 
            <Card>
              <CardHeader className="hard-blue">
                <CardTitle className="text-center"> {chartTitle} - {t('formulario:TrendLast12Months')} -  {byTonLabel} <InputSwitch style={{height:'15px'}} checked={esPorTonelada} onChange={onChangeByton} />  </CardTitle>
              </CardHeader>    
              <CardBody>  
                <Row className="align-items-start">
                  {sparkLinesChartValues.map((bucket) => { 
                const options = {
                  xAxis: { 
                      categories: bucket.categories
                  },
                  yAxis: {  
                    categories: ['Real'] 
                  },
                  series: [{ 
                    name: 'Real',
                    data: bucket.serieReal 
                  } 
                 ],
            
                  tooltip: {
                    headerFormat: `<span style="font-sze:10px"><b>${bucket.name} ({series.name})</b> <br/> <b>{point.x}</b>: </span>`,
                    pointFormat: '{point.y}'
                  },
                  chart: {
                    type: 'area'
                  }
                }

                return (
                  <Col lg={4} md={4} sm={6} xs={6} key={bucket.name}> 
                    <Card>
                      <CardHeader className="bg-card-header-grid">
                        <CardTitle className="text-center">{bucket.name}</CardTitle>
                      </CardHeader>    
                      <CardBody>    
                        <SparkLine options={options} color="#ffb21f" />
                      </CardBody> 
                    </Card>
                  </Col>
                );
              })} 
                
                </Row>
              </CardBody> 
            </Card>
          </Col>
        </Row>
      </>
    );
  }
  if (!props.showHide) {
    return <></>;
  }
};
export default ElementosCostos;

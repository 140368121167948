/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react'; 
import { config } from '../../../utils/config';
import { callApi, showSweetAlert } from '../../../utils/utils'; 
import { useTranslation } from 'react-i18next'; 
import PageContent from '../../../layouts/PageContent'; 
import { Toolbar } from 'primereact/toolbar'; 
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row, Col, Card, CardBody } from 'reactstrap';
import FieldNumber from '../../../components/Controls/FieldNumber.jsx'
import FieldText from '../../../components/Controls/FieldText.jsx'
import { Divider } from 'primereact/divider';
import FieldToogleSwitch from '../../../components/Controls/FieldToogleSwitch/FieldToogleSwitch' 
import PerformanceDevQuestionario from './PerformanceDevQuestionario';  
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import FieldCalendar from '../../../components/Controls/FieldCalendar';
import { Calendar } from 'primereact/calendar';
import { LegendTitleSubtitle } from 'devextreme-react/bar-gauge';
import FieldDropdown from '../../../components/Controls/FieldDropdown.jsx';
import swal from '@sweetalert/with-react';
import SweatAlertCustomIcon from 'components/SweatAlert/SweatAlertCustomIcon';
import PerformanceDevCriterio from './PerformanceDevCriterio';

const PerformanceDevSetUpDetail = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);
  const [yesNoOptions, setYesNoOptions] = useState([`${t('common:On')}`, `${t('common:Off')}`]);
  const [ActivaEmployeeSelfReview, setActivaEmployeeSelfReview] = useState(false);
  const [ActivaManagerReview, setActivaManagerReview] = useState(false);
  const [ActivaAnnualReview, setActivaAnnualReview] = useState(false);
  const [Activa180DegreeReview, setActiva180DegreeReview] = useState(false);
  const [ActivaIDPTool, setActivaIDPTool] = useState(false);
  const [Anio, setAnio] = useState(new Date().getFullYear());
  const [errors, setErrors] = useState({});  
  const [EmployeeReviewQuestionario, setEmployeeReviewQuestionario] = useState([]); 
  const [ManagerQuestionario, setManagerQuestionario] = useState([]); 
  const [IdPerformanceDev, setIdPerformanceDev] = useState(null); 
  const [employeeSelfReviewRange, setEmployeeSelfReviewRange] = useState([]);
  const [managerReviewRange, setManagerReviewRange] = useState([]);
  const [c180DegreeReviewRange, setC180DegreeReviewRange] = useState([]);
  const [annualReviewRange, setAnnualReviewRange] = useState([]);
  const [IDPRange, setIDPRange] = useState([]);
  const [EncuestasList, setEncuestasList] = useState([]);
  const [IdEncuesta, setIdEncuesta] = useState(null);
  const [ActivaRecibirFeedbackCultureSurvey, setActivaRecibirFeedbackCultureSurvey] = useState(false);
  const [ActivaDarFeedbackCultureSurvey, setActivaDarFeedbackCultureSurvey] = useState(false);
  const [PerformanceCriterio, setPerformanceCriterio] = useState([]); 
  
  const rightToolbarTemplate = () => {
    return (
      <>
        <FieldButton label={t('common:Back')} icon="pi pi-arrow-left" className="p-button-secondary p-mr-2" onClick={onReturnClick} />
      &nbsp;
        <FieldButton label={t('common:Save')} icon="pi pi-save" className="p-button-success p-mr-2" onClick={onSaveClick} /> 
        
      </>
    )
  } 

  const closeDetailView = () => { 
    if (props.onCloseDetailView !== undefined){
      props.onCloseDetailView();
    }
  }

  const onReturnClick = () => { 
    // avisamos al componente padre que debemos de cerrar el detalle 
    closeDetailView();
  } 

  const onEmployeeSelfReviewRangeChange = (value) => {  
    const newErrors = { ...errors }
    delete newErrors.employeeSelfReviewRange;   
    setErrors(newErrors)
    setEmployeeSelfReviewRange(value);
  } 

  const onIDPRange = (value) => {  
    const newErrors = { ...errors }
    delete newErrors.IDPRange;   
    setErrors(newErrors)
    setIDPRange(value);
  } 
  

  const onManagerReviewRangeChange = (value) => {  
    const newErrors = { ...errors }
    delete newErrors.managerReviewRange;   
    setErrors(newErrors)
    setManagerReviewRange(value);
  } 

  const on180DegreeFeedbackRangeChange = (value) => {  
    const newErrors = { ...errors }
    delete newErrors.c180DegreeReviewRange;   
    setErrors(newErrors)
    setC180DegreeReviewRange(value);
  } 

  const onAnnualReviewRangeChange = (value) => {  
    const newErrors = { ...errors }
    delete newErrors.annualReviewRange;   
    setErrors(newErrors)
    setAnnualReviewRange(value);
  } 

  const valitationScheme = yup.object().shape({
    Anio: yup.string().required(t('common:RequiredField')).nullable(), 
    EmployeeReviewQuestionario: yup.array().of(
      yup.object().shape({  
        DescripcionPregunta: yup.string().when('BajaLogica', {is: 0,
          then: yup.string().trim().required(t('common:RequiredField')).nullable(),
          otherwise: yup.string().nullable()
        }) 
      })
    ), 
    ManagerQuestionario: yup.array().of(
      yup.object().shape({  
        DescripcionPregunta: yup.string().when('BajaLogica', {is: 0,
          then: yup.string().trim().required(t('common:RequiredField')).nullable(),
          otherwise: yup.string().nullable()
        }) 
      })
    ),
    PerformanceCriterio: yup.array().of(
      yup.object().shape({  
        ClaPerformanceCriterio: yup.mixed().when('BajaLogica', {is: 0,
          then: yup.mixed().required(t('common:RequiredField')).nullable(),
          otherwise: yup.string().nullable()
        }) 
      })
    )
  });

  const onSendReceivingFeedbackNotificationClick = async ()  => { 
    const paramsService = {
      IdPerformanceDev  
    };
    const SaveCfgPerformanceDev = `${config.UrlApiProject}HR/SendReceivingFeedbackNotificationCfgPerformanceDev`;
    callApi(SaveCfgPerformanceDev, 'POST', paramsService, (response) => { 
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
   });   
  } 

  const getPerformanceCriterio = (rows) => {
    const _rows = [];
    if (rows.length>0) {
      rows.forEach((item) => {
        if (item.ClaPerformanceCriterio>0) {
          _rows.push({...item});
        }
      });
    } else {
      return [];
    }
    return _rows; 
  };
  
  const onSaveClick = async ()  => { 
    setErrors({});

    const fields = {
      Anio,
      employeeSelfReviewRange,
      EmployeeReviewQuestionario,
      ManagerQuestionario,
      PerformanceCriterio
    }
    const validator = new YupValidator(valitationScheme);
    
    let esValido = await validator.validate(fields);
     

    if (IDPRange === null || IDPRange.length === 0 ){
      esValido = false;

      
      validator.errors = { ...validator.errors, IDPRange : {
        message: t('common:RequiredField'),
        type: "required"
      } }  
    }
    
    if (IDPRange[0] === null || IDPRange[1] === null ){
      esValido = false;
      validator.errors = { ...validator.errors, IDPRange : {
        message: t('common:StartDateEndDateRequired'),
        type: "required"
      } } 
    }  

    // if (ActivaEmployeeSelfReview){
      // console.log(employeeSelfReviewRange);
    if (employeeSelfReviewRange === null || employeeSelfReviewRange.length === 0 ){
      esValido = false;

      
      validator.errors = { ...validator.errors, employeeSelfReviewRange : {
        message: t('common:RequiredField'),
        type: "required"
      } }  
    }
    
    if (employeeSelfReviewRange[0] === null || employeeSelfReviewRange[1] === null ){
      esValido = false;
      validator.errors = { ...validator.errors, employeeSelfReviewRange : {
        message: t('common:StartDateEndDateRequired'),
        type: "required"
      } } 
    }  
    // }

    if (managerReviewRange === null || managerReviewRange.length === 0 ){
      esValido = false;

      
      validator.errors = { ...validator.errors, managerReviewRange : {
        message: t('common:RequiredField'),
        type: "required"
      } }  
    }
    
    if (managerReviewRange[0] === null || managerReviewRange[1] === null ){
      esValido = false;
      validator.errors = { ...validator.errors, managerReviewRange : {
        message: t('common:StartDateEndDateRequired'),
        type: "required"
      } } 
    }  

    if (c180DegreeReviewRange === null || c180DegreeReviewRange.length === 0 ){
      esValido = false;

      
      validator.errors = { ...validator.errors, c180DegreeReviewRange : {
        message: t('common:RequiredField'),
        type: "required"
      } }  
    }
    
    if (c180DegreeReviewRange[0] === null || c180DegreeReviewRange[1] === null ){
      esValido = false;
      validator.errors = { ...validator.errors, c180DegreeReviewRange : {
        message: t('common:StartDateEndDateRequired'),
        type: "required"
      } } 
    }  

    if (annualReviewRange === null || annualReviewRange.length === 0 ){
      esValido = false;

      
      validator.errors = { ...validator.errors, annualReviewRange : {
        message: t('common:RequiredField'),
        type: "required"
      } }  
    }
    
    if (annualReviewRange[0] === null || annualReviewRange[1] === null ){
      esValido = false;
      validator.errors = { ...validator.errors, annualReviewRange : {
        message: t('common:StartDateEndDateRequired'),
        type: "required"
      } } 
    }  

    if (PerformanceCriterio === null || PerformanceCriterio.length === 0 ){
      esValido = false;
      
    }
    
    if (!esValido){   
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
      setErrors(validator.errors); 
      return;
    }

    // verificamos los campos condicionales de los rangos

    

    const paramsService = {
       Anio
      ,IdPerformanceDev   
      ,Activa180DegreeReview  
      ,ActivaEmployeeSelfReview  
      ,ActivaManagerReview 
      ,ActivaAnnualReview 
      ,ActivaIDPTool
      ,PerformanceDevEmployeeQuestionnarie:EmployeeReviewQuestionario
      ,PerformanceDevManagerQuestionnarie:ManagerQuestionario
      ,FechaIniciaEmployeeSelfReview: employeeSelfReviewRange[0].toDateString()
      ,FechaTerminaEmployeeSelfReview:  employeeSelfReviewRange[1].toDateString()
      ,FechaIniciaManagerReview: managerReviewRange[0].toDateString()
      ,FechaTerminaManagerReview: managerReviewRange[1].toDateString()
      ,FechaInicia180Degree: c180DegreeReviewRange[0].toDateString()
      ,FechaTermina180Degree: c180DegreeReviewRange[1].toDateString()
      ,FechaIniciaAnnualReview: annualReviewRange[0].toDateString()
      ,FechaTerminaAnnualReview: annualReviewRange[1].toDateString() 
      ,FechaIniciaIDPTool: IDPRange[0].toDateString()
      ,FechaTerminaIDPTool: IDPRange[1].toDateString()
      ,IdEncuesta
      ,ActivaRecibirFeedbackCultureSurvey
      ,ActivaDarFeedbackCultureSurvey
      ,PerformanceDevCriterio:getPerformanceCriterio(PerformanceCriterio)
    };
  
    
    const SaveCfgPerformanceDev = `${config.UrlApiProject}HR/SaveCfgPerformanceDev`;

    callApi(SaveCfgPerformanceDev, 'POST', paramsService, (response) => { 
      // toast.current.onShow = closeDetailView();  
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      closeDetailView(true);
   });   
  } 

  const leftToolbarTemplate = () => {
    return (
      <></>
      )
  }

  const onAnioChange = (e) => {  
    const newErrors = { ...errors }
    delete newErrors.Anio;   
    setErrors(newErrors);
    setAnio(e.target.value);
  }


  useEffect(() => {  
    const GetCfgPerformanceDev = `${config.UrlApiProject}HR/GetCfgPerformanceDev?IdPerformanceDev=${props.filters.IdPerformanceDev !== undefined ? props.filters.IdPerformanceDev : ''}`; 
    
    const paramsToService = {  
    }; 
 
    callApi(GetCfgPerformanceDev, 'GET', paramsToService, (response) => {
      GetEncuestaList();
      setEmployeeReviewQuestionario(response.data.PerformanceDevEmployeeQuestionnarie);
      setIdEncuesta(null);
      
      setManagerQuestionario(response.data.PerformanceDevManagerQuestionnarie);
      setPerformanceCriterio(response.data.PerformanceCriterio);

      if (response.data.PerformanceDev.length > 0)
      {
        setIdPerformanceDev(response.data.PerformanceDev[0].IdPerformanceDev);
        setAnio(response.data.PerformanceDev[0].Anio);
        setActiva180DegreeReview(!!(response.data.PerformanceDev[0].Activa180DegreeReview === 1 || response.data.PerformanceDev[0].Activa180DegreeReview));  
        setActivaEmployeeSelfReview(!!(response.data.PerformanceDev[0].ActivaEmployeeSelfReview === 1 || response.data.PerformanceDev[0].ActivaEmployeeSelfReview));  
        setActivaManagerReview(!!(response.data.PerformanceDev[0].ActivaManagerReview === 1 || response.data.PerformanceDev[0].ActivaManagerReview)); 
        setActivaAnnualReview(!!(response.data.PerformanceDev[0].ActivaAnnualReview === 1 || response.data.PerformanceDev[0].ActivaAnnualReview)); 
        setActivaIDPTool(!!(response.data.PerformanceDev[0].ActivaIDPTool === 1 || response.data.PerformanceDev[0].ActivaIDPTool));
        setIdEncuesta(response.data.PerformanceDev[0].IdEncuesta);
        const empSelfRango = [];
        const managerRango = [];
        const cDegreeRango = [];
        const annualRango = []; 
        const idpRango = []; 

        empSelfRango[0] = new Date(response.data.PerformanceDev[0].FechaIniciaEmployeeSelfReview);
        empSelfRango[1] = new Date(response.data.PerformanceDev[0].FechaTerminaEmployeeSelfReview);

        setEmployeeSelfReviewRange(empSelfRango); 
        managerRango[0] = new Date(response.data.PerformanceDev[0].FechaIniciaManagerReview);
        managerRango[1] = new Date(response.data.PerformanceDev[0].FechaTerminaManagerReview);
        setManagerReviewRange(managerRango);
        cDegreeRango[0] = new Date(response.data.PerformanceDev[0].FechaInicia180Degree);
        cDegreeRango[1] = new Date(response.data.PerformanceDev[0].FechaTermina180Degree);
        setC180DegreeReviewRange(cDegreeRango);
        annualRango[0] = new Date(response.data.PerformanceDev[0].FechaIniciaAnnualReview);
        annualRango[1] = new Date(response.data.PerformanceDev[0].FechaTerminaAnnualReview);
        setAnnualReviewRange(annualRango);

        idpRango[0] = new Date(response.data.PerformanceDev[0].FechaIniciaIDPTool);
        idpRango[1] = new Date(response.data.PerformanceDev[0].FechaTerminaIDPTool);
        setIDPRange(idpRango);

        setActivaRecibirFeedbackCultureSurvey(!!(response.data.PerformanceDev[0].ActivaRecibirFeedbackCultureSurvey === 1 || response.data.PerformanceDev[0].ActivaRecibirFeedbackCultureSurvey))
        setActivaDarFeedbackCultureSurvey(!!(response.data.PerformanceDev[0].ActivaDarFeedbackCultureSurvey === 1 || response.data.PerformanceDev[0].ActivaDarFeedbackCultureSurvey))
        
      } 
      
    });
     
  }, [props.filters]); 

  const onChangeEncuesta = (value) => {  
    const newErrors = { ...errors } 
    delete newErrors.IdEncuesta;   
    setErrors(newErrors)
    setIdEncuesta(value);
  }  

  const GetEncuestaList = () => {
    // ClaEncuestaCategoria = Culture Survey
    const GetEncuestasCmb = `${config.UrlApiProject}HR/GetEncuestasCmb?ClaEncuestaCategoria=${1}`;     
    const paramsToService = {};
    callApi(GetEncuestasCmb, 'GET', paramsToService, (response) => {
      setEncuestasList(response.data.EncuestasList);
    });
  };

  const sendNotificationConfirmationHTML = (
    <div>
      <SweatAlertCustomIcon title={t('common:SubmitConfirmation')} letter={t('common:QuestionMark')}></SweatAlertCustomIcon> 
      <div>{t('hr:SendRecivingFeedBackNotification')}.</div> 
    </div>  
   ) 
  
 
const onConfirmSendReceivingFeedbackNotification = () => {
  swal({ 
    buttons: {
      cancelar: {
        text: t('common:Cancel'),
        value: "cancel",
        className: "rounded-button danger"
      },
      continuar: {
        text: t('common:Continue'),
        value: "continue",
        className: "rounded-button light-green"
      }
    },
    content: sendNotificationConfirmationHTML
  }).then((value) => {
    switch (value) { 
      case "continue":
        onSendReceivingFeedbackNotificationClick();
        break; 
      default:
        break;
    }
  });
}

  return (
    <> 
      <Card>   
        <CardBody> 
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>  
              <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
            &nbsp;
            </Col> 
          </Row>
          <Row>
            <Col lg={3} md={6} sm={6} xs={12}>  
              <Row>
                <Col lg={6} md={6} sm={6} xs={12}> 
                  <h2> <label>1.- {t('hr:SelectYear')} </label> </h2>
                </Col>
                <Col lg={6} md={6} sm={6} xs={12}> 
                  <div className="p-fluid">
                    <FieldNumber 
                      name="Anio" 
                      value={Anio}
                      onChange={onAnioChange}
                      showButtons={true}
                      min={0}
                      useGrouping={false}
                      buttonLayout="horizontal"
                      errors={errors}
                      disabled={IdPerformanceDev>0}
                    /> 
                  </div>
                  
                </Col>
              </Row>   
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Row> 
                <Col lg={8} md={12} sm={12} xs={12}>
                  <h2> <label>2.- {t('hr:IdPTool')} </label></h2>
                  <div><small><label><b>{t('hr:IdPTool')}</b> {t('hr:TurnOnSection')}</label></small></div>
                </Col>
                <Col lg={4} md={12} sm={12} xs={12} className="text-right">
                  <FieldToogleSwitch
                    id="ActivaIDPTool"
                    checked={ActivaIDPTool}
                    onChange={setActivaIDPTool} 
                    optionLabels={yesNoOptions}
                  />
                </Col> 
              </Row>
              <Row>
                <Col lg={8} md={6} sm={12} xs={12} className="text-right">
                </Col>  
                <Col lg={4} md={6} sm={12} xs={12} className="text-right">
                  <div><label>{t('hr:IDPToolRange')}</label></div>
                  <div className="p-fluid">
                    <FieldCalendar 
                      name="IDPRange"
                      value={IDPRange}
                      onChange={onIDPRange} 
                      selectionMode="range"
                      errors={errors}
                    />   
                  </div>
              
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Row className='align-items-start'> 
                <Col lg={12} md={12} sm={12} xs={12}>
                  <Row>
                    <Col lg={8} md={8} sm={12} xs={12}>
                      <h2> <label>3.- {t('hr:180Degree')} </label></h2>
                      <div><small><label><b>{t('hr:180Degree')}</b> {t('hr:TurnOnSection')}</label></small></div>
                 
                    </Col>
                    <Col lg={4} md={4} sm={12} xs={12}>
                      <FieldToogleSwitch
                        id="Activa180DegreeReview"
                        checked={Activa180DegreeReview}
                        onChange={setActiva180DegreeReview} 
                        optionLabels={yesNoOptions}
                      /> 
                    </Col>
                  </Row>
                  {
                    Anio < 2022 ? (<></>) :
                    (
                      <>
                        <Row>
                          <Col lg={8} md={8} sm={12} xs={12}>
                            <div className="p-fluid pt-3">
                              <FieldDropdown
                                name="TituloEncuesta"
                                value={IdEncuesta}
                                options={EncuestasList}
                                optionValue="IdEncuesta"
                                optionLabel="Titulo"
                                onChange={(e) => onChangeEncuesta(e.value)}
                              />
                            </div>  
                          </Col>
                          <Col lg={4} md={4} sm={12} xs={12}>
                            &nbsp;
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={8} md={8} sm={12} xs={12}>
                            <div className="p-fluid pt-3">
                              <label>{t('hr:ActivateGivingFeedback')}</label>&nbsp;&nbsp;&nbsp;&nbsp;
                              <FieldToogleSwitch
                                id="ActivaDarFeedbackCultureSurvey"
                                checked={ActivaDarFeedbackCultureSurvey}
                                onChange={setActivaDarFeedbackCultureSurvey} 
                                optionLabels={yesNoOptions}
                              />
                            </div>
                          </Col>
                          <Col lg={4} md={4} sm={12} xs={12}>
                            &nbsp;
                          </Col>
                        </Row> 
                        <Row className='pb-3'>
                          <Col lg={8} md={8} sm={12} xs={12}>
                            <div className="p-fluid pt-3">
                              <label>{t('hr:ActivateReceivingFeedback')}</label>&nbsp;&nbsp;&nbsp;&nbsp;
                              <FieldToogleSwitch
                                id="ActivaRecibirFeedbackCultureSurvey"
                                checked={ActivaRecibirFeedbackCultureSurvey}
                                onChange={setActivaRecibirFeedbackCultureSurvey} 
                                optionLabels={yesNoOptions}
                              />
                            </div>
                          </Col>
                          <Col lg={4} md={4} sm={12} xs={12} className='text-right'>
                            {
                                IdPerformanceDev > 0 && ActivaRecibirFeedbackCultureSurvey ? (
                                  <>
                                    <FieldButton
                                      label={t('hr:SendReceivingFeedbackNotification')} 
                                      icon="pi pi-send" 
                                      className="p-button-info p-button-sm" 
                                      onClick={onConfirmSendReceivingFeedbackNotification}
                                    /> 
                                  </>
                                ):(
                                  <>
                                  </>
                                )
                              }
                          </Col>
                        </Row>
                      </>
                    )
                  } 
                </Col> 
              </Row>
              <Row>
                <Col lg={8} md={6} sm={12} xs={12} className="text-right">
                </Col>  
                <Col lg={4} md={6} sm={12} xs={12} className="text-right">
                  <div><label>{t('hr:c180DegreeRange')}</label></div>
                  <div className="p-fluid">
                    <FieldCalendar 
                      name="c180DegreeReviewRange"
                      value={c180DegreeReviewRange}
                      onChange={on180DegreeFeedbackRangeChange} 
                      selectionMode="range"
                      errors={errors}
                    />   
                  </div>
              
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider />
          <Row> 
            <Col lg={8} md={8} sm={12} xs={12}>
              <h2> <label>4.- {t('hr:EmployeeSelfReview')} </label></h2>
              <div><small><label><b>{t('hr:ManagerReview')}</b> {t('hr:TurnOnSection')}</label></small></div>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12} className="text-right">
              <FieldToogleSwitch
                id="ActivaEmployeeSelfReview"
                checked={ActivaEmployeeSelfReview}
                onChange={setActivaEmployeeSelfReview} 
                optionLabels={yesNoOptions}
              />
            </Col> 
          </Row> 
          <Row>
            <Col lg={10} md={8} sm={12} xs={12} className="text-right">
            </Col>  
            <Col lg={2} md={4} sm={12} xs={12} className="text-right">
              <div><label>{t('hr:EmployeeSelfReviewRange')}</label></div>
              <div className="p-fluid">
                <FieldCalendar 
                  name="employeeSelfReviewRange"
                  value={employeeSelfReviewRange}
                  onChange={onEmployeeSelfReviewRangeChange} 
                  selectionMode="range"
                  errors={errors}
                />   
              </div>
              

              
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <PerformanceDevQuestionario questionario={EmployeeReviewQuestionario} errors={errors} name="EmployeeReviewQuestionario" onChange={(questionario) => setEmployeeReviewQuestionario(questionario)}></PerformanceDevQuestionario>
            </Col>  
          </Row>
          <Divider />
          <Row> 
            <Col lg={8} md={8} sm={12} xs={12}>
              <h2> <label>5.- {t('hr:ManagerReview')}</label></h2>
              <div><small><label><b>{t('hr:ManagerReview')}</b> {t('hr:TurnOnSection')}</label></small></div>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12} className="text-right">
              <FieldToogleSwitch
                id="ActivaManagerReview"
                checked={ActivaManagerReview}
                onChange={setActivaManagerReview} 
                optionLabels={yesNoOptions}
              />
            </Col> 
          </Row>
          <Row>
            <Col lg={10} md={8} sm={12} xs={12} className="text-right">
            </Col>  
            <Col lg={2} md={4} sm={12} xs={12} className="text-right">
              <div><label>{t('hr:ManagerReviewRange')}</label></div>
              <div className="p-fluid">
                <FieldCalendar 
                  name="managerReviewRange"
                  value={managerReviewRange}
                  onChange={onManagerReviewRangeChange} 
                  selectionMode="range"
                  errors={errors}
                />   
              </div>
              
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <PerformanceDevQuestionario questionario={ManagerQuestionario} name="ManagerQuestionario" errors={errors} onChange={(questionario) => setManagerQuestionario(questionario)}></PerformanceDevQuestionario>
            </Col>  
          </Row>
          <Divider />
          <Row> 
            <Col lg={6} md={6} sm={12} xs={12}>
              <Row> 
                <Col lg={8} md={12} sm={12} xs={12}>
                  <h2> <label>6.- {t('hr:AnnualReview')}</label></h2>
                  <div><small><label><b>{t('hr:AnnualReview')}</b> {t('hr:TurnOnSection')} </label></small></div>
                </Col>
                <Col lg={4} md={12} sm={12} xs={12} className="text-right">
                  <FieldToogleSwitch
                    id="ActivaAnnualReview"
                    checked={ActivaAnnualReview}
                    onChange={setActivaAnnualReview} 
                    optionLabels={yesNoOptions}
                  />
                </Col> 
              </Row>
              <Row>
                <Col lg={8} md={6} sm={12} xs={12} className="text-right">
                </Col>  
                <Col lg={4} md={6} sm={12} xs={12} className="text-right">
                  <div><label>{t('hr:AnnualReviewRange')}</label></div>
                  <div className="p-fluid">
                    <FieldCalendar 
                      name="annualReviewRange"
                      value={annualReviewRange}
                      onChange={onAnnualReviewRangeChange} 
                      selectionMode="range"
                      errors={errors}
                    />   
                  </div>
              
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              
            </Col>
          </Row>
          <Divider />
          <Row> 
            <Col lg={12} md={12} sm={12} xs={12}>
              <Row> 
                <Col lg={12} md={12} sm={12} xs={12}>
                  <h2> <label>7.- {t('hr:PerformanceCriterio')}</label></h2>
                  <div><small><label><b>{t('hr:PerformanceCriterioStep')}</b> {t('hr:SubtituloCriterioStep')} </label></small></div>
                </Col>
              </Row>
            </Col> 
          </Row>   
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <PerformanceDevCriterio 
                rowsCriterio={PerformanceCriterio} 
                name="PerformanceCriterio" 
                errors={errors} 
                onChange={(criterio) => setPerformanceCriterio(criterio)}
              >
              </PerformanceDevCriterio>
            </Col>  
          </Row>
          <Divider />
        </CardBody>
      </Card>
    </>
  );
}; 
export default PerformanceDevSetUpDetail;



import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardImg,
    CardImgOverlay,
    CardTitle,
    CardText,
    ListGroupItem,
    ListGroup,
    Row,
    Col
} from "reactstrap";
import './SolicitudMaterialCard.scss';
import moment from 'moment';

function SolicitudMaterialCard(request, t, key) {

  const FechaSolicitudFormatted = moment(request.FechaSolicitud).format('M/DD/YY h:mm A');

  return (
    <Card key={key}>
      <CardHeader className="solicitud-header">
        {`${request.NomAreaProduccionIngles} - ${t('operations:cellLabel')} #${request.ClaveCelda}`}
      </CardHeader>
      <CardBody className="solicitud-body">
        <Row className="align-items-start">
          <Col>
            <Row>{`${t('operations:itemLabel')}: ${request.NomTipoMaterial} ${request.ClaveArticulo === null ? '' : `- ${request.ClaveArticulo}`}`}</Row>
            <Row>{`${t('operations:quantityLabel')}: ${request.Quantity}`}</Row>
            <Row>{`${t('operations:idLabel')}: ${request.SolicitudMaterialCeldaID}`}</Row>
          </Col>
        </Row>
      </CardBody>
      <CardFooter className="solicitud-footer">
        {`${t('operations:requestedLabel')}: ${FechaSolicitudFormatted}`}
      </CardFooter>
    </Card>
  );
};

  export default SolicitudMaterialCard;
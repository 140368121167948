/* eslint-disable react/no-this-in-sfc */
/* eslint-disable jsx-a11y/label-has-associated-control */
import moment from 'moment';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, { useEffect, useState, useRef } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './FieldDateRange.scss' 
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays,
} from 'date-fns';


const FieldDateRange = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const defaultDateRange = {
    startDate: props.startDate,
    endDate: props.endDate,
    key: 'selection',
  };

  const defineds = {
    startOfWeek: addDays(startOfWeek(new Date()), +1),
    endOfWeek: addDays(endOfWeek(new Date()), +1),
    startOfLastWeek: addDays(startOfWeek(addDays(new Date(), -7)), +1),
    endOfLastWeek: addDays(endOfWeek(addDays(new Date(), -7)), +1),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOf15DayBeforeToday: startOfDay(addDays(new Date(), -15)),
    startOf30DayBeforeToday: startOfDay(addDays(new Date(), -30))
  };

  const staticRanges = []
 
  if (typeof(props.maxDate) === 'undefined' || props.maxDate >= defineds.startOfToday){
    staticRanges.push({
      label: 'Today',
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        );
      }
    })
  }
  
  
  staticRanges.push({
      label: 'Yesterday',
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday,
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        );
      }
    })
    
    
    staticRanges.push({
      label: 'This Week',
      range: () => ({
        startDate: defineds.startOfWeek,
        endDate: defineds.endOfWeek,
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        );
      }
    })
    
    
    staticRanges.push({
      label: 'Last Week',
      range: () => ({
        startDate: defineds.startOfLastWeek,
        endDate: defineds.endOfLastWeek,
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        );
      }
    })

    staticRanges.push({
      label: 'This Month',
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfMonth,
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        );
      }
    })
    
    staticRanges.push({
      label: 'Last Month',
      range: () => ({
        startDate: defineds.startOfLastMonth,
        endDate: defineds.endOfLastMonth,
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        );
      }
    })
    
    staticRanges.push({
      label: 'Last 15 Days',
      range: () => ({
        startDate: defineds.startOf15DayBeforeToday,
        endDate: defineds.startOfToday,
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        );
      }
    })
    
    staticRanges.push({
      label: 'Last 30 Days',
      range: () => ({
        startDate: defineds.startOf30DayBeforeToday,
        endDate: defineds.startOfToday,
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        );
      }
    })
     
  const [DateRange, setDateRange] = useState(defaultDateRange);  
 
  const op = useRef(null);

  const getFriendlyDate = () => {    
    const startDateMoment = moment(DateRange.startDate);
    const endDateMoment = moment(DateRange.endDate);
    const startDate = startDateMoment.format("DD MMM YYYY");
    const endDate = endDateMoment.format("DD MMM YYYY");
    let dateLabel = '';
    if (startDateMoment.isSame(endDate)) {
      dateLabel = startDate;
    } else {
      dateLabel = `${startDate} - ${endDate}`;
    }
    return (
      <>
        {dateLabel}
      </>
    )
  };

  const getDateRange = (startDate, endDate) => {
    const dateRange = defaultDateRange;
    dateRange.startDate = startDate;
    dateRange.endDate = endDate;
    return dateRange;
  };
 

  const onDateRangeChange = (ranges) => {        
    setDateRange(ranges.selection);
    if (props.onDateRangeChange) {
      props.onDateRangeChange(ranges.selection.startDate, ranges.selection.endDate);
    }
    // setIsOpen(false);
  };
 

  useEffect(() => {  
    setDateRange(getDateRange(props.startDate, props.endDate));
  }, [props.startDate, props.endDate]);
   
  const dateRangeTemplate = (
    <>    
      <div className="dateRangeWrapper p-fluid">
        <span className="p-float-label">
          <div 
            className="p-dropdown p-component p-inputwrapper p-inputwrapper-filled"
            aria-haspopup
            aria-controls="overlay_panel"
            // onClick={() => {setIsOpen(!isOpen)}}
            onClick={(e) => op.current.toggle(e)} 
          >
            <span className="p-dropdown-label p-inputtext">
              {getFriendlyDate()}
            </span>                
            <div className="p-dropdown-trigger" role="button" aria-haspopup="listbox" aria-expanded="false">
              <span className="p-dropdown-trigger-icon p-clickable pi pi-chevron-down">
              </span>
            </div>
          </div>
          <label className="">
            {props.label}
          </label>
        </span>
        <OverlayPanel ref={op} id="overlay_panel">
          <DateRangePicker
            // className='dateRangePanel'
            minDate={props.minDate}
            maxDate={props.maxDate}
            ranges={[DateRange]}
            onChange={onDateRangeChange}
            staticRanges={staticRanges} 
          />      
        </OverlayPanel> 
      </div>          
    </>
  );
  
 
 
  return (  
    <>   
      {dateRangeTemplate}
    </> 
  )
}   
     
export default FieldDateRange;


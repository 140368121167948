/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';
import PageContent from 'layouts/PageContent'; 
import MisProyectosFilter from './MisProyectosFilter';
import MisProyectosList from './MisProyectosList';

const MisProyectosScreen = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['misProyectosScreen', 'common']);  
  const [ProyectosScreenData, setProyectosScreenData] = useState({});
  const [filtersData, setfiltersData] = useState({ 
    flagExport: 0,
    ClaEstatusProyecto: "1,2,3,4,",
    ClaTipoProyecto: 1,
    flagClean: 0
  });
  const [exportToExcelMyProject, setexportToExcelMyProject] = useState({});
  
    
  const onSearch = (filters) => { 
    setfiltersData({...filters});   
  };

  const onExportToExcelMyProject = (filters) => {  
    setfiltersData({...filters});     
  };

  return (
    <>
      <PageContent title={t('misProyectosScreen:MyProjects')}>  
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <MisProyectosFilter onSearch={onSearch} onExportToExcelMyProject={onExportToExcelMyProject}></MisProyectosFilter>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
          </Col>
        </Row>
        <Card>   
          <CardBody>  
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <MisProyectosList filtersData={filtersData}></MisProyectosList>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </PageContent> 
    </>
  );
};
export default MisProyectosScreen;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap'; 
import { useTranslation } from 'react-i18next';
import { config } from '../../../utils/config';
import { callApi} from '../../../utils/utils.js';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import FieldDropdown from '../../../components/Controls/FieldDropdown';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { Dialog } from 'primereact/dialog';
import PriceTrackerMap from './PriceTrackerMap';
import FieldInputSwitch from '../../../components/Controls/FieldInputSwitch';
import { Slider } from 'primereact/slider';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import './PriceTrackerScreen.scss';   
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';

const PriceTrackerDetailInvoices = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['priceTracker', 'common']);
  const [errors, setErrors] = useState({}); 
  const [IsLoading, setIsLoading] = useState(true);
  const [ClaZonaFilter, setClaZonaFilter] = useState('All');
  const [ClaStateFilter, setClaStateFilter] = useState('All');
  const [ClaFactoryFilter, setClaFactoryFilter] = useState(null);
  const [ClaCuCostumerFilter, setClaCuCostumerFilter] = useState(null);
  const [ClaCostumerFilter, setClaCostumerFilter] = useState(null);
  const [ClaMileRangeFilter, setClaMileRangeFilter] = useState(null);
  const [InvoiceDetailList, setInvoiceDetailList] = useState(null);

  useEffect(() => { 
    getPriceTrackerDetailInvoices(); 
    setIsLoading(false);
    console.log('PriceTrackerDetailInvoices',props)
  }, [props]); 


  const getPriceTrackerDetailInvoices = () => { 
    const url = `${config.UrlApiProject}USADatalake/GetPriceTrackerDetaiInvoice`;   
    const paramsToService = {
      AnioMes:props.AnioMes,
      ClaPeriodo:props.ClaPeriodo,
      ClaDivision:props.ClaDivision,
      ClaNivel:props.ClaNivel,
      ClaTipo:props.ClaTipo,
      ClaNivelProducto:props.ClaNivelProducto,
      NivelProducto:props.NivelProducto,
      ClaZona:ClaZonaFilter,
      ClaState:ClaStateFilter,
      ClaFactory:ClaFactoryFilter,
      ClaCuCostumer:ClaCuCostumerFilter,
      ClaCostumer:props.ClaCustumer,
      ClaDivicion:null,
      Milles:ClaMileRangeFilter,
      EsPrecioNeto:props.EsPrecioNeto
    }
    callApi(url, 'POST', paramsToService, (response) => { 
      const ListInvoiceDetail = response.data.invoiceDetailList;
      setInvoiceDetailList(ListInvoiceDetail);
      setIsLoading(false);
    });
  }

if (IsLoading){
  return (<>Loading...</>);
}

const DescriptionTemplate = (item) => {
  return (
    <> 
      {
        item.data.Nivel!== null ? (
          <>
            <span
              className='alink' 
              onClick={() => getNivelTracker(item.data.Nivel,item.data.Descripcion)}
            > {item.data.Descripcion}
            </span> 
          </>
        ):(
          <>
            <span> {item.data.Descripcion}
            </span> 
          </>
        )
      }
    </>
    );
}; 


const onHideDialog = () => { 
  if (props.onHide !== null && typeof props.onHide === 'function') {
    props.onHide();
  }
};

const templateHeaderDialog = (
  <> 
    <Row>
      <Col> {props.NomPeriodo}{props.CustomerInvoice}-{t('priceTracker:Detail')}
        <div><small className='info-color'>{t('priceTracker:DisclaimerInfo')}</small></div>
        <div>
          {
          props.ClaTipo===1 ? (
            <>
              <small className='info-color'>{t('priceTracker:LeyendaDetailPriceTracker')}  {t('priceTracker:Tons')}</small>
            </>
          ):(
            <>   
              <small className='info-color'>{t('priceTracker:LeyendaDetailPriceTracker')}  {t('priceTracker:Units')}</small>
            </>
          )
         }
        </div>
      </Col>
    </Row>
  </>
  );

  
const ImporteVentaTemplate = (item) => {
  return (
    <> 
      <span>{Highcharts.numberFormat(item.ImporteVenta,2)} </span>
    </>
    );
}; 
const BillingNameTemplate = (item) => {
  return (
    <> 
      <span className='overflow-ellipsis' title={item.BillingName}>{item.BillingName}</span>
    </>
    );
}; 

const AgentTemplate = (item) => {
  return (
    <> 
      <span className='overflow-ellipsis' title={item.Agent}>{item.Agent}</span>
    </>
    );
}; 

const TonsTemplate = (item) => {
  return (
    <> 
      <span>{Highcharts.numberFormat(item.Tons,2)} </span>
    </>
    );
}; 

const QtyTemplate = (item) => {
  return (
    <> 
      <span>{Highcharts.numberFormat(item.Qty,2)} </span>
    </>
    );
};  
const SpreadTemplate = (item) => {
  return (
    <> 
      <span>{Highcharts.numberFormat(item.Spread,2)} </span>
    </>
    );
};  

const PriceTemplate = (item) => {
  return (
    <> 
      <span>{Highcharts.numberFormat(item.Price,2)} </span>
    </>
    );
};  

  return (
    <>
      <Dialog
        className='PriceTrackerScreen'
        visible={props.Visible}
        onHide={onHideDialog}  
        style={{ width: '90%' }}
        maximized={false}
        header={templateHeaderDialog}
        blockScroll={true}
      > 
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
            <FieldDataTable
              value={InvoiceDetailList}    
              scrollable
              scrollHeight="450px"
            >
              <Column
                field="BillingID"
                columnKey="BillingID"
                header={t('priceTracker:BillingID')}
                headerStyle={{ width: '90px' }}
              >
              </Column>  
              <Column
                field="BillingName"
                columnKey="BillingName"
                header={t('priceTracker:BillingName')}
                className='overflow-ellipsis'
                body={BillingNameTemplate}
                headerStyle={{ width: '200px' }}
              >
              </Column> 
              <Column
                field="Factory"
                columnKey="Factory"
                header={t('priceTracker:Factory')}
                headerStyle={{ width: '200px' }}
              >
              </Column> 
              <Column
                field="ClaveFactura"
                columnKey="ClaveFactura"
                header={t('priceTracker:Invoice')}
                headerStyle={{ width: '100px' }}
              >
              </Column> 
              <Column
                field="FechaFactura"
                columnKey="FechaFactura"
                header={t('priceTracker:Date')}
                headerStyle={{ width: '90px' }}
              >
              </Column> 
              <Column
                field="ImporteVenta"
                columnKey="ImporteVenta"
                header={t('priceTracker:Revenue')}
                headerStyle={{ width: '90px' }}
                body={ImporteVentaTemplate}
              >
              </Column>    
              <Column
                field="Tons"
                columnKey="Tons"
                header={t('priceTracker:Tons')}
                headerStyle={{ width: '90px' }}
                body={TonsTemplate}
              >
              </Column>  
              <Column
                field="Qty"
                columnKey="Qty"
                header={t('priceTracker:QTY')}
                headerStyle={{ width: '90px' }}
                body={QtyTemplate}
              >
              </Column>   
              <Column
                field="Price"
                columnKey="Price"
                header={t('priceTracker:Price')}
                headerStyle={{ width: '90px' }}
                body={PriceTemplate}
              >
              </Column> 
              <Column
                field="Spread"
                columnKey="Spread"
                header={t('priceTracker:Spread')}
                headerStyle={{ width: '90px' }}
                body={SpreadTemplate}
              >
              </Column>  
              <Column
                field="Agent"
                columnKey="Agent"
                header={t('priceTracker:SalesRep')}
                className='overflow-ellipsis'  
                body={AgentTemplate}
                headerStyle={{ width: '150px' }}
              >
              </Column>        
            </FieldDataTable>
          </Col>
        </Row>
      </Dialog>   
    </>
  );
};
export default PriceTrackerDetailInvoices;

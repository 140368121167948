import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldUpload from 'components/Controls/FieldUpload/FieldUpload';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import { callApi, showSweetAlert, formatDate } from '../../../utils/utils';
import { config } from '../../../utils/config';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import { Column } from 'primereact/column';

const ManageADPEmployees = (props) => {
  const { t } = useTranslation(['hr', 'common']);

  const [errors, setErrors] = useState(null);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    getEmployees(props.filters);
  }, [props.filters])

  const getEmployees = (filters) => {
    const urlWebService = `${config.UrlApiProject}HR/GetADPEmployees`;
    const paramsService = {};
    
    if (filters !== undefined && filters !== null) {
      paramsService.FullName = filters.FullName;
      paramsService.PositionId = filters.PositionId;
      paramsService.AssociateId = filters.AssociateId;
      paramsService.CompanyName = filters.CompanyName;
    }

    callApi(urlWebService, 'POST', paramsService, (response) => {
      setEmployees(response.data);
    });
  }

  const hireDateTemplate = (row) => {
    if (row.HireDate !== undefined && row.HireDate !== null) {
      return (
        <>
          {formatDate(row.HireDate)}
        </>
      )
    }

    return <></>
  }

  const termDateTemplate = (row) => {
    if (row.TerminationDate !== undefined && row.TerminationDate !== null) {
      return (
        <>
          {formatDate(row.TerminationDate)}
        </>
      )
    }

    return <></>
}

  return (
    <>
      <FieldDataTable
        value={employees} 
        header={t('hr:EmployeesImported')}  
        scrollable 
        className="p-datatable-striped"
      >
        <Column field="LastName" header={t('hr:LastName')} sortable headerStyle={{ width: '150px' }} />
        <Column field="FirstName" header={t('hr:FirstName')} sortable headerStyle={{ width: '150px' }} />
        <Column field="PositionId" header={t('hr:PositionId')} sortable headerStyle={{ width: '110px' }} />
        <Column field="AssociateId" header={t('hr:AssociateId')} sortable headerStyle={{ width: '110px' }} />
        <Column body={hireDateTemplate} header={t('hr:HireDate')} sortable sortField='HireDate' headerStyle={{ width: '100px' }} />
        <Column body={termDateTemplate} header={t('hr:TermDate')} sortable sortField='TerminationDate' headerStyle={{ width: '100px' }} />
        <Column field="PayrollCompanyName" header={t('hr:PayrollCompany')} sortable headerStyle={{ width: '180px' }} />
        <Column field="HomeCostNumberCode" header={t('hr:HomeCostNumberCode')} sortable headerStyle={{ width: '175px' }} />
        <Column field="JobTitleDescription" header={t('hr:JobTitleDescription')} sortable />
      </FieldDataTable>
    </>
  )
}

export default ManageADPEmployees;
/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */

import FieldButton from 'components/Controls/FieldButton/FieldButton';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';
import { config } from 'utils/config';
import { callApi } from 'utils/utils';
import SurveyInsightQuestion from './SurveyInsightQuestion';
import SurveyInsightsSummary from './SurveyInsightsSummary';
import SurveyInsightsTrend from './SurveyInsightsTrend';
import SurveySummaryQuestion from './SurveySummaryQuestion';

const SurveySummary = ({ IdEncuesta, onSearch }) => {

  /* ################## useState area start ################## */

  // const { t } = useTranslation(['hr', 'focusReport', 'common']);
  const [EncuestaPaginaPreguntaList, setEncuestaPaginaPreguntaList] = useState([]);

  useEffect(() => {
    GetSurveyInsightsSummaryQuestion(IdEncuesta);
  }, [IdEncuesta]);

  const GetSurveyInsightsSummaryQuestion = (pIdEncuesta) => {
    const getSurveyInsightsSummaryQuestion = `${config.UrlApiProject}hr/GetSurveyInsightsSummaryQuestion?IdEncuesta=${pIdEncuesta}`;
    const paramsToService = {};

    callApi(getSurveyInsightsSummaryQuestion, 'GET', paramsToService, (response) => {
      setEncuestaPaginaPreguntaList(response.data.InsightsSummaryQuestion);
      console.table(response.data.InsightsSummaryQuestion);
     
    });
  };
   


  /* ################## useState area start ################## */
  const { t } = useTranslation(['misProyectosScreen', 'common']);
  return (
    <> 
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
                    &nbsp;
        </Col>
      </Row>      
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          {
            EncuestaPaginaPreguntaList.map((element, index) => {
              return (
                <>
                  {index === 0 ? (
                    <>
                      <Row>
                        <Col lg={10} md={10} sm={12} xs={12} className="p-mr-2">
                          <div>
                            <span className="h2 bold">{element.TituloPagina}</span>
                          </div>                        
                        </Col>
                        <Col>
                          <div className="text-right">
                            <small className="BOLD">{element.CurrentPage} | {element.TotalPages}</small>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                        &nbsp;
                        </Col>
                      </Row>
                    </>
                ) : (
                  <></>
                )}

                  {index > 0 &&
                  element.IdEncuestaPagina !==
                    (index + 1 === EncuestaPaginaPreguntaList.length
                      ? EncuestaPaginaPreguntaList[index].IdEncuestaPagina
                      : EncuestaPaginaPreguntaList[index - 1].IdEncuestaPagina) ? (
                        <>
                          <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>
                          &nbsp;
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={10} md={10} sm={12} xs={12} className="p-mr-2">
                              <div>
                                <span className="h2 bold">{element.TituloPagina}</span>
                              </div> 
                            </Col>
                            <Col>                              
                              <div className="text-right">
                                <small className="BOLD">{element.CurrentPage} | {element.TotalPages}</small>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>
                          &nbsp;
                            </Col>
                          </Row>
                        </>
                  ) : (
                    <></>
                  )}

                  <SurveySummaryQuestion
                    IdEncuesta={element.IdEncuesta}
                    IdEncuestaPaginaPregunta={element.IdEncuestaPaginaPregunta}
                  >
                  </SurveySummaryQuestion>
                </>
              )
            })
          }
          
        </Col>
      </Row>      
    </>
  );
};
export default SurveySummary;

import React, { useState, useEffect } from 'react';
import { config } from 'utils/config';
import { callApi, formatDate, getSessionItem } from 'utils/utils';
import DefaultNewsletterThumbnail from 'assets/img/DefaultNewsletterThumbnail.jpg';

import './NewsletterThumbnail.scss';

const DntNewsletterImage = ({IdLibraryFile}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState({
    ImageFile: null,
    ImageExtensionType: null
  });

  useEffect(() => {
    const urlWebService = `${config.UrlApiProject}DMS/GetMSWTraBSCDMLibraryFileThumbnailById?IdLibraryFile=${IdLibraryFile}`;

    const headers = {
      'x-api-key': config.ApiKey,
      'x-access-token': getSessionItem('Token', '')
    };
    
    fetch(urlWebService, {
      method: 'GET',
      headers,
      body: null
    })
    .then((response) => response.json())
    .then((res) => {
      setImage({
        ImageFile: res.data.ThumbnailFile,
        ImageExtensionType: res.data.ThumbnailExtensionType
      })
      setIsLoading(false);
    })
    .catch((error) => console.log('Error fetching image', error));
  }, [])

  const imageTemplate = (i) => {
    if (i.ImageFile !== undefined && i.ImageFile !== null) {
      return <img src={`data:image/${i.ImageExtensionType};base64,${i.ImageFile}`} alt='Newsletter Thumbnail' className='newsletter-thumbnail' />
    }
    return <img src={DefaultNewsletterThumbnail} alt='Newsletter Thumbnail' className='newsletter-thumbnail' />
  }

  return (
    isLoading ? (
      <div className='newsletter-loading-container'>
        <div className='newsletter-loading' />
        <img src={DefaultNewsletterThumbnail} alt='Newsletter Thumbnail' className='newsletter-thumbnail' />
      </div> 
    ):(
      <> 
        {imageTemplate(image)}
      </>
    ) 
  )
}

export default DntNewsletterImage;

import PageContent from 'layouts/PageContent';
import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap'; 
import { config } from 'utils/config';
import { callApi, getSessionItem, showSweetAlert, getDeviceUniqueID } from 'utils/utils';
import AvailableCheckListByLocation from './AvailableCheckListByLocation';

const AvailableCheckListByLocationScreen = (props) => {
  const { t } = useTranslation(['formulario', 'common']);   
  const [ClaUbicacion, setClaUbicacion] = useState(props.match.params.ClaUbicacion);  
  
  return (
    <PageContent title={t('formulario:AvailableCheckList')}>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <AvailableCheckListByLocation claUbicacion={ClaUbicacion}></AvailableCheckListByLocation>
        </Col>
      </Row>              
    </PageContent>
  )
}

export default AvailableCheckListByLocationScreen

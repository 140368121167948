/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'primereact/column';
import {
  Row,
  Col,
  ListGroupItem,
  ListGroup,
  Progress,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from 'reactstrap';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { Badge } from 'primereact/badge';
import { Link } from 'react-router-dom';
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../../utils/utils';
import Score from '../../../../components/Cards/Score/Score.jsx';
import FieldDataTable from '../../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import PropType from 'prop-types';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import { Dialog } from 'primereact/dialog';
import FieldText from 'components/Controls/FieldText';
import { Toolbar } from 'primereact/toolbar';
import { evaluate, forEach } from "mathjs";
import FieldRadioButton from 'components/Controls/FieldRadioButton/FieldRadioButton';
import FieldDropdown from 'components/Controls/FieldDropdown';
import OrganizationalPositionView from '../OrganizationalPositionView';
import SweatAlertCustomIcon from 'components/SweatAlert/SweatAlertCustomIcon';
import swal from '@sweetalert/with-react';
import * as yup from 'yup';
import YupValidator from '../../../../utils/YupValidator';
import moment from 'moment';

const KPIFormulaResultadoReview = ({show, onHide, AnioMes, IdKPIFormula}) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['scoreCard', 'common', 'misProyectosScreen']);
  const [errors, setErrors] = useState({}); 
  const [Formula, setFormula] = useState(null);
  const [ResultadoFormula, setResultadoFormula] = useState(null);
  const [FormulaVariablesList, setFormulaVariablesList] = useState([]);
  const [FormulaEjecutada, setFormulaEjecutada] = useState(null); 
  const [Periodo, setPeriodo] = useState(null); 
  const [LastUpdate, setLastUpdate] = useState(null);
   

  useEffect(() => {  
    if (AnioMes !== null && IdKPIFormula !== null){ 
        GetKPIFormulaResultadoCalculoInfo(AnioMes, IdKPIFormula);
    }
  }, []);

  const GetKPIFormulaResultadoCalculoInfo = (AnioMes, IdKPIFormula) => {
    IdKPIFormula    = IdKPIFormula === undefined ? -1 : IdKPIFormula
    AnioMes         = AnioMes === undefined ? 190001 : AnioMes
    const GetKPIFormulaResultadoCalculoInfo = `${config.UrlApiProject}ScoreCard/GetKPIFormulaResultadoCalculoInfo?AnioMes=${AnioMes}&IdKPIFormula=${IdKPIFormula}`;
    
    console.log('GetKPIFormulaResultadoCalculoInfo', GetKPIFormulaResultadoCalculoInfo);
    const paramsToService = {};
    callApi(GetKPIFormulaResultadoCalculoInfo, 'GET', paramsToService, (response) => {
         const Formula = response.data.Formula.length > 0 ? response.data.Formula[0] : {};
         const { FormulaDetalle } = response.data; 
         setFormula(Formula.FormulaOriginal); 
         setFormulaEjecutada(Formula.FormulaEjecutada); 
         setResultadoFormula(Formula.ResultadoFormula);
         setPeriodo(Formula.Periodo);
         setLastUpdate(Formula.FechaUltimaMod);
         setFormulaVariablesList(FormulaDetalle);
    });
  };
  
  const renderHeader = () => {
    return (
      <div>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
            <span>{t('scoreCard:CalculationReview')}</span> 
          </Col> 
        </Row>
      </div>
    );
  };

  const leftToolbarFooterTemplate = () => {  
    return (
      <>    
      </>
    );
  }; 

  const rightToolbarFooterTemplate = () => {
     
    return (
      <>   
        
      </> 
    );
  };  

  const renderFooter = () => {
    return (
      <>
        <Toolbar
          left={leftToolbarFooterTemplate}
          right={rightToolbarFooterTemplate}
        >
        </Toolbar>
      </>
    );
  };

   

  return (
    <>
      <Dialog
        visible={show}
        modal
        style={{ width: '60vw' }}
        footer={renderFooter}
        header={renderHeader} 
        closable={true}
        onHide={onHide}
        className="dialog-header-background-blue"
        maximized={false}
        draggable={false}
      >
        <Row className='pt-4'>
          <Col lg={3} md={3} sm={3} xs={3}> 
            <div>{t('scoreCard:Formula')}:</div>
            <div className="p-fluid">
              <div className="p-inputgroup">
                <div className='p-inputgroup-addon'><i className="fas fa-equals"></i>&nbsp;</div>
                <FieldText
                  name="Formula"  
                  value={Formula}
                  disabled={true} 
                />  
              </div>
            </div> 
          </Col>
          <Col lg={1} md={1} sm={1} xs={1} className='text-center'>
            <div>&nbsp;</div>
            <div><i className="fas fa-arrow-right"></i></div> 
          </Col>
          <Col lg={3} md={3} sm={3} xs={3}> 
            <div>{t('scoreCard:Command')}:</div>
            <div className="p-fluid">
              <div className="p-inputgroup">
                <div className='p-inputgroup-addon'><i className="fas fa-equals"></i>&nbsp;</div>
                <FieldText
                  name="FormulaEjecutada"  
                  value={FormulaEjecutada}
                  disabled={true} 
                />  
              </div>
            </div> 
          </Col>
          <Col lg={1} md={1} sm={1} xs={1} className='text-center'>
            <div>&nbsp;</div>
            <div><i className="fas fa-arrow-right"></i></div> 
          </Col>
          <Col lg={4} md={4} sm={4} xs={4}> 
            <div>{t('scoreCard:FinalResult')}:</div>
            <div className="p-fluid">
              <div className="p-inputgroup">
                <div className='p-inputgroup-addon'><i className="fas fa-equals"></i>&nbsp;</div>
                <FieldText
                  name="ResultadoFormula"  
                  value={ResultadoFormula}
                  disabled={true} 
                />  
              </div>
            </div> 
          </Col>
        </Row>   
        <Row className='pt-4 pb-2'>
          <Col> 
            &nbsp;
          </Col>
        </Row>
        <Row>
          <Col> 
            <span>{t('scoreCard:AllVariablesCalculatedForPeriod')}:  &nbsp; <b>{moment(Periodo).format("MMMM, YYYY")}</b> </span>
            <div className='p-datatable p-component p-datatable-scrollable table-header-background-blue'>
              <table className='w-100'>
                <thead className='p-datatable-thead'>
                  <tr>
                    <th width="20%">{t('scoreCard:Variable')}</th>
                    <th width="70%">{t('scoreCard:KPIReference')}</th> 
                    <th width="10%">
                      {t('scoreCard:Value')}
                    </th>
                    <th width="10%">&nbsp;</th> 
                  </tr>
                </thead> 
                <tbody className='p-datatable-tbody'>
                  {
            FormulaVariablesList.length === 0 &&
            (
            <>
              <tr>
                <td colSpan={2} className='text-center p-3'><i className='fas fa-exclamation-triangle danger-color' style={{fontSize:'30px'}}></i><br></br><br></br>
                  <div>
                    {t('scoreCard:NoVariablesFound')}
                  </div>
                </td>
              </tr>
            </>   
            )
            } 
                  {
            FormulaVariablesList.map((item, index) => {
                return (
                  <tr key={index}>
                    <td width="20%">{item.ClaveKPIFormulaDetalle}</td>
                    <td width="70%"> 
                      {
                        item.ClaTipoKPIFormula === 1 ?
                        (<div>{t('scoreCard:FromScorecard')}</div>)
                        :
                        (<div>{t('scoreCard:FromProject')}</div>)
                      } 
                      <div>{t('scoreCard:KPI')}: <b>{item.KPIReferenceDescription}</b></div>   
                      {
                        item.ClaTipoKPIFormula === 1 ?
                        (
                          <>
                            <div>{t('scoreCard:Position')}: {item.NomPuesto} - {item.NomColaborador}</div>
                            <div>
                              {t('scoreCard:Source')}:&nbsp;
                              {
                                    item.TipoResultadoKPI === 1 &&
                                    (<>{t('scoreCard:CurrentStatus')}</>)
                              }
                              {
                                    item.TipoResultadoKPI === 2 &&
                                    (<>{t('scoreCard:MinimunAcceptable')}</>)
                              }
                              {
                                    item.TipoResultadoKPI === 3 &&
                                    (<>{t('scoreCard:Satisfactory')}</>)
                              }
                              {
                                    item.TipoResultadoKPI === 4 &&
                                    (<>{t('scoreCard:OutStanding')}</>)
                              }
                            </div>
                          </>
                        )
                        :
                        (<div>{t('scoreCard:Project')}:  {item.NomProyecto}</div>)
                      }
                    </td>
                    <td width="10%">
                      {item.ValorVariable}
                    </td>
                    <td width="10%">
                      {
                            typeof(item.ValorVariable) !== 'undefined'  && item.ValorVariable !== '' && item.ValorVariable !== null 
                            ?
                            (<><i className="fas fa-check success-color"></i></>)
                            :
                            (<><i className='fas fa-exclamation-triangle danger-color'></i></>)
                      }
                    </td>
                  </tr>
                )
                })
            } 
                </tbody>
              </table>
            </div>
            {
                /*
                <div className='text-right'><small>{t('scoreCard:LastUpdate')}:&nbsp;{moment(LastUpdate).format('MMMM Do YYYY, h:mm:ss a')}</small></div>
                */
            }
            
          </Col>
        </Row> 
      </Dialog>
      
    </>
  );
};
 

export default KPIFormulaResultadoReview;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { PickList } from 'primereact/picklist';
import {
  TreeList,
  Scrolling,
  Paging,
  Pager,
  Column,
  Selection,
  Format,
  SearchPanel,
} from 'devextreme-react/tree-list';
import { config } from '../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../utils/utils';
import moment from 'moment';
import { Link } from 'react-router-dom';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldText from 'components/Controls/FieldText';
import FieldRadioButton from '../../../components/Controls/FieldRadioButton/FieldRadioButton.jsx';
import { Divider } from 'primereact/divider';
import { Panel } from 'primereact/panel';

const FilterCorporateView = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['corporate', 'common']);
  const [OpcionesAnio, setOpcionesAnio] = useState([]);
  const [OpcionesAnio2, setOpcionesAnio2] = useState([]);
  const [OpcionesFromMes1, setOpcionesFromMes1] = useState([]);
  const [OpcionesFromMes2, setOpcionesFromMes2] = useState([]);
  const [OpcionesToMes1, setOpcionesToMes1] = useState([]);
  const [OpcionesToMes2, setOpcionesToMes2] = useState([]);
  const [OpcionesScenario1, setOpcionesScenario1] = useState([]);
  const [OpcionesScenario2, setOpcionesScenario2] = useState([]);

  const [Seg2ClaAnio, setSeg2ClaAnio] = useState(null);
  const [Seg2ClaMesFrom, setSeg2ClaMesFrom] = useState(null);
  const [Seg2ClaMesTo, setSeg2ClaMesTo] = useState(null);
  const [Seg2ClaEscenarioInfo, setSeg2ClaEscenarioInfo] = useState(null);
  const [Seg2NomMesFrom, setSeg2NomMesFrom] = useState(null);
  const [Seg2NomMesTo, setSeg2NomMesTo] = useState(null);
  const [Seg2NomEscenarioInfo, setSeg2NomEscenarioInfo] = useState(null);

  const [Seg1ClaAnio, setSeg1ClaAnio] = useState(null);
  const [Seg1ClaMesFrom, setSeg1ClaMesFrom] = useState(null);
  const [Seg1ClaMesTo, setSeg1ClaMesTo] = useState(null);
  const [Seg1ClaEscenarioInfo, setSeg1ClaEscenarioInfo] = useState(null);
  const [Seg1NomMesFrom, setSeg1NomMesFrom] = useState(null);
  const [Seg1NomMesTo, setSeg1NomMesTo] = useState(null);
  const [Seg1NomEscenarioInfo, setSeg1NomEscenarioInfo] = useState(null);

  const [EsPromedio, setEsPromedio] = useState(true);
  const [EsAcumulado, setEsAcumulado] = useState(false);
  const [EsMTons, setEsMTons] = useState(true);
  const [EsShortTons, setEsShortTons] = useState(false);
  const [TipoTonleadas, setTipoTonleadas] = useState('Mtons');
  const [TipoPromedio, setTipoPromedio] = useState('Avg');

  useEffect(() => {
    InitCombos();
    
  }, []); 

  const InitCombos = () => {
    ObtenerAnio();
    // GetMesCmb();
    // GetEscenarioCmb();
  }

  const InitializeDefaultFilters = (months, scenarios) => {
     const currentYear  = new Date().getFullYear(); 
     let currentMonth = new Date().getMonth() + 1;

     currentMonth = currentMonth-1 === 0 ? 1 : currentMonth-1;
     
     const mesNameOpc = [...months];
     const mesName  = mesNameOpc.filter((item) => item.ClaMes === 1)[0];
     const mesName2 = mesNameOpc.filter((item) => item.ClaMes === currentMonth)[0];
     
     setSeg1ClaAnio(currentYear);
     setSeg2ClaAnio(currentYear-1);

     setSeg1NomMesFrom(mesName.NomMes);
     setSeg1ClaMesFrom(1);
     setSeg1NomMesTo(mesName2.NomMes);
     setSeg1ClaMesTo(currentMonth);
     
     setSeg2NomMesFrom(mesName.NomMes);
     setSeg2ClaMesFrom(1);
     setSeg2NomMesTo(mesName2.NomMes);
     setSeg2ClaMesTo(currentMonth);

     const EscenarioOpc = [...scenarios];
     const escenarioName = EscenarioOpc.length > 0 ? EscenarioOpc[0] : {};
     setSeg1NomEscenarioInfo(escenarioName.NomEscenarioInfo);
     setSeg1ClaEscenarioInfo(escenarioName.ClaEscenarioInfo);

     setSeg2NomEscenarioInfo(escenarioName.NomEscenarioInfo);
     setSeg2ClaEscenarioInfo(escenarioName.ClaEscenarioInfo);

     onSearchCorporate(
        currentYear,
        1,
        mesName.NomMes,
        currentMonth-1,
        mesName2.NomMes,
        escenarioName.ClaEscenarioInfo,
        escenarioName.NomEscenarioInfo,
        currentYear-1,
        1,
        mesName.NomMes,
        currentMonth-1,
        mesName2.NomMes,
        escenarioName.ClaEscenarioInfo,
        escenarioName.NomEscenarioInfo,
        true,
        false,
        true,
        false
    ); 
  }


  const ObtenerAnio = () => {
    const GetAnioCmb = `${config.UrlApiProject}ScoreCard/GetAnioCmb`;
    const paramsToService = {};
    callApi(GetAnioCmb, 'GET', paramsToService, (response) => {
      setOpcionesAnio(response.data.Anio);
      setOpcionesAnio2(response.data.Anio);
      GetMesCmb();
    });
  };
  const GetMesCmb = () => {
    const GetMesCmb = `${config.UrlApiProject}ScoreCard/GetMesCmb`;
    const paramsToService = {};
    callApi(GetMesCmb, 'GET', paramsToService, (response) => {
      setOpcionesFromMes1(response.data.Mes);
      setOpcionesToMes1(response.data.Mes);

      setOpcionesFromMes2(response.data.Mes);
      setOpcionesToMes2(response.data.Mes);
      GetEscenarioCmb(response.data.Mes);
    });
  };

  const GetEscenarioCmb = (monthsList) => {
    const GetEscenarioCmb = `${config.UrlApiProject}ScoreCard/GetEscenarioCmb?ReporteID=${props.ReportID === null || typeof(props.ReportID) === 'undefined' ? -1 : props.ReportID}`;
    const paramsToService = {};
    callApi(GetEscenarioCmb, 'GET', paramsToService, (response) => {
      setOpcionesScenario1(response.data.Escenario);
      setOpcionesScenario2(response.data.Escenario);
      InitializeDefaultFilters(monthsList, response.data.Escenario);
    });
  };

  const onClaAnioChange = (props, e) => {
    const OpcionesAnioName = [...OpcionesAnio];
    const anioName = OpcionesAnioName.filter((word) => word.ClaAnio === e.value)[0];
    setSeg1ClaAnio(e.value);
    onSearchCorporate(
      e.value,
      Seg1ClaMesFrom,
      Seg1NomMesFrom,
      Seg1ClaMesTo,
      Seg1NomMesTo,
      Seg1ClaEscenarioInfo,
      Seg1NomEscenarioInfo,
      Seg2ClaAnio,
      Seg2ClaMesFrom,
      Seg2NomMesFrom,
      Seg2ClaMesTo,
      Seg2NomMesTo,
      Seg2ClaEscenarioInfo,
      Seg2NomEscenarioInfo,
      EsPromedio,
      EsAcumulado,
      EsMTons,
      EsShortTons
    );
  };
  const onClaAnio2Change = (props, e) => {
    setSeg2ClaAnio(e.value);
    onSearchCorporate(
      Seg1ClaAnio,
      Seg1ClaMesFrom,
      Seg1NomMesFrom,
      Seg1ClaMesTo,
      Seg1NomMesTo,
      Seg1ClaEscenarioInfo,
      Seg1NomEscenarioInfo,
      e.value,
      Seg2ClaMesFrom,
      Seg2NomMesFrom,
      Seg2ClaMesTo,
      Seg2NomMesTo,
      Seg2ClaEscenarioInfo,
      Seg2NomEscenarioInfo,
      EsPromedio,
      EsAcumulado,
      EsMTons,
      EsShortTons
    );
  };

  const onFromMes1Change = (props, e) => {
    const mesNameOpc = [...OpcionesFromMes1];
    const mesName = mesNameOpc.filter((item) => item.ClaMes === e.value)[0];
    setSeg1NomMesFrom(mesName.NomMes);
    setSeg1ClaMesFrom(e.value);
    onSearchCorporate(
      Seg1ClaAnio,
      e.value,
      mesName.NomMes,
      Seg1ClaMesTo,
      Seg1NomMesTo,
      Seg1ClaEscenarioInfo,
      Seg1NomEscenarioInfo,
      Seg2ClaAnio,
      Seg2ClaMesFrom,
      Seg2NomMesFrom,
      Seg2ClaMesTo,
      Seg2NomMesTo,
      Seg2ClaEscenarioInfo,
      Seg2NomEscenarioInfo,
      EsPromedio,
      EsAcumulado,
      EsMTons,
      EsShortTons
    );
  };

  const onFromMes2Change = (props, e) => {
    const mesNameOpc = [...OpcionesFromMes2];
    const mesName = mesNameOpc.filter((item) => item.ClaMes === e.value)[0];
    setSeg2NomMesFrom(mesName.NomMes);
    setSeg2ClaMesFrom(e.value);
    onSearchCorporate(
      Seg1ClaAnio,
      Seg1ClaMesFrom,
      Seg1NomMesFrom,
      Seg1ClaMesTo,
      Seg1NomMesTo,
      Seg1ClaEscenarioInfo,
      Seg1NomEscenarioInfo,
      Seg2ClaAnio,
      e.value,
      mesName.NomMes,
      Seg2ClaMesTo,
      Seg2NomMesTo,
      Seg2ClaEscenarioInfo,
      Seg2NomEscenarioInfo,
      EsPromedio,
      EsAcumulado,
      EsMTons,
      EsShortTons
    );
  };

  const onToMes1Change = (props, e) => {
    const mesNameOpc = [...OpcionesToMes1];
    const mesName = mesNameOpc.filter((item) => item.ClaMes === e.value)[0];
    setSeg1NomMesTo(mesName.NomMes);
    setSeg1ClaMesTo(e.value);
    onSearchCorporate(
      Seg1ClaAnio,
      Seg1ClaMesFrom,
      Seg1NomMesFrom,
      e.value,
      mesName.NomMes,
      Seg1ClaEscenarioInfo,
      Seg1NomEscenarioInfo,
      Seg2ClaAnio,
      Seg2ClaMesFrom,
      Seg2NomMesFrom,
      Seg2ClaMesTo,
      Seg2NomMesTo,
      Seg2ClaEscenarioInfo,
      Seg2NomEscenarioInfo,
      EsPromedio,
      EsAcumulado,
      EsMTons,
      EsShortTons
    );
  };

  const onToMes2Change = (props, e) => {
    const mesNameOpc = [...OpcionesToMes2];
    const mesName = mesNameOpc.filter((item) => item.ClaMes === e.value)[0];
    setSeg2NomMesTo(mesName.NomMes);
    setSeg2ClaMesTo(e.value);
    onSearchCorporate(
      Seg1ClaAnio,
      Seg1ClaMesFrom,
      Seg1NomMesFrom,
      Seg1ClaMesTo,
      Seg1NomMesTo,
      Seg1ClaEscenarioInfo,
      Seg1NomEscenarioInfo,
      Seg2ClaAnio,
      Seg2ClaMesFrom,
      Seg2NomMesFrom,
      e.value,
      mesName.NomMes,
      Seg2ClaEscenarioInfo,
      Seg2NomEscenarioInfo,
      EsPromedio,
      EsAcumulado,
      EsMTons,
      EsShortTons
    );
  };

  const onScenario1Change = (props, e) => {
    const EscenarioOpc = [...OpcionesScenario1];
    const escenarioName = EscenarioOpc.filter((item) => item.ClaEscenarioInfo === e.value)[0];
    setSeg1NomEscenarioInfo(escenarioName.NomEscenarioInfo);
    setSeg1ClaEscenarioInfo(e.value);
    onSearchCorporate(
      Seg1ClaAnio,
      Seg1ClaMesFrom,
      Seg1NomMesFrom,
      Seg1ClaMesTo,
      Seg1NomMesTo,
      e.value,
      escenarioName.NomEscenarioInfo,
      Seg2ClaAnio,
      Seg2ClaMesFrom,
      Seg2NomMesFrom,
      Seg2ClaMesTo,
      Seg2NomMesTo,
      Seg2ClaEscenarioInfo,
      Seg2NomEscenarioInfo,
      EsPromedio,
      EsAcumulado,
      EsMTons,
      EsShortTons
    );
  };

  const onScenario2Change = (props, e) => {
    const EscenarioOpc = [...OpcionesScenario2];
    const escenarioName = EscenarioOpc.filter((item) => item.ClaEscenarioInfo === e.value)[0];
    setSeg2NomEscenarioInfo(escenarioName.NomEscenarioInfo);
    setSeg2ClaEscenarioInfo(e.value);
    onSearchCorporate(
      Seg1ClaAnio,
      Seg1ClaMesFrom,
      Seg1NomMesFrom,
      Seg1ClaMesTo,
      Seg1NomMesTo,
      Seg1ClaEscenarioInfo,
      Seg1NomEscenarioInfo,
      Seg2ClaAnio,
      Seg2ClaMesFrom,
      Seg2NomMesFrom,
      Seg2ClaMesTo,
      Seg2NomMesTo,
      e.value,
      escenarioName.NomEscenarioInfo,
      EsPromedio,
      EsAcumulado,
      EsMTons,
      EsShortTons
    );
  };

  const onSearchCorporate = (
    pSeg1ClaAnio,
    pSeg1ClaMesFrom,
    pSeg1NomMesFrom,
    pSeg1ClaMesTo,
    pSeg1NomMesTo,
    pSeg1ClaEscenarioInfo,
    pSeg1NomEscenarioInfo,
    pSeg2ClaAnio,
    pSeg2ClaMesFrom,
    pSeg2NomMesFrom,
    pSeg2ClaMesTo,
    pSeg2NomMesTo,
    pSeg2ClaEscenarioInfo,
    pSeg2NomEscenarioInfo,
    pEsPromedio,
    pEsAcumulado,
    pEsMTons,
    pEsShortTons
  ) => {
    const filter = {
      Seg1ClaAnio: pSeg1ClaAnio,
      Seg1ClaMesFrom: pSeg1ClaMesFrom,
      Seg1NomMesFrom: pSeg1NomMesFrom,
      Seg1ClaMesTo: pSeg1ClaMesTo,
      Seg1NomMesTo: pSeg1NomMesTo,
      Seg1ClaEscenarioInfo: pSeg1ClaEscenarioInfo,
      Seg1NomEscenarioInfo: pSeg1NomEscenarioInfo,
      Seg2ClaAnio: pSeg2ClaAnio,
      Seg2ClaMesFrom: pSeg2ClaMesFrom,
      Seg2NomMesFrom: pSeg2NomMesFrom,
      Seg2ClaMesTo: pSeg2ClaMesTo,
      Seg2NomMesTo: pSeg2NomMesTo,
      Seg2ClaEscenarioInfo: pSeg2ClaEscenarioInfo,
      Seg2NomEscenarioInfo: pSeg2NomEscenarioInfo,
      EsPromedio: pEsPromedio,
      EsAcumulado: pEsAcumulado,
      EsMTons: pEsMTons,
      EsShortTons: pEsShortTons,
    };
    if (props.onSearch) {
      props.onSearch(filter);
    }
    console.log(filter);
  };
  const onChangeClaAcumProm = (e) => {
    if (e === 'Avg') {
      setEsPromedio(true);
      setEsAcumulado(false);
      onSearchCorporate(
        Seg1ClaAnio,
        Seg1ClaMesFrom,
        Seg1NomMesFrom,
        Seg1ClaMesTo,
        Seg1NomMesTo,
        Seg1ClaEscenarioInfo,
        Seg1NomEscenarioInfo,
        Seg2ClaAnio,
        Seg2ClaMesFrom,
        Seg2NomMesFrom,
        Seg2ClaMesTo,
        Seg2NomMesTo,
        Seg2ClaEscenarioInfo,
        Seg2NomEscenarioInfo,
        true,
        false,
        EsMTons,
        EsShortTons
      );
    } else {
      setEsAcumulado(false);
      setEsPromedio(true);
      onSearchCorporate(
        Seg1ClaAnio,
        Seg1ClaMesFrom,
        Seg1NomMesFrom,
        Seg1ClaMesTo,
        Seg1NomMesTo,
        Seg1ClaEscenarioInfo,
        Seg1NomEscenarioInfo,
        Seg2ClaAnio,
        Seg2ClaMesFrom,
        Seg2NomMesFrom,
        Seg2ClaMesTo,
        Seg2NomMesTo,
        Seg2ClaEscenarioInfo,
        Seg2NomEscenarioInfo,
        false,
        true,
        EsMTons,
        EsShortTons
      );
    }
    setTipoPromedio(e);
  };
  const onChangeTons = (e) => {
    if (e === 'Mtons') {
      setEsMTons(true);
      setEsShortTons(false);
      onSearchCorporate(
        Seg1ClaAnio,
        Seg1ClaMesFrom,
        Seg1NomMesFrom,
        Seg1ClaMesTo,
        Seg1NomMesTo,
        Seg1ClaEscenarioInfo,
        Seg1NomEscenarioInfo,
        Seg2ClaAnio,
        Seg2ClaMesFrom,
        Seg2NomMesFrom,
        Seg2ClaMesTo,
        Seg2NomMesTo,
        Seg2ClaEscenarioInfo,
        Seg2NomEscenarioInfo,
        EsPromedio,
        EsAcumulado,
        true,
        false
      );
    } else {
      setEsMTons(false);
      setEsShortTons(true);
      onSearchCorporate(
        Seg1ClaAnio,
        Seg1ClaMesFrom,
        Seg1NomMesFrom,
        Seg1ClaMesTo,
        Seg1NomMesTo,
        Seg1ClaEscenarioInfo,
        Seg1NomEscenarioInfo,
        Seg2ClaAnio,
        Seg2ClaMesFrom,
        Seg2NomMesFrom,
        Seg2ClaMesTo,
        Seg2NomMesTo,
        Seg2ClaEscenarioInfo,
        Seg2NomEscenarioInfo,
        EsPromedio,
        EsAcumulado,
        false,
        true
      );
    }
    setTipoTonleadas(e);
  };

  const filterHeaderTemplate = (options) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down text-white' : 'pi pi-chevron-up text-white';
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className="card-header">
        <div className="p-toolbar p-component">
          <div className="p-toolbar-group-left">
            <div className="card-title">
              <FieldButton className={options.togglerClassName} onClick={options.onTogglerClick}>
                <span className={toggleIcon}></span> 
              </FieldButton>
              <span className={titleClassName}>
                {t('common:Filters')}
              </span>
            </div>  
          </div>
         
          <div className="p-toolbar-group-right">
          </div>
        </div> 
      </div>
    )
}


  return (
    <>
      <Panel
        header={t('common:Filters')}
        toggleable 
        headerTemplate={filterHeaderTemplate}
      >   
        <Row>
          <Col lg={8} md={8} sm={12} xs={12}>
            <Row>
              <Col
                lg={5}
                md={5}
                sm={12}
                xs={12}
                className="flex align-items-center justify-content-center"
              >
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12} className="p-3">
                    <div className="p-fluid">
                      <FieldDropdown
                        name="Seg1ClaAnio"
                        value={Seg1ClaAnio}
                        options={OpcionesAnio}
                        optionValue="ClaAnio"
                        optionLabel="NomAnio"
                        label={t('corporate:Anio')}
                        onChange={(e) => onClaAnioChange(props, e)}
                        showClear={false}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="pb-3">
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <div className="p-fluid">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">{t('corporate:From')}</span>
                        <FieldDropdown
                          name="Seg1ClaMesFrom"
                          value={Seg1ClaMesFrom}
                          options={OpcionesFromMes1}
                          optionValue="ClaMes"
                          optionLabel="NomMes"
                          onChange={(e) => onFromMes1Change(props, e)}
                          showClear={false}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <div className="p-fluid">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">{t('corporate:To')}</span>
                        <FieldDropdown
                          name="Seg1ClaMesTo"
                          value={Seg1ClaMesTo}
                          options={OpcionesToMes1}
                          optionValue="ClaMes"
                          optionLabel="NomMes"
                          onChange={(e) => onToMes1Change(props, e)}
                          showClear={false}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="pt-3">
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="p-fluid">
                      <FieldDropdown
                        name="Seg1ClaEscenarioInfo"
                        value={Seg1ClaEscenarioInfo}
                        options={OpcionesScenario1}
                        optionValue="ClaEscenarioInfo"
                        optionLabel="NomEscenarioInfo"
                        label={t('corporate:Scenario')}
                        onChange={(e) => onScenario1Change(props, e)}
                        showClear={false}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={1} md={1} sm={12} xs={12} style={{ height: '150px' }}>
                <Divider layout="vertical">
                  <b>Vs</b>
                </Divider>
              </Col>
              <Col
                lg={5}
                md={5}
                sm={12}
                xs={12}
                className="flex align-items-center justify-content-center"
              >
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="p-fluid pb-1 pt-3">
                      <FieldDropdown
                        name="Seg2ClaAnio"
                        value={Seg2ClaAnio}
                        options={OpcionesAnio2}
                        optionValue="ClaAnio"
                        optionLabel="NomAnio"
                        label={t('corporate:Anio')}
                        onChange={(e) => onClaAnio2Change(props, e)}
                        showClear={false}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <div className="p-fluid pb-1 pt-3">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">{t('corporate:From')}</span>
                        <FieldDropdown
                          name="Seg2ClaMesFrom"
                          value={Seg2ClaMesFrom}
                          options={OpcionesFromMes2}
                          optionValue="ClaMes"
                          optionLabel="NomMes"
                            // label={t('corporate:From')}
                          onChange={(e) => onFromMes2Change(props, e)}
                          showClear={false}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <div className="p-fluid pb-1 pt-3">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">{t('corporate:To')}</span>
                        <FieldDropdown
                          name="Seg2ClaMesTo"
                          value={Seg2ClaMesTo}
                          options={OpcionesToMes2}
                          optionValue="ClaMes"
                          optionLabel="NomMes"
                            // label={t('corporate:To')}
                          onChange={(e) => onToMes2Change(props, e)}
                          showClear={false}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="pt-3">
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="p-fluid pb-1 pt-3">
                      <FieldDropdown
                        name="Seg2ClaEscenarioInfo"
                        value={Seg2ClaEscenarioInfo}
                        options={OpcionesScenario2}
                        optionValue="ClaEscenarioInfo"
                        optionLabel="NomEscenarioInfo"
                        label={t('corporate:Scenario')}
                        onChange={(e) => onScenario2Change(props, e)}
                        showClear={false}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={1} md={1} sm={12} xs={12} style={{ height: '150px' }}>
                <Divider layout="vertical">
                  <b></b>
                </Divider>
              </Col>
            </Row>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <Row>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div className="p-fluid pb-1 pt-3">
                  <FieldRadioButton
                    inputId="Average"
                    name="AcumProm"
                    value="Avg"
                    label={t('corporate:Average')}
                    onChange={(value) => onChangeClaAcumProm(value)}
                    checked={TipoPromedio === 'Avg'}
                  >
                  </FieldRadioButton>
                  <FieldRadioButton
                    inputId="Accum"
                    name="AcumProm"
                    value="Ac"
                    label={t('corporate:Accum')}
                    onChange={(value) => onChangeClaAcumProm(value)}
                    checked={TipoPromedio === 'Ac'}
                  >
                  </FieldRadioButton>
                </div>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div className="p-fluid pb-1 pt-3">
                  <FieldRadioButton
                    inputId="EsMTons"
                    name="Tons"
                    value="Mtons"
                    label={t('corporate:Metric')}
                    onChange={(value) => onChangeTons(value)}
                    checked={TipoTonleadas === 'Mtons'}
                  >
                  </FieldRadioButton>
                  <FieldRadioButton
                    inputId="EsShorTons"
                    name="Tons"
                    value="ShorTons"
                    label={t('corporate:Short')}
                    onChange={(value) => onChangeTons(value)}
                    checked={TipoTonleadas === 'ShorTons'}
                  >
                  </FieldRadioButton>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Panel> 
    </>
  );
};
export default FilterCorporateView;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import SweatAlertCustomIcon from 'components/SweatAlert/SweatAlertCustomIcon';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldUpload from 'components/Controls/FieldUpload/FieldUpload';
import FieldTextArea from 'components/Controls/FieldTextArea';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import { Column } from 'primereact/column';
import * as yup from 'yup';
import YupValidator from 'utils/YupValidator';
import { callApi, showSweetAlert } from 'utils/utils';
import { Tooltip } from 'primereact/tooltip';
import swal from '@sweetalert/with-react';
import { config } from 'utils/config';

const MinutasMeetingAttachment = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['actionPlan', 'minutameeting', 'common']);
  const [errors, setErrors] = useState({});  
  const [Comentario, setComentario] = useState(null); 
  const [datafiles, setdatafiles] = useState([]);
  const dt = useRef(null);
  const [TabActiveIndex, setTabActiveIndex] = useState(1); 
  const [IdMinuta, setIdMinuta] = useState(props.IdMinuta === undefined ? 0 : props.IdMinuta);
  const [MinutaFileList, setMinutaFileList] = useState([]);

  useEffect(() => {
    obtenerInformacionMinutaFileAttachtment();
    if (props.IsUpload !== undefined && !props.IsUpload) {
      setTabActiveIndex(2);
    }
    setErrors({});
  }, [props.IdMinuta]);

  useEffect(() => {}, [props.Filters]);

  const obtenerInformacionMinutaFileAttachtment = () => {
    const GetActionPlanFile = `${config.UrlApiProject}ScoreCard/GetMinutaArchivoDocumentos`;
    const paramsToService = {
      IdMinuta,
    };
    callApi(GetActionPlanFile, 'POST', paramsToService, (response) => {
      setMinutaFileList(response.data.Anexos);
    });
  };

  const onChangeComentario = (e) => {
    const newErrors = { ...errors };
    delete newErrors.Comentario;
    setErrors(newErrors);
    setComentario(e.target.value);
  };


  const save = async () => {
    const value = {
      Comentario,
      datafiles
    };

    const validator = new YupValidator(valitationScheme);
    const esValido = await validator.validate(value);

    if (!esValido) {
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      return;
    }
    const urlWebServiceSave = `${config.UrlApiProject}ScoreCard/SaveMinutaArchivoDocumentos`;
    const paramsService = {
      IdMinuta,
      FileData: datafiles[0].FileData,
      NomArchivo: datafiles[0].FileName,
      Extension: datafiles[0].FileExtension,      
      Comentario,
    };
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {
      setComentario(null);     
      setdatafiles([]);
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      obtenerInformacionMinutaFileAttachtment();
      setTabActiveIndex(2);    
    });
  };

  

  const valitationScheme = yup.object().shape({
    Comentario: yup.string().required(t('common:RequiredField')).nullable(),
    datafiles: yup
      .array()
      .min(1, t('common:RequiredField'))
      .required(t('common:RequiredField'))
      .nullable(),
    AttributeList: yup.array().of(
      yup.object().shape({
        RespuestaAttribute: yup.string().when('EsObligatorio', {
          is: true,
          then: yup.string().trim().required(t('common:RequiredField')).nullable(),
          otherwise: yup.string().nullable(),
        }),
      })
    ),
  });

  const switchTab = (tab) => {
    if (TabActiveIndex !== tab) {
      setTabActiveIndex(tab);
    }
  };

  const confirmationMessageHTML = (
    <div>
      <SweatAlertCustomIcon
        title={t('common:SubmitConfirmation')}
        letter={t('common:QuestionMark')}
      >
      </SweatAlertCustomIcon>
      <div>{t('actionPlan:SubmitRemoveDocument')}. </div>
    </div>
  );

  const downloadFile = (rowData) => {
    const GetFileData = `${config.UrlApiProject}ScoreCard/GetMinutaArchivoDocumentosById`;
    const paramsToService = {
        IdMinutaArchivo: rowData.IdMinutaArchivo,
    };
    callApi(GetFileData, 'POST', paramsToService, (response) => {
        const AnexoDetalle = response.data.AnexoDetalle[0];
        if(AnexoDetalle!==undefined){
          const linkSource = `data:application/${AnexoDetalle.TipoExtension};base64,${AnexoDetalle.FileData}`;
          const downloadLink = document.createElement("a");
          const fileName = `${AnexoDetalle.NombreArchivo}`;
          downloadLink.href = linkSource;
          downloadLink.download = `${fileName}.${AnexoDetalle.TipoExtension}`;
          downloadLink.click();
        }
      });
  };

  const onConfirmRemoveSubmit = (pIdMinutaArchivo) => {
    swal({
      buttons: {
        cancelar: {
          text: 'Cancel',
          value: 'cancel',
          className: 'rounded-button danger',
        },
        continuar: {
          text: 'Continue',
          value: 'continue',
          className: 'rounded-button light-green',
        },
      },
      content: confirmationMessageHTML,
    }).then((value) => {
      switch (value) {
        case 'continue':
          // run function
          onSubmit(pIdMinutaArchivo);
          break;
        default:
          break;
      }
    });
  };

  const onSubmit = async (pIdMinutaArchivo) => {
    const paramsService = {
        IdMinutaArchivo: pIdMinutaArchivo,
    };
    const SubmitBajaAnexosActionPlan = `${config.UrlApiProject}ScoreCard/BajaAnexosMinutaileById`;
    callApi(SubmitBajaAnexosActionPlan, 'POST', paramsService, (response) => {
        obtenerInformacionMinutaFileAttachtment();    
    });
  };

  const NomArchivoTemplate = (rowData) => {
    return <>{`${rowData.NomArchivo}.${rowData.Extension}`}</>;
  };

  const AnexoInfo = (rowData) => {
    return (
      <>
        <Col className="text-right">
          <Tooltip target=".p-button-info" mouseTrack mouseTrackTop={10} />
          <FieldButton
            type="button"
            onClick={() => downloadFile(rowData)}
            data-pr-position="top"
            data-pr-tooltip={t('minutameeting:Download')}
            icon="pi pi-arrow-circle-down"
            className="p-button-rounded p-button-info p-button-outlined"
          />
          &nbsp;
          {props.IsReadyOnly !== undefined && props.IsReadyOnly ? (
            <></>
          ) : (
            <>
              <Tooltip target=".p-button-danger" mouseTrack mouseTrackTop={10} />
              <FieldButton
                type="button"
                onClick={() => onConfirmRemoveSubmit(rowData.IdMinutaArchivo)}
                data-pr-position="top"
                data-pr-tooltip={t('minutameeting:Remove')}
                icon="pi pi-trash"
                className="p-button-rounded p-button-danger p-button-outlined"
              />
            </>
          )}
        </Col>
      </>
    );
  };


  const ondocFielUpLoadChange = (files) => {
    const newErrors = { ...errors };
    delete newErrors.datafiles;
    setErrors(newErrors);
    setdatafiles(files);
  };

  return (
    <>
      <div className="p-tabview p-component pb-3">
        <Nav className="p-tabview-nav">
          <NavItem
            hidden={props.IsUpload !== undefined && !props.IsUpload}
            className={
              TabActiveIndex === 1
                ? 'p-tabview-selected p-highlight p-unselectable-text nav-item flex-sm-fill'
                : 'p-unselectable-text nav-item flex-sm-fill'
            }
          >
            <NavLink
              className="p-tabview-nav-link"
              onClick={() => {
                switchTab(1);
              }}
            >
              {t('minutameeting:Upload')}
            </NavLink>
          </NavItem>
          <NavItem
            hidden={props.IsUpload !== undefined && !props.IsUpload}
            className={
              TabActiveIndex === 2
                ? 'p-tabview-selected p-highlight p-unselectable-text nav-item flex-sm-fill'
                : 'p-unselectable-text nav-item flex-sm-fill'
            }
          >
            <NavLink
              className="p-tabview-nav-link"
              onClick={() => {
                switchTab(2);
              }}
            >
              {t('minutameeting:UploadedDocuments')}
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <TabContent className="p-tabview-panels" activeTab={TabActiveIndex}>
        <TabPane tabId={1} hidden={props.IsUpload !== undefined && !props.IsUpload}>         
          <Row>
            <Col lg={1} md={1} sm={12} xs={12}>
              <span className="bold">{t('minutameeting:File')}</span>
            </Col>
            <Col>
              <FieldUpload
                files={datafiles}
                onChange={(files) => ondocFielUpLoadChange(files)}
                errors={errors}
              >
              </FieldUpload>
            </Col>
          </Row>
          <Row className="pt-2">
            <Col lg={1} md={1} sm={12} xs={12}>
              <span className="bold">{t('minutameeting:Comment')}</span>
            </Col>
            <Col>
              <div className="p-fluid">
                <FieldTextArea
                  name="Comentario"
                  rows={2}
                  value={Comentario}
                  onChange={onChangeComentario}
                  errors={errors}
                  maxLength={1000}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="text-right">
              <FieldButton
                type="button"
                onClick={save}
                label={t('common:Save')}
                className="p-button-success no-border p-mr-2 rounded-button"
                style={{ marginRight: '.25em' }}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId={2}>
          <Row className="pt-3">
            <Col>
              <div style={{ minHeight: '300px' }}>
                <FieldDataTable
                  value={MinutaFileList}
                  header={t('minutameeting:Documents')}
                  scrollable
                  scrollHeight="250px"
                  className="p-datatable-striped"
                >
                  <Column
                    field="NomArchivo"
                    body={NomArchivoTemplate}
                    columnKey="NomArchivo"
                    header={t('minutameeting:File')}
                    headerStyle={{ width: '240px' }}
                  >
                  </Column>
                  <Column
                    field="Comentario"
                    columnKey="Comentario"
                    header={t('minutameeting:Comment')}
                    headerStyle={{ width: '180px' }}
                  >
                  </Column>
                  {/* <Column field="NomUsuarioInsArchivo" columnKey="NomUsuarioInsArchivo" header={t('minutameeting:User')} headerStyle={{ width: '120px' }}></Column>      */}
                  <Column body={AnexoInfo} headerStyle={{ width: '90px' }}></Column>
                </FieldDataTable>
              </div>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </>
  );
};
export default MinutasMeetingAttachment;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React  from 'react';       

function PageContent (props) { 
    return (  
      <>  
        <div className="bg-title">  
          <div className='p-toolbar'>
            <div className='p-toolbar-group-left'>
              {props.title}  
            </div>
            <div className='p-toolbar-group-right'>
              { props.toolBar }
            </div>
          </div> 
        </div>  
        <div>
          {props.subheader}  
        </div>
        <div className={`${props.className === undefined ? '' : props.className} content`}>
          { props.children }
        </div> 
      </> 
    )
}   
export default PageContent; 

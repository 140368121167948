import React, {useState, useEffect} from 'react';
import { 
  Row, 
  Col,
  Card,
  CardHeader,
  CardBody,
  CardText,
  CardFooter } from 'reactstrap';
  import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { config } from '../../../utils/config';
import { callApi } from '../../../utils/utils'; 
import './ActiveDowntimes.scss';
import PageContent from '../../../layouts/PageContent';
import { Dialog } from 'primereact/dialog';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable';
import { Column } from 'primereact/column';

const ActiveDowntimesComponent = (props) => {
  const { t, i18n } = useTranslation(['productionDashboard', 'common']);
  const MINUTES_MS = 60000; // 1 minute

  let defaultZone;
  let firstRun = 0; // Fetch data on first run

  // Check to see if the zone was passed from the filter or as a URL parameter
  if (props.location !== undefined) {
    const params = queryString.parse(props.location.search);
    defaultZone = params.ClaZona !== undefined ? params.ClaZona : "";
  }
  else {
    defaultZone = props.filters.ClaZona  !== undefined ? props.filters.ClaZona : "";
  }

  const [claZona, setClaZona] = useState(defaultZone); // Zone number
  const [nomZona, setNomZona] = useState(""); // Zone name
  const [downtimeData, setDowntimeData] = useState([]); // Active downtimes
  const [openDetails, setOpenDetails] = useState(false); // Detail Dialog open/close
  const [details, setDetails] = useState([]); // Details for individual cell

  useEffect(() => {

    // Check to see the the zone changed
    if (props.location !== undefined) {
      const params = queryString.parse(props.location.search);
      defaultZone = params.ClaZona !== undefined ? params.ClaZona : "";
    }
    else {
      defaultZone = props.filters.ClaZona  !== undefined ? props.filters.ClaZona : "";
    }

    // Get data on first run
    if (firstRun === 0) {
      getData();
      firstRun = 1;
    }

    // Refresh the data every 1 minute
    const getDataInterval = setInterval(() => {
      getData();
    }, MINUTES_MS)

    return () => clearInterval(getDataInterval);
  }, [props.filters]);



  // Get the active downtimes
  const getData = () => {
    const filters = {}
    filters.ClaZona = defaultZone;

    // console.log(defaultZone);

    const paramsService = {
      ... filters,
    }

    const urlWebService = `${config.UrlApiProject}Produccion/GetActiveDowntimeData?ClaZona=${defaultZone}`;

    callApi(urlWebService, 'GET', paramsService, (response) => { 
      setDowntimeData(response.data.cells);

      if (response.data.cells.length > 0 ){
        setNomZona(response.data.cells[0].ZoneName);
      }
      else{
        setNomZona('');
      }
     
    }, () => {
      // no hacemos nada solo  para evitar de mandar el mensaje si algun momento se pierde la conexion...
    });  
  };

  // Handle Detail button click
  const onOpenDetails = (claCelda, claTurno) => {
    const filters = {
      ClaCelda: claCelda,
      ClaTurno: claTurno,
    };

    const paramsService = {
      ... filters,
    };

    const urlWebService = `${config.UrlApiProject}Produccion/GetDowntimeDetails?ClaCelda=${claCelda}&ClaTurno=${claTurno}`;

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setDetails(response.data.rows);

      setOpenDetails(true);
    }, () => {
      // no hacemos nada solo  para evitar de mandar el mensaje si algun momento se pierde la conexion...
    });
  };

  const onCloseDetails = () => { setOpenDetails(false); };

  // Format the time
  const formatCellTime = (cellTime) => {
    const time = new Date(cellTime);

    const options = {
      dateStyle: "short",
      timeStyle: "short"
    }

    return(time.toLocaleString("en-US", options));
  }

  // Create a Card from the cell data
  const addCell = (cell) => {

    // Cell is down
    if (cell.ActiveDowntime === 1) {
      // Card Style
      let style = "up"
      if (!cell.CheckInTime && cell.ActiveDowntime === 1) {
        style = "down-red"
      }
      if (cell.CheckInTime && cell.ActiveDowntime === 1) {
        style = "down-yellow"
      }

      let andonColor = "black"
      switch(cell.ANDON.toUpperCase()) {
        case "MAINTENANCE":
          andonColor = "DarkRed";
          break;
        case "SUPERVISOR":
          andonColor = "Purple";
          break;
        case "SETUP":
          andonColor = "rgb(255,123,0)";
          break;
        case "QUALITY":
          andonColor = "Blue";
          break;
        case "TEAM LEAD":
          andonColor = "DarkBlue";
          break;
        case "ENGINEER":
          andonColor = "DarkGreen";
          break;
        default:
          andonColor = "Black";
      }

      // Hours and minutes
      let hours = 0;
      let minutes = 0;
      if (cell.ActiveDowntime === 1) {
        hours = Math.floor(cell.TimeElapsed / 60);
        minutes = cell.TimeElapsed % 60;
      }



      return (
        <Col xs={12} md={6} sm={12} lg={4}>
          <Card className="card-downtimes">
            <CardHeader className={`card-header ${style}`}>{`${cell.CellCode} - ${cell.CellType} - ${cell.ShiftName}`}</CardHeader>
            <CardBody className="card-body-downtimes">
              <CardText className="card-text-downtimes">
                <Col xs={12} md={12} sm={12} lg={12}>
                  <Row className="align-items-start">
                    <Col xs={4} md={4} sm={4} lg={4}>
                      <Row className="text-label">{t("productionDashboard:DowntimeRequestLabel")}</Row>
                      <Row>{formatCellTime(cell.StartDate)}</Row>
                      <Row className="text-label">{t("productionDashboard:DowntimeByLabel")}</Row>
                      <Row style={{color: andonColor}}>{cell.ANDON}</Row>
                    </Col>
                    <Col xs={4} md={4} sm={4} lg={4}>
                      <Row className="text-label">{t("productionDashboard:AssignedDateLabel")}</Row>
                      <Row>{!cell.AssignedDate || cell.AssignedDate === "" ? "-" : formatCellTime(cell.AssignedDate)}</Row>
                      <Row className="text-label">{t("productionDashboard:MachineLabel")}</Row>
                      <Row>{`${cell.MachineType} - ${cell.MachinePosition}`}</Row>
                    </Col>
                    <Col xs={4} md={4} sm={4} lg={4}>
                      <Row className="text-label">{t("productionDashboard:CheckInLabel")}</Row>
                      <Row>{!cell.CheckInTime || cell.CheckInTime === "" ? "-" : formatCellTime(cell.CheckInTime)}</Row>
                      <Row className="text-label">{t("productionDashboard:ReasonLabel")}</Row>
                      <Row>{cell.Reason}</Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={10} md={10} sm={10} lg={10}>
                      <Row><span className="text-normal">{t("productionDashboard:AssignedToLabel")}</span>&nbsp;{!cell.AssignedTo || cell.AssignedTo === "" ? "-" : cell.AssignedTo}</Row>
                      <Row><span className="text-normal">{t("productionDashboard:DescriptionLabel")}</span>&nbsp;{cell.Description}</Row>
                    </Col>
                    <Col>
                      <Row className="float-right"><FieldButton className="details-button btn-sm" onClick={() => {onOpenDetails(cell.CellNumber, cell.ShiftNumber)}}>{t("productionDashboard:detailsButton")}</FieldButton></Row>
                    </Col>
                  </Row>
                </Col>
              </CardText>
            </CardBody>
            <CardFooter className={`card-footer ${style}`}><span className="text-normal">{t("productionDashboard:TimeElapsedLabel")}</span>{` ${hours} H ${minutes} M`}</CardFooter>
          </Card>
        </Col>
      )
    }

    // Cell is up
    // Hours and minutes
    const runHours = Math.floor(cell.RunTime / 60);
    const runMinutes = cell.RunTime % 60;
    const downHours = Math.floor(cell.TotalDownTime / 60);
    const downMinutes = cell.TotalDownTime % 60;
    const downtimeColor = cell.TotalDownTime > 0 ? "Red" : "Black"

    return (
      <Col xs={6} md={3} sm={4} lg={2}>
        <Card className="card-downtimes">
          <CardHeader className="up-green">{`${cell.CellCode} - ${cell.CellType} - ${cell.ShiftName}`}</CardHeader>
          <CardBody className="card-body-downtimes">
            <CardText className="card-text-downtimes">
              <Col xs={12} ms={12} sm={12} lg={12}>
                <Row><span className="text-normal">{t("productionDashboard:RuntimeLabel")}</span>&nbsp;{runHours} H {runMinutes} M</Row>
                <Row><span className="text-normal">{t("productionDashboard:TimesDownLabel")}</span>&nbsp;{cell.TotalDownTimes}</Row>
                <Row><span className="text-normal">{t("productionDashboard:TotalDowntimeLabel")}</span>&nbsp;<span style={{color: downtimeColor}}>{downHours} H {downMinutes} M</span></Row>
                <Row><FieldButton className="details-button" onClick={() => {onOpenDetails(cell.CellNumber, cell.ShiftNumber)}}>{t("productionDashboard:detailsButton")}</FieldButton></Row>
              </Col>
            </CardText>
          </CardBody>
          <CardFooter className="up-green">&nbsp;</CardFooter>
        </Card>
      </Col>
    )
  };

  return ( 
    <>
      <Dialog
        header="Details"
        visible={openDetails}
        onHide={onCloseDetails}
      >
        <FieldDataTable
          value={details}
          scrollable 
          scrollHeight="330px"
        >
          <Column field='CellCode' header={t('productionDashboard:detailsCellCodeLabel')} headerStyle={{ width: '70px' }} />
          <Column field='Shift' header={t('productionDashboard:detailsShiftLabel')} headerStyle={{ width: '70px' }} />
          <Column field='StartDate' header={t('productionDashboard:detailsStartDateLabel')} headerStyle={{ width: '150px' }} />
          <Column field='AssignedDate' header={t('productionDashboard:detailsAssignedDateLabel')} headerStyle={{ width: '150px' }} />
          <Column field='AssignedTo' header={t('productionDashboard:detailsAssignedToLabel')} />
          <Column field='ArrivedDate' header={t('productionDashboard:detailsCheckInDateLabel')} headerStyle={{ width: '150px' }}  />
          <Column field='EndDate' header={t('productionDashboard:detailsEndDateLabel')} headerStyle={{ width: '150px' }}  />
          <Column field='TimeElapsed' header={t('productionDashboard:detailsTimeElapsedLabel')} />
          <Column field='Reason' header={t('productionDashboard:detailsReasonLabel')} />
          <Column field='Description' header={t('productionDashboard:detailsDescriptionLabel')} />
          <Column field='CountL30' header={t('productionDashboard:detailsCountL30Label')} headerStyle={{ width: '100px' }} />
          <Column field='ActionTaken' header={t('productionDashboard:detailsActionTakenLabel')} />
        </FieldDataTable>
      </Dialog>
      <Row>
        <Col xs={12} sm={12} ms={12} lg={12}>
          <div className="header-dashboard">
            <Row className="header-dashboard-title">
              <Col xs={6} sm={6} ms={6} lg={6}>
                {t('productionDashboard:ActiveDowntimesTitle') }
              </Col>
              <Col xs={6} sm={6} ms={6} lg={6}>
                <span className="float-right">{nomZona}</span>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row><br></br></Row>
      <Row className="align-items-start">
        {downtimeData.map(cell => addCell(cell))} 
      </Row>
    </>
  )
}

export default ActiveDowntimesComponent;
import Highcharts from 'highcharts';
import { Badge } from 'primereact/badge';
import React, { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { config } from 'utils/config';
import { callApi } from 'utils/utils';
import './CompanyOverviewOverallStatusChart.scss';
import CompanyOverviewOverallStatusChartSkeleton from './CompanyOverviewOverallStatusChartSkeleton';

const CompanyOverviewOverallStatusChart = (props) => {
  const {t, i18n} = useTranslation(['companyOverview', 'common']);  
  const [Loading, setLoading] = useState(true);
  const [TotalDown, setTotalDown] = useState('');
  const [TotalActive, setTotalActive] = useState('');
  const [TotalMicrostops, setTotalMicrostops] = useState('');

  const getReport = (
    ReportType,
    Shifts,
    StartDate,
    EndDate
  ) => {
    setLoading(true);
    const url = `${config.UrlApiProject}Operations/GetCompanyOverviewOverallStatus`;       
    const paramsService = {
      ReportType,
      Shifts,
      StartDate: StartDate.toDateString(),
      EndDate: EndDate.toDateString()
    };
    callApi(url, 'POST', paramsService, (response) => {
      const report = response.data.CompanyOverviewOverallStatus;
      setTotalDown(report? report.DowntimeHrs: 0);
      setTotalActive(report? report.RunHrs: 0);
      setTotalMicrostops(report? report.MicrostopsHrs: 0);     
      setLoading(false); 
    }, (error) => {
      setLoading(false); 
      console.error("Error getting company overview utilization", error);   
    }, false, false);
  };
  
  const calculaDescripcionTiempo = (tiempoHrs) => {
    if (tiempoHrs >= 1){
      return `${Highcharts.numberFormat(tiempoHrs,1, '.', ',')}h`
    }

    return `${Highcharts.numberFormat(tiempoHrs*60.0,1, '.', ',')}m`
  }

  useEffect(() => {      
    if (props.reportType && props.shifts && props.startDate && props.endDate) {
      getReport(props.reportType, props.shifts, props.startDate, props.endDate);   
    }    
  }, [
    props.refresh
  ]);

  const headerTemplate = (
    <>
      <Row>
        <Col xs={8}>
          <h5>
            {t('companyOverview:OverallStatus')}
          </h5>     
        </Col>
        <Col 
          xs={4}
          className='text-right'
        >
          <span className="p-badge CompanyOverviewOverallStatusChart-liveBadge"> 
            {t('companyOverview:Live')}
          </span>          
        </Col>
      </Row>  
    </>
  );

  const chartTemplate = (
    <>
      <Row className='text-center pt-2'>
        <Col xs={4}>
          <Badge
            value={calculaDescripcionTiempo(TotalDown)}
            severity="danger"
            size="large"
          />                   
        </Col>
        <Col xs={4}>
          <Badge
            value={calculaDescripcionTiempo(TotalActive)}
            severity="success"
            size="large"               
          />               
        </Col>
        <Col xs={4}>
          <Badge
            className='CompanyOverviewOverallStatusChart-microstopsBadge'
            value={calculaDescripcionTiempo(TotalMicrostops)}            
            size="large"               
          />              
        </Col>
      </Row>   
      <Row className='text-center'>
        <Col 
          xs={4}
          className='CompanyOverviewOverallStatusChart-label'
        >               
          <span>
            {t('companyOverview:Down')}            
          </span>
        </Col>
        <Col 
          xs={4}
          className='CompanyOverviewOverallStatusChart-label'
        >           
          <span>
            {t('companyOverview:Active')}            
          </span>
        </Col>
        <Col 
          xs={4}
          className='CompanyOverviewOverallStatusChart-label'
        >          
          <span>
            {t('companyOverview:Microstops')}            
          </span>
        </Col>
      </Row>   
    </>
  );

  return (
    <>
      {
        Loading? <CompanyOverviewOverallStatusChartSkeleton /> : (
          <Card
            className='CompanyOverviewOverallStatusChart'
            style={{borderRadius: '1%'}}
          >
            <CardBody>
              <Row className='align-items-start'>
                <Col> 
                  {headerTemplate}
                </Col>            
              </Row>
              <Row className='align-items-start'>            
                <Col>
                  {chartTemplate}                     
                </Col>
              </Row>
            </CardBody>
          </Card>  
        )
      }  
    </>
  )
}

export default CompanyOverviewOverallStatusChart
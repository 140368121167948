/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../../../utils/config';
import { Row, Col, Card, CardHeader, CardBody, } from 'reactstrap';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Avatar } from 'primereact/avatar';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import { Dialog } from 'primereact/dialog';
import { callApi, showSweetAlert } from '../../../utils/utils';
import FieldCalendar from '../../../components/Controls/FieldCalendar.jsx';
import FieldDropdown from '../../../components/Controls/FieldDropdown.jsx';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import FieldTextArea from '../../../components/Controls/FieldTextArea.jsx';
import FieldText from '../../../components/Controls/FieldText.jsx';
import FieldSelectButton from '../../../components/Controls/FieldSelectButton.jsx';
import moment from "moment";
import FieldUpload from 'components/Controls/FieldUpload/FieldUpload';
import FieldRadioButton from 'components/Controls/FieldRadioButton/FieldRadioButton';
import { idpVersions } from './IndividualDevelopmentPlanConstants';

/**
 * 
 * @param {*} props 
 * props:
 * - isNew
 * - idIDP
 * - reportType (M = Myself, T = Team)
 * - onHide
 * - onSaved
 * - onStatusSaved
 * @returns 
 */
const IndividualDevelopmentPlanFormV1 = (props) => {  
  const { t } = useTranslation(['hr', 'common']);
  const [errors, setErrors] = useState({});   
  const [OpcionesIDPCompetencia, setOpcionesIDPCompetencia] = useState([]); 
  const [ClaIDPEstatus, setClaIDPEstatus] = useState(0); 
  const [EsDisabled, setEsDisabled] = useState(false);   
  const [IDPClase, setIDPClase] = useState({}); 
  const [IDPAcciones, setIDPAcciones] = useState([]); 
  const [AccionesLista, setAccionesLista] = useState([]); 
  const [IdIDP, setIdIDP] = useState(null); 
  const [Respuesta1, setRespuesta1] = useState(null); 
  const [Respuesta2, setRespuesta2] = useState(null); 
  const [Respuesta3, setRespuesta3] = useState(null); 
  const [Respuesta4, setRespuesta4] = useState(null); 
  const [MotivoDeshacer, setMotivoDeshacer] = useState(null); 
  const [ObjetivoSmart, setObjetivoSmart] = useState(null); 
  const [ClaCompetencia, setClaCompetencia] = useState(null); 
  const [EsDiscutirGerente, setEsDiscutirGerente] = useState(false); 
  const [EsJefeInmediato, setEsJefeInmediato] = useState(false);  
  const [EsPlanRevisado, setEsPlanRevisado] = useState(false);  
  const [ComentariosRevisado, setComentariosRevisado] = useState(null);  
  const [TextFilter, setTextFilter] = useState(null);
  const [Filters, setFilters] = useState({}); 
  const [OpcionesYesNo, setOpcionesYesNo] = useState([{ value: false, name: t('common:No') },{ value: true, name: t('common:Yes')}]);
  const dt = useRef(null);   
  const [supportDucumentCourseDatafiles, setSupportDucumentCourseDatafiles] = useState([]);
  const [supportDucumentCertificateDatafiles, setSupportDucumentCertificateDatafiles] = useState([]); 

  useEffect(() => {     
    loadCompetenciaCmb();
    nuevaAccion();
    if (!props.isNew && props.idIDP) {
      getIdpDetalle();
    }    
  }, [    
    props.isNew,
    props.idIDP
  ]); 

  const loadCompetenciaCmb = () => { 
    const GetServiceIDP = `${config.UrlApiProject}hr/GetCompetenciaIndividualDevelopmentPersonal?Version=${idpVersions.V1}&Idioma=}`;  
    const paramsToService = { };   
    callApi(GetServiceIDP, 'GET', paramsToService, (response) => { 
      if (response.data.CompetenciaIDPInfo.length > 0)
      { 
        setOpcionesIDPCompetencia(response.data.CompetenciaIDPInfo);
      }       
    });
  }
  
  const getIdpDetalle = () => { 
    const GetServiceIDP = `${config.UrlApiProject}hr/GetIndividualDevelopmentPlanDetail`;  
    const paramsToService = { 
      // IdIDP:rowData.IdIDP,
      // ClaIDPEstatus:null,
      // Idioma: '',
      // ClaUsuario:claUsuarioSeleccionado,
      // FechaInicio:null,
      // FechaFinal:null
      IdIDP:props.idIDP,      
      Idioma: '',      
      Anio: null,
      TipoReporte: props.reportType,
      ClaUsuarioOwner:props.claUsuarioOwner
    };   
    callApi(GetServiceIDP, 'POST', paramsToService, (response) => {
      const dataIDP =  response.data.IDPLista[0];
      const dataAccionesIDP =  response.data.IDPAccionesLista;
      setEsDisabled(false);
      setErrors({});   
      if(dataIDP!==undefined){
        setIDPClase(dataIDP);
        setIdIDP(dataIDP.IdIDP);
        setEsJefeInmediato(dataIDP.EsjefeInmediato);
        setClaIDPEstatus(dataIDP.ClaIDPEstatus);
        setRespuesta1(dataIDP.Respuesta1);
        setRespuesta2(dataIDP.Respuesta2);
        setRespuesta3(dataIDP.Respuesta3);
        setRespuesta4(dataIDP.Respuesta4);
        setObjetivoSmart(dataIDP.ObjetivoSmart);
        setClaCompetencia(dataIDP.ClaCompetencia);
        setEsDiscutirGerente(dataIDP.EsDiscutirGerente);
        setEsPlanRevisado(dataIDP.EsPlanRevisado);
        setComentariosRevisado(dataIDP.ComentariosRevisado);
        setMotivoDeshacer(dataIDP.MotivoDeshacer);
        setIDPAcciones(dataAccionesIDP);
        setAccionesLista(dataAccionesIDP);        
        
        const base64Icon = `data:image/${dataIDP.SupportDocumentCourseTipoExtension};base64,${dataIDP.SupportDocumentCourseFileData}`;
        const SupportDucumentCourseFileInfo = [
          {
            FileName: `${dataIDP.SupportDocumentCourseNomArchivo}.${dataIDP.SupportDocumentCourseTipoExtension}`,
            FileData: dataIDP.SupportDocumentCourseFileData,
            objectURL: base64Icon,
            FileSize: dataIDP.SupportDocumentCourseFileSize,
            FileExtension: dataIDP.SupportDocumentCourseExtension,
          },
        ];
        setSupportDucumentCourseDatafiles(dataIDP.SupportDocumentCourseFileData === null ? [] : SupportDucumentCourseFileInfo);

        const base64Icon2 = `data:image/${dataIDP.SupportDocumentCertificateTipoExtension};base64,${dataIDP.SupportDocumentCertificateFileData}`;
        const SupportDucumentCertificateFileInfo = [
          {
            FileName: `${dataIDP.SupportDocumentCertificateNomArchivo}.${dataIDP.SupportDocumentCertificateTipoExtension}`,
            FileData: dataIDP.SupportDocumentCertificateFileData,
            objectURL: base64Icon2,
            FileSize: dataIDP.SupportDocumentCertificateFileSize,
            FileExtension: dataIDP.SupportDocumentCertificateExtension,
          },
        ];
        setSupportDucumentCertificateDatafiles(dataIDP.SupportDocumentCertificateFileData === null ? [] : SupportDucumentCertificateFileInfo);


        if( (dataIDP.ClaIDPEstatus!==1 && dataIDP.ClaIDPEstatus!==4 ) || dataIDP.EsUsuarioIDP === 0){
          setEsDisabled(true); 
        }
      }
    });
  }

  const onCompetenciaIDPChange = (value) => {  
    const newErrors = { ...errors } 
    delete newErrors.ClaCompetencia;   
    setErrors(newErrors)
    setClaCompetencia(value);
  };

  const onSelectEsDiscutirGerente = ( value ) => {  
    const newErrors = { ...errors } 
    delete newErrors.EsDiscutirGerente;   
    setErrors(newErrors)
    setEsDiscutirGerente(value); 
  }; 

  const onSelectEsPlanRevisado = ( value ) => {  
    const newErrors = { ...errors } 
    delete newErrors.EsPlanRevisado;   
    setErrors(newErrors)
    setEsPlanRevisado(value); 
  }; 

  const onChangeStartDate = (e, index) => {   
    const updatedIDPAcciones = [...IDPAcciones];
    updatedIDPAcciones[index].FechaInicioEstimada = e.target.value; 
    if (e.target.value !== undefined){ 
      updatedIDPAcciones[index].errors = null;
      const newErrors = { ...errors }  
      const field = { ...newErrors[`IDPAcciones[${index}]`] };
      field.FechaInicioEstimada = null;   
      newErrors[`IDPAcciones[${index}]`] = field;   
      setErrors(newErrors) ;  
    }
    setIDPAcciones(updatedIDPAcciones); 
  }
  const onChangeEndDate = (e, index) => {   
    const updatedIDPAcciones = [...IDPAcciones];
    updatedIDPAcciones[index].FechaFinalEstimada = e.target.value;
    if (e.target.value !== undefined){ 
      updatedIDPAcciones[index].errors = null;
      const newErrors = { ...errors }  
      const field = { ...newErrors[`IDPAcciones[${index}]`] };
      field.FechaFinalEstimada = null;   
      newErrors[`IDPAcciones[${index}]`] = field;   
      setErrors(newErrors) ;  
    } 
    setIDPAcciones(updatedIDPAcciones); 
  }

  const onChangeFechaFinalReal = (e, index) => {   
    const updatedIDPAcciones = [...IDPAcciones];
    updatedIDPAcciones[index].FechaFinalReal = e.target.value;
    if (e.target.value !== undefined){ 
      updatedIDPAcciones[index].errors = null;
      const newErrors = { ...errors }  
      const field = { ...newErrors[`IDPAcciones[${index}]`] };
      field.FechaFinalReal = null;   
      newErrors[`IDPAcciones[${index}]`] = field;   
      setErrors(newErrors) ;  
    } 
    setIDPAcciones(updatedIDPAcciones); 
  }

  const onChangeComentariosSeguimiento = (e, index) => {   
    const updatedIDPAcciones = [...IDPAcciones];
    updatedIDPAcciones[index].ComentariosSeguimiento = e.target.value;
    if (e.target.value !== undefined){ 
      updatedIDPAcciones[index].errors = null;
      const newErrors = { ...errors }  
      const field = { ...newErrors[`IDPAcciones[${index}]`] };
      field.ComentariosSeguimiento = null;  
      newErrors[`IDPAcciones[${index}]`] = field;  
      setErrors(newErrors) ;  
    }
    setIDPAcciones(updatedIDPAcciones); 
  }

  const onChangeRespuesta1 = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.Respuesta1;   
    setErrors(newErrors) 
    setRespuesta1(e.target.value)
  } 
  const onChangeComentariosRevisado = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.ComentariosRevisado;   
    setErrors(newErrors) 
    setComentariosRevisado(e.target.value)
  }
  const onChangeRespuesta2 = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.Respuesta2;   
    setErrors(newErrors) 
    setRespuesta2(e.target.value)
  }
  const onChangeRespuesta3 = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.Respuesta3;   
    setErrors(newErrors) 
    setRespuesta3(e.target.value)
  }
  const onChangeRespuesta4 = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.Respuesta4;   
    setErrors(newErrors) 
    setRespuesta4(e.target.value)
  }

  const onChangeObjetivoSmart = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.ObjetivoSmart;   
    setErrors(newErrors) 
    setObjetivoSmart(e.target.value)
  }

  const headerActions = (rowData) => {
    return (
      <> 
        <FieldButton icon="pi pi-trash" disabled={EsDisabled} onClick={() => handleRemoveClick(rowData)} className="p-button-rounded p-button-warning" /> 
      </>
    );
  }


  const onChangeEsAccionCompletada = (e, index) => {   
    const updatedIDPAcciones = [...IDPAcciones];
    if(updatedIDPAcciones[index].EsAccionCompletada===e){
      updatedIDPAcciones[index].EsAccionCompletada = null;
    }else{
      updatedIDPAcciones[index].EsAccionCompletada = e;
    }
    if (e !== undefined){ 
      updatedIDPAcciones[index].errors = null;
      const newErrors = { ...errors }  
      const field = { ...newErrors[`IDPAcciones[${index}]`] };
      field.EsAccionCompletada = null;   
      newErrors[`IDPAcciones[${index}]`] = field;   
      setErrors(newErrors) ;  
    } 
    setIDPAcciones(updatedIDPAcciones); 
  }

  const regresarListado = () => {
    onHide();
  }

  const nuevaAccion = () =>{
    setIdIDP(0);
    setClaIDPEstatus(0);
    setRespuesta1('');
    setRespuesta2('');
    setRespuesta3('');
    setRespuesta4('');
    setObjetivoSmart('');
    setComentariosRevisado('');
    setClaCompetencia(null);
    setEsPlanRevisado(false);
    setEsDiscutirGerente(false);
    setEsJefeInmediato(false);
    setIDPAcciones([]);
    setAccionesLista([]);
    setErrors({});
    setIDPClase({});
    setEsDisabled(false);
    // setEsListado(false);
    setSupportDucumentCourseDatafiles([]);
    setSupportDucumentCertificateDatafiles([]);
  }
  const schemaAction = yup.object().shape({
    TextFilter: yup.string().required(t('common:RequiredField')).nullable()
  });
  
  const addAction = async () => {
    const value = {
      TextFilter
    }
    const validator = new YupValidator(schemaAction);
    const esValido = await validator.validate(value);
    if (!esValido){ 
      setErrors(validator.errors); 
      return;
    }
    const newRow = { 
      RowIndex: AccionesLista.length,
      rowData:{
        NomAccion:''
      },
      field: 'NomAccion',
      IdAccion: 0,
      BajaLogica:0,
      FechaInicioEstimada:null,
      FechaFinalEstimada:null,
      FechaFinalReal:null,
      ComentariosSeguimiento:null,
      NomAccion: TextFilter
    }; 
    AccionesLista.push(newRow);
    setAccionesLista(AccionesLista);
    setTextFilter(null);
    setIDPAcciones(AccionesLista);
    setFilters({ ...Filters, arreglo:AccionesLista, row:newRow.RowIndex});
  } 

  const handleRemoveClick = (rowData) => {
    const currentList = [...AccionesLista];
    currentList[rowData.RowIndex].BajaLogica = 1;
    setAccionesLista(AccionesLista);
    setIDPAcciones(AccionesLista);
    setFilters({ ...Filters, arreglo:AccionesLista, row:rowData.RowIndex});
  };
  
  const showActiveRows = (rows) => {
    const activeRows = [];
    if (rows.length>0) {
      rows.forEach((item, index) => {
        if (item.BajaLogica === 0) {
          item.RowIndex = index;
          activeRows.push({...item});
        }
      });
    } else {
      return [];
    }
    return activeRows; 
  };

  const onNomAccionValueChange = (props, value) => {  
    const updatedProducts = [...AccionesLista];
    updatedProducts[props.rowData.RowIndex][props.field] = value;
    setAccionesLista(AccionesLista);
    setIDPAcciones(AccionesLista);
    setTextFilter(null); 
    setFilters({ ...Filters, arreglo:AccionesLista, row:props.rowIndex});
  }; 

 const onChangeTextFilter = (value) => {
    const newErrors = { ...errors } 
    delete newErrors.TextFilter;   
    setErrors(newErrors)   
    setTextFilter(value); 
  }; 

  const emptyValueValidator = (e) => {
    const { rowData, field } = e.columnProps;
    return rowData[field].trim().length > 0;
  }

  const NomAccionEditor = (props, rowData) => {
    rowData = props.rowData;
    return (
      <div className="p-fluid">
        <FieldText   
          value={props.rowData.NomAccion} 
          onChange={(e) => onNomAccionValueChange(props, e.target.value, rowData)}
          disabled={EsDisabled}
          maxLength={250}
        />  
      </div>
      
    );
 }

  const valitationSchemeRejected = yup.object().shape({
    ComentariosRevisado: yup.string().required(t('common:RequiredField')).nullable()
  });
  const valitationSchemeSave = yup.object().shape({
    ClaCompetencia: yup.string().required(t('common:RequiredField')).nullable(),
    ObjetivoSmart: yup.string().required(t('common:RequiredField')).nullable(),
    IDPAcciones: yup.array().of(
        yup.object().shape({  
          FechaInicioEstimada: yup.date().when('BajaLogica', {is: 0,
            then: yup.date().required(t('common:RequiredField')).nullable(),
            otherwise: yup.date().nullable()
          }), 
          FechaFinalEstimada:  yup.date().when('BajaLogica', {is: 0,
            then: yup.date().required(t('common:RequiredField')).nullable(),
            otherwise: yup.date().nullable()
          })
        })
      )
  });  

  const valitationSchemeCompleted = yup.object().shape({
    IDPAcciones: yup.array().of(
        yup.object().shape({  
          FechaFinalReal: yup.date().when('EsAccionCompletada', {is: true,
            then: yup.date().required(t('common:RequiredField')).nullable(),
            otherwise: yup.date().nullable()
          }), 
          ComentariosSeguimiento:  yup.string().when('EsAccionCompletada', {is: false,
            then: yup.string().required(t('common:RequiredField')).nullable(),
            otherwise: yup.string().nullable()
          }) 
          // ,EsAccionCompletada: yup.boolean().required(t('common:RequiredField'))
        })
      )
  });  

  const saveStatus = async (ClaEstatus) => {
    let value = {
      ComentariosRevisado
    }
    let validator;
    let esValido;
    if(ClaEstatus===4){
      validator = new YupValidator(valitationSchemeRejected);
      esValido = await validator.validate(value);
      if (!esValido){ 
        setErrors(validator.errors);
        showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
        return;
      }
    }
    if(ClaEstatus===6){
      value = {
        IDPAcciones
      }
      validator = new YupValidator(valitationSchemeCompleted);
      esValido = await validator.validate(value);
      if (!esValido){ 
        setErrors(validator.errors);
        showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
        return;
      }
    }
    const urlWebServiceSave = `${config.UrlApiProject}hr/SaveStatusIndividualDevelopmentPersonal`;
    const paramsService = {
      IdIDP,
      ClaIDPEstatus:ClaEstatus,
      ComentariosRevisado,
      IndividualDevelopmentPersonalAcciones:IDPAcciones,
      SupportDocumentCertificateFileData:(supportDucumentCertificateDatafiles.length > 0 ? supportDucumentCertificateDatafiles[0].FileData : null),
      SupportDocumentCertificateNomArchivo:(supportDucumentCertificateDatafiles.length > 0 ? supportDucumentCertificateDatafiles[0].FileName: null),
      SupportDocumentCertificateExtension:(supportDucumentCertificateDatafiles.length > 0 ? supportDucumentCertificateDatafiles[0].FileExtension: null) 
    }; 
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {
      showSweetAlert(t('common:Success'), t('common:SaveSuccess'), 'success');
      // getIDP(StartDateFilter,EndDateFilter);
      if (props.onStatusSaved && typeof props.onStatusSaved === 'function') {
        props.onStatusSaved();
      }
    });
  }

  const save = async () => {
    const value = {
      ClaCompetencia,
      ObjetivoSmart,
      IDPAcciones
    }
    let validator = new YupValidator(valitationSchemeSave);
    let esValido = await validator.validate(value);
    if (!esValido){ 
      setErrors(validator.errors); 
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      return;
    }
    if(EsDiscutirGerente){
      if(showActiveRows(IDPAcciones).length===0){
        const valueAction = {
          TextFilter:''
        }
        validator = new YupValidator(schemaAction);
        esValido = await validator.validate(valueAction);
        if (!esValido){ 
          setTextFilter(null);
          setErrors(validator.errors);  
          showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
          return;
        }
      }
    }
    const urlWebServiceSave = `${config.UrlApiProject}hr/SaveIndividualDevelopmentPersonal`;
    const paramsService = {
      IdIDP,
      Respuesta1,
      Respuesta2,
      Respuesta3,
      Respuesta4,
      ObjetivoSmart,
      ClaCompetencia,
      EsDiscutirGerente,
      EsPlanRevisado,
      IndividualDevelopmentPersonalAcciones:IDPAcciones,
      SupportDocumentCourseFileData:(supportDucumentCourseDatafiles.length > 0 ? supportDucumentCourseDatafiles[0].FileData : null),
      SupportDocumentCourseNomArchivo:(supportDucumentCourseDatafiles.length > 0 ? supportDucumentCourseDatafiles[0].FileName: null),
      SupportDocumentCourseExtension:(supportDucumentCourseDatafiles.length > 0 ? supportDucumentCourseDatafiles[0].FileExtension: null) ,
      SupportDocumentCertificateFileData:(supportDucumentCertificateDatafiles.length > 0 ? supportDucumentCertificateDatafiles[0].FileData : null),
      SupportDocumentCertificateNomArchivo:(supportDucumentCertificateDatafiles.length > 0 ? supportDucumentCertificateDatafiles[0].FileName: null),
      SupportDocumentCertificateExtension:(supportDucumentCertificateDatafiles.length > 0 ? supportDucumentCertificateDatafiles[0].FileExtension: null) 
    }; 
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {
      showSweetAlert(t('common:Success'), t('common:SaveSuccess'), 'success');
      // getIDP(StartDateFilter,EndDateFilter);
      if (props.onSaved && typeof props.onSaved === 'function') {
        props.onSaved();
      }
    });
  }

  const onDownloadDocument = (file) => {     
    const linkSource = `data:application/${file.FileExtension};base64,${file.FileData}`;
    const downloadLink = document.createElement("a"); 
    downloadLink.href = linkSource;
    downloadLink.download = `${file.FileName}`;
    downloadLink.click();
  };

  const onHide = () => {
    setMotivoDeshacer(null);    
    if (props.onHide && typeof props.onHide === 'function') {
      props.onHide();
    }
  };

  const formTemplate = (
    <>
      {
          ClaIDPEstatus===3||ClaIDPEstatus===4 ?
          (
            <>
              <div className='card info mt-2'> 
                <div className='card-body'>
                  <div className='pb-3'>
                    <b>{t('hr:ManagerFeedback')}</b>
                  </div>
                  <span className="text-left">{ComentariosRevisado}</span> 
                </div>
              </div>              
            </>
          )
          :
          ( <></> )
      }
      <Row className="RowBody">
        <Col lg={8} md={8} sm={12} xs={12} className="pl-2"> 
          <div hidden={IdIDP===0}>
            <span>{t('hr:PlanCreatedby')}</span>&nbsp;
            <span><b>{IDPClase.NombreCompleto}</b> </span>
            <span>{t('hr:On')}</span>&nbsp;
            <span><b>{moment(IDPClase.FechaCreacion).format("LL")} </b> </span>&nbsp;
            <span><b>{IDPClase.HoraCreacion} </b> </span>
          </div>
        </Col>
        <Col lg={4} md={4} sm={12} xs={12} className="pl-2  text-right"> 
          <div hidden={IdIDP===0}>
            <span>{t('hr:LastUpdate')}</span>&nbsp;
            <span><b> {moment(IDPClase.FechaModificacion).format("LL")}</b></span>
              &nbsp;
            <span><b>{IDPClase.HoraModificacion} </b> </span>
          </div>
        </Col>
      </Row> 
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>  
          <Card>
            <CardBody>
              <Row>
                <Col lg={3} md={3} sm={12} xs={12} className="pl-2 text-center">
                  <Row>
                    <Col>
                      <h2> {t('hr:ThinkingBoard')} </h2> 
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h4> {t('hr:ThinkingBoardMessage')} </h4> 
                    </Col>
                  </Row>
                </Col>
                <Col lg={9} md={9} sm={12} xs={12} className="border-left">
                  <Row className="pt-3">
                    <Col>
                      <span className="bold"><h2> {t('hr:TitleStep1')} </h2> </span> 
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col> 
                      <span className="bold">{t('hr:TitleQuestion1')}</span> 
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col>
                      <div className="p-fluid"> 
                        <FieldTextArea 
                          name="Respuesta1"
                          rows={2}
                          placeholder={EsDisabled ? '' : t('hr:YourAnswer')}
                          value={Respuesta1} 
                          onChange={onChangeRespuesta1}
                          errors={errors}
                          disabled={EsDisabled}
                          maxLength={1000}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col> 
                      <span className="bold">{t('hr:TitleQuestion2')}</span> 
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col>
                      <div className="p-fluid"> 
                        <FieldTextArea 
                          name="Respuesta2"
                          rows={2}
                          placeholder={EsDisabled ? '' : t('hr:YourAnswer')}
                          value={Respuesta2} 
                          onChange={onChangeRespuesta2}
                          errors={errors}
                          disabled={EsDisabled}
                          maxLength={1000}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col> 
                      <span className="bold">{t('hr:TitleQuestion3')}</span> 
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col>
                      <div className="p-fluid"> 
                        <FieldTextArea 
                          name="Respuesta3"
                          rows={2}
                          placeholder={EsDisabled ? '' : t('hr:YourAnswer')}
                          value={Respuesta3} 
                          onChange={onChangeRespuesta3}
                          errors={errors}
                          disabled={EsDisabled}
                          maxLength={1000}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col> 
                      <span className="bold">{t('hr:TitleQuestion4')}</span> 
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col>
                      <div className="p-fluid"> 
                        <FieldTextArea 
                          name="Respuesta1"
                          rows={2}
                          placeholder={EsDisabled ? '' :t('hr:YourAnswer')}
                          value={Respuesta4} 
                          onChange={onChangeRespuesta4}
                          errors={errors}
                          disabled={EsDisabled}
                          maxLength={1000}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>  
          <Card>
            <CardBody>
              <Row>
                <Col lg={3} md={3} sm={12} xs={12} className="pl-2 text-center">
                  <Avatar
                    label="1" 
                    className="ex-large secondary" 
                    size="xlarge" 
                    shape="circle"
                  />
                </Col>
                <Col lg={9} md={9} sm={12} xs={12} className="border-left">
                  <Row className="pt-3">
                    <Col>
                      <span className="bold"><h2> {t('hr:TitleStep2')} </h2> </span> 
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col> 
                      <span className="bold">{t('hr:TitleGoalSmart')}</span> 
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col>
                      <div className="p-fluid"> 
                        <FieldTextArea 
                          name="ObjetivoSmart"
                          rows={2}
                          placeholder={EsDisabled ? '' :t('hr:YourAnswer')}
                          value={ObjetivoSmart} 
                          onChange={onChangeObjetivoSmart}
                          errors={errors}
                          disabled={EsDisabled}
                          maxLength={1000}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>  
          <Card>
            <CardBody>
              <Row>
                <Col lg={3} md={3} sm={12} xs={12} className="pl-2 text-center">
                  <Avatar
                    label="2" 
                    className="ex-large secondary" 
                    size="xlarge" 
                    shape="circle"
                  />
                </Col>
                <Col lg={9} md={9} sm={12} xs={12} className="border-left">
                  <Row className="pt-3">
                    <Col>
                      <span className="bold"><h2> {t('hr:TitleStep3')} </h2> </span> 
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col> 
                      <span className="bold">{t('hr:TitleCompetency')}</span> 
                    </Col>
                  </Row>
                  <Row className="pt-5">
                    <Col>
                      <div className="p-fluid"> 
                        <FieldDropdown  
                          name="ClaCompetencia"
                          value={ClaCompetencia} 
                          options={OpcionesIDPCompetencia}  
                          optionValue="ClaCompetencia"
                          optionLabel="NomCompetencia" 
                          onChange={(e) => onCompetenciaIDPChange(e.value)}
                          label={t('hr:Competency')}
                          errors={errors}
                          disabled={EsDisabled}
                        />   
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>  
          <Card>
            <CardBody>
              <Row>
                <Col lg={3} md={3} sm={12} xs={12} className="pl-2 text-center">
                  <Avatar
                    label="3" 
                    className="ex-large secondary" 
                    size="xlarge" 
                    shape="circle"
                  />
                </Col>
                <Col lg={9} md={9} sm={12} xs={12} className="border-left">
                  <Row className="pt-3">
                    <Col>
                      <span className="bold"><h2> {t('hr:TitleStep4')} </h2> </span> 
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col lg={6} md={6} sm={12} xs={12}> 
                      <FieldText   
                        name="TextFilter"
                        value={TextFilter} 
                        onChange={(e) => onChangeTextFilter(e.target.value)}
                        errors={errors}
                        disabled={EsDisabled}
                        maxLength={250}
                        isInputGroup
                        classGroup="p-inputgroup-addon info Cursor"
                        iconGroup="pi pi-plus"
                        onClick={(e) => addAction()}
                      />
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col> 
                      <span className="bold">{t('hr:TitleAction')}</span> 
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col>
                      <FieldDataTable 
                        value={showActiveRows(IDPAcciones)}
                        reference={dt} 
                        selectionMode="single"  
                        metaKeySelection={false}
                        editMode="cell" 
                        className="editable-cells-table"
                      >
                        <Column field="NomAccion" header={t('hr:NomAccionTaken')} headerStyle={{ width: '85%' }} editorValidator={emptyValueValidator} editor={(props) => NomAccionEditor(props)}></Column>
                        <Column body={headerActions} headerStyle={{ width: '70px' }}></Column>
                      </FieldDataTable>  
                    </Col>
                  </Row>
                  {
                      ClaIDPEstatus === 1 || ClaIDPEstatus === 4 || ClaIDPEstatus === 0
                      ?
                        (
                          <>
                            <Row className="pt-2">
                              <Col> 
                                <span className="bold">{t('hr:AddYourSupportDucuments')}</span> 
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FieldUpload 
                                  name="SupportDucumentCourse"
                                  files={supportDucumentCourseDatafiles} 
                                  onChange={(files) => setSupportDucumentCourseDatafiles(files)}
                                  errors={errors}
                                >
                                </FieldUpload>
                              </Col>
                            </Row>
                          </>
                        )
                      : 
                          (
                            <> 
                              {
                              supportDucumentCourseDatafiles.length > 0 
                              ?
                              (
                                <>
                                  <Row className="pt-3">
                                    <Col> 
                                      
                                      <span className="link-text info-color" onClick={()=> onDownloadDocument(supportDucumentCourseDatafiles[0])}>
                                        {t('hr:DownloadSupportDocuments')}&nbsp;<i className='pi pi-download'></i>
                                      </span>
                                    </Col>
                                  </Row> 
                                </>
                              ) 
                              :
                              (<></>)
                              }
                            </>
                          )  
                } 
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>  
          <Card>
            <CardBody>
              <Row>
                <Col lg={3} md={3} sm={12} xs={12} className="pl-2 text-center">
                  <Avatar
                    label="4" 
                    className="ex-large secondary" 
                    size="xlarge" 
                    shape="circle"
                  />
                </Col>
                <Col lg={9} md={9} sm={12} xs={12} className="border-left">
                  <Row className="pt-3">
                    <Col>
                      <div className="p-fluid">
                        <h2>
                          <span><b> {t('hr:AssignTarget')} </b> </span> &nbsp;
                          {
                          /*
                          
                              <span> {t('hr:TitleStep5StartDate')}</span> &nbsp;
                              <span><b> {t('hr:CompletionDate')} </b> </span> &nbsp;
                              <span>{t('hr:TitleStep5EstimateFinishDate')}</span>

                          */
                        }
                        </h2>
                      </div> 
                      <span className="bold">{t('hr:DescriptionTargets')}</span>  
                    </Col>
                    <Col lg={2} md={2} sm={2} xs={2} className="text-right">
                      <FieldButton
                        hidden={(ClaIDPEstatus!==3 && ClaIDPEstatus!==7) || !IDPClase.EsUsuarioIDP}
                        label={t('common:Update')}
                        className="p-button-raised p-button-success"
                        onClick={() => {saveStatus(6)}}
                      />
                    </Col>
                  </Row>  
                  <Row className="align-items-start pt-3">
                    {
                      IDPAcciones.map((item,index) => { 
                            return ( 
                              <Col lg={4} md={6} sm={12} xs={12}>
                                {
                                item.BajaLogica===0 ? (
                                  <Row>
                                    <Col>
                                      <Card className="radiuscard boxshadow padding10"> 
                                        <CardHeader className="light-green text-center">
                                          {item.NomAccion}
                                        </CardHeader>
                                        <CardBody>  
                                          <Row className='align-items-start'>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                              <Row className="pt-4">
                                                <Col className="primary-color p-fluid">
                                                  <FieldCalendar
                                                    name="FechaInicioEstimada"
                                                    label={t('formulario:StartDate')}   
                                                    value={(item.FechaInicioEstimada!== null ? (new Date(item.FechaInicioEstimada)): null)}
                                                    onChange={(e) => { onChangeStartDate(e, index)}}  
                                                    errors={errors[`IDPAcciones[${index}]`]}
                                                    disabled={EsDisabled}
                                                  />   
                                                </Col> 
                                              </Row>
                                              <Row className="pt-4">
                                                <Col className="primary-color p-fluid">
                                                  <FieldCalendar
                                                    name="FechaFinalEstimada"
                                                    label={t('hr:TargetDate')} 
                                                    value={(item.FechaFinalEstimada!== null ? (new Date(item.FechaFinalEstimada)): null)}
                                                    onChange={(e) => { onChangeEndDate(e, index)}}  
                                                    errors={errors[`IDPAcciones[${index}]`]}
                                                    disabled={EsDisabled}
                                                  />   
                                                </Col> 
                                              </Row>
                                              <Row className='pt-2' hidden={ClaIDPEstatus!==3&&ClaIDPEstatus!==6&&ClaIDPEstatus!==7}>
                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                  <span>{t('hr:WouldYouLikeToComplete')}</span>
                                                </Col>
                                                <Col lg={6} md={6} sm={6} xs={6}>
                                                  <FieldRadioButton
                                                    inputId="EsAccionCompletada1"
                                                    name="EsAccionCompletada" 
                                                    value={true}
                                                    label="Yes"
                                                    onChange={(e) => onChangeEsAccionCompletada(e, index)} 
                                                    checked={item.EsAccionCompletada&&item.EsAccionCompletada!=null}
                                                    errors={errors[`IDPAcciones[${index}]`]}
                                                    disabled={ClaIDPEstatus===6 || !IDPClase.EsUsuarioIDP}
                                                  >
                                                  </FieldRadioButton>
                                                </Col>
                                                <Col lg={6} md={6} sm={6} xs={6}>
                                                  <FieldRadioButton
                                                    inputId="EsAccionCompletada2"
                                                    name="EsAccionCompletada" 
                                                    value={false}
                                                    label="No"
                                                    onChange={(e) => onChangeEsAccionCompletada(e, index)} 
                                                    checked={!item.EsAccionCompletada&&item.EsAccionCompletada!=null}
                                                    errors={errors[`IDPAcciones[${index}]`]}
                                                    disabled={ClaIDPEstatus===6 || !IDPClase.EsUsuarioIDP}
                                                  >
                                                  </FieldRadioButton>
                                                </Col>
                                              </Row> 
                                              <Row hidden={ClaIDPEstatus!==3&&ClaIDPEstatus!==6&&ClaIDPEstatus!==7} className="pt-4 pb-1">
                                                {
                                                  item.EsAccionCompletada ? (
                                                    <>
                                                      <Col className="primary-color p-fluid">
                                                        <FieldCalendar
                                                          name="FechaFinalReal"
                                                          label={t('hr:CompletedDate')}
                                                          value={(item.FechaFinalReal!== null ? (new Date(item.FechaFinalReal)): null)}  
                                                          onChange={(e) => { onChangeFechaFinalReal(e, index)}}  
                                                          errors={errors[`IDPAcciones[${index}]`]}
                                                          disabled={ClaIDPEstatus===6 || !IDPClase.EsUsuarioIDP}
                                                        />   
                                                      </Col> 
                                                    </>
                                                  ):(
                                                    <>
                                                      <Col className="p-fluid">
                                                        &nbsp;
                                                      </Col>
                                                    </>
                                                  )
                                                }
                                              </Row>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                              <Row hidden={ClaIDPEstatus!==3&&ClaIDPEstatus!==6&&ClaIDPEstatus!==7} className="pt-4">
                                                {
                                                    item.EsAccionCompletada ? (
                                                      <>
                                                        <Col className="p-fluid">
                                                          <span>{t('hr:FollowUpComments')}</span>
                                                          <FieldTextArea 
                                                            name="ComentariosSeguimiento"
                                                            rows={7}
                                                            value={item.ComentariosSeguimiento}  
                                                            onChange={(e) => onChangeComentariosSeguimiento(e,index)}  
                                                            errors={errors}
                                                            disabled={ClaIDPEstatus===6 || !IDPClase.EsUsuarioIDP}
                                                            maxLength={5000}
                                                          />
                                                        </Col> 
                                                      </>
                                                    ):(
                                                      <>
                                                        <Col className="p-fluid">
                                                          <span>{t('hr:FollowUpComments')}</span>
                                                          <FieldTextArea 
                                                            placeholder={t('hr:AccionNoCompletadaMensaje')}
                                                            name="ComentariosSeguimiento"
                                                            rows={7}
                                                            value={item.ComentariosSeguimiento}  
                                                            onChange={(e) => onChangeComentariosSeguimiento(e,index)}  
                                                            errors={errors[`IDPAcciones[${index}]`]}
                                                            disabled={ClaIDPEstatus===6 || !IDPClase.EsUsuarioIDP}
                                                            maxLength={5000}
                                                          />
                                                        </Col> 
                                                      </>
                                                    )
                                                }
                                              </Row> 
                                            </Col>
                                          </Row>
                                        </CardBody> 
                                      </Card>
                                    </Col>
                                  </Row>
                                ):(
                                  <>&nbsp; </>
                                )
                              } 
                              </Col> 
                            );
                        })
                      }  
                  </Row>
                  {
                      ClaIDPEstatus === 3 || ClaIDPEstatus === 7 
                      ?
                        (
                          <>
                            <Row className="pt-2">
                              <Col> 
                                <span className="bold">{t('hr:AddYourSupportDucuments')}</span> 
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FieldUpload 
                                  name="SupportDucumentCertificate"
                                  files={supportDucumentCertificateDatafiles} 
                                  onChange={(files) => setSupportDucumentCertificateDatafiles(files)}
                                  errors={errors}
                                >
                                </FieldUpload>
                              </Col>
                            </Row>
                          </>
                        )
                      : 
                          (
                            <> 
                              {
                              supportDucumentCertificateDatafiles.length > 0 && ClaIDPEstatus === 6 
                              ?
                              (
                                <>
                                  <Row className="pt-3">
                                    <Col> 
                                      
                                      <span className="link-text info-color" onClick={()=> onDownloadDocument(supportDucumentCertificateDatafiles[0])}>
                                        {t('hr:DownloadSupportDocuments')}&nbsp;<i className='pi pi-download'></i>
                                      </span>
                                    </Col>
                                  </Row> 
                                </>
                              ) 
                              :
                              (<></>)
                              }
                            </>
                          )  
                } 
                  <Row className="pt-2">
                    <Col>
                      &nbsp;
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <Card>
            <CardBody>
              <Row>
                <Col lg={3} md={3} sm={12} xs={12} className="pl-2 text-center">
                  <Avatar
                    label="5" 
                    className="ex-large secondary" 
                    size="xlarge" 
                    shape="circle"
                  />
                </Col>
                <Col lg={9} md={9} sm={12} xs={12} className="border-left">
                  <Row className="align-items-start">
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <Row className="pt-3">
                        <Col>
                          <span className="bold"><h2> {t('hr:TitleStep6')} </h2> </span> 
                        </Col>
                      </Row>
                      <Row className="pt-3">
                        <Col> 
                          <span className="bold">
                            {t('hr:TitleDiscussManager')}
                          </span> 
                        </Col>
                      </Row>
                      <Row className="pt-2">
                        <Col>
                          <div className="p-field">
                            <FieldSelectButton 
                              name="EsDiscutirGerente"
                              value={EsDiscutirGerente} 
                              options={OpcionesYesNo} 
                              onChange={(e) => onSelectEsDiscutirGerente(e.value)} 
                              optionValue="value"
                              optionLabel="name"  
                              errors={errors}
                              disabled={EsDisabled}
                            /> 
                          </div> 
                        </Col>
                      </Row>
                      <Row className="pt-2">
                        <Col>
                          <div className="p-fluid"> 
                            {
                          EsDiscutirGerente?(
                            <>  
                              { EsDisabled ? (<></>) :
                              (
                                <FieldButton
                                  label={t('hr:SendReview')}
                                  className="p-button-raised p-button-success"
                                  onClick={() => {save()}}
                                  hidden={ClaIDPEstatus!==1 && ClaIDPEstatus!==4 && ClaIDPEstatus !== 0}
                                />
                              ) }
                              <div hidden={ClaIDPEstatus<2} className="p-fluid">
                                <span>{t('hr:SendRevieLegend')}</span>&nbsp;
                                <span><b>{moment(IDPClase.FechaEnviadoGerente).format("LL")}</b> </span>&nbsp;
                                <span>{t('common:At')}</span>&nbsp;
                                <span><b>{IDPClase.HoraEnviadoGerente}</b> </span> &nbsp;
                                <span>{t('common:By')}</span>&nbsp;
                                <span><b>{IDPClase.NombreCompleto}</b> </span>&nbsp;
                              </div> 
                            </>
                          ):(
                            <>
                              <FieldButton
                                hidden={EsDisabled}
                                label={t('hr:SaveDraft')}
                                className="p-button-raised p-button-success"
                                onClick={() => {save()}}
                              />
                              <div hidden={EsDisabled} className="p-col-12">
                                <small>{t('hr:SaveDraftLegend')}</small>
                              </div> 
                            </>
                          )
                        }
                        
                          </div> 
                        </Col>
                      </Row>
                    </Col> 
                  </Row> 
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {(EsJefeInmediato && ClaIDPEstatus===2) || ClaIDPEstatus===3||ClaIDPEstatus===4 || ClaIDPEstatus===6 || ClaIDPEstatus===5 || ClaIDPEstatus===7  ?
      (
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>  
            <Card>
              <CardBody>
                <Row>
                  <Col lg={3} md={3} sm={12} xs={12} className="pl-2 text-center">
                    <Avatar
                      label="6" 
                      className="ex-large secondary" 
                      size="xlarge" 
                      shape="circle"
                    />
                  </Col>
                  <Col lg={9} md={9} sm={12} xs={12} className="border-left">
                    <Row className="pt-3 align-items-start">
                      <Col lg={6} md={6} sm={12} xs={12}>
                        {(EsJefeInmediato && ClaIDPEstatus===2) || ClaIDPEstatus===3||ClaIDPEstatus===4 || ClaIDPEstatus===6 || ClaIDPEstatus===5 || ClaIDPEstatus===7  ? (
                          <>
                            <Row className="pt-3 align-items-start">
                              <Col>
                                <span className="bold"><h2> {t('hr:TitleStep6Reviewed')} </h2> </span> 
                                <div className="bold">
                                  {t('hr:TitleStep6ReviewedQuestion')}
                                </div> 
                              </Col>
                            </Row>
                            <Row className="pt-3 pb-3">
                              <Col>
                                <FieldSelectButton 
                                  name="EsPlanRevisado"
                                  value={EsPlanRevisado} 
                                  options={OpcionesYesNo} 
                                  onChange={(e) => onSelectEsPlanRevisado(e.value)} 
                                  optionValue="value"
                                  optionLabel="name"  
                                  errors={errors} 
                                  disabled={ClaIDPEstatus===3||ClaIDPEstatus===4||ClaIDPEstatus===5||ClaIDPEstatus===6||ClaIDPEstatus===7}
                                /> 
                              </Col>
                            </Row>
                            {EsPlanRevisado ? (
                              <> 
                                <Row className="pt-2">
                                  <Col className="p-fluid" lg={12} md={12} sm={12} xs={12}>
                                    {
                                    ClaIDPEstatus===3||ClaIDPEstatus===4||ClaIDPEstatus===5||ClaIDPEstatus===6||ClaIDPEstatus===7 ?
                                    (
                                      <>
                                        <div className='card info'> 
                                          <div className='card-body'>
                                            <div className='pb-3'>
                                              <b>{t('hr:ManagerFeedback')}</b>
                                            </div>
                                            <span className="text-left">{ComentariosRevisado}</span> 
                                          </div>
                                        </div>
                                        
                                      </>
                                    )
                                    :
                                    (
                                      <>
                                        <FieldTextArea 
                                          name="ComentariosRevisado"
                                          rows={4}
                                          placeholder={t('hr:YourComments')}
                                          value={ComentariosRevisado} 
                                          onChange={onChangeComentariosRevisado}
                                          errors={errors}
                                          maxLength={1000}
                                          disabled={ClaIDPEstatus===3||ClaIDPEstatus===4||ClaIDPEstatus===6||ClaIDPEstatus===7}
                                        />
                                      </> 
                                    )
                                  }
                                  
                                  </Col>
                                </Row>
                                <Row hidden={ClaIDPEstatus===3||ClaIDPEstatus===4||ClaIDPEstatus===5||ClaIDPEstatus===6||ClaIDPEstatus===7} className="pt-3 pb-3">
                                  <Col lg={6} md={6} sm={12} xs={12} className="p-fluid">
                                    <FieldButton
                                      label={t('hr:Accept')}
                                      className="p-button-raised p-button-success"
                                      onClick={() => {saveStatus(3)}}
                                    />  
                                  </Col>
                                  <Col lg={6} md={6} sm={12} xs={12} className="p-fluid">
                                    <FieldButton
                                      label={t('common:AdditionalInputNeeded')}
                                      className="p-button-raised orange"
                                      onClick={() => {saveStatus(4)}}
                                    />
                                  </Col>
                                </Row> 
                                <Row>
                                  <Col>
                                    <div hidden={IDPClase.ClaIDPEstatus<3} className="p-fluid">
                                      <span>{t('hr:SendReviewedLegend')}</span>&nbsp;
                                      <span><b>{moment(IDPClase.FechaRevisado).format("LL")}</b> </span>&nbsp;
                                      <span>{t('common:At')}</span>&nbsp;
                                      <span><b>{IDPClase.HoraRevisado}</b> </span>&nbsp;
                                      <span>{t('common:By')}</span>&nbsp;
                                      <span><b>{IDPClase.UsuarioReviso}</b> </span>&nbsp;
                                    </div> 
                                  </Col>
                                </Row>
                              </>
                            ):(
                              <>
                                <FieldButton
                                  label={t('common:Return')}
                                  icon="pi pi-arrow-left" 
                                  className="p-button-secondary"
                                  onClick={regresarListado}
                                />
                              </>
                            )} 
                          </>
                        ):(
                          <></>
                        )}
                      </Col>
                      <Col lg={6} md={6} sm={12} xs={12}>
                        {
                            MotivoDeshacer!= null ? (
                              <>
                                <Row className="pt-3 align-items-start">
                                  <Col>
                                    <span className="bold"><h2> {t('hr:CommentsUndoAccepted')} </h2> </span> 
                                    <div>
                                      {MotivoDeshacer}
                                    </div> 
                                  </Col>
                                </Row>
                              </>
                            ):(
                              <>
                              </>
                            )
                          } 
                      </Col>
                    </Row>    
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )
      :
      (<></>)}
      <Row className="RowBody">
        <Col lg={8} md={8} sm={12} xs={12} className="pl-2"> 
          <div hidden={IdIDP===0}>
            <span>{t('hr:PlanCreatedby')}</span>&nbsp;
            <span><b>{IDPClase.NombreCompleto}</b> </span>
            <span>{t('hr:On')}</span>&nbsp;
            <span><b>{moment(IDPClase.FechaCreacion).format("LL")} </b> </span>&nbsp;
            <span><b>{IDPClase.HoraCreacion} </b> </span>
          </div>
        </Col>
        <Col lg={4} md={4} sm={12} xs={12} className="pl-2 text-right"> 
          <div hidden={IdIDP===0}>
            <span>{t('hr:LastUpdate')}</span>&nbsp;
            <span><b> {moment(IDPClase.FechaModificacion).format("LL")}</b></span>
              &nbsp;
            <span><b>{IDPClase.HoraModificacion} </b> </span>
          </div>
        </Col>
      </Row> 
    </>
  );

  return (
    <>
      {formTemplate}
    </>
  )
};

export default IndividualDevelopmentPlanFormV1;
import React, { useState, useRef, useEffect  } from 'react'; 
import { useTranslation } from 'react-i18next';   
import { config } from '../../../utils/config';
import { callApi, showSweetAlert} from '../../../utils/utils';   
import PageContent from 'layouts/PageContent';   
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { Row,Col,Card, CardHeader, CardTitle, CardBody, CardFooter} from 'reactstrap';   
import FieldDataView from 'components/Controls/FieldDataView/FieldDataView.jsx';
import FieldDropdown from 'components/Controls/FieldDropdown.jsx' 
import FieldButton from 'components/Controls/FieldButton/FieldButton.jsx';
import FieldTextArea  from 'components/Controls/FieldTextArea.jsx'
import FieldCalendar from 'components/Controls/FieldCalendar.jsx'
import './ProductionDailyFollowUPScreen.scss';  
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import InstructionsCard from 'components/Cards/InstructionsCard/InstructionsCard';
import { setDate } from 'date-fns';

const ProductionDailyFollowUPScreen = (props) => { 
  const { t } = useTranslation(['wtw', 'common']);
  const today = new Date();
  const [errors, setErrors] = useState({});   
  const [OpcionesZonas, setOpcionesZona] = useState([]);
  const [OpcionesTurnos, setOpcionesTurnos] = useState([]);
  const [ProduccionSeguimientoDiarioList, setProduccionSeguimientoDiarioList] = useState([]);
  const [ProduccionSeguimientoDiarioCompleteCellList, setProduccionSeguimientoDiarioCompleteCellList] = useState([]);
  const [ClaZona, setClaZona] = useState(null);
  const [ClaTurno, setClaTurno] = useState(null);
  // si es antes de las 11 de la maniana ponemos el dia de ayer...
  const [Fecha, setFecha] = useState(new Date().getHours() < 11 ?  new Date(new Date(today).setDate(today.getDate() - 1)) : today); 
  const [EsApply, setEsApply] = useState(false); 
  const [PlantasList, setPlantasList] = useState([]);
  const [ClaUbicacion, setClaUbicacion] = useState(null);
  const [NomUbicacion, setNomUbicacion] = useState(null);

  useEffect(() => {
    getPlantas();
    // getZonas();
    // getTurnos();
  }, [])

  const getZonas = (ClaUbicacion) => {  
    const urlWebService = `${config.UrlApiProject}celda/GetZonas?EsZonaDashboard=1&EsAgregarTodos=0&ClaUbicacion=${ClaUbicacion===null?'':ClaUbicacion}`; 
    const paramsService= {};  
    callApi(urlWebService, 'GET', paramsService, (response) => {
      setOpcionesZona(response.data.Zonas);
    }, null, false, true);
  }

  const getTurnos = (ClaUbicacion) => {  
    const urlWebService = `${config.UrlApiProject}turno/getTurnos?ClaUbicacion=${ClaUbicacion===null?'':ClaUbicacion}`; 
    const paramsService= {};  
    callApi(urlWebService, 'GET', paramsService, (response) => {
      setOpcionesTurnos(response.data.Turnos); 
    }, null, false, false);
  }

    const onZoneChange = (e) => {  
      const newErrors = { ...errors } 
      delete newErrors.ClaZona;   
      setErrors(newErrors)  
      setClaZona(e.value); 
      setEsApply(false); 
      setProduccionSeguimientoDiarioList([]);
  };

  const onTurnoChange = (e) => {  
    const newErrors = { ...errors } 
    delete newErrors.ClaTurno;   
    setErrors(newErrors)  
    setClaTurno(e.value);  
    setEsApply(false);
    setProduccionSeguimientoDiarioList([]);
  };

  const onChangeFecha = (e) => {  
    const newErrors = { ...errors }
    delete newErrors.Fecha;   
    setErrors(newErrors);
    setFecha(e.target.value);
    setEsApply(false);
    setProduccionSeguimientoDiarioList([]);
  } 

  const valitationSchemeApply = yup.object().shape({
    ClaUbicacion: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaZona: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaTurno: yup.mixed().required(t('common:RequiredField')).nullable(),
    Fecha: yup.date().required(t('common:RequiredField')).nullable()
  }); 

  const onSearch = async () => {  
        const value = {
          ClaUbicacion,
          ClaZona,
          ClaTurno,
          Fecha
        } 
        const validator = new YupValidator(valitationSchemeApply);
        const esValido = await validator.validate(value);
        
        if (!esValido){ 
          showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
          setErrors(validator.errors); 
          return;
        }
      const urlWebService = `${config.UrlApiProject}WTW/GetProduccionSeguimientoDiario`; 
      const paramsService = {
        ClaZona,
        ClaTurno,
        Fecha,
        ClaUbicacion
      }; 
      callApi(urlWebService, 'POST', paramsService, (response) => {  
        setProduccionSeguimientoDiarioList(response.data.ProduccionSeguimientoDiarioList);
        setProduccionSeguimientoDiarioCompleteCellList(response.data.ProduccionSeguimientoDiarioCompleteCellList);
        setEsApply(true);
        setErrors({});
      }, () => {
    });
};

const valitationSchemeSave = yup.object().shape({
  ProduccionSeguimientoDiarioList: yup.array().of(
    yup.object().shape({
      ExplicacionProblema: yup.string().trim().required(t('common:RequiredField')).nullable()
    })
  )
}); 
 

const getUniqueCellsObjects = (array) => {
  const seen = new Map();
  return array.filter((item) => {
    const key = JSON.stringify({
      ClaMaquina: item.ClaMaquina,
      ClaTurno: item.ClaTurno,
      Fecha: item.Fecha,
      ClaUbicacion: item.ClaUbicacion 
    });
    return seen.has(key) ? false : seen.set(key, true);
  });
};

const getProduccionParo = () => {
  const ProduccionParoList = [];
  getUniqueCellsObjects(ProduccionSeguimientoDiarioList).forEach((item) => {
    let data = {}
     
    data = {}
    data.ClaTurno = ClaTurno;
    data.Fecha = Fecha;
    data.ClaMaquina = item.ClaMaquina;
    data.ClaUbicacion = item.ClaUbicacion; 
    data.IdProduccionSeguimientoDiario = item.IdProduccionSeguimientoDiario;
    data.ExplicacionProblema = item.ExplicacionProblema;
    data.PosibleSolucionProblema = item.PosibleSolucionProblema;
    ProduccionParoList.push(data); 
  });
  return ProduccionParoList
}

  const onSave = async () => {  
    const value = {
      ProduccionSeguimientoDiarioList
    }
    const esValido=true;
    // const validator = new YupValidator(valitationSchemeSave);
    // const esValido = await validator.validate(value);
    if (!esValido){ 
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
      return;
    }

    const urlWebService = `${config.UrlApiProject}WTW/SaveProduccionSeguimientoDiario`; 
    const paramsService = {
      ProduccionSeguimientoDiario:getProduccionParo()
    }; 
    callApi(urlWebService, 'POST', paramsService, (response) => {
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      onSearch();
    }, () => {
  });
};

const onChangeExplicacionProblema = (e, index,indexData) => {   
  const copyProduccionSeguimientoDiarioList = [...ProduccionSeguimientoDiarioList];
  copyProduccionSeguimientoDiarioList[index].ExplicacionProblema = e.target.value;
  if (e.target.value !== undefined){ 
    copyProduccionSeguimientoDiarioList[index].errors = null;
    const newErrors = { ...errors }   
    
    if (typeof(newErrors[`ProduccionSeguimientoDiarioList[${index}]`]) !== 'undefined')
    {
      delete newErrors[`ProduccionSeguimientoDiarioList[${index}]`] 
    }
     
    setErrors(newErrors) ;  
  }
  setProduccionSeguimientoDiarioList(copyProduccionSeguimientoDiarioList); 
}
 

const onChangePosibleSolucionProblema = (e, index,indexData) => {   
  const copyProduccionSeguimientoDiarioList = [...ProduccionSeguimientoDiarioList];
  copyProduccionSeguimientoDiarioList[index].PosibleSolucionProblema = e.target.value;
  setProduccionSeguimientoDiarioList(copyProduccionSeguimientoDiarioList); 
}

 const filterHeaderTemplate = (options) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down text-white' : 'pi pi-chevron-up text-white';
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className="card-header">
        <div className="p-toolbar p-component">
          <div className="p-toolbar-group-left">
            <div className="card-title">
              <FieldButton className={options.togglerClassName} onClick={options.onTogglerClick}>
                <span className={toggleIcon}></span>
                <Ripple />
              </FieldButton>
              <span className={titleClassName}>
                {t('common:Filters')}
              </span>
            </div>  
          </div>
          <div className="p-toolbar-group-right">
            <FieldButton
              className="p-button p-button-icon-only p-highlight" 
              onClick={() => onSearch()}
            >
              <i className="pi pi-search"></i>
            </FieldButton>
          </div>
        </div> 
      </div>
    )
 }

 const onChangeClaUbicacion = (e) => {  
  const newErrors = { ...errors } 
  delete newErrors.ClaUbicacion;
  setErrors(newErrors)  
  setClaUbicacion(e.value);
  setEsApply(false);
 
  if (e.value === null || typeof(e.value) === 'undefined'){
    setNomUbicacion('');  
    setProduccionSeguimientoDiarioList([]);
    setClaZona(null);
    setClaTurno(null); 
    setOpcionesZona([])
    setOpcionesTurnos([]);
    return;
  }

  const UbicacionList = [...PlantasList];
  const UbicacionName = UbicacionList.filter((item) => item.ClaUbicacion === e.value)[0];
  if(UbicacionName!==undefined){
    setNomUbicacion(UbicacionName.NombreUbicacion);  
  }  

  setProduccionSeguimientoDiarioList([]);
  setClaZona(null);
  setClaTurno(null);
  getZonas(e.value);
  getTurnos(e.value);
};

const getPlantas = () => {  
  const urlWebService = `${config.UrlApiProject}WTW/GetPlantasProduccionSeguimientoCmb`; 
  const paramsService= {};  
  callApi(urlWebService, 'GET', paramsService, (response) => {
    setPlantasList(response.data.PlantasList);
  }, null, false, true);
}
 
  
  const filters = (
    <> 
      <Row>
        <Col lg={12} md={12} sm={12} xs={12} className="pb-2">
          <InstructionsCard className="instrucciones info">
            <p>{t('wtw:infoDailyProduction')}.</p>
          </InstructionsCard>
        </Col>
      </Row>
      <Row className="pt-5 pb-4 align-items-start">
        <Col>
          <div className="p-fluid">
            <FieldDropdown  
              name="ClaUbicacion"
              value={ClaUbicacion} 
              options={PlantasList}  
              optionValue="ClaUbicacion"
              optionLabel="NombreUbicacion" 
              onChange={(e) => onChangeClaUbicacion(e)}
              label={t('wtw:Plant')}
              errors={errors}
            />   
          </div>
        </Col>
        <Col>
          <div className="p-fluid">
            <FieldCalendar
              name="Fecha"
              label={t('wtw:Fecha')}
              value={Fecha}
              onChange={(e) => onChangeFecha(e)}
              errors={errors}
            />   
          </div>
        </Col>
        <Col>
          <div className="p-fluid">
            <FieldDropdown  
              name="ClaZona"
              value={ClaZona} 
              options={OpcionesZonas}  
              optionValue="ClaZonaCelda"
              optionLabel="NomZonaCelda" 
              onChange={(e) => onZoneChange(e)}
              label={t('wtw:Zone')}
              errors={errors}
            />   
          </div>
        </Col>
        <Col>
          <div className="p-fluid">
            <FieldDropdown  
              name="ClaTurno"
              value={ClaTurno} 
              options={OpcionesTurnos}  
              optionValue="ClaTurno"
              optionLabel="NomTurno" 
              onChange={(e) => onTurnoChange(e)}
              label={t('wtw:Shift')}
              errors={errors}
            />   
          </div>
        </Col>
        <Col>
          <FieldButton
            className="p-button p-highlight" 
            onClick={() => onSearch()}
          >
            <i className="pi pi-search"></i>&nbsp;{t('wtw:Search')}
          </FieldButton>
        </Col>
      </Row>   
    </>
  );

  return (
    <>   
      <PageContent title={t('wtw:ProductionDailyFollowUPTitle')} className="ProductionDailyFollowUPScreen">  
        <Row className='align-items-start pb-3'>
          <Col lg={12} md={12} sm={12} xs={12}>  
            {filters}
          </Col> 
        </Row> 
        {
          ProduccionSeguimientoDiarioList.length>0 ? (
            <>  
              <Row className='pb-4'>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className='text-right'>
                    <FieldButton
                      label={t('wtw:Save')}
                      icon="pi pi-save"
                      className="p-button-success no-border p-mr-2 "
                      onClick={() => onSave()}
                    >
                    </FieldButton>
                  </div>
                </Col>
              </Row>
              {ProduccionSeguimientoDiarioList.map((item, index) => { 
                      return ( 
                        <Card key={index}>
                          <CardHeader className='bg-card-header-gray'>
                            <Row className="align-items-start p-3">
                              <Col>
                                <Row> 
                                  <Col lg={4} md={4} sm={12} xs={12}>
                                    <span className='bold'>{`${t('wtw:Cell')}:`}</span> 
                                    <span> {`${item.NomMaquina}`}</span>
                                  </Col>
                                  <Col lg={8} md={8} sm={12} xs={12} className='text-right'>
                                    <span className='bold'>{`${t('wtw:UnitsByHr')}:`}</span> 
                                    <span>  {`${Highcharts.numberFormat(item.UnitsByHr,1, '.', ',')}`}</span>
                                    &nbsp;
                                    <span className='bold'>{`${t('wtw:Utilizacion')}:`}</span> 
                                    <span>  {`${Highcharts.numberFormat(item.Utilizacion,1, '.', ',')}`} %</span>
                                    &nbsp;
                                    <span className='bold'>{`${t('wtw:Efficiencia')}:`}</span> 
                                    <span>  {`${Highcharts.numberFormat(item.Efficiencia, 1,'.', ',')}`} %</span>
                                    &nbsp;
                                    <span className='bold'>{`${t('wtw:Scrap')}:`}</span> 
                                    <span>  {`${Highcharts.numberFormat(item.Scrap,1,'.', ',')}`} %</span>
                                    &nbsp; 
                                    <span className='bold'>{`${t('wtw:ScheduledHrs')}:`}</span> 
                                    <span>  {`${Highcharts.numberFormat(item.HrsProgramadas,1, '.', ',')}`}</span>
                                    &nbsp;
                                    <span className='bold'>{`${t('wtw:RunningHrs')}:`}</span> 
                                    <span>  {`${Highcharts.numberFormat(item.RunningHrs,1, '.', ',')}`}</span>
                                    &nbsp;
                                    <span className='bold'>{`${t('wtw:DowntimeHours')}:`}</span> 
                                    <span>  {`${Highcharts.numberFormat(item.HorasParo,1, '.', ',')}`}</span>
                                  </Col>
                                </Row> 
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardBody className="" style={{ padding: "0px", paddingTop: "5px"}}>  
                            <Row className='p-2'> 
                              <Col>
                                <span className='info-color'>{`${t('wtw:ReasonsListedInfo')}`}</span>
                              </Col>
                            </Row>
                            <table width="100%"> 
                              <tbody>
                                <tr className='pb-3'>
                                  <td style={{verticalAlign:"top"}}>
                                    <b>{`${t('wtw:DowntimeReason')}`}</b>
                                    <div>
                                      <ul>
                                        {
                                        item.ProduccionParo.map((itemData, indexData) => { 
                                          return (<li key={indexData}> {`${itemData.NomRazon}`}</li>);
                                        })
                                      }
                                      </ul>
                                    </div>
                                  </td>
                                  {/* <td><b>{`${t('wtw:Hours')}`}</b></td> */}
                                  <td width="35%" style={{verticalAlign:"top"}}>
                                    <b>{`${t('wtw:Problem')}`}</b>
                                    <div className="p-fluid">
                                      <FieldTextArea 
                                        name="ExplicacionProblema"
                                        rows={2}
                                        value={item.ExplicacionProblema}  
                                        onChange={(e) => onChangeExplicacionProblema(e,index,null)}  
                                        errors={typeof(errors[`ProduccionSeguimientoDiarioList[${index}]`]) !== 'undefined' ? errors[`ProduccionSeguimientoDiarioList[${index}]`] : {}}
                                        maxLength={3000}
                                        placeholder={t('wtw:ExplicacionProblema')}
                                      />
                                    </div>
                                  </td>
                                  <td width="35%" style={{verticalAlign:"top"}}>
                                    <b>{`${t('wtw:Solution')}`}</b>
                                    <div className="p-fluid">
                                      <FieldTextArea 
                                        name="PosibleSolucionProblema"
                                        rows={2}
                                        value={item.PosibleSolucionProblema}  
                                        onChange={(e) => onChangePosibleSolucionProblema(e,index,null)}  
                                        errors={errors} 
                                        maxLength={3000}
                                        placeholder={t('wtw:PosibleSolucionProblema')}
                                      />
                                    </div>
                                  </td>
                                </tr>  
                              </tbody>
                            </table>
                          </CardBody>
                        </Card>
                    );
                  })}  
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className='text-right'>
                    <FieldButton
                      label={t('wtw:Save')}
                      icon="pi pi-save"
                      className="p-button-success no-border p-mr-2 "
                      onClick={() => onSave()}
                    >
                    </FieldButton>
                  </div>
                </Col>
              </Row>
              <div className="">
                      &nbsp;
              </div> 
            </>
          ):(
            <>
              {
                ProduccionSeguimientoDiarioList.length === 0 || EsApply === false
                ?
                (
                  <Card className='card-produccion' hidden={!EsApply}>
                    <CardBody className="" style={{paddingTop: "5px"}}>
                      <Row className='pt-2'>
                        <Col lg={12} md={12} sm={12} xs={12}>  
                          {t('wtw:MessageNoData')}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                )
                :
                (
                  <Card className='card-produccion-congratulation'>
                    <CardBody className="" style={{paddingTop: "5px"}}>
                      <Row className='pt-2'>
                        <Col lg={12} md={12} sm={12} xs={12}>  
                          <b>Congratulations!</b>
                          <br></br>
                          <br></br>
                          The calculated results for the shift selected indicate that we do not need daily comments today.
                          <br></br>
                          <br></br>
                          <i>Thank you!</i>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                )
              }

              
            </>
          )
        }
      </PageContent> 
    </>
  );
};

export default ProductionDailyFollowUPScreen;

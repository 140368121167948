/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef, useEffect } from 'react';   
import { Sidebar } from 'primereact/sidebar'; 
import FieldButton from '../../components/Controls/FieldButton/FieldButton.jsx';
import { ToggleButton } from 'primereact/togglebutton';
import { FormGroup, Label, Card, CardHeader, CardBody, Row, Col, CardTitle,  CardFooter, InputGroup, InputGroupText } from 'reactstrap'; 
import PocketMarginFilters from './PocketMarginFilters.js'; 
import { useTranslation } from 'react-i18next';

const PocketMarginFilterBarActions =  (props) => {
    const { t, i18n } = useTranslation(['formulario','common']); 
    const [EsMostrarFilterToolbar, setEsMostrarFilterToolbar] = useState(false);

    const onSearch = ( filters ) => {  
       setEsMostrarFilterToolbar(false); 
       props.onSearch(filters);
    }
return (  

  <>   
    <Row>
      <Col lg={12} md={12} sm={12} xs={12}>  
        <div className="card-header bg-card-header-grid">
          <div className="p-toolbar p-component">
            <div className="p-toolbar-group-left">
              <div className="card-title"> 
                <span>
                  {t('common:Filters')}
                </span>
              </div>  
            </div>
             
            <div className="p-toolbar-group-right"> 
              <FieldButton icon="pi pi-filter" className="PM-Step-1" onClick={(e) => setEsMostrarFilterToolbar(true)} />
            </div>
          </div> 
        </div>

      </Col>
    </Row> 
         
    <Sidebar visible={EsMostrarFilterToolbar} dismissable={false} baseZIndex={1000000} onHide={(e) => setEsMostrarFilterToolbar(false)}>
      <PocketMarginFilters onSearch={onSearch} defultFilters={props.defultFilters} />   
    </Sidebar> 
  </> 
)
}   

    
export default PocketMarginFilterBarActions; 

      
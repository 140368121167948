import PageContent from 'layouts/PageContent';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TableauDashboard from 'views/ProductionsDashboards/TableauDashboard/TableauDashboard';

const TableauDashboardScreen = (props) => {
  const {t, i18n} = useTranslation(['operations', 'common']); // Translation

  return (
    <>
      <PageContent title={t('operations:TableauOperationsDashboard')}>
        <TableauDashboard />
      </PageContent>
    </>
  )
}

export default TableauDashboardScreen;
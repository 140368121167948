import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next'; 
import PageContent from 'layouts/PageContent.jsx';
import MCSWNewsletter from './MCSWNewsletter';

const MCSWNewsletterScreen = (props) => {
  const { t } = useTranslation(['MCSWNewsletter', 'common']);

  return (
    <>
      <PageContent title={t('MCSWNewsletter:MCSWNewsletter')}>
        <MCSWNewsletter {...props} />
      </PageContent>
    </>
  )
}

export default MCSWNewsletterScreen;
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Sidebar } from 'primereact/sidebar';
import React, { useState, useEffect, useRef } from 'react'; 
import { config } from '../../../utils/config'; 
import Highcharts from 'highcharts';
import { useTranslation } from 'react-i18next';
// import { useTranslation } from 'react-i18next'; 
 
const PriceTrackerMap = ({
  refreshResize,
  markersList 
}) => { 
  /* ################## useState area start ################## */ 
  // const { t } = useTranslation(['warehouse', 'common']);  
  const { t } = useTranslation(['priceTracker', 'common']);
  const [mapInstance, setMapInstance] = useState(null); 
  const [SelectedMarker,setSelectedMarker] = useState({});
  const HEREApiKey = config.HereKey;
  const mapRef = useRef(null);     
  const {H} = window;
  const platform = new H.service.Platform({
      apikey: HEREApiKey
  });
  const defaultLayers = platform.createDefaultLayers();  
  useEffect(() => {
    // `mapRef.current` will be `undefined` when this hook first runs; edge case that
    if (!mapRef.current) return;  
    // Step 2: initialize a map - this map is centered over Europe
    const map = new H.Map(mapRef.current,
      defaultLayers.vector.normal.map,{ 
      center: {lat:41.196730757596754, lng:-100.77804884180203},
      zoom: 4,
      pixelRatio: window.devicePixelRatio || 1,
      padding: {top: 50, left: 50, bottom: 50, right: 50}
    });
    // add a resize listener to make sure that the map occupies the whole container
    window.addEventListener('resize', () => { 
      console.log('map resize',map);
      console.log('map getViewPort',map.getViewPort());
      map.getViewPort().resize();
    });

    // Step 3: make the map interactive
    // MapEvents enables the event system
    // Behavior implements default interactions for pan/zoom (also on mobile touch environments)
    const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
    // Create the default UI components
    const ui = H.ui.UI.createDefault(map, defaultLayers); 
    const mapsettings = ui.getControl('mapsettings'); 
    mapsettings.setVisibility(false);
    addMarkersToMap(map);
    setMapInstance(map);
    // This will act as a cleanup to run once this hook runs again.
    // This includes when the component un-mounts 
    return () => {  
      map.dispose();
    };
  }, [markersList]);

  useEffect(() => {  
    /* cuando le dan click al menu para ocultarlo hacemos el resize del objeto del mapa... */
    if (mapInstance !== null){  
      setTimeout(()=> {
        mapInstance.getViewPort().resize(); 
      }, 200);
    } 
  }, [refreshResize]); 
 
  function addMarkerToGroup(group, coordinate, html, iconCircle) {
    const marker = new H.map.Marker(coordinate,
      {icon: iconCircle }
    );
    // add custom data to the marker
    marker.setData(html);
    group.addObject(marker);
  }

  const addMarkersToMap = async (map) => { 
    const {H} = window;   
    const ui = H.ui.UI.createDefault(map, defaultLayers); 
    const markersInfoList = [];
    const coordenates = [];
    const markerOrigin1 = null;
    const markerStop = null;
    const group = new H.map.Group();
    map.addObject(group);
    
    for (let index = 0; index < markersList.length; index++) {
      let tooltip = '';
      const element = markersList[index];  
      const latLong = {
        lat: element.Lat,
        lng: element.Lng
      }; 
      if (latLong !== null){
        
        if(element.ClaDivision===-1){
          tooltip =  `${'<table className="tblHistoryTooltip" width="100%">' + 
          '<tr>' +
            '<td style="color:#fff;background-color:'}${ element.ColorHexadecimal }" colspan="2"><b>${ element.NomClienteCuenta}</b></td>` +
          `</tr>` +
           `<tr>` +
            `<td style="border:solid 1px #CDCDCD"><b>Location</b></td>` +
            `<td style="border:solid 1px #CDCDCD;text-align:right;">${ element.Location }</td>` +
          `</tr>` +
        `</table>`
        }else{
          tooltip =  `${'<table className="tblHistoryTooltip" width="100%">' + 
          '<tr>' +
            '<td style="color:#fff;background-color:'}${ element.ColorHexadecimal }" colspan="2"><b>${ element.NomClienteCuenta}</b></td>` +
          `</tr>` +
           `<tr>` +
            `<td style="border:solid 1px #CDCDCD"><b>Location</b></td>` +
            `<td style="border:solid 1px #CDCDCD;text-align:right;">${ element.Location }</td>` +
          `</tr>` +
          `<tr>` +
            `<td style="border:solid 1px #CDCDCD"><b>Price</b></td>` +
            `<td style="border:solid 1px #CDCDCD;text-align:right;">${ Highcharts.numberFormat(element.UnitPrice, 2, '.', ',')}</td>` +
          `</tr>` +
          `<tr>` +
            `<td style="border:solid 1px #CDCDCD"><b>Spread</b></td>` +
            `<td style="border:solid 1px #CDCDCD;text-align:right;">${ Highcharts.numberFormat(element.UnitSpread, 2, '.', ',')}</td>` +
          `</tr>` +
          `<tr>` +
            `<td style="border:solid 1px #CDCDCD"><b>${t('priceTracker:Outbund')}</b></td>` +
            `<td style="border:solid 1px #CDCDCD;text-align:right;">${ Highcharts.numberFormat(element.Outbound, 2, '.', ',')}</td>` +
          `</tr>` +
          `<tr>` +
            `<td style="border:solid 1px #CDCDCD"><b>Tons</b></td>` +
            `<td style="border:solid 1px #CDCDCD;text-align:right;">${ Highcharts.numberFormat(element.Tons, 2, '.', ',')}</td>` +
          `</tr>` +
          `<tr>` +
            `<td style="border:solid 1px #CDCDCD"><b>Units</b></td>` +
            `<td style="border:solid 1px #CDCDCD;text-align:right;">${ element.Qty }</td>` +
          `</tr>` +
          `<tr>` +
            `<td style="border:solid 1px #CDCDCD"><b>Revenue</b></td>` +
            `<td style="border:solid 1px #CDCDCD;text-align:right;">${ Highcharts.numberFormat(element.Revenue, 2, '.', ',')}</td>` +
          `</tr>` +
          `<tr>` +
            `<td style="border:solid 1px #CDCDCD"><b>Agent</b></td>` +
            `<td style="border:solid 1px #CDCDCD;text-align:right;">${ element.Agent }</td>` +
          `</tr>` +
          `<tr>` +
            `<td style="border:solid 1px #CDCDCD"><b>Number of invoices</b></td>` +
            `<td style="border:solid 1px #CDCDCD;text-align:right;">${ element.NumberInvoices }</td>` +
          `</tr>` +
          `<tr>` +
            `<td style="border:solid 1px #CDCDCD"><b>Factory</b></td>` +
            `<td style="border:solid 1px #CDCDCD;text-align:right;">${ element.Factory }</td>` +
          `</tr>` +
          `<tr>` +
            `<td style="border:solid 1px #CDCDCD"><b>Miles</b></td>` +
            `<td style="border:solid 1px #CDCDCD;text-align:right;">${ element.MillasRecorridas }</td>` +
          `</tr>` +
        `</table>`
        }

        const svgCircle = `${'<svg width="18" height="18" ' +
        'xmlns="http://www.w3.org/2000/svg">' +
        '<circle cx="4" cy="4" r="4" ' +
          'fill="'}${ element.ColorHexadecimal  }" stroke="white" stroke-width="1" />` +
        `</svg>`
        
        const iconMarker = new H.map.Icon(svgCircle, {anchor: {x:8, y:8}})

        addMarkerToGroup(group,{lat:latLong.lat, lng:latLong.lng},tooltip,iconMarker);

      }  
    }

   group.addEventListener('tap', (evt) => {
    
    /*
        const {target} = evt;
        const {maxZoom} = target.getData();
        map.getViewModel().setLookAtData({
          zoom: maxZoom,
          bounds: target.getGeometry()
        });
    */
      /*
        ui.getBubbles().forEach(bub => ui.removeBubble(bub));
        if(!evt.target.getData) return;
      */
        
      // event target is the marker itself, group is a parent event target
      // for all objects that it contains
      const bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
        // read custom data
        content: evt.target.getData()
      });
      // show info bubble
      ui.addBubble(bubble);
    }, false);

    

  } 
  
    return (
      <>      
        <div className="pricetracker-map" ref={mapRef}>
        </div>
      </>  
        ); 
};

export default PriceTrackerMap;

/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef } from "react"; 
import PropTypes from "prop-types";  
import "./FieldDataView.css";
import { Dropdown } from 'primereact/dropdown'; 
import { DataView} from 'primereact/dataview';
import { useTranslation } from 'react-i18next'; 

const FieldDataView = ({
    className, 
    value,
    paginatorClassName, 
    itemTemplate,
    layout,
    paginator,
    sortOrder,
    sortField
}) => {
     
  const { t } = useTranslation(['common']);
    
  const [first, setFirst] = useState(0);
  const [rows, setRow] = useState(50);

  const paginatorTemplate = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
        const dropdownOptions = [
            { label: 10, value: 10 },
            { label: 20, value: 20 },
            { label: 50, value: 50 }
        ];

        return (
          <>
            <span className="p-mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>{t('common:ItemsByPage')}: </span>
            <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} appendTo={document.body} />
          </>
        );
    },
    'CurrentPageReport': (options) => {
        return (
          <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
            {options.first} - {options.last} of {options.totalRecords}
          </span>
        )
    }
};
  const onPageChange = (event) => {
    setFirst(event.first);
    setRow(event.rows);
  }

  return (
    <> 
      <DataView
        value={value}
        layout={layout}
        itemTemplate={itemTemplate}
        paginator={paginator}
        paginatorTemplate={paginatorTemplate} 
        onPage={onPageChange}
        paginatorClassName={paginatorClassName}
        first={first} 
        rows={rows} 
        className={typeof(className) === "undefined" || className === "" ? 'dataview-list' : className}
        sortOrder={sortOrder}
        sortField={sortField}
      />
    </>
  );
};

export default  FieldDataView;

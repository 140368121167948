/* eslint-disable no-self-compare */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldTag from 'components/Controls/FieldTag.jsx';
import { Column } from 'primereact/column';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap'; 
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../../utils/utils'; 
import FieldToogleSwitch from 'components/Controls/FieldToogleSwitch/FieldToogleSwitch';
import FieldSelectButton from 'components/Controls/FieldSelectButton';
import moment from 'moment';
import { Divider } from 'primereact/divider';
import PropType from 'prop-types';


const SurveryIndividualRespondView = ({IdEncuesta, IdEncuestaContestada}) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['survey', 'common']);
  const [errors, setErrors] = useState({});
  const [ViewFeedBackResponseList, setViewFeedBackResponseList] = useState([]);  
  const [StartedDaySurvey, setStartedDaySurvey]= useState(null);
  const [EndDaySurvey, setEndDaySurvey]= useState(null);
  const [TiempoInvertido, setTiempoInvertido] = useState(null);

  useEffect(() => { 
    loadViewFeedBackResponse(IdEncuesta, IdEncuestaContestada); 
  }, [IdEncuesta, IdEncuestaContestada]); 

  const loadViewFeedBackResponse = (pIdEncuesta, pIdEncuestaContestada) => {
    const getViewFeedBackResponse = `${config.UrlApiProject}hr/GetViewFeedBackResponse?IdEncuesta=${pIdEncuesta}&IdEncuestaContestada=${pIdEncuestaContestada}`;
    const paramsToService = {};
    callApi(getViewFeedBackResponse, 'GET', paramsToService, (response) => { 
      const ViewFeedbackResponse = response.data.ViewFeedbackResponse.length > 0 ? response.data.ViewFeedbackResponse[0] : {}
      setStartedDaySurvey(ViewFeedbackResponse.FechaIniciaEncuesta);
      setEndDaySurvey(ViewFeedbackResponse.FechaCompletada);
      setTiempoInvertido(ViewFeedbackResponse.TiempoInvertido);

      // setEsSoloLectura(ViewFeedbackResponse.EsRevisadoRH);
      setViewFeedBackResponseList(response.data.ViewFeedbackResponseDet); 
    });
  };

  const showActiveRows = (rows) => {
    const activeRows = [];
    if (rows !== undefined) {
      if (rows.length > 0) {
        rows.forEach((item, index) => {
          if (!item.BajaLogica || item.BajaLogica === 0 || item.BajaLogica === undefined) {
            item.BajaLogica = 0;
            item.RowIndex = index;
            item.RowIndexCount = index + 1;
            activeRows.push({ ...item });
          }
        });
      } else {
        return [];
      }
    }

    return activeRows;
  };


  return (
    <>       
      <Row>
        <Col xs={12} sm={12} ms={12} lg={12}> 
          <Card>
            <CardBody>
              <Row>
                <Col lg={10} md={10} sm={12} xs={12} className="p-mr-2">
                  <div> 
                    <FieldTag 
                      label={t('survey:Completed')}
                      className="success"
                    /> 
                  </div>
                  <br></br>
                  <table>
                    <tbody>
                      <tr>
                        <td><b>{t('survey:DateStarSurvey')}:</b></td>
                        <td>&nbsp;</td>
                        <td> {moment(StartedDaySurvey).format('MMMM DD, YYYY h:mm:ss a')} </td>
                      </tr>
                      <tr>
                        <td><b> {t('survey:DateEndSurvey')} :</b> </td>
                        <td>&nbsp;</td>
                        <td> {moment(EndDaySurvey).format('MMMM DD, YYYY h:mm:ss a') }</td>
                      </tr>
                      <tr>
                        <td><b> {t('survey:TimeSpent')} :</b> </td>
                        <td>&nbsp;</td>
                        <td>{TiempoInvertido}</td>
                      </tr>
                    </tbody>
                  </table> 
                </Col>
              </Row> 
            </CardBody>
          </Card>
          
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
                &nbsp;
            </Col>
          </Row>
          <Card>
            <CardBody>
              {showActiveRows(ViewFeedBackResponseList).map((element, index) => {
              return (
                <div key={index}>
                  {index === 0 ? (
                    <>
                      <Row>
                        <Col lg={10} md={10} sm={12} xs={12} className="p-mr-2">
                          <div>
                            <span className="h2 bold">{element.TituloPagina}</span>
                          </div>
                          <div>
                            <span className="bold">{element.DescripcionPagina}</span>
                          </div>
                          <div className="Pregunta" dangerouslySetInnerHTML={element.DescripcionHtml}>
                          </div>                          
                        </Col>
                        <Col>
                          <div className="text-right">
                            <small className="BOLD">{element.CountPage} | {element.TotalPage}</small>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                          &nbsp;
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <></>
                  )}
                  {index > 0 &&
                  element.IdEncuestaPagina !==
                    (index + 1 === ViewFeedBackResponseList.length
                      ? ViewFeedBackResponseList[index].IdEncuestaPagina
                      : ViewFeedBackResponseList[index - 1].IdEncuestaPagina) ? (
                        <>
                          <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>
                          &nbsp;
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={10} md={10} sm={12} xs={12} className="p-mr-2">
                              <div>
                                <span className="h2 bold">{element.TituloPagina}</span>
                              </div>
                              <div>
                                <span className="bold">{element.DescripcionPagina}</span>
                              </div>
                              <div className="Pregunta" dangerouslySetInnerHTML={element.DescripcionHtml}>
                              </div>
                            </Col>
                            <Col>                              
                              <div className="text-right">
                                <small className="BOLD">{element.CountPage} | {element.TotalPage}</small>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>
                          &nbsp;
                            </Col>
                          </Row>
                        </>
                  ) : (
                    <></>
                  )}
                  
                  
                  <div className="survey-approve-container">
                    <div className="survey-approve-body">
                      <Row>
                        <Col lg={10} md={10} sm={12} xs={12} className="p-mr-2">
                          <div className='Pregunta'>
                            <span className="bold" hidden={true}> {index + 1}.- </span>
                            <label><b>{element.DescripcionPregunta}</b></label>
                          </div>
                          <div className='Respuesta'>
                            {element.RespuestaPregunta} 
                          </div>
                        </Col>
                      </Row>
                      <Divider></Divider>
                    </div>
                  </div> 
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      &nbsp;
                    </Col>
                  </Row>
                </div>
              );
            })}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
            &nbsp;
        </Col>
      </Row>     
    </>
  );
};

SurveryIndividualRespondView.prototype = {
    IdEncuesta: PropType.number.isRequired,
    IdEncuestaContestada: PropType.number.isRequired,
  };

export default SurveryIndividualRespondView;

/* eslint-disable react/no-this-in-sfc */
/* eslint-disable object-shorthand */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  ListGroupItem,
  ListGroup,
  Progress,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from 'reactstrap';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { Badge } from 'primereact/badge';
import { Link } from 'react-router-dom';
import { Toolbar } from 'primereact/toolbar';
import Highcharts from 'highcharts';
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../../utils/utils'; 
import HighchartsReact from 'highcharts-react-official';
import FieldButton from '../../../../components/Controls/FieldButton/FieldButton.jsx';
import PropType from 'prop-types'; 
import FieldCalendar from 'components/Controls/FieldCalendar';
import { Inplace, InplaceContent, InplaceDisplay } from 'primereact/inplace';
import moment from 'moment';

const SurveyInsightQuestion = ({IdEncuesta, IdEncuestaPaginaPregunta}) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'focusReport', 'common']);
  const [PosicionesRelacionadasList, setPosicionesRelacionadasList] = useState([]);
  const [ClaPeriodo, setClaPeriodo] = useState(null);
  const [ClaFechaReporte, setClaFechaReporte] = useState(null);
  const [IdCSF, setIdCSF] = useState(null);
  const [Titulo, setTitulo] = useState('');
  const [Subtitulo, setSubtitulo] = useState(''); 
  const [xAxisCategories, setxAxisCategories] = useState([]);
  const [xAxisName, setxAxisName] = useState('');
  const [Series, setSeries] = useState([]);
  const [fechaInicial, setFechaInicial] = useState(null);
  const [fechaFinal, setFechaFinal] = useState(null);
  const [InplaceActiveCalendar, setInplaceActiveCalendar] = useState(false);

  useEffect(() => { 
    GetSurveyInsightsQuestion(IdEncuesta, IdEncuestaPaginaPregunta, null, null);
 
  }, [IdEncuesta, IdEncuestaPaginaPregunta]);


  const GetSurveyInsightsQuestion = (pIdEncuesta, pIdEncuestaPaginaPregunta, pFechaRango1, pFechaRango2) => {
    const GetSurveyInsightsQuestion = `${config.UrlApiProject}hr/GetSurveyInsightsQuestion`; /* ?IdEncuesta=${pIdEncuesta}&IdEncuestaPaginaPregunta=${pIdEncuestaPaginaPregunta}`; */
    const paramsToService = {
      IdEncuesta: pIdEncuesta,
      IdEncuestaPaginaPregunta: pIdEncuestaPaginaPregunta,
      FechaRango1: pFechaRango1,
      FechaRango2: pFechaRango2,
    };

    callApi(GetSurveyInsightsQuestion, 'POST', paramsToService, (response) => {  
        setTitulo(response.data.ConfiguracionGrafica.Titulo);
        setSubtitulo(response.data.ConfiguracionGrafica.Subtitulo);
        setxAxisName(response.data.ConfiguracionGrafica.xAxisName);
        setxAxisCategories(response.data.xAxisCategories);
        setSeries(response.data.series);   
        setFechaInicial(response.data.Fechas.FechaIni);
        setFechaFinal(response.data.Fechas.FechaFin);      
    });
  };   

  Highcharts.wrap(Highcharts.Series.prototype, 'drawPoints', function(p) {
    const {options} = this;
    const symbolCallback = options.marker && options.marker.symbolCallback;
    const {points} = this;
    if (symbolCallback && points.length) {
      points.forEach(point => {
        const symbol = symbolCallback.call(point);
        if (symbol) {
          point.update({
            marker: {
                fillColor: symbol
            }
          }, false)
        }
      })
    }
    p.call(this);
  })

  const surveyInsightQuestionChart = {
    
      chart: {
        type: 'column'
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: xAxisCategories
      },
      yAxis: {
        min: 0,
        title: {
          text: xAxisName
        }
      },
      tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
        shared: true
      },
      plotOptions: {
        column: {
          stacking: 'percent'
        }
      },
      series: Series   
}

  
  const onFechaInicialChange = ( e ) => {  
    setFechaInicial(e.value);
    if(fechaFinal!==null && e.value!==null){ 
        setInplaceActiveCalendar(false);        
        GetSurveyInsightsQuestion(IdEncuesta, IdEncuestaPaginaPregunta, e.value, fechaFinal);
    }
  }; 
  const onFechaFinalChange = ( e ) => {  
    setFechaFinal(e.value);
    if(fechaInicial!==null && e.value!==null){ 
        setInplaceActiveCalendar(false);     
        GetSurveyInsightsQuestion(IdEncuesta,  IdEncuestaPaginaPregunta, fechaInicial , e.value);   
    }
  }; 
    
  return (
    <>       
      <Card>        
        <CardBody>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
              <span className='survey-chart-name'>{Titulo}</span>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <div className="p-fluid">
                <Inplace
                  closable={true}
                  active={InplaceActiveCalendar}
                  onToggle={(e) => setInplaceActiveCalendar(e.value)}
                  className="p-fluid"
                >
                  <InplaceDisplay>
                    {
                       // <span className="fa fa-pencil-alt"></span>
                      } 
                    {t('survey:InformationFrom')}&nbsp;
                    <span className="pi pi-calendar"></span> &nbsp;
                    {moment(fechaInicial).format("ll")}-{moment(fechaFinal).format("ll")} 
                  </InplaceDisplay>
                  <InplaceContent>
                    <b>{t('scoreCard:InformationFrom')}</b> &nbsp;
                    <FieldCalendar
                      name="FechaInicial" 
                      value={(fechaInicial!== null ? (new Date(fechaInicial)): null)}
                      onChange={(e) => onFechaInicialChange(e)}
                    />
                          &nbsp;
                    <FieldCalendar
                      name="FechaFinal" 
                      value={(fechaFinal!== null ? (new Date(fechaFinal)): null)}
                      onChange={(e) => onFechaFinalChange(e)}
                    />
                  </InplaceContent>
                </Inplace> 
              </div>
            </Col>
            {/* <Col lg={4} md={6} sm={12} xs={12} className="text-right">
              
              <div className="p-fluid">
                <FieldCalendar
                  name="trendReviewRange"
                  value={trendReviewRange}
                  onChange={onTrendRangeChange}
                  selectionMode="range"
                />
              </div>
            </Col> */}
          </Row>
          <br />
          <HighchartsReact containerProps={{ style: { height: "420px" } }} highcharts={Highcharts} options={surveyInsightQuestionChart} /> 
        </CardBody>
        {/* <CardFooter hidden={!props.showCloseButton}>
          <Row>
            <Col className='text-center'>
              <FieldButton
                label={t('common:Close')}
                icon="fas fa-times"
                className="warning no-border p-mr-2 rounded-button"
                onClick={typeof(props.onCloseClick) !== 'undefined' && typeof(props.onCloseClick) === 'function' ? props.onCloseClick : null}
              />
            </Col>
          </Row>
        </CardFooter> */}
      </Card>
    </>
  );
}

SurveyInsightQuestion.prototype = {
    IdEncuesta: PropType.number.isRequired,
    IdEncuestaPaginaPregunta : PropType.number.isRequired
}

export default SurveyInsightQuestion;

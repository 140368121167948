/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next'; 
import PageContent from 'layouts/PageContent.jsx';

const TalentPlanningScreen = (props) => {  
   
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);  
   
 
  return (
    <>   
      <PageContent title={t('hr:TalentPlanning')} className="content-no-margin">  
        <div style={{margin:'0px', marginLeft: '0px', padding: '0px', height: "90vh" }}> 
          <iframe title='Talent Plannnig' width="100%" height="100%" src="https://lookerstudio.google.com/embed/reporting/d459fc1a-f825-434d-8db0-f05cabcf2afd/page/RSWCD" frameBorder={0} style={{border:0}} allowFullScreen></iframe>
        </div>   
      </PageContent> 
    </>
  );
};
export default TalentPlanningScreen;

/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{ useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../../../utils/config';
import { callApi } from '../../../utils/utils';
import { Row, Col } from 'reactstrap';
import './DigitalViewDashboard.scss';
import DigitalViewDashboardMachineCard from './DigitalViewDashboardMachineCard';
import DigitalViewDashboardMachineCardSkeleton from './DigitalViewDashboardMachineCardSkeleton';

const DigitalViewDashboardCurrentShift = (props) => { 
 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);
  const [DGTDashboard, setDGTDashboard] = useState([]);
  const [DGTDetail, setDGTDetail] = useState([]);
  const [DGTDetailModal, setDGTDetailModal] = useState({});
  const [EsDialogDGTDashboard, setEsDialogDGTDashboard] = useState(false);
  const [DGTDashboardDetail, setDGTDashboardDetail] = useState([]);
  const [ClaCelda, setClaCelda] = useState([]);
  const [TodayDateBegin, setTodayDateBegin] = useState(null);
  const [TodayDateEnd, setTodayDateEnd] = useState(null);
  const [IdCelda, setIdCelda] = useState(null);
  const [InformacionEncabezadoCelda, setInformacionEncabezadoCelda] = useState({});
  const [DetalleInformacionMaquinasCelda, setDetalleInformacionMaquinasCelda] = useState([]);
  const [CajasPorHoraMaquina, setCajasPorHoraMaquina] = useState([]);
  const [boxesByHrsChart, setboxesByHrsChart] = useState(null);
  const [LastTipoPlano, setLastTipoPlano] = useState(null);
  const [loading, setLoading] = useState(false);
  const skeletonList = [{}, {}, {}, {}, {}, {}];

  useEffect(() => {
    getDigitalViewDashboard(props.TipoPlano);
    const start = new Date();
    start.setUTCHours(0,0,0,0);

    const end = new Date();
    end.setUTCHours(23,59,59,999);

    setTodayDateBegin(start.getTime())
    setTodayDateEnd(end.getTime())
  }, [props.TipoPlano, props.forceRefresh])

  const getDigitalViewDashboard = (tipoPlano) => { 
    setLoading(true);
    if (props.OnLoadStarts) props.OnLoadStarts();
    if (tipoPlano !== LastTipoPlano) {
      setDGTDashboard([]);
      setDGTDetail([]);
      setLastTipoPlano(tipoPlano);
    }
    console.log('getDigitalViewDashboard',tipoPlano);
    const url = `${config.UrlApiProject}DGT/GetDGTDigitalViewDashboard?TipoPlano=${tipoPlano}`;   
    const paramsService= {};  
    callApi(url, 'GET', paramsService, (response) => {
      const list = response.data.DGTDashboard;
      const detail = response.data.DGTDetail;
      setDGTDashboard(list);
      setDGTDetail(detail);
      if (typeof(props.LastRefresInfo) === 'function'){
        props.LastRefresInfo(detail.LastUpdateDate);
      }
      if (props.OnLoadEnds) props.OnLoadEnds();
      setLoading(false);
    }, (error) => {
      console.error("Error getting digital layout current shift.", error);
      setLoading(false);
      if (props.OnLoadEnds) props.OnLoadEnds();      
    }, false, false);
  }

  return (
    <>  
      <Row className='p-2'>
        {
          loading? (
            <>
              {
                skeletonList.map((item, index) => {                  
                  return (
                    <Col 
                      xs={12} 
                      sm={props.ShowAssetView? 12: 6} 
                      md={props.ShowAssetView? 6: 3} 
                      lg={props.ShowAssetView? 4: 2}
                    >
                      <DigitalViewDashboardMachineCardSkeleton                        
                        ShowAssetView={props.ShowAssetView}
                      />
                    </Col>      
                  );
                })
              }              
            </>
          ) : (
            <>
              {
                DGTDashboard.map((item, index) => {                  
                  return (
                    <Col 
                      xs={12} 
                      sm={props.ShowAssetView? 12: 6} 
                      md={props.ShowAssetView? 6: 3} 
                      lg={props.ShowAssetView? 4: 2}
                    >
                      <DigitalViewDashboardMachineCard
                        TipoPlano={props.TipoPlano}
                        ClaCelda={item.ClaCelda}
                        forceRefresh={false}
                        ShowAssetView={props.ShowAssetView}
                      />
                    </Col>      
                  );
                })
              }                
            </>
          )
        }         
      </Row>
    </>  
  ); 
};

export default DigitalViewDashboardCurrentShift;

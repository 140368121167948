/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next';  
import { Row, Col , 
    ListGroupItem,
    ListGroup,
    Progress,} from 'reactstrap';  
import HomeMenuCard from '../../../components/Cards/HomeMenuCard/HomeMenuCard.jsx';
import MyPerformanceImg from '../../../assets/img/MCSW-team.png';
import ListItem from '../../../components/ListGroup/ListItem.jsx' 
import { useHistory, Link } from "react-router-dom";
import { config } from '../../../utils/config';
import { callApi, getSessionItem } from '../../../utils/utils';  

const MyPerformanceMenu = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);
  const [EsTienePermisosTalenPlannig, setEsTienePermisosTalenPlannig] = useState(false);
  
  useEffect(() => {
    getOptionsMyPerformanceRestricted();
  }, []);

  const getOptionsMyPerformanceRestricted = () => {
    const GetOptionsMyPerformanceRestricted = `${config.UrlApiProject}HR/GetOptionsMyPerformanceRestricted`; 

    callApi(GetOptionsMyPerformanceRestricted, 'GET', {}, (response) => {
      
      setEsTienePermisosTalenPlannig(response.data.EsTienePermisosTalenPlannig);    
    });
  };

  const annualReviewLnk = useRef(null);
  const survey180DegreeLnk = useRef(null);
  const individualDevelopmentPlanLnk = useRef(null); 
  const talentPlanningLnk = useRef(null); 
  
  const onAnnualReviewClick = () => {
    annualReviewLnk.current.click();
  }
  const onsurvey180DegreeClick = () => {
     survey180DegreeLnk.current.click();
  }
 const onIndividualDevelopmentPlanClick = () => {
     individualDevelopmentPlanLnk.current.click();
  }

  const ontalentPlanningClick = () => {
    talentPlanningLnk.current.click();
 }
  
    return (
      <> 
        <HomeMenuCard title={t('hr:MyTotalSuccess')} imageSrc={MyPerformanceImg} className='HomeMenuCard' color="#f6824d"> 
          {/*  <ListItem title="Self-Awareness" color="#f6824d"></ListItem> */ }  
          {/*  <ListItem title="Self-Development" color="#f6824d">
            <ListItem title="IDP Tool" color="#f6824d"></ListItem>
            <ListItem title="eLearning" color="#f6824d"></ListItem>
          </ListItem>
          */ }
          <ListItem title={t('hr:FeedbackSurvey')} color="#f6824d" onClick={onsurvey180DegreeClick}> 
            {/* <ListItem
              title={t('hr:CultureSurvey')}
              color="#f6824d" 
              onClick={onsurvey180DegreeClick} 
            >

            </ListItem> */}
          </ListItem> 
          <ListItem title={t('hr:PerformanceDevelopment')} color="#f6824d" onClick={onAnnualReviewClick}></ListItem>  
          <ListItem title={t('hr:IndividualDevelopmentPlan')} color="#f6824d" onClick={onIndividualDevelopmentPlanClick}></ListItem> 
          {
            EsTienePermisosTalenPlannig && (<ListItem title={t('hr:TalentPlanning')} color="#f6824d" onClick={ontalentPlanningClick}></ListItem>)
          } 
        </HomeMenuCard> 
        <Link ref={annualReviewLnk} to={{ pathname: "PerformanceDevelpmentHome" }}></Link> 
        <Link ref={survey180DegreeLnk} to={{ pathname: "Survey180DegreeScreen" }}></Link> 
        <Link ref={individualDevelopmentPlanLnk} to={{ pathname: "IndividualDevelopmentPlan" }}></Link>  
        <Link ref={talentPlanningLnk} to={{ pathname: "TalentPlanningScreen" }}></Link> 
      </>  
        ); 
};

export default MyPerformanceMenu;

/* eslint-disable object-shorthand */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useState , useEffect }  from 'react';    
import CardSparkLine from '../../../components/Charts/Card-SparkLine/CardSparkLine';
import { Row, Col } from 'reactstrap';
import Highcharts from 'highcharts';
import HighchartsPareto from "highcharts/modules/pareto";
import HighchartsReact from 'highcharts-react-official';
import { config } from '../../../utils/config';
import { callApi } from '../../../utils/utils'; 
import queryString from 'query-string'  
import moment from 'moment'
import { useTranslation } from 'react-i18next';

HighchartsPareto(Highcharts);

const OperationDowntimesParetos =  (props) => {

    
   const { t, i18n } = useTranslation(['productionDashboard', 'common']);

   let defaultZona;
   let defaultFechaInicial = null;
   let defaultFechaFinal= null;

   if(props.location!==undefined){
    const params = queryString.parse(props.location.search);
    defaultZona = params !== undefined ? params.ClaZona : 0;
   }
   else {
    defaultZona = props.filters.ClaZona  !== undefined ? props.filters.ClaZona : 0;
    defaultFechaInicial = props.filters.FechaInicial  !== undefined ? props.filters.FechaInicial : null;
    defaultFechaFinal = props.filters.FechaFinal  !== undefined ? props.filters.FechaFinal : null;
   }
 
   const [claZona, setClaZona] = useState(defaultZona);
   const [fechaInicial, setFechaInicial] = useState(null);
   const [fechaFinal, setFechaFinal] = useState(null);
   const [nomZona, setNomZona] = useState('');
   const [totalDias, setTotalDias] = useState('');

   
   const [downtimeByCellCategories, setDowntimeByCellCategories] = useState([]);
   const [downtimeByCellSeries, setDowntimeByCellSeries] = useState([]);
   const [downtimeByReasonRank1Categories, setDowntimeByReasonRank1Categories] = useState([]);
   const [downtimeByReasonRank1Series, setDowntimeByReasonRank1Series] = useState([]);
   
   const [downtimeByReasonRank2Categories, setDowntimeByReasonRank2Categories] = useState([]);
   const [downtimeByReasonRank2Series, setDowntimeByReasonRank2Series] = useState([]);

   const [titleRank1, setTitleRank1] = useState('');
   const [titleRank2, setTitleRank2] = useState('');

    const formatDecimalValue = (x, y) => {   
        return Highcharts.numberFormat( y,2); 
    }

    const onSearch = (filters) => {
         
        const urlWebService = `${config.UrlApiProject}Produccion/GetOperationDowntimePareto`; 
        
        filters.EsTipoPareto = 2;

        let paramsService = {
            ...filters,
          }; 

        callApi(urlWebService, 'POST', paramsService, (response) => {  
 
            if (response.data.series.length >0 ){ 
                response.data.series[0] = {
                    y: response.data.series[0] ,
                    color: '#ef2f40'
                }  

                response.data.series[response.data.series.length-1] = {
                    y: response.data.series[response.data.series.length-1] ,
                    color: '#e4e4e4'
                }  

                
            }

            setDowntimeByCellSeries(response.data.series);
            setDowntimeByCellCategories(response.data.categories); 
        });
        
        filters.EsTipoPareto = 3;
        filters.DetalleRank = 1;

        paramsService = {
            ...filters,
          }; 

        callApi(urlWebService, 'POST', paramsService, (response) => { 
            if (response.data.series.length >0 ){ 
                response.data.series[0] = {
                    y: response.data.series[0] ,
                    color: '#ef2f40'
                }  

                response.data.series[response.data.series.length-1] = {
                    y: response.data.series[response.data.series.length-1] ,
                    color: '#e4e4e4'
                }  

                
            }

            setTitleRank1(response.data.title);
            setDowntimeByReasonRank1Series(response.data.series);
            setDowntimeByReasonRank1Categories(response.data.categories); 
        });

        filters.EsTipoPareto = 3;
        filters.DetalleRank = 2;

        paramsService = {
            ...filters,
          }; 

        callApi(urlWebService, 'POST', paramsService, (response) => { 
            if (response.data.series.length >0 ){ 
                response.data.series[0] = {
                    y: response.data.series[0] ,
                    color: '#ef2f40'
                }  

                response.data.series[response.data.series.length-1] = {
                    y: response.data.series[response.data.series.length-1] ,
                    color: '#e4e4e4'
                }  

                
            }

            setTitleRank2(response.data.title);
            setDowntimeByReasonRank2Series(response.data.series);
            setDowntimeByReasonRank2Categories(response.data.categories); 
        });
         
      };
      const paretoChart = {

        chart: {
            renderTo: 'container',
            type: 'column' 
        },
        title: {
            text:  t('productionDashboard:chartDownTimeByCell')
        },
        tooltip: {
            shared: true
        },
        xAxis: {
            categories: downtimeByCellCategories,
            crosshair: true
        },
        yAxis: [{
            title: {
                text: ''
            }
        }, {
            title: {
                text: ''
            },
            minPadding: 0,
            maxPadding: 0,
            max: 100,
            min: 0,
            opposite: true,
            labels: {
                format: "{value}%"
            }
        }],
        series: [
        {
            type: 'pareto',
            name: 'Pareto',
            showInLegend: false, 
            yAxis: 1,
            zIndex: 10,
            baseSeries: 1, 
            dataLabels:{
                enabled:true,  
                formatter: function() {  
                    const { x, y, point, series } = this; 
                    return `${Highcharts.numberFormat(y,1)  }%`;
                  }
            },
            tooltip: {
                valueDecimals: 2,
                valueSuffix: '%'
            }
        }, {
            name: 'Hours',
            type: 'column',
            showInLegend: false, 
            zIndex: 2,
            data: downtimeByCellSeries
        }]
    
    }

    const paretoReasonChartRank1 = {

        chart: {
            renderTo: 'container',
            type: 'column'
        },
        title: {
            text: titleRank1
        },
        tooltip: {
            shared: true
        },
        xAxis: {
            categories: downtimeByReasonRank1Categories,
            crosshair: true
        },
        yAxis: [{
            title: {
                text: ''
            }
        }, {
            title: {
                text: ''
            },
            minPadding: 0,
            maxPadding: 0,
            max: 100,
            min: 0,
            opposite: true,
            labels: {
                format: "{value}%"
            }
        }],
        series: [{
            type: 'pareto',
            name: 'Pareto',
            showInLegend: false, 
            yAxis: 1,
            zIndex: 10,
            baseSeries: 1, 
            dataLabels:{
                enabled:true,  
                formatter: function() {  
                    const { x, y, point, series } = this; 
                    return `${Highcharts.numberFormat(y,1)  }%`;
                  }
            },
            tooltip: {
                valueDecimals: 2,
                valueSuffix: '%'
            }
        }, {
            name: 'Hours',
            type: 'column',
            showInLegend: false, 
            zIndex: 2,
            data: downtimeByReasonRank1Series
        }]
    
    }

    const paretoReasonChartRank2 = {

        chart: {
            renderTo: 'container',
            type: 'column'
        },
        title: {
            text: titleRank2
        },
        tooltip: {
            shared: true
        },
        xAxis: {
            categories: downtimeByReasonRank2Categories,
            crosshair: true
        },
        yAxis: [{
            title: {
                text: ''
            }
        }, {
            title: {
                text: ''
            },
            minPadding: 0,
            maxPadding: 0,
            max: 100,
            min: 0,
            opposite: true,
            labels: {
                format: "{value}%"
            }
        }],
        series: [{
            type: 'pareto',
            name: 'Pareto',
            showInLegend: false, 
            yAxis: 1,
            zIndex: 10,
            baseSeries: 1, 
            dataLabels:{
                enabled:true,  
                formatter: function() {  
                    const { x, y, point, series } = this; 
                    return `${Highcharts.numberFormat(y,1)  }%`;
                  }
            },
            tooltip: {
                valueDecimals: 2,
                valueSuffix: '%'
            }
        }, {
            name: 'Hours',
            type: 'column',
            showInLegend: false, 
            zIndex: 2,
            data: downtimeByReasonRank2Series
        }]
    
    }

    useEffect(() => {
        const filter = {}
        filter.ClaZona = defaultZona;
        filter.FechaInicial = defaultFechaInicial
        filter.FechaFinal = defaultFechaFinal
        
        const urlWebService = `${config.UrlApiProject}Produccion/GetOperacionDashboardHeaderInfo`;
    
        const paramsService = {
          ...filter,
        }; 
    
        callApi(urlWebService, 'POST', paramsService, (response) => 
             { 
                setFechaInicial(response.data.FechaInicial);
                setFechaFinal(response.data.FechaFinal); 
                setNomZona(response.data.NomZona); 
                setTotalDias(response.data.TotalDias); 
                const filter = {}
                filter.ClaZona      = response.data.ClaZona;
                filter.FechaInicial = response.data.FechaInicial;
                filter.FechaFinal = response.data.FechaFinal;
                onSearch(filter);
            
            }
        ); 
        
      }, [props.filters]);

    return (  
      <>     
        <Row>  
          <Col xs={12} md={12} sm={12} lg={12}>
            <div className="header-dashboard">
              <Row> 
                <Col xs={12} md={12} sm={12} lg={12} className="header-dashboard-title"> 
                  {t('productionDashboard:downtimesDashboardTitle')} 
                </Col> 
                <Col xs={12} md={12} sm={12} lg={12}> 
                  <Row> 
                    <Col xs={12} md={6} sm={12} lg={6}> 
                      <div className="header-dashboard-sub-title">
                        {t('productionDashboard:Last')} {totalDias} {t('productionDashboard:Daysfrom') } <b> { moment(fechaInicial).format('MMMM Do YYYY')  } </b> {t('common:to') }  <b> {moment(fechaFinal).format('MMMM Do YYYY') }  </b> 
                      </div>
                    </Col>
                    <Col xs={12} md={6} sm={12} lg={6}> 
                      <div className="header-dashboard-sub-title-2">
                        {nomZona}
                      </div> 
                    </Col> 
                  </Row> 
                </Col> 
              </Row>
            </div> 
          </Col> 
        </Row> 
        <Row>  
          <Col xs={12} md={12} sm={12} lg={12}>
                &nbsp;
          </Col>
        </Row>
        <Row className="align-items-start">  
          <Col xs={12} md={6} sm={12} lg={6}> 
            <HighchartsReact containerProps={{ style: { height: "520px" } }} highcharts={Highcharts} options={paretoChart} />
          </Col>
          <Col xs={12} md={6} sm={12} lg={6}> 
            <Row>  
              <Col xs={12} md={12} sm={12} lg={12}>   
                <HighchartsReact containerProps={{ style: { height: "250px" } }} highcharts={Highcharts} options={paretoReasonChartRank1} />
              </Col>
              <Col xs={12} md={12} sm={12} lg={12}>   
                 &nbsp;
              </Col>
              <Col xs={12} md={12} sm={12} lg={12}>   
                <HighchartsReact containerProps={{ style: { height: "250px" } }} highcharts={Highcharts} options={paretoReasonChartRank2} />
              </Col>
            </Row> 
          </Col>
        </Row>
      </> 
    )
}   

    
export default OperationDowntimesParetos; 

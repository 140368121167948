import React, { useState, useRef, useEffect  } from 'react'; 
import { useTranslation } from 'react-i18next';   
import { config } from '../../../utils/config';
import { callApi, showSweetAlert} from '../../../utils/utils';   
import PageContent from 'layouts/PageContent';   
import { Badge } from 'primereact/badge'; 
import { Row,Col,Card, CardHeader, CardTitle, CardBody, CardFooter} from 'reactstrap';   
import FieldDataView from 'components/Controls/FieldDataView/FieldDataView.jsx';
import './MMADashboardScreen.scss';  
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

const MMADashboardScreen = (props) => { 
  const { t } = useTranslation(['common']);
  const [informationWO, setinformationWO] = useState([]);
  const [notAssignedWO, setNotAssignedWO] = useState([]);
  const [assignedWO, setAssignedWO] = useState([]);
  const [doneWO, setDoneWO] = useState([]);

  const [woChartSeries, setWoChartSeries] = useState([]);
  const [woByDeptChartSeries, setWoByDeptChartSeries] = useState([]);
  const [woByDeptChartCategories, setWoByDeptChartCategories] = useState([]);
  const [woByPersonChartSeries, setWoByPersonChartSeries] = useState([]);
  const [woByPersonChartCategories, setWoByPersonChartCategories] = useState([]);


  useEffect(() => {
    getData();
  }, [])

  const getData = () => { 
    console.log('getData MMA');
    const url = `${config.UrlApiProject}MMA/GetMMA_CU53_Pag1_Dashboard_Mto_Prev`;   
    const paramsService= {};  
    callApi(url, 'GET', paramsService, (response) => {
      const informationList = response.data.MMADashboardList;
      const capturedList = response.data.MMACapturedList;
      const inProgressList = response.data.MMAInProgressList;
      const closedList = response.data.MMAClosedList;
      const series = response.data.DepartamentSeries;
      const categories = response.data.DepartamentCategories;
      const seriesPerson = response.data.PersonSeries;
      const categoriesPerson = response.data.PersonCategories;
      setinformationWO(informationList);
      setNotAssignedWO(capturedList);
      setAssignedWO(inProgressList)
      setDoneWO(closedList);
      getWoChart(informationList,capturedList,inProgressList,closedList);
      getWoByDeptChart(series,categories);
      getWoByPersonChart(seriesPerson,categoriesPerson);
    });
  }

  const woCardStyle = {
    backgroundColor: 'inherit',    
  };

  const woCardStyleAlternative = {
    backgroundColor: 'whitesmoke'
  };

  const woCardBodyStyle = {
    padding: '0',    
  };

  const woChartOptions = {
    chart: {
      type: 'pie',          
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    title: {
      text: "Work orders",
      align: 'center'
    },
    subtitle: {
      text: ""
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    tooltip: {      
      pointFormat: '<b>{point.percentage:.1f}%</b>'            
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        }
      }
    },
    series: woChartSeries
  };

  const woByDebtChartOptions = {
    chart: {
      type: 'bar',
    },
    title: {
      text: "Work orders by Dept.",
      align: 'center'
    },
    subtitle: {
      text: ""
    },
    xAxis: {
      categories: woByDeptChartCategories,
      title: {
        text: null
      },
      gridLineWidth: 1,
      lineWidth: 0
    },
    yAxis: {
      min: 0,
      title: {
        text: "Work Orders"
      },
      gridLineWidth: 1,
      lineWidth: 0,
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color: 'blue',
          textOutline: 'none'
        }
      }               
    },
    tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: <b>{point.y}<b/>'
    },
    plotOptions: {
      bar: {          
        dataLabels: {
          enabled: true
        }
      }
    },
    series: woByDeptChartSeries
  };

  const woByPersonChartOptions = {
    chart: {
      type: 'bar'
    },
    title: {
      text: "Work orders by Person in charge",
      align: 'center'
    },
    subtitle: {
      text: ""
    },
    xAxis: {
      categories: woByPersonChartCategories,
      title: {
        text: null
      },
      gridLineWidth: 1,
      lineWidth: 0
    },
    yAxis: {
      min: 0,
      title: {
        text: "Work Orders"
      },
      gridLineWidth: 1,
      lineWidth: 0,
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color: 'blue',
          textOutline: 'none'
        }
      }               
    },
    tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: <b>{point.y}<b/>'
    },
    plotOptions: {
      bar: {          
        dataLabels: {
          enabled: true
        }
      }
    },
    series: woByPersonChartSeries
  };

  const getNotAssignedWO = () => {
    setNotAssignedWO([{
      index: 0,
      wo: '7521',
      asset: 'Bulk / Receiving / Dock 1',
      name: 'Dock Door PM',
      description: '1522 - 1522 - GT By Frequency - 12 Months - PREVENTIVE - Mechanic - Running Asset - 1 - 1-5 Dock Door PM',
      planDate: '6/18/2023',
      expirationDate: '8/9/2023'
    }, {
      index: 1,
      wo: '7521',
      asset: 'Bulk / Receiving / Dock 1',
      name: 'Dock Door PM',
      description: '1522 - 1522 - GT By Frequency - 12 Months - PREVENTIVE - Mechanic - Running Asset - 1 - 1-5 Dock Door PM',
      planDate: '6/18/2023',
      expirationDate: '8/9/2023'
    }, {
      index: 2,
      wo: '7521',
      asset: 'Bulk / Receiving / Dock 1',
      name: 'Dock Door PM',
      description: '1522 - 1522 - GT By Frequency - 12 Months - PREVENTIVE - Mechanic - Running Asset - 1 - 1-5 Dock Door PM',
      planDate: '6/18/2023',
      expirationDate: '8/9/2023'
    }, {
      index: 3,
      wo: '7521',
      asset: 'Bulk / Receiving / Dock 1',
      name: 'Dock Door PM',
      description: '1522 - 1522 - GT By Frequency - 12 Months - PREVENTIVE - Mechanic - Running Asset - 1 - 1-5 Dock Door PM',
      planDate: '6/18/2023',
      expirationDate: '8/9/2023'
    },{
      index: 4,
      wo: '7521',
      asset: 'Bulk / Receiving / Dock 1',
      name: 'Dock Door PM',
      description: '1522 - 1522 - GT By Frequency - 12 Months - PREVENTIVE - Mechanic - Running Asset - 1 - 1-5 Dock Door PM',
      planDate: '6/18/2023',
      expirationDate: '8/9/2023'
    }, {
      index: 5,
      wo: '7521',
      asset: 'Bulk / Receiving / Dock 1',
      name: 'Dock Door PM',
      description: '1522 - 1522 - GT By Frequency - 12 Months - PREVENTIVE - Mechanic - Running Asset - 1 - 1-5 Dock Door PM',
      planDate: '6/18/2023',
      expirationDate: '8/9/2023'
    }, {
      index: 6,
      wo: '7521',
      asset: 'Bulk / Receiving / Dock 1',
      name: 'Dock Door PM',
      description: '1522 - 1522 - GT By Frequency - 12 Months - PREVENTIVE - Mechanic - Running Asset - 1 - 1-5 Dock Door PM',
      planDate: '6/18/2023',
      expirationDate: '8/9/2023'
    }]);
  };

  const getAssignedWO = () => {
    setAssignedWO([{
      index: 0,
      wo: '7521',
      asset: 'Bulk / Receiving / Dock 1',
      name: 'Dock Door PM',
      description: '1522 - 1522 - GT By Frequency - 12 Months - PREVENTIVE - Mechanic - Running Asset - 1 - 1-5 Dock Door PM',
      planDate: '6/18/2023',
      expirationDate: '8/9/2023'
    }]);
  };

  const getDonedWO = () => {
    setDoneWO([{
      index: 0,
      wo: '11134',
      asset: 'Collated / Facility. / Compressor Room.',
      name: 'Clean Compressor',
      description: '3350 - 3350 - 3350 - TG - By Frequency - 1 - Week - PREVENTIVE - Mechanic - Asset - Running - 11-15 - 11-15.5 - Clean Compressor',
      planDate: '6/18/2023',
      expirationDate: '8/9/2023'
    },{
      index: 1,
      wo: '11134',
      asset: 'Collated / Facility. / Compressor Room.',
      name: 'Clean Compressor',
      description: '3350 - 3350 - 3350 - TG - By Frequency - 1 - Week - PREVENTIVE - Mechanic - Asset - Running - 11-15 - 11-15.5 - Clean Compressor',
      planDate: '6/18/2023',
      expirationDate: '8/9/2023'
    }]);
  };

  const getWoChart = (informationList,capturedList,inProgressList,closedList) => {
    setWoChartSeries([{      
      data: [{
        name:"Not asigned",
        color:"#17a2b8",
        y: (capturedList.length > 0) ? (capturedList.length / informationList.length) * 100 : 0
      }, {
        name:"Asigned",
        color:"#ff8248",
        y: (inProgressList.length > 0) ? (inProgressList.length / informationList.length) * 100 : 0
      },{
        name:"Done",
        color:"#00cc85",
        y: (closedList.length > 0) ? (closedList.length / informationList.length) * 100 : 0
      }]
    }]);
  };

  const getWoByDeptChart = (serieInfo,categoriesInfo) => {
    setWoByDeptChartSeries([{
      name:"Not asigned",
      color:serieInfo[0].color,
      data: serieInfo[0].data
    }, {
      name:"Asigned",
      color:serieInfo[1].color,
      data: serieInfo[1].data
    },{
      name:"Done",
      color:serieInfo[2].color,
      data: serieInfo[2].data
    }]);
    setWoByDeptChartCategories(categoriesInfo); 
    // setWoByDeptChartCategories(["Bulk", "Paper Tape", "Collated", "Spare Equipment"]);    
  };

  const getWoByPersonChart = (serieInfo,categoriesInfo) => {
    setWoByPersonChartSeries([{
      name:"Not asigned",
      color:serieInfo[0].color,
      data: serieInfo[0].data
    }, {
      name:"Asigned",
      color:serieInfo[1].color,
      data: serieInfo[1].data
    },{
      name:"Done",
      color:serieInfo[2].color,
      data: serieInfo[2].data
    }]);
    setWoByPersonChartCategories(categoriesInfo); 
    // setWoByPersonChartCategories(["Mickey Kovach", "Jimmy R Hill", "Stephen E Skinner", "Ricky G Dockins"]);
  };

  const getWOCardStyle = (index) => {
    return index % 2? woCardStyleAlternative : woCardStyle;
  };

  useEffect(() => {
    // getWoChart();
    // getWoByDeptChart();
    // getWoByPersonChart();    
    // getNotAssignedWO();
    // getAssignedWO();
    // getDonedWO();
  }, []);

  const woCardTemplateUnAssigned = (wo) => {
    return (
      <>
        <Row className='w-100 m-0 pt-2'>
          <Col>
            <Row>
              <Col> 
                WO: <b>{wo.ClaveOt}</b>  
                <div> 
                  Plan date:
                  &nbsp;<b>{wo.FechaHoraProgOriginal}</b> 
                </div>  
              </Col>
              <Col className='text-right'> 
                Asset: <b>{wo.ClaveEquipo}</b>  
                <div> 
                  Expiration date:
                  &nbsp;<b>{wo.FechaHoraVigencia}</b> 
                </div>    
              </Col>
            </Row>
            <Row>
              <Col className='pt-2'>
                <b>{wo.NomActividad}</b>
                <div>{wo.ClaveGuia} </div>
              </Col>
            </Row>
            <Row className="">
              <Col lg={12} md={12} sm={12} xs={12}>
                <div className="border-color-info-color" style={{borderBottom: "1px solid"}}></div>
              </Col>
            </Row> 
          </Col>
        </Row>      
      </>
    );
  };
  const woCardTemplateAssigned = (wo) => {
    return (
      <>
        <Row className='w-100 m-0 pt-2'>
          <Col>
            <Row>
              <Col> 
                WO: <b>{wo.ClaveOt}</b>  
                <div> 
                  Plan date:
                  &nbsp;<b>{wo.FechaHoraProgOriginal}</b> 
                </div>  
              </Col>
              <Col className='text-right'> 
                Asset: <b>{wo.ClaveEquipo}</b>  
                <div> 
                  Expiration date:
                  &nbsp;<b>{wo.FechaHoraVigencia}</b> 
                </div>    
              </Col>
            </Row>
            <Row>
              <Col className='pt-2'>
                <b>{wo.NomActividad}</b>
                <div>{wo.ClaveGuia} </div>
              </Col>
            </Row>
            <Row className="">
              <Col lg={12} md={12} sm={12} xs={12}>
                <div className="border-color-orange-color" style={{borderBottom: "1px solid"}}></div>
              </Col>
            </Row> 
          </Col>
        </Row>      
      </>
    );
  };
  const woCardTemplateDone = (wo) => {
    return (
      <>
        <Row className='w-100 m-0 pt-2'>
          <Col>
            <Row>
              <Col> 
                WO: <b>{wo.ClaveOt}</b>  
                <div> 
                  Plan date:
                  &nbsp;<b>{wo.FechaHoraProgOriginal}</b> 
                </div>  
              </Col>
              <Col className='text-right'> 
                Asset: <b>{wo.ClaveEquipo}</b>  
                <div> 
                  Expiration date:
                  &nbsp;<b>{wo.FechaHoraVigencia}</b> 
                </div>    
              </Col>
            </Row>
            <Row>
              <Col className='pt-2'>
                <b>{wo.NomActividad}</b>
                <div>{wo.ClaveGuia} </div>
              </Col>
            </Row>
            <Row className="">
              <Col lg={12} md={12} sm={12} xs={12}>
                <div className="border-color-light-green" style={{ borderBottom: "1px solid"}}></div>
              </Col>
            </Row> 
          </Col>
        </Row>      
      </>
    );
  };

  const chartsTemplate = (
    <>
      <Card>          
        <CardBody> 
          <Row>
            <Col>
              <HighchartsReact 
                highcharts={Highcharts} 
                options={woChartOptions} 
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <HighchartsReact 
                highcharts={Highcharts} 
                options={woByDebtChartOptions} 
              />         
            </Col>
          </Row>
          <Row className='pt-5'>
            <Col>       
              <HighchartsReact 
                highcharts={Highcharts} 
                options={woByPersonChartOptions} 
              />
            </Col>
          </Row>
        </CardBody>
      </Card>      
    </>
  );
  
  const notAssignedWOTemplate = (
    <>
      <Card>
        <CardHeader className="info">
          <CardTitle>
            <Row className="pt-3">
              <Col>
                Not assigned
              </Col>
              <Col className="text-right">
                <Badge
                  value={notAssignedWO.length}
                  className="info-dark"
                  size="large"
                />  
              </Col>
            </Row>             
          </CardTitle>
        </CardHeader>    
        <CardBody style={woCardBodyStyle}> 
          <FieldDataView
            value={notAssignedWO}            
            itemTemplate={woCardTemplateUnAssigned}
            layout="list"    
            className="MMADashboardScreen-data-view"   
            paginator
            rows={50}        
          />
        </CardBody>
      </Card>      
    </>
  );

  const assignedWOTemplate = (
    <>
      <Card>
        <CardHeader className="orange">
          <CardTitle>
            <Row className="pt-3">
              <Col>
                Assigned
              </Col>
              <Col className="text-right">
                <Badge
                  value={assignedWO.length}
                  className="info-dark"
                  size="large"
                />  
              </Col>
            </Row>             
          </CardTitle>
        </CardHeader>    
        <CardBody style={woCardBodyStyle}> 
          <FieldDataView
            value={assignedWO}            
            itemTemplate={woCardTemplateAssigned}
            layout="list"
            className="MMADashboardScreen-data-view"
            paginatorClassName="painatorfixed"
            paginator
            rows={50}
          />
        </CardBody>
      </Card>      
    </>
  );

  const doneWOTemplate = (
    <>
      <Card>
        <CardHeader className="light-green">
          <CardTitle>
            <Row className="pt-3">
              <Col>
                Done
              </Col>
              <Col className="text-right">
                <Badge
                  value={doneWO.length}
                  className="info-dark"
                  size="large"
                />  
              </Col>
            </Row>             
          </CardTitle>
        </CardHeader>    
        <CardBody style={woCardBodyStyle}> 
          <FieldDataView
            value={doneWO}            
            itemTemplate={woCardTemplateDone}
            layout="list"
            className="MMADashboardScreen-data-view"
            paginator
            rows={50}
          />
        </CardBody>
      </Card>      
    </>
  );

  return (
    <>   
      <PageContent title="MMA Dashboard" className="MMADashboardScreen">  
        <Row className='align-items-start'>
          <Col lg={4} md={4} sm={12} xs={12}>  
            {chartsTemplate}
          </Col> 
          <Col lg={8} md={8} sm={12} xs={12}>  
            <Row>
              <Col lg={6} md={6} sm={12} xs={12}>
                {notAssignedWOTemplate}
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                {assignedWOTemplate}
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                {doneWOTemplate}
              </Col>              
            </Row>
          </Col>           
        </Row>    
      </PageContent> 
    </>
  );
};

export default MMADashboardScreen;

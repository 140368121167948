/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  ListGroupItem,
  ListGroup,
  Progress,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle
} from 'reactstrap';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { Badge } from 'primereact/badge';
// import FeedbackCoaching from './FeedbackCoaching.jsx';
import { Link } from 'react-router-dom';
import ActionPlanScoreSquare from './ActionPlanScoreSquare.jsx';
import { Toolbar } from 'primereact/toolbar';
import Highcharts from 'highcharts';
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../../utils/utils';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable.jsx';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';

const ScoreCardAnnualDetail = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['focusReport', 'common']);
  const [ClaPuesto, setClaPuesto] = useState(null); 
  const [ClaAnio, setClaAnio] = useState(null);
  const [ScoreCardDetailList, setScoreCardDetailList] = useState([]);
  const [ProjectActivitiesAnnualAvg, setProjectActivitiesAnnualAvg] = useState(null);
  
  const [JanStatus, setJanStatus] = useState(null);
  const [FebStatus, setFebStatus] = useState(null);
  const [MarStatus, setMarStatus] = useState(null);
  const [AprStatus, setAprStatus] = useState(null);
  const [MayStatus, setMayStatus] = useState(null);
  const [JunStatus, setJunStatus] = useState(null);
  const [JulStatus, setJulStatus] = useState(null);
  const [AugStatus, setAugStatus] = useState(null);
  const [SepStatus, setSepStatus] = useState(null);
  const [OctStatus, setOctStatus] = useState(null);
  const [NovStatus, setNovStatus] = useState(null);
  const [DecStatus, setDecStatus] = useState(null);

  const [JanScore, setJanScore] = useState(null);
  const [FebScore, setFebScore] = useState(null);
  const [MarScore, setMarScore] = useState(null);
  const [AprScore, setAprScore] = useState(null);
  const [MayScore, setMayScore] = useState(null);
  const [JunScore, setJunScore] = useState(null);
  const [JulScore, setJulScore] = useState(null);
  const [AugScore, setAugScore] = useState(null);
  const [SepScore, setSepScore] = useState(null);
  const [OctScore, setOctScore] = useState(null);
  const [NovScore, setNovScore] = useState(null);
  const [DecScore, setDecScore] = useState(null);

  const [JanColorStatus, setJanColorStatus] = useState(null);
  const [FebColorStatus, setFebColorStatus] = useState(null);
  const [MarColorStatus, setMarColorStatus] = useState(null);
  const [AprColorStatus, setAprColorStatus] = useState(null);
  const [MayColorStatus, setMayColorStatus] = useState(null);
  const [JunColorStatus, setJunColorStatus] = useState(null);
  const [JulColorStatus, setJulColorStatus] = useState(null);
  const [AugColorStatus, setAugColorStatus] = useState(null);
  const [SepColorStatus, setSepColorStatus] = useState(null);
  const [OctColorStatus, setOctColorStatus] = useState(null);
  const [NovColorStatus, setNovColorStatus] = useState(null);
  const [DecColorStatus, setDecColorStatus] = useState(null);

  useEffect(() => { 
    setClaPuesto(props.ClaPuesto === undefined ? 0 : props.ClaPuesto);
    setClaAnio(props.ClaAnio === undefined ? 0 : props.ClaAnio);
    GetProjectActivitiesScoreCardDet();
  }, [props.ClaPuesto, props.ClaAnio]);

  const GetProjectActivitiesScoreCardDet = () => {
    const getProjectActivitiesScoreCardDet = `${config.UrlApiProject}ScoreCard/GetProjectActivitiesScoreCardDet`;
    const paramsToService = {
      ClaPuesto: props.ClaPuesto === undefined ? 0 : props.ClaPuesto,
      ClaAnio: props.ClaAnio === undefined ? 0 : props.ClaAnio,
    };
    callApi(getProjectActivitiesScoreCardDet, 'POST', paramsToService, (response) => {  
        
        const ScoreCardDetailTitle = response.data.ScoreCardDetailTitle.length > 0 ? response.data.ScoreCardDetailTitle[0] : {}
 
        setProjectActivitiesAnnualAvg(ScoreCardDetailTitle.Score);  
        
        setJanStatus(ScoreCardDetailTitle.JanStatus);
        setFebStatus(ScoreCardDetailTitle.FebStatus);
        setMarStatus(ScoreCardDetailTitle.MarStatus);
        setAprStatus(ScoreCardDetailTitle.AprStatus);
        setMayStatus(ScoreCardDetailTitle.MayStatus);
        setJunStatus(ScoreCardDetailTitle.JunStatus);
        setJulStatus(ScoreCardDetailTitle.JulStatus);
        setAugStatus(ScoreCardDetailTitle.AugStatus);
        setSepStatus(ScoreCardDetailTitle.SepStatus);
        setOctStatus(ScoreCardDetailTitle.OctStatus);
        setNovStatus(ScoreCardDetailTitle.NovStatus);
        setDecStatus(ScoreCardDetailTitle.DecStatus);

        setJanScore(ScoreCardDetailTitle.JanScore);
        setFebScore(ScoreCardDetailTitle.FebScore);
        setMarScore(ScoreCardDetailTitle.MarScore);
        setAprScore(ScoreCardDetailTitle.AprScore);
        setMayScore(ScoreCardDetailTitle.MayScore);
        setJunScore(ScoreCardDetailTitle.JunScore);
        setJulScore(ScoreCardDetailTitle.JulScore);
        setAugScore(ScoreCardDetailTitle.AugScore);
        setSepScore(ScoreCardDetailTitle.SepScore);
        setOctScore(ScoreCardDetailTitle.OctScore);
        setNovScore(ScoreCardDetailTitle.NovScore);
        setDecScore(ScoreCardDetailTitle.DecScore);

        setJanColorStatus(ScoreCardDetailTitle.JanColorStatus);
        setFebColorStatus(ScoreCardDetailTitle.FebColorStatus);
        setMarColorStatus(ScoreCardDetailTitle.MarColorStatus);
        setAprColorStatus(ScoreCardDetailTitle.AprColorStatus);
        setMayColorStatus(ScoreCardDetailTitle.MayColorStatus);
        setJunColorStatus(ScoreCardDetailTitle.JunColorStatus);
        setJulColorStatus(ScoreCardDetailTitle.JulColorStatus);
        setAugColorStatus(ScoreCardDetailTitle.AugColorStatus);
        setSepColorStatus(ScoreCardDetailTitle.SepColorStatus);
        setOctColorStatus(ScoreCardDetailTitle.OctColorStatus);
        setNovColorStatus(ScoreCardDetailTitle.NovColorStatus);
        setDecColorStatus(ScoreCardDetailTitle.DecColorStatus); 

      setScoreCardDetailList(response.data.ScoreCardDetail); 
    });
  };
  const PorcTemplate = (rowData) => {
    return (
      <>
        <label>
          <b>{rowData.Porc}</b> %
        </label>
      </>
    );
  };

const renderDescription = (rowData) => {
  return (
    <> 
      <span className='overflow-ellipsis' title={rowData.Description}>{rowData.Description}</span> 
    </>
  );
}

  const headerScoreCardDetail = <></>;

  const headerScoreCardDetailGroup  = ( 
    <ColumnGroup>
      <Row>   
        <Column header={t('focusReport:Jan')} className="text-center table-header-background-dark-green" colSpan={3}></Column>
        <Column header={t('focusReport:Feb')} className="text-center" colSpan={3}></Column> 
        <Column header={t('focusReport:Mar')} className="text-center table-header-background-dark-green" colSpan={3}></Column> 
        <Column header={t('focusReport:Apr')} className="text-center" colSpan={3}></Column>
        <Column header={t('focusReport:May')} className="text-center table-header-background-dark-green" colSpan={3}></Column> 
        <Column header={t('focusReport:Jun')} className="text-center" colSpan={3}></Column>
        <Column header={t('focusReport:Jul')} className="text-center table-header-background-dark-green" colSpan={3}></Column> 
        <Column header={t('focusReport:Aug')} className="text-center" colSpan={3}></Column> 
        <Column header={t('focusReport:Sep')} className="text-center table-header-background-dark-green" colSpan={3}></Column> 
        <Column header={t('focusReport:Oct')} className="text-center" colSpan={3}></Column>
        <Column header={t('focusReport:Nov')} className="text-center table-header-background-dark-green" colSpan={3}></Column> 
        <Column header={t('focusReport:Dec')} className="text-center" colSpan={3}></Column>       
   
      </Row> 
      <Row> 
        <Column className="text-center table-header-background-dark-green" header={t('focusReport:Weight')}></Column>  
        <Column className="text-center table-header-background-dark-green" header={t('focusReport:Status')}></Column>  
        <Column className="text-center table-header-background-dark-green" header={t('focusReport:Score')}></Column>  
        <Column className="text-center" header={t('focusReport:Weight')}></Column>  
        <Column className="text-center" header={t('focusReport:Status')}></Column>  
        <Column className="text-center" header={t('focusReport:Score')}></Column> 
        <Column className="text-center table-header-background-dark-green" header={t('focusReport:Weight')}></Column>  
        <Column className="text-center table-header-background-dark-green" header={t('focusReport:Status')}></Column>  
        <Column className="text-center table-header-background-dark-green" header={t('focusReport:Score')}></Column>  
        <Column className="text-center" header={t('focusReport:Weight')}></Column>  
        <Column className="text-center" header={t('focusReport:Status')}></Column>  
        <Column className="text-center" header={t('focusReport:Score')}></Column>  
        <Column className="text-center table-header-background-dark-green" header={t('focusReport:Weight')}></Column>  
        <Column className="text-center table-header-background-dark-green" header={t('focusReport:Status')}></Column>  
        <Column className="text-center table-header-background-dark-green" header={t('focusReport:Score')}></Column>  
        <Column className="text-center" header={t('focusReport:Weight')}></Column>  
        <Column className="text-center" header={t('focusReport:Status')}></Column>  
        <Column className="text-center" header={t('focusReport:Score')}></Column>  
        <Column className="text-center table-header-background-dark-green" header={t('focusReport:Weight')}></Column>  
        <Column className="text-center table-header-background-dark-green" header={t('focusReport:Status')}></Column>  
        <Column className="text-center table-header-background-dark-green" header={t('focusReport:Score')}></Column>  
        <Column className="text-center" header={t('focusReport:Weight')}></Column>  
        <Column className="text-center" header={t('focusReport:Status')}></Column>  
        <Column className="text-center" header={t('focusReport:Score')}></Column>  
        <Column className="text-center table-header-background-dark-green" header={t('focusReport:Weight')}></Column>  
        <Column className="text-center table-header-background-dark-green" header={t('focusReport:Status')}></Column>  
        <Column className="text-center table-header-background-dark-green" header={t('focusReport:Score')}></Column>  
        <Column className="text-center" header={t('focusReport:Weight')}></Column>  
        <Column className="text-center" header={t('focusReport:Status')}></Column>  
        <Column className="text-center" header={t('focusReport:Score')}></Column>  
        <Column className="text-center table-header-background-dark-green" header={t('focusReport:Weight')}></Column>  
        <Column className="text-center table-header-background-dark-green" header={t('focusReport:Status')}></Column>  
        <Column className="text-center table-header-background-dark-green" header={t('focusReport:Score')}></Column>  
        <Column className="text-center" header={t('focusReport:Weight')}></Column>  
        <Column className="text-center" header={t('focusReport:Status')}></Column>  
        <Column className="text-center" header={t('focusReport:Score')}></Column>  
      </Row> 
    </ColumnGroup>
   )
  
  const footerFrozenColumnGroup  = ( 
    <> 
      <Row>
        <Column></Column>  
        <Column></Column>  
        <Column footer={<b>Total</b>}></Column>          
      </Row>
    </>
  )

  const footer  = ( 
    <> 
      <Row>
        <Column className="text-center" footer={() => (<b>{ (ScoreCardDetailList.reduce((a,v) =>  a += v.JanWeight === null ? 0 : v.JanWeight , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>  
        <Column className="text-center" footer={() => (<b>{ JanStatus}</b>)}></Column> 
        <Column className="text-center" footer={() => (<b>{ JanScore}</b>)}></Column>
 
        <Column className="text-center" footer={() => (<b>{ (ScoreCardDetailList.reduce((a,v) =>  a += v.FebWeight === null ? 0 : v.FebWeight , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>  
        <Column className="text-center" footer={() => (<b>{ FebStatus}</b>)}></Column> 
        <Column className="text-center" footer={() => (<b>{ FebScore}</b>)}></Column>

        <Column className="text-center" footer={() => (<b>{ (ScoreCardDetailList.reduce((a,v) =>  a += v.MarWeight === null ? 0 : v.MarWeight , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>  
        <Column className="text-center" footer={() => (<b>{ MarStatus}</b>)}></Column>   
        <Column className="text-center" footer={() => (<b>{ MarScore}</b>)}></Column>

        <Column className="text-center" footer={() => (<b>{ (ScoreCardDetailList.reduce((a,v) =>  a += v.AprWeight === null ? 0 : v.AprWeight , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>  
        <Column className="text-center" footer={() => (<b>{ AprStatus}</b>)}></Column> 
        <Column className="text-center" footer={() => (<b>{ AprScore}</b>)}></Column>

        <Column className="text-center" footer={() => (<b>{ (ScoreCardDetailList.reduce((a,v) =>  a += v.MayWeight === null ? 0 : v.MayWeight , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>  
        <Column className="text-center" footer={() => (<b>{ MayStatus}</b>)}></Column>   
        <Column className="text-center" footer={() => (<b>{ MayScore}</b>)}></Column>

        <Column className="text-center" footer={() => (<b>{ (ScoreCardDetailList.reduce((a,v) =>  a += v.JunWeight === null ? 0 : v.JunWeight , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>  
        <Column className="text-center" footer={() => (<b>{ JunStatus}</b>)}></Column>  
        <Column className="text-center" footer={() => (<b>{ JunScore}</b>)}></Column>

        <Column className="text-center" footer={() => (<b>{ (ScoreCardDetailList.reduce((a,v) =>  a += v.JulWeight === null ? 0 : v.JulWeight , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>  
        <Column className="text-center" footer={() => (<b>{ JulStatus}</b>)}></Column>  
        <Column className="text-center" footer={() => (<b>{ JulScore}</b>)}></Column>

        <Column className="text-center" footer={() => (<b>{ (ScoreCardDetailList.reduce((a,v) =>  a += v.AugWeight === null ? 0 : v.AugWeight , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>  
        <Column className="text-center" footer={() => (<b>{ AugStatus}</b>)}></Column>   
        <Column className="text-center" footer={() => (<b>{ AugScore}</b>)}></Column>

        <Column className="text-center" footer={() => (<b>{ (ScoreCardDetailList.reduce((a,v) =>  a += v.SepWeight === null ? 0 : v.SepWeight , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>  
        <Column className="text-center" footer={() => (<b>{ SepStatus}</b>)}></Column>   
        <Column className="text-center" footer={() => (<b>{ SepScore}</b>)}></Column>

        <Column className="text-center" footer={() => (<b>{ (ScoreCardDetailList.reduce((a,v) =>  a += v.OctWeight === null ? 0 : v.OctWeight , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>  
        <Column className="text-center" footer={() => (<b>{ OctStatus}</b>)}></Column>  
        <Column className="text-center" footer={() => (<b>{ OctScore}</b>)}></Column>

        <Column className="text-center" footer={() => (<b>{ (ScoreCardDetailList.reduce((a,v) =>  a += v.NovWeight === null ? 0 : v.NovWeight , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>  
        <Column className="text-center" footer={() => (<b>{ NovStatus}</b>)}></Column>   
        <Column className="text-center" footer={() => (<b>{ NovScore}</b>)}></Column>

        <Column className="text-center" footer={() => (<b>{ (ScoreCardDetailList.reduce((a,v) =>  a += v.DecWeight === null ? 0 : v.DecWeight , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>  
        <Column className="text-center" footer={() => (<b>{ DecStatus}</b>)}></Column>   
        <Column className="text-center" footer={() => (<b>{ DecScore}</b>)}></Column> 

      </Row>
    </>
  )

  const frozenHeaderColumnGroup  = (  
    <ColumnGroup>
      <Row> 
        <Column header={t('focusReport:IndexType')} rowSpan={2} headerStyle={{ height:'52px'}}></Column>  
        <Column header={t('focusReport:Code')} rowSpan={2}></Column>  
        <Column header={t('focusReport:Description')} rowSpan={2}></Column>           
      </Row>
    </ColumnGroup> 
  )
 
  const renderJanStatus = (rowData) => {
    return (
      <>
        { 
         rowData.JanStatus !== null ?
         (rowData.JanStatus).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) 
         :
         (<>&nbsp;</>)
        }
      </>
    );
  };

  const renderFebStatus = (rowData) => {
    return (
      <>
        { 
         rowData.FebStatus !== null ?
         (rowData.FebStatus).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) 
         :
         (<>&nbsp;</>)
        }
      </>
    );
  };

  const renderMarStatus = (rowData) => {
    return (
      <>
        { 
         rowData.MarStatus !== null ?
         (rowData.MarStatus).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) 
         :
         (<>&nbsp;</>)
        }
      </>
    );
  };

  const renderAprStatus = (rowData) => {
    return (
      <>
        { 
         rowData.AprStatus !== null ?
         (rowData.AprStatus).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) 
         :
         (<>&nbsp;</>)
        }
      </>
    );
  };

  const renderMayStatus = (rowData) => {
    return (
      <>
        { 
         rowData.MayStatus !== null ?
         (rowData.MayStatus).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) 
         :
         (<>&nbsp;</>)
        }
      </>
    );
  };

  const renderJunStatus = (rowData) => {
    return (
      <>
        { 
         rowData.JunStatus !== null ?
         (rowData.JunStatus).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) 
         :
         (<>&nbsp;</>)
        }
      </>
    );
  };

  const renderJulStatus = (rowData) => {
    return (
      <>
        { 
         rowData.JulStatus !== null ?
         (rowData.JulStatus).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) 
         :
         (<>&nbsp;</>)
        }
      </>
    );
  };

  const renderAugStatus = (rowData) => {
    return (
      <>
        { 
         rowData.AugStatus !== null ?
         (rowData.AugStatus).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) 
         :
         (<>&nbsp;</>)
        }
      </>
    );
  };

  const renderSepStatus = (rowData) => {
    return (
      <>
        { 
         rowData.SepStatus !== null ?
         (rowData.SepStatus).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) 
         :
         (<>&nbsp;</>)
        }
      </>
    );
  };

  const renderOctStatus = (rowData) => {
    return (
      <>
        { 
         rowData.OctStatus !== null ?
         (rowData.OctStatus).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) 
         :
         (<>&nbsp;</>)
        }
      </>
    );
  };

  const renderNovStatus = (rowData) => {
    return (
      <>
        { 
         rowData.NovStatus !== null ?
         (rowData.NovStatus).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) 
         :
         (<>&nbsp;</>)
        }
      </>
    );
  };

  const renderDecStatus = (rowData) => {
    return (
      <>
        { 
         rowData.DecStatus !== null ?
         (rowData.DecStatus).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) 
         :
         (<>&nbsp;</>)
        }
      </>
    );
  };

  return (
    <>
      <Card>
        <CardBody>   
          <Row>
            <Col>
              <h3><span className="score-square-ScoreCardInfo">{t('focusReport:ScoreCard')}</span></h3>
            </Col>
            <Col className="text-right">   
              <h3><span className="score-square-ScoreCardInfo">{t('focusReport:AnnualAvg')}: &nbsp;</span>{ProjectActivitiesAnnualAvg}</h3>
            </Col>
          </Row>       
          <Row className="align-items-start ">
            <Col lg={12} md={12} sm={12} xs={12}>
              <FieldDataTable
                value={ScoreCardDetailList}
                scrollable  
                className="table-header-background-ligth-green"  
                headerColumnGroup={headerScoreCardDetailGroup} 
                frozenHeaderColumnGroup={frozenHeaderColumnGroup} 
                footerColumnGroup={footer}
                frozenFooterColumnGroup={footerFrozenColumnGroup}
                paginator={false}
                frozenWidth="350px"  
              >
                <Column
                  field="IndexType"
                  header={t('focusReport:IndexType')} 
                  columnKey="IndexType"
                  headerStyle={{ width: '50px', textAlign: 'center' }}  
                  frozen={true} 
                >
                </Column>
                <Column
                  field="Code" 
                  header={t('focusReport:Code')}
                  columnKey="Code"
                  headerStyle={{ width: '40px', textAlign: 'center' }}  
                  frozen={true} 
                >                  
                </Column>
                <Column
                  field="Description"
                  header={t('focusReport:Description')}
                  columnKey="Description"
                  headerStyle={{ width: '260px' }}
                  className="border-right overflow-ellipsis"  
                  frozen={true} 
                  body={renderDescription}
                >
                </Column>
                <Column
                  field="JanWeight" 
                  header={t('focusReport:Jan')}
                  columnKey="JanWeight"
                  headerStyle={{ width: '70px', textAlign: 'center' }}
                  className="text-center"
                  
                >
                </Column>
                <Column
                  field="JanStatus" 
                  header={t('focusReport:Jan')}
                  columnKey="JanStatus"
                  headerStyle={{ width: '115px', textAlign: 'center' }}
                  className="text-center"
                  body={renderJanStatus}  
                >
                </Column>
                <Column
                  field="JanScore" 
                  header={t('focusReport:Jan')}
                  columnKey="JanScore"
                  headerStyle={{ width: '90px', textAlign: 'center' }}
                  className="table-header-background-dark-green border-right text-center"
                  
                >
                </Column>
                <Column
                  field="FebWeight"
                  className="text-center"
                  header={t('focusReport:Jan')}
                  columnKey="FebWeight"
                  headerStyle={{ width: '70px', textAlign: 'center' }}
                >
                </Column>
                <Column
                  field="FebStatus"
                  className="text-center"
                  header={t('focusReport:Jan')}
                  columnKey="FebStatus"
                  headerStyle={{ width: '115px', textAlign: 'center' }}
                  body={renderFebStatus}  
                  
                >
                </Column>
                <Column
                  field="FebScore" 
                  header={t('focusReport:Jan')}
                  columnKey="FebScore"
                  headerStyle={{ width: '90px', textAlign: 'center' }}
                  className="text-center table-header-background-dark-green border-right"
                  
                >
                </Column>
                <Column
                  field="MarWeight" 
                  header={t('focusReport:Jan')}
                  columnKey="MarWeight"
                  headerStyle={{ width: '70px', textAlign: 'center' }}
                  className="text-center"
                  
                >
                </Column>
                <Column
                  field="MarStatus" 
                  header={t('focusReport:Jan')}
                  columnKey="MarStatus"
                  headerStyle={{ width: '115px', textAlign: 'center' }}
                  className="text-center"
                  body={renderMarStatus}  
                  
                >
                </Column>
                <Column
                  field="MarScore" 
                  header={t('focusReport:Jan')}
                  columnKey="MarScore"
                  headerStyle={{ width: '90px', textAlign: 'center' }}
                  className="table-header-background-dark-green border-right text-center" 
                >
                </Column>
                <Column
                  field="AprWeight"
                  className="text-center"
                  header={t('focusReport:Jan')}
                  columnKey="AprWeight"
                  headerStyle={{ width: '70px', textAlign: 'center' }}
                  
                >
                </Column>
                <Column
                  field="AprStatus"
                  className="text-center"
                  header={t('focusReport:Jan')}
                  columnKey="AprStatus"
                  headerStyle={{ width: '115px', textAlign: 'center' }}
                  body={renderAprStatus}  
                  
                >
                </Column>
                <Column
                  field="AprScore" 
                  header={t('focusReport:Jan')}
                  columnKey="AprScore"
                  headerStyle={{ width: '90px', textAlign: 'center' }}
                  className="text-center table-header-background-dark-green border-right"
                  
                >
                </Column>               
                <Column
                  field="MayWeight"
                  className="text-center"
                  header={t('focusReport:Jan')}
                  columnKey="MayWeight"
                  headerStyle={{ width: '70px', textAlign: 'center' }}
                  
                >
                </Column>
                <Column
                  field="MayStatus"
                  className="text-center"
                  header={t('focusReport:Jan')}
                  columnKey="MayStatus"
                  headerStyle={{ width: '115px', textAlign: 'center' }}
                  body={renderMayStatus}  
                  
                >
                </Column>
                <Column
                  field="MayScore" 
                  header={t('focusReport:Jan')}
                  columnKey="MayScore"
                  headerStyle={{ width: '90px', textAlign: 'center' }}
                  className="text-center table-header-background-dark-green border-right"
                  
                >
                </Column>
                <Column
                  field="JunWeight"
                  className="text-center"
                  header={t('focusReport:Jan')}
                  columnKey="JunWeight"
                  headerStyle={{ width: '70px', textAlign: 'center' }}
                  
                >
                </Column>
                <Column
                  field="JunStatus"
                  className="text-center"
                  header={t('focusReport:Jan')}
                  columnKey="JunStatus"
                  headerStyle={{ width: '115px', textAlign: 'center' }}
                  body={renderJunStatus}  
                  
                >
                </Column>
                <Column
                  field="JunScore" 
                  header={t('focusReport:Jan')}
                  columnKey="JunScore"
                  headerStyle={{ width: '90px', textAlign: 'center' }}
                  className="text-center table-header-background-dark-green border-right"
                  
                >
                </Column>
                <Column
                  field="JulWeight"
                  className="text-center"
                  header={t('focusReport:Jan')}
                  columnKey="JulWeight"
                  headerStyle={{ width: '70px', textAlign: 'center' }}
                  
                >
                </Column>
                <Column
                  field="JulStatus"
                  className="text-center"
                  header={t('focusReport:Jan')}
                  columnKey="JulStatus"
                  headerStyle={{ width: '115px', textAlign: 'center' }}
                  body={renderJulStatus}  
                  
                >
                </Column>
                <Column
                  field="JulScore" 
                  header={t('focusReport:Jan')}
                  columnKey="JulScore"
                  headerStyle={{ width: '90px', textAlign: 'center' }}
                  className="text-center table-header-background-dark-green border-right"
                  
                >
                </Column>
                <Column
                  field="AugWeight"
                  className="text-center"
                  header={t('focusReport:Jan')}
                  columnKey="AugWeight"
                  headerStyle={{ width: '70px', textAlign: 'center' }}
                  
                >
                </Column>
                <Column
                  field="AugStatus"
                  className="text-center"
                  header={t('focusReport:Jan')}
                  columnKey="AugStatus"
                  headerStyle={{ width: '115px', textAlign: 'center' }}
                  body={renderAugStatus}  
                  
                >
                </Column>
                <Column
                  field="AugScore" 
                  header={t('focusReport:Jan')}
                  columnKey="AugScore"
                  headerStyle={{ width: '90px', textAlign: 'center' }}
                  className="text-center table-header-background-dark-green border-right"
                  
                >
                </Column>
                <Column
                  field="SepWeight"
                  className="text-center"
                  header={t('focusReport:Jan')}
                  columnKey="SepWeight"
                  headerStyle={{ width: '70px', textAlign: 'center' }}
                  
                >
                </Column>
                <Column
                  field="SepStatus"
                  className="text-center"
                  header={t('focusReport:Jan')}
                  columnKey="SepStatus"
                  headerStyle={{ width: '115px', textAlign: 'center' }}
                  body={renderSepStatus}  
                  
                >
                </Column>
                <Column
                  field="SepScore" 
                  header={t('focusReport:Jan')}
                  columnKey="SepScore"
                  headerStyle={{ width: '90px', textAlign: 'center' }}
                  className="text-center table-header-background-dark-green border-right"
                  
                >
                </Column>
                <Column
                  field="OctWeight"
                  className="text-center"
                  header={t('focusReport:Jan')}
                  columnKey="OctWeight"
                  headerStyle={{ width: '70px', textAlign: 'center' }}
                  
                >
                </Column>
                <Column
                  field="OctStatus"
                  className="text-center"
                  header={t('focusReport:Jan')}
                  columnKey="OctStatus"
                  headerStyle={{ width: '115px', textAlign: 'center' }}
                  body={renderOctStatus}  
                  
                >
                </Column>
                <Column
                  field="OctScore" 
                  header={t('focusReport:Jan')}
                  columnKey="OctScore"
                  headerStyle={{ width: '90px', textAlign: 'center' }}
                  className="text-center table-header-background-dark-green border-right"
                  
                >
                </Column>
                <Column
                  field="NovWeight"
                  className="text-center"
                  header={t('focusReport:Jan')}
                  columnKey="NovWeight"
                  headerStyle={{ width: '70px', textAlign: 'center' }}
                  
                >
                </Column>
                <Column
                  field="NovStatus"
                  className="text-center"
                  header={t('focusReport:Jan')}
                  columnKey="NovStatus"
                  headerStyle={{ width: '115px', textAlign: 'center' }}
                  body={renderNovStatus}  
                  
                >
                </Column>
                <Column
                  field="NovScore" 
                  header={t('focusReport:Jan')}
                  columnKey="NovScore"
                  headerStyle={{ width: '90px', textAlign: 'center' }}
                  className="text-center table-header-background-dark-green border-right"
                  
                >
                </Column>
                <Column
                  field="DecWeight"
                  className="text-center"
                  header={t('focusReport:Jan')}
                  columnKey="DecWeight"
                  headerStyle={{ width: '70px', textAlign: 'center' }}
                  
                >
                </Column>
                <Column
                  field="DecStatus"
                  className="text-center"
                  header={t('focusReport:Jan')}
                  columnKey="DecStatus"
                  headerStyle={{ width: '115px', textAlign: 'center' }}
                  body={renderDecStatus}  
                  
                >
                </Column>
                <Column
                  field="DecScore" 
                  header={t('focusReport:Jan')}
                  columnKey="DecScore"
                  headerStyle={{ width: '90px', textAlign: 'center' }} 
                  className="text-center"
                >
                </Column>
              </FieldDataTable>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default ScoreCardAnnualDetail;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { config } from '../../../utils/config';
import { callApi,showSweetAlert} from '../../../utils/utils'; 
import React, { useState, useEffect } from 'react'; 
import { useTranslation } from 'react-i18next'; 
import { Button } from 'primereact/button';
import { Row, Col} from 'reactstrap';   
import { Panel } from 'primereact/panel';
import FieldText from '../../../components/Controls/FieldText.jsx'
import YupValidator from '../../../utils/YupValidator';
import { Dialog } from 'primereact/dialog'; 
import * as yup from 'yup';
import moment from 'moment';  
import FieldTextArea from 'components/Controls/FieldTextArea';

const FolderDetail = (props) => { 
 
  /* ################## useState area start ################## */
  const [errors, setErrors] = useState({});
  const [ClaFolder, setClaFolder] = useState(null); 
  const [sJerarquia, setsJerarquia] = useState(null); 
  const [ClaFolderPadre, setClaFolderPadre] = useState(null);  
  const [NomFolder, setNomFolder] = useState(null);  
  const [NomFolderDescripcion, setNomFolderDescripcion] = useState(null);   
  const { t } = useTranslation(['dms', 'common']);
   
  useEffect(() => {   
    console.log('detail',props);
    getCategoriaId(props.ClaFolder);   
  }, []); 

  const getCategoriaId = (pClaFolder) => {
    const GetServiceConductor = `${config.UrlApiProject}DMS/GetFolderDetail?ClaFolder=${pClaFolder}&Language=""`;  
    const paramsFilter = {};   
    callApi(GetServiceConductor, 'GET', paramsFilter, (response) => { 
      const folder = response.data.Folder[0];
      if(folder!==undefined){
        setClaFolder(folder.ClaFolder);
        setClaFolderPadre(folder.ClaFolderPadre);
        setNomFolder(folder.NomFolder);
        setNomFolderDescripcion(folder.NomFolderDescripcion);
        setsJerarquia(folder.Jerarquia);
        if(props.EsNuevo){
          setClaFolderPadre(folder.ClaFolder);
          setClaFolder(0);
          setNomFolder(null);
          setNomFolderDescripcion(null);
        }
      }else{
        setClaFolder(0);
        setClaFolderPadre(0);
      }
    });
  }

  const valitationScheme = yup.object().shape({
    NomFolder: yup.string().required(t('common:RequiredField')).nullable(),
    NomFolderDescripcion: yup.string().required(t('common:RequiredField')).nullable()
  });

  const guardar = async () => {
    const daterequired = {
      NomFolder,
      NomFolderDescripcion,
    }
    const validator = new YupValidator(valitationScheme);
    const esValido = await validator.validate(daterequired);
    if (!esValido){ 
      setErrors(validator.errors); 
      return;
    }
    const urlWebServiceSave = `${config.UrlApiProject}DMS/PostCatFolder`;
    const paramsService = {
      ClaFolder,
      ClaFolderPadre,
      NomFolder,
      NomFolderDescripcion,
    }; 
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {
      showSweetAlert(t('common:Exito'), t('common:SaveSuccess'), 'success');
      if (props.onAfterReturn !=  null){ 
        props.onAfterReturn();
      }
    });  
  }

  const regresar = () => {
    if (props.onAfterReturn !=  null){ 
      props.onAfterReturn();
    }
  }

  const onChangeNomFolder = (e) => {     
    const newErrors = { ...errors } 
    if(e.target.value.match(/[/:*?"<>|\\]/)){
        setErrors(newErrors)  
    }else{
        
    delete newErrors.NomFolder;   
    setErrors(newErrors)  
    setNomFolder(e.target.value)
    }
    
  }
  
  const onChangeNomFolderDescripcion = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.NomFolderDescripcion;   
    setErrors(newErrors)  
    setNomFolderDescripcion(e.target.value)
  }

  const renderHeader = () => {
    return (
      <> 
        <h2>
          {sJerarquia}  
          {
                            ClaFolder>0 ? (
                              <>
                                <span>{t('dms:SimboloMayorQue')}</span>{t('common:EDIT')}
                              </>
                            ):(
                              <>
                                <span hidden={ClaFolderPadre===0}>{t('dms:SimboloMayorQue')}</span>{t('common:NEW')}
                              </>
                            )
                          }
        </h2>   
      </>
    );
}

  const renderFooter = () => {
    return (
      <>  
        <div className="text-center">
          {/* <Button
            label={t('common:Return')}
            icon="fa fa-arrow-left" 
            onClick={props.onHide}
            className="warning no-border"
          /> &nbsp; */}
          <Button
            label={t('common:Save')}
            icon="fa fa-save" 
            onClick={guardar}
            className="light-green no-border p-mr-2 rounded-button"
          />
        </div>
      </>
    );
}

    return (
      <>   
        <Dialog visible={props.show} modal style={{width: '60vw' }} footer={renderFooter} header={renderHeader} onHide={props.onHide}>   
          <Row className='align-items-start'>
            <Col lg={12} md={12} sm={12} xs={12}> 
              <Row>
                <Col>
                &nbsp;
                </Col>  
              </Row>              
              <Row className='align-items-start'>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <span> {t('dms:Folder')}</span>                   
                </Col> 
              </Row> 
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className="p-fluid p-field p-col-12"> 
                    <FieldText  
                      name="NomFolder"
                      value={NomFolder}
                      errors={errors}
                      onChange={onChangeNomFolder}
                      maxLength={250}
                    /> 
                  </div>                     
                </Col>  
              </Row>  
              <Row className='align-items-start'>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <span> {t('dms:FolderDescription')}</span>                   
                </Col> 
              </Row>  
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className="p-fluid p-field p-col-12"> 
                    <FieldTextArea 
                      name="NomFolderDescripcion"
                      rows={5}
                      placeholder=''
                      maxLength={500}
                      value={NomFolderDescripcion}
                      onChange={onChangeNomFolderDescripcion}
                      errors={errors}
                    /> 
                  </div>
                </Col>  
              </Row>               
            </Col> 
          </Row>
        </Dialog>
      </>  
        ); 
};

export default FolderDetail;

/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PageContent from '../../../../layouts/PageContent';
import { Row, Col, Card, CardBody, CardHeader, CardTitle, CardFooter } from 'reactstrap'; 
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../../utils/utils';
import * as yup from 'yup';
import YupValidator from '../../../../utils/YupValidator';
import swal from '@sweetalert/with-react';
import SweatAlertCustomIcon from '../../../../components/SweatAlert/SweatAlertCustomIcon';
import moment from 'moment';
import FieldButton from '../../../../components/Controls/FieldButton/FieldButton.jsx';
import { Panel } from 'primereact/panel';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import SurveyManagementNew from './SurveyManagementNew';
import SurveyManagementEdit from './SurveyManagementEdit'; 
import { Tree } from 'primereact/tree';
import { SplitButton } from 'primereact/splitbutton';
import SurveyInsights from '../SurveyAnalyze/SurveyInsights';
import FieldChips from 'components/Controls/FieldChips/FieldChips.jsx';
import SurveyManagementCollectResponses from './SurveyManagementCollectResponses.jsx'; 
import { Route, Redirect, Link, useHistory } from 'react-router-dom';

const SurveyManagementList = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['survey', 'common']);
  const [CategoriaListCmb, setCategoriaListCmb] = useState([]);
  const [EncuestaList, setEncuestaList] = useState([]);
  const [ClaEncuestaCategoria, setClaEncuestaCategoria] = useState(null);
  const [EsMostrarNewSurvey, setEsMostrarNewSurvey] = useState(false);
  const [IdEncuesta, setIdEncuesta] = useState(null);
  const [EsMostrarListSurvey, setEsMostrarListSurvey] = useState(true);
  const [EsEnviarMail, setEsEnviarMail] = useState(false); 
  const [EsMostrarInsights, setEsMostrarInsights] = useState(false);
  const history = useHistory(); 
   

  useEffect(() => {
    obtenerCategoryCmb();
    obtenerEncuestaList(null); 
  }, []);

  const obtenerCategoryCmb = () => {
    const getCategoryCmb = `${config.UrlApiProject}HR/GetCategoryCmb`;
    const paramsToService = {};
    callApi(getCategoryCmb, 'GET', paramsToService, (response) => {
      setCategoriaListCmb(response.data.Category);
    });
  };
  const obtenerEncuestaList = (pClaCategory) => {
    const GetCategoryList = `${config.UrlApiProject}HR/GetHRSurveyManagementList`;
    const paramsToService = {
      ClaEncuestaCategoria: pClaCategory,
    };
    callApi(GetCategoryList, 'POST', paramsToService, (response) => {
      setEncuestaList(response.data.SurveyManagementList);
    });
  };
  const onChangeCategory = (props, e) => {
    setClaEncuestaCategoria(e.target.value);
    // obtenerEncuestaList(e.target.value);
  };

  const onNewSurvey = () => {
    setEsMostrarNewSurvey(true);
  };

  const dialogStyle = {
    width: '80vw',
  };

  const closeModalNewSurvey = () => {
    setEsMostrarNewSurvey(false);
  };

  const filterHeaderTemplate = (options) => {
    const toggleIcon = options.collapsed
      ? 'pi pi-chevron-down text-white'
      : 'pi pi-chevron-up text-white';
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className="card-header">
        <div className="p-toolbar p-component">
          <div className="p-toolbar-group-left">
            <div className="card-title">
              <FieldButton className={options.togglerClassName} onClick={options.onTogglerClick}>
                <span className={toggleIcon}></span>
              </FieldButton>
              <span className={titleClassName}>{t('common:Filters')}</span>
            </div>
          </div>
          <div className="p-toolbar-group-right">
            <FieldButton
              label={t('survey:NewSurvey')}
              icon="pi pi-plus"
              className="light-green no-border p-mr-2"
              onClick={onNewSurvey}
            />
            &nbsp;
            <FieldButton
              label={t('survey:Search')}
              icon="pi pi-search"
              className="p-button p-highlight"
              onClick={(e) => obtenerEncuestaList(ClaEncuestaCategoria)}
            >
            </FieldButton>
          </div>
        </div>
      </div>
    );
  };

  const FechaCreacion = (rowData) => {
    return <>{moment(rowData.FechaCreacion).format('MMMM DD, YYYY h:mm:ss a')}</>;
  };

  const FechaModificacion = (rowData) => {
    return <>{moment(rowData.FechaModificacion).format('MMMM DD, YYYY h:mm:ss a')}</>;
  };

  const EditarTemplate = (row) => {
    return (
      <>
        <FieldButton
          icon="pi pi-pencil"
          onClick={() => onClickEditarSurvey(row)}
          className="p-button-rounded p-button-primary no-border"
          tooltip={t('survey:EditSurvey')}
        />
      </>
    );
  };

  const CollectTemplate = (row) => {

    if (row.EsEncuestaProcesoInterno === 1) {
      return (
        <div title={t('survey:CollectResponsesNotAvailable')}>
          <FieldButton
            icon="fa fa-paper-plane" 
            className="p-button-rounded info no-border"
            disabled={true}
            hidden={!row.EsTienePreguntasConfiguradas}
          />
        </div>
)
    }
    return (
      <>
        <FieldButton
          icon="fa fa-paper-plane"
          onClick={() => onClickSendEmailSurvey(row)}
          className="p-button-rounded info no-border"
          tooltip={t('survey:CollectResponses')}
          hidden={!row.EsTienePreguntasConfiguradas}
        />
      </>
    );
  };

  const TituloTemplate = (row) => {
    return (
      <>
        <div>
          <span className="bold">{row.Titulo}</span>{' '}
        </div>
        <div> 
          <small>{row.NomResponsable}</small>
        </div>
        <div> 
          <small><i>{t('survey:Created')}&nbsp;{FechaCreacion(row)}</i></small>
        </div>
      </>
    );
  };

  const onClickEditarSurvey = (row) => {
    setIdEncuesta(row.IdEncuesta);
    setEsMostrarListSurvey(false);
    setEsMostrarInsights(false);
  };
  const onClickEditarSurveyInsights = (row) => {
    // setIdEncuesta(row.IdEncuesta);
    // setEsMostrarInsights(true);
    // setEsMostrarListSurvey(true);
    history.push({
      pathname: `./SurveyAnalyzeScreen/${  row.IdEncuesta}` 
    });
  };

  const onSearch = () => {
    obtenerEncuestaList(null);
    setEsMostrarListSurvey(true);
    setEsMostrarInsights(false);
  };

  const onClickSendEmailSurvey = (row) => {
    setIdEncuesta(row.IdEncuesta);
    setEsEnviarMail(true);
  }; 
  
  const closeModalEnviarMail = () => {
    setEsEnviarMail(false);
  }; 

  

  const BotonesTemplate = (rowData) => {
    return (
      <>
        <SplitButton
          className="hidebutton"
          buttonTemplate="danger"
          dropdownIcon="pi pi-bars"
          model={dataInfo(rowData)}
        >
        </SplitButton>
      </>
    );
  };

  const BotonesGrafTemplate = (rowData) => {
    return (
      <>
        <FieldButton
          icon="fas fa-chart-bar"
          onClick={() => onClickEditarSurveyInsights(rowData)}
          className="p-button-rounded orange no-border"
          tooltip={t('survey:SurveyAnalyze')}
        />       
      </>
    );
  };

  const dataInfo = (rowData) => {
    const arreglo = [];
    // rowData.Opciones.forEach((item) => {
      const arrItemExport = {
        label: t('survey:Export'),
        icon: 'fa fa-file-excel',
        command: () => {
          ExportExcelSurvey(rowData.IdEncuesta);
        },
      };
      arreglo.push(arrItemExport);

    const arrItemSave = {
      label: t('survey:copySurvey'),
      icon: 'pi pi-copy',
      command: () => {
        onConfirmSubmit(rowData.IdEncuesta);
      },
    };
    arreglo.push(arrItemSave);

    const arrItemDelete = {
      label: t('survey:delSurvey'),
      icon: 'pi pi-trash',
      command: () => {
        onConfirmDelete(rowData.IdEncuesta);
      },
    };
    arreglo.push(arrItemDelete);

    if ((rowData.EsEncuestaProcesoInterno === false || rowData.EsEncuestaProcesoInterno === 0) && !rowData.MultiplesRespuestasPorDispositivo){
      const multiplesResponses = {
        label: t('survey:AllowMultipleResponses'),
        icon: 'pi pi-user-plus',
        command: () => {
          onConfirmAllowMultiplesResponses(rowData.IdEncuesta);
        },
      };
      arreglo.push(multiplesResponses);
    }
    
    if ((rowData.EsEncuestaProcesoInterno === false || rowData.EsEncuestaProcesoInterno === 0) && rowData.MultiplesRespuestasPorDispositivo){
      const NotmultiplesResponses = {
        label: t('survey:NotAllowMultipleResponses'),
        icon: 'pi pi-user',
        command: () => {
          onConfirmNotAllowMultiplesResponses(rowData.IdEncuesta);
        },
      };
      arreglo.push(NotmultiplesResponses);
    }

    if (rowData.EsEncuestaCerrada){
      const openSurvey = {
        label: t('survey:OpenSurvey'),
        icon: 'pi pi-lock-open',
        command: () => {
          onConfirmOpenSurvey(rowData.IdEncuesta);
        },
      };
      arreglo.push(openSurvey);
    }
    else {
      const closeSurvey = {
        label: t('survey:CloseSurvey'),
        icon: 'pi pi-lock',
        command: () => {
          onConfirmCloseSurvey(rowData.IdEncuesta);
        },
      };
      arreglo.push(closeSurvey);
    }
    // })
    return arreglo;
  };

  const onConfirmSubmit = (pIdEncuesta) => {
    swal({
      buttons: {
        cancelar: {
          text: t('common:Cancel'),
          value: 'cancel',
          className: 'rounded-button danger',
        },
        continuar: {
          text: t('common:Continue'),
          value: 'continue',
          className: 'rounded-button light-green',
        },
      },
      content: confirmationCopyMessageHTML,
    }).then((value) => {
      switch (value) {
        case 'continue':
          saveCopySurvey(pIdEncuesta);
          break;
        default:
          break;
      }
    });
  };

  const onConfirmDelete = (pIdEncuesta) => {
    swal({
      buttons: {
        cancelar: {
          text: t('common:Cancel'),
          value: 'cancel',
          className: 'rounded-button danger',
        },
        continuar: {
          text: t('common:Continue'),
          value: 'continue',
          className: 'rounded-button light-green',
        },
      },
      content: confirmationMessageHTML,
    }).then((value) => {
      switch (value) {
        case 'continue':
          bajaSurveyManagement(pIdEncuesta);
          break;
        default:
          break;
      }
    });
  };  

  const onConfirmAllowMultiplesResponses = (pIdEncuesta) => {
    swal({
      buttons: {
        cancelar: {
          text: t('common:Cancel'),
          value: 'cancel',
          className: 'rounded-button danger',
        },
        continuar: {
          text: t('common:Continue'),
          value: 'continue',
          className: 'rounded-button light-green',
        },
      },
      content: confirmationMultipleResponsesSurveyMessageHTML,
    }).then((value) => {
      switch (value) {
        case 'continue':
          allowMultiplesResponsesPerDevice(pIdEncuesta);
          break;
        default:
          break;
      }
    });
  };  

  const onConfirmNotAllowMultiplesResponses = (pIdEncuesta) => {
    swal({
      buttons: {
        cancelar: {
          text: t('common:Cancel'),
          value: 'cancel',
          className: 'rounded-button danger',
        },
        continuar: {
          text: t('common:Continue'),
          value: 'continue',
          className: 'rounded-button light-green',
        },
      },
      content: confirmationMultipleResponsesSurveyOffMessageHTML,
    }).then((value) => {
      switch (value) {
        case 'continue':
          NotallowMultiplesResponsesPerDevice(pIdEncuesta);
          break;
        default:
          break;
      }
    });
  };  

  const onConfirmOpenSurvey = (pIdEncuesta) => {
    swal({
      buttons: {
        cancelar: {
          text: t('common:Cancel'),
          value: 'cancel',
          className: 'rounded-button danger',
        },
        continuar: {
          text: t('common:Continue'),
          value: 'continue',
          className: 'rounded-button light-green',
        },
      },
      content: confirmationOpenMessageHTML,
    }).then((value) => {
      switch (value) {
        case 'continue':
          openSurvey(pIdEncuesta);
          break;
        default:
          break;
      }
    });
  };  

  const onConfirmCloseSurvey = (pIdEncuesta) => {
    swal({
      buttons: {
        cancelar: {
          text: t('common:Cancel'),
          value: 'cancel',
          className: 'rounded-button danger',
        },
        continuar: {
          text: t('common:Continue'),
          value: 'continue',
          className: 'rounded-button light-green',
        },
      },
      content: confirmationCloseMessageHTML,
    }).then((value) => {
      switch (value) {
        case 'continue':
          closeSurvey(pIdEncuesta);
          break;
        default:
          break;
      }
    });
  };  
  

  
  const saveCopySurvey = (pIdEncuesta) => {
    const SaveSurvey = `${config.UrlApiProject}HR/SaveSurveyCopy`;
    const paramsService = {
      IdEncuesta: pIdEncuesta,
    };
    callApi(SaveSurvey, 'POST', paramsService, (response) => {
      onSearch();
    });
  };

  const bajaSurveyManagement = async (pIdEncuesta) => {
    const paramsService = {
      IdEncuesta: pIdEncuesta,
      Accion: 1,
    };
    const SaveSurvey = `${config.UrlApiProject}HR/SaveSurvey`;

    callApi(SaveSurvey, 'POST', paramsService, (response) => {
      onSearch();
    });
  };

  const allowMultiplesResponsesPerDevice = async (pIdEncuesta) => {
    const paramsService = {
      IdEncuesta: pIdEncuesta, 
      MultiplesRespuestasPorDispositivo :  true
    };
    const SaveSurvey = `${config.UrlApiProject}HR/SurveyMultiplesRespuestasPorDispositivoUpdate`;

    callApi(SaveSurvey, 'POST', paramsService, (response) => {
      onSearch();
    });
  };

  const NotallowMultiplesResponsesPerDevice = async (pIdEncuesta) => {
    const paramsService = {
      IdEncuesta: pIdEncuesta, 
      MultiplesRespuestasPorDispositivo :  false
    };
    const SaveSurvey = `${config.UrlApiProject}HR/SurveyMultiplesRespuestasPorDispositivoUpdate`;

    callApi(SaveSurvey, 'POST', paramsService, (response) => {
      onSearch();
    });
  };

  const openSurvey = async (pIdEncuesta) => {
    const paramsService = {
      IdEncuesta: pIdEncuesta ,
      EsEncuestaCerrada: false
    };
    const SaveSurvey = `${config.UrlApiProject}HR/SurveyEncuestaCerradaUpdate`;

    callApi(SaveSurvey, 'POST', paramsService, (response) => {
      onSearch();
    });
  };

  const closeSurvey = async (pIdEncuesta) => {
    const paramsService = {
      IdEncuesta: pIdEncuesta ,
      EsEncuestaCerrada: true
    };
    const SaveSurvey = `${config.UrlApiProject}HR/SurveyEncuestaCerradaUpdate`;

    callApi(SaveSurvey, 'POST', paramsService, (response) => {
      onSearch();
    });
  };

  

  const confirmationMessageHTML = (
    <div>
      <SweatAlertCustomIcon
        title={t('common:SubmitConfirmation')}
        letter={t('common:QuestionMark')}
      >
      </SweatAlertCustomIcon>
      <div>{t('survey:DeleteSurveyConfirmation')}.</div>
    </div>
  );

  const confirmationCopyMessageHTML = (
    <div>
      <SweatAlertCustomIcon
        title={t('common:SubmitConfirmation')}
        letter={t('common:QuestionMark')}
      >
      </SweatAlertCustomIcon>
      <div>{t('survey:CopySurveyConfirmation')}.</div>
    </div>
  );

  const confirmationCloseMessageHTML = (
    <div>
      <SweatAlertCustomIcon
        title={t('common:SubmitConfirmation')}
        letter={t('common:QuestionMark')}
      >
      </SweatAlertCustomIcon>
      <div>{t('survey:ConfirmCloseSurvey')}.</div>
    </div>
  );

  const confirmationOpenMessageHTML = (
    <div>
      <SweatAlertCustomIcon
        title={t('common:SubmitConfirmation')}
        letter={t('common:QuestionMark')}
      >
      </SweatAlertCustomIcon>
      <div>{t('survey:ConfirmOpenSurvey')}.</div>
    </div>
  );
   
  const confirmationMultipleResponsesSurveyMessageHTML = (
    <div>
      <SweatAlertCustomIcon
        title={t('common:SubmitConfirmation')}
        letter={t('common:QuestionMark')}
      >
      </SweatAlertCustomIcon>
      <div>{t('survey:ConfirmMultipleResponsesSurvey')}.</div>
    </div>
  );

  const confirmationMultipleResponsesSurveyOffMessageHTML = (
    <div>
      <SweatAlertCustomIcon
        title={t('common:SubmitConfirmation')}
        letter={t('common:QuestionMark')}
      >
      </SweatAlertCustomIcon>
      <div>{t('survey:ConfirmMultipleResponsesOffSurvey')}.</div>
    </div>
  );

  const ExportExcelSurvey = (pIdEncuesta) => { 
    const ExportarSurvey = `${config.UrlApiProject}HR/ExportarSurvey`;        
    const paramsService = {
      IdEncuesta: pIdEncuesta
    };
    callApi(ExportarSurvey, 'POST', paramsService, (response) => {
      const linkSource = `data:application/${response.data.FileExtension};base64,${response.data.FileData}`;
      const downloadLink = document.createElement("a");
      const fileName = `${response.data.FileName}`;
      downloadLink.href = linkSource;
      downloadLink.download = `${fileName  }.${  response.data.FileExtension}`;
      downloadLink.click();
    });    
  };


  if (EsMostrarListSurvey) {
    return (
      <>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Panel header={t('common:Filters')} toggleable headerTemplate={filterHeaderTemplate}>
              <Row>
                <Col lg={4} md={4} sm={12} xs={12} className="p-3">
                  <div className="p-fluid">
                    <FieldDropdown
                      name="ClaEncuestaCategoria"
                      label={t('survey:Category')}
                      value={ClaEncuestaCategoria}
                      options={CategoriaListCmb}
                      optionValue="ClaEncuestaCategoria"
                      optionLabel="NomEncuestaCategoria"
                      onChange={(e) => onChangeCategory(props, e)}
                    />
                  </div>
                </Col>
              </Row>
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            &nbsp;
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <FieldDataTable
              value={EncuestaList}
              scrollable 
              className="editable-cells-table"
              editMode="cell"
              selectionMode="single"
            >
              <Column
                field="Titulo"
                header={t('survey:SurveyTile')}
                // columnKey="Titulo"
                headerStyle={{ width: '250px' }}
                body={TituloTemplate}
              >
              </Column>
              <Column
                field="FechaModificacion"
                header={t('survey:LastUpd')}
                body={FechaModificacion}
                headerStyle={{ width: '110px' }}
              >
              </Column>
              <Column
                field="Categoria"
                header={t('survey:Category')}
                columnKey="Categoria"
                headerStyle={{ width: '250px' }}
              >
              </Column>
              <Column
                field="EstatusEncuesta"
                header={t('survey:EstatusEncuesta')}
                columnKey="EstatusEncuesta" 
                headerStyle={{ width: '90px' ,textAlign: 'center' }}
                style={{ textAlign: 'center' }}
              >
              </Column>
              <Column
                field="TotalRespuesta"
                header={t('survey:Responses')}
                columnKey="TotalRespuesta" 
                headerStyle={{ width: '90px' ,textAlign: 'center' }}
                style={{ textAlign: 'center' }}
              >
              </Column>
              
              <Column
                body={CollectTemplate}
                className="text-center"
                header={t('survey:Collect')}
                headerStyle={{ width: '30px' ,textAlign: 'center' }}
                style={{ textAlign: 'center' }}
              >
              </Column>
              <Column
                body={BotonesGrafTemplate}
                header={t('survey:Analyze')}
                style={{ textAlign: 'center' }}
                headerStyle={{ width: '30px' ,textAlign: 'center' }} 
              >
              </Column>
              <Column
                body={EditarTemplate}
                className="text-center"
                header={t('survey:Edit')}
                headerStyle={{ width: '30px',textAlign: 'center' }}
                style={{ textAlign: 'center' }}
              >
              </Column>
              <Column
                body={BotonesTemplate}
                style={{ textAlign: 'center' }}
                header={t('survey:More')}
                headerStyle={{ width: '30px',textAlign: 'center' }}
              >
              </Column>
              
            </FieldDataTable>
          </Col>
        </Row>
        <Dialog
          visible={EsMostrarNewSurvey}
          header={t('survey:NewSurvey')}
          style={dialogStyle}
          modal
          className="dialog-custom"
          onHide={closeModalNewSurvey}
        >
          <Row>
            <Col>
              <SurveyManagementNew closeModalNewSurvey={closeModalNewSurvey} onSearch={onSearch} />
            </Col>
          </Row>
        </Dialog> 
        {
          EsEnviarMail ?
          (<SurveyManagementCollectResponses IdEncuesta={IdEncuesta} Show={EsEnviarMail} onHide={closeModalEnviarMail}></SurveyManagementCollectResponses>)
          :
          (<></>)
        }
        
      </>
    );
  }
  return (
    <>
      {EsMostrarInsights ? (
        <>
          <SurveyInsights IdEncuesta={IdEncuesta} onSearch={onSearch}></SurveyInsights>
        </>
      ) : (
        <>
          <SurveyManagementEdit IdEncuesta={IdEncuesta} onSearch={onSearch}></SurveyManagementEdit>
        </>
      )}
    </>
  );
};

export default SurveyManagementList;

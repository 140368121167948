/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import DAISeerAnswer from './DAISeerAnswer/DAISeerAnswer';

// Props:
// - question
// - onLikePress
// - onUnlikePress
const DAISeerQuestion = (props) => {
  const {t, i18n} = useTranslation(['daiSeer', 'common']);  

  const onLikePress = () => {
    if (props.onLikePress) {
      props.onLikePress(props.question);
    }    
  }

  const onUnlikePress = () => {
    if (props.onUnlikePress) {
      props.onUnlikePress(props.question);
    }    
  }

  const avatarTemplate = (
    <>
      <p>
        <span className='primary p-2'>
          <b>
            {t("daiSeer:QuestionShortName")}
          </b>
        </span>
      </p>        
    </>
  );

  const questionTemplate = (
    <>
      <span style={{whiteSpace: 'pre-line'}}>
        {props.question.Pregunta}
      </span>
    </>
  );

  const answerTemplate = (
    <>
      <DAISeerAnswer             
        question={props.question}
        onLikePress={onLikePress}
        onUnlikePress={onUnlikePress}
      />      
    </>
  );

  const componentTemplate = (
    <> 
      <Row 
        className='align-items-start p-3'
        style={{ backgroundColor: '#F5F5F5'}}
      >
        <Col 
          xs={1}
          className='text-right'
        >
          {avatarTemplate}
        </Col>
        <Col xs={10}>
          {questionTemplate}
        </Col>        
      </Row>           
      <Row>
        <Col>
          {answerTemplate}          
        </Col>
      </Row>    
    </>
  );

  return (
    <>
      {componentTemplate}
    </>
  )
}

export default DAISeerQuestion
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next'; 
import PageContent from '../../../../layouts/PageContent';  
import { Row, Col, Card, CardBody, CardHeader, CardTitle, CardFooter } from 'reactstrap';   
import InstructionsCard from '../../../../components/Cards/InstructionsCard/InstructionsCard'; 
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../../utils/utils';   
import * as yup from 'yup';
import YupValidator from '../../../../utils/YupValidator'; 
import swal from '@sweetalert/with-react';
import SweatAlertCustomIcon from '../../../../components/SweatAlert/SweatAlertCustomIcon'
import moment from "moment";
import { Badge } from 'primereact/badge';
import Score from '../../../../components/Cards/Score/Score.jsx'
import FieldTextArea  from '../../../../components/Controls/FieldTextArea.jsx'; 
import FieldButton from '../../../../components/Controls/FieldButton/FieldButton.jsx';
import FieldCarousel from '../../../../components/Carousel/Carousel';
import ListItem from '../../../../components/ListGroup/ListItem';
import HighlighterText from '../../../../components/Controls/HighlighterText/HighlighterText';
import FieldToogleSwitch from '../../../../components/Controls/FieldToogleSwitch/FieldToogleSwitch';
import Signature from '../../../../components/Signature/Signature.jsx';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple'; 
import { color } from 'highcharts';
import EmployeeSelfReview from '../../CompanyCulture/EmployeeSelfReview/EmployeeSelfReview.jsx'
import ManagerReview from '../../CompanyCulture/ManagerReview/ManagerReview.jsx';
import ScoreCardSummaryDet from './ScoreCardSummaryDet.jsx';
import FieldPassword from '../../../../components/Controls/FieldPassword.jsx';
import FieldNumber from 'components/Controls/FieldNumber';
import { Column } from 'primereact/column';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';

const ReviewOfInformation = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);  
  
  const defaultAnio = props.Anio  !== undefined ? props.Anio : null;
  const defaultAnioCriteria = 2024;
  const defaultClaUsuario = props.ClaUsuario  !== undefined ? props.ClaUsuario : getSessionItem('ClaUsuarioMod', 0);;

  const usuarioConectado = getSessionItem('ClaUsuarioMod', 0);
     
  const [esInformacionDisponible, setEsInformacionDisponible] = useState(false); 
  const [esTienePermisoEdicion, setEsTienePermisoEdicion] = useState(0);  
  const [errors, setErrors] = useState({});  
  const [EmployeeSignature, setEmployeeSignature] = useState(null);
  const [ManagerSignature, setManagerSignature] = useState(null);
  const [EsEmployeeSelfReviewRevisado, setEsEmployeeSelfReviewRevisado] = useState(false); 
  const [EsManagerReviewRevisado, setEsManagerReviewRevisado] = useState(false);
  const [EsContribucionRevisado, setEsContribucionRevisado] = useState(false);
  const [PreviousStepsComplete, setPreviousStepsComplete] = useState(false);
  const [EstatusAnnualReview, setEstatusAnnualReview] = useState(0);
  const [ComentariosAnnualReview, setComentariosAnnualReview] = useState(null);
  const [CompromisosSiguienteAnioReview, setCompromisosSiguienteAnioReview] = useState(null);
  const [ComentariosCalificacionDesempenioFinal, setComentariosCalificacionDesempenioFinal] = useState(null);
  const [IDPSiguienteAnioObjetivos, setIDPSiguienteAnioObjetivos] = useState(null);
  
  const [idPerformanceDev, setIdPerformanceDev] = useState(null);
  const [FechaConfirmaRevisionAnual, setFechaConfirmaRevisionAnual] = useState(null);
  const [EmployeePasswordValid, setEmployeePasswordValid] = useState(false);
  const [ManagerPasswordValid, setManagerPasswordValid] = useState(false);
  const [EmployeePassword, setEmployeePassword] = useState(null);
  const [ManagerPassword, setManagerPassword] = useState(null);
  const [EmployeeLoginName, setEmployeeLoginName] = useState('');
  const [ManagerLoginName, setManagerLoginName] = useState('');
  
  const [yesNoOptions, setYesNoOptions] = useState([`${t('common:Yes')}`, `${t('common:No')}`]);
  const [SelfReviewCriteria, setSelfReviewCriteria] = useState([]);
  const [SelfReviewCriteriaScale, setSelfReviewCriteriaScale] = useState([]);
  const [AvgEmployeeTotal, setAvgEmployeeTotal] = useState(0);
  const [AvgManagerTotal, setAvgManagerTotal] = useState(0);
  const [AvgTotal, setAvgTotal] = useState(0);

  useEffect(() => {     
    loadAnnualReview();   
  }, [props.Anio, props.ClaUsuario]); 

  const loadAnnualReview = () => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}hr/GetReviewOfInformationUser`;  
    const paramsToService = {  
      ClaUsuario: defaultClaUsuario,
      Anio: defaultAnio
    };       

    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => { 
        
        setPreviousStepsComplete(false);
        if (response.data.Encabezado.length>0){  
          setAvgEmployeeTotal(obtienePromedio(response.data.InformationReviewCriteria,'CalificacionEmpleado'));
          setAvgManagerTotal(obtienePromedio(response.data.InformationReviewCriteria,'CalificacionManager'));
          setAvgTotal(obtienePromedio(response.data.InformationReviewCriteria,'CalificacionFinal'));

          setEsInformacionDisponible(response.data.Encabezado[0].ActivaAnnualReview); 
          setEsTienePermisoEdicion (response.data.Encabezado[0].ClaUsuarioManagerPerformanceDev === usuarioConectado);
          setEstatusAnnualReview(response.data.Encabezado[0].EstatusAnnualReview); 
          setComentariosAnnualReview(response.data.Encabezado[0].ComentariosAnnualReview); 
          setCompromisosSiguienteAnioReview(response.data.Encabezado[0].CompromisosSiguienteAnioReview);
          setComentariosCalificacionDesempenioFinal(response.data.Encabezado[0].ComentariosCalificacionDesempenioFinal);
          setIDPSiguienteAnioObjetivos(response.data.Encabezado[0].IDPSiguienteAnioObjetivos);
          setIdPerformanceDev(response.data.Encabezado[0].IdPerformanceDev);   
          setEsEmployeeSelfReviewRevisado(response.data.Encabezado[0].EsEmployeeSelfReviewRevisado === 1); 
          setEsManagerReviewRevisado(response.data.Encabezado[0].EsManagerReviewRevisado === 1 ); 
          setEsContribucionRevisado(response.data.Encabezado[0].EsContribucionRevisado === 1); 
          setFechaConfirmaRevisionAnual(response.data.Encabezado[0].FechaConfirmaRevisionAnual); 
          setManagerSignature(response.data.Encabezado[0].FirmaJefeDirecto);
          setEmployeeSignature(response.data.Encabezado[0].FirmaEmpleado);
          setEmployeeLoginName(response.data.Encabezado[0].EmployeeLoginName);
          setManagerLoginName(response.data.Encabezado[0].ManagerLoginName);

          if (response.data.Encabezado[0].EstatusEmployeeSelfReview === 1 &&
            response.data.Encabezado[0].EstatusManagerReview &&
            response.data.Encabezado[0].EstatusContribucionReview
            )
          {
            setPreviousStepsComplete(true);
          } 
        }  
        setSelfReviewCriteria(response.data.InformationReviewCriteria);
        setSelfReviewCriteriaScale([
        {
            Scale: t('hr:ScaleValueOutstanding'),
            PerformanceRating: t('hr:ScaleOutstanding')
        },
        {
          Scale: t('hr:ScaleValueExceeds'),
          PerformanceRating: t('hr:ScaleExceeds')
        },
        {
          Scale: t('hr:ScaleValueMeets'),
          PerformanceRating: t('hr:ScaleMeets')
        },
        {
          Scale: t('hr:ScaleValueNeedsImprovement'),
          PerformanceRating: t('hr:ScaleNeedsImprovement')
        },
        {
          Scale: t('hr:ScaleValueUnsatisfactory'),
          PerformanceRating: t('hr:ScaleUnsatisfactory')
          }
        ]);
        setErrors({});
    });
  }

  const onClearEmployeeSignture = () => { 
    setEmployeeSignature(null);
  } 

  const onClearManagerSignature = () => { 
    setManagerSignature(null);
  } 

  const EmployeeSelfReviewRevisado = () => { 
    return (
      <>
        <FieldToogleSwitch
          id="EsEmployeeSelfReviewRevisado"
          checked={EsEmployeeSelfReviewRevisado}  
          onChange={setEsEmployeeSelfReviewRevisado} 
          optionLabels={yesNoOptions}
          disabled={EstatusAnnualReview === 1}
        />
      </>
    );
  } 

  const ManagerReviewRevisado = () => { 
    return (
      <>
        <FieldToogleSwitch
          id="EsManagerReviewRevisado"
          checked={EsManagerReviewRevisado} 
          onChange={setEsManagerReviewRevisado} 
          optionLabels={yesNoOptions}
          disabled={EstatusAnnualReview === 1}
        />
      </>
    );
  } 
 
  const ContribucionRevisado = () => { 
    return (
      <> 
        <FieldToogleSwitch
          id="EsContribucionRevisado"
          checked={EsContribucionRevisado}  
          onChange={setEsContribucionRevisado} 
          optionLabels={yesNoOptions}
          disabled={EstatusAnnualReview === 1}
        />
      </>
    );
  } 

  const panelHeaderTemplate = (options, tipo) => {    
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
    const className = `${options.className} ${options.collapsed ? ' collapsed-header' : ''}`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}> 
        <div> 
          <FieldButton className={options.togglerClassName} onClick={options.onTogglerClick}>
            <span className={toggleIcon}></span>
            <Ripple />
          </FieldButton>
          <span className={titleClassName}>
            {options.props.header}
          </span>
        </div>
        <div className="p-panel-icons">
          <b style={{color:"#666666"}}>{t('hr:Reviewed')}</b>&nbsp;
          {tipo===1 ? <EmployeeSelfReviewRevisado></EmployeeSelfReviewRevisado> : <></>}
          {tipo===2 ? <ContribucionRevisado></ContribucionRevisado> : <></>}
          {tipo===3 ? <ManagerReviewRevisado></ManagerReviewRevisado> : <></>}
        </div> 
      </div>
    )
}

const onConfirmSubmit = async () => {  
    const value = {
      SelfReviewCriteria,
      CompromisosSiguienteAnioReview,
      ComentariosCalificacionDesempenioFinal,
      IDPSiguienteAnioObjetivos
    }
    const validator = new YupValidator(valitationScheme);
    const esValido = await validator.validate(value);
    
    if (!esValido){ 
        showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
        setErrors(validator.errors); 
        return;
    }

  swal({ 
    buttons: {
      cancelar: {
        text: "Cancel",
        value: "cancel",
        className: "rounded-button danger"
      },
      continuar: {
        text: "Continue",
        value: "continue",
        className: "rounded-button light-green"
      }
    },
    content: confirmationMessageHTML
  }).then((value) => {
    switch (value) { 
      case "continue":
        // run function
        onSubmit();
        break; 
      default:
        break;
    }
  });

}

const valitationScheme = yup.object().shape({
    SelfReviewCriteria: (
      defaultAnio>=defaultAnioCriteria
      ?  yup.array().of(
        yup.object().shape({
          CalificacionFinal: yup.mixed().required(t('common:RequiredField')).nullable(),
        })
      )
      : yup.array().of(
        yup.object().shape({
          CalificacionFinal: yup.mixed().nullable(),
        })
      )
    ),
    CompromisosSiguienteAnioReview: yup.string().trim().nullable()
      .when([], {
        is: () => defaultAnio >= defaultAnioCriteria,
        then: schema => schema.required(t('common:RequiredField'))
      }),
    ComentariosCalificacionDesempenioFinal: yup.string().trim().nullable()
      .when([], {
        is: () => defaultAnio >= defaultAnioCriteria,
        then: schema => schema.required(t('common:RequiredField'))
      }),
    IDPSiguienteAnioObjetivos: yup.string().trim().nullable()
      .when([], {
        is: () => defaultAnio >= defaultAnioCriteria,
        then: schema => schema.required(t('common:RequiredField'))
      })
}); 


const onSubmit = async () => { 
  /* 
   validamos la informacion requerida antes de hacer el submit
  */
   

   if (props.Anio === '2021') {  
      if (!EsContribucionRevisado || !EsManagerReviewRevisado || !EsEmployeeSelfReviewRevisado)
      {
        showSweetAlert(t('common:Oops'), t('hr:ReviewedRequired'), 'warning');
        return;
      }
      
  }
  else if (!EsManagerReviewRevisado || !EsEmployeeSelfReviewRevisado)
      {
        showSweetAlert(t('common:Oops'), t('hr:ReviewedRequired'), 'warning');
        return;
      }
  
  if (EmployeeSignature === null || ManagerSignature === null) { 
    showSweetAlert(t('common:Oops'), t('hr:SignaturesRequired'), 'warning');
    return;
  } 

  if (EmployeePasswordValid === false || ManagerPasswordValid === false) { 
    showSweetAlert(t('common:Oops'), t('hr:PasswordConfirmationRequired'), 'warning');
    return;
  } 
  
  const paramsService = {
    IdPerformanceDev: idPerformanceDev,
    Anio : defaultAnio
   ,ClaUsuarioPerformanceDev : defaultClaUsuario  
   ,EsContribucionRevisado : EsContribucionRevisado || props.Anio !== '2021' ? 1:0
   ,EsManagerReviewRevisado : EsManagerReviewRevisado ? 1:0
   ,EsEmployeeSelfReviewRevisado : EsEmployeeSelfReviewRevisado ? 1:0
   ,ComentariosAnnualReview
   ,CompromisosSiguienteAnioReview
   ,FirmaEmpleado : EmployeeSignature
   ,FirmaJefeDirecto : ManagerSignature
   ,EstatusAnnualReview: 1
   ,PerformanceDevCriterioCalificacion: SelfReviewCriteria
   ,ComentariosCalificacionDesempenioFinal
   ,IDPSiguienteAnioObjetivos
 };   

 const SubmitAnnualReview = `${config.UrlApiProject}HR/SubmitAnnualReview`;

 callApi(SubmitAnnualReview, 'POST', paramsService, (response) => { 
    showSweetAlert('success', t('common:SuccessOperation'), 'success');
    loadAnnualReview();   
 });   
}

const confirmationMessageHTML = (
  <div>
    <SweatAlertCustomIcon title={t('common:SubmitConfirmation')} letter={t('common:QuestionMark')}></SweatAlertCustomIcon> 
    <div>{t('hr:SubmitResponseConfirmationAnnualRev')}. <b>{t('common:ThanksParticipation')}</b></div> 
  </div>  
 )

 const onChangeEmployeePassword = (e) => { 
  const newErrors = { ...errors } 
  delete newErrors.EmployeePassword;   
  setErrors(newErrors) 
  setEmployeePassword(e.target.value)
}

const onChangeManagerPassword = (e) => { 
  const newErrors = { ...errors } 
  delete newErrors.ManagerPassword;   
  setErrors(newErrors) 
  setManagerPassword(e.target.value)
}

const valitationSchemeEmployeePassword = yup.object().shape({
  EmployeePassword: yup.string().trim().required(t('common:RequiredField')).nullable()
}); 

const valitationSchemeManagerPassword = yup.object().shape({
  ManagerPassword: yup.string().trim().required(t('common:RequiredField')).nullable()
}); 


const onConfirmEmployeePassword = async () => {
   
  const value = {
    EmployeePassword 
  }

  const validator = new YupValidator(valitationSchemeEmployeePassword);
  const esValido = await validator.validate(value);
   

  if (!esValido){ 
      setErrors(validator.errors); 
      return;
  }

    const urlWebService = `${config.UrlLoginServer}/authenticate`;

    const data = {
      username: EmployeeLoginName,
      password: EmployeePassword,
    }; 

    callApi(urlWebService, 'POST', data, (res) => {
      if (!res.token) {
        showSweetAlert('Error', res.mensaje, 'error');
      } else { 
        setEmployeePasswordValid(true);
      }
    });
    
}
 
const onConfirmManagerPassword = async () => {
   
    const value = {
      ManagerPassword 
    }
  
    const validator = new YupValidator(valitationSchemeManagerPassword);
    const esValido = await validator.validate(value);
     
  
    if (!esValido){ 
        setErrors(validator.errors); 
        return;
    }
    
    const urlWebService = `${config.UrlLoginServer}/authenticate`;

    const data = {
      username: ManagerLoginName,
      password: ManagerPassword,
    }; 

    callApi(urlWebService, 'POST', data, (res) => {
      if (!res.token) {
        showSweetAlert('Error', res.mensaje, 'error');
      } else { 
        setManagerPasswordValid(true);
      }
    }); 
  }

  const fieldsRequired = (rowData) =>  { 
    return (
      <>
        {errors[`SelfReviewCriteria[${rowData.RowIndex}]`] != null ? (<i className="fas fa-exclamation-triangle invalid-color" title={t('hr:FinalSelfReviewCriteriaRequired')}></i>) : ""}
      </>
    );
  } 

  const calificacionFinalByEditor = (props, rowData) => {
    return (
      <div className="p-fluid">
        <FieldNumber   
          mode="decimal"
          locale="en-US"
          value={props.rowData.CalificacionFinal} 
          minFractionDigits={1}
          maxFractionDigits={1}
          min={0}
          max={5}
          onChange={(e) => onCalificacionFinalByChange(props, e.target.value, rowData)}
        />  
      </div>
      
    );
  }
  
  const onCalificacionFinalByChange = (props, value ) => {  
    const updatedRows = [...SelfReviewCriteria];
    updatedRows[props.rowIndex].CalificacionFinal = value;

    updatedRows[props.rowIndex].errors = null;
    const newErrors = { ...errors };
    const fieldKey = `SelfReviewCriteria[${props.rowIndex}]`;
    if (value) {
      if (newErrors[fieldKey]) {
        delete newErrors[fieldKey].CalificacionFinal; 
        if (Object.keys(newErrors[fieldKey]).length === 0) {
          delete newErrors[fieldKey];
        }
      }
    } else { 
      if (!newErrors[fieldKey]) {
        newErrors[fieldKey] = {};
      }
      newErrors[fieldKey].CalificacionFinal = null;
    }
    setErrors(newErrors);
    setSelfReviewCriteria(updatedRows);
    setAvgTotal(obtienePromedio(updatedRows,'CalificacionFinal'));
  };  

  function obtienePromedio(lSelfReviewCriteria, calificacionKey) {
    const calificaciones = lSelfReviewCriteria
        .map(row => row[calificacionKey])
        .filter(val => val !== null && val !== undefined && !isNaN(val));
  
    if (calificaciones.length === 0) {
        return 0;  
    }

    const nAvgTotal = calificaciones.reduce((acc, curr) => acc + curr, 0) / calificaciones.length;
    const roundedAvgTotal = parseFloat(nAvgTotal.toFixed(2));
    return roundedAvgTotal;
}

const onChangeCompromisosSiguienteAnioReview = (e) => {
  const newErrors = { ...errors };
  delete newErrors.CompromisosSiguienteAnioReview;
  setErrors(newErrors);
  setCompromisosSiguienteAnioReview(e.target.value);
};

const onChangeComentariosCalificacionDesempenioFinal = (e) => {
  const newErrors = { ...errors };
  delete newErrors.ComentariosCalificacionDesempenioFinal;
  setErrors(newErrors);
  setComentariosCalificacionDesempenioFinal(e.target.value);
};

const onChangeIDPSiguienteAnioObjetivos = (e) => {
  const newErrors = { ...errors };
  delete newErrors.IDPSiguienteAnioObjetivos;
  setErrors(newErrors);
  setIDPSiguienteAnioObjetivos(e.target.value);
};

const TituloDescripcionCriterioTemplate = (row) => { 
     

  return (
    <>
      <div>{row.TituloCriterio}</div>
      <div className='info-color'>{row.DescripcionCiterio}</div>
    </>
)
}

  if(!esInformacionDisponible){
    return (
      <>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="text-center p-7">  
            <h2>{t('hr:ReviewOfInformationClosed')}</h2> 
          </Col>
        </Row>
      </> 
    );
  }  
 
  if(!PreviousStepsComplete){
    return (
      <>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="text-center p-7">  
            <h2>{t('hr:AnnualReviewNotReadyPendingSteps')}</h2> 
          </Col>
        </Row>
      </> 
    );
  }  
 
  if (!esTienePermisoEdicion && EstatusAnnualReview === 0){
    return (
      <>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="text-center p-7">  
            <h2>{t('hr:AnnualReviewNotReadyForEmployee')}</h2> 
          </Col>
        </Row>
      </> 
    );
  } 

  return (
    <>   
      { EstatusAnnualReview ? (
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="text-right">   
            <label>{t('common:SubmitedDate')}</label> : <b> {FechaConfirmaRevisionAnual}</b>
          </Col>
        </Row>
      ) : <></>}
      
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>   
          <InstructionsCard> 
            <p>{t("hr:ReviewOfInformationInstructions")}</p>
          </InstructionsCard>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}> 
        &nbsp;
        </Col> 
      </Row> 
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>   
          <Panel headerTemplate={(options) => { return panelHeaderTemplate(options, 1)}} header={t('hr:EmployeeSelfReview')} className="panel-skyblue" collapsed={true} toggleable>
            <Row className="align-items-start mb-4">
              <Col lg={12} md={12} sm={12} xs={12}>  
                <><EmployeeSelfReview Anio={defaultAnio} ReadOnlyView={true} ClaUsuario={defaultClaUsuario}></EmployeeSelfReview></>
              </Col>  
            </Row> 
          </Panel> 
          <br></br>
          {
            props.Anio === '2021' ?
            (
              <>
                <Panel headerTemplate={(options) => { return panelHeaderTemplate(options, 2)}} header={t('hr:ScoreCardSummary')} className="panel-skyblue" collapsed={true} toggleable>
                  <Row className="align-items-start mb-4">
                    <Col lg={12} md={12} sm={12} xs={12}>  
                      <>
                        <ScoreCardSummaryDet Anio={defaultAnio} ReadOnlyView={true} ClaUsuario={defaultClaUsuario}></ScoreCardSummaryDet>
                      </>
                    </Col>  
                  </Row> 
                </Panel> 
                <br></br>
              </>
            )
          :
          (<></>)
          }
          <Panel headerTemplate={(options) => { return panelHeaderTemplate(options, 3)}} header={t('hr:ManagerReview')} className="panel-skyblue" collapsed={true} toggleable>
            <Row className="align-items-start mb-4">
              <Col lg={12} md={12} sm={12} xs={12}>  
                <>
                  <ManagerReview Anio={defaultAnio} ReadOnlyView={true} ClaUsuario={defaultClaUsuario}></ManagerReview>
                </>
              </Col>  
            </Row> 
          </Panel> 
        </Col> 
      </Row> 
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>  
                          &nbsp;
        </Col>
      </Row>
      <Row id="FinalRating">
        <Col lg={12} md={12} sm={12} xs={12}>
            &nbsp;
        </Col>
      </Row>
      <Row id="FinalRating">
        <Col lg={12} md={12} sm={12} xs={12}>
          <Card> 
            <CardBody className="">
              <Row className="align-items-start">
                <Col lg={8} md={8} sm={12} xs={12} className="text-center"> 
                  <div>
                    <h1>{t('hr:ReviewPerformanceRating')}</h1>
                  </div> 
                </Col>
                <Col lg={4} md={4} sm={12} xs={12} className="text-center"> 
                  <div>
                    <h1>{t('hr:PerformanceRatingScale')}</h1>
                  </div> 
                </Col>
              </Row>
              <Row className="align-items-start h-100">  
                <Col lg={8} md={8} sm={12} xs={12} className="h-100">  
                  <FieldDataTable 
                    value={SelfReviewCriteria} 
                    editMode="cell" 
                    className="editable-cells-table"
                    paginator={false}
                  > 
                    <Column body={fieldsRequired} headerStyle={{ width: '20px' }}></Column>
                    <Column 
                      field="TituloCriterio" 
                      columnKey="TituloCriterio" 
                      header={t('hr:PerformanceCriteria')} 
                      headerStyle={{ width: '50%' }} 
                      footer={t('hr:Total')}
                      body={TituloDescripcionCriterioTemplate}
                    >
                    </Column> 
                    <Column 
                      field="CalificacionEmpleado" 
                      columnKey="CalificacionEmpleado" 
                      header={t('hr:EmployeeRating')}  
                      headerStyle={{ width: '15%', textAlign: 'center' }} 
                      style={{ textAlign: 'center' }} 
                      footer={AvgEmployeeTotal} 
                    >
                    </Column> 
                    <Column 
                      field="CalificacionManager" 
                      columnKey="CalificacionManager" 
                      header={t('hr:ManagerRating')}  
                      headerStyle={{ width: '15%', textAlign: 'center' }} 
                      style={{ textAlign: 'center' }} 
                      footer={AvgManagerTotal}
                    >
                    </Column> 
                    <Column
                      field="CalificacionFinal" 
                      header={t('hr:FinalRating')} 
                      headerStyle={{ width: '20%', textAlign: 'center' }} 
                      style={{ textAlign: 'center' }}
                      editor={EstatusAnnualReview > 0 ? null : (props) => calificacionFinalByEditor(props)} 
                      footer={AvgTotal} 
                    >
                    </Column>   
                  </FieldDataTable>
                </Col>  
                <Col lg={4} md={4} sm={12} xs={12} className="h-100">  
                  <FieldDataTable 
                    value={SelfReviewCriteriaScale}  
                    paginator={false}
                    className='table-header-background-ligth-green'
                  >  
                    <Column 
                      field="Scale" 
                      columnKey="Scale" 
                      header={t('hr:Scale')} 
                      headerStyle={{ width: '20%',textAlign: 'center' }} 
                      style={{ textAlign: 'center' }} 
                      className="table-header-background-ligth-green"
                    >
                    </Column> 
                    <Column
                      field="PerformanceRating" 
                      columnKey="PerformanceRating" 
                      header={t('hr:PerformanceRating')} 
                      headerStyle={{ width: '80%'}}  
                      className="table-header-background-ligth-green"  
                    >
                    </Column>   
                  </FieldDataTable>
                </Col>  
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}> 
          <Card> 
            <CardBody>
              <Row className="align-items-start" id="ComentariosCalificacionDesempenioFinal" hidden={defaultAnio < defaultAnioCriteria}> 
                <Col lg={12} md={12} sm={12} xs={12}> 
                  <div>
                    <label><b>{t('hr:CommentsFinalPerformance')}</b></label> 
                  </div> 
                  {esTienePermisoEdicion && EstatusAnnualReview === 0 ? (
                    <div className="p-fluid">
                      <FieldTextArea 
                        name="ComentariosCalificacionDesempenioFinal"
                        rows={8}
                        placeholder=''
                        value={ComentariosCalificacionDesempenioFinal} 
                        errors={errors}
                        onChange={(e) => { onChangeComentariosCalificacionDesempenioFinal (e) }}
                      /> 
                    </div>
                          ) : <label className="text-left">{ComentariosCalificacionDesempenioFinal}</label> } 
                </Col> 
              </Row>
              <Row className="align-items-start" id="IDPSiguienteAnioObjetivos" hidden={defaultAnio < defaultAnioCriteria}> 
                <Col lg={12} md={12} sm={12} xs={12}> 
                  <div>
                    <label><b>{defaultAnio+1}&nbsp;{t('hr:IDPSiguienteAnioObjetivos1')}</b>&nbsp;{t('hr:IDPSiguienteAnioObjetivos1-2')}&nbsp;{defaultAnio+1},&nbsp;{t('hr:IDPSiguienteAnioObjetivos1-3')}</label> 
                  </div> 
                  {esTienePermisoEdicion && EstatusAnnualReview === 0 ? (
                    <div className="p-fluid">
                      <FieldTextArea 
                        name="IDPSiguienteAnioObjetivos"
                        rows={8}
                        placeholder=''
                        value={IDPSiguienteAnioObjetivos} 
                        errors={errors}
                        onChange={(e) => { onChangeIDPSiguienteAnioObjetivos (e) }}
                      /> 
                    </div>
                          ) : <label className="text-left">{IDPSiguienteAnioObjetivos}</label> } 
                </Col> 
              </Row> 
              <Row className="align-items-start"> 
                <Col lg={12} md={12} sm={12} xs={12}> 
                  {defaultAnio < defaultAnioCriteria ? (
                    <>
                      <div>
                        <label><b>{t('hr:NextCommitments')}</b></label> 
                      </div> 
                    </>
                  ) : (
                    <>
                      <div>
                        <label><b>{defaultAnio+1}&nbsp;{t('hr:PerformanceGoals1')}</b>&nbsp;{t('hr:PerformanceGoals1-2')}</label> 
                      </div> 
                    </>
                  )}   
                  {esTienePermisoEdicion && EstatusAnnualReview === 0 ? (
                    <div className="p-fluid">
                      <FieldTextArea 
                        name="CompromisosSiguienteAnioReview"
                        rows={8}
                        placeholder=''
                        value={CompromisosSiguienteAnioReview} 
                        onChange={(e) => { onChangeCompromisosSiguienteAnioReview(e) }}
                        errors={errors}
                      /> 
                    </div>
                          ) : <label className="text-left">{CompromisosSiguienteAnioReview}</label> } 
                </Col> 
              </Row> 
              <Row className="align-items-start"> 
                <Col lg={12} md={12} sm={12} xs={12}> 
                  <div>
                    <label><b>{t('hr:AdditionalComments')}</b></label> 
                  </div> 
                  {esTienePermisoEdicion && EstatusAnnualReview === 0 ? (
                    <div className="p-fluid">
                      <FieldTextArea 
                        name="ComentariosAnnualReview"
                        rows={8}
                        placeholder=''
                        value={ComentariosAnnualReview} 
                        onChange={(e) => { setComentariosAnnualReview (e.target.value) }}
                      /> 
                    </div>
                          ) : <label className="text-left">{ComentariosAnnualReview}</label> } 
                </Col> 
              </Row>
            </CardBody>
          </Card>
        </Col> 
      </Row>  
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>   
          <InstructionsCard> 
            <p>{t("hr:ReviewOfInformationInstructionsSection2")}</p>
          </InstructionsCard>
        </Col>
      </Row> 
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
            &nbsp;
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <Card> 
            <CardBody className=""> 
              <Row className="align-items-start h-100">  
                <Col lg={6} md={6} sm={12} xs={12} className="h-100">  
                  <div className="text-center">
                    <label><b>{t('hr:EmployeeESignature')}</b></label>
                  </div> 
                  <Signature 
                    onChange={setEmployeeSignature}   
                    onClear={onClearEmployeeSignture}
                    readonly={EstatusAnnualReview === 1}
                    value={EmployeeSignature}
                  />  
                  <div style={{ display: esTienePermisoEdicion && EstatusAnnualReview === 0 ? "block" : "none"}}>
                    { EmployeePasswordValid ? 
                    (
                      <>
                        <small><i className="fa fa-check light-green-color"></i>&nbsp;{t('hr:PasswordConfirmationSuccess')}.</small>
                      </>
                    )
                    :
                    (
                      <>
                        <Card> 
                          <CardHeader>
                            {t('hr:EmployeePasswordConfirmarion')} 
                          </CardHeader>
                          <CardBody>
                            <div className="p-fluid">
                              <label><b> {EmployeeLoginName} </b></label>
                            </div>
                            <div className="p-fluid">
                              <FieldPassword
                                name="EmployeePassword" 
                                placeholder={t('hr:TypePassword')}
                                value={EmployeePassword} 
                                onChange={onChangeEmployeePassword} 
                                errors={errors} 
                                feedback={false}
                              >
                              </FieldPassword>
                            </div> 
                        
                          </CardBody>
                          <CardFooter>
                            <div className="p-fluid">
                              <FieldButton className="p-button-success btn-min-width no-border" label={t('hr:ConfirmarPassword')} onClick={() => onConfirmEmployeePassword()}  />
                            </div>
                          </CardFooter>
                        </Card>
                      </>
                  )}
                  </div>
                </Col> 
                <Col lg={6} md={6} sm={12} xs={12} className="h-100">
                  <div className="text-center">
                    <label><b>{t('hr:ManagerESignature')}</b></label>
                  </div> 
                  <Signature 
                    onChange={setManagerSignature}   
                    onClear={onClearManagerSignature}   
                    readonly={EstatusAnnualReview === 1}
                    value={ManagerSignature}
                  /> 
                  <div style={{ display: esTienePermisoEdicion && EstatusAnnualReview === 0 ? "block" : "none"}}>
                    { ManagerPasswordValid ? 
                    (
                      <>
                        <small><i className="fa fa-check light-green-color"></i>&nbsp;{t('hr:PasswordConfirmationSuccess')}.</small>
                      </>
                    )
                    :
                    (
                      <>
                        <Card> 
                          <CardHeader>
                            {t('hr:ManagerPasswordConfirmarion')} 
                          </CardHeader>
                          <CardBody>
                            <div className="p-fluid">
                              <label><b> {ManagerLoginName}</b></label>
                            </div>
                            <div className="p-fluid">
                              <FieldPassword
                                name="ManagerPassword" 
                                placeholder={t('hr:TypePassword')}
                                value={ManagerPassword} 
                                onChange={onChangeManagerPassword} 
                                errors={errors} 
                                feedback={false}
                              >
                              </FieldPassword>
                            </div>  
                          </CardBody>
                          <CardFooter>
                            <div className="p-fluid">
                              <FieldButton className="p-button-success btn-min-width no-border" label={t('hr:ConfirmarPassword')} onClick={() => onConfirmManagerPassword()}  />
                            </div>
                          </CardFooter>
                        </Card>
                      </>
                    )}
                  </div>
                </Col> 
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
            &nbsp;
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12} className="text-center">
          <div style={{ display: esTienePermisoEdicion && EstatusAnnualReview === 0 ? "block" : "none"}}>
            <FieldButton label={t('common:Submit')} className="light-green no-border rounded-button p-3 pl-5 pr-5  font-weight-bold" onClick={onConfirmSubmit} /> 
          </div> 
        </Col>
      </Row>
     

    </>  
        ); 
};

export default ReviewOfInformation;

/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from 'components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { Avatar } from 'primereact/avatar';
import { config } from '../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../utils/utils';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable.jsx';
import { ProgressBar } from 'primereact/progressbar';
import SweatAlertCustomIcon from 'components/SweatAlert/SweatAlertCustomIcon';
import FieldText from 'components/Controls/FieldText';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx';
import FieldDropdown from 'components/Controls/FieldDropdown.jsx';
import * as yup from 'yup';
import YupValidator from 'utils/YupValidator';
import moment from 'moment';
import swal from '@sweetalert/with-react';
import PageContent from 'layouts/PageContent';
import { Toolbar } from 'primereact/toolbar';
import FieldTextArea from 'components/Controls/FieldTextArea';
import FieldAutoComplete from 'components/Controls/FieldAutoComplete.jsx';
import FieldTime from 'components/Controls/FieldTime';
import { cssNumber } from 'jquery';
import FieldCheckbox from 'components/Controls/FieldCheckbox.jsx';
import FieldSelectButton from 'components/Controls/FieldSelectButton';
import { Dialog } from 'primereact/dialog';
import CultureSurveyApprovingFeedbackResponse from './CultureSurveyApprovingFeedbackResponse.jsx';
import { TramOutlined } from '@material-ui/icons';

const CultureSurveyApprovingFeedbackDetail = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['survey', 'common']);
  const [EsViewResponse, setEsViewResponse] = useState(false);
  const [IdEncuesta, setIdEncuesta] = useState(null);
  const [IdEncuestaContestada, setIdEncuestaContestada] = useState(null);
  const [AprovingFeedBackDetailListSelectedByMe, setAprovingFeedBackDetailListSelectedByMe] = useState([]);
  const [AprovingFeedBackDetailListRequested, setAprovingFeedBackDetailListRequested] = useState([]);
  const [ClaAnio, setClaAnio] = useState(null);
  const [ClaUsuario, setClaUsuario] = useState(null);
  const [DescEncuesta, setDescEncuesta] = useState(null);
  const [AprovingFeedBackResponseList, setAprovingFeedBackResponseList] = useState([]);
  const [Visible, setVisible] = useState(false);
  const [RespuestasPara, setRespuestasPara] = useState(null);

  useEffect(() => {
    setClaAnio(props.ClaAnio === undefined ? null : props.ClaAnio);
    setClaUsuario(props.ClaUsuario === undefined ? null : props.ClaUsuario);
    loadAprovingFeedBackDetail(props.ClaAnio, props.ClaUsuario);
  }, [props.ClaAnio, props.ClaUsuario]);

  const loadAprovingFeedBackDetail = (pAnio, pUsuario) => {
    const GetAprovingFeedBackDetailUser = `${config.UrlApiProject}hr/GetAprovingFeedBackDetailUser?Anio=${pAnio}&ClaUsuario=${pUsuario}`;
    const paramsToService = {};
    callApi(GetAprovingFeedBackDetailUser, 'GET', paramsToService, (response) => { 
      setAprovingFeedBackDetailListSelectedByMe(response.data.AprovingFeedBackDetailListSelectedByMe);
      setAprovingFeedBackDetailListRequested(response.data.AprovingFeedBackDetailListRequested);
    });
  };

  const showActiveRows = (rows) => {
    const activeRows = [];
    if (rows !== undefined) {
      if (rows.length > 0) {
        rows.forEach((item, index) => {
          if (!item.BajaLogica || item.BajaLogica === 0 || item.BajaLogica === undefined) {
            item.BajaLogica = 0;
            item.RowIndex = index;
            item.RowIndexCount = index + 1;
            activeRows.push({ ...item });
          }
        });
      } else {
        return [];
      }
    }

    return activeRows;
  };
 
  const dialogStyle = {
    width: '80vw',
  };

  const onClickViewResponses = (row) => {
    setIdEncuestaContestada(row.IdEncuestaContestada);
    setIdEncuesta(row.IdEncuesta);
    setDescEncuesta(row.DescEncuesta);
    setEsViewResponse(true);
    setRespuestasPara(row.NomUsuario);
    loadAprovingFeedBackResponse(row.IdEncuesta, row.IdEncuestaContestada);
    setVisible(false);
  };

  const regresarListado = () => {
    if (props.ClosedDetail) {
      props.ClosedDetail();
    }
  }; 

  const onResponsesApproved = () => {
    setEsViewResponse(false);
    loadAprovingFeedBackDetail(props.ClaAnio, props.ClaUsuario);
  }

  const NomUsuarioTemplate = (row) => {
    return (
      <>
        
        <div className="p-toolbar p-component">
          <div className='p-toolbar-group-left d-block'>
            <div>
              <span className=''><b>{row.NomUsuario}</b></span>
            </div>
            <div>
              <small>
                {row.FechaCompletada !== null
                    ? 
                    (<>{t('survey:DateEndSurvey')}: <span className="success-color"> <b>{moment(row.FechaCompletada).format('MMMM DD, YYYY h:mm:ss a')}</b></span></>)
                    : 
                    (<><span className="danger-color">{t('survey:SurveyNotCompleted')}</span></>)}
              </small> 
            </div>
            {
              row.EsRevisadoRH === 1 ?
              (
                <div> 
                  <small><i className="fas fa-award warning-color"></i>{t('survey:ApprovedByHRDate')}: <b>{moment(row.FechaRevisadoRH).format('MMMM DD, YYYY h:mm:ss a')}</b></small>
                </div>
              )
              :
              (<></>)
            }
            
          </div>
          <div className='p-toolbar-group-right'> 
            {
              row.EsCompletado === 1 ? (
                <>
                  {
                  row.EsRevisadoRH === 1 ?
                  (
                    <>
                      <FieldButton
                        className="p-button-rounded p-button-secondary btn-min-width no-border"
                        label={t('survey:ViewResponses')} 
                        onClick={(e) => onClickViewResponses(row)}
                      />
                    </>
)
                  :
                  (
                    <>
                      <FieldButton
                        className="p-button-rounded p-button-success btn-min-width no-border"
                        label={t('survey:ApproveResponses')} 
                        onClick={(e) => onClickViewResponses(row)}
                      />
                    </>
)
                }
                  
                  
                  
                </>
            ) : (
              <></>
            )
          }
          </div>
        </div> 
      </>
    );
  }
 

  const modalFooter = () => {
    return (
      <>
        
      </>
    );
  };
  /* Modal */
  const loadAprovingFeedBackResponse = (pIdEncuesta, pIdEncuestaContestada) => {
    const GetAprovingFeedBackResponse = `${config.UrlApiProject}hr/GetAprovingFeedBackResponse?IdEncuesta=${pIdEncuesta}&IdEncuestaContestada=${pIdEncuestaContestada}`;
    const paramsToService = {};
    callApi(GetAprovingFeedBackResponse, 'GET', paramsToService, (response) => { 
      setAprovingFeedBackResponseList(response.data.AprovingFeedbackResponse);
    });
  };

  const ApprovedAllResponse = (props, e) => {
    const updatedField = [...AprovingFeedBackResponseList];

    updatedField.map((p) => (updatedField[p.RowIndex].Visible = e.checked ? 1 : 0));

    setVisible(e.checked ? 1 : 0);

    setAprovingFeedBackResponseList(updatedField);
  }; 

  return (
    <>
      <Row hidden={TramOutlined}>
        <Col className="text-right">
          <FieldButton
            label={t('common:Back')}
            icon="pi pi-arrow-left"
            className="p-button-secondary p-mr-2"
            onClick={regresarListado}
          />
          &nbsp;
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          &nbsp;
        </Col>
      </Row>
      <Row className='align-items-start'>
        <Col lg={4} md={4} sm={12} xs={12}>
          <Row>
            <Col>
              <FieldDataTable
                value={showActiveRows(AprovingFeedBackDetailListSelectedByMe)} 
                className="table-header-background-ligth-green"
                editMode="cell"
                selectionMode="single"
                header={null}
                paginator={false}
              >
                <Column
                  field="NomUsuario"
                  header={t('survey:Participant')} 
                  columnKey="NomUsuario"
                  body={NomUsuarioTemplate}
                >
                </Column>  
              </FieldDataTable>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
                &nbsp;
            </Col>
          </Row>
          <Row>
            <Col>
              <FieldDataTable
                value={showActiveRows(AprovingFeedBackDetailListRequested)} 
                className="table-header-background-orange"
                editMode="cell"
                selectionMode="single"
                header={null}
                paginator={false}
              >
                <Column
                  field="NomUsuario"
                  header={t('survey:ParticipantRequestingFeedback')} 
                  columnKey="NomUsuario"
                  body={NomUsuarioTemplate}
                >
                </Column>  
              </FieldDataTable>
            </Col>
          </Row>
         
        </Col>
        <Col lg={8} md={8} sm={12} xs={12}>
          {
             EsViewResponse ?
             (
               <CultureSurveyApprovingFeedbackResponse
                 IdEncuesta={IdEncuesta}
                 IdEncuestaContestada={IdEncuestaContestada} 
                 NomUsuarioRespondeEncuesta={props.NomUsuarioRespondeEncuesta}
                 RespuestasPara={RespuestasPara}
                 onResponsesApproved={onResponsesApproved}
               >
               </CultureSurveyApprovingFeedbackResponse>
             )
             :
             (<></>)
           } 
        </Col>
      </Row> 
    </>
  );
};
export default CultureSurveyApprovingFeedbackDetail;

/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'; 
import { useTranslation } from 'react-i18next'; 
import PageContent from '../../../../../layouts/PageContent';  
import { Button } from 'primereact/button';
import { Row, Col, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';   
import PlanningMapSegment from './PlanningMapSegment'; 

const PlanningScreen = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);
  const [OpenOrdersList, setOpenOrdersList] = useState([]);
  const [ShipmentsList, setShipmentsList] = useState([]);
  const [Marker, setMarker] = useState(null);  
  const [EsLoad, setEsLoad] = useState(false); 
  const [Refresh, setRefresh] = useState(0); 
  
  
  useEffect(() => {  
  }, [Refresh]); 
   
  const onRefresh = () => {  
    setRefresh(Refresh+1);
  }
 
    return (
      <>  
        <PageContent title={t('map:OpenOrdersTitle')} className="content-no-margin">  
          
          <PlanningMapSegment 
            Destination={null}  
            refresh={onRefresh}
          >
          </PlanningMapSegment>  
        </PageContent> 
      </>  
        ); 
};

export default PlanningScreen;

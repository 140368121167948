import React , { useEffect, useState, useRef }  from 'react';   
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';  
import { config } from '../../../utils/config';
import { callApi } from '../../../utils/utils';
import { Workbook } from 'exceljs';
import { exportPivotGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';

 
import PivotGrid, {
    Export,
    FieldChooser  ,
    FieldPanel,
    HeaderFilter,
    Scrolling,
    Search,
    StateStoring
  } from 'devextreme-react/pivot-grid';
   
import { useTranslation } from 'react-i18next'; 
import PageContent from 'layouts/PageContent'; 
import OrganizationalSalesRepView from '../SalesDashboard/OrganizationalSalesRepView';
import { Card, CardBody, Col, Row } from 'reactstrap';
import  './SalesDataPivotScreen.scss';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import FieldDropdown from 'components/Controls/FieldDropdown';


const SalesDataPivotScreen = (props) => {
    const {t, i18n} = useTranslation(['salesPerformance', 'common']);

    const [ClaUsuario, setClaUsuario] = useState(null);  
    const [IsLoading, setIsLoading] = useState(true);
    const [dataSource, setDataSource] = useState(null);
    const [ProfunidadInformacionID, setProfunidadInformacionID] = useState(1);
    const [IsOnSearchApplied, setIsOnSearchApplied] = useState(false); 
    const [errors, setErrors] = useState({});   
    const [salesInfo, setSalesInfo] = useState([]);   
    const pivotGridRef = useRef(null);
    
    const [OpcionesProfunidadInformacion, setOpcionesProfunidadInformacion] = useState([
      {
        ProfunidadInformacionID  : 1,
        NomProfunidadInformacion : 'Statistical Gpo 3'
      },
      {
        ProfunidadInformacionID  : 2,
        NomProfunidadInformacion : 'Statistical Gpo 4'
      },
      {
        ProfunidadInformacionID  : 3,
        NomProfunidadInformacion : 'Item Code'
      }
    ])

    useEffect(() => { 
      // Mandamos verificar con el usaurio conectado cual es el ClaAgenteVentas Default que estara visualizando
      const GetOrganizationalSalesRepByUser = `${config.UrlApiProject}ScoreCard/GetOrganizationalSalesRepByUser`;
      const paramsToService = {};
 
      callApi(GetOrganizationalSalesRepByUser, 'GET', paramsToService, (response) => {
          const firstElement   = response.data.length > 0 ? response.data[0] : {};
          console.log('firstElement', firstElement);
          if (firstElement.key !== null && typeof(firstElement.key) !== 'undefined'){
            setClaUsuario(firstElement.key); /* key = ClaUsaurio */
          }
  
          setIsLoading(false);
      });
  
    }, []); 

    useEffect(() => { 
        // obtieneSalesDataTransparency();  
    }, []);
 
  const obtieneSalesDataTransparency = () => {
    const GetSalesDataTransparency = `${config.UrlApiProject}USADatalake/GetSalesDataTransparency?NivelProfundidadInformacion=${ProfunidadInformacionID}&ClaUsuarioSalesRep=${ ClaUsuario === null || typeof(ClaUsuario) === 'undefined' ? '' : ClaUsuario}`; 
    const paramsToService = {};
    callApi(GetSalesDataTransparency, 'Get', paramsToService, (response) => { 
      const defaultValues = getPivotDataSource(); 
      defaultValues.store = response.data.SalesInfo; 
      setSalesInfo(response.data.SalesInfo);
      setDataSource(new PivotGridDataSource(defaultValues)); 
      setIsOnSearchApplied(true); 
    });
  }
  
  const getPivotDataSource = () => {
      return {  
          retrieveFields: true,

          fields: [ 
            { displayFolder: 'Measures', caption: 'Vol (t)', summaryType: 'sum',     dataField: 'MTTons',  area: 'data',  isMeasure: true, dataType: 'number',  format: 'fixedPoint' }, 
            { displayFolder: 'Measures', caption: 'Quantity',   summaryType: 'sum',     dataField: 'Quantity', isMeasure: true, dataType: 'number',  format: 'fixedPoint', }, 
            { displayFolder: 'Measures', caption: 'Raw Material',  summaryType: 'sum', visible: false,     dataField: 'RawMaterialAmount', isMeasure: true, dataType: 'number',  format: 'currency' } ,
            { displayFolder: 'Measures', caption: 'Spread Bdgt',   summaryType: 'sum',  visible: false,    dataField: 'Spread_Bgt', isMeasure: true, dataType: 'number',  format: 'currency' } ,
            { displayFolder: 'Measures', caption: 'Vol Bdgt (t) ',  summaryType: 'sum',      dataField: 'TonsPresupuesto', isMeasure: true, dataType: 'number',  format: 'fixedPoint' } ,  
            { displayFolder: 'Measures', caption: 'Net Sale ($k)',  summaryType: 'sum',      dataField: 'ImpMaterialNeto', isMeasure: true, dataType: 'number',  format: 'currency', calculateSummaryValue (e) {
               
              return e.value('ImpMaterialNeto', true) * 0.001;
            }    } ,
            { displayFolder: 'Measures', caption: 'Freight ($k)',    summaryType: 'sum',    dataField: 'FleteOutbound', isMeasure: true, dataType: 'number',  format: 'currency',
              calculateSummaryValue (e) { 
              return e.value('FleteOutbound', true) * 0.001;
            }    } ,
            
            { displayFolder: 'Measures', caption: 'Revenue ($k)',    summaryType: 'sum',    dataField: 'ImporteVenta', isMeasure: true, dataType: 'number',  format: 'currency', calculateSummaryValue (e) {
              
              return e.value('ImporteVenta', true) * 0.001;
            }   
            }, 
            { displayFolder: 'Measures', caption: 'Spread ($k)', summaryType: 'sum',     dataField: 'Spread',   isMeasure: true, dataType: 'number',  format: 'currency'
              , calculateSummaryValue (e) { 
                return  e.value('Spread', true) * 0.001;
              }  
            },
            { displayFolder: 'Measures', caption: 'Price ($/t)', summaryType: 'sum',  area: 'data',   isMeasure: true, dataType: 'number',  format: 'currency'
              , calculateSummaryValue (e) {
                if (e.value('MTTons', true) === 0 || e.value('MTTons', true) === null ) {
                  return null;
                }
          
                return e.value('ImporteVenta', true) !== 0 ? e.value('ImporteVenta', true) / e.value('MTTons', true) : 0;
              }   
            }  ,
            { displayFolder: 'Measures', caption: 'Net Price ($/t)', summaryType: 'sum',  area: 'data',   isMeasure: true, dataType: 'number',  format: 'currency'
                , calculateSummaryValue (e) {
                  if (e.value('MTTons', true) === 0 || e.value('MTTons', true) === null ) {
                    return null;
                  }
            
                  return e.value('ImpMaterialNeto', true) !== 0 ? e.value('ImpMaterialNeto', true) / e.value('MTTons', true) : 0;
                }   
            } ,
            { displayFolder: 'Measures', caption: 'Freight ($/t)', summaryType: 'sum',  area: 'data',   isMeasure: true, dataType: 'number',  format: 'currency'
              , calculateSummaryValue (e) {
                if (e.value('MTTons', true) === 0 || e.value('MTTons', true) === null ) {
                  return null;
                }
          
                return e.value('FleteOutbound', true) / e.value('MTTons', true);
              }   
            }, 
            { displayFolder: 'Measures', caption: 'Spread ($/t)', summaryType: 'sum',  area: 'data',   isMeasure: true, dataType: 'number',  format: 'currency'
                , calculateSummaryValue (e) {
                  if (e.value('MTTons', true) === 0 || e.value('MTTons', true) === null ) {
                    return null;
                  }
            
                  return  e.value('Spread', true) / e.value('MTTons', true) ;  
                }   
            },
            { displayFolder: 'Measures', caption: 'Spread Bdgt ($/t)', summaryType: 'sum',   isMeasure: true, dataType: 'number',  format: 'currency'
                , calculateSummaryValue (e) { 
                  if (e.value('MTTons', true) === 0 || e.value('MTTons', true) === null ) {
                    return null;
                  }

                  return e.value('Spread_Bgt', true) / e.value('MTTons', true) ;
                }   
            },
            { displayFolder: 'Measures', caption: 'Raw Mat ($/t)', summaryType: 'sum',   dataField: 'RawMaterialAmount',   isMeasure: true, dataType: 'number',  format: 'currency'
              , calculateSummaryValue (e) { 
                if (e.value('MTTons', true) === 0 || e.value('MTTons', true) === null ) {
                  return null;
                }
          
                return  e.value('ImpMaterialNeto', true)  / e.value('MTTons', true) ;
              }   
            }
            ,
            { displayFolder: 'Bussiness', caption: 'Division',       dataField: 'NomGrupoEstadistico1', area: 'row', width: 250, isMeasure: false  } ,
            { displayFolder: 'Product', caption: 'Segment', area: 'row',    dataField: 'NomSegmento', width: 250, isMeasure: false }, 
            { displayFolder: 'Product', caption: 'Stat. Gpo 3',     dataField: 'NomGrupoEstadistico3', area: 'row', width: 250, isMeasure: false },
            { displayFolder: 'Product', caption: 'Stat. Gpo 4',     dataField: 'NomGrupoEstadistico4', width: 250, isMeasure: false },
            { displayFolder: 'Product', caption: 'Stat. Gpo 2',     dataField: 'NomGrupoEstadistico2', width: 250, isMeasure: false },
            { displayFolder: 'Product', caption: 'Item Code',    dataField: 'ClaveArticulo',  width: 250, isMeasure: false }, 
            { displayFolder: 'Product', caption: 'Item Name',    dataField: 'NomArticulo',  width: 250, isMeasure: false },
            { displayFolder: 'Product', caption: 'Item ID',    dataField: 'ClaArticulo', width: 250, isMeasure: false, visible: false },
            // { displayFolder: 'Invoice', caption: 'Invoice Date',       dataField: 'FechaFactura', allowFiltering:false, isMeasure: false , dataType: 'date', format: 'yyyy/MM/dd' } , 
            // { displayFolder: 'Invoice', caption: 'Inovice ID',       dataField: 'IdFactura',  allowFiltering:false, isMeasure: false },
            // { displayFolder: 'Invoice', caption: 'Inovice Code',       dataField: 'ClaveFactura', allowFiltering:false,  isMeasure: false },
            // { displayFolder: 'Invoice', caption: 'Type',       dataField: 'TipoDocumento', width: 250, isMeasure: false },
            { displayFolder: 'Invoice', caption: 'Period',       dataField: 'FechaPeriodo',  area: 'column', dataType: 'date',  isMeasure: false },
            { displayFolder: 'Bussiness', caption: 'Business Line',       dataField: 'BusinessLine' ,width: 250, isMeasure: false  },
            { displayFolder: 'Bussiness', caption: 'Location',       dataField: 'Location', width: 250, isMeasure: false  } ,
            { displayFolder: 'Customer', caption: 'CUC ID',       dataField: 'ClaveUnicaCliente', width: 250,  isMeasure: false  } ,
            { displayFolder: 'Customer', caption: 'CUC Name', area: 'filter',       dataField: 'CUCName', width: 250,  isMeasure: false  } ,
            { displayFolder: 'Customer', caption: 'CUC Type',       dataField: 'NomTipoCliente', width: 250,  isMeasure: false  } ,
            { displayFolder: 'Customer', caption: 'Category',       dataField: 'NomCategoriaCliente', width: 250,  isMeasure: false  } ,
            { displayFolder: 'Customer', caption: 'Account ID',       dataField: 'ClaveClienteCuenta', width: 250,  isMeasure: false  } ,
            { displayFolder: 'Customer', caption: 'Account Name',       dataField: 'NomClienteCuenta', width: 250,  isMeasure: false  } ,
            { displayFolder: 'Customer', caption: 'Account City',       dataField: 'CiudadCteCuenta', width: 250,  isMeasure: false  } ,
            { displayFolder: 'Customer', caption: 'Account State',       dataField: 'EstadoCteCuenta',width: 250,   isMeasure: false  } ,
            { displayFolder: 'Customer', caption: 'Account Country',       dataField: 'PaisCteCuenta', width: 250,  isMeasure: false  }  ,
            { displayFolder: 'Customer', caption: 'Consignee ID',       dataField: 'ClaveConsignado',  width: 250, isMeasure: false  } ,
            { displayFolder: 'Customer', caption: 'Consignee Name',       dataField: 'NomClienteConsignado',width: 250,   isMeasure: false  } ,
            { displayFolder: 'Customer', caption: 'Consignee City',       dataField: 'CiudadCteConsignado', width: 250,  isMeasure: false  } ,
            { displayFolder: 'Customer', caption: 'Consignee State',       dataField: 'EstadoCteConsignado',width: 250,   isMeasure: false  }  ,
            { displayFolder: 'Customer', caption: 'Consignee Country',       dataField: 'PaisCteConsignado',width: 250,   isMeasure: false  } ,
            { displayFolder: 'Customer', caption: 'Region',       dataField: 'RegionEmbarcado', width: 250,  isMeasure: false  } ,
            { displayFolder: 'Agent', caption: 'Zone',       dataField: 'NomZona',width: 250,   isMeasure: false  } ,
            { displayFolder: 'Agent', caption: 'Sales Representative', area: 'filter',width: 250,   dataField: 'NomAgente',  isMeasure: false  } ,
            { displayFolder: 'Agent', caption: 'Customer Service Rep',       dataField: 'NomCSR', width: 250,  isMeasure: false  } ,
            { displayFolder: 'Agent', caption: 'Manager',       dataField: 'NomGerencia', width: 250,  isMeasure: false  } ,
            { caption: 'TipoNaturaleza',       dataField: 'TipoNaturaleza',width: 250,   visible: false, isMeasure: false  } ,
            { caption: 'Naturaleza',       dataField: 'Naturaleza',  width: 250, visible: false, isMeasure: false  }  ,
            { caption: 'SistemaOrigen',       dataField: 'SistemaOrigen', width: 250,  visible: false, isMeasure: false  } ,
            { caption: 'NomSubSegmento',       dataField: 'NomSubSegmento', width: 250,  visible: false, isMeasure: false  } ,
            { caption: 'Aniomes',       dataField: 'Aniomes', width: 250,  visible: false, isMeasure: false  } ,
            { caption: 'Spread',       dataField: 'Spread', width: 250,  visible: false, isMeasure: false  } 

              
        ],
        store: []
      }
  } 

  const itemsDateTransform = (items) => {
    const newItems = [];

    for (let index = 0; index < items.length; index++) {
        const element = items[index];
        element.FechaFactura = element.FechaFactura !== null ? new Date(element.FechaFactura) : null; 
        newItems.push(element);
    }
    
    console.log('newItems', newItems);
    return newItems;
};

    const organizationalView = (
      <div className='organization-default-width'>
        <div className='WTW-Step-1'>
          <OrganizationalSalesRepView value={ClaUsuario} showPositionName={true} onChange={(itemValue, itemNode) => onChangeOrganizationalValue(itemValue, itemNode)}></OrganizationalSalesRepView> 
        </div>
      </div>

    )

    const onChangeOrganizationalValue = (itemValue, itemNode)=> {  
      setClaUsuario(itemNode.key); /* key = ClaUsaurio */
    }

    const onChangeProfunidadInformacionID = (e) => {  
      const newErrors = { ...errors } 
      delete newErrors.ProfunidadInformacionID;
      setErrors(newErrors)  
      setProfunidadInformacionID(e.value);
      setIsOnSearchApplied(false); 
  };

  const onSearch = async () => {  
    obtieneSalesDataTransparency();
  };

  const getExcelCellFormat = ({ fill, font, bold }) => ({
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: fill } },
    font: { color: { argb: font }, bold },
  }); 

  const getConditionalAppearance = (cell) => {
    if (isTotalCell(cell)) {
      return { fill: 'f8f8f8', font: '000000', bold: true };
    }
    const { value } = cell;
     
    return { font: '000000' };
  };

  const isDataCell = (cell) => (cell.area === 'data' && cell.rowType === 'D' && cell.columnType === 'D');

  const isTotalCell = (cell) => (cell.type === 'T' || cell.type === 'GT' || cell.rowType === 'T' || cell.rowType === 'GT' || cell.columnType === 'T' || cell.columnType === 'GT');

  // Ensure all rows and columns are expanded to export the entire dataset 
  const expandAllFields = (fields) => {
    fields.forEach(field => {
      if (field.area === 'row' 
         //    || field.area === 'column'
      ) {
        field.expanded = true;
      }
    });
  }; 
 

  const resetOriginalState = async () => { 
    setIsOnSearchApplied(false);
    const defaultValues = getPivotDataSource(); 
    defaultValues.store = salesInfo;  
    setDataSource(new PivotGridDataSource(defaultValues)); 
    setIsOnSearchApplied(true); 
  }

  const onExporting =   async (e) => {  
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
      const pivotGridInstance = pivotGridRef.current.instance;
 
      const dataSource = pivotGridInstance.getDataSource();  
      const currentFields  = dataSource.fields();
      
      expandAllFields(dataSource.fields()); 

      await dataSource.reload();

      // Extract the data from the pivot grid
      const data = dataSource.getData();
      const {rows, columns, values} = data;  
      
      // Function to handle cell values
      const handleCellValue = (value, excelCell) => { 
         
        if (typeof(value) !== 'string' && (value === null || isNaN(value))) {
          return '';
        }
         
        return value;
      };
      
      await exportPivotGrid({
          component: e.component,
          worksheet,
          customizeCell(options) {
              // console.log('options', options);
              
              const { pivotCell , excelCell } = options;
              // excelCell.font = { name: 'Arial', size: 12 };
              // excelCell.alignment = { horizontal: 'left' };
              
              excelCell.value = handleCellValue(excelCell.value, excelCell);

              if (isDataCell(pivotCell) || isTotalCell(pivotCell)) {
                const appearance = getConditionalAppearance(pivotCell);
                Object.assign(excelCell, getExcelCellFormat(appearance));
              }

              const borderStyle = { style: 'thin', color: { argb: 'FF7E7E7E' } };
              excelCell.border = {
                bottom: borderStyle,
                left: borderStyle,
                right: borderStyle,
                top: borderStyle,
              };
          } 
      }).then(() => {
          workbook.xlsx.writeBuffer()
              .then((buffer) => {
                  saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Sales2yrPivote.xlsx'); 
              });
      });
      
      
      resetOriginalState();
  };


    const filters = (
      <>
        <Row className="pt-3 pb-3">
          <Col>
            <div className="p-fluid">
              <FieldDropdown  
                name="ProfunidadInformacionID"
                value={ProfunidadInformacionID} 
                options={OpcionesProfunidadInformacion}  
                optionValue="ProfunidadInformacionID"
                optionLabel="NomProfunidadInformacion" 
                onChange={(e) => onChangeProfunidadInformacionID(e)}
                label="Granularity Level / Information Depth"
                showClear={false}
                errors={errors}
              />   
            </div>
          </Col>
          <Col>
            &nbsp;
          </Col>
          <Col>
             &nbsp;
          </Col>
          <Col lg={2} md={2} sm={2} xs={2}>
            <div className='text-right'>
              <FieldButton
                label={t('common:Search')}
                icon="pi pi-search"
                className="p-button p-highlight"
                onClick={() => onSearch()}
              >
              </FieldButton> 
            </div> 
          </Col>
        </Row>  
        {
          true &&
          (
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <small className='warning'>
                Attention! This query may take longer than usual due to the volume of data. After you click search button please wait a moment as we process the information.
              </small> 
            </Col>
          </Row>
)
        }
      </>
    );

    if (IsLoading){
      return (<>Loading....</>)
    }
    
    return (
      <PageContent title="Sales 2yr. Data" toolBar={organizationalView}>
        <div className='SalesDataPivotScreen'>
          <Row className='align-items-start pb-3'>
            <Col lg={12} md={12} sm={12} xs={12}>  
              {filters}
            </Col> 
          </Row> 
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              {
          dataSource !== null && IsOnSearchApplied ?
          (
            <PivotGrid
              id="gridDataPivotScreen" 
              dataSource={dataSource}
              allowSortingBySummary={false}
              allowFiltering={true}
              showBorders={true}
              showColumnTotals={false}
              showColumnGrandTotals={false}
              showRowTotals={true}
              showTotalsPrior="rows"
              showRowGrandTotals={false}  
              rowHeaderLayout="tree" 
              height="100%"  
              width="100%" 
              texts={{noData:t('common:NoData')}}
              onExporting={onExporting}
              ref={pivotGridRef}
            > 
              <StateStoring enabled={true} type="localStorage" storageKey="gridDataPivotPersists" /> 
              <HeaderFilter
                width={300}
                height={400}
              >
                <Search enabled={true} />
              </HeaderFilter> 
              <FieldPanel 
                showColumnFields={false}
                showDataFields={false}
                showFilterFields={true}
                showRowFields={false}
                allowFieldDragging={false}
                visible={true}
              />
              <FieldChooser enabled={true} height="100%" width="100%" applyChangesMode='onDemand'  />  
              <Scrolling mode="virtual" />
              <Export enabled={true} />
            </PivotGrid>
          )
          :
          (
            <Card className='card-produccion'>
              <CardBody className="" style={{paddingTop: "5px"}}>
                <Row className='pt-2'>
                  <Col lg={12} md={12} sm={12} xs={12}>  
                    {t('wtw:DoNotForgetClickSearch')}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )
        } 
            </Col> 
          </Row> 
        </div>
        
      </PageContent>
    )
}
export default SalesDataPivotScreen;
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PageContent from '../../../../layouts/PageContent';
import { Row, Col, Card, CardBody, CardHeader, CardTitle, CardFooter } from 'reactstrap';
import InstructionsCard from '../../../../components/Cards/InstructionsCard/InstructionsCard';
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../../utils/utils';
import * as yup from 'yup';
import YupValidator from '../../../../utils/YupValidator';
import swal from '@sweetalert/with-react';
import SweatAlertCustomIcon from '../../../../components/SweatAlert/SweatAlertCustomIcon';
import moment from 'moment';
import { Badge } from 'primereact/badge';
import Score from '../../../../components/Cards/Score/Score.jsx';
import FieldTextArea from '../../../../components/Controls/FieldTextArea.jsx';
import FieldButton from '../../../../components/Controls/FieldButton/FieldButton.jsx';
import FieldCarousel from '../../../../components/Carousel/Carousel';
import ListItem from '../../../../components/ListGroup/ListItem';
import HighlighterText from '../../../../components/Controls/HighlighterText/HighlighterText';
import FieldToogleSwitch from '../../../../components/Controls/FieldToogleSwitch/FieldToogleSwitch';
import Signature from '../../../../components/Signature/Signature.jsx';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { color } from 'highcharts';
import EmployeeSelfReview from '../../CompanyCulture/EmployeeSelfReview/EmployeeSelfReview.jsx';
import ManagerReview from '../../CompanyCulture/ManagerReview/ManagerReview.jsx';
import FieldPassword from '../../../../components/Controls/FieldPassword.jsx';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import { Column } from 'primereact/column';
import FieldText from 'components/Controls/FieldText';
import EditorText from 'components/Controls/EditorText/EditorText';

const SurveyManagementNew = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['survey', 'common']);
  const [CategoriaListCmb, setCategoriaListCmb] = useState([]);
  const [ClaEncuestaCategoria, setClaEncuestaCategoria] = useState(null);
  const [Titulo, setTitulo] = useState(null);
  const [descripcionSurvey, setdescripcionSurvey] = useState(null);
  const [descripcionSurveyHtml, setdescripcionSurveyHtml] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    obtenerCategoryCmb();
  }, []);

  const obtenerCategoryCmb = () => {
    const getCategoryCmb = `${config.UrlApiProject}HR/GetCategoryCmb`;
    const paramsToService = {};
    callApi(getCategoryCmb, 'GET', paramsToService, (response) => {
      setCategoriaListCmb(response.data.Category);
    });
  };

  const onChangeCategory = (props, e) => {
    setClaEncuestaCategoria(e.target.value);
  };

  const onTilteChange = (e) => {
    setTitulo(e.target.value);
  };

  const onEditorChange = (ehtml, etext) => {
    setdescripcionSurvey(etext);
    setdescripcionSurveyHtml(ehtml);
  };
  const renderHeader = () => {
    return (
      <span className="ql-formats">
        <FieldButton className="ql-bold" aria-label="Bold"></FieldButton>
        <FieldButton className="ql-italic" aria-label="Italic"></FieldButton>
        <FieldButton className="ql-underline" aria-label="Underline"></FieldButton>
      </span>
    );
  };
  const header = renderHeader();

  const valitationSchemeSave = yup.object().shape({    
    Titulo: yup.string().required(t('common:RequiredField')).nullable(),    
    ClaEncuestaCategoria: yup.mixed().required(t('common:RequiredField')).nullable()     
  });

  const onSaveClick = async () => {
    const value = {          
      Titulo ,
      ClaEncuestaCategoria
    };

    const paramsService = {
      ClaEncuestaCategoria,
      Titulo,
      Descripcion: descripcionSurvey,
      DescripcionHtml: descripcionSurveyHtml
    };
     
    const validator = new YupValidator(valitationSchemeSave);
    const esValido = await validator.validate(value);
    if (!esValido) {
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      return;
    }



    const SaveSurvey = `${config.UrlApiProject}HR/SaveSurvey`;

    callApi(SaveSurvey, 'POST', paramsService, (response) => {
      showSweetAlert(t('common:Success'), t('common:SuccessOperation'), 'success');
      props.closeModalNewSurvey();
      props.onSearch();
    });
  };
  const onCancelClick = () => {
    props.closeModalNewSurvey();
  };
  return (
    <>
      <Row>
        <Col lg={6} md={6} sm={12} xs={12}>
          <div className="p-fluid">
            <FieldText
              name="Titulo"
              value={Titulo}
              label={t('survey:Title')}
              onChange={onTilteChange}
              maxLength={500}
              errors={errors}
            />
          </div>
        </Col>
        <Col lg={6} md={6} sm={12} xs={12}>
          <div className="p-fluid">
            <FieldDropdown
              name="ClaEncuestaCategoria"
              label={t('survey:Category')}
              value={ClaEncuestaCategoria}
              options={CategoriaListCmb}
              optionValue="ClaEncuestaCategoria"
              optionLabel="NomEncuestaCategoria"
              onChange={(e) => onChangeCategory(props, e)}
              errors={errors}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          &nbsp;
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <EditorText
            style={{ height: '120px' }}
            value={descripcionSurvey}
            onTextChange={(e) => onEditorChange(e.htmlValue, e.textValue)}
          >
          </EditorText>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          &nbsp;
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12} className="text-right">
          <FieldButton
            icon="fas fa-times"
            label={t('common:Cancel')}
            className="warning no-border p-mr-2 rounded-button" 
            onClick={onCancelClick}
          />
          &nbsp;
          <FieldButton
            label={t('common:Save')}
            icon="pi pi-save"
            className="p-button-success no-border p-mr-2 rounded-button"
            onClick={onSaveClick}
          />
        </Col>
      </Row>
    </>
  );
};

export default SurveyManagementNew;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  ListGroupItem,
  ListGroup,
  Progress,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label
} from 'reactstrap';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { Badge } from 'primereact/badge';
// import FeedbackCoaching from './FeedbackCoaching.jsx';
import { Link } from 'react-router-dom';
import ActionPlanScoreSquare from './ActionPlanScoreSquare.jsx';
import { Toolbar } from 'primereact/toolbar';
import Highcharts from 'highcharts';
import { config } from '../../../../utils/config';
import { callApi, tracker, getSessionItem, showSweetAlert } from '../../../../utils/utils';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable.jsx';
import { Column } from 'primereact/column';
import FieldButton from 'components/Controls/FieldButton/FieldButton.jsx';
import { Dialog } from 'primereact/dialog';
import { Tag } from 'primereact/tag';
import moment from "moment"; 

const KPIProgressEvaluation = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['focusReport', 'common']); 
  const [ClaPuesto, setClaPuesto] = useState(null); 
  const [ClaAnio, setClaAnio] = useState(null); 
  const [ClaMes, setClaMes] = useState(null);  
  const [ClaProyecto, setClaProyecto] = useState(null);  
  const [EsActivity, setEsActivity] = useState(false);  
  const [KPIProgressEvaluationResultHeader, setKPIProgressEvaluationResultHeader] = useState({});
  const [ActivitiesProgressEvaluationResult, setActivitiesProgressEvaluationResult] = useState([]);
  const [KPIProgressEvaluationResult, setKPIProgressEvaluationResult] = useState({});
  const [KPIProgressEvaluationDetList, setKPIProgressEvaluationDetList] = useState([]);
  const [DetailActivityList, setDetailActivityList] = useState([]);
  const [TotalActivityDetail, setTotalActivityDetail] = useState(null);
  const [NomCumplimiento, setNomCumplimiento] = useState(null);
  const [ProjectActivitiesAccomplishmentPercentage, setProjectActivitiesAccomplishmentPercentage] = useState(null);
  const [ProjectActivitiesAccomplishment, setProjectActivitiesAccomplishment] = useState(null);
  const [ProjectKPIAccomplishmentPercentage, setProjectKPIAccomplishmentPercentage] = useState(null);
  const [ProjectKPIAccomplishment, setProjectKPIAccomplishment] = useState(null);
  const [IsLoad, setIsLoad] = useState(false);

  useEffect(() => {
    setClaPuesto(props.ClaPuesto === undefined ? 0 : props.ClaPuesto);
    setClaAnio(props.ClaAnio === undefined ? 0 : props.ClaAnio);
    setClaMes(props.ClaMes === undefined ? 0 : props.ClaMes);
    setClaProyecto(props.ClaProyecto === undefined ? 0 : props.ClaProyecto);
    GetKPIProgressEvaluationResultado();
  }, [props.ClaPuesto, props.ClaAnio, props.ClaMes, props.ClaProyecto]);

  const GetKPIProgressEvaluationResultado = () => {
    const getKPIProgressEvaluationResultado = `${config.UrlApiProject}ScoreCard/GetKPIProgressEvaluationResultado`;
    const paramsToService = {
      ClaPuesto: props.ClaPuesto === undefined ? 0 : props.ClaPuesto,
      ClaAnio: props.ClaAnio === undefined ? 0 : props.ClaAnio,
      ClaMes: props.ClaMes === undefined ? 0 : props.ClaMes,
      ClaProyecto: props.ClaProyecto === undefined ? 0 : props.ClaProyecto,
    };
    callApi(getKPIProgressEvaluationResultado, 'POST', paramsToService, (response) => {
      const {data} = response;
      setKPIProgressEvaluationResultHeader(data.KPIProgressEvaluationHeader[0])
      setActivitiesProgressEvaluationResult(data.KPIProgressEvaluationHeader)
      setKPIProgressEvaluationResult(data.KPIProgressEvaluation[0]);
      setKPIProgressEvaluationDetList(response.data.KPIProgressEvaluationDetail);
      setIsLoad(true); 
    });
  };

  const GetActivityDetail = (pnClaCumplimiento) => {
    const getProjectActivityDetail = `${config.UrlApiProject}ScoreCard/GetProjectActivityDetail`;
    const paramsToService = {
      ClaPuesto,
      ClaAnio,
      ClaMes,
      ClaCumplimiento: pnClaCumplimiento
    };
    callApi(getProjectActivityDetail, 'POST', paramsToService, (response) => {
      const headerResulst = response.data.ProjectActivityHead.length>0? response.data.ProjectActivityHead[0] : {}; 
      setTotalActivityDetail(headerResulst.Total);
      setNomCumplimiento(headerResulst.NomCumplimiento);
      setDetailActivityList(response.data.ProjectActivityDetail);
    });
  };

  const TargetMesTemplate = (rowData) => {
    return (
         (<span>{rowData.TargetMes !== null?  Highcharts.numberFormat(rowData.TargetMes, 2, '.', ',') : null} </span>)
    );
  };

  const PointObtainedTemplate = (rowData) => {
    return (
         (<span>{rowData.PointsObtained !== null?  Highcharts.numberFormat(rowData.PointsObtained, 2, '.', ',') : null} </span>)
    );
  };


  const headerActionPlaActivitiesMonth = (
    <>
      <div className="row">
        <div className="col-12 col-lg-6 col-sm-12"> 
          {KPIProgressEvaluationResult.NomIndicador}
        </div>
      </div>  
    </>
);

  const footer  = ( 
    <> 
      <Row>
        <Column></Column>     
        <Column></Column>  
        <Column></Column>  
        <Column></Column>  
        <Column></Column>   
        <Column className="text-right" footer={() => (<b>{t('focusReport:Total')}</b>)}></Column>     
        <Column className="text-center" footer={() => (<b>{ (KPIProgressEvaluationDetList.reduce((a,v) =>  a +=  v.Points === null ? 0 : v.Points , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>     
        <Column className="text-center" footer={() => (<b>{ (KPIProgressEvaluationDetList.reduce((a,v) =>  a +=  v.PointsObtained === null ? 0 : v.PointsObtained , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>     
      </Row>
    </>
  )
  
  
  const footerActivities  = ( 
    <> 
      <Row> 
        <Column></Column>   
        <Column className="text-right" footer={() => (<b>{t('focusReport:Total')}</b>)}></Column>     
        <Column className="text-center" footer={() => (<b>{ (KPIProgressEvaluationDetList.reduce((a,v) =>  a +=  v.Points === null ? 0 : v.Points , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>     
      </Row>
    </>
  )

  const EstatusMesTemplate = (rowData) => {
    return (
         (<span>{rowData.EstatusMes !== null?  Highcharts.numberFormat(rowData.EstatusMes, 2, '.', ',') : null} </span>)
    );
  };
  
  const dialogStyle = {
    width: '80vw',
  };

  const closeModalActivity = () => {
    setEsActivity(false);
  };

  const onClickViewDetailActivity = (props) => {    
    setEsActivity(!EsActivity);
    GetActivityDetail(props.CumplimientoID);
  };

  const headerActivity =(
    <>
      <Row>
        <Col>
          {NomCumplimiento}
        </Col>
        <Col className="text-right">
          {t('focusReport:Total')}: {TotalActivityDetail}
        </Col>
      </Row>
        
    </>
      );

  if (!IsLoad){
    return (
      <>
      </> 
    );
  }

  const SentidoMejoraTemplate = (props) => {
    return <>{props.ClaSentidoMejora === 1 ?  (<i className="fas fa-sort-amount-up"></i>): (<i className="fas fa-sort-amount-down"></i>)}</>;
  }; 
  
  const ProgressTargetTemplate = (rowData) => {
    return (
      <>
        <label>
          <b>{Highcharts.numberFormat(rowData.Accomplishment, 1)} %</b>
        </label>
      </>
    );
  };

  return (
    <>
      <Card>
        <CardBody>
          <Row className="align-items-start ">
            <Col lg={10} md={10} sm={10} xs={10}>              
              <div style={{ float:'left'}}>
                <h2> {KPIProgressEvaluationResult.NomIndicador}</h2>
              </div>  
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className='text-right'> 
              { KPIProgressEvaluationResultHeader.ProjectProgressDate !== null ? moment(KPIProgressEvaluationResultHeader.ProjectProgressDate).format("MMMM, YYYY") : NULL }
            </Col>
          </Row>
          <Row className="align-items-start ">
            <Col lg={8} md={8} sm={12} xs={12}>              
              <div style={{ float:'left'}}>
                <span className="h4">{t('focusReport:ProjectActivitiesAccomplishmentPercentage')}</span>&nbsp;({KPIProgressEvaluationResultHeader.ProjectActivitiesAccomplishmentPercentage}%)
              </div>              
            </Col>
            <Col lg={4} md={4} sm={12} xs={12} className="text-right">
              <div className="text-right">
                <h4><span className="title-ProjectActivities-Sub">{t('focusReport:Points')}:</span> {Highcharts.numberFormat(KPIProgressEvaluationResultHeader.ProjectActivitiesAccomplishment, 2, '.', ',') }</h4>
              </div>  
            </Col>
          </Row>
          <Row className="align-items-start ">
            <Col lg={8} md={8} sm={12} xs={12}>              
              <div style={{ float:'left'}} className="Cursor">
                <span className="h4">{t('focusReport:ProjectKPIAccomplishmentPercentage')}</span>&nbsp;({KPIProgressEvaluationResultHeader.ProjectKPIAccomplishmentPercentage}%)
              </div>               
            </Col>
            <Col lg={4} md={4} sm={12} xs={12} className="text-right">
              <div className="text-right">
                <h4><span className="title-ProjectActivities-Sub">{t('focusReport:Points')}:</span> {Highcharts.numberFormat(KPIProgressEvaluationResultHeader.ProjectKPIAccomplishment, 2, '.', ',') }</h4>
              </div>  
            </Col>
          </Row>
          <Row className="align-items-start ">
            <Col lg={8} md={8} sm={12} xs={12}>              
              <div>
                <small className='info-color'>*{t('focusReport:KPIProgressEvaluationNote')}</small>
              </div>               
            </Col>
            <Col lg={4} md={4} sm={12} xs={12} className="text-right">
              <div className="text-right">
                <h4><span className="title-ProjectActivities-Sub">{t('focusReport:TotalScoreTitle')}:</span> {Highcharts.numberFormat(KPIProgressEvaluationResultHeader.Total, 2, '.', ',') }</h4>
              </div>  
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
            </Col>
          </Row>
          <div>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <h4>
                  <span className="title-ProjectActivities">
                    {t('focusReport:ProjectProgressActivitiesSummary')}
                  </span> 
                </h4>
              </Col>
            </Row>
            <Row className="align-items-start ">
              <Col lg={12} md={12} sm={12} xs={12}>
                <FieldDataTable
                  value={ActivitiesProgressEvaluationResult}
                  scrollable 
                  className="table-header-background-blue" 
                  paginator={false}
                >
                  <Column   
                    field="PorcEstimado" 
                    header={t('focusReport:EstProgress')}
                    columnKey="PorcEstimado"
                    headerStyle={{ width: '60px' }}
                  >
                  </Column>
                  <Column
                    field="PorcReal"
                    className="text-center"
                    header={t('focusReport:RealProgress')}
                    columnKey="PorcReal"
                    headerStyle={{ width: '60px' }}
                  >
                  </Column>
                  <Column
                    field="ScoreTotal"
                    className="text-center"
                    header={t('focusReport:Score')}
                    columnKey="ScoreTotal"
                    headerStyle={{ width: '40px'}}
                  >
                  </Column>
                  <Column
                    field="ProjectActivitiesAccomplishment"
                    className="text-center"
                    header={t('focusReport:PointObtained')}
                    columnKey="ProjectActivitiesAccomplishment"
                    headerStyle={{ width: '40px' }}
                  >
                  </Column>
                </FieldDataTable>
              </Col>
            </Row>  
          </div>
          <br></br>
          <div>
            <Row>
              <Col lg={10} md={10} sm={10} xs={10}>
                <h4>
                  <span className="title-ProjectActivities">
                    {t('focusReport:KPIProgressEvaluation')}
                  </span> 
                </h4>
              </Col>
              <Col lg={2} md={2} sm={2} xs={2} className='text-right'> 
                { KPIProgressEvaluationResultHeader.KPIsEvaluationProgressDate !== null ? moment(KPIProgressEvaluationResultHeader.KPIsEvaluationProgressDate).format("MMMM, YYYY") : NULL }
              </Col>
            </Row>
            <Row className="align-items-start ">
              <Col lg={12} md={12} sm={12} xs={12}>
                {
              KPIProgressEvaluationDetList.length>0 && KPIProgressEvaluationResultHeader.EsRequiereProgressReport  ? (
                <>
                  <FieldDataTable
                    value={KPIProgressEvaluationDetList}
                    scrollable 
                    className="table-header-background-blue" 
                    footerColumnGroup={footer}
                    paginator={false}
                  >
                    <Column   
                      field="NomProyectoKPIManagement" 
                      header={t('focusReport:KPI')}
                      columnKey="NomProyectoKPIManagement"
                      headerStyle={{ width: '145px', textAlign: 'center' }}
                    >
                    </Column>
                    <Column
                      field="ClaSentidoMejora"
                      className="text-center"
                      header=''
                      columnKey="ClaSentidoMejora"
                      headerStyle={{ width: '20px' }}
                      body={SentidoMejoraTemplate}
                    >
                    </Column> 
                    <Column
                      field="EstatusMes"
                      className="text-center"
                      header={t('focusReport:Current')}
                      columnKey="EstatusMes"
                      headerStyle={{ width: '60px', textAlign: 'center' }}
                      body={EstatusMesTemplate} 
                    >
                    </Column>
                    <Column
                      field="TargetMes"
                      body={TargetMesTemplate}
                      className="text-center"
                      header={t('focusReport:Target')}
                      columnKey="TargetMes"
                      headerStyle={{ width: '40px', textAlign: 'center' }}
                    >
                      
                    </Column>
                    <Column
                      field="Accomplishment"
                      className="text-center"
                      header={t('focusReport:ProgressTarget')}
                      columnKey="Accomplishment"
                      headerStyle={{ width: '60px', textAlign: 'center' }}
                      body={ProgressTargetTemplate}
                    >
                      
                    </Column>
                    <Column
                      field="PorcPeso"
                      className="text-center"
                      header={t('focusReport:Weight')}
                      columnKey="PorcPeso"
                      headerStyle={{ width: '60px', textAlign: 'center' }}
                    >
                    </Column>
                    <Column
                      field="Points"
                      className="text-center"
                      header={t('focusReport:Score')}
                      columnKey="Points"
                      headerStyle={{ width: '60px', textAlign: 'center' }}
                    >
                    </Column>
                    <Column
                      field="PointsObtained"
                      className="text-center"
                      header={t('focusReport:PointObtained')}
                      body={PointObtainedTemplate}
                      columnKey="PointsObtained"
                      headerStyle={{ width: '40px' }}
                    >
                    </Column>
                  </FieldDataTable>
                
                </>
              ):(
                <>
                  {
                            KPIProgressEvaluationResultHeader.EsRequiereProgressReport ? (
                              <>
                                <div className='card info'> 
                                  <div className='card-body'>
                                    <div className='p-2 text-center'>
                                      <span>
                                        {t('focusReport:NothingKPI')}
                                      </span> 
                                    </div>
                                  </div>
                                </div>
                              </>
                            ):(
                              <>
                                <div className='card success'> 
                                  <div className='card-body'>
                                    <div className='p-2 text-center'>
                                      <span>
                                        {t('focusReport:MessageNotProgressReport')}
                                      </span> 
                                    </div>
                                  </div>
                                </div> 
                              </>
                            )
                          }
                
                </>
              )
            }
              </Col>
            </Row>  
          </div>
        </CardBody>
        <CardFooter hidden={!props.showCloseButton}>
          <Row>
            <Col className='text-center'>
              <FieldButton
                label={t('common:Close')}
                icon="fas fa-times"
                className="warning no-border p-mr-2 rounded-button"
                onClick={typeof(props.onCloseClick) !== 'undefined' && typeof(props.onCloseClick) === 'function' ? props.onCloseClick : null}
              />
            </Col>
          </Row>
        </CardFooter>
      </Card>
      <Dialog
        visible={EsActivity}
        header=""
        style={dialogStyle}
        modal
        className="dialog-custom"
        onHide={closeModalActivity}
      >
        <Row className="align-items-start">
          <Col lg={12} md={12} sm={12} xs={12}>
            <FieldDataTable
              value={DetailActivityList}
              scrollable 
              scrollHeight="330px" 
              className="editable-cells-table"  
              editMode="cell"
              selectionMode="single"
              header={headerActivity}
            >
              <Column
                field="NomProyecto"
                header={t('focusReport:Project')}
                columnKey="NomProyecto"
                headerStyle={{ width: '145px' }}
              >
              </Column>
              <Column
                field="NomFase"
                header={t('focusReport:Phase')}
                columnKey="NomFase"
                headerStyle={{ width: '145px', textAlign: 'center' }}                
              > 
              </Column>
              <Column
                field="NomActividad"
                header={t('focusReport:DescriptionModal')}
                columnKey="NomActividad"
                headerStyle={{ width: '145px', textAlign: 'center' }}                
              > 
              </Column>
              <Column
                field="FechaEstimadaFin"
                className="text-center"
                header={t('focusReport:DueDate')}
                columnKey="FechaEstimadaFin"
                headerStyle={{ width: '40px', textAlign: 'center' }}
              >
                 
              </Column>
              <Column
                field="FechaRealFin"
                className="text-center"
                header={t('focusReport:CompletedDate')}
                columnKey="FechaRealFin"
                headerStyle={{ width: '60px', textAlign: 'center' }}
              >                 
              </Column>            
              <Column
                field="DiferenciaDias"
                className="text-center"
                header={t('focusReport:DateDiff')}
                columnKey="DiferenciaDias"
                headerStyle={{ width: '60px', textAlign: 'center' }}
              >                 
              </Column>
              
            </FieldDataTable>
          </Col>
        </Row>
      </Dialog>
    </>
  );
};

export default KPIProgressEvaluation;

import PageContent from 'layouts/PageContent';
import React, { useState, useRef, useEffect  } from 'react';  
import { useTranslation } from 'react-i18next';    
import { Row, Col  } from 'reactstrap';     
import OrangePage from './OrangePage';

const OrangePageScreen = (props) => { 

  
  const { t } = useTranslation(['common']);
  const { IdReporteMensualProyecto, ClaProyecto } = props.match.params; 

  const onHide = () => {
    return true;
  }
 
  return (
    <>   
      <PageContent title={t('common:Home')}>  
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>  
            <OrangePage Visible={true} onHide={()=> onHide()} ClaProyecto={ClaProyecto} IdReporteMensual={IdReporteMensualProyecto}></OrangePage>
          </Col> 
        </Row>    
      </PageContent> 
    </>
  );
};

export default OrangePageScreen;

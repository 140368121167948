/* eslint-disable react/no-deprecated */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/state-in-constructor */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/static-property-placement */
import React from "react";
import PropTypes from "prop-types";  
import { Page } from 'react-pdf';  
import { Waypoint } from 'react-waypoint';

const EXPERIMENTAL_VIRTUALIZED = false;
const noop = () => {};

export default class PDFPage extends React.PureComponent {
 
  static propTypes = {
    fitTo: PropTypes.oneOf(["page", "width"]),
    scale: PropTypes.number,
    pageCount: PropTypes.number,
    pages: PropTypes.object,
    onPageChange: PropTypes.func,
    rotation: PropTypes.number
  };

  static defaultProps = {
    onPageChange: noop
  };

  state = {
    pages: {}
  }; 
 
  handleWaypointEnter = (page, index) => {   
      this.props.onPageChange(index + 1);
  };

  handleOnLoadSuccess = (page) => {
    this.setState({
        pages: { ...this.state.pages, [page._pageIndex]: page }
      });
    this.props.onLoadSuccess(page);
  }; 

  renderPage = ({
    index, // Index of row  
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    key, // Unique key within array of rendered rows
    parent, // Reference to the parent List (instance)
    style, // Style object to be applied to row (to position it); This must be passed through to the rendered row element.
  }) => {
    const { pages } = this.state;
    return (   
      <Waypoint
        topOffset="50%"
        bottomOffset="49%"
        onEnter={this.handleWaypointEnter.bind(this, pages[index], index)}
        key={key}
      > 
        <div>
          <Page
            {...this.props}
            style={style}
            scale={this.calculateScale(pages[index])}
            pageNumber={index + 1}
            onLoadSuccess={(page) => this.handleOnLoadSuccess(page)}
            rotate={this.props.rotation}
          />  
        </div>
      </Waypoint>   
    );
  };

  calculateScale = (page) => {
    const { fitTo, scale: globalScale, container } = this.props;

    if (!page || !container) {
      return globalScale;
    }

    const pageScale = page.scale || 1;
    const { clientWidth, clientHeight } = container.current;

    const rotated = page._pageInfo.rotate === 90 || page._pageInfo.rotate === 270;
    const pageWidth = rotated ? page.originalHeight : page.originalWidth;
    const pageHeight = rotated ? page.originalWidth : page.originalHeight;
    const scaleX = clientWidth / pageWidth;
    const scaleY = clientHeight / pageHeight;
    if (fitTo === "page") {
      return globalScale * Math.min(scaleX, scaleY);
    }
    if (fitTo === "width") {
      return globalScale * scaleX;
    }
    return globalScale * pageScale;
  }; 

  render() { 

    const { pageCount } = this.props; 

    return (  
      <div className="page-list">
        {[...Array(this.props.pageCount).keys()].map((index) =>
          this.renderPage({
            index,
            key: `page-${index}`
          })
        )} 
      </div> 
    );
  }
}

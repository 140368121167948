/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable-next-line react/no-danger */
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import { Divider } from 'primereact/divider';
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next';  
import { Row, Col } from 'reactstrap';
import { config } from '../../../../utils/config';
import { callApi } from '../../../../utils/utils';
import SurveyManagementPageEdit from './SurveyManagementPageEdit';
import SurveyManagementQuestionLoad from './SurveyManagementQuestionLoad';

const SurveyManagementPageLoad = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['survey', 'common']);
  const [PageList, setPageList] = useState([]);
  const [IdEncuesta, setIdEncuesta] = useState();
  const [Titulo, setTitulo] = useState(null);
  const [Descripcion, setDescripcion] = useState(null);
  const [DescripcionHtml, setDescripcionHtml] = useState(null);
  const [IdEncuestaPagina, setIdEncuestaPagina] = useState(null);  
  const [Index, setIndex] = useState();  
  const [EsHabilitarEditarSurveyPage, setEsHabilitarEditarSurveyPage] = useState(false);
  const [ForceRefresh, setForceRefresh] = useState(0);  


  useEffect(() => {

    setIdEncuesta(props.IdEncuesta === undefined ? 0 : props.IdEncuesta)
    setTitulo(props.Titulo === undefined ? 0 : props.Titulo)
    setDescripcion(props.Descripcion === undefined ? 0 : props.Descripcion)
    setDescripcionHtml(props.DescripcionHtml === undefined ? 0 : props.DescripcionHtml)
    setIdEncuestaPagina(props.IdEncuestaPagina === undefined ? 0 : props.IdEncuestaPagina)
    setIndex(props.index === undefined ? 0 : props.index)
    setForceRefresh(props.ForceRefresh === undefined ? 0 : props.ForceRefresh)

  }, [props.IdEncuesta, props.Titulo, props.Descripcion, props.DescripcionHtml, props.IdEncuestaPagina, props.index, props.ForceRefresh]);


  const EditarSurveyPage = () => {
    setEsHabilitarEditarSurveyPage(true);
  };

  const OcultarEditarSurveyPage = () => {
    setEsHabilitarEditarSurveyPage(false); 
    if (
      props.onSearch !== null &&
      props.onSearch !== 'undefined' &&
      typeof props.onSearch === 'function'
    ) {
      props.onSearch();
    }
  };  

  return (
    <> 
      <Row>
        <Col lg={12} md={12} sm={12} xs={12} className="text-right">
          <span className='h1'>{props.Index+1}</span> 
        </Col>
      </Row>          
      <Row>        
        <Col xs={12} sm={12} ms={12} lg={12}>
          {EsHabilitarEditarSurveyPage === false ? (
            <div onClick={EditarSurveyPage} className='page-container-no-edit'>
              {
                (Titulo === null || Titulo) === '' && (DescripcionHtml === null || DescripcionHtml === '')
                ? (
                  <>
                    <FieldButton
                      label={t('survey:AddPageTitle')}
                      icon="pi pi-plus"
                      className="no-border rounded-button"
                      onClick={EditarSurveyPage}
                    /> 
                  </>
)
                : (<></>)
              }
              <div className="Pregunta">
                <label>
                  <b>{ Titulo == null ? (
                    <>
                      <FieldButton
                        label={t('survey:AddPageTitle')}
                        icon="pi pi-plus"
                        className="no-border rounded-button"
                        onClick={EditarSurveyPage}
                      /> 
                    </>
                    ) : Titulo}
                  </b>
                </label>
              </div>
              <div className="Pregunta" dangerouslySetInnerHTML={{ __html: DescripcionHtml }}>
              </div>
            </div>
            
          ) : (
            <>
              <div className="">
                <SurveyManagementPageEdit
                  OcultarEditarSurveyPage={OcultarEditarSurveyPage} 
                  Titulo={Titulo} 
                  Descripcion={Descripcion}
                  DescripcionHtml={DescripcionHtml}
                  IdEncuesta={IdEncuesta}
                  IdEncuestaPagina={IdEncuestaPagina}
                  ForceRefresh={ForceRefresh}
                >
                </SurveyManagementPageEdit>
              </div>     
            </>
          )}
        </Col>
      </Row> 
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          &nbsp;
        </Col>
      </Row>      
      <Row>
        <Col>
          <SurveyManagementQuestionLoad IdEncuestaPagina={props.IdEncuestaPagina} IdEncuesta={props.IdEncuesta}></SurveyManagementQuestionLoad>               
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          &nbsp;
        </Col>
      </Row>  
      <Row>
        <Col> 
          <Divider></Divider>
        </Col>
      </Row>    
    </>
  );
};

export default SurveyManagementPageLoad;



/* eslint-disable jsx-a11y/label-has-associated-control */
import React  from 'react';      
import { Chips } from 'primereact/chips';

const FieldChips =  (props) => {

    const onChange = (value) => {  
        if (props.onChange !== null && props.onChange !== undefined){
            props.onChange(value);
        } 
    }  
     
    const isFormFieldInValid = (name) => !!(typeof(props.errors) !== 'undefined' && props.errors !== null && props.errors[name]);
    
    const getFormErrorMessage = (name) => { 
        return isFormFieldInValid(name) && <small className="p-error">{props.errors[name].message}</small>;
    };

 
    return (  
      <> 
        <Chips
          value={props.value === null ? '' : props.value} 
          onChange={onChange}
          separator={props.separator}
          className={`${props.className} ${isFormFieldInValid(props.name) === true ? 'p-invalid' : ''}`}
        />
        { getFormErrorMessage(props.name) }  
      </> 
    )
}   

    
export default FieldChips; 

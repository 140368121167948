/* eslint-disable jsx-a11y/label-has-associated-control */
import React  from 'react';     
import { SelectButton } from 'primereact/selectbutton'; 

const FieldSelectButton =  (props) => {
    
    const onChange = (value) => {  
        if (props.onChange !== null){
            props.onChange(value);
        } 
    }  

    const isFormFieldInValid = (name) => !!(typeof(props.errors) !== 'undefined' && props.errors !== null && props.errors[name]);
    
    const getFormErrorMessage = (name) => {  
        return isFormFieldInValid(name) && <small className="p-error">{props.errors[name].message}</small>;
    };

    return (  
      <>  
      

        <span className="p-float-label">
          <SelectButton   
            value={props.value} 
            options={props.options}  
            optionValue={props.optionValue}
            optionLabel={props.optionLabel}
            onChange={onChange}
            className={`${props.className} ${isFormFieldInValid(props.name) === true ? 'p-invalid' : ''}`}
            placeholder={props.placeholder}
            disabled={props.disabled}
            multiple={props.multiple}
          />  
          <label className={isFormFieldInValid(props.name) === true ? 'p-error' : ''}>{props.label}</label>
        </span>
        { getFormErrorMessage(props.name) } 
      </> 
    )
}   

    
export default FieldSelectButton; 

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'; 
import { useTranslation } from 'react-i18next'; 
import { config } from '../../../utils/config';
import { callApi, showSweetAlert, tracker} from '../../../utils/utils'; 
import PageContent from '../../../layouts/PageContent';  
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row,Col, TabContent, TabPane , Nav, NavItem, NavLink, Card, CardBody} from 'reactstrap';    
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import ProyectoDetalle from './ProyectoDetalle.jsx';
import Fases from './Fases.jsx';
import FasesDetalle from './FasesDetalle.jsx';
import Documentos from './Documentos.jsx'
import CaratulaProyecto from './CaratulaProyecto.jsx';
import KpisManagementTable from './KpisManagementTable.jsx';
import Minutas from './Minutas.jsx';
import ProyectoInvolucrados from './ProyectoInvolucrados.jsx';
import ProgressReportList from './ProgressReportList.jsx';
import ProjectGantt from './ProjectGantt.jsx';
import FasesProyectosPorcPesoList from './FasesProyectosPorcPesoList.jsx';
import ProyectoMaestroDetalle from './ProyectoMaestroDetalle.jsx';
import ProjectGanttMaster from './ProjectGanttMaster';
import { Link } from "react-router-dom";
import FieldToogleSwitch from '../../../components/Controls/FieldToogleSwitch/FieldToogleSwitch.jsx';
import { Dialog } from 'primereact/dialog';

const EdicionProyecto = (props) => {  
    

   /* ################## useState area start ################## */
   const { t } = useTranslation(['caratulaproyecto', 'common', 'misActividades']); 
   const [TabActiveIndex, setTabActiveIndex] = useState(1);
   const [FasesProyecto, setFasesProyecto] = useState([]);
   const [EsCrearNuevaFase, setEsCrearNuevaFase] = useState(false);
   const [ActualizarEncabezado, setActualizarEncabezado] = useState(0);
   const [EsMostrarGantt, setEsMostrarGantt] = useState(false);
   const [EsMostrarActualizarPeso, setEsMostrarActualizarPeso] = useState(false);
   const [ClaTipoProyecto, setClaTipoProyecto] = useState(null);
   const [EsProyectoMaestro, setEsProyectoMaestro] = useState(null);
   const [EsMostrarGanttProjectMasters, setEsMostrarGanttProjectMasters] = useState(false);
   const [EsProjectManager, setEsProjectManager] = useState(false);
   const [EsAdminBSC, setEsAdminBSC] = useState(false);
   const [yesNoOptions, setYesNoOptions] = useState([`${t('common:Yes')}`, `${t('common:No')}`]);
   const [IsTeamMemberReviewed, setIsTeamMemberReviewed] = useState(false);
   const [EsStakeHolder, setEsStakeHolder] = useState(false);

   const [IsShowingAnotherProyect, setIsShowingAnotherProyect] = useState(false);
   const [AnotherClaProyecto, setAnotherClaProyecto] = useState(0);  

   useEffect(() => {  
      if (props.EsMostrarEditarProyec){
        document.body.classList.add('bodynotscroll'); 
      }  
   
      obtenerInformacionProyectoCaratula();
      obtieneFasesProyecto(); 

      return () => { 
        document.body.classList.remove('bodynotscroll'); 
      }  
  }, []); 

   const switchTab = tab => {
    if(TabActiveIndex !== tab){
      setTabActiveIndex(tab);
    }
  }

  const obtieneFasesProyecto = () => {  
    const GetFasesProyectoList = `${config.UrlApiProject}ScoreCard/GetFasesProyectoList?ClaProyecto=${props.ClaProyecto}`;  
    const paramsToService = { 
      
    };   
 
    callApi(GetFasesProyectoList, 'GET', paramsToService, (response) => {  
        setFasesProyecto(response.data.FasesProyecto);
    });
  }

  const onClickNuevaFase = () => {
    // document.body.classList.add('bodynotscroll'); 
    setEsCrearNuevaFase(true);
    tracker.trackEvent({
      type: 'EdicionProyecto', /* component name */
      action: 'CREATE_NEW_PHASE'
    }) 
  }

  const onChangeEsFaseDetalle = () => { 
    // document.body.classList.remove('bodynotscroll'); 
    setEsCrearNuevaFase(false);  
    obtieneFasesProyecto();
  };

  const onChangeFase = () => { 
    // document.body.classList.remove('bodynotscroll'); 
    setEsCrearNuevaFase(false);
    setActualizarEncabezado(ActualizarEncabezado+1);  
    // setFasesProyecto([]);
    // obtieneFasesProyecto();
  };

  const actualizarEncabezado = () =>{ 
    setActualizarEncabezado(ActualizarEncabezado+1);  
  } 

  const onCloseActualizarPesoFases = () => {
    setEsMostrarActualizarPeso(true);
    setActualizarEncabezado(ActualizarEncabezado+1);

    tracker.trackEvent({
      type: 'EdicionProyecto', /* component name */
      action: 'UPDATE_WEIGHTS_PHASES'
    }) 
  }

  const obtenerInformacionProyectoCaratula = () => { 
    const GetProyectoCaratula = `${config.UrlApiProject}ScoreCard/GetProyectoCaratula?ClaProyecto=${props.ClaProyecto}`;  
    const paramsToService = {  
    };   
    callApi(GetProyectoCaratula, 'POST', paramsToService, (response) => { 
      setClaTipoProyecto(response.data.ProyectoCaratula[0].ClaTipoProyecto); 
      setEsProyectoMaestro(response.data.ProyectoCaratula[0].EsProyectoMaestro);
      setEsProjectManager(response.data.ProyectoCaratula[0].EsProjectManager === 1);
      setEsAdminBSC(response.data.ProyectoCaratula[0].EsAdminBSC === 1);
      setIsTeamMemberReviewed(response.data.ProyectoCaratula[0].IsTeamMemberReviewed === 1)
      setEsStakeHolder(response.data.ProyectoCaratula[0].EsStakeHolder === 1); 
    });
  }

  const ProyectosPorcPesoList = () => {
    setActualizarEncabezado(ActualizarEncabezado+1);  
    setFasesProyecto([]);
    obtieneFasesProyecto();
  }

  const onConfirmReviewed = () => {  
      
    const paramsServiceOwners = { 
      ClaProyecto : props.ClaProyecto
    }; 
    
    const ConfirmTeamMemberReview = `${config.UrlApiProject}ScoreCard/ConfirmTeamMemberReview`;

      callApi(ConfirmTeamMemberReview, 'POST', paramsServiceOwners, (response) => {  
        showSweetAlert('success', t('common:SuccessOperation'), 'success');
        setActualizarEncabezado(ActualizarEncabezado+1);  
    });  
  } 

  const onOpenAnotherProjectClick = (anotherClaProyecto) => {
    setAnotherClaProyecto(anotherClaProyecto);
    setIsShowingAnotherProyect(true);
  }

  const onHideAnotherProject = () => {
    setIsShowingAnotherProyect(false);
  }

  const renderAnotherProjectDialogFooter = () => {
    return (
      <>
        <FieldButton
          label={t('common:Close')}
          icon="fas fa-times"
          className="warning no-border p-mr-2 rounded-button"
          onClick={onHideAnotherProject}
        />
      </>
    );
  };

  const renderAnotherProjectDialogHeader = () => {
    return (
      <div>
        <Row>
          <Col lg={4} md={4} sm={12} xs={12}>            
            <div>
              <span>{t('common:EditProject')}</span>
            </div>
          </Col>          
        </Row>
      </div>
    );
  };

  /* SI NO TIENE PERMISOS PARA VER EL DETALLE DEL PROYECTO */
  if (!EsProjectManager && !EsAdminBSC && !EsStakeHolder){
    return (
      <>  
        <Row>
          <Col lg={12} className='text-left'>
            <Link 
              title={t('caratulaproyecto:ReturnMyProjects')}  
              to={{ pathname: "MyProjects" }}
            >          
              <i className="fas fa-arrow-circle-left"></i> {t('caratulaproyecto:ReturnMyProjects')} 
            </Link>  
          </Col>
        </Row>
        <Row>
          <Col>
                &nbsp;
          </Col>
        </Row>
        <Row>
          <Col lg={2} className='text-center p-7'>
           &nbsp;
          </Col>
          <Col lg={8} className='text-center p-7'>
            <h1> {t('caratulaproyecto:NoAccessToEdiProject')} </h1>
          </Col>
          <Col lg={2} className='text-center p-7'>
           &nbsp;
          </Col>
        </Row>
      </>
    )
  }

  /* SI ES UN STAKEHOLDER LE MOSTRAMOS LA PANTALLA DIFERENTE */
  if (EsStakeHolder && !EsAdminBSC && !EsProjectManager) {
    return (
      <>  
        <Row>
          <Col lg={12} className='text-left'>
            <Link 
              title={t('caratulaproyecto:ReturnMyProjects')}  
              to={{ pathname: "MyProjects" }}
            >          
              <i className="fas fa-arrow-circle-left"></i> {t('caratulaproyecto:ReturnMyProjects')} 
            </Link>  
          </Col>
        </Row>
        <Row>
          <Col>
              &nbsp;
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Card> 
              <CardBody className="">
                <ProyectoDetalle ClaProyecto={props.ClaProyecto} refrescarInformacion={ActualizarEncabezado} readOnly={true}></ProyectoDetalle>
              </CardBody>
            </Card>
         
          </Col>
        </Row>
        <Row>
          <Col>
              &nbsp;
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="p-tabview p-component pb-3">
              <Nav className="p-tabview-nav tabs-reverse">  
                  
                <NavItem className={TabActiveIndex === 7 ? "p-tabview-selected p-highlight p-unselectable-text nav-item " : "p-unselectable-text nav-item "}>
                  <NavLink className="p-tabview-nav-link" onClick={() => { switchTab(7); }}>
                    {t('caratulaproyecto:Documents')}
                  </NavLink>
                </NavItem>       
                <NavItem className={TabActiveIndex === 5 ? "p-tabview-selected p-highlight p-unselectable-text nav-item " : "p-unselectable-text nav-item "}>
                  <NavLink className="p-tabview-nav-link" onClick={() => { switchTab(5); }}>
                    {t('caratulaproyecto:ProgressReport')}
                  </NavLink>
                </NavItem>
                <NavItem className={TabActiveIndex === 4 ? "p-tabview-selected p-highlight p-unselectable-text nav-item " : "p-unselectable-text nav-item "}>
                  <NavLink className="p-tabview-nav-link" onClick={() => { switchTab(4); }}>
                    {t('caratulaproyecto:MeetingMinutes')}
                  </NavLink>
                </NavItem>
                <NavItem className={TabActiveIndex === 3 ? "p-tabview-selected p-highlight p-unselectable-text nav-item " : "p-unselectable-text nav-item "}>
                  <NavLink className="p-tabview-nav-link" onClick={() => { switchTab(3); }}>
                    {t('caratulaproyecto:KPIsManagement')}
                  </NavLink>
                </NavItem>
                <NavItem className={TabActiveIndex === 2 ? "p-tabview-selected p-highlight p-unselectable-text nav-item " : "p-unselectable-text nav-item "}>
                  <NavLink className="p-tabview-nav-link" onClick={() => { switchTab(2); }}>
                    {t('caratulaproyecto:TeamMembers')}
                  </NavLink>
                </NavItem>
                <NavItem className={TabActiveIndex === 1 ? "p-tabview-selected p-highlight p-unselectable-text nav-item " : "p-unselectable-text nav-item "}>
                  <NavLink className="p-tabview-nav-link" onClick={() => { switchTab(1); }}>
                    {t('caratulaproyecto:OverView')}
                  </NavLink>
                </NavItem> 
              </Nav> 
            </div>
            <TabContent className="p-tabview-panels" activeTab={TabActiveIndex}>
              <TabPane tabId={1}>   
                <Row>
                  <Col lg={12}>
                    <Card> 
                      <CardBody className="">
                        <CaratulaProyecto ClaProyecto={props.ClaProyecto} onChange={actualizarEncabezado} readOnly={true}></CaratulaProyecto>
                      </CardBody>
                    </Card>
                  </Col>
                </Row> 
              </TabPane> 
              <TabPane tabId={2}>
                <Row>
                  <Col lg={12}>
                    <Card> 
                      <CardBody className=""> 
                        <ProyectoInvolucrados ClaProyecto={props.ClaProyecto} readOnly={true} onChange={() => setActualizarEncabezado(ActualizarEncabezado+1)}></ProyectoInvolucrados>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>  
              </TabPane>
              <TabPane tabId={3}>   
                <Row>
                  <Col lg={12}>
                    <Card> 
                      <CardBody className="">
                        <KpisManagementTable ClaProyecto={props.ClaProyecto} Refresh={ActualizarEncabezado} readOnly={true} onChange={actualizarEncabezado}></KpisManagementTable>
                      </CardBody>
                    </Card>
                  </Col>
                </Row> 
              </TabPane> 
              <TabPane tabId={4}>
                <Row>
                  <Col lg={12}>
                    <Card> 
                      <CardBody className="">
                        <Minutas ClaProyecto={props.ClaProyecto} readOnly={true} ClaTipoMinuta={1}></Minutas> 
                      </CardBody>
                    </Card>
                  </Col>
                </Row>  
              </TabPane>
              <TabPane tabId={5}>
                <Row>
                  <Col lg={12}>
                    <ProgressReportList ClaProyecto={props.ClaProyecto} readOnly={true}></ProgressReportList>
                  </Col>
                </Row>  
              </TabPane>
              <TabPane tabId={7}>
                <Row>
                  <Col lg={12}>
                    <Documentos ClaProyecto={props.ClaProyecto}></Documentos>
                  </Col>
                </Row>  
              </TabPane>
            </TabContent>
          </Col>
        </Row> 
      </>
  )
  }

    return (
      <>          
        <Row hidden={props.EsMostrarEditarProyec}>
          <Col lg={12} className='text-left'>
            <Link 
              title={t('caratulaproyecto:ReturnMyProjects')}  
              to={{ pathname: "MyProjects" }}
            >          
              <i className="fas fa-arrow-circle-left"></i> {t('caratulaproyecto:ReturnMyProjects')} 
            </Link>  
          </Col>
        </Row>
        <Row>
          <Col>
                &nbsp;
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Card> 
              <CardBody className="">
                <ProyectoDetalle ClaProyecto={props.ClaProyecto} refrescarInformacion={ActualizarEncabezado}></ProyectoDetalle>
              </CardBody>
            </Card>
           
          </Col>
        </Row>
        <Row>
          <Col>
                &nbsp;
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="p-tabview p-component pb-3">
              <Nav className="p-tabview-nav tabs-reverse"> 
                <NavItem className={TabActiveIndex === 7 ? "p-tabview-selected p-highlight p-unselectable-text nav-item " : "p-unselectable-text nav-item "}>
                  <NavLink className="p-tabview-nav-link" onClick={() => { switchTab(7); }}>
                    {t('caratulaproyecto:Documents')}
                  </NavLink>
                </NavItem> 
                <NavItem className={TabActiveIndex === 6 ? "p-tabview-selected p-highlight p-unselectable-text nav-item " : "p-unselectable-text nav-item "}>
                  <NavLink className="p-tabview-nav-link" onClick={() => { switchTab(6); }}>
                    {t('caratulaproyecto:ProgressReport')}
                  </NavLink>
                </NavItem>
                <NavItem className={TabActiveIndex === 5 ? "p-tabview-selected p-highlight p-unselectable-text nav-item " : "p-unselectable-text nav-item "}>
                  <NavLink className="p-tabview-nav-link" onClick={() => { switchTab(5); }}>
                    {t('caratulaproyecto:MeetingMinutes')}
                  </NavLink>
                </NavItem>
                
                { EsProyectoMaestro === 0 && ClaTipoProyecto !== 4 && ClaTipoProyecto !== 5 && ClaTipoProyecto !== 6 ? 
                  (
                    <NavItem className={TabActiveIndex === 3 ? "p-tabview-selected p-highlight p-unselectable-text nav-item " : "p-unselectable-text nav-item "}>
                      <NavLink className="p-tabview-nav-link" onClick={() => { switchTab(3); }}>
                        {t('caratulaproyecto:PhasesActivities')}
                      </NavLink>
                    </NavItem>
) 
                : (
                  <NavItem className={TabActiveIndex === 8 ? "p-tabview-selected p-highlight p-unselectable-text nav-item " : "p-unselectable-text nav-item "}>
                    <NavLink className="p-tabview-nav-link" onClick={() => { switchTab(8); }}>
                      {t('caratulaproyecto:Projects')}
                    </NavLink>
                  </NavItem>
)}
                
                <NavItem className={TabActiveIndex === 2 ? "p-tabview-selected p-highlight p-unselectable-text nav-item " : "p-unselectable-text nav-item "}>
                  <NavLink className="p-tabview-nav-link" onClick={() => { switchTab(2); }}>
                    {t('caratulaproyecto:KPIsManagement')}
                  </NavLink>
                </NavItem>
                <NavItem className={TabActiveIndex === 4 ? "p-tabview-selected p-highlight p-unselectable-text nav-item " : "p-unselectable-text nav-item "}>
                  <NavLink className="p-tabview-nav-link" onClick={() => { switchTab(4); }}>
                    {t('caratulaproyecto:TeamMembers')}
                  </NavLink>
                </NavItem>
                <NavItem className={TabActiveIndex === 1 ? "p-tabview-selected p-highlight p-unselectable-text nav-item " : "p-unselectable-text nav-item "}>
                  <NavLink className="p-tabview-nav-link" onClick={() => { switchTab(1); }}>
                    {t('caratulaproyecto:OverView')}
                  </NavLink>
                </NavItem>
              </Nav> 
            </div>
            <TabContent className="p-tabview-panels" activeTab={TabActiveIndex}>
              <TabPane tabId={1}>   
                <Row>
                  <Col lg={12}>
                    <Card> 
                      <CardBody className="">
                        <CaratulaProyecto ClaProyecto={props.ClaProyecto} onChange={actualizarEncabezado}></CaratulaProyecto>
                      </CardBody>
                    </Card>
                  </Col>
                </Row> 
              </TabPane>
              <TabPane tabId={2}> 
                <Row>
                  <Col lg={12}>
                    <Card> 
                      <CardBody className="">
                        <KpisManagementTable ClaProyecto={props.ClaProyecto} Refresh={ActualizarEncabezado} onChange={actualizarEncabezado}></KpisManagementTable>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>   
              </TabPane>
              <TabPane tabId={3}>
                <Card> 
                  <CardBody className="">
                    <Row>  
                      <Col lg={4} md={4} sm={12} xs={12}>
                        <i className="fas fa-exclamation-triangle warning-color bold"></i>&nbsp;<span>{t('misActividades:Delayed')}</span> 
                      </Col>
                      <Col lg={8} md={8} sm={12} xs={12} className="text-right">    
                        <FieldButton label={t('caratulaproyecto:ActualizarPesos')} icon="fas fa-bolt" className="info no-border p-mr-2 rounded-button" onClick={(e) => onCloseActualizarPesoFases()}   /> 
                  &nbsp;
                        { !EsMostrarGantt ?  (
                          <FieldButton
                            label={t('caratulaproyecto:GanttView')}
                            icon="fas fa-align-left"
                            className="secondary no-border p-mr-2 rounded-button"
                            onClick={(e) => { setEsMostrarGantt(true); 
                            tracker.trackEvent({
                              type: 'EdicionProyecto', /* component name */
                              action: 'CLICK_GANTVIEW'
                            }) 
                          }}
                          /> 
                          )
                          :    
                          (
                            <FieldButton label={t('caratulaproyecto:NormalView')} icon="fas fa-align-left" className="secondary no-border p-mr-2 rounded-button" onClick={(e) => setEsMostrarGantt(false)}  /> 
                          )}
                   &nbsp;
                        <FieldButton label={t('caratulaproyecto:CrearFase')} icon="pi pi-plus" className="light-green no-border p-mr-2 rounded-button" onClick={onClickNuevaFase}  /> 
                      </Col>  
                    </Row>
                  </CardBody>
                </Card> 
                { !EsMostrarGantt ?
                 (
                   <Row>
                     <Col lg={12}>
                   
                       {
                        FasesProyecto.map((item) => {
                            return ( 
                              <div key={item.ClaFase}>
                                <Fases ClaProyecto={item.ClaProyecto} ClaFase={item.ClaFase} onChange={onChangeFase}></Fases>
                                <div>&nbsp;</div>
                              </div>
                            )
                            }
                        )
                   }
                    
                     </Col>
                   </Row>  
                 )
                 :
                 (
                   <>
                     <ProjectGantt ClaProyecto={props.ClaProyecto} refrescarInformacion={ActualizarEncabezado} onChange={() => setActualizarEncabezado(ActualizarEncabezado+1)}></ProjectGantt>
                   </>
              )}
              </TabPane>
              <TabPane tabId={8}>
                <Card> 
                  <CardBody className="">
                    <Row>  
                      <Col lg={12} md={12} sm={12} xs={12} className="text-right">    
                       
                        { !EsMostrarGanttProjectMasters ?  (
                          <FieldButton label={t('caratulaproyecto:GanttView')} icon="fas fa-align-left" className="secondary no-border p-mr-2 rounded-button" onClick={(e) => setEsMostrarGanttProjectMasters(true)}  /> 
                          )
                          :    
                          (
                            <FieldButton label={t('caratulaproyecto:NormalView')} icon="fas fa-align-left" className="secondary no-border p-mr-2 rounded-button" onClick={(e) => setEsMostrarGanttProjectMasters(false)}  /> 
                          )} 
                      </Col>  
                    </Row>
                  </CardBody>
                </Card> 
                { !EsMostrarGanttProjectMasters ?
                 (
                   <>
                     <Card> 
                       <CardBody className="">
                         <ProyectoMaestroDetalle ClaProyecto={props.ClaProyecto} onChange={actualizarEncabezado} onOpenAnotherProjectClick={onOpenAnotherProjectClick}></ProyectoMaestroDetalle>
                       </CardBody>
                     </Card> 
                   </>
                 )
                 :
                 (
                   <> 
                     <ProjectGanttMaster ClaProyecto={props.ClaProyecto}></ProjectGanttMaster>
                   </>
              )}
              </TabPane>
              <TabPane tabId={4}>
                <Row>
                  <Col lg={12}>
                    <Card> 
                      <CardBody className="">
                        <Row>
                          <Col className='text-right'>
                            {  IsTeamMemberReviewed ? (<></>)
                              :
                              (<FieldButton label={t('caratulaproyecto:confirmTeamReviewed')} className="p-button-success p-mr-2 no-border rounded-button" onClick={onConfirmReviewed} /> 
                              )}
                            {
                              /*
                              {t('caratulaproyecto:confirmTeamReviewed')} 
                            &nbsp;
                            <FieldToogleSwitch
                              id="IsTeamMemberReviewed"
                              checked={IsTeamMemberReviewed}  
                              onChange={setIsTeamMemberReviewed} 
                              optionLabels={yesNoOptions} 
                              small
                            />
                             */
                            }
                            
                          </Col>
                        </Row>
                        <ProyectoInvolucrados ClaProyecto={props.ClaProyecto} onChange={() => setActualizarEncabezado(ActualizarEncabezado+1)}></ProyectoInvolucrados>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>  
              </TabPane>
              <TabPane tabId={5}>
                <Row>
                  <Col lg={12}>
                    <Card> 
                      <CardBody className="">
                        <Minutas ClaProyecto={props.ClaProyecto} ClaTipoMinuta={1}></Minutas> 
                      </CardBody>
                    </Card>
                  </Col>
                </Row>  
              </TabPane>
              <TabPane tabId={6}>
                <Row>
                  <Col lg={12}>
                    <ProgressReportList ClaProyecto={props.ClaProyecto}></ProgressReportList>
                  </Col>
                </Row>  
              </TabPane>
              <TabPane tabId={7}>
                <Row>
                  <Col lg={12}>
                    <Documentos ClaProyecto={props.ClaProyecto}></Documentos>
                  </Col>
                </Row>  
              </TabPane>
            </TabContent>
          </Col>
        </Row>
        {
          EsMostrarActualizarPeso ?
          (<FasesProyectosPorcPesoList ClaProyecto={props.ClaProyecto} IsVisible={EsMostrarActualizarPeso} onClose={() => setEsMostrarActualizarPeso(false)} onChange={ProyectosPorcPesoList}></FasesProyectosPorcPesoList>
          ):
          (<></>)
        }
        {
         EsCrearNuevaFase != null ? (
           <> 
             <FasesDetalle IsVisible={EsCrearNuevaFase} onClickClose={onChangeEsFaseDetalle} onChange={onChangeFase} ClaProyecto={props.ClaProyecto} ClaFase={0} />
           </>
          ):(<></>)
        }
        {
          IsShowingAnotherProyect &&
          (
          <Dialog          
            visible={IsShowingAnotherProyect}
            modal
            style={{ width: '100vw' }}
            footer={renderAnotherProjectDialogFooter}
            header={renderAnotherProjectDialogHeader}
            onHide={onHideAnotherProject}
            closable={false}
            className="dialog-header-background-blue"
            maximized={true}
            draggable={false}
            blockScroll={true}
          >
            <EdicionProyecto ClaProyecto={AnotherClaProyecto} EsMostrarEditarProyec={IsShowingAnotherProyect}></EdicionProyecto>
          </Dialog> 
          )
        }     
      </>  
        ); 
};

export default EdicionProyecto;

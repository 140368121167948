/* eslint-disable no-const-assign */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { config } from 'utils/config';
import { callApi, showSweetAlert, showNotify } from 'utils/utils';
import FieldSelectButton from 'components/Controls/FieldSelectButton';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import YupValidator from 'utils/YupValidator';
import * as yup from 'yup';
import { Dialog } from 'primereact/dialog';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import { Column } from 'primereact/column'; 
import FieldText from 'components/Controls/FieldText';
import FieldNumber from 'components/Controls/FieldNumber';
import moment from 'moment';
import FieldDataView from 'components/Controls/FieldDataView/FieldDataView';
import { KeyboardReturnOutlined } from '@material-ui/icons';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  ListGroupItem,
  ListGroup,
  Row,
  Col,
  FormGroup,
  Label,
} from 'reactstrap';
import './DonwTime.scss';
import { Badge } from 'primereact/badge';
import FieldDropdown from 'components/Controls/FieldDropdown'; 

const DonwTimeDetail = (props) => {
  const { t, i18n } = useTranslation(['downTime', 'common']);
  const [AreaDepartamentoList, setAreaDepartamentoList] = useState([]);
  const [CeldaList, setCeldaList] = useState([]);
  const [MaquinasList, setMaquinasList] = useState([]);
  const [TipoAndonList, setTipoAndonList] = useState([]);
  const [TipoParoAndonList, setTipoParoAndonList] = useState([]);
  const [RazonAndonList, setRazonAndonList] = useState([]);
  const [ClaMaquina, setClaMaquina] = useState(props.ClaMaquina === null || props.ClaMaquina === undefined? null:  props.ClaMaquina);
  const [ClaAreaDepartamento, setClaAreaDepartamento] = useState(null);
  const [TipoPreguntaPorMaquina, setTipoPreguntaPorMaquina] = useState({});
  const [ClaCelda, setClaCelda] = useState(props.IdCelda === null ? null : props.IdCelda);
  const [ClaTipoAndon, setClaTipoAndon] = useState(null);
  const [ClaTipoParoAndon, setClaTipoParoAndon] = useState(null);
  const [ClaRazonAndon, setClaRazonAndon] = useState(null);
  const [ClaTurno, setClaTurno] = useState(null);
  const [Causing, setCausing] = useState(null);
  const [CausingList, setCausingList] = useState([]);
  const [TurnoList, setTurnoList] = useState([]);
  const [errors, setErrors] = useState([]);
  const [EsDetieneProduccion, setEsDetieneProduccion] = useState(0);
  const [IdReporteAndon, setIdReporteAndon] = useState(null);
  const [ReporteAndonList, setReporteAndonList] = useState({});
  const [FixList, setFixList] = useState([]);
  const [IdFixProblem, setIdFixProblem] = useState(null);
  const [ClaTipoAndon2, setClaTipoAndon2] = useState(null);
  const [EsTipoConfirmacion, setEsTipoConfirmacion] = useState(null);
  const [EsEditarAD, setEsEditarAD] = useState(true);
  const [EsEditarCelda, setEsEditarCelda] = useState(false);
  const [EsEditarMaquina, setEsEditarMaquina] = useState(false);
  const [EsEditarCausing, setEsEditarCausing] = useState(false);
  const [EsEditarTipoAndon, setEsEditarTipoAndon] = useState(false);
  const [EsEditarTipoParoAndon, setEsEditarTipoParoAndon] = useState(false);
  const [EsEditarRazonAndon, setEsEditarRazonAndon] = useState(false);
  const [EsEditarTurno, setEsEditarTurno] = useState(false);
  const [EsEditarFixProblem, setEsEditarFixProblem] = useState(false);
  const [EsEditarTipoAndon2, setEsEditarTipoAndon2] = useState(false);
  const [ShowScrollButtons, setShowScrollButtons] = useState(true);
  const [IsPageLoad, setIsPageLoad] = useState(true);
  const [ComentarioRazonAndon, setComentarioRazonAndon] = useState('');
  const [NombreEmpleado, setNombreEmpleado] = useState('');
  const [SolucionAndon, setSolucionAndon] = useState('');
  const [layoutName, setLayoutName] = useState('');  
  const [EsEditarComentarioRazonAndon, setEsEditarComentarioRazonAndon] = useState(false);
  const [EsEditarSolucionAndon, setEsEditarSolucionAndon] = useState(false);
  const [EsEditarNombreEmpleado, setEsEditarNombreEmpleado] = useState(false);
  const [IsNewHomeVersion, setIsNewHomeVersion] = useState(false);
  const [ShowResponsableCheckIn, setShowResponsableCheckIn] = useState(false);
  const [EsEditarResponsableCheckIn, setEsEditarResponsableCheckIn] = useState(false);
  const [ResponsableCheckIn, setResponsableCheckIn] = useState('');
  const [ClaTipoAndonParoCheckIn, setClaTipoAndonParoCheckIn] =  useState(null);
  const [ResponsableResuelveProblema, setResponsableResuelveProblema] = useState(''); 
  const [EsEditarResponsableResuelveProblema, setEsEditarResponsableResuelveProblema] = useState(false);
    


  useEffect(() => {
    GetAreaDepartamento();
    GetTurnoActual();
    GetListYESNO();   
    setIsNewHomeVersion(
      props.IsNewHomeVersion === null || props.IsNewHomeVersion === undefined
        ? false
        :  Boolean(props.IsNewHomeVersion)
    );
    if (props.IsNewHomeVersion === null || props.IsNewHomeVersion === undefined
      ? false
      :  Boolean(props.IsNewHomeVersion)){

        setShowScrollButtons(false);
      }
    setClaTurno(props.ClaTurno === null || props.ClaTurno === undefined? null:  props.ClaTurno);
    setClaAreaDepartamento(props.ClaAreaDepartamento === null || props.ClaAreaDepartamento === undefined? null:  Number(props.ClaAreaDepartamento));    
    
    // setClaMaquina(props.ClaMaquina === null || props.ClaMaquina === undefined? null:  Number(props.ClaMaquina));  
  }, [props.IsNewHomeVersion, props.ClaTurno, props.ClaAreaDepartamento, props.IdCelda, props.ClaMaquina]);

  const GetListYESNO = () => {
    setCausingList([
      {
        IdCausing: 0,
        NomCausing: 'NO',
      },
      {
        IdCausing: 1,
        NomCausing: 'YES',
      },
    ]);
    setFixList([
      {
        IdFixProblem: 0,
        NomFix: 'NO',
      },
      {
        IdFixProblem: 1,
        NomFix: 'YES',
      },
    ]);
  };
  const GetTurnoActual = () => {
    const paramsService = {};
    const urlWebService = `${config.UrlApiProject}Operations/GetTurnoActual?ClaCelda=${props.IdCelda === null ? -1 : props.IdCelda}`;
    callApi(urlWebService, 'GET', paramsService, (response) => {
      setClaTurno(
        response.data.TurnoActual.length > 0 ? response.data.TurnoActual[0].ClaTurno : null
      );
      GetTurno();
    });
  };

  const GetTurno = () => {
    const paramsService = {};
    const urlWebService = `${config.UrlApiProject}Operations/GetTurno`;
    callApi(urlWebService, 'GET', paramsService, (response) => {
      setTurnoList(response.data.Turnos);
    });
  };

  const GetAreaDepartamento = () => {
    const urlWebService = `${config.UrlApiProject}departamento/getDepartamentos`;
    const paramsService = null;

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setAreaDepartamentoList(response.data.Departamentos);
      if (IsPageLoad){ 
        setIsPageLoad(false);
        if (props.IdCelda > 0 && props.ClaAreaDepartamento > 0){ 
          onAreaDepartamentoClick(props.ClaAreaDepartamento); 
          setClaAreaDepartamento(Number(props.ClaAreaDepartamento)); 
          setClaCelda(Number(props.IdCelda)); 
          GetMaquinasPorCelda(props.IdCelda, props.ClaAreaDepartamento); 
        } 
      }

    });
  };

  const onClaTurnoClick = (pClaTurno) => {
    LimpiaVariables();
    setClaTurno(pClaTurno);  
    setEsEditarTurno(pClaTurno === null); 
    setEsEditarAD(false);
    setEsEditarCelda(false);
    setEsEditarMaquina(false);
    setEsEditarCausing(false);
    setEsEditarTipoAndon(false);
    setEsEditarTipoParoAndon(false);
    setEsEditarRazonAndon(false);
    setEsEditarFixProblem(false);
  };

  const onAreaDepartamentoClick = (pClaAreaDepartamento) => {
    const newErrors = { ...errors };
    delete newErrors.ClaAreaDepartamento;
    setErrors(newErrors);
    LimpiaVariables();
    setClaAreaDepartamento(pClaAreaDepartamento);
    GetCelda(pClaAreaDepartamento);
    setEsEditarAD(pClaAreaDepartamento === null);
    setEsEditarCelda(false);
    setEsEditarMaquina(false);
    setEsEditarCausing(false);
    setEsEditarTipoAndon(false);
    setEsEditarTipoParoAndon(false);
    setEsEditarRazonAndon(false);
    setEsEditarFixProblem(false);
  };
  const onCeldaClick = (pIdCelda) => {
    const newErrors = { ...errors };
    delete newErrors.ClaCelda;
    setErrors(newErrors);
    LimpiaVariablesDesdeCelda();
    GetMaquinasPorCelda(pIdCelda, ClaAreaDepartamento);
    setTipoPreguntaPorMaquina(null);
    setClaCelda(pIdCelda);
    setEsEditarCelda(pIdCelda === null);
    setEsEditarMaquina(false);
    setEsEditarCausing(false);
    setEsEditarTipoAndon(false);
    setEsEditarTipoParoAndon(false);
    setEsEditarRazonAndon(false);
    setEsEditarFixProblem(false);
  };

  const GetCelda = (pClaAreaDepartamento) => {
    const urlWebService = `${config.UrlApiProject}celda/GetCeldas?ClaDepartamento=${pClaAreaDepartamento}`;
    const paramsService = null;

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setCeldaList(response.data.Celdas);
    });
  };

  const onMaquinaClick = (pClaMaquina) => {
    const newErrors = { ...errors };
    delete newErrors.ClaMaquina;
    setErrors(newErrors);
    LimpiaVariablesDesdeMaquina(); 
    setClaMaquina(pClaMaquina);
    GetTipoPreguntaPorMaquina(pClaMaquina);
    setEsEditarMaquina(pClaMaquina === null);
    setEsEditarCausing(false);
    setEsEditarTipoAndon(false);
    setEsEditarTipoParoAndon(false);
    setEsEditarRazonAndon(false);
    setEsEditarFixProblem(false);
  };

  const validationScheme5 = yup.object().shape({ 
    ResponsableCheckIn: yup.string().required(t('common:RequiredField')).nullable() 
  });


  const ConfirmarLlegadaResponsableCheckIn = async () => {
    const values = {
      ResponsableCheckIn
    }
    const validator2 = new YupValidator(validationScheme5);
      const isValid2 = await validator2.validate(values);
      if (!isValid2) {  
        setErrors(validator2.errors); 
        showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
        return;
    } 

    const urlWebService = `${config.UrlApiProject}Operations/SiConfirmallegada`;
        const paramsService = {
          ClaCelda,
          ClaDepartamento: ClaAreaDepartamento,
          ClaTurno,
          ClaMaquina,
          ResponsableCheckIn
        };

        callApi(urlWebService, 'POST', paramsService, (response) => {
          // showSweetAlert('success', t('common:SuccessOperation'), 'success');
          // busc
          GetReporteAndon(IdReporteAndon);
          setEsTipoConfirmacion(3);
          setShowResponsableCheckIn(false);
          setEsEditarResponsableCheckIn(false);
        });
  }

  const SiConfirmallegadaClick = (value) => {
    setEsEditarCausing(value === null);
    setEsEditarTipoAndon(false);
    setEsEditarTipoParoAndon(false);
    setEsEditarRazonAndon(false);
    setEsEditarFixProblem(false);
    setCausing(value);
     

    if (value === 1) {
      GetTipoAndon();
      if (TipoPreguntaPorMaquina === 1) {
        setEsDetieneProduccion(1);
      } 
      else if (TipoPreguntaPorMaquina === 2 && ClaTipoAndonParoCheckIn !== 1)
      {
        // TABLET - Make Checke in by field required on the tablets for everyone except for Maintenance
        // Aun no confirmamos ya que para set up nos tienen decir sus iniciales antes de confirmar la llegada
        setShowResponsableCheckIn(true);
        setEsEditarResponsableCheckIn(true);
      }
      else if (TipoPreguntaPorMaquina === 2) {
        const urlWebService = `${config.UrlApiProject}Operations/SiConfirmallegada`;
        const paramsService = {
          ClaCelda,
          ClaDepartamento: ClaAreaDepartamento,
          ClaTurno,
          ClaMaquina,
        };

        callApi(urlWebService, 'POST', paramsService, (response) => {
          // showSweetAlert('success', t('common:SuccessOperation'), 'success');
          // busc
          GetReporteAndon(IdReporteAndon);
          setEsTipoConfirmacion(3);
        });
      }
    } else if (TipoPreguntaPorMaquina === 1) {
      setEsDetieneProduccion(0);
      GetTipoAndon();
      // LimpiaVariables();
    } else if (TipoPreguntaPorMaquina === 2) {
      /* aqui limpiamos la pantalla por que no viene a hacer nada el usuario */
      /* Mandamos mensaje y limpia */
      LimpiaVariables();
      if (typeof(props.onSubmitDowntime) === 'function'){ 
        props.onSubmitDowntime();
      } 
    }
  };

  const onTipoAndonClick = (pClaTipoAndon) => {
    const newErrors = { ...errors };
    delete newErrors.ClaTipoAndon;
    setErrors(newErrors);

    setClaTipoAndon(pClaTipoAndon);
    GetTipoParoAndon(pClaTipoAndon);
    AltaAndon(pClaTipoAndon);
    setEsEditarTipoAndon(pClaTipoAndon === null);
    setEsEditarTipoParoAndon(false);
    setEsEditarRazonAndon(false);
    setEsEditarFixProblem(false);
  };

  const onTipoParoAndonClick = (pClaTipoParoAndon) => {
    const newErrors = { ...errors };
    delete newErrors.ClaTipoParoAndon;
    setErrors(newErrors);

    setClaTipoParoAndon(pClaTipoParoAndon);
    if (pClaTipoParoAndon === null) {
      setRazonAndonList([]);
    } else {
      GetRazonAndon(ClaTipoAndon, pClaTipoParoAndon);
    }
    setEsEditarTipoParoAndon(pClaTipoParoAndon === null);
    setEsEditarRazonAndon(true);
    setEsEditarFixProblem(false);
  };

  const onRazonAndonClick = (value) => {
    const newErrors = { ...errors };
    delete newErrors.ClaRazonAndon;
    setErrors(newErrors);

    setClaRazonAndon(value);
    setEsEditarRazonAndon(value === null);
    setEsEditarFixProblem(false);
    setEsEditarComentarioRazonAndon(true);
  };

  const onTipoAndon2Click = async (pClaTipoAndon) => {
   
    const values = {
      ClaTurno,
      ClaAreaDepartamento,
      ClaCelda,
      ClaMaquina,
      ClaTipoAndon,
      ClaTipoParoAndon,
      ClaRazonAndon,
      ComentarioRazonAndon,
      SolucionAndon,
      NombreEmpleado
    };

    // validamos que tenga comentario
    if (pClaTipoAndon === 1 || pClaTipoAndon === 5){
      const validator2 = new YupValidator(validationScheme3);
      const isValid2 = await validator2.validate(values);
      if (!isValid2) {  
        setErrors(validator2.errors);
        // setIdFixProblem(null); 
        setClaTipoAndon2(null);
        setClaTipoAndon(pClaTipoAndon);
        setEsEditarComentarioRazonAndon(ComentarioRazonAndon === null || ComentarioRazonAndon === '');
        setEsEditarNombreEmpleado(NombreEmpleado === null || NombreEmpleado === '');
        // setEsEditarSolucionAndon(SolucionAndon === null || SolucionAndon === '');
        showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
        return;
      }
    }

    setClaTipoAndon2(pClaTipoAndon);
    // setClaTipoAndon(null);
    SendFix(pClaTipoAndon, IdFixProblem);
  };

  const onIdFixProblemClick = async (value) => { 
    setEsEditarFixProblem(!EsEditarFixProblem);
    setEsEditarTipoAndon2(!EsEditarTipoAndon2);
    setIdFixProblem(value); 
    
    setEsEditarComentarioRazonAndon(false);
    setEsEditarNombreEmpleado(false);
    setEsEditarSolucionAndon(false);
    setErrors([]);
    const values = {
      ClaTurno,
      ClaAreaDepartamento,
      ClaCelda,
      ClaMaquina,
      ClaTipoAndon,
      ClaTipoParoAndon,
      ClaRazonAndon,
      ComentarioRazonAndon,
      SolucionAndon,
      NombreEmpleado
    };

    if (value === 1) {
      if (ClaTipoAndon === 1 || ClaTipoAndon === 5 || ClaTipoAndon === 3){
        const validator2 = new YupValidator(validationScheme2);
        const isValid2 = await validator2.validate(values);
        if (!isValid2) {  
          setErrors(validator2.errors);
          setIdFixProblem(null); 
          setEsEditarComentarioRazonAndon(ComentarioRazonAndon === null || ComentarioRazonAndon === '');
          setEsEditarNombreEmpleado(NombreEmpleado === null || NombreEmpleado === '');
          setEsEditarSolucionAndon(SolucionAndon === null || SolucionAndon === '');
          showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
          return;
        }
      } 
      
      // Valida que este capturado la persona que resolvio el problema
      if (ClaTipoAndon === 3 || ClaTipoAndon === 4 || ClaTipoAndon === 5){
        const camposRequeridos = {
          ResponsableResuelveProblema
        }
        const validator2 = new YupValidator(validationScheme6);
        const isValid2 = await validator2.validate(camposRequeridos);
        if (!isValid2) {  
          setErrors(validator2.errors);
          setIdFixProblem(null);  
          setEsEditarResponsableResuelveProblema(ResponsableResuelveProblema === null || ResponsableResuelveProblema === '');
          showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
          return;
        }
      }

      SendFix(ClaTipoAndon, value);
    }
    else if (ClaTipoAndon === 1 || ClaTipoAndon === 5){

      const validator = new YupValidator(validationScheme);
      const isValid = await validator.validate(values);
      if (!isValid) { 
        setErrors(validator.errors);
        setIdFixProblem(null);
        setEsEditarComentarioRazonAndon(ComentarioRazonAndon === null || ComentarioRazonAndon === '');
        setEsEditarNombreEmpleado(NombreEmpleado === null || NombreEmpleado === '');
        showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
        
      }
    }
  };

  const GetMaquinasPorCelda = (pClaCelda, pClaAreaDepartamento) => {
    const paramsService = {};
    const urlWebService = `${config.UrlApiProject}Operations/GetMaquinasPorCelda?ClaCelda=${pClaCelda}&ClaAreaDepartamento=${pClaAreaDepartamento}`;
    callApi(urlWebService, 'GET', paramsService, (response) => {
      setMaquinasList(response.data.Maquinas);
      if (IsPageLoad){ 
        setClaMaquina(props.ClaMaquina === null || props.ClaMaquina === undefined? null:  Number(props.ClaMaquina));  
        if (props.ClaMaquina === null || props.ClaMaquina === undefined? null:  Number(props.ClaMaquina) !== null){ 
          onMaquinaClick(props.ClaMaquina === null || props.ClaMaquina === undefined? null:  Number(props.ClaMaquina));
        }
      }
    });
  };

  const GetTipoAndon = () => {
    const paramsService = {};
    const urlWebService = `${config.UrlApiProject}Operations/GetTipoAndon`;
    callApi(urlWebService, 'GET', paramsService, (response) => {
      setTipoAndonList(response.data.TipoAndon);
    });
  };

  const GetTipoPreguntaPorMaquina = (claMaquina) => {
    const paramsService = {};
    const urlWebService = `${config.UrlApiProject}Operations/GetTipoPreguntaPorMaquina?ClaMaquina=${claMaquina}&ClaCelda=${ClaCelda}&ClaAreaDepartamento=${ClaAreaDepartamento===null?'':ClaAreaDepartamento}`;
    callApi(urlWebService, 'GET', paramsService, (response) => {
      setTipoPreguntaPorMaquina(response.data.TipoPreguntaPorMaquina[0].EsTipoConfirmacion);
      setIdReporteAndon(response.data.TipoPreguntaPorMaquina[0].IdReporteAndon);
      setEsTipoConfirmacion(response.data.TipoPreguntaPorMaquina[0].EsTipoConfirmacion);
      setClaTipoAndonParoCheckIn(response.data.TipoPreguntaPorMaquina[0].ClaTipoAndon);
      if (response.data.TipoPreguntaPorMaquina[0].EsTipoConfirmacion === 3) {
        // precarga la informacion
        GetReporteAndon(response.data.TipoPreguntaPorMaquina[0].IdReporteAndon);
      }
    });
  };

  const GetTipoParoAndon = (pClaTipoAndon) => {
    const urlWebService = `${config.UrlApiProject}Operations/GetTipoParoAndon`;
    const paramsService = {
      ClaMaquina,
    };

    callApi(urlWebService, 'POST', paramsService, (response) => {
      setTipoParoAndonList(response.data.TipoParoAndon);
    });
    // const paramsService = {
    //     ClaMaquina
    // };
    // const urlWebService = `${config.UrlApiProject}Operations/GetTipoParoAndon}`;
    // callApi(urlWebService, 'POST', paramsService, (response) => {
    //   setTipoParoAndonList(response.data.TipoParoAndon);
    // });
  };

  const GetRazonAndon = (nClaTipoAndon, nClaTipoParoAndon) => {
    const paramsService = {
      ClaTipoAndon: nClaTipoAndon,
      ClaTipoParoAndon: nClaTipoParoAndon,
    };
    const urlWebService = `${config.UrlApiProject}Operations/GetRazonAndon`;
    callApi(urlWebService, 'POST', paramsService, (response) => {
      setRazonAndonList(response.data.RazonAndon);
    });
  };

  const showActiveRows = (rows) => {
    const activeRows = [];
    if (rows !== undefined) {
      if (rows.length > 0) {
        rows.forEach((item, index) => {
          if (!item.BajaLogica || item.BajaLogica === 0 || item.BajaLogica === undefined) {
            item.BajaLogica = 0;
            item.RowIndex = index;
            activeRows.push({ ...item });
          }
        });
      } else {
        return [];
      }
    }

    return activeRows;
  };

  const TipoPregunta = (tipoPregunra) => {
    let pregunta = '';
    switch (tipoPregunra) {
      case 1:
        pregunta = t('downTime:CausingDowntime');
        break;
      case 2:
        pregunta = t('downTime:ConfirmationArrive');
        break;
      default:
        pregunta = '';
        break;
    }

    return (
      <>
        {pregunta}
      </>
    );
  };

  const AltaAndon = (nClaTipoAndon) => {
    const urlWebService = `${config.UrlApiProject}Operations/AltaAndon`;
    const paramsService = {
      ClaCelda,
      ClaDepartamento: ClaAreaDepartamento,
      ClaTurno,
      ClaTipoAndon: nClaTipoAndon,
      ClaTipoParoAndon: null,
      ClaRazonAndon: null,
      ClaMaquina,
    };

    callApi(urlWebService, 'POST', paramsService, (response) => {
      const alta = response.data.AltaAndon.length > 0 ? response.data.AltaAndon[0] : {};
      setClaTipoParoAndon(alta.ClaTipoParoAndon);
      setClaRazonAndon(alta.ClaRazonAndon);
      onTipoParoAndonClick(alta.ClaTipoParoAndon);
      // showSweetAlert('success', t('common:SuccessOperation'), 'success');
    });
  };

  const SendAlert = async (e) => {
    const value = {
      ClaTurno,
      ClaAreaDepartamento,
      ClaCelda,
      ClaMaquina,
      ClaTipoAndon,
      ClaTipoParoAndon,
      ClaRazonAndon,
      ComentarioRazonAndon,
      NombreEmpleado
    };

    const validator = new YupValidator(validationScheme);
    const isValid = await validator.validate(value);
    if (!isValid) {
      setErrors(validator.errors);
      setEsEditarComentarioRazonAndon(true);
      setEsEditarNombreEmpleado(true);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      return;
    }

    const urlWebService = `${config.UrlApiProject}Operations/SendAlert`;
    const paramsService = {
      ClaCelda,
      ClaDepartamento: ClaAreaDepartamento,
      ClaTurno,
      ClaTipoAndon,
      ClaTipoParoAndon,
      ClaRazonAndon,
      ComentarioRazonAndon,
      ClaMaquina,
      EsDetieneProduccion,
      NombreEmpleado
    };

    callApi(urlWebService, 'POST', paramsService, (response) => {
      setErrors([]);
      
      LimpiaVariables();

      if (typeof(props.onSubmitDowntime) === 'function'){
        showNotify(t('common:SuccessOperation'), 'centerTop', 'success'); 
        props.onSubmitDowntime();
      }
      else{ 
        showSweetAlert('success', t('common:SuccessOperation'), 'success');
      }
    });
  };

  const SendFix = async (pClaTipoAndon, pIdFixProblem) => {
     
    const value = {
      ClaTurno,
      ClaAreaDepartamento,
      ClaCelda,
      ClaMaquina,
      ClaTipoAndon: pClaTipoAndon,
      ClaTipoParoAndon,
      ClaRazonAndon, 
    };

    const validator = new YupValidator(validationScheme4);
    const isValid = await validator.validate(value);
    if (!isValid) {
      setErrors(validator.errors); 
      setClaTipoAndon2(null);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      return;
    } 

    const urlWebService = `${config.UrlApiProject}Operations/SendFix`;
    const paramsService = {
      ClaDepartamento: ClaAreaDepartamento,
      ClaTurno,
      ClaCelda,
      ClaTipoAndon: pClaTipoAndon,
      ClaRazonAndon,
      ComentarioRazonAndon,
      SolucionAndon,
      IdFixProblem: pIdFixProblem,
      IdReporteAndon,
      ClaTipoParoAndon,
      ClaMaquina,
      EsDetieneProduccion,
      NombreEmpleado,
      ResponsableResuelveProblema 
    };

    callApi(urlWebService, 'POST', paramsService, (response) => {
      setErrors([]);
      LimpiaVariables(); 

      if (typeof(props.onSubmitDowntime) === 'function'){
        showNotify(t('common:SuccessOperation'), 'centerTop', 'success'); 
        props.onSubmitDowntime();
      }
      else{ 
        showSweetAlert('success', t('common:SuccessOperation'), 'success');
      }
    });
  };

  const validationScheme = yup.object().shape({
    ClaTurno: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaAreaDepartamento: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaCelda: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaMaquina: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaTipoAndon: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaTipoParoAndon: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaRazonAndon: yup.mixed().required(t('common:RequiredField')).nullable(),
    ComentarioRazonAndon: (
      ClaTipoAndon === 1 || ClaTipoAndon === 5
      ? yup.string().required(t('common:RequiredField')).nullable() 
      : yup.string().nullable() 
    ),
    NombreEmpleado: (
      ClaTipoAndon === 1 || ClaTipoAndon === 5
      ? yup.string().required(t('common:RequiredField')).nullable() 
      : yup.string().nullable() 
    ),
    SolucionAndon: (
      (ClaTipoAndon === 1 || ClaTipoAndon === 5) && IdFixProblem === 1
      ? yup.string().required(t('common:RequiredField')).nullable() 
      : yup.string().nullable() 
    ) 
  });

  const validationScheme2 = yup.object().shape({
    ClaTurno: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaAreaDepartamento: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaCelda: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaMaquina: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaTipoAndon: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaTipoParoAndon: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaRazonAndon: yup.mixed().required(t('common:RequiredField')).nullable(),
    ComentarioRazonAndon: (
      ClaTipoAndon === 1 || ClaTipoAndon === 5
      ? yup.string().required(t('common:RequiredField')).nullable() 
      : yup.string().nullable() 
    ),
    NombreEmpleado: (
      ClaTipoAndon === 1 || ClaTipoAndon === 5 
      ? yup.string().required(t('common:RequiredField')).nullable() 
      : yup.string().nullable() 
    ),
    SolucionAndon: (
      (ClaTipoAndon === 1 || ClaTipoAndon === 5) ? yup.string().required(t('common:RequiredField')).nullable() 
      : yup.string().nullable() 
    ) 
  });

  const validationScheme3 = yup.object().shape({
    ClaTurno: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaAreaDepartamento: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaCelda: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaMaquina: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaTipoAndon: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaTipoParoAndon: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaRazonAndon: yup.mixed().required(t('common:RequiredField')).nullable(),
    ComentarioRazonAndon: yup.string().required(t('common:RequiredField')).nullable() ,
    NombreEmpleado: yup.string().required(t('common:RequiredField')).nullable() 
  });

  const validationScheme4 = yup.object().shape({
    ClaTurno: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaAreaDepartamento: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaCelda: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaMaquina: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaTipoAndon: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaTipoParoAndon: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaRazonAndon: yup.mixed().required(t('common:RequiredField')).nullable() 
  });

  const validationScheme6 = yup.object().shape({ 
    ResponsableResuelveProblema: yup.string().required(t('common:RequiredField')).nullable() 
  });


  const GetReporteAndon = (nIdReporteAndon) => {
    const paramsService = {};
    const urlWebService = `${config.UrlApiProject}Operations/GetReporteAndon?IdReporteAndon=${nIdReporteAndon}`;
    callApi(urlWebService, 'GET', paramsService, (response) => {
      const reporteAndon =
        response.data.ReporteAndon.length > 0 ? response.data.ReporteAndon[0] : {};
      setClaTipoAndon(reporteAndon.ClaTipoAndon);
      setClaTipoParoAndon(reporteAndon.ClaTipoParoAndon);
      setClaRazonAndon(reporteAndon.ClaRazonAndon);
      setComentarioRazonAndon(reporteAndon.ComentarioRazonAndon);
      setNombreEmpleado(reporteAndon.NombreEmpleado);
      // mandamos llenar los combos
      GetTipoAndon();
      GetTipoParoAndon(reporteAndon.ClaTipoAndon);
      GetRazonAndon(reporteAndon.ClaTipoAndon, reporteAndon.ClaTipoParoAndon);
      if (reporteAndon.ClaTipoAndon !== null) {
        setEsEditarTipoAndon(false);
      }
      if (reporteAndon.ClaTipoParoAndon !== null) {
        setEsEditarTipoParoAndon(false);
      }
      if (reporteAndon.ClaRazonAndon !== null) {
        setEsEditarRazonAndon(false);
      }
    });
  };

  const TipoAndonButton = (tipoAndon, name) => {
    let severityClass = '';
    switch (tipoAndon) {
      case 1:
        severityClass = 'downtime-score-square-red-gradient';
        break;
      case 2:
        severityClass = 'downtime-score-square-purple-gradient';
        break;
      case 3:
        severityClass = 'downtime-score-square-yellow-gradient';
        break;
      case 4:
        severityClass = 'downtime-score-square-blue-gradient';
        break;
      case 5:
        severityClass = 'downtime-score-square-green-gradient';
        break;
      case 6:
        severityClass = 'downtime-score-square-strongblue-gradient';
        break;
      default:
        severityClass = '';
        break;
    }

    return (
      <>
        <div className="downtime-score-square-continer">
          <div>
            <FieldButton
              label={name}
              className={`${severityClass} no-border downtime-score-square font-weight-bold downtime-score-cursor-square`}
              style={{ textAlign: 'center', padding: '0px' }}
              onClick={() => onTipoAndonClick(tipoAndon)}
            >
              {ClaTipoAndon === tipoAndon ? (
                <div className="downtime-square-right-top-badge">
                  <Badge
                    value={<i className="fa fa-check light-green-color downtime-square-check"></i>}
                    className="downtime-sqare-badge"
                  >                    
                  </Badge>
                </div>
              ) : (
                <></>
              )}
            </FieldButton>
          </div>
        </div>
      </>
    );
  };

  const TipoAndon2 = (tipoAndon, name) => {
    let severityClass = '';
    switch (tipoAndon) {
      case 1:
        severityClass = 'downtime-score-square-red-gradient';
        break;
      case 2:
        severityClass = 'downtime-score-square-purple-gradient';
        break;
      case 3:
        severityClass = 'downtime-score-square-yellow-gradient';
        break;
      case 4:
        severityClass = 'downtime-score-square-blue-gradient';
        break;
      case 5:
        severityClass = 'downtime-score-square-green-gradient';
        break;
      case 6:
        severityClass = 'downtime-score-square-strongblue-gradient';
        break;
      default:
        severityClass = '';
        break;
    }

    return (
      <>
        <div className="downtime-score-square-continer">
          <div>
            <FieldButton
              label={name}
              className={`${severityClass} no-border downtime-score-square font-weight-bold downtime-score-cursor-square`}
              style={{ textAlign: 'center', padding: '0px' }}
              onClick={() => onTipoAndon2Click(tipoAndon)}
            >
              {ClaTipoAndon2 === tipoAndon ? (
                <div className="downtime-square-right-top-badge">
                  <Badge
                    value={<i className="fa fa-check light-green-color downtime-square-check"></i>}
                    className="downtime-sqare-badge"
                  >                    
                  </Badge>
                </div>
              ) : (
                <></>
              )}
            </FieldButton>
          </div>
        </div>
      </>
    );
  };

  const LimpiaVariables = () => { 
    setClaAreaDepartamento(null);
    setClaCelda(null);
    setClaMaquina(null);
    setTipoPreguntaPorMaquina(null);
    setCausing(null);
    setClaTipoAndon(null);
    setClaTipoParoAndon(null);
    setClaRazonAndon(null);
    setIdReporteAndon(null);
    setIdFixProblem(null);
    setCeldaList([]);
    setMaquinasList([]);
    setTipoAndonList([]);
    setTipoParoAndonList([]);
    setRazonAndonList([]);
    setEsEditarTurno(false);
    setEsEditarAD(false);
    setEsEditarCelda(false);
    setEsEditarMaquina(false);
    setEsEditarCausing(false);
    setEsEditarTipoAndon(false);
    setEsEditarTipoParoAndon(false);
    setEsEditarRazonAndon(false);
    setEsEditarFixProblem(false);
    setEsEditarComentarioRazonAndon(false);
    setEsEditarRazonAndon(false);
    setComentarioRazonAndon(null);
    setSolucionAndon(null); 
    setClaTipoAndon2(null);
    setNombreEmpleado(null);
  };

  const LimpiaVariablesDesdeCelda = () => {
    setClaMaquina(null);
    setTipoPreguntaPorMaquina(null);
    setCausing(null);
    setClaTipoAndon(null);
    setClaTipoParoAndon(null);
    setClaRazonAndon(null);
    setIdReporteAndon(null);
    setIdFixProblem(null);
    setClaTipoAndon2(null);
    setMaquinasList([]);
    setTipoAndonList([]);
    setTipoParoAndonList([]);
    setRazonAndonList([]); 
  };

  const LimpiaVariablesDesdeMaquina = () => {
    // setTipoPreguntaPorMaquina(null);
    setCausing(null);
    setClaTipoAndon(null);
    setClaTipoParoAndon(null);
    setClaRazonAndon(null);
    setIdReporteAndon(null);
    setIdFixProblem(null);
    setClaTipoAndon2(null);
    setTipoParoAndonList([]);
    setRazonAndonList([]); 
  };

  const LimpiaVariablesDesdeCausing = () => {
    setClaTipoAndon(null);
    setClaTipoParoAndon(null);
    setClaRazonAndon(null);
    setClaTipoAndon2(null); 
  };

  const LabelTurno = (value) => {
    const turno = TurnoList.filter((opt) => opt.ClaTurno === value);
    let nomTurno = '';
    if (TurnoList.length > 0) {
      nomTurno = turno[0].NomTurno;
    }
    return nomTurno;
  };

  const LabelAreaDepartamento = (value) => { 
    const ad = AreaDepartamentoList.filter((opt) => opt.ClaDepartamento4 === value);
    return ad.length > 0 ? ad[0].NomDepartamento4 : '';
  };
  const LabelCell = (value) => {
    const cell = CeldaList.filter((opt) => opt.ClaCelda === value);
    let claveCelda = '';
    if (cell.length > 0) {
      claveCelda = cell[0].ClaveCelda;
    }
    return claveCelda;
  };

  const LabelMaquina = (value) => {
    const maquina = MaquinasList.filter((opt) => opt.ClaMaquina === value);
    let nomMaquina = '';
    if (maquina.length > 0) {
      nomMaquina = maquina[0].NomMaquina;
    }
    return nomMaquina;
  };

  const LabelCausing = (value) => {
    const causing = CausingList.filter((opt) => opt.IdCausing === value);
    let nomCausing = '';
    if (causing.length > 0) {
      nomCausing = causing[0].NomCausing;
    }
    return nomCausing;
  };

  const LabelTipoAndon = (value) => {
    const tipoAndon = TipoAndonList.filter((opt) => opt.ClaTipoAndon === value);
    let nomTipoAndon = '';
    if (tipoAndon.length > 0) {
      nomTipoAndon = tipoAndon[0].NomTipoAndon;
    }
    return nomTipoAndon;
  };

  const LabelTipoParoAndon = (value) => {
    const claTipoParoAndon = TipoParoAndonList.filter((opt) => opt.ClaTipoParoAndon === value);
    let nomTipoParoAndon = '';
    if (claTipoParoAndon.length > 0) {
      nomTipoParoAndon = claTipoParoAndon[0].NomTipoParoAndon;
    }
    return nomTipoParoAndon;
  };

  const LabelRazonAndon = (value) => {
    const claRazonAndon = RazonAndonList.filter((opt) => opt.ClaRazonAndon === value);
    let nomRazonAndon = '';
    if (claRazonAndon.length > 0) {
      nomRazonAndon = claRazonAndon[0].NomRazonAndon;
    }
    return nomRazonAndon;
  };

  const LabelFix = (value) => {
    const idFixProblem = FixList.filter((opt) => opt.IdFixProblem === value);
    let nomFix = '';
    if (idFixProblem.length > 0) {
      nomFix = idFixProblem[0].NomFix;
    }
    return nomFix;
  };

  const onScrollUp = () => { 
    window.scrollTo(0, window.scrollY-200);
  }

  const onScrollDown = () => {
    window.scrollTo(0, window.scrollY+200);
  }

  const onChangeComentarioRazonAndon = (e) => { 
      const newErrors = { ...errors } 
      delete newErrors.ComentarioRazonAndon;   
      setErrors(newErrors);
      setComentarioRazonAndon(e.target.value);
      setEsEditarComentarioRazonAndon(e.target.value === null || e.target.value === '');
      setIdFixProblem(null);
      // keyboardRef.current.setInput(e.target.value);
  }

  const onChangeNombreEmpleado = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.NombreEmpleado;   
    setErrors(newErrors);
    setNombreEmpleado(e.target.value);
    setEsEditarNombreEmpleado(e.target.value === null || e.target.value === '');
    setIdFixProblem(null);
    // keyboardRef.current.setInput(e.target.value);
 }

 const onChangeResponsableCheckIn = (e) => { 
  const newErrors = { ...errors } 
  delete newErrors.ResponsableCheckIn;   
  setErrors(newErrors);
  setResponsableCheckIn(e.target.value);
  setEsEditarResponsableCheckIn(e.target.value === null || e.target.value === '');
  setIdFixProblem(null);
  // keyboardRef.current.setInput(e.target.value);
}

const onChangeResponsableResuelveProblema = (e) => {
  const newErrors = { ...errors } 
  delete newErrors.ResponsableResuelveProblema;   
  setErrors(newErrors);
  setResponsableResuelveProblema(e.target.value);
  setEsEditarResponsableResuelveProblema(e.target.value === null || e.target.value === '');
  setIdFixProblem(null);
}

  const onChangeSolucionAndon = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.SolucionAndon;   
    setErrors(newErrors);
    setSolucionAndon(e.target.value);
    setEsEditarSolucionAndon(e.target.value === null || e.target.value === '');
    setIdFixProblem(null);
    // keyboardRef.current.setInput(e.target.value);
}  
 
  return (
    <>
      {
        IsNewHomeVersion ? (<></>)
        :
        (
          <>
            {ClaTurno === null || EsEditarTurno ? (
              <div className='Tablet-Field'>
                <Row className='pb-1'>
                  <Col>
                    <span className="h3">{t('downTime:Turn')}</span>
                  </Col>
                  {ClaTurno !== null ? (
                    <>
                      <Col className="text-right">
                        <FieldButton
                          icon="pi pi-times"
                          onClick={() => setEsEditarTurno(!EsEditarTurno)}
                          className="p-button-rounded p-button-danger"
                        />
                      </Col>
                    </>
            ) : (
              <></>
            )}
                </Row>
                <Row className="text-center">
                  <Col>
                    <div className="p-fluid">
                      <FieldSelectButton
                        name="ClaTurno"
                        value={ClaTurno}
                        options={TurnoList}
                        onChange={(e) => onClaTurnoClick(e.value)}
                        optionValue="ClaTurno"
                        optionLabel="NomTurno"
                        errors={errors}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
                  </Col>
                </Row>
              </div>
      ) : (
        <div onClick={() => setEsEditarTurno(!EsEditarTurno)} className="Tablet-Field-Read-Only">
          <Row>
            <Col>
              {t('downTime:Turn')} 
            </Col>
            <Col className="text-right"><span className="tablet-label-value">{LabelTurno(ClaTurno)}</span></Col>
          </Row>
        </div>
      )} 
            {ClaAreaDepartamento === null || EsEditarAD ? (
              <div className='Tablet-Field'>
                <Row className='pb-1'>
                  <Col>
                    <span className="h3">{t('downTime:Department')}</span>
                  </Col>
                  {ClaAreaDepartamento !== null ? (
                    <>
                      <Col className="text-right">
                        <FieldButton
                          icon="pi pi-times"
                          onClick={() => setEsEditarAD(!EsEditarAD)}
                          className="p-button-rounded p-button-danger"
                        />
                      </Col>
                    </>
            ) : (
              <></>
            )}
                </Row>
                <Row className="text-center">
                  <Col>
                    <div className="p-fluid">
                      <FieldSelectButton
                        name="ClaAreaDepartamento"
                        value={ClaAreaDepartamento}
                        options={AreaDepartamentoList}
                        onChange={(e) => onAreaDepartamentoClick(e.value)}
                        optionValue="ClaDepartamento4"
                        optionLabel="NomDepartamento4"
                        errors={errors}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
      ) : (
        <div className='Tablet-Field-Read-Only' onClick={() => setEsEditarAD(!EsEditarAD)}>
          <Row>
            <Col>
              {t('downTime:Department')} 
            </Col>
            <Col className="text-right"><span className="tablet-label-value">{LabelAreaDepartamento(ClaAreaDepartamento)}</span></Col>
          </Row>
        </div>
      )}
            <></>

            {ClaCelda === null || EsEditarCelda ? (
              <>
                {ClaAreaDepartamento !== null ? (
                  <div className='Tablet-Field'>
                    <Row className='pb-1'>
                      <Col>
                        <span className="h3">{t('downTime:Cell')}</span>
                      </Col>
                      {ClaCelda !== null ? (
                        <>
                          <Col className="text-right">
                            <FieldButton
                              icon="pi pi-times"
                              onClick={() => setEsEditarCelda(!EsEditarCelda)}
                              className="p-button-rounded p-button-danger"
                            />
                          </Col>
                        </>
                ) : (
                  <></>
                )}
                    </Row>
                    <Row className="pt-2 text-center">
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <FieldSelectButton
                          name="ClaCelda"
                          value={ClaCelda}
                          options={CeldaList}
                          className="cellList"
                          onChange={(e) => onCeldaClick(e.value)}
                          optionValue="ClaCelda"
                          optionLabel="ClaveCelda"
                          errors={errors}
                        />
                      </Col>
                    </Row>
                  </div>
          ) : (
            <></>
          )}
              </>
      ) : (
        <div className='Tablet-Field-Read-Only' onClick={() => setEsEditarCelda(!EsEditarCelda)}>
          <Row>
            <Col>
              {t('downTime:Cell')}
            </Col>
            <Col className="text-right"><span className="tablet-label-value">{LabelCell(ClaCelda)}</span></Col>
          </Row>
        </div>
      )}
          </>
        )
      }
      
      {ClaMaquina === null || EsEditarMaquina ? (
        <>
          {ClaCelda != null ? (
            <div className='Tablet-Field'> 
              <Row className='pb-1'>
                <Col>
                  <span className="h3">{t('downTime:PleaseSelectAsset')}</span>
                </Col>
                {ClaMaquina !== null ? (
                  <>
                    <Col className="text-right">
                      <FieldButton
                        icon="pi pi-times"
                        onClick={() => setEsEditarMaquina(!EsEditarMaquina)}
                        className="p-button-rounded p-button-danger"
                      />
                    </Col>
                  </>
                ) : (
                  <></>
                )}
              </Row>
              <Row className="pt-2">
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className="p-fluid">
                    <FieldSelectButton
                      name="ClaMaquina"
                      value={ClaMaquina}
                      options={MaquinasList}
                      onChange={(e) => onMaquinaClick(e.value)}
                      optionValue="ClaMaquina"
                      optionLabel="NomMaquina"
                      className="buttonListSurveyTablet"
                      multiple={false}
                      errors={errors}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <></>
          )}
        </> 
      ) : (
        <>
          {ClaMaquina !== null && ClaCelda != null ? (
            <div className='Tablet-Field-Read-Only' onClick={() => setEsEditarMaquina(!EsEditarMaquina)}>
              <Row>
                <Col lg={6} md={6} sm={4} xs={4}>
                  {t('downTime:Asset')}
                </Col>
                <Col className="text-right" lg={6} md={6} sm={8} xs={8}><span className="tablet-label-value">{LabelMaquina(ClaMaquina)}</span></Col>
              </Row>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
      {Causing === null || EsEditarCausing ? (
        <>
          {ClaMaquina !== null && (TipoPreguntaPorMaquina === 1 || TipoPreguntaPorMaquina === 2) ? (
            <div className='Tablet-Field'> 
              <Row className='pb-1'>
                <Col><span className="h3">{TipoPregunta(TipoPreguntaPorMaquina)}</span></Col>
                {Causing !== null ? (
                  <>
                    <Col className="text-right">
                      <FieldButton
                        icon="pi pi-times"
                        onClick={() => setEsEditarCausing(!EsEditarCausing)}
                        className="p-button-rounded p-button-danger"
                      />
                    </Col>
                  </>
                ) : (
                  <></>
                )}
              </Row>
              <Row>
                <Col>
                  {TipoPreguntaPorMaquina === 1 || TipoPreguntaPorMaquina === 2 ? (
                    <>
                      <div className="p-fluid">
                        <FieldSelectButton
                          name="Causing"
                          value={Causing}
                          options={CausingList}
                          onChange={(e) => SiConfirmallegadaClick(e.value)}
                          optionValue="IdCausing"
                          optionLabel="NomCausing"
                          className="buttonListSurveyTablet"
                          multiple={false}
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row> 
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          {Causing !== null && ClaMaquina !== null ? (
            <div className='Tablet-Field-Read-Only' onClick={() => setEsEditarCausing(!EsEditarCausing)}>
              <Row>
                <Col>
                  {/* <span className="h3">{t('downTime:Causing')}</span> */}
                  {TipoPregunta(TipoPreguntaPorMaquina)}
                </Col>
                <Col className="text-right"><span className="tablet-label-value">{LabelCausing(Causing)}</span></Col>
              </Row>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
      {
                ShowResponsableCheckIn && 
                (
                  <>
                    {
                      EsEditarResponsableCheckIn ?
                      (
                        <div className='Tablet-Field'>
                          <Row className='pb-1'>
                            <Col>
                              <span className="h3">Check In Reported By</span>
                            </Col>
                            {ResponsableCheckIn !== null ? (
                              <>
                                <Col className="text-right">
                                  <FieldButton
                                    icon="pi pi-times"
                                    onClick={() => setEsEditarResponsableCheckIn(!EsEditarResponsableCheckIn)}
                                    className="p-button-rounded p-button-danger"
                                  />
                                </Col>
                              </>
                            ) : (
                              <></>
                            )}
                          </Row>
                          <Row className="text-center">
                            <Col>
                              <div className="p-fluid">
                                <FieldText  
                                  name="ResponsableCheckIn"
                                  value={ResponsableCheckIn}
                                  errors={errors}
                                  onChange={onChangeResponsableCheckIn}
                                  maxLength={250}
                                  IsShowKeyboard={true} 
                                  title="Check In Reported By"
                                />   
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>
                              &nbsp;
                            </Col>
                          </Row>
                        </div> 
                      )
                      :
                      (
                        <div onClick={() => setEsEditarResponsableCheckIn(!EsEditarResponsableCheckIn)} className="Tablet-Field-Read-Only">
                          <Row>
                            <Col>
                              Check In Reported By
                            </Col>
                            <Col className="text-right"><span className="tablet-label-value">{ResponsableCheckIn}</span></Col>
                          </Row>
                        </div>
                      )
                    }
                    <Row>
                      <Col>
                        <div className="p-fluid">
                          <FieldButton
                            type="button"
                            onClick={() => ConfirmarLlegadaResponsableCheckIn()}
                            label={t('downTime:ConfirmarLlegada')}
                            className="p-button-success no-border p-mr-2 rounded-button btntablet"
                            style={{ marginRight: '.25em' }}
                          />
                        </div>
                      </Col>
                      
                    </Row>
                  </>
                )
              }
      
      {
        ShowResponsableCheckIn ?
        (<></>)
        :
        (
          <>
            {ClaTipoAndon === null || EsEditarTipoAndon ? (
              <>
                <Row>
                  <Col>&nbsp;</Col>
                </Row>
                {(Causing !== null || ClaTipoAndon !== null) && TipoAndonList.length > 0 ? (
                  <div className='Tablet-Field'>
                    <Row className='pb-1'>
                      <Col>
                        <span className="h3">{t('downTime:ChoiceAndon')}</span>
                      </Col>
                      {ClaTipoAndon !== null ? (
                        <>
                          <Col className="text-right">
                            <FieldButton
                              icon="pi pi-times"
                              onClick={() => setEsEditarTipoAndon(!EsEditarTipoAndon)}
                              className="p-button-rounded p-button-danger"
                            />
                          </Col>
                        </>
                ) : (
                  <></>
                )}
                    </Row>
                    <Row className="pt-2">
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <Row className="align-items-start pb-3 margin-right-5"> 
                          {TipoAndonList.length >= 1 ? (
                      showActiveRows(TipoAndonList).map((element, index) => {
                        return (
                          <Col xs={4} className="pb-3 text-center">
                            {TipoAndonButton(element.ClaTipoAndon, element.NomTipoAndon)}
                          </Col>
                        );
                      })
                    ) : (
                      <></>
                    )} 
                        </Row>
                      </Col>
                    </Row>
                  </div>
          ) : (
            <></>
          )}
              </>
      ) : (
        <>
          {(Causing !== null || ClaTipoAndon !== null) && TipoAndonList.length > 0 ? (
            <div className='Tablet-Field-Read-Only' onClick={() => setEsEditarTipoAndon(!EsEditarTipoAndon)}>
              <Row>
                <Col>
                  {t('downTime:TypeAndon')}
                </Col>
                <Col className="text-right"><span className="tablet-label-value">{LabelTipoAndon(ClaTipoAndon)}</span></Col>
              </Row>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
          </>
        )
      }


      
      {EsEditarTipoParoAndon ? (
        <> 
          {ClaTipoAndon !== null ? (
            <div className='Tablet-Field'> 
              <Row className='pb-1'>
                <Col>
                  <span className="h3">{t('downTime:DownTimeCode')}</span>
                </Col>
                {ClaTipoParoAndon !== null ? (
                  <>
                    <Col className="text-right">
                      <FieldButton
                        icon="pi pi-times"
                        onClick={() => setEsEditarTipoParoAndon(!EsEditarTipoParoAndon)}
                        className="p-button-rounded p-button-danger"
                      />
                    </Col>
                  </>
                ) : (
                  <></>
                )}
              </Row>
              <Row className="pt-2">
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className="p-fluid">
                    <FieldSelectButton
                      name="ClaTipoParoAndon"
                      value={ClaTipoParoAndon}
                      options={TipoParoAndonList}
                      onChange={(e) => onTipoParoAndonClick(e.value)}
                      optionValue="ClaTipoParoAndon"
                      optionLabel="NomTipoParoAndon"
                      className="buttonListSurveyTablet"
                      multiple={false}
                      errors={errors}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          {ClaTipoAndon !== null ? (
            <div className='Tablet-Field-Read-Only' onClick={() => setEsEditarTipoParoAndon(!EsEditarTipoParoAndon)}>
              <Row>
                <Col>
                  {t('downTime:TypeParoAndon')}
                </Col>
                <Col className="text-right"><span className="tablet-label-value">{LabelTipoParoAndon(ClaTipoParoAndon)}</span></Col>
              </Row>
            </div>
          ) : (
            <></>
          )}
        </>
      )}

      {EsEditarRazonAndon ? (
        <div className='Tablet-Field'>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
            </Col>
          </Row>
          {ClaTipoParoAndon !== null ? (
            <>
              <Row className='pb-1'>
                <Col>
                  <span className="h3">{t('downTime:Reason')}</span>
                </Col>
                {ClaRazonAndon !== null ? (
                  <>
                    <Col className="text-right">
                      <FieldButton
                        icon="pi pi-times"
                        onClick={() => setEsEditarRazonAndon(!EsEditarRazonAndon)}
                        className="p-button-rounded p-button-danger"
                      />
                    </Col>
                  </>
                ) : (
                  <></>
                )}
              </Row>
              <Row className="pt-2">
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className="p-fluid">
                    <FieldSelectButton
                      name="ClaRazonAndon"
                      value={ClaRazonAndon}
                      options={RazonAndonList}
                      onChange={(e) => onRazonAndonClick(e.value)}
                      optionValue="ClaRazonAndon"
                      optionLabel="NomRazonAndon"
                      className="buttonListSurveyTablet"
                      multiple={false}
                      errors={errors}
                    />
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <>
          {ClaTipoParoAndon !== null ? (
            <>
              <div className='Tablet-Field-Read-Only' onClick={() => setEsEditarRazonAndon(!EsEditarRazonAndon)}>
                <Row>
                  <Col>
                    {t('downTime:RazonAndon')}
                  </Col>
                  <Col className="text-right"><span className="tablet-label-value">{LabelRazonAndon(ClaRazonAndon)}</span></Col>
                </Row>
              </div> 
            </>
          ) : (
            <></>
          )}
        </>
      )}

      {
        ClaRazonAndon !== null ?
        (
          <>
            {
              ClaTipoAndon === 1 || ClaTipoAndon === 5 ?
              (
                <>
                  {
                  EsEditarComentarioRazonAndon ?
                  (
                    <div className='Tablet-Field'>
                      <Row className='pb-1'>
                        <Col>
                          <span className="h3">Explain the problem with your own words</span>
                        </Col>
                        {ComentarioRazonAndon !== null ? (
                          <>
                            <Col className="text-right">
                              <FieldButton
                                icon="pi pi-times"
                                onClick={() => setEsEditarComentarioRazonAndon(!EsEditarComentarioRazonAndon)}
                                className="p-button-rounded p-button-danger"
                              />
                            </Col>
                          </>
                        ) : (
                          <></>
                        )}
                      </Row>
                      <Row className="text-center">
                        <Col>
                          <div className="p-fluid">
                            <FieldText  
                              name="ComentarioRazonAndon"
                              value={ComentarioRazonAndon}
                              errors={errors}
                              onChange={onChangeComentarioRazonAndon}
                              maxLength={250}
                              IsShowKeyboard={true} 
                              title="Explain the problem with your own words"
                            />   
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                          &nbsp;
                        </Col>
                      </Row>
                    </div> 
                  )
                  :
                  (
                    <div onClick={() => setEsEditarComentarioRazonAndon(!EsEditarComentarioRazonAndon)} className="Tablet-Field-Read-Only">
                      <Row>
                        <Col>
                          Explain the problem with your own words
                        </Col>
                        <Col className="text-right"><span className="tablet-label-value">{ComentarioRazonAndon}</span></Col>
                      </Row>
                    </div>
                  )
                }
                </>
              )  
              :
              (<></>)
            }
          </>
        ):
        (<></>)
      }

      {
        ClaRazonAndon !== null ?
        (
          <>
            {
              ClaTipoAndon === 1 || ClaTipoAndon === 5 ?
              (
                <>
                  {
                  EsEditarNombreEmpleado ?
                  (
                    <div className='Tablet-Field'>
                      <Row className='pb-1'>
                        <Col>
                          <span className="h3">Reported by</span>
                        </Col>
                        {NombreEmpleado !== null ? (
                          <>
                            <Col className="text-right">
                              <FieldButton
                                icon="pi pi-times"
                                onClick={() => setEsEditarNombreEmpleado(!EsEditarNombreEmpleado)}
                                className="p-button-rounded p-button-danger"
                              />
                            </Col>
                          </>
                        ) : (
                          <></>
                        )}
                      </Row>
                      <Row className="text-center">
                        <Col>
                          <div className="p-fluid">
                            <FieldText  
                              name="NombreEmpleado"
                              value={NombreEmpleado}
                              errors={errors}
                              onChange={onChangeNombreEmpleado}
                              maxLength={250}
                              IsShowKeyboard={true} 
                              title="Explain the problem with your own words"
                            />   
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                          &nbsp;
                        </Col>
                      </Row>
                    </div> 
                  )
                  :
                  (
                    <div onClick={() => setEsEditarNombreEmpleado(!EsEditarNombreEmpleado)} className="Tablet-Field-Read-Only">
                      <Row>
                        <Col>
                          Reported by
                        </Col>
                        <Col className="text-right"><span className="tablet-label-value">{NombreEmpleado}</span></Col>
                      </Row>
                    </div>
                  )
                }
                </>
              )  
              :
              (<></>)
            }
          </>
        ):
        (<></>)
      }


      {
        ClaRazonAndon !== null && IdReporteAndon !== null ?
        (
          <>
            {
              ClaTipoAndon === 3 || ClaTipoAndon === 4 || ClaTipoAndon === 5 ?
              (
                <>
                  {
                  EsEditarResponsableResuelveProblema ?
                  (
                    <div className='Tablet-Field'>
                      <Row className='pb-1'>
                        <Col>
                          <span className="h3">Solved by</span>
                        </Col>
                        {ResponsableResuelveProblema !== null ? (
                          <>
                            <Col className="text-right">
                              <FieldButton
                                icon="pi pi-times"
                                onClick={() => setEsEditarResponsableResuelveProblema(!ResponsableResuelveProblema)}
                                className="p-button-rounded p-button-danger"
                              />
                            </Col>
                          </>
                        ) : (
                          <></>
                        )}
                      </Row>
                      <Row className="text-center">
                        <Col>
                          <div className="p-fluid">
                            <FieldText  
                              name="ResponsableResuelveProblema"
                              value={ResponsableResuelveProblema}
                              errors={errors}
                              onChange={onChangeResponsableResuelveProblema}
                              maxLength={250}
                              IsShowKeyboard={true} 
                              title="Solved By"
                            />   
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                          &nbsp;
                        </Col>
                      </Row>
                    </div> 
                  )
                  :
                  (
                    <div onClick={() => setEsEditarResponsableResuelveProblema(!ResponsableResuelveProblema)} className="Tablet-Field-Read-Only">
                      <Row>
                        <Col>
                          Solved by
                        </Col>
                        <Col className="text-right"><span className="tablet-label-value">{ResponsableResuelveProblema}</span></Col>
                      </Row>
                    </div>
                  )
                }
                </>
              )  
              :
              (<></>)
            }
          </>
        ):
        (<></>)
      }

      {
        IdReporteAndon > 0 ?
        (
          <>
            {
              ClaTipoAndon === 1 || ClaTipoAndon === 5 ?
              (
                <>
                  {
                  EsEditarSolucionAndon ?
                  (
                    <div className='Tablet-Field'>
                      <Row className='pb-1'>
                        <Col>
                          <span className="h3">How did you solve this problem?</span>
                        </Col>
                        {SolucionAndon !== null ? (
                          <>
                            <Col className="text-right">
                              <FieldButton
                                icon="pi pi-times"
                                onClick={() => setEsEditarSolucionAndon(!EsEditarSolucionAndon)}
                                className="p-button-rounded p-button-danger"
                              />
                            </Col>
                          </>
                        ) : (
                          <></>
                        )}
                      </Row>
                      <Row className="text-center">
                        <Col>
                          <div className="p-fluid">
                            <FieldText  
                              name="SolucionAndon"
                              value={SolucionAndon}
                              errors={errors}
                              onChange={onChangeSolucionAndon}
                              maxLength={250}
                              IsShowKeyboard={true} 
                              title="How did you solve this problem?"
                            />   
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                          &nbsp;
                        </Col>
                      </Row>
                    </div> 
                  )
                  :
                  (
                    <div onClick={() => setEsEditarSolucionAndon(!EsEditarSolucionAndon)} className="Tablet-Field-Read-Only">
                      <Row>
                        <Col>
                          How did you solve this problem?
                        </Col>
                        <Col className="text-right"><span className="tablet-label-value">{SolucionAndon}</span></Col>
                      </Row>
                    </div>
                  )
                }
                </>
              )  
              :
              (<></>)
            }
          </>
        ):
        (<></>)
      }
      
      {IdReporteAndon > 0 && EsTipoConfirmacion !== 2 ? (
        <>
          {IdFixProblem === null || !EsEditarFixProblem ? (
            <>
              <Row>
                <Col>
                  <span className="h3">{t('downTime:FixProblem')}</span>
                </Col>
                {IdFixProblem !== null ? (
                  <>
                    <Col className="text-right">
                      <FieldButton
                        icon="pi pi-times"
                        onClick={() => setEsEditarFixProblem(!EsEditarFixProblem)}
                        className="p-button-rounded p-button-danger"
                      />
                    </Col>
                  </>
                ) : (
                  <></>
                )}
              </Row>
              <Row>
                <Col>
                  <div className="p-fluid">
                    <FieldSelectButton
                      name="IdFixProblem"
                      value={IdFixProblem}
                      options={FixList}
                      onChange={(e) => onIdFixProblemClick(e.value)}
                      optionValue="IdFixProblem"
                      optionLabel="NomFix"
                      className="buttonListSurveyTablet"
                      multiple={false}
                    />
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <>
              {IdFixProblem !== null ? (
                <div className='Tablet-Field-Read-Only' onClick={() => setEsEditarFixProblem(!EsEditarFixProblem)}>
                  <Row>
                    <Col>
                      {t('downTime:FixProblem')}
                    </Col>
                    <Col className="text-right"><span className="tablet-label-value">{LabelFix(IdFixProblem)}</span></Col>
                  </Row>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
          {IdFixProblem === 0 ? (
            <>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  &nbsp;
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="h3">{t('downTime:Help')}</span>
                </Col>                
              </Row>
              <Row className="pt-2">
                <Col lg={12} md={12} sm={12} xs={12}>
                  <Row className="align-items-start pb-3">
                    {TipoAndonList.length >= 1 ? (
                      showActiveRows(TipoAndonList).map((element, index) => {
                        return (
                          <Col xs={4} sm={4} ms={4} lg={4} className="pb-3 text-center">
                            {TipoAndon2(element.ClaTipoAndon, element.NomTipoAndon)}
                          </Col>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </Row>
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <Row>
            <Col>
              <div className="text-center">
                {ClaTipoAndon != null ? (
                  <div className="p-fluid">
                    <FieldButton
                      type="button"
                      onClick={() => SendAlert()}
                      label={t('downTime:SendAlert')}
                      className="p-button-success no-border p-mr-2 rounded-button btntablet"
                      style={{ marginRight: '.25em' }}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>  
              
            </Col>
          </Row>
        </>
      )}

      {
        ShowScrollButtons &&
        (
          <>
            <div className='icon-position-bottom-left'>
              <FieldButton
                icon="fas fa-chevron-up"
                onClick={() => onScrollUp()}
                className="p-button-rounded icon-style"
              />
            </div>
            <div className='icon-position-bottom-right'>
              <FieldButton
                icon="fas fa-chevron-down"
                onClick={() => onScrollDown()}
                className="p-button-rounded icon-style"
              />
            </div>
          </>
        )
      }
      
    </>
  );
};

export default DonwTimeDetail;

import React from "react";
import SolicitudMaterialDashboard from "./SolicitudMaterialDashboard";
import PageContent from "layouts/PageContent";
import {Row, Col} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import SolicitudMaterialCelda from "./SolicitudMaterialCelda";

const SolicitudMaterialDashboardScreen = (props) => {
  const {t, i18n} = useTranslation(['operations', 'common']);

  return (
    <PageContent title={t("operations:solicitudMaterialDashboardTitle")}>
      <Row>
        <Col>
          <SolicitudMaterialDashboard />
        </Col>
      </Row>
    </PageContent>
  );
}

export default SolicitudMaterialDashboardScreen;
import React, { useEffect, useState } from 'react';
import { config } from '../../../utils/config';
import { callApi } from '../../../utils/utils';
import { 
  Row,
  Col, 
} from 'reactstrap';  
import ArrowUpwardRounded from '@material-ui/icons/ArrowUpwardRounded';
import ArrowDownwardRounded from '@material-ui/icons/ArrowDownwardRounded'; 
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import TimelineIcon from '@material-ui/icons/Timeline';
import IconButton from '@material-ui/core/IconButton';
import StandarVsRealChar from "./StandarVsRealChar.jsx";
import { useTranslation } from 'react-i18next';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
/* ############### START COMPONENT ###############  */

const Inconsistencies = (props) => {
  /* ############### DECLARE URLS ###############  */
  const urlWebServiceChart = `${config.UrlApiProject}Rentabilidad/GetBucketGrafico12Months`;
  const urlWebServiceLineChart = `${config.UrlApiProject}Rentabilidad/GetInconsistenciasLinesChart`; 

  /* ############### DECLARE USESTATE ###############  */

  const { t } = useTranslation(['formulario','common']);
  const [rowsGrid, setRowsGrid] = useState([]); 
  const [chartTitle, setChartTitle] = useState('');
  const [SelectedBucket, setSelectedBucket] = useState(null);
  const [ClaBucket, setClaBucket] = useState(null);

  

  /* ############### DECLARE USEEFFECT ###############  */

  useEffect(() => {
    
    const GetInconsistenciasGridService = `${config.UrlApiProject}Rentabilidad/GetInconsistenciasGrid`;

    const paramsToService = {
      anioMes: props.filters.AnioMes,
      claGrupoEstadistico3: props.filters.ClaGrupoEstadistico3,
      claGerenciaRegional: props.filters.ClaGerenciaRegional,
      claclientecuenta: props.filters.ClaClienteCuenta,
      claBucket: null,
      esMostrarSoloConfiables: props.filters.EsMostrarSoloConfiables,
      ClaMarketSegment: props.filters.ClaMarketSegment
    }; 

    callApi(GetInconsistenciasGridService, 'POST', paramsToService, (response) => { 
      setRowsGrid(response.data[0]); 
    });

    callApi(urlWebServiceChart, 'POST', paramsToService, (response) => {
      const newChartSeries = [];

      for (const dataChart of response.data) {
        newChartSeries.push({
          name: dataChart.name,
          data: dataChart.values,
        });
      }
    });

    const GetFollowUpTitle = `${config.UrlApiProject}Rentabilidad/GetBucketFollowUpTitle`; 

    callApi(GetFollowUpTitle, 'POST', paramsToService, (response) => {  
       
      if (response.data.NomClienteCuenta !== ''){ 
        setChartTitle(`${response.data.NomClienteCuenta  } / ${  response.data.NomFecha}`);  
      }else{ 
        setChartTitle(response.data.NomFecha); 
      }  
      
      setClaBucket(null);
    });

  }, [props.filters]);

  /* ############### END USEEFFECT ###############  */
  
  const header  = ( 
    <>
      {chartTitle}
    </>
  )
   
  const pirColumn = (row) => {  
    return (<>{ row.PIR === null ? '' : row.PIR.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</>);
  }

  const standardCostColumn = (row) => {  
      return (<>{row.StandarCost === null ? '' : row.StandarCost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>);
  }

  const realCostColumn = (row) => {  
    return (<>{ row.RealCost === null ? '' :row.RealCost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>);
  }

  const onRowSelect = (event) => {  
    setClaBucket(event.data.ClaBucket)
  }

  const onRowUnselect = (event) =>{  
    setClaBucket(null);
  }

  
  const chartColumn = (row) =>  {    
      return (<><IconButton size="small" color="primary"><TimelineIcon /></IconButton></>);
  }

  const trendColumn = (row) =>  {    
      return (<>{row.Trend ? <ArrowUpwardRounded className={row.ClaBucket === 7 ? "green" : "red"} /> : <ArrowDownwardRounded className={row.ClaBucket === 7 ? "red" : "green"} />}</>);
  }

  
  /* ############### RENDER AREA ###############  */

  if (props.showHide) {
    return (
      <>
        <Row className="align-items-start">
          <Col lg={6} md={6} sm={12} xs={12}>
            <FieldDataTable 
              value={rowsGrid} 
              header={header} 
              scrollable 
              scrollHeight="330px" 
              className="p-datatable-striped"
              selection={SelectedBucket} 
              onSelectionChange={e => setSelectedBucket(e.value)} 
              onRowSelect={onRowSelect} 
              onRowUnselect={onRowUnselect}
              selectionMode="single"
              metaKeySelection={false}
            >
              <Column header="" headerStyle={{ width: '50px' }} body={chartColumn}></Column>
              <Column field="BucketName" header={t('formulario:BucketName')} headerStyle={{ width: '150px' }} columnKey="BucketName"></Column> 
              <Column body={standardCostColumn} header={t('formulario:Standard')} headerStyle={{ width: '90px' }} columnKey="StandarCost"></Column> 
              <Column body={realCostColumn} header={t('formulario:Real')} headerStyle={{ width: '80px' }} columnKey="RealCost"></Column>   
              <Column body={pirColumn} header={t('formulario:PiR')} headerStyle={{ width: '60px' }} columnKey="PIR"></Column>  
              <Column header={t('formulario:Trend')} headerStyle={{ width: '70px' }} body={trendColumn}></Column>  
               
            </FieldDataTable> 
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            {
              ClaBucket !== null ?  <StandarVsRealChar ClaBucket={ClaBucket} filters={props.filters} />
              : <></>
            }
          </Col>
        </Row>  
      </>
    );
  }
  if (!props.showHide) {
    return <></>;
  }
};

export default Inconsistencies;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React , {useEffect, useState} from 'react';  
import { config } from '../../utils/config';
import { callApi, callKrakenApi } from '../../utils/utils';  
import '../../assets/css/PivotGridBase.css'; 
import { useTranslation } from 'react-i18next'; 
import FieldDropdown from '../../components/Controls/FieldDropdown.jsx' 
import * as yup from 'yup';
import YupValidator from '../../utils/YupValidator';
import { Ripple } from 'primereact/ripple';
import { Panel } from 'primereact/panel';
import FieldCalendar from '../../components/Controls/FieldCalendar.jsx'
import moment from 'moment'
import FieldButton from '../../components/Controls/FieldButton/FieldButton.jsx';
import { Row, Col } from 'reactstrap';

const ProductionsDashboardsFilters =  (props) => {
     
    const { t } = useTranslation(['formulario', 'common']);
    const [ClaZona, setClaZona] = useState(props.filters.ClaZona !== undefined ? props.filters.ClaZona : null);
    const [OpcionesZonas, setOpcionesZona] = useState([]);
    const [OpcionesTurnos, setOpcionesTurnos] = useState([]);
    const [ClaTurno, setClaTurno] = useState(props.filters.ClaTurno !== undefined ? props.filters.ClaTurno : null);
    const [errors, setErrors] = useState({});   
    const [FechaInicial, setFechaInicial] = useState(null); 
    const [FechaFinal, setFechaFinal] = useState(null); 
    const valitationScheme = yup.object().shape({
        ClaZona: yup.string().required(t('common:RequiredField')).nullable(),
        FechaInicial: yup.date().required(t('common:RequiredField')).nullable(),
        FechaFinal: yup.date().required(t('common:RequiredField')).nullable()
    });
    
      useEffect(() => {   
        let urlWebService = `${config.UrlApiProject}celda/GetZonas?EsZonaDashboard=1&EsAgregarTodos=0`; 
        let paramsService = null; 
        callApi(urlWebService, 'GET', paramsService, (response) => {
          setOpcionesZona(response.data.Zonas);
          urlWebService = `${config.UrlApiProject}Produccion/GetOperacionDashboardHeaderInfo`;
          paramsService = {
            ClaZona,
            FechaInicial:null,
            FechaFinal:null,
          }; 
          callApi(urlWebService, 'POST', paramsService, (response) => 
             { 
                setFechaInicial(new Date(response.data.FechaInicial)); 
                setFechaFinal(new Date(response.data.FechaFinal)); 
                setClaTurno((response.data.ClaTurno)); 
            });
          
            const urlgetTurnos = `${config.UrlApiProject}turno/getTurnos`; 
              
            callApi(urlgetTurnos, 'GET', paramsService, (response) => {
              setOpcionesTurnos(response.data.Turnos);
              // obtenemos los turnos
            });  
            

        })
      }, []);
    
    const onZoneChange = (e) => {  
        const newErrors = { ...errors } 
        delete newErrors.ClaZona;   
        setErrors(newErrors)  
        setClaZona(e.value);  
    };
  
    const onTurnoChange = (e) => {  
      const newErrors = { ...errors } 
      delete newErrors.ClaTurno;   
      setErrors(newErrors)  
      setClaTurno(e.value);  
  };

  const onChangeStartDate = (e) => {  
    const newErrors = { ...errors }
    delete newErrors.FechaInicial;   
    setErrors(newErrors);
    setFechaInicial(e.target.value);
  } 

  const onChangeEndDate = (e) => {  
    const newErrors = { ...errors }
    delete newErrors.FechaFinal;   
    setErrors(newErrors);
    setFechaFinal(e.target.value);
  } 

    const onApply = async () => {  
        const value = {
            ClaZona,
            FechaInicial,   
            FechaFinal
          }
          console.log('onApply');
          console.log(value);
          const validator = new YupValidator(valitationScheme);
          const esValido = await validator.validate(value);
          
          if (!esValido){ 
            setErrors(validator.errors); 
            return;
          }
          if (props.onSearch !=  null){ 
            const filtersData = {}
            filtersData.ClaZona  = ClaZona === undefined ? null : ClaZona;
            filtersData.FechaInicial  = FechaInicial === undefined ? null : FechaInicial;
            filtersData.FechaFinal = FechaFinal === undefined ? null : FechaFinal;
            filtersData.ClaTurno = ClaTurno === undefined ? null : ClaTurno;
            props.onSearch(filtersData);
          }
    };

    const filterHeaderTemplate = (options) => {
        const toggleIcon = options.collapsed ? 'pi pi-chevron-down text-white' : 'pi pi-chevron-up text-white';
        const className = `${options.className} p-jc-start`;
        const titleClassName = `${options.titleClassName} p-pl-1`;

        return (
          <div className="card-header">
            <div className="p-toolbar p-component">
              <div className="p-toolbar-group-left">
                <div className="card-title">
                  <FieldButton className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                  </FieldButton>
                  <span className={titleClassName}>
                    {t('common:Filters')}
                  </span>
                </div>  
              </div>
              <div className="p-toolbar-group-right">
                <FieldButton className="p-button p-button-icon-only p-highlight" onClick={onApply}>
                  <i className="pi pi-search"></i>
                </FieldButton>
              </div>
            </div> 
          </div>
        )
    }
     
    return (
      <>   
        <Panel header="Filters" toggleable headerTemplate={filterHeaderTemplate}> 
          <Row className="pt-2">
            <Col>
              <div className="p-fluid">
                <FieldDropdown  
                  name="ClaZona"
                  value={ClaZona} 
                  options={OpcionesZonas}  
                  optionValue="ClaZonaCelda"
                  optionLabel="NomZonaCelda" 
                  onChange={(e) => onZoneChange(e)}
                  label={t('formulario:Zone')}
                  errors={errors}
                />   
              </div>
            </Col>
            <Col>
              <div className="p-fluid">
                <FieldDropdown  
                  name="ClaTurno"
                  value={ClaTurno} 
                  options={OpcionesTurnos}  
                  optionValue="ClaTurno"
                  optionLabel="NomTurno" 
                  onChange={(e) => onTurnoChange(e)}
                  label={t('formulario:Shift')}
                  errors={errors}
                />   
              </div>
            </Col>
            <Col>
              <div className="p-fluid">
                <FieldCalendar
                  name="FechaInicial"
                  label={t('formulario:StartDate')}
                  value={FechaInicial}
                  onChange={(e) => onChangeStartDate(e)}
                  errors={errors}
                />   
              </div>
            </Col>
            <Col>
              <div className="p-fluid">
                <FieldCalendar
                  name="FechaFinal"
                  label={t('formulario:EndDate')}
                  value={FechaFinal}
                  onChange={(e) => onChangeEndDate(e)}
                  errors={errors}
                />   
              </div>
            </Col>
          </Row>  
        </Panel>
      </>
)
}  
 
export default ProductionsDashboardsFilters;   
 

import React, { useState, useEffect } from 'react';
import { callApi, showSweetAlert } from '../../../utils/utils';
import { config } from '../../../utils/config';
import { useTranslation } from 'react-i18next';
import { Panel } from 'primereact/panel';
import { Dialog } from 'primereact/dialog';
import { Row, Col, Label } from 'reactstrap';
import FieldText from 'components/Controls/FieldText';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldUpload from 'components/Controls/FieldUpload/FieldUpload';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';

const ManageADPEmployeesFilters = (props) => {
  const {t, i18n} = useTranslation(['hr', 'common']);

  const [name, setName] = useState('');
  const [positionId, setPositionId] = useState('');
  const [associateId, setAssociateId] = useState('');
  const [company, setCompany] = useState(null);
  const [errors, setErrors] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [showUploadEmployees, setShowUploadEmployees] = useState(false);
  const [file, setFile] = useState([]);

  useEffect(() => {
    getCompanyNames();
  }, [])

  useEffect(() => {
    const onKeyDown = (e) => {
      if(e.keyCode === 13) {
        updateFilters();
      }
    }
    
    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [name, positionId, associateId, company])

  const getCompanyNames = () => {
    const urlWebService = `${config.UrlApiProject}HR/GetADPEmployeeCompanyPayrollNames`;
    const paramsService = {};

    callApi(urlWebService, 'GET', paramsService, (response) => {
      console.log(response.data)
      setCompanies(response.data);
    });
  }

  const onChangeName = (e) => {
    const newErrors = {...errors};
    delete newErrors.name;
    setErrors(newErrors);
    setName(e.target.value);
  }

  const onChangePositionId = (e) => {
    const newErrors = {...errors};
    delete newErrors.positionId;
    setErrors(newErrors);
    setPositionId(e.target.value);
  }

  const onChangeAssociateId = (e) => {
    const newErrors = {...errors};
    delete newErrors.associateId;
    setErrors(newErrors);
    setAssociateId(e.target.value);
  }

  const onChangeCompany = (e) => {
    console.log(e);
    const newErrors = {...errors};
    delete newErrors.company;
    setErrors(newErrors);
    setCompany(e.value);
  }

  const updateFilters = () => {
    props.setFilters({
      FullName: name,
      PositionId: positionId,
      AssociateId: associateId,
      CompanyName: company
    })
  }

  const clearFilters = () => {
    setName('');
    setAssociateId('');
    setPositionId('');
    setCompany(null);
    props.setFilters({})
  }

  const onSubmitClick = async () => {
    console.log(file);

    const validationSchema = yup.object().shape({
      file: yup.array().min(1, t('common:RequiredField')).required(t('common:RequiredField')).nullable()
    })

    const value = {
      file
    }

    const validator = new YupValidator(validationSchema);
    const isValid = await validator.validate(value);

    if (!isValid) {
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      return;
    }

    const urlWebService = `${config.UrlApiProject}HR/ReadEmployeesFromSpreadsheet`;
    const paramsService = {
      FileData: file[0].FileData,
      FileName: file[0].FileName,
      FileExtension: file[0].FileExtension
    }

    callApi(urlWebService, 'POST', paramsService, (response) => {
      setFile([]);
      clearFilters();
      setShowUploadEmployees(false);
      getCompanyNames();
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
    });
  }

  const filterHeaderTemplate = (options) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down text-white' : 'pi pi-chevron-up text-white';
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className="card-header">
        <div className="p-toolbar p-component">
          <div className="p-toolbar-group-left">
            <div className="card-title">
              <FieldButton className={options.togglerClassName} onClick={options.onTogglerClick}>
                <span className={toggleIcon}></span> 
              </FieldButton>
              <span className={titleClassName}>
                {t('common:Filters')}
              </span>
            </div>  
          </div>
          <div className="p-toolbar-group-right">
            <FieldButton
              label={t('hr:UploadEmployees')}
              onClick={() => setShowUploadEmployees(true)}
              className='p-button-secondary mr-2'
              icon='pi pi-upload'
            />
            <FieldButton
              onClick={() => updateFilters()}
              className='p-button mr-2'
              icon='pi pi-search'
            />
            <FieldButton
              onClick={() => clearFilters()}
              className='p-button-danger'
              icon='pi pi-times'
            />
          </div>
        </div> 
      </div>
    )
  }

  const uploadFooter = () => {
    return (
      <>
        <FieldButton label='Submit' onClick={() => onSubmitClick()} icon='pi pi-upload' className='p-button-success' />
      </>
    )
  }

  return (
    <>
      <Panel
        header={t('common:Filters')}
        toggleable
        headerTemplate={filterHeaderTemplate}
      >
        <Row className='p-fluid p-grid p-formgroup-inline align-items-end'>
          <Col xs={12} sm={12} md={4} lg={4} xl={3}>
            <div className='p-field'>
              <Label>{t('hr:Name')}</Label>
              <FieldText
                name='name'
                value={name}
                onChange={onChangeName}
              />
            </div>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} xl={3}>
            <div className='p-field'>
              <Label>{t('hr:PositionId')}</Label>
              <FieldText
                name='positionId'
                value={positionId}
                onChange={onChangePositionId}
              />
            </div>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} xl={3}>
            <div className='p-field'>
              <Label>{t('hr:AssociateId')}</Label>
              <FieldText
                name='associateId'
                value={associateId}
                onChange={onChangeAssociateId}
              />
            </div>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} xl={3}>
            <div className='p-field'>
              <Label>{t('hr:PayrollCompany')}</Label>
              <FieldDropdown
                name='company'
                value={company}
                options={companies}
                optionValue="PayrollCompanyName"
                optionLabel="PayrollCompanyName"
                onChange={onChangeCompany}
              />
            </div>
          </Col>
        </Row>
      </Panel>
      <Dialog
        visible={showUploadEmployees}
        onHide={() => {
          setShowUploadEmployees(false);
          setFile([]);
          setErrors(null);
        }}
        footer={uploadFooter}
        blockScroll={true}
        style={{ width: '15vw' }}
        breakpoints={{'1400px': '25vw', '1000px': '35vw', '510px': '50vw', '560px': '100vw' }}
      >
        <FieldUpload
          name='file'
          files={file}
          onChange={(files) => setFile(files)}
          accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          errors={errors}
          uploadPrompt={t('hr:ADPUploadPrompt')}
        >
        </FieldUpload>
      </Dialog>
    </>
  )
}

export default ManageADPEmployeesFilters;
import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { getSessionItem, callApi, showSweetAlert } from 'utils/utils';
import { Label, Row, Col } from 'reactstrap';
import FieldText from 'components/Controls/FieldText';
import FieldTextArea from 'components/Controls/FieldTextArea';
import FieldCheckbox from 'components/Controls/FieldCheckbox';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldUpload from 'components/Controls/FieldUpload/FieldUpload';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import FieldAutoComplete from 'components/Controls/FieldAutoComplete';
import { config } from 'utils/config';
import YupValidator from 'utils/YupValidator';
import * as yup from 'yup';
import FieldCalendar from 'components/Controls/FieldCalendar';
import moment from 'moment';

const EnterTicket = (props) => {
  const {t, i18n} = useTranslation(['it', 'common']);

  const defaultUsuario = {
    ClaUsuario: getSessionItem('ClaUsuarioMod', 0),
    NomUsuario: getSessionItem('NomUsuario', '')
  };

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [priorities, setPriorities] = useState([]);
  const [priority, setPriority] = useState(8); // Default priority 8 - Normal
  const [isRecurrent, setIsRecurrent] = useState(false);
  const [hour, setHour] = useState('72');
  const [image1, setImage1] = useState([]);
  const [image2, setImage2] = useState([]);
  const [image3, setImage3] = useState([]);
  const [image4, setImage4] = useState([]);
  const [errors, setErrors] = useState([]);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(defaultUsuario);
  const [isITUser, setIsITUser] = useState(false);
  const [fechaPromesa, setFechaPromesa] = useState(null);
  const [pageUrl, setPageUrl] = useState(props.pageUrl !== undefined ? props.pageUrl : null);
  const [tipoSoporte, setTipoSoporte] = useState(null);
  const [tiposSoporte, setTiposSoporte] = useState([]);

  const validationScheme = yup.object().shape({
    title: yup.string().required(t('common:RequiredField')).nullable(),
    description: yup.string().trim().required(t('common:RequiredField')).nullable(),
    priority: yup.number().required(t('common:RequiredField')).integer().nullable(),
    user: yup.object({ ClaUsuario: yup.string().required(t('common:RequiredField')) }).required(t('common:RequiredField')).nullable(),
    tipoSoporte: yup.number().required(t('common:RequiredField')).integer().nullable()
  });

  
  useEffect(() => {
    GetPrioridadIncidenteHlpUSA();
    getITUsers();
    getFechaPromesa(priority);
    GetTipoSoporte();
  }, [])

  // Get the list of IT users
  const getITUsers = () => {
    const urlWebService = `${config.UrlApiProject}IT/GetITTicketUsersUSA`;
    const paramsService = {};

    callApi(urlWebService, 'GET', paramsService, (response) => {
      const users = response.data.Users;

      for (const id of Object.keys(users)) {
        if (getSessionItem('ClaUsuarioMod', 0) === users[id].ClaUsuario) {
          console.log('success')
          setIsITUser(true);
          break;
        }
      }
    });
  }

  const getHour = (ClaPrioridadIncidenteHlp) => {
    if (ClaPrioridadIncidenteHlp === undefined) {
      setHour('')
    }
    else {
      priorities.forEach((p) => {
        if (p.ClaPrioridadIncidenteHlp === ClaPrioridadIncidenteHlp) {
          setHour(p.HorasSolucion);
        }
      });
    }
  }

  const getFechaPromesa = (ClaPrioridadIncidenteHlp) => {
    if (ClaPrioridadIncidenteHlp === undefined || ClaPrioridadIncidenteHlp === null) {
      setFechaPromesa(null);
    }
    else {
      const urlWebService = `${config.UrlApiProject}IT/GetPromiseDate?ClaPrioridadIncidenteHlp=${ClaPrioridadIncidenteHlp}`;
      const paramsService = {};
  
      callApi(urlWebService, 'GET', paramsService, (response) => {
        setFechaPromesa(response.data.parameters.pdFechaPromesa);
      });
    }
  }

  const filterUser = (e) => {
    let valor = ''; 
    if (user.NomUsuario !== null && user.NomUsuario !== '' &&  e.query === "")
    {
     valor = user.NomUsuario;
    }
    else{
     valor = e.query;
    }
    console.log(valor);
    const paramsServiceOwners = {};
    // event.query 
    const urlWebServiceOwners = `${config.UrlApiProject}IT/GetUsers?valor=${valor}`;
    // Get owner
    callApi(urlWebServiceOwners, 'GET', paramsServiceOwners, (response) => {  
       setUsers(response.data.Users);
    });    
  }

  const GetPrioridadIncidenteHlpUSA = () => {
    const urlWebService = `${config.UrlApiProject}IT/GetPrioridadIncidenteHlpUSA`;
    const paramsService = {
    };

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setPriorities(response.data.PrioridadIncidenteHlp);
    });
  }

  // Get the support types
  const GetTipoSoporte = () => {
    const urlWebService = `${config.UrlApiProject}IT/GetTipoSoporteUSA`;
    const paramsService = {
    };

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setTiposSoporte(response.data.TipoSoporte);
    });
  }

  const onUserChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.user;
    setErrors(newErrors);
    setUser(e.value);
    console.log(e.value);
  }

  const onDescriptionChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.description;
    setErrors(newErrors);
    setDescription(e.target.value);
  }

  const onTitleChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.title;
    setErrors(newErrors);
    setTitle(e.target.value);
  }

  const isRecurrentChanged = (e) => {
    const newErrors = {...errors};
    delete newErrors.isRecurrent;
    setErrors(newErrors);
    setIsRecurrent(e.checked);
  }

  // Handle support type change
  const onChangeTipoSoporte = (e) => {
    const newErrors = {...errors};
    delete newErrors.tipoSoporte;
    setErrors(newErrors);
    setTipoSoporte(e.value);
  }

  const onPriorityChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.priority;
    setErrors(newErrors);
    setPriority(e.value);
    getHour(e.value);
    getFechaPromesa(e.value);
  }

  const clearForm = () => {
    setTitle('');
    setDescription('');
    setPriority(8);
    getHour(8);
    setIsRecurrent(false);
    setImage1([]);
    setImage2([]);
    setImage3([]);
    setImage4([]);
    setUser(defaultUsuario);
  }

  const onSubmitClick = async (e) => {
    const value = {
      title,
      description,
      priority,
      user,
      tipoSoporte
    };

    const validator = new YupValidator(validationScheme);
    const isValid = await validator.validate(value);
    if (!isValid) {
      setErrors(validator.errors);
    }
    else {
      const urlWebService = `${config.UrlApiProject}IT/InsertITSupportTicketUSA`; 
      const paramsService = {
        ClaUsuarioSolicita: user.ClaUsuario,
        TituloSoporte: title,
        DescripcionSoporte: description,
        ClaPrioridadIncidenteHlp: priority,
        EsRecurrente: isRecurrent === true ? 1 : 0,
        ArchivoSoporte: image1.length !== 0 ? image1[0].FileData : null,
        ArchivoSoporteExt: image1.length !== 0? image1[0].FileExtension.replace('.', '').trim() : null,
        ArchivoSoporte2: image2.length !== 0 ? image2[0].FileData : null,
        ArchivoSoporteExt2: image2.length !== 0? image2[0].FileExtension.replace('.', '').trim() : null,
        ArchivoSoporte3: image3.length !== 0 ? image3[0].FileData : null,
        ArchivoSoporteExt3: image3.length !== 0? image3[0].FileExtension.replace('.', '').trim() : null,
        ArchivoSoporte4: image4.length !== 0 ? image4[0].FileData : null,
        ArchivoSoporteExt4: image4.length !== 0? image4[0].FileExtension.replace('.', '').trim() : null,
        PageUrl: pageUrl,
        ClaTipoSoporte: tipoSoporte
      };

      callApi(urlWebService, 'POST', paramsService, (response) => {
        showSweetAlert("Success", t('it:TicketSubmitted'), 'success');

        clearForm();

        if (props.onHide !== undefined) {
          props.onHide();
        }

      });
    }
  }

  return (
    <>
      <Row className="p-fluid p-grid p-formgrid">
        <Col xs={12} sm={6} md={6} lg={6} className="p-field">
          <Label>{t("common:User")}</Label>
          <FieldAutoComplete
            dropdown
            id="user"
            name="user"
            value={user}
            onChange={onUserChange}
            suggestions={users}
            field="NomUsuario"
            completeMethod={filterUser}
            errors={errors}
            disabled={!isITUser}
          /> 
        </Col>
        <Col xs={6} sm={6} md={6} lg={6} className='p-field'>
          <Label>{t('it:SupportType')}</Label>
          <FieldDropdown
            id='tipoSoporte'
            name="tipoSoporte"
            value={tipoSoporte}
            optionValue="ClaTipoSoporte"
            optionLabel="NomTipoSoporte"
            options={tiposSoporte}
            onChange={onChangeTipoSoporte}
            errors={errors}
          >
          </FieldDropdown>
        </Col>
        <Col xs={12} sm={6} md={6} lg={4} className='p-field'>
          <Label>{t("it:Priority")}</Label>
          <FieldDropdown
            name='priority'
            value={priority}
            optionValue="ClaPrioridadIncidenteHlp"
            optionLabel="NomPrioridadIncidenteHlp"
            options={priorities}
            onChange={onPriorityChange}
            errors={errors}
          >
          </FieldDropdown>
        </Col>
        <Col xs={12} sm={6} md={6} lg={4} className="p-field">
          <label htmlFor='hours'>{t("it:Hours")}</label>
          <FieldText
            id='hours'
            name='hours'
            value={hour}
            disabled={true}
          >  
          </FieldText>
        </Col>
        <Col xs={12} sm={6} md={6} lg={4} className='p-field'>
          <label htmlFor='fechaPromesa'>{t('it:PromiseDate')}</label>
          <FieldCalendar
            id='fechaPromesa'
            name='fechaPromesa'
            value={moment(fechaPromesa).toDate()}
            disabled={true}
          >
          </FieldCalendar>
        </Col>
        <Col xs={12} sm={6} md={12} lg={12} className="p-field">
          <Label>{t('it:TicketTitle')}</Label>
          <FieldText
            id='title'
            name='title'
            value={title}
            onChange={onTitleChange}
            errors={errors}
          >
          </FieldText>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} className='p-field'>
          <Label>{t("common:Description")}</Label>
          <FieldTextArea
            id='description'
            name='description'
            value={description}
            onChange={onDescriptionChange}
            rows={5}
            errors={errors}
          >
          </FieldTextArea>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} className=''>
          <Label>{t("it:Images")}</Label>
        </Col>
        <Col xs={12} sm={6} md={3} lg={3} className='p-field'>
          <FieldUpload
            name='image1'
            files={image1}
            onChange={(files) => setImage1(files)}
          >
          </FieldUpload>
        </Col>
        <Col xs={12} sm={6} md={3} lg={3} className='p-field'>
          <FieldUpload
            name='image2'
            files={image2}
            onChange={(files) => setImage2(files)}
          >
          </FieldUpload>
        </Col>
        <Col xs={12} sm={6} md={3} lg={3} className='p-field'>
          <FieldUpload
            name='image3'
            files={image3}
            onChange={(files) => setImage3(files)}
          >
          </FieldUpload>
        </Col>
        <Col xs={12} sm={6} md={3} lg={3} className='p-field'>
          <FieldUpload
            name='image4'
            files={image4}
            onChange={(files) => setImage4(files)}
          >
          </FieldUpload>
        </Col>
      </Row>
      <Row className="p-grid float-right mt-2">
        <Col>
          <FieldButton
            name='submit'
            icon='pi pi-check'
            className='p-button-success'
            onClick={onSubmitClick}
            label={t('common:Submit')}
          >
          </FieldButton>
        </Col>
      </Row>
    </>
  )
}

export default EnterTicket;
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PageContent from '../../../layouts/PageContent';
import { Ripple } from 'primereact/ripple';
import { config } from '../../../utils/config';
import { Row, Col, Card, CardHeader, CardBody, Tooltip } from 'reactstrap';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Panel } from 'primereact/panel';
import { Column } from 'primereact/column';
import swal from '@sweetalert/with-react';
import { Avatar } from 'primereact/avatar';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import { SplitButton } from 'primereact/splitbutton';
import { ProgressBar } from 'primereact/progressbar';
import { callApi, getSessionItem, showSweetAlert } from '../../../utils/utils';
import FieldCalendar from '../../../components/Controls/FieldCalendar.jsx';
import FieldDropdown from '../../../components/Controls/FieldDropdown.jsx';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import FieldTextArea from '../../../components/Controls/FieldTextArea.jsx';
import FieldText from '../../../components/Controls/FieldText.jsx';
import FieldSelectButton from '../../../components/Controls/FieldSelectButton.jsx';
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon';
import moment from 'moment';
import FieldCheckbox from 'components/Controls/FieldCheckbox';
import FieldNumber from 'components/Controls/FieldNumber';
import { Divider } from 'primereact/divider';
import { Tree } from 'primereact/tree';
import TreeviewButton from 'components/Controls/TreeViewButton/TreeviewButton';
import FieldAutoComplete from 'components/Controls/FieldAutoComplete';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import LibraryEditAttribute from './LibraryEditAttribute';

const LibraryAddNew = (props) => {
  const { t } = useTranslation(['dms', 'common']);
  const [errors, setErrors] = useState({});
  const [NomLibrary, setNomLibrary] = useState(null);
  const [NomLibraryDescripcion, setNomLibraryDescripcion] = useState(null);
  const [Descripcion, setDescripcion] = useState(null);
  const [ClaFormularioTipoCampo, setClaFormularioTipoCampo] = useState(null);
  const [TipoCampoCmb, setTipoCampoCmb] = useState([]);
  const [EsHabilitarMultiSeleccion, setEsHabilitarMultiSeleccion] = useState(0);
  const [CamposMultiSeleccion, setCamposMultiSeleccion] = useState([]);
  const [CamposMultiSeleccionParam, setCamposMultiSeleccionParam] = useState([]);
  const [EsObligatorio, setEsObligatorio] = useState(false);
  const [AttributeMultiSeleccion, setAttributeMultiSeleccion] = useState([]);
  const [NomFormularioTipoCampo, setNomFormularioTipoCampo] = useState(null);
  const [nodes, setNodes] = useState([]);
  const [selectedUser, setselectedUser] = useState(null);
  const [selectedUserFinal, setselectedUserFinal] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState({});
  const [selectedFolder, setselectedFolder] = useState(null);
  const [CamposMultiSeleccionById, setCamposMultiSeleccionById] = useState([]);
  const [EsEditarOpciones, setEsEditarOpciones] = useState(false);
  const [itemAtributoEditar, setitemAtributoEditar] = useState({});
  const [filteredResponsibleParticipants, setfilteredResponsibleParticipants] = useState([]);
  const [ClaUsuario, setClaUsuario] = useState(null);
  const [EsMostrarSoloUsuarioMSW, setEsMostrarSoloUsuarioMSW] = useState(false);
  const [UsuarioRoles, setUsuarioRoles] = useState([]);
  const [ClaFolder, setClaFolder] = useState([]);
  const [ClaLibrary, setClaLibrary] = useState(null);
  const [EsEditar, setEsEditar] = useState(false);
  const [ClaColaborador, setClaColaborador] = useState({});

  useEffect(() => {
    obtenerTipoCampo();
    obtenerOrganizationalDataByUser();
    setselectedUser([]);
    loadLibraryList(props.ClaLibrary);
    // GetLibraryAttribute(props.ClaLibrary);
    // GetLibraryRole(props.ClaLibrary);
    setClaLibrary(props.ClaLibrary === undefined ? null : props.ClaLibrary);
    setEsEditar(props.EsEditar === undefined ? false : props.EsEditar);
  }, [props.Filters, props.EsEditar, props.ClaLibrary]);

  const loadLibraryList = (pClaLibrary) => {
    const GetTeamPerformanceDevelopmentInfo = `${config.UrlApiProject}DMS/GetLibraryById`;
    const paramsToService = {
      ClaLibrary: pClaLibrary 
    };

    callApi(GetTeamPerformanceDevelopmentInfo, 'POST', paramsToService, (response) => {
      const library = response.data.Library.length > 0 ? response.data.Library[0] : {};
      setNomLibrary(library.NomLibrary);
      setNomLibraryDescripcion(library.NomLibraryDescripcion);
      setselectedFolder(library.ClaFolder);
      setClaFolder(library.ClaFolder); 
      setAttributeMultiSeleccion(response.data.LibraryAttributes);
      bindAttributeOpciones(response.data.LibraryAttributesOptions, response.data.LibraryAttributes);
      bindUsuarioRoles(response.data.LibraryRoles);

    });
  };

  const GetLibraryAttribute = (pClaLibrary) => {
    const getLibraryAttribute = `${config.UrlApiProject}DMS/GetLibraryAttribute?ClaLibrary=${pClaLibrary}`;
    const paramsToService = {};
    callApi(getLibraryAttribute, 'GET', paramsToService, (response) => {
      setAttributeMultiSeleccion(response.data.ListAttibute);
      GetLibraryAttributeOpciones(pClaLibrary, response.data.ListAttibute);
      console.log(response.data.ListAttibute);
    });
  };
  const GetLibraryAttributeOpciones = (pClaLibrary, pListAttribute) => {
    const getLibraryAttribute = `${config.UrlApiProject}DMS/GetLibraryAttributeOpciones?ClaLibrary=${pClaLibrary}`;
    const paramsToService = {};
    callApi(getLibraryAttribute, 'GET', paramsToService, (response) => {
      bindAttributeOpciones(response.data.ListOpciones, pListAttribute);
    });
  };

  const GetLibraryRole = (pClaLibrary) => {
    const getLibraryRole = `${config.UrlApiProject}DMS/GetLibraryRole?ClaLibrary=${pClaLibrary}`;
    const paramsToService = {};
    callApi(getLibraryRole, 'GET', paramsToService, (response) => {
      bindUsuarioRoles(response.data.ListRol);
    });
  };

  const bindUsuarioRoles = (listUsuarioRoles) => { 
    const arrayUsuarioRoles = [];
      let RowValues ={};
      for(let i=0; i < listUsuarioRoles.length; i++){
        RowValues ={    
           ...listUsuarioRoles[i]
           ,ClaUsuario : {
            ClaColaborador: listUsuarioRoles[i].ClaUsuario,
            NomColaborador: listUsuarioRoles[i].NomUsuario
          } 
      }
      arrayUsuarioRoles.push(RowValues);
    }
    setUsuarioRoles(arrayUsuarioRoles)
  }

  const bindAttributeOpciones = (attributeOpciones, pListAttribute) => {
    const arrayOpciones = [];
    let RowValues = {};
    for (let i = 0; i < pListAttribute.length; i++) {
      const arrayOpcciones = attributeOpciones.filter(
        (opt) => opt.ClaLibraryAttribute === pListAttribute[i].ClaLibraryAttribute
      );
      RowValues = {
        CamposMultiSeleccion: arrayOpcciones,
        RowIndex: i,
        BajaLogica: 0,
      };
      arrayOpciones.push(RowValues);
    }
    console.log(arrayOpciones);
    setCamposMultiSeleccionById(arrayOpciones);
  };

  const obtenerTipoCampo = () => {
    const getTipoCampoCmb = `${config.UrlApiProject}HR/GetTipoCampoCmb`;
    const paramsToService = {};
    callApi(getTipoCampoCmb, 'GET', paramsToService, (response) => {
      setTipoCampoCmb(response.data.TipoCampo);
    });
  };

  const onChangeNomLibrary = (value) => {
    const newErrors = { ...errors };
    delete newErrors.NomLibrary;
    setErrors(newErrors);
    setNomLibrary(value);
  };

  const onChangeNomLibraryDescripcion = (e) => {
    const newErrors = { ...errors };
    delete newErrors.NomLibraryDescripcion;
    setErrors(newErrors);
    setNomLibraryDescripcion(e.target.value);
  };

  const showActiveRows = (rows) => {
    const activeRows = [];
    if (rows !== undefined) {
      if (rows.length > 0) {
        rows.forEach((item, index) => {
          if (!item.BajaLogica || item.BajaLogica === 0 || item.BajaLogica === undefined) {
            item.BajaLogica = 0;
            item.RowIndex = index;
            activeRows.push({ ...item });
          }
        });
      } else {
        return [];
      }
    }

    return activeRows;
  };

  const onRemoveAttributeClick = (item) => {
    const copyAttributeMultiSeleccion = [...AttributeMultiSeleccion];
    copyAttributeMultiSeleccion[item.RowIndex].BajaLogica = 1;
    setAttributeMultiSeleccion(copyAttributeMultiSeleccion);

    if (item.ClaFormularioTipoCampo === 8 || item.ClaFormularioTipoCampo === 10 || item.ClaFormularioTipoCampo === 11) {
      const copyCamposMultiSeleccionById = [...CamposMultiSeleccionById];
      copyCamposMultiSeleccionById[item.RowIndex].BajaLogica = 1;
      setCamposMultiSeleccionById(copyCamposMultiSeleccionById);
    }
  };

  const onEditAttributeClick = (item) => {
    setitemAtributoEditar(item);
    setEsEditarOpciones(true);

    // onDescripcionChange(item.Descripcion);
    // onEditTipoCampo(item.ClaFormularioTipoCampo);
    // onEditObligarotio(item.EsObligatorio);
    setCamposMultiSeleccionParam([]);

    if (item.ClaFormularioTipoCampo === 11 || item.ClaFormularioTipoCampo === 10 || item.ClaFormularioTipoCampo === 8) {
      setEsHabilitarMultiSeleccion(1);
      const copyAttributeMultiSeleccion = [...CamposMultiSeleccionById];
      const filterAttributeMultiSeleccion = copyAttributeMultiSeleccion.filter(
        (opt) => opt.RowIndex === item.RowIndex
      );
      setCamposMultiSeleccionParam([...filterAttributeMultiSeleccion[0].CamposMultiSeleccion]);
      // setCamposMultiSeleccion([...copyAttributeMultiSeleccion[item.RowIndex].CamposMultiSeleccion]);
    }
  };

  const AddNewAttribute = () => {
    setitemAtributoEditar({});
    setEsEditarOpciones(true);
    setCamposMultiSeleccionParam([]);
  };

  const onHide = () => {
    setEsHabilitarMultiSeleccion(0);
    setEsEditarOpciones(false);
    setitemAtributoEditar({});
  };

  const valitationSchemeSave = yup.object().shape({
    NomLibrary: yup.string().required(t('common:RequiredField')).nullable(),
    NomLibraryDescripcion: yup.string().required(t('common:RequiredField')).nullable(),
    /* ClaFolder: yup.mixed().required(t('common:RequiredField')).nullable(), */
  });

  const onSaveLibrary = async () => {
    const value = {
      NomLibrary,
      NomLibraryDescripcion,
      /* ClaFolder, */
    };

    const validator = new YupValidator(valitationSchemeSave);
    const esValido = await validator.validate(value);
    if (!esValido) { 
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      return;
    }

    /*
    if (ClaFolder === undefined) {
      showSweetAlert(t('common:Warning'), t('dms:ReviewRequiredFieldsFolder'), 'warning');
      return;
    }
    */

    const OpcionesPorAtributo = CamposMultiSeleccionById; /* .filter((opt) => opt.BajaLogica === 0); */
    const AttributeOptionListById = [];
    let objOpciones = {};

    AttributeMultiSeleccion.forEach((element) => {
      const arrayOpciones = OpcionesPorAtributo.filter((opt) => opt.RowIndex === element.RowIndex);
      if (arrayOpciones !== undefined) {
        if (arrayOpciones[0].CamposMultiSeleccion !== null && arrayOpciones[0].CamposMultiSeleccion.length > 0) {
          const arrayCamposMultiSeleccion = arrayOpciones[0].CamposMultiSeleccion;
          arrayCamposMultiSeleccion.forEach((item) => {
            objOpciones = {
              RowIndexOpciones: element.RowIndex,
              ClaLibraryAttribute: element.ClaLibraryAttribute,
              ClaLibraryAttributeOption: item.ClaLibraryAttributeOption,
              NomLibraryAttributeOption: item.NomLibraryAttributeOption,
              BajaLogica : item.BajaLogica,
              Order : item.Order
            };
            AttributeOptionListById.push(objOpciones);
          });
        }
      }
    });
    const urlWebServiceSave = `${config.UrlApiProject}DMS/SaveLibrary`;

    const paramsService = {
      ClaLibrary,
      NomLibrary,
      NomLibraryDescripcion,
      ClaFolder,
      AttributeListDTO: AttributeMultiSeleccion,
      AttributeOptionListDTO: AttributeOptionListById,
      RolesListDTO: UsuarioRoles,
      // IdAccion: 1,
    };
    console.log(paramsService);

    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {
      showSweetAlert(t('common:Exito'), t('common:SaveSuccess'), 'success');
      setErrors({});
      if (props.onAfterReturn != null) {
        props.onAfterReturn();
      }
    });    
  };

  const onCancel = () => {
    if (props.onHide !== undefined && typeof props.onHide === 'function') {
      props.onHide();
    }
  };  

  const SaveOpcionesEditor = (filterApply) => {
    setEsHabilitarMultiSeleccion(0);
    // Guardamos la descripcion del atributo seleccionado
    const updatedAttributeMultiSeleccion = [...AttributeMultiSeleccion];

    console.log(filterApply);
    if (Object.keys(itemAtributoEditar).length === 0) {
      const newRow = {
        RowIndex: AttributeMultiSeleccion !== undefined ? AttributeMultiSeleccion.length : 0,
        ClaLibraryAttribute: null,
        NomLibraryAttribute: filterApply.NomLibraryAttribute,
        ClaFormularioTipoCampo: filterApply.ClaFormularioTipoCampo,
        EsObligatorio: filterApply.EsObligatorio,
        BajaLogica: 0,
        NomFormularioTipoCampo: filterApply.NomFormularioTipoCampo,
        Order: filterApply.Order,
      };

      setAttributeMultiSeleccion([...AttributeMultiSeleccion, newRow]);

      if (filterApply.ClaFormularioTipoCampo === 11 || filterApply.ClaFormularioTipoCampo === 10 || filterApply.ClaFormularioTipoCampo === 8) {
        const newRowMultiSelOpciones = {
          RowIndex: AttributeMultiSeleccion !== undefined ? AttributeMultiSeleccion.length : 0,
          CamposMultiSeleccion: filterApply.CamposMultiSeleccionByIdNew,
          BajaLogica: 0,
        };
        setCamposMultiSeleccionById([...CamposMultiSeleccionById, newRowMultiSelOpciones]);
      }else{
        const newRowMultiSelOpcionesVacias = {
          RowIndex: AttributeMultiSeleccion !== undefined ? AttributeMultiSeleccion.length : 0,
          CamposMultiSeleccion: null,
          BajaLogica: 1,
        };
        setCamposMultiSeleccionById([...CamposMultiSeleccionById, newRowMultiSelOpcionesVacias]);
      }
    } else {
      updatedAttributeMultiSeleccion[itemAtributoEditar.RowIndex].NomLibraryAttribute =
        filterApply.NomLibraryAttribute;
      updatedAttributeMultiSeleccion[itemAtributoEditar.RowIndex].EsObligatorio =
        filterApply.EsObligatorio;
        updatedAttributeMultiSeleccion[itemAtributoEditar.RowIndex].Order =
        filterApply.Order;

      const claFormularioTipoCampo = TipoCampoCmb.filter(
        (item) => item.ClaFormularioTipoCampo === filterApply.ClaFormularioTipoCampo
      )[0];
      if (claFormularioTipoCampo !== undefined) {
        updatedAttributeMultiSeleccion[itemAtributoEditar.RowIndex].NomFormularioTipoCampo =
          claFormularioTipoCampo.NomFormularioTipoCampo;
      }
      updatedAttributeMultiSeleccion[itemAtributoEditar.RowIndex].ClaFormularioTipoCampo =
        filterApply.ClaFormularioTipoCampo;

      setAttributeMultiSeleccion(updatedAttributeMultiSeleccion);

      if (itemAtributoEditar.ClaFormularioTipoCampo === 11 || itemAtributoEditar.ClaFormularioTipoCampo === 10 || itemAtributoEditar.ClaFormularioTipoCampo === 8) {
        // const filterAttributeMultiSeleccion = CamposMultiSeleccionById.filter(
        //   (opt) => opt.RowIndex === itemAtributoEditar.RowIndex
        // );
        // const copyCamposMultiSeleccionById = [...filterAttributeMultiSeleccion];
        // copyCamposMultiSeleccionById[0].CamposMultiSeleccion =
        //   filterApply.CamposMultiSeleccionByIdNew;
        const copyCamposMultiSeleccionById = [...CamposMultiSeleccionById];
        copyCamposMultiSeleccionById[itemAtributoEditar.RowIndex].CamposMultiSeleccion =filterApply.CamposMultiSeleccionByIdNew;
        setCamposMultiSeleccionById(copyCamposMultiSeleccionById);
      }
    }
    setitemAtributoEditar({});
  };

  const obtenerOrganizationalDataByUser = () => {
    const GetOrganizationalDataInfo = `${config.UrlApiProject}DMS/GetCatBSCDMFolderCmb`;
    const paramsToService = {};

    callApi(GetOrganizationalDataInfo, 'GET', paramsToService, (response) => {
      expandAll(response.data);
      setNodes(response.data);
    });
  };

  const expandAll = (nodes) => {
    const _expandedKeys = {};
    for (const node of nodes) {
      expandNode(node, _expandedKeys);
      break;
    }

    setExpandedKeys(_expandedKeys);
  };

  const expandNode = (node, _expandedKeys) => {
    if (node.children && node.children.length) {
      _expandedKeys[node.key] = true;
    }
  };

  const onToggle = (event) => {
    setExpandedKeys(event.value);
  };

  const nodeTemplate = (node, options) => {
    return <span className={options.className}>{node.label}</span>;
  };
  let selectedNodeElement = null;
  const onChangeFolder = (node) => {
    const newErrors = { ...errors };
    delete newErrors.ClaFolder;
    setErrors(newErrors);
    setselectedFolder(node);
    // busco en mis nodos con el id para obtener el elemento y setear el valor de imgUrl
    selectedNodeElement = null;
    const item = readChildNode(nodes, node);

    setClaFolder(item.key);
    // setImageUrl(item.ImageUrl);

    // if (props.onChange !== undefined && typeof props.onChange === 'function') {
    //   props.onChange(node, item);
    // }
  };

  const readChildNode = (nodesArray, node) => {
    nodesArray.map((element, key) => {
      if (element.key === node) {
        selectedNodeElement = element;
        return element;
      }

      if (element.children.length > 0) {
        return readChildNode(element.children, node);
      }

      return null;
    });

    return selectedNodeElement;
  };

  const onChangeClaUsuarioResponsableParticipants = (props, e) => {
    const updatedUsuarioRoles = [...UsuarioRoles];
    if (e.value != null && typeof e.value === 'object') {
      const responsible = filteredResponsibleParticipants.filter(
        (item) => item.ClaColaborador === e.value.ClaColaborador
      )[0];

      if (responsible !== undefined) {
        updatedUsuarioRoles[props.rowData.RowIndex].NomUsuario = responsible.NomColaborador;
        updatedUsuarioRoles[props.rowData.RowIndex].ClaUsuario = responsible.ClaColaborador;
        updatedUsuarioRoles[props.rowData.RowIndex].ClaColaborador = responsible.ClaColaborador;
      }
    } else {
      updatedUsuarioRoles[props.rowData.RowIndex].NomUsuario = null;
    }
    updatedUsuarioRoles[props.rowData.RowIndex].ClaUsuario = e.value;
    setUsuarioRoles(updatedUsuarioRoles);
   
  };

  const AddResponsableRol = () => {
    const newRow = {
      RowIndex: UsuarioRoles.length,
      ClaUsuario: null,
      NomUsuario: null,
      ClaColaborador: null,
      Bajalogica: 0,
    };
    setUsuarioRoles([...UsuarioRoles, newRow]);
    // setUsuarioRoles([...UsuarioRoles, ClaColaborador]);
  };
  const filterResponsibleParticipants = (props, event) => {
    let valor = '';
    valor = event.query;
    const paramsServiceOwners = {};
    // event.query
    const urlWebServiceOwners = `${
      config.UrlApiProject
    }DMS/GetRolesColaboradorCmb?descriptionFilter=${valor}&EsMostrarSoloUsuarioMSW=${
      !EsMostrarSoloUsuarioMSW ? 1 : 0
    }`;
    // Get owner
    callApi(urlWebServiceOwners, 'GET', paramsServiceOwners, (response) => {
      setfilteredResponsibleParticipants(response.data[0]);
    });
  };

  const onRemoveResponsableRolesClick = (item) => {
    const copyeUsuarioRoles = [...UsuarioRoles];
    copyeUsuarioRoles[item.RowIndex].BajaLogica = 1;
    setUsuarioRoles(copyeUsuarioRoles);
  };

  const headerTemplateResponsableRol = (
    <>
      <Row>
        <Col>{t('dms:UsersList')}</Col>
        <Col className="text-right">
          <FieldButton
            className="p-button p-button-icon-only p-button-info rounded-button"
            onClick={(e) => AddResponsableRol()}
          >
            <i className="pi pi-plus"></i>
          </FieldButton>
        </Col>
      </Row>
    </>
  );

  const ResponsibleParticipantsEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldAutoComplete
          dropdown
          name="ClaUsuario"
          value={props.rowData.ClaUsuario}
          onChange={(e) => onChangeClaUsuarioResponsableParticipants(props, e)}
          suggestions={filteredResponsibleParticipants}
          field="NomColaborador"
          completeMethod={(e) => filterResponsibleParticipants(props, e)}
          errors={errors[`UsuarioRoles[${props.rowData.RowIndex}]`]}
        />
      </div>
    );
  };
  const fieldsRequiredRol = (rowData, props) => {
    return (
      <>
        {errors[`UsuarioRoles[${props.rowData.RowIndex}]`] != null ? (
          <i className="fas fa-exclamation-triangle invalid-color"></i>
        ) : (
          ''
        )}
      </>
    );
  };

  const BotonesTemplateRol = (rowData, e) => {
    return (
      <>
        <FieldButton
          icon="pi pi-trash"
          onClick={() => onRemoveResponsableRolesClick(rowData)}
          className="p-button-rounded p-button-warning"
        />
      </>
    );
  };

  const actionBodyTemplateAttribute = (rowData, props) => {
    return (
      <>
        <FieldButton
          hidden={showActiveRows(AttributeMultiSeleccion).length === 0}
          icon="pi pi-pencil"
          className="p-button-rounded p-highlight"
          onClick={() => onEditAttributeClick(rowData)}
        />
        &nbsp;
        <FieldButton
          icon="pi pi-trash"
          onClick={() => onRemoveAttributeClick(rowData)}
          className="p-button-rounded p-button-warning"
        />
      </>
    );
  };

  const EsObligatorioEditor = (rowData, props) => {
    return (
      <span className="survey-edit-label">
        {rowData.EsObligatorio === 1 || rowData.EsObligatorio ? 'YES' : 'NO'}
      </span>
    );
  };

  return (
    <>
      <Row>
        <Col className="text-right">
          <FieldButton
            icon="pi pi-arrow-left"
            className="p-button-rounded p-button-info"
            onClick={onCancel}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          &nbsp;
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <Card>
            <CardBody>
              <Row>
                <Col lg={3} md={3} sm={12} xs={12} className="pl-2 text-center">
                  <Avatar label="1" className="ex-large secondary" size="xlarge" shape="circle" />
                </Col>
                <Col lg={9} md={9} sm={12} xs={12} className="border-left">
                  <Row className="pt-3">
                    <Col>
                      <span className="bold">
                        <h2> {t('dms:TitleStep1')} </h2>{' '}
                      </span>
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col>
                      <span className="bold">{t('dms:NomLibrary')}</span>
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col>
                      <div className="p-fluid">
                        <FieldText
                          name="NomLibrary"
                          value={NomLibrary}
                          onChange={(e) => onChangeNomLibrary(e.target.value)}
                          errors={errors}
                          autoFocus
                          maxLength={250}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col>
                      <span className="bold">{t('dms:NomLibraryDescription')}</span>
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col>
                      <div className="p-fluid">
                        <FieldTextArea
                          name="NomLibraryDescripcion"
                          rows={2}
                          placeholder={t('dms:NomLibraryDescription')}
                          value={NomLibraryDescripcion}
                          onChange={onChangeNomLibraryDescripcion}
                          errors={errors}
                          maxLength={500}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row hidden={true}>
        <Col lg={12} md={12} sm={12} xs={12}>
          <Card>
            <CardBody>
              <Row>
                <Col lg={3} md={3} sm={12} xs={12} className="pl-2 text-center">
                  <Avatar label="2" className="ex-large secondary" size="xlarge" shape="circle" />
                </Col>
                <Col lg={9} md={9} sm={12} xs={12} className="border-left">
                  <Row className="pt-3">
                    <Col>
                      <span className="bold">
                        <h2> {t('dms:TitleStep2')} </h2>{' '}
                      </span>
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col>
                      <span className="bold">{t('dms:Folder')}</span>
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <div className="p-fluid p-field p-col-12">
                        <TreeviewButton
                          nodes={nodes}
                          optionLabel="label"
                          expandedKeys={expandedKeys}
                          nodeTemplate={nodeTemplate}
                          selectedKey={selectedFolder}
                          onChange={onChangeFolder}
                          icon="pi pi-angle-down"
                          iconPosition="right"
                          showAsTreeSelect={true}
                          // showPositionName={ClaFolder}
                          onToggle={onToggle}
                          name="ClaFolder"
                          errors={errors}
                        >
                        </TreeviewButton>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <Card>
            <CardBody>
              <Row>
                <Col lg={3} md={3} sm={12} xs={12} className="pl-2 text-center">
                  <Avatar label="2" className="ex-large secondary" size="xlarge" shape="circle" />
                </Col>
                <Col lg={9} md={9} sm={12} xs={12} className="border-left">
                  {!EsEditarOpciones ? (
                    <>
                      <Row className="pt-3">
                        <Col>
                          <span className="bold">
                            <h2> {t('dms:TitleStep3')} </h2>{' '}
                          </span>
                        </Col>
                      </Row>
                      <Row className="pt-2">
                        <Col>
                          <span className="bold">{t('dms:QuestionCategory')}</span>
                        </Col>
                      </Row>                     
                      <Row className="pt-5">
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <FieldDataTable
                            value={showActiveRows(AttributeMultiSeleccion)}
                            className="table-header-background-blue"
                            editMode="cell"
                            selectionMode="single"
                            paginator={false}
                          >                           
                            <Column
                              field="NomLibraryAttribute"
                              header={t('dms:Attribute')}
                              headerStyle={{ width: '280px' }}
                              // editor={(props) => DescripcionEditor(props)}
                            >
                            </Column>
                            <Column
                              field="NomFormularioTipoCampo"
                              columnKey="ClaFormularioTipoCampo"
                              header={t('dms:FieldType')}
                              headerStyle={{ width: '150px' }}
                              // editor={(props) => ClaFormularioTipoCampoEditor(props)}
                            >
                            </Column>
                            <Column
                              field="EsObligatorio"
                              header={t('dms:Required')}
                              headerStyle={{ width: '80px' }}
                              body={(rowData, props) => EsObligatorioEditor(rowData, props)}
                            >
                            </Column>
                            <Column
                              field="Order"
                              columnKey="Order"
                              header={t('dms:Order')}
                              headerStyle={{ width: '80px' }}                              
                            >
                            </Column>
                            <Column
                              body={(rowData, props) => actionBodyTemplateAttribute(rowData, props)}
                              headerStyle={{ width: '60px' }} 
                              className="text-right"
                            >
                            </Column>
                          </FieldDataTable>                          
                        </Col>
                      </Row>
                      <Row className="pt-5">
                        <Col lg={12} md={12} sm={12} xs={12} className="text-center">
                          <FieldButton
                            label={t('dms:AddNewAttribute')}
                            icon="pi pi-plus"
                            className="no-border p-mr-2 rounded-button"
                            onClick={AddNewAttribute}
                          />
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <></>
                  )}
                  {EsEditarOpciones ? (
                    <>
                      <LibraryEditAttribute
                        ClaLibrary={ClaLibrary}
                        onHide={onHide}
                        itemAtributoEditar={itemAtributoEditar}
                        SaveOpcionesEditor={SaveOpcionesEditor}
                        CamposMultiSeleccionParam={CamposMultiSeleccionParam}
                        EsHabilitarMultiSeleccion={EsHabilitarMultiSeleccion}
                      >
                      </LibraryEditAttribute>
                    </>
                  ) : (
                    <></>
                  )}
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      &nbsp;
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <Card>
            <CardBody>
              <Row>
                <Col lg={3} md={3} sm={12} xs={12} className="pl-2 text-center">
                  <Avatar label="3" className="ex-large secondary" size="xlarge" shape="circle" />
                </Col>
                <Col lg={9} md={9} sm={12} xs={12} className="border-left">
                  <Row className="pt-3">
                    <Col>
                      <span className="bold">
                        <h2> {t('dms:TitleStep4')} </h2>{' '}
                      </span>
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col>
                      <span className="bold">{t('dms:Role')}</span>
                    </Col>
                  </Row>
                  <Row className="pt-5">
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <FieldDataTable
                        value={showActiveRows(UsuarioRoles)}
                        header={headerTemplateResponsableRol}
                        scrollable
                        scrollHeight="270px"
                        className="editable-cells-table"
                        editMode="cell"
                      >
                        <Column body={fieldsRequiredRol} headerStyle={{ width: '20px' }}></Column>
                        <Column
                          body={BotonesTemplateRol}
                          className="text-left"
                          headerStyle={{ width: '35px' }}
                        >
                        </Column>
                        <Column
                          field="NomUsuario"
                          header={t('dms:UserName')}
                          columnKey="ClaUsuario"
                          headerStyle={{ width: '400px' }}
                          editor={(props) => ResponsibleParticipantsEditor(props)}
                        >
                        </Column>
                      </FieldDataTable>
                    </Col>                   
                  </Row>                 
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12} className="text-center">
          <FieldButton
            icon="fas fa-times"
            label={t('common:Cancel')}
            className="warning no-border p-mr-2 rounded-button"
            onClick={onCancel}
          />
          &nbsp;
          <FieldButton
            label={t('common:Save')}
            icon="pi pi-save"
            className="p-button-success no-border p-mr-2 rounded-button"
            onClick={onSaveLibrary}
          />
        </Col>
      </Row>
    </>
  );
};

export default LibraryAddNew;

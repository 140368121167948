/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next';  
import { Button } from 'primereact/button'; 
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row,Col} from 'reactstrap';   
import { config } from '../../../utils/config';
import { callApi,showSweetAlert, tracker} from '../../../utils/utils.js'; 
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon' 
import FieldText from 'components/Controls/FieldText';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx' 
import FieldDropdown from 'components/Controls/FieldDropdown.jsx' 
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import moment from "moment"; 
import swal from '@sweetalert/with-react';
import FasesActividades from "./FasesActividades.jsx"; 
import FasesDetalle from "./FasesDetalle.jsx";
import { Tag } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip';
import Highcharts from 'highcharts';
import MoverActividadNuevaFase from './MoverActividadNuevaFase.jsx';

const Fases = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'misActividades', 'common']);
  const [errors, setErrors] = useState({}); 
  const [ClaFase, setClaFase] = useState(props.ClaFase === undefined ? 0 : props.ClaFase); 
  const [ClaProyecto, setClaProyecto] = useState(props.ClaProyecto === undefined ? 0 : props.ClaProyecto); 
  const [ClaActividadSelected, setClaActividadSelected] = useState(null); 
  const [NomActividad, setNomActividad] = useState(null);  
  const [FaseProyecto, setFaseProyecto] = useState({});
  const [FaseProyectos, setFaseProyectos] = useState([]);
  const [ClaResponsable , setClaResponsable ] = useState(null); 
  const [ClaAutorizador, setClaAutorizador] = useState(null); 
  const [FechaEstimadaInicio , setFechaEstimadaInicio ] = useState(null); 
  const [FechaEstimadaFin, setFechaEstimadaFin] = useState(null);  
  const [StatusFasesActividad, setStatusFasesActividad] = useState([]);
  const [FaseProyectoActividades, setFaseProyectoActividades] = useState([]);
  const [EsFaseDetalle, setEsFaseDetalle] = useState(false);
  const [EsFaseActividad, setEsFaseActividad] = useState(false);
  const [OpcionesResponsables, setOpcionesResponsables] = useState({});
  const [OriginalFaseProyectoActividades, setOriginalFaseProyectoActividades] = useState([]); 
  const [Filters, setFilters] = useState({});
  const [classNameButton, setclassNameButton] = useState('secondary');
  const inputRef = useRef(null);
  const [EsColapsado, setEsColapsado] = useState(true);
  const [EsMoverActividad, setEsMoverActividad] = useState(false);

  useEffect(() => {    
    const GetStatusFasesActividad = `${config.UrlApiProject}ScoreCard/GetMSWEstatusFasesCmb`;  
    const paramsToService = {};   
    callApi(GetStatusFasesActividad, 'POST', paramsToService, (response) => {  
        setStatusFasesActividad(response.data[0]);
        obtenerResponsablesProyecto(); 
    });
  }, []); 

  useEffect(() => {
  }, [props.Filters]); 

  const obtenerResponsablesProyecto = () => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/GetMswBSCColaboradorProyectosTeam`;  
    const paramsToService = { 
      ClaProyecto
    };   
    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => { 
      if (response.data[0].length >0)
      {  
        setOpcionesResponsables(response.data[0]);
        obtenerInformacionFasesProyecto();
      }  
    });
  }

  const obtenerInformacionFasesProyecto = () => { 
    const GetFaseProyecto = `${config.UrlApiProject}ScoreCard/GetFaseProyecto`;  
    const paramsToService = { 
      ClaProyecto,
      ClaFase
    };   
    callApi(GetFaseProyecto, 'POST', paramsToService, (response) => { 
      if (response.data.FaseProyecto[0] !== undefined)
      {  
        setFaseProyecto(response.data.FaseProyecto[0]);
        setFaseProyectos(response.data.FaseProyecto);
        setFaseProyectoActividades(response.data.FaseProyectoActividades)
        setOriginalFaseProyectoActividades(response.data.FaseProyectoActividades); 
        // console.table(response.data.FaseProyecto);
      }  
    }, null, false, false);
  }

  const handleEditClick = (rowData) => {
    // document.body.classList.add('bodynotscroll');
    setClaActividadSelected(rowData.ClaActividad);
    setEsFaseActividad(true); 

    tracker.trackEvent({
      type: 'Fases', /* component name */
      action: 'EDIT_ACTIVITY'
    }) 
  };

  const onCopyActivity = (rowData) => {
    const CopiarProyectoActividad = `${config.UrlApiProject}ScoreCard/CopiarProyectoActividad`;
    const paramsService = {
        ClaProyecto:rowData.ClaProyecto,
        ClaFase:rowData.ClaFase, 
        ClaActividad:rowData.ClaActividad 
    };  

    tracker.trackEvent({
      type: 'Fases', /* component name */
      action: 'COPY_ACTIVITY'
    }) 

    callApi(CopiarProyectoActividad, 'POST', paramsService, (response) => { 
      
      // showSweetAlert(t('common:Success'), t('common:SuccessOperation'), 'success');
      // refrescamos la informacion....
      obtenerInformacionFasesProyecto();
      if (props.onChange !== null && props.onChange !== 'undefined' && typeof(props.onChange) === 'function'){
        props.onChange();
      }
    }, () =>{ 
      obtenerInformacionFasesProyecto();
   }, null, false, false);
  }

  const handleHeaderEditClick = () => {
    // document.body.classList.add('bodynotscroll');
    setEsFaseDetalle(true);

    
    tracker.trackEvent({
      type: 'Fases', /* component name */
      action: 'EDIT_PHASE'
    }) 

  };
  
  const onHideFase = () => {
    // document.body.classList.remove('bodynotscroll');
    setEsFaseDetalle(false);
  }

  const onHideActividad = () => {
    // document.body.classList.remove('bodynotscroll');
    setEsFaseActividad(false);
  }

  const onHideMoverActividad = () => {
    // document.body.classList.remove('bodynotscroll');
    setEsMoverActividad(false);
  }

  const handleHeaderMoverActividadClick = (rowData) => {
    // document.body.classList.add('bodynotscroll');
    setClaActividadSelected(rowData.ClaActividad);
    setEsMoverActividad(true);
    tracker.trackEvent({
      type: 'Fases', /* component name */
      action: 'MOVE_ACTIVITY'
    }) 
  };
  const onChangeEsFaseActividad = () => {
    setClaActividadSelected(null);  
    onHideActividad();
    obtenerInformacionFasesProyecto();
  };
  
  const onChangeEsFaseDetalle = () => { 
    onHideFase();
    obtenerInformacionFasesProyecto();
  };
  
  const onChangeEsMoverActividad = () => { 
    onHideMoverActividad();
    obtenerInformacionFasesProyecto();
  };

  const onChangeNomActividad = (value) => {
    const newErrors = { ...errors } 
    delete newErrors.NomActividad;   
    setErrors(newErrors)   
    setclassNameButton('secondary');
    if(value.length>0){
      setclassNameButton('info');
    }
    setNomActividad(value); 
  };

  const onClaResponsableChange = (props, e ) => {
    const newErrors = { ...errors } 
    delete newErrors.ClaResponsable;   
    delete newErrors.ClaAutorizador; 
    setErrors(newErrors) 
    setClaResponsable(e.value);
    setClaAutorizador(e.value);
  }; 
  
  const onClaAutorizadorChange = (props, e ) => {
    const newErrors = { ...errors } 
    delete newErrors.ClaAutorizador;   
    setErrors(newErrors) 
    setClaAutorizador(e.value)
  }; 
  
  const onFechaEstimadaInicioRowChange = ( e ) => {  
    const newErrors = { ...errors } 
    delete newErrors.FechaEstimadaInicio;   
    setErrors(newErrors) 
    setFechaEstimadaInicio(e.value); 
  }; 

  const onFechaEstimadaFinRowChange = ( e ) => {  
    const newErrors = { ...errors } 
    delete newErrors.FechaEstimadaFin;   
    setErrors(newErrors) 
    setFechaEstimadaFin(e.value);
  }; 
  
  const headerActionsHeader = (rowData) => {
    return (
      <>    
        <div className="p-fluid" style={{paddingRight:'8px'}}> 
          <FieldButton icon="pi pi-pencil" title={t('misActividades:EditPhase')} onClick={() => handleHeaderEditClick()} className="p-button-rounded p-button-info" /> 
                &nbsp;
          <FieldButton icon="pi pi-trash" title={t('misActividades:RemovePhase')} onClick={() => onConfirmBaja(null,true)} className="p-button-rounded p-button-danger" /> 
                &nbsp; 
          <FieldButton icon="pi pi-angle-down" title={t('misActividades:ViewPhaseActivities')} onClick={() => onCollapsed(false)} className="p-button-rounded p-button-secondary" />               
        </div>
      </>
    );
  }  
     
  const headerActions = (rowData) => {
    return (
      <>    
        <div className="p-fluid" style={{paddingRight:'8px'}}> 
          <FieldButton icon="pi pi-pencil" title={t('misActividades:EditActivity')} onClick={() => handleEditClick(rowData)} className="p-button-rounded p-button-info" /> 
          &nbsp;
          <FieldButton icon="pi pi-trash" title={t('misActividades:RemoveActivity')} onClick={() => onConfirmBaja(rowData,true)} className="p-button-rounded p-button-danger" /> 
          &nbsp;
          {
            rowData.ClaTipoProyecto === 3 ?
            (   
              <FieldButton icon="fas fa-copy" title={t('misActividades:CopyActivity')} onClick={() => onCopyActivity(rowData)} className="p-button-rounded p-button-secondary" />  
            )
            :
            (<></>)
          }          
          &nbsp;          
          <FieldButton icon="fas fa-exchange-alt" title={t('misActividades:Move')} onClick={() => handleHeaderMoverActividadClick(rowData)} className="p-button-rounded p-button-secondary" /> 
          &nbsp; 
        </div>
      </>
    );
  } 
    
  const onRowEditInit = (event) => {
    OriginalFaseProyectoActividades[event.index] = { ...FaseProyectoActividades[event.index] };
    setOriginalFaseProyectoActividades(OriginalFaseProyectoActividades); 
  };

  const NomActividadEditor = ( props ) => { 
    return (
      <div className="p-fluid">
        <FieldText
          value={props.rowData.NomActividad} 
          onChange={(e) => onNomActividadChange(props, e.target.value)}
          onBlur={(e) => onNomActividadBlur(props, e.target.value)}
          maxLength={250}
        />
      </div>
    )
  }; 

  const FechaRealInicioEditor = ( props ) => { 
    return (
      <div className="p-fluid">
        <FieldCalendar
          name="FechaEstimadaInicio"
          value={(props.rowData.FechaEstimadaInicio!== null ? (new Date(props.rowData.FechaEstimadaInicio)): null)}
          onChange={(e) => onFechaEstimadaInicioChange(props, e.target.value)}
        />  
      </div> 
    )
  }; 

  const FechaRealFinEditor = ( props ) => { 
    return (
      <div className="p-fluid">
        <FieldCalendar
          name="FechaEstimadaFin"
          value={(props.rowData.FechaEstimadaFin!== null ? (new Date(props.rowData.FechaEstimadaFin)): null)}
          onChange={(e) => onFechaEstimadaFinChange(props, e.target.value)}
        />   
      </div> 
    )
  }; 

  const emptyValueValidator = (e) => {
    const { rowData, field } = e.columnProps;
    return rowData[field].trim().length > 0;
  }

  const ClaResponsableEditor = ( props ) => {
    return (
      <div className="p-fluid">
        <FieldDropdown  
          name="NomResponsable"
          value={props.rowData.ClaResponsable}
          options={OpcionesResponsables}  
          optionValue="ClaColaborador"
          optionLabel="NomColaborador" 
          showClear={false}
          onChange={(e) => onResponsableChange(props,e)}
        />  
      </div>
    )
  }; 
 
  const ClaAutorizadorEditor = ( props ) => { 
   return (
     <div className="p-fluid">
       <FieldDropdown  
         name="NomAutorizador"
         value={props.rowData.ClaAutorizador}
         options={OpcionesResponsables}  
         optionValue="ClaColaborador"
         optionLabel="NomColaborador" 
         showClear={false}
         onChange={(e) => onAutorizadorChange(props,e)}
       />   
     </div>
   )
  }; 

  const onNomActividadChange = (props, value ) => {
    const updatedProducts = [...props.value];
    updatedProducts[props.rowIndex][props.field] = value;
    setFaseProyectoActividades(updatedProducts);  
  }; 

  const onNomActividadBlur = (props, value ) => {
    const saveArray= getCurrentRowValues(props);
    if(value!=='' && saveArray.NomActividadReal !== saveArray.NomActividad){
      saveArray.NomActividad = value;
      saveActividad(saveArray);
    }else{
      obtenerInformacionFasesProyecto();
    }
  }; 

  const onResponsableChange = (props, e ) => {
    if(e.value===undefined){
      obtenerInformacionFasesProyecto();
    }else{
      const saveArray= getCurrentRowValues(props);
      const updatedProducts = [...props.value];
      const colaborador = OpcionesResponsables.filter(item => item.ClaColaborador ===e.value)[0];
      if(colaborador!==undefined){
        updatedProducts[props.rowIndex].NomResponsable = colaborador.NomColaborador;
      }
      updatedProducts[props.rowIndex].ClaResponsable = e.value;
      saveArray.ClaResponsable = e.value;
      setFaseProyectoActividades(updatedProducts); 
      saveActividad(saveArray);
    }
  }; 

  const onAutorizadorChange = (props, e ) => {
    if(e.value===undefined){
      obtenerInformacionFasesProyecto();
    }else{
      const saveArray= getCurrentRowValues(props);
      const updatedProducts = [...props.value];
      const colaborador = OpcionesResponsables.filter(item => item.ClaColaborador ===e.value)[0];
      if(colaborador!==undefined){
        updatedProducts[props.rowIndex].NomAutorizador = colaborador.NomColaborador;
      }
      updatedProducts[props.rowIndex].ClaAutorizador = e.value;
      saveArray.ClaAutorizador = e.value;
      setFaseProyectoActividades(updatedProducts); 
      saveActividad(saveArray);
    }
  };

  const onFechaEstimadaInicioChange = ( props, value ) => {  
    
    const updatedProducts = [...props.value];
    const saveArray= getCurrentRowValues(props); 
    saveArray.FechaEstimadaInicio = new Date(value)
    updatedProducts[props.rowIndex].FechaEstimadaInicio = value;

    if (value === null){
      obtenerInformacionFasesProyecto();
      return;
    } 
    saveActividad(saveArray);
  }; 

  const onFechaEstimadaFinChange = ( props, value ) => {  
    const updatedProducts = [...props.value];
    const saveArray= getCurrentRowValues(props);
    saveArray.FechaEstimadaFin = new Date(value)
    updatedProducts[props.rowIndex].FechaEstimadaFin = value;
    saveActividad(saveArray);
  }; 
 
  const getCurrentRowValues = (props) => {
    const updatedProducts = [...props.value];
    const RowValues ={
        ClaProyecto: updatedProducts[props.rowIndex].ClaProyecto,
        ClaFase: updatedProducts[props.rowIndex].ClaFase,
        ClaActividad: updatedProducts[props.rowIndex].ClaActividad,
        NomActividad: updatedProducts[props.rowIndex].NomActividad,
        NomActividadReal: updatedProducts[props.rowIndex].NomActividadReal,
        ClaResponsable:updatedProducts[props.rowIndex].ClaResponsable,
        ClaAutorizador:updatedProducts[props.rowIndex].ClaAutorizador,
        ClaEstatus: updatedProducts[props.rowIndex].ClaEstatus,
        FechaEstimadaInicio: updatedProducts[props.rowIndex].FechaEstimadaInicio, 
        FechaEstimadaFin: updatedProducts[props.rowIndex].FechaEstimadaFin,
        FechaRealFin: updatedProducts[props.rowIndex].FechaRealFin,
        Bajalogica: updatedProducts[props.rowIndex].Bajalogica,
        Milestone:updatedProducts[props.rowIndex].Milestone,
        IdProyectoFaseEntregable:updatedProducts[props.rowIndex].IdProyectoFaseEntregable
    }
    return RowValues;
  }

  const valitationSchemeAdd = yup.object().shape({
    NomActividad: yup.string().required(t('common:RequiredField')).nullable(),
    ClaResponsable: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaAutorizador: yup.mixed().required(t('common:RequiredField')).nullable(),
    FechaEstimadaInicio: yup.date().required(t('common:RequiredField')).nullable(),
    FechaEstimadaFin: yup.date().required(t('common:RequiredField')).nullable()
  });

  const addRow = async () => {

    tracker.trackEvent({
      type: 'Fases', /* component name */
      action: 'QUICK_NEW_ACTIVITY'
    }) 

    const value = {
      NomActividad,
      ClaResponsable,
      ClaAutorizador,
      FechaEstimadaInicio,
      FechaEstimadaFin
    }
    const validator = new YupValidator(valitationSchemeAdd);
    const esValido = await validator.validate(value);
    if (!esValido){ 
      setErrors(validator.errors); 
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      if(NomActividad===''){
        inputRef.current.focus();
      }
      return;
    }
    const addRowValues ={
      ClaProyecto,
      ClaFase,
      ClaActividad: 0,
      NomActividad,
      ClaEstatus: 1,
      ClaResponsable,
      ClaAutorizador,
      FechaEstimadaInicio,
      FechaEstimadaFin,
      Bajalogica:0
   }
 

   saveActividad(addRowValues);
   setNomActividad(null);
   setClaResponsable(null);
   setClaAutorizador(null);
   setFechaEstimadaInicio(null);
   setFechaEstimadaFin(null); 
   setclassNameButton('secondary');
   inputRef.current.focus();
  } 

  const saveActividad = async (actividad) => {
    let FechaInicio = actividad.FechaEstimadaInicio;
    let FechaFin = actividad.FechaEstimadaFin;
    if(actividad.FechaEstimadaInicio!= null){
      if(actividad.FechaEstimadaInicio.toDateString !== undefined){
        FechaInicio = actividad.FechaEstimadaInicio.toDateString();
      }
    }
    if(actividad.FechaEstimadaFin!= null){
      if(actividad.FechaEstimadaFin.toDateString !== undefined){
        FechaFin = actividad.FechaEstimadaFin.toDateString();
      }
    }
    const urlWebServiceSave = `${config.UrlApiProject}ScoreCard/SaveFaseProyectoActividad`;
    const paramsService = {
        ClaProyecto:actividad.ClaProyecto,
        ClaFase:actividad.ClaFase,
        ClaResponsable:actividad.ClaResponsable,
        ClaAutorizador:actividad.ClaAutorizador,
        ClaActividad:actividad.ClaActividad,
        NomActividad:actividad.NomActividad,
        ClaEstatus:actividad.ClaEstatus,
        FechaEstimadaInicio:FechaInicio,
        FechaEstimadaFin:FechaFin,
        FechaRealFin:actividad.FechaRealFin,
        Bajalogica:actividad.Bajalogica,
        Milestone:actividad.Milestone,
        IdProyectoFaseEntregable:actividad.IdProyectoFaseEntregable,
        EsVersionCorta: 1
    }; 
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => { 
      obtenerInformacionFasesProyecto();
      if (props.onChange !== null && props.onChange !== 'undefined' && typeof(props.onChange) === 'function'){
        props.onChange();
      }
    }, () =>{ 
      obtenerInformacionFasesProyecto();
   }, null, false, false);
  }

  const bajaActividad = async (actividad) => {
    const urlWebServiceSave = `${config.UrlApiProject}ScoreCard/SaveBajaFaseProyectoActividad`;
    const paramsService = {
        ClaProyecto:actividad.ClaProyecto,
        ClaFase:actividad.ClaFase,
        ClaActividad:actividad.ClaActividad,
        Bajalogica:actividad.Bajalogica,
    }; 

    tracker.trackEvent({
      type: 'Fases', /* component name */
      action: 'REMOVE_ACTIVITY'
    }) 

    callApi(urlWebServiceSave, 'POST', paramsService, (response) => { 
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      obtenerInformacionFasesProyecto();
      if (props.onChange !== null && props.onChange !== 'undefined' && typeof(props.onChange) === 'function'){
        props.onChange();
      }
    });
  }

  const bajaFase = async () => {
    const urlWebServiceSave = `${config.UrlApiProject}ScoreCard/SaveBajaFaseProyecto`;
    const paramsService = {
        ClaProyecto,
        ClaFase,
        Bajalogica:1,
    }; 

    tracker.trackEvent({
      type: 'Fases', /* component name */
      action: 'REMOVE_PHASE'
    }) 

    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {
      showSweetAlert('success', t('common:SuccessOperation'), 'success'); 
      obtenerInformacionFasesProyecto();
      if (props.onChange !== null && props.onChange !== 'undefined' && typeof(props.onChange) === 'function'){
        props.onChange();
      }
    }, null, false, false);
  }

  const onConfirmBaja = (actividad, esBajaActividad) => {
    swal({ 
      buttons: {
        cancelar: {
          text: t('common:Cancel'),
          value: "cancel",
          className: "rounded-button danger"
        },
        continuar: {
          text: t('common:Continue'),
          value: "continue",
          className: "rounded-button light-green"
        }
      },
      content: confirmationMessageHTML
    }).then((value) => {
      switch (value) { 
        case "continue":
          if(actividad==null){
            bajaFase()
          }else{
            actividad.Bajalogica=1;
            if(esBajaActividad){
              bajaActividad(actividad);
            }
          }
          break; 
        default:
          break;
      }
    });
  }

  const onCollapsed = ( isCollapsed) => {
    setEsColapsado(isCollapsed);

    if (!isCollapsed){
      obtenerInformacionFasesProyecto();
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      addRow();
    }
  }

  const refreshPhasesList = () => { 
    if (props.onChange !== null && props.onChange !== 'undefined' && typeof(props.onChange) === 'function'){
      props.onChange();
    } 
 }


const confirmationMessageHTML = (
  <div>
    <SweatAlertCustomIcon title={t('common:SubmitConfirmation')} letter={t('common:QuestionMark')}></SweatAlertCustomIcon> 
    <div>{t('hr:SubmitCancelled')}.</div> 
  </div>  
 ) 

 const headerCol = (
   <div style={{paddingLeft:'10px'}} className="overflow-ellipsis" title={FaseProyecto.NomFase}>
     {
       // FaseProyecto.PorcAvance
      }<b>{FaseProyecto.Milestone === 1 ? (<><i className="fas fa-star primary-color"></i>&nbsp;</>) : '' } {FaseProyecto.NomFase}</b>
   </div>  
 ) 
 
 const porcHeaderCol = (  
   <b>{FaseProyecto.PorcPeso }%</b> 
  ) 
 
  const headerStatusFase = (
    <>{t('hr:Status')} &nbsp; {FaseProyecto.EsAtrasada ? <i className="fas fa-exclamation-triangle warning-color bold"></i> : <></> }</>
  ) 

  const NomEstatusActividadColumn = (rowData) => {
    return (
      <>
        {rowData.NomEstatusActividad} &nbsp; {rowData.EsAtrasada ? <i className="fas fa-exclamation-triangle warning-color bold"></i> : <></> }
      </>
    );
  }

 const headerButtons = (
   <div>
     <FieldButton icon="pi pi-pencil" title={t('misActividades:EditPhase')} onClick={() => handleHeaderEditClick()} className="p-button-rounded p-button-info" /> 
      &nbsp;
     <FieldButton icon="pi pi-trash" title={t('misActividades:RemovePhase')} onClick={() => onConfirmBaja(null,true)} className="p-button-rounded p-button-danger" /> 
     &nbsp;
     <FieldButton icon="pi pi-angle-up" title={t('misActividades:ViewPhaseActivities')} onClick={() => onCollapsed(true)} className="p-button-rounded p-button-secondary" /> 
   </div>  
) 

const NomActividadColumn = (
  <div className="p-fluid">
    <FieldText
      name="NomActividad"
      value={NomActividad} 
      onChange={(e) => onChangeNomActividad(e.target.value)}
      onKeyDown={handleKeyDown}
      reference={inputRef}
      maxLength={250}
      autoFocus
      placeholder={t('common:New')}
      errors={errors}
    > 
    </FieldText>
  </div>  
) 
const ResponsableColumn = (
  <div className="p-fluid">
    <FieldDropdown  
      name="ClaResponsable"
      value={ClaResponsable}
      options={OpcionesResponsables}  
      optionValue="ClaColaborador"
      optionLabel="NomColaborador" 
      className="pt-3"
      onChange={(e) => onClaResponsableChange(props,e)}
      errors={errors}
    />  
  </div>  
) 
const AutorizadorColumn = (
  <div className="p-fluid">
    <FieldDropdown  
      name="ClaAutorizador"
      value={ClaAutorizador}
      options={OpcionesResponsables}  
      optionValue="ClaColaborador"
      optionLabel="NomColaborador" 
      onChange={(e) => onClaAutorizadorChange(props,e)}
      errors={errors}
    />  
  </div>  
) 

const FechaEstimadaInicioColumn = (
  <div className="p-fluid">
    <FieldCalendar
      name="FechaEstimadaInicio" 
      value={(FechaEstimadaInicio!== null ? (new Date(FechaEstimadaInicio)): null)}
      onChange={(e) => onFechaEstimadaInicioRowChange(e)}
      errors={errors}
    />  
  </div>  
) 

const FechaEstimadaFinColumn = (
  <div className="p-fluid">
    <FieldCalendar
      name="FechaEstimadaFin"
      value={(FechaEstimadaFin!== null ? (new Date(FechaEstimadaFin)): null)}
      onChange={(e) => onFechaEstimadaFinRowChange(e)}
      errors={errors}
    />   
  </div>  
) 

const addNewActivity = (rowData) => {
  // document.body.classList.add('bodynotscroll');
  setClaActividadSelected(0);
  setEsFaseActividad(true); 

  tracker.trackEvent({
    type: 'Fases', /* component name */
    action: 'ADD_NEW_ACTIVITY'
  }) 
};

const ButtonColumn = (
  <div> 
    <FieldButton icon="fas fa-check" title={t('misActividades:AddNewActivity')} onClick={() => addRow()} className="p-button-rounded p-button-success" /> 
    &nbsp;
    <FieldButton icon="pi pi-plus" title={t('misActividades:AddNewActivityDet')} onClick={() => addNewActivity()} className="p-button-rounded p-button-secondary" />  
  </div>  
) 

  const headerData  = ( 
    <>
      <Row>
        <Column header={headerCol} headerStyle={{ width: '200px' }}> 
        </Column>
        <Column header={t('hr:Responsible')} headerStyle={{ width: '130px' }}>
        </Column>
        <Column header={t('hr:StartDate')} headerStyle={{ width: '90px' }}>
        </Column>
        <Column header={t('hr:EndDate')} headerStyle={{ width: '90px' }}>
        </Column>
        <Column header={t('hr:Authorizer')} headerStyle={{ width: '130px' }}>
        </Column>
        <Column header={headerStatusFase} headerStyle={{ width: '100px' }}>
        </Column>
        <Column header={porcHeaderCol} headerStyle={{ width: '20px' }}>
        </Column>
        <Column header="" headerStyle={{ width: '20px' }}>
        </Column>
        <Column header={headerButtons} style={{textAlign:'right'}}> 
        </Column>
      </Row> 
    </>
  )

const footerData  = ( 
  <>
    <Row>
      <Column footer={NomActividadColumn}> 
      </Column>
      <Column footer={ResponsableColumn}> 
      </Column>
      <Column footer={FechaEstimadaInicioColumn}> 
      </Column>
      <Column footer={FechaEstimadaFinColumn}> 
      </Column>
      <Column footer={AutorizadorColumn}> 
      </Column>
      <Column> 
        &nbsp;
      </Column>
      <Column> 
        &nbsp;
      </Column>
      <Column> 
        &nbsp;
      </Column>
      <Column className="text-right" footer={ButtonColumn}> 
      </Column>
    </Row>
  </>
  )

  
  const NomEstatusFaseTemplate = (rowData) => {
    return (
      <>
        <Tag className={rowData.ClaEstatusFase === 1 ? "primary" :  rowData.ClaEstatusFase === 2 ? "light-green" : "dark"}>{rowData.NomEstatusFase}</Tag> 
        &nbsp; {rowData.EsAtrasada ? <i className="fas fa-exclamation-triangle warning-color bold"></i> : <></> }
      </>
    );
  }

  const PorcPesoTemplate = (rowData) => {
    return (
      <>
        {rowData.PorcPeso}%
      </>
    );
  }

  const PorcPesoActividadTemplate = (rowData) => {
    return (
      <>
        { rowData.PesoActividad !== null && typeof(rowData.PesoActividad) === 'number' ?  Highcharts.numberFormat(rowData.PesoActividad, 2, '.', ',') : '' }% 
      </>
    );
  }
  

  const FechaInicialTemplate = (rowData) => {
    return (
      <>
        { (rowData.FechaEstimadaInicio !== null ? moment(rowData.FechaEstimadaInicio).format("MM/DD/YYYY") : null)}
      </>
    );
  }

  const FechaFinTemplate = (rowData) => {
    return (
      <>
        {(rowData.FechaEstimadaInicio !== null ? moment(rowData.FechaEstimadaFin).format("MM/DD/YYYY") : null)}
      </>
    );
  }

  const FaseTemplate = (rowData) => {
    return (
      <>
        <div className="overflow-ellipsis" title={rowData.NomFase}> 
          { 
            // rowData.PorcAvance%
           } 
          <b>{rowData.Milestone === 1 ? (<><i className="fas fa-star primary-color"></i>&nbsp;</>) : '' }{rowData.NomFase} </b>
        </div>
      </>
    );
  }

  const DescriptionTemplate = (rowData) => {
    return (
      <> 
        { 
          rowData.Descripcion === null || rowData.Descripcion === '' ? (<></>) :
          (
            <>
              <Tooltip target="#DescriptionNote" />
              <i 
                id="DescriptionNote"  
                data-pr-tooltip={rowData.Descripcion}
                className="fas fa-sticky-note light-yellow-color"
              > 
              </i>
            </>
          )
         }
         
      </>
    );
  } 
        
  if(EsColapsado){
    return (
      <>     
        <FieldDataTable 
          value={FaseProyectos} 
          scrollable 
          scrollHeight="270px" 
          className="datatable-custom"
          paginator={false}
        >  
          <Column className={FaseProyecto.ClaEstatusFase === 1 ? "FaseNoIniciada" : FaseProyecto.ClaEstatusFase === 2 ? "FaseTerminada" : FaseProyecto.ClaEstatusFase === 3 ? "FaseEnProgreso" : "FaseDefault"} field="Fase" columnKey="Fase" body={FaseTemplate} headerStyle={{ width: '200px' }}></Column>  
          <Column field="NomResponsable" columnKey="ClaResponsable" headerStyle={{ width: '130px' }}></Column> 
          <Column field="FechaEstimadaInicio" body={FechaInicialTemplate} headerStyle={{ width: '90px' }}></Column>
          <Column field="FechaEstimadaFin" body={FechaFinTemplate} headerStyle={{ width: '90px' }}></Column> 
          <Column field="NomAutorizador" columnKey="NomAutorizador" headerStyle={{ width: '130px' }}></Column> 
          <Column field="NomEstatusFase" body={NomEstatusFaseTemplate} columnKey="NomEstatusFase" headerStyle={{ width: '100px' }}></Column>
          <Column field="PorcPeso" body={PorcPesoTemplate} columnKey="PorcPeso" headerStyle={{ width: '20px' }}></Column>
          <Column className="colHeaderRight" body={headerActionsHeader} style={{textAlign:'right'}} headerStyle={{ width: '120px' }}></Column>
        </FieldDataTable> 
        {
        EsFaseDetalle !== null ? (
          <> 
            <FasesDetalle IsVisible={EsFaseDetalle} onClickClose={onChangeEsFaseDetalle} onChange={refreshPhasesList} ClaProyecto={ClaProyecto} ClaFase={ClaFase} />
          </>
          ):(<></>)
       }        
      </> 
        ); 
  }
     

    return (
      <>     
        <FieldDataTable 
          value={FaseProyectoActividades} 
          headerColumnGroup={headerData}  
          footerColumnGroup={footerData}  
          scrollable 
          scrollHeight="370px" 
          className="datatable-score"
          onRowEditInit={onRowEditInit}
          editMode="cell" 
          selectionMode="single"   
        >  
          <Column field="NomActividad" columnKey="NomActividad" headerStyle={{ width: '200px' }} editorValidator={emptyValueValidator} editor={(props) => NomActividadEditor(props)}></Column>
          <Column field="NomResponsable" columnKey="ClaResponsable" headerStyle={{ width: '130px' }} editor={(props) => ClaResponsableEditor(props)}></Column> 
          <Column field="FechaEstimadaInicio" columnKey="FechaEstimadaInicio" headerStyle={{ width: '90px' }} body={FechaInicialTemplate} editor={(props) => FechaRealInicioEditor(props)}></Column>
          <Column field="FechaEstimadaFin" columnKey="FechaEstimadaFin" headerStyle={{ width: '90px' }} body={FechaFinTemplate} editor={(props) => FechaRealFinEditor(props)}></Column> 
          <Column field="NomAutorizador" columnKey="NomAutorizador" headerStyle={{ width: '130px' }} editor={(props) => ClaAutorizadorEditor(props)}></Column> 
          <Column field="NomEstatusActividad" columnKey="NomEstatusActividad" body={NomEstatusActividadColumn} headerStyle={{ width: '100px' }}></Column> 
          <Column field="PesoActividad" body={PorcPesoActividadTemplate} columnKey="PesoActividad" headerStyle={{ width: '40px' }}></Column> 
          <Column field="Descripcion" body={DescriptionTemplate} columnKey="Descripcion" headerStyle={{ width: '30px' }}></Column> 
          <Column body={headerActions} style={{textAlign:'right'}} headerStyle={{ width: '120px' }}></Column>
        </FieldDataTable> 
        {
        EsFaseActividad !== null ? (
          <> 
            <FasesActividades IsVisible={EsFaseActividad} onClickClose={onChangeEsFaseActividad} ClaProyecto={ClaProyecto} ClaFase={ClaFase} ClaActividad={ClaActividadSelected}  />
          </>
          ):(<></>)
       }
        {
        EsFaseDetalle !== null ? (
          <> 
            <FasesDetalle IsVisible={EsFaseDetalle} onClickClose={onChangeEsFaseDetalle} onChange={refreshPhasesList} ClaProyecto={ClaProyecto} ClaFase={ClaFase} />
          </>
          ):(<></>)
       }
        {
        EsMoverActividad !== null ? (
          <> 
            <MoverActividadNuevaFase IsVisible={EsMoverActividad} onClickClose={onChangeEsMoverActividad} onChange={refreshPhasesList} ClaProyecto={ClaProyecto} ClaFase={ClaFase} ClaActividad={ClaActividadSelected} />
          </>
          ):(<></>)
       }
      </> 
        ); 
};
export default Fases;

/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef } from "react"; 
import PropTypes from "prop-types"; 
import { Tree } from 'primereact/tree';
import { TreeSelect } from 'primereact/treeselect';
import { OverlayPanel } from 'primereact/overlaypanel';
import onClickOutside from "react-onclickoutside"; 
import FieldText from "../FieldText.jsx";
import "./TreeviewButton.css";

const TreeviewButton = ({
    nodes,
    selectedKey, 
    optionLabel,
    onChange,
    icon,
    nodeTemplate,
    expandedKeys,
    showAsTreeSelect, 
    iconPosition,
    showPositionName,
    onToggle,
    errors,
    name
}) => {
     
    const [isOpen, setIsOpen] = useState(false);
    const [selectedText, setSelectedText] = useState(null); 
    const [selectedNodeKey2, setSelectedNodeKey2] = useState(null);

    TreeviewButton.handleClickOutside = () => setIsOpen(false);
      
    const onNodeSelect = (node) => {
        setSelectedText(node.node[optionLabel]); 
        setIsOpen(false);
    }

    const onToggleChange = (e) => {
        if (typeof(onToggle) === 'function'){
           onToggle(e);
        }
    }

    const onNodeUnselect = (node) => { 
        setSelectedText(null);
        setIsOpen(false);
    }

    const getSelectedNodes = () => {
        const selectedNodes = [];
        if (selectedKey && nodes) {
            const keys =  {[`${selectedKey}`]: true};
            findSelectedNodes(null, keys, selectedNodes);
        }

        return selectedNodes;
    }

    const findSelectedNodes = (node, keys, selectedNodes) => {
        if (node) {
            if (isSelected(node, keys)) {
                selectedNodes.push(node);
                delete keys[node.key];
            }

            if (Object.keys(keys).length && node.children) {
                for (const childNode of node.children) {
                    findSelectedNodes(childNode, keys, selectedNodes);
                }
            }
        }
        else {
            for (const childNode of nodes) {
                findSelectedNodes(childNode, keys, selectedNodes);
            }
        }
    }

    const isSelected = (node, keys) => {
        return  keys[node.key];
    } 

    const nodeTemplateDefault = (node, options) => {
        
       if (nodeTemplate !== null && typeof(nodeTemplate) === 'function'){
          return nodeTemplate(node, options);
       }

       return (
         <span className={options.className}>
           {node.label}
         </span>
        )
    }

    const isFormFieldInValid = (name) => !!(typeof(errors) !== 'undefined' && errors !== null && errors[name]);
    
    const getFormErrorMessage = (name) => { 
        return isFormFieldInValid(name) && <small className="p-error">{errors[name].message}</small>;
    };
  

  return (
    <> 
      {
      showAsTreeSelect?(
        <>
          <TreeSelect
            value={selectedKey} 
            options={nodes}
            onChange={e =>  onChange(e.value)} 
            filter
            placeholder="Select Items" 
            resetFilterOnHide
            className={isFormFieldInValid(name) === true ? 'p-invalid' : ''}
          >
          </TreeSelect>
          { getFormErrorMessage(name) } 
        </>
      ):
      
      typeof(iconPosition) === 'undefined' || iconPosition === 'left' ?
      (
        <>
          <div className="treeViewWrapper">
            <div className="treevew-button" onClick={(e) => setIsOpen(!isOpen)}>
              <i className={icon === undefined ? "fas fa-bars" : icon} style={{fontSize: '1.3rem'}}></i>&nbsp;&nbsp;
              { getSelectedNodes().length > 0 ? getSelectedNodes()[0][optionLabel] : null }
            </div>
        
            { isOpen ? (
              <div className="treeButtonPanel">
                <Tree value={nodes} selectionMode="single" expandedKeys={expandedKeys} nodeTemplate={nodeTemplateDefault} filter selectionKeys={selectedKey} onSelectionChange={e =>  onChange(e.value)} onSelect={onNodeSelect} onUnselect={onNodeUnselect} onToggle={e => onToggleChange(e)} />
              </div>
        ) : <></>}
          </div>
        </>
      )
      : 
      (
        <>
          <div className="treeViewWrapper">
            <div className="treeview-container treeview-button" onClick={(e) => setIsOpen(!isOpen)}>
              <div className="treeview-item-left overflow-ellipsis">
                <span>{ getSelectedNodes().length > 0 ?  getSelectedNodes()[0][optionLabel] : null }</span>
                { showPositionName ? 
                  (<><br></br><span style={{fontSize: '11px', fontWeight: 'normal'}}>{ getSelectedNodes().length > 0 ? getSelectedNodes()[0].positionName : null}</span></>) :
                  (<></>)}
              </div>
              <div className="treeview-item-right">
                <i className={icon === undefined ? "fas fa-bars" : icon} style={{fontSize: '1.3rem'}}></i>&nbsp;&nbsp;
             
              </div>
            </div>
            
        
            { isOpen ? (
              <div className="treeButtonPanel">
                <Tree value={nodes} selectionMode="single" expandedKeys={expandedKeys} nodeTemplate={nodeTemplateDefault} filter selectionKeys={selectedKey} onSelectionChange={e =>  onChange(e.value)} onSelect={onNodeSelect} onUnselect={onNodeUnselect} onToggle={e => onToggleChange(e)} />
              </div>
        ) : <></>}
          </div>
        </>
      )
    }
    </>
  );
};

const clickOutsideConfig = {
    handleClickOutside: () => TreeviewButton.handleClickOutside
  };
 
export default onClickOutside(TreeviewButton, clickOutsideConfig);

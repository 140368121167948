/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { config } from '../../../utils/config';
import { callApi } from '../../../utils/utils'; 
import { 
  Row,
  Col  
} from 'reactstrap';  
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Dialog } from 'primereact/dialog'; 
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import FieldDropdown from '../../../components/Controls/FieldDropdown.jsx'
import FieldCalendar from '../../../components/Controls/FieldCalendar.jsx'
import FieldAutoComplete from '../../../components/Controls/FieldAutoComplete.jsx'
import FieldText from '../../../components/Controls/FieldText.jsx'
import FieldTextArea from '../../../components/Controls/FieldTextArea.jsx'
import { confirmDialog } from 'primereact/confirmdialog'; 
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';

// START COMPONENT
const Oportunities = (props) => { 
  const { t } = useTranslation(['formulario','common']); 
  const [rowsGrid, setRowsGrid] = useState([]); 
  const [TipoOportunidades, setTipoOportunidades] = useState([]);  
  const [ClaTipoOportunidad, setClaTipoOportunidad] = useState(null);
  const [openNewProduct, setOpenNewProduct] = useState(false); 
  const [IdRdsOportunidad, setIdRdsOportunidad] = useState(null);
  const [DueDate, setDueDate] = useState(null);
  const [CompletedDate, setCompletedDate] = useState(null);
  const [filteredResponsible, setfilteredResponsible] = useState([]);
  const [ClaUsuarioResponsableOportunidad, setClaUsuarioResponsableOportunidad] = useState(null);
  const [TituloOportunidad, setTituloOportunidad] = useState(null); 
  const [Comentarios, setComentarios] = useState(null);  
  const [errors, setErrors] = useState({});  
  const [NomGrupoEstadistico3, setNomGrupoEstadistico3] = useState(null);  
  const [NomFecha, setNomFecha] = useState(null);  
  const urlWebServiceOportunitiesType = `${config.UrlApiProject}Rentabilidad/GetTiposOportunidades`;
  const urlWebServiceSaveOportunidad = `${config.UrlApiProject}Rentabilidad/PostOportunidad`;
  // Declare useEffect 
  useEffect(() => {
    const paramsToService = {
      anioMes:props.filters.AnioMes, 
      claGerenciaRegional:props.filters.ClaGerenciaRegional,
      claGrupoEstadistico3: props.filters.ClaGrupoEstadistico3,
      claclientecuenta: props.filters.ClaClienteCuenta,
      claBucket: null,
      esMostrarSoloConfiables: props.filters.EsMostrarSoloConfiables,
      claMarketSegment: props.filters.ClaMarketSegment
    };
    const GetFollowUpTitle = `${config.UrlApiProject}Rentabilidad/GetBucketFollowUpTitle`; 
    callApi(GetFollowUpTitle, 'POST', paramsToService, (response) => {   
        setNomGrupoEstadistico3(response.data.NomGrupoEstadistico3);
        setNomFecha(response.data.NomFecha);   
        const paramsServiceOporType = null;
        // Get owner type
        callApi(urlWebServiceOportunitiesType, 'GET', paramsServiceOporType, (response) => {
          setTipoOportunidades(response.data.TiposOportunidades);
          // Get grid data
          onGridOpportunity(); 
        });  
    });
  }, [props.filters]);
  
  const onGridOpportunity = () => {   
    let paramsService = null; 
    let urlWebService = ``;  
    paramsService = {
                    anioMes:props.filters.AnioMes, 
                    claGerenciaRegional:props.filters.ClaGerenciaRegional,
                    claGrupoEstadistico3: props.filters.ClaGrupoEstadistico3};
    urlWebService = `${config.UrlApiProject}Rentabilidad/GetHistoriaOportunidadesGrid`;  
    callApi(urlWebService, 'POST', paramsService, (response) => { 
      setRowsGrid(response.data[0]); 
    });    
  };

  const leftToolbarTemplate = () => {
    return (
      <>
        <FieldButton label={t('common:New')} icon="pi pi-plus" className="p-button-success p-mr-2" onClick={newOpportunity} /> 
      </>
      )
  }
  const modalFooter = () => {
    return (
      <>
        <FieldButton label={t('common:Cancel')} icon="pi pi-times" className="p-button-text" onClick={closeModal}    />
        <FieldButton label={t('common:Save')} icon="pi pi-check" className="p-button-text" onClick={saveOpportunity} />
      </>
  );
  } 
  const rightToolbarTemplate = () => {
      return (
        <></>
      )
  } 
  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <FieldButton onClick={(e) => onDetailOpportunity(rowData)} icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" />
        <FieldButton onClick={(e) => confirm(rowData)} icon="pi pi-trash" className="p-button-rounded p-button-warning" />
      </>
    );
  }
   
  const DueDateTemplate = (rowData) => {
    return (
      <>
        { rowData.DueDate !== null ? moment(rowData.DueDate).format("MM/DD/YYYY") : rowData.DueDate } 
        &nbsp;
        <i className="pi pi-exclamation-triangle red" hidden={rowData.AlertDueDate<=0}></i>
      </>
    );
  }

  const CompletedDateTemplate = (rowData) => {
    return (
      <>
        { rowData.CompletedDate !== null ? moment(rowData.CompletedDate).format("MM/DD/YYYY") : rowData.CompletedDate}
      </>
    );
  }
 
  const header  = ( 
    <>
      {t('formulario:TitleGridOpportunity')}  <i>{NomGrupoEstadistico3}</i>
    </>
  )

  const newOpportunity = () => { 
    resetModal();
    setOpenNewProduct(true);
  } 

  const onDetailOpportunity = (row) =>{ 
    resetModal();
    const urlWebService = `${config.UrlApiProject}Rentabilidad/GetOportunidadPorClave`;
    const paramsToService = {
      IdRdsOportunidad: row.IdRdsOportunidad
    };
    callApi(urlWebService, 'POST', paramsToService, (response) => { 
      if (response.data.Oportunidad.length >0 ){  
        const AutoCompleteData = {
          ClaUsuario: response.data.Oportunidad[0].ClaUsuario,
          ClaEmpleado: response.data.Oportunidad[0].ClaEmpleado,
          NomUsuario: response.data.Oportunidad[0].NomUsuario,
        };
        setIdRdsOportunidad(response.data.Oportunidad[0].IdRdsOportunidad);
        setClaTipoOportunidad(response.data.Oportunidad[0].ClaTipoOportunidad); 
        setClaUsuarioResponsableOportunidad(AutoCompleteData);
        setDueDate(new Date(response.data.Oportunidad[0].DueDate)); 
        setTituloOportunidad(response.data.Oportunidad[0].TituloOportunidad);
        setComentarios(response.data.Oportunidad[0].Comentarios);
        if(response.data.Oportunidad[0].CompletedDate !== null){
          setCompletedDate(new Date(response.data.Oportunidad[0].CompletedDate)); 
        } 
        setOpenNewProduct(true);
      }
    });
  }

  const resetModal = () => {
    setErrors({}); 
    setIdRdsOportunidad(null);
    setClaTipoOportunidad(null); 
    setDueDate(null); 
    setClaUsuarioResponsableOportunidad(null);
    setTituloOportunidad(null);
    setComentarios(null);
    setCompletedDate(null); 
  }

  const closeModal = () => {
    setOpenNewProduct(false)
  }  

  const confirm = (rowData) => {
    confirmDialog({
        message: t('formulario:MessageConfirm'),
        header: t('common:Confirmation'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => acceptBaja(rowData)
    });
  }

  const acceptBaja = (row) =>{   
    const urlWebService = `${config.UrlApiProject}Rentabilidad/OportunidadBaja`;
    const paramsToService = {
      IdRdsOportunidad: row.IdRdsOportunidad
    }; 
    callApi(urlWebService, 'POST', paramsToService, (response) => {  
      onGridOpportunity(); 
    });
  } 

  const onChangeTipoOportunidad = (value) => {  
    const newErrors = { ...errors } 
    delete newErrors.ClaTipoOportunidad;   
    setErrors(newErrors)
    setClaTipoOportunidad(value);
  }  

  const onChangeClaUsuarioResponsable = (e) => {  
    const newErrors = { ...errors }
    delete newErrors.ClaUsuarioResponsableOportunidad;   
    setErrors(newErrors)
    setClaUsuarioResponsableOportunidad(e.value)
  }

  const onChangeTituloOportunidad = (e) => {  
    const newErrors = { ...errors }
    delete newErrors.TituloOportunidad;   
    setErrors(newErrors)
    setTituloOportunidad(e.target.value)
  }

  const onChangeComentarios = (e) => {  
    const newErrors = { ...errors }
    delete newErrors.Comentarios;   
    setErrors(newErrors)
    setComentarios(e.target.value)
  }

  const onChangeDueDate = (e) => {  
    const newErrors = { ...errors }
    delete newErrors.DueDate;   
    setErrors(newErrors)
    setDueDate(e.target.value)
  } 

  const onChangeCompletedDate = (e) => {  
    const newErrors = { ...errors }
    delete newErrors.CompletedDate;   
    setErrors(newErrors)
    setCompletedDate(e.target.value)
  } 
  const filterResponsible = (event) => {
     let valor = ''; 
     if (ClaUsuarioResponsableOportunidad !== null && ClaUsuarioResponsableOportunidad !== '' &&  event.query === "")
     {
      valor = ClaUsuarioResponsableOportunidad.NomUsuario;
     }
     else{
      valor = event.query;
     }
     const paramsServiceOwners = {};
     // event.query 
     const urlWebServiceOwners = `${config.UrlApiProject}Rentabilidad/GetUsuarioResponsableOportunidad?descriptionFilter=${valor}`;
     // Get owner
     callApi(urlWebServiceOwners, 'GET', paramsServiceOwners, (response) => {  
        setfilteredResponsible(response.data.DueniosOportunidades);
     });    
     
  } 

  const valitationScheme = yup.object().shape({
    ClaTipoOportunidad: yup.mixed().required(t('common:RequiredField')).nullable(),
    DueDate: yup.date().required(t('common:RequiredField')).nullable(),
    ClaUsuarioResponsableOportunidad: yup.object({ ClaUsuario: yup.string().required(t('common:RequiredField')) }).required(t('common:RequiredField')).nullable(),
    TituloOportunidad: yup.string().required(t('common:RequiredField')).nullable(),
    Comentarios: yup.string().required(t('common:RequiredField')).nullable(),
  });
   
  const saveOpportunity = async () => { 
    // ejecutamos validaciones....
    const value = {
      ClaTipoOportunidad,
      DueDate,
      ClaUsuarioResponsableOportunidad,
      TituloOportunidad,
      Comentarios 
    }
    const validator = new YupValidator(valitationScheme);
    const esValido = await validator.validate(value);
 

    if (!esValido){ 
      console.log(validator.errors);
      setErrors(validator.errors); 
      return;
    }
    const paramsServiceOwners = {
      AnioMes: props.filters.AnioMes,
      ClaGrupoEstadistico3: props.filters.ClaGrupoEstadistico3,
      ClaGerenciaRegional: props.filters.ClaGerenciaRegional,
      Claclientecuenta: props.filters.ClaClienteCuenta,
      ClaTipoOportunidad,
      DueDate,
      ClaUsuarioResponsableOportunidad:ClaUsuarioResponsableOportunidad.ClaUsuario,
      TituloOportunidad,
      Comentarios,
      IdRdsOportunidad,
      CompletedDate
    };
    callApi(urlWebServiceSaveOportunidad, 'POST', paramsServiceOwners, (response) => {  
      onGridOpportunity();
      closeModal();
   });    
  }  

  const isFormFieldValid = (name) => !!(errors[name]); 
  const getFormErrorMessage = (name) => { 
      return isFormFieldValid(name) && <small className="p-error">{errors[name].message}</small>;
  };
 
  if (props.showHide) {
    return (
      <> 
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>  
            <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
            <FieldDataTable 
              value={rowsGrid}  
              header={header}
              scrollable 
              scrollHeight="330px" 
              className="p-datatable-striped"  
              selectionMode="single"  
              metaKeySelection={false}
            >  
              <Column field="TituloOportunidad" header={t('formulario:Title')} headerStyle={{ width: '150px' }} columnKey="TituloOportunidad"></Column> 
              <Column field="NomTipoOportunidad" header={t('formulario:Type')} headerStyle={{ width: '150px' }} columnKey="NomTipoOportunidad"></Column> 
              <Column field="NomUsuarioResponsableOportunidad" header={t('formulario:Responsible')} headerStyle={{ width: '150px' }} columnKey="NomDuenioOportunidad"></Column>  
              <Column field="DueDate" body={DueDateTemplate} header={t('formulario:DueDate')} headerStyle={{ width: '100px' }} columnKey="DueDate"></Column>  
              <Column field="CompletedDate" body={CompletedDateTemplate} header={t('formulario:CompletedDate')} headerStyle={{ width: '100px' }} columnKey="CompletedDate"></Column>  
              <Column field="Comentarios" header={t('formulario:Comments')} headerStyle={{ width: '200px' }} columnKey="Comentarios"></Column>   
              <Column body={actionBodyTemplate} headerStyle={{ width: '70px' }}></Column>
            </FieldDataTable>   
          </Col>
          <Dialog visible={openNewProduct} style={{ width: '450px' }} header="Opportunity" footer={modalFooter} modal onHide={closeModal}>
           
            <div className="p-field  p-col-12"> 
              <div className='p-fluid'>
                <FieldDropdown  
                  name="ClaTipoOportunidad"
                  label={t('formulario:Type')}
                  value={ClaTipoOportunidad} 
                  options={TipoOportunidades}  
                  optionValue="ClaTipoOportunidad"
                  optionLabel="NomTipoOportunidad" 
                  onChange={(e) => onChangeTipoOportunidad(e.value)}
                  errors={errors}
                />   
              </div>
            </div>
            <div className="p-field p-col-12">  
              <div className='p-fluid'>
                <FieldCalendar
                  name="DueDate"
                  label={t('formulario:DueDate')}
                  value={DueDate}
                  onChange={onChangeDueDate} 
                  errors={errors}
                />   
              </div>
            </div> 
            <div className="p-field p-col-12" hidden={IdRdsOportunidad === null}>  
              <div className='p-fluid'>
                <FieldCalendar
                  name="CompletedDate"
                  label={t('formulario:CompletedDate')}
                  value={CompletedDate}
                  onChange={onChangeCompletedDate} 
                  errors={errors}
                />   
              </div>
            </div>
            <div className="p-field  p-col-12"> 
              <div className='p-fluid'>
                <FieldAutoComplete
                  dropdown
                  name="ClaUsuarioResponsableOportunidad"
                  label={t('formulario:Responsible')}
                  value={ClaUsuarioResponsableOportunidad} 
                  onChange={onChangeClaUsuarioResponsable}  
                  suggestions={filteredResponsible}
                  field="NomUsuario"
                  completeMethod={filterResponsible}
                  errors={errors}
                /> 
              </div>
            </div>
            <div className="p-field  p-col-12"> 
              <div className='p-fluid'>
                <FieldText  
                  name="TituloOportunidad"
                  label={t('formulario:Title')}
                  value={TituloOportunidad} 
                  onChange={onChangeTituloOportunidad}
                  errors={errors}
                /> 
              </div>
            </div>
            <div className="p-field  p-col-12"> 
              <div className='p-fluid'> 
                <FieldTextArea 
                  name="Comentarios"
                  label={t('formulario:Comments')}
                  rows={5} 
                  value={Comentarios} 
                  onChange={onChangeComentarios} 
                  errors={errors}
                /> 
              </div>
            </div> 
          </Dialog>
        </Row>        
      </>
    );
  }
  if (!props.showHide) {
    return <></>;
  }
};
export default Oportunities;

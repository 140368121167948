import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import { config } from 'utils/config';
import { callApi } from 'utils/utils';
import MonthlyProductionSummaryChartSkeleton from './MonthlyProductionSummaryChartSkeleton';
import moment from 'moment'
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import { Dialog } from 'primereact/dialog';

const MonthlyProductionSummaryChart = (props) => {
  const {t, i18n} = useTranslation(['wtw', 'common']);  
  const [Loading, setLoading] = useState(true);
  const [ChartCategories, setChartCategories] = useState([]);
  const [ChartSeries, setChartSeries] = useState([]);
  const [ChartStep, setChartStep] = useState(0);  
  const [isGanttFullScreenMode, setisGanttFullScreenMode] = useState(false);
   
  const getChart = () => {    
    setupChartBars(props.data,7);
    setLoading(false);
  };
  
  const toggleFullScreen = () => {
    setisGanttFullScreenMode(!isGanttFullScreenMode); 
  };

  useEffect(() => {      
    setupChartBars(props.data,7);
  }, [isGanttFullScreenMode]);

  const chartBarsOptions = {
    chart: {
      type: 'column',
      height: isGanttFullScreenMode ? 550 : 250,
      events: {
        load() {
            const chart = this; 
           
            // X
            const xAxis = chart.xAxis[0];
            const xExtremes = xAxis.getExtremes();
            const minXValue = xExtremes.dataMin;  
            const maxXValue = xExtremes.dataMax;  
             
             let xTickInterval;
             if (maxXValue < 10) {
                 xTickInterval =  1; // Si el valor máximo es menor a 10, es 1
             } else if (maxXValue >= 10 && maxXValue < 20) {
                 xTickInterval = 3; // Si el valor máximo es mayor a 10 pero menor a 20, = 2
             } else if (maxXValue > 20) {
                 xTickInterval = 3; // Si el valor máximo es mayor a 20, = 4
             } 

             xTickInterval = null; // lo dejamos null de momento por que agregamos 3 seres que querems que siempre se vea el nombre y hasta que no podamos hacer dinamico esolo dejamos asi..
             
             xAxis.update({
                 min: minXValue,
                 max: maxXValue,
                 tickInterval: xTickInterval 
             });
            
             // Y
             const yAxis = chart.yAxis[0];
             const yExtremes = yAxis.getExtremes();
             const minYValue = yExtremes.dataMin;  
             const maxYValue = yExtremes.dataMax;  
       
            const rangeYValue = maxYValue - minYValue;  
          
            let yTickInterval = 0; 
            yTickInterval = Math.ceil(rangeYValue / 4);   
  
            yAxis.update({
              min: minYValue,
              max: maxYValue,
              tickInterval: yTickInterval 
          });
 
        }
    }
    },
    title: {
      text: props.nomIndicador,
    },
    subtitle: {
      text: props.title,
    },
    plotOptions: {
      series: {
        lineWidth: 1
      }
    },
    xAxis: {
      categories: ChartCategories,
    
    },
    yAxis: {
      title: {
        text: ''
      },
      tickInterval: null,
      dataLabels: {
        enabled: true,  // Show data labels
        // format: '{y}'  // Display the value of the data point
      }
      /* ,
      labels: {
        formatter: (item) => {
          return `${formatValue(item.value, props.tipoIndicador)}`;
        }
      } */
    },
    tooltip: {
      formatter() {
        const { series, point } = this;
        const value = series.type === 'column' ? point.y : point.y;
        const date = point.category; // moment(point.category).format('MMM D');
        return `${date} - ${series.name}: ${formatValue(value, props.tipoIndicador)}`;
      }
    },
    legend: {
      enabled: true,
      align: 'center',
      verticalAlign: 'bottom',
      layout: 'horizontal'
    },
    series: ChartSeries,
  };
  
  const setupChartBars = (report, step) => {
    let categories = [];
    let seriePrincipal   = [];
    let promedioDiario   = [];
    const series = [];

    let firstElement = { AnioPrevioPromedio1: null, AnioPrevioPromedio2: null, AnioPrevioPromedio3: null, Date: null, PromedioAnio: null };
    
    if (report) {      
      firstElement = report.lenght > 0 ? { AnioPrevioPromedio1: null, AnioPrevioPromedio2: null, AnioPrevioPromedio3: null, Date : null, PromedioAnio: null } : report[0];

      const nameMapping = {
        '1': { name: t('common:Jan') },
        '2': { name: t('common:Feb') },
        '3': { name: t('common:Mar') },
        '4': { name: t('common:Apr') }, 
        '5': { name: t('common:May') },  
        '6': { name: t('common:Jun') },  
        '7': { name: t('common:Jul') },  
        '8': { name: t('common:Aug') },  
        '9': { name: t('common:Sep') }, 
        '10': { name: t('common:Oct')},  
        '11': { name: t('common:Nov') },  
        '12': { name: t('common:Dec') }    
      };
      
      /*
      categories = report.map(({ Date }) => {
        const monthNumber = moment(Date).format('D');  
        return monthNumber;  
      });

      categories = categories.map(category => category.name);
  */

      categories   = report.map(({ Date }) => moment(Date).format('D'));     
       
 
      const firstDate = new Date(firstElement.Date); // Current date 

      const PrevYear1 = new Date(firstDate.setFullYear(firstDate.getFullYear() - 1));
      const PrevYear2 = new Date(firstDate.setFullYear(firstDate.getFullYear() - 1));
      const PrevYear3 = new Date(firstDate.setFullYear(firstDate.getFullYear() - 1));
      
      
      categories =  [ moment(PrevYear3).format('YYYY'), 
                      moment(PrevYear2).format('YYYY'), 
                      moment(PrevYear1).format('YYYY'), 
                      t('wtw:PromedioAnio'), ...categories];
      
      seriePrincipal = report.map(({ Valor }) => Valor);

      seriePrincipal = [{
        y: firstElement.AnioPrevioPromedio3,  // value of the bar
        color: '#8898aa'  // red color for this specific bar
    } , 
    {
        y: firstElement.AnioPrevioPromedio2,  // value of the bar
        color: '#8898aa'  // red color for this specific bar
    } ,
    {
        y: firstElement.AnioPrevioPromedio1,  // value of the bar
        color: '#8898aa'  // red color for this specific bar
    }  ,
    {
        y: firstElement.PromedioAnio,  // value of the bar
        color: '#0d3c61'  // red color for this specific bar
    } , ...seriePrincipal];
      
      console.log('categories order',categories)
      console.log('seriePrincipal',seriePrincipal)

      
    const categoriesRenombradas = categories.map((category) => {
      if (nameMapping[category]) {
          return nameMapping[category].name; // Si hay un mapeo para el nombre actual, lo cambia
      }
      return category; // Si no, mantiene el nombre original
    });

    categories = categoriesRenombradas 
    

    promedioDiario = report.map(({ PromedioAnio }) => PromedioAnio);

    promedioDiario = [null, null, null, ...promedioDiario];


      /* a las categorias agregamos AnioPrevio1, 2 y 3 */

      series.push({
        type: 'column', // Serie de barra
        name: props.nomIndicador,
        data: seriePrincipal,
        color: '#2CAFFE',
        dataLabels: {
            enabled: false,  // no show data labels, they need to use the png image..
            formatter () { 
              // when screen mode is not in full mode we not need to display the lables of this serie.
              if (!isGanttFullScreenMode){
                 return null;
              }

              if (this.y > 0) {
                  return  formatValue(this.y, props.tipoIndicador)   ;  // Show data label if value > 3
              } 
             
              return null;  // Do not display label if value <= 0
              
          }
        }
      });

      /*
      series.push({
        type: 'line', // Serie de Linea
        name: 'Daily Avg',
        data: promedioDiario,
        dashStyle: 'Dash',
        color: '#000',
        marker: {
          enabled: false, 
        }
      });
      */
      
      console.log('series', series);
    }
  
    setChartCategories(categories);
    setChartSeries(series);  
    setChartStep(step);  
  };

 
  useEffect(() => {      
    getChart();
  }, [
    props.refresh
  ]);

  function formatValue(value, tipoValorIndicador) {
   
    let formattedResult;
    switch (tipoValorIndicador) {
      case 1:
        formattedResult = value.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
        break;
      case 2:
        formattedResult = value.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
        break;
      case 3:
        formattedResult = `${(value).toFixed(1)}%`;
        break;
      default:
        formattedResult = value;
    }
    return formattedResult;
  }

  const onDetail = () => {
    if (props.onViewDetail !=  null){ 
       props.onViewDetail(props.nomIndicador);
     }
  };

  const chartTemplate = (
    <>
      <HighchartsReact
        highcharts={Highcharts} 
        options={chartBarsOptions} 
      />    
    </>
  );

  const screenRender = () => {
    return (
      <>
        {
          Loading? <MonthlyProductionSummaryChartSkeleton /> : (
            <div>
              <Row>
                <Col>
                  <div onClick={props.onViewDetail ? onDetail : undefined} hidden={props.onViewDetail===undefined}>
                    <span className='link-text primary-color'> 
                      View detail by asset / cell 
                    </span>
                  </div>                   
                </Col>
                <Col className='text-right'>
                  <FieldButton
                    type="button" 
                    onClick={toggleFullScreen}
                    icon={isGanttFullScreenMode?"fas fa-compress":"fas fa-expand"}
                    className="p-button-secondary no-border p-mr-2 p-button-rounded p-button-text devextreme-gantt-toolbar-button"
                    style={{ marginRight: '.25em' }}
                    title={isGanttFullScreenMode?t('wtw:TurnScreenMode'):t('wtw:FullScreenMode')}
                  />                     
                </Col>
              </Row>  
              <Row className='align-items-start' style={isGanttFullScreenMode? {height:'100%'}: null}>
                <Col xs={12} style={isGanttFullScreenMode? {height:'100%', padding: '0px'}: null}>
                  {chartTemplate}                     
                </Col>
              </Row> 
            </div>
          )
        } 
      </>
    )
  }

  if (isGanttFullScreenMode){ 
    return (
      <>
        <Dialog
          visible={isGanttFullScreenMode}
          modal 
          header="Full Screen Mode View"
          className="DailyOperationAssetDetailTree dialog-header-background-blue"
          closable={false} 
          maximized={true}
          draggable={false}
          position="right" 
        >
          <div className='pt-3'>
            {screenRender()}
          </div>
        </Dialog>
      </> 
    )
  }
  return (
    <>
      <Card style={{borderRadius: '1%'}}>  
        <CardBody> 
          {screenRender()}
        </CardBody>
      </Card>      
    </>
      ); 
  };

export default MonthlyProductionSummaryChart
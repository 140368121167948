import { React, useState, useEffect, useRef } from 'react';
import Viz from 'components/Tableau/Viz';
import { useTranslation } from 'react-i18next';
import { callApi, getSessionItem } from 'utils/utils';
import { config } from 'utils/config';
import { FreeBreakfastRounded } from '@material-ui/icons';

const MonthlyFollowUpDashboard = (props) => {

  const [viz, setViz] = useState(null);

  useEffect(() => {
    createViz();
  }, [])

  const createViz = () =>  {
    const paramsService = {};
    const urlWebService = `${config.UrlApiProject}Tableau/GetSalesPerformanceDashboardAgente?ClaUsuario=${getSessionItem('ClaUsuarioMod', 0)}`;

    callApi(urlWebService, 'GET', paramsService, (response) => {
      // Tableau Dashboard parameters
      const params = {};

      // Current Month
      const date = new Date();
      const [DateParam] = new Date( date.getFullYear(), date.getMonth(), 1).toISOString().split('T');
      params.DateParameter = DateParam;

      if (response.data.Agente !== -1) {
        params.IDUSER = response.data.Agente.ClaTipoAgente;
        params.ClaUsuarioManager = response.data.Agente.ClaUsuario;
        params.AgenteSrManager = response.data.Agente.ClaUsuario;
        params.ClaUsuarioAgente = response.data.Agente.ClaUsuario
      }
      else {
        // Not set up in table
        params.IDUSER = 0;
      }

      // const url = 'https://tableau.deacero.com/#/views/SalesMonthlyIncentiveProgress/SalesCurrMonthPerf'
      const url = 'https://tableau.deacero.com/views/SalesMonthlyIncentiveProgress_16999894413290/SalesCurrMonthPerf'

      setViz(<Viz url={url} parameters={params}></Viz>);
    });
  }

  return (
    <>
      <div>{viz}</div>
    </>
  )
}

export default MonthlyFollowUpDashboard;
import React, {useEffect} from 'react'; 
import OneSignal from 'react-onesignal';
import { callApi, getSessionItem, showSweetAlert } from '../../utils/utils';   

const PushNotification = (props) => { 
  
  useEffect(() => {
    OneSignal.init({
      // appId: "6c758123-75b9-4d08-9b57-20eecd7b0b1d"
      appId: "41f8d09d-e48c-42d3-9323-ec8caef5d2e7" 
    } 
    );

    OneSignal.on('subscriptionChange', (isSubscribed) => {
      /* enter here when the user subscribe to this push server */
      // set the user to create a relationship between player id and the current user....
      if (isSubscribed){
        OneSignal.setExternalUserId(getSessionItem('ClaUsuarioMod', 0));
      }
      else{
        OneSignal.removeExternalUserId()
      }
    });

      
  }, []);

  return (
    <>  
    </>
  );
};

export default PushNotification;

import React from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardImg,
    CardImgOverlay,
    CardTitle,
    CardText,
    ListGroupItem,
    ListGroup,
    Row,
    Col,
    Form, 
    FormGroup, 
    Label, 
    Input 
  } from "reactstrap";
  import { NavLink as NavLinkRRD, Link, Switch } from 'react-router-dom';
  import Pregunta from './Pregunta.js'; 
  import { Divider } from 'primereact/divider'; 
  import { useTranslation } from 'react-i18next';

function  chunkArray(myArray, chunk_size){
    const results = [];
    
    while (myArray.length) {
        results.push(myArray.splice(0, chunk_size));
    }
    
    return results;
}

// Format the time
const formatTime = (inputTime) => {
  const time = new Date(inputTime);

  const options = {
    dateStyle: "short",
    timeStyle: "short"
  }

  return(time.toLocaleString("en-US", options));
}

const FormularioResuelto = ({ formularioResuelto, returnResultados }, ) => {
    
    const { t, i18n } = useTranslation(['formulario','common']);
    const listPreguntasTemp = []; 

    const  copyPreguntas = [...formularioResuelto.preguntas];

    const result =  chunkArray(copyPreguntas, formularioResuelto.preguntas.length);

    for (let i = 0; i < result.length;){ 
        for (let x = 0; x < result[i].length;) {
          listPreguntasTemp.push(<Pregunta key={`pregunta-${result[i][x].IdFormularioPregunta}`} pregunta={result[i][x]}  />)
          x+=1;
        } 

        i+=1;
    }
     
    return (
      <> 
        <Card>
          <CardHeader>
            <CardTitle>{formularioResuelto.NomFormulario}</CardTitle>
          </CardHeader>
          <CardBody> 
            <Divider align="center" type="dashed">
              <b>{t('formulario:GeneralInformation')}</b>
            </Divider>
            <Row>  
              <Col lg={12} md={12} sm={12} xs={12}> 
                <FormGroup>
                  <Label><b>{t('common:UserName')}</b></Label> 
                  <div>{formularioResuelto.NomUsuarioResuelve}</div>
                </FormGroup>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}> 
                <FormGroup>
                  <Label><b>{t('common:Date')}</b></Label> 
                  <div>{formatTime(formularioResuelto.FechaCaptura)}</div>
                </FormGroup>
              </Col> 
            </Row>
            { formularioResuelto.ClaFormulario === 1 ?  
                  ( 
                    // cuando es el formulario 1 
                    <Row>  
                      <Col lg={6} md={6} sm={12} xs={12}> 
                        <FormGroup>
                          <Label><b>{t('formulario:Shift')}</b></Label> 
                          <div>{formularioResuelto.NomTurno}</div>
                        </FormGroup> 
                      </Col>
                      <Col lg={6} md={6} sm={12} xs={12}> 
                        <FormGroup>
                          <Label><b>{t('formulario:Department')}</b></Label> 
                          <div>{formularioResuelto.NomDepartamento}</div>
                        </FormGroup> 
                      </Col>
                      <Col lg={6} md={6} sm={12} xs={12}> 
                        <FormGroup>
                          <Label><b>{t('formulario:Cell')}</b></Label> 
                          <div>{formularioResuelto.ClaveCelda}</div>
                        </FormGroup> 
                      </Col> 
                    </Row> 
                  ) 
                  : formularioResuelto.ClaFormulario === 2 ? (
                    <div className="product-grid-item-additional-info">  
                      <Row>  
                        <Col lg={6} md={6} sm={12} xs={12}> 
                          <FormGroup>
                            <Label><b>{t('formulario:Shift')}</b></Label> 
                            <div>{formularioResuelto.NomTurno}</div>
                          </FormGroup> 
                        </Col>
                      </Row>
                    </div>
                  ) 
                  : formularioResuelto.ClaFormulario === 3 || formularioResuelto.ClaFormulario === 4 || formularioResuelto.ClaFormulario === 5 ? (
                    <Row>  
                      <Col lg={4} md={4} sm={12} xs={12}> 
                        <FormGroup>
                          <Label><b>{t('formulario:Shift')}</b></Label> 
                          <div>{formularioResuelto.NomTurno}</div>
                        </FormGroup> 
                      </Col>
                      <Col lg={4} md={4} sm={12} xs={12}> 
                        <FormGroup>
                          <Label><b>{t('formulario:Department')}</b></Label> 
                          <div>{formularioResuelto.NomDepartamento}</div>
                        </FormGroup> 
                      </Col>
                      <Col lg={4} md={4} sm={12} xs={12}> 
                        <FormGroup>
                          <Label><b>{t('formulario:Cell')}</b></Label> 
                          <div>{`${formularioResuelto.ClaveCelda}${formularioResuelto.ABCMachine !== null && formularioResuelto.ABCMachine !== undefined ? formularioResuelto.ABCMachine : ''}`}</div>
                        </FormGroup> 
                      </Col> 
                    </Row> 
                  ) : <></>} 
            <Divider align="center" type="dashed">
              <b>{t('formulario:Questionnaire')} &nbsp; { formularioResuelto.EsRequiereRevision === true ? (<i className="fab fa-font-awesome-flag text-warning"></i>) : <></> }</b>
            </Divider>
            {listPreguntasTemp}
            <img src={`data:image/jpeg;base64,${formularioResuelto.FirmaDigital}`} height="130px" alt="Sign"></img>
          </CardBody>
          <CardFooter>
            <Button color="primary" className="btn-icon btn-circle btn-lg" onClick={returnResultados} type="button">
              <i className="fas fa-chevron-left" />
            </Button>
          </CardFooter>
        </Card>  
      </>
        );  
}; 
        
export default FormularioResuelto;
/* eslint-disable no-array-constructor */
/* eslint-disable react/no-find-dom-node */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useState, useEffect, useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { getSessionItem, callApi, showSweetAlert } from 'utils/utils';
import { Label, Row, Col, Button } from 'reactstrap'; 
import { config } from 'utils/config'; 
import * as yup from 'yup'; 
import moment from 'moment'; 
import { Dialog } from "primereact/dialog"
import './HelpCenter.css';
import VideoPlayer from '../Controls/VideoPlayer/VideoPlayer.jsx';  
import PDFViewer from '../Controls/PDFViewer/PDFViewer';
import screenfull from 'screenfull'
import { findDOMNode } from 'react-dom'
import HelpCenterItem from './HelpCenterItem.jsx';

const HelpCenter = (props) => {
  const {t, i18n} = useTranslation(['it', 'common']);  

  const [showHelpCenterInfo, setShowHelpCenterInfo] = useState(false);
  const [helpCenterInfoDetail, setHelpCenterInfoDetail] = useState([]); 
  

  const onClickShowHelpCenter = () => { 
    setHelpCenterInfoDetail([
      {
        ScreenName: 'Strategic Aligment (Video Tutorials)',
        DocumentList: [
          {
            FileName: 'Strategic Alignment Module Introduction',
            FileURL:  'https://sirius.mcswusa.com/media/help_center/Strategic_align.mp4',
            FileType:  1,
            FileDescription: ''
          },
          {
            FileName: 'Scorecard and Results',
            FileURL:  'https://sirius.mcswusa.com/media/help_center/Scorecard.mp4',
            FileType:  1,
            FileDescription: 'Introduction to the use of the Scorecard and objectives module, including a breif explanation on how KPIs are updated as part of the month end process and the visualization of the objective capture screen.'
          },{
            FileName: 'My Activities',
            FileURL:  'https://sirius.mcswusa.com/media/help_center/My_activities.mp4',
            FileType:  1,
            FileDescription: 'Introduction to the layout of the new My Activities screen and it´s new functionalities, including the new filtering options and administrative tab for My Team Activities.'
          },
          {
          FileName: 'Focus Report',
          FileURL:  'https://sirius.mcswusa.com/media/help_center/Focus_report.mp4',
          FileType:  1,
          FileDescription: 'Introduction to the detail contained in the focus report, including a breif explanation of how our monthly score is divided and calculated as well as the creation of Action Plans for indicators under the minimum satisfactory goal.'
        },{
          FileName: 'My Projects',
          FileURL:  'https://sirius.mcswusa.com/media/help_center/My_proyects.mp4',
          FileType:  1,
          FileDescription: 'Introduction to the new MY Project layout and the update to the project editor, explaining it´s multiple functionalities and providing an example for the creation of new projects.'
        },{
          FileName: 'Manage Meetings',
          FileURL:  'https://sirius.mcswusa.com/media/help_center/Manage_meetings.mp4',
          FileType:  1,
          FileDescription: 'Introduction to the new Manage Meetings functionality an the creation of multiple types of meetings, explanation of shortcuts for key meeting types such as project, vertical and team meetings.'
        },{
          FileName: 'Progress Report',
          FileURL:  'https://sirius.mcswusa.com/media/help_center/ProgressReport.mp4',
          FileType:  1,
          FileDescription: 'Introduction to the Progress Report screen in My Projects, including the creation and visualization of current and previous progress reports.'
        }
       ]
      },
      {
        ScreenName: 'Strategic Aligment (Standard Process Manuals)',
        DocumentList: [
          {
            FileName: 'Scorecard and Results',
            FileURL:  'https://sirius.mcswusa.com/media/help_center/Standard_Process_Manual_Scorecard_and_Objectives_SIRIUS.pdf',
            FileType:  2,
            FileDescription: 'Detailed description of the suggested process to follow for the upload of KPI results to the Strategic Alignment module in SIRIUS.'
          },
          {
            FileName: 'My Activities',
            FileURL:  'https://sirius.mcswusa.com/media/help_center/Standard_Process_Manual_My_Activities_SIRIUS.pdf',
            FileType:  2,
            FileDescription: 'Detailed description of the of the layout and functionality of the new My Activities module, explaining the process to start and finish ur activities as well as manage our team activities.'
          },{
            FileName: 'Focus Report',
            FileURL:  'https://sirius.mcswusa.com/media/help_center/Standard_procedure_Focus_Report_SIRIUS.pdf',
            FileType:  2,
            FileDescription: 'Detailed description of the process regarding the creation of Action Plans through the Focus Report.'
          },
          {
          FileName: 'My Projects',
          FileURL:  'https://sirius.mcswusa.com/media/help_center/Standard_Process_Manual_My_Projects_SIRIUS.pdf',
          FileType:  2,
          FileDescription: 'Detailed description of the new My Projects screen and tool, explaining how to create a new project in the platform and execute the follow up process after the projects were created.'
        },{
          FileName: 'Vertical Meetings',
          FileURL:  'https://sirius.mcswusa.com/media/help_center/Standard_procedure_Vertical_Meetings_SIRIUS.pdf',
          FileType:  2,
          FileDescription: 'Detailed description of the elements contained in the meetings screen, explaining the creation of a vertical meeting and the process for it´s execution.'
        },{
          FileName: 'Progress Report',
          FileURL:  'https://sirius.mcswusa.com/media/help_center/Standard_Process_Manual_Progress_Reports_SIRIUS.pdf',
          FileType:  2,
          FileDescription: 'Detail description of the process of how to use the Progress Reports included in the My Projects screen.'
        }
       ]
      }  
    ])
    setShowHelpCenterInfo(true);
  }
    
   
  return (
    
    <>
      <Button
        className="layout-topbar-action"
        type="button"
        color=" "
        onClick={() => onClickShowHelpCenter()}
      >
        <span className="btn-inner--icon">
          <i className="fas fa-question-circle fa-lg x2"></i>
                       
        </span>
      </Button>   
      <Dialog
        visible={showHelpCenterInfo}
        onHide={() => setShowHelpCenterInfo(false)}
        header={t('it:HelpCenter')}
        style={{width: '80vw'}}
        breakpoints={{'1700px': '85vw', '1000px': '90vw'}}
      >  
        <Row className="align-items-start ">
          <Col lg={12} md={12} sm={12} xs={12}>
            {helpCenterInfoDetail.map((element, index) => {
                return (
                  <div key={index}>
                    <Row>
                      <Col>
                        <span className='h2'><i className="fas fa-angle-double-right"></i> {element.ScreenName}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        { 
                          element.DocumentList.map((documentInfo, index2) => {
   
                          return (
                            <HelpCenterItem key={index2} index={index2} FileType={documentInfo.FileType} FileURL={documentInfo.FileURL} FileName={documentInfo.FileName} FileDescription={documentInfo.FileDescription}></HelpCenterItem>
                          );
                          
                          
                        }) 
                   
                  }
                      </Col>
                    </Row>
                  </div>
                );
              })}
          </Col>
        </Row>
      </Dialog> 
    </>
  )
}

export default HelpCenter;
/* eslint-disable radix */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useLayoutEffect, Component, useEffect } from 'react';
// import OrgChart from '@balkangraph/orgchart.js';

const OrganizationalChartTemplate = (props) => {
  const divRef = useRef(null);

  useEffect(() => {
    renderOrgChart(props.filters);
    console.log(props.nodes);
  }, [props.filters]);

  const renderOrgChart = (pfilter) => {
    if (!pfilter.Indicadores) {
      IndicadorTemplate();
    } else if (pfilter.Indicadores.length === 1) {
      Indicador1Template();
    } else if (pfilter.Indicadores.length === 2) {
      Indicador2Template();
    } else if (pfilter.Indicadores.length === 3) {
      Indicador3Template();
    } else if (pfilter.Indicadores.length === 4) {
      Indicador4Template();
    } else if (pfilter.Indicadores.length === 5) {
      Indicador5Template();
    }else {
      IndicadorTemplate();
    }
  };
  const IndicadorTemplate = () => {
    OrgChart.templates.cool = { ...OrgChart.templates.ana };    
    // OrgChart.templates.cool.link = '<path stroke-linejoin="round" stroke="#aeaeae" stroke-width="1px" fill="none" d="{curve}" />';
    OrgChart.templates.cool.defs =
      '<filter x="-50%" y="-50%" width="200%" height="300%" filterUnits="objectBoundingBox" id="cool-shadow">' +
      '<feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />' +
      '<feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1" />' +
      '<feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0" in="shadowBlurOuter1" type="matrix" result="shadowMatrixOuter1" />' +
      '<feMerge><feMergeNode in="shadowMatrixOuter1" /><feMergeNode in="SourceGraphic" /></feMerge>' +
      '</filter>';

    OrgChart.templates.cool.size = [360, 195];

    OrgChart.templates.cool.node = // `${
      '<rect filter="url(#cool-shadow)"  x="0" y="40" height="155" width="360" fill="#ffffff" stroke-width="1" stroke="#eeeeee" rx="10" ry="10"></rect>' +
      '<rect fill="#ffffff" x="10" y="70" width="340" height="40" rx="10" ry="10" filter="url(#cool-shadow)"></rect>' +
      '<rect stroke="#eeeeee" x="10" y="115" stroke-width="1" width="275" fill="#ffffff" rx="10" ry="10" height="60"></rect>' +
      '<rect stroke="#eeeeee" x="290" y="115" stroke-width="1" width="60" fill="#ffffff" rx="10" ry="10" height="60"></rect>';
 

    OrgChart.templates.cool.img =
      '<clipPath id="{randId}">' +
      '<rect  fill="#ffffff" stroke="#039BE5" stroke-width="2" x="0" y="0" rx="10" ry="10" width="80" height="100"></rect></clipPath>' +
      '<image preserveAspectRatio="xMidYMid slice" xlink:href="{val}" onerror=\'this.setAttributeNS("http://www.w3.org/1999/xlink", "href", "https://mcsw.deacero.com/Colaborador/none.png");\' x="150" y="5"  width="60" height="60"></image>' ;

    OrgChart.templates.cool.name =
      '<text  style="font-size: 18px;" data-width="280" fill="#afafaf" x="15" y="95">{val}</text>';
    OrgChart.templates.cool.Area =
      '<text  data-width="160" style="font-size: 14px;" fill="#F57C00" x="20" y="140">{val}</text>';
    OrgChart.templates.cool.title =
      '<text style="font-size: 14px;" fill="#afafaf" x="20" y="160">{val}</text>';
    OrgChart.templates.cool.points =
      '<text style="font-size: 24px;" fill="#F57C00" x="320" y="160" text-anchor="middle">{val}</text>';
    OrgChart.templates.cool.svg =
      '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="background-color:#e4e7f0 !important;display:block;" width="{w}" height="{h}" viewBox="{viewBox}">{content}</svg>';

    const chart = new OrgChart(divRef.current, {
      nodes: props.nodes,
      layout: OrgChart.mixed,
      align: OrgChart.ORIENTATION, 
      scaleInitial : OrgChart.match.boundary,
      showXScroll: OrgChart.scroll.visible,
      // menu: {
        /* pdf: { text: "Export PDF" },
        png: { text: "Export PNG" }, */
        // svg: { text: "Export SVG" },
        // csv: { text: "Export CSV" }
      // },
      nodeBinding: {
        name: 'name',
        Area: 'Area',
        title: 'title',
        points: 'points',
        img: 'img',
      },
      template: 'cool',
      enableDragDrop: false,
      enableSearch: false,
      toolbar: {
        layout: false,
        zoom: true,
        fit: true,
        expandAll: false,
        fullScreen: true

      }, 
      collapse: {
        level: 2,
        allChildren: true,
      },
      nodeMouseClick: 'none',
    }); 
  };
 

  const Indicador1Template = () => {
    OrgChart.templates.cool = { ...OrgChart.templates.ana };
    // OrgChart.templates.cool.link = '<path stroke-linejoin="round" stroke="#aeaeae" stroke-width="1px" fill="none" d="{curve}" />';
    OrgChart.templates.cool.defs =
      '<filter x="-50%" y="-50%" width="200%" height="300%" filterUnits="objectBoundingBox" id="cool-shadow">' +
      '<feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />' +
      '<feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1" />' +
      '<feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0" in="shadowBlurOuter1" type="matrix" result="shadowMatrixOuter1" />' +
      '<feMerge><feMergeNode in="shadowMatrixOuter1" /><feMergeNode in="SourceGraphic" /></feMerge>' +
      '</filter>';

    OrgChart.templates.cool.size = [360, 255];

    OrgChart.templates.cool.node = // `${
      '<rect filter="url(#cool-shadow)"  x="0" y="40" height="215" width="360" fill="#ffffff" stroke-width="1" stroke="#eeeeee" rx="10" ry="10"></rect>' +
      '<rect fill="#ffffff" x="10" y="70" width="340" height="40" rx="10" ry="10" filter="url(#cool-shadow)"></rect>' +
      '<rect stroke="#eeeeee" x="10" y="115" stroke-width="1" width="275" fill="#ffffff" rx="10" ry="10" height="60"></rect>' +
      '<rect stroke="#eeeeee" x="290" y="115" stroke-width="1" width="60" fill="#ffffff" rx="10" ry="10" height="60"></rect>' +
      '<rect fill="#ffffff" x="10" y="180" width="340" height="70" rx="10" ry="10" filter="url(#cool-shadow)"></rect>' + 
      '<text  style="font-size: 18px;" fill="#afafaf" x="150" y="205" stroke-width="1" font-weight="bold">Score</text>';

    OrgChart.templates.cool.img =
    '<clipPath id="{randId}">' +
    '<rect  fill="#ffffff" stroke="#039BE5" stroke-width="2" x="0" y="0" rx="10" ry="10" width="80" height="100"></rect></clipPath>' +
    '<image preserveAspectRatio="xMidYMid slice" xlink:href="{val}" onerror=\'this.setAttributeNS("http://www.w3.org/1999/xlink", "href", "https://mcsw.deacero.com/Colaborador/none.png");\' x="150" y="5"  width="60" height="60"></image>' ;


    OrgChart.templates.cool.name =
      '<text  style="font-size: 18px;" data-width="280" fill="#afafaf" x="15" y="95">{val}</text>';
    OrgChart.templates.cool.Area =
      '<text  data-width="160" style="font-size: 14px;" fill="#F57C00" x="20" y="140">{val}</text>';
    OrgChart.templates.cool.title =
      '<text style="font-size: 14px;" fill="#afafaf" x="20" y="160">{val}</text>';
    OrgChart.templates.cool.points =
      '<text style="font-size: 24px;" fill="#F57C00" x="320" y="160" text-anchor="middle">{val}</text>';
    OrgChart.templates.cool.Indicador1 =
      '<text x="15" y="230" font-size="14px" fill="#afafaf">{val}</text>';
    OrgChart.templates.cool.Indicador1Value =
      '<text style="font-size: 14px;" fill="#F57C00" x="320" y="230" text-anchor="middle">{val}</text>';
    OrgChart.templates.cool.svg =
      '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="background-color:#e4e7f0;display:block;" width="{w}" height="{h}" viewBox="{viewBox}">{content}</svg>';

    const chart = new OrgChart(divRef.current, {
      nodes: props.nodes,
      layout: OrgChart.mixed,
      align: OrgChart.ORIENTATION, 
      scaleInitial : OrgChart.match.boundary,
      showXScroll: OrgChart.scroll.visible,
      
      // menu: {
        /* pdf: { text: "Export PDF" },
        png: { text: "Export PNG" }, */
        //  svg: { text: "Export SVG" },
        // csv: { text: "Export CSV" }
      // },  
      nodeBinding: {
        name: 'name',
        Area: 'Area',
        title: 'title',
        points: 'points',
        // performance: 'performance',
        img: 'img',
        Indicador1: 'Indicador1',
        Indicador1Value: 'Indicador1Value',
      },
      template: 'cool',
      enableDragDrop: false,
      enableSearch: false,
      toolbar: {
        layout: false,
        zoom: true,
        fit: true,
        expandAll: false, 
        fullScreen: true 
      }, 
      collapse: {
        level: 2,
        allChildren: true,
      },
      nodeMouseClick: 'none',
    });
  };

  const Indicador2Template = () => {
    OrgChart.templates.cool = { ...OrgChart.templates.ana };
    // OrgChart.templates.cool.link = '<path stroke-linejoin="round" stroke="#aeaeae" stroke-width="1px" fill="none" d="{curve}" />';
    OrgChart.templates.cool.defs =
      '<filter x="-50%" y="-50%" width="200%" height="300%" filterUnits="objectBoundingBox" id="cool-shadow">' +
      '<feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />' +
      '<feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1" />' +
      '<feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0" in="shadowBlurOuter1" type="matrix" result="shadowMatrixOuter1" />' +
      '<feMerge><feMergeNode in="shadowMatrixOuter1" /><feMergeNode in="SourceGraphic" /></feMerge>' +
      '</filter>';

    OrgChart.templates.cool.size = [360, 275];

    OrgChart.templates.cool.node = // `${
      '<rect filter="url(#cool-shadow)"  x="0" y="40" height="235" width="360" fill="#ffffff" stroke-width="1" stroke="#eeeeee" rx="10" ry="10"></rect>' +
      '<rect fill="#ffffff" x="10" y="70" width="340" height="40" rx="10" ry="10" filter="url(#cool-shadow)"></rect>' +
      '<rect stroke="#eeeeee" x="10" y="115" stroke-width="1" width="275" fill="#ffffff" rx="10" ry="10" height="60"></rect>' +
      '<rect stroke="#eeeeee" x="290" y="115" stroke-width="1" width="60" fill="#ffffff" rx="10" ry="10" height="60"></rect>' +
      '<rect fill="#ffffff" x="10" y="180" width="340" height="83" rx="10" ry="10" filter="url(#cool-shadow)"></rect>' + 
      '<text  style="font-size: 18px;" fill="#afafaf" x="150" y="205" stroke-width="1" font-weight="bold">Score</text>';

      OrgChart.templates.cool.img =
      '<clipPath id="{randId}">' +
      '<rect  fill="#ffffff" stroke="#039BE5" stroke-width="2" x="0" y="0" rx="10" ry="10" width="80" height="100"></rect></clipPath>' +
      '<image preserveAspectRatio="xMidYMid slice" xlink:href="{val}" onerror=\'this.setAttributeNS("http://www.w3.org/1999/xlink", "href", "https://mcsw.deacero.com/Colaborador/none.png");\' x="150" y="5"  width="60" height="60"></image>' ;

    OrgChart.templates.cool.name =
      '<text  style="font-size: 18px;" data-width="280" fill="#afafaf" x="15" y="95">{val}</text>';
    OrgChart.templates.cool.Area =
      '<text  data-width="160" style="font-size: 14px;" fill="#F57C00" x="20" y="140">{val}</text>';
    OrgChart.templates.cool.title =
      '<text style="font-size: 14px;" fill="#afafaf" x="20" y="160">{val}</text>';
    OrgChart.templates.cool.points =
      '<text style="font-size: 24px;" fill="#F57C00" x="320" y="160" text-anchor="middle">{val}</text>';
    OrgChart.templates.cool.Indicador1 =
      '<text x="15" y="230" font-size="14px" fill="#afafaf">{val}</text>';
    OrgChart.templates.cool.Indicador1Value =
      '<text style="font-size: 14px;" fill="#F57C00" x="320" y="230" text-anchor="middle">{val}</text>';
    OrgChart.templates.cool.Indicador2 =
      '<text x="15" y="250" font-size="14px" fill="#afafaf">{val}</text>';
    OrgChart.templates.cool.Indicador2Value =
      '<text style="font-size: 14px;" fill="#F57C00" x="320" y="250" text-anchor="middle">{val}</text>';
    OrgChart.templates.cool.svg =
      '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="background-color:#e4e7f0;display:block;" width="{w}" height="{h}" viewBox="{viewBox}">{content}</svg>';

    const chart = new OrgChart(divRef.current, {
      nodes: props.nodes,
      layout: OrgChart.mixed,
      align: OrgChart.ORIENTATION, 
      scaleInitial : OrgChart.match.boundary,
      showXScroll: OrgChart.scroll.visible,
      // menu: {
        /* pdf: { text: "Export PDF" },
        png: { text: "Export PNG" }, */
        // svg: { text: "Export SVG" },
        // csv: { text: "Export CSV" }
      // }, 
      nodeBinding: {
        name: 'name',
        Area: 'Area',
        title: 'title',
        points: 'points',
        // performance: 'performance',
        img: 'img',
        Indicador1: 'Indicador1',
        Indicador1Value: 'Indicador1Value',
        Indicador2: 'Indicador2',
        Indicador2Value: 'Indicador2Value',
      },
      template: 'cool',
      enableDragDrop: false,
      enableSearch: false,
      toolbar: {
        layout: false,
        zoom: true,
        fit: true,
        expandAll: false,  
        fullScreen: true 
      }, 
      collapse: {
        level: 2,
        allChildren: true,
      },
      nodeMouseClick: 'none',
    });
  };
  const Indicador3Template = () => {
    OrgChart.templates.cool = { ...OrgChart.templates.ana };
    // OrgChart.templates.cool.link = '<path stroke-linejoin="round" stroke="#aeaeae" stroke-width="1px" fill="none" d="{curve}" />';
    OrgChart.templates.cool.defs =
      '<filter x="-50%" y="-50%" width="200%" height="300%" filterUnits="objectBoundingBox" id="cool-shadow">' +
      '<feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />' +
      '<feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1" />' +
      '<feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0" in="shadowBlurOuter1" type="matrix" result="shadowMatrixOuter1" />' +
      '<feMerge><feMergeNode in="shadowMatrixOuter1" /><feMergeNode in="SourceGraphic" /></feMerge>' +
      '</filter>';

    OrgChart.templates.cool.size = [360, 295];

    OrgChart.templates.cool.node = // `${
      '<rect filter="url(#cool-shadow)"  x="0" y="40" height="255" width="360" fill="#ffffff" stroke-width="1" stroke="#eeeeee" rx="10" ry="10"></rect>' +
      '<rect fill="#ffffff" x="10" y="70" width="340" height="40" rx="10" ry="10" filter="url(#cool-shadow)"></rect>' +
      '<rect stroke="#eeeeee" x="10" y="115" stroke-width="1" width="275" fill="#ffffff" rx="10" ry="10" height="60"></rect>' +
      '<rect stroke="#eeeeee" x="290" y="115" stroke-width="1" width="60" fill="#ffffff" rx="10" ry="10" height="60"></rect>' +
      '<rect fill="#ffffff" x="10" y="180" width="340" height="100" rx="10" ry="10" filter="url(#cool-shadow)"></rect>' + 
      '<text  style="font-size: 18px;" fill="#afafaf" x="150" y="205" stroke-width="1" font-weight="bold">Score</text>';

      OrgChart.templates.cool.img =
      '<clipPath id="{randId}">' +
      '<rect  fill="#ffffff" stroke="#039BE5" stroke-width="2" x="0" y="0" rx="10" ry="10" width="80" height="100"></rect></clipPath>' +
      '<image preserveAspectRatio="xMidYMid slice" xlink:href="{val}" onerror=\'this.setAttributeNS("http://www.w3.org/1999/xlink", "href", "https://mcsw.deacero.com/Colaborador/none.png");\' x="150" y="5"  width="60" height="60"></image>' ;

    OrgChart.templates.cool.name =
      '<text  style="font-size: 18px;" data-width="280" fill="#afafaf" x="15" y="95">{val}</text>';
    OrgChart.templates.cool.Area =
      '<text  data-width="160" style="font-size: 14px;" fill="#F57C00" x="20" y="140">{val}</text>';
    OrgChart.templates.cool.title =
      '<text style="font-size: 14px;" fill="#afafaf" x="20" y="160">{val}</text>';
    OrgChart.templates.cool.points =
      '<text style="font-size: 24px;" fill="#F57C00" x="320" y="160" text-anchor="middle">{val}</text>';
    OrgChart.templates.cool.Indicador1 =
      '<text x="15" y="230" font-size="14px" fill="#afafaf">{val}</text>';
    OrgChart.templates.cool.Indicador1Value =
      '<text style="font-size: 14px;" fill="#F57C00" x="320" y="230" text-anchor="middle">{val}</text>';
    OrgChart.templates.cool.Indicador2 =
      '<text x="15" y="250" font-size="14px" fill="#afafaf">{val}</text>';
    OrgChart.templates.cool.Indicador2Value =
      '<text style="font-size: 14px;" fill="#F57C00" x="320" y="250" text-anchor="middle">{val}</text>';
    OrgChart.templates.cool.Indicador3 =
      '<text x="15" y="270" font-size="14px" fill="#afafaf">{val}</text>';
    OrgChart.templates.cool.Indicador3Value =
      '<text style="font-size: 14px;" fill="#F57C00" x="320" y="270" text-anchor="middle">{val}</text>';
    OrgChart.templates.cool.svg =
      '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="background-color:#e4e7f0;display:block;" width="{w}" height="{h}" viewBox="{viewBox}">{content}</svg>';

    const chart = new OrgChart(divRef.current, {
      nodes: props.nodes,
      layout: OrgChart.mixed,
      align: OrgChart.ORIENTATION, 
      scaleInitial : OrgChart.match.boundary,
      showXScroll: OrgChart.scroll.visible,
      //  menu: {
        /* pdf: { text: "Export PDF" },
        png: { text: "Export PNG" }, */
        //  svg: { text: "Export SVG" },
        // csv: { text: "Export CSV" }
      // }, 
      nodeBinding: {
        name: 'name',
        Area: 'Area',
        title: 'title',
        points: 'points',
        // performance: 'performance',
        img: 'img',
        Indicador1: 'Indicador1',
        Indicador1Value: 'Indicador1Value',
        Indicador2: 'Indicador2',
        Indicador2Value: 'Indicador2Value',
        Indicador3: 'Indicador3',
        Indicador3Value: 'Indicador3Value',
      },
      template: 'cool',
      enableDragDrop: false,
      enableSearch: false,
      toolbar: {
        layout: false,
        zoom: true,
        fit: true,
        expandAll: false,   
        fullScreen: true
      }, 
      collapse: {
        level: 2,
        allChildren: true,
      },
      nodeMouseClick: 'none',
    });
  };
  const Indicador4Template = () => {
    OrgChart.templates.cool = { ...OrgChart.templates.ana };
    // OrgChart.templates.cool.link = '<path stroke-linejoin="round" stroke="#aeaeae" stroke-width="1px" fill="none" d="{curve}" />';
    OrgChart.templates.cool.defs =
      '<filter x="-50%" y="-50%" width="200%" height="300%" filterUnits="objectBoundingBox" id="cool-shadow">' +
      '<feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />' +
      '<feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1" />' +
      '<feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0" in="shadowBlurOuter1" type="matrix" result="shadowMatrixOuter1" />' +
      '<feMerge><feMergeNode in="shadowMatrixOuter1" /><feMergeNode in="SourceGraphic" /></feMerge>' +
      '</filter>';

    OrgChart.templates.cool.size = [360, 315];

    OrgChart.templates.cool.node = // `${
      '<rect filter="url(#cool-shadow)"  x="0" y="40" height="275" width="360" fill="#ffffff" stroke-width="1" stroke="#eeeeee" rx="10" ry="10"></rect>' +
      '<rect fill="#ffffff" x="10" y="70" width="340" height="40" rx="10" ry="10" filter="url(#cool-shadow)"></rect>' +
      '<rect stroke="#eeeeee" x="10" y="115" stroke-width="1" width="275" fill="#ffffff" rx="10" ry="10" height="60"></rect>' +
      '<rect stroke="#eeeeee" x="290" y="115" stroke-width="1" width="60" fill="#ffffff" rx="10" ry="10" height="60"></rect>' +
      '<rect fill="#ffffff" x="10" y="180" width="340" height="120" rx="10" ry="10" filter="url(#cool-shadow)"></rect>' + 
      '<text  style="font-size: 18px;" fill="#afafaf" x="150" y="205" stroke-width="1" font-weight="bold">Score</text>';

      OrgChart.templates.cool.img =
      '<clipPath id="{randId}">' +
      '<rect  fill="#ffffff" stroke="#039BE5" stroke-width="2" x="0" y="0" rx="10" ry="10" width="80" height="100"></rect></clipPath>' +
      '<image preserveAspectRatio="xMidYMid slice" xlink:href="{val}" onerror=\'this.setAttributeNS("http://www.w3.org/1999/xlink", "href", "https://mcsw.deacero.com/Colaborador/none.png");\' x="150" y="5"  width="60" height="60"></image>' ;

    OrgChart.templates.cool.name =
      '<text  style="font-size: 18px;" data-width="280" fill="#afafaf" x="15" y="95">{val}</text>';
    OrgChart.templates.cool.Area =
      '<text  data-width="160" style="font-size: 14px;" fill="#F57C00" x="20" y="140">{val}</text>';
    OrgChart.templates.cool.title =
      '<text style="font-size: 14px;" fill="#afafaf" x="20" y="160">{val}</text>';
    OrgChart.templates.cool.points =
      '<text style="font-size: 24px;" fill="#F57C00" x="320" y="160" text-anchor="middle">{val}</text>';
    OrgChart.templates.cool.Indicador1 =
      '<text x="15" y="230" font-size="14px" fill="#afafaf">{val}</text>';
    OrgChart.templates.cool.Indicador1Value =
      '<text style="font-size: 14px;" fill="#F57C00" x="320" y="230" text-anchor="middle">{val}</text>';
    OrgChart.templates.cool.Indicador2 =
      '<text x="15" y="250" font-size="14px" fill="#afafaf">{val}</text>';
    OrgChart.templates.cool.Indicador2Value =
      '<text style="font-size: 14px;" fill="#F57C00" x="320" y="250" text-anchor="middle">{val}</text>';
    OrgChart.templates.cool.Indicador3 =
      '<text x="15" y="270" font-size="14px" fill="#afafaf">{val}</text>';
    OrgChart.templates.cool.Indicador3Value =
      '<text style="font-size: 14px;" fill="#F57C00" x="320" y="270" text-anchor="middle">{val}</text>';
    OrgChart.templates.cool.Indicador4 =
      '<text x="15" y="290" font-size="14px" fill="#afafaf">{val}</text>';
    OrgChart.templates.cool.Indicador4Value =
      '<text style="font-size: 14px;" fill="#F57C00" x="320" y="290" text-anchor="middle">{val}</text>';
    OrgChart.templates.cool.svg =
      '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="background-color:#e4e7f0;display:block;" width="{w}" height="{h}" viewBox="{viewBox}">{content}</svg>';

    const chart = new OrgChart(divRef.current, {
      nodes: props.nodes,
      layout: OrgChart.mixed,
      align: OrgChart.ORIENTATION, 
      scaleInitial : OrgChart.match.boundary,
      showXScroll: OrgChart.scroll.visible,
      //  menu: {
        /* pdf: { text: "Export PDF" },
        png: { text: "Export PNG" }, */
        // svg: { text: "Export SVG" },
        // csv: { text: "Export CSV" }
      // }, 
      nodeBinding: {
        name: 'name',
        Area: 'Area',
        title: 'title',
        points: 'points',
        // performance: 'performance',
        img: 'img',
        Indicador1: 'Indicador1',
        Indicador1Value: 'Indicador1Value',
        Indicador2: 'Indicador2',
        Indicador2Value: 'Indicador2Value',
        Indicador3: 'Indicador3',
        Indicador3Value: 'Indicador3Value',
        Indicador4: 'Indicador4',
        Indicador4Value: 'Indicador4Value',
      },
      template: 'cool',
      enableDragDrop: false,
      enableSearch: false,
      toolbar: {
        layout: false,
        zoom: true,
        fit: true,
        expandAll: false,   
        fullScreen: true
      }, 
      collapse: {
        level: 2,
        allChildren: true,
      },
      nodeMouseClick: 'none',
    });
  };
  const Indicador5Template = () => {
    OrgChart.templates.cool = { ...OrgChart.templates.ana }; 
    // OrgChart.templates.cool.link = '<path stroke-linejoin="round" stroke="#aeaeae" stroke-width="1px" fill="none" d="{curve}" />';
    OrgChart.templates.cool.defs =
      '<filter x="-50%" y="-50%" width="200%" height="300%" filterUnits="objectBoundingBox" id="cool-shadow">' +
      '<feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />' +
      '<feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1" />' +
      '<feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0" in="shadowBlurOuter1" type="matrix" result="shadowMatrixOuter1" />' +
      '<feMerge><feMergeNode in="shadowMatrixOuter1" /><feMergeNode in="SourceGraphic" /></feMerge>' +
      '</filter>';

    OrgChart.templates.cool.size = [360, 335];

    OrgChart.templates.cool.node = // `${
      '<rect filter="url(#cool-shadow)"  x="0" y="40" height="295" width="360" fill="#ffffff" stroke-width="1" stroke="#eeeeee" rx="10" ry="10"></rect>' +
      '<rect fill="#ffffff" x="10" y="70" width="340" height="40" rx="10" ry="10" filter="url(#cool-shadow)"></rect>' +
      '<rect stroke="#eeeeee" x="10" y="115" stroke-width="1" width="275" fill="#ffffff" rx="10" ry="10" height="60"></rect>' +
      '<rect stroke="#eeeeee" x="290" y="115" stroke-width="1" width="60" fill="#ffffff" rx="10" ry="10" height="60"></rect>' +
      '<rect fill="#ffffff" x="10" y="180" width="340" height="140" rx="10" ry="10" filter="url(#cool-shadow)"></rect>' + 
      '<text  style="font-size: 18px;" fill="#afafaf" x="150" y="205" stroke-width="1" font-weight="bold">Score</text>';

      OrgChart.templates.cool.img =
      '<clipPath id="{randId}">' +
      '<rect  fill="#ffffff" stroke="#039BE5" stroke-width="2" x="0" y="0" rx="10" ry="10" width="80" height="100"></rect></clipPath>' +
      '<image preserveAspectRatio="xMidYMid slice" xlink:href="{val}" onerror=\'this.setAttributeNS("http://www.w3.org/1999/xlink", "href", "https://mcsw.deacero.com/Colaborador/none.png");\' x="150" y="5"  width="60" height="60"></image>' ;

    OrgChart.templates.cool.name =
      '<text  style="font-size: 18px;" data-width="280" fill="#afafaf" x="15" y="95">{val}</text>';
    OrgChart.templates.cool.Area =
      '<text  data-width="160" style="font-size: 14px;" fill="#F57C00" x="20" y="140">{val}</text>';
    OrgChart.templates.cool.title =
      '<text style="font-size: 14px;" fill="#afafaf" x="20" y="160">{val}</text>';
    OrgChart.templates.cool.points =
      '<text style="font-size: 24px;" fill="#F57C00" x="320" y="160" text-anchor="middle">{val}</text>';
    OrgChart.templates.cool.Indicador1 =
      '<text x="15" y="230" font-size="14px" fill="#afafaf">{val}</text>';
    OrgChart.templates.cool.Indicador1Value =
      '<text style="font-size: 14px;" fill="#F57C00" x="320" y="230" text-anchor="middle">{val}</text>';
    OrgChart.templates.cool.Indicador2 =
      '<text x="15" y="250" font-size="14px" fill="#afafaf">{val}</text>';
    OrgChart.templates.cool.Indicador2Value =
      '<text style="font-size: 14px;" fill="#F57C00" x="320" y="250" text-anchor="middle">{val}</text>';
    OrgChart.templates.cool.Indicador3 =
      '<text x="15" y="270" font-size="14px" fill="#afafaf">{val}</text>';
    OrgChart.templates.cool.Indicador3Value =
      '<text style="font-size: 14px;" fill="#F57C00" x="320" y="270" text-anchor="middle">{val}</text>';
    OrgChart.templates.cool.Indicador4 =
      '<text x="15" y="290" font-size="14px" fill="#afafaf">{val}</text>';
    OrgChart.templates.cool.Indicador4Value =
      '<text style="font-size: 14px;" fill="#F57C00" x="320" y="290" text-anchor="middle">{val}</text>';
    OrgChart.templates.cool.Indicador5 =
      '<text x="15" y="310" font-size="14px" fill="#afafaf">{val}</text>';
    OrgChart.templates.cool.Indicador5Value =
      '<text style="font-size: 14px;" fill="#F57C00" x="320" y="310" text-anchor="middle">{val}</text>';
    OrgChart.templates.cool.svg =
      '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="background-color:#e4e7f0;display:block;" width="{w}" height="{h}" viewBox="{viewBox}">{content}</svg>';

    const chart = new OrgChart(divRef.current, {
      nodes: props.nodes,
      layout: OrgChart.mixed,
      align: OrgChart.ORIENTATION, 
      scaleInitial : OrgChart.match.boundary,
      showXScroll: OrgChart.scroll.visible,
      // menu: {
        /* pdf: { text: "Export PDF" },
        png: { text: "Export PNG" }, */
        // svg: { text: "Export SVG" },
        // csv: { text: "Export CSV" }
      // },
      nodeBinding: {
        name: 'name',
        Area: 'Area',
        title: 'title',
        points: 'points',
        // performance: 'performance',
        img: 'img',
        Indicador1: 'Indicador1',
        Indicador1Value: 'Indicador1Value',
        Indicador2: 'Indicador2',
        Indicador2Value: 'Indicador2Value',
        Indicador3: 'Indicador3',
        Indicador3Value: 'Indicador3Value',
        Indicador4: 'Indicador4',
        Indicador4Value: 'Indicador4Value',
        Indicador5: 'Indicador5',
        Indicador5Value: 'Indicador5Value',
      },
      template: 'cool',
      enableDragDrop: false,
      enableSearch: false,
      toolbar: {
        layout: false,
        zoom: true,
        fit: true,
        expandAll: false,   
        fullScreen: true
      }, 
      collapse: {
        level: 2,
        allChildren: true,
      },
      nodeMouseClick: 'none',
    });
  };  

  return (
    <> 
      <div ref={divRef} style={{ overflow: 'hidden', position: 'relative', height: '700px', width: '100%' }}></div>  
    </>
  );
};

export default OrganizationalChartTemplate;

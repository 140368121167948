/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import { Dialog } from 'primereact/dialog';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { idpVersions } from './IndividualDevelopmentPlanConstants';
import IndividualDevelopmentPlanFormV1 from './IndividualDevelopmentPlanFormV1';
import IndividualDevelopmentPlanFormV2 from './IndividualDevelopmentPlanFormV2';
import { Badge } from 'primereact/badge';

/**
 * 
 * @param {*} props 
 * props:
 * - isVisible
 * - isNew
 * - idIDP
 * - iDPStatusName
 * - year
 * - reportType (M = Myself, T = Team)
 * - version
 * - onHide
 * - onSaved
 * - onStatusSaved
 * @returns 
 */
const IndividualDevelopmentPlanDetailDialog = (props) => {    
  const { t } = useTranslation(['hr', 'common']);
  
  const onHide = () => {
    if (props.onHide && typeof props.onHide === 'function') {
      props.onHide();
    }
  };

  const ipdV1FormTemplate = (
    <>
      <IndividualDevelopmentPlanFormV1 
        isNew={props.isNew}
        idIDP={props.idIDP}
        reportType={props.reportType}
        claUsuarioOwner={props.claUsuarioOwner}
        onHide={props.onHide}
        onSaved={props.onSaved}
        onStatusSaved={props.onStatusSaved}
      />
    </>    
  );

  const ipdV2FormTemplate = (
    <>
      <IndividualDevelopmentPlanFormV2
        isNew={props.isNew}
        idIDP={props.idIDP}
        reportType={props.reportType}
        onHide={props.onHide}
        onSaved={props.onSaved}
        onStatusSaved={props.onStatusSaved}
        year={props.year}
      />
    </>    
  );

  const dialogHeaderTemplate = (
    <>
      <Row>
        <Col xs={12} lg={6}>
          <h2 className='text-white'>
            {t('hr:IndividualDevelopmentPlan')}
          </h2>
        </Col>
        <Col 
          xs={12} 
          lg={6}
          className='text-right'
        >          
          {
            props.iDPStatusName && (
              <Badge 
                value={props.iDPStatusName} 
                className="mr-3"
                size="large" 
                severity="info"
              />
            )
          }
        </Col>
      </Row>
    </>    
  );

  const dialogBodyTemplate = (
    <>
      <Row>
        <Col>
          {props.version === idpVersions.V1 && ipdV1FormTemplate}
          {props.version === idpVersions.V2 && ipdV2FormTemplate}
        </Col>
      </Row>
    </>    
  );

  const dialogTemplate = (
    <>
      <Dialog
        modal
        header={dialogHeaderTemplate}
        visible={props.isVisible}        
        style={{ width: '100vw' }}
        onHide={onHide}
        closable={true}
        className="dialog-header-background-blue"
        maximized={true}
        draggable={false}
        blockScroll={true}
        /* footer={<h1>{`VERSION: ${props.version}`}</h1>} */
      >         
        {dialogBodyTemplate}
      </Dialog>       
    </>
  );
  
  return (
    <>
      {dialogTemplate}
    </>
  )
};

export default IndividualDevelopmentPlanDetailDialog;
/* eslint-disable jsx-a11y/label-has-associated-control */
import React  from 'react';     
import { Calendar } from 'primereact/calendar';

const FieldTime =  (props) => {
     
    const onChange = (value) => {  
        if (props.onChange !== null){            
              props.onChange(value);
        } 
    }  

    const isFormFieldInValid = (name) => !!(typeof(props.errors) !== 'undefined' && props.errors !== null && props.errors[name]);
    
    const getFormErrorMessage = (name) => {   
        return isFormFieldInValid(name) && <small className="p-error">{props.errors[name].message}</small>;
    };

    return (  
      <>  
        <span className="p-float-label">
          <Calendar
            value={props.value}
            onChange={onChange}
            timeOnly 
            showTime 
            hourFormat="24"
            className={isFormFieldInValid(props.name) === true ? 'p-invalid' : ''}
            selectionMode={props.selectionMode}
            disabled={props.disabled}
          />  
          <label className={isFormFieldInValid(props.name) === true ? 'p-error' : ''}>{props.label}</label>
        </span>
        {getFormErrorMessage(props.name)}
      </> 
    )
}   

    
export default FieldTime; 



import React, {createContext, useState, useEffect } from 'react';

export const AppContext = createContext();

const AppContextProvider = (props) => { 

    const [forceRefresh, setforceRefresh] = useState(0);
    const [viewportRefresh, setViewportRefresh] = useState(null);

    const refreshAlerts = () => {
        setforceRefresh(forceRefresh+1);
    }
    
  const refreshViewport = (viewport) => {
    setViewportRefresh(viewport);
  }

  const getViewport = () => {
    const width = window.innerWidth;
    let viewport;
    if (width < 576) {
      viewport = 'xs';
    } else if (width < 768) {
      viewport = 'sm';
    } else if (width < 992) {
      viewport = 'md';
    } else if (width < 1200) {
      viewport = 'lg';
    } else {
      viewport = 'xl';
    }
    refreshViewport(viewport);
  };

  useEffect(() => { 
    getViewport();
    window.addEventListener('resize', getViewport);
    window.addEventListener('orientationchange', getViewport2);

    return () => {
      window.removeEventListener('resize', getViewport);
      window.removeEventListener('orientationchange', getViewport2);
    }
  }, []);

  const getViewport2 = () => {
    setTimeout(()=> {

      getViewport();
    }, 100)
  };

  return (
    <AppContext.Provider value={{forceRefresh, refreshAlerts,viewportRefresh}}>
      { props.children}
    </AppContext.Provider>
    )
}

export default AppContextProvider;

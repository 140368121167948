import { React, UseState, UseEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PageContent from 'layouts/PageContent';
import InventoryBySGDash from './InventoryBySGDash';

const InventoryBySGDashScreen = (props) => {
  const {t, i18n} = useTranslation(['salesPerformance', 'common']);
  
  return (
    <PageContent title={t('salesPerformance:InventoryBySGDash')}>
      <InventoryBySGDash />
    </PageContent>
  )
}

export default InventoryBySGDashScreen;
const config = {
  LoginMensaje: 'Powered by MSW',
  LoginLogoTamanio: '48px',
  ApiKey: 'ApiKey para Kraken',
  GoogleKey: 'ApiKey para Google',
  
  
  // ** Desarrollo
  // 'http://10.1.1.50:2622/
  
  // UrlLoginServer: 'https://sweetsrv.azurewebsites.net/LoginSandbox',
  // UrlApiProject: 'http://localhost:4271/',
  // UrlProject: 'http://localhost:3000/',
  // HereKey: '4SwQ02gYNvp2wjkhzmRElogtQ1aUzGMyHxjHgzwWJa4',
  // ** Kraken Desarrollo
  // KrakenService: 'http://10.1.1.50:2022/KrakenServices', 
  
  // ** Produccion
  UrlLoginServer: 'https://sweetsrv.azurewebsites.net/Login',
  UrlApiProject: 'https://siriusapi.mcswusa.com/',
  UrlProject: 'https://sirius.mcswusa.com/',
  HereKey: '4SwQ02gYNvp2wjkhzmRElogtQ1aUzGMyHxjHgzwWJa4',
  

  // ** Kraken Produccion
  KrakenService: 'http://10.1.1.50:2022/KrakenServices',
  

  // ** PRE-Produccion
  // UrlLoginServer: 'https://sweetsrv.azurewebsites.net/LoginSandbox',
  // UrlApiProject: 'http://10.1.13.4:20272/', 
  // UrlProject: 'http://10.1.13.4:10272/', 
  

  // ** Kraken PRE-Produccion
  // KrakenService: 'http://10.1.1.50:2022/KrakenServices',
  
  
  // Debugging mode
  DebuggingMode: true,
};

function GetToken() {
  return sessionStorage.getItem('Token');
}

export { config, GetToken };

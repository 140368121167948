/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../utils/utils';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon';
import moment from 'moment';
import Highcharts from 'highcharts';
import swal from '@sweetalert/with-react';
import PageContent from 'layouts/PageContent';
import { Toolbar } from 'primereact/toolbar';
import { ProgressBar } from 'primereact/progressbar';
import FieldDropdown from 'components/Controls/FieldDropdown'; 
import FieldText from 'components/Controls/FieldText.jsx';
import FieldNumber from 'components/Controls/FieldNumber.jsx';
import FieldCheckbox from 'components/Controls/FieldCheckbox.jsx';
import FieldAutoComplete from 'components/Controls/FieldAutoComplete.jsx';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx' 
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator'; 
import { Dialog } from 'primereact/dialog';

const ProyectoMaestroDetalle = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['proyectoMaestroDetalle', 'common']);
  const [ClaProyecto, setClaProyecto] = useState(0);  
  const [errors, setErrors] = useState({});    
  const [ProyectoMaestroDetalleList, setProyectoMaestroDetalleList] = useState([]);    
  const [ProyectoCmbList, setProyectoCmbList] = useState([]);    
  
  useEffect(() => {
    setClaProyecto(props.ClaProyecto === undefined ? 0 : props.ClaProyecto);

    GetProyectoMaestroDetalle(props.ClaProyecto === undefined ? 0 : props.ClaProyecto);
    GetProyectoCmb(props.ClaProyecto === undefined ? 0 : props.ClaProyecto);
  }, [props.ClaProyecto]);

  const GetProyectoMaestroDetalle = (pClaProyecto) => {
    const ProyectoMaestroDet = `${config.UrlApiProject}ScoreCard/GetProyectoMaestroDetalle?ClaProyecto=${pClaProyecto===null?0:pClaProyecto}`;
    const paramsToService = {};
    callApi(ProyectoMaestroDet, 'GET', paramsToService, (response) => {
      setProyectoMaestroDetalleList(response.data.ProyectoMaestroDetalle);  
    });
  };

  const GetProyectoCmb = (pClaProyecto) => {
    const GetProyectoMaestroDetalleCmb = `${config.UrlApiProject}ScoreCard/GetProyectoMaestroDetalleCmb`;
    const paramsToService = {
      ClaProyecto: pClaProyecto
    };
    callApi(GetProyectoMaestroDetalleCmb, 'POST', paramsToService, (response) => {
      setProyectoCmbList(response.data.ProyectoMaestroDetalleCmb);
    });
  };

  const GetProyectoMaestroDetalleCambioValor = (props, pClaProyecto) => {
    const GetDetalleCambioValor = `${config.UrlApiProject}ScoreCard/GetProyectoMaestroDetalleCambioValor?ClaProyecto=${pClaProyecto===null?0:pClaProyecto}`;
    const paramsToService = {};
    callApi(GetDetalleCambioValor, 'GET', paramsToService, (response) => {
      // setProyectoMaestroDetalleList(response.data.CambioValor);  
      const saveArray= getCurrentProyectoMaestroRowValues(props);
      const updatedProducts = [...ProyectoMaestroDetalleList];
      const proyecto = ProyectoCmbList.filter(item => item.ClaProyecto ===pClaProyecto)[0];
      if(proyecto!==undefined){
        updatedProducts[props.rowData.RowIndex].NomProyectoGrid = proyecto.NomProyecto;
      }
      updatedProducts[props.rowData.RowIndex].ClaProyectoGrid = pClaProyecto;
      updatedProducts[props.rowData.RowIndex].NomResponsable = response.data.CambioValor[0].NomResponsable;
      updatedProducts[props.rowData.RowIndex].FechaEstimadaInicio = response.data.CambioValor[0].FechaEstimadaInicio;
      updatedProducts[props.rowData.RowIndex].FechaEstimadaFin = response.data.CambioValor[0].FechaEstimadaFin
      updatedProducts[props.rowData.RowIndex].DiasLinealesEstimados = response.data.CambioValor[0].DiasLinealesEstimados
      updatedProducts[props.rowData.RowIndex].PorcPeso = response.data.CambioValor[0].PorcPeso

      saveArray.ClaProyectoGrid = pClaProyecto; 
      saveArray.NomResponsable = response.data.CambioValor[0].NomResponsable; 
      saveArray.FechaEstimadaInicio = response.data.CambioValor[0].FechaEstimadaInicio; 
      saveArray.FechaEstimadaFin = response.data.CambioValor[0].FechaEstimadaFin; 
      saveArray.DiasLinealesEstimados = response.data.CambioValor[0].DiasLinealesEstimados; 
      saveArray.PorcPeso = response.data.CambioValor[0].PorcPeso; 
      setProyectoMaestroDetalleList(updatedProducts);

      console.log(response.data.CambioValor);
    });
  };

  const onNewProyectoMaestroDetalleClick = () => { 
    const newRow = {
      ClaProyectoGrid : null,
      NomProyectoGrid: "",
      NomResponsable: "",
      FechaEstimadaInicio: null,
      FechaEstimadaFin: null,
      DiasLinealesEstimados: null,
      PorcPeso: null,
      BajaLogica: 0,
      IsNewRow: true
    };
    setProyectoMaestroDetalleList([...ProyectoMaestroDetalleList, newRow]);
  }

  const onClaProyectoChange = (props, e ) => {    
    GetProyectoMaestroDetalleCambioValor(props, e.value);
  };
  const onPorcPesoChange = (props, value) => {
    const updatedField= [...ProyectoMaestroDetalleList];    
    updatedField[props.rowData.RowIndex][props.field] = value;

    setProyectoMaestroDetalleList(updatedField);
  };
  const getCurrentProyectoMaestroRowValues = (props) => {
    const updatedProducts = [...props.value];
    let RowValues ={}    
      RowValues ={
        ClaProyectoGrid: updatedProducts[props.rowIndex].ClaProyectoGrid,
        NomProyectoGrid: updatedProducts[props.rowIndex].NomProyectoGrid,
        NomResponsable: updatedProducts[props.rowIndex].NomResponsable,
        FechaEstimadaInicio: updatedProducts[props.rowIndex].FechaEstimadaInicio,
        FechaEstimadaFin: updatedProducts[props.rowIndex].FechaEstimadaFin,
        DiasLinealesEstimados: updatedProducts[props.rowIndex].DiasLinealesEstimados,        
        PorcPeso: updatedProducts[props.rowIndex].PorcPeso,
        BajaLogica: updatedProducts[props.rowIndex].BajaLogica
    }   
     
    return RowValues;
  }  

  const headerProyectoMaestroDetalle = (
    <Row>
      <Col lg={9} md={9} sm={12} xs={12}>
        {t('proyectoMaestroDetalle:HeadTittle')}
      </Col>
      <Col lg={3} md={3} sm={12} xs={12} className="text-right">
        <div className="p-fluid">
          <FieldButton
            icon="pi pi-plus"
            className="p-button-success no-border p-mr-2 rounded-button"
            onClick={onNewProyectoMaestroDetalleClick}
          />
        </div>
      </Col>
    </Row>
  );
  const footer  = ( 
    <> 
      <Row> 
        <Column></Column>  
        <Column></Column>  
        <Column></Column>  
        <Column></Column>  
        <Column></Column>  
        <Column></Column>          
        <Column className="text-center" footer={() => (<b> {t('common:Total')}</b>)}></Column>  
        <Column className="text-center" footer={() => (<b>{ (showActiveRows(ProyectoMaestroDetalleList).reduce((a,v) =>  a += v.PorcPeso === null ? 0 : v.PorcPeso , 0 )).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) }</b>)}></Column>             
      </Row>
    </>
  )

  const ClaProyectoEditor = ( props ) => {
    return (
      <div className="p-fluid">
        <FieldDropdown  
          name="ClaProyecto"
          value={props.rowData.ClaProyectoGrid}
          options={ProyectoCmbList}  
          optionValue="ClaProyecto"
          optionLabel="NomProyecto" 
          onChange={(e) => onClaProyectoChange(props,e)}
          errors={errors[`ProyectoMaestroDetalleList[${props.rowData.RowIndex}]`]} 
        />  
      </div>
    )
  };
  const PorcPesoEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldNumber
          name="PorcPeso" 
          value={props.rowData.PorcPeso}
          onChange={(e) => onPorcPesoChange(props, e.target.value)}
          errors={errors[`ProyectoMaestroDetalleList[${props.rowData.RowIndex}]`]}      
        />
      </div>
    );
  };
  const fieldsRequiredProyectoMaestroDetalleList = (rowData, props) =>  {  
    return (
      <>
        {errors[`ProyectoMaestroDetalleList[${props.rowData.RowIndex}]`] != null ? (<i className="fas fa-exclamation-triangle invalid-color"></i>) : ""}
      </>
    );
  }
  const FechaEstimadaInicioTemplate = (rowData) => {
    return (
      <>
        {rowData.FechaEstimadaInicio !== null
          ? moment(rowData.FechaEstimadaInicio).format('MM/DD/YYYY')
          : rowData.FechaEstimadaInicio}
      </>
    );
  };

  const FechaEstimadaFinTemplate = (rowData) => {
    return (
      <>
        {rowData.FechaEstimadaFin !== null
          ? moment(rowData.FechaEstimadaFin).format('MM/DD/YYYY')
          : rowData.FechaEstimadaFin}
      </>
    );
  };

  const PorcPesoTemplate = (rowData) => {
    return (
      <>
        <label>
          <b>{rowData.PorcPeso}</b> %
        </label>
      </>
    );
  };

  const actionBodyTemplateProyectoMaestro = (rowData, props) => {
    return (
      <> 
        <FieldButton icon="pi pi-trash" onClick={() => onConfirmBajaProyectoMaestroDetalle(rowData,props)} className="p-button-rounded p-button-warning" /> 
      </>
    );
  }

  const actionOpenAnotherProjectBodyTemplate = (rowData, props) => {
    return (
      <>            
        <i 
          className="fas fa-external-link-square-alt info-color"
          onClick={() => {onSelfOpenAnotherProjectClick(rowData)}}
        >
        </i>
      </>
    );
  }
  
  const onSelfOpenAnotherProjectClick = (rowData) => {    
    props.onOpenAnotherProjectClick(rowData.ClaProyectoGrid);
  }

  const onConfirmBajaProyectoMaestroDetalle = (row, props) => {        

      const ProyectoMaestroDetalleListnew = [...ProyectoMaestroDetalleList];      
      
      if  (ProyectoMaestroDetalleListnew[row.RowIndex].IsNewRow){
        ProyectoMaestroDetalleListnew.splice(row.RowIndex, 1);
      }
      else { 
        ProyectoMaestroDetalleListnew[row.RowIndex].BajaLogica = 1;
      } 
      
      setProyectoMaestroDetalleList(ProyectoMaestroDetalleListnew);    
  }
  const onSaveClick = async () => {  
   
    setErrors({});

    const value = {    
      ProyectoMaestroDetalleList 
    };

    const validator = new YupValidator(valitationScheme);
    const esValido = await validator.validate(value);
  
    if (!esValido){   
      setErrors(validator.errors); 
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
     
      return;
    }
      
    
    const arrayProyectoDetalle = [];
    let RowValues ={};
  
    for (let index = 0; index < ProyectoMaestroDetalleList.length; index++) {
      RowValues =  {
        ClaProyecto,
        ClaProyectoRel: ProyectoMaestroDetalleList[index].ClaProyectoGrid,
        PorcPeso: ProyectoMaestroDetalleList[index].PorcPeso,
        BajaLogica: ProyectoMaestroDetalleList[index].BajaLogica
      }
      arrayProyectoDetalle.push(RowValues);
    }

    const paramsServiceOwners = {    
      ClaProyecto,
      ProyectoMestroDetalleList: arrayProyectoDetalle
    }; 

    const urlWebServiceSaveProyectoMaestroDetalle = `${config.UrlApiProject}ScoreCard/SaveProyectoMaestroDetalle`; 
   
    callApi(urlWebServiceSaveProyectoMaestroDetalle, 'POST', paramsServiceOwners, (response) => {
      showSweetAlert('success', t('common:SuccessOperation'), 'success');

      if (props.onChange !== null && props.onChange !== 'undefined' && typeof(props.onChange) === 'function'){
        props.onChange();
      }
    });  
     
  }

  const valitationScheme = yup.object().shape({    
    ProyectoMaestroDetalleList: yup.array().of(
      yup.object().shape({
        ClaProyectoGrid: yup.string().required(t('common:RequiredField')).nullable(), 
        PorcPeso: yup.string().required(t('common:RequiredField')).nullable()
    }))
  });

  const showActiveRows = (rows) => {
    const activeRows = [];
    if (rows.length>0) {
      rows.forEach((item, index) => { 
        if (item.BajaLogica === 0) {
          item.RowIndex = index;
          activeRows.push({...item});
        }
      });
    } else {
      return [];
    }
    return activeRows; 
  };
  return (
    <>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12} className="text-right">                       
          <FieldButton label={t('common:Save')} icon="pi pi-save" className="p-button-success no-border p-mr-2 rounded-button" onClick={onSaveClick} /> 
        </Col> 
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
                &nbsp;
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <FieldDataTable
            value={showActiveRows(ProyectoMaestroDetalleList)}
            scrollable
            scrollHeight="330px"
            className="editable-cells-table"
            editMode="cell"
            selectionMode="single"
            header={headerProyectoMaestroDetalle}
            footerColumnGroup={footer}
          >
            <Column body={(rowData, props) => fieldsRequiredProyectoMaestroDetalleList(rowData, props)} headerStyle={{ width: '20px' }}></Column>
            <Column body={(rowData, props) => actionBodyTemplateProyectoMaestro(rowData, props)} headerStyle={{ width: '20px' }}></Column>
            <Column field="NomProyectoGrid" header={t('proyectoMaestroDetalle:Project')} headerStyle={{ width: '180px' }} columnKey="ClaProyectoGrid" editor={(props) => ClaProyectoEditor(props)}></Column>
            <Column body={(rowData, props) => actionOpenAnotherProjectBodyTemplate(rowData, props)} headerStyle={{ width: '20px' }}></Column>
            <Column field="NomResponsable" header={t('proyectoMaestroDetalle:Responsable')} columnKey="NomResponsable" headerStyle={{ width: '200px' }}></Column>
            <Column field="FechaEstimadaInicio" body={FechaEstimadaInicioTemplate} className="text-center" header={t('proyectoMaestroDetalle:StartDate')} columnKey="FechaEstimadaInicio" headerStyle={{ width: '110px' }}> </Column>
            <Column field="FechaEstimadaFin" body={FechaEstimadaFinTemplate} className="text-center" header={t('proyectoMaestroDetalle:EndDate')} columnKey="FechaEstimadaFin" headerStyle={{ width: '110px' }}> </Column>
            <Column field="DiasLinealesEstimados" className="text-center" header={t('proyectoMaestroDetalle:EstDays')} columnKey="DiasLinealesEstimados" headerStyle={{ width: '60px' }}> </Column>
            <Column field="PorcPeso" body={PorcPesoTemplate} className="text-center" header={t('proyectoMaestroDetalle:PorcWeight')} columnKey="PorcPeso" headerStyle={{ width: '60px' }} editor={(props) => PorcPesoEditor(props)}> </Column>
          </FieldDataTable>
        </Col>
      </Row>                        
    </>
  );
};
export default ProyectoMaestroDetalle;

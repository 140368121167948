/* eslint-disable jsx-a11y/anchor-is-valid */
import { config } from '../../../utils/config';
import { callApi, showSweetAlert } from '../../../utils/utils';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import moment from 'moment';
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon';
import swal from '@sweetalert/with-react';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import { Column } from 'primereact/column';
import FileLibraryUpLoadEdit from './FileLibraryUpLoadEdit.jsx'

const FileLibraryList = (props) => {
  const { t } = useTranslation(['dms', 'common']);
  const [LibraryFileList, setLibraryFileList] = useState([]);
  const [LibraryFileListDynamic, setLibraryFileListDynamic] = useState([]);  
  
  const [EsShowDialog, setEsShowDialog] = useState(false);
  const [IdLibraryFile, setIdLibraryFile] = useState(false);

  useEffect(() => {
     
    if (Object.keys(props.filtersData).length !== 0) {

        if (props.filtersData.isClearInfo){
          setLibraryFileList([]);
        }
        else{
          loadFileLibraryList(props.filtersData); 
        }
    } 
  }, [props.refresh, props.filtersData]);
 

  const loadFileLibraryList = (filtersData) => {
    const GetTeamPerformanceDevelopmentInfo = `${config.UrlApiProject}DMS/GetFileLibraryList`;
    const paramsToService = {
      ClaLibrary:filtersData.ClaLibrary,
      LibraryAttributeListDTO: filtersData.AttributeList
    };

    callApi(GetTeamPerformanceDevelopmentInfo, 'POST', paramsToService, (response) => {
      setLibraryFileList(response.data.LibraryFile);
      setLibraryFileListDynamic(response.data.LibraryFileColumnDynamic);      
    });
  };

  const renderDynamicValue = (columnName, row, ClaFormularioTipoCampo, props) => {      
    /* 
      1	Text
      2	Integer
      3	Numeric
      4	Date
      5	Date & Time
      6	Title / Seccion
      7	Long-Text
      8	Single-Selection
      9	Multi-Selection
      10	Single-Selection-Button
      11	Multi-Selection-Button
    */

    if (ClaFormularioTipoCampo === 2) {
        return (<>{ row[columnName] === null ? '' : row[columnName].toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</>);    
    }

    if (ClaFormularioTipoCampo === 3) {
      return (<>{ row[columnName] === null ? '' : row[columnName].toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</>);    
    }

    if (ClaFormularioTipoCampo === 4) {
      return (<>{ row[columnName]  === null ? '' : moment(row[columnName]).format('MM/DD/YYYY')}</>);    
    }

    if (ClaFormularioTipoCampo === 5) {
      return (<>{ row[columnName]  === null ? '' : moment(row[columnName]).format('MM/DD/YYYY HH:mm')}</>);    
    }
  
    return (
      <>
        { row[columnName] }
      </>
    );

  }

  const dynamicColumns = LibraryFileListDynamic.map((col,i) => {
    return (
      <Column 
        className="table-header-background-dark-green" 
        columnKey={col.NomLibraryAttribute}
        field={col.NomLibraryAttribute}
        header={col.NomLibraryAttribute}
        headerStyle={{ width: '250px'}}
        body={(rowData, props) => renderDynamicValue(col.NomLibraryAttribute, rowData, col.ClaFormularioTipoCampo, props)}
      >
      </Column>
);
  });

  const onEdit = (row) => {
    setEsShowDialog(true);
    setIdLibraryFile(row.IdLibraryFile);  
  };

  const confirmarEliminar = (pIdLibraryFile) => {
    const urlWebServiceSave = `${config.UrlApiProject}DMS/PostTraBSCDMLibraryFileInactive`;
    const paramsService = {
      IdLibraryFile: pIdLibraryFile,
    };
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {
      showSweetAlert(t('common:Exito'), t('common:SaveSuccess'), 'success'); 
      loadFileLibraryList(props.filtersData); 
    });
  };

  const onConfirmarEliminar = (Obj) => {    
    swal({
      buttons: {
        cancelar: {
          text: 'Cancel',
          value: 'cancel',
          className: 'rounded-button danger',
        },
        continuar: {
          text: 'Continue',
          value: 'continue',
          className: 'rounded-button light-green',
        },
      },
      content: confirmationMessageHTML,
    }).then((value) => {
      switch (value) {
        case 'continue':
          // run function
          confirmarEliminar(Obj.IdLibraryFile);
          break;
        default:
          break;
      }
    });
  };

  const confirmationMessageHTML = (
    <div>
      <SweatAlertCustomIcon
        title={t('dms:DeleteFileConfirmation')}
        letter={t('common:QuestionMark')}
      >
      </SweatAlertCustomIcon>
      <div>
        <b>{t('dms:MessageDeleteFileConfirmation')}</b>
      </div>
    </div>
  );

  const FechaUltimaModTemplate = (rowData) => {
    return (
      <>
        {rowData.FechaUltimaMod !== null
          ? moment(rowData.FechaUltimaMod).format('MM/DD/YYYY')
          : null}
      </>
    );
  }; 

  const LibraryColumn = (rowData, props) => {
    return (
      <>
        <Button
          icon="pi pi-pencil"
          className="p-highlight p-button-rounded no-border"          
          onClick={(e) => onEdit(rowData)}
        />
        &nbsp;
        <Button
          icon="pi pi-trash"
          className="p-button-rounded no-border p-button-warning"          
          onClick={(e) => onConfirmarEliminar(rowData)}
        />
      </>
    );
  };

  return (
    <>     
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <FieldDataTable
            value={LibraryFileList}
            scrollable
            className="editable-cells-table"
            editMode="cell"
            selectionMode="single"
          >
            <Column                
              body={(rowData, props) => LibraryColumn(rowData, props)}
              headerStyle={{ width: '80px' }}
            >
            </Column>
            <Column
              field="NomLibrary"
              columnKey="NomLibrary"
              header={t('dms:NomLibrary')}
              headerStyle={{ width: '180px' }}
            >
            </Column>
            <Column
              field="TitleFile"
              columnKey="TitleFile"
              header={t('dms:TitleFile')}
              headerStyle={{ width: '250px' }}
            >
            </Column>  
            <Column
              field="DescriptionFile"
              columnKey="DescriptionFile"
              header={t('dms:DescriptionFile')}
              headerStyle={{ width: '300px' }}
            >
            </Column>  
            {
              /* 
              <Column
                field="NomLibraryFileComplete"
                columnKey="NomLibraryFileComplete"
                header={t('dms:NomFile')}
                headerStyle={{ width: '250px' }}
              >
              </Column>
              */
            } 
            {dynamicColumns} 
            <Column
              field="NomColaborador"
              columnKey="NomColaborador"
              header={t('dms:CreateBy')}
              headerStyle={{ width: '115px' }}
            >
            </Column>
            <Column
              field="FechaUltimaMod"
              columnKey="FechaUltimaMod"
              header={t('dms:LastUpd')}
              headerStyle={{ width: '115px' }}
              body={FechaUltimaModTemplate}
            >
            </Column>        
            
          </FieldDataTable>
        </Col>
      </Row>
      {EsShowDialog ? (        
        <FileLibraryUpLoadEdit                    
          onCloseClick={() => setEsShowDialog(false)}
          showCloseButton={true}
          EsShowDialog={EsShowDialog}
          IdLibraryFile={IdLibraryFile}
        >
        </FileLibraryUpLoadEdit>
        ) : (
          <></>
        )}
    </>
  );
};

export default FileLibraryList;

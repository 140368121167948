import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next'; 
import PageContent from 'layouts/PageContent.jsx';
import DntNewsletter from './DntNewsletter';

const DnTNewsletterScreen = (props) => {
  const { t } = useTranslation(['MCSWNewsletter', 'common']);

  return (
    <>
      <PageContent title={t('MCSWNewsletter:DnTNewsletter')}>
        <DntNewsletter {...props} />
      </PageContent>
    </>
  )
}

export default DnTNewsletterScreen;
import React, { useEffect, useState } from 'react'; 
import { config } from '../../../utils/config';
import { callApi} from '../../../utils/utils';
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  CardTitle
} from 'reactstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import IconButton from '@material-ui/core/IconButton';
import TimelineIcon from '@material-ui/icons/Timeline';
import OpenInNewIcon from '@material-ui/icons/OpenInNew'; 
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ElementosCostos from "../ElementosCostos/ElementosCostos"; 
import ArticulosCelda from './ArticulosCelda.jsx'; 
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Tag } from 'primereact/tag';
import { useTranslation } from 'react-i18next';  
import FieldTag from '../../../components/Controls/FieldTag.jsx' 
import DailyOperationDashboard from "../../ProductionsDashboards/DailyOperationDashboard/DailyOperationDashboard";
import { Dialog } from 'primereact/dialog';   
import { Sidebar } from 'primereact/sidebar';
import Tooltip from '@material-ui/core/Tooltip';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';

/* ################################# START COMPONENT ################################# */

const CeldasCostos = (props) => {
 
  const { buttonLabel, className } = props;

  /* ################# SET STATE AREA ################# */
  const { t } = useTranslation(['formulario','common']);
  const [rowsGrid, setRowsGrid] = useState([]); 
  const [elementFilters , setelementFilters] = useState({...props});
  const [showEstadisticas, setshowEstadisticas] = useState(false); 
  const [SelectedCustomer, setSelectedCustomer] = useState(null);
  const [IdCelda, setIdCelda] = useState(null);
  const [modalArticulos, setModalArticulos] = useState(false);
  const [CeldaDetalleArticulosFilters, setCeldaDetalleArticulosFilters] = useState({...props});
  const [openDetailProduction, setOpenDetailProduction] = useState(false);
  const [dailyOperationFilters, setdailyOperationFilters] = useState({});
  
  /* ################# END SET STATE AREA ################# */
 
  useEffect(() => { 
    console.log('Celda costos useffect');
    const GetCeldasGridService = `${config.UrlApiProject}Costos/GetCeldasGrid`;
    const paramsToService = {
        anio: props.filters.Anio,
        anioMes: props.filters.AnioMes,
        claTipoElementoCosto: props.filters.ClaTipoElementoCosto,
        claDepartamento:props.filters.ClaDepartamento,
        claTipoCelda:props.filters.ClaTipoCelda,
        claCelda:props.filters.ClaCelda,
        claArticulo:props.filters.ClaArticulo,
        TipoConversionCost: props.filters.TipoConversionCost
      };
        setRowsGrid(null);
        setModalArticulos(false);
        callApi(GetCeldasGridService, 'POST', paramsToService, (response) => { 
          setIdCelda(null);
          setRowsGrid(response.data[0]); 
        });
    
  }, [props.filters]); 

  const header  = ( 
    <>
      <div className="row">
        <div className="col-12 col-lg-6 col-sm-12">{t('formulario:CellListInformation')} </div>
        <div className="col-12 col-lg-6 col-sm-12 text-right">            
          <Tag className="danger">80%</Tag>&nbsp;<Tag className="warning">17%</Tag>&nbsp;<Tag className="light">3%</Tag>&nbsp;<Tag className="success">(-)</Tag>
        </div>
      </div>
    </>
  )

  const TonsColumn = (row) => {  
      return (<>{row.MTons === null ? '' : row.MTons.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>);
  }

  const StandarCostColumn = (row) => {  
    return (<>${ row.StandarCost === null ? '' : row.StandarCost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>);
  }
  
  const RealCostColumn = (row) => {  
    return (<>${ row.RealCost === null ? '' : row.RealCost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>);
  }

  const AcumuladoRealCostColumn = (row) => {   
    return (<><Tag className={row.Clasificacion === 1 ? "danger" : row.Clasificacion === 2 ? "warning" : row.Clasificacion === 3 ? "light" : row.Clasificacion === 4 ? "success" : ""}>${row.AcumuladoRealCost === null ? '' : row.AcumuladoRealCost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Tag></>);
  }

  const onElementoDetalleCelda = (row) =>{
    const filters = { ...props.filters };
    filters.ClaCelda = row.IdCelda;
    filters.Cell = row.Cell;  
    setIdCelda(row.IdCelda);
    setelementFilters(filters);
    setshowEstadisticas(true); 
  }

  const onCeldaDetalleArticulos = (row) =>{
    const filters = { ...props.filters }; 
    filters.ClaCelda = row.IdCelda; 
    filters.Cell = row.Cell;
    setCeldaDetalleArticulosFilters(filters);  
    setModalArticulos(true); 

    console.log('onCeldaDetalleArticulos')
    console.log(filters);

  }

  const onShowInvoice = (data) => { 
    // setInvoiceCustomerProductFilters(data)
    setModalArticulos(false);
  }

  const ActionsColumn = (row) =>  {    
    return (
      <> 
        <Tooltip title={t('formulario:TooltipDetailByElement')}>
          <IconButton onClick={(e) => onElementoDetalleCelda(row)} size="small" color="primary"><TimelineIcon /></IconButton>
        </Tooltip> 
        <Tooltip title={t('formulario:TooltipProductsProduced')}>
          <IconButton size="small" onClick={(e) => onCeldaDetalleArticulos(row)} color="secondary"><OpenInNewIcon /></IconButton>
        </Tooltip> 
        <Tooltip title={t('formulario:TooltipProductionInfo')}>
          <IconButton size="small" onClick={(e) => onOpenModalDetail(row)} color="action"><EqualizerIcon /></IconButton>
        </Tooltip>
        
      </>
    );
  }

  const onRowSelect = (event) => {  
    onElementoDetalleCelda(event.data); 
  }

  const onRowUnselect = (event) =>{  
    //  setClaBucket(null);
  } 

  const onCloseModal = () => {
    setModalArticulos(!modalArticulos);
  }

  const closeModalDetail = () => {
    setOpenDetailProduction(false);
  }
  
  const onOpenModalDetail = (row) => {  
    const filters = {
      ClaCelda : row.IdCelda,
      ClaZona: null,
      FechaInicial: row.FechaInicial,
      FechaFinal: row.FechaFinal
    };  
    
    setdailyOperationFilters(filters);
    setOpenDetailProduction(true);
  }

  const dialogStyle  = ( 
    { width: '1120px', 
    borderLeft:"solid 2px #2791FF", 
    borderTop:"solid 2px #2791FF", 
    textAlign:"center" 
   }
 )

 const headerDialog  = ( 
   <>
     <div className="dialogHeader">
       <h1>DETAIL</h1>
     </div>  
   </>
)

  /* ################# START RENDER AREA ################# */
  if (props.showHide) {


    if (modalArticulos){
      return (
        <>  
          <Card>
            <CardHeader className="bg-card-header-grid">
              <CardTitle className="text-right">
                <FieldButton icon="pi pi-times" className="p-button-danger" onClick={() => onCloseModal()} />
              </CardTitle>
            </CardHeader>    
            <CardBody>  
              <ArticulosCelda onClose={onCloseModal} onClickInvoice={onShowInvoice} filters={CeldaDetalleArticulosFilters}></ArticulosCelda> 
            </CardBody> 
          </Card>  
        </>  
      );
    }
    
      return (
        <>   
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
              <FieldDataTable 
                value={rowsGrid} 
                header={header}  
                scrollable 
                scrollHeight="270px" 
                className="p-datatable-striped"
                selection={SelectedCustomer} 
                selectionMode="single" 
                onSelectionChange={e => setSelectedCustomer(e.value)} 
                onRowSelect={onRowSelect} 
                onRowUnselect={onRowUnselect}
              >  
                <Column body={ActionsColumn} header={t('formulario:ViewDetail')} headerStyle={{ width: '110px' }}></Column>  
                <Column field="Cell" columnKey="Cell" header={t('formulario:Cell')} headerStyle={{ width: '200px' }}></Column> 
                <Column field="CellType" columnKey="CellType" header={t('formulario:CellType')} headerStyle={{ width: '110px' }}></Column> 
                <Column body={TonsColumn} header={t('formulario:Tons')} headerStyle={{ width: '80px' }} columnKey="MTons"></Column>    
                <Column body={StandarCostColumn} header={t('formulario:StdCost')} headerStyle={{ width: '110px' }} columnKey="StandarCost"></Column>  
                <Column body={RealCostColumn} header={t('formulario:RealCost')} headerStyle={{ width: '110px' }} columnKey="RealCost"></Column>  
                <Column body={AcumuladoRealCostColumn} header={t('formulario:AcumTons')} headerStyle={{ width: '150px' }} columnKey="AcumuladoRealCost"></Column>  
              </FieldDataTable>
            </Col>  
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
                  &nbsp;
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
                  &nbsp;
            </Col>
          </Row>
          <Row>
            <Col>&nbsp;</Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
              <p>
                {
                IdCelda !== null ?   <ElementosCostos showHide={showEstadisticas} filters={elementFilters} />
                : <></>
              }
              </p>
            </Col>
          </Row>  
          <Sidebar visible={openDetailProduction} dismissable={false} baseZIndex={1000000} fullScreen onHide={closeModalDetail}>
            <DailyOperationDashboard filters={dailyOperationFilters} />   
          </Sidebar> 
        </>  
      );
    
 
  }
  if (!props.showHide) {
    return <></>;
  }
};

export default CeldasCostos;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';  
import { useTranslation } from 'react-i18next';   
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi} from '../../../utils/utils';
import FieldNumber from '../../../components/Controls/FieldNumber.jsx'
import FieldText from '../../../components/Controls/FieldText.jsx' 
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import FieldDropdown from 'components/Controls/FieldDropdown.jsx';

const PerformanceDevCriterio = ({
  rowsCriterio,
  onChange,
  errors,
  name
}) => { 
   
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']); 
  const [PerformanceCriterioList, setPerformanceCriterioList] = useState([]);

  useEffect(() => {
    GetPerformanceCriterioList();
  }, []);
 

const GetPerformanceCriterioList = () => { 
  const getPerformanceCriterio = `${config.UrlApiProject}HR/GetPerformanceCriterioCmb?Idioma=${''}`;     
  const paramsToService = {};
  callApi(getPerformanceCriterio, 'GET', paramsToService, (response) => {
    setPerformanceCriterioList(response.data.PerformanceCriterioList);
  });
};

const onPerformanceCriterioChange = (rowsCriterio) => { 
    onChange(rowsCriterio)
}

const orderByEditor = (props, rowData) => {
  return (
    <div className="p-fluid">
      <FieldNumber  
        value={props.rowData.Orden} 
        onChange={(e) => onorderByChange(props, e.target.value, rowData)}
      />  
    </div>
    
  );
}

const onorderByChange = (props, value ) => {  
  const updatedProducts = [...rowsCriterio];
  updatedProducts[props.rowData.RowIndex][props.field] = value;
  onPerformanceCriterioChange(updatedProducts); 
}; 
  
const addNewRow = () => {  
  const newRow = { 
    RowIndex: rowsCriterio.length,
    ClaPerformanceCriterio: null,
    IdPerformanceDev: null,
    BajaLogica: 0,
    DescripcionPregunta: "",
    Orden: null
  };   
  onPerformanceCriterioChange([...rowsCriterio, newRow]);
} 

const handleRemoveClick = (rowData) => {
  const currentList = [...rowsCriterio];
  currentList[rowData.RowIndex].BajaLogica = 1;
  onPerformanceCriterioChange(currentList); 
};

const showActiveRows = (rows) => {
  const activeRows = [];
  if (rows.length>0) {
    rows.forEach((item, index) => {
      if (item.BajaLogica === 0) {
        item.RowIndex = index;
        activeRows.push({...item});
      }
    });
  } else {
    return [];
  }
 
  return activeRows; 
};

const actionBodyTemplate = (rowData) => {
  return (
    <> 
      <FieldButton icon="pi pi-trash" onClick={() => handleRemoveClick(rowData)} className="p-button-rounded p-button-warning" /> 
    </>
  );
}

const fieldsRequired = (rowData) =>  { 
  return (
    <>
      {errors[`${name}[${rowData.RowIndex}]`] != null ? (<i className="fas fa-exclamation-triangle invalid-color"></i>) : ""}
    </>
  );
} 

const TituloCriterioEditor = (props) => {
  return (
    <div className="p-fluid">
      <FieldDropdown
        name="ClaPerformanceCriterio"
        value={props.rowData.ClaPerformanceCriterio}
        options={PerformanceCriterioList}
        optionValue="ClaPerformanceCriterio"
        optionLabel="TituloCriterio"
        onChange={(e) => onClaPerformanceCriterioChange(props, e)}
      />
    </div>
  );
};
 
const onClaPerformanceCriterioChange = (props, e) => {
  const _performanceCriterioList = [...rowsCriterio];
  const ClaPerformanceCriterio = PerformanceCriterioList.filter((item) => item.ClaPerformanceCriterio === e.value)[0];
  if (ClaPerformanceCriterio !== undefined) {
    _performanceCriterioList[props.rowData.RowIndex].ClaPerformanceCriterio = ClaPerformanceCriterio.ClaPerformanceCriterio;
    _performanceCriterioList[props.rowData.RowIndex].TituloCriterio = ClaPerformanceCriterio.TituloCriterio;
  } 
  onPerformanceCriterioChange(_performanceCriterioList);
}; 


  return (
    <> 
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <FieldButton label={t('common:New')} icon="pi pi-plus" className="p-button-success p-mr-2" onClick={addNewRow} />  
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}> 
            &nbsp;
        </Col> 
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}> 
          <FieldDataTable value={showActiveRows(rowsCriterio)} editMode="cell" className="editable-cells-table"> 
            <Column body={fieldsRequired} headerStyle={{ width: '20px' }}></Column>
            <Column
              field="TituloCriterio" 
              header={t('hr:TituloCriterio')} 
              headerStyle={{ width: '70%' }} 
              editor={(props) => TituloCriterioEditor(props)}
            >
            </Column>
            <Column
              field="Orden" 
              header={t('hr:OrderBy')} 
              headerStyle={{ width: '30%' }} 
              editor={(props) => orderByEditor(props)}
            >
            </Column>  
            <Column body={actionBodyTemplate} headerStyle={{ width: '70px' }}></Column>
          </FieldDataTable> 
        </Col>  
      </Row>
    </>
  );
}; 

export default PerformanceDevCriterio;



/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PageContent from '../../../layouts/PageContent';
import { Ripple } from 'primereact/ripple';
import { config } from '../../../utils/config';
import { Row, Col, Card, CardHeader, CardBody, Tooltip } from 'reactstrap';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Panel } from 'primereact/panel';
import { Column } from 'primereact/column';
import swal from '@sweetalert/with-react';
import { Avatar } from 'primereact/avatar';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import { SplitButton } from 'primereact/splitbutton';
import { ProgressBar } from 'primereact/progressbar';
import { callApi, getSessionItem, showSweetAlert } from '../../../utils/utils';
import FieldCalendar from '../../../components/Controls/FieldCalendar.jsx';
import FieldDropdown from '../../../components/Controls/FieldDropdown.jsx';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import FieldTextArea from '../../../components/Controls/FieldTextArea.jsx';
import FieldText from '../../../components/Controls/FieldText.jsx';
import FieldSelectButton from '../../../components/Controls/FieldSelectButton.jsx';
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon';
import moment from 'moment';
import FieldCheckbox from 'components/Controls/FieldCheckbox';
import FieldNumber from 'components/Controls/FieldNumber';
import { Divider } from 'primereact/divider';
import { Tree } from 'primereact/tree';
import TreeviewButton from 'components/Controls/TreeViewButton/TreeviewButton';
import FieldAutoComplete from 'components/Controls/FieldAutoComplete';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import '../../HR/Survey/SurveyManagement/Survey.css';

const LibraryEditAttribute = (props) => {
  const { t } = useTranslation(['dms', 'common']);
  const [errors, setErrors] = useState({});
  const [NomLibrary, setNomLibrary] = useState(null);
  const [NomLibraryDescripcion, setNomLibraryDescripcion] = useState(null);
  const [NomLibraryAttribute, setNomLibraryAttribute] = useState(null);
  const [ClaFormularioTipoCampo, setClaFormularioTipoCampo] = useState(null);
  const [TipoCampoCmb, setTipoCampoCmb] = useState([]);
  const [EsHabilitarMultiSeleccion, setEsHabilitarMultiSeleccion] = useState(0);
  const [CamposMultiSeleccion, setCamposMultiSeleccion] = useState([]);
  const [EsObligatorio, setEsObligatorio] = useState(false);
  const [AttributeMultiSeleccion, setAttributeMultiSeleccion] = useState([]);
  const [NomFormularioTipoCampo, setNomFormularioTipoCampo] = useState(null);
  const [CamposMultiSeleccionById, setCamposMultiSeleccionById] = useState([]);
  const [UsuarioRoles, setUsuarioRoles] = useState([]);
  const [ClaFolder, setClaFolder] = useState([]);
  const [ClaLibrary, setClaLibrary] = useState(null);
  const [ClaLibraryAttribute, setClaLibraryAttribute] = useState(null);
  const [Order, setOrder] = useState(null);

  useEffect(() => {
    obtenerTipoCampo();
    setClaLibrary(props.ClaLibrary === undefined ? null : props.ClaLibrary);
    setNomLibraryAttribute(
      props.itemAtributoEditar.NomLibraryAttribute === undefined
        ? null
        : props.itemAtributoEditar.NomLibraryAttribute
    );
    setClaFormularioTipoCampo(
      props.itemAtributoEditar.ClaFormularioTipoCampo === undefined
        ? null
        : props.itemAtributoEditar.ClaFormularioTipoCampo
    );
    setClaLibraryAttribute(
      props.itemAtributoEditar.ClaLibraryAttribute === undefined
        ? null
        : props.itemAtributoEditar.ClaLibraryAttribute
    );
    setEsObligatorio(
      props.itemAtributoEditar.EsObligatorio === undefined
        ? false
        : props.itemAtributoEditar.EsObligatorio
    );
    setOrder(
      props.itemAtributoEditar.Order === undefined
        ? false
        : props.itemAtributoEditar.Order
    );

    setCamposMultiSeleccion(
      props.CamposMultiSeleccionParam === undefined ? [] : props.CamposMultiSeleccionParam
    );
    setEsHabilitarMultiSeleccion(
      props.EsHabilitarMultiSeleccion === undefined ? 0 : props.EsHabilitarMultiSeleccion
    );
    if (props.ClaLibrary !== null) {
      GetLibraryAttribute(props.ClaLibrary);
    }
    console.log(props);
  }, [props.Filters, props.ClaLibrary, props.itemAtributoEditar, props.CamposMultiSeleccion]);

  const obtenerTipoCampo = () => {
    const getTipoCampoCmb = `${config.UrlApiProject}HR/GetTipoCampoCmb`;
    const paramsToService = {};
    callApi(getTipoCampoCmb, 'GET', paramsToService, (response) => {
      setTipoCampoCmb(response.data.TipoCampo);
    });
  };
  const GetLibraryAttribute = (pClaLibrary) => {
    const getLibraryAttribute = `${config.UrlApiProject}DMS/GetLibraryAttribute?ClaLibrary=${pClaLibrary}`;
    const paramsToService = {};
    callApi(getLibraryAttribute, 'GET', paramsToService, (response) => {
      setAttributeMultiSeleccion(response.data.ListAttibute);
      GetLibraryAttributeOpciones(pClaLibrary, response.data.ListAttibute);
    });
  };
  const GetLibraryAttributeOpciones = (pClaLibrary, pListAttribute) => {
    const getLibraryAttribute = `${config.UrlApiProject}DMS/GetLibraryAttributeOpciones?ClaLibrary=${pClaLibrary}`;
    const paramsToService = {};
    callApi(getLibraryAttribute, 'GET', paramsToService, (response) => {
      bindAttributeOpciones(response.data.ListOpciones, pListAttribute);
    });
  };

  const bindAttributeOpciones = (attributeOpciones, pListAttribute) => {
    const arrayOpciones = [];
    let RowValues = {};
    for (let i = 0; i < pListAttribute.length; i++) {
      const arrayOpcciones = attributeOpciones.filter(
        (opt) => opt.ClaLibraryAttribute === pListAttribute[i].ClaLibraryAttribute
      );
      RowValues = {
        CamposMultiSeleccion: arrayOpcciones,
        RowIndex: i,
        BajaLogica: 0,
      };
      arrayOpciones.push(RowValues);
    }
    setCamposMultiSeleccionById(arrayOpciones);
  };

  const onNomLibraryAttributeChange = (value) => {
    const newErrors = { ...errors };
    delete newErrors.NomLibraryAttribute;
    setErrors(newErrors);
    setNomLibraryAttribute(value);
  };

  const onChangeTipoCampo = (props, e) => {
    const newErrors = { ...errors };
    delete newErrors.ClaFormularioTipoCampo;
    setErrors(newErrors);

    if (e.target.value === null || typeof e.target.value === 'undefined') {
      setClaFormularioTipoCampo(null);
      setEsHabilitarMultiSeleccion(0);
      setCamposMultiSeleccion([]);
      return;
    }

    const [EsHabilitarOpciones] = TipoCampoCmb.filter(
      (opc) => opc.ClaFormularioTipoCampo === e.target.value
    );

    setNomFormularioTipoCampo(EsHabilitarOpciones.NomFormularioTipoCampo);
    setClaFormularioTipoCampo(e.target.value);
 
     
    if (EsHabilitarOpciones.EsHabilitarOpciones === 1) {
      setEsHabilitarMultiSeleccion(1);
 
      if (CamposMultiSeleccion.length > 0){
        CamposMultiSeleccion.forEach(element => {
          element.BajaLogica = 0;
        });
      }

      setCamposMultiSeleccion(CamposMultiSeleccion);  
    } else {

      if (CamposMultiSeleccion.length > 0){
        CamposMultiSeleccion.forEach(element => {
          element.BajaLogica = 1;
        });
      }

      setCamposMultiSeleccion(CamposMultiSeleccion);
      setEsHabilitarMultiSeleccion(0);
    }  
  };

  const onChangeObligarotio = (props, e) => {
    setEsObligatorio(!!e.checked);
  };

  const showActiveRows = (rows) => {
    const activeRows = [];
    if (rows !== undefined) {
      if (rows.length > 0) {
        rows.forEach((item, index) => {
          if (!item.BajaLogica || item.BajaLogica === 0 || item.BajaLogica === undefined) {
            item.BajaLogica = 0;
            item.RowIndex = index;
            activeRows.push({ ...item });
          }
        });
      } else {
        return [];
      }
    }

    return activeRows;
  };

  const onDescripcionOpcRespuestaPreguntaChange = (value, item) => {
    const copyCamposMultiSeleccion = [...CamposMultiSeleccion];
    copyCamposMultiSeleccion[item.RowIndex].NomLibraryAttributeOption = value;
    setCamposMultiSeleccion(copyCamposMultiSeleccion);
  };
  const onOrderOptionChange = (value, item) => {
    const copyCamposMultiSeleccion = [...CamposMultiSeleccion];
    copyCamposMultiSeleccion[item.RowIndex].Order = value;
    setCamposMultiSeleccion(copyCamposMultiSeleccion);
  };
  

  const onRemoveRespuestaPreguntaClick = (item) => {
    const copyCamposMultiSeleccion = [...CamposMultiSeleccion];
    copyCamposMultiSeleccion[item.RowIndex].BajaLogica = 1;
    setCamposMultiSeleccion(copyCamposMultiSeleccion);
  };

  const valitationSchemeAttribute = yup.object().shape({
    NomLibraryAttribute: yup.string().required(t('common:RequiredField')).nullable(),
    ClaFormularioTipoCampo: yup.mixed().required(t('common:RequiredField')).nullable(),
    Order: yup.mixed().required(t('common:RequiredField')).nullable(),
    CamposMultiSeleccion: yup.array().of(
      yup.object().shape({
        NomLibraryAttributeOption: yup.string().when('BajaLogica', {
          is: 0,
          then: yup.string().trim().required(t('common:RequiredField')).nullable(),
          otherwise: yup.string().nullable(),
        }),
      })
    ),
  });

  const valitationSchemeSave = yup.object().shape({
    NomLibrary: yup.string().required(t('common:RequiredField')).nullable(),
    NomLibraryDescripcion: yup.string().required(t('common:RequiredField')).nullable(),
  });

  const onCancel = () => {
    if (props.onHide !== undefined && typeof props.onHide === 'function') {
      props.onHide();
    }
  };
  const AddNewAttributeArregloMultiSel = async () => {
    const newRow = {
      RowIndex: AttributeMultiSeleccion !== undefined ? AttributeMultiSeleccion.length : 0,
      ClaLibraryAttribute,
      NomLibraryAttribute,
      ClaFormularioTipoCampo,
      EsObligatorio,
      BajaLogica: 0,
      NomFormularioTipoCampo,
      Order
    };

    const value = {
      NomLibraryAttribute,
      ClaFormularioTipoCampo,
      CamposMultiSeleccion,
      Order
    };

    const validator = new YupValidator(valitationSchemeAttribute);
    const esValido = await validator.validate(value);
    if (!esValido) {
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      return;
    }

    setAttributeMultiSeleccion([...AttributeMultiSeleccion, newRow]);

    const claFormularioTipoCampo = TipoCampoCmb.filter(
      (item) => item.ClaFormularioTipoCampo === ClaFormularioTipoCampo
    )[0];

    const filterApply = {
      ClaLibraryAttribute,
      NomLibraryAttribute,
      ClaFormularioTipoCampo,
      NomFormularioTipoCampo: claFormularioTipoCampo.NomFormularioTipoCampo,
      EsObligatorio,
      CamposMultiSeleccionByIdNew: CamposMultiSeleccion,
      Order
    };

    if (props.SaveOpcionesEditor) {
      props.onHide();
      props.SaveOpcionesEditor(filterApply);
    }

    // setDescripcion(null);
    // setClaFormularioTipoCampo(null);
    // setCamposMultiSeleccion([]);
    // setEsHabilitarMultiSeleccion(0);
    // setEsObligatorio(false);
  };

  const readChildNode = (nodesArray, node) => {
    nodesArray.map((element, key) => {
      if (element.key === node) {
        selectedNodeElement = element;
        return element;
      }

      if (element.children.length > 0) {
        return readChildNode(element.children, node);
      }

      return null;
    });

    return selectedNodeElement;
  };

  const AddNewItemArregloMultiSel = () => {
    const newRow = {
      RowIndex: CamposMultiSeleccion !== undefined ? CamposMultiSeleccion.length : 0,
      ClaLibraryAttributeOption: null,
      NomLibraryAttributeOption: null,
      ClaLibrary: null,
      ClaLibraryRespuesta: null,
      BajaLogica: 0,
      Order:null,
    };

    setCamposMultiSeleccion([...CamposMultiSeleccion, newRow]);
  };

  const onOrderChange = (value) => {
    const newErrors = { ...errors };
    delete newErrors.Order;
    setErrors(newErrors);
    setOrder(value);
  };

  return (
    <>
      <div className="survey-edit-container">
        <div className="survey-edit-body">
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} sm={12} xs={12}>
              <span className="survey-edit-label">{t('dms:Attribute')}</span>
              <div className="p-fluid">
                <FieldText
                  name="NomLibraryAttribute"
                  value={NomLibraryAttribute}
                  onChange={(e) => onNomLibraryAttributeChange(e.target.value)}
                  autoFocus
                  maxLength={500}
                  errors={errors}
                />
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12} className="p-3">
              <span className="survey-edit-label">{t('dms:AttributeCategory')}</span>
              <div className="p-fluid">
                <FieldDropdown
                  name="ClaFormularioTipoCampo"
                  value={ClaFormularioTipoCampo}
                  options={TipoCampoCmb}
                  optionValue="ClaFormularioTipoCampo"
                  optionLabel="NomFormularioTipoCampo"
                  onChange={(e) => onChangeTipoCampo(props, e)}
                  errors={errors}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} sm={12} xs={12}>
              <div className="p-fluid">
                <span className="survey-edit-label">{t('dms:AttributeRequired')}</span>
                &nbsp;
                <FieldCheckbox
                  inputId={EsObligatorio}
                  checked={EsObligatorio}
                  onChange={(e) => onChangeObligarotio(props, e)}
                />
                &nbsp;
                <i
                  className="fas fa-info-circle required-question secondary-color"
                  tooltip={t('dms:AttributeRequiredHelp')}
                >
                </i>
                <Tooltip target=".required-question" content={t('dms:AttributeRequiredHelp')} />
              </div>
            </Col>
            <Col className="lg={6} md={6} sm={12} xs={12}">
              <span className="survey-edit-label">{t('dms:Order')}</span>
              <div className="p-fluid">
                <FieldNumber
                  name="Order"
                  minFractionDigits={2}
                  value={Order}
                  onChange={(e) => {onOrderChange(e.target.value)}}
                  errors={errors}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
            </Col>
          </Row>
          {EsHabilitarMultiSeleccion === 1 ? (
            <>
              <Divider></Divider>
              <Row>
                <Col>
                  <span className="h3">{t('dms:ChoiceOptionTitle')}</span>
                  &nbsp;
                  <FieldButton
                    icon="pi pi-plus"
                    className="light-green no-border p-mr-2 p-button-rounded"
                    onClick={AddNewItemArregloMultiSel}
                  />
                  &nbsp;
                  <i
                    className="fas fa-info-circle new-option-choice secondary-color"
                    tooltip={t('dms:AttributeRequiredHelp')}
                  >
                  </i>
                  <Tooltip target=".new-option-choice" content={t('dms:AttributeRequiredHelp')} />
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  &nbsp;
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ width: '70%' }}>{t('dms:Description')}</th>
                        <th style={{ width: '10%' }}>{t('dms:Order')}</th>
                        <th style={{ width: '10%' }}>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {showActiveRows(CamposMultiSeleccion).map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <div className="p-fluid">
                                <FieldText
                                  name="NomLibraryAttributeOption"
                                  value={item.NomLibraryAttributeOption}
                                  onChange={(e) =>
                                    onDescripcionOpcRespuestaPreguntaChange(e.target.value, item)}
                                  maxLength={500}
                                  errors={errors[`CamposMultiSeleccion[${item.RowIndex}]`]}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="p-fluid">
                                <FieldNumber
                                  name="OrderOption"
                                  minFractionDigits={2}
                                  value={item.Order}
                                  onChange={(e) => {onOrderOptionChange(e.target.value, item)}}
                                  errors={errors}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="p-fluid">
                                <FieldButton
                                  hidden={showActiveRows(CamposMultiSeleccion).length === 0}
                                  icon="pi pi-trash"
                                  className="p-button-rounded p-button-warning"
                                  onClick={() => onRemoveRespuestaPreguntaClick(item)}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  &nbsp;
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="survey-toolbar-options">
          <Row>
            <Col lg={12} md={12} sm={12} xs={12} className="text-left">
              <FieldButton
                icon="fas fa-times"
                label={t('common:Cancel')}
                className="warning no-border p-mr-2 rounded-button"
                onClick={onCancel}
              />
              &nbsp;
              <FieldButton
                label={t('common:Save')}
                icon="pi pi-save"
                className="p-button-success no-border p-mr-2 rounded-button"
                onClick={AddNewAttributeArregloMultiSel}
              />
            </Col>
          </Row>
        </div>
      </div>

      <br></br>
    </>
  );
};

export default LibraryEditAttribute;

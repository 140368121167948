/* eslint-disable jsx-a11y/label-has-associated-control */
import React , {useEffect, useState} from 'react';  
import { config } from '../../utils/config';
import { callApi, callKrakenApi, showSweetAlert } from '../../utils/utils';  
import '../../assets/css/PivotGridBase.css'; 
import FieldButton from '../../components/Controls/FieldButton/FieldButton.jsx';  
import { useTranslation } from 'react-i18next'; 
import FieldDropdown from '../../components/Controls/FieldDropdown.jsx' 
import FieldAutoComplete from '../../components/Controls/FieldAutoComplete.jsx'
import FieldSelectButton from '../../components/Controls/FieldSelectButton.jsx' 
import * as yup from 'yup';
import YupValidator from '../../utils/YupValidator';
import SearchIcon from '@material-ui/icons/Search';
import { Ripple } from 'primereact/ripple';
import { Panel } from 'primereact/panel';
import Joyride, { CallBackProps, STATUS, Step, StoreHelpers } from 'react-joyride'; 
import StepsTourCostAnalysisSummary from './StepsTourCostAnalysisSummary'
import StepsTourCostAnalysisDetail from './StepsTourCostAnalysisDetail'
import StepsTourCostAnalysisDefault from './StepsTourCostAnalysisDefault'
import { Row, Col, Card, CardHeader, CardBody, } from 'reactstrap';    

const AnalisisCostosFilters =  (props) => {
     
    const { t } = useTranslation(['formulario', 'common']);
    const [Anio, setAnio] = useState(props.filters.Anio !== undefined ? props.filters.Anio : null);
    const [Mes, setMes] = useState();
    const [Target, setTarget] = useState(props.filters.Target !== undefined ? props.filters.Target : null);
    const [ClaMes, setClaMes] = useState();
    const [OpcionesAnios, setOpcionesAnios] = useState([]);
    const [OpcionesMeses, setOpcionesMeses] = useState([]); 
    const [ClaDepartamento, setClaDepartamento] = useState();
    const [OpcionesDepartamentos, setOpcionesDepartamentos] = useState([]); 
    const [OpcionesTipoCelda, setOpcionesTipoCelda] = useState([]); 
    const [OpcionesCelda, setOpcionesCelda] = useState([]); 
    const [ClaCelda , setClaCelda ] = useState();
    const [ClaTipoCelda , setClaTipoCelda ] = useState(); 
    const [ClaArticulo , setClaArticulo ] = useState();
    const [FilteredArticulo, setFilteredArticulo] = useState([]);
    const [errors, setErrors] = useState({});  
    const valitationScheme = yup.object().shape({
      Anio: yup.string().required(t('common:RequiredField')).nullable(),
      Mes: yup.string().required(t('common:RequiredField')).nullable(),
      TipoConversionCost: yup.mixed().required(t('common:RequiredField')).nullable()
    });
    
    const [OpcionesConversionCost, setOpcionesConversionCost] = useState([{ value: 1, name: t('formulario:ConversionCost')}, { value: 2, name: t('formulario:ManufactureCost') }]);
    const [TipoConversionCost, setTipoConversionCost] = useState(1);
    
    const [IsRunTour, setIsRunTour] = useState(false); 
    
      useEffect(() => {   
        let urlWebService = `${config.UrlApiProject}Costos/GetAnios`;  
        let paramsService = null; 
        callApi(urlWebService, 'GET', paramsService, (response) => {
          setOpcionesAnios (response.data.Anios);
          paramsService = {Anio:null};
          if (response.data.Anios.length > 0){
            setAnio(response.data.Anios[0].ClaAnio);
            paramsService.Anio = response.data.Anios[0].ClaAnio;
          } 
          urlWebService = `${config.UrlApiProject}Costos/GetMeses`; 
          callApi(urlWebService, 'POST', paramsService, (response) => {
            setOpcionesMeses(response.data.Meses); 
    
            urlWebService = `${config.UrlApiProject}departamento/getDepartamentos`; 
            callApi(urlWebService, 'GET', paramsService, (response) => {
              setOpcionesDepartamentos(response.data.Departamentos); 
            });  
          });
        })
      }, []);
    
      const onYearChange = (yearId) => {  
        const newErrors = { ...errors } 
        delete newErrors.Anio;   
        setErrors(newErrors)  
        setAnio(yearId);
        let paramsService = null; 
        let urlWebService = ``;  
        paramsService = {Anio:yearId};
        urlWebService = `${config.UrlApiProject}Costos/GetMeses`;  
        if (props.onChangeFilter !== null){
            props.onChangeFilter();
        }
 
        callApi(urlWebService, 'POST', paramsService, (response) => {
          setMes(null);
          setOpcionesMeses(response.data.Meses); 
        });
    };
    
    const onMonthChange = (anioMes,e) => {  
      const newErrors = { ...errors } 
      delete newErrors.Mes;   
      setErrors(newErrors) 
      setMes(anioMes); 
      let value = 0;
      OpcionesMeses.map((a) => { 
        if(a.AnioMes === anioMes){
          value = a.ClaMes;
        }
        return 0;
      });
      setClaMes(value); 
      if (props.onChangeFilter !== null){
        props.onChangeFilter();
      }
    }; 

    const onChangeDepartament = (departamentId) => { 
      setClaDepartamento(departamentId); 
      if (props.onChangeFilter !== null){
        props.onChangeFilter();
      }
      if(departamentId===undefined){
        setClaTipoCelda(null);
        setOpcionesTipoCelda(null); 
        setOpcionesCelda(null); 
        setClaCelda(null);  
        return;
      }  
      let paramsService = null; 
      let urlWebService = ``;  
      paramsService = {};
      urlWebService = `${config.UrlApiProject}celda/GetTipoCeldas?ClaDepartamento=${departamentId}`; 
      callApi(urlWebService, 'GET', paramsService, (response) => {
        setClaTipoCelda(null);
        setOpcionesTipoCelda(null); 
        setOpcionesCelda(null); 
        setClaCelda(null);  
        setOpcionesTipoCelda(response.data.TipoCelda);   
      });
    };
    
    const onChangeTipoCelda = (tipoCeldaId) => {
      setClaTipoCelda(tipoCeldaId);
      if (props.onChangeFilter !== null){
        props.onChangeFilter();
      }
      if(tipoCeldaId===undefined){
        setOpcionesCelda(null);  
        setClaCelda(null);  
        return;
      }  
      let paramsService = null; 
      let urlWebService = ``;
      paramsService = {}; 
      urlWebService = `${config.UrlApiProject}celda/GetCeldas?ClaDepartamento=${ClaDepartamento}&ClaTipoCelda=${tipoCeldaId}`; 
      callApi(urlWebService, 'GET', paramsService, (response) => {
        setOpcionesCelda(response.data.Celdas); 
        setClaCelda(null);  
      });
    };
    
    const onChangeCelda = (celdaId) => {   
      setClaCelda(celdaId); 
      if (props.onChangeFilter !== null){
        props.onChangeFilter();
      } 
    };
    
    const onChangeArticulo = (e) => {  
      setClaArticulo(e.value)
      if (props.onChangeFilter !== null){
        props.onChangeFilter();
      } 
    }
    
    const FilterArticulo = (event) => {
      const paramsService = {};
      const urlWebService = `${config.UrlApiProject}articulo/GetArticulos?Valor=${event.query}&Tipo=2`;
      callApi(urlWebService, 'GET',  paramsService, (response) => {  
        setFilteredArticulo(response.data.Articulos);
      });    
    } 
    
    const onApply = async ( ) => {  
      const value = {
        Anio,
        Mes,
        TipoConversionCost
      }  

      const validator = new YupValidator(valitationScheme);
      const esValido = await validator.validate(value);
      
      if (!esValido){ 
        setErrors(validator.errors); 
        showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
        return;
      }
      const filtersData = {}
      filtersData.Anio              = Anio === undefined ? null : Anio;
      filtersData.AnioMes           = Mes === undefined ? null : Mes; 
      filtersData.Mes               = ClaMes === undefined ? null : ClaMes;
      filtersData.ClaDepartamento   = ClaDepartamento === undefined ? null : ClaDepartamento;
      filtersData.ClaCelda          = ClaCelda === undefined ? null : ClaCelda;
      filtersData.ClaTipoCelda      = ClaTipoCelda === undefined ? null : ClaTipoCelda;
      filtersData.ClaArticulo       = ClaArticulo === null || ClaArticulo === undefined  ? null : ClaArticulo.ClaArticulo;
      filtersData.Target            = Target === undefined ? null : Target;
      filtersData.TipoConversionCost = TipoConversionCost === undefined ? null : TipoConversionCost;
      if (props.onSearch !=  null){ 
        props.onSearch(filtersData);
      }
    };

    const filterHeaderTemplate = (options) => {
        const toggleIcon = options.collapsed ? 'pi pi-chevron-down text-white' : 'pi pi-chevron-up text-white';
        const className = `${options.className} p-jc-start`;
        const titleClassName = `${options.titleClassName} p-pl-1`;

        return (
          <div className="card-header">
            <div className="p-toolbar p-component">
              <div className="p-toolbar-group-left">
                <div className="card-title">
                  <FieldButton className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                  </FieldButton>
                  <span className={titleClassName}>
                    {t('common:Filters')}
                  </span>
                </div>  
              </div>
             
              <div className="p-toolbar-group-right">
                <FieldButton className="p-button p-button-icon-only p-highlight" onClick={onApply}>
                  <i className="pi pi-search"></i>
                </FieldButton>
                &nbsp;
                <FieldButton className="p-button p-button-icon-only p-button-help" onClick={onStartTour}> 
                  <i className="fa fa-question"></i>
                </FieldButton>
              </div>
            </div> 
          </div>
        )
    }

    const handleJoyrideCallback = (data) => {
      const { status, type } = data;
      const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
  
      if (finishedStatuses.includes(status)) {
        setIsRunTour(false);
      }  
    };

    const onStartTour = () =>{ 
      setIsRunTour(true);
    }

    const onchangeTipoConversionCost = ( value ) => {  
      const newErrors = { ...errors } 
      delete newErrors.TipoConversionCost;   
      setErrors(newErrors)  
      setTipoConversionCost(value); 
      
      if (props.onChangeFilter !== null){
        props.onChangeFilter();
      }
    };  
  

     
    return (
      <>   

        { props.TourType === 1 ? (
          <Joyride
            callback={handleJoyrideCallback}
            continuous={true}  
            run={IsRunTour}
            scrollToFirstStep={true}
            showProgress={true}
            showSkipButton={true}
            steps={StepsTourCostAnalysisSummary.steps}
            disableBeacon={true}
            styles={{
              options: {
                 // modal arrow and background color
                 arrowColor: "#eee",
                 backgroundColor: "#eee", 
                 // button color
                 primaryColor: "mediumaquamarine",
                 // text color
                 textColor: "#333", 
                 // zindex of modal
                 zIndex: 1000
             }
          }}
          />
      )  
      : props.TourType === 2 ? (
        <Joyride
          callback={handleJoyrideCallback}
          continuous={true}  
          run={IsRunTour}
          scrollToFirstStep={true}
          showProgress={true}
          showSkipButton={true}
          steps={StepsTourCostAnalysisDetail.steps}
          disableBeacon={true}
          styles={{
          options: {
             // modal arrow and background color
             arrowColor: "#eee",
             backgroundColor: "#eee", 
             // button color
             primaryColor: "mediumaquamarine",
             // text color
             textColor: "#333", 
             // zindex of modal
             zIndex: 1000
         }
      }}
        />
       ) : (
         <Joyride
           callback={handleJoyrideCallback}
           continuous={true}  
           run={IsRunTour}
           scrollToFirstStep={true}
           showProgress={true}
           showSkipButton={true}
           steps={StepsTourCostAnalysisDefault.steps}
           disableBeacon={true}
           styles={{
       options: {
          // modal arrow and background color
          arrowColor: "#eee",
          backgroundColor: "#eee", 
          // button color
          primaryColor: "mediumaquamarine",
          // text color
          textColor: "#333", 
          // zindex of modal
          zIndex: 1000
      }
   }}
         />
) }
        <Panel header="Filters" toggleable headerTemplate={filterHeaderTemplate} className="AC-Step-1"> 
          <div className="row">
            &nbsp;
          </div>
          <div className="p-fluid p-grid">
            <div className="p-grid p-field col-lg-3 "> 
              <div className="p-field col-lg-6">
                <FieldDropdown  
                  name="Anio"
                  value={Anio} 
                  options={OpcionesAnios}  
                  optionValue="ClaAnio"
                  optionLabel="NomAnio" 
                  onChange={(e) => onYearChange(e.value)}
                  label={t('common:Year')}
                  errors={errors}
                />   
              </div>
              <div className="p-field col-lg-6 ">
                <FieldDropdown  
                  name="Mes"
                  value={Mes} 
                  options={OpcionesMeses}  
                  optionValue="AnioMes"
                  optionLabel="NomMes" 
                  onChange={(e) => onMonthChange(e.value,e)}
                  label={t('common:Month')}
                  errors={errors}
                />   
              </div>
            </div>
             
            <div className="p-grid p-field  col-lg-3 "> 
              <div className="p-field col-lg-6">
                <FieldDropdown  
                  value={ClaDepartamento} 
                  options={OpcionesDepartamentos}  
                  optionValue="ClaDepartamento4"
                  optionLabel="NomDepartamento4" 
                  onChange={(e) => onChangeDepartament(e.value)}
                  label={t('formulario:Department')}
                />   
              </div>
              <div className="p-field col-lg-6">
                <FieldDropdown  
                  value={ClaTipoCelda} 
                  options={OpcionesTipoCelda}  
                  optionValue="ClaTipoCelda4"
                  optionLabel="NomTipoCelda4" 
                  onChange={(e) => onChangeTipoCelda(e.value)}
                  label={t('formulario:CellType')}
                />    
              </div>
            </div> 

            <div className="p-grid p-field  col-lg-3 "> 
              <div className="p-field col-lg-12">
                <FieldDropdown  
                  value={ClaCelda} 
                  options={OpcionesCelda}  
                  optionValue="ClaCelda"
                  optionLabel="NomCelda" 
                  onChange={(e) => onChangeCelda(e.value)}
                  label={t('formulario:Cell')}
                />    
              </div>
            </div> 
            <div className="p-grid p-field  col-lg-3 "> 
              <div className="p-field col-lg-12">
                <FieldAutoComplete
                  dropdown
                  name="ClaArticulo"
                  label={t('formulario:Item')}
                  value={ClaArticulo} 
                  onChange={onChangeArticulo}  
                  suggestions={FilteredArticulo}
                  field="NomArticulo"
                  completeMethod={FilterArticulo}
                >
                </FieldAutoComplete>    
              </div>
            </div>  
          </div> 
          <Row className='p-md-3'>
            <Col lg={12} md={12} sm={12} xs={12}>    
              <div className="p-fluid">
                <FieldSelectButton 
                  name="TipoConversionCost"
                  value={TipoConversionCost} 
                  options={OpcionesConversionCost} 
                  onChange={(e) => onchangeTipoConversionCost(e.value)} 
                  optionValue="value"
                  optionLabel="name"
                  errors={errors}
                /> 
              </div>
            </Col>
          </Row> 
        </Panel>

      </>
)
}  
 
export default AnalisisCostosFilters;   
 

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi, showSweetAlert } from '../../../utils/utils';
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon';
import moment from 'moment';
import Highcharts from 'highcharts';
import swal from '@sweetalert/with-react';
import PageContent from 'layouts/PageContent';
import { Toolbar } from 'primereact/toolbar';
import { ProgressBar } from 'primereact/progressbar';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldNumber from '../../../components/Controls/FieldNumber.jsx';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import { Dialog } from 'primereact/dialog';

const KpisManagementDetTable = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['kpisManagement', 'common']);
  const [KpisManagementDetList, setKpisManagementDetList] = useState([]); 
  const [openKPIsManagmentDetail, setopenKPIsManagmentDetail] = useState(false);
  const [KpisManagementEnc, setKpisManagementEnc] = useState({}); 

  useEffect(() => { 
    obtieneKPIsManagementeDet(); 
    setopenKPIsManagmentDetail(props.IsVisible);
  }, [props.ClaProyecto,props.IsVisible]);

  const obtieneKPIsManagementeDet = () => {
    
    const GetKPIsManagementDet = `${config.UrlApiProject}ScoreCard/GetKPIsManagementDet?IdProyectoKPIManagement=${props.IdProyectoKPIManagement}`;
    const paramsToService = {};
    callApi(GetKPIsManagementDet, 'GET', paramsToService, (response) => {
        setKpisManagementEnc(response.data.KPIManagementEnc.length > 0 ? response.data.KPIManagementEnc[0] : {});
        setKpisManagementDetList(response.data.KPIManagementDet);
    });
     
  };
  const onTargetMesChange = (props, value) => {
    const updatedKPIsManagementeDet = [...props.value];
    updatedKPIsManagementeDet[props.rowIndex][props.field] = value;
    updatedKPIsManagementeDet[props.rowIndex].IsRowEdited = true;

    setKpisManagementDetList(updatedKPIsManagementeDet);
  };

  const onSaveClick = async () => {
    const items = KpisManagementDetList.filter(item => item.IsRowEdited === true);
    
    console.log('items', items);
    const paramsServiceOwners = {
        ProyectoKPIManagementDet: items,
    };
  
    const SaveProyectoKPIManagementDet = `${config.UrlApiProject}ScoreCard/SaveProyectoKPIManagementDet`;

    callApi(SaveProyectoKPIManagementDet, 'POST', paramsServiceOwners, (response) => {
        showSweetAlert('success', t('common:SuccessOperation'), 'success');

        if (props.onClose !== null && typeof(props.onClose) === 'function'){
            props.onClose();
        }  
    }); 
  };

  const TargetMesEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldNumber
          name="TargetMes"
          value={props.rowData.TargetMes}
          onChange={(e) => onTargetMesChange(props, e.target.value)}
          max={99999999.99}
          maxFractionDigits={2}
        />
      </div>
    );
  };
 

  const modalFooter = () => {
    return (
      <>
        <Button
          label={t('fasesproyectos:Save')}
          className="primary no-border rounded-button"
          onClick={() => onSaveClick()}
        />
      </>
    );
  };
  const closeModal = () => {
    setopenKPIsManagmentDetail(false);

    if (props.onClose !== null && typeof(props.onClose) === 'function'){
      props.onClose();
    }
  }

  const NomMesTemplate = (rowData) => {
    return (<>{moment(rowData.FechaAnioMes).format("MMMM, YYYY")}</>) 
  }

  const EstatusMesTemplate = (rowData) => {
    return (<>{rowData.EstatusMes !== null ? (rowData.EstatusMes).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : '-' }</>)
  }
  const TargetMesTemplate = (rowData) => {
    return (<>{rowData.TargetMes !== null ? (rowData.TargetMes).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : <i className='fas fa-pencil-alt'></i> }</>)
  }
 
  return (
    <>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <Dialog
            visible={openKPIsManagmentDetail}
            style={{ width: '60vw' }}
            footer={modalFooter}
            modal
            header={t('kpisManagement:KPIDetailModalTitulo')}
            onHide={closeModal}
            contentStyle={{ maxHeight: '500px' }}
          >
            <Row className='light p-3 align-items-start'>
              <Col lg={6} md={6} sm={12} xs={12}>
                <table>
                  <tr>
                    <td><b>{t('kpisManagement:KPIName')}:</b></td>
                    <td className='pl-4'>{KpisManagementEnc.NomProyectoKPIManagement}</td>
                  </tr>
                  <tr>
                    <td><b>{t('kpisManagement:UoM')}:</b></td>
                    <td className='pl-4'>{KpisManagementEnc.DescUnidadMedidad}</td>
                  </tr>
                </table> 
              </Col>
              <Col lg={6} md={6} sm={12} xs={12} className='text-right'>
                <b>{t('kpisManagement:Peso')} :</b> &nbsp;&nbsp;{KpisManagementEnc.PorcPeso} %
              </Col>
            </Row>
            <Row className='pt-3'>
              <Col lg={12} md={12} sm={12} xs={12}>
                <FieldDataTable
                  value={KpisManagementDetList}
                  scrollable
                  scrollHeight="300px"
                  className="editable-cells-table"
                  editMode="cell"
                  selectionMode="single"
                >
                  <Column 
                    header={t('kpisManagement:NomMes')} 
                    headerStyle={{ width: '150px' }}
                    body={NomMesTemplate}
                  >
                  </Column>
                  <Column
                    field="TargetMes"
                    header={t('kpisManagement:TargetMes')}
                    headerStyle={{ width: '75px' }}
                    columnKey="TargetMes"
                    editor={(props) => TargetMesEditor(props)}
                    body={TargetMesTemplate}
                  >
                  </Column>
                  <Column
                    field="EstatusMes"
                    header={t('kpisManagement:EstatusMes')}
                    headerStyle={{ width: '75px' }}
                    columnKey="EstatusMes" 
                    body={EstatusMesTemplate}
                  >
                  </Column>
                </FieldDataTable>
              </Col>
            </Row>
           
          </Dialog>
        </Col>
      </Row>
    </>
  );
};

export default KpisManagementDetTable;

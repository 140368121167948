/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React  from 'react';
import PropTypes from 'prop-types';
import "./SweatAlertCustomIcon.scss";
 

function SweatAlertCustomIcon ({
     title
    ,letter 
    ,className
  }) { 
    return (  
      <>  
        <div className={`swal-icon swal-custom-letter swal-icon-show ${className === undefined ? '' : className}`}>
          <div className="swal-icon-content">{letter}</div>
        </div>
        <div className="swal-title">{title}</div>
      </> 
    )
}   

SweatAlertCustomIcon.propTypes = { 
    title: PropTypes.string,
    letter: PropTypes.string,
    className : PropTypes.string
}

export default SweatAlertCustomIcon; 

import React, { useState, useRef, useEffect, useContext  } from 'react'; 
import { Row,Col,Card, CardHeader, CardTitle, CardBody, CardFooter} from 'reactstrap'; 
import { AppContext } from 'context/AppContext'; 
import { config } from '../../../utils/config';
import { useTranslation } from 'react-i18next'; 
import PageContent from 'layouts/PageContent';   
import moment from 'moment';
import { callApi, showSweetAlert} from '../../../utils/utils'; 
import FieldButton from '../../../components/Controls/FieldButton/FieldButton';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import FieldDataView from '../../../components/Controls/FieldDataView/FieldDataView.jsx';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import notassigned from '../../../assets/img/icons/notassigned.png'
import assigned from '../../../assets/img/icons/assigned.png'
import done from '../../../assets/img/icons/done.png'
import { Column } from 'primereact/column';
import { Badge } from 'primereact/badge'; 

const MTDPerformanceReportDetail = (props) => {  
  const { t } = useTranslation(['mtd', 'common']);
  const { viewportRefresh } = useContext(AppContext);
  const [Viewport, setViewport] = useState(viewportRefresh);
  const [workOrdersList, setworkOrdersList] = useState([]);
  

  useEffect(() => {
    GetDetail();
  }, []);
  
  useEffect(() => {    
    setViewport(viewportRefresh); 
  }, [viewportRefresh]);

  const GetDetail = () => { 
    let sBuilding = '';
    let cBuilding = 0;
    const buildingList = props.filterBuildingList;
    const assignedTechList = props.filterTecAssignedList;
    const techTypeList = props.filterTecTypeList;
    for (let index = 0; index < buildingList.length; index++) {
      if(buildingList[index].EsSeleccionado){
        const element = buildingList[index].ClaEdificio;
        sBuilding = `${sBuilding + element},`;
        cBuilding +=1;
      }
    } 
    let sAssignedTech = '';
    for (let index = 0; index < assignedTechList.length; index++) {
      if(assignedTechList[index].EsSeleccionado){
        const element = assignedTechList[index].ClaTechAssigned;
        sAssignedTech = `${sAssignedTech + element},`;
      }
    } 
    let sTechType = '';
    for (let index = 0; index < techTypeList.length; index++) {
      if(techTypeList[index].EsSeleccionado){
        const element = techTypeList[index].ClaTechType;
        sTechType = `${sTechType + element},`;
      }
    }
    const paramsToService = {
      Building:sBuilding,
      AssignedTech:sAssignedTech,
      TechType:sTechType,
      RangeDateStart:  props.StartDate,
      RangeDateEnd:  props.EndDate,
      ClaEdificio: props.ClaEdificio,
      ClaAssignedTech: props.ClaAssignedTech,
      ClaTechType: props.ClaTechType,
      ClaAging:props.ClaAging,
      ClaEstatusOt: props.ClaEstatusOt,
      IsOpenWorkOrders: props.IsOpenWorkOrders,
      IsOverdueWorkOrders:props.IsOverdueWorkOrders,
      IsOverdueWorkOrdersTotal:props.IsOverdueWorkOrdersTotal,
      IsOntimeWorkOrders:props.IsOntimeWorkOrders,
      IsOntimeWorkOrdersTotal:props.IsOntimeWorkOrdersTotal,
      IsWorkOrders:props.IsWorkOrders,
      IsCurrentWeekWorkOrders:props.IsCurrentWeekWorkOrders,
      IsBackLog:props.IsBackLog
    }
    const url = `${config.UrlApiProject}MMA/GetMDTPerformanceReportDetail`;
    callApi(url, 'POST', paramsToService, (response) => {
      const ordersList = response.data.WorkOrdersList;
      setworkOrdersList(ordersList);
    });   
  }

  const headerWorkOrders =(
    <>
      <Row>
        <Col>
          {t('focusReport:Total')}
        </Col>
      </Row>
    </>
      );

      const woCardTemplate = (wo) => {
        return (
          <>
            <Row className='w-100 m-0 pt-2'>
              <Col>
                <Row>
                  <Col> 
                    {t('mtd:WO')}: <b>{wo.ClaveOt}</b>  
                    <div> 
                      {t('mtd:Date')}:
                      &nbsp;<b>{moment(wo.FechaHoraProgOriginal).format("MM/DD/YYYY")}</b> 
                    </div>  
                    <div> 
                      {t('mtd:PlanDate')}
                      :
                      {
                        wo.FechaHoraProgOriginal!= null ? (
                          <>
                            &nbsp;<b>{moment(wo.FechaHoraProgOriginal).format("MM/DD/YYYY")}</b> 
                          </>
                        ):(
                          <>&nbsp;
                          </>
                        )
                      }
                    </div>  
                    <div> 
                      {t('mtd:CompletionDate')}:
                      {
                        wo.FechaHoraFin!= null ? (
                          <>
                            &nbsp;<b>{moment(wo.FechaHoraFin).format("MM/DD/YYYY")}</b> 
                          </>
                        ):(
                          <>&nbsp;
                          </>
                        )
                      }  
                    </div>  
                  </Col>
                  <Col className='text-right'> 
                    {t('mtd:Asset')}: <b>{wo.ClaveEquipo}</b> 
                    <div> 
                      {t('mtd:AssignedPersonal')}:
                      &nbsp;<b>{wo.PersonalAsignado}</b> 
                    </div>   
                    <div> 
                      {t('mtd:ExpirationDate')}:
                      {
                        wo.FechaHoraVigencia!= null ? (
                          <>
                            &nbsp;<b>{moment(wo.FechaHoraVigencia).format("MM/DD/YYYY")}</b> 
                          </>
                        ):(
                          <>&nbsp;
                          </>
                        )
                      } 
                    </div>  
                    <div> 
                      {t('mtd:OverdueDate')}:
                      {
                        wo.FechaVigenciaOverdue!= null ? (
                          <>
                            &nbsp;<b>{moment(wo.FechaVigenciaOverdue).format("MM/DD/YYYY")}</b> 
                          </>
                        ):(
                          <>&nbsp;
                          </>
                        )
                      }  
                    </div>     
                  </Col>
                </Row>
                <Row>
                  <Col className='pt-2'>
                    <b>{wo.NomActividad}</b>
                    <div>{wo.ClaveGuia} </div>
                  </Col>
                </Row>
                <Row className="">
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="border-color-info-color" style={{borderBottom: "1px solid"}}></div>
                  </Col>
                </Row> 
              </Col>
            </Row>      
          </>
        );
      };

      const woCardBodyStyle = {
        padding: '0',    
      };
    

const returnClass = ()=> {
  let sclass = null; 
  switch (props.ClaEstatusOt) {
    case 1:
      sclass = 'info'
      break;
    case 2:
      sclass = 'orange'
      break;       
    case 3: 
    sclass = 'light-green'
      break;       
    default:
      sclass = 'primary'
      break;
  }
  return sclass;
}
      
const returnClassBadge = ()=> {
  let sclass = null; 
  switch (props.ClaEstatusOt) {
    case 1:
      sclass = 'info-dark'
      break;
    case 2:
      sclass = 'orange-dark'
      break;       
    case 3: 
    sclass = 'light-green-dark'
      break;       
    default:
      sclass = 'secondary-dark'
      break;
  }
  return sclass;
}

const returnLegend = () => {
  let statusName = '';
  switch (props.ClaEstatusOt) {
    case 1:
      statusName = t('mtd:NotAssigned');
      break;
    case 2:
      statusName = t('mtd:Assigned');
      break;
    case 3:
      statusName = t('mtd:Done');;
      break;
    default:
      statusName = '';
  }
  return (
    <>
      <div>
        {statusName}
      </div>
    </>
  );
};

  return (
    <>   
      <div>
        <Card>
          <CardHeader className={returnClass()}>
            <CardTitle>
              <Row className="pt-3">
                <Col>
                  {returnLegend()}
                </Col>
                <Col className="text-right">
                  <Badge
                    value={workOrdersList.length}
                    className={returnClassBadge()} 
                    size="large"
                  />  
                </Col>
              </Row>             
            </CardTitle>
          </CardHeader>    
          <CardBody style={woCardBodyStyle}> 
            <FieldDataView
              value={workOrdersList}            
              itemTemplate={woCardTemplate}
              layout="list"    
              className="MMADashboardScreen-data-view"   
              paginator
              rows={50}
            /> 
          </CardBody>
        </Card>      
      </div>
    </>
  );
};

export default MTDPerformanceReportDetail;

import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Label, Card, CardBody } from 'reactstrap';
import { callApi, showSweetAlert, formatPhoneNumberNA } from "utils/utils";
import { config } from 'utils/config';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import defaultLogo from 'assets/img/MCSW-logo-35anniversary-dark-background.svg';
import defaultAvatar from 'assets/img/default-avatar.png';
import { Dialog } from 'primereact/dialog';
import FieldText from 'components/Controls/FieldText';
import FieldPhone from 'components/Controls/FieldPhone/FieldPhone';
import { object, string, number, date, InferType, array } from 'yup';
import YupValidator from 'utils/YupValidator';

const BusinessCard = (props) => {
  const {t, i18n} = useTranslation(['operations', 'common']);

  const [backgroundColor, setBackgroundColor] = useState('#162d48');
  const [userInfoColor, setUserInfoColor] = useState('#ffffff');
  const [secondaryColor, setSecondaryColor] = useState('#162d48');
  const [buttonColor, setButtonColor] = useState('#cf3339');
  const [userName, setUserName] = useState('');
  const [jobTitle, setJobTitle] = useState('')
  const [companyName, setCompanyName] = useState('');
  const [website, setWebsite] = useState('');
  const [address, setAddress] = useState('');
  const [contactInfo, setContactInfo] = useState({Phone: [], Email: [], Social: []});
  const [companyLogo, setCompanyLogo] = useState(null);
  const [userImage, setUserImage] = useState(null);
  const [windowDimensions, setWindowDimensions] = useState({});
  const [showShareContactInfo, setShowShareContactInfo] = useState(false);
  const [contactFirstName, setContactFirstName] = useState('');
  const [contactLastName, setContactLastName] = useState('');
  const [contactCompanyName, setContactCompanyName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhoneNumber, setContactPhoneNumber] = useState('');
  const [errors, setErrors] = useState({});
  const [selectedCountryCode, setSelectedCountryCode] = useState('')
  const [selectedCountry, setSelectedCountry] = useState('');

  const isEditorMode = props.isEditorMode !== undefined ? props.isEditorMode : false;
  const ClaUsuarioBC = props.ClaUsuarioBC !== undefined ? props.ClaUsuarioBC : !props.isEditorMode ? props.match.params.ClaUsuarioBC: null;
  const userInfo = props.userInfo !== undefined ? props.userInfo : null;

  useEffect(() => {
    console.log(isEditorMode)
    if (isEditorMode) {
      getEditorUserInfo();
    }
    else {
      getVCardInfo();
    }
    
    setWindowDimensions(getWindowDimensions());

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
      console.log(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [userInfo])

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  // Download the .vcf file when the user taps the add contact button
  const downloadVCardFile = () => {
    const url = `${config.UrlApiProject}QRCode/GetVCardFile?ClaUsuario=${ClaUsuarioBC}`;  
    const paramsService= {};  
    callApi(url, 'GET', paramsService, (response) => {
      const downloadFile = response.data.file;
      if(downloadFile !== undefined){
        const linkSource = `data:text/vcard;base64,${downloadFile}`
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = `${response.data.fileName}.${response.data.fileExtension}`;
        downloadLink.click();
      }
    });
  }

  // Get the employee info
  const getVCardInfo = () => {
    const url = `${config.UrlApiProject}QRCode/GetVCardInfo?ClaUsuario=${ClaUsuarioBC}`;  
    const paramsService= {};  
    callApi(url, 'GET', paramsService, (response) => {
      setUserName(response.data.info.FullName);
      setJobTitle(response.data.info.JobTitle);
      setCompanyName(response.data.info.CompanyName);
      setWebsite(response.data.info.Website);
      setAddress(response.data.info.FullAddress);
      setContactInfo(response.data.contactInfo);
      setCompanyLogo({
        file: response.data.info.LogoFile,
        fileName: response.data.info.LogoFileName,
        mimeType: response.data.info.LogoFileMIMEType
      });
      setUserImage({
        file: response.data.info.UserImageFile,
        fileName: response.data.info.UserImageFileName,
        mimeType: response.data.info.UserImageFileMIMEType
      });
      setBackgroundColor(response.data.info.BackgroundColor);
      setUserInfoColor(response.data.info.UserInfoColor);
      setSecondaryColor(response.data.info.SecondaryColor);
      setButtonColor(response.data.info.ButtonColor);
    });
  }

  const getEditorUserInfo = () => {
    if (userInfo !== null) {
      console.log(userInfo);
      setUserName(userInfo.FullName);
      setJobTitle(userInfo.JobTitle);
      setCompanyName(userInfo.CompanyName);
      setWebsite(userInfo.Website);
      setAddress(userInfo.FullAddress);
      setContactInfo(userInfo.contactInfo);
      setCompanyLogo({
        file: userInfo.LogoFile,
        fileName: userInfo.LogoFileName,
        mimeType: userInfo.LogoFileMIMEType
      });
      setUserImage({
        file: userInfo.UserImageFile,
        fileName: userInfo.UserImageFileName,
        mimeType: userInfo.UserImageFileMIMEType
      });
      setBackgroundColor(userInfo.BackgroundColor);
      setUserInfoColor(userInfo.UserInfoColor);
      setSecondaryColor(userInfo.SecondaryColor);
      setButtonColor(userInfo.ButtonColor);
    }
  }

  // Render the profile picture of the employee
  const renderUserImage = () => {
    if (userImage !== null && userImage.file !== null) {
      return (
        <img 
          src={`data:${userImage.mimeType};base64,${userImage.file}`}
          alt=''
          style={{height: '150px', width: '150px', borderRadius: '50%'}}
        />
      )
    }

    return (
      <img src={defaultAvatar} alt='Employee' style={{height: '150px', width: '150px', borderRadius: '50%'}} />
    )
  }

  // Render the logo at the top of the business card
  const renderLogo = () => {
    if (companyLogo !== null && companyLogo.file !== null) {
      return (
        <img 
          src={`data:${companyLogo.mimeType};base64,${companyLogo.file}`}
          alt='Logo'
          style={{maxWidth: '100%', maxHeight: '100%', objectFit: 'contain'}}
        />
      )
    }

    return (
      <img src={defaultLogo} alt='Logo' style={{maxWidth: '70%'}} />
    )
  }

  const renderContactInfo = () => {
    const phone = contactInfo !== undefined && contactInfo.Phone !== undefined && contactInfo.Phone !== null ? contactInfo.Phone : [];
    const email = contactInfo !== undefined && contactInfo.Email !== undefined && contactInfo.Email !== null ? contactInfo.Email : [];
    const social = contactInfo !== undefined && contactInfo.Social !== undefined && contactInfo.Social !== null ? contactInfo.Social : [];

    return (
      <>
        {phone.length > 0 ? (
          <Col xs={12} sm={12} md={12} lg={12}>
            {phone.map(p => {
              return (
                <span key={p.ContactId}>
                  <Label>{p.NomTipoDBCContactLabel}</Label>
                  <a href={`tel:${p.Valor}`}>
                    <h2 style={{color: secondaryColor}}>{formatPhoneNumberNA(p.Valor)}</h2>
                  </a>
                </span>
              )
            })}
            <hr className='m-2' />
          </Col>
          ) : <></>}
        {email.length > 0 ? (
          <Col xs={12} sm={12} md={12} lg={12}>
            {email.map(p => {
              return (
                <span key={p.ContactId}>
                  <Label>{p.NomTipoDBCContactLabel}</Label>
                  <a href={`mailto:${p.Valor}`}>
                    <h2 style={{color: secondaryColor}}>{p.Valor}</h2>
                  </a>
                </span>
              )
            })}
            <hr className='m-2' />
          </Col>
        ) : <></>}
        {website ? (
          <Col xs={12} sm={12} md={12} lg={12} className=''>
            <Label>Website</Label>
            <a href={website} target='_blank' rel="noopener noreferrer">
              <h2 style={{color: secondaryColor}}>{website}</h2>
            </a>
            <hr className='m-2' />
          </Col>
        ): <></>}
        {address ? (
          <Col xs={12} sm={12} md={12} lg={12} className=''>
            <Label>Address</Label>
            <a 
              href={`https://www.google.com/maps/search/?api=1&query=${address.replace(' ', '+')}`}
              target='_blank'
              rel="noopener noreferrer"
            >
              <h2 style={{color: secondaryColor}}>{address}</h2>
            </a>
            <hr className='m-2' />
          </Col>
        ) : <></>}
        {social.length > 0 ? (
          <Col xs={12} sm={12} md={12} lg={12}>
            <Label>Connect With Me On</Label>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                {social.map(s => {
                  return (
                    <a 
                      href={s.Valor}
                      target='_blank'
                      rel="noopener noreferrer"
                      key={s.ContactId}
                    >
                      <i style={{fontSize: '35px', color: secondaryColor}} className={`${s.Icon} mr-2`} />
                    </a>
                  )
                })}
              </Col>
            </Row>
          </Col>
        ): <></>}
      </>
    )
  }

  const renderContactButtons = () => {
    const phone = contactInfo !== undefined && contactInfo.Phone !== undefined && contactInfo.Phone !== null ? contactInfo.Phone : [];
    const email = contactInfo !== undefined && contactInfo.Email !== undefined && contactInfo.Email !== null ? contactInfo.Email : [];

    return (
      <>
        {phone.length > 0 ? (
          <FieldButton 
            style={{padding: '2rem', backgroundColor: buttonColor, borderColor: buttonColor}}
            className={`p-button-rounded p-button-lg ${email.length > 0 ? 'mr-4' : ''}`}
            icon='pi pi-phone'
            onClick={() => window.location.assign(`tel:${phone[0].Valor}`)}
          />
        ) : <></>}
        {email.length > 0 ? (
          <FieldButton
            style={{padding: '2rem', backgroundColor: buttonColor, borderColor: buttonColor}}
            className={`p-button-rounded p-button-lg ${address ? 'mr-4' : ''}`}
            icon='pi pi-envelope'
            onClick={() => window.location.assign(`mailto:${email[0].Valor}`)}
          />
        ) : <></>}
        {address ? (
          <FieldButton
            style={{padding: '2rem', backgroundColor: buttonColor, borderColor: buttonColor}}
            className='p-button-rounded p-button-lg'
            icon='pi pi-map-marker'
            onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${address.replace(' ', '+')}`, '_blank', 'noreferrer')}
          />
        ) : <></>}
      </>
    )
  }

  const onHideShareContactInfo = () => {
    setShowShareContactInfo(false);
    setContactFirstName('');
    setContactLastName('');
    setContactCompanyName('');
    setContactEmail('');
    setContactPhoneNumber();
    setErrors({});
  }

  const onContactFirstNameChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.contactFirstName;
    setErrors(newErrors);
    setContactFirstName(e.target.value);
  }

  const onContactLastNameChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.contactLastName;
    setErrors(newErrors);
    setContactLastName(e.target.value);
  }

  const onContactCompanyNameChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.contactCompanyName;
    setErrors(newErrors);
    setContactCompanyName(e.target.value);
  }

  const onContactEmailChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.contactEmail;
    setErrors(newErrors);
    setContactEmail(e.target.value);
  }

  const onContactPhoneNumberChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.contactPhoneNumber;
    setErrors(newErrors);
    setContactPhoneNumber(e);
  }

  const onCountryChange = (country, countryCode) => {
    setSelectedCountry(country);
    setSelectedCountryCode(countryCode);
  }

  const onSubmitContactInfoClick = async () => {
    const validationScheme = object().shape({
      contactFirstName: string().required(t('common:RequiredField')),
      contactLastName: string().required(t('common:RequiredField')),
      contactCompanyName: string(),
      contactEmail: string().when('contactPhoneNumber', {
        is: (contactPhoneNumber) => { return contactPhoneNumber === "" || contactPhoneNumber === undefined || contactPhoneNumber === null },
        then: string().matches(String.raw`(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))`,
                                t('qr:ValidEmailOrPhone')),
        otherwise: string()
      }),
      contactPhoneNumber: string().when('contactEmail', {
        is: '',
        then: string().matches(String.raw`^[\+][0-9]{1,3}[0-9]{3}[0-9]{3}[0-9]{4}$`,
                                t('qr:ValidEmailOrPhone')).required(t('qr:ValidEmailOrPhone')).nullable(),
        otherwise: string().nullable()
      })
    }, [['contactEmail', 'contactPhoneNumber']]);
    
    const value = {
      contactFirstName,
      contactLastName,
      contactCompanyName,
      contactEmail,
      contactPhoneNumber
    }

    console.log(value);

    const validator = new YupValidator(validationScheme);
    const isValid = await validator.validate(value);

    if (!isValid) {
      setErrors(validator.errors);
      return;
    }

    const url = `${config.UrlApiProject}QRCode/InsertSharedContact`;  
    const paramsService= {
      ClaUsuarioBC,
      FirstName: contactFirstName,
      LastName: contactLastName,
      Email: contactEmail,
      PhoneNumber: contactPhoneNumber
    };
    console.log(paramsService);
    callApi(url, 'POST', paramsService, (response) => {
      showSweetAlert('Success', t('common:Success'), 'success');
      onHideShareContactInfo();
    });
  }

  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <div style={{minHeight: '100vh', maxWidth: '500px', backgroundColor: 'white', overflow: 'hidden'}} className={`pb-5 ${props.className !== undefined ? props.className : ''}`}>
        <Row style={{backgroundColor, left: 0, right: 0}} className='pb-5 pt-3'>
          <Col xs={12} sm={12} md={12} lg={12} className='p-d-flex p-jc-center mt-2'>
            <div style={{maxHeight: '200px', width: '70%'}} className='p-d-flex p-jc-center'>
              {renderLogo()}
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} className='p-d-flex p-jc-center mt-3'>
            {renderUserImage()}
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} className='p-d-flex p-jc-center mt-3'>
            <h2 style={{color: userInfoColor, fontSize: '20px'}}>{userName}</h2>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} className='p-d-flex p-jc-center'>
            <h2 style={{color: userInfoColor}}>{jobTitle}</h2>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} className='p-d-flex p-jc-center'>
            <h2 style={{color: userInfoColor}}>{companyName}</h2>
          </Col>
        </Row>
        <div style={{display: 'flex', position: 'relative', justifyContent: 'center', top: '-30px', width: '100%'}}>
          {renderContactButtons()}
        </div>
        <Row className='pl-2 pr-2 pb-5'>
          {renderContactInfo()}
        </Row>
        <Row className='p-d-flex p-jc-center'>
          <Col xs={11} sm={11} md={11} lg={11} className='p-fluid'>
            <FieldButton 
              style={{backgroundColor: buttonColor, borderColor: buttonColor, fontWeight: 'bold'}}
              label={t('qr:ShareYourContactInfoWithUs')}
              onClick={() => setShowShareContactInfo(true)}
            />
          </Col>
        </Row>
        {!isEditorMode ? (
          <>
            <FieldButton
              style={{
                position:'fixed',
                width:'60px',
                height:'60px',
                bottom:'60px',
                right: windowDimensions.width > 500 ? `${(windowDimensions.width / 2) - (250) + 40}px`  : '40px',
                color:'white',
                backgroundColor: buttonColor,
                borderColor: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                borderRadius: '50%'
              }}
              onClick={() => downloadVCardFile()}
            >
              <i className='pi pi-user-plus' style={{fontSize: '20px'}} />
            </FieldButton>
          </>
        ) : <></> }
      </div>
      <Dialog
        visible={showShareContactInfo}
        dismissable={true}
        blockScroll={true}
        showCloseIcon={true}
        maximized
        onHide={() => onHideShareContactInfo()}
        position='center'
        draggable={false}
        resizable={false}
        header={t('qr:ShareContactInfo')}
      >
        <Row className='p-fluid'>
          <Col xs={12} sm={12} md={12} lg={12} className='p-field'>
            <Label>{t('common:FirstName')} *</Label>
            <FieldText
              name="contactFirstName"
              value={contactFirstName}
              onChange={onContactFirstNameChange}
              errors={errors}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} className='p-field'>
            <Label>{t('common:LastName')} *</Label>
            <FieldText
              name="contactLastName"
              value={contactLastName}
              onChange={onContactLastNameChange}
              errors={errors}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} className='p-field'>
            <Label>{t('common:CompanyName')}</Label>
            <FieldText
              name="contactCompanyName"
              value={contactCompanyName}
              onChange={onContactCompanyNameChange}
              errors={errors}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} className='p-field'>
            <Label>{t('common:Email')}</Label>
            <FieldText
              name="contactEmail"
              value={contactEmail}
              onChange={onContactEmailChange}
              errors={errors}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} className='p-field'>
            <Label>{t('common:PhoneNumber')}</Label>
            <FieldPhone
              name='contactPhoneNumber'
              value={contactPhoneNumber}
              placeholder={t('common:ContactNumber')}
              onChange={(phone) => {onContactPhoneNumberChange(phone)}}
              onCountryChange={(c, cc) => {onCountryChange(c, cc)}}
              errors={errors}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} className='p-field'>
            <FieldButton
              label={t('common:Submit')}
              className='p-button-success'
              icon='pi pi-send'
              onClick={() => onSubmitContactInfoClick()}
            />
          </Col>
        </Row>
      </Dialog>
    </div>
  )
}

export default BusinessCard;
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import FieldButton from 'components/Controls/FieldButton/FieldButton';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from 'utils/config';
import { callApi, formatDate, showSweetAlert } from 'utils/utils';
import { Card, CardBody, Row, Col, CardHeader, CardFooter } from 'reactstrap';
import PDFViewer from 'components/Controls/PDFViewer/PDFViewer';
import { Dialog } from 'primereact/dialog';
import VideoPlayer from 'components/Controls/VideoPlayer/VideoPlayer.jsx';
import queryString from 'query-string';

import MCSWNewsletterImage from './MSCWNewsletterImage';

const MCSWNewsletter = (props) => {
  const { t } = useTranslation(['hr', 'common']);

  const [newsletters, setNewsletters] = useState([]);
  const [file, setFile] = useState(null);
  const [showPdfViewer, setShowPdfViewer] = useState(false);
  const VideoPlayerViewer  = useRef();     
  const [EsShowDialog, setEsShowDialog] = useState(false);
  const [fileExtension, setfileExtension] = useState('');
  const [selectedNewsletter, setSelectedNewsletter] = useState(null);
  const [fileData, setFileData] = useState(null);

  useEffect(() => {
    GetNewsletters();

    const params = queryString.parse(props.location.search);

    if (params.IdLibraryFile !== null && params.IdLibraryFile !== undefined) {
      onViewNewsletterClick({IdLibraryFile: params.IdLibraryFile});
    }
  }, [])

  // Get the newsletters
  const GetNewsletters = () => {
    const urlWebService = `${config.UrlApiProject}HR/GetMCSWNewsletters`;
    const paramsService = {};

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setNewsletters(response.data.Newsletters);
    });
  }

  const newsletterTemplate = (newsletter) => {
    return (
      <Col xs={12} sm={12} md={4} lg={3} className='mb-5'>
        <div className='p-grid'>
          <div className='p-col-12 p-d-flex p-jc-center pb-0'>
            <h1>{`${newsletter.MonthName} ${newsletter.YearNum} Newsletter`}</h1>
          </div>
          <div className='p-col-12 p-d-flex p-jc-center pt-0'>
            <MCSWNewsletterImage IdLibraryFile={newsletter.IdLibraryFile} />
          </div>
          <div className='p-col-12 p-d-flex p-jc-center'>
            <FieldButton
              label='View Newsletter'
              className='p-button-lg'
              onClick={() => onViewNewsletterClick(newsletter)}
            />
          </div>
        </div>
      </Col>
    )
  }

  const getNewsletterFile = (newsletter) => {
    const GetFileData = `${config.UrlApiProject}DMS/GetNewsletterLibraryFileById`;
    const paramsToService = { 
      IdLibraryFile: newsletter.IdLibraryFile
    };

    callApi(GetFileData, 'POST', paramsToService, (response) => {
      setFileData(response.data);
      const AnexoDetalle = response.data.AnexoDetalle[0];
      const AnexoImg = response.data.ImgDownLoad;
      if(AnexoDetalle!==undefined){
        
        let linkSource = ''

        switch (AnexoDetalle.TypeExtension) {
          case 'mp4':
          case 'webm':
            linkSource = `data:video/${AnexoDetalle.TypeExtension};base64,${AnexoImg}`;
            setEsShowDialog(true); 
            break;
          case 'pdf':
              linkSource = `data:application/${AnexoDetalle.TypeExtension};base64,${AnexoImg}`;
              setShowPdfViewer(true); 
              break;
          case 'jpg':
          case 'gif':
          case 'jpeg':
          case 'jfif':
          case 'pjpeg':
          case 'png':
          case 'svg':
          case 'webp':
          case 'bmp':
          case 'ico':
          case 'cur':
                linkSource = `data:image/${AnexoDetalle.TypeExtension};base64,${AnexoImg}`;
                setEsShowDialog(true); 
                break;
          default:
            break;
        } 
        
        // SI NO FORMAMOS EL LINK QUE LO INTENTE DESCARGAR
        if (linkSource === ''){
          const linkSource = `data:application/${AnexoDetalle.TypeExtension};base64,${AnexoImg}`;
          const downloadLink = document.createElement("a");
          const fileName = `${AnexoDetalle.NomLibraryFile}`;
          downloadLink.href = linkSource;
          downloadLink.download = `${fileName}.${AnexoDetalle.TypeExtension}`;
          downloadLink.click();
        }
        else{  
          setFile(linkSource);
          setfileExtension(AnexoDetalle.TypeExtension);
        }

      }
    });
  }

  const onViewNewsletterClick = (newsletter) => {
    console.log('OnView',newsletter)
    setSelectedNewsletter(newsletter);
    getNewsletterFile(newsletter);
  }

  const copyLink = () => {
    if (selectedNewsletter.IdLibraryFile !== undefined) {
      navigator.clipboard.writeText(`https://sirius.mcswusa.com/r/MCSWNewsletter?IdLibraryFile=${selectedNewsletter.IdLibraryFile}`);
      showSweetAlert('Success', t('common:LinkCopiedSuccessfully'), 'success');
    }
    else {
      navigator.clipboard.writeText('https://sirius.mcswusa.com/r/MCSWNewsletter');
      showSweetAlert('Success', t('common:LinkCopiedSuccessfully'), 'success');
    }
  }

  const downloadFile = () => {
    console.log('Download',selectedNewsletter);
    const AnexoDetalle = fileData.AnexoDetalle[0];
    const AnexoImg = fileData.ImgDownLoad;
    console.log(fileData);

    if(AnexoDetalle !== undefined) {
      const linkSource = `data:application/${AnexoDetalle.TypeExtension};base64,${AnexoImg}`;
      const downloadLink = document.createElement("a");
      const fileName = `${AnexoDetalle.NomLibraryFile}`;
      downloadLink.href = linkSource;
      downloadLink.download = `Newsletter.${AnexoDetalle.TypeExtension}`;
      downloadLink.click();
    }
  }

  const renderHeader = () => {
    return (
      <div>
        <Row className="text-center">
          <Col lg={12} md={12} sm={12} xs={12} className="text-left">
            {t('dms:Viewer')}
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <>
      <Card>
        <CardBody>
          <div className='p-grid p-jc-center align-items-end'>
            {newsletters.map((n) => newsletterTemplate(n))}
          </div>
        </CardBody>
      </Card>
      <PDFViewer
        file={file} 
        show={showPdfViewer}
        onHide={() => { setShowPdfViewer(false) }}
        copyLink={copyLink}
        downloadFile={downloadFile}
      > 
      </PDFViewer>
      <Dialog
        visible={EsShowDialog}
        modal
        style={{ width: '100vw' }}
        footer=""
        header={renderHeader} 
        closable={true}
        className="dialog-header-background-blue"
        maximized={true}
        draggable={false}
        blockScroll={true}
        onHide={() => setEsShowDialog(false)}
      >
        {
            fileExtension === 'mp4'
            ||
            fileExtension === 'webm'
            ?
            (
              <Row className='h-100'>
                <Col className='h-100 video-visor'>  
                  <VideoPlayer reference={VideoPlayerViewer} url={file} height="100%"></VideoPlayer>  
                </Col>
              </Row>
            )
            :
            (<></>)
          }

        {
            fileExtension === 'jpg'   || fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'jfif' ||
            fileExtension === 'pjpeg' || fileExtension === 'png' || fileExtension === 'svg' || fileExtension === 'webp'  ||
            fileExtension === 'bmp'   || fileExtension === 'ico' || fileExtension === 'cur' 
            ?
            (
              <Row className='h-100'>
                <Col className='h-100'>  
                  <img
                    src={file} 
                    width="100%"                   
                  />
                </Col>
              </Row>
            )
            :
            (<></>)
          }  
      </Dialog>
    </>
  )
}

export default MCSWNewsletter;
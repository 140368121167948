/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next';   
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row,Col} from 'reactstrap';   
import { config } from '../../../utils/config';
import { callApi,showSweetAlert, getSessionItem, tracker} from '../../../utils/utils'; 
import { Checkbox } from 'primereact/checkbox';
import FieldText  from '../../../components/Controls/FieldText.jsx' 
import FieldTextArea  from '../../../components/Controls/FieldTextArea.jsx' 
import FieldNumber from '../../../components/Controls/FieldNumber.jsx'
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx' 
import FieldDropdown from 'components/Controls/FieldDropdown.jsx' 
import FieldCheckBox from 'components/Controls/FieldCheckbox.jsx' 
import { RadioButton } from 'primereact/radiobutton';
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import moment from "moment"; 
import { Sidebar } from 'primereact/sidebar';
import swal from '@sweetalert/with-react'; 
import { Tag } from 'primereact/tag';

const FasesDetalle = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['scoreCard', 'common']);
  const [errors, setErrors] = useState({}); 
  const [Filters, setFilters] = useState({});
  const [ClaFase, setClaFase] = useState(props.ClaFase === undefined ? 0 : props.ClaFase); 
  const [ClaActividad, setClaActividad] = useState(props.ClaActividad === undefined ? 0 : props.ClaActividad); 
  const [ClaProyecto, setClaProyecto] = useState(props.ClaProyecto === undefined ? 0 : props.ClaProyecto); 
  const [ClaEstatus, setClaEstatus] = useState(null);
  const [BajaLogica, setBajaLogica] = useState(null);  
  const [NomFase, setNomFase] = useState(null); 
  const [NomProyecto, setNomProyecto] = useState(null); 
  const [PorcAvance, setPorcAvance] = useState(null);  
  const [PorcPeso , setPorcPeso] = useState(null);  
  const [yesNoOptions, setYesNoOptions] = useState([`${t('common:Yes')}`, `${t('common:No')}`]);
  const [OpcionesResponsables, setOpcionesResponsables] = useState([]);
  const [Milestone, setMilestone] = useState(null); 
  const [Objetivo, setObjetivo] = useState(null); 
  const [Scope, setScope] = useState(null); 
  const [Orden, setOrden] = useState(null); 
  const [ClaEtapaFase, setClaEtapaFase] = useState(null); 
  const [ClaEstatusFase, setClaEstatusFase] = useState(null); 
  const [NomEstatusFase, setNomEstatusFase] = useState(null); 
  const [ClaResponsable , setClaResponsable ] = useState(null); 
  const [ClaAutorizador, setClaAutorizador] = useState(null); 
  const [NomResponsable, setNomResponsable] = useState(null); 
  const [NomAutorizador, setNomAutorizador] = useState(null); 
  const [FechaEstimadaInicio , setFechaEstimadaInicio ] = useState(null); 
  const [FechaEstimadaFin, setFechaEstimadaFin] = useState(null);   
  const [OpcionesEtapaFase, setOpcionesEtapaFase] = useState([]);
  const [FaseProyectoEntregables, setFaseProyectoEntregables] = useState([]);
  const [OriginalFaseProyectoEntregables, setOriginalFaseProyectoEntregables] = useState([]); 
  const [inplaceActive, setInplaceActive] = useState(false);

  useEffect(() => {   
    setFaseProyectoEntregables([]);
    if(props.IsVisible){ 
      setClaFase (props.ClaFase === undefined ? 0 : props.ClaFase);  
      setClaProyecto (props.ClaProyecto === undefined ? 0 : props.ClaProyecto); 
      obtenerResponsablesProyecto();
    }
  }, [props.IsVisible,props.ClaFase,props.ClaProyecto]); 

  useEffect(() => {    
  }, [props.Filters]); 


  const obtenerResponsablesProyecto = () => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/GetMswBSCColaboradorProyectosTeam`;  
    const paramsToService = { 
      ClaProyecto
    };   
    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => { 
      if (response.data[0].length >0)
      {  
        setOpcionesResponsables(response.data[0]);
        obtenerStage();
      }  
    });
  }

  const obtenerStage = () => { 
    const GetUrlEtapaFase = `${config.UrlApiProject}ScoreCard/getMSWEtapaFaseCmb`;  
    const paramsToService = {
      ClaProyecto
    };   
    callApi(GetUrlEtapaFase, 'POST', paramsToService, (response) => { 
      const EtapaList = response.data[0];
      if (EtapaList!==undefined)
      {  
        setOpcionesEtapaFase(EtapaList);
        obtenerInformacionFase();
      }  
    });
  }

  const obtenerInformacionFase = () => { 
    const GetFaseProyecto = `${config.UrlApiProject}ScoreCard/GetFaseProyecto`;  
    const paramsToService = { 
      ClaProyecto,
      ClaFase:props.ClaFase
    };   
 
    callApi(GetFaseProyecto, 'POST', paramsToService, (response) => {  
      const ClaseFase = response.data.FaseProyecto[0];
      const ClaseFaseProyectoEntregables = response.data.FaseProyectoEntregables;
      
      console.log(response.data);
      
      if (ClaseFase !== undefined)
      {  
        setNomProyecto(ClaseFase.NomProyecto); 
        setNomFase(ClaseFase.NomFase); 
        setObjetivo(ClaseFase.Objetivo); 
        setScope(ClaseFase.Scope);
        setOrden(ClaseFase.Orden);
        setClaEstatusFase(ClaseFase.ClaEstatusFase);
        setClaEtapaFase(ClaseFase.ClaEtapaFase);
        setNomEstatusFase(ClaseFase.NomEstatusFase);
        setClaResponsable(ClaseFase.ClaResponsable); 
        setNomResponsable(ClaseFase.NomResponsable); 
        setClaAutorizador(ClaseFase.ClaAutorizador); 
        setNomAutorizador(ClaseFase.NomAutorizador); 
        setFechaEstimadaInicio((ClaseFase.FechaEstimadaInicio!== null ? (new Date(ClaseFase.FechaEstimadaInicio)): null)); 
        setFechaEstimadaFin((ClaseFase.FechaEstimadaFin!== null ? (new Date(ClaseFase.FechaEstimadaFin)): null)); 
        setMilestone(ClaseFase.Milestone);
        setClaEstatus(ClaseFase.ClaEstatus);
        setBajaLogica(ClaseFase.Bajalogica);
        setNomFase(ClaseFase.NomFase);
        setPorcAvance(ClaseFase.PorcAvance);
        setPorcPeso(ClaseFase.PorcPeso);
        if (ClaseFase.ClaFase === null){ 
          setClaResponsable(getSessionItem('ClaUsuarioMod', null));
          setClaAutorizador(getSessionItem('ClaUsuarioMod', null));
        }

        if (ClaseFase.NomFase === null || ClaseFase.NomFase === ''){
          setInplaceActive(true);
        }else{
          setInplaceActive(false);
        }
      }
      if(ClaseFaseProyectoEntregables!==undefined){
        setFaseProyectoEntregables(ClaseFaseProyectoEntregables);
        setOriginalFaseProyectoEntregables(ClaseFaseProyectoEntregables);
      }
    });
  }

  const onChangeObjetivo = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.Objetivo;   
    setErrors(newErrors) 
    setObjetivo(e.target.value)
  }  

  const onChangeScope = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.Scope;   
    setErrors(newErrors) 
    setScope(e.target.value)
  }  

  const onClaResponsableChange = (props, e ) => {
    const newErrors = { ...errors } 
    delete newErrors.ClaResponsable;   
    setErrors(newErrors) 
    setClaResponsable(e.value)
  }; 
  
  const onClaAutorizadorChange = (props, e ) => {
    const newErrors = { ...errors } 
    delete newErrors.ClaAutorizador;   
    setErrors(newErrors) 
    setClaAutorizador(e.value)
  }; 
 
 const onNomFaseChange = (value) => {   
    setNomFase(value);
  }

  const onBlurNomFase = (value) => {   
    if (value === '' || value === null){
      setInplaceActive(true);
    }
    else{
      setInplaceActive(false);
    }
  }
  
const valitationSchemeSave = yup.object().shape({
  ClaResponsable:yup.mixed().required(t('common:RequiredField')).nullable(),
  ClaAutorizador:yup.mixed().required(t('common:RequiredField')).nullable(),
  Objetivo:yup.string().required(t('common:RequiredField')).nullable(), 
  ClaEstatusFase:yup.mixed().required(t('common:RequiredField')).nullable(),
  NomFase:yup.string().required(t('common:RequiredField')).nullable(),
  FaseProyectoEntregables: yup.array().of(
    yup.object().shape({
      Descripcion: yup.string().when('Bajalogica', {is: 0,
        then: yup.string().trim().required(t('common:RequiredField')).nullable(),
        otherwise: yup.string().nullable()
      }),
      FechaCompromiso:  yup.date().when('Bajalogica', {is: 0,
        then: yup.date().required(t('common:RequiredField')).nullable(),
        otherwise: yup.date().nullable()
      }),
      ClaResponsable:  yup.mixed().when('Bajalogica', {is: 0,
        then: yup.mixed().required(t('common:RequiredField')).nullable(),
        otherwise: yup.mixed().nullable()
      })
    })
  )
});  

 const save = async () => {
  const value = {
    ClaResponsable,
    ClaAutorizador,
    Objetivo,
    ClaEstatusFase,
    FaseProyectoEntregables,
    NomFase
  }
  const validator = new YupValidator(valitationSchemeSave);
  const esValido = await validator.validate(value);
  if (!esValido){ 
    console.log(validator.errors);
    setErrors(validator.errors); 
    showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
    return;
  }
  const urlWebServiceSave = `${config.UrlApiProject}ScoreCard/SaveFaseProyecto`;
  const paramsService = {
    ClaProyecto,
    ClaFase,
    NomFase,
    ClaResponsable,
    ClaAutorizador,
    Objetivo,
    Scope,
    ClaEtapaFase,
    ClaEstatusFase,
    FechaEstimadaInicio:(FechaEstimadaInicio!== null ? (new Date(FechaEstimadaInicio)): null),
    FechaEstimadaFin:(FechaEstimadaFin!== null ? (new Date(FechaEstimadaFin)): null),
    Milestone:(Milestone?1:0),
    Orden,
    ProyectoFaseEntregable:FaseProyectoEntregables
  }; 

  tracker.trackEvent({
    type: 'FasesDetalle', /* component name */
    action: 'SAVE_PHASE'
  }) 

  callApi(urlWebServiceSave, 'POST', paramsService, (response) => { 
    showSweetAlert('success', t('common:SuccessOperation'), 'success');
    onChangeFase(true);
  });
 } 

 const onChangeFase = (isSave) => {
    setErrors({});
    if (props.onClickClose !== null && typeof(props.onClickClose) === 'function'){
      props.onClickClose();
    }   

    if(isSave)
    {
      if (props.onChange !== null && typeof(props.onChange) === 'function'){
        props.onChange();
      }  
    } 
 }

 const onEsMilestoneChange = (e) => {   
  setMilestone(e.checked)
}

 const onOrdenChange = (e) => {  
  const newErrors = { ...errors }
  delete newErrors.Orden;   
  setErrors(newErrors);
  setOrden(e.target.value);
}

const onClaEtapaFaseChange = (props, e ) => {
  const newErrors = { ...errors } 
  delete newErrors.ClaEtapaFase;   
  setErrors(newErrors) 
  setClaEtapaFase(e.value)
}; 

 const close = async () => {
  setErrors({});
  if (props.onClickClose !== null){
    props.onClickClose();
  }   
}

const onDescripcionChange = (props, value ) => {
  const updatedProducts = [...FaseProyectoEntregables];
  updatedProducts[props.rowData.RowIndex][props.field] = value;
  setFaseProyectoEntregables(updatedProducts);  
  setOriginalFaseProyectoEntregables(updatedProducts);  
}; 

const onDescripcionBlur = (props, value ) => {
  const saveArray= getCurrentRowValues(props);
  if(value!==''){
    saveArray.Descripcion = value;
  }
}; 
const fieldsRequired = (rowData,props) =>  {  
  return (
    <>
      {errors[`FaseProyectoEntregables[${props.rowData.RowIndex}]`] != null ? (<i className="fas fa-exclamation-triangle invalid-color"></i>) : ""}
    </>
  );
}
const getCurrentRowValues = (props) => {
  const updatedProducts = [...props.value];
  const RowValues ={
      IdProyectoFaseEntregable: updatedProducts[props.rowIndex].IdProyectoFaseEntregable,
      ClaProyecto: updatedProducts[props.rowIndex].ClaProyecto,
      ClaFase: updatedProducts[props.rowIndex].ClaFase,
      Descripcion: updatedProducts[props.rowIndex].Descripcion,
      ClaResponsable:updatedProducts[props.rowIndex].ClaResponsable,
      ClaAutorizador:updatedProducts[props.rowIndex].ClaAutorizador, 
      FechaCompromiso: updatedProducts[props.rowIndex].FechaCompromiso, 
      FechaEntrega: updatedProducts[props.rowIndex].FechaEntrega
  }
  return RowValues;
}

const onFechaCompromisoChange = ( props, value ) => {  
  const updatedProducts = [...FaseProyectoEntregables];
  const saveArray= getCurrentRowValues(props); 
  saveArray.FechaCompromiso = new Date(value)
  updatedProducts[props.rowData.RowIndex].FechaCompromiso = value;
  setFaseProyectoEntregables(updatedProducts);  
  setOriginalFaseProyectoEntregables(updatedProducts);
}; 

const FechaCompromisoTemplate = (rowData) => {
  return (
    <>
      { (rowData.FechaCompromiso !== null ? moment(rowData.FechaCompromiso).format("MM/DD/YYYY") : null)}
    </>
  );
}

const FechaRealTemplate = (rowData) => {
  return (
    <>
      { (rowData.FechaEntrega !== null ? moment(rowData.FechaEntrega).format("MM/DD/YYYY") : null)}
    </>
  );
}

const onRowEditInit = (event) => {
  OriginalFaseProyectoEntregables[event.index] = { ...FaseProyectoEntregables[event.index] };
  setOriginalFaseProyectoEntregables(OriginalFaseProyectoEntregables); 
};

const DescripcionEditor = ( props ) => { 
  return (
    <div className="p-fluid">
      <FieldText
        name="Descripcion"
        value={props.rowData.Descripcion} 
        onChange={(e) => onDescripcionChange(props, e.target.value)}
        onBlur={(e) => onDescripcionBlur(props, e.target.value)}
        errors={errors[`FaseProyectoEntregables[${props.rowData.RowIndex}]`]} 
        maxLength={150}
      />
    </div>
  )
}; 

const FechaCompromisoEditor = ( props ) => {  
  return (
    <div className="p-fluid">
      <FieldCalendar
        name="FechaCompromiso"
        value={(props.rowData.FechaCompromiso!== null ? (new Date(props.rowData.FechaCompromiso)): null)}
        onChange={(e) => onFechaCompromisoChange(props, e.target.value)}
        errors={errors[`FaseProyectoEntregables[${props.rowData.RowIndex}]`]} 
      />  
    </div>
  )
}; 

const headerTemplate  = ( 
  <>
    <Row>
      <Col>
        {t('scoreCard:PhaseDeliverables')} 
      </Col>
      <Col className="text-right">
         
        <FieldButton className="p-button p-button-icon-only p-button-info rounded-button" onClick={(e) => addRow()}>
          <i className="pi pi-plus"></i>
        </FieldButton>

      </Col>
    </Row>
  </>
)

const addRow = async () => {
  const newRow = { 
    RowIndex: FaseProyectoEntregables.length,
    rowData:{ 
      ClaProyecto,
      ClaFase,
      Descripcion:'',
      NomResponsable: '',
      ClaResponsable:0,
      Bajalogica:0,
      FechaCompromiso:null,
      FechaEntrega:null
    },
    field: 'Descripcion',
    ClaProyecto,
    ClaFase,
    NomResponsable: '',
    ClaResponsable:0,
    Bajalogica:0,
    FechaCompromiso:null,
    FechaEntrega:null
  }; 
  OriginalFaseProyectoEntregables.push(newRow);
  setOriginalFaseProyectoEntregables(OriginalFaseProyectoEntregables);
  setFaseProyectoEntregables(OriginalFaseProyectoEntregables);
  setFilters({ ...Filters, arreglo:OriginalFaseProyectoEntregables, row:newRow.RowIndex});
}


const showActiveRows = (rows) => {
  const activeRows = [];
  if (rows.length>0) {
    rows.forEach((item, index) => {
      if (item.Bajalogica === 0) {
        item.RowIndex = index;
        activeRows.push({...item});
      }
    });
  } else {
    return [];
  }
  return activeRows; 
};

const handleRemoveClick = (rowData) => {
  const indexRow = rowData.RowIndex; // (rowData.rowIndex !== undefined ? rowData.rowIndex : e.rowIndex);
  const currentList = [...OriginalFaseProyectoEntregables];
  currentList[indexRow].Bajalogica = 1;
  setOriginalFaseProyectoEntregables(currentList); 
  setFaseProyectoEntregables(currentList); 
  setFilters({ ...Filters, arreglo:OriginalFaseProyectoEntregables, row:indexRow});
};


const onResponsableChange = (props, e ) => {
  const saveArray= getCurrentRowValues(props);
  const updatedProducts = [...FaseProyectoEntregables];
  const colaborador = OpcionesResponsables.filter(item => item.ClaColaborador ===e.value)[0];
  if(colaborador!==undefined){
    updatedProducts[props.rowData.RowIndex].NomResponsable = colaborador.NomColaborador;
  }
  updatedProducts[props.rowData.RowIndex].ClaResponsable = e.value;
  saveArray.ClaResponsable = e.value;
  setFaseProyectoEntregables(updatedProducts); 
  setOriginalFaseProyectoEntregables(updatedProducts);  
  // saveActividad(saveArray);
}; 

const ClaResponsableEditor = ( props ) => {
  return (
    <div className="p-fluid">
      <FieldDropdown  
        name="ClaResponsable"
        value={props.rowData.ClaResponsable}
        options={OpcionesResponsables}  
        optionValue="ClaColaborador"
        optionLabel="NomColaborador" 
        onChange={(e) => onResponsableChange(props,e)}
        errors={errors[`FaseProyectoEntregables[${props.rowData.RowIndex}]`]}
      />  
    </div>
  )
}; 

const customIcons = (
  <> 
    <div>
      <Row className="align-items-start border-bottom-red">
        <Col>
          <Row>
            <Col lg={8} md={12} sm={12} xs={12}>
              <Inplace 
                closable={false}
                active={inplaceActive}
                onToggle={(e) => setInplaceActive(e.value)}
                className="p-fluid"
              >
                <InplaceDisplay>
                  <span className="fa fa-pencil-alt default-color">  
                  </span>&nbsp; <b> {NomFase} </b>  
                </InplaceDisplay>
                <InplaceContent>
                  <div className="p-fluid"> 
                    <FieldText
                      name="NomFase"
                      value={NomFase} 
                      onBlur={(e) => onBlurNomFase(e.target.value)}
                      onChange={(e) => onNomFaseChange(e.target.value)}
                      autoFocus
                      placeholder={t('scoreCard:PlaceHolderFase')}
                      errors={errors} 
                      maxLength={250}
                    />
                  </div>
                </InplaceContent>
              </Inplace> 
            </Col>
            <Col lg={4} md={12} sm={12} xs={12}>
              <Row className="align-items-start">
                <Col lg={6} md={12} sm={12} xs={12} className="text-right"> 
                  <div className="p-fluid pb-2">
                    <FieldButton
                      type="button" 
                      onClick={(e) => close(e)}
                      label={t('common:Close')}
                      className="p-button-secondary no-border p-mr-2 rounded-button"
                      style={{ marginRight: '.25em' }}
                    /> 
                  </div>
                </Col>
                <Col lg={6} md={12} sm={12} xs={12}> 
                  <div className="p-fluid pb-2">
                    <FieldButton
                      type="button"
                      onClick={save} 
                      label={t('common:Save')}
                      className="p-button-success no-border p-mr-2 rounded-button"
                      style={{ marginRight: '.25em' }}
                    /> 
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col className="default-color">
              <span className="fa fa-folder"> </span>&nbsp; {NomProyecto}
            </Col>  
            <Col lg={4} md={12} sm={12} xs={12}>
              { 
                FechaEstimadaInicio !== null && FechaEstimadaFin !== null 
                ? (
                  <>
                    <span><b>{moment(FechaEstimadaInicio).format("LL")} </b> </span>&nbsp;
                    <span><b>{t('common:to')} </b> </span>&nbsp;
                    <span><b>{moment(FechaEstimadaFin).format("LL")} </b> </span>
                  </>
                )
                : <></>
              }
              
            </Col>
          </Row> 
          <Row>
            <Col>
              <span>
                <Tag className={ClaEstatusFase === 1 ? "primary" :  ClaEstatusFase === 2 ? "light-green" : "dark"}>{NomEstatusFase}</Tag> 
              </span>
            </Col>  
            <Col lg={4} md={12} sm={12} xs={12}>
              <Row className="align-items-start default-color">
                <Col lg={1} md={1} sm={12} xs={12}> 
                  <div className="p-fluid pb-2">
                    <FieldCheckBox
                      inputId="EsMilestone"
                      checked={Milestone===1||Milestone===true} 
                      onChange={onEsMilestoneChange}
                    />
                  </div>
                </Col>
                <Col lg={10} md={10} sm={12} xs={12}> 
                  <div className="p-fluid pb-2">
                    <span>{t('scoreCard:IsMilestone')}</span>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row> 
          <Row className="default-color">
            <Col>
              {
                     ClaFase > 0 ?

                        PorcPeso !== null ? (<b> {`${PorcPeso} ${t('scoreCard:PorcPesoFase')}`} </b> ) 
                        : <span className="orange-color"><>{t('scoreCard:PesoFaseNoAsignado')}</></span>
                        : <></>
                    } 
            </Col>  
            <Col lg={4} md={12} sm={12} xs={12}>
              <Row className="default-color">
                <Col lg={4} md={4} sm={12} xs={12}> 
                  <div className="p-fluid pb-2">
                    <span>{t('scoreCard:Order')}</span>
                  </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={12}> 
                  <div className="p-fluid pb-2">
                    <FieldNumber 
                      name="Orden" 
                      value={Orden}
                      onChange={onOrdenChange}
                      min={0}
                      useGrouping={false}
                      buttonLayout="horizontal"
                      errors={errors}
                    />  
                  </div>
                </Col> 
              </Row>
            </Col>
          </Row> 
        </Col> 
      </Row>  
    </div>
  </>
);

const onHideFase = () => {
 // document.body.classList.remove('bodynotscroll');
 if (props.onClickClose !== null){
   props.onClickClose();
 }   
} 

const BotonesTemplate = (rowData, e) =>  {   
  return (
    <>
      <FieldButton
        icon="pi pi-trash" 
        onClick={() => handleRemoveClick(rowData)}
        className="p-button-rounded p-button-warning"
      />
    </>
  );
}


    return (
      <>   
        <Sidebar blockScroll={true} icons={customIcons} showCloseIcon={false} visible={props.IsVisible} className="p-sidebar-lg" position="right" dismissable={false} baseZIndex={1000000} onHide={(e) => onHideFase()}>
          <Row className="pb-1 pt-1">
            <Col>
              <div className="p-fluid">
                <span className="spanController pb-1">
                  {t('scoreCard:Objetivo')}
                </span>
                <FieldTextArea 
                  name="Objetivo"
                  rows={2}
                  value={Objetivo} 
                  onChange={onChangeObjetivo}
                  errors={errors} 
                  maxLength={1500}
                />
              </div> 
            </Col>  
          </Row> 
          <Row className="pb-3 pt-1">
            <Col>
              <div className="p-fluid">
                <span className="spanController pb-1">
                  {t('scoreCard:Scope')}
                </span>
                <FieldTextArea 
                  name="Scope"
                  rows={2}
                  value={Scope} 
                  onChange={onChangeScope}
                  errors={errors} 
                  maxLength={1500}
                />
              </div> 
            </Col>  
          </Row> 
          <Row className="pb-3 pt-3">
            <Col>
              <div className="p-fluid">
                <FieldDropdown  
                  name="ClaResponsable"
                  value={ClaResponsable}
                  options={OpcionesResponsables}  
                  optionValue="ClaColaborador"
                  optionLabel="NomColaborador" 
                  label={t('scoreCard:Responsible')}
                  onChange={(e) => onClaResponsableChange(props,e)}
                  errors={errors}
                />  
              </div> 
            </Col>  
            <Col>
              <div className="p-fluid">
                <FieldDropdown  
                  name="ClaAutorizador"
                  value={ClaAutorizador}
                  options={OpcionesResponsables}  
                  optionValue="ClaColaborador"
                  optionLabel="NomColaborador" 
                  label={t('scoreCard:Authorizer')}
                  onChange={(e) => onClaAutorizadorChange(props,e)}
                  errors={errors}
                />  
              </div> 
            </Col>  
          </Row> 
          <Row className="pb-1 pt-3">
            <Col>
              <div className="p-fluid">
                <FieldDropdown  
                  name="ClaEtapaFase"
                  value={ClaEtapaFase}
                  options={OpcionesEtapaFase}  
                  optionValue="ClaEtapaFase"
                  optionLabel="NomEtapaFase" 
                  label={t('scoreCard:Stage')}
                  onChange={(e) => onClaEtapaFaseChange(props,e)}
                  errors={errors}
                />  
              </div> 
            </Col>  
            <Col>
           &nbsp;
            </Col>  
          </Row> 
          <Row className="pb-5 pt-5">
            <Col>
              <FieldDataTable 
                value={showActiveRows(FaseProyectoEntregables)} 
                header={headerTemplate}
                scrollable 
                scrollHeight="270px"
                className="editable-cells-table"
                editMode="cell" 
              >
                <Column body={fieldsRequired} headerStyle={{ width: '20px' }}></Column>   
                <Column body={BotonesTemplate} style={{ textAlign: 'center' }} headerStyle={{ width: '35px' }}></Column>  
                <Column field="Descripcion" columnKey="Descripcion" header={t('scoreCard:Description')} headerStyle={{ width: '150px' }} editor={(props) => DescripcionEditor(props)}></Column>
                <Column field="NomResponsable" columnKey="ClaResponsable" header={t('scoreCard:Responsible')} headerStyle={{ width: '150px' }} editor={(props) => ClaResponsableEditor(props)}></Column> 
                <Column field="FechaCompromiso" columnKey="FechaCompromiso" header={t('scoreCard:EstimatedDate')} headerStyle={{ width: '120px' }} body={FechaCompromisoTemplate} editor={(props) => FechaCompromisoEditor(props)}></Column>
                <Column field="FechaEntrega" columnKey="FechaEntrega" header={t('scoreCard:LastArtifactReviewed')} headerStyle={{ width: '80px' }} body={FechaRealTemplate}></Column>
              </FieldDataTable> 
            </Col>
          </Row>
        </Sidebar>
      </>  
        ); 
};
export default FasesDetalle;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from 'primereact/dialog';
import { config } from 'utils/config';
import { callApi } from 'utils/utils';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import { Column } from 'primereact/column';
import Highcharts from 'highcharts';
import { Col, Row } from 'reactstrap';
import moment from 'moment';

/**
 * 
 * @param {*} props 
 * Props:
 * - claProyecto
 * - idReporteMensual
 * - isVisible
 * - onHide
 * 
 * @returns dialog
 */
const ProjectPerformanceDialog = (props) => {  
  const { t } = useTranslation(['focusReport', 'orangePage', 'common']);   
  const [projectPerformance, setProjectPerformance] = useState({});
  const [kpiProgressEvaluationDetailList, setKPIProgressEvaluationDetailList] = useState([]);
  
  // #region Methods

  const getOrangePageProjectPerformanceDetail = (claProyecto, idReporteMensual) => {
    const serviceURL = `${config.UrlApiProject}ScoreCard/GetOrangePageProjectPerformanceDetail`;
    const params = {
      ClaProyecto: claProyecto,
      IdReporteAvanceMensualProyecto: idReporteMensual
    };
    callApi(serviceURL, 'POST', params, (response) => {
      const {data} = response;
      console.log("Project performance:", data.ProjectPerformance);
      console.log("KPI progress evaluation detail list:", data.KPIProgressEvaluationDetail);
      setProjectPerformance(data.ProjectPerformance);      
      setKPIProgressEvaluationDetailList(data.KPIProgressEvaluationDetail);              
    });
  };

  // #endregion

  // #region Events

  useEffect(() => {
    if (props.isVisible && props.claProyecto && props.idReporteMensual) {
      getOrangePageProjectPerformanceDetail(props.claProyecto, props.idReporteMensual);
    }
  }, [
    props.isVisible,
    props.claProyecto,
    props.idReporteMensual
  ]);

  const onHide = () =>{
    if (props.onHide !== null && typeof props.onHide === 'function') {
      props.onHide();
    }
  };

  // #endregion

  // #region Templates

  const projectPerformanceTemplate = (
    <>
      {
        projectPerformance && (
          <> 
            <Row>             
              <Col xs={{size: 3, offset: 6}} className='text-right'>
                {t('orangePage:Results')}
              </Col>
              <Col xs={3} className='text-right'>
                {t('orangePage:Points')}
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                {`50 % ${t('orangePage:ProjectProgress')}`}
              </Col>
              <Col xs={3} className='text-right'>
                {projectPerformance.ProgresoProyecto}
              </Col>
              <Col xs={3} className='text-right'>
                {projectPerformance.PuntosProgresoProyecto}
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                {`50 % ${t('orangePage:KPIProgressEvaluation')}`}
              </Col>
              <Col xs={3} className='text-right'>
                {projectPerformance.KPIResultados}              
              </Col>
              <Col xs={3} className='text-right'>
                {projectPerformance.PuntosKPIResultados}              			
              </Col>
            </Row>
            <Row className='pb-4'>
              <Col xs={9} className='text-right'>
                <b>
                  {t('orangePage:ProjectPerformance')}
                </b>
              </Col>
              <Col xs={3} className='text-right'>
                <b>
                  {projectPerformance.DesempenioProyecto}
                </b>   
              </Col>
            </Row>
          </>
        )
      }
    </>
  );

  const projectProgressTemplate = (
    <>
      {
        projectPerformance && (
          <>
            <h4>
              <span className="title-ProjectActivities">
                {t('orangePage:ProjectProgressEvaluation')}
              </span> 
            </h4>            
            <p>
              {t('orangePage:YourEstimatedProgressIs')}&nbsp;
              <b>
                {projectPerformance.LabelEstProgress}
              </b>
              {`, ${t('orangePage:YourRealProgressIs')}`}&nbsp;
              <b>
                {projectPerformance.LabelRealProgress}
              </b>
              {`, ${t('orangePage:YourProjectProgressIs')}`}&nbsp;
              <b>
                {`${projectPerformance.ProgresoProyecto} (${projectPerformance.ProgresoProyectoDivision})`}
              </b>
            </p>  
          </>
        )
      }
    </>
  );
  
  const headerDialog = (
    <>
      <Row className='pb-4'>
        <Col>
          <h1>
            {`${t('orangePage:ProjectPerformanceCalculation')} ${projectPerformance.AnioMesDate? moment(projectPerformance.AnioMesDate).format("MMMM, YYYY"): '' }:`}
          </h1>
        </Col>
      </Row>
    </>
  )
  const projectPerformanceCalculationTemplate = (
    <>
      {
        projectPerformance && (
          <>
            <Row>
              <Col xs={12} lg={6}>
                {projectPerformanceTemplate}
              </Col>
            </Row>
            <Row>
              <Col>
                {projectProgressTemplate}
              </Col>
            </Row>    
          </>
        )
      }
    </>
  );

  const sentidoMejoraColumnTemplate = (props) => {
    return (
      <>
        {props.ClaSentidoMejora === 1 ?  (<i className="fas fa-sort-amount-up"></i>): (<i className="fas fa-sort-amount-down"></i>)}
      </>
    )
  }; 

  const estatusMesColumnTemplate = (rowData) => {
    return (
      <span>
        {rowData.EstatusMes !== null?  Highcharts.numberFormat(rowData.EstatusMes, 2, '.', ',') : null} 
      </span>
    );
  };

  const targetMesColumnTemplate = (rowData) => {
    return (
      <span>
        {rowData.TargetMes !== null?  Highcharts.numberFormat(rowData.TargetMes, 2, '.', ',') : null} 
      </span>
    );
  };

  const progressTargetColumnTemplate = (rowData) => {
    return (
      <>
        <label>
          <b>{Highcharts.numberFormat(rowData.Accomplishment, 1)} %</b>
        </label>
      </>
    );
  };

  const pointsObtainedColumnTemplate = (rowData) => {
    return (
      <span>
        {rowData.PointsObtained !== null?  Highcharts.numberFormat(rowData.PointsObtained, 2, '.', ',') : null} 
      </span>
    );
  };

  const dataTableHeader = (
    <>
      <Row>
        <Col lg={10} md={10} xs={12}>
          <h4>
            <span className="title-ProjectActivities">
              {t('orangePage:KPIProgressEvaluation')}
            </span> 
          </h4>
        </Col>
        <Col lg={2} md={2} xs={12} className='text-right'>           
          { 
            projectPerformance && (
              projectPerformance.AnioMesDate !== null ? moment(projectPerformance.AnioMesDate).format("MMMM, YYYY"): null 
            )            
          }
        </Col>
      </Row>    
    </>
  );

  const datatableFooter = ( 
    <> 
      <div>
        <Column></Column>     
        <Column></Column>  
        <Column></Column>  
        <Column></Column>  
        <Column></Column>   
        <Column className="text-right" footer={() => (<b>{t('focusReport:Total')}</b>)}></Column>     
        <Column className="text-right" footer={() => (<b>{ (kpiProgressEvaluationDetailList.reduce((a,v) =>  a +=  v.Points === null ? 0 : v.Points , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>     
        <Column className="text-right" footer={() => (<b>{ (kpiProgressEvaluationDetailList.reduce((a,v) =>  a +=  v.PointsObtained === null ? 0 : v.PointsObtained , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>     
      </div>
    </>
  )

  const datatableTemplate = (
    <>
      {dataTableHeader}
      <FieldDataTable
        value={kpiProgressEvaluationDetailList}
        scrollable 
        className="table-header-background-blue" 
        footerColumnGroup={datatableFooter}
        paginator={false}
      >
        <Column
          field="NomProyectoKPIManagement" 
          header={t('focusReport:KPI')}
          columnKey="NomProyectoKPIManagement"
          headerStyle={{ width: '145px', textAlign: 'center' }}
        >
        </Column>
        <Column
          field="ClaSentidoMejora"
          className="text-center"
          header=''
          columnKey="ClaSentidoMejora"
          headerStyle={{ width: '20px' }}
          body={sentidoMejoraColumnTemplate}
        >
        </Column> 
        <Column
          field="EstatusMes"
          className="text-right"
          header={t('focusReport:Current')}
          columnKey="EstatusMes"
          headerStyle={{ width: '60px', textAlign: 'center' }}
          body={estatusMesColumnTemplate} 
        >
        </Column>
        <Column
          field="TargetMes"
          className="text-right"
          header={t('focusReport:Target')}
          columnKey="TargetMes"
          headerStyle={{ width: '40px', textAlign: 'center' }}
          body={targetMesColumnTemplate}
        >
        </Column> 
        <Column
          field="Accomplishment"
          className="text-center"
          header={t('focusReport:ProgressTarget')}
          columnKey="Accomplishment"
          headerStyle={{ width: '60px', textAlign: 'center' }}
          body={progressTargetColumnTemplate}
        >          
        </Column>
        <Column
          field="PorcPeso"
          className="text-right"
          header={t('focusReport:Weight')}
          columnKey="PorcPeso"
          headerStyle={{ width: '60px', textAlign: 'center' }}
        >
        </Column>
        <Column
          field="Points"
          className="text-right"
          header={t('focusReport:Score')}
          columnKey="Points"
          headerStyle={{ width: '60px', textAlign: 'center' }}
        >
        </Column>
        <Column
          field="PointsObtained"
          className="text-right"
          header={t('focusReport:PointObtained')}          
          columnKey="PointsObtained"
          headerStyle={{ width: '40px' }}
          body={pointsObtainedColumnTemplate}
        >
        </Column>
      </FieldDataTable>
    </>
  );

  const dialogTemplate = (
    <>
      <Dialog
        modal
        header={headerDialog}
        visible={props.isVisible}                        
        onHide={onHide}
        style={{
          width: '80vw',
        }}
      >
        <Row>
          <Col>
            {projectPerformanceCalculationTemplate}          
          </Col>
        </Row>
        <Row>
          <Col>
            {datatableTemplate}
          </Col>
        </Row>
      </Dialog>    
    </>
  );

  // #endregion

  return (
    <>
      {dialogTemplate}
    </>
  );
};

export default ProjectPerformanceDialog;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next';   
import FieldButton from '../../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row,Col, TabContent, TabPane , Nav, NavItem, NavLink} from 'reactstrap';   
import { config } from '../../../../utils/config';
import { callApi,showSweetAlert } from '../../../../utils/utils';  
import FieldDataTable from '../../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import FieldTextArea  from '../../../../components/Controls/FieldTextArea.jsx' 
import FieldUpload from '../../../../components/Controls/FieldUpload/FieldUpload.jsx';
import FieldDropdown from '../../../../components/Controls/FieldDropdown.jsx' 
import * as yup from 'yup';
import YupValidator from '../../../../utils/YupValidator'; 
import swal from '@sweetalert/with-react';
import SweatAlertCustomIcon from '../../../../components/SweatAlert/SweatAlertCustomIcon'
import { Tooltip } from 'primereact/tooltip';
import { Card } from 'primereact/card';

const KPITrendGraphAttachment = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['actionPlan', 'common']);
  const [errors, setErrors] = useState({});
  const [Comentarios, setComentarios] = useState(null); 
  const [UploadName, setUploadName] = useState(null);
  const [FieldData, setFieldData] = useState(null); 
  const [datafiles, setdatafiles] = useState([]);
  const [ActionPlanFileList, setActionPlanFileList] = useState([]);  
  const fileRef = useRef(null);  
  const [IdPuestoIndicadorActionPlanReviewLog, setIdPuestoIndicadorActionPlanReviewLog] = useState(props.IdPuestoIndicadorActionPlanReviewLog === undefined ? 0 : props.IdPuestoIndicadorActionPlanReviewLog);
  const [ClaPeriodo, setClaPeriodo] = useState(props.ClaPeriodo === undefined ? 0 : props.ClaPeriodo);
  const [ClaFechaReporte, setClaFechaReporte] = useState(props.ClaFechaReporte === undefined ? 0 : props.ClaFechaReporte);  
  const [Imagen, setImagen] = useState(null); 
  const [NombreArchivo, setNombreArchivo] = useState(null); 
  const [ExtensionArchivo, setExtensionArchivo] = useState(null); 
  const [ImagenBase64, setImagenBase64] = useState(null); 
  const [EsMostrarImagen, setEsMostrarImagen] = useState(false); 

  useEffect(() => {    
    if(props.IdPuestoIndicadorActionPlanReviewLog>0){
      verActionPlanReviewLog(props.IdPuestoIndicadorActionPlanReviewLog);
    }
  }, [props.IdPuestoIndicadorActionPlanReviewLog, props.ClaPuesto, props.ClaIndicador, props.ClaTipoIndicador]); 

  useEffect(() => {
  }, [props.Filters]); 

  
  const verActionPlanReviewLog = (nIdPuestoIndicadorActionPlanReviewLog) => { 
    const GetFileData = `${config.UrlApiProject}ScoreCard/GetKPITendenciaActionPlanReviewLogByID`;  
    const paramsToService = { 
      IdPuestoIndicadorActionPlanReviewLog:nIdPuestoIndicadorActionPlanReviewLog
    };   
    callApi(GetFileData, 'POST', paramsToService, (response) => {
      const AnexoDetalle = response.data.ActionPlanReviewLogData[0];
      setNombreArchivo(AnexoDetalle.NombreArchivo);
      setExtensionArchivo(AnexoDetalle.ExtensionArchivoOriginal);
      setComentarios(AnexoDetalle.Notas);
      if(AnexoDetalle.ArchivoAnexo!=null){
        setEsMostrarImagen(true);
        const base64Icon = `data:image/${AnexoDetalle.ExtensionArchivo};base64,${AnexoDetalle.ArchivoAnexo}`;
        setImagenBase64(base64Icon);
        setImagen(AnexoDetalle.ArchivoAnexo);
      }
    });
  }

  const onChangeComentarios = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.Comentarios;   
    setErrors(newErrors) 
    setComentarios(e.target.value)
  } 
  
const valitationScheme = yup.object().shape({
  Comentarios: yup.string().required(t('common:RequiredField')).nullable()
});
 
const save = async () => {
  const value = {    
    Comentarios
    };

  const validator = new YupValidator(valitationScheme);
  const esValido = await validator.validate(value);

  if (!esValido){   
    setErrors(validator.errors); 
    showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');   
    return;
  } 
  const urlWebServiceSave = `${config.UrlApiProject}ScoreCard/SaveActionPlanReviewLog`;
  const paramsService = {
    IdPuestoIndicadorActionPlanReviewLog:(props.IdPuestoIndicadorActionPlanReviewLog === undefined ? 0 : props.IdPuestoIndicadorActionPlanReviewLog),
    ClaPuesto:props.ClaPuesto,
    ClaIndicador:props.ClaIndicador,
    ClaTipoIndicador:props.ClaTipoIndicador,
    ArchivoAnexo:(datafiles[0]!==undefined?datafiles[0].FileData:(Imagen===null?null:Imagen)),
    NombreArchivo:(datafiles[0]!==undefined?datafiles[0].FileName:(NombreArchivo===null?null:NombreArchivo)),
    ExtensionArchivo:(datafiles[0]!==undefined?datafiles[0].FileExtension:(ExtensionArchivo===null?null:ExtensionArchivo)),
      Notas:Comentarios
  };
  callApi(urlWebServiceSave, 'POST', paramsService, (response) => { 
    setComentarios(null);
    setUploadName(null);
    setFieldData(null); 
    setdatafiles([]); 
    showSweetAlert('success', t('common:SuccessOperation'), 'success');   
    if(props.AfterSaveAttachment !== undefined){
      props.AfterSaveAttachment(props.ClaPuesto,props.ClaIndicador,props.ClaTipoIndicador);
    }    
    if(typeof(props.onCloseClick) !== 'undefined' && typeof(props.onCloseClick) === 'function'){
      props.onCloseClick();
    }
 })
};


 const confirmationMessageHTML = (
   <div>
     <SweatAlertCustomIcon title={t('common:SubmitConfirmation')} letter={t('common:QuestionMark')}></SweatAlertCustomIcon> 
     <div>{t('focusReport:SubmitRemoveDocument')}. </div> 
   </div>  
 )


 
const onConfirmRemoveSubmit = (nIdArchivo) => {  
  swal({ 
    buttons: {
      cancelar: {
        text: "Cancel",
        value: "cancel",
        className: "rounded-button danger"
      },
      continuar: {
        text: "Continue",
        value: "continue",
        className: "rounded-button light-green"
      }
    },
    content: confirmationMessageHTML
  }).then((value) => {
    switch (value) { 
      case "continue":
        // run function
        onSubmit(nIdArchivo);
        break; 
      default:
        break;
    }
  }); 
}

const onSubmit = async (nIdArchivo) => { 
  const paramsService = {
    IdArchivo: nIdArchivo
 };   
 const SubmitBajaAnexosActionPlan = `${config.UrlApiProject}ScoreCard/BajaAnexosActionPlanFileById`;
 callApi(SubmitBajaAnexosActionPlan, 'POST', paramsService, (response) => { 
    obtenerInformacionActionPlanFileAttachtment(); 
    props.obtenerInformacionActionPlanFile !== undefined? props.obtenerInformacionActionPlanFile(IdCSF, ClaPeriodo, ClaFechaReporte) : "";      
 });   
} 

const onChangeUpload = (e) => {  
  if(e===null){
    return;
  } 
  setdatafiles(e);
  setImagenBase64(null);
  if(e[0]!==undefined){
    setEsMostrarImagen(true);
    setImagenBase64(e[0].objectURL);
  }
}; 

const removerImagen = () => {
  setdatafiles([]);
  setEsMostrarImagen(false);
  setImagenBase64(null);
  setImagen(null); 
}

 
const footer = (
  <> 
    <FieldButton 
      label={t('focusReport:Remove')}
      className="p-button-danger no-border p-mr-2" 
      onClick={()=>removerImagen()} 
      icon="pi pi-times" 
      style={{ marginLeft: '0.5em' }}
    />
  </>
);

    return (
      <>     
        <div className="p-component">
          <Row className="pt-2">
            <Col lg={12} md={12} sm={12} xs={12}> 
              <span className="bold">{t('focusReport:Comments')}</span> 
            </Col>
          </Row>  
          <Row className="pt-2">
            <Col>
              <div className="p-fluid"> 
                <FieldTextArea 
                  name="Comentarios"
                  rows={3}
                  value={Comentarios} 
                  onChange={onChangeComentarios}
                  errors={errors} 
                  maxLength={1000}
                />
              </div>
            </Col>
          </Row> 
          <Row className="pt-2">
            <Col lg={12} md={12} sm={12} xs={12}> 
              <span className="bold">{t('focusReport:File')}</span> 
            </Col>
          </Row>  
          <Row className="pt-2">
            <Col>

              {
                        EsMostrarImagen && ImagenBase64!=null ? (
                          <>
                            <Row> 
                              <Col lg={12} md={12} sm={12} xs={12}>
                                <Card 
                                  footer={footer}  
                                >
                                  <p className="m-0">
                                    <img 
                                      alt={NombreArchivo} 
                                      src={ImagenBase64} 
                                      role="presentation" 
                                      width={100}
                                      height={100}
                                    />
                                  </p>
                                </Card> 
                              </Col> 
                            </Row>  
                          </>
                        ):(
                          <>
                            <Row>
                              <Col lg={12} md={12} sm={12} xs={12}>
                                <div className="p-fluid p-field p-col-12"> 
                                  <FieldUpload
                                    files={datafiles} 
                                    onChange={onChangeUpload}
                                  >
                                  </FieldUpload>
                                </div>
                              </Col> 
                            </Row>  
                          </>
                        )
                      }

            
            </Col>
          </Row>   
          <Row className="pt-3">
            <Col className="text-right">
              <FieldButton
                label={t('common:Close')}
                icon="fas fa-times"
                className="warning no-border p-mr-2 rounded-button"
                onClick={typeof(props.onCloseClick) !== 'undefined' && typeof(props.onCloseClick) === 'function' ? props.onCloseClick : null}
              />
              <FieldButton
                type="button" 
                onClick={save}
                label={t('common:Save')}
                className="p-button-success no-border p-mr-2 rounded-button"
                style={{ marginRight: '.25em' }}
              /> 
            </Col>
          </Row>
        </div>
       
      </> 
        ); 
};
export default KPITrendGraphAttachment;
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef, useEffect } from 'react';
import { config } from '../../utils/config';
import { callApi, callKrakenApi } from '../../utils/utils';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import '../../assets/css/PivotGridBase.css';
import { Dropdown } from 'primereact/dropdown';
import Chart, { AdaptiveLayout, CommonSeriesSettings, Size, Tooltip } from 'devextreme-react/chart';
import PivotGrid, { FieldChooser } from 'devextreme-react/pivot-grid';
import { Sidebar } from 'primereact/sidebar';
import FieldButton from '../../components/Controls/FieldButton/FieldButton.jsx';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { ToggleButton } from 'primereact/togglebutton';
import {
  FormGroup,
  Label,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  CardTitle,
  CardFooter,
  InputGroup,
  InputGroupText,
  TabContent, 
  TabPane, 
  Nav, 
  NavItem, 
  NavLink
} from 'reactstrap';
import PocketMarginFilters from './PocketMarginFilters.js';
import PocketMarginSummary from './PocketMarginSummary.js';
import PocketMarginFilterBarActions from './PocketMarginFilterBarActions.js';
import PocketMarginFollowupSegment from './PocketMarginFollowupSegment.jsx';
import PocketInconsistenciesSummary from './PocketInconsistenciesSummary.js';
import PocketMarginSummaryRDS from './PocketMarginSummaryRDS';
import { useTranslation } from 'react-i18next';
import { TabView, TabPanel } from 'primereact/tabview';
import DataGrid, {
  ColumnFixing,
  HeaderFilter,
  Paging,
  StateStoring,
} from 'devextreme-react/data-grid';
import Joyride, { CallBackProps, STATUS, Step, StoreHelpers } from 'react-joyride'; 
import StepsTourPocketMargin from './StepsTourPocketMargin';
import PageContent from '../../layouts/PageContent';  
import FieldToogleSwitch from '../../components/Controls/FieldToogleSwitch/FieldToogleSwitch';

/* ################## Finish import section ################## */

const PocketMarginResults = (props) => {

  const { t } = useTranslation(['formulario', 'common']);
  const [RdSDetallePM, setRdSDetallePM] = useState([]);
  const [RdSDetallePMSoloRDS, setRdSDetallePMSoloRDS] = useState([]);
  const [RdSDetallePMBucket, setRdSDetallePMBucket] = useState([]);
  const [filtersData, setFiltersData] = useState({}); 
  const [pageBucketIndex, setpageBucketIndex] = useState({}); 
  const dataGridSummary = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [valueFromPivot, setValueFromPivot] = useState({});
  const [RdSDetalleInconsistenciesPM, setRdSDetalleInconsistenciesPM] = useState([]);
  const [headerTitle, setHeaderTitle] = useState();
  /* mantener state entre tabs */ 
  const [Anio, setAnio] = useState(null);
  const [Mes, setMes] = useState(null);
  const [Division, setDivision] = useState(null);
  const [Segmento, setSegmento] = useState(null);
  const [GrupoEstadistico3, setGrupoEstadistico3] = useState(null);
  const [EsMostrarSoloConfiables, setEsMostrarSoloConfiables] = useState(null);
  
  const [AnioInc, setAnioInc] = useState(null);
  const [AnioMesInc, setAnioMesInc] = useState(null);
  const [ClaGerenciaRegionalInc, setClaGerenciaRegionalInc] = useState(null);
  const [IsRunTour, setIsRunTour] = useState(false); 

  
  const [showCompareVsSalesTons, setShowCompareVsSalesTons] = useState(true);
  const [showOnlyRds, setShowOnlyRds] = useState(false);
  const [yesNoOptions, setYesNoOptions] = useState([`${t('common:On')}`, `${t('common:Off')}`]);
  const [esSoloInformacionRDS, setEsSoloInformacionRDS] = useState(false);
  /* ########### End useState declarations ########### */
  

  const onSearch = (filters) => {
    let urlWebService = `${config.UrlApiProject}Rentabilidad/GetDetallePM`;
 
    let paramsService = {
      ...filters,
    };

    setFiltersData(filters);
     
    callApi(urlWebService, 'POST', paramsService, (response) => {
      setRdSDetallePM(response.data.RdSDetallePM);
    });
     
    paramsService = {
      ...filters,
      EsSoloInformacionRDS: 1
    };

    callApi(urlWebService, 'POST', paramsService, (response) => {
      setRdSDetallePMSoloRDS(response.data.RdSDetallePM);
    });


    urlWebService = `${config.UrlApiProject}Rentabilidad/GetDetallePMBucket`;

      paramsService = {
        ...filters,
      };
    
     
      callApi(urlWebService, 'POST', paramsService, (response) => {
        response.data.columns[0].fixed = true;
        response.data.columns[0].fixedPosition = 'left';
        response.data.columns[0].headerFilter = {
          allowSearch: true,
        };
        response.data.columns[1].fixed = true;
        response.data.columns[1].fixedPosition = 'left';
        response.data.columns[1].headerFilter = {
          allowSearch: true,
        };
        response.data.columns[2].fixed = true;
        response.data.columns[2].fixedPosition = 'left';
        response.data.columns[2].headerFilter = {
          allowSearch: true,
        };
        response.data.columns[3].fixed = true;
        response.data.columns[3].fixedPosition = 'left';
        response.data.columns[3].headerFilter = {
          allowSearch: true,
        };

        localStorage.removeItem('gBucketPersists');
 
        setRdSDetallePMBucket(response.data); 
      });
     
      // select the first tab...
      setActiveIndex(0);
  };
 
  const getValueFromPivot = (value) => {
    setValueFromPivot(value);
    setFilterViewActive(false);
  }
    
  const onPocketMarginFollowUpSelectionChange  = (filters) => {
     setAnio(filters.Anio);
     setMes(filters.Mes);
     setDivision(filters.ClaGerenciaRegional);
     setGrupoEstadistico3(filters.ClaGrupoEstadistico3);
     setEsMostrarSoloConfiables(filters.EsMostrarSoloConfiables); 
     setSegmento(filters.ClaMarketSegment);
  } 

  const onPocketInconsistenciesChange  = (filters) => { 
    setAnioInc(filters.Anio);
    setAnioMesInc(filters.AnioMes); 
    setClaGerenciaRegionalInc(filters.ClaGerenciaRegional);
    setEsMostrarSoloConfiables(filters.EsMostrarSoloConfiables);
 } 

 const onClickDetailProduct = (row) =>{
    setAnio(row.Anio);
    setMes(row.AnioMes);
    setDivision(row.ClaGerenciaRegional);
    setGrupoEstadistico3(row.ClaGrupoEstadistico3);  
    setSegmento(row.ClaMarketSegment);
    setActiveIndex(3);
  }
 
  const onStartTour = () =>{ 
    setIsRunTour(true);
  }

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setIsRunTour(false);
    } 
  }; 

  useEffect(() => {
    // defaults...
    const filtersData = {};
    filtersData.Anio = null;
    filtersData.Divisiones = null;
    filtersData.DivisionesList = null;
    filtersData.EsUltimos12Meses = true;

    setFiltersData(filtersData);

  }, []);  
     

    return (
      <>
        <PageContent title={t('formulario:PocketMargin')}>  
          <Row style={{ display: activeIndex === 3 || activeIndex === 2 ? 'none' : 'block' }}>
            <Col lg={12} md={12} sm={12} xs={12}>  
              <div className="card-header bg-card-header-grid">
                <div className="p-toolbar p-component">
                  <div className="p-toolbar-group-left">
                    <div className="card-title"> 
                      <span>
                      &nbsp;
                      </span>
                    </div>  
                  </div>
             
                  <div className="p-toolbar-group-right"> 
                    <FieldButton icon="fa fa-question" className="p-button-help" onClick={onStartTour}></FieldButton>
                  </div>
                </div> 
              </div>

            </Col>
          </Row> 
        
          <div className="card"> 
            <Joyride
              callback={handleJoyrideCallback}
              continuous={true}  
              run={IsRunTour}
              scrollToFirstStep={true}
              showProgress={true}
              showSkipButton={true}
              steps={StepsTourPocketMargin.steps}
              disableBeacon={true}
              styles={{
              options: {
                 // modal arrow and background color
                 arrowColor: "#eee",
                 backgroundColor: "#eee", 
                 // button color
                 primaryColor: "mediumaquamarine",
                 // text color
                 textColor: "#333", 
                 // zindex of modal
                 zIndex: 1000
             }
          }}
            />  
            <div style={{ display: activeIndex === 3 || activeIndex === 2 ? 'none' : 'block' }}>
              <PocketMarginFilterBarActions onSearch={onSearch} defultFilters={filtersData}></PocketMarginFilterBarActions>
            </div>
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}> 
              <TabPanel header={t('formulario:Summarytab')} headerClassName="nav-item">  
                <Row> 
                  <Col lg={8} md={8} sm={12} xs={12}> 
                    { esSoloInformacionRDS === false ? <small><label>Information currently displayed in table below shows comparison of <b className="primary-color"><i>Sales Tons vs Tons Reliable</i></b>, toggle ON/OFF filter to ON to switch to <b>Profit-to-Serve data</b>.</label></small>
                    : <small><label>Information currently displayed in table below shows comparison of <b className="primary-color"><i>Profit-to-Serve data</i></b>, toggle ON/OFF filter to OFF to switch to <b>Sales Tons vs Tons Reliable</b>.</label></small> }
                  </Col>
                  <Col lg={4} md={4} sm={12} xs={12} className="text-right">
                    <FieldToogleSwitch
                      id="EsSoloInformacionRDS"
                      checked={esSoloInformacionRDS}
                      onChange={setEsSoloInformacionRDS} 
                      optionLabels={yesNoOptions}
                    /> 
                  </Col> 
                </Row> 
                <Row> 
                  <Col lg={12} md={12} sm={12} xs={12}> 
                  &nbsp;
                  </Col>
                </Row>
                { esSoloInformacionRDS ? (<PocketMarginSummaryRDS data={RdSDetallePMSoloRDS}></PocketMarginSummaryRDS>) : (<PocketMarginSummary onclick={value => getValueFromPivot(value)} data={RdSDetallePM}></PocketMarginSummary>) }
                
              </TabPanel>
              <TabPanel header={t('formulario:BucketDetail')} headerClassName="nav-item flex-sm-fill PM-Step-3">
                <DataGrid
                  allowColumnReordering={true}
                  dataSource={RdSDetallePMBucket.rows}
                  defaultColumns={RdSDetallePMBucket.columns}
                  showBorders={true}
                  rowAlternationEnabled={true}
                  height={470}
                  defaultPageSize={150}
                  noDataText={t('common:NoData')}
                  ref={dataGridSummary}
                  allowSorting={false}
                  sorting={false}
                >
                  <StateStoring enabled={true} type="localStorage" storageKey="gBucketPersists" />
                  <HeaderFilter visible={true} />
                  <ColumnFixing enabled={false} />
                  <Paging defaultPageSize={150} pageIndex={pageBucketIndex} />
                </DataGrid>
              </TabPanel>
              <TabPanel header={t('formulario:Inconsistencies')} headerClassName="nav-item flex-sm-fill PM-Step-4">
                <PocketInconsistenciesSummary filters={filtersData} onClickDetailProduct={onClickDetailProduct} onSelectionChange={onPocketInconsistenciesChange} Anio={AnioInc} AnioMes={AnioMesInc} ClaGerenciaRegional={ClaGerenciaRegionalInc}></PocketInconsistenciesSummary> 
              </TabPanel>
              <TabPanel header={t('formulario:STATISTICALGROUP3DETAIL')} headerClassName="nav-item flex-sm-fill PM-Step-5"> 
                <PocketMarginFollowupSegment filters={filtersData} onSelectionChange={onPocketMarginFollowUpSelectionChange} Anio={Anio} Mes={Mes} Division={Division} GrupoEstadistico3={GrupoEstadistico3} Segmento={Segmento}></PocketMarginFollowupSegment>
              </TabPanel>  
            </TabView>
          </div> 
        </PageContent>
      </>
    ); 
};
export default PocketMarginResults;

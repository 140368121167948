import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import moment from "moment";
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { config } from 'utils/config';
import { callApi } from 'utils/utils';

const UsageTrackingByUserDialog = (props) => {
  const {t, i18n} = useTranslation(['it', 'common']);
  const [UserTrackingSummary, setUserTrackingSummary] = useState({});
  const [UserTrackingSessions, setUserTrackingSessions] = useState([]);  
  const [IsDialogVisible, setIsDialogVisible] = useState(false);
  const [TableExpandedRows, setTableExpandedRows] = useState(null);

  const getUsageTrackingInfoByUser = (
    claUsuario, 
    startDate, 
    endDate,
    claTrackingPlataforma,
    url,
    navegador,
    screenWidth,
    screenHeight,
    sistemaOperativo,
    country,
    city    
    ) => {
    const serviceURL = `${config.UrlApiProject}IT/GetITUsageTrackingInfoByUser`;       
    const paramsService = {
      ClaUsuario: claUsuario,
      StartDate: startDate,
      EndDate: endDate,
      ClaTrackingPlataforma: claTrackingPlataforma,
      URL: url,
      Navegador: navegador,
      ScreenWidth: screenWidth,
      ScreenHeight: screenHeight,
      SistemaOperativo: sistemaOperativo,
      Country: country,
      City: city      
    };  
    callApi(serviceURL, 'POST', paramsService, (response) => {               
      setUserTrackingSummary(response.data.userTrackingSummary);   
      setUserTrackingSessions(response.data.userTrackingSessions);   
    });
  };

  const getFriendlyDate = () => {
    let friendlyDate = '';
    const startDateMoment = moment(props.startDate);
    const endDateMoment =  moment(props.endDate);
    const startDate = startDateMoment.format("DD MMM YYYY");
    const endDate = endDateMoment.format("DD MMM YYYY");
    if (startDateMoment.isSame(endDate)) {
      friendlyDate = startDate;
    } else {
      friendlyDate = `${startDate} - ${endDate}`;
    }
    return friendlyDate;
  };

  const onHide = () => {
    if (props.onHide) {
      props.onHide();
    }
  };

  const dialogHeaderTemplate = (
    <>
      <Row className='align-items-start'>
        <Col lg={8} md={8} sm={7} xs={7}>
          <h1>
            {UserTrackingSummary.NombreCompleto}            
            <small>
              ({t('usageTracking:UserID')}: {UserTrackingSummary.ClaUsuario})
            </small>
          </h1>
          <p>
            <b>{t('usageTracking:EventDate')}:</b> {getFriendlyDate()}&nbsp;
            <b>{t('usageTracking:NumberSessions')}:</b> {UserTrackingSummary.Sessions}
          </p>
        </Col>        
      </Row>
    </>
  ); 

  const tebleRowExpansionTemplate = (data) => {
    return (
      <div className="orders-subtable">
        <h5>{t('usageTracking:EventsFor')}: {data.UUID}</h5>
        <FieldDataTable 
          className="table-header-background-green"
          value={data.Events} 
          paginator={false}          
        > 
          <Column field="Row" header="#" sortable style={{ width: '70px' }}></Column>         
          <Column field="FechaTrackingEventLog" header={t('usageTracking:EventDate')} sortable style={{ width: '250px' }}></Column>
          <Column field="URL" header={t('usageTracking:URL')} sortable style={{ width: '250px' }}></Column>   
          <Column field="Accion" header={t('usageTracking:Action')} sortable style={{ width: '200px' }}></Column>          
          <Column field="Duration" header={t('usageTracking:Duration')} sortable style={{ width: '100px' }}></Column>          
        </FieldDataTable>
      </div>
    );
  }

  useEffect(() => {                 
    setIsDialogVisible(props.isVisible);
    if (props.isVisible) {
      setUserTrackingSummary({});
      setUserTrackingSessions([]);
      setTableExpandedRows([]);
      getUsageTrackingInfoByUser(
        props.claUsuario, 
        props.startDate,        
        props.endDate,
        props.claTrackingPlataforma, 
        props.url,
        props.navegador,
        props.screenWidth,
        props.screenHeight,
        props.sistemaOperativo,
        props.country,
        props.city           
      );
    }
  }, [
    props.isVisible, 
    props.claUsuario, 
    props.startDate, 
    props.endDate,
    props.claTrackingPlataforma, 
    props.url,
    props.navegador,
    props.screenWidth,
    props.screenHeight,
    props.sistemaOperativo,
    props.country,
    props.city    
  ]);

  return (
    <>
      <Dialog 
        header={dialogHeaderTemplate} 
        visible={IsDialogVisible} 
        onHide={onHide} 
        breakpoints={{'80vw': '80vw', '640px': '100vw'}} 
        style={{width: '80vw'}}
      >
        <FieldDataTable
          value={UserTrackingSessions} 
          expandedRows={TableExpandedRows} 
          onRowToggle={(e) => setTableExpandedRows(e.data)}          
          rowExpansionTemplate={tebleRowExpansionTemplate} 
          dataKey="UUID" 
          paginator={false}
        >
          <Column expander style={{ width: '3em' }} />
          <Column field="UUID" header={t('usageTracking:Session')} sortable style={{ width: '300px' }} />
          <Column field="MinFechaTrackingEventLog" header={t('usageTracking:Start')} sortable style={{ width: '200px' }} />
          <Column field="MaxFechaTrackingEventLog" header={t('usageTracking:End')} sortable style={{ width: '200px' }} />
          <Column field="City" header={t('usageTracking:City')} sortable style={{ width: '150px' }} />
          <Column field="Country" header={t('usageTracking:Country')} sortable style={{ width: '150px' }} />
          <Column field="PageViews" header={t('usageTracking:PagesVisited')} sortable className='text-right' style={{ width: '100px' }} />     
        </FieldDataTable>      
      </Dialog>
    </>
  )
}

export default UsageTrackingByUserDialog
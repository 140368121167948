import PageContent from 'layouts/PageContent';
import React from 'react';
import { Row, Col } from 'reactstrap';
import SolicitudMaterialList from './SolicitudMaterialList';
import { useTranslation } from 'react-i18next';


const SolicitudMaterialListScreen = (props) => {
  const {t, i18n} = useTranslation(['operations', 'common']);

  return (
    <PageContent title={t('operations:solicitudMaterialListTitle')}>
      <Row>
        <Col>
          <SolicitudMaterialList />
        </Col>
      </Row>
    </PageContent>
  );
}

export default SolicitudMaterialListScreen;
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next';  
import { Row, Col } from 'reactstrap';
import { config } from '../../../../utils/config';
import { callApi } from '../../../../utils/utils';
import FieldText from 'components/Controls/FieldText';
import EditorText from 'components/Controls/EditorText/EditorText';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import * as yup from 'yup';
import YupValidator from '../../../../utils/YupValidator'; 
import SweatAlertCustomIcon from 'components/SweatAlert/SweatAlertCustomIcon';
import swal from '@sweetalert/with-react';

const SurveyManagementPageEdit = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['survey', 'common']);
  const [TipoCampoCmb, setTipoCampoCmb] = useState([]);    
  const [Titulo, setTitulo] = useState(props.Titulo === undefined ? 0 : props.Titulo);    
  const [errors, setErrors] = useState({});
  const [Descripcion, setDescripcion] = useState(null);
  const [DescripcionHtml, setDescripcionHtml] = useState(null);  
  const [IdEncuesta, setIdEncuesta] = useState(null);
  const [IdEncuestaPagina, setIdEncuestaPagina] = useState(null);    

  useEffect(() => {
    setIdEncuesta(props.IdEncuesta === undefined ? null : props.IdEncuesta);
    setIdEncuestaPagina(props.IdEncuestaPagina === undefined ? 0 : props.IdEncuestaPagina);    
    setDescripcion(props.Descripcion === undefined ? null : props.Descripcion);
    setDescripcionHtml(props.DescripcionHtml === undefined ? null : props.DescripcionHtml);
  }, [props.IdEncuestaPagina, props.ForceRefresh, props.Descripcion, props.DescripcionHtml ]);

  const onTituloPaginaChange = (value) => {
    const newErrors = { ...errors };
    delete newErrors.Titulo;
    setErrors(newErrors);

    setTitulo(value);
  };
  const onSiguientePregunta = () => {
    console.log('Siguiente Pregunta');
  };

  const onEditorDescripcionChange = (ehtml, etext) => {
    setDescripcionHtml(ehtml);
    setDescripcion(etext);    
  };
  
  const onClicClosedEdit = () => {
    if (
      props.OcultarEditarSurveyPage !== null &&
      props.OcultarEditarSurveyPage !== 'undefined' &&
      typeof props.OcultarEditarSurveyPage === 'function'
    ) {
      props.OcultarEditarSurveyPage();
    }  
  }

  const onSaveClick = async () => {
    const value = { 
          IdEncuestaPagina,
          IdEncuesta,         
          Titulo,
          Descripcion,
          DescripcionHtml,
          IdAccion:1
        };

    const validator = new YupValidator(valitationSchemeSave);
    const esValido = await validator.validate(value);
    if (!esValido) {
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      return;
    }

    const paramsService = value;

    const SaveSurvey = `${config.UrlApiProject}HR/SaveSurveyPagina`;

    callApi(SaveSurvey, 'POST', paramsService, (response) => {      
      // setIdEncuestaPagina(response.data.idEncuestaPagina)      
      props.OcultarEditarSurveyPage(); 
    });

  };

  const onConfirmBaja = () => {

    swal({ 
      buttons: {
        cancelar: {
          text: t('common:Cancel'),
          value: "cancel",
          className: "rounded-button danger"
        },
        continuar: {
          text: t('common:Continue'),
          value: "continue",
          className: "rounded-button light-green"
        }
      },
      content: confirmationMessageHTML
    }).then((value) => {
      switch (value) { 
        case "continue":
          bajasurveyManagementPage();          
          break; 
        default:
          break;
      }
    });
  }

  const bajasurveyManagementPage = async () => { 
    
    const urlWebServiceSave = `${config.UrlApiProject}HR/SaveSurveyPagina`;
    const paramsService = { 
        IdEncuesta,  
        IdEncuestaPagina,
        IdAccion: 3
    }; 
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {       
      if (props.OcultarEditarSurveyPage !== null && props.OcultarEditarSurveyPage !== 'undefined' && typeof(props.OcultarEditarSurveyPage) === 'function'){
        props.OcultarEditarSurveyPage();
      }
    });
  }

  const confirmationMessageHTML = (
    <div>
      <SweatAlertCustomIcon title={t('common:SubmitConfirmation')} letter={t('common:QuestionMark')}></SweatAlertCustomIcon> 
      <div>{t('survey:SubmitCancelled')}.</div> 
    </div>  
   )

  const valitationSchemeSave = yup.object().shape({    
    Titulo: yup.mixed().required(t('common:RequiredField')).nullable(),    
  });


  return (
    <>
      <div className='survey-edit-container'>
        <div className='survey-edit-body'>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
          &nbsp;
            </Col>
          </Row>      
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <span className="survey-edit-label">{t('survey:PageTitle')}</span>
              <div className="p-fluid">
                <FieldText
                  name="Titulo"
                  value={Titulo}
                  onChange={(e) => onTituloPaginaChange(e.target.value)}
                  autoFocus 
                  maxLength={500}
                  errors={errors}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
          &nbsp;
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
              <span className="survey-edit-label">{t('survey:DescripcionPage')}</span>
              <EditorText
                name="Descripcion"
                style={{ height: '120px' }}
                value={DescripcionHtml}
                onTextChange={(e) => onEditorDescripcionChange(e.htmlValue, e.textValue)}
              >
              </EditorText>
            </Col>
          </Row>   
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
          &nbsp;
            </Col>
          </Row>
        </div>
        <div className='survey-toolbar-options'>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12} className="">
              <FieldButton 
                icon="fas fa-times"
                label={t('common:Cancel')}
                className="warning no-border p-mr-2 rounded-button"
                onClick={onClicClosedEdit}
              />
          &nbsp;
              <FieldButton
                label={t('common:Save')}
                icon="pi pi-save"
                className="p-button-success no-border p-mr-2 rounded-button"
                onClick={onSaveClick}
              /> 
            </Col>
          </Row>
        
        </div>
      </div>
     
    </>
  );
};

export default SurveyManagementPageEdit;

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-const-assign */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { config } from 'utils/config';
import { callApi, showSweetAlert, getSessionItem } from 'utils/utils';  
import moment from 'moment';  
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  ListGroupItem,
  ListGroup,
  Row,
  Col,
  FormGroup,
  Label,
} from 'reactstrap'; 
import PageContent from 'layouts/PageContent';  
import PrincipalHeader from '../Common/Header/PrincipalHeader.jsx';
import ResponderFormulario from '../../../Formulario/ResponderFormulario.js';
import { useHistory, Link } from "react-router-dom";
import HeaderTablet from '../Common/Header/HeaderTablet.jsx';

const DailyOperationCheckListScreen = (props) => {
  const { t, i18n } = useTranslation(['tablets', 'common']); 
  const homeScreenLink = useRef(); 

  let IsNewHomeVersion = false;
  let IdCelda = null  
  let ClaAreaDepartamento = null;
  let ClaTurno = null;

  if(props.location!==undefined){
    const params = queryString.parse(props.location.search);
    IsNewHomeVersion = params !== undefined ? Boolean(params.isNewHomeVersion) : false;
  }  

  if(props.location!==undefined){
    const params = queryString.parse(props.location.search);
    IdCelda = params !== undefined ? params.IdCelda : null;
  }

  if(props.location!==undefined){
    const params = queryString.parse(props.location.search);
    ClaAreaDepartamento = params !== undefined ? params.ClaAreaDepartamento : null;
  }

  if(props.location!==undefined){
    const params = queryString.parse(props.location.search);
    ClaTurno = params !== undefined ? params.ClaTurno : null;
  }
  const [ClaveArticulo, setClaveArticulo] = useState(null);
  const [ClaArticulo, setClaArticulo] = useState(null);
  const [NomArticulo, setNomArticulo] = useState(null);
  const [Cajas, setCajas] = useState(null);
  const [NomTurno, setNomTurno] = useState(null);
  const [ClaAreaProduccion, setClaAreaProduccion] = useState(null);
  const [NomAreaProduccion, setNomAreaProduccion] = useState(null);
  const [NomCelda, setNomCelda] = useState(null);  
  const [ClaveCelda, setClaveCelda] = useState(null);
  const [ClaCelda, setClaCelda] = useState(null);

  useEffect(() => { 
     OnloadDailyOperationCheck(IdCelda);
  }, []);

  const onFormularioSent = () => {
    homeScreenLink.current.click();
  }

  const OnloadDailyOperationCheck = (pIdCelda) => {  
    setClaveArticulo(null);
    const paramsService = {};
    const urlWebService = `${config.UrlApiProject}Operations/GetOperationOnloadDailyOperationCheck?IdCelda=${pIdCelda}`;
    callApi(urlWebService, 'GET', paramsService, (response) => {      
      const dailyOperationCheckHead =
        response.data.DailyOperationCheckHead.length > 0 ? response.data.DailyOperationCheckHead[0] : {};            
        setNomArticulo(dailyOperationCheckHead.NomArticulo);
        setClaArticulo(dailyOperationCheckHead.ClaArticulo);        
        setClaveArticulo(dailyOperationCheckHead.ClaveArticulo);    
        setCajas(dailyOperationCheckHead.Cajas);        
        ClaTurno = dailyOperationCheckHead.ClaTurno;
        setNomTurno(dailyOperationCheckHead.NomTurno);
        setClaAreaProduccion(dailyOperationCheckHead.ClaAreaProduccion);
        setNomAreaProduccion(dailyOperationCheckHead.NomAreaProduccion);        
        setNomCelda(dailyOperationCheckHead.NomCelda);
        setClaveCelda(dailyOperationCheckHead.ClaveCelda);
        setClaCelda(dailyOperationCheckHead.ClaCelda);
    });
  };
   
  const subHeader = (
    <HeaderTablet
      ClaTurno={ClaTurno}
      NomTurno={NomTurno}
      ClaAreaProduccion={ClaAreaProduccion}
      NomAreaProduccion={NomAreaProduccion}
      IdCelda={ClaCelda}
      NomCelda={NomCelda}
      ClaveCelda={ClaveCelda}
    >
    </HeaderTablet> 
  )

  return (
    <>
      <PrincipalHeader IsHomeScreen={false}></PrincipalHeader>
      <PageContent title="Check List" subheader={IsNewHomeVersion ? subHeader : null}>  
        <ResponderFormulario ClaFormulario="1" onFormularioSent={onFormularioSent} IsNewHomeVersion={IsNewHomeVersion} IdCelda={IdCelda} ClaAreaDepartamento={ClaAreaDepartamento} ClaTurno={ClaTurno}></ResponderFormulario>
        <Link 
          ref={homeScreenLink} 
          to={ 
                        { pathname: "CellHomeMenuScreen", search: `?IsDeviceAssignedOnCell=true` }
                }
        >
        </Link> 
      </PageContent> 
    </>
  );
};

export default DailyOperationCheckListScreen;

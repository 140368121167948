/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next';  
import { Column } from 'primereact/column';
import { Row, Col , 
    ListGroupItem,
    ListGroup,
    Progress,
    Card,
    CardHeader,
    CardBody,
    CardFooter} from 'reactstrap';   
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple'; 
import { Badge } from 'primereact/badge';
import { Link } from "react-router-dom";
import { config } from '../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../utils/utils';  
import Score from '../../../components/Cards/Score/Score.jsx'
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import Highcharts from 'highcharts';
import moment from 'moment';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import { ColumnGroup } from 'primereact/columngroup';

const SalesDashboardAccountSummary = ({ClaUsuarioSalesRep, AnioMes, EstatusTipoCliente, ClaClienteCuenta}) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['salesDashboard', 'common']);
   
  const [AccountSummaryInfo, setAccountSummaryInfo] = useState([]); 
  const [EncabezadosAccountSummary, setEncabezadosAccountSummary] = useState({}); 

  const AccountSummaryInfoGrid = useRef(null);


  useEffect(() => {     
     // consultamos la informacion
     getSalesWTWDashboardAccountSummary();
  }, [AnioMes, ClaUsuarioSalesRep, EstatusTipoCliente, ClaClienteCuenta]); 

  const getSalesWTWDashboardAccountSummary = () => {  
    const GetSalesWTWDashboardAccountSummary = `${config.UrlApiProject}SalesPerformanceSales/GetSalesWTWDashboardAccountSummary`;  
    const paramsToService = {   
      AnioMes:            AnioMes             !== undefined ? AnioMes : null, 
      EstatusTipoCliente: EstatusTipoCliente  !== undefined ? EstatusTipoCliente : null,
      ClaUsuarioSalesRep: ClaUsuarioSalesRep  !== undefined ? ClaUsuarioSalesRep : null , 
      ClaClienteCuenta: ClaClienteCuenta !== undefined ? ClaClienteCuenta : null
    };     

    console.log('paramsToService', paramsToService);

    callApi(GetSalesWTWDashboardAccountSummary, 'POST', paramsToService, (response) => { 
        setEncabezadosAccountSummary(response.data.AccountSummaryHeader.length > 0 ? response.data.AccountSummaryHeader[0] : {}) 
        setAccountSummaryInfo(response.data.AccountSummaryInfo);
        

    });
  } 
   
  const header = (
    <>&nbsp;</>
  );

  const stringColumn = (row, columnName) => {
    return (
      <>
        <span className="overflow-ellipsis" title={row[columnName]}>{row[columnName]}</span>
      </>
    );
  } 

  const integerColumn = (row, columnName) => {

    if (row[columnName] === null){
      return (<></>);
    }

    return (
      <>
        <span>{Highcharts.numberFormat(row[columnName], 0, '.', ',')}</span>
      </>
    );
  } 
 
  const numericColumn = (row, columnName) => {
    if (row[columnName] === null){
      return (<></>);
    }

    return (
      <>
        <span>{Highcharts.numberFormat(row[columnName], 2, '.', ',')}</span>
      </>
    );
  } 

  const priceColumn = (row, columnName) => {
    if (row[columnName] === null){
      return (<></>);
    }

    return (
      <>
        <span>${Highcharts.numberFormat(row[columnName], 2, '.', ',')}</span>
      </>
    );
  } 

  const dateColumn = (row, columnName) => {
    if (row[columnName] === null){
      return (<></>);
    }

    return (
      <>
        <span>{moment(row[columnName]).format('MM/DD/YYYY')}</span>
      </>
    );
  } 

  const numericColumnMes = (row, columnName) => {
    if (row[columnName] === null){
      return (<></>);
    }
     
    if (row.ComparablePeriodo !== 0 && row.ComparablePeriodo !== null){

        if ((row[columnName]/row.ComparablePeriodo)*100.0 > 90.0)
        {
            return (
              <>
                <span className='light-green-color'>{Highcharts.numberFormat(row[columnName], 2, '.', ',')}</span>
              </>
              );  
        }

        if ((row[columnName]/row.ComparablePeriodo)*100.0 > 70.0 && (row[columnName]/row.ComparablePeriodo)*100.0 < 90.0)
        {
            return (
              <>
                <span className='warning-color'>{Highcharts.numberFormat(row[columnName], 2, '.', ',')}</span>
              </>
              );  
        }

        if ((row[columnName]/row.ComparablePeriodo)*100.0 < 70.0)
        {
            return (
              <>
                <span className='danger-color'>{Highcharts.numberFormat(row[columnName], 2, '.', ',')}</span>
              </>
              );  
        }
    }

    return (
      <>
        <span>{Highcharts.numberFormat(row[columnName], 2, '.', ',')}</span>
      </>
    );
  } 

  const footer  = ( 
    <> 
      <Row>
        <Column></Column>   
        <Column></Column>
        <Column></Column>       
        <Column></Column>
        <Column></Column>
        <Column></Column> 
      </Row>
    </>
  )

  const onExportGrid = () => {  
    AccountSummaryInfoGrid.current.exportCSV();
 }

 const headerGroup = (
   <ColumnGroup>
     <Row>
       <Column header="" colSpan={4} style={{width:'740px'}} />
       <Column header="Avg Tons by year" colSpan={5} style={{fontWeight:'bold',textAlign:'center'}} className="text-center overflow-ellipsis table-header-background-dark-green" headerStyle={{textAlign:'center'}} /> 
       <Column header={EncabezadosAccountSummary.AnioActualPromTons} colSpan={12} style={{width:'1080px'}} className="text-center" />
     </Row>
     <Row>
       <Column header={t('salesDashboard:ClaCuC')} style={{width:'90px'}} className="text-left overflow-ellipsis" />
       <Column header={t('salesDashboard:NomCUC')} style={{width:'400px'}} className="text-left overflow-ellipsis" />
       <Column header={t('salesDashboard:UltimaCompra')} style={{width:'100px'}} className="text-center overflow-ellipsis" />
       <Column header={t('salesDashboard:SituacionActualCliente')} style={{width:'150px'}} className="text-left overflow-ellipsis" />
       <Column header={EncabezadosAccountSummary.AnioAnterior4PromTons} style={{width:'90px'}} className="text-right overflow-ellipsis table-header-background-ligth-green" />
       <Column header={EncabezadosAccountSummary.AnioAnterior3PromTons} style={{width:'90px'}} className="text-right overflow-ellipsis table-header-background-ligth-green" />
       <Column header={EncabezadosAccountSummary.AnioAnterior2PromTons} style={{width:'90px'}} className="text-right overflow-ellipsis table-header-background-ligth-green" />
       <Column header={EncabezadosAccountSummary.AnioAnterior1PromTons} style={{width:'90px'}} className="text-right overflow-ellipsis table-header-background-ligth-green" />
       <Column header={EncabezadosAccountSummary.AnioActualPromTons} style={{width:'90px'}} className="text-right overflow-ellipsis table-header-background-ligth-green" />
       <Column header={t('common:Jan')} style={{width:'90px'}} className="text-right overflow-ellipsis" />
       <Column header={t('common:Feb')} style={{width:'90px'}} className="text-right overflow-ellipsis" />
       <Column header={t('common:Mar')} style={{width:'90px'}} className="text-right overflow-ellipsis" />
       <Column header={t('common:Apr')} style={{width:'90px'}} className="text-right overflow-ellipsis" />
       <Column header={t('common:May')} style={{width:'90px'}} className="text-right overflow-ellipsis" />
       <Column header={t('common:Jun')} style={{width:'90px'}} className="text-right overflow-ellipsis" />
       <Column header={t('common:Jul')} style={{width:'90px'}} className="text-right overflow-ellipsis" />
       <Column header={t('common:Aug')} style={{width:'90px'}} className="text-right overflow-ellipsis" />
       <Column header={t('common:Sep')} style={{width:'90px'}} className="text-right overflow-ellipsis" />
       <Column header={t('common:Oct')} style={{width:'90px'}} className="text-right overflow-ellipsis" />
       <Column header={t('common:Nov')} style={{width:'90px'}} className="text-right overflow-ellipsis" />
       <Column header={t('common:Dec')} style={{width:'90px'}} className="text-right overflow-ellipsis" />
     </Row>
   </ColumnGroup>
  );
   
  return (
    <div className='SalesDashboardScreen'>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>   
          <Row>
            <Col>
              <div>
                <span className='info-color'><div>* <b>Years</b> column representing the average of purchased tons during the period.</div></span>
              </div>
              <div>
                <span className='info-color'><div>Current Situation = GP: &gt; 90% , NA: &gt; 70% and &lt; 90%, NSA: &lt; 70 % comparing vs last period comparable. <i className='fas fa-info-circle info-color' title='Last Period Comparable = Previous year with average value greater than 0 if the past 4 year are 0 we are comparing using current year average.'></i></div> </span>
              </div>
            </Col>
            <Col className='text-right pb-3'>
              <FieldButton  
                className="p-button p-button-rounded p-button-secondary"
                onClick={onExportGrid}
              >
                <i className="pi pi-download"></i>&nbsp;Export to Excel
              </FieldButton>
            </Col> 
          </Row>   
          <FieldDataTable 
            refererence={AccountSummaryInfoGrid}
            value={AccountSummaryInfo}   
            scrollable  
            className="p-datatable-striped p-datatable-footer-no-pading"  
            selectionMode="single"  
            metaKeySelection={false} 
            scrollHeight="350px"
            footerColumnGroup={footer}
            headerColumnGroup={headerGroup} 
          >      
            <Column field="ClaClienteCuenta" header={t('salesDashboard:ClaCuC')} headerStyle={{ width: '90px' }} className="text-left overflow-ellipsis" body={(row) => stringColumn(row, 'ClaClienteCuenta')}></Column>  
            <Column field="NomClienteCuenta" header={t('salesDashboard:NomCUC')} headerStyle={{ width: '400px' }} className="text-left overflow-ellipsis" body={(row) => stringColumn(row, 'NomClienteCuenta')}></Column>  
            <Column field="UltimaCompra" header={t('salesDashboard:UltimaCompra')} headerStyle={{ width: '100px' }} className="text-center overflow-ellipsis" body={(row) => dateColumn(row, 'UltimaCompra')}></Column>  
            <Column field="SituacionActualCliente" header={t('salesDashboard:SituacionActualCliente')} headerStyle={{ width: '150px' }} className="text-left overflow-ellipsis" body={(row) => stringColumn(row, 'SituacionActualCliente')}></Column>  
            <Column field="AnioAnterior4PromTons" header={EncabezadosAccountSummary.AnioAnterior4PromTons} headerStyle={{ width: '90px' }} className="text-right overflow-ellipsis" body={(row) => numericColumn(row, 'AnioAnterior4PromTons')}></Column>  
            <Column field="AnioAnterior3PromTons" header={EncabezadosAccountSummary.AnioAnterior3PromTons} headerStyle={{ width: '90px' }} className="text-right overflow-ellipsis" body={(row) => numericColumn(row, 'AnioAnterior3PromTons')}></Column>  
            <Column field="AnioAnterior2PromTons" header={EncabezadosAccountSummary.AnioAnterior2PromTons} headerStyle={{ width: '90px' }} className="text-right overflow-ellipsis" body={(row) => numericColumn(row, 'AnioAnterior2PromTons')}></Column>  
            <Column field="AnioAnterior1PromTons" header={EncabezadosAccountSummary.AnioAnterior1PromTons} headerStyle={{ width: '90px' }} className="text-right overflow-ellipsis" body={(row) => numericColumn(row, 'AnioAnterior1PromTons')}></Column>  
            <Column field="AnioActualPromTons" header={EncabezadosAccountSummary.AnioActualPromTons} headerStyle={{ width: '90px' }} className="text-right overflow-ellipsis" body={(row) => numericColumn(row, 'AnioActualPromTons')}></Column>  
            <Column field="Enero" header={t('common:Jan')} headerStyle={{ width: '90px' }} className="text-right overflow-ellipsis" body={(row) => numericColumnMes(row, 'Enero')}></Column>  
            <Column field="Febrero" header={t('common:Feb')} headerStyle={{ width: '90px' }} className="text-right overflow-ellipsis" body={(row) => numericColumnMes(row, 'Febrero')}></Column>  
            <Column field="Marzo" header={t('common:Mar')} headerStyle={{ width: '90px' }} className="text-right overflow-ellipsis" body={(row) => numericColumnMes(row, 'Marzo')}></Column>  
            <Column field="Abril" header={t('common:Apr')} headerStyle={{ width: '90px' }} className="text-right overflow-ellipsis" body={(row) => numericColumnMes(row, 'Abril')}></Column>  
            <Column field="Mayo" header={t('common:May')} headerStyle={{ width: '90px' }} className="text-right overflow-ellipsis" body={(row) => numericColumnMes(row, 'Mayo')}></Column>  
            <Column field="Junio" header={t('common:Jun')} headerStyle={{ width: '90px' }} className="text-right overflow-ellipsis" body={(row) => numericColumnMes(row, 'Junio')}></Column>  
            <Column field="Julio" header={t('common:Jul')} headerStyle={{ width: '90px' }} className="text-right overflow-ellipsis" body={(row) => numericColumnMes(row, 'Julio')}></Column>  
            <Column field="Agosto" header={t('common:Aug')} headerStyle={{ width: '90px' }} className="text-right overflow-ellipsis" body={(row) => numericColumnMes(row, 'Agosto')}></Column>  
            <Column field="Septiembre" header={t('common:Sep')} headerStyle={{ width: '90px' }} className="text-right overflow-ellipsis" body={(row) => numericColumnMes(row, 'Septiembre')}></Column>  
            <Column field="Octubre" header={t('common:Oct')} headerStyle={{ width: '90px' }} className="text-right overflow-ellipsis" body={(row) => numericColumnMes(row, 'Octubre')}></Column>  
            <Column field="Noviembre" header={t('common:Nov')} headerStyle={{ width: '90px' }} className="text-right overflow-ellipsis" body={(row) => numericColumnMes(row, 'Noviembre')}></Column>  
            <Column field="Diciembre" header={t('common:Dec')} headerStyle={{ width: '90px' }} className="text-right overflow-ellipsis" body={(row) => numericColumnMes(row, 'Diciembre')}></Column>  
          </FieldDataTable>   
        </Col>
      </Row> 
    </div>  
        ); 
};

export default SalesDashboardAccountSummary;

import React, {useState, useEffect, useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardHeader, CardBody, CardFooter, Label } from 'reactstrap';
import { callApi, showSweetAlert, formatDate, formatPhoneNumberNA } from "utils/utils";
import { config } from 'utils/config';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import FieldText from 'components/Controls/FieldText';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Sidebar } from 'primereact/sidebar';
import defaultAvatar from 'assets/img/default-avatar.png';
import FieldUpload from 'components/Controls/FieldUpload/FieldUpload';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldPhone from 'components/Controls/FieldPhone/FieldPhone';
import { object, string, number, date, InferType, array } from 'yup';
import YupValidator from 'utils/YupValidator';
import FieldColorPicker from 'components/Controls/FieldColorPicker';

const ManageTemplates = (props) => {
  const isFirstRender = useRef(true);

  const { t } = useTranslation(['qr', 'common']);
  const [showUploadImageDialog, setShowUploadImageDialog] = useState(false);
  const [logoFile, setLogoFile] = useState([]);
  const [logo, setLogo] = useState({});
  const [errors, setErrors] = useState({});
  const [templateId, setTemplateId] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [templateName, setTemplateName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [website, setWebsite] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [userInfoColor, setUserInfoColor] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [buttonColor, setButtonColor] = useState('');
  const [isNewTemplate, setIsNewTemplate] = useState(false);

  useEffect(() => {
    getBusinessCardTemplates();
  }, [])
  
  useEffect(() => {
    if (!isFirstRender.current && !isNewTemplate) {
      getBusinessCardTemplateDetails(templateId);
    }
    else {
      isFirstRender.current = false;
    }
  }, [templateId])

  useEffect(() => {
    if (props.setUserInfo !== undefined && props.userInfo !== undefined) {
      console.log(props.userInfo)
      const newUserInfo = props.userInfo;
      newUserInfo.FullName = 'Full Name';
      newUserInfo.JobTitle = 'Job Title';
      newUserInfo.FullAddress = '2700 Central Ave, Poplar Bluff, MO 63901';
      newUserInfo.TemplateId = templateId;
      newUserInfo.contactInfo = {
        Phone: [{ContactId: 1, NomTipoDBCContactLabel: 'Office', Valor: '+15737781211'}],
        Email: [{ContactId: 2, NomTipoDBCContactLabel: 'Work', Valor: 'test@mcswusa.com'}],
        Social: [{ContactId: 3, NomTipoDBCContactLabel: 'Facebook', Icon: 'fab fa-facebook-square', Valor: 'https://facebook.com'}]
      };
      newUserInfo.UserImageFile = null;
      newUserInfo.UserImageFileName = null;
      newUserInfo.UserImageFileMIMEType = null;
      newUserInfo.BackgroundColor = backgroundColor;
      newUserInfo.UserInfoColor = userInfoColor;
      newUserInfo.SecondaryColor = secondaryColor;
      newUserInfo.ButtonColor = buttonColor;
      newUserInfo.Website = website;
      newUserInfo.CompanyName = companyName;
      newUserInfo.LogoFile = logo.file;
      newUserInfo.LogoFileName = logo.fileName;
      newUserInfo.LogoFileMIMEType = logo.mimeType;
    
      console.log(newUserInfo)

      props.setUserInfo({...newUserInfo});
    }
  }, [templateId, templateName, companyName, website, userInfoColor, backgroundColor, secondaryColor, buttonColor, logo])

  const getBusinessCardTemplates = () => { 
    const url = `${config.UrlApiProject}QRCode/GetBusinessCardTemplates`;  
    const paramsService= {};  
    callApi(url, 'GET', paramsService, (response) => {
      setTemplates(response.data);
      if (templateId === null && response.data.length > 0 && !isNewTemplate) {
        setTemplateId(response.data[0].TemplateId);
        getBusinessCardTemplateDetails(response.data[0].TemplateId)
      }
    });
  }

  const getBusinessCardTemplateDetails = (mTemplateId) => { 
    const url = `${config.UrlApiProject}QRCode/GetBusinessCardTemplateDetails?TemplateId=${mTemplateId}`;  
    const paramsService= {};  
    callApi(url, 'GET', paramsService, (response) => {
      setTemplateId(response.data.TemplateId);
      setTemplateName(response.data.TemplateName);
      setCompanyName(response.data.CompanyName);
      setWebsite(response.data.Website);
      setSecondaryColor(response.data.SecondaryColor);
      setUserInfoColor(response.data.UserInfoColor);
      setBackgroundColor(response.data.BackgroundColor);
      setButtonColor(response.data.ButtonColor);
      setLogo({
        file: response.data.LogoFile,
        fileName: response.data.LogoFileName,
        mimeType: response.data.LogoFileMIMEType
      });

      if (props.setUserInfo !== undefined) {
        props.setUserInfo({
          FullName: 'Full Name',
          JobTitle: 'Job Title',
          FullAddress: '2700 Central Ave, Poplar Bluff, MO 63901',
          TemplateId: response.data.TemplateId,
          contactInfo: {
            Phone: [],
            Email: [],
            Social: []
          },
          UserImageFile: null,
          UserImageFileName: null,
          UserImageFileMIMEType: null,
          TemplateName: response.data.TemplateName,
          CompanyName: response.data.CompanyName,
          Website: response.data.Website,
          SecondaryColor: response.data.SecondaryColor,
          UserInfoColor: response.data.UserInfoColor,
          BackgroundColor: response.data.BackgroundColor,
          ButtonColor: response.data.ButtonColor,
          LogoFile: response.data.LogoFile,
          LogoFileName: response.data.LogoFileName,
          LogoFileMIMEType: response.data.LogoFileMIMEType
        })
      }
    });
  }

  const renderLogo = () => {
    if (logo.file !== undefined && logo.file !== null)
    return (
      <> 
        <img 
          src={`data:${logo.mimeType};base64,${logo.file}`}
          alt=''
          style={{width: '100%', height: '100%', objectFit: 'contain'}}
        />
      </>
    )
  }

  const onWebsiteChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.website;
    setErrors(newErrors);
    setWebsite(e.target.value.trim());
  }

  const onTemplateNameChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.templateName;
    setErrors(newErrors);
    setTemplateName(e.target.value);
  }

  const onCompanyNameChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.companyName;
    setErrors(newErrors);
    setCompanyName(e.target.value);
  }

  const onLogoFileChange = (files) => {
    const newErrors = {...errors};
    delete newErrors.logoFile;
    setErrors(newErrors);
    setLogoFile(files)
  }

  const onTemplateChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.templateId;
    setErrors(newErrors);
    setTemplateId(e.value)
  }

  const onHideUploadImageDialog = () => {
    setShowUploadImageDialog(false);
    setLogoFile([]);
  }

  const onBackgroundColorChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.backgroundColor;
    setErrors(newErrors);
    setBackgroundColor(`#${e.value.replace('#', '').slice(0,6)}`);
  }

  const onSecondaryColorChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.secondaryColor;
    setErrors(newErrors);
    setSecondaryColor(`#${e.value.replace('#', '').slice(0,6)}`);
  }

  const onUserInfoColorChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.userInfoColor;
    setErrors(newErrors);
    setUserInfoColor(`#${e.value.replace('#', '').slice(0,6)}`);
  }

  const onButtonColorChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.buttonColor;
    setErrors(newErrors);
    setButtonColor(`#${e.value.replace('#', '').slice(0,6)}`);
  }

  const onSubmitLogoClick = async () => {
    const logoObject = logoFile.length > 0 ? logoFile[0] : null;

    const validationScheme = object({
      logoFile: object().shape({
            FileData: string().required().nullable(),
            FileExtension: string().matches(String.raw`^(\.gif|\.jpg|\.jpeg|\.png|\.svg)`, t('qr:ValidImageTypes')).required(t('qr:ValidImageTypes')).nullable()
          }).required(t('common:RequiredField')).nullable()
    });
    
    const value = {
      logoFile: logoObject
    }

    const validator = new YupValidator(validationScheme);
    const isValid = await validator.validate(value);

    if (!isValid) {
      const newErrors = validator.errors;
      if (validator.errors.logoFile.FileExtension !== undefined && validator.errors.logoFile.FileExtension !== null) {
        validator.errors.logoFile = validator.errors.logoFile.FileExtension;
      }

      setErrors(newErrors);
      console.log(newErrors)
      return;
    }

    const fileExtension = logoFile[0].FileExtension.toLowerCase();

    setLogo({
      file: logoFile[0].FileData,
      fileName: logoFile[0].FileName,
      mimeType: fileExtension === '.gif' ? 'image/gif'
                  : fileExtension === '.jpg' || fileExtension === '.jpeg' ? 'image/jpeg'
                  : fileExtension === '.png' ? 'image/png'
                  : fileExtension === '.svg' ? 'image/svg+xml'
                  : 'application/unknown'
    });

    const newErrors = {...errors};
    delete newErrors.logo;
    delete newErrors.logoFile;
    setErrors(newErrors);

    onHideUploadImageDialog();
  }

  const onAddTemplateClick = () => {
    if (!isNewTemplate) {
      setIsNewTemplate(true);
      setTemplateId(null);
      setTemplateName('');
      setCompanyName('');
      setWebsite('');
      setLogo({});
      setBackgroundColor('#FF0000')
      setSecondaryColor('#FF0000')
      setUserInfoColor('#FF0000');
      setButtonColor('#FF0000');
    }
  }

  const onSaveInfoClick = async () => {
    const validationScheme = object({
      templateName: string().required(t('common:RequiredField')).nullable(),
      companyName: string().required(t('common:RequiredField')).nullable(),
      templateId: isNewTemplate ? number().nullable() : number().required(t('common:RequiredField')).nullable(),
      backgroundColor: string().matches(String.raw`^#[0-9a-fA-F]{6}$`, t('qr:InvalidHexCode')).required().nullable(),
      secondaryColor: string().matches(String.raw`^#[0-9a-fA-F]{6}$`, t('qr:InvalidHexCode')).required().nullable(),
      userInfoColor: string().matches(String.raw`^#[0-9a-fA-F]{6}$`, t('qr:InvalidHexCode')).required().nullable(),
      buttonColor: string().matches(String.raw`^#[0-9a-fA-F]{6}$`, t('qr:InvalidHexCode')).required().nullable(),
      website: string().matches(String.raw`(^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*))|(^$)`,
                t('common:InvalidHttpUrl')).required(t('common:RequiredField')).nullable(),
      logo: object().shape({
        file: string().required().nullable(),
        fileName: string().required().nullable()
      })
    });
    
    const value = {
      templateName,
      companyName,
      templateId,
      backgroundColor,
      secondaryColor,
      userInfoColor,
      buttonColor,
      website,
      logo
    }

    const validator = new YupValidator(validationScheme);
    const isValid = await validator.validate(value);

    if (!isValid) {
      setErrors(validator.errors);
      return;
    }

    let url = '';
    let paramsService = {};

    if (isNewTemplate) {
      url = `${config.UrlApiProject}QRCode/InsertBusinessCardTemplate`;
      paramsService = {
        TemplateName: templateName,
        CompanyName: companyName,
        Website: website,
        BackgroundColor: backgroundColor,
        SecondaryColor: secondaryColor,
        UserInfoColor: userInfoColor,
        ButtonColor: buttonColor,
        LogoFile: logo.file,
        LogoFileName: logo.fileName
      };
      callApi(url, 'POST', paramsService, (response) => {
        getBusinessCardTemplates();
        setTemplateId(response.data);
        setIsNewTemplate(false);
      });
    }
    else {
      url = `${config.UrlApiProject}QRCode/UpdateBusinessCardTemplate`;  
      paramsService = {
        TemplateId: templateId,
        TemplateName: templateName,
        CompanyName: companyName,
        Website: website,
        BackgroundColor: backgroundColor,
        SecondaryColor: secondaryColor,
        UserInfoColor: userInfoColor,
        ButtonColor: buttonColor,
        LogoFile: logo.file,
        LogoFileName: logo.fileName
      };
      callApi(url, 'POST', paramsService, (response) => {
        getBusinessCardTemplates();
        getBusinessCardTemplateDetails(templateId);
      });
    }

  }

  const onCancelClick = () => {
    getBusinessCardTemplateDetails(1);
    setErrors({});
    setIsNewTemplate(false);
  }

  return (
    <>
      <Row className='p-fluid'>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardBody>
              <Row className='align-items-start'>
                <Col xs={12} sm={12} md={12} lg={12} className='p-field'>
                  <Label>{t('qr:Template')}</Label>
                  <span className='p-inputgroup'>
                    <FieldButton icon='pi pi-plus' onClick={() => onAddTemplateClick()} />
                    <FieldDropdown
                      name='templateId'
                      value={templateId}
                      optionValue="TemplateId"
                      optionLabel="TemplateName"
                      options={templates}
                      onChange={onTemplateChange}
                      errors={errors}
                      showClear={false}
                      disabled={isNewTemplate}
                    />
                  </span>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} className='p-field'>
                  <Card className={`${errors.logo !== undefined ? 'border border-danger' : ''}`} style={{backgroundColor}}>
                    <CardBody className='p-d-flex p-jc-center'>
                      <div style={{width: '300px', height: '150px'}}>
                        {renderLogo()}
                      </div>
                    </CardBody>
                    <CardFooter>
                      <FieldButton
                        label={t('qr:ChangeLogo')}
                        icon='pi pi-image'
                        onClick={() => setShowUploadImageDialog(true)}
                      />
                    </CardFooter>
                  </Card>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} className='align-items-start'>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12} className='p-field'>
                      <Label>{t('qr:TemplateName')}</Label>
                      <FieldText
                        name='templateName'
                        value={templateName}
                        onChange={onTemplateNameChange}
                        errors={errors}
                      />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} className='p-field'>
                      <Label>{t('qr:CompanyName')}</Label>
                      <FieldText
                        name='companyName'
                        value={companyName}
                        onChange={onCompanyNameChange}
                        errors={errors}
                      />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} className='p-field'>
                      <Label>{t('qr:Website')}</Label>
                      <FieldText
                        name='website'
                        value={website}
                        onChange={onWebsiteChange}
                        errors={errors}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} className='p-field'>
                  <Label>{t('qr:BackgroundColor')}</Label>
                  <FieldColorPicker
                    name='backgroundColor'
                    value={backgroundColor}
                    inline
                    onChange={onBackgroundColorChange}
                    format='hex'
                    errors={errors}
                  />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} className='p-field'>
                  <Label>{t('qr:SecondaryColor')}</Label>
                  <FieldColorPicker
                    name='secondaryColor'
                    value={secondaryColor}
                    inline
                    onChange={onSecondaryColorChange}
                    format='hex'
                    errors={errors}
                  />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} className='p-field'>
                  <Label>{t('qr:UserInfoColor')}</Label>
                  <FieldColorPicker
                    name='userInfoColor'
                    value={userInfoColor}
                    inline
                    onChange={onUserInfoColorChange}
                    format='hex'
                    errors={errors}
                  />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} className='p-field'>
                  <Label>{t('qr:ButtonColor')}</Label>
                  <FieldColorPicker
                    name='buttonColor'
                    value={buttonColor}
                    inline
                    onChange={onButtonColorChange}
                    format='hex'
                    errors={errors}
                  />
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row className='p-d-flex p-jc-center'>
                <Col xs={12} sm={12} md={4} lg={4}>
                  <FieldButton label={t('common:Save')} icon='pi pi-save' className='p-button-success' onClick={() => onSaveInfoClick()} />
                </Col>
                {isNewTemplate ? (
                  <Col xs={12} sm={12} md={4} lg={4}>
                    <FieldButton label={t('common:Cancel')} icon='pi pi-times' className='p-button-danger' onClick={() => onCancelClick()} />
                  </Col>
                ) : <></>}
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Dialog
        visible={showUploadImageDialog}
        onHide={onHideUploadImageDialog}
        style={{minWidth: '10vw', minHeight: '20vh'}}
        header={t('qr:UploadLogo')}
      >
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} className='mb-2'>
            <div className='text-info'><i className='pi pi-info-circle' /> {t('qr:AcceptedImageTypes')}</div>
            <div className='text-info'><i className='pi pi-info-circle' /> {t('qr:RecommendedLogoSize')}</div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12}>
            <FieldUpload 
              name='logoFile'
              files={logoFile}
              onChange={onLogoFileChange}
              errors={errors}
              accept='image/gif,image/svg+xml,image/jpeg,image/png'
              uploadPrompt={t('qr:DragAndDropLogoHere')}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} className='p-fluid mt-2'>
            <FieldButton label={t('common:Submit')} icon='pi pi-save' onClick={() => onSubmitLogoClick()} />
          </Col>
        </Row>
      </Dialog>
    </>
  )
}

export default ManageTemplates;

import PageContent from 'layouts/PageContent';
import React, {useState, useEffect} from 'react';
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../../utils/utils';
import { useTranslation } from 'react-i18next';
import SurveyManagementEdit from './SurveyManagementEdit';
import SurveyManagementList from './SurveyManagementList';


const SurveyManagementScreen = (props) => {
  const { t } = useTranslation(['survey', 'common']);
  const [IdEncuesta, setIdEncuesta] = useState(null);
  const [Survey, setSurvey] = useState([]);
  const [SurveyPageList, setSurveyPageList] = useState([]);

  
  return (
    <PageContent title={t('survey:ManageSurvey')}>
      <SurveyManagementList></SurveyManagementList>
    </PageContent>
  )
}

export default SurveyManagementScreen

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi, showSweetAlert } from '../../../utils/utils';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import FieldTextArea from '../../../components/Controls/FieldTextArea.jsx';
import FieldUpload from '../../../components/Controls/FieldUpload/FieldUpload.jsx';
import FieldDropdown from '../../../components/Controls/FieldDropdown.jsx';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import swal from '@sweetalert/with-react';
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon';
import { Tooltip } from 'primereact/tooltip';
import '../../HR/Survey/SurveyManagement/Survey.css';
import FieldText from 'components/Controls/FieldText.jsx';
import FieldNumber from 'components/Controls/FieldNumber.jsx';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx';
import FieldMultiSelect from 'components/Controls/FieldMultiSelect.jsx';
import FieldSelectButton from 'components/Controls/FieldSelectButton.jsx';

const LibraryAttributeTipoCampoEdit = (props) => {
  /* ################## useState area start ################## */  
  const [ClaLibrary, setClaLibrary] = useState(null);
  const [ClaFormularioTipoCampo, setClaFormularioTipoCampo] = useState(null);
  const [NomLibraryAttribute, setNomLibraryAttribute] = useState(null);
  const [Index, setIndex] = useState(null);
  const [ClaLibraryAttribute, setClaLibraryAttribute] = useState(null);

  useEffect(() => {
    setClaLibrary(props.ClaLibrary === undefined ? null : props.ClaLibrary);
    setIndex(props.Index === undefined ? null : props.Index);
    setNomLibraryAttribute(props.NomLibraryAttribute === undefined ? null : props.NomLibraryAttribute);
    setClaFormularioTipoCampo(
      props.ClaFormularioTipoCampo === undefined ? null : props.ClaFormularioTipoCampo
    );
    setClaLibraryAttribute(
        props.ClaLibraryAttribute === undefined ? null : props.ClaLibraryAttribute
      );
    
  }, [
    props.ClaLibrary,
    props.AttributeOpciones,
    props.Index,
    props.NomLibraryAttribute,
    props.ClaFormularioTipoCampo,
    props.ClaLibraryAttribute,
    props.RespuestaAttribute
  ]);

  const GetLibraryAttributeOpciones = (pClaLibrary, pClaLibraryAttribute) => {
    const getLibraryAttribute = `${config.UrlApiProject}DMS/GetLibraryAttributeOpciones?ClaLibrary=${pClaLibrary}`;
    const paramsToService = {};
    callApi(getLibraryAttribute, 'GET', paramsToService, (response) => {
        // setAttributeOpciones(response.data.ListOpciones);
        const opciones = response.data.ListOpciones;
        const filterByAttribute = opciones.filter((opc) =>opc.ClaLibraryAttribute === pClaLibraryAttribute);
        setAttributeOpciones(filterByAttribute);
    });
  };

  const onchangeControlSingle = (value, pprops) => {    
    props.onValueChangedQuestion(props.Index, props.ClaLibraryAttribute, value, pprops);
  };

  const onChangeControlPlano = (value) => {
    props.onValueChangedQuestion(props.Index, props.ClaLibraryAttribute, value, null);
  };
  const TipodeCampoAttribute = (pClaFormularioTipoCampo) => {
    if (pClaFormularioTipoCampo === 1) {
      return (
        <div className="p-fluid">
          <FieldText
            name="ftClaLibraryAttribute"
            value={props.RespuestaAttribute}
            onChange={(e) => {
              onChangeControlPlano(e.target.value);
            }}
            maxLength={7000}
            errors={props.errors}
          />
        </div>
      );
    }
    if (pClaFormularioTipoCampo === 2) {
      return (
        <div className="p-fluid">
          <FieldNumber
            name="fnClaLibraryAttribute"
            value={props.RespuestaAttribute}
            onChange={(e) => {
              onChangeControlPlano(e.target.value);              
            }}
            errors={props.errors}
          />
        </div>
      );
    }
    if (pClaFormularioTipoCampo === 3) {
      return (
        <div className="p-fluid">
          <FieldNumber
            name="fnClaLibraryAttribute"
            minFractionDigits={2}
            value={props.RespuestaAttribute}
            onChange={(e) => {
              onChangeControlPlano(e.target.value);
            }}
            errors={props.errors}
          />
        </div>
      );
    }
    if (pClaFormularioTipoCampo === 4) {
      return (
        <div className="p-fluid">
          <FieldCalendar
            name="fcClaLibraryAttribute"
            value={props.RespuestaAttribute !== '' && props.RespuestaAttribute !== null  ? new Date(props.RespuestaAttribute) : props.RespuestaAttribute}
            onChange={(e) => {
              onChangeControlPlano(e.target.value);
            }}
            errors={props.errors}
          />
        </div>
      );
    }
    if (pClaFormularioTipoCampo === 5) {
      return (
        <div className="p-fluid">
          <FieldCalendar
            name="fcClaLibraryAttribute"
            value={props.RespuestaAttribute !== '' && props.RespuestaAttribute !== null  ? new Date(props.RespuestaAttribute) : props.RespuestaAttribute}
            showTime={true}
            onChange={(e) => {
              onChangeControlPlano(e.target.value);
            }}
            errors={props.errors}
          />
        </div>
      );
    }
    if (pClaFormularioTipoCampo === 7) {
      return (
        <div className="p-fluid">
          <FieldTextArea
            name="ftaClaLibraryAttribute"
            rows={5}
            placeholder=""
            value={props.RespuestaAttribute}
            onChange={(e) => {
              onChangeControlPlano(e.target.value);
            }}
            maxLength={7000}
            errors={props.errors}
          />
        </div>
      );
    }
    if (pClaFormularioTipoCampo === 8) {
      return (
        <div className="p-fluid">
          <FieldDropdown
            name="fdClaLibraryAttributeOption"
            value={props.RespuestaAttribute}
            optionValue="ClaLibraryAttributeOption"
            optionLabel="NomLibraryAttributeOption"
            options={props.AttributeOpciones}
            onChange={(e) => {
              onchangeControlSingle(e.value, props);
            }}
            errors={props.errors}
          >
          </FieldDropdown>
        </div>
      );
    }
    if (pClaFormularioTipoCampo === 9) {
      return (
        <div className="p-fluid">
          <FieldMultiSelect
            name="MultiSelectClaLibraryAttributeOption"
            optionLabel="NomAttributeOpciones"
            value={props.RespuestaAttribute}
            options={props.AttributeOpciones}
            onChange={(e) => onchangeControlSingle(e.value, props)}
            errors={props.errors}
          >
          </FieldMultiSelect>
        </div>
      );
    }
    if (pClaFormularioTipoCampo === 10) {
      return (
        <div className="p-fluid">
          <FieldSelectButton
            name="FSClaLibraryAttributeOption"
            value={props.RespuestaAttribute}
            options={props.AttributeOpciones}
            onChange={(e) => onchangeControlSingle(e.value, props)}
            optionValue="ClaLibraryAttributeOption"
            optionLabel="NomLibraryAttributeOption"
            className="buttonListSurvey"
            multiple={false}
            errors={props.errors}
          />
        </div>
      );
    }
    if (pClaFormularioTipoCampo === 11) {
      return (
        <div className="p-fluid">
          <FieldSelectButton
            name="FSMultipleClaLibraryAttributeOption"            
            value={Array.isArray(props.RespuestaAttribute) ? props.RespuestaAttribute : (props.RespuestaAttribute !== null ? props.RespuestaAttribute.split(',') : props.RespuestaAttribute)}
            options={props.AttributeOpciones}
            onChange={(e) => onchangeControlSingle(e.value, props)}
            optionValue="ClaLibraryAttributeOption"
            optionLabel="NomLibraryAttributeOption"
            className="buttonListSurvey"
            multiple={true}
            errors={props.errors}
          />
        </div>
      );
    }
  };

  return (
    <>
      <div>
        <div>
          <div className="Pregunta">            
            <b>{props.NomLibraryAttribute}</b>
          </div>
        </div>
        <br></br>
        <div>{TipodeCampoAttribute(ClaFormularioTipoCampo)}</div> 
        <div>&nbsp;</div>
      </div>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          &nbsp;
        </Col>
      </Row>
    </>
  );
};
export default LibraryAttributeTipoCampoEdit;

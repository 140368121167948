
import PageContent from 'layouts/PageContent';
import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap'; 
import { config } from 'utils/config';
import { callApi, getSessionItem, showSweetAlert, getDeviceUniqueID } from 'utils/utils';
import CardFormulario from '../Formulario/CardFormulario';

const AvailableCheckListByLocation = (props) => {
  const { t } = useTranslation(['common']);   
  const [FormularioList, setFormularioList] = useState([]);
  
  useEffect(() => { 
    GetFormularios(props.claUbicacion); 
  }, []);

  const GetFormularios = (pClaUbicacion) => {
    const urlWebService = `${config.UrlApiProject}formulario/GetAvailableCheckListByLocation?ClaUbicacion=${pClaUbicacion}`;
    const paramsToService = {};
    callApi(urlWebService, 'GET', paramsToService, (response) => {
      setFormularioList(response.data.Formularios);
      console.log('response', response); 
    });
  };

  
  return (  
    <Row className="align-items-start"> 
      {FormularioList.map((formulario) => (
        <Col xs={12} md={4} sm={6} lg={4} key={`formulario-${formulario.ClaFormulario}`}>
          <CardFormulario formulario={formulario} isAvailableCheckList={true} claUbicacion={props.claUbicacion} />
        </Col>
      ))}  
    </Row> 
  )
}

export default AvailableCheckListByLocation

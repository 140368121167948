import React, { useRef, useEffect, useState } from "react";
import SolicitudMaterialCelda from "./SolicitudMaterialCelda.jsx";
import PageContent from "layouts/PageContent";
import {Row, Col} from 'reactstrap';
import { useTranslation } from 'react-i18next'; 
import queryString from 'query-string' 
import { config } from 'utils/config';
import { callApi } from 'utils/utils'; 
import PrincipalHeader from '../Common/Header/PrincipalHeader.jsx';
import { useHistory, Link } from "react-router-dom"; 
import HeaderTablet from "../Common/Header/HeaderTablet.jsx";

const SolicitudMaterialCeldaScreen = (props) => {
  const {t, i18n} = useTranslation(['operations', 'common']);
  const [ClaveArticulo, setClaveArticulo] = useState(null);
  const [ClaArticulo, setClaArticulo] = useState(null);
  const [NomArticulo, setNomArticulo] = useState(null);
  const [Cajas, setCajas] = useState(null);
  const [ClaTurno, setClaTurno] = useState(null);
  const [NomTurno, setNomTurno] = useState(null);
  const [ClaAreaProduccion, setClaAreaProduccion] = useState(null);
  const [NomAreaProduccion, setNomAreaProduccion] = useState(null);
  const [NomCelda, setNomCelda] = useState(null);  
  const [OperationCheckInList, setOperationCheckInList] = useState([]);
  const [ClaveCelda, setClaveCelda] = useState(null);
  const [ClaCelda, setClaCelda] = useState(null);

  const params= typeof(props.location) === 'undefined' || props.location === null ? {} : queryString.parse(props.location.search);
  const homeScreenLink = useRef();

  let IsNewHomeVersion = false;
  let IdCelda = null  

  if(props.location!==undefined){
    const params = queryString.parse(props.location.search);
    IsNewHomeVersion = params !== undefined ? params.isNewHomeVersion : false;
  }

  if(props.location!==undefined){
    const params = queryString.parse(props.location.search);
    IdCelda = params !== undefined ? params.IdCelda : null;
  }

  const onSubmitSolicitudMaterial = () => {
    homeScreenLink.current.click();
  }

  useEffect(() => { 
    OnloadMaterialCeldaScreen(IdCelda);
  }, []);

  const OnloadMaterialCeldaScreen = (pIdCelda) => {  
    setClaveArticulo(null);
    const paramsService = {};
    const urlWebService = `${config.UrlApiProject}Operations/GetOperationOnloadMaterialCelda?IdCelda=${pIdCelda}`;
    callApi(urlWebService, 'GET', paramsService, (response) => {
      const operationMaterialCeldaHead =
        response.data.OperationMaterialCeldaHead.length > 0 ? response.data.OperationMaterialCeldaHead[0] : {};            
        setNomArticulo(operationMaterialCeldaHead.NomArticulo);
        setClaArticulo(operationMaterialCeldaHead.ClaArticulo);        
        setClaveArticulo(operationMaterialCeldaHead.ClaveArticulo);    
        setCajas(operationMaterialCeldaHead.Cajas);        
        setClaTurno(operationMaterialCeldaHead.ClaTurno);
        setNomTurno(operationMaterialCeldaHead.NomTurno);
        setClaAreaProduccion(operationMaterialCeldaHead.ClaAreaProduccion);
        setNomAreaProduccion(operationMaterialCeldaHead.NomAreaProduccion);        
        setNomCelda(operationMaterialCeldaHead.NomCelda);
        setOperationCheckInList(response.data.OperationCheckIn);
        setClaveCelda(operationMaterialCeldaHead.ClaveCelda);
        setClaCelda(operationMaterialCeldaHead.ClaCelda);
    });
  };


  const subHeader = (  
    <HeaderTablet
      ClaTurno={ClaTurno}
      NomTurno={NomTurno}
      ClaAreaProduccion={ClaAreaProduccion}
      NomAreaProduccion={NomAreaProduccion}
      IdCelda={IdCelda}
      NomCelda={NomCelda}
      ClaveCelda={ClaveCelda}
    >
    </HeaderTablet> 
  )  

  if (IsNewHomeVersion){
    return (
      <>
        <PrincipalHeader IsHomeScreen={false}></PrincipalHeader>
        <PageContent title="Material Request" subheader={subHeader}>  
          <Row>
            <Col>
              <SolicitudMaterialCelda onSubmitMaterialRequest={onSubmitSolicitudMaterial} turno={ClaTurno} ClaveCelda={ClaveCelda} celda={ClaCelda} departamento={ClaAreaProduccion} IsNewHomeVersion={IsNewHomeVersion} />
            </Col>
          </Row>
          <Link 
            ref={homeScreenLink} 
            to={
                          { pathname: "CellHomeMenuScreen", search: `?IsDeviceAssignedOnCell=true` }
                  }
          >
          </Link>  
        </PageContent> 
      </>
    );
  }  
  return (
    <PageContent title={t('operations:solicitudMaterialCeldaTitle')}>
      <Row>
        <Col>
          <SolicitudMaterialCelda />
        </Col>
      </Row>
    </PageContent>
  )
};

export default SolicitudMaterialCeldaScreen;
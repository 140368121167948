import React, { useState, useRef, useEffect  } from 'react';  
import { useTranslation } from 'react-i18next'; 
import PageContent from '../layouts/PageContent.jsx';  
import { Row, Col  } from 'reactstrap';     

const Home = (props) => { 

  
  const { t } = useTranslation(['common']);
 
  return (
    <>   
      <PageContent title={t('common:Home')}>  
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>  
             
          </Col> 
        </Row>    
      </PageContent> 
    </>
  );
};

export default Home;

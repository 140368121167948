/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from 'components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row, Col, Card, CardHeader, CardBody, Table } from 'reactstrap';
import { Avatar } from 'primereact/avatar';
import { config } from '../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../utils/utils';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable.jsx';
import { ProgressBar } from 'primereact/progressbar';
import SweatAlertCustomIcon from 'components/SweatAlert/SweatAlertCustomIcon';
import FieldText from 'components/Controls/FieldText';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx';
import FieldDropdown from 'components/Controls/FieldDropdown.jsx';
import * as yup from 'yup';
import YupValidator from 'utils/YupValidator';
import moment from 'moment';
import swal from '@sweetalert/with-react';
import PageContent from 'layouts/PageContent';
import { Toolbar } from 'primereact/toolbar';
import FieldTextArea from 'components/Controls/FieldTextArea';
import FieldAutoComplete from 'components/Controls/FieldAutoComplete.jsx';
import FieldTime from 'components/Controls/FieldTime';
import { cssNumber } from 'jquery';
import FieldCheckbox from 'components/Controls/FieldCheckbox.jsx';
import FieldSelectButton from 'components/Controls/FieldSelectButton';
import InstructionsCard from '../../../components/Cards/InstructionsCard/InstructionsCard.jsx';

const CultureSurveyFeedbackSetUp = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['survey', 'common']);
  const [errors, setErrors] = useState({});
  const defaultAnio = props.Anio !== undefined ? props.Anio : null;
  const [TeamPerformanceInfoList, setTeamPerformanceInfoList] = useState([]);
  const [TeamPerformanceManagerInfoList, setTeamPerformanceManagerInfoList] = useState([]);
  const [UsuarioAEvaluar, setUsuarioAEvaluar] = useState([]);
  const [UsuarioQuieroEvaluacion, setUsuarioQuieroEvaluacion] = useState([]);
  const [IdPerformanceDev, setIdPerformanceDev] = useState(null);
  const [IdEncuestaContestada, setIdEncuestaContestada] = useState(null);
  const [OpcionesYesNo, setOpcionesYesNo] = useState([
    { value: false, name: t('common:No') },
    { value: true, name: t('common:Yes') },
  ]);
  const [EsPlanRevisado, setEsPlanRevisado] = useState(false);
  const [CultureSurveyFeedbackSetUp, setCultureSurveyFeedbackSetUp] = useState({});
  const [EsSoloLectura, setEsSoloLectura] = useState(false);
  const [ActivaDarFeedbackCultureSurvey, setActivaDarFeedbackCultureSurvey] = useState(false);
  const submitValidationSchema = yup.object().shape({    
    TeamToBeEvaluated: yup.array().test(
      'minNoDefaultTeamMembers',
      t('survey:AtLeast2Members'),
      (value) => {
        const count = value.filter((item) => (item.ClaUsuario && item.CargaDefault === 0)).length;        
        return count >= 2;
      }
    )    
  });

  useEffect(() => {
    setIdPerformanceDev(props.IdPerformanceDev !== undefined ? props.IdPerformanceDev : null);
    setIdEncuestaContestada(
      props.IdEncuestaContestada !== undefined ? props.IdEncuestaContestada : null
    );

    getCultureSurveyFeedbackSetUp();
    // loadTeamPerformanceData();
    // loadTeamPerformanceManagerData();
    GetHRUsuariosDisponiblesCultureSurveyCmb();
  }, [props.Anio, props.IdPerformanceDev, props.IdEncuestaContestada]);

  const getCultureSurveyFeedbackSetUp = () => {
    const GetCultureSurveyFeedbackSetUp = `${config.UrlApiProject}hr/GetCultureSurveyFeedbackSetUp?ClaAnio=${props.Anio===null?'':props.Anio}`; 

    callApi(GetCultureSurveyFeedbackSetUp, 'GET', {}, (response) => {
      const cultureSurveyFeedbackSetUp = response.data.CultureSurveyFeedbackSetUp.length > 0 ? response.data.CultureSurveyFeedbackSetUp[0] : { FechaCreacion : null}
      // setTeamPerformanceInfoList(response.data.TeamPerformanceInfo);
      setCultureSurveyFeedbackSetUp(cultureSurveyFeedbackSetUp);
      bindTeamPerformanceInfo(response.data.CultureSurveAEvaluarList);
      bindTeamPerformanceManagerInfo(response.data.CultureSurveyMyEvaluationList);

      setEsPlanRevisado(cultureSurveyFeedbackSetUp.FechaCreacion !== null);
      setEsSoloLectura(cultureSurveyFeedbackSetUp.FechaCreacion !== null);
      setIdPerformanceDev(cultureSurveyFeedbackSetUp.IdPerformanceDev);
      setActivaDarFeedbackCultureSurvey(cultureSurveyFeedbackSetUp.ActivaDarFeedbackCultureSurvey);
    });
  };

  /** Grilal del lado Izquierdo**** */
   
  const bindTeamPerformanceInfo = (cultureSurveAEvaluarList) => {
    const arrayteamPerformanceInfo = [];
    // const nivelPuesto = cultureSurveAEvaluarList[0].NivelPuesto;
    let RowValues = {};
    for (let i = 0; i < cultureSurveAEvaluarList.length; i++) {
      RowValues = {
        ClaUsuario: cultureSurveAEvaluarList[i].ClaUsuario,
        NomUsuario: cultureSurveAEvaluarList[i].NomUsuario,
        CargaDefault: cultureSurveAEvaluarList[i].CargaDefault,
        BajaLogica: 0,        
      };
      arrayteamPerformanceInfo.push(RowValues);
    }

    /*
    const cont = (nivelPuesto <= 2 ? 9 : 11) - arrayteamPerformanceInfo.length;
    let RowValuesEmpty = {};
    for (let i = 0; i < cont; i++) {
      RowValuesEmpty = {
        ClaUsuario: null,
        NomUsuario: null,
        CargaDefault: 0,
        BajaLogica: 0,
      };
      arrayteamPerformanceInfo.push(RowValuesEmpty);
    }
    */

    setTeamPerformanceInfoList(arrayteamPerformanceInfo);
  };
   

  const onUsuarioAEvaluarChangeNewTable = (rows, e, rowIndex) => { 
    const _errors = {...errors};
    delete _errors.TeamToBeEvaluated;
    setErrors(_errors);

    const saveArray = getCurrentRowValuesTeamManagerTableNew(rows, rowIndex);
    const updatedProductsTeam = [...rows];
    const colaborador = UsuarioAEvaluar.filter((item) => item.ClaUsuario === e.value)[0];
    updatedProductsTeam[rowIndex].NomUsuario = null;
    if (colaborador !== undefined) {
      updatedProductsTeam[rowIndex].NomUsuario = colaborador.NomUsuario;
    }
    updatedProductsTeam[rowIndex].ClaUsuario = typeof(e.value) === 'undefined' ? null : e.value;
    saveArray.ClaUsuario = typeof(e.value) === 'undefined' ? null : e.value;
     
    setTeamPerformanceInfoList(updatedProductsTeam);
  };

  
  const getCurrentRowValuesTeamManagerTableNew = (rows, rowIndex) => {
    const updatedProducts = [...rows];
    
    const RowValues = {
      NomUsuario: updatedProducts[rowIndex].NomUsuario,
      ClaUsuario: updatedProducts[rowIndex].ClaUsuario 
      // NivelPuesto: updatedProducts[rowIndex].NivelPuesto,
    };
    return RowValues;
  };

  const onUsuarioQuieroEvaluacionChangeTableNew = (rows, e, rowIndex) => {
    const saveArray = getCurrentRowValuesTeamManagerTableNew(rows, rowIndex);
    const updatedProductsTeam = [...rows];
    const colaborador = UsuarioQuieroEvaluacion.filter((item) => item.ClaUsuario === e.value)[0];
    updatedProductsTeam[rowIndex].NomUsuario = null;
    if (colaborador !== undefined) {
      updatedProductsTeam[rowIndex].NomUsuario = colaborador.NomUsuario;
    }
    updatedProductsTeam[rowIndex].ClaUsuario = typeof(e.value) === 'undefined' ? null : e.value;
    saveArray.ClaUsuario = typeof(e.value) === 'undefined' ? null : e.value;
    setTeamPerformanceManagerInfoList(updatedProductsTeam);
  }; 
  

  const headerTeam = (
    <>
      <Row>
        <Col>{t('survey:EvaluateQuestion1')}</Col>
      </Row>
    </>
  );

  // Fin de la Grilla Izquierda

  /* Grid del lado Derecho */
 
  const bindTeamPerformanceManagerInfo = (cultureSurveyMyEvaluationList) => {
    const arrayteamPerformanceInfo = [];
    // const nivelPuesto = cultureSurveyMyEvaluationList[0].NivelPuesto;
    let RowValues = {};
    for (let i = 0; i < cultureSurveyMyEvaluationList.length; i++) {
      RowValues = {
        ClaUsuario: cultureSurveyMyEvaluationList[i].ClaUsuario,
        NomUsuario: cultureSurveyMyEvaluationList[i].NomUsuario,
        CargaDefault: cultureSurveyMyEvaluationList[i].CargaDefault,
        BajaLogica: 0,        
      };
      arrayteamPerformanceInfo.push(RowValues);
    }

    /*
    const cont = 9 - arrayteamPerformanceInfo.length;
    let RowValuesEmpty = {};
    for (let i = 0; i < cont; i++) {
      RowValuesEmpty = {
        ClaUsuario: null,
        NomUsuario: null,
        CargaDefault: 0,
        BajaLogica: 0,
      };
      arrayteamPerformanceInfo.push(RowValuesEmpty);
    }
    */
    setTeamPerformanceManagerInfoList(arrayteamPerformanceInfo);
  };
 
  const headerManager = (
    <>
      <Row>
        <Col>{t('survey:EvaluateQuestion2')}</Col>
      </Row>
    </>
  );
  /* End Grid Derecho */
   
  const GetHRUsuariosDisponiblesCultureSurveyCmb = () => {
    const GetHRUsuariosDisponiblesCultureSurveyCmb = `${config.UrlApiProject}HR/GetHRUsuariosDisponiblesCultureSurveyCmb?ModoSel=${0}`;     
    const paramsToService = {};
    callApi(GetHRUsuariosDisponiblesCultureSurveyCmb, 'GET', paramsToService, (response) => {
      setUsuarioAEvaluar(response.data.Usuario);
      setUsuarioQuieroEvaluacion(response.data.Usuario);
    });
  };

  const showActiveRows = (rows) => {
    const activeRows = [];
    if (rows !== undefined) {
      if (rows.length > 0) {
        rows.forEach((item, index) => {
          if (!item.BajaLogica || item.BajaLogica === 0 || item.BajaLogica === undefined) {
            item.BajaLogica = 0;
            item.RowIndex = index;
            item.RowIndexCount = index + 1;
            activeRows.push({ ...item });
          }
        });
      } else {
        return [];
      }
    }

    return activeRows;
  };

  const validateTeamToBeEvaluated = async (data) => {
    const validator = new YupValidator(submitValidationSchema);
    const isValid = await validator.validate(data);
    if (!isValid) {
      console.warn("Data not valid", validator.errors);
      setErrors(validator.errors);
    }
    return isValid;
  };

  const onSaveResponsibesClick = async () => {
    /*
     KISHA: Favor de remover que solicite al menos dos adicionales
    
    const isValid = await validateTeamToBeEvaluated({
      TeamToBeEvaluated: TeamPerformanceInfoList
    });
    if (!isValid) {
      console.warn("Data not valid");      
      return;
    }
    */
   
    const savePerformanceDevCultureFeedback = `${config.UrlApiProject}HR/SavePerformanceDevCultureFeedback`;
    const arrayEvaluate = [];
    let ListEvaluate = {};
    for (let index = 0; index < TeamPerformanceInfoList.length; index++) {
      if (TeamPerformanceInfoList[index].ClaUsuario !== null) {
        ListEvaluate = {
          EsSolicitoEvaluacion: 0,
          ClaUsuarioEvaluacion: TeamPerformanceInfoList[index].ClaUsuario,
          IdEncuestaContestada,
        };
        arrayEvaluate.push(ListEvaluate);
      }
    }

    for (let index = 0; index < TeamPerformanceManagerInfoList.length; index++) {
      if (TeamPerformanceManagerInfoList[index].ClaUsuario !== null && TeamPerformanceManagerInfoList[index].CargaDefault !== 1) {
        ListEvaluate = {
          EsSolicitoEvaluacion: 1,
          ClaUsuarioEvaluacion: TeamPerformanceManagerInfoList[index].ClaUsuario,
          IdEncuestaContestada,
        };
        arrayEvaluate.push(ListEvaluate);
      }
    }
 
     
    const paramsToService = {
      IdPerformanceDev,
      PerformanceDevCultureFeedbackDetDTOList: arrayEvaluate,
    };

    callApi(savePerformanceDevCultureFeedback, 'POST', paramsToService, (response) => {
       showSweetAlert(t('common:Success'), `${t('common:SuccessOperation')  } ${  t('survey:CultureSurveyCompleteSelfReview')}`, 'success');
       getCultureSurveyFeedbackSetUp(); 
       if (props.OnSubmit) props.OnSubmit();
      // obtenerEncuestaPorPagina(IdEncuesta, PaginaActual, 1);
    });
  };

  const onSelectEsPlanRevisado = (value) => {
    const newErrors = { ...errors };
    delete newErrors.EsPlanRevisado;
    setErrors(newErrors);
    setEsPlanRevisado(value);
  };  
  
  if(!CultureSurveyFeedbackSetUp.Activa180DegreeReview){
    return (
      <>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="text-center p-7">  
            <h2>{t('hr:Culture180DegreeReviewPeriodClosed')}</h2> 
          </Col>
        </Row>
      </> 
    );
  } 

  if(!ActivaDarFeedbackCultureSurvey){
    return (
      <>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="text-center p-7">  
            <h2>{t('hr:ActivaDarFeedbackCultureSurveyClosed')}</h2> 
          </Col>
        </Row>
      </> 
    );
  } 

  const filterUserOptions = (options, filterOptions) => {
    const userOptios = [];

    if (options.length>0) {
      options.forEach((item, index) => {
        let userExist  = false;
        filterOptions.forEach((item2, index) => {
          if (item2.ClaUsuario === item.ClaUsuario && item2.CargaDefault === 1){
            userExist = true;
          }
        });

        if (!userExist) { 
          userOptios.push({...item});
        }
      });
    } else {
      return [];
    }
   
    return userOptios; 
  }

  return (
    <>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12} className="pb-2">   
          <InstructionsCard>
            <p>
              {t('survey:CultureSurveyFeedbackInstructions')}.
            </p>
          </InstructionsCard>
        </Col>
      </Row>
      <Row className="align-items-start">
        <Col lg={4} md={6} sm={12} xs={12}>
          <Card>
            <CardBody>
              <Row> 
                <Col lg={12} md={12} sm={12} xs={12}>
                  <Row className="pt-3">
                    <Col>
                      <span className="bold">
                        <h2> {t('survey:TitleQuestion1')} </h2>{' '}
                      </span>
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col>
                      <span className="bold">{t('survey:SubTitleQuestion1')}</span>
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col>                      
                      <div className={`p-datatable table-header-background-ligth-green ${errors.TeamToBeEvaluated? 'form-control-invalid': ''}`}>
                        <table className='p-datatable-scrollable-header-table'>
                          <thead className='p-datatable-thead'>
                            <tr>
                              <th style={{width: '10%'}}>
                                #
                              </th>
                              <th style={{width: '80%'}}>
                                {t('survey:Participant')}
                              </th> 
                              <th style={{width: '10%'}}>
                                &nbsp;
                              </th>
                            </tr>
                          </thead>
                          <tbody className='p-datatable-tbody'>
                            {showActiveRows(TeamPerformanceInfoList).map((element, index) => {  
                          return (  
                            <tr key={index}>
                              <td>
                                {element.RowIndexCount}
                              </td>
                              <td>
                                <div className="p-fluid">
                                  {
                                    element.CargaDefault === 1 ?
                                    (<>{element.NomUsuario}</>)
                                    :
                                    (
                                      <FieldDropdown
                                        name="NomUsuario"
                                        value={element.ClaUsuario}
                                        options={filterUserOptions(UsuarioAEvaluar,TeamPerformanceInfoList)}
                                        optionValue="ClaUsuario"
                                        optionLabel="NomUsuario"
                                        onChange={(e) => onUsuarioAEvaluarChangeNewTable(TeamPerformanceInfoList, e, index)}
                                        disabled={element.CargaDefault === 1}
                                      />
                                    )
                                  }
                                  
                                </div>
                              </td> 
                              <td>
                                &nbsp;
                              </td>
                            </tr>  
                          );
                      })}   
                          
                          </tbody>
                        </table>
                      </div>
                      {
                        errors.TeamToBeEvaluated && (
                          <>
                            <small className="p-error">
                              {errors.TeamToBeEvaluated.message}
                            </small>
                          </>
                        )
                      }
                      {
                        /*
                     
                      <FieldDataTable
                        value={showActiveRows(TeamPerformanceInfoList)}
                        scrollable
                        scrollHeight="330px"
                        className="editable-cells-table"
                        editMode="cell"
                        selectionMode="single"
                        header={null}
                        paginator={false} 
                      >
                        <Column field="RowIndexCount" headerStyle={{ width: '15px' }}></Column>
                        <Column
                          field="NomUsuario"
                          header={t('survey:Participant')}
                          headerStyle={{ width: '280px' }}
                          columnKey="ClaUsuario"
                          editor={(props) => TeamPerformanceEditor(props)}
                        >
                        </Column>
                      </FieldDataTable>
                       */
                     }
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col> 
        <Col lg={4} md={6} sm={12} xs={12}>
          <Card>
            <CardBody>
              <Row> 
                <Col lg={12} md={12} sm={12} xs={12}>
                  <Row className="pt-3">
                    <Col>
                      <span className="bold">
                        <h2> {t('survey:TitleQuestion2')} </h2>{' '}
                      </span>
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col>
                      <span className="bold">{t('survey:SubTitleQuestion2')}</span>
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col>
                      <div className='p-datatable table-header-background-orange'>
                        <table className='p-datatable-scrollable-header-table'>
                          <thead className='p-datatable-thead'>
                            <tr>
                              <th style={{width: '10%'}}>
                                #
                              </th>
                              <th style={{width: '80%'}}>
                                {t('survey:Participant')}
                              </th> 
                              <th style={{width: '10%'}}>
                                &nbsp;
                              </th>
                            </tr>
                          </thead>
                          <tbody className='p-datatable-tbody'>
                            {showActiveRows(TeamPerformanceManagerInfoList).map((element, index) => {  
                          return (  
                            <tr key={index}>
                              <td>
                                {element.RowIndexCount}
                              </td>
                              <td> 
                                <div className="p-fluid">
                                  {
                                    element.CargaDefault === 1 ?
                                    (<>{element.NomUsuario}</>)
                                    :
                                    (
                                      <FieldDropdown
                                        name="NomUsuario"
                                        value={element.ClaUsuario}
                                        options={filterUserOptions(UsuarioQuieroEvaluacion,TeamPerformanceManagerInfoList)}
                                        optionValue="ClaUsuario"
                                        optionLabel="NomUsuario"
                                        onChange={(e) => onUsuarioQuieroEvaluacionChangeTableNew(TeamPerformanceManagerInfoList, e, index)}
                                        disabled={element.CargaDefault === 1}
                                      />
)
                                  }
                                  
                                </div> 
                              </td> 
                              <td>
                                &nbsp;
                              </td>
                            </tr>  
                          );
                      })}   
                          
                          </tbody>
                        </table>
                      </div>
                      {
                        /*
                      <FieldDataTable
                        value={showActiveRows(TeamPerformanceManagerInfoList)}
                        scrollable
                        className="editable-cells-table"
                        editMode=""
                        selectionMode="single"
                        header={null}
                        paginator={false} 
                      >
                        <Column field="RowIndexCount" headerStyle={{ width: '9px' }}></Column>
                        <Column
                          field="NomUsuario"
                          header={t('survey:Participant')}
                          headerStyle={{ width: '280px' }}
                          columnKey="ClaUsuario"                          
                          editor={(props) => TeamPerformanceManagerEditor(props)}
                        >
                        </Column>
                      </FieldDataTable>
                        */
                      }
                      
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col lg={4} md={12} sm={12} xs={12}>
          <Card>
            <CardBody>
              <Row> 
                <Col lg={12} md={12} sm={12} xs={12}>
                  <Row className="pt-3">
                    <Col>
                      <span className="bold">
                        <h2>  {t('survey:TitleStep3')} </h2>{' '}
                      </span>
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col>
                      <span className="bold">{t('survey:TitleQuestion3')}</span>
                    </Col>
                  </Row>
                  <Row className="pt-3 pb-3">
                    <Col>
                      <FieldSelectButton
                        name="EsPlanRevisado"
                        value={EsPlanRevisado}
                        options={OpcionesYesNo}
                        onChange={(e) => onSelectEsPlanRevisado(e.value)}
                        optionValue="value"
                        optionLabel="name"
                        errors={errors}
                        disabled={EsSoloLectura}
                      />
                    </Col>
                  </Row>
                  {EsPlanRevisado ? (
                    <>
                      <Row className="pt-3 pb-3" hidden={CultureSurveyFeedbackSetUp.FechaCreacion !== null}>
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <span className='danger-color'>{t('survey:SubmitCultureSetUpWarning')}</span>
                        </Col>
                      </Row>
                      <Row className="pt-3 pb-3" hidden={CultureSurveyFeedbackSetUp.FechaCreacion !== null}>
                        <Col lg={12} md={12} sm={12} xs={12} className="text-right">
                          <FieldButton
                            label={t('common:Submit')}                            
                            className="p-button-raised p-button-success"
                            onClick={onSaveResponsibesClick}
                          />
                        </Col>
                      </Row>
                      {
                        CultureSurveyFeedbackSetUp.FechaCreacion !== null ?
                        (
                          <Row className="pt-3 pb-3">
                            <Col lg={12} md={12} sm={12} xs={12} className="text-right">
                              {t('common:SubmitedDate')} :  { moment(CultureSurveyFeedbackSetUp.FechaCreacion).format('MMMM DD, YYYY h:mm:ss a') }
                            </Col>
                          </Row>
                        )
                        :
                        (<></>)
                      }
                      
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
          <InstructionsCard>
            <p>
              {t('survey:CultureSurveyCompleteSelfReview')}.
            </p>
          </InstructionsCard>
        </Col>
      </Row> 
    </>
  );
};
export default CultureSurveyFeedbackSetUp;

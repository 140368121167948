
import PageContent from 'layouts/PageContent';
import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import VisitLogFilter from './VisitLogFilter';
import VisitLogList from './VisitLogList';


const VisitLogScreen = (props) => {
  const { t } = useTranslation(['visitLog', 'common']);  
  const [IdEncuesta, setIdEncuesta] = useState(null);  
  const [Reflesh, setReflesh] = useState(1);  
  const { ClaUbicacion } = props.match.params

  useEffect(() => {
    console.log(props.match.params);
  }, []);

  const onSearch = ()=>{
    setReflesh(Reflesh+1);
  }
  
  return (
    <PageContent title={t('visitLog:VisitLog')}>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <VisitLogFilter
            onSearch={onSearch}                 
            refresh={Reflesh}   
            ClaUbicacion={ClaUbicacion}
          >
          </VisitLogFilter>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
            &nbsp;
        </Col>
      </Row>  
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <VisitLogList ClaUbicacion={ClaUbicacion} refresh={Reflesh}></VisitLogList>
        </Col>
      </Row>        
    </PageContent>
  )
}

export default VisitLogScreen

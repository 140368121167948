import React, {useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardHeader, CardBody, CardFooter, Label } from 'reactstrap';
import { callApi, showSweetAlert, formatDate, formatPhoneNumberNA } from "utils/utils";
import { config } from 'utils/config';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import FieldText from 'components/Controls/FieldText';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Sidebar } from 'primereact/sidebar';
import EditBusinessCard from './EditBusinessCard';
import FieldAutoComplete from 'components/Controls/FieldAutoComplete';
import FieldCheckbox from 'components/Controls/FieldCheckbox';
import ManageTemplates from './ManageTemplates';
import BusinessCard from './BusinessCard';
import { object, string, number, date, InferType, array } from 'yup';
import YupValidator from 'utils/YupValidator';
import { Panel } from 'primereact/panel'
import FieldDropdown from 'components/Controls/FieldDropdown';

const ManageBusinessCards = (props) => {
  const { t } = useTranslation(['qr', 'common']);

  const [businessCards, setBusinessCards] = useState([]);
  const [showEditSidebar, setShowEditSidebar] = useState(false);
  const [selectedClaUsuarioBC, setSelectedClaUsuarioBC] = useState(null);
  const [selectedEditClaUsuarioBC, setSelectedEditClaUsuarioBC] = useState(null);
  const [showAddUserDialog, setShowAddUserDialog] = useState(false);
  const [users, setUsers] = useState([]);
  const [errors, setErrors] = useState(null);
  const [EsMostrarSoloUsuarioMSW, setEsMostrarSoloUsuarioMSW] = useState(false);
  const [showTemplateSidebar, setShowTemplateSidebar] = useState(false);
  const [showBusinessCard, setShowBusinessCard] = useState(false);
  const [userInfo, setUserInfo] = useState({})
  const [qrCode, setQrCode] = useState({});
  const [showQRCode, setShowQRCode] = useState(false);
  const [showEmailDialog, setShowEmailDialog] = useState(false);
  const [email, setEmail] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    getBusinessCardTemplates();
  }, [])

  useEffect(() => {
    getBusinessCards();
  }, [searchText, selectedTemplate])

  const getBusinessCards = () => { 
    const url = `${config.UrlApiProject}QRCode/GetBusinessCards?SearchText=${searchText}&${selectedTemplate !== null && selectedTemplate !== undefined ? `TemplateId=${selectedTemplate}` : ''}`;  
    const paramsService= {};  
    callApi(url, 'GET', paramsService, (response) => {
      setBusinessCards(response.data);
    });
  }

  const getBusinessCardTemplates = () => { 
    const url = `${config.UrlApiProject}QRCode/GetBusinessCardTemplates`;  
    const paramsService= {};  
    callApi(url, 'GET', paramsService, (response) => {
      setTemplates(response.data);
    });
  }

  const filterUser = (e) => {
    let valor = ''; 
    if (selectedClaUsuarioBC.NomUsuario !== null && selectedClaUsuarioBC.NomUsuario !== '' &&  e.query === "")
    {
     valor = selectedClaUsuarioBC.NomUsuario;
    }
    else{
     valor = e.query;
    }
    const paramsServiceOwners = {};
    // event.query 
    const urlWebServiceOwners = `${config.UrlApiProject}ScoreCard/GetUsuariosCmb?descriptionFilter=${valor}&EsMostrarSoloUsuarioMSW=${!EsMostrarSoloUsuarioMSW?1:0}`;
    // Get owner
    callApi(urlWebServiceOwners, 'GET', paramsServiceOwners, (response) => {  
       setUsers(response.data.Usuarios);
    });    
  }

  const getQRCode = (claUsuarioBC) => { 
    const url = `${config.UrlApiProject}QRCode/GetBusinessCardQRCode?ClaUsuarioBC=${claUsuarioBC}`;  
    const paramsService= {};  
    callApi(url, 'GET', paramsService, (response) => {
      setQrCode({...response.data});
    });
  }

  const getEmployeeEmail = (claUsuarioBC) => {
    const url = `${config.UrlApiProject}QRCode/GetEmployeeEmail?ClaUsuarioBC=${claUsuarioBC}`;  
    const paramsService= {};  
    callApi(url, 'GET', paramsService, (response) => {
      setEmail(response.data.Email)
    });
  }

  const disableBusinessCard = (claUsuarioBC) => { 
    const url = `${config.UrlApiProject}QRCode/DisableBusinessCard`;  
    const paramsService= {
      ClaUsuarioBC: claUsuarioBC
    };  
    callApi(url, 'POST', paramsService, (response) => {
      getBusinessCards();
      showSweetAlert('Success', t('common:Success'), 'success');
    });
  }

  const onEmailChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.email;
    setErrors(newErrors);
    console.log(e.target.value)
    if ([' ', ':', ';'].includes(e.target.value)) {
      console.log('test')
      return;
    }
    setEmail(e.target.value);
  }

  const onChangeIncluirSoloUsuariosMSW = (e) => {
    setEsMostrarSoloUsuarioMSW(e.checked ? 1 : 0); 
  };

  const onSelectedClaUsuarioBcChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.selectedClaUsuarioBC;
    setErrors(newErrors);
    setSelectedClaUsuarioBC(e.value);
  }

  const onShowEditSidebar = (claUsuarioBC) => {
    setSelectedEditClaUsuarioBC(claUsuarioBC);
    setShowEditSidebar(true);
  }

  const onShowViewBusinessCard = (claUsuarioBC) => {
    setSelectedEditClaUsuarioBC(claUsuarioBC);
    setShowBusinessCard(true);
  }

  const onHideEditSideBar = () => {
    setShowEditSidebar(false);
    setSelectedClaUsuarioBC(null);
    setSelectedEditClaUsuarioBC(null);
    setUserInfo({});
    getBusinessCards();
  }

  const onHideTemplateSidebar = () => {
    setShowTemplateSidebar(false);
    setUserInfo({});
  }

  const onAddBusinessCardDialogClick = () => {
    setShowAddUserDialog(true);
  }

  const onAddNewBusinessCardUserClick = () => {
    const params = {
      ClaUsuarioBC: selectedClaUsuarioBC.ClaUsuario
    }
    const url = `${config.UrlApiProject}QRCode/InsertNewBusinessCard`;
    callApi(url, 'POST', params, (response) => {
      onCloseAddUserDialog();
      setSelectedEditClaUsuarioBC(selectedClaUsuarioBC.ClaUsuario);
      setShowEditSidebar(true);
    });
  }

  const onCloseAddUserDialog = () => {
    setShowAddUserDialog(false);
    setSelectedClaUsuarioBC(null);
    setEsMostrarSoloUsuarioMSW(false);
  }

  const onShowQRCodeClick = (claUsuarioBC) => {
    getQRCode(claUsuarioBC);
    setShowQRCode(true);
  }

  const onShowEmailDialog = (claUsuarioBC) => {
    getEmployeeEmail(claUsuarioBC);
    setSelectedClaUsuarioBC(claUsuarioBC);
    setShowEmailDialog(true);
  }

  const onHideEmailDialog = () => {
    setSelectedClaUsuarioBC(null);
    setEmail('');
    setShowEmailDialog(false);
  }

  const onUpdateScorecardClick = (claUsuarioBC) => {
    const params = {
      ClaUsuarioBC: claUsuarioBC
    }
    const url = `${config.UrlApiProject}QRCode/UpdateScorecardInfo`;
    callApi(url, 'POST', params, (response) => {
      showSweetAlert('Success', t('common:Success'), 'success');
    });
  }

  const onSendEmailButtonClick = async () => {
    const validationScheme = object({
      email: string().matches(String.raw`(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))`,
                                            t('common:InvalidEmailError')).nullable()
    });
    
    const value = {
      email
    }

    const validator = new YupValidator(validationScheme);
    const isValid = await validator.validate(value);

    if (!isValid) {
      setErrors(validator.errors);
      return;
    }

    const url = `${config.UrlApiProject}QRCode/SendBusinessCardQREmail?ClaUsuarioBC=${selectedClaUsuarioBC}&emailTo=${email}`;  
    const paramsService= {};  
    callApi(url, 'GET', paramsService, (response) => {
      onHideEmailDialog();
      showSweetAlert('Success', t('common:Success'), 'success');
    });
  }

  const onTemplateChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.template;
    setErrors(newErrors);
    setSelectedTemplate(e.value);
  }

  const onSearchTextChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.searchText;
    setErrors(newErrors);
    setSearchText(e.target.value);
  }

  const optionsTemplate = (row) => {
    return (
      <>
        <FieldButton label={t('common:Edit')} icon='pi pi-pencil' className='p-button-primary mr-2' onClick={() => onShowEditSidebar(row.ClaUsuarioBC)} />
        <FieldButton label={t('qr:ViewBusinessCard')} icon='pi pi-eye' className='p-button-secondary mr-2' onClick={() => onShowViewBusinessCard(row.ClaUsuarioBC)} />
        <FieldButton label={t('qr:ViewQRCode')} icon='fas fa-qrcode' className='p-button-secondary mr-2' onClick={() => onShowQRCodeClick(row.ClaUsuarioBC)} />
        <FieldButton label={t('qr:EmailQRCode')} icon='pi pi-envelope' className='p-button-success mr-2' onClick={() => onShowEmailDialog(row.ClaUsuarioBC)} />
        <FieldButton label={t('qr:UpdateScorecard')} icon='pi pi-cloud-upload' className='p-button-warning mr-2' onClick={() => onUpdateScorecardClick(row.ClaUsuarioBC)} disabled={row.IsExternal} />
        <FieldButton label={t('common:Disable')} icon='pi pi-trash' className='p-button-danger mr-2' onClick={() => disableBusinessCard(row.ClaUsuarioBC)} />
      </>
    )
  }

  const headerTemplate = (
    <Row>
      <Col xs={6} sm={6} md={6} lg={6} className='p-d-flex p-ai-center p-jc-start'>
        {t('qr:BusinessCards')}
      </Col>
      <Col xs={6} sm={6} md={6} lg={6} className='p-d-flex p-jc-end'>
        <FieldButton className='p-button-sm mr-2' label={t('qr:ManageTemplates')} icon='pi pi-file' onClick={() => setShowTemplateSidebar(true)} />
        <FieldButton className='p-button-sm' icon='pi pi-plus' onClick={() => onAddBusinessCardDialogClick()} />
      </Col>
    </Row>
  );

  const addBusinessCardFooter = (
    <div className='p-d-flex p-jc-start'>
      <FieldButton 
        label={t('common:Add')} 
        icon='pi pi-plus'
        className='p-button-success'
        onClick={() => onAddNewBusinessCardUserClick()}
      />
    </div>
  )

  const filterHeaderTemplate = (options) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down text-white' : 'pi pi-chevron-up text-white';
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className="card-header">
        <div className="p-toolbar p-component">
          <div className="p-toolbar-group-left">
            <div className="card-title">
              <FieldButton className={options.togglerClassName} onClick={options.onTogglerClick}>
                <span className={toggleIcon}></span> 
              </FieldButton>
              <span className={titleClassName}>
                {t('common:Filters')}
              </span>
            </div>  
          </div>
          <div className="p-toolbar-group-right">

          </div>
        </div> 
      </div>
    )
  }

  return (
    <>
      <Panel
        header={t('common:Filters')}
        toggleable
        headerTemplate={filterHeaderTemplate}
        className='mb-2'
      >
        <Row className='p-grid p-fluid align-items-start'>
          <Col xs={12} sm={6} md={6} lg={8} className='p-field'>
            <Label>{t('common:Search')}</Label>
            <FieldText
              name='searchText'
              value={searchText}
              onChange={onSearchTextChange}
              errors={errors}
            />
          </Col>
          <Col xs={12} sm={6} md={6} lg={4} className='p-field'>
            <Label>{t('qr:Template')}</Label>
            <FieldDropdown
              name='template'
              value={selectedTemplate}
              optionValue="TemplateId"
              optionLabel="TemplateName"
              options={templates}
              onChange={onTemplateChange}
              errors={errors}
              className='mb-2'
            />
          </Col>
        </Row>
      </Panel>
      <Card>
        <CardBody>
          <FieldDataTable
            value={businessCards} 
            header={headerTemplate}  
            scrollable 
            className="p-datatable-striped"
          >
            <Column field="FullName" header={t('qr:Name')} sortable headerStyle={{ width: '200px' }}></Column>
            <Column field="JobTitle" header={t('qr:JobTitle')} sortable headerStyle={{ width: '200px' }}></Column>
            <Column field="TemplateName" header={t('qr:TemplateName')} sortable headerStyle={{ width: '200px' }}></Column>
            <Column body={optionsTemplate} header={t('common:Options')}></Column>
          </FieldDataTable>
        </CardBody>
      </Card>
      <Sidebar
        visible={showEditSidebar}
        className="p-sidebar-full"
        position="right"
        dismissable={false}
        baseZIndex={1000000}
        blockScroll={true}
        showCloseIcon={true}
        onHide={onHideEditSideBar}
      >
        <Row className='align-items-start'>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Card className='mt-2'>
              <CardHeader className='pt-2 pb-1'>
                <span style={{fontSize: '20px'}}>{t('qr:EditBusinessCard')}</span>
              </CardHeader>
              <CardBody>
                <EditBusinessCard ClaUsuarioBC={selectedEditClaUsuarioBC} onHide={onHideEditSideBar} addNew={true} setUserInfo={setUserInfo} userInfo={userInfo} />
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Card className='mt-2'>
              <CardHeader className='pt-2 pb-1'>
                <span style={{fontSize: '20px'}}>{t('qr:BusinessCardPreview')}</span>
              </CardHeader>
              <CardBody>
                <BusinessCard isEditorMode={true} userInfo={userInfo} className='border border-light rounded' />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Sidebar>
      <Dialog
        visible={showAddUserDialog}
        onHide={() => onCloseAddUserDialog()}
        style={{minWidth: '20vw', minHeight: '20vh'}}
        header={t('qr:AddBusinessCard')}
        footer={addBusinessCardFooter}
      >
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} className='p-fluid p-field'>
            <FieldCheckbox
              inputId={EsMostrarSoloUsuarioMSW}
              checked={EsMostrarSoloUsuarioMSW === 1}
              onChange={(e) => onChangeIncluirSoloUsuariosMSW(e)}
            />
            <Label className='ml-2'>{t("qr:IncludeExternalUsers")}</Label>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} className='p-fluid p-field mt-2'>
            <FieldAutoComplete
              dropdown
              id="selectedClaUsuarioBC"
              name="selectedClaUsuarioBC"
              value={selectedClaUsuarioBC}
              onChange={onSelectedClaUsuarioBcChange}
              suggestions={users}
              field="NomUsuario"
              completeMethod={filterUser}
              errors={errors}
              label={t('qr:SelectAUser')}
            /> 
          </Col>
        </Row>
      </Dialog>
      <Sidebar
        visible={showTemplateSidebar}
        className="p-sidebar-full"
        position="right"
        dismissable={true}
        baseZIndex={1000000}
        blockScroll={true}
        showCloseIcon={true}
        onHide={() => onHideTemplateSidebar()}
      >
        <Row className='align-items-start'>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Card className='mt-2'>
              <CardHeader className='pt-2 pb-1'>
                <span style={{fontSize: '20px'}}>{t('qr:ManageTemplates')}</span>
              </CardHeader>
              <CardBody>
                <ManageTemplates setUserInfo={setUserInfo} userInfo={userInfo} />
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Card className='mt-2'>
              <CardHeader className='pt-2 pb-1'>
                <span style={{fontSize: '20px'}}>{t('qr:TemplatePreview')}</span>
              </CardHeader>
              <CardBody>
                <BusinessCard isEditorMode={true} userInfo={userInfo} className='border border-light rounded' />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Sidebar>
      <Dialog
        visible={showBusinessCard}
        dismissable={true}
        blockScroll={true}
        showCloseIcon={true}
        maximized
        onHide={() => setShowBusinessCard(false)}
        position='center'
        draggable={false}
        resizable={false}
        header={t('qr:BusinessCard')}
      >
        <BusinessCard ClaUsuarioBC={selectedEditClaUsuarioBC} className='border border-light rounded' />,
      </Dialog>
      <Dialog
        visible={showQRCode}
        onHide={() => setShowQRCode(false)}
        header={t('qr:QRCode')}
      >
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} className=''>
            <img 
              src={`data:${qrCode.mimeType};base64,${qrCode.file}`}
              alt=''
              style={{maxWidth: '75vw', maxHeight: '75vh'}}
            />
          </Col>
        </Row>
      </Dialog>
      <Dialog
        visible={showEmailDialog}
        onHide={() => setShowEmailDialog(false)}
        header={t('qr:EmailQRCode')}
      >
        <Row className='p-fluid' style={{minWidth: '25vw'}}>
          <Col xs={12} sm={12} md={12} lg={12} className='p-field'>
            <Label>{t('common:EmailTo')}</Label>
            <FieldText
              id='email'
              name='email'
              value={email}
              onChange={onEmailChange}
              errors={errors}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} className='p-field'>
            <FieldButton label={t('common:Send')} icon='pi pi-send' className='p-button-success' onClick={() => onSendEmailButtonClick()} />
          </Col>
        </Row>
      </Dialog>
      
    </>
  )
}

export default ManageBusinessCards;
import React from 'react'; 
import { FormGroup, Label, Card, CardHeader, CardBody, Row, Col, CardTitle, Button, CardFooter } from 'reactstrap';
import Pregunta from './Pregunta.js'; 
import { config } from '../../utils/config'; 
import { callApi, showSweetAlert, showNotify, getCliente } from '../../utils/utils';  
import queryString from 'query-string' 
import  WizardLayout from '../../components/Wizard/Wizard.js'; 
import { Skeleton } from 'primereact/skeleton';
import SignatureCanvas from 'react-signature-canvas'
import { SelectButton } from 'primereact/selectbutton'; 
import { withTranslation } from 'react-i18next';
import PageContent from '../../layouts/PageContent'; 
import FieldSelectButton from 'components/Controls/FieldSelectButton.jsx';


class ResponderFormulario extends React.Component {
    constructor(props) {
      super(props); 
             
      const params= typeof(props.location) === 'undefined' || props.location === null ? {} : queryString.parse(props.location.search);
      

      this.state = {
         ClaFormulario: params.ClaFormulario ? params.ClaFormulario : props.ClaFormulario,
         IsAvailableCheckList: props.isAvailableCheckList,
         NomFormulario: null,
         DefaultCantidadPreguntas: null,
         ClaTurno: props.ClaTurno ? props.ClaTurno : null,
         ClaCelda: props.IdCelda ? props.IdCelda : null,
         ClaMaquina: null,
         ABCMachine: null,
         preguntas: [] , 
         Shifts: [],
         Departments: [],
         Cells: [],
         Lines: [],
         SignData: null,
         errors: {},
         IsLoading: false,
         ClaDepartamento: props.ClaAreaDepartamento ? props.ClaAreaDepartamento : null,
         IsNewHomeVersion:props.IsNewHomeVersion ? Boolean(props.IsNewHomeVersion) : Boolean(false),
         Language: 'English'
      }
      console.log(this.state);
      this.preguntaCambio = this.preguntaCambio.bind(this);
      this.enviarFormulario = this.enviarFormulario.bind(this);  
      this.onBeforeNextStep = this.onBeforeNextStep.bind(this);  
      this.ValidaStep1 = this.ValidaStep1.bind(this);

      this.languageOptions = [
        {name: 'EN', value: 'English'},        
        {name: 'ES', value: 'Spanish'}
      ];      
    };

    clearSignPad = () => {
      this.signPad.clear()
      this.setState({SignData: null})
    }

    setSignData = () => { 
      this.setState({SignData: this.signPad.getTrimmedCanvas().toDataURL().replace('data:image/png;base64,','')})
    }
    
    getFormulario = (claFormulario, language) => {
      let urlWebService = `${config.UrlApiProject}formulario/getFormulario?ClaFormulario=${claFormulario}&Idioma=${language}`; 
      let paramsService = null;

      this.setState({ 
        preguntas: []
      });

      callApi(urlWebService, 'GET', paramsService, (response) => {
        this.setState({
          NomFormulario: response.data.NomFormulario,
          DefaultCantidadPreguntas: response.data.DefaultCantidadPreguntas,
          preguntas: response.data.preguntas,
          // ClaTurno:null, 
          // ClaDepartamento:null,
          // ClaCelda:null,
          Shifts:response.data.Turnos,
          Departments:response.data.Departamentos,
          Celdas:[],
          errors: {},
          IsLoading:false
        });

        if (!this.state.IsNewHomeVersion) {
          this.setState({ 
            ClaTurno:null, 
            ClaDepartamento:null,
            ClaCelda:null 
          });
        }

        // Wire Coil
        if (claFormulario === '3') {
          this.setState({
            ClaDepartamento: 2
          })

          urlWebService = `${config.UrlApiProject}Celda/GetCeldas?ClaTipoCelda=${5}`; 
          paramsService = null;
      
          callApi(urlWebService, 'GET', paramsService, (response) => {
            this.setState({
              Celdas: response.data.Celdas
            })
          });
        }

        // Plastic Strip
        if (claFormulario === '4') {
          this.setState({
            ClaDepartamento: 2
          })

          urlWebService = `${config.UrlApiProject}Celda/GetCeldas?ClaTipoCelda=${3}`; 
          paramsService = null;
      
          callApi(urlWebService, 'GET', paramsService, (response) => {
            this.setState({
              Celdas: response.data.Celdas
            })
          });
        }
  
        // Bulk
        if (claFormulario === '5') {
          this.setState({
            ClaDepartamento: 1
          })

          urlWebService = `${config.UrlApiProject}Celda/GetCeldas?ClaDepartamento=${1}`; 
          paramsService = null;
      
          callApi(urlWebService, 'GET', paramsService, (response) => {
            this.setState({
              Celdas: response.data.Celdas
            })
          });
        }

      });
    };

    componentDidMount()   
    { 
      this.getFormulario(this.state.ClaFormulario, this.state.Language);
    }
    

   // se manda llamar cuando modifican una respuesta en la pregunta....
   preguntaCambio ( item,  newValue, isImageField ) {   
       const newStatePregunta =  item;
       const index = this.state.preguntas.findIndex(prg => prg.IdFormularioPregunta === item.IdFormularioPregunta);
       // index.respuesta = newValue;
       const newPreguntas = [...this.state.preguntas] // important to create a copy, otherwise you'll modify state outside of setState call
       
       if (isImageField){
        newStatePregunta.imageItems = newValue;
        newStatePregunta.respuesta = newValue.length === 0 ? '' : newValue.length;
       }else{
        newStatePregunta.respuesta = newValue;
       }
       
       newPreguntas[index] = newStatePregunta;
       this.marcarPreguntaInvalida(item, false);
       // this.setState({ preguntas:newPreguntas}); 
   };

   onBeforeNextStep ( items )   {   
      console.log(this.state);
      let isContinue = true;
      // validamos los elementos recibidos......  
      for (let i = 0; i < items.length;){ 
          if ((items[i].EsRequerida === true || items[i].EsRequerida === 1)  && (items[i].respuesta === undefined || items[i].respuesta === '' || items[i].respuesta === null)){
            this.marcarPreguntaInvalida(items[i], true);
            isContinue = false;
          }
          
          i+=1; 
      }

      if (!isContinue){
        // regresar a pantalla de formularios.....
        showNotify(this.props.t('common:ReviewRequiredFields'), 'centerTop', 'warning');
      }
      return isContinue;
  };


  ValidaStep1 ( items )   {   
      let isContinue = true;
      // validamos manualmente los campos de celda, turno y departamento.......  
       
      const errorHandled = {}
       
      switch(this.state.ClaFormulario) 
      {
        case '1':
          if (this.state.ClaTurno === null){
            isContinue =false;
            errorHandled.ClaTurno = this.props.t('common:RequiredField')
          } 
    
          if (this.state.ClaDepartamento === null){
            isContinue =false;
            errorHandled.ClaDepartamento = this.props.t('common:RequiredField')
          }
    
          if (this.state.Celdas.length> 0 && this.state.ClaCelda  === null){
            isContinue =false;
            errorHandled.ClaCelda = this.props.t('common:RequiredField')
          }
          
          this.setState({errors:errorHandled});

          break; 
          case '2':
            if (this.state.ClaTurno === null){
              isContinue =false;
              errorHandled.ClaTurno = this.props.t('common:RequiredField') 
            } 
            
            this.setState({errors:errorHandled});

            break;
          case '3':
          case '4':
          case '5':
            if (this.state.ClaTurno === null){
              isContinue =false;
              errorHandled.ClaTurno = this.props.t('common:RequiredField')
            }

            if (this.state.Celdas.length> 0 && this.state.ClaCelda  === null){
              isContinue =false;
              errorHandled.ClaCelda = this.props.t('common:RequiredField')
            }

            if (this.state.ClaMaquina === null){
              isContinue =false;
              errorHandled.ClaMaquina = this.props.t('common:RequiredField')
            }
            
            this.setState({errors:errorHandled});
            
            break;
          default:
            // nothing special
            break;
      } 

      if (!isContinue){
        // regresar a pantalla de formularios..... 
        showNotify(this.props.t('common:ReviewRequiredFields') , 'centerTop', 'warning');
      }
 
      return isContinue;
  };

  marcarPreguntaInvalida ( item,  esInvalida ) {   
      const newStatePregunta =  item;
      const index = this.state.preguntas.findIndex(prg => prg.IdFormularioPregunta === item.IdFormularioPregunta);
      // index.respuesta = newValue;
      const newPreguntas = [...this.state.preguntas] // important to create a copy, otherwise you'll modify state outside of setState call
      newStatePregunta.EsInvalida = esInvalida;
      newPreguntas[index] = newStatePregunta;
       
      this.setState({ preguntas:newPreguntas}); 
  };

   enviarFormulario()  {

        // validamos que este firmado el formulario....
        if (this.state.SignData === null || this.state.SignData === '') { 
          showSweetAlert(null, this.props.t('formulario:SignatureRequired') , 'warning');
          return;
        } 
        
        this.setState({IsLoading:true});

        let urlServ = `${config.UrlApiProject}formulario/enviarFormulario`;
        
        if(this.state.IsAvailableCheckList){
          urlServ = `${config.UrlApiProject}formulario/EnviarFormularioAvailableCheckListByLocation`;
        }
        
        const params = {
            ...this.state
        }; 

        console.log('formulario', JSON.stringify( params ) );
        
        callApi(urlServ, "POST", params, (res) => {
          
            // harcode dependiendo el formulario

            this.setState({
              ClaFormulario: params.ClaFormulario ? params.ClaFormulario : null,
              NomFormulario:null,
              DefaultCantidadPreguntas:null,
              ClaTurno:null,
              ClaCelda:null,
              preguntas: [] , 
              Shifts:[],
              Departments:[],
              Cells:[],
              SignData:null,
              errors: {},
              IsLoading:false,
              ClaMaquina: null,
              ABCMachine: null
           });
           
           // regresar a pantalla de formularios.....
           showNotify(this.props.t('common:SuccessOperation') , 'centerTop', 'success');
          
           if (res.data.EsEnviarAdvertencia){
            showSweetAlert(null, res.data.MensajeAdvertencia, 'warning'); 
           } 
            
           if (typeof(this.props.onFormularioSent) === 'function'){
            this.props.onFormularioSent();
           }
           else {
            this.props.history.push("/layout/Formularios");
           }
            
        });
    };  

   chunkArray(myArray, chunk_size){
      const results = [];
      
      while (myArray.length) {
          results.push(myArray.splice(0, chunk_size));
      }
      
      return results;
  }

  onSelectedShiftChange = ( event ) => { 
    this.setState ( ( state, props ) => {  
        return { 
          ClaTurno: event.value,
          errors:{...this.state.errors, ClaTurno:null}
      }
    } )  
  };

  onSelectedCeldaChange = (e) => { 
    this.setState ( ( state, props ) => {  
        return { 
          ClaCelda: event.value,
          errors:{...this.state.errors, ClaCelda:null}
      }
    } )  

    if (e.value === null)
    {
      e.value = '';
      this.setState({ 
        Lines: [],
      });
      
      return;
    }

    let claTipoMaquina = 1

    if (this.state.ClaFormulario === "3" || this.state.ClaFormulario === "4") {
      claTipoMaquina = 3
    }

    const urlWebService = `${config.UrlApiProject}Celda/GetCellLines?ClaTipoMaquina=${claTipoMaquina}&ClaCelda=${e.value}`; 
    const paramsService = null;

    callApi(urlWebService, 'GET', paramsService, (response) => {
      this.setState({ 
        ClaCelda: e.value,
        Lines: response.data.Lines
      });


    });  

  };

  onSelectedLineChange = (e) => {
    this.state.Lines.forEach(line => {
      if (line.ClaMaquina === e.value) {
        this.setState ((state, props) => {
          return {
            ABCMachine: line.ABCMachine,
            ClaMaquina: e.value,
            errors: {...this.state.errors, ClaMaquina: null, ABCMachine: null}
          }
        })
        
      }
    });

    if (e.value === null) {
      e.value = '';
      this.setState({
        ClaMaquina: null,
        ABCMachine: null
      });
    }
  }

  onLanguageChange = (e) => {
    const language = e.value;
    if (e.value !== null) {
      this.setState({ 
        Language: language
      });
      this.getFormulario(this.state.ClaFormulario, language); 
    }
  };

  onSelectedDepartmentChange = ( event ) => { 

    let {value} = event;

    this.setState ( ( state, props ) => {  
        return { 
          ClaDepartamento: event.value,
          ClaCelda:null,
          errors:{...this.state.errors, ClaDepartamento:null, ClaCelda:null}
      }
    } )
    
    if (value === null)
    {
      value = '';
      this.setState({ 
        Celdas:[] 
      });
      
      return;
    }

    
    const urlWebService = `${config.UrlApiProject}formulario/GetCeldas?ClaDepartamento=${value}`; 
    const paramsService = null;

    callApi(urlWebService, 'GET', paramsService, (response) => {
      this.setState({ 
        Celdas:response.data.Celdas 
      });


    });  


  };

  languageTemplate = () => {
    return (
      <>
        <FieldSelectButton 
          options={this.languageOptions}
          value={this.state.Language}   
          className="p-1"   
          optionLabel="name" 
          onChange={this.onLanguageChange} 
        />      
      </>
    );
  };
  
    render () {  

      const {preguntas} = this.state; 
      

      const  copyPreguntas = [...preguntas];
      const listPreguntasSteps = [];
       
      let listPreguntasTemp = []; 
 
      
      if (preguntas !== undefined){
         // generamos arreglo divido por default de preguntas por step
         
         const result =  this.chunkArray(copyPreguntas, this.state.DefaultCantidadPreguntas);
         
         if ( result.length > 0)
         {

            if (this.state.ClaFormulario === "1"){
              if (!this.state.IsNewHomeVersion) {
                listPreguntasSteps.push(
                  <WizardLayout.Step key="Step1-1" onBeforeNextStep={this.ValidaStep1}>
                    <Row className="align-items-start">
                      <Col lg={2} md={3} sm={12} xs={12}> 
                        <FormGroup>
                          <Label>{this.props.t('formulario:Shift')}</Label>
                          <SelectButton 
                            value={this.state.ClaTurno} 
                            options={this.state.Shifts}
                            onChange={this.onSelectedShiftChange}
                            optionLabel="NomTurno"
                            optionValue="ClaTurno" 
                            className={this.state.Shifts.length > 3 ? ( this.state.errors.ClaTurno !== null && this.state.errors.ClaTurno !== undefined) ? " buttonList buttonListInvalid" : " buttonList"
                                       : ( this.state.errors.ClaTurno !== null && this.state.errors.ClaTurno !== undefined)  ? " p-invalid" : " "}
                          > 
                          </SelectButton> 
                          { this.state.errors.ClaTurno !== null ? ( 
                            <div className="dx-item dx-validationsummary-item"> 
                              <div className="dx-item-content dx-validationsummary-item-content">{this.state.errors.ClaTurno}
                              </div>
                            </div>
                  
                          ) 
                            : <></>}
                        </FormGroup>
                      </Col>
                      <Col lg={3} md={3} sm={12} xs={12}> 
                        <FormGroup>
                          <Label>{this.props.t('formulario:Department')}</Label>
                          <SelectButton 
                            value={this.state.ClaDepartamento} 
                            options={this.state.Departments}
                            onChange={this.onSelectedDepartmentChange}
                            optionLabel="NomDepartamento4"
                            optionValue="ClaDepartamento4"
                            className={this.state.Departments.length > 3 ? ( this.state.errors.ClaDepartamento !== null && this.state.errors.ClaDepartamento !== undefined) ? " buttonList buttonListInvalid" : " buttonList"
                            : ( this.state.errors.ClaDepartamento !== null && this.state.errors.ClaDepartamento !== undefined)  ? " p-invalid" : " "}
                          > 
                          </SelectButton> 
                          { this.state.errors.ClaDepartamento !== null ? ( 
                            <div className="dx-item dx-validationsummary-item"> 
                              <div className="dx-item-content dx-validationsummary-item-content">{this.state.errors.ClaDepartamento}
                              </div>
                            </div>
                          
                          ) 
                          : <></>}
                        </FormGroup>
                      </Col>
                      {
                         this.state.Celdas.length > 0 ? (
                           <Col lg={7} md={6} sm={12} xs={12}> 
                             <FormGroup>
                               <Label>{this.props.t('formulario:Cell')}</Label>
                               <SelectButton 
                                 value={this.state.ClaCelda} 
                                 options={this.state.Celdas}
                                 onChange={this.onSelectedCeldaChange}
                                 optionLabel="ClaveCelda"
                                 optionValue="ClaCelda"
                                 className={this.state.Celdas.length > 3 ? ( this.state.errors.ClaCelda !== null && this.state.errors.ClaCelda !== undefined) ? "  cellList cellListInvalid scrollbar-primary scroll-y" : " cellList scrollbar-primary scroll-y"
                            : ( this.state.errors.ClaCelda !== null && this.state.errors.ClaCelda !== undefined)  ? " p-invalid" : " "}
                               > 
                               </SelectButton> 
                               { this.state.errors.ClaCelda !== null ? ( 
                                 <div className="dx-item dx-validationsummary-item"> 
                                   <div className="dx-item-content dx-validationsummary-item-content">{this.state.errors.ClaCelda}
                                   </div>
                                 </div>
      
                                ) 
                                : <></>}
                             </FormGroup>
                           </Col>
                         ) : <></>
                      }
                      
                    </Row>
                  </WizardLayout.Step>) 
              }              
            }

            if (this.state.ClaFormulario === "2"){
              listPreguntasSteps.push(
                <WizardLayout.Step key="Step1-2" onBeforeNextStep={this.ValidaStep1}>
                  <Row className="align-items-start">
                    <Col lg={2} md={3} sm={12} xs={6}> 
                      <FormGroup>
                        <Label>{this.props.t('formulario:Shift')}</Label>
                        <SelectButton 
                          value={this.state.ClaTurno} 
                          options={this.state.Shifts}
                          onChange={this.onSelectedShiftChange}
                          optionLabel="NomTurno"
                          optionValue="ClaTurno" 
                          className={this.state.Shifts.length > 3 ? ( this.state.errors.ClaTurno !== null && this.state.errors.ClaTurno !== undefined) ? " buttonList buttonListInvalid scroll-y" : " buttonList scroll-y"
                                     : ( this.state.errors.ClaTurno !== null && this.state.errors.ClaTurno !== undefined)  ? " p-invalid" : " "}
                        > 
                        </SelectButton> 
                        { this.state.errors.ClaTurno !== null ? ( 
                          <div className="dx-item dx-validationsummary-item"> 
                            <div className="dx-item-content dx-validationsummary-item-content">{this.state.errors.ClaTurno}
                            </div>
                          </div>
                
                        ) 
                          : <></>}
                      </FormGroup>
                    </Col>  
                  </Row>
                </WizardLayout.Step>)
            }

            if (this.state.ClaFormulario === "3" || this.state.ClaFormulario === "4" || this.state.ClaFormulario === "5") {
              listPreguntasSteps.push(
                <WizardLayout.Step key="Step1-3" onBeforeNextStep={this.ValidaStep1}>
                  <Row className="align-items-start">
                    <Col lg={2} md={3} sm={12} xs={12}> 
                      <FormGroup>
                        <Label>{this.props.t('formulario:Shift')}</Label>
                        <SelectButton 
                          value={this.state.ClaTurno} 
                          options={this.state.Shifts}
                          onChange={this.onSelectedShiftChange}
                          optionLabel="NomTurno"
                          optionValue="ClaTurno" 
                          className={this.state.Shifts.length > 3 ? ( this.state.errors.ClaTurno !== null && this.state.errors.ClaTurno !== undefined) ? " buttonList buttonListInvalid" : " buttonList"
                                     : ( this.state.errors.ClaTurno !== null && this.state.errors.ClaTurno !== undefined)  ? " p-invalid" : " "}
                        > 
                        </SelectButton> 
                        { this.state.errors.ClaTurno !== null ? ( 
                          <div className="dx-item dx-validationsummary-item"> 
                            <div className="dx-item-content dx-validationsummary-item-content">{this.state.errors.ClaTurno}
                            </div>
                          </div>
                
                        ) 
                          : <></>}
                      </FormGroup>
                    </Col>
                    {
                       this.state.Celdas.length > 0 ? (
                         <Col lg={7} md={6} sm={12} xs={12}> 
                           <FormGroup>
                             <Label>{this.props.t('formulario:Cell')}</Label>
                             <SelectButton 
                               value={this.state.ClaCelda} 
                               options={this.state.Celdas}
                               onChange={this.onSelectedCeldaChange}
                               optionLabel="ClaveCelda"
                               optionValue="ClaCelda"
                               className={this.state.Celdas.length > 3 ? ( this.state.errors.ClaCelda !== null && this.state.errors.ClaCelda !== undefined) ? "  cellList cellListInvalid scrollbar-primary scroll-y" : " cellList scrollbar-primary scroll-y"
                          : ( this.state.errors.ClaCelda !== null && this.state.errors.ClaCelda !== undefined)  ? " p-invalid" : " "}
                             > 
                             </SelectButton> 
                             { this.state.errors.ClaCelda !== null ? ( 
                               <div className="dx-item dx-validationsummary-item"> 
                                 <div className="dx-item-content dx-validationsummary-item-content">{this.state.errors.ClaCelda}
                                 </div>
                               </div>
    
                              ) 
                              : <></>}
                           </FormGroup>
                         </Col>
                       ) : <></>
                    }
                    {
                      this.state.Lines.length > 0 ? (
                        <Col lg={2} md={3} sm={12} xs={12}> 
                          <FormGroup>
                            <Label>{this.props.t('formulario:Line')}</Label>
                            <SelectButton 
                              value={this.state.ClaMaquina} 
                              options={this.state.Lines}
                              onChange={this.onSelectedLineChange}
                              optionLabel="ABCMachine"
                              optionValue="ClaMaquina" 
                              className={this.state.Lines.length > 3 ? ( this.state.errors.ClaMaquina !== null && this.state.errors.ClaMaquina !== undefined) ? " buttonList buttonListInvalid" : " buttonList"
                                          : ( this.state.errors.ABCMachine !== null && this.state.errors.ABCMachine !== undefined)  ? " p-invalid" : " "}
                            > 
                            </SelectButton> 
                            { this.state.errors.ClaMaquina !== null ? ( 
                              <div className="dx-item dx-validationsummary-item"> 
                                <div className="dx-item-content dx-validationsummary-item-content">{this.state.errors.ClaMaquina}
                                </div>
                              </div>
                    
                            ) 
                              : <></>}
                          </FormGroup>
                        </Col>
                      ) : <></>
                    }
                    
                  </Row>
                </WizardLayout.Step>)
            }
          
         } 

         for (let i = 0; i < result.length;){ 
              for (let x = 0; x < result[i].length;) {
                listPreguntasTemp.push(<Pregunta key={`pregunta-${result[i][x].IdFormularioPregunta}`} onValueChanged={this.preguntaCambio} pregunta={result[i][x]}  />)
                x+=1;
              } 

              listPreguntasSteps.push(
                <WizardLayout.Step
                  key={`step-${i}`} 
                  items={result[i]}
                  onBeforeNextStep={this.onBeforeNextStep}
                  showNavigation={listPreguntasTemp.length > 5 ? 'both' : 'default'}
                >
                  {listPreguntasTemp}
                </WizardLayout.Step>
              )

              listPreguntasTemp = [];
              i+=1;
         }
        
         if (listPreguntasSteps.length>0){
          listPreguntasSteps.push(
            <WizardLayout.Step key={`step-${listPreguntasSteps.length+1}`}> 
              <Row>
                <Col md={12} xs={12}> 
                  <FormGroup>
                    <Label>{this.props.t('formulario:Signature')}</Label>
                    <SignatureCanvas
                      ref={(ref) => { this.signPad = ref }}
                      canvasProps={{ className: 'react-sign-canvas'}}
                      onEnd={this.setSignData}
                    />
                    <Button color="primary" className="w-100 btn-icon " type="button" onClick={this.clearSignPad}>
                      <span className="btn-inner--icon">
                        <i className="fas fa-chevron-right" />
                      </span>
                      <span className="btn-inner--text">{this.props.t('common:Clear')}</span>  
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12} xs={12} className="text-right"> 
                  <Button color="success" className="btn-icon " type="button" onClick={this.enviarFormulario}>
                   
                    <span className="btn-inner--text">{this.props.t('common:Send')}</span>  
                    <span className="btn-inner--icon">
                      <i className="fas fa-check" />
                    </span> 
                  </Button>
                </Col>
              </Row>
            </WizardLayout.Step>)
         }
      }  
      
      if (listPreguntasSteps.length===0){
          return (
            <>   
              <PageContent> 
                <Row> 
                  <Col md={12}>
                    <Card>
                      <CardHeader>
                        <CardTitle>{this.props.t('common:Loading')}</CardTitle>
                      </CardHeader>
                      <CardBody> 
                      
                      
                        <Skeleton width="10rem" className="p-mb-2"></Skeleton> 
                        <br></br>
                        <Skeleton className="p-mb-2" height="2rem"></Skeleton>

                      </CardBody>
                      <CardFooter>
                        <Row>
                          <Col md={6} xs={6} className="text-left">
                            <Skeleton width="8rem" height="2rem" className="p-mb-2"></Skeleton> 
                          </Col>
                          <Col md={6} xs={6} className="text-right">
                            <Skeleton width="8rem" height="2rem" className="p-mb-2"></Skeleton> 
                          </Col>
                        </Row>
                      </CardFooter>
                    </Card>
                  </Col> 
                </Row>    
              </PageContent>  
            </>
          );
      }

      if (this.props.ClaFormulario !== null && typeof(this.props.ClaFormulario) !== 'undefined'){
        return (
          <>    
            <Row> 
              <Col>
                <Card>
                  <CardHeader className={this.state.IsAvailableCheckList ? 'bg-white' : ''}>
                    <CardTitle>
                      <Row>
                        <Col xs={8}>
                          {this.state.IsAvailableCheckList ? '' : this.state.NomFormulario}
                        </Col>
                        <Col xs={4} className='text-right'>
                          {this.languageTemplate()}
                        </Col>
                      </Row>                                                                                                                      
                    </CardTitle>
                  </CardHeader>
                  <CardBody> 
                    <WizardLayout>
                      { listPreguntasSteps }   
                    </WizardLayout>
                  </CardBody> 
                </Card>
              </Col> 
            </Row>     
          </>
        );
      }
       
        return (
          <>   
            <PageContent>  
              <Row> 
                <Col md={12}>
                  <Card>
                    <CardHeader>
                      <CardTitle>
                        <Row>
                          <Col xs={8}>
                            {this.state.NomFormulario}
                          </Col>
                          <Col xs={4} className='text-right'>
                            {this.languageTemplate()}
                          </Col>
                        </Row>                                                
                      </CardTitle>
                    </CardHeader>
                    <CardBody> 
                      <WizardLayout>
                        { listPreguntasSteps }   
                      </WizardLayout>
                    </CardBody> 
                  </Card>
                </Col> 
              </Row>  
            </PageContent>    
          </>
        );
 }
} 

 export default withTranslation(['formulario','common'])(ResponderFormulario); 
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react'; 
import { useTranslation } from 'react-i18next'; 
import PageContent from '../../../layouts/PageContent'; 
import { Toolbar } from 'primereact/toolbar'; 
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row, Col, Card, CardBody } from 'reactstrap';
import PerformanceDevSetUpTable from './PerformanceDevSetUpTable'; 
import PerformanceDevSetUpDetail from './PerformanceDevSetUpDetail'; 
import CultureSurveyApprovingFeedback from "../CompanyCulture/CultureSurveyApprovingFeedback.jsx"; 
import { Divider } from 'primereact/divider';

const PerformanceDevSetUp = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);
  const [filters, setfilters] = useState({});   
  const [esModoConsulta, setEsModoConsulta] = useState(true);
  const [IdEncuesta, setIdEncuesta] = useState(null);
  const [ClaAnio, setClaAnio] = useState(null);
  const [EsApproveResponses, setEsApproveResponses] = useState(false);
  
  const rightToolbarTemplate = () => {
    return (
      <>
        <FieldButton label={t('common:New')} icon="pi pi-plus" className="p-button-success p-mr-2" onClick={onNewClick} /> 
      </>
    )
  } 

  const leftToolbarTemplate = () => {
    return (
      <></>
      )
  }

  const onNewClick = () => { 
    setfilters({});
    setEsModoConsulta(false);
  } 

  const onEditClick = (rowClick) => {  
    setfilters({IdPerformanceDev:rowClick.IdPerformanceDev})
    setEsModoConsulta(false);
  } 

  const onClickApproveResponses = (rowClick) => {   
    setClaAnio(rowClick.Anio);
    setIdEncuesta(rowClick.IdEncuesta);
    setEsApproveResponses(true);
  }  

  const onReturnApproveResponsesClick = () => {
    setEsApproveResponses(false);
    setEsModoConsulta(true); 
  }

  const header  = ( 
    <>
      {t('hr:PerformanceDevTableTitle')}
    </>
  )

  const onCloseDetailView = (isSaveReturn) => { 
    setEsModoConsulta(true); 
  };

  const rightToolbarCultureSurveApproveTemplate = () => {
    return (
      <> 
        <FieldButton label={t('common:Back')} icon="pi pi-arrow-left" className="p-button-secondary p-mr-2" onClick={onReturnApproveResponsesClick} />
      </>
    )
  } 

  const leftToolbarCultureSurveApproveTemplate = () => {
    return (
      <> 
        <h2><label>{ClaAnio}</label> </h2>
      </>
      )
  }
 
  if (EsApproveResponses){
    return (
      <PageContent title={t('hr:CultureSurveyApproveResponses')}>   
        <Card>   
          <CardBody> 
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>   
                <Toolbar left={leftToolbarCultureSurveApproveTemplate} right={rightToolbarCultureSurveApproveTemplate}></Toolbar>
              </Col> 
            </Row>
            <Divider />
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>   
                <CultureSurveyApprovingFeedback ClaAnio={ClaAnio} IdEncuesta={IdEncuesta}></CultureSurveyApprovingFeedback>
              </Col> 
            </Row> 
          </CardBody>
        </Card>
      </PageContent>
    )
  }

  if (esModoConsulta){
    return (
      <>  
        <PageContent title={t('hr:PerformanceDevTitle')}>  
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>   
              <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
            </Col> 
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
            &nbsp;
            </Col> 
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>   
              <PerformanceDevSetUpTable onEditClick={onEditClick} onClickApproveResponses={onClickApproveResponses}></PerformanceDevSetUpTable>
            </Col> 
          </Row> 
        </PageContent>
      </>
      );
  }
  
    return (
      <> 
        <PageContent title={t('hr:PerformanceDevTitle')}>   
          <PerformanceDevSetUpDetail onCloseDetailView={onCloseDetailView} filters={filters}></PerformanceDevSetUpDetail>
        </PageContent>
      </>
        );
  
  


};

export default PerformanceDevSetUp;

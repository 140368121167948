import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardHeader, CardBody, CardFooter, Label } from 'reactstrap';
import { Panel } from 'primereact/panel'
import { callApi, showSweetAlert, formatDate } from "utils/utils";
import { config } from 'utils/config';
import FieldText from 'components/Controls/FieldText';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldAutoComplete from 'components/Controls/FieldAutoComplete';
import EnterPOD from './EnterPOD';
import { Dialog } from 'primereact/dialog';
import ManualPODSearch from './ManualPODSearch';
import FixPODsWithErrors from './FixPODsWithErrors';

const ViewPODFilters = (props) => {
  const {t, i18n} = useTranslation(['pod', 'common']);
  const [soNumber, setSoNumber] = useState(null);
  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [errors, setErrors] = useState([]);
  const [tiposPOD, setTiposPOD] = useState([]); // POD types
  const [tipoPOD, setTipoPOD] = useState(null); // Selected POD type
  const [showEnterPOD, setShowEnterPOD] = useState(false);
  const [clienteCuentas, setClienteCuentas] = useState([]);
  const [clienteCuenta, setClienteCuenta] = useState([]);
  const [showManualPODSearch, setShowManualPODSearch] = useState(false);
  const [showFixPODs, setShowFixPODs] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState(null);
  const [filters, setFilters] = useState([]);
  const [lastPODProcessed, setLastPODProcessed] = useState([]);
  const [releaseNumber, setReleaseNumber] = useState(null)

  useEffect(() => {
    getTiposPOD();
    refreshPODs();
    getPODCompanies();
  }, [])

  useEffect(() => {
    getLastProcessedPOD();
  }, [company])

  const refreshPODs = () => {
    const newFilters = {
      soNumber,
      invoiceNumber,
      claTipoPOD: tipoPOD,
      company,
      releaseNumber
    };

    if (clienteCuenta !== null
        && clienteCuenta !== undefined 
        && clienteCuenta.ClaClienteCuenta !== null 
        && clienteCuenta.ClaClienteCuenta !== undefined
      ) {
      newFilters.claClienteCuenta = clienteCuenta.ClaClienteCuenta;
    }

    props.onFiltersChange(newFilters);
    getTiposPOD();
    getPODCompanies();
  }

  const getTiposPOD = () => {
    const urlWebService = `${config.UrlApiProject}POD/GetTiposPOD`;

    const paramsService = {
    };

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setTiposPOD(response.data);
    });
  }

  const getLastProcessedPOD = () => {
    const urlWebService = `${config.UrlApiProject}POD/GetLastIdentifiedPODByCompany?${company !== null && company !== undefined ? `ClaEmpresa=${company}`: ''}`;

    const paramsService = {
    };

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setLastPODProcessed(response.data);
    });
  }

  const getPODCompanies = () => {
    const urlWebService = `${config.UrlApiProject}POD/GetPODCompanies`;

    const paramsService = {
    };

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setCompanies(response.data);
    });
  }

  const onTipoPODChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.tipoPOD;
    setErrors(newErrors);
    setTipoPOD(e.value);
  }

  const onCompanyChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.company;
    setErrors(newErrors);
    setCompany(e.value);
  }

  const onSoNumberChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.soNumber;
    setErrors(newErrors);
    setSoNumber(e.target.value);
  }

  const onReleaseNumberChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.releaseNumber;
    setErrors(newErrors);
    setReleaseNumber(e.target.value);
  }

  const onInvoiceNumberChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.invoiceNumber;
    setErrors(newErrors);
    setInvoiceNumber(e.target.value);
  }

  const onEnterPODHide = () => {
    setShowEnterPOD(false);
    refreshPODs();
  }

  const onManualPODSearchHide = () => {
    setShowManualPODSearch(false);
    refreshPODs();
  }

  const filterClienteCuenta = (e) => {
    let valor = ''; 
    if (clienteCuenta.NomClienteCuenta !== null && clienteCuenta.NomClienteCuenta !== '' &&  e.query === "")
    {
     valor = clienteCuenta.NomClienteCuenta;
    }
    else{
     valor = e.query;
    }
    const paramsServiceOwners = {};
    // event.query 
    const urlWebServiceOwners = `${config.UrlApiProject}POD/GetClienteCuentas?valor=${valor}`;
    // Get owner
    callApi(urlWebServiceOwners, 'GET', paramsServiceOwners, (response) => {  
       setClienteCuentas(response.data.ClienteCuentas)
    });    
  }

  const onClienteCuentaChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.clienteCuenta;
    setErrors(newErrors);
    setClienteCuenta(e.value);
  }

  const getInvoiceNumFromManual = (invoiceNum) => {
    if (invoiceNum !== undefined && invoiceNum !== null)
    {
      setInvoiceNumber(invoiceNum);
    }

    setClienteCuenta([]);
    setSoNumber(null);
  }

  const filterHeaderTemplate = (options) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down text-white' : 'pi pi-chevron-up text-white';
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className="card-header">
        <div className="p-toolbar p-component">
          <div className="p-toolbar-group-left">
            <div className="card-title">
              <FieldButton className={options.togglerClassName} onClick={options.onTogglerClick}>
                <span className={toggleIcon}></span> 
              </FieldButton>
              <span className={titleClassName}>
                {t('common:Filters')}
              </span>
            </div>  
          </div>
          <div className="p-toolbar-group-right">
            <FieldButton
              label={t('pod:FixPODsWithErrors')}
              onClick={() => setShowFixPODs(true)}
              className='p-button-secondary mr-2'
              icon='pi pi-pencil'
            />
            <FieldButton
              label={t('pod:UploadPOD')}
              onClick={() => setShowEnterPOD(true)}
              className='p-button-success mr-2'
              icon='pi pi-upload'
            />
            <FieldButton
              onClick={() => refreshPODs()}
              className='p-button'
              icon='pi pi-search'
            />
          </div>
        </div> 
      </div>
    )
  }

  return (
    <>
      <Panel
        header={t('common:Filters')}
        toggleable
        headerTemplate={filterHeaderTemplate}
      >
        <Row className='p-grid p-fluid align-items-start'>
          <Col xs={12} sm={12} md={12} lg={3} className="p-field">
            <label htmlFor='user'>{t("pod:BillTo")}</label>
            <FieldAutoComplete
              dropdown
              id="clientCuenta"
              name="clienteCuenta"
              value={clienteCuenta}
              onChange={onClienteCuentaChange}
              suggestions={clienteCuentas}
              field="NomClienteCuenta"
              completeMethod={filterClienteCuenta}
              errors={errors}
            /> 
          </Col>
          <Col xs={12} sm={6} md={6} lg={2} className='p-field'>
            <Label>{t('pod:Company')}</Label>
            <FieldDropdown
              name='company'
              value={company}
              optionValue="ClaEmpresa"
              optionLabel="NombreEmpresa"
              options={companies}
              onChange={onCompanyChange}
              errors={errors}
              className='mb-2'
            />
          </Col>
          <Col xs={12} sm={6} md={6} lg={2} className='p-field'>
            <Label>{t('pod:InvoiceNum')}</Label>
            <FieldText
              name='invoiceNumber'
              value={invoiceNumber}
              onChange={onInvoiceNumberChange}
              errors={errors}
            />
          </Col>
          <Col xs={12} sm={6} md={6} lg={2} className='p-field'>
            <Label>{t('pod:SONum')}</Label>
            <FieldText
              name='soNumber'
              value={soNumber}
              onChange={onSoNumberChange}
              errors={errors}
            />
          </Col>
          <Col xs={12} sm={6} md={6} lg={1} className='p-field'>
            <Label>{t('pod:ReleaseNum')}</Label>
            <FieldText
              name='releaseNumber'
              value={releaseNumber}
              onChange={onReleaseNumberChange}
              errors={errors}
            />
          </Col>
          <Col xs={12} sm={6} md={6} lg={2} className='p-field'>
            <table>
              <tr>
                <th colSpan={6} style={{borderBottom: '1px solid'}}>{t('pod:LastProcessedPOD')}</th>
              </tr>
              <tr>
                <td><span style={{fontWeight: 'bold'}}>{t('pod:Company')}</span>: {lastPODProcessed.NombreEmpresa}</td>
              </tr>
              <tr>
                <td><span style={{fontWeight: 'bold'}}>{t('pod:Invoice')}</span>: {lastPODProcessed.NumFactura}</td>
              </tr>
              <tr>
                <td><span style={{fontWeight: 'bold'}}>{t('pod:ProcessedDate')}</span>: {formatDate(lastPODProcessed.FechaUltimaMod)}</td>
              </tr>
            </table>
            {/* <h3>{t('pod:LastProcessedPOD')}</h3><br />
            <Label>{t('pod:Company')}:</Label> {lastPODProcessed.NombreEmpresa}
            {t('pod:Invoice')}: {lastPODProcessed.NumFactura}
            {t('pod:ProcessedDate')}: {formatDate(lastPODProcessed.FechaUltimaMod)} */}
          </Col>
        </Row>
      </Panel>
      <Dialog
        visible={showEnterPOD}
        header={t('pod:UploadPOD')}
        onHide={() => onEnterPODHide()}
        maximized={true}
        blockScroll
      >
        <EnterPOD />
      </Dialog>
      <Dialog
        visible={showManualPODSearch}
        header={t('pod:ManualPODSearch')}
        onHide={() => onManualPODSearchHide()}
        // breakpoints={{'1500px': '75vw', '1000px': '100vw'}}
        style={{minWidth: '30vw', minHeight: '10vw'}}
        blockScroll
      >
        <ManualPODSearch setInvoiceNum={getInvoiceNumFromManual} />
      </Dialog>
      <Dialog
        visible={showFixPODs}
        header={t('pod:FixPODsWithErrors')}
        onHide={() => setShowFixPODs(false)}
        maximized={true}
        modal
        blockScroll
      >
        <FixPODsWithErrors />
      </Dialog>
    </>
)
}

export default ViewPODFilters;
/* eslint-disable no-const-assign */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next'; 
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  ListGroupItem,
  ListGroup,
  Row,
  Col,
  FormGroup,
  Label,
} from 'reactstrap';   
import logo from '../../../../../assets/img/Sirius_logo.png';
import FieldButton from '../../../../../components/Controls/FieldButton/FieldButton.jsx';

import { config } from 'utils/config';
import { callApi, showSweetAlert, setSessionData } from 'utils/utils';
import { Dialog } from 'primereact/dialog';
import './HeaderTablet.css';
import FieldSelectButton from 'components/Controls/FieldSelectButton';
import * as yup from 'yup';
import YupValidator from '../../../../../utils/YupValidator';
import { useHistory, Link } from "react-router-dom";

const PrincipalHeader = ({
  onConfigurationChange,
  IsHomeScreen
}
) => {
  const { t, i18n } = useTranslation(['tablets', 'common', 'downTime']);
  useEffect(() => {}, []);

  const [IsShowDeviceSettings, setIsShowDeviceSettings] = useState(false);
  const [ClaAreaDepartamento, setClaAreaDepartamento] = useState(null); 
  const [ClaCelda, setClaCelda] = useState(null);
  const [AreaDepartamentoList, setAreaDepartamentoList] = useState([]);
  const [CeldaList, setCeldaList] = useState([]);
  const [errors, setErrors] = useState([]);
  const homeScreenLink = useRef();

  useEffect(() => {
    GetAreaDepartamento();  
  }, []);

  const GetAreaDepartamento = () => {
    const urlWebService = `${config.UrlApiProject}departamento/getDepartamentos`;
    const paramsService = null;

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setAreaDepartamentoList(response.data.Departamentos); 
    });
  };

  const renderHeader = () => {
    return (
      <div>
        <Row>
          <Col lg={4} md={4} sm={12} xs={12}> 
            <span>Set Default Cell of This Device</span> 
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="text-center">
             
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="text-right">
             
          </Col>
        </Row>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <Row>
          <Col lg={4} md={4} sm={12} xs={12}>
             
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="text-center">
            <FieldButton
              label="Close" 
              className="p-button-secondary no-border p-mr-2 rounded-button btntablet"
              onClick={() => setIsShowDeviceSettings(false)}
            />
            &nbsp;
            <FieldButton
              label="Set" 
              className="p-button-success no-border p-mr-2 rounded-button btntablet"
              onClick={setCeldaDefaultOnDevice}
            />
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="text-right">
            
          </Col>
        </Row>
      </div>
    );
  };

  const onAreaDepartamentoClick = (pClaAreaDepartamento) => {
    const newErrors = { ...errors };
    delete newErrors.ClaAreaDepartamento;
    setErrors(newErrors); 
    setClaAreaDepartamento(pClaAreaDepartamento);
    GetCelda(pClaAreaDepartamento);
    setClaCelda(null);
  };

  const GetCelda = (pClaAreaDepartamento) => {
    const urlWebService = `${config.UrlApiProject}celda/GetCeldas?ClaDepartamento=${pClaAreaDepartamento}`;
    const paramsService = null;

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setCeldaList(response.data.Celdas);
    });
  };

  const onCeldaClick = (pIdCelda) => {
    const newErrors = { ...errors };
    delete newErrors.ClaCelda;
    setErrors(newErrors);  
    setClaCelda(pIdCelda); 
  };

  const onShowDeviceSettings = () => {
    setErrors([]);  
    setClaAreaDepartamento(null); 
    setClaCelda(null); 
    setIsShowDeviceSettings(true)
  }

  const validationScheme = yup.object().shape({
    ClaAreaDepartamento: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaCelda: yup.mixed().required(t('common:RequiredField')).nullable() 
  });

  const setCeldaDefaultOnDevice = async (e) => {
    const value = {
      ClaAreaDepartamento,
      ClaCelda 
    };

    const validator = new YupValidator(validationScheme);
    const isValid = await validator.validate(value);
    if (!isValid) {
      setErrors(validator.errors); 
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      return;
    }
    
    // cambiamos la configuracion
    setSessionData({
      IsDeviceAssignedOnCell: true,
      ClaAreaDepartamento,
      ClaCelda
    });

    setIsShowDeviceSettings(false);
    setTimeout(()=> {
      onConfigurationChange()
    }, 10);
  };
  

  const onHomeScreenClick = () => {
    homeScreenLink.current.click();
  }


  return (
    <> 
      <div className='bgtablet-header'> 
        <img alt="..." src={logo} style={{ width: '160px', height: '30px' }}  /> 
        
        
        {
          IsHomeScreen === false ?
          (<i className="fas fa-home fa-2x Cursor float-right" onClick={onHomeScreenClick}></i>)
          :
          (<i className="fas fa-cogs fa-2x Cursor float-right" onClick={onShowDeviceSettings}></i>) 
        }
        
        <Link 
          ref={homeScreenLink} 
          to={
                   { pathname: "CellHomeMenuScreen", search: `?IsDeviceAssignedOnCell=true` }
                }
        >
        </Link>      
      </div>
      <Dialog
        visible={IsShowDeviceSettings}
        modal
        style={{ width: '100vw' }}
        footer={renderFooter}
        header={renderHeader}
        onHide={() => setIsShowDeviceSettings(false)}
        closable={false}
        className="dialog-header-background-blue"
        maximized={true}
        draggable={false}
      >
        <Row className='pt-3'>
          <Col>
            <div className='Tablet-Field'>
              <Row className='pb-1'>
                <Col>
                  <span className="h3">{t('downTime:Department')}</span>
                </Col> 
              </Row>
              <Row className="text-center">
                <Col>
                  <div className="p-fluid">
                    <FieldSelectButton
                      name="ClaAreaDepartamento"
                      value={ClaAreaDepartamento}
                      options={AreaDepartamentoList}
                      onChange={(e) => onAreaDepartamentoClick(e.value)}
                      optionValue="ClaDepartamento4"
                      optionLabel="NomDepartamento4"
                      errors={errors}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row hidden={ClaAreaDepartamento === null}>
          <Col>
            <div className='Tablet-Field'>
              <Row className='pb-1'>
                <Col>
                  <span className="h3">{t('downTime:Cell')}</span>
                </Col> 
              </Row>
              <Row className="pt-2 text-center">
                <Col lg={12} md={12} sm={12} xs={12}>
                  <FieldSelectButton
                    name="ClaCelda"
                    value={ClaCelda}
                    options={CeldaList}
                    className="cellList"
                    onChange={(e) => onCeldaClick(e.value)}
                    optionValue="ClaCelda"
                    optionLabel="ClaveCelda"
                    errors={errors}
                  />
                </Col>
              </Row>
            </div> 
          </Col>
        </Row>
      </Dialog>
    </>
  );
};

export default PrincipalHeader;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'; 
import { useTranslation } from 'react-i18next';  
import { config } from '../../../../utils/config';
import { callApi, getSessionItem } from '../../../../utils/utils';  
 // import OrgChart from '../../mytree';
import { Col, Row } from 'reactstrap';
import OrganizationalChartTemplate from './OrganizationalChartTemplate';

const OrganizationalChart = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['actionPlan', 'common']);
  const [errors, setErrors] = useState({});
  const [PersonasOrganigrama, setPersonasOrganigrama] = useState({});
  const [filters, setfilters] = useState(null);
  

  useEffect(() => {
    obtenerOrganigrama(props.filters);
    setfilters(props.filters);
    setErrors({});
    
  }, [props.filters]);

  const obtenerOrganigrama = (pFilters) => {    
    setPersonasOrganigrama([]);
    const getMSWOrganigrama = `${config.UrlApiProject}HR/GetMSWOrganigrama`;
    const paramsToService = {
      ClaAnio:                pFilters.ClaAnio,
      ClaMes:                 pFilters.ClaMes,
      EsShowIndicador1:       pFilters.Indicadores !== undefined ? (pFilters.Indicadores[0] !== undefined ? pFilters.Indicadores[0] : null) : null,
      EsShowIndicador2:       pFilters.Indicadores !== undefined ? (pFilters.Indicadores[1] !== undefined ? pFilters.Indicadores[1] : null) : null,
      EsShowIndicador3:       pFilters.Indicadores !== undefined ? (pFilters.Indicadores[2] !== undefined ? pFilters.Indicadores[2] : null) : null,
      EsShowIndicador4:       pFilters.Indicadores !== undefined ? (pFilters.Indicadores[3] !== undefined ? pFilters.Indicadores[3] : null) : null,
      EsShowIndicador5:       pFilters.Indicadores !== undefined ? (pFilters.Indicadores[4] !== undefined ? pFilters.Indicadores[4] : null) : null
    };
    
    callApi(getMSWOrganigrama, 'POST', paramsToService, (response) => { 
      setPersonasOrganigrama(response.data.Organigrama);
    });
  };
  

  if (PersonasOrganigrama.length > 0) {
    return (
      <>
        <Row>
          <Col>
            <OrganizationalChartTemplate nodes={PersonasOrganigrama} filters={filters} />            
          </Col>
        </Row>
      </>
    );
  }

  return <>Loading...</>;
};
export default OrganizationalChart;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter, Label } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../utils/utils';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon';
import moment from 'moment';
import Highcharts from 'highcharts';
import swal from '@sweetalert/with-react';
import PageContent from 'layouts/PageContent';
import { Toolbar } from 'primereact/toolbar';
import { ProgressBar } from 'primereact/progressbar';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldDropdownMultiSelect from '../../../components/Controls/FieldDropDownMultiSelect/FieldDropdownMultiSelect.jsx';
import FieldText from 'components/Controls/FieldText.jsx';
import FieldTextArea  from 'components/Controls/FieldTextArea.jsx'
import FieldNumber from 'components/Controls/FieldNumber.jsx';
import FieldCheckbox from 'components/Controls/FieldCheckbox.jsx';
import FieldAutoComplete from 'components/Controls/FieldAutoComplete.jsx';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import { Dialog } from 'primereact/dialog';
import Select, { InputActionMeta } from 'react-select';
import { Panel } from 'primereact/panel';
import FileLibraryUpLoadEdit from './FileLibraryUpLoadEdit.jsx';
import FileLibraryByUserAttributeTipoCampo from './FileLibraryByUserAttributeTipoCampo.jsx';
import InstructionsCard from 'components/Cards/InstructionsCard/InstructionsCard.jsx';

const FileLibraryFileByUserFilter = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['dms', 'common']);
  const [errors, setErrors] = useState({});
  const [ClaLibrary, setClaLibrary] = useState(null);
  const [OpcionesLibrary, setOpcionesLibrary] = useState([]);
  const [AttributeList, setAttributeList] = useState([]);
  const [AttributeOpciones, setAttributeOpciones] = useState([]);
  const [TitleFile, setTitleFile] = useState(null);
  const [DescriptionFile, setDescriptionFile] = useState(null);
  

  useEffect(() => {
    obtenerLibrary();
    setErrors({});
  }, []);

  useEffect(() => {  
    if (props.ClaLibrary > 0) {
      setErrors({}); 
      setClaLibrary(props.ClaLibrary);
      if (props.ClaLibrary === null) {
        setAttributeList([]);
        GetLibraryAttributeOpciones([]); 
      }else {
        GetLibraryAttribute(props.ClaLibrary);
      }    
  
      if (props.onSearch) {
        const filtersData = {
          isClearInfo: true,
          TitleFile,
          DescriptionFile
        };
        props.onSearch(filtersData);        
      }
    }
    
  }, [props.ClaLibrary]);

  const obtenerLibrary = () => {
    const getLibraryCmb = `${config.UrlApiProject}DMS/GetLibraryCmb`;
    const paramsToService = {};
    callApi(getLibraryCmb, 'GET', paramsToService, (response) => {
      setOpcionesLibrary(response.data.Library);
    });
  };

  const onApplyFilters = async () => {

    const value = {
      ClaLibrary      
    };

    const validator = new YupValidator(valitationSchemeSearch);
    const esValido = await validator.validate(value);

    if (!esValido) {
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      return;
    }

    const AttributeListCopy = [...AttributeList];

    AttributeListCopy.forEach((element) => {
      if (Array.isArray(element.RespuestaAttribute)) {
        let opc = '';
        element.RespuestaAttribute.map((item, index) => {
          return (opc = `${opc + element.RespuestaAttribute[index]},`);
        });
        element.RespuestaAttribute = opc.slice(0, -1);
      }
    });

    if (props.onSearch) {
      const filtersData = {
        ClaLibrary,
        AttributeList : AttributeListCopy,
        AttributeOpciones,
        TitleFile,
        DescriptionFile
      };
      props.onSearch(filtersData);
      console.log(filtersData);
    }
  };

  const valitationSchemeSearch = yup.object().shape({    
    ClaLibrary: yup.mixed().required(t('common:RequiredField')).nullable(),
  });

  const filterHeaderTemplate = (options) => {
    const toggleIcon = options.collapsed
      ? 'pi pi-chevron-down text-white'
      : 'pi pi-chevron-up text-white';
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className="card-header">
        <div className="p-toolbar p-component">
          <div className="p-toolbar-group-left">
            <div className="card-title">
              <FieldButton className={options.togglerClassName} onClick={options.onTogglerClick}>
                <span className={toggleIcon}></span>
              </FieldButton>
              <span className={titleClassName}>{t('common:Filters')}</span>
            </div>
          </div>

          <div className="p-toolbar-group-right">
            <FieldButton
              label={t('myactivities:Search')}
              icon="pi pi-search"
              className="p-button p-highlight"
              onClick={onApplyFilters}
            >
            </FieldButton>
            &nbsp;
          </div>
        </div>
      </div>
    );
  };

  const onClaLibraryChange = (props, e) => {
    const newErrors = { ...errors };
    delete newErrors.ClaLibrary;
    setErrors(newErrors);
    setErrors({});

    const clalibrary =  e.value === undefined ? null : e.value;
    setClaLibrary(clalibrary);
    if (clalibrary === null) {
      setAttributeList([]);
      GetLibraryAttributeOpciones([]); 
    }else {
      GetLibraryAttribute(clalibrary);
    }    

    if (props.onSearch) {
      const filtersData = {
        isClearInfo: true,
        TitleFile,
        DescriptionFile
      };
      props.onSearch(filtersData);        
    }
  };

  const GetLibraryAttribute = (pClaLibrary) => {
    const getLibraryAttribute = `${config.UrlApiProject}DMS/GetLibraryAttribute?ClaLibrary=${pClaLibrary}`;
    const paramsToService = {};
    callApi(getLibraryAttribute, 'GET', paramsToService, (response) => {
      setAttributeList(response.data.ListAttibute);
      GetLibraryAttributeOpciones(pClaLibrary);
    });
  };

  const GetLibraryAttributeOpciones = (pClaLibrary) => {
    const getLibraryAttribute = `${config.UrlApiProject}DMS/GetLibraryAttributeOpciones?ClaLibrary=${pClaLibrary}`;
    const paramsToService = {};
    callApi(getLibraryAttribute, 'GET', paramsToService, (response) => {
      setAttributeOpciones(bindOpciones(response.data.ListOpciones));
    });
  };

  const bindOpciones = (rows) => {
    const activeRows = [];
    if (rows !== undefined) {
      if (rows.length > 0) {
        rows.forEach((item, index) => {
          item.RowIndex = index;
          activeRows.push({ ...item });
        });
      } else {
        return [];
      }
    }
    return activeRows;
  };

  const showActiveRows = (rows) => {
    const activeRows = [];
    if (rows !== undefined) {
      if (rows.length > 0) {
        rows.forEach((item, index) => {
          if (!item.BajaLogica || item.BajaLogica === 0 || item.BajaLogica === undefined) {
            item.BajaLogica = 0;
            item.RowIndex = index;
            activeRows.push({ ...item });
          }
        });
      } else {
        return [];
      }
    }

    return activeRows;
  };

  const onValueChangedQuestion = (RowIndex, pClaAttribute, newValue, pAttributeOpciones) => {
    const newAttributeList = [...AttributeList];    
    newAttributeList[RowIndex].RespuestaAttribute = newValue === "" || newValue === undefined  || (Array.isArray(newValue) && newValue.length === 0)? null: newValue;    
    newAttributeList[RowIndex].ClaAttribute = pClaAttribute; 
    setAttributeList(newAttributeList);
    if (props.onSearch) {
      const filtersData = {
        isClearInfo: true,
        TitleFile,
        DescriptionFile
      };
      props.onSearch(filtersData);        
    }
  };

  
  const onChangeTitleFile = (e) => {
    const newErrors = {...errors};
    delete newErrors.TitleFile;
    setErrors(newErrors);
    setTitleFile(e.target.value.trim());
  }

  const onChangeDescriptionFile = (e) => {
    const newErrors = {...errors};
    delete newErrors.DescriptionFile;
    setErrors(newErrors);
    setDescriptionFile(e.target.value.trim());
  }

  return (
    <>
      <Panel header={t('common:Filters')} toggleable headerTemplate={filterHeaderTemplate}>
        <Row>
          <Col lg={4} md={4} sm={12} xs={12} className="p-3">
            <div className="p-fluid">
              <FieldDropdown
                name="ClaLibrary"
                value={ClaLibrary}
                options={OpcionesLibrary}
                optionValue="ClaLibrary"
                optionLabel="NomLibrary"
                onChange={(e) => onClaLibraryChange(props, e)}
                errors={errors}
                label={t('dms:NomLibrary')}
              />
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={12} className="text-left">  
            { ClaLibrary !== null ? (<small className=''><p><i className='info-dark-color fas fa-info-circle'></i><b> {t('dms:notSureAboutFilters')} </b></p></small>) : (<></>) }
          </Col> 
        </Row>
        <Row className='align-items-start'>
          <Col lg={4} md={4} sm={12} xs={12} className="p-3">
            <div className="p-fluid"> 
              <FieldText  
                name="TitleFile"
                label={t('dms:TitleFile')}
                value={TitleFile}
                errors={errors}
                onChange={onChangeTitleFile}
                maxLength={250}
              /> 
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={12} className="p-3">
            <div className="p-fluid"> 
              <FieldTextArea  
                name="DescriptionFile"
                label={t('dms:DescriptionFile')}
                value={DescriptionFile}
                errors={errors}
                onChange={onChangeDescriptionFile}
                maxLength={3000}
              /> 
            </div>
          </Col>
        </Row>
        <Row className="pt-2">
          <Col lg={12} md={12} sm={12} xs={12}>
            <Row className="align-items-start pb-3">
              {AttributeList.length >= 1 ? (
                showActiveRows(AttributeList).map((element, index) => {
                  return (
                    <FileLibraryByUserAttributeTipoCampo
                      ClaLibrary={ClaLibrary}
                      ClaAttribute={element.ClaLibraryAttribute}
                      onValueChangedQuestion={onValueChangedQuestion}
                      AttributeOpciones={AttributeOpciones.filter(
                        (opc) => opc.ClaLibraryAttribute === element.ClaLibraryAttribute
                      )}
                      ClaFormularioTipoCampo={element.ClaFormularioTipoCampo}
                      NomLibraryAttribute={element.NomLibraryAttribute}
                      Index={element.RowIndex}
                      RespuestaAttribute={element.RespuestaAttribute}
                      errors={errors}
                    >
                    </FileLibraryByUserAttributeTipoCampo>
                  );
                })
              ) : (
                <></>
              )}
            </Row>
          </Col>
        </Row>
      </Panel>
    </>
  );
};
export default FileLibraryFileByUserFilter;

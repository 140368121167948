/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next';   
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Row,Col, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';  
import { Column } from 'primereact/column'; 
import { config } from '../../../utils/config';
import { callApi,showSweetAlert, tracker } from '../../../utils/utils';  
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import FieldTextArea  from '../../../components/Controls/FieldTextArea.jsx';
import swal from '@sweetalert/with-react';
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon'
import moment from "moment"; 
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';

const FasesActividadesComentario = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['misActividades', 'common']); 
  const [errors, setErrors] = useState({}); 
  const [FaseActividadComentarios, setFaseActividadComentarios] = useState([]);
  const [Comentario, setComentario] = useState(null);

  useEffect(() => {    
    obtenerComentariosActividad();
  }, [props.ClaProyecto, props.ClaFase, props.ClaActividad]); 

  const obtenerComentariosActividad = () => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/GetMSWActividadFaseComentario`;  
    const paramsToService = {  
      ClaProyecto:props.ClaProyecto,
      ClaFase:props.ClaFase,
      ClaActividad:props.ClaActividad,
    };   
    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => { 
      setFaseActividadComentarios(response.data.ActividadFaseComentarios)
    });
  }

  const FechaColumnTemplate = (rowData) => {
    return (
      <>
        { (rowData.FechaComentario !== null ? moment(rowData.FechaComentario).format("MM/DD/YYYY") : null)}
      </>
    );
  }

  const onChangeComentario = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.Comentario;   
    setErrors(newErrors) 
    setComentario(e.target.value)
  }

  const valitationSchemeSave = yup.object().shape({
    Comentario:yup.string().required(t('common:RequiredField')).nullable()
  });  

  const GuardarComentario = async () => {
    const value = {
      Comentario
    }
    const validator = new YupValidator(valitationSchemeSave);
    const esValido = await validator.validate(value);
    if (!esValido){ 
      setErrors(validator.errors); 
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      return;
    }
    const urlWebServiceSave = `${config.UrlApiProject}ScoreCard/SaveMSWActividadFaseComentario`;
    const paramsService = {
      ClaProyecto:props.ClaProyecto,
      ClaFase:props.ClaFase,
      ClaActividad:props.ClaActividad,
      Comentario
    }; 
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => { 
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      tracker.trackEvent({
        type: 'FasesActividadesComentario', /* component name */
        action: 'ADD_COMMENTS' 
      })

      if(props.onClickCloseComentario){
        props.onClickCloseComentario();
      }
    });
  }

    return (
      <>      
        <Row className="pt-2">
          <Col>   
            <div className="p-fluid">
              <FieldTextArea 
                name="Comentario"
                rows={4}
                placeholder={t('misActividades:Comment')}
                value={Comentario} 
                onChange={onChangeComentario}
                errors={errors}
                maxLength={8000}
              /> 
            </div>
          </Col>  
        </Row> 
        <Row className="pt-2">
          <Col>   
            <FieldDataTable 
              value={FaseActividadComentarios} 
              header={t('misActividades:TituloGridFaseActividadNotas')}
              scrollable 
              scrollHeight="250px"
              className="p-datatable-striped"
            > 
              <Column field="NombreUsuario" columnKey="NombreUsuario" header={t('misActividades:User')} headerStyle={{ width: '120px' }}></Column> 
              <Column body={FechaColumnTemplate} header={t('misActividades:Date')} headerStyle={{ width: '90px' }} columnKey="FechaComentario"></Column> 
              <Column field="Comentario" columnKey="Comentario" header={t('misActividades:Comment')} headerStyle={{ width: '120px' }}></Column> 
            </FieldDataTable> 
          </Col> 
        </Row>
        <Row className="pt-2">
          <Col className="text-right">   
            <FieldButton label={t('Common:Save')} onClick={() => GuardarComentario()} className="success no-border pr-3 pl-3 rounded-button btn-min-width"  /> 
          </Col> 
        </Row>
      </> 
        ); 
};
export default FasesActividadesComentario;
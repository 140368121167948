import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next'; 
import PageContent from 'layouts/PageContent.jsx';
import ManageADPEmployees from './ManageADPEmployees';
import ManageADPEmployeesFilters from './ManageADPEmployeesFilters';

const ManageADPEmployeesScreen = (props) => {
  const { t } = useTranslation(['hr', 'common']);

  const [filters, setFilters] = useState(null);

  return (
    <>
      <PageContent title={t('hr:ManageADPEmployees')}>
        <div>
          <ManageADPEmployeesFilters setFilters={setFilters} />
        </div>
        <div className='mt-2'>
          <ManageADPEmployees filters={filters} />
        </div>
      </PageContent>
    </>
  )
}

export default ManageADPEmployeesScreen;

import React from 'react';

const StepsTourCostAnalysisDefault = {
    steps: [
      {
        target: '.AC-Step-1',
        content: (
          <>
            <h1>Filters Section</h1>
            Use this section to filter your data. Remember, you will need to fill in all required fields. 
            <br></br>
            <br></br>
            Do not forget to click the <b>Search Icon</b> when you are ready. 
          </>
        ),
        disableBeacon: true
      } 
    ]
  };

  
export default StepsTourCostAnalysisDefault;
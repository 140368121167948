import FieldButton from 'components/Controls/FieldButton/FieldButton';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import moment from "moment";
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { config } from 'utils/config';
import { callApi } from 'utils/utils';
import UsageTrackingByUserDialog from '../UsageTrackingByUserDialog/UsageTrackingByUserDialog';

const UsageTrackingUsersDialog = (props) => {
  const {t, i18n} = useTranslation(['it', 'common']);
  const [UsersTrackingSummary, setUsersTrackingSummary] = useState({});
  const [UsersTracking, setUsersTracking] = useState([]);  
  const [SelectedClaUsuario, setSelectedClaUsuario] = useState(null);  
  const [IsDialogVisible, setIsDialogVisible] = useState(false);  
  const [IsTrackingByUserDialogVisible, setIsTrackingByUserDialogVisible] = useState(false);

  const getUsageTrackingInfoUsers = (
    startDate,
    endDate,
    claTrackingPlataforma,
    url,
    navegador,
    screenWidth,
    screenHeight,
    sistemaOperativo,
    country,
    city
  ) => {
    const serviceURL = `${config.UrlApiProject}IT/GetITUsageTrackingInfoUsers`;       
    const paramsService = {      
      StartDate: startDate,
      EndDate: endDate,
      ClaTrackingPlataforma: claTrackingPlataforma,
      URL: url,
      Navegador: navegador,
      ScreenWidth: screenWidth,
      ScreenHeight: screenHeight,
      SistemaOperativo: sistemaOperativo,
      Country: country,
      City: city
    };  
    callApi(serviceURL, 'POST', paramsService, (response) => {         
      console.info("TRACKING: UsageTrackingUsersDialog > getUsageTrackingInfoUsers > success > response:", response);
      setUsersTrackingSummary(response.data.usersTrackingSummary);   
      setUsersTracking(response.data.usersTracking);   
    });
  };

  const getFriendlyDate = () => {
    let friendlyDate = '';
    const startDateMoment = moment(props.startDate);
    const endDateMoment =  moment(props.endDate);
    const startDate = startDateMoment.format("DD MMM YYYY");
    const endDate = endDateMoment.format("DD MMM YYYY");
    if (startDateMoment.isSame(endDate)) {
      friendlyDate = startDate;
    } else {
      friendlyDate = `${startDate} - ${endDate}`;
    }
    return friendlyDate;
  };

  const onHide = () => {
    if (props.onHide) {
      props.onHide();
    }
  };

  const onUserSessionsPress = (claUsuario) => {        
    setSelectedClaUsuario(claUsuario);
    setIsTrackingByUserDialogVisible(true);
  }

  const onHideTrackingByUserDialog = () => {
    setIsTrackingByUserDialogVisible(false);
  }

  const dialogHeaderTemplate = (
    <>
      <Row className='align-items-start'>
        <Col>
          <h1>
            {props.title}
          </h1>          
          <p>
            <b>{t('usageTracking:EventDate')}:</b> {getFriendlyDate()}&nbsp;
            <b>{t('usageTracking:Views')}:</b> {UsersTrackingSummary.TotalVisits}&nbsp;
            <b>{t('usageTracking:NumberSessions')}:</b> {UsersTrackingSummary.TotalSessions}
          </p>
        </Col>        
      </Row>
    </>
  );

  const sessionsColumnTemplate = (rowData) => {
    return (
      <>
        <FieldButton
          label={`${rowData.TotalSessions}`} 
          className="p-button-link pt-0 pb-0"
          onClick={() => {
            onUserSessionsPress(rowData.ClaUsuario)
          }}
        />        
      </>
    )
  };

  const renderText = (rowData, columnName) => {
    return (
      <>  
        <div className='overflow-ellipsis' title={rowData[columnName]}>{rowData[columnName]}</div> 
      </>
    );
  }

  useEffect(() => {                 
    setIsDialogVisible(props.isVisible);
    if (props.isVisible) {
      setUsersTrackingSummary({});
      setUsersTracking([]);     
      console.info("TRACKING: UsageTrackingUsersDialog > useEffect > props:", props); 
      getUsageTrackingInfoUsers(
        props.startDate,
        props.endDate,
        props.claTrackingPlataforma,
        props.url,
        props.navegador,
        props.screenWidth,
        props.screenHeight,
        props.sistemaOperativo,
        props.country,
        props.city        
      );
    }
  }, [
    props.isVisible,     
    props.title,
    props.startDate,     
    props.endDate,
    props.claTrackingPlataforma,
    props.url,
    props.navegador,
    props.screenWidth,
    props.screenHeight,
    props.sistemaOperativo,
    props.country,
    props.city
  ]);

  return (
    <>
      <Dialog 
        header={dialogHeaderTemplate} 
        visible={IsDialogVisible} 
        onHide={onHide} 
        breakpoints={{'75vw': '75vw', '640px': '100vw'}} 
        style={{width: '75vw'}}
      >
        <FieldDataTable
          value={UsersTracking}          
          paginator={true}
        > 
          <Column field="ClaUsuario" header={t('usageTracking:UserID')} sortable style={{ width: '270px' }} />
          <Column field="NombreCompleto" header={t('usageTracking:UserName')} sortable style={{ width: '270px' }} />
          <Column field="TotalVisits" header={t('usageTracking:Views')} sortable className='text-right' style={{ width: '100px' }} />    
          <Column field="TotalSessions" header={t('usageTracking:NumberSessions')} sortable className='text-right' body={sessionsColumnTemplate} style={{ width: '100px' }} />      
          <Column sortable header={t('usageTracking:LastSeen')} headerStyle={{ width: '120px' }} body={(rowdata) => renderText(rowdata, "LastVisit")} columnKey="LastVisit" field="LastVisit"></Column> 
        </FieldDataTable>      
      </Dialog>
      <UsageTrackingByUserDialog
        claUsuario={SelectedClaUsuario}
        startDate={props.startDate}
        endDate={props.endDate}
        claTrackingPlataforma={props.claTrackingPlataforma}        
        url={props.url}
        navegador={props.navegador}
        screenWidth={props.screenWidth}
        screenHeight={props.screenHeight}
        sistemaOperativo={props.sistemaOperativo}
        country={props.country}
        city={props.city}            
        isVisible={IsTrackingByUserDialogVisible}
        onHide={onHideTrackingByUserDialog}
      >
      </UsageTrackingByUserDialog>
    </>
  )
}

export default UsageTrackingUsersDialog
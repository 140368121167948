import swal from 'sweetalert';
import notify from 'devextreme/ui/notify';
import { config } from './config'; 
import { useTranslation } from 'react-i18next'; 
import { v4 as uuidv4  } from 'uuid';   
import visitorInfo from 'visitor-info'
import { Tracker } from 'react-tracker'
import CryptoJS from 'crypto-js' 
import { typeOf } from 'mathjs';

require('./prototypes');

const tracker = new Tracker();


let totalExecs = 0;

tracker.on('*', (event, eventsHistory) =>
  { 
    onTracking(event)
  }
);
  
 const onTracking = (track) => { 
  const visitor = visitorInfo();
  let paramsService = {}; 
  let trackingInfo = getTrackingInfo();   
  
  const { location } = window;

  if(trackingInfo===null){
    fetch('https://api.ipify.org/?format=json')
    .then(response => response.json())
    .then(data => {      
      fetch(`https://json.geoiplookup.io/${data.ip}`)
      .then(response => response.json())
      .then(data => {
          console.log('geoiplookup data',data)
          trackingInfo = { 
            UUID:uuidv4(),
            Navegador:visitor.browser.name,
            NavegadorVersion:visitor.browser.version,
            SistemaOperativo:visitor.os.name,
            SistemaOperativoVersion:visitor.os.version,
            CountryCode:removeAccents(data.country_code),
            Country:removeAccents(data.country_name),
            City:removeAccents(data.city),
            Region:removeAccents(data.region),
            Latitude:data.latitude,
            Longitude:data.longitude
          }
          paramsService = {
            URL: location.pathname, // getSessionItem('url'),
            UUID:trackingInfo.UUID,
            Componente: track.type,
            Accion: track.action,
            Navegador:trackingInfo.Navegador,
            NavegadorVersion:trackingInfo.NavegadorVersion,
            SistemaOperativo:trackingInfo.SistemaOperativo,
            SistemaOperativoVersion:trackingInfo.SistemaOperativoVersion,
            Latitude:trackingInfo.Latitude,
            Longitude:trackingInfo.Longitude,
            CountryCode:trackingInfo.CountryCode,
            Country:trackingInfo.Country,
            City:trackingInfo.City,
            Region:trackingInfo.Region,
            ScreenWidth:window.screen.width,
            ScreenHeight:window.screen.height,
            ClaTrackingPlataforma:1
          }; 
          const UrlApiTracking = `${config.UrlApiProject}TrackingEventLog/TrackEvent`; 
          
          if (getSessionItem('Token', '') !== '') {
            callApi(UrlApiTracking, 'Post', paramsService, (response) => {
              setSessionData({
                trackingInfo: CryptoJS.AES.encrypt(JSON.stringify(trackingInfo), 'MCSW_app').toString()
              });
            }, null, false, false);  
          }
      });
    });
  }else{
    paramsService = {
        URL: location.pathname, // getSessionItem('url'),
        UUID:trackingInfo.UUID,
        Componente: track.type,
        Accion: track.action,
        Navegador:trackingInfo.Navegador,
        NavegadorVersion:trackingInfo.NavegadorVersion,
        SistemaOperativo:trackingInfo.SistemaOperativo,
        SistemaOperativoVersion:trackingInfo.SistemaOperativoVersion,
        Latitude:trackingInfo.Latitude,
        Longitude:trackingInfo.Longitude,
        CountryCode:trackingInfo.CountryCode,
        Country:trackingInfo.Country,
        City:trackingInfo.City,
        Region:trackingInfo.Region,
        ScreenWidth:window.screen.width,
        ScreenHeight:window.screen.height,
        ClaTrackingPlataforma:1
    }; 
    if (getSessionItem('Token', '') !== '') {
      const UrlApiTracking = `${config.UrlApiProject}TrackingEventLog/TrackEvent`; 
      callApi(UrlApiTracking, 'Post', paramsService, (response) => {
        console.log('tracking Ok')
      }, null, false, false);         
    }
  }
  
} 


const getTrackingInfo = () => {
  let trackingInfo = null; 
  // si esta logueado tratamos de desencriptar......
  if (typeof(getSessionItem('trackingInfo')) !== 'undefined') {
    try { 
      // tratamos de desencriptar, si no podemos quiere decir que el usuario se logueo antes de la funcionalidad de encriptacion o no esta logueado
      const bytes  = CryptoJS.AES.decrypt(getSessionItem('trackingInfo'), 'MCSW_app');
      trackingInfo = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));  
    } catch (error) {
      // 
    } 
  } 
  return trackingInfo;
}

/**
 * showLoad()
 * Muestra la animacion de espera de carga de la pagina
 *
 */
 function showLoad() {  

  if (totalExecs === 0 || totalExecs < 0) {
      ShowLoading("mswSpinner", "#f96332");
  } 
  ++totalExecs;
}

/**
* hideLoad()
*Oculta la animacion de espera de carga de la pagina
*/
function hideLoad() {
  --totalExecs;

  if (totalExecs < 0){
    totalExecs = 0;
  }

  if (totalExecs === 0) {
      HideLoading();
  }
}


/**
 * Función para mostrar spinner en medio de la pantalla
 * @param {string} spinner clases de font awesome que se va a mostrar como spinner
 * @param {string} color color que se le quiera dar al spinner
 */
 function ShowLoading(spinner, color) {
  if (!color) {
      color = "#000000";
  }

  if (!spinner) {
      spinner = "fa fa-spinner";
  }

  const divParent = document.createElement("div");
  const divInner = document.createElement("div");
  const iChild = document.createElement("div");

  divParent.id = "dialogLoading";
  divParent.classList.add("loader-parent");
  divInner.style.color = color;
  divInner.classList.add("loader-content");

  spinner.split(" ").forEach((item) => {
      iChild.classList.add(item);
  });

  // iChild.classList.add(spinner);
  iChild.classList.add("fa-pulse");
  iChild.classList.add("fa-2x");
  iChild.classList.add("fa-fw");

  divInner.append(iChild);
  divParent.append(divInner);

  document.getElementsByTagName("body")[0].append(divParent);
}

/**
* Función para ocultar spinner
*/
function HideLoading() {
  try{ 
    const divParent = document.getElementById("dialogLoading");
    document.getElementsByTagName("body")[0].removeChild(divParent);
  }
  catch(e){
    // 
  }
}



/**
 * Realiza una peticion a una URL especificada.
 *
 * @param {String} url Dirección donde se realizara la peticioón
 * @param {String} method Tipo de peticion a ejecutar (POST, GET, PUT, DELETE)
 * @param {JSON} [data={}] Objeto que se adjuntará al body de la petición
 * @returns
 */
async function callApi(url, method, data = {}, callBack, onCalbbackError, multipart = false, showOnLoad = true) {
  method = method.toUpperCase(); 
  const headers = {
    'x-api-key': config.ApiKey,
    'x-access-token': getSessionItem('Token', ''),
    ...(!multipart && {
      'Content-Type': 'application/json',
    }),
  };
 
  /*
  if (config.DebuggingMode) { 
    console.log(`Url: ${url}`);
  }
  */

  try { 
    if (showOnLoad){ 
      showLoad();
    }
 
    const response = await fetch(url, {
      method,
      headers,
      body: method === 'GET' ? null : !multipart ? JSON.stringify( data ) : data,
      dataType: !multipart ? 'json' : null,
    });
     
    const res = await response.json();
     
    if (response.status === 200) {
      
      if (config.DebuggingMode) {
        console.log(`Url: ${url}, Response: `, res);
      }
      
      // si regreso error response....
      if (res.HasError)
      {
        if (res.IsControlledException){
          swal('Warning', res.message, 'warning', {
            buttons: {
              confirm: {
                text: 'OK',
                className: 'animation-on-hover btn btn-success',
              },
            },
          });
          if (showOnLoad){   
            hideLoad();
          }

          if (typeof(onCalbbackError)==='function'){
            onCalbbackError();
          }
          return;
        }
        // error no controlado por el api
        
          swal('Error', res.message, 'error', {
            buttons: {
              confirm: {
                text: 'OK',
                className: 'animation-on-hover btn btn-success',
              },
            },
          });

          if (showOnLoad){ 
           hideLoad();
          }
          return;
        
      }


      callBack(res);

      if (showOnLoad){ 
       hideLoad();
      }
    } else { 
      if (showOnLoad){ 
       hideLoad();
      }

      
        // console.log('res', res);

        const errX = new Error();
				errX.message = res.message ?? res.mensaje;
				errX.name = response.status;
				errX.description = res.message;
				errX.status = response.status;

				throw errX;

      // throw Error(`${response.message}`);
    }
  } catch (err) { 

    if (typeof(err.message) === 'undefined'){
      swal('Error', 'Bad Request petition or unhandled error, please check with IT Team.', 'error', {
        buttons: {
          confirm: {
            text: 'OK',
            className: 'animation-on-hover btn btn-success',
          },
        },
      });
    }
    
    if (typeof(onCalbbackError)==='function'){
      onCalbbackError();
      hideLoad();
      return 
    }

    if (showOnLoad){ 
     hideLoad();
    } 
     

    // si atrapamos un error y es un error por problemas de vencimiento de token, redireccionamos  
    if (typeof(err.message) === 'string'){
      if (err.message.indexOf('System.IdentityModel.Tokens.Jwt.JwtSecurityToken') !== -1
      ||  err.message.indexOf('Internal Server Error. IDX10000: The parameter') !== -1
      )
      {
        // 
        swal('', "Your sesion has ended.", 'info', {
          buttons: {
            confirm: {
              text: 'OK',
              className: 'animation-on-hover btn btn-success',
            },
          },
        }).then((value) => {
          logOut();
          location.reload();
        }); 
      }
      else
      {
        swal('Error', err.message, 'error', {
            buttons: {
              confirm: {
                text: 'OK',
                className: 'animation-on-hover btn btn-success',
              },
            },
          }); 
      }
    }
    else {
        swal('Error', err.message, 'error', {
        buttons: {
          confirm: {
            text: 'OK',
            className: 'animation-on-hover btn btn-success',
          },
        },
      });
    } 
  }
}


/**
 * Función para realizar la petición a Kraken por medio de su servicio en Node js
 * @param {Number} claProducto número de el producto, dueño de la entidad
 * @param {Number} idEntidad número de la entidad que se desea consultar
 * @param {Object} paramsSP parametros que necesita la entidad
 * @param {Object} tipoEstructura número
 * @param {Function} callback función de callback para manejo del resultado
 */
async function callKrakenApi(claProducto, idEntidad, paramsSP, tipoEstructura, callback) {
  const method = 'POST';

  const params = {
    parameters: JSON.stringify(paramsSP),
    tipoEstructura,
  };

  const url = `${config.KrakenService}/${claProducto}/${idEntidad}`;

  await callApi(url, method, params, callback);
}

/**
 * Guarda en el localStorage cualquier valor que se mande en un objeto
 *
 * @param {object} params objeto donde cada propiedad se va a guardar
 */
function setSessionData(params) {
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const element = params[key];

      if (typeof element === 'object') {
        localStorage.setItem(key, JSON.stringify(element));
      } else {
        localStorage.setItem(key, element);
      }
    }
  }
}
 
/**
 * Regresa del localStorage cualquier valor que se pase como primer parametro,
 * en caso de no encontrarlo, regresa el valor por default del segundo parametro
 *
 * @param {string} key
 * @param {any} def
 */
function getSessionItem(key, def) {
  let value;

  try {
    value = JSON.parse(localStorage.getItem(key));
  } catch (error) {
    value = localStorage.getItem(key);
  }

  if (!value) {
    return def;
  }
  return value;
}

const removeAccents = (str) => {
  if (str===undefined){
    return '';
  }

  if(str.normalize!==undefined&&typeOf(str.normalize)==='function'){
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }
} 

/**
 * Regresa del sessionStorage el NumUsuario
 *
 * @returns {Number} NumUsuario
 */
function getCliente() {
  return localStorage.getItem('NumUsuario');
}

/**
 * Remueve del localStorage el JWT token y el nombre del usuario
 *
 */
function logOut() {
  // localStorage.clear();
  // remueve todo excepto device-uuid
  const values = [];
      const keys = Object.keys(localStorage);
      let i = keys.length;

  while ( i-- ) {
      if ( keys[i] !== 'device-uuid'
        && keys[i] !== 'ClaAreaDepartamento'
        && keys[i] !== 'ClaCelda'
        && keys[i] !== 'IsDeviceAssignedOnCell'
      ){
        localStorage.removeItem(keys[i])
      } 
  } 

}

/**
 * Hace el decode del JWT token
 *
 * @param {string} token
 * @returns {object} payload del token
 */
function decodeToken(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

/**
 * Valida la si existe un token guardado en localStorage y en caso de existir
 * se valida su expiracion
 *
 * @returns {boolean} retorna si el token es valido o no
 */
function sessionAlive() {
  const jwtToken = localStorage.getItem('Token');
  let resp = false;

  if (jwtToken) {
    const { exp } = decodeToken(jwtToken);

    if (Date.now() < exp * 1000) {
      resp = true;
    }
  }

  if (!resp) {
    logOut();
  }

  return resp;
}


/**
 * Tipo de la notificacion de DevExtreme
 */
const notifyType = {
  success: 'success',
  error: 'error',
  info: 'info',
  warning: 'warning',
};

/**
 * Posicion la notificacion de DevExtreme
 * 'bottom' | 'center' | 'left' | 'left bottom' | 'left top' | 'right' | 'right bottom' | 'right top' | 'top'
 */
const notifyPosition = {
  centerBottom: 'center bottom',
  centerTop: 'center top',
  rightBottom: 'right bottom',
  rightTop: 'right top',
};

/**
 * Manda Un mensaje Generico con el Notify de DevExtreme
 *
 * @param {string} message mensaje de la alerta
 * @param {notifyPosition} notifyPosition posicion de la alerta
 * @param {notifyType} notifyType tipo de notificacion
 */
function showNotify(message, notifyPosition, notifyType) {
  notify(
    {
      message,
      position: {
        my: notifyPosition,
        at: notifyPosition,
      },
      width: '400px',
      closeOnSwipe: true,
      zindex: 99999999
    },
    notifyType,
    3000
  );
}

function showSweetAlert(title, message, notifyType) {
  
  if (title===null){
    switch(notifyType){
      case 'success':
        title = 'Success';
      break;
      case 'error':
        title = 'Error';
      break;
      case 'info':
        title = 'Information';
      break;
      case 'warning':
        title = 'Warning';
      break;
      
      default:
      break;
    }
    
  }
  
  if (notifyType === 'success' || notifyType === 'warning'){
    showNotify(message, 'centerTop', notifyType);  
  }
  else{ 
    swal(title,  message, notifyType, {
      buttons: {
        confirm: {
          text: 'OK',
          className: 'animation-on-hover btn btn-success',
        },
      },
    });
  }  
}


function  getDeviceUniqueID ()  { 
  // primero tratamos de obtener el device-uuid de la sesion y si existe usamos ese si no generamos uno nuevo
  // y lo guardamos en sesion, esto no va a segurar que este sea el del usuario por siempre...
  let deviceUniqueID = getSessionItem('device-uuid', null);   

  if (deviceUniqueID === null){
    deviceUniqueID = uuidv4();
    setSessionData({
      "device-uuid": deviceUniqueID,
    })
  } 

  return deviceUniqueID;
}

function formatDate(date) {
  return new Date(date).toLocaleDateString(
    'en-us',
    {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    }
  )
}

// Only works for North American phone numbers
function formatPhoneNumberNA(phoneNumber) {
  return `${phoneNumber.replace(phoneNumber.slice(-10), '')} ${phoneNumber.slice(-10).replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")}`
}

function scrollToTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' });  
}

function scrollToBottom() {  
  window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
}

export {
  callApi,
  callKrakenApi,
  setSessionData,
  getSessionItem,
  getCliente,
  logOut,
  decodeToken,
  sessionAlive,
  notifyType,
  notifyPosition,
  showNotify,
  showSweetAlert,
  showLoad,
  hideLoad,
  getDeviceUniqueID,
  formatDate,
  formatPhoneNumberNA,
  tracker,
  scrollToTop,
  scrollToBottom
};

/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next'; 
import PageContent from '../../../layouts/PageContent'; 
import { Toolbar } from 'primereact/toolbar'; 
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row, Col , 
    ListGroupItem,
    ListGroup,
    Progress,
    Card,
    CardHeader,
    CardBody,
    CardFooter} from 'reactstrap';   
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple'; 
import { Badge } from 'primereact/badge';
import { Link } from "react-router-dom";
import { config } from '../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../utils/utils'; 

const FeedbackCoaching = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);
   
  
  const employeeSelfReviewLnk = useRef(null); 

  const onEmployeeSelfReviewClick = () => {
    employeeSelfReviewLnk.current.click();
  } 
  
  const [claUsuarioSeleccionado, setClaUsuarioSeleccionado] = useState(props.ClaUsuario === undefined ? getSessionItem('ClaUsuarioMod', '') : props.ClaUsuario); 
  const [anio, setAnio] = useState(props.Anio ? props.Anio : null); 
  const [EstatusEmployeeSelfReview, setEstatusEmployeeSelfReview] = useState(0); 
  const [EstatusManagerReview, setEstatusManagerReview] = useState(0); 
  const [EstatusContribucionReview, setEstatusContribucionReview] = useState(0); 
  const [EstatusAnnualReview, setEstatusAnnualReview] = useState(0); 
  const [Activa180DegreeReview, setActiva180DegreeReview] = useState(0); 
  const [ActivaEmployeeSelfReview, setActivaEmployeeSelfReview] = useState(0); 
  const [ActivaManagerReview, setActivaManagerReview] = useState(0); 
  const [ActivaAnnualReview, seActivaAnnualReview] = useState(0);  


  useEffect(() => {      
    obtenerInformacionAnnualUsuario(claUsuarioSeleccionado);
  }, [props.Anio]); 

  
  const obtenerInformacionAnnualUsuario = (ClaUsuarioSeleccionado) => { 
    const GetPerformanceDevelopmentByUser = `${config.UrlApiProject}hr/GetPerformanceDevelopmentByUser?Anio=${props.Anio === null ? '' : props.Anio}&ClaUsuario=${ClaUsuarioSeleccionado}`;  
    const paramsToService = { };   
    callApi(GetPerformanceDevelopmentByUser, 'GET', paramsToService, (response) => { 

      if (response.data.PerformanceDevelopmentInfo.length > 0)
      { 
        setEstatusEmployeeSelfReview(response.data.PerformanceDevelopmentInfo[0].EstatusEmployeeSelfReview);
        setEstatusManagerReview(response.data.PerformanceDevelopmentInfo[0].EstatusManagerReview);
        setEstatusContribucionReview(response.data.PerformanceDevelopmentInfo[0].EstatusContribucionReview);
        setEstatusAnnualReview(response.data.PerformanceDevelopmentInfo[0].EstatusAnnualReview);

        
        setActiva180DegreeReview(response.data.PerformanceDevelopmentInfo[0].Activa180DegreeReview);
        setActivaEmployeeSelfReview(response.data.PerformanceDevelopmentInfo[0].ActivaEmployeeSelfReview);
        setActivaManagerReview(response.data.PerformanceDevelopmentInfo[0].ActivaManagerReview);
        seActivaAnnualReview(response.data.PerformanceDevelopmentInfo[0].ActivaAnnualReview);
      } 
       
      /*
       : Array(1) 
          Activa180DegreeReview: 1
          Anio: 2021
          Comentarios: null
          EsCompanyCultureRevisado: 0
          EsContribucionRevisado: 0
          EsEmployeeSelfReviewRevisado: 0
          EsManagerReviewRevisado: 0
          Estatus180DegreeManagerReview: 0
          Estatus180DegreeSelfReview: 0
          EstatusAnnualReview: 0
          EstatusContribucionReview: 0
          EstatusEmployeeSelfReview: 1
          EstatusManagerReview: 0
          IdPerformanceDev: 1 
      */
    });
  }


  const managerReviewLnk = useRef(null); 

  const onManagerReviewClick = () => {
    managerReviewLnk.current.click();
  } 

  return (
    <>    
      <Card> 
        <CardBody>
          <h1>{t('hr:FeedbackCoaching')}</h1>
            
          {
          /*
          <div className="w-100 pl-3 pr-3"> -->
            <FielButton label={t('hr:180Degree')} className="purple no-border w-100 rounded-button font-weight-bold" /> 
          </div>  
          <br></br>  
          */
          }
          <div className="w-100 pl-3 pr-3 relative">
            
            <FieldButton label={t('hr:EmployeeSelfReview')} className="light-blue no-border w-100 rounded-button font-weight-bold" onClick={onEmployeeSelfReviewClick} />
            <Link
              ref={employeeSelfReviewLnk} 
              to={{ pathname: "AnnualReview"
                              , search: `?Anio=${props.Anio}&ActiveStep=0` 
                              }}
            >
            </Link> 
            <div className="right-top-badge">  
              <Badge
                value={EstatusEmployeeSelfReview === 1 && ActivaEmployeeSelfReview
            ?  <i className="fa fa-check light-green-color"></i>
            : 
            ActivaEmployeeSelfReview === 1 ? 
              <i className="fa fa-unlock primary-color"></i>  
              : 
              <i className="fa fa-lock warning-color"></i>}
                className="white-badge"
              />  
            </div> 
          </div>  
          <br></br> 
          <div className="w-100 pl-3 pr-3 relative">
            <FieldButton label={t('hr:ManagerReview')} className="light-green no-border w-100 rounded-button font-weight-bold" onClick={onManagerReviewClick} /> 
            <Link
              ref={managerReviewLnk} 
              to={{ pathname: "AnnualReview"
                              , search: `?Anio=${props.Anio}&ActiveStep=2` 
                              }}
            >
            </Link>  
            <div className="right-top-badge">  
              <Badge
                value={EstatusManagerReview === 1 && ActivaManagerReview 
                  ?  <i className="fa fa-check light-green-color"></i>
                  : 
                    ActivaManagerReview === 1 ? 
                      <i className="fa fa-unlock primary-color"></i>  
                    : 
                      <i className="fa fa-lock warning-color"></i>}
                className="white-badge"
              />  
            </div> 
          </div>  
          {
            /*
         
          <br></br> 
          <div className="w-100 pl-3 pr-3">
            <FieldButton label={t('hr:ManagerAdministration')} className="orange no-border w-100 rounded-button font-weight-bold" onClick={onManagerReviewClick}> 
            </Button> 
            <div className="w-100 center-badge"> 
              <Badge value="0 of 5" className="score-badge" />
            </div>
          </div> 

          */
        }
        </CardBody>
      </Card>  
    </>  
        ); 
};

export default FeedbackCoaching;

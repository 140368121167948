/* eslint-disable no-bitwise */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  ListGroupItem,
  ListGroup,
  Progress,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from 'reactstrap';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { Badge } from 'primereact/badge';
import { Link } from 'react-router-dom';
import ActionPlanScoreSquare from './ActionPlanScoreSquare.jsx';
import { Toolbar } from 'primereact/toolbar';
import Highcharts from 'highcharts';
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert, tracker } from '../../../../utils/utils';
import FieldButton from 'components/Controls/FieldButton/FieldButton.jsx';
import { Dialog } from 'primereact/dialog';
import MinutasMeeting from '../../Projects/MinutasMeeting.jsx';

const FocusReportMonthSummary = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'focusReport', 'common']);
  const [anio, setAnio] = useState(props.Anio ? props.Anio : null);
  const [ClaPuesto, setClaPuesto] = useState(null); 
  const [ClaAnio, setClaAnio] = useState(null); 
  const [ClaMes, setClaMes] = useState(null); 
  const [AnnualResult, setAnnualResult] = useState(null);
  const [AnnualScoreCard, setAnnualScoreCard] = useState(null);
  const [AnnualActionPlan, setAnnualActionPlan] = useState(null);
  const [AnnualProjectActivities, setAnnualProjectActivities] = useState(null);
  const [Weight_Scorecard, setWeight_Scorecard] = useState(null);
  const [Score_Scorecard, setScore_Scorecard] = useState(null);
  const [Point_Scorecard, setPoint_Scorecard] = useState(null);
  const [Weight_ActionPlans, setWeight_ActionPlans] = useState(null);
  const [Score_ActionPlans, setScore_ActionPlans] = useState(null);
  const [Point_ActionPlans, setPoint_ActionPlans] = useState(null);
  const [Weight_ProjectActivities, setWeight_ProjectActivities] = useState(null);
  const [Score_ProjectActivities, setScore_ProjectActivities] = useState(null);
  const [Point_ProjectActivities, setPoint_ProjectActivities] = useState(null);
  const [FinalScore, setFinalScore] = useState(null);
  const [EsMinutaVisible, setEsMinutaVisible] = useState(false);
  const [ClaTipoMinuta, setClaTipoMinuta] = useState(null);
  const [IdMinuta, setIdMinuta] = useState(null); 
  const [ClaPeriodo, setClaPeriodo] = useState(null); 
  const [ClaFechaReporte, setClaFechaReporte] = useState(null); 
  const [IdMinutaVertical, setIdMinutaVertical] = useState(null); 
  const [IdMinutaTeam, setIdMinutaTeam] = useState(null);  

  useEffect(() => { 
    setClaPuesto(props.ClaPuesto === undefined ? 0 : props.ClaPuesto);
    setClaAnio(props.ClaAnio === undefined ? 0 : props.ClaAnio);
    setClaMes(props.ClaMes === undefined ? 0 : props.ClaMes);
    GetResultadoAnual();
  }, [props.ClaPuesto,props.ClaAnio,props.ClaMes]);

  const GetResultadoAnual = () => {
    const getResultadoAnual = `${config.UrlApiProject}ScoreCard/GetResultadoMensualPuesto`;
    const paramsToService = {
      ClaPuesto: props.ClaPuesto === undefined ? 0 : props.ClaPuesto,
      ClaAnio: props.ClaAnio === undefined ? 0 : props.ClaAnio,
      ClaMes: props.ClaMes === undefined ? 0 : props.ClaMes,
    };
    callApi(getResultadoAnual, 'POST', paramsToService, (response) => {      
      const ResultadoAnual = response.data.ResultadoAnual.length > 0 ? response.data.ResultadoAnual[0] : {}

      setWeight_Scorecard(ResultadoAnual.Weight_Scorecard);
      setScore_Scorecard(ResultadoAnual.Score_Scorecard);
      setPoint_Scorecard(ResultadoAnual.Point_Scorecard);
      setWeight_ActionPlans(ResultadoAnual.Weight_ActionPlans);
      setScore_ActionPlans(ResultadoAnual.Score_ActionPlans);
      setPoint_ActionPlans(ResultadoAnual.Point_ActionPlans);
      setWeight_ProjectActivities(ResultadoAnual.Weight_ProjectActivities);
      setScore_ProjectActivities(ResultadoAnual.Score_ProjectActivities);
      setPoint_ProjectActivities(ResultadoAnual.Point_ProjectActivities);
      setFinalScore(ResultadoAnual.FinalScore); 
      setClaPeriodo(ResultadoAnual.ClaPeriodo); 
      setClaFechaReporte(ResultadoAnual.ClaFechaReporte);
      setIdMinutaVertical(ResultadoAnual.IdMinutaVertical);
      setIdMinutaTeam(ResultadoAnual.IdMinutaTeam); 
    });
  };

   

const renderHeader = () => {
  return (
    <div>
      <Row>
        <Col lg={4} md={4} sm={12} xs={12}>
          <span>{ IdMinuta === null ? t('focusReport:NewMeeting') :  t('focusReport:EditMeeting') }</span>
        </Col>
        <Col lg={4} md={4} sm={12} xs={12} className="text-center">
          &nbsp;
        </Col>
        <Col lg={4} md={4} sm={12} xs={12} className="text-right">
          <div className="w-100 relative">
            <i className='fas fa-times fa-1x Cursor' title={t('common:Close')} onClick={onCloseMinuta}></i> 
          </div>
        </Col>
      </Row> 
    </div>
  );
};
 

const onCloseMinuta = () => { 
  setEsMinutaVisible(false);  
  GetResultadoAnual();
};

const onVerticalMeetingClick = () => { 
  setClaTipoMinuta(3); // VERTICAL MEETINGS
  setIdMinuta(IdMinutaVertical);
  setEsMinutaVisible(true); 

  tracker.trackEvent({
    type: 'FocusReportMonthSummary', /* component name */
    action: 'CLICK_VERTICAL_MEETING'
  }) 
};

const onTeamMeetingClick = () => { 
  setClaTipoMinuta(2); // TEAM MEETINGS
  setIdMinuta(IdMinutaTeam);
  setEsMinutaVisible(true); 

  tracker.trackEvent({
    type: 'FocusReportMonthSummary', /* component name */
    action: 'CLICK_TEAM_MEETING'
  }) 
};


const getSeverityClass = (value) => {
  let severityClass = ''; 

  let severity = '';

  const nPorcMinimo        =	70.0;
	const	nPorcSatisfactorio =	91.0;
	const	nPorcSobreSaliente =	101.0; 
	/*
	 0   - 70    --NO CUMPLE
	 71  - 90   --MINIMO
	 91  - 100  --SATISFACTORIO
	 101 - 120 --SOBRESALIENTE
	*/ 
  
  if (value < nPorcMinimo)
  {
      severity = "red";
  }
  else if (value >= nPorcMinimo && value < nPorcSatisfactorio){
    severity = "yellow";
  } else if (value >= nPorcSatisfactorio && value < nPorcSobreSaliente) { 
    severity = "green"
  } else if (value >= nPorcSobreSaliente) {
    severity = "green";
  } else {
    severity = "";
  }    

  switch (severity) {
      case "blue":
          severityClass = "score-square-blue-gradient";
          break;
      case "green":
          severityClass = "score-square-green-gradient";
          break;
      case "yellow":
          severityClass = "score-square-yellow-gradient";
          break;
      case "red":
          severityClass = "score-square-red-gradient";
          break;        
      default:
          severityClass = "score-square-default-gradient";
          break;
  }
  
  return severityClass;
}


  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col lg={10} md={10} sm={12} xs={12}>
              <Row>
                <Col lg={2} md={2} sm={12} xs={12}></Col>
                <Col lg={2} md={2} sm={12} xs={12} className="score-square-annualinfo text-center">
                  <span className="h3 score-square-annualinfo-scorecard">{t('focusReport:ScoreCardLower')}</span>
                </Col>
                <Col lg={3} md={3} sm={12} xs={12} className="score-square-annualinfo text-center">
                  <span className="h3 score-square-annualinfo-actionplan">{t('focusReport:ActionPlan')}</span>
                  
                </Col>
                <Col lg={3} md={3} sm={12} xs={12} className="score-square-annualinfo text-center">
                   
                  <span className="h3 score-square-annualinfo-activities">{t('focusReport:ProjectActivities')}</span>
                   
                </Col>
                <Col lg={2} md={2} sm={12} xs={12} className="score-square-annualinfo text-center">
                  
                  <span className="h3">{t('focusReport:Final')}</span>
                  
                </Col>
              </Row>
              <Row>
                <Col lg={2} md={2} sm={12} xs={12} className="text-center"> 
                  <span className="h4"><b>{t('focusReport:WeightLower')}</b></span>
                </Col>
                <Col lg={2} md={2} sm={12} xs={12} className="text-center"> 
                  <span><b>{Weight_Scorecard}</b></span> 
                </Col>
                <Col lg={3} md={3} sm={12} xs={12} className="text-center"> 
                  <span><b>{Weight_ActionPlans}</b></span> 
                </Col>
                <Col lg={3} md={3} sm={12} xs={12} className="text-center"> 
                  <span><b>{Weight_ProjectActivities}</b></span>  
                </Col>
                <Col lg={2} md={2} sm={12} xs={12}></Col>
              </Row>
              <Row>
                <Col lg={2} md={2} sm={12} xs={12} className="text-center"> 
                  <span className="h4"><b>{t('focusReport:ScoreLower')}</b></span>
                </Col>
                <Col lg={2} md={2} sm={12} xs={12} className="text-center"> 
                  <span className='text-underline'><b>{Score_Scorecard}</b></span> 
                </Col>
                <Col lg={3} md={3} sm={12} xs={12} className="text-center"> 
                  <span className='text-underline'><b>{Score_ActionPlans}</b></span> 
                </Col>
                <Col lg={3} md={3} sm={12} xs={12} className="text-center"> 
                  <span className='text-underline'><b>{Score_ProjectActivities}</b></span> 
                </Col>
                <Col lg={2} md={2} sm={12} xs={12}></Col>
              </Row>
              <Row className="align-items-start ">
                <Col lg={2} md={2} sm={12} xs={12} className="text-center"> 
                  <span className="h4"><b>{t('focusReport:Points')}</b></span>
                </Col>
                <Col lg={2} md={2} sm={12} xs={12} className="text-center"> 
                  <span><b>{Point_Scorecard}</b></span> 
                </Col>
                <Col lg={3} md={3} sm={12} xs={12} className="text-center"> 
                  <span><b>{Point_ActionPlans}</b></span> 
                </Col>
                <Col lg={3} md={3} sm={12} xs={12} className="text-center"> 
                  <span><b>{Point_ProjectActivities}</b></span> 
                </Col>
                <Col lg={2} md={2} sm={12} xs={12} className="text-center"> 
                  <FieldButton 
                    className={`${getSeverityClass(FinalScore)} no-border score-square-month-result font-weight-bold score-cursor text-center`}
                  >
                    <span className="h2 w-100"><b>{Highcharts.numberFormat(FinalScore,2)}</b></span>
                  </FieldButton>  
                </Col>
              </Row>
            </Col>
            <Col lg={2} md={2} sm={12} xs={12}>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12} className="text-right">    
                  <FieldButton label={t('focusReport:Vertical')} className="primary no-border p-mr-2 rounded-button w-100" onClick={onVerticalMeetingClick} /> 
                  <div className="right-top-badge" hidden={IdMinutaVertical === null}>  
                    <Badge
                      value={IdMinutaVertical !== null ?  (<i className="fa fa-check light-green-color"></i>)
                      : 
                      (<></>)}
                      className="white-badge"
                    />  
                  </div> 
                </Col>  
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12} className="text-right">    
                  <FieldButton label={t('focusReport:Team')} className="light-green no-border p-mr-2 rounded-button w-100" onClick={onTeamMeetingClick} /> 
                  <div className="right-top-badge" hidden={IdMinutaTeam === null}>  
                    <Badge
                      value={IdMinutaTeam !== null ?  (<i className="fa fa-check light-green-color"></i>)
                      : 
                      (<></>)}
                      className="white-badge"
                    />  
                  </div> 
                </Col>  
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Dialog
        visible={EsMinutaVisible}
        modal  
        header={renderHeader} 
        closable={false} 
        maximized={true}
        draggable={false} 
        blockScroll={true}
      >
        <MinutasMeeting ClaTipoMinuta={ClaTipoMinuta} IsHideBackButton={true} ClaPuesto={ClaPuesto} ClaPeriodo={ClaPeriodo} ClaFechaReporte={ClaFechaReporte} IdMinuta={IdMinuta} onCloseDetailView={onCloseMinuta}></MinutasMeeting>
      </Dialog>
    </>
  );
};

export default FocusReportMonthSummary;

import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Label } from 'reactstrap';
import { callApi } from "utils/utils";
import { config } from 'utils/config';
import FieldAutoComplete from 'components/Controls/FieldAutoComplete';
import { Panel } from 'primereact/panel'
import FieldButton from 'components/Controls/FieldButton/FieldButton';

const ToolPartsMappingFilters = (props) => {
  const {t, i18n} = useTranslation(['it', 'common']); // Translation
  const [articulo, setArticulo] = useState([]); // Selected articulo
  const [articulos, setArticulos] = useState([]); // Articulos list for autocomplete
  const [errors, setErrors] = useState([]); // Errors

  // Set the filters when an articulo is selected
  useEffect(() => {
    const newFilters = {
      articulo
    }

    if (articulo.ClaArticulo3 !== undefined && articulo.ClaArticulo3 !== null) {
      props.onFiltersChange(newFilters)
    }
  }, [articulo])

  // Filter for the autocomplete
  const filterArticulo = (e) => {
    let valor = '';
    if (articulo !== null && articulo.NomArticulo3 !== null && articulo.NomArticulo3 !== '' &&  e.query === '') {
    valor = articulo.NomArticulo3;
    }
    else {
    valor = e.query;
    }

    const paramsService = {};
    const urlWebService = `${config.UrlApiProject}Operations/GetArticulo3ToolParts?Valor=${valor}`;
    callApi(urlWebService, 'GET', paramsService, (response) => {
      setArticulos(response.data.Articulos);
    });    
  }

  // On articulo filter change
  const onArticuloChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.articulo;
    setErrors(newErrors);

    let art = '';

    if (e.value !== null) {
      art = e.value;
    }

    setArticulo(art);
  }

  // Panel header
  const headerTemplate = (options) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down text-white' : 'pi pi-chevron-up text-white';
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className="card-header">
        <div className="p-toolbar p-component mt-1 mb-1">
          <div className="p-toolbar-group-left">
            <div className="card-title">
              <FieldButton className={options.togglerClassName} onClick={options.onTogglerClick}>
                <span className={toggleIcon}></span> 
              </FieldButton>
              <span className={titleClassName}>
                {t('common:Filters')}
              </span>
            </div> 
          </div>
        </div> 
      </div>
    )
  }

  return (
    <>
      <Panel header={t('common:Filters')} toggleable headerTemplate={headerTemplate}>
        <Row className='p-fluid p-grid p-formgroup-inline align-items-end'>
          <Col xs={12} sm={12} md={8} lg={6} className='p-field'>
            <Label>{t('operations:ToolPart')}</Label>
            <FieldAutoComplete
              dropdown
              value={articulo}
              onChange={onArticuloChange}
              suggestions={articulos}
              field="NomArticulo3"
              completeMethod={filterArticulo}
              errors={errors}
            /> 
          </Col>
        </Row>
      </Panel>
    </>
  )
}

export default ToolPartsMappingFilters;
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next';  
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row,Col, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';   
import { config } from '../../../utils/config';
import { callApi,showSweetAlert} from '../../../utils/utils.js';
import { ProgressBar } from 'primereact/progressbar';  
import FieldText  from '../../../components/Controls/FieldText.jsx' 
import FieldCalendar  from '../../../components/Controls/FieldCalendar.jsx' 
import Highcharts from 'highcharts'; 
import HighchartsMore from "highcharts/highcharts-more.js";
import HighchartsReact from 'highcharts-react-official';
import HighchartsSolidGauge from "highcharts/modules/solid-gauge";
import moment from "moment"; 

HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);
const ProyectoDetalle = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);
  const [errors, setErrors] = useState({}); 
  const [ClaProyecto, setClaProyecto] = useState(props.ClaProyecto === undefined ? 0 : props.ClaProyecto); 
  const [Informacion, setInformacion] = useState(0);
  const [Fases, setFases] = useState(0);
  const [Actividades, setActividades] = useState(0);
  const [KPI, setKPI] = useState(0);
  const [WeightFases, setWeightFases] = useState(0);
  const [ProyectoPorc, setProyectoPorc] = useState(0);
  const [NomProyecto, setNomProyecto] = useState(null);
  const [ActividadesAbiertas, setActividadesAbiertas] = useState(0);
  const [ActividadesTotal, setActividadesTotal] = useState(0);
  const [ActividadesRetrasadas, setActividadesRetrasadas] = useState(0);
  const [TotalInvolucrados, setTotalInvolucrados] = useState(0);
  const [TotalDiasProyecto, setTotalDiasProyecto] = useState(0); 
  const [TotalDiasRestantes, setTotalDiasRestantes] = useState(0); 
  const [FechaInicioProyecto, setFechaInicioProyecto] = useState(null);
  const [FechaEstimadaFin, setFechaEstimadaFin] = useState(null);
  const [PorcActividades, setPorcActividades] = useState(0); 
  const [PorcentajeAvanceReal, setPorcentajeAvanceReal] = useState(0); 
  const [PorcentajeAvenceEstimado, setPorcentajeAvenceEstimado] = useState(0); 
  const [PorcentajeFaltanteReal, setPorcentajeFaltanteReal] = useState(0); 
  const [PorcentajeFaltanteEstimado, setPorcentajeFaltanteEstimado] = useState(0); 
  const [inplaceActive, setInplaceActive] = useState(false);
  const [InplaceActiveCalendar, setInplaceActiveCalendar] = useState(false);
  const [NomProyectoEstatus, setNomProyectoEstatus] = useState(null);
  const [ClaEstatusProyecto, setClaEstatusProyecto] = useState(null);
  const [RangoFecha, setRangoFecha] = useState([]);
  const [fechaInicial, setFechaInicial] = useState(null);
  const [fechaFinal, setFechaFinal] = useState(null);
  const [EsTieneNotificacionActiva, setEsTieneNotificacionActiva] = useState(null);
  const [FechaNotificaciones, setFechaNotificaciones] = useState(null);
  const [EsProyectoActivo, setEsProyectoActivo] = useState(false);
  const [ClaTipoProyecto, setClaTipoProyecto] = useState(null);
  const [ProyectosRelacionads, setProyectosRelacionads] = useState(0);
  const [PesoProyectos, setPesoProyectos] = useState(0);
  const [EsProjectManager, setEsProjectManager] = useState(false);
  const [EsAdminBSC, setEsAdminBSC] = useState(false);
  const [ReviewTeamMember, setReviewTeamMember] = useState(0);

  useEffect(() => {    
    obtenerInformacionFasesProyecto(null,null);
  }, [props.refrescarInformacion]); 

  const options = {
    chart: {
        type: 'solidgauge',
        margin: [10, 0, 10, 0],
        spacingTop: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        spacingRight: 0,
        backgroundColor: 'transparent',
        height: `60%`
      },
      pane: {
        startAngle: 0,
        endAngle: 360,
        background: [
            {
            outerRadius: '110%',
            innerRadius: '90%',
            borderWidth: 0,
            backgroundColor: Highcharts.color('#519CFE')
            .setOpacity(0.3)
            .get(),
            }
        ],
        center: ['30%', '48%']
      },
      title: {
        text: ''
    },
      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: []
      },
      legend: {
        align: 'center',
        verticalAlign: 'top',
        layout: 'vertical',
        x:50,
        y:-5

    },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: true,
            verticalAlign: 'middle'
          }, 
          showInLegend: true,
          linecap: 'round',
          stickyTracking: false,
          colorByPoint: false
        }
      },
      series: [
          {
            name: t('scoreCard:PorcEstimado'),
            showInLegend: true,
            data: [
                {
                color: '#519CFE',
                radius: '110%',
                innerRadius: '90%',
                y: PorcentajeAvenceEstimado,
                dataLabels: {
                    y: 30,
                    format: '{y}%',
                    borderWidth: 0,
                    style: {
                        fontSize: '11px',
                        color: '#519CFE',
                    }
                    }
                }
            ]
        },
        {
            name: t('scoreCard:PorcReal'),
            showInLegend: true,
            data: [
                {
                color: '#3668A9',
                radius: '110%',
                innerRadius: '100%',
                y: PorcentajeAvanceReal,
                dataLabels: {
                    y: 0,
                    format: '{y}%',
                    borderWidth: 0,
                    style: {
                        fontSize: '18px',
                        color: '#3668A9',
                    }
                    }
                }
            ]
        }
    ]
  };  
 
  const obtenerInformacionFasesProyecto = (FechaInicio, FechaFin) => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/GetInfoProyecto`;  
    const paramsToService = { 
      ClaProyecto,
      FechaEstimadaInicio:FechaInicio,
      FechaEstimadaFin:FechaFin
    };   
    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => {
      const dataInfo =  response.data.ProyectoDetalle[0];
      const dataInfoActiva =  response.data.ProyectoActivoDetalle[0];
      if (dataInfo !== undefined)
      {  
        setClaTipoProyecto(dataInfo.ClaTipoProyecto);
        setInformacion(dataInfo.Informacion);
        setFases(dataInfo.Fases);
        setActividades(dataInfo.Actividades);
        setKPI(dataInfo.KPI);
        setWeightFases(dataInfo.WeightFases);
        setProyectoPorc(dataInfo.ProyectoPorcentaje); 
        setNomProyecto(dataInfoActiva.NomProyecto);
        setActividadesAbiertas(dataInfoActiva.ActividadesAbiertas);
        setActividadesTotal(dataInfoActiva.TotalActividades);
        setActividadesRetrasadas(dataInfoActiva.TotalActividadesRetrasadas);
        setTotalInvolucrados(dataInfoActiva.TotalInvolucrados);
        setTotalDiasProyecto(dataInfoActiva.TotalDiasProyecto); 
        setTotalDiasRestantes(dataInfoActiva.TotalDiasRestantes);
        setPorcActividades(dataInfoActiva.PorcActividades);
        setPorcentajeAvanceReal(dataInfoActiva.PorcentajeAvanceReal);
        setPorcentajeAvenceEstimado(dataInfoActiva.PorcentajeAvenceEstimado);
        setPorcentajeFaltanteReal(dataInfoActiva.PorcFaltanteReal);
        setPorcentajeFaltanteEstimado(dataInfoActiva.PorcFaltanteEstimado);
        setFechaInicioProyecto((dataInfoActiva.FechaInicioProyecto!== null ? (new Date(dataInfoActiva.FechaInicioProyecto)): null));
        setFechaEstimadaFin((dataInfoActiva.FechaEstimadaFin!== null ? (new Date(dataInfoActiva.FechaEstimadaFin)): null));
        setFechaInicial(new Date(dataInfoActiva.FechaEstimadaInicioProgress));
        setFechaFinal(new Date(dataInfoActiva.FechaEstimadaFinProgress));
        setNomProyectoEstatus(dataInfoActiva.NomProyectoEstatus);
        setClaEstatusProyecto(dataInfoActiva.ClaEstatusProyecto);
        setFechaNotificaciones(dataInfoActiva.FechaNotificaciones);
        setEsTieneNotificacionActiva(dataInfoActiva.EsTieneNotificacionActiva);
        setPesoProyectos(dataInfo.PesoProyectos);
        setProyectosRelacionads(dataInfo.ProyectosRelacionads);
        setEsProjectManager(dataInfoActiva.EsProjectManager === 1);
        setEsAdminBSC(dataInfoActiva.EsAdminBSC === 1);
        setReviewTeamMember(dataInfo.ReviewTeamMember);
        if(dataInfo.ProyectoPorcentaje===100){
            setEsProyectoActivo(true);
        }
      }  
    });
  } 

  const onNomProyectoChange = (value) => {  
    setNomProyecto(value);
  }

  const onBlurNomProyectoChange = (value) => {  
    const urlWebServiceSave = `${config.UrlApiProject}ScoreCard/SaveCaratulaProyectoNomProyecto`;  
    const paramsService = {
        NomProyecto : value,
        ClaProyecto
      };
      callApi(urlWebServiceSave, 'POST', paramsService, (response) => {  
        setInplaceActive(false);
     },null, false,false);  
  }

  const onFechaInicialChange = ( e ) => {  
    setFechaInicial(e.value);
    if(fechaFinal!==null && e.value!==null){ 
        setInplaceActiveCalendar(false);
        obtenerInformacionFasesProyecto(e.value,fechaFinal);
    }
  }; 
  const onFechaFinalChange = ( e ) => {  
    setFechaFinal(e.value);
    if(fechaInicial!==null && e.value!==null){ 
        setInplaceActiveCalendar(false);
        obtenerInformacionFasesProyecto(fechaInicial,e.value);
    }
  }; 

  
const onStartNotificacion = async () => { 
   
  const StartNotificationProject = `${config.UrlApiProject}ScoreCard/StartNotificationProject?ClaProyecto=${ClaProyecto}`;
    
  const paramsServiceOwners = { 
    ClaProyecto, 
  }; 
  
  callApi(StartNotificationProject, 'POST', paramsServiceOwners, (response) => {  
    showSweetAlert('success', t('common:SuccessOperation'), 'success'); 
    obtenerInformacionFasesProyecto(null,null);
 });  
}

const onStopNotificacion = async () => { 
   
  const StopNotificationProject = `${config.UrlApiProject}ScoreCard/StopNotificationProject?ClaProyecto=${ClaProyecto}`;
    
  const paramsServiceOwners = { 
    ClaProyecto, 
  }; 
  
  callApi(StopNotificationProject, 'POST', paramsServiceOwners, (response) => {  
    showSweetAlert('success', t('common:SuccessOperation'), 'success'); 
    obtenerInformacionFasesProyecto(null,null);
 });  
}

    return (
      <>     
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
            <Inplace
              closable={false}
              active={inplaceActive}
              onToggle={(e) => setInplaceActive(e.value)}
              className="p-fluid"
            >
              <InplaceDisplay>
                <span className="fa fa-pencil-alt"></span> <b className='h1'>{NomProyecto}</b>
              </InplaceDisplay>
              <InplaceContent>
                {
                  props.readOnly === true ? (<><span className="fa fa-pencil-alt"></span> <b className='h1'>{NomProyecto}</b></>)
                  :
                  (
                    <FieldText
                      value={NomProyecto} 
                      onBlur={(e) => onBlurNomProyectoChange(e.target.value)} 
                      onChange={(e) => onNomProyectoChange(e.target.value)} 
                      autoFocus
                      maxLength={250}
                    />
                  )
                }
                 
              </InplaceContent>
            </Inplace> 
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={8} sm={12} xs={12}>
            <div className="p-fluid" hidden={!EsProyectoActivo}>
              <Inplace
                closable={true}
                active={false}
                onToggle={(e) => setInplaceActiveCalendar(e.value)}
                className="p-fluid"
              >
                <InplaceDisplay>
                  {
                       // <span className="fa fa-pencil-alt"></span>
                      } 
                  <b>{t('scoreCard:InformationFrom')}</b> &nbsp;
                  <span className="pi pi-calendar"></span> &nbsp;
                  {moment(fechaInicial).format("ll")}-{moment(fechaFinal).format("ll")} 
                </InplaceDisplay>
                <InplaceContent>
                  <b>{t('scoreCard:InformationFrom')}</b> &nbsp;
                  <FieldCalendar
                    name="FechaInicial" 
                    value={(fechaInicial!== null ? (new Date(fechaInicial)): null)}
                    onChange={(e) => onFechaInicialChange(e)}
                  />
                          &nbsp;
                  <FieldCalendar
                    name="FechaFinal" 
                    value={(fechaFinal!== null ? (new Date(fechaFinal)): null)}
                    onChange={(e) => onFechaFinalChange(e)}
                  />
                </InplaceContent>
              </Inplace> 
            </div>
          </Col> 
          <Col className="text-right" lg={6} md={4} sm={12} xs={12}>
            <div className="p-fluid">
              <b>{t('scoreCard:NotificationStatus')}</b>  
              &nbsp;
              <span className="pi pi-calendar"></span>  &nbsp;
              { EsTieneNotificacionActiva === 1 ? "Active" : "Inactive" } &nbsp;
              {
                FechaNotificaciones!= null ? (
                  <> {t('scoreCard:Since')} &nbsp;{moment(FechaNotificaciones).format("ll")}
                  </>
                ):(<></>)
                }
             
            </div> 
            
            {
                  props.readOnly === true ? (<></>)
                  :
                  (
                    <>
                      {
                      EsAdminBSC ? (
                        <>{EsTieneNotificacionActiva === 0 ? 
                        (<FieldButton label="Start Project Notifications" icon="fas fa-play-circle" className="p-button-success p-mr-2 no-border rounded-button" onClick={onStartNotificacion} /> ) 
                      : (<FieldButton label="Stop Project Notifications" icon="fas fa-stop-circle" className="p-button-danger p-mr-2 no-border rounded-button" onClick={onStopNotificacion} />)}
                        </>
                        ) : (<></>)
                    }
                    </>
                  )
            }
          </Col>  
        </Row>

        {
        EsProyectoActivo ? (
          <> 
            <Row className="pt-3">  
              <Col lg={3} md={3} sm={12} xs={12}>
                <Row className="align-items-start">
                  <Col>
                    <div className="p-grid"> 
                      <div className="p-col-12 col-lg-2 col-md-4 col-sm-4 text-center"> 
                        <div className="containerChart">
                          <HighchartsReact highcharts={Highcharts} options={options} />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={9} md={9} sm={12} xs={12}>
                <Row className="align-items-start">
                  <Col>
                    <div className="p-grid"> 
                      <div className="p-col-12 col-lg-2 col-md-4 col-sm-4"> 
                        <Card className="cardh" hidden={(ClaTipoProyecto === 4 || ClaTipoProyecto === 5 || ClaTipoProyecto === 6)}>
                          <CardHeader className="azul-fuerte">
                            <CardTitle className="text-center">{t('scoreCard:Open')} </CardTitle>
                          </CardHeader>    
                          <CardBody>   
                            <Row>
                              <Col className="text-center">
                                <h2>{ActividadesAbiertas} {t('scoreCard:Out')}  {ActividadesTotal}</h2>
                              </Col>
                            </Row> 
                          </CardBody>
                          <CardFooter>
                            <Row>
                              <Col className="text-center">
                               &nbsp;
                              </Col>
                            </Row> 
                          </CardFooter>
                        </Card>  
                      </div> 
                      <div className="p-col-12 col-lg-2 col-md-4 col-sm-4"> 
                        <Card className="cardh" hidden={(ClaTipoProyecto === 4 || ClaTipoProyecto === 5 || ClaTipoProyecto === 6)}>
                          <CardHeader className="azul-fuerte">
                            <CardTitle className="text-center">{t('scoreCard:Delayed')}  </CardTitle>
                          </CardHeader>    
                          <CardBody>   
                            <Row>
                              <Col className="text-center">
                                <h2>{ActividadesRetrasadas}</h2>
                              </Col>
                            </Row> 
                          </CardBody> 
                          <CardFooter>
                            <Row>
                              <Col className="text-center">
                               &nbsp;
                              </Col>
                            </Row> 
                          </CardFooter>
                        </Card>
                      </div> 
                      <div className="p-col-12 col-lg-2 col-md-4 col-sm-4"> 
                        <Card className="cardh">
                          <CardHeader className="sky-blue">
                            <CardTitle className="text-center">{t('scoreCard:TotalMembers')} </CardTitle>
                          </CardHeader>    
                          <CardBody>   
                            <Row>
                              <Col className="text-center">
                                <h2>{TotalInvolucrados}</h2>
                              </Col>
                            </Row> 
                          </CardBody>  
                          <CardFooter>
                            <Row>
                              <Col className="text-center">
                               &nbsp;
                              </Col>
                            </Row> 
                          </CardFooter>
                        </Card>
                      </div> 
                      <div className="p-col-12 col-lg-2 col-md-4 col-sm-4"> 
                        <Card className="cardh">
                          <CardHeader className="sky-blue">
                            <CardTitle className="text-center">{t('scoreCard:ProjectStatus')} </CardTitle>
                          </CardHeader>    
                          <CardBody>   
                            <Row>
                              <Col className="text-center">
                                <h2><span className={`${ ClaEstatusProyecto  === 3 ? "danger-color" : ''}`}>  <i className="fa fa-bookmark"></i> &nbsp; {NomProyectoEstatus} </span></h2>
                              </Col>
                            </Row> 
                          </CardBody>  
                          <CardFooter>
                            <Row>
                              <Col className="text-center">
                               &nbsp;
                              </Col>
                            </Row> 
                          </CardFooter>
                        </Card>
                      </div> 
                      <div className="p-col-12 col-lg-2 col-md-4 col-sm-4"> 
                        <Card className="cardh">
                          <CardHeader className="hard-blue">
                            <CardTitle className="text-center">{t('scoreCard:LaunchDate')} </CardTitle>
                          </CardHeader>    
                          <CardBody>   
                            <Row>
                              <Col className="text-center">
                                <h2> {TotalDiasProyecto} {t('scoreCard:Days')}  </h2>
                              </Col>
                            </Row> 
                          </CardBody>  
                          <CardFooter>
                            <Row>
                              <Col className="text-center">
                                <small> <i className="pi pi-calendar"></i> &nbsp; 
                                  {moment(FechaInicioProyecto).format("ll")} 
                                </small>
                              </Col>
                            </Row> 
                          </CardFooter>
                        </Card> 
                      </div> 
                      <div className="p-col-12 col-lg-2 col-md-4 col-sm-4">
                        <Card className="cardh">
                          <CardHeader className="hard-blue">
                            <CardTitle className="text-center"> {t('scoreCard:EstimatedDateEnd')}</CardTitle>
                          </CardHeader>    
                          <CardBody>   
                            <Row>
                              <Col className="text-center">
                                <h2> {TotalDiasRestantes} {t('scoreCard:Days')}  </h2>
                              </Col>
                            </Row>
                          </CardBody> 
                          <CardFooter>
                            <Row>
                              <Col className="text-center">
                                <small> 
                                  <i className="pi pi-calendar"></i> &nbsp; 
                                  {moment(FechaEstimadaFin).format("ll")}
                                </small>
                              </Col>
                            </Row>
                          </CardFooter>
                        </Card> 
                      </div> 
                    </div>
                  </Col>
                </Row> 
              </Col>
            </Row> 
          </>
        ):(
          <>
            <Row className="align-items-start">
              <Col lg={4} md={12} sm={12} xs={12}>
                <Row className="pb-1">
                  <Col lg={2} md={2} sm={2} xs={2}> 
                    <FieldButton icon="pi pi-check" className={`p-button-rounded ${  Informacion===0?"p-button-secondary":"p-button-success"}`} />
                  </Col>
                  <Col lg={10} md={10} sm={10} xs={10}> 
                    {t('scoreCard:1OverviewInformation')}
                  </Col>
                </Row>
              </Col>
              <Col lg={4} md={12} sm={12} xs={12}>
                <Row className="pb-1">
                  <Col lg={2} md={2} sm={2} xs={2}> 
                    <FieldButton icon="pi pi-check" className={`p-button-rounded ${  KPI===0?"p-button-secondary":"p-button-success"}`} />
                  </Col>
                  <Col lg={10} md={10} sm={10} xs={10}> 
                    {t('scoreCard:2KPIS')}
                  </Col>
                </Row> 
              </Col>
              <Col lg={4} md={12} sm={12} xs={12}>
                <Row className="pb-1" hidden={ClaTipoProyecto === 4 || ClaTipoProyecto === 5 || ClaTipoProyecto === 6}>
                  <Col lg={2} md={2} sm={2} xs={2}> 
                    <FieldButton icon="pi pi-check" className={`p-button-rounded ${  WeightFases===0?"p-button-secondary":"p-button-success"}`} />
                  </Col>
                  <Col lg={10} md={10} sm={10} xs={10}> 
                    {t('scoreCard:4Weight')}
                  </Col>
                </Row>
                <Row className="pb-1" hidden={ClaTipoProyecto !== 4 && ClaTipoProyecto !== 5 && ClaTipoProyecto !== 6}>
                  <Col lg={2} md={2} sm={2} xs={2}> 
                    <FieldButton icon="pi pi-check" className={`p-button-rounded ${  PesoProyectos===0?"p-button-secondary":"p-button-success"}`} />
                  </Col>
                  <Col lg={10} md={10} sm={10} xs={10}> 
                    {t('scoreCard:ProyectoRelacionadosPeso')}
                  </Col>
                </Row>
                
              </Col>
            </Row>
            <Row className="align-items-start">
              <Col lg={4} md={12} sm={12} xs={12}>
                <Row className="pb-1">
                  <Col lg={2} md={2} sm={2} xs={2}> 
                    <FieldButton icon="pi pi-check" className={`p-button-rounded ${  ReviewTeamMember===0?"p-button-secondary":"p-button-success"}`} />
                  </Col>
                  <Col lg={10} md={10} sm={10} xs={10}> 
                    {t('scoreCard:ReviewTeamMember')}
                  </Col>
                </Row>
              </Col>
              <Col lg={4} md={12} sm={12} xs={12}>
                <Row className="pb-1" hidden={ClaTipoProyecto === 4 || ClaTipoProyecto === 5 || ClaTipoProyecto === 6}>
                  <Col lg={2} md={2} sm={2} xs={2}> 
                    <FieldButton icon="pi pi-check" className={`p-button-rounded ${  Fases===0?"p-button-secondary":"p-button-success"}`} />
                  </Col>
                  <Col lg={10} md={10} sm={10} xs={10}> 
                    {t('scoreCard:3Phases')}
                  </Col>
                </Row>
                <Row className="pb-1" hidden={ClaTipoProyecto !== 4 && ClaTipoProyecto !== 5 && ClaTipoProyecto !== 6}>
                  <Col lg={2} md={2} sm={2} xs={2}> 
                    <FieldButton icon="pi pi-check" className={`p-button-rounded ${  ProyectosRelacionads===0?"p-button-secondary":"p-button-success"}`} />
                  </Col>
                  <Col lg={10} md={10} sm={10} xs={10}> 
                    {t('scoreCard:ProyectosRelacionados')}
                  </Col>
                </Row>
                
              </Col>
              <Col lg={4} md={12} sm={12} xs={12}>
                <Row className="pb-1" hidden={ClaTipoProyecto === 4 || ClaTipoProyecto === 5 || ClaTipoProyecto === 6}>
                  <Col lg={2} md={2} sm={2} xs={2}> 
                    <FieldButton icon="pi pi-check" className={`p-button-rounded ${  Actividades===0?"p-button-secondary":"p-button-success"}`} />
                  </Col>
                  <Col lg={10} md={10} sm={10} xs={10}> 
                    {t('scoreCard:5Activities')}
                  </Col>
                </Row> 
              </Col>
            </Row>
            <Row className="align-items-start pt-1">
              <Col>
                <div>
                  <ProgressBar className="success-color" value={ProyectoPorc}></ProgressBar>
                </div>
              </Col>
            </Row>      
          </>
        )
        }
      </> 
        ); 
};
export default ProyectoDetalle;

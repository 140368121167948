import FieldButton from 'components/Controls/FieldButton/FieldButton';
import PageContent from 'layouts/PageContent';
import { Dialog } from 'primereact/dialog';
import { React, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EnterPOD from './EnterPOD';
import ViewPODs from './ViewPODs';
import PODStatistics from './PODStatistics';

const PODScreen = (props) => {
  const {t, i18n} = useTranslation(['pod', 'common']);
  const [showStats, setShowStats] = useState(false);

  const toolBar = (
    <>
      <div>
        <FieldButton label={t('pod:ViewStatistics')} onClick={() => setShowStats(true)} icon="pi pi-chart-bar" className="p-button-success" />
      </div>
    </>
  )

  return (
    <>
      <PageContent title={t('pod:ViewPODs')} toolBar={toolBar}>
        <ViewPODs />
      </PageContent>
      <Dialog
        visible={showStats}
        modal
        maximized
        onHide={() => setShowStats(false)}
        header={t('pod:PODStatistics')}
        blockScroll
      >
        <PODStatistics />
      </Dialog>
    </>
  )
}

export default PODScreen;
/* eslint-disable object-shorthand */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useState , useEffect }  from 'react';    
import CardSparkLine from '../../../components/Charts/Card-SparkLine/CardSparkLine';
import { Row, Col } from 'reactstrap';
import Highcharts from 'highcharts';
import HighchartsHeatmap from "highcharts/modules/heatmap";
import HighchartsReact from 'highcharts-react-official';
import { config } from '../../../utils/config';
import { callApi } from '../../../utils/utils'; 
import queryString from 'query-string'  
import moment from 'moment'
import { useTranslation } from 'react-i18next';
import { Dialog } from 'primereact/dialog';  
import { Tag } from 'primereact/tag';

HighchartsHeatmap(Highcharts);

const DailyOperationDashboard =  (props) => {
   
  
   const { t, i18n } = useTranslation(['productionDashboard', 'common']);
    
   let defaultZona;
   let defaultFechaInicial = null;
   let defaultFechaFinal= null;
   let defaultClaCelda= null;
   let defaultClaTurno= null;

   if(props.location!==undefined){
    const params = queryString.parse(props.location.search);
    defaultZona = params !== undefined ? params.ClaZona : 0;
   }
   else {
    defaultZona = props.filters.ClaZona  !== undefined ? props.filters.ClaZona : 0;
    defaultFechaInicial = props.filters.FechaInicial  !== undefined ? props.filters.FechaInicial : null;
    defaultFechaFinal = props.filters.FechaFinal  !== undefined ? props.filters.FechaFinal : null;
    defaultClaCelda  = props.filters.ClaCelda !== undefined ? props.filters.ClaCelda : null;
    defaultClaTurno= props.filters.ClaTurno !== undefined ? props.filters.ClaTurno : null;
   } 

   const [claZona, setClaZona] = useState(defaultZona);
   const [fechaInicial, setFechaInicial] = useState(null);
   const [fechaFinal, setFechaFinal] = useState(null);
   const [nomZona, setNomZona] = useState('');
   const [NomTurno, setNomTurno] = useState('');
   const [ClaTurno, setClaTurno] = useState(defaultClaTurno);
   const [totalDias, setTotalDias] = useState('');
   const [tonsData, setTonsData] = useState([]);
   const [tonsTotal, setTonsTotal] = useState(0);
   const [cajasData, setCajasData] = useState([]);
   const [cajasTotal, setCajasTotal] = useState(0);
   const [utilizacionData, setUtilizacionData] = useState([]);
   const [utilizacionTotal, setUtilizacionTotal] = useState(0);
   const [disponibilidadData, setDisponibilidadData] = useState([]);
   const [disponibilidadTotal, setDisponibilidadTotal] = useState(0);
   const [eficienciaData, setEficienciaData] = useState([]);
   const [eficienciaTotal, setEficienciaTotal] = useState(0); 
   const [oEEData, setOEEData] = useState([]);
   const [oEETotal, setOEETotal] = useState(0);
   const [utilizationSeries, setUtilizationSeries] = useState([]);
   const [utilizationXCategoriesFecha, setUtilizationXCategoriesFecha] = useState([]);
   const [utilizationYCategoriesClaCelda, setUtilizationYCategoriesClaCelda] = useState([]);
   const [utilizationXCategories, setUtilizationXCategories] = useState([]);
   const [utilizationYCategories, setUtilizationYCategories] = useState([]);
   const [openModal, setOpenModal] = useState(false);
   const [cellValue, setCellValue] = useState(null);
   const [titleRank1, setTitleRank1] = useState('');
   const [titleRank2, setTitleRank2] = useState('');
   const [downtimeByReasonRank1Categories, setDowntimeByReasonRank1Categories] = useState([]);
   const [downtimeByReasonRank1Series, setDowntimeByReasonRank1Series] = useState([]);
   const [downtimeByReasonRank2Categories, setDowntimeByReasonRank2Categories] = useState([]);
   const [downtimeByReasonRank2Series, setDowntimeByReasonRank2Series] = useState([]);

    const onSearch = (filters) => {
        
        let urlWebService = `${config.UrlApiProject}Produccion/GetGraficoUtilizationData`; 
        
        const paramsService = {
            ...filters,
          }; 

        callApi(urlWebService, 'POST', paramsService, (response) => {
            setUtilizationSeries(response.data.series);
            setUtilizationYCategories(response.data.yAxisCategories);
            setUtilizationXCategories(response.data.xAxisCategories); 
            setUtilizationYCategoriesClaCelda(response.data.arregloClaCelda); 
            setUtilizationXCategoriesFecha(response.data.arregloFecha); 
        }, () => {
          // no hacemos nada solo  para evitar de mandar el mensaje si algun momento se pierde la conexion...
        });

        // como no dependen entre si los hacemos asincronos todos...
        urlWebService = `${config.UrlApiProject}Produccion/GetGraficoTonsData`; 
    
        callApi(urlWebService, 'POST', paramsService, (response) => {
            setTonsData(response.data.series);
            setTonsTotal(response.data.total); 
        }, () => {
          // no hacemos nada solo  para evitar de mandar el mensaje si algun momento se pierde la conexion...
        });

        urlWebService = `${config.UrlApiProject}Produccion/GetGraficoCajasData`; 
    
        callApi(urlWebService, 'POST', paramsService, (response) => {
            setCajasData(response.data.series);
            setCajasTotal(response.data.total); 
        }, () => {
          // no hacemos nada solo  para evitar de mandar el mensaje si algun momento se pierde la conexion...
        });

        urlWebService = `${config.UrlApiProject}Produccion/GetGraficoUtilizationCardData`; 
    
        callApi(urlWebService, 'POST', paramsService, (response) => {
            setUtilizacionData(response.data.series);
            setUtilizacionTotal(response.data.total); 
        }, () => {
          // no hacemos nada solo  para evitar de mandar el mensaje si algun momento se pierde la conexion...
        });

        urlWebService = `${config.UrlApiProject}Produccion/GetGraficoDisponibilidadCardData`; 
    
        callApi(urlWebService, 'POST', paramsService, (response) => {
            setDisponibilidadData(response.data.series);
            setDisponibilidadTotal(response.data.total); 
        }, () => {
          // no hacemos nada solo  para evitar de mandar el mensaje si algun momento se pierde la conexion...
        });

        urlWebService = `${config.UrlApiProject}Produccion/GetGraficoEficienciaCardData`; 
    
        callApi(urlWebService, 'POST', paramsService, (response) => {
            setEficienciaData(response.data.series);
            setEficienciaTotal(response.data.total); 
        }, () => {
          // no hacemos nada solo  para evitar de mandar el mensaje si algun momento se pierde la conexion...
        });

        urlWebService = `${config.UrlApiProject}Produccion/GetGraficoOEECardData`; 
    
        callApi(urlWebService, 'POST', paramsService, (response) => {
            setOEEData(response.data.series);
            setOEETotal(response.data.total); 
        }, () => {
          // no hacemos nada solo  para evitar de mandar el mensaje si algun momento se pierde la conexion...
        });
    };
    
    const onSearchGrafica = (filters) => {
      const urlWebService   = `${config.UrlApiProject}Produccion/GetOperationDowntimePareto`; 
      filters.EsTipoPareto  = 3;
      filters.DetalleRank   = null;
      filters.ClaZona       = props.filters.ClaZona;
      filters.FechaInicial  = filters.Fecha;
      filters.FechaFinal    = filters.Fecha;
      filters.ClaTurno      = ClaTurno;
      let paramsService = {
          ...filters,
        }; 
        callApi(urlWebService, 'POST', paramsService, (response) => { 
            if (response.data.series.length >0 ){ 
                response.data.series[0] = {
                    y: response.data.series[0] ,
                    color: '#ef2f40'
                }  

                response.data.series[response.data.series.length-1] = {
                    y: response.data.series[response.data.series.length-1] ,
                    color: '#e4e4e4'
                }  
            }
            setTitleRank1(response.data.title);
            setDowntimeByReasonRank1Series(response.data.series);
            setDowntimeByReasonRank1Categories(response.data.categories); 
        });
        filters.FechaInicial = props.filters.FechaInicial;
        filters.FechaFinal = props.filters.FechaFinal;
        paramsService = {
            ...filters,
          }; 
        callApi(urlWebService, 'POST', paramsService, (response) => { 
            if (response.data.series.length >0 ){ 
                response.data.series[0] = {
                    y: response.data.series[0] ,
                    color: '#ef2f40'
                }  
                response.data.series[response.data.series.length-1] = {
                    y: response.data.series[response.data.series.length-1] ,
                    color: '#e4e4e4'
                }  
            }
            setTitleRank2(response.data.title);
            setDowntimeByReasonRank2Series(response.data.series);
            setDowntimeByReasonRank2Categories(response.data.categories); 
        });
    }
    
    const utilizationHeatMap = {
        chart: {
            type: 'heatmap',
            marginTop: 40,
            marginBottom: 80,
            plotBorderWidth: 1
        }, 
         
        title: {
            text: t('productionDashboard:chartUtilizationTitle') 
        },
    
        xAxis: { 
            categories: utilizationXCategories
        },
    
        yAxis: { 
            title:"",
            categories: utilizationYCategories,
            reversed: true
        },

        tooltip: {
          formatter: function() {  
            const { x, y, point, series } = this; 
            return `<b>${t('productionDashboard:chartUtilizationTitle') }</b> <br/> <b>${t('formulario:Cell')}:</b> ${  series.yAxis.categories[y]  }</b><br/><b>${t('formulario:Day')}:</b> ${  series.xAxis.categories[point.x]  }   <br/><b>${t('formulario:Value')}:</b>  ${Highcharts.numberFormat(point.value,1)  }`;
          }
        },
        /*
        colorAxis: {
             
            min: 0,
            max:100, 
            stops: [
                [0,    '#d9d9d9'],  
                [0.01, '#c00000'],  
                [0.79, '#c00000'], 
                [0.82, '#fafd00'], 
                [0.83, '#00b050']  
            ]
        },
        */
        legend: {
            align: 'right',
            layout: 'vertical',
            margin: 0,
            verticalAlign: 'top',
            y: 25,
            symbolHeight: 280
        }, 
    
        series: [{
            name: t('productionDashboard:chartUtilizationTitle') ,
            borderWidth: 1,
            color: '#e4e4e4',
            cursor : 'pointer',
            data: utilizationSeries,
            showInLegend:false,
            dataLabels: {
                enabled: true,
                color: '#FFFFFF'
            },
            point: {
              events: {
                click: function () {
                  const { x, y, point, series } = this; 
                  setCellValue(series.yAxis.categories[y]);
                  setOpenModal(true);
                  const filtersData = {}
                  filtersData.xAxis             = series.xAxis.categories[x];
                  filtersData.yAxis             = series.yAxis.categories[y];
                  filtersData.Fecha             = utilizationXCategoriesFecha[x];
                  filtersData.claCelda          = utilizationYCategoriesClaCelda[y];
                  onSearchGrafica(filtersData);
                }
              }
            }
        }],
    }

   
    
    useEffect(() => {
        
        console.log('turno', defaultClaTurno);
        const filter = {} 
        filter.ClaZona = defaultZona;  
        filter.FechaInicial = defaultFechaInicial
        filter.FechaFinal = defaultFechaFinal
        filter.ClaCelda   = defaultClaCelda
        filter.ClaTurno   = defaultClaTurno
        const urlWebService = `${config.UrlApiProject}Produccion/GetOperacionDashboardHeaderInfo`;
        const paramsService = {
          ...filter,
        }; 
    
        callApi(urlWebService, 'POST', paramsService, (response) => 
             { 
                setFechaInicial(response.data.FechaInicial);
                setFechaFinal(response.data.FechaFinal); 
                setNomZona(response.data.NomZona); 
                setTotalDias(response.data.TotalDias); 
                setClaTurno(response.data.ClaTurno); 
                setNomTurno(response.data.NomTurno); 
                const filter = {}
                filter.ClaZona      = response.data.ClaZona;
                filter.FechaInicial = response.data.FechaInicial;
                filter.FechaFinal = response.data.FechaFinal;
                filter.ClaCelda = response.data.ClaCelda;
                filter.ClaTurno = response.data.ClaTurno;
                onSearch(filter);
            
            }, () => {}, false, false
        ); 
        
      }, [props.filters]);

    
      const closeModal = () => {
        setOpenModal(false);
      }
      
      const onOpenModal = () => {
        setOpenModal(true)
      }

      const paretoReasonChartRank1 = {

        chart: {
            renderTo: 'container',
            type: 'column'
        },
        title: {
            text: titleRank1
        },
        tooltip: {
            shared: true
        },
        xAxis: {
            categories: downtimeByReasonRank1Categories,
            crosshair: true
        },
        yAxis: [{
            title: {
                text: ''
            }
        }, {
            title: {
                text: ''
            },
            minPadding: 0,
            maxPadding: 0,
            max: 100,
            min: 0,
            opposite: true,
            labels: {
                format: "{value}%"
            }
        }],
        series: [{
            type: 'pareto',
            name: 'Pareto',
            showInLegend: false, 
            yAxis: 1,
            zIndex: 10,
            baseSeries: 1, 
            dataLabels:{
                enabled:true,  
                formatter: function() {  
                    const { x, y, point, series } = this; 
                    return `${Highcharts.numberFormat(y,1)  }%`;
                  }
            },
            tooltip: {
                valueDecimals: 2,
                valueSuffix: '%'
            }
        }, {
            name: 'Hours',
            type: 'column',
            showInLegend: false, 
            zIndex: 2,
            data: downtimeByReasonRank1Series
        }]
    
    }

    const paretoReasonChartRank2 = {

      chart: {
          renderTo: 'container',
          type: 'column'
      },
      title: {
          text: titleRank2
      },
      tooltip: {
          shared: true
      },
      xAxis: {
          categories: downtimeByReasonRank2Categories,
          crosshair: true
      },
      yAxis: [{
          title: {
              text: ''
          }
      }, {
          title: {
              text: ''
          },
          minPadding: 0,
          maxPadding: 0,
          max: 100,
          min: 0,
          opposite: true,
          labels: {
              format: "{value}%"
          }
      }],
      series: [{
          type: 'pareto',
          name: 'Pareto',
          showInLegend: false, 
          yAxis: 1,
          zIndex: 10,
          baseSeries: 1, 
          dataLabels:{
              enabled:true,  
              formatter: function() {  
                  const { x, y, point, series } = this; 
                  return `${Highcharts.numberFormat(y,1)  }%`;
                }
          },
          tooltip: {
              valueDecimals: 2,
              valueSuffix: '%'
          }
      }, {
          name: 'Hours',
          type: 'column',
          showInLegend: false, 
          zIndex: 2,
          data: downtimeByReasonRank2Series
      }]
  
  }

  const headerDialog  = ( 
    <>
      <div className="dialogHeader">
        <h1>  {`${t('formulario:DowntimesCausesDetailCell')}`} <b>{ cellValue}</b> </h1>
      </div>  
    </>
  )

  const dialogStyle  = ( 
     { width: '900px', 
     borderLeft:"solid 2px #2791FF", 
     borderTop:"solid 2px #2791FF", 
     textAlign:"center" 
    }
  )

    return (  
      <>     
        <Row>  
          <Col xs={12} md={12} sm={12} lg={12}>
            <div className="header-dashboard">
              <Row> 
                <Col xs={12} md={12} sm={12} lg={12} className="header-dashboard-title"> 
                   
                  {t('productionDashboard:productionDashboardTitle') }
                </Col> 
                <Col xs={12} md={12} sm={12} lg={12}> 
                  <Row> 
                    <Col xs={12} md={6} sm={12} lg={6}> 
                      <div className="header-dashboard-sub-title">
                        {t('productionDashboard:Last') } {totalDias} {t('productionDashboard:Daysfrom') } <b> { moment(fechaInicial).format('MMMM Do YYYY')  } </b> {t('common:to') }  <b> {moment(fechaFinal).format('MMMM Do YYYY') }  </b> 
                      </div>
                    </Col>
                    <Col xs={12} md={6} sm={12} lg={6}> 
                      <div className="header-dashboard-sub-title-2">
                        {nomZona} - Shift - {NomTurno}
                      </div> 
                    </Col> 
                  </Row> 
                </Col> 
              </Row>
            </div> 
          </Col> 
        </Row>
        <Row>  
          <Col xs={12} md={12} sm={12} lg={12}>
                &nbsp;
          </Col>
        </Row>
        <Row>  
          <Col xs={12} md={6} sm={12} lg={2} hidden={true}>
            {cajasData.map((bucket) => { 
                 return (
                   <Col lg={12} md={4} sm={6} xs={6} key={bucket.name}> 
                     <CardSparkLine theme="blue" categories={bucket.categories} title={t('productionDashboard:cardBoxesProducedTitle')} series={bucket.serieReal} footerValue={bucket.name}></CardSparkLine>
                   </Col>
                );
              })} 
          </Col>
          <Col xs={12} md={6} sm={12} lg={2} hidden={true}>
            {tonsData.map((bucket) => { 
                 return (
                   <Col lg={12} md={4} sm={6} xs={6} key={bucket.name}> 
                     <CardSparkLine theme="azulMedio" categories={bucket.categories} title={t('productionDashboard:cardTonsTitle')} series={bucket.serieReal} footerValue={bucket.name}></CardSparkLine>
                   </Col>
                );
              })} 
          </Col>
          <Col xs={12} md={6} sm={12} lg={3}>
            {utilizacionData.map((bucket) => { 
                 return (
                   <Col lg={12} md={4} sm={6} xs={6} key={bucket.name}> 
                     <CardSparkLine theme="gray" categories={bucket.categories} title={t('productionDashboard:cardutilizationTitle')} series={bucket.serieReal} footerValue={bucket.name}></CardSparkLine>
                   </Col>
                );
              })} 
          </Col>
          <Col xs={12} md={6} sm={12} lg={3}>
            {disponibilidadData.map((bucket) => { 
                 return (
                   <Col lg={12} md={4} sm={6} xs={6} key={bucket.name}> 
                     <CardSparkLine theme="skyBlue" categories={bucket.categories} title={t('productionDashboard:cardavailabilityTitle')} series={bucket.serieReal} footerValue={bucket.name}></CardSparkLine>
                   </Col>
                );
              })} 
          </Col>
          <Col xs={12} md={6} sm={12} lg={3}>
            {eficienciaData.map((bucket) => { 
                 return (
                   <Col lg={12} md={4} sm={6} xs={6} key={bucket.name}> 
                     <CardSparkLine theme="lightBlue" categories={bucket.categories} title={t('productionDashboard:cardEfficiencyTitle')} series={bucket.serieReal} footerValue={bucket.name}></CardSparkLine>
                   </Col>
                );
              })} 
          </Col>
          <Col xs={12} md={6} sm={12} lg={3}>
            {oEEData.map((bucket) => { 
                 return (
                   <Col lg={12} md={4} sm={6} xs={6} key={bucket.name}> 
                     <CardSparkLine theme="azulFuerte" categories={bucket.categories} title={t('productionDashboard:cardOEETitle')} series={bucket.serieReal} footerValue={bucket.name}></CardSparkLine>
                   </Col>
                );
              })} 
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} sm={12} lg={12}>
            <div className="paddingLeft10 paddignTop10" hidden={true}>
              <Tag style={{ background: '#d9d9d9', color:"#777777" }}>0%</Tag>&nbsp;
              <Tag style={{ background: '#c00000' }}>79%</Tag>&nbsp;
              <Tag style={{ background: '#fafd00', color: "#333" }}>80-82%</Tag>&nbsp;
              <Tag style={{ background: '#00b050' }}>83% +</Tag>
            </div>
            { defaultClaCelda  === null ? <HighchartsReact containerProps={{ style: { height:  "500px" } }} highcharts={Highcharts} options={utilizationHeatMap} /> 
            : <HighchartsReact containerProps={{ style: { height:  "220px" } }} highcharts={Highcharts} options={utilizationHeatMap} /> }
            
          </Col>
        </Row>
        <Dialog
          visible={openModal}
          style={dialogStyle} 
          header={headerDialog}
          modal
          className="p-fluid p-grid"
          onHide={closeModal}
        >
          <Row>
            <Col> 
              <HighchartsReact containerProps={{ style: { height: "250px" } }} highcharts={Highcharts} options={paretoReasonChartRank1} />
            </Col>
          </Row>
          <Row>
           &nbsp;
          </Row>
          <Row>
            <Col> 
              <HighchartsReact containerProps={{ style: { height: "250px" } }} highcharts={Highcharts} options={paretoReasonChartRank2} />
            </Col>
          </Row>
        </Dialog>
      </> 
    )
}   

    
export default DailyOperationDashboard; 

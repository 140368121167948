/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from '../../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../../utils/utils';
import FieldDataTable from '../../../../components/Controls/FieldDataTable/FieldDataTable.jsx'; 
import FieldText from 'components/Controls/FieldText';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx'; 
import * as yup from 'yup';
import YupValidator from '../../../../utils/YupValidator';
import moment from 'moment'; 
import { Toolbar } from 'primereact/toolbar';
import FieldTextArea from 'components/Controls/FieldTextArea';
import FieldAutoComplete from '../../../../components/Controls/FieldAutoComplete.jsx'; 
import FieldNumber from '../../../../components/Controls/FieldNumber.jsx';
import FieldRadioButton from '../../../../components/Controls/FieldRadioButton/FieldRadioButton.jsx'; 
import ActionPlanAttachment from './ActionPlanAttachment.jsx';
import { Dialog } from 'primereact/dialog';
import { Badge } from 'primereact/badge';
import ActionPlanComments from './ActionPlanComments.jsx';
import { Tooltip } from 'primereact/tooltip';
import Highcharts from 'highcharts'; 
import InstructionsCard from '../../../../components/Cards/InstructionsCard/InstructionsCard.jsx';
import { Steps } from 'primereact/steps';
import FieldSelectButton from '../../../../components/Controls/FieldSelectButton.jsx' 
import ActionPlanQuickviewLevel2 from './ActionPlanQuickviewLevel2.jsx';
import KPIObjectivesDetail from '../FocusReport/KPIObjectivesDetail.jsx';

const ActionPlanQuickViewItem = (props) => {  
   
  /* ################## useState area start ################## */
  const { t } = useTranslation(['actionPlan', 'common']);
  const [errors, setErrors] = useState({});

  // ActionPlan
  const [filteredResponsible, setfilteredResponsible] = useState([]);
  const [CausaRaizList, setCausaRaizList] = useState([]);
  const [ResponsablesList, setResponsablesList] = useState([]);
  const [HistorialPendientesList, setHistorialPendientesList] = useState([]);
  const [PickOneVal, setPickOneVal] = useState(null);
  const [ResFecha, setResFecha] = useState(null);
  const [NombreLider, setNombreLider] = useState(null);
  const [TopicoAnalisis, setTopicoAnalisis] = useState(null);
  const [CurrRes, setCurrRes] = useState(null);
  const [PlanRes, setPlanRes] = useState(null);
  const [ExplicacionResultado, setExplicacionResultado] = useState(null);
  const [EmailOwner, setEmailOwner] = useState(null);
  const [PlanAccionBandera, setPlanAccionBandera] = useState(null);

  const [IdPlanAccion, setIdPlanAccion] = useState(null);
  const [IdCausa, setIdCausa] = useState(null);
  const [IdCausaAux, setIdCausaAux] = useState(null);
  const [DescCausaRaiz, setDescCausaRaiz] = useState(null);
  const [EsVerAnexo, setEsVerAnexo] = useState(false);
  const [EsAbrirNotas, setEsAbrirNotas] = useState(false);
  const [RowDataNotas, setRowDataNotas] = useState(null);
  const [ActionPlanFileList, setActionPlanFileList] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [headerColor, setHeaderColor] = useState('');
  const [colorEstatus, setColorEstatus] = useState(0);
  const [EsMostrarDetalleObjetivos, setEsMostrarDetalleObjetivos] =  useState(false);
  const [KPISelected, setKPISelected] = useState({});
  // End ActionPlan

  const [ClaPeriodo, setClaPeriodo] = useState(null);
  const [ClaFechaReporte, setClaFechaReporte] = useState(null);
  const [IdCSF, setIdCSF] = useState(null);
  const [PosicionesRelacionadasList, setPosicionesRelacionadasList] = useState([]);
  const [ComposicionList, setComposicionList] = useState([]);
  const [CausaRaiz, setCausaRaiz] = useState(null);
  const [OpcionesCausaRaiz, setOpcionesCausaRaiz] = useState([{ value: 1, name: t('actionPlan:Yes')}, { value: 0, name: t('actionPlan:No') }]);
  const [AgregarAcciones, setAgregarAcciones] = useState(null);
  const [OpcionesAgregarAcciones, setOpcionesAgregarAcciones] = useState([{ value: 1, name: t('actionPlan:Yes')}, { value: 0, name: t('actionPlan:No') }]);
  const [CrearActionPlan, setCrearActionPlan] = useState(null);
  const [OpcionesCrearActionPlan, setOpcionesCrearActionPlan] = useState([{ value: 1, name: t('actionPlan:Yes')}, { value: 0, name: t('actionPlan:No') }]);
  
 
  /* ACTION PLAN CHILDS */
  const [EsAbrirActionPlanSel, setEsAbrirActionPlanSel] = useState(false); 
  const [ClaPeriodoSel, setClaPeriodoSel] = useState(null); 
  const [ClaFechaReporteSel, setClaFechaReporteSel] = useState(null); 
  const [IdCSFSel, setIdCSFSel] = useState(null); 
  const [IsAtLeastOneActionPlanOpen, setIsAtLeastOneActionPlanOpen] = useState(false);
  const [showAsEmptyActionPlan, setShowAsEmptyActionPlan] = useState(false);
  const [EsReviewActionPlan, setEsReviewActionPlan] = useState(false); 
  const [ActionPlanList, setActionPlanList] = useState([]);
 

  useEffect(() => { 
    setErrors({}); 
    setIdCausa(null);
    setPickOneVal(null);
    setIdCausaAux(null);
    setClaPeriodo(props.ClaPeriodo === undefined ? 0 : props.ClaPeriodo); 
    setClaFechaReporte(props.ClaFechaReporte === undefined ? 0 : props.ClaFechaReporte);
    setIdCSF(props.IdCSF === undefined ? 0 : props.IdCSF);
    obtenerEncabezadoActionPlan(
      props.ClaPeriodo === undefined ? 0 : props.ClaPeriodo,
      props.IdCSF === undefined ? 0 : props.IdCSF,
      props.ClaFechaReporte === undefined ? 0 : props.ClaFechaReporte
    ); 

    if (props.show) {
      // document.body.classList.add('bodynotscroll');
    }
  }, [props.IdCSF, props.ClaPeriodo, props.ClaFechaReporte, props.show]);

  const obtenerEncabezadoActionPlan = (ClaPeriodo, IdCSF, ClaFechaReporte) => {
    const GetEncabezado = `${config.UrlApiProject}ScoreCard/GetEncabezadoActionPlan`;
    const paramsToService = {
      IdCSF,
      ClaPeriodo,
      ClaFechaReporte,
      IdPlanAccion: null,
    };

    callApi(GetEncabezado, 'POST', paramsToService, (response) => { 
      if (response.data.EncabezadoActionPlan.length > 0) { 
        
        setShowAsEmptyActionPlan(false);
        setResFecha(response.data.EncabezadoActionPlan[0].ResFecha);
        setNombreLider(response.data.EncabezadoActionPlan[0].Leader);
        setTopicoAnalisis(response.data.EncabezadoActionPlan[0].Topico);
        setCurrRes(response.data.EncabezadoActionPlan[0].CurrRes);
        setPlanRes(response.data.EncabezadoActionPlan[0].PlanRes);
        setExplicacionResultado(response.data.EncabezadoActionPlan[0].ExplicacionResultado);
        setIdPlanAccion(response.data.EncabezadoActionPlan[0].IdPlanAccion);
        setCausaRaiz(response.data.EncabezadoActionPlan[0].EsCausaRaizRevisadas);
        setAgregarAcciones(response.data.EncabezadoActionPlan[0].EsAccionesRevisadas);
        setCrearActionPlan(response.data.EncabezadoActionPlan[0].EsCrearPropioActionPlan);
        setEmailOwner(response.data.EncabezadoActionPlan[0].EmailResponsable);
        setPlanAccionBandera(response.data.EncabezadoActionPlan[0].PlanAccionBandera);
        // ObtenerCausaRaizActionPlan(response.data.EncabezadoActionPlan[0].IdPlanAccion);
        bindCausaRaiz(response.data.CausaRaiz);

        // ObtenerResponsablesActionPlan(response.data.EncabezadoActionPlan[0].IdPlanAccion);
        bindResponsables(response.data.Responsables);

        /*
        ObtenerHistorialPendientesActionPlan(
          IdCSF,
          response.data.EncabezadoActionPlan[0].IdPlanAccion
        );
        */
       setHistorialPendientesList(response.data.HistorialPendientes);
        
        // obtenerInformacionActionPlanFile(IdCSF, ClaPeriodo, ClaFechaReporte);
        setActionPlanFileList(response.data.ActionPlanFile);
        setPosicionesRelacionadasList(response.data.PosicionesRelacionadas);
        setComposicionList(response.data.Composicion);

        setActiveIndex(response.data.EncabezadoActionPlan[0].IdPlanAccion === null ? 0 : null);
        

        // Cuando es nuevo pero no y esta configurado como si requiere administracion y seguimiento el KPI
        if (response.data.EncabezadoActionPlan[0].IdPlanAccion === null && ( response.data.PosicionesRelacionadas.length > 0 || response.data.Composicion.length > 0))
        { 
          setActiveIndex(-1);
        }
         
        // si se creo un action plan y solo se marco como revisado mostramos la pantalla inicializad en el tab de revision de KPIs
        if (
            ( response.data.EncabezadoActionPlan[0].IdPlanAccion > 0 && response.data.EncabezadoActionPlan[0].EsCrearPropioActionPlan === 0)
            && ( response.data.PosicionesRelacionadas.length > 0 || response.data.Composicion.length > 0)
        )
        { 
          setActiveIndex(-1);
          setShowAsEmptyActionPlan(true);
        }
  

        /*
          1: Rojo
          2: Amarillo
          3: Verde
        */
        setColorEstatus(response.data.EncabezadoActionPlan[0].ColorEstatus);
        setHeaderColor('');
        if (response.data.EncabezadoActionPlan[0].ColorEstatus === 1) {
          setHeaderColor('table-header-background-red');
        }
        if (response.data.EncabezadoActionPlan[0].ColorEstatus === 2) {
          setHeaderColor('table-header-background-yellow');
        }
      }
    });
  };
   
  const bindResponsables = (resposibleList) => {
    const arrayResponsable = [];
    let RowValues = {};
    for (let i = 0; i < resposibleList.length; i++) {
      RowValues = {
        ...resposibleList[i],
        RowIndex: i,
        BajaLogica: 0,
        ClaColaborador: {
          ClaColaborador: resposibleList[i].cClaColaborador,
          NomColaborador: resposibleList[i].NomColaborador,
        },
      };
      arrayResponsable.push(RowValues);
    }
 
    setResponsablesList(arrayResponsable);
  };

  const bindCausaRaiz = (CausaRaizList) => {
    for (let i = 0; i < CausaRaizList.length; i++) {
      if (CausaRaizList[i].EsSeleccionada === 1) {
        setIdCausaAux(CausaRaizList[i].IdCausa);
        setPickOneVal(CausaRaizList[i].IdCausa);
        setIdCausa(CausaRaizList[i].IdCausa);
      }
    }
    setCausaRaizList(CausaRaizList);
  };
  
  const PickOneColumn = (rowData, props) => {
    return (
      <>
        <FieldRadioButton
          inputId={`PickOne-${rowData.IdCausa}`}
          name="IdCausa"
          onChange={(value) => onChangePickOne(value, props)}
          checked={PickOneVal === rowData.IdCausa || rowData.EsSeleccionada === 1}
          value={rowData.IdCausa}
          disabled={true}
        />
      </>
    );
  }; 
   
  const FechaEntregaTemplate = (rowData) => {
    return (
      <>
        {rowData.cFechaTermino !== null ? moment(rowData.cFechaTermino).format('MM/DD/YYYY') : null}
      </>
    );
  };

  const FechaEntregaPendingActionTemplate = (rowData) => {
    return (
      <>
        {rowData.cFechaTermino !== null ? moment(rowData.cFechaTermino).format('MM/DD/YYYY') : null}
      </>
    );
  };

  const FechaReportePendingActionTemplate = (rowData) => {
    return (
      <>
        {rowData.cFechaReporte !== null ? moment(rowData.cFechaReporte).format('MM/DD/YYYY') : null}
      </>
    );
  };

  const FechaCompromisoTemplate = (rowData) => {
    return (
      <>
        {rowData.cFechaCompromiso !== null
          ? moment(rowData.cFechaCompromiso).format('MM/DD/YYYY')
          : null}
      </>
    );
  };

  const AddNoteTemplate = (rowData) => { 
    return (
      <div className="p-fluid"> 
        <Tooltip target="#DescriptionNote" />
        <i
          id="DescriptionNote"
          data-pr-tooltip={rowData.cComentarioTarea}
          onClick={() => AbrirNotas(rowData)}
          className={`fas fa-sticky-note ${
        rowData.cComentarioTarea !== null && rowData.cComentarioTarea !== ''
          ? 'light-yellow-color'
          : 'light-gray-color'
      }`}
        >
        </i>
      </div>    
);
  };
   

  const onChangePickOne = (value, props) => {
    const updatedPickOne = [...CausaRaizList];
    for (let i = 0; i < updatedPickOne.length; i++) {
      if (props.rowData.IdCausa === updatedPickOne[i].IdCausa) {
        updatedPickOne[i].EsSeleccionada = 1;
        if (props.rowData.DescripcionCausa !== null && props.rowData.DescripcionCausa !== '') {
          setIdCausaAux(props.rowData.IdCausa);
        }
      } else {
        updatedPickOne[i].EsSeleccionada = 0;
      }
    }
    setPickOneVal(value);
    setIdCausa(props.rowData.IdCausa);
  };

  const onChangeExplicacionResultado = (e) => {
    const newErrors = { ...errors };
    delete newErrors.ExplicacionResultado;
    setErrors(newErrors);
    setExplicacionResultado(e.target.value);
  };

   
 

  const showActiveRows = (rows) => {
    const activeRows = [];
    if (rows.length > 0) {
      rows.forEach((item, index) => {
        if (item.BajaLogica === 0 || item.BajaLogica === undefined) {
          item.BajaLogica = 0;
          item.RowIndex = index;
          activeRows.push({ ...item });
        }
      });
    } else {
      return [];
    }
    return activeRows;
  };

  const onNewClick = () => {
    const newRow = {
      RowIndex: ResponsablesList.length,
      cIdCausa: null,
      DescAccion: null,
      ClaColaborador: null,
      cFechaCompromiso: null,
      cFechaTermino: null,
      cIdPlanAccion: null,
    };
    const updatedProducts = [...ResponsablesList];
    updatedProducts.push(newRow);

    setResponsablesList(updatedProducts);
  };
 

  const valitationScheme = yup.object().shape({
    ExplicacionResultado: yup.string().required(t('common:RequiredField')).nullable(),
    CausaRaizList: yup.array().of(
      yup.object().shape({
        DescripcionCausa: yup
          .string()
          .when('EsSeleccionada', {
            is: 1,
            then: yup.string().required(t('common:RequiredField')).nullable(),
            otherwise: yup.string().nullable(),
          }),
      })
    ),
    ResponsablesList: yup.array().of(
      yup.object().shape({
        cDescAccion: yup.string().required(t('common:RequiredField')).nullable(),
        ClaColaborador: yup.mixed().required(t('common:RequiredField')).nullable(),
        cFechaCompromiso: yup.string().trim().required(t('common:RequiredField')).nullable(),
      })
    ) 
  });

  const valitationMarkAsReviewedScheme = yup.object().shape({ 
    CausaRaiz: yup.mixed().required(t('common:RequiredField')).nullable(),
    AgregarAcciones: yup.mixed().required(t('common:RequiredField')).nullable(),
    CrearActionPlan: yup.mixed().required(t('common:RequiredField')).nullable()
  });

  const headerResponsable = (
    <>
      <Row>
        <Col>
          {colorEstatus === 1 ? (
            <>{t('actionPlan:ListActionImproveRojo')}</>
          ) : colorEstatus === 2 ? (
            <>{t('actionPlan:ListActionImproveAmarillo')}</>
          ) : colorEstatus === 3 ? (
            <>{t('actionPlan:ListActionImproveVerde')}</>
          ) : (
            <>NOT STATUS CALCULATED</>
          )}
        </Col>
        <Col className="text-right" hidden={true}>
          <FieldButton
            className="p-button p-button-icon-only p-button-info rounded-button"
            onClick={onNewClick}
          >
            <i className="pi pi-plus"></i>
          </FieldButton>
        </Col>
      </Row>
    </>
  );
 
  const headerPendiente = (
    <>
      <Row>
        <Col>{t('actionPlan:Pending')}</Col>
      </Row>
    </>
  );
  const headerPosicionesRelacionadas = (
    <>
      <Row>
        <Col>{t('actionPlan:PosicionesRelacionadas')}</Col>
      </Row>
    </>
  );
  const headerComposicion = (
    <>
      <Row>
        <Col>{t('actionPlan:Composicion')}</Col>
      </Row>
    </>
  );
  const headerDiscuss = (
    <>
      <Row>
        <Col>
          {colorEstatus === 1 ? (
            <>{t('actionPlan:DiscussRojo')}</>
          ) : colorEstatus === 2 ? (
            <>{t('actionPlan:DiscussAmarillo')}</>
          ) : colorEstatus === 3 ? (
            <>{t('actionPlan:DiscussVerde')}</>
          ) : (
            <>NOT STATUS CALCULATED</>
          )}
        </Col>
      </Row>
    </>
  );
    
  

   

  const TituloDescription = () => {
    switch (colorEstatus) {
      case 1: // rojo
        return <>{t('actionPlan:DescCurrentResultRojo')}</>;

      case 2: // Amarillo
        return <>{t('actionPlan:DescCurrentResultAmarillo')}</>;

      case 3: // Verde
        return <>{t('actionPlan:DescCurrentResultVerde')}</>;

      default:
        return <>NOT STATUS CALCULATED PLEASE REVIEW WITH IT DEPARTMENT</>;
    }
  };
 

  const RenderDescription = (
    <>
      <div
        className={`p-datatable p-component p-datatable-scrollable p-datatable-hoverable-rows ${headerColor}`}
      >
        <div className="p-datatable-header">
          <Row>
            <Col>
              <TituloDescription></TituloDescription>
            </Col>
          </Row>
        </div>
      </div>
      <div className="p-fluid">
        <FieldTextArea
          name="ExplicacionResultado"
          rows={4}
          placeholder=""
          maxLength={450}
          value={ExplicacionResultado}
          onChange={(e) => onChangeExplicacionResultado(e)}
          errors={errors}
          className="bottom-rounded"
          readonly={true}
        />
      </div>
    </>
  );

  const RenderCausaRaiz = (
    <>
      <FieldDataTable
        value={showActiveRows(CausaRaizList)}
        className={`${headerColor}`}
        editMode="cell"
        selectionMode="single"
        header={headerDiscuss}
        paginator={false}
      > 
        <Column
          field="IdCausa"
          header={t('actionPlan:numeral')}
          headerStyle={{ width: '10px' }}
          columnKey="IdCausa"
          className="text-left"
        >
        </Column>
        <Column
          field="ClaveCausa"
          header={t('actionPlan:numeral')}
          headerStyle={{ width: '10px' }}
          columnKey="ClaveCausa"
          className="text-left"
        >
        </Column>
        <Column
          field="DescripcionCausa"
          header={t('actionPlan:Action')}
          headerStyle={{ width: '280px' }} 
        >
        </Column>
        <Column
          field="PesoRelevancia"
          header={t('actionPlan:Weigh')}
          headerStyle={{ width: '50px' }} 
          className="text-center"
        >
        </Column>
        <Column
          field="EsSeleccionada"
          header={t('actionPlan:PickOne')}
          headerStyle={{ width: '50px' }}
          body={(rowData, props) => PickOneColumn(rowData, props)}
        >
        </Column>
      </FieldDataTable>
    </>
  );

  const RenderResponsableList = (
    <>
      <FieldDataTable
        value={showActiveRows(ResponsablesList)}
        scrollable
        scrollHeight="150px"
        className={`${headerColor}`}
        editMode="cell"
        selectionMode="single"
        header={headerResponsable}
        paginator={false}
      > 
        <Column
          field="cClaveTarea"
          header={t('actionPlan:numeral')}
          headerStyle={{ width: '40px' }}
        >
        </Column>
        <Column
          field="cDescAccion"
          header={t('actionPlan:Action')}
          headerStyle={{ width: '280px' }} 
        >
        </Column>
        <Column
          field="NomColaborador"
          header={t('actionPlan:Owner')}
          headerStyle={{ width: '280px' }}
          columnKey="ClaColaborador" 
        >
        </Column> 
        <Column
          field="AddNote"
          header={t('actionPlan:Notes')}
          headerStyle={{ width: '70px', display: IdPlanAccion === null ? 'none' : '' }}
          style={{ display: IdPlanAccion === null ? 'none' : '' }}
          body={AddNoteTemplate}
          className="text-center"
        >
        </Column>
        <Column
          field="cFechaCompromiso"
          header={t('actionPlan:DueDate')}
          headerStyle={{ width: '120px' }}
          body={FechaCompromisoTemplate} 
        >
        </Column>
        <Column
          field="cFechaTermino"
          header={t('actionPlan:Completed')}
          headerStyle={{ width: '120px', display: IdPlanAccion === null ? 'none' : '' }}
          style={{ display: IdPlanAccion === null ? 'none' : '' }}
          body={FechaEntregaTemplate} 
        >
        </Column>
      </FieldDataTable>
    </>
  );
 

  const BanderaIcon = () => {
    let className = '';

    switch (PlanAccionBandera) {
      case 0:
        className = 'fas fa-flag-checkered  fa-20x';
        break;
      case 1:
        className = 'fa fa-flag danger-color  fa-20x';
        break;
      case 2:
        className = 'fa fa-flag warning-color  fa-20x';
        break;
      case 3:
        className = 'fa fa-flag success-color  fa-20x';
        break;
      default:
        className = 'fa fa-flag default-color  fa-20x';
    }
    return (
      <>
        <i className={className}></i>
      </>
    );
  };

  const closeModal = () => {
    setEsVerAnexo(false);
  }; 

  const AbrirAnexo = () => {
    setEsVerAnexo(!EsVerAnexo);
  };

  const dialogStyle = {
    width: '80vw',
  };

  const openObjetivosKPIDetalle = (item) => {  
    setKPISelected(item);
    setEsMostrarDetalleObjetivos(true)
  }


  const EstatusPeriodoTemplate = (props) => {
    let className = '';

    switch (props.ColorEstatus) {
      case 1:
        className = 'danger-color';
        break;
      case 2:
        className = 'warning-color';
        break;
      case 3:
        className = 'success-color';
        break;
      default:
        className = 'default-color';
    }
   

    return (
      <span className={`Cursor text-underline ${className}`} title={`${t('focusReport:ViewKPIByPeriod')}`} onClick={() => openObjetivosKPIDetalle(props)}>
        <b>{props.CurrentStatus !== null? Highcharts.numberFormat(props.CurrentStatus, 2, '.', ','): null}</b>
      </span> 
      )

      
  };

  const BodyTemplatePositionName = (rowData, props) => {
    return (
      <> 
        <div className='p-toolbar-group-left'>
          <span className="bold">{rowData.PositionName}</span>
        </div>
        <div className='p-toolbar-group-right'>
          <small>{ rowData.Responsible}</small> 
        </div>
      </>
    );
  } 

  const AccionesTemplateAp = (row) => {
    let className = '';

    switch (row.PlanAccionBandera) {
      case 0:
        className = 'fas fa-flag-checkered Cursor';
        break;
      case 1:
        className = 'fa fa-flag danger-color Cursor';
        break;
      case 2:
        className = 'fa fa-flag warning-color Cursor';
        break;
      case 3:
        className = 'fa fa-flag success-color Cursor';
        break;
      default:
        className = 'fa fa-flag default-color Cursor';
    }
    return (
      <>
        <i className={className} onClick={() => onClickReviewActionPlan(row)}></i>
      </>
    );
  };

   const onClickReviewActionPlan = (row) => {  
      setIsAtLeastOneActionPlanOpen(true);
      setIdCSFSel(row.IdCSF);
      setClaPeriodoSel(row.ClaPeriodo);
      setClaFechaReporteSel(row.ClaFechaReporte);  
      setEsReviewActionPlan(true); 
  }

  const onHideActionPlanReview = () => {
    setEsReviewActionPlan(false); 
    setIsAtLeastOneActionPlanOpen(true); 
  }

  // Cuando es un nuevo plan de accion lo renderizamos en modo wizard, para llevar al usuario
  // paso a paso o cuando se decide no crear action plan por que es manager del kpi
 
  
  if (IdPlanAccion === null || (IdPlanAccion > 0 && showAsEmptyActionPlan)) {
    return (
      <> 
        <Row className='light'>
          <Col lg={4} md={4} sm={12} xs={12}> 
            <small hidden={EmailOwner === null || EmailOwner === '' || typeof(EmailOwner) === 'undefined'}> 
              <i className="fas fa-user primary border-none btn-circle" /> 
              &nbsp;
            </small> 
            <span className='h1 dark-color'>{NombreLider} </span>
            &nbsp;&nbsp;
            <a
              title={`${t('actionPlan:EmailBtnTitle')} ${NombreLider} (${EmailOwner})`}
              href={`mailto:${EmailOwner}?subject=${TopicoAnalisis} - ${ResFecha}&body=Hello ${NombreLider} ,%0D%0A%0D%0APlease create / edit an Action Plan for this initiative. %0D%0A%0D%0AThank you!`}
            >
              <i className="fas fa-paper-plane orange-color" /> &nbsp;<i className="fas fa-envelope orange-color"></i>
            </a>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="text-center">
              
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="text-right">
            <div className="w-100 relative">
              <span className='h1 dark-color'> {ResFecha} </span>
            </div>
          </Col>
        </Row>
        <Row className="align-items-start pt-3 pb-3 light">
          <Col lg={6} md={6} sm={12} xs={12}>
            <h2>{TopicoAnalisis}</h2>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12} className="text-right">
            <Row>
              <Col lg={6} md={6} sm={12} xs={12}>
                <span className="dark-color h2">{t('actionPlan:CurrentResult')}</span>: &nbsp;
                <span className="h2">{Highcharts.numberFormat(CurrRes, 2)}</span>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                <span className="dark-color h2">{t('actionPlan:PlanedResult')}</span>: &nbsp;
                <span className="h2">{Highcharts.numberFormat(PlanRes, 2)}</span>
              </Col> 
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
             &nbsp;
          </Col>
        </Row>
        <Row className="pt-3 pb-3" hidden={activeIndex !== -1}>
          <Col lg={12}>
            <Row className="align-items-start" hidden={PosicionesRelacionadasList.length === 0 && ComposicionList.length === 0}>
              <Col lg={9} md={9} sm={12} xs={12}>              
                <Row className="pt-3 pb-3" hidden={PosicionesRelacionadasList.length === 0}>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <FieldDataTable
                      value={showActiveRows(PosicionesRelacionadasList)}
                      scrollable
                      scrollHeight="330px"
                // className={`${headerColor}`}
                      editMode="cell"
                      selectionMode="single"
                      header={headerPosicionesRelacionadas}
                      paginator={false}
                    >
                      <Column
                        field="IndexRole"
                        header={t('actionPlan:IndexRole')}
                        className="text-center"
                        columnKey="IndexRole"
                        headerStyle={{ width: '45px', textAlign: 'center' }}
                      >
                      </Column>
                      <Column
                        field="IndexDescription"
                        header={t('actionPlan:IndexDescription')}
                        headerStyle={{ width: '280px' }}
                      >
                      </Column>
                      <Column
                        field="PositionName"
                        header={t('actionPlan:PositionName')}
                        headerStyle={{ width: '250px' }}
                        body={(rowData, props) => BodyTemplatePositionName(rowData, props)}
                      >
                      </Column>
                      {/* <Column
                      field="Responsible"
                      header={t('actionPlan:Responsible')}
                      headerStyle={{ width: '150px' }}
                      columnKey="Responsible"
                    >
                    </Column> */}
                      <Column
                        field="CurrentStatus"
                        header={t('actionPlan:CurrentStatus')}
                        headerStyle={{ width: '80px' }}
                        className="text-center"
                        body={EstatusPeriodoTemplate}
                      >
                      </Column>
                      <Column
                        className="text-center"
                        header={t('actionPlan:AP')}
                        headerStyle={{ width: '30px', textAlign: 'center' }}
                        body={AccionesTemplateAp}
                      >
                      </Column>
                      <Column
                        field="Result"
                        header={t('actionPlan:ResultStatusPlan')}
                        headerStyle={{ width: '300px' }}
                      >
                      </Column>
                   
                    </FieldDataTable>
                  </Col>
                </Row> 
                <Row className="pt-3 pb-3" hidden={ComposicionList.length === 0}>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <FieldDataTable
                      value={showActiveRows(ComposicionList)}
                      scrollable
                      scrollHeight="330px"
                // className={`${headerColor}`}
                      editMode="cell"
                      selectionMode="single"
                      header={headerComposicion}
                      paginator={false}
                    >
                      <Column
                        field="IndexType"
                        header={t('actionPlan:IndexType')}
                        className="text-center"
                        columnKey="IndexType"
                        headerStyle={{ width: '45px', textAlign: 'center' }}
                      >
                      </Column>
                      <Column
                        field="IndexDescription"
                        header={t('actionPlan:IndexDescription')}
                        headerStyle={{ width: '280px' }}
                      >
                      </Column>
                      <Column
                        field="PositionName"
                        header={t('actionPlan:PositionName')}
                        headerStyle={{ width: '250px' }}
                        body={(rowData, props) => BodyTemplatePositionName(rowData, props)}
                      >
                      </Column>
                      {/* <Column
                      field="Responsible"
                      header={t('actionPlan:Responsible')}
                      headerStyle={{ width: '150px' }}
                      columnKey="Responsible"
                    >
                    </Column> */}
                      <Column
                        field="CurrentStatus"
                        header={t('actionPlan:CurrentStatus')}
                        headerStyle={{ width: '80px' }}
                        className="text-center"
                        body={EstatusPeriodoTemplate}
                      >
                      </Column>
                      <Column
                        className="text-center"
                        header={t('actionPlan:AP')}
                        headerStyle={{ width: '30px', textAlign: 'center' }}
                        body={AccionesTemplateAp}
                      >
                      </Column>
                      <Column
                        field="ResultVsPlan"
                        header={t('actionPlan:ResultStatusPlan')}
                        headerStyle={{ width: '300px' }}
                      >
                      </Column>                   
                    </FieldDataTable>
                  </Col>
                </Row>
              </Col>
              <Col lg={3} md={3} sm={12} xs={12}>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>   
                    <span className="instruccions-legend">
                      {t('actionPlan:CausaRaizPretunta')}
                    </span>
                    <div className="p-fluid">
                      <FieldSelectButton 
                        name="CausaRaiz"
                        value={CausaRaiz} 
                        options={OpcionesCausaRaiz} 
                        onChange={(e) => onchangeCausaRaiz(e.value)} 
                        optionValue="value"
                        optionLabel="name"
                        disabled={true}
                      /> 
                    </div>
                  </Col>
                </Row> 
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>   
                    <span className="instruccions-legend">
                      {t('actionPlan:ActionPregunta')}
                    </span>
                    <div className="p-fluid">
                      <FieldSelectButton 
                        name="AgregarAcciones"
                        value={AgregarAcciones} 
                        options={OpcionesAgregarAcciones} 
                        onChange={(e) => onchangeAgregarAcciones(e.value)} 
                        optionValue="value"
                        optionLabel="name"
                        disabled={true}
                      /> 
                    </div>
                  </Col>
                </Row> 
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>   
                    <span className="instruccions-legend">
                      {t('actionPlan:CreateActionPlanPregunta')}
                    </span>
                    <div className="p-fluid">
                      <FieldSelectButton 
                        name="CrearActionPlan"
                        value={CrearActionPlan} 
                        options={OpcionesCrearActionPlan} 
                        onChange={(e) => onchangeCrearActionPlan(e.value)} 
                        optionValue="value"
                        optionLabel="name"
                        disabled={true}
                      /> 
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
                  </Col>
                </Row> 
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
                  </Col>
                </Row>
                <Row hidden={true}>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <InstructionsCard>
                      <p>
                        <i className="fas fa-info-circle"></i>&nbsp;{t('actionPlan:HelpReviewActionPlan')}
                      </p> 
                    </InstructionsCard>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row hidden={PosicionesRelacionadasList.length > 0 || ComposicionList.length > 0}>
          <Col lg={12} className="text-center">
            <BanderaIcon></BanderaIcon> 
            <div>
                  &nbsp;
            </div>
            <div>
              <span className='h1'>{t('actionPlan:APForNoExists').replace('[TopicAnalisis]', TopicoAnalisis)}.</span> 
            </div>
          </Col>
        </Row> 
        <Dialog
          visible={EsMostrarDetalleObjetivos}
          header=""
          style={dialogStyle} 
          modal
          className="dialog-transparent"
          contentClassName="dialog-content-transparent"
          closable={false} 
          onHide={()=> setEsMostrarDetalleObjetivos(false)}
        >
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
                          &nbsp;
            </Col> 
          </Row> 
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
              <KPIObjectivesDetail
                ClaDepartamento={KPISelected.ClaDepartamento} 
                ClaArea={KPISelected.ClaArea}
                ClaTipoIndicador={KPISelected.ClaTipoIndicador}
                ClaIndicador={KPISelected.ClaIndicador}
                ClaPuesto={KPISelected.ClaPuesto}
                ClaAnio={KPISelected.ClaAnio}
                showCloseButton={true}
                NomKPI={KPISelected.IndexDescription}
                onCloseClick={()=> setEsMostrarDetalleObjetivos(false)}
              >
              </KPIObjectivesDetail>
            </Col>
          </Row>
        </Dialog> 
        {
        EsReviewActionPlan ?
        (  
          <> 
            <ActionPlanQuickviewLevel2 show={EsReviewActionPlan} onHide={onHideActionPlanReview} IdCSF={IdCSFSel} ClaPeriodo={ClaPeriodoSel} ClaFechaReporte={ClaFechaReporteSel} ActionPlanList={ActionPlanList}></ActionPlanQuickviewLevel2>
          </>
        )
        :
        (<></>)
      }  
      </>
    );
  }

  const closeModalNotas = () => {
    setEsAbrirNotas(false);
  };

  const AbrirNotas = async (data) => {
    // setEsAbrirNotas(!EsAbrirNotas);
    // setRowDataNotas(data);
  }; 

  return (
    <> 
      <Row className='light'>
        <Col lg={6} md={6} sm={12} xs={12}>
          <small hidden={EmailOwner === null || EmailOwner === '' || typeof(EmailOwner) === 'undefined'}>
            <a title={`${t('actionPlan:EmailBtnTitle')} ${NombreLider} (${EmailOwner})`} href={`mailto:${EmailOwner}?subject=${TopicoAnalisis} - ${ResFecha}&body=Hello ${NombreLider} `}>
              <FieldButton icon="fas fa-envelope-square" className="primary border-none bold btn-circle"  />  
            </a>
              &nbsp;
          </small>
          <span className='h1 dark-color'>{NombreLider} </span> 
          
        </Col> 
        <Col lg={6} md={6} sm={12} xs={12} className="text-right">
          <Row>
            <Col lg={6} md={6} sm={12} xs={12}>
              <div>
                <FieldButton
                  label={t('scoreCard:APSupportFiles')}
                  icon="pi pi-paperclip"
                  className="light-blue no-border rounded-button font-weight-bold"
                  onClick={() => AbrirAnexo()}
                >
                  <div className="" hidden={ActionPlanFileList.length === 0}>
                    <Badge value={ActionPlanFileList.length} className="warning" />
                  </div>
                </FieldButton>
              </div>  
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <div className="w-100 relative">
                <span className='h1 dark-color'> {ResFecha} </span>
              </div>
            </Col> 
          </Row>
        </Col>
      </Row>
      <Row className="align-items-start pt-3 pb-3 light">
        <Col lg={6} md={6} sm={12} xs={12}>
          <h2>{TopicoAnalisis}</h2>
        </Col>
        <Col lg={6} md={6} sm={12} xs={12} className="text-right">
          <Row>
            <Col lg={6} md={6} sm={12} xs={12}>
              <span className="dark-color h2">{t('actionPlan:CurrentResult')}</span>: &nbsp;
              <span className="h2">{Highcharts.numberFormat(CurrRes, 2)}</span>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <span className="dark-color h2">{t('actionPlan:PlanedResult')}</span>: &nbsp;
              <span className="h2">{Highcharts.numberFormat(PlanRes, 2)}</span>
            </Col> 
          </Row>
        </Col>
      </Row>
      <Row className="pt-3 pb-3">
        <Col lg={2}></Col>
        <Col lg={8}>
          <InstructionsCard className="transparent">
            <span className="instruccions-legend">
              {t('actionPlan:InstruccionesGeneralesPaso1')}
            </span>
          </InstructionsCard>
        </Col>
        <Col lg={2}></Col>
      </Row>
      <Row className="align-items-start">
        <Col lg={4} md={4} sm={12} xs={12}>
          {RenderDescription}
        </Col>
        <Col lg={8} md={8} sm={12} xs={12}>
          {RenderCausaRaiz}
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12} className="text-right">
            &nbsp;
        </Col>
      </Row>
      <Row className="align-items-start pb-3">
        <Col lg={12} md={12} sm={12} xs={12}>
          {RenderResponsableList}
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12} className="text-right"></Col>
      </Row>
      <Row hidden={true}>
        <Col lg={12} md={12} sm={12} xs={12}>
          <FieldDataTable
            value={showActiveRows(HistorialPendientesList)}
            scrollable
            scrollHeight="330px"
            className={`${headerColor}`}
            editMode="cell"
            selectionMode="single"
            header={headerPendiente}
            paginator={false}
          >
            <Column
              field="cFechaReporte"
              header={t('actionPlan:StatusDate')}
              headerStyle={{ width: '100px' }}
              body={FechaReportePendingActionTemplate}
            >
            </Column>

            <Column
              field="cDescAccion"
              header={t('actionPlan:Action')}
              headerStyle={{ width: '280px' }}
            >
            </Column>
            <Column
              field="NomColaborador"
              header={t('actionPlan:Owner')}
              headerStyle={{ width: '150px' }}
              columnKey="NomColaborador"
            >
            </Column>  
            <Column
              field="cFechaCompromiso"
              header={t('actionPlan:DueDate')}
              headerStyle={{ width: '120px' }}
              body={FechaCompromisoTemplate}
            >
            </Column>
            <Column
              field="cFechaTermino"
              header={t('actionPlan:Completed')}
              headerStyle={{ width: '120px' }}
              body={FechaEntregaPendingActionTemplate}
            >
            </Column>
          </FieldDataTable>
        </Col>
      </Row> 
      <Dialog
        visible={EsVerAnexo}
        header={t('actionPlan:ActionDocuments')}
        style={dialogStyle}
        modal
        className="dialog-custom"
        onHide={closeModal}
      >
        <Row>
          <Col>
            <ActionPlanAttachment
              IsUpload={false}
              IsReadyOnly={true}
              IdCSF={IdCSF}
              ClaPeriodo={ClaPeriodo}
              ClaFechaReporte={ClaFechaReporte} 
            >
            </ActionPlanAttachment>
          </Col>
        </Row>
      </Dialog>  
      <Dialog
        visible={EsAbrirNotas}
        header={t('actionPlan:tooltipComentarios')}
        style={dialogStyle}
        modal
        className="dialog-custom"
        onHide={closeModalNotas}
      >
        <Row>
          <Col>
            <ActionPlanComments
              RowDataNotas={RowDataNotas}
              IdCSF={props.IdCSF}
              ClaPeriodo={props.ClaPeriodo}
              ClaFechaReporte={props.ClaFechaReporte}
              closeModalNotas={closeModalNotas}
              IdPlanAccion={IdPlanAccion} 
              IsReadOnly={true}
            >
            </ActionPlanComments>
          </Col>
        </Row>
      </Dialog>
      <Dialog
        visible={EsMostrarDetalleObjetivos}
        header=""
        style={dialogStyle} 
        modal
        className="dialog-transparent"
        contentClassName="dialog-content-transparent"
        closable={false} 
        onHide={()=> setEsMostrarDetalleObjetivos(false)}
      >
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
                          &nbsp;
          </Col> 
        </Row> 
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
            <KPIObjectivesDetail
              ClaDepartamento={KPISelected.ClaDepartamento} 
              ClaArea={KPISelected.ClaArea}
              ClaTipoIndicador={KPISelected.ClaTipoIndicador}
              ClaIndicador={KPISelected.ClaIndicador}
              ClaPuesto={KPISelected.ClaPuesto}
              ClaAnio={KPISelected.ClaAnio}
              showCloseButton={true}
              NomKPI={KPISelected.IndexDescription}
              onCloseClick={()=> setEsMostrarDetalleObjetivos(false)}
            >
            </KPIObjectivesDetail>
          </Col>
        </Row>
      </Dialog>
    </>
  );
};
export default ActionPlanQuickViewItem;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect,useRef  } from 'react'; 
import { useTranslation } from 'react-i18next';   
import { Row,Col, TabContent, TabPane , Nav, NavItem, NavLink, Card, CardBody} from 'reactstrap';    
import marker from '../../../assets/img/icons/marker_success.png'
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import DigitalViewDashboard from './DigitalViewDashboard'
import './DigitalViewDashboard.scss'; 
import PageContent from 'layouts/PageContent'; 
import moment from 'moment';  
import FieldDropdown from 'components/Controls/FieldDropdown';
import DigitalViewDashboardCurrentShift from './DigitalViewDashboardCurrentShift';
import FieldToogleSwitch from 'components/Controls/FieldToogleSwitch/FieldToogleSwitch';


const DigitalViewDashboardScreen = (props) => { 
 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['dgt', 'common']);
  const [file, setFile] = useState(null);
  const [TabActiveIndex, setTabActiveIndex] = useState(1);
  const [TipoPlano, setTipoPlano] = useState(1);
  const [ShowAssetView, setShowAssetView] = useState(true);
  const [lastUpdateDate, setLastUpdateDate] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(0);
  const [Loading, setLoading] = useState(false);
  const viewsTypes = {
    LAYOUT: 1,
    CURRENT_SHIFT: 2
  };
  const [viewType, setViewType] = useState(viewsTypes.LAYOUT);  
  const Layouts = [
    { layoutType: 1, name: t('dgt:BULKPLANT') },
    { layoutType: 2, name: t('dgt:COLLATED') },
    { layoutType: 3, name: t('dgt:PAPERTAPE') },
    // { layoutType: 4, name: t('dgt:Houston') }
  ];
  const interval = useRef();
  

  const refreshScreen = () => {
    console.log('refresh...', forceRefresh);
    setForceRefresh(forceRefresh+1);
  }

  useEffect(() => {     
    window.addEventListener('visibilitychange', () => {
      if(document.hidden) { 
        clearInterval(interval.current); 
      }
      else {  
        if (interval.current) {
          clearTimeout(interval.current); 
        }

        interval.current = setInterval(() => {
          refreshScreen();
            }, 60000); 
      }
    });
      
    interval.current = setInterval(() => {
      refreshScreen();
    }, 60000);
  
    return () => {
      if (interval.current) {
        clearTimeout(interval.current);  
      }  
    }  
  }, [forceRefresh]);  
 

  const switchTab = tab => {
    if(TabActiveIndex !== tab){
      setTipoPlano(tab);
      setTabActiveIndex(tab);
    }
  }

  const getPageTitle = () => {
    let title = ''; 
    if (viewType === viewsTypes.LAYOUT) {
      title = t('dgt:LayoutView');
    } else if (viewType === viewsTypes.CURRENT_SHIFT) {
      title = t('dgt:CurrentShift');
    }
    return <>{title}</>;

    // if (TabActiveIndex === 1){ 
    //   return <>{t('dgt:DigitalViewDashboard')} - {t('dgt:BULKPLANT')}</>
    // }

    // if (TabActiveIndex === 2){ 
    //   return <>{t('dgt:DigitalViewDashboard')} - {t('dgt:COLLATED')}</>
    // }

    // if (TabActiveIndex === 3){ 
    //   return <>{t('dgt:DigitalViewDashboard')} - {t('dgt:PAPERTAPE')}</>
    // }

    // if (TabActiveIndex === 4){ 
    //   return <>{t('dgt:DigitalViewDashboard')} - {t('dgt:Houston')}</>
    // }

    // return <>{t('dgt:DigitalViewDashboard')}</>    
  }

  const LastRefresInfo = (lastupdatedate) => { 
    return setLastUpdateDate( lastupdatedate);
  }

  const onLayoutChange = (value) => {
    setTipoPlano(value);    
  }; 

  const onViewTypeClick = () => {
    if (viewType === viewsTypes.LAYOUT) {
      setViewType(viewsTypes.CURRENT_SHIFT) 
    } else {
      setViewType(viewsTypes.LAYOUT) 
    }
  }; 

  const onShowAssetViewChange = (value) => {
    setShowAssetView(value);    
  }; 

  const onLoadStarts = () => {
    setLoading(true);
  };

  const onLoadEnds = () => {
    setLoading(false);
  };

  const toolBar = (
    <>
      <div className="p-fluid pr-2">
        <div             
          style={{width: '250px'}}
        >{lastUpdateDate !== null? `@ ${moment(lastUpdateDate).format('MMMM Do YYYY, h:mm:ss a')}` : ''}
        </div>
      </div>
      <div className="p-fluid pr-2">
        <FieldButton 
          label={viewType === viewsTypes.LAYOUT? t('dgt:CurrentShift'): t('dgt:LayoutView')}
          className="white no-border btn-shadow"               
          style={{width: '150px'}}
          icon="fas fa-external-link-alt"        
          iconPos='right'                 
          onClick={onViewTypeClick}
          disabled={Loading}
        >
        </FieldButton>
      </div>
      <div className="p-fluid pr-2">
        <div 
          className="p-fluid"
          style={{
              backgroundColor: 'white',
              borderRadius: '3px',
              padding: '6px',
              minWidth: '150px'
            }}
        >
          <span 
            style={{
                color: 'black',
                fontWeight: 'normal'
              }}
          >
            {t('dgt:AssetView')}
          </span>&nbsp;
          <FieldToogleSwitch       
            id="ShowAssetInput"     
            checked={ShowAssetView}
            onChange={onShowAssetViewChange}                  
            small
            disabled={Loading}
          /> 
        </div>     
      </div> 
      <div className="p-fluid pr-2">
        <FieldDropdown
          options={Layouts}  
          value={TipoPlano} 
          optionValue="layoutType"
          optionLabel="name" 
          onChange={(e) => onLayoutChange(e.value)}
          label=""
          editable={false}
          showClear={false}
          disabled={Loading}
        />   
      </div>
    </>
  );
  
  return (
    <> 
              
      {
          viewType === viewsTypes.LAYOUT && (
            <PageContent title={getPageTitle()} toolBar={toolBar} className='content-no-margin digital-plant-dashboard'>  
              {
                TipoPlano > 0 && (
                  <DigitalViewDashboard 
                    TipoPlano={TipoPlano} 
                    LastRefresInfo={LastRefresInfo} 
                    ShowAssetView={ShowAssetView}
                    forceRefresh={forceRefresh}
                    OnLoadStarts={onLoadStarts}
                    OnLoadEnds={onLoadEnds}
                  >                
                  </DigitalViewDashboard>
                )            
              }             
            </PageContent> 
          )
        }       
      {
          viewType === viewsTypes.CURRENT_SHIFT && (
            <PageContent title={getPageTitle()} toolBar={toolBar} className='digital-plant-dashboard'>  
              {
                TipoPlano > 0 && (
                  <DigitalViewDashboardCurrentShift
                    TipoPlano={TipoPlano} 
                    LastRefresInfo={LastRefresInfo} 
                    ShowAssetView={ShowAssetView}
                    forceRefresh={forceRefresh}
                    OnLoadStarts={onLoadStarts}
                    OnLoadEnds={onLoadEnds}
                  />
                )            
              }             
            </PageContent> 
          )
        }     
    </>  
  ); 
};

export default DigitalViewDashboardScreen;

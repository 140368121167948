/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';
import PageContent from 'layouts/PageContent';
import { Panel } from 'primereact/panel';
import FieldButton from '../../../../components/Controls/FieldButton/FieldButton.jsx';
import FieldDropdown from '../../../../components/Controls/FieldDropdown.jsx';
import { config } from '../../../../utils/config';
import { callApi } from '../../../../utils/utils';
import FieldCheckbox from 'components/Controls/FieldCheckbox.jsx';
import OrganizationalChart from './OrganizationalChart.jsx';

const OrganizationalScoreSceen = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['common', 'organizationalChart']);
  const [OpcionesAnios, setOpcionesAnios] = useState([]);
  const [OpcionesMeses, setOpcionesMeses] = useState([]);
  const [UltimoClaMesCerrado, setUltimoClaMesCerrado] = useState(null);
  const [PrimerClaMes, setPrimerClaMes] = useState(null);
  const [ClaAnio, setClaAnio] = useState(null);
  const [ClaMes, setClaMes] = useState(null);
  const [EsMostrarIndividual, setEsMostrarIndividual] = useState(false);
  const [EsMostrarTeam, setEsMostrarTeam] = useState(false);
  const [EsMostrarProjectAssesment, setEsMostrarProjectAssesment] = useState(false);
  const [EsMostrarVerticalMeeting, setEsMostrarVerticalMeeting] = useState(false);
  const [EsMostrarTeamMeeting, setEsMostrarTeamMeeting] = useState(false);
  const [filters, setfilters] = useState({});  
  const [NivelPuesto, setNivelPuesto] = useState(null);
  const [EsAdmin, setEsAdmin] = useState(null);
  const [IsLoading, setIsLoading] = useState(true);
  
  
  useEffect(() => {
    const paramsService = {};

    const GetCurrentPeriodScoreCard = `${config.UrlApiProject}ScoreCard/GetScorecardUserDefaultInfo`;
    setIsLoading (true);
    callApi(GetCurrentPeriodScoreCard, 'GET', paramsService, (response) => {
      ParamScoreLlenado(false, false, false, false, false, response.data.ClaAnio, response.data.ClaMes);
      getYears(response.data.ClaAnio);
      getMeses(response.data.ClaAnio);
      setClaAnio(response.data.ClaAnio);
      setClaMes(response.data.ClaMes);   
      setNivelPuesto(response.data.NivelPuesto);
      setEsAdmin(response.data.EsAdmin);
      setIsLoading (false);
    }, ()=> { setIsLoading (false); });
  }, []);

  const panelMonthHeaderTemplate = (options) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
    const className = `${options.className} ${options.collapsed ? ' collapsed-header' : ''}`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>
          <FieldDropdown
            name="ClaAnio"
            value={ClaAnio}
            className="border-none background-none title info-dark-color"
            options={OpcionesAnios}
            onChange={(e) => onAnioChange(e.value)}
            optionValue="ClaAnio"
            optionLabel="NomAnio"
            placeholder={t('common:SelectYear')}
            showClear={false}
          />
        </span>
        <div>
          <div style={{ width: '200px', margin: 'auto' }}>
            <div className="p-inputgroup round white">
              {ClaMes === PrimerClaMes ? (
                <>
                  <FieldButton
                    icon="pi pi-angle-left"
                    className="white border-none bold"
                    disabled
                    onClick={(e) => {
                      onBeforeClaMesChange(ClaMes);
                    }}
                  />
                </>
              ) : (
                <>
                  <FieldButton
                    icon="pi pi-angle-left"
                    className="white border-none bold"
                    onClick={(e) => {
                      onBeforeClaMesChange(ClaMes);
                    }}
                  />
                </>
              )}
              <div style={{ width: '100%' }} className="text-center">
                <span className="h2">
                  <FieldDropdown
                    name="ClaMes"
                    value={ClaMes}
                    className="border-none background-none title info-dark-color hide_dropdown_btn"
                    options={OpcionesMeses}
                    onChange={(e) => onClaMesChange(e.value)}
                    optionValue="ClaMes"
                    optionLabel="NomMes"
                    showClear={false}
                  />
                </span>
              </div>
              {ClaMes === UltimoClaMesCerrado ? (
                <>
                  <FieldButton
                    icon="pi pi-angle-right"
                    className="white border-none"
                    disabled
                    onClick={(e) => {
                      onNextClaMesChange(ClaMes);
                    }}
                  />
                </>
              ) : (
                <>
                  <FieldButton
                    icon="pi pi-angle-right"
                    className="white border-none"
                    onClick={(e) => {
                      onNextClaMesChange(ClaMes);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="p-panel-icons">
          <FieldButton className={options.togglerClassName} onClick={options.onTogglerClick}>
            <span className={toggleIcon}></span>
          </FieldButton>
        </div>
      </div>
    );
  };

  const getYears = (ClaAnio) => {
    const paramsService = {
      MostrarSoloCerrados: 1,
    };

    const GetAniosScoreCard = `${config.UrlApiProject}ScoreCard/GetAniosScoreCard`;

    callApi(GetAniosScoreCard, 'Post', paramsService, (response) => {
      setOpcionesAnios(response.data.Anios);
      getMeses(ClaAnio);
    });
  };

  const getMeses = (ClaAnio) => {
    const paramsService = {
      ClaAnio,
      MostrarSoloCerrados: 1,
    };

    const GetMesesScoreCard = `${config.UrlApiProject}ScoreCard/GetMesesScoreCard`;

    callApi(GetMesesScoreCard, 'Post', paramsService, (response) => {
      setOpcionesMeses(response.data.Meses);
      setUltimoClaMesCerrado(getUltimoMesCerrado(response.data.Meses));

      if (ClaMes > getUltimoMesCerrado(response.data.Meses) || ClaMes === 0 || ClaMes === null) {
        setClaMes(getUltimoMesCerrado(response.data.Meses));
      } else if (response.data.Meses.length > 0) {
        if (response.data.Meses[0].ClaMes > ClaMes) {
          setClaMes(response.data.Meses[0].ClaMes);
        }
      }

      if (response.data.Meses.length > 0) {
        setPrimerClaMes(response.data.Meses[0].ClaMes);
      }
    });
  };

  const onAnioChange = (value) => {
    setClaAnio(value);
    getMeses(value);
    ParamScoreLlenado(EsMostrarIndividual, EsMostrarTeam, EsMostrarProjectAssesment, EsMostrarVerticalMeeting, EsMostrarTeamMeeting, value, ClaMes);
  };

  const onClaMesChange = (value) => {
    setClaMes(value);
    ParamScoreLlenado(EsMostrarIndividual, EsMostrarTeam, EsMostrarProjectAssesment, EsMostrarVerticalMeeting, EsMostrarTeamMeeting, ClaAnio, value);
  };

  const getUltimoMesCerrado = (meses) => {
    let ClaMes = 0;

    for (let index = 0; index < meses.length; index++) {
      ClaMes = meses[index].ClaMes;
    }
    
    return ClaMes;
  };

  const onNextClaMesChange = (ClaMes) => {
    setClaMes(ClaMes + 1);
    ParamScoreLlenado(EsMostrarIndividual, EsMostrarTeam, EsMostrarProjectAssesment, EsMostrarVerticalMeeting, EsMostrarTeamMeeting, ClaAnio, ClaMes + 1);
  };

  const onBeforeClaMesChange = (ClaMes) => {
    if (ClaMes > PrimerClaMes) {
      setClaMes(ClaMes - 1);
      ParamScoreLlenado(EsMostrarIndividual, EsMostrarTeam, EsMostrarProjectAssesment, EsMostrarVerticalMeeting, EsMostrarTeamMeeting, ClaAnio, ClaMes - 1);
    }
  };
  const onChangeMostrarIndividual = (props, e) => {
    // setEsMostrarIndividual(e.checked ? 1 : 0);
    setEsMostrarIndividual(e.checked);
    ParamScoreLlenado(e.checked, EsMostrarTeam, EsMostrarProjectAssesment, EsMostrarVerticalMeeting, EsMostrarTeamMeeting, ClaAnio, ClaMes);
  };
  const onChangeMostrarTeam = (props, e) => {
    // setEsMostrarTeam(e.checked ? 1 : 0);
    setEsMostrarTeam(e.checked);
    ParamScoreLlenado(EsMostrarIndividual, e.checked, EsMostrarProjectAssesment, EsMostrarVerticalMeeting, EsMostrarTeamMeeting, ClaAnio, ClaMes);
  };
  const onChangeMostrarProjectAssesment = (props, e) => {
    // setEsMostrarProjectAssesment(e.checked ? 1 : 0);
    setEsMostrarProjectAssesment(e.checked);
    ParamScoreLlenado(EsMostrarIndividual, EsMostrarTeam, e.checked, EsMostrarVerticalMeeting, EsMostrarTeamMeeting, ClaAnio, ClaMes);
  };
  const onChangeMostrarVerticalMeeting = (props, e) => {
    // setEsMostrarVerticalMeeting(e.checked ? 1 : 0);
    setEsMostrarVerticalMeeting(e.checked);
    ParamScoreLlenado(EsMostrarIndividual, EsMostrarTeam, EsMostrarProjectAssesment, e.checked, EsMostrarTeamMeeting, ClaAnio, ClaMes);
  };
  const onChangeMostrarTeamMeeting = (props, e) => {
    // setEsMostrarTeamMeeting(e.checked ? 1 : 0);
    setEsMostrarTeamMeeting(e.checked);
    ParamScoreLlenado(EsMostrarIndividual, EsMostrarTeam, EsMostrarProjectAssesment, EsMostrarVerticalMeeting, e.checked, ClaAnio, ClaMes);
  };
  const RefrescarInformacion = () => { 
    ParamScoreLlenado(EsMostrarIndividual, EsMostrarTeam, EsMostrarProjectAssesment, EsMostrarVerticalMeeting, EsMostrarTeamMeeting, ClaAnio, ClaMes);
  }

  const ParamScoreLlenado = (pIndividual, pTeam, pProjectAssesment, pVerticalMeeting, pTeamMeeting, pClaAnio, pClaMes) => {
    const filtersData = {};   
    const arrayIndicadores = [];

    if (pIndividual) {
      arrayIndicadores.push(1);  
    }
    if (pTeam) {
      arrayIndicadores.push(2);  
    }
    if (pProjectAssesment) {
      arrayIndicadores.push(3);  
    }
    if (pVerticalMeeting) {
      arrayIndicadores.push(4);  
    }
    if (pTeamMeeting) {
      arrayIndicadores.push(5);  
    }     

    filtersData.Indicadores = arrayIndicadores;
    filtersData.ClaAnio = pClaAnio;
    filtersData.ClaMes = pClaMes;


    setfilters(filtersData);
  }

  const EsKPIsVisibles = () => {
     
    if (NivelPuesto === 1 || NivelPuesto === 2 || EsAdmin === 1){
      return true;
    }

    return false;
  } 
  
  return (
    <>
      <PageContent title={t('organizationalChart:TitleOrgChartScreen')}>   
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
            <Panel
              headerTemplate={panelMonthHeaderTemplate}
              className="panel-skyblue"
              collapsed={false}
              toggleable
            > 
              <Row hidden={!EsKPIsVisibles()}>
                <Col lg={2} md={2} sm={12} xs={12} className="p-field-checkbox">
                  
                  <FieldCheckbox
                    inputId={EsMostrarIndividual}
                    checked={EsMostrarIndividual}
                    onChange={(e) => onChangeMostrarIndividual(props, e)}
                  />
                  <label htmlFor={EsMostrarIndividual}>Individual</label>
                </Col>
                <Col lg={2} md={2} sm={12} xs={12} className="p-field-checkbox">
                  <FieldCheckbox
                    inputId={EsMostrarTeam}
                    checked={EsMostrarTeam}
                    onChange={(e) => onChangeMostrarTeam(props, e)}
                  />
                  <label htmlFor={EsMostrarIndividual}>Team</label>
                </Col>
                <Col lg={2} md={2} sm={12} xs={12} className="p-field-checkbox">
                  <FieldCheckbox
                    inputId={EsMostrarProjectAssesment}
                    checked={EsMostrarProjectAssesment}
                    onChange={(e) => onChangeMostrarProjectAssesment(props, e)}
                  />
                  <label htmlFor={EsMostrarIndividual}>Project Assesment</label>
                </Col>
                <Col lg={3} md={3} sm={12} xs={12} className="p-field-checkbox">
                  <FieldCheckbox
                    inputId={EsMostrarVerticalMeeting}
                    checked={EsMostrarVerticalMeeting}
                    onChange={(e) => onChangeMostrarVerticalMeeting(props, e)}
                  />
                  <label htmlFor={EsMostrarIndividual}>Vertical Meeting Accomplishment</label>
                </Col>
                <Col lg={3} md={3} sm={12} xs={12} className="p-field-checkbox">
                  <FieldCheckbox
                    inputId={EsMostrarTeamMeeting}
                    checked={EsMostrarTeamMeeting}
                    onChange={(e) => onChangeMostrarTeamMeeting(props, e)}
                  />
                  <label htmlFor={EsMostrarIndividual}>Team Meeting Accomplishment</label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <OrganizationalChart filters={filters}></OrganizationalChart>
                </Col>
              </Row>
            </Panel>
          </Col>
        </Row>
      </PageContent>
    </>
  );
};

export default OrganizationalScoreSceen;

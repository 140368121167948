/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next';   
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row,Col,Card, CardHeader, CardTitle, CardBody} from 'reactstrap';   
import { config } from '../../../utils/config';
import { callApi,showSweetAlert, tracker} from '../../../utils/utils.js'; 
import { Badge } from 'primereact/badge';
import moment from "moment"; 
import FasesActividadesAnexo from "../Projects/FasesActividadesAnexo.jsx"
import FasesActividadesComentario from "../Projects/FasesActividadesComentario.jsx"
import MisActividadesRevision from "views/ScoreCard/MyActivities/MisActividadesRevision.jsx"
import FieldDataView  from 'components/Controls/FieldDataView/FieldDataView.jsx' 
import FieldToogleSwitch from 'components/Controls/FieldToogleSwitch/FieldToogleSwitch.jsx';
import { Dialog } from 'primereact/dialog'; 
import MisActividadesFinalizar from "./MisActividadesFinalizar.jsx";
import ActionPlanQuickview from '../Scorecard/ActionPlans/ActionPlanQuickview.jsx';
import AnexoEntregableActividad from '../Projects/AnexoEntregableActividad.jsx';
import FasesActividades from '../Projects/FasesActividades.jsx';
import { Link } from "react-router-dom";

const MisActividadesTerminadas = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common','misActividades']);
  const [errors, setErrors] = useState({}); 
  const [ActividadesTerminadas, setActividadesTerminadas] = useState([]); 
  const [layout, setLayout] = useState('grid');
  const [EsVerAnexo, setEsVerAnexo] = useState(false); 
  const [EsTerminarActividad, setEsTerminarActividad] = useState(false); 
  const [EsUltimos30Dias, setEsUltimos30Dias] = useState(false); 
  const [EsSinRevisar, setEsSinRevisar] = useState(false); 
  const [EsAbrirNotas, setEsAbrirNotas] = useState(false); 
  const [ClaProyecto, setClaProyecto] = useState(null); 
  const [ClaFase, setClaFase] = useState(null); 
  const [ClaActividad, setClaActividad] = useState(null); 
  const [ActividadDescripcion, setActividadDescripcion] = useState(null); 
  const [EsRevisarActividad, setEsRevisarActividad] = useState(false);
  const [EsEditarActividadProyecto, setEsEditarActividadProyecto] = useState(false); 
  const editProjectLnk = useRef(null); 

  const [EsAbrirActionPlan, setEsAbrirActionPlan] = useState(false); 
  const [ClaPeriodo, setClaPeriodo] = useState(null); 
  const [ClaFechaReporte, setClaFechaReporte] = useState(null); 
  const [IdCSF, setIdCSF] = useState(null); 
  const [IdProyectoRepActTerminada, setIdProyectoRepActTerminada] = useState(null); 
  const [EsVerAnexoEntreable, setEsVerAnexoEntreable] = useState(false);  

  useEffect(() => {    
    obtenerMisActividadesTerminadas(EsUltimos30Dias, EsSinRevisar);
  }, [props.filters]);


  const obtenerMisActividadesTerminadas = (EsUltimos30Dias, EsSinRevisar ) => { 
    const GetActividadesTerminadas = `${config.UrlApiProject}ScoreCard/GetProyectoSeguimientoTerminadas`;  
    const paramsToService = {
      ...props.filters,
      // ClaFase:null,
      ClaActividad:null, 
      EsUltimos30Dias,
      EsSinRevisar
    };   
    callApi(GetActividadesTerminadas, 'POST', paramsToService, (response) => { 
      setActividadesTerminadas(response.data.ProyectoActividadesTerminadas);
    });
  }

  const LabelTypeActivity = (data)=> {
    let labelTypeActivity = "";
    switch (data.ActivityType) {
      case 1:
        labelTypeActivity = t('misActividades:Project')
        break;
      case 2:       
      case 3:        
      case 4:
        labelTypeActivity = t('misActividades:Source')
        break;
      default:
        labelTypeActivity = t('misActividades:Project')
        break;
    }
    return labelTypeActivity;
  }
  const renderGridItem = (data) => {
    return (
      <div className="p-col-12">
        <div className="dataview-content"> 
          <Row className="">
            <Col lg={8} md={8} sm={12} xs={12} className="bold"> 
              {
                  data.ActivityType === 1 ? (
                    <span className="bold">{`${ LabelTypeActivity(data)}`}: 
                    &nbsp; 
                      {
                      data.EsEnProyectoMaestro === 1 ? (
                        <span className="btn-inner--icon"><i
                          title={t('misActividades:MasterProjectAward')} 
                          className="fa fa-award purple-color"
                        />
                        </span>
                    ) : ''
}                       
                      <a 
                        className='Cursor overflow-ellipsis' 
                        style={{color:'#5e72e4'}} 
                        onClick={()=> onProjectClick(data.ClaProjectRel)}
                      >
                        {data.ClaProjectRel} - {data.NomProyecto}
                      </a>  
                    </span>
):<span className="bold">{data.NomProyecto}</span>
              }
            </Col>
            <Col lg={4} md={4} sm={12} xs={12} className="text-right">
              <small className="bold">{t('misActividades:CreatedBy')}:</small> &nbsp; 
              <small> {data.NomCreatedBy} </small>
            </Col>
          </Row>
          <Row className="">
            <Col lg={8} md={8} sm={12} xs={12} className="">              
              {
                data.ActivityType === 1 ? <span className="bold">{t('misActividades:Phase')}: </span> : <span className="bold">{t('misActividades:Source')}: </span>
              }
              &nbsp; 
              <span className="secondary-color"> 
                {
                  data.ActivityType !== 1 ? `${data.NomTipoIndicador === null ? '' : `${data.NomTipoIndicador} -` } ${data.ClaProjectRel === null ? '' : data.ClaProjectRel} ${data.ClaProjectRel !== null ? '-' : ''} ${data.NomFase}` : data.NomFase 
              }
              </span>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12} className="text-right">
              <small className="bold">{t('misActividades:Responsible')}:</small> &nbsp; <small> {data.NomResponsableActividad} </small>
            </Col>
          </Row>
          <Row className="">
            <Col lg={12} md={12} sm={12} xs={12} className="">
              <span className="bold">{t('misActividades:Activity')}: </span> 
              {
                data.ActivityType === 1 && data.EsPM === 1 ? 
                (<span className='Cursor' onClick={() => AbrirEdicionActividad(data)}><span className="fa fa-pencil-alt"></span>&nbsp;{data.NomActividad}</span>)
                :
                (<>&nbsp;{data.NomActividad}</>)
              }

              {
                data.FechaReporte !== null ? (<div><span className="bold">{t('misActividades:ReportedDate')}: </span><small className="info-color bold"><i className="pi pi-calendar"></i> &nbsp;{moment(data.FechaReporte).format("ll")}</small></div>) : (<></>)
              } 
              <div>
                <span><b>{t('misActividades:FechaTerminada')}:</b></span> <small>{moment(data.FechaRealTermina).format("ll")}</small>
                 
              </div>
            </Col> 
          </Row>
          <Row className="align-items-start">
            <Col lg={6} md={6} sm={12} xs={12}>
              <span className="bold">{t('misActividades:Period')}: </span>&nbsp;
              <small className="info-color bold"> 
                <i className="pi pi-calendar"></i> &nbsp; 
                {moment(data.FechaInicia).format("ll")} - &nbsp; 
                {moment(data.FechaEstimadaTermina).format("ll")}
              </small>
              { 
                data.DiasAtraso > 0 ? 
                (<div> <span className="bold"> <i className="fas fa-exclamation-triangle warning-color bold"></i> </span>&nbsp;<small className="warning-color bold"> {data.DiasAtraso}&nbsp;{t('misActividades:DaysDelayed')} </small></div>)
                :
                (<></>)
              }
            </Col>
            <Col lg={6} md={6} sm={12} xs={12} className="text-right">
              {
               data.IdCSF !== null ?
               (<><FieldButton icon="fas fa-flag-checkered" tooltip={t('misActividades:ViewActioPlan')} title={t('misActividades:ViewActioPlan')} onClick={() => onClickActionPlan(data)} className="p-button-rounded p-button-text white" /></>)
               :
               (<></>)  
              }
              { data.ActivityType === 1 ? 
                (<FieldButton icon="fas fa-download" tooltip={t('misActividades:DownloadSupportDocuments')} title={t('misActividades:DownloadSupportDocuments')} onClick={() => AbrirAnexo(data)} className="p-button-rounded p-button-text" />)
                :
                (<></>)} 
              
              { data.EsTieneDocumentoEntregable === 1 ? 
                (<FieldButton icon="fas fa-file-download" tooltip={t('misActividades:DownloadActivityDeliverable')} title={t('misActividades:DownloadActivityDeliverable')} onClick={() => AbrirAnexoEntregable(data)} className="p-button-rounded orange-color p-button-text" />)
                :
                (<></>)}  

              <FieldButton icon="fas fa-comments" tooltip={t('misActividades:AddViewComments')} title={t('misActividades:tooltipComentarios')} onClick={() => AbrirNotas(data)} className={`p-button-rounded p-button-text ${ data.EsTieneComentarios === 1 ? 'light-yellow-color' : 'light-gray-color' }`} />
              <FieldButton icon="fas fa-edit" tooltip={t('misActividades:EditTask')} onClick={() => AbrirTerminarActividad(data)} className="p-button-rounded p-button-primary p-button-text" />
              { data.ClaProyecto === -997 || data.ClaProyecto === -998 ? (<></>) 
                :  
                (<FieldButton icon="fas fa-user-check" tooltip={t('misActividades:ReviewTask')} title={t('misActividades:MarkActivityAsReviewed')} onClick={() => AbrirRevisionActividad(data)} className="p-button-rounded p-button-success p-button-text" />)}
            </Col>
          </Row> 
          <Row className="">
            <Col lg={12} md={12} sm={12} xs={12}>
              <div className="border-color-light-green" style={{ borderBottom: "1px solid"}}></div>
            </Col>
          </Row> 
        </div>  
      </div>
    );
  }
  
  const itemTemplate = (product, layout) => {
    if (!product) {
        return;
    }
    if (layout === 'grid')
        return renderGridItem(product);
  }

  const AbrirAnexo = async (data) => {
    setActividadDescripcion(data.Descripcion);
    setClaProyecto(data.ClaProyecto);
    setClaFase(data.ClaFase);
    setClaActividad(data.ClaActividad);
    setEsVerAnexo(!EsVerAnexo);
    tracker.trackEvent({
      type: 'MisActividadesTerminadas', /* component name */
      action: 'VIEW_SUPPORT_DOCUMENTS' 
    })
}

const AbrirAnexoEntregable = async (data) => { 
  setIdProyectoRepActTerminada(data.IdProyectoRepActTerminada);
  setEsVerAnexoEntreable(!EsVerAnexoEntreable);
}
  
const AbrirTerminarActividad = async (data) => {
  setClaProyecto(data.ClaProyecto);
  setClaFase(data.ClaFase);
  setClaActividad(data.ClaActividad);
  setEsTerminarActividad(!EsTerminarActividad);
  tracker.trackEvent({
    type: 'MisActividadesTerminadas', /* component name */
    action: 'EDIT_ACTIVITY_FINISHED'
  }) 
}

const AbrirRevisionActividad = async (data) => {
  setClaProyecto(data.ClaProyecto);
  setClaFase(data.ClaFase);
  setClaActividad(data.ClaActividad);
  setEsRevisarActividad(!EsRevisarActividad);
  tracker.trackEvent({
    type: 'MisActividadesTerminadas', /* component name */
    action: 'OPEN_REVIEW_ACIVITY'
  }) 
}

const AbrirNotas = async (data) => {
  setClaProyecto(data.ClaProyecto);
  setClaFase(data.ClaFase);
  setClaActividad(data.ClaActividad);
  setEsAbrirNotas(!EsAbrirNotas);
  tracker.trackEvent({
    type: 'MisActividadesTerminadas', /* component name */
    action: 'VIEW_COMMENTS' 
  })
}

const AbrirEdicionActividad = async (data) => { 
  setClaProyecto(data.ClaProyecto);
  setClaFase(data.ClaFase);
  setClaActividad(data.ClaActividad);

  setEsEditarActividadProyecto(!EsEditarActividadProyecto);
  tracker.trackEvent({
    type: 'MisActividadesTerminadas', /* component name */
    action: 'VIEW_ACTIVITY_DETAIL'
  })
}



const closeModalNotas = () => {
  setEsAbrirNotas(false); 
} 

const closeModalTerminarActividad = () => {

 setEsTerminarActividad(false); 
} 

const closeModalRevisionActividad = () => {

  setEsRevisarActividad(false); 

  if (props.onChange !== null && props.onChange !== 'undefined' && typeof(props.onChange) === 'function'){
    props.onChange();
  }
 } 


const closeModal = () => {
 setEsVerAnexo(false);
 setActividadDescripcion(null);
} 

const closeModalAnexoEntreable = () =>
{
  setEsVerAnexoEntreable(false);
  setIdProyectoRepActTerminada(null);
}

const dialogStyle  = ( 
    { 
    width: '80vw'
  
})

const onClickCloseComentario = () => {
 closeModalNotas();
 obtenerMisActividadesTerminadas(EsUltimos30Dias, EsSinRevisar);
};

const onClickCloseFinalizar = () => {
  closeModalTerminarActividad();

  if (props.onChange !== null && props.onChange !== 'undefined' && typeof(props.onChange) === 'function'){
    props.onChange();
  }
};

const onCloseActivityDetail = () => {
    
  setClaProyecto(null);
  setClaFase(null);
  setClaActividad(null);
  setEsEditarActividadProyecto(false); 
  obtenerMisActividadesTerminadas(EsUltimos30Dias, EsSinRevisar);
  if (props.onChange !== null && props.onChange !== 'undefined' && typeof(props.onChange) === 'function'){
    props.onChange();
  }
};


const headerTerminarActividad = () => {

  if (ClaProyecto>0){
    return (<>{t('misActividades:TituloFinalizarTareaProyecto')}</>);
  }

  if (ClaProyecto===-999){
    return (<>{t('misActividades:TituloFinalizarTareaMinuta')}</>);
  }

  if (ClaProyecto===-998){
    return (<>{t('misActividades:TituloFinalizarTareaAP')}</>);
  }

  if (ClaProyecto===-997){
    return (<>{t('misActividades:TituloFinalizarTareaSporteProgress')}</>);
  }

  return (<>{t('misActividades:TituloFinalizarActividad')}</>);
  
}


const onChangeEsUltimos30Dias = (value) => {   
  setEsUltimos30Dias(value) 
  obtenerMisActividadesTerminadas(value, EsSinRevisar);
}


const onChangeEsSinRevisar = (value) => {   
  setEsSinRevisar(value) 
  obtenerMisActividadesTerminadas(EsUltimos30Dias, value);
}

const onClickActionPlan = (row) => {  
  setClaPeriodo(row.ClaPeriodo);
  setClaFechaReporte(row.ClaFechaReporte);
  setIdCSF(row.IdCSF);
  setEsAbrirActionPlan(true);
}

const onHideActionPlan = () => {
  setEsAbrirActionPlan(false); 
}

const onProjectClick = (claProyecto) => {   
  tracker.trackEvent({
    type: 'MisActividadesTerminadas', /* component name */
    action: 'OPEN_EDIT_PROJECT'
  }) 
  if (props.onOpenProject) props.onOpenProject(claProyecto);
};

    return (
      <>      
        <Row className="pt-3">
          <Col lg={12} md={12} sm={12} xs={12}>
            <Card>
              <CardHeader className="light-green">
                <CardTitle>
                  <Row className="pt-2">
                    <Col lg={3} md={3} sm={3} xs={3}>
                      {t('misActividades:FinishedTasks')}
                    </Col>
                    <Col className="text-right">
                      <Badge
                        value={ActividadesTerminadas.length}
                        className="light-green-dark"
                        size="large"
                      />  
                    </Col>
                  </Row> 
                  <Row className="pt-2">
                    <Col lg={3} md={3} sm={3} xs={3}>
                      <small className="white-color bold"> 
                        <span>
                          {t('misActividades:Last30Days')}
                        </span> 
                      </small> &nbsp;
                      <FieldToogleSwitch
                        id="EsUltimos30Dias"
                        className="white-color"
                        checked={EsUltimos30Dias}
                        onChange={onChangeEsUltimos30Dias}  
                        small
                      />
                    </Col>
                    <Col className="text-right">
                      <small className="white-color bold" style={{}}> 
                        <span>
                          {t('misActividades:SinRevisar')}
                        </span> 
                      </small> &nbsp;
                      <FieldToogleSwitch
                        id="EsSinRevisar"
                        className="white-color"
                        checked={EsSinRevisar}
                        onChange={onChangeEsSinRevisar}  
                        small
                      />
                    </Col>
                  </Row>
                </CardTitle>
              </CardHeader>    
              <CardBody>
    
                <FieldDataView
                  value={ActividadesTerminadas}
                  layout={layout}
                  itemTemplate={itemTemplate}
                  paginator
                  rows={15}
                  className="dataview-list"
                />
                   
              </CardBody>
            </Card>  
          </Col>
        </Row>
        <Dialog
          visible={EsVerAnexo}
          header={t('misActividades:ActivityDocuments')}
          style={dialogStyle} 
          modal
          className="dialog-custom"
          onHide={closeModal}
        >
          <Row>
            <Col>
              <h3>  {ActividadDescripcion} </h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <FasesActividadesAnexo IsUpload={false} IsReadyOnly={EsVerAnexo} ClaProyecto={ClaProyecto} ClaFase={ClaFase} ClaActividad={ClaActividad}  />
            </Col>
          </Row>
        </Dialog>
        <Dialog
          visible={EsTerminarActividad}
          header={headerTerminarActividad}
          style={dialogStyle} 
          modal
          className="dialog-custom"
          onHide={closeModalTerminarActividad}
        >
          <Row className="align-items-start">
            <Col>
              <MisActividadesFinalizar onClickCloseFinalizar={onClickCloseFinalizar} ClaProyecto={ClaProyecto} ClaFase={ClaFase} ClaActividad={ClaActividad}  />
            </Col>
          </Row>
        </Dialog>
        <Dialog
          visible={EsRevisarActividad}
          header={t('misActividades:TituloRevisionActividad')}
          style={dialogStyle} 
          modal
          className="dialog-custom"
          onHide={closeModalRevisionActividad}
        >
          <Row className="align-items-start">
            <Col> 
              <MisActividadesRevision onClickCloseFinalizar={closeModalRevisionActividad} ClaProyecto={ClaProyecto} ClaFase={ClaFase} ClaActividad={ClaActividad}  />
            </Col>
          </Row>
        </Dialog>
        <Dialog
          visible={EsAbrirNotas}
          header={t('misActividades:TituloFaseActividadNotas')}
          style={dialogStyle} 
          modal
          className="dialog-custom"
          onHide={closeModalNotas}
        >
          <Row>
            <Col>
              <FasesActividadesComentario onClickCloseComentario={onClickCloseComentario} ClaProyecto={ClaProyecto} ClaFase={ClaFase} ClaActividad={ClaActividad}  />
            </Col>
          </Row>
        </Dialog> 
        <Dialog
          visible={EsVerAnexoEntreable}
          header={t('misActividades:ActivityDocumentDeliverable')}
          style={dialogStyle} 
          modal
          className="dialog-custom"
          onHide={closeModalAnexoEntreable}
        > 
          <Row>
            <Col>
              <AnexoEntregableActividad IdProyectoRepActTerminada={IdProyectoRepActTerminada} />
            </Col>
          </Row>
        </Dialog>  
        <ActionPlanQuickview show={EsAbrirActionPlan} onHide={onHideActionPlan} IdCSF={IdCSF} ClaPeriodo={ClaPeriodo} ClaFechaReporte={ClaFechaReporte}></ActionPlanQuickview>
        {
          EsEditarActividadProyecto!= null ? (
            <> 
              <FasesActividades IsVisible={EsEditarActividadProyecto} onClickClose={onCloseActivityDetail} ClaProyecto={ClaProyecto} ClaFase={ClaFase} ClaActividad={ClaActividad}  />
            </>
            ):(<></>)
        }
      </> 
        ); 
};
export default MisActividadesTerminadas;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle  } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { PickList } from 'primereact/picklist';
import { TreeList, Scrolling, Paging, Pager, Column, Selection, Format, SearchPanel } from 'devextreme-react/tree-list';
import { config } from '../../../utils/config';
import { callApi, getSessionItem, showSweetAlert, tracker } from '../../../utils/utils';
import moment from 'moment'; 
import { Link } from "react-router-dom";
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import FieldTag from '../../../components/Controls/FieldTag.jsx';
import EdicionProyecto from '../Projects/EdicionProyecto';
import { Dialog } from 'primereact/dialog';

const MisProyectosList = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['misProyectosScreen', 'common']);  
  const [errors, setErrors] = useState({}); 
  const expandedRowKeys = [0];
  const [matriz, setMatriz] = useState([]);    
  const editProjectLnk = useRef(null);   
  const [EsMostrarEditarProyec, setEsMostrarEditarProyec] = useState(false);
  const [ClaProyecto, setClaProyecto] = useState(false);


  useEffect(() => { 
    // Cuando se solicita exportacion se exporta y se filtra el grid....
    
    if (props.filtersData.flagExport === 1) {
      DataBindTree(props.filtersData);
      ExportExcelMisProyectos(props.filtersData);
    }
    else
    {
      DataBindTree(props.filtersData);
    }    
  }, [props.filtersData]);

  const DataBindTree = (paramsToService) => { 
    const GetConsultaProyGral = `${config.UrlApiProject}ScoreCard/GetConsultaProyGral`;    
    if (paramsToService.flagClean === 1) {
      setMatriz([]);
    }else{
      callApi(GetConsultaProyGral, 'POST', paramsToService, (response) => {
        setMatriz(response.data.ConsultaProyGral);
      });
    }   
  };
  
  const ExportExcelMisProyectos = (paramsToService) => { 
    const GetConsultaProyGral = `${config.UrlApiProject}ScoreCard/ExportarMisProyectos`;        
    callApi(GetConsultaProyGral, 'POST', paramsToService, (response) => {
      const linkSource = `data:application/${response.data.FileExtension};base64,${response.data.FileData}`;
      const downloadLink = document.createElement("a");
      const fileName = `${response.data.FileName}`;
      downloadLink.href = linkSource;
      downloadLink.download = `${fileName  }.${  response.data.FileExtension}`;
      downloadLink.click();
    });    
  };
  
  const formatDate = (date) => {
    return moment(date).format("MM/DD/YYYY");
  }
  
  const renderDescription = (row) =>  {   
      
    if (row.data.ClaProyecto === null){
      return (
        <span title={row.data.Descripcion}>
          {row.data.Descripcion}
        </span>
)
    }    

    
    return (  
      <>    
        
        <a className='Cursor overflow-ellipsis' style={{color:'#5e72e4'}} onClick={()=> EditarProyecto(row.data.ClaProyecto)}>{row.data.Descripcion}</a>           
        {/* <Link 
          title={row.data.Descripcion} 
          ref={editProjectLnk} 
          to={{ pathname: "EditProject"
              , search: `?ClaProyecto=${row.data.ClaProyecto}` 
                                        }} 
        >          
          {row.data.Descripcion} 
        </Link>  */}
      </>
     );
  }   

  const EditarProyecto = (pClaProyecto)=>{ 
    setClaProyecto(pClaProyecto);
    setEsMostrarEditarProyec(true);
    tracker.trackEvent({
      type: 'MisProyectosList', /* component name */
      action: 'OPEN_EDIT_PROJECT'
    }) 
  }

  const onHideEditarProyecto = () => {
    setEsMostrarEditarProyec(false);
  }

  const onEditProjectClick = () => {
    editProjectLnk.current.click();
  } 


  const renderType = (row) => {
    return ( 
      <small>
        {row.data.ClaActividad !== null ? 'Activity' : row.data.ClaFase !== null ? 'Phase' : row.data.NomTipoProyecto} 
      </small> 
    )
  }

  
  const renderFooter = () => {
    return (
      <>
        <FieldButton
          label={t('common:Close')}
          icon="fas fa-times"
          className="warning no-border p-mr-2 rounded-button"
          onClick={onHideEditarProyecto}
        />
      </>
    );
  };

  const renderHeader = () => {
    return (
      <div>
        <Row>
          <Col lg={4} md={4} sm={12} xs={12}>            
            <div>
              <span>{t('common:EditProject')}</span>
            </div>
          </Col>          
        </Row>
      </div>
    );
  };

  return (
    <>
      <TreeList
        id="matriz"
        dataSource={matriz}
        rootValue={-1}
        defaultExpandedRowKeys={expandedRowKeys}
        showRowLines={true}
        showBorders={false} 
        height={650} 
        keyExpr="Id"
        parentIdExpr="PadreId"
        noDataText={t('common:NoData')}
        className="TreeListView" 
      >
        <SearchPanel visible={true} width={500} placeholder="Advanced Search" />
        <Selection mode="single" />

        <Scrolling
          mode="standard"
        />
        <Paging
          enabled={true}
          defaultPageSize={150}
        />
        <Pager
          showPageSizeSelector={false}
          showInfo={false}
        />
        <Column 
          fixed={true}  
          caption={t('misProyectosScreen:Type')}
          width={200}   
          cellRender={renderType}          
        >  
        </Column>
        <Column 
          fixed={true} 
          dataField="ClaProyecto"
          caption="#"
          width={40}    
          alignment="center"      
        >  
        </Column>
        <Column 
          fixed={true} 
          dataField="Descripcion"
          caption={t('misProyectosScreen:Descripcion')}   
          cellRender={renderDescription} 
          width={250} 
        >  
        </Column>
        <Column 
          fixed={true} 
          dataField="NomResponsable"
          caption={t('misProyectosScreen:Responsable')}
          width={150}
        >
        </Column>
        <Column  
          fixed={true} 
          dataField="Area"
          caption={t('misProyectosScreen:Area')}
          width={130}
        >
        </Column>
        <Column  
          fixed={true} 
          dataField="Initiative"
          caption={t('misProyectosScreen:Initiative')}
          width={150}
        >
        </Column>
        <Column caption={t('misProyectosScreen:Progress')} alignment="center">
          <Column  
            dataField="PorcAvance"
            caption={t('misProyectosScreen:PorcReal')}
            width={70}
            alignment="center"
          >
            <Format type="percent" precision={0} /> 
          </Column>
          <Column  
            dataField="PorcEstimado"
            caption={t('misProyectosScreen:PorcEst')}
            width={70}
            alignment="center"
          >
            <Format type="percent" precision={0} /> 
          </Column>
        </Column>
        <Column  
          dataField="Estatus"
          caption={t('misProyectosScreen:Status')}
          width={100}
          alignment="center"
        >
        </Column>
        <Column caption="Estimated" alignment="center">
          <Column  
            dataField="FechaIniEst"
            caption="Start"
            width={110}
            dataType="date"
            alignment="center"
          >
            <Format type="shortDate" formatter={formatDate}></Format>
          </Column>
          <Column  
            dataField="FechaFinEst"
            caption="End"
            width={110}
            dataType="date"
            alignment="center"
          >
            <Format type="shortDate" formatter={formatDate}></Format>
          </Column>
        </Column>
        <Column caption={t('misProyectosScreen:Real')} alignment="center">
          <Column  
            dataField="FechaIniReal"
            caption={t('misProyectosScreen:Start')}
            width={110}
            dataType="date"
            alignment="center"
          >
            <Format type="shortDate" formatter={formatDate}></Format>
          </Column>
          <Column  
            dataField="FechaFinReal"
            caption={t('misProyectosScreen:End')}
            width={110}
            dataType="date"
            alignment="center"
          >
            <Format type="shortDate" formatter={formatDate}></Format>
          </Column>
        </Column>
        <Column caption={t('misProyectosScreen:Days')} alignment="center">
          <Column  
            dataField="DiasEst"
            caption={t('misProyectosScreen:DiasEst')}
            width={90}
            alignment="center"
          >
          </Column>
          <Column  
            dataField="DiasReal"
            caption={t('misProyectosScreen:DiasReal')}
            width={80}
            alignment="center"
          >
          </Column>
        </Column>
        <Column  
          dataField="Peso"
          caption={t('misProyectosScreen:Peso')}
          width={90}
          alignment="center"
        >
          <Format type="percent"></Format>   
        </Column> 
      </TreeList>
      <Dialog
        visible={EsMostrarEditarProyec}
        modal
        style={{ width: '100vw' }}
        footer={renderFooter}
        header={renderHeader}
        onHide={onHideEditarProyecto}
        closable={false}
        className="dialog-header-background-blue"
        maximized={true}
        draggable={false}
        blockScroll={true}
      >
        <EdicionProyecto ClaProyecto={ClaProyecto} EsMostrarEditarProyec={EsMostrarEditarProyec}></EdicionProyecto>
      </Dialog>
      
    </>
  );

  
};
export default MisProyectosList;

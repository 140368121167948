/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from '../../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../../utils/utils';
import FieldDataTable from '../../../../components/Controls/FieldDataTable/FieldDataTable.jsx'; 
import FieldText from 'components/Controls/FieldText';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx'; 
import * as yup from 'yup';
import YupValidator from '../../../../utils/YupValidator';
import moment from 'moment'; 
import { Toolbar } from 'primereact/toolbar';
import FieldTextArea from 'components/Controls/FieldTextArea';
import FieldAutoComplete from '../../../../components/Controls/FieldAutoComplete.jsx'; 
import FieldNumber from '../../../../components/Controls/FieldNumber.jsx';
import FieldRadioButton from '../../../../components/Controls/FieldRadioButton/FieldRadioButton.jsx'; 
import ActionPlanAttachment from './ActionPlanAttachment.jsx';
import { Dialog } from 'primereact/dialog';
import { Badge } from 'primereact/badge';
import ActionPlanComments from './ActionPlanComments.jsx';
import { Tooltip } from 'primereact/tooltip';
import Highcharts from 'highcharts'; 
import InstructionsCard from '../../../../components/Cards/InstructionsCard/InstructionsCard.jsx';
import { Steps } from 'primereact/steps';
import FieldSelectButton from '../../../../components/Controls/FieldSelectButton.jsx'  
import ActionPlanQuickview from './ActionPlanQuickview.jsx';
import FieldCheckbox from '../../../../components/Controls/FieldCheckbox.jsx';
import KPIObjectivesDetail from '../FocusReport/KPIObjectivesDetail.jsx';

const ActionPlan = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['actionPlan', 'common']);
  const [errors, setErrors] = useState({});

  // ActionPlan
  const [filteredResponsible, setfilteredResponsible] = useState([]);
  const [CausaRaizList, setCausaRaizList] = useState([]);
  const [ResponsablesList, setResponsablesList] = useState([]);
  const [HistorialPendientesList, setHistorialPendientesList] = useState([]);
  const [HistorialPendientesPrevios, setHistorialPendientesPrevios] = useState([]);
  const [PickOneVal, setPickOneVal] = useState(null);
  const [ResFecha, setResFecha] = useState(null);
  const [NombreLider, setNombreLider] = useState(null);
  const [TopicoAnalisis, setTopicoAnalisis] = useState(null);
  const [CurrRes, setCurrRes] = useState(null);
  const [PlanRes, setPlanRes] = useState(null);
  const [ExplicacionResultado, setExplicacionResultado] = useState(null);

  const [IdPlanAccion, setIdPlanAccion] = useState(null);
  const [IdCausa, setIdCausa] = useState(null);
  const [IdCausaAux, setIdCausaAux] = useState(null);
  const [DescCausaRaiz, setDescCausaRaiz] = useState(null);
  const [EsVerAnexo, setEsVerAnexo] = useState(false);
  const [EsAbrirNotas, setEsAbrirNotas] = useState(false);
  const [RowDataNotas, setRowDataNotas] = useState(null);
  const [ActionPlanFileList, setActionPlanFileList] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [headerColor, setHeaderColor] = useState('');
  const [colorEstatus, setColorEstatus] = useState(0);
  const [EsMostrarSoloUsuarioMSW, setEsMostrarSoloUsuarioMSW] = useState(false);
  
  // End ActionPlan

  const [ClaPeriodo, setClaPeriodo] = useState(null);
  const [ClaFechaReporte, setClaFechaReporte] = useState(null);
  const [IdCSF, setIdCSF] = useState(null);
  const [PosicionesRelacionadasList, setPosicionesRelacionadasList] = useState([]);
  const [ComposicionList, setComposicionList] = useState([]);
  const [CausaRaiz, setCausaRaiz] = useState(null);
  const [OpcionesCausaRaiz, setOpcionesCausaRaiz] = useState([{ value: 1, name: t('actionPlan:Yes')}, { value: 0, name: t('actionPlan:No') }]);
  const [AgregarAcciones, setAgregarAcciones] = useState(null);
  const [OpcionesAgregarAcciones, setOpcionesAgregarAcciones] = useState([{ value: 1, name: t('actionPlan:Yes')}, { value: 0, name: t('actionPlan:No') }]);
  const [CrearActionPlan, setCrearActionPlan] = useState(null);
  const [OpcionesCrearActionPlan, setOpcionesCrearActionPlan] = useState([{ value: 1, name: t('actionPlan:Yes')}, { value: 0, name: t('actionPlan:No') }]);
  
 
  /* ACTION PLAN CHILDS */
  const [EsReviewActionPlan, setEsReviewActionPlan] = useState(false); 
  const [ClaPeriodoSel, setClaPeriodoSel] = useState(null); 
  const [ClaFechaReporteSel, setClaFechaReporteSel] = useState(null); 
  const [IdCSFSel, setIdCSFSel] = useState(null); 
  const [IsAtLeastOneActionPlanOpen, setIsAtLeastOneActionPlanOpen] = useState(false);
  const [showAsEmptyActionPlan, setShowAsEmptyActionPlan] = useState(false);
  const [ActionPlanList, setActionPlanList] = useState([]);
  const [EsMostrarDetalleObjetivos, setEsMostrarDetalleObjetivos] =  useState(false);
  const [KPISelected, setKPISelected] = useState({});

  const onHideActionPlanReview = () => {
    setEsReviewActionPlan(false); 
    setIsAtLeastOneActionPlanOpen(true); 
  }

  useEffect(() => {
    setErrors({});

    setIdCausa(null);
    setPickOneVal(null);
    setIdCausaAux(null);
    setClaPeriodo(props.ClaPeriodo === undefined ? 0 : props.ClaPeriodo); 
    setClaFechaReporte(props.ClaFechaReporte === undefined ? 0 : props.ClaFechaReporte);
    setIdCSF(props.IdCSF === undefined ? 0 : props.IdCSF);
    obtenerEncabezadoActionPlan(
      props.ClaPeriodo === undefined ? 0 : props.ClaPeriodo,
      props.IdCSF === undefined ? 0 : props.IdCSF,
      props.ClaFechaReporte === undefined ? 0 : props.ClaFechaReporte
    ); 

    if (props.show) {
      document.body.classList.add('bodynotscroll');
    }
  }, [props.IdCSF, props.ClaPeriodo, props.ClaFechaReporte, props.show]);

  const obtenerEncabezadoActionPlan = (ClaPeriodo, IdCSF, ClaFechaReporte) => {
    const GetEncabezado = `${config.UrlApiProject}ScoreCard/GetEncabezadoActionPlan`;
    const paramsToService = {
      IdCSF,
      ClaPeriodo,
      ClaFechaReporte,
      IdPlanAccion: null,
    };

    callApi(GetEncabezado, 'POST', paramsToService, (response) => {
      if (response.data.EncabezadoActionPlan.length > 0) {
        setResFecha(response.data.EncabezadoActionPlan[0].ResFecha);
        setNombreLider(response.data.EncabezadoActionPlan[0].Leader);
        setTopicoAnalisis(response.data.EncabezadoActionPlan[0].Topico);
        setCurrRes(response.data.EncabezadoActionPlan[0].CurrRes);
        setPlanRes(response.data.EncabezadoActionPlan[0].PlanRes);
        setExplicacionResultado(response.data.EncabezadoActionPlan[0].ExplicacionResultado);
        setIdPlanAccion(response.data.EncabezadoActionPlan[0].IdPlanAccion);
        setCausaRaiz(response.data.EncabezadoActionPlan[0].EsCausaRaizRevisadas);
        setAgregarAcciones(response.data.EncabezadoActionPlan[0].EsAccionesRevisadas);
        setCrearActionPlan(response.data.EncabezadoActionPlan[0].EsCrearPropioActionPlan);
         
        // ObtenerCausaRaizActionPlan(response.data.EncabezadoActionPlan[0].IdPlanAccion);
        bindCausaRaiz(response.data.CausaRaiz);

        // ObtenerResponsablesActionPlan(response.data.EncabezadoActionPlan[0].IdPlanAccion);
        bindResponsables(response.data.Responsables);

        /*
        ObtenerHistorialPendientesActionPlan(
          IdCSF,
          response.data.EncabezadoActionPlan[0].IdPlanAccion
        );
        */
       setHistorialPendientesList(response.data.HistorialPendientes);
       setHistorialPendientesPrevios(response.data.HistorialPendientesPrevios);
        
        // obtenerInformacionActionPlanFile(IdCSF, ClaPeriodo, ClaFechaReporte);
        setActionPlanFileList(response.data.ActionPlanFile);
        setPosicionesRelacionadasList(response.data.PosicionesRelacionadas);
        setComposicionList(response.data.Composicion);
         
        setActionPlanList([...response.data.PosicionesRelacionadas, ...response.data.Composicion]);
 
        setActiveIndex(response.data.EncabezadoActionPlan[0].IdPlanAccion === null ? 0 : null);
        

        // Cuando es nuevo pero no y esta configurado como si requiere administracion y seguimiento el KPI
        if (response.data.EncabezadoActionPlan[0].IdPlanAccion === null && ( response.data.PosicionesRelacionadas.length > 0 || response.data.Composicion.length > 0))
        { 
          setActiveIndex(-1);
        }
        
        // si se creo un action plan y solo se marco como revisado mostramos la pantalla inicializad en el tab de revision de KPIs
        if (
            ( response.data.EncabezadoActionPlan[0].IdPlanAccion > 0 && response.data.EncabezadoActionPlan[0].EsCrearPropioActionPlan === 0)
            && ( response.data.PosicionesRelacionadas.length > 0 || response.data.Composicion.length > 0)
        )
        { 
          setActiveIndex(-1);
          setShowAsEmptyActionPlan(true);
        }
  
        if (response.data.EncabezadoActionPlan[0].ColorEstatus === null) {
          if (response.data.EncabezadoActionPlan[0].PlanAccionBandera === 1) {
            response.data.EncabezadoActionPlan[0].ColorEstatus = 1; // rojo por que detectamos la bandera roja
          }
          else {
            response.data.EncabezadoActionPlan[0].ColorEstatus = 3; // verde por que puede estar mal configurado el KPI y no supimos calcularlo
          }
        }
        /*
          1: Rojo
          2: Amarillo
          3: Verde
        */
        setColorEstatus(response.data.EncabezadoActionPlan[0].ColorEstatus);
        setHeaderColor('');
        if (response.data.EncabezadoActionPlan[0].ColorEstatus === 1) {
          setHeaderColor('table-header-background-red');
        }
        if (response.data.EncabezadoActionPlan[0].ColorEstatus === 2) {
          setHeaderColor('table-header-background-yellow');
        }
      }
    });
  };
  const obtenerPosicionesRelacionadas = (ClaPeriodo, IdCSF, ClaFechaReporte, pClaPuesto) => {
    const getKPIPosicionesRelacionadas = `${config.UrlApiProject}ScoreCard/GetKPIPosicionesRelacionadas`;
    const paramsToService = {
      IdCSF,
      ClaPeriodo,
      ClaFechaReporte,
      IdPlanAccion: null 
    };
    callApi(getKPIPosicionesRelacionadas, 'POST', paramsToService, (response) => {
      setPosicionesRelacionadasList(response.data.PosicionesRelacionadas);
    });
  };

  const obtenerComposicion = (ClaPeriodo, IdCSF, ClaFechaReporte, pClaPuesto) => {
    const getKPIComposicion = `${config.UrlApiProject}ScoreCard/GetKPIComposicion`;
    const paramsToService = {
      IdCSF,
      ClaPeriodo,
      ClaFechaReporte,
      IdPlanAccion: null 
    };
    callApi(getKPIComposicion, 'POST', paramsToService, (response) => {
      setComposicionList(response.data.Composicion);
    });
  };

  const ObtenerCausaRaizActionPlan = (idPlanAccion) => {
    const GetCausaRaizActionPlan = `${config.UrlApiProject}ScoreCard/GetCausaRaizActionPlan`;
    const paramsToService = {
      IdPlanAccion: idPlanAccion,
    };
    callApi(GetCausaRaizActionPlan, 'POST', paramsToService, (response) => {
      // setCausaRaizList(response.data.CausaRaiz);
      bindCausaRaiz(response.data.CausaRaiz);
    });
  };

  const ObtenerResponsablesActionPlan = (idPlanAccion) => {
    const GetResponsablesActionPlan = `${config.UrlApiProject}ScoreCard/GetResponsablesActionPlan`;
    const paramsToService = {
      IdPlanAccion: idPlanAccion,
    };
    callApi(GetResponsablesActionPlan, 'POST', paramsToService, (response) => {
      bindResponsables(response.data.Responsables);
      // setResponsablesList(response.data.Responsables);
    });
  };
  const obtenerInformacionActionPlanFile = (pIdCSF, pClaPeriodo, pClaFechaReporte) => {
    const GetActionPlanFile = `${config.UrlApiProject}ScoreCard/GetActionPlanFile`;
    const paramsToService = {
      IdCSF: pIdCSF,
      ClaPeriodo: pClaPeriodo,
      ClaFechaReporte: pClaFechaReporte,
    };
    callApi(GetActionPlanFile, 'POST', paramsToService, (response) => {
      setActionPlanFileList(response.data.ActionPlanFile);
    });
  };
  const bindResponsables = (resposibleList) => {
    const arrayResponsable = [];
    let RowValues = {};
    for (let i = 0; i < resposibleList.length; i++) {
      RowValues = {
        ...resposibleList[i],
        RowIndex: i,
        BajaLogica: 0,
        ClaColaborador: {
          ClaColaborador: resposibleList[i].cClaColaborador,
          NomColaborador: resposibleList[i].NomColaborador,
        },
      };
      arrayResponsable.push(RowValues);
    }
    setResponsablesList(arrayResponsable);
  };

  const bindCausaRaiz = (CausaRaizList) => {
    for (let i = 0; i < CausaRaizList.length; i++) {
      if (CausaRaizList[i].EsSeleccionada === 1) {
        setIdCausaAux(CausaRaizList[i].IdCausa);
        setPickOneVal(CausaRaizList[i].IdCausa);
        setIdCausa(CausaRaizList[i].IdCausa);
      }
    }
    setCausaRaizList(CausaRaizList);
  };
  const ObtenerHistorialPendientesActionPlan = (idCSF, idPlanAccion) => {
    const GetHistorialPendientesActionPlan = `${config.UrlApiProject}ScoreCard/GetHistorialPendientesActionPlan`;
    const paramsToService = {
      IdCSF: idCSF,
      IdPlanAccion: idPlanAccion,
    };
    callApi(GetHistorialPendientesActionPlan, 'POST', paramsToService, (response) => {
      setHistorialPendientesList(response.data.HistorialPendientes);
    });
  };

  const ResponsibleEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldAutoComplete
          dropdown
          name="ClaColaborador"
          value={props.rowData.ClaColaborador}
          onChange={(e) => onChangeClaUsuarioResponsable(props, e)}
          suggestions={filteredResponsible}
          field="NomColaborador"
          completeMethod={(e) => filterResponsible(props, e)}
          errors={errors[`ResponsablesList[${props.rowData.RowIndex}]`]}
        />
      </div>
    );
  };

  const Descripcionditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldText
          name="cDescAccion"
          value={props.rowData.cDescAccion}
          onChange={(e) => onDescripcionChange(props, e.target.value)}
          errors={errors[`ResponsablesList[${props.rowData.RowIndex}]`]}
          maxLength={550}
        />
      </div>
    );
  };

  const PickOneColumn = (rowData, props) => {
    return (
      <>
        <FieldRadioButton
          inputId={`PickOne-${rowData.IdCausa}`}
          name="IdCausa"
          onChange={(value) => onChangePickOne(value, props)}
          checked={PickOneVal === rowData.IdCausa || rowData.EsSeleccionada === 1}
          value={rowData.IdCausa}
        />
      </>
    );
  };

  const FechaCompromisoEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldCalendar
          name="cFechaCompromiso"
          value={
            props.rowData.cFechaCompromiso !== null
              ? new Date(props.rowData.cFechaCompromiso)
              : null
          }
          onChange={(e) => onChangeFechaCompromiso(props, e.target.value)}
          errors={errors[`ResponsablesList[${props.rowData.RowIndex}]`]}
        />
      </div>
    );
  };

  const FechaEntregaEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldCalendar
          name="cFechaTermino"
          value={
            props.rowData.cFechaTermino !== null ? new Date(props.rowData.cFechaTermino) : null
          }
          onChange={(e) => onChangeFechaEntrega(props, e)}
        />
      </div>
    );
  };

  const FechaEntregaPendingActionEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldCalendar
          name="cFechaTermino"
          value={
            props.rowData.cFechaTermino !== null ? new Date(props.rowData.cFechaTermino) : null
          }
          onChange={(e) => onChangeFechaEntregaPendingAction(props, e)}
        />
      </div>
    );
  };

  const fieldsRequiredActionPlan = (rowData, props) => {
    return (
      <>
        {errors[`ResponsablesList[${props.rowData.RowIndex}]`] != null ? (
          <i className="fas fa-exclamation-triangle invalid-color"></i>
        ) : (
          ''
        )}
      </>
    );
  };

  const actionBodyTemplateActionPlan = (rowData, props) => {
    return (
      <>
        <FieldButton
          icon="pi pi-trash"
          onClick={() => onConfirmBajaActionPlan(rowData, props)}
          className="p-button-rounded p-button-warning"
        />
      </>
    );
  };

  const FechaEntregaTemplate = (rowData) => {
    return (
      <>
        {rowData.cFechaTermino !== null ? moment(rowData.cFechaTermino).format('MM/DD/YYYY') : null}
      </>
    );
  };

  const FechaEntregaPendingActionTemplate = (rowData) => {
    return (
      <>
        {rowData.cFechaTermino !== null ? moment(rowData.cFechaTermino).format('MM/DD/YYYY') : null}
      </>
    );
  };

  const FechaReportePendingActionTemplate = (rowData) => {
    return (
      <>
        {rowData.cFechaReporte !== null ? moment(rowData.cFechaReporte).format('MM/DD/YYYY') : null}
      </>
    );
  };

  const FechaCompromisoTemplate = (rowData) => {
    return (
      <>
        {rowData.cFechaCompromiso !== null
          ? moment(rowData.cFechaCompromiso).format('MM/DD/YYYY')
          : null}
      </>
    );
  }; 

  const CopyButtonTemplate = (rowData) => {
    return (
      <>
        <span title={t('actionPlan:Copy')}>
          <FieldButton 
            label={t('actionPlan:Copy')} 
            disabled={typeof(rowData.EsAccionCopiada) === 'undefined' ? false : rowData.EsAccionCopiada} 
            icon="fas fa-copy"  
            className="success no-border p-mr-2 rounded-button"
            onClick={() => onCopyAction(rowData, props)}
          />
        </span> 
      </>
      
    );
  }

  const onCopyAction = (rowData, props) => { 
    const historialPendientesPreviosNew = [...HistorialPendientesPrevios];
    historialPendientesPreviosNew[rowData.RowIndex].EsAccionCopiada = true;
    setHistorialPendientesList(historialPendientesPreviosNew);

    
    const ResponsablesListNew = [...ResponsablesList];
     
     
    ResponsablesListNew.push(
      {
        BajaLogica: 0
        ,ClaColaborador: {
           ClaColaborador: rowData.cClaColaborador
          ,NomColaborador: rowData.NomColaborador
        } 
        ,DescAccion: rowData.cDescAccion
        ,NomColaborador: rowData.NomColaborador
        ,RowIndex: ResponsablesList.length
        ,cClaColaborador: rowData.cClaColaborador
        ,cDescAccion: rowData.cDescAccion
        ,cFechaCompromiso: rowData.cFechaCompromiso 
        ,cFechaTermino: null
        ,cIdCausa: null
        ,cIdPlanAccion: null
        ,IdPlanAccionOrigen: rowData.cIdPlanAccion
        ,IdCausaOrigen: rowData.cIdCausa
        ,IdTareaOrigen: rowData.cClaTarea
      }
    );

    setResponsablesList(ResponsablesListNew);
  };

  
  const AddNoteTemplate = (rowData) => {
    return rowData.cIdPlanAccion !== null ? (
      <div className="p-fluid">
        <Tooltip target="#DescriptionNote" />
        <i
          id="DescriptionNote"
          data-pr-tooltip={rowData.cComentarioTarea}
          onClick={() => AbrirNotas(rowData)}
          className={`fas fa-sticky-note ${
            rowData.cComentarioTarea !== null && rowData.cComentarioTarea !== ''
              ? 'light-yellow-color'
              : 'light-gray-color'
          }`}
        >
        </i>
      </div>
    ) : (
      ''
    );
  };
  const AddNotePendingActionTemplate = (rowData) => {
    return (
      <div className="p-fluid">
        <Tooltip target="#DescriptionNote" />
        <i
          id="DescriptionNote"
          data-pr-tooltip={rowData.cComentarioTarea}
          onClick={() => AbrirNotas(rowData)}
          className={`fas fa-sticky-note ${
            rowData.cComentarioTarea !== null && rowData.cComentarioTarea !== ''
              ? 'light-yellow-color'
              : 'light-gray-color'
          }`}
        >
        </i>
      </div>
    );
  };

  const PossibleCauseEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldText
          name="DescripcionCausa"
          value={props.rowData.DescripcionCausa}
          onChange={(e) => onPossibleCauseChange(props, e.target.value)}
          errors={errors[`CausaRaizList[${props.rowData.RowIndex}]`]}
          maxLength={550}
        />
      </div>
    );
  };

  const WeighEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldNumber
          name="PesoRelevancia"
          value={props.rowData.PesoRelevancia}
          onChange={(e) => onWeighChange(props, e.target.value)}
          minFractionDigits={0}
          min={0}
          max={100}
          errors={errors[`CausaRaizList[${props.rowData.RowIndex}]`]}
        />
      </div>
    );
  };

  const onPossibleCauseChange = (props, value) => {
    // setDescCausaRaiz(value);
    const newErrors = { ...errors };
    delete newErrors.DescripcionCausa;
    setErrors(newErrors);

    if (value === null || value === '') {
      setIdCausaAux(null);
    }
    const updatedDescripcion = [...CausaRaizList];
    updatedDescripcion[props.rowIndex][props.field] = value;
    setCausaRaizList(updatedDescripcion);
  };

  const onWeighChange = (props, value) => {
    const updatedDescripcion = [...CausaRaizList];
    updatedDescripcion[props.rowIndex][props.field] = value;
    setCausaRaizList(updatedDescripcion);
  };

  const onDescripcionChange = (props, value) => {
    const newErrors = { ...errors };
    delete newErrors.cDescAccion;
    setErrors(newErrors);
    const updatedDescripcion = [...ResponsablesList];
    updatedDescripcion[props.rowData.RowIndex][props.field] = value;

    setResponsablesList(updatedDescripcion);
  };

  const onChangeFechaCompromiso = (props, value) => {
    const newErrors = { ...errors };
    delete newErrors.cFechaCompromiso;
    setErrors(newErrors);
    const updatedProducts = [...ResponsablesList];
    const saveArray = getCurrentActionPlanRowValues(props);
    saveArray.cFechaCompromiso = new Date(value);
    updatedProducts[props.rowData.RowIndex].cFechaCompromiso = value;
    setResponsablesList(updatedProducts);
  };

  const onChangeFechaEntrega = (props, e) => {
    const updatedProducts = [...ResponsablesList];
    const saveArray = getCurrentActionPlanRowValues(props);
    saveArray.cFechaTermino = new Date(e.target.value);
    updatedProducts[props.rowData.RowIndex].cFechaTermino = e.target.value;
    setResponsablesList(updatedProducts);
  };

  const onChangeFechaEntregaPendingAction = (props, e) => {
    const updatedProducts = [...HistorialPendientesList];
    // const saveArray = getCurrentActionPlanRowValues(props);
    // saveArray.cFechaTermino = new Date(e.target.value);
    updatedProducts[props.rowData.RowIndex].cFechaTermino = e.target.value;
    setHistorialPendientesList(updatedProducts);
  };

  const onConfirmBajaActionPlan = (ActionPlan, props) => {
    const ActionPlanListnew = [...ResponsablesList];
    if (ActionPlanListnew[ActionPlan.RowIndex].cIdCausa === null) {
      ActionPlanListnew.splice(ActionPlan.RowIndex, 1);
    } else {
      ActionPlanListnew[ActionPlan.RowIndex].BajaLogica = 1;
    }
    setResponsablesList(ActionPlanListnew);
  };

  const onChangePickOne = (value, props) => {
    const updatedPickOne = [...CausaRaizList];
    for (let i = 0; i < updatedPickOne.length; i++) {
      if (props.rowData.IdCausa === updatedPickOne[i].IdCausa) {
        updatedPickOne[i].EsSeleccionada = 1;
        if (props.rowData.DescripcionCausa !== null && props.rowData.DescripcionCausa !== '') {
          setIdCausaAux(props.rowData.IdCausa);
        }
      } else {
        updatedPickOne[i].EsSeleccionada = 0;
      }
    }
    setPickOneVal(value);
    setIdCausa(props.rowData.IdCausa);
  };

  const onChangeExplicacionResultado = (e) => {
    const newErrors = { ...errors };
    delete newErrors.ExplicacionResultado;
    setErrors(newErrors);
    setExplicacionResultado(e.target.value);
  };

  const onChangeClaUsuarioResponsable = (props, e) => {
    const newErrors = { ...errors };
    const field = { ...newErrors[`ResponsablesList[${props.rowData.RowIndex}]`] };
    field.ClaColaborador = null;
    newErrors[`ResponsablesList[${props.rowData.RowIndex}]`] = field;
    delete newErrors[`ResponsablesList[${props.rowData.RowIndex}]`];
    setErrors(newErrors);

    const saveArray = getCurrentActionPlanRowValues(props);
    const updatedProducts = [...ResponsablesList];

    if (e.value != null && typeof e.value === 'object') {
      const responsible = filteredResponsible.filter(
        (item) => item.ClaColaborador === e.value.ClaColaborador
      )[0];

      if (responsible !== undefined) {
        updatedProducts[props.rowData.RowIndex].NomColaborador = responsible.NomColaborador;
        updatedProducts[props.rowData.RowIndex].cClaColaborador = responsible.ClaColaborador;
      }
    } else {
      updatedProducts[props.rowData.RowIndex].NomColaborador = null;
    }

    updatedProducts[props.rowData.RowIndex].ClaColaborador = e.value;
    saveArray.ClaColaborador = e.value;
    setResponsablesList(updatedProducts);
  };

  const getCurrentActionPlanRowValues = (props) => {
    const updatedProducts = [...props.value];
    let RowValues = {};

    if (updatedProducts[props.rowIndex].ClaColaborador !== null) {
      RowValues = {
        cIdCausa: updatedProducts[props.rowIndex].cIdCausa,
        cDescAccion: updatedProducts[props.rowIndex].cDescAccion,
        ClaColaborador: {
          ClaColaborador: updatedProducts[props.rowIndex].ClaColaborador.ClaColaborador,
          NomColaborador: updatedProducts[props.rowIndex].ClaColaborador.NomColaborador,
        },
        NomColaborador: updatedProducts[props.rowIndex].cNomColaborador,
        cFechaCompromiso: updatedProducts[props.rowIndex].cFechaCompromiso,
        cFechaTermino: updatedProducts[props.rowIndex].cFechaEntrega,
      };
    } else {
      RowValues = {
        cIdCausa: updatedProducts[props.rowIndex].cIdCausa,
        cDescAccion: updatedProducts[props.rowIndex].cDescAccion,
        ClaColaborador: null,
        NomColaborador: null,
        cFechaCompromiso: updatedProducts[props.rowIndex].cFechaCompromiso,
        cFechaTermino: updatedProducts[props.rowIndex].cFechaTermino,
      };
    }

    return RowValues;
  };

  const filterResponsible = (props, event) => {
    let valor = '';
    const updatedProducts = [...ResponsablesList];

    if (
      updatedProducts[props.rowData.RowIndex].ClaColaborador !== null &&
      updatedProducts[props.rowData.RowIndex].ClaColaborador !== '' &&
      event.query === ''
    ) {
      valor = updatedProducts[props.rowData.RowIndex].ClaColaborador.NomColaborador;
    } else {
      valor = event.query;
    }
    const paramsServiceOwners = {};
    // event.query
    const urlWebServiceOwners = `${config.UrlApiProject}ScoreCard/GetColaboradorProyectosCmb?descriptionFilter=${valor}&EsMostrarSoloUsuarioMSW=${!EsMostrarSoloUsuarioMSW?1:0}`;
    // Get owner
    callApi(urlWebServiceOwners, 'GET', paramsServiceOwners, (response) => {
      setfilteredResponsible(response.data[0]);
    });
  };

  const ActivarCheck = (rowData, props) => {
    return true;
  };

  const showActiveRows = (rows) => {
    const activeRows = [];
    if (rows.length > 0) {
      rows.forEach((item, index) => {
        if (item.BajaLogica === 0 || item.BajaLogica === undefined) {
          item.BajaLogica = 0;
          item.RowIndex = index;
          activeRows.push({ ...item });
        }
      });
    } else {
      return [];
    }
    return activeRows;
  };

  const onNewClick = () => {
    const newRow = {
      RowIndex: ResponsablesList.length,
      cIdCausa: null,
      DescAccion: null,
      ClaColaborador: null,
      cFechaCompromiso: null,
      cFechaTermino: null,
      cIdPlanAccion: null,
    };
    const updatedProducts = [...ResponsablesList];
    updatedProducts.push(newRow);

    setResponsablesList(updatedProducts);
  };

  const onSaveActionPlanClick = async () => {
    setErrors({});

    const value = {
      ExplicacionResultado,
      ResponsablesList,
      CausaRaizList,
    };

    const validator = new YupValidator(valitationScheme);
    const esValido = await validator.validate(value);

    if (!esValido) {
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');

      return;
    }

    if (PickOneVal === null) {
      showSweetAlert(t('common:Warning'), t('actionPlan:PickOneVal'), 'warning');
      return;
    }
    /*
  if( IdCausaAux !== PickOneVal){
    showSweetAlert(t('common:Warning'), t('actionPlan:PickOneDesc'), 'warning');    
    return;
  }
*/
    if (ResponsablesList.length === 0) {
      showSweetAlert(
        t('common:Warning'),
        t('actionPlan:ReviewRequiredFieldsResponsible'),
        'warning'
      );
      return;
    }

    /*
  const arrayCausaRaizList = [];
  let RowValues ={};

  for (let index = 0; index < CausaRaizList.length; index++) {
    if (PickOneVal === CausaRaizList[index].IdCausa) {      
    RowValues =  {
      IdCausa: CausaRaizList[index].IdCausa,
      ClaveCausa: CausaRaizList[index].ClaveCausa,
      DescripcionCausa: CausaRaizList[index].DescripcionCausa,
      PesoRelevancia: CausaRaizList[index].PesoRelevancia,
      EsSeleccionada: 1
    }
    arrayCausaRaizList.push(RowValues);
    }
  }
 */
    const arrayResponsablesList = [];
    let RowValuesResponsables = {};

    for (let index = 0; index < ResponsablesList.length; index++) {
      RowValuesResponsables = {
        IdCausaSel: IdCausa,
        IdCausa: ResponsablesList[index].cIdCausa,
        IdTarea: ResponsablesList[index].cClaTarea,
        ClaveTarea: ResponsablesList[index].cClaveTarea,
        DescripcionTarea: ResponsablesList[index].cDescAccion,
        ClaUsuario: ResponsablesList[index].ClaColaborador.ClaColaborador,
        FechaCompromiso: ResponsablesList[index].cFechaCompromiso,
        FechaTermino: ResponsablesList[index].cFechaTermino,
        BajaLogica: ResponsablesList[index].BajaLogica,
        IdPlanAccionOrigen: typeof(ResponsablesList[index].IdPlanAccionOrigen) === 'undefined' ? null : ResponsablesList[index].IdPlanAccionOrigen,
        IdCausaOrigen: typeof(ResponsablesList[index].IdCausaOrigen) === 'undefined' ? null : ResponsablesList[index].IdCausaOrigen,
        IdTareaOrigen: typeof(ResponsablesList[index].IdTareaOrigen) === 'undefined' ? null : ResponsablesList[index].IdTareaOrigen 
      };
      arrayResponsablesList.push(RowValuesResponsables);
    }

    const arrayHistorialPendientesList = [];
    let RowHistorialPendientes = {};

    for (let index = 0; index < HistorialPendientesList.length; index++) {
      RowHistorialPendientes = {
        IdPlanAccion: HistorialPendientesList[index].cIdPlanAccion,
        IdCSF: HistorialPendientesList[index].IdCSF,
        IdCausa: HistorialPendientesList[index].cIdCausa,
        IdTarea: HistorialPendientesList[index].cClaTarea,
        ClaveTarea: HistorialPendientesList[index].cClaveTarea,
        DescripcionCausa: HistorialPendientesList[index].cDescAccion,
        ClaUsuario: HistorialPendientesList[index].cClaColaborador,
        FechaCompromiso: HistorialPendientesList[index].cFechaCompromiso,
        FechaTermino: HistorialPendientesList[index].cFechaTermino,
        BajaLogica: HistorialPendientesList[index].BajaLogica,
      };
      arrayHistorialPendientesList.push(RowHistorialPendientes);
    }

    const paramsServiceOwners = {
      IdCSF,
      ClaPeriodo,
      ClaFechaReporte,
      NombreLider,
      TopicoAnalisis,
      ResultadoActual: CurrRes,
      ResultadoPlaneado: PlanRes,
      ExplicacionResultado,
      CausaRaizList,
      ResponsablesList: arrayResponsablesList,
      HistorialPendientesList: arrayHistorialPendientesList,
      EsCausaRaizRevisadas: CausaRaiz,
      EsAccionesRevisadas: AgregarAcciones,
      EsCrearPropioActionPlan: CrearActionPlan
    };

    const urlWebServiceSaveActionPlan = `${config.UrlApiProject}ScoreCard/SaveActionPlan`;

    callApi(urlWebServiceSaveActionPlan, 'POST', paramsServiceOwners, (response) => {
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      obtenerEncabezadoActionPlan(
        props.IdCSF === undefined ? 0 : props.IdCSF,
        props.ClaPeriodo === undefined ? 0 : props.ClaPeriodo,
        props.ClaFechaReporte === undefined ? 0 : props.ClaFechaReporte
      );
      onHideAP();
    });
  };


  const onSaveActionPlanAsReviewedClick = async () => {
    setErrors({});

    const value = {
      CausaRaiz,
      AgregarAcciones,
      CrearActionPlan 
    };

    const validator = new YupValidator(valitationMarkAsReviewedScheme);
    const esValido = await validator.validate(value);

    console.log(validator.errors);

    if (!esValido) {
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');

      return;
    } 
    
    const arrayResponsablesList = [];
    let RowValuesResponsables = {};

    for (let index = 0; index < ResponsablesList.length; index++) {
      RowValuesResponsables = {
        IdCausaSel: IdCausa,
        IdCausa: ResponsablesList[index].cIdCausa,
        IdTarea: ResponsablesList[index].cClaTarea,
        ClaveTarea: ResponsablesList[index].cClaveTarea,
        DescripcionTarea: ResponsablesList[index].cDescAccion,
        ClaUsuario: ResponsablesList[index].ClaColaborador.ClaColaborador,
        FechaCompromiso: ResponsablesList[index].cFechaCompromiso,
        FechaTermino: ResponsablesList[index].cFechaTermino,
        BajaLogica: ResponsablesList[index].BajaLogica,
      };
      arrayResponsablesList.push(RowValuesResponsables);
    }

    const arrayHistorialPendientesList = [];
    let RowHistorialPendientes = {};

    for (let index = 0; index < HistorialPendientesList.length; index++) {
      RowHistorialPendientes = {
        IdPlanAccion: HistorialPendientesList[index].cIdPlanAccion,
        IdCSF: HistorialPendientesList[index].IdCSF,
        IdCausa: HistorialPendientesList[index].cIdCausa,
        IdTarea: HistorialPendientesList[index].cClaTarea,
        ClaveTarea: HistorialPendientesList[index].cClaveTarea,
        DescripcionCausa: HistorialPendientesList[index].cDescAccion,
        ClaUsuario: HistorialPendientesList[index].cClaColaborador,
        FechaCompromiso: HistorialPendientesList[index].cFechaCompromiso,
        FechaTermino: HistorialPendientesList[index].cFechaTermino,
        BajaLogica: HistorialPendientesList[index].BajaLogica,
      };
      arrayHistorialPendientesList.push(RowHistorialPendientes);
    }

    const paramsServiceOwners = {
      IdCSF,
      ClaPeriodo,
      ClaFechaReporte,
      NombreLider,
      TopicoAnalisis,
      ResultadoActual: CurrRes,
      ResultadoPlaneado: PlanRes,
      ExplicacionResultado,
      CausaRaizList,
      ResponsablesList: arrayResponsablesList,
      HistorialPendientesList: arrayHistorialPendientesList,
      EsCausaRaizRevisadas: CausaRaiz,
      EsAccionesRevisadas: AgregarAcciones,
      EsCrearPropioActionPlan: CrearActionPlan
    };

    const urlWebServiceSaveActionPlan = `${config.UrlApiProject}ScoreCard/SaveActionPlanAsReviewed`;

    callApi(urlWebServiceSaveActionPlan, 'POST', paramsServiceOwners, (response) => {
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      obtenerEncabezadoActionPlan(
        props.IdCSF === undefined ? 0 : props.IdCSF,
        props.ClaPeriodo === undefined ? 0 : props.ClaPeriodo,
        props.ClaFechaReporte === undefined ? 0 : props.ClaFechaReporte
      );
      onHideAP();
    });
  };

  const valitationScheme = yup.object().shape({
    ExplicacionResultado: yup.string().required(t('common:RequiredField')).nullable(),
    CausaRaizList: yup.array().of(
      yup.object().shape({
        DescripcionCausa: yup
          .string()
          .when('EsSeleccionada', {
            is: 1,
            then: yup.string().required(t('common:RequiredField')).nullable(),
            otherwise: yup.string().nullable(),
          }),
        PesoRelevancia: yup
          .number()
          .when('EsSeleccionada', {
            is: 1,
            then: yup.number().required(t('common:RequiredField')).nullable(),
            otherwise: yup.number().nullable(),
          }),    
          

      })
    ),
    ResponsablesList: yup.array().of(
      yup.object().shape({
        cDescAccion: yup.string().required(t('common:RequiredField')).nullable(), 
        ClaColaborador: yup.object().when('BajaLogica', {is: 0,
          then: yup.object({ ClaColaborador: yup.string().required(t('common:RequiredField')) }).required(t('common:RequiredField')).nullable(),
          otherwise: yup.object().nullable()
        }),
        cFechaCompromiso: yup.string().trim().required(t('common:RequiredField')).nullable(),
      })
    ) 
  });

  const valitationMarkAsReviewedScheme = yup.object().shape({ 
    CausaRaiz: yup.mixed().required(t('common:RequiredField')).nullable(),
    AgregarAcciones: yup.mixed().required(t('common:RequiredField')).nullable(),
    CrearActionPlan: yup.mixed().required(t('common:RequiredField')).nullable()
  });

  const headerResponsable = (
    <>
      <Row>
        <Col>
          {colorEstatus === 1 ? (
            <>{t('actionPlan:ListActionImproveRojo')}</>
          ) : colorEstatus === 2 ? (
            <>{t('actionPlan:ListActionImproveAmarillo')}</>
          ) : colorEstatus === 3 ? (
            <>{t('actionPlan:ListActionImproveVerde')}</>
          ) : (
            <>{t('actionPlan:ListActionNotFound')}</>
          )} 
        </Col>
        <Col className="text-right">
          <span className="mr-2">{t('misProyectosScreen:EsMostrarSoloUsuarioMSW')}</span>
          <FieldCheckbox
            inputId={EsMostrarSoloUsuarioMSW}
            checked={EsMostrarSoloUsuarioMSW===1}
            onChange={(e) => onChangeIncluirSoloUsuariosMSW(props, e)}
          />
          &nbsp;
          &nbsp;
          &nbsp;
          &nbsp;
          &nbsp;
          <FieldButton
            className="p-button p-button-icon-only p-button-info rounded-button"
            onClick={onNewClick}
          >
            <i className="pi pi-plus"></i>
          </FieldButton>
        </Col>
      </Row>
    </>
  );

  const fieldsRequiredCausaRaiz = (rowData, props) => {
    return (
      <>
        {errors[`CausaRaizList[${props.rowData.RowIndex}]`] != null ? (
          <i className="fas fa-exclamation-triangle invalid-color"></i>
        ) : (
          ''
        )}
      </>
    );
  };

  const headerPendiente = (
    <>
      <Row>
        <Col>{t('actionPlan:Pending')}</Col>
      </Row>
    </>
  );
  const headerPosicionesRelacionadas = (
    <>
      <Row>
        <Col>{t('actionPlan:PosicionesRelacionadas')}</Col>
      </Row>
    </>
  );
  const headerComposicion = (
    <>
      <Row>
        <Col>{t('actionPlan:Composicion')}</Col>
      </Row>
    </>
  );
  const headerDiscuss = (
    <>
      <Row>
        <Col>
          {colorEstatus === 1 ? (
            <>{t('actionPlan:DiscussRojo')}</>
          ) : colorEstatus === 2 ? (
            <>{t('actionPlan:DiscussAmarillo')}</>
          ) : colorEstatus === 3 ? (
            <>{t('actionPlan:DiscussVerde')}</>
          ) : (
            <>{t('actionPlan:DiscussColorNotFound')}</>
          )}
        </Col>
      </Row>
    </>
  );
  const AccionesTemplateAp = (row) => {
    let className = '';

    switch (row.PlanAccionBandera) {
      case 0:
        className = 'fas fa-flag-checkered Cursor';
        break;
      case 1:
        className = 'fa fa-flag danger-color Cursor';
        break;
      case 2:
        className = 'fa fa-flag warning-color Cursor';
        break;
      case 3:
        className = 'fa fa-flag success-color Cursor';
        break;
      default:
        className = 'fa fa-flag default-color Cursor';
    }
    return (
      <>
        <i className={className} onClick={() => onClickReviewActionPlan(row)}></i>
      </>
    );
  };

  const onClickReviewActionPlan = (row) => { 
      console.log('onClickReviewActionPlan', row);
      setIsAtLeastOneActionPlanOpen(true);
      setIdCSFSel(row.IdCSF);
      setClaPeriodoSel(row.ClaPeriodo);
      setClaFechaReporteSel(row.ClaFechaReporte);  
      setEsReviewActionPlan(true); 
  }

  const openObjetivosKPIDetalle = (item) => {  
    setKPISelected(item);
    setEsMostrarDetalleObjetivos(true)
  }



  const EstatusPeriodoTemplate = (props) => {
    let className = '';

    switch (props.ColorEstatus) {
      case 1:
        className = 'danger-color';
        break;
      case 2:
        className = 'warning-color';
        break;
      case 3:
        className = 'success-color';
        break;
      default:
        className = 'default-color';
    } 

    return (
      <span className={`Cursor text-underline ${className}`} title={`${t('focusReport:ViewKPIByPeriod')}`} onClick={() => openObjetivosKPIDetalle(props)}>
        <b>{props.CurrentStatus !== null? Highcharts.numberFormat(props.CurrentStatus, 2, '.', ','): null}</b>
      </span> 
      )

  };
  const BodyTemplatePositionName = (rowData, props) => {
    return (
      <> 
        <div className='p-toolbar-group-left'>
          <span className="bold">{rowData.PositionName}</span>
        </div>
        <div className='p-toolbar-group-right'>
          <small>{ rowData.Responsible}</small>&nbsp; &nbsp;  
          <small hidden={rowData.EmailResponsable === null || rowData.EmailResponsable === '' || typeof(rowData.EmailResponsable) === 'undefined'}>
            <a
              title={`${t('actionPlan:EmailBtnTitle')} ${rowData.Responsible} (${rowData.EmailResponsable})`}
              href={`mailto:${rowData.EmailResponsable}?subject=${rowData.IndexDescription} - ${ResFecha}&body=Hello ${rowData.Responsible} ,%0D%0A%0D%0APlease create / edit an Action Plan for this initiative. %0D%0A%0D%0AThank you!`}
            >
              <i className="fas fa-paper-plane orange-color" /> &nbsp;<i className="fas fa-envelope orange-color"></i>
            </a>
              
          </small>
        </div>
      </>
    );
  } 

  const dialogStyle = {
    width: '80vw',
  };
  const closeModal = () => {
    setEsVerAnexo(false);
  };
  const closeModalNotas = () => {
    setEsAbrirNotas(false);
  };
  const AbrirAnexo = () => {
    setEsVerAnexo(!EsVerAnexo);
  };
  const AbrirNotas = async (data) => {
    setEsAbrirNotas(!EsAbrirNotas);
    setRowDataNotas(data);
  };

  const goToStep1 = async () => {
    setErrors({});
    setActiveIndex(0);
  };

  const goToStep2 = async () => {
    setErrors({});

    const value = {
      ExplicacionResultado,
    };

    const validator = new YupValidator(valitationScheme);
    const esValido = await validator.validate(value);

    console.log(validator.errors);
    if (!esValido) {
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      return;
    }

    // Si es diferente de rojo no requerimos paso 2 por default y lo mandamos al 3
    // ingresamos por default un valor en el paso #2
    if (colorEstatus !== 1) {
      const updatedDescripcion = [...CausaRaizList];
      if (updatedDescripcion[0].DescripcionCausa === null) {
        updatedDescripcion[0].DescripcionCausa = t('actionPlan:DefaultRootCause');
        updatedDescripcion[0].EsSeleccionada = 1;
        updatedDescripcion[0].PesoRelevancia = 100;
        setPickOneVal(updatedDescripcion[0].IdCausa);
        setCausaRaizList(updatedDescripcion);
      }

      setActiveIndex(2);
    } else {
      setActiveIndex(1);
    }
  };

  const goToStep3 = async () => {
    setErrors({});

    const value = {
      ExplicacionResultado,
      CausaRaizList,
    };

    const validator = new YupValidator(valitationScheme);
    const esValido = await validator.validate(value);

    if (!esValido) {
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');

      return;
    }

    if (PickOneVal === null) {
      showSweetAlert(t('common:Warning'), t('actionPlan:PickOneVal'), 'warning');
      return;
    }

    setActiveIndex(2);
  };

  const goToStep4 = async () => {
    setErrors({});

    const value = {
      ExplicacionResultado,
      ResponsablesList,
      CausaRaizList,
    };

    const validator = new YupValidator(valitationScheme);
    const esValido = await validator.validate(value);

    if (!esValido) {
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');

      return;
    }

    if (PickOneVal === null) {
      showSweetAlert(t('common:Warning'), t('actionPlan:PickOneVal'), 'warning');
      return;
    }

    if (ResponsablesList.length === 0) {
      showSweetAlert(
        t('common:Warning'),
        t('actionPlan:ReviewRequiredFieldsResponsible'),
        'warning'
      );
      return;
    }

    setActiveIndex(3);
  };

  const StepRender = (index) => {
    return <></>;
  };

  const stepItems = [
    {
      label: (
        <>
          <div className="text-center">
            <i
              className={`fa fa-check ${activeIndex > 0 ? 'light-green-color' : 'secondary-color'}`}
            >
            </i>
          </div>
        </>
      ),
      className: 'itemInicio',
      command: (event) => {},
    },
    {
      label: (
        <>
          <div className="text-center">
            <i
              className={`fa fa-check ${activeIndex > 1 ? 'light-green-color' : 'secondary-color'}`}
            >
            </i>
          </div>
        </>
      ),
      command: (event) => {},
    },
    {
      label: (
        <>
          <div className="text-center">
            <i
              className={`fa fa-check ${activeIndex > 2 ? 'light-green-color' : 'secondary-color'}`}
            >
            </i>
          </div>
        </>
      ),
      command: (event) => {},
    },
    {
      label: (
        <>
          <div className="text-center">
            <i
              className={`fa fa-check ${activeIndex > 3 ? 'light-green-color' : 'secondary-color'}`}
            >
            </i>
          </div>
        </>
      ),
      className: 'itemFinal',
      command: (event) => {},
    },
  ];

  const renderHeader = () => {
    return (
      <div>
        <Row>
          <Col lg={4} md={4} sm={12} xs={12}>
            <div hidden={IdPlanAccion === null}>
              <span>{t('scoreCard:APModalTitle')}</span>
            </div>
            <div hidden={IdPlanAccion !== null}>
              <span>{t('scoreCard:CrearNuevoAP')}</span>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="text-center">
            <span>{NombreLider} </span>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="text-right">
            <div className="w-100 relative">
              <span> {ResFecha} </span>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <>
        <Toolbar
          className='pl-5'
          left={leftToolbarFooterNewAPTemplate}
          right={rightToolbarFooterNewAPTemplate}
        >
        </Toolbar>
      </>
    );
  };

  const leftToolbarFooterNewAPTemplate = () => {  
    return (
      <> 
        {
          // a veces podemos traer el paso -1 pero eso depende de la configuracion del KPI
          activeIndex === -1 ? (<></>)
          :
          ( PosicionesRelacionadasList.length > 0 || ComposicionList.length > 0) 
          ?
          (
            <FieldButton
              hidden={IdPlanAccion !== null}
              label={t('common:Previous')}
              icon="fas fa-arrow-circle-left"
              className="p-button-secondary no-border p-mr-2 rounded-button"
              onClick={() => setActiveIndex(activeIndex - 1)}
            />
        )
          :
          (
            <FieldButton
              hidden={IdPlanAccion !== null || activeIndex === 0}
              label={t('common:Previous')}
              icon="fas fa-arrow-circle-left"
              className="p-button-secondary no-border p-mr-2 rounded-button"
              onClick={() => setActiveIndex(activeIndex - 1)}
            />
        )
        }
        
        <FieldButton
          label={t('common:Close')}
          icon="fas fa-times"
          className="warning no-border p-mr-2 rounded-button"
          onClick={onHideAP}
        />
      </>
    );
  };
  const rightToolbarFooterNewAPTemplate = () => {
     
    return (
      <>
        <div hidden={activeIndex !== 0}>
          <FieldButton
            label={t('common:Next')}
            icon="fas fa-arrow-circle-right"
            className="p-button-primary no-border p-mr-2 rounded-button"
            onClick={goToStep2}
          />
        </div>
        <div hidden={activeIndex !== 1}>
          <FieldButton
            label={t('common:Next')}
            icon="fas fa-arrow-circle-right"
            className="p-button-primary no-border p-mr-2 rounded-button"
            onClick={goToStep3}
          />
        </div>
        <div hidden={activeIndex !== 2}>
          <FieldButton
            label={t('common:Next')}
            icon="fas fa-arrow-circle-right"
            className="p-button-primary no-border p-mr-2 rounded-button"
            onClick={goToStep4}
          />
        </div>

        {
          activeIndex === 3 || (activeIndex === null && IdPlanAccion > 0) ?
          (
            <FieldButton
              label={t('common:Save')}
              icon="pi pi-save"
              className="p-button-success no-border p-mr-2 rounded-button"
              onClick={onSaveActionPlanClick}
            />
          )
          :
          (<></>)
        } 
      </>
    );
  };

  const TituloDescription = () => {
    switch (colorEstatus) {
      case 1: // rojo
        return <>{t('actionPlan:DescCurrentResultRojo')}</>;

      case 2: // Amarillo
        return <>{t('actionPlan:DescCurrentResultAmarillo')}</>;

      case 3: // Verde
        return <>{t('actionPlan:DescCurrentResultVerde')}</>;

      default:
        return <>{t('actionPlan:DescCurrentResultNOTFound')}</>;
    }
  };

  const onHideAP = () => {
    document.body.classList.remove('bodynotscroll');

    if (props.onHide !== null && typeof props.onHide === 'function') {
      props.onHide();
    }
  };

  const RenderDescription = (
    <>
      <div
        className={`p-datatable p-component p-datatable-scrollable p-datatable-hoverable-rows ${headerColor}`}
      >
        <div className="p-datatable-header">
          <Row>
            <Col>
              <TituloDescription></TituloDescription>
            </Col>
          </Row>
        </div>
      </div>
      <div className="p-fluid">
        <FieldTextArea
          name="ExplicacionResultado"
          rows={4}
          placeholder=""
          maxLength={500}
          value={ExplicacionResultado}
          onChange={(e) => onChangeExplicacionResultado(e)}
          errors={errors}
          className="bottom-rounded"
        />
      </div>
    </>
  );

  const RenderCausaRaiz = (
    <>
      <FieldDataTable
        value={showActiveRows(CausaRaizList)}
        className={`${headerColor}`}
        editMode="cell"
        selectionMode="single"
        header={headerDiscuss}
        paginator={false}
      >
        <Column
          body={(rowData, props) => fieldsRequiredCausaRaiz(rowData, props)}
          headerStyle={{ width: '10px' }}
        >
        </Column>
        <Column
          field="IdCausa"
          header={t('actionPlan:numeral')}
          headerStyle={{ width: '10px' }}
          columnKey="IdCausa"
          className="text-left"
        >
        </Column>
        <Column
          field="ClaveCausa"
          header={t('actionPlan:numeral')}
          headerStyle={{ width: '10px' }}
          columnKey="ClaveCausa"
          className="text-left"
        >
        </Column>
        <Column
          field="DescripcionCausa"
          header={t('actionPlan:Action')}
          headerStyle={{ width: '280px' }}
          editor={(props) => PossibleCauseEditor(props)}
        >
        </Column>
        <Column
          field="PesoRelevancia"
          header={t('actionPlan:Weigh')}
          headerStyle={{ width: '50px' }}
          editor={(props) => WeighEditor(props)}
          className="text-center"
        >
        </Column>
        <Column
          field="EsSeleccionada"
          header={t('actionPlan:PickOne')}
          headerStyle={{ width: '50px' }}
          body={(rowData, props) => PickOneColumn(rowData, props)}
        >
        </Column>
      </FieldDataTable>
    </>
  );

  const RenderResponsableList = (
    <>
      <FieldDataTable
        value={showActiveRows(ResponsablesList)}
        scrollable
        scrollHeight="150px"
        className={`${headerColor}`}
        editMode="cell"
        selectionMode="single"
        header={headerResponsable}
        paginator={false}
      >
        <Column
          body={(rowData, props) => fieldsRequiredActionPlan(rowData, props)}
          headerStyle={{ width: '20px' }}
        >
        </Column>
        <Column
          body={(rowData, props) => actionBodyTemplateActionPlan(rowData, props)}
          headerStyle={{ width: '40px' }}
        >
        </Column>
        <Column
          field="cClaveTarea"
          header={t('actionPlan:numeral')}
          headerStyle={{ width: '40px' }}
        >
        </Column>
        <Column
          field="cDescAccion"
          header={t('actionPlan:Action')}
          headerStyle={{ width: '280px' }}
          editor={(props) => Descripcionditor(props)}
        >
        </Column>
        <Column
          field="NomColaborador"
          header={t('actionPlan:Owner')}
          headerStyle={{ width: '280px' }}
          columnKey="ClaColaborador"
          editor={(props) => ResponsibleEditor(props)}
        >
        </Column>
        <Column
          field="AddNote"
          header={t('actionPlan:Notes')}
          headerStyle={{ width: '70px', display: IdPlanAccion === null ? 'none' : '' }}
          style={{ display: IdPlanAccion === null ? 'none' : '' }}
          body={AddNoteTemplate}
          className="text-center"
        >
        </Column>
        <Column
          field="cFechaCompromiso"
          header={t('actionPlan:DueDate')}
          headerStyle={{ width: '120px' }}
          body={FechaCompromisoTemplate}
          editor={(props) => FechaCompromisoEditor(props)}
        >
        </Column>
        <Column
          field="cFechaTermino"
          header={t('actionPlan:Completed')}
          headerStyle={{ width: '120px', display: IdPlanAccion === null ? 'none' : '' }}
          style={{ display: IdPlanAccion === null ? 'none' : '' }}
          body={FechaEntregaTemplate}
          editor={(props) => FechaEntregaEditor(props)}
        >
        </Column>
      </FieldDataTable>
    </>
  );

  const onchangeCausaRaiz = ( value ) => {    
    setCausaRaiz(value);     
  }; 
  const onchangeAgregarAcciones = ( value ) => {    
    setAgregarAcciones(value);     
  }; 
  const onchangeCrearActionPlan = ( value ) => {    
    setCrearActionPlan(value);     
  }; 

  const onChangeIncluirSoloUsuariosMSW = (props, e) => {
    setEsMostrarSoloUsuarioMSW(e.checked ? 1 : 0); 
  };
  // Cuando es un nuevo plan de accion lo renderizamos en modo wizard, para llevar al usuario
  // paso a paso o cuando se decide no crear action plan por que es manager del kpi

  if (IdPlanAccion === null || (IdPlanAccion > 0 && showAsEmptyActionPlan)) {
    return (
      <>
        <Dialog
          visible={props.show}
          modal 
          footer={renderFooter}
          header={renderHeader}
          onHide={onHideAP}
          closable={false}
          className="dialog-header-background-blue" 
          maximized={true}
          draggable={false}
        >
          <Row className="align-items-start pt-3">
            <Col lg={6} md={6} sm={12} xs={12}>
              <h2>{TopicoAnalisis}</h2>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Row>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <span className="dark-color h2">{t('actionPlan:CurrentResult')}</span>: &nbsp;
                  <span className="h2">{Highcharts.numberFormat(CurrRes, 2)}</span>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <span className="dark-color h2">{t('actionPlan:PlanedResult')}</span>: &nbsp;
                  <span className="h2">{Highcharts.numberFormat(PlanRes, 2)}</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="align-items-start pt-3" hidden={activeIndex === -1}>
            <Col lg={12} md={12} sm={12} xs={12} className="text-center">
              <Steps
                model={stepItems}
                activeIndex={activeIndex}
                onSelect={(e) => setActiveIndex(e.index)}
                readOnly={true}
              />
            </Col>
          </Row> 
          <Row className="pt-3 pb-3" hidden={activeIndex !== -1}>
            <Col lg={12}>
              <Row className="align-items-start" hidden={PosicionesRelacionadasList.length === 0 && ComposicionList.length === 0}>
                <Col lg={9} md={9} sm={12} xs={12}>              
                  <Row className="pt-3 pb-3" hidden={PosicionesRelacionadasList.length === 0}>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <FieldDataTable
                        value={showActiveRows(PosicionesRelacionadasList)}
                        scrollable
                        scrollHeight="330px"
                // className={`${headerColor}`}
                        editMode="cell"
                        selectionMode="single"
                        header={headerPosicionesRelacionadas}
                        paginator={false}
                      >
                        <Column
                          field="IndexRole"
                          header={t('actionPlan:IndexRole')}
                          className="text-center"
                          columnKey="IndexRole"
                          headerStyle={{ width: '45px', textAlign: 'center' }}
                        >
                        </Column>
                        <Column
                          field="IndexDescription"
                          header={t('actionPlan:IndexDescription')}
                          headerStyle={{ width: '280px' }}
                        >
                        </Column>
                        <Column
                          field="PositionName"
                          header={t('actionPlan:PositionName')}
                          headerStyle={{ width: '250px' }}
                          body={(rowData, props) => BodyTemplatePositionName(rowData, props)}
                        >
                        </Column>
                        {/* <Column
                      field="Responsible"
                      header={t('actionPlan:Responsible')}
                      headerStyle={{ width: '150px' }}
                      columnKey="Responsible"
                    >
                    </Column> */}
                        <Column
                          field="CurrentStatus"
                          header={t('actionPlan:CurrentStatus')}
                          headerStyle={{ width: '80px' }}
                          className="text-center"
                          body={EstatusPeriodoTemplate}
                        >
                        </Column>
                        <Column
                          className="text-center"
                          header={t('actionPlan:AP')}
                          headerStyle={{ width: '30px', textAlign: 'center' }}
                          body={AccionesTemplateAp}
                        >
                        </Column>
                        <Column
                          field="Result"
                          header={t('actionPlan:ResultStatusPlan')}
                          headerStyle={{ width: '300px' }}
                        >
                        </Column>
                   
                      </FieldDataTable>
                    </Col>
                  </Row> 
                  <Row className="pt-3 pb-3" hidden={ComposicionList.length === 0}>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <FieldDataTable
                        value={showActiveRows(ComposicionList)}
                        scrollable
                        scrollHeight="330px"
                // className={`${headerColor}`}
                        editMode="cell"
                        selectionMode="single"
                        header={headerComposicion}
                        paginator={false}
                      >
                        <Column
                          field="IndexType"
                          header={t('actionPlan:IndexType')}
                          className="text-center"
                          columnKey="IndexType"
                          headerStyle={{ width: '45px', textAlign: 'center' }}
                        >
                        </Column>
                        <Column
                          field="IndexDescription"
                          header={t('actionPlan:IndexDescription')}
                          headerStyle={{ width: '280px' }}
                        >
                        </Column>
                        <Column
                          field="PositionName"
                          header={t('actionPlan:PositionName')}
                          headerStyle={{ width: '250px' }}
                          body={(rowData, props) => BodyTemplatePositionName(rowData, props)}
                        >
                        </Column>
                        {/* <Column
                      field="Responsible"
                      header={t('actionPlan:Responsible')}
                      headerStyle={{ width: '150px' }}
                      columnKey="Responsible"
                    >
                    </Column> */}
                        <Column
                          field="CurrentStatus"
                          header={t('actionPlan:CurrentStatus')}
                          headerStyle={{ width: '80px' }}
                          className="text-center"
                          body={EstatusPeriodoTemplate}
                        >
                        </Column>
                        <Column
                          className="text-center"
                          header={t('actionPlan:AP')}
                          headerStyle={{ width: '30px', textAlign: 'center' }}
                          body={AccionesTemplateAp}
                        >
                        </Column>
                        <Column
                          field="ResultVsPlan"
                          header={t('actionPlan:ResultStatusPlan')}
                          headerStyle={{ width: '300px' }}
                        >
                        </Column>                   
                      </FieldDataTable>
                    </Col>
                  </Row>
                </Col>
                <Col lg={3} md={3} sm={12} xs={12}>
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>   
                      <span className="instruccions-legend">
                        {t('actionPlan:CausaRaizPretunta')}
                      </span>
                      <div className="p-fluid">
                        <FieldSelectButton 
                          name="CausaRaiz"
                          value={CausaRaiz} 
                          options={OpcionesCausaRaiz} 
                          onChange={(e) => onchangeCausaRaiz(e.value)} 
                          optionValue="value"
                          optionLabel="name"
                          disabled={!IsAtLeastOneActionPlanOpen}
                        /> 
                      </div>
                    </Col>
                  </Row> 
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>   
                      <span className="instruccions-legend">
                        {t('actionPlan:ActionPregunta')}
                      </span>
                      <div className="p-fluid">
                        <FieldSelectButton 
                          name="AgregarAcciones"
                          value={AgregarAcciones} 
                          options={OpcionesAgregarAcciones} 
                          onChange={(e) => onchangeAgregarAcciones(e.value)} 
                          optionValue="value"
                          optionLabel="name"
                          disabled={!IsAtLeastOneActionPlanOpen}
                        /> 
                      </div>
                    </Col>
                  </Row> 
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>   
                      <span className="instruccions-legend">
                        {t('actionPlan:CreateActionPlanPregunta')}
                      </span>
                      <div className="p-fluid">
                        <FieldSelectButton 
                          name="CrearActionPlan"
                          value={CrearActionPlan} 
                          options={OpcionesCrearActionPlan} 
                          onChange={(e) => onchangeCrearActionPlan(e.value)} 
                          optionValue="value"
                          optionLabel="name"
                        /> 
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} className="text-center">
                      <FieldButton
                        label={t('actionPlan:btnStartCreateActionPlan')}
                        icon="fas fa-play"
                        className="success no-border p-mr-2 rounded-button"
                        onClick={goToStep1}
                        hidden={CrearActionPlan!==1}
                      />  
                      <FieldButton
                        label={t('actionPlan:btnActionPlanNotNeeded')}
                        icon="fas fa-check-circle"
                        className="success no-border p-mr-2 rounded-button"
                        hidden={CrearActionPlan===1 || CrearActionPlan === null || (CausaRaiz === 0 || CausaRaiz === null || AgregarAcciones === 0 || AgregarAcciones === null)}
                        onClick={onSaveActionPlanAsReviewedClick}
                      />  
                    </Col> 
                  </Row> 
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <InstructionsCard>
                        <p>
                          <i className="fas fa-info-circle"></i>&nbsp;{t('actionPlan:HelpReviewActionPlan')}
                        </p> 
                      </InstructionsCard>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="pt-3 pb-3" hidden={activeIndex !== 0}>
            <Col lg={2}></Col>
            <Col lg={8}>
              <InstructionsCard className="transparent">
                <span className="instruccions-legend">
                  {t('actionPlan:InstruccionesGeneralesPaso1')}
                </span>
              </InstructionsCard>
            </Col>
            <Col lg={2}></Col>
          </Row> 
          <Row className="align-items-start" hidden={activeIndex !== 0}>
            <Col lg={12} md={12} sm={12} xs={12}>
              {RenderDescription}
            </Col>
          </Row>
          <Row className="pb-3" hidden={activeIndex !== 1}>
            <Col lg={2}>&nbsp;</Col>
            <Col lg={8}>
              <InstructionsCard className="transparent">
                <span className="instruccions-legend">
                  {t('actionPlan:InstruccionesGeneralesPaso2')}
                </span>
              </InstructionsCard>
            </Col>
            <Col lg={2}>&nbsp;</Col>
          </Row>
          <Row className="align-items-start" hidden={activeIndex !== 1}>
            <Col lg={4} md={4} sm={12} xs={12}>
              {RenderDescription}
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              {RenderCausaRaiz}
            </Col>
          </Row>

          <Row className="pb-3" hidden={activeIndex !== 2}>
            <Col lg={2}>&nbsp;</Col>
            <Col lg={8}>
              <InstructionsCard className="transparent">
                <span className="instruccions-legend">
                  {t('actionPlan:InstruccionesGeneralesPaso3')}
                </span>
              </InstructionsCard>
            </Col>
            <Col lg={2}>&nbsp;</Col>
          </Row>
          <div hidden={activeIndex !== 2}>
            <Row className="align-items-start">
              <Col lg={4} md={4} sm={12} xs={12}>
                {RenderDescription}
              </Col>
              <Col lg={8} md={8} sm={12} xs={12}>
                {RenderCausaRaiz}
              </Col>
            </Row>
            <Row>
              <Col>
                    &nbsp;
              </Col>
            </Row>
            <Row hidden={HistorialPendientesPrevios.length === 0}>
              <Col lg={12} md={12} sm={12} xs={12}>
                <InstructionsCard className="transparent">
                  <span className="instruccions-legend">
                    {t('actionPlan:PendingActionsAvailable').replace('[TopicAnalisis]', TopicoAnalisis)}.
                  </span>
                </InstructionsCard>

                <FieldDataTable
                  value={showActiveRows(HistorialPendientesPrevios)}
                  scrollable
                  scrollHeight="330px"
                  className={`${headerColor}`}
                  editMode="cell"
                  selectionMode="single" 
                  paginator={false}
                >
                  <Column
                    field="cFechaReporte"
                    header={t('actionPlan:StatusDate')}
                    headerStyle={{ width: '100px' }}
                    body={FechaReportePendingActionTemplate}
                  >
                  </Column>

                  <Column
                    field="cDescAccion"
                    header={t('actionPlan:Action')}
                    headerStyle={{ width: '280px' }}
                  >
                  </Column>
                  <Column
                    field="NomColaborador"
                    header={t('actionPlan:Owner')}
                    headerStyle={{ width: '150px' }}
                    columnKey="NomColaborador"
                  >
                  </Column>  
                  <Column
                    field="cFechaCompromiso"
                    header={t('actionPlan:DueDate')}
                    headerStyle={{ width: '120px' }}
                    body={FechaCompromisoTemplate}
                  >
                  </Column> 
                  <Column 
                    headerStyle={{ width: '120px' }}
                    className="text-center"
                    body={CopyButtonTemplate}
                  >
                  </Column> 
                  
                </FieldDataTable>
              </Col>
            </Row>
            <Row className="pt-3">
              <Col lg={12}>{RenderResponsableList}</Col>
            </Row>
          </div>
          <Row className="pb-3" hidden={activeIndex !== 3}>
            <Col lg={2}>&nbsp;</Col>
            <Col lg={8}>
              <InstructionsCard className="transparent">
                <span className="instruccions-legend">
                  {t('actionPlan:InstruccionesGeneralesPaso4')}
                </span>
              </InstructionsCard>
            </Col>
            <Col lg={2}>&nbsp;</Col>
          </Row>
          <div hidden={activeIndex !== 3}>
            <Row className="align-items-start">
              <Col lg={4} md={4} sm={12} xs={12}>
                {RenderDescription}
              </Col>
              <Col lg={8} md={8} sm={12} xs={12}>
                {RenderCausaRaiz}
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12} className="text-right">
                &nbsp;
              </Col>
            </Row>
            <Row className="align-items-start pb-3">
              <Col lg={12} md={12} sm={12} xs={12}>
                {RenderResponsableList}
              </Col>
            </Row>
          </div>

          <Dialog
            visible={EsMostrarDetalleObjetivos}
            header=""
            style={dialogStyle} 
            modal
            className="dialog-transparent"
            contentClassName="dialog-content-transparent"
            closable={false} 
            onHide={()=> setEsMostrarDetalleObjetivos(false)}
          >
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}> 
                          &nbsp;
              </Col> 
            </Row> 
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}> 
                <KPIObjectivesDetail
                  ClaDepartamento={KPISelected.ClaDepartamento} 
                  ClaArea={KPISelected.ClaArea}
                  ClaTipoIndicador={KPISelected.ClaTipoIndicador}
                  ClaIndicador={KPISelected.ClaIndicador}
                  ClaPuesto={KPISelected.ClaPuesto}
                  ClaAnio={KPISelected.ClaAnio}
                  showCloseButton={true}
                  NomKPI={KPISelected.IndexDescription}
                  onCloseClick={()=> setEsMostrarDetalleObjetivos(false)} 
                >
                </KPIObjectivesDetail>
              </Col>
            </Row>
          </Dialog>
          {
        EsReviewActionPlan ?
        (  
          <> 
            <ActionPlanQuickview show={EsReviewActionPlan} onHide={onHideActionPlanReview} IdCSF={IdCSFSel} ClaPeriodo={ClaPeriodoSel} ClaFechaReporte={ClaFechaReporteSel} ActionPlanList={ActionPlanList}></ActionPlanQuickview>
          </>
        )
        :
        (<></>)
      } 
        </Dialog>  
       
      </>
    );
  }

  return (
    <>
      <Dialog
        visible={props.show}
        modal
        style={{ width: '100vw' }}
        footer={renderFooter}
        header={renderHeader}
        onHide={onHideAP}
        closable={false}
        className="dialog-header-background-blue"
        maximized={true}
        draggable={false}
      >
        <Row className="align-items-start pt-3 pb-3">
          <Col lg={6} md={6} sm={12} xs={12}>
            <h2>{TopicoAnalisis}</h2>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <Row>
              <Col lg={4} md={5} sm={12} xs={12}>
                <span className="dark-color h2">{t('actionPlan:CurrentResult')}</span>: &nbsp;
                <span className="h2">{Highcharts.numberFormat(CurrRes, 2)}</span>
              </Col>
              <Col lg={4} md={5} sm={12} xs={12}>
                <span className="dark-color h2">{t('actionPlan:PlanedResult')}</span>: &nbsp;
                <span className="h2">{Highcharts.numberFormat(PlanRes, 2)}</span>
              </Col>
              <Col lg={4} md={2} sm={12} xs={12}>
                <div>
                  <FieldButton
                    label={t('scoreCard:APSupportFiles')}
                    icon="pi pi-paperclip"
                    className="light-blue no-border rounded-button font-weight-bold"
                    onClick={() => AbrirAnexo()}
                  >
                    <div className="" hidden={ActionPlanFileList.length === 0}>
                      <Badge value={ActionPlanFileList.length} className="warning" />
                    </div>
                  </FieldButton>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="pt-3 pb-3">
          <Col lg={12}>
            <Row className="align-items-start" hidden={PosicionesRelacionadasList.length === 0 && ComposicionList.length === 0}>
              <Col lg={9} md={9} sm={12} xs={12}>              
                <Row className="pt-3 pb-3" hidden={PosicionesRelacionadasList.length === 0}>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <FieldDataTable
                      value={showActiveRows(PosicionesRelacionadasList)}
                      scrollable
                      scrollHeight="330px"
                // className={`${headerColor}`}
                      editMode="cell"
                      selectionMode="single"
                      header={headerPosicionesRelacionadas}
                      paginator={false}
                    >
                      <Column
                        field="IndexRole"
                        header={t('actionPlan:IndexRole')}
                        className="text-center"
                        columnKey="IndexRole"
                        headerStyle={{ width: '45px', textAlign: 'center' }}
                      >
                      </Column>
                      <Column
                        field="IndexDescription"
                        header={t('actionPlan:IndexDescription')}
                        headerStyle={{ width: '280px' }}
                      >
                      </Column>
                      <Column
                        field="PositionName"
                        header={t('actionPlan:PositionName')}
                        headerStyle={{ width: '250px' }}
                        body={(rowData, props) => BodyTemplatePositionName(rowData, props)}
                      >
                      </Column>
                      {/* <Column
                      field="Responsible"
                      header={t('actionPlan:Responsible')}
                      headerStyle={{ width: '150px' }}
                      columnKey="Responsible"
                    >
                    </Column> */}
                      <Column
                        field="CurrentStatus"
                        header={t('actionPlan:CurrentStatus')}
                        headerStyle={{ width: '80px' }}
                        className="text-center"
                        body={EstatusPeriodoTemplate}
                      >
                      </Column>
                      <Column
                        className="text-center"
                        header={t('actionPlan:AP')}
                        headerStyle={{ width: '30px', textAlign: 'center' }}
                        body={AccionesTemplateAp}
                      >
                      </Column>
                      <Column
                        field="Result"
                        header={t('actionPlan:ResultStatusPlan')}
                        headerStyle={{ width: '300px' }}
                      >
                      </Column>
                   
                    </FieldDataTable>
                  </Col>
                </Row> 
                <Row className="pt-3 pb-3" hidden={ComposicionList.length === 0}>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <FieldDataTable
                      value={showActiveRows(ComposicionList)}
                      scrollable
                      scrollHeight="330px"
                // className={`${headerColor}`}
                      editMode="cell"
                      selectionMode="single"
                      header={headerComposicion}
                      paginator={false}
                    >
                      <Column
                        field="IndexType"
                        header={t('actionPlan:IndexType')}
                        className="text-center"
                        columnKey="IndexType"
                        headerStyle={{ width: '45px', textAlign: 'center' }}
                      >
                      </Column>
                      <Column
                        field="IndexDescription"
                        header={t('actionPlan:IndexDescription')}
                        headerStyle={{ width: '280px' }}
                      >
                      </Column>
                      <Column
                        field="PositionName"
                        header={t('actionPlan:PositionName')}
                        headerStyle={{ width: '250px' }}
                        body={(rowData, props) => BodyTemplatePositionName(rowData, props)}
                      >
                      </Column>
                      {/* <Column
                      field="Responsible"
                      header={t('actionPlan:Responsible')}
                      headerStyle={{ width: '150px' }}
                      columnKey="Responsible"
                    >
                    </Column> */}
                      <Column
                        field="CurrentStatus"
                        header={t('actionPlan:CurrentStatus')}
                        headerStyle={{ width: '80px' }}
                        className="text-center"
                        body={EstatusPeriodoTemplate}
                      >
                      </Column>
                      <Column
                        className="text-center"
                        header={t('actionPlan:AP')}
                        headerStyle={{ width: '30px', textAlign: 'center' }}
                        body={AccionesTemplateAp}
                      >
                      </Column>
                      <Column
                        field="ResultVsPlan"
                        header={t('actionPlan:ResultStatusPlan')}
                        headerStyle={{ width: '300px' }}
                      >
                      </Column>                   
                    </FieldDataTable>
                  </Col>
                </Row>
              </Col>
              <Col lg={3} md={3} sm={12} xs={12}>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>   
                    <span className="instruccions-legend">
                      {t('actionPlan:CausaRaizPretunta')}
                    </span>
                    <div className="p-fluid">
                      <FieldSelectButton 
                        name="CausaRaiz"
                        value={CausaRaiz} 
                        options={OpcionesCausaRaiz} 
                        onChange={(e) => onchangeCausaRaiz(e.value)} 
                        optionValue="value"
                        optionLabel="name"
                        disabled={!IsAtLeastOneActionPlanOpen}
                      /> 
                    </div>
                  </Col>
                </Row> 
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>   
                    <span className="instruccions-legend">
                      {t('actionPlan:ActionPregunta')}
                    </span>
                    <div className="p-fluid">
                      <FieldSelectButton 
                        name="AgregarAcciones"
                        value={AgregarAcciones} 
                        options={OpcionesAgregarAcciones} 
                        onChange={(e) => onchangeAgregarAcciones(e.value)} 
                        optionValue="value"
                        optionLabel="name"
                        disabled={!IsAtLeastOneActionPlanOpen}
                      /> 
                    </div>
                  </Col>
                </Row> 
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>   
                    <span className="instruccions-legend">
                      {t('actionPlan:CreateActionPlanPregunta')}
                    </span>
                    <div className="p-fluid">
                      <FieldSelectButton 
                        name="CrearActionPlan"
                        value={CrearActionPlan} 
                        options={OpcionesCrearActionPlan} 
                        onChange={(e) => onchangeCrearActionPlan(e.value)} 
                        optionValue="value"
                        optionLabel="name"
                      /> 
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} className="text-center">
                    <FieldButton
                      label={t('actionPlan:btnStartCreateActionPlan')}
                      icon="fas fa-play"
                      className="success no-border p-mr-2 rounded-button"
                      onClick={goToStep1}
                      hidden={CrearActionPlan!==1}
                    />  
                    <FieldButton
                      label={t('actionPlan:btnActionPlanNotNeeded')}
                      icon="fas fa-check-circle"
                      className="success no-border p-mr-2 rounded-button"
                      hidden={CrearActionPlan===1 || CrearActionPlan === null || (CausaRaiz === 0 || CausaRaiz === null || AgregarAcciones === 0 || AgregarAcciones === null)}
                      onClick={onSaveActionPlanAsReviewedClick}
                    />  
                  </Col> 
                </Row> 
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <InstructionsCard>
                      <p>
                        <i className="fas fa-info-circle"></i>&nbsp;{t('actionPlan:HelpReviewActionPlan')}
                      </p> 
                    </InstructionsCard>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="pt-3 pb-3">
          <Col lg={2}></Col>
          <Col lg={8}>
            <InstructionsCard className="transparent">
              <span className="instruccions-legend">
                {t('actionPlan:InstruccionesGeneralesPaso1')}
              </span>
            </InstructionsCard>
          </Col>
          <Col lg={2}></Col>
        </Row>
        <Row className="align-items-start">
          <Col lg={4} md={4} sm={12} xs={12}>
            {RenderDescription}
          </Col>
          <Col lg={8} md={8} sm={12} xs={12}>
            {RenderCausaRaiz}
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="text-right">
            &nbsp;
          </Col>
        </Row>
        <Row className="align-items-start pb-3">
          <Col lg={12} md={12} sm={12} xs={12}>
            {RenderResponsableList}
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="text-right"></Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <FieldDataTable
              value={showActiveRows(HistorialPendientesList)}
              scrollable
              scrollHeight="330px"
              className={`${headerColor}`}
              editMode="cell"
              selectionMode="single"
              header={headerPendiente}
              paginator={false}
            >
              <Column
                field="cFechaReporte"
                header={t('actionPlan:StatusDate')}
                headerStyle={{ width: '100px' }}
                body={FechaReportePendingActionTemplate}
              >
              </Column>

              <Column
                field="cDescAccion"
                header={t('actionPlan:Action')}
                headerStyle={{ width: '280px' }}
              >
              </Column>
              <Column
                field="NomColaborador"
                header={t('actionPlan:Owner')}
                headerStyle={{ width: '150px' }}
                columnKey="NomColaborador"
              >
              </Column>
              <Column
                field="AddNote"
                header={t('actionPlan:Notes')}
                headerStyle={{ width: '70px' }}
                body={AddNotePendingActionTemplate}
                className="text-center"
              >
              </Column>
              <Column
                field="cFechaCompromiso"
                header={t('actionPlan:DueDate')}
                headerStyle={{ width: '120px' }}
                body={FechaCompromisoTemplate}
              >
              </Column>
              <Column
                field="cFechaTermino"
                header={t('actionPlan:Completed')}
                headerStyle={{ width: '120px' }}
                body={FechaEntregaPendingActionTemplate}
                editor={(props) => FechaEntregaPendingActionEditor(props)}
              >
              </Column>
            </FieldDataTable>
          </Col>
        </Row>
        <Dialog
          visible={EsVerAnexo}
          header={t('actionPlan:ActionDocuments')}
          style={dialogStyle}
          modal
          className="dialog-custom"
          onHide={closeModal}
        >
          <Row>
            <Col>
              <ActionPlanAttachment
                IsUpload={true}
                IdCSF={props.IdCSF}
                ClaPeriodo={props.ClaPeriodo}
                ClaFechaReporte={props.ClaFechaReporte}
                obtenerInformacionActionPlanFile={obtenerInformacionActionPlanFile}
              >
              </ActionPlanAttachment>
            </Col>
          </Row>
        </Dialog>
        <Dialog
          visible={EsAbrirNotas}
          header={t('actionPlan:tooltipComentarios')}
          style={dialogStyle}
          modal
          className="dialog-custom"
          onHide={closeModalNotas}
        >
          <Row>
            <Col>
              <ActionPlanComments
                RowDataNotas={RowDataNotas}
                IdCSF={props.IdCSF}
                ClaPeriodo={props.ClaPeriodo}
                ClaFechaReporte={props.ClaFechaReporte}
                closeModalNotas={closeModalNotas}
                IdPlanAccion={IdPlanAccion}
                ObtenerResponsablesActionPlan={ObtenerResponsablesActionPlan}
                ObtenerHistorialPendientesActionPlan={ObtenerHistorialPendientesActionPlan}
              >
              </ActionPlanComments>
            </Col>
          </Row>
        </Dialog>
        <Dialog
          visible={EsMostrarDetalleObjetivos}
          header=""
          style={dialogStyle} 
          modal
          className="dialog-transparent"
          contentClassName="dialog-content-transparent"
          closable={false} 
          onHide={()=> setEsMostrarDetalleObjetivos(false)}
        >
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
                          &nbsp;
            </Col> 
          </Row> 
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
              <KPIObjectivesDetail
                ClaDepartamento={KPISelected.ClaDepartamento} 
                ClaArea={KPISelected.ClaArea}
                ClaTipoIndicador={KPISelected.ClaTipoIndicador}
                ClaIndicador={KPISelected.ClaIndicador}
                ClaPuesto={KPISelected.ClaPuesto}
                ClaAnio={KPISelected.ClaAnio}
                showCloseButton={true}
                NomKPI={KPISelected.IndexDescription}
                onCloseClick={()=> setEsMostrarDetalleObjetivos(false)}
              >
              </KPIObjectivesDetail>
            </Col>
          </Row>
        </Dialog>
      </Dialog> 
      {
        EsReviewActionPlan ?
        (  
          <> 
            <ActionPlanQuickview show={EsReviewActionPlan} onHide={onHideActionPlanReview} IdCSF={IdCSFSel} ClaPeriodo={ClaPeriodoSel} ClaFechaReporte={ClaFechaReporteSel} ActionPlanList={ActionPlanList}></ActionPlanQuickview>
          </>
        )
        :
        (<></>)
      } 
    </>
  );
};
export default ActionPlan;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next'; 
import PageContent from '../../../layouts/PageContent';  
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Row, Col, Card,
  CardHeader,
  CardBody, } from 'reactstrap';   
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';  
import FeedbackCoaching from './FeedbackCoaching.jsx'; 
import ScoreSummary from './ScoreSummary.jsx';
import TeamPerformance from './TeamPerformance.jsx';
import { Link } from "react-router-dom";
import { config } from '../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../utils/utils'; 
import FieldDropdown from '../../../components/Controls/FieldDropdown.jsx';  

const PerformanceDevelpmentHome = (props) => {  
    const { t } = useTranslation(['hr', 'common']);
    
    const annualReviewLnk = useRef(null); 
    
    const [performanceDevelopmentInfo, setPerformanceDevelopmentInfo] = useState([]);  
    const [ClaAnio, setClaAnio] = useState(null);
    
    const [Anio, setAnio] = useState(null);
    const [TotalDependientesDirectos, setTotalDependientesDirectos] = useState(null);
    const [EstatusAnnualReview, setEstatusAnnualReview] = useState(null);

    useEffect(() => {     
      loadTeamPerformanceDevelopment();   
    }, [props.Anio]); 

    const loadTeamPerformanceDevelopment = () => { 
      const GetPerformanceDevelopmentByUserList = `${config.UrlApiProject}hr/GetPerformanceDevelopmentByUserList`;  
      const paramsToService = { };   
      callApi(GetPerformanceDevelopmentByUserList, 'GET', paramsToService, (response) => { 
        setPerformanceDevelopmentInfo(response.data.PerformanceDevelopmentInfo);  
        if (response.data.PerformanceDevelopmentInfo.length>0){
          onAnioChange(response.data.PerformanceDevelopmentInfo[response.data.PerformanceDevelopmentInfo.length-1].Anio, response.data.PerformanceDevelopmentInfo);
        }
        /*
         : Array(1) 
            Activa180DegreeReview: 1
            Anio: 2021
            Comentarios: null
            EsCompanyCultureRevisado: 0
            EsContribucionRevisado: 0
            EsEmployeeSelfReviewRevisado: 0
            EsManagerReviewRevisado: 0
            Estatus180DegreeManagerReview: 0
            Estatus180DegreeSelfReview: 0
            EstatusAnnualReview: 0
            EstatusContribucionReview: 0
            EstatusEmployeeSelfReview: 1
            EstatusManagerReview: 0
            IdPerformanceDev: 1 
        */
      });
    }

    const onAnnualReviewClick = () => {
      annualReviewLnk.current.click();
    } 

    const onAnioChange = (value, options) => {
      setClaAnio(value);   
      const selectedYear = options.filter( opc => opc.Anio === value); 
      const element = selectedYear.length > 0 ? selectedYear[0] : {}; 
      
      setAnio(element.Anio);
      setTotalDependientesDirectos(element.TotalDependientesDirectos);
      setEstatusAnnualReview(element.EstatusAnnualReview);

    } 

    const panelHeaderTemplate = (options) => { 
        const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
        const className = `${options.className} ${options.collapsed ? ' collapsed-header' : ''}`;
        const titleClassName = `${options.titleClassName} p-pl-1`;

        return (
          <div className={className}> 
            <span className={titleClassName}> 
              <FieldDropdown 
                name="Anio"
                value={ClaAnio} 
                className="border-none background-none title info-dark-color"
                options={performanceDevelopmentInfo} 
                onChange={(e) => onAnioChange(e.value, performanceDevelopmentInfo)} 
                optionValue="Anio"
                optionLabel="Anio" 
                placeholder={t('common:SelectYear')} 
                showClear={false}
              /> 

            </span>
            <div className="p-panel-icons">
              <FieldButton className={options.togglerClassName} onClick={options.onTogglerClick}>
                <span className={toggleIcon}></span>
                <Ripple />
              </FieldButton>
            </div>
            
          </div>
        )
    }
    
    if (Anio === null)
    {
      return (<></>);
    }   
 
    return (
      <>  
        <PageContent title={t('hr:PerformanceDevelopment')}>  
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>    
              <Panel key={`panel-${0}`} headerTemplate={panelHeaderTemplate} header={Anio} className="panel-skyblue mb-4" collapsed={false} toggleable>
                <Row className="align-items-start mb-4">
                  <Col lg={TotalDependientesDirectos === 0 ? 12 : 6} md={12} sm={12} xs={12}>  
                    <Card> 
                      <CardBody>
                        <h1>{t('hr:MytotalSuccessPathway')}</h1>
                        <br></br>
                        <Row className="align-items-start mb-4">
                          <Col lg={6} md={12} sm={12} xs={12}>  
                            <FeedbackCoaching Anio={Anio}></FeedbackCoaching>
                          </Col> 
                          <Col lg={6} md={12} sm={12} xs={12}>  
                            <ScoreSummary Anio={Anio}></ScoreSummary> 
                          </Col> 
                        </Row> 
                        {
                                    EstatusAnnualReview === 1 ? (
                                      <Row className="align-items-start">
                                        <Col lg={12} md={12} sm={12} xs={12}>  
                                          <FieldButton icon="pi annual-icon" label={`Performance Development Review ${Anio}`} onClick={onAnnualReviewClick} className="dark-purple-gradient no-border w-100 rounded-button font-weight-bold">
                                            <i className="pi pi-arrow-right"></i>
                                          </FieldButton>  
                                          <Link
                                            ref={annualReviewLnk} 
                                            to={{ pathname: "AnnualReview"
                                        , search: `?Anio=${Anio}&ActiveStep=3` 
                                        }}
                                          >
                                          </Link> 
                                        </Col>
                                      </Row>
                                )
                                : <></>

                                }
                      </CardBody>
                    </Card>
                  </Col>
                  {TotalDependientesDirectos > 0 ? (
                    <Col lg={6} md={12} sm={12} xs={12}> 
                      <TeamPerformance Anio={Anio}></TeamPerformance>
                    </Col>
                              ) : <></> }
                            
                </Row>  
              </Panel>  
            </Col> 
          </Row> 
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>  
            &nbsp;
            </Col>
          </Row>
         
        </PageContent>
      </>  
        ); 
};

export default PerformanceDevelpmentHome;

/* eslint-disable jsx-a11y/label-has-associated-control */
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import FieldDateRange from 'components/Controls/FieldDateRange/FieldDateRange';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PageContent from 'layouts/PageContent';
import moment from "moment";
import { Column } from 'primereact/column';
import React, { useEffect, useState, useRef  } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Label, Row } from 'reactstrap';
import { config } from 'utils/config';
import { callApi } from 'utils/utils';
import UsageTrackingByUserDialog from './UsageTrackingByUserDialog/UsageTrackingByUserDialog';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import UsageTrackingUsersDialog from './UsageTrackingUsersDialog/UsageTrackingUsersDialog';
import FieldDropdown from 'components/Controls/FieldDropdown';
import { Button } from 'primereact/button';
import FieldText from 'components/Controls/FieldText';

const UsageTrackingScreen = (props) => {
  const {t, i18n} = useTranslation(['it', 'common']);
  const [TrackingByDateReport, setTrackingByDateReport] = useState([]);
  const [TrackingByDateSummary, setTrackingByDateSummary] = useState([]);
  const [TrackingByDateChartSeries, setTrackingByDateChartSeries] = useState([]);
  const [TrackingByDateChartCategories, setTrackingByDateChartCategories] = useState([]);
  const [TrackingByBrowserReport, setTrackingByBrowserReport] = useState([]);
  const [TrackingByBrowserSummary, setTrackingByBrowserSummary] = useState([]);
  const [TrackingByBrowserChartSeries, setTrackingByBrowserChartSeries] = useState([]);
  const [TrackingByScreenResolutionReport, setTrackingByScreenResolutionReport] = useState([]);
  const [TrackingByScreenResolutioSummary, setTrackingByScreenResolutioSummary] = useState([]);
  const [TrackingByScreenResolutionChartSeries, setTrackingByScreenResolutionChartSeries] = useState([]);
  const [TrackingByOSReport, setTrackingByOSReport] = useState([]);
  const [TrackingByOSSummary, setTrackingByOSSummary] = useState([]);
  const [TrackingByOSChartSeries, setTrackingByOSChartSeries] = useState([]);
  const [TrackingByLocationReport, setTrackingByLocationReport] = useState([]);
  const [TrackingByLocationSummary, setTrackingByLocationSummary] = useState([]);
  const [TrackingByLocationChartSeries, setTrackingByLocationChartSeries] = useState([]);
  const [TrackingByUserReport, setTrackingByUserReport] = useState([]);
  const [TrackingByURLReport, setTrackingByURLReport] = useState([]);  
  const [StartDate, setStartDate] = useState(new Date());
  const [EndDate, setEndDate] = useState(new Date());
  const [ClaTrackingPlatforma, setClaTrackingPlatforma] = useState(1);
  const [TrackingPlatforms, setTrackingPlatforms] = useState([]);
  const [SelectedClaUsuario, setSelectedClaUsuario] = useState(null);  
  const [SelectedURL, setSelectedURL] = useState(null);
  const [SelectedNavegador, setSelectedNavegador] = useState(null);
  const [SelectedScreenWidth, setSelectedScreenWidth] = useState(null);
  const [SelectedScreenHeight, setSelectedScreenHeight] = useState(null);
  const [SelectedSistemaOperativo, setSelectedSistemaOperativo] = useState(null);
  const [SelectedCountry, setSelectedCountry] = useState(null);
  const [SelectedCity, setSelectedCity] = useState(null);
  const [IsTrackingUsersDialogVisible, setIsTrackingUsersDialogVisible] = useState(false);
  const [TrackingUsersDialogTitle, setTrackingUsersDialogTitle] = useState('');
  const [IsTrackingByUserDialogVisible, setIsTrackingByUserDialogVisible] = useState(false);
  const dt = useRef(null);

  const trackingByDateChartOptions = {
    title: {
      text: t('usageTracking:TrafficOverview'),
      align: 'center'
    },  
    subtitle: {
      text: '',
      align: 'left'
    },  
    yAxis: {
      title: {
        text: t('usageTracking:NumberOfUsers')
      }
    },  
    xAxis: {
      categories: TrackingByDateChartCategories
    },  
    responsive: {
      rules: [{
        condition: {
          maxWidth: 700
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    },
    series: TrackingByDateChartSeries,   
  };
  const trackingByBrowserChartOptions = {
    chart: {
      type: 'pie',          
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    title: {
      text: t('usageTracking:Browsers'),
      align: 'center'
    },
    subtitle: {
      text: ""
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    tooltip: {      
      pointFormat: '<b>{point.percentage:.1f}%</b>'            
    }, 
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        }
      }
    },
    series: [{
      name: t('usageTracking:Browsers'),
      colorByPoint: true,
      data: TrackingByBrowserChartSeries
    }]
  };
  const trackingByscreenResolutionChartOptions = {
    chart: {
      type: 'pie',          
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    title: {
      text: t('usageTracking:ScreenResolutions'),
      align: 'center'
    },
    subtitle: {
      text: ""
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    tooltip: {      
      pointFormat: '<b>{point.percentage:.1f}%</b>'            
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        }
      }
    },
    series: [{
      name: t('usageTracking:ScreenResolutions'),
      colorByPoint: true,
      data: TrackingByScreenResolutionChartSeries
    }]
  };
  const trackingByOSChartOptions = {
    chart: {
      type: 'pie',          
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    title: {
      text: t('usageTracking:OperatingSystems'),
      align: 'center'
    },
    subtitle: {
      text: ""
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    tooltip: {      
      pointFormat: '<b>{point.percentage:.1f}%</b>'            
    }, 
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        }
      }
    },
    series: [{
      name: t('usageTracking:OperatingSystems'),
      colorByPoint: true,
      data: TrackingByOSChartSeries
    }]
  };
  const trackingByLocationChartOptions = {
    chart: {
      type: 'pie',          
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    title: {
      text: t('usageTracking:Locations'),
      align: 'center'
    },
    subtitle: {
      text: ""
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    tooltip: {      
      pointFormat: '<b>{point.percentage:.1f}%</b>'            
    }, 
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        }
      }
    },
    series: [{
      name: t('usageTracking:Locations'),
      colorByPoint: true,
      data: TrackingByLocationChartSeries
    }]
  };

  const getUsageTrackingInfo = (startDate, endDate, claTrackingPlataform) => {
    const url = `${config.UrlApiProject}IT/GetITUsageTrackingInfo`;       
    const paramsService = {
      StartDate: startDate,
      EndDate: endDate,
      ClaTrackingPlataforma: claTrackingPlataform
    };  
    callApi(url, 'POST', paramsService, (response) => {      
      setTrackingByDateReport(response.data.datesReport);      
      setTrackingByDateSummary(response.data.datesSummary);
      setTrackingByBrowserReport(response.data.browsersReport);
      setTrackingByBrowserSummary(response.data.browsersSummary);
      setTrackingByScreenResolutionReport(response.data.screenResolutionsReport);
      setTrackingByScreenResolutioSummary(response.data.screenResolutionsSummary);
      setTrackingByOSReport(response.data.operatingSystemsReport);
      setTrackingByOSSummary(response.data.operatingSystemsSummary);   
      setTrackingByLocationReport(response.data.locationsReport);
      setTrackingByLocationSummary(response.data.locationsSummary);
      setTrackingByUserReport(response.data.usersReport);      
      setTrackingByURLReport(response.data.pagesReport);
      setupTrackingByDateChart(response.data.datesReport);
      setupTrackingByBrowserChart(response.data.browsersReport, response.data.browsersSummary);      
      setupTrackingByScreenResolutionChart(response.data.screenResolutionsReport, response.data.screenResolutionsSummary)
      setupTrackingByOSChart(response.data.operatingSystemsReport, response.data.operatingSystemsSummary);
      setupTrackingByLocationChart(response.data.locationsReport, response.data.locationsSummary);      
    });
  };

  const getPlatforms = () => {
    const url = `${config.UrlApiProject}IT/GetITTrackingPlataformaCmb`;       
    const paramsService = {
      Valor: null,
      Tipo: null,
      ModoSel: 2,
      BajasSn: 0
    };  
    callApi(url, 'POST', paramsService, (response) => {      
      setTrackingPlatforms(response.data.trackingPlatforms);       
    });
  };

  const setupTrackingByDateChart = (report) => {
    const chartSeries = [];
    let chartCategories = [];
    if (report) {      
      chartCategories = report.map(({ FechaTrackingEventLog }) => FechaTrackingEventLog);          
      chartSeries.push({
        name: t('usageTracking:Users'),
        data: report.map(({ TotalUsuarios }) => TotalUsuarios)
      });
    }        
    setTrackingByDateChartSeries(chartSeries);
    setTrackingByDateChartCategories(chartCategories);
  };

  const setupTrackingByBrowserChart = (report, summary) => {
    const chartSeries = [];
    if (report) {
      report.forEach((item) => {
        chartSeries.push({
          name: item.Navegador,
          y: (item.PageViews / summary.PageViews) * 100
        });
      });
    }    
    setTrackingByBrowserChartSeries(chartSeries);
  };

  const setupTrackingByScreenResolutionChart = (report, summary) => {
    const chartSeries = [];
    if (report) {
      report.forEach((item) => {
        chartSeries.push({
          name: item.ScreenResolution,
          y: (item.PageViews / summary.PageViews) * 100
        });
      });
    }    
    setTrackingByScreenResolutionChartSeries(chartSeries);
  };

  const setupTrackingByOSChart = (report, summary) => {
    const chartSeries = [];
    if (report) {
      report.forEach((item) => {
        chartSeries.push({
          name: item.SistemaOperativo,
          y: (item.PageViews / summary.PageViews) * 100
        });
      });
    }    
    setTrackingByOSChartSeries(chartSeries);
  };

  const setupTrackingByLocationChart = (report, summary) => {
    const chartSeries = [];
    if (report) {
      report.forEach((item) => {
        chartSeries.push({
          name: item.Location,
          y: (item.PageViews / summary.PageViews) * 100
        });
      });
    }    
    setTrackingByLocationChartSeries(chartSeries);
  };

  const renderText = (rowData, columnName) => {
    return (
      <>  
        <div className='overflow-ellipsis' title={rowData[columnName]}>{rowData[columnName]}</div> 
      </>
    );
  }

  const resetSelectedDetail = (selected) => {
    setSelectedURL(null);
    setSelectedClaUsuario(null);
    setSelectedNavegador(null);
    setSelectedScreenWidth(null);
    setSelectedScreenHeight(null);
    setSelectedSistemaOperativo(null);
    setSelectedCountry(null);
    setSelectedCity(null);
  }

  const onDateRangeChange = (startDate, endDate) => {    
    setStartDate(startDate);
    setEndDate(endDate);
    getUsageTrackingInfo(startDate, endDate, ClaTrackingPlatforma); 
  }

  const onPlatformChange = (e) => {    
    setClaTrackingPlatforma(e.value);
    getUsageTrackingInfo(StartDate, EndDate, e.value); 
  }

  const onUserSessionsPress = (claUsuario) => {    
    resetSelectedDetail();
    setSelectedClaUsuario(claUsuario);
    setIsTrackingByUserDialogVisible(true);
  }

  const onURLUsersPress = (url) => {
    resetSelectedDetail();
    setSelectedURL(url);
    setTrackingUsersDialogTitle(`${t("usageTracking:PageURL")}: ${url}`);
    setIsTrackingUsersDialogVisible(true);
  }

  const onBrowserUsersPress = (navegador) => {
    resetSelectedDetail();
    setSelectedNavegador(navegador);
    setTrackingUsersDialogTitle(`${t("usageTracking:Browser")}: ${navegador}`);
    setIsTrackingUsersDialogVisible(true);
  }

  const onScreenResolutionUsersPress = (screenResolution, width, heigth) => {
    resetSelectedDetail();
    setSelectedScreenWidth(width);
    setSelectedScreenHeight(heigth);
    setTrackingUsersDialogTitle(`${t("usageTracking:ScreenResolution")}: ${screenResolution}`);
    setIsTrackingUsersDialogVisible(true);
  }  

  const onOSUsersPress = (os) => {
    resetSelectedDetail();
    setSelectedSistemaOperativo(os);
    setTrackingUsersDialogTitle(`${t("usageTracking:OperatingSystem")}: ${os}`);
    setIsTrackingUsersDialogVisible(true);
  }

  const onLocationUsersPress = (location, country, city) => {
    resetSelectedDetail();
    setSelectedCountry(country);
    setSelectedCity(city);
    setTrackingUsersDialogTitle(`${t("usageTracking:Location")}: ${location}`);
    setIsTrackingUsersDialogVisible(true);
  }    

  const onHideTrackingUsersDialog = () => {
    setIsTrackingUsersDialogVisible(false);
  }

  const onHideTrackingByUserDialog = () => {
    setIsTrackingByUserDialogVisible(false);
  }

  const filtersTemplate = (
    <>
      <Row>
        <Col lg={4} md={6} sm={6} xs={12}>
          <Label>
            {t('usageTracking:EventDate')}
          </Label>
          <div className="p-fluid">
            <FieldDateRange            
              startDate={StartDate}
              endDate={EndDate}
              onDateRangeChange={onDateRangeChange}
            >            
            </FieldDateRange>   
          </div> 
        </Col>
        <Col lg={4} md={6} sm={6} xs={12}>
          <Label>
            {t('usageTracking:Platform')}
          </Label>                
          <div className="p-fluid">
            <FieldDropdown
              name='platformFilterDropdown'
              value={ClaTrackingPlatforma}
              optionValue="ClaTrackingPlataforma"
              optionLabel="NomTrackingPlataforma"
              options={TrackingPlatforms}
              onChange={onPlatformChange}              
              className='mb-2'
            />
          </div>
        </Col>
      </Row>
    </>
  );
  
  const trackingByDateTemplate = (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col lg={9} md={9} sm={12} xs={12}>
              <HighchartsReact
                highcharts={Highcharts} 
                options={trackingByDateChartOptions} 
              />
            </Col>
            <Col lg={3} md={3} sm={12} xs={12}>
              <Row>
                <Col lg={12} md={12} sm={4} xs={6}>
                  <Row>
                    <Col>
                      <b>{t('usageTracking:VisitorStats')}</b>
                    </Col>              
                  </Row>              
                  <Row>
                    <Col xs={8}>
                      {t('usageTracking:PagesVisited')}
                    </Col>
                    <Col xs={4} className='text-right'>
                      <b>{TrackingByDateSummary.PageViews}</b>
                    </Col>
                  </Row>              
                  <Row>
                    <Col xs={8}>
                      {t('usageTracking:TotalUsers')}
                    </Col>
                    <Col xs={4} className='text-right'>
                      <b>{TrackingByDateSummary.TotalUsuarios}</b>
                    </Col>
                  </Row>                       
                </Col>
              </Row>                    
            </Col>
          </Row>
        </CardBody>
      </Card>    
    </>
  );

  const trackingByBrowserUsersColumnTemplate = (rowData) => {
    return (
      <>
        <FieldButton 
          label={`${rowData.TotalUsuarios}`} 
          className="p-button-link pt-0 pb-0"
          onClick={() => {
            onBrowserUsersPress(rowData.Navegador)
          }}
        />        
      </>
    )
  }

  const trackingByBrowserTemplate = (
    <>
      <Card>
        <CardBody>
          <HighchartsReact
            highcharts={Highcharts} 
            options={trackingByBrowserChartOptions} 
          />
          <FieldDataTable
            value={TrackingByBrowserReport}             
            scrollable 
            scrollHeight="330px" 
            className="p-datatable-striped"                                                
            removableSort            
            paginator={false}
          >                                
            <Column sortable header={t('usageTracking:Browser')} headerStyle={{ width: '120px' }} columnKey="Navegador" field="Navegador"></Column>    
            <Column sortable header={t('usageTracking:PagesVisited')} headerStyle={{ width: '110px' }} columnKey="PageViews" field="PageViews" className='text-right'></Column>  
            <Column sortable header={t('usageTracking:TotalUsers')} headerStyle={{ width: '100px' }} columnKey="TotalUsuarios" field="TotalUsuarios" body={trackingByBrowserUsersColumnTemplate} className='text-right'></Column>  
            <Column sortable header={t('usageTracking:LastSeen')} headerStyle={{ width: '120px' }} body={(rowdata) => renderText(rowdata, "LastVisit")} columnKey="LastVisit" field="LastVisit"></Column>              
          </FieldDataTable>              
        </CardBody>
      </Card>
    </>
  );

  const trackingByScreenResolutionUsersColumnTemplate = (rowData) => {
    return (
      <>
        <FieldButton 
          label={`${rowData.TotalUsuarios}`} 
          className="p-button-link pt-0 pb-0"
          onClick={() => {
            onScreenResolutionUsersPress(rowData.ScreenResolution, rowData.ScreenWidth, rowData.ScreenHeight)
          }}
        />        
      </>
    )
  }

  const trackingByScreenResolutionTemplate = (
    <>
      <Card>
        <CardBody>
          <HighchartsReact
            highcharts={Highcharts} 
            options={trackingByscreenResolutionChartOptions} 
          />
          <FieldDataTable
            value={TrackingByScreenResolutionReport}             
            scrollable 
            scrollHeight="330px" 
            className="p-datatable-striped"                                                
            removableSort            
            paginator={false}
          >                                
            <Column sortable header={t('usageTracking:ScreenResolution')} headerStyle={{ width: '130px' }} columnKey="ScreenResolution" field="ScreenResolution"></Column>    
            <Column sortable header={t('usageTracking:PagesVisited')} headerStyle={{ width: '110px' }} columnKey="PageViews" field="PageViews" className='text-right'></Column>  
            <Column sortable header={t('usageTracking:TotalUsers')} headerStyle={{ width: '100px' }} columnKey="TotalUsuarios" field="TotalUsuarios" body={trackingByScreenResolutionUsersColumnTemplate} className='text-right'></Column>  
            <Column sortable header={t('usageTracking:LastSeen')} headerStyle={{ width: '110px' }} body={(rowdata) => renderText(rowdata, "LastVisit")} columnKey="LastVisit" field="LastVisit"></Column>              
          </FieldDataTable>               
        </CardBody>
      </Card>    
    </>
  );

  const trackingByOSUsersColumnTemplate = (rowData) => {
    return (
      <>
        <FieldButton 
          label={`${rowData.TotalUsuarios}`} 
          className="p-button-link pt-0 pb-0"
          onClick={() => {
            onOSUsersPress(rowData.SistemaOperativo)
          }}
        />        
      </>
    )
  }

  const trackingByOSTemplate = (
    <>
      <Card>
        <CardBody>
          <HighchartsReact
            highcharts={Highcharts} 
            options={trackingByOSChartOptions} 
          />
          <FieldDataTable
            value={TrackingByOSReport}             
            scrollable 
            scrollHeight="330px" 
            className="p-datatable-striped"                                                
            removableSort            
            paginator={false}
          >                                
            <Column sortable header={t('usageTracking:OperatingSystem')} headerStyle={{ width: '130px' }} columnKey="SistemaOperativo" field="SistemaOperativo"></Column>    
            <Column sortable header={t('usageTracking:PagesVisited')} headerStyle={{ width: '110px' }} columnKey="PageViews" field="PageViews" className='text-right'></Column>  
            <Column sortable header={t('usageTracking:TotalUsers')} headerStyle={{ width: '100px' }} columnKey="TotalUsuarios" field="TotalUsuarios" body={trackingByOSUsersColumnTemplate} className='text-right'></Column>  
            <Column sortable header={t('usageTracking:LastSeen')} headerStyle={{ width: '110px' }} body={(rowdata) => renderText(rowdata, "LastVisit")} columnKey="LastVisit" field="LastVisit"></Column>              
          </FieldDataTable>              
        </CardBody>
      </Card>
    </>
  );

  const trackingByLocationUsersColumnTemplate = (rowData) => {
    return (
      <>
        <FieldButton 
          label={`${rowData.TotalUsuarios}`} 
          className="p-button-link pt-0 pb-0"
          onClick={() => {
            onLocationUsersPress(rowData.Location, rowData.Country, rowData.City)
          }}
        />        
      </>
    )
  }

  const trackingByLocationTemplate = (
    <>
      <Card>
        <CardBody>
          <HighchartsReact
            highcharts={Highcharts} 
            options={trackingByLocationChartOptions} 
          />
          <FieldDataTable
            value={TrackingByLocationReport}             
            scrollable 
            scrollHeight="330px" 
            className="p-datatable-striped"                                                
            removableSort            
            paginator={false}
          >                                
            <Column sortable header={t('usageTracking:Location')} headerStyle={{ width: '130px' }} columnKey="Location" field="Location"></Column>    
            <Column sortable header={t('usageTracking:PagesVisited')} headerStyle={{ width: '110px' }} columnKey="PageViews" field="PageViews" className='text-right'></Column>  
            <Column sortable header={t('usageTracking:TotalUsers')} headerStyle={{ width: '100px' }} columnKey="TotalUsuarios" field="TotalUsuarios" body={trackingByLocationUsersColumnTemplate} className='text-right'></Column>  
            <Column sortable header={t('usageTracking:LastSeen')} headerStyle={{ width: '110px' }} body={(rowdata) => renderText(rowdata, "LastVisit")} columnKey="LastVisit" field="LastVisit"></Column>              
          </FieldDataTable>              
        </CardBody>
      </Card>
    </>
  );

  const trackingByUserSessionsColumnTemplate = (rowData) => {
    return (
      <>
        <FieldButton 
          label={`${rowData.TotalSessionUser}`} 
          className="p-button-link pt-0 pb-0"
          onClick={() => {
            onUserSessionsPress(rowData.ClaUsuario)
          }}
        />        
      </>
    )
  }

  const trackingByUserTemplate = (
    <>
      <Card>
        <CardBody>
          <h2>{t('usageTracking:Users')}</h2>
          <FieldDataTable
            value={TrackingByUserReport}             
            scrollable 
            scrollHeight="330px" 
            className="p-datatable-striped"                                                
            removableSort            
            paginator={false}
          >                    
            <Column sortable header={t('usageTracking:UserID')} headerStyle={{ width: '100px' }} columnKey="ClaUsuario" field="ClaUsuario"></Column>  
            <Column sortable header={t('usageTracking:UserName')} headerStyle={{ width: '300px' }} columnKey="NombreCompleto" field="NombreCompleto"></Column>    
            <Column sortable header={t('usageTracking:PagesVisited')} headerStyle={{ width: '100px' }} columnKey="PageViews" field="PageViews" className='text-right'></Column>  
            <Column sortable header={t('usageTracking:NumberSessions')} headerStyle={{ width: '100px' }} columnKey="TotalSessionUser" field="TotalSessionUser" className='text-right' body={trackingByUserSessionsColumnTemplate}></Column>  
            <Column sortable header={t('usageTracking:LastSeen')} headerStyle={{ width: '130px' }} body={(rowdata) => renderText(rowdata, "LastVisit")} columnKey="LastVisit" field="LastVisit"></Column>              
          </FieldDataTable>    
        </CardBody>
      </Card>    
    </>
  );

  const trackingByURLUsersColumnTemplate = (rowData) => {
    return (
      <>
        <FieldButton 
          label={`${rowData.TotalUsuarios}`} 
          className="p-button-link pt-0 pb-0"
          onClick={() => {
            onURLUsersPress(rowData.URL)
          }}
        />        
      </>
    )
  }
  
  const [globalFilter, setGlobalFilter] = useState(''); 

  const reset = () => { 
    setGlobalFilter('');
    // dt.current.reset();
}
  

  const header = (
    <div className="">
      <FieldButton type="button" label="Clear" icon="pi pi-filter-slash" onClick={reset} />
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <FieldText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
      </span>
    </div>
);

  const trackingByURLTemplate = (
    <>
      <Card>
        <CardBody>
          <Row className='pb-4'>
            <Col> 
              <h2>{t('usageTracking:Pages')}</h2>
            </Col> 
            <Col className='text-right'> 
              {header}
            </Col>
          </Row> 
          <FieldDataTable
            value={TrackingByURLReport}           
            scrollable 
            scrollHeight="700px" 
            className="p-datatable-striped"                                                
            removableSort         
            paginator={false}     
            ref={dt} 
            globalFilter={globalFilter}
          >                    
            <Column sortable header={t('usageTracking:PageURL')} headerStyle={{ width: '300px' }} columnKey="URL" field="URL"></Column>  
            <Column sortable header={t('usageTracking:Views')} headerStyle={{ width: '100px' }} columnKey="TotalVisitas" field="TotalVisitas" className='text-right'></Column>    
            <Column sortable header={t('usageTracking:LastSeen')} headerStyle={{ width: '130px' }} body={(rowdata) => renderText(rowdata, "LastVisit")} columnKey="LastVisit" field="LastVisit"></Column>  
            <Column sortable header={t('usageTracking:TotalVisitors')} headerStyle={{ width: '100px' }} columnKey="TotalUsuarios" field="TotalUsuarios" body={trackingByURLUsersColumnTemplate} className='text-right'></Column>              
          </FieldDataTable>    
        </CardBody>
      </Card>    
    </>
  );

  useEffect(() => {         
    const startDateMoment = moment().startOf('month'); 
    const endDateMoment =  startDateMoment.clone().endOf('month');
    setStartDate(startDateMoment.toDate());
    setEndDate(endDateMoment.toDate());        
    getUsageTrackingInfo(startDateMoment.toDate(), endDateMoment.toDate(), ClaTrackingPlatforma); 
    getPlatforms();
  }, []);

  return (
    <PageContent title={t('usageTracking:UsageTracking')}>
      <Row className='pb-4 pt-4'>
        <Col> 
          {filtersTemplate}
        </Col> 
      </Row>
      <Row className='align-items-start'>
        <Col lg={8} md={12} sm={12} xs={12}>  
          <Row>
            <Col>
              {trackingByDateTemplate}
            </Col>
          </Row>
          <Row>
            <Col>
              {trackingByUserTemplate}   
            </Col>
          </Row>
          <Row>
            <Col>
              {trackingByURLTemplate}
            </Col>
          </Row>          
        </Col> 
        <Col lg={4} md={12} sm={12} xs={12}>  
          <Row>
            <Col>
              {trackingByBrowserTemplate}
            </Col>
          </Row>
          <Row>
            <Col>
              {trackingByScreenResolutionTemplate}
            </Col>
          </Row>
          <Row>
            <Col>
              {trackingByOSTemplate}
            </Col>
          </Row>  
          <Row>
            <Col>
              {trackingByLocationTemplate}
            </Col>
          </Row>                    
        </Col>    
        <UsageTrackingByUserDialog
          isVisible={IsTrackingByUserDialogVisible}
          claUsuario={SelectedClaUsuario}
          startDate={StartDate}
          endDate={EndDate}
          claTrackingPlataforma={ClaTrackingPlatforma}
          url={SelectedURL}
          navegador={SelectedNavegador}
          screenWidth={SelectedScreenWidth}
          screenHeight={SelectedScreenHeight}
          sistemaOperativo={SelectedSistemaOperativo}
          country={SelectedCountry}
          city={SelectedCity}              
          onHide={onHideTrackingByUserDialog}
        >
        </UsageTrackingByUserDialog>
        <UsageTrackingUsersDialog          
          isVisible={IsTrackingUsersDialogVisible}
          title={TrackingUsersDialogTitle}
          startDate={StartDate}
          endDate={EndDate}     
          claTrackingPlataforma={ClaTrackingPlatforma}
          url={SelectedURL}
          navegador={SelectedNavegador}
          screenWidth={SelectedScreenWidth}
          screenHeight={SelectedScreenHeight}
          sistemaOperativo={SelectedSistemaOperativo}
          country={SelectedCountry}
          city={SelectedCity}          
          onHide={onHideTrackingUsersDialog}
        >
        </UsageTrackingUsersDialog>
      </Row>   
    </PageContent>
  )
}

export default UsageTrackingScreen
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert, getDeviceUniqueID } from '../../../../utils/utils';
import * as yup from 'yup';
import YupValidator from '../../../../utils/YupValidator';
import swal from '@sweetalert/with-react';
import SweatAlertCustomIcon from 'components/SweatAlert/SweatAlertCustomIcon';
import SurveyManagementContestarPreguntas from './SurveyManagementContestarPreguntas';
import { CodeSharp } from '@material-ui/icons';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import InstructionsCard from '../../../../components/Cards/InstructionsCard/InstructionsCard.jsx';

const SurveyManagementContestar = (props) => {
  const { t } = useTranslation(['survey', 'common']);
  const [IdEncuesta, setIdEncuesta] = useState(null);
  const [IdEncuestaPagina, setIdEncuestaPagina] = useState(null);
  const [Siguiente, setSiguiente] = useState(null);
  const [Survey, setSurvey] = useState([]);
  const [Preguntas, setPreguntas] = useState([]);
  const [PosiblesRespuestas, setPosiblesRespuestas] = useState([]);
  const [Titulo, setTitulo] = useState(null);
  const [CantPaginas, setCantPaginas] = useState(null);
  const [PaginaActual, setPaginaActual] = useState(null);
  const [TituloPagina, setTituloPagina] = useState(null);
  const [DescripcionPagina, setDescripcionPagina] = useState(null);
  const [DescripcionHtmlPagina, setDescripcionHtmlPagina] = useState(null);
  const [DescripcionEncuestaHtml, setDescripcionEncuestaHtml] = useState(null);
  const [errors, setErrors] = useState({});
  const [EsContestar, setEsContestar] = useState(true);
  const [IdEncuestaContestada, setIdEncuestaContestada] = useState(null);
  const [Completado, setCompletado] = useState(1);
  const [FechaIniciaEncuesta, setFechaIniciaEncuesta] = useState(null);
  const [EsEncuestaCompletada, setEsEncuestaCompletada] = useState(0);
  const [EsEncuestaCerrada, setEsEncuestaCerrada] = useState(0);
  const [DeviceUniqueID, setDeviceUniqueID] = useState(getDeviceUniqueID());
  const [EsDeviceParticipo, setEsDeviceParticipo] = useState(0);
  const [MultiplesRespuestasPorDispositivo, setMultiplesRespuestasPorDispositivo] = useState(false);
   
  useEffect(() => { 
    setIdEncuesta(props.IdEncuesta === undefined ? null : props.IdEncuesta);
    setIdEncuestaContestada(props.IdEncuestaContestada === undefined ? null : props.IdEncuestaContestada);
    obtenerEncuestaPorPagina(props.IdEncuesta, null, null, props.IdEncuestaContestada, DeviceUniqueID);
    
    /*
    if (props.IdEncuestaContestada !== null) {
      EncuestaContestadExistSel(props.IdEncuestaContestada);  
    }
    */
  }, [props.IdEncuesta, props.IdEncuestaContestada]);

  const obtenerEncuestaPorPagina = (pIdEncuesta, pPaginaActual, pSiguiente, pIdEncuestaContestada, DeviceUniqueID) => {
    const getHRSurveyManagementContestar = `${config.UrlApiProject}HR/GetHRSurveyManagementContestar`;
    const paramsToService = {
      IdEncuesta: pIdEncuesta,
      IdEncuestaPagina: pPaginaActual === null ? null : IdEncuestaPagina,
      IdEncuestaContestada : pIdEncuestaContestada,
      Siguiente: pSiguiente,
      DeviceUniqueID
    };
    callApi(getHRSurveyManagementContestar, 'POST', paramsToService, (response) => {
      const head = response.data.Encuesta.length > 0 ? response.data.Encuesta[0] : {};
      setTitulo(head.Titulo);
      setDescripcionEncuestaHtml(head.DescripcionHtml);
      setCantPaginas(head.CantPaginas);
      setPaginaActual(head.PaginaActual);
      setIdEncuestaPagina(head.IdEncuestaPagina);
      setTituloPagina(head.TituloPagina);
      setDescripcionPagina(head.DescripcionPagina);
      setDescripcionHtmlPagina(head.DescripcionHtmlPagina);
      setEsEncuestaCompletada(head.EsEncuestaCompletada);
      setIdEncuestaContestada(head.IdEncuestaContestada);
      setFechaIniciaEncuesta(head.FechaIniciaEncuesta)
      setPreguntas(response.data.Preguntas);
      setPosiblesRespuestas(response.data.PosiblesRespuestas); 
      setEsEncuestaCerrada(head.EsEncuestaCerrada);
      setEsDeviceParticipo(head.EsDeviceParticipo);
      setMultiplesRespuestasPorDispositivo(head.MultiplesRespuestasPorDispositivo);
    });
  };

  const siguientePagina = () => {
    onSaveQuestionClick();
  };

  const previusPagina = () => {
    setErrors({});
    obtenerEncuestaPorPagina(IdEncuesta, PaginaActual, 2, IdEncuestaContestada, DeviceUniqueID);
  };

  const onValueChangedQuestion = (RowIndex, idEncuestaPaginaPreguntaRespuesta, newValue) => {
    const newErrors = { ...errors };    
    const newPreguntas = [...Preguntas];
    newPreguntas[RowIndex].RespuestaPregunta = newValue;
    newPreguntas[RowIndex].IdEncuestaPaginaPreguntaRespuesta = idEncuestaPaginaPreguntaRespuesta;
    setPreguntas(newPreguntas);
  };

  async function onSaveQuestionClick() {
    const newPreguntasOpc = [...Preguntas];

    newPreguntasOpc.forEach((element) => {
      if (Array.isArray(element.RespuestaPregunta)) {
        let opc = '';
        element.RespuestaPregunta.map((item, index) => {
          return (opc = `${opc + element.RespuestaPregunta[index]},`);
        });
        element.RespuestaPregunta = opc.slice(0, -1);
        element.IdEncuestaPaginaPreguntaRespuesta = null;
      }
    });

    const value = {
      Preguntas: newPreguntasOpc,
    };

    const validator = new YupValidator(valitationSchemeSave);
    const esValido = await validator.validate(value);

    if (!esValido) {
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      return;
    }
    setErrors({});
    const saveMSWSurveyRespuestaPorPregunta = `${config.UrlApiProject}HR/SaveMSWSurveyRespuestaPorPregunta`;

    const paramsToService = {
      IdEncuesta,
      IdEncuestaContestada,
      surveyManagementContestadaDTO: newPreguntasOpc,
    };

    callApi(saveMSWSurveyRespuestaPorPregunta, 'POST', paramsToService, (response) => {
      obtenerEncuestaPorPagina(IdEncuesta, PaginaActual, 1, IdEncuestaContestada, DeviceUniqueID);
    });
  }

  async function onSubmitSaveQuestionClick() {
    const newPreguntasOpc = [...Preguntas];

    newPreguntasOpc.forEach((element) => {
      if (Array.isArray(element.RespuestaPregunta)) {
        let opc = '';
        element.RespuestaPregunta.map((item, index) => {
          return (opc = `${opc + element.RespuestaPregunta[index]},`);
        });
        element.RespuestaPregunta = opc.slice(0, -1);
        element.IdEncuestaPaginaPreguntaRespuesta = null;
      }
    });

    const value = {
      Preguntas: newPreguntasOpc,
    };

    const validator = new YupValidator(valitationSchemeSave);
    const esValido = await validator.validate(value);

    if (!esValido) {
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      return;
    }

    const saveMSWSurveyRespuestaPorPregunta = `${config.UrlApiProject}HR/SaveMSWSurveyRespuestaPorPregunta`;

    const paramsToService = {
      IdEncuesta,
      IdEncuestaContestada,
      Completado,
      surveyManagementContestadaDTO: newPreguntasOpc,
    };

    callApi(saveMSWSurveyRespuestaPorPregunta, 'POST', paramsToService, (response) => {
      obtenerEncuestaPorPagina(IdEncuesta, null, null, IdEncuestaContestada, DeviceUniqueID);
      showSweetAlert(t('common:Success'), t('survey:EncuestaContestada'), 'success');
      EncuestaEnviada();
    });
  }

  const valitationSchemeSave = yup.object().shape({
    Preguntas: yup.array().of(
      yup.object().shape({
        RespuestaPregunta: yup
          .string()
          .when('EsObligatorio', {
            is: 1,
            then: yup.string().trim().required(t('common:RequiredField')).nullable(),
            otherwise: yup.string().nullable(),
          }),
      })
    ),
  });
  
  const InicioContestarEncuesta = () => { 
    const saveMSWSurveyRespuestaHead = `${config.UrlApiProject}HR/SaveMSWSurveyRespuestaHead`;

    const paramsToService = {
      IdEncuesta,
      IdEncuestaContestada,
      DeviceUniqueID
    };

    callApi(saveMSWSurveyRespuestaHead, 'POST', paramsToService, (response) => {
      setEsContestar(false); 
      obtenerEncuestaPorPagina(IdEncuesta, null, null, response.data.Encuesta[0].IdEncuestaContestada, DeviceUniqueID);
      // setIdEncuestaContestada(response.data.Encuesta.IdEncuestaContestada);
      // setFechaIniciaEncuesta(response.data.Encuesta.FechaIniciaEncuesta); 
      // setEsEncuestaCompletada(response.data.Encuesta.EsEncuestaCompletada); 
    }); 
  }
  
  /*
  const EncuestaContestadExistSel = (IdEncuestaContestada) => {
    const getEncuestaContestadExistSel = `${config.UrlApiProject}HR/GetEncuestaContestadExistSel`;

    const paramsToService = {
      IdEncuesta: props.IdEncuesta,
      IdEncuestaContestada
    };

    callApi(getEncuestaContestadExistSel, 'POST', paramsToService, (response) => {
      const encuestaContestada = response.data.EncuestaContestada.length > 0 ? response.data.EncuestaContestada[0]:{};
      setFechaIniciaEncuesta(encuestaContestada.FechaIniciaEncuesta);            
    });    
  }
  */

  const showActiveRows = (rows) => {
    const activeRows = [];
    if (rows !== undefined) {
      if (rows.length > 0) {
        rows.forEach((item, index) => {
          if (!item.BajaLogica || item.BajaLogica === 0 || item.BajaLogica === undefined) {
            item.BajaLogica = 0;
            item.RowIndex = index;
            activeRows.push({ ...item });
          }
        });
      } else {
        return [];
      }
    }

    return activeRows;
  };

  
  const closeModalEsIniciarEncuesta = () => {
    if (typeof(props.onClose)==='function'){ 
      props.onClose();
    } 
  };

  const EncuestaEnviada = () => {
    if (typeof(props.onFinishSubmit)==='function'){ 
      props.onFinishSubmit();
    } 
  };

  const renderHeaderContestada = () => {
    return (
      <div>
        <Row>
          <Col lg={4} md={4} sm={12} xs={12}> 
            <span>{Titulo}</span> 
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="text-center">
             
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="text-right">
             &nbsp;
          </Col>
        </Row>
      </div>
    );
  };

  
  const renderHeader = () => {
    return (
      <div>
        <Row>
          <Col lg={4} md={4} sm={12} xs={12}> 
            <span>{Titulo}</span> 
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="text-center">
             
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="text-right">
            <div className="w-100 relative pr-3" hidden={IdEncuestaContestada === null || FechaIniciaEncuesta === null}>
              <small className="BOLD">
                {PaginaActual} | {CantPaginas}
              </small>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const leftToolbarFooterNewAPTemplate = () => {  
    return (
      <> 
        {PaginaActual > 1 ? (
          <>
            <FieldButton
              label={t('common:Previous')}
              className="p-button-secondary no-border p-mr-2 rounded-button"
              icon="fas fa-arrow-circle-left"
              onClick={previusPagina}
            />
              &nbsp;
          </>
          ) : (
            <></>
          )} 
        
      </>
    );
  };
  const rightToolbarFooterNewAPTemplate = () => {
     
    return (
      <>
        {CantPaginas > 1 && PaginaActual !== CantPaginas ? (
          <>
            <FieldButton
              label={t('common:Next')}
              icon="fas fa-arrow-circle-right"
              className="p-button-primary no-border p-mr-2 rounded-button"
              onClick={siguientePagina}
            />
              &nbsp;
          </>
          ) : (
            <></>
          )}
        {PaginaActual === CantPaginas ? (
          <>
            <FieldButton
              label={t('common:Submit')}
              icon="fa fa-paper-plane"
              className="p-button-success no-border p-mr-2 rounded-button"
              onClick={onSubmitSaveQuestionClick}
            />
              &nbsp;
          </>
          ) : (
            <></>
          )}
          &nbsp; 
      </>
    );
  };

  const renderFooter = () => {
    return (
      <>
        <Toolbar
          left={leftToolbarFooterNewAPTemplate}
          right={rightToolbarFooterNewAPTemplate}
        >
        </Toolbar>
      </>
    );
  };
  
  const RenderEncuestaDeviceParticipo = (
    <>
      <div hidden={props.showAsModal === false}> 
        <Row>
          <Col className="text-center">
            <InstructionsCard className="surveyNotFoundIntructionsModal">
              <p>
                {t('survey:EncuestaContestada')}
              </p>
            </InstructionsCard>
          </Col>
        </Row>
      </div>
      <div hidden={props.showAsModal === true}>
        <Row>
          <Col xs={12} sm={12} ms={11} lg={11} className="">
            <div>
              <span className='survey-title'>{Titulo}</span>
            </div>
            <div dangerouslySetInnerHTML={{ __html: DescripcionEncuestaHtml }}>
            </div>
          </Col>         
        </Row>
        <Row className='pt-5'>
          <Col>
            &nbsp;
          </Col>
        </Row>
        <Row>
          <Col className="text-center"> 
            <InstructionsCard className="surveyNotFoundIntructions">
              <p>
                {t('survey:EncuestaContestada')}
              </p>
            </InstructionsCard>
          </Col>
        </Row>
      </div>
    </>

  )


  const RenderEncuestaCerrada = (
    <>
      <div hidden={props.showAsModal === false}> 
        <Row>
          <Col className="text-center">
            <InstructionsCard className="surveyNotFoundIntructionsModal">
              <p>
                {t('survey:SurveyClosed')}.
              </p>
            </InstructionsCard>
          </Col>
        </Row>
      </div>
      <div hidden={props.showAsModal === true}>
        <Row>
          <Col xs={12} sm={12} ms={11} lg={11} className="">
            <div>
              <span className='survey-title'>{Titulo}</span>
            </div>
            <div dangerouslySetInnerHTML={{ __html: DescripcionEncuestaHtml }}>
            </div>
          </Col>         
        </Row>
        <Row className='pt-5'>
          <Col>
            &nbsp;
          </Col>
        </Row>
        <Row>
          <Col className="text-center"> 
            <InstructionsCard className="surveyNotFoundIntructions">
              <p>
                {t('survey:SurveyClosed')}.
              </p>
            </InstructionsCard>
          </Col>
        </Row>
      </div>
    </>

  )

  const RenderIniciarEncuesta = (
    <>
      <div hidden={props.showAsModal === false}>
        <Row>
          <Col xs={12} sm={12} ms={11} lg={11} className="pt-5"> 
            <div dangerouslySetInnerHTML={{ __html: DescripcionEncuestaHtml }}>
            </div>
          </Col>         
        </Row>
        <Row className="pt-5">
          <Col className="text-center">
            <FieldButton
              label={t('survey:InitContestarEncuesta')}
              className="p-button-success p-mr-2"
              onClick={InicioContestarEncuesta}
            />
            &nbsp;
          </Col>
        </Row>
      </div>
      <div hidden={props.showAsModal === true}>
        <Row>
          <Col xs={12} sm={12} ms={11} lg={11} className="">
            <div>
              <span className='survey-title'>{Titulo}</span>
            </div>
            <div dangerouslySetInnerHTML={{ __html: DescripcionEncuestaHtml }}>
            </div>
          </Col>         
        </Row>
        <Row className='pt-5'>
          <Col>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <FieldButton
              icon="fas fa-play"
              label={t('survey:InitContestarEncuesta')}
              className="light-green p-mr-2 rounded-button no-border"
              onClick={InicioContestarEncuesta}
            />
            &nbsp;
          </Col>
        </Row>
      </div>
    </>
  );

  const RenderEncuestaTerminada = (
    <>
      {props.showAsModal ?
     (
       <Row>
         <Col xs={12} sm={12} md={12} lg={12}>
           <div className="">
             <Row> 
               <Col xs={12} sm={12} ms={12} lg={12} className="pt-5 text-center">
                 <span className='h2'>{t('survey:EncuestaContestada')}</span> 
               </Col>
             </Row>
           </div>
         </Col>
       </Row>
     )
     :
     (
       <Row>
         <Col xs={12} sm={12} md={12} lg={12}>
           <div className="">
             <Row>
               <Col xs={12} sm={12} ms={12} lg={12} className="text-center">
                 <div>
                   <span className='survey-title'>{Titulo}</span>
                 </div>
               </Col>
               <Col xs={12} sm={12} ms={12} lg={12} className="pt-5 text-center">
                 <span className='h2'>{t('survey:EncuestaContestada')}</span> 
               </Col>
             </Row>
           </div>
         </Col>
       </Row>
)} 
    </>
  )
  const RenderEncuestaIniciada = (
    <>
      <Row hidden={props.showAsModal === true}>
        <Col xs={12} sm={12} md={12} lg={12}>
          <div className="">
            <Row>
              <Col xs={12} sm={12} ms={11} lg={11}>
                <div>
                  <span className="h1">{Titulo}</span>
                </div>
              </Col>
              <Col xs={12} sm={12} ms={1} lg={1} className="text-right">
                <small className="BOLD">
                  {PaginaActual} | {CantPaginas}
                </small>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          &nbsp;
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <div className="Pregunta">
            <label>
              <b>{TituloPagina}</b>
            </label>
          </div>
          <div className="Pregunta" dangerouslySetInnerHTML={{ __html: DescripcionPagina }}></div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          &nbsp;
        </Col>
      </Row>

      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <Card>
            <CardBody className="questionario-viewer">
              {Preguntas.length >= 1 ? (
                showActiveRows(Preguntas).map((element, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        errors[`Preguntas[${index}]`] != null ? 'survey_question_required' : ''
                      }
                    >
                      <SurveyManagementContestarPreguntas
                        IdEncuesta={IdEncuesta}
                        IdEncuestaPagina={element.IdEncuestaPagina}
                        IdEncuestaPaginaPregunta={element.IdEncuestaPaginaPregunta}
                        onValueChangedQuestion={onValueChangedQuestion}
                        PosiblesRespuestas={PosiblesRespuestas.filter(
                          (opc) => opc.IdEncuestaPaginaPregunta === element.IdEncuestaPaginaPregunta
                        )}
                        RespuestaPregunta={element.RespuestaPregunta}   
                        ClaFormularioTipoCampo={element.ClaFormularioTipoCampo}
                        Descripcion={element.DescripcionPregunta}                     
                        Index={element.RowIndex}
                      >
                      </SurveyManagementContestarPreguntas>
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row hidden={props.showAsModal === true}>
        <Col className="text-center">
          {PaginaActual > 1 ? (
            <>
              <FieldButton
                label={t('common:Previous')}
                className="p-button-secondary p-mr-2"
                icon="fas fa-arrow-circle-left"
                onClick={previusPagina}
              />
              &nbsp;
            </>
          ) : (
            <></>
          )}
          {CantPaginas > 1 && PaginaActual !== CantPaginas ? (
            <>
              <FieldButton
                label={t('common:Next')}
                className="p-button-secondary p-mr-2"
                icon="fas fa-arrow-circle-right"
                onClick={siguientePagina}
              />
              &nbsp;
            </>
          ) : (
            <></>
          )}
          {PaginaActual === CantPaginas ? (
            <>
              <FieldButton
                label={t('common:Submit')}
                icon="fa fa-paper-plane"
                className="p-button-success p-mr-2"
                onClick={onSubmitSaveQuestionClick}
              />
              &nbsp;
            </>
          ) : (
            <></>
          )}
          &nbsp;
        </Col>
      </Row>
    </>
  ) 
 
  if (EsEncuestaCerrada === 1 || EsEncuestaCerrada === true) {
    if (props.showAsModal === true){
      return (
        <>
          <Dialog
            visible={props.isOpen}         
            modal
            style={{ width: '100vw', height: '100vw' }}
            maximized={true}
            draggable={false}
            onHide={closeModalEsIniciarEncuesta}
            className="dialog-header-background-blue"
            header={renderHeader}
          > 
            {RenderEncuestaCerrada}
          </Dialog>
        </>
      );
    } 
    
    return  (
      <>
        {RenderEncuestaCerrada}
      </>
    );
  } 
           
  if (IdEncuestaContestada === null || FechaIniciaEncuesta === null) {
    
    if ((MultiplesRespuestasPorDispositivo === 0 || MultiplesRespuestasPorDispositivo === false) && (EsDeviceParticipo === 1 || EsDeviceParticipo === true)) {
      if (props.showAsModal === true){
        return (
          <>
            <Dialog
              visible={props.isOpen}         
              modal
              style={{ width: '100vw', height: '100vw' }}
              maximized={true}
              draggable={false}
              onHide={closeModalEsIniciarEncuesta}
              className="dialog-header-background-blue"
              header={renderHeader}
            > 
              {RenderEncuestaDeviceParticipo}
            </Dialog>
          </>
        );
      } 
      
      return  (
        <>
          {RenderEncuestaDeviceParticipo}
        </>
      );
    }

    if (props.showAsModal === true){
      return (
        <>
          <Dialog
            visible={props.isOpen}         
            modal
            style={{ width: '100vw', height: '100vw' }}
            maximized={true}
            draggable={false}
            onHide={closeModalEsIniciarEncuesta}
            className="dialog-header-background-blue"
            header={renderHeader}
          > 
            {RenderIniciarEncuesta}
          </Dialog>
        </>
      );
    } 
    
    return  (
      <>
        {RenderIniciarEncuesta}
      </>
    );
    
    
  }


  if (EsEncuestaCompletada === 0){
    if (props.showAsModal === true){
      return (
        <>
          <Dialog
            visible={props.isOpen}         
            modal
            style={{ width: '100vw', height: '100vw' }}
            maximized={true}
            draggable={false}
            onHide={closeModalEsIniciarEncuesta}
            className="dialog-header-background-blue"
            header={renderHeader} 
            footer={renderFooter}
          >   
            {
              RenderEncuestaIniciada
            }   
          </Dialog>
        </>
      );
    
      }
        return (
          <>
            {
              RenderEncuestaIniciada
            }  
          </>
        )
  }

  
  if (EsEncuestaCompletada === 1){
    if (props.showAsModal === true){
      return (
        <>
          <Dialog
            visible={props.isOpen}         
            modal
            style={{ width: '100vw', height: '100vw' }}
            maximized={true}
            draggable={false}
            onHide={closeModalEsIniciarEncuesta}
            className="dialog-header-background-blue"
            header={renderHeaderContestada} 
          >   
            {
              RenderEncuestaTerminada
            }   
          </Dialog>
        </>
      );
    
      }
        return (
          <>
            {
              RenderEncuestaTerminada
            }  
          </>
        )
  }

  return (
    <>
      Loading...
    </>
  ); 
};

export default SurveyManagementContestar;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next';  
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row,Col, Card, CardBody} from 'reactstrap';   
import { config } from '../../../utils/config';
import { callApi, showSweetAlert, tracker} from '../../../utils/utils'; 
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon' 
import moment from "moment"; 
import swal from '@sweetalert/with-react';
import PageContent from 'layouts/PageContent';
import { Toolbar } from 'primereact/toolbar';
import { ProgressBar } from 'primereact/progressbar'; 
import OrangePage from "./OrangePage.jsx";
import ReporteAvanceMensualProyecto from './ReporteAvanceMensualProyecto.jsx';
import { Dialog } from 'primereact/dialog';
import ReporteAvanceMensualProyectoAttachment from './ReporteAvanceMensualProyectoAttachment.jsx';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const ProgressReportList = (props) => {
   /* ################## useState area start ################## */
   const { t } = useTranslation(['proyectoprogressreport', 'common']);    
   const [ClaProyecto, setClaProyecto] = useState(0);  
   const [AnioMes, setAnioMes] = useState(null);
   const [ProyectoProgressReportList, setProyectoProgressReportList]= useState({});
   const [EsVerOrangePage, setEsVerOrangePage] = useState(false);  
   const [IdReporteMensual, setIdReporteMensual] = useState(0);  
   const [EsCrearEditarProyecto, setEsCrearEditarProyecto] = useState(false);
   const [IdReporteAvanceMensualProyectoSelected, setIdReporteAvanceMensualProyectoSelected] = useState(null);
   const [EsAbrirDocumentosSoporte , setEsAbrirDocumentosSoporte] = useState(false);
   const [ProgressReportPeriodSelected , setProgressReportPeriodSelected] = useState('');
   const [SiriusURL , setSiriusURL] = useState('');

   useEffect(() => {   
    setClaProyecto(props.ClaProyecto === undefined ? 0 : props.ClaProyecto);
    obtenerInformacionProyectoProgressRepor();    
  }, [props.ClaProyecto]); 

  const obtenerInformacionProyectoProgressRepor = () => {  
    const GetProyectoProgressReportList = `${config.UrlApiProject}ScoreCard/GetProyectoProgressReport?ClaProyecto=${props.ClaProyecto}`;          
    const paramsToService = { };   
    callApi(GetProyectoProgressReportList, 'GET', paramsToService, (response) => {
      setProyectoProgressReportList(response.data.ProyectoProgressReport);
    });
  }  
  

  const ProgressReportTemplate = (row) =>  {

    if (props.readOnly === true){
      return (<></>);
    }
    
    const bandera = row.ClaEstatusReporte === 1 ? 1 : 2;
    return (
      <> 
        {
          bandera === 1 ? (
            <>
              <FieldButton className="p-button-rounded p-button-success btn-min-width no-border" label={t('proyectoprogressreport:CreateProgressReport')} onClick={(e) => onClickCreateReport(row)} />
            </>
          ) :(
            <>
              <FieldButton className="p-button-rounded p-button-success btn-min-width secondary no-border" label={t('proyectoprogressreport:EditProgressReport')} onClick={(e) => onClickEditReport(row)} /> 
              &nbsp;
              <FieldButton
                title={t('reportevanceMensualProyecto:SupportFiles')}
                icon="pi pi-paperclip"
                className={`no-border rounded-button font-weight-bold ${ row.TieneAnexos === 1 ? 'light-yellow' : 'light-gray'} `}
                onClick={(e) => AbrirAnexo(row)}  
              >
              </FieldButton>
            </>
          )
        }        
      </>
    );
  }  

  const onClickOrangeReport = (row) =>
  {
    console.log(row);
    setIdReporteMensual(row.IdReporteAvanceMensualProyecto);
    setEsVerOrangePage(true);
    tracker.trackEvent({
      type: 'ProgressReportList', /* component name */
      action: 'VIEW_ORANGE_PAGE'
    }) 
  }

  const onClickCreateReport = (row) =>
  {  
    setAnioMes(row.AnioMes);
    setEsCrearEditarProyecto(true);

    tracker.trackEvent({
      type: 'ProgressReportList', /* component name */
      action: 'CREATE_PROGRESS_REPORT'
    }) 
  }

  const onClickEditReport = (row) =>
  { 
    setAnioMes(row.AnioMes);
    setEsCrearEditarProyecto(true);

    tracker.trackEvent({
      type: 'ProgressReportList', /* component name */
      action: 'EDIT_PROGRESS_REPORT'
    }) 
  }

  const AbrirAnexo = (row) => {
    setIdReporteAvanceMensualProyectoSelected(row.IdReporteAvanceMensualProyecto);
    setProgressReportPeriodSelected(row.Periodo);
    setEsAbrirDocumentosSoporte(!EsAbrirDocumentosSoporte);

    tracker.trackEvent({
      type: 'ProgressReportList', /* component name */
      action: 'VIEW_ATTACHEMENT_PROGRESS_REPORT'
    }) 
  };
  
  const onCopyClipBoard = () => { 
    showSweetAlert('success', 'Link copied to clipboard.', 'success');
    tracker.trackEvent({
      type: 'ProgressReportList', /* component name */
      action: 'COPY_LINK_PROGRESS_REPORT'
    }) 
  }

  const OrangePageTemplate = (row) =>  {
    const bandera = row.ClaEstatusReporte === 1 ? 1 : 2;
    
    return (
      <> 
        {
          bandera === 1 ? (
            <> 
            </>
          ) :(
            <> 
              <FieldButton className="p-button-rounded p-button-info no-border btn-min-width" label={t('proyectoprogressreport:ViewOrangePage')} onClick={(e) => onClickOrangeReport(row)} /> 
              &nbsp;
              <CopyToClipboard text={`${config.UrlProject}layout/OrangePageScreen/${ClaProyecto}/${row.IdReporteAvanceMensualProyecto}`} onCopy={() => onCopyClipBoard()}>  
                <i className="fas fa-link fa-lg Cursor" title={t('proyectoprogressreport:ClickHereoCopyOrangePageLink')}></i>  
              </CopyToClipboard>
            </>
          )
        }        
      </>
    );
  } 

  const FechaUltimaModTemplate = (row) => {
    return (
      <> 
        { row.FechaUltimaMod === null ? <></> : moment(row.FechaUltimaMod).format("LL") } 
      </>
    );
  }

  return (
    <>      
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}> 
          &nbsp;
        </Col> 
      </Row>   
      {
        !EsVerOrangePage && !EsCrearEditarProyecto ?
        (
          <FieldDataTable 
            value={ProyectoProgressReportList}
            scrollable 
            scrollHeight="330px" 
            className="editable-cells-table"  
            editMode="cell"
            selectionMode="single"
          >       
        
            <Column field="Periodo" header={t('proyectoprogressreport:Period')} columnKey="Periodo" headerStyle={{ width: '110px' }}></Column>  
            <Column field="NomResponsable" header={t('proyectoprogressreport:NomResponsable')} columnKey="NomResponsable" headerStyle={{ width: '240px' }}></Column> 
            <Column field="CreadoPor" header={t('proyectoprogressreport:CreatedBy')} columnKey="CreadoPor" headerStyle={{ width: '240px' }}></Column>   
            <Column body={FechaUltimaModTemplate} header={t('proyectoprogressreport:LastUpdate')} columnKey="FechaUltimaMod" headerStyle={{ width: '160px' }}></Column>          
            <Column body={ProgressReportTemplate} className="text-center" header={t('proyectoprogressreport:ProgressReport')} headerStyle={{ width: '190px' }}></Column>           
            <Column field="EstatusReporte" className="text-center" header={t('proyectoprogressreport:Status')} columnKey="EstatusReporte" headerStyle={{ width: '110px' }}></Column>  
            <Column body={OrangePageTemplate} className="text-center" header={t('proyectoprogressreport:OrangePage')} headerStyle={{ width: '190px' }}></Column>                      
          </FieldDataTable>
        )
        :
        (<></>)
      }
      
      
      {
        EsVerOrangePage && 
        (<OrangePage Visible={EsVerOrangePage} onHide={()=> setEsVerOrangePage(false)} ClaProyecto={ClaProyecto} IdReporteMensual={IdReporteMensual}></OrangePage> )
      } 
      
      {
        EsCrearEditarProyecto &&
        (<ReporteAvanceMensualProyecto Visible={EsCrearEditarProyecto} onHide={()=> setEsCrearEditarProyecto(false)} ClaProyecto={ClaProyecto} AnioMes={AnioMes} onChange={obtenerInformacionProyectoProgressRepor}></ReporteAvanceMensualProyecto> 
        )
      }    
             
      <Dialog
        visible={EsAbrirDocumentosSoporte}
        header={`${ProgressReportPeriodSelected} - ${t('reportevanceMensualProyecto:SupportFilesTitle')}`}
        style={{width: '80vw'}}
        modal
        className="dialog-custom"
        onHide={()=> setEsAbrirDocumentosSoporte(false)}
      >
        <Row>
          <Col>
            <ReporteAvanceMensualProyectoAttachment
              IsUpload={true}
              IdReporteAvanceMensualProyecto={IdReporteAvanceMensualProyectoSelected}
            >
            </ReporteAvanceMensualProyectoAttachment>
          </Col>
        </Row>
      </Dialog>

    </>
  );
};
export default ProgressReportList;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row, Col } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi, showSweetAlert, getSessionItem, tracker } from '../../../utils/utils';
import { Checkbox } from 'primereact/checkbox';
import FieldText from '../../../components/Controls/FieldText.jsx';
import FieldTextArea from '../../../components/Controls/FieldTextArea.jsx';
import FieldNumber from '../../../components/Controls/FieldNumber.jsx';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx';
import FieldDropdown from 'components/Controls/FieldDropdown.jsx';
import { RadioButton } from 'primereact/radiobutton';
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import moment from 'moment';
import { Sidebar } from 'primereact/sidebar';
import swal from '@sweetalert/with-react';
import { Tag } from 'primereact/tag';
import { Dialog } from 'primereact/dialog';

const MoverActividadNuevaFase = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['scoreCard', 'common']);
  const [errors, setErrors] = useState({});
  const [Filters, setFilters] = useState({});
  const [ClaFase, setClaFase] = useState(props.ClaFase === undefined ? 0 : props.ClaFase);
  const [ClaActividad, setClaActividad] = useState(
    props.ClaActividad === undefined ? 0 : props.ClaActividad
  );
  const [ClaProyecto, setClaProyecto] = useState(
    props.ClaProyecto === undefined ? 0 : props.ClaProyecto
  );
  const [OpcionesFase, setOpcionesFase] = useState([]);  
  const [ClaFaseNew, setClaFaseNew] = useState(null);

  useEffect(() => {
    if (props.IsVisible) {
      setClaFase(props.ClaFase === undefined ? 0 : props.ClaFase);
      setClaProyecto(props.ClaProyecto === undefined ? 0 : props.ClaProyecto);
      setClaActividad(props.ClaActividad === undefined ? 0 : props.ClaActividad);
      obtenerFasesProyecto(props.ClaProyecto);
    }
  }, [props.IsVisible, props.ClaFase, props.ClaProyecto, props.ClaActividad]);

  useEffect(() => {}, [props.Filters]);

  const obtenerFasesProyecto = (claProyecto) => {
    const getMswFasesCmb = `${config.UrlApiProject}ScoreCard/GetMswFasesCmb?ClaProyecto=${
      claProyecto === null ? 0 : claProyecto
    }`;
    const paramsToService = {};
    callApi(getMswFasesCmb, 'GET', paramsToService, (response) => {
      setOpcionesFase(response.data[0]);
    });
  };

  const valitationSchemeSave = yup.object().shape({
    ClaFaseNew: yup.mixed().required(t('common:RequiredField')).nullable(),
  });

  const save = async () => {
    const value = {
      ClaFaseNew,
    };
    const validator = new YupValidator(valitationSchemeSave);
    const esValido = await validator.validate(value);
    if (!esValido) {
      console.log(validator.errors);
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      return;
    }
    const urlWebServiceSave = `${config.UrlApiProject}ScoreCard/MoveActividadFase`;
    const paramsService = {
      ClaProyecto,
      ClaFase,
      ClaFaseNew,
      ClaActividad,
    };
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      onChangeFase(true);
      tracker.trackEvent({
        type: 'MoverActividadNuevaFase', /* component name */
        action: 'MOVE_ACTIVITY'
      })

    });
  };

  const onChangeFase = (isSave) => {
    setErrors({});
    if (props.onClickClose !== null && typeof props.onClickClose === 'function') {
      props.onClickClose();
    }

    if (isSave) {
      if (props.onChange !== null && typeof props.onChange === 'function') {
        props.onChange();
      }
    }
  };

  const onNewPhaseChange = (e) => {
    const newErrors = { ...errors };
    delete newErrors.ClaFaseNew;
    setErrors(newErrors);
    setClaFaseNew(e.value);
  };

  const renderFooter = () => {
    return (
      <>
        <div className="text-center">
          <FieldButton
            type="button"
            onClick={save}
            label={t('scoreCard:Move')}
            className="p-button-success no-border p-mr-2 rounded-button"
            style={{ marginRight: '.25em' }}
          />
        </div>
      </>
    );
  };

  const renderHeader = () => {
    return (
      <>
        <h2>
          <span>{t('scoreCard:MoveActivity')}</span>
        </h2>
      </>
    );
  };

  const close = async () => {
    setErrors({});
    if (props.onClickClose !== null) {
      props.onClickClose();
    }
  };
  return (
    <>
      <Dialog
        blockScroll={true}
        visible={props.IsVisible}
        modal
        style={{ width: '60vw' }}
        footer={renderFooter}
        header={renderHeader}
        onHide={close}
      >
        <Row className="pb-3 pt-5">
          <Col>
            <div className="p-fluid">
              <FieldDropdown
                name="ClaFase"
                value={ClaFase}
                options={OpcionesFase}
                optionValue="ClaFase"
                optionLabel="NomFase"
                label={t('scoreCard:PhaseActual')}
                disabled
              />
            </div>
          </Col>
          <Col>
            <div className="p-fluid">
              <FieldDropdown
                name="ClaFaseNew"
                value={ClaFaseNew}
                options={OpcionesFase}
                optionValue="ClaFase"
                optionLabel="NomFase"
                label={t('scoreCard:PhaseNew')}
                onChange={(e) => onNewPhaseChange(e)}
                errors={errors}
              />
            </div>
          </Col>
        </Row>
      </Dialog>
    </>
  );
};
export default MoverActividadNuevaFase;

import React from 'react';
// react library for routing
import { Route, Switch, Redirect } from 'react-router-dom';
// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import AdminFooter from 'components/Footers/AdminFooter.js';
import Sidebar from 'components/Sidebar/Sidebar.js'; 
import Components  from 'routes.js'; 
import { config } from '../utils/config';
import { callApi, getCliente } from '../utils/utils';   
 

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidenavOpen: false,
      routes: []
    };
  }

  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }


  }

  componentDidMount()   
  {  
     /* obenemos la informacion de las opciones de menu */
      const urlWebService = `${config.UrlApiProject}Seguridad/GetMenu?LoginName=${getCliente()}`; 

      const paramsService = null;
 
      callApi(urlWebService, 'GET', paramsService, (response) => {
           // this.setState({routes: routesHardCoded});

            this.setState({routes: response.data});
      });   
  }      
  
 

  getRoutes = (routes) =>
    routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === '/layout') {
        return <Route path={prop.layout + prop.path} component={Components[prop.component]} key={key} />;
      }
      return null;
    });

  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (this.props.location.pathname.indexOf(this.state.routes[i].layout + routes[i].path) !== -1) {
        return this.state.routes[i].name;
      }
    }
    return 'Brand';
  }; 
  
  getNavbarTheme = () =>
    this.props.location.pathname.indexOf('admin/alternative-dashboard') === -1 ? 'dark' : 'light';

  closeSidenav = (e) => {  
        document.body.classList.remove('g-sidenav-pinned');
        document.body.classList.add('g-sidenav-hidden'); 
    };

  render() {
    return (
      <> 
        <Sidebar
          {...this.props}
          routes={this.state.routes}
        /> 
        <div className="main-content" ref="mainContent">
          <AdminNavbar {...this.props} />
          <div onClick={this.closeSidenav} className="main-content-layout" style={{ flex: '1 1 0' }}> 
            
            <Switch>
              {this.getRoutes(this.state.routes)}
              <Redirect from="*" to="/layout/Home" />
            </Switch>  
          </div> 
        </div> 
        <AdminFooter />
      </>
    );
  }
}

export default Admin;

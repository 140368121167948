/* eslint-disable no-self-compare */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from 'components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap'; 
import { config } from '../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../utils/utils'; 
import FieldToogleSwitch from 'components/Controls/FieldToogleSwitch/FieldToogleSwitch';
import FieldSelectButton from 'components/Controls/FieldSelectButton';
import moment from 'moment';
import { Divider } from 'primereact/divider';

const CultureSurveyApprovingFeedbackResponse = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['survey', 'common']);
  const [errors, setErrors] = useState({});
  const [IdEncuesta, setIdEncuesta] = useState(null);
  const [IdEncuestaContestada, setIdEncuestaContestada] = useState(null);
  const [AprovingFeedBackResponseList, setAprovingFeedBackResponseList] = useState([]);
  const [Visible, setVisible] = useState(false);
  const [TituloEncuesta, setTituloEncuesta] = useState(null);
  const [Options, setOptions] = useState([`${t('common:Show')}`, `${t('common:Hide')}`]);
  const [EsRevisadoRH, setEsRevisadoRH] = useState(false);
  const [FechaRevisadoRH, setFechaRevisadoRH] = useState(null);
  const [EsSoloLectura, setEsSoloLectura] = useState(false);
  const [OpcionesYesNo, setOpcionesYesNo] = useState([
    { value: false, name: t('common:No') },
    { value: true, name: t('common:Yes') },
  ]);

  useEffect(() => {
    setIdEncuesta(props.IdEncuesta === undefined ? null : props.IdEncuesta);
    setIdEncuestaContestada(
      props.IdEncuestaContestada === undefined ? null : props.IdEncuestaContestada
    );
    loadAprovingFeedBackResponse(props.IdEncuesta, props.IdEncuestaContestada);
  }, [props.IdEncuesta, props.IdEncuestaContestada]);

  const loadAprovingFeedBackResponse = (pIdEncuesta, pIdEncuestaContestada) => {
    const GetAprovingFeedBackResponse = `${config.UrlApiProject}hr/GetAprovingFeedBackResponse?IdEncuesta=${pIdEncuesta}&IdEncuestaContestada=${pIdEncuestaContestada}`;
    const paramsToService = {};
    callApi(GetAprovingFeedBackResponse, 'GET', paramsToService, (response) => { 
      const AprovingFeedbackResponse = response.data.AprovingFeedbackResponse.length > 0 ? response.data.AprovingFeedbackResponse[0] : {}
      setEsRevisadoRH(AprovingFeedbackResponse.EsRevisadoRH);
      setFechaRevisadoRH(AprovingFeedbackResponse.FechaRevisadoRH);
      setEsSoloLectura(AprovingFeedbackResponse.EsRevisadoRH);
      setAprovingFeedBackResponseList(response.data.AprovingFeedbackResponseDet); 
    });
  };

  const showActiveRows = (rows) => {
    const activeRows = [];
    if (rows !== undefined) {
      if (rows.length > 0) {
        rows.forEach((item, index) => {
          if (!item.BajaLogica || item.BajaLogica === 0 || item.BajaLogica === undefined) {
            item.BajaLogica = 0;
            item.RowIndex = index;
            item.RowIndexCount = index + 1;
            activeRows.push({ ...item });
          }
        });
      } else {
        return [];
      }
    }

    return activeRows;
  };

  const SurveyResponseTemplate = (row) => {
    return (
      <>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            {row.DescripcionPregunta}
          </Col>
        </Row>
        <Row>
          <Col>
            <span className="bold">{row.RespuestaPregunta}</span>
          </Col>
        </Row>
      </>
    );
  };

  const ApprovedAllResponse = (props, e) => {
    const updatedField = [...AprovingFeedBackResponseList];
    
    updatedField.map(p =>
      updatedField[p.RowIndex].Visible = e.checked ? 1 : 0      
    );

    setVisible(e.checked ? 1 : 0); 

    setAprovingFeedBackResponseList(updatedField);
  };

  const ApprovedQuestion = (props, e) => {
    const updatedField = [...AprovingFeedBackResponseList];
    // updatedField[props.RowIndex].Visible = e.checked ? 1 : 0;
    updatedField[props.RowIndex].Visible = !!e;
    setAprovingFeedBackResponseList(updatedField);
  };

  const SaveApproved = () => {
    const paramsService = {
      IdEncuesta,
      IdEncuestaContestada,
      surveyManagementContestadaDTO: AprovingFeedBackResponseList.filter(opc => 1 === 1),
    }; 
     
    const SaveSurvey = `${config.UrlApiProject}HR/SaveMSWSurveyApprovedQuestion`;

    callApi(SaveSurvey, 'POST', paramsService, (response) => {
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      props.onResponsesApproved();
    });
  };

  const toggleApprovedAllResponse = () => {
    const updatedField = [...AprovingFeedBackResponseList];

    updatedField.map((p) => (updatedField[p.RowIndex].Visible = !Visible));

    setVisible(!Visible);

    setAprovingFeedBackResponseList(updatedField);
  };

  const onSelectEsRevisadoRH = (value) => {
    const newErrors = { ...errors };
    delete newErrors.EsRevisadoRH;
    setErrors(newErrors);
    setEsRevisadoRH(value);
  };  
  
  return (
    <>       
      <Row>
        <Col xs={12} sm={12} ms={12} lg={12}>
          <div>
            <div className="survey-edit-container">
              <div className="survey-edit-body">
                <Row>
                  <Col lg={10} md={10} sm={12} xs={12} className="p-mr-2">
                    <div>
                      {t('common:From')}: <b>{props.NomUsuarioRespondeEncuesta}</b>
                    </div>
                    <div>
                      {t('common:To')}: <b>{props.RespuestasPara}</b>
                    </div> 
                  </Col>
                  <Col>
                    <div>
                      <label>{t('survey:HideShow')}</label>&nbsp;
                    </div>                      
                    <FieldToogleSwitch
                      id="EsVisible"
                      checked={Visible}
                      onChange={toggleApprovedAllResponse} 
                      optionLabels={Options}
                      small
                      disabled={EsSoloLectura}
                    />
                    {/* <div className="p-fluid p-field-checkbox">
                        <FieldCheckbox
                          inputId={Visible}
                          checked={Visible === 1}
                          onChange={(e) => ApprovedAllResponse(props, e)}
                        />
                      </div> */}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
                &nbsp;
            </Col>
          </Row>
          {showActiveRows(AprovingFeedBackResponseList).map((element, index) => {
              return (
                <div key={index}>
                  {index === 0 ? (
                    <>
                      <Row>
                        <Col lg={10} md={10} sm={12} xs={12} className="p-mr-2">
                          <div>
                            <span className="h2 bold">{element.TituloPagina}</span>
                          </div>
                          <div>
                            <span className="bold">{element.DescripcionPagina}</span>
                          </div>
                          <div className="Pregunta" dangerouslySetInnerHTML={element.DescripcionHtml}>
                          </div>                          
                        </Col>
                        <Col>
                          <div className="text-right">
                            <small className="BOLD">{element.CountPage} | {element.TotalPage}</small>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                          &nbsp;
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <></>
                  )}
                  {index > 0 &&
                  element.IdEncuestaPagina !==
                    (index + 1 === AprovingFeedBackResponseList.length
                      ? AprovingFeedBackResponseList[index].IdEncuestaPagina
                      : AprovingFeedBackResponseList[index - 1].IdEncuestaPagina) ? (
                        <>
                          <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>
                          &nbsp;
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={10} md={10} sm={12} xs={12} className="p-mr-2">
                              <div>
                                <span className="h2 bold">{element.TituloPagina}</span>
                              </div>
                              <div>
                                <span className="bold">{element.DescripcionPagina}</span>
                              </div>
                              <div className="Pregunta" dangerouslySetInnerHTML={element.DescripcionHtml}>
                              </div>
                            </Col>
                            <Col>                              
                              <div className="text-right">
                                <small className="BOLD">{element.CountPage} | {element.TotalPage}</small>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>
                          &nbsp;
                            </Col>
                          </Row>
                        </>
                  ) : (
                    <></>
                  )}

                  <div className="survey-approve-container">
                    <div className="survey-approve-body">
                      <Row>
                        <Col lg={10} md={10} sm={12} xs={12} className="p-mr-2">
                          <div className='Pregunta'>
                            <span className="bold" hidden={true}> {index + 1}.- </span>
                            <b>{element.DescripcionPregunta}</b>
                          </div>
                          <div className='Respuesta'>
                            {element.RespuestaPregunta} 
                          </div>
                        </Col>
                        <Col>
                          {/* <div className="p-fluid p-field-checkbox">
                            <FieldCheckbox
                              inputId={element.Visible}
                              checked={element.Visible === 1}
                              onChange={(e) => ApprovedQuestion(element, e)}
                            />
                          </div> */}
                          <FieldToogleSwitch
                            id={`Q${index + 1}`}
                            checked={element.Visible}
                            onChange={(e) => ApprovedQuestion(element, e)}
                            // optionLabels={Options}
                            small
                            disabled={EsSoloLectura}
                          />

                        </Col>
                      </Row>
                      <Divider></Divider>
                    </div>
                  </div>
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      &nbsp;
                    </Col>
                  </Row>
                </div>
              );
            })}
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
            &nbsp;
        </Col>
      </Row>
      <Row>
        <Col> 
          <Card>
            <CardBody>
              <Row> 
                <Col lg={12} md={12} sm={12} xs={12}>
                  <Row className="pt-3">
                    <Col>
                      <span className="bold">
                        <h2> {t('survey:ApproveResponses')} </h2>{' '}
                      </span>
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col>
                      <span className="bold">{t('survey:ApproveResponsesConfirmation')}</span>
                    </Col>
                  </Row>
                  <Row className="pt-3 pb-3">
                    <Col>
                      <FieldSelectButton
                        name="EsRevisadoRH"
                        value={EsRevisadoRH}
                        options={OpcionesYesNo}
                        onChange={(e) => onSelectEsRevisadoRH(e.value)}
                        optionValue="value"
                        optionLabel="name"
                        errors={errors} 
                        disabled={EsSoloLectura}
                      />
                    </Col>
                  </Row>
                  {EsRevisadoRH ? (
                    <>
                      {
                        EsSoloLectura ?
                        ( 
                          <Row className="pt-3 pb-3">
                            <Col lg={12} md={12} sm={12} xs={12} className="text-right">
                              {t('survey:ApprovedByHRDate')} :  { moment(FechaRevisadoRH).format('MMMM DD, YYYY h:mm:ss a') }
                            </Col>
                          </Row>
)
                        :
                        (
                          <>
                            <Row className="pt-3 pb-3">
                              <Col lg={12} md={12} sm={12} xs={12}>
                                <span className='danger-color'>{t('survey:ApproveCultureSetUpWarning')}</span>
                              </Col>
                            </Row>
                            <Row className="pt-3 pb-3">
                              <Col lg={12} md={12} sm={12} xs={12} className="text-right">
                                <FieldButton 
                                  icon="fas fa-clipboard-check"
                                  label={t('common:Approve')}                            
                                  className="p-button-raised p-button-success"
                                  onClick={SaveApproved}
                                />
                              </Col>
                            </Row>  
                          </>
                        )
                      }
                      
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card> 
        </Col>
      </Row>
    </>
  );
};
export default CultureSurveyApprovingFeedbackResponse;

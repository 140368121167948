/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter, Label } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi, getSessionItem, showSweetAlert,tracker } from '../../../utils/utils';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon';
import moment from 'moment';
import Highcharts from 'highcharts';
import swal from '@sweetalert/with-react';
import PageContent from 'layouts/PageContent';
import { Toolbar } from 'primereact/toolbar';
import { ProgressBar } from 'primereact/progressbar';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldDropdownMultiSelect from '../../../components/Controls/FieldDropDownMultiSelect/FieldDropdownMultiSelect.jsx';
import FieldText from 'components/Controls/FieldText.jsx';
import FieldNumber from 'components/Controls/FieldNumber.jsx';
import FieldCheckbox from 'components/Controls/FieldCheckbox.jsx';
import FieldAutoComplete from 'components/Controls/FieldAutoComplete.jsx';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import { Dialog } from 'primereact/dialog';
import Select, { InputActionMeta } from 'react-select';
import { Panel } from 'primereact/panel';
import AltaProyecto  from '../Projects/AltaProyecto.jsx';

const MisProyectosFilter = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['misProyectosScreen', 'common']);
  const [TipoProyectoList, setTipoProyectoList] = useState([]);
  const [EstatusProyectoList, setEstatusProyectoList] = useState([]);
  const [ProyectoList, setProyectoList] = useState([]);
  const [ColaboradorPorProyectosList, setColaboradorPorProyectosList] = useState([]);
  const [ResponsablePorProyectosList, setResponsablePorProyectosList] = useState([]);
  const [EstatusActividadesList, setEstatusActividadesList] = useState([]);
  const [ClaTipoProyecto, setClaTipoProyecto] = useState(1);
  const [ClaEstatusProyecto, setClaEstatusProyecto] = useState([]);
  const [FechaEstimada, setFechaEstimada] = useState(null);
  const [FechaEstimadaIni, setFechaEstimadaIni] = useState(null);
  const [FechaEstimadaFin, setFechaEstimadaFin] = useState(null);
  const [ClaProyecto, setClaProyecto] = useState(null);
  const [AdvancedSearch, setAdvancedSearch] = useState(0);
  const [ClaColaborador, setClaColaborador] = useState([]);
  const [ClaResponsable, setClaResponsable] = useState([]);
  const [ClaResponsableTeamMeber, setClaResponsableTeamMeber] = useState([]);
  const [AdvancedSearchText, setAdvancedSearchText] = useState(null);
  const [ClaEstatusActividad, setClaEstatusActividad] = useState([]);
  const [errors, setErrors] = useState({});
  const [EsMostrarAltraProyecto, setEsMostrarAltraProyecto] = useState(false);
  const [flagTipoProyecto, setflagTipoProyecto] = useState(1);
  const [flagProyecto, setflagProyecto] = useState(1);

  useEffect(() => {
    
    obtenerTipoProyecto();
    obtenerEstatus();
    obtenerEstatusActividades();
    obtenerColaboradorPorProyectos(-1);
    obtenerProyecto(1); // ClaTipoProyecto 1 por default
    setErrors({});
    const date = new Date();
    date.setDate(date.getDate() - 1);
    setFechaEstimadaFin(date);

  }, [props.ClaProyecto, props.AnioMes]);

  const obtenerTipoProyecto = () => {
    const GetTipoProyectoAllCmb = `${config.UrlApiProject}ScoreCard/GetTipoProyectoAllCmb`;
    const paramsToService = {};
    callApi(GetTipoProyectoAllCmb, 'GET', paramsToService, (response) => {
      setTipoProyectoList(response.data.TipoProyecto.filter(a => a.ClaTipoProyecto !== -1));
    });
  };
  const obtenerEstatus = () => {
    const GetEstatusProyectoCmb = `${config.UrlApiProject}ScoreCard/GetEstatusProyectoCmb`;
    const paramsToService = {};
    callApi(GetEstatusProyectoCmb, 'GET', paramsToService, (response) => {
      // setEstatusProyectoList(response.data.EstatusProyecto);
      const options = response.data.EstatusProyecto.map((d) => ({
        value: d.ClaEstatusProyecto,
        label: d.NomEstatusProyecto,
      }));

      const optionsDefaults = response.data.EstatusProyecto.filter(d => 
            d.ClaEstatusProyecto === 1 
            || d.ClaEstatusProyecto === 2
            || d.ClaEstatusProyecto === 3
            || d.ClaEstatusProyecto === 4).map((d) => ({
        value: d.ClaEstatusProyecto,
        label: d.NomEstatusProyecto,
      }));
      setEstatusProyectoList(options);
      setClaEstatusProyecto(optionsDefaults);
    });
  };
  const obtenerProyecto = (pClaTipoProyecto) => {
    pClaTipoProyecto = pClaTipoProyecto === undefined ? 1 : pClaTipoProyecto
    const GetProyectoCmb = `${config.UrlApiProject}ScoreCard/GetProyectoCmb?ClaTipoProyecto=${pClaTipoProyecto}`;
    const paramsToService = {};
    callApi(GetProyectoCmb, 'GET', paramsToService, (response) => {
      setProyectoList(response.data.Proyecto);
    });
  };
  const obtenerColaboradorPorProyectos = (pClaProyecto) => {
    if (typeof(pClaProyecto) === 'undefined'){
      pClaProyecto = -1;
    }

    const GetColaboradorPorProyectosCmb = `${config.UrlApiProject}ScoreCard/GetColaboradorPorProyectosCmb?ClaProyecto=${pClaProyecto}`;
    const paramsToService = {};
    callApi(GetColaboradorPorProyectosCmb, 'GET', paramsToService, (response) => {
      // setColaboradorPorProyectosList(response.data.ColaboradorPorProyectos);
      const options = response.data.ColaboradorPorProyectos.map((d) => ({
        value: d.ClaColaborador,
        label: d.NomColaborador,
      }));
      setColaboradorPorProyectosList(options);
      setResponsablePorProyectosList(options);
    });
  };
  const obtenerEstatusActividades = () => {
    const GetEstatusActividadesCmb = `${config.UrlApiProject}ScoreCard/GetEstatusActividadesCmb`;
    const paramsToService = {};
    callApi(GetEstatusActividadesCmb, 'GET', paramsToService, (response) => {
      const options = response.data.EstatusActividades.map((d) => ({
        value: d.ClaEstatusActividad,
        label: d.NomEstatusActividad,
      }));
      setEstatusActividadesList(options);
    });
  };
  const onSearchClean = () => {
    if (props.onSearch) {
      const filtersData = {};
      filtersData.flagClean = 1;
      filtersData.flagExport = 0;
      props.onSearch(filtersData);
      props.onExportToExcelMyProject(filtersData);
    }
  }
  const onChangeTipoProyecto = (props, e) => {
    const newErrors = { ...errors }
    delete newErrors.ClaTipoProyecto;   
    setErrors(newErrors);
    obtenerProyecto(e.target.value);
    setClaTipoProyecto(e.target.value);
    setflagTipoProyecto(e.target.value === 4 || e.target.value === 5 || e.target.value === 6 ? 0 : 1);
    setClaProyecto(null);
    onSearchClean();    
  };
  const onChangeEstatusProyecto = (e) => {
    const newErrors = { ...errors };
    delete newErrors.ClaEstatusProyecto;
    setErrors(newErrors);
    setClaEstatusProyecto(e); 
    onSearchClean(); 
  };
  const onChangeFechaEstimada = (props, e) => {
    setFechaEstimadaFin(e.target.value);
    onSearchClean(); 
  };
  const onChangeFechaEstimadaFin = (props, e) => {
    setFechaEstimadaFin(e.target.value);
    onSearchClean(); 
  };
  const onChangeFechaEstimadaIni = (props, e) => {
    setFechaEstimadaIni(e.target.value);
    onSearchClean(); 
  };
  const onChangeProyecto = (props, e) => {
    obtenerColaboradorPorProyectos(e.target.value);
    setClaProyecto(e.target.value);
    const updatedProyectos = [...ProyectoList];
    if (e.target.value !== undefined) {
      const tipoProyectoList = updatedProyectos.filter(a => a.ClaProyecto === e.target.value);
      setflagProyecto(tipoProyectoList[0].ClaTipoProyecto === 4 || tipoProyectoList[0].ClaTipoProyecto === 5 ? 0 : 1);            
    }else {
      setflagProyecto(1);
      setClaColaborador(null);
      setClaResponsable(null);
      setClaResponsableTeamMeber(null);
    }
    onSearchClean(); 
  };
  const onChangeAdvancedSearch = (props, e) => { 
    setAdvancedSearch(e.checked ? 1 : 0);
    setFechaEstimadaIni(null);
    setClaColaborador(null);
    onSearchClean(); 
  };
  const onChangeColaboradorPorProyectos = (e) => {
    const newErrors = { ...errors };
    delete newErrors.NomColaborador;
    setErrors(newErrors);
    setClaColaborador(e);
    onSearchClean(); 
  };
  const onChangeResponsablePorProyectos = (e) => {
    const newErrors = { ...errors };
    delete newErrors.NomColaborador;
    setErrors(newErrors);
    setClaResponsable(e);
    onSearchClean(); 
  };
  const onChangeAdvancedSearchText = (props, value) => {
    setAdvancedSearchText(value);
    onSearchClean(); 
  };
  const onChangeEstatusActividad = (e) => {
    setClaEstatusActividad(e);
    onSearchClean(); 
  }; 
  const onChangeResponsableTeamMember = (e) => {
    // const newErrors = { ...errors };
    // delete newErrors.NomColaborador;
    // setErrors(newErrors);
    setClaResponsableTeamMeber(e);
    onSearchClean(); 
  };

  const onApplyFilters = async () => {
    
    const value = {    
      ClaTipoProyecto  
    };

    const validator = new YupValidator(valitationScheme);
    const esValido = await validator.validate(value);
    if (!esValido){   
      setErrors(validator.errors); 
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
     
      return;
    }
    
    let estatus = "";
    let responsable = "";
    let responsableProyecto = "";
    let responsableTeamMember = "";
    let estatusActividad = "";

    if (ClaEstatusProyecto !== null) {
      for (let index = 0; index < ClaEstatusProyecto.length; index++) {
        const element = ClaEstatusProyecto[index].value;
        estatus = `${estatus + element},`;
      } 
    }    

    if (ClaColaborador !== null) {
      for (let index = 0; index < ClaColaborador.length; index++) {
        const element = ClaColaborador[index].value;
        responsable = `${responsable + element},`;
      }  
    }
    
    if (ClaResponsable !== null) {
      for (let index = 0; index < ClaResponsable.length; index++) {
        const element = ClaResponsable[index].value;
        responsableProyecto = `${responsableProyecto + element},`;
      }  
    }

    if (ClaResponsableTeamMeber !== null) {
      for (let index = 0; index < ClaResponsableTeamMeber.length; index++) {
        const element = ClaResponsableTeamMeber[index].value;
        responsableTeamMember = `${responsableTeamMember + element},`;
      }  
    }

    for (let index = 0; index < ClaEstatusActividad.length; index++) {
      const element = ClaEstatusActividad[index].value;
      estatusActividad = `${estatusActividad + element},`;
    }
     
    if (props.onSearch) {
      const filtersData = {};
      filtersData.flagExport = 0;
      filtersData.AdvancedSearch = AdvancedSearchText;
      filtersData.FechaEstimadaIni = FechaEstimadaIni === null ? null : FechaEstimadaIni.toDateString();     
      filtersData.FechaEstimadaFin = FechaEstimadaFin === null ? null : FechaEstimadaFin.toDateString();
      filtersData.ClaUsuarioResponsable = responsable;
      filtersData.ClaUsuarioResponsableProyecto = responsableProyecto;
      filtersData.ClaTipoProyecto = ClaTipoProyecto;
      filtersData.ClaProyecto = ClaProyecto;       
      filtersData.ClaEstatusProyecto = estatus;      
      filtersData.ClaEstatusActividad = estatusActividad; 
      filtersData.flagClean = 0;
      filtersData.ClaUsuarioResponsableEquipo = responsableTeamMember;
      props.onSearch(filtersData);
      // props.onExportToExcelMyProject(filtersData);
    }
  };

  const onExportExcelMyProject = async () => {
    
    const value = {    
      ClaTipoProyecto  
    };

    const validator = new YupValidator(valitationScheme);
    const esValido = await validator.validate(value);
    if (!esValido){   
      setErrors(validator.errors); 
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
     
      return;
    }
    
    let estatus = "";
    let responsable = "";
    let responsableProyecto = "";
    let responsableTeamMember = "";
    let estatusActividad = "";

    if (ClaEstatusProyecto !== null) {
      for (let index = 0; index < ClaEstatusProyecto.length; index++) {
        const element = ClaEstatusProyecto[index].value;
        estatus = `${estatus + element},`;
      } 
    }    

    if (ClaColaborador !== null) {
      for (let index = 0; index < ClaColaborador.length; index++) {
        const element = ClaColaborador[index].value;
        responsable = `${responsable + element},`;
      }  
    }
    
    if (ClaResponsable !== null) {
      for (let index = 0; index < ClaResponsable.length; index++) {
        const element = ClaResponsable[index].value;
        responsableProyecto = `${responsableProyecto + element},`;
      }  
    }

    if (ClaResponsableTeamMeber !== null) {
      for (let index = 0; index < ClaResponsableTeamMeber.length; index++) {
        const element = ClaResponsableTeamMeber[index].value;
        responsableTeamMember = `${responsableTeamMember + element},`;
      }  
    }

    for (let index = 0; index < ClaEstatusActividad.length; index++) {
      const element = ClaEstatusActividad[index].value;
      estatusActividad = `${estatusActividad + element},`;
    }
     
    if (props.onExportToExcelMyProject) {
      const filtersData = {};
      filtersData.AdvancedSearch = AdvancedSearchText;
      filtersData.FechaEstimadaIni = FechaEstimadaIni === null ? null : FechaEstimadaIni.toDateString();     
      filtersData.FechaEstimadaFin = FechaEstimadaFin === null ? null : FechaEstimadaFin.toDateString();
      filtersData.ClaUsuarioResponsable = responsable;
      filtersData.ClaUsuarioResponsableProyecto = responsableProyecto;
      filtersData.ClaTipoProyecto = ClaTipoProyecto;
      filtersData.ClaProyecto = ClaProyecto;       
      filtersData.ClaEstatusProyecto = estatus;      
      filtersData.ClaEstatusActividad = estatusActividad; 
      filtersData.flagClean = 0;
      filtersData.ClaUsuarioResponsableEquipo = responsableTeamMember;
      filtersData.flagExport = 1;
      tracker.trackEvent({
        type: 'MisProyectosFilter', /* component name */
        action: 'EXPORT_PROJECTS'
      }) 

      props.onExportToExcelMyProject(filtersData);
    }
  };

  const valitationScheme = yup.object().shape({
    ClaTipoProyecto: yup.mixed().required(t('common:RequiredField')).nullable(), 
    // ClaEstatusProyecto: yup
    //   .array()
    //   .min(1, t('common:RequiredField'))
    //   .required(t('common:RequiredField')),
  });

  const filterHeaderTemplate = (options) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down text-white' : 'pi pi-chevron-up text-white';
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className="card-header">
        <div className="p-toolbar p-component">
          <div className="p-toolbar-group-left">
            <div className="card-title">
              <FieldButton className={options.togglerClassName} onClick={options.onTogglerClick}>
                <span className={toggleIcon}></span> 
              </FieldButton>
              <span className={titleClassName}>
                {t('common:Filters')}
              </span>
            </div>  
          </div>
         
          <div className="p-toolbar-group-right">
            <FieldButton label={t('myactivities:Search')} icon="pi pi-search" className="p-button p-highlight" onClick={onApplyFilters}>              
            </FieldButton> 
            &nbsp;
            <FieldButton label={t('myactivities:Export')} icon="fa fa-file-excel" className="secondary no-border" onClick={onExportExcelMyProject}>              
            </FieldButton> 
            &nbsp;
            <FieldButton label={t('myactivities:NewProject')} icon="pi pi-plus" className="light-green no-border p-mr-2" onClick={onClickNewProyect}  /> 
          </div>
        </div> 
      </div>
    )
}

const onClickNewProyect = () => {  
  setEsMostrarAltraProyecto(true);
  tracker.trackEvent({
    type: 'MisProyectosFilter', /* component name */
    action: 'NEW_PROJECT'
  }) 
} 

const onCloseAltaProyecto = () => { 
  setEsMostrarAltraProyecto(false); 
  onApplyFilters();  
} 

  return (
    <>
      <Panel
        header={t('common:Filters')}
        toggleable 
        headerTemplate={filterHeaderTemplate}
      >   
        <Row>
          <Col lg={4} md={4} sm={12} xs={12} className="p-3">
            <div className="p-fluid">  
              <FieldDropdown
                name="ClaTipoProyecto"
                label={t('misProyectosScreen:ProyectType')}
                value={ClaTipoProyecto}
                options={TipoProyectoList}
                optionValue="ClaTipoProyecto"
                optionLabel="NomTipoProyecto"
                onChange={(e) => onChangeTipoProyecto(props, e)}
                errors={errors}
              /> 
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="p-3">
            <div className="p-fluid">  
              <FieldDropdownMultiSelect
                isMulti={true}
                options={EstatusProyectoList}
                isClearable={true}
                isSearchable={true}
                value={ClaEstatusProyecto}
                name="ClaEstatusProyecto"
                onChange={(e) => onChangeEstatusProyecto(e)}
                errors={errors}
                placeholder={t('misProyectosScreen:ProjectStatusPlaceHolder')}
                label={t('misProyectosScreen:ProjectStatus')}
              />
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="p-3">
            {AdvancedSearch === 0 ? (
              <>
                <div className="p-fluid">
                  <FieldCalendar
                    name="FechaEstimada"
                    value={FechaEstimadaFin}
                    label={t('misProyectosScreen:EstProgressDate')}
                    onChange={(e) => onChangeFechaEstimada(props, e)}
                  />
                </div>
              </>
              ) : (
                <> 
                  <div className="p-fluid"> 
                    <div className="p-float-label" style={{top:-9}}>
                      <label>{t('misProyectosScreen:EstProgressDate')}</label>
                    </div>
                    <div className="p-inputgroup"> 
                      <span className="p-inputgroup-addon">
                        <i>
                          {t('misProyectosScreen:Start')}
                        </i>
                      </span>
                      <FieldCalendar
                        name="FechaEstimadaIni"
                        value={FechaEstimadaIni}
                        onChange={(e) => onChangeFechaEstimadaIni(props, e)}
                      /> 
                      <span className="p-inputgroup-addon">
                        <i>
                          {t('misProyectosScreen:End')}
                        </i>
                      </span> 
                      <FieldCalendar
                        name="FechaEstimadaFin"
                        value={FechaEstimadaFin}
                        onChange={(e) => onChangeFechaEstimadaFin(props, e)}
                      />
                    </div> 
                  </div>
                </>
              )}
          </Col>
        </Row> 
        <Row>          
          <Col lg={4} md={4} sm={12} xs={12} className="p-3">
            <div className="p-fluid">
              <FieldDropdown
                name="NomProyecto"
                label={t('misProyectosScreen:Project')}
                value={ClaProyecto}
                options={ProyectoList}
                optionValue="ClaProyecto"
                optionLabel="NomProyecto"
                onChange={(e) => onChangeProyecto(props, e)}
              />
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="p-3">
            <div className="p-fluid">
              <FieldDropdownMultiSelect
                isMulti={true}
                options={ResponsablePorProyectosList}
                isClearable={true}
                isSearchable={true}
                value={ClaResponsable}
                name="NomColaborador"
                onChange={(e) => onChangeResponsablePorProyectos(e)}
                errors={errors}
                placeholder={t('misProyectosScreen:ResponsiblePlaceHolder')} 
                label={t('misProyectosScreen:ProjectResponsible')}
              /> 
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="p-3"> 
            <div className="p-fluid"> 
              <Label className="mr-2">{t('misProyectosScreen:AdvancedSearch')}</Label>
              <FieldCheckbox
                inputId={AdvancedSearch}
                checked={AdvancedSearch === 1}
                onChange={(e) => onChangeAdvancedSearch(props, e)}
              />
            </div>
          </Col>
        </Row>  
        <Row hidden={!(AdvancedSearch === 1 &&
            (ClaTipoProyecto === 1 ||
              ClaTipoProyecto === 2 ||
              ClaTipoProyecto === 3 ||
              ClaTipoProyecto === -1 
              )  && flagProyecto === 1)}
        >
          <Col lg={4} md={4} sm={12} xs={12} className="p-3" hidden={true}>
            <div className="p-fluid"> 
              <FieldText
                label={t('misProyectosScreen:AdvancedSearch')}
                value={AdvancedSearchText}
                onChange={(e) => onChangeAdvancedSearchText(props, e.target.value)}
                maxLength={250}
              /> 
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="p-3">
            <div className="p-fluid"> 
              <FieldDropdownMultiSelect
                isMulti={true}
                options={EstatusActividadesList}
                isClearable={true}
                isSearchable={true}
                value={ClaEstatusActividad} 
                name="NomEstatusActividad"
                onChange={(e) => onChangeEstatusActividad(e)}
                errors={errors}
                placeholder={t('misProyectosScreen:ActivityStatusPlaceHolder')}  
                label={t('misProyectosScreen:ActivityStatus')}
              />  
            </div>
          </Col> 
          <Col lg={4} md={4} sm={12} xs={12} className="p-3">
            <div className="p-fluid" hidden={flagProyecto === 0 || flagTipoProyecto === 0}>
              <FieldDropdownMultiSelect
                isMulti={true}
                options={ColaboradorPorProyectosList}
                isClearable={true}
                isSearchable={true}
                value={ClaColaborador}
                name="NomColaborador"
                onChange={(e) => onChangeColaboradorPorProyectos(e)}
                errors={errors}
                placeholder={t('misProyectosScreen:ResponsiblePlaceHolder')} 
                label={t('misProyectosScreen:Responsible')}
              /> 
            </div>
          </Col>
        </Row>
        <Row hidden={!(flagTipoProyecto === 0 && AdvancedSearch === 1)}>
          <Col lg={4} md={4} sm={12} xs={12} className="p-3">
            <div className="p-fluid">
              <FieldDropdownMultiSelect
                isMulti={true}
                options={ResponsablePorProyectosList}
                isClearable={true}
                isSearchable={true}
                value={ClaResponsableTeamMeber}
                name="NomColaborador"
                onChange={(e) => onChangeResponsableTeamMember(e)}
                errors={errors}
                placeholder={t('misProyectosScreen:TeamMemberPlaceHolder')} 
                label={t('misProyectosScreen:TeamMember')}
              /> 
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="text-right pr-5">
            <small className='danger-color'>{t('myactivities:DisclaimerProjectList')}</small>
          </Col>
        </Row>
      </Panel>
      { EsMostrarAltraProyecto ? <AltaProyecto show={EsMostrarAltraProyecto} onHide={onCloseAltaProyecto}></AltaProyecto> : <></>}
          
    </>
  );
};
export default MisProyectosFilter;

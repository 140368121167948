import { Skeleton } from 'primereact/skeleton';
import React from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTranslation } from 'react-i18next';
import './CompanyOverviewOverallStatusChartSkeleton.scss';
import { Card, CardBody, Col, Row } from 'reactstrap';

const CompanyOverviewOverallStatusChartSkeleton = (props) => {
  const {t, i18n} = useTranslation(['companyOverview', 'common']);  

  const summaryTemplate = (
    <>
      <Row>
        <Col xs={8}>
          <h5>
            {t('companyOverview:OverallStatus')}
          </h5>     
        </Col>
        <Col 
          xs={4} 
          className='text-right'
        >
          <Skeleton className="p-mb-2" borderRadius="16px" />
        </Col>
      </Row> 
    </>
  );

  const chartTemplate = (
    <>
      <Row className='text-center pt-2'>
        <Col>
          <Skeleton 
            className='CompanyOverviewOverallStatusChartSkeleton-status'
            width="3rem" 
            height="2rem" 
            borderRadius="12px"            
          />
        </Col>
        <Col>
          <Skeleton 
            className='CompanyOverviewOverallStatusChartSkeleton-status'
            width="3rem" 
            height="2rem" 
            borderRadius="12px"            
          />
        </Col>
        <Col>
          <Skeleton 
            className='CompanyOverviewOverallStatusChartSkeleton-status'
            width="3rem" 
            height="2rem" 
            borderRadius="12px"            
          />
        </Col>
      </Row>   
      <Row className='text-center'>
        <Col 
          xs={4}
          className='CompanyOverviewOverallStatusChart-label'
        >               
          <span>
            {t('companyOverview:Down')}            
          </span>
        </Col>
        <Col 
          xs={4}
          className='CompanyOverviewOverallStatusChart-label'
        >           
          <span>
            {t('companyOverview:Active')}            
          </span>
        </Col>
        <Col 
          xs={4}
          className='CompanyOverviewOverallStatusChart-label'
        >          
          <span>
            {t('companyOverview:Microstops')}            
          </span>
        </Col>
      </Row>        
    </>
  );

  return (
    <>
      <Card 
        className='CompanyOverviewOverallStatusChartSkeleton'
        style={{borderRadius: '1%'}}
      >
        <CardBody>
          <Row className='align-items-start'>
            <Col> 
              {summaryTemplate}
            </Col>            
          </Row>
          <Row className='align-items-start'>            
            <Col>
              {chartTemplate}                     
            </Col>
          </Row>
        </CardBody>
      </Card>   
    </>
  )
}

export default CompanyOverviewOverallStatusChartSkeleton
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'; 
import SurveyManagementContestar from '../SurveyManagement/SurveyManagementContestar.jsx';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import PageContent from '../../../../layouts/PageContent';
import InstructionsCard from '../../../../components/Cards/InstructionsCard/InstructionsCard.jsx';
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../../utils/utils';


const SurveyExternalRespondent  = (props) => {
  const { t } = useTranslation(['survey', 'common']);
  const [IdEncuesta, setIdEncuesta] = useState(null);
  const [IdEncuestaContestada, setIdEncuestaContestada] = useState(null);
  
  const { CodigoUnicoEncuesta } = props.match.params
  
  useEffect(() => {   
    getHRSurveyManagementInfo();
  }, []); 

  const getHRSurveyManagementInfo = () => {
    const GetHRSurveyByUniqueCode = `${config.UrlApiProject}HR/GetHRSurveyByUniqueCode?CodigoUnicoEncuesta=${CodigoUnicoEncuesta}`;
    const paramsToService = { 
    };
    callApi(GetHRSurveyByUniqueCode, 'GET', paramsToService, (response) => { 
      const encuesta =  response.data.Encuesta.length > 0 ? response.data.Encuesta[0] : {};
      setIdEncuestaContestada(encuesta.IdEncuestaContestada); 
      setIdEncuesta(encuesta.IdEncuesta); 
      

      if (encuesta.IdEncuesta === null) { 
        setIdEncuesta(-1); 
      }
    });
  };

  if (IdEncuesta === null) {
    return(
      <PageContent title={t('survey:Survey')}>
        Loading....
      </PageContent> 
    )
  }

  if (IdEncuesta === -1) {
    return(
      <PageContent title={t('survey:Survey')}>
        <InstructionsCard className="surveyNotFoundIntructions">
          <p>
            {t('survey:InvalidSurveycode')}.
          </p>
        </InstructionsCard>
      </PageContent> 
    )
  }

   return (
     <> 
       <PageContent title={t('survey:Survey')}>
         <SurveyManagementContestar IdEncuesta={IdEncuesta} showAsModal={false} IdEncuestaContestada={IdEncuestaContestada}></SurveyManagementContestar>
       </PageContent> 
     </>
   );
};

export default SurveyExternalRespondent ;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next';   
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row,Col, TabContent, TabPane , Nav, NavItem, NavLink} from 'reactstrap';   
import { config } from '../../../utils/config';
import { callApi,showSweetAlert } from '../../../utils/utils';  
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import FieldTextArea  from '../../../components/Controls/FieldTextArea.jsx'
import FieldText from '../../../components/Controls/FieldText.jsx' 
import FieldUpload from '../../../components/Controls/FieldUpload/FieldUpload.jsx';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator'; 
import swal from '@sweetalert/with-react';
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon'
import { Tooltip } from 'primereact/tooltip';

const AnexoEntregableActividad = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);  
  const [ProyectoRepActTerminadaList, setProyectoRepActTerminadaList] = useState([]); 

  useEffect(() => {    
    obtenerInformacionFasesProyecto(); 
  }, []); 

  useEffect(() => {
  }, [props.IdProyectoRepActTerminada]); 

  
  const obtenerInformacionFasesProyecto = () => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/GetProyectoRepActTerminadaSel?IdProyectoRepActTerminada=${props.IdProyectoRepActTerminada === undefined ? '' : props.IdProyectoRepActTerminada}`;  
     
    callApi(GetSummaryScorecardUser, 'GET', {}, (response) => {  
        setProyectoRepActTerminadaList(response.data.ProyectoRepActTerminadaList) 
    });
  }
  
 const downloadFile = (rowData) => { 
    const GetFileData = `${config.UrlApiProject}ScoreCard/GetProyectoRepActTerminadaId`;  
    const paramsToService = { 
      IdProyectoRepActTerminada:rowData.IdProyectoRepActTerminada
    };   
    callApi(GetFileData, 'POST', paramsToService, (response) => {
      const AnexoDetalle = response.data.AnexoDetalle[0];
      if(AnexoDetalle!==undefined){
        const linkSource = `data:application/${rowData.TipoExtension};base64,${AnexoDetalle.FileData}`;
        const downloadLink = document.createElement("a");
        const fileName = `${rowData.NombreArchivo}`;
        downloadLink.href = linkSource;
        downloadLink.download = `${fileName  }.${  rowData.TipoExtension}`;
        downloadLink.click();
      }
    });
 }
  

const NomArchivoTemplate = (rowData) => {
  return (
    <>
      { `${rowData.NombreArchivo}.${rowData.TipoExtension}` } 
    </>
  )
}
 
const AnexoInfo = (rowData) => {
    return (
      <>   
        <Col className="text-right">
          <Tooltip target=".p-button-info" mouseTrack mouseTrackTop={10} />
          <FieldButton
            type="button" 
            onClick={() => downloadFile(rowData)}
            data-pr-position="top" 
            data-pr-tooltip={t('scoreCard:Download')}
            icon="pi pi-arrow-circle-down"
            className="p-button-rounded p-button-info p-button-outlined"
          />  
        </Col>
      </>
    );
  }
  
    return (
      <>      
        <Row className="pt-3">
          <Col>
            <div style={{minHeight:"300px"}}>
              <FieldDataTable 
                value={ProyectoRepActTerminadaList} 
                header={t('scoreCard:Documents')}
                scrollable 
                scrollHeight="250px"
                className="p-datatable-striped"
              > 
                <Column field="NomUsuarioInsArchivo" columnKey="NomUsuarioInsArchivo" header={t('scoreCard:User')} headerStyle={{ width: '120px' }}></Column> 
                <Column field="NomArchivo" body={NomArchivoTemplate} columnKey="NomArchivo" header={t('scoreCard:File')} headerStyle={{ width: '240px' }}></Column> 
                <Column field="Comentarios" columnKey="Comentarios" header={t('scoreCard:Comments')} headerStyle={{ width: '180px' }}></Column> 
                <Column field="Link" columnKey="Link" header={t('scoreCard:Link')} headerStyle={{ width: '90px' }}></Column> 
                <Column body={AnexoInfo} headerStyle={{ width: '90px' }}></Column> 
             
              </FieldDataTable> 
            </div>
          </Col>
        </Row>  
      </> 
        ); 
};
export default AnexoEntregableActividad;
/* eslint-disable jsx-a11y/label-has-associated-control */
import React  from 'react';     
import { Chips } from 'primereact/chips';
import { Button } from 'reactstrap'; 

const FieldChips =  (props) => {

    const onChange = (value) => {  
        if (props.onChange !== null && props.onChange !== undefined){
            props.onChange(value);
        } 
    }  
    const onBlur = (value) => {  
      if (props.onBlur !== null && props.onBlur !== undefined){
          props.onBlur(value);
      } 
    }  
    const onKeyDown = (value) => {  
      if (props.onKeyDown !== null && props.onKeyDown !== undefined){
          props.onKeyDown(value);
      } 
    }  
    const onClick = () => {  
      if (props.onClick !== null && props.onClick !== undefined){
          props.onClick(); 
      } 
  }  

  const onFocus = (event) => {
    event.target.select();
    if (props.onFocus !== null && typeof(props.onFocus) === 'function'){
      props.onFocus(event);
    } 
  }

    const isFormFieldInValid = (name) => !!(typeof(props.errors) !== 'undefined' && props.errors !== null && props.errors[name]);
    
    const getFormErrorMessage = (name) => { 
        return isFormFieldInValid(name) && <small className="p-error">{props.errors[name].message}</small>;
    };

    return (  
      <>  
        <span className="p-float-label">
          <Chips 
            value={props.value === null ? '' : props.value} 
            onChange={onChange}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            className={isFormFieldInValid(props.name) === true ? 'p-invalid' : ''}
            disabled={props.disabled}
            maxLength={props.maxLength}
            placeholder={props.placeholder}
            autoFocus={props.autoFocus}
            ref={props.reference}
            onFocus={onFocus}
            tooltip={props.tooltip}
            tooltipOptions={props.tooltipOptions}
          />  
          <label className={isFormFieldInValid(props.name) === true ? 'p-error' : ''}>{props.label}</label>
        </span>
        { getFormErrorMessage(props.name) } 
      </> 
    )
}   

    
export default FieldChips; 

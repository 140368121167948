import React, { useState, useRef, useEffect  } from 'react'; 
import { useTranslation } from 'react-i18next';   
import { config } from '../../../utils/config';
import { callApi, showSweetAlert} from '../../../utils/utils';   
import { Row,Col,Card, CardHeader, CardTitle, CardBody, CardFooter} from 'reactstrap';   
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable.jsx';
import FieldRadioButton from 'components/Controls/FieldRadioButton/FieldRadioButton.jsx';
import FieldButton from 'components/Controls/FieldButton/FieldButton.jsx';
import FieldTextArea  from 'components/Controls/FieldTextArea.jsx'
import FieldCalendar from 'components/Controls/FieldCalendar.jsx'
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import ProductionWeeklyFollowUPComentarios from './ProductionWeeklyFollowUPComentarios';
import ProductionWeeklyFollowUPZona from './ProductionWeeklyFollowUPZona';
import YupValidator from '../../../utils/YupValidator';
import * as yup from 'yup';
import './ProductionDailyFollowUPScreen.scss';  
import { Skeleton } from 'primereact/skeleton';
import Highcharts from 'highcharts';
import FieldSelectButton from 'components/Controls/FieldSelectButton';
import ProductionWeeklyFollowUPTracker from './ProductionWeeklyFollowUPTracker';
import moment from 'moment';
import DailyOperationProductionTrend from './DailyOperationTracker/DailyOperationProductionTrend';

const ProductionWeeklyFollowUPDepartamento = (props) => { 
  const { t } = useTranslation(['wtw', 'common']);
  const [errors, setErrors] = useState({});   
  const [ZonasList, setZonasList] = useState([]);
  const [ClaAnioMes, setClaAnioMes] = useState(props.ClaAnioMes); 
  const [ClaUbicacion, setClaUbicacion] = useState(props.ClaUbicacion);
  const [ClaSemana, setClaSemana] = useState(props.ClaSemana); 
  const [ClaDepartamento, setClaDepartamento] = useState(props.ClaDepartamento); 
  const [ClaArea, setClaArea] = useState(props.ClaArea);
  const [NomArea, setNomArea] = useState(props.NomArea);

  const [ClaZona, setClaZona] = useState(null);   
  const [NomZona, setNomZona] = useState(null);  
  const [ExplicacionProblema, setExplicacionProblema] = useState(props.Comentarios);  
  const [PosibleSolucionProblema, setPosibleSolucionProblema] = useState(null); 
  const [FechaVencimiento, setFechaVencimiento] = useState(null); 
  const [EsApply, setEsApply] = useState(false); 
  const [EsMostrarDialogComentarios, setEsMostrarDialogComentarios] = useState(false); 
  const [EsMostrarDialogZona, setEsMostrarDialogZona] = useState(false); 
  const [EsActionPlan, setEsActionPlan] = useState(false); 
  const [TurnoList, setTurnoList] = useState([]);
  const [ClaTurno, setClaTurno] = useState([1, 2, 3]);
  const [EsCargandoListado, setEsCargandoListado] = useState(false);
  const [EsMostrarProduccionTendencia, setEsMostrarProduccionTendencia] = useState(false);

useEffect(() => { 
    console.log('ProductionWeeklyFollowUPDepartamento',props);
}, []) 
 
const onChangeExplicacionProblema = (e) => {  
  const newErrors = { ...errors }
  delete newErrors.ExplicacionProblema;   
  setErrors(newErrors);
  setExplicacionProblema(e.target.value);
} 

const valitationSchemeSave = yup.object().shape({
  ExplicacionProblema: yup.string().trim().required(t('common:RequiredField')).nullable() 
}); 

const onSave = async () => {  
  const value = {
   ExplicacionProblema
  }
  if(EsActionPlan){
    value.FechaVencimiento=FechaVencimiento;
    value.PosibleSolucionProblema=PosibleSolucionProblema;
  }
  const validator = new YupValidator(valitationSchemeSave);
  const esValido = await validator.validate(value);
  if (!esValido){ 
    setErrors(validator.errors);
    showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
    return;
  }
  const urlWebService = `${config.UrlApiProject}WTW/SaveProduccionSeguimientoSemanal`; 
  const paramsService = {
    IdProduccionSeguimientoSemanal:props.IdProduccionSeguimientoSemanal,
    ClaUbicacion:props.ClaUbicacion,
    ClaAnioMes:props.ClaAnioMes,
    ClaSemana:props.ClaSemana,
    ClaDepartamento:props.ClaDepartamento,
    ClaArea:props.ClaArea,
    ExplicacionProblema,
    PosibleSolucionProblema,
    FechaVencimiento
  }; 
  callApi(urlWebService, 'POST', paramsService, (response) => {
    showSweetAlert(t('common:Exito'), t('common:SaveSuccess'), 'success');
    setEsApply(true);
    onHideDialog();
  }, () => {
});
};

const onHideDialog = () => { 
  if (props.onHide !== null && typeof props.onHide === 'function') {
    props.onHide();
  }
};

 const templateHeaderDialog = (
   <> 
     <Row className='PriceTrackerScreen align-items-start'>
       <Col lg={10} md={10} sm={10} xs={10}>
         <div className='p-fluid'>
           <table>
             <tr style={{borderBottom: '1px dashed #cccccc'}}>
               <td style={{width: '120px'}}>
                 {t('wtw:Plant')}   
               </td>
               <td style={{width: '200px'}} className=''>{props.NomUbicacion} </td>
               <td style={{width: '120px'}}>
                 
               </td>
               <td style={{width: '300px'}} className=''> </td>
             </tr>
             <tr>
               <td>
                 {t('wtw:Departament')}    
               </td>
               <td className=''>{props.NomDepartamento} ({props.NomArea})</td>
               <td>
                  
               </td>
               <td className=''>{props.NomSemana}  </td>
             </tr>
           </table> 
         </div>
       </Col>
     </Row>   
   </>
 );
 
 const templateFooterDialog = (
   <>  
   </>
  );
 
  const ComentariosDiariosOperationTrackerDepartamento = () => {  
    return (
      <>
        <ProductionWeeklyFollowUPTracker
          ClaUbicacion={props.ClaUbicacion}
          ClaArea={props.ClaArea}
          FechaInicio={props.FechaInicio}
          FechaFin={props.FechaFin}
          NomArea={props.NomArea}  
        >
        </ProductionWeeklyFollowUPTracker>
      </>
    )
  }

  const showProductionTrend = (item) => {   
    setNomArea(item.NomArea);
    setClaArea(item.ClaArea);
    setEsMostrarProduccionTendencia(true);
  }

  const headerDialogProductionTrendDetail = () => {
    return (
      <>
        {t('wtw:ProductionTrendDetail')} - {moment(props.FechaFin).format('MMMM, YYYY') } - {NomArea} - {t('wtw:Shift')} : {t('wtw:All')} - {t('wtw:Cell')}: {t('wtw:All')}
      </>
    )
  }

  return (
    <> 
      <Dialog 
        className='PriceTrackerScreen dialog-header-background-blue'
        closable={true}
        visible={props.Visible}
        onHide={onHideDialog}  
        style={{ width: '100vw' }}
        maximized={true}
        header={templateHeaderDialog}
        footer={templateFooterDialog}
        blockScroll={true}
      > 
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
            <Row className='align-items-start'>
              <Col lg={8} md={8} sm={12} xs={12}> 
                {ComentariosDiariosOperationTrackerDepartamento()}
              </Col>  
              <Col className='sticky-top comment-section' lg={4} md={4} sm={12} xs={12}>
                <Row>
                  <Col>
                    <span className='h2'>{t('wtw:DepartmentlevelComments')}</span>
                    <div className='info-color'>
                      {t('wtw:CommentsFeel')} <b>{props.NomArea}</b>  {t('wtw:CommentsFeel2')}  
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>&nbsp;</Col>
                </Row>  
                <Row className="align-items-start">
                  <Col>
                    <div className="p-fluid">
                      <span>{`${t('wtw:Comments')}:`}</span>
                      <FieldTextArea 
                        name="ExplicacionProblema"
                        rows={15}
                        value={ExplicacionProblema}  
                        onChange={(e) => onChangeExplicacionProblema(e)}  
                        errors={errors} 
                        maxLength={3000}
                      />
                    </div>
                  </Col>
                </Row>  
                <Row className='pt-1'>
                  <Col lg={8} md={8} sm={8} xs={8}>
                    <div className='' onClick={() => showProductionTrend({ClaArea: props.ClaArea, NomArea: props.NomArea })}><span className='bold link-text primary-color'>View {props.NomArea} Production Trend <i className="fas fa-external-link-alt"></i></span></div> 
                  </Col>
                  <Col className='text-right' lg={4} md={4} sm={4} xs={4}>
                    <div className='text-right'>
                      <FieldButton
                        label={t('wtw:Save')}
                        icon="pi pi-save"
                        className="p-button p-button-success"
                        onClick={() => onSave()}
                      >
                      </FieldButton>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>   
          </Col>
        </Row> 

        {EsMostrarProduccionTendencia ? (
          <>
            <Dialog 
              visible={EsMostrarProduccionTendencia}
              header={headerDialogProductionTrendDetail} 
              draggable={false}
              modal 
              maximized={true}
              className="DailyOperationAssetDetailTree dialog-header-background-blue"
              onHide={() => setEsMostrarProduccionTendencia(false)}
              position="top"
              blockScroll={true}
            > 
              <DailyOperationProductionTrend
                visible={EsMostrarProduccionTendencia} 
                Fecha={props.FechaFin}
                ClaUbicacion={ClaUbicacion}
                ClaArea={ClaArea}
                ClaCelda={null}
                ClaTurno={null}
                DisableFilters={true}
              >
              </DailyOperationProductionTrend>
            </Dialog>  
          </>
        ) : (
          <></>
        )}  
      </Dialog>     
    </>
  );
};
export default ProductionWeeklyFollowUPDepartamento;

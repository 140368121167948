/* eslint-disable object-shorthand */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-template */
import React, {useState, useEffect} from 'react';
import { 
  Row, 
  Col,
  Card,
  CardHeader,
  CardBody,
  CardText,
  CardFooter } from 'reactstrap';
  import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { config } from '../../../utils/config';
import { callApi } from '../../../utils/utils'; 
import './ActiveDowntimes.scss';
import PageContent from '../../../layouts/PageContent';
import { Dialog } from 'primereact/dialog';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable';
import { Column } from 'primereact/column'
import ActiveDowntimesComponent from './ActiveDowntimesComponent.jsx';
import GantryWarningBanner from 'views/Operations/Tablets/Common/GantryWarningBanner/GantryWarningBanner.jsx';

const ActiveDowntimes = (props) => {
  const { t, i18n } = useTranslation(['productionDashboard', 'common']);

  return (
    <PageContent title={t('productionDashboard:ActiveDowntimesTitle')}>
      <ActiveDowntimesComponent {...props} />
      <GantryWarningBanner></GantryWarningBanner>
    </PageContent>
  );
}
export default ActiveDowntimes;
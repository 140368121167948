/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-const-assign */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next'; 
import FieldButton from 'components/Controls/FieldButton/FieldButton'; 
import { 
  Row,
  Col 
} from 'reactstrap';
import './HeaderTablet.css'; 
import MSWLogo from '../../../../../assets/img/MCSW-blue.png'
import GantryWarningBanner from '../GantryWarningBanner/GantryWarningBanner';

const HeaderTablet = (props) => {
  const { t, i18n } = useTranslation(['tablets', 'common']);
  
  useEffect(() => {
    console.log('use effect', props.IdCelda)
  }, [props.ClaTurno, props.NomTurno, props.ClaAreaProduccion, props.NomAreaProduccion, props.IdCelda, props.NomCelda]);

  const onScrollUp = () => { 
    window.scrollTo(0, window.scrollY-200);
  }

  const onScrollDown = () => {
    window.scrollTo(0, window.scrollY+200);
  }

  return (
    <> 
      <Row className="tablet-header-main">
        <Col lg={2} md={2} sm={2} xs={2}>      
          <img style={{width:'60px', height: '60px'}} src={MSWLogo}></img>      
        </Col>
        <Col lg={10} md={10} sm={10} xs={10}>
          <Row>
            <Col lg={8} md={8} sm={8} xs={8}>
              <Row>
                <Col><span className="tablet-header-label">{t('tablets:Shift')}</span></Col>
                <Col><span className="tablet-header-label">{t('tablets:Deparment')}</span></Col>
              </Row>
              <Row>
                <Col><span className="tablet-header-label-value">{props.NomTurno}</span></Col>
                <Col> <span className="tablet-header-label-value">{props.NomAreaProduccion}</span></Col>
              </Row> 
            </Col> 
            <Col lg={4} md={4} sm={4} xs={4} className='text-right'> 
              <span className='tablet-header-cell-number'>#{props.ClaveCelda}</span>
              
            </Col>
          </Row>           
        </Col>
      </Row> 
      <>
        <div className='tablet-icon-position-bottom-left'>
          <FieldButton
            icon="fas fa-chevron-up"
            onClick={() => onScrollUp()}
            className="p-button-rounded tablet-icon-style"
          />
        </div>
        <div className='tablet-icon-position-bottom-right'>
          <FieldButton
            icon="fas fa-chevron-down"
            onClick={() => onScrollDown()}
            className="p-button-rounded tablet-icon-style"
          />
        </div>
      </>
      {
        /*
        props.IdCelda !== null && typeof(props.IdCelda) !== 'undefined' ?
        (
          <GantryWarningBanner ClaCelda={props.IdCelda}></GantryWarningBanner>
        )
        :
        (<></>)
        */
      }
      
    </>
  );
};

export default HeaderTablet;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React , { useState, useEffect } from 'react'; 
import { FormGroup, Label, Card, CardHeader, CardBody, Row, Col, CardTitle,  CardFooter, InputGroup, InputGroupText } from 'reactstrap'; 
import { config } from '../../utils/config'; 
import { callApi, showSweetAlert, showNotify, getCliente } from '../../utils/utils';  
import queryString from 'query-string' 
import  WizardLayout from '../../components/Wizard/Wizard.js'; 
import { Skeleton } from 'primereact/skeleton'; 
import { SelectButton } from 'primereact/selectbutton'; 
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import DateBox from 'devextreme-react/date-box'; 
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Column } from 'primereact/column'; 
import '../../assets/css/DataViewBase.css';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button'; 
import { NavLink as NavLinkRRD, Link, Switch } from 'react-router-dom';
import { Checkbox } from 'primereact/checkbox';
import { withTranslation } from 'react-i18next'; 
import ResultadoFormularioDet from './ResultadoFormularioDet.js'; 
import PageContent from '../../layouts/PageContent'; 

class ResultadoFormulario extends React.Component {
     
    constructor(props) {
      super(props); 

      this.state = {
        IsLoading:false,
        ClaFormulario:null,
        Shifts:[],
        Departments:[],
        Celdas:[],
        Formularios:[],
        FormularioResueltos:[],
        layout:'grid'
      } 
 
      this.onFormularioSelectedChange = this.onFormularioSelectedChange.bind(this);
      this.onStartDateChange = this.onStartDateChange.bind(this); 
      this.onLayoutChange = this.onLayoutChange.bind(this); 
      this.onActionsClick = this.onActionsClick.bind(this);
      this.onSearch = this.onSearch.bind(this);
      this.onEsRequiereRevisionChange = this.onEsRequiereRevisionChange.bind(this); 
      this.onclickButton = this.onclickButton.bind(this);
      this.returnResultados = this.returnResultados.bind(this);
      this.formatTime = this.formatTime.bind(this);
    } 

    componentDidMount()   
    {  
        let urlWebService = `${config.UrlApiProject}formulario/getFormularios`; 

        const paramsService = null;

        this.setState({
          EsRequiereRevision: false,
          EsVerDetalle: false,
          IsLoading: true, 
        });
        
        callApi(urlWebService, 'GET', paramsService, (response) => {
            this.setState({
                Formularios: response.data.Formularios 
            }); 

            // obtenemos los departamentos 
            urlWebService = `${config.UrlApiProject}departamento/getDepartamentos`; 
            
            callApi(urlWebService, 'GET', paramsService, (response) => {
                this.setState({
                  Departments: response.data.Departamentos 
                });
                // obtenemos los turnos

                urlWebService = `${config.UrlApiProject}turno/getTurnos`; 
              
                callApi(urlWebService, 'GET', paramsService, (response) => {
                    this.setState({
                      Shifts: response.data.Turnos, 
                        IsLoading:false
                    });
                  // obtenemos los turnos
                });    
                
            });    
        });  
 
    } 
    
    componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevState.ClaTurno !== this.state.ClaTurno) {
        this.onSearch();
      }

      if (prevState.ClaFormulario !== this.state.ClaFormulario) {
        this.onSearch();
      }

      if (prevState.ClaDepartamento !== this.state.ClaDepartamento) {
        this.onSearch();
      }

      if (prevState.EsRequiereRevision !== this.state.EsRequiereRevision) {
        this.onSearch();
      }

      if (prevState.FechaCapturaFin !== this.state.FechaCapturaFin) {
        this.onSearch();
      }

      if (prevState.FechaCapturaInicia !== this.state.FechaCapturaInicia) {
        this.onSearch();
      }

    }

   
    onclickButton(IdFormularioResuelto)  { 
      this.setState({ 
        IsLoading:false,
        EsVerDetalle:true,
        IdFormularioResuelto
      });


    }

    returnResultados()  { 
      console.log('entro aquiu');
      this.setState({ 
        IsLoading:false,
        EsVerDetalle:false,
        IdFormularioResuelto:null
      });


    }
    

    onFormularioSelectedChange = ( event ) => { 
        this.setState ( ( state, props ) => {  
            return { 
              ClaFormulario: event.value,
              ClaCelda:null ,
              ClaDepartamento:null ,
              ClaTurno:null,
              Celdas:[],
              FormularioResueltos:[]
          }
        } )
      };
      
    
      onStartDateChange = ( event ) => { 
        this.setState ( ( state, props ) => {
          return { 
            FechaCapturaInicia: event.value,
            FormularioResueltos:[]
          }
        } )
     
     };

     onEsRequiereRevisionChange = ( event ) => { 
      this.setState ( ( state, props ) => {
        return { 
          EsRequiereRevision: event.checked,
          FormularioResueltos:[]
        }
      } )
   
    };

     onEndDateChange = ( event ) => { 
        this.setState ( ( state, props ) => {
          return { 
            FechaCapturaFin: event.value,
            FormularioResueltos:[]
          }
        } )
     
     };  

     onLayoutChange = ( event ) => { 
        this.setState ( ( state, props ) => {
          return { 
             layout: event.value
          }
        } )
     
     };

     onActionsClick = ( event ) => {  
        console.log(this.menuRef.current);
        this.menuRef.current.toggle(event)
   
     };

     onSelectedShiftChange = ( event ) => { 
      this.setState ( ( state, props ) => {  
          return { 
            ClaTurno: event.value ,
            FormularioResueltos:[]
        }
      } )  
    };

    onSelectedDepartmentChange = ( event ) => { 

      let {value} = event;
  
      this.setState ( ( state, props ) => {  
          return { 
            ClaDepartamento: event.value,
            ClaCelda:null ,
            FormularioResueltos:[]
        }
      } )
      
      if (value === null)
      {
        value = '';
        this.setState({ 
          Celdas:[] ,
          FormularioResueltos:[]
        });
        
        return;
      }
  
      
      const urlWebService = `${config.UrlApiProject}celda/GetCeldas?ClaDepartamento=${value}`; 
      const paramsService = null;
  
      callApi(urlWebService, 'GET', paramsService, (response) => {
        this.setState({ 
          Celdas:response.data.Celdas ,
          FormularioResueltos:[]
        });
  
  
      });  
  
  
    };

    onSelectedCeldaChange = ( event ) => { 
      this.setState ( ( state, props ) => {  
          return { 
            ClaCelda: event.value ,
            FormularioResueltos:[]
        }
      } )  
    };

    onSearch()  { 
      
      this.setState({IsLoading:true});


      const urlServ = `${config.UrlApiProject}formulario/getFormulariosResueltos`;
      const params = {
          ...this.state
      }; 

      // console.log( JSON.stringify( params ) );
      callApi(urlServ, "POST", params, (res) => {
        if (res.hasError) {
          showSweetAlert(null, res.mensaje, 'error');
        } else { 
          this.setState({
            FormularioResueltos: res.data.FormularioResueltos, 
            IsLoading:false
         });
 
        }
      });
  };
  
  // Format the time
  formatTime(inputTime) {
    const time = new Date(inputTime);

    const options = {
      dateStyle: "short",
      timeStyle: "short"
    }

    return(time.toLocaleString("en-US", options));
  };


  render () {   
        const itemTemplate = (item, layout) => {
            if (!item) {
                return;
            }
    
            if (layout === 'list')
                return renderListItem(item);
            if (layout === 'grid')
                return renderGridItem(item);
        }

        const items = [
          {
              label: 'Options' ,
              command: () => {
                  console.log('click options');
              }
          },
          {
              label: 'Navigate' ,
              command: () => {
                console.log('click Navigate');
              }
          }
      ];

        const renderListItem = (data) => {
            return (
              <div className="p-col-12">
                <div className="product-list-item"> 
                  <div className="product-list-detail">
                    <span className="product-category">{this.formatTime(data.FechaCaptura)}</span>
                    <div className="product-name">{data.NomUsuarioResuelve}</div>
                    <div className="product-description">{data.NomFormulario}</div> 

                    { data.ClaFormulario === 1 ?  
                    ( 
                      // cuando es el formulario 1
                      <div className="product-grid-item-additional-info">  
                        <Row>  
                          <Col lg={4} md={2} sm={6} xs={6}> 
                            <div>
                              <span className="product-label">{this.props.t('formulario:Shift')}</span>  
                            </div>
                            {data.NomTurno} 
                          </Col>
                          <Col lg={4} md={8} sm={6} xs={6}>   
                            <div>
                              <span className="product-label">{this.props.t('formulario:Department')}</span>  
                            </div>
                            {data.NomDepartamento} 
                          </Col>  
                          <Col lg={4} md={2} sm={6} xs={6}> 
                            <div>
                              <span className="product-label">{this.props.t('formulario:Cell')}</span>  
                            </div> 
                            {data.ClaveCelda}
                          </Col>
                        </Row>
                      </div>
                    ) 
                    : data.ClaFormulario === 2 ? (
                      <div className="product-grid-item-additional-info">  
                        <Row>  
                          <Col lg={12} md={12} sm={12} xs={12}> 
                            <div>
                              <span className="product-label">{this.props.t('formulario:Shift')}</span>  
                            </div>
                            {data.NomTurno} 
                          </Col>
                        </Row>
                      </div>
                  )
                  : data.ClaFormulario === 3 || data.ClaFormulario === 4 || data.ClaFormulario === 5 ? (
                    <div className="product-grid-item-additional-info">  
                      <Row>  
                        <Col lg={4} md={2} sm={6} xs={6}> 
                          <div>
                            <span className="product-label">{this.props.t('formulario:Shift')}</span>  
                          </div>
                          {data.NomTurno} 
                        </Col>
                        <Col lg={4} md={8} sm={6} xs={6}>   
                          <div>
                            <span className="product-label">{this.props.t('formulario:Department')}</span>  
                          </div>
                          {data.NomDepartamento} 
                        </Col>  
                        <Col lg={4} md={2} sm={6} xs={6}> 
                          <div>
                            <span className="product-label">{this.props.t('formulario:Cell')}</span>  
                          </div> 
                          {`${data.ClaveCelda}${data.ABCMachine !== null && data.ABCMachine !== undefined ? data.ABCMachine : ''}`}
                        </Col>
                      </Row>
                    </div>
                  ) 
                  : <></> }
                  </div>
                  <div className="product-list-action">
                    <span>{ data.EsRequiereRevision === true ? (<i className="fab fa-font-awesome-flag text-warning"></i>) : <></> }</span>
                    <Button label="View Detail" className="p-button-secondary" onClick={() => { this.onclickButton(data.IdFormularioResuelto)}} />         
                    <Button label="Download" icon="pi pi-file-pdf" disabled={true} className="p-button-info" />  
                  </div>
                </div> 
              </div>
            );
        }
    
        const renderGridItem = (data) => {
            return ( 
              <div className="col-12 col-md-4 p-nogutter">
                <div className="product-grid-item card">
                  <div className="product-grid-item-top">
                    <div> 
                      <span className="product-category">{this.formatTime(data.FechaCaptura)}</span>
                    </div> 
                    { data.EsRequiereRevision === true ? (<i className="fab fa-font-awesome-flag text-warning"></i>) : <></> }
                  </div>
                  <div className="product-grid-item-content"> 
                    
                    <div className="product-name">{data.NomUsuarioResuelve}</div>
                    <div className="product-description">{data.NomFormulario}</div> 
                  </div> 
                  { data.ClaFormulario === 1 ?  
                  ( 
                    // cuando es el formulario 1
                    <div className="product-grid-item-additional-info">  
                      <Row>  
                        <Col lg={2} md={1} sm={6} xs={6}> 
                          <div>
                            <span className="product-label">{this.props.t('formulario:Shift')}</span>  
                          </div>
                          {data.NomTurno} 
                        </Col>
                        <Col lg={8} md={10} sm={6} xs={6}>   
                          <div>
                            <span className="product-label">{this.props.t('formulario:Department')}</span>  
                          </div>
                          {data.NomDepartamento} 
                        </Col>  
                        <Col lg={2} md={1} sm={6} xs={6}> 
                          <div>
                            <span className="product-label">{this.props.t('formulario:Cell')}</span>  
                          </div> 
                          {data.ClaveCelda}  
                        </Col>
                      </Row>
                    </div>
                  ) 
                  : data.ClaFormulario === 2 ? (
                    <div className="product-grid-item-additional-info">  
                      <Row>  
                        <Col lg={12} md={12} sm={12} xs={12}> 
                          <div>
                            <span className="product-label">{this.props.t('formulario:Shift')}</span>  
                          </div>
                          {data.NomTurno} 
                        </Col>
                      </Row>
                    </div>
                  ) 
                  : data.ClaFormulario === 3 || data.ClaFormulario === 4 || data.ClaFormulario === 5 ? (
                    <div className="product-grid-item-additional-info">  
                      <Row>  
                        <Col lg={2} md={1} sm={6} xs={6}> 
                          <div>
                            <span className="product-label">{this.props.t('formulario:Shift')}</span>  
                          </div>
                          {data.NomTurno} 
                        </Col>
                        <Col lg={8} md={10} sm={6} xs={6}>   
                          <div>
                            <span className="product-label">{this.props.t('formulario:Department')}</span>  
                          </div>
                          {data.NomDepartamento} 
                        </Col>  
                        <Col lg={2} md={1} sm={6} xs={6}> 
                          <div>
                            <span className="product-label">{this.props.t('formulario:Cell')}</span>  
                          </div> 
                          {`${data.ClaveCelda}${data.ABCMachine !== null && data.ABCMachine !== undefined ? data.ABCMachine : ''}`} 
                        </Col>
                      </Row>
                    </div>
                  )
                  : <></>}
                  <div className="product-grid-item-bottom-toolbar">   
                    <Button label="View Detail" className="p-button-secondary" onClick={() => { this.onclickButton(data.IdFormularioResuelto)}} />         
                     &nbsp;
                    <Button label="Download" icon="pi pi-file-pdf" disabled={true} className="p-button-info" /> 
                  </div>
                </div>
              </div>
            );
        }

        
        const renderHeader = () => {
            return (
              <div className="p-grid p-nogutter">
                <div className="p-col-6" style={{textAlign: 'left'}}>
                  &nbsp;
                </div>
                <div className="p-col-6" style={{textAlign: 'right'}}>
                  <DataViewLayoutOptions layout={this.state.layout} onChange={this.onLayoutChange} />
                </div>
              </div>
            );
        }

        const filterHeaderTemplate = (options) => {
            const toggleIcon = options.collapsed ? 'pi pi-chevron-down text-white' : 'pi pi-chevron-up text-white';
            const className = `${options.className} p-jc-start`;
            const titleClassName = `${options.titleClassName} p-pl-1`;
    
            return (
              <div className="card-header">
                <div className="p-toolbar p-component">
                  <div className="p-toolbar-group-left">
                    <div className="card-title">
                      <button type="button" className={options.togglerClassName} onClick={options.onTogglerClick}>
                        <span className={toggleIcon}></span>
                        <Ripple />
                      </button>
                      <span className={titleClassName}>
                        {this.props.t('common:Filters')}
                      </span>
                    </div>  
                  </div>
                 
                  <div className="p-toolbar-group-right">
                    <button type="button" className="p-button p-button-icon-only p-highlight" onClick={this.onSearch}>
                      <i className="pi pi-search"></i>
                    </button>

                  </div>
                </div> 
              </div>
            )
        }
 

    
        const header = renderHeader();

        return (
          <>
            <PageContent title={this.props.t('formulario:QuestionnaireResult')}>  
              <Panel header="Filters" toggleable headerTemplate={filterHeaderTemplate}> 
                <div className="p-grid p-nogutter">
                  <div className="col-12" style={{textAlign: 'left'}}> 
                    <div className="p-grid">
                      <div className="p-field p-fluid col-12 col-md-4">
                        <label htmlFor="questionary">{this.props.t('formulario:Questionnaire')}</label>
                        <Dropdown
                          value={this.state.ClaFormulario}
                          options={this.state.Formularios}
                          onChange={this.onFormularioSelectedChange}
                          optionLabel="NomFormulario"
                          optionValue="ClaFormulario"
                          filter
                          showClear
                          filterBy="NomFormulario"
                        />
                      </div>
                      { this.state.ClaFormulario === 1 ?  
                  ( 
                    // cuando es el formulario 1
                    <>
                      <div className="p-field col-12 col-md-4">
                        <label htmlFor="Shift">{this.props.t('formulario:Shift')}</label>
                        <SelectButton  
                          value={this.state.ClaTurno} 
                          onChange={this.onSelectedShiftChange}
                          options={this.state.Shifts} 
                          optionLabel="NomTurno"
                          optionValue="ClaTurno"
                        > 
                        </SelectButton> 
                      </div>
                      <div className="p-field col-12 col-md-4">
                        <label htmlFor="Department">{this.props.t('formulario:Department')}</label>
                        <SelectButton  
                          value={this.state.ClaDepartamento} 
                          onChange={this.onSelectedDepartmentChange}
                          options={this.state.Departments} 
                          optionLabel="NomDepartamento4"
                          optionValue="ClaDepartamento4"
                        > 
                        </SelectButton> 
                      </div>
                      {
                       this.state.Celdas.length > 0 ? (
                         <div className="p-field col-12 col-md-4">
                           <FormGroup>
                             <label htmlFor="Cell">{this.props.t('formulario:Cell')}</label>
                             <SelectButton  
                               value={this.state.ClaCelda} 
                               onChange={this.onSelectedCeldaChange}
                               options={this.state.Celdas} 
                               optionLabel="ClaveCelda"
                               optionValue="ClaCelda"
                               className="cellList scrollbar-primary scroll-y"
                             > 
                             </SelectButton>
                           </FormGroup> 
                         </div>
                       ) : <></> 
}
                    </>
                  ) 
                  : this.state.ClaFormulario === 2 ? (
                    <div className="p-field col-12 col-md-4">
                      <label htmlFor="questionary">{this.props.t('formulario:Shift')}</label>
                      <SelectButton  
                        value={this.state.ClaTurno} 
                        onChange={this.onSelectedShiftChange}
                        options={this.state.Shifts} 
                        optionLabel="NomTurno"
                        optionValue="ClaTurno"
                      > 
                      </SelectButton> 
                    </div>
                  ) : this.state.ClaFormulario === 3 || this.state.ClaFormulario === 4 || this.state.ClaFormulario === 5 ? (
                    <>
                      <div className="p-field col-12 col-md-8">
                        <label htmlFor="Shift">{this.props.t('formulario:Shift')}</label>
                        <SelectButton  
                          value={this.state.ClaTurno} 
                          onChange={this.onSelectedShiftChange}
                          options={this.state.Shifts} 
                          optionLabel="NomTurno"
                          optionValue="ClaTurno"
                        > 
                        </SelectButton> 
                      </div>
                    </>
                  ) : <></>}

                    
                      <div className="p-field col-12 col-md-4">
                        <label htmlFor="StartDate">{this.props.t('common:StartDate')}</label>
                        <DateBox 
                          pickerType="calendar"
                          showClearButton={true}
                          onValueChanged={this.onStartDateChange}
                          defaultValue={this.state.FechaCapturaInicia}
                        />
                      </div>
                      <div className="p-field col-12 col-md-4">
                        <label htmlFor="EndDate">{this.props.t('common:EndDate')}</label>
                        <DateBox 
                          pickerType="calendar"
                          showClearButton={true}
                          onValueChanged={this.onEndDateChange}
                          defaultValue={this.state.FechaCapturaFin}
                        />
                      </div>
                      <div className="p-field-checkbox col-12 col-md-4">
                        <Checkbox inputId="EsRequiereRevision" checked={this.state.EsRequiereRevision} onChange={this.onEsRequiereRevisionChange} />
                        <label htmlFor="EsRequiereRevision">{this.props.t('formulario:ShowOnlyFlagged')}</label>
                      </div>

                    </div>  
                  </div> 
                </div>
              </Panel>
              <Row> 
                <Col lg={12} md={12} sm={12} xs={12}>
                    &nbsp;
                </Col>
              </Row> 
              <Row> 
                { this.state.EsVerDetalle === false ?  
                  ( 
                    <Col lg={12} md={12} sm={12} xs={12}> 
                      <div className="dataview-base">
                        <div className="card">
                          <DataView
                            value={this.state.FormularioResueltos}
                            layout={this.state.layout}
                            header={header}
                            itemTemplate={itemTemplate}
                            paginator  
                            alwaysShowPaginator={false}
                            paginatorPosition="both"
                            rows={50}
                          />
                        </div>
                      </div>
                    </Col>
                  )
                  :
                  (
                    <Col lg={12} md={12} sm={12} xs={12}> 
                      <div className="dataview-base">
                        <ResultadoFormularioDet returnResultados={this.returnResultados} IdFormularioResuelto={this.state.IdFormularioResuelto}></ResultadoFormularioDet>                      
                      </div>
                    </Col>
                  )}
              </Row>  
            </PageContent> 
          </>
);
    }

}


export default withTranslation(['formulario','common'])(ResultadoFormulario); 
import React, {useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardHeader, CardBody, CardFooter, Label } from 'reactstrap';
import { callApi, showSweetAlert, formatDate, formatPhoneNumberNA } from "utils/utils";
import { config } from 'utils/config';

const BusinessCardQR = (props) => {
  const { t } = useTranslation(['qr', 'common']);

  const claUsuarioBC = props.match.params.ClaUsuarioBC;
  const [qrCode, setQrCode] = useState({});
  const [userName, setUserName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [companyName, setCompanyName] = useState('');

  useEffect(() => {
    getQRCode();
    getVCardInfo();
  }, [])

  const getQRCode = () => { 
    const url = `${config.UrlApiProject}QRCode/GetBusinessCardQRCode?ClaUsuarioBC=${claUsuarioBC}`;  
    const paramsService= {};  
    callApi(url, 'GET', paramsService, (response) => {
      setQrCode({...response.data});
    });
  }

    // Get the employee info
    const getVCardInfo = () => {
      const url = `${config.UrlApiProject}QRCode/GetVCardInfo?ClaUsuario=${claUsuarioBC}`;  
      const paramsService= {};  
      callApi(url, 'GET', paramsService, (response) => {
        setUserName(response.data.info.FullName);
        setJobTitle(response.data.info.JobTitle);
        setCompanyName(response.data.info.CompanyName);
      });
    }

  return (
    <Row style={{backgroundColor: '#162d48', height: '100vh', overflow: 'hidden'}}>
      <Col xs={12} sm={12} md={12} lg={12} className='ml-2'>
        <span style={{color: '#cf3339'}}>{t('qr:Name')}</span>
        <h2 style={{color: 'white'}}>{userName}</h2>
        <span style={{color: '#cf3339'}}>{t('qr:JobTitle')}</span>
        <h2 style={{color: 'white'}}>{jobTitle}</h2>
        <span style={{color: '#cf3339'}}>{t('qr:Company')}</span>
        <h2 style={{color: 'white'}}>{companyName}</h2>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} className='p-d-flex p-jc-center p-ai-center'>
        <img 
          src={`data:${qrCode.mimeType};base64,${qrCode.file}`}
          alt=''
          style={{maxWidth: '75vw', maxHeight: '75vh'}}
        />
      </Col>
    </Row>
  )
}

export default BusinessCardQR;
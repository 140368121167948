/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'primereact/column';
import {
  Row,
  Col,
  ListGroupItem,
  ListGroup,
  Progress,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from 'reactstrap';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { Badge } from 'primereact/badge';
import { Link } from 'react-router-dom';
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../../utils/utils';
import Score from '../../../../components/Cards/Score/Score.jsx';
import FieldDataTable from '../../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import PropType from 'prop-types';
import FieldButton from 'components/Controls/FieldButton/FieldButton';

const TeamPerformanceCalculationDet = ({ ClaPuesto, ClaMes, ClaAnio, onCloseClick, showCloseButton }) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);
  const [TeamPerformanceInfo, setTeamPerformanceInfo] = useState([]);

  useEffect(() => {
    loadTeamPerformanceData(ClaPuesto, ClaMes, ClaAnio);
    
  }, [ClaPuesto, ClaMes, ClaAnio]);

  const loadTeamPerformanceData = (pClaPuesto, pClaMes, pClaAnio) => {
    const GetTeamPerformanceDevelopmentInfo = `${config.UrlApiProject}ScoreCard/GetTeamPerformanceDevelopmentMonthInfo`;
    const paramsToService = {
      ClaPuesto: pClaPuesto,
      ClaMes: pClaMes,
      ClaAnio: pClaAnio,
    };

    callApi(GetTeamPerformanceDevelopmentInfo, 'POST', paramsToService, (response) => {
      setTeamPerformanceInfo(response.data.TeamPerformanceInfo);
      
    });
  };

  const Points = (rowData) => {
    return (
      <>
        <Score score={rowData.Score} small severity={rowData.ScoreSeverity}></Score>
      </>
    );
  };

  const EmployeeName = (rowData) => {
    return (
      <> 
        {rowData.EmployeeName} 
      </>
    );
  };

  const header = <>&nbsp;</>;

  return (
    <>
      <Card>
        <CardBody>
          <h1>{t('hr:TeamPerformanceIndexCalculation')}</h1>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <FieldDataTable
                value={TeamPerformanceInfo}
                scrollable
                className="p-datatable-striped"
                selectionMode="single"
                metaKeySelection={false}
                header={header}
                paginator={false}
              >
                <Column
                  header={t('hr:EmployeeName')}
                  headerStyle={{ width: '172px' }}
                  className="text-left"
                  body={EmployeeName}
                >
                </Column>
                <Column
                  header={t('hr:ScorecardReview')}
                  headerStyle={{ width: '38px' }}
                  body={Points}
                >
                </Column>
              </FieldDataTable>
            </Col>
          </Row>
        </CardBody>
        <CardFooter hidden={!showCloseButton}>
          <Row>
            <Col className='text-center'>
              <FieldButton
                label={t('common:Close')}
                icon="fas fa-times"
                className="warning no-border p-mr-2 rounded-button"
                onClick={typeof(onCloseClick) !== 'undefined' && typeof(onCloseClick) === 'function' ? onCloseClick : null}
              />
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  );
};

TeamPerformanceCalculationDet.prototype = {
  ClaPuesto: PropType.number,
  ClaMes: PropType.number,
  ClaAnio: PropType.number,
};

export default TeamPerformanceCalculationDet;

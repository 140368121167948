/* eslint-disable react/static-property-placement */
import React from 'react';
// nodejs library that concatenates classes
import classnames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// import images
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  Nav,
  Container,
  Row, 
  Col,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Button,
  Avatar
} from 'reactstrap';
import team4 from '../../assets/img/default-avatar.png';
import logo from '../../assets/img/Sirius_logo.png';
// reactstrap components
import { logOut, getSessionItem } from '../../utils/utils';

import { AppContext } from 'context/AppContext';

import Alerts from '../Sidebar/Alerts.jsx';
import PushNotification  from '../Sidebar/PushNotification.jsx';
import EnterTicketButton from 'components/Sidebar/EnterTicketButton';
import HelpCenter from 'components/Sidebar/HelpCenter.jsx';
import { NavLink as NavLinkRRD, Link, useHistory, Redirect } from 'react-router-dom';


class AdminNavbar extends React.Component {

  static contextType = AppContext;

  toggleSideAdminBar = (e) => {  
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden'); 
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden'); 
    } 
  };

  homeScreen = () => { 
    this.props.history.push('/layout/HomeHR'); 
  }
      
  render() { 

    return (
      <> 
        <Navbar
          className={classnames(
            'navbar-top navbar-expand border-bottom',
            { 'navbar-dark bg-header': this.props.theme === 'dark' },
            { 'navbar-light bg-secondary': this.props.theme === 'light' }
          )}
        >
          <Container fluid className="layout-container">
            <Collapse navbar isOpen>
              <Nav className="align-items-left ml-md-0 d-xl-none" navbar>
                <div 
                  className='d-xl-none Cursor' 
                  style={{paddingRight:'10px'}}
                  onClick={this.toggleSideAdminBar}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </Nav>              
              <Nav className="align-items-right mr-auto " navbar>   
                <img alt="..." src={logo} style={{ width: '160px', height: '30px' }}  />
              </Nav> 
              <Nav className="align-items-right ml-md-0 d-lg-none d-xl-none" navbar>
                <Alerts></Alerts>
              </Nav>     
              
              <Nav className="align-items-center ml-md-auto ml-md-auto d-none d-xl-flex d-lg-flex" navbar>
                <UncontrolledDropdown nav style={{ cursor: 'pointer' }}>
                  <DropdownToggle className="nav-link pr-0" color="" tag="a">
                    <Media className="align-items-center"> 
                      <Media className="ml-2">
                        <span className="mb-0 text-sm font-weight-bold userName d-none d-lg-block">
                          Welcome: {getSessionItem('NomUsuario', '')}
                        </span>  
                        
                        {/* <span className="avatar avatar-md rounded-circle d-block d-lg-none">
                          <img alt="..." src={team4}  />
                        </span>  */}
                        &nbsp;
                      </Media>
                      
                    </Media>
                  </DropdownToggle>
                  {/* <DropdownMenu right>
                    <DropdownItem className="noti-title" header tag="div">
                      <h6 className="text-overflow m-0">Welcome!</h6>
                    </DropdownItem>
                    <DropdownItem className="noti-title" header tag="div">
                      <i className="fa fa-home" />
                      <h6 className="text-overflow m-0">Home</h6>
                    </DropdownItem>
                    <DropdownItem className="noti-title" header tag="div">
                      <i className="fa fa-user" />
                      <h6 className="text-overflow m-0">User profile</h6>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem href="#salir" onClick={logOut}>
                      <i className="fa fa-power-off" />
                      <span>Log out</span>
                    </DropdownItem>
                  </DropdownMenu> */}
                </UncontrolledDropdown>
                <Media className="ml-2 d-none d-lg-block">
                  <Alerts></Alerts>
                  <PushNotification></PushNotification>
                  <EnterTicketButton />
                  <Button className="btn-icon btn-2" type="button" color="secondary" onClick={this.homeScreen}>
                    <span className="btn-inner--icon">
                      <i className="fa fa-lg fa-home" />
                    </span>
                  </Button>                
                  <Button className="btn-icon btn-2" type="button" color="secondary" disabled>
                    <span className="btn-inner--icon">
                      <i className="fa fa-lg fa-user" />
                    </span>
                  </Button>    
                  <HelpCenter></HelpCenter>            
                  <Button 
                    className="layout-topbar-action"
                    type="button"
                    color=" "  
                    href="#salir"
                    onClick={logOut}
                  >
                    <span className="btn-inner--icon">
                      <i className="fa fa-lg fa-power-off" />
                    </span>
                  </Button>                
                </Media>
              </Nav>
            </Collapse>
          </Container> 
        </Navbar>  
      </>
    );
  }
}
AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: 'dark',
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(['dark', 'light']),
};

export default AdminNavbar;

const idpVersions = {
  V1: 1,
  V2: 2
};

const idpSelfReflectionPurposeTypes = {
  NEW_TO_MY_ROLE: 1,
  CURRENT_DEVELOPMENT: 2,
  FUTURE_PREPARATION: 3,
  RECOMMENDED_BY_SUPERVISOR: 4,
  NOT_PARTICIPATE: 5
};

const idpStatus = {
  NEW: 0,
  DRAFT: 1,
  PENDING_REVIEW: 2,
  IN_PROGRESS: 3,
  ADDITIONAL_INPUT_NEEDED: 4,
  CANCELLED: 5,
  COMPLETED: 6,
  PARTIAL_COMPLETED: 7,
  ACCEPTED: 8
};

export { 
  idpVersions,
  idpSelfReflectionPurposeTypes,
  idpStatus
 };
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React  from 'react';
import PropTypes from 'prop-types';
import "./InstructionsCard.scss"; 
  

function InstructionsCard ({ 
     className,
     children
  }) { 
 
    return (  
      <>  
        <div className={className === null || typeof(className) === 'undefined' ? "instrucciones" : className}>
          {children}
        </div>
      </> 
    )
}    

export default InstructionsCard; 

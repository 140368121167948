/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-const-assign */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { config } from "../../utils/config";  
import { callApi } from "../../utils/utils";  
import {Row,Col} from 'reactstrap';   
import { Link } from "react-router-dom"; 
import { Dialog } from 'primereact/dialog';
import ResponderFormulario from './ResponderFormulario.js';

const DialogFormulario = (props) => {
  const { t, i18n } = useTranslation(['formulario', 'common']); 
  const homeScreenLink = useRef(); 
  const [ClaFormulario, setClaFormulario] = useState(null);
  const [NomFormulario, setNomFormulario] = useState(null);
  const [ClaUbicacion, setClaUbicacion] = useState(null);
  const [IsVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if(props.location !== undefined){
      const params = queryString.parse(props.location.search);
      setClaFormulario(params !== undefined ? params.ClaFormulario : null);
      setClaUbicacion(params !== undefined ? params.ClaUbicacion : null);
      getFormulario(params.ClaFormulario,'English');
    }
  }, [props.location]);


  const getFormulario = (pClaFormulario,pIdioma) => {
    const urlWebService = `${config.UrlApiProject}formulario/getFormulario?ClaFormulario=${pClaFormulario}&Idioma=${pIdioma}`; 
    const paramsToService = {};
    callApi(urlWebService, 'GET', paramsToService, (response) => {
      setNomFormulario(response.data.NomFormulario);
    });
  };


  const onFormularioSent = () => {
    closeDialogFormulario();
  }

  const closeDialogFormulario = () => {
    homeScreenLink.current.click();
  };

  const renderHeader = () => {
    return (
      <div>
        <Row>
          <Col lg={6} md={6} sm={12} xs={12}> 
            <span>{NomFormulario}</span> 
          </Col>
          <Col lg={6} md={6} sm={12} xs={12} className="text-center">
             &nbsp;
          </Col> 
        </Row>
      </div>
    );
  };
   
  return (
    <> 
      <Dialog
        visible={IsVisible}         
        modal
        style={{ width: '100vw', height: '100vw' }}
        maximized={true}
        draggable={false}
        onHide={closeDialogFormulario}
        className="dialog-header-background-blue"
        header={renderHeader}
      >
        <br></br>
        <ResponderFormulario ClaFormulario={ClaFormulario} onFormularioSent={onFormularioSent} isAvailableCheckList={true}></ResponderFormulario>
        <Link 
          ref={homeScreenLink} 
          to={ 
                { pathname: `/r/AvailableCheckListByLocationScreen/${ClaUbicacion}`}
              }
        >
        </Link>
      </Dialog>
    </>
  );
};

export default DialogFormulario;

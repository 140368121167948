/* eslint-disable object-shorthand */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import CardSparkLine from '../../../components/Charts/Card-SparkLine/CardSparkLine';
import { Row, Col } from 'reactstrap';
import Highcharts from 'highcharts';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import HighchartsReact from 'highcharts-react-official';
import { config } from '../../../utils/config';
import { callApi } from '../../../utils/utils';
import queryString from 'query-string';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Dialog } from 'primereact/dialog';
import { Tag } from 'primereact/tag';
import DailyOperationDashboard from '../DailyOperationDashboard/DailyOperationDashboard';
import OperationScrapDashboard from '../OperationScrap/OperationScrapDashboard';
import ActiveDowntimesComponent from '../ActiveDowntimes/ActiveDowntimesComponent';
import PrincipalHeader from 'views/Operations/Tablets/Common/Header/PrincipalHeader';
import PageContent from 'layouts/PageContent';
import GantryWarningBanner from 'views/Operations/Tablets/Common/GantryWarningBanner/GantryWarningBanner';
 

HighchartsHeatmap(Highcharts);

const DisplayOperationsDashboards = (props) => {
  const { t, i18n } = useTranslation(['productionDashboard', 'common']);
  const [SettingList, setSettingList] = useState([]);
  const [componentID, setComponentID] = useState(null);  
  const [filters, setfilters] = useState({});
  const timerRef = useRef();
  let selected = 0;
  let arraySettingList = [];
  
  const params = queryString.parse(props.location.search);

  const  ClaZona = params !== undefined ? params.ClaZona : 0;

  useEffect(() => {    
    loadingGetOperacionDashboardSetting(ClaZona);
  }, []);

  const calculateComponent = (items) => {
    timerRef.current = setInterval(() => {
      // ya que entro aqui , ahora necesitamos mandar llamar el siguiente:
      selected += 1;

      clearInterval(timerRef.current);
      // mandamos llamar el otro
      if (items.length === selected) {
        loadingGetOperacionDashboardSetting(ClaZona);
      } else {
        setComponentID(items[selected].ComponenteId);
        setfilters({
          ClaZona: items[selected].ClaZona,
          ClaTurno: items[selected].ClaTurno,
        });
        calculateComponent(arraySettingList);
      }
    }, items[selected].TiempoenSegundos * 1000);

    if (selected === 0) {
      // desplegamos el componente del arreglo
      setComponentID(items[selected].ComponenteId);
      setfilters({
        ClaZona: items[selected].ClaZona,
        ClaTurno: items[selected].ClaTurno,
      });
    }
  };

  const onErrorLoadingGetOperacionDashboardSetting = () => {  
    // si ocurrio algun error al consultar la informacion reseteamos de nuevo al 0 para que vuelva a correr el ciclo normal... 
    selected = 0;
    if (arraySettingList.length > 0){ 
      calculateComponent(arraySettingList);
    }
  }

  const loadingGetOperacionDashboardSetting = (pClaZona) => {
    const urlWebService = `${config.UrlApiProject}Produccion/GetOperacionDashboardSetting?ClaZona=${pClaZona}`;
    const paramsService = {}; 
    callApi(urlWebService, 'GET', paramsService, (response) => {
      selected = 0;
      setSettingList(response.data.Setting);
      arraySettingList = response.data.Setting;

      if (response.data.Setting.length > 0){ 
        calculateComponent(response.data.Setting);
      }
    }, onErrorLoadingGetOperacionDashboardSetting, false, true);
  };

  if (componentID === null) {
    return (
      <>
        <Row className='mt-5'>
          <Col className='text-center'>
            <h1>No data was found for zone #: {ClaZona}</h1>
          </Col>
        </Row>
        <GantryWarningBanner></GantryWarningBanner>
      </>
);
  }

  if (componentID === 1) {
    return (
      <> 
        <PrincipalHeader IsHomeScreen={false}></PrincipalHeader>
        <PageContent title=""> 
          <Row className='mt-1'>
            <Col>
              <DailyOperationDashboard filters={filters} />
            </Col>
          </Row> 
        </PageContent> 
        <GantryWarningBanner></GantryWarningBanner>
      </>
      
    );
  }

  if (componentID === 2) {
    return (
      <> 
        <PrincipalHeader IsHomeScreen={false}></PrincipalHeader>
        <PageContent title=""> 
          <Row className='mt-1'>
            <Col>
              <OperationScrapDashboard filters={filters} />
            </Col>
          </Row>
        </PageContent> 
        <GantryWarningBanner></GantryWarningBanner>
      </> 
    );
  }

  if (componentID === 3) {
    return (
      <> 
        <PrincipalHeader IsHomeScreen={false}></PrincipalHeader>
        <PageContent title=""> 
          <Row className='mt-1'>
            <Col>
              <ActiveDowntimesComponent filters={filters} />
            </Col>
          </Row>
        </PageContent> 
        <GantryWarningBanner></GantryWarningBanner>
      </>  
    );
  }

  return <h1>Component {componentID} is not valid, logic not found, please review this component ID</h1>;
};

export default DisplayOperationsDashboards;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row, Col } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi, showSweetAlert, getSessionItem } from '../../../utils/utils';
import { Checkbox } from 'primereact/checkbox';
import FieldText from '../../../components/Controls/FieldText.jsx';
import FieldTextArea from '../../../components/Controls/FieldTextArea.jsx';
import FieldNumber from '../../../components/Controls/FieldNumber.jsx';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx';
import FieldDropdown from 'components/Controls/FieldDropdown.jsx';
import { RadioButton } from 'primereact/radiobutton';
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import moment from 'moment';
import { Sidebar } from 'primereact/sidebar';
import swal from '@sweetalert/with-react';
import { Tag } from 'primereact/tag';
import PageContent from 'layouts/PageContent.jsx';
import { Dialog } from 'primereact/dialog';
import IncentiveProgramSetUpEdit from './IncentiveProgramSetUpEdit.jsx';

const IncentiveProgramSetUpList = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['salesPerformanceSales', 'common']);
  const [errors, setErrors] = useState({});
  const [CompensationAgList, setCompensationAgList] = useState([]);
  const [OpcionesTipoAgente, setOpcionesTipoAgente] = useState([]);
  const [OpcionesTipoPago, setOpcionesTipoPago] = useState([]);
  const [OpcionesIncentivo, setOpcionesIncentivo] = useState([]);
  const [OpcionesClaAgenteAg, setOpcionesClaAgenteAg] = useState([]);
  const [EsVerCompensacionAg, setEsVerCompensacionAg] = useState(false); 
  const [ClaUsuario, setClaUsuario] = useState(null); 
  const [ClaAgenteVentas, setClaAgenteVentas] = useState(null);
  const [AddNew, setAddNew] = useState(null);

  useEffect(() => {
    GetCompensationAg();
    GetTipoAgenteCmb();
    GetAgenteCmb();
  }, [props.filtersData]);

  const GetCompensationAg = () => {
    const getCompensationAg = `${config.UrlApiProject}SalesPerformanceSales/GetCompensationAg`;
    const paramsToService = {
      ShowOnlyInactives : props.filtersData.ShowOnlyInactives
    };
    callApi(getCompensationAg, 'POST', paramsToService, (response) => {
      setCompensationAgList(response.data.CompensationAg);
    });
  };

  const GetTipoPagoCmb = () => {
    const getTipoPagoCmb = `${config.UrlApiProject}SalesPerformanceSales/GetTipoPagoCmb`;
    const paramsToService = {};
    callApi(getTipoPagoCmb, 'GET', paramsToService, (response) => {
      setOpcionesTipoPago(response.data.TipoPago);
    });
  };

  const GetTipoAgenteCmb = () => {
    const getTipoAgenteCmb = `${config.UrlApiProject}SalesPerformanceSales/GetTipoAgenteCmb`;
    const paramsToService = {};
    callApi(getTipoAgenteCmb, 'GET', paramsToService, (response) => {
      setOpcionesTipoAgente(response.data.TipoAgente);
    });
  };

  const GetIncentivoCmb = () => {
    const getIncentivoCmb = `${config.UrlApiProject}SalesPerformanceSales/GetIncentivoCmb`;
    const paramsToService = {};
    callApi(getIncentivoCmb, 'GET', paramsToService, (response) => {
      setOpcionesIncentivo(response.data.Incentivo);
    });
  };

  const GetAgenteCmb = () => {
    const getAgenteCmb = `${config.UrlApiProject}SalesPerformanceSales/GetAgenteCmb`;
    const paramsToService = {};
    callApi(getAgenteCmb, 'GET', paramsToService, (response) => {
      setOpcionesClaAgenteAg(response.data.Agente);
    });
  };

  const ClaAgenteAgEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldDropdown
          name="ClaAgente"
          value={props.rowData.ClaAgente}
          options={OpcionesClaAgenteAg}
          optionValue="ClaAgente"
          optionLabel="NomAgente"
          onChange={(e) => onClaAgenteAgChange(props, e)}
          // errors={errors[`FaseProyectoEntregables[${props.rowData.RowIndex}]`]}
        />
      </div>
    );
  };

  const onClaAgenteAgChange = (props, e) => {
    const CompensationAgListCopy = [...CompensationAgList];
    const ClaAgente = OpcionesClaAgenteAg.filter((item) => item.ClaAgente === e.value)[0];
    if (ClaAgente !== undefined) {
      CompensationAgListCopy[props.rowData.RowIndex].ClaAgente = ClaAgente.ClaAgente;
      CompensationAgListCopy[props.rowData.RowIndex].SalesPerson = ClaAgente.NomAgente;
    }
    setCompensationAgList(CompensationAgListCopy);
  };

  const ClaTipoAgenteEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldDropdown
          name="ClaTipoAgente"
          value={props.rowData.ClaTipoAgente}
          options={OpcionesTipoAgente}
          optionValue="ClaTipoAgente"
          optionLabel="NomTipoAgente"
          onChange={(e) => onClaTipoAgenteChange(props, e)}
          // errors={errors[`FaseProyectoEntregables[${props.rowData.RowIndex}]`]}
        />
      </div>
    );
  };

  const onClaTipoAgenteChange = (props, e) => {
    const CompensationAgListCopy = [...CompensationAgList];
    const ClaTipoAgente = OpcionesTipoAgente.filter((item) => item.ClaTipoAgente === e.value)[0];
    if (ClaTipoAgente !== undefined) {
      CompensationAgListCopy[props.rowData.RowIndex].ClaTipoAgente = ClaTipoAgente.ClaTipoAgente;
      CompensationAgListCopy[props.rowData.RowIndex].NomTipoAgente = ClaTipoAgente.NomTipoAgente;
    }
    setCompensationAgList(CompensationAgListCopy);
  };

  const PctIncentivoEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldNumber
          name="PctIncentivo"
          value={props.rowData.PctIncentivo}
          onChange={(e) => PctIncentivoChange(props, e.target.value)}
          // errors={errors[`KPIManagementList[${props.rowIndex}]`]}
          min={-99999999.99}
          max={99999999.99}
        />
      </div>
    );
  };
  const PctIncentivoChange = (props, value) => {
    const UpdCompensationAgListCopy = [...props.value];
    UpdCompensationAgListCopy[props.rowIndex][props.field] = value;

    setCompensationAgList(UpdCompensationAgListCopy);
  };

  const FechaInicioEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldCalendar
          name="FechaInicio"
          value={
            props.rowData.FechaInicio !== null ? new Date(props.rowData.FechaInicio) : null
          }
          onChange={(e) => onChangeFechaInicio(props, e)}
        />
      </div>
    );
  };

  const onChangeFechaInicio = (props, e) => {
    const CompensationAgListCopy = [...CompensationAgList];
    CompensationAgListCopy[props.rowData.RowIndex].FechaInicio = e.target.value;
    setCompensationAgList(CompensationAgListCopy);
  };

  const FechaBajaEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldCalendar
          name="FechaBaja"
          value={
            props.rowData.FechaBaja !== null ? new Date(props.rowData.FechaBaja) : null
          }
          onChange={(e) => onChangeFechaBaja(props, e)}
        />
      </div>
    );
  };

  const onChangeFechaBaja = (props, e) => {
    const CompensationAgListCopy = [...CompensationAgList];
    CompensationAgListCopy[props.rowData.RowIndex].FechaBaja = e.target.value;
    setCompensationAgList(CompensationAgListCopy);
  };

  const ComentarioEditor = (props) => {
    return (
      <div className='p-fluid'> 
        <FieldTextArea 
          name="Comentario"
          rows={5}
          placeholder=''
          maxLength={250}
          value={props.rowData.Comentario}
          onChange={(e) => ComentarioChange(props, e.target.value)}
          // errors={errors}
        /> 
      </div>
    );
  };

  const ComentarioChange = (props, value) => {  
    const UpdCompensationAgListCopy = [...props.value];
    UpdCompensationAgListCopy[props.rowIndex][props.field] = value;

    setCompensationAgList(UpdCompensationAgListCopy);
 }

 const ComentarioBajaEditor = (props) => {
    return (
      <div className='p-fluid'> 
        <FieldTextArea 
          name="ComentarioBaja"
          rows={5}
          placeholder=''
          maxLength={250}
          value={props.rowData.ComentarioBaja}
          onChange={(e) => ComentarioBajaChange(props, e.target.value)}
          // errors={errors}
        /> 
      </div>
    );
  };

  const ComentarioBajaChange = (props, value) => {  
    const UpdCompensationAgListCopy = [...props.value];
    UpdCompensationAgListCopy[props.rowIndex][props.field] = value;

    setCompensationAgList(UpdCompensationAgListCopy);
 }
  const onSaveClick = async () => {
    const ListConfigurado = CompensationAgList.filter((opc) => opc.Configurado === 1);

    const paramsServiceOwners = {
      CompensationAgStpListDTO: ListConfigurado,
    };

    const urlWebServiceSaveCompensationAgStp = `${config.UrlApiProject}SalesPerformanceSales/SaveCompensationAgStp`;

    callApi(urlWebServiceSaveCompensationAgStp, 'POST', paramsServiceOwners, (response) => {
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      obtenerCompensationAgStp();
    });
  };

  const renderNumericValue = (value) => {
    return (
      <>
        {value === null
          ? ''
          : value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
      </>
    );
  };
  const FechaInicioTemplate = (rowData) => {
    return (
      <>{rowData.FechaInicio !== null ? moment(rowData.FechaInicio).format('MM/DD/YYYY') : null}</>
    );
  };

  const FechaBajaTemplate = (rowData) => {
    return (
      <>{rowData.FechaBaja !== null ? moment(rowData.FechaBaja).format('MM/DD/YYYY') : null}</>
    );
  };

  const EditarTemplate = (row) => {
    return (
      <>
        <FieldButton
          icon="pi pi-pencil"
          onClick={() => onClickEditarSurvey(row)}
          className="p-button-rounded p-button-primary no-border"
          tooltip={t('salesPerformanceSales:EditRow')}
        />
      </>
    );
  };

  const onClickEditarSurvey = (row) => {
    setEsVerCompensacionAg(true); 
    setClaAgenteVentas(row.ClaAgenteVentas === undefined ? null : row.ClaAgenteVentas);
    setClaUsuario(row.ClaUsuario === undefined ? null : row.ClaUsuario);
  }
  const showActiveRows = (rows) => {
    const activeRows = [];
    if (rows !== undefined) {
      if (rows.length > 0) {
        rows.forEach((item, index) => {
          if (!item.BajaLogica || item.BajaLogica === 0 || item.BajaLogica === undefined) {
            item.BajaLogica = 0;
            item.RowIndex = index;
            item.RowIndexCount = index + 1;
            // item.Configurado = 0;
            activeRows.push({ ...item });
          }
        });
      } else {
        return [];
      }
    }

    return activeRows;
  };

  const dialogStyle = {
    width: '80vw',
  };

  const closeModalCompesacionAg = () => {
    setEsVerCompensacionAg(false);
    GetCompensationAg();
  };

  const addRow = async (newRow) => {    
    setCompensationAgList([...CompensationAgList, newRow]);    
  }
  return (
    <>
      <Row>
        <Col>
          <FieldDataTable
            value={showActiveRows(CompensationAgList)}
            header={null}
            className="editable-cells-table"
            editMode="cell"
          >
            <Column
              body={EditarTemplate}
              className="text-center"
              header={t('salesPerformanceSales:Edit')}
              headerStyle={{ width: '45px',textAlign: 'center' }}
              style={{ textAlign: 'center' }}
            >
            </Column>
            <Column
              field="SalesPerson"
              header={t('salesPerformanceSales:SalesPerson')}
              columnKey="SalesPerson"
              headerStyle={{ width: '250px' }}
              // editor={(props) => ClaAgenteAgEditor(props)}
            >
            </Column>
            <Column
              field="NomTipoAgente"
              columnKey="NomTipoAgente"
              header={t('salesPerformanceSales:SalesType')}
              headerStyle={{ width: '105px' }}
              // editor={(props) => ClaTipoAgenteEditor(props)}
            >
            </Column>
            <Column
              className="text-center"
              body={(row) => renderNumericValue(row.PctIncentivo)}
              field="PctIncentivo"
              columnKey="PctIncentivo"
              header={t('salesPerformanceSales:IncentivePct')}
              headerStyle={{ width: '85px' }}
              // editor={(props) => PctIncentivoEditor(props)}
            >
            </Column>
            <Column
              className="text-center"
              body={(row) => renderNumericValue(row.AnnualSPITargetIncentive)}
              field="AnnualSPITargetIncentive"
              columnKey="AnnualSPITargetIncentive"
              header={t('salesPerformanceSales:AnnualSPITargetIncentive')}
              headerStyle={{ width: '120px' }}
              // editor={(props) => PctIncentivoEditor(props)}
            >
            </Column>
            
            <Column
              field="FechaInicio"
              header={t('salesPerformanceSales:StartingDate')}
              headerStyle={{ width: '85px' }}
              body={FechaInicioTemplate}
              // editor={(props) => FechaInicioEditor(props)}
            >
            </Column>
            <Column              
              field="Comentario"
              columnKey="Comentario"
              header={t('salesPerformanceSales:Observation')}
              headerStyle={{ width: '155px' }}
              // editor={(props) => ComentarioEditor(props)}
            >
            </Column>
            <Column
              field="FechaBaja"
              header={t('salesPerformanceSales:StopDate')}
              headerStyle={{ width: '85px' }}
              body={FechaBajaTemplate}
              // editor={(props) => FechaBajaEditor(props)}
            >
            </Column>
            <Column
              field="ComentarioBaja"
              columnKey="ComentarioBaja"
              header={t('salesPerformanceSales:StopReason')}
              headerStyle={{ width: '155px' }}
              // editor={(props) => ComentarioBajaEditor(props)}
            >
            </Column>
          </FieldDataTable>
        </Col>
      </Row>
      {
        EsVerCompensacionAg ?
        (  
          <IncentiveProgramSetUpEdit          
            propsClaAgenteVentas={ClaAgenteVentas}            
            propsClaUsuario={ClaUsuario}            
            onCloseClick={closeModalCompesacionAg}
            showCloseButton={true}
            EsVerCompensacionAg={EsVerCompensacionAg}
            EsNew={AddNew}
          >
          </IncentiveProgramSetUpEdit>
        )
        :
        (<></>)
      }      
    </>
  );
};
export default IncentiveProgramSetUpList;

/* eslint-disable react/forbid-prop-types */

import React, {useState, useEffect, useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardHeader, CardBody, CardFooter, Label } from 'reactstrap';
import { callApi, showSweetAlert, formatDate, formatPhoneNumberNA } from "utils/utils";
import { config } from 'utils/config';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import FieldText from 'components/Controls/FieldText';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import defaultAvatar from 'assets/img/default-avatar.png';
import FieldUpload from 'components/Controls/FieldUpload/FieldUpload';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldPhone from 'components/Controls/FieldPhone/FieldPhone';
import { object, string, number, date, InferType, array } from 'yup';
import YupValidator from 'utils/YupValidator';

const EditBusinessCard = (props) => {
  const { t } = useTranslation(['qr', 'common']);

  const [showEditSidebar, setShowEditSidebar] = useState(false);
  const [fullName, setFullName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [templateId, setTemplateId] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [emails, setEmails] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [address, setAddress] = useState('');
  const [socialLinks, setSocialLinks] = useState([]);
  const [userImage, setUserImage] = useState({});
  const [selectedClaUsuarioBC, setSelectedClaUsuarioBC] = useState(props.ClaUsuarioBC !== undefined ? props.ClaUsuarioBC : null);
  const [errors, setErrors] = useState([]);
  const [userImageFile, setUserImageFile] = useState([]);
  const [showUploadImageDialog, setShowUploadImageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedPhoneType, setSelectedPhoneType] = useState(null);
  const [selectedEmailType, setSelectedEmailType] = useState(null);
  const [selectedSocialType, setSelectedSocialType] = useState(null);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState('');
  const [selectedCountryCode, setSelectedCountryCode] = useState('')
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedEmail, setSelectedEmail] = useState('');
  const [selectedSocialLink, setSelectedSocialLink] = useState('');
  const [phoneNumberTypes, setPhoneNumberTypes] = useState([]);
  const [emailTypes, setEmailTypes] = useState([]);
  const [socialTypes, setSocialTypes] = useState([]);
  const [templateDetails, setTemplateDetails] = useState({});

  useEffect(() => {
    getEmailLabelTypes();
    getPhoneLabelTypes();
    getSocialLabelTypes();
    getBusinessCardTemplates();
    getVCardInfo();
  }, [])

  useEffect(() => {
    if (props.setUserInfo !== undefined && props.userInfo !== undefined) {
      const newUserInfo = props.userInfo;
      newUserInfo.FullName = fullName;
      newUserInfo.JobTitle = jobTitle;
      newUserInfo.FullAddress = address;
      newUserInfo.TemplateId = templateId;
      newUserInfo.contactInfo = {
        Phone: phoneNumbers,
        Email: emails,
        Social: socialLinks
      };
      newUserInfo.UserImageFile = userImage.file;
      newUserInfo.UserImageFileName = userImage.fileName;
      newUserInfo.UserImageFileMIMEType = userImage.mimeType;
      newUserInfo.BackgroundColor = templateDetails.BackgroundColor;
      newUserInfo.UserInfoColor = templateDetails.UserInfoColor;
      newUserInfo.SecondaryColor = templateDetails.SecondaryColor;
      newUserInfo.ButtonColor = templateDetails.ButtonColor;
      newUserInfo.Website = templateDetails.Website;
      newUserInfo.CompanyName = templateDetails.CompanyName;
      newUserInfo.LogoFile = templateDetails.LogoFile;
      newUserInfo.LogoFileName = templateDetails.LogoFileName;
      newUserInfo.LogoFileMIMEType = templateDetails.LogoFileMIMEType
    
      props.setUserInfo({...newUserInfo});
    }
  }, [fullName, address, jobTitle, templateId, phoneNumbers, emails, socialLinks, userImage, templateDetails])

  const getBusinessCardTemplates = () => { 
    const url = `${config.UrlApiProject}QRCode/GetBusinessCardTemplates`;  
    const paramsService= {};  
    callApi(url, 'GET', paramsService, (response) => {
      setTemplates(response.data);
    });
  }

  const getBusinessCardTemplateDetails = (mTemplateId) => { 
    const url = `${config.UrlApiProject}QRCode/GetBusinessCardTemplateDetails?TemplateId=${mTemplateId}`;  
    const paramsService= {};  
    callApi(url, 'GET', paramsService, (response) => {
      setTemplateDetails({
        TemplateName: response.data.TemplateName,
        CompanyName: response.data.CompanyName,
        Website: response.data.Website,
        SecondaryColor: response.data.SecondaryColor,
        UserInfoColor: response.data.UserInfoColor,
        BackgroundColor: response.data.BackgroundColor,
        ButtonColor: response.data.ButtonColor,
        LogoFile: response.data.LogoFile,
        LogoFileName: response.data.LogoFileName,
        LogoFileMIMEType: response.data.LogoFileMIMEType
      });
    });
  }

  const getSocialLabelTypes = () => { 
    const url = `${config.UrlApiProject}QRCode/GetBusinessCardLabels?ClaTipoDBCContact=3`;  
    const paramsService= {};  
    callApi(url, 'GET', paramsService, (response) => {
      setSocialTypes(response.data);
    });
  }

  const getPhoneLabelTypes = () => { 
    const url = `${config.UrlApiProject}QRCode/GetBusinessCardLabels?ClaTipoDBCContact=1`;  
    const paramsService= {};  
    callApi(url, 'GET', paramsService, (response) => {
      setPhoneNumberTypes(response.data);
    });
  }

  const getEmailLabelTypes = () => { 
    const url = `${config.UrlApiProject}QRCode/GetBusinessCardLabels?ClaTipoDBCContact=2`;  
    const paramsService= {};  
    callApi(url, 'GET', paramsService, (response) => {
      setEmailTypes(response.data);
    });
  }

  const getVCardInfo = () => {
    const url = `${config.UrlApiProject}QRCode/GetVCardInfo?ClaUsuario=${selectedClaUsuarioBC}`;  
    const paramsService= {};  
    callApi(url, 'GET', paramsService, (response) => {
      setFullName(response.data.info.FullName);
      setJobTitle(response.data.info.JobTitle);
      setAddress(response.data.info.FullAddress);
      setTemplateId(response.data.info.TemplateId);
      setEmails(response.data.contactInfo.Email);
      setPhoneNumbers(response.data.contactInfo.Phone);
      setSocialLinks(response.data.contactInfo.Social);
      setUserImage({
        file: response.data.info.UserImageFile,
        fileName: response.data.info.UserImageFileName,
        mimeType: response.data.info.UserImageFileMIMEType
      });

      if (props.setUserInfo !== undefined) {
        props.setUserInfo({
          FullName: response.data.info.FullName,
          JobTitle: response.data.info.JobTitle,
          CompanyName: response.data.info.CompanyName,
          FullAddress: response.data.info.FullAddress,
          TemplateId: response.data.info.TemplateId,
          contactInfo: response.data.contactInfo,
          UserImageFile: response.data.info.UserImageFile,
          UserImageFileName: response.data.info.UserImageFileName,
          UserImageFileMIMEType: response.data.info.UserImageFileMIMEType,
          LogoFile: response.data.info.LogoFile,
          LogoFileName: response.data.info.LogoFileName,
          LogoFileMIMEType: response.data.info.LogoFileMIMEType,
          BackgroundColor: response.data.info.BackgroundColor,
          UserInfoColor: response.data.info.UserInfoColor,
          SecondaryColor: response.data.info.SecondaryColor,
          ButtonColor: response.data.info.ButtonColor
        })

        getBusinessCardTemplateDetails(response.data.info.TemplateId);
      }

    });
  }

  const onFullNameChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.fullName;
    setErrors(newErrors);
    setFullName(e.target.value);

    if (props.userInfo !== undefined) {
      const newUserInfo = props.userInfo;
      newUserInfo.FullName = e.target.value;
      props.setUserInfo({...newUserInfo});
    }
  }

  const onJobTitleChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.jobTitle;
    setErrors(newErrors);
    setJobTitle(e.target.value);
  }

  const onAddressChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.address;
    setErrors(newErrors);
    setAddress(e.target.value);
  }

  const onTemplateChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.templateId;
    setErrors(newErrors);
    setTemplateId(e.value);
    getBusinessCardTemplateDetails(e.value);
  }

  const onUserImageFileChange = (files) => {
    const newErrors = {...errors};
    delete newErrors.userImageFile;
    setErrors(newErrors);
    setUserImageFile(files)
  }

  const onHideUploadImageDialog = () => {
    setShowUploadImageDialog(false);
    setUserImageFile([]);
  }

  const onSubmitUserImageClick = async () => {

    const validationScheme = object({
      userImageFile: array().min(1, t('common:RequiredField')).nullable()
    });
    
    const value = {
      userImageFile
    }

    const validator = new YupValidator(validationScheme);
    const isValid = await validator.validate(value);

    if (!isValid) {
      setErrors(validator.errors);
      return;
    }

    const fileExtension = userImageFile[0].FileExtension.toLowerCase();

    setUserImage({
      file: userImageFile[0].FileData,
      fileName: userImageFile[0].FileName,
      mimeType: fileExtension === '.gif' ? 'image/gif'
                  : fileExtension === '.jpg' || fileExtension === '.jpeg' ? 'image/jpeg'
                  : fileExtension === '.png' ? 'image/png'
                  : fileExtension === '.svg' ? 'image/svg+xml'
                  : 'application/unknown'
      });

      const newErrors = {...errors};
      delete newErrors.userImage;
      setErrors(newErrors);

      onHideUploadImageDialog();
  }

  const renderUserImage = () => {
    if (userImage.file !== null) {
      return (
        <img 
          src={`data:${userImage.mimeType};base64,${userImage.file}`}
          alt=''
          style={{height: '150px', width: '150px', borderRadius: '50%'}}
        />
      )
    }

    return (
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} className='p-d-flex p-jc-center'>
          <img src={defaultAvatar} alt='Employee' style={{height: '150px', width: '150px', borderRadius: '50%'}} />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} className='p-d-flex p-jc-center'>
          <Label>{t('qr:PleaseUploadPhoto')}</Label>
        </Col>
      </Row>
    )
  }

  const phoneNumberHeaderTemplate = (
    <Row>
      <Col xs={6} sm={6} md={6} lg={6} className='p-d-flex p-ai-center p-jc-start'>
        {t('qr:PhoneNumbers')}
      </Col>
      <Col xs={6} sm={6} md={6} lg={6} className='p-d-flex p-jc-end'>
        <FieldButton className='p-button-sm' icon='pi pi-plus' onClick={() => onAddPhoneNumberClick()} />
      </Col>
    </Row>
  );

  const emailHeaderTemplate = (
    <Row>
      <Col xs={6} sm={6} md={6} lg={6} className='p-d-flex p-ai-center p-jc-start'>
        {t('qr:EmailAddresses')}
      </Col>
      <Col xs={6} sm={6} md={6} lg={6} className='p-d-flex p-jc-end'>
        <FieldButton className='p-button-sm' icon='pi pi-plus' onClick={() => onAddEmailClick()} />
      </Col>
    </Row>
  );

  const socialHeaderTemplate = (
    <Row>
      <Col xs={6} sm={6} md={6} lg={6} className='p-d-flex p-ai-center p-jc-start'>
        {t('qr:SocialLinks')}
      </Col>
      <Col xs={6} sm={6} md={6} lg={6} className='p-d-flex p-jc-end'>
        <FieldButton className='p-button-sm' icon='pi pi-plus' onClick={() => onAddSocialLinkClick()} />
      </Col>
    </Row>
  );

  const onSaveInfoClick = async () => {
    const validationScheme = object({
      fullName: string().nullable(),
      jobTitle: string().nullable(),
      templateId: number().required(t('common:RequiredField')).nullable(),
      address: string().nullable(),
      userImage: object().shape({
        file: string().required().nullable(),
        fileName: string().required().nullable()
      })
    });
    
    const value = {
      fullName,
      jobTitle,
      templateId,
      address,
      userImage
    }

    const validator = new YupValidator(validationScheme);
    const isValid = await validator.validate(value);

    if (!isValid) {
      setErrors(validator.errors);
      return;
    }

    const url = `${config.UrlApiProject}QRCode/UpdateBusinessCardInfo`;  
    const paramsService= {
      FullName: fullName,
      JobTitle: jobTitle,
      TemplateId: templateId,
      FullAddress: address,
      ClaUsuarioBC: selectedClaUsuarioBC,
      UserImageFile: userImage.file,
      UserImageFileName: userImage.fileName
    };  
    callApi(url, 'POST', paramsService, (response) => {
      const url2 = `${config.UrlApiProject}QRCode/GetVCardInfo?ClaUsuario=${selectedClaUsuarioBC}`; 
      const params2 = {}
      showSweetAlert('Success', t('common:Success'), 'success');
      callApi(url2, 'GET', params2, (response) => {
        setFullName(response.data.info.FullName);
        setJobTitle(response.data.info.JobTitle);
        setAddress(response.data.info.FullAddress);
        setTemplateId(response.data.info.TemplateId);
      });
    });
  }

  const clearSelectedValues = () => {
    setSelectedPhoneType(null);
    setSelectedCountry(null);
    setSelectedCountryCode(null);
    setSelectedPhoneNumber('');
    setSelectedEmailType(null);
    setSelectedEmail('');
    setSelectedSocialType(null);
    setSelectedSocialLink('');
    setSelectedRow(null);

    const newErrors = errors;
    delete newErrors.selectedPhoneNumber;
    delete newErrors.selectedPhoneType;
    delete newErrors.selectedEmail;
    delete newErrors.selectedEmailType;
    delete newErrors.selectedSocialLink;
    delete newErrors.selectedSocialType;
    delete newErrors.selectedCountry;
    delete newErrors.selectedCountryCode;
    setErrors(newErrors);
  }

  const onAddPhoneNumberClick = () => {
    if (selectedRow !== null) {
      showSweetAlert('Finish Editing Current Row', t('qr:SaveOrRemoveRow'), 'warning');
      return;
    }

    clearSelectedValues();

    const newPhoneNumber = {
      ContactId: -1,
      ClaTipoDBCContact: 1,
      ClaTipoDBCContactLabel: null,
      Valor: ''
    }

    setSelectedRow(newPhoneNumber);
    const newPhoneNumbers = phoneNumbers;
    newPhoneNumbers.push(newPhoneNumber);
    setPhoneNumbers([...phoneNumbers]);
  }

  const onAddEmailClick = () => {
    if (selectedRow !== null) {
      showSweetAlert('Finish Editing Current Row', t('qr:SaveOrRemoveRow'), 'warning');
      return;
    }

    clearSelectedValues();

    const newEmail = {
      ContactId: -1,
      ClaTipoDBCContact: 2,
      ClaTipoDBCContactLabel: null,
      Valor: ''
    }

    setSelectedRow(newEmail);
    const newEmails = emails;
    newEmails.push(newEmail);
    setEmails([...newEmails]);
  }

  const onAddSocialLinkClick = () => {
    if (selectedRow !== null) {
      showSweetAlert('Finish Editing Current Row', t('qr:SaveOrRemoveRow'), 'warning');
      return;
    }

    clearSelectedValues();

    const newSocialLink = {
      ContactId: -1,
      ClaTipoDBCContact: 3,
      ClaTipoDBCContactLabel: null,
      Valor: ''
    }

    setSelectedRow(newSocialLink);
    const newSocialLinks = socialLinks;
    newSocialLinks.push(newSocialLink);
    setSocialLinks([...newSocialLinks]);
  }

  // Removes an unsaved empty row
  const removeEmptyContactRow = () => {
    if (selectedRow !== null && selectedRow.ContactId <= 0) {
      const contactArray = selectedRow.ClaTipoDBCContact === 1 ? phoneNumbers
                          : selectedRow.ClaTipoDBCContact === 2 ? emails
                          : selectedRow.ClaTipoDBCContact === 3 ? socialLinks
                          : [];

      for (let i = 0; i < contactArray.length; i++) {
        if (contactArray[i].ContactId === -1) {
          contactArray.splice(i, 1);
          break;
        }
      }

      if (selectedRow.ClaTipoDBCContact === 1) {
        setPhoneNumbers([...contactArray]);
      }
      else if (selectedRow.ClaTipoDBCContact === 2) {
        setEmails([...contactArray]);
      }
      else if (selectedRow.ClaTipoDBCContact === 3) {
        setSocialLinks([...contactArray]);
      }
    }
  }

  const onSelectedRowChange = (e) => {
    // Remove an unsaved row and clear values
    removeEmptyContactRow();
    clearSelectedValues();

    if (e.value !== undefined && e.value !== null) {
      setSelectedRow(e.value);

      if (e.value.ClaTipoDBCContact === 1) {
        setSelectedPhoneType(e.value.ClaTipoDBCContactLabel);
        setSelectedPhoneNumber(e.value.Valor);
      }
      else if (e.value.ClaTipoDBCContact === 2) {
        setSelectedEmailType(e.value.ClaTipoDBCContactLabel);
        setSelectedEmail(e.value.Valor);
      }
      else if(e.value.ClaTipoDBCContact === 3) {
        setSelectedSocialType(e.value.ClaTipoDBCContactLabel);
        setSelectedSocialLink(e.value.Valor);
      }
    }
  }

  const onSelectedPhoneTypeChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.selectedPhoneType;
    setErrors(newErrors);
    setSelectedPhoneType(e.value);
  }

  const onSelectedPhoneNumberChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.selectedPhoneNumber;
    setErrors(newErrors);
    setSelectedPhoneNumber(e);
  }

  const onSavePhoneNumberClick = async () => {
    // Update the value in the table
    const validationScheme = object({
      selectedPhoneType: number().required(t('common:RequiredField')).nullable(),
      selectedPhoneNumber: string().matches(String.raw`^[\+][0-9]{1,3}[0-9]{3}[0-9]{3}[0-9]{4}$`,
                                            t('common:10DigitPhoneError')).nullable()
    });

    const value = {
      selectedPhoneType,
      selectedPhoneNumber
    }

    const validator = new YupValidator(validationScheme);
    const isValid = await validator.validate(value);

    if (!isValid) {
      setErrors(validator.errors);
      return;
    }

    if (selectedRow.ContactId !== -1) {
      const url = `${config.UrlApiProject}QRCode/UpdateBusinessCardContactInfoRow`;  
      const paramsService= {
        ContactId: selectedRow.ContactId,
        ClaTipoDBCContactLabel: selectedPhoneType,
        Valor: selectedPhoneNumber
      };  
      callApi(url, 'POST', paramsService, (response) => {
        const url2 = `${config.UrlApiProject}QRCode/GetVCardInfo?ClaUsuario=${selectedClaUsuarioBC}`;  
        const paramsService2= {};  
        showSweetAlert('Success', t('common:Success'), 'success');
        callApi(url2, 'GET', paramsService2, (response) => {
          setPhoneNumbers(response.data.contactInfo.Phone);
        });
      });
    }
    else {
      const url = `${config.UrlApiProject}QRCode/InsertBusinessCardContactInfoRow`;  
      const paramsService= {
        ClaUsuarioBc: selectedClaUsuarioBC,
        ClaTipoDBCContactLabel: selectedPhoneType,
        Valor: selectedPhoneNumber
      };  
      callApi(url, 'POST', paramsService, (response) => {
        const url2 = `${config.UrlApiProject}QRCode/GetVCardInfo?ClaUsuario=${selectedClaUsuarioBC}`;  
        const paramsService2= {};  
        showSweetAlert('Success', t('common:Success'), 'success');
        callApi(url2, 'GET', paramsService2, (response) => {
          setPhoneNumbers(response.data.contactInfo.Phone);
        });
      });
    }

    setSelectedRow(null);
    clearSelectedValues();
  }

  const onDeleteContactRowClick = (row) => {
    if (row.ContactId === -1) {
      removeEmptyContactRow();
      clearSelectedValues();
    }
    else {
      const url = `${config.UrlApiProject}QRCode/DeleteBusinessCardContactInfoRow`;  
      const paramsService= {
        ContactId: row.ContactId
      };  
      callApi(url, 'POST', paramsService, (response) => {
        const url2 = `${config.UrlApiProject}QRCode/GetVCardInfo?ClaUsuario=${selectedClaUsuarioBC}`;  
        const paramsService2= {}; 
        showSweetAlert('Success', t('common:Success'), 'success');
        callApi(url2, 'GET', paramsService2, (response) => {
          if (row.ClaTipoDBCContact === 1) {
            setPhoneNumbers(response.data.contactInfo.Phone);
          }
          else if (row.ClaTipoDBCContact === 2) {
            setEmails(response.data.contactInfo.Email);
          }
          else if (row.ClaTipoDBCContact === 3) {
            setSocialLinks(response.data.contactInfo.Social);
          }
          
          clearSelectedValues();
        });
      });
    }
  }

  const onCountryChange = (country, countryCode) => {
    setSelectedCountry(country);
    setSelectedCountryCode(countryCode);
  }

  const onSelectedEmailTypeChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.selectedEmailType;
    setErrors(newErrors);
    setSelectedEmailType(e.value);
  }

  const onSelectedEmailChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.selectedEmail;
    setErrors(newErrors);
    setSelectedEmail(e.target.value.trim());
  }

  const onSaveEmailClick = async (row) => {
    const validationScheme = object({
      selectedEmailType: number().required(t('common:RequiredField')).nullable(),
      selectedEmail: string().matches(String.raw`(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))`,
                                            t('common:InvalidEmailError')).nullable()
    });
    
    const value = {
      selectedEmailType,
      selectedEmail
    }

    const validator = new YupValidator(validationScheme);
    const isValid = await validator.validate(value);

    if (!isValid) {
      setErrors(validator.errors);
      return;
    }

    if (selectedRow.ContactId !== -1) {
      const url = `${config.UrlApiProject}QRCode/UpdateBusinessCardContactInfoRow`;  
      const paramsService= {
        ContactId: selectedRow.ContactId,
        ClaTipoDBCContactLabel: selectedEmailType,
        Valor: selectedEmail
      };  
      callApi(url, 'POST', paramsService, (response) => {
        const url2 = `${config.UrlApiProject}QRCode/GetVCardInfo?ClaUsuario=${selectedClaUsuarioBC}`;  
        const paramsService2= {};
        showSweetAlert('Success', t('common:Success'), 'success');
        callApi(url2, 'GET', paramsService2, (response) => {
          setEmails(response.data.contactInfo.Email);
        });
      });
    }
    else {
      const url = `${config.UrlApiProject}QRCode/InsertBusinessCardContactInfoRow`;  
      const paramsService= {
        ClaUsuarioBc: selectedClaUsuarioBC,
        ClaTipoDBCContactLabel: selectedEmailType,
        Valor: selectedEmail
      };  
      callApi(url, 'POST', paramsService, (response) => {
        const url2 = `${config.UrlApiProject}QRCode/GetVCardInfo?ClaUsuario=${selectedClaUsuarioBC}`;  
        const paramsService2= {};
        showSweetAlert('Success', t('common:Success'), 'success');
        callApi(url2, 'GET', paramsService2, (response) => {
          setEmails(response.data.contactInfo.Email);
        });
      });
    }

    setSelectedRow(null);
    clearSelectedValues();
  }

  const onSelectedSocialTypeChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.selectedSocialType;
    setErrors(newErrors);
    setSelectedSocialType(e.value);
  }

  const onSelectedSocialLinkChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.selectedSocialLink;
    setErrors(newErrors);
    setSelectedSocialLink(e.target.value.trim());
  }

  const onSaveSocialLinkClick = async () => {
    const validationScheme = object({
      selectedSocialType: number().required(t('common:RequiredField')).nullable(),
      selectedSocialLink: string().matches(String.raw`^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)`,
                                            t('common:InvalidHttpUrl')).nullable()
    });
    
    const value = {
      selectedSocialType,
      selectedSocialLink: selectedSocialLink.trim()
    }

    const validator = new YupValidator(validationScheme);
    const isValid = await validator.validate(value);

    if (!isValid) {
      setErrors(validator.errors);
      return;
    }

    if (selectedRow.ContactId !== -1) {
      const url = `${config.UrlApiProject}QRCode/UpdateBusinessCardContactInfoRow`;  
      const paramsService= {
        ContactId: selectedRow.ContactId,
        ClaTipoDBCContactLabel: selectedSocialType,
        Valor: selectedSocialLink.trim()
      };  
      callApi(url, 'POST', paramsService, (response) => {
        const url2 = `${config.UrlApiProject}QRCode/GetVCardInfo?ClaUsuario=${selectedClaUsuarioBC}`;  
        const paramsService2= {};  
        showSweetAlert('Success', t('common:Success'), 'success');
        callApi(url2, 'GET', paramsService2, (response) => {
          setSocialLinks(response.data.contactInfo.Social);
        });
      });
    }
    else {
      const url = `${config.UrlApiProject}QRCode/InsertBusinessCardContactInfoRow`;  
      const paramsService= {
        ClaUsuarioBc: selectedClaUsuarioBC,
        ClaTipoDBCContactLabel: selectedSocialType,
        Valor: selectedSocialLink
      };  
      callApi(url, 'POST', paramsService, (response) => {
        const url2 = `${config.UrlApiProject}QRCode/GetVCardInfo?ClaUsuario=${selectedClaUsuarioBC}`;  
        const paramsService2= {};  
        showSweetAlert('Success', t('common:Success'), 'success');
        callApi(url2, 'GET', paramsService2, (response) => {
          setSocialLinks(response.data.contactInfo.Social);
        });
      });
    }

    setSelectedRow(null);
    clearSelectedValues();
  }

  const phoneNumberTypeTemplate = (row) => {
    if (row === selectedRow) {
      return (
        <>
          <FieldDropdown
            name='selectedPhoneType'
            value={selectedPhoneType}
            optionValue="ClaTipoDBCContactLabel"
            optionLabel="NomTipoDBCContactLabel"
            options={phoneNumberTypes}
            onChange={onSelectedPhoneTypeChange}
            errors={errors}
          />
        </>
      )
    }

    return row.NomTipoDBCContactLabel;
  }

  const phoneNumberTemplate = (row) => {
    if (row === selectedRow) {
      return (
        <>
          <FieldPhone
            name='selectedPhoneNumber'
            value={selectedPhoneNumber}
            placeholder={t('common:ContactNumber')}
            onChange={(phone) => {onSelectedPhoneNumberChange(phone)}}
            onCountryChange={(c, cc) => {onCountryChange(c, cc)}}
            errors={errors}
          />
        </>
      )
    }

    return row.Valor.length >= 10 ? formatPhoneNumberNA(row.Valor) : row.Valor;
  }

  const emailTypeTemplate = (row) => {
    if (row === selectedRow) {
      return (
        <>
          <FieldDropdown
            name='selectedEmailType'
            value={selectedEmailType}
            optionValue="ClaTipoDBCContactLabel"
            optionLabel="NomTipoDBCContactLabel"
            options={emailTypes}
            onChange={onSelectedEmailTypeChange}
            errors={errors}
          />
        </>
      )
    }

    return row.NomTipoDBCContactLabel;
  }

  const emailTemplate = (row) => {
    if (row === selectedRow) {
      return (
        <>
          <FieldText
            name='selectedEmail'
            value={selectedEmail}
            onChange={onSelectedEmailChange}
            errors={errors}
          />
        </>
      )
    }

    return row.Valor;
  }

  const socialTypeTemplate = (row) => {
    if (row === selectedRow) {
      return (
        <>
          <FieldDropdown
            name='selectedSocialType'
            value={selectedSocialType}
            optionValue="ClaTipoDBCContactLabel"
            optionLabel="NomTipoDBCContactLabel"
            options={socialTypes}
            onChange={onSelectedSocialTypeChange}
            errors={errors}
          />
        </>
      )
    }

    return row.NomTipoDBCContactLabel;
  }

  const socialLinkTemplate = (row) => {
    if (row === selectedRow) {
      return (
        <>
          <FieldText
            name='selectedSocialLink'
            value={selectedSocialLink}
            onChange={onSelectedSocialLinkChange}
            errors={errors}
          />
        </>
      )
    }

    return row.Valor;
  }

  const phoneNumberOptionsTemplate = (row) => {
    return (
      <div className='p-d-flex p-jc-end'>
        {row === selectedRow ? (
          <FieldButton icon='pi pi-check' className='p-button-primary p-button-sm mr-2' onClick={() => onSavePhoneNumberClick(row)} />
        ) : <></>}
        <FieldButton icon='pi pi-trash' className='p-button-danger p-button-sm' onClick={() => onDeleteContactRowClick(row)} />
      </div>
    )
  }

  const emailOptionsTemplate = (row) => {
    return (
      <div className='p-d-flex p-jc-end'>
        {row === selectedRow ? (
          <FieldButton icon='pi pi-check' className='p-button-primary p-button-sm mr-2' onClick={() => onSaveEmailClick(row)} />
        ) : <></>}
        <FieldButton icon='pi pi-trash' className='p-button-danger p-button-sm' onClick={() => onDeleteContactRowClick(row)} />
      </div>
    )
  }

  const socialLinkOptionsTemplate = (row) => {
    return (
      <div className='p-d-flex p-jc-end'>
        {row === selectedRow ? (
          <FieldButton icon='pi pi-check' className='p-button-primary p-button-sm mr-2' onClick={() => onSaveSocialLinkClick(row)} />
        ) : <></>}
        <FieldButton icon='pi pi-trash' className='p-button-danger p-button-sm' onClick={() => onDeleteContactRowClick(row)} />
      </div>
    )
  }

  return (
    <>
      <Row className='p-fluid'>
        {/* <Col xs={12} sm={12} md={12} lg={12}>
          <h1>{t('qr:Edit Business Card')}</h1>
        </Col> */}
        <Col xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardBody>
              <Row className='align-items-start'>
                <Col xs={12} sm={12} md={12} lg={12} className='p-field'>
                  <i className='pi pi-info-circle mr-2 text-primary' />{t('qr:DefaultBSCValues')}
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} className='p-field'>
                  <Card className={`${errors.userImage !== undefined ? 'border border-danger' : ''}`}>
                    <CardBody className='p-d-flex p-jc-center'>
                      {renderUserImage()}
                    </CardBody>
                    <CardFooter>
                      <FieldButton
                        label={t('qr:ChangeProfilePicture')}
                        icon='pi pi-image'
                        onClick={() => setShowUploadImageDialog(true)}
                      />
                    </CardFooter>
                  </Card>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <Row className='align-items-start'>
                    <Col xs={12} sm={12} md={12} lg={12} className='p-field'>
                      <Label><i className='pi pi-info-circle mr-2 text-primary' />{t('qr:FullName')}</Label>
                      <FieldText
                        id='fullName'
                        name='fullName'
                        value={fullName}
                        onChange={onFullNameChange}
                        errors={errors}
                      />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} className='p-field'>
                      <Label><i className='pi pi-info-circle mr-2 text-primary' />{t('qr:JobTitle')}</Label>
                      <FieldText
                        id='jobTitle'
                        name='jobTitle'
                        value={jobTitle}
                        onChange={onJobTitleChange}
                        errors={errors}
                      />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} className='p-field'>
                      <Label>{t('qr:Template')}</Label>
                      <FieldDropdown
                        name='templateId'
                        value={templateId}
                        optionValue="TemplateId"
                        optionLabel="TemplateName"
                        options={templates}
                        onChange={onTemplateChange}
                        errors={errors}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} className='p-field'>
                  <Label>{t('qr:Address')}</Label>
                  <FieldText
                    id='address'
                    name='address'
                    value={address}
                    onChange={onAddressChange}
                    errors={errors}
                  />
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row className='p-d-flex p-jc-center'>
                <Col xs={12} sm={6} md={4} lg={4}>
                  <FieldButton label={t('common:Save')} icon='pi pi-save' className='p-button-success' onClick={() => onSaveInfoClick()} />
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} className='p-field'>
          <FieldDataTable
            value={phoneNumbers} 
            header={phoneNumberHeaderTemplate}  
            scrollable 
            className="p-datatable-striped"
            paginator={false}
            selectionMode='single'
            selection={selectedRow}
            onSelectionChange={e => {onSelectedRowChange(e)}}
          >
            <Column body={phoneNumberTypeTemplate} header={t('qr:Type')} headerStyle={{ width: '200px' }}></Column>
            <Column body={phoneNumberTemplate} header={t('qr:PhoneNumber')} headerStyle={{ width: '225px' }}></Column>
            <Column body={phoneNumberOptionsTemplate}></Column>
          </FieldDataTable>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} className='p-field'>
          <FieldDataTable
            value={emails} 
            header={emailHeaderTemplate}  
            scrollable 
            className="p-datatable-striped"
            paginator={false}
            selectionMode='single'
            selection={selectedRow}
            onSelectionChange={e => {onSelectedRowChange(e)}}
          >
            <Column body={emailTypeTemplate} header={t('qr:Type')} headerStyle={{ width: '200px' }}></Column>
            <Column body={emailTemplate} header={t('qr:Email Address')}></Column>
            <Column body={emailOptionsTemplate}></Column>
          </FieldDataTable>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} className='p-field'>
          <FieldDataTable
            value={socialLinks} 
            header={socialHeaderTemplate}  
            scrollable 
            className="p-datatable-striped"
            paginator={false}
            selectionMode='single'
            selection={selectedRow}
            onSelectionChange={e => {onSelectedRowChange(e)}}
          >
            <Column body={socialTypeTemplate} header={t('qr:Type')} headerStyle={{ width: '200px' }}></Column>
            <Column body={socialLinkTemplate} header={t('qr:URL')} headerStyle={{ width: '350px' }}></Column>
            <Column body={socialLinkOptionsTemplate}></Column>
          </FieldDataTable>
        </Col>
      </Row>
      <Dialog
        visible={showUploadImageDialog}
        onHide={onHideUploadImageDialog}
        style={{minWidth: '10vw', minHeight: '20vh'}}
        header={t('qr:ChangeProfilePicture')}
      >
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} className='mb-2'>
            <div className='text-info'><i className='pi pi-info-circle' /> {t('qr:AcceptedImageTypesProfilePic')}</div>
            <div className='text-info'><i className='pi pi-info-circle' /> {t('qr:RecommendedProfilePicSize')}</div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12}>
            <FieldUpload 
              name='userImageFile'
              files={userImageFile}
              onChange={onUserImageFileChange}
              errors={errors}
              accept='image/gif,image/svg+xml,image/jpeg,image/png'
              uploadPrompt={t('qr:DragAndDropPhotoHere')}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} className='p-fluid mt-2'>
            <FieldButton label={t('common:Submit')} icon='pi pi-save' onClick={() => onSubmitUserImageClick()} />
          </Col>
        </Row>
      </Dialog>
    </>
  )
}

export default EditBusinessCard;
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col, 
  Card,
  CardHeader,
  CardBody 
} from 'reactstrap'; 
import FieldCheckbox from 'components/Controls/FieldCheckbox';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable.jsx'; 
import { Skeleton } from 'primereact/skeleton';
import { Column } from 'primereact/column';

const DailyDowntimeSummaryTrendSkeleton = (props) => { 
  const {t} = useTranslation(['wtw', 'common']);

 
  const filtersCheckbox = (
    <> 
      <Row className="pt-3 align-items-start">
        <Col lg={12} md={12} sm={12} xs={12}>
          <table width="100%">
            <tr>
              <td><Skeleton height="1rem" width="100%" className="mb-2 mx-2"></Skeleton></td>
              <td><Skeleton height="1rem" width="100%" className="mb-2 mx-2"></Skeleton></td>
              <td><Skeleton height="1rem" width="100%" className="mb-2 mx-2"></Skeleton></td>
              <td><Skeleton height="1rem" width="100%" className="mb-2 mx-2"></Skeleton></td>
              <td width="60%">&nbsp;</td>
            </tr>
          </table>
        </Col> 
      </Row>
    </>
  );

  const skeletonTemplate = () =>{
    return (<Skeleton height="1rem" width="100%" className="mb-2"></Skeleton>);
  }   

  return (
    <>   
      <div className='CedisShippingAndReceiving'>
        <Row className='align-items-start'>
          <Col lg={12} md={12} sm={12} xs={12}> 
            {filtersCheckbox}
            <FieldDataTable 
              value={[{},{},{},{},{},{},{},{}]}
              scrollable 
              paginator={false}
              scrollHeight="calc(100vh - 360px)" 
              scrollDirection="both"
            >    
              <Column body={skeletonTemplate} header={t('wtw:ConceptDownTime')} style={{ width: '180px' }}></Column> 
              <Column body={skeletonTemplate} header={t('wtw:30')} style={{ width: '50px', textAlign: 'right' }}></Column> 
              <Column body={skeletonTemplate} header={t('wtw:29')} style={{ width: '50px', textAlign: 'right' }}></Column> 
              <Column body={skeletonTemplate} header={t('wtw:28')} style={{ width: '50px', textAlign: 'right' }}></Column> 
              <Column body={skeletonTemplate} header={t('wtw:27')} style={{ width: '50px', textAlign: 'right' }}></Column> 
              <Column body={skeletonTemplate} header={t('wtw:26')} style={{ width: '50px', textAlign: 'right' }}></Column> 
              <Column body={skeletonTemplate} header={t('wtw:25')} style={{ width: '50px', textAlign: 'right' }}></Column> 
              <Column body={skeletonTemplate} header={t('wtw:24')} style={{ width: '50px', textAlign: 'right' }}></Column> 
              <Column body={skeletonTemplate} header={t('wtw:23')} style={{ width: '50px', textAlign: 'right' }}></Column> 
              <Column body={skeletonTemplate} header={t('wtw:22')} style={{ width: '50px', textAlign: 'right' }}></Column> 
              <Column body={skeletonTemplate} header={t('wtw:21')} style={{ width: '50px', textAlign: 'right' }}></Column> 
              <Column body={skeletonTemplate} header={t('wtw:20')} style={{ width: '50px', textAlign: 'right' }}></Column> 
              <Column body={skeletonTemplate} header={t('wtw:19')} style={{ width: '50px', textAlign: 'right' }}></Column> 
              <Column body={skeletonTemplate} header={t('wtw:18')} style={{ width: '50px', textAlign: 'right'}}></Column> 
            </FieldDataTable>
          </Col> 
        </Row> 
      </div>
    </>   
  ); 
};

export default DailyDowntimeSummaryTrendSkeleton;
import React from 'react'
import ReactPlayer from 'react-player' 
import PropTypes from "prop-types";
import "./VideoPlayer.css";

const VideoPlayer = ({
    url, 
    reference,
    index,
    height
  }) => {
    
    return (
      <div className='player-wrapper'>
        <ReactPlayer
          ref={reference}
          id={`react-player-${index}`}
          className='react-player'
          controls={true}
          url={url}
          width='100%'
          height={height === null ? '100%' : height}
        />
      </div>
    );
  };
   
  VideoPlayer.propTypes = {
    url: PropTypes.string.isRequired 
  };
  
  export default VideoPlayer;
/* eslint-disable no-self-compare */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from 'components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap'; 
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../../utils/utils'; 
import FieldToogleSwitch from 'components/Controls/FieldToogleSwitch/FieldToogleSwitch';
import FieldSelectButton from 'components/Controls/FieldSelectButton';
import moment from 'moment';
import { Divider } from 'primereact/divider';
import PropType from 'prop-types';
import SurveryIndividualRespondView from './SurveryIndividualRespondView';
import { Toolbar } from 'primereact/toolbar';


const SurveyIndividualResponses = ({IdEncuesta}) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['survey', 'common']);
  const [errors, setErrors] = useState({});
  const [ViewFeedBackResponseList, setViewFeedBackResponseList] = useState([]);  
  const [SurveyContestadasList, setSurveyContestadasList] = useState([]);  
  const [itemRow, setItemRow] = useState(null);  
  const [CurrentElement, setCurrentElement] = useState({});
  

  useEffect(() => {
    loadSurveyContestada(IdEncuesta);
   
  }, [IdEncuesta]);

  const loadSurveyContestada = (pIdEncuesta) => {
    const getSurveyContestada = `${config.UrlApiProject}hr/GetSurveyContestada?IdEncuesta=${pIdEncuesta}`;
    const paramsToService = {};
    callApi(getSurveyContestada, 'GET', paramsToService, (response) => { 
       
        setSurveyContestadasList(response.data.EncuestaContestada); 
        setCurrentElement(response.data.EncuestaContestada.length > 0 ? response.data.EncuestaContestada[0] : null);
        setItemRow(0);
    });
  }; 

  const nextItem = () => { 
    let index = itemRow; 
    if (SurveyContestadasList.length > index+1) {   
      index += 1;
      setItemRow(index);
    }
    else { 
      index = 0
      setItemRow(0);
    }     
    setCurrentElement(SurveyContestadasList[index]);
  }
  console.log(CurrentElement);
  const prevItem = () => {
    let index = itemRow; 
    if (index-1 >= 0) {   
      index -= 1;
      setItemRow(index);
    }
    else { 
      index = SurveyContestadasList.length -1;
      setItemRow(index);
    }     
    setCurrentElement(SurveyContestadasList[index]);

  }

  const isValidPrevItem = () => {
    const index = itemRow; 
    if (index-1 >= 0) {   
      return true;
    }
     
    return false;
          
  }

  const isValidNextItem = () => {
    const index = itemRow; 
    if (SurveyContestadasList.length > index+1) {   
      return true;
    }
     
    return false; 
          
  } 

  const leftToolbarTemplate = () => {  
    return (
      <>    
        <FieldButton icon="fas fa-angle-left fa-2x" className={`${ !isValidPrevItem() ? 'disabled-gray' : 'hard-gray'} border-none bold btn-circle btn-lg`} disabled={!isValidPrevItem()} onClick={prevItem}  /> 
      </>
    );
  };
  const rightToolbarTemplate = () => {
     
    return (
      <>   
        <FieldButton icon="fas fa-angle-right fa-2x" className={`${ !isValidNextItem() ? 'disabled-gray' : 'hard-gray'} border-none bold btn-circle btn-lg`} disabled={!isValidNextItem()} onClick={nextItem} /> 
      </> 
    );
  }; 


  return (
    <>       
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <Toolbar
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          >
          </Toolbar> 
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
            &nbsp;
        </Col>
      </Row>   

      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          {
            CurrentElement !== null && CurrentElement.IdEncuesta > 0 && CurrentElement.IdEncuestaContestada > 0 ?
            (<SurveryIndividualRespondView IdEncuesta={CurrentElement.IdEncuesta} IdEncuestaContestada={CurrentElement.IdEncuestaContestada}></SurveryIndividualRespondView>)
            :
            (<></>)
          } 
        </Col>
      </Row>     
    </>
  );
};

export default SurveyIndividualResponses;

import PageContent from 'layouts/PageContent';
import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import EnterTicketEmail from './EnterTicketEmail';
import { Card, CardBody } from 'reactstrap';

const EnterTicketScreen = (props) => {
  const {t, i18n} = useTranslation(['it', 'common']);

  return (
    <>
      <PageContent title={t('it:EnterTicket')}>
        <EnterTicketEmail />
      </PageContent>
    </>
  )
}

export default EnterTicketScreen;
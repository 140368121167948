/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'; 
import { useTranslation } from 'react-i18next';  
import { Route, Switch, Redirect } from 'react-router-dom';
import AdminFooter from 'components/Footers/AdminFooter.js';
import Components  from 'externalRoutes.js';

/* all the external pages with no menu must start with /s/ */
const ExternalPagesNoMenu = (props) => {  
   
    const { t } = useTranslation(['common']); 

    return (
      <>   
        <div className="main-content">
          <div>
            <Switch>
              <Route path="/s/DigitalBusinessCard/:ClaUsuarioBC" component={Components.BusinessCard} />
              <Route path="/s/BusinessCardQR/:ClaUsuarioBC" component={Components.BusinessCardQR} /> 
            </Switch>
          </div>
        </div> 
        <AdminFooter />  
      </>  
        ); 
};

export default ExternalPagesNoMenu;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  ListGroupItem,
  ListGroup,
  Progress,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label
} from 'reactstrap';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { Badge } from 'primereact/badge';
// import FeedbackCoaching from './FeedbackCoaching.jsx';
import { Link } from 'react-router-dom';
import ActionPlanScoreSquare from './ActionPlanScoreSquare.jsx';
import { Toolbar } from 'primereact/toolbar';
import Highcharts from 'highcharts';
import { config } from '../../../../utils/config';
import { callApi, tracker, getSessionItem, showSweetAlert } from '../../../../utils/utils';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable.jsx';
import { Column } from 'primereact/column';
import FieldButton from 'components/Controls/FieldButton/FieldButton.jsx';
import { Dialog } from 'primereact/dialog';
import { Tag } from 'primereact/tag';
import moment from "moment"; 

const KPIObjectivesDetail = ({
    ClaDepartamento,
    ClaArea,
    ClaTipoIndicador,
    ClaIndicador,
    ClaPuesto,
    ClaAnio,
    showCloseButton,
    onCloseClick,
    NomKPI
}) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['focusReport', 'common']); 

  const [PuestoIndicadorDetList, setIndicadorPuestoDetalleList] = useState([]);
  const [nomIndicadorSelected, setNomIndicadorSelected] = useState(null); 

  useEffect(() => { 
    GetKPIObjetivosDetalle();
  }, [ClaDepartamento, ClaArea, ClaTipoIndicador, ClaIndicador,ClaPuesto,ClaAnio ]);

  const GetKPIObjetivosDetalle = () => {
    const GetIndicadorPuesto = `${config.UrlApiProject}ScoreCard/GetIndicadorPuestoDetalle`;
    const paramsToService = {
      ClaDepartamento,
      ClaArea,
      ClaTipoIndicador,
      ClaIndicador,
      ClaPuesto,
      ClaAnio,
    }; 
    callApi(GetIndicadorPuesto, 'POST', paramsToService, (response) => {
      setIndicadorPuestoDetalleList(response.data.IndicadorPuestoDetalle); 
    } );
  };
 
  const headerPuestoIndicadorDet = <>{NomKPI} {t('position:ByPeriod')}</>;
  
  const NomMesTemplate = (props) => {

    if (props.EsMostrarBotonActualizarResultado === true) {
      return (
        <div title={t('position:TooltipBotonActualizaEstatusKPI')} onClick={() => confirmacionRecalcultarEstatusKPI(props)}>
          <table>
            <tbody>
              <tr>
                <td width="70%">{props.NomMes}</td>
                <td>&nbsp;<i className="fas fa-sync cursor"></i></td>
              </tr>
            </tbody>
          </table>
        </div>
      )
   }

   return (<>{props.NomMes}</>);

    
  }

  const EstatusPeriodoTemplate = (props) => {
    let className = '';

    switch (props.ColorEstatus) {
      case 1:
        className = 'danger-color';
        break;
      case 2:
        className = 'warning-color';
        break;
      case 3:
        className = 'success-color';
        break;
      default:
        className = 'default-color';
    }
    return (
      <>
        <i className={`fa fa-circle ${className}`}></i>
        &nbsp;
        {props.EstatusPeriodo !== null? Highcharts.numberFormat(props.EstatusPeriodo, 2, '.', ','): null}
      </>
    );
  };
  const MinimoTemplate = (props) => {
    return <>{props.Minimo !== null?  Highcharts.numberFormat(props.Minimo, 2, '.', ',') : null}</>;
  };
  const SatisfactorioTemplate = (props) => {
    return <>{props.Satisfactorio !== null?  Highcharts.numberFormat(props.Satisfactorio, 2, '.', ','): null}</>;
  };

  const SobreSalienteTemplate = (props) => {
    return <>{props.SobreSaliente !== null?  Highcharts.numberFormat(props.SobreSaliente, 2, '.', ','): null}</>;
  };
 

  return (
    <>
      <Card>
        <CardBody>
          <Row className="align-items-start ">
            <Col lg={10} md={10} sm={10} xs={10}>              
              <div style={{ float:'left'}}>
                <h2> {headerPuestoIndicadorDet}</h2>
              </div>  
            </Col> 
          </Row> 
          <div> 
            <Row className="align-items-start ">
              <Col lg={12} md={12} sm={12} xs={12}>
                <FieldDataTable
                  value={PuestoIndicadorDetList}
                  scrollable
                  scrollHeight="480px"
                  selectionMode="single"
                  paginator={false} 
                > 
                  <Column
                    field="NomMes"
                    header={t('position:ReportingPeriod')} 
                    columnKey="NomMes"
                    headerStyle={{ width: '100px' }}
                    body={NomMesTemplate}
                  >
                  </Column>
                  <Column
                    field="NomUnidadIndicador"
                    className="text-center"
                    header={t('position:UOM')}
                    columnKey="NomUnidadIndicador"
                    headerStyle={{ width: '50px' }}
                  >
                  </Column>
                  <Column
                    field="EstatusPeriodo"
                    header={t('position:CurrentStatus')}
                    columnKey="EstatusPeriodo"
                    headerStyle={{ width: '80px' }} 
                    body={EstatusPeriodoTemplate}
                  >
                  </Column>
                  <Column
                    field="Minimo"
                    header={t('position:MinimunAcceptable')}
                    columnKey="Minimo"
                    headerStyle={{ width: '75px' }} 
                    body={MinimoTemplate}
                  >
                  </Column>
                  <Column
                    field="Satisfactorio"
                    header={t('position:Satisfactory')}
                    columnKey="Satisfactorio"
                    headerStyle={{ width: '75px' }} 
                    body={SatisfactorioTemplate}
                  >
                  </Column>
                  <Column
                    field="SobreSaliente"
                    header={t('position:OutStanding')}
                    columnKey="SobreSaliente"
                    headerStyle={{ width: '75px' }} 
                    body={SobreSalienteTemplate}
                  >
                  </Column>
                </FieldDataTable> 
              </Col>
            </Row>  
          </div>
          <br></br> 
        </CardBody>
        <CardFooter hidden={!showCloseButton}>
          <Row>
            <Col className='text-center'>
              <FieldButton
                label={t('common:Close')}
                icon="fas fa-times"
                className="warning no-border p-mr-2 rounded-button"
                onClick={typeof(onCloseClick) !== 'undefined' && typeof(onCloseClick) === 'function' ? onCloseClick : null}
              />
            </Col>
          </Row>
        </CardFooter>
      </Card> 
    </>
  );
};

export default KPIObjectivesDetail;

import React, { useState, useRef, useEffect  } from 'react'; 
import { useTranslation } from 'react-i18next';   
import { config } from '../../../utils/config';
import { callApi, showSweetAlert} from '../../../utils/utils';   
import PageContent from 'layouts/PageContent';   
import { Row,Col,Card, CardHeader, CardTitle, CardBody, CardFooter} from 'reactstrap';  
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable.jsx';
import FieldDropdown from 'components/Controls/FieldDropdown.jsx' 
import FieldButton from 'components/Controls/FieldButton/FieldButton.jsx';
import FieldRadioButton from 'components/Controls/FieldRadioButton/FieldRadioButton.jsx';
import FieldTextArea  from 'components/Controls/FieldTextArea.jsx'
import FieldCalendar from 'components/Controls/FieldCalendar.jsx'
import './ProductionDailyFollowUPScreen.scss';  
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import { Column } from 'primereact/column';
import ProductionWeeklyFollowUPDepartamento from './ProductionWeeklyFollowUPDepartamento';
import ProductionWeeklyFollowUPComentarios from './ProductionWeeklyFollowUPComentarios';
import Highcharts from 'highcharts';
import FieldSelectButton from 'components/Controls/FieldSelectButton';
import { Skeleton } from 'primereact/skeleton';
import { Dialog } from 'primereact/dialog';
import DailyOperationProductionTrend from './DailyOperationTracker/DailyOperationProductionTrend';
import moment from 'moment';
import { PrimeIcons } from 'primereact/api'; 

const ProductionWeeklyFollowUPScreen = (props) => { 
  const { t } = useTranslation(['wtw', 'common']);
  const [errors, setErrors] = useState({});   
  const [AnioMesLista, setAnioMesLista] = useState([]);
  const [SemanaLista, setSemanaLista] = useState([]);
  const [PlantasList, setPlantasList] = useState([]);
  const [DepartamentosList, setDepartamentosList] = useState([]);
  const [ClaAnioMes, setClaAnioMes] = useState(null);
  const [NomAnioMes, setNomAnioMes] = useState(null); 
  const [IdProduccionSeguimientoSemanal, setIdProduccionSeguimientoSemanal] = useState(null); 
  const [IdProduccionSeguimientoSemanalDepartamento, setIdProduccionSeguimientoSemanalDepartamento] = useState(null);
  const [ClaUbicacion, setClaUbicacion] = useState(null);
  const [NomUbicacion, setNomUbicacion] = useState(null);
  const [ClaDepartamento, setClaDepartamento] = useState(null);
  const [NomDepartamento, setNomDepartamento] = useState(null);
  const [ClaArea, setClaArea] = useState(null);
  const [NomArea, setNomArea] = useState(null);
  const [ClaSemana, setClaSemana] = useState(null); 
  const [NomSemana, setNomSemana] = useState(null); 
  const [ExplicacionProblema, setExplicacionProblema] = useState(null); 
  const [ExplicacionProblemaDepartamento, setExplicacionProblemaDepartamento] = useState(null); 
  const [IsOnSearchApplied, setIsOnSearchApplied] = useState(false); 
  const [PosibleSolucionProblema, setPosibleSolucionProblema] = useState(null); 
  const [FechaVencimiento, setFechaVencimiento] = useState(null); 
  const [EsMostrarDialogDepartament, setEsMostrarDialogDepartament] = useState(false); 
  const [EsMostrarDialogComentarios, setEsMostrarDialogComentarios] = useState(false); 
  const [EsActionPlan, setEsActionPlan] = useState(null);  
  const [TurnoList, setTurnoList] = useState([]);
  const [ClaTurno, setClaTurno] = useState([1, 2, 3]);
  const [EsCargandoListado, setEsCargandoListado] = useState(false);
  const [EsMostrarProduccionTendencia, setEsMostrarProduccionTendencia] = useState(false);

  useEffect(() => {
    getAnioMes();
    getPlantas();
    GetTurno();
  }, [])

  const getAnioMes = () => {  
    const urlWebService = `${config.UrlApiProject}WTW/GetAnioMesProduccionSeguimientoCmb`; 
    const paramsService= {};  
    callApi(urlWebService, 'GET', paramsService, (response) => {
      setAnioMesLista(response.data.AnioMesLista);
    }, null, false, true);
  }

  const getPlantas = () => {  
    const urlWebService = `${config.UrlApiProject}WTW/GetPlantasProduccionSeguimientoCmb`; 
    const paramsService= {};  
    callApi(urlWebService, 'GET', paramsService, (response) => {
      setPlantasList(response.data.PlantasList);
    }, null, false, true);
  }
 
  const getSemanas = (anioMes) => {  
    const urlWebService = `${config.UrlApiProject}WTW/GetSemanaProduccionSeguimientoCmb?AnioMes=${anioMes}`; 
    const paramsService= {};  
    callApi(urlWebService, 'GET', paramsService, (response) => {
      setSemanaLista(response.data.SemanaLista);
    }, null, false, true);
  }

  const GetTurno = () => {
    const paramsService = {};
    const urlWebService = `${config.UrlApiProject}Operations/GetTurno`;
    callApi(urlWebService, 'GET', paramsService, (response) => {
      setTurnoList(response.data.Turnos);
    });
  };

    const onChangeClaUbicacion = (e) => {  
      const newErrors = { ...errors } 
      delete newErrors.ClaUbicacion;
      setErrors(newErrors)  
      setClaUbicacion(e.value);
      setIsOnSearchApplied(false);
      const UbicacionList = [...PlantasList];
      const UbicacionName = UbicacionList.filter((item) => item.ClaUbicacion === e.value)[0];
      if(UbicacionName!==undefined){
        setNomUbicacion(UbicacionName.NombreUbicacion);  
      }  
  };

  const onChangeClaAnioMes = (e) => {  
    const newErrors = { ...errors } 
    delete newErrors.ClaAnioMes;   
    setErrors(newErrors)  
    setClaAnioMes(e.value);
    setIsOnSearchApplied(false);
    const AnioMesList = [...AnioMesLista];
    const AniMesName = AnioMesList.filter((item) => item.ClaAnioMes === e.value)[0];
    if(AniMesName!==undefined){
      setNomAnioMes(AniMesName.NomAnioMes);  
    }
    getSemanas(e.value);  
  };

  const onChangeClaSemana = (e) => {  
    const newErrors = { ...errors }
    delete newErrors.ClaSemana;   
    setErrors(newErrors);
    setClaSemana(e.target.value);
    setIsOnSearchApplied(false);
    const SemanaList = [...SemanaLista];
    const SemanaName = SemanaList.filter((item) => item.ClaSemana === e.value)[0];
    if(SemanaName!==undefined){
      setNomSemana(SemanaName.NomSemana);  
    }
  } 

  const onChangeExplicacionProblema = (e) => {  
    const newErrors = { ...errors }
    delete newErrors.ExplicacionProblema;   
    setErrors(newErrors);
    setExplicacionProblema(e.target.value);
  } 
  
  const onChangeFechaVencimiento = (e) => {  
    const newErrors = { ...errors }
    delete newErrors.FechaVencimiento;   
    setErrors(newErrors);
    setFechaVencimiento(e.target.value);
  } 

  const onHideDepartamentos = async () => {
    setEsMostrarDialogDepartament(false);
    onRefreshDepartamentosSummary(ClaTurno);
  }

  const getInformacionSemana = () => {
    const infoSemana = SemanaLista.filter((item) => item.ClaSemana === ClaSemana)

    if (infoSemana != null){
      return infoSemana[0];
    }
 
    return {}
  }

  const valitationSchemeApply = yup.object().shape({
    ClaUbicacion: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaAnioMes: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaSemana: yup.mixed().required(t('common:RequiredField')).nullable()
  }); 

  const onRefreshDepartamentosSummary = async (ClaTurnos) => {
      const value = {
        ClaUbicacion,
        ClaAnioMes,
        ClaSemana
      } 
      const validator = new YupValidator(valitationSchemeApply);
      const esValido = await validator.validate(value);
      
      if (!esValido){ 
        showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
        setErrors(validator.errors); 
        return;
      }
      const urlWebService = `${config.UrlApiProject}WTW/GetProduccionSeguimientoSemanalDepartamento`; 
      const paramsService = {
        ClaUbicacion,
        ClaAnioMes,
        ClaSemana,
        ClaTurnos: '' // ClaTurnos.join(',')
        ,FechaInicio: getInformacionSemana().FechaInicio
        ,FechaFin : getInformacionSemana().FechaFin
      }; 
      setEsCargandoListado(true);
      callApi(urlWebService, 'POST', paramsService, (response) => {  
          setDepartamentosList(response.data.DepartamentosList);  
          setEsCargandoListado(false); 
          }, () => {
      }, null, false);
  }

  const onSearch = async () => {  
        setExplicacionProblema(null);
        setPosibleSolucionProblema(null);
        setPosibleSolucionProblema(null);
        setFechaVencimiento(null);
        setEsActionPlan(false);
        setDepartamentosList([]);
        const value = {
          ClaUbicacion,
          ClaAnioMes,
          ClaSemana
        } 
        const validator = new YupValidator(valitationSchemeApply);
        const esValido = await validator.validate(value);
        
        if (!esValido){ 
          showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
          setErrors(validator.errors); 
          return;
        }
      const urlWebService = `${config.UrlApiProject}WTW/GetProduccionSeguimientoSemanalDepartamento`; 
      const paramsService = {
        ClaUbicacion,
        ClaAnioMes,
        ClaSemana,
        ClaTurnos: '' // ClaTurno.join(',')
        ,FechaInicio: getInformacionSemana().FechaInicio
        ,FechaFin : getInformacionSemana().FechaFin
      }; 
      callApi(urlWebService, 'POST', paramsService, (response) => {
        const data = response.data.ProduccionSeguimientoPlanta[0];
        setIdProduccionSeguimientoSemanal(null);
        if(data!==undefined){
          setIdProduccionSeguimientoSemanal(data.IdProduccionSeguimientoSemanal);
          setExplicacionProblema(data.ExplicacionProblema);
          setPosibleSolucionProblema(data.PosibleSolucionProblema);
          let fechaVencimiento = null;
          if(data.FechaVencimiento!==null){
            fechaVencimiento = new Date(data.FechaVencimiento)
          }
          setFechaVencimiento(fechaVencimiento);
          setEsActionPlan(data.EsActionPlan);
        }
        setDepartamentosList(response.data.DepartamentosList);
        setIsOnSearchApplied(true);
      }, () => {
    });
};

const valitationSchemeSave = yup.object().shape({
  ClaUbicacion: yup.mixed().required(t('common:RequiredField')).nullable(),
  ClaAnioMes: yup.mixed().required(t('common:RequiredField')).nullable(),
  ClaSemana: yup.mixed().required(t('common:RequiredField')).nullable(),
  ExplicacionProblema: yup.string().trim().required(t('common:RequiredField')).nullable(),
  FechaVencimiento: (
    EsActionPlan
    ? yup.date().required(t('common:RequiredField')).nullable() 
    : yup.string()
  ),
  PosibleSolucionProblema: (
    EsActionPlan
    ? yup.string().trim().required(t('common:RequiredField')).nullable() 
    : yup.string()
  )
}); 

const onSave = async () => {  
    const value = {
      ClaUbicacion,
      ClaAnioMes,
      ClaSemana,
      ExplicacionProblema
    }
    if(EsActionPlan){
      value.FechaVencimiento=FechaVencimiento;
      value.PosibleSolucionProblema=PosibleSolucionProblema;
    }
    const validator = new YupValidator(valitationSchemeSave);
    const esValido = await validator.validate(value);
    if (!esValido){ 
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
      return;
    }

    const urlWebService = `${config.UrlApiProject}WTW/SaveProduccionSeguimientoSemanal`; 
    const paramsService = {
      IdProduccionSeguimientoSemanal,
      ClaUbicacion,
      ClaAnioMes,
      ClaSemana,
      ExplicacionProblema,
      PosibleSolucionProblema,
      FechaVencimiento
    };
    callApi(urlWebService, 'POST', paramsService, (response) => {
      showSweetAlert(t('common:Exito'), t('common:SaveSuccess'), 'success');
      setEsApply(true);
    }, () => {
  });
};
  
  const filters = (
    <>
      <Row className="pt-3 pb-3">
        <Col>
          <div className="p-fluid">
            <FieldDropdown  
              name="ClaUbicacion"
              value={ClaUbicacion} 
              options={PlantasList}  
              optionValue="ClaUbicacion"
              optionLabel="NombreUbicacion" 
              onChange={(e) => onChangeClaUbicacion(e)}
              label={t('wtw:Plant')}
              errors={errors}
            />   
          </div>
        </Col>
        <Col>
          <div className="p-fluid">
            <FieldDropdown  
              name="ClaAnioMes"
              value={ClaAnioMes} 
              options={AnioMesLista}  
              optionValue="ClaAnioMes"
              optionLabel="NomAnioMes" 
              onChange={(e) => onChangeClaAnioMes(e)}
              label={t('wtw:YearMonth')}
              errors={errors}
            />     
          </div>
        </Col>
        <Col>
          <div className="p-fluid">
            <FieldDropdown  
              name="ClaSemana"
              value={ClaSemana} 
              options={SemanaLista}  
              optionValue="ClaSemana"
              optionLabel="NomSemana" 
              onChange={(e) => onChangeClaSemana(e)}
              label={t('wtw:Week')}
              errors={errors}
            />   
          </div>
        </Col>
        <Col lg={2} md={2} sm={2} xs={2}>
          <div className='text-right'>
            <FieldButton
              label={t('common:Search')}
              icon="pi pi-search"
              className="p-button p-highlight"
              onClick={() => onSearch()}
            >
            </FieldButton>
          </div> 
        </Col>
      </Row>  
    </>
  );
  
  const openDialogDepartament = (item) => {
    setClaDepartamento(item.ClaDepartamento);
    setNomDepartamento(item.NomDepartamento);
    setExplicacionProblemaDepartamento(item.Comentarios);
    setIdProduccionSeguimientoSemanalDepartamento(item.IdProduccionSeguimientoSemanal);
    setClaArea(item.ClaArea);
    setNomArea(item.NomArea);
    setEsMostrarDialogDepartament(true);
  }
 
  const AreaDepartamentoColumna = (row) => {  
    
    if (row.ClaArea === -1){
      return (
        <> 
          <span> 
            {row.NomArea}
          </span> 
        </>
  );
    }
    return (
      <>
        <div onClick={() =>openDialogDepartament(row)}>
          <span className='link-text primary-color'> 
            {row.NomArea}
          </span>
        </div>
      </>
);
}

const onChangePosibleSolucionProblema = (e) => {  
  const newErrors = { ...errors }
  delete newErrors.PosibleSolucionProblema;   
  setErrors(newErrors);
  setPosibleSolucionProblema(e.target.value);
} 

const onViewComments = async () => {  
    setEsMostrarDialogComentarios(true);
};

const onChangeEsActionPlan = (e) => {
  if (e === 'Si') {
    setEsActionPlan(true);
  } else {
    setFechaVencimiento(null);
    setPosibleSolucionProblema(null); 
    setEsActionPlan(false);
  }
};

const CajasTemplateCol = (item, columnName, decimals, columnNamePrev, columnNamePorc) => {
  const currentValue = item[columnName];
  const prevValue = item[columnNamePrev];
  const prevValuePoc = columnNamePorc ? item[columnNamePorc] : null;
  let changeIcon = '';
  let percentageChange = 0;

  if (prevValuePoc !== null && prevValuePoc !== 0) {
    percentageChange = prevValuePoc; 
    changeIcon = percentageChange > 0 ? PrimeIcons.ARROW_UP : PrimeIcons.ARROW_DOWN;
  }
  console.log('columnNamePrev',columnNamePrev,'prevValue',prevValue)
  return (
    <> 
      {columnNamePrev && prevValue !== null && prevValue !== 0 && prevValue !== undefined ? (
        <span style={{ whiteSpace: 'nowrap' }}>
          {Highcharts.numberFormat(item[columnName], decimals, '.', ',')} 
          <small
            title={`${t('wtw:titleWeekPrev')} ${Highcharts.numberFormat(Math.abs(prevValue), decimals, '.', ',')}`} 
            style={{ marginLeft: '8px', alignItems: 'center', color: percentageChange > 0 ? 'green' : 'red' }}
          >
            ({Highcharts.numberFormat(Math.abs(percentageChange), decimals, '.', ',')}%)
            <i className={changeIcon} style={{ marginLeft: '4px', fontSize: '0.875em' }}></i>
          </small>
        </span>
      ) : (
        <span style={{ whiteSpace: 'nowrap' }}>
          {Highcharts.numberFormat(item[columnName], decimals, '.', ',')}
        </span>
      )}
    </>
    );
};  

const DecimalTemplateCol = (item, columnName, decimals, columnNamePrev, columnNamePorc) => {
  const currentValue = item[columnName];
  const prevValue = item[columnNamePrev];
  const prevValuePorc = columnNamePorc ? item[columnNamePorc] : null;
  let changeIcon = '';
  let percentageChange = null;

  if (prevValuePorc !== null && prevValuePorc !== 0) {
    percentageChange = prevValuePorc; 
    changeIcon = percentageChange > 0 ? PrimeIcons.ARROW_UP : PrimeIcons.ARROW_DOWN;
  }

  return (
    <>
      {columnNamePrev && prevValue !== null && prevValue !== 0 && prevValue !== undefined ? (
        <span style={{ whiteSpace: 'nowrap' }}>
          {Highcharts.numberFormat(item[columnName], decimals, '.', ',')}
          <small
            title={`${t('wtw:titleWeekPrev')} ${Highcharts.numberFormat(Math.abs(prevValue), decimals, '.', ',')}`}
            style={{ marginLeft: '8px', alignItems: 'center', color: percentageChange > 0 ? 'green' : 'red' }}
          >
            ({Highcharts.numberFormat(Math.abs(percentageChange), decimals, '.', ',')}%)
            <i className={changeIcon} style={{ marginLeft: '4px', fontSize: '0.875em' }}></i>
          </small>
        </span>
    ) : (
      <span style={{ whiteSpace: 'nowrap' }}>
        {Highcharts.numberFormat(item[columnName], decimals, '.', ',')}
      </span>
    )}
    </>
  
  );
};

const PorcentajeTemplateCol = (item, columnName, columnNamePrev, columnNamePorc) => {
  const currentValue = item[columnName];
  const prevValue = item[columnNamePrev];
  const prevValuePorc = columnNamePorc ? item[columnNamePorc] : null;
  let changeIcon = '';
  let percentageChange = null; 

  if (prevValuePorc !== null && prevValuePorc !== 0) {
    percentageChange = prevValuePorc;
    changeIcon = percentageChange > 0 ? PrimeIcons.ARROW_UP : PrimeIcons.ARROW_DOWN;
  }

  return (
    <>
      {columnNamePrev && prevValue !== null && prevValue !== 0 && prevValue !== undefined ? (
        <span style={{ whiteSpace: 'nowrap' }}>
          {Highcharts.numberFormat(item[columnName], 2, '.', ',')}%
          <small
            title={`${t('wtw:titleWeekPrev')} ${Highcharts.numberFormat(Math.abs(prevValue), 1, '.', ',')}`}
            style={{ marginLeft: '8px', alignItems: 'center', color: percentageChange > 0 ? 'green' : 'red' }}
          >
            ({Highcharts.numberFormat(Math.abs(percentageChange), 1, '.', ',')}%)
            <i className={changeIcon} style={{ marginLeft: '4px', fontSize: '0.875em' }}></i>
          </small>
        </span>
    ) : (
      <span style={{ whiteSpace: 'nowrap' }}>
        {Highcharts.numberFormat(item[columnName], 2, '.', ',')}%
      </span>
    )}
    </>
  
  );
};

const PorcentajeScrapTemplateCol = (item, columnName, columnNamePrev, columnNamePorc) => {
  const currentValue = item[columnName];
  const prevValue = item[columnNamePrev];
  const prevPorc = columnNamePorc ? item[columnNamePorc] : null;
  let changeIcon = '';
  let percentageChange = null; 

  if (prevPorc !== null && prevPorc !== 0) {
    percentageChange = prevPorc;
    changeIcon = percentageChange > 0 ? PrimeIcons.ARROW_UP : PrimeIcons.ARROW_DOWN;
  }

  return (
    <>
      {columnNamePrev && prevValue !== null && prevValue !== 0 && prevValue !== undefined ? (
        <span style={{ whiteSpace: 'nowrap' }}>
          {Highcharts.numberFormat(item[columnName], 2, '.', ',')}%
          <small
            title={`${t('wtw:titleWeekPrev')} ${Highcharts.numberFormat(Math.abs(prevValue), 2, '.', ',')}`}
            style={{ marginLeft: '8px', alignItems: 'center', color: percentageChange < 0 ? 'green' : 'red' }}
          >
            ({Highcharts.numberFormat(Math.abs(percentageChange), 2, '.', ',')}%)
            <i className={changeIcon} style={{ marginLeft: '4px', fontSize: '0.875em' }}></i>
          </small>
        </span>
    ) : (
      <span style={{ whiteSpace: 'nowrap' }}>
        {Highcharts.numberFormat(item[columnName], 2, '.', ',')}%
      </span>
    )}
    </>
  
  );
};  
 

  const rowClass = (data) => {
    return {
        'light-gray': data.ClaArea === -1
    }
  }

  const onClaTurnoClick = (pClaTurno) => {  
    // no se puede desmarcar el ultimo...
    if (pClaTurno.length === 0){
      return; 
    }

    setClaTurno(pClaTurno);  
    onRefreshDepartamentosSummary(pClaTurno);
  };

  const skeletonTemplate = () =>{
    return (<Skeleton height="1rem" width="100%" className="mb-2"></Skeleton>);
  }  

  const showProductionTrend = (item) => {   
    setNomArea(item.NomArea);
    setClaArea(item.ClaArea);
    setEsMostrarProduccionTendencia(true);
  }

  const headerDialogProductionTrendDetail = () => {
    return (
      <>
        {t('wtw:ProductionTrendDetail')} - {moment(getInformacionSemana().FechaFin).format('MMMM, YYYY') } - {NomArea} - {t('wtw:Shift')} : {t('wtw:All')} - {t('wtw:Cell')}: {t('wtw:All')}
      </>
    )
  }

  const ComentariosTemplate = (row) => {
    if (row.ClaArea === -1){
      return (
        <>   
        </>
     );
    }
    
    if (row.IdProduccionSeguimientoSemanal>0){
      return (
        <> 
          <i   
            className="fas fa-comments light-yellow-color cursor"
            onClick={()=>  onViewComments()}
          > 
          </i>
        </>
);
    }

    return (
      <> 
        <i   
          className="fas fa-comments light-gray-color"
          style={{cursor:"not-allowed"}}
          title={t('wtw:NoCommentsAvailable')}
        > 
        </i>
      </>
    );
  } 

  const ListadoDepartamentos = () => {
    if (EsCargandoListado){
      return (
        <> 
          <FieldDataTable 
            value={[{},{},{},{}]}
            paginator={false} 
          >     
            <Column
              body={skeletonTemplate}
              header={t('formulario:Departament')} 
              columnKey="MTons"
              headerStyle={{ width: '100px' }}
            >
            </Column>    
            <Column header={t('wtw:Boxes')} className='text-right' headerStyle={{ width: '100px' }} body={skeletonTemplate}></Column>
            <Column header={t('wtw:UnitsByHr')} className='text-right' headerStyle={{ width: '80px' }} body={skeletonTemplate}></Column>
            <Column header={t('wtw:Tons')} className='text-right' headerStyle={{ width: '100px' }} body={skeletonTemplate}></Column>
            <Column header={t('wtw:Scrap')} className='text-right' headerStyle={{ width: '100px' }} body={skeletonTemplate}></Column>
            <Column header={t('wtw:Utilizacion')} className='text-right' headerStyle={{ width: '80px' }} body={skeletonTemplate}></Column> 
            <Column header={t('wtw:Disponibilidad')} className='text-right' headerStyle={{ width: '80px' }} body={skeletonTemplate}></Column>
            <Column header={t('wtw:Efficiencia')} className='text-right' headerStyle={{ width: '80px' }} body={skeletonTemplate}></Column>
            <Column header={t('wtw:OEE')} className='text-right' headerStyle={{ width: '80px' }} body={skeletonTemplate}></Column>
          </FieldDataTable>
        </>  
      )
    }
    return (
      <FieldDataTable 
        value={DepartamentosList}    
        paginator={false}
        rowClassName={rowClass}
        className="p-datatable-striped"
      > 
        <Column
          body={ComentariosTemplate}
          header='' 
          headerStyle={{ width: '20px' }}
        >
        </Column>  
        <Column
          body={AreaDepartamentoColumna}
          header={t('formulario:Departament')} 
          columnKey="MTons"
          headerStyle={{ width: '90px' }}
        >
        </Column>   
        <Column header={t('wtw:Boxes')} className='text-right' headerStyle={{ width: '120px' }} body={(rowData)=> CajasTemplateCol(rowData, 'Cajas', 0, 'CajasPrev', 'CajasPorc')}></Column>
        <Column header={t('wtw:UnitsByHr')} className='text-right' headerStyle={{ width: '120px' }} body={(rowData)=> DecimalTemplateCol(rowData, 'UnitsByHr', 2, 'UnitsByHrPrev', 'UnitsByHrPorc')}></Column>
        <Column header={t('wtw:Tons')} className='text-right' headerStyle={{ width: '120px' }} body={(rowData)=> DecimalTemplateCol(rowData, 'Tons', 2, 'TonsPrev', 'TonsPorc')}></Column>
        <Column header={t('wtw:Scrap')} className='text-right' headerStyle={{ width: '120px' }} body={(rowData)=> PorcentajeScrapTemplateCol(rowData, 'Scrap', 'ScrapPrev', 'ScrapPorc')}></Column>
        <Column header={t('wtw:Utilizacion')} className='text-right' headerStyle={{ width: '120px' }} body={(rowData)=> PorcentajeTemplateCol(rowData, 'Utilizacion', 'UtilizacionPrev', 'UtilizacionPorc')}></Column> 
        <Column header={t('wtw:Disponibilidad')} className='text-right' headerStyle={{ width: '120px' }} body={(rowData)=> PorcentajeTemplateCol(rowData, 'Disponibilidad', 'DisponibilidadPrev', 'DisponibilidadPorc')}></Column>
        <Column header={t('wtw:Efficiencia')} className='text-right' headerStyle={{ width: '120px' }} body={(rowData)=> PorcentajeTemplateCol(rowData, 'Efficiencia', 'EfficienciaPrev', 'EfficienciaPorc')}></Column>
        <Column header={t('wtw:OEE')} className='text-right' headerStyle={{ width: '120px' }} body={(rowData)=> PorcentajeTemplateCol(rowData, 'OEE', 'OEEPrev', 'OEEPorc')}></Column>
      </FieldDataTable>
    )
  }

 

  return (
    <>   
      <PageContent title={t('wtw:ProductionWeeklyFollowUPTitle')} className="ProductionDailyFollowUPScreen">  
        <Row className='align-items-start pb-3'>
          <Col lg={12} md={12} sm={12} xs={12}>  
            {filters}
          </Col> 
        </Row> 
        {IsOnSearchApplied}
        {
          IsOnSearchApplied ?
          (
            <>
               
              <Row className='align-items-start'>
                <Col lg={12} md={12} sm={12} xs={12}> 
                  <Row className='pb-2'>
                    <Col> <span className='h2'>{t('wtw:ResultsbyDepartment')}</span> </Col>
                    <Col className='pb-2 text-right'>
                      <div className='' onClick={() => showProductionTrend({ClaArea: null, NomArea:  t('wtw:AllDepatments') })}><span className='bold link-text primary-color'>View Production Trend <i className="fas fa-external-link-alt"></i></span></div> 
                    </Col> 
                  </Row> 
                  {ListadoDepartamentos()} 
                </Col>  
                <Col lg={12} md={12} sm={12} xs={12}>
                  <Row className='pb-3 pt-3'>
                    <Col>
                      <span className='h2'>{t('wtw:PlantlevelComments')}</span>
                      <div className='info-color'>
                        <small>{t('wtw:PlantlevelCommentsInstructions')}</small>
                        <br></br>
                        <small><i>Click <b className='cursor' onClick={()=>  onViewComments()}>here</b> to view comments organized by department.</i></small>
                      </div>
                    </Col>
                  </Row>  
                  <Card>
                    <CardBody className="" style={{paddingTop: "5px"}}> 
                      <Row className="align-items-start">
                        <Col>
                  &nbsp;
                        </Col>
                      </Row>  
                      <Row className="align-items-start">
                        <Col>
                          <div className="p-fluid">
                            <span>{`${t('wtw:Comments')}:`}</span>
                            <FieldTextArea 
                              name="ExplicacionProblema"
                              rows={5}
                              value={ExplicacionProblema}  
                              onChange={(e) => onChangeExplicacionProblema(e)}  
                              errors={errors} 
                              maxLength={3000}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="pt-3" hidden={true}>
                        <Col>
                          <div className="p-fluid">
                            <span>{`${t('wtw:DoYouNeedActionPlan')}`}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row className="pt-3" hidden={true}>
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <Row>
                            <Col>
                              <FieldRadioButton
                                inputId="EsSiActionPlan"
                                name="ActionPlan"
                                value="Si"
                                label={t('common:Yes')}
                                onChange={(value) => onChangeEsActionPlan(value)}
                                checked={EsActionPlan}
                              >
                              </FieldRadioButton>
                            </Col>
                            <Col>
                              <FieldRadioButton
                                inputId="EsNoActionPlan"
                                name="ActionPlan"
                                value="No"
                                label={t('common:No')}
                                onChange={(value) => onChangeEsActionPlan(value)}
                                checked={!EsActionPlan}
                              >
                              </FieldRadioButton>
                            </Col>
                          </Row> 
                        </Col>
                      </Row>
                      {
                  EsActionPlan ? (
                    <>
                      <Row className="pt-3">
                        <Col>
                          <div className="p-fluid">
                            <span>{`${t('wtw:ActionPlan')}:`}</span>
                            <FieldTextArea 
                              name="PosibleSolucionProblema"
                              rows={4}
                              value={PosibleSolucionProblema}  
                              onChange={(e) => onChangePosibleSolucionProblema(e)}  
                              errors={errors} 
                              maxLength={3000}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-start">
                        <Col lg={3} md={3} sm={3} xs={3}>
                          <div className="p-fluid">
                            <span>{`${t('wtw:DueDate')}:`}</span>
                            <FieldCalendar
                              name="FechaVencimiento"
                              value={FechaVencimiento}
                              onChange={(e) => onChangeFechaVencimiento(e)}
                              errors={errors}
                            />   
                          </div>
                        </Col>
                      </Row>
                    </>
                  ):(
                    <>
                    </>
                  )
                }  
                      <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <div className='text-right'>
                            <FieldButton
                              label={t('wtw:Save')}
                              icon="pi pi-save"
                              className="p-button p-button-success"
                              onClick={() => onSave()}
                            >
                            </FieldButton>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row> 
            </>
          )
          :
          (
            <>
              <Card className='card-produccion' hidden={!IsOnSearchApplied}>
                <CardBody className="" style={{paddingTop: "5px"}}>
                  <Row className='pt-2'>
                    <Col lg={12} md={12} sm={12} xs={12}>  
                      {t('wtw:MessageNoData')}
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card className='card-produccion' hidden={IsOnSearchApplied}>
                <CardBody className="" style={{paddingTop: "5px"}}>
                  <Row className='pt-2'>
                    <Col lg={12} md={12} sm={12} xs={12}>  
                      {t('wtw:DoNotForgetClickSearch')}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </>
          )
        }
        
        

 
        

        {
        EsMostrarDialogDepartament && 
        (
          <ProductionWeeklyFollowUPDepartamento  
            Visible={EsMostrarDialogDepartament}
            onHide={()=> onHideDepartamentos()}  
            ClaUbicacion={ClaUbicacion}
            ClaAnioMes={ClaAnioMes} 
            ClaSemana={ClaSemana}  
            FechaInicio={getInformacionSemana().FechaInicio}
            FechaFin={getInformacionSemana().FechaFin}
            ClaDepartamento={ClaDepartamento}  
            ClaArea={ClaArea}
            NomArea={NomArea}
            NomUbicacion={NomUbicacion}
            NomAnioMes={NomAnioMes} 
            NomSemana={NomSemana}
            NomDepartamento={NomDepartamento}
            IdProduccionSeguimientoSemanal={IdProduccionSeguimientoSemanalDepartamento}
            Comentarios={ExplicacionProblemaDepartamento}
          >
          </ProductionWeeklyFollowUPDepartamento>
         )
      } 
        {
        EsMostrarDialogComentarios && 
        (
          <ProductionWeeklyFollowUPComentarios  
            Visible={EsMostrarDialogComentarios}
            ComentariosList={DepartamentosList}
            onHide={()=> setEsMostrarDialogComentarios(false)}  
            ClaUbicacion={ClaUbicacion}
            ClaAnioMes={ClaAnioMes} 
            ClaSemana={ClaSemana}
            NomUbicacion={NomUbicacion}
            NomAnioMes={NomAnioMes} 
            NomDepartamento={null}
            NomSemana={NomSemana}
            ClaTipo={1} 
            IsModal={true}
          >
          </ProductionWeeklyFollowUPComentarios>
        )
      } 

        {EsMostrarProduccionTendencia ? (
          <>
            <Dialog 
              visible={EsMostrarProduccionTendencia}
              header={headerDialogProductionTrendDetail} 
              draggable={false}
              modal 
              maximized={true}
              className="DailyOperationAssetDetailTree dialog-header-background-blue"
              onHide={() => setEsMostrarProduccionTendencia(false)}
              position="top"
              blockScroll={true}
            > 
              <DailyOperationProductionTrend
                visible={EsMostrarProduccionTendencia} 
                Fecha={getInformacionSemana().FechaFin}
                ClaUbicacion={ClaUbicacion}
                ClaArea={ClaArea}
                ClaCelda={null}
                ClaTurno={null}
                DisableFilters={true}
              >
              </DailyOperationProductionTrend>
            </Dialog>  
          </>
        ) : (
          <></>
        )}  
      </PageContent> 
    </>
  );
};

export default ProductionWeeklyFollowUPScreen;

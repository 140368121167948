import { React, useState, useEffect, useRef } from 'react';
import Viz from 'components/Tableau/Viz';
import { useTranslation } from 'react-i18next';
import { callApi, getSessionItem } from 'utils/utils';
import { config } from 'utils/config';

const BacklogByDivision = (props) => {

  const [viz, setViz] = useState(null);

  useEffect(() => {
    createViz();
  }, [])

  const createViz = () =>  {
    const paramsService = {};
    const urlWebService = `${config.UrlApiProject}Tableau/GetBacklogByDivisionParameters?ClaUsuario=${getSessionItem('ClaUsuarioMod', 0)}`;

    callApi(urlWebService, 'GET', paramsService, (response) => {
      // Tableau Dashboard parameters
      const params = {
        IDUSER: response.data.Params.IDUSER === null ? 0 : response.data.Params.IDUSER,
        ClaUsuario: getSessionItem('ClaUsuarioMod', 0)
      };

      // // Current Month
      // const date = new Date();
      // const [DateParam] = new Date( date.getFullYear(), date.getMonth(), 1).toISOString().split('T');
      // params.DateParameter = DateParam;

      // if (response.data.Agente !== -1) {
      //   // ClaTipoAgente
      //   const IDUSERParam = response.data.Agente.ClaTipoAgente;
      //   params.IDUSER = IDUSERParam;
        
      //   // Agent ID or FLMID
      //   if (IDUSERParam === 1) {
      //     params.AgentFLMIDParameter = response.data.Agente.ClaAgenteFLM;
      //   }
      //   else if (IDUSERParam === 2) {
      //     params.AgentIDParameter = response.data.Agente.ClaAgente;
      //   }
      // }
      // else {
      //   // Not set up in table
      //   params.IDUSER = 0;
      // }

      const url = 'https://tableau.deacero.com/views/BacklogNewDash/C1-BacklogNEWDSSW'

      setViz(<Viz url={url} parameters={params}></Viz>);
    });
  }

  return (
    <>
      <div>{viz}</div>
    </>
  )
}

export default BacklogByDivision;
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi, showSweetAlert } from '../../../utils/utils';
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon';
import moment from 'moment';
import Highcharts from 'highcharts';
import swal from '@sweetalert/with-react';
import PageContent from 'layouts/PageContent';
import { Toolbar } from 'primereact/toolbar';
import { ProgressBar } from 'primereact/progressbar';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldNumber from '../../../components/Controls/FieldNumber.jsx';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import { Dialog } from 'primereact/dialog';

const FasesProyectosPorcPesoList = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['fasesproyectos', 'common']);
  const [FasesProyectosPorcPeso, setFasesProyectosPorcPeso] = useState([]);
  const [ClaProyecto, setClaProyecto] = useState({});
  const [openFaseProyectosPorcPeso, setopenFaseProyectosPorcPeso] = useState(false);

  useEffect(() => { 
    obtenerFasesProyectosPorcPesoList(props.ClaProyecto === undefined ? 0 : props.ClaProyecto);
    setClaProyecto(props.ClaProyecto === undefined ? 0 : props.ClaProyecto);
    setopenFaseProyectosPorcPeso(props.IsVisible);
  }, [props.ClaProyecto,props.IsVisible]);

  const obtenerFasesProyectosPorcPesoList = (ClaProyecto) => {
    const GetFasesProyectosPorcPesoList = `${config.UrlApiProject}ScoreCard/GetFasesProyectosPorcPesoList?ClaProyecto=${ClaProyecto}`;
    const paramsToService = {};
    callApi(GetFasesProyectosPorcPesoList, 'POST', paramsToService, (response) => {
      setFasesProyectosPorcPeso(response.data.FasesProyectosPorcPeso);
    });
  };
  const onNomFaseChange = (props, value) => {
    const updatedFasesProyectosPorcPeso = [...props.value];
    updatedFasesProyectosPorcPeso[props.rowIndex][props.field] = value;

    setFasesProyectosPorcPeso(updatedFasesProyectosPorcPeso);
  };

  const onSaveClick = async () => {
    const fpPorcPeso = [];
    for (let index = 0; index < FasesProyectosPorcPeso.length; index++) {
      const elementrow = {
        ClaProyecto,
        PorcPeso: FasesProyectosPorcPeso[index].PorcPeso,
        ClaFase: FasesProyectosPorcPeso[index].ClaFaseGrid,
        Orden: FasesProyectosPorcPeso[index].Orden,
      };
      fpPorcPeso.push(elementrow);
    }
    const paramsServiceOwners = {
      FasesProyectoDTO: fpPorcPeso,
    };

    const urlWebServiceSaveMinutas = `${config.UrlApiProject}ScoreCard/SaveFasesProyectosPorcPeso`;

    callApi(urlWebServiceSaveMinutas, 'POST', paramsServiceOwners, (response) => {
      showSweetAlert('success', t('common:SuccessOperation'), 'success');

      if (props.onChange !== null && typeof(props.onChange) === 'function'){
        props.onChange();
      }  
    });
  };
  const NomFaseEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldNumber
          name="PorcPeso"
          value={props.rowData.PorcPeso}
          onChange={(e) => onNomFaseChange(props, e.target.value)}
          max={100}
        />
      </div>
    );
  };

  const OrdenColumnEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldNumber
          name="Orden"
          value={props.rowData.Orden}
          onChange={(e) => onOrdenChange(props, e.target.value)}
          max={999999}
        />
      </div>
    );
  };

  const onOrdenChange = (props, value) => {
    const updatedFasesProyectosPorcPeso = [...props.value];
    updatedFasesProyectosPorcPeso[props.rowIndex][props.field] = value;

    setFasesProyectosPorcPeso(updatedFasesProyectosPorcPeso);
  };

  const modalFooter = () => {
    return (
      <>
        <Button
          label={t('fasesproyectos:Save')}
          className="primary no-border rounded-button"
          onClick={() => onSaveClick()}
        />
      </>
    );
  };
  const closeModal = () => {
    setopenFaseProyectosPorcPeso(false);

    if (props.onClose !== null && typeof(props.onClose) === 'function'){
      props.onClose();
    }
  }

  const footer  = ( 
    <> 
      <Row> 
        <Column footer={() => (<b> {t('common:Total')}</b>)}></Column>  
        <Column footer={() => (<b>{ (FasesProyectosPorcPeso.reduce((a,v) =>  a += v.PorcPeso === null ? 0 : v.PorcPeso , 0 )).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) }</b>)}></Column>             
      </Row>
    </>
  )

  return (
    <>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <Dialog
            visible={openFaseProyectosPorcPeso}
            style={{ width: '80vw' }}
            footer={modalFooter}
            modal
            header={t('fasesproyectos:ActualizacionPesosFase')} 
            onHide={closeModal}
            contentStyle={{ maxHeight: '500px', overflow: 'auto' }}
          >
            <FieldDataTable
              value={FasesProyectosPorcPeso}
              scrollable
              scrollHeight="330px"
              className="editable-cells-table"
              editMode="cell"
              selectionMode="single"
              footerColumnGroup={footer}
            >
              <Column
                field="NomFaseC"
                header={t('fasesproyectos:Fase')}
                columnKey="NomFaseC"
                headerStyle={{ width: '150px' }}
              >
              </Column>
              <Column
                field="PorcPeso"
                header={t('fasesproyectos:Peso')}
                headerStyle={{ width: '75px' }}
                columnKey="PorcPeso"
                editor={(props) => NomFaseEditor(props)}
              >
              </Column>
              <Column
                field="Orden"
                header={t('fasesproyectos:Orden')}
                headerStyle={{ width: '75px' }}
                columnKey="Orden"
                editor={(props) => OrdenColumnEditor(props)}
              >
              </Column>
            </FieldDataTable>
          </Dialog>
        </Col>
      </Row>
    </>
  );
};

export default FasesProyectosPorcPesoList;

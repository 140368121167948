/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardBody, CardHeader, CardTitle, CardFooter } from 'reactstrap';
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../../utils/utils';
import * as yup from 'yup';
import YupValidator from '../../../../utils/YupValidator';
import swal from '@sweetalert/with-react';
import SweatAlertCustomIcon from '../../../../components/SweatAlert/SweatAlertCustomIcon';
import moment from 'moment';
import { Badge } from 'primereact/badge';
import Score from '../../../../components/Cards/Score/Score.jsx';
import FieldTextArea from '../../../../components/Controls/FieldTextArea.jsx';
import FieldButton from '../../../../components/Controls/FieldButton/FieldButton.jsx';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldText from 'components/Controls/FieldText';
import EditorText from 'components/Controls/EditorText/EditorText';
import { Divider } from 'primereact/divider';
import SurveyManagementQuestionEdit from './SurveyManagementQuestionEdit';
import FieldNumber from 'components/Controls/FieldNumber';
import FieldCalendar from 'components/Controls/FieldCalendar';
import FieldSelectButton from 'components/Controls/FieldSelectButton';
import FieldMultiSelect from 'components/Controls/FieldMultiSelect';

const SurveyManagementQuestionLoad = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['survey', 'common']);
  const [PageList, setPageList] = useState([]);
  const [IdEncuesta, setIdEncuesta] = useState(null);
  const [Titulo, setTitulo] = useState(null);
  const [Descripcion, setDescripcion] = useState(null);
  const [IdEncuestaPagina, setIdEncuestaPagina] = useState(null);
  const [EsHabilitarNewSurveyQuestions, setEsHabilitarNewSurveyQuestions] = useState(false);
  const [EsHabilitarEditarSurveyQuestions, setEsHabilitarEditarSurveyQuestions] = useState(false);
  const [PaginaPreguntaList, setPaginaPreguntaList] = useState([]);
  const [RespuestaPregunta, setRespuestaPregunta] = useState(null);
  const [IdEncuestaPaginaPregunta, setIdEncuestaPaginaPregunta] = useState(null);
  const [arrayRespuesPorPregunta, setarrayRespuesPorPregunta] = useState([]);
  const [arrayPreguntasPorPagina, setarrayPreguntasPorPagina] = useState([]);
  const [arrayOpcionesPorPregunta, setarrayOpcionesPorPregunta] = useState([]);
  const [OpcionesPorPreguntaList, setOpcionesPorPreguntaList] = useState([]);
  const [CamposMultiSeleccion, setCamposMultiSeleccion] = useState([]);
  const [ForceRefresh, setForceRefresh] = useState(0);

  useEffect(() => {
    setIdEncuesta(props.IdEncuesta === undefined ? null : props.IdEncuesta);
    setIdEncuestaPagina(props.IdEncuestaPagina === undefined ? null : props.IdEncuestaPagina);
    obtenerSurveyPageQuestion(props.IdEncuestaPagina, props.IdEncuesta);   
    console.log('Carga Preguntas Pagina:', props); 
  }, [props.IdEncuestaPagina, props.IdEncuesta]);

  const obtenerSurveyPageQuestion = (pIdEncuestaPagina, pIdEncuesta) => {
    const GetHRSurveyManagementQuestionForPage = `${config.UrlApiProject}HR/GetHRSurveyManagementQuestionForPage`;
    const paramsToService = {
      IdEncuesta: pIdEncuesta,
      IdEncuestaPagina: pIdEncuestaPagina,
    };
    callApi(GetHRSurveyManagementQuestionForPage, 'POST', paramsToService, (response) => {
      setPaginaPreguntaList(response.data.EncuestaPaginaPregunta);
      setOpcionesPorPreguntaList(response.data.EncuestaPaginaPreguntaOpciones);            
    });
  };

  const EditarSurveyQuestion = (element, paramindex) => {
    setEsHabilitarEditarSurveyQuestions(true);
    const arrayAuxPaginaPreguntaList = [...PaginaPreguntaList];
    arrayAuxPaginaPreguntaList.length > 0 ? (arrayAuxPaginaPreguntaList[paramindex].EsEditar = true): [];
    setPaginaPreguntaList(arrayAuxPaginaPreguntaList);
    MostrarOcultarEditQuestion(arrayAuxPaginaPreguntaList[paramindex].EsEditar);
    MostrarOcultarQuestion(arrayAuxPaginaPreguntaList[paramindex].EsEditar);
    setCamposMultiSeleccion(OpcionesPorPreguntaList.filter( opc => opc.IdEncuestaPaginaPregunta === arrayAuxPaginaPreguntaList[paramindex].IdEncuestaPaginaPregunta));    
  };

  const MostrarOcultarEditQuestion = (e) => {
    let styleShow = '';

    return styleShow = e === false ? 'none' : '';    
  }

  const MostrarOcultarQuestion = (e) => {
    let styleShow = '';

    return styleShow = e === false ? '' : 'none';    
  }

  const AddNewSurveyQuestion = () => {
    setEsHabilitarNewSurveyQuestions(true);
  };

  const OcultarEditarSurveyQuestion = () => {
    setEsHabilitarNewSurveyQuestions(false);
    props.onSearch();
  };

  const OcultarEditarSurveyPageQuestion = () => {
    setEsHabilitarEditarSurveyQuestions(false);
    setEsHabilitarNewSurveyQuestions(false);
    obtenerSurveyPageQuestion(IdEncuestaPagina, IdEncuesta);
  };

  const RecargarPaginaPreguntas = () => {
    setForceRefresh(ForceRefresh + 1);
    setEsHabilitarEditarSurveyQuestions(false);
    setEsHabilitarNewSurveyQuestions(false);
    obtenerSurveyPageQuestion(IdEncuestaPagina, IdEncuesta);
  }
  return (
    <>
      <Row>
        <Col xs={12} sm={12} ms={12} lg={12}>
          {PaginaPreguntaList.map((element, index) => {
            return (
              <div key={index}>
                <SurveyManagementQuestionEdit                  
                  IdEncuesta={IdEncuesta}                  
                  IdEncuestaPagina={IdEncuestaPagina}
                  IdEncuestaPaginaPregunta={PaginaPreguntaList[index].IdEncuestaPaginaPregunta}
                  ForceRefresh={ForceRefresh}
                  RecargarPaginaPreguntas={RecargarPaginaPreguntas}
                  EsEditar={false}
                >
                </SurveyManagementQuestionEdit>                                   
              </div>
            );
          })}
        </Col>
      </Row>

      {EsHabilitarNewSurveyQuestions ? (
        <>
          <Row>
            <Col xs={12} sm={12} ms={12} lg={12}>
              <div className="pb-3 p-mr-2">
                <SurveyManagementQuestionEdit
                  // OcultarEditarSurveyPageQuestion={OcultarEditarSurveyPageQuestion}
                  RecargarPaginaPreguntas={RecargarPaginaPreguntas}
                  IdEncuesta={IdEncuesta}
                  IdEncuestaPagina={IdEncuestaPagina}
                  EsEditar={true}
                >
                </SurveyManagementQuestionEdit>
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <></>
      )}

      <Row>
        <Col lg={12} md={12} sm={12} xs={12} className="text-center">
          <FieldButton
            label={t('survey:AddNewQuestion')}
            icon="pi pi-plus"
            className="no-border p-mr-2 rounded-button"
            onClick={AddNewSurveyQuestion}
          />
        </Col>
      </Row>
    </>
  );
};

export default SurveyManagementQuestionLoad;

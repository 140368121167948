import React from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardImg,
    CardImgOverlay,
    CardTitle,
    CardText,
    ListGroupItem,
    ListGroup,
    Row,
    Col
  } from "reactstrap";
  import { NavLink as NavLinkRRD, Link, Switch } from 'react-router-dom';
  import 'assets/css/CardFormulario.css';
// the hook
import { useTranslation } from 'react-i18next';

const CardFormulario = ({ formulario, isAvailableCheckList,claUbicacion }) => {
  const { t, i18n } = useTranslation(['formulario','common']);

  return (
    <> 
      
      <Card key={formulario.ClaFormulario}>
        <CardHeader className={formulario.HeaderClassCSS}>
          <CardTitle>{formulario.NomFormulario}</CardTitle>
        </CardHeader>    
        <CardBody> 
          <Row>
            <div className="col ml--2">
              <p className="text-sm text-muted mb-0">({formulario.TotalPreguntas}) {t('formulario:QuestionAvailable')} </p>
              <span className="text-success">●</span>
              <small>Active</small>
            </div>
            <Col className="col-auto">  
              <Link
                to={{
                        pathname: isAvailableCheckList 
                          ? "/layout/DialogFormulario" 
                          : "/layout/ResponderFormulario",
                        search: isAvailableCheckList 
                          ? `?ClaFormulario=${formulario.ClaFormulario}&ClaUbicacion=${claUbicacion}` 
                          : `?ClaFormulario=${formulario.ClaFormulario}`
                      }}
              > 
                <Button color="success" className="btn-icon btn-3" size="sm" type="button">
                  <span className="btn-inner--icon">
                    <i className="fas fa-chevron-right" />
                  </span>
                  <span className="btn-inner--text">{t('common:Start')}</span>  
                </Button>
              </Link> 
            </Col>
          </Row>
        </CardBody>
      </Card> 
    </>
  ); 
};

export default CardFormulario;
import React from 'react';
// react library for routing
import { NavLink as NavLinkRRD, Link, useHistory } from 'react-router-dom';
// nodejs library that concatenates classes
import classnames from 'classnames';
// nodejs library to set properties for components
import { PropTypes } from 'prop-types';
// react library that creates nice scrollbar on windows devices
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Collapse, NavbarBrand, Navbar, NavItem, NavLink, Nav, Button, Media, FormGroup, Input, InputGroup } from 'reactstrap';
import { setSessionData,tracker, getSessionItem, logOut } from '../../utils/utils';
import logo from '../../assets/img/MSWLogo35-small.png';
import logoSmall from '../../assets/img/MSW.png';
import team4 from '../../assets/img/default-avatar.png'; 
// reactstrap components

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      sidenavOpen: true,
      ...this.getCollapseStates(props.routes),
      filteredRoutes:null
    };
    
    this.keypressFilter = this.keypressFilter.bind(this);
    this.onLogService = this.onLogService.bind(this);
  }

  
  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) =>
    this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';

  // makes the sidenav normal on hover (actually when mouse enters on it)
  onMouseEnterSidenav = () => {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.add('g-sidenav-show');
    }
  };

  // makes the sidenav mini on hover (actually when mouse leaves from it)
  onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-show');
    }
  };

 
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };

  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false,
    });
  };

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};  
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      }
      if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  closeSidenav = () => {
    if (window.innerWidth < 1200) {
      this.props.toggleSidenav();
    }
  };

  homeScreen = () => {
    console.log('click....')
    history.push({
      pathname: `./HomeHR` 
    });
  }

  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => 
    routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        return (
          <NavItem key={key}>
            <NavLink
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={prop.isCollapsed}
              className={classnames({
                active: this.getCollapseInitialState(prop.views),
              })}
              onClick={(e) => {
                prop.isCollapsed=!prop.isCollapsed;
                this.setState({
                  isCollapsed: !prop.isCollapsed,
                });
                e.preventDefault();
              }}
            >
              {prop.icon ? (
                <>
                  <i className={prop.icon} />
                  <span className="nav-link-text">{prop.name}</span>
                </>
              ) : prop.miniName ? (
                <>
                  <span className="sidenav-mini-icon"> {prop.miniName} </span>
                  <span className="sidenav-normal"> {prop.name} </span>
                </>
              ) : (
                <>
                  <i className="" />
                  <span className="nav-link-text">{prop.name}</span>
                </>
              )}
            </NavLink>
            <Collapse isOpen={prop.isCollapsed} className="paddingLeft10">
              <Nav className="nav-sm flex-column">{this.createLinks(prop.views)}</Nav>
            </Collapse>
          </NavItem>
        );
      }
      return (
        <NavItem className={this.activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink
            to={prop.layout + prop.path}
            activeClassName=""
            onClick={() => {
              this.closeSidenav();
              setSessionData({
                url: prop.layout + prop.path,
              });
              this.onLogService(prop);
            }}
            tag={NavLinkRRD}
          >
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <span className="nav-link-text">{prop.name}</span>
              </>
            ) : prop.miniName !== undefined ? (
              <>
                <span className="sidenav-mini-icon"> {prop.miniName} </span>
                <span className="sidenav-normal"> {prop.name} </span>
              </>
            ) : (
              prop.name
            )}
          </NavLink>
        </NavItem>
      );
    });

  // toggles collapse between mini sidenav and normal
  toggleSidenav = (e) => {
    
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
    } 
    this.setState({
      sidenavOpen: !this.state.sidenavOpen,
    });
  };

  componentDidMount() {
    document.body.classList.remove('g-sidenav-pinned');
    document.body.classList.add('g-sidenav-hidden');
    document.body.classList.remove('g-sidenav-show');
  }

  searchViews(texto,views) {
    const aViews = [];
    views.forEach((item) => {
      if(item.name.toLowerCase().indexOf(texto.toLowerCase()) > -1){
        aViews.push(item);
      }else if(item.views.length>0){
          const dataItem = { ...item}; 
          let viewData = [];
          viewData = this.searchViews(texto,item.views);
          if(viewData.length>0){
            dataItem.views = viewData;
            aViews.push(dataItem);
          }
        }
    })
    return aViews;
}

keypressFilter(e){
  const result = [];  
  const {value} = e.target 
  const { routes } = this.props;
  routes.forEach((item) => {
    let views = [];
    views = this.searchViews(value,item.views);
    if(views!==undefined){
      const data = { ...item}; 
      data.views = views;
      if(views.length>0 || item.name.toLowerCase().indexOf(value.toLowerCase()) > -1){
        result.push(data);
      }
    }
  })
  this.setState({
    filteredRoutes: result,
  });  
}

onLogService(menu){
  // tracker.trackEvent({
  //   type: menu.path,
  //   action: 'Page_Load'
  // })
}

  render() {
    let { routes } = this.props;
    if(this.state.filteredRoutes != null){
       routes  = this.state.filteredRoutes;
    }
    // const { routes, logo } = this.props;
    let navbarBrandProps;
    /* if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: '_blank',
      };
    } */
    const scrollBarInner = (
      <div className="scrollbar-inner">
        <div className="sidenav-header d-flex align-items-center">
          { /* logo ? (
            <NavbarBrand {...navbarBrandProps}>
              <img alt={logo.imgAlt} className="navbar-brand-img" src={logo.imgSrc} />
            </NavbarBrand>
          ) : null */}
          {/* <NavbarBrand {...navbarBrandProps}>
            <img src={logo} alt="MSW Logo" />
          </NavbarBrand>           */}
          <img style={{marginLeft: 16}} src={logo} alt="MSW Logo" />
          {/* <div style={{marginLeft: 20}} onClick={this.toggleSidenav} className="d-lg-none d-xl-block">   */}
          <div style={{marginLeft: 20}} onClick={this.toggleSidenav} className="d-lg-none">  
            <div className={classnames('sidenav-toggler d-none d-xl-block sidenav-toggler-inner', {active: this.props.sidenavOpen,})}>
               
            </div>
            <i className="fa fa-lg fa-times d-lg-none" />            
          </div>
          {/* <div className="ml-auto">
            <div 
              style={{marginLeft: -50}}
              className={classnames('sidenav-toggler d-none d-xl-block', {
                active: this.state.sidenavOpen,
              })}
              onClick={this.toggleSidenav}
            > 
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
              </div>
            </div>
          </div> */}
        </div>
        <div className="navbar-inner">
          <Collapse navbar isOpen>
            <Media className="ml-2 d-lg-none d-sm-block">
              {/* <span className="avatar avatar-md rounded-circle d-block d-lg-none">
                <img alt="..." src={team4}  />
              </span>       
              <span className="mb-0 text-sm font-weight-bold userName d-none d-lg-block">
                Bienvenido: {getSessionItem('NomUsuario', '')}
              </span>                 */}
              {/* <Button className="btn-icon btn-2 d-sm-block" type="button" color="secondary" disabled>
                <span className="btn-inner--icon">
                  <i className="fa fa-lg fa-home" />
                </span>
              </Button>                 */}
              {/* <Button className="btn-icon btn-2" type="button" color="secondary" disabled>
                <span className="btn-inner--icon">
                  <i className="fa fa-lg fa-user" />
                </span>
              </Button>                 */}
              {/* <Button className="btn-icon btn-2" type="button" color="secondary">
                <span className="btn-inner--icon">
                  <i className="fa fa-lg fa-power-off" />
                </span>
              </Button>                 */}
            </Media>            
            <Media className="ml-2 d-lg-none d-sm-block">
              <span className="avatar avatar-md rounded-circle d-block d-lg-none" style={{marginLeft: -10}}>
                <img alt="..." src={team4}  />
              </span>       
              <span className="mb-0 text-sm font-weight-bold userName d-lg-none" style={{max: 20, marginLeft: 10}}>
                {getSessionItem('NomUsuario', '')}
              </span>    
            </Media>       
            <br />
            <Media className="ml-2 d-lg-none d-sm-block">
              <Button className="btn-icon btn-2 d-sm-block" outline type="button" color="secondary" style={{marginLeft: -25}}>
                <span className="btn-inner--icon" onClick={this.homeScreen}>
                  <i className="fa fa-lg fa-home" />
                </span>
                <span className="mb-0 text-sm font-weight-bold align-content-center userName d-lg-none" style={{max: 40, marginLeft: 10}} onClick={this.homeScreen}>
                  Home
                </span>    
              </Button>                
            </Media>            
            <FormGroup className="search-header-box d-lg-none d-sm-block">
              <InputGroup>
                <Input
                  className="header-search"
                  placeholder="Search"
                  type="text"
                  onKeyUp={this.keypressFilter}
                />
              </InputGroup>
            </FormGroup>      
            
            <FormGroup className="search-header-box d-lg-block d-sm-none d-none">
              <InputGroup className="mb-3">
                <Input
                  className="header-search"
                  placeholder="Search"
                  type="text"
                  onKeyUp={this.keypressFilter}
                />
              </InputGroup>
            </FormGroup>  

            <Nav navbar>{this.createLinks(routes)}</Nav>
                     
            <Media className="ml-2 d-lg-none d-sm-block">
              <Button 
                className="layout-topbar-action"
                type="button"
                color=" " 
                style={{marginLeft: -25}}
                href="#salir"
                onClick={logOut}
              >
                <span className="btn-inner--icon">
                  <i className="fa fa-lg fa-power-off text-danger" />
                </span>
                <span className="mb-0 text-sm font-weight-bold align-content-center userName d-lg-none" style={{max: 40, marginLeft: 10}}>
                  Cerrar sesión
                </span>                    
              </Button>                
            </Media>                        

          </Collapse>
        </div>
      </div>
    );
    return (
      <Navbar
        className={`sidenav navbar-vertical navbar-expand-xs navbar-light bg-white ${
          this.props.rtlActive ? '' : 'fixed-left'
        }`}
        onMouseEnter={this.onMouseEnterSidenav}
        onMouseLeave={this.onMouseLeaveSidenav}
      >
        {navigator.platform.indexOf('Win') > -1 ? (
          <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
        ) : (
          scrollBarInner
        )}
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
  toggleSidenav: () => {},
  sidenavOpen: false,
  rtlActive: false,
};

Sidebar.propTypes = {
  // function used to make sidenav mini or normal
  toggleSidenav: PropTypes.func,
  // prop to know if the sidenav is mini or normal
  sidenavOpen: PropTypes.bool,
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  // logo
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
  // rtl active, this will make the sidebar to stay on the right side
  rtlActive: PropTypes.bool,
};

export default Sidebar;

import SurveyExternalRespondent from './views/HR/Survey/SurveyExternalRespondent/SurveyExternalRespondent.jsx';
import VisitLogScreen from './views/HR/Survey/VisitLog/VisitLogScreen.jsx';
import VisitLogMovilScreen from './views/HR/Survey/VisitLog/VisitLogMovilScreen.jsx';
import BusinessCard from './views/HR/DigitalBusinessCards/BusinessCard.jsx';
import BusinessCardQR from './views/HR/DigitalBusinessCards/BusinessCardQR.jsx';
import AvailableCheckListByLocationScreen from './views/AvailableCheckListByLocation/AvailableCheckListByLocationScreen.jsx';
import ResponderFormulario from "./views/Formulario/ResponderFormulario.js"; 
import DialogFormulario from "./views/Formulario/DialogFormulario.jsx"; 
import MCSWNewsletterScreen from 'views/HR/Newsletter/MCSWNewsletterScreen.jsx';
import DnTNewsletterScreen from 'views/IT/DnTNewsletter/DnTNewsletterScreen.jsx';

const Components = { 
    "SurveyExternalRespondent" : SurveyExternalRespondent,
    "VisitLogScreen" : VisitLogScreen,
    "VisitLogMovilScreen" : VisitLogMovilScreen,
    "BusinessCard": BusinessCard,
    "BusinessCardQR": BusinessCardQR,
    "AvailableCheckListByLocationScreen" : AvailableCheckListByLocationScreen,
    "ResponderFormulario" : ResponderFormulario,
    "DialogFormulario" : DialogFormulario,
    "MCSWNewsletterScreen": MCSWNewsletterScreen,
    "DnTNewsletterScreen": DnTNewsletterScreen
};

export default Components;

/*
const routes = [ 
    {
        collapse: true,
        path: '/Questionarios',
        name: 'Questionaries',
        icon: 'ni ni-collection', 
        layout: '/layout',
        state: "FormularioCollapse",
        views: [ {
            path: "/Formularios",		 
            name: "Start Questionnaire",		 
            icon: " ",	
            miniName: "SQ", 
            component: Formularios,	 
            layout: "/layout",			 
        },
        {
            path: "/ResultadoFormularios",		 
            name: "Questionnaire Results",		 
            icon: " ",	
            miniName: "SQ", 
            component: ResultadoFormulario,	 
            layout: "/layout",			 
        }]
    },  
    {
        path: '/PocketMargin',
        name: 'Profitability Summary Report',
        icon: 'ni ni-chart-bar-32',
        component: PocketMarginResults,
        redirect: false,
        layout: '/layout',
        views: []
    },
    {
        path: '/ResponderFormulario',
        name: 'Start Questionnaire 2',
        icon: 'ni ni-shop text-primary',
        component: ResponderFormulario,
        redirect: true,
        layout: '/layout',
    },
    {
        path: '/FormularioResuelto',
        name: 'Questionnaire Resolved Det',
        icon: 'ni ni-shop text-primary',
        component: ResultadoFormularioDet,
        redirect: true,
        layout: '/layout',
    }  
];


export default routes;
*/
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next';  
import { Column } from 'primereact/column';
import { Row, Col , 
    ListGroupItem,
    ListGroup,
    Progress,
    Card,
    CardHeader,
    CardBody,
    CardFooter} from 'reactstrap';   
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple'; 
import { Badge } from 'primereact/badge';
import { Link } from "react-router-dom";
import { config } from '../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../utils/utils';  
import Score from '../../../components/Cards/Score/Score.jsx'
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';

const TeamPerformance = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);
  
  const defaultAnio = props.Anio  !== undefined ? props.Anio : null;
  const [TeamPerformanceInfo, setTeamPerformanceInfo] = useState([]); 

  useEffect(() => {     
    loadTeamPerformanceData();   
  }, [props.Anio]); 

  const loadTeamPerformanceData = () => {  
    const GetTeamPerformanceDevelopmentInfo = `${config.UrlApiProject}hr/GetTeamPerformanceDevelopmentInfo`;  
    const paramsToService = {   
      Anio: props.Anio  !== undefined ? props.Anio : null
    };     

    callApi(GetTeamPerformanceDevelopmentInfo, 'POST', paramsToService, (response) => { 
          
        setTeamPerformanceInfo(response.data.TeamPerformanceInfo);  
        /*
        PerformanceDevelopmentInfo: Array(1)
        0:
        Activa180DegreeReview: 1
        Anio: 2021
        Comentarios: null
        EsCompanyCultureRevisado: 0
        EsContribucionRevisado: 0
        EsEmployeeSelfReviewRevisado: 0
        EsManagerReviewRevisado: 0
        Estatus180DegreeManagerReview: 0
        Estatus180DegreeSelfReview: 0
        EstatusAnnualReview: 0
        EstatusContribucionReview: 0
        EstatusEmployeeSelfReview: 1
        EstatusManagerReview: 0
        IdPerformanceDev: 1

        */ 
    });
  }

  const Points = (rowData) => {
    return (
      <>
        <Link 
          to={{ pathname: "AnnualReview"
                              , search: `?Anio=${props.Anio}&ActiveStep=1&ClaUsuarioSeleccionado=${rowData.ClaUsuarioPerformanceDev}` 
                              }}
        >
          <Score score={rowData.Score} small severity="blue"></Score>  
        </Link>
      </>
    );
  }


  const EmployeeName = (rowData) => {
    return (
      <> 
        <Link 
          to={{ pathname: "AnnualReview"
                              , search: `?Anio=${props.Anio}&ActiveStep=0&ClaUsuarioSeleccionado=${rowData.ClaUsuarioPerformanceDev}` 
                              }}
        >
          {rowData.EmployeeName}
        </Link> 

         
      </>
    );
  }

  const EmployeeSelfReviewEstatus = (rowData) => {
    return (
      <>
        <Link 
          to={{ pathname: "AnnualReview"
                              , search: `?Anio=${props.Anio}&ActiveStep=0&ClaUsuarioSeleccionado=${rowData.ClaUsuarioPerformanceDev}` 
                              }}
        >
          { rowData.EstatusEmployeeSelfReview === 1 && rowData.ActivaEmployeeSelfReview 
            ?  <i className="fa fa-check light-green-color"></i>
            : 
              rowData.ActivaEmployeeSelfReview === 1 ? 
                <i className="fa fa-unlock primary-color"></i>  
              : 
                <i className="fa fa-lock warning-color"></i>}
         
          
         

        
        
        </Link> 

        
      </>
    );
  }

  const EstatusContribucionReview = (rowData) => {
    return (
      <>
        <Link 
          to={{ pathname: "AnnualReview"
                              , search: `?Anio=${props.Anio}&ActiveStep=1&ClaUsuarioSeleccionado=${rowData.ClaUsuarioPerformanceDev}` 
                              }}
        > 
          { rowData.EstatusContribucionReview === 1 && rowData.ActivaEmployeeSelfReview 
            ?  <i className="fa fa-check light-green-color"></i>
            : 
              rowData.ActivaEmployeeSelfReview === 1 ? 
                <i className="fa fa-unlock primary-color"></i>  
              : 
                <i className="fa fa-lock warning-color"></i>}
        </Link>

        
      </>
    );
  }

  const EstatusManagerReview = (rowData) => {
    return (
      <>
        <Link 
          to={{ pathname: "AnnualReview"
                              , search: `?Anio=${props.Anio}&ActiveStep=2&ClaUsuarioSeleccionado=${rowData.ClaUsuarioPerformanceDev}` 
                              }}
        > 
          { rowData.EstatusManagerReview === 1 && rowData.ActivaManagerReview 
            ?  <i className="fa fa-check light-green-color"></i>
            : 
              rowData.ActivaManagerReview === 1 ? 
                <i className="fa fa-unlock primary-color"></i>  
              : 
                <i className="fa fa-lock warning-color"></i>}
        </Link>

        
      </>
    );
  }

  const EstatusAnnualReview = (rowData) => {
    return (
      <>
        <Link 
          to={{ pathname: "AnnualReview"
                              , search: `?Anio=${props.Anio}&ActiveStep=3&ClaUsuarioSeleccionado=${rowData.ClaUsuarioPerformanceDev}` 
                              }}
        > 
          { rowData.EstatusAnnualReview === 1 && rowData.ActivaAnnualReview 
            ?  <i className="fa fa-check light-green-color"></i>
            : 
              rowData.ActivaAnnualReview === 1 ? 
                <i className="fa fa-unlock primary-color"></i>  
              : 
                <i className="fa fa-lock warning-color"></i>}
        </Link>

        
      </>
    );
  }

  const reviewedMark = (
    <><i className="fa fa-check"></i></>
  );

  const unReviewedMark = (
    <><i className="fa fa-times"></i></>
  );


  const header = (
    <>&nbsp;</>
  );

  return (
    <>    
      <Card> 
        <CardBody>
          <h1>{t('hr:TeamPerformance')}</h1> 
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>   
              <FieldDataTable 
                value={TeamPerformanceInfo}   
                scrollable  
                className="p-datatable-striped"  
                selectionMode="single"  
                metaKeySelection={false}
                header={header} 
              >    
                <Column header="" headerStyle={{ width: '38px' }} body={Points}></Column>  
                <Column header={t('hr:EmployeeName')} headerStyle={{ width: '172px' }} className="text-left" body={EmployeeName}></Column>  
                <Column header={t('hr:EmployeeSelfReviewShort')} className="text-center" headerStyle={{ width: '110px' }} body={EmployeeSelfReviewEstatus}></Column>  
                <Column header={t('hr:ScorecardReview')} className="text-center" body={EstatusContribucionReview} headerStyle={{ width: '100px' }}></Column>  
                <Column header={t('hr:ManagerReviewShort')} className="text-center" body={EstatusManagerReview} headerStyle={{ width: '100px' }}></Column>  
                <Column header={t('hr:AnnualReviewShort')} className="text-center" body={EstatusAnnualReview} headerStyle={{ width: '100px' }}></Column>  
              
              </FieldDataTable>  
            </Col>
          </Row>
        </CardBody>
      </Card>  
    </>  
        ); 
};

export default TeamPerformance;

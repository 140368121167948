import { Skeleton } from 'primereact/skeleton';
import React from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Row } from 'reactstrap';

const DailyDowntimeSummaryTrendChartSkeleton = (props) => {
  const {t, i18n} = useTranslation(['companyOverview', 'common']);  

  const summaryTemplate = (
    <>
      <h5>
        {t('companyOverview:Utilization')}
      </h5>      
      <h1>
        <Skeleton width="5rem" height="2rem" className="p-mb-2" />
      </h1>
      {/* <p>
        <Skeleton width="3rem" className="p-mb-2 mt-3" borderRadius="16px" />
      </p> */}
    </>
  );

  const chartTemplate = (
    <>
      <Skeleton width="100%" height="12rem" />
    </>
  );

  return (
    <>
      <Card style={{borderRadius: '1%'}}>
        <CardBody>
          <Row className='align-items-start'>
            <Col xs={2}> 
              {summaryTemplate}
            </Col>
            <Col xs={10}>
              {chartTemplate}                     
            </Col>
          </Row>
        </CardBody>
      </Card>   
    </>
  )
}

export default DailyDowntimeSummaryTrendChartSkeleton
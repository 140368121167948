
import { Skeleton } from 'primereact/skeleton';
import React from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Row } from 'reactstrap';

const CompanyOverviewProductionRateChartSkeleton = (props) => {
  const {t, i18n} = useTranslation(['companyOverview', 'common']);  

  const summaryTemplate = (
    <>
      <Row>
        <Col xs={4}>
          <h5>
            {t('companyOverview:ProductionRate')}
          </h5>              
        </Col>
        <Col xs={5}>          
          {/* <Skeleton height="2rem" className="p-mb-2" /> */}
        </Col>        
        <Col xs={3}>
          {/* <Skeleton width="3rem" className="p-mb-2 mt-3" borderRadius="16px" /> */}
        </Col>
      </Row>    
    </>
  );

  const chartTemplate = (
    <>
      <Skeleton width="100%" height="10rem" />     
    </>
  );

  return (
    <>
      <Card style={{borderRadius: '1%'}}>
        <CardBody>
          <Row className='align-items-start'>
            <Col> 
              {summaryTemplate}
            </Col>            
          </Row>
          <Row className='align-items-start'>            
            <Col>
              {chartTemplate}                     
            </Col>
          </Row>
        </CardBody>
      </Card>   
    </>
  )
}

export default CompanyOverviewProductionRateChartSkeleton
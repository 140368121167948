/* eslint-disable radix */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable object-shorthand */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'primereact/column';
import { Row,Col,Card, CardBody, CardHeader, CardTitle, CardFooter} from 'reactstrap'; 
import { config } from '../../../utils/config';
import { callApi, showSweetAlert, getSessionItem } from '../../../utils/utils';
import Highcharts from 'highcharts';
import SparkLine from '../../../components/Charts/SparkLine/SparkLine.jsx'
import HighchartsReact from 'highcharts-react-official';
import new_customer from '../../../assets/img/icons/new_customer.png'
import active_customer from '../../../assets/img/icons/active_customer.png'
import inactive_customer from '../../../assets/img/icons/inactive_customer.png'
import truck_load_promised from '../../../assets/img/icons/truck_load_promised.png'
import customer_service_operations from '../../../assets/img/icons/customer_service_operations.png'


import avatar1 from '../../../assets/img/icons/avatar-1.png'
import avatar2 from '../../../assets/img/icons/avatar-2.png'
import avatar3 from '../../../assets/img/icons/avatar-3.png'
import avatar4 from '../../../assets/img/icons/avatar-4.png'  
import { DataTable } from 'primereact/datatable';
import { ColumnGroup } from 'primereact/columngroup';
import moment from "moment";
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import FieldDropdown from 'components/Controls/FieldDropdown';
import { row } from 'mathjs';
import FieldTag from 'components/Controls/FieldTag';
import SalesDashboardDetailInvoices from './SalesDashboardDetailInvoices';
import { Dialog } from 'primereact/dialog';
import Joyride, { CallBackProps, STATUS, Step, StoreHelpers } from 'react-joyride'; 
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import InstructionsCard from 'components/Cards/InstructionsCard/InstructionsCard';
import SalesDashboardAccountSummary from './SalesDashboardAccountSummary';
import FieldSelectButton from 'components/Controls/FieldSelectButton';


const SalesDashboard = ({ ClaUsuario }) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['salesDashboard', 'common']);
  const [salesDashboardInfo, setsalesDashboardInfo] = useState({}); 
  const [tonsSold, settonsSold] = useState({}); 
  const [revenue, setrevenue] = useState({}); 
  const [tonsPlanned, settonsPlanned] = useState({}); 
  const [todaySold, settodaySold] = useState({}); 
  const [tonsBySegmentList, settonsBySegmentList] = useState([]); 
  const [spread, setspread] = useState({}); 
  const [customer, setcustomer] = useState({}); 
  const [promisedInfo, setpromisedInfo] = useState({}); 
  const [myTeamList, setmyTeamList] = useState({}); 
  const [currentWeek, setcurrentWeek] = useState({}); 
  const [chartOptions, setchartOptions] = useState({}); 
  const [chartRevenue, setchartRevenue] = useState({}); 
  const [chartSpread, setchartSpread] = useState({}); 
  const [chartCurrentMonth, setchartCurrentMonth] = useState({}); 
  const [chartCurrentWeek, setchartCurrentWeek] = useState({}); 
  const [chartTonsBySegment, setchartTonsBySegment] = useState({}); 
  const [myTeam, setmyTeam] = useState([]);
  const [IsLoading, setIsLoading] = useState(true);
  const [byStatisticalGpo2List, setbyStatisticalGpo2List] = useState([]); 
  const [byCustomerAccountList, setbyCustomerAccountList] = useState([]); 
  const [TonsByCategoryList, setTonsByCategoryList] = useState([
    {
      ClaCategoria: 1,
      NomCategoria: 'Segment'
    },
    {
      ClaCategoria: 2,
      NomCategoria: 'Statistical Gpo 3'
    },
    {
      ClaCategoria: 3,
      NomCategoria: 'Customer (Account)'
    }
  ]);
  const [ClaCategoria, setClaCategoria] = useState(1);
  const [EsMostrarDetalleFacturacion, setEsMostrarDetalleFacturacion] = useState(false);
  const [AnioMes, setAnioMes] = useState(null);
  const [FechaSeleccionada, setFechaSeleccionada] = useState(null);
  const [EsShowNewOrder, setEsShowNewOrder] = useState(false);
  const [EstatusTipoCliente, setEstatusTipoCliente] = useState(null);
  const [TipoDetalleInformacion, setTipoDetalleInformacion] = useState(null);
  const [IsRunTour, setIsRunTour] = useState(false); 
  const [EsMostrarAccountSummary, setEsMostrarAccountSummary] = useState(false);
  
  const [TipoObjetivo, setTipoObjetivo] = useState(1);
  const tipoObjetivoFilterOptions = [
      {name: t('salesDashboard:PVO'), value: 1},
      {name: t('salesDashboard:Compensation'), value: 2}      
  ];

  const onStartTour = () =>{ 
    setIsRunTour(true);
  }

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setIsRunTour(false);
    } 
  }; 


  useEffect(() => { 
      obtieneSalesDashboard(TipoObjetivo); 
    
    /* 
     obtieneSalesDashboardInfo();
     obtieneSalesDasboardInfo2();
      setmyTeam([
      {name: 'Madison Huges',"email":"madison@gmail.com", "avatar":"avatar-1.png",  planned: 105, sold: 90, promised: 10, esPositivo:1,porcentaje:"31.8"},
      {name: 'Joseph Wiliams',"email":"joseph@gmail.com", "avatar":"avatar-2.png",  planned: 70, sold: 50, promised: 10, esPositivo:1,porcentaje:"10.5"},
      {name: 'Emily Clark',"email":"emily@gmail.com", "avatar":"avatar-3.png",  planned: 50, sold: 50, promised: 0, esPositivo:1,porcentaje:"1.2"},
      {name: 'Tim Jonshon',"email":"tim@gmail.com", "avatar":"avatar-4.png",  planned: 150, sold: 200, promised: 25,esPositivo:0,porcentaje:"8.5"},
    ]);
  */
  }, [ClaUsuario]);
 
  Highcharts.wrap(Highcharts.Series.prototype, 'drawPoints', function(p) {
    const {options} = this;
    const symbolCallback = options.marker && options.marker.symbolCallback;
    const {points} = this;
    if (symbolCallback && points.length) {
      points.forEach(point => {
        const symbol = symbolCallback.call(point);
        if (symbol) {
          point.update({
            marker: {
                fillColor: symbol
            }
          }, false)
        }
      })
    }
    p.call(this);
  })

 const obtieneSalesDashboard = (tipoObjetivo) => {
    const urlGetSalesDashboard = `${config.UrlApiProject}SalesPerformanceSales/GetSalesWTWDashboard?ClaUsuarioSalesRep=${ ClaUsuario === null || typeof(ClaUsuario) === 'undefined' ? '' : ClaUsuario}&TipoObjetivo=${ tipoObjetivo === null || typeof(tipoObjetivo) === 'undefined' ? '1' : tipoObjetivo}`; 
    const paramsToService = {};
    callApi(urlGetSalesDashboard, 'GET', paramsToService, (response) => {
      console.log('response.data',response.data);
      const dataSalesDashboardInfo = response.data.SalesDashboardInfo[0];
      setAnioMes(dataSalesDashboardInfo.AnioMes);

      // const dataTonsSoldStadistic = response.data.TonsSoldSerie[0];
      // const dataRevenueStadistic = response.data.RevenueSerie[0];
      const dataCurrentMonthCategories = response.data.CurrentMonthCategories;
      const dataCurrentMonthSeries = response.data.CurrentMonthSeries;
      
      // const dataCurrentWeekCategories = response.data.CurrentWeekCategories;
      // const dataCurrentWeekSeries = response.data.CurrentWeekSeries;
      const dataBySegmentCategories = response.data.BySegmentCategories;
      const dataBySegmentSeries = response.data.BySegmentSeries;
      const dataBySegmentList = response.data.BySegmentList;
      // const dataSpreadInfoSerie = response.data.SpreadInfoSerie[0];
      const dataMyTeamList = response.data.MyTeamList;
       
      // TONS SOLD
      setsalesDashboardInfo(dataSalesDashboardInfo);
      /*
      if(dataTonsSoldStadistic!==undefined){
        setchartOptions({
          xAxis: { 
              categories: []
          },
          series: [{ 
            name: '',
            data: dataTonsSoldStadistic.data 
          } 
        ],
          tooltip: {
            headerFormat: `<span style="font-sze:10px"><b> Tons </b> <br/> <b>{point.x}</b> </span>`,
          },
          chart: {
            type: 'area'
          }
        })
      } 
      */
       
      // TONS PLANNED
      // CURRENT MONTH
      setchartCurrentMonth({
        chart: { 
          height: '348px'
        },
        title: {
          align: 'center',
          text: ''
        },
        subtitle: {
          align: 'left',
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          title: {
            text: 'Days'
          },
          categories: dataCurrentMonthCategories,
          plotLines: [{
            color: '#FF0000', // Red
            dashStyle: 'dash',
            width: 1,
            value: parseInt(moment(new Date()).format('DD'))-1 // Position, you'll have to translate this to the values on your x axis
          }]
        },
        yAxis: {
          title: {
            text: 'Tons'
          }
        }, 
        legend: {
          enabled: true
        },
        tooltip: {
          headerFormat: ' ',
          pointFormat: '<span>{series.name}</span>: <b>{point.y}'
        },
        series: [
          { 
            type: 'area',
            name: 'Goal',
            data: dataCurrentMonthSeries[0].data,  
            showInLegend: true, 
            color: dataCurrentMonthSeries[0].color,
            dataLabels:{
                enabled:false,  
                formatter: function() {  
                    const { x, y, point, series } = this; 
                    return `${Highcharts.numberFormat(y,0,'.',',')  }`;
                  }
            },
            marker: {
              enabled: false
            }
          },
          {
            type: 'line',
            name: 'Invoiced',
            data: dataCurrentMonthSeries[1].data,   
            color: dataCurrentMonthSeries[1].color,
            showInLegend: true, 
            dataLabels:{
                enabled:true,  
                formatter: function() {  
                    const { x, y, point, series } = this; 
                    return `${Highcharts.numberFormat(y,0,'.',',')  }`;
                  }
            },
            marker: { 
                fillColor: 'white',
                lineWidth: 2,
                lineColor: dataCurrentMonthSeries[1].color,
                symbolCallback: function() { 
                  let budget = null;
                   
                  // console.log('symbollCallback', '');

                  if (dataCurrentMonthSeries[0].data.length > 0) {
                    budget = dataCurrentMonthSeries[0].data[this.index];
                  } 
   
                  if (this.y < budget){ 
                      return 'red';
                  }

                  if (this.y >= budget){ 
                      return 'MediumSeaGreen';
                  } 
  
                   
  
                  return 'white';
                }   
            },
            cursor: 'pointer', 
            point: {
                events: {
                    click: function() {
                        onCurrentMonthDailyInfoClick('Invoiced', this.category, dataSalesDashboardInfo.AnioMes);
                    }
                }
            } 
          },
          {
            type: 'line',
            name: 'New Orders',
            data: dataCurrentMonthSeries[2].data,   
            color: dataCurrentMonthSeries[2].color,
            showInLegend: true, 
            dataLabels:{
                enabled:false,  
                formatter: function() {  
                    const { x, y, point, series } = this; 
                    return `${Highcharts.numberFormat(y,0,'.',',')  }`;
                  }
            },
            marker: {
              enabled: false
            },
            cursor: 'pointer', 
            point: {
                events: {
                    click: function() {
                        onCurrentMonthDailyInfoClick('NewOrders', this.category, dataSalesDashboardInfo.AnioMes);
                    }
                }
            }  
          }
        ]
      });
      
 
      if(dataBySegmentCategories!==undefined){
      
        // TONS BY SEGMENT
      setchartTonsBySegment({
        chart: {
          type: 'bar',
          height: '60px' 
        },
        title: {
          align: 'center',
          text: ''
        }, 
        xAxis: {
          visible:false,
          categories: dataBySegmentCategories
        },
        yAxis: { 
          visible:false
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          series: {
              stacking: 'normal',
              dataLabels: {
                  enabled: false
              }
          }
      },
        tooltip: {
          enabled: true,
          headerFormat: '<span style="font-size:11px">Segment</span><br>',
          pointFormat: '<span style="color:{point.color}">{series.name}</span>: <b>{point.y}'
        },
        series: dataBySegmentSeries
      });

      }

      
      settonsBySegmentList(dataBySegmentList);
       
      setmyTeamList(dataMyTeamList);
      setbyStatisticalGpo2List(response.data.ByStatisticalGpo3); 
      setbyCustomerAccountList(response.data.ByCustomerAccount); 
      setIsLoading(false);
      // setIsRunTour(true);
    });
  }; 


  const NameTemplate = (rowData) => {
  
    return (
      <>
        <table width="100%">
          <tbody>
            <tr> 
              <td>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        {rowData.Name}
                        <div>
                          <small>
                            {rowData.Email}
                          </small>
                        </div>
                      </td>
                    </tr> 
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }    

  const ResultadoTemplate = (rowData)=>{

    if (rowData.MontoResultado === null){
      return (<></>)
    }

  return (
    rowData.EsArribaPresupuesto === 1 ? (
      <div className='text-right pr-2'>
        <div className='success-color'>
          {rowData.MontoResultado}% <i className='pi pi-arrow-up small'></i>
          &nbsp;
          <i className='fas fa-info-circle info-color' title='Percentage of completion vs objective of daily tons'></i>
        </div>
      </div>
    ):(
      <div className='text-right pr-2'>
        <div className='danger-color'>
          {rowData.MontoResultado}% <i className='pi pi-arrow-down small'></i>
          &nbsp;
          <i className='fas fa-info-circle info-color' title='Percentage of completion vs objective of daily tons'></i>
        </div> 
      </div>
    )
  );
}

const plannedBodyTemplate = (rowData) => {
    return `${Highcharts.numberFormat(rowData.Planned,0,'.',',')}`;
}

const soldBodyTemplate = (rowData) => {
    return Highcharts.numberFormat(rowData.Sold,0,'.',',') ;
}

const promisedBodyTemplate = (rowData) => {
    return Highcharts.numberFormat(rowData.Promised,0,'.',',') ;
} 

const EstatusCUCTemplate = (rowData) =>{
  return (
    <>
      {
    rowData.EstatusTipoCliente === 1 &&
    (
      <>  
        <FieldTag 
          label="New"
          className="success small-tag"  
        /> 
      </>
      )
    }
      {
    rowData.EstatusTipoCliente === 2 &&
    (
      <>  
        <FieldTag 
          label="Active"
          className="secondary small-tag"   
        /> 
      </>
      )
    }
      {
    rowData.EstatusTipoCliente === 3 &&
    (
      <>  
        <FieldTag 
          label="Recovered"
          className="info small-tag"  
        /> 
      </>
      )
    }
    </>
)
}

const NomCUCTemplate = (rowData) => {
  /*
	EstatusTipoCliente
	1 = Nuevo
	2 = Activo
	3 = Recuperado
	4 = Inactivo
  */

  return (
    <> 
      {rowData.NomCUC}
    </>
);
}

const ClaCUCTemplate = (rowData) => {
  return <>{rowData.ClaCuC}</>;
}

const NomGrupoEstadistico3Template = (rowData) => {
  return <>{rowData.NomGrupoEstadistico3}</>;
}

const MTTonsTemplate = (rowData) => {
  return `${Highcharts.numberFormat(rowData.MTTons,0,'.',',')}`;
}

const NeededTonsThisMonthTemplate = (rowData) => {
  return `${Highcharts.numberFormat(rowData.NeededTonsThisMonth,0,'.',',')}`;
}

const PromiseTonsThisMonthTemplate = (rowData) => {
  return `${Highcharts.numberFormat(rowData.PromiseTonsThisMonth,0,'.',',')}`;
}

const AvgMTTonsTemplate = (rowData) => {
  return `${Highcharts.numberFormat(rowData.MTtonsPromedioMensual,0,'.',',')}`;
}

const DiffTemplate = (rowData) => {
  const diferencia = rowData.MTtonsPromedioMensual - rowData.MTTons;
 
  if (diferencia <=0 ){
    return (<><span className='success-color'>{Highcharts.numberFormat(diferencia,0,'.',',')}</span></>)
  }

  return `${Highcharts.numberFormat(diferencia,0,'.',',')}`;
}

const SpreadTemplate = (rowData) => {
  return `${Highcharts.numberFormat(rowData.Spread,0,'.',',')}`;
}


const PriceByTonTemplate = (rowData) => {
  return `${Highcharts.numberFormat(rowData.PriceByTon,0,'.',',')}`;
}

/*
	EstatusTipoCliente
	1 = Nuevo
	2 = Activo
	3 = Recuperado
	4 = Inactivo
  */

const onClickInvoicedNewAccountsTons = () =>
{ 
  // setAnioMes(AnioMes);
  setTipoDetalleInformacion(null);
  setEsShowNewOrder(false);
  setFechaSeleccionada(null);
  setEstatusTipoCliente(1);
  setEsMostrarDetalleFacturacion(true);
}

const onClickInvoicedActiveAccountsTons = () =>
{ 
  // setAnioMes(AnioMes);
  setTipoDetalleInformacion(null);
  setEsShowNewOrder(false);
  setFechaSeleccionada(null);
  setEstatusTipoCliente(2);
  setEsMostrarDetalleFacturacion(true);
}

const onClickInvoicedRecoverAccountsTons = () =>
{ 
  // setAnioMes(AnioMes);
  setTipoDetalleInformacion(null);
  setEsShowNewOrder(false);
  setFechaSeleccionada(null);
  setEstatusTipoCliente(3);
  setEsMostrarDetalleFacturacion(true);
}

const onClickTodayInvoicedTons = () =>
{ 
  // setAnioMes(AnioMes);
  setTipoDetalleInformacion(null);
  setEsShowNewOrder(false);
  setEstatusTipoCliente(null);
  setFechaSeleccionada(salesDashboardInfo.DateToday);
  setEsMostrarDetalleFacturacion(true);
}

const onClickInvoicedTons = () =>
{ 
  // setAnioMes(AnioMes);
  setTipoDetalleInformacion(null);
  setEsShowNewOrder(false);
  setFechaSeleccionada(null);
  setEstatusTipoCliente(null);
  setEsMostrarDetalleFacturacion(true);
}

const onCurrentMonthDailyInfoClick = (Tipo, Category, anioMes) => {

  // console.log('Tipo', Tipo);
  // console.log('Category', Category);
  // console.log('AnioMes', anioMes); 
  
  setTipoDetalleInformacion(null);
  setEstatusTipoCliente(null);
  setFechaSeleccionada(new Date(parseInt(anioMes)/100, (parseInt(anioMes)%100)-1, parseInt(Category)));
  setEsShowNewOrder(Tipo==='NewOrders');
  setEsMostrarDetalleFacturacion(true); 

}

const onClickDueNeedTons = () =>
{ 
  // setAnioMes(AnioMes);
  setTipoDetalleInformacion(1);
  setEsShowNewOrder(false);
  setFechaSeleccionada(null);
  setEstatusTipoCliente(null);
  setEsMostrarDetalleFacturacion(true);
}

const onClickNeededTons = () =>
{ 
  // setAnioMes(AnioMes);
  setTipoDetalleInformacion(2);
  setEsShowNewOrder(false);
  setFechaSeleccionada(null);
  setEstatusTipoCliente(null);
  setEsMostrarDetalleFacturacion(true);
}

const onClickSoldPlusNeededTons = () =>
{ 
  // setAnioMes(AnioMes);
  setTipoDetalleInformacion(3);
  setEsShowNewOrder(false);
  setFechaSeleccionada(null);
  setEstatusTipoCliente(null);
  setEsMostrarDetalleFacturacion(true);
}

const onClickOpportunityDuePromiseTons = () =>
{ 
  // setAnioMes(AnioMes);
  setTipoDetalleInformacion(4);
  setEsShowNewOrder(false);
  setFechaSeleccionada(null);
  setEstatusTipoCliente(null);
  setEsMostrarDetalleFacturacion(true);
}

const onClickOpportunityPromiseForNextMonthsTons = () =>
{ 
  // setAnioMes(AnioMes);
  setTipoDetalleInformacion(5);
  setEsShowNewOrder(false);
  setFechaSeleccionada(null);
  setEstatusTipoCliente(null);
  setEsMostrarDetalleFacturacion(true);
}

const onClickOONoPromiseDateNeeded = () =>
{ 
  // setAnioMes(AnioMes);
  setTipoDetalleInformacion(6);
  setEsShowNewOrder(false);
  setFechaSeleccionada(null);
  setEstatusTipoCliente(null);
  setEsMostrarDetalleFacturacion(true);
}

const onClickOONoPromiseDate = () =>
{ 
  // setAnioMes(AnioMes);
  setTipoDetalleInformacion(7);
  setEsShowNewOrder(false);
  setFechaSeleccionada(null);
  setEstatusTipoCliente(null);
  setEsMostrarDetalleFacturacion(true);
}

const onClickActiveAccountsSummary = () =>
{ 
  // setAnioMes(AnioMes); 
  setEstatusTipoCliente(2); // Activos
  setEsMostrarAccountSummary(true);
}

const onClickInactiveAccountsSummary = () =>
{ 
  // setAnioMes(AnioMes); 
  setEstatusTipoCliente(4); // Inactivos
  setEsMostrarAccountSummary(true);
}

const onChangeTipoObjetivo = (e) => {
  const tipoObjetivoFilterOptionso = e.value;    
  if (tipoObjetivoFilterOptionso !== null) {
    setTipoObjetivo(tipoObjetivoFilterOptionso); 
    obtieneSalesDashboard(tipoObjetivoFilterOptionso);
    // refresh
  }
};


const myTeamHeaderGroup = (
  <ColumnGroup>
    <Row>
      <Column header="" className="table-header-background-dark-green" rowSpan={3} style={{width:'250px'}} />
    </Row>
    <Row>
      <Column header="TONS" colSpan={3} style={{fontWeight:'bold',textAlign:'center'}} headerStyle={{textAlign:'center'}} />
      <Column header="" className="table-header-background-dark-green" />
    </Row>
    <Row>
      <Column header="Goal" field="planned" style={{textAlign:'center'}} headerStyle={{textAlign:'center'}} />
      <Column header="Invoiced" field="sold" style={{textAlign:'center'}} headerStyle={{textAlign:'center'}}  />
      <Column header="Needed" field="promised" style={{textAlign:'center'}} headerStyle={{textAlign:'center'}}  />
      <Column header="Progress" field="resultado" style={{textAlign:'center'}} className="table-header-background-dark-green" />
    </Row>
  </ColumnGroup>
);

const myTeamFooterGroup = (
  <ColumnGroup>
    <Row>
      <Column footer="" />
      <Column footer="" style={{textAlign:'center'}} />
      <Column footer="" style={{textAlign:'center'}} />
      <Column footer="" style={{textAlign:'center'}} />
      <Column footer="" style={{textAlign:'right'}} /> 
    </Row>
  </ColumnGroup>
);

const StepsTourPocketMargin = {
  steps: [ 
    {
      target: '.WTW-Step-2',
      disableBeacon: true,
      content: (
        <>
          <div className='text-left'>
            <span className='h3'>Invoiced Tons</span>
          </div>
          <div className='pt-3 text-left'>
            <span className='tour-info'>
              {
                salesDashboardInfo.TonsSoldEsArribaPresupuesto>0
              ?
              (<>You are currently at <b>{Highcharts.numberFormat(salesDashboardInfo.TonsSold,0,'.',',') }</b> tons. You are doing great and above the mark by <b>{Highcharts.numberFormat(salesDashboardInfo.TonsSold-salesDashboardInfo.TonsSoldExpectedCurrentDay,0,'.',',') }</b> tons! Keep up the good work!</>)  
              :
              (
                <>You are currently at <b>{Highcharts.numberFormat(salesDashboardInfo.TonsSold,0,'.',',') }</b> tons, which is <b>{Highcharts.numberFormat(salesDashboardInfo.TosnSoldPercentageVsCurrentDay,2,'.',',') }%</b> below your goal of <b>{Highcharts.numberFormat(salesDashboardInfo.TonsSoldExpectedCurrentDay,0,'.',',') }</b> tons as of today. You are missing the mark by {Highcharts.numberFormat(salesDashboardInfo.TonsSoldExpectedCurrentDay-salesDashboardInfo.TonsSold,0,'.',',') } tons.
                </>
              )
              }       
            </span>
          </div>  
        </>
      )
    } ,
    {
      target: '.WTW-Step-3',
      content: (
        <>
          <div className='text-left'>
            <span className='h3'>Goal Tons</span>
          </div>
          <div className='pt-3 text-left'>
            <span className='tour-info'>
              <div>
                To meet your target of <b>{Highcharts.numberFormat(salesDashboardInfo.MonthValue,0,'.',',')}</b> tons this month, we need to invoice about <b>{Highcharts.numberFormat(salesDashboardInfo.DailyGoal,0,'.',',')}</b> tons per day. 

              </div>
              {
              salesDashboardInfo.DailyGoalIncreasedEsArribaPresupuesto > 0
              ? 
              (
                <>
                  <br></br>
                  <div>
                    However, we need to increase our daily goal by <b className='danger-color'>{Highcharts.numberFormat(salesDashboardInfo.DailyGoalIncreasedPercentage,2,'.',',')}%</b> to invoice <b>{Highcharts.numberFormat(salesDashboardInfo.DailyGoalIncreased,0,'.',',')}</b> tons per day for the rest of the month.
                  </div>
                </>
              )
              :
              (<></>)
              }
            </span>
          </div>  
        </>
      )
    },
    {
      target: '.WTW-Step-4',
      content: (
        <>
          <div className='text-left'>
            <span className='h3'>Current Month - Daily invoiced tons Vs Daily Goal</span>
          </div>
          <div className='pt-3 text-left'>
            <span className='tour-info'>
              <div>
                This chart presents a comparison of three key data series for the current month:
                <br></br>
                <br></br>
                <ul>
                  <li>
                    <b>Goal</b>
                    <div>Track the daily goal for tonnage, estimated based on working days of this month. This provides a benchmark to gauge performance.</div>
                  </li>
                  <li>
                    <b>Invoiced</b>
                    <div>Visualize the daily tons invoiced. By clicking on a marker, you can access a detailed list of each invoice considered for that specific day.</div>
                  </li>
                  <li>
                    <b>New Orders</b>
                    <div>View the tons entered per day, based on the date the order was placed. Clicking on a marker allows you to explore a comprehensive list of each order entered on the selected day.</div>
                  </li>
                </ul> 
                <br></br>
                <i>Note: The vertical red dashed line indicates the current day of the month, offering a reference point for real-time analysis.</i>
              </div> 
            </span>
          </div>  
        </>
      )
    },
    {
      target: '.WTW-Step-5',
      content: (
        <>
          <div className='text-left'>
            <span className='h3'>Accounts behavior this month</span>
          </div>
          <div className='pt-3 text-left'>
            <span className='tour-info'>
              <div>
                This comprehensive chart allows you to analyze three distinct series related to accounts behavior for the current month:
                <br></br>
                <br></br>
                <ul>
                  <li>
                    <b>New</b>
                    <div>This category represents accounts for which we have invoiced something for the first time this month.</div>
                  </li>
                  <li>
                    <b>Recovered</b>
                    <div>These accounts demonstrate invoicing activity this month after a period of inactivity lasting three months. It highlights accounts that have re-engaged with our services or products.</div>
                  </li>
                  <li>
                    <b>Recurrent</b>
                    <div>Accounts that have been invoiced this month and have displayed invoicing activity at least once in the past three months. It reflects the consistent engagement of long-standing customers.</div>
                  </li>
                </ul> 
                <br></br>
                <i>Note: Clicking on the respective number will grant you access to a detailed list of all invoices associated with that particular category. This enables you to delve deeper into the invoicing details and gain a comprehensive understanding of account behavior.</i>
              </div> 
            </span>
          </div>  
        </>
      )
    },
    {
      target: '.WTW-Step-6',
      content: (
        <>
          <div className='text-left'>
            <span className='h3'>Total Accounts</span>
          </div>
          <div className='pt-3 text-left'>
            <span className='tour-info'>
              <div> 
                <ul>
                  <li>
                    <b>Active</b>
                    <div>Total number of accounts that have had at least one invoicing event within the past three months.</div>
                  </li>
                  <li>
                    <b>Inactive</b>
                    <div>Total accounts without any invoicing in the last 3 months. This analysis helps us understand the engagement level of our accounts and identify potential areas for re-engagement or customer retention strategies..</div>
                  </li> 
                </ul>  
              </div> 
            </span>
          </div>  
        </>
      )
    },
    {
      target: '.WTW-Step-7',
      content: (
        <>
          <div className='text-left'>
            <span className='h3'>Overdue Tons by Needed Date</span>
          </div>
          <div className='pt-3 text-left'>
            <span className='tour-info'>
              <div> 
                This information highlights the status of shipments based on needed dates. Currently, there are <b>{Highcharts.numberFormat(salesDashboardInfo.PromisedTonsValue,0,'.',',')}</b> tons of products that have not been shipped and are overdue, as the customers required them earlier. 
                <br></br><br></br>
                For a detailed breakdown, click on the number to access a comprehensive list.
                <br></br><br></br>
                This overview enables you to prioritize and address the pending shipments promptly, ensuring customer satisfaction and meeting their urgent needs.

              </div> 
            </span>
          </div>  
        </>
      )
    },
    {
      target: '.WTW-Step-8',
      content: (
        <>
          <div className='text-left'>
            <span className='h3'>Needed tons</span>
          </div>
          <div className='pt-3 text-left'>
            <span className='tour-info'>
              <div>  
                Based on our data, there are <b>{Highcharts.numberFormat(salesDashboardInfo.PromiseTonsUptoThisMonth,0,'.',',')}</b> tons that need to be shipped by the end of this month. 
                <br></br><br></br>
                These tons represent pending orders that require prompt attention. For a detailed list, click on the number to access comprehensive information.
                <br></br><br></br>
                This overview allows you to prioritize and allocate resources efficiently to ensure timely delivery of the pending orders. By addressing these shipments promptly, you can meet customer expectations and maintain smooth operations.
              </div> 
            </span>
          </div>  
        </>
      )
    },
    {
      target: '.WTW-Step-9',
      content: (
        <>
          <div className='text-left'>
            <span className='h3'>Invoiced + Due Needed Tons</span>
          </div>
          <div className='pt-3 text-left'>
            <span className='tour-info'>
              <div> 
                This analysis combines the invoiced tons with the orders that, based on the customers specified needed date captured in the order, should have been shipped prior to today. The total sum of these tons provides an overview of both invoiced and overdue shipments. To access a detailed list, click on the number for comprehensive information.
              </div> 
            </span>
          </div>  
        </>
      )
    },
    {
      target: '.WTW-Step-10',
      content: (
        <>
          <div className='text-left'>
            <span className='h3'>Opportunity Tons</span>
          </div>
          <div className='pt-3 text-left'>
            <span className='tour-info'>
              <div> 
                <ul>
                  <li>
                    <b>Due Promised Tons</b>
                    <div>These are the tons that were promised by the shipping department before today but have not been shipped yet. They represent an opportunity to fulfill pending orders and meet customer expectations.</div>
                  </li>
                  <li>
                    <b>Promise date for next month(s)</b>
                    <div>These tons indicate the total quantity of products that were promised by the shipping department for shipment in the upcoming month(s). Although based on the customers needs for this month, the shipping department has assigned a promise date for next month(s).</div>
                  </li> 
                </ul>  
              </div>
              <br></br>
              <br></br>
              <div>For a detailed list, click on the respective numbers to access comprehensive information.</div>
            </span>
          </div>  
        </>
      )
    },
    {
      target: '.WTW-Step-11',
      content: (
        <>
          <div className='text-left'>
            <span className='h3'>Open Orders without Promise Date</span>
          </div>
          <div className='pt-3 text-left'>
            <span className='tour-info'>
              <div> 
                <ul>
                  <li>
                    <b>Needed up to this month</b>
                    <div>These are the total tons that do not have a promise date assigned, but our customers require them within this month. This indicates the immediate attention required to fulfill these orders.</div>
                  </li>
                  <li>
                    <b>All Open Orders</b>
                    <div>This category represents the total tons of open orders that do not have a promise date assigned, regardless of the customers specified timeframe.</div>
                  </li> 
                </ul>  
              </div>
              <br></br>
              <br></br>
              <div>If you need more detail, please click at the number to view detailed list.</div>
            </span>
          </div>  
        </>
      )
    },
    {
      target: '.WTW-Step-12',
      content: (
        <>
          <div className='text-left'>
            <span className='h3'>Invoiced Tons by</span>
          </div>
          <div className='pt-3 text-left'>
            <span className='tour-info'>
              <div>
                This card provides information on all invoiced orders during this month, allowing you to explore different grouping modes by clicking on the arrow icon.
                <br></br> <br></br>
                By analyzing the invoiced tons, you can gain insights into the volume of orders fulfilled and track performance trends. The ability to customize the grouping mode offers flexibility in organizing and analyzing the data based on your specific requirements.
                <br></br> <br></br>
                This overview helps monitor invoiced tons and facilitates data-driven decision-making to optimize operations and meet customer demands effectively.
              </div>
              <br></br>
              <br></br>
            </span>
          </div>  
        </>
      )
    },
    {
      target: '.WTW-Step-1',
      isLastStep: true,
      content: (
        <>
          <div className='text-left'>
            <span className='h4'>Welcome to the WTW Dashboard, {salesDashboardInfo.UserName}!</span>
          </div>
          <div className='pt-3 text-left'>
            <span className='tour-info'>
              You are currently viewing <b>{salesDashboardInfo.SalesRepName}&apos;s</b> WTW Dashboard. If you need to choose another sales representative, feel free to select someone else by clicking on the dropdown menu provided.
            </span>
          </div> 
        </>
      )
    }  
  ]
};

 if (IsLoading){
  return (<>Loading....</>)
 }

 if (ClaUsuario === null){
  return (<>We could not find a sales representative by default for you, feel free to pick a valid sales rep from the dropdown menu.</>)
 }

  return (
    <> 
      <div>
        <Joyride
          callback={handleJoyrideCallback}
          continuous={true}  
          run={IsRunTour}
          scrollOffset={50}
          scrollToFirstStep={true}
          showProgress={true}
          showSkipButton={true}
          steps={StepsTourPocketMargin.steps}
          disableBeacon={true}
          disableCloseOnEsc={true}
          disableOverlayClose={true}
          styles={{
            options: { 
              zIndex: 99999,
            }
          }}
        />  
        <Row className="align-items-start"> 
          <Col lg={12} md={12} sm={12} xs={12}>
            <Row className='pb-2'>
              <Col>
                {/* <h3>Welcome {salesDashboardInfo.UserName}</h3> */}  
                Do you need a Walkthrough?  <a href='#' className='link' onClick={onStartTour}>click here</a> 
              </Col>
              <Col className='text-right'>
                <h3>{moment(salesDashboardInfo.DateToday).format('MMMM, YYYY')}</h3>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row> 
          <Col lg={12} md={12} sm={12} xs={12}>
            <Row>
              <Col lg={3} md={3} sm={12} xs={12}> 
                <Card>
                  <CardHeader>
                    <Row>
                      <Col className='col-title'>Invoiced Tons</Col> 
                    </Row>
                  </CardHeader>
                  <CardBody className='WTW-card-height'> 
                    <Row className='pt-2 text-center'>
                      <Col><span className='col-value Cursor WTW-Step-2' onClick={onClickInvoicedTons}>{Highcharts.numberFormat(salesDashboardInfo.TonsSold,0,'.',',') }</span> &nbsp;<i className='fas fa-info-circle info-color' title='Invoiced tons during the current month'></i></Col> 
                    </Row>
                    <Row className='pt-2'>
                      <Col> 
                        <i className='fas fa-info-circle info-color' title='Percentage of completion vs objective of daily tons'></i>&nbsp;
                        {
                          salesDashboardInfo.TonsSoldEsArribaPresupuesto>0 ? (
                            <>
                              <small className='success-color'>
                                <b> {Highcharts.numberFormat(salesDashboardInfo.TosnSoldPercentageVsCurrentDay,2,'.',',') } % ({Highcharts.numberFormat(salesDashboardInfo.TonsSoldExpectedCurrentDay,0,'.',',') })</b>
                              </small>
                            </>
                          ):(
                            <>
                              <small className='danger-color'>
                                <b> {Highcharts.numberFormat(salesDashboardInfo.TosnSoldPercentageVsCurrentDay,2,'.',',')} % ({Highcharts.numberFormat(salesDashboardInfo.TonsSoldExpectedCurrentDay,0,'.',',') })</b>
                              </small>
                            </>
                          )
                        }
                        {
                          salesDashboardInfo.TonsSoldEsArribaPresupuesto>0 ? (
                            <>
                              <small className='success-color'>
                                <i className='pi pi-arrow-up small'></i>
                              </small>
                            </>
                          ):(
                            <>
                              <small className='danger-color'>
                                <i className='pi pi-arrow-down small'></i>
                              </small>
                            </>
                          )
                        }
                      </Col> 
                    </Row> 
                  </CardBody> 
                </Card>
              </Col>
              <Col lg={3} md={3} sm={12} xs={12}> 
                <Card>
                  <CardHeader className='hard-gray'>
                    <Row>
                      <Col className='col-title'>Current month revenue</Col> 
                    </Row>
                  </CardHeader>
                  <CardBody className='WTW-card-height'> 
                    <Row className='pt-2 text-center'>
                      <Col className='col-value'> ${Highcharts.numberFormat(salesDashboardInfo.Revenue,0,'.',',')}</Col> 
                    </Row>
                    <Row className='pt-2'>
                      <Col> 
                        &nbsp;
                      </Col>
                      <Col>
                        &nbsp;
                      </Col>
                    </Row> 
                  </CardBody> 
                </Card>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}> 
                <Card>
                  <CardHeader className='sky-blue'>
                    <Row>
                      <Col className='col-title'>Goal Tons</Col>
                      <Col className='text-right'>
                        <FieldSelectButton
                          className="fieldbutton-dashboard"
                          optionLabel="name"  
                          value={TipoObjetivo} 
                          options={tipoObjetivoFilterOptions} 
                          onChange={onChangeTipoObjetivo}
                        />
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className='WTW-card-height'>
                    
                    <Row className="align-items-start WTW-Step-3"> 
                      <Col lg={12} md={12} sm={12} xs={12}> 
                        <Row>
                          <Col className='col-subtitle'>
                            Month&nbsp;<i className='fas fa-info-circle info-color' title='Total tons objective for the month'></i>
                          </Col>
                          <Col className='col-subtitle'>
                            Daily&nbsp;<i className='fas fa-info-circle info-color' title='Monthly tons divided by the working days of the month'></i>
                          </Col>
                          <Col className='col-subtitle'>
                            Daily Accumulated
                            {
                              salesDashboardInfo.DailyGoalIncreasedEsArribaPresupuesto > 0
                              ? 
                              (
                                <small className='danger-color bold'>&nbsp;(Increased)</small> 
                              )
                              :
                              (<></>)
                             }
                            &nbsp;<i className='fas fa-info-circle info-color' title='Updated daily tons needed to achieve monthly goal based on current performance'></i>
                          </Col>
                        </Row>
                        <Row className='pt-4'>
                          <Col className='col-value'>
                            {Highcharts.numberFormat(salesDashboardInfo.MonthValue,0,'.',',')}
                          </Col>
                          <Col className='col-value'>
                            {Highcharts.numberFormat(salesDashboardInfo.DailyGoal,0,'.',',')}
                          </Col>
                          <Col>
                            <span className='col-value'>
                              {Highcharts.numberFormat(salesDashboardInfo.DailyGoalIncreased,0,'.',',')}&nbsp;
                            </span>
                            
                            {
                              salesDashboardInfo.DailyGoalIncreasedEsArribaPresupuesto>0 ? (
                                <>
                                  <small className='danger-color bold'>
                                    {Highcharts.numberFormat(salesDashboardInfo.DailyGoalIncreasedPercentage,2,'.',',')}%
                                  </small>
                                </>
                              ):(
                                <>
                                  <small className='success-color bold'>
                                    - {Highcharts.numberFormat(salesDashboardInfo.DailyGoalIncreasedPercentage,2,'.',',')}%
                                  </small>
                                </>
                              )
                            }
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <span className='light-gray-color'><small>The goal metrics displayed on this dashboard are derived from:  <b>{tipoObjetivoFilterOptions.filter(item => item.value === TipoObjetivo)[0].name}</b></small></span>
                          </Col> 
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col className='col-value'> 
                        &nbsp;
                      </Col>
                      <Col>
                        &nbsp;
                      </Col>
                      <Col>
                        &nbsp;
                      </Col>
                    </Row>
                  </CardBody>  
                </Card>
              </Col>
            </Row>
          </Col>
        </Row> 
        <Row> 
          <Col lg={12} md={12} sm={12} xs={12}>
            <Row className="align-items-start"> 
              <Col lg={8} md={8} sm={12} xs={12}> 
                <Card>
                  <CardBody>
                    <Row className='pt-2'>
                      <Col
                        lg={9}
                        md={9}
                        sm={12}
                        xs={12}
                        style={{borderRight:'solid #E8E8E8 1px'}}
                        className='WTW-Step-4'
                      >
                        <Row>
                          <Col className='col-title pb-5'>
                            Current Month - Daily tons invoiced Vs Daily Goal 
                          </Col>
                        </Row> 
                        <HighchartsReact highcharts={Highcharts} options={chartCurrentMonth} /> 
                      </Col>
                      <Col lg={3} md={3} sm={12} xs={12} className='text-center'>
                        <Row>
                          <Col className='col-title text-center pb-4'>
                            Today 
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <span className='col-value Cursor' onClick={onClickTodayInvoicedTons}>{Highcharts.numberFormat(salesDashboardInfo.TodaySold,0,'.',',') } </span> 
                          </Col>
                        </Row>
                        <Row className='pt-3'>
                          <Col>
                            {
                              salesDashboardInfo.TodaySoldEsArribaPresupuesto>0 ? (
                                <>
                                  <small className='success-color'>
                                    {Highcharts.numberFormat(salesDashboardInfo.TodayTosnSoldPercentageVsCurrentDay,2,'.',',') }% <i className='pi pi-arrow-up small'></i>
                                  </small>
                                </>
                              ):(
                                <>
                                  <small className='danger-color'>
                                    {Highcharts.numberFormat(salesDashboardInfo.TodayTosnSoldPercentageVsCurrentDay,2,'.',',') }% <i className='pi pi-arrow-down small'></i>
                                  </small>
                                </>
                              )
                            }
                          </Col>
                        </Row> 
                      </Col>
                    </Row>
                  </CardBody>  
                </Card>
              </Col>
              <Col lg={4} md={4} sm={12} xs={12}> 
                
                <Card>
                  <CardBody className='WTW-Step-5'>
                    <Row className="align-items-start pb-4">
                      <Col className='text-center'>
                        <span className='h3'>Accounts behavior this month</span>
                      </Col>
                    </Row> 
                    <Row className="align-items-start">
                      <Col style={{borderRight:'solid #E8E8E8 1px'}}>
                        <Row className='pt-2'>
                          <Col className='text-center'> 
                            <span className='col-value Cursor' onClick={onClickInvoicedNewAccountsTons}>{Highcharts.numberFormat(salesDashboardInfo.NewCustomerValue,0,'.',',')}</span> 
                          </Col>
                        </Row>
                        <Row className='pt-4'>
                          <Col className='col-description-title text-center'>
                            New &nbsp;<i className='fas fa-info-circle info-color ' title='New account that we invoice something for the first time'></i>  
                          </Col>
                        </Row>
                        <Row>
                          <Col className='col-description-small text-center'>
                            {Highcharts.numberFormat(salesDashboardInfo.NewCustomerTons,0,'.',',')} Invoiced Tons 
                            <div>
                              <span className='info-color'> ({Highcharts.numberFormat(salesDashboardInfo.NewCustomerPorcentaje,2,'.',',')}% of total) &nbsp;<i className='fas fa-info-circle info-color' title='% is versus tons we have invoiced in current month'></i></span>
                            </div>
                          </Col>
                        </Row> 
                      </Col>
                      <Col style={{borderRight:'solid #E8E8E8 1px'}}>
                        <Row className='pt-2'>
                          <Col className='col-value text-center'> <span className='Cursor' onClick={onClickInvoicedRecoverAccountsTons}> {Highcharts.numberFormat(salesDashboardInfo.RecoveredCustomerValue,0,'.',',')}</span></Col>
                        </Row>
                        <Row className='pt-4'>
                          <Col className='col-description-title text-center'>Recovered &nbsp;<i className='fas fa-info-circle info-color' title='Accounts that we invoiced and that have had no activity in the last 3 months.'></i>  </Col>
                        </Row>
                        <Row>
                          <Col className='col-description-small text-center'>
                            {Highcharts.numberFormat(salesDashboardInfo.RecoveredTons,0,'.',',')} Invoiced Tons 
                            <div>
                              <span className='info-color'> ({Highcharts.numberFormat(salesDashboardInfo.RecoveredPorcentaje,2,'.',',')}% of total)&nbsp;<i className='fas fa-info-circle info-color' title='% is versus tons we have invoiced in current month.'></i></span>
                            </div>
                          </Col> 
                        </Row> 
                      </Col>
                      <Col>
                        <Row className='pt-2'>
                          <Col className='col-value text-center'> <span className='Cursor' onClick={onClickInvoicedActiveAccountsTons}> {Highcharts.numberFormat(salesDashboardInfo.ActiveCustomerValue,0,'.',',')}</span></Col>
                        </Row>
                        <Row className='pt-4'>
                          <Col className='col-description-title text-center'>Recurrent &nbsp;<i className='fas fa-info-circle info-color' title='Accounts that have had invoicing activity in the last 3 months.'></i> </Col>
                        </Row>
                        <Row>
                          <Col className='col-description-small text-center'>
                            {Highcharts.numberFormat(salesDashboardInfo.ActiveCustomerTons,0,'.',',')} Invoiced Tons  
                            <div>
                              <span className='info-color'> ({Highcharts.numberFormat(salesDashboardInfo.ActiveCustomerTonsPorcentaje,2,'.',',')}% of total)&nbsp;<i className='fas fa-info-circle info-color' title='% is versus tons we have invoiced in current month.'></i></span>
                            </div>
                          </Col> 
                        </Row> 
                      </Col> 
                    </Row> 
                  </CardBody> 
                </Card> 
                <Card>
                  <CardBody className='WTW-Step-6'>
                    <Row className="align-items-start pb-4">
                      <Col className='text-center'>
                        <span className='h3'>Total Accounts</span>
                      </Col>
                    </Row> 
                    <Row>
                      <Col style={{borderRight:'solid #E8E8E8 1px'}}>
                        <Row className='pt-2'>
                          <Col className='text-center'><span className="col-value Cursor" onClick={onClickActiveAccountsSummary}>{Highcharts.numberFormat(salesDashboardInfo.TotalActiveCustomers,0,'.',',')}</span></Col>
                        </Row>
                        <Row className='pt-4'>
                          <Col className='col-description text-center'>
                            Active &nbsp;<i className='fas fa-info-circle info-color ' title='Total Accounts that have had at least one invoicing in the last 3 months'></i> 
                          </Col>
                        </Row>
                        <Row>
                          <Col className='col-description text-center'>
                            &nbsp;
                          </Col>
                        </Row> 
                      </Col>
                      <Col style={{borderRight:'solid #E8E8E8 1px'}}> 
                        <Row className='pt-2'>
                          <Col className='text-center'><span className="col-value Cursor" onClick={onClickInactiveAccountsSummary}>{Highcharts.numberFormat(salesDashboardInfo.InactiveCustomer,0,'.',',')}</span></Col>
                        </Row>
                        <Row className='pt-4'>
                          <Col className='col-description text-center'>
                            Inactive &nbsp;<i className='fas fa-info-circle info-color ' title='Accounts that have had no invoicing in the last 3 months'></i> 
                          </Col>
                        </Row>
                        <Row className='pt-2'>
                          <Col className='col-description-small text-center'>
                            &nbsp;
                          </Col>
                        </Row>
                      </Col>
                      <Col> 
                        <Row>
                          <Col className='col-description-title text-center'>
                            <img alt="..." src={inactive_customer} style={{ width: '90px', height: '90px' }}  />  
                          </Col>
                        </Row> 
                      </Col>
                    </Row> 
                  </CardBody> 
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>  
        <Row> 
          <Col lg={12} md={12} sm={12} xs={12}>
            <Row>
              <Col lg={4} md={4} sm={12} xs={12}> 
                
              </Col>
              <Col lg={4} md={4} sm={12} xs={12}> 
               
              </Col>
              <Col lg={4} md={4} sm={12} xs={12}> 
                
              </Col>
            </Row>
          </Col>
        </Row> 
        <Row> 
          <Col lg={12} md={12} sm={12} xs={12}>
            <Row className="align-items-start">
              {
                /*
              <Col lg={3} md={3} sm={12} xs={12}> 
                <Card>
                  <CardHeader className='white-card-header'>
                    <Row>
                      <Col className='col-title'>Spread</Col> 
                    </Row>
                  </CardHeader>
                  <CardBody className='WTW-card-height'> 
                    <Row className='pt-2 text-center'>
                      <Col className='col-value'>{salesDashboardInfo.SpreadValue}</Col> 
                    </Row>
                    <Row className='pt-4'>
                      <Col>
                        <SparkLine options={chartSpread} color="#26E3FF" />
                      </Col>
                    </Row>
                  </CardBody> 
                </Card>
              </Col>
                */
              }
              
              <Col lg={6} md={6} sm={12} xs={12}> 
                <Card>
                  <CardHeader className='azul-fuerte'>
                    <Row>
                      <Col className='col-title'>Overdue Tons by Needed Date</Col> 
                      <Col className='col-title text-center'>Needed Tons</Col> 
                      <Col className='col-title text-right'>Invoiced + Due Needed Tons</Col> 
                    </Row>
                  </CardHeader>
                  <CardBody className='WTW-card-height'> 
                    <Row className='pt-2'> 
                      <Col className=''> <span className='col-value Cursor WTW-Step-7' onClick={onClickDueNeedTons}> {Highcharts.numberFormat(salesDashboardInfo.PromisedTonsValue,0,'.',',')}</span>&nbsp;<i className='fas fa-info-circle info-color ' title='Tons that have not been shipped and that the customer needed them for an earlier date'></i><div>&nbsp;</div></Col>
                      <Col className='text-center'><span className='col-value Cursor WTW-Step-8' onClick={onClickNeededTons}>{Highcharts.numberFormat(salesDashboardInfo.PromiseTonsUptoThisMonth,0,'.',',')}</span><div><small>All open orders needed up to the end of the month.</small></div></Col> 
                      <Col className=' text-right'><span className='col-value Cursor WTW-Step-9' onClick={onClickSoldPlusNeededTons}> {Highcharts.numberFormat(salesDashboardInfo.PromisedMasSold,0,'.',',')}</span>&nbsp;<i className='fas fa-info-circle info-color ' title='Invoiced tons plus due needed tons in the month'></i>
                        <div>
                          <i className='fas fa-info-circle info-color ' title='% is versus tons goal up to today'></i>
                          &nbsp;
                          {
                              salesDashboardInfo.PromisedMasSoldEsArribaPresupuesto>0 ? (
                                <>
                                  <small className='success-color'>
                                    +&nbsp;{Highcharts.numberFormat(salesDashboardInfo.PromisedMasSoldPorcentaje,2,'.',',')}% <i className='pi pi-arrow-up small'></i>
                                  </small>
                                </>
                              ):(
                                <>
                                  <small className='danger-color'>
                                    -&nbsp;{Highcharts.numberFormat(salesDashboardInfo.PromisedMasSoldPorcentaje,2,'.',',')}% <i className='pi pi-arrow-down small'></i>
                                  </small>
                                </>
                              )
                            }
                        </div>
                      </Col>
                    </Row>
                    <Row className='pt-2'>
                      <Col className='text-left'><img alt="..." src={customer_service_operations} style={{ width: '50px', height: '50px' }}  />  </Col>
                     
                      <Col className='text-right'> 
                        <img alt="..." src={truck_load_promised} style={{ width: '50px', height: '50px' }}  />
                      </Col>
                    </Row>
                  </CardBody> 
                </Card>
              </Col>
              <Col lg={3} md={3} sm={12} xs={12}> 
                <Card className='WTW-Step-10'>
                  <CardHeader className='disabled-gray'>
                    <Row>
                      <Col className='col-title'>Opportunity Tons</Col>  
                    </Row>
                  </CardHeader>
                  <CardBody className='WTW-card-height'> 
                    <Row className='pt-5'>
                      <Col className='text-center'> 
                        <span className='col-value Cursor' onClick={onClickOpportunityDuePromiseTons}>{Highcharts.numberFormat(salesDashboardInfo.DuePromisedOrders,0,'.',',') }</span>
                        &nbsp;<i className='fas fa-info-circle info-color ' title='Tons that were promised before today but are not invoiced yet.'></i>
                        <div>
                          <span>Due Promised Tons<div>&nbsp;</div></span>
                        </div>
                      </Col> 
                      <Col className='text-center'> 
                        <span className='col-value Cursor' onClick={onClickOpportunityPromiseForNextMonthsTons}>{Highcharts.numberFormat(salesDashboardInfo.CustomerNeededTonsCurrentMonthPromiseLater,0,'.',',') }</span>
                        &nbsp;<i className='fas fa-info-circle info-color ' title='Tons that customer needs this month but have a promise date for next month(s)'></i>
                        <div>
                          <span>Promise date for next month(s)</span>
                        </div>
                      </Col> 
                      {
                        /*
                        <Col>
                          <SparkLine options={chartOptions} color="#ffb21f" />
                        </Col>
                        */
                      }
                      
                    </Row>
                  </CardBody> 
                </Card>
              </Col>
              <Col lg={3} md={3} sm={12} xs={12}> 
                <Card className='WTW-Step-11'>
                  <CardHeader className='white-card-header'>
                    <Row>
                      <Col><span className='col-title'>Open Orders without Promise Date</span>&nbsp;<i className='fas fa-info-circle info-color ' title='Orders that do not have a promise date assigned in the system'></i></Col>  
                    </Row>
                  </CardHeader>
                  <CardBody className='WTW-card-height'> 
                    <Row className='pt-5'>
                      <Col className='text-center'> 
                        <span className='col-value Cursor' onClick={onClickOONoPromiseDateNeeded}>{Highcharts.numberFormat(salesDashboardInfo.CustomerNeededTonsWithOutPromiseDate,0,'.',',') }</span>
                        <div>
                          <span>Needed up to this month</span>
                        </div>
                      </Col> 
                      <Col className='text-center'> 
                        <span className='col-value Cursor' onClick={onClickOONoPromiseDate}>{Highcharts.numberFormat(salesDashboardInfo.TonsWithOutPromiseDate,0,'.',',') }</span> 
                        <div>
                          <span>All Open Orders</span>
                        </div>
                      </Col>
                      
                    </Row> 
                  </CardBody> 
                </Card>
              </Col>
            </Row>
          </Col>
        </Row> 
        <Row>
          <Col>
            <Row className="align-items-start">
              <Col lg={6} md={12} sm={12} xs={12}>
                <Card>
                  <CardBody className='WTW-Step-12'>
                    <Row>
                      <Col lg={12} md={12} sm={12} xs={12} className='col-title'> 
                        <table>
                          <tbody>
                            <tr>
                              <td>Invoiced Tons by</td>
                              <td>
                                <FieldDropdown
                                  showClear={false}
                                  name="ClaCategoria"
                                  value={ClaCategoria} 
                                  options={TonsByCategoryList}  
                                  optionValue="ClaCategoria"
                                  optionLabel="NomCategoria" 
                                  onChange={(e) => setClaCategoria(e.value)}
                                  className="DashboardDropdownCategory"
                                />   
                              </td>
                            </tr>
                          </tbody>
                        </table> 
                        
                      </Col>
                    </Row>
                    <Row hidden={ClaCategoria !== 1}>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        {
                          chartTonsBySegment === null ?
                          (<></>)
                          :
                          (<><HighchartsReact highcharts={Highcharts} options={chartTonsBySegment} /> </>)
                        }
                        
                        <div className='p-3'>
                          <Row className='pt-2'>
                            <Col
                              lg={1}
                              md={1}
                              sm={1}
                              xs={1} 
                            >
                                      &nbsp;
                            </Col>
                            <Col>
                              <b>Segment Name</b>
                            </Col>
                            <Col className='text-right'>
                              <b>Tons</b>
                            </Col>
                            <Col className='text-right'>
                              <b>Price per Ton</b>
                            </Col>
                            <Col className='text-right'>
                              <b>Spread</b>
                            </Col>
                          </Row>

                          {tonsBySegmentList.map((element, index) => {  
                              return (  
                                <Row className='pt-2' key={index}>
                                  <Col
                                    lg={1}
                                    md={1}
                                    sm={1}
                                    xs={1}
                                    style={{background:element.Color}} 
                                  >
                                      &nbsp;
                                  </Col>
                                  <Col>
                                    {element.NomSegmento}
                                  </Col>
                                  <Col className='text-right'>
                                    {Highcharts.numberFormat(element.Tons,0,'.',',')}
                                  </Col>
                                  <Col className='text-right'>
                                    {Highcharts.numberFormat(element.PriceByTon,0,'.',',')}
                                  </Col>
                                  <Col className='text-right'>
                                    {Highcharts.numberFormat(element.Spread,0,'.',',')}
                                  </Col>
                                </Row>
                              );
                              })}  
                        </div>
                      </Col> 
                    </Row>
                    <Row hidden={ClaCategoria !== 2}>
                      <Col lg={12} md={12} sm={12} xs={12} className='pt-3'>
                        <FieldDataTable
                          value={byStatisticalGpo2List}   
                          scrollable 
                          scrollHeight="250px"
                          className="table-header-background-blue"
                        >
                          <Column field="NomGrupoEstadistico3" header="Statistical Gpo 3" body={NomGrupoEstadistico3Template} style={{width:"250px"}} />
                          <Column field="MTTons" header="Tons" body={MTTonsTemplate} style={{textAlign:'right'}} headerStyle={{textAlign:'right'}} />
                          <Column field="PriceByTon" header="Price per Ton" body={PriceByTonTemplate} style={{textAlign:'right'}} headerStyle={{textAlign:'right'}} /> 
                          <Column field="Spread" header="Spread" body={SpreadTemplate} style={{textAlign:'right'}} headerStyle={{textAlign:'right'}} />
                        </FieldDataTable>
                      </Col>
                    </Row>
                    <Row hidden={ClaCategoria !== 3}>
                      <Col lg={12} md={12} sm={12} xs={12} className='pt-3'>
                        <div>
                          <span className='info-color'><small>Avg Tons = Invoiced tons average last 12 months.</small></span>
                        </div>
                        <FieldDataTable
                          value={byCustomerAccountList}   
                          scrollable 
                          scrollHeight="250px"
                          className="table-header-background-blue"
                        >
                          <Column field="ClaCUC" header="CUC" body={ClaCUCTemplate} style={{width:"80px"}} headerStyle={{width:'80px'}} />
                          <Column field="NomCUC" header="Name" body={NomCUCTemplate} style={{width:"230px"}} headerStyle={{width:'230px'}} />
                          <Column body={EstatusCUCTemplate} style={{width:"80px"}} headerStyle={{width:'80px'}} />
                          <Column field="AvgMTons" header="Avg Tons" body={AvgMTTonsTemplate} style={{textAlign:'right', width:"110px"}} headerStyle={{textAlign:'right', width:"110px"}} />
                          <Column field="MTTons" header="Tons" body={MTTonsTemplate} style={{textAlign:'right', width:"110px"}} headerStyle={{textAlign:'right', width:"110px"}} />
                          <Column field="Diff" header="Diff" body={DiffTemplate} style={{textAlign:'right', width:"110px"}} headerStyle={{textAlign:'right', width:"110px"}} />
                          <Column field="PriceByTon" header="Price per Ton" body={PriceByTonTemplate} style={{textAlign:'right', width:"110px"}} headerStyle={{textAlign:'right', width:"110px"}} /> 
                          <Column field="NeededTonsThisMonth" header="Need" body={NeededTonsThisMonthTemplate} style={{textAlign:'right', width:"110px"}} headerStyle={{textAlign:'right', width:"110px"}} />
                          <Column field="PromiseTonsThisMonth" header="Promised" body={PromiseTonsThisMonthTemplate} style={{textAlign:'right', width:"110px"}} headerStyle={{textAlign:'right', width:"110px"}} />
                        </FieldDataTable>
                      </Col>
                    </Row>
                  </CardBody> 
                </Card>    
              </Col>
              <Col lg={6} md={12} sm={12} xs={12}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg={12} md={12} sm={12} xs={12} className='col-title'> 
                        My Team Performance
                      </Col>
                    </Row>
                    <Row className='pt-3'>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        <div>
                          <span className='info-color'><small>Performance of my team comparing invoiced tons vs the goal tons that should be invoiced up to today.</small></span>
                        </div>
                        <FieldDataTable
                          value={myTeamList} 
                          headerColumnGroup={myTeamHeaderGroup} 
                          // footerColumnGroup={myTeamFooterGroup} 
                          responsiveLayout="scroll"
                          className="table-header-background-blue" 
                          paginator={false} 
                        >
                          <Column field="Name" body={NameTemplate} style={{width:"250px"}} />
                          <Column field="Planned" body={plannedBodyTemplate} style={{textAlign:'center'}} headerStyle={{textAlign:'center'}} />
                          <Column field="Sold" body={soldBodyTemplate} style={{textAlign:'center'}} headerStyle={{textAlign:'center'}} />
                          <Column field="Promised" body={promisedBodyTemplate} style={{textAlign:'center'}} headerStyle={{textAlign:'center'}} />
                          <Column body={ResultadoTemplate} />
                        </FieldDataTable>
                      </Col> 
                    </Row>
                  </CardBody> 
                </Card>    
             
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

 
      <Dialog
        visible={EsMostrarDetalleFacturacion}
        header="Invoice Detail Information"
        style={{width: '90vw'}}
        modal
        className="dialog-custom"
        onHide={()=> setEsMostrarDetalleFacturacion(false)}
      >
        {
              EsMostrarDetalleFacturacion &&
              (
                <><SalesDashboardDetailInvoices AnioMes={AnioMes} ClaUsuarioSalesRep={ClaUsuario} Fecha={FechaSeleccionada} EsShowNewOrder={EsShowNewOrder} EstatusTipoCliente={EstatusTipoCliente} TipoDetalleInformacion={TipoDetalleInformacion}></SalesDashboardDetailInvoices></>
              )
        }    
      </Dialog> 

      <Dialog
        visible={EsMostrarAccountSummary}
        header="Account Summary"
        style={{width: '90vw'}}
        modal
        className="dialog-custom"
        onHide={()=> setEsMostrarAccountSummary(false)}
      >
        {
              EsMostrarAccountSummary &&
              (
                <><SalesDashboardAccountSummary AnioMes={AnioMes} ClaUsuarioSalesRep={ClaUsuario} EstatusTipoCliente={EstatusTipoCliente} ClaClienteCuenta={null}></SalesDashboardAccountSummary></>
              )
        }    
      </Dialog> 
    </>
  );
};
export default SalesDashboard;

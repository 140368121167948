/* eslint-disable react/forbid-prop-types */

import { React, useEffect, useState } from 'react';
import { callApi } from "utils/utils";
import { config } from 'utils/config';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import * as Tableau from 'tableau-api-js';

const Viz = (props) => {

  let viz = null;

  useEffect(() => {
    createViz();
  }, [])

  const createViz = () => {
    // Get the url from props
    const { url } = props;

    // Make sure the url is for the Deacero tableau server
    if (url.search('https://tableau.deacero.com') === -1) {
      throw new Error('URL must be for https://tableau.deacero.com')
    }

    // Get the view part of the url
    const index = url.search('/views/');
    if (index === -1) {
      throw new Error('URL is not a valid view URL');
    }
    const urlEnd = url.substring(index);

    const paramsService = {  };
    const urlWebService = `${config.UrlApiProject}Tableau/GetTableauTicket`;

    callApi(urlWebService, 'POST', paramsService, (response) => {
      // Add in the trusted ticket
      const ticketUrl = `https://tableau.deacero.com/trusted/${response.data.ticket}${urlEnd}`;

      // Get the div container
      const containerDiv = document.getElementById('vizContainer');

      // Set options
      const options = {
        width: containerDiv.clientWidth,
        height: "85vh",
        toolbarPosition: Tableau.ToolbarPosition.BOTTOM
      };

      if (props.parameters !== null && props.parameters !== undefined) {
        Object.assign(options, props.parameters);
      }

      // Create the viz
      viz = new Tableau.Viz(containerDiv, ticketUrl, options);
    });
  }

  // Callback to resize the viz with the window
  window.addEventListener('resize', resizeViz);
  function resizeViz() {

    const vizContainer = document.getElementById('vizContainer');
    
    let width = "100vw";
    if (vizContainer !== null && vizContainer !== undefined) {
      width = vizContainer.offsetWidth;
    }

    const height = "85vh";

    if (viz !== null && viz !== undefined) {
      viz.setFrameSize(width, height);
    }
  }

  return (
    <>
      <Row>
        <Col className='p-fluid'>
          <div id='vizContainer'></div>
        </Col>
      </Row>
    </>
  )
}


Viz.propTypes = {
  url: PropTypes.string.isRequired,
  parameters: PropTypes.object
}

export default Viz;
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PageContent from '../../../../layouts/PageContent';
import { Row, Col, Card, CardBody, CardHeader, CardTitle, CardFooter } from 'reactstrap';
import InstructionsCard from '../../../../components/Cards/InstructionsCard/InstructionsCard';
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../../utils/utils';
import * as yup from 'yup';
import YupValidator from '../../../../utils/YupValidator';
import swal from '@sweetalert/with-react';
import SweatAlertCustomIcon from '../../../../components/SweatAlert/SweatAlertCustomIcon';
import moment from 'moment';
import { Badge } from 'primereact/badge';
import Score from '../../../../components/Cards/Score/Score.jsx';
import FieldButton from '../../../../components/Controls/FieldButton/FieldButton.jsx';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldText from 'components/Controls/FieldText';
import EditorText from 'components/Controls/EditorText/EditorText';
import FieldCheckbox from 'components/Controls/FieldCheckbox';
import FieldSelectButton from 'components/Controls/FieldSelectButton';
import FieldMultiSelect from 'components/Controls/FieldMultiSelect';
import FieldTextArea from 'components/Controls/FieldTextArea';
import FieldCalendar from 'components/Controls/FieldCalendar';
import FieldNumber from 'components/Controls/FieldNumber';
import './Survey.css';
import { Tooltip } from 'primereact/tooltip';
import { Divider } from 'primereact/divider';

const SurveyManagementQuestionEdit = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['survey', 'common']);
  const [TipoCampoCmb, setTipoCampoCmb] = useState([]);
  const [EncuestaTopicoList, setEncuestaTopicoList] = useState([]);
  const [RespuestaOpcionesList, setRespuestaOpcionesList] = useState([]);
  const [ClaFormularioTipoCampo, setClaFormularioTipoCampo] = useState(null);
  const [Titulo, setTitulo] = useState(null);
  const [preguntaPagina, setpreguntaPagina] = useState(null);
  const [EsHabilitarOpciones, setEsHabilitarOpciones] = useState(false);
  const [errors, setErrors] = useState({});
  const [Descripcion, setDescripcion] = useState(null);
  const [IdEncuesta, setIdEncuesta] = useState(null);
  const [IdEncuestaPagina, setIdEncuestaPagina] = useState(null);
  const [IdEncuestaPaginaPregunta, setIdEncuestaPaginaPregunta] = useState(null);
  const [EsHabilitarMultiSeleccion, setEsHabilitarMultiSeleccion] = useState(0);
  const [CamposMultiSeleccion, setCamposMultiSeleccion] = useState([]);
  const [EsObligatorio, setEsObligatorio] = useState(false);
  const [EsEditar, setEsEditar] = useState(false);
  const [OpcionesPorPreguntaList, setOpcionesPorPreguntaList] = useState([]);
  const [IdEncuestaTopico,setIdEncuestaTopico] = useState(null)

  useEffect(() => {
    setIdEncuestaPagina(props.IdEncuestaPagina === undefined ? null : props.IdEncuestaPagina);
    setIdEncuestaPaginaPregunta(
      props.IdEncuestaPaginaPregunta === undefined ? null : props.IdEncuestaPaginaPregunta
    );
    setEsEditar(props.EsEditar === undefined ? false : props.EsEditar);
     
    if (props.EsEditar === false) {
      GetHREncuestaPaginaPreguntaById(props.IdEncuestaPagina, props.IdEncuestaPaginaPregunta);  
    }else{
      obtenerTipoCampo();
      obtenerEncuestaTopico();
      obtenerOpcionesRespuestaPorPregunta(props.IdEncuestaPaginaPregunta);
    }       
    // obtenerOpcionesRespuestaPorPregunta(props.IdEncuestaPaginaPregunta);
  }, [
    props.IdEncuesta,
    props.IdEncuestaPagina,
    props.IdEncuestaPaginaPregunta,
    props.ForceRefresh,
    props.EsEditar,
  ]);

  const GetHREncuestaPaginaPreguntaById = (pIdEncuestaPagina, pIdEncuestaPaginaPregunta) => {
    const getHREncuestaPaginaPreguntaById = `${config.UrlApiProject}HR/GetHREncuestaPaginaPreguntaById`;
    const paramsToService = {
      IdEncuestaPagina: pIdEncuestaPagina,
      IdEncuestaPaginaPregunta: pIdEncuestaPaginaPregunta,
    };
    callApi(getHREncuestaPaginaPreguntaById, 'POST', paramsToService, (response) => {
      const PreguntaResult = response.data.Pregunta.length > 0 ? response.data.Pregunta[0] : {};
      setDescripcion(PreguntaResult.Descripcion);
      setClaFormularioTipoCampo(PreguntaResult.ClaFormularioTipoCampo);
      setIdEncuestaTopico(PreguntaResult.IdEncuestaTopico);
      setEsObligatorio(PreguntaResult.EsObligatorio);
      setIdEncuestaPaginaPregunta(PreguntaResult.IdEncuestaPaginaPregunta);
      setOpcionesPorPreguntaList(response.data.OpcionesRespuesta);
      setEsHabilitarMultiSeleccion(PreguntaResult.EsHabilitarOpciones);
    });
  };

  const obtenerOpcionesRespuestaPorPregunta = (pIdEncuestaPaginaPregunta) => {
    const getHREncuestaPaginaPreguntaRespuesta = `${config.UrlApiProject}HR/GetHREncuestaPaginaPreguntaRespuesta`;
    const paramsToService = {
      IdEncuestaPaginaPregunta: pIdEncuestaPaginaPregunta,
    };
    callApi(getHREncuestaPaginaPreguntaRespuesta, 'POST', paramsToService, (response) => {
      setCamposMultiSeleccion(response.data.PreguntasPorPagina);
    });
  };

  const onDescripcionChange = (value) => {
    const newErrors = { ...errors };
    delete newErrors.Descripcion;
    setErrors(newErrors);
    setDescripcion(value);
  };
  const onCancel = () => {
    // setCamposMultiSeleccion(null);
    // if (props.OcultarEditarSurveyPageQuestion) {
    //   props.OcultarEditarSurveyPageQuestion();
    // }
    setErrors({});
    if (props.RecargarPaginaPreguntas) {
      props.RecargarPaginaPreguntas();
    }
    setEsEditar(false);
  };

  const obtenerTipoCampo = () => {
    const getTipoCampoCmb = `${config.UrlApiProject}HR/GetTipoCampoCmb`;
    const paramsToService = {};
    callApi(getTipoCampoCmb, 'GET', paramsToService, (response) => {
      setTipoCampoCmb(response.data.TipoCampo);
    });
  };

  const obtenerEncuestaTopico = () => {
    const GetEncuestaTopicoCmb = `${config.UrlApiProject}HR/GetEncuestaTopicoCmb?IdEncuesta=${props.IdEncuesta}`;
    const paramsToService = {};
    callApi(GetEncuestaTopicoCmb, 'GET', paramsToService, (response) => {
      setEncuestaTopicoList(response.data.EncuestaTopicoList);
    });
  };


  const onChangeTipoCampo = (props, e) => {
    const newErrors = { ...errors };
    delete newErrors.ClaFormularioTipoCampo;
    setErrors(newErrors);

    if (e.target.value === null || typeof e.target.value === 'undefined') {
      setClaFormularioTipoCampo(null);
      setEsHabilitarMultiSeleccion(0);
      setCamposMultiSeleccion([]);
      return;
    }

    const [EsHabilitarOpciones] = TipoCampoCmb.filter(
      (opc) => opc.ClaFormularioTipoCampo === e.target.value
    );

    setClaFormularioTipoCampo(e.target.value);

    if (EsHabilitarOpciones.EsHabilitarOpciones === 1) {
      setEsHabilitarMultiSeleccion(1);
      setCamposMultiSeleccion([{ DescripcionRespuesta: '', PesoRespuesta: 1 }]);
    } else {
      // setCamposMultiSeleccion([]);
      setCamposMultiSeleccion([]);
      setEsHabilitarMultiSeleccion(0);
    }
  };

  const onChangeEncuestaTopico = (props, e) => {
    const newErrors = { ...errors };
    delete newErrors.IdEncuestaTopico;
    setErrors(newErrors); 
 
    setIdEncuestaTopico(e.target.value); 
  };  

  const onDescripcionOpcRespuestaPreguntaChange = (value, item) => {
    const copyCamposMultiSeleccion = [...CamposMultiSeleccion];
    copyCamposMultiSeleccion[item.RowIndex].DescripcionRespuesta = value;
    setCamposMultiSeleccion(copyCamposMultiSeleccion);
  };

  const onRemoveRespuestaPreguntaClick = (item) => {
    const copyCamposMultiSeleccion = [...CamposMultiSeleccion];
    copyCamposMultiSeleccion[item.RowIndex].BajaLogica = 1;
    setCamposMultiSeleccion(copyCamposMultiSeleccion);
  };

  const AddCampoArregloMultiSeleccion = (item, index) => {
    return (
      <>
        <FieldText
          name="DescripcionRespuesta"
          value={item.DescripcionRespuesta}
          onChange={(e) => onDescripcionOpcRespuestaPreguntaChange(e.target.value, item)}
          autoFocus
          maxLength={500}
        />
        &nbsp;
        <FieldButton
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={() => onRemoveRespuestaPreguntaClick(item)}
        />
      </>
    );
  };

  const AddNewItemArregloMultiSel = () => {
    const newRow = {
      RowIndex: CamposMultiSeleccion !== undefined ? CamposMultiSeleccion.length : 0,
      DescripcionRespuesta: null,
      IdEncuestaPaginaPregunta,
      IdEncuestaPaginaPreguntaRespuesta: null,
      BajaLogica: 0,
    };

    setCamposMultiSeleccion([...CamposMultiSeleccion, newRow]);
  };

  const onSaveQuestionClick = async () => {
    const value = {
      Descripcion,
      ClaFormularioTipoCampo,
      CamposMultiSeleccion,
      IdEncuestaTopico
    };

    const validator = new YupValidator(valitationSchemeSave);
    const esValido = await validator.validate(value);
    if (!esValido) {
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      return;
    }

    const paramsService = {
      IdEncuestaPaginaPregunta,
      IdEncuestaPagina,
      Descripcion,
      ClaFormularioTipoCampo,
      EsObligatorio,
      SurveyManagementPaginaPreguntaRespuestaDTOList: CamposMultiSeleccion,
      IdAccion: 1,
      IdEncuestaTopico
    };

    const SaveSurvey = `${config.UrlApiProject}HR/SaveSurveyPaginaPregunta`;

    callApi(SaveSurvey, 'POST', paramsService, (response) => {
      // props.OcultarEditarSurveyPageQuestion();
      setErrors({});
      if (props.RecargarPaginaPreguntas) {
        props.RecargarPaginaPreguntas();
      }
      setEsEditar(false);
    });
  };

  const onConfirmBaja = () => {
    swal({
      buttons: {
        cancelar: {
          text: t('common:Cancel'),
          value: 'cancel',
          className: 'rounded-button danger',
        },
        continuar: {
          text: t('common:Continue'),
          value: 'continue',
          className: 'rounded-button light-green',
        },
      },
      content: confirmationMessageHTML,
    }).then((value) => {
      switch (value) {
        case 'continue':
          bajasurveyManagementPageQuestion();
          break;
        default:
          break;
      }
    });
  };

  const confirmationMessageHTML = (
    <div>
      <SweatAlertCustomIcon
        title={t('common:SubmitConfirmation')}
        letter={t('common:QuestionMark')}
      >
      </SweatAlertCustomIcon>
      <div>{t('survey:RemoveQuestion')}</div>
    </div>
  );

  const bajasurveyManagementPageQuestion = async () => {
    const urlWebServiceSave = `${config.UrlApiProject}HR/SaveSurveyPaginaPregunta`;
    const paramsService = {
      IdEncuestaPaginaPregunta,
      IdEncuestaPagina,
      IdAccion: 3,
    };
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {
      if (
        props.RecargarPaginaPreguntas !== null &&
        props.RecargarPaginaPreguntas !== 'undefined' &&
        typeof props.RecargarPaginaPreguntas === 'function'
      ) {
        props.RecargarPaginaPreguntas();
      }
      setEsEditar(false);
    });
  };

  const onCopyQuestion = () => {
    const urlWebServiceSave = `${config.UrlApiProject}HR/SaveSurveyPaginaPreguntaCopy`;

    const paramsService = {
      IdEncuestaPaginaPregunta,
      IdEncuestaPagina,
    };
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {
      if (
        props.RecargarPaginaPreguntas !== null &&
        props.RecargarPaginaPreguntas !== 'undefined' &&
        typeof props.RecargarPaginaPreguntas === 'function'
      ) {
        props.RecargarPaginaPreguntas();
      }
      setEsEditar(false);
    });
  };

  const valitationSchemeSave = yup.object().shape({
    Descripcion: yup.string().required(t('common:RequiredField')).nullable(),
    ClaFormularioTipoCampo: yup.mixed().required(t('common:RequiredField')).nullable(),
    IdEncuestaTopico: yup.mixed().required(t('common:RequiredField')).nullable(),
    CamposMultiSeleccion: yup.array().of(
      yup.object().shape({
        DescripcionRespuesta: yup
          .string()
          .when('BajaLogica', {
            is: 0,
            then: yup.string().trim().required(t('common:RequiredField')).nullable(),
            otherwise: yup.string().nullable(),
          }),
        PesoRespuesta: yup
          .number()
          .when('BajaLogica', {
            is: 0,
            then: yup.number().required(t('common:RequiredField')).integer().nullable(),
            otherwise: yup.number().nullable(),
          }),
      })
    ),
  });

  const showActiveRows = (rows) => {
    const activeRows = [];
    if (rows !== undefined) {
      if (rows.length > 0) {
        rows.forEach((item, index) => {
          if (!item.BajaLogica || item.BajaLogica === 0 || item.BajaLogica === undefined) {
            item.BajaLogica = 0;
            item.RowIndex = index;
            activeRows.push({ ...item });
          }
        });
      } else {
        return [];
      }
    }

    return activeRows;
  };

  const onChangeObligarotio = (props, e) => {
    setEsObligatorio(e.checked ? 1 : 0);
  };

  const EditarSurveyQuestion = (
    descipcion,
    claFormularioTipoCampo,
    pIdEncuestaPaginaPregunta,
    pEsObligatorio
  ) => {
    // setDescripcion(descipcion);
    // setClaFormularioTipoCampo(claFormularioTipoCampo);
    // setEsObligatorio(pEsObligatorio);
    // obtenerOpcionesRespuestaPorPregunta(pIdEncuestaPaginaPregunta);
    obtenerTipoCampo();
    obtenerEncuestaTopico();
    obtenerOpcionesRespuestaPorPregunta(props.IdEncuestaPaginaPregunta);
    setEsEditar(true);
  };

  const onPesoRespuestaChange = (value, item) => {
    const copyCamposMultiSeleccion = [...CamposMultiSeleccion];
    copyCamposMultiSeleccion[item.RowIndex].PesoRespuesta = value;
    setCamposMultiSeleccion(copyCamposMultiSeleccion);
  };

  const TipodeCampoPregunta = (pClaFormularioTipoCampo) => {
    if (pClaFormularioTipoCampo === 1) {
      return (
        <div className="p-fluid">
          <FieldText name="ftIdEncuestaPaginaPregunta" value={null} />
        </div>
      );
    }
    if (pClaFormularioTipoCampo === 2) {
      return (
        <div className="p-fluid">
          <FieldNumber name="fnIdEncuestaPaginaPregunta" value={null} onChange={null} />
        </div>
      );
    }
    if (pClaFormularioTipoCampo === 3) {
      return (
        <div className="p-fluid">
          <FieldNumber
            name="fnIdEncuestaPaginaPregunta"
            minFractionDigits={2}
            value={null}
            onChange={null}
          />
        </div>
      );
    }
    if (pClaFormularioTipoCampo === 4) {
      return (
        <div className="p-fluid">
          <FieldCalendar name="fcIdEncuestaPaginaPregunta" value={null} onChange={null} />
        </div>
      );
    }
    if (pClaFormularioTipoCampo === 5) {
      return (
        <div className="p-fluid">
          <FieldCalendar
            name="fcIdEncuestaPaginaPregunta"
            value={null}
            showTime={true}
            onChange={null}
          />
        </div>
      );
    }
    if (pClaFormularioTipoCampo === 7) {
      return (
        <div className="p-fluid">
          <FieldTextArea name="ftaIdEncuestaPaginaPregunta" rows={5} placeholder="" value={null} />
        </div>
      );
    }
    if (pClaFormularioTipoCampo === 8) {
      return (
        <div className="p-fluid">
          <FieldDropdown
            name="IdEncuestaPaginaPreguntaRespuesta"
            value={null}
            optionValue="IdEncuestaPaginaPreguntaRespuesta"
            optionLabel="DescripcionRespuesta"
            options={OpcionesPorPreguntaList}
            onChange={null}
          >
          </FieldDropdown>
        </div>
      );
    }
    if (pClaFormularioTipoCampo === 9) {
      return (
        <div className="p-fluid">
          <FieldMultiSelect
            name="MultiSelectIdEncuestaPaginaPreguntaRespuesta"
            value={null}
            optionLabel="DescripcionRespuesta"
            options={OpcionesPorPreguntaList}
            // onChange={null}
          >
          </FieldMultiSelect>
        </div>
      );
    }
    if (pClaFormularioTipoCampo === 10) {
      return (
        <div className="p-fluid">
          <FieldSelectButton
            name="FSIdEncuestaPaginaPreguntaRespuesta"
            value={null}
            options={OpcionesPorPreguntaList}
            onChange={null}
            optionValue="IdEncuestaPaginaPreguntaRespuesta"
            optionLabel="DescripcionRespuesta"
            className="buttonListSurvey"
            multiple={false}
          />
        </div>
      );
    }
    if (pClaFormularioTipoCampo === 11) {
      return (
        <div className="p-fluid">
          <FieldSelectButton
            name="FSMultipleIdEncuestaPaginaPreguntaRespuesta"
            value={null}
            options={OpcionesPorPreguntaList}
            onChange={null}
            optionValue="IdEncuestaPaginaPreguntaRespuesta"
            optionLabel="DescripcionRespuesta"
            className="buttonListSurvey"
            multiple={true}
          />
        </div>
      );
    }
  };

  if (!EsEditar) {
    return (
      <>
        <div
          className="pregunta-container-no-edit"
          onClick={() =>
            EditarSurveyQuestion(
              Descripcion,
              ClaFormularioTipoCampo,
              IdEncuestaPaginaPregunta,
              EsObligatorio
            )}
        >
          <div>
            <div className="Pregunta"><b>{Descripcion}</b></div>
          </div>
          <br></br>
          <div>{TipodeCampoPregunta(ClaFormularioTipoCampo)}</div>
          <div>&nbsp;</div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="survey-edit-container">
        <div className="survey-edit-body">
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} sm={12} xs={12}>
              <span className="survey-edit-label">{t('survey:Question')}</span>
              <div className="p-fluid">
                <FieldText
                  name="Descripcion"
                  value={Descripcion}
                  onChange={(e) => onDescripcionChange(e.target.value)}
                  autoFocus
                  maxLength={500}
                  errors={errors}
                />
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12} className="p-3">
              <span className="survey-edit-label">{t('survey:QuestionCategory')}</span>
              <div className="p-fluid">
                <FieldDropdown
                  name="ClaFormularioTipoCampo"
                  value={ClaFormularioTipoCampo}
                  options={TipoCampoCmb}
                  optionValue="ClaFormularioTipoCampo"
                  optionLabel="NomFormularioTipoCampo"
                  onChange={(e) => onChangeTipoCampo(props, e)}
                  errors={errors}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} sm={12} xs={12}>
              <div className="p-fluid">
                <span className="survey-edit-label">{t('survey:QuestionRequired')}</span>
                &nbsp;
                <FieldCheckbox
                  inputId={EsObligatorio}
                  checked={EsObligatorio === 1}
                  onChange={(e) => onChangeObligarotio(props, e)}
                />
                &nbsp;
                <i
                  className="fas fa-info-circle required-question secondary-color"
                  tooltip={t('survey:QuestionRequiredHelp')}
                >
                </i>
                <Tooltip target=".required-question" content={t('survey:QuestionRequiredHelp')} />
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12} className="p-3">
              <span className="survey-edit-label">{t('survey:QuestionTopic')}</span>
              <div className="p-fluid">
                <FieldDropdown
                  name="IdEncuestaTopico"
                  value={IdEncuestaTopico}
                  options={EncuestaTopicoList}
                  optionValue="IdEncuestaTopico"
                  optionLabel="Titulo"
                  onChange={(e) => onChangeEncuestaTopico(props, e)}
                  errors={errors}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
            </Col>
          </Row>
          {EsHabilitarMultiSeleccion === 1 ? (
            <>
              <Divider></Divider>
              <Row>
                <Col>
                  <span className="h3">{t('survey:ChoiceOptionTitle')}</span>
                  &nbsp;
                  <FieldButton
                    icon="pi pi-plus"
                    className="light-green no-border p-mr-2 rounded-button"
                    onClick={AddNewItemArregloMultiSel}
                  />
                  &nbsp;
                  <i
                    className="fas fa-info-circle new-option-choice secondary-color"
                    tooltip={t('survey:QuestionRequiredHelp')}
                  >
                  </i>
                  <Tooltip
                    target=".new-option-choice"
                    content={t('survey:QuestionNewOptionHelp')}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  &nbsp;
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ width: '70%' }}>{t('survey:Description')}</th>
                        <th style={{ width: '20%' }}>{t('survey:WeightPoints')}</th>
                        <th style={{ width: '10%' }}>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                      showActiveRows(CamposMultiSeleccion).map((item, index) => {
                          return   (
                            <tr key={index}>
                              <td>
                                <div className="p-fluid">
                                  <FieldText
                                    name="DescripcionRespuesta"
                                    value={item.DescripcionRespuesta}
                                    onChange={(e) => onDescripcionOpcRespuestaPreguntaChange(e.target.value, item)}
                                    maxLength={500}
                                    errors={errors[`CamposMultiSeleccion[${item.RowIndex}]`]} 
                                  /> 
                                </div>
                              </td>
                              <td>
                                 
                                <FieldNumber
                                  name="PesoRespuesta"
                                  value={item.PesoRespuesta}
                                  onChange={(e) => onPesoRespuestaChange(e.target.value, item)} 
                                  errors={errors[`CamposMultiSeleccion[${item.RowIndex}]`]} 
                                />  
                              </td>
                              
                              <td>
                                <div className="p-fluid">
                                  <FieldButton
                                    hidden={showActiveRows(CamposMultiSeleccion).length === 1}
                                    icon="pi pi-trash"
                                    className="p-button-rounded p-button-warning"
                                    onClick={() => onRemoveRespuestaPreguntaClick(item)}
                                  />
                                </div> 
                              </td>
                            </tr>    
                                )
                          }
                      )  
                        }                  
                    </tbody>
                  </table>
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  &nbsp;
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )} 
        </div>
        <div className="survey-toolbar-options">
          <Row>
            <Col lg={12} md={12} sm={12} xs={12} className="text-left">
              <FieldButton
                icon="fas fa-times"
                label={t('common:Cancel')}
                className="warning no-border p-mr-2 rounded-button"
                onClick={onCancel}
              />
              &nbsp;
              <FieldButton
                label={t('common:Save')}
                icon="pi pi-save"
                className="p-button-success no-border p-mr-2 rounded-button"
                onClick={onSaveQuestionClick}
              />
              {props.IdEncuestaPaginaPregunta > 0 ? (
                <>
                  &nbsp;
                  <FieldButton
                    label={t('common:Remove')}
                    icon="pi pi-trash"
                    className="danger no-border p-mr-2 rounded-button"
                    onClick={onConfirmBaja}
                  />
                  &nbsp;
                  <FieldButton
                    label={t('survey:QuestionCopy')}
                    icon="fas fa-paste"
                    className="secondary no-border p-mr-2 rounded-button"
                    onClick={onCopyQuestion}
                  />
                </>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </div>
      </div>

      <br></br>
    </>
  );
};

export default SurveyManagementQuestionEdit;

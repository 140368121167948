/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  ListGroupItem,
  ListGroup,
  Progress,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from 'reactstrap';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { Badge } from 'primereact/badge';
import { Link } from 'react-router-dom';
import ActionPlanScoreSquare from './ActionPlanScoreSquare.jsx';
import { Toolbar } from 'primereact/toolbar';
import Highcharts from 'highcharts'; 
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert, tracker } from '../../../../utils/utils';
import { Dialog } from 'primereact/dialog'; 
import ProjectActivitiesMonthSummary from './ProjectActivitiesMonthSummary.jsx';

const ProjectActivitiesAnnualSummary = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);

  // const [claUsuarioSeleccionado, setClaUsuarioSeleccionado] = useState(props.ClaUsuario === undefined ? getSessionItem('ClaUsuarioMod', '') : props.ClaUsuario);
  const [anio, setAnio] = useState(props.Anio ? props.Anio : null);
  const [EstatusContribucionReview, setEstatusContribucionReview] = useState(0);
  const [ActivaEmployeeSelfReview, setActivaEmployeeSelfReview] = useState(0);
  const [ScoreCardSummaryInfo, setScoreCardSummaryInfo] = useState({});
  const [ClaPuesto, setClaPuesto] = useState(null); 
  const [ClaAnio, setClaAnio] = useState(null);
  const [ProjectActivitiesAnnualResult, setProjectActivitiesAnnualResult] = useState(null);
  const [ProjectActivitiesList, setProjectActivitiesList] = useState([]);
  const [EsActivity, setEsActivity]= useState(false);
  const [ClaMes, setClaMes] = useState(null);

  useEffect(() => { 
    setClaPuesto(props.ClaPuesto === undefined ? 0 : props.ClaPuesto);
    setClaAnio(props.ClaAnio === undefined ? 0 : props.ClaAnio);
    GetProjectActivities();
  }, [props.ClaPuesto, props.ClaAnio]);

  const GetProjectActivities = () => { 
    const getProjectActivities = `${config.UrlApiProject}ScoreCard/GetProjectActivitiesResultadoAnualPuesto`;  
    const paramsToService = { 
      ClaPuesto: props.ClaPuesto === undefined ? 0 : props.ClaPuesto,
      ClaAnio: props.ClaAnio === undefined ? 0 : props.ClaAnio,
    };   
    callApi(getProjectActivities, 'POST', paramsToService, (response) => {   
        setProjectActivitiesAnnualResult(response.data.ProjectActivities.length>0?response.data.ProjectActivities[0].ProjectActivitiesAnnualResult : null);
        setProjectActivitiesList(response.data.ProjectActivitiesDetail);
    });
  }

  const leftToolbarTemplate = () => {
    return (
      <>
        <h3 className="title-ProjectActivities">{t('focusReport:ProjectActivities')}</h3>
      </>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <h3><span className="title-ProjectActivities">{t('focusReport:AnnualAvg')}: </span> {Highcharts.numberFormat(ProjectActivitiesAnnualResult,2)}</h3>
      </>
    );
  };
  const dialogStyle  = ( 
    { 
    width: '80vw'
  
  })
  const closeModalActivity = () => {

    setEsActivity(false); 
  
   } 

   const onViewActivity = (pClaMes, element) => {  
     if (element.Resultado === null){
       return;
     }

    setClaMes(pClaMes);
    setEsActivity(!EsActivity);

    tracker.trackEvent({
      type: 'ProjectActivitiesAnnualSummary', /* component name */
      action: 'CLICK_MONTH_DETAIL'
    }) 
  }
  return (
    <> 
      <Card>
        <CardBody>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
            </Col>
          </Row>
          <Row className="align-items-start ">
            <Col lg={12} md={12} sm={12} xs={12}>              
              {ProjectActivitiesList.map((element, index) => {
                    return (
                      <div
                        key={index} 
                        className={element.Resultado === null ? '' :'Cursor'}
                        style={{paddingRight:'10px', float:'left'}}
                        onClick={() => onViewActivity(element.ClaMes, element)}
                      >
                        <ActionPlanScoreSquare
                          title={element.NomMes}                          
                          score={element.Resultado}
                          severity={element.ResultadoNivel}
                        >
                        </ActionPlanScoreSquare>
                      </div>
                    );
                  })}
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Dialog
        visible={EsActivity}
        header=""
        style={dialogStyle} 
        modal
        className="dialog-transparent"
        contentClassName="dialog-content-transparent"
        closable={false} 
        onHide={closeModalActivity}
      >
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
          &nbsp;
          </Col> 
        </Row> 
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
            <ProjectActivitiesMonthSummary 
              ClaPuesto={ClaPuesto} 
              ClaMes={ClaMes} 
              ClaAnio={ClaAnio}
              showCloseButton={true}
              onCloseClick={closeModalActivity}
            >
            </ProjectActivitiesMonthSummary>
          </Col>
        </Row>
      </Dialog>
    </>
  );
};

export default ProjectActivitiesAnnualSummary;

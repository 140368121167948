/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next';   
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Row,Col} from 'reactstrap';    
import MinutasMeeting from './MinutasMeeting';
import MinutasList from './MinutasList';
import { tracker} from '../../../utils/utils'; 


const Minutas = (props) => {
   /* ################## useState area start ################## */
   const { t } = useTranslation(['minutaproyecto', 'minutameeting', 'common']);  
   
   const [ClaProyecto, setClaProyecto] = useState(0);
   const [ClaTipoMinuta, setClaTipoMinuta] = useState(0);
   const [EsAdminScorecard, setEsAdminScorecard] = useState(0);
   const [errors, setErrors] = useState({}); 
   const [MinutaList, setMinutaList] = useState([]);
   const [EsModoConsulta, setEsModoConsulta] = useState(true); 
   const [IdMinuta, setIdMinuta] = useState(null);   

   useEffect(() => {   
    setClaProyecto (props.ClaProyecto === undefined ? 0 : props.ClaProyecto);
    setClaTipoMinuta (props.ClaTipoMinuta === undefined ? null : props.ClaTipoMinuta);
  }, [props.ClaProyecto, props.ClaTipoMinuta]); 
  
  const onNewClick = () => {     
    setEsModoConsulta(false);
    tracker.trackEvent({
      type: 'Minutas', /* component name */
      action: 'NEW_MEETING'
    }) 

  }
  const onCloseDetailView = (isSaveReturn) => { 
    setEsModoConsulta(true); 
    setIdMinuta(null);
  };
  
  const onClickViewDetailMeeting = (props) => {   
   setEsModoConsulta(false); 
   setIdMinuta(props);
  }

  
    return (
      <>         
        {
          EsModoConsulta? (
            <>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12} className="text-right">   
                  
                  {
                    props.readOnly === true ?
                    (<></>)
                    :
                    ( 
                      <FieldButton label={t('minutaproyecto:Newmeeting')} icon="pi pi-plus" className="p-button-success no-border p-mr-2 rounded-button" onClick={onNewClick} /> 
                    )
                  }
                </Col> 
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}> 
            &nbsp;
                </Col> 
              </Row> 
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}> 
                  <MinutasList ClaTipoMinuta={props.ClaTipoMinuta} ClaProyecto={props.ClaProyecto} onClickViewDetailMeeting={onClickViewDetailMeeting}></MinutasList>
                </Col> 
              </Row> 
            </>            
          ) : (
            <>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}> 
                  <MinutasMeeting ClaTipoMinuta={props.ClaTipoMinuta} readOnly={props.readOnly} IdMinuta={IdMinuta} ClaProyecto={props.ClaProyecto} onCloseDetailView={onCloseDetailView}></MinutasMeeting>
                </Col> 
              </Row>   
            </>
          )
        } 
      </>
    );    
  
};
export default Minutas;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter, Label } from 'reactstrap';
import { config } from 'utils/config';
import { callApi, getSessionItem, showSweetAlert, getDeviceUniqueID } from 'utils/utils';
// import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
// import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon';
import moment from 'moment';
import Highcharts from 'highcharts';
import swal from '@sweetalert/with-react';
import PageContent from 'layouts/PageContent';
import { Toolbar } from 'primereact/toolbar';
import { ProgressBar } from 'primereact/progressbar';
import FieldDropdown from 'components/Controls/FieldDropdown';
// import FieldDropdownMultiSelect from '../../../components/Controls/FieldDropDownMultiSelect/FieldDropdownMultiSelect.jsx';
import FieldText from 'components/Controls/FieldText.jsx';
import FieldNumber from 'components/Controls/FieldNumber.jsx';
import FieldCheckbox from 'components/Controls/FieldCheckbox.jsx';
import FieldAutoComplete from 'components/Controls/FieldAutoComplete.jsx';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx';
import * as yup from 'yup';
import YupValidator from 'utils/YupValidator';
import { Dialog } from 'primereact/dialog';
import Select, { InputActionMeta } from 'react-select';
import { Panel } from 'primereact/panel';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import SurveyManagementContestar from '../SurveyManagement/SurveyManagementContestar';

const VisitLogMovil = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['visitLog', 'common']);
  const [Ubicacion, setUbicacion] = useState({});
  const [EsIniciarEncuesta, setEsIniciarEncuesta] = useState(false);
  const [IdEncuesta, setIdEncuesta] = useState(null);
  const [ClaUbicacion, setClaUbicacion] = useState(null);
  const [IdEncuestaContestada, setIdEncuestaContestada] = useState(null);
  const [DeviceUniqueID, setDeviceUniqueID] = useState(getDeviceUniqueID());
  const [IdRegistroVisitante, setIdRegistroVisitante] = useState(null);  
  const [NombreUbicacion, setNombreUbicacion] = useState(null);  
  const [LabelBtnLink, setLabelBtnLink] = useState(null);  
  const [Estatus, setEstatus] = useState(null);  
  const refreshTimerClock = useRef();
  const [CurrentTime, setCurrentTime] = useState(new Date());


  useEffect(() => {    
    setClaUbicacion(props.ClaUbicacion === undefined || props.ClaUbicacion === null ? 0 : props.ClaUbicacion);
    GetRegistroVisitanteEmcabezado(props.ClaUbicacion);
    refreshTimerClock.current = setInterval(() => { 
      setCurrentTime(new Date());
    }, 1000);
    
    return () => {
      if (refreshTimerClock.current) {
        clearTimeout(refreshTimerClock.current);  
      }  
    }
  }, [props.ClaUbicacion]);

  const GetRegistroVisitanteEmcabezado = (pClaUbicacion) => {
    const urlWebService = `${config.UrlApiProject}VisitLog/GetRegistroVisitanteEmcabezado?ClaUbicacion=${pClaUbicacion}`;
    const paramsToService = {};
    callApi(urlWebService, 'GET', paramsToService, (response) => { 
      setNombreUbicacion(response.data.Ubicacion.length > 0 ? response.data.Ubicacion[0].NombreUbicacion : null); 
      GetRegistroVisitanteMovil(props.ClaUbicacion, DeviceUniqueID);
    });
  };

  const onClickStarSurvey = () => {
    if (Estatus === 0) {
      onClickCheckedOut();
    }else{
      StartSurvey();
    }
  };

  const StartSurvey = () => {
    const urlWebServiceSave = `${config.UrlApiProject}VisitLog/RegistroVisitante`;
    const paramsService = {
      ClaUbicacion,
      IdEncuesta,
      DeviceUniqueID
    };
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {
      const registroVisitante =
        response.data.RegistroVisitante.length > 0 ? response.data.RegistroVisitante[0] : {};
      setIdEncuestaContestada(registroVisitante.IdEncuestaContestada);
      setIdEncuesta(registroVisitante.IdEncuesta);
      setEsIniciarEncuesta(true);
    });
  };

  const onClickCheckedOut = () => {
    const urlWebServiceSave = `${config.UrlApiProject}VisitLog/RegistroVisitante`;
    const paramsService = {
      ClaUbicacion,      
      Accion: 3,
      IdRegistroVisitante,
      DeviceUniqueID
    };
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {
      GetRegistroVisitanteMovil(ClaUbicacion);
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
    });
  };


  const closeModalEsIniciarEncuesta = () => {
    setEsIniciarEncuesta(false);
    GetRegistroVisitanteMovil(ClaUbicacion);
  };

  const onEncuestaTerminada = () => {
    setEsIniciarEncuesta(false);
    GetRegistroVisitanteMovil(ClaUbicacion);
  };

  const GetRegistroVisitanteMovil = (pClaUbicacion) => {
    const urlWebService = `${config.UrlApiProject}VisitLog/GetRegistroVisitanteMovil?ClaUbicacion=${pClaUbicacion}&DeviceUniqueID=${DeviceUniqueID}`;
    const paramsToService = {};
    callApi(urlWebService, 'GET', paramsToService, (response) => {
      setEstatus(response.data.RegistroVisitanteMovil.length > 0 ? response.data.RegistroVisitanteMovil[0].EstatusLink : null);
      setIdRegistroVisitante(response.data.RegistroVisitanteMovil.length > 0 ? response.data.RegistroVisitanteMovil[0].IdRegistroVisitante : null);
    });
  };

  return (
    <>     
      <Row>
        <Col lg={12} md={12} sm={12} xs={12} className="p-3">
          <div>
            <h1>
              {t('visitLog:Welcome')} {NombreUbicacion}
            </h1>
            <span>{moment(CurrentTime).format("MMMM Do YYYY, h:mm:ss a")}</span>
          </div>
        </Col>          
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12} className="p-3">
          <div className="p-fluid">
            <FieldButton
              label={t(Estatus === null ? 'visitLog:CheckIn' : 'visitLog:CheckOut')}
              onClick={(e) => onClickStarSurvey()}
              className="light-green no-border p-mr-2"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {EsIniciarEncuesta ? (
            <SurveyManagementContestar
              IdEncuesta={IdEncuesta}
              onClose={closeModalEsIniciarEncuesta}
              onFinishSubmit={onEncuestaTerminada}
              showAsModal={true}
              isOpen={EsIniciarEncuesta}
              IdEncuestaContestada={IdEncuestaContestada}
            >
            </SurveyManagementContestar>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </>
  );
};
export default VisitLogMovil;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next';   
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row,Col, TabContent, TabPane , Nav, NavItem, NavLink} from 'reactstrap';   
import { config } from '../../../utils/config';
import { callApi,showSweetAlert } from '../../../utils/utils';  
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import FieldTextArea  from '../../../components/Controls/FieldTextArea.jsx'
import FieldText from '../../../components/Controls/FieldText.jsx' 
import FieldUpload from '../../../components/Controls/FieldUpload/FieldUpload.jsx';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator'; 
import swal from '@sweetalert/with-react';
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon'
import { Tooltip } from 'primereact/tooltip';

const ProjectAttachDocument = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);
  const [errors, setErrors] = useState({}); 
  const [ClaProyecto, setClaProyecto] = useState(props.ClaProyecto === undefined ? 0 : props.ClaProyecto);  
  const [ClaTipoDocumentoProyectoRep, setClaTipoDocumentoProyectoRep] = useState(props.ClaTipoDocumentoProyectoRep === undefined ? 1 : props.ClaTipoDocumentoProyectoRep);  
  

  const [Comentarios, setComentarios] = useState(null);
  const [Link, setLink] = useState(null);
  const [UploadName, setUploadName] = useState(null);
  const [FieldData, setFieldData] = useState(null); 
  const [datafiles, setdatafiles] = useState([]); 
  const dt = useRef(null);  
  const fileRef = useRef(null); 

  useEffect(() => {    
    setClaProyecto(props.ClaProyecto);
  }, [props.ClaProyecto]);  
   
  const onChangeComentarios = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.Comentarios;   
    setErrors(newErrors) 
    setComentarios(e.target.value)
  } 

  const onChangeLink = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.Link;   
    setErrors(newErrors) 
    setLink(e.target.value)
  } 
 
const save = async () => {
   
  if(datafiles.length===0){
    showSweetAlert(t('common:Warning'), t('scoreCard:AttachFile'), 'warning');
    return;
  }
  const urlWebServiceSave = `${config.UrlApiProject}ScoreCard/SaveProjectAttachment`;
  const paramsService = {
    
    ClaProyecto, 
    Comentarios,
    Link,
    ClaTipoDocumentoProyectoRep, /* Project Documents */
    FileData:datafiles[0].FileData,
    NomArchivo:datafiles[0].FileName,
    Extension:datafiles[0].FileExtension
  }; 
  callApi(urlWebServiceSave, 'POST', paramsService, (response) => { 
    setComentarios(null);
    setUploadName(null);
    setFieldData(null);
    setLink(null);
    setdatafiles([]); 
    showSweetAlert('success', t('common:SuccessOperation'), 'success'); 
    if (typeof(props.onClose)==='function'){ 
        props.onClose(); 
    }
 })
};
    
  
  
    return (
      <>      
        <Row>
          <Col lg={1} md={1} sm={12} xs={12}> 
            <span className="bold">{t('scoreCard:File')}</span> 
          </Col>
          <Col>
            <FieldUpload files={datafiles} onChange={(files) => setdatafiles(files)}></FieldUpload>
          </Col>
        </Row>
        <Row className="pt-2">
          <Col lg={1} md={1} sm={12} xs={12}> 
            <span className="bold">{t('scoreCard:Comments')}</span>
          </Col>
          <Col>
            <div className="p-fluid"> 
              <FieldTextArea 
                name="Comentarios"
                rows={2}
                value={Comentarios} 
                onChange={onChangeComentarios}
                errors={errors} 
                maxLength={1000}
              />
            </div>
          </Col>
        </Row>
        <Row className="pt-2 pb-5">
          <Col lg={1} md={1} sm={12} xs={12}> 
            <span className="bold">{t('scoreCard:URL')}</span> 
          </Col>
          <Col>
            <div className="p-fluid"> 
              <FieldText   
                name="Link"
                value={Link} 
                onChange={onChangeLink}
                maxLength={200}
                errors={errors}
              />
            </div>
          </Col>
        </Row> 
        <Row>
          <Col className="text-right">
            <FieldButton
              type="button" 
              onClick={save}
              label={t('common:Save')}
              className="p-button-success no-border p-mr-2 rounded-button"
              style={{ marginRight: '.25em' }}
            /> 
          </Col>
        </Row> 
      </> 
        ); 
};
export default ProjectAttachDocument;
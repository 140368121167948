import React, { useEffect, useState } from 'react'; 
import { config } from '../../../utils/config';
import { callApi } from '../../../utils/utils';
import { 
  Row,
  Col
} from 'reactstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import IconButton from '@material-ui/core/IconButton';
import TimelineIcon from '@material-ui/icons/Timeline'; 
import ElementosCostos from "../ElementosCostos/ElementosCostos"; 
import { Tag } from 'primereact/tag';
import { useTranslation } from 'react-i18next'; 
import PropTypes from 'prop-types' 
import Tooltip from '@material-ui/core/Tooltip';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
/* ################################# START COMPONENT ################################# */

const ArticulosCelda = (props) => { 
    const { t } = useTranslation(['formulario','common']);
    const [rowsGrid, setRowsGrid] = useState([]);
    const [showEstadisticas, setshowEstadisticas] = useState(false);
    const [EstadisticasFilters , setEstadisticasFilters] = useState({});
    const [SelectedCustomer, setSelectedCustomer] = useState(null); 
    const [Title, setTitle] = useState('');
   
    useEffect(() => {

      console.log('ArticulosCelda useEffect')
      console.log(props.filters);

        const GetCeldaArticulosGridService = `${config.UrlApiProject}Costos/GetCeldaArticulosGrid`; 
        const paramsToService = {
            anio: props.filters.Anio,
            anioMes: props.filters.AnioMes,
            claTipoElementoCosto: props.filters.ClaTipoElementoCosto,
            claCelda:props.filters.ClaCelda,
            claDepartamento:props.filters.ClaDepartamento,
            claTipoCelda:props.filters.ClaTipoCelda, 
            claArticulo:props.filters.ClaArticulo,
            TipoConversionCost: props.filters.TipoConversionCost
        }; 

        setshowEstadisticas(false); 
        callApi(GetCeldaArticulosGridService, 'POST', paramsToService, (response) => { 
            setRowsGrid(response.data[0]); 
            const GetCeldaTitleService = `${config.UrlApiProject}Costos/GetCeldaElementTitle`; 
            callApi(GetCeldaTitleService, 'POST', paramsToService, (response) => { 
            if(response.data.info!==undefined)
            {  
              setTitle(`${response.data.info[0].NomFecha } ${  response.data.info[0].NomCelda}`);  
            }     
            });
        });
       
      }, [props.filters]); 


    const header  = ( 
      <>  
        <div className="row">
          <div className="col-12 col-lg-6 col-sm-12"> 
            {t('formulario:FullListOfArticlesBoughtBy')}&nbsp;
            {Title} 
          </div>
          <div className="col-12 col-lg-6 col-sm-12 text-right">
            <Tag className="danger">80%</Tag>&nbsp;<Tag className="warning">17%</Tag>&nbsp;<Tag className="light">3%</Tag>&nbsp;<Tag className="success">(-)</Tag>
          </div>
        </div>  
      </>
      )

      const TonsColumn = (row) => {  
        return (<>{row.MTons === null ? '' : row.MTons.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>);
    }
  
    const StandarCostColumn = (row) => {  
      return (<>${ row.StandarCost === null ? '' : row.StandarCost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>);
    }
    
    const RealCostColumn = (row) => {  
      return (<>${ row.RealCost === null ? '' : row.RealCost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>);
    }
  
    const AcumuladoRealCostColumn = (row) => {   
      return (<><Tag className={row.Clasificacion === 1 ? "danger" : row.Clasificacion === 2 ? "warning" : row.Clasificacion === 3 ? "light" : row.Clasificacion === 4 ? "success" : ""}>${row.AcumuladoRealCost === null ? '' : row.AcumuladoRealCost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Tag></>);
    }

      const ActionsColumn = (row) =>  {    
        return (
          <>
            <Tooltip title={t('formulario:TooltipDetailByElement')}>
              <IconButton onClick={(e) => onDetailBucket(row)} size="small" color="primary"><TimelineIcon /></IconButton> 
            </Tooltip> 
          </>
        );
      }
      
      const onDetailBucket = (row) =>{
        const filters = { ...props.filters }; 
        filters.ClaCelda = row.IdCelda;
        filters.ClaArticulo = row.ClaArticulo;
        setEstadisticasFilters(filters);
        setshowEstadisticas(true); 
      }
      
    const onRowSelect = (event) => {  
        onDetailBucket(event.data); 
    }
     
    return (
      <>    
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
            <FieldDataTable 
              value={rowsGrid} 
              header={header} 
              scrollable 
              scrollHeight="270px" 
              className="p-datatable-striped"
              selection={SelectedCustomer} 
              selectionMode="single" 
              onSelectionChange={e => setSelectedCustomer(e.value)} 
              onRowSelect={onRowSelect}
            >   
              <Column body={ActionsColumn} header={t('formulario:ViewDetail')} headerStyle={{ width: '110px' }}></Column>  
              <Column field="ClaveArticulo" columnKey="ClaveArticulo" header={t('formulario:ItemCode')} headerStyle={{ width: '330px' }}></Column> 
              <Column field="NomArticulo" columnKey="NomArticulo" header={t('formulario:ItemName')} headerStyle={{ width: '330px' }}></Column> 
              <Column body={TonsColumn} header={t('formulario:Tons')} headerStyle={{ width: '80px' }} columnKey="MTons"></Column>    
              <Column body={StandarCostColumn} header={t('formulario:StdCost')} headerStyle={{ width: '110px' }} columnKey="StandarCost"></Column>  
              <Column body={RealCostColumn} header={t('formulario:RealCost')} headerStyle={{ width: '110px' }} columnKey="RealCost"></Column>  
              <Column body={AcumuladoRealCostColumn} header={t('formulario:AcumTons')} headerStyle={{ width: '150px' }} columnKey="AcumuladoRealCost"></Column>  
            </FieldDataTable>    
          </Col>  
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
                &nbsp;
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
                &nbsp;
          </Col>
        </Row>
        <Row>
          <Col>&nbsp;</Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
            {
                SelectedCustomer !== null ?   <ElementosCostos showHide={showEstadisticas} filters={EstadisticasFilters} />
                : <></>
              } 
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
             &nbsp;
          </Col>
        </Row> 
      </>
    ); 
};
export default ArticulosCelda;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi, showSweetAlert } from '../../../utils/utils';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import FieldTextArea from '../../../components/Controls/FieldTextArea.jsx';
import FieldUpload from '../../../components/Controls/FieldUpload/FieldUpload.jsx';
import FieldDropdown from '../../../components/Controls/FieldDropdown.jsx';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import swal from '@sweetalert/with-react';
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon';
import { Tooltip } from 'primereact/tooltip';
import '../../HR/Survey/SurveyManagement/Survey.css';
import FieldText from 'components/Controls/FieldText.jsx';
import FieldNumber from 'components/Controls/FieldNumber.jsx';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx';
import FieldMultiSelect from 'components/Controls/FieldMultiSelect.jsx';
import FieldSelectButton from 'components/Controls/FieldSelectButton.jsx';
import LibraryAttributeTipoCampo from './LibraryAttributeTipoCampo.jsx';
import PageContent from 'layouts/PageContent.jsx';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import LibraryAttributeTipoCampoEdit from './LibraryAttributeTipoCampoEdit.jsx';
import FieldCheckbox from 'components/Controls/FieldCheckbox.jsx';
import FieldAutoComplete from 'components/Controls/FieldAutoComplete.jsx';

const FileLibraryUpLoadEdit = ({ IdLibraryFile, EsShowDialog, onCloseClick }) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['dms', 'common']);
  const [errors, setErrors] = useState({});
  const [datafiles, setdatafiles] = useState([]);
  const [OpcionesLibrary, setOpcionesLibrary] = useState([]);
  const [ClaLibrary, setClaLibrary] = useState(null);
  const [AttributeList, setAttributeList] = useState([]);
  const [AttributeOpciones, setAttributeOpciones] = useState([]);
  const [ImgModified, setImgModified] = useState(false);
  const [FileDataRead, setFileDataRead] = useState(null);
  const [EsArchivoPrivado, setEsArchivoPrivado] = useState(false);
  const [UsuarioRoles, setUsuarioRoles] = useState([]);
  const [UsuarioRolesAux, setUsuarioRolesAux] = useState([]);
  const [filteredResponsibleParticipants, setfilteredResponsibleParticipants] = useState([]);
  const [EsMostrarSoloUsuarioMSW, setEsMostrarSoloUsuarioMSW] = useState(false);
  const [TitleFile, setTitleFile] = useState(null);
  const [DescriptionFile, setDescriptionFile] = useState(null);
  const [ShowInHome, setShowInHome] = useState(false);
  const [ThumbnailFile, setThumbnailFile] = useState([]);

  useEffect(() => {
    obtenerLibrary();
    if (IdLibraryFile !== null) {
      GetLibraryFileById(IdLibraryFile);
    }
    setErrors({});
  }, [IdLibraryFile, EsShowDialog, onCloseClick]);

  const GetLibraryFileById = (pIdLibraryFile) => {
    const getLibraryFileById = `${config.UrlApiProject}DMS/GetMSWTraBSCDMLibraryFileById`;
    const paramsToService = {
      IdLibraryFile: pIdLibraryFile,
    };
    callApi(getLibraryFileById, 'POST', paramsToService, (response) => {
      const LibraryFile =
        response.data.LibraryFileById.length > 0 ? response.data.LibraryFileById[0] : {};

      
      const claLibrary = LibraryFile.ClaLibrary;
      const titleFile = LibraryFile.TitleFile;
      const descriptionFile = LibraryFile.DescriptionFile;
      const showInHome = LibraryFile.ShowInHome;

      setClaLibrary(claLibrary);
      setEsArchivoPrivado(LibraryFile.EsArchivoPrivado);

      setTitleFile(titleFile);
      setDescriptionFile(descriptionFile);
      setShowInHome(showInHome);

      // const base64Icon = `data:image/${LibraryFile.TipoExtension};base64,${LibraryFile.FileData}`;
      // const FileInfo = [
      //   {
      //     FilenName: `${LibraryFile.NomLibraryFile}.${LibraryFile.TipoExtension}`,
      //     FileData: LibraryFile.FileData,
      //     objectURL: base64Icon,
      //     FileSize: LibraryFile.FileSize,
      //     FileExtension: LibraryFile.Extension,
      //   },
      // ];

      // setdatafiles(FileInfo);
      loadFile(
        pIdLibraryFile,
        LibraryFile.NomLibraryFile,
        LibraryFile.TipoExtension,
        LibraryFile.FileSize,
        LibraryFile.Extension
      );
      loadThumbnail(pIdLibraryFile);
      setFileDataRead(LibraryFile.FileData);
      setAttributeList(response.data.LibraryFileAttributeById);
      setAttributeOpciones(bindOpciones(response.data.LibraryFileOptionById));
      bindUsuarioRoles(response.data.UserRol);
    });
  };

  const loadFile = (pIdLibraryFile, pNomLibraryFile, pTipoExtension, pFileSize, pExtension) => {
    const GetFileData = `${config.UrlApiProject}DMS/GetLibraryFileById`;
    const paramsToService = {
      IdLibraryFile: pIdLibraryFile,
    };
    callApi(GetFileData, 'POST', paramsToService, (response) => {
      const AnexoDetalle = response.data.AnexoDetalle[0];
      const AnexoImg = response.data.ImgDownLoad;
      pFileSize = response.data.FileSize;      
      if (AnexoDetalle !== undefined) {
        const base64Icon = `data:image/${pTipoExtension};base64,${AnexoImg}`;
        const FileInfo = [
          {
            FileName: `${pNomLibraryFile}.${pTipoExtension}`,
            FileData: AnexoImg,
            objectURL: base64Icon,
            FileSize: pFileSize,
            FileExtension: pExtension,
          },
        ];
        setdatafiles(FileInfo);
      }
    });
  };

  const loadThumbnail = (pIdLibraryFile) => {
    const urlWebService = `${config.UrlApiProject}DMS/GetMSWTraBSCDMLibraryFileThumbnailById?IdLibraryFile=${pIdLibraryFile}`;
    const paramsService = {};
    callApi(urlWebService, 'GET', paramsService, (response) => {
      console.log('response.data',response.data); // FIXME
      const thumbnailFile = response.data.ThumbnailFile;
      const fileSize = response.data.ThumbnailFileSize;      
      if (thumbnailFile !== undefined && thumbnailFile !== null) {
        const base64Icon = `data:image/${response.data.ThumbnailExtensionType};base64,${thumbnailFile}`;
        const FileInfo = [
          {
            FileName: `NewsletterThumbnail${response.data.ThumbnailExtension}`,
            FileData: thumbnailFile,
            objectURL: base64Icon,
            FileSize: fileSize,
            FileExtension: response.data.ThumbnailExtension,
          },
        ];
        setThumbnailFile(FileInfo);
        console.log('FileInfo',FileInfo) // FIXME
      }
    });
  }

  const obtenerLibrary = () => {
    const getLibraryCmb = `${config.UrlApiProject}DMS/GetLibraryByUserCmb`;
    const paramsToService = {};
    callApi(getLibraryCmb, 'GET', paramsToService, (response) => {
      setOpcionesLibrary(response.data.Library);
    });
  };

  const ondocFielUpLoadChange = (files) => {
    const newErrors = { ...errors };
    delete newErrors.datafiles;
    setErrors(newErrors);
    setdatafiles(files);
    setImgModified(true);
  };

  const onThumbnailUploadChange = (files) => {
    const newErrors = { ...errors };
    delete newErrors.thumbnailFile;
    setErrors(newErrors);
    setThumbnailFile(files);
    // setImgModified(true);
  };

  const onClaLibraryChange = (e) => {
    const newErrors = { ...errors };
    delete newErrors.ClaLibrary;
    setErrors(newErrors);
    setClaLibrary(e.value);
    GetLibraryAttribute(e.value);
    setErrors({});
  };

  const GetLibraryAttribute = (pClaLibrary) => {
    const getLibraryAttribute = `${config.UrlApiProject}DMS/GetLibraryAttribute?ClaLibrary=${pClaLibrary}`;
    const paramsToService = {};
    callApi(getLibraryAttribute, 'GET', paramsToService, (response) => {
      setAttributeList(response.data.ListAttibute);
      GetLibraryAttributeOpciones(pClaLibrary);
    });
  };

  const GetLibraryAttributeOpciones = (pClaLibrary) => {
    const getLibraryAttribute = `${config.UrlApiProject}DMS/GetLibraryAttributeOpciones?ClaLibrary=${pClaLibrary}`;
    const paramsToService = {};
    callApi(getLibraryAttribute, 'GET', paramsToService, (response) => {
      setAttributeOpciones(bindOpciones(response.data.ListOpciones));
    });
  };

  const bindOpciones = (rows) => {
    const activeRows = [];
    if (rows !== undefined) {
      if (rows.length > 0) {
        rows.forEach((item, index) => {
          item.RowIndex = index;
          activeRows.push({ ...item });
        });
      } else {
        return [];
      }
    }

    return activeRows;
  };

  const showActiveRows = (rows) => {
    const activeRows = [];
    if (rows !== undefined) {
      if (rows.length > 0) {
        rows.forEach((item, index) => {
          if (!item.BajaLogica || item.BajaLogica === 0 || item.BajaLogica === undefined) {
            item.BajaLogica = 0;
            item.RowIndex = index;
            activeRows.push({ ...item });
          }
        });
      } else {
        return [];
      }
    }

    return activeRows;
  };

  const onValueChangedQuestion = (RowIndex, pClaAttribute, newValue, pAttributeOpciones) => {
    const newAttributeList = [...AttributeList];
    const newErrors = { ...errors };
    newAttributeList[RowIndex].RespuestaAttribute = newValue;
    newAttributeList[RowIndex].ClaAttribute = pClaAttribute;

    // opciones
    if (pAttributeOpciones !== null) {
      const newAttributeOpciones = [...AttributeOpciones];
      const filternewAttributeOpciones = pAttributeOpciones.AttributeOpciones;
      if (Array.isArray(newValue)) {
        newValue.forEach((opt) => {
          const opciones = filternewAttributeOpciones.filter(
            (item) => item.ClaLibraryAttributeOption === opt
          )[0];
          newAttributeOpciones[opciones.RowIndex].RespuestaPregunta = opt;
          setAttributeOpciones(newAttributeOpciones);
        });
      } else {
        const opcionesArray = filternewAttributeOpciones.filter(
          (item) => item.ClaLibraryAttributeOption === newValue
        )[0];
        newAttributeOpciones[opcionesArray.RowIndex].RespuestaPregunta = newValue;
        setAttributeOpciones(newAttributeOpciones);
      }
    }

    setAttributeList(newAttributeList);
  };

  const saveUpLoadFile = async () => {
    const attributeOpcList = [...AttributeList];

    attributeOpcList.forEach((element) => {
      if (Array.isArray(element.RespuestaAttribute)) {
        let opc = '';
        element.RespuestaAttribute.map((item, index) => {
          return (opc = `${opc + element.RespuestaAttribute[index]},`);
        });
        element.RespuestaAttribute = opc.slice(0, -1);
        // element.ClaAttribute = element.ClaAttribute;
      }
    });

    const value = {
      ClaLibrary,
      TitleFile,
      DescriptionFile,
      datafiles,
      AttributeList: attributeOpcList,
    };

    const validator = new YupValidator(valitationSchemeSave);
    const esValido = await validator.validate(value);

    if (!esValido) {
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      return;
    }

    const urlWebServiceSave = `${config.UrlApiProject}DMS/SaveFolderLibraryFile`;
    const paramsService = {
      ClaLibrary,
      IdLibraryFile,
      FileData: ImgModified ? datafiles[0].FileData : FileDataRead,
      NomLibraryFile: ImgModified ? datafiles[0].FileName : null,
      Extension: ImgModified ? datafiles[0].FileExtension : null,
      LibraryAttributeListDTO: AttributeList,
      LibraryAttributeOptionListDTO: AttributeOpciones,
      ImgModified,
      RolesListDTO: UsuarioRoles,
      EsArchivoPrivado,
      TitleFile,
      DescriptionFile,
      ShowInHome,
      ThumbnailFile: ThumbnailFile.length > 0 ? ThumbnailFile[0].FileData : null,
      ThumbnailExtension: ThumbnailFile.length > 0 ? ThumbnailFile[0].FileExtension : null
    };
    
    // FIXME
    console.log('ThumbnailFile', ThumbnailFile);
    console.log('paramsService', paramsService);

    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {
      setClaLibrary(null);
      setdatafiles([]);
      setThumbnailFile([]);
      setAttributeList([]);
      setAttributeOpciones([]);
      setErrors({});
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      onHideAP();
    });
  };

  const valitationSchemeSave = yup.object().shape({
    ClaLibrary: yup.mixed().required(t('common:RequiredField')).nullable(),
    TitleFile: yup.string().required(t('common:RequiredField')).nullable(),
    DescriptionFile: yup.string().required(t('common:RequiredField')).nullable(),
    datafiles: yup
      .array()
      .min(1, t('common:RequiredField'))
      .required(t('common:RequiredField'))
      .nullable(),
    AttributeList: yup.array().of(
      yup.object().shape({
        RespuestaAttribute: yup.string().when('EsObligatorio', {
          is: true,
          then: yup.string().trim().required(t('common:RequiredField')).nullable(),
          otherwise: yup.string().nullable(),
        }),
      })
    ),
  });

  const onHideAP = () => {
    document.body.classList.remove('bodynotscroll');

    if (onCloseClick !== null && typeof onCloseClick === 'function') {
      onCloseClick();
    }
  };

  const renderFooter = () => {
    return (
      <>
        <Toolbar
          left={leftToolbarFooterNewAPTemplate}
          right={rightToolbarFooterNewAPTemplate}
        >
        </Toolbar>
      </>
    );
  };
  const leftToolbarFooterNewAPTemplate = () => {
    return (
      <>
        <FieldButton
          label={t('common:Close')}
          icon="fas fa-times"
          className="warning no-border p-mr-2 rounded-button"
          onClick={
            typeof onCloseClick !== 'undefined' && typeof onCloseClick === 'function'
              ? onCloseClick
              : null
          }
        />
      </>
    );
  };
  const rightToolbarFooterNewAPTemplate = () => {
    return (
      <>
        <FieldButton
          label={t('common:Save')}
          icon="pi pi-save"
          className="light-green no-border p-mr-2"
          onClick={saveUpLoadFile}
        >
        </FieldButton>
      </>
    );
  };

  const renderHeader = () => {
    return (
      <div>
        <Row className="text-center">
          <Col lg={12} md={12} sm={12} xs={12} className="text-center">
            {IdLibraryFile !== null ? <>{t('dms:Edit')} :</> : <>{t('dms:New')}</>}
            {/* <span>{SalesPerson} </span> */}
          </Col>
        </Row>
      </div>
    );
  };

  const onChangeEsPrivado = (e) => {
    setEsArchivoPrivado(!!e.checked);
    if (e.checked) {
      if (UsuarioRolesAux.length > 0) {
        setUsuarioRoles(UsuarioRolesAux);
      }
    } else {
      setUsuarioRoles([]);
    }
  };

  /*
  const GetLibraryRole = (pClaLibrary) => {
    const getLibraryRole = `${config.UrlApiProject}DMS/GetLibraryRole?ClaLibrary=${pClaLibrary}`;
    const paramsToService = {};
    callApi(getLibraryRole, 'GET', paramsToService, (response) => {
      bindUsuarioRoles(response.data.ListRol);
    });
  };
  */

  const bindUsuarioRoles = (listUsuarioRoles) => {
    const arrayUsuarioRoles = [];
    let RowValues = {};
    for (let i = 0; i < listUsuarioRoles.length; i++) {
      RowValues = {
        ...listUsuarioRoles[i],
        ClaColaborador: listUsuarioRoles[i].ClaUsuario,
        ClaUsuario: {
          ClaColaborador: listUsuarioRoles[i].ClaUsuario,
          NomColaborador: listUsuarioRoles[i].NomUsuario,
        },
      };
      arrayUsuarioRoles.push(RowValues);
    }
    setUsuarioRoles(arrayUsuarioRoles);
    setUsuarioRolesAux(arrayUsuarioRoles);
  };

  const headerTemplateResponsableRol = (
    <>
      <Row>
        <Col>{t('dms:UserWithAccessToThisFile')}</Col>
        <Col className="text-right">
          <FieldButton
            className="p-button p-button-icon-only p-button-info rounded-button"
            onClick={(e) => AddResponsableRol()}
          >
            <i className="pi pi-plus"></i>
          </FieldButton>
        </Col>
      </Row>
    </>
  );

  const AddResponsableRol = () => {
    const newRow = {
      RowIndex: UsuarioRoles.length,
      ClaUsuario: null,
      NomUsuario: null,
      ClaColaborador: null,
      Bajalogica: 0,
    };
    setUsuarioRoles([...UsuarioRoles, newRow]);
  };

  const fieldsRequiredRol = (rowData, props) => {
    return (
      <>
        {errors[`UsuarioRoles[${props.rowData.RowIndex}]`] != null ? (
          <i className="fas fa-exclamation-triangle invalid-color"></i>
        ) : (
          ''
        )}
      </>
    );
  };

  const BotonesTemplateRol = (rowData, e) => {
    return (
      <>
        <FieldButton
          icon="pi pi-trash"
          onClick={() => onRemoveResponsableRolesClick(rowData)}
          className="p-button-rounded p-button-warning"
        />
      </>
    );
  };

  const onRemoveResponsableRolesClick = (item) => {
    const copyeUsuarioRoles = [...UsuarioRoles];
    copyeUsuarioRoles[item.RowIndex].BajaLogica = 1;
    setUsuarioRoles(copyeUsuarioRoles);
  };

  const ResponsibleParticipantsEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldAutoComplete
          dropdown
          name="ClaUsuario"
          value={props.rowData.ClaUsuario}
          onChange={(e) => onChangeClaUsuarioResponsableParticipants(props, e)}
          suggestions={filteredResponsibleParticipants}
          field="NomColaborador"
          completeMethod={(e) => filterResponsibleParticipants(props, e)}
          errors={errors[`UsuarioRoles[${props.rowData.RowIndex}]`]}
        />
      </div>
    );
  };
  const filterResponsibleParticipants = (props, event) => {
    let valor = '';
    valor = event.query;
    const paramsServiceOwners = {};
    // event.query
    const urlWebServiceOwners = `${
      config.UrlApiProject
    }DMS/GetRolesColaboradorCmb?descriptionFilter=${valor}&EsMostrarSoloUsuarioMSW=${
      !EsMostrarSoloUsuarioMSW ? 1 : 0
    }`;
    // Get owner
    callApi(urlWebServiceOwners, 'GET', paramsServiceOwners, (response) => {
      setfilteredResponsibleParticipants(response.data[0]);
    });
  };

  const onChangeClaUsuarioResponsableParticipants = (props, e) => {
    const updatedUsuarioRoles = [...UsuarioRoles];
    if (e.value != null && typeof e.value === 'object') {
      const responsible = filteredResponsibleParticipants.filter(
        (item) => item.ClaColaborador === e.value.ClaColaborador
      )[0];

      if (responsible !== undefined) {
        updatedUsuarioRoles[props.rowData.RowIndex].NomUsuario = responsible.NomColaborador;
        updatedUsuarioRoles[props.rowData.RowIndex].ClaUsuario = responsible.ClaColaborador;
        updatedUsuarioRoles[props.rowData.RowIndex].ClaColaborador = responsible.ClaColaborador;
      }
    } else {
      updatedUsuarioRoles[props.rowData.RowIndex].NomUsuario = null;
    }
    updatedUsuarioRoles[props.rowData.RowIndex].ClaUsuario = e.value;
    setUsuarioRoles(updatedUsuarioRoles);
  };

  const onChangeTitleFile = (value) => {
    const newErrors = { ...errors };
    delete newErrors.TitleFile;
    setErrors(newErrors);
    setTitleFile(value);
  };

  const onChangeDescriptionFile = (value) => {
    const newErrors = { ...errors };
    delete newErrors.DescriptionFile;
    setErrors(newErrors);
    setDescriptionFile(value);
  };

  const onChangeShowInHome = (e) => {
    setShowInHome(!!e.checked);    
  };

  return (
    <>
      <Dialog
        visible={EsShowDialog}
        modal
        style={{ width: '100vw' }}
        footer={renderFooter}
        header={renderHeader}
        onHide={onHideAP}
        closable={false}
        className="dialog-header-background-blue"
        maximized={true}
        draggable={false}
        blockScroll={true}
      >
        <Row className="pt-2 pb-5">
          <Col lg={1} md={1} sm={12} xs={12}>
            <span className="bold">{t('dms:NomLibrary')}</span>
          </Col>
          <Col>
            <div className="p-fluid">
              <FieldDropdown
                name="ClaLibrary"
                value={ClaLibrary}
                options={OpcionesLibrary}
                optionValue="ClaLibrary"
                optionLabel="NomLibrary"
                onChange={(e) => onClaLibraryChange(e)}
                errors={errors}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={1} md={1} sm={12} xs={12}>
            <span className="bold">{t('dms:File')}</span>
          </Col>
          <Col>
            <FieldUpload
              name="datafiles"
              files={datafiles}
              onChange={(files) => ondocFielUpLoadChange(files)}
              // onChange={(files) => setdatafiles(files)}
              errors={errors}
              maxFileSize={60000000}
            >
            </FieldUpload>
          </Col>
        </Row>
        <Row className='pt-2'>
          <Col lg={1} md={1} sm={12} xs={12}>
            <span className="bold">{t('dms:Thumbnail')}</span>
          </Col>
          <Col>
            <FieldUpload
              name="thumbnailFile"
              files={ThumbnailFile}
              onChange={(files) => onThumbnailUploadChange(files)}
              // onChange={(files) => setdatafiles(files)}
              errors={errors}
              maxFileSize={60000000}
            >
            </FieldUpload>
          </Col>
        </Row>
        <Row className="pt-2">
          <Col lg={1} md={1} sm={12} xs={12}>
            <span className="bold">{t('dms:TitleFile')}</span>
          </Col>
          <Col>
            <div className="p-fluid">
              <FieldText
                name="TitleFile"
                value={TitleFile}
                onChange={(e) => onChangeTitleFile(e.target.value)}
                errors={errors}
                autoFocus
                maxLength={250}
              />
            </div>
          </Col>
        </Row>
        <Row className="pt-2">
          <Col lg={1} md={1} sm={12} xs={12}>
            <span className="bold">{t('dms:DescriptionFile')}</span>
          </Col>
          <Col>
            <div className="p-fluid">
              <FieldTextArea
                name="DescriptionFile"
                value={DescriptionFile}
                onChange={(e) => onChangeDescriptionFile(e.target.value)}
                errors={errors}
                autoFocus
                maxLength={3000}
                rows={4}
              />
            </div>
          </Col>
        </Row>
        <Row className="pt-2 pb-5">
          <Col lg={1} md={1} sm={12} xs={12}>
            <span className="survey-edit-label">{t('dms:ShowInHome')}</span>
          </Col>
          <Col lg={2} md={2} sm={12} xs={12}>
            <div className="p-fluid">
              <FieldCheckbox
                inputId={ShowInHome}
                checked={ShowInHome}
                onChange={(e) => onChangeShowInHome(e)}
              />
            </div>
          </Col>
          <Col lg={1} md={1} sm={12} xs={12}>
            <span className="survey-edit-label">{t('dms:FilePrivate')}</span>
          </Col>
          <Col lg={2} md={2} sm={12} xs={12}>
            <div className="p-fluid">
              <FieldCheckbox
                inputId={EsArchivoPrivado}
                checked={EsArchivoPrivado}
                onChange={(e) => onChangeEsPrivado(e)}
              />
            </div>
          </Col>
        </Row>
        {EsArchivoPrivado ? (
          <>
            <Row className="pt-2">
              <Col lg={12} md={12} sm={12} xs={12}>
                <FieldDataTable
                  value={showActiveRows(UsuarioRoles)}
                  header={headerTemplateResponsableRol}
                  scrollable
                  scrollHeight="270px"
                  className="editable-cells-table"
                  editMode="cell"
                >
                  <Column body={fieldsRequiredRol} headerStyle={{ width: '20px' }}></Column>
                  <Column
                    body={BotonesTemplateRol}
                    style={{ textAlign: 'center' }}
                    headerStyle={{ width: '35px' }}
                  >
                  </Column>
                  <Column
                    field="NomUsuario"
                    header={t('dms:UserName')}
                    columnKey="ClaUsuario"
                    headerStyle={{ width: '260px' }}
                    editor={(props) => ResponsibleParticipantsEditor(props)}
                  >
                  </Column>
                </FieldDataTable>
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )}
        <Row className="pt-2 align-items-start"> 
          {AttributeList.length >= 1 ? (
              showActiveRows(AttributeList).map((element, index) => {
                return (
                  <Col lg={3} md={4} sm={12} xs={12} key={index}>
                    <div
                      key={index}
                      className={
                      errors[`AttributeList[${index}]`] != null ? 'survey_question_required' : ''
                    }
                    >
                      <LibraryAttributeTipoCampoEdit
                        ClaLibrary={ClaLibrary}
                        ClaLibraryAttribute={element.ClaLibraryAttribute}
                        onValueChangedQuestion={onValueChangedQuestion}
                        AttributeOpciones={AttributeOpciones.filter(
                        (opc) => opc.ClaLibraryAttribute === element.ClaLibraryAttribute
                      )}
                        ClaFormularioTipoCampo={element.ClaFormularioTipoCampo}
                        NomLibraryAttribute={element.NomLibraryAttribute}
                        Index={element.RowIndex}
                        RespuestaAttribute={element.RespuestaAttribute}
                        errors={errors}
                      >
                      </LibraryAttributeTipoCampoEdit>
                    </div>
                  </Col>
                );
              })
            ) : (
              <></>
            )}
         
        </Row>
        {/* <Row>
          <Col className="text-right">
            <FieldButton
              type="button"
              onClick={saveUpLoadFile}
              label={t('common:Save')}
              className="p-button-success no-border p-mr-2 rounded-button"
              style={{ marginRight: '.25em' }}
            />
          </Col>
        </Row> */}
      </Dialog>
    </>
  );
};

export default FileLibraryUpLoadEdit;

import React, { useState, useEffect } from 'react';
import {
  Card, 
  CardBody, 
  Row,
  Col, 
  label,
} from 'reactstrap'; 
import Button from '@material-ui/core/Button';
import AppsIcon from '@material-ui/icons/Apps';
import Help from '@material-ui/icons/Help';
import { config } from '../../utils/config';
import { callApi } from '../../utils/utils'; 
import { Tag } from 'primereact/tag';
import { useTranslation } from 'react-i18next';
import ProductionsDashboardsFilters from './ProductionsDashboardsFilters';
import DailyOperationDashboard from "./DailyOperationDashboard/DailyOperationDashboard";
import OperationDowntimesDashboard from "./OperationDownTimes/OperationDowntimesParetos"
import OperationScrapDashboard from "./OperationScrap/OperationScrapDashboard";
import { TabView, TabPanel } from 'primereact/tabview';
import Joyride, { CallBackProps, STATUS, Step, StoreHelpers } from 'react-joyride';  
import PageContent from '../../layouts/PageContent'; 
import ActiveDowntimes from './ActiveDowntimes/ActiveDowntimes';
import ActiveDowntimesComponent from './ActiveDowntimes/ActiveDowntimesComponent';

const ProductionsDashboards = (props) => { 
  /* ################## useState area start ################## */
const { t } = useTranslation(['formulario', 'common']);
const [showContent, setshowContent] = useState(false);
const [filters, setfilters] = useState({ ClaZona:null });  
const [activeIndex, setActiveIndex] = useState(0);
  /* ################## End useState area start ################## */
 

  const onSearch = (dataFilter) => {  
      console.log('onSearch'); 
      console.log(dataFilter);
      setfilters(dataFilter);
      if (dataFilter.ClaZona > 0){
        setshowContent(true); 
      }
      else{
        setshowContent(false);
      }
  }  
 
  return (
    <>
      <PageContent title={t('formulario:ProductionsDashboards')}>   
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>  
            <ProductionsDashboardsFilters onSearch={onSearch} filters={filters} /> 
          </Col> 
        </Row> 
        <Row>
          <Col>&nbsp;</Col>
        </Row> 
        { showContent === true ? ( 
          <div> 
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}> 
              <TabPanel header={t('formulario:DailyOperationDashboard')} headerClassName="nav-item flex-sm-fill">
                <DailyOperationDashboard filters={filters} />
              </TabPanel>
              <TabPanel header={t('formulario:OperationDowntimesDashboard')} headerClassName="nav-item flex-sm-fill">
                <OperationDowntimesDashboard filters={filters} /> 
              </TabPanel>
              <TabPanel header={t('formulario:OperationDashboardScrap')} headerClassName="nav-item flex-sm-fill">
                <OperationScrapDashboard filters={filters} /> 
              </TabPanel> 
              <TabPanel header={t('productionDashboard:ActiveDowntimesTitle')} headerClassName="nav-item flex-sm-fill">
                <ActiveDowntimesComponent filters={filters} />
              </TabPanel>
            </TabView>
          </div> 
     )
     :<></>}
      </PageContent>
    </>
  );
};

export default ProductionsDashboards;

import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Label, Row, Col, Card, CardBody, CardHeader, CardFooter } from 'reactstrap';
import FieldButton from 'components/Controls/FieldButton/FieldButton';

import './EnterTicketEmail.scss'

const EnterTicketEmail = () => {
  const {t, i18n} = useTranslation(['it', 'common']);

  return (
    <>
      <Row>
        <Col>
          <div className='mb-4'>
            <h2 style={{color: 'black'}}>{t("it:NeedHelpFrom")}</h2>
          </div>
          <div
            className='ticket-selection mb-4'
            onClick={() => window.location.href = 'mailto:softwaresupport@mcswusa.com?'}
          >
            <div className='ticket-selection-title mb-2'>{t("it:SoftwareSupport")}</div>
            <div>{t("it:SoftwareSupportDesc")}</div>
          </div>
          <div
            className='ticket-selection'
            onClick={() => window.location.href = 'mailto:itsupport@mcswusa.com?'}
          >
            <div className='ticket-selection-title mb-2'>{t("it:ITHelpDesk")}</div>
            <div>{t("it:ITHelpDeskDesc")}</div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default EnterTicketEmail;
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../utils/utils';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon';
import moment from 'moment';
import Highcharts from 'highcharts';
import swal from '@sweetalert/with-react';
import PageContent from 'layouts/PageContent';
import { Toolbar } from 'primereact/toolbar';
import { ProgressBar } from 'primereact/progressbar';
import FieldDropdown from 'components/Controls/FieldDropdown'; 
import ProyectoKPIManagementFullView from './ProyectoKPIManagementFullView';
import FieldText from 'components/Controls/FieldText.jsx';
import FieldNumber from 'components/Controls/FieldNumber.jsx';
import FieldCheckbox from 'components/Controls/FieldCheckbox.jsx';
import FieldAutoComplete from 'components/Controls/FieldAutoComplete.jsx';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx' 
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator'; 
import { Dialog } from 'primereact/dialog';
import OrangePage from './OrangePage.jsx';
import { Link } from 'react-router-dom';
import FieldTextArea from 'components/Controls/FieldTextArea.jsx';
import ReporteAvanceMensualProyectoAttachment from './ReporteAvanceMensualProyectoAttachment.jsx';
import KPIFormulaResultadoReview from '../Scorecard/KPIsFormulaSetUp/KPIFormulaResultadoReview.jsx';

const ReporteAvanceMensualProyecto = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['reportevanceMensualProyecto', 'common']);
  const [ClaProyecto, setClaProyecto] = useState(0);
  const [AnioMes, setAnioMes] = useState(0);
  const [IdReporteAvanceMensualProyecto, setIdReporteAvanceMensualProyecto] = useState(0);
  const [ReporteAvanceProyectoKPIList, setReporteAvanceProyectoKPIList] = useState([]);
  const [SeguimientoFasesList, setSeguimientoFasesList] = useState([]);
  const [SoporteList, setSoporteList] = useState([]);
  const [EncabezadoList, setEncabezadoList] = useState([]);  
  const [openViewFull, setopenViewFull] = useState(false);
  const [PresenteList, setPresenteList] = useState([]);
  const [ActividadesAConcluirIList, setActividadesAConcluirIList] = useState([]);
  const [ActividadesCompletadasList, setActividadesCompletadasList] = useState([]);
  const [FuturoList, setFuturoList] = useState([]);
  const [ActividadesSiguienteList, setActividadesSiguienteList] = useState([]);
  const [EntregableList, setEntregableList] = useState([]);
  const [filteredResponsibleParticipants, setfilteredResponsibleParticipants] = useState([]);
  const [LabelRealProgress, setLabelRealProgress] = useState(0);
  const [LabelEstProgress, setLabelEstProgress] = useState(0);
  const [errors, setErrors] = useState({});  
  const [SeguimientoFasesListeningList, setSeguimientoFasesListeningList] = useState([]);
  const [EsProyectoMaestro, setEsProyectoMaestro] = useState(0);
  const [ClaTipoProyecto, setClaTipoProyecto] = useState(null);
  const [EsMostrarSoloUsuarioMSW, setEsMostrarSoloUsuarioMSW] = useState(false);
  const [EsMostrarProgressReportParent, setEsMostrarProgressReportParent] = useState(false);
  const [claSelectedProject, setclaSelectedProject] = useState(null);
  const [idSelectedReporteMensual, setidSelectedReporteMensual] = useState(null);
  const [EsAbrirDocumentosSoporte , setEsAbrirDocumentosSoporte] = useState(false);
  const [TieneAnexos, setTieneAnexos] = useState(null);
  const [esMostrarFormulaReview, setEsMostrarFormulaReview] = useState(false);
  const [IdKPIFormulaSelected, setIdKPIFormulaSelected] =  useState(false);
  const [Logros, setLogros] = useState(''); 
  const [ProblemasAResolver, setProblemasAResolver] = useState(''); 
  const [SiguientesPasos, setSiguientesPasos] = useState(''); 
  
  useEffect(() => {
    setClaProyecto(props.ClaProyecto === undefined ? 0 : props.ClaProyecto);
    setAnioMes(props.AnioMes === undefined ? 0 : props.AnioMes); 

    obtenerEncabezadoReportProgress(
      props.ClaProyecto === undefined ? 0 : props.ClaProyecto,
      props.AnioMes === undefined ? 0 : props.AnioMes
    );
  }, [props.ClaProyecto, props.AnioMes]);

  const obtenerEncabezadoReportProgress = (ClaProyecto, AnioMes) => {
    const GetEncabezado = `${config.UrlApiProject}ScoreCard/GetEncabezadoReportProgress`;
    const paramsToService = {
      AnioMes,
      ClaProyecto,
    };
    callApi(GetEncabezado, 'POST', paramsToService, (response) => {
      if (response.data.Encabezado.length > 0) {
        setIdReporteAvanceMensualProyecto(response.data.Encabezado[0].IdReporteMensual);
        setLabelRealProgress(response.data.Encabezado[0].LabelRealProgress);
        setLabelEstProgress(response.data.Encabezado[0].LabelEstProgress);
        setTieneAnexos(response.data.Encabezado[0].TieneAnexos);
        setEncabezadoList(response.data.Encabezado[0]);        
        GetKPIManagementReportProgress(ClaProyecto, AnioMes);        
        obtenerGetSeguimientoFases(ClaProyecto, AnioMes, response.data.Encabezado[0].IdReporteMensual);
        obtenerGetSeguimientoFasesListening(ClaProyecto, AnioMes, response.data.Encabezado[0].IdReporteMensual);
        obtenerPresente(response.data.Encabezado[0].IdReporteMensual, ClaProyecto, AnioMes);
        GetActividadesAConcluir(ClaProyecto, AnioMes);
        GetActividadesCompletadas(ClaProyecto, AnioMes);
        obtenerFuturo(response.data.Encabezado[0].IdReporteMensual, ClaProyecto, AnioMes);
        GetActividadesSiguiente(ClaProyecto, AnioMes);
        obtenerSoporte(response.data.Encabezado[0].IdReporteMensual);
        GetActividadesEntregable(ClaProyecto, AnioMes);
        setEsProyectoMaestro(response.data.Encabezado[0].EsProyectoMaestro);
        setClaTipoProyecto(response.data.Encabezado[0].ClaTipoProyecto);
        setLogros(response.data.Encabezado[0].Logros);
        setProblemasAResolver(response.data.Encabezado[0].ProblemasAResolver);
        setSiguientesPasos(response.data.Encabezado[0].SiguientesPasos);        
      } else {
        setEncabezadoList({
          IdReporteMensual: null,
          ObjetivoProyecto: null,
          LineaEstrategica: null,
          Iniciativa: null,
          LabelEstProgress: null,
          LabelRealProgress: null,
          EstatusProyecto: null,
        });
        setLogros('');
        setProblemasAResolver('');
        setSiguientesPasos('');  
      }
    });
  };
  const GetKPIManagementReportProgress = (pClaProyecto, pAnioMes) => {
    const GetKPIManagementReportProgress = `${config.UrlApiProject}ScoreCard/GetKPIManagementReportProgress`;
    const paramsToService = {
      ClaProyecto: pClaProyecto,
      AnioMes: pAnioMes,
    };
    callApi(GetKPIManagementReportProgress, 'POST', paramsToService, (response) => {
      setReporteAvanceProyectoKPIList(response.data.KPIManagementReport);
    });
  };
  const obtenerGetSeguimientoFases = (pClaProyecto, pAnioMes, pIdReporteAvanceMensualProyecto) => {
    const GetSeguimientoFases = `${config.UrlApiProject}ScoreCard/GetSeguimientoFasesReportProgress`;
    const paramsToService = {
      ClaProyecto: pClaProyecto,
      AnioMes: pAnioMes,
      IdReporteAvanceMensualProyecto: pIdReporteAvanceMensualProyecto
    };
    callApi(GetSeguimientoFases, 'POST', paramsToService, (response) => {
      setSeguimientoFasesList(response.data.SeguimientoFases);
    });
  };
  const obtenerPresente = (pIdReporteAvanceMensualProyecto, pClaProyecto, pAnioMes) => {
    const GetPresente = `${config.UrlApiProject}ScoreCard/GetPresenteReportProgress`;
    const paramsToService = {
      IdReporteAvanceMensualProyecto: pIdReporteAvanceMensualProyecto,
      ClaProyecto: pClaProyecto,
      AnioMes: pAnioMes,
    };
    callApi(GetPresente, 'POST', paramsToService, (response) => {
      setPresenteList(response.data.Presente);  
    });
    /*
    const GetPresente = `${config.UrlApiProject}ScoreCard/GetPresenteReportProgress?IdReporteAvanceMensualProyecto=${pIdReporteAvanceMensualProyecto===null?0:pIdReporteAvanceMensualProyecto}`;
    const paramsToService = {};
    callApi(GetPresente, 'GET', paramsToService, (response) => {
      setPresenteList(response.data.Presente);  
    });
    */
  };
  const GetActividadesAConcluir = (pClaProyecto, pAnioMes) => {
    const GetGetActividadesAConcluirReportProgress = `${config.UrlApiProject}ScoreCard/GetActividadesAConcluirPeriodoReportProgress`;
    const paramsToService = {
      ClaProyecto: pClaProyecto,
      AnioMes: pAnioMes,
    };
    callApi(GetGetActividadesAConcluirReportProgress, 'POST', paramsToService, (response) => {
      setActividadesAConcluirIList(response.data.ActividadesAConcluirPeriodo);
    });
  };
  const GetActividadesCompletadas = (pClaProyecto, pAnioMes) => {
    const ActividadesCompletadas = `${config.UrlApiProject}ScoreCard/GetActividadesCompletadasPeriodoReportProgress`;
    const paramsToService = {
      ClaProyecto: pClaProyecto,
      AnioMes: pAnioMes,
    };
    callApi(ActividadesCompletadas, 'POST', paramsToService, (response) => {
      setActividadesCompletadasList(response.data.ActividadesCompletadas);
    });
  };
  const obtenerFuturo = (pIdReporteAvanceMensualProyecto, pClaProyecto, pAnioMes) => {
    const GetFuturo = `${config.UrlApiProject}ScoreCard/GetFuturoReportProgress`;
    const paramsToService = {
      IdReporteAvanceMensualProyecto: pIdReporteAvanceMensualProyecto,
      ClaProyecto: pClaProyecto,
      AnioMes: pAnioMes,
    };
    callApi(GetFuturo, 'POST', paramsToService, (response) => {
      setFuturoList(response.data.Futuro);
    });
    /*
    const GetFuturo = `${config.UrlApiProject}ScoreCard/GetFuturoReportProgress?IdReporteAvanceMensualProyecto=${pIdReporteAvanceMensualProyecto===null?0:pIdReporteAvanceMensualProyecto}`;
    const paramsToService = {};
    callApi(GetFuturo, 'GET', paramsToService, (response) => {
      setFuturoList(response.data.Futuro);
      console.log(response.data.Futuro);
    });
    */
  };
  const GetActividadesSiguiente = (pClaProyecto, pAnioMes) => {
    const ActividadesSiguiente = `${config.UrlApiProject}ScoreCard/GetActividadesSiguientePeriodoReportProgress`;
    const paramsToService = {
      ClaProyecto: pClaProyecto,
      AnioMes: pAnioMes,
    };
    callApi(ActividadesSiguiente, 'POST', paramsToService, (response) => {
      setActividadesSiguienteList(response.data.ActividadesSiguiente);
    });
  };
  const obtenerSoporte = (pIdReporteAvanceMensualProyecto) => {
    const GetSoporte = `${config.UrlApiProject}ScoreCard/GetSoporteReportProgress?IdReporteAvanceMensualProyecto=${pIdReporteAvanceMensualProyecto===null?0:pIdReporteAvanceMensualProyecto}`;
    const paramsToService = {};
    callApi(GetSoporte, 'GET', paramsToService, (response) => {
      bindSoporteList(response.data.Soporte); 
    });
  };

  const obtenerGetSeguimientoFasesListening = (pClaProyecto, pAnioMes, pIdReporteAvanceMensualProyecto) => {
    const GetSeguimientoFasesListening = `${config.UrlApiProject}ScoreCard/GetSeguimientoFasesListeningReportProgress`;
    const paramsToService = {
      ClaProyecto: pClaProyecto,
      AnioMes: pAnioMes,
      IdReporteAvanceMensualProyecto: pIdReporteAvanceMensualProyecto
    };
    callApi(GetSeguimientoFasesListening, 'POST', paramsToService, (response) => {
      setSeguimientoFasesListeningList(response.data.SeguimientoFases);
    });
  };

  const bindSoporteList = (listParticipant) => { 
    const arrayParticipants = [];
      let RowValues ={};
      for(let i=0; i < listParticipant.length; i++){
        RowValues ={    
           ...listParticipant[i]
           ,ClaUsuario : {
            ClaColaborador: listParticipant[i].ClaUsuario,
            NomColaborador: listParticipant[i].NomUsuario
          } 
      }
      arrayParticipants.push(RowValues);
    }
    setSoporteList(arrayParticipants)
  }

  const GetActividadesEntregable = (pClaProyecto, pAnioMes) => {
    const ActividadesEntregable = `${config.UrlApiProject}ScoreCard/GetActividadesEntregablePeriodoReportProgress`;
    const paramsToService = {
      ClaProyecto: pClaProyecto,
      AnioMes: pAnioMes,
    };
    callApi(ActividadesEntregable, 'POST', paramsToService, (response) => {
      setEntregableList(response.data.Entregable);
    });
  };

  const valitationScheme = yup.object().shape({ 
    PresenteList: yup.array().of(
      yup.object().shape({ 
        Descripcion: yup.string().when('BajaLogica', {is: 0,
          then: yup.string().trim().required(t('common:RequiredField')).nullable(),
          otherwise: yup.string().nullable()
        }) 
    })),
    FuturoList: yup.array().of(
      yup.object().shape({ 
        Descripcion: yup.string().when('BajaLogica', {is: 0,
          then: yup.string().trim().required(t('common:RequiredField')).nullable(),
          otherwise: yup.string().nullable()
        }) 
    })),
    SoporteList: yup.array().of(
      yup.object().shape({ 
        Descripcion: yup.string().when('BajaLogica', {is: 0,
          then: yup.string().trim().required(t('common:RequiredField')).nullable(),
          otherwise: yup.string().nullable()
        }),
        ClaUsuario: yup.object().when('BajaLogica', {is: 0,
          then: yup.object({ ClaColaborador: yup.string().required(t('common:RequiredField')) }).required(t('common:RequiredField')).nullable(),
          otherwise: yup.object().nullable()
        }),
        FechaCompromiso: yup.string().when('BajaLogica', {is: 0,
          then: yup.string().trim().required(t('common:RequiredField')).nullable(),
          otherwise: yup.string().nullable()
        }) 
    }))  
  });

  const onViewFull = () => {  
    setopenViewFull(true);
  }
  
  const closeModalViewFull = () => {
    setopenViewFull(false);
  }
  
  const onSaveClick = async () => {     

    // Verificamos la informacion requerida.
    setErrors({});
     
    const value = {    
      PresenteList,
      FuturoList,
      SoporteList
    };  

    const validator = new YupValidator(valitationScheme);
    const esValido = await validator.validate(value);

    if (!esValido){   
      setErrors(validator.errors); 
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
    
      return;
    }

    const arraySoporte = [];
    let RowValues ={};
  
    for (let index = 0; index < SoporteList.length; index++) {    
       RowValues = {
        BajaLogica: SoporteList[index].BajaLogica,
        ClaUsuarioResponsable: SoporteList[index].ClaUsuario.ClaColaborador === undefined ? SoporteList[index].ClaUsuario : SoporteList[index].ClaUsuario.ClaColaborador,
        Descripcion: SoporteList[index].Descripcion,
        EsMostrarEnOrangePage: SoporteList[index].EsMostrarEnOrangePage, 
        FechaCompromiso: (SoporteList[index].FechaCompromiso === null ? null : SoporteList[index].FechaCompromiso),
        FechaRealTermino : (SoporteList[index].FechaRealTermino === null ? null : SoporteList[index].FechaRealTermino),
        IdReporteAvanceMenProySeg: SoporteList[index].IdReporteAvanceMenProySeg,        
        NomUsuario: SoporteList[index].ClaUsuario.ClaColaborador === undefined ? SoporteList[index].NomUsuario : SoporteList[index].ClaUsuario.NomColaborador        
      };       
      arraySoporte.push(RowValues);      
    }

    const paramsServiceOwners = {
      ClaProyecto,
      AnioMes,      
      IdReporteAvanceMensualProyecto,
      LabelRealProgress,
      LabelEstProgress,
      ReporteAvanceMensualProyectoList: ReporteAvanceProyectoKPIList,
      ReporteAvanceMensualProyectoFases: SeguimientoFasesList,
      ReporteAvanceMensualProyectoFasesListening: SeguimientoFasesListeningList,
      ReporteAvanceMensualProyectoSeguimientoPresente: PresenteList,
      ReporteAvanceMensualProyectoSeguimientoFuturo: FuturoList,
      ReporteAvanceMensualProyectoSeguimientoSoporte: arraySoporte,
      Logros,
      ProblemasAResolver,
      SiguientesPasos
    };

     
    const urlWebServiceSaveMinutas = `${config.UrlApiProject}ScoreCard/SaveReportProgress`;
    
    callApi(urlWebServiceSaveMinutas, 'POST', paramsServiceOwners, (response) => {
      showSweetAlert('success', t('common:SuccessOperation'), 'success');

      obtenerEncabezadoReportProgress( props.ClaProyecto, props.AnioMes); 

      if (props.onChange !== null && typeof(props.onChange) === 'function'){
        props.onChange();
      } 

      onHideProgressReport();
    });
    
  }

  const headerKPIProgress = (
    <Row>
      <Col lg={9} md={9} sm={12} xs={12}>
        {t('reportevanceMensualProyecto:KPIProgress')}
      </Col>
      <Col lg={3} md={3} sm={12} xs={12} className="text-right">
        <div className="p-fluid">
          <FieldButton
            label={t('reportevanceMensualProyecto:ViewFull')}
            className="primary no-border rounded-button"
            onClick={onViewFull}  
          />
        </div>
      </Col>
    </Row>
  );
  const ValorActualTemplate = (rowData) => {
    return (
      <>
        <label>
          <b>{Highcharts.numberFormat(rowData.ValorActual, 2)}</b>
        </label>
      </>
    );
  };
  const EstatusObjetivoTemplate = (rowData) => {
    return (
      <>
        <label>
          <b> 
            {rowData.EstatusObjetivo !== null?  Highcharts.numberFormat(rowData.EstatusObjetivo, 2, '.', ',') : null} 
          </b>
        </label>
      </>
    );
  };
  const DifActualVsObjetivoTemplate = (rowData) => {
    return (
      <>
        <label>
          <b>{Highcharts.numberFormat(rowData.DifActualVsObjetivo, 1)} %</b>
        </label>
      </>
    );
  };
  const onNewClickPresente = () => { 
    const newRow = {
      IdReporteAvanceMensualProyecto	,
      Descripcion	 : ""	,
      ClaUsuario	 : null,
      NomUsuario	 : null	,
      EsMostrarEnOrangePage	 : 1	,
      BajaLogica: 0,
      IdReporteAvanceMenProySeg	 : null	,
    };
 
    setPresenteList([...PresenteList, newRow]);
  }
  const onNewClickFuturo = () => { 
    const newRow = {         
      IdReporteAvanceMenProySeg	 : null	,
      IdReporteAvanceMensualProyecto	,
      Descripcion	 : ""	,
      ClaUsuario	 : null,
      NomUsuario	 : null	,
      EsMostrarEnOrangePage	 : 1	,
      BajaLogica: 0
    };
    setFuturoList([...FuturoList, newRow]);
  }
  const onNewSoporteClick = () => { 
    const newRow = {
      IdReporteAvanceMenProySeg : null,
      IdReporteAvanceMensualProyecto,
      Descripcion: "",
      ClaUsuario: null,
      NomUsuario: null,
      EsMostrarEnOrangePage: 0,
      FechaCompromiso: null,
      FechaRealTermino: null,
      BajaLogica: 0
    };
    setSoporteList([...SoporteList, newRow]);
  }
  const actionBodyTemplatePresente = (rowData, props) => {
    return (
      <> 
        <FieldButton icon="pi pi-trash" onClick={() => onConfirmBajaPresente(rowData,props)} className="p-button-rounded p-button-warning" /> 
      </>
    );
  }
  const onConfirmBajaPresente = (row, props) => {    
    /* AQUI  */    
      // aqui lo borro de mi arreglo 
      const PresenteListnew = [...PresenteList];
 
      if (PresenteListnew[row.RowIndex].IdReporteAvanceMenProySeg === null){
        PresenteListnew.splice(row.RowIndex, 1);
      }
      else { 
        PresenteListnew[row.RowIndex].BajaLogica = 1;
      } 

      setPresenteList(PresenteListnew);    
  }
  const actionBodyTemplateFuturo = (rowData, props) => {
    return (
      <> 
        <FieldButton icon="pi pi-trash" onClick={() => onConfirmBajaFuturo(rowData,props)} className="p-button-rounded p-button-warning" /> 
      </>
    );
  }
  const onConfirmBajaFuturo = (row, props) => {    
    /* AQUI  */    
      // aqui lo borro de mi arreglo 
      const FuturoListnew = [...FuturoList];

      if (FuturoListnew[row.RowIndex].IdReporteAvanceMenProySeg === null){
        FuturoListnew.splice(row.RowIndex, 1);
      }
      else { 
        FuturoListnew[row.RowIndex].BajaLogica = 1;
      } 

      setFuturoList(FuturoListnew);    
  }
  const actionBodyTemplateSoporte = (rowData, props) => {
    return (
      <> 
        <FieldButton icon="pi pi-trash" onClick={() => onConfirmBajaSoporte(rowData,props)} className="p-button-rounded p-button-warning" /> 
      </>
    );
  }
  const onConfirmBajaSoporte = (row, props) => {    
    /* AQUI  */    
      // aqui lo borro de mi arreglo 
      const SoporteListnew = [...SoporteList];

      if (SoporteListnew[row.RowIndex].IdReporteAvanceMenProySeg === null){
        SoporteListnew.splice(row.RowIndex, 1);
      }
      else { 
        SoporteListnew[row.RowIndex].BajaLogica = 1;
      } 

      setSoporteList(SoporteListnew);    
  }
  const headerSeguimientoFases = <>{t('reportevanceMensualProyecto:ProjectRoadmap')}</>;
  const headerSeguimientoFasesListening = <>{t('reportevanceMensualProyecto:ProjectLListening')}</>;

  const headerPresente = (
    <Row>
      <Col lg={9} md={9} sm={12} xs={12}>
        {t('reportevanceMensualProyecto:ActionsIdentified')}
      </Col>
      <Col lg={3} md={3} sm={12} xs={12} className="text-right">
        <div className="p-fluid">
          <FieldButton
            icon="pi pi-plus"
            className="p-button-success no-border p-mr-2 rounded-button"
            onClick={onNewClickPresente}
          />
        </div>
      </Col>
    </Row>
  );
  const headerFuturo = (
    <Row>
      <Col lg={9} md={9} sm={12} xs={12}>
        {t('reportevanceMensualProyecto:Future')}
      </Col>
      <Col lg={3} md={3} sm={12} xs={12} className="text-right">
        <div className="p-fluid">
          <FieldButton
            icon="pi pi-plus"
            className="p-button-success no-border p-mr-2 rounded-button"
            onClick={onNewClickFuturo}
          />
        </div>
      </Col>
    </Row>
  );

  const onChangeIncluirSoloUsuariosMSW = (props, e) => { 
    setEsMostrarSoloUsuarioMSW(e.checked ? 1 : 0); 
  };

  const headerSoporte = (
    <Row>
      <Col lg={9} md={9} sm={12} xs={12}>
        {t('reportevanceMensualProyecto:Support')} 
      </Col>
      <Col lg={3} md={3} sm={12} xs={12} className="text-right">
        <div className="p-fluid">
          <FieldButton
            icon="pi pi-plus"
            className="p-button-success no-border p-mr-2 rounded-button"
            onClick={onNewSoporteClick}
          />
        </div>
      </Col>
    </Row>
  );
  const headerSeguimientoFasesGroup = (
    <ColumnGroup>
      <Row>
        <Column header={t('reportevanceMensualProyecto:Phase')} rowSpan={2}></Column>
        <Column header={t('reportevanceMensualProyecto:Status')} rowSpan={2}></Column>
        <Column header="" rowSpan={2}></Column>
        <Column
          header={t('reportevanceMensualProyecto:EstimatedEndDate')}
          className="text-center"
          rowSpan={2}
        >
        </Column>
        <Column
          header={t('reportevanceMensualProyecto:Weight')}
          className="text-center"
          rowSpan={2}
        >
        </Column>
        <Column
          header={t('reportevanceMensualProyecto:Progress')}
          colSpan={2}
          className="text-center"
        >
        </Column>
        <Column header={t('reportevanceMensualProyecto:ConsiderableUpd')} rowSpan={2}></Column>
      </Row>
      <Row>
        <Column
          className="text-center"
          header={t('reportevanceMensualProyecto:ProgressEst')}
        >
        </Column>
        <Column
          className="text-center"
          header={t('reportevanceMensualProyecto:ProgressReal')}
        >
        </Column>
      </Row>
    </ColumnGroup>
  );
  const headerSeguimientoFasesListeningGroup = (
    <ColumnGroup>
      <Row>
        <Column header={t('reportevanceMensualProyecto:Key')} rowSpan={2}></Column>
        <Column header={t('reportevanceMensualProyecto:Project')} rowSpan={2}></Column>
        <Column header={t('reportevanceMensualProyecto:ScorecardInitiative')} rowSpan={2}></Column>
        <Column header="" rowSpan={2}></Column>
        <Column
          header={t('reportevanceMensualProyecto:Status')}
          className="text-center"
          rowSpan={2}
        >
        </Column>
        <Column
          header={t('reportevanceMensualProyecto:Progress')}
          colSpan={2}
          className="text-center"
        >
        </Column>
        <Column
          header={t('reportevanceMensualProyecto:Responsable')}
          className="text-center"
          rowSpan={2}
        >
        </Column>
        <Column
          header={t('reportevanceMensualProyecto:Weight')}
          className="text-center"
          rowSpan={2}
        >
        </Column>
        <Column
          header={t('reportevanceMensualProyecto:StartDate')}
          className="text-center"
          rowSpan={2}
        >
        </Column>
        <Column
          header={t('reportevanceMensualProyecto:EndDate')}
          className="text-center"
          rowSpan={2}
        >
        </Column>
        <Column
          header={t('reportevanceMensualProyecto:ConsiderableUpd')}
          className="text-center"
          rowSpan={2}
        >
        </Column>
      </Row>
      <Row>
        <Column
          className="text-center"
          header={t('reportevanceMensualProyecto:ProgressEst')}
        >
        </Column>
        <Column
          className="text-center"
          header={t('reportevanceMensualProyecto:ProgressReal')}
        >
        </Column>
      </Row>
    </ColumnGroup>
  );
  
  const headerActividadesAConcluirGroup = (
    <ColumnGroup>
      <Row>
        <Column header={t('reportevanceMensualProyecto:Activity')} rowSpan={2}></Column>
        <Column
          header={t('reportevanceMensualProyecto:EstimatedDate')}
          colSpan={2}
          className="text-center"
        >
        </Column>
        <Column
          header={t('reportevanceMensualProyecto:Days')}
          colSpan={3}
          className="text-center"
        >
        </Column>
        <Column header={t('reportevanceMensualProyecto:Phase')} rowSpan={2}></Column>
        <Column header={t('reportevanceMensualProyecto:Responsable')} rowSpan={2}></Column>
      </Row>
      <Row>
        <Column className="text-center" header={t('reportevanceMensualProyecto:Start')}></Column>
        <Column className="text-center" header={t('reportevanceMensualProyecto:End')}></Column>
        <Column className="text-center" header={t('reportevanceMensualProyecto:Est')}></Column>
        <Column className="text-center" header={t('reportevanceMensualProyecto:Real')}></Column>
        <Column className="text-center" header={t('reportevanceMensualProyecto:Delay')}></Column>
      </Row>
    </ColumnGroup>
  );
  const headerActividadesCompletadasGroup = (
    <ColumnGroup>
      <Row>
        <Column header={t('reportevanceMensualProyecto:Activity')} rowSpan={2}></Column>
        <Column
          header={t('reportevanceMensualProyecto:RealDate')}
          colSpan={2}
          className="text-center"
        >
        </Column>
        <Column
          header={t('reportevanceMensualProyecto:Days')}
          colSpan={3}
          className="text-center"
        >
        </Column>
        <Column header={t('reportevanceMensualProyecto:Phase')} rowSpan={2}></Column>
        <Column header={t('reportevanceMensualProyecto:Responsable')} rowSpan={2}></Column>
      </Row>
      <Row>
        <Column className="text-center" header={t('reportevanceMensualProyecto:Start')}></Column>
        <Column className="text-center" header={t('reportevanceMensualProyecto:End')}></Column>
        <Column className="text-center" header={t('reportevanceMensualProyecto:Est')}></Column>
        <Column className="text-center" header={t('reportevanceMensualProyecto:Real')}></Column>
        <Column className="text-center" header={t('reportevanceMensualProyecto:Delay')}></Column>
      </Row>
    </ColumnGroup>
  );
  const headerActividadesSiguienteGroup = (
    <ColumnGroup>
      <Row>
        <Column header={t('reportevanceMensualProyecto:Activity')} rowSpan={2}></Column>
        <Column
          header={t('reportevanceMensualProyecto:EstimatedDate')}
          colSpan={2}
          className="text-center"
        >
        </Column>
        <Column
          header={t('reportevanceMensualProyecto:Days')}
          colSpan={2}
          className="text-center"
        >
        </Column>
        <Column header={t('reportevanceMensualProyecto:Phase')} rowSpan={2}></Column>
        <Column header={t('reportevanceMensualProyecto:Responsable')} rowSpan={2}></Column>
      </Row>
      <Row>
        <Column className="text-center" header={t('reportevanceMensualProyecto:Start')}></Column>
        <Column className="text-center" header={t('reportevanceMensualProyecto:End')}></Column>
        <Column className="text-center" header={t('reportevanceMensualProyecto:Est')}></Column>
        <Column className="text-center" header={t('reportevanceMensualProyecto:Real')}></Column>
      </Row>
    </ColumnGroup>
  );

  const onClickViewProgressReport = (ClaProyecto, IdReporteMensual) => {
    setEsMostrarProgressReportParent(true);
    setclaSelectedProject(ClaProyecto);
    setidSelectedReporteMensual(IdReporteMensual);
  }

  const nomProyectoTemplate = (rowData) => {
     
    if (rowData.IdReporteMensual !== null){
      return (<><div className='Cursor text-underline' onClick={() => onClickViewProgressReport(rowData.ClaProyecto, rowData.IdReporteMensual)}><a href='#'><span title={`View Progress Report - ${rowData.NomProyecto}`}>{rowData.NomProyecto}</span></a></div></>);
    }

    return (<>{rowData.NomProyecto}</>)
  }

  const keyTemplate = (rowData) => {
      

    return (
      <>
        <Link 
          title={rowData.NomProyecto}  
          to={{ pathname: "EditProject"
              , search: `?ClaProyecto=${rowData.ClaProyecto}` 
                                        }}
          target="_blank"
        >       {rowData.ClaProyecto}
        </Link> 
      </>
)
  }
 
          
  const SemaforoTemplate = (rowData) => {
    let className = ''; 
    const semaforo = rowData.Semaforo;
    switch (semaforo.toString()) {
      case '1':
        className = 'success-color';
        break;
      case '2':
        className = 'warning-color';
        break;
      case '3':
        className = ' danger-color';
        break;
      default:
        className = 'default-color';
    }
    return (
      <>
        <i className={`fas fa-circle ${className}`}></i>
      </>
    );
  };
  const ActivityTemplate = (rowData) => {
    let className = '';
    const semaforo = rowData.Semaforo;
    switch (semaforo.toString()) {
      case '1':
        className = 'color:green;';
        break;
      case '2':
        className = 'color:red;';
        break;
      default:
        className = 'color:red;';
    }
    return { className };
  };
  const FechaEstimadaFinTemplate = (rowData) => {
    return (
      <>
        {rowData.FechaEstimadaFin !== null
          ? moment(rowData.FechaEstimadaFin).format('MM/DD/YYYY')
          : rowData.FechaEstimadaFin}
      </>
    );
  };
  
  const FechaTerminoTemplate = (rowData) => {
    return (
      <>
        {rowData.FechaTermino !== null
          ? moment(rowData.FechaTermino).format('MM/DD/YYYY')
          : rowData.FechaTermino}
      </>
    );
  };

  const FechaRealTerminoTemplate = (rowData) => {
    return (
      <>
        {rowData.FechaRealTermino !== null
          ? moment(rowData.FechaRealTermino).format('MM/DD/YYYY')
          : rowData.FechaRealTermino}
      </>
    );
  }; 

  const FechaRealFinTemplate = (rowData) => {
    return (
      <>
        {rowData.FechaRealFin !== null
          ? moment(rowData.FechaRealFin).format('MM/DD/YYYY')
          : rowData.FechaRealFin}
      </>
    );
  };

  const FechaCompromisoTemplate = (rowData) => {
    return (
      <>
        {rowData.FechaCompromiso !== null
          ? moment(rowData.FechaCompromiso).format('MM/DD/YYYY')
          : rowData.FechaCompromiso}
      </>
    );
  };
  const PorcPesoTemplate = (rowData) => {
    return (
      <>
        <label>
          <b>{rowData.PorcPeso}</b> %
        </label>
      </>
    );
  };
  const AvanceEstimadoTemplate = (rowData) => {
    return (
      <>
        <label>
          <b>{Highcharts.numberFormat(rowData.AvanceEstimado, 0)}</b> %
        </label>
      </>
    );
  };
  const AvanceRealTemplate = (rowData) => {
    return (
      <>
        <label>
          <b>{Highcharts.numberFormat(rowData.AvanceReal, 0)}</b> %
        </label>
      </>
    );
  };
  const ComentariosActualizacionFaseTemplate = (rowData) => {
    return (
      <>
        <label>
          {rowData.ComentariosActualizacionFase} 
        </label>
      </>
    );
  };
  

  const FechaEstimadaInicioTemplate = (rowData) => {
    return (
      <>
        {rowData.FechaEstimadaInicio !== null
          ? moment(rowData.FechaEstimadaInicio).format('MM/DD/YYYY')
          : rowData.FechaEstimadaInicio}
      </>
    );
  };
  const FechaRealInicioTemplate = (rowData) => {
    return (
      <>
        {rowData.FechaRealInicio !== null
          ? moment(rowData.FechaRealInicio).format('MM/DD/YYYY')
          : rowData.FechaRealInicio}
      </>
    );
  };

  const headerActividadesACloncluir = <>{t('reportevanceMensualProyecto:ActPendComp')}</>;
  const headerActividadesCompletadas = <>{t('reportevanceMensualProyecto:ActivitiesPeriod')}</>;
  const headerActividadesSiguiente = <>{t('reportevanceMensualProyecto:ActivitiesMonth')}</>;
  const headerActividadesEntregable = <>{t('reportevanceMensualProyecto:Deliverables')}</>;
  
  const DescripcionPresenteEditor = (props) => { 
    return (
      <div className="p-fluid">
        <FieldText
          name="Descripcion" 
          value={props.rowData.Descripcion}
          onChange={(e) => onDescripcionPresenteChange(props, e.target.value)} 
          errors={errors[`PresenteList[${props.rowData.RowIndex}]`]}    
          maxLength={500}   
        />
      </div>
    );
  };
  const EsMostrarEnOrangePageEditor = (rowData, props) => {
    return (
      <div className="p-fluid">
        <FieldCheckbox
          inputId={rowData.EsMostrarEnOrangePage} 
          checked={rowData.EsMostrarEnOrangePage===1}
          onChange={(e) => EsMostrarEnOrangePageSelected(props, e)}
        />
      </div>
    );
  };
  const DescripcionFuturoEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldText
          name="Descripcion" 
          value={props.rowData.Descripcion}
          onChange={(e) => onDescripcionFuturoChange(props, e.target.value)}
          errors={errors[`FuturoList[${props.rowData.RowIndex}]`]}    
          maxLength={500}   
        />
      </div>
    );
  };
  const EsMostrarEnOrangePageFuturoEditor = (rowData, props) => {
    return (
      <div className="p-fluid">
        <FieldCheckbox
          inputId={rowData.EsMostrarEnOrangePage} 
          checked={rowData.EsMostrarEnOrangePage===1}
          onChange={(e) => EsMostrarEnOrangePageFuturoSelected(props, e)}
        />
      </div>
    );
  };

  const onShowCalculationFormulaRevi = (IdKPIFormula) => {
    setEsMostrarFormulaReview(true);
    setIdKPIFormulaSelected(IdKPIFormula); 
  }

  const calculateKPIResult = (item, props) => { 
    /* Ejecutamos proceso para calcular el kpi con la formula */
    const paramsService = {
      AnioMes,      
      IdKPIFormula: item.IdKPIFormula,
    };

     
    const CalcularResultadoFormula = `${config.UrlApiProject}ScoreCard/CalcularResultadoFormula`;
    
    callApi(CalcularResultadoFormula, 'POST', paramsService, (response) => {
      const updatedValorActual = [...props.value];
      const target =   updatedValorActual[props.rowIndex].EstatusObjetivo;
      let DiffvsTarget = null;
      const value = response.data.Resultado; 

      console.log('updatedValorActual[props.rowIndex].ClaSentidoMejora', updatedValorActual[props.rowIndex].ClaSentidoMejora);
      if (updatedValorActual[props.rowIndex].ClaSentidoMejora === 1 )
      {
        if (target === 0){
            if (value === 0){
              DiffvsTarget = 100.0;
            }else {
              // no se puede divir entre 0
              DiffvsTarget = null
            }
        }
        else 
        {
          DiffvsTarget = (1 - ((target - value)/target))*100.0; 
          DiffvsTarget = DiffvsTarget > 100? 100 : DiffvsTarget;
        }
      }
      else if (updatedValorActual[props.rowIndex].ClaSentidoMejora === 2)
      {
        if (value === 0){
          if (target === 0){
            DiffvsTarget = 100.0;
          }else {
            // no se puede divir entre 0
            DiffvsTarget = null
          }
        }
        else 
        {

          DiffvsTarget = (1 - ((value - target)/value))*100.0; 
          DiffvsTarget = DiffvsTarget > 100? 100 : DiffvsTarget;
        }  
      } 

      
      updatedValorActual[props.rowIndex].ValorActual         = value;
      updatedValorActual[props.rowIndex].DifActualVsObjetivo = DiffvsTarget;
      updatedValorActual[props.rowIndex].EsCalculoEjecutado  = true;
      
      setReporteAvanceProyectoKPIList(updatedValorActual);
    }); 
  }
  // IdKPIFormula
  const ValorActualKPITemplate = (rowData, props) => {
    
    if (rowData.IdKPIFormula !== null){
      return (
        <>
          <span onClick={()=> calculateKPIResult(rowData, props)}><i className="fas fa-sync Cursor"></i></span>&nbsp;&nbsp;&nbsp;<span className='link-text' onClick={()=> onShowCalculationFormulaRevi(rowData.IdKPIFormula)}>{rowData.ValorActual !== null?  Highcharts.numberFormat(rowData.ValorActual, 2, '.', ',') : null}</span>
          
          {
            rowData.EsCalculoEjecutado === true &&
            (<>&nbsp;&nbsp;&nbsp;<span title="Click here to view how we calculate " className='Cursor' onClick={()=> onShowCalculationFormulaRevi(rowData.IdKPIFormula)}><i className="fas fa-question-circle info-color"></i></span></>) 
          }
          
        </>
      )
    }
    return (<span>{rowData.ValorActual !== null?  Highcharts.numberFormat(rowData.ValorActual, 2, '.', ',') : null}</span>)
  }

  const ValorActualEditor = (props) => {

    if (props.rowData.IdKPIFormula !== null){
      return (
        <>
          <span onClick={()=> calculateKPIResult(props.rowData, props)}><i className="fas fa-sync Cursor"></i></span>&nbsp;&nbsp;&nbsp;<span className='link-text' onClick={()=> onShowCalculationFormulaRevi(props.rowData.IdKPIFormula)}>{props.rowData.ValorActual !== null?  Highcharts.numberFormat(props.rowData.ValorActual, 2, '.', ',') : null}</span>
          {
            props.rowData.EsCalculoEjecutado === true &&
            (<>&nbsp;&nbsp;&nbsp;<span title="Click here to view how we calculate" className='Cursor' onClick={()=> onShowCalculationFormulaRevi(props.rowData.IdKPIFormula)}><i className="fas fa-question-circle info-color"></i></span></>) 
          }
        </>
      )
    }

    return (
      <div className="p-fluid">
        <FieldNumber
          name="ValorActual"
          value={props.rowData.ValorActual}
          onChange={(e) => onValorActualKPIManagementChange(props, e.target.value)}
          minFractionDigits={2}
          min={-99999999.99}
          max={99999999.99}
        />
      </div>
    );
  };
  const ComentariosActualizacionFaseEditor = ( props ) => { 
    return (
      <div className="p-fluid">
        <FieldTextArea
          value={props.rowData.ComentariosActualizacionFase} 
          onChange={(e) => ComentariosActualizacionFaseChange(props, e.target.value)}
          maxLength={3000}
          rows={2}
        />
      </div>
      )
    };
    const ComentariosActualizacionFaseListeningEditor = ( props ) => { 
      return (
        <div className="p-fluid">
          <FieldTextArea
            value={props.rowData.ComentariosActualizacionFase} 
            onChange={(e) => ComentariosActualizacionFaseListeningChange(props, e.target.value)}
            maxLength={3000}
            rows={2}
          />
        </div>
        )
      };
  const DescripcionSoporteEditor = (props) => {
      return (
        <div className="p-fluid">
          <FieldText
            name="Descripcion" 
            value={props.rowData.Descripcion}
            onChange={(e) => onDescripcionSoporteChange(props, e.target.value)}
            errors={errors[`SoporteList[${props.rowData.RowIndex}]`]}     
            maxLength={500} 
          />
        </div>
      );
    };
    const FechaCompromisoEditor = ( props ) => {   
      return (
        <div className="p-fluid">            
          <FieldCalendar
            name="FechaCompromiso"
            value={(props.rowData.FechaCompromiso!== null ? (new Date(props.rowData.FechaCompromiso)): null)}
            onChange={(e) => onChangeFechaCompromiso(props, e)}
            errors={errors[`SoporteList[${props.rowData.RowIndex}]`]}      
          /> 
        </div>
        )
      };
    const onChangeFechaCompromiso = (props, e) => {  
        const updatedProducts = [...SoporteList];
        const saveArray= getCurrentSoporteRowValues(props); 
        saveArray.FechaCompromiso = new Date(e.target.value)
        updatedProducts[props.rowData.RowIndex].FechaCompromiso = e.target.value;
        setSoporteList(updatedProducts); 
    }   
    const FechaRealTerminoEditor = ( props ) => {   
      return (
        <div className="p-fluid">            
          <FieldCalendar
            name="FechaRealTermino"
            value={(props.rowData.FechaRealTermino!== null ? (new Date(props.rowData.FechaRealTermino)): null)}
            onChange={(e) => onChangeFechaRealTermino(props, e)}
          /> 
        </div>
        )
      };
      const onChangeFechaRealTermino = (props, e) => {  
        const updatedProducts = [...SoporteList];
        const saveArray= getCurrentSoporteRowValues(props); 
        saveArray.FechaRealTermino = new Date(e.target.value)
        updatedProducts[props.rowData.RowIndex].FechaRealTermino = e.target.value;
        setSoporteList(updatedProducts); 
    }
    const getCurrentSoporteRowValues = (props) => {
      const updatedProducts = [...props.value];
      let RowValues ={}
  
      if (updatedProducts[props.rowIndex].IdReporteAvanceMenProySeg !== null){
        RowValues ={
          IdReporteAvanceMenProySeg: updatedProducts[props.rowIndex].IdReporteAvanceMenProySeg,
          IdReporteAvanceMensualProyecto: updatedProducts[props.rowIndex].IdReporteAvanceMensualProyecto,
          Descripcion: updatedProducts[props.rowIndex].Descripcion,
          // ClaColaborador: {
          //   ClaColaborador: updatedProducts[props.rowIndex].ClaColaborador.ClaColaborador,
          //   NomColaborador: updatedProducts[props.rowIndex].ClaColaborador.NomColaborador
          // },  
          ClaUsuario: SoporteList[props.rowIndex].ClaUsuario.ClaColaborador === undefined ? SoporteList[props.rowIndex].ClaUsuario : SoporteList[props.rowIndex].ClaUsuario.ClaColaborador,         
          NomUsuario: SoporteList[props.rowIndex].ClaUsuario.ClaColaborador === undefined ? SoporteList[props.rowIndex].NomUsuario : SoporteList[props.rowIndex].ClaUsuario.NomColaborador,          
          EsMostrarEnOrangePage: updatedProducts[props.rowIndex].EsMostrarEnOrangePage,
          FechaCompromiso: updatedProducts[props.rowIndex].FechaCompromiso,        
          FechaRealTermino: updatedProducts[props.rowIndex].FechaRealTermino,
          BajaLogica: updatedProducts[props.rowIndex].BajaLogica
        }
      }
      else {
        RowValues ={
          IdReporteAvanceMenProySeg: updatedProducts[props.rowIndex].IdReporteAvanceMenProySeg,
          IdReporteAvanceMensualProyecto: updatedProducts[props.rowIndex].IdReporteAvanceMensualProyecto,
          Descripcion: updatedProducts[props.rowIndex].Descripcion,
          ClaUsuario: null,
          NomUsuario: updatedProducts[props.rowIndex].NomUsuario,
          EsMostrarEnOrangePage: updatedProducts[props.rowIndex].EsMostrarEnOrangePage,
          FechaCompromiso: updatedProducts[props.rowIndex].FechaCompromiso,        
          FechaRealTermino: updatedProducts[props.rowIndex].FechaRealTermino,
          BajaLogica: updatedProducts[props.rowIndex].BajaLogica
      }
      }
       
      return RowValues;
    }       
  const ComentariosActualizacionFaseChange = (props, value) => {
      const updatedComentariosActualizacion = [...props.value];
      updatedComentariosActualizacion[props.rowIndex][props.field] = value;
      
      setSeguimientoFasesList(updatedComentariosActualizacion);
    };

  const ComentariosActualizacionFaseListeningChange = (props, value) => {
    const updatedComentariosActualizacion = [...props.value];
    updatedComentariosActualizacion[props.rowIndex][props.field] = value;
    
    setSeguimientoFasesListeningList(updatedComentariosActualizacion);
  };

  const onValorActualKPIManagementChange = (props, value) => { 
    const updatedValorActual = [...props.value];
    const target =   updatedValorActual[props.rowIndex].EstatusObjetivo;
    let DiffvsTarget = null;

    // algunos meses el valor puede ser 0 entonces para esos casos, no dividimos, ya que puede ser que ese no genera valor
    // hasta mas adelante del proyecto
          console.log('ClaSentidoMejora 1', updatedValorActual[props.rowIndex].ClaSentidoMejora);
    
    if (updatedValorActual[props.rowIndex].ClaSentidoMejora === 1 )
    {
      if (target === 0){
          if (value === 0){
            DiffvsTarget = 100.0;
          }else if (value > 0){
              DiffvsTarget = 100.0;
          }else if (value < 0){
            DiffvsTarget = 0.0;
          }else{ 
            // no se puede divir entre 0
            DiffvsTarget = null
          }
      }
      else 
      {
        DiffvsTarget = (1 - ((target - value)/target))*100.0; 
        DiffvsTarget = DiffvsTarget > 100? 100 : DiffvsTarget;
      }
    }
    else if (updatedValorActual[props.rowIndex].ClaSentidoMejora === 2)
    {
      if (target === 0){
        if (value === 0){
          DiffvsTarget = 100.0;
        }else if (value < 0){
          DiffvsTarget = 100.0;
        }else if (value > 0){
            DiffvsTarget = 0.0;
        }else {
          // no se puede divir entre 0
          DiffvsTarget = null
        }
      }
      else 
      {
        DiffvsTarget = (1 - ((value - target)/value))*100.0;
        DiffvsTarget = DiffvsTarget > 100? 100 : DiffvsTarget;
      }  
    } 
    
    updatedValorActual[props.rowIndex][props.field] = value;
    updatedValorActual[props.rowIndex].DifActualVsObjetivo = DiffvsTarget;
    
    setReporteAvanceProyectoKPIList(updatedValorActual);
  };
  const onDescripcionPresenteChange = (props, value) => { 
    const updatedField= [...PresenteList];    
    updatedField[props.rowData.RowIndex][props.field] = value;

    setPresenteList(updatedField);
  };
  const EsMostrarEnOrangePageSelected = (props, e) => {     
    const updatedField= [...PresenteList];    
    updatedField[props.rowData.RowIndex][props.field] = e.checked ? 1 : 0;       
    setPresenteList(updatedField);
  }
  const onDescripcionFuturoChange = (props, value) => {
    const updatedField= [...FuturoList];    
    updatedField[props.rowData.RowIndex][props.field] = value;

    setFuturoList(updatedField);
  };
  const EsMostrarEnOrangePageFuturoSelected = (props, e) => {     
    const updatedField= [...FuturoList];    
    updatedField[props.rowData.RowIndex][props.field] = e.checked ? 1 : 0;       
    setFuturoList(updatedField);
  }
  const onDescripcionSoporteChange = (props, value) => {
    const updatedField= [...SoporteList];    
    updatedField[props.rowData.RowIndex][props.field] = value;

    setSoporteList(updatedField);
  };
  const emptyValueValidator = (e) => {
    const { rowData, field } = e.columnProps;
    return rowData[e.columnProps.field].trim().length > 0;
  }
  const ResponsibleParticipantsEditor = (props) => {   

     // if ( props.rowData.IdReporteAvanceMenProySeg === null) { 
       return ( 
         <div className="p-fluid"> 
           <FieldAutoComplete
             dropdown
             name="ClaUsuario" 
             value={props.rowData.ClaUsuario} 
             onChange={(e) => onChangeClaUsuarioResponsableParticipants(props,e)} 
             suggestions={filteredResponsibleParticipants}
             field="NomColaborador"
             completeMethod={(e) => filterResponsibleParticipants(props, e)} 
             errors={errors[`SoporteList[${props.rowData.RowIndex}]`]}
             // errors={errors[`ParticipantsList[${props.rowIndex}]`]}
           /> 
         </div>
       ) 
     // }
     
     // return   (<>{props.rowData.NomColaborador}</>)
     
   };
   const onChangeClaUsuarioResponsableParticipants = (props, e) => {  
    const saveArray= getCurrentRowValuesParticipants(props);  
    const updatedProducts = [...SoporteList];    
    
    // const newErrors = { ...errors }  
    // const field = { ...newErrors[`ParticipantsList[${props.rowIndex}]`] };
    // field.ClaColaborador = null;
    // newErrors[`ParticipantsList[${props.rowIndex}]`] = field;
    // delete newErrors[`ParticipantsList[${props.rowIndex}]`];
    // setErrors(newErrors) ;

    if (e.value != null && typeof(e.value) === 'object'){
      const responsible = filteredResponsibleParticipants.filter(item => item.ClaColaborador ===e.value.ClaColaborador)[0];  
    
      if(responsible!==undefined){
        updatedProducts[props.rowData.RowIndex].NomUsuario = responsible.NomColaborador;
        updatedProducts[props.rowData.RowIndex].ClaUsuario = responsible.ClaColaborador;
      }
    }
    else{
      updatedProducts[props.rowData.RowIndex].NomUsuario = null;
    }
    updatedProducts[props.rowData.RowIndex].ClaUsuario = e.value;
    saveArray.ClaColaborador = e.value; 
    setSoporteList(updatedProducts);    
 }
 const filterResponsibleParticipants = (props, event) => {
  let valor = ''; 
  const updatedProducts = [...SoporteList];

  if (updatedProducts[props.rowData.RowIndex].ClaUsuario !== null && updatedProducts[props.rowData.RowIndex].ClaUsuario !== '' &&  event.query === "")
  {
   valor = updatedProducts[props.rowData.RowIndex].NomUsuario;
  }
  else{
   valor = event.query;
  }
  const paramsServiceOwners = {};
  // event.query 
  const urlWebServiceOwners = `${config.UrlApiProject}ScoreCard/GetColaboradorProyectosCmb?descriptionFilter=${valor}&EsMostrarSoloUsuarioMSW=${!EsMostrarSoloUsuarioMSW?1:0}`;
  // Get owner
  callApi(urlWebServiceOwners, 'GET', paramsServiceOwners, (response) => {  
     setfilteredResponsibleParticipants(response.data[0]);
  });
}
 const getCurrentRowValuesParticipants = (props) => {
  const updatedProducts = [...props.value];
  let RowValues ={}

  if (updatedProducts[props.rowIndex].ClaUsuario !== null){
    RowValues ={      
      ClaColaborador: {
        ClaColaborador: updatedProducts[props.rowIndex].ClaUsuario,
        NomColaborador: updatedProducts[props.rowIndex].NomUsuario
      }
  }
  }
  else {
    RowValues ={      
      ClaColaborador: null,      
  }
  }
   
  return RowValues;
}
const showActiveRows = (rows) => {
  const activeRows = [];
  if (rows.length>0) {
    rows.forEach((item, index) => { 
      if (item.BajaLogica === 0) {
        item.RowIndex = index;
        activeRows.push({...item});
      }
    });
  } else {
    return [];
  }
  return activeRows; 
};

const fieldsRequiredPresenteList = (rowData, props) =>  {  
  return (
    <>
      {errors[`PresenteList[${props.rowData.RowIndex}]`] != null ? (<i className="fas fa-exclamation-triangle invalid-color"></i>) : ""}
    </>
  );
}

const fieldsRequiredFuturoList = (rowData, props) =>  {  
  return (
    <>
      {errors[`FuturoList[${props.rowData.RowIndex}]`] != null ? (<i className="fas fa-exclamation-triangle invalid-color"></i>) : ""}
    </>
  );
}

const fieldsRequiredSoporteList = (rowData, props) =>  {  
  return (
    <>
      {errors[`SoporteList[${props.rowData.RowIndex}]`] != null ? (<i className="fas fa-exclamation-triangle invalid-color"></i>) : ""}
    </>
  );
}
 
const onHideProgressReport = () => { 

  if (props.onHide !== null && typeof props.onHide === 'function') {
    props.onHide();
  }
};

const renderHeader = () => { 

  return (
    <div>
      <Row>
        <Col lg={4} md={4} sm={12} xs={12}>
          {t('reportevanceMensualProyecto:ProgressReport')} 
        </Col>
        <Col lg={4} md={4} sm={12} xs={12} className="text-center">
          <span>{EncabezadoList.NomProyecto}</span>
        </Col>
        <Col lg={4} md={4} sm={12} xs={12} className="text-right">
          <div className="w-100 relative pr-3">
            <span> {EncabezadoList.NomPeriodo} </span>
          </div>
        </Col>
      </Row>
    </div>
  );
};  
 
const renderFooter = () => {
  return (
    <>
      <div className='pt-2'>
        <Toolbar
          className='pl-5'
          left={leftToolbarFooterNewAPTemplate}
          right={rightToolbarFooterNewAPTemplate}
        >
        </Toolbar>
      </div>
      
    </>
  );
};

const leftToolbarFooterNewAPTemplate = () => {  
  return (
    <>  
    </>
  );
};
const rightToolbarFooterNewAPTemplate = () => {
   
  return (
    <>
      <FieldButton
        label={t('reportevanceMensualProyecto:SupportFiles')}
        icon="pi pi-paperclip"
        className={`no-border rounded-button font-weight-bold ${ TieneAnexos === 1 ? 'light-yellow' : 'light-gray'} `}
        onClick={() => AbrirAnexo()} 
        hidden={IdReporteAvanceMensualProyecto === null}
      >
      </FieldButton> 
      &nbsp;
      <FieldButton label={t('common:Save')} icon="pi pi-save" className="p-button-success no-border p-mr-2 pt-2 rounded-button" onClick={onSaveClick} /> 
    </>
  );
};

const editorValidator = (e) => { 
 
  if (e.originalEvent.keyCode === 13){ 
    e.originalEvent.defaultPrevented = false;
    return false;
  } 
  
  return true;
}

const AbrirAnexo = () => {
  setEsAbrirDocumentosSoporte(!EsAbrirDocumentosSoporte);
};

const SentidoMejoraTemplate = (props) => {
  return <>{props.ClaSentidoMejora === 1 ?  (<i className="fas fa-sort-amount-up"></i>): (<i className="fas fa-sort-amount-down"></i>)}</>;
}; 



  return (
    <>
      <Dialog
        visible={props.Visible}
        modal
        style={{ width: '100vw' }} 
        onHide={onHideProgressReport}
        header={renderHeader}
        footer={renderFooter}
        closable={true}
        className="dialog-header-background-blue"
        maximized={true}
        draggable={false}
        blockScroll={true}
      > 
        <> 
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
          &nbsp;
            </Col>
          </Row> 
          <Row className="align-items-start">
            <Col lg={8} md={8} sm={12} xs={12}>
              <Row>
                <Col xs={12}>
                  <Card>
                    <CardHeader className="hard-blue">
                      <CardTitle>{t('reportevanceMensualProyecto:ProjectGoal')}</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col>
                          <label>{EncabezadoList.ObjetivoProyecto}</label>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>  
                </Col>             
              </Row>
              {
                ClaTipoProyecto === 5 && 
                (
                  <>
                    <Row>
                      <Col xs={12}>
                        <Card>
                          <CardHeader className="hard-blue">
                            <CardTitle>{t('reportevanceMensualProyecto:ProgressSummary')}</CardTitle>
                          </CardHeader>
                          <CardBody>
                            <Row>
                              <Col>
                                <Row>
                                  <Col lg={12} md={12} sm={12} xs={12}>
                                    <label className='bold'>{t('reportevanceMensualProyecto:WhatDidYouAchieve')}</label>
                                    <div className="p-fluid">
                                      <FieldTextArea 
                                        name="whatDidYouAchieveTextArea"                                        
                                        rows={5}
                                        maxLength={5000} 
                                        errors={errors}
                                        value={Logros} 
                                        className="text-area"
                                        onChange={(e)=>{setLogros(e.target.value)}}
                                      /> 
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={12} md={12} sm={12} xs={12}>
                                    <label className='bold'>{t('reportevanceMensualProyecto:ChallengesToSolve')}</label>
                                    <div className="p-fluid">
                                      <FieldTextArea 
                                        name="challengesToSolveTextArea"                                        
                                        rows={5}
                                        maxLength={5000} 
                                        errors={errors}
                                        value={ProblemasAResolver}  
                                        className="text-area"                                       
                                        onChange={(e)=>{setProblemasAResolver(e.target.value)}}
                                      /> 
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={12} md={12} sm={12} xs={12}>
                                    <label className='bold'>{t('reportevanceMensualProyecto:NextMilestones')}</label>
                                    <div className="p-fluid">
                                      <FieldTextArea 
                                        name="NextMilestonesTextArea"                                        
                                        rows={5}
                                        maxLength={5000} 
                                        errors={errors}
                                        value={SiguientesPasos} 
                                        className="text-area"      
                                        onChange={(e)=>{setSiguientesPasos(e.target.value)}}
                                      /> 
                                    </div>
                                  </Col>
                                </Row>
                              </Col>                        
                            </Row>
                          </CardBody>
                        </Card>  
                      </Col>        
                    </Row>                  
                  </>
                )
              }
            </Col>
            <Col lg={4} md={4} sm={12} xs={12}>
              <Row className="align-items-start">
                <Col lg={6} md={6} sm={12} xs={12}>
                  <Card>
                    <CardHeader className="hard-blue text-center">
                      <CardTitle>{t('reportevanceMensualProyecto:StrategicLine')}</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="bold text-center">
                          <label>{EncabezadoList.LineaEstrategica}</label>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <Card>
                    <CardHeader className="hard-blue text-center">
                      <CardTitle>{t('reportevanceMensualProyecto:StrategicInitiative')}</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="bold text-center">
                          <label>{EncabezadoList.Iniciativa}</label>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <Card>
                    <CardHeader className="hard-blue text-center">
                      <CardTitle>{t('reportevanceMensualProyecto:EstProgress')}</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="bold text-center">
                          <label style={{ fontSize: '2.3rem' }}>
                            {EncabezadoList.LabelEstProgress}
                          </label>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <Card>
                    <CardHeader className="hard-blue text-center">
                      <CardTitle>{t('reportevanceMensualProyecto:RealProgress')}</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="bold text-center">
                          <label style={{ fontSize: '2.3rem' }}>
                            <b>{EncabezadoList.LabelRealProgress}</b> 
                          </label>
                          &nbsp;
                          <span style={{ fontSize: '1.3rem' }}>{SemaforoTemplate({Semaforo: EncabezadoList.Semaforo === null || typeof(EncabezadoList.Semaforo) === 'undefined'  ?  '' : EncabezadoList.Semaforo})}</span>
                         
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {
                /*
                  <Row hidden={IdReporteAvanceMensualProyecto === null}>
                    <Col>
                      <FieldButton
                        label={t('reportevanceMensualProyecto:SupportFiles')}
                        icon="pi pi-paperclip"
                        className="light-blue no-border rounded-button font-weight-bold"
                        onClick={() => AbrirAnexo()} 
                        hidden={IdReporteAvanceMensualProyecto === null}
                      >
                      </FieldButton>
                    </Col>
                  </Row>
                */
              } 
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
          &nbsp;
            </Col>
          </Row>
          <Row className="align-items-start">
            <Col lg={12} md={12} sm={12} xs={12}>
              <FieldDataTable
                value={ReporteAvanceProyectoKPIList}
                scrollable
                scrollHeight="330px"
                className="editable-cells-table"
                editMode="cell" 
                header={headerKPIProgress}
              >
                <Column
                  field="NomProyectoKPIManagement"
                  header={t('reportevanceMensualProyecto:KPIstoImpact')}
                  columnKey="NomProyectoKPIManagement"
                  headerStyle={{ width: '195px' }}
                >
                </Column>
                <Column
                  field="DescUnidadMedidad"
                  header={t('reportevanceMensualProyecto:UoM')}
                  columnKey="DescUnidadMedidad"
                  headerStyle={{ width: '65px' }}
                >
                </Column>
                <Column
                  field="ClaSentidoMejora"
                  className="text-center"
                  header=''
                  columnKey="ClaSentidoMejora"
                  headerStyle={{ width: '20px' }}
                  body={SentidoMejoraTemplate}
                >
                </Column> 
                <Column
                  field="ValorActual"              
                  header={t('reportevanceMensualProyecto:Actual')}
                  columnKey="ValorActual"
                  headerStyle={{ width: '65px' }}
                  body={(rowData, props) => ValorActualKPITemplate(rowData, props)}
              // editorValidator={emptyValueValidator}
                  editor={(props) => ValorActualEditor(props)}
                >
                </Column>
                <Column
                  field="EstatusObjetivo"
                  body={EstatusObjetivoTemplate}
                  header={t('reportevanceMensualProyecto:Target')}
                  columnKey="EstatusObjetivo"
                  headerStyle={{ width: '65px' }}
                >
                </Column>
                <Column
                  field="DifActualVsObjetivo"
                  body={DifActualVsObjetivoTemplate}
                  header={t('reportevanceMensualProyecto:DiffvsTarget')}
                  columnKey="DifActualVsObjetivo"
                  headerStyle={{ width: '65px' }}
                >
                </Column>
              </FieldDataTable>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
          &nbsp;
            </Col>
          </Row>
          {        
        ClaTipoProyecto === 1 || ClaTipoProyecto === 2 || ClaTipoProyecto === 3 ?
        (           
          <>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                &nbsp;
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <FieldDataTable
                  value={SeguimientoFasesList}
                  scrollable
                  scrollHeight="330px"
                  className="editable-cells-table"
                  editMode="cell" 
                  header={headerSeguimientoFases}
                  headerColumnGroup={headerSeguimientoFasesGroup}
                >
                  <Column
                    field="NomFase"
                    header={t('reportevanceMensualProyecto:Phase')}
                    columnKey="NomFase"
                    headerStyle={{ width: '280px' }}
                  >
                  </Column>
                  <Column
                    field="NomEstatusFase"
                    header={t('reportevanceMensualProyecto:Status')}
                    columnKey="NomEstatusFase"
                    headerStyle={{ width: '100px' }} 
                  >
                  </Column>
                  <Column
                    field="Semaforo"
                    body={SemaforoTemplate}
                    className="text-center"
                    header={t('reportevanceMensualProyecto:-')}
                    columnKey="Semaforo"
                    headerStyle={{ width: '30px' }}
                  >
                  </Column>
                  <Column
                    field="FechaEstimadaFin"
                    body={FechaEstimadaFinTemplate}
                    className="text-center"
                    header={t('reportevanceMensualProyecto:EstimatedEndDate')}
                    columnKey="FechaEstimadaFin"
                    headerStyle={{ width: '100px' }}
                  >
                  </Column>
                  <Column
                    field="PorcPeso"
                    body={PorcPesoTemplate}
                    className="text-center"
                    header={t('reportevanceMensualProyecto:Weight')}
                    columnKey="PorcPeso"
                    headerStyle={{ width: '50px' }}
                  >
                  </Column>
                  <Column
                    field="AvanceEstimado"
                    body={AvanceEstimadoTemplate}
                    className="text-center"
                    header={t('reportevanceMensualProyecto:ProgressEst')}
                    columnKey="AvanceEstimado"
                    headerStyle={{ width: '50px' }}
                  >
                  </Column>
                  <Column
                    field="AvanceReal"
                    body={AvanceRealTemplate}
                    className="text-center"
                    header={t('reportevanceMensualProyecto:ProgressReal')}
                    columnKey="AvanceReal"
                    headerStyle={{ width: '50px' }}
                  >
                  </Column>
                  <Column
                    field="ComentariosActualizacionFase"
                    header={t('reportevanceMensualProyecto:ConsiderableUpd')}
                    columnKey="ComentariosActualizacionFase"
                    headerStyle={{ width: '370px' }}
                    editor={(props) => ComentariosActualizacionFaseEditor(props)}
                    body={ComentariosActualizacionFaseTemplate}
                    editorValidator={editorValidator}
                  >
                  </Column>
                </FieldDataTable>
              </Col>
            </Row>            
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
          &nbsp;
              </Col>
            </Row>
            <Row className="align-items-start">
              <Col lg={4} md={4} sm={12} xs={12}>
                <FieldDataTable
                  value={showActiveRows(PresenteList)}
                  scrollable
                  scrollHeight="330px"
                  className="editable-cells-table"
                  editMode="cell"
                  selectionMode="single"
                  header={headerPresente}
                >
                  <Column body={(rowData, props) => fieldsRequiredPresenteList(rowData, props)} headerStyle={{ width: '20px' }}></Column>
                  <Column body={(rowData, props) => actionBodyTemplatePresente(rowData, props)} headerStyle={{ width: '35px' }}></Column>
                  <Column
                    field="Descripcion"
                    header={t('reportevanceMensualProyecto:Description')}
                    headerStyle={{ width: '240px' }}
                    editor={(props) => DescripcionPresenteEditor(props)}
                  >
                  </Column>
                  <Column
                    field="SourceDescrition"
                    header={t('reportevanceMensualProyecto:Source')}
                    style={{ display : (ClaTipoProyecto === 1 || ClaTipoProyecto === 2 || ClaTipoProyecto === 3 ?'none':'')}} 
                    headerStyle={{ width: '240px', display : (ClaTipoProyecto === 1 || ClaTipoProyecto === 2 || ClaTipoProyecto === 3 ?'none':'') }}
                  >
                  </Column>
                  <Column
                    field="EsMostrarEnOrangePage"
                    header={t('reportevanceMensualProyecto:ShowinOP')}
                    headerStyle={{ width: '80px', display : (ClaTipoProyecto === 1 || ClaTipoProyecto === 2 || ClaTipoProyecto === 3 ?'none':'') }}
                    style={{ display : (ClaTipoProyecto === 1 || ClaTipoProyecto === 2 || ClaTipoProyecto === 3 ?'none':'')}} 
                      // editor={(props) => EsMostrarEnOrangePageEditor(props)}
                    body={(rowData, props) => EsMostrarEnOrangePageEditor(rowData, props)}
                  >
                  </Column>
                 
                </FieldDataTable>
              </Col>
              <Col lg={8} md={8} sm={12} xs={12}>          
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <FieldDataTable
                      value={ActividadesAConcluirIList}
                      scrollable
                      scrollHeight="330px"
                      className="editable-cells-table"
                      editMode="cell"
                      selectionMode="single"
                      header={headerActividadesACloncluir}
                      headerColumnGroup={headerActividadesAConcluirGroup}
                    >
                      <Column
                        field="NomActividad"
                        header={t('reportevanceMensualProyecto:Activity')}
                        columnKey="NomActividad"
                        headerStyle={{ width: '200px' }}
                      >
                      </Column>
                      <Column
                        field="FechaEstimadaInicio"
                        body={FechaEstimadaInicioTemplate}
                        className="text-center"
                        header={t('reportevanceMensualProyecto:Start')}
                        columnKey="FechaEstimadaInicio"
                        headerStyle={{ width: '80px' }}
                      >
                      </Column>
                      <Column
                        field="FechaEstimadaFin"
                        body={FechaEstimadaFinTemplate}
                        className="text-center"
                        header={t('reportevanceMensualProyecto:End')}
                        columnKey="FechaEstimadaFin"
                        headerStyle={{ width: '80px' }}
                      >
                      </Column>
                      <Column
                        field="DiasEstimados"
                        header={t('reportevanceMensualProyecto:Est')}
                        columnKey="DiasEstimados"
                        className="text-center"
                        headerStyle={{ width: '40px' }}
                      >
                      </Column>
                      <Column
                        field="DiasReales"
                        header={t('reportevanceMensualProyecto:Real')}
                        columnKey="DiasReales"
                        className="text-center"
                        headerStyle={{ width: '40px' }}
                      >
                      </Column>
                      <Column
                        field="DiasAtraso"
                        header={t('reportevanceMensualProyecto:Delay')}
                        columnKey="DiasAtraso"
                        className="text-center"
                        headerStyle={{ width: '50px' }}
                      >
                      </Column>
                      <Column
                        field="NomFase"
                        header={t('reportevanceMensualProyecto:Phase')}
                        columnKey="NomFase"
                        headerStyle={{ width: '150px' }}
                      >
                      </Column>
                      <Column
                        field="NomColaborador"
                        header={t('reportevanceMensualProyecto:Responsable')}
                        columnKey="NomColaborador"
                        headerStyle={{ width: '150px' }}
                      >
                      </Column>
                    </FieldDataTable>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    &nbsp;
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <FieldDataTable
                      value={ActividadesCompletadasList}
                      scrollable
                      scrollHeight="330px"
                      className="editable-cells-table"
                      editMode="cell"
                      selectionMode="single"
                      header={headerActividadesCompletadas}
                      headerColumnGroup={headerActividadesCompletadasGroup}
                    >
                      <Column
                        field="NomActividad"
                        header={t('reportevanceMensualProyecto:Activity')}
                        columnKey="NomActividad"
                        headerStyle={{ width: '200px' }}
                      >
                      </Column>
                      <Column
                        field="FechaRealInicio"
                        body={FechaRealInicioTemplate}
                        className="text-center"
                        header={t('reportevanceMensualProyecto:Start')}
                        columnKey="FechaRealInicio"
                        headerStyle={{ width: '80px' }}
                      >
                      </Column>
                      <Column
                        field="FechaRealFin"
                        body={FechaRealFinTemplate}
                        className="text-center"
                        header={t('reportevanceMensualProyecto:End')}
                        columnKey="FechaRealFin"
                        headerStyle={{ width: '80px' }}
                      >
                      </Column>
                      <Column
                        field="DiasEstimados"
                        header={t('reportevanceMensualProyecto:Est')}
                        columnKey="DiasEstimados"
                        className="text-center"
                        headerStyle={{ width: '40px' }}
                      >
                      </Column>
                      <Column
                        field="DiasReales"
                        header={t('reportevanceMensualProyecto:Real')}
                        columnKey="DiasReales"
                        className="text-center"
                        headerStyle={{ width: '40px' }}
                      >
                      </Column>
                      <Column
                        field="DiasAtraso"
                        header={t('reportevanceMensualProyecto:Delay')}
                        columnKey="DiasAtraso"
                        className="text-center"
                        headerStyle={{ width: '50px' }}
                      >
                      </Column>
                      <Column
                        field="NomFase"
                        header={t('reportevanceMensualProyecto:Phase')}
                        columnKey="NomFase"
                        headerStyle={{ width: '150px' }}
                      >
                      </Column>
                      <Column
                        field="NomColaborador"
                        header={t('reportevanceMensualProyecto:Responsable')}
                        columnKey="NomColaborador"
                        headerStyle={{ width: '150px' }}
                      >
                      </Column>
                    </FieldDataTable>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                 &nbsp;
              </Col>
            </Row>
            <Row className="align-items-start">
              <Col lg={4} md={4} sm={12} xs={12}>
                <FieldDataTable
                  value={showActiveRows(FuturoList)}
                  scrollable
                  scrollHeight="330px"
                  className="editable-cells-table"
                  editMode="cell"
                  selectionMode="single"
                  header={headerFuturo}
                >
                  <Column body={(rowData, props) => fieldsRequiredFuturoList(rowData, props)} headerStyle={{ width: '20px' }}></Column>
                  <Column body={(rowData, props) => actionBodyTemplateFuturo(rowData, props)} headerStyle={{ width: '35px' }}></Column>
                  <Column
                    field="Descripcion"
                    header={t('reportevanceMensualProyecto:Description')}
                    headerStyle={{ width: '240px' }}
                    editor={(props) => DescripcionFuturoEditor(props)}
                  >
                  </Column>
                  <Column
                    field="SourceDescrition"
                    header={t('reportevanceMensualProyecto:Source')}
                    style={{ display : (ClaTipoProyecto === 1 || ClaTipoProyecto === 2 || ClaTipoProyecto === 3 ?'none':'')}} 
                    headerStyle={{ width: '240px', display : (ClaTipoProyecto === 1 || ClaTipoProyecto === 2 || ClaTipoProyecto === 3 ?'none':'') }}
                  >
                  </Column>
                  <Column
                    field="EsMostrarEnOrangePage"
                    header={t('reportevanceMensualProyecto:ShowinOP')}
                    style={{ display : (ClaTipoProyecto === 1 || ClaTipoProyecto === 2 || ClaTipoProyecto === 3 ?'none':'')}} 
                    headerStyle={{ width: '80px', display : (ClaTipoProyecto === 1 || ClaTipoProyecto === 2 || ClaTipoProyecto === 3 ?'none':'') }}
                    body={(rowData, props) => EsMostrarEnOrangePageFuturoEditor(rowData, props)}
                  >
                  </Column>
                </FieldDataTable>
              </Col>
              <Col lg={8} md={8} sm={12} xs={12}>
                <FieldDataTable
                  value={ActividadesSiguienteList}
                  scrollable
                  scrollHeight="330px"
                  className="editable-cells-table"
                  editMode="cell"
                  selectionMode="single"
                  header={headerActividadesSiguiente}
                  headerColumnGroup={headerActividadesSiguienteGroup}
                >
                  <Column
                    field="NomActividad"
                    header={t('reportevanceMensualProyecto:Activity')}
                    columnKey="NomActividad"
                    headerStyle={{ width: '200px' }}
                  >
                  </Column>
                  <Column
                    field="FechaEstimadaInicio"
                    body={FechaEstimadaInicioTemplate}
                    className="text-center"
                    header={t('reportevanceMensualProyecto:Start')}
                    columnKey="FechaEstimadaInicio"
                    headerStyle={{ width: '80px' }}
                  >
                  </Column>
                  <Column
                    field="FechaEstimadaFin"
                    body={FechaEstimadaFinTemplate}
                    className="text-center"
                    header={t('reportevanceMensualProyecto:End')}
                    columnKey="FechaEstimadaFin"
                    headerStyle={{ width: '80px' }}
                  >
                  </Column>
                  <Column
                    field="DiasEstimados"
                    header={t('reportevanceMensualProyecto:Est')}
                    columnKey="DiasEstimados"
                    className="text-center"
                    headerStyle={{ width: '40px' }}
                  >
                  </Column>
                  <Column
                    field="DiasReales"
                    header={t('reportevanceMensualProyecto:Real')}
                    columnKey="DiasReales"
                    className="text-center"
                    headerStyle={{ width: '40px' }}
                  >
                  </Column>
                  <Column
                    field="NomFase"
                    header={t('reportevanceMensualProyecto:Phase')}
                    columnKey="NomFase"
                    headerStyle={{ width: '150px' }}
                  >
                  </Column>
                  <Column
                    field="NomColaborador"
                    header={t('reportevanceMensualProyecto:Responsable')}
                    columnKey="NomColaborador"
                    headerStyle={{ width: '150px' }}
                  >
                  </Column>
                </FieldDataTable>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                  &nbsp;
              </Col>
            </Row>
            <Row className="align-items-start">
              <Col lg={4} md={4} sm={12} xs={12}>
                <span className="mr-2">{t('reportevanceMensualProyecto:EsMostrarSoloUsuarioMSW')}</span>
                <FieldCheckbox
                  inputId={EsMostrarSoloUsuarioMSW}
                  checked={EsMostrarSoloUsuarioMSW}
                  onChange={(e) => onChangeIncluirSoloUsuariosMSW(props, e)}
                />
                <FieldDataTable
                  value={showActiveRows(SoporteList)}
                  scrollable
                  scrollHeight="330px"
                  className="editable-cells-table"
                  editMode="cell"
                  selectionMode="single"
                  header={headerSoporte}
                >
                  <Column body={(rowData, props) => fieldsRequiredSoporteList(rowData, props)} headerStyle={{ width: '20px' }}></Column>
                  <Column body={(rowData, props) => actionBodyTemplateSoporte(rowData, props)} headerStyle={{ width: '35px' }}></Column>
                  <Column
                    field="Descripcion"
                    header={t('reportevanceMensualProyecto:Description')}
                    headerStyle={{ width: '240px' }}
                    editor={(props) => DescripcionSoporteEditor(props)}
                  >
                  </Column>            
                  <Column
                    field="NomUsuario"
                    header={t('reportevanceMensualProyecto:Responsible')}
                    columnKey="ClaUsuario"
                    headerStyle={{ width: '260px' }}
                    editor={(props) => ResponsibleParticipantsEditor(props)}
                  >
                  </Column>
                  <Column
                    field="FechaCompromiso"
                    body={FechaCompromisoTemplate}
                    className="text-center"
                    header={t('reportevanceMensualProyecto:DueDate')}
                    columnKey="FechaCompromiso"
                    headerStyle={{ width: '160px' }}              
                    editor={(props) => FechaCompromisoEditor(props)}
                  >
                  </Column>
                  <Column
                    field="FechaRealTermino"
                    body={FechaRealTerminoTemplate}
                    className="text-center"
                    header={t('reportevanceMensualProyecto:FinishedDate')}
                    columnKey="FechaRealTermino"
                    headerStyle={{ width: '160px' }}
                    editor={(props) => FechaRealTerminoEditor(props)}
                  >
                  </Column>
                </FieldDataTable>
              </Col>
              <Col lg={8} md={8} sm={12} xs={12}>
                <FieldDataTable
                  value={EntregableList}
                  scrollable
                  scrollHeight="330px"
                  className="editable-cells-table"
                  editMode="cell"
                  selectionMode="single"
                  header={headerActividadesEntregable}
                >
                  <Column
                    field="NomFase"
                    header={t('reportevanceMensualProyecto:Phase')}
                    columnKey="NomFase"
                    headerStyle={{ width: '180px' }}
                  >
                  </Column>
                  <Column
                    field="Descripcion"
                    header={t('reportevanceMensualProyecto:Description')}
                    columnKey="Descripcion"
                    headerStyle={{ width: '240px' }}
                  >
                  </Column>
                  <Column
                    field="NomResponsable"
                    header={t('reportevanceMensualProyecto:Responsable')}
                    columnKey="NomResponsable"
                    headerStyle={{ width: '200px' }}
                  >
                  </Column>
                  <Column
                    field="FechaEstimadaFin"
                    body={FechaEstimadaFinTemplate}
                    className="text-center"
                    header={t('reportevanceMensualProyecto:EstimatedEndDate')}
                    columnKey="FechaEstimadaFin"
                    headerStyle={{ width: '80px' }}
                  >
                  </Column>
                  <Column
                    field="FechaTermino"
                    body={FechaTerminoTemplate}
                    className="text-center"
                    header={t('reportevanceMensualProyecto:EstimatedDate')}
                    columnKey="FechaTermino"
                    headerStyle={{ width: '80px' }}
                  >
                  </Column>
                </FieldDataTable>
              </Col>
            </Row>
          </>
        )
        :
        (
          <>        
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                  &nbsp;
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <FieldDataTable
                  value={SeguimientoFasesListeningList}
                  scrollable
                  scrollHeight="330px"
                  className="editable-cells-table"
                  editMode="cell" 
                  header={headerSeguimientoFasesListening}
                  headerColumnGroup={headerSeguimientoFasesListeningGroup}
                >
                  <Column
                    field="ClaProyecto"
                    header={t('reportevanceMensualProyecto:Key')}
                    columnKey="ClaProyecto"
                    headerStyle={{ width: '40px' }}
                    body={keyTemplate}
                  >
                  </Column>
                             
                  <Column
                    field="NomProyecto"
                    header={t('reportevanceMensualProyecto:Project')}
                    columnKey="NomProyecto"
                    headerStyle={{ width: '180px' }}
                    body={nomProyectoTemplate}
                  >
                  </Column>
                  <Column
                    field="NomIniciativa"
                    header={t('reportevanceMensualProyecto:Initiative')}
                    columnKey="NomIniciativa"
                    headerStyle={{ width: '180px' }}
                  >
                  </Column>
                  <Column
                    field="Semaforo"
                    body={SemaforoTemplate}
                    className="text-center"
                    header={t('reportevanceMensualProyecto:-')}
                    columnKey="Semaforo"
                    headerStyle={{ width: '25px' }}
                  >
                  </Column>
                  <Column
                    field="NomEstatusProyecto"
                    header={t('reportevanceMensualProyecto:Status')}
                    columnKey="NomEstatusProyecto"
                    headerStyle={{ width: '90px' }}
                    className="text-center"
                  >
                  </Column>
                  <Column
                    field="AvanceEstimado"
                    body={AvanceEstimadoTemplate}
                    className="text-center"
                    header={t('reportevanceMensualProyecto:ProgressEst')}
                    columnKey="AvanceEstimado"
                    headerStyle={{ width: '50px' }}
                  >
                  </Column>
                  <Column
                    field="AvanceReal"
                    body={AvanceRealTemplate}
                    className="text-center"
                    header={t('reportevanceMensualProyecto:ProgressReal')}
                    columnKey="AvanceReal"
                    headerStyle={{ width: '50px' }}
                  >
                  </Column>
                  <Column
                    field="NomResponsable"
                    header={t('reportevanceMensualProyecto:Responsable')}
                    columnKey="NomResponsable"
                    headerStyle={{ width: '180px' }}
                  >
                  </Column>
                  <Column
                    field="PorcPeso"
                    body={PorcPesoTemplate}
                    className="text-center"
                    header={t('reportevanceMensualProyecto:Weight')}
                    columnKey="PorcPeso"
                    headerStyle={{ width: '60px' }}
                  >
                  </Column>  
                  <Column
                    field="FechaEstimadaInicio"
                    body={FechaEstimadaInicioTemplate}
                    className="text-center"
                    header={t('reportevanceMensualProyecto:StartDate')}
                    columnKey="FechaEstimadaInicio"
                    headerStyle={{ width: '85px' }}
                  >
                  </Column>
                  <Column
                    field="FechaEstimadaFin"
                    body={FechaEstimadaFinTemplate}
                    className="text-center"
                    header={t('reportevanceMensualProyecto:EndDate')}
                    columnKey="FechaEstimadaFin"
                    headerStyle={{ width: '85px' }}
                  >
                  </Column>
                     
                  <Column
                    field="ComentariosActualizacionFase"
                    header={t('reportevanceMensualProyecto:ConsiderableUpd')}
                    columnKey="ComentariosActualizacionFase"
                    headerStyle={{ width: '290px' }}
                    editor={(props) => ComentariosActualizacionFaseListeningEditor(props)}
                    body={ComentariosActualizacionFaseTemplate}
                    editorValidator={editorValidator}
                  >
                  </Column>
                </FieldDataTable>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                &nbsp;
              </Col>
            </Row>
            <Row className="align-items-start">
              <Col lg={6} md={6} sm={12} xs={12}>
                <FieldDataTable
                  value={showActiveRows(PresenteList)}
                  scrollable
                  scrollHeight="330px"
                  className="editable-cells-table"
                  editMode="cell"
                  selectionMode="single"
                  header={headerPresente}
                >
                  <Column body={(rowData, props) => fieldsRequiredPresenteList(rowData, props)} headerStyle={{ width: '20px' }}></Column>
                  <Column body={(rowData, props) => actionBodyTemplatePresente(rowData, props)} headerStyle={{ width: '35px' }}></Column>
                  <Column
                    field="Descripcion"
                    header={t('reportevanceMensualProyecto:Description')}
                    headerStyle={{ width: '240px' }}
                    editor={(props) => DescripcionPresenteEditor(props)}
                  >
                  </Column>
                  <Column
                    field="SourceDescrition"
                    header={t('reportevanceMensualProyecto:Source')}                    
                    style={{ display : (ClaTipoProyecto === 1 || ClaTipoProyecto === 2 || ClaTipoProyecto === 3 ?'none':'')}} 
                    headerStyle={{ width: '240px', display : (ClaTipoProyecto === 1 || ClaTipoProyecto === 2 || ClaTipoProyecto === 3 ?'none':'') }}
                  >
                  </Column>
                  <Column
                    field="EsMostrarEnOrangePage"
                    header={t('reportevanceMensualProyecto:ShowinOP')}
                    headerStyle={{ width: '80px', display : (ClaTipoProyecto === 1 || ClaTipoProyecto === 2 || ClaTipoProyecto === 3 ?'none':'') }}
                    style={{ display : (ClaTipoProyecto === 1 || ClaTipoProyecto === 2 || ClaTipoProyecto === 3 ?'none':'')}} 
                      // editor={(props) => EsMostrarEnOrangePageEditor(props)}
                    body={(rowData, props) => EsMostrarEnOrangePageEditor(rowData, props)}
                  >
                  </Column>
                </FieldDataTable>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>                          
                <FieldDataTable
                  value={showActiveRows(FuturoList)}
                  scrollable
                  scrollHeight="330px"
                  className="editable-cells-table"
                  editMode="cell"
                  selectionMode="single"
                  header={headerFuturo}
                >
                  <Column body={(rowData, props) => fieldsRequiredFuturoList(rowData, props)} headerStyle={{ width: '20px' }}></Column>
                  <Column body={(rowData, props) => actionBodyTemplateFuturo(rowData, props)} headerStyle={{ width: '35px' }}></Column>
                  <Column
                    field="Descripcion"
                    header={t('reportevanceMensualProyecto:Description')}
                    headerStyle={{ width: '240px' }}
                    editor={(props) => DescripcionFuturoEditor(props)}
                  >
                  </Column>
                  <Column
                    field="SourceDescrition"
                    header={t('reportevanceMensualProyecto:Source')}
                    style={{ display : (ClaTipoProyecto === 1 || ClaTipoProyecto === 2 || ClaTipoProyecto === 3 ?'none':'')}} 
                    headerStyle={{ width: '240px', display : (ClaTipoProyecto === 1 || ClaTipoProyecto === 2 || ClaTipoProyecto === 3 ?'none':'') }}
                  >
                  </Column>
                  <Column
                    field="EsMostrarEnOrangePage"
                    header={t('reportevanceMensualProyecto:ShowinOP')}
                    style={{ display : (ClaTipoProyecto === 1 || ClaTipoProyecto === 2 || ClaTipoProyecto === 3 ?'none':'')}} 
                    headerStyle={{ width: '80px', display : (ClaTipoProyecto === 1 || ClaTipoProyecto === 2 || ClaTipoProyecto === 3 ?'none':'') }}
                    body={(rowData, props) => EsMostrarEnOrangePageFuturoEditor(rowData, props)}
                  >
                  </Column>
                </FieldDataTable>                                
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                 &nbsp;
              </Col>
            </Row>
            <Row className="align-items-start">
              <Col lg={6} md={6} sm={12} xs={12}>
                <FieldDataTable
                  value={showActiveRows(SoporteList)}
                  scrollable
                  scrollHeight="330px"
                  className="editable-cells-table"
                  editMode="cell"
                  selectionMode="single"
                  header={headerSoporte}
                >
                  <Column body={(rowData, props) => fieldsRequiredSoporteList(rowData, props)} headerStyle={{ width: '20px' }}></Column>
                  <Column body={(rowData, props) => actionBodyTemplateSoporte(rowData, props)} headerStyle={{ width: '35px' }}></Column>
                  <Column
                    field="Descripcion"
                    header={t('reportevanceMensualProyecto:Description')}
                    headerStyle={{ width: '240px' }}
                    editor={(props) => DescripcionSoporteEditor(props)}
                  >
                  </Column>            
                  <Column
                    field="NomUsuario"
                    header={t('reportevanceMensualProyecto:Responsible')}
                    columnKey="ClaUsuario"
                    headerStyle={{ width: '260px' }}
                    editor={(props) => ResponsibleParticipantsEditor(props)}
                  >
                  </Column>
                  <Column
                    field="FechaCompromiso"
                    body={FechaCompromisoTemplate}
                    className="text-center"
                    header={t('reportevanceMensualProyecto:DueDate')}
                    columnKey="FechaCompromiso"
                    headerStyle={{ width: '160px' }}              
                    editor={(props) => FechaCompromisoEditor(props)}
                  >
                  </Column>
                  <Column
                    field="FechaRealTermino"
                    body={FechaRealTerminoTemplate}
                    className="text-center"
                    header={t('reportevanceMensualProyecto:FinishedDate')}
                    columnKey="FechaRealTermino"
                    headerStyle={{ width: '160px' }}
                    editor={(props) => FechaRealTerminoEditor(props)}
                  >
                  </Column>
                </FieldDataTable>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                <FieldDataTable
                  value={EntregableList}
                  scrollable
                  scrollHeight="330px"
                  className="editable-cells-table"
                  editMode="cell"
                  selectionMode="single"
                  header={headerActividadesEntregable}
                >
                  <Column
                    field="NomFase"
                    header={t('reportevanceMensualProyecto:Phase')}
                    columnKey="NomFase"
                    headerStyle={{ width: '180px' }}
                  >
                  </Column>
                  <Column
                    field="Descripcion"
                    header={t('reportevanceMensualProyecto:Description')}
                    columnKey="Descripcion"
                    headerStyle={{ width: '240px' }}
                  >
                  </Column>
                  <Column
                    field="NomResponsable"
                    header={t('reportevanceMensualProyecto:Responsable')}
                    columnKey="NomResponsable"
                    headerStyle={{ width: '200px' }}
                  >
                  </Column>
                  <Column
                    field="FechaEstimadaFin"
                    body={FechaEstimadaFinTemplate}
                    className="text-center"
                    header={t('reportevanceMensualProyecto:EstimatedEndDate')}
                    columnKey="FechaEstimadaFin"
                    headerStyle={{ width: '80px' }}
                  >
                  </Column>
                  <Column
                    field="FechaTermino"
                    body={FechaTerminoTemplate}
                    className="text-center"
                    header={t('reportevanceMensualProyecto:EstimatedDate')}
                    columnKey="FechaTermino"
                    headerStyle={{ width: '80px' }}
                  >
                  </Column>
                </FieldDataTable>
              </Col>
            </Row>
          </>
        )
      } 
        </>
      </Dialog> 

      <Dialog
        visible={openViewFull}
        style={{ width: '80vw' }}
        footer={null}
        modal 
        onHide={closeModalViewFull}
        contentStyle={{maxHeight: "500px", overflow:"auto"}}
        header={t('orangePage:KPIProgress')}
      >
        <Col lg={12} md={12} sm={12} xs={12}>
          <ProyectoKPIManagementFullView ClaProyecto={ClaProyecto}></ProyectoKPIManagementFullView>
        </Col>
      </Dialog>
 
      {
        EsMostrarProgressReportParent && 
        (<OrangePage Visible={EsMostrarProgressReportParent} onHide={()=> setEsMostrarProgressReportParent(false)} ClaProyecto={claSelectedProject} IdReporteMensual={idSelectedReporteMensual}></OrangePage> )
      } 


      <Dialog
        visible={EsAbrirDocumentosSoporte}
        header={`${EncabezadoList.NomPeriodo} - ${t('reportevanceMensualProyecto:SupportFilesTitle')}`}
        style={{width: '80vw'}}
        modal
        className="dialog-custom"
        onHide={()=> setEsAbrirDocumentosSoporte(false)}
      >
        <Row>
          <Col>
            <ReporteAvanceMensualProyectoAttachment
              IsUpload={true}
              IdReporteAvanceMensualProyecto={IdReporteAvanceMensualProyecto}
            >
            </ReporteAvanceMensualProyectoAttachment>
          </Col>
        </Row>
      </Dialog>
      
      {
        esMostrarFormulaReview && 
        (  
          <KPIFormulaResultadoReview show={esMostrarFormulaReview} onHide={() => setEsMostrarFormulaReview(false)} AnioMes={AnioMes} IdKPIFormula={IdKPIFormulaSelected}></KPIFormulaResultadoReview>
        )
      }
    </>

    
  );
};
export default ReporteAvanceMensualProyecto;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi, showSweetAlert, getSessionItem } from '../../../utils/utils';
import { Checkbox } from 'primereact/checkbox';
import FieldText from '../../../components/Controls/FieldText.jsx';
import FieldTextArea from '../../../components/Controls/FieldTextArea.jsx';
import FieldNumber from '../../../components/Controls/FieldNumber.jsx';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx';
import FieldDropdown from 'components/Controls/FieldDropdown.jsx';
import { RadioButton } from 'primereact/radiobutton';
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import moment from 'moment';
import { Sidebar } from 'primereact/sidebar';
import swal from '@sweetalert/with-react';
import { Tag } from 'primereact/tag';
import PageContent from 'layouts/PageContent.jsx';
import { ColumnGroup } from 'primereact/columngroup';
import SalesDashboard from "./SalesDashboard";
import './SalesDashboardScreen.scss';  
import OrganizationalSalesRepView from './OrganizationalSalesRepView.jsx';

const SalesDashboardScreen = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['salesDashboard', 'common']);
 
  const [ClaUsuario, setClaUsuario] = useState(null);  
  const [IsLoading, setIsLoading] = useState(true);

  useEffect(() => { 
     // Mandamos verificar con el usaurio conectado cual es el ClaAgenteVentas Default que estara visualizando
     const GetOrganizationalSalesRepByUser = `${config.UrlApiProject}ScoreCard/GetOrganizationalSalesRepByUser`;
     const paramsToService = {};

    callApi(GetOrganizationalSalesRepByUser, 'GET', paramsToService, (response) => {
       const firstElement   = response.data.length > 0 ? response.data[0] : {};
       console.log('firstElement', firstElement);
       if (firstElement.key !== null && typeof(firstElement.key) !== 'undefined'){
         setClaUsuario(firstElement.key); /* key = ClaUsaurio */
       }

       setIsLoading(false);
    });

  }, []); 
  
  const organizationalView = (
    <div className='organization-default-width'>
      <div className='WTW-Step-1'>
        <OrganizationalSalesRepView value={ClaUsuario} showPositionName={true} onChange={(itemValue, itemNode) => onChangeOrganizationalValue(itemValue, itemNode)}></OrganizationalSalesRepView> 
      </div>
    </div>

  )
  
  const onChangeOrganizationalValue = (itemValue, itemNode)=> {  
    setClaUsuario(itemNode.key); /* key = ClaUsaurio */
  }

  if (IsLoading){
    return (<>Loading...</>);
  }

  if (ClaUsuario === null){
    return (
      <div className='SalesDashboardScreen'>
        <PageContent title={t('salesDashboard:SalesDashboardWTW')}>
          <Row className="align-items-start"> 
            <Col className='pt-10'> 
              <Card>  
                <CardBody className="card-body text-center">
                  There is no report available for signed in user.
                  <div className='pt-3'> 
                    If you consider that you should have access to this dashboard, please make your request through IT Department.
                  </div>
                </CardBody>
              </Card> 
            </Col> 
          </Row>   
        </PageContent> 
      </div>
    )
  }
  
  return (
    <>
      <div className='SalesDashboardScreen'>
        <PageContent title={t('salesDashboard:SalesDashboardWTW')} toolBar={organizationalView}>
          <Row className="align-items-start">
            <Col lg={12} md={12} sm={12} xs={12}>
              <SalesDashboard
                ClaUsuario={ClaUsuario}
              >
              </SalesDashboard>
            </Col> 
          </Row>   
        </PageContent>
      </div>
    </>
  );
};
export default SalesDashboardScreen;

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { config } from '../../../utils/config';
import { callApi, showSweetAlert } from '../../../utils/utils';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import moment from 'moment';
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon';
import swal from '@sweetalert/with-react';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import { Column } from 'primereact/column'; 
import PDFViewer from 'components/Controls/PDFViewer/PDFViewer';
import VideoPlayer from 'components/Controls/VideoPlayer/VideoPlayer.jsx';  
import { Dialog } from 'primereact/dialog';

const FileLibraryFileByUserList = (props) => {
  const { t } = useTranslation(['dms', 'common']);
  const [LibraryFileByUserList, setLibraryFileByUserList] = useState([]);
  const [LibraryFileByUserListDynamic, setLibraryFileByUserListDynamic] = useState([]);  
  const [showVisorPDF, setShowVisorPDF] = useState(false);
  const [file, setFile] = useState(null);
  const VideoPlayerViewer  = useRef();     
  const [EsShowDialog, setEsShowDialog] = useState(false);
  const [fileExtension, setfileExtension] = useState('');

  useEffect(() => {
    console.log('props.filtersData', props.filtersData);
    if (Object.keys(props.filtersData).length !== 0) {
      if (props.filtersData.isClearInfo){   
        setLibraryFileByUserList([]);
      }
      else{
        loadFileLibraryByUserList(props.filtersData); 
      } 
    }     

  }, [props.refresh, props.filtersData]);

  const loadFileLibraryByUserList = (filtersData) => {
    const GetFileLibraryByUserList = `${config.UrlApiProject}DMS/GetFileLibraryByUserList`;
    
    const paramsToService = {
      ClaLibrary:filtersData.ClaLibrary,
      TitleFile:filtersData.TitleFile,
      DescriptionFile:filtersData.DescriptionFile,
      LibraryAttributeListDTO: filtersData.AttributeList
    };
    console.log(`GetFileLibraryByUserList filtersData:${filtersData}`, paramsToService);
    callApi(GetFileLibraryByUserList, 'POST', paramsToService, (response) => {
      setLibraryFileByUserList(response.data.LibraryFileByUser);
      setLibraryFileByUserListDynamic(response.data.LibraryFileByUserColumnDynaic);      
    });
  };  

  const LibraryColumn = (rowData, props) => {

    let type = null;

        switch (rowData.Extension) {
          case '.mp4':
          case '.webm':
            type = 'video'; 
            break;
          case '.pdf': 
              type = 'pdf'; 
              break;
          case '.jpg':
          case '.gif':
          case '.jpeg':
          case '.jfif':
          case '.pjpeg':
          case '.png':
          case '.svg':
          case '.webp':
          case '.bmp':
          case '.ico':
          case '.cur': 
                type = 'image'; 
                break;
          default:
            break;
        } 


    return (
      <>
        <FieldButton
          type="button" 
          onClick={() => downloadFile(rowData)}
          data-pr-position="top" 
          data-pr-tooltip={t('dms:Download')}
          title={t('dms:Download')}
          icon="fas fa-download"
          className="p-button-rounded white no-border"
        /> 
        &nbsp;
        {
          type === null ?
          (<></>)
          :
          (
            <FieldButton
              type="button" 
              onClick={() => viewFile(rowData)}
              data-pr-position="top" 
              data-pr-tooltip={t('dms:ViewFile')}
              title={t('dms:ViewFile')}
              icon={type === 'image' ? "fas fa-image" : type === "pdf" ? "fas fa-file-pdf" : "fas fa-video"}
              className={type === 'image' ? "p-button-rounded p-button-outlined info-color no-border" : type === "pdf" ? "p-button-rounded p-button-outlined danger-color no-border" : "p-button-rounded p-button-outlined danger-color no-border"} 
            />  
          )
        }
        
      </>
    );
  };

  const viewFile = (rowData) => { 
    const GetFileData = `${config.UrlApiProject}DMS/GetLibraryFileById`;  
    // const GetFileData = `${config.UrlApiProject}DMS/GetFileFromServer`;  
    const paramsToService = { 
      IdLibraryFile:rowData.IdLibraryFile
    };   
    callApi(GetFileData, 'POST', paramsToService, (response) => {
      const AnexoDetalle = response.data.AnexoDetalle[0];
      const AnexoImg = response.data.ImgDownLoad;
        console.log(response);
      if(AnexoDetalle!==undefined){
        
        let linkSource = ''

        switch (AnexoDetalle.TypeExtension) {
          case 'mp4':
          case 'webm':
            linkSource = `data:video/${AnexoDetalle.TypeExtension};base64,${AnexoImg}`;
            setEsShowDialog(true); 
            break;
          case 'pdf':
              linkSource = `data:application/${AnexoDetalle.TypeExtension};base64,${AnexoImg}`;
              setShowVisorPDF(true); 
              break;
          case 'jpg':
          case 'gif':
          case 'jpeg':
          case 'jfif':
          case 'pjpeg':
          case 'png':
          case 'svg':
          case 'webp':
          case 'bmp':
          case 'ico':
          case 'cur':
                linkSource = `data:image/${AnexoDetalle.TypeExtension};base64,${AnexoImg}`;
                setEsShowDialog(true); 
                break;
          default:
            break;
        } 

        setFile(linkSource);
        setfileExtension(AnexoDetalle.TypeExtension);
      }
    });  
  }
  
  

  const downloadFile = (rowData) => { 
  const GetFileData = `${config.UrlApiProject}DMS/GetLibraryFileById`;  
  // const GetFileData = `${config.UrlApiProject}DMS/GetFileFromServer`;  
  const paramsToService = { 
    IdLibraryFile:rowData.IdLibraryFile
  };   
  callApi(GetFileData, 'POST', paramsToService, (response) => {
    const AnexoDetalle = response.data.AnexoDetalle[0];
    const AnexoImg = response.data.ImgDownLoad;
      console.log(response);
    if(AnexoDetalle!==undefined){
      const linkSource = `data:application/${AnexoDetalle.TypeExtension};base64,${AnexoImg}`;
      const downloadLink = document.createElement("a");
      const fileName = `${AnexoDetalle.DownLoadFileName}`;
      downloadLink.href = linkSource;
      downloadLink.download = `${fileName}.${AnexoDetalle.TypeExtension}`;
      downloadLink.click();
    }
  });  
}

const renderDynamicValue = (columnName, row, ClaFormularioTipoCampo, props) => {      
  /* 
    1	Text
    2	Integer
    3	Numeric
    4	Date
    5	Date & Time
    6	Title / Seccion
    7	Long-Text
    8	Single-Selection
    9	Multi-Selection
    10	Single-Selection-Button
    11	Multi-Selection-Button
  */

  if (ClaFormularioTipoCampo === 2) {
      return (<>{ row[columnName] === null ? '' : row[columnName].toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</>);    
  }

  if (ClaFormularioTipoCampo === 3) {
    return (<>{ row[columnName] === null ? '' : row[columnName].toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</>);    
  }

  if (ClaFormularioTipoCampo === 4) {
    return (<>{ row[columnName]  === null ? '' : moment(row[columnName]).format('MM/DD/YYYY')}</>);    
  }

  if (ClaFormularioTipoCampo === 5) {
    return (<>{ row[columnName]  === null ? '' : moment(row[columnName]).format('MM/DD/YYYY HH:mm')}</>);    
  }

  return (
    <>
      { row[columnName] }
    </>
  );

}

const dynamicColumns = LibraryFileByUserListDynamic.map((col,i) => {
  return (
    <Column 
      className="table-header-background-dark-green" 
      columnKey={col.NomLibraryAttribute}
      field={col.NomLibraryAttribute}
      header={col.NomLibraryAttribute}
      headerStyle={{ width: '250px'}}
      body={(rowData, props) => renderDynamicValue(col.NomLibraryAttribute, rowData, col.ClaFormularioTipoCampo, props)}
    >
    </Column>
);
});

const renderHeader = () => {
  return (
    <div>
      <Row className="text-center">
        <Col lg={12} md={12} sm={12} xs={12} className="text-left">
          {t('dms:Viewer')}
        </Col>
      </Row>
    </div>
  );
};


  return (
    <>     
      {
      LibraryFileByUserList.length > 0 ? (
        <>
          <Row>     
            <Col lg={12} md={12} sm={12} xs={12}>
              <FieldDataTable
                value={LibraryFileByUserList}    
                scrollable
                scrollHeight="450px"
              >
                <Column                
                  body={(rowData, props) => LibraryColumn(rowData, props)}
                  headerStyle={{ width: '80px' }}
                >
                </Column>
                <Column
                  field="NomLibrary"
                  columnKey="NomLibrary"
                  header={t('dms:NomLibrary')}
                  headerStyle={{ width: '180px' }}
                >
                </Column>   
                <Column
                  field="TitleFile"
                  columnKey="TitleFile"
                  header={t('dms:TitleFile')}
                  headerStyle={{ width: '250px' }}
                >
                </Column>  
                <Column
                  field="DescriptionFile"
                  columnKey="DescriptionFile"
                  header={t('dms:DescriptionFile')}
                  headerStyle={{ width: '300px' }}
                >
                </Column>     
                {dynamicColumns}               
                {
                  /* 
                   <Column
                    field="NomColaborador"
                    columnKey="NomColaborador"
                    header={t('dms:CreateBy')}
                    headerStyle={{ width: '500px' }}
                  >
                  </Column>
                  <Column
                    field="FechaUltimaMod"
                    columnKey="FechaUltimaMod"
                    header={t('dms:LastUpd')}
                    headerStyle={{ width: '115px' }}
                    body={FechaUltimaModTemplate}
                  >
                  </Column>      
                  */
                }   
              </FieldDataTable>
            </Col>        
          </Row>
          <PDFViewer
            file={file} 
            show={showVisorPDF}
            onHide={() => { setShowVisorPDF(false) }}
          > 
          </PDFViewer> 
          <Dialog
            visible={EsShowDialog}
            modal
            style={{ width: '100vw' }}
            footer=""
            header={renderHeader} 
            closable={true}
            className="dialog-header-background-blue"
            maximized={true}
            draggable={false}
            blockScroll={true}
            onHide={() => setEsShowDialog(false)}
          >

            {
              fileExtension === 'mp4'
              ||
              fileExtension === 'webm'
              ?
              (
                <Row className='h-100'>
                  <Col className='h-100 video-visor'>  
                    <VideoPlayer reference={VideoPlayerViewer} url={file} height="100%"></VideoPlayer>  
                  </Col>
                </Row>
              )
              :
              (<></>)
            }

            {
              fileExtension === 'jpg'   || fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'jfif' ||
              fileExtension === 'pjpeg' || fileExtension === 'png' || fileExtension === 'svg' || fileExtension === 'webp'  ||
              fileExtension === 'bmp'   || fileExtension === 'ico' || fileExtension === 'cur' 
              ?
              (
                <Row className='h-100'>
                  <Col className='h-100'>  
                    <img
                      src={file} 
                      width="100%"                   
                    />
                  </Col>
                </Row>
              )
              :
              (<></>)
            }  
          </Dialog>
          
        </>
      ) : (<></>)
    }     
    </>
  );
};

export default FileLibraryFileByUserList;

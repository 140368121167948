import PageContent from 'layouts/PageContent';
import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import ManageITTickets from './ManageITTickets';
import ManageITTicketsFilters from './ManageITTicketsFilters';

const ManageITTicketsScreen = (props) => {
  const {t, i18n} = useTranslation(['it', 'common']);
  const [filtersData, setfiltersData] = useState({});

  const onSearch = (filters) => {
    setfiltersData({...filters});   
  };

  return (
    <PageContent title={t('it:ManageITTickets')}>
      <ManageITTickets />
    </PageContent>
  )
}

export default ManageITTicketsScreen
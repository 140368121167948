/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';
import PageContent from 'layouts/PageContent'; 
import PLByBucketFilterCorporateView from './PLByBucketFilterCorporateView';
import PLByBucketTreeCorporateView from './PLByBucketTreeCorporateView';
import { config } from '../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../utils/utils';
import './PLByBucket.css'; 

const PLByBucketScreen = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['corporate', 'common']);  
  const [filtersData, setfiltersData] = useState({});
  const [matriz, setMatriz] = useState([]);    

  const onSearch = (filters) => { 
    setfiltersData(filters);
     
    const getCorporatePLAdministration = `${config.UrlApiProject}CorporateReports/GetCorporatePLByBucket`;      
    const paramsToService = {
      Anio:filters.Seg1ClaAnio,
      Anio2: filters.Seg2ClaAnio,
      ClaEscenarioInfo1: filters.Seg1ClaEscenarioInfo,
      ClaEscenarioInfo2: filters.Seg2ClaEscenarioInfo,
      ClaMes1: filters.Seg1ClaMesFrom,
      ClaMes2: filters.Seg1ClaMesTo,
      ClaMes3: filters.Seg2ClaMesFrom,
      ClaMes4: filters.Seg2ClaMesTo,
      EsPromedio: filters.EsPromedio ? 1 : 0,
      EsMetricTons: filters.EsMTons ? 1 : 0,
      EsTodosRegresarTodos: 1,
      ClaVistaPL : filters.ClaVistaPL,
      ClaUbicacion: filters.ClaUbicacion
    }; 
    callApi(getCorporatePLAdministration, 'POST', paramsToService, (response) => {  
      setMatriz(response.data.CorporateDetalle); 
    }); 
  };

  const onChangeFilters = () => {
    setMatriz([]);
  }
  return (
    <>
      <PageContent title={t('corporate:TitlePLBucket')}>     
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <PLByBucketFilterCorporateView onSearch={onSearch} ReportID={2} onChangeFilters={onChangeFilters}></PLByBucketFilterCorporateView>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
          </Col>
        </Row>
        <Card>   
          <CardBody>  
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <PLByBucketTreeCorporateView Rows={matriz} filters={filtersData}></PLByBucketTreeCorporateView>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </PageContent>
    </>
  );
};
export default PLByBucketScreen;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from 'utils/config';
import { formatDate, callApi } from 'utils/utils';
import { Column } from 'primereact/column';
import { Sidebar } from 'primereact/sidebar';
import { Row, Col } from 'reactstrap';
import FieldText from 'components/Controls/FieldText';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';

const ITAssetsDetails = (props) => {
  const {t, i18n} = useTranslation(['it', 'common']);

  const [assignmentHistory, setAssignmentHistory] = useState([]); // Asset assignment history
  const [asset, setAsset] = useState([]);
  const [showEditDetails, setShowEditDetails] = useState(false);

  const {selectedAsset} = props;

  useEffect(() => {
    getAssignmentHistory();
    getAssetDetails();
  }, [])

  const getAssignmentHistory = () => {
    const urlWebService = `${config.UrlApiProject}IT/GetITAssetAssignmentHistory?IdITEquipo=${selectedAsset.IdITEquipo}`;
    const paramsService = {};

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setAssignmentHistory(response.data);
    })
  }

  const getAssetDetails = () => {
    const urlWebService = `${config.UrlApiProject}IT/GetITEquipmentDetails?IdITEquipo=${selectedAsset.IdITEquipo}`;
    const paramsService = {};

    callApi(urlWebService, 'GET', paramsService, (response) => {
      const detailsList = [];

      for (const [key, value] of Object.entries(response.data)) {
        detailsList.push({
          key,
          value
        })
      }

      setAsset(detailsList);
    })
  }

  const assignedDateTemplate = (row) => {
    return (
      <>
        <span>{formatDate(row.FechaAsignada)}</span>
      </>
    )
  }

  return (
    <>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} className='p-field'>
          <FieldDataTable
            value={asset}
            header={t('it:Details')}
            paginator={false}
          >
            <Column field='key' header={t('it:Property')} headerStyle={{ width: '125px' }} />
            <Column field='value' header={t('it:Value')} />
          </FieldDataTable>
        </Col>
      </Row>
      <Row className='p-field'>
        <Col xs={12} sm={12} md={12} lg={12}>
          <FieldDataTable
            value={assignmentHistory}
            header={t('it:AssignmentHistory')}
            paginator={false}
          >
            <Column body={assignedDateTemplate} header={t('common:Date')} headerStyle={{ width: '90px' }} />
            <Column field='NombreUbicacion' header={t('it:Location')} headerStyle={{ width: '150px' }} />
            <Column field='AssignedTo' header={t('it:AssignedTo')} headerStyle={{ width: '150px' }} />
            <Column field='NomUsuario' header={t('it:User')} headerStyle={{ width: '150px' }} />
            <Column field='Notas' header={t('it:Notes')} />
          </FieldDataTable>
        </Col>
      </Row>
    </>
  )
}

export default ITAssetsDetails;
import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { config } from 'utils/config';
import { callApi, showSweetAlert } from 'utils/utils';
import FieldSelectButton from 'components/Controls/FieldSelectButton';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import YupValidator from 'utils/YupValidator';
import * as yup from 'yup';
import { Dialog } from 'primereact/dialog';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import { Column } from 'primereact/column';
import SolicitudMaterialCelda from './SolicitudMaterialCelda';
import FieldCheckbox from 'components/Controls/FieldCheckbox';
import moment from 'moment';
import FieldDataView from 'components/Controls/FieldDataView/FieldDataView';
import { KeyboardReturnOutlined } from '@material-ui/icons';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  ListGroupItem,
  ListGroup,
  Row, Col, FormGroup, Label  
} from "reactstrap";
import './SolicitudMaterialList.scss';
import { DataViewLayoutOptions } from 'primereact/dataview';

const SolicitudMaterialList = (props) => {
  const {t, i18n} = useTranslation(['operations', 'common']);

  const [list, setList] = useState([]); // Solicitud List
  const [showAddNew, setShowAddNew] = useState(false); // Show add new dialog
  const [showCompletedChecked, setShowCompletedChecked] = useState(false);
  const [layout, setLayout] = useState('grid');

  // Handle Done button click
  const onDoneClick = (solicitudMaterialCeldaId) => {
    const urlWebService = `${config.UrlApiProject}Operations/CompleteSolicitudMaterialCelda`;
    const paramsService = {
      SolicitudMaterialCeldaID: solicitudMaterialCeldaId
    };

    callApi(urlWebService, 'POST', paramsService, (response) => {
      getList(); 
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
    });
  };

  const onAddNewClick = () => { setShowAddNew(true); };

  const onAddNewHide = () => { 
    setShowAddNew(false); 
    getList();
  };

  const onShowCompletedChange = (e) => {
    setShowCompletedChecked(e.checked);
  }

  // Get Solicitud list
  const getList = () => {
    const esPendienteEntregar = showCompletedChecked === true ? 0 : 1;
    const urlWebService = `${config.UrlApiProject}Operations/GetSolicitudMaterialList?EsPendienteEntregar=${esPendienteEntregar}`; 
    const paramsService = null;

    callApi(urlWebService, 'GET', paramsService, (response) => {
      const rows = response.data.Rows;

      for (const row of Object.keys(rows)) {
        // Add Done button
        if (showCompletedChecked === false) {
          const button = (
            <FieldButton
              label="Done"
              onClick={() => { onDoneClick(rows[row].SolicitudMaterialCeldaID) }}
            >
            </FieldButton>
          );
          rows[row].Button = button;
        }

        rows[row].FechaSolicitudFormatted = moment(rows[row].FechaSolicitud).format("M/D/YY h:mm A");
      };

      setList(rows);
    });
  };

  const renderGridItem = (request) => {
    return ( 
      <Card className="solicitudList-card mb-3">
        <CardHeader className="solicitudList-header">
          {`${request.NomAreaProduccionIngles} - ${t('operations:cellLabel')} #${request.ClaveCelda}`}
        </CardHeader>
        <CardBody className="solicitudList-body">
          <Row className="">
            <Col>
              <Row>{`${t('operations:itemLabel')}: ${request.NomTipoMaterial} ${request.ClaveArticulo === null ? '' : `- ${request.ClaveArticulo}`}`}</Row>
              <Row>{`${t('operations:quantityLabel')}: ${request.Quantity == null ? '' : request.Quantity}`}</Row>
              <Row>{`${t('operations:idLabel')}: ${request.SolicitudMaterialCeldaID}`}</Row>
            </Col>
            <>
              {
                showCompletedChecked === false ?
                (
                  <Col>
                    <FieldButton
                      label="Done"
                      onClick={() => { onDoneClick(request.SolicitudMaterialCeldaID) }}
                      className="float-right p-button-lg"
                      icon="pi pi-check"
                      iconPos="left"
                    >
                    </FieldButton>
                  </Col>
                )
                : <></>
              }
            </>
          </Row>
        </CardBody>
        <CardFooter className="solicitudList-footer">
          {`${t('operations:requestedLabel')}: ${moment(request.FechaSolicitud).format("M/D/YY h:mm A")}`}
        </CardFooter>
      </Card>
    );
  }

  const renderListItem = (request) => {
    return (
      <Row className="mb-2 mt-2 solicitudList-body">
        <Col>
          <Row>{`${request.NomAreaProduccionIngles} - ${t('operations:cellLabel')} #${request.ClaveCelda}`}</Row>
          <Row>{`${t('operations:itemLabel')}: ${request.NomTipoMaterial} ${request.ClaveArticulo === null ? '' : `- ${request.ClaveArticulo}`}`}</Row>
          <Row>{`${t('operations:quantityLabel')}: ${request.Quantity == null ? '' : request.Quantity}`}</Row>
          <Row>{`${t('operations:requestedLabel')}: ${moment(request.FechaSolicitud).format("M/D/YY h:mm A")}`}</Row>
          <Row>{`${t('operations:idLabel')}: ${request.SolicitudMaterialCeldaID}`}</Row>
        </Col>
        <>
          {
            showCompletedChecked === false ?
            (
              <Col>
                <FieldButton
                  label="Done"
                  onClick={() => { onDoneClick(request.SolicitudMaterialCeldaID) }}
                  className="float-right p-button-lg"
                  icon="pi pi-check"
                  iconPos="right"
                >
                </FieldButton>
              </Col>
            )
            : <></>
          }
        </>
      </Row>
    );
  }

  const itemTemplate = (request, layout) => {
    if (!request) {
      return;
    }

    if (layout === 'grid') {
      return (
        <Col xs={12} sm={12} md={6} lg={4} className="align-items-start mb-3">
          {renderGridItem(request)}
        </Col>
      )
    }
    if (layout === 'list') {
      return (
        <Col xs={12} sm={12} md={12} lg={12}>
          {renderListItem(request)}
        </Col>
      )
    }

  };

  const renderHeader = () => {
    return(
      <Row>
        <Col>
          <DataViewLayoutOptions className="float-right" layout={layout} onChange={(e) => setLayout(e.value)} />
        </Col>
      </Row>
    );
  };

  useEffect(() => {
    getList();
  }, [showCompletedChecked]);


  return (
    <>
      <Dialog
        header={t("operations:addNewLabel")}
        visible={showAddNew}
        onHide={onAddNewHide}
      >
        <SolicitudMaterialCelda onAddNewHide={onAddNewHide} />
      </Dialog>
      <Row>
        <Col>
          <Label className="mr-2">Show Completed</Label>
          <FieldCheckbox
            onChange={onShowCompletedChange}
            checked={showCompletedChecked}
          >
          </FieldCheckbox>
        </Col>
        <Col>
          <FieldButton
            label={t("operations:addNewLabel")}
            className="float-right"
            onClick={onAddNewClick}
          >
          </FieldButton>
          <FieldButton
            label={t('operations:refreshLabel')}
            className="float-right mr-3"
            onClick={getList}
          >
          </FieldButton>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <Card>
            <CardHeader className="light">
              {renderHeader()}
            </CardHeader>
            <CardBody>
              <FieldDataView
                value={list}
                layout={layout}
                itemTemplate={itemTemplate}
                paginator
                rows={50} 
                className="no-scroll-dataview"
              >
              </FieldDataView>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SolicitudMaterialList;
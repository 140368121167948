/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next';  
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row,Col,Card, CardHeader, CardTitle, CardBody} from 'reactstrap';   
import { config } from '../../../utils/config';
import { callApi,showSweetAlert, tracker} from '../../../utils/utils.js'; 
import { Badge } from 'primereact/badge';
import moment from "moment"; 
import FieldDataView  from 'components/Controls/FieldDataView/FieldDataView.jsx' 
import FasesActividadesAnexo from "../Projects/FasesActividadesAnexo.jsx";
import MisActividadesFinalizar from "views/ScoreCard/MyActivities/MisActividadesFinalizar"
import FasesActividadesComentario from "../Projects/FasesActividadesComentario.jsx"
import FieldCalendar  from 'components/Controls/FieldCalendar.jsx' 
import { Dialog } from 'primereact/dialog'; 
import { when } from 'jquery';
import ActionPlanQuickview from '../Scorecard/ActionPlans/ActionPlanQuickview.jsx';
import ActionPlan from '../Scorecard/ActionPlans/ActionPlan.jsx';
import FasesActividades from '../Projects/FasesActividades.jsx';
import { Link } from "react-router-dom";

const MisActividadesEnProceso = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common', 'misActividades']);
  const [errors, setErrors] = useState({}); 
  const [ActividadesEnProceso, setActividadesEnProceso] = useState([]); 
  const [layout, setLayout] = useState('grid');
  const [EsVerAnexo, setEsVerAnexo] = useState(false); 
  const [EsTerminarActividad, setEsTerminarActividad] = useState(false); 
  const [EsAbrirNotas, setEsAbrirNotas] = useState(false); 
  const [ClaProyecto, setClaProyecto] = useState(null); 
  const [ClaFase, setClaFase] = useState(null); 
  const [ClaActividad, setClaActividad] = useState(null); 
  const [ActividadDescripcion, setActividadDescripcion] = useState(null); 
  const [EsEditarActividadProyecto, setEsEditarActividadProyecto] = useState(false);
  const editProjectLnk = useRef(null); 

  
  const [EsAbrirActionPlan, setEsAbrirActionPlan] = useState(false); 
  const [ClaPeriodo, setClaPeriodo] = useState(null); 
  const [ClaFechaReporte, setClaFechaReporte] = useState(null); 
  const [IdCSF, setIdCSF] = useState(null); 
  

  useEffect(() => {    
    obtenerMisActividadesEnProceso();
  }, [props.filters]); 


  const obtenerMisActividadesEnProceso = () => { 
    const GetActividadesEnProceso = `${config.UrlApiProject}ScoreCard/GetProyectoSeguimientoEnProceso`;  
    const paramsToService = {
      ...props.filters,
      // ClaFase:null,
      ClaActividad:null 
    };   
    callApi(GetActividadesEnProceso, 'POST', paramsToService, (response) => { 
      setActividadesEnProceso(response.data.ProyectoActividadesEnProceso); 
    });
  }

  const LabelTypeActivity = (data)=> {
    let labelTypeActivity = ""; 
    switch (data.ActivityType) {
      case 1:
        labelTypeActivity = t('misActividades:Project')
        break;
      case 2:
        labelTypeActivity = t('misActividades:Source')
        break;       
      case 3: 
        labelTypeActivity = t('misActividades:Source')
        break;       
      case 4: 
        labelTypeActivity = t('misActividades:Source')
        break;
      case 5:
          labelTypeActivity = t('misActividades:Source')
          break;
      default:
        labelTypeActivity = t('misActividades:Project')
        break;
    }
    return labelTypeActivity;
  }
  const renderGridItem = (data) => {
    return (
      <div className="p-col-12">
        <div className="dataview-content"> 
          <Row className="">
            <Col lg={8} md={8} sm={12} xs={12} className="">
              {
                  data.ActivityType === 1 ? (
                    <span className="bold">{`${ LabelTypeActivity(data)}`}
                      : 
                      &nbsp; {  
                        data.EsEnProyectoMaestro === 1 ?  
                          <span className="btn-inner--icon"><i title={t('misActividades:MasterProjectAward')} className="fa fa-award purple-color" /></span> 
                        : 
                        ''
                      } 
                      <a 
                        className='Cursor overflow-ellipsis' 
                        style={{color:'#5e72e4'}} 
                        onClick={()=> onProjectClick(data.ClaProjectRel)}
                      >
                        {data.ClaProjectRel} - {data.NomProyecto} 
                      </a>                        
                    </span> 
                  ):<span className="bold">{data.NomProyecto}</span>
              }
            </Col>
            <Col lg={4} md={4} sm={12} xs={12} className="text-right">
              <small className="bold">{t('misActividades:CreatedBy')}:</small> &nbsp; 
              <small> {data.NomCreatedBy} </small>
            </Col>
          </Row>
          <Row className="">
            <Col lg={8} md={8} sm={12} xs={12} className="">
              {
                data.ActivityType === 1 ? <span className="bold">{t('misActividades:Phase')}: </span> : <span className="bold">{t('misActividades:Source')}: </span>
              }
              &nbsp; 
              <span className="secondary-color"> 
                {
                  data.ActivityType !== 1 ? `${data.NomTipoIndicador === null ? '' : `${data.NomTipoIndicador} -` } ${data.ClaProjectRel === null ? '' : data.ClaProjectRel} ${data.ClaProjectRel !== null ? '-' : ''} ${data.NomFase}` : data.NomFase 
              }
              </span>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12} className="text-right">
              <small className="bold">{t('misActividades:Responsible')}:</small> &nbsp; <small> {data.NomResponsableActividad} </small>
              
            </Col>
          </Row>
          <Row className="">
            <Col lg={12} md={12} sm={12} xs={12} className="">
              <span className="bold">{t('misActividades:Activity')}: </span>

              {
                data.ActivityType === 1 && data.EsPM === 1 ? 
                (<span className='Cursor' onClick={() => AbrirEdicionActividad(data)}><span className="fa fa-pencil-alt"></span>&nbsp;{data.NomActividad}</span>)
                :
                (<>&nbsp;{data.NomActividad}</>)
              }

              {
                data.FechaReporte !== null ? (<div><span className="bold">{t('misActividades:ReportedDate')}: </span><small className="info-color bold"><i className="pi pi-calendar"></i> &nbsp;{moment(data.FechaReporte).format("ll")}</small></div>) : (<></>)
              }
            </Col> 
          </Row> 
          <Row className="align-items-start">
            <Col lg={6} md={6} sm={12} xs={12}>
              <span className="bold">{t('misActividades:Period')}: </span>&nbsp;
              <small className="info-color bold"> 
                <i className="pi pi-calendar"></i> &nbsp; 
                {moment(data.FechaInicia).format("ll")} - &nbsp; 
                {moment(data.FechaEstimadaTermina).format("ll")}
              </small>
              { 
                data.DiasAtraso > 0 ? 
                (<div> <span className="bold"> <i className="fas fa-exclamation-triangle warning-color bold"></i> </span>&nbsp;<small className="warning-color bold"> {data.DiasAtraso}&nbsp;{t('misActividades:DaysDelayed')} </small></div>)
                :
                (<></>)
              }
            </Col>
            <Col lg={6} md={6} sm={12} xs={12} className="text-right">
              {
               data.IdCSF !== null ?
               (<><FieldButton icon="fa fa-flag warning-color" tooltip={t('misActividades:ViewActioPlan')} title={t('misActividades:ViewActioPlan')} onClick={() => onClickActionPlan(data)} className="p-button-rounded p-button-text white" /></>)
               :
               (<></>)  
              }
              { data.ActivityType  === 1 ? 
                (<FieldButton icon="fas fa-download" tooltip={t('misActividades:DownloadSupportDocuments')} title={t('misActividades:DownloadSupportDocuments')} onClick={() => AbrirAnexo(data)} className="p-button-rounded p-button-text" />)
                :
                (<></>)}
              
              <FieldButton icon="fas fa-comments" tooltip={t('misActividades:AddViewComments')} title={t('misActividades:AddViewComments')} onClick={() => AbrirNotas(data)} className={`p-button-rounded p-button-text ${ data.EsTieneComentarios === 1 ? 'light-yellow-color' : 'light-gray-color' }`} />
              <FieldButton label={t('misActividades:FinishTask')} icon="fas fa-clipboard-check" tooltip={t('misActividades:FinishTask')} onClick={() => AbrirTerminarActividad(data)} className="p-button-rounded p-button-success p-button-text" />
            </Col>
          </Row>  
          <Row className="">
            <Col lg={12} md={12} sm={12} xs={12}>
              <div className="border-color-orange-color" style={{borderBottom: "1px solid"}}></div>
            </Col>
          </Row> 
        </div>  
      </div>
    );
  }
  
  const itemTemplate = (product, layout) => {
    if (!product) {
        return;
    }
    if (layout === 'grid')
        return renderGridItem(product);
  }

   const AbrirAnexo = async (data) => { 
       setActividadDescripcion(data.Descripcion);
       setClaProyecto(data.ClaProyecto);
       setClaFase(data.ClaFase);
       setClaActividad(data.ClaActividad);
       setEsVerAnexo(!EsVerAnexo);
       tracker.trackEvent({
        type: 'MisActividadesEnProceso', /* component name */
        action: 'VIEW_SUPPORT_DOCUMENTS' 
      })
   }
     
   const AbrirTerminarActividad = async (data) => {
     setClaProyecto(data.ClaProyecto);
     setClaFase(data.ClaFase);
     setClaActividad(data.ClaActividad);
     setEsTerminarActividad(!EsTerminarActividad);
   }
 
   const AbrirNotas = async (data) => {
     setClaProyecto(data.ClaProyecto);
     setClaFase(data.ClaFase);
     setClaActividad(data.ClaActividad);
     setEsAbrirNotas(!EsAbrirNotas);
     tracker.trackEvent({
      type: 'MisActividadesEnProceso', /* component name */
      action: 'VIEW_COMMENTS' 
    })
   }

   const AbrirEdicionActividad = async (data) => { 
    setClaProyecto(data.ClaProyecto);
    setClaFase(data.ClaFase);
    setClaActividad(data.ClaActividad);
 
    setEsEditarActividadProyecto(!EsEditarActividadProyecto);
    tracker.trackEvent({
      type: 'MisActividadesEnProceso', /* component name */
      action: 'VIEW_ACTIVITY_DETAIL'
    })
  }

 
   const closeModalNotas = () => {
     setEsAbrirNotas(false); 
   } 

   const closeModalTerminarActividad = () => {
    setEsTerminarActividad(false); 
  } 

  
   const closeModal = () => {
    setEsVerAnexo(false);
    setActividadDescripcion(null);
   } 
 
   const dialogStyle  = ( 
       { 
       width: '80vw'
     
   })

   const onClickCloseComentario = () => {
    closeModalNotas();
    obtenerMisActividadesEnProceso();
  };

  const onClickCloseFinalizar = () => {
    closeModalTerminarActividad();
    obtenerMisActividadesEnProceso();

    if (props.onChange !== null && props.onChange !== 'undefined' && typeof(props.onChange) === 'function'){
      props.onChange();
    }
  };

  const onCloseActivityDetail = () => {
    
    setClaProyecto(null);
    setClaFase(null);
    setClaActividad(null);
    setEsEditarActividadProyecto(false); 
    obtenerMisActividadesEnProceso();
    if (props.onChange !== null && props.onChange !== 'undefined' && typeof(props.onChange) === 'function'){
      props.onChange();
    }
  };


  const headerTerminarActividad = () => {

    if (ClaProyecto>0){
      return (<>{t('misActividades:TituloFinalizarTareaProyecto')}</>);
    }

    if (ClaProyecto===-999){
      return (<>{t('misActividades:TituloFinalizarTareaMinuta')}</>);
    }

    if (ClaProyecto===-998){
      return (<>{t('misActividades:TituloFinalizarTareaAP')}</>);
    }

    if (ClaProyecto===-997){
      return (<>{t('misActividades:TituloFinalizarTareaSporteProgress')}</>);
    }

    return (<>{t('misActividades:TituloFinalizarActividad')}</>);
    
  }

  const onClickActionPlan = (row) => {  
    setClaPeriodo(row.ClaPeriodo);
    setClaFechaReporte(row.ClaFechaReporte);
    setIdCSF(row.IdCSF);
    setEsAbrirActionPlan(true);
  }
  
  const onHideActionPlan = () => {
    setEsAbrirActionPlan(false); 
  }
  
  const onProjectClick = (claProyecto) => {   
    tracker.trackEvent({
      type: 'MisActividadesEnProceso', /* component name */
      action: 'OPEN_EDIT_PROJECT'
    }) 
    if (props.onOpenProject) props.onOpenProject(claProyecto);
  };

    return (
      <>     
        <Row className="pt-3">
          <Col lg={12} md={12} sm={12} xs={12}>
            <Card>
              <CardHeader className="orange">
                <CardTitle>  
                  <Row className="pt-3">
                    <Col>
                      {t('misActividades:WorkingProcess')}
                    </Col>
                    <Col className="text-right">
                      <Badge
                        value={ActividadesEnProceso.length}
                        className="orange-dark"
                        size="large"
                      />  
                    </Col>
                  </Row> 
                  <Row className="pt-2">
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <small className="white-color bold" style={{}}> 
                        <span>
                                  
                        </span> 
                      </small> &nbsp; 
                    </Col>
                  </Row>
                </CardTitle>
              </CardHeader>    
              <CardBody>
                <FieldDataView
                  value={ActividadesEnProceso}
                  layout={layout}
                  itemTemplate={itemTemplate}
                  paginator
                  rows={50}
                  className="dataview-list"
                />
              </CardBody>
            </Card>  
          </Col>
        </Row>
        <Dialog
          visible={EsVerAnexo}
          header={t('misActividades:ActivityDocuments')}
          style={dialogStyle} 
          modal
          className="dialog-custom"
          onHide={closeModal}
        >
          <Row>
            <Col>
              <h3> {ActividadDescripcion} </h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <FasesActividadesAnexo IsUpload={false} IsReadyOnly={EsVerAnexo} ClaProyecto={ClaProyecto} ClaFase={ClaFase} ClaActividad={ClaActividad}  />
            </Col>
          </Row>
        </Dialog>
        <Dialog
          visible={EsTerminarActividad}
          header={headerTerminarActividad}
          style={dialogStyle} 
          modal
          className="dialog-custom"
          onHide={closeModalTerminarActividad}
        >
          <Row className="align-items-start">
            <Col>
              <MisActividadesFinalizar onClickCloseFinalizar={onClickCloseFinalizar} ClaProyecto={ClaProyecto} ClaFase={ClaFase} ClaActividad={ClaActividad}  />
            </Col>
          </Row>
        </Dialog>
        <Dialog
          visible={EsAbrirNotas}
          header={t('misActividades:TituloFaseActividadNotas')}
          style={dialogStyle} 
          modal
          className="dialog-custom"
          onHide={closeModalNotas}
        >
          <Row>
            <Col>
              <FasesActividadesComentario onClickCloseComentario={onClickCloseComentario} ClaProyecto={ClaProyecto} ClaFase={ClaFase} ClaActividad={ClaActividad}  />
            </Col>
          </Row>
        </Dialog> 
        {
          EsAbrirActionPlan ?
          (<ActionPlanQuickview show={EsAbrirActionPlan} onHide={onHideActionPlan} IdCSF={IdCSF} ClaPeriodo={ClaPeriodo} ClaFechaReporte={ClaFechaReporte}></ActionPlanQuickview>)
          :
          (<></>)
        } 

        {
        EsEditarActividadProyecto!= null ? (
          <> 
            <FasesActividades IsVisible={EsEditarActividadProyecto} onClickClose={onCloseActivityDetail} ClaProyecto={ClaProyecto} ClaFase={ClaFase} ClaActividad={ClaActividad}  />
          </>
          ):(<></>)
       }

      </> 
        ); 
};
export default MisActividadesEnProceso;

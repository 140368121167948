/* eslint-disable jsx-a11y/label-has-associated-control */
import React  from 'react';     
import { Checkbox } from 'primereact/checkbox'; 

const FieldCheckbox =  (props) => {
    const onChange = (value) => {  
        if (props.onChange !== null){
            props.onChange(value);
        } 
    }  
    const isFormFieldInValid = (name) => !!(typeof(props.errors) !== 'undefined' && props.errors !== null && props.errors[name]);
    
    const getFormErrorMessage = (name) => {  
        return isFormFieldInValid(name) && <small className="p-error">{props.errors[name].message}</small>;
    };

      return (  
        <>  
          <Checkbox  
            style={{verticalAlign:"middle"}}
            inputId={props.inputId} 
            checked={props.checked}  
            onChange={onChange}
            disabled={props.disabled} 
            name={props.name? props.name: null}
            value={props.value? props.value: null}
            className={`${props.className} ${isFormFieldInValid(props.name) === true ? 'p-invalid' : ''}`}            
          />
        </> 
      )
}   
    
export default FieldCheckbox; 

import { React, UseState, UseEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PageContent from 'layouts/PageContent'; 
import InventoryWeeklyFollowUpScreenDash from './InventoryWeeklyFollowUpScreenDash';

const InventoryWeeklyFollowUpScreen = (props) => {
  const {t, i18n} = useTranslation(['salesPerformance', 'common']);
  
  return (
    <PageContent title={t('salesPerformance:InventoryWeeklyFollowUpDashboard')}>
      <InventoryWeeklyFollowUpScreenDash />
    </PageContent>
  )
}

export default InventoryWeeklyFollowUpScreen;
import React, { useEffect, useState } from 'react'; 
import { config } from '../../../utils/config';
import { callApi } from '../../../utils/utils';
import { 
  Row,
  Col
} from 'reactstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import IconButton from '@material-ui/core/IconButton';
import TimelineIcon from '@material-ui/icons/Timeline'; 
import DescriptionIcon from '@material-ui/icons/Description'; 
import Buckets from '../Buckets/Buckets.jsx';
import { Tag } from 'primereact/tag';
import { useTranslation } from 'react-i18next';
import FieldTag from '../../../components/Controls/FieldTag.jsx'
import PropTypes from 'prop-types'
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
/* ################################# START COMPONENT ################################# */

const CustomerProductDetail = (props) => { 
    const { t } = useTranslation(['formulario','common']);
    const [rowsGrid, setRowsGrid] = useState([]);
    const [showBuckets, setshowBuckets] = useState(false);
    const [bucketFilters , setbucketFilters] = useState({});
    const [SelectedCustomer, setSelectedCustomer] = useState(null);
    const [showAllColumns, setshowAllColumns] = useState(false);
   
    useEffect(() => {
        const GetBallenasGridService = `${config.UrlApiProject}Rentabilidad/GetProductsGrid`; 

        const paramsToService = {
          anioMes: props.filters.AnioMes, 
          claGerenciaRegional: props.filters.ClaGerenciaRegional,
          claClienteCuenta: props.filters.ClaClienteCuenta,
          esMostrarSoloConfiables: props.filters.EsMostrarSoloConfiables,
          ClaClienteUnico: props.filters.ClaClienteUnico
        }; 
    
        callApi(GetBallenasGridService, 'POST', paramsToService, (response) => { 
          setRowsGrid(response.data[0]); 
          setshowBuckets(false); 
        });
    
      }, [props.filters]); 

      const onShowColumnsDetail = () => {  
        setshowAllColumns(!showAllColumns);  
      }

    const header  = ( 
      <>  
        <div className="row">
          <div className="col-12 col-lg-6 col-sm-12"> 
            {t('formulario:FullListOfProductsBoughtBy')}&nbsp;
            {props.filters.NomClienteCuenta} in {props.filters.NomFecha} 
          </div>
          <div className="col-12 col-lg-6 col-sm-12 text-right">
            
            <FieldTag 
              label={showAllColumns ? t('formulario:HideColumns') : t('formulario:ShowColumns')}
              className="info" 
              onClick={onShowColumnsDetail}
            /> 

            &nbsp;
            <Tag className="success">80%</Tag>&nbsp;<Tag className="warning">17%</Tag>&nbsp;<Tag className="light">3%</Tag>&nbsp;<Tag className="danger">(-)</Tag>
          </div>
        </div>  
       
      </>
      )
     
      const PMPriceColumn = (row) => {  
        return (<>${ row.PMPrice === null ? '' : row.PMPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>);
      }
    
      const PMPorcColumn = (row) => {  
        return (<>{ row.PMPorc === null ? '' : row.PMPorc.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}%</>);
      }
    
      const TonsColumn = (row) => {  
          return (<>{row.Tons === null ? '' : row.Tons.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>);
      }
     
      const ConvCostColumn = (row) => {  
        return (<>${row.ConvCostReal === null ? '' : row.ConvCostReal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>);
      }

      const FreightInboundColumn = (row) => {  
        return (<>${row.FreightInboundReal === null ? '' : row.FreightInboundReal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>);
      }

      const FreightOutboundColumn = (row) => {  
        return (<>${row.FreightOutboundReal === null ? '' : row.FreightOutboundReal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>);
      }
      
      const PriceColumn = (row) => {  
        return (<>${row.PriceReal === null ? '' : row.PriceReal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>);
      }

      const RawMaterialColumn = (row) => {  
        return (<>${row.RawMaterialReal === null ? '' : row.RawMaterialReal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>);
      }

      const SHColumn= (row) => {  
        return (<>${ row.SHReal === null ? '' : row.SHReal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>);
      } 

      const SGFinancialColumn= (row) => {  
        return (<>${ row.SGFinancialReal === null ? '' : row.SGFinancialReal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>);
      } 

      const ActionsColumn = (row) =>  {    
        return (
          <> 
            <IconButton onClick={(e) => onDetailBucket(row)} size="small" color="primary"><TimelineIcon /></IconButton> 
            <IconButton onClick={(e) => onClickInvoice(row)} size="small" color="success"><DescriptionIcon /></IconButton> 
          </>
        );
      }
      
      const onDetailBucket = (row) =>{
        const filters = { ...props.filters }; 
        filters.ClaGrupoEstadistico3 = row.ClaGrupoEstadistico3;
        setbucketFilters(filters);
        setshowBuckets(true); 
      }

      const onClickInvoice = (row) =>{
        const filters = { ...props.filters }; 
        filters.ClaGrupoEstadistico3 = row.ClaGrupoEstadistico3;
        filters.NomGrupoEstadistico3 = row.NomGrupoEstadistico3;
        props.onClickInvoice(filters)
      }
    
      const PMColumn = (row) => {  
        return (<>${ row.ImpMargenBolsillo === null ? '' : row.ImpMargenBolsillo.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>);
      }

      
    const onRowSelect = (event) => {  
        onDetailBucket(event.data); 

    }

    const AcumuladoImpMargenColumn = (row) => {   
        return (<><Tag className={row.Clasificacion === 1 ? "success" : row.Clasificacion === 2 ? "warning" : row.Clasificacion === 3 ? "light" : row.Clasificacion === 4 ? "danger" : ""}>${row.AcumuladoImpMargen === null ? '' : row.AcumuladoImpMargen.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Tag></>);
      }

      const TonsPorcColumn = (row) => {  
        return (<>{ row.TonsPorc === null ? '' : row.TonsPorc.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%</>);
      }
    
      
  const footer  = ( 
    <> 
      <Row>
        <Column></Column>  
        <Column footer={() => (<b> {t('common:Total')}</b>)}></Column> 
        <Column></Column>  
        <Column footer={() => (<b>{ (rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>    
              
        { showAllColumns ? ( 
          <Column footer={() => (
            <b>
              ${ 
                (rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )) > 0 ? 
                  ( (rowsGrid.reduce((a,v) =>  a += v.RawMaterialRealImp === null ? 0 : v.RawMaterialRealImp , 0 ))/(rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )) ).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                : null
              }
            </b>
          )}
          >
          </Column> 
                  ) : 
                  (<Column style={{display:'none'}}></Column>  )}
                
        { showAllColumns ? ( 
          <Column footer={() => (
            <b>
              ${ 
                (rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )) > 0 ? 
                  ( (rowsGrid.reduce((a,v) =>  a += v.ConvCostRealImp === null ? 0 : v.ConvCostRealImp , 0 ))/(rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )) ).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                : null
              }
            </b>
          )}
          >
          </Column> 
                  ) : 
                  (<Column style={{display:'none'}}></Column>  )}
                
        { showAllColumns ? ( 
          <Column footer={() => (
            <b>
              ${ 
                (rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )) > 0 ? 
                  ( (rowsGrid.reduce((a,v) =>  a += v.FreightInboundRealImp === null ? 0 : v.FreightInboundRealImp , 0 ))/(rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )) ).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                : null
              }
            </b>
          )}
          >
          </Column> 
                  ) : 
                  (<Column style={{display:'none'}}></Column>  )}
                
        { showAllColumns ? ( 
          <Column footer={() => (
            <b>
              ${ 
                (rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )) > 0 ? 
                  ( (rowsGrid.reduce((a,v) =>  a += v.FreightOutboundRealImp === null ? 0 : v.FreightOutboundRealImp , 0 ))/(rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )) ).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                : null
              }
            </b>
          )}
          >
          </Column> 
                  ) : 
                  (<Column style={{display:'none'}}></Column>  )}
                
        { showAllColumns ? ( 
          <Column footer={() => (
            <b>
              ${ 
                (rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )) > 0 ? 
                  ( (rowsGrid.reduce((a,v) =>  a += v.SGFinancialRealImp === null ? 0 : v.SGFinancialRealImp , 0 ))/(rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )) ).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                : null
              }
            </b>
          )}
          >
          </Column> 
                  ) : 
                  (<Column style={{display:'none'}}></Column>  )}
               
        { showAllColumns ? ( 
          <Column footer={() => (
            <b>
              ${ 
                (rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )) > 0 ? 
                  ( (rowsGrid.reduce((a,v) =>  a += v.SHRealImp === null ? 0 : v.SHRealImp , 0 ))/(rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )) ).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                : null
              }
            </b>
          )}
          >
          </Column> 
                  ) : 
                  (<Column style={{display:'none'}}></Column>  )}
               
        { showAllColumns ? ( 
          <Column footer={() => (
            <b>
              ${ 
                (rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )) > 0 ? 
                  ( (rowsGrid.reduce((a,v) =>  a += v.PriceRealImp === null ? 0 : v.PriceRealImp , 0 ))/(rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )) ).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                : null
              }
            </b>
          )}
          >
          </Column> 
                  ) : 
                  (<Column style={{display:'none'}}></Column>  )}
               
               
        <Column footer={() => (
          <b>
            ${ 
                (rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )) > 0 ? 
                  ( (rowsGrid.reduce((a,v) =>  a += v.ImpMargenBolsillo === null ? 0 : v.ImpMargenBolsillo , 0 ))/(rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )) ).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                : null
              }
          </b>
          )}
        >
        </Column>  
        <Column  footer={() => (
          <b>
            { 
                (rowsGrid.reduce((a,v) =>  a += v.ImpPriceVtaPocketPrice === null ? 0 : v.ImpPriceVtaPocketPrice , 0 )) > 0 ? 
                  (100* (rowsGrid.reduce((a,v) =>  a += v.ImpMargenBolsillo === null ? 0 : v.ImpMargenBolsillo , 0 ))/(rowsGrid.reduce((a,v) =>  a += v.ImpPriceVtaPocketPrice === null ? 0 : v.ImpPriceVtaPocketPrice , 0 )) ).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                : null
              }
            %
          </b>
          )}
        >
        </Column>  
        <Column footer={() => (
          <b>
            ${ 
                
                  ( (rowsGrid.reduce((a,v) =>  a += v.ImpMargenBolsillo === null ? 0 : v.ImpMargenBolsillo , 0 )) ).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                 
              }
          </b>
          )}
        >
        </Column>  
        <Column></Column>  
              



      </Row>
    </>
  )


    return (
      <>    
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
            <FieldDataTable 
              value={rowsGrid} 
              header={header} 
              scrollable 
              scrollHeight="270px" 
              className="p-datatable-striped"
              selection={SelectedCustomer} 
              selectionMode="single" 
              onSelectionChange={e => setSelectedCustomer(e.value)} 
              onRowSelect={onRowSelect}
              footerColumnGroup={footer}
              removableSort
            >   
              <Column body={ActionsColumn} header={t('formulario:ViewDetail')} headerStyle={{ width: '110px' }}></Column>  
              <Column field="NomGrupoEstadistico3" header={t('formulario:StatisticalGpo3')} headerStyle={{ width: '330px' }} columnKey="NomGrupoEstadistico3"></Column> 
              <Column sortable body={TonsPorcColumn} header={t('formulario:TonsPorc')} headerStyle={{ width: '110px' }} columnKey="TonsPorc" field="TonsPorc"></Column>  
              <Column sortable body={TonsColumn} header={t('formulario:Tons')} headerStyle={{ width: '110px' }} columnKey="Tons" field="Tons"></Column>    
               
              { showAllColumns ? ( 
                <Column sortable body={RawMaterialColumn} columnKey="RawMaterialReal" field="RawMaterialReal" header={t('formulario:RawMaterial')} headerStyle={{ width: '140px' }}></Column> 
                  ) : 
                  (<Column style={{display:'none'}} headerStyle={{ display: 'none' }}></Column>  )}
                
              { showAllColumns ? ( 
                <Column sortable body={ConvCostColumn} columnKey="ConvCostReal" field="ConvCostReal" header={t('formulario:ConvCost')} headerStyle={{ width: '140px' }}></Column> 
                  ) : 
                  (<Column style={{display:'none'}} headerStyle={{ display: 'none' }}></Column>  )}
                
              { showAllColumns ? ( 
                <Column sortable body={FreightInboundColumn} columnKey="FreightInboundReal" field="FreightInboundReal" header={t('formulario:FreightInbound')} headerStyle={{ width: '160px' }}></Column> 
                  ) : 
                  (<Column style={{display:'none'}} headerStyle={{ display: 'none' }}></Column>  )}
                
              { showAllColumns ? ( 
                <Column sortable body={FreightOutboundColumn} columnKey="FreightOutboundReal" field="FreightOutboundReal" header={t('formulario:FreightOutbound')} headerStyle={{ width: '180px' }}></Column> 
                  ) : 
                  (<Column style={{display:'none'}} headerStyle={{ display: 'none' }}></Column>  )}
                
              { showAllColumns ? ( 
                <Column sortable body={SGFinancialColumn} columnKey="SGFinancialReal" field="SGFinancialReal" header={t('formulario:SGAFinancials')} headerStyle={{ width: '140px' }}></Column> 
                  ) : 
                  (<Column style={{display:'none'}} headerStyle={{ display: 'none' }}></Column>  )}
               
              { showAllColumns ? ( 
                <Column sortable body={SHColumn} columnKey="SHReal" field="SHReal" header={t('formulario:SH')} headerStyle={{ width: '140px' }}></Column> 
                  ) : 
                  (<Column style={{display:'none'}} headerStyle={{ display: 'none' }}></Column>  )}
               
              { showAllColumns ? ( 
                <Column sortable body={PriceColumn} columnKey="PriceReal" header={t('formulario:Price')} headerStyle={{ width: '140px' }} field="PriceReal"></Column> 
                  ) : 
                  (<Column style={{display:'none'}} headerStyle={{ display: 'none' }}></Column>  )}
               
               
              <Column sortable body={PMPriceColumn} header={t('formulario:PMPriceByton')} headerStyle={{ width: '110px' }} columnKey="PMPrice" field="PMPrice"></Column>  
              <Column sortable body={PMPorcColumn} header={t('formulario:PMPorc')} headerStyle={{ width: '110px' }} columnKey="PMPorc" field="PMPorc"></Column>  
              <Column sortable body={PMColumn} header={t('formulario:Contribution')} headerStyle={{ width: '140px' }} columnKey="ImpMargenBolsillo" field="ImpMargenBolsillo"></Column>  
              <Column body={AcumuladoImpMargenColumn} header={t('formulario:AcumMargen')} headerStyle={{ width: '150px' }} columnKey="AcumuladoImpMargen"></Column>  
            </FieldDataTable>    
          </Col>  
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
            &nbsp;
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
            <Buckets showHide={showBuckets} filters={bucketFilters}></Buckets> 
          </Col>
        </Row> 
      </>
    ); 
};


export default CustomerProductDetail;

import React, { useRef, useState, useEffect, useContext } from 'react';
import WebCam from 'react-webcam'
import './CameraComponent.scss'; // Import your CSS file
// import { RTLContext } from '../../../App.js';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import FieldButton from '../FieldButton/FieldButton';
import { useTranslation } from 'react-i18next';
import imageCompression from 'browser-image-compression';
import { showNotify } from 'utils/utils';

const CameraComponent =  ({
  show,
  onAcceptPhoto,
  onHide,
  allowUploadImageFromFile 
}) => {
  const { t } = useTranslation(['common']);
  // const { toastRef } = useContext(RTLContext);
  const [cameraType, setCameraType] = useState('environment'); // 'user' is front camera, 'environment' is rear camera
  const [ImageURL, setImageURL] = useState(null);
  const webCamRef = useRef(null);
  const [IsCameraAccessDenied, setIsCameraAccessDenied] = useState(false);
  const allowedExtensions = ['jpg', 'png', 'jpeg'];

  useEffect(() => {
    async function validateCameraAccess () {
      try {
        const cameraPermission = await navigator.permissions.query({ name: 'camera' });

        console.log('cameraPermission', cameraPermission);

        if (show){
          if (cameraPermission.state === 'denied') {
            setIsCameraAccessDenied(true);
          }
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    validateCameraAccess ();

  }, [show]); // Empty dependency array to run once when the component mounts


  const toggleCamera = () => {
    setCameraType((prevCameraType) =>
      prevCameraType === 'user' ? 'environment' : 'user'
    );
  };

  const compressImage = async (imageFile, callback) => {
    try {
      const options = {
        maxSizeMB: 1, 
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(imageFile, options);
      const compressedBase64 = await imageCompression.getDataUrlFromFile(compressedFile);
      callback(compressedBase64);
    } catch (error) { 
      callback(imageFile);
      console.error('Error compressing image:', error);
    }
  };

  const capture = async() => {
    const cameraPermission = await navigator.permissions.query({ name: 'camera' });

    if (show){
      if (cameraPermission.state === 'denied') {
        setIsCameraAccessDenied(true);
      }
    }

    const imageSrc = webCamRef.current.getScreenshot();
    const imageBlob = await (await fetch(imageSrc)).blob();

    compressImage(imageBlob, setImageURL);
  };

  const uploadPhoto = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/jpeg, image/jpg, image/png';
    if (typeof input.click === 'function') {
      input.addEventListener('change', (event) => {
        const file = event.target.files[0];
        if (file) {
          if (!isValidExtension(file)) {
            /*
            toastRef.current.show(
              {
              severity: 'warn',
              summary: t('common:Warning'),
              detail: t('common:InvalidFileTypeSelected'),
            });
            */
            showNotify(t('common:InvalidFileTypeSelected'),  'centerTop', 'warning');
            setImageURL(null);
            return false;
          }

          compressImage(file, setImageURL);
        }
      });
      input.click();
    }
  };

  const acceptPhoto = () => {
    setImageURL(null);
    // mandamos llamar el metodo de aceptar y le mandamos la imagen: ImageURL
    onAcceptPhoto(ImageURL);
  }

  const retakePhoto = () => {
    setImageURL(null);
  }

  const onCancel = () => {
    setImageURL(null);
    onHide();
  }

  const isValidExtension = (fileInfo) => {
    const fileExtension = fileInfo.name.split(".").pop();

    if (typeof(allowedExtensions) !== 'undefined' &&  allowedExtensions !== null && typeof(allowedExtensions) === 'object')
      {
        if(!allowedExtensions.includes(fileExtension.toString().toLowerCase())){
         return false;
        }
      }
      
      return true; 
    }

  return (
    <Dialog
      className={`${ IsCameraAccessDenied ? 'camera-dialog-white' : 'camera-dialog'}`}
      visible={show}
      modal
      maximized={true}
      onHide={onHide}
      blockScroll={true}
      closable={false}
    >
      {
        IsCameraAccessDenied ?
          (
            <>
              <div className='camera-container'>
                <div className='camera-helper'>
                  <div className='camera-helper-container'>
                    <div className='text-center'>
                      <h6>
                        {t('common:HeaderCameraAccess')}.
                      </h6>
                      <p>
                        {t('common:SubHeaderCameraAccess')}:
                      </p>
                    </div>
                    <ul>
                      <li>{t('common:CameraAccessInstruccion1')}.</li>
                      <li>{t('common:CameraAccessInstruccion2')}.</li>
                      <li>{t('common:CameraAccessInstruccion3')}.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='camera-footer-components'>
                <FieldButton
                  className="p-button-icon-only p-button-rounded camera-button camera-close-button"
                  icon="fa fa-solid fa-close fa-2x px-2"
                  onClick={onHide}
                />
              </div>
            </>
)
          :
          (
            <>
              {
              ImageURL!= null ? (
                <>
                  <div className='camera-container grid p-fluid col-12'>
                    <div className='col-12 text-center'>
                      <Image
                        src={ImageURL}
                        alt="Image"
                        preview
                      />
                    </div>
                  </div>
                  <div className='camera-footer-components'>
                    {allowUploadImageFromFile && (
                      <FieldButton
                        className="p-button-icon-only p-button-rounded camera-button camera-rotate-button pr-2"
                        icon="fas fa-image fa-2x px-2"
                        onClick={()=>uploadPhoto()}
                      />
                    )} 
                    <FieldButton
                      className="p-button-icon-only p-button-rounded camera-button camera-rotate-button pr-2"
                      icon="fas fa-sync fa-2x px-2"
                      onClick={()=>retakePhoto()}
                    />

                    <FieldButton
                      className="p-button-icon-only p-button-rounded p-button-success camera-button pr-2"
                      icon="fa fa-solid fa-check fa-2x px-2"
                      onClick={acceptPhoto}
                    />

                    <FieldButton
                      className="p-button-icon-only p-button-rounded camera-button camera-close-button pr-2"
                      icon="fas fa-times-circle fa-2x px-2"
                      onClick={onCancel}
                    /> 
                  </div>
                </>
              ):(
                <>
                  <div className='camera-container'>
                    <WebCam
                      audio={false}
                      ref={webCamRef}
                      screenshotFormat='image/jpeg'
                      videoConstraints={{
                          facingMode: cameraType,
                        }}
                      video={false}
                      autoPlay
                      playsInline
                      muted
                    />
                  </div>
                  <div className='camera-footer-components'>
                    {allowUploadImageFromFile && (
                      <FieldButton
                        className="p-button-icon-only p-button-rounded camera-button camera-rotate-button pr-2"
                        icon="fas fa-image fa-2x px-2"
                        onClick={()=>uploadPhoto()}
                      />
                    )} 
                    <FieldButton
                      className="p-button-icon-only p-button-rounded camera-button camera-rotate-button pr-2"
                      icon="fas fa-sync fa-2x px-2"
                      onClick={()=>toggleCamera()}
                    />

                    <FieldButton
                      className="p-button-icon-only p-button-rounded p-button-success camera-button pr-2"
                      icon="fa fa-solid fa-camera fa-2x px-2"
                      onClick={capture}
                    />

                    <FieldButton
                      className="p-button-icon-only p-button-rounded camera-button camera-close-button pr-2"
                      icon="fas fa-times-circle fa-2x px-2"
                      onClick={onCancel}
                    />

                  </div>
                </>
              )
            }
            </>
)
      }

    </Dialog>
  );
}

export default CameraComponent;

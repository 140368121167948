/* eslint-disable radix */  
import { config } from './config';
import { setSessionData, getSessionItem, callApi  } from './utils'; 

function decodeToken(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

const IsTokenUpdateRequired = () => {
  const jwtToken = getSessionItem('Token');

  if (jwtToken) {
    const { exp } = decodeToken(jwtToken); 

    // obetnemos los minutos restantes de vencimiento del token, si detectamos que esta a menos de 5 minutos de vencer lo refrescamos
    const diffMiliseconds = (new Date(exp*1000) - Date.now());
    const diffMins = Math.round(((diffMiliseconds % 86400000) % 3600000) / 60000);
      
    if (diffMins <= 30){
       // refrescams el token 
       return true;
    }
     
    return false;
  }

  return false;
}
  
const RefreshToken = () => {
   // si detectamos en el navegador que no tiene internet
    try{
      if (!window.navigator.onLine){
        return ;
      }
    }catch(e){
      // 
    } 
      
    const jwtToken = getSessionItem('Token');

    if (jwtToken) {
        const urlRefreshToken = `${config.UrlLoginServer}/refreshToken`;
        const paramsToService = {
            Token: jwtToken,
        };

        
           callApi(urlRefreshToken, 'POST', paramsToService, (response) => {   
                setSessionData(
                    {
                        Token: response.token, 
                    }  
                );
            }, () => {
                // usamos el callback error para que no continue y no muestre mensaje en dado caso de que este fallando el refreshtoken.
                return true;
            } , false, false); 
    } 
};
 
export {
   RefreshToken,
   IsTokenUpdateRequired 
};

import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardHeader, CardBody, CardFooter, Label } from 'reactstrap';
import { callApi, showSweetAlert } from "utils/utils";
import { config } from 'utils/config';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import { Column } from 'primereact/column';
import YupValidator from 'utils/YupValidator';
import { object, string, number, date, InferType, array } from 'yup';
import PDFViewer from 'components/Controls/PDFViewer/PDFViewer';
import FieldChips from 'components/Controls/FieldChips';
import FixPODType from './FixPODTypes';

const FixPODForm = (props) => {
  const {t, i18n} = useTranslation(['pod', 'common']);

  // const isManualPODSearch = props.isManualPODSearch !== undefined ? props.isManualPODSearch : false;
  const docType = props.docType !== undefined ? props.docType : FixPODType.Email;

  const [doc, setDoc] = useState(props.document !== undefined ? {...props.document} : []);
  const [documentPages, setDocumentPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState([]);
  const [selectedPageIsPOD, setSelectedPageIsPOD] = useState(null);
  const [selectedPageTipoPOD, setSelectedPageTipoPOD] = useState(null);
  const [selectedPageInvoiceNumbers, setSelectedPageInvoiceNumbers] = useState([]);
  const [selectedPageExtractedData, setSelectedPageExtractedData] = useState({});
  const [tiposPOD, setTiposPOD] = useState([]);
  const [pdfLink, setPDFLink] = useState(null);
  const [errors, setErrors] = useState([]);
  const [documentTitle, setDocumentTitle] = useState(props.documentTitle !== undefined ? props.documentTitle : '');
  const [showSubmitNext, setShowSubmitNext] = useState(props.showSubmitNext !== undefined ? props.showSubmitNext : false);

  useEffect(() => {
    getTiposPOD();

    // Prepare the document for the form
    const newDoc = doc;
    const docPages = [];
    for (let i = 0; i < newDoc.Pages.length; i++)
    {
      const newPage = {
        PageNumber: doc.Pages[i].PageNumber,
        PageFile: doc.Pages[i].PageFile,
        IsPOD: null,
        ClaTipoPOD: null,
        NomTipoPOD: "",
        NumFacturas: [],
        ExtractedData: doc.Pages[i].ExtractedData !== undefined ? doc.Pages[i].ExtractedData : {}
      }

      docPages.push(newPage);

      if (i === 0)
      {
        const e = {};
        e.data = newPage
        onSelectedPageChange(e);
      }
    }

    setDocumentPages(docPages);
  }, [doc])

  // Options for isPOD dropdown
  const isPODOptions = [
    {Label: "Yes", Value: true},
    {Label: "No", Value: false}
  ]

  function isObjEmpty (obj) {
    return Object.keys(obj).length === 0;
  }

  // Get the POD types
  const getTiposPOD = () => {
    const urlWebService = `${config.UrlApiProject}POD/GetTiposPOD`;

    const paramsService = {
    };

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setTiposPOD(response.data);
    });
  }

  // Handle IsPOD change
  const onIsPODChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.selectedPageIsPOD;
    setErrors(newErrors);
    setSelectedPageIsPOD(e.value);
  }

  // Handle selected page POD type change
  const onSelectedPageTipoPODChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.selectedPageTipoPOD;
    setErrors(newErrors);
    setSelectedPageTipoPOD(e.value);
  }

  // Handle invoice numbers change
  const onSelectedPageInvoiceNumbersChange = (value) => {
    const newErrors = { ...errors };
    delete newErrors.selectedPageInvoiceNumbers;
    setErrors(newErrors);
    setSelectedPageInvoiceNumbers(value);
  }

  // Page number template
  const pageNumberTemplate = (row) => {
    return (
      <>
        {`Page ${row.PageNumber}`}
      </>
    )
  }

  // Is POD dropdown template
  const isPODTemplate = (row) => {
    if (row.PageNumber === selectedPage.PageNumber) {
      return (
        <Row className='align-items-start'>
          <Col className='p-fluid'>
            <FieldDropdown 
              name='selectedPageIsPOD'
              value={selectedPageIsPOD}
              optionValue="Value"
              optionLabel="Label"
              options={isPODOptions}
              onChange={onIsPODChange}
              errors={errors}
            />
          </Col>
        </Row>
      )
    }

    return (
      <>
        {row.IsPOD ? t('pod:Yes') : row.IsPOD === false ? t('pod:No') : t('pod:NotReviewed')}
      </>
    )
  }

  // Invoice numbers template
  const invoiceNumbersTemplate = (row) => {
    if (row.PageNumber === selectedPage.PageNumber) {
      if (selectedPageIsPOD === true) {
        return (
          <Row className='align-items-start'>
            <Col className='p-fluid'>
              <FieldChips
                name="selectedPageInvoiceNumbers"
                value={selectedPageInvoiceNumbers}
                onChange={(e) => onSelectedPageInvoiceNumbersChange(e.value)}
                separator=","
                errors={errors}
                tooltip={t('pod:InvoiceNumberInstructions')}
                tooltipOptions={{position: 'top', event: 'focus'}}
              />
            </Col>
          </Row>
        )
      }
    }

    return (
      <>
        {row.NumFacturas !== undefined && row.NumFacturas !== null ? String(row.NumFacturas) : ""}
      </>
    )
  }

  // POD Type template
  const tipoPODTemplate = (row) => {
    if (row.PageNumber === selectedPage.PageNumber) {
      if (selectedPageIsPOD === true) {
        return (
          <Row className='align-items-start'>
            <Col className='p-fluid'>
              <FieldDropdown
                name='selectedPageTipoPOD'
                value={selectedPageTipoPOD}
                optionValue="ClaTipoPOD"
                optionLabel="NomTipoPOD"
                options={tiposPOD}
                onChange={onSelectedPageTipoPODChange}
                errors={errors}
                className='mb-2 p-fluid'
              />
            </Col>
          </Row>
        )
      }
    }

    let nomTipoPOD = "";

    for (const tipoPOD of tiposPOD) {
      if (tipoPOD.ClaTipoPOD === row.ClaTipoPOD)
      {
        nomTipoPOD = tipoPOD.NomTipoPOD;
      }
    }

    return (
      <>
        {nomTipoPOD}
      </>
    )
  }

  // Clear button template
  const clearButtonTemplate = (row) => {
    if (row.PageNumber === selectedPage.PageNumber) {
      return (
        <FieldButton className='p-button-rounded p-button-text p-button-secondary' icon='pi pi-times' onClick={() => setSelectedPage({})} />
      )
    }
  }

  // Handle selected page change
  const onSelectedPageChange = async (e) => {
    // Get the document pages
    const docPages = documentPages;

    // If the page is a POD we need to validate the fields
    if (selectedPageIsPOD === true && !isObjEmpty(selectedPage))
    {
      console.log('Change Row: Yes was selected')
      const validationScheme = object({
        selectedPageInvoiceNumbers: array().required(t('common:RequiredField')).min(1, t("pod:AtLeastOneInvoice")),
        selectedPageTipoPOD: number().required(t('common:RequiredField')).positive().integer().nullable(),
      })

      const value = {
        selectedPageInvoiceNumbers,
        selectedPageTipoPOD
      }

      const validator = new YupValidator(validationScheme);
      const isValid = await validator.validate(value);

      if (!isValid) {
        console.log('Change Row: Not Valid');
        setErrors(validator.errors);
        return;
      }

      console.log('Change Row: Valid');

      for (let i = 0; i < docPages.length; i++)
      {
        if (docPages[i].PageNumber === selectedPage.PageNumber)
        {
          docPages[i].IsPOD = selectedPageIsPOD;
          docPages[i].ClaTipoPOD = selectedPageTipoPOD;
          docPages[i].NumFacturas = selectedPageInvoiceNumbers;
        }
      }
    }
    else
    {
      console.log('Change Row: No was selected')
      for (let i = 0; i < docPages.length; i++)
      {
        if (docPages[i].PageNumber === selectedPage.PageNumber)
        {
          docPages[i].IsPOD = selectedPageIsPOD;
          docPages[i].ClaTipoPOD = null;
          docPages[i].NumFacturas = [];
        }
      }

      setSelectedPageInvoiceNumbers([]);
      setSelectedPageTipoPOD(null);
    }

    // Update the page selection table
    setDocumentPages([...docPages]);

    const row = e.data;
    setSelectedPage(e.data);
    setPDFLink(`data:application/pdf;base64,${row.PageFile}`)
    setSelectedPageIsPOD(row.IsPOD);
    setSelectedPageInvoiceNumbers(row.NumFacturas);
    setSelectedPageTipoPOD(row.ClaTipoPOD);
    setErrors([]);

    // Info to show for Fix Manual POD Search
    // setSelectedPageExtractedData(
    //    [
    //     {Field: 'Deacero PO #', Value: row.ExtractedData.DeaceroPONumber},
    //     {Field: "Invoice B/L", Value: row.ExtractedData.InvoiceBL},
    //     {Field: "Invoice Included", Value: row.ExtractedData.InvoiceIncluded},
    //     {Field: "Master BOL", Value: row.ExtractedData.MasterBOL},
    //     {Field: "Release Number", Value: row.ExtractedData.ReleaseNumber},
    //     {Field: "Trip Number", Value: row.ExtractedData.TripNumber}
    //   ]
    // );
  }

  // Handle submit button click
  const onSumbitAllPagesClick = async (submitNext = false) => {
    await onSelectedPageChange({data: selectedPage}); // Validate the row
    setSelectedPage({}); // Set the row to unselected

    swal("Are you sure you want to submit the pages? This cannot be undone.", {
      buttons: {
        yes: {
          text: "Yes",
          value: "yes",
          visible: true,
          className: "danger",
          closeModal: true,
        },
        no: {
          text: "No",
          value: null,
          visible: true,
          className: "",
          closeModal: true
        }
      }
    })
    .then((value) => {
      switch (value) { 
        case "yes":
          submitDocumentAsFixed(submitNext);
          break;
        default:
      }
    }); 
  }

  // Reset the state after Submit & Next click
  const resetState = () => {
    setDocumentPages([]);
    setDocumentTitle('');
    setPDFLink(null);
    setSelectedPage(null);
    setSelectedPageIsPOD(null);
    setSelectedPageTipoPOD(null);
    setSelectedPageInvoiceNumbers([]);
    setSelectedPageExtractedData({});
    setErrors([]);
  }

  // Final validation of document and organization of page structure. Then call the function to submit it to the API
  const submitDocumentAsFixed = async (submitNext) => {
    // Validate that the user has clicked the save button
    // if (Object.keys(selectedPage).length !== 0) {
    //   showSweetAlert('Error', t('pod:UnsavedChanges'), 'error');
    //   return;
    // }

    // Validate that a POD Type and Invoice Numbers are entered when it is a POD
    for (let i = 0; i < documentPages.length; i++) {
      const page = documentPages[i];
      if (page.IsPOD && (page.ClaTipoPOD === null || page.NumFacturas === null || page.NumFacturas === '')) {
        showSweetAlert('Error', t('pod:IsPODPageError'), 'error');
        return;
      }

      if (page.IsPOD !== true && page.IsPOD !== false) {
        showSweetAlert('Error', t('pod:IsPODPageNotReviewedError'), 'error');
        return;
      }
    }

    // Map to the correct structure for each page
    const newDocPages = documentPages.map(page => {
      return {
        ClaTipoPOD: page.ClaTipoPOD,
        IsPOD: page.IsPOD,
        NumFacturas: page.NumFacturas,
        PageFile: page.PageFile
      }
    });

    // Call the submit to api function passed by the parent element
    props.submitDocumentAsFixedToAPI(newDocPages, submitNext);

    // If the user clicked Submit & Next
    if (submitNext) {
      // Clear everything
      resetState();

      // Get and set the next document
      const nextDoc = await props.getNextDocument();
      setDocumentTitle(nextDoc.Title);
      setDoc(nextDoc.Document);
      setShowSubmitNext(nextDoc.ShowSubmitNext);
    }
  }

  // Return
  return (
    <>
      <Row className='align-items-start'>
        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <Card className='mt-2'>
            <CardHeader>
              {documentTitle}
            </CardHeader>
            <CardBody className=''>
              {docType === FixPODType.Manual ? (
                <>
                  <div className='text-warning mb-2'><i className='pi pi-info-circle mr-2' />{t('pod:ManualFixPODInstructionsWarning')}</div>
                </>
              ): docType === FixPODType.Upload ? (
                <>
                  <div className='text-warning mb-2'><i className='pi pi-info-circle mr-2' />{t('pod:FixUploadPODInstructionsWarning')}</div>
                </>
              ): <></>}
              <h3>
                {t('pod:FixPODInstructions1')}
                <ol>
                  <li>{t('pod:FixPODInstructions2')}</li>
                  <li>{t('pod:FixPODInstructions3')}
                    <ol type="a">
                      <li>{t('pod:FixPODInstructions3a')}</li>
                    </ol>
                  </li>
                  <li>{t('pod:FixPODInstructions5')}</li>
                </ol>
              </h3>
              <FieldDataTable
                value={documentPages} 
                header={t('pod:Pages')}  
                scrollable
                selectionMode='single'
                selection={selectedPage}
                onRowSelect={e => onSelectedPageChange(e)}
                paginator={false}
              >
                <Column body={pageNumberTemplate} header={t('pod:PageNumber')} headerStyle={{ width: '90px' }} />
                <Column body={isPODTemplate} header={t('pod:IsPOD')} headerStyle={{ width: '90px' }} />
                <Column body={tipoPODTemplate} header={t('pod:PODType')} headerStyle={{ width: '175px' }} />
                <Column body={invoiceNumbersTemplate} header={t('pod:InvoiceNumbers')} headerStyle={{ width: '475px' }} />
                <Column body={clearButtonTemplate} />
              </FieldDataTable>
              <Row className='mt-2'>
                <Col xs={12} sm={12} md={12} lg={12} className='mt-2 p-jc-center p-grid'>
                  <FieldButton
                    label={t('pod:Submit')}
                    onClick={() => onSumbitAllPagesClick(false)}
                    className='p-button-rounded p-button-success pr-5 pl-5'
                    icon='pi pi-check' 
                  />
                  {showSubmitNext ? (
                    <>
                      <FieldButton
                        label={t('pod:SubmitNext')}
                        onClick={() => onSumbitAllPagesClick(true)}
                        className='p-button-rounded p-button-success pr-5 pl-5 ml-2'
                        icon='pi pi-arrow-right' 
                      />
                    </>
                  ) : <></>}
                </Col>
              </Row>
              {docType === FixPODType.Manual || docType === FixPODType.Upload ? (
                <Row className='mt-2'>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <FieldDataTable
                      value={selectedPageExtractedData}
                      header="This is the data we extracted from the page"
                    >
                      <Column field='Field' header='Field' />
                      <Column field='Value' header='Value' />
                    </FieldDataTable>
                  </Col>
                </Row>
              ) : <></>}
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={6} className='mt-2' style={{height: '90vh'}}>
          <PDFViewer
            className=''
            file={pdfLink}
            ShowDialog={false}
          />
        </Col>
      </Row>
    </>
  )
}

export default FixPODForm;
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Card, CardBody, CardHeader, CardTitle } from 'reactstrap'; 
import { useTranslation } from 'react-i18next';
import { config } from '../../../utils/config';
import { callApi,showSweetAlert} from '../../../utils/utils.js'; 
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import FieldDropdown from '../../../components/Controls/FieldDropdown';
import FieldRadioButton from '../../../components/Controls/FieldRadioButton/FieldRadioButton.jsx';
import FieldDropdownMultiSelect from '../../../components/Controls/FieldDropDownMultiSelect/FieldDropdownMultiSelect.jsx';
import './PriceTrackerScreen.scss';   
import PriceTrackerDetail from './PriceTrackerDetail';   
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { Dialog } from 'primereact/dialog';
import moment from 'moment';
import DataGrid, { Scrolling, Paging, Column } from 'devextreme-react/data-grid';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import InfoNeedsToBeRefresh from 'components/Cards/InfoNeedsToBeRefresh/InfoNeedsToBeRefresh';

const PriceTracker = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['priceTracker', 'common']);
  const [errors, setErrors] = useState({}); 
  const [ClaUsuario, setClaUsuario] = useState(null);  
  const [IsLoading, setIsLoading] = useState(true);
  const [PeriodoFilter, setPeriodoFilter] = useState(null);
  const [ClaPeriodoFilter, setClaPeriodoFilter] = useState([]);
  const [ClaPeriodoMultiFilter, setClaPeriodoMultiFilter] = useState(null);
  const [NomPeriodoFilter, setNomPeriodoFilter] = useState(null);
  const [ClaDivisionFilter, setClaDivisionFilter] = useState(null);
  const [NomDivisionFilter, setNomDivisionFilter] = useState(null);
  const [NomDivisionSearchFilter, setNomDivisionSearchFilter] = useState(null);
  const [ClaNivelFilter, setClaNivelFilter] = useState(1); // BY SG3
  const [NomNivelFilter, setNomNivelFilter] = useState(null);
  const [ClaTipoFilter, setClaTipoFilter] = useState(null);
  const [ClaPriceFilter, setClaPriceFilter] = useState(null);
  const [ClaNivelProducto, setClaNivelProducto] = useState(null);
  const [PeriodoList, setPeriodoList] = useState([]);
  const [DivisionList, setDivisionList] = useState([]);
  const [NivelList, setNivelList] = useState([]);
  const [TipoList, setTipoList] = useState([]);
  const [TipoPrecioList, setTipoPrecioList] = useState([]);
  const [LevelComparisonList, setLevelComparisonList] = useState([]);
  const [layoutLevelComparison, setlayoutLevelComparison] = useState('grid');
  
  const [ChartBubbleSeries, setChartBubbleSeries] = useState([]);
  const [ChartBubbleCategories, setChartBubbleCategories] = useState([]);
  const [EsMostrarDialogDetail, setEsMostrarDialogDetail] = useState(false);
  const [EsMostrarDialogDetailParent, setEsMostrarDialogDetailParent] = useState(false);
  const [NivelGrupo, setNivelGrupo] = useState(null);
  const [TipoPrecio, setTipoPrecio] = useState('price');
  const [EsPrecioNetoFilter, setEsPrecioNetoFilter] = useState(false);

 
  const [ClaZonaFilter, setClaZonaFilter] = useState('All');
  const [ClaStateFilter, setClaStateFilter] = useState('All');
  const [ZonaList, setZonaList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [NeedsToClicSearchButton, setNeedsToClicSearchButton] = useState(false);
  const [ClaSegmento, setClaSegmento] = useState(-1);
  const [SegmentoList, setSegmentoList] = useState([]);

  Highcharts.setOptions({
    lang: {
      thousandsSep: ','
    }
  })
  
  useEffect(() => { 
    getFilters();
    setIsLoading(false);
    setClaTipoFilter(1);
    setClaPriceFilter(1);
  }, []); 

  
const getStateByZoneFilter = (pZona) => {
  const getStateCmb = `${config.UrlApiProject}USADatalake/GetPriceTrackerFilterStateByZone?ClaZona=${pZona}`;
  const paramsToService = {};
 
  setClaStateFilter('All');
  callApi(getStateCmb, 'GET', paramsToService, (response) => {
    setStateList(response.data.StateList);
  });
};

  const getFilters = (sClaPeriodo, sClaDivision) => { 
    const url = `${config.UrlApiProject}USADatalake/GetPriceTrackerFilter`;   
    const paramsService= {
      ClaPeriodo: sClaPeriodo,
      ClaDivision: sClaDivision
    };  

    callApi(url, 'POST', paramsService, (response) => {
      const periodoList = response.data.PeriodoList;
      const divisionList = response.data.DivisionList;
      const nivelList = response.data.NivelList;
      const tipoList = response.data.TipoList;
      const tipoPrecioList = response.data.TipoPrecioList;
      const options = periodoList.map((d) => ({
        value: d.ClaPeriodo,
        label: d.NomPeriodo,
      }));
      setPeriodoList(options);
      setDivisionList(divisionList);
      setNivelList(nivelList)
      setTipoList(tipoList);
      setTipoPrecioList(tipoPrecioList);
      setZonaList(response.data.ZonaList);
      setSegmentoList(response.data.SegmentoList);
      // setStateList(response.data.StateList);
      getStateByZoneFilter(ClaZonaFilter);
    });
  }

  const getZoneStateByDivision = (sClaPeriodo, sClaDivision) => { 
    const url = `${config.UrlApiProject}USADatalake/GetPriceTrackerFilter`;   
    const paramsService= {
      ClaPeriodo: sClaPeriodo,
      ClaDivision: sClaDivision
    };  

    callApi(url, 'POST', paramsService, (response) => { 
      setZonaList(response.data.ZonaList);
      setStateList(response.data.StateList);
      // cambiamos a all por default de nuevo    
      setClaZonaFilter('All');
      setClaStateFilter('All');
    });
  }


  const validationScheme = yup.object().shape({
    ClaDivision: yup.mixed().required(t('common:RequiredField')).nullable(), 
    ClaNivel: yup.mixed().required(t('common:RequiredField')).nullable(), 
    ClaPeriodo: yup
     .array()
       .min(1, t('common:RequiredField'))
       .required(t('common:RequiredField'))
  });

  const getPriceTracker = async () => {
    const value = {
      ClaDivision: ClaDivisionFilter,
      ClaNivel:ClaNivelFilter,
      ClaPeriodo:ClaPeriodoFilter,
      ClaZona:ClaZonaFilter,
      ClaState:ClaStateFilter,
   };
   const validator = new YupValidator(validationScheme);
   const esValido = await validator.validate(value);
   if (!esValido) {
     setErrors(validator.errors);
     showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
     return;
   }

    const url = `${config.UrlApiProject}USADatalake/GetPriceTracker`;    
    const paramsToService = {
      ClaPeriodo:ClaPeriodoMultiFilter, // ClaPeriodoFilter,
      ClaNivel:ClaNivelFilter,
      ClaTipo:ClaTipoFilter,
      ClaDivision:ClaDivisionFilter,
      EsPrecioNeto:EsPrecioNetoFilter,
      ClaZona:ClaZonaFilter,
      ClaState:ClaStateFilter,
      ClaSegmento 
    }
    console.log('getPriceTracker',paramsToService);
    callApi(url, 'POST', paramsToService, (response) => {
      const priceTrackerList = response.data.PriceTrackerList;
      const ListSeriesBubble = response.data.seriesBubbleList;
      setLevelComparisonList(priceTrackerList);
      getChartBubble(ListSeriesBubble);
      setNomDivisionSearchFilter(NomDivisionFilter);
      setNeedsToClicSearchButton(false);
    });
  }

  const getChartBubble = (series) => { 
    setChartBubbleSeries(series)
    setChartBubbleCategories([]);
  }

  const optionsChartBubble =  {
    chart: {
      type: 'bubble',
      plotBorderWidth: 1
    },
    title: {
        text: `${t('priceTracker:ChartComparison')} ${NomDivisionSearchFilter} ${t('priceTracker:ChartBubbleTitle')}`,
        align: 'center'
    },
    

    yAxis: {
      title: {
        text: `% Price Difference` 
      } 
    },
    xAxis: { 
      labels: {
        overflow: 'justify'
      },
      lineColor: "#1E232A",
      tickColor: "#1E232A",
      type: "category"
      /* , scrollbar: {
        enabled: true,  // Set this to true to enable the scrollbar
        barBackgroundColor: 'gray', // Color of the scrollbar
        barBorderRadius: 5, // Radius of the scrollbar
        barBorderWidth: 1, // Width of the scrollbar border
        buttonBackgroundColor: 'gray', // Color of the scroll buttons
        buttonBorderWidth: 1, // Width of the scroll buttons' border
        buttonArrowColor: 'white', // Color of the scroll buttons' arrows
        buttonBorderRadius: 5, // Radius of the scroll buttons
        rifleColor: 'white', // Color of the rifle (the small vertical line on the scrollbar)
        trackBackgroundColor: 'lightgray', // Color of the scrollbar track
        trackBorderWidth: 1, // Width of the scrollbar track's border
        trackBorderRadius: 5, // Radius of the scrollbar track
        liveRedraw: true // Set this to true to allow live redraw when scrolling
      } */
    },
    series: ChartBubbleSeries,
    plotOptions: { 
        series: {
            cursor: 'pointer',
            point: {
            events: {
              click(e){
                console.log('ChartBubbleSeries info',e);
                const info = e.point;
                setNivelGrupo(info.name);
                setEsMostrarDialogDetail(true);
              }
            }
          }
        }
    },
    tooltip: {
      useHTML: true,
      headerFormat: '',
      pointFormat:  `${'<table width="100%">' + 
                    '<tr>' +
                      '<td style="text-align:center;" colspan="3"><b>{point.name}</b></td>' +
                    '</tr>' +
                    '<tr>' +
                      '<td>&nbsp;</td>' +
                      '<td style="text-align:center;border:solid 1px #CDCDCD"><b>{point.nomDivision}</b></td>' +
                      '<td style="text-align:center;border:solid 1px #CDCDCD"><b>{point.nomDivisionComparacion}</b></td>' +
                    '</tr>' +
                    '<tr>' +
                      '<td style="border:solid 1px #CDCDCD">'}${ t('priceTracker:UnitPrice') }</td>` +
                      `<td style="text-align:right;border:solid 1px #CDCDCD">{point.unitPriceDivision:,.2f}</td>` +
                      `<td style="text-align:right;border:solid 1px #CDCDCD">{point.unitPriceDivisionComparacion:,.2f}</td>` +
                    `</tr>` +
                    `<tr>` +
                      `<td style="border:solid 1px #CDCDCD">${ t('priceTracker:Tons') }</td>` +
                      `<td style="text-align:right;border:solid 1px #CDCDCD">{point.tonsDivision:,.2f}</td>` +
                      `<td style="text-align:right;border:solid 1px #CDCDCD">{point.tonsDivisionComparacion:,.2f}</td>` +
                    `</tr>` +
                    `<tr>` +
                      `<td style="border:solid 1px #CDCDCD">${ t('priceTracker:Spread') }</td>` +
                      `<td style="text-align:right;border:solid 1px #CDCDCD">{point.spreadDivision:,.2f}</td>` +
                      `<td style="text-align:right;border:solid 1px #CDCDCD">{point.spreadDivisionComparacion:,.2f}</td>` +
                    `</tr>` +
                    `<tr>` +
                    `<td style="text-align:center;" colspan="3"><b>{point.diferenciaPorc:,.2f}${ t('priceTracker:DifferencePorc') }</b></td>` +
                  `</tr>` +
                  `</table>` +
                  ``
  }
  }

  if (IsLoading){
    return (<>Loading...</>);
  }

  const filterHeaderTemplate = (options) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down text-white' : 'pi pi-chevron-up text-white';
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className="card-header">
        <div className="p-toolbar p-component">
          <div className="p-toolbar-group-left">
            <div className="card-title">
              <FieldButton className={options.togglerClassName} onClick={options.onTogglerClick}>
                <span className={toggleIcon}></span>
                <Ripple />
              </FieldButton>
              <span className={titleClassName}>
                {t('common:Filters')}
              </span>
            </div>  
          </div>
         
          <div className="p-toolbar-group-right">
            <FieldButton className="p-button p-highlight" onClick={getPriceTracker}>
              <i className="pi pi-search"></i>&nbsp;{t('priceTracker:Search')}
            </FieldButton>
          </div>
        </div> 
      </div>
    )
}


const onChangeTipoPrecio = (e) => {   
  if(e.value===1){
    setEsPrecioNetoFilter(false);
  }else{
    setEsPrecioNetoFilter(true);
  }  
  setClaPriceFilter(e.value);
  setNeedsToClicSearchButton(true);
}

const onChangeTypePrice = (e) => {
  if (e === 'price') {
    setEsPrecioNetoFilter(false);
  } else {
    setEsPrecioNetoFilter(true);
  }
  setTipoPrecio(e);
  setNeedsToClicSearchButton(true);
};

const onChangeZona = (e) => {    
  setLevelComparisonList([]);
  getStateByZoneFilter(e.value);
  setClaZonaFilter(e.value);   
  setNeedsToClicSearchButton(true);
}

const onChangeState = (e) => {    
  setLevelComparisonList([]);
  setClaStateFilter(e.value);
  setNeedsToClicSearchButton(true);   
}

const onChangeClaSegmento = (e) => {    
  setLevelComparisonList([]);
  setClaSegmento(e.value);
  setNeedsToClicSearchButton(true);   
}




const filters = () => {
    return (
      <Panel header="Filters" toggleable headerTemplate={filterHeaderTemplate} className="AC-Step-1"> 
        <Row className='PriceTrackerScreen pt-2'>
          <Col lg={6} md={6} sm={12} xs={12}>
            <div className='p-fluid p-2'>
              <table width="100%">
                <tbody>
                  <tr>
                    <td width="50%">
                      <div style={{display:'none'}} className="p-grid p-field col-lg-12"> 
                        <div className="p-field col-lg-12">
                          <FieldDropdown  
                            name="ClaPeriodo" 
                            label={t('priceTracker:Period')}
                            value={ClaPeriodoFilter} 
                            options={PeriodoList}  
                            optionValue="ClaPeriodo"
                            optionLabel="NomPeriodo" 
                            onChange={(e) => onChangePeriodo(e)}
                            errors={errors}
                          />  
                        </div> 
                      </div>
                      <div className="p-grid p-field col-lg-12"> 
                        <div className="p-field col-lg-12">
                          <FieldDropdownMultiSelect
                            isMulti={true}
                            options={PeriodoList}
                            isClearable={true}
                            isSearchable={true}
                            value={ClaPeriodoFilter}
                            name="ClaPeriodo"
                            onChange={(e) => onChangePeriodoMultiSelect(e)}
                            errors={errors}
                            placeholder={t('priceTracker:Period')}
                            label={t('priceTracker:Period')}
                          />
                        </div> 
                      </div>
                    </td>
                    <td width="50%">
                      <div className="p-grid p-field  col-lg-12"> 
                        <div className="p-field col-lg-12 ">
                          <FieldDropdown  
                            name="ClaDivision" 
                            label={t('priceTracker:Division')}
                            value={ClaDivisionFilter} 
                            options={DivisionList}  
                            optionValue="ClaDivision"
                            optionLabel="NomDivision" 
                            onChange={(e) => onChangeDivision(e)}
                            errors={errors}
                          />  
                        </div> 
                      </div> 
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <div className='p-fluid p-2'>
              <table width="100%">
                <tr>
                  <td width="34%">
                    <div className="p-grid p-field col-lg-12"> 
                      <div className="p-field col-lg-12"> 
                        <FieldDropdown  
                          name="ClaNivel" 
                          label={t('priceTracker:Level')}
                          value={ClaNivelFilter} 
                          options={NivelList}  
                          optionValue="ClaNivel"
                          optionLabel="NomNivel" 
                          onChange={(e) => onChangeNivel(e)}
                          errors={errors}
                          showClear={false}
                        />   
           
                      </div>
                    </div> 
                  </td>
                  <td width="33%">
                    <div className="p-grid p-field col-lg-12"> 
                      <div className="p-field col-lg-12"> 
                        <FieldDropdown  
                          name="ClaTipo" 
                          label={t('priceTracker:Type')}
                          value={ClaTipoFilter} 
                          options={TipoList}  
                          optionValue="ClaTipo"
                          optionLabel="NomTipo" 
                          onChange={(e) => onChangeTipo(e)}
                          errors={errors}
                          showClear={false}
                        />   
                      </div>
                    </div>  
                  </td>
                  <td width="33%">
                    <div className="p-grid p-field col-lg-12"> 
                      <div className="p-field col-lg-12"> 
                        <FieldDropdown  
                          name="ClaPrice" 
                          label={t('priceTracker:PriceFilter')}
                          value={ClaPriceFilter} 
                          options={TipoPrecioList}  
                          optionValue="ClaPrice"
                          optionLabel="NomPrice" 
                          onChange={(e) => onChangeTipoPrecio(e)}
                          errors={errors}
                          showClear={false}
                        />   
                      </div>
                    </div>  
                  </td>
                </tr>
              </table>
            </div>
          </Col> 
        </Row>
        <Row>
          <Col lg={6} md={6} sm={12} xs={12}>
            <div className='p-fluid p-2'>
              <table width="100%">
                <tr>
                  <td width="50%">
                    <div className="p-grid p-field col-lg-12"> 
                      <div className="p-field col-lg-12">
                        <FieldDropdown  
                          name="ClaZona" 
                          label={t('priceTracker:Zone')}
                          showClear={false}
                          value={ClaZonaFilter} 
                          options={ZonaList}  
                          optionValue="ClaZona"
                          optionLabel="NomZona" 
                          onChange={(e) => onChangeZona(e)}
                          errors={errors}
                        />  
                      </div> 
                    </div> 
                  </td>
                  <td width="50%">
                    <div className="p-grid p-field  col-lg-12"> 
                      <div className="p-field col-lg-12 ">
                        <FieldDropdown  
                          name="ClaState" 
                          label={t('priceTracker:State')}
                          showClear={false}
                          value={ClaStateFilter} 
                          options={StateList}  
                          optionValue="ClaState"
                          optionLabel="NomState" 
                          onChange={(e) => onChangeState(e)}
                          errors={errors}
                        />  
                      </div> 
                    </div> 
                  </td>
                </tr>
              </table>
            </div> 
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <div className='p-fluid p-2'>
              <table width="100%">
                <tr>
                  <td width="34%">
                    <div className="p-grid p-field col-lg-12"> 
                      <div className="p-field col-lg-12"> 
                        <FieldDropdown  
                          name="ClaSegmento" 
                          label={t('priceTracker:Segment')}
                          value={ClaSegmento} 
                          options={SegmentoList}  
                          optionValue="ClaSegmento"
                          optionLabel="NomSegmento" 
                          onChange={(e) => onChangeClaSegmento(e)}
                          errors={errors}
                          showClear={false}
                        />   
           
                      </div>
                    </div> 
                  </td>
                  <td width="33%">
                    &nbsp;
                  </td>
                  <td width="33%">
                     &nbsp;
                  </td>
                </tr>
              </table>
            </div>
          </Col> 
        </Row>
      </Panel>
    )
}
const onChangePeriodo = (e) => {   
  setLevelComparisonList([]);
  setClaPeriodoFilter(e.value);   
  const periodoNameList = [...PeriodoList];
  const periodoName = periodoNameList.filter((item) => item.ClaPeriodo === e.value)[0];
  setNomPeriodoFilter(periodoName.NomPeriodo);
  setPeriodoFilter(periodoName.ClaPeriodo);
  setNeedsToClicSearchButton(true);
}

const onChangePeriodoMultiSelect = (e) => {
  setLevelComparisonList([]);
  const newErrors = { ...errors };
  delete newErrors.ClaPeriodo;
  setErrors(newErrors);
  let periodo = "";
  let periodoName = "";
  if (e !== null) {
    for (let index = 0; index < e.length; index++) {
      const texto = e[index].label;
      const element = e[index].value;
      periodo = `${periodo + element},`;
      periodoName = `${periodoName + texto}-`
    } 
  }    
  setClaPeriodoFilter(e);   
  setClaPeriodoMultiFilter(periodo);  
  setNomPeriodoFilter(periodoName);
  console.log('onChangePeriodoMultiSelect',e);
  getZoneStateByDivision(periodo, ClaDivisionFilter);
  // setNomPeriodoFilter(periodoName.NomPeriodo);
  // setPeriodoFilter(periodoName.ClaPeriodo);
  setNeedsToClicSearchButton(true);
};

const onChangeDivision = (e) => {    
  setLevelComparisonList([]);
  const newErrors = {...errors};
  delete newErrors.ClaDivision;
  setErrors(newErrors);
  setClaDivisionFilter(e.value); 
  const divisionNameList = [...DivisionList];
    const divisionName = divisionNameList.filter((item) => item.NomDivision === e.value)[0];
    if(divisionName!==undefined){
      setNomDivisionFilter(divisionName.NomDivision);  
    }

  
  getZoneStateByDivision(ClaPeriodoMultiFilter, e.value);
  setNeedsToClicSearchButton(true);
}

const onChangeNivel = (e) => {  
  setLevelComparisonList([]);
  const newErrors = {...errors};
  delete newErrors.ClaNivel;
  setErrors(newErrors);  
  setClaNivelFilter(e.value);   
  const nivelNameList = [...NivelList];
    const nivelName = nivelNameList.filter((item) => item.NomNivel === e.value)[0];
    if(nivelName!==undefined){
      setNomNivelFilter(nivelName.NomNivel);
    }
    setNeedsToClicSearchButton(true);
}

const onChangeTipo = (e) => {   
  setLevelComparisonList([]); 
  setClaTipoFilter(e.value);  
  setNeedsToClicSearchButton(true); 
}

const openDialogDetail = (item) => {
  setNivelGrupo(item.NomNivel);
  setEsMostrarDialogDetail(true);
}

const onChangePriceTrackerDetail = (nomnivel, esPrecioNetoFilter) => {
  setNivelGrupo(nomnivel);
  setEsPrecioNetoFilter(esPrecioNetoFilter); 
  setClaPriceFilter(esPrecioNetoFilter?2:1);
  setEsMostrarDialogDetail(true);
}

const NomNivelTemplate = (item) => {
  return (
    <> 
      <span
        className='alink' 
        onClick={() =>openDialogDetail(item.data)}
      > {item.data.NomNivel}
      </span> 
    </>
    );
}; 

const TonsTemplate = (item) => {
  return (
    <> 
      <span className='text-right'>
        {Highcharts.numberFormat(item.data.Tons,0,'.',',')}
      </span>
    </>
    );
}; 
const MinPriceTemplate = (item) => {
  return (
    <> 
      <span className='text-right'>
        {Highcharts.numberFormat(item.data.MinPrice,0,'.',',')}
      </span>
    </>
    );
}; 
const MaxPriceTemplate = (item) => {
  return (
    <> 
      <span className='text-right'>
        {Highcharts.numberFormat(item.data.MaxPrice,0,'.',',')}
      </span>
    </>
    );
}; 
const AVGPriceTemplate = (item) => {
  return (
    <> 
      <span className='text-right'>
        {Highcharts.numberFormat(item.data.MixPrice,0,'.',',')}
      </span>
    </>
    );
}; 
const SpreadTemplate = (item) => {
  return (
    <> 
      <span className='text-right'>
        {Highcharts.numberFormat(item.data.Spread,0,'.',',')}
      </span>
    </>
    );
}; 
const SpreadGoalTemplate = (item) => {
  return (
    <> 
      <span className='text-right'>
        {Highcharts.numberFormat(item.data.SpreadGoal,0,'.',',')}
      </span>
    </>
    );
}; 

  return (
    <>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>  
          {filters()}
        </Col> 
      </Row> 
      {
          NeedsToClicSearchButton ?
          (<div className='pt-4'><InfoNeedsToBeRefresh onRefresh={getPriceTracker}></InfoNeedsToBeRefresh></div>)
          :
          (
            <>
              <Row>
                <Col><small className='info-color'>{t('priceTracker:DisclaimerInfo')}</small></Col>
              </Row>
              {
          LevelComparisonList.length>0 ? (
            <> 
              <div>
                {
                    ClaTipoFilter===1 ? (
                      <>
                        <small className='info-color'>{t('priceTracker:LeyendaDetailPriceTracker')}  {t('priceTracker:Tons')}</small>
                      </>
                    ):(
                      <>   
                        <small className='info-color'>{t('priceTracker:LeyendaDetailPriceTracker')}  {t('priceTracker:Units')}</small>
                      </>
                    )
                  }
              </div>
              <Row className="align-items-start">
                <Col lg={12} md={12} sm={12} xs={12}> 
                  <div className=' '>
                    <Row className="align-items-start">
                      <Col lg={12} md={12} sm={12} xs={12}>
                        {
                          optionsChartBubble !== null
                          &&
                          (
                            <HighchartsReact 
                              containerProps={{ style: { height: "530px" } }}
                              highcharts={Highcharts} 
                              options={optionsChartBubble}
                            />  
                          )
                        }
                              
                      </Col>  
                    </Row>
                    <Row>
                      <Col>&nbsp;</Col>
                    </Row>
                    <Row>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <DataGrid
                          dataSource={LevelComparisonList}
                          columnAutoWidth={true}
                          allowColumnReordering={true}
                          showBorders={true}
                          // scrolling={true}
                          // paging={false}
                          width="100%"
                          height={600}
                        >
                          <Scrolling columnRenderingMode="virtual" />
                          <Column
                            dataField="NomNivel"
                            caption={NomNivelFilter}
                            cellRender={NomNivelTemplate}
                            width={500}
                          />
                          <Column
                            dataField="Tons"
                            caption={t('priceTracker:Tons')}
                            width={200}
                            cellRender={TonsTemplate} 
                          /> 
                          <Column
                            dataField="MinPrice"
                            caption={t('priceTracker:MinPrice')}
                            width={200}
                            cellRender={MinPriceTemplate} 
                          /> 
                          <Column
                            dataField="MaxPrice"
                            caption={t('priceTracker:MaxPrice')}
                            width={200}
                            cellRender={MaxPriceTemplate} 
                          /> 
                          <Column
                            dataField="MixPrice"
                            caption={t('priceTracker:AVGPrice')}
                            width={200}
                            cellRender={AVGPriceTemplate} 
                          /> 
                          <Column
                            dataField="Spread"
                            caption={t('priceTracker:Spread')}
                            width={200}
                            cellRender={SpreadTemplate} 
                          /> 
                          <Column
                            dataField="SpreadGoal"
                            caption={t('priceTracker:SpreadGoal')}
                            width={200}
                            cellRender={SpreadGoalTemplate} 
                          /> 
                        </DataGrid>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </>
          ):(
            <>
            </>
          )
        }
              {
        EsMostrarDialogDetail && 
        (
          <PriceTrackerDetail
            Visible={EsMostrarDialogDetail}
            onHide={()=> setEsMostrarDialogDetail(false)}  
            onChangePriceTrackerDetail={onChangePriceTrackerDetail}
            NomPeriodo={NomPeriodoFilter} 
            ClaPeriodo={ClaPeriodoMultiFilter} 
            ClaDivision={NomDivisionFilter} 
            ClaNivel={ClaNivelFilter}
            ClaNivelProducto={ClaNivelProducto}
            NivelProducto={NivelGrupo}
            ClaTipo={ClaTipoFilter}
            NomNivel={NomNivelFilter}
            EsPrecioNeto={EsPrecioNetoFilter}
            ClaZonaFilter={ClaZonaFilter}
            ClaStateFilter={ClaStateFilter}
          >
          </PriceTrackerDetail>
         )
      }
            </>
          )
} 
    </>
  );
};
export default PriceTracker;

import React, { useState, useEffect, useRef } from 'react';
import { callApi, showSweetAlert, formatDate } from '../../../utils/utils';
import { config } from '../../../utils/config';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import { Column } from 'primereact/column';
import { useTranslation } from 'react-i18next';

const ViewEmployeeItAssets = (props) => {
  const {t, i18n} = useTranslation(['it', 'common']);
  const [assets, setAssets] = useState([]);

  useEffect(() => {
    GetAssets(props.filters);
  }, [props.filters])

  const GetAssets = (filters) => {
    const urlWebService = `${config.UrlApiProject}IT/GetITAssets`;
    const paramsService = {};

    if (filters !== undefined && filters !== null) {
      paramsService.IdITEquipo = filters.IdITEquipo;
      paramsService.ClaUsuarioAsignado = filters.ClaUsuarioAsignado;
      paramsService.ClaUbicacion = filters.ClaUbicacion;
      paramsService.AssignedTo = filters.AssignedTo;
      paramsService.ClaADPEmployee = filters.ClaADPEmployee;
    }

    console.log(paramsService);

    callApi(urlWebService, 'POST', paramsService, (response) => {
      setAssets(response.data.assets);
    });
  }

  return (
    <>
      <FieldDataTable
        value={assets}
        scrollable
      >
        <Column header={t('it:ID')} sortable field='IdITEquipo' headerStyle={{ width: '60px' }} />
        <Column header={t('it:Equipment')} sortable field='NomTipoEquipo' headerStyle={{ width: '155px' }} />
        <Column header={t('it:Brand')} sortable field='NomMarcaEquipo' headerStyle={{ width: '125px' }} />
        <Column header={t('it:SerialNumber')} sortable field='SerialNumber' headerStyle={{ width: '145px' }} />
        <Column header={t('it:Model')} sortable field='ModeloEquipo' headerStyle={{ width: '200px' }} />
        <Column header={t('it:Location')} sortable field='Location' headerStyle={{ width: '150px' }} />
        <Column header={t('it:AssignedTo')} sortable field='AssignedTo' headerStyle={{ width: '135px' }} />
        <Column header={t('it:Employee')} sortable field='Employee' headerStyle={{ width: '275px' }} />
        <Column header={t('it:User')} sortable field='AssignedUser' />
      </FieldDataTable>
    </>
  )
}

export default ViewEmployeeItAssets;
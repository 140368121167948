/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PageContent from '../../../layouts/PageContent';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Panel } from 'primereact/panel';
import queryString from 'query-string';
import FileLibraryFilter from './FileLibraryFilter';
import FileLibraryUpLoadEdit from './FileLibraryUpLoadEdit';
import FileLibraryList from './FileLibraryList';

const FileLibraryScreen = (props) => {
  /* ################## useState area start ################## */  
  const [EsShowDialog, setEsShowDialog] = useState(false);
  const [ClaLibrary, setClaLibrary] = useState(null);
  const [IdLibraryFile, setIdLibraryFile] = useState(null);  
  const [refresh, setrefresh] = useState(0);
  const [filtersData, setfiltersData] = useState({});
  const { t } = useTranslation(['dms', 'common']);

  useEffect(() => {}, []);
 
  const onAfterReturn = () => {
    setrefresh(refresh + 1);
  };

  const onSearch = (filters) => {
    setfiltersData({ ...filters });
  };

  return (
    <>
      <PageContent title={t('dms:FileLibraryScreenTitle')}>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <FileLibraryFilter
              onSearch={onSearch}      
              refresh={refresh}    
            >
            </FileLibraryFilter>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            &nbsp;
          </Col>
        </Row> 
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <FileLibraryList 
              refresh={refresh}
              filtersData={filtersData}
              EsEditar={false}
            >
            </FileLibraryList>
          </Col>
        </Row> 
      </PageContent>
    </>
  );
};

export default FileLibraryScreen;

/* eslint-disable radix */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';  
import PageContent from 'layouts/PageContent';
import { Row,Col, TabContent, TabPane , Nav, NavItem, NavLink, Card, CardBody} from 'reactstrap'; 
import { TabView, TabPanel } from 'primereact/tabview';
import SurveyInsights from './SurveyInsights.jsx';
import SurveyIndividualResponses from '../SurveyManagement/SurveyIndividualResponses.jsx'; 
import { config } from 'utils/config';
import { callApi } from 'utils/utils';
import FieldButton from '../../../../components/Controls/FieldButton/FieldButton.jsx'; 
import SurveySummaryQuestion from './SurveySummaryQuestion.jsx';
import SurveySummary from './SurveySummary.jsx'; 
import { Route, Redirect, Link, useHistory } from 'react-router-dom';

const SurveyAnalyzeScreen = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['survey', 'common']);
  const [activeIndex, setActiveIndex] = useState(0); 
  const [Survey, setSurvey] = useState({});
  const [TabActiveIndex, setTabActiveIndex] = useState(1);

  const { IdEncuesta } = props.match.params
  const history = useHistory(); 
 
  useEffect(() => {   
    getHRSurveyManagementInfo(IdEncuesta);
  }, []); 

  const getHRSurveyManagementInfo = () => {
    const GetHRSurveyManagementInfo = `${config.UrlApiProject}HR/GetHRSurveyManagementInfo`;
    const paramsToService = {
      IdEncuesta,
    };
    callApi(GetHRSurveyManagementInfo, 'POST', paramsToService, (response) => {  
      setSurvey(response.data.Encuesta[0]); 
    });
  };

  const switchTab = tab => {
    if(TabActiveIndex !== tab){
      setTabActiveIndex(tab);
    }
  }

  const onReturnSurveyManagementList = () => { 
    history.push({
      pathname: '../SurveyManagement'
    });
  };
   
  return (
    <>
      <PageContent title={t('survey:SurveyAnalyze')}>
        <Row>
          <Col lg={8} md={8} sm={12} xs={12}>
            <span className='h1'>{Survey.Titulo}</span>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className='text-right'>
            <FieldButton
              label={t('common:Return')}
              icon="pi pi-arrow-left" 
              className="p-button-secondary"
              onClick={() => onReturnSurveyManagementList()}
            />
          </Col>
        </Row> 
      </PageContent>
      <Row>
        <Col>
                &nbsp;
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>    
          <div className="p-tabview p-component pb-3">
            <Nav className="p-tabview-nav" style={{justifyContent: 'center'}}> 
              <NavItem className={TabActiveIndex === 1 ? "p-tabview-selected p-highlight p-unselectable-text nav-item " : "p-unselectable-text nav-item "}>
                <NavLink className="p-tabview-nav-link" onClick={() => { switchTab(1); }}>
                  {t('survey:QuestionSummaries')}
                </NavLink>
              </NavItem> 
              <NavItem className={TabActiveIndex === 2 ? "p-tabview-selected p-highlight p-unselectable-text nav-item " : "p-unselectable-text nav-item "}>
                <NavLink className="p-tabview-nav-link" onClick={() => { switchTab(2); }}>
                  {t('survey:SurveyInsights')}
                </NavLink>
              </NavItem>
              <NavItem className={TabActiveIndex === 3 ? "p-tabview-selected p-highlight p-unselectable-text nav-item " : "p-unselectable-text nav-item "}>
                <NavLink className="p-tabview-nav-link" onClick={() => { switchTab(3); }}>
                  {t('survey:IndividualResponses')}
                </NavLink>
              </NavItem>
            </Nav>
          </div>  
        </Col>
      </Row> 
      <div className='content'>
        <Row>
          <Col>
            <TabContent className="p-tabview-panels" activeTab={TabActiveIndex}>
              <TabPane tabId={1}>   
                <Row>
                  <Col lg={12}>
                    {TabActiveIndex === 1 ? <SurveySummary IdEncuesta={IdEncuesta}></SurveySummary> : <></> }
                  </Col>
                </Row> 
              </TabPane>
              <TabPane tabId={2}>
             
                <Row>
                  <Col lg={12}> 
                    {TabActiveIndex === 2 ? <SurveyInsights IdEncuesta={IdEncuesta}></SurveyInsights> : <></> } 
                  </Col>
                </Row>   
              </TabPane>
              <TabPane tabId={3}>
                <Row>
                  <Col> 
                    {TabActiveIndex === 3 ? <SurveyIndividualResponses IdEncuesta={IdEncuesta}></SurveyIndividualResponses> : <></> }
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SurveyAnalyzeScreen;

import React, { useState, useRef, useEffect, useContext  } from 'react'; 
import { Row,Col,Card, CardHeader, CardTitle, CardBody, CardFooter} from 'reactstrap'; 
import { AppContext } from 'context/AppContext'; 
import { config } from '../../../utils/config';
import { useTranslation } from 'react-i18next'; 
import PageContent from 'layouts/PageContent';   
import moment from 'moment';
import { callApi, showSweetAlert} from '../../../utils/utils'; 
import FieldButton from '../../../components/Controls/FieldButton/FieldButton';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import notassigned from '../../../assets/img/icons/notassigned.png'
import assigned from '../../../assets/img/icons/assigned.png'
import done from '../../../assets/img/icons/done.png'
import MTDPerformanceReportDetail from './MTDPerformanceReportDetail';
import { Dialog } from 'primereact/dialog';

const MTDPerformanceReportBacklog = (props) => {  
  const { t } = useTranslation(['mtd', 'common']);
  const { viewportRefresh } = useContext(AppContext);
  const [Viewport, setViewport] = useState(viewportRefresh);
  const [workOrdersByDeptCategories, setworkOrdersByDeptCategories] = useState([]);
  const [workOrdersByDeptSeries, setworkOrdersByDeptSeries] = useState([]);
  const [workOrdersAgingCategories, setworkOrdersAgingCategories] = useState([]);
  const [workOrdersAgingSeries, setworkOrdersAgingSeries] = useState([]);
  const [assignedTechBarChartCategories, setassignedTechBarChartCategories] = useState([]);
  const [assignedTechBarChartSeries, setassignedTechBarChartSeries] = useState([]);
  const [techTypeChartCategories, settechTypeChartCategories] = useState([]);
  const [techTypeChartSeries, settechTypeChartSeries] = useState([]);  
  const [EsMostrarDialogDetail, setEsMostrarDialogDetail] = useState(false);
  const [ClaEdificio, setClaEdificio] = useState(null);
  const [ClaAssignedTech, setClaAssignedTech] = useState(null);
  const [ClaAging, setClaAging] = useState(null);
  const [ClaTechType, setClaTechType] = useState(null);
  const [ClaEstatusOt, setClaEstatusOt] = useState(null); 
  const [TitleDetail, setTitleDetail] = useState(null); 
  const [SubTitleDetail, setSubTitleDetail] = useState(null); 
  const [IsOpenWorkOrders, setIsOpenWorkOrders] = useState(false); 
  const [IsOverdueWorkOrders, setIsOverdueWorkOrders] = useState(false); 
  const [IsOverdueWorkOrdersTotal, setIsOverdueWorkOrdersTotal] = useState(false); 
  const [IsOntimeWorkOrders, setIsOntimeWorkOrders] = useState(false); 
  const [IsOntimeWorkOrdersTotal, setIsOntimeWorkOrdersTotal] = useState(false); 
  const [IsWorkOrders, setIsWorkOrders] = useState(false); 
  const [IsCurrentWeekWorkOrders, setIsCurrentWeekWorkOrders] = useState(false); 

  useEffect(() => {
    GetChartLoad();
  }, []);
  
  useEffect(() => {    
    setViewport(viewportRefresh); 
  }, [viewportRefresh]);

  const GetChartLoad = () => { 
    let sBuilding = '';
    let cBuilding = 0;
    const buildingList = props.filterBuildingList;
    const assignedTechList = props.filterTecAssignedList;
    const techTypeList = props.filterTecTypeList;
    for (let index = 0; index < buildingList.length; index++) {
      if(buildingList[index].EsSeleccionado){
        const element = buildingList[index].ClaEdificio;
        sBuilding = `${sBuilding + element},`;
        cBuilding +=1;
      }
    } 
    let sAssignedTech = '';
    for (let index = 0; index < assignedTechList.length; index++) {
      if(assignedTechList[index].EsSeleccionado){
        const element = assignedTechList[index].ClaTechAssigned;
        sAssignedTech = `${sAssignedTech + element},`;
      }
    } 
    let sTechType = '';
    for (let index = 0; index < techTypeList.length; index++) {
      if(techTypeList[index].EsSeleccionado){
        const element = techTypeList[index].ClaTechType;
        sTechType = `${sTechType + element},`;
      }
    }
    const paramsToService = {
      Building:sBuilding,
      AssignedTech:sAssignedTech,
      TechType:sTechType,
      RangeDateStart:  props.StartDate,
      RangeDateEnd:  props.EndDate
    }
    const url = `${config.UrlApiProject}MMA/GetMDTPerformanceReportBacklog`;
    callApi(url, 'POST', paramsToService, (response) => {
      console.log('response',response)
      const series = response.data.DepartamentSeries;
      const categories = response.data.DepartamentCategories;
      const seriesPerson = response.data.PersonSeries;
      const categoriesPerson = response.data.PersonCategories;
      const seriesTypeTech = response.data.TypeTechSeries;
      const categoriesTypeTech = response.data.TypeTechCategories;
      const seriesAging = response.data.AgignSeries;
      const categoriesAging = response.data.AgignCategories;
      getworkOrdersByDeptSeries(series,categories);
      getworkOrdersAgingSeries(seriesAging,categoriesAging);
      getassignedTechSeries(seriesPerson,categoriesPerson);
      gettypeTechSeries(seriesTypeTech,categoriesTypeTech);
    });   
  }
  
  
  const workOrdersByDeptBarChartOptions = {
    chart: {
      type: 'bar',
    },
    title: {
      text: t('mtd:WorkordersByDept'),
      align: 'center'
    },
    subtitle: {
      text: ""
    },
    xAxis: {
      categories: workOrdersByDeptCategories,
      title: {
        text: null
      },
      gridLineWidth: 1,
      lineWidth: 0
    },
    yAxis: {
      min: 0,
      title: {
        text: t('mtd:WorkOrders')
      },
      gridLineWidth: 1,
      lineWidth: 0,
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color: 'blue',
          textOutline: 'none'
        }
      }               
    },
    tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: <b>{point.y}<b/>'
    },
    
    plotOptions: {
      bar: {          
        dataLabels: {
          enabled: true
        }
      },
      series: {
          point: {
          events: {
            click(e){
              const seriesName = e.point.series.name;
              console.log('seriesName',seriesName);
              console.log('e',e);
              console.log('series',e.point.series);
              console.log('category',e.point.category);
              console.log('value',e.point.y);

              let dataSerie = {};
              let ClaDepto = null;
              workOrdersByDeptSeries.forEach((item, index) => {
                if (item.name === seriesName) {
                  dataSerie = item;
                }
              });
              const infolist = dataSerie.info
              infolist.forEach((item, index) => {
                  if (item.NomDepartamento === e.point.category) {
                    ClaDepto = item.ClaDepartamento;
                    setSubTitleDetail(item.NomDepartamento);
                  }
                }); 
              setClaEdificio(ClaDepto);
              setClaEstatusOt(dataSerie.id);
              setClaAssignedTech(null);
              setClaAging(null);
              setClaTechType(null);
              setTitleDetail(t('mtd:WorkordersByDept'));
              setIsOpenWorkOrders(false);
              setIsOverdueWorkOrders(false);
              setIsOverdueWorkOrdersTotal(false);
              setIsOntimeWorkOrders(false);
              setIsOntimeWorkOrdersTotal(false);
              setEsMostrarDialogDetail(true);
            }
          }
        }
      }
    },   
    series: workOrdersByDeptSeries
  };

  const workOrdersAgingChartOptions = {
    chart: {
      type: 'column',
    },
    title: {
      text: t('mtd:WorkOrderAging'),
      align: 'center'
    },
    subtitle: {
      text: ""
    },
    xAxis: {
      categories: workOrdersAgingCategories,
      crosshair: true,
      accessibility: {
          description: 'Countries'
      }
    },
    yAxis: {
      min: 0,
      title: {
          text: t('mtd:WorkOrders')
      }
    },
    tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: <b>{point.y}<b/>'
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        dataLabels: {
            enabled: true
        },
        point: {
          events: {
            click(e){
              const seriesName = e.point.series.name;
              let dataSerie = {};
              let ClaAging = null;
              workOrdersAgingSeries.forEach((item, index) => {
                if (item.name === seriesName) {
                  dataSerie = item;
                }
              });
              const infolist = dataSerie.info
              infolist.forEach((item, index) => {
                  if (item.NomAging === e.point.category) {
                    ClaAging = item.ClaAging;
                    setSubTitleDetail(item.NomAging);
                  }
                });
              setTitleDetail(t('mtd:WorkOrderAging'));
              setClaEdificio(null);
              setClaTechType(null);
              setClaEstatusOt(dataSerie.id);
              setClaAssignedTech(null);
              setClaAging(ClaAging);
              setIsOpenWorkOrders(false);       
              setIsOverdueWorkOrders(false);
              setIsOverdueWorkOrdersTotal(false);
              setIsOntimeWorkOrders(false);
              setIsOntimeWorkOrdersTotal(false);
              setEsMostrarDialogDetail(true);
            }
          }
        }
      }
    },
    series: workOrdersAgingSeries
  };

  const assignedTechBarChartOptions = {
    chart: {
      type: 'column'
    },
    title: {
      text: "",
      align: 'center'
    },
    subtitle: {
      text: ""
    },
    xAxis: {
      categories: assignedTechBarChartCategories
    },
    yAxis: {
      min: 0,
      title: {
        text: t('mtd:WorkOrders')
      }, 
      gridLineWidth: 1,
      lineWidth: 0,
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color: 'blue',
          textOutline: 'none'
        }
      }               
    },
    tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: <b>{point.y}<b/>'
    },
    legend: {
      enabled: true
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
            enabled: true
        },
        point: {
          events: {
            click(e){
              const seriesName = e.point.series.name;
              console.log('seriesName',seriesName);
              console.log('e',e);
              console.log('series',e.point.series);
              console.log('category',e.point.category);
              console.log('value',e.point.y);
  
              let dataSerie = {};
              let ClaResponsable = null;
              assignedTechBarChartSeries.forEach((item, index) => {
                if (item.name === seriesName) {
                  dataSerie = item;
                }
              });
              const infolist = dataSerie.info
              infolist.forEach((item, index) => {
                  if (item.NomResponsable === e.point.category) {
                    ClaResponsable = item.ClaResponsable;
                    setSubTitleDetail(item.NomResponsable);
                  }
                });
              console.log('info',dataSerie);
              setClaEdificio(null);
              setClaTechType(null);
              setClaEstatusOt(dataSerie.id);
              setClaAssignedTech(ClaResponsable);
              setClaAging(null);
              setTitleDetail(t('mtd:AssignedTech'));
              setIsOpenWorkOrders(false);       
              setIsOverdueWorkOrders(false);
              setIsOverdueWorkOrdersTotal(false);
              setIsOntimeWorkOrders(false);
              setIsOntimeWorkOrdersTotal(false);
              setEsMostrarDialogDetail(true);
            }
          }
        }
      }
    },
    series: assignedTechBarChartSeries
  };

  const techTypeBarChartOptions = {
    chart: {
      type: 'column'
    },
    title: {
      text: "",
      align: 'center'
    },
    subtitle: {
      text: ""
    },
    legend: {
      enabled: true
    },
    xAxis: {
      categories: techTypeChartCategories,
      title: {
        text: null
      },
      gridLineWidth: 1,
      lineWidth: 0
    },
    yAxis: {
      min: 0,
      title: {
        text: t('mtd:WorkOrders')
      },
      gridLineWidth: 1,
      lineWidth: 0,
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color: 'blue',
          textOutline: 'none'
        }
      }               
    },
    tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: <b>{point.y}<b/>'
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
            enabled: true
        },
        point: {
          events: {
            click(e){
              const seriesName = e.point.series.name;
              console.log('seriesName',seriesName);
              console.log('e',e);
              console.log('series',e.point.series);
              console.log('category',e.point.category);
              console.log('value',e.point.y);
  
              let dataSerie = {};
              let ClaDisciplina = null;
              techTypeChartSeries.forEach((item, index) => {
                if (item.name === seriesName) {
                  dataSerie = item;
                }
              });
              const infolist = dataSerie.info
              infolist.forEach((item, index) => {
                  if (item.NomDisciplina === e.point.category) {
                    ClaDisciplina = item.ClaDisciplina;
                    setSubTitleDetail(item.NomDisciplina);
                  }
                });
              console.log('info',dataSerie);
              setClaEdificio(null);
              setClaTechType(ClaDisciplina);
              setClaEstatusOt(dataSerie.id);
              setClaAssignedTech(null);
              setClaAging(null);
              setTitleDetail(t('mtd:TechType'));
              setIsOpenWorkOrders(false);
              setIsOverdueWorkOrders(false);
              setIsOverdueWorkOrdersTotal(false);
              setIsOntimeWorkOrders(false);
              setIsOntimeWorkOrdersTotal(false);
              setEsMostrarDialogDetail(true);
            }
          }
        }
      }
    },
    series: techTypeChartSeries
  };
  const getworkOrdersByDeptSeries = (serieInfo,categoriesInfo) => {
    setworkOrdersByDeptSeries(serieInfo);
    setworkOrdersByDeptCategories(categoriesInfo); 
    // setWoByDeptChartCategories(["Bulk", "Paper Tape", "Collated", "Spare Equipment"]);    
  };
  const getworkOrdersAgingSeries = (serieInfo,categoriesInfo) => {
    setworkOrdersAgingSeries(serieInfo);
    setworkOrdersAgingCategories(categoriesInfo); 
    // setWoByDeptChartCategories(["Bulk", "Paper Tape", "Collated", "Spare Equipment"]);    
  };
  const getassignedTechSeries = (serieInfo,categoriesInfo) => {
    setassignedTechBarChartSeries(serieInfo);
    setassignedTechBarChartCategories(categoriesInfo); 
  };

  const gettypeTechSeries = (serieInfo,categoriesInfo) => {
    settechTypeChartSeries(serieInfo);
    settechTypeChartCategories(categoriesInfo); 
  };


  const chartOrdersTemplate = (
    <> 
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <HighchartsReact 
            containerProps={{ style: { height: "280px" } }}
            highcharts={Highcharts} 
            options={workOrdersByDeptBarChartOptions}
          />         
        </Col>
        <Col lg={12} md={12} sm={12} xs={12}>
          <HighchartsReact 
            containerProps={{ style: { height: "280px" } }}
            highcharts={Highcharts}
            options={workOrdersAgingChartOptions}
          />         
        </Col>
        <Col lg={12} md={12} sm={12} xs={12}>
          <Card className='card-detail'>
            <CardHeader className='hard-gray'> {t('mtd:AssignedTech')} </CardHeader>
            <CardBody>
              <HighchartsReact 
                containerProps={{ style: { height: "160px" } }}
                highcharts={Highcharts} 
                options={assignedTechBarChartOptions}
              />        
            </CardBody>
          </Card>
        </Col>
        <Col lg={12} md={12} sm={12} xs={12}>
          <Card className='card-detail'>
            <CardHeader className='hard-gray'> {t('mtd:TechType')} </CardHeader>
            <CardBody>
              <HighchartsReact 
                containerProps={{ style: { height: "160px" } }}
                highcharts={Highcharts} 
                options={techTypeBarChartOptions}
              />        
            </CardBody>
          </Card>
        </Col>
        <Col lg={12} md={12} sm={12} xs={12}>
           
        </Col>
      </Row>    
    </>
  );

  const onHideDialogDetail = () => {
    setEsMostrarDialogDetail(false);
  }

  return (
    <>   
      <div>
        <Row>
          <Col>
            {chartOrdersTemplate}
            <Dialog
              visible={EsMostrarDialogDetail}
              onHide={() => onHideDialogDetail()}  
              style={{width: '50vw'}}
              header={`${TitleDetail  }-${  SubTitleDetail}`}
            >
              <MTDPerformanceReportDetail
                filterBuildingList={props.filterBuildingList}
                filterTecAssignedList={props.filterTecAssignedList}
                filterTecTypeList={props.filterTecTypeList}
                StartDate={null}
                EndDate={null}
                ClaEdificio={ClaEdificio}
                ClaAssignedTech={ClaAssignedTech}
                ClaTechType={ClaTechType}
                ClaAging={ClaAging}
                ClaEstatusOt={ClaEstatusOt}
                Title={TitleDetail}
                SubTitle={SubTitleDetail}
                IsOpenWorkOrders={IsOpenWorkOrders}
                IsOverdueWorkOrders={IsOverdueWorkOrders}
                IsOverdueWorkOrdersTotal={IsOverdueWorkOrdersTotal}
                IsOntimeWorkOrders={IsOntimeWorkOrders}
                IsOntimeWorkOrdersTotal={IsOntimeWorkOrdersTotal}
                IsWorkOrders={IsWorkOrders}
                IsCurrentWeekWorkOrders={IsCurrentWeekWorkOrders}
                IsBackLog={true}
              >
              </MTDPerformanceReportDetail>
            </Dialog>  
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MTDPerformanceReportBacklog;

/* eslint-disable object-shorthand */
import React, { useState, useRef, useEffect  } from 'react'; 
import { useTranslation } from 'react-i18next';   
import { config } from '../../../utils/config';
import { callApi, showSweetAlert} from '../../../utils/utils';   
import PageContent from 'layouts/PageContent';   
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { Row,Col,Card, CardHeader, CardTitle, CardBody, CardFooter} from 'reactstrap';   
import FieldDataView from 'components/Controls/FieldDataView/FieldDataView.jsx';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable.jsx';
import FieldDropdown from 'components/Controls/FieldDropdown.jsx' 
import FieldButton from 'components/Controls/FieldButton/FieldButton.jsx';
import FieldTextArea  from 'components/Controls/FieldTextArea.jsx'
import FieldCalendar from 'components/Controls/FieldCalendar.jsx'
import './ProductionDailyFollowUPScreen.scss';  
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import ProductionWeeklyFollowUPComentarios from './ProductionWeeklyFollowUPComentarios';
import { Skeleton } from 'primereact/skeleton';
import FieldSelectButton from 'components/Controls/FieldSelectButton';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsHeatmap from "highcharts/modules/heatmap";

HighchartsHeatmap(Highcharts);

const ProductionWeeklyFollowUPZona = (props) => { 
  const { t } = useTranslation(['wtw', 'common']);
  const [errors, setErrors] = useState({});   
  const [CeldasList, setCeldasList] = useState([]);
  const [ClaAnioMes, setClaAnioMes] = useState(props.ClaAnioMes);
  const [IdProduccionSeguimientoSemanal, setIdProduccionSeguimientoSemanal] = useState(null); 
  const [ClaUbicacion, setClaUbicacion] = useState(props.ClaUbicacion);
  const [ClaSemana, setClaSemana] = useState(props.ClaSemana); 
  const [ClaDepartamento, setClaDepartamento] = useState(props.ClaDepartamento);
  const [ClaArea, setClaArea] = useState(props.ClaArea);
  const [NomArea, setNomArea] = useState(props.NomArea);
  const [ClaZona, setClaZona] = useState(props.ClaZona);  
  const [ExplicacionProblema, setExplicacionProblema] = useState(null);    
  const [EsApply, setEsApply] = useState(false); 
  const [EsMostrarDialogComentarios, setEsMostrarDialogComentarios] = useState(false); 
  const [TurnoList, setTurnoList] = useState([]);
  const [ClaTurno, setClaTurno] = useState([1, 2, 3]);
  const [EsCargandoListado, setEsCargandoListado] = useState(false);
  const [utilizationXCategories, setUtilizationXCategories] = useState([]);
  const [utilizationYCategories, setUtilizationYCategories] = useState([]);
  const [utilizationSeries, setUtilizationSeries] = useState([]);
  const [utilizationXCategoriesFecha, setUtilizationXCategoriesFecha] = useState([]);
  const [utilizationYCategoriesClaCelda, setUtilizationYCategoriesClaCelda] = useState([]);

  useEffect(() => {
    getZonas();
    GetTurno();
  }, [])

  const GetTurno = () => {
    const paramsService = {};
    const urlWebService = `${config.UrlApiProject}Operations/GetTurno`;
    callApi(urlWebService, 'GET', paramsService, (response) => {
      setTurnoList(response.data.Turnos);
    });
  };

  const getZonas = () => {  
    setExplicacionProblema(null);
    setCeldasList([]);
    const urlWebService = `${config.UrlApiProject}WTW/GetProduccionSeguimientoSemanalCelda`; 
     const paramsService = {
        ClaUbicacion,
        ClaAnioMes,
        ClaSemana,
        ClaDepartamento,
        ClaArea,
        ClaZona,
        ClaTurnos: ClaTurno.join(',')
      }; 
    callApi(urlWebService, 'POST', paramsService, (response) => {
      const data = response.data.ProduccionSeguimientoZona[0];
      
      setIdProduccionSeguimientoSemanal(null);
        if(data!==undefined){
          setIdProduccionSeguimientoSemanal(data.IdProduccionSeguimientoSemanal);
          setExplicacionProblema(data.ExplicacionProblema);
        }
      setCeldasList(response.data.CeldasList);
      setUtilizationSeries(response.data.UtilizationSeries);
      setUtilizationYCategories(response.data.yAxisCategories);
      setUtilizationXCategories(response.data.xAxisCategories); 
      setUtilizationYCategoriesClaCelda(response.data.arregloClaCelda); 
      setUtilizationXCategoriesFecha(response.data.arregloFecha); 
    }, null, false, false);
  }

  const onChangeExplicacionProblema = (e) => {  
    const newErrors = { ...errors }
    delete newErrors.ExplicacionProblema;   
    setErrors(newErrors);
    setExplicacionProblema(e.target.value);
  } 

  const valitationSchemeSave = yup.object().shape({
    ExplicacionProblema: yup.string().trim().required(t('common:RequiredField')).nullable()
  }); 

  const onSave = async () => {  
    const value = {
     ExplicacionProblema
    }
    const validator = new YupValidator(valitationSchemeSave);
    const esValido = await validator.validate(value);
    if (!esValido){ 
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
      return;
    }
    const urlWebService = `${config.UrlApiProject}WTW/SaveProduccionSeguimientoSemanal`; 
    const paramsService = {
      IdProduccionSeguimientoSemanal,
      ClaUbicacion:props.ClaUbicacion,
      ClaAnioMes:props.ClaAnioMes,
      ClaSemana:props.ClaSemana,
      ClaDepartamento:props.ClaDepartamento,
      ClaArea: props.ClaArea,
      ClaZona:props.ClaZona,
      ExplicacionProblema
    }; 
    callApi(urlWebService, 'POST', paramsService, (response) => {
    showSweetAlert(t('common:Exito'), t('common:SaveSuccess'), 'success');
    setEsApply(true);
    onHideDialog();
    }, () => {
  });
  };

  const onViewComments = async () => {  
    setEsMostrarDialogComentarios(true);
  };

const onHideDialog = () => { 
  if (props.onHide !== null && typeof props.onHide === 'function') {
    props.onHide();
  }
};

  const utilizationHeatMap = {
    chart: {
        type: 'heatmap',
        marginTop: 65,
        marginBottom: 80,
        plotBorderWidth: 1,
        height: 'auto'
    }, 
    
    title: {
        text: t('productionDashboard:chartUtilizationTitle') 
    },
    subtitle: {
      text: props.NomSemana
    },
    xAxis: { 
        categories: utilizationXCategories
    },

    yAxis: { 
        title:"",
        categories: utilizationYCategories,
        reversed: true
    },

    tooltip: {
      formatter: function() {  
        const { x, y, point, series } = this; 
        return `<b>${t('productionDashboard:chartUtilizationTitle') }</b> <br/> <b>${t('formulario:Cell')}:</b> ${  series.yAxis.categories[y]  }</b><br/><b>${t('formulario:Day')}:</b> ${  series.xAxis.categories[point.x]  }   <br/><b>${t('formulario:Value')}:</b>  ${Highcharts.numberFormat(point.value,1)  }`;
      }
    }, 
    legend: {
        align: 'right',
        layout: 'vertical',
        margin: 0,
        verticalAlign: 'top',
        y: 25,
        symbolHeight: 280
    }, 

    series: [{
        name: t('productionDashboard:chartUtilizationTitle') ,
        borderWidth: 1,
        color: '#e4e4e4', 
        data: utilizationSeries,
        showInLegend:false,
        dataLabels: {
            enabled: true,
            color: '#FFFFFF'
        } 
    }],
  }

const templateHeaderDialog = (
  <> 
    <Row className='PriceTrackerScreen align-items-start'>
      <Col lg={10} md={10} sm={10} xs={10}>
        <div className='p-fluid'>
          <table>
            <tr style={{borderBottom: '1px dashed #cccccc'}}>
              <td style={{width: '120px'}}>
                {t('wtw:Plant')}   
              </td>
              <td style={{width: '200px'}} className='gray'>{props.NomUbicacion} </td>
              <td style={{width: '120px'}}>
                {t('wtw:Zone')}
              </td>
              <td style={{width: '300px'}} className='gray'>{props.NomZona}  </td>
            </tr>
            <tr>
              <td>
                {t('wtw:Departament')}    
              </td>
              <td className='gray'>{props.NomDepartamento} ({props.NomArea})</td>
              <td>
              
              </td>
              <td className='gray'>{props.NomSemana}  </td>
            </tr>
          </table> 
        </div> 
      </Col>
    </Row>   
  </>
  );

  const templateFooterDialog = (
    <> 
      
    </>
   );


   const CajasTemplate = (item, columnName, decimals) => {
  
   
  
    return (
      <> 
        <span>{Highcharts.numberFormat(item[columnName],decimals, '.', ',')} </span>
      </>
      );
  };  
  
  const DecimalTemplate = (item, columnName, decimals) => {
    
    return (
      <> 
        <span>{Highcharts.numberFormat(item[columnName],decimals, '.', ',')}</span>
      </>
      );
  };  
  
  const PorcentajeTemplate = (item, columnName) => {
    
    return (
      <> 
        <span>{Highcharts.numberFormat(item[columnName],2, '.', ',')}%</span>
      </>
      );
  };  
  
    const rowClass = (data) => {
      return {
          'gray': data.ClaZona === -1
      }
    }
 
    const onRefreshCeldasSummary = (ClaTurnos) => {  
      
      setCeldasList([]);
      const urlWebService = `${config.UrlApiProject}WTW/GetProduccionSeguimientoSemanalCelda`; 
       const paramsService = {
          ClaUbicacion,
          ClaAnioMes,
          ClaSemana,
          ClaDepartamento,
          ClaArea,
          ClaZona,
          ClaTurnos: ClaTurnos.join(',')
        }; 
        setEsCargandoListado(true);
      callApi(urlWebService, 'POST', paramsService, (response) => { 
        setCeldasList(response.data.CeldasList);
        setEsCargandoListado(false);
        setUtilizationSeries(response.data.UtilizationSeries);
        setUtilizationYCategories(response.data.yAxisCategories);
        setUtilizationXCategories(response.data.xAxisCategories); 
        setUtilizationYCategoriesClaCelda(response.data.arregloClaCelda); 
        setUtilizationXCategoriesFecha(response.data.arregloFecha); 
      }, null, false, false);
    }
  

  const onClaTurnoClick = (pClaTurno) => {  
    // no se puede desmarcar el ultimo...
    if (pClaTurno.length === 0){
      return; 
    }

    setClaTurno(pClaTurno);  
    onRefreshCeldasSummary(pClaTurno);
  };

  const skeletonTemplate = () =>{
    return (<Skeleton height="1rem" width="100%" className="mb-2"></Skeleton>);
  }  

  const ListadoCeldas = () => {
    if (EsCargandoListado){
      return (
        <> 
          <FieldDataTable 
            value={[{},{},{},{}]}
            paginator={false} 
          >     
            <Column
              body={skeletonTemplate}
              header={t('wtw:Cell')}  
            >
            </Column>     
            <Column header={t('wtw:Boxes')} className='text-right' headerStyle={{ width: '100px' }} body={skeletonTemplate}></Column>
            <Column header={t('wtw:Tons')} className='text-right' headerStyle={{ width: '100px' }} body={skeletonTemplate}></Column>
            <Column header={t('wtw:Scrap')} className='text-right' headerStyle={{ width: '80px' }} body={skeletonTemplate}></Column> 
            <Column header={t('wtw:Utilizacion')} className='text-right' headerStyle={{ width: '80px' }} body={skeletonTemplate}></Column> 
            <Column header={t('wtw:Disponibilidad')} className='text-right' headerStyle={{ width: '80px' }} body={skeletonTemplate}></Column>
            <Column header={t('wtw:Efficiencia')} className='text-right' headerStyle={{ width: '80px' }} body={skeletonTemplate}></Column>
            <Column header={t('wtw:OEE')} className='text-right' headerStyle={{ width: '80px' }} body={skeletonTemplate}></Column>
      

          </FieldDataTable>
        </>  
      )
    }

    return (
      <FieldDataTable 
        value={CeldasList}  
        paginator={false}
        rowClassName={rowClass}
      >  
        <Column
          header={t('wtw:Cell')} 
          columnKey="NomCelda"
          field="NomCelda"
        >
        </Column>    
        <Column header={t('wtw:Boxes')} className='text-right' headerStyle={{ width: '100px' }} body={(rowData)=> CajasTemplate (rowData, 'Cajas', 0)}></Column>
        <Column header={t('wtw:Tons')} className='text-right' headerStyle={{ width: '100px' }} body={(rowData)=> DecimalTemplate (rowData, 'Tons', 2)}></Column>
        <Column header={t('wtw:Scrap')} className='text-right' headerStyle={{ width: '80px' }} body={(rowData)=> PorcentajeTemplate (rowData, 'Scrap')}></Column>
        <Column header={t('wtw:Utilizacion')} className='text-right' headerStyle={{ width: '80px' }} body={(rowData)=> PorcentajeTemplate (rowData, 'Utilizacion')}></Column> 
        <Column header={t('wtw:Disponibilidad')} className='text-right' headerStyle={{ width: '80px' }} body={(rowData)=> PorcentajeTemplate (rowData, 'Disponibilidad')}></Column>
        <Column header={t('wtw:Efficiencia')} className='text-right' headerStyle={{ width: '80px' }} body={(rowData)=> PorcentajeTemplate (rowData, 'Efficiencia')}></Column>
        <Column header={t('wtw:OEE')} className='text-right' headerStyle={{ width: '80px' }} body={(rowData)=> PorcentajeTemplate (rowData, 'OEE')}></Column>
        
      </FieldDataTable> 
    )
  }

  return (
    <> 
      <Dialog
        className='PriceTrackerScreen'
        closable={true}
        visible={props.Visible}
        onHide={onHideDialog}  
        style={{ width: '100vw' }}
        maximized={true}
        header={templateHeaderDialog}
        footer={templateFooterDialog}
        blockScroll={true}
      > 
        <Row className='align-items-start'>
          <Col lg={7} md={7} sm={12} xs={12}> 
            <Row>
              <Col>
                <Row>
                  <Col>
                    <span className='h2'>{t('wtw:ResultsbyCell') }</span>  
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <div>{t('wtw:Shift')}:</div>
                        <FieldSelectButton 
                          name="ClaTurno"
                          value={ClaTurno}
                          options={TurnoList}
                          onChange={(e) => onClaTurnoClick(e.value)}
                          optionValue="ClaTurno"
                          optionLabel="NomTurno"
                          multiple={true}
                          errors={errors}
                        /> 
                      </Col> 
                    </Row>  
                  </Col>
                </Row>
                <Row className='align-items-start'>
                  <Col lg={12} md={12} sm={12} xs={12}> 
                    <Row className='pt-4'>
                      <Col></Col>
                    </Row>
                    {ListadoCeldas()}
                    <Row className='pt-6'>
                      <Col>
                        {
                          EsCargandoListado ?
                          (<><Skeleton height="200px" width="100%" className="mb-2"></Skeleton></>)
                          :
                          (<><HighchartsReact highcharts={Highcharts} options={utilizationHeatMap} /></>)
                        }
                        
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row> 
          </Col>  
          <Col lg={5} md={5} sm={12} xs={12}>
            <ProductionWeeklyFollowUPComentarios  
              Visible={EsMostrarDialogComentarios}
              onHide={()=> setEsMostrarDialogComentarios(false)}  
              ClaUbicacion={ClaUbicacion}
              ClaAnioMes={ClaAnioMes} 
              ClaSemana={ClaSemana}
              ClaDepartamento={ClaDepartamento}
              ClaArea={ClaArea}
              NomArea={props.NomArea}
              NomUbicacion={props.NomUbicacion}
              NomAnioMes={props.NomAnioMes} 
              NomDepartamento={props.NomDepartamento}
              NomSemana={props.NomSemana}
              NomZona={props.NomZona}
              ClaTipo={3} 
              IsModal={false}
              ClaZona={props.ClaZona}
            >
            </ProductionWeeklyFollowUPComentarios>    
            <div hidden={true}>
              <Row className='pt-4'>
                <Col></Col>
              </Row> 
              <Row>
                <Col>
                  <span className='h2'>Zone-level comments</span>
                  <div className='info-color'>
                    You can use the following section to include comments at the Zone level.
                    <br></br>
                    <i>Click <b>View Cell Comments</b> to access an overview of comments organized by cell.</i>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>&nbsp;</Col>
              </Row> 
              <Card>
                <CardBody className="" style={{paddingTop: "5px"}}> 
                  <Row className="align-items-start">
                    <Col>
                  &nbsp;
                    </Col>
                  </Row>  
                  <Row className="align-items-start">
                    <Col>
                      <div className="p-fluid">
                        <span>{`${t('wtw:Comments')}:`}</span>
                        <FieldTextArea 
                          name="ExplicacionProblema"
                          rows={4}
                          value={ExplicacionProblema}  
                          onChange={(e) => onChangeExplicacionProblema(e)}  
                          errors={errors} 
                          maxLength={3000}
                        />
                      </div>
                    </Col>
                  </Row> 
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <div className='text-right'>
                        <FieldButton
                          label={t('wtw:Save')}
                          icon="pi pi-save"
                          className="p-button p-button-success"
                          onClick={() => onSave()}
                        >
                        </FieldButton>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Col> 
        </Row> 
      </Dialog>     
    </>
  );
};
export default ProductionWeeklyFollowUPZona;

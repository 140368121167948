/* eslint-disable jsx-a11y/label-has-associated-control */
import React  from 'react';    
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter, 
    CardTitle, 
    Row,
    Col 
  } from "reactstrap";
  import { Sparklines, SparklinesLine, SparklinesBars } from 'react-sparklines';
  import PropTypes from 'prop-types' 
import { Switch } from 'react-router';
import SparkLine from "../SparkLine/SparkLine.jsx"

const CardSparkLine =  (props) => {

    const options = {
      xAxis: { 
          categories: props.categories
      },
      series: [{ 
        data: props.series 
      }],
      tooltip: {
        headerFormat: `<span style="font-sze:10px"><b>${props.title} </b> <br/> <b>{point.x}</b>: </span>`,
        pointFormat: '{point.y}'
      },
      chart: {
        type: 'area'
      }
    }
    // console.log('spark');
    // console.log(options);

    const chartTitle = props.title;
    let colorChart = "#cccccc";
    let chartValue = ""; 
    
    let headerClass = "";

    switch (props.theme) {
        case "blue":
            headerClass = "hard-blue";
            colorChart = "#0d2034";
            break;
        case "lightBlue":
            headerClass = "light-blue";
            colorChart = "#00b7c3";
            break;
        case "red":
            headerClass = "light-red";
            colorChart = "#ef2f40";
            break;
        case "green":
            headerClass = "light-green";
            colorChart = "#20d891";
            break;
        case "gray":
            headerClass = "hard-gray";
            colorChart = "#636465";
            break;
        case "skyBlue":
            headerClass = "sky-blue";
            colorChart = "#457dcc";
            break;
        case "azulFuerte":
                headerClass = "azul-fuerte";
                colorChart = "#2E4480";
                break;
        case "azulMedio":
                  headerClass = "azul-medio";
                  colorChart = "#215ABF";
               break;
        default:
            headerClass = "hard-blue";
            colorChart = "#0d2034";
            break;
    } 
    
    let sufijo = "";

    let finalValue = props.footerValue == null ? 0 : props.footerValue;

    if (finalValue > 100000){
        finalValue  /= 1000.0;
        sufijo = "K";
    }
    else{
        sufijo = "";
    } 

    chartValue = new Intl.NumberFormat('en-US').format(finalValue);

    return (  
      <>  
        <Card>
          <CardHeader className={headerClass}>
            <CardTitle className="text-center">{chartTitle}</CardTitle>
          </CardHeader>    
          <CardBody>   
            <Row> 
              <Col>   
                <SparkLine options={options} color={colorChart} /> 
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Col className="text-center"><span className="card-chart-value" style={{ color: colorChart }}>{chartValue} {sufijo} </span></Col>
          </CardFooter>
        </Card> 
      </> 
    )
}   

    
export default CardSparkLine; 

      
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import FieldSelectButton from 'components/Controls/FieldSelectButton';
import FieldText from 'components/Controls/FieldText';
import FieldNumber from 'components/Controls/FieldNumber';
import FieldCalendar from 'components/Controls/FieldCalendar';
import FieldTextArea from 'components/Controls/FieldTextArea';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import CameraComponent from 'components/Controls/Camera/CameraComponent';
import { Image } from 'primereact/image';
import PreguntaArchivo from './PreguntaArchivo';

const Pregunta = (props) => {
  const [esRespuestaDeOpciones, setEsRespuestaDeOpciones] = useState(null);
  const [respuesta, setRespuesta] = useState(null); 
  
  const [showCamera, setShowCamera] = useState(false);  
  
  const onSelectedChange = (event) => {
    const {value} = event.target;
    setEsRespuestaDeOpciones(1);
    setRespuesta(value);
    props.onValueChanged(props.pregunta, value);
  };

  const onMultipleSelectedChange = (event) => {
    const value = Array.from(event.target.selectedOptions, (option) => option.value).join('|');
    setEsRespuestaDeOpciones(1);
    setRespuesta(value);
    props.onValueChanged(props.pregunta, value);
  };

  const onTextChange = (event) => {
    const {value} = event.target;
    setEsRespuestaDeOpciones(0);
    setRespuesta(value);
    props.onValueChanged(props.pregunta, value);
  };

  const onNumBoxValueChange = (event) => {
    const {value} = event;
    setEsRespuestaDeOpciones(0);
    setRespuesta(value);
    props.onValueChanged(props.pregunta, value);
  };

  const onDateBoxChange = (event) => {
    const {value} = event;
    setEsRespuestaDeOpciones(0);
    setRespuesta(value);
    props.onValueChanged(props.pregunta, value);
  };

  const onSelectedButtonChange = (event) => {
    const {value} = event;
    setEsRespuestaDeOpciones(1);
    setRespuesta(value);
    props.onValueChanged(props.pregunta, value);
  };

  const onMultipleSelectedButtonChange = (event) => {
    const value = Array.from(event.value).join('|');
    setEsRespuestaDeOpciones(1);
    setRespuesta(value);
    props.onValueChanged(props.pregunta, value);
  };

  const onImageChange = (imageItems) => {
    setEsRespuestaDeOpciones(0);
    setRespuesta('');
    props.onValueChanged(props.pregunta, imageItems, true);
  };

  const options = props.pregunta.OpcionesRespuesta.map(optn => ({
    label: optn.TextoFormularioPregunta || optn.IdFormularioPreguntaOpcion,
    value: optn.IdFormularioPreguntaOpcion
  }));

  const selectButtonOptions = props.pregunta.OpcionesRespuesta.map(optn => ({
    label: optn.TextoFormularioPregunta || optn.IdFormularioPreguntaOpcion,
    value: optn.IdFormularioPreguntaOpcion
  }));

  const renderField = () => {
    const { pregunta } = props;
    if(pregunta.respuesta!== ""&&pregunta.OpcionesRespuesta.length===0&&pregunta.imageItems===undefined){
      pregunta.imageItems=null;
    }

    switch (pregunta.ClaFormularioTipoCampo) {
      case 1:
        return (
          <div className='row pb-3' key={pregunta.IdFormularioPregunta}>
            <div className='col p-fluid'>
              <div>{pregunta.DescripcionFormularioPregunta}</div>
              <FieldText
                disabled={pregunta.EsDesactivar ? pregunta.EsDesactivar : false}
                className={pregunta.EsRequerida === true && pregunta.EsInvalida === true ? "p-invalid" : ""}
                onChange={onTextChange}
                value={pregunta.respuesta}
              />
              {pregunta.EsRequerida === true && pregunta.EsInvalida === true && (
                <div className="dx-item dx-validationsummary-item">
                  <div className="dx-item-content dx-validationsummary-item-content">
                    {props.t('common:RequiredField')}.
                  </div>
                </div>
              )}
            </div>
          </div>
        );

      case 2:
      case 3:
        return (
          <div className='row  pb-3' key={pregunta.IdFormularioPregunta}>
            <div className='col  p-fluid'>
              <div>{pregunta.DescripcionFormularioPregunta}</div>
              <FieldNumber
                value={pregunta.respuesta}
                disabled={pregunta.EsDesactivar ? pregunta.EsDesactivar : false}
                onChange={onNumBoxValueChange}
                className={pregunta.EsRequerida === true && pregunta.EsInvalida === true ? "p-invalid" : ""}
              />
              {pregunta.EsRequerida === true && pregunta.EsInvalida === true && (
                <div className="dx-item dx-validationsummary-item">
                  <div className="dx-item-content dx-validationsummary-item-content">
                    {props.t('common:RequiredField')}.
                  </div>
                </div>
              )}
            </div>
          </div>
        );

      case 4:
      case 5:
        return (
          <div className='row  pb-3' key={pregunta.IdFormularioPregunta}>
            <div className='col  p-fluid'>
              <div>{pregunta.DescripcionFormularioPregunta}</div>
              <FieldCalendar
                showTime={pregunta.ClaFormularioTipoCampo === 5}
                hourFormat="24"
                onChange={onDateBoxChange}
                disabled={pregunta.EsDesactivar ? pregunta.EsDesactivar : false}
                value={pregunta.respuesta}
                className={pregunta.EsRequerida === true && pregunta.EsInvalida === true ? "p-invalid" : ""}
              />
              {pregunta.EsRequerida === true && pregunta.EsInvalida === true && (
                <div className="dx-item dx-validationsummary-item">
                  <div className="dx-item-content dx-validationsummary-item-content">
                    {props.t('common:RequiredField')}.
                  </div>
                </div>
              )}
            </div>
          </div>
        );

      case 6:
        return (
          <div key={pregunta.IdFormularioPregunta} className="row questionary-title">
            <div className="col-12 my-auto ">
              {pregunta.DescripcionFormularioPregunta}
            </div>
          </div>
        );

      case 7:
        return (
          <div className='row  pb-3' key={pregunta.IdFormularioPregunta}>
            <div className='col p-fluid'>
              <div>{pregunta.DescripcionFormularioPregunta}</div>
              <FieldTextArea
                onChange={onTextChange}
                disabled={pregunta.EsDesactivar ? pregunta.EsDesactivar : false}
                className={pregunta.EsRequerida === true && pregunta.EsInvalida === true ? "p-invalid" : ""}
                value={pregunta.respuesta}
                style={{ width: '100%', height: '90px' }}
              />
              {pregunta.EsRequerida === true && pregunta.EsInvalida === true && (
                <div className="dx-item dx-validationsummary-item">
                  <div className="dx-item-content dx-validationsummary-item-content">
                    {props.t('common:RequiredField')}.
                  </div>
                </div>
              )}
            </div>
          </div>
        );

      case 8:
      case 9:  
        return (
          <div className='row  pb-3' key={pregunta.IdFormularioPregunta}>
            <div className='col'>
              <div>{pregunta.DescripcionFormularioPregunta}</div>
              <FieldDropdown
                value={pregunta.respuesta}
                options={options}
                onChange={onSelectedChange}
                disabled={pregunta.EsDesactivar ? pregunta.EsDesactivar : false}
                className={pregunta.EsRequerida === true && pregunta.EsInvalida === true ? "form-control-invalid" : " "}
                placeholder={props.t('common:SelectOption')}
              />
              {pregunta.EsRequerida === true && pregunta.EsInvalida === true && (
                <div className="dx-item dx-validationsummary-item">
                  <div className="dx-item-content dx-validationsummary-item-content">
                    {props.t('common:RequiredField')}.
                  </div>
                </div>
              )}
            </div>
          </div>
        );

      case 10:
      case 11: 
        return (
          <div className='row  pb-3' key={pregunta.IdFormularioPregunta}>
            <div className='col'>
              <div>{pregunta.DescripcionFormularioPregunta}</div>
              <FieldSelectButton
                multiple={pregunta.ClaFormularioTipoCampo === 11}
                value={pregunta.ClaFormularioTipoCampo === 11 && pregunta.respuesta ? pregunta.respuesta.split('|').map(Number) : pregunta.respuesta}
                options={selectButtonOptions}
                disabled={pregunta.EsDesactivar ? pregunta.EsDesactivar : false}
                onChange={pregunta.ClaFormularioTipoCampo === 11 ? onMultipleSelectedButtonChange : onSelectedButtonChange}
                className={selectButtonOptions.length > 3 ? pregunta.EsRequerida === true && pregunta.EsInvalida === true ? "buttonList buttonListInvalid scroll-y scrollbar-primary" : "buttonList scrollbar-primary scroll-y" : pregunta.EsRequerida === true && pregunta.EsInvalida === true ? "p-invalid" : ""}
              />
              {pregunta.EsRequerida === true && pregunta.EsInvalida === true && (
                <div className="dx-item dx-validationsummary-item">
                  <div className="dx-item-content dx-validationsummary-item-content">
                    {props.t('common:RequiredField')}.
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      
      case 12:
        return (
          <>
            <div className='row mt-2'> 
              <div className="col-12 md:col-6 lg:col-4 sm:col-12">
                <div>{pregunta.DescripcionFormularioPregunta}</div>
                {
                  pregunta.respuesta!== ""&&pregunta.OpcionesRespuesta.length===0 && pregunta.imageItems=== null
                  ?
                  (
                    <>
                      <PreguntaArchivo
                        IdFormularioResuelto={pregunta.IdFormularioResuelto}
                        IdFormularioPregunta={pregunta.IdFormularioPregunta}
                      >
                      </PreguntaArchivo>
                    </>
                  )
                  :
                  (
                    <>
                      <FieldButton 
                        onClick={()=>setShowCamera(true)} 
                        label={props.t('common:AttachPhotos')}
                        icon="fas fa-camera"
                        className={pregunta.EsRequerida === true && pregunta.EsInvalida === true ? "p-3 p-button-outlined p-button-info p-invalid-fileupload" : "p-3 p-button-outlined p-button-info"}
                      >
                      </FieldButton>
                      {
                  pregunta.imageItems !== undefined && pregunta.imageItems !== null
                  ?
                  (
                    <>
                      {
                          pregunta.imageItems.map((item, index) => {
                          const base64Icon = `data:image/jpeg;base64,${item.Archivo}`;
                          return (
                            <>
                              <div key={`label_${index}`} className={item.BajaLogica===0?'col-12 pt-2':'hidden'}>  
                                <table>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <Image 
                                          src={base64Icon} 
                                          alt="Image" 
                                          width={50}
                                          preview
                                        />
                                      </td>
                                      <td>
                                        <FieldButton
                                          icon="pi pi-trash"
                                          className="p-button-rounded p-button-danger p-button-outlined"
                                          onClick={()=>removeImage(index)}
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>  
                              </div>  
                            </>
                            )
                          })
                     } 
                    </>
                  )
                  :
                  (<></>)
                }
               
                      {pregunta.EsRequerida === true && pregunta.EsInvalida === true && (
                      <div className="dx-item dx-validationsummary-item">
                        <div className="dx-item-content dx-validationsummary-item-content">
                          {props.t('common:RequiredField')}.
                        </div>
                      </div>
                    )}
                    </>
                )
              }
              </div>
            </div> 
          </>
      );

      default:
        return null;
    }
  };
  
  const removeImage = (idata) => { 
    const newImageList = []
    for (let index = 0; index < props.pregunta.imageItems.length; index++) {
      if(idata===index){
        // no se agrega la imagen  
      }else{
        newImageList.push(props.pregunta.imageItems[index]);
      }
      
    }
    
    // setImageItems(newImageList);
    onImageChange(newImageList);
  };

  const addImage = (ImageURL) => {  
    const Image = ImageURL.split(",");
    if(Image[1]!==undefined){
        const infoObjectImage = { 
            BajaLogica:0,
            Archivo:Image[1],
            ExtensionArchivo:'jpeg',
            NomArchivo:''
        }

        const CurrentImages = props.pregunta.imageItems === undefined || props.pregunta.imageItems === null ? [] :  props.pregunta.imageItems;

        CurrentImages.push(infoObjectImage);

        // setImageItems(CurrentImages);
        onImageChange(CurrentImages);
    } 
    
    setShowCamera(false);
  }

  const onHideCam=()=>{ 
    setShowCamera(false);
    document.body.classList.add('p-overflow-hidden');
  }

  return (
    <>
      {renderField()}

      {showCamera && (
      <CameraComponent
        show={showCamera}
        onAcceptPhoto={addImage}
        onHide={onHideCam}
        allowUploadImageFromFile={true}
      >
      </CameraComponent> 
                  )}  
    </>
  );
};

export default withTranslation()(Pregunta);
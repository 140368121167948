import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from 'utils/config';
import { callApi, showSweetAlert } from 'utils/utils';
import { object, string, number } from 'yup';
import { Label, Row, Col } from 'reactstrap';
import moment from 'moment';
import YupValidator from 'utils/YupValidator';
import FieldText from 'components/Controls/FieldText';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldCalendar from 'components/Controls/FieldCalendar';
import FieldAutoComplete from 'components/Controls/FieldAutoComplete';
import FieldTextArea from 'components/Controls/FieldTextArea';

const AddITAsset = (props) => {
  const {t, i18n} = useTranslation(['it', 'common']);

  const [equipment, setEquipment] = useState(null); // Type of equipment
  const [equipments, setEquipments] = useState([]); // Equipment types
  const [isNewEquipment, setIsNewEquipment] = useState(false); // Is the equipment new
  const [showAddEquipment, setShowAddEquipment] = useState(false); // Show the new equipment box
  const [brand, setBrand] = useState(null); // Brand
  const [brands, setBrands] = useState([]); // Equipment brands
  const [isNewBrand, setIsNewBrand] = useState(false); // Is the brand new
  const [showAddBrand, setShowAddBrand] = useState(false); // Show the new brand box
  const [serialNumber, setSerialNumber] = useState(null); // Serial number
  const [model, setModel] = useState(''); // Model
  const [purchaseOrder, setPurchaseOrder] = useState(null); // Purchase order number (opt.)
  const [purchaseDate, setPurchaseDate] = useState(null); // Purchase date (opt.)
  const [vendor, setVendor] = useState(null); // Vendor (opt.)
  const [location, setLocation] = useState(null); // ClaUbicacion
  const [locations, setLocations] = useState(null);
  const [assignedTo, setAssignedTo] = useState(null); // Assigned to Description
  const [assignedUsers, setAssignedUsers] = useState(null);
  const [assignedUser, setAssignedUser] = useState(null); // ClaUsuario (optional)
  const [notes, setNotes] = useState(''); // Notes
  const [errors, setErrors] = useState([]); // Errors
  const [adpEmployee, setAdpEmployee] = useState(null);
  const [adpEmployees, setAdpEmployees] = useState([]);

  useEffect(() => {
    GetEquipments();
    GetBrands();
  }, [])

  // Filter for assignedUser autocomplete
  const filterAssignedUsers = (e) => {
    let valor = ''; 
    if (assignedUser.NomUsuario !== null && assignedUser.NomUsuario !== '' &&  e.query === "")
    {
     valor = assignedUser.NomUsuario;
    }
    else{
     valor = e.query;
    }
    const paramsServiceOwners = {};

    const urlWebServiceOwners = `${config.UrlApiProject}IT/GetUsers?valor=${valor}`;
    
    callApi(urlWebServiceOwners, 'GET', paramsServiceOwners, (response) => {  
       setAssignedUsers(response.data.Users);
    });    
  }

  // When the assignedUser changes
  const onAssignedUserChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.assignedUser;
    setErrors(newErrors);
    setAssignedUser(e.value);
  }

  const filterAdpEmployee = (e) => {
    let valor = ''; 
    if (adpEmployee.FullName !== null && adpEmployee.FullName !== '' &&  e.query === "")
    {
     valor = adpEmployee.FullName;
    }
    else{
     valor = e.query;
    }
    const paramsServiceOwners = {};
    // event.query 
    const urlWebServiceOwners = `${config.UrlApiProject}HR/CmbADPEmployee?valor=${valor}`;
    // Get owner
    callApi(urlWebServiceOwners, 'GET', paramsServiceOwners, (response) => {  
       setAdpEmployees(response.data);
    });    
  }

  const onAdpEmployeeChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.adpEmployee;
    setErrors(newErrors);
    setAdpEmployee(e.value);
  }

  // Filter for location autocomplete
  const filterLocations = (e) => {
    let valor = ''; 
    if (location.NomUbicacion !== null && location.NomUbicacion !== '' &&  e.query === "")
    {
     valor = location.NomUbicacion;
    }
    else{
     valor = e.query;
    }
    const paramsServiceOwners = {};
    
    const urlWebServiceOwners = `${config.UrlApiProject}IT/GetUbicaciones?valor=${valor}`;

    callApi(urlWebServiceOwners, 'GET', paramsServiceOwners, (response) => {  
       setLocations(response.data.Locations);
    });    
  }

  // When the locations changes
  const onLocationChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.location;
    setErrors(newErrors);
    setLocation(e.value);
  }

  // Get the list of equipment types
  const GetEquipments = () => {
    const urlWebService = `${config.UrlApiProject}IT/GetEquipmentTypes`;
    const paramsService = {};

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setEquipments(response.data);
    });
  }

  // Get the list of brands
  const GetBrands = () => {
    const urlWebService = `${config.UrlApiProject}IT/GetEquipmentBrands`;
    const paramsService = {};

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setBrands(response.data);
    });
  }

  // Handle equipment change (either object or text)
  const onChangeEquipment = (e) => {
    const newErrors = {...errors};
    delete newErrors.equipment;
    setErrors(newErrors);
    !isNewEquipment ? setEquipment(e.value) : setEquipment(e.target.value)
  }

  // Handle brand change (either object or text)
  const onChangeBrand = (e) => {
    const newErrors = {...errors};
    delete newErrors.brand;
    setErrors(newErrors);
    !isNewBrand ? setBrand(e.value) : setBrand(e.target.value)
  }

  // Handle serial number change
  const onChangeSerialNumber = (e) => {
    const newErrors = {...errors};
    delete newErrors.serialNumber;
    setErrors(newErrors);
    setSerialNumber(e.target.value === '' ? null : e.target.value);
  }

  // Handle model change
  const onChangeModel = (e) => {
    const newErrors = {...errors};
    delete newErrors.model;
    setErrors(newErrors);
    setModel(e.target.value);
  }

  // Handle purchase order change
  const onChangePurchaseOrder = (e) => {
    const newErrors = {...errors};
    delete newErrors.purchaseOrder;
    setErrors(newErrors);
    setPurchaseOrder(e.target.value === '' ? null : e.target.value);
  }

  // Handle purchase date change
  const onChangePurchaseDate = (e) => {
    const newErrors = {...errors};
    delete newErrors.purchaseDate;
    setErrors(newErrors);
    setPurchaseDate(e.value);
  }

  // Handle vendor change
  const onChangeVendor = (e) => {
    const newErrors = {...errors};
    delete newErrors.vendor;
    setErrors(newErrors);
    setVendor(e.target.value === '' ? null : e.target.value);
  }

  // Handle model change
  const onChangeAssignedTo = (e) => {
    const newErrors = {...errors};
    delete newErrors.assignnedTo;
    setErrors(newErrors);
    setAssignedTo(e.target.value);
  }

  // Handle Notes change
  const onChangeNotes = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.notes;   
    setErrors(newErrors)  
    setNotes(e.target.value)
  }

  // Clear the form
  const clearForm = () => {
    setEquipment(null);
    setShowAddEquipment(false);
    setBrand(null);
    setShowAddBrand(false);
    setSerialNumber(null);
    setModel('');
    setPurchaseOrder(null);
    setPurchaseDate(null);
    setVendor(null);
    setErrors([]);
  }

  // Handle the add button with validation
  const onClickAddAsset = async (e) => {
    const validationScheme = object({
      equipment: !isNewEquipment ? number().required('').integer().nullable()
                  : string().required('').nullable(),
      brand: !isNewBrand ? number().required('').integer().nullable()
              : string().required('').nullable(),
      serialNumber: string().required('').nullable(),
      model: string().trim().required('').nullable(),
      purchaseOrder: string().nullable(),
      vendor: string().nullable(),
      location: object({ ClaUbicacion: number().required('').integer() }).required('').nullable(),
      assignedTo: string().required('').nullable(),
      adpEmployee: object({ ClaADPEmployee: number().integer() }).nullable()
    });
    
    const value = {
      equipment,
      brand,
      serialNumber,
      model,
      purchaseOrder,
      vendor,
      location,
      assignedTo
    };

    const validator = new YupValidator(validationScheme);
    const isValid = await validator.validate(value);

    if (!isValid) {
      setErrors(validator.errors);
    } else {
      const urlWebService = `${config.UrlApiProject}IT/InsertITAssets`;
      const paramsService = {
        ClaTipoEquipo: isNewEquipment ? -1 : equipment,
        NomTipoEquipo: !isNewEquipment ? null : equipment,
        ClaMarcaEquipo: isNewBrand ? -1 : brand,
        NomMarcaEquipo: !isNewBrand ? null : brand,
        SerialNumber: serialNumber,
        ModeloEquipo: model,
        IdOrdenCompraRef: purchaseOrder,
        FechaCompra: purchaseDate,
        NomProveedor: vendor,
        ClaUbicacion: location.ClaUbicacion,
        AssignedTo: assignedTo,
        ClaUsuarioAsignado: assignedUser !== null && assignedUser !== undefined ? assignedUser.ClaUsuario : null,
        Notas: notes,
        ClaADPEmployee: adpEmployee !== null && adpEmployee !== undefined ? adpEmployee.ClaADPEmployee : null
      };

      console.log(paramsService);
      
      callApi(urlWebService, 'POST', paramsService, (response) => {
        showSweetAlert("Success", t('it:AssetAdded'), 'success');

        clearForm();

        if (props.onHide !== undefined) {
          props.onHide();
        }

      });
    }
  }

  return (
    <>
      <Row className='p-fluid'>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Row className='align-items-start'>
            <Col xs={12} sm={12} md={12} lg={12}>
              <h3>{t('it:EquipmentDetails')}</h3>
            </Col>
            <Col xs={6} sm={6} md={4} lg={4} className='p-field'>
              {!showAddEquipment ? (
                <span>
                  <Label>{`${t('it:Equipment')} *`}</Label>
                  <span className='p-inputgroup'>
                    <FieldButton
                      icon='pi pi-plus'
                      onClick={() => {
                        setShowAddEquipment(true);
                        setIsNewEquipment(true);
                        setEquipment(null);
                      }}
                    />
                    <FieldDropdown
                      id='equipment'
                      name='equipment'
                      value={equipment}
                      optionValue='ClaTipoEquipo'
                      optionLabel='NomTipoEquipo'
                      options={equipments}
                      onChange={onChangeEquipment}
                      errors={errors}
                    >
                    </FieldDropdown>
                  </span>
                </span>
              ) : (
                <span>
                  <Label>{t('it:AddEquipment')}</Label>
                  <span className='p-inputgroup'>
                    <FieldButton
                      icon='pi pi-times'
                      className='p-button-danger'
                      onClick={() => {
                        setShowAddEquipment(false);
                        setIsNewEquipment(false);
                        setEquipment(null);
                      }}
                    />
                    <FieldText
                      id='equipment'
                      name='equipment'
                      value={equipment}
                      onChange={onChangeEquipment}
                      errors={errors}
                    >
                    </FieldText>
                  </span>
                </span>
              )}
            </Col>
            <Col xs={6} sm={6} md={4} lg={4} className='p-field'>
              {!showAddBrand ? (
                <span>
                  <Label>{`${t('it:Brand')} *`}</Label>
                  <span className='p-inputgroup'>
                    <FieldButton
                      icon='pi pi-plus'
                      onClick={() => {
                        setShowAddBrand(true);
                        setIsNewBrand(true);
                        setBrand(null);
                      }}
                    />
                    <FieldDropdown
                      id='brand'
                      name='brand'
                      value={brand}
                      optionValue='ClaMarcaEquipo'
                      optionLabel='NomMarcaEquipo'
                      options={brands}
                      onChange={onChangeBrand}
                      errors={errors}
                    >
                    </FieldDropdown>
                  </span>
                </span>
              ) : (
                <span>
                  <Label>{t('it:AddBrand')}</Label>
                  <span className='p-inputgroup'>
                    <FieldButton
                      icon='pi pi-times'
                      className='p-button-danger'
                      onClick={() => {
                        setShowAddBrand(false);
                        setIsNewBrand(false);
                        setBrand(null);
                      }}
                    />
                    <FieldText
                      id='brand'
                      name='brand'
                      value={brand}
                      onChange={onChangeBrand}
                      errors={errors}
                    >
                    </FieldText>
                  </span>
                </span>
              )}
            </Col>
            <Col xs={6} sm={6} md={4} lg={4} className='p-field'>
              <Label>{`${t('it:SerialNumber')} *`}</Label>
              <FieldText
                id='serialNumber'
                name='serialNumber'
                value={serialNumber}
                onChange={onChangeSerialNumber}
                errors={errors}
              >
              </FieldText>
            </Col>
            <Col xs={6} sm={6} md={4} lg={4} className='p-field'>
              <Label>{`${t('it:Model')} *`}</Label>
              <FieldText
                id='model'
                name='model'
                value={model}
                onChange={onChangeModel}
                errors={errors}
              >
              </FieldText>
            </Col>
            <Col xs={6} sm={6} md={4} lg={4} className='p-field'>
              <Label>{t('it:PurchaseOrder')}</Label>
              <FieldText
                id='purchaseOrder'
                name='purchaseOrder'
                value={purchaseOrder}
                onChange={onChangePurchaseOrder}
                errors={errors}
              >
              </FieldText>
            </Col>
            <Col xs={6} sm={6} md={4} lg={4} className='p-field'>
              <Label>{t('it:PurchaseDate')}</Label>
              <FieldCalendar
                id='purchaseDate'
                name='purchaseDate'
                value={moment(purchaseDate).toDate()}
                onChange={onChangePurchaseDate}
                errors={errors}
              >
              </FieldCalendar>
            </Col>
            <Col xs={6} sm={6} md={4} lg={4} className='p-field'>
              <Label>{t('it:Vendor')}</Label>
              <FieldText
                id='vendor'
                name='vendor'
                value={vendor}
                onChange={onChangeVendor}
                errors={errors}
              >
              </FieldText>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <hr className='mt-2 mb-2' />
              <h3>{t('it:UserDetails')}</h3>
            </Col>
            <Col xs={6} sm={6} md={4} lg={4} className='p-field'>
              <Label>{`${t('it:Location')} *`}</Label>
              <FieldAutoComplete
                dropdown
                id="location"
                name="location"
                value={location}
                onChange={onLocationChange}
                suggestions={locations}
                field="NomUbicacion"
                completeMethod={filterLocations}
                errors={errors}
              /> 
            </Col>
            <Col xs={6} sm={6} md={4} lg={4} className='p-field'>
              <Label>{`${t('it:AssignedTo')} *`}</Label>
              <FieldText
                id='assignedTo'
                name='assignedTo'
                value={assignedTo}
                onChange={onChangeAssignedTo}
                errors={errors}
              >
              </FieldText>
            </Col>
            <Col xs={6} sm={6} md={4} lg={4} className='p-field'>
              <Label>{t("it:EmployeeID")}</Label>
              <FieldAutoComplete
                dropdown
                id="adpEmployee"
                name="adpEmployee"
                value={adpEmployee}
                onChange={onAdpEmployeeChange}
                suggestions={adpEmployees}
                field="IdFullName"
                completeMethod={filterAdpEmployee}
                errors={errors}
              /> 
            </Col>
            <Col xs={6} sm={6} md={4} lg={4} className='p-field'>
              <Label>{t('it:User')}</Label>
              <FieldAutoComplete
                dropdown
                id="assignedToUser"
                name="ClaUsuario"
                value={assignedUser}
                onChange={onAssignedUserChange}
                suggestions={assignedUsers}
                field="NomUsuario"
                completeMethod={filterAssignedUsers}
                errors={errors}
              /> 
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} className='p-field p-fluid'>
              <Label>{t("common:Notes")}</Label>
              <FieldTextArea
                name="notes"
                rows={3}
                placeholder=''
                maxLength={200}
                value={notes}
                onChange={onChangeNotes}
                errors={errors}
              /> 
            </Col>
          </Row>
          <Row className='p-d-flex p-jc-center'>
            <Col xs={12} sm={12} md={4} lg={4}>
              <FieldButton
                name='addAsset'
                icon='pi pi-plus'
                className='p-button-success'
                onClick={onClickAddAsset}
                label={t('common:Add')}
              >
              </FieldButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default AddITAsset;
import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Label, Card, CardBody } from 'reactstrap';
import { callApi, showSweetAlert } from "utils/utils";
import { config } from 'utils/config';
import FieldUpload from 'components/Controls/FieldUpload/FieldUpload';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import { Column } from 'primereact/column';
import YupValidator from 'utils/YupValidator';
import { object, number, array, string } from 'yup';
import FixPODForm from './FixPODForm';
import { Dialog } from 'primereact/dialog';
import FixPODType from './FixPODTypes';
import ViewPODDetails from './ViewPODDetails';
import FieldChips from 'components/Controls/FieldChips';
import FieldText from 'components/Controls/FieldText';

const EnterPOD = (props) => {
  const {t, i18n} = useTranslation(['operations', 'common']);

  const [podFile, setPodFile] = useState([]); // POD pdf file
  const [tiposPOD, setTiposPOD] = useState([]); // POD types
  const [tipoPOD, setTipoPOD] = useState(null); // Selected POD type
  const [errors, setErrors] = useState([]);
  const [pods, setPODs] = useState([]) // Returned PODs
  const [fixDocument, setFixDocument] = useState({}); // Document to send to the FixPODForm
  const [showFixUploadPOD, setShowFixUploadPOD] = useState(false); // Show the dialog to fix the POD
  const [selectedPod, setSelectedPod] = useState([]);
  const [showPodDetails, setShowPodDetails] = useState(false);
  const [detectionType, setDetectionType] = useState(2);
  const [invoiceNumbers, setInvoiceNumbers] = useState([]);
  const [shippersNo, setShippersNo] = useState(null);
  const [soNumbers, setSoNumbers] = useState([]);

  const detectionTypes = [
    {"id": 1, "name": "Automatic AI"},
    {"id": 2, "name": "Manual"}
  ]

  useEffect(() => {
    getTiposPOD();
  }, [])

  const getTiposPOD = () => {
    const urlWebService = `${config.UrlApiProject}POD/GetTiposPOD`;

    const paramsService = {
    };

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setTiposPOD(response.data);
    });
  }

  const onTipoPODChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.priority;
    setErrors(newErrors);
    setTipoPOD(e.value);
    setShippersNo(null);
    setSoNumbers([]);
    setInvoiceNumbers([]);
  }

  const onShippersNoChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.shippersNo;
    setErrors(newErrors);
    setShippersNo(e.target.value);
  }

  const onSoNumbersChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.soNumbers;
    setErrors(newErrors);
    setSoNumbers(e.value);
  }

  const onInvoiceNumbersChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.invoiceNumbers;
    setErrors(newErrors);
    setInvoiceNumbers(e.value);
  }


  const onDetectionTypeChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.detectionType;
    setErrors(newErrors);
    setDetectionType(e.value);
  }

  const onPODFileUpload = (files) => {
    const newErrors = {...errors};
    delete newErrors.podFile;
    setErrors(newErrors);
    setPodFile(files)
  }

  const onUploadClick = async () => {
    const validationScheme = object({
      podFile: array().min(1, t('common:RequiredField')),
      tipoPOD: number().required(t('common:RequiredField')).positive().integer().nullable(),
      detectionType: number().required(t('common:RequiredField')).positive().integer().nullable(),
      shippersNo: number().transform(val => (isNaN(val) ? null : val)).nullable().when(['tipoPOD', 'detectionType'], {
        is: (tipoPOD, detectionType) => tipoPOD === 1 && detectionType === 2,
        then: number()
              .required(t('common:RequiredField')).positive().integer().nullable()
      }),
      soNumbers: array().when(['tipoPOD', 'detectionType'], {
        is: (tipoPOD, detectionType) => tipoPOD === 1 && detectionType === 2,
        then: array().min(1, t('common:RequiredField'))
      }),
      invoiceNumbers: array().when(['tipoPOD', 'detectionType'], {
        is: (tipoPOD, detectionType) => tipoPOD === 2 && detectionType === 2,
        then: array().min(1, t('common:RequiredField'))
      })
    })

    const value = {
      podFile,
      tipoPOD,
      detectionType,
      shippersNo,
      soNumbers,
      invoiceNumbers
    }

    const validator = new YupValidator(validationScheme);
    const isValid = await validator.validate(value);
    if (!isValid) {
      console.log(validator.errors)
      setErrors(validator.errors);
    }
    else {
      let urlWebService = `${config.UrlApiProject}POD/SubmitPOD`;

      const paramsService = {
        PODFile: podFile.length > 0 ? podFile[0].FileData : null,
        PODFileName: podFile.length > 0 ? podFile[0].FileName : null,
        ClaTipoPOD: tipoPOD
      };

      // MCSW Manual
      if (detectionType === 2 && tipoPOD === 1) {
        urlWebService = `${config.UrlApiProject}POD/UploadMCSWPODManually`;
        paramsService.SONumbers = soNumbers.map(Number);
        paramsService.ShippersNo = shippersNo;
      }

      // Deacero / Ingetek Manual
      if (detectionType === 2 && tipoPOD === 2) {
        urlWebService = `${config.UrlApiProject}POD/UploadDEAPODManually`;
        paramsService.InvoiceNumbers = invoiceNumbers;
      }

      callApi(urlWebService, 'POST', paramsService, (response) => {
        const pages = response.data.Pages;

        const podArr = [];
        
        console.log(pages);

        if (Object.keys(pages).length > 0)
        {
          // Loop through the returned PODs and add them to the array
          for (const podPage of Object.values(pages))
          {
            for (const pod of podPage)
            {
              // Check for duplicates and remove errors
              let exists = false;
              for (const existingPod of podArr)
              {
                exists = existingPod.IdPod === pod.IdPod || !Object.hasOwn(pod, 'IdPod');

                if (exists)
                {
                  break;
                }
              }

              if (!exists && Object.hasOwn(pod, 'IdPod'))
              {
                podArr.push(pod);
              }
            }
          }
        }

        if (podArr.length === 0)
        {
          const {data} = response;
          // If no POD is found in the document get all the pages to show the user
          if (data.AllPages !== undefined && data.AllPages.length > 0)
          {
            const allPages = data.AllPages;
            const newPages = []
            for (let i = 0; i < allPages.length; i++) {
              newPages.push(
                {
                  PageNumber: allPages[i].Page.pageNum,
                  PageFile: allPages[i].Page.pageFile,
                  ExtractedData: allPages[i].ExtractedData
                }
              )
            }
            setFixDocument({Pages: newPages});
            setShowFixUploadPOD(true);
          }
        }
        else
        {
          // props.setInvoiceNum(invoiceNumber);
          showSweetAlert('Success', t('common:Success'), 'success');
        }
        
        // Log for troubleshooting
        console.log('Response: ', response);
        console.log('Data: ', pages);
        console.log('podArr: ', podArr);

        setPODs(podArr);
        setPodFile([])
        setErrors([]);
        setInvoiceNumbers([]);
        setSoNumbers([]);
        setShippersNo(null);
      });

      setPodFile([]);
      setErrors([]);
    }
  }

  const downloadFile = (row) => { 
    const url = `${config.UrlApiProject}POD/DownloadPOD?IdPod=${row.IdPod}`;  
    const paramsService= {};  
    callApi(url, 'GET', paramsService, (response) => {
      const downloadFile = response.data.File;
      if(downloadFile !== undefined){
        const linkSource = `data:application/${response.data.FileExtension};base64,${downloadFile}`
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = `POD-${row.NumFactura}`;
        downloadLink.click();
      }
    });
  }

  const onViewDetailsClick = (row) => {
    setSelectedPod(row);
    setShowPodDetails(true);
  }

  const onViewDetailsHide = () => {
    setShowPodDetails(false);
    setSelectedPod([]);
  }

  const onFixUploadPODHide = () => {
    setShowFixUploadPOD(false);
    setFixDocument([]);
  }

  const submitUploadPODAsFixed = (newDocPages) => {
    const completedDoc = {
      Pages: newDocPages
    }

    const url = `${config.UrlApiProject}POD/FixUploadPOD`;
    const paramsService = completedDoc; 
    callApi(url, 'POST', paramsService, (response) => {
      showSweetAlert("Success", t('common:Success'), 'success');
      onFixUploadPODHide();
    });  
  }

  
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString(
      'en-us',
      {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }
    )
  }

  const shipDateColumn = (row) => {
    return formatDate(row.ShipDate);
  }

  const fechaFacturaColumn = (row) => {
    return row.FechaFactura !== null ? formatDate(row.FechaFactura) : '';
  }

  const optionsColumn = (row) => {
    return (
      <>
        <FieldButton className='p-button-rounded p-button-success mr-1' icon='pi pi-list' tooltip={t('pod:ViewDetails')} onClick={() => onViewDetailsClick(row)} />
        <FieldButton className='p-button-rounded p-button-warning' icon='pi pi-download' tooltip={t('pod:DownloadAll')} onClick={() => downloadFile(row)} />
      </>
    )
  }

  return (
    <>
      <Row className='mt-2'>
        <Col xs={12} sm={12} md={12} lg={6}>
          <Card>
            <CardBody>
              <Row className='p-flex align-items-start p-fluid'>
                <Col xs={12} sm={12} md={6} lg={6} className=''>
                  <div className='p-field'>
                    <Label>{t('pod:PODType')}</Label>
                    <FieldDropdown
                      name='tipoPOD'
                      value={tipoPOD}
                      optionValue="ClaTipoPOD"
                      optionLabel="NomTipoPOD"
                      options={tiposPOD}
                      onChange={onTipoPODChange}
                      errors={errors}
                      className='mb-2'
                    />
                  </div>
                  <div className='p-field'>
                    <Label>{t('pod:DetectionType')}</Label>
                    <FieldDropdown
                      name='detectionType'
                      value={detectionType}
                      optionValue="id"
                      optionLabel="name"
                      options={detectionTypes}
                      onChange={onDetectionTypeChange}
                      errors={errors}
                      className='mb-2'
                    />
                  </div>
                  {detectionType === 2 && tipoPOD === 2? // Manually enter Deacero invoice numbers
                  (
                    <div className='p-field'>
                      <Label>{t('pod:InvoiceNumbersUploadInstructions')}</Label>
                      <FieldChips
                        name="invoiceNumbers"
                        value={invoiceNumbers}
                        onChange={onInvoiceNumbersChange}
                        separator=","
                        errors={errors}
                      />
                    </div>
                  ) : <></>}
                  {detectionType === 2 && tipoPOD === 1? // Manually enter MCSW numbers
                  (
                    <>
                      <div className='p-field'>
                        <Label>{t('pod:ShippersNo')}</Label>
                        <FieldText
                          name="shippersNo"
                          value={shippersNo}
                          onChange={onShippersNoChange}
                          errors={errors}
                          keyfilter="int"
                        />
                      </div>
                      <div className='p-field'>
                        <Label>{t('pod:SONumbersUploadInstructions')}</Label>
                        <FieldChips
                          name="soNumbers"
                          value={soNumbers}
                          onChange={onSoNumbersChange}
                          separator=","
                          errors={errors}
                        />
                      </div>
                    </>
                  ) : <></>}
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} className='p-field'>
                  <Label className='text-info'><i className='pi pi-info-circle mr-2' />{t('pod:ValidTypes')}</Label>
                  <FieldUpload
                    name='podFile'
                    files={podFile}
                    onChange={onPODFileUpload}
                    errors={errors}
                    accept='application/pdf,image/tiff,image/jpeg,image/png'
                    uploadPrompt={t('pod:PODUploadPrompt')}
                  >
                  </FieldUpload>
                </Col>
              </Row>
              <Row className='p-flex p-fluid p-jc-end'>
                <Col xs={12} sm={12} md={4} lg={3} className='p-field p-jc-center'>
                  <FieldButton
                    label={t('common:Submit')}
                    className='p-button-success mt-2'
                    icon='pi pi-upload'
                    onClick={onUploadClick}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className='mb-2'>
                <span className='text-info'><i className='pi pi-info-circle mr-2' />{t('pod:PODsIdentifiedTable')}</span>
              </div>
              <FieldDataTable 
                value={pods} 
                header={t('pod:PODs')}  
                scrollable 
                className="p-datatable-striped"
              >  
                <Column field="PODType" header={t('pod:PODType')} sortable headerStyle={{ width: '60px' }}></Column>
                <Column field="NumFactura" header={t('pod:InvoiceNum')} sortable headerStyle={{ width: '60px' }}></Column>
                <Column field="SoNum" header={t('pod:SONum')} sortable headerStyle={{ width: '50px' }}></Column>
                <Column body={fechaFacturaColumn} header={t('pod:FechaFactura')} sortable sortField='ShipDate' headerStyle={{ width: '60px' }}></Column>
                <Column field="NomProveedor" header={t('pod:Vendor')} sortable headerStyle={{ width: '120px' }}></Column>
                <Column field="BillToName" header={t('pod:BillTo')} sortable headerStyle={{ width: '170px' }}></Column> 
                <Column field="ShipToName" header={t('pod:ShipTo')} sortable headerStyle={{ width: '170px' }}></Column> 
                <Column body={optionsColumn} header={t('pod:Options')} headerStyle={{ width: '110px' }}></Column>
              </FieldDataTable>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Dialog
        visible={showFixUploadPOD}
        onHide={() => onFixUploadPODHide()}
        maximized={true}
        modal
      >
        <FixPODForm 
          document={fixDocument}
          documentTitle='Upload'
          submitDocumentAsFixedToAPI={submitUploadPODAsFixed}
          docType={FixPODType.Upload}
        />
      </Dialog>
      <Dialog
        visible={showPodDetails}
        onHide={onViewDetailsHide}
        header={t('pod:PODDetails')}
        className='p-grid p-fluid'
        maximized={true}
      >
        <ViewPODDetails pod={selectedPod} />
      </Dialog>
    </>
  )
}

export default EnterPOD;
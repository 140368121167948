/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  ListGroupItem,
  ListGroup,
  Progress,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label
} from 'reactstrap';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { Badge } from 'primereact/badge';
// import FeedbackCoaching from './FeedbackCoaching.jsx';
import { Link } from 'react-router-dom';
import ActionPlanScoreSquare from './ActionPlanScoreSquare.jsx';
import { Toolbar } from 'primereact/toolbar';
import Highcharts from 'highcharts';
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert, tracker } from '../../../../utils/utils';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable.jsx';
import { Column } from 'primereact/column';
import FieldButton from 'components/Controls/FieldButton/FieldButton.jsx';
import { Dialog } from 'primereact/dialog';

const ProjectActivitiesMonthSummary = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['focusReport', 'common']); 
  const [ClaPuesto, setClaPuesto] = useState(null); 
  const [ClaAnio, setClaAnio] = useState(null); 
  const [ClaMes, setClaMes] = useState(null);  
  const [EsActivity, setEsActivity] = useState(false);  
  const [ProjectActivitiesMonthResult, setProjectActivitiesMonthResult] = useState(null);
  const [ActionPlaActivitiesMonthtDetList, setActionPlaActivitiesMonthtDetList] = useState([]);
  const [DetailActivityList, setDetailActivityList] = useState([]);
  const [TotalActivityDetail, setTotalActivityDetail] = useState(null);
  const [NomCumplimiento, setNomCumplimiento] = useState(null);

  useEffect(() => {
    setClaPuesto(props.ClaPuesto === undefined ? 0 : props.ClaPuesto);
    setClaAnio(props.ClaAnio === undefined ? 0 : props.ClaAnio);
    setClaMes(props.ClaMes === undefined ? 0 : props.ClaMes);
    GetProjectActivitiesResultadoMonth();
  }, [props.ClaPuesto, props.ClaAnio, props.ClaMes]);

  const GetProjectActivitiesResultadoMonth = () => {
    const getProjectActivitiesResultadoMonth = `${config.UrlApiProject}ScoreCard/GetProjectActivitiesResultadoMonthPuesto`;
    const paramsToService = {
      ClaPuesto: props.ClaPuesto === undefined ? 0 : props.ClaPuesto,
      ClaAnio: props.ClaAnio === undefined ? 0 : props.ClaAnio,
      ClaMes: props.ClaMes === undefined ? 0 : props.ClaMes,
    };
    callApi(getProjectActivitiesResultadoMonth, 'POST', paramsToService, (response) => {
      setProjectActivitiesMonthResult(
        response.data.ProjectActivitiesMonth[0].ProjectActivitiesMonthResult
      );
      setActionPlaActivitiesMonthtDetList(response.data.ProjectActivitiesMonthDetail);
    });
  };

  const GetActivityDetail = (pnClaCumplimiento) => {
    const getProjectActivityDetail = `${config.UrlApiProject}ScoreCard/GetProjectActivityDetail`;
    const paramsToService = {
      ClaPuesto,
      ClaAnio,
      ClaMes,
      ClaCumplimiento: pnClaCumplimiento
    };
    callApi(getProjectActivityDetail, 'POST', paramsToService, (response) => {
      const headerResulst = response.data.ProjectActivityHead.length>0? response.data.ProjectActivityHead[0] : {}; 
      setTotalActivityDetail(headerResulst.Total);
      setNomCumplimiento(headerResulst.NomCumplimiento);
      setDetailActivityList(response.data.ProjectActivityDetail);
    });
  };

  const PorcTemplate = (rowData) => {
    return (
      <>
        <label>
          {rowData.Porc} %
        </label>
      </>
    );
  };
  const headerActionPlaActivitiesMontht = <></>;

  const footer  = ( 
    <> 
      <Row>
        <Column></Column>          
        <Column className="text-center" footer={() => (<b>{ (ActionPlaActivitiesMonthtDetList.reduce((a,v) =>  a += v.Quality === null ? 0 : v.Quality , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>     
        <Column></Column>         
        <Column className="text-center" footer={() => (<b>{ (ActionPlaActivitiesMonthtDetList.reduce((a,v) =>  a += v.Point === null ? 0 : v.Point , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>     
      </Row>
    </>
  )
  
  const actionBodyTemplateQuality = (rowData, props) => {
    return (
      <>
        <div>
          <Label className='Cursor' onClick={() => onClickViewDetailActivity(rowData)}>{rowData.Quality}</Label>          
        </div>
      </>
    );
  }
  const dialogStyle = {
    width: '80vw',
  };

  const closeModalActivity = () => {
    setEsActivity(false);
  };

  const onClickViewDetailActivity = (props) => {    
    setEsActivity(!EsActivity);
    GetActivityDetail(props.CumplimientoID);

    tracker.trackEvent({
      type: 'ProjectActivitiesMonthSummary', /* component name */
      action: 'CLICK_ACTIVITY_DETAIL_LIST'
    }) 
  };

  const headerActivity =(
    <>
      <Row>
        <Col>
          {NomCumplimiento}
        </Col>
        <Col className="text-right">
          {t('focusReport:Total')}: {TotalActivityDetail}
        </Col>
      </Row>
        
    </>
      );

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col className="text-center">
              <h2 className="title-ProjectActivities">{t('focusReport:ProjectActivities')}</h2>
            </Col>
          </Row>
          <Row className="align-items-start ">
            <Col lg={12} md={12} sm={12} xs={12}>              
              <div style={{ float:'left'}}>
                <h4>{t('focusReport:ProjectActivitiesTiltle')}</h4>
              </div> 
              <div className="text-right">
                <h4><span className="title-ProjectActivities-Sub">{t('focusReport:Score')}:</span> {Highcharts.numberFormat(ProjectActivitiesMonthResult, 2, '.', ',') }%</h4>
              </div>              
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
            </Col>
          </Row>
          <Row className="align-items-start ">
            <Col lg={12} md={12} sm={12} xs={12}>
              <FieldDataTable
                value={ActionPlaActivitiesMonthtDetList}
                scrollable 
                className="table-header-background-blue" 
                header={headerActionPlaActivitiesMontht}
                footerColumnGroup={footer}     
                paginator={false}  
              >
                <Column
                  field="Description"
                  header={t('focusReport:ActivitiesAccomp')}
                  columnKey="Description"
                  headerStyle={{ width: '145px', textAlign: 'center' }}
                >
                </Column>
                <Column
                  field="Quality"
                  className="text-center"
                  header={t('focusReport:Quality')}
                  columnKey="Quality"
                  headerStyle={{ width: '60px', textAlign: 'center' }}
                  body={(rowData, props) => actionBodyTemplateQuality(rowData, props)} 
                >
                  
                </Column>
                <Column
                  field="Porc"
                  body={PorcTemplate}
                  className="text-center"
                  header={t('focusReport:Porc')}
                  columnKey="Porc"
                  headerStyle={{ width: '40px', textAlign: 'center' }}
                >
                  
                </Column>
                <Column
                  field="Point"
                  className="text-center"
                  header={t('focusReport:Point')}
                  columnKey="Point"
                  headerStyle={{ width: '60px', textAlign: 'center' }}
                >
                  
                </Column>
              </FieldDataTable>
            </Col>
          </Row>
        </CardBody>
        <CardFooter hidden={!props.showCloseButton}>
          <Row>
            <Col className='text-center'>
              <FieldButton
                label={t('common:Close')}
                icon="fas fa-times"
                className="warning no-border p-mr-2 rounded-button"
                onClick={typeof(props.onCloseClick) !== 'undefined' && typeof(props.onCloseClick) === 'function' ? props.onCloseClick : null}
              />
            </Col>
          </Row>
        </CardFooter>
      </Card>
      <Dialog
        visible={EsActivity}
        header=""
        style={dialogStyle}
        modal
        className="dialog-custom"
        onHide={closeModalActivity}
      >
        <Row className="align-items-start">
          <Col lg={12} md={12} sm={12} xs={12}>
            <FieldDataTable
              value={DetailActivityList}
              scrollable 
              scrollHeight="330px" 
              className="editable-cells-table"  
              editMode="cell"
              selectionMode="single"
              header={headerActivity}
            >
              <Column
                field="NomProyecto"
                header={t('focusReport:Project')}
                columnKey="NomProyecto"
                headerStyle={{ width: '145px' }}
              >
              </Column>
              <Column
                field="NomFase"
                header={t('focusReport:Phase')}
                columnKey="NomFase"
                headerStyle={{ width: '145px', textAlign: 'center' }}                
              > 
              </Column>
              <Column
                field="NomActividad"
                header={t('focusReport:DescriptionModal')}
                columnKey="NomActividad"
                headerStyle={{ width: '145px', textAlign: 'center' }}                
              > 
              </Column>
              <Column
                field="FechaEstimadaFin"
                className="text-center"
                header={t('focusReport:DueDate')}
                columnKey="FechaEstimadaFin"
                headerStyle={{ width: '40px', textAlign: 'center' }}
              >
                 
              </Column>
              <Column
                field="FechaRealFin"
                className="text-center"
                header={t('focusReport:CompletedDate')}
                columnKey="FechaRealFin"
                headerStyle={{ width: '60px', textAlign: 'center' }}
              >                 
              </Column>            
              <Column
                field="DiferenciaDias"
                className="text-center"
                header={t('focusReport:DateDiff')}
                columnKey="DiferenciaDias"
                headerStyle={{ width: '60px', textAlign: 'center' }}
              >                 
              </Column>
              
            </FieldDataTable>
          </Col>
        </Row> 
      </Dialog>
    </>
  );
};

export default ProjectActivitiesMonthSummary;

/* eslint-disable object-shorthand */
import React, { useState, useEffect } from 'react';
import { config } from '../../../utils/config';
import { callApi } from '../../../utils/utils'; 
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official'; 
import { useTranslation } from 'react-i18next';

const StandarVsRealChar = (props) => {
 
    const { t } = useTranslation(['formulario', 'common']);
    const [chartTitle, setChartTitle] = useState('');
    const [standardCostData, setStandardCostData] = useState([]);
    const [realCostData, setRealCostData] = useState([]);
    const [xCategories, setXCategories] = useState([]);

    const lineChart = {
        chart: {
          height: 328,
          type: 'line',
        },
        title: {
          text: `<b>${chartTitle}</b><br/>${  t('formulario:TrendLast12Months')}`,
        },  
        xAxis: {  
          gridLineWidth:0,
          lineWidth: 0,
          categories: xCategories,
          title: {
            text: null,
          },
        },
        yAxis: { 
          gridLineWidth:1,
          title: {
            text: null,
          }, 
          labels: {
            overflow: 'justify',
            enabled:true
          },
        },
        tooltip: { 
            valuePrefix: ' $',
            valueDecimals: 2
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        legend: {
          layout: 'horizontal',
          align: 'left',
          verticalAlign: 'bottom', 
          borderWidth: 1,
          backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
          shadow: true,
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: 'Actual',
            data: realCostData,  
            showInLegend: true, 
            color: '#ff7a3d',
            dataLabels:{
                enabled:false,  
                formatter: function() {  
                    const { x, y, point, series } = this; 
                    return `$ ${Highcharts.numberFormat(y,1)  }`;
                  }
            }
          },
          {
            name: 'Standard',
            data: standardCostData,  
            showInLegend: true, 
            color: '#26caf6',
            dataLabels:{
                enabled:false,  
                formatter: function() {  
                    const { x, y, point, series } = this; 
                    return `$ ${Highcharts.numberFormat(y,1)  }`;
                  }
            }
          }
        ], 
      };

      useEffect(() => {
        
        const GetInconsistenciasElementGridService = `${config.UrlApiProject}Costos/GetElementInconsistenciasLinesChart`;
    
        const paramsToService = {
          anio: props.filters.Anio,
          anioMes: props.filters.AnioMes,
          claTipoElementoCosto: props.filters.ClaTipoElementoCosto,
          claElemento: props.ClaElemento,
          claDepartamento:props.filters.ClaDepartamento,
          claTipoCelda:props.filters.ClaTipoCelda,
          claCelda:props.filters.ClaCelda,
          claArticulo:props.filters.ClaArticulo,
          TipoConversionCost: props.filters.TipoConversionCost
        };   
        
        callApi(GetInconsistenciasElementGridService, 'POST', paramsToService, (response) => {
            setStandardCostData(response.data.Standard.data);
            setRealCostData(response.data.Real.data);
            setXCategories(response.data.Categories); 
            console.log('paramsToService');
            console.log(paramsToService);
            const GetCeldaTitleService = `${config.UrlApiProject}Costos/GetCeldaElementTitle`;  
            callApi(GetCeldaTitleService, 'POST', paramsToService, (response) => { 
              if(response.data.info!==undefined)
              {  
                setChartTitle(`${response.data.info[0].NomElemento }`);  
              }     
              });
        });

      }, [props.ClaElemento]);

    return  <HighchartsReact highcharts={Highcharts} options={lineChart} />;
}

export default StandarVsRealChar;

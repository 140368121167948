/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { config } from '../../utils/config';
import { callApi } from '../../utils/utils';
import React, { useState, useEffect } from 'react'; 
import { useTranslation } from 'react-i18next';   
import { Image } from 'primereact/image';
import { Col, Row } from 'reactstrap';

const PreguntaArchivo = ({
    IdFormularioResuelto,
    IdFormularioPregunta
}) => { 
  const { t } = useTranslation(['common']);
  const [IsLoading, setIsLoading] = useState(true);
  const [Archivos, setArchivos] = useState([]);

  useEffect(() => {    
    const urlWebService = `${config.UrlApiProject}formulario/getFormularioRespuestaArchivoAnexo?IdFormularioResuelto=${IdFormularioResuelto}&IdFormularioPregunta=${IdFormularioPregunta}`; 
    const paramsService = {};
    setIsLoading(true);
    callApi(urlWebService, 'GET', paramsService, (response) => {
     const archivos = response.data.ArchivoAnexoList;
     setArchivos(archivos);
     setIsLoading(false);
    });
  }, [IdFormularioResuelto, IdFormularioPregunta]); 


  return (
    IsLoading ? (
      <div className='img-thumbnail'> 
        <i className="fa-solid fa-spinner loading-spiner"></i>
      </div> 
    ) : (
      <> 
        <Col>
          <Row>
            {
              Archivos.map((item, index) => {
                const base64Icon = `data:image/${item.ExtensionArchivo};base64,${item.Archivo}`;
                return (
                  <Col className='p-1' style={{ margin:1, width: '50px', display: 'flex', flexBasis: 'auto', flexGrow: 0 }}>
                    <Image 
                      src={base64Icon}
                      className='pl-1' 
                      alt="Image" 
                      width={50}
                      height={50}
                      preview
                    />
                  </Col>  
              );
              })
            }
          </Row>

        </Col> 

      
    
      </>
    )
  ); 
};
export default PreguntaArchivo;
import React, { useEffect, useState } from 'react';
import './Ballenas.scss';
import { config } from '../../../utils/config';
import { callApi} from '../../../utils/utils';
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  CardTitle
} from 'reactstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import IconButton from '@material-ui/core/IconButton';
import TimelineIcon from '@material-ui/icons/Timeline';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Buckets from '../Buckets/Buckets.jsx';
import CustomerProductDetail from './CustomerProductDetail.jsx';  
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Tag } from 'primereact/tag';
import { useTranslation } from 'react-i18next'; 
import FieldTag from '../../../components/Controls/FieldTag.jsx'
import InvoiceCustomerProduct from './InvoiceCustomerProduct.jsx'; 
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
/* ################################# START COMPONENT ################################# */

const Ballenas = (props) => {
 
  const { buttonLabel, className } = props;

  /* ################# SET STATE AREA ################# */
  const { t, i18n } = useTranslation(['formulario','common']);
  const [rowsGrid, setRowsGrid] = useState([]);
  const [rowsClientes, setRowsClientes] = useState([]);
  const [modalProduct, setModalProduct] = useState(false);
  const [modalInvoice, setModalInvoice] = useState(false);
  const [bucketFilters , setbucketFilters] = useState({});
  const [showBuckets, setshowBuckets] = useState(false);
  const [showAllColumns, setshowAllColumns] = useState(false);
  const [CustomerDetailProductFilters, setCustomerDetailProductFilters] = useState({});
  const [InvoiceCustomerProductFilters, setInvoiceCustomerProductFilters] = useState({});
  const [SelectedCustomer, setSelectedCustomer] = useState(null);
  const [EsMostrarPorCuenta, setEsMostrarPorCuenta] = useState(true);
  /* ################# END SET STATE AREA ################# */
 
  useEffect(() => { 
    const GetBallenasGridService = `${config.UrlApiProject}Rentabilidad/GetBallenasGrid`;
    
    const paramsToService = {
      anioMes: props.filters.AnioMes,
      claGrupoEstadistico3: props.filters.ClaGrupoEstadistico3,
      claGerenciaRegional: props.filters.ClaGerenciaRegional,
      claClienteCuenta: props.filters.ClaClienteCuenta,
      claBucket: null,
      esMostrarSoloConfiables: props.filters.EsMostrarSoloConfiables,
      claMarketSegment: props.filters.ClaMarketSegment,
    }; 

    callApi(GetBallenasGridService, 'POST', paramsToService, (response) => { 
      setRowsGrid(response.data[0]); 
      setRowsClientes(response.data[1]);
      setshowBuckets(false); 
      setModalProduct(false);
      setModalInvoice(false);
    });

  }, [props.filters]); 

  const onCloseModal = () => {
    setModalProduct(!modalProduct);
    if(modalInvoice){
      setModalInvoice(!modalInvoice);
    }
  }

  const onShowInvoice = (data) => { 

    setInvoiceCustomerProductFilters(data)
    setModalInvoice(true);
    setModalProduct(false);
  }

  const onShowColumns = () => { 
    setshowAllColumns(!showAllColumns);  
  }

  const onClickByCustomer = () => {
    setEsMostrarPorCuenta(!EsMostrarPorCuenta);
    setshowBuckets(false);
  }

  const header  = ( 
    <>
      <div className="row">
        <div className="col-12 col-lg-6 col-sm-12">
          {t('formulario:AccountListInformation')} &nbsp;
          
          <FieldTag 
            label={t('formulario:ByCustomer')}
            className="secondary" 
            onClick={onClickByCustomer}
          /> 
        </div>
        <div className="col-12 col-lg-6 col-sm-12 text-right">            
          <FieldTag 
            label={showAllColumns ? t('formulario:HideColumns') : t('formulario:ShowColumns')}
            className="info" 
            onClick={onShowColumns}
          /> 
          &nbsp;
          <Tag className="success">80%</Tag>&nbsp;<Tag className="warning">17%</Tag>&nbsp;<Tag className="light">3%</Tag>&nbsp;<Tag className="danger">(-)</Tag>
        </div>
      </div>
    </>
  )

  const headerClientes = ( 
    <>
      <div className="row">
        <div className="col-12 col-lg-6 col-sm-12">
          {t('formulario:CustomerListInformation')} &nbsp;
          
          <FieldTag 
            label={t('formulario:ByAccount')}
            className="secondary" 
            onClick={onClickByCustomer}
          /> 
        </div>
        <div className="col-12 col-lg-6 col-sm-12 text-right">            
          <FieldTag 
            label={showAllColumns ? t('formulario:HideColumns') : t('formulario:ShowColumns')}
            className="info" 
            onClick={onShowColumns}
          /> 
          &nbsp;
          <Tag className="success">80%</Tag>&nbsp;<Tag className="warning">17%</Tag>&nbsp;<Tag className="light">3%</Tag>&nbsp;<Tag className="danger">(-)</Tag>
        </div>
      </div>
    </>
  ) 

  const PMPriceColumn = (row) => {  
    return (<>${ row.PMPrice === null ? '' : row.PMPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>);
  }

  const PMPorcColumn = (row) => {  
    return (<>{ row.PMPorc === null ? '' : row.PMPorc.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%</>);
  }

  const TonsPorcColumn = (row) => {  
    return (<>{ row.TonsPorc === null ? '' : row.TonsPorc.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%</>);
  }

  const TonsColumn = (row) => {  
      return (<>{row.Tons === null ? '' : row.Tons.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>);
  }

  const ConvCostColumn = (row) => {  
    return (<>${row.ConvCostReal === null ? '' : row.ConvCostReal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>);
  }

  const FreightInboundColumn = (row) => {  
    return (<>${row.FreightInboundReal === null ? '' : row.FreightInboundReal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>);
  }

  const FreightOutboundColumn = (row) => {  
    return (<>${row.FreightOutboundReal === null ? '' : row.FreightOutboundReal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>);
  }
  
  const PriceColumn = (row) => {  
    return (<>${row.PriceReal === null ? '' : row.PriceReal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>);
  }

  const RawMaterialColumn = (row) => {  
    return (<>${row.RawMaterialReal === null ? '' : row.RawMaterialReal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>);
  }

  const SHColumn= (row) => {  
    return (<>${ row.SHReal === null ? '' : row.SHReal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>);
  } 

  const SGFinancialColumn= (row) => {  
    return (<>${ row.SGFinancialReal === null ? '' : row.SGFinancialReal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>);
  } 

  const AcumuladoImpMargenColumn = (row) => {   
    return (<><Tag className={row.Clasificacion === 1 ? "success" : row.Clasificacion === 2 ? "warning" : row.Clasificacion === 3 ? "light" : row.Clasificacion === 4 ? "danger" : ""}>${row.AcumuladoImpMargen === null ? '' : row.AcumuladoImpMargen.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Tag></>);
  }

  const PMColumn = (row) => {  
    return (<>${ row.ImpMargenBolsillo === null ? '' : row.ImpMargenBolsillo.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>);
  }

  const onCustomerDetailBucket = (row) =>{
    const filters = { ...props.filters };
    filters.ClaClienteCuenta = row.ClaClienteCuenta;
    filters.ClaClienteUnico  = row.ClaClienteUnico;  
 
    setbucketFilters(filters);
    setshowBuckets(true); 
  }

  const onCustomerDetailProducts = (row) =>{
    const filters = { ...props.filters };
    filters.ClaClienteCuenta = row.ClaClienteCuenta;
    filters.NomClienteCuenta = row.ClaClienteCuenta > 0 ? row.NomClienteCuenta : row.NomClienteUnico; 
    filters.ClaClienteUnico  = row.ClaClienteUnico;  
     
    setCustomerDetailProductFilters(filters); 
    setInvoiceCustomerProductFilters(filters);
    setModalProduct(true); 
  }

  const ActionsColumn = (row) =>  {    
    return (
      <> 
        <IconButton onClick={(e) => onCustomerDetailBucket(row)} size="small" color="primary"><TimelineIcon /></IconButton>
        <IconButton size="small" onClick={(e) => onCustomerDetailProducts(row)} color="secondary"><OpenInNewIcon /></IconButton>
      </>
    );
  }

  const onRowSelect = (event) => {  
    onCustomerDetailBucket(event.data); 
  }

  const onRowUnselect = (event) =>{  
    //  setClaBucket(null);
  } 

  const footer  = ( 
    <> 
      <Row>
        <Column></Column>  
        <Column footer={() => (<b> {t('common:Total')}</b>)}></Column> 
        <Column></Column>  
        <Column footer={() => (<b>{ (rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>    
              
        { showAllColumns ? ( 
          <Column footer={() => (
            <b>
              ${ 
                (rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )) > 0 ? 
                  ( (rowsGrid.reduce((a,v) =>  a += v.RawMaterialRealImp === null ? 0 : v.RawMaterialRealImp , 0 ))/(rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )) ).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                : null
              }
            </b>
          )}
          >
          </Column> 
                  ) : 
                  (<Column style={{display:'none'}}></Column>  )}
                
        { showAllColumns ? ( 
          <Column footer={() => (
            <b>
              ${ 
                (rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )) > 0 ? 
                  ( (rowsGrid.reduce((a,v) =>  a += v.ConvCostRealImp === null ? 0 : v.ConvCostRealImp , 0 ))/(rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )) ).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                : null
              }
            </b>
          )}
          >
          </Column> 
                  ) : 
                  (<Column style={{display:'none'}}></Column>  )}
                
        { showAllColumns ? ( 
          <Column footer={() => (
            <b>
              ${ 
                (rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )) > 0 ? 
                  ( (rowsGrid.reduce((a,v) =>  a += v.FreightInboundRealImp === null ? 0 : v.FreightInboundRealImp , 0 ))/(rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )) ).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                : null
              }
            </b>
          )}
          >
          </Column> 
                  ) : 
                  (<Column style={{display:'none'}}></Column>  )}
                
        { showAllColumns ? ( 
          <Column footer={() => (
            <b>
              ${ 
                (rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )) > 0 ? 
                  ( (rowsGrid.reduce((a,v) =>  a += v.FreightOutboundRealImp === null ? 0 : v.FreightOutboundRealImp , 0 ))/(rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )) ).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                : null
              }
            </b>
          )}
          >
          </Column> 
                  ) : 
                  (<Column style={{display:'none'}}></Column>  )}
                
        { showAllColumns ? ( 
          <Column footer={() => (
            <b>
              ${ 
                (rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )) > 0 ? 
                  ( (rowsGrid.reduce((a,v) =>  a += v.SGFinancialRealImp === null ? 0 : v.SGFinancialRealImp , 0 ))/(rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )) ).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                : null
              }
            </b>
          )}
          >
          </Column> 
                  ) : 
                  (<Column style={{display:'none'}}></Column>  )}
               
        { showAllColumns ? ( 
          <Column footer={() => (
            <b>
              ${ 
                (rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )) > 0 ? 
                  ( (rowsGrid.reduce((a,v) =>  a += v.SHRealImp === null ? 0 : v.SHRealImp , 0 ))/(rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )) ).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                : null
              }
            </b>
          )}
          >
          </Column> 
                  ) : 
                  (<Column style={{display:'none'}}></Column>  )}
               
        { showAllColumns ? ( 
          <Column footer={() => (
            <b>
              ${ 
                (rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )) > 0 ? 
                  ( (rowsGrid.reduce((a,v) =>  a += v.PriceRealImp === null ? 0 : v.PriceRealImp , 0 ))/(rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )) ).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                : null
              }
            </b>
          )}
          >
          </Column> 
                  ) : 
                  (<Column style={{display:'none'}}></Column>  )}
               
               
        <Column footer={() => (
          <b>
            ${ 
                (rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )) > 0 ? 
                  ( (rowsGrid.reduce((a,v) =>  a += v.ImpMargenBolsillo === null ? 0 : v.ImpMargenBolsillo , 0 ))/(rowsGrid.reduce((a,v) =>  a += v.Tons === null ? 0 : v.Tons , 0 )) ).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                : null
              }
          </b>
          )}
        >
        </Column>  
        <Column  footer={() => (
          <b>
            { 
                (rowsGrid.reduce((a,v) =>  a += v.ImpPriceVtaPocketPrice === null ? 0 : v.ImpPriceVtaPocketPrice , 0 )) > 0 ? 
                  (100* (rowsGrid.reduce((a,v) =>  a += v.ImpMargenBolsillo === null ? 0 : v.ImpMargenBolsillo , 0 ))/(rowsGrid.reduce((a,v) =>  a += v.ImpPriceVtaPocketPrice === null ? 0 : v.ImpPriceVtaPocketPrice , 0 )) ).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                : null
              }
            %
          </b>
          )}
        >
        </Column>  
        <Column footer={() => (
          <b>
            ${ 
                
                  ( (rowsGrid.reduce((a,v) =>  a += v.ImpMargenBolsillo === null ? 0 : v.ImpMargenBolsillo , 0 )) ).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                 
              }
          </b>
          )}
        >
        </Column>  
        <Column></Column>  
              



      </Row>
    </>
  )

  /* ################# START RENDER AREA ################# */
 
  if (props.showHide) {
    return (
      <>  
        <Card style={{ display: modalProduct || modalInvoice ? 'block' : 'none' }}>
          <CardHeader className="bg-card-header-grid">
            <CardTitle className="text-right">
              <FieldButton icon="pi pi-times" className="p-button-danger" onClick={() => onCloseModal()} />
            </CardTitle>
          </CardHeader>    
          <CardBody>  
            { 
              modalProduct ? ( <CustomerProductDetail onClose={onCloseModal} onClickInvoice={onShowInvoice} filters={CustomerDetailProductFilters}></CustomerProductDetail>)
               : (<InvoiceCustomerProduct onClose={onCloseModal} filters={InvoiceCustomerProductFilters}></InvoiceCustomerProduct>
            )
          }
          </CardBody> 
        </Card> 
        <div style={{ display: modalProduct || modalInvoice ? 'none' : 'block' }}>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
              { EsMostrarPorCuenta ? 
                (
                  <>
                    <FieldDataTable 
                      value={rowsGrid} 
                      header={header}  
                      scrollable 
                      scrollHeight="270px" 
                      className="p-datatable-striped"
                      selection={SelectedCustomer} 
                      selectionMode="single" 
                      onSelectionChange={e => setSelectedCustomer(e.value)} 
                      onRowSelect={onRowSelect} 
                      onRowUnselect={onRowUnselect}
                      footerColumnGroup={footer}
                      removableSort
                    > 
                      <Column body={ActionsColumn} header={t('formulario:ViewDetail')} headerStyle={{ width: '110px' }}></Column>  
                      <Column sortable field="NomClienteCuenta" header={t('formulario:AccountName')} headerStyle={{ width: '330px' }} columnKey="NomClienteCuenta"></Column> 
                
                      <Column sortable body={TonsPorcColumn} header={t('formulario:TonsPorc')} headerStyle={{ width: '110px' }} columnKey="TonsPorc" field="TonsPorc"></Column>  
                      <Column sortable body={TonsColumn} header={t('formulario:Tons')} headerStyle={{ width: '110px' }} columnKey="Tons" field="Tons"></Column>    
               
                      { showAllColumns ? ( 
                        <Column sortable body={RawMaterialColumn} columnKey="RawMaterialReal" field="RawMaterialReal" header={t('formulario:RawMaterial')} headerStyle={{ width: '140px' }}></Column> 
                  ) : 
                  (<Column style={{display:'none'}} headerStyle={{ display: 'none' }}></Column>  )}
                
                      { showAllColumns ? ( 
                        <Column sortable body={ConvCostColumn} columnKey="ConvCostReal" field="ConvCostReal" header={t('formulario:ConvCost')} headerStyle={{ width: '140px' }}></Column> 
                  ) : 
                  (<Column style={{display:'none'}} headerStyle={{ display: 'none' }}></Column>  )}
                
                      { showAllColumns ? ( 
                        <Column sortable body={FreightInboundColumn} columnKey="FreightInboundReal" field="FreightInboundReal" header={t('formulario:FreightInbound')} headerStyle={{ width: '160px' }}></Column> 
                  ) : 
                  (<Column style={{display:'none'}} headerStyle={{ display: 'none' }}></Column>  )}
                
                      { showAllColumns ? ( 
                        <Column sortable body={FreightOutboundColumn} columnKey="FreightOutboundReal" field="FreightOutboundReal" header={t('formulario:FreightOutbound')} headerStyle={{ width: '180px' }}></Column> 
                  ) : 
                  (<Column style={{display:'none'}} headerStyle={{ display: 'none' }}></Column>  )}
                
                      { showAllColumns ? ( 
                        <Column sortable body={SGFinancialColumn} columnKey="SGFinancialReal" field="SGFinancialReal" header={t('formulario:SGAFinancials')} headerStyle={{ width: '140px' }}></Column> 
                  ) : 
                  (<Column style={{display:'none'}} headerStyle={{ display: 'none' }}></Column>  )}
               
                      { showAllColumns ? ( 
                        <Column sortable body={SHColumn} columnKey="SHReal" field="SHReal" header={t('formulario:SH')} headerStyle={{ width: '140px' }}></Column> 
                  ) : 
                  (<Column style={{display:'none'}} headerStyle={{ display: 'none' }}></Column>  )}
               
                      { showAllColumns ? ( 
                        <Column sortable body={PriceColumn} columnKey="PriceReal" header={t('formulario:Price')} headerStyle={{ width: '140px' }} field="PriceReal"></Column> 
                  ) : 
                  (<Column style={{display:'none'}} headerStyle={{ display: 'none' }}></Column>  )}
               
               
                      <Column sortable body={PMPriceColumn} header={t('formulario:PMPriceByton')} headerStyle={{ width: '110px' }} columnKey="PMPrice" field="PMPrice"></Column>  
                      <Column sortable body={PMPorcColumn} header={t('formulario:PMPorc')} headerStyle={{ width: '110px' }} columnKey="PMPorc" field="PMPorc"></Column>  
                      <Column sortable body={PMColumn} header={t('formulario:Contribution')} headerStyle={{ width: '140px' }} columnKey="ImpMargenBolsillo" field="ImpMargenBolsillo"></Column>  
                      <Column body={AcumuladoImpMargenColumn} header={t('formulario:AcumMargen')} headerStyle={{ width: '150px' }} columnKey="AcumuladoImpMargen"></Column>  
                    </FieldDataTable>   
                  </>
)
                :
                (
                  <>
                    <FieldDataTable 
                      value={rowsClientes} 
                      header={headerClientes}  
                      scrollable 
                      scrollHeight="270px" 
                      className="p-datatable-striped"
                      selection={SelectedCustomer} 
                      selectionMode="single" 
                      onSelectionChange={e => setSelectedCustomer(e.value)} 
                      onRowSelect={onRowSelect} 
                      onRowUnselect={onRowUnselect}
                      footerColumnGroup={footer}
                      removableSort
                    > 
                      <Column body={ActionsColumn} header={t('formulario:ViewDetail')} headerStyle={{ width: '110px' }}></Column>  
                      <Column sortable field="NomClienteUnico" header={t('formulario:NomClienteUnico')} headerStyle={{ width: '330px' }} columnKey="NomClienteUnico"></Column> 
              
                      <Column sortable body={TonsPorcColumn} header={t('formulario:TonsPorc')} headerStyle={{ width: '110px' }} columnKey="TonsPorc" field="TonsPorc"></Column>  
                      <Column sortable body={TonsColumn} header={t('formulario:Tons')} headerStyle={{ width: '110px' }} columnKey="Tons" field="Tons"></Column>    
             
                      { showAllColumns ? ( 
                        <Column sortable body={RawMaterialColumn} columnKey="RawMaterialReal" field="RawMaterialReal" header={t('formulario:RawMaterial')} headerStyle={{ width: '140px' }}></Column> 
                ) : 
                (<Column style={{display:'none'}} headerStyle={{ display: 'none' }}></Column>  )}
              
                      { showAllColumns ? ( 
                        <Column sortable body={ConvCostColumn} columnKey="ConvCostReal" field="ConvCostReal" header={t('formulario:ConvCost')} headerStyle={{ width: '140px' }}></Column> 
                ) : 
                (<Column style={{display:'none'}} headerStyle={{ display: 'none' }}></Column>  )}
              
                      { showAllColumns ? ( 
                        <Column sortable body={FreightInboundColumn} columnKey="FreightInboundReal" field="FreightInboundReal" header={t('formulario:FreightInbound')} headerStyle={{ width: '160px' }}></Column> 
                ) : 
                (<Column style={{display:'none'}} headerStyle={{ display: 'none' }}></Column>  )}
              
                      { showAllColumns ? ( 
                        <Column sortable body={FreightOutboundColumn} columnKey="FreightOutboundReal" field="FreightOutboundReal" header={t('formulario:FreightOutbound')} headerStyle={{ width: '180px' }}></Column> 
                ) : 
                (<Column style={{display:'none'}} headerStyle={{ display: 'none' }}></Column>  )}
              
                      { showAllColumns ? ( 
                        <Column sortable body={SGFinancialColumn} columnKey="SGFinancialReal" field="SGFinancialReal" header={t('formulario:SGAFinancials')} headerStyle={{ width: '140px' }}></Column> 
                ) : 
                (<Column style={{display:'none'}} headerStyle={{ display: 'none' }}></Column>  )}
             
                      { showAllColumns ? ( 
                        <Column sortable body={SHColumn} columnKey="SHReal" field="SHReal" header={t('formulario:SH')} headerStyle={{ width: '140px' }}></Column> 
                ) : 
                (<Column style={{display:'none'}} headerStyle={{ display: 'none' }}></Column>  )}
             
                      { showAllColumns ? ( 
                        <Column sortable body={PriceColumn} columnKey="PriceReal" header={t('formulario:Price')} headerStyle={{ width: '140px' }} field="PriceReal"></Column> 
                ) : 
                (<Column style={{display:'none'}} headerStyle={{ display: 'none' }}></Column>  )}
             
             
                      <Column sortable body={PMPriceColumn} header={t('formulario:PMPriceByton')} headerStyle={{ width: '110px' }} columnKey="PMPrice" field="PMPrice"></Column>  
                      <Column sortable body={PMPorcColumn} header={t('formulario:PMPorc')} headerStyle={{ width: '110px' }} columnKey="PMPorc" field="PMPorc"></Column>  
                      <Column sortable body={PMColumn} header={t('formulario:Contribution')} headerStyle={{ width: '140px' }} columnKey="ImpMargenBolsillo" field="ImpMargenBolsillo"></Column>  
                      <Column body={AcumuladoImpMargenColumn} header={t('formulario:AcumMargen')} headerStyle={{ width: '150px' }} columnKey="AcumuladoImpMargen"></Column>  
                    </FieldDataTable>   
                  </>
)}
               
            </Col>  
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
                &nbsp;
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
              <Buckets showHide={showBuckets} filters={bucketFilters}></Buckets> 
            </Col>
          </Row> 
        </div>
      </>  
    );
  }
  if (!props.showHide) {
    return <></>;
  }
};

export default Ballenas;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi } from '../../../utils/utils';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon';
import moment from 'moment';
import Highcharts from 'highcharts';
import swal from '@sweetalert/with-react';
import PageContent from 'layouts/PageContent';
import { Toolbar } from 'primereact/toolbar';
import { ProgressBar } from 'primereact/progressbar';
import FieldDropdown from 'components/Controls/FieldDropdown';
import { DataTable } from 'primereact/datatable';
import { DataGrid } from 'devextreme-react';
import { HeaderFilter, StateStoring } from 'devextreme-react/pivot-grid';
import { ColumnFixing, Paging } from 'devextreme-react/data-grid';

const ProyectoKPIManagementFullView = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['orangePage', 'common']);  
  const [columnsHeaderList, setcolumnsHeaderList] = useState([]);
  const [columnsBodyList, setcolumnsBodyList] = useState([]);
  const [consulta, setconsulta] = useState(false);
  const [delayed, setDelayed] = useState(true);
  const [KpisProyectoManagentFullView, setKpisProyectoManagentFullView] = useState([]);
  const dataGridSummary = useRef(null);
  const [pageKPIManagementFullViewIndex, setpageKPIManagementFullViewIndex] = useState({}); 

  useEffect(() => {
    obtenerProyectoKPIManagementFullView(props.ClaProyecto === undefined ? 0 : props.ClaProyecto);
  }, [props.ClaProyecto]);

  const obtenerProyectoKPIManagementFullView = (ClaProyecto) => {
    const GetProyectoKPIManagementFullView = `${config.UrlApiProject}ScoreCard/GetProyectoKPIManagementFullView`;
    const paramsToService = {
      ClaProyecto,
    };
    callApi(GetProyectoKPIManagementFullView, 'POST', paramsToService, (response) => {     
      if (response.data.columns.length > 0) { 
        response.data.columns[0].fixed = true;
        response.data.columns[0].fixedPosition = 'left';
        response.data.columns[0].headerFilter = {
          allowSearch: true,
        };
        response.data.columns[1].fixed = true;
        response.data.columns[1].fixedPosition = 'left';
        response.data.columns[1].headerFilter = {
          allowSearch: true,
        };
      }
      setKpisProyectoManagentFullView(response.data); 
      
      
      localStorage.removeItem('lsProyectoKPIManagementFullView');
      // setcolumnsHeaderList(response.data.HeaderColumns);
      // setcolumnsBodyList(response.data.BodyColumns);
    });
  };
  /*
  const dynamicColumns = () => {
    
    return columnsHeaderList && columnsHeaderList.length > 0 ? (
      columnsHeaderList.map((col, i) => <Column header={col.Name} />)
    ) : (
      <Column header="Hola" />
    );
  };
  */
  // const dynamicHeaderGroup =
  // columnsHeaderList.forEach(element => {
  //    return  console.log(element);
  //   });

  /*
  const dynamicColumns = columnsHeaderList.map((col, i) => {
    return (<Column header={col.Header} field={col.Column} columnKey={col.Column} />);
  }); 
 */

  const dynamicColumns = columnsHeaderList.map((col, i) => {
    if (col.colspan === null){
      return <Column header={col.header} field={col.field} columnKey={col.columnkey} headerStyle={{ width: '185px' }} />;
    }
    return <></>
    
  });

  return (
    <>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          { 
            typeof(KpisProyectoManagentFullView.columns) === 'object' && KpisProyectoManagentFullView.columns.length > 0 ?
            (
              <DataGrid
                allowColumnReordering={true}
                dataSource={KpisProyectoManagentFullView.rows}
                defaultColumns={KpisProyectoManagentFullView.columns}
                showBorders={true}
                rowAlternationEnabled={true}
                height={470}
                defaultPageSize={150}
                noDataText={t('common:NoData')} 
                allowSorting={false}
                sorting={false}
              >
                <StateStoring enabled={true} type="localStorage" storageKey="lsProyectoKPIManagementFullView" />
                <HeaderFilter visible={true} />
                <ColumnFixing enabled={false} />
                <Paging defaultPageSize={150} pageIndex={pageKPIManagementFullViewIndex} />
              </DataGrid>
)
            :
            (<></>)
          } 
        </Col>
      </Row>
    </>
  );
};

export default ProyectoKPIManagementFullView;

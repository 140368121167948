/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { PickList } from 'primereact/picklist';
import {
  TreeList,
  Scrolling,
  Paging,
  Pager,
  Column,
  Selection,
  Format,
  SearchPanel,
} from 'devextreme-react/tree-list';
import { config } from '../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../utils/utils';
import moment from 'moment';
import { Link } from 'react-router-dom';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldText from 'components/Controls/FieldText';
import FieldRadioButton from '../../../components/Controls/FieldRadioButton/FieldRadioButton.jsx';
import { Divider } from 'primereact/divider';
import { Panel } from 'primereact/panel';
import ActionPlanScoreSquare from './FocusReport/ActionPlanScoreSquare';
import PuestoIndicadorResultadoMensual from './PuestoIndicadorResultadoMensual';
import { TrafficOutlined } from '@material-ui/icons';
import PageContent from 'layouts/PageContent';
import OrganizationalPositionView from './OrganizationalPositionView';
import PuestoIndicadorObjetivos from './PuestoIndicadorObjetivos';

const PuestoIndicadorScreen = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['puestoIndicador', 'common']);
  const [OpcionesAnio, setOpcionesAnio] = useState([]);
  const [ClaAnio, setClaAnio] = useState(null);
  const [MostrarEstatusMes, setMostrarEstatusMes] = useState(false);
  const [MostrarObjetivo, setMostrarObjetivo] = useState(false);
  const [MostrarMain, setMostrarMain] = useState(false);
  const [ClaPuesto, setClaPuesto] = useState(null);
  const [Acction, setAccion] = useState(1);
  const [refreshResultadoMensual, setrefreshResultadoMensual] = useState(1);
  const [refreshIndicadorObjetivos, setrefreshIndicadorObjetivos] = useState(1);


  useEffect(() => { 
    
  }, []);

  useEffect(() => { 
    const paramsService = {}; 
    
    const GetCurrentPeriodScoreCard = `${config.UrlApiProject}ScoreCard/GetScorecardUserDefaultInfo`; 
        
    callApi(GetCurrentPeriodScoreCard, 'GET', paramsService, (response) => {  
        // setClaAnio(response.data.ClaAnio);
        setClaPuesto(response.data.ClaPuesto);
        InitCombos(); 
    });   
}, []);


  const InitCombos = () => {
    ObtenerAnio();
  };
  const ObtenerAnio = () => {
    
    const paramsService = {
      MostrarSoloCerrados : 0
    }; 
      
    const GetAniosScoreCard = `${config.UrlApiProject}ScoreCard/GetAniosScoreCard`; 
        
    callApi(GetAniosScoreCard, 'Post', paramsService, (response) => {
      setOpcionesAnio(response.data.Anios);  

      // asignamos por default el primero, ya que es el anio mas reciente...
      if (response.data.Anios.length > 0){ 
        setClaAnio(response.data.Anios[0].ClaAnio); 
      }
    });   
    
  }; 

  const onClaAnioChange = (props, e) => {    
    setClaAnio(e.value);
  };

 const onCapturaEstatusMesual = ()=> {
    setAccion(1);
 }

 const onCapturaObjetivo = ()=> { 
  setAccion(2);
}

const onChangeOrganizationalValue = (itemValue, itemNode)=> { 
  // console.log(itemNode);
  setClaPuesto(itemNode.claPuesto);
}

const onRefreshResultadoMensual = ()=> {  
  setrefreshResultadoMensual(refreshResultadoMensual+1);
} 

const onRefreshIndicadorObjetivos = ()=> {  
  setrefreshIndicadorObjetivos(refreshIndicadorObjetivos+1);
}

 const organizationalView = (
   <div className='organization-default-width'>
     <OrganizationalPositionView showPositionName={true} onChange={(itemValue, itemNode) => onChangeOrganizationalValue(itemValue, itemNode)}></OrganizationalPositionView> 
   </div>
)

  return (
    <>
      <PageContent title={Acction === 1 ? t('puestoIndicador:Month'): (Acction === 2 ? t('puestoIndicador:Objective'): t('puestoIndicador:ScreenName'))} toolBar={organizationalView}>
        <Card>
          <CardBody>
            <Row className="align-items-start mt-3">
              <Col lg={4} md={4} sm={12} xs={12}>
                <div className="p-fluid">
                  <FieldDropdown
                    name="ClaAnio"
                    value={ClaAnio}
                    options={OpcionesAnio}
                    optionValue="ClaAnio"
                    optionLabel="NomAnio"
                    label={t('puestoIndicador:Anio')}
                    onChange={(e) => onClaAnioChange(props, e)} 
                    showClear={false}
                  />
                </div>
              </Col> 
              <Col lg={8} md={8} sm={12} xs={12} className="text-right">
                <FieldButton   
                  className={`${Acction === 1 ? "light-purple" : "light"} border-none btn-shadow`}
                  onClick={()=> onCapturaEstatusMesual()}
                  label={t('puestoIndicador:Month')}
                  icon="fas fa-clipboard-list" 
                > 
                </FieldButton>  
                &nbsp;    
                <FieldButton  
                  className={`${Acction === 2 ? "light-purple" : "light"} border-none btn-shadow`}
                  onClick={()=> onCapturaObjetivo()} 
                  label={t('puestoIndicador:Objective')}
                  icon="fas fa-address-book" 
                > 
                </FieldButton> 
              </Col> 
            </Row>    
          </CardBody>
        </Card>   
        <Row hidden={Acction!==1}>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Card>
              <CardBody> 
                <PuestoIndicadorResultadoMensual refresh={refreshResultadoMensual} onChange={onRefreshIndicadorObjetivos} ClaAnio={ClaAnio} ClaPuesto={ClaPuesto}></PuestoIndicadorResultadoMensual>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row hidden={Acction!==2}>
          <Col lg={12} md={12} sm={12} xs={12}>
            <PuestoIndicadorObjetivos refresh={refreshIndicadorObjetivos} onChange={onRefreshResultadoMensual} ClaAnio={ClaAnio} ClaPuesto={ClaPuesto}></PuestoIndicadorObjetivos>
          </Col>
        </Row>
      </PageContent>       
    </>
  );
};
export default PuestoIndicadorScreen;

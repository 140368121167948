/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable object-shorthand */
import EditorText from 'components/Controls/EditorText/EditorText';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import FieldText from 'components/Controls/FieldText';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import YupValidator from '../../../../utils/YupValidator';
import { Row, Col } from 'reactstrap';
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../../utils/utils';

const SurveyManagementHeadSurveyEdit = (props) => {
  const { t } = useTranslation(['survey', 'common']);  
  const [IdEncuesta, setIdEncuesta] = useState(null);
  const [Titulo, setTitulo] = useState(null);
  const [Descripcion, setDescripcion] = useState(null);
  const [DescripcionHtml, setDescripcionHtml] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setIdEncuesta(props.IdEncuesta === undefined ? null : props.IdEncuesta);
    setTitulo(props.Titulo === undefined ? null : props.Titulo);
    setDescripcion(props.Descripcion === undefined ? null : props.Descripcion);
    setDescripcionHtml(props.DescripcionHtml === undefined ? null : props.DescripcionHtml);
    console.log(props.IdEncuesta, props.Titulo, props.Descripcion);
  }, [props.IdEncuesta, props.Titulo, props.Descripcion]);

  const onClicClosedEdit = () => {
    if (
      props.onClickCloseEditarSurvey !== null &&
      props.onClickCloseEditarSurvey !== 'undefined' &&
      typeof props.onClickCloseEditarSurvey === 'function'
    ) {
      props.onClickCloseEditarSurvey();
    }
  };
  const onTituloChange = (value) => {
    const newErrors = { ...errors };
    delete newErrors.Titulo;
    setErrors(newErrors);

    setTitulo(value);
  };

  const onEditorDescripcionChange = (ehtml, etext) => {
    setDescripcion(etext);
    setDescripcionHtml(ehtml);
  };

  const onSaveClick = async () => {
    const value = { 
      Titulo 
    };

    const validator = new YupValidator(valitationSchemeSave);
    const esValido = await validator.validate(value);
    if (!esValido) {
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      return;
    }
    const paramsService = {
      IdEncuesta,
      Titulo,
      Descripcion,
      DescripcionHtml
    };

    const SaveSurvey = `${config.UrlApiProject}HR/SaveSurvey`;

    callApi(SaveSurvey, 'POST', paramsService, (response) => {
      if (props.onClickCloseEditarSurvey !== null && typeof props.onClickCloseEditarSurvey === 'function') {
        props.onClickCloseEditarSurvey();
      }
    });
  };
  const valitationSchemeSave = yup.object().shape({
    Titulo: yup.string().required(t('common:RequiredField')).nullable(),
  });

  return (
    <>
      <div className='survey-edit-container'>
        <div className='survey-edit-body'>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
            &nbsp;
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <span className="survey-edit-label">{t('survey:Title')}</span>
              <div className="p-fluid">
                <FieldText
                  name="Titulo"
                  value={Titulo}
                  onChange={(e) => onTituloChange(e.target.value)}
                  autoFocus
                  placeholder={t('survey:SurveyTile')}
                  maxLength={500}
                  errors={errors}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
            &nbsp;
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
              <span className="survey-edit-label">{t('survey:SurveyDescription')}</span>
              <EditorText
                name="Descripcion"
                style={{ height: '120px' }}
                value={DescripcionHtml} 
                onPaste={onEditorDescripcionChange}
                onTextChange={(e) => onEditorDescripcionChange(e.htmlValue, e.textValue)}
              >
              </EditorText>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
            &nbsp;
            </Col>
          </Row>
        </div>
        <div className='survey-toolbar-options'>
          <Row>
            <Col lg={6} md={6} sm={12} xs={12}>
              <FieldButton 
                icon="fas fa-times"
                label={t('common:Cancel')}
                className="warning no-border p-mr-2 rounded-button"
              // onClick={onCancel}
                onClick={onClicClosedEdit}
              />
            &nbsp;
              <FieldButton
                label={t('common:Save')}
                icon="pi pi-save"
                className="p-button-success no-border p-mr-2 rounded-button"
                onClick={onSaveClick}
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default SurveyManagementHeadSurveyEdit;

/* eslint-disable jsx-a11y/label-has-associated-control */
import PageContent from 'layouts/PageContent';
import React, { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import DAISeerTopicsSelector from './DAISeerTopicsSelector/DAISeerTopicsSelector';
import DAISeerConversation from './DAISeerConversation/DAISeerConversation';
import { scrollToTop } from 'utils/utils';
import moment from 'moment';

const DAISeerScreen = (props) => {
  const screenModes = {
    SELECT_TOPIC: 1,
    CHAT: 2
  }
  const {t, i18n} = useTranslation(['daiSeer', 'common']);
  const [title, setTitle] = useState('');
  const [date, setDate] = useState(moment());
  const [topic, setTopic] = useState(null);
  const [screenMode, setScreenMode] = useState(null);

  const switchToSelectTopicMode = () => {
    setTopic(null);
    setTitle(t('daiSeer:WelcomeToDAISeer'));
    setScreenMode(screenModes.SELECT_TOPIC);
  };

  const switchToChatMode = (topic) => {
    setTopic(topic);
    setTitle(t('daiSeer:CurrentTopic', {
      topic: topic.NomDAISeerTopic
    }));
    setScreenMode(screenModes.CHAT); 
  };

  const onTopicSelected = (topic) => {      
    switchToChatMode(topic);
  };

  const onChooseNewTopicPress = () => {        
    switchToSelectTopicMode();
  };

  const onQuestionAdded = () => {        
    scrollToTop();
  };
  
  const headerTemplate = (
    <>    
      <h2>
        {title}
        <small className='float-right'>
          {date.format("MMMM Do YYYY, h:mm:ss a")}
        </small>
      </h2> 
    </>
  );

  const step1SelectTopicTemplate = (
    <>
      <Row>
        <Col> 
          <DAISeerTopicsSelector
            onTopicSelected={onTopicSelected}
          />          
        </Col> 
      </Row>
    </>
  );

  const step2ChatTemplate = (
    <>
      <Row>
        <Col> 
          <DAISeerConversation 
            topic={topic}
            onChooseNewTopicPress={onChooseNewTopicPress}
            onQuestionAdded={onQuestionAdded}
          />
        </Col> 
      </Row>   
    </>
  );

  useEffect(() => {    
    switchToSelectTopicMode();         
  }, []);

  return (
    <PageContent title={t('daiSeer:DAISeer')}>
      <Row>
        <Col>  
          {headerTemplate}
        </Col> 
      </Row>
      <Row>
        <Col>
          {screenMode === screenModes.SELECT_TOPIC && step1SelectTopicTemplate}
          {screenMode === screenModes.CHAT && step2ChatTemplate}        
        </Col>
      </Row>
    </PageContent>
  )
}

export default DAISeerScreen
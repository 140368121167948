import React, { useEffect, useContext, useState, useRef } from "react";
import { Button, Row, Col } from 'reactstrap';
import { Badge } from 'primereact/badge';
import { AppContext } from 'context/AppContext';
import { config } from '../../utils/config';
import {  setSessionData, 
    callApi,
    getSessionItem,
    sessionAlive
} from '../../utils/utils'; 
import useSound from 'use-sound';
import alert_sound from '../../assets/media/alert_sound.wav';
import { OverlayPanel } from 'primereact/overlaypanel';
import './Alerts.css';
import moment from "moment"; 
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Alerts  = () => {
    
    const { t, i18n } = useTranslation(['common']);

    const buttonRef = useRef(null);
    const op = useRef(null);
    
    const [ listadoAlertas, setListadoAlertas ] = useState([]);
    const [ newAlert, setNewAlert ] = useState(false);

    const { forceRefresh, refreshAlerts } = useContext(AppContext);
    const visibilityListenerAdded = useRef(false);


    let currentAlerts =  getSessionItem('TotalAlertas', 0)
    const interval = useRef();

    useEffect(() => {    
        if (forceRefresh !== null && forceRefresh > 0){ 
          getAlerts();
        }
        
    }, [forceRefresh]); 

    useEffect(() => {     
        
        if (!visibilityListenerAdded.current) {
            window.addEventListener('visibilitychange', () => {   
              if(document.hidden) { 
                clearInterval(interval.current); 
              }
              else {    
                getAlerts();
              }
            });

            visibilityListenerAdded.current = true;
        }
      
        
          
        interval.current = setInterval(() => {  
              getAlerts();
            }, 60000);
        
            return () => {
              if (interval.current) {
                clearTimeout(interval.current);  
              }  
            }  
    }, []); 
 

    
    const [play, { stop }] = useSound(
        alert_sound 
    );

    const removeAlert = (item) => {
      const LeerAlerta = `${config.UrlApiProject}Seguridad/LeerAlerta?IdNotificacionAlerta=${item.IdNotificacion}`;  
      const paramsToService = {};   

      callApi(LeerAlerta, 'POST', paramsToService, (response) => {   
          
        getAlerts();
          
      }); 

      
    }

    const getAlerts = () => {
        const GetStatusFasesActividad = `${config.UrlApiProject}Seguridad/GetAlerts`;  
        const paramsToService = {};   
        
        // si detectamos en el navegador que no tiene internet
        try{
          if (!window.navigator.onLine){
            return ;
          }
        }catch(e){
          // 
        } 

        // si no tenemos conexion no es necesario ir a verificar las alertas
        if (sessionAlive()){
            callApi(GetStatusFasesActividad, 'GET', paramsToService, (response) => {   
              
              currentAlerts =  getSessionItem('TotalAlertas', 0)

              setSessionData({ TotalAlertas: response.data.Alertas.length });
              if (response.data.Alertas.length > currentAlerts){ 
                  buttonRef.current.onClick();
              } 

              setListadoAlertas(response.data.Alertas);  
              
              
          }, () => {
            // no hacemos nada solo  para evitar de mandar el mensaje si algun momento se pierde la conexion...
          }, false, false); 
        } 
    }
 
    
    return (
      <> 
        <Button
          className="layout-topbar-action"
          type="button"
          color=" "
          onClick={(e) => { op.current.toggle(e)  }}
        >
          <span className="btn-inner--icon">
            <i 
              className="fa fa-bell p-text-secondary p-overlay-badge"
              style={{ fontSize: '1.3rem' }}
            > 
              { listadoAlertas.length === 0 ? <></> : <Badge value={listadoAlertas.length} severity="warning"></Badge>} 
            </i>
          </span>
        </Button>  
        <OverlayPanel ref={op} style={{width: '300px'}} className="alert_panel"> 
          <div className="pb-2">
            <span className="alert_notifications_summary">You have <b>{listadoAlertas.length}</b> new notifications</span>
          </div>
          <div className="text-center">
            { listadoAlertas.length === 0 ? ( 
              <i 
                className="fa fa-bell-slash p-text-secondary "
                style={{ fontSize: '3rem' }}
              />  
           ) : (<></>)}
          </div>
          {listadoAlertas.map((element, index) => { 

              if (element.ClaTipoNotificacion === 1) {
                return (
                  <div key={index} className={index+1 !== listadoAlertas.length ? "alert_notification" : "alert_notification_last"}>
                    <Link to={{ pathname: element.RedireccionarA }}> 
                      <div className="alert_title">
                        {element.TituloNotificacion}
                      </div>
                    
                      <div className="alert_descripcion">
                        {element.DescripcionNotificacion}
                      </div> 
                    </Link>
                    <div className="alert_date text-right pb-1">
                      { moment(element.FechaNotificacion).format("LL") }  
                    </div>
                  </div>
                  )
            }
            
            if (element.RedireccionarA !== null && element.RedireccionarA.length > 0){
              return (
                <div key={index} className={index+1 !== listadoAlertas.length ? "alert_notification" : "alert_notification_last"}>
                  <Link to={{ pathname: element.RedireccionarA }} target="_blank"> 
                    <div className="alert_title">
                      {element.TituloNotificacion}
                    </div>
                  
                    <div className="alert_descripcion">
                      {element.DescripcionNotificacion}
                    </div> 
                  </Link>
                  <Row>
                    <Col>
                      <div className="alert_date text-left">
                        { moment(element.FechaNotificacion).format("LL") }  
                      </div>
                    </Col>
                    <Col>
                      <div className="text-right Cursor" onClick={() => { removeAlert(element)}}>
                        <i className="fas fa-trash-alt"></i>&nbsp;{t('common:Remove')}  
                      </div>
                    </Col>
                  </Row>
                  
                  
                </div>
                )  
            }
            
              return (
                <div key={index} className={index+1 !== listadoAlertas.length ? "alert_notification" : "alert_notification_last"}> 
                  <div className="alert_title">
                    {element.TituloNotificacion}
                  </div>
                  
                  <div className="alert_descripcion">
                    {element.DescripcionNotificacion}
                  </div>  
                  <Row>
                    <Col>
                      <div className="alert_date text-left">
                        { moment(element.FechaNotificacion).format("LL") }  
                      </div>
                    </Col>
                    <Col>
                      <div className="text-right Cursor" onClick={() => { removeAlert(element)}}>
                        <i className="fas fa-trash-alt"></i>&nbsp;{t('common:Remove')}  
                      </div>
                    </Col>
                  </Row>
                  
                  
                </div>
                )  
            
              
            })}
        </OverlayPanel>
        <Button ref={buttonRef} hidden={true} onClick={() => play()}>

        </Button>
      </>    
    );
}

export default Alerts
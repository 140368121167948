/* eslint-disable jsx-a11y/label-has-associated-control */
import React  from 'react';      
import { AutoComplete } from 'primereact/autocomplete'; 

const FieldAutoComplete =  (props) => {
    
    const onChange = (value) => {   
        if (props.onChange !== null){
            if (value.value === ''){
                value.value = null;
            }
            
            props.onChange(value);
        } 
    }  

    const isFormFieldInValid = (name) => !!(typeof(props.errors) !== 'undefined' && props.errors !== null && props.errors[name]);
    
    const getFormErrorMessage = (name) => {   
        return isFormFieldInValid(name) && <small className="p-error">{props.errors[name].message}</small>;
    };

    const onFocus = (event) => {
      event.target.select();
      if (props.onFocus !== null && typeof(props.onFocus) === 'function'){
        props.onFocus(event);
      } 
    }

    return (  
      <>  
        <span className="p-float-label">
          <AutoComplete
            dropdown
            value={props.value} 
            onChange={onChange}  
            suggestions={props.suggestions}
            field={props.field}
            completeMethod={props.completeMethod}
            className={isFormFieldInValid(props.name) === true ? 'p-invalid' : ''}
            onFocus={onFocus}
            disabled={props.disabled}
          />
          <label className={isFormFieldInValid(props.name) === true ? 'p-error' : ''}>{props.label}</label>
        </span>
        {getFormErrorMessage(props.name)}
      </> 
    )
}   

    
export default FieldAutoComplete; 



import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { config } from 'utils/config';
import { callApi } from 'utils/utils';
import CompanyOverviewUtilizationChartSkeleton from './CompanyOverviewUtilizationChartSkeleton';

const CompanyOverviewUtilizationChart = (props) => {
  const {t, i18n} = useTranslation(['companyOverview', 'common']);  
  const [Loading, setLoading] = useState(true);
  const [ChartCategories, setChartCategories] = useState([]);
  const [ChartSeries, setChartSeries] = useState([]);
  const [TotalUtilization, setTotalUtilization] = useState(0);
  const [TotalDifference, setTotalDifference] = useState(0);
  const [ChartStep, setChartStep] = useState(0);  
  const chartOptions = {
    chart: {
      height: 150
    },
    title: {
      text: ''      
    },  
    plotOptions: {
      series: {
        lineWidth: 1
      }
    },
    yAxis: {
      title: {
        text: ''
      },
      tickInterval: 50,   
      labels: {
        formatter: (item) => {          
          return `${item.value} %`;
        }
      }
    },  
    xAxis: {
      categories: ChartCategories,
      labels: {
        step: ChartStep
      }
    },  
    tooltip: {    
      valueSuffix: ' %'
    },
    legend: {
      enabled: false,
    },
    series: ChartSeries,   
  };

  const getReport = (
    ReportType,
    Shifts,
    StartDate,
    EndDate
  ) => {    
    setLoading(true);
    const url = `${config.UrlApiProject}Operations/GetCompanyOverviewUtilization`;       
    const paramsService = {
      ReportType,
      Shifts,
      StartDate: StartDate.toDateString(),
      EndDate: EndDate.toDateString()
    };
    console.log("paramsService",paramsService);
    callApi(url, 'POST', paramsService, (response) => {            
      setTotalUtilization(response.data.CompanyOverviewUtilizationSummary.TotalUtilization);
      setTotalDifference(response.data.CompanyOverviewUtilizationSummary.TotalDifference);
      setupChart(response.data.CompanyOverviewUtilization, response.data.CompanyOverviewUtilizationSummary.ChartStep);      
      setLoading(false);
    }, (error) => {
      setLoading(false);
      console.error("Error getting company overview utilization", error);      
    }, false, false);
  };

  const setupChart = (report, step) => {
    let categories = [];
    const series = [];    
    if (report) {      
      categories = report.map(({ DateName }) => DateName);          
      series.push({
        name: t('companyOverview:Utilization'),
        data: report.map(({ Utilization }) => Utilization),
        color: 'black'
      });
      series.push({
        name: '',
        data: report.map(({ Goal }) => Goal),
        dashStyle: 'Dash',
        color: 'black',
        marker: {
          enabled: false,
        }
      });
    }        
    setChartCategories(categories);
    setChartSeries(series);  
    setChartStep(step);  
  };

  useEffect(() => {      
    if (props.reportType && props.shifts && props.startDate && props.endDate) {
      getReport(props.reportType, props.shifts, props.startDate, props.endDate);   
    }    
  }, [
    props.refresh
  ]);

  const summaryTemplate = (
    <>
      <h5>
        {t('companyOverview:Utilization')}
      </h5>      
      <h1>
        {`${TotalUtilization} %`}
      </h1>
      {/* <p>
        <span className={`p-badge ${TotalDifference < 0? 'danger': 'success'}`}> 
          {`${TotalDifference < 0? TotalDifference*-1: TotalDifference} %`}&nbsp;
          <i className={`fas fa-arrow-${TotalDifference < 0? 'down': 'up'}`}></i>
        </span>
      </p> */}
    </>
  );

  const chartTemplate = (
    <>
      <HighchartsReact
        highcharts={Highcharts} 
        options={chartOptions} 
      />    
    </>
  );

  return (
    <>
      {
        Loading? <CompanyOverviewUtilizationChartSkeleton /> : (
          <Card style={{borderRadius: '1%'}}>
            <CardBody>
              <Row className='align-items-start'>
                <Col xs={2}> 
                  {summaryTemplate}
                </Col>
                <Col xs={10}>
                  {chartTemplate}                     
                </Col>
              </Row>
            </CardBody>
          </Card>  
        )
      } 
    </>
  )
}

export default CompanyOverviewUtilizationChart
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  ListGroupItem,
  ListGroup,
  Progress,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from 'reactstrap';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { Badge } from 'primereact/badge';
// import FeedbackCoaching from './FeedbackCoaching.jsx';
import { Link } from 'react-router-dom';
import ActionPlanScoreSquare from './ActionPlanScoreSquare.jsx';
import { Toolbar } from 'primereact/toolbar';
import Highcharts from 'highcharts';
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert, tracker } from '../../../../utils/utils';
import { Dialog } from 'primereact/dialog';
import ActionPlanMonthSummary from './ActionPlanMonthSummary.jsx';
import { element } from 'prop-types';

const ActionPlanAnnualSummary = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);
  const [ClaPuesto, setClaPuesto] = useState(null);
  const [ClaAnio, setClaAnio] = useState(null);
  const [ClaMes, setClaMes] = useState(null);
  const [ActionPlanList, setActionPlanList] = useState([]);
  const [ActionPlanAnnualResult, setActionPlanAnnualResult] = useState(null);
  const [EsActionPlan, setEsActionPlan] = useState(false);

  useEffect(() => {
    setClaPuesto(props.ClaPuesto === undefined ? 0 : props.ClaPuesto);
    setClaAnio(props.ClaAnio === undefined ? 0 : props.ClaAnio);
    GetActionPlanResultadoAnualPuesto();
  }, [props.ClaPuesto, props.ClaAnio]);

  const GetActionPlanResultadoAnualPuesto = () => {
    const getActionPlanResultadoAnualPuesto = `${config.UrlApiProject}ScoreCard/GetActionPlanResultadoAnualPuesto`;
    const paramsToService = {
      ClaPuesto: props.ClaPuesto === undefined ? 0 : props.ClaPuesto,
      ClaAnio: props.ClaAnio === undefined ? 0 : props.ClaAnio,
    };
    callApi(getActionPlanResultadoAnualPuesto, 'POST', paramsToService, (response) => {
      setActionPlanAnnualResult(
        response.data.ActionPlan.length > 0
          ? response.data.ActionPlan[0].ActionPlanAnnualResult
          : null
      );
      setActionPlanList(response.data.ActionPlanDetail);
    });
  };

  const leftToolbarTemplate = () => {
    return (
      <>
        <h3 className="title-ActionPlan">{t('focusReport:ActionPlan')}</h3>
      </>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <h3>
          {' '}
          <span className="title-ActionPlan">{t('focusReport:AnnualAvg')}: </span>{' '}
          {Highcharts.numberFormat(ActionPlanAnnualResult, 2)}
        </h3>
      </>
    );
  };
  const onViewActionPlan = (pClaMes, element) => { 

    if (element.Resultado === null){
      return;
    }

    setClaMes(pClaMes);
    setEsActionPlan(!EsActionPlan);

    tracker.trackEvent({
      type: 'ActionPlanAnnualSummary', /* component name */
      action: 'CLICK_MONTH_DETAIL'
    }) 
  };

  const dialogStyle = {
    width: '80vw',
  };
  const closeModalActionPlan = () => {
    setEsActionPlan(false);
  };
  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
            </Col>
          </Row>
          <Row className="align-items-start ">
            <Col lg={12} md={12} sm={12} xs={12}>
              {ActionPlanList.map((element, index) => {
                return (
                  <div
                    key={index}
                    style={{ paddingRight: '10px', float: 'left' }}
                    onClick={() => onViewActionPlan(element.ClaMes, element)}
                    className={element.Resultado === null ? '' :'Cursor'}
                  >
                    <ActionPlanScoreSquare
                      title={element.NomMes}
                      score={element.Resultado}
                      severity={element.ResultadoNivel}
                    >
                    </ActionPlanScoreSquare>
                  </div>
                );
              })}
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Dialog
        visible={EsActionPlan} 
        style={dialogStyle}
        modal
        className="dialog-transparent"
        contentClassName="dialog-content-transparent"
        closable={false}
        onHide={closeModalActionPlan}
      > 
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
            <ActionPlanMonthSummary
              ClaPuesto={ClaPuesto}
              ClaMes={ClaMes}
              ClaAnio={ClaAnio}
              showCloseButton={true}
              onCloseClick={closeModalActionPlan}
            >
            </ActionPlanMonthSummary>
          </Col>
        </Row>
      </Dialog>
    </>
  );
};

export default ActionPlanAnnualSummary;

/* eslint-disable jsx-a11y/label-has-associated-control */
import { Dialog } from 'primereact/dialog';
import React from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTranslation } from 'react-i18next';

// Props:
// - question
// - visible
// - onHide
const DAISeerThoughtsDialog = (props) => {
  const {t, i18n} = useTranslation(['daiSeer', 'common']);  

  const dialogHeader = () => {
    return (
      <>
        <h1>
          {props.question.Question}
        </h1>
      </>
    )
  }

  const dialogFooter = () => {
    return <></>;
  }

  const dialogTemplate = (
    <>
      <Dialog
        visible={props.visible}
        modal
        style={{ width: '60vw' }}
        footer={dialogFooter}
        header={dialogHeader} 
        closable={true}
        onHide={props.onHide}        
        maximized={false}
        draggable={true}
      >        
        <p>
          Thoughts...
        </p>
      </Dialog>
    </>
  );

  return (
    <>
      {dialogTemplate}
    </>
  )
}

export default DAISeerThoughtsDialog
import React, { useState, useRef, useEffect, useContext  } from 'react'; 
import { AppContext } from 'context/AppContext'; 
import { config } from '../../../utils/config';
import { callApi, showSweetAlert} from '../../../utils/utils'; 
import { useTranslation } from 'react-i18next'; 
import PageContent from 'layouts/PageContent';  
import { Row,Col,Card, CardHeader, CardTitle, CardBody, CardFooter} from 'reactstrap';  
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import FieldCalendar from '../../../components/Controls/FieldCalendar';
import FieldCheckbox from '../../../components/Controls/FieldCheckbox.jsx' 
import FieldButton from '../../../components/Controls/FieldButton/FieldButton';
import { Sidebar } from 'primereact/sidebar';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Dialog } from 'primereact/dialog';
import FieldDateRange from '../../../components/Controls/FieldDateRange/FieldDateRange';
import notassigned from '../../../assets/img/icons/notassigned.png'
import assigned from '../../../assets/img/icons/assigned.png'
import done from '../../../assets/img/icons/done.png'
import moment from 'moment';
import MTDPerformanceReportBacklog from './MTDPerformanceReportBacklog';
import MTDPerformanceReportDetail from './MTDPerformanceReportDetail';
import './MTDPerformanceReportScreen.scss';  
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';

const MTDPerformanceReportScreen = (props) => {  
  const { t } = useTranslation(['mtd', 'common']);
  const [workOrdersPieChartSeries, setworkOrdersPieChartSeries] = useState([]);
  const [workOrdersByDeptCategories, setworkOrdersByDeptCategories] = useState([]);
  const [workOrdersByDeptSeries, setworkOrdersByDeptSeries] = useState([]);
  const [workOrdersCardPieSeries, setworkOrdersCardPieSeries] = useState([]);
  const [assignedTechBarChartCategories, setassignedTechBarChartCategories] = useState([]);
  const [assignedTechBarChartSeries, setassignedTechBarChartSeries] = useState([]);
  const [workOrdersOverdueSeries, setworkOrdersOverdueSeries] = useState([]);
  const [workOrdersOverdueCategories, setworkOrdersOverdueCategories] = useState([]);
  const [techTypeChartCategories, settechTypeChartCategories] = useState([]);
  const [techTypeChartSeries, settechTypeChartSeries] = useState([]);  
  const [TypeTechInfo, setTypeTechInfo] = useState([]);  
  const [filterDateRange, setfilterDateRange] = useState([]);
  const [filterBuildingList, setfilterBuildingList] = useState([]);
  const [filterTecAssignedList, setfilterTecAssignedList] = useState([]);
  const [filterTecTypeList, setfilterTecTypeList] = useState([]);
  const [EsByBuildingSelectedAll, setEsByBuildingSelectedAll] = useState(true);
  const [EsByTechAssignedSelectedAll, setEsByTechAssignedSelectedAll] = useState(true);
  const [EsByTechTypeSelectedAll, setEsByTechTypeSelectedAll] = useState(true);
  const [BuildingCount, setBuildingCount] = useState(null);
  const [BuildingEtiqueta, setBuildingEtiqueta] = useState(null);
  const [openWorkOrders, setopenWorkOrders] = useState(null);   
  const [MTTR, setMTTR] = useState(null);    
  const [workorderbacklogadministrator, setworkorderbacklogadministrator] = useState(null);  
  const [CountWorkOrderBackLogAdministrator, setCountWorkOrderBackLogAdministrator] = useState(null);  
  const [OverdueWorkOrders, setOverdueWorkOrders] = useState(null);       
  const [OverdueWorkOrdersTotal, setOverdueWorkOrdersTotal] = useState(null);     
  const [OverdueWorkOrdersTotalRest, setOverdueWorkOrdersTotalRest] = useState(null);  
  const [WorkOrderOntimeCompletion, setWorkOrderOntimeCompletion] = useState(null);       
  const [WorkOrderOntimeCompletionRest, setWorkOrderOntimeCompletionRest] = useState(null); 
  const [StartDateCurrentWeek, setStartDateCurrentWeek] = useState(null);
  const [EndDateCurrentWeek, setEndDateCurrentWeek] = useState(null); 
  const [errors, setErrors] = useState({});   
  const { viewportRefresh } = useContext(AppContext);
  const [Viewport, setViewport] = useState(viewportRefresh);
  const [isShowFilter, setisShowFilter] = useState(false);
  const [StartDate, setStartDate] = useState(new Date());
  const [EndDate, setEndDate] = useState(new Date());
  const [EsMostrarDialogBacklog, setEsMostrarDialogBacklog] = useState(false);
  const [EsMostrarDialogDetail, setEsMostrarDialogDetail] = useState(false);
  const [ClaEdificio, setClaEdificio] = useState(null);
  const [ClaAssignedTech, setClaAssignedTech] = useState(null);
  const [ClaTechType, setClaTechType] = useState(null);
  const [ClaAging, setClaAging] = useState(null);
  const [ClaEstatusOt, setClaEstatusOt] = useState(null); 
  const [TitleDetail, setTitleDetail] = useState(null); 
  const [SubTitleDetail, setSubTitleDetail] = useState(null); 
  const [IsOpenWorkOrders, setIsOpenWorkOrders] = useState(false); 
  const [IsOverdueWorkOrders, setIsOverdueWorkOrders] = useState(false); 
  const [IsOverdueWorkOrdersTotal, setIsOverdueWorkOrdersTotal] = useState(false); 
  const [IsOntimeWorkOrders, setIsOntimeWorkOrders] = useState(false); 
  const [IsOntimeWorkOrdersTotal, setIsOntimeWorkOrdersTotal] = useState(false); 
  const [IsWorkOrders, setIsWorkOrders] = useState(false); 
  const [IsCurrentWeekWorkOrders, setIsCurrentWeekWorkOrders] = useState(false); 
 
  useEffect(() => {
    GetMDTLoad(); 
  }, []);

  useEffect(() => {    
    setViewport(viewportRefresh); 
  }, [viewportRefresh]);
  

  const valitationScheme = yup.object().shape({
    filterBuildingList: yup.array().of(
      yup.object().shape({
        EsSeleccionado: yup.boolean().required(t('common:RequiredField')).nullable(),
      })
    ),
  });


  const onApplyFiltersClick = async () => { 
    const value = {
      filterBuildingList,
    };

    const validator = new YupValidator(valitationScheme);
    const esValido = await validator.validate(value);

    console.log(validator.errors);
    if (!esValido) {
      setErrors(validator.errors);
      return;
    }
    GetChartLoad(filterBuildingList,filterTecAssignedList,filterTecTypeList);
    closeSidebar();
  
  }

  const GetMDTLoad = () => { 
    const url = `${config.UrlApiProject}MMA/GetMDTPerformanceReportFilter`;  
    const paramsService= {};   
    callApi(url, 'GET', paramsService, (response) => {
      const buildingList = response.data.MDTInfoBuilding;
      const assignedTechList = response.data.MDTInfoAssignedTech;
      const techTypeList = response.data.MDTInfoTechType;
      setfilterBuildingList(buildingList);
      setfilterTecAssignedList(assignedTechList);
      setfilterTecTypeList(techTypeList); 
      GetChartLoad(buildingList,assignedTechList,techTypeList);
    });
  }

  const GetChartLoad = (buildingList,assignedTechList,techTypeList) => { 
    let sBuilding = '';
    let cBuilding = 0;
    for (let index = 0; index < buildingList.length; index++) {
      if(buildingList[index].EsSeleccionado){
        const element = buildingList[index].ClaEdificio;
        sBuilding = `${sBuilding + element},`;
        cBuilding +=1;
      }
    } 
    let sAssignedTech = '';
    for (let index = 0; index < assignedTechList.length; index++) {
      if(assignedTechList[index].EsSeleccionado){
        const element = assignedTechList[index].ClaTechAssigned;
        sAssignedTech = `${sAssignedTech + element},`;
      }
    } 
    let sTechType = '';
    for (let index = 0; index < techTypeList.length; index++) {
      if(techTypeList[index].EsSeleccionado){
        const element = techTypeList[index].ClaTechType;
        sTechType = `${sTechType + element},`;
      }
    }
    const paramsToService = {
      Building:sBuilding,
      AssignedTech:sAssignedTech,
      TechType:sTechType,
      RangeDateStart:  StartDate.toDateString(),
      RangeDateEnd:  EndDate.toDateString()
    }
    const url = `${config.UrlApiProject}MMA/GetMDTPerformanceReport`;
    callApi(url, 'POST', paramsToService, (response) => {
      const informationList = response.data.MDTDashboardPieInfo[0]; 
      const series = response.data.DepartamentSeries;
      const categories = response.data.DepartamentCategories;
      const seriesPerson = response.data.PersonSeries;
      const categoriesPerson = response.data.PersonCategories;
      const seriesTypeTech = response.data.TypeTechSeries;
      const categoriesTypeTech = response.data.TypeTechCategories;
      const dashboardInfo = response.data.MDTDashboardInfo[0];
      const techInfo = response.data.TypeTechInfo;
      getworkOrdersPieChartSeries(informationList);
      getworkOrdersByDeptSeries(series,categories);
      getOrdersOverdueSeries(seriesTypeTech,categoriesTypeTech);
      getassignedTechSeries(seriesPerson,categoriesPerson);
      gettypeTechSeries(seriesTypeTech,categoriesTypeTech);
      getworkOrdersCardPieSeries(dashboardInfo);
      setopenWorkOrders(dashboardInfo.OpenWorkOrders);
      setMTTR(dashboardInfo.MTTR);
      setTypeTechInfo(techInfo);
      setworkorderbacklogadministrator(dashboardInfo.WorkOrderBackLogAdministrator);
      setCountWorkOrderBackLogAdministrator(dashboardInfo.CountWorkOrderBackLogAdministrator);
      setOverdueWorkOrders(dashboardInfo.OverdueWorkOrders);  
      setOverdueWorkOrdersTotal(dashboardInfo.OverdueWorkOrdersTotal);   
      setOverdueWorkOrdersTotalRest(dashboardInfo.OverdueWorkOrdersTotalRest);   
      setWorkOrderOntimeCompletion(dashboardInfo.WorkOrderOntimeCompletion);  
      setWorkOrderOntimeCompletionRest(dashboardInfo.WorkOrderOntimeCompletionRest); 
      setStartDateCurrentWeek(dashboardInfo.StartDateCurrentWeek); 
      setEndDateCurrentWeek(dashboardInfo.EndDateCurrentWeek); 
      setBuildingCount(cBuilding);
      setBuildingEtiqueta(cBuilding>1?t('mtd:Buildings'):t('mtd:Building'));
    });   
  }

  const getOrdersOverdueSeries = (serieInfo,categoriesInfo) => {
    setworkOrdersOverdueSeries(serieInfo);
    setworkOrdersOverdueCategories(categoriesInfo); 
  };

  const getassignedTechSeries = (serieInfo,categoriesInfo) => {
    setassignedTechBarChartSeries(serieInfo);
    setassignedTechBarChartCategories(categoriesInfo); 
  };
  const gettypeTechSeries = (serieInfo,categoriesInfo) => {
    settechTypeChartSeries(serieInfo);
    settechTypeChartCategories(categoriesInfo); 
  };
  const getworkOrdersPieChartSeries2 = (informationList,capturedList,inProgressList,closedList) => {
    setworkOrdersPieChartSeries([{      
      data: [{
        name:t('mtd:NotAssigned'),
        color:"#17a2b8",
        y: (capturedList.length > 0) ? (capturedList.length / informationList.length) * 100 : 0
      }, {
        name:t('mtd:Assigned'), 
        color:"#ff8248",
        y: (inProgressList.length > 0) ? (inProgressList.length / informationList.length) * 100 : 0
      },{
        name:t('mtd:Done'),
        color:"#00cc85",
        y: (closedList.length > 0) ? (closedList.length / informationList.length) * 100 : 0
      }]
    }]);
  };
  const getworkOrdersPieChartSeries = (information) => {
    setworkOrdersPieChartSeries([{      
      data: [{
        id:1,
        name:t('mtd:NotAssigned'),
        color:"#17a2b8",
        y: information.NotAssigned
      }, {
        id:2,
        name:t('mtd:Assigned'), 
        color:"#ff8248",
        y: information.Assigned
      },{
        id:3,
        name:t('mtd:Done'),
        color:"#00cc85",
        y: information.Done
      }]
    }]);
  };
  const getworkOrdersByDeptSeries = (serieInfo,categoriesInfo) => {
    setworkOrdersByDeptSeries(serieInfo);
    setworkOrdersByDeptCategories(categoriesInfo); 
    // setWoByDeptChartCategories(["Bulk", "Paper Tape", "Collated", "Spare Equipment"]);    
  };
  const getworkOrdersCardPieSeries = (data) => {
    setworkOrdersCardPieSeries([{      
      data: [{
        id:1,
        name:t('mtd:NotAssigned'),
        color:"#17a2b8",
        y: data.CurrentWeekNotAssigned
      }, {
        id:2,
        name:t('mtd:Assigned'), 
        color:"#ff8248",
        y: data.CurrentWeekAssigned
      },{
        id:3,
        name:t('mtd:Done'),
        color:"#00cc85",
        y: data.CurrentWeekDone
      }]
    }]);
  }; 
  const onfilterDateRange2 = (value) => {  
    const newErrors = { ...errors }
    delete newErrors.filterDateRange;   
    setErrors(newErrors)
    setfilterDateRange(value);
  } 
  const onfilterDateRange = (startDate, endDate) => {    
     setStartDate(startDate);
     setEndDate(endDate);
  }
  const onfilterBuildingSelectedAll = (selected) => {  
    const opciones = [...filterBuildingList];
    for (let i = 0; i < opciones.length; i++) {
      opciones[i].EsSeleccionado = (!selected ? null : selected);
    }
    setErrors({});
    setfilterBuildingList(opciones);
    setEsByBuildingSelectedAll(!EsByBuildingSelectedAll);
  }

  const onfilterBuildingSelected = (e,index) => {  
    const opciones = [...filterBuildingList];
    opciones[index].EsSeleccionado = (!e.checked ? null : e.checked);
    let icheckedAll = 0;
    for (let i = 0; i < opciones.length; i++) {
      if(opciones[i].EsSeleccionado){
        icheckedAll +=1;
      }
    }
    if(icheckedAll===0){
      for (let i = 0; i < opciones.length; i++) {
        opciones[i].EsSeleccionado = null;
      }
    }else{
      for (let i = 0; i < opciones.length; i++) {
        if(opciones[i].EsSeleccionado === null){
          opciones[i].EsSeleccionado = false;
        }
      }
    }
    opciones[index].errors = null;
    setErrors({});
    setEsByBuildingSelectedAll(icheckedAll===opciones.length);
    setfilterBuildingList(opciones);
  }
  const onfilterTecAssignedSelectedAll = (selected) => {  
  const opciones = [...filterTecAssignedList];
  for (let i = 0; i < opciones.length; i++) {
    opciones[i].EsSeleccionado = selected;
  }
  setfilterTecAssignedList(opciones);
  setEsByTechAssignedSelectedAll(!EsByTechAssignedSelectedAll);
  }

  const onfilterTecAssignedSelected = (e,index) => {  
    const opciones = [...filterTecAssignedList];
    opciones[index].EsSeleccionado = e.checked;
    let icheckedAll = 0;
    for (let i = 0; i < opciones.length; i++) {
      if(opciones[i].EsSeleccionado){
        icheckedAll +=1;
      }
    }
    setEsByTechAssignedSelectedAll(icheckedAll===opciones.length);
    setfilterTecAssignedList(opciones);
  }

  const onfilterTecTypeSelectedAll = (selected) => {  
  const opciones = [...filterTecTypeList]; 
  for (let i = 0; i < opciones.length; i++) {
    opciones[i].EsSeleccionado = selected;
  }
  setfilterTecTypeList(opciones);
  setEsByTechTypeSelectedAll(!EsByTechTypeSelectedAll);
  } 
  
  const onfilterTecTypeSelected = (e,index) => {  
    const opciones = [...filterTecTypeList];
    opciones[index].EsSeleccionado = e.checked;
    let icheckedAll = 0;
    for (let i = 0; i < opciones.length; i++) {
      if(opciones[i].EsSeleccionado){
        icheckedAll +=1;
      }
    }
    setEsByTechTypeSelectedAll(icheckedAll===opciones.length);
    setfilterTecTypeList(opciones);
  } 

  const filterTemplate = (
    <> 
      <Row>
        <Col>
          <div className='titlefilter'>
            <span>
              {t('mtd:Filters')}
            </span>
          </div>
          <div className="p-fluid">
            <br></br>
          </div>
        </Col> 
      </Row>
      <Row>
        <Col>
          <div className='subtitlefilter'>
            <span>
              {t('mtd:WorkingPeriod')}
            </span>
          </div>
          <div className="p-fluid">
            <FieldDateRange
              startDate={StartDate}
              endDate={EndDate}
              onDateRangeChange={onfilterDateRange}
            >            
            </FieldDateRange>  
          </div>
        </Col> 
      </Row>  
      <Row>
        <Col>
          <div className='subtitlefilter'>
            <span>
              {t('mtd:ByBuilding')}
            </span>
          </div>
          <Row className="align-items-start">
            <Col lg={12} md={12} sm={12} xs={12}> 
              <Row className="align-items-start pt-2">
                <Col>
                  <span className='bold'>
                    <FieldCheckbox
                      checked={EsByBuildingSelectedAll} 
                      onChange={(e) => onfilterBuildingSelectedAll(!EsByBuildingSelectedAll)}
                    />
                    &nbsp;
                    &nbsp;
                    {t('hr:All')}
                  </span> 
                </Col>
              </Row>
            </Col> 
            {filterBuildingList.map((element, index) => {  
                    return (  
                      <Col lg={12} md={12} sm={12} xs={12} key={index}> 
                        <Row className="align-items-start pt-2">
                          <Col>
                            <span key={index}>
                              <FieldCheckbox 
                                name='EsSeleccionado'
                                inputId='EsSeleccionado'
                                checked={element.EsSeleccionado} 
                                onChange={(e) => onfilterBuildingSelected(e,index)}
                                errors={errors[`filterBuildingList[${index}]`]}
                              />
                                  &nbsp;
                                  &nbsp;
                              {element.NomEdificio}
                            </span> 
                          </Col>
                        </Row>
                      </Col> 
                    );
                })}  
          </Row>
        </Col>
      </Row>    
      <Row>
        <Col>
          <div className='subtitlefilter'>
            <span>
              {t('mtd:ByAssignedTech')} 
            </span>
          </div>
          <Row className="align-items-start">
            <Col lg={12} md={12} sm={12} xs={12}> 
              <Row className="align-items-start pt-2">
                <Col>
                  <span className='bold'>
                    <FieldCheckbox
                      checked={EsByTechAssignedSelectedAll} 
                      onChange={(e) => onfilterTecAssignedSelectedAll(!EsByTechAssignedSelectedAll)}
                    />
                    &nbsp;
                    &nbsp;
                    {t('hr:All')}
                  </span> 
                </Col>
              </Row>
            </Col> 
            {filterTecAssignedList.map((element, index) => {  
                    return (  
                      <Col lg={12} md={12} sm={12} xs={12} key={index}> 
                        <Row className="align-items-start pt-2">
                          <Col>
                            <span key={index}>
                              <FieldCheckbox
                                inputId={element.ClaTechAssigned}
                                checked={element.EsSeleccionado} 
                                onChange={(e) => onfilterTecAssignedSelected(e,index)}
                                label={element.NomTechAssigned}
                              />
                                  &nbsp;
                                  &nbsp;
                              {element.NomTechAssigned}
                            </span> 
                          </Col>
                        </Row>
                      </Col> 
                    );
                })}  
          </Row>
        </Col>
      </Row>  
      <Row>
        <Col>
          <div className='subtitlefilter'>
            <span>
              {t('mtd:ByTechType')}
            </span>
          </div>
          <Row className="align-items-start">
            <Col lg={12} md={12} sm={12} xs={12}> 
              <Row className="align-items-start pt-2">
                <Col>
                  <span className='bold'>
                    <FieldCheckbox
                      checked={EsByTechTypeSelectedAll} 
                      onChange={(e) => onfilterTecTypeSelectedAll(!EsByTechTypeSelectedAll)}
                    />
                    &nbsp;
                    &nbsp;
                    {t('hr:All')}
                  </span> 
                </Col>
              </Row>
            </Col> 
            {filterTecTypeList.map((element, index) => {  
                    return (  
                      <Col lg={12} md={12} sm={12} xs={12} key={index}> 
                        <Row className="align-items-start pt-2">
                          <Col>
                            <span key={index}>
                              <FieldCheckbox
                                inputId={element.ClaTechType}
                                checked={element.EsSeleccionado} 
                                onChange={(e) => onfilterTecTypeSelected(e,index)}
                                label={element.NomTechType}
                              />
                                  &nbsp;
                                  &nbsp;
                              {element.NomTechType}
                            </span> 
                          </Col>
                        </Row>
                      </Col> 
                    );
                })}  
          </Row>
        </Col>
      </Row> 
      <Row>
        <Col>
          <div className='subtitlefilter'>
            <span>
                  &nbsp;
            </span>
          </div>
        </Col>
      </Row>  
    </>
  );

  const workOrdersPieChartOptions = {
    chart: {
      type: 'pie',          
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    margin: [2, 0, 2, 0],
    height: "200px",
    title: {
      text: t('mtd:WorkOrders'),
      align: 'center'
    },
    subtitle: {
      text: ""
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    tooltip: {      
      pointFormat: '<b>{point.percentage:.1f}%</b>'            
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        },
        point: {
          events: {
            click(e){
              setClaEstatusOt(e.point.options.id); 
              setTitleDetail(t('mtd:WorkOrders'));
              setSubTitleDetail(t('mtd:Detail'));
              setClaEdificio(null);
              setClaTechType(null);
              setClaAssignedTech(null);
              setIsOpenWorkOrders(false);
              setIsOverdueWorkOrders(false);
              setIsOverdueWorkOrdersTotal(false);
              setIsOntimeWorkOrders(false);
              setIsOntimeWorkOrdersTotal(false);
              setIsWorkOrders(true);
              setEsMostrarDialogDetail(true);
            }
          }
        }
      }
    },
    series: workOrdersPieChartSeries
  };

  const workOrdersByDeptBarChartOptions = {
    chart: {
      type: 'bar',
    },
    title: {
      text: t('mtd:WorkordersByDept'),
      align: 'center'
    },
    subtitle: {
      text: ""
    },
    xAxis: {
      categories: workOrdersByDeptCategories,
      title: {
        text: null
      },
      gridLineWidth: 1,
      lineWidth: 0
    },
    yAxis: {
      min: 0,
      title: {
        text: "Work Orders"
      },
      gridLineWidth: 1,
      lineWidth: 0,
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color: 'blue',
          textOutline: 'none'
        }
      }               
    },
    tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: <b>{point.y}<b/>'
    },
    
    plotOptions: {
      bar: {          
        dataLabels: {
          enabled: true
        }
      },
      series: {
          point: {
          events: {
            click(e){
              setSubTitleDetail(null);
              const seriesName = e.point.series.name;
              let dataSerie = {};
              let ClaDepto = null;
              workOrdersByDeptSeries.forEach((item, index) => {
                if (item.name === seriesName) {
                  dataSerie = item;
                }
              });
              const infolist = dataSerie.info
              infolist.forEach((item, index) => {
                  if (item.NomDepartamento === e.point.category) {
                    ClaDepto = item.ClaDepartamento;
                    setSubTitleDetail(item.NomDepartamento);
                  }
                }); 
              setClaEdificio(ClaDepto);
              setClaEstatusOt(dataSerie.id);
              setClaAssignedTech(null);
              setClaTechType(null);
              setTitleDetail(t('mtd:WorkordersByDept')); 
              setIsOpenWorkOrders(false);
              setIsOverdueWorkOrders(false);
              setIsOntimeWorkOrders(false);
              setIsOntimeWorkOrdersTotal(false);
              setIsWorkOrders(false);
              setEsMostrarDialogDetail(true);
            }
          }
        }
      }
    },   
    series: workOrdersByDeptSeries
  };

  const chartOrdersTemplate = (
    <> 
      <Row>
        <Col lg={6} md={6} sm={12} xs={12}>
          <HighchartsReact 
            containerProps={{ style: { height: "280px" } }}
            highcharts={Highcharts} 
            options={workOrdersPieChartOptions}
          />
        </Col>
        <Col lg={6} md={6} sm={12} xs={12}>
          <HighchartsReact 
            containerProps={{ style: { height: "280px" } }}
            highcharts={Highcharts} 
            options={workOrdersByDeptBarChartOptions}
          />         
        </Col>
      </Row>    
    </>
  );

 const titleDialogDetail = (
   <>  
     {
      SubTitleDetail!= null ? (
        <>
          {`${TitleDetail  }-${  SubTitleDetail}`} 
        </>
      ):(
        <>
          {TitleDetail}
        </>
      )
    } 
   </>
  );
  const titleBacklogDialogDetail = (
    <> 
      <Row>
        <Col>
          {t('mtd:WorkOrderBacklogAdministrator')} ({CountWorkOrderBackLogAdministrator})
        </Col>
        <Col className='text-right pr-5'>
          {t('mtd:CountWorkOrderBacklogAdministrator')} {workorderbacklogadministrator}
        </Col>
      </Row>
    </>
   );
  const workOrdersCardPieChartOptions = {
    chart: {
      type: 'pie',          
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    margin: [2, 0, 2, 0],
    height: "200px",
    title: {
      text: "",
      align: 'center'
    },
    subtitle: {
      text: ""
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    tooltip: {      
      pointFormat: '<b>{point.percentage:.1f}%</b>'            
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        },
        point: {
          events: {
            click(e){
              console.log('CurrentWorkOrders options',e.point.options);
              setClaEstatusOt(e.point.options.id); 
              setTitleDetail(t('mtd:CurrentWeekWorkOrders'));
              setSubTitleDetail(null);
              setClaEdificio(null);
              setClaTechType(null);
              setClaAssignedTech(null);
              setIsOpenWorkOrders(false);
              setIsOverdueWorkOrders(false);
              setIsOverdueWorkOrdersTotal(false);
              setIsOntimeWorkOrders(false);
              setIsOntimeWorkOrdersTotal(false);
              setIsWorkOrders(false);
              setIsCurrentWeekWorkOrders(true);
              setEsMostrarDialogDetail(true);
            }
          }
        }
      }
    },
    series: workOrdersCardPieSeries
  };

  const workOrdersGroupPieChartOptions = {
    chart: {
      type: 'pie',          
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      height: '140px' 
    },
    title: {
      text: "",
      align: 'center'
    },
    subtitle: {
      text: ""
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    tooltip: {      
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{point.percentage:.1f}%<b/>'    
    },
    plotOptions: {
      series: {
        cursor: 'pointer' 
    },
      pie: {
        dataLabels: {
            enabled: true,
            distance: -65,
            style: {
                color: '#000'
            }
        },
        point: {
          events: {
            click(e){
              const seriesName = e.point.series.name;
              console.log('seriesName',seriesName);
              console.log('options',e.point.options);
              if(e.point.options.name==='overduetotal'){
                setIsOverdueWorkOrders(false);
                setIsOverdueWorkOrdersTotal(true);
              }else{
                setIsOverdueWorkOrders(true);
                setIsOverdueWorkOrdersTotal(false);
              }
              setTitleDetail(t('mtd:OverdueWorkOrders'));
              setSubTitleDetail(t('mtd:Detail'));
              setClaEdificio(null);
              setClaTechType(null);
              setClaEstatusOt(null);
              setClaAssignedTech(null);
              setIsOpenWorkOrders(false);
              setIsOntimeWorkOrders(false);
              setIsOntimeWorkOrdersTotal(false);
              setIsWorkOrders(false);
              setIsCurrentWeekWorkOrders(false);
              setEsMostrarDialogDetail(true);
            }
          }
        },
        startAngle: 180,
        endAngle: 180,
        center: ['50%', '50%'],
        size: '150%'
    }
    },
    series: [{
      type: 'pie',
      name: '',
      innerSize: '60%',
      data: [
        [`${OverdueWorkOrders  } Of ${  OverdueWorkOrdersTotal}`, OverdueWorkOrders],
          {
              name: 'overduetotal',
              y: OverdueWorkOrdersTotal,
              dataLabels: {
                  enabled: false
              }
          }
      ]
  }]
  }; 

  const workOrdersOnTimePieChartOptions = {
    chart: {
      type: 'pie',          
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      height: '140px' 
    },
    title: {
      text: "",
      align: 'center'
    },
    subtitle: {
      text: ""
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    tooltip: {      
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{point.percentage}<b/>'            
    },
    plotOptions: {
      pie: {
        dataLabels: {
            enabled: true,
            distance: -75,
            y: 12,
            style: {
                fontWeight: 'bold',
                color: '#000',
                fontSize:22
            }
        },
        point: {
          events: {
            click(e){
              if(e.point.options.name==='workorderontimerest'){
                setIsOntimeWorkOrders(false);
                setIsOntimeWorkOrdersTotal(true);
              }else{
                setIsOntimeWorkOrders(true);
                setIsOntimeWorkOrdersTotal(false);
              }
              setTitleDetail(t('mtd:WorkOrderOnTime'));
              setSubTitleDetail(t('mtd:Detail'));
              setClaEdificio(null);
              setClaTechType(null);
              setClaEstatusOt(null);
              setClaAssignedTech(null);
              setIsOpenWorkOrders(false);
              setIsOverdueWorkOrders(false); 
              setIsOverdueWorkOrdersTotal(false);
              setIsWorkOrders(false);  
              setIsCurrentWeekWorkOrders(false);
              setEsMostrarDialogDetail(true);
            }
          }
        },
        startAngle: -90,
        endAngle: 90,
        center: ['50%', '50%'],
        size: '150%'
    }
    },
    series: [{
      type: 'pie',
      name: '%',
      innerSize: '55%',
      data: [
          [`${WorkOrderOntimeCompletion  } %`, WorkOrderOntimeCompletion],
          {
              name: 'workorderontimerest',
              y: WorkOrderOntimeCompletionRest,
              dataLabels: {
                  enabled: false
              }
          }
      ]
  }]
  }; 
 
  const assignedTechBarChartOptions = {
    chart: {
      type: 'column'
    },
    title: {
      text: "",
      align: 'center'
    },
    subtitle: {
      text: ""
    },
    xAxis: {
      categories: assignedTechBarChartCategories
    },
    yAxis: {
      min: 0,
      title: {
        text: "Work Orders"
      }, 
      gridLineWidth: 1,
      lineWidth: 0,
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color: 'blue',
          textOutline: 'none'
        }
      }               
    },
    tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: <b>{point.y}<b/>'
    },
    legend: {
      enabled: true
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
            enabled: true
        },
        point: {
          events: {
            click(e){
              setSubTitleDetail(null);
              const seriesName = e.point.series.name;
              let dataSerie = {};
              let ClaResponsable = null;
              assignedTechBarChartSeries.forEach((item, index) => {
                if (item.name === seriesName) {
                  dataSerie = item;
                }
              });
              const infolist = dataSerie.info
              infolist.forEach((item, index) => {
                  if (item.NomResponsable === e.point.category) {
                    ClaResponsable = item.ClaResponsable;
                    setSubTitleDetail(item.NomResponsable);
                  }
                });
              console.log('info',dataSerie);
              setClaEdificio(null);
              setClaTechType(null);
              setClaEstatusOt(dataSerie.id);
              setClaAssignedTech(ClaResponsable);
              setTitleDetail(t('mtd:AssignedTech'));
              setIsOpenWorkOrders(false);
              setIsOverdueWorkOrders(false); 
              setIsOverdueWorkOrdersTotal(false);
              setIsOntimeWorkOrders(false);
              setIsOntimeWorkOrdersTotal(false);
              setIsWorkOrders(false);
              setIsCurrentWeekWorkOrders(false);
              setEsMostrarDialogDetail(true);
            }
          }
        }
      }
    },
    series: assignedTechBarChartSeries
  };

  const techTypeBarChartOptions = {
    chart: {
      type: 'column'
    },
    title: {
      text: "",
      align: 'center'
    },
    subtitle: {
      text: ""
    },
    legend: {
      enabled: true
    },
    xAxis: {
      categories: techTypeChartCategories,
      title: {
        text: null
      },
      gridLineWidth: 1,
      lineWidth: 0
    },
    yAxis: {
      min: 0,
      title: {
        text: "Work Orders"
      },
      gridLineWidth: 1,
      lineWidth: 0,
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color: 'blue',
          textOutline: 'none'
        }
      }               
    },
    tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: <b>{point.y}<b/>'
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
            enabled: true
        },
        point: {
          events: {
            click(e){
              const seriesName = e.point.series.name;
              setSubTitleDetail(null);
              let dataSerie = {};
              let ClaDisciplina = null;
              techTypeChartSeries.forEach((item, index) => {
                if (item.name === seriesName) {
                  dataSerie = item;
                }
              });
              const infolist = dataSerie.info
              infolist.forEach((item, index) => {
                  if (item.NomDisciplina === e.point.category) {
                    ClaDisciplina = item.ClaDisciplina;
                    setSubTitleDetail(item.NomDisciplina);
                  }
                });
              console.log('info',dataSerie);
              setClaEdificio(null);
              setClaTechType(ClaDisciplina);
              setClaEstatusOt(dataSerie.id);
              setClaAssignedTech(null);
              setTitleDetail(t('mtd:TechType'));
              setIsOpenWorkOrders(false);
              setIsOverdueWorkOrders(false); 
              setIsOverdueWorkOrdersTotal(false);
              setIsOntimeWorkOrders(false);
              setIsOntimeWorkOrdersTotal(false);
              setIsWorkOrders(false);
              setIsCurrentWeekWorkOrders(false);
              setEsMostrarDialogDetail(true);
            }
          }
        }
      }
    },
    series: techTypeChartSeries
  };

  const cardTemplate1 = (
    <>
      <Card className='card-chart'>
        <CardHeader>
          {t('mtd:CurrentWeek')} &nbsp; {moment(StartDateCurrentWeek).format('MM/DD/YYYY')} - {moment(EndDateCurrentWeek).format('MM/DD/YYYY')}
        </CardHeader>
        <CardBody> 
          <Row className='text-center'>
            <Col lg={4} md={4} sm={12} xs={12}>
              <Card className="card-one">
                <CardHeader>
                  <CardTitle className="text-center">
                    {t('mtd:OpenWorkOrders')} 
                  </CardTitle>
                  {t('mtd:AllAssets')} 
                </CardHeader>    
                <CardBody>   
                  <div
                    className='bottomdiv cursor'
                    onClick={() =>openDialogDetaiWorkOrders()}
                  >
                    {openWorkOrders} 
                  </div> 
                </CardBody>  
              </Card> 
            </Col>
            <Col lg={4} md={4} sm={12} xs={12}>
              <Card className="card-one">
                <CardHeader>
                  <CardTitle className="text-center">{t('mtd:MTTR')} </CardTitle>
                </CardHeader>    
                <CardBody>   
                  <div className='bottomdiv'>
                    {MTTR}<span>{t('mtd:hrs')}</span>
                  </div> 
                </CardBody>  
              </Card> 
            </Col>
            <Col lg={4} md={4} sm={12} xs={12}>
              <Card className="card-one">
                <HighchartsReact 
                  highcharts={Highcharts}  
                  containerProps={{ style: { height: "140px" } }}
                  options={workOrdersCardPieChartOptions}
                />
              </Card> 
            </Col>
          </Row>    
        </CardBody>
      </Card>      
    </>
  );

  const cardTemplate2 = (
    <>
      <Card className='card-chart-two'>
        <CardBody> 
          <Row className='text-center'>
            <Col lg={4} md={4} sm={12} xs={12}>
              <Card className="card-one">
                <CardHeader>
                  <CardTitle className="text-center">
                    {t('mtd:OverdueWorkOrders')} 
                  </CardTitle>
                  {t('mtd:AllAssets')} 
                </CardHeader>    
                <HighchartsReact 
                  containerProps={{ style: { height: "140px", align: 'center' } }}
                  highcharts={Highcharts} 
                  options={workOrdersGroupPieChartOptions}
                />
              </Card> 
            </Col>
            <Col lg={4} md={4} sm={12} xs={12}>
              <Card className="card-one">
                <CardHeader>
                  <CardTitle className="text-center">{t('mtd:WorkOrderOnTime')} </CardTitle>
                  &nbsp;
                </CardHeader>    
                <HighchartsReact 
                  containerProps={{ style: { height: "140px" } }}
                  highcharts={Highcharts} 
                  options={workOrdersOnTimePieChartOptions}
                />
              </Card> 
            </Col>
            <Col lg={4} md={4} sm={12} xs={12}>
              <Card className="card-one">
                <CardHeader>
                  <CardTitle className="text-center">{t('mtd:WorkOrderBacklogAdministrator')} </CardTitle>
                </CardHeader>    
                <CardBody>   
                  <div className='bottomOntime cursor' onClick={() =>openDialogBacklog()}>
                    {CountWorkOrderBackLogAdministrator}<span></span>
                  </div> 
                  <Row className='bottomOntimefooter'>
                    <Col>
                      {t('mtd:CountWorkOrderBacklogAdministrator')} {workorderbacklogadministrator}&nbsp;Days
                    </Col>
                  </Row>
                </CardBody>
              </Card>  
            </Col>
          </Row>    
        </CardBody>
      </Card>  
      <Dialog
        visible={EsMostrarDialogBacklog}
        onHide={() => onHideDialogBacklog()}  
        style={{ width: '100vw' }}
        maximized={true}
        header={titleBacklogDialogDetail}
      >
        <MTDPerformanceReportBacklog 
          filterBuildingList={filterBuildingList}
          filterTecAssignedList={filterTecAssignedList}
          filterTecTypeList={filterTecTypeList}
          StartDate={StartDate}
          EndDate={EndDate}
        >
        </MTDPerformanceReportBacklog>
      </Dialog>    
    </>
  );

  const cardTemplate3 = (
    <>
      <Card className='card-chart-three'>
        <CardBody> 
          <Row className='text-center'>
            <Col lg={12} md={12} sm={12} xs={12}>  
              <Card className='card-detail'>
                <CardHeader className='hard-gray'> {t('mtd:AssignedTech')} </CardHeader>
                <CardBody>
                  <Row className='align-items-start'>
                    <Col lg={6} md={6} sm={12} xs={12}> 
                      <div className='p-datatable p-component p-datatable-scrollable table-header-background-blue'>
                        <table className='w-100'>
                          <thead className='p-datatable-thead'>
                            <tr>
                              <th width="20%">&nbsp;</th>
                              <th className='text-center' colSpan={2}>{t('mtd:WorkInProgress')}</th> 
                              <th className='text-center' colSpan={2}>{t('mtd:OverdueWorkOrders')}</th> 
                              <th className='text-center' colSpan={2}>{t('mtd:OntimeCompletation')}</th> 
                              <th>
                                &nbsp;
                              </th>
                            </tr>
                            <tr>
                              <th width="20%">{t('scoreCard:Assigned Tech')}</th>
                              <th className='text-center'>{t('mtd:Total')}</th> 
                              <th className='text-center'>{t('mtd:porcentaje')}</th> 
                              <th className='text-center'>{t('mtd:Total')}</th> 
                              <th className='text-center'>{t('mtd:porcentaje')}</th> 
                              <th className='text-center'>{t('mtd:Total')}</th> 
                              <th className='text-center'>{t('mtd:porcentaje')}</th> 
                              <th className='text-center'>{t('mtd:Total')}</th> 
                            </tr>
                          </thead> 
                          <tbody className='p-datatable-tbody'> 
                            {TypeTechInfo.map((element, index) => {  
                                return (  
                                  <tr key={index}>
                                    <td width="20%">
                                      {element.NomResponsable}
                                    </td>
                                    <td className='text-right'> 
                                      {Highcharts.numberFormat(Highcharts.numberFormat(element.WorkInProgressTotal,0,'.',','),0,'.',',')}
                                    </td>
                                    <td className='text-right'>
                                      {Highcharts.numberFormat(Highcharts.numberFormat(element.WorkInProgressPorcentaje,1,'.',','),1,'.',',')}%
                                    </td>
                                    <td className='text-right'> 
                                      {Highcharts.numberFormat(Highcharts.numberFormat(element.WorkOrderOverDueTotal,0,'.',','),0,'.',',')}
                                    </td>
                                    <td className='text-right'> 
                                      {Highcharts.numberFormat(Highcharts.numberFormat(element.WorkOrderOverDuePorcentaje,1,'.',','),1,'.',',')}%
                                    </td>
                                    <td className='text-right'> 
                                      {Highcharts.numberFormat(Highcharts.numberFormat(element.WorkOrderOnTimeTotal,0,'.',','),0,'.',',')}
                                    </td>
                                    <td className='text-right'> 
                                      {Highcharts.numberFormat(Highcharts.numberFormat(element.WorkOrderOnTimePorcentaje,1,'.',','),1,'.',',')}%
                                    </td>
                                    <td className='text-right'> 
                                      {element.Total}
                                    </td>
                                  </tr>  
                                );
                            })}   
                          </tbody>
                        </table>
                      </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}> 
                      <HighchartsReact 
                        containerProps={{ style: { height: "160px" } }}
                        highcharts={Highcharts} 
                        options={assignedTechBarChartOptions}
                      />      
                    </Col>
                  </Row>
                    
                </CardBody>
              </Card>
            </Col>
          </Row> 
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
              <Card className='card-detail'>
                <CardHeader className='hard-gray'> {t('mtd:TechType')} </CardHeader>
                <CardBody>
                  <HighchartsReact 
                    containerProps={{ style: { height: "160px" } }}
                    highcharts={Highcharts} 
                    options={techTypeBarChartOptions}
                  />        
                </CardBody>
              </Card>
            </Col>
          </Row>   
        </CardBody>
      </Card>      
    </>
  );

  const renderChart = (
    <> 
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}> 
          <h2> 
            <span> 
              { Viewport === 'xs' || Viewport === 'sm' ?
                   (
                     <i className='pi pi-filter cursor' onClick={()=> openSidebar()}> &nbsp;</i> 
                   )
                   :
                   (<></>)}
              {t('mtd:SummarizedPerformance')}&nbsp;
            </span> 
            <span> 
              {
                BuildingCount!= null ? (
                  <>
                    ({BuildingCount} {BuildingEtiqueta})
                  </>
                ):(
                  <>
                  </>
                )
              }
            </span> 
          </h2>
        </Col>
      </Row>  
      <Row className='align-items-start'>
        <Col lg={12} md={12} sm={12} xs={12}> 
          {chartOrdersTemplate}
        </Col>
        <Col lg={12} md={12} sm={12} xs={12}> 
          {cardTemplate1}
          {cardTemplate2}
          {cardTemplate3}
          
          <Dialog
            visible={EsMostrarDialogDetail}
            onHide={() => onHideDialogDetail()}  
            style={{width: '50vw'}}
            header={titleDialogDetail}  
          >
            <MTDPerformanceReportDetail
              filterBuildingList={filterBuildingList}
              filterTecAssignedList={filterTecAssignedList}
              filterTecTypeList={filterTecTypeList}
              StartDate={StartDate}
              EndDate={EndDate}
              ClaEdificio={ClaEdificio}
              ClaAssignedTech={ClaAssignedTech}
              ClaTechType={ClaTechType}
              ClaAging={ClaAging}
              ClaEstatusOt={ClaEstatusOt}
              Title={TitleDetail}
              SubTitle={SubTitleDetail}
              IsOpenWorkOrders={IsOpenWorkOrders}
              IsOverdueWorkOrders={IsOverdueWorkOrders}
              IsOverdueWorkOrdersTotal={IsOverdueWorkOrdersTotal}
              IsOntimeWorkOrders={IsOntimeWorkOrders}
              IsOntimeWorkOrdersTotal={IsOntimeWorkOrdersTotal}
              IsWorkOrders={IsWorkOrders}
              IsCurrentWeekWorkOrders={IsCurrentWeekWorkOrders}
              IsBackLog={false}
            >
            </MTDPerformanceReportDetail>
          </Dialog>  
        </Col>
      </Row>   
    </>
  );

  const openSidebar = () => {
    setisShowFilter(true);
  }

  const closeSidebar = () => {
    setisShowFilter(false);
  }

  const customIcons = (
    <> 
      <Row className="align-items-start">
        <Col>
          <Row>
            <Col lg={2} md={2} sm={2} xs={2}> 
              <span style={{'fontSize': '2em'}} className='pi pi-times-circle light-gray-color cursor' onClick={closeSidebar}>
              </span> 
            </Col>
            <Col lg={10} md={10} sm={10} xs={10} className='text-right'>
              <FieldButton
                label={t('common:ApplyFilter')}
                className="product-data-view-apply-button ml-1"
                onClick={onApplyFiltersClick}
              >
              </FieldButton>
            </Col>
          </Row>
        </Col> 
      </Row>  
    </>
 );

 const onHideDialogBacklog = () => {
  setEsMostrarDialogBacklog(false);
}
const openDialogBacklog = () => {
  setEsMostrarDialogBacklog(true);
}
const onHideDialogDetail = () => {
  setEsMostrarDialogDetail(false);
}
const openDialogDetail = () => {
  setEsMostrarDialogDetail(true);
}

const openDialogDetaiWorkOrders = () => {
  setClaEdificio(null);
  setClaAssignedTech(null);
  setClaTechType(null);
  setClaEstatusOt(null);
  setTitleDetail(t('mtd:OpenWorkOrders'));
  setSubTitleDetail(null);
  setIsOpenWorkOrders(true);
  setIsOverdueWorkOrders(false); 
  setIsOverdueWorkOrdersTotal(false);
  setIsOntimeWorkOrders(false);
  setIsOntimeWorkOrdersTotal(false);
  setIsWorkOrders(false);
  setIsCurrentWeekWorkOrders(false);
  setEsMostrarDialogDetail(true);
}

  return (
    <>   
      <PageContent title={t('mtd:MTDPerformanceReport')} className="MTDScreen content-no-margin"> 
        { Viewport === 'xs' || Viewport === 'sm' ?
        (
          <Row className='align-items-start rowNotMargin' style={{height:'100%'}}>
            <Col lg={12} md={12} sm={12} xs={12}>  
              {renderChart}
            </Col> 
            <Sidebar
              visible={isShowFilter}
              blockScroll={true} 
              icons={customIcons}
              showCloseIcon={false}
              fullScreen
              onHide={closeSidebar}
            >
              <div className='p-2'>
                {filterTemplate} 
              </div>
            </Sidebar>         
          </Row>    
        )
        :
        (
          <>
            <Row className='align-items-start rowNotMargin' style={{height:'100%'}}>
              <Col
                lg={2}
                md={2}
                sm={12}
                xs={12}
                className='filterTemplate'
                style={{height: `calc(100vh - 100px)` }}
              >
                <ScrollPanel>
                  {filterTemplate}
                  <Row className='filter-footer p-3 pb-5'>
                    <Col className='text-right p-fluid'>
                      <FieldButton
                        label={t('common:ApplyFilter')}
                        className="product-data-view-apply-button ml-1"
                        onClick={onApplyFiltersClick}
                      >
                      </FieldButton>
                    </Col>
                  </Row>
                </ScrollPanel>
              </Col> 
              <Col lg={10} md={10} sm={12} xs={12} style={{backgroundColor:'#fff', height: `calc(100vh - 100px)` }}>
                <ScrollPanel>
                  {renderChart}
                </ScrollPanel>
              </Col> 
            </Row>
          </>
)} 
       
      </PageContent> 
    </>
  );

};

export default MTDPerformanceReportScreen;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next';   
import FieldButton from '../../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row,Col, TabContent, TabPane , Nav, NavItem, NavLink} from 'reactstrap';   
import { config } from '../../../../utils/config';
import { callApi,showSweetAlert } from '../../../../utils/utils';  
import FieldDataTable from '../../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import FieldTextArea  from '../../../../components/Controls/FieldTextArea.jsx' 
import FieldUpload from '../../../../components/Controls/FieldUpload/FieldUpload.jsx';
import FieldDropdown from '../../../../components/Controls/FieldDropdown.jsx' 
import * as yup from 'yup';
import YupValidator from '../../../../utils/YupValidator'; 
import swal from '@sweetalert/with-react';
import SweatAlertCustomIcon from '../../../../components/SweatAlert/SweatAlertCustomIcon'
import { Tooltip } from 'primereact/tooltip';

const ActionPlanAttachment = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['actionPlan', 'common']);
  const [errors, setErrors] = useState({}); 
  const [ClaProyecto, setClaProyecto] = useState(props.ClaProyecto === undefined ? 0 : props.ClaProyecto); 
  const [ClaFase, setClaFase] = useState(props.ClaFase === undefined ? 0 : props.ClaFase); 
  const [ClaActividad, setClaActividad] = useState(props.ClaActividad === undefined ? 0 : props.ClaActividad); 
  const [Comentarios, setComentarios] = useState(null);
  const [Link, setLink] = useState(null);
  const [UploadName, setUploadName] = useState(null);
  const [FieldData, setFieldData] = useState(null); 
  const [datafiles, setdatafiles] = useState([]);
  const [ActionPlanFileList, setActionPlanFileList] = useState([]);
  const dt = useRef(null); 
  const [TabActiveIndex, setTabActiveIndex] = useState(1);
  const fileRef = useRef(null); 
  const [ClaTipoArchivoCSF, setClaTipoArchivoCSF] = useState(null); 
  const [OpcionesTipoArchivo, setOpcionesTipoArchivo] = useState([]);
  const [IdCSF, setIdCSF] = useState(props.IdCSF === undefined ? 0 : props.IdCSF);
  const [ClaPeriodo, setClaPeriodo] = useState(props.ClaPeriodo === undefined ? 0 : props.ClaPeriodo);
  const [ClaFechaReporte, setClaFechaReporte] = useState(props.ClaFechaReporte === undefined ? 0 : props.ClaFechaReporte);  


  useEffect(() => {    
    obtenerInformacionActionPlanFileAttachtment();
    obtenerTipoArchivo();
    if(props.IsUpload !== undefined && !props.IsUpload){
      setTabActiveIndex(2); 
    }
    setErrors({});  
  }, [props.IdCSF, props.ClaPeriodo, props.ClaFechaReporte]); 

  useEffect(() => {
  }, [props.Filters]); 

  
  const obtenerInformacionActionPlanFileAttachtment = () => { 
    const GetActionPlanFile = `${config.UrlApiProject}ScoreCard/GetActionPlanFile`;  
    const paramsToService = { 
      IdCSF,
      ClaPeriodo,
      ClaFechaReporte
    };   
    callApi(GetActionPlanFile, 'POST', paramsToService, (response) => { 
        setActionPlanFileList(response.data.ActionPlanFile);
    });
  }
  const obtenerTipoArchivo = () => { 
    const GetTipoArchivo = `${config.UrlApiProject}ScoreCard/GetTipoArchivo`;  
    const paramsToService = { 
    };   
    callApi(GetTipoArchivo, 'POST', paramsToService, (response) => { 
        setOpcionesTipoArchivo(response.data.TipoArchivo);
    });
  }

  const onChangeComentarios = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.Comentarios;   
    setErrors(newErrors) 
    setComentarios(e.target.value)
  } 

  const onChangeLink = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.Link;   
    setErrors(newErrors) 
    setLink(e.target.value)
  } 
 
const save = async () => {
   
 const value = {    
    ClaTipoArchivoCSF
    };

  const validator = new YupValidator(valitationScheme);
  const esValido = await validator.validate(value);

  if (!esValido){   
    setErrors(validator.errors); 
    showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');   
    return;
  }

  if(datafiles.length===0){
    showSweetAlert(t('common:Warning'), t('actionPlan:AttachFile'), 'warning');
    return;
  }
  const urlWebServiceSave = `${config.UrlApiProject}ScoreCard/SaveActionPlanFile`;
  const paramsService = {
    IdCSF,
    ClaPeriodo,
    ClaFechaReporte,
    FileData:datafiles[0].FileData,
    NomArchivo:datafiles[0].FileName,
    Extension:datafiles[0].FileExtension,
    ClaTipoArchivo: ClaTipoArchivoCSF,
    Comentarios
  }; 
  callApi(urlWebServiceSave, 'POST', paramsService, (response) => { 
    setComentarios(null);
    setUploadName(null);
    setFieldData(null);
    setLink(null);
    setdatafiles([]); 
    showSweetAlert('success', t('common:SuccessOperation'), 'success');
    obtenerInformacionActionPlanFileAttachtment();
    setTabActiveIndex(2);     
    props.obtenerInformacionActionPlanFile !== undefined? props.obtenerInformacionActionPlanFile(IdCSF, ClaPeriodo, ClaFechaReporte) : "";      
 })
};

 const switchTab = tab => {
  if(TabActiveIndex !== tab){
    setTabActiveIndex(tab);
  }
}

 const confirmationMessageHTML = (
   <div>
     <SweatAlertCustomIcon title={t('common:SubmitConfirmation')} letter={t('common:QuestionMark')}></SweatAlertCustomIcon> 
     <div>{t('actionPlan:SubmitRemoveDocument')}. </div> 
   </div>  
 )

 const downloadFile = (rowData) => { 
    const GetFileData = `${config.UrlApiProject}ScoreCard/GetAnexosActionPlanFileById`;  
    const paramsToService = { 
        IdArchivo:rowData.IdArchivo
    };   
    callApi(GetFileData, 'POST', paramsToService, (response) => {
      const AnexoDetalle = response.data.AnexoDetalle[0];
      if(AnexoDetalle!==undefined){
        const linkSource = `data:application/${rowData.TipoExtension};base64,${AnexoDetalle.FileData}`;
        const downloadLink = document.createElement("a");
        const fileName = `${rowData.NombreArchivo}`;
        downloadLink.href = linkSource;
        downloadLink.download = `${fileName  }.${  rowData.TipoExtension}`;
        downloadLink.click();
      }
    });
 }
 
const onConfirmRemoveSubmit = (nIdArchivo) => {  
  swal({ 
    buttons: {
      cancelar: {
        text: "Cancel",
        value: "cancel",
        className: "rounded-button danger"
      },
      continuar: {
        text: "Continue",
        value: "continue",
        className: "rounded-button light-green"
      }
    },
    content: confirmationMessageHTML
  }).then((value) => {
    switch (value) { 
      case "continue":
        // run function
        onSubmit(nIdArchivo);
        break; 
      default:
        break;
    }
  }); 
}

const onSubmit = async (nIdArchivo) => { 
  const paramsService = {
    IdArchivo: nIdArchivo
 };   
 const SubmitBajaAnexosActionPlan = `${config.UrlApiProject}ScoreCard/BajaAnexosActionPlanFileById`;
 callApi(SubmitBajaAnexosActionPlan, 'POST', paramsService, (response) => { 
    obtenerInformacionActionPlanFileAttachtment(); 
    props.obtenerInformacionActionPlanFile !== undefined? props.obtenerInformacionActionPlanFile(IdCSF, ClaPeriodo, ClaFechaReporte) : "";      
 });   
} 

const NomArchivoTemplate = (rowData) => {
  return (
    <>
      { `${rowData.NombreArchivo}.${rowData.Extension}` } 
    </>
  )
}
 
const AnexoInfo = (rowData) => {
    return (
      <>   
        <Col className="text-right">
          <Tooltip target=".p-button-info" mouseTrack mouseTrackTop={10} />
          <FieldButton
            type="button" 
            onClick={() => downloadFile(rowData)}
            data-pr-position="top" 
            data-pr-tooltip={t('actionPlan:Download')}
            icon="pi pi-arrow-circle-down"
            className="p-button-rounded p-button-info p-button-outlined"
          /> 
                    &nbsp;
          { 
          props.IsReadyOnly !== undefined && props.IsReadyOnly ?
          (
            <></>
          )
          :
          (
            <>
              <Tooltip target=".p-button-danger" mouseTrack mouseTrackTop={10} />
              <FieldButton
                type="button" 
                onClick={() => onConfirmRemoveSubmit(rowData.IdArchivo)}
                data-pr-position="top" 
                data-pr-tooltip={t('actionPlan:Remove')}
                icon="pi pi-trash"
                className="p-button-rounded p-button-danger p-button-outlined"
              />
            </>
)
        } 
        </Col>
      </>
    );
  }
  const onTipoArchivoChange = (props,e) => {
    const newErrors = { ...errors }
    delete newErrors.ClaTipoArchivoCSF;   
    setErrors(newErrors);
    setClaTipoArchivoCSF(e.value);
  }
  
  const valitationScheme = yup.object().shape({
    ClaTipoArchivoCSF: yup.mixed().required(t('common:RequiredField')).nullable()
  });

    return (
      <>     
        <div className="p-tabview p-component pb-3">
          <Nav className="p-tabview-nav">
            <NavItem hidden={props.IsUpload !== undefined && !props.IsUpload} className={TabActiveIndex === 1 ? "p-tabview-selected p-highlight p-unselectable-text nav-item flex-sm-fill" : "p-unselectable-text nav-item flex-sm-fill"}>
              <NavLink className="p-tabview-nav-link" onClick={() => { switchTab(1); }}>
                {t('actionPlan:Upload')}
              </NavLink>
            </NavItem>
            <NavItem hidden={props.IsUpload !== undefined && !props.IsUpload} className={TabActiveIndex === 2 ? "p-tabview-selected p-highlight p-unselectable-text nav-item flex-sm-fill" : "p-unselectable-text nav-item flex-sm-fill"}>
              <NavLink className="p-tabview-nav-link" onClick={() => { switchTab(2); }}>
                {t('actionPlan:UploadedDocuments')}
              </NavLink>
            </NavItem>
          </Nav> 
        </div>
        <TabContent className="p-tabview-panels" activeTab={TabActiveIndex}>
          <TabPane tabId={1} hidden={props.IsUpload !== undefined && !props.IsUpload}> 
            <Row className="pt-2 pb-5">
              <Col lg={1} md={1} sm={12} xs={12}> 
                <span className="bold">{t('actionPlan:Type')}</span> 
              </Col>
              <Col>
                <div className="p-fluid"> 
                  <FieldDropdown  
                    name="ClaTipoArchivoCSF"
                    value={ClaTipoArchivoCSF}
                    options={OpcionesTipoArchivo}  
                    optionValue="ClaTipoArchivoCSF"
                    optionLabel="NomTipoArchivoCSF" 
                    onChange={(e) => onTipoArchivoChange(props,e)}
                    errors={errors}
                  />  
                </div>
              </Col>
            </Row> 
            <Row>
              <Col lg={1} md={1} sm={12} xs={12}> 
                <span className="bold">{t('actionPlan:File')}</span> 
              </Col>
              <Col>
                <FieldUpload files={datafiles} onChange={(files) => setdatafiles(files)}></FieldUpload>
              </Col>
            </Row>
            <Row className="pt-2">
              <Col lg={1} md={1} sm={12} xs={12}> 
                <span className="bold">{t('actionPlan:Comments')}</span> 
              </Col>
              <Col>
                <div className="p-fluid"> 
                  <FieldTextArea 
                    name="Comentarios"
                    rows={2}
                    value={Comentarios} 
                    onChange={onChangeComentarios}
                    errors={errors} 
                    maxLength={1000}
                  />
                </div>
              </Col>
            </Row>           
            <Row>
              <Col className="text-right">
                <FieldButton
                  type="button" 
                  onClick={save}
                  label={t('common:Save')}
                  className="p-button-success no-border p-mr-2 rounded-button"
                  style={{ marginRight: '.25em' }}
                /> 
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId={2}>
            <Row className="pt-3">
              <Col>
                <div style={{minHeight:"300px"}}>
                  <FieldDataTable 
                    value={ActionPlanFileList} 
                    header={t('actionPlan:Documents')}
                    scrollable 
                    scrollHeight="250px"
                    className="p-datatable-striped"
                  > 
                    <Column field="NomTipoArchivo" columnKey="NomTipoArchivo" header={t('actionPlan:Type')} headerStyle={{ width: '120px' }}></Column> 
                    <Column field="NomArchivo" body={NomArchivoTemplate} columnKey="NomArchivo" header={t('actionPlan:File')} headerStyle={{ width: '240px' }}></Column>                     
                    <Column field="Comentario" columnKey="Comentario" header={t('actionPlan:Comments')} headerStyle={{ width: '180px' }}></Column>                     
                    <Column field="NomUsuarioInsArchivo" columnKey="NomUsuarioInsArchivo" header={t('actionPlan:User')} headerStyle={{ width: '120px' }}></Column>     
                    <Column body={AnexoInfo} headerStyle={{ width: '90px' }}></Column> 
             
                  </FieldDataTable> 
                </div>
              </Col>
            </Row> 
          </TabPane>
        </TabContent>
      </> 
        ); 
};
export default ActionPlanAttachment;
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable object-shorthand */
import React, { useState, useEffect } from 'react';
import { config } from '../../../utils/config';
import { callApi } from '../../../utils/utils';
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  CardTitle 
} from 'reactstrap'; 
import { useTranslation } from 'react-i18next';
import { PickList } from 'primereact/picklist';
import { TreeList, Scrolling, Paging, Pager, Column, Selection, Format } from 'devextreme-react/tree-list';
import { Dialog } from 'primereact/dialog';  
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import FieldToogleSwitch from '../../../components/Controls/FieldToogleSwitch/FieldToogleSwitch.jsx'

const AnalisisCostosGeneral = (props) => { 
  const { t } = useTranslation(['formulario','common']);
  const [source, setSource] = useState([]);
  const [target, setTarget] = useState([]);
  const [matriz, setMatriz] = useState([]);  
  const [openFilterTree, setOpenFilterTree] = useState(false); 

  const [showColumnsEne, setshowColumnsEne] = useState(false);
  const [showColumnsFeb, setshowColumnsFeb] = useState(false);
  const [showColumnsMar, setshowColumnsMar] = useState(false);
  const [showColumnsAbr, setshowColumnsAbr] = useState(false);
  const [showColumnsMay, setshowColumnsMay] = useState(false);
  const [showColumnsJun, setshowColumnsJun] = useState(false);
  const [showColumnsJul, setshowColumnsJul] = useState(false);
  const [showColumnsAgo, setshowColumnsAgo] = useState(false);
  const [showColumnsSep, setshowColumnsSep] = useState(false);
  const [showColumnsOct, setshowColumnsOct] = useState(false);
  const [showColumnsNov, setshowColumnsNov] = useState(false);
  const [showColumnsDic, setshowColumnsDic] = useState(false);
  const [yesNoOptions, setYesNoOptions] = useState([`${t('common:Yes')}`, `${t('common:No')}`]);
  const [EsMostrarTodoElAnio, setEsMostrarTodoElAnio] = useState(true); 
  const expandedRowKeys = [0];
  let targetFilter = [];
  
  useEffect(() => {  
      console.log('Entro');
      const GetDetalleElementService = `${config.UrlApiProject}Costos/GetMatrizCtaContableCmb`;
      const paramsToService = {
      }; 
      callApi(GetDetalleElementService, 'POST', paramsToService, (response) => {  
          if(props.filters.target!==undefined){
            targetFilter = props.filters.target;
            setSource(props.filters.source);  
            setTarget(props.filters.target);
          }else if(source.length===0){
              const defaultSource = [];
              const defaultTarget = [];
              
              for (let index = 0; index < response.data[0].length; index++) {

                if (response.data[0][index].ClaPivotOrderMatrizCtaContable === 1){ 
                  defaultTarget.push(response.data[0][index]); 
                }
                else if (response.data[0][index].ClaPivotOrderMatrizCtaContable === 2){ 
                  defaultTarget.push(response.data[0][index]);
                }
                else    if (response.data[0][index].ClaPivotOrderMatrizCtaContable === 3){ 
                  defaultTarget.push(response.data[0][index]);
                }
                else {
                  defaultSource.push(response.data[0][index]);
                }
              } 


              setSource(defaultSource);   
              setTarget(defaultTarget);
            }
          DataBindTree();
      });
  }, [props.filters]); 

 
const onChange = (event) => {
    setSource(event.source);
    setTarget(event.target);  
}


const itemTemplate = (item) => {
    return (
      <div className="product-item">
        <div className="product-list-detail">
          <h5 className="p-mb-2">{item.NomPivotOrderMatrizCtaContable}</h5>
        </div>
      </div>
    );
}

const closeModal = () => {
  setOpenFilterTree(false);
}

const onOpenModal = () => {
  setOpenFilterTree(true)
}

const DataBindTree = ()=>{
  if(source.length>0){ 
    props.filters.source = source;
    props.filters.target = target;
    targetFilter = target;
  }
  if(targetFilter.length>0){
      const GetDetalleElementService = `${config.UrlApiProject}Costos/GetMatrizCtaContable`;
      const paramsToService = {
          anioMes: props.filters.AnioMes,
          claTipoElementoCosto: props.filters.ClaTipoElementoCosto,
          claDepartamento:props.filters.ClaDepartamento,
          claTipoCelda:props.filters.ClaTipoCelda,
          claCelda:props.filters.ClaCelda,
          claArticulo:props.filters.ClaArticulo,
          anio: props.filters.Anio,
          mes:props.filters.Mes, 
          cmbPivot1:(targetFilter[0] !== undefined ? targetFilter[0].ClaPivotOrderMatrizCtaContable : null),
          cmbPivot2:(targetFilter[1] !== undefined ? targetFilter[1].ClaPivotOrderMatrizCtaContable : null),
          cmbPivot3:(targetFilter[2] !== undefined ? targetFilter[2].ClaPivotOrderMatrizCtaContable : null),
          cmbPivot4:(targetFilter[3] !== undefined ? targetFilter[3].ClaPivotOrderMatrizCtaContable : null),
          paramsLength:target.length,
          EsMostrarTodoElAnio: EsMostrarTodoElAnio ? 1 : 0,
          TipoConversionCost: props.filters.TipoConversionCost
        }; 
 
      
      callApi(GetDetalleElementService, 'POST', paramsToService, (response) => {
          setMatriz(response.data[0]); 
          if(response.data[1]!==undefined){
            setshowColumnsEne(response.data[1][0].EsEne === 1);
            setshowColumnsFeb(response.data[1][0].EsFeb === 1);
            setshowColumnsMar(response.data[1][0].EsMar === 1);
            setshowColumnsAbr(response.data[1][0].EsAbr === 1);
            setshowColumnsMay(response.data[1][0].EsMay === 1);
            setshowColumnsJun(response.data[1][0].EsJun === 1);
            setshowColumnsJul(response.data[1][0].EsJul === 1);
            setshowColumnsAgo(response.data[1][0].EsAgo === 1);
            setshowColumnsSep(response.data[1][0].EsSep === 1);
            setshowColumnsOct(response.data[1][0].EsOct === 1);
            setshowColumnsNov(response.data[1][0].EsNov === 1);
            setshowColumnsDic(response.data[1][0].EsDic === 1);
          }
          closeModal();
      });
  }
}

const modalFooter = () => {
  return (
    <>
      <FieldButton label={t('common:Search')} icon="pi pi-search" className="p-button-text" onClick={DataBindTree}    />
    </>
);
} 

return (
  <>
    <div>

      
      <Row> 
        <Col lg={6} md={6} sm={12} xs={12}>
          <FieldButton
            label={t('formulario:CustomizeDrillDown')}
            icon="dx-icon-columnchooser" 
            className="p-button-success p-mr-2 AC-Step-3" 
            onClick={onOpenModal}
          /> 
        </Col>
        <Col lg={6} md={6} sm={12} xs={12} className="text-right"> 
          <label>{t('formulario:AllYear')}</label> &nbsp;
          <FieldToogleSwitch
            id="EsMostrarTodoElAnio"
            checked={EsMostrarTodoElAnio}
            onChange={setEsMostrarTodoElAnio} 
            optionLabels={yesNoOptions}
            small
          />
        </Col>  
      </Row>

      <Dialog
        visible={openFilterTree}
        style={{ width: '600px' }} 
        header={t('common:Filter')}
        footer={modalFooter}
        modal 
        className="p-fluid p-grid"
        onHide={closeModal}
      >
        <PickList
          className="align-top"
          source={source}
          target={target}
          itemTemplate={itemTemplate}
          sourceHeader={t('formulario:Available')} 
          targetHeader={t('formulario:DrillDown')}
          onChange={onChange}
          sourceStyle={{ height: '100px' }} 
          targetStyle={{ height: '100px' }}
        >
        </PickList>
      </Dialog>
      <Row className="AC-Step-4">
        <Col> 
          <TreeList
            id="matriz"
            dataSource={matriz}
            rootValue={-1}
            defaultExpandedRowKeys={expandedRowKeys}
            showRowLines={true}
            showBorders={true}
            columnAutoWidth={true} 
            height={470}
            keyExpr="Id"
            parentIdExpr="PadreId"
            noDataText={t('common:NoData')}
          >
            
            <Selection mode="single" />

            <Scrolling
              mode="standard"
            />
            <Paging
              enabled={true}
              defaultPageSize={150}
            />
            <Pager
              showPageSizeSelector={false}
              showInfo={false}
            />

            <Column 
              fixed={true} 
              dataField="Descripcion"
              caption="Descripcion"
              width={350}
            >
            </Column>

            <Column caption="YTD" visible={EsMostrarTodoElAnio} alignment="center">
              <Column
                dataField="YTDActual"
                caption="Actual" 
                alignment="center"
              >
                <Format
                  type="fixedPoint"
                  precision={0}
                />
              </Column>
              <Column
                dataField="YTDMTons"
                caption="Tons"
                alignment="center"
              >
                <Format
                  type="fixedPoint"
                  precision={0}
                />
              </Column>
              <Column
                dataField="YTD$/MTons"
                caption="YTD$/MTons" 
                format="currency"
                alignment="center"
              > 
              </Column>
            </Column>
            <Column caption={t('common:December')} visible={showColumnsDic} alignment="center">
              <Column
                dataField="DiciembreActual"
                caption="Actual"
                alignment="center"
              >
                <Format
                  type="fixedPoint"
                  precision={0}
                />
              </Column>
              <Column
                dataField="DiciembreMTons"
                caption="Tons"
                alignment="center"
              >
                <Format
                  type="fixedPoint"
                  precision={0}
                />
              </Column>
              <Column
                dataField="Diciembre$/MTons"
                caption="$/MTons"
                format="currency"
                alignment="center"
              > 
              </Column>
            </Column>
            <Column caption={t('common:November')} visible={showColumnsNov} alignment="center">
              <Column
                dataField="NoviembreActual"
                caption="Actual"
                alignment="center"
              >
                <Format
                  type="fixedPoint"
                  precision={0}
                />
              </Column>
              <Column
                dataField="NoviembreMTons"
                caption="Tons"
                alignment="center"
              >
                <Format
                  type="fixedPoint"
                  precision={0}
                />
              </Column>
              <Column
                dataField="Noviembre$/MTons"
                caption="$/MTons"
                format="currency"
                alignment="center"
              > 
              </Column>
            </Column>

            <Column caption={t('common:October')} visible={showColumnsOct} alignment="center">
              <Column
                dataField="OctubreActual"
                caption="Actual"
                alignment="center"
              >
                <Format
                  type="fixedPoint"
                  precision={0}
                />
              </Column>
              <Column
                dataField="OctubreMTons"
                caption="Tons"
                alignment="center"
              >
                <Format
                  type="fixedPoint"
                  precision={0}
                />
              </Column>
              <Column
                dataField="Octubre$/MTons"
                caption="$/MTons"
                format="currency"
                alignment="center"
              > 
              </Column>
            </Column>
            
            <Column caption={t('common:September')} visible={showColumnsSep} alignment="center">
              <Column
                dataField="SeptiembreActual"
                caption="Actual"
                alignment="center"
              >
                <Format
                  type="fixedPoint"
                  precision={0}
                />
              </Column>
              <Column
                dataField="SeptiembreMTons"
                caption="Tons"
                alignment="center"
              >
                <Format
                  type="fixedPoint"
                  precision={0}
                />
              </Column>
              <Column
                dataField="Septiembre$/MTons"
                caption="$/MTons"
                format="currency"
                alignment="center"
              > 
              </Column>
            </Column>
            

            <Column caption={t('common:August')} visible={showColumnsAgo} alignment="center">
              <Column
                dataField="AgostoActual"
                caption="Actual"
                alignment="center"
              >
                <Format
                  type="fixedPoint"
                  precision={0}
                />
              </Column>
              <Column
                dataField="AgostoMTons"
                caption="Tons"
                alignment="center"
              >
                <Format
                  type="fixedPoint"
                  precision={0}
                />
              </Column>
              <Column
                dataField="Agosto$/MTons"
                caption="$/MTons"
                format="currency"
                alignment="center"
              >
              </Column>
            </Column> 
            <Column caption={t('common:July')} visible={showColumnsJul} alignment="center">
              <Column
                dataField="JulioActual"
                caption="Actual"
                alignment="center"
              >
                <Format
                  type="fixedPoint"
                  precision={0}
                />
              </Column>
              <Column
                dataField="JulioMTons"
                caption="Tons"
                alignment="center"
              >
                <Format
                  type="fixedPoint"
                  precision={0}
                />
              </Column>
              <Column
                dataField="Julio$/MTons"
                caption="$/MTons"
                format="currency"
                alignment="center"
              > 
              </Column>
            </Column>
            <Column caption={t('common:June')} visible={showColumnsJun} alignment="center">
              <Column
                dataField="JunioActual"
                caption="Actual"
                alignment="center"
              >
                <Format
                  type="fixedPoint"
                  precision={0}
                />
              </Column>
              <Column
                dataField="JunioMTons"
                caption="Tons"
                alignment="center"
              >
                <Format
                  type="fixedPoint"
                  precision={0}
                />
              </Column>
              <Column
                dataField="Junio$/MTons"
                caption="$/MTons"
                format="currency"
                alignment="center"
              > 
              </Column>
            </Column>
            <Column caption={t('common:May')} visible={showColumnsMay} alignment="center">
              <Column
                dataField="MayoActual"
                caption="Actual"
                alignment="center"
              >
                <Format
                  type="fixedPoint"
                  precision={0}
                />
              </Column>
              <Column
                dataField="MayoMTons"
                caption="Tons"
                alignment="center"
              >
                <Format
                  type="fixedPoint"
                  precision={0}
                />
              </Column>
              <Column
                dataField="Mayo$/MTons"
                caption="$/MTons"
                format="currency"
                alignment="center"
              > 
              </Column>
            </Column>

            <Column caption={t('common:April')} visible={showColumnsAbr} alignment="center">
              <Column
                dataField="AbrilActual"
                caption="Actual"
                alignment="center"
              >
                <Format
                  type="fixedPoint"
                  precision={0}
                />
              </Column>
              <Column
                dataField="AbrilMTons"
                caption="Tons"
                alignment="center"
              >
                <Format
                  type="fixedPoint"
                  precision={0}
                />
              </Column>
              <Column
                dataField="Abril$/MTons"
                caption="$/MTons"
                format="currency"
                alignment="center"
              > 
              </Column>
            </Column>

            <Column caption={t('common:March')} visible={showColumnsMar} alignment="center">
              <Column
                dataField="MarzoActual"
                caption="Actual"
                alignment="center"
              >
                <Format
                  type="fixedPoint"
                  precision={0}
                />
              </Column>
              <Column
                dataField="MarzoMTons"
                caption="Tons"
                alignment="center"
              >
                <Format
                  type="fixedPoint"
                  precision={0}
                />
              </Column>
              <Column
                dataField="Marzo$/MTons"
                caption="$/MTons" 
                format="currency"
                alignment="center"
              > 
              </Column>
            </Column>  
        
            <Column caption={t('common:February')} visible={showColumnsFeb} alignment="center">
              <Column
                dataField="FebreroActual"
                caption="Actual"
                alignment="center"
              >
                <Format
                  type="fixedPoint"
                  precision={0}
                />
              </Column>
              <Column
                dataField="FebreroMTons"
                caption="Tons"
                alignment="center"
              >
                <Format
                  type="fixedPoint"
                  precision={0}
                />
              </Column>
              <Column
                dataField="Febrero$/MTons"
                caption="$/MTons" 
                format="currency"
                alignment="center"
              > 
              </Column>
            </Column>
            <Column caption={t('common:January')} visible={showColumnsEne} alignment="center">
              <Column
                dataField="EneroActual" 
                caption="Actual"
                alignment="center"
              >
                <Format
                  type="fixedPoint"
                  precision={0}
                />
              </Column>
              <Column
                dataField="EneroMTons" 
                caption="Tons"
                alignment="center"
              >
                <Format
                  type="fixedPoint"
                  precision={0}
                />
              </Column>
              <Column
                dataField="Enero$/MTons" 
                caption="$/MTons" 
                alignment="center"
                format="currency"
              > 
              </Column>
            </Column>
            

          </TreeList>
        </Col>
      </Row>
    </div> 
  </>
);
};
export default AnalisisCostosGeneral;

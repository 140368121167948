/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next';   
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row,Col,Card, CardHeader, CardTitle, CardBody} from 'reactstrap';   
import { config } from '../../../utils/config';
import { callApi,tracker,showSweetAlert} from '../../../utils/utils.js'; 
import FieldToogleSwitch from 'components/Controls/FieldToogleSwitch/FieldToogleSwitch.jsx';
import { Badge } from 'primereact/badge';
import moment from "moment"; 
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import FieldDataView  from 'components/Controls/FieldDataView/FieldDataView.jsx' 
import FasesActividadesAnexo from "views/ScoreCard/Projects/FasesActividadesAnexo.jsx"
import FasesActividadesComentario from "views/ScoreCard/Projects/FasesActividadesComentario.jsx"
import FieldCalendar  from 'components/Controls/FieldCalendar.jsx' 
import { Dialog } from 'primereact/dialog'; 
import ActionPlanQuickview from '../Scorecard/ActionPlans/ActionPlanQuickview.jsx';
import ActionPlan from '../Scorecard/ActionPlans/ActionPlan.jsx';
import FasesActividades from '../Projects/FasesActividades.jsx';
import { Link } from "react-router-dom";

const MisActividadesPendientes = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common','misActividades']);
  const [errors, setErrors] = useState({}); 
  const [ActividadesPendientes, setActividadesPendientes] = useState([]); 
  const [layout, setLayout] = useState('grid');
  const [EsEstaSemana, setEsEstaSemana] = useState(false); 
  const [EsVerAnexo, setEsVerAnexo] = useState(false); 
  const [EsIniciarActividad, setEsIniciarActividad] = useState(false); 
  const [EsAbrirNotas, setEsAbrirNotas] = useState(false); 
  const [ClaProyecto, setClaProyecto] = useState(null); 
  const [ClaFase, setClaFase] = useState(null); 
  const [ClaActividad, setClaActividad] = useState(null); 
  const [ActividadDescripcion, setActividadDescripcion] = useState(null); 
  const [FechaInicioReal, setFechaInicioReal] = useState(null); 
  const [EsEditarActividadProyecto, setEsEditarActividadProyecto] = useState(false);

  
  const [EsAbrirActionPlan, setEsAbrirActionPlan] = useState(false); 
  const [ClaPeriodo, setClaPeriodo] = useState(null); 
  const [ClaFechaReporte, setClaFechaReporte] = useState(null); 
  const [IdCSF, setIdCSF] = useState(null); 
  const [EsAbrirCreateActionPlan, setEsAbrirCreateActionPlan] = useState(false);
  const editProjectLnk = useRef(null); 



  useEffect(() => {    
    obtenerMisActividades(EsEstaSemana);
  }, [props.filters]); 

  const obtenerMisActividades = (EsEstaSemana) => { 
    const GetActividadesPendientes = `${config.UrlApiProject}ScoreCard/GetProyectoSeguimientoPendientes`;  
    const paramsToService = {
      ...props.filters,
      // ClaFase:null,
      ClaActividad:null, 
      EsEstaSemana
    };   
    callApi(GetActividadesPendientes, 'POST', paramsToService, (response) => { 
      setActividadesPendientes(response.data.ProyectoActividadesPendientes);
    });
  }

  const onClickActionPlan = (row) => {  
    setClaPeriodo(row.ClaPeriodo);
    setClaFechaReporte(row.ClaFechaReporte);
    setIdCSF(row.IdCSF);
    setEsAbrirActionPlan(true);
  }
  
  const onHideActionPlan = () => {
    setEsAbrirActionPlan(false); 
  }
  

  const renderGridItem = (data) => {
    return (
      <div className="p-col-12">
        <div className="dataview-content"> 
          <Row className="">
            <Col lg={8} md={8} sm={12} xs={12}> 
              {
                  data.ActivityType === 1 ? (
                      
                    <span className="bold"> 
                      { LabelTypeActivity(data)} : &nbsp;
                      {  
                        data.EsEnProyectoMaestro === 1 ?  
                          <span className="btn-inner--icon"><i title={t('misActividades:MasterProjectAward')} className="fa fa-award purple-color" /></span> 
                        : 
                        ''
                      }
                      <a 
                        className='Cursor overflow-ellipsis' 
                        style={{color:'#5e72e4'}} 
                        onClick={()=> onProjectClick(data.ClaProjectRel)}
                      >
                        {data.ClaProjectRel} - {data.NomProyecto}
                      </a>           
                    </span>
                   
                  ):(
                    <span className="bold">{
                    data.ActivityType === 5 ? 
                    data.Descripcion
                    :
                    data.NomProyecto
                    }
                    </span>
)
              }
            </Col> 
            <Col lg={4} md={4} sm={12} xs={12} className="text-right">
              <small className="bold">{t('misActividades:CreatedBy')}:</small> &nbsp;
              <small>{data.NomCreatedBy} </small>
            </Col>
          </Row>
          <Row className="">
            <Col lg={8} md={8} sm={12} xs={12} className="">
              {
                data.ActivityType === 1 ? <span className="bold">{t('misActividades:Phase')}: </span> : <span className="bold">{t('misActividades:Source')}: </span>
              }
              &nbsp; 
              <span className="secondary-color"> 
                {
                  data.ActivityType !== 1 ? `${data.NomTipoIndicador === null ? '' : `${data.NomTipoIndicador} -` } ${data.ClaProjectRel === null ? '' : data.ClaProjectRel} ${data.ClaProjectRel !== null ? '-' : ''} ${data.NomFase}` : data.NomFase 
              }
              </span>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12} className="text-right">
              <small className="bold">{t('misActividades:Responsible')}:</small> &nbsp;
                       
              <small>{data.NomResponsableActividad} </small>
            
            </Col>
          </Row>
          <Row className="">
            <Col lg={12} md={12} sm={12} xs={12} className="">
              {
                data.ActivityType === 1 ? (
                  <><span className="bold">{t('misActividades:Activity')}: </span> 
                  
                    {
                    data.EsPM === 1 ? 
                    (<span className='Cursor' onClick={() => AbrirEdicionActividad(data)}><span className="fa fa-pencil-alt"></span>&nbsp;{data.NomActividad}</span>)
                    :
                    (<>&nbsp;{data.NomActividad}</>)
                  }

                  </> 
                )
                :
                (<></>)
              }   

              {
                data.ActivityType === 5 ? (
                  <><span className="">{data.NomFase}</span></> 
                )
                :
                (<></>)
              }   
              {
                data.FechaReporte !== null ? (<div><span className="bold">{t('misActividades:ReportedDate')}: </span><small className="info-color bold"><i className="pi pi-calendar"></i> &nbsp;{moment(data.FechaReporte).format("ll")}</small></div>) : (<></>)
              }
            </Col> 
          </Row>
          <Row className="align-items-start">
            <Col lg={6} md={6} sm={12} xs={12}>
              <span className="bold">{t('misActividades:Period')}: </span>&nbsp;
              <small className="info-color bold"> 
                <i className="pi pi-calendar"></i> &nbsp; 
                {moment(data.FechaInicia).format("ll")} - &nbsp; 
                {moment(data.FechaEstimadaTermina).format("ll")}
              </small>
              { 
                data.DiasAtraso > 0 ? 
                (<div> <span className="bold"> <i className="fas fa-exclamation-triangle warning-color bold"></i> </span>&nbsp;<small className="warning-color bold"> {data.DiasAtraso}&nbsp;{t('misActividades:DaysDelayed')} </small></div>)
                :
                (<></>)
              }
            </Col>
            <Col lg={6} md={6} sm={12} xs={12} className="text-right" hidden={data.ActivityType !== 1}>  
              { data.ActivityType === 1 ? 
                (<FieldButton icon="fas fa-download" tooltip={t('misActividades:DownloadSupportDocuments')} title={t('misActividades:DownloadSupportDocuments')} onClick={() => AbrirAnexo(data)} className="p-button-rounded p-button-text" />)
                :
                (<></>)}

              <FieldButton icon="fas fa-comments" tooltip={t('misActividades:AddViewComments')} title={t('misActividades:AddViewComments')} onClick={() => AbrirNotas(data)} className={`p-button-rounded p-button-text ${ data.EsTieneComentarios === 1 ? 'light-yellow-color' : 'light-gray-color' }`}  />
              <FieldButton label={t('misActividades:StartTask')} icon="fas fa-play-circle" tooltip={t('misActividades:StartTask')} onClick={() => AbrirInicioFecha(data)} className="p-button-rounded p-button-success p-button-text" />
            </Col>
            <Col lg={6} md={6} sm={12} xs={12} className="text-right" hidden={data.ActivityType !== 5}>  
              <FieldButton label={t('misActividades:CreateActionPlan')} icon="fa fa-flag danger-color Cursor" tooltip={t('misActividades:CreateActionPlan')} onClick={() => onClickCreateActionPlan(data)} className="p-button-rounded p-button-success p-button-text" />
            </Col>
          </Row> 
          <Row className="">
            <Col lg={12} md={12} sm={12} xs={12}>
              <div className="border-color-info-color" style={{borderBottom: "1px solid"}}></div>
            </Col>
          </Row> 
        </div>  
      </div>
    );
  }
  
  const itemTemplate = (product, layout) => {
    if (!product) {
        return;
    }
    if (layout === 'grid')
        return renderGridItem(product);
  }

  const closeModal = () => {
   setEsVerAnexo(false);
   setActividadDescripcion(null);
  } 

  const dialogStyle  = ( 
      { 
      width: '80vw'
    
  })

  const dialogActividadStyle  = ( 
    { 
    width: '30vw'
    
  })

  const AbrirAnexo = async (data) => {
      setActividadDescripcion(data.Descripcion);
      setClaProyecto(data.ClaProyecto);
      setClaFase(data.ClaFase);
      setClaActividad(data.ClaActividad);
      setEsVerAnexo(!EsVerAnexo); 
      tracker.trackEvent({
        type: 'MisActividadesPendientes', /* component name */
        action: 'VIEW_SUPPORT_DOCUMENTS' 
      })
  }
    
  const AbrirInicioFecha = async (data) => {
    setClaProyecto(data.ClaProyecto);
    setClaFase(data.ClaFase);
    setClaActividad(data.ClaActividad);
    setEsIniciarActividad(!EsIniciarActividad); 
  }

  const AbrirNotas = async (data) => {
    setClaProyecto(data.ClaProyecto);
    setClaFase(data.ClaFase);
    setClaActividad(data.ClaActividad);
    setEsAbrirNotas(!EsAbrirNotas);
    tracker.trackEvent({
      type: 'MisActividadesPendientes', /* component name */
      action: 'VIEW_COMMENTS' 
    })
  }

  const AbrirEdicionActividad = async (data) => { 
    setClaProyecto(data.ClaProyecto);
    setClaFase(data.ClaFase);
    setClaActividad(data.ClaActividad);
    tracker.trackEvent({
      type: 'MisActividadesPendientes', /* component name */
      action: 'VIEW_ACTIVITY_DETAIL'
    })
 
    setEsEditarActividadProyecto(!EsEditarActividadProyecto);
  }

  const closeModalNotas = () => {
    setEsAbrirNotas(false); 
  } 

  const closeModalActividad = () => {
    setFechaInicioReal(null);
    setEsIniciarActividad(false); 
  } 

  const onFechaInicioRealChange = ( e ) => {  
    const newErrors = { ...errors }
    delete newErrors.FechaInicioReal;   
    setErrors(newErrors);
    setFechaInicioReal(e.value);
  }; 

  const valitationSchemeStart = yup.object().shape({
    FechaInicioReal:yup.date().required(t('common:RequiredField')).nullable()
  });  

  const IniciarActividad = async () => {
    const value = {
      FechaInicioReal
    }
    const validator = new YupValidator(valitationSchemeStart);
    const esValido = await validator.validate(value);
    if (!esValido){ 
      setErrors(validator.errors); 
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      return;
    }
    const urlWebServiceIniciar = `${config.UrlApiProject}ScoreCard/StartActividadesFases`;
    const paramsService = {
      ClaProyecto,
      ClaFase,
      ClaActividad,
      FechaRealInicio:(FechaInicioReal!== null ? (new Date(FechaInicioReal)): null),
    }; 
    callApi(urlWebServiceIniciar, 'POST', paramsService, (response) => { 
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      obtenerMisActividades();
      closeModalActividad();
      if (props.onChange !== null && props.onChange !== 'undefined' && typeof(props.onChange) === 'function'){
        props.onChange();
      }
    });
  }

  const onClickCloseComentario = () => {
    closeModalNotas();
    obtenerMisActividades(EsEstaSemana);
  };

  const onChangeEsEstaSemana = (value) => {   
    setEsEstaSemana(value) 
    obtenerMisActividades(value);
  }

  const LabelTypeActivity = (data)=> {
    let labelTypeActivity = ""; 
    switch (data.ActivityType) {
      case 1:
        labelTypeActivity = t('misActividades:Project')
        break;
      case 2:
        labelTypeActivity = t('misActividades:Source')
        break;       
      case 3: 
        labelTypeActivity = t('misActividades:Source')
        break;       
      case 4: 
        labelTypeActivity = t('misActividades:Source')
        break;
      case 5:
          labelTypeActivity = t('misActividades:Source')
          break;
      default:
        labelTypeActivity = t('misActividades:Project')
        break;
    }
    return labelTypeActivity;
  }

  const onHideAbrirCreateActionPlan = () => {
    setEsAbrirCreateActionPlan(false);
    obtenerMisActividades(EsEstaSemana);
    if (props.onChange !== null && props.onChange !== 'undefined' && typeof(props.onChange) === 'function'){
      props.onChange();
    }
  }

  const onClickCreateActionPlan = (row) => {   
    setClaPeriodo(row.ClaPeriodo);
    setClaFechaReporte(row.ClaFechaReporte);
    setIdCSF(row.IdCSF);
    setEsAbrirCreateActionPlan(true); 
    tracker.trackEvent({
      type: 'MisActividadesPendientes', /* component name */
      action: 'CREATE_NEW_ACTION_PLAN'
    })
  }

  const onCloseActivityDetail = () => {
    
    setClaProyecto(null);
    setClaFase(null);
    setClaActividad(null);
    setEsEditarActividadProyecto(false); 
    obtenerMisActividades(EsEstaSemana);
    if (props.onChange !== null && props.onChange !== 'undefined' && typeof(props.onChange) === 'function'){
      props.onChange();
    }
  };

  const onProjectClick = (claProyecto) => {    
    tracker.trackEvent({
      type: 'MisActividadesPendientes', /* component name */
      action: 'OPEN_EDIT_PROJECT'
    })
    if (props.onOpenProject) props.onOpenProject(claProyecto);
  };
  
    return (
      <>      
        <Row className="pt-3">
          <Col lg={12} md={12} sm={12} xs={12}>
            <Card>
              <CardHeader className="info">
                <CardTitle>
                  <Row className="pt-3">
                    <Col>
                      {t('misActividades:PendingTasks')}
                    </Col>
                    <Col className="text-right">
                      <Badge
                        value={ActividadesPendientes.length}
                        className="info-dark"
                        size="large"
                      />  
                    </Col>
                  </Row> 
                  <Row className="pt-2">
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <small className="white-color bold" style={{}}> 
                        <span>
                          {t('misActividades:ThisWeek')}
                        </span> 
                      </small> &nbsp;
                      <FieldToogleSwitch
                        id="EsEstaSemana"
                        className="white-color"
                        checked={EsEstaSemana}
                        onChange={onChangeEsEstaSemana}  
                        small
                      />
                    </Col>
                  </Row>
                </CardTitle>
              </CardHeader>    
              <CardBody> 
                <FieldDataView
                  value={ActividadesPendientes}
                  layout={layout}
                  itemTemplate={itemTemplate}
                  paginator
                  rows={50}
                  className="dataview-list"
                  paginatorClassName="painatorfixed"
                />
              </CardBody>
            </Card>  
          </Col>
        </Row>
        <Dialog
          visible={EsVerAnexo}
          header={t('misActividades:ActivityDocuments')}
          style={dialogStyle} 
          modal
          className="dialog-custom"
          onHide={closeModal}
        >
          <Row>
            <Col>
              <h3> {ActividadDescripcion} </h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <FasesActividadesAnexo IsUpload={false} IsReadyOnly={EsVerAnexo} ClaProyecto={ClaProyecto} ClaFase={ClaFase} ClaActividad={ClaActividad}  />
            </Col>
          </Row>
        </Dialog>
        <Dialog
          visible={EsIniciarActividad}
          header={t('misActividades:TituloIniciarActividad')}
          style={dialogActividadStyle} 
          modal
          className="dialog-custom"
          onHide={closeModalActividad}
        >
          <Row className="align-items-start">
            <Col>
              <div className="p-fluid">
                <div className="p-field"> 
                  <FieldCalendar
                    name="FechaInicioReal" 
                    value={(FechaInicioReal!== null ? (new Date(FechaInicioReal)): null)} 
                    onChange={(e) => onFechaInicioRealChange(e)} 
                    errors={errors}
                  />
                </div> 
              </div> 
            </Col>
            <Col className="text-right">
              <FieldButton label={t('misActividades:Start')} onClick={() => IniciarActividad()} className="success no-border pr-3 pl-3 btn-min-width"  /> 
            </Col>
          </Row>
             
        </Dialog>
        <Dialog
          visible={EsAbrirNotas}
          header={t('misActividades:TituloFaseActividadNotas')}
          style={dialogStyle} 
          modal
          className="dialog-custom"
          onHide={closeModalNotas}
        >
          <Row>
            <Col>
              <FasesActividadesComentario onClickCloseComentario={onClickCloseComentario} ClaProyecto={ClaProyecto} ClaFase={ClaFase} ClaActividad={ClaActividad}  />
            </Col>
          </Row>
        </Dialog>  
        {
          EsAbrirCreateActionPlan ?
          (  
            <ActionPlan show={EsAbrirCreateActionPlan} onHide={onHideAbrirCreateActionPlan} IdCSF={IdCSF} ClaPeriodo={ClaPeriodo} ClaFechaReporte={ClaFechaReporte}></ActionPlan>
          )
          :
          (<></>)
        }

        {
        EsEditarActividadProyecto!= null ? (
          <> 
            <FasesActividades IsVisible={EsEditarActividadProyecto} onClickClose={onCloseActivityDetail} ClaProyecto={ClaProyecto} ClaFase={ClaFase} ClaActividad={ClaActividad}  />
          </>
          ):(<></>)
       }
      </> 
        ); 
};
export default MisActividadesPendientes;

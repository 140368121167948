import React from 'react';
import PageContent from '../../../layouts/PageContent'; 
import { useTranslation } from 'react-i18next';
import ManageBusinessCards from './ManageBusinessCards';

const ManageBusinessCardsScreen = (props) => {
  const { t } = useTranslation(['qr', 'common']);

  return (
    <PageContent title={t('qr:ManageBusinessCards')}>
      <ManageBusinessCards />
    </PageContent>
  )
}

export default ManageBusinessCardsScreen;
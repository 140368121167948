import React, { useState, useRef } from 'react';  
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack'; 
import PDFPage from './PDFPage';
import PDFToolbar from './PDFToolbar'; 
import { Dialog } from 'primereact/dialog'; 
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import printJS from 'print-js'
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardHeader} from 'reactstrap';

export default function PDFViewer(props) {
    
    const { t } = useTranslation(['common']);

    pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker; // `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [rotation, setRotation] = useState(0);
    
    const PDFcontainer = useRef(null);

    const [fitTo, setFitTo] = useState('page');
    const [scale, setScale] = useState(1); 

    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
      setPageNumber(1); 
    }

    function onPageChange(pageNumber) {
        setPageNumber(pageNumber); 
      }
  
    function changePage(offset) {
      setPageNumber(prevPageNumber => prevPageNumber + offset);
    }
  
    function previousPage() {
      changePage(-1);
    }
  
    function nextPage() {
      changePage(1);
    } 

    function onZoomToFit(fitTo) {
        setScale(1);
        setFitTo(fitTo);  
     }

    function onRotateLeft() {
      setRotation(rotation === 0 ? 270 : rotation - 90);
      console.log(rotation);
    }

    function onRotateRight() {
      setRotation(rotation === 270 ? 0 : rotation + 90);
      console.log(rotation);
    }
    
     function onDownloadDocument() {
        if (file.includes('.')) {
          
          printJS({printable:file, type:'pdf', showModal:true})
        }
        else {
          let b64File = file;

          const searchString = 'base64,';
          if (file.search(searchString) !== -1) {
            b64File = file.split(searchString).pop();
          }

          printJS({printable: b64File, type: 'pdf', base64: true, showModal: true});
        }
      }
     

      function onLoadSuccess(page) {  
        const pageScale = PDFcontainer.current.clientWidth / page.originalWidth

       
        if (PDFcontainer.current.clientWidth < 350) {
          // si es un monitor chico, escalamos la imagen por default...
          setScale(pageScale);
        } 
         
      }
      
     const renderHeader = () => {
        return (
          <>

 


            <PDFToolbar
              fitTo={fitTo}
              onZoomToFit={fitTo => onZoomToFit(fitTo)}
              onZoomIn={() => setScale(scale * 1.1)}
              onZoomOut={() => setScale(Math.max(0.1, scale * 0.9))}
              onDownloadDocument={onDownloadDocument}
              onRotateLeft={onRotateLeft}
              onRotateRight={onRotateRight}
              copyLink={props.copyLink}
              downloadFile={props.downloadFile}
            /> 
            <div className="text-center">
              { numPages && (
              <div className="page-counter">
                {t('common:Page')} {pageNumber} {t('common:Of')} {numPages}
              </div>
        )}
            </div>
            
          </>
        );
    }


    const { file } = props;
     /*
      
        
     */
     // https://codesandbox.io/s/62yw483vpw?file=/src/PageList.js
    return (
      <>
        {props.ShowDialog === false ?
          (
            <Card className='pdf-viewer-card'>
              <CardHeader className='pt-2'>
                {renderHeader()}
              </CardHeader>
              <CardBody>
                <div ref={PDFcontainer} className="pdf-container">
                  <Document
                    file={file} 
                    onLoadSuccess={onDocumentLoadSuccess}
                  > 
                    <PDFPage
                      container={PDFcontainer}
                      pageCount={numPages}
                      scale={scale}
                      fitTo={fitTo}
                      onPageChange={onPageChange}
                      onLoadSuccess={onLoadSuccess}
                      rotation={rotation}
                    /> 
                  </Document> 
                </div>
              </CardBody>
            </Card>
          )
          : (
            <Dialog visible={props.show} modal style={{width: '80vw' }} footer=" " header={renderHeader} className="pdf-viewer-dialog" maximizable onHide={props.onHide}>    
              <div ref={PDFcontainer} className="pdf-container">
                <Document
                  file={file} 
                  onLoadSuccess={onDocumentLoadSuccess}
                > 
                  <PDFPage
                    container={PDFcontainer}
                    pageCount={numPages}
                    scale={scale}
                    fitTo={fitTo}
                    onPageChange={onPageChange}
                    onLoadSuccess={onLoadSuccess}
                    rotation={rotation}
                  /> 
                </Document> 
              </div> 
            </Dialog>
          ) }
      </>
    );
};
  

/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next';  
import { Row, Col } from 'reactstrap';
import { config } from '../../../../utils/config';
import { callApi } from '../../../../utils/utils';
import SurveyManagementHeadSurveyEdit from './SurveyManagementHeadSurveyEdit';
import SurveyManagementTopics from './SurveyManagementTopics.jsx';

const SurveyManagementHeadSurveyLoad = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['survey', 'common']);
  const [IdEncuesta, setIdEncuesta] = useState(null);
  const [Titulo, setTitulo] = useState(null);
  const [Descripcion, setDescripcion] = useState(null);
  const [DescripcionHtml, setDescripcionHtml] = useState(null);
  const [EsHabilitarEditarSurvey, setEsHabilitarEditarSurvey] = useState(false);
  const [Index, setIndex] = useState(null);

  useEffect(() => { 
    setIdEncuesta(props.IdEncuesta === undefined ? null : props.IdEncuesta);
    setTitulo(props.Titulo === undefined ? null : props.Titulo);
    setDescripcion(props.Descripcion === undefined ? null : props.Descripcion);
    setDescripcionHtml(props.DescripcionHtml === undefined ? null : props.DescripcionHtml);
    setIndex(props.Index === undefined ? null : props.Index);

  }, [props.IdEncuesta, props.ForceRefresh, props.Titulo, props.Descripcion, props.DescripcionHtml, props.Index]);

  const EditarSurvey = () => {
    setEsHabilitarEditarSurvey(true);
  }

  const onClickCloseEditarSurvey = () => {
    setEsHabilitarEditarSurvey(false); 
    if (
      props.onSearch !== null &&
      props.onSearch !== 'undefined' &&
      typeof props.onSearch === 'function'
    ) {
      props.onSearch();
    }
  };  

  return (
    <>
      {EsHabilitarEditarSurvey === false ? (
        <>
          <div className='page-container-no-edit' onClick={EditarSurvey}>
            <Row>
              <Col xs={12} sm={12} ms={12} lg={12}>
                <div>
                  <span className='h1'>{Titulo}</span>
                </div>
                <div dangerouslySetInnerHTML={{ __html: DescripcionHtml }}>
                </div>
              </Col> 
            </Row>
          </div>
          <div>
            <SurveyManagementTopics IdEncuesta={props.IdEncuesta}></SurveyManagementTopics>
          </div>
        </>
          ) : (
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}> 
                <div className="pb-3 p-mr-2">
                  <SurveyManagementHeadSurveyEdit
                    onClickCloseEditarSurvey={onClickCloseEditarSurvey} 
                    IdEncuesta={IdEncuesta}
                    Titulo={Titulo}
                    Descripcion={Descripcion}
                    DescripcionHtml={DescripcionHtml}
                  >
                  </SurveyManagementHeadSurveyEdit>
                </div>  
              </Col>   
            </Row>
          )}
    </>
  );
};

export default SurveyManagementHeadSurveyLoad;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from 'utils/config';
import { callApi } from 'utils/utils';
import { Panel } from 'primereact/panel';
import { Row, Col, Label } from 'reactstrap';
import FieldText from 'components/Controls/FieldText';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import FieldAutoComplete from 'components/Controls/FieldAutoComplete';

const ViewEmployeeITAssetsFilters = (props) => {
  const {t, i18n} = useTranslation(['it', 'common']);

  const [filters, setFilters] = useState({}); // Filters
  const [idITEquipo, setIdITEquipo] = useState(null); // Filter by id
  const [assignedUser, setAssignedUser] = useState(null); // Filter by assigned to user
  const [errors, setErrors] = useState([]); // Errors
  const [users, setUsers] = useState([]); // User suggestions
  const [location, setLocation] = useState(null); // ClaUbicacion
  const [locations, setLocations] = useState(null);
  const [assignedTo, setAssignedTo] = useState(null); // Assigned to Description
  const [adpEmployees, setAdpEmployees] = useState([]);
  const [adpEmployee, setAdpEmployee] = useState(null);

  useEffect(() => {
    const onKeyDown = (e) => {
      if(e.keyCode === 13) {
        updateFilters();
      }
    }
    
    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [idITEquipo, location, assignedTo, adpEmployee, assignedUser])

  const filterAdpEmployee = (e) => {
    let valor = ''; 
    if (adpEmployee.FullName !== null && adpEmployee.FullName !== '' &&  e.query === "")
    {
     valor = adpEmployee.FullName;
    }
    else{
     valor = e.query;
    }
    const paramsServiceOwners = {};
    const urlWebServiceOwners = `${config.UrlApiProject}HR/CmbADPEmployee?valor=${valor}`;
    callApi(urlWebServiceOwners, 'GET', paramsServiceOwners, (response) => {  
       setAdpEmployees(response.data);
    });    
  }

  const onAdpEmployeeChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.adpEmployee;
    setErrors(newErrors);
    setAdpEmployee(e.value);
  }

  const onChangeIdFilter = (e) => {
    setIdITEquipo(e.target.value === undefined ? null : e.target.value);
  }

  const onChangeAssignedUserFilter = (e) => {
    setAssignedUser(e.value);
  }

  const filterUser = (e) => {
    let valor = ''; 
    if (assignedUser.NomUsuario !== null && assignedUser.NomUsuario !== '' &&  e.query === "")
    {
     valor = assignedUser.NomUsuario;
    }
    else{
     valor = e.query;
    }
    const paramsServiceOwners = {};
    // event.query 
    const urlWebServiceOwners = `${config.UrlApiProject}IT/GetUsers?valor=${valor}`;
    // Get owner
    callApi(urlWebServiceOwners, 'GET', paramsServiceOwners, (response) => {  
       setUsers(response.data.Users);
    });    
  }

  const filterLocations = (e) => {
    console.log(e);
    let valor = ''; 
    if (location !== null && location !== '' &&  e.query === "")
    {
     valor = location.NomUbicacion;
    }
    else{
     valor = e.query;
    }
    const paramsServiceOwners = {};
    
    const urlWebServiceOwners = `${config.UrlApiProject}IT/GetUbicaciones?${e.query === '' ? '' : `valor=${valor}`}`;

    callApi(urlWebServiceOwners, 'GET', paramsServiceOwners, (response) => {  
       setLocations(response.data.Locations);
    });    
  }

  const onLocationChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.location;
    setErrors(newErrors);
    setLocation(e.value);
  }

  const onChangeAssignedTo = (e) => {
    const newErrors = {...errors};
    delete newErrors.assignnedTo;
    setErrors(newErrors);
    setAssignedTo(e.target.value);
  }

  const updateFilters = () => {
    // console.log(idITEquipo);
    // console.log(assignedUser);
    // console.log(adpEmployee);
    // console.log(location);
    // console.log(assignedTo);
    props.setFilters({
      IdITEquipo: idITEquipo,
      ClaUsuarioAsignado: assignedUser !== null ? assignedUser.ClaUsuario : null,
      ClaADPEmployee: adpEmployee !== null ? adpEmployee.ClaADPEmployee : null,
      AssignedTo: assignedTo,
      ClaUbicacion: location !== null ? location.ClaUbicacion: null
    })
  }

  const onClickClearFilters = () => {
    setIdITEquipo(null);
    setAssignedUser(null);
    setLocation(null);
    setAssignedTo(null);
    setAdpEmployee(null);
    props.setFilters({});
  }

  const filterHeaderTemplate = (options) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down text-white' : 'pi pi-chevron-up text-white';
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className='card-header'>
        <div className='p-toolbar p-component'>
          <div className='p-toolbar-group-left'>
            <div className='card-title'>
              <FieldButton
                className={options.togglerClassName}
                onClick={options.onTogglerClick}
              >
                <span className={toggleIcon}></span>
              </FieldButton>
              <span className={titleClassName}>
                {t('common:Filters')}
              </span>
            </div>
          </div>

          <div className='p-toolbar-group-right'>
            <FieldButton className="p-button p-button-icon-only p-highlight mr-2" onClick={() => updateFilters()}>
              <i className="pi pi-search"></i>
            </FieldButton>
            <FieldButton className="p-button-danger p-button-icon-only p-highlight" onClick={onClickClearFilters}>
              <i className="pi pi-times"></i>
            </FieldButton>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Panel
        header={t('common:Filters')}
        toggleable
        headerTemplate={filterHeaderTemplate}
      >
        <Row className='p-fluid p-grid p-formgroup-inline align-items-end'>
          <Col xs={12} sm={12} md={6} lg={2}>
            <div className='p-field'>
              <Label>{t('it:AssetID')}</Label>
              <FieldText
                id='idITEquipo'
                name='idITEquipo'
                value={idITEquipo}
                onChange={onChangeIdFilter}
              >
              </FieldText>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={2}>
            <div className='p-field'>
              <Label>{t('it:AssignedTo')}</Label>
              <FieldText
                id='assignedTo'
                name='assignedTo'
                value={assignedTo}
                onChange={onChangeAssignedTo}
                errors={errors}
              >
              </FieldText>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={2}>
            <div className='p-field'>
              <Label>{t('it:Location')}</Label>
              <FieldAutoComplete
                dropdown
                id="location"
                name="location"
                value={location}
                onChange={onLocationChange}
                suggestions={locations}
                field="NomUbicacion"
                completeMethod={filterLocations}
                errors={errors}
              />
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={3}>
            <div className='p-field'>
              <Label>{t('it:User')}</Label>
              <FieldAutoComplete
                dropdown
                name="assignedUser"
                value={assignedUser}
                onChange={onChangeAssignedUserFilter}
                suggestions={users}
                field="NomUsuario"
                completeMethod={filterUser}
                errors={errors}
              /> 
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={3}>
            <div className='p-field'>
              <Label>{t("it:EmployeeID")}</Label>
              <FieldAutoComplete
                dropdown
                id="adpEmployee"
                name="adpEmployee"
                value={adpEmployee}
                onChange={onAdpEmployeeChange}
                suggestions={adpEmployees}
                field="IdFullName"
                completeMethod={filterAdpEmployee}
                errors={errors}
              />
            </div>
          </Col>
        </Row>
      </Panel>
    </>
  )
}

export default ViewEmployeeITAssetsFilters;
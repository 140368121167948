/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'; 
import { useTranslation } from 'react-i18next'; 
import PageContent from '../../../layouts/PageContent';  
import { Row,Col, TabContent, TabPane , Nav, NavItem, NavLink, Card, CardBody} from 'reactstrap';      
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import AltaProyecto  from '../Projects/AltaProyecto.jsx';
import EdicionProyecto  from '../Projects/EdicionProyecto.jsx';
import MisActividades from './MisActividades.jsx';
import OrganizationalPositionView from '../Scorecard/OrganizationalPositionView.jsx';
import { callApi, tracker, getSessionItem, showSweetAlert } from '../../../utils/utils';  



const MyActivities = (props) => { 
  
 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['myactivities', 'common']); 
  
  const [EsMostrarAltraProyecto, setEsMostrarAltraProyecto] = useState(false);
  const [TabActiveIndex, setTabActiveIndex] = useState(1);
  const [ClaUsuarioFilter, setClaUsuarioFilter] = useState(null);
  
  useEffect(() => { 
    console.log(getSessionItem('ClaUsuarioMod', 0));
    // DEFAULT EL USUARIO CONECTADO....
    setClaUsuarioFilter(getSessionItem('ClaUsuarioMod', 0));
  }, []);

  
  
  const onClickNewProyect = () => {  
    setEsMostrarAltraProyecto(true);
  } 

  const onCloseAltaProyecto = () => { 
    setEsMostrarAltraProyecto(false);
  } 

  const switchTab = tab => {
    if(TabActiveIndex !== tab){
      setTabActiveIndex(tab);
    }
  }

  const organizationalView = (
    <div className='organization-default-width'>
      <OrganizationalPositionView showPositionName={true} onChange={(itemValue, itemNode) => onChangeOrganizationalValue(itemValue, itemNode)}></OrganizationalPositionView> 
    </div>
  )

  const onChangeOrganizationalValue = (itemValue, itemNode)=> { 
    // console.log(itemNode);
    // key = ClaUsuario
    setClaUsuarioFilter(itemNode.key);
  }
  

    return (
      <>  
        <PageContent title={t('myactivities:MyActivities')} toolBar={organizationalView}>  
          <Row hidden={true}>
            <Col lg={12} md={12} sm={12} xs={12} className="text-right">    
              <FieldButton label={t('myactivities:NewProject')} icon="pi pi-plus" className="light-green no-border p-mr-2 rounded-button" onClick={onClickNewProyect}  /> 
            </Col>  
          </Row>
          <Row>
            <Col>
               &nbsp;
            </Col>
          </Row>  
          <Card> 
            <CardBody className="" style={{paddingTop: "5px"}}> 
              <Row>
                <Col>
                  <div className="p-tabview p-component pb-3">
                    <Nav className="p-tabview-nav"> 
                      <NavItem className={TabActiveIndex === 1 ? "p-tabview-selected p-highlight p-unselectable-text nav-item " : "p-unselectable-text nav-item "}>
                        <NavLink className="p-tabview-nav-link" onClick={() => { switchTab(1); }}>
                          {t('myactivities:MyActivities')}
                        </NavLink>
                      </NavItem> 
                      <NavItem className={TabActiveIndex === 2 ? "p-tabview-selected p-highlight p-unselectable-text nav-item " : "p-unselectable-text nav-item "}>
                        <NavLink className="p-tabview-nav-link" onClick={() => { switchTab(2); }}>
                          {t('myactivities:MyTeamActivities')}
                        </NavLink>
                      </NavItem> 
                    </Nav> 
                  </div>
                  <TabContent className="p-tabview-panels" activeTab={TabActiveIndex}>
                    <TabPane tabId={1}>   
                      <Row>
                        <Col>
                          <MisActividades ClaUsuarioFilter={ClaUsuarioFilter}></MisActividades>
                        </Col>
                      </Row> 
                    </TabPane>
                    <TabPane tabId={2}>             
                      <Row>
                        <Col>
                          <MisActividades ClaUsuarioFilter={ClaUsuarioFilter} IsMyTeamActivities={true}></MisActividades>
                        </Col>
                      </Row>   
                    </TabPane>
                  </TabContent>                  
                </Col>
              </Row>
            </CardBody>
          </Card> 
          { EsMostrarAltraProyecto ? <AltaProyecto show={EsMostrarAltraProyecto} onHide={() => onCloseAltaProyecto()}></AltaProyecto> : <></>}
          
        </PageContent>
      </>  
        ); 
};

export default MyActivities;

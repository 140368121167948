/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React  from 'react';
import PropTypes from 'prop-types';
import "./InfoNeedsToBeRefresh.scss"; 
import FieldButton from 'components/Controls/FieldButton/FieldButton';
  

function InfoNeedsToBeRefresh ({ 
     className, 
     onRefresh
  }) { 
 
    return (  
      <>  
        <div className={className === null || typeof(className) === 'undefined' ? "infoNeedsToBeRefreshCard" : className}>
          <h1>Updated Filters</h1>
          <div className='infoNeedsToBeRefreshCard-legend pt-4'>We need to refresh the information, please click following button once you set up all your filters.</div>
          <FieldButton
            icon="fas fa-sync-alt" 
            label="Refresh"
            className="p-button infoNeedsToBeRefreshCard-button"
            onClick={onRefresh}
          >
          </FieldButton>
        </div>
      </> 
    )
}    

export default InfoNeedsToBeRefresh; 

/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next'; 
import { Row, Col } from 'reactstrap'; 
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../../utils/utils';
import * as yup from 'yup';
import YupValidator from '../../../../utils/YupValidator'; 
import SweatAlertCustomIcon from '../../../../components/SweatAlert/SweatAlertCustomIcon'; 
import FieldButton from '../../../../components/Controls/FieldButton/FieldButton.jsx'; 
import FieldText from 'components/Controls/FieldText'; 
import './Survey.css';
import { Tooltip } from 'primereact/tooltip';
import { Divider } from 'primereact/divider';

const SurveyManagementTopics = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['survey', 'common']);
 
  const [EncuestaTopicoList, setEncuestaTopicoList] = useState([]);  
  const [errors, setErrors] = useState({});
  const [IdEncuesta, setIdEncuesta] = useState(null); 
  const [EsEditar , setEsEditar ] = useState(false);

  useEffect(() => {
    setIdEncuesta(props.IdEncuesta === undefined ? null : props.IdEncuesta);
  }, [
    props.IdEncuesta,
    props.ForceRefresh,
    props.EsEditar,
  ]);

  

  const obtenerEncuestaTopico = () => {
    const GetEncuestaTopicoCmb = `${config.UrlApiProject}HR/GetEncuestaTopicoCmb?IdEncuesta=${props.IdEncuesta}`;
    const paramsToService = {};
    callApi(GetEncuestaTopicoCmb, 'GET', paramsToService, (response) => {
      setEncuestaTopicoList(response.data.EncuestaTopicoList);
    });
  };


  const AddNewItemArregloMultiSel = () => {
    const newRow = {
      RowIndex: EncuestaTopicoList !== undefined ? EncuestaTopicoList.length : 0,
      Titulo: null,
      IdEncuestaTopico: null,
      IdEncuesta: props.IdEncuesta,
      BajaLogica: 0,
    };

    setEncuestaTopicoList([...EncuestaTopicoList, newRow]);
  };

  const onSaveEncuestaTopicClick = async () => {
    const value = {
       EncuestaTopicoList, 
    };

    const validator = new YupValidator(valitationSchemeSave);
    const esValido = await validator.validate(value);
    if (!esValido) {
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      return;
    }

    const paramsService = {
      IdEncuesta,
      SurveyManagementTopico: EncuestaTopicoList 
    };

    const SaveSurveyEncuestaTopico = `${config.UrlApiProject}HR/SaveSurveyEncuestaTopico`;

    callApi(SaveSurveyEncuestaTopico, 'POST', paramsService, (response) => { 
      setErrors({}); 
      showSweetAlert(t('common:Success'), t('common:SuccessOperation'), 'success');
      setEsEditar(false);
    });
  };  

  const confirmationMessageHTML = (
    <div>
      <SweatAlertCustomIcon
        title={t('common:SubmitConfirmation')}
        letter={t('common:QuestionMark')}
      >
      </SweatAlertCustomIcon>
      <div>{t('survey:RemoveQuestion')}</div>
    </div>
  ); 

  const valitationSchemeSave = yup.object().shape({ 
    EncuestaTopicoList: yup.array().of(
      yup.object().shape({
        Titulo: yup
          .string()
          .when('BajaLogica', {
            is: 0,
            then: yup.string().trim().required(t('common:RequiredField')).nullable(),
            otherwise: yup.string().nullable(),
          }) 
      })
    ),
  });

  const showActiveRows = (rows) => {
    const activeRows = [];
    if (rows !== undefined) {
      if (rows.length > 0) {
        rows.forEach((item, index) => {
          if (!item.BajaLogica || item.BajaLogica === 0 || item.BajaLogica === undefined) {
            item.BajaLogica = 0;
            item.RowIndex = index;
            activeRows.push({ ...item });
          }
        });
      } else {
        return [];
      }
    }

    return activeRows;
  };
 
  const EditarSurveyQuestion = ( 
  ) => {
    obtenerEncuestaTopico();
    setEsEditar(true);
  };

  const onTituloChange = (value, item) => {
    const copyEncuestaTopicoList = [...EncuestaTopicoList];
    copyEncuestaTopicoList[item.RowIndex].Titulo = value;
    setEncuestaTopicoList(copyEncuestaTopicoList);
  };

  const onRemoveEncuestaTopicoClick = (item) => {
    const copyEncuestaTopicoList = [...EncuestaTopicoList];
    copyEncuestaTopicoList[item.RowIndex].BajaLogica = 1;
    setEncuestaTopicoList(copyEncuestaTopicoList);
  };

  const onCancel = () => { 
    setErrors({}); 
    setEsEditar(false);
  };


  if (!EsEditar) {
    return (
      <>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
          </Col>
        </Row> 
        <div
          className="pregunta-container-no-edit"
          onClick={() =>
            EditarSurveyQuestion()}
        >
          <div className='text-right'>
            <div>
              <FieldButton
                label={t('survey:ViewEditTopics')}
                icon="pi pi-plus"
                className="no-border secondary p-mr-2 rounded-button"
                onClick={EditarSurveyQuestion}
              /> 
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="survey-edit-container">
        <div className="survey-edit-body">
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
            </Col>
          </Row> 
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
              <Row>
                <Col>
                  <span className="h3">{t('survey:ManageSurveyTopics')}</span>
                  &nbsp;
                  <FieldButton
                    icon="pi pi-plus"
                    className="light-green no-border p-mr-2 rounded-button"
                    onClick={AddNewItemArregloMultiSel}
                  />
                  &nbsp;
                  <i
                    className="fas fa-info-circle new-option-choice secondary-color"
                    tooltip={t('survey:ManageSurveyTopicsHelp')}
                  >
                  </i>
                  <Tooltip
                    target=".new-option-choice"
                    content={t('survey:ManageSurveyTopicsHelp')}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  &nbsp;
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ width: '70%' }}>{t('survey:Description')}</th> 
                        <th style={{ width: '10%' }}>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                      showActiveRows(EncuestaTopicoList).map((item, index) => {
                          return   (
                            <tr key={index}>
                              <td>
                                <div className="p-fluid">
                                  <FieldText
                                    name="Titulo"
                                    value={item.Titulo}
                                    onChange={(e) => onTituloChange(e.target.value, item)}
                                    maxLength={100}
                                    errors={errors[`EncuestaTopicoList[${item.RowIndex}]`]} 
                                  /> 
                                </div>
                              </td> 
                              <td>
                                <div className="p-fluid">
                                  <FieldButton
                                    hidden={showActiveRows(EncuestaTopicoList).length === 1}
                                    icon="pi pi-trash"
                                    className="p-button-rounded p-button-warning"
                                    onClick={() => onRemoveEncuestaTopicoClick(item)}
                                  />
                                </div> 
                              </td>
                            </tr>    
                                )
                          }
                      )  
                        }                  
                    </tbody>
                  </table>
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  &nbsp;
                </Col>
              </Row>
            &nbsp;
            </Col>
          </Row> 
        </div>
        <div className="survey-toolbar-options">
          <Row>
            <Col lg={12} md={12} sm={12} xs={12} className="text-left">
              <FieldButton
                icon="fas fa-times"
                label={t('common:Cancel')}
                className="warning no-border p-mr-2 rounded-button"
                onClick={onCancel}
              />
              &nbsp;
              <FieldButton
                label={t('common:Save')}
                icon="pi pi-save"
                className="p-button-success no-border p-mr-2 rounded-button"
                onClick={onSaveEncuestaTopicClick}
              /> 
            </Col>
          </Row>
        </div>
      </div>

      <br></br>
    </>
  );
};

export default SurveyManagementTopics;

import React, { useState, useRef, useEffect  } from 'react'; 
import { useTranslation } from 'react-i18next';   
import { config } from '../../../utils/config';
import { callApi, showSweetAlert} from '../../../utils/utils';   
import { Row,Col,Card, CardHeader, CardTitle, CardBody, CardFooter} from 'reactstrap';   
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable.jsx';
import './ProductionDailyFollowUPScreen.scss';  
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import moment from 'moment';
import FieldSelectButton from 'components/Controls/FieldSelectButton';

const ProductionWeeklyFollowUPComentarios = (props) => { 
  const { t } = useTranslation(['wtw', 'common']);
  const [errors, setErrors] = useState({});
  const [ComentariosList, setComentariosList] = useState([]);
  const [AreaList, setAreaList] = useState([]);
  const [ZoneList, setZoneList] = useState([]);
  const [DiasList, setDiasList] = useState([]);
  const [ClaAnioMes, setClaAnioMes] = useState(props.ClaAnioMes);
  const [ClaUbicacion, setClaUbicacion] = useState(props.ClaUbicacion);
  const [ClaSemana, setClaSemana] = useState(props.ClaSemana); 
  const [ClaDepartamento, setClaDepartamento] = useState(props.ClaDepartamento); 
  const [ClaArea, setClaArea] = useState(props.ClaArea);
  const [NomArea, setNomArea] = useState(props.NomArea);
  const [ClaTipo, setClaTipo] = useState(props.ClaTipo);  
  const [ClaZona, setClaZona] = useState(props.ClaZona);
  const [TurnoList, setTurnoList] = useState([]);
  const [ClaTurno, setClaTurno] = useState(1);
  const [ComentariosDepartamentoList, setComentariosDepartamentoList] = useState((props.ComentariosList!==undefined?props.ComentariosList:[]));
    
   const getUniqueAreaObjects = (array) => {
    const seen = new Map();
    return array.filter((item) => {
      const key = JSON.stringify({
        ClaDepartamento: item.ClaDepartamento,
        NomDepartamento: item.NomDepartamento,
        ClaArea: item.ClaArea,
        NomArea: item.NomArea 
      });
      return seen.has(key) ? false : seen.set(key, true);
    });
  };
 
  const getUniqueZoneObjects = (array) => {
    const seen = new Map();
    return array.filter((item) => {
      const key = JSON.stringify({
        ClaDepartamento: item.ClaDepartamento,
        NomDepartamento: item.NomDepartamento,
        ClaArea: item.ClaArea,
        NomArea: item.NomArea,
        ClaZona: item.ClaZona,
        NomZona: item.NomZona 
      });
      return seen.has(key) ? false : seen.set(key, true);
    });
  };

  const getUniqueDaysObjects = (array) => {
    const seen = new Map();
    return array.filter((item) => {
      const key = JSON.stringify({
        ClaDepartamento: item.ClaDepartamento,
        NomDepartamento: item.NomDepartamento,
        ClaArea: item.ClaArea,
        NomArea: item.NomArea,
        ClaZona: item.ClaZona,
        NomZona: item.NomZona,
        Fecha: item.Fecha ,
        ClaTurno: item.ClaTurno 
      });
      return seen.has(key) ? false : seen.set(key, true);
    });
  };

  const GetTurno = () => {
    const paramsService = {};
    const urlWebService = `${config.UrlApiProject}Operations/GetTurno`;
    callApi(urlWebService, 'GET', paramsService, (response) => {
      setTurnoList(response.data.Turnos);
    });
  };


  useEffect(() => {
    if(props.ComentariosList===undefined){
      getComentarios();
    }else{ 
      const comentariosFiltradosYOrdenados = props.ComentariosList
      .filter(element => element.IdProduccionSeguimientoSemanal > 0 && element.ClaArea > 0)
      .sort((a, b) => a.NomArea.localeCompare(b.NomArea));
      setComentariosDepartamentoList(comentariosFiltradosYOrdenados);
    }
    GetTurno();
  }, [])
 
  const getComentarios = () => {  
    const urlWebService = `${config.UrlApiProject}WTW/GetProduccionSeguimientoSemanalComentarios`; 
     const paramsService = {
        ClaTipo,
        ClaUbicacion,
        ClaAnioMes,
        ClaSemana,
        ClaDepartamento,
        ClaArea,
        ClaZona
      }; 
    callApi(urlWebService, 'POST', paramsService, (response) => {
      setComentariosList(response.data.ComentariosList);

      // generamos el listado unico de areas involucradas
      setAreaList(getUniqueAreaObjects(response.data.ComentariosList)); 
      setZoneList(getUniqueZoneObjects(response.data.ComentariosList)); 
      setDiasList(getUniqueDaysObjects(response.data.ComentariosList)); 
    }, null, false, true);
  }

const onHideDialog = () => { 
  if (props.onHide !== null && typeof props.onHide === 'function') {
    props.onHide();
  }
};

const templateHeaderDialog = (
  <> 
    <Row>
      <Col lg={12} md={12} sm={12} xs={12}>

        {
          props.ClaTipo===3 ? (
            <>
              <Row>
                <Col className='p-fluid'> 
                  {t('wtw:OperationDailyComments')}
                </Col>
              </Row>
            </>
          ):(
            <>
              <Row>
                <Col className='p-fluid'> 
                  {t('wtw:OperationWeeklyComments')} 
                </Col>
              </Row>
            </>
          )
        } 
      </Col>
    </Row>
    <Row>
      <Col lg={12} md={12} sm={12} xs={12}>
        <Row>
          <Col>  
            <div className='p-fluid'>
              <table>
                <tr style={{borderBottom: '1px dashed #cccccc'}}>
                  <td style={{width: '120px'}}>
                    {t('wtw:Plant')}   
                  </td>
                  <td style={{width: '200px'}} className=''>{props.NomUbicacion} </td>
                  <td style={{width: '120px'}}>
                    { 
                      props.ClaTipo===3 ?
                      (<>{t('wtw:Zone')}</>)
                      :
                      (<></>)
                    }
                      
                  </td>
                  <td style={{width: '300px'}} className=''>
                    {props.NomSemana}
                  </td>
                </tr>
              </table> 
            </div> 
          </Col>
        </Row>
      </Col>
    </Row>
  </>
  );

  const FechaColumna = (row) => {  
    return (
      <>
        <span> 
          { moment(row.Fecha).format('MMMM DD, YYYY')}
        </span>
      </>
);
}
  const ComentariosColumna = (row) => {  
    return (
      <>
        <span> 
          {row.ExplicacionProblema}
        </span>
      </>
);
} 

const onClaTurnoClick = (pClaTurno) => {   
  // no se puede desmarcar el ultimo...
  if (pClaTurno === null){
    return; 
  }

  setClaTurno(pClaTurno);   
};

const renderComments = () => { 
  if (ComentariosList.length === 0){
    return (
      <div className='ProductionDailyFollowUPScreen pt-1'>
        <br></br>
        <Card className='card-produccion'>
          <CardBody className="" style={{paddingTop: "5px"}}>
            <Row className='pt-2'>
              <Col lg={12} md={12} sm={12} xs={12}>  
                {t('wtw:NoCommentsAvailable')}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    )
  }
  
  return (
    <>
      {
          props.ClaTipo=== 1 
          &&
          (
            <Row className='align-items-start pt-1'> 
              {AreaList.sort((a, b) => a.NomArea !== b.NomArea ? a.NomArea < b.NomArea ? -1 : 1 : 0).map((element, index) => {
                return (
                  <Col 
                    key={index} 
                  >
                    <Card>
                      <CardHeader className="">
                        <CardTitle>  
                          <Row className="pt-3">
                            <Col>
                              {element.NomArea}
                            </Col>
                            <Col className="text-right"> 
                            </Col>
                          </Row>  
                        </CardTitle>
                      </CardHeader>    
                      <CardBody>
                        {
                           ComentariosList.filter((item) => {
                             return item.ClaArea === element.ClaArea && item.ClaDepartamento === element.ClaDepartamento
                           }).map((item, rowIndex) => {
                            return (
                              <>
                                <div className='info-color'><i className="pi pi-calendar"></i> &nbsp;Last Update: {moment(item.Fecha).format("MMMM, DD, YYYY HH:mm")}</div>
                                <div className='pt-2'>
                                  <span className='label'>
                                    {item.ExplicacionProblema}
                                  </span>
                                </div>
                                <div className='pt-2 border-bottom-dashed'></div>
                                <div>&nbsp;</div>
                              </>
                            )
                           })
                          }
                      </CardBody>
                    </Card>
                  </Col>
                )
              })}

            </Row>
          )   
      }
      
      {
          props.ClaTipo=== 2 
          &&
          (
            <Row className='align-items-start pt-1'>  
              <Col>
                <Card>
                  <CardHeader className="">
                    <CardTitle>  
                      <Row className="pt-3">
                        <Col>
                          {props.NomArea}
                        </Col>
                        <Col className="text-right"> 
                        </Col>
                      </Row>  
                    </CardTitle>
                  </CardHeader>    
                  <CardBody>
                    {ZoneList.sort((a, b) => a.NomZona !== b.NomZona ? a.NomZona < b.NomZona ? -1 : 1 : 0).map((element, index) => {
                      return (
                        <div key={index}> 
                          {
                                ComentariosList.filter((item) => {
                                  return item.ClaArea === element.ClaArea && item.ClaDepartamento === element.ClaDepartamento
                                         && item.ClaZona === element.ClaZona
                                }).map((item, rowIndex) => {
                                  return (
                                    <>
                                      <span className='h3'>{element.NomZona}</span>
                                      <div className='info-color'><i className="pi pi-calendar"></i> &nbsp;Last Update: {moment(item.Fecha).format("MMMM, DD, YYYY HH:mm")}</div>
                                      <div className='pt-2'>
                                        <span className='label'>
                                          {item.ExplicacionProblema}
                                        </span>
                                      </div>
                                      <div className='pt-2 border-bottom-dashed'></div>
                                      <div>&nbsp;</div>
                                    </>
                                  )
                                })
                                } 
                        </div>
                      )
                    })} 
                  </CardBody>
                </Card>
              </Col> 

            </Row>
          )   
      }


      {
          props.ClaTipo=== 3 
          &&
          (
            <> 
              <Row className='align-items-start pt-1'>  
                <Col>
                  <Card>
                    <CardHeader className="light-gray">
                      <CardTitle>  
                        <Row>
                          <Col>
                            {t('wtw:CommentsFromShift')}:
                          </Col>
                          <Col>
                            <FieldSelectButton 
                              name="ClaTurno"
                              value={ClaTurno}
                              options={TurnoList}
                              onChange={(e) => onClaTurnoClick(e.value)}
                              optionValue="ClaTurno"
                              optionLabel="NomTurno"
                              multiple={false}
                              errors={errors}
                            /> 
                          </Col>
                          <Col className="text-right"> 
                          </Col>
                        </Row>  
                      </CardTitle>
                    </CardHeader>    
                    <CardBody>
                      {
                        DiasList.sort((a, b) => a.Fecha !== b.Fecha ? a.Fecha < b.Fecha ? -1 : 1 : 0).filter((item) => {
                          return  item.ClaTurno === ClaTurno
                        }).length === 0
                        ?
                        (
                          <>
                            <div className='ProductionDailyFollowUPScreen'>
                              <br></br>
                              <Card className='card-produccion'>
                                <CardBody className="" style={{paddingTop: "5px"}}>
                                  <Row className='pt-2'>
                                    <Col lg={12} md={12} sm={12} xs={12}>  
                                      {t('wtw:NoCommentsAvailable')}
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </div>
                          </>
                          )
                        :
                        (
                          <>
                            {DiasList.sort((a, b) => a.Fecha !== b.Fecha ? a.Fecha < b.Fecha ? -1 : 1 : 0).filter((item) => {
                                  return  item.ClaTurno === ClaTurno
                                }).map((element, index) => {
                      return (
                        <div key={index}> 
                          <span className='h2'>{moment(element.Fecha).format("MMMM, DD, YYYY")}</span>
                          {
                                ComentariosList.filter((item) => {
                                  return item.ClaArea === element.ClaArea && item.ClaDepartamento === element.ClaDepartamento
                                         && item.ClaZona === element.ClaZona
                                         && item.Fecha === element.Fecha
                                         && item.ClaTurno === ClaTurno
                                }).map((item, rowIndex) => {
                                  return (
                                    <div key={rowIndex}>
                                      <div className='pt-2'>{t('wtw:Cell')}: &nbsp;<b>{item.NomMaquina}</b></div> 
                                      <div className='pt-2'>
                                        <span className='label'>
                                          {item.ExplicacionProblema}
                                        </span>
                                      </div>
                                      <div className='pt-2 border-bottom-dashed'></div>
                                      <div>&nbsp;</div>
                                    </div>
                                  )
                                })
                                } 
                        </div>
                      )
                    })} 
                          </>
)
                      }
                     
                    </CardBody>
                  </Card>
                </Col> 

              </Row>
            </>
          )   
      }
    </>
   )
}

const renderCommentsByList = () => { 
  if (ComentariosDepartamentoList.length === 0){
    return (
      <div className='ProductionDailyFollowUPScreen pt-1'>
        <br></br>
        <Card className='card-produccion'>
          <CardBody className="" style={{paddingTop: "5px"}}>
            <Row className='pt-2'>
              <Col lg={12} md={12} sm={12} xs={12}>  
                {t('wtw:NoCommentsAvailable')}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    )
  }
  
  return (
    <>
      <Row className='align-items-start pt-1'> 
        {ComentariosDepartamentoList.map((element, index) => {
                return (
                  <Col 
                    key={index} 
                  >
                    <Card>
                      <CardHeader className="">
                        <CardTitle>  
                          <Row className="pt-3">
                            <Col>
                              {element.NomArea}
                            </Col>
                            <Col className="text-right"> 
                            </Col>
                          </Row>  
                        </CardTitle>
                      </CardHeader>    
                      <CardBody>
                        <div className='info-color'>
                          <i className="pi pi-calendar"></i> &nbsp;{t('wtw:LastUpdate')}: 
                          {moment(element.Fecha).format("MMMM, DD, YYYY HH:mm")}
                        </div>
                        <div className='pt-2'>
                          <span className='label'>
                            {element.Comentarios}
                          </span>
                        </div>
                        <div className='pt-2 border-bottom-dashed'></div>
                        <div>&nbsp;</div>
                      </CardBody>
                    </Card>
                  </Col>
                )
              })}

      </Row>
    </>
   )
}

if (props.IsModal===false){
  return (
    <>
      {props.ComentariosList !== undefined ? (
        <>
          {renderCommentsByList()}
        </>
            ) : (
              <>
                {renderComments()}
              </>
            )} 
    </>
)
}

return (
  <> 
    <Dialog 
      className='PriceTrackerScreen dialog-header-background-blue'
      closable={true}
      draggable={false}
      visible={props.Visible}
      onHide={onHideDialog}  
      style={{ width: '90vw', height: '80vw' }}
      header={templateHeaderDialog}
      blockScroll={true}
    > 
      {props.ComentariosList !== undefined ? (
        <>
          {renderCommentsByList()}
        </>
            ) : (
              <>
                {renderComments()}
              </>
            )} 
    </Dialog>     
  </>
  );
};

export default ProductionWeeklyFollowUPComentarios;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next';   
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row,Col, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';   
import { config } from '../../../utils/config';
import { callApi} from '../../../utils/utils'; 
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon' 
import moment from "moment"; 
import Highcharts from 'highcharts'; 
import swal from '@sweetalert/with-react';
import PageContent from 'layouts/PageContent';
import { Toolbar } from 'primereact/toolbar';
import { ProgressBar } from 'primereact/progressbar';
import FieldDropdown from 'components/Controls/FieldDropdown';
import { Dialog } from 'primereact/dialog';
import ProyectoKPIManagementFullView from './ProyectoKPIManagementFullView';
import { Link } from "react-router-dom";
import ReporteAvanceMensualProyectoAttachment from './ReporteAvanceMensualProyectoAttachment.jsx';
import KPIFormulaResultadoReview from '../Scorecard/KPIsFormulaSetUp/KPIFormulaResultadoReview.jsx';
import { ScrollPanel } from 'primereact/scrollpanel';
import FieldTextArea from 'components/Controls/FieldTextArea.jsx';
import ProjectPerformanceDialog from './ProjectPerformanceDialog.jsx';

const OrangePage = (props) => {
   /* ################## useState area start ################## */
   const { t } = useTranslation(['orangePage', 'common']);    
   const [ClaProyecto, setClaProyecto] = useState(0);  
   const [IdReporteMensual, setIdReporteMensual] = useState(0);  
   const [ReporteAvanceProyectoKPIList, setReporteAvanceProyectoKPIList]= useState({});   
   const [TeamMembersList, setTeamMembersList]= useState({});   
   const [SeguimientoFasesList, setSeguimientoFasesList]= useState({});   
   const [AccionesList, setAccionesList]= useState({});  
   const [SiguienesPasosList, setSiguienesPasosList]= useState({});  
   const [KPisManagementList, setKPisManagementList]= useState({});  
   const [SoporteList, setSoporteList]= useState({}); 
   const [EncabezadoList, setEncabezadoList]= useState({}); 
   const [EsProyectoMaestro, setEsProyectoMaestro]= useState(null);  
   const [SeguimientoFases2List, setSeguimientoFases2List]= useState({});   
   const [AnioMes, setAnioMes] = useState(null);
   const [openViewFull, setopenViewFull] = useState(false);
   const [ClaTipoProyecto, setClaTipoProyecto] = useState(null); 
   const [EsMostrarProgressReportParent, setEsMostrarProgressReportParent] = useState(false);
   const [claSelectedProject, setclaSelectedProject] = useState(null);
   const [idSelectedReporteMensual, setidSelectedReporteMensual] = useState(null);
   const [EsAbrirDocumentosSoporte, setEsAbrirDocumentosSoporte] = useState(false)
   const [TieneAnexos, setTieneAnexos] = useState(null);
   const [esMostrarFormulaReview, setEsMostrarFormulaReview] = useState(false);
   const [IdKPIFormulaSelected, setIdKPIFormulaSelected] =  useState(false);
   const [Logros, setLogros] = useState(''); 
   const [ProblemasAResolver, setProblemasAResolver] = useState(''); 
   const [SiguientesPasos, setSiguientesPasos] = useState(''); 
   const [IsProjectPerformanceDialogVisible, setIsProjectPerformanceDialogVisible] =  useState(false);

   useEffect(() => {   
    setClaProyecto(props.ClaProyecto === undefined ? 0 : props.ClaProyecto);  
    setIdReporteMensual(props.IdReporteMensual === undefined ? 0 : props.IdReporteMensual); 
    obtenerEncabezadoOrangePage(props.ClaProyecto === undefined ? 0 : props.ClaProyecto, props.IdReporteMensual === undefined ? 0 : props.IdReporteMensual);  
  }, [props.ClaProyecto, props.IdReporteMensual]); 
 

  const obtenerEncabezadoOrangePage = (ClaProyecto, IdReporteMensual) => { 
    const GetEncabezado = `${config.UrlApiProject}ScoreCard/GetEncabezadoOrangePage`;      
    const paramsToService = {
      IdReporteAvanceMensualProyecto: IdReporteMensual,
      ClaProyecto
     };   
    callApi(GetEncabezado, 'POST', paramsToService, (response) => {    
      if (response.data.Encabezado.length > 0) {
        setEncabezadoList(response.data.Encabezado[0]);
        setAnioMes(response.data.Encabezado[0].AnioMes);
        setTieneAnexos(response.data.Encabezado[0].TieneAnexos);
        setEsProyectoMaestro(response.data.Encabezado[0].EsProyectoMaestro);
        setClaTipoProyecto(response.data.Encabezado[0].ClaTipoProyecto);
        obtenerReporteAvanceProyectoKPIManagement(ClaProyecto, IdReporteMensual, response.data.Encabezado[0].AnioMes); 
        obtenerGetTeamMembers(ClaProyecto, IdReporteMensual, response.data.Encabezado[0].AnioMes);
        obtenerGetSeguimientoFases(ClaProyecto, IdReporteMensual,);
        obtenerGetSeguimientoFases2(ClaProyecto, IdReporteMensual,);
        obtenerGetAcciones(ClaProyecto, IdReporteMensual,);
        obtenerGetSiguienesPasos(ClaProyecto, IdReporteMensual,);
        obtenerKPisManagement(ClaProyecto, IdReporteMensual,);
        obtenerGetSoporte(ClaProyecto, IdReporteMensual,);
        setLogros(response.data.Encabezado[0].Logros);
        setProblemasAResolver(response.data.Encabezado[0].ProblemasAResolver);
        setSiguientesPasos(response.data.Encabezado[0].SiguientesPasos);
      } 
      else {
        setEncabezadoList(
          {
            IdReporteMensual: null,
            ObjetivoProyecto: null,	
            LineaEstrategica: null,
            Iniciativa: null,
            LabelEstProgress: null,	
            LabelRealProgress: null,	
            EstatusProyecto: null,
            EsProyectoMaestro: null
          }
        );
        setLogros('');
        setProblemasAResolver('');
        setSiguientesPasos('');  
      }
    });
  }

 const onPrintReport = () => { 
    const ClaProyecto =  props.ClaProyecto === undefined ? 0 : props.ClaProyecto;
    const  IdReporteMensual =   props.IdReporteMensual === undefined ? 0 : props.IdReporteMensual;
    const GetEncabezado = `${config.UrlApiProject}ScoreCard/GetProgressReportPrint`;      
    const paramsToService = {
      IdReporteAvanceMensualProyecto: IdReporteMensual,
      ClaProyecto
     };   
    callApi(GetEncabezado, 'POST', paramsToService, (response) => {    
       console.log('onPrintReport response',response.data);
       const linkSource = `data:application/${response.data.FileExtension};base64,${response.data.FileData}`;
       const downloadLink = document.createElement("a");
       const fileName = `progress_report_${  IdReporteMensual}`;
       downloadLink.href = linkSource;
       downloadLink.download = `${fileName  }.${  response.data.FileExtension}`;
       downloadLink.click();
    });
  }

  const obtenerReporteAvanceProyectoKPIManagement = (ClaProyecto, IdReporteMensual, AnioMesParam) => { 
    const GetReporteAvanceProyectoKPIManagement = `${config.UrlApiProject}ScoreCard/GetReporteAvanceProyectoKPIManagement`;      
    const paramsToService = {
      ClaProyecto,
      AnioMes: AnioMesParam,
      IdReporteAvanceMensualProyecto: IdReporteMensual
     };   
    callApi(GetReporteAvanceProyectoKPIManagement, 'POST', paramsToService, (response) => {
      setReporteAvanceProyectoKPIList(response.data.ReporteAvanceProyectoKPI);
    });
  }
  const obtenerGetTeamMembers = (ClaProyecto, IdReporteMensual, AnioMesParam) => { 
    const GetTeamMembers = `${config.UrlApiProject}ScoreCard/GetTeamMembers`;      
    const paramsToService = {
      ClaProyecto,
      AnioMes: AnioMesParam,
      IdReporteAvanceMensualProyecto: IdReporteMensual
     };   
    callApi(GetTeamMembers, 'POST', paramsToService, (response) => {
      setTeamMembersList(response.data.TeamMembers);
    });
  }
  const obtenerGetSeguimientoFases = (ClaProyecto, IdReporteMensual) => { 
    const GetSeguimientoFases = `${config.UrlApiProject}ScoreCard/GetSeguimientoFases`;      
    const paramsToService = {
      ClaProyecto,
      IdReporteAvanceMensualProyecto: IdReporteMensual
     };   
    callApi(GetSeguimientoFases, 'POST', paramsToService, (response) => {
      setSeguimientoFasesList(response.data.SeguimientoFases);
    });
  }
  const obtenerGetSeguimientoFases2 = (ClaProyecto, IdReporteMensual) => { 
    const GetSeguimientoFases2 = `${config.UrlApiProject}ScoreCard/GetSeguimientoFases2`;      
    const paramsToService = {      
      ClaProyecto,
      IdReporteAvanceMensualProyecto: IdReporteMensual
     };   
    callApi(GetSeguimientoFases2, 'POST', paramsToService, (response) => {
      setSeguimientoFases2List(response.data.SeguimientoFases2);
    });
  }
  const obtenerGetAcciones = (ClaProyecto, IdReporteMensual) => { 
    const GetAcciones = `${config.UrlApiProject}ScoreCard/GetAcciones?IdReporteMensual=${IdReporteMensual}`;        
    const paramsToService = {};   
    callApi(GetAcciones, 'GET', paramsToService, (response) => {
      setAccionesList(response.data.Acciones);
    });
  }
  const obtenerGetSiguienesPasos = (ClaProyecto, IdReporteMensual) => { 
    const GetSiguienesPasos = `${config.UrlApiProject}ScoreCard/GetSiguienesPasos?IdReporteMensual=${IdReporteMensual}`;   
    const paramsToService = {};   
    callApi(GetSiguienesPasos, 'GET', paramsToService, (response) => {
      setSiguienesPasosList(response.data.SiguienesPasos);
    });
  }
  const obtenerKPisManagement = (ClaProyecto, IdReporteMensual) => { 
    const GetKPisManagement = `${config.UrlApiProject}ScoreCard/GetKPisManagement`;      
    const paramsToService = {
      ClaProyecto,
      IdReporteAvanceMensualProyecto: IdReporteMensual
     };   
    callApi(GetKPisManagement, 'POST', paramsToService, (response) => {
      setKPisManagementList(response.data.KPisManagement);
    });
  }
  const obtenerGetSoporte = (ClaProyecto, IdReporteMensual) => { 
    const GetSoporte = `${config.UrlApiProject}ScoreCard/GetSoporte?IdReporteMensual=${IdReporteMensual}`;   
    const paramsToService = {};   
    callApi(GetSoporte, 'GET', paramsToService, (response) => {
      setSoporteList(response.data.Soporte);
    });
  }
  const onViewFull = () => { 
    setopenViewFull(true);
  }
 
  

  const closeModalViewFull = () => {
    setopenViewFull(false);
  }
  const headerKPIProgress =(
    <Row>
      <Col lg={9} md={9} sm={12} xs={12}>
        {t('orangePage:KPIProgress')}
      </Col>
      <Col lg={3} md={3} sm={12} xs={12} className="text-right">
        <div className="p-fluid">          
          <FieldButton label={t('orangePage:ViewFull')} className="primary no-border rounded-button" onClick={onViewFull} /> 
        </div>        
      </Col>
    </Row>
      );
  const headerTeamMembers =(
    <> 
      {t('orangePage:TeamMembers')}   
    </>
          );
    const headerSeguimientoFases =(
      <> 
        {t('orangePage:ProjectRoadmap')} 
      </>
            );
      const headerSeguimientoFases2 =(
        <> 
          {t('orangePage:ProjectListing')} 
        </>
              );  
    const headerAcciones =(
      <> 
        {t('orangePage:Acciones')}    
      </>
            ); 
    const headerSiguienesPasos =(
      <> 
        {t('orangePage:NextSteps')}   
      </>
            );  
    const headerKPisManagement =(
      <> 
        {t('orangePage:Management')} 
      </>
            );           
    const headerSoporte =(
      <> 
        {t('orangePage:SupportRequest')} 
      </>
            );
    const FechaEstimadaFinTemplate = (rowData) => {
      return (
        <>
          { rowData.FechaEstimadaFin !== null ? moment(rowData.FechaEstimadaFin).format("MM/DD/YYYY") : rowData.FechaEstimadaFin }           
        </>
      );
    }
    const FechaEstimadaInicioTemplate = (rowData) => {
      return (
        <>
          { rowData.FechaEstimadaInicio !== null ? moment(rowData.FechaEstimadaInicio).format("MM/DD/YYYY") : rowData.FechaEstimadaInicio }           
        </>
      );
    }
    
    const FechaCompromisoTemplate = (rowData) => {
      return (
        <>
          { rowData.FechaCompromiso !== null ? moment(rowData.FechaCompromiso).format("MM/DD/YYYY") : rowData.FechaCompromiso }           
        </>
      );
    }
    const FechaRealTerminoTemplate = (rowData) => {
      return (
        <>
          { rowData.FechaRealTermino !== null ? moment(rowData.FechaRealTermino).format("MM/DD/YYYY") : rowData.FechaRealTermino }           
        </>
      );
    }
    
    const PorcPesoTemplate= (rowData) => {
      return (
        <>
          <label><b>{rowData.PorcPeso}</b> %</label>  
        </>
      );
    }
    const AvanceEstimadoTemplate= (rowData) => {
      return (
        <>
          <label><b>{Highcharts.numberFormat(rowData.AvanceEstimado,0)}</b> %</label>  
        </>
      );
    }

    const AvanceRealTemplate= (rowData) => {
      return (
        <>
          <label><b>{Highcharts.numberFormat(rowData.AvanceReal,0)}</b> %</label>  
        </>
      );
    }
    const ValorActualTemplate= (rowData) => {
      return (
        <>
          <label><b>{rowData.ValorActual !== null?  Highcharts.numberFormat(rowData.ValorActual, 2, '.', ',') : null}</b></label>  
        </>
      );
    }
    const EstatusObjetivoTemplate= (rowData) => {
      return (
        <>
          <label><b>{rowData.EstatusObjetivo !== null?  Highcharts.numberFormat(rowData.EstatusObjetivo, 2, '.', ',') : null}</b></label>  
        </>
      );
    }

    const NomProyectoTemplate = (rowData) => {

      if (rowData.IdReporteMensual !== null){
        return (<><div className='Cursor text-underline' onClick={() => onClickViewProgressReport(rowData.ClaProyecto, rowData.IdReporteMensual)}><a href='#'><span title={`View Progress Report - ${rowData.NomProyecto}`}>{rowData.NomProyecto}</span></a></div></>);
      }
  
      return (<>{rowData.NomProyecto}</>)
      /*
      return ( 
        <>    
          <Link 
            title={rowData.NomProyecto}  
            to={{ pathname: "EditProject"
              , search: `?ClaProyecto=${rowData.ClaProyecto}` 
                                        }}
            target="_blank"
          >          
            {rowData.NomProyecto} 
          </Link> 
        </>
) */
    }

    const onClickViewProgressReport = (ClaProyecto, IdReporteMensual) => {
      setEsMostrarProgressReportParent(true);
      setclaSelectedProject(ClaProyecto);
      setidSelectedReporteMensual(IdReporteMensual);
    }

    const keyTemplate = (rowData) => {
      return ( 
        <>    
          <Link 
            title={rowData.NomProyecto}  
            to={{ pathname: "EditProject"
              , search: `?ClaProyecto=${rowData.ClaProyecto}` 
                                        }}
            target="_blank"
          >          
            {rowData.ClaProyecto} 
          </Link> 
        </>
)
    }

    const SemaforoTemplate = (rowData) => {
      let className = '';
    const semaforo = rowData.Semaforo === null ? '' : rowData.Semaforo ;
    switch(semaforo.toString()) {
      case "1":
        className = "success-color";
        break;
      case "2":
        className = "warning-color";
        break;
      case "3":
        className = " danger-color";
        break;
        default:
          className = "default-color";
    }
      return (
        <>
          <i className={`fas fa-circle ${className}`}></i>
        </>
      );
    }     

    const DifActualVsObjetivoTemplate = (rowData) => {
      return (
        <>
          <label>
            <b>{Highcharts.numberFormat(rowData.DifActualVsObjetivo, 1)} %</b>
          </label>
        </>
      );
    };
     

   const headerSeguimientoFasesGroup  = ( 
     <ColumnGroup>
       <Row>
         <Column header={t('orangePage:Phase')} rowSpan={2}></Column>  
         <Column header={t('orangePage:Status')} rowSpan={2}></Column>  
         <Column header="" rowSpan={2}></Column>  
         <Column header={t('orangePage:EstimatedEndDate')} className="text-center" rowSpan={2}></Column>              
         <Column header={t('orangePage:Weight')} className="text-center" rowSpan={2}></Column>  
         <Column header={t('orangePage:Progress')} colSpan={2} className="text-center"></Column>   
         <Column header={t('orangePage:ConsiderableUpd')} rowSpan={2}></Column>  
    
       </Row> 
       <Row>
         <Column className="text-center" header={t('orangePage:ProgressEst')}></Column>  
         <Column className="text-center" header={t('orangePage:ProgressReal')}></Column>  
       </Row> 
     </ColumnGroup>
    )

    const headerSeguimientoFases2Group  = ( 
      <ColumnGroup>
        <Row>
          <Column header={t('orangePage:Key')} rowSpan={2}></Column>  
          <Column header={t('orangePage:ScorecardInitiative')} rowSpan={2}></Column>  
          <Column header={t('orangePage:Project')} rowSpan={2}></Column>  
          <Column header="" className="text-center" rowSpan={2}></Column>    
          <Column
            header={t('reportevanceMensualProyecto:Status')}
            className="text-center"
            rowSpan={2}
          >
          </Column>                                  
          <Column header={t('orangePage:Progress')} className="text-center" colSpan={2}></Column>   
          <Column header={t('orangePage:Responsible')} rowSpan={2}></Column>  
          <Column header={t('orangePage:Weight')} className="text-center" rowSpan={2}></Column>  
          <Column header={t('orangePage:StartDate')} className="text-center" rowSpan={2}></Column>              
          <Column header={t('orangePage:EndDate')} className="text-center" rowSpan={2}></Column>                                
          <Column header={t('orangePage:Considerable')} rowSpan={2}></Column>  
       
        </Row> 
        <Row>
          <Column className="text-center" header={t('orangePage:ProgressEst')}></Column>  
          <Column className="text-center" header={t('orangePage:ProgressReal')}></Column>  
        </Row> 
      </ColumnGroup>
     )

                 
  
 
const onHideOrangeReport = () => { 

  if (props.onHide !== null && typeof props.onHide === 'function') {
    props.onHide();
  }
};

const renderHeader = () => {
  return (
    <div>
      <Row>
        <Col lg={4} md={4} sm={12} xs={12}>
          Orange Report 
        </Col>
        <Col lg={4} md={4} sm={12} xs={12} className="text-center">
          <span>{EncabezadoList.NomProyecto}</span>
        </Col>
        <Col lg={4} md={4} sm={12} xs={12} className="text-right">
          <div className="w-100 relative pr-3">
            <span> {EncabezadoList.NomPeriodo} </span>
          </div>
        </Col>
      </Row>
    </div>
  );
};  
 
const renderFooter = () => {
  return (
    <>
      <div className='pt-2'>
        <Toolbar
          className='pl-5'
          left={leftToolbarFooterNewAPTemplate}
          right={rightToolbarFooterNewAPTemplate}
        >
        </Toolbar>
      </div>
      
    </>
  );
};

const leftToolbarFooterNewAPTemplate = () => {  
  return (
    <> 
       
    </>
  );
};
const rightToolbarFooterNewAPTemplate = () => {
   
  return (
    <>
      <Row>
        <Col>
          <FieldButton
            label={t('orangePage:Print')} 
            icon='fa fa-print'
            className="warning no-border rounded-button"
            onClick={onPrintReport}
          /> 
        </Col>
      </Row>
    </>
  );
};

const ComentariosActualizacionFaseTemplate = (rowData) => {
  return (
    <>
      <label>
        {rowData.ComentariosActualizacionFase} 
      </label>
    </>
  );
};

const AbrirAnexo = () => {
  setEsAbrirDocumentosSoporte(!EsAbrirDocumentosSoporte);
};

const SentidoMejoraTemplate = (props) => {
  return <>{props.ClaSentidoMejora === 1 ?  (<i className="fas fa-sort-amount-up"></i>): (<i className="fas fa-sort-amount-down"></i>)}</>;
}; 

const onShowCalculationFormulaRevi = (IdKPIFormula) => {
  setEsMostrarFormulaReview(true);
  setIdKPIFormulaSelected(IdKPIFormula); 
}

const ValorActualKPITemplate = (rowData, props) => {
    
  if (rowData.IdKPIFormula !== null){
    return (
      <>
        <span className='link-text' onClick={()=> onShowCalculationFormulaRevi(rowData.IdKPIFormula)}><b>{rowData.ValorActual !== null?  Highcharts.numberFormat(rowData.ValorActual, 2, '.', ',') : null}</b></span>
        <>&nbsp;&nbsp;&nbsp;<span title="Click here to view how we calculate " className='Cursor' onClick={()=> onShowCalculationFormulaRevi(rowData.IdKPIFormula)}><i className="fas fa-question-circle info-color"></i></span></>
        
      </>
    )
  }
  return (<span>{rowData.ValorActual !== null?  Highcharts.numberFormat(rowData.ValorActual, 2, '.', ',') : null} </span>)
}

const onProjectPerformanceClick = () => {
  setIsProjectPerformanceDialogVisible(true);
};

const onProjectPerformanceHide = () => {
  setIsProjectPerformanceDialogVisible(false);
};
                                              
  return (
    <>   
      <Dialog
        visible={props.Visible}
        modal
        style={{ width: '100vw' }} 
        onHide={onHideOrangeReport}
        header={renderHeader}
        footer={renderFooter}
        closable={true}
        className="dialog-header-background-blue"
        maximized={true}
        draggable={false}
        blockScroll={true}
      > 
        <> 
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
          &nbsp;
            </Col>
          </Row> 
          <Row className="align-items-start">
            <Col lg={8} md={8} sm={12} xs={12}>  
              <Row>
                <Col>
                  <Card>
                    <CardHeader className="hard-blue">
                      <CardTitle>
                        {t('orangePage:ProjectGoal')}
                      </CardTitle>
                    </CardHeader>    
                    <CardBody>  
                      <Row>
                        <Col>
                          <label>{EncabezadoList.ObjetivoProyecto}</label>
                        </Col>
                      </Row>
                    </CardBody> 
                  </Card>                 
                </Col>                
              </Row>
              {
                ClaTipoProyecto === 5 && 
                (
                  <>
                    <Row>
                      <Col>
                        <Card>
                          <CardHeader className="hard-blue">
                            <CardTitle>
                              {t('orangePage:ProgressSummary')}
                            </CardTitle>
                          </CardHeader>    
                          <CardBody>                        
                            <Row>
                              <Col>
                                <Row>
                                  <Col lg={12} md={12} sm={12} xs={12}>
                                    <label className='bold'>
                                      {t('reportevanceMensualProyecto:WhatDidYouAchieve')}  
                                    </label>
                                    <ScrollPanel style={{ width: '100%', height: '90px' }}>
                                      <p className='text-paragraph'>
                                        {Logros}
                                      </p>
                                    </ScrollPanel>                                                                        
                                  </Col>
                                </Row>
                                <Row className='pt-3'>
                                  <Col lg={12} md={12} sm={12} xs={12}>
                                    <label className='bold'>
                                      {t('reportevanceMensualProyecto:ChallengesToSolve')}  
                                    </label>
                                    <ScrollPanel style={{ width: '100%', height: '90px' }}>
                                      <p className='text-paragraph'>
                                        {ProblemasAResolver}
                                      </p>
                                    </ScrollPanel>                                                                           
                                  </Col>
                                </Row>
                                <Row className='pt-3'>
                                  <Col lg={12} md={12} sm={12} xs={12}>
                                    <label className='bold'>
                                      {t('reportevanceMensualProyecto:NextMilestones')}  
                                    </label>
                                    <ScrollPanel style={{ width: '100%', height: '90px' }}>
                                      <p className='text-paragraph'>
                                        {SiguientesPasos}
                                      </p>
                                    </ScrollPanel> 
                                  </Col>
                                </Row>
                              </Col>                        
                            </Row>                            
                          </CardBody>
                        </Card>                      
                      </Col>
                    </Row>                  
                  </>
                )
              }
            </Col> 
            <Col lg={4} md={4} sm={12} xs={12}>    
              <Row className="align-items-start">
                <Col lg={6} md={6} sm={12} xs={12}>  
                  <Card>
                    <CardHeader className="hard-blue text-center">
                      <CardTitle>
                        {t('orangePage:StrategicLine')}
                      </CardTitle>
                    </CardHeader>    
                    <CardBody>  
                      <Row>
                        <Col className="bold text-center">
                          <label>{EncabezadoList.LineaEstrategica}</label>
                        </Col>
                      </Row>
                    </CardBody> 
                  </Card> 
                </Col> 
                <Col lg={6} md={6} sm={12} xs={12}>   
                  <Card>
                    <CardHeader className="hard-blue text-center">
                      <CardTitle>
                        {t('orangePage:StrategicInitiative')}
                      </CardTitle>
                    </CardHeader>    
                    <CardBody>  
                      <Row>
                        <Col className="bold text-center">
                          <label>{EncabezadoList.Iniciativa}</label>
                        </Col>
                      </Row>
                    </CardBody> 
                  </Card>   
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6} sm={12} xs={12}>   
                  <Card>
                    <CardHeader className="hard-blue text-center">
                      <CardTitle>
                        {t('orangePage:EstVSRealProgress')}
                      </CardTitle>
                    </CardHeader>    
                    <CardBody>  
                      <Row>
                        <Col className="bold text-center">                          
                          <label style={{ fontSize: '2.3rem' }}>
                            {`${EncabezadoList.LabelEstProgress? EncabezadoList.LabelEstProgress: ''} / ${EncabezadoList.LabelRealProgress? EncabezadoList.LabelRealProgress: ''}`}
                          </label>&nbsp;
                          <span style={{ fontSize: '1.3rem' }}>
                            {SemaforoTemplate({Semaforo: EncabezadoList.Semaforo === null || typeof(EncabezadoList.Semaforo) === 'undefined'  ?  '' : EncabezadoList.Semaforo})}
                          </span>                         
                        </Col>
                      </Row>
                    </CardBody> 
                  </Card> 
                </Col> 
                <Col lg={6} md={6} sm={12} xs={12}>   
                  <Card>
                    <CardHeader className="hard-blue text-center">
                      <CardTitle>       
                        {t('orangePage:PercentKPIResults')}
                      </CardTitle>
                    </CardHeader>    
                    <CardBody>  
                      <Row>
                        <Col className="bold text-center">   
                          <label style={{ fontSize: '2.3rem' }}>
                            {EncabezadoList.KPIResultados? EncabezadoList.KPIResultados: ''}
                          </label>&nbsp;
                          <span style={{ fontSize: '1.3rem' }}>
                            {SemaforoTemplate({Semaforo: EncabezadoList.KPIResultadosSemaforo === null || typeof(EncabezadoList.KPIResultadosSemaforo) === 'undefined'  ?  '' : EncabezadoList.KPIResultadosSemaforo})}
                          </span>  
                        </Col>
                      </Row>
                    </CardBody> 
                  </Card>  
                </Col> 
              </Row> 
              <Row>
                <Col lg={6} md={6} sm={12} xs={12}>   
                  <FieldButton
                    label={t('reportevanceMensualProyecto:SupportFiles')}
                    icon="pi pi-paperclip"
                    className={`no-border rounded-button font-weight-bold ${ TieneAnexos === 1 ? 'light-yellow' : 'light-gray'} `}
                    onClick={() => AbrirAnexo()}  
                  >
                  </FieldButton>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>   
                  <Card>
                    <CardHeader className="hard-blue text-center">
                      <CardTitle>       
                        {t('orangePage:ProjectPerformance')}               
                      </CardTitle>
                    </CardHeader>    
                    <CardBody>  
                      <Row>
                        <Col className="bold text-center">                             
                          <label 
                            style={{ 
                              fontSize: '2.3rem',
                              cursor: 'pointer',
                              textDecoration: 'underline'
                            }}
                            onClick={onProjectPerformanceClick}
                            // className='info-color'
                          >
                            {EncabezadoList.DesempenioProyecto? EncabezadoList.DesempenioProyecto: ''}
                          </label>&nbsp;&nbsp;
                          <span style={{ fontSize: '1.3rem' }}>
                            {SemaforoTemplate({Semaforo: EncabezadoList.DesempenioProyectoSemaforo === null || typeof(EncabezadoList.DesempenioProyectoSemaforo) === 'undefined'  ?  '' : EncabezadoList.DesempenioProyectoSemaforo})}
                          </span>  
                        </Col>
                      </Row>
                    </CardBody> 
                  </Card>  
                </Col> 
              </Row> 
            </Col> 
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
          &nbsp;
            </Col> 
          </Row>  
          <Row className="align-items-start">
            <Col lg={6} md={6} sm={12} xs={12}> 
              <FieldDataTable 
                value={ReporteAvanceProyectoKPIList}
                scrollable 
                scrollHeight="330px" 
                className="editable-cells-table"  
                editMode="cell"
                selectionMode="single"
                header={headerKPIProgress}
              > 
                <Column field="NomProyectoKPIManagement" header={t('orangePage:KPIstoImpact')} columnKey="NomProyectoKPIManagement" headerStyle={{ width: '195px' }}></Column>  
                <Column field="DescUnidadMedidad" header={t('orangePage:UoM')} columnKey="DescUnidadMedidad" headerStyle={{ width: '65px'}}></Column>  
                <Column
                  field="ClaSentidoMejora"
                  className="text-center"
                  header=''
                  columnKey="ClaSentidoMejora"
                  headerStyle={{ width: '20px' }}
                  body={SentidoMejoraTemplate}
                >
                </Column> 
                <Column
                  field="ValorActual"  
                  body={(rowData, props) => ValorActualKPITemplate(rowData, props)} // body={ValorActualTemplate} 
                  header={t('orangePage:Actual')}
                  columnKey="ValorActual"
                  headerStyle={{ width: '65px' }}
                >
                </Column>  
                <Column field="EstatusObjetivo" body={EstatusObjetivoTemplate} header={t('orangePage:Target')} columnKey="EstatusObjetivo" headerStyle={{ width: '65px'}}></Column>  
                <Column
                  field="DifActualVsObjetivo"
                  body={DifActualVsObjetivoTemplate}
                  header={t('reportevanceMensualProyecto:DiffvsTarget')}
                  columnKey="DifActualVsObjetivo"
                  headerStyle={{ width: '65px' }}
                >
                </Column>
              </FieldDataTable>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}> 
              <FieldDataTable 
                value={TeamMembersList}
                scrollable 
                scrollHeight="330px" 
                className="editable-cells-table"  
                editMode="cell"
                selectionMode="single"
                header={headerTeamMembers}
              > 
                <Column field="NomUsuario" header={t('orangePage:Name')} columnKey="NomUsuario" headerStyle={{ width: '270px' }}></Column>  
                <Column field="NomRolProyecto" header={t('orangePage:Role')} columnKey="NomRolProyecto" headerStyle={{ width: '125px'  }}></Column>  
                <Column field="NomArea" header={t('orangePage:Area')} columnKey="NomArea" headerStyle={{ width: '180px'  }}></Column>  
            
              </FieldDataTable>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
          &nbsp;
            </Col> 
          </Row>
          {
        ClaTipoProyecto === 4 || ClaTipoProyecto === 5 || ClaTipoProyecto === 6 ? 
        (
          <>          
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}> 
                <FieldDataTable 
                  value={SeguimientoFases2List}
                  scrollable 
                  scrollHeight="330px" 
                  className="editable-cells-table"  
                  editMode="cell"
                  selectionMode="single"
                  header={headerSeguimientoFases2}
                  headerColumnGroup={headerSeguimientoFases2Group} 
                > 
                  <Column field="ClaProyecto" header={t('orangePage:Key')} body={keyTemplate} columnKey="ClaProyecto" headerStyle={{ width: '40px' }}></Column>  
                  <Column field="NomIniciativa" header={t('orangePage:Initiative')} columnKey="NomIniciativa" headerStyle={{ width: '180px'}}></Column>  
                  <Column field="NomProyecto" body={NomProyectoTemplate} header={t('orangePage:Project')} columnKey="NomProyecto" headerStyle={{ width: '180px'}}></Column>  
                  <Column field="Semaforo" body={SemaforoTemplate} className="text-center" header={t('orangePage:-')} columnKey="Semaforo" headerStyle={{ width: '25px'}}></Column> 
                  <Column
                    field="NomEstatusProyecto"
                    header={t('reportevanceMensualProyecto:Status')}
                    columnKey="NomEstatusProyecto"
                    headerStyle={{ width: '90px' }}
                    className="text-center"
                  >
                  </Column>                                     
                  <Column field="AvanceEstimado" body={AvanceEstimadoTemplate} className="text-center" header={t('orangePage:ProgressEst')} columnKey="AvanceEstimado" headerStyle={{ width: '50px' }}></Column>  
                  <Column field="AvanceReal" body={AvanceRealTemplate} className="text-center" header={t('orangePage:ProgressReal')} columnKey="AvanceReal" headerStyle={{ width: '50px',}}></Column>  
                  <Column field="NomResponsable" header={t('orangePage:Responsible')} columnKey="NomResponsable" headerStyle={{ width: '180px'}}></Column>  
                  <Column field="PorcPeso" body={PorcPesoTemplate} className="text-center" header={t('orangePage:Weight')} columnKey="PorcPeso" headerStyle={{ width: '60px'}}></Column>  
                  <Column field="FechaEstimadaInicio" body={FechaEstimadaInicioTemplate} className="text-center" header={t('orangePage:StartDate')} columnKey="FechaEstimadaInicio" headerStyle={{ width: '80px'}}></Column>              
                  <Column field="FechaEstimadaFin" body={FechaEstimadaFinTemplate} className="text-center" header={t('orangePage:StartDate')} columnKey="FechaEstimadaFin" headerStyle={{ width: '80px' }}></Column>                                
                  <Column field="ComentariosActualizacionFase" body={ComentariosActualizacionFaseTemplate} header={t('orangePage:Considerable')} columnKey="ComentariosActualizacionFase" headerStyle={{ width: '300px' }}></Column>  
                  
                  
                </FieldDataTable>
              </Col> 
            </Row> 
          </>
        ): (
          <>
        
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}> 
                <FieldDataTable 
                  value={SeguimientoFasesList}
                  scrollable 
                  scrollHeight="330px" 
                  className="editable-cells-table"  
                  editMode="cell"
                  selectionMode="single"
                  header={headerSeguimientoFases}
                  headerColumnGroup={headerSeguimientoFasesGroup} 
                > 
                  <Column field="NomFase" header={t('orangePage:Phase')} columnKey="NomFase" headerStyle={{ width: '280px'  }}></Column>  
                  <Column field="NomEstatusFase" header={t('orangePage:Status')} columnKey="NomEstatusFase" headerStyle={{ width: '100px'  }}></Column>  
                  <Column field="Semaforo" body={SemaforoTemplate} className="text-center" header={t('orangePage:-')} columnKey="Semaforo" headerStyle={{ width: '40px' }}></Column>  
                  <Column field="FechaEstimadaFin" body={FechaEstimadaFinTemplate} className="text-center" header={t('orangePage:EstimatedEndDate')} columnKey="FechaEstimadaFin" headerStyle={{ width: '110px'  }}></Column>              
                  <Column field="PorcPeso" body={PorcPesoTemplate} className="text-center" header={t('orangePage:Weight')} columnKey="PorcPeso" headerStyle={{ width: '60px' }}></Column>  
                  <Column field="AvanceEstimado" body={AvanceEstimadoTemplate} className="text-center" header={t('orangePage:ProgressEst')} columnKey="AvanceEstimado" headerStyle={{ width: '50px'  }}></Column>  
                  <Column field="AvanceReal" body={AvanceRealTemplate} className="text-center" header={t('orangePage:ProgressReal')} columnKey="AvanceReal" headerStyle={{ width: '50px'  }}></Column>  
                  <Column field="ComentariosActualizacionFase" body={ComentariosActualizacionFaseTemplate} header={t('orangePage:ConsiderableUpd')} columnKey="ComentariosActualizacionFase" headerStyle={{ width: '350px'  }}></Column>  
                </FieldDataTable>
              </Col> 
            </Row> 
          </>
) 
      }
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
          &nbsp;
            </Col> 
          </Row>
          <Row className="align-items-start">
            <Col lg={6} md={6} sm={12} xs={12}> 
              <FieldDataTable 
                value={AccionesList}
                scrollable 
                scrollHeight="330px" 
                className="editable-cells-table"  
                editMode="cell"
                selectionMode="single"
                header={headerAcciones}
              > 
                <Column field="Descripcion" header={t('orangePage:Description')} columnKey="Descripcion" headerStyle={{ width: '495px' }}></Column>  
                <Column
                  field="SourceDescrition"
                  header={t('orangePage:Source')}
                  style={{ display : (ClaTipoProyecto === 1 || ClaTipoProyecto === 2 || ClaTipoProyecto === 3 ?'none':'')}} 
                  headerStyle={{ width: '240px', display : (ClaTipoProyecto === 1 || ClaTipoProyecto === 2 || ClaTipoProyecto === 3 ?'none':'') }}
                >
                </Column>
            
              </FieldDataTable>
            </Col> 
            <Col lg={6} md={6} sm={12} xs={12}> 
              <FieldDataTable 
                value={SiguienesPasosList}
                scrollable 
                scrollHeight="330px" 
                className="editable-cells-table"  
                editMode="cell"
                selectionMode="single"
                header={headerSiguienesPasos}
              > 
                <Column field="Descripcion" header={t('orangePage:Description')} columnKey="Descripcion" headerStyle={{ width: '495px'  }}></Column>  
                <Column
                  field="SourceDescrition"
                  header={t('orangePage:Source')}
                  style={{ display : (ClaTipoProyecto === 1 || ClaTipoProyecto === 2 || ClaTipoProyecto === 3 ?'none':'')}} 
                  headerStyle={{ width: '240px', display : (ClaTipoProyecto === 1 || ClaTipoProyecto === 2 || ClaTipoProyecto === 3 ?'none':'') }}
                >
                </Column>
              </FieldDataTable>
            </Col> 
          </Row> 
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
          &nbsp;
            </Col> 
          </Row>
          <Row className="align-items-start">
            <Col lg={6} md={6} sm={12} xs={12}> 
              <FieldDataTable 
                value={KPisManagementList}
                scrollable 
                scrollHeight="330px" 
                className="editable-cells-table"  
                editMode="cell"
                selectionMode="single"
                header={headerKPisManagement}
              > 
                <Column field="NomProyectoKPIManagement" header={t('orangePage:KPIName')} columnKey="NomProyectoKPIManagement" headerStyle={{ width: '150px'  }}></Column>  
                <Column field="NomProyectoTipoKPIManagement" header={t('orangePage:Type')} columnKey="NomProyectoTipoKPIManagement" headerStyle={{ width: '70px'  }}></Column>  
                <Column field="NomRefSeguimientoKPIManagement" header={t('orangePage:Description')} columnKey="NomRefSeguimientoKPIManagement" headerStyle={{ width: '150px'  }}></Column>  
                <Column field="NomUsuario" header={t('orangePage:Responsible')} columnKey="NomUsuario" headerStyle={{ width: '140px'  }}></Column>  
                <Column field="NomProyectoFrecuenciaKPIManagement" header={t('orangePage:Frecuency')} columnKey="NomProyectoFrecuenciaKPIManagement" headerStyle={{ width: '70px'  }}></Column>  
              </FieldDataTable>
            </Col> 
            <Col lg={6} md={6} sm={12} xs={12}> 
              <FieldDataTable 
                value={SoporteList}
                scrollable 
                scrollHeight="330px" 
                className="editable-cells-table"  
                editMode="cell"
                selectionMode="single"
                header={headerSoporte}
              > 
                <Column field="Descripcion" header={t('orangePage:Description')} columnKey="Descripcion" headerStyle={{ width: '185px'  }}></Column>  
                <Column field="NomUsuario" header={t('orangePage:Responsible')} columnKey="NomUsuario" headerStyle={{ width: '155px'  }}></Column>  
                <Column field="FechaCompromiso" body={FechaCompromisoTemplate} className="text-center" header={t('orangePage:DueDate')} columnKey="FechaCompromiso" headerStyle={{ width: '105px'  }}></Column>  
                <Column field="FechaRealTermino" body={FechaRealTerminoTemplate} className="text-center" header={t('orangePage:FinishedDate')} columnKey="FechaRealTermino" headerStyle={{ width: '105px'  }}></Column>  

              </FieldDataTable>
            </Col> 
          </Row> 
          <Row>
            <Col lg={10} md={10} sm={12} xs={12}>
              &nbsp;
            </Col>
            <Col lg={2} md={2} sm={12} xs={12} className="text-right">
              <div className="p-fluid">          
              </div>        
            </Col>
          </Row>
        </>
      </Dialog>
      <Dialog
        visible={openViewFull}
        style={{ width: '80vw' }}
        footer={null}
        modal 
        onHide={closeModalViewFull}
        contentStyle={{maxHeight: "500px", overflow:"auto"}}
        header={t('orangePage:KPIProgress')}
      >
        <Col lg={12} md={12} sm={12} xs={12}>
          <ProyectoKPIManagementFullView ClaProyecto={ClaProyecto}></ProyectoKPIManagementFullView>
        </Col>
      </Dialog>

      {
        EsMostrarProgressReportParent && 
        (<OrangePage Visible={EsMostrarProgressReportParent} onHide={()=> setEsMostrarProgressReportParent(false)} ClaProyecto={claSelectedProject} IdReporteMensual={idSelectedReporteMensual}></OrangePage> )
      } 

      <Dialog
        visible={EsAbrirDocumentosSoporte}
        header={`${EncabezadoList.NomPeriodo} - ${t('reportevanceMensualProyecto:SupportFilesTitle')}`}
        style={{width: '80vw'}}
        modal
        className="dialog-custom"
        onHide={()=> setEsAbrirDocumentosSoporte(false)}
      >
        <Row>
          <Col>
            <ReporteAvanceMensualProyectoAttachment
              IsUpload={false}
              IdReporteAvanceMensualProyecto={IdReporteMensual}
              IsReadyOnly={true}
            >
            </ReporteAvanceMensualProyectoAttachment>
          </Col>
        </Row>
      </Dialog>
      {
        esMostrarFormulaReview && 
        (  
          <KPIFormulaResultadoReview show={esMostrarFormulaReview} onHide={() => setEsMostrarFormulaReview(false)} AnioMes={AnioMes} IdKPIFormula={IdKPIFormulaSelected}></KPIFormulaResultadoReview>
        )
      }
      <ProjectPerformanceDialog
        claProyecto={ClaProyecto}
        idReporteMensual={IdReporteMensual}
        isVisible={IsProjectPerformanceDialogVisible}
        onHide={onProjectPerformanceHide}
      />
    </>
  );
};
export default OrangePage;

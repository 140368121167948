/* eslint-disable no-const-assign */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { config } from 'utils/config';
import { callApi, showSweetAlert, showNotify } from 'utils/utils'; 
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import YupValidator from 'utils/YupValidator';
import * as yup from 'yup'; 
import FieldText from 'components/Controls/FieldText';
import FieldNumber from 'components/Controls/FieldNumber';
import moment from 'moment'; 
import {
  Card, 
  CardBody, 
  Row,
  Col,
  FormGroup,
  Label,
  CardHeader,
  CardFooter,
} from 'reactstrap'; 
import PageContent from 'layouts/PageContent'; 
import HeaderTablet from '../../Common/Header/HeaderTablet.jsx'; 
import PrincipalHeader from '../../Common/Header/PrincipalHeader.jsx';
import './DashboardUtilizationScreen.scss'; 
import { useHistory, Link } from "react-router-dom"; 
import Highcharts from 'highcharts'; 
import HighchartsReact from 'highcharts-react-official';

const DashboardUtilizationScreen = (props) => {
  const { t, i18n } = useTranslation(['tablets', 'common']);
  const [OperationCheckInList, setOperationCheckInList] = useState([]);
  const [ClaveArticulo, setClaveArticulo] = useState(null);
  const [ClaArticulo, setClaArticulo] = useState(null);
  const [NomArticulo, setNomArticulo] = useState(null);
  const [Cajas, setCajas] = useState(null);
  const [ClaTurno, setClaTurno] = useState(null);
  const [NomTurno, setNomTurno] = useState(null);
  const [ClaAreaProduccion, setClaAreaProduccion] = useState(null);
  const [NomAreaProduccion, setNomAreaProduccion] = useState(null);
  const [NomCelda, setNomCelda] = useState(null);  
  const [errors, setErrors] = useState({});
  const [ClaveCelda, setClaveCelda] = useState(null);
  const [ClaCelda, setClaCelda] = useState(null);
  const [FechaInicial, setFechaInicial] = useState(null);
  const [FechaFinal, setFechaFinal] = useState(null);
  const [NomZona, setNomZona] = useState('')
  const [ColorZona, setColorZona] = useState(null);
  const [ResultadoZona, setResultadoZona] = useState(null);
  const [DashboardUtilizationSemanal, setDashboardUtilizationSemanal] = useState([]);
  const [DashboardUtilizationSemanaActual, setDashboardUtilizationSemanaActual] = useState([]);
  const [IntervaloRojo, setIntervaloRojo] = useState(null);
  const [IntervaloAmarillo	, setIntervaloAmarillo	] = useState(null);
  const [IntervalorVerde, setIntervalorVerde] = useState(null);
  const homeScreenLink = useRef();

  // const IdCelda = props.IdCelda !== undefined ? props.IdCelda : null;
  let IdCelda = null
  
  if(props.location!==undefined){
    const params = queryString.parse(props.location.search);
    IdCelda = params !== undefined ? params.IdCelda : null;
  }
  

  useEffect(() => { 
    OnloadDashboardUtilizationScreen(IdCelda);    
  }, []);

  const OnloadDashboardUtilizationScreen = (pIdCelda) => {  
    setClaveArticulo(null);
    const paramsService = {};
    const urlWebService = `${config.UrlApiProject}Operations/GetOperationOnloadDashboardUtilizationScreen?IdCelda=${pIdCelda}`;
    callApi(urlWebService, 'GET', paramsService, (response) => {
      const DashboardUtilizationHead =
        response.data.DashboardUtilizationHead.length > 0 ? response.data.DashboardUtilizationHead[0] : {};            
        setNomArticulo(DashboardUtilizationHead.NomArticulo);
        setClaArticulo(DashboardUtilizationHead.ClaArticulo);        
        setClaveArticulo(DashboardUtilizationHead.ClaveArticulo);    
        setCajas(DashboardUtilizationHead.Cajas);        
        setClaTurno(DashboardUtilizationHead.ClaTurno);
        setNomTurno(DashboardUtilizationHead.NomTurno);
        setClaAreaProduccion(DashboardUtilizationHead.ClaAreaProduccion);
        setNomAreaProduccion(DashboardUtilizationHead.NomAreaProduccion);        
        setNomCelda(DashboardUtilizationHead.NomCelda); 
        setClaveCelda(DashboardUtilizationHead.ClaveCelda);
        setClaCelda(DashboardUtilizationHead.ClaCelda);
        setFechaInicial(DashboardUtilizationHead.FechaInicial);
        setFechaFinal(DashboardUtilizationHead.FechaFinal);
        setNomZona(DashboardUtilizationHead.NombreZona);
        setColorZona(DashboardUtilizationHead.ColorZona);
        setResultadoZona(DashboardUtilizationHead.ResultadoZona);
        setIntervaloRojo(DashboardUtilizationHead.IntervaloRojo);
        setIntervaloAmarillo(DashboardUtilizationHead.IntervaloAmarillo);
        setIntervalorVerde(DashboardUtilizationHead.IntervalorVerde);
        setDashboardUtilizationSemanal(response.data.DashboardUtilizationSemanal);
        setDashboardUtilizationSemanaActual(response.data.DashboardUtilizationSemanaActual);
    });
  }; 

  const subHeader = (  
    <HeaderTablet
      ClaTurno={ClaTurno}
      NomTurno={NomTurno}
      ClaAreaProduccion={ClaAreaProduccion}
      NomAreaProduccion={NomAreaProduccion}
      IdCelda={IdCelda}
      NomCelda={NomCelda}
      ClaveCelda={ClaveCelda}
    >
    </HeaderTablet> 
  )

  const getColorByID = (colorID) =>
  {
    return colorID === null ? '#c0c0c0'
           : colorID === 1 ?   '#DF5353'
           : colorID === 2 ?   '#DDDF0D'
           : colorID === 3 ?   '#55BF3B'
           : ''
  }

  const getFaceIconClass = (colorID) =>
  {
    return  colorID === null ? ''
           : colorID === 1 ?   'fas fa-frown fa-lg'
           : colorID === 2 ?   'fas fa-meh fa-lg'
           : colorID === 3 ?   'fas fa-laugh fa-lg'
           : ''
  }

  const utilizationChartCfg = { 
    chart: {
        type: 'gauge',
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        height: '50%'
    },

    title: {
        text: '',
        style: {
          display: 'none'
        }
    },

    pane: {
        startAngle: -90,
        endAngle: 89.9,
        background: null,
        center: ['50%', '60%'],
        size: '100%'
    },

    // the value axis
    yAxis: {
        min: 0,
        max: 100,
        tickPixelInterval: 40,
        tickPosition: 'inside',
        tickColor: Highcharts.defaultOptions.chart.backgroundColor || '#FFFFFF',
        tickLength: 20,
        tickWidth: 1,
        minorTickInterval: null,
        labels: {
            distance: 15,
            style: {
                fontSize: '10px'
            }
        },
        plotBands: [{
            from: 0,
            to: IntervaloRojo,
            color: '#DF5353', // 
            thickness: 10
        }, {
            from: IntervaloRojo,
            to: IntervaloAmarillo,
            color: '#DDDF0D', // 
            thickness: 10
        }, {
            from: IntervaloAmarillo,
            to: IntervalorVerde,
            color: '#55BF3B', // 
            thickness: 10
        }]
    },

    series: [{
        name: 'Utilization',
        data: [ResultadoZona],
        tooltip: {
            valueSuffix: ' %',
            enabled: false
        },
        dataLabels: {
            format: '{y} %', 
            borderWidth: 0,
            color: getColorByID(ColorZona),
            style: {
                fontSize: '18px'
            }
        },
        dial: {
            radius: '100%',
            backgroundColor: 'gray',
            baseWidth: 6,
            baseLength: '0%',
            rearLength: '0%'
        },
        pivot: {
            backgroundColor: 'gray',
            radius: 3
        }

    }]

  }
  

  return (
    <>
      <PrincipalHeader IsHomeScreen={false}></PrincipalHeader> 
      <PageContent title="Dashboard" subheader={subHeader}>      
        <Row className='pt-1 align-items-start'>
          <Col lg={6} md={6} sm={12} xs={12}>
            <Card>
              <CardHeader className='production-dashboard-zone'>
                <Row>
                  <Col className='text-center'><span className="h1 white-color">{t('tablets:UtlizationForZone')} - {NomZona} </span></Col>
                </Row>
              </CardHeader>
              <CardBody className='production-dashboard-card-body'> 
                <Row className='align-items-start'>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <HighchartsReact highcharts={Highcharts} options={utilizationChartCfg} />                    
                  </Col> 
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col className='text-center'> 
                    <small> 
                      <i className="fas fa-calendar-alt"></i>&nbsp;
                      <b>{ FechaInicial === null ? '' : moment(FechaInicial).format("MMMM Do YYYY")}
                      &nbsp;-&nbsp;
                        { FechaFinal === null ? '' : moment(FechaFinal).format("MMMM Do YYYY")}
                      </b>
                    </small> 
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col> 
          <Col lg={6} md={6} sm={12} xs={12}>
            <Card>
              <CardHeader className='production-dashboard-cell'>
                <Row>
                  <Col className='text-center'><span className="h1 white-color">{t('tablets:UtlizationForCell')}</span></Col>
                </Row>
              </CardHeader>
              <CardBody className='production-dashboard-card-body'> 
                <Row className='align-items-start'>
                  <Col lg={6} md={6} sm={6} xs={6}>
                    <Row className='pt-1'>
                      <Col>
                        <div className='production-average-by-week light-gray'>
                          <b><span>{t('tablets:AverageByweek')}</span></b>
                        </div> 
                        {DashboardUtilizationSemanal.length >= 1 ? 
                        (  
                          DashboardUtilizationSemanal.map((element, index) => {
                              return ( 
                                <div className='production-dashboard-row'>
                                  <Row>
                                    <Col lg={6} md={6} sm={7} xs={7}>
                                      <Row>
                                        <Col>
                                          <span className="tablet-label">{t('tablets:week')} {element.NumeroSemana} <i className={getFaceIconClass(element.Color)}></i></span>
                                          <div> 
                                            <small>
                                              { element.FechaInicial === null ? '' : moment(element.FechaInicial).format("MMMM DD") }
                                              &nbsp;-&nbsp;
                                              {
                                                  element.FechaFinal === null ? '' : moment(element.FechaFinal).format("DD")
                                                }  
                                            </small> 
                                          </div>
                                        </Col>
                                      </Row> 
                                    </Col>
                                    <Col lg={6} md={6} sm={5} xs={5} className="text-center">
                                      <span className='resultado-utilizacion' style={{ color: getColorByID(element.Color)}}>
                                        {
                                          element.Resultado === null ? ' - '
                                          : (
                                            <>
                                              {element.Resultado} %
                                            </>
                                            ) 
                                        }
                                      </span>
                                    </Col>
                                  </Row> 
                                </div> 
                              );
                            }) 
                      ) : (
                        <></>
                      )} 

                      </Col>    
                    </Row>              
                  </Col> 
                  <Col lg={6} md={6} sm={6} xs={6}>
                    <Row className='pt-1'>
                      <Col>
                        <div className='production-current-week light-gray'>
                          <b><span>{t('tablets:CurrentWeek')}</span></b>
                        </div>
                        {DashboardUtilizationSemanaActual.length >= 1 ? 
                        (  
                          DashboardUtilizationSemanaActual.map((element, index) => {
                              return ( 
                                <div className='production-dashboard-row'>
                                  <Row>
                                    <Col lg={6} md={6} sm={7} xs={7}>
                                      <Row>
                                        <Col>
                                          <span className="tablet-label">{element.NomDia} <i className={getFaceIconClass(element.Color)}></i></span>
                                          <div> 
                                            <small>
                                              { element.FechaDia === null ? '' : moment(element.FechaDia).format("MMMM DD") } 
                                            </small> 
                                          </div>
                                        </Col>
                                      </Row> 
                                    </Col>
                                    <Col lg={6} md={6} sm={5} xs={5} className="text-center">
                                      <span className='resultado-utilizacion' style={{ color: getColorByID(element.Color)}}>
                                        {
                                          element.Resultado === null ? ' - '
                                          : (
                                            <>
                                              {element.Resultado} %
                                            </>
                                            ) 
                                        }
                                      </span>
                                    </Col>
                                  </Row> 
                                </div> 
                              );
                            }) 
                      ) : (
                        <></>
                      )} 
                      </Col>    
                    </Row>              
                  </Col> 
                </Row>
                <Row>
                  <Col>&nbsp;</Col>
                </Row>
              </CardBody> 
            </Card>
          </Col>
        </Row>
        <Link 
          ref={homeScreenLink} 
          to={
               { pathname: "CellHomeMenuScreen", search: `?IsDeviceAssignedOnCell=true` }
             }
        >
        </Link> 
      </PageContent> 
    </>
  );
};

export default DashboardUtilizationScreen;

/* eslint-disable no-const-assign */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { config } from 'utils/config';
import { callApi, showSweetAlert, showNotify } from 'utils/utils'; 
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import YupValidator from 'utils/YupValidator';
import * as yup from 'yup'; 
import FieldText from 'components/Controls/FieldText';
import FieldNumber from 'components/Controls/FieldNumber';
import moment from 'moment'; 
import {
  Card, 
  CardBody, 
  Row,
  Col,
  FormGroup,
  Label,
} from 'reactstrap'; 
import PageContent from 'layouts/PageContent'; 
import HeaderTablet from '../Common/Header/HeaderTablet.jsx'; 
import PrincipalHeader from '../Common/Header/PrincipalHeader.jsx';
import './CheckInScreen.scss';
import PackingList32 from '../../../../assets/img/PackingList32.png';
import { useHistory, Link } from "react-router-dom"; 

const CheckInScreen = (props) => {
  const { t, i18n } = useTranslation(['tablets', 'common']);
  const [OperationCheckInList, setOperationCheckInList] = useState([]);
  const [ClaveArticulo, setClaveArticulo] = useState(null);
  const [ClaArticulo, setClaArticulo] = useState(null);
  const [NomArticulo, setNomArticulo] = useState(null);
  const [Cajas, setCajas] = useState(null);
  const [ClaTurno, setClaTurno] = useState(null);
  const [NomTurno, setNomTurno] = useState(null);
  const [ClaAreaProduccion, setClaAreaProduccion] = useState(null);
  const [NomAreaProduccion, setNomAreaProduccion] = useState(null);
  const [NomCelda, setNomCelda] = useState(null);  
  const [errors, setErrors] = useState({});
  const [ClaveCelda, setClaveCelda] = useState(null);
  const [ClaCelda, setClaCelda] = useState(null);
  const homeScreenLink = useRef();

  // const IdCelda = props.IdCelda !== undefined ? props.IdCelda : null;
  let IdCelda = null
  
  if(props.location!==undefined){
    const params = queryString.parse(props.location.search);
    IdCelda = params !== undefined ? params.IdCelda : null;
  }
  

  useEffect(() => { 
    OnloadCheckInScreen(IdCelda);    
  }, []);

  const OnloadCheckInScreen = (pIdCelda) => {  
    setClaveArticulo(null);
    const paramsService = {};
    const urlWebService = `${config.UrlApiProject}Operations/GetOperationOnloadCheckIn?IdCelda=${pIdCelda}`;
    callApi(urlWebService, 'GET', paramsService, (response) => {
      const operationCheckInHead =
        response.data.OperationCheckInHead.length > 0 ? response.data.OperationCheckInHead[0] : {};            
        setNomArticulo(operationCheckInHead.NomArticulo);
        setClaArticulo(operationCheckInHead.ClaArticulo);        
        setClaveArticulo(operationCheckInHead.ClaveArticulo);    
        setCajas(operationCheckInHead.Cajas);        
        setClaTurno(operationCheckInHead.ClaTurno);
        setNomTurno(operationCheckInHead.NomTurno);
        setClaAreaProduccion(operationCheckInHead.ClaAreaProduccion);
        setNomAreaProduccion(operationCheckInHead.NomAreaProduccion);        
        setNomCelda(operationCheckInHead.NomCelda);
        setOperationCheckInList(response.data.OperationCheckIn);
        setClaveCelda(operationCheckInHead.ClaveCelda);
        setClaCelda(operationCheckInHead.ClaCelda);
    });
  };

  const onChangeClaveArticulo = (e) => {
    const newErrors = { ...errors };
    delete newErrors.ClaveArticulo;
    setErrors(newErrors);
    setClaveArticulo(e.target.value);
    getOperationGetItemByCode(e.target.value === null || typeof(e.target.value) === 'undefined' ? '' : e.target.value);
   
  };

  const onChangeCajas = (e) => {
    const newErrors = { ...errors };
    delete newErrors.Cajas;
    setErrors(newErrors); 
    // convertimos a intero ya que la cajas no son decimales
    setCajas(e.target.value !== null ? Math.floor(e.target.value) : null);
  };

  const getOperationGetItemByCode = (pClaveArticulo) => {  
     
    const paramsService = {};
    const urlWebService = `${config.UrlApiProject}Operations/GetOperationGetItemByCode?ClaveArticulo=${pClaveArticulo}`;
    callApi(urlWebService, 'GET', paramsService, (response) => {
      const operationCheckInByCode =
        response.data.OperationCheckInByCode.length > 0 ? response.data.OperationCheckInByCode[0] : {};      

        // cuando no encuentra el producto lo limpiamos para simular que no existe y que no es un 
        // valor valido, no mandamos mensaje por que la version actual tampoco lo hace.
        if (response.data.OperationCheckInByCode.length === 0){

          setNomArticulo(null); 
          setClaArticulo(null); 
          setClaveArticulo(null); 
        }
        else{
          setNomArticulo(operationCheckInByCode.NomArticulo); 
          setClaArticulo(operationCheckInByCode.ClaArticulo); 
        }

    });
  };

  const onChangeContador = (value, item) => {
    const copyOperationCheckInList = [...OperationCheckInList];
    copyOperationCheckInList[item.RowIndex].Contador = value;
    setOperationCheckInList(copyOperationCheckInList);
  };

  const onSavePostCheckInClick = async () => {
    setErrors({});

    const value = {
      ClaveArticulo,
      Cajas,
      OperationCheckInList
    };

    const validator = new YupValidator(valitationSchemeSave);
    const esValido = await validator.validate(value);
    if (!esValido) {
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      return;
    }
    
    let maquinaValues= '';
    OperationCheckInList.forEach((element) => {
      maquinaValues+=`${element.IdContador}|${element.ClaMaquina}|${element.Contador},`;
    });

    const paramsService = {
      IdCelda: ClaCelda,
      ClaArticulo,
      Cajas,
      ClaTurno,
      ClaAreaProduccion,
      MaquinaValues:maquinaValues,
    };

    const urlWebService = `${config.UrlApiProject}Operations/SavePostCheckIn`;
    callApi(urlWebService, 'POST', paramsService, (response) => { 
      showNotify(t('common:SaveSuccess'), 'centerTop', 'success'); 
      homeScreenLink.current.click();
      setErrors({});
    });  

  };


  const valitationSchemeSave = yup.object().shape({
    ClaveArticulo: yup.string().required(t('common:RequiredField')).nullable(),
    Cajas: yup.number().required(t('common:RequiredField')).nullable(),    
    OperationCheckInList: yup.array().of(
      yup.object().shape({      
        Contador: yup
          .number().required(t('common:RequiredField')).nullable(),    
      })
    ),
  });

  const showActiveRows = (rows) => {
    const activeRows = [];
    if (rows !== undefined) {
      if (rows.length > 0) {
        rows.forEach((item, index) => {
          if (!item.BajaLogica || item.BajaLogica === 0 || item.BajaLogica === undefined) {
            item.BajaLogica = 0;
            item.RowIndex = index;
            activeRows.push({ ...item });
          }
        });
      } else {
        return [];
      }
    }

    return activeRows;
  };

  const subHeader = (  
    <HeaderTablet
      ClaTurno={ClaTurno}
      NomTurno={NomTurno}
      ClaAreaProduccion={ClaAreaProduccion}
      NomAreaProduccion={NomAreaProduccion}
      IdCelda={IdCelda}
      NomCelda={NomCelda}
      ClaveCelda={ClaveCelda}
    >
    </HeaderTablet> 
  )
  

  return (
    <div className='tablet-screen'>
      <PrincipalHeader IsHomeScreen={false}></PrincipalHeader> 
      <PageContent title="Check In" subheader={subHeader}>      
        <Row className='pt-2'>
          <Col lg={12} md={12} sm={12} xs={12}> 
            <span className="tablet-label">{t('tablets:EnterMeterReadings')}</span>         
          </Col>
        </Row>
        <Row>
          <Col>&nbsp;</Col>
        </Row>
        <Row className="align-items-start">
          {
            showActiveRows(OperationCheckInList).map((item, index) => {
              return (
                <Col lg={6} md={6} sm={6} xs={6} key={index}>            
                  <Card>
                    <CardBody>
                      <Row>
                        <Col lg={12} md={12} sm={12} xs={12}> 
                          <span className="tablet-label-content">{item.NomMaquina}</span>         
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <div className="p-fluid">
                            <FieldNumber
                              name="Contador"
                              value={item.Contador}
                              onChange={(e) => onChangeContador(e.target.value, item)}
                              min={-99999999.99}
                              max={99999999.99}
                              mode="decimal"
                              // locale="en-US"
                              // minFractionDigits={2}
                              IsShowKeyboard={true}  
                              errors={errors[`OperationCheckInList[${item.RowIndex}]`]} 
                              title={item.NomMaquina}
                            />
                          </div> 
                        </Col>
                      </Row>                     
                    </CardBody> 
                  </Card>                     
                </Col>   
              );
            })
          }  
         
        </Row>    
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>   
            <span className="tablet-label">{t('tablets:CurrentItem')}</span>         
          </Col>       
        </Row>  
        <Row>
                            &nbsp;
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>  
            <Card>
              <CardBody>                
                <Row className="align-items-start">
                  <Col lg={6} md={6} sm={6} xs={6}>
                    <Row>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <span className="tablet-label-content">{t('tablets:ItemNumber')}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <div className="p-fluid">
                          <FieldNumber  
                            name="ClaveArticulo" 
                            value={ClaArticulo}                         
                            onChange={onChangeClaveArticulo}
                            min={-99999999.99}
                            max={99999999.99}
                            minFractionDigits={0}
                            maxFractionDigits={0} 
                            IsShowKeyboard={true}                         
                            errors={errors}
                            IsShowText={true}
                            title={t('tablets:ItemNumber')}
                          />                       
                        </div>
                      </Col>
                    </Row>                     
                  </Col> 
                  <Col lg={6} md={6} sm={6} xs={6}> 
                    <Row>
                      <Col lg={3} md={3} sm={3} xs={3} className='text-right'>
                        <div className="boxes-button"> 
                          <img className='boxes-buttonIcon' alt="..." src={PackingList32} />
                        </div>
                      </Col>
                      <Col lg={9} md={9} sm={9} xs={9}>
                        <Row> 
                          <Col lg={12} md={12} sm={12} xs={12}>
                            <span className="tablet-label-content">{t('tablets:StartingBoxes')}</span>
                          </Col>
                        </Row>    
                        <Row>
                          <Col lg={12} md={12} sm={12} xs={12}>
                            <div className="p-fluid">
                              <FieldNumber
                                name="Cajas"
                                value={Cajas}
                                onChange={onChangeCajas}
                                min={-99999999}
                                max={99999999}
                                minFractionDigits={0}
                                maxFractionDigits={0}
                                mode="decimal"     
                                IsShowKeyboard={true}                        
                                errors={errors}
                                title={t('tablets:StartingBoxes')}
                              />
                            </div>         
                          </Col>
                        </Row>  
                      </Col>
                    </Row>  
                                                       
                  </Col>       
                </Row>  
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <span className="tablet-label-span">{ClaveArticulo} - {NomArticulo}</span>       
                  </Col>
                </Row>                            
              </CardBody> 
            </Card>     
          </Col>             
        </Row>         
        <Row>
                            &nbsp;
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="text-center">
            <div className="p-fluid">
              <FieldButton
                label={t('tablets:PostCheckIn')}
                className="light-green no-border p-mr-2 btntablet"
                onClick={onSavePostCheckInClick}
              />
            </div> 
          </Col>
        </Row>   
        <Link 
          ref={homeScreenLink} 
          to={
               { pathname: "CellHomeMenuScreen", search: `?IsDeviceAssignedOnCell=true` }
             }
        >
        </Link> 
      </PageContent> 
    </div>
  );
};

export default CheckInScreen;

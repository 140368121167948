import React, { useRef, useEffect  } from 'react';
import IdleTimer from 'react-idle-timer';
import { RefreshToken, IsTokenUpdateRequired } from '../../utils/tokenChecker';

const Session =  (props) => {
  // se encarga de mantener activa la sesion mientras este en el tab correspondiente...
  const interval = useRef();

  const onAction = () => {  
        if (IsTokenUpdateRequired()){
          RefreshToken();
        }
  }; 
 
  
  useEffect(() => {     
    window.addEventListener('visibilitychange', () => {
      if(document.hidden) { 
        clearInterval(interval.current); 
      }
      else {  
        interval.current = setInterval(() => {
          onAction();
            }, 120000); 
      }
    });
      
    interval.current = setInterval(() => {
      onAction();
        }, 120000);
    
        return () => {
          if (interval.current) {
            clearTimeout(interval.current);  
          }  
        }  
    }, []); 



    return (  
      <>  
        <IdleTimer 
          onAction={onAction} 
        >
        </IdleTimer>
      </> 
    )
}   
export default Session; 

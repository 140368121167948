import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { callApi, getSessionItem } from 'utils/utils';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import { config } from 'utils/config';
import { Dialog } from 'primereact/dialog';
import { Row, Col, Label } from 'reactstrap'
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldCheckbox from 'components/Controls/FieldCheckbox';
import EnterTicket from './EnterTicket';
import { Panel } from 'primereact/panel';

const ManageITTicketsFilters = (props) => {

  const {t, i18n} = useTranslation(['it', 'common']);

  const [isFirstRender, setIsFirstRender] = useState(true);
  const [claUsuarioSolicita, setClaUsuarioSolictita] = useState(null); // Filter by support user
  const [claUsuarioSoluciona, setClaUsuarioSoluciona] = useState(null); // Filter by IT User
  const [showCompleted, setShowCompleted] = useState(false); // Show completed checkbox
  const [showCanceled, setShowCanceled] = useState(false); // Show cancelled tickets
  const [isITUser, setIsITUser] = useState(false); // Is the user an IT user
  const [usuarioSolicitas, setUsuarioSolicitas] = useState([]) // Support Users
  const [usuarioSolucionas, setUsuarioSolucionas] = useState([]); // IT Users
  const [showEnterTicket, setShowEnterTicket] = useState(false); // Show Enter Ticket dialog
  const [filters, setFilters] = useState({}); // Filters

  useEffect(() => {
    getITUsers();
    getSupportUsers();

    setIsFirstRender(false);
  }, []);

  
  useEffect(() => {
    console.log(isFirstRender)
    if (!isFirstRender)
    {
      const newFilters = {
        showCanceled,
        showCompleted,
        claUsuarioSolicita,
        claUsuarioSoluciona,
        isITUser,
        isFirstRender
      }

      props.onFiltersChange(newFilters);

      setFilters(newFilters);

      console.log(newFilters)
    }
  }, [showCompleted, showCanceled, claUsuarioSolicita, claUsuarioSoluciona])

  useEffect(() => {
    getSupportUsers();
  }, [claUsuarioSoluciona, showCanceled, showCompleted])

  // Get the list of IT users
  const getITUsers = () => {
    const urlWebService = `${config.UrlApiProject}IT/GetITTicketUsersUSA`;
    const paramsService = {};

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setUsuarioSolucionas(response.data.Users);

      const users = response.data.Users;
      const sessionUser = getSessionItem('ClaUsuarioMod', 0);
      for (const userId of Object.keys(users)) {
        if (sessionUser === users[userId].ClaUsuario) {
          setIsITUser(true);
          setClaUsuarioSoluciona(sessionUser);
          setClaUsuarioSolictita(null);
          break;
        }
        else {
          setClaUsuarioSolictita(sessionUser);
          setClaUsuarioSoluciona(null);
        }
      }
    });
  }

  const getSupportUsers = () => {
    const urlWebService = `${config.UrlApiProject}IT/GetSolicitudSoporteUsuarioSolicitasUSA?showCompleted=${showCompleted === true ? 1 : 0}` +
                          `&showRejected=${showCanceled === true ? 1 : 0}` +
                          `${claUsuarioSoluciona !== null ? `&claUsuarioSoluciona=${claUsuarioSoluciona}` : ''}`;
    const paramsService = {};

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setUsuarioSolicitas(response.data.Users);
    });
  }

  // Handle show completed checkbox change
  const onShowCompletedChanged = (e) => {
    setShowCompleted(e.checked);
  }

  // Handle show canceled checkbox change
  const onShowCanceledChanged = (e) => {
    setShowCanceled(e.checked);
  }

  const onChangeRequesterFilter = (e) => {
    setClaUsuarioSolictita(e.value === undefined ? null : e.value);
  }

  const onChangeAssignedToFilter = (e) => {
    setClaUsuarioSoluciona(e.value === undefined ? null : e.value);
  }

  const onEnterTicketHide = () => {
    setShowEnterTicket(false);
    props.getTickets(filters);
  }

  const filterHeaderTemplate = (options) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down text-white' : 'pi pi-chevron-up text-white';
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className="card-header">
        <div className="p-toolbar p-component">
          <div className="p-toolbar-group-left">
            <div className="card-title">
              <FieldButton className={options.togglerClassName} onClick={options.onTogglerClick}>
                <span className={toggleIcon}></span> 
              </FieldButton>
              <span className={titleClassName}>
                {t('common:Filters')}
              </span>
            </div>  
          </div>
         
          <div className="p-toolbar-group-right">
            <FieldButton label={t('it:EnterTicket')} className='p-button-success no-border p-mr-2' icon='pi pi-plus' onClick={() => setShowEnterTicket(true)} />
            <FieldButton className="p-button p-button-icon-only p-highlight" onClick={() => props.getTickets(filters)}>
              <i className="pi pi-search"></i>
            </FieldButton>
          </div>
        </div> 
      </div>
    )
  }

  return (
    <>
      <Panel
        header={t('common:Filters')}
        toggleable
        headerTemplate={filterHeaderTemplate}
      >
        <Row className='p-fluid p-grid p-formgroup-inline align-items-end'>
          { isITUser ?
          (
            <>
              <Col lg={3} md={6} sm={12} xs={12}>
                <div className='p-field'>
                  <Label>{t('it:Requester')}</Label>
                  <FieldDropdown
                    id='usuarioSolicita'
                    name='usuarioSolicita'
                    value={claUsuarioSolicita}
                    options={usuarioSolicitas}
                    optionValue='ClaUsuarioSolicita'
                    optionLabel='NombreCompleto'
                    onChange={onChangeRequesterFilter}
                  >
                  </FieldDropdown>
                </div>
              </Col>
              <Col lg={3} md={6} sm={12} xs={12}>
                <div className='p-field'>
                  <Label>{t('it:AssignedTo')}</Label>
                  <FieldDropdown
                    id='claUsuarioSoluciona'
                    name='claUsuarioSoluciona'
                    value={claUsuarioSoluciona}
                    options={usuarioSolucionas}
                    optionLabel='NombreCompleto'
                    optionValue='ClaUsuario'
                    onChange={onChangeAssignedToFilter}
                  >
                  </FieldDropdown>
                </div>
              </Col>
            </>
          ) : <></> }
          <Col lg={6} md={6} sm={12} xs={12} className='p-formgroup-inline'>
            <div className='p-field-checkbox'>
              <FieldCheckbox id='showCompleted' name='showCompleted' checked={showCompleted} onChange={onShowCompletedChanged} />
              <label htmlFor='showCompleted'>{t('it:ShowCompleted')}</label>
            </div>
            <div className='p-field-checkbox'>
              <FieldCheckbox id='showCanceled' name='showCanceled' checked={showCanceled} onChange={onShowCanceledChanged} />
              <label htmlFor='showCanceled'>{t('it:ShowCanceled')}</label>
            </div>
          </Col>
        </Row>
      </Panel>
      <Dialog
        visible={showEnterTicket}
        onHide={() => setShowEnterTicket(false)}
        header={t('it:EnterTicket')}
        style={{width: '50vw'}}
        breakpoints={{'1700px': '75vw', '1000px': '100vw'}}
      >
        <EnterTicket onHide={onEnterTicketHide} />
      </Dialog>
    </>
  )

}

export default ManageITTicketsFilters;
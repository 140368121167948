import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// reactstrap components
import { Container, Row, Col } from 'reactstrap';
import logo from '../../assets/img/MSWLogo.png';

class AuthHeader extends React.Component {
  render() {
    return (
      <>
        <div className="header py-7 py-lg-8 pt-lg-8">
          <Container>
            <div className="header-body text-center mb-3">
              <Row className="justify-content-center">
                <Col className="px-5" lg="6" md="8" xl="5">
                  {/* <img src={logo} alt="deaceroLogo"  /> */}
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
           
          </div>
        </div>
      </>
    );
  }
}

AuthHeader.propTypes = {
  title: PropTypes.string,
  lead: PropTypes.string,
};

export default AuthHeader;

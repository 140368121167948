import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { Row, Col, FormGroup, Label  } from 'reactstrap';
import { config } from 'utils/config';
import { callApi, showSweetAlert } from 'utils/utils';
import FieldSelectButton from 'components/Controls/FieldSelectButton';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import YupValidator from 'utils/YupValidator';
import * as yup from 'yup';
import SolictudMaterialCard from 'components/Cards/SolicitudMaterialCard/SolicitudMaterialCard';


const SolicitudMaterialDashboard = (props) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const highLoadDashboard = queryParameters.get("highLoadDashboard");

  const MINUTES_MS = 60000; // 1 minute
  const {t, i18n} = useTranslation(['operations', 'common']);

  const [list, setList] = useState([]); // Request list

  // Get the requests
  const getList = () => {
    const urlWebService = `${config.UrlApiProject}Operations/GetSolicitudMaterialList?EsPendienteEntregar=1&highLoadDashboard=${highLoadDashboard}`; 
    const paramsService = null;

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setList(response.data.Rows);
    });
  };

  useEffect(() => {
    getList();

    const getDataInterval = setInterval(() => {
      getList();
    }, MINUTES_MS)
  }, []);

  return (
    <Row>
      
      {list.map((request) => 
        (
          <Col xs={6} sm={6} md={4} lg={3} key={request.SolicitudMaterialCeldaID}>
            {SolictudMaterialCard(request, t, request.SolicitudMaterialCeldaID)}
          </Col>
        )
      )}
      
    </Row>
  );
}

export default SolicitudMaterialDashboard;
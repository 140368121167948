/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'; 
import { useTranslation } from 'react-i18next'; 
import PageContent from '../../../../layouts/PageContent';  
import { Row, Col, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';   
import InstructionsCard from '../../../../components/Cards/InstructionsCard/InstructionsCard';
import FieldTextArea  from '../../../../components/Controls/FieldTextArea.jsx' 
import FieldButton from '../../../../components/Controls/FieldButton/FieldButton.jsx';
import { Divider } from 'primereact/divider';
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../../utils/utils';  
import { ConstantLineStyle } from 'devextreme-react/polar-chart';
import * as yup from 'yup';
import YupValidator from '../../../../utils/YupValidator'; 
import Highcharts from 'highcharts';
import { Tag } from 'primereact/tag';
import { BackgroundColor } from 'devextreme-react/chart';

const Survey180DegreeFeedback = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);  
  
  const defaultAnio = props.Anio  !== undefined ? props.Anio : null;
  const defaultClaUsuario = props.ClaUsuario  !== undefined && props.ClaUsuario  !== null ? props.ClaUsuario : getSessionItem('ClaUsuarioMod', 0);

  console.log(props.ClaUsuario);
  const usuarioConectado = getSessionItem('ClaUsuarioMod', 0);
    
  const [selfReviewFeedback, setSelfReviewFeedback] = useState([]);
  const [managerReviewFeedback, setManagerReviewFeedback] = useState([]);
  const [commentAvailable, setCommentAvailable] = useState(false);
  const [selfReviewAvailable, setSelfReviewAvailable] = useState(false);
  const [managerReviewAvailable, setManagerReviewAvailable] = useState(false);
  const [esInformacionDisponible, setEsInformacionDisponible] = useState(false);
  const [Comentarios, setComentarios] = useState(null);  
  const [esTienePermisoEdicion, setEsTienePermisoEdicion] = useState(0);  
  const [errors, setErrors] = useState({}); 
  const [idPerformanceDev, setIdPerformanceDev] = useState(null);
  const [EsVersionSoloLectura, setEsVersionSoloLectura] = useState(false);
  const [ActivaRecibirFeedbackCultureSurvey, setActivaRecibirFeedbackCultureSurvey] = useState(false);
  const [RecivingFeedbackResponseQuestion1, setRecivingFeedbackResponseQuestion1] = useState(null);
  const [RecivingFeedbackResponseQuestion2, setRecivingFeedbackResponseQuestion2] = useState(null);
  const [SummaryRatedQuestions, setSummaryRatedQuestions] = useState([]);
  const [SummaryNotRatedQuestions, setSummaryNotRatedQuestions] = useState([]);



  useEffect(() => {    
    load180DegreeReview();
  }, [props.Anio, props.ClaUsuario]); 

  const load180DegreeReview = () => { 
    const Get180DegreeFeedbackUser = `${config.UrlApiProject}hr/Get180DegreeFeedbackUser`;  
    const paramsToService = {  
      ClaUsuario: defaultClaUsuario,
      Anio: defaultAnio
    };     

    callApi(Get180DegreeFeedbackUser, 'POST', paramsToService, (response) => {  
           
        setSelfReviewFeedback(response.data.SelfReview); 
        setManagerReviewFeedback(response.data.ManagerReview); 

        setSelfReviewAvailable(true);
        // setSelfReviewAvailable(response.data.SelfReview.length>0);
        setManagerReviewAvailable(true);
        // setManagerReviewAvailable(response.data.ManagerReview.length>0);
        setCommentAvailable(response.data.Encabezado.length>0); 
        setEsInformacionDisponible(false);
        if (response.data.Encabezado.length>0){
          setComentarios(response.data.Encabezado[0].Comentarios);
          setEsInformacionDisponible(response.data.Encabezado[0].Activa180DegreeReview);
          setIdPerformanceDev(response.data.Encabezado[0].IdPerformanceDev);
          setActivaRecibirFeedbackCultureSurvey(response.data.Encabezado[0].ActivaRecibirFeedbackCultureSurvey);
          setRecivingFeedbackResponseQuestion1(response.data.Encabezado[0].RecivingFeedbackResponseQuestion1);
          setRecivingFeedbackResponseQuestion2(response.data.Encabezado[0].RecivingFeedbackResponseQuestion2);
        } 

        setEsTienePermisoEdicion (defaultClaUsuario === usuarioConectado);

         // varificamos si es solo lectura....
         if (typeof(props.ReadOnlyView) !== 'undefined')
         {
           setEsVersionSoloLectura(props.ReadOnlyView);
         } 
        
         setSummaryRatedQuestions(response.data.SummaryRatedQuestions);
         setSummaryNotRatedQuestions(response.data.SummaryNotRatedQuestions);

    });
  }

  const valitationSchemeComentarios = yup.object().shape({
    Comentarios: yup.string().trim().required(t('common:RequiredField')).nullable()
  }); 


  const onChangeComentarios = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.Comentarios;   
    setErrors(newErrors) 
    setComentarios(e.target.value)
  }

  const onChangeRecivingFeedbackResponseQuestion1 = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.RecivingFeedbackResponseQuestion1;   
    setErrors(newErrors) 
    setRecivingFeedbackResponseQuestion1(e.target.value)
  }

  const onChangeRecivingFeedbackResponseQuestion2 = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.RecivingFeedbackResponseQuestion2;   
    setErrors(newErrors) 
    setRecivingFeedbackResponseQuestion2(e.target.value)
  }

  
  const getRateValue = (currentValue) => {
    let value = '';

    if (currentValue === null){
      currentValue = 0
    }
    
    switch (Math.round(currentValue)) {
      case 0:
        value = ''
        break;
      case 1:
        value = 'Rarely'
        break;
      case 2:
        value = 'Sometimes'
        break;
      case 3:
        value = 'Often'
        break;
      case 4:
        value = 'Almost Always'
        break; 
      case 5:
        value = 'Always'
        break;
      default:
        break;
    }
    
    return value;
  }

  
  const onUpdateClick =  async () => { 
      
    /*
    const value = {
        Comentarios 
    }

    const validator = new YupValidator(valitationSchemeComentarios);
    const esValido = await validator.validate(value);
     
    if (!esValido){ 
        setErrors(validator.errors); 
        return;
    }
    */
    
    const paramsService = {
      IdPerformanceDev: idPerformanceDev,
      Anio : defaultAnio
     ,ClaUsuarioPerformanceDev : defaultClaUsuario 
     ,ComentariosCompanyCulture: Comentarios 
     ,RecivingFeedbackResponseQuestion2
     ,RecivingFeedbackResponseQuestion1 
   };  
    

   const SaveManagerReview = `${config.UrlApiProject}HR/Update180DegreeReviewComments`;
 
   callApi(SaveManagerReview, 'POST', paramsService, (response) => {  
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      load180DegreeReview();  
   }); 
  }

  const getUniqueCompetencies = (array) => {
    const result = [];
    const map = new Map();
    for (const item of array) {
      if(!map.has(item.TituloTopico)){
          map.set(item.TituloTopico, true);    // set any value to Map
          result.push({...item});
      }
    }

    return result;
  }

   
  if(!esInformacionDisponible){
    return (
      <>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="text-center p-7">  
            <h2>{t('hr:Culture180DegreeReviewPeriodClosed')}</h2> 
          </Col>
        </Row>
      </> 
    );
  } 

  if(!ActivaRecibirFeedbackCultureSurvey){
    return (
      <>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="text-center p-7">  
            <h2>{t('hr:ActivaRecibirFeedbackCultureSurveyClosed')}</h2> 
          </Col>
        </Row>
      </> 
    );
  } 
  

  if (selfReviewFeedback.length ===0 && managerReviewFeedback.length === 0){
    return (
      <>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="text-center p-7">  
            <h2>{t('common:NoData')}</h2> 
          </Col>
        </Row>
      </> 
    );
  }


  return (
    <>   
      { EsVersionSoloLectura ? <></> : (
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>   
            <InstructionsCard>
              <p>
                {t('hr:PleaseReviewYourFeedback')} {t('hr:AddCommentBelow')}.
              </p>
            </InstructionsCard>
          </Col>
        </Row>
    )}
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}> 
                &nbsp;
        </Col>
      </Row>
      <Row className="align-items-start mb-4">
        <Col lg={8} md={8} sm={12} xs={12}>
          <div className='p-datatable table-header-background-blue' hidden={SummaryRatedQuestions.length === 0}>
            <table className='p-datatable-scrollable-header-table'>
              <thead className='p-datatable-thead'>
                <tr> 
                  <th style={{width: '40%'}}>
                    Value / Competency
                  </th> 
                  <th style={{width: '20%'}} className='light-blue'>
                    Self-Review Rating
                  </th>
                  <th style={{width: '20%'}} className='orange'>
                    Other&apos;s Review Rating
                  </th>
                  <th style={{width: '20%'}}>
                    Overall Rating
                  </th>
                </tr>
              </thead>
              <tbody className='p-datatable-tbody'>
                {SummaryRatedQuestions.map((element, index) => {  
                          return (  
                            <tr key={index}> 
                              <td>
                                <div className="p-fluid">
                                  {element.TituloTopico} 
                                </div>
                              </td> 
                              <td> 
                                <div className='flex' style={{display:'flex', justifyContent: 'space-between'}}>
                                  <div>{element.SelfReviewRate !== null? Highcharts.numberFormat(element.SelfReviewRate, 2, '.', ','): null}</div>
                                  <div><Tag className="p-mr-2" value={getRateValue(element.SelfReviewRate)} rounded></Tag></div>
                                </div>  
                              </td>
                              <td>
                                <div className='flex' style={{display:'flex', justifyContent: 'space-between'}}>
                                  {element.OthersReviewRate !== null? Highcharts.numberFormat(element.OthersReviewRate, 2, '.', ','): null}
                                  <Tag className="p-mr-2" value={getRateValue(element.OthersReviewRate)} rounded></Tag>
                                </div>
                              </td> 
                              <td>
                                <div className='flex' style={{display:'flex', justifyContent: 'space-between'}}>
                                  {element.OverallRateReview !== null? Highcharts.numberFormat(element.OverallRateReview, 2, '.', ','): null}
                                  <Tag className="p-mr-2" value={getRateValue(element.OverallRateReview)} rounded></Tag>
                                </div>
                              </td> 
                            </tr>  
                          );
                      })}   
                          
              </tbody>
            </table>
          </div>
          <br></br>
          <div className='p-datatable table-header-background-blue' hidden={SummaryRatedQuestions.length === 0}>
            <table className='p-datatable-scrollable-header-table'>
              <thead className='p-datatable-thead'>
                <tr> 
                  <th style={{width: '40%'}}>
                    Value / Competency
                  </th> 
                  <th style={{width: '60%'}}>
                    Feedback Received
                  </th> 
                </tr>
              </thead>
              <tbody className='p-datatable-tbody'>
                {getUniqueCompetencies(SummaryNotRatedQuestions).map((element, index) => {  
                          return (  
                            <tr key={index}> 
                              <td>
                                <div className="p-fluid">
                                  {element.TituloTopico} 
                                </div>
                              </td> 
                              <td> 
                                {
                                  SummaryNotRatedQuestions.filter((item) => item.TituloTopico === element.TituloTopico).map((item, index) => {
                                    return (
                                      <div>
                                        <div className=' pb-2'>
                                          {item.DetalleRespuesta}
                                        </div>
                                        <div className='pt-2' style={{borderTop:'1px dashed #efefef'}}></div>
                                      </div>
                                      
                                    )
                                  })
                                }
                              </td> 
                            </tr>  
                          );
                      })}   
                          
              </tbody>
            </table>
          </div>
          <Row className="align-items-start mb-4 pt-3" hidden={SummaryRatedQuestions.length > 0}> 
            <Col md={managerReviewAvailable ? commentAvailable  ? 6 : 6 : commentAvailable  ? 6 : 12} sm={12} xs={12} style={{ display: selfReviewAvailable ? "block" : "none"}}>  
              <Card> 
                <CardHeader className="light-blue">
                  <CardTitle className="text-center p-2">{t('hr:EmployeeSelfReview')}</CardTitle>
                </CardHeader>    
                <CardBody className="questionario-viewer"> 
                  {selfReviewFeedback.map((element, index) => {  
                    return (  
                      <div key={index}>
                        { index > 0 ? <Divider></Divider> : <></> }
                        <div className="Pregunta">
                          <label><b>{element.DescripcionPregunta}</b></label>
                        </div>
                        <div className="Respuesta">
                          {element.RespuestaPregunta}
                        </div>
                      </div>  
                    );
                })}   
                </CardBody>
              </Card>
            </Col> 
            <Col md={selfReviewAvailable ? commentAvailable  ? 6 : 6 : commentAvailable  ? 6 : 12} sm={12} xs={12} style={{ display: managerReviewAvailable ? "block" : "none"}}>  
              <Card> 
                <CardHeader className="orange">
                  <CardTitle className="text-center p-2">{t('hr:OthersReview')}</CardTitle>
                </CardHeader>    
                <CardBody className="questionario-viewer">
                  {managerReviewFeedback.map((element, index) => {  
                    return (  
                      <div key={index}>
                        { index > 0 ? <Divider></Divider> : <></> }
                        <div className="Pregunta">
                          <label><b>{element.DescripcionPregunta}</b></label>
                        </div>
                        <div className="Respuesta">
                          {element.RespuestaPregunta}
                        </div>
                      </div>  
                    );
                })}  

                  {
                managerReviewFeedback.length === 0 ?
                (<h2>{t('common:NoData')}</h2>)
                :
                (<></>)
              }

                </CardBody>
              </Card>   
            </Col>  
          </Row>  
        </Col>
        <Col lg={4} md={4} sm={12} xs={12} style={{ display: commentAvailable ? "block" : "none"}}>  
          <Card> 
            <CardBody>
              <Row className="align-items-start">
                <Col lg={12} md={12} sm={12} xs={12}> 
                  <div className="text-center"> 
                    <span className="bold">
                      <h2> {t('hr:SelfReflecton')} </h2>{' '}
                    </span> 
                  </div> 
                  <br></br>
                  <div> 
                    <label><b>{t('hr:SelfReflectonQuestion1')}</b></label> 
                  </div>
                  {esTienePermisoEdicion && EsVersionSoloLectura === false ? (
                    <div className="p-fluid">
                      <FieldTextArea 
                        name="RecivingFeedbackResponseQuestion1"
                        rows={8}
                        placeholder=''
                        value={RecivingFeedbackResponseQuestion1} 
                        onChange={onChangeRecivingFeedbackResponseQuestion1}
                        errors={errors}
                        maxLength={8000}
                      /> 
                    </div>
                  ) : <label className="text-left">{RecivingFeedbackResponseQuestion1}</label> }
                  <br></br>
                  <div> 
                    <label><b>{t('hr:SelfReflectonQuestion2')}</b></label> 
                  </div>
                  {esTienePermisoEdicion && EsVersionSoloLectura === false ? (
                    <div className="p-fluid">
                      <FieldTextArea 
                        name="RecivingFeedbackResponseQuestion2"
                        rows={8}
                        placeholder=''
                        value={RecivingFeedbackResponseQuestion2} 
                        onChange={onChangeRecivingFeedbackResponseQuestion2}
                        errors={errors}
                        maxLength={8000}
                      /> 
                    </div>
                  ) : <label className="text-left">{RecivingFeedbackResponseQuestion2}</label> }
                  
                </Col>
              </Row>
              <Row className="align-items-start">
                <Col lg={12} md={12} sm={12} xs={12}> 
                  <div>
                    <label><b>{t('hr:Comments')}</b></label> 
                  </div> 
                  {esTienePermisoEdicion && EsVersionSoloLectura === false ? (
                    <div className="p-fluid">
                      <FieldTextArea 
                        name="Comentarios"
                        rows={8}
                        placeholder=''
                        value={Comentarios} 
                        onChange={onChangeComentarios}
                        errors={errors}
                        maxLength={8000}
                      /> 
                    </div>
                  ) : <label className="text-left">{Comentarios}</label> }
                  { EsVersionSoloLectura ? <></> : (
                    <div className="text-center" style={{ display: esTienePermisoEdicion ? "block" : "none"}}>
                      <FieldButton label={t('common:Update')} className="light-green no-border rounded-button p-3 pl-5 pr-5  font-weight-bold" onClick={onUpdateClick} /> 
                    </div>
                )}
                </Col> 
              </Row>
            </CardBody>
          </Card>
        </Col> 
      </Row> 
    </>  
        ); 
};

export default Survey180DegreeFeedback;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'; 
import PageContent from 'layouts/PageContent.jsx';
import ViewEmployeeItAssets from './ViewEmployeeITAssets';
import ViewEmployeeITAssetsFilters from './ViewEmployeeITAssetsFilters';


const ViewEmployeeItAssetsScreen = () => {
  const { t } = useTranslation(['hr', 'common']);
  const [filters, setFilters] = useState(null);

  return (
    <PageContent title={t('hr:ViewEmployeeITAssets')}>
      <div>
        <ViewEmployeeITAssetsFilters setFilters={setFilters} />
      </div>
      <div className='mt-2'>
        <ViewEmployeeItAssets filters={filters} />
      </div>
    </PageContent>
  )
}

export default ViewEmployeeItAssetsScreen;

import PageContent from 'layouts/PageContent';
import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import VisitLogMovil from './VisitLogMovil';
import { config } from 'utils/config';
import { callApi, getSessionItem, showSweetAlert, getDeviceUniqueID } from 'utils/utils';


const VisitLogMovilScreen = (props) => {
  const { t } = useTranslation(['visitLog', 'common']);    
  const { ClaUbicacion } = props.match.params
  
  useEffect(() => {
    console.log(props.match.params);
  }, []);


  
  return (
    <PageContent title={t('visitLog:VisitLog')}>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <VisitLogMovil    
            ClaUbicacion={ClaUbicacion}  
          >
          </VisitLogMovil>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
            &nbsp;
        </Col>
      </Row>              
    </PageContent>
  )
}

export default VisitLogMovilScreen

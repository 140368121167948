/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next'; 
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row, Col } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi, showSweetAlert, tracker } from '../../../utils/utils';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon';
import swal from '@sweetalert/with-react'; 
import { ProgressBar } from 'primereact/progressbar';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldAutoComplete from 'components/Controls/FieldAutoComplete';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import FieldCheckbox from 'components/Controls/FieldCheckbox';
import { Dialog } from 'primereact/dialog';
import moment from "moment"; 

const ProyectoInvolucrados = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['proyectoinvolucrados', 'common']);
  const [ClaProyecto, setClaProyecto] = useState(
    props.ClaProyecto === undefined ? 0 : props.ClaProyecto
  );
  const [ProyectoInvolucradosList, setProyectoInvolucradosList] = useState({});
  const [OpcionesRolProyecto, setOpcionesRolProyecto] = useState({});
  const [ClaInvolucrado, setClaInvolucrado] = useState(null);
  const [filteredResponsible, setfilteredResponsible] = useState([]);
  const [errors, setErrors] = useState({}); 
  const [ClaRolProyecto, setClaRolProyecto] = useState(null);   
  const [OpcionesRoleProyecto, setOpcionesRoleProyecto] = useState([]); 
  const [ClaTipoProyecto, setClaTipoProyecto] = useState(null);
  const [EsMostrarSoloUsuarioMSW, setEsMostrarSoloUsuarioMSW] = useState(false);
  const [DetailActivityList, setDetailActivityList] = useState([]);
  const [EsMostrarDetalleActividades, setEsMostrarDetalleActividades] = useState(false);
  const [TeamMemberNameSelected, setTeamMemberNameSelected] = useState('');
  useEffect(() => {
    obtenerInformacionProyectoInvoludrado();  
    obtenerInformacionProyectoCaratula();  
    obtenerRolProyecto();
  }, []);

  const obtenerInformacionProyectoInvoludrado = () => {
    const GetProyectoInvoludradoList = `${config.UrlApiProject}ScoreCard/GetProyectoInvolucrados?ClaProyecto=${ClaProyecto}`;
    const paramsToService = {};
    callApi(GetProyectoInvoludradoList, 'GET', paramsToService, (response) => {
      setProyectoInvolucradosList(response.data.ProyectoInvolucrados);
    });
  };
  const saveRolProyectoInvolucrados = async (proyectoInvolucrado) => {
    const urlWebServiceSave = `${config.UrlApiProject}ScoreCard/SaveRolProyectoInvolucrados`;
    const paramsService = {
      ...proyectoInvolucrado,
    };
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {
      obtenerInformacionProyectoInvoludrado();
    });
  };

  const bajaProyectoInvolucrado = async (proyectoInvolucrado) => {
    const urlWebServiceSave = `${config.UrlApiProject}ScoreCard/SaveBajaProyectoInvolucrados`;
    const paramsService = {
      ...proyectoInvolucrado,
    };
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {
      obtenerInformacionProyectoInvoludrado();
    });
  };
  const obtenerRolProyecto = () => {
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/GetRolProyectoCmb`;
    const paramsToService = {};
    callApi(GetSummaryScorecardUser, 'GET', paramsToService, (response) => {
      setOpcionesRolProyecto(response.data.RolesProyecto);
      setOpcionesRoleProyecto(response.data.RolesProyecto); 
    });
  };
  const obtenerInformacionProyectoCaratula = () => { 
    const GetProyectoCaratula = `${config.UrlApiProject}ScoreCard/GetProyectoCaratula?ClaProyecto=${ClaProyecto}`;  
    const paramsToService = {  
    };   
    callApi(GetProyectoCaratula, 'POST', paramsToService, (response) => {
      setClaTipoProyecto(response.data.ProyectoCaratula[0].ClaTipoProyecto);
    });
  }
  const onRolProyectoChange = (props, e) => {
    const saveArray = getCurrentRowValues(props);
    const updatedProducts = [...props.value];
    const clarolproyecto = OpcionesRolProyecto.filter((item) => item.ClaRolProyecto === e.value)[0];
    if (clarolproyecto !== undefined) {
      updatedProducts[props.rowIndex].NomRolProyecto = clarolproyecto.NomRolProyecto;
    }
    updatedProducts[props.rowIndex].ClaRolProyecto = e.value;
    saveArray.ClaRolProyecto = e.value;
    setProyectoInvolucradosList(updatedProducts);
    const proyectoInvolucradoRow = {
      ClaUsuario: updatedProducts[props.rowIndex].ClaUsuario,
      ClaRolProyecto: updatedProducts[props.rowIndex].ClaRolProyecto,
      ClaProyecto: updatedProducts[props.rowIndex].ClaProyecto,
    };
    saveRolProyectoInvolucrados(proyectoInvolucradoRow);
  };
  const getCurrentRowValues = (props) => {
    const updatedProducts = [...props.value];
    const RowValues = {
      ClaUsuario: updatedProducts[props.rowIndex].ClaUsuario,
      ClaRolProyecto: updatedProducts[props.rowIndex].ClaRolProyecto,
      ClaProyecto: updatedProducts[props.rowIndex].ClaProyecto,
      NomUsuario: updatedProducts[props.rowIndex].NomUsuario,
      NomRolProyecto: updatedProducts[props.rowIndex].NomRolProyecto,
      TotalActividades: updatedProducts[props.rowIndex].TotalActividades,
      ActividadesAbiertas: updatedProducts[props.rowIndex].ActividadesAbiertas,
      ActividadesAtrasadas: updatedProducts[props.rowIndex].ActividadesAtrasadas,
    };
    return RowValues;
  };

  const TipoDetalleType = {
    All: 1,
    Open: 2,
    Delayed: 3 
  };

  const GetActivityDetail = (ClaProyecto, ClaUsuario, TipoDetalleType) => {
    const getProjectActivityDetail = `${config.UrlApiProject}ScoreCard/GetProjectActivityDetailByUser?ClaProyecto=${ClaProyecto}&ClaUsuario=${ClaUsuario}&TipoDetalle=${TipoDetalleType}`;
    /*
    const paramsToService = {
      ClaProyecto,
      ClaUsuario,
      TipoDetalle: TipoDetalleType
    };
    */ 
    callApi(getProjectActivityDetail, 'GET', {}, (response) => { 
      setDetailActivityList(response.data.DetalleActividadesUsuario);
    });
  };

  const onClickViewActivityDetail = (ClaUsuario, TipoDetalleType, NomUsuario) => {
    setEsMostrarDetalleActividades(true);
    setDetailActivityList([]);
    setTeamMemberNameSelected(NomUsuario);
    GetActivityDetail(ClaProyecto, ClaUsuario, TipoDetalleType); 

    /*
    All: 1,
    Open: 2,
    Delayed: 3 
    */
    tracker.trackEvent({
      type: 'ProyectoInvolucrados', /* component name */
      action: TipoDetalleType === 1 ? 'VIEW_ACTIVITIES_MEMBER' :
              TipoDetalleType === 2 ? 'VIEW_OPEN_ACTIVITIES_MEMBER' :
              'VIEW_DELAYED_ACTIVITIES_MEMBER'
    }) 

  }
  
  const headerActivity =(
    <> 
    </>
      );

  const AccionesTemplate = (row) => {
    let className = '';
    const Porcentaje =
      (row.TotalActividades > 0 && row.ActividadesAbiertas > 0
        ? (row.TotalActividades - row.ActividadesAbiertas) / row.TotalActividades
        : 1.0) * 100.0;
    switch (true) {
      case Porcentaje < 50:
        className = 'danger-color';
        break;
      case Porcentaje < 75:
        className = 'orange-color';
        break;
      case Porcentaje < 99:
        className = 'warning-color';
        break;
      case Porcentaje === 100:
        className = 'success-color';
        break;
      default:
        className = 'default-color';
    }
    return (
      <>
        <div>
          <ProgressBar
            className={className}
            value={Porcentaje.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          >
          </ProgressBar>
        </div>
      </>
    );
  };
  const onConfirmBaja = (proyectoInvolucrado, props) => {
    const proyectoRow = {
      ClaUsuario: proyectoInvolucrado.ClaUsuario,
      ClaRolProyecto: proyectoInvolucrado.ClaRolProyecto,
      ClaProyecto: proyectoInvolucrado.ClaProyecto,
    };
    swal({
      buttons: {
        cancelar: {
          text: t('common:Cancel'),
          value: 'cancel',
          className: 'rounded-button danger',
        },
        continuar: {
          text: t('common:Continue'),
          value: 'continue',
          className: 'rounded-button light-green',
        },
      },
      content: confirmationMessageHTML,
    }).then((value) => {
      switch (value) {
        case 'continue':
          bajaProyectoInvolucrado(proyectoRow);
          break;
        default:
          break;
      }
    });
  };
  const confirmationMessageHTML = (
    <div>
      <SweatAlertCustomIcon
        title={t('common:SubmitConfirmation')}
        letter={t('common:QuestionMark')}
      >
      </SweatAlertCustomIcon>
      <div>{t('proyectoinvolucrados:SubmitCancelled')}.</div>
    </div>
  );
  const actionBodyTemplate = (rowData, itemProps) => {
    if (props.readOnly === true){
      return (<></>)
    }

    return (
      <>
        <FieldButton
          icon="pi pi-trash"
          onClick={() => onConfirmBaja(rowData, itemProps)}
          className="p-button-rounded p-button-warning" 
        />
      </>
    );
  };

  const RolProyectoEditor = (itemProps) => {

    if (props.readOnly === true){
      return (<span>{itemProps.rowData.NomRolProyecto}</span>)
    }

    return (
      <div className="p-fluid">
        <FieldDropdown
          name="ClaRolProyecto"
          value={itemProps.rowData.ClaRolProyecto}
          options={OpcionesRolProyecto}
          optionValue="ClaRolProyecto"
          optionLabel="NomRolProyecto"
          onChange={(e) => onRolProyectoChange(itemProps, e)}
        />
      </div>
    );
  };
  const onChangeInvolucrado = (e) => {
    const newErrors = { ...errors };
    delete newErrors.ClaInvolucrado;
    setErrors(newErrors);
    setClaInvolucrado(e.value);
  };
  const onChangeClaRolProyecto = (value) => {
    const newErrors = { ...errors };
    delete newErrors.ClaRolProyecto;
    setErrors(newErrors);
    setClaRolProyecto(value);
  };

  const filterResponsible = (event) => {
    let valor = '';
    if (ClaInvolucrado !== null && ClaInvolucrado !== '' && event.query === '') {
      valor = ClaInvolucrado.NomUsuario;
    } else {
      valor = event.query;
    }
    const paramsServiceOwners = {};
    // event.query
    const urlWebServiceOwners = `${config.UrlApiProject}ScoreCard/GetUsuariosCmb?descriptionFilter=${valor}&EsMostrarSoloUsuarioMSW=${!EsMostrarSoloUsuarioMSW?1:0}`;
    // Get owner
    callApi(urlWebServiceOwners, 'GET', paramsServiceOwners, (response) => {
      setfilteredResponsible(response.data.Usuarios);
    });
  };
  const addNewMember = async (e) => {
    const value = {
      ClaInvolucrado,
      ClaRolProyecto,
    };

    const validator = new YupValidator(valitationSchemeAgregarInvolucrado);
    const esValido = await validator.validate(value);
    if (!esValido) {
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      return;
    }
    const urlWebServiceSave = `${config.UrlApiProject}ScoreCard/SaveInvolucrados`;
    const paramsService = {
      ClaUsuario: ClaInvolucrado.ClaUsuario,
      ClaRolProyecto,
      ClaProyecto
    };
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {
      obtenerInformacionProyectoInvoludrado();
      setClaInvolucrado(null);
      showSweetAlert('success', t('common:SuccessOperation'), 'success');

      if (props.onChange !== null && props.onChange !== 'undefined' && typeof(props.onChange) === 'function'){
        props.onChange();
      }
    });
    
    
  };
  const valitationSchemeAgregarInvolucrado = yup.object().shape({
    ClaInvolucrado: yup.object({ ClaUsuario: yup.string().required(t('common:RequiredField')) }).required(t('common:RequiredField')).nullable(),
    ClaRolProyecto: yup.mixed().required(t('common:RequiredField')).nullable(),
  });

  

  const onChangeIncluirSoloUsuariosMSW = (props, e) => {
    setEsMostrarSoloUsuarioMSW(e.checked ? 1 : 0); 
  };

  const closeModalActivityDetail = () => {
    setEsMostrarDetalleActividades(false);
  };
   

  const TotalActividadesTemplate = (props) => {  
    return (<><div className='Cursor text-underline' onClick={() => onClickViewActivityDetail(props.ClaUsuario, TipoDetalleType.All, props.NomUsuario)}><a href='#'><span title="View Acitivies">{props.TotalActividades}</span></a></div></>);
    
  }
  const ActividadesAbiertasTemplate= (props) => {  
    return (<><div className='Cursor text-underline' onClick={() => onClickViewActivityDetail(props.ClaUsuario, TipoDetalleType.Open, props.NomUsuario)}><a href='#'><span title="View Acitivies">{props.ActividadesAbiertas}</span></a></div></>);
    
  }
  const ActividadesAtrasadasTemplate= (props) => {  
    return (<><div className='Cursor text-underline' onClick={() => onClickViewActivityDetail(props.ClaUsuario, TipoDetalleType.Delayed, props.NomUsuario)}><a href='#'><span title="View Acitivies">{props.ActividadesAtrasadas}</span></a></div></>);
    
  }

  const FechaEstimadaFinTemplate = (props) => {
    return (<span>{props.FechaEstimadaFin === null ? '' : moment(props.FechaEstimadaFin).format("ll") } </span>)
  }

  const FechaRealFinTemplate = (props) => {
    return (<span>{props.FechaRealFin === null ? '' : moment(props.FechaRealFin).format("ll") } </span>)
  }

  return (
    <>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
            &nbsp;
        </Col>
      </Row>
      <Row hidden={props.readOnly === true}>
        <Col lg={2} md={2} sm={12} xs={12} className='text-right'>
          <div className="p-fluid">
            <span className="mr-2">{t('misProyectosScreen:EsMostrarSoloUsuarioMSW')}</span>
            <FieldCheckbox
              inputId={EsMostrarSoloUsuarioMSW}
              checked={EsMostrarSoloUsuarioMSW === 1}
              onChange={(e) => onChangeIncluirSoloUsuariosMSW(props, e)}
            />
          </div>
        </Col>
        <Col lg={5} md={5} sm={12} xs={12}>
          <Row>
            <Col>
              <div className="p-fluid"> 
                <FieldAutoComplete
                  dropdown
                  name="ClaInvolucrado"
                  label={t('caratulaproyecto:SeleccionaInvolucrado')}
                  value={ClaInvolucrado}
                  onChange={onChangeInvolucrado}
                  suggestions={filteredResponsible}
                  field="NomUsuario"
                  completeMethod={filterResponsible}
                  errors={errors}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col lg={3} md={3} sm={12} xs={12}>
          <div className="p-fluid">
            <FieldDropdown
              name="ClaRolProyecto"
              value={ClaRolProyecto}
              options={OpcionesRoleProyecto}
              optionValue="ClaRolProyecto"
              optionLabel="NomRolProyecto"
              onChange={(e) => onChangeClaRolProyecto(e.value)}
              label={t('caratulaproyecto:SeleccionaRole')}
              errors={errors}
            />
          </div>
        </Col>
        <Col lg={2} md={2} sm={12} xs={12}>
          <FieldButton
            label={t('common:Add')}
            className="primary no-border pr-3 pl-3 rounded-button"
            onClick={addNewMember}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          &nbsp;
        </Col>
      </Row>
      <FieldDataTable
        value={ProyectoInvolucradosList}
        scrollable
        scrollHeight="330px"
        className="editable-cells-table"
        editMode="cell"
        selectionMode="single"
      > 
        <Column
          body={(rowData, props) => actionBodyTemplate(rowData, props)}
          headerStyle={{ width: '65px' }} 
        >
        </Column> 
        
        <Column
          field="ClaveUsuario"
          header={t('proyectoinvolucrados:ClaveUsuario')}
          columnKey="ClaveUsuario"
          headerStyle={{ width: '110px' }}
        >
        </Column>
        <Column
          field="NomUsuario"
          header={t('proyectoinvolucrados:Member')}
          columnKey="NomUsuario"
          headerStyle={{ width: '250px' }}
        >
        </Column>
        <Column
          field="NomCortoRolIndicador"
          className="text-center"
          header={t('proyectoinvolucrados:IndexRole')}
          columnKey="NomCortoRolIndicador"
          headerStyle={{ width: '80px' }}
        >
        </Column>
        <Column
          field="NomRolProyecto"
          header={t('proyectoinvolucrados:Role')}
          columnKey="NomRolProyecto"
          headerStyle={{ width: '230px' }}
          editor={(props) => RolProyectoEditor(props)}
        >
        </Column>
        
        <Column
          field="TotalActividades"
          className="text-center"
          header={t('proyectoinvolucrados:Activities')}
          columnKey="TotalActividades"
          headerStyle={{ width: '95px', display : (ClaTipoProyecto === 4 || ClaTipoProyecto === 5 ? 'none':'') }}
          style={{ display : (ClaTipoProyecto === 4 || ClaTipoProyecto === 5 ? 'none':'')}}
          body={TotalActividadesTemplate}
        >
        </Column>
        <Column
          field="ActividadesAbiertas"
          className="text-center"
          header={t('proyectoinvolucrados:OpenActivities')}
          columnKey="ActividadesAbiertas"
          headerStyle={{ width: '95px', display : (ClaTipoProyecto === 4 || ClaTipoProyecto === 5 ? 'none':'') }}
          style={{ display : (ClaTipoProyecto === 4 || ClaTipoProyecto === 5 ? 'none':'')}}
          body={ActividadesAbiertasTemplate}
        >
        </Column>
        <Column
          field="ActividadesAtrasadas"
          className="text-center"
          header={t('proyectoinvolucrados:DelayedActivities')}
          columnKey="ActividadesAtrasadas"
          headerStyle={{ width: '95px', display : (ClaTipoProyecto === 4 || ClaTipoProyecto === 5 ? 'none':'') }}
          style={{ display : (ClaTipoProyecto === 4 || ClaTipoProyecto === 5 ? 'none':'')}}
          body={ActividadesAtrasadasTemplate}
        >
        </Column>
        <Column          
          body={AccionesTemplate}
          className="text-center"
          header={t('proyectoinvolucrados:Progress')}
          headerStyle={{ width: '180px', display : (ClaTipoProyecto === 4 || ClaTipoProyecto === 5 ? 'none':'') }}
          style={{ display : (ClaTipoProyecto === 4 || ClaTipoProyecto === 5 ? 'none':'')}}
        >          
        </Column>
           
        
       
      </FieldDataTable>

      <Dialog
        visible={EsMostrarDetalleActividades}
        header={`${TeamMemberNameSelected} | Activities`}
        style={{width:'80vw'}}
        modal
        className="dialog-custom"
        onHide={closeModalActivityDetail}
      >
        <Row className="align-items-start">
          <Col lg={12} md={12} sm={12} xs={12}>
            <FieldDataTable
              value={DetailActivityList}
              scrollable 
              scrollHeight="330px" 
              className="editable-cells-table"  
              editMode="cell"
              selectionMode="single"
              header={headerActivity}
            > 
              <Column
                field="NomFase"
                header={t('focusReport:Phase')}
                columnKey="NomFase"
                headerStyle={{ width: '145px'  }}                
              > 
              </Column>
              <Column
                field="NomActividad"
                header={t('focusReport:DescriptionModal')}
                columnKey="NomActividad"
                headerStyle={{ width: '280px' }}                
              > 
              </Column>
              <Column
                field="FechaEstimadaFin"
                className="text-center"
                header={t('focusReport:DueDate')}
                columnKey="FechaEstimadaFin"
                headerStyle={{ width: '40px', textAlign: 'center' }}
                body={FechaEstimadaFinTemplate}
              >
                 
              </Column>
              <Column
                field="FechaRealFin"
                className="text-center"
                header={t('focusReport:CompletedDate')}
                columnKey="FechaRealFin"
                headerStyle={{ width: '60px', textAlign: 'center' }}
                body={FechaRealFinTemplate}
              >                 
              </Column>   
            </FieldDataTable>
          </Col>
        </Row>
      </Dialog>
    </>
  );
};
export default ProyectoInvolucrados;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter, Label } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../utils/utils';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon';
import moment from 'moment';
import Highcharts from 'highcharts';
import swal from '@sweetalert/with-react';
import PageContent from 'layouts/PageContent';
import { Toolbar } from 'primereact/toolbar';
import { ProgressBar } from 'primereact/progressbar';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldDropdownMultiSelect from '../../../components/Controls/FieldDropDownMultiSelect/FieldDropdownMultiSelect.jsx';
import FieldText from 'components/Controls/FieldText.jsx';
import FieldNumber from 'components/Controls/FieldNumber.jsx';
import FieldCheckbox from 'components/Controls/FieldCheckbox.jsx';
import FieldAutoComplete from 'components/Controls/FieldAutoComplete.jsx';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import { Dialog } from 'primereact/dialog';
import Select, { InputActionMeta } from 'react-select';
import { Panel } from 'primereact/panel';

const SalesPerformanceSalesRepManagementFilter = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['salesPerformanceSales', 'common']);
  const [ClaTipoProyecto, setClaTipoProyecto] = useState(1);
  const [FechaEstimadaIni, setFechaEstimadaIni] = useState(null);
  const [FechaEstimadaFin, setFechaEstimadaFin] = useState(null);
  const [ClaProyecto, setClaProyecto] = useState(null);
  const [AdvancedSearchText, setAdvancedSearchText] = useState(null);
  const [ClaEstatusActividad, setClaEstatusActividad] = useState([]);
  const [errors, setErrors] = useState({});
  const [ShowOnlyPending, setShowOnlyPending] = useState(false);
  const [OpcionesClaAgenteCom, setOpcionesClaAgenteCom] = useState([]);
  const [OpcionesClaAgenteMgr, setOpcionesClaAgenteMgr] = useState([]);
  const [OpcionesClaDivision, setOpcionesClaDivision] = useState([]);
  const [ClaAgenteSalesManager, setClaAgenteSalesManager] = useState(null); 
  const [ClaAgenteSalesRep, setClaAgenteSalesRep] = useState(null);
  const [ClaDivision, setClaDivision] = useState(null);

  useEffect(() => {
    GetAgenteCmb();
    GetDivisionCmb();
    setErrors({});
  }, []);

  const GetAgenteCmb = () => {
    const getAgenteCmb = `${config.UrlApiProject}SalesPerformanceSales/GetAgenteCmb`;
    const paramsToService = {};
    callApi(getAgenteCmb, 'GET', paramsToService, (response) => {
      setOpcionesClaAgenteCom(response.data.Agente);
      setOpcionesClaAgenteMgr(response.data.Agente);
    });
  };

  const GetDivisionCmb = () => {
    const getDivisionCmb = `${config.UrlApiProject}SalesPerformanceSales/GetDivisionCmb`;
    const paramsToService = {};
    callApi(getDivisionCmb, 'GET', paramsToService, (response) => {
      setOpcionesClaDivision(response.data.Division);
    });
  }; 

  const onApplyFilters = async () => {
    if (props.onSearch) {
      const filtersData = {};
      filtersData.flagSave = 0;
      filtersData.ShowOnlyPending = ShowOnlyPending ? 1 : 0;
      filtersData.ClaAgenteSalesManager = typeof(ClaAgenteSalesManager) !== 'undefined' ? ClaAgenteSalesManager : null;
      filtersData.ClaAgenteSalesRep = typeof(ClaAgenteSalesRep) !== 'undefined' ? ClaAgenteSalesRep : null ;
      filtersData.ClaDivision = typeof(ClaDivision) !== 'undefined' ? ClaDivision : null  ;
      props.onSearch(filtersData);
    }
  };

  const onClickSaveSettins = () => {
    if (props.onSave) {
      const filtersData = {};
      filtersData.flagSave = 1;
      props.onSave(filtersData);
    }
  };

  const filterHeaderTemplate = (options) => {
    const toggleIcon = options.collapsed
      ? 'pi pi-chevron-down text-white'
      : 'pi pi-chevron-up text-white';
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className="card-header">
        <div className="p-toolbar p-component">
          <div className="p-toolbar-group-left">
            <div className="card-title">
              <FieldButton className={options.togglerClassName} onClick={options.onTogglerClick}>
                <span className={toggleIcon}></span>
              </FieldButton>
              <span className={titleClassName}>{t('common:Filters')}</span>
            </div>
          </div>

          <div className="p-toolbar-group-right">
            <FieldButton
              label={t('myactivities:Search')}
              icon="pi pi-search"
              className="p-button p-highlight"
              onClick={onApplyFilters}
            >
            </FieldButton>
            &nbsp;
            <FieldButton
              label={t('myactivities:Save')}
              icon="pi pi-save"
              className="light-green no-border p-mr-2"
              onClick={onClickSaveSettins}
            >
            </FieldButton>
            &nbsp;
            {/* <FieldButton label={t('myactivities:Export')} icon="fa fa-file-excel" className="secondary no-border" onClick={onExportExcelMyProject}>              
            </FieldButton>             */}
          </div>
        </div>
      </div>
    );
  };

  const onChangePendingSearch = (props, e) => {
    setShowOnlyPending(e.checked);
  };

  const onClaAgenteSalesManagerComChange = (props, e) => {  
    setClaAgenteSalesManager(e.value); 
  };

  const onClaAgenteSalesRepComChange = (props, e) => {  
    setClaAgenteSalesRep(e.value); 
  };

  
  const onClaDivisionComChange = (props, e) => {  
    setClaDivision(e.value); 
  };

  

  return (
    <>
      <Panel header={t('common:Filters')} toggleable headerTemplate={filterHeaderTemplate}>
        <Row>
          <Col lg={3} md={3} sm={12} xs={12} className="p-3">
            <div className="p-fluid">
              <Label className="mr-2">{t('salesPerformanceSales:OnlyPending')}</Label>
              <FieldCheckbox
                inputId={ShowOnlyPending}
                checked={ShowOnlyPending}
                onChange={(e) => onChangePendingSearch(props, e)}
              />
            </div>
          </Col>
          <Col lg={3} md={3} sm={12} xs={12} className="p-3">
            <div className="p-fluid">
              <FieldDropdown
                name="ClaAgenteSalesManager"
                value={ClaAgenteSalesManager}
                options={OpcionesClaAgenteCom}
                optionValue="ClaAgente"
                optionLabel="NomAgente"
                label={t('salesPerformanceSales:Manager')}
                onChange={(e) => onClaAgenteSalesManagerComChange(props, e)}
                showClear={true}
              /> 
            </div>
          </Col>
          <Col lg={3} md={3} sm={12} xs={12} className="p-3">
            <div className="p-fluid">
              <FieldDropdown
                name="ClaDivision"
                value={ClaDivision}
                options={OpcionesClaDivision}
                optionValue="ClaDivision"
                optionLabel="NomDivision"
                label={t('salesPerformanceSales:Sales')}
                onChange={(e) => onClaDivisionComChange(props, e)}
                showClear={true}
              /> 
            </div> 
          </Col>
          <Col lg={3} md={3} sm={12} xs={12} className="p-3">
            <div className="p-fluid">
              <FieldDropdown
                name="ClaAgenteSalesRep"
                value={ClaAgenteSalesRep}
                options={OpcionesClaAgenteCom}
                optionValue="ClaAgente"
                optionLabel="NomAgente"
                label={t('salesPerformanceSales:AgenteCompesation')}
                onChange={(e) => onClaAgenteSalesRepComChange(props, e)}
                showClear={true}
              /> 
            </div> 
          </Col>
        </Row>
      </Panel>
    </>
  );
};
export default SalesPerformanceSalesRepManagementFilter;

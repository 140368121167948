import React from "react";
import PageContent from "layouts/PageContent";
import {Row, Col} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PagerOnBreak from "./PagerOnBreak.jsx";

const PagerOnBreakScreen = (props) => {
  const {t, i18n} = useTranslation(['operations', 'common']);

  return (
    <PageContent title={t('operations:PagerOnBreakTitle')}>
      <Row>
        <Col>
          <PagerOnBreak />
        </Col>
      </Row>
    </PageContent>
  )

}

export default PagerOnBreakScreen;
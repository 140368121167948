/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef } from 'react';  
import PropTypes from "prop-types"; 
import FieldButton from '../FieldButton/FieldButton.jsx';
import { FileUpload } from 'primereact/fileupload';
import { Row,Col} from 'reactstrap';   
import { config } from '../../../utils/config';
import { callApi,showSweetAlert } from '../../../utils/utils'; 
import { Tag } from 'primereact/tag';
import { useTranslation } from 'react-i18next'; 
import Highcharts from 'highcharts';
import { ProgressBar } from 'primereact/progressbar';

const FieldUpload = ({  
  onChange,
  files,
  name,
  errors,
  accept = 'image/*',
  uploadPrompt = '',
  maxFileSize,
  disabled = false
}) => {

  const { t } = useTranslation(['scoreCard', 'common']);
  const [Files, setFiles] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);

  const uploadPromptText = uploadPrompt === '' ? t('scoreCard:DragDrop') : uploadPrompt;
  
  useEffect(() => {     
    setFiles(files);
  }, [files]); 

 const onUpload = async (e) => {
    const info = JSON.parse( e.xhr.response );
    if (info.data.HasError){
      console.log(info.data.Message);
      return;
    }
    info.data.objectURL = e.files[0].objectURL;
    setFiles([...[], info.data]);
    if (typeof(onChange) !== 'undefined' && typeof(onChange) ==='function' && onChange !== null){
        onChange([...Files, info.data]);
    }
  }

  const onClear = () => {
    setFiles([]);
    if (typeof(onChange) !== 'undefined' && typeof(onChange) ==='function' && onChange !== null){
        onChange([]);
    }
  }

  const isFormFieldInValid = (name) => !!(typeof(errors) !== 'undefined' && errors !== null && errors[name]);

  const emptyTemplate = () => {
    console.log('length', Files.length);

    if ( Files.length > 0 )
    {
        return (    
          <div>
            <Row className="p-my-5">
              <Col>
                <Row>
                  <Col>
                    <div className="p-d-flex p-ai-center" style={{width: '40%'}}>
                      {
                        typeFile(Files[0].FileExtension) === 'image' ?
                        (<img src={Files[0].objectURL} role="presentation" width={100} />)
                        :
                        typeFile(Files[0].FileExtension) === 'video' ?
                        (<i className="fas fa-video danger-color"></i>) 
                        :
                        typeFile(Files[0].FileExtension) === 'pdf' ?
                        (<i className="fas fa-file-pdf danger-color"></i>)
                        :
                        typeFile(Files[0].FileExtension) === 'excel' ?
                        (<i className="fas fa-file-excel success-color"></i>)
                        : 
                        typeFile(Files[0].FileExtension) === 'csv' ?
                        (<i className="fas fa-file-csv success-color"></i>)
                        : 
                        typeFile(Files[0].FileExtension) === 'word' ?
                        (<i className="fas fa-file-word light-blue-color"></i>) 
                        : 
                        typeFile(Files[0].FileExtension) === 'zip' ?
                        (<i className="fas fa-file-archive secondary-color"></i>)
                        : 
                        (<i className="fas fa-archive secondary-color"></i>)
                      } 
                      <span className="p-d-flex p-dir-col p-text-left p-ml-3">
                        {Files[0].FileName}
                      </span>
                    </div>
                  </Col>
                  <Col>
                    <Tag value={`${ Highcharts.numberFormat(Files[0].FileSize*.001,0, '.', ',') } KB`} severity="warning" className="p-px-3 p-py-2" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
      );
    } 
    return (
      <div className="p-d-flex p-ai-center p-dir-col">
        <i className="pi pi-image" style={{'fontSize': '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'}}></i>
        <span style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}} className="p-my-2">{uploadPromptText}</span>
      </div>
    )
  } 

  const typeFile = (Extension) => {

        let type = null;

        switch (Extension) {
          case '.mp4':
          case '.webm':
            type = 'video'; 
            break;
          case '.pdf': 
              type = 'pdf'; 
              break;
          
          case '.xls': 
          case '.xlsx': 
              type = 'excel'; 
              break;
          case '.csv': 
              type = 'csv'; 
              break;
          case '.doc': 
          case '.docx': 
              type = 'word'; 
              break;
          case '.zip': 
          case '.rar': 
              type = 'zip'; 
              break;
          case '.jpg':
          case '.gif':
          case '.jpeg':
          case '.jfif':
          case '.pjpeg':
          case '.png':
          case '.svg':
          case '.webp':
          case '.bmp':
          case '.ico':
          case '.cur': 
                type = 'image'; 
                break;

          default:
            break;
        } 


    return type;
  };
  
  const headerTemplate = (options) => {
    const { className, chooseButton  } = options;
    const value = totalSize/10000;
    const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';
        

    return (
      <div className={`${className  } text-right `} style={{backgroundColor: 'transparent'}}>
        {
          Files.length === 0 ? (
            <>
              {chooseButton}
            </>
          ):(
            <>
              <FieldButton
                icon="pi pi-times" 
                onClick={onClear}
                className="p-button-rounded p-button-danger p-button-outlined"
              />
            </>
)
        } 
      </div>
    );
  }

const chooseOptions = {title: 'Subir Archivo', icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined'};
const uploadOptions = {icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined'};
const cancelOptions = {icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'};

const getFormErrorMessage = (name) => { 
  return isFormFieldInValid(name) && <small className="p-error">{errors[name].message}</small>;
};
 

  return (
    <>
      <FileUpload 
        ref={fileUploadRef}
        mode="advanced"
        name="file"
        url={`${config.UrlApiProject}Upload/Upload`}  
        accept={accept}
        emptyTemplate={emptyTemplate}
        headerTemplate={headerTemplate}
        auto
        maxFileSize={(typeof(maxFileSize) === 'undefined' || maxFileSize === null ) ? 7000000 : maxFileSize}
        onUpload={onUpload} 
        chooseOptions={chooseOptions}
        uploadOptions={uploadOptions}
        cancelOptions={cancelOptions}
        className={isFormFieldInValid(name) === true ? ' p-invalid-fileupload' :''}
        progressBarTemplate={(<ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>)}
        disabled={disabled}
      /> 
      { getFormErrorMessage(name) } 
    </>
  );
};
 

export default FieldUpload;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next';  
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row,Col} from 'reactstrap';   
import { config } from '../../../utils/config';
import { callApi, getSessionItem, showSweetAlert} from '../../../utils/utils'; 
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import { ProgressBar } from 'primereact/progressbar';
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon' 
import FieldText from 'components/Controls/FieldText';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx' 
import FieldDropdown from 'components/Controls/FieldDropdown.jsx' 
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import moment from "moment"; 
import swal from '@sweetalert/with-react';
import PageContent from 'layouts/PageContent';
import { Toolbar } from 'primereact/toolbar';
import FieldTextArea from 'components/Controls/FieldTextArea';
import FieldAutoComplete from '../../../components/Controls/FieldAutoComplete.jsx'
import FieldTime from 'components/Controls/FieldTime';
import { cssNumber } from 'jquery';
import FieldCheckbox from '../../../components/Controls/FieldCheckbox.jsx';
import { Dialog } from 'primereact/dialog';
import MinutasMeetingAttachment from './MinutasMeetingAttachment.jsx';

const MinutasMeeting = (props) => {
   /* ################## useState area start ################## */
   const { t } = useTranslation(['minutameeting', 'common']);  
   const [ClaProyecto, setClaProyecto] = useState(props.ClaProyecto === undefined ? 0 : props.ClaProyecto);    
   const [errors, setErrors] = useState({});  
   const [TipoMinuta, setTipoMinuta] = useState(null); 
   const [ClaTipoMinuta, setClaTipoMinuta] = useState(props.ClaTipoMinuta === undefined ? null : props.ClaTipoMinuta);   
   const [ClaProyectoNoMaestro, setClaProyectoNoMaestro] = useState(null); 
   const [ProyectoNoMaestro, setProyectoNoMaestro] = useState([]); 
   const [Objetivo, setObjetivo] = useState(null);
   const [Comentarios, setComentarios] = useState(null);
   const [ClaColaboradorResponsable, setClaColaboradorResponsable] = useState(null);
   const [ClaColaborador, setClaColaborador] = useState(null);
   const [OpcionesResponsables, setOpcionesResponsables] = useState(null);  
   const [filteredResponsible, setfilteredResponsible] = useState([]);
   const [filteredTypeMeetingProyect, setfilteredTypeMeetingProyect] = useState(0);
   const [EsProyecto, setEsProyecto] = useState(0);
   const [FechaReunion, setFechaReunion] = useState(null); 
   const [HoraReunion, setHoraReunion] = useState(null); 
   const [IdMinuta, setIdMinuta] = useState(props.IdMinuta === undefined ? null : props.IdMinuta);   
   const [Lugar, setLugar] = useState(null);
   const [ClaFechaReporte, setClaFechaReporte] = useState(null);
   const [ClaPeriodo, setClaPeriodo] = useState(null);
   const [ClaPuesto, setClaPuesto] = useState(null); 
   const [FechaReporte, setFechaReporte] = useState(null); 
   const [Periodo, setPeriodo] = useState(null); 
   const [Puesto, setPuesto] = useState(null); 
   const [EsTeamMeeting, setEsTeamMeeting] = useState(0);
   const usuarioConectado = getSessionItem('ClaUsuarioMod', 0);
   const NomUsuarioConectado = getSessionItem('NomUsuario', '');
   const [IsHideBackButton, setIsHideBackButton] = useState(false);
   const [Asunto, setAsunto] = useState(null);
    
   const [EsTieneTipoMinutaFija, setEsTieneTipoMinutaFija] = useState(false);
   const [EsTieneInfoPeriodoFija, setEsTieneInfoPeriodoFija] = useState(false);
   const [defaultResponsable, setDefaultResponsable] = useState(null);
   // Add Participant
   const [ParticipantsList, setParticipantsList] = useState([]);
   const [filteredResponsibleParticipants, setfilteredResponsibleParticipants] = useState([]);


   // End Participant

   // Acuerdos
  const [AcuerdoList, setAcuerdoList] = useState([]);
  const [OpcionesTipoPendienteMinutaAcuerdo, setOpcionesTipoPendienteMinutaAcuerdo] = useState({}); 
  const [OpcionesEstatusPendienteMinutaAcuerdo, setOpcionesEstatusPendienteMinutaAcuerdo] = useState({}); 
  const [filteredResponsibleAcuerdo, setfilteredResponsibleAcuerdo] = useState([]);
  const [EsMostrarSoloUsuarioMSW, setEsMostrarSoloUsuarioMSW] = useState(false);
  
   // End Acuerdos

   const [EsVerAnexo, setEsVerAnexo] = useState(false);
   const [MinutaFileList, setMinutaFileList] = useState([]);


   useEffect(() => {   
    LlenadoCombos(); 
    obtenerTipoPendienteMinutaAcuerdo();
    obtenerEstatusPendienteMinutaAcuerdo(); 
    
    
    if (IdMinuta !== null) {
      obtenerInformacionMinuta(IdMinuta);
    }
    else {      
      inicializaPantallaMinuta();
    }

    setIsHideBackButton(props.IsHideBackButton === undefined ? false : props.IsHideBackButton)
  }, [props.ClaProyecto, props.ClaTipoMinuta, props.IsHideBackButton]); 

  const inicializaPantallaMinuta = () => { 
    const GetMinutaDefaultValues = `${config.UrlApiProject}ScoreCard/GetMinutaDefaultValues`;  
    const paramsToService = { 
       ClaProyecto: props.ClaProyecto === undefined ? null : props.ClaProyecto
      ,ClaPeriodo: props.ClaPeriodo === undefined ? null : props.ClaPeriodo
      ,ClaTipoMinuta: props.ClaTipoMinuta === undefined ? null : props.ClaTipoMinuta
      ,ClaFechaReporte : props.ClaFechaReporte === undefined ? null : props.ClaFechaReporte 
      ,ClaPuesto: props.ClaPuesto === undefined ? null : props.ClaPuesto 
    };   

    callApi(GetMinutaDefaultValues, 'POST', paramsToService, (response) => {   
        const minutaDefaultValues = response.data.Minuta.length > 0 ? response.data.Minuta[0] : {};

        setClaTipoMinuta(minutaDefaultValues.ClaTipoMinuta === undefined ? null : minutaDefaultValues.ClaTipoMinuta);
        setClaPuesto(minutaDefaultValues.ClaPuesto === undefined ? null : minutaDefaultValues.ClaPuesto);
        setClaPeriodo(minutaDefaultValues.ClaPeriodo === undefined ? null : minutaDefaultValues.ClaPeriodo);
        setClaFechaReporte(minutaDefaultValues.ClaFechaReporte === undefined ? null : minutaDefaultValues.ClaFechaReporte); 
        
        if (minutaDefaultValues.ClaPeriodo !== undefined && minutaDefaultValues.ClaPeriodo !== null) {
          setEsTieneInfoPeriodoFija(true);
        } 
        
        obtenerFechaReporte(minutaDefaultValues.ClaPeriodo === undefined ? null : minutaDefaultValues.ClaPeriodo); 
        if (minutaDefaultValues.ClaTipoMinuta !== undefined && minutaDefaultValues.ClaTipoMinuta !== null){
          setEsTieneTipoMinutaFija(true);
        }
        else {
          setEsTieneTipoMinutaFija(false);
        }
        
        obtenerResponsablesProyecto(minutaDefaultValues.ClaProyecto === undefined ? 0 : minutaDefaultValues.ClaProyecto);
 
        setClaColaborador( 
          {
            ClaColaborador: minutaDefaultValues.ClaResponsableUsr,
            NomColaborador: minutaDefaultValues.NomResponsableUsr
          }
        );  

        setDefaultResponsable( {
          ClaColaborador: minutaDefaultValues.ClaResponsableUsr,
          NomColaborador: minutaDefaultValues.NomResponsableUsr
        })

        setClaColaboradorResponsable(minutaDefaultValues.ClaResponsableUsr);
        setClaProyectoNoMaestro(minutaDefaultValues.ClaProyecto);
        setFechaReunion(new Date());
        setHoraReunion(new Date());

        // agregamos por default a los participantes segun sea el caso 
        bindParticipantsList(response.data.MinutaParticipantesDefault); 
    });
  }

  const obtenerInformacionMinuta = (paramIdMinuta) => { 
    const GetMinutaMeeting = `${config.UrlApiProject}ScoreCard/GetMinutaMeeting?IdMinuta=${paramIdMinuta}`;  
    const paramsToService = { };   
    callApi(GetMinutaMeeting, 'GET', paramsToService, (response) => {   
      const minuta = response.data.Minuta.length >0 ? response.data.Minuta[0] : {};

      setClaTipoMinuta(minuta.ClaTipoMinuta);
      setClaFechaReporte(minuta.ClaFechaReporte);
      setClaPeriodo(minuta.ClaPeriodo);
      setClaPuesto(minuta.ClaPuesto);
      setClaProyectoNoMaestro(minuta.ClaProyecto); 
      setLugar(minuta.Lugar);
      setFechaReunion(minuta.FechaReunion);
      setHoraReunion(minuta.HoraReunion);
      setObjetivo(minuta.Objetivo);
      setClaColaboradorResponsable(minuta.ClaResponsable);
      setComentarios(minuta.Comentarios);
      setAsunto(minuta.Asunto);
      setClaColaborador( 
        {
          ClaColaborador: minuta.ClaResponsableUsr,
          NomColaborador: minuta.NomResponsableUsr
        }
      );  
      
      obtenerFechaReporte(minuta.ClaPeriodo === undefined ? null : minuta.ClaPeriodo); 
      obtenerResponsablesProyecto(minuta.ClaProyecto);      
      bindParticipantsList(response.data.MinutaParticipantes);
      bindAcuerdosList(response.data.MinutaPendientes);

      if (props.ClaPeriodo !== undefined && props.ClaPeriodo !== null) {
        setEsTieneInfoPeriodoFija(true);
      } 
      else {
        setEsTieneInfoPeriodoFija(false);
      }
     
      if (props.ClaTipoMinuta !== undefined && props.ClaTipoMinuta !== null){
        setEsTieneTipoMinutaFija(true);
      }
      else {
        setEsTieneTipoMinutaFija(false);
      }
      

    });
  }

  const bindParticipantsList = (listParticipant) => { 
    const arrayParticipants = [];
      let RowValues ={};
      for(let i=0; i < listParticipant.length; i++){
        RowValues ={     
          IdMinuta :  listParticipant[i].IdMinuta,
          ClaParticipante:  listParticipant[i].ClaUsuario,
          ClaColaborador: {
            ClaColaborador: listParticipant[i].ClaUsuario,
            NomColaborador: listParticipant[i].NomUsuario
          },
          NomColaborador: listParticipant[i].NomUsuario,
          BajaLogica: 0
      }
      arrayParticipants.push(RowValues);
    }
      setParticipantsList(arrayParticipants)
  }
  
  const bindAcuerdosList = (listAcuerdos) => { 
      const arrayAcuerdo = [];
      let RowValues ={};
      for(let i=0; i < listAcuerdos.length; i++){
        RowValues ={  
          RowIndex: i,
          IdMinuta:  listAcuerdos[i].IdMinuta,
          ClaResponsable: listAcuerdos[i].ClaUsuario,
          BajaLogica: 0,
          ClaColaborador: {
            ClaColaborador: listAcuerdos[i].ClaUsuario,
            NomColaborador: listAcuerdos[i].NomUsuario
          },          
          ClaEstatusPendienteMinuta: listAcuerdos[i].ClaEstatusPendienteMinuta,
          ClaTipoPendienteMinuta: listAcuerdos[i].ClaTipoPendienteMinuta,
          Comentarios: listAcuerdos[i].Comentarios,
          Descripcion: listAcuerdos[i].Descripcion,
          FechaCompromiso: listAcuerdos[i].FechaCompromiso,
          FechaEntrega: listAcuerdos[i].FechaEntrega,
          NomColaborador: listAcuerdos[i].NomUsuario,
          NomEstatusPendienteMinuta: listAcuerdos[i].NomEstatusPendienteMinuta,
          NomTipoPendienteMinuta: listAcuerdos[i].NomTipoPendienteMinuta,
          IdMinutaPendiente: listAcuerdos[i].IdMinutaPendiente
      }
      arrayAcuerdo.push(RowValues);
    }
  setAcuerdoList(arrayAcuerdo);
  }

  const closeDetailView = () => { 
    if (props.onCloseDetailView !== undefined){
      props.onCloseDetailView();
    }
  }

  const LlenadoCombos = () => {     
    obtenerTipoMinuta();    
    obtenerProyectos();
    obtenerPeriodo();
    // obtenerPeriodo();
    obtenerPuesto();
  }
  const obtenerProyectos = () => { 
     
    const GetProyectoCmb = `${config.UrlApiProject}ScoreCard/GetProyectoCmb?ClaTipoProyecto=-1&EsIncluirTodosSN=0`;
    const paramsToService = {};
    callApi(GetProyectoCmb, 'GET', paramsToService, (response) => { 
      setProyectoNoMaestro(response.data.Proyecto);
    });

  }

  const obtenerTipoMinuta = () => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/GetMswBSCTipoMinuta`;  
    const paramsToService = { 
      
    };   
    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => {  
        setTipoMinuta(response.data[0]);     
        setfilteredTypeMeetingProyect(ClaTipoMinuta);
        setEsProyecto(1);
        setClaColaboradorResponsable(null);   
    });
  }
  const obtenerFechaReporte = (claPeriodo) => { 

    if (claPeriodo === null || typeof(claPeriodo) === 'undefined'){
      claPeriodo = -1
    }

    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/GetMswBSCFechaReporte?ClaPeriodo=${claPeriodo}`;  
    const paramsToService = { 
      
    };   
    callApi(GetSummaryScorecardUser, 'GET', paramsToService, (response) => {  
        setFechaReporte(response.data[0]);   
    });
  }
  const obtenerPeriodo = () => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/GetMswBSCPeriodo`;  
    const paramsToService = { 
      
    }; 
    callApi(GetSummaryScorecardUser, 'GET', paramsToService, (response) => {   
        setPeriodo(response.data[0]);  
    });
  }
  const obtenerPuesto = () => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/GetMswBSCPuesto`;  
    const paramsToService = { 
      
    };   
    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => {  
        setPuesto(response.data[0]);  
    });
  }
  const filterResponsible = (event) => {
    let valor = ''; 
    if (ClaColaborador !== null && ClaColaborador !== '' &&  event.query === "")
    {
     valor = ClaColaborador.NomColaborador;
    }
    else{
     valor = event.query;
    }
    const paramsServiceOwners = {};
    // event.query  
    const urlWebServiceOwners = `${config.UrlApiProject}ScoreCard/GetColaboradorProyectosCmb?descriptionFilter=${valor}&EsMostrarSoloUsuarioMSW=${!EsMostrarSoloUsuarioMSW?1:0}`;
     
    // Get owner
    callApi(urlWebServiceOwners, 'GET', paramsServiceOwners, (response) => {  
       setfilteredResponsible(response.data[0]);
    });    
    
  }
  const filterResposibleNoProyect = () =>{
    const valor = ''; 
    const paramsServiceOwners = {};
    // event.query  
    const urlWebServiceOwners = `${config.UrlApiProject}ScoreCard/GetColaboradorProyectosCmb?descriptionFilter=${valor}&EsMostrarSoloUsuarioMSW=${!EsMostrarSoloUsuarioMSW?1:0}`;
     
    // Get owner
    callApi(urlWebServiceOwners, 'GET', paramsServiceOwners, (response) => {  
       setfilteredResponsible(response.data[0]);
    });
  }
  const obtenerResponsablesProyecto = (ClaProyecto) => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/GetMswBSCColaboradorProyectosTeam`;  
    const paramsToService = { 
      ClaProyecto
    };   
    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => {  
        setOpcionesResponsables(response.data[0]);
        // obtenerInformacionFasesProyecto();
        if (IdMinuta === null) {
          if (ClaTipoMinuta === 1){ 
            InicializaResponsableColaborador(response.data[0]);
          }
        } 
    });
  }

  const InicializaResponsableColaborador = (colaboradoresList) => {
    setClaColaboradorResponsable(null);

    for (let index = 0; index < colaboradoresList.length; index++) {
      const element = colaboradoresList[index];
      if (element.ClaColaborador === usuarioConectado){ 
        // antes de intentar asignarlo verificamos si exite en la lista de responsables
        setClaColaboradorResponsable(usuarioConectado);
      }
    }
  }

  const onChangeClaUsuarioResponsable = (e) => {  
    const newErrors = { ...errors }
    delete newErrors.ClaColaboradorResponsable;   
    setErrors(newErrors)
    setClaColaboradorResponsable(e.value)
 }
 const onChangeClaUsuarioClaColaborador = (e) => {  
  const newErrors = { ...errors }
  delete newErrors.ClaColaborador;   
  setErrors(newErrors)
  setClaColaborador(e.value)
}
  const onChangeObjetivo = (e) => {  
    const newErrors = { ...errors }
    delete newErrors.Objetivo;   
    setErrors(newErrors);
    setObjetivo(e.target.value);  
  }

  const onChangeAsunto = (e) => {  
    const newErrors = { ...errors }
    delete newErrors.Objetivo;   
    setErrors(newErrors);
    setAsunto(e.target.value);  
  }

  const onChangeLugar = (e) => {  
    const newErrors = { ...errors }
    delete newErrors.Lugar;   
    setErrors(newErrors);
    setLugar(e.target.value);  
  }
  const onChangeMeetingType = (e) => {  
    const newErrors = { ...errors }
    delete newErrors.ClaTipoMinuta;   
    setErrors(newErrors);
    setClaTipoMinuta(e.value);  
    if (e.value === 1) {      
      setfilteredTypeMeetingProyect(e.value);
      setEsProyecto(1); 
      InicializaResponsableColaborador(OpcionesResponsables); 
    }
    else{
      setfilteredTypeMeetingProyect(null);
      setEsProyecto(0);      
      setClaColaborador(defaultResponsable);
    }
    if (e.value === 2 || e.value === 3){
      setEsTeamMeeting(1);
    }else{
      setEsTeamMeeting(0);
    }
  }
  const onChangeProyectoNoMaestro = (e) => {  
    const newErrors = { ...errors }
    delete newErrors.ClaProyecto;
    setErrors(newErrors);
    setClaProyectoNoMaestro(e.value);  

    if (filteredTypeMeetingProyect === 1) {      
      obtenerResponsablesProyecto(e.value);
    }else {
      obtenerResponsablesProyecto(null);
    }   
  }
  const onChangeFechaReunion = (e) => {  
    setFechaReunion(e.target.value)
  }
  const onChangeHourDateFilter = (e) => {  
    setHoraReunion(e.target.value)
    console.log(e.target.value);
  }
  const onChangeFechaReporte = (e) => {  
    const newErrors = { ...errors }
    delete newErrors.ClaFechaReporte;   
    setErrors(newErrors);
    setClaFechaReporte(e.value);  
  }
  const onChangePeriodo = (e) => {  
    const newErrors = { ...errors }
    delete newErrors.ClaPeriodo;   
    setErrors(newErrors);
    setClaPeriodo(e.value);  
    setClaFechaReporte(null);
    obtenerFechaReporte(e.value);
  }
  const onChangePuesto = (e) => {  
    const newErrors = { ...errors }
    delete newErrors.ClaPuesto;   
    setErrors(newErrors);
    setClaPuesto(e.value);  
  }
  onReturnClick
  const onReturnClick = () => {
    closeDetailView(true);
  } 
const onSaveClick = async () => {  
   
  setErrors({});
     
  const value = {    
    ParticipantsList,
    AcuerdoList,
    ClaTipoMinuta,
    ClaProyecto: ClaProyectoNoMaestro,
    Lugar,
    ClaColaboradorResponsable,
    ClaColaborador ,
    ClaFechaReporte,
    ClaPeriodo,
    ClaPuesto,
    Asunto 
  };

 
  
  const validator = new YupValidator(valitationScheme);
  const esValido = await validator.validate(value);

  if (!esValido){   
    setErrors(validator.errors); 
    showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
   
    return;
  }
    
  
  const arrayParticipants = [];
  let RowValuesParticipants ={};

  for (let index = 0; index < ParticipantsList.length; index++) {
    RowValuesParticipants =  {
      BajaLogica: ParticipantsList[index].BajaLogica,
      ClaParticipante: ParticipantsList[index].ClaColaborador.ClaColaborador,
    }
    arrayParticipants.push(RowValuesParticipants);
  }

  const arrayMinutaPendiente = [];
  let RowValues ={};

  for (let index = 0; index < AcuerdoList.length; index++) {    
     RowValues = {
      IdMinutaPendiente: AcuerdoList[index].IdMinutaPendiente, 
      BajaLogica: AcuerdoList[index].BajaLogica,
      ClaResponsable: AcuerdoList[index].ClaColaborador.ClaColaborador,
      ClaTipoPendienteMinuta: AcuerdoList[index].ClaTipoPendienteMinuta,
      Descripcion: AcuerdoList[index].Descripcion,
      FechaCompromiso: (AcuerdoList[index].FechaCompromiso === null ? null : new Date(AcuerdoList[index].FechaCompromiso)),
      ClaEstatusPendienteMinuta : AcuerdoList[index].ClaEstatusPendienteMinuta,
      Comentarios : AcuerdoList[index].Comentarios,
      FechaEntrega : (AcuerdoList[index].FechaEntrega === null ? null : new Date(AcuerdoList[index].FechaEntrega))
    };
     
    arrayMinutaPendiente.push(RowValues);
    
  } 
  
   
  const paramsServiceOwners = {    
    IdMinuta,
    ClaTipoMinuta,
    ClaProyecto: ClaProyectoNoMaestro,
    ClaFechaReporte: ClaFechaReporte === undefined ? null: ClaFechaReporte,
    ClaPeriodo: ClaPeriodo === undefined ? null: ClaPeriodo,
    ClaPuesto : ClaPuesto === undefined ? null: ClaPuesto,
    Lugar,
    FechaReunion,
    HoraReunion,
    Objetivo,
    ClaResponsable : ClaColaboradorResponsable,
    ClaResponsableUsr: ClaColaborador !== null ? ClaColaborador.ClaColaborador : null,
    Comentarios,
    Asunto,
    MinutaParticipante: arrayParticipants,
    MinutaPendiente: arrayMinutaPendiente
  }; 

  
  const urlWebServiceSaveMinutasMeeeting = `${config.UrlApiProject}ScoreCard/SaveMinutasMeeeting`; 
 
  callApi(urlWebServiceSaveMinutasMeeeting, 'POST', paramsServiceOwners, (response) => {
    showSweetAlert('success', t('common:SuccessOperation'), 'success');
      closeDetailView(true);
  });  
   
}
  const valitationScheme = yup.object().shape({
    ParticipantsList:yup.array().of(
      yup.object().shape({  
        ClaColaborador: yup.object().when('BajaLogica', {is: 0,
          then: yup.object({ ClaColaborador: yup.string().required(t('common:RequiredField')) }).required(t('common:RequiredField')).nullable(),
          otherwise: yup.object().nullable()
        })   
    })),
    AcuerdoList: yup.array().of(
      yup.object().shape({

        ClaTipoPendienteMinuta: yup.string().when('BajaLogica', {is: 0,
          then: yup.string().trim().required(t('common:RequiredField')).nullable(),
          otherwise: yup.string().nullable()
        }),
        
        Descripcion: yup.string().when('BajaLogica', {is: 0,
          then: yup.string().trim().required(t('common:RequiredField')).nullable(),
          otherwise: yup.string().nullable()
        }),
        
        FechaCompromiso: yup.string().when('BajaLogica', {is: 0,
          then: yup.string().trim().required(t('common:RequiredField')).nullable(),
          otherwise: yup.string().nullable()
        }), 

        ClaColaborador: yup.object().when('BajaLogica', {is: 0,
          then: yup.object({ ClaColaborador: yup.string().required(t('common:RequiredField')) }).required(t('common:RequiredField')).nullable(),
          otherwise: yup.object().nullable()
        }),
        
        ClaEstatusPendienteMinuta: yup.string().when('BajaLogica', {is: 0,
          then: yup.string().trim().required(t('common:RequiredField')).nullable(),
          otherwise: yup.string().nullable()
        }) 
    })),
    ClaTipoMinuta: yup.mixed().required(t('common:RequiredField')).nullable(), 
    ClaProyecto: (ClaTipoMinuta === 1
      ? yup.mixed().required(t('common:RequiredField')).nullable()
      : yup.mixed().nullable()
      ),
    Lugar: yup.string().required(t('common:RequiredField')).nullable(),     
    ClaColaboradorResponsable: (ClaTipoMinuta === 1
      ? yup.mixed().required(t('common:RequiredField')).nullable()
      : yup.mixed().nullable()
      ),
    ClaColaborador: (ClaTipoMinuta !== 1
        ? yup.object({ ClaColaborador: yup.string().required(t('common:RequiredField')) }).required(t('common:RequiredField')).nullable()
        : yup.object().nullable()
        ),
    ClaFechaReporte:   (ClaTipoMinuta ===2 || ClaTipoMinuta === 3
    ? yup.mixed().required(t('common:RequiredField')).nullable()
    : yup.mixed().nullable()
    ),
    ClaPeriodo: (ClaTipoMinuta ===2 || ClaTipoMinuta === 3
      ? yup.mixed().required(t('common:RequiredField')).nullable()
      : yup.mixed().nullable()
      ),
    ClaPuesto: (ClaTipoMinuta ===2 || ClaTipoMinuta === 3
      ? yup.mixed().required(t('common:RequiredField')).nullable()
      : yup.mixed().nullable()
      ),
      Asunto: (ClaTipoMinuta !== 1
        ? yup.string().required(t('common:RequiredField')).nullable()
        : yup.string().nullable()
        ),
  });

  /* Participants */
  const ResponsibleParticipantsEditor = ( props ) => {   
     

      if ( props.rowData.IdMinuta === null) { 
        return ( 
          <div className="p-fluid"> 
            <FieldAutoComplete
              dropdown
          // name="ClaColaboradorParticipants" 
              name="ClaColaborador" 
              value={props.rowData.ClaColaborador} 
              onChange={(e) => onChangeClaUsuarioResponsableParticipants(props,e)} 
              suggestions={filteredResponsibleParticipants}
              field="NomColaborador"
              completeMethod={(e) => filterResponsibleParticipants(props, e)}
              errors={errors[`ParticipantsList[${props.rowData.RowIndex}]`]}
            /> 
          </div>
        ) 
      }
      
      return   (<>{props.rowData.NomColaborador}</>)
      
    };
    const onNewParticipantsClick = () => { 
      const newRow = {
        ClaParticipante: null,
        ClaColaborador	 : null	,
        BajaLogica: 0,
        IdMinuta: null
      };
      setParticipantsList([...ParticipantsList, newRow]);
    }
  const onConfirmBajaParticipants = (row, props) => {    
      /* AQUI  */    
        // aqui lo borro de mi arreglo 
        const participantsListnew = [...ParticipantsList];

        if (participantsListnew[row.RowIndex].IdMinuta === null){
          participantsListnew.splice(row.RowIndex, 1);
        }
        else { 
          participantsListnew[row.RowIndex].BajaLogica = 1;
        } 
 
        setParticipantsList(participantsListnew);    
    }

  const onChangeClaUsuarioResponsableParticipants = (props, e) => {  
      const saveArray= getCurrentRowValuesParticipants(props);  
      const updatedProducts = [...ParticipantsList];    
      
      const newErrors = { ...errors }  
      const field = { ...newErrors[`ParticipantsList[${props.rowData.RowIndex}]`] };
      field.ClaColaborador = null;
      newErrors[`ParticipantsList[${props.rowData.RowIndex}]`] = field;
      delete newErrors[`ParticipantsList[${props.rowData.RowIndex}]`];
      setErrors(newErrors) ;

      if (e.value != null && typeof(e.value) === 'object'){
        const responsible = filteredResponsibleParticipants.filter(item => item.ClaColaborador ===e.value.ClaColaborador)[0];  
      
        if(responsible!==undefined){
          updatedProducts[props.rowData.RowIndex].NomColaborador = responsible.NomColaborador;
          updatedProducts[props.rowData.RowIndex].ClaParticipante = responsible.ClaColaborador;
        }
      }
      else{
        updatedProducts[props.rowData.RowIndex].NomColaborador = null;
      }
      updatedProducts[props.rowData.RowIndex].ClaColaborador = e.value;
      saveArray.ClaColaborador = e.value; 
      setParticipantsList(updatedProducts);    
   }
  const filterResponsibleParticipants = (props, event) => {
    let valor = ''; 
    const updatedProducts = [...ParticipantsList];
  
    if (updatedProducts[props.rowData.RowIndex].ClaColaborador !== null && updatedProducts[props.rowData.RowIndex].ClaColaborador !== '' &&  event.query === "")
    {
     valor = updatedProducts[props.rowData.RowIndex].ClaColaborador.NomColaborador;
    }
    else{
     valor = event.query;
    }
    const paramsServiceOwners = {};
    // event.query 
    const urlWebServiceOwners = `${config.UrlApiProject}ScoreCard/GetColaboradorProyectosCmb?descriptionFilter=${valor}&EsMostrarSoloUsuarioMSW=${!EsMostrarSoloUsuarioMSW?1:0}`;
     
    // Get owner
    callApi(urlWebServiceOwners, 'GET', paramsServiceOwners, (response) => {  
       setfilteredResponsibleParticipants(response.data[0]);
    });
  }
  const getCurrentRowValuesParticipants = (props) => {
    const updatedProducts = [...props.value];
    let RowValues ={}
  
    if (updatedProducts[props.rowIndex].ClaColaborador !== null){
      RowValues ={      
        ClaColaborador: {
          ClaColaborador: updatedProducts[props.rowIndex].ClaColaborador.ClaColaborador,
          NomColaborador: updatedProducts[props.rowIndex].ClaColaborador.NomColaborador
        }
    }
    }
    else {
      RowValues ={      
        ClaColaborador: null,      
    }
    }
     
    return RowValues;
  }
  const actionBodyTemplateParticipants = (rowData, itemProps) => {
    if (props.readOnly === true)
    {
      return (<></>);
    }
     
    return (
      <> 
        <FieldButton icon="pi pi-trash" onClick={() => onConfirmBajaParticipants(rowData,itemProps)} className="p-button-rounded p-button-warning" /> 
      </>
    );
  } 
  const headerParticipants =(
    <>
      <Row>
        <Col>
          {t('minutameeting:Participants')}
        </Col>
        <Col className="text-right">
          {
            props.readOnly === true ?
            (<></>)
            :
            (
              <FieldButton className="p-button p-button-icon-only p-button-info rounded-button" onClick={onNewParticipantsClick}>
                <i className="pi pi-plus"></i>
              </FieldButton>
            )
          }
         
        </Col>
      </Row>
        
    </>
      );
      const fieldsRequiredParticipants = (rowData, props) =>  {  
        return (
          <>
            {errors[`ParticipantsList[${props.rowData.RowIndex}]`] != null ? (<i className="fas fa-exclamation-triangle invalid-color"></i>) : ""}
          </>
        );
      }


    const showActiveRows = (rows) => {
        const activeRows = [];
        if (rows.length>0) {
          rows.forEach((item, index) => {
            if (item.BajaLogica === 0) {
              item.RowIndex = index;
              activeRows.push({...item});
            }
          });
        } else {
          return [];
        }
        return activeRows; 
    };

  /* End Participants */

  /* Acuerdo */

  const ClaTipoPendienteMinutaAcuerdo = ( props ) => {
    return (
      <div className="p-fluid">
        <FieldDropdown  
          name="ClaTipoPendienteMinuta"
          value={props.rowData.ClaTipoPendienteMinuta}
          options={OpcionesTipoPendienteMinutaAcuerdo}  
          optionValue="ClaTipoPendienteMinuta"
          optionLabel="NomTipoPendienteMinuta" 
          onChange={(e) => onTipoPendienteMinutaAcuerdoChange(props,e)}
          errors={errors[`AcuerdoList[${props.rowData.RowIndex}]`]}   
        />  
      </div>
    )
  };
  const DescripcionAcuerdoEditor = ( props ) => {   
    return (
      <div className="p-fluid">
        <FieldText
          name="Descripcion" 
          value={props.rowData.Descripcion} 
          onChange={(e) => onDescripcionAcuerdoChange(props, e.target.value)}    
          errors={errors[`AcuerdoList[${props.rowData.RowIndex}]`]}      
          maxLength={200}
        />
      </div>
      )
    };
  const ResponsibleAcuerdoEditor = ( props ) => {   
      return (
        <div className="p-fluid"> 
          <FieldAutoComplete
            dropdown
            name="ClaColaborador" 
            value={props.rowData.ClaColaborador} 
            onChange={(e) => onChangeClaUsuarioResponsableAcuerdo(props,e)} 
            suggestions={filteredResponsibleAcuerdo}
            field="NomColaborador"
            completeMethod={(e) => filterResponsibleAcuerdo(props, e)}
            errors={errors[`AcuerdoList[${props.rowData.RowIndex}]`]} 
          /> 
        </div> 
        )
      };
      
  const FechaCompromisoAcuerdoEditor = ( props ) => {   
        return (
          <div className="p-fluid">
            <FieldCalendar
              name="FechaCompromiso"              
              value={(props.rowData.FechaCompromiso!== null ? (new Date(props.rowData.FechaCompromiso)): null)}
              onChange={(e) => onChangeFechaCompromisoAcuerdo(props, e.target.value)}
              errors={errors[`AcuerdoList[${props.rowData.RowIndex}]`]} 
            />    
          </div>
          )
        };
  const ClaEstatusPendienteMinutaAcuerdoEditor = ( props ) => {
        return (
          <div className="p-fluid">
            <FieldDropdown  
              name="ClaEstatusPendienteMinuta"
              value={props.rowData.ClaEstatusPendienteMinuta}
              options={OpcionesEstatusPendienteMinutaAcuerdo}  
              optionValue="ClaEstatusPendienteMinuta"
              optionLabel="NomEstatusPendienteMinuta" 
              onChange={(e) => onEstatusPendienteMinutaAcuerdoChange(props,e)}
              errors={errors[`AcuerdoList[${props.rowData.RowIndex}]`]} 
            />  
          </div>
        )
      };
      
  const ComentariosAcuerdoEditor = ( props ) => {   
    return (
      <div className="p-fluid">
        <FieldText
          name="Comentarios" 
          value={props.rowData.Comentarios} 
          onChange={(e) => onComentariosAcuerdoChange(props, e.target.value)}  
          maxLength={200}        
        />
      </div>
      )
    };
  const FechaEntregaAcuerdoEditor = ( props ) => {   
    return (
      <div className="p-fluid">            
        <FieldCalendar
          name="FechaEntrega"
          value={(props.rowData.FechaEntrega!== null ? (new Date(props.rowData.FechaEntrega)): null)}
          onChange={(e) => onChangeFechaEntregaAcuerdo(props, e)}
        /> 
      </div>
      )
    };
  const FechaEntregaTemplateAcuerdo = (rowData) => {
    return (
      <>
        {(rowData.FechaEntrega !== null ? moment(rowData.FechaEntrega).format("MM/DD/YYYY") : null)}
      </>
    );
  }
  const FechaCompromisoTemplateAcuerdo = (rowData) => {
    return (
      <>
        {(rowData.FechaCompromiso !== null ? moment(rowData.FechaCompromiso).format("MM/DD/YYYY") : null)}
      </>
    );
  }   
  
  const ComentariosAcuerdoTemplate = (rowData) => {
    return (
      <> 
        <div className='overflow-ellipsis' title={rowData.Comentarios}>{rowData.Comentarios}</div> 
      </>
    );
  }

  const DescripcionAcuerdoTemplate = (rowData) => {
    return (
      <> 
        <div className='overflow-ellipsis' title={rowData.Descripcion}>{rowData.Descripcion}</div> 
      </>
    );
  }

  const obtenerTipoPendienteMinutaAcuerdo = () => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/GetMswBSCTipoPendienteMinuta`;  
    const paramsToService = { 
    };   
    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => { 
      if (response.data[0].length >0)
      {  
        setOpcionesTipoPendienteMinutaAcuerdo(response.data[0]);
      }  
    });
  }
  const obtenerEstatusPendienteMinutaAcuerdo = () => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/GetMswBSCEstatusPendienteMinuta`;  
    const paramsToService = { 
    };   
    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => { 
      if (response.data[0].length >0)
      {  
        setOpcionesEstatusPendienteMinutaAcuerdo(response.data[0]);
      }  
    });
  }
  const onTipoPendienteMinutaAcuerdoChange = (props, e ) => {
    const updatedProducts = [...AcuerdoList];
    const tipominutapendiente = OpcionesTipoPendienteMinutaAcuerdo.filter(item => item.ClaTipoPendienteMinuta ===e.value)[0];
    if(tipominutapendiente!==undefined){
      updatedProducts[props.rowData.RowIndex].NomTipoPendienteMinuta = tipominutapendiente.NomTipoPendienteMinuta;
    }
    updatedProducts[props.rowData.RowIndex].ClaTipoPendienteMinuta = e.value;
    setAcuerdoList(updatedProducts);
  };
  const onDescripcionAcuerdoChange = (props, value) => {   
    const updatedDescripcion = [...AcuerdoList];
    updatedDescripcion[props.rowData.RowIndex][props.field] = value;
    
    setAcuerdoList(updatedDescripcion);  
  }; 
  const onChangeClaUsuarioResponsableAcuerdo = (props, e) => { 
    
    const newErrors = { ...errors }  
    const field = { ...newErrors[`AcuerdoList[${props.rowData.RowIndex}]`] };
    field.ClaColaborador = null;
    newErrors[`AcuerdoList[${props.rowData.RowIndex}]`] = field;
    delete newErrors[`AcuerdoList[${props.rowData.RowIndex}]`];
    setErrors(newErrors) ;

    const saveArray= getCurrentAcuerdoRowValues(props);  
    const updatedProducts = [...AcuerdoList];  

    if (e.value != null && typeof(e.value) === 'object'){
      const responsible = filteredResponsibleAcuerdo.filter(item => item.ClaColaborador ===e.value.ClaColaborador)[0];  
    
      if(responsible!==undefined){
        updatedProducts[props.rowData.RowIndex].NomColaborador = responsible.NomColaborador;
        updatedProducts[props.rowData.RowIndex].ClaResponsable = responsible.ClaColaborador;
      }
    }
    else{
      updatedProducts[props.rowData.RowIndex].NomColaborador = null;
    }
    
     
    updatedProducts[props.rowData.RowIndex].ClaColaborador = e.value;
    saveArray.ClaColaborador = e.value; 
    setAcuerdoList(updatedProducts);
    
 }

 const onEstatusPendienteMinutaAcuerdoChange = (props, e ) => {
  const saveArray= getCurrentAcuerdoRowValues(props);
  const updatedProducts = [...AcuerdoList];
  const estatusminutapendiente = OpcionesEstatusPendienteMinutaAcuerdo.filter(item => item.ClaEstatusPendienteMinuta ===e.value)[0];
  if(estatusminutapendiente!==undefined){
    updatedProducts[props.rowData.RowIndex].NomEstatusPendienteMinuta = estatusminutapendiente.NomEstatusPendienteMinuta;
  }
  updatedProducts[props.rowData.RowIndex].ClaEstatusPendienteMinuta = e.value;
  saveArray.ClaEstatusPendienteMinuta = e.value; 
  setAcuerdoList(updatedProducts);
};

const onComentariosAcuerdoChange = (props, value) => {   
  const updatedComentarios = [...AcuerdoList];
  updatedComentarios[props.rowData.RowIndex][props.field] = value;
  
  setAcuerdoList(updatedComentarios);  
}; 
const onChangeFechaEntregaAcuerdo = (props, e) => {  
    const updatedProducts = [...AcuerdoList];
    const saveArray= getCurrentAcuerdoRowValues(props); 
    saveArray.FechaEntrega = new Date(e.target.value)
    updatedProducts[props.rowData.RowIndex].FechaEntrega = e.target.value;
    setAcuerdoList(updatedProducts); 
} 
const onChangeFechaCompromisoAcuerdo = (props, value) => {  
  const updatedProducts = [...AcuerdoList];
  const saveArray= getCurrentAcuerdoRowValues(props); 
  saveArray.FechaCompromiso = new Date(value)
  updatedProducts[props.rowData.RowIndex].FechaCompromiso = value;
  setAcuerdoList(updatedProducts); 
}

const filterResponsibleAcuerdo = (props, event) => {
    let valor = ''; 
    const updatedProducts = [...AcuerdoList];

    if (updatedProducts[props.rowData.RowIndex].ClaColaborador !== null && updatedProducts[props.rowData.RowIndex].ClaColaborador !== '' &&  event.query === "")
    {
     valor = updatedProducts[props.rowData.RowIndex].ClaColaborador.NomColaborador;
    }
    else{
     valor = event.query;
    }
    const paramsServiceOwners = {};
    // event.query  
    const urlWebServiceOwners = `${config.UrlApiProject}ScoreCard/GetColaboradorProyectosCmb?descriptionFilter=${valor}&EsMostrarSoloUsuarioMSW=${!EsMostrarSoloUsuarioMSW?1:0}`;
     
    // Get owner
    callApi(urlWebServiceOwners, 'GET', paramsServiceOwners, (response) => {  
       setfilteredResponsibleAcuerdo(response.data[0]);
    });    
    
  }

const onConfirmBajaAcuerdo = (Acuerdo, props) => {    
    const AcuerdoListnew = [...AcuerdoList];
    // AcuerdoListnew.splice(props.rowIndex, 1);
    if (AcuerdoListnew[Acuerdo.RowIndex].IdMinuta === null){
      AcuerdoListnew.splice(Acuerdo.RowIndex, 1);
    }
    else { 
      AcuerdoListnew[Acuerdo.RowIndex].BajaLogica = 1;
    }  
    setAcuerdoList(AcuerdoListnew);
  }

const onNewAcuerdoClick = () => { 
    const newRow = {        
      RowIndex:  AcuerdoList.length,
      ClaResponsable: null,
      ClaTipoPendienteMinuta	 : 1,
      Descripcion	 : null,
      ClaColaborador : null,
      FechaCompromiso: null,
      ClaEstatusPendienteMinuta: 1,
      NomEstatusPendienteMinuta: "",
      Comentarios: "",
      NomTipoPendienteMinuta:"",
      FechaEntrega: null,
      BajaLogica: 0,
      IdMinuta: null
    };
    const updatedProducts = [...AcuerdoList];
    updatedProducts.push(newRow);

    const tipominutapendiente = OpcionesTipoPendienteMinutaAcuerdo.filter(item => item.ClaTipoPendienteMinuta ===newRow.ClaTipoPendienteMinuta)[0];
    if(tipominutapendiente!==undefined){
      updatedProducts[newRow.RowIndex].NomTipoPendienteMinuta = tipominutapendiente.NomTipoPendienteMinuta;
    }

    const estatusminutapendiente = OpcionesEstatusPendienteMinutaAcuerdo.filter(item => item.ClaEstatusPendienteMinuta ===newRow.ClaEstatusPendienteMinuta)[0];
    if(estatusminutapendiente!==undefined){
      updatedProducts[newRow.RowIndex].NomEstatusPendienteMinuta = estatusminutapendiente.NomEstatusPendienteMinuta;
    }    

    updatedProducts[newRow.RowIndex].ClaTipoPendienteMinuta = newRow.ClaTipoPendienteMinuta;
    updatedProducts[newRow.RowIndex].ClaEstatusPendienteMinuta = newRow.ClaEstatusPendienteMinuta;
    setAcuerdoList(updatedProducts);

  }
  
const getCurrentAcuerdoRowValues = (props) => {
    const updatedProducts = [...props.value];
    let RowValues ={}

    if (updatedProducts[props.rowIndex].ClaColaborador !== null){
      RowValues ={
        ClaTipoPendienteMinuta: updatedProducts[props.rowIndex].ClaTipoPendienteMinuta,
        Descripcion: updatedProducts[props.rowIndex].Descripcion,
        ClaColaborador: {
          ClaColaborador: updatedProducts[props.rowIndex].ClaColaborador.ClaColaborador,
          NomColaborador: updatedProducts[props.rowIndex].ClaColaborador.NomColaborador
        },
        FechaCompromiso: updatedProducts[props.rowIndex].FechaCompromiso,
        ClaEstatusPendienteMinuta: updatedProducts[props.rowIndex].ClaEstatusPendienteMinuta,        
        Comentarios: updatedProducts[props.rowIndex].Comentarios,
        FechaEntrega: updatedProducts[props.rowIndex].FechaEntrega
    }
    }
    else {
      RowValues ={
        ClaTipoPendienteMinuta: updatedProducts[props.rowIndex].ClaTipoPendienteMinuta,
        Descripcion: updatedProducts[props.rowIndex].Descripcion,
        ClaColaborador: null,
        FechaCompromiso: updatedProducts[props.rowIndex].FechaCompromiso,
        ClaEstatusPendienteMinuta: updatedProducts[props.rowIndex].ClaEstatusPendienteMinuta,        
        Comentarios: updatedProducts[props.rowIndex].Comentarios,
        FechaEntrega: updatedProducts[props.rowIndex].FechaEntrega
    }
    }
     
    return RowValues;
  }   

const actionBodyTemplateAcuerdo = (rowData, itemProps) => {
  
   if (props.readOnly === true)
   {
    return (<></>)
   }

    return (
      <> 
        <FieldButton icon="pi pi-trash" onClick={() => onConfirmBajaAcuerdo(rowData,itemProps)} className="p-button-rounded p-button-warning" /> 
      </>
    );
  } 

const headerAcuerdo =(
  <>
    <Row>
      <Col>
        {t('minutameeting:AcuerdoList')}
      </Col>
      <Col className="text-right">
        {
          props.readOnly === true ?
          (<></>)
          :
          (
            <FieldButton className="p-button p-button-icon-only p-button-info rounded-button" onClick={onNewAcuerdoClick}>
              <i className="pi pi-plus"></i>
            </FieldButton>
          )
        }
      </Col>
    </Row>        
  </>
      );
  const fieldsRequiredAcuerdo = (rowData, props) =>  {  
        return (
          <>
            {errors[`AcuerdoList[${props.rowData.RowIndex}]`] != null ? (<i className="fas fa-exclamation-triangle invalid-color"></i>) : ""}
          </>
        );
      }
  /* End Acuerdos */ 
  
  const onChangeIncluirSoloUsuariosMSW = (props, e) => {
    setEsMostrarSoloUsuarioMSW(e.checked ? 1 : 0); 
  };

   const closeModal = () => {
    setEsVerAnexo(false);
  };

  const AbrirAnexo = () => {
    setEsVerAnexo(!EsVerAnexo);
  };

  const dialogStyle = {
    width: '80vw',
  };

  return (
    <>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12} className="text-right">             
          <FieldButton hidden={IsHideBackButton} label={t('common:Back')} icon="pi pi-arrow-left" className="p-button-secondary no-border p-mr-2 rounded-button" onClick={onReturnClick} />
      &nbsp;
          {
            props.readOnly === true ?
            (<></>)
            :
            (
              <FieldButton label={t('common:Save')} icon="pi pi-save" className="p-button-success no-border p-mr-2 rounded-button" onClick={onSaveClick} /> 
            )
          }
          &nbsp;
          {
            props.readOnly === true ?
            (<></>)
            :
            (
              <FieldButton
                label={t('minutameeting:SupportFiles')}
                icon="pi pi-paperclip"
                className="light-blue no-border rounded-button font-weight-bold"
                onClick={() => AbrirAnexo()}
                hidden={IdMinuta === null}
              >
              </FieldButton>
          )
        }
        </Col> 
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12} className="text-right">   
          
        </Col> 
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}> 
          &nbsp;
        </Col> 
      </Row>
      <Row className="align-items-start">
        <Col lg={7} md={7} sm={12} xs={12}> 
          <Row hidden={IdMinuta === null}>
            <Col lg={2} md={2} sm={12} xs={12}> 
              <div className="p-fluid">
                <FieldText   
                  name="IdMinuta"
                  label={t('minutameeting:ID')}
                  value={IdMinuta} 
                  onChange={(e) => setIdMinuta(e.target.value)}
                  disabled={true}
                />  
              </div>
            </Col> 
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
          &nbsp;
            </Col> 
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>   
              <Row>
                <Col lg={8} md={8} sm={12} xs={12}>
                  <div className="p-fluid">
                    <FieldDropdown  
                      name="ClaTipoMinuta" 
                      label={t('minutameeting:MeetingType')}
                      value={ClaTipoMinuta} 
                      options={TipoMinuta}  
                      optionValue="ClaTipoMinuta"
                      optionLabel="NomTipoMinuta" 
                      onChange={(e) => onChangeMeetingType(e)}
                      disabled={EsTieneTipoMinutaFija}
                      errors={errors}
                    />  
                  </div>
                </Col>
                <Col lg={4} md={4} sm={12} xs={12}>
                  <div className="p-fluid">
                    <span className="mr-2">{t('misProyectosScreen:EsMostrarSoloUsuarioMSW')}</span>
                    <FieldCheckbox
                      inputId="EsMostrarSoloUsuarioMSW"
                      checked={EsMostrarSoloUsuarioMSW===1}
                      onChange={(e) => onChangeIncluirSoloUsuariosMSW(props, e)}
                    />
                  </div>
                </Col>
              </Row>
             
              
            </Col> 
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
          &nbsp;
            </Col> 
          </Row>
          {
            ClaTipoMinuta === 2 || ClaTipoMinuta === 3 ?
            (
              <>
                <Row>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <Row>
                      <Col lg={6} md={6} sm={12} xs={12}>   
                        <div className="p-fluid">
                          <FieldDropdown  
                            name="ClaPeriodo" 
                            label={t('minutameeting:Period')}
                            value={ClaPeriodo} 
                            options={Periodo}  
                            optionValue="ClaPeriodo"
                            optionLabel="NomPeriodo" 
                            onChange={(e) => onChangePeriodo(e)}
                            errors={errors}
                            disabled={EsTieneInfoPeriodoFija}
                          />  
                     
                        </div>
                      </Col> 
                      <Col lg={6} md={6} sm={12} xs={12}>   
                        <div className="p-fluid">
                          <FieldDropdown  
                            name="ClaFechaReporte" 
                            label={t('minutameeting:StatusDate')}
                            value={ClaFechaReporte} 
                            options={FechaReporte}  
                            optionValue="ClaFechaReporte"
                            optionLabel="NomFechaReporte" 
                            onChange={(e) => onChangeFechaReporte(e)}
                            errors={errors}
                            disabled={EsTieneInfoPeriodoFija}
                          />  
                        </div>
                      </Col> 
                    </Row>
                  </Col>
                  
                  <Col lg={6} md={6} sm={12} xs={12}>   
                    <div className="p-fluid">
                      <FieldDropdown  
                        name="ClaPuesto" 
                        label={t('minutameeting:Position')}
                        value={ClaPuesto} 
                        options={Puesto}  
                        optionValue="ClaPuesto"
                        optionLabel="NomPuesto" 
                        onChange={(e) => onChangePuesto(e)}
                        errors={errors}
                        disabled={EsTieneInfoPeriodoFija}
                      />  
                    </div>
                  </Col> 
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}> 
                  &nbsp;
                  </Col> 
                </Row>
              </>
            ): (<></>) 
          } 
          {
            ClaTipoMinuta === 1 ?
            (
              <>                
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>   
                    <div className="p-fluid">
                      <FieldDropdown  
                        name="ClaProyecto" 
                        label={t('minutameeting:Project')}
                        value={ClaProyectoNoMaestro} 
                        options={ProyectoNoMaestro}  
                        optionValue="ClaProyecto"
                        optionLabel="NomProyecto" 
                        onChange={(e) => onChangeProyectoNoMaestro(e)}
                        disabled={EsTieneTipoMinutaFija}
                        errors={errors}
                      />  
                    </div>
                  </Col> 
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}> 
                    &nbsp;
                  </Col> 
                </Row>
              </>
            ) : (<></>) 
          }     
          <Row>
            <Col lg={6} md={6} sm={12} xs={12}>   
              <div className="p-fluid">
                <FieldText   
                  name="Lugar"
                  value={Lugar}
                  maxLength={200}
                  label={t('minutameeting:Location')}
                  onChange={(e) => onChangeLugar(e)}
                  errors={errors}
                />  
              </div>
            </Col> 
            <Col lg={4} md={4} sm={12} xs={12}>             
              <div className="p-fluid">
                <FieldCalendar 
                  label={t('minutameeting:MeetingDate')}
                  name="MeetingDate"
                  value={(FechaReunion!== null ? (new Date(FechaReunion)): new Date())}
                  onChange={onChangeFechaReunion}
                />   
              </div>
            </Col> 
            <Col lg={2} md={2} sm={12} xs={12}> 
              <div className="p-fluid">
                <FieldTime
                  name="Hora"
                  label={t('minutameeting:Hora')}
                  value={(HoraReunion!== null ? (new Date(HoraReunion)): new Date())}
                  onChange={onChangeHourDateFilter}
                />   
              </div>
            </Col>
          </Row> 
          {
            ClaTipoMinuta !==  1 ?
            (
              <>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}> 
                    &nbsp;
                  </Col> 
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}> 
                    <div className="p-fluid"> 
                      <FieldText 
                        name="Asunto"  
                        maxLength={200}
                        value={Asunto}
                        onChange={(e) => onChangeAsunto(e)}
                        label={t('minutameeting:Asunto')}
                        errors={errors}
                      /> 
                    </div> 
                  </Col> 
                </Row>
              </>
)
            :
            (<></>)
          }
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
              &nbsp;
            </Col> 
          </Row>
         
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
              <div className="p-fluid"> 
                <FieldTextArea 
                  name="Objetivo"
                  rows={5}
                  placeholder=''
                  maxLength={1500}
                  value={Objetivo}
                  onChange={(e) => onChangeObjetivo(e)}
                  label={t('minutameeting:Objective')}
                  errors={errors}
                /> 
              </div> 
            </Col> 
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
          &nbsp; &nbsp; &nbsp;
            </Col> 
          </Row>
          <Row>
            {
          ClaTipoMinuta === 1 ?
          (
            <>
              <Col lg={12} md={12} sm={12} xs={12}> 
                <div className="p-fluid">
                  <FieldDropdown  
                    name="ClaColaboradorResponsable" 
                    label={t('minutameeting:Responsible')}
                    value={ClaColaboradorResponsable} 
                    options={OpcionesResponsables}  
                    optionValue="ClaColaborador"
                    optionLabel="NomColaborador" 
                    onChange={(e) => onChangeClaUsuarioResponsable(e)}
                    errors={errors}
                  />  
                </div>
              </Col>
            </>
): (
  <>
    <Col lg={12} md={12} sm={12} xs={12}> 
      <div className="p-fluid"> 
        <FieldAutoComplete
          dropdown
          name="ClaColaborador"
          label={t('minutameeting:Responsible')}
          value={ClaColaborador} 
          onChange={onChangeClaUsuarioClaColaborador}  
          suggestions={filteredResponsible}
          field="NomColaborador"
          completeMethod={filterResponsible}
          errors={errors}
        /> 
      </div>           
    </Col>
  </>
)
}
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
          &nbsp;
            </Col> 
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
              <label>{t('minutameeting:Comments')}  </label> 
              <div className="p-fluid"> 
                <FieldTextArea 
                  name="Comentarios"
                  rows={5}
                  placeholder=''
                  maxLength={1500}
                  value={Comentarios}
                  onChange={(e) => setComentarios(e.target.value)}
                /> 
              </div>
            </Col>           
          </Row>
        </Col> 
        <Col lg={5} md={5} sm={12} xs={12}>   
          <FieldDataTable 
            value={showActiveRows(ParticipantsList)}
            scrollable 
            scrollHeight="330px" 
            className="editable-cells-table"  
            editMode="cell"
            selectionMode="single"
            header={headerParticipants}
          >
            <Column body={(rowData, props) => fieldsRequiredParticipants(rowData, props)} headerStyle={{ width: '20px' }}></Column>
            <Column body={(rowData, props) => actionBodyTemplateParticipants(rowData, props)} headerStyle={{ width: '20px' }}></Column>
            <Column field="NomColaborador" header={t('minutameeting:Responsible')} headerStyle={{ width: '220px' }} columnKey="ClaColaborador" editor={(props) => ResponsibleParticipantsEditor(props)}></Column>            
          </FieldDataTable>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <FieldDataTable 
            value={showActiveRows(AcuerdoList)}
            scrollable  
            className="editable-cells-table"  
            editMode="cell"
            selectionMode="single"
            header={headerAcuerdo}
          >       
            <Column body={(rowData, props) => fieldsRequiredAcuerdo(rowData, props)} headerStyle={{ width: '20px' }}></Column>
            <Column body={(rowData, props) => actionBodyTemplateAcuerdo(rowData, props)} headerStyle={{ width: '70px' }}></Column>
            <Column field="NomTipoPendienteMinuta" header={t('minutameeting:Type')} headerStyle={{ width: '120px' }} columnKey="ClaTipoPendienteMinuta" editor={(props) => ClaTipoPendienteMinutaAcuerdo(props)}></Column>
            <Column field="Descripcion" header={t('minutameeting:Description')} headerStyle={{ width: '280px' }} editor={(props) => DescripcionAcuerdoEditor(props)} body={DescripcionAcuerdoTemplate}></Column>
            <Column field="FechaCompromiso" header={t('minutameeting:DueDate')} headerStyle={{ width: '120px' }} body={FechaCompromisoTemplateAcuerdo} editor={(props) => FechaCompromisoAcuerdoEditor(props)}></Column>
            <Column field="NomColaborador" header={t('minutameeting:Responsible')} headerStyle={{ width: '280px' }} columnKey="ClaColaborador" editor={(props) => ResponsibleAcuerdoEditor(props)}></Column>
            <Column field="NomEstatusPendienteMinuta" header={t('minutameeting:Status')} headerStyle={{ width: '140px' }} columnKey="ClaEstatusPendienteMinuta" editor={(props) => ClaEstatusPendienteMinutaAcuerdoEditor(props)}></Column>
            <Column field="FechaEntrega" header={t('minutameeting:FinishedDate')} headerStyle={{ width: '120px' }} body={FechaEntregaTemplateAcuerdo} editor={(props) => FechaEntregaAcuerdoEditor(props)}></Column>
            <Column field="Comentarios" header={t('minutameeting:Remarks')} headerStyle={{ width: '350px' }} body={ComentariosAcuerdoTemplate} editor={(props) => ComentariosAcuerdoEditor(props)}></Column>
            
          </FieldDataTable>
        </Col>
      </Row>
      <Dialog
        visible={EsVerAnexo}
        header={t('minutameeting:MinutaDocuments')}
        style={dialogStyle}
        modal
        className="dialog-custom"
        onHide={closeModal}
      >
        <Row>
          <Col>
            <MinutasMeetingAttachment
              IsUpload={true}
              IdMinuta={IdMinuta}
            >
            </MinutasMeetingAttachment>
          </Col>
        </Row>
      </Dialog>
    </>
  );
};
export default MinutasMeeting;

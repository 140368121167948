/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next'; 
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row, Col } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi, showSweetAlert, tracker } from '../../../utils/utils';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import { ProgressBar } from 'primereact/progressbar'; 
import FieldText from 'components/Controls/FieldText'; 
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import moment from "moment"; 
import FieldTextArea from 'components/Controls/FieldTextArea'; 
import { Dialog } from 'primereact/dialog';
import FieldCheckbox from '../../../components/Controls/FieldCheckbox.jsx'

const MinutasList = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['minutaproyecto', 'minutameeting', 'common']);

  const [ClaProyecto, setClaProyecto] = useState(props.ClaProyecto === undefined ? 0 : props.ClaProyecto);
  const [ClaTipoMinuta, setClaTipoMinuta] = useState(props.ClaTipoMinuta === undefined ? null : props.ClaTipoMinuta);
  const [EsAdminScorecard, setEsAdminScorecard] = useState(0);
  const [errors, setErrors] = useState({});
  const [MinutaList, setMinutaList] = useState([]);
  const [EsModoConsulta, setEsModoConsulta] = useState(true);
  const [IdMinuta, setIdMinuta] = useState(null);
  const [openSendMail, setopenSendMail] = useState(false);
  const [ProjectDesc, setProjectDesc] = useState([]);
  const [Asunto, setAsunto] = useState([]);
  const [MeetingDay, setMeetingDay] = useState([]);
  const [ParticipantesMinutaList, setParticipantesMinutaList] = useState([]);
  const [CopiarA, setCopiarA] = useState([]);
  const [Mensaje, setMensaje] = useState([]);
  const [MinutaCorreo, setMinutaCorreo] = useState([]);
  const [MinutaSeleccionada, setMinutaSeleccionada] = useState([]);; 
  const [ClaTipoMinutaSendEmail, setClaTipoMinutaSendEmail] = useState(null);

  useEffect(() => {
    obtenerInformacionMinuta();
  }, []);

  const obtenerInformacionMinuta = () => {
    const GetMinutaList = `${config.UrlApiProject}ScoreCard/GetProyectoMinuta`;
    const paramsToService = {
      ClaProyecto,
      ClaTipoMinuta
    };
    callApi(GetMinutaList, 'POST', paramsToService, (response) => {
      setMinutaList(response.data.ProyectoMinuta);
      setErrors({});
    });
  }
  const obtenerParticipanes = (idMinutaParam) => {
    const GetParticipantesMinuta = `${config.UrlApiProject}ScoreCard/GetParticipantesMinuta?IdMinuta=${idMinutaParam}`;
    const paramsToService = {};
    callApi(GetParticipantesMinuta, 'GET', paramsToService, (response) => {
      setParticipantesMinutaList(response.data.ParticipantesMinuta);
    });
  }
  const obtenerInformacionMinutaCorreo = (idMinutaParam) => {
    console.log(idMinutaParam);
    const GetMinutasCorreo = `${config.UrlApiProject}ScoreCard/GetMinutasCorreo?IdMinuta=${idMinutaParam}`;
    const paramsToService = { };
    callApi(GetMinutasCorreo, 'GET', paramsToService, (response) => {
      if (response.data.Minutas.length > 0) {
        setProjectDesc(response.data.Minutas[0].ProyectoSeleccionado);
        setAsunto(response.data.Minutas[0].AsuntoSeleccionado);
        setMeetingDay(response.data.Minutas[0].FechaReunionModal);
        setCopiarA(response.data.Minutas[0].CopiarA);
        setMensaje(response.data.Minutas[0].MensajeModal);
        setClaTipoMinutaSendEmail(response.data.Minutas[0].ClaTipoMinuta);
      }
    });
  }
  const closeModal = () => {
    setopenSendMail(false);
  }

  const onOpenModal = (rowData) => {
    const newErrors = { ...errors } 
    delete newErrors.CopiarA;   
    setErrors(newErrors) 
    obtenerInformacionMinutaCorreo(rowData.IdMinuta);
    obtenerParticipanes(rowData.IdMinuta);
    setopenSendMail(true)
    setMinutaSeleccionada(rowData.IdMinuta);

    tracker.trackEvent({
      type: 'MinutasList', /* component name */
      action: 'OPEN_SEND_MEETING_BY_EMAIL'
    }) 
  }
  const onClickViewDetailMeeting = (rowData) => {
    setEsModoConsulta(false);
    setIdMinuta(rowData.IdMinuta);
    props.onClickViewDetailMeeting(rowData.IdMinuta);

    tracker.trackEvent({
      type: 'MinutasList', /* component name */
      action: 'VIEW_MEETING_DETAIL'
    }) 

  }
  const EsEnviarCorreoTemplate = (rowData, props) => {
    return (
      <>
        <FieldCheckbox
          inputId={rowData.EsEnviarCorreo}
          checked={rowData.EsEnviarCorreo}
          onChange={(e) => EsEnviarCorreoSelected(props, e)}
        />
      </>
    );
  }

  const EsEnviarCorreoSelected = (props, e) => {
    const newErrors = { ...errors } 
    delete newErrors.CopiarA;   
    setErrors(newErrors) 
    const participantesMinutaList = [...ParticipantesMinutaList];
    participantesMinutaList[props.rowIndex].EsEnviarCorreo = e.checked;
    setParticipantesMinutaList(participantesMinutaList);
  }

  const onChangeMensajeModal = (e) => {
    setMensaje(e.target.value);
  }
  const onChangeCopiarA = (e) => {
    const newErrors = { ...errors } 
    delete newErrors.CopiarA;   
    setErrors(newErrors) 
    setCopiarA(e.target.value);
  }
  const onSendClick = async () => {
    let partSel = false;
    for (let index = 0; index < ParticipantesMinutaList.length; index++) {
      const element = ParticipantesMinutaList[index].EsEnviarCorreo;
      if (element) {
          partSel = true;
      }
    }
    if (CopiarA === '' && !partSel) {
      const value = {
        CopiarA
      }
      const validator = new YupValidator(valitationSchemeAdd);
      const esValido = await validator.validate(value);
      if (!esValido){ 
        setErrors(validator.errors); 
        showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
        return;
      }
    }
    const paramsServiceOwners = {
      IdMinuta: MinutaSeleccionada,
      CopiarA,
      Asunto,
      Mensaje,
      MinutaParticipante: ParticipantesMinutaList
    };

    const urlWebServiceSaveMinutas = `${config.UrlApiProject}ScoreCard/SetEnviarMinuta`;
    
    tracker.trackEvent({
      type: 'MinutasList', /* component name */
      action: 'SEND_MEETING_BY_EMAIL'
    }) 

    callApi(urlWebServiceSaveMinutas, 'POST', paramsServiceOwners, (response) => {
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      setopenSendMail(false);
    });
    
  }
  const valitationSchemeAdd = yup.object().shape({
    CopiarA: yup.string().trim().required(t('common:RequiredField')).nullable()   
  });
  const leftToolbarTemplate = () => {
    return (
      <></>
    )
  }
  const FechaReunionEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldText
          name="FechaReunion"
          value={props.rowData.FechaReunion}
        />
      </div>
    )
  };
  const LugarEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldText
          name="Lugar"
          value={props.rowData.Lugar}
        />
      </div>
    )
  };
  const NomResponsableEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldText
          name="NomResponsable"
          value={props.rowData.NomResponsable}
        />
      </div>
    )
  };
  const TaskEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldText
          name="Task"
          value={props.rowData.Task}
        />
      </div>
    )
  };
  const OpenTaskEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldText
          name="OpenTask"
          value={props.rowData.OpenTask}
        />
      </div>
    )
  };
  const FechaReunion = (rowData) => {
    return (
      <>
        <div>
          {/* <small> { moment(rowData.FechaReunion).format("LL") } - { moment(rowData.FechaReunion).format("LL") }</small> */}
          {moment(rowData.FechaReunion).format("MM/DD/YYYY")}
        </div>
      </>
    );
  }
  const AccionesTemplate = (row) => {
    let className = '';
    const Porcentaje = (row.Task > 0 && row.OpenTask > 0 ? ((row.Task - row.OpenTask) / row.Task) : 1.0) * 100.0


    switch (true) {
      case Porcentaje < 50:
        className = "danger-color";
        break;
      case Porcentaje < 75:
        className = "orange-color";
        break;
      case Porcentaje < 99:
        className = "warning-color";
        break;
      case Porcentaje === 100:
        className = "success-color";
        break;
      default:
        className = "default-color";
    }
    return (
      <>
        {/* <div className="text-center">
                    <span>{row.OpenTask}</span> &nbsp;
                    <span className="bold">{t('hr:Of')}</span> &nbsp;
                    <span>{row.Task}</span>&nbsp;
                    <span className="bold">{t('hr:Activities')}</span> 
                  </div> */}
        <div>
          <ProgressBar className={className} value={Porcentaje.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}></ProgressBar>
        </div>
      </>
    );
  }
  const actionBodyTemplate = (rowData, props) => {
    return (
      <>
        <div>
          <FieldButton icon="pi pi-eye" className="p-button-rounded p-button-success" onClick={() => onClickViewDetailMeeting(rowData)} />
          &nbsp;
          <FieldButton icon="pi pi-envelope" className="p-button-rounded p-button-info" onClick={() => onOpenModal(rowData)} />
        </div>
      </>
    );
  }
  const modalFooter = () => {
    return (
      <>
        <FieldButton label={t('minutaproyecto:Send')} icon="fas fa-envelope" className="p-button-success no-border p-mr-2 rounded-button" onClick={() => onSendClick()} />
      </>
    );
  }

  return (
    <>
      <Dialog
        visible={openSendMail}
        style={{ width: '600px' }}
        footer={modalFooter}
        modal
        className="p-fluid p-grid"
        onHide={closeModal}
        contentStyle={{maxHeight: "540px", overflow:"auto"}}
      >
        <Col lg={12} md={12} sm={12} xs={12}>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
            </Col>
          </Row>
          <Row hidden={ClaTipoMinutaSendEmail !== 1}>
            <Col lg={12} md={12} sm={12} xs={12}>
              <div className="p-fluid">
                <FieldText
                  name="NomProyecto"
                  label={t('minutaproyecto:Project')}
                  value={ProjectDesc}
                  disabled={true}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <div className="p-fluid">
                <FieldText
                  name="Asunto"
                  label={t('minutaproyecto:Subject')}
                  value={Asunto}
                  disabled={true}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <div className="p-fluid">
                <FieldText
                  name="MeetingDay"
                  label={t('minutaproyecto:MeetingDay')}
                  value={moment(MeetingDay).format("MM/DD/YYYY")}
                  disabled={true}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <div className="p-fluid">
                <FieldText
                  name="CopiarA"
                  label={t('minutaproyecto:CopyTo')}
                  onChange={(e) => onChangeCopiarA(e)}
                  value={CopiarA}
                  errors={errors} 
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <small>{t('minutaproyecto:AddMail')}</small>
            </Col>
          </Row> 
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <div className="p-fluid">
                <FieldTextArea
                  name="Mensaje"
                  rows={3}
                  placeholder=''
                  maxLength={1500}
                  value={Mensaje}
                  onChange={(e) => onChangeMensajeModal(e)}
                  label={t('minutameeting:Message')}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
            </Col>
          </Row>
          <Row>
            <Col>
              <FieldDataTable
                value={ParticipantesMinutaList}
                scrollable
                scrollHeight="330px"
                className="editable-cells-table"
                editMode="cell"
                selectionMode="single"
              >
                <Column field="EsEnviarCorreo" columnKey="EsEnviarCorreo" body={(rowData, props) => EsEnviarCorreoTemplate(rowData, props)} headerStyle={{ width: '75px' }}></Column>
                <Column field="NomUsuario" header={t('minutaproyecto:Name')} columnKey="NomUsuario" headerStyle={{ width: '250px', textAlign: 'center' }}></Column>
              </FieldDataTable>
            </Col>
          </Row>
        </Col>
      </Dialog>
      <FieldDataTable
        value={MinutaList}
        scrollable
        scrollHeight="330px"
        className="editable-cells-table"
        editMode="cell"
        selectionMode="single"
      >
        <Column field="FechaReunion" header={t('minutaproyecto:MeetingDate')} columnKey="FechaReunion" body={FechaReunion} headerStyle={{ width: '120px' }}></Column>
        <Column field="Lugar" header={t('minutaproyecto:Location')} columnKey="Lugar" headerStyle={{ width: '250px' }}></Column>
        <Column field="NomResponsable" header={t('minutaproyecto:MeetingResponsible')} columnKey="NomResponsable" headerStyle={{ width: '250px' }}></Column>
        <Column field="Task" header={t('minutaproyecto:Task')} columnKey="Task" headerStyle={{ width: '120px' }}></Column>
        <Column field="OpenTask" header={t('minutaproyecto:OpenTask')} columnKey="OpenTask" headerStyle={{ width: '120px' }}></Column>
        <Column body={AccionesTemplate} style={{ textAlign: 'right' }} header={t('minutaproyecto:Progress')} headerStyle={{ width: '180px' }}></Column>
        <Column body={(rowData, props) => actionBodyTemplate(rowData, props)} headerStyle={{ width: '100px' }}></Column>

      </FieldDataTable>
    </>
  );

};
export default MinutasList;

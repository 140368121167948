/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from 'components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { Avatar } from 'primereact/avatar';
import { config } from '../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../utils/utils';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable.jsx';
import { ProgressBar } from 'primereact/progressbar';
import SweatAlertCustomIcon from 'components/SweatAlert/SweatAlertCustomIcon';
import FieldText from 'components/Controls/FieldText';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx';
import FieldDropdown from 'components/Controls/FieldDropdown.jsx';
import * as yup from 'yup';
import YupValidator from 'utils/YupValidator';
import moment from 'moment';
import swal from '@sweetalert/with-react';
import PageContent from 'layouts/PageContent';
import { Toolbar } from 'primereact/toolbar';
import FieldTextArea from 'components/Controls/FieldTextArea';
import FieldAutoComplete from 'components/Controls/FieldAutoComplete.jsx';
import FieldTime from 'components/Controls/FieldTime';
import { cssNumber } from 'jquery';
import FieldCheckbox from 'components/Controls/FieldCheckbox.jsx';
import FieldSelectButton from 'components/Controls/FieldSelectButton';
import { Dialog } from 'primereact/dialog';
import SurveyManagementContestar from '../Survey/SurveyManagement/SurveyManagementContestar';
import CultureSurveyApprovingFeedbackDetail from './CultureSurveyApprovingFeedbackDetail';
import InstructionsCard from '../../../components/Cards/InstructionsCard/InstructionsCard.jsx';

const CultureSurveyApprovingFeedback = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['survey', 'common']);
  const [EsReviewResponse, setEsReviewResponse] = useState(false);
  const [IdEncuesta, setIdEncuesta] = useState(null);
  const [IdEncuestaContestada, setIdEncuestaContestada] = useState(null);
  const [AprovingFeedBackList, setAprovingFeedBackList] = useState([]);
  const [ClaAnio, setClaAnio] = useState(null);
  const [ClaUsuario, setClaUsuario] = useState(null);
  const [OpcionesAnio, setOpcionesAnio] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const dt = useRef(null);

  useEffect(() => {
    setClaAnio(props.ClaAnio);
    setIdEncuesta(props.IdEncuesta);
    loadAprovingFeedBack(props.ClaAnio);
    console.log(props);
  }, [props.ClaAnio, props.IdEncuesta]);

  const loadAprovingFeedBack = (pAnio) => {
    const GetAprovingFeedBack = `${config.UrlApiProject}hr/GetAprovingFeedBack?Anio=${pAnio}`;
    const paramsToService = {};
    callApi(GetAprovingFeedBack, 'GET', paramsToService, (response) => {
      setAprovingFeedBackList(response.data.AprovingFeedback);
    });
  };

  const showActiveRows = (rows) => {
    const activeRows = [];
    if (rows !== undefined) {
      if (rows.length > 0) {
        rows.forEach((item, index) => {
          if (!item.BajaLogica || item.BajaLogica === 0 || item.BajaLogica === undefined) {
            item.BajaLogica = 0;
            item.RowIndex = index;
            item.RowIndexCount = index + 1;
            activeRows.push({ ...item });
          }
        });
      } else {
        return [];
      }
    }

    return activeRows;
  };

  const SurveyTemplate = (row) => {
    return (
      <>
        <FieldButton
          className="p-button-rounded p-button-success btn-min-width no-border"
          label={t('survey:ReviewResponses')}
          onClick={(e) => onClickStarSurvey(row)}
        />
      </>
    );
  };

  const CultureSurveySetUpReadyTemplate = (row) => {
    return (
      <>
        {row.EsPerformanceDevCultureFeedbackSetUp === 1 ? (
          <>
            <i className="fas fa-check-circle light-green-color"></i>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  const onClickStarSurvey = (row) => {
    setClaUsuario(row.ClaUsuario);
    setSelectedEmployee(row.NomUsuario);
    setEsReviewResponse(true);
  };

  const AccionesTemplate = (row) => {
    let className = '';
    const Porcentaje =
      100.0 -
      (row.TotalAContestar > 0 && row.ContestadasCompletas > 0
        ? (row.TotalAContestar - row.ContestadasCompletas) / row.TotalAContestar
        : 1.0) *
        100.0;

    switch (true) {
      case Porcentaje < 50:
        className = 'danger-color';
        break;
      case Porcentaje < 75:
        className = 'orange-color';
        break;
      case Porcentaje < 99:
        className = 'warning-color';
        break;
      case Porcentaje === 100:
        className = 'success-color';
        break;
      default:
        className = 'default-color';
    }
    return (
      <>
        <div>
          <ProgressBar
            className={className}
            value={Porcentaje.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          >
          </ProgressBar>
        </div>
      </>
    );
  };

  const ClosedDetail = () => {
    setEsReviewResponse(!EsReviewResponse);
    loadAprovingFeedBack(props.ClaAnio);
  };

  const renderHeader = () => {
    return (
      <div>
        <Row>
          <Col lg={4} md={4} sm={12} xs={12}>
            <span>{selectedEmployee}</span>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="text-center">
            {ClaAnio}
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="text-right">
            {t('hr:CultureSurveyApproveResponses')}
          </Col>
        </Row>
      </div>
    );
  };

  const onExportGrid = () => {
    dt.current.exportCSV();
  };

  const ExportExcelSurvey = () => { 
    const ExportarSurvey = `${config.UrlApiProject}HR/ExportarPerformanceDevSetUp`;        
    const paramsService = {
      IdEncuesta,
      IsCultureSurvey: 1
    };
    callApi(ExportarSurvey, 'POST', paramsService, (response) => {
      const linkSource = `data:application/${response.data.FileExtension};base64,${response.data.FileData}`;
      const downloadLink = document.createElement("a");
      const fileName = `${response.data.FileName}`;
      downloadLink.href = linkSource;
      downloadLink.download = `${fileName  }.${  response.data.FileExtension}`;
      downloadLink.click();
    });    
  };

  return (
    <>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12} className="pb-2">
          <InstructionsCard>
            <p>{t('survey:CultureSurveyApprovalResponsesInstructions')}.</p>
          </InstructionsCard>
        </Col>
      </Row>
      <Row className="pb-2">
        <Col>
          <>
            <div className="p-panel-header pr-3">
              <div>
                <span className="h2">{t('hr:EmployeeParticipationDet')}</span>
              </div>
              <div>
                <FieldButton
                  className="p-button p-button-rounded p-button-icon-only p-button-secondary"
                  onClick={onExportGrid}
                  title={t('common:Export')}
                >
                  <i className="pi pi-download"></i>
                </FieldButton>
                &nbsp;
                <FieldButton
                  label={t('myactivities:Export')}
                  icon="fa fa-file-excel"
                  // className="secondary no-border"
                  className="p-button p-button-rounded p-button-icon-only p-button-secondary"
                  onClick={ExportExcelSurvey}
                >
                </FieldButton>
              </div>
            </div>
          </>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <FieldDataTable
            value={showActiveRows(AprovingFeedBackList)}
            scrollable
            className="editable-cells-table"
            editMode="cell"
            selectionMode="single"
            header={null}
            paginator={true}
            refererence={dt}
          >
            <Column
              field="NomUsuario"
              header={t('survey:Name')}
              headerStyle={{ width: '270px' }}
              columnKey="NomUsuario"
            >
            </Column>
            <Column
              field="EsPerformanceDevCultureFeedbackSetUp"
              header={t('survey:CultureSurveySetUpReady')}
              columnKey="EsPerformanceDevCultureFeedbackSetUp"
              style={{ display: 'none' }}
              headerStyle={{ width: '200px', display: 'none' }}
            >
            </Column>
            <Column
              body={CultureSurveySetUpReadyTemplate}
              style={{ textAlign: 'center' }}
              header={t('survey:CultureSurveySetUpReady')}
              headerStyle={{ width: '110px', textAlign: 'center' }}
            >
            </Column>
            <Column
              field="TotalAContestar"
              header={t('survey:GivingFeedBack')}
              style={{ textAlign: 'center' }}
              headerStyle={{ width: '110px', textAlign: 'center' }}
              columnKey="TotalAContestar"
            >
            </Column>
            <Column
              field="ContestadasCompletas"
              header={t('survey:FeedBackCompleted')}
              style={{ textAlign: 'center' }}
              headerStyle={{ width: '110px', textAlign: 'center' }}
              columnKey="ContestadasCompletas"
            >
            </Column>
            <Column
              field="TotalAprobadasRH"
              header={t('survey:ApprovedByHR')}
              style={{ textAlign: 'center' }}
              headerStyle={{ width: '110px', textAlign: 'center' }}
              columnKey="TotalAprobadasRH"
            >
            </Column>
            <Column
              body={AccionesTemplate}
              style={{ textAlign: 'right' }}
              header={t('survey:Progress')}
              headerStyle={{ width: '120px' }}
            >
            </Column>
            <Column
              body={SurveyTemplate}
              className="text-center"
              header={null}
              headerStyle={{ width: '130px' }}
            >
            </Column>
          </FieldDataTable>
        </Col>
      </Row>

      {EsReviewResponse ? (
        <Dialog
          visible={EsReviewResponse}
          modal
          style={{ width: '100vw', height: '100vw' }}
          maximized={true}
          draggable={false}
          onHide={ClosedDetail}
          className="dialog-header-background-blue"
          header={renderHeader}
          blockScroll={true}
        >
          <CultureSurveyApprovingFeedbackDetail
            ClaAnio={ClaAnio}
            ClaUsuario={ClaUsuario}
            NomUsuarioRespondeEncuesta={selectedEmployee}
            ClosedDetail={ClosedDetail}
          >
          </CultureSurveyApprovingFeedbackDetail>
        </Dialog>
      ) : (
        <></>
      )}
    </>
  );
};
export default CultureSurveyApprovingFeedback;

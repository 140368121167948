/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';
import PropType from 'prop-types';
import { config } from 'utils/config';
import { callApi } from 'utils/utils';

const SurveyInsightsSummary = ({ IdEncuesta }) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['survey', 'common']);
  const [TotalRespuesta, setTotalRespuesta] = useState(null);
  const [CompletadoRate, setCompletadoRate] = useState(null);
  const [TiempoRespuesta, setTiempoRespuesta] = useState(null);
  useEffect(() => {
    loadSurveyInsightsSummary(IdEncuesta);
  }, [IdEncuesta]);

  const loadSurveyInsightsSummary = (pIdEncuesta) => {
    const GetSurveyInsightsSummary = `${config.UrlApiProject}hr/GetSurveyInsightsSummary?IdEncuesta=${pIdEncuesta}`;
    const paramsToService = {};
    callApi(GetSurveyInsightsSummary, 'GET', paramsToService, (response) => {
      
      const insightsSummary =
        response.data.InsightsSummary.length > 0 ? response.data.InsightsSummary[0] : {};        
      setTotalRespuesta(insightsSummary.TotalRespuesta);
      setCompletadoRate(insightsSummary.CompletadoRate);
      setTiempoRespuesta(insightsSummary.TiempoRespuesta);
    });
  };

  return (
    <>
      <Row className="align-items-start">
        <Col lg={4} md={4} sm={12} xs={12}>
          <Card body> 
            <CardTitle className="text-center survey-card-title">{t('survey:TotalResponses')}</CardTitle> 
            <CardBody className="text-center">                
              <span className="survey-card-result">
                {TotalRespuesta} 
              </span>
            </CardBody> 
          </Card>
        </Col>
        <Col lg={4} md={4} sm={12} xs={12}> 
          <Card body> 
            <CardTitle className="text-center survey-card-title">{t('survey:CompletionRate')}</CardTitle> 
            <CardBody className="text-center">                
              <span className="survey-card-result">
                {CompletadoRate} 
              </span>
            </CardBody>               
              
          </Card>
        </Col>
        <Col lg={4} md={4} sm={12} xs={12}> 
          <Card body> 
            <CardTitle className="text-center survey-card-title">{t('survey:TypicalTimeSpent')}</CardTitle> 
            <CardBody className="text-center">                
              <span className="survey-card-result">
                {TiempoRespuesta} 
              </span>
            </CardBody>        
          </Card>
        </Col>
      </Row>   
    </>
  );
};

SurveyInsightsSummary.prototype = {
  IdEncuesta: PropType.number.isRequired,
};
export default SurveyInsightsSummary;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Avatar } from 'primereact/avatar';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import TreeviewButton from '../../../components/Controls/TreeViewButton/TreeviewButton.jsx';
import { config } from '../../../utils/config';
import { callApi, getSessionItem } from '../../../utils/utils';
import "../../ScoreCard/Scorecard/OrganizationalPositionView.scss"; 

const OrganizationalSalesRepView = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);
 
  const [selectedUser, setSelectedUser] = useState(
    props.value !== undefined ? props.value : getSessionItem('ClaUsuarioMod', '')
  );
  const [expandedKeys, setExpandedKeys] = useState({});
  const [nodes, setNodes] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);

  const expandAll = (nodes) => {
    const _expandedKeys = {};
    for (const node of nodes) {
      expandNode(node, _expandedKeys);
      break;
    }

    setExpandedKeys(_expandedKeys);
  };

  const expandNode = (node, _expandedKeys) => {
    if (node.children && node.children.length) {
      _expandedKeys[node.key] = true;
    }
  };

  const onToggle = (event) => {
    setExpandedKeys(event.value);
  };

  useEffect(() => {
    const GetOrganizationalSalesRepByUser = `${config.UrlApiProject}ScoreCard/GetOrganizationalSalesRepByUser`;
    const paramsToService = {};

    callApi(GetOrganizationalSalesRepByUser, 'GET', paramsToService, (response) => {
      expandAll(response.data);
      setNodes(response.data);
      console.log('selectedUser',selectedUser);
    });
  }, []);

  const nodeTemplate = (node, options) => {
    return <span className={options.className}>{node.label}</span>;
  };
 
  let selectedNodeElement = null

  const readChildNode = (nodesArray, node) => { 
    nodesArray.map((element, key) => { 
      if (element.key === node ) {
        selectedNodeElement = element;  
        return element;
      }

      if (element.children.length > 0 ) 
      {  
        return readChildNode(element.children, node); 
      }
      
      return null;
    }); 


    return selectedNodeElement;
  }
  const onChange = (node) => {
    setSelectedUser(node);
    
    
    // busco en mis nodos con el id para obtener el elemento y setear el valor de imgUrl
    selectedNodeElement = null
    const item = readChildNode(nodes, node);
    
    setImageUrl(item.ImageUrl);  
    
    if (props.onChange !== undefined && typeof props.onChange === 'function') { 
      props.onChange(node, item);
    } 
  };

  return (
    <> 
      <div className="p-panel-header head-organizationalPosition">
        <div>
          {
          imageUrl === null || imageUrl === undefined ? 
          (<Avatar icon="pi pi-user" className="mr-2 avatar-position dark-color" shape="circle" />)
          :
          (<Avatar image={imageUrl} className="mr-2 avatar-position" shape="circle" />)  
        }
          
        </div>
        <div className="user-name" title="">
          <TreeviewButton
            nodes={nodes}
            showAsTreeSelect={props.showAsTreeSelect}
            optionLabel="label"
            expandedKeys={expandedKeys}
            nodeTemplate={nodeTemplate}                     
            selectedKey={selectedUser}
            onChange={onChange}
            icon="pi pi-angle-down"
            iconPosition="right"
            showPositionName={props.showPositionName}
            onToggle={onToggle}
          >
          </TreeviewButton>              
        </div>          
      </div> 
      {/* <TreeviewButton nodes={nodes} showAsTreeSelect={props.showAsTreeSelect} optionLabel="label" expandedKeys={expandedKeys} nodeTemplate={nodeTemplate} icon="pi pi-users" selectedKey={selectedUser} onChange={onChange}></TreeviewButton>                       */}
    </>
  );
};

export default OrganizationalSalesRepView;

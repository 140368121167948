/* eslint-disable jsx-a11y/anchor-is-valid */
import { config } from '../../../utils/config';
import { callApi,showSweetAlert } from '../../../utils/utils';  
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next';  
import { Row, Col} from 'reactstrap';
import { Button } from 'primereact/button'; 
import { confirmDialog } from 'primereact/confirmdialog'; 
import { TreeList, Scrolling, Paging, Pager, Column, Selection, Format } from 'devextreme-react/tree-list';
import moment from 'moment';  
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon'
import swal from '@sweetalert/with-react';

const FolderList =(props) => { 
  const { t } = useTranslation(['dms', 'common']); 
  const [FolderList, setFolderList] = useState([]); 
  const expandedRowKeys = [0];

  useEffect(() => {    
    getList(0);
}, [props.refresh]);

  const getList = (pClaFolderPadre) => { 
    const paramsFilter = {};  
    const GetProductoFolderList = `${config.UrlApiProject}DMS/GetProductoFolderList?ClaFolderPadre=${pClaFolderPadre}&Language=""`;  
    callApi(GetProductoFolderList, 'GET', paramsFilter, (response) => { 
      setFolderList(response.data.FolderList);
    });
  }

  const onSearch = () => {
    getList(0);
  };

  const onAddFolder = () => {
    console.log('onAddFolder',);
    if (props.onAfterSelect !=  null){ 
      const data = {ClaFolder:0}
       props.onAfterSelect(data);
     }
  };

  const onAdd = (row) => {
    console.log('onAdd',row);
    if (props.onAfterSelect !=  null){ 
      row.data.EsNuevo = true;
      props.onAfterSelect(row.data);
    }
  };

  const onEdit = (row) => {
    console.log('onEdit',row);
    if (props.onAfterSelect !=  null){ 
      props.onAfterSelect(row.data);
    }
  };

  const confirmarEliminar = (ClaFolder) => {
    const urlWebServiceSave = `${config.UrlApiProject}DMS/PostCatFolderInactive`;
    const paramsService = {
      ClaFolder
    }; 
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {
      showSweetAlert(t('common:Exito'), t('common:SaveSuccess'), 'success');
      getList(0);
    });  
  }

  
const onConfirmarEliminar = (Obj) => {  
  swal({ 
    buttons: {
      cancelar: {
        text: "Cancel",
        value: "cancel",
        className: "rounded-button danger"
      },
      continuar: {
        text: "Continue",
        value: "continue",
        className: "rounded-button light-green"
      }
    },
    content: confirmationMessageHTML
  }).then((value) => {
    switch (value) { 
      case "continue":
        // run function
        confirmarEliminar(Obj.data.ClaFolder);
        break; 
      default:
        break;
    }
  });

}

const confirmationMessageHTML = (
  <div>
    <SweatAlertCustomIcon title={t('common:DeleteFileConfirmation')} letter={t('common:QuestionMark')}></SweatAlertCustomIcon> 
    <div><b>{t('dms:MessageDeleteFileConfirmation')}</b></div> 
  </div>  
 )

  const ShowButtons = (row) =>  {  
    return (
      <>      
        <Button
          // label={t('common:Add')}
          icon="fa fa-plus"  
          className="light-green p-button-rounded no-border p-mr-2"
          // className="p-button-sm p-1 success"
          onClick={(e) => onAdd(row)} 
        />&nbsp;
        <Button
          // label={t('common:Edit')}
          icon="pi pi-pencil"  
          className="p-button-rounded no-border p-highlight"
          // className="p-button-sm p-1 info"
          onClick={(e) => onEdit(row)} 
        />&nbsp;
        {/* <Button
          label={t('common:Remove')}
          icon="fa fa-ban"  
          className="light-red p-mr-2"
          // className="p-button-sm p-1 danger"
          onClick={(e) => onConfirmarEliminar(row)} 
        /> */}
      </>
    );
  }

    return (
      <> 
        <Row className='pb-2'>
          <Col lg={10} md={10} sm={10} xs={10}>
            &nbsp;
          </Col>
          <Col lg={2} md={2} sm={2} xs={2} className="text-right">
            <Button
              label={t('common:New')}
              icon="pi pi-plus" 
              onClick={(e) => onAddFolder()} 
              className="light-green no-border p-mr-2"
            /> &nbsp;
            <Button
              label={t('common:Refresh')}
              icon="pi pi-refresh" 
              onClick={(e) => onSearch()} 
              className="p-button p-highlight"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TreeList
              id="FolderList"
              className='treeList'
              dataSource={FolderList}
              rootValue={0}
              defaultExpandedRowKeys={expandedRowKeys}
              showRowLines={true}
              showBorders={true}
              columnAutoWidth={true}
              keyExpr="ClaFolder"
              parentIdExpr="ClaFolderPadre"
              noDataText={t('common:NoData')}
              height="auto"
            >
              <Selection mode="single" />
              <Scrolling
                mode="standard"
              />
              <Paging
                enabled={true}
                defaultPageSize={150}
              />
              <Pager
                showPageSizeSelector={false}
                showInfo={false}
              />
              <Column 
                dataField="NomFolder"
                caption={t('dms:Folder')}
              >
              </Column>
              <Column 
                dataField="NomFolderDescripcion"
                caption={t('dms:FolderDescription')}
              >
              </Column>             
              <Column 
                cellRender={ShowButtons}  
                headerStyle={{ width: '150px' }}
                // cssClass="empty-column-space expand-column-info-color text-center"
              >  
              </Column> 
            </TreeList>
          </Col>
        </Row>
      </>  
        ); 
};

export default FolderList;

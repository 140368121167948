/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'primereact/column';
import {
  Row,
  Col,
  ListGroupItem,
  ListGroup,
  Progress,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from 'reactstrap';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { Badge } from 'primereact/badge';
import { Link } from 'react-router-dom';
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../../utils/utils';
import Score from '../../../../components/Cards/Score/Score.jsx';
import FieldDataTable from '../../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import PropType from 'prop-types';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import { Dialog } from 'primereact/dialog';
import FieldText from 'components/Controls/FieldText';
import { Toolbar } from 'primereact/toolbar';
import { evaluate, forEach } from "mathjs";
import FieldRadioButton from 'components/Controls/FieldRadioButton/FieldRadioButton';
import FieldDropdown from 'components/Controls/FieldDropdown';
import OrganizationalPositionView from '../OrganizationalPositionView';
import SweatAlertCustomIcon from 'components/SweatAlert/SweatAlertCustomIcon';
import swal from '@sweetalert/with-react';
import * as yup from 'yup';
import YupValidator from '../../../../utils/YupValidator';
import { useStaticState } from '@material-ui/pickers';

const KPIFormulaSetUp = ({show, onHide, onFormulaCreated, IdKPIFormula, Description, onFormulaRemoved}) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['scoreCard', 'common', 'misProyectosScreen']);
  const [errors, setErrors] = useState({}); 
  const [Formula, setFormula] = useState(null);
  const [FormulaVariablesList, setFormulaVariablesList] = useState([]);
  const [EsTieneErroresFormula, setEsTieneErroresFormula] = useState(false);
  const [showKPIRelated, setshowKPIRelated] = useState(false);
  const [tipoConfiguracionKPI, setTipoConfiguracionKPI] = useState(null);
  const [ClaTipoProyecto, setClaTipoProyecto] = useState(null);
  const [ProyectoList, setProyectoList] = useState([]);
  const [TipoProyectoList, setTipoProyectoList] = useState([]);
  const [ClaProyecto, setClaProyecto] = useState(null);
  const [PuestosList, setPuestosList] = useState([]);
  const [ClaPuesto, setClaPuesto] = useState(null);
  const [ClaIndicador, setClaIndicador] = useState(null);
  const [IndicadoresList, setIndicadoresList] = useState([]);
  const [IdProyectoKPIManagement, setIdProyectoKPIManagement] = useState(null);
  const [ProyectoKPIManagementList, setProyectoKPIManagementList] = useState([]);
  const [ClaTipoIndicador, setClaTipoIndicador] = useState(null);
  const [ClaIndicadorUnico, setClaIndicadorUnico] = useState(null);
  const [EsHabilitarActualizarFormulaBtn, setEsHabilitarActualizarFormulaBtn] = useState(false);
  const [EsMostrarVariablesFormula, setEsMostrarVariablesFormula] = useState(false);
  const [EsHabilitarEdicionFormula, setEsHabilitarEdicionFormula] = useState(true);
  const [CurrentRowIndexVariable, setCurrentRowIndexVariable] = useState(null);
  const [KPIVariableSelected, setKPIVariableSelected] = useState(null);
  const [showWarningVariablePendientes, setShowWarningVariablePendientes] = useState(false);
  const [TipoResultadoKPI, setTipoResultadoKPI] = useState(1);

  useEffect(() => { 
    obtenerTipoProyecto();
    GetKPIFormulaPuestos(); 
    /* si estan consultando una formula que ya existe */
    if (typeof(IdKPIFormula) !== 'undefined' && IdKPIFormula !== null){ 
      GetKPIFormulaInfo(IdKPIFormula);
    }
  }, []);

  const GetKPIFormulaPuestos = () => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/GetKPIFormulaPuestos`;  
    const paramsToService = { 
      
    };   
    callApi(GetSummaryScorecardUser, 'GET', paramsToService, (response) => { 
      setPuestosList(response.data[0]);
    });
  }

  const GetKPIFormulaScorecardIndicadoresPuesto = (ClaPuesto, item) => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/GetKPIFormulaScorecardIndicadoresPuesto?ClaPuesto=${ClaPuesto === null || typeof(ClaPuesto) === 'undefined' ? -1 : ClaPuesto}`;  
    const paramsToService = { 
      
    };   
    callApi(GetSummaryScorecardUser, 'GET', paramsToService, (response) => { 
      const indicadoresList = addUniqueRowIndexToArray(response.data[0]);
      setIndicadoresList(indicadoresList);

      if (typeof(item) !== 'undefined'){ 
        setClaPuesto(item.ClaPuesto);
        const indicador = { ...indicadoresList.filter((item2) => item2.ClaIndicador === item.ClaIndicador && item2.ClaTipoIndicador === item.ClaTipoIndicador)[0]}; 
        setClaIndicadorUnico(indicador.ClaIndicadorUnico);
        setClaIndicador(item.ClaIndicador);
        setClaTipoIndicador(item.ClaTipoIndicador);
        setTipoResultadoKPI(item.TipoResultadoKPI);
      }
    });
  }

  const addUniqueRowIndexToArray = (items) => {
    const newItems = []

    for (let index = 0; index < items.length; index++) {
        const element = items[index];
        element.ClaIndicadorUnico = index;
        newItems.push(element);
    }     
    return newItems;
  }

  const obtenerTipoProyecto = () => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/MSWBSCTipoProyecto`;  
    const paramsToService = { 
      
    };   
    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => { 
      if (response.data[0].length >0)
      {  
        setTipoProyectoList(response.data[0]);
        setClaTipoProyecto(1);
        obtenerProyecto(1);
      }
      else{
        setTipoProyectoList([]);
      }  
    });
  }

  const obtenerProyecto = (pClaTipoProyecto) => {
    pClaTipoProyecto = pClaTipoProyecto === undefined ? 1 : pClaTipoProyecto
    const GetProyectoCmb = `${config.UrlApiProject}ScoreCard/GetProyectoCmb?ClaTipoProyecto=${pClaTipoProyecto}`;
    const paramsToService = {};
    callApi(GetProyectoCmb, 'GET', paramsToService, (response) => {
      setProyectoList(response.data.Proyecto);
    });
  };

  const obtenerInformacionKPIManagement = (ClaProyecto) => { 
    const GetKPIManagementList = `${config.UrlApiProject}ScoreCard/GetKPIManagementList?IdClaProyecto=${ClaProyecto}`;  
    const paramsToService = { };   
    callApi(GetKPIManagementList, 'GET', paramsToService, (response) => {
      setProyectoKPIManagementList(response.data.ProyectoKPIManagementList);  
    });
  }

  const GetKPIFormulaInfo = (IdKPIFormula) => {
    IdKPIFormula = IdKPIFormula === undefined ? -1 : IdKPIFormula
    const GetKPIFormulaInfo = `${config.UrlApiProject}ScoreCard/GetKPIFormulaInfo?IdKPIFormula=${IdKPIFormula}`;
    const paramsToService = {};
    callApi(GetKPIFormulaInfo, 'GET', paramsToService, (response) => {
         const Formula = response.data.Formula.length > 0 ? response.data.Formula[0] : {};
         const { FormulaDetalle } = response.data;
 
         setFormula(Formula.Formula);
         setEsMostrarVariablesFormula(true);
         setEsTieneErroresFormula(false);
         setEsHabilitarEdicionFormula(false);
         setEsHabilitarActualizarFormulaBtn(false);
         setShowWarningVariablePendientes(false);
         setFormulaVariablesList(FormulaDetalle);
    });
  };
  
  const renderHeader = () => {
    return (
      <div>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
            <span>{t('scoreCard:CreateEditFormula')}: {Description}</span> 
          </Col> 
        </Row>
      </div>
    );
  };

  const leftToolbarFooterTemplate = () => {  
    return (
      <>    
      </>
    );
  }; 

  const rightToolbarFooterTemplate = () => {
     
    return (
      <>   
        {
          typeof(IdKPIFormula) !== 'undefined' && IdKPIFormula !== null &&
          (
          <FieldButton
            label={t('scoreCard:RemoveFormula')}
            icon="fas fa-trash-alt"
            className="p-button-danger no-border p-mr-2 rounded-button"
            onClick={removeFormula}
          />
          )
        } 
        {
          EsMostrarVariablesFormula &&
          (
          <FieldButton
            label={typeof(IdKPIFormula) !== 'undefined' && IdKPIFormula !== null ? t('scoreCard:EditFormula'): t('scoreCard:CreateFormula')}
            icon="fas fa-cogs"
            className="p-button-success no-border p-mr-2 rounded-button"
            onClick={createFormula}
          />
          )
        }
        
      </> 
    );
  }; 


  const valitationSchemeCreateFormula = yup.object().shape({  
    Formula: yup.string().required(t('common:RequiredField')),
     
  });

  const createFormula = async () => {
     /* mandamos validar los campos requeridos.. */ 

    const idkpiformula = typeof(IdKPIFormula) !== 'undefined' ? IdKPIFormula : null;
    setErrors({});
    setShowWarningVariablePendientes(false);
    const value = {    
      Formula
    }; 
   
    const validator = new YupValidator(valitationSchemeCreateFormula);
    const esValido = await validator.validate(value);
 
    if (!esValido){   
      setErrors(validator.errors);  
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
      return;
    }

    for (let index = 0; index < FormulaVariablesList.length; index++) {
      const element = FormulaVariablesList[index];
       
      if (element.ClaTipoKPIFormula === null){
        showSweetAlert(t('common:Warning'), t('scoreCard:KPIVariablePendingSetUp'), 'warning');  
        setShowWarningVariablePendientes(true);
        return
      }
    }

    // Manamos crear la formula y la relacionamos
    const KPIFormulaDetalle = [];
    
    for (let index = 0; index < FormulaVariablesList.length; index++) {
      const element = FormulaVariablesList[index];
      
      KPIFormulaDetalle.push(
        {
          IdKPIFormulaDet : element.IdKPIFormulaDet,
          IdKPIFormula : idkpiformula , 
          ClaveKPIFormulaDetalle : element.ClaveKPIFormulaDetalle ,
          ClaTipoKPIFormula: element.ClaTipoKPIFormula,
          ClaPuesto: element.ClaPuesto,
          ClaIndicador: element.ClaIndicador,
          ClaTipoIndicador: element.ClaTipoIndicador,
          IdProyectoKPIManagement: element.IdProyectoKPIManagement,
          TipoResultadoKPI : element.TipoResultadoKPI
        }
      );
    }

    const CreateEditFormula = `${config.UrlApiProject}ScoreCard/CreateEditFormula`;  
    const paramsToService = {  
         IdKPIFormula: idkpiformula,
         Formula,  
         KPIFormulaDetalle 
    };   

    callApi(CreateEditFormula, 'POST', paramsToService, (response) => {  
       showSweetAlert('success', t('common:SuccessOperation'), 'success');
        
       if (typeof(onFormulaCreated) === 'function'){ 
         onFormulaCreated(response.data);
       }
    });

  }


  const RemoveFormulaKPI = () => {
    const RemoveKPIFormula = `${config.UrlApiProject}ScoreCard/RemoveKPIFormula`;  
    const paramsToService = {  
         IdKPIFormula 
    };   

    callApi(RemoveKPIFormula, 'POST', paramsToService, (response) => {  
       showSweetAlert('success', t('common:SuccessOperation'), 'success');
        
       if (typeof(onFormulaRemoved) === 'function'){ 
         onFormulaRemoved(response.data);
       }
    });
  }

  const renderFooterKPIRelated = () => {
    return (
      <> 
        <FieldButton
          label={t('common:Apply')}
          icon="fas fa-check"
          className="p-button-success no-border p-mr-2 rounded-button" 
          onClick={onApplySetUpVariable}
        />  
      </>
    );
  };


  const getVariablesFromFormula = (formula) => { 
    
    if (formula === null){
        return [];
    }

    let items = [];
    
    const allParts = formula.split('{');
  
    allParts.forEach((part) => {
      if (part.indexOf('}') > -1) {
              const item = (part.split('}'))[0];

              if (!items.includes(item)){
                items = items.concat(item); 
              }
      }
    });
    
    return items;
  }
 
  const onChangeFormula = (e) => {  
    const newErrors = { ...errors }
    delete newErrors.Formula;   
    setErrors(newErrors);
    setFormula(e.target.value);
    setEsHabilitarActualizarFormulaBtn(e.target.value !== null && typeof(e.target.value) !== 'undefined' && e.target.value !== '');
    setEsMostrarVariablesFormula(false);
  }
  
  const renderHeaderKPIRelated = () => {
    return (
      <div>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
            <span>{t('scoreCard:SetUpVariable')}</span> 
          </Col> 
        </Row>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <>
        <Toolbar
          left={leftToolbarFooterTemplate}
          right={rightToolbarFooterTemplate}
        >
        </Toolbar>
      </>
    );
  };

  const onClickConfigurarVariable = (item) => {
     setshowKPIRelated(true); 
     setCurrentRowIndexVariable(item.RowIndex);
     setKPIVariableSelected({...item});
      
     // Si es nuevo inicializamos la pantalla....
     setTipoConfiguracionKPI(null);
     setClaPuesto(null);
     setClaIndicador(null);
     setClaTipoIndicador(null);
     setClaTipoProyecto(null);
     setClaProyecto(null);
     setIdProyectoKPIManagement(null);
     setClaIndicadorUnico(null);
      
     setProyectoKPIManagementList([]);
     setIndicadoresList([]);
     setProyectoList([]);
      
     if (item.ClaIndicador !== null || item.IdProyectoKPIManagement !== null ){ 
        // KPI from Scorecard
        setTipoConfiguracionKPI(item.ClaTipoKPIFormula);
        if (item.ClaTipoKPIFormula === 1) {
          GetKPIFormulaScorecardIndicadoresPuesto(item.ClaPuesto, item);
         
        }
        // KPI from project
        else {
          
          obtenerProyecto(item.ClaTipoProyecto);
          obtenerInformacionKPIManagement(item.ClaProyecto);
          setClaTipoProyecto(item.ClaTipoProyecto);
          setClaProyecto(item.ClaProyecto);
          setIdProyectoKPIManagement(item.IdProyectoKPIManagement);
        }
     }
  }

  const onChangeTipoConfiguracionKPI = (value) => {
        const newErrors = { ...errors } 
        delete newErrors.TipoConfiguracionKPI;   
        setErrors(newErrors) 
        setTipoConfiguracionKPI(value); 
  }

  const onChangeTipoResultadKPI = (value) => {
    const newErrors = { ...errors } 
    delete newErrors.TipoResultadoKPI;   
    setErrors(newErrors) 
    setTipoResultadoKPI(value); 
}
  
  const onChangeTipoProyecto = (e) => {
    const newErrors = { ...errors }
    delete newErrors.ClaTipoProyecto;   
    setErrors(newErrors);
    obtenerProyecto(e.target.value);
    setClaTipoProyecto(e.target.value);
    obtenerInformacionKPIManagement(-1);    
  };

  const onChangeProyecto = (e) => {
    const newErrors = { ...errors }
    delete newErrors.ClaProyecto;   
    setErrors(newErrors);
    setClaProyecto(e.target.value); 
    setIdProyectoKPIManagement(null);
    obtenerInformacionKPIManagement(e.target.value === null || typeof(e.target.value) === 'undefined' ? -1 : e.target.value);
  };

  const onChangeProyectoKPIManagement = (e) => {
    const newErrors = { ...errors }
    delete newErrors.IdProyectoKPIManagement;   
    setErrors(newErrors);
    setIdProyectoKPIManagement(e.target.value);
  }

  const onChangePuesto = (e) => {
    const newErrors = { ...errors }
    delete newErrors.ClaPuesto;   
    setErrors(newErrors);
    setClaPuesto(e.target.value); 
    setClaIndicador(null);
    setClaTipoIndicador(null);
    setClaIndicadorUnico(null);
    GetKPIFormulaScorecardIndicadoresPuesto(e.target.value);
  };

  const onChangeIndicador = (e, x) => {
    const newErrors = { ...errors }
    delete newErrors.ClaIndicador;   
    setErrors(newErrors);
    setClaIndicador(null);
    setClaTipoIndicador(null);
    
    setClaIndicadorUnico(e.target.value);
    if (typeof(e.target.value) !== 'undefined' && e.target.value !== null){
      const item = IndicadoresList.filter((item) => item.ClaIndicadorUnico === e.target.value)[0]; 
      setClaIndicador(item.ClaIndicador);
      setClaTipoIndicador(item.ClaTipoIndicador);
    } 
  };

  const onChangeOrganizationalValue = (itemValue, itemNode)=> { 
    // console.log(itemNode);
    setClaPuesto(itemNode.claPuesto);
  }
  
  const confirmationMessageHTML = (
    <div>
      <SweatAlertCustomIcon title="" letter={t('common:QuestionMark')}></SweatAlertCustomIcon> 
      <div>{t('scoreCard:FormulaEditWarening')}</div> 
    </div>  
   ) 

   const confirmationRemoveMessageHTML = (
     <div>
       <SweatAlertCustomIcon title={t('common:DeleteConfirmation')} letter={t('common:QuestionMark')}></SweatAlertCustomIcon> 
       <div>{t('scoreCard:FormulaRemoveWarening')}</div> 
     </div>  
   ) 

   const removeFormula = () => {
    /* mandamos confirmar que desea editar */
    swal({ 
      buttons: {
        cancelar: {
          text: t('common:Cancel'),
          value: "cancel",
          className: "rounded-button danger"
        },
        continuar: {
          text: t('common:Continue'),
          value: "continue",
          className: "rounded-button light-green"
        }
      },
      content: confirmationRemoveMessageHTML
    }).then((value) => {
      switch (value) { 
        case "continue": 
          RemoveFormulaKPI();
          break; 
        default:
          break;
      }
    });
   }

  const EditarFormula = () => {
    /* mandamos confirmar que desea editar */
    swal({ 
      buttons: {
        cancelar: {
          text: t('common:Cancel'),
          value: "cancel",
          className: "rounded-button danger"
        },
        continuar: {
          text: t('common:Continue'),
          value: "continue",
          className: "rounded-button light-green"
        }
      },
      content: confirmationMessageHTML
    }).then((value) => {
      switch (value) { 
        case "continue": 
          setEsHabilitarEdicionFormula(true);
          break; 
        default:
          break;
      }
    });
  }

  const AplicarFormula = () => {
    const variablesList =  getVariablesFromFormula(Formula); 
    setEsTieneErroresFormula(false);
    /* evaluamos la expresion a ver si detectamos que es valida */
    let expression = Formula;
    // console.log('expression', expression)
    for (let index = 0; index < variablesList.length; index++) {
        const element = variablesList[index]; 
         // se trato de usar replaceAll y no funciono correctamente por las llaves
        for (let index2 = 0; index2 < 50; index2++) {
            expression = expression.replace(`{${  element  }}`,'1'); 
        }

        // console.log('expression', expression);
    }
 
    // evaluamos la formula solo para utilizar y poner una bandera de mensaje de error para que la revisen, no los vamos a
    // detener
    try{
       console.log('evaluacion:', evaluate(expression)) 
    }
    catch(ex){
        setEsTieneErroresFormula(true);
    }

    const variables = [];
 
    variablesList.forEach(element => {
      variables.push({
          ClaveKPIFormulaDetalle: `{${  element  }}`,
          IdKPIFormulaDet: null,
          IdKPIFormula : null, 
          RowIndex: variables.length,
          tipoConfiguracionKPI: null,
          ClaPuesto: null,
          ClaIndicador: null,
          ClaTipoIndicador: null,
          ClaTipoProyecto: null,
          ClaProyecto: null,
          IdProyectoKPIManagement: null,
          ClaTipoKPIFormula: null,
          TipoResultadoKPI: null
      })
    });

    
    setFormulaVariablesList(variables);
    setEsHabilitarActualizarFormulaBtn(false);
    setEsMostrarVariablesFormula(true);
    setEsHabilitarEdicionFormula(false)
  }

  
  const valitationSchemeSetUpVariable = yup.object().shape({  
    tipoConfiguracionKPI: yup.number().required(t('common:RequiredField')),
    TipoResultadoKPI: yup.number().required(t('common:RequiredField')),
    ClaTipoProyecto: (tipoConfiguracionKPI === 2
      ? yup.mixed().required(t('common:RequiredField')).nullable()
      : yup.mixed().nullable()
      ),     
    ClaProyecto: (tipoConfiguracionKPI === 2
      ? yup.mixed().required(t('common:RequiredField')).nullable()
      : yup.mixed().nullable()
      ),   
    IdProyectoKPIManagement: (tipoConfiguracionKPI === 2  
      ? yup.mixed().required(t('common:RequiredField')).nullable()
      : yup.mixed().nullable()
      ),
    ClaPuesto: (tipoConfiguracionKPI === 1
      ? yup.mixed().required(t('common:RequiredField')).nullable()
      : yup.mixed().nullable()
      ),
    ClaIndicadorUnico : (tipoConfiguracionKPI === 1
        ? yup.mixed().required(t('common:RequiredField')).nullable()
        : yup.mixed().nullable()
        ),
    ClaIndicador: (tipoConfiguracionKPI === 1
        ? yup.mixed().required(t('common:RequiredField')).nullable()
        : yup.mixed().nullable()
        ),
    ClaTipoIndicador: (tipoConfiguracionKPI === 1
          ? yup.mixed().required(t('common:RequiredField')).nullable()
          : yup.mixed().nullable()
       ) 
  });

  const onApplySetUpVariable = async () => {
    
    // validamos campos requeridos. 
  setErrors({});
     
  const value = {    
     tipoConfiguracionKPI
    ,ClaPuesto
    ,ClaIndicador
    ,ClaTipoIndicador
    ,ClaIndicadorUnico

    ,ClaTipoProyecto
    ,ClaProyecto
    ,IdProyectoKPIManagement
    ,TipoResultadoKPI
  };

 
  
  const validator = new YupValidator(valitationSchemeSetUpVariable);
  const esValido = await validator.validate(value);

  if (!esValido){   
    setErrors(validator.errors); 
    console.log('errors', validator.errors);
    showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
    return;
  }


     
    const listadoVariables = [...FormulaVariablesList];
    const row = listadoVariables[CurrentRowIndexVariable]

    row.ClaPuesto       =  tipoConfiguracionKPI === 1 ? ClaPuesto : null;
    row.ClaIndicador    =  tipoConfiguracionKPI === 1 ? ClaIndicador: null;
    row.ClaTipoIndicador  = tipoConfiguracionKPI === 1 ?  ClaTipoIndicador: null;  
    row.ClaIndicadorUnico = tipoConfiguracionKPI === 1 ?  ClaIndicadorUnico: null; 
    row.TipoResultadoKPI        = tipoConfiguracionKPI === 1 ?  TipoResultadoKPI: null;
    row.ClaTipoProyecto   = tipoConfiguracionKPI === 2 ?  ClaTipoProyecto : null;
    row.ClaProyecto             = tipoConfiguracionKPI === 2 ?  ClaProyecto : null;
    row.IdProyectoKPIManagement = tipoConfiguracionKPI === 2 ?   IdProyectoKPIManagement : null;
    row.ClaTipoKPIFormula       = tipoConfiguracionKPI;  
    let indicador = null;

    if (tipoConfiguracionKPI === 1){ 
      indicador = { ...IndicadoresList.filter((item) => item.ClaIndicador === ClaIndicador && item.ClaTipoIndicador === ClaTipoIndicador)[0]}; 
      row.KPIReferenceDescription = indicador.Description;
    }
    else { 
      indicador = { ...ProyectoKPIManagementList.filter((item) => item.IdProyectoKPIManagement === IdProyectoKPIManagement)[0]}; 
      row.KPIReferenceDescription = indicador.NomProyectoKPIManagement;
    }
 
    setFormulaVariablesList(listadoVariables); 
    setshowKPIRelated(false);
  }
  


  return (
    <>
      <Dialog
        visible={show}
        modal
        style={{ width: '60vw' }}
        footer={renderFooter}
        header={renderHeader} 
        closable={true}
        onHide={onHide}
        className="dialog-header-background-blue"
        maximized={false}
        draggable={false}
      >
        <Row className='pt-4'>
          <Col lg={12} md={12} sm={12} xs={12}> 
            <div>{t('scoreCard:Formula')}:</div>
            <div className="p-fluid">
              <div className="p-inputgroup">
                <div className='p-inputgroup-addon'><i className="fas fa-equals"></i>&nbsp;</div>
                <FieldText
                  name="Formula" 
                  maxLength={150} 
                  autofocus={true}
                  placeholder="Type here your formula expample: (({1}*{2})/{3}))*100.0"
                  onChange={onChangeFormula}
                  value={Formula}
                  disabled={!EsHabilitarEdicionFormula}
                  errors={errors}
                /> 
                {
                  Formula !== null && Formula !== '' && !EsHabilitarActualizarFormulaBtn ?
                  (
                    <>
                      <FieldButton
                        label={t('common:Edit')}
                        icon="fas fa-pen-square"
                        className="p-button-warning p-inputgroup-addon"
                        onClick={EditarFormula} 
                      />   
                    </>
                  )
                  :
                  (
                    <>
                      <FieldButton
                        label={t('common:Apply')}
                        icon="fas fa-check"
                        className="p-button-success p-inputgroup-addon"
                        onClick={AplicarFormula}
                        disabled={!EsHabilitarActualizarFormulaBtn}
                      />   
                    </>
)  
                } 
              </div>
            </div>
            <div><small>{t('scoreCard:FormulaHelper')}</small></div>
            {
                EsTieneErroresFormula &&
                (
                <span className='danger-color'><i className='fas fa-exclamation-triangle'></i>&nbsp;{t('scoreCard:FormulaError')}</span>
                )
            } 
          </Col>
        </Row>  
        {
          EsMostrarVariablesFormula &&
          (
            <>
              <Row className='pt-4 pb-2'>
                <Col> 
                  <span>{t('scoreCard:AssignValuesVariables')}:</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  {
                    showWarningVariablePendientes &&
                    (<span className='danger-color'>{t('scoreCard:KPIVariablePendingSetUp')}</span>)
                  }
                  <div className='p-datatable p-component p-datatable-scrollable table-header-background-blue'>
                    <table className='w-100'>
                      <thead className='p-datatable-thead'>
                        <tr>
                          <th width="20%">{t('scoreCard:Variable')}</th>
                          <th width="70%">{t('scoreCard:KPIReference')}</th> 
                          <th width="10%">&nbsp;</th> 
                        </tr>
                      </thead> 
                      <tbody className='p-datatable-tbody'>
                        {
                    FormulaVariablesList.length === 0 &&
                    (
                    <>
                      <tr>
                        <td colSpan={2} className='text-center p-3'><i className='fas fa-exclamation-triangle danger-color' style={{fontSize:'30px'}}></i><br></br><br></br>
                          <div>
                            {t('scoreCard:NoVariablesFound')}
                          </div>
                        </td>
                      </tr>
                    </>   
                    )
                  } 
                        {
                  FormulaVariablesList.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td width="20%">{item.ClaveKPIFormulaDetalle}</td>
                            <td width="70%">
                              <span className='link-text' onClick={() => onClickConfigurarVariable(item)}>
                                {
                                  typeof(item.KPIReferenceDescription) !== 'undefined'  && item.KPIReferenceDescription !== '' && item.KPIReferenceDescription !== null 
                                  ?
                                  (<>{item.KPIReferenceDescription}</>)
                                  :
                                  (<>{t('scoreCard:KPICfgLink')}</>)

                                 } 
                              </span>
                            </td>
                            <td width="10%">
                              {
                                  typeof(item.KPIReferenceDescription) !== 'undefined'  && item.KPIReferenceDescription !== '' && item.KPIReferenceDescription !== null 
                                  ?
                                  (<><i className="fas fa-check success-color"></i></>)
                                  :
                                  (<><i className='fas fa-exclamation-triangle danger-color'></i></>)
                            }
                            </td>
                          </tr>
                        )
                        })
                   } 
                      </tbody>
                    </table>
                  </div>
            
                </Col>
              </Row>
            </>
          )
        }
        
      </Dialog>
      <Dialog
        visible={showKPIRelated}
        modal
        style={{ width: '60vw' }}
        footer={renderFooterKPIRelated}
        header={renderHeaderKPIRelated} 
        closable={true}
        onHide={() => setshowKPIRelated(false)}
        className="dialog-header-background-blue"
        maximized={false}
        draggable={false}
      >
        <Row className='pt-4'>
          <Col lg={12} md={12} sm={12} xs={12}> 
            <div><span>{t('scoreCard:HowWouldSetUpVariable')}</span></div>
            
            
          </Col>
        </Row> 
        <Row className='pt-2'>
          <Col lg={6} md={6} sm={6} xs={6}>
            <FieldRadioButton
              inputId="configuracionPor-scorecardKPI"
              name="tipoConfiguracionKPI" 
              value={1}
              label="KPI from Scorecard"
              onChange={(value) => onChangeTipoConfiguracionKPI(value)} 
              checked={tipoConfiguracionKPI === 1}
              errors={errors}
            >
            </FieldRadioButton>
          </Col>
          <Col lg={6} md={6} sm={6} xs={6}>
            <FieldRadioButton
              inputId="configuracionPor-proyectoKPI"
              name="tipoConfiguracionKPI" 
              value={2}
              label="KPI from project"
              onChange={(value) => onChangeTipoConfiguracionKPI(value)} 
              checked={tipoConfiguracionKPI === 2}
              errors={errors}
            >
            </FieldRadioButton>
          </Col>
        </Row>
        {
            tipoConfiguracionKPI === 1 &&
            (
              <>
                <Row className='pt-2'>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div><span>{t('scoreCard:Position')}</span></div>
                    <div className="p-fluid">  
                      <FieldDropdown
                        name="ClaPuesto" 
                        value={ClaPuesto}
                        options={PuestosList}
                        optionValue="ClaPuesto"
                        optionLabel="NomPuesto"
                        onChange={(e) => onChangePuesto(e)}
                        errors={errors}
                      /> 
                    </div>
                  </Col>
                </Row> 
                <Row className='pt-2'>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div><span>{t('scoreCard:KPIIndex')}</span></div>
                    <div className="p-fluid">  
                      <FieldDropdown
                        name="ClaIndicadorUnico" 
                        value={ClaIndicadorUnico}
                        options={IndicadoresList}
                        optionValue="ClaIndicadorUnico"
                        optionLabel="Description"
                        onChange={(e, x) => onChangeIndicador(e)}
                        errors={errors}
                      />
                    </div> 
                  </Col>
                </Row>
                <Row className='pt-3'>
                  <Col><div><span>{t('scoreCard:PickASource')}:</span></div></Col>
                </Row>
                <Row className='pt-2'> 
                  <Col lg={3} md={3} sm={3} xs={3}>
                    <FieldRadioButton
                      inputId="kpi-value"
                      name="TipoResultadoKPI" 
                      value={1}
                      label={t('scoreCard:CurrentStatus')}
                      onChange={(value) => onChangeTipoResultadKPI(value)} 
                      checked={TipoResultadoKPI === 1}
                      errors={errors}
                    >
                    </FieldRadioButton>
                  </Col>
                  <Col lg={3} md={3} sm={3} xs={3}>
                    <FieldRadioButton
                      inputId="kpi-value"
                      name="TipoResultadoKPI" 
                      value={2}
                      label={t('scoreCard:MinimunAcceptable')}
                      onChange={(value) => onChangeTipoResultadKPI(value)} 
                      checked={TipoResultadoKPI === 2}
                      errors={errors}
                    >
                    </FieldRadioButton>
                  </Col>
                  <Col lg={3} md={3} sm={3} xs={3}>
                    <FieldRadioButton
                      inputId="kpi-value"
                      name="TipoResultadoKPI" 
                      value={3}
                      label={t('scoreCard:Satisfactory')}
                      onChange={(value) => onChangeTipoResultadKPI(value)} 
                      checked={TipoResultadoKPI === 3}
                      errors={errors}
                    >
                    </FieldRadioButton>
                  </Col>
                  <Col lg={3} md={3} sm={3} xs={3}>
                    <FieldRadioButton
                      inputId="kpi-value"
                      name="TipoResultadoKPI" 
                      value={4}
                      label={t('scoreCard:OutStanding')}
                      onChange={(value) => onChangeTipoResultadKPI(value)} 
                      checked={TipoResultadoKPI === 4}
                      errors={errors}
                    >
                    </FieldRadioButton>
                  </Col>
                </Row>
              </>  
            )
        }
        {
            tipoConfiguracionKPI === 2 &&
            (
            <>
              <Row className='pt-2'>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div><span>{t('misProyectosScreen:ProyectType')}</span></div>
                  <div className="p-fluid">  
                    <FieldDropdown
                      name="ClaTipoProyecto" 
                      value={ClaTipoProyecto}
                      options={TipoProyectoList}
                      optionValue="ClaTipoProyecto"
                      optionLabel="NomTipoProyecto"
                      onChange={(e) => onChangeTipoProyecto(e)}
                      errors={errors}
                    /> 
                  </div>
                </Col>
              </Row>
              <Row className='pt-2'>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div><span>{t('misProyectosScreen:Project')}</span></div>
                  <div className="p-fluid">  
                    <FieldDropdown
                      name="ClaProyecto" 
                      value={ClaProyecto}
                      options={ProyectoList}
                      optionValue="ClaProyecto"
                      optionLabel="NomProyecto"
                      onChange={(e) => onChangeProyecto(e)}
                      errors={errors}
                    />
                  </div>
                </Col>
              </Row>
              <Row className='pt-2'>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div><span>{t('misProyectosScreen:KPI')}</span></div>
                  <div className="p-fluid">  
                    <FieldDropdown
                      name="IdProyectoKPIManagement" 
                      value={IdProyectoKPIManagement}
                      options={ProyectoKPIManagementList}
                      optionValue="IdProyectoKPIManagement"
                      optionLabel="NomProyectoKPIManagement"
                      onChange={(e) => onChangeProyectoKPIManagement(e)}
                      errors={errors}
                    />
                  </div>
                </Col>
              </Row>
            </>
            )
        }
        
        
      </Dialog>
    </>
  );
};
 

export default KPIFormulaSetUp;

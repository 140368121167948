import { React, UseState, UseEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PageContent from 'layouts/PageContent';
import SteelTrackMonitor from './SteelTrackMonitor';

const SteelTrackMonitorScreen = (props) => {
  const {t, i18n} = useTranslation(['salesPerformance', 'common']);

  return (
    <PageContent title={t('salesPerformance:SteelTrackMonitor')}>
      <SteelTrackMonitor />
    </PageContent>
  )
}

export default SteelTrackMonitorScreen;
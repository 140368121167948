/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'; 
import { useTranslation } from 'react-i18next'; 
import PageContent from '../../../layouts/PageContent';  
import { Row, Col , 
    ListGroupItem,
    ListGroup,
    Progress,} from 'reactstrap';  
import HomeMenuCard from '../../../components/Cards/HomeMenuCard/HomeMenuCard';
import WeAreMCSW from '../../../assets/img/WeAreMCSW.png';
import ListItem from '../../../components/ListGroup/ListItem.jsx'
import MyPerformanceMenu from './MyPerformanceMenu.jsx';
import HrDocumentsMenu from './HrDocumentsMenu';
import CommunicationMenu from './CommunicationMenu';
import WeAreMCSWMenu from './WeAreMCSWMenu'; 
import ProgressReportList from '../../ScoreCard/Projects/ProgressReportList.jsx'; 
import ProyectoInvolucrados from '../../ScoreCard/Projects/ProyectoInvolucrados.jsx'; 
 

const HRHome = (props) => { 
  
 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);
   
 

    return (
      <>  
        <PageContent title={t('hr:HomeHRTitle')}>  
          <Row className='align-items-start'>
            <Col lg={6} md={6} sm={12} xs={12}>   
              <MyPerformanceMenu></MyPerformanceMenu>
              <CommunicationMenu></CommunicationMenu>
              <WeAreMCSWMenu></WeAreMCSWMenu>
            </Col> 
            <Col rowSpan={3} lg={6} md={6} sm={12} xs={12}>   
              <HrDocumentsMenu></HrDocumentsMenu> 
            </Col> 
          </Row>
        </PageContent>
      </>  
        ); 
};

export default HRHome;

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { config } from 'utils/config';
import { callApi } from 'utils/utils';
import './CompanyOverviewActiveCellsChart.scss';
import CompanyOverviewActiveCellsChartSkeleton from './CompanyOverviewActiveCellsChartSkeleton';

const CompanyOverviewActiveCellsChart = (props) => {
  const {t, i18n} = useTranslation(['companyOverview', 'common']);   
  const [Loading, setLoading] = useState(true); 
  const [ChartSeries, setChartSeries] = useState([]);
  const [Report, setReport] = useState(null);
  const chartOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      height: 150
    },
    title: {      
      text: '',
    }, 
    subtitle: {
      verticalAlign: 'middle',
      floating: true,
      useHTML: true,
      text: `${Report? Report.PercentageTotal: 0} %`,
    },    
    tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        },
        showInLegend: false,
      },
      series: {
        borderWidth: 0,
        colorByPoint: true,
        type: 'pie',
        size: '100%',
        innerSize: '60%',
        dataLabels: {
            enabled: true,
            crop: false,
            distance: '-10%',
            style: {
                fontWeight: 'bold',
                fontSize: '16px'
            },
            connectorWidth: 0
        }
      }
    },
    series: [{
      name: '',
      colorByPoint: true,
      data: ChartSeries
    }]
  };

  const getReport = (
    ReportType,
    Shifts,
    StartDate,
    EndDate
  ) => {
    setLoading(true);
    const url = `${config.UrlApiProject}Operations/GetCompanyOverviewActiveCells`;       
    const paramsService = {
      ReportType,
      Shifts,
      StartDate: StartDate.toDateString(),
      EndDate: EndDate.toDateString()
    };
    callApi(url, 'POST', paramsService, (response) => {            
      setReport(response.data.CompanyOverviewActiveCells);          
      setupChart(response.data.CompanyOverviewActiveCells);
      setLoading(false);
    }, (error) => {
      setLoading(false);
      console.error("Error getting company overview active cells.", error);
    }, false, false);
  };

  const setupChart = (report) => {    
    const series = [];    
    if (report) {            
      series.push({
        name: t('companyOverview:Active'),
        color: 'limegreen',
        y: report.ActivePercentage,
      });                      
      series.push({
        name: t('companyOverview:Down'),
        color: 'red',
        y: report.DownPercentage,
      });   
      series.push( {
        name: t('companyOverview:Other'),
        color: 'lightgrey',
        y: report.OtherPercentage
      });     
    }        
    setChartSeries(series);    
  };

  useEffect(() => {      
    if (props.reportType && props.shifts && props.startDate && props.endDate) {
      getReport(props.reportType, props.shifts, props.startDate, props.endDate);   
    }    
  }, [
    props.refresh
  ]);

  const summaryTemplate = (
    <>
      <Row className='align-items-start'>
        <Col xs={8}>
          <h5>
            {t('companyOverview:ActiveCells')}
          </h5>      
          {
            Report && (
              <h1>
                {`${Report.ActiveCount}/${Report.ActiveCount + Report.DownCount + Report.OtherCount}`}
              </h1>                 
            )
          }          
        </Col>
        <Col 
          xs={4} 
          className='text-right'
        >
          <span className="p-badge CompanyOverviewActiveCellsChart-liveBadge"> 
            {t('companyOverview:Live')}
          </span>
        </Col>
      </Row>  
    </>
  );

  const chartTemplate = (
    <>      
      {
        Report && (
          <HighchartsReact
            highcharts={Highcharts} 
            options={chartOptions} 
          />    
        )
      }   
    </>
  );

  const leyendsTemplate = (
    <>
      {
        Report && (
          <>
            <Row className='pb-2'> 
              <Col xs={6}>
                {t('companyOverview:Active')}
              </Col>
              <Col xs={6}>
                <span 
                  className="p-badge success"
                  style={{width: '100%'}}
                > 
                  {Report.ActiveCount}
                </span>
              </Col>
            </Row>
            <Row className='pb-2'>
              <Col xs={6}>
                {t('companyOverview:Down')}
              </Col>
              <Col xs={6}>
                <span 
                  className="p-badge danger"
                  style={{width: '100%'}}
                > 
                  {Report.DownCount}
                </span>
              </Col>
            </Row>
            <Row className='pb-2'>
              <Col xs={6}>
                {t('companyOverview:Other')}
              </Col>
              <Col xs={6}>
                <span 
                  className="p-badge"
                  style={{backgroundColor: 'lightgrey', color: 'white', width: '100%'}}
                > 
                  {Report.OtherCount}
                </span>
              </Col>
            </Row>                 
          </>              
        )
      }     
    </>
  );

  return (
    <>
      {
        Loading? <CompanyOverviewActiveCellsChartSkeleton /> : (
          <Card 
            className='CompanyOverviewActiveCellsChart'
            style={{borderRadius: '1%', height: '100%'}}
          >    
            <CardBody>
              <Row className='align-items-start'>
                <Col xs={12}> 
                  {summaryTemplate}
                </Col>
              </Row>
              <Row className='align-items-start'>
                <Col xs={12}>
                  {chartTemplate}                     
                </Col>
              </Row>
              <Row className='align-items-start'>
                <Col xs={12}>
                  {leyendsTemplate}                     
                </Col>
              </Row>
            </CardBody>
          </Card>  
        )
      }      
    </>
  )
};

export default CompanyOverviewActiveCellsChart
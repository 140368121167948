/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi } from '../../../utils/utils';
import FieldDropdown from 'components/Controls/FieldDropdown.jsx';
import PageContent from 'layouts/PageContent.jsx';
import InstructionsCard from '../../../components/Cards/InstructionsCard/InstructionsCard.jsx';
import IncentiveProgramManualAdjustmentsRep from './IncentiveProgramManualAdjustmentsRep.jsx';

const IncentiveProgramManualAdjustmentsScreen = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['salesPerformance', 'common']); 

  const [ClaAnio, setClaAnio] = useState(null);  
  const [AniosList, setAniosList] = useState([]);
 
  useEffect(() => { 
    GetAniosCmb();
  }, []);
  
  const GetAniosCmb = () => {
    const GetAniosCmb = `${config.UrlApiProject}SalesPerformanceSales/GetAniosCmb`;
    const paramsToService = {};
    callApi(GetAniosCmb, 'GET', paramsToService, (response) => {
      setAniosList(response.data.Anios); 
    });
  };

  return (
    <>
      <PageContent title={t('incentiveProgramManualAdjustments:incentiveProgramManualAdjustments')}> 
        <Row>
          <Col lg={3} md={3} sm={12} xs={12} className="p-3">
            <div className="p-fluid">
              <FieldDropdown  
                name="ClaAnio" 
                label={t('incentiveProgramManualAdjustments:Year')}
                value={ClaAnio} 
                options={AniosList}  
                optionValue="ClaAnio"
                optionLabel="NomAnio" 
                onChange={(e) => setClaAnio(e.value === undefined ?  null : e.value)}  
              />  
            </div>
          </Col>
        </Row>
        <Row hidden={ClaAnio === null}>
          <Col>            
            <IncentiveProgramManualAdjustmentsRep ClaAnio={ClaAnio}></IncentiveProgramManualAdjustmentsRep>
          </Col>
        </Row> 
      </PageContent>
    </>
  );
};
export default IncentiveProgramManualAdjustmentsScreen;

import React, {useState, useEffect, useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Label, Card, CardBody } from 'reactstrap';
import { callApi, showSweetAlert } from "utils/utils";
import { config } from 'utils/config';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import FieldText from 'components/Controls/FieldText';
import YupValidator from 'utils/YupValidator';
import * as yup from 'yup';
import './GantryBoxLookup.scss';

const GantryBoxLookup = (props) => {
  const {t, i18n} = useTranslation(['operations', 'common']);

  const [barcode, setBarcode] = useState('');
  const [prevBarcode, setPrevBarcode] = useState('');
  const [boxData, setBoxData] = useState(null);
  const [showError, setShowError] = useState(false);
  const [showData, setShowData] = useState(false);
  const [errors, setErrors] = useState(null);
  const [focus, setFocus] = useState(false);

  const barcodeFieldRef = useRef(null);

  useEffect(() => {
    const onKeyDown = (e) => {
      if(e.keyCode === 9 || e.keyCode === 13) {
        getBarcodeData();
      }
    }
    
    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [barcode])

  useEffect(() => {
    barcodeFieldRef.current.focus();
  }, [focus])

  const getBarcodeData = async () => {
    const validationScheme = yup.object({
      barcode: yup.string().min(1).required('').nullable()
    })

    const value = {
      barcode
    }

    const validator = new YupValidator(validationScheme);
    const isValid = await validator.validate(value);

    if (!isValid) {
      setErrors(validator.errors);
    }
    else {
      setShowError(false);
      const urlWebService = `${config.UrlApiProject}Operations/GetGantryBoxData?barcode=${barcode}`;

      const paramsService = {
        barcode
      };
  
      callApi(urlWebService, 'GET', paramsService, (response) => {
        if(response.data.length > 0) {
          setBoxData(response.data[0]);
          setShowError(false);
          setShowData(true);
        }
        else {
          setShowError(true);
          setShowData(false);
        }
      });

      setPrevBarcode(barcode);
      setBarcode(null);
    }
    setFocus(!focus);
  }

  const onChangeBarcode = (e) => {
    const newErrors = {...errors};
    delete newErrors.barcode;
    setErrors(newErrors);
    setBarcode(e.target.value);
  }

  return (
    <>
      <div className='p-grid'>
        <div className='p-col-11'>
          <div className='p-field p-fluid'>
            <FieldText
              id='barcode'
              name='barcode'
              value={barcode}
              onChange={onChangeBarcode}
              errors={errors}
              reference={barcodeFieldRef}
              autofocus
            />
          </div>
        </div>
        <div className='p-col-1'>
          <div className='p-field'>
            <FieldButton
              icon='pi pi-search'
              onClick={getBarcodeData}
            />
          </div>
        </div>
        {showData ? (
          <div className='p-col-12'>
            <table className='gantry-box-data'>
              <tr>
                <th>{t('operations:Barcode')}:</th>
                <td>{boxData.barcode}</td>
              </tr>
              <tr>
                <th>{t('operations:Cell')}:</th>
                <td>{boxData.cell_description}</td>
              </tr>
              <tr>
                <th>{t('operations:ProductionLine')}:</th>
                <td>{boxData.production_line}</td>
              </tr>
              <tr>
                <th>{t('operations:SKU')}:</th>
                <td>{boxData.sku}</td>
              </tr>
              <tr>
                <th>{t('operations:ItemCode')}:</th>
                <td>{boxData['label sku']}</td>
              </tr>
              <tr>
                <th>{t('common:Description')}:</th>
                <td>{boxData.description}</td>
              </tr>
              <tr>
                <th>{t('common:Timestamp')}:</th>
                <td>{boxData.timestamp}</td>
              </tr>
              <tr>
                <th>{t('operations:Operator')}:</th>
                <td>{boxData.operator}</td>
              </tr>
            </table>
          </div>
        ) : showError ? (
          <div className='p-col-12'>
            <span style={{fontSize: 'large'}}>No data was found for <span style={{fontWeight: 'bold'}}>{prevBarcode}</span></span>
          </div>
        ) : <></>}
      </div>
    </>
  );
}

export default GantryBoxLookup;
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import FieldRadioButton from 'components/Controls/FieldRadioButton/FieldRadioButton';
import FieldTextArea from 'components/Controls/FieldTextArea';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardHeader, CardBody, } from 'reactstrap';
import { idpSelfReflectionPurposeTypes, idpStatus, idpVersions } from './IndividualDevelopmentPlanConstants';
import { Tooltip } from 'primereact/tooltip';
import { Dialog } from 'primereact/dialog';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import { Avatar } from 'primereact/avatar';
import FieldCalendar from 'components/Controls/FieldCalendar';
import moment from 'moment';
import FieldText from 'components/Controls/FieldText';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import { Column } from 'primereact/column';
import { config } from 'utils/config';
import { callApi, showSweetAlert } from 'utils/utils';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldUpload from 'components/Controls/FieldUpload/FieldUpload';
import FieldSelectButton from 'components/Controls/FieldSelectButton';
import FieldCheckbox from 'components/Controls/FieldCheckbox';
import { ScrollPanel } from 'primereact/scrollpanel';

/**
 * IdIDP
 * @param {*} props 
 * props:
 * - isNew
 * - idIDP
 * - year
 * - reportType (M = Myself, T = Team) 
 * - onHide
 * - onSaved
 * - onStatusSaved
 * @returns 
 */
const IndividualDevelopmentPlanFormV2 = (props) => {  
  const { t } = useTranslation(['hr', 'iDPV2', 'common']);
  const [errors, setErrors] = useState({});    
  const [ClaIDPEstatus, setClaIDPEstatus] = useState(0); 
  const [EsDisabled, setEsDisabled] = useState(false);
  const [IDPClase, setIDPClase] = useState({}); 
  const [IdIDP, setIdIDP] = useState(0); 
  const [Year, setYear] = useState(0); 
  const [MotivoDeshacer, setMotivoDeshacer] = useState(null); 
  const [EsDiscutirGerente, setEsDiscutirGerente] = useState(false); 
  const [EsJefeInmediato, setEsJefeInmediato] = useState(false);  
  const [ComentariosRevisado, setComentariosRevisado] = useState(null);  
  const [ComentariosRevisadoEmpleado, setComentariosRevisadoEmpleado] = useState('');    
  const [ComentariosRevisadoEmpleadoOriginal, setComentariosRevisadoEmpleadoOriginal] = useState('');   
  const [EsPlanRevisado, setEsPlanRevisado] = useState(false);  
  const [LearningMethods, setLearningMethods] = useState([]);
  const [NotCompletedReasons, setNotCompletedReasons] = useState([]);
  const [ShowHelpDialog, setShowHelpDialog] = useState(false);
  const [HelpDialogHeaderText, setHelpDialogHeaderText] = useState('');
  const [HelpDialogBodyTemplate, setHelpDialogBodyTemplate] = useState(null);
  // self reflection
  const [SelfReflectionPurposeID, setSelfReflectionPurposeID] = useState(idpSelfReflectionPurposeTypes.NEW_TO_MY_ROLE);
  const [SelfReflectionPurposeAnswer1_1, setSelfReflectionPurposeAnswer1_1] = useState('');
  const [SelfReflectionPurposeAnswer1_2, setSelfReflectionPurposeAnswer1_2] = useState('');
  const [SelfReflectionPurposeAnswer2_1, setSelfReflectionPurposeAnswer2_1] = useState('');
  const [SelfReflectionPurposeAnswer2_2, setSelfReflectionPurposeAnswer2_2] = useState('');
  const [SelfReflectionPurposeAnswer3_1, setSelfReflectionPurposeAnswer3_1] = useState('');
  const [SelfReflectionPurposeAnswer3_2, setSelfReflectionPurposeAnswer3_2] = useState('');
  const [SelfReflectionPurposeAnswer4_1, setSelfReflectionPurposeAnswer4_1] = useState('');
  const [SelfReflectionPurposeAnswer4_2, setSelfReflectionPurposeAnswer4_2] = useState('');
  const [SelfReflectionPurposeAnswer5_1, setSelfReflectionPurposeAnswer5_1] = useState('');
  // step 1  
  const [Step1Answer1SetYourGoal, setStep1Answer1SetYourGoal] = useState('');  
  const [Step1Answer1bStartDate, setStep1Answer1bStartDate] = useState(null);  
  const [Step1Answer1bEndDate, setStep1Answer1bEndDate] = useState(null);  
  // step 2
  const [Step2DevelpmentAction, setStep2DevelpmentAction] = useState('');
  const [AccionesLista, setAccionesLista] = useState([]); 
  const [IDPAcciones, setIDPAcciones] = useState([]); 
  const [Step2ActionFilters, setStep2ActionFilters] = useState({});   
  const Setep2ActionsDTRef = useRef(null);   
  const [supportDucumentCourseDatafiles, setSupportDucumentCourseDatafiles] = useState([]);  
  const [Step2ActionsComments, setStep2ActionsComments] = useState('');
  const Step2AddDevelpmentActionStepValidationSchema = yup.object().shape({
    Step2DevelpmentAction: yup.string().required(t('common:RequiredField')).nullable()
  });
  const Step2AddDevelpmentActionsValidationSchema = yup.object().shape({
    IDPAcciones: yup.array().of(
      yup.object().shape({  
        NomAccion: yup.string().when('BajaLogica', {is: 0,
          then: yup.string().required(t('common:RequiredField')).nullable(),
          otherwise: yup.string().nullable()
        }),
        ClaMetodoAprendizaje: yup.string().when('BajaLogica', {is: 0,
          then: yup.string().required(t('common:RequiredField')).nullable(),
          otherwise: yup.string().nullable()
        })       
      })
    )
  });

  const [OpcionesIDPCompetencia, setOpcionesIDPCompetencia] = useState([]); 
  const [Step2SelectedCompetences, setStep2SelectedCompetences] = useState([]);  
  // step 3
  const [OpcionesYesNo, setOpcionesYesNo] = useState([{ value: false, name: t('common:No') },{ value: true, name: t('common:Yes')}]);
  const valitationSchemeCompleted = yup.object().shape({
    IDPAcciones: yup.array().of(
      yup.object().shape({  
        FechaFinalReal: yup.date().when('EsAccionCompletada', {is: true,
          then: yup.date().required(t('common:RequiredField')).nullable(),
          otherwise: yup.date().nullable()
        })
      })
    )
  });  
  // Step 4
  const valitationSchemeManagerReview = yup.object().shape({
    ComentariosRevisado: yup.string().required(t('common:RequiredField')).nullable()
  });
  const reviewOptions = {
    APPROVE: 1,
    REQUEST_ADDITIONAL_INFORMATION: 2
  };
  const [Step4ReviewOptionSelected, setStep4ReviewOptionSelected] = useState(reviewOptions.REQUEST_ADDITIONAL_INFORMATION);
  // Step 5
  const [Step5GoalCompleted, setStep5GoalCompleted] = useState(null);
  const [Step5GoalCompletedDate, setStep5GoalCompletedDate] = useState(null);  
  const [Step5NotCompletedReasonID, setStep5NotCompletedReasonID] = useState(null);  
  const [Step5LessonsLearned, setStep5LessonsLearned] = useState('');  
  const [Step5HasCertificateDocument, setStep5HasCertificateDocument] = useState(true);
  const [supportDucumentCertificateDatafiles, setSupportDucumentCertificateDatafiles] = useState([]); 
  const Step5CompleteValidationScheme = yup.object().shape({
    IDPAcciones: yup.array().of(
      yup.object().shape({  
        EsAccionCompletada: yup.boolean().required(t('common:RequiredField')).nullable(),
        FechaFinalReal: yup.date().when('EsAccionCompletada', {is: true,
          then: yup.date().required(t('common:RequiredField')).nullable(),
          otherwise: yup.date().nullable()
        })
      })
    ),
    Step5GoalCompleted: yup.boolean().required(t('common:RequiredField')).nullable(),
    Step5GoalCompletedDate: yup.date().when('Step5GoalCompleted', {is: true,
      then: yup.date().required(t('common:RequiredField')).nullable(),
      otherwise: yup.date().nullable()
    }),
    Step5NotCompletedReasonID: yup.date().when('Step5GoalCompleted', {is: false,
      then: yup.date().required(t('common:RequiredField')).nullable(),
      otherwise: yup.date().nullable()
    }),
    // SupportDucumentCertificate: yup.array().min(1, t('common:RequiredField')),      
    SupportDucumentCertificate: yup.array().when('Step5HasCertificateDocument', {is: true,
      then: yup.array().min(1, t('common:RequiredField')).nullable(),
      otherwise: yup.array().nullable()
    }),
    Step5LessonsLearned: yup.string().required(t('common:RequiredField')).nullable()
  });  

  useEffect(() => {    
    clear();
    loadIDPLearningMethods();
    loadIDPNotCompletedReasons();
    loadCompetenciaCmb();
    if (!props.isNew && props.idIDP) {
      getIdpDetalle();
    }  
  },  [    
    props.isNew,
    props.idIDP
  ]); 

  // #region Methods

  const getIdpDetalle = () => { 
    const GetServiceIDP = `${config.UrlApiProject}hr/GetIndividualDevelopmentPlanDetail`;  
    const paramsToService = { 
      IdIDP: props.idIDP,      
      Idioma: '',      
      Anio: null,
      TipoReporte: props.reportType
    };   
    callApi(GetServiceIDP, 'POST', paramsToService, (response) => {
      const dataIDP =  response.data.IDPLista[0];
      const dataAccionesIDP =  response.data.IDPAccionesLista;
      setEsDisabled(false);
      setErrors({});   
      if(dataIDP!==undefined){
        setIDPClase(dataIDP);
        setIdIDP(dataIDP.IdIDP);
        setYear(dataIDP.AnioIDP);
        setEsJefeInmediato(dataIDP.EsjefeInmediato===1);
        setClaIDPEstatus(dataIDP.ClaIDPEstatus);
        setEsDiscutirGerente(dataIDP.EsDiscutirGerente);
        setEsPlanRevisado(dataIDP.EsPlanRevisado);
        setComentariosRevisado(dataIDP.ComentariosRevisado);        
        setComentariosRevisadoEmpleadoOriginal(dataIDP.ComentariosRevisadoEmpleado);
        if (
          (dataIDP.EsjefeInmediato===0) &&
          (
            dataIDP.ClaIDPEstatus === idpStatus.DRAFT ||
            dataIDP.ClaIDPEstatus === idpStatus.ADDITIONAL_INPUT_NEEDED 
          )
        ) {
          setComentariosRevisadoEmpleado('');
        } else {
          setComentariosRevisadoEmpleado(dataIDP.ComentariosRevisadoEmpleado);
        }                
        setMotivoDeshacer(dataIDP.MotivoDeshacer);
        setAccionesLista(dataAccionesIDP);
        setIDPAcciones(dataAccionesIDP);        
        setSelfReflectionPurposeID(dataIDP.ClaProposito);
        setSelfReflectionPurposeAnswer1_1(dataIDP.RespuestaProposito1_1);
        setSelfReflectionPurposeAnswer1_2(dataIDP.RespuestaProposito1_2);
        setSelfReflectionPurposeAnswer2_1(dataIDP.RespuestaProposito2_1);
        setSelfReflectionPurposeAnswer2_2(dataIDP.RespuestaProposito2_2);
        setSelfReflectionPurposeAnswer3_1(dataIDP.RespuestaProposito3_1);
        setSelfReflectionPurposeAnswer3_2(dataIDP.RespuestaProposito3_2);
        setSelfReflectionPurposeAnswer4_1(dataIDP.RespuestaProposito4_1);
        setSelfReflectionPurposeAnswer4_2(dataIDP.RespuestaProposito4_2);
        setSelfReflectionPurposeAnswer5_1(dataIDP.RespuestaProposito5_1);
        setStep1Answer1SetYourGoal(dataIDP.ObjetivoSmart);        
        setStep1Answer1bStartDate(new Date(dataIDP.FechaInicioObjetivo));
        setStep1Answer1bEndDate(new Date(dataIDP.FechaFinObjetivo));
        setStep2ActionsComments(dataIDP.ComentariosAcciones);
        setStep2SelectedCompetences(dataIDP.ClaCompetencias?dataIDP.ClaCompetencias.split(','):[]);
        setStep5GoalCompleted(dataIDP.ObjetivoCompletado);
        setStep5GoalCompletedDate(dataIDP.FechaObjetivoCompletado);
        setStep5NotCompletedReasonID(dataIDP.ClaRazonNoCompletado);     
        setStep5LessonsLearned(dataIDP.LeccionesAprendidas);
        const base64Icon = `data:image/${dataIDP.SupportDocumentCourseTipoExtension};base64,${dataIDP.SupportDocumentCourseFileData}`;
        const SupportDucumentCourseFileInfo = [
          {
            FileName: `${dataIDP.SupportDocumentCourseNomArchivo}.${dataIDP.SupportDocumentCourseTipoExtension}`,
            FileData: dataIDP.SupportDocumentCourseFileData,
            objectURL: base64Icon,
            FileSize: dataIDP.SupportDocumentCourseFileSize,
            FileExtension: dataIDP.SupportDocumentCourseExtension,
          },
        ];
        setSupportDucumentCourseDatafiles(dataIDP.SupportDocumentCourseFileData === null ? [] : SupportDucumentCourseFileInfo);
        const base64Icon2 = `data:image/${dataIDP.SupportDocumentCertificateTipoExtension};base64,${dataIDP.SupportDocumentCertificateFileData}`;
        const SupportDucumentCertificateFileInfo = [
          {
            FileName: `${dataIDP.SupportDocumentCertificateNomArchivo}.${dataIDP.SupportDocumentCertificateTipoExtension}`,
            FileData: dataIDP.SupportDocumentCertificateFileData,
            objectURL: base64Icon2,
            FileSize: dataIDP.SupportDocumentCertificateFileSize,
            FileExtension: dataIDP.SupportDocumentCertificateExtension,
          },
        ];
        setSupportDucumentCertificateDatafiles(dataIDP.SupportDocumentCertificateFileData === null ? [] : SupportDucumentCertificateFileInfo);
      
        if( (dataIDP.ClaIDPEstatus!==1 && dataIDP.ClaIDPEstatus!==4 ) || dataIDP.EsUsuarioIDP === 0){
          setEsDisabled(true); 
        }
      }
    });
  };

  const loadIDPLearningMethods = () => { 
    const getIndividualDevelopmentPlanLearningMethodsURL = `${config.UrlApiProject}hr/GetIndividualDevelopmentPlanLearningMethods?Idioma=English}`;  
    const paramsToService = { };   
    callApi(getIndividualDevelopmentPlanLearningMethodsURL, 'GET', paramsToService, (response) => {       
      if (Array.isArray(response.data.IDPLearningMethods)) { 
        setLearningMethods(response.data.IDPLearningMethods);        
      }       
    }, (error) => {
      console.error("Error gettin IDP learning methods" ,error);
    });
  };

  const loadCompetenciaCmb = () => { 
    const GetServiceIDP = `${config.UrlApiProject}hr/GetCompetenciaIndividualDevelopmentPersonal?Version=${idpVersions.V2}&Idioma=`;  
    const paramsToService = { };   
    callApi(GetServiceIDP, 'GET', paramsToService, (response) => { 
      if (response.data.CompetenciaIDPInfo.length > 0)
      { 
        setOpcionesIDPCompetencia(response.data.CompetenciaIDPInfo);
      }       
    });
  };

  const loadIDPNotCompletedReasons = () => { 
    const getIndividualDevelopmentPlanNotCompletedReasonsURL = `${config.UrlApiProject}hr/GetIndividualDevelopmentPlanNotCompletedReasons?Idioma=English}`;  
    const paramsToService = { };   
    callApi(getIndividualDevelopmentPlanNotCompletedReasonsURL, 'GET', paramsToService, (response) => {       
      if (Array.isArray(response.data.IDPNotCompletedReasons)) { 
        setNotCompletedReasons(response.data.IDPNotCompletedReasons);        
      }       
    }, (error) => {
      console.error("Error gettin IDP not completed reasons" ,error);
    });
  };  

  const clear = () => {
    setIdIDP(0);
    setSelfReflectionPurposeID(idpSelfReflectionPurposeTypes.NEW_TO_MY_ROLE);
    setSelfReflectionPurposeAnswer1_1('')
    setSelfReflectionPurposeAnswer1_2('')
    setSelfReflectionPurposeAnswer2_1('')
    setSelfReflectionPurposeAnswer2_2('')
    setSelfReflectionPurposeAnswer3_1('')
    setSelfReflectionPurposeAnswer3_2('')
    setSelfReflectionPurposeAnswer4_1('')
    setSelfReflectionPurposeAnswer4_2('')     
    setSelfReflectionPurposeAnswer5_1('')    
    setStep1Answer1SetYourGoal('')    
    setStep1Answer1bStartDate(null);
    setStep1Answer1bEndDate(null);
    setStep2DevelpmentAction('');
    setAccionesLista([]);
    setIDPAcciones([]);
    setStep2ActionFilters({});
    setStep2ActionsComments('');
    setSupportDucumentCourseDatafiles([]);
    setSupportDucumentCertificateDatafiles([]);
    setYear(props.year);
  };

  const showHelpDialog = (header, template) => {   
    setHelpDialogHeaderText(header);
    setHelpDialogBodyTemplate(template);
    setShowHelpDialog(true);    
  };

  const showActiveRows = (rows) => {
    const activeRows = [];
    if (rows.length>0) {
      rows.forEach((item, index) => {
        if (item.BajaLogica === 0) {
          item.RowIndex = index;
          activeRows.push({...item});
        }
      });
    } else {
      return [];
    }
    return activeRows; 
  };

  const onStep4ManagerReviewSubmit = () => {
    setEsPlanRevisado(true);
    if (Step4ReviewOptionSelected === reviewOptions.REQUEST_ADDITIONAL_INFORMATION) {      
      saveStatus(idpStatus.ADDITIONAL_INPUT_NEEDED);
    } else if (Step4ReviewOptionSelected === reviewOptions.APPROVE) {
      saveStatus(idpStatus.IN_PROGRESS);
    }
  };

  const saveStatus = async (ClaEstatus) => {
    let value = {
      ComentariosRevisado
    }
    let validator;
    let esValido;

    // Manager: Additional input needed or Accepted (In progress)

    if (
      EsJefeInmediato && (
        ClaEstatus === idpStatus.ADDITIONAL_INPUT_NEEDED ||
        ClaEstatus === idpStatus.IN_PROGRESS
      )
    )
    {
      validator = new YupValidator(valitationSchemeManagerReview);
      esValido = await validator.validate(value);
      if (!esValido){ 
        setErrors(validator.errors);
        showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
        return;
      }
    }

    // Employee: Update progress 

    if (
      !EsJefeInmediato &&
      ClaEstatus === idpStatus.IN_PROGRESS
    )
    {
      value = {
        IDPAcciones: showActiveRows(IDPAcciones)   
      }
      validator = new YupValidator(valitationSchemeCompleted);
      esValido = await validator.validate(value);
      if (!esValido){ 
        setErrors(validator.errors);
        showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
        return;
      }
    }

    // Employee: Complete

    if (
      !EsJefeInmediato &&
      ClaEstatus === idpStatus.COMPLETED
    )
    {      
      value = {
        IDPAcciones: showActiveRows(IDPAcciones),
        Step5GoalCompleted,
        Step5GoalCompletedDate,
        Step5NotCompletedReasonID,
        Step5LessonsLearned,
        Step5HasCertificateDocument,
        SupportDucumentCertificate: supportDucumentCertificateDatafiles
      }            
      validator = new YupValidator(Step5CompleteValidationScheme);     
      esValido = await validator.validate(value);
      if (!esValido){         
        setErrors(validator.errors);
        showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
        return;
      }
    }

    // Save

    const urlWebServiceSave = `${config.UrlApiProject}hr/SaveStatusIndividualDevelopmentPlanV2`;
    const paramsService = {
      IdIDP,
      ClaIDPEstatus: ClaEstatus,
      ComentariosRevisado,
      IndividualDevelopmentPersonalAcciones: IDPAcciones,
      ComentariosAcciones: Step2ActionsComments,
      ObjetivoCompletado: Step5GoalCompleted,
      FechaObjetivoCompletado: Step5GoalCompleted? Step5GoalCompletedDate: null,
      ClaRazonNoCompletado: !Step5GoalCompleted? Step5NotCompletedReasonID: null,
      LeccionesAprendidas: Step5LessonsLearned,
      SupportDocumentCertificateFileData:(supportDucumentCertificateDatafiles.length > 0 ? supportDucumentCertificateDatafiles[0].FileData : null),
      SupportDocumentCertificateNomArchivo:(supportDucumentCertificateDatafiles.length > 0 ? supportDucumentCertificateDatafiles[0].FileName: null),
      SupportDocumentCertificateExtension:(supportDucumentCertificateDatafiles.length > 0 ? supportDucumentCertificateDatafiles[0].FileExtension: null) 
    }; 
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {
      showSweetAlert(t('common:Success'), t('common:SaveSuccess'), 'success');      
      if (props.onStatusSaved && typeof props.onStatusSaved === 'function') {
        props.onStatusSaved();
      }
    });
  };

  const save = async () => {
    let validationShape = {};

    // Self reflection validators
    console.log("SelfReflectionPurposeID", SelfReflectionPurposeID);
    switch(SelfReflectionPurposeID) {
      case idpSelfReflectionPurposeTypes.NEW_TO_MY_ROLE:        
        validationShape = { ...validationShape, ...{
          SelfReflectionPurposeAnswer1_1: yup.string().required(t('common:RequiredField')).nullable(),
          SelfReflectionPurposeAnswer1_2: yup.string().required(t('common:RequiredField')).nullable()
        }};
        break;
      case idpSelfReflectionPurposeTypes.CURRENT_DEVELOPMENT:
        validationShape = { ...validationShape, ...{
          SelfReflectionPurposeAnswer2_1: yup.string().required(t('common:RequiredField')).nullable(),
          SelfReflectionPurposeAnswer2_2: yup.string().required(t('common:RequiredField')).nullable()
        }};
        break;   
      case idpSelfReflectionPurposeTypes.FUTURE_PREPARATION:
        validationShape = { ...validationShape, ...{
          SelfReflectionPurposeAnswer3_1: yup.string().required(t('common:RequiredField')).nullable(),
          SelfReflectionPurposeAnswer3_2: yup.string().required(t('common:RequiredField')).nullable()
        }};
        break;   
      case idpSelfReflectionPurposeTypes.RECOMMENDED_BY_SUPERVISOR:
        validationShape = { ...validationShape, ...{
          SelfReflectionPurposeAnswer4_1: yup.string().required(t('common:RequiredField')).nullable(),
          SelfReflectionPurposeAnswer4_2: yup.string().required(t('common:RequiredField')).nullable()
        }};      
        break;     
      case idpSelfReflectionPurposeTypes.NOT_PARTICIPATE:
        validationShape = { ...validationShape, ...{
          SelfReflectionPurposeAnswer5_1: yup.string().required(t('common:RequiredField')).nullable()          
        }};      
        break;    
      default:
        break; 
    };

    // Step 1 validators

    if (SelfReflectionPurposeID !== idpSelfReflectionPurposeTypes.NOT_PARTICIPATE) {
      validationShape = {
        ...validationShape, 
        ...{      
          Step1Answer1SetYourGoal: yup.string().required(t('common:RequiredField')).nullable(),      
          Step2SelectedCompetences: yup.array()
            .min(1, '')
            .max(3, '')
            .required(t('common:RequiredField')),      
          Step1Answer1bStartDate: yup.string().required(t('common:RequiredField')).nullable(),
          Step1Answer1bEndDate: yup.string().required(t('common:RequiredField')).nullable()
        }
      };
    }

    // Step 2 validators

    const iDPActions = IDPAcciones;
    let actionsAreValid = true; 
    if (
      EsDiscutirGerente && 
      SelfReflectionPurposeID !== idpSelfReflectionPurposeTypes.NOT_PARTICIPATE
    ) {            
      if (showActiveRows(iDPActions).length === 0) {                
        actionsAreValid = false;
        setStep2DevelpmentAction('');
        validationShape = { ...validationShape, ...{
          Step2DevelpmentAction: yup.string().required(t('common:RequiredField')).nullable()
        }};
      } else if (showActiveRows(iDPActions).length > 0) {        
        const actionsValidator = new YupValidator(Step2AddDevelpmentActionsValidationSchema);
        actionsAreValid = await actionsValidator.validate({
          IDPAcciones: iDPActions
        });
        if (!actionsAreValid) {          
          setStep2DevelpmentAction('');
          validationShape = { ...validationShape, ...{
            Step2DevelpmentAction: yup.string().required(t('common:RequiredField')).nullable()
          }};          
        }
      }        
    } else if (SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.NOT_PARTICIPATE) {      
      iDPActions.forEach((action) => {
        action.BajaLogica = 1;
      });
    }

    // Step 3 validators
    
    if (EsPlanRevisado && EsDiscutirGerente) {      
      validationShape = { ...validationShape, ...{
        ComentariosRevisadoEmpleado: yup.string().required(t('common:RequiredField')).nullable()
      }};
    }    

    // Validation

    const valitationSchemeSave = yup.object().shape(validationShape);  
    const validator = new YupValidator(valitationSchemeSave);
    const value = {
      SelfReflectionPurposeAnswer1_1: SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.NEW_TO_MY_ROLE? SelfReflectionPurposeAnswer1_1: '',
      SelfReflectionPurposeAnswer1_2: SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.NEW_TO_MY_ROLE? SelfReflectionPurposeAnswer1_2: '',
      SelfReflectionPurposeAnswer2_1: SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.CURRENT_DEVELOPMENT? SelfReflectionPurposeAnswer2_1: '',
      SelfReflectionPurposeAnswer2_2: SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.CURRENT_DEVELOPMENT? SelfReflectionPurposeAnswer2_2: '',
      SelfReflectionPurposeAnswer3_1: SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.FUTURE_PREPARATION? SelfReflectionPurposeAnswer3_1: '',
      SelfReflectionPurposeAnswer3_2: SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.FUTURE_PREPARATION? SelfReflectionPurposeAnswer3_2: '',
      SelfReflectionPurposeAnswer4_1: SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.RECOMMENDED_BY_SUPERVISOR? SelfReflectionPurposeAnswer4_1: '',
      SelfReflectionPurposeAnswer4_2: SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.RECOMMENDED_BY_SUPERVISOR? SelfReflectionPurposeAnswer4_2: '',
      SelfReflectionPurposeAnswer5_1: SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.NOT_PARTICIPATE? SelfReflectionPurposeAnswer5_1: '',
      Step1Answer1SetYourGoal,      
      Step1Answer1bStartDate,
      Step1Answer1bEndDate,
      IDPAcciones: iDPActions,
      Step2SelectedCompetences,
      Step2DevelpmentAction: actionsAreValid? 'valid': '',
      Step2ActionsComments,
      ComentariosRevisadoEmpleado
    };

    const esValido = await validator.validate(value);
    if (!esValido){       
      setErrors(validator.errors); 
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      return;
    }    

    // Save
    
    const urlWebServiceSave = `${config.UrlApiProject}hr/SaveIndividualDevelopmentPlanV2`;
    const paramsService = {
      IdIDP,
      Anio: Year,
      // Self refelction
      ClaProposito: SelfReflectionPurposeID,
      RespuestaProposito1_1: SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.NEW_TO_MY_ROLE? SelfReflectionPurposeAnswer1_1: '',
      RespuestaProposito1_2: SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.NEW_TO_MY_ROLE? SelfReflectionPurposeAnswer1_2: '',
      RespuestaProposito2_1: SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.CURRENT_DEVELOPMENT? SelfReflectionPurposeAnswer2_1: '',
      RespuestaProposito2_2: SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.CURRENT_DEVELOPMENT? SelfReflectionPurposeAnswer2_2: '',
      RespuestaProposito3_1: SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.FUTURE_PREPARATION? SelfReflectionPurposeAnswer3_1: '',
      RespuestaProposito3_2: SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.FUTURE_PREPARATION? SelfReflectionPurposeAnswer3_2: '',
      RespuestaProposito4_1: SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.RECOMMENDED_BY_SUPERVISOR? SelfReflectionPurposeAnswer4_1: '',
      RespuestaProposito4_2: SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.RECOMMENDED_BY_SUPERVISOR? SelfReflectionPurposeAnswer4_2: '',
      RespuestaProposito5_1: SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.NOT_PARTICIPATE? SelfReflectionPurposeAnswer5_1: '',
      // Step 1
      ObjetivoSmart: SelfReflectionPurposeID !== idpSelfReflectionPurposeTypes.NOT_PARTICIPATE? Step1Answer1SetYourGoal: '',                  
      ClaCompetencia: 0,
      ClaCompetencias: (
        SelfReflectionPurposeID !== idpSelfReflectionPurposeTypes.NOT_PARTICIPATE? 
          (Step2SelectedCompetences && Step2SelectedCompetences.length > 0? Step2SelectedCompetences.join(','): null):
          null
      ),
      FechaInicioObjetivo: SelfReflectionPurposeID !== idpSelfReflectionPurposeTypes.NOT_PARTICIPATE? Step1Answer1bStartDate: null,
      FechaFinObjetivo: SelfReflectionPurposeID !== idpSelfReflectionPurposeTypes.NOT_PARTICIPATE? Step1Answer1bEndDate: null,
      // Step 2
      IndividualDevelopmentPersonalAcciones: iDPActions,
      SupportDocumentCourseFileData:(
        SelfReflectionPurposeID !== idpSelfReflectionPurposeTypes.NOT_PARTICIPATE? 
        (supportDucumentCourseDatafiles.length > 0 ? supportDucumentCourseDatafiles[0].FileData : null): 
        null
      ),
      SupportDocumentCourseNomArchivo:(
        SelfReflectionPurposeID !== idpSelfReflectionPurposeTypes.NOT_PARTICIPATE? 
        (supportDucumentCourseDatafiles.length > 0 ? supportDucumentCourseDatafiles[0].FileName: null):
        null
      ),
      SupportDocumentCourseExtension:(
        SelfReflectionPurposeID !== idpSelfReflectionPurposeTypes.NOT_PARTICIPATE? 
        (supportDucumentCourseDatafiles.length > 0 ? supportDucumentCourseDatafiles[0].FileExtension: null):
        null
      ),
      ComentariosAcciones: SelfReflectionPurposeID !== idpSelfReflectionPurposeTypes.NOT_PARTICIPATE? Step2ActionsComments: '',
      // Step 3
      EsDiscutirGerente,
      ComentariosRevisadoEmpleado: EsDiscutirGerente? ComentariosRevisadoEmpleado: '',
      // Step 4   
      EsPlanRevisado,
      // Step 5
      SupportDocumentCertificateFileData:(
        SelfReflectionPurposeID !== idpSelfReflectionPurposeTypes.NOT_PARTICIPATE? 
        (supportDucumentCertificateDatafiles.length > 0 ? supportDucumentCertificateDatafiles[0].FileData : null):
        null
      ),
      SupportDocumentCertificateNomArchivo:(
        SelfReflectionPurposeID !== idpSelfReflectionPurposeTypes.NOT_PARTICIPATE? 
        (supportDucumentCertificateDatafiles.length > 0 ? supportDucumentCertificateDatafiles[0].FileName: null):
        null
      ),
      SupportDocumentCertificateExtension:(
        SelfReflectionPurposeID !== idpSelfReflectionPurposeTypes.NOT_PARTICIPATE? 
        (supportDucumentCertificateDatafiles.length > 0 ? supportDucumentCertificateDatafiles[0].FileExtension: null):
        null
      )       
    }; 
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {
      showSweetAlert(t('common:Success'), t('common:SaveSuccess'), 'success');      
      if (props.onSaved && typeof props.onSaved === 'function') {
        props.onSaved();
      }
    });
  };

  const limitOfSelectedCompetenciesReached = () => {
    return Step2SelectedCompetences.length === 3;
  };

  // #endregion

  // #region Events

  const onHelpDialogHide = () => {
    setHelpDialogHeaderText('');    
    setShowHelpDialog(false);
  };

  const onDownloadDocument = (file) => {     
    const linkSource = `data:application/${file.FileExtension};base64,${file.FileData}`;
    const downloadLink = document.createElement("a"); 
    downloadLink.href = linkSource;
    downloadLink.download = `${file.FileName}`;
    downloadLink.click();
  };  

  const onSelfReflectionPurposeChange = (value) => {
    setSelfReflectionPurposeID(value)
  };

  const onSelfReflectionPurposeAnswer1_1Change = (e) => {
    const newErrors = { ...errors } 
    delete newErrors.SelfReflectionPurposeAnswer1_1;   
    setErrors(newErrors) 
    setSelfReflectionPurposeAnswer1_1(e.target.value);
  };

  const onSelfReflectionPurposeAnswer1_2Change = (e) => {
    const newErrors = { ...errors } 
    delete newErrors.SelfReflectionPurposeAnswer1_2;   
    setErrors(newErrors) 
    setSelfReflectionPurposeAnswer1_2(e.target.value);
  };

  const onSelfReflectionPurposeAnswer2_1Change = (e) => {
    const newErrors = { ...errors } 
    delete newErrors.SelfReflectionPurposeAnswer2_1;   
    setErrors(newErrors) 
    setSelfReflectionPurposeAnswer2_1(e.target.value);
  };

  const onSelfReflectionPurposeAnswer2_2Change = (e) => {
    const newErrors = { ...errors } 
    delete newErrors.SelfReflectionPurposeAnswer2_2;   
    setErrors(newErrors) 
    setSelfReflectionPurposeAnswer2_2(e.target.value);
  };

  const onSelfReflectionPurposeAnswer3_1Change = (e) => {
    const newErrors = { ...errors } 
    delete newErrors.SelfReflectionPurposeAnswer3_1;   
    setErrors(newErrors) 
    setSelfReflectionPurposeAnswer3_1(e.target.value);
  };

  const onSelfReflectionPurposeAnswer3_2Change = (e) => {
    const newErrors = { ...errors } 
    delete newErrors.SelfReflectionPurposeAnswer3_2;   
    setErrors(newErrors) 
    setSelfReflectionPurposeAnswer3_2(e.target.value);
  };

  const onSelfReflectionPurposeAnswer4_1Change = (e) => {
    const newErrors = { ...errors } 
    delete newErrors.SelfReflectionPurposeAnswer4_1;   
    setErrors(newErrors) 
    setSelfReflectionPurposeAnswer4_1(e.target.value);
  };

  const onSelfReflectionPurposeAnswer4_2Change = (e) => {
    const newErrors = { ...errors } 
    delete newErrors.SelfReflectionPurposeAnswer4_2;   
    setErrors(newErrors) 
    setSelfReflectionPurposeAnswer4_2(e.target.value);
  };

  const onSelfReflectionPurposeAnswer5_1Change = (e) => {
    const newErrors = { ...errors } 
    delete newErrors.SelfReflectionPurposeAnswer5_1;   
    setErrors(newErrors) 
    setSelfReflectionPurposeAnswer5_1(e.target.value);
  };

  const onStep1SetYourGoalHelpPress = () => {    
    showHelpDialog(t('iDPV2:Step1SetYourGoalQuestion1Title'), step1SetYourGoalHelpDialogBodyTemplate);
  };

  const onStep1Answer1SetYourGoalChange = (e) => {
    const newErrors = { ...errors } 
    delete newErrors.Step1Answer1SetYourGoal;   
    setErrors(newErrors) 
    setStep1Answer1SetYourGoal(e.target.value);
  };

  const onStep1SkillsToBeDevelopedHelpPress = () => {    
    showHelpDialog(t('iDPV2:Step1SetYourGoalQuestion1aTitle'), step1SkillsToBeDevelopedHelpDialogBodyTemplate);
  };

  const onStep1Answer1bStartDateChange = (e) => {   
    const newErrors = { ...errors } 
    delete newErrors.Step1Answer1bStartDate;   
    setErrors(newErrors) 
    setStep1Answer1bStartDate(e.target.value);
  };

  const onStep1Answer1bEndDateChange = (e) => {   
    const newErrors = { ...errors } 
    delete newErrors.Step1Answer1bEndDate;   
    setErrors(newErrors) 
    setStep1Answer1bEndDate(e.target.value);
  };

  const onStep2DevelopmentActionStepsHelpPress = () => {    
    showHelpDialog(t('iDPV2:Step2DevelopmentActionSteps'), step2DevelopmentActionStepsHelpDialogBodyTemplate);
  };

  const onStep2AddDevelpmentActionClick = async () => {   
    const newRow = { 
      RowIndex: AccionesLista.length,
      rowData:{
        NomAccion:''
      },
      field: 'NomAccion',
      IdAccion: 0,
      BajaLogica: 0,
      FechaInicioEstimada: null,
      FechaFinalEstimada: null,
      FechaFinalReal: null,
      ComentariosSeguimiento: null,
      NomAccion: '',
      ClaMetodoAprendizaje: null,
      NomMetodoAprendizaje: ''      
    }; 
    AccionesLista.push(newRow);
    setAccionesLista(AccionesLista);    
    // setStep2DevelpmentAction('');
    setIDPAcciones(AccionesLista);
    setStep2ActionFilters({ ...Step2ActionFilters, arreglo:AccionesLista, row:newRow.RowIndex});
  };

  const onStep2ActionColumnValidator = (e) => {  
    const { rowData, field } = e.columnProps;
    return rowData[field].trim().length > 0;
  };

  const onStep2LearningMethodColumnValidator = (e) => {    
    const { rowData, field } = e.columnProps;
    return rowData[field] !== null;
  };

  const onStep2ActionColumnChange = (props, value) => {  
    const actions = [...AccionesLista];
    actions[props.rowData.RowIndex][props.field] = value;
    setAccionesLista(AccionesLista);
    setIDPAcciones(AccionesLista);

    const newErrors = { ...errors } 
    delete newErrors.Step2DevelpmentAction;   
    setErrors(newErrors)   
    setStep2DevelpmentAction(''); 

    setStep2ActionFilters({ ...Step2ActionFilters, arreglo:AccionesLista, row:props.rowIndex});    
  }; 

  const onStep2LearningMethodColumnChange = (props, value, e) => {  
    const actions = [...AccionesLista];
    actions[props.rowData.RowIndex][props.field] = value;
    setAccionesLista(AccionesLista);
    setIDPAcciones(AccionesLista);      
    
    const newErrors = { ...errors } 
    delete newErrors.Step2DevelpmentAction;   
    setErrors(newErrors)   
    setStep2DevelpmentAction(''); 

    setStep2ActionFilters({ ...Step2ActionFilters, arreglo:AccionesLista, row:props.rowIndex});    
  }; 

  const onStep2RemoveActionClick = (rowData) => {
    const currentList = [...AccionesLista];
    currentList[rowData.RowIndex].BajaLogica = 1;
    setAccionesLista(AccionesLista);
    setIDPAcciones(AccionesLista);
    setStep2ActionFilters({ ...Step2ActionFilters, arreglo:AccionesLista, row:rowData.RowIndex});
  };

  const onStep2ActionsCommentsChange = (e) => {
    const newErrors = { ...errors } 
    delete newErrors.Step2ActionsComments;   
    setErrors(newErrors)   
    setStep2ActionsComments(e.target.value);
  };

  const onStep2CompetencesChange = (e) => {
    const newErrors = { ...errors } 
    delete newErrors.Step2SelectedCompetences;   
    setErrors(newErrors)   
    const selectedCompetences = [...Step2SelectedCompetences];
    if (e.checked) {
      selectedCompetences.push(e.value);
    } else {
      selectedCompetences.splice(selectedCompetences.indexOf(e.value), 1);
    }        
    setStep2SelectedCompetences(selectedCompetences);
  };

  const onChangeEsAccionCompletada = (e, index) => {   
    const updatedIDPAcciones = [...IDPAcciones];
    if(updatedIDPAcciones[index].EsAccionCompletada===e){
      updatedIDPAcciones[index].EsAccionCompletada = null;
    }else{
      updatedIDPAcciones[index].EsAccionCompletada = e;
    }
    if (e !== undefined){ 
      updatedIDPAcciones[index].errors = null;
      const newErrors = { ...errors }  
      const field = { ...newErrors[`IDPAcciones[${index}]`] };
      field.EsAccionCompletada = null;   
      newErrors[`IDPAcciones[${index}]`] = field;   
      setErrors(newErrors) ;  
    } 
    setIDPAcciones(updatedIDPAcciones); 
  }

  const onChangeFechaFinalReal = (e, index) => {   
    const updatedIDPAcciones = [...IDPAcciones];
    updatedIDPAcciones[index].FechaFinalReal = e.target.value;
    if (e.target.value !== undefined){ 
      updatedIDPAcciones[index].errors = null;
      const newErrors = { ...errors }  
      const field = { ...newErrors[`IDPAcciones[${index}]`] };
      field.FechaFinalReal = null;   
      newErrors[`IDPAcciones[${index}]`] = field;   
      setErrors(newErrors) ;  
    } 
    setIDPAcciones(updatedIDPAcciones); 
  }

  const onStep3SelectEsDiscutirGerente = ( value ) => {  
    const newErrors = { ...errors } 
    delete newErrors.EsDiscutirGerente;   
    setErrors(newErrors)
    setEsDiscutirGerente(value); 
  }; 

  const onHide = () => {
    setMotivoDeshacer(null);    
    if (props.onHide && typeof props.onHide === 'function') {
      props.onHide();
    }
  };

  const onChangeComentariosRevisado = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.ComentariosRevisado;   
    setErrors(newErrors) 
    setComentariosRevisado(e.target.value)
  }

  const onComentariosRevisadoEmpleadoChange = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.ComentariosRevisadoEmpleado;   
    setErrors(newErrors) 
    setComentariosRevisadoEmpleado(e.target.value)
  }

  const onStep5GoalCompletedChange = (value) => {
    const newErrors = { ...errors } 
    delete newErrors.Step5GoalCompleted;   
    setErrors(newErrors) 
    setStep5GoalCompleted(value)
  };

  const onStep5GoalCompletedDateChange = (e) => {   
    const newErrors = { ...errors } 
    delete newErrors.Step5GoalCompletedDate;   
    setErrors(newErrors) 
    setStep5GoalCompletedDate(e.target.value);
  };  

  const onStep5NotCompletedReasonIDChange = (value) => {
    setStep5NotCompletedReasonID(value)
  };

  const onStep5HasCertificateDocumentchange = (value) => {
    setStep5HasCertificateDocument(value)
  };

  const onStep5SupportDucumentCertificateDatafilesChange = (file) => {
    const newErrors = { ...errors } 
    delete newErrors.SupportDucumentCertificate;   
    setErrors(newErrors) 
    setSupportDucumentCertificateDatafiles(file);
  };
  
  const onStep5Question3LessonsLearnedHelpPress = () => {    
    showHelpDialog(t('iDPV2:Step5CloseOutIDPQuestion3Title'), step5Question3HelpDialogBodyTemplate);
  };

  const onStep5LessonsLearnedChange = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.Step5LessonsLearned;   
    setErrors(newErrors) 
    setStep5LessonsLearned(e.target.value)
  }

  // #endregion

  // #region Templates

  const helpDialogTemplate = (
    <>
      <Dialog 
        header={HelpDialogHeaderText}
        visible={ShowHelpDialog} 
        style={{ width: '50vw' }}
        onHide={onHelpDialogHide}
      >      
        {HelpDialogBodyTemplate}
      </Dialog>
    </>
  );

  const createdByTemplate = (
    <>
      <Row className="RowBody">
        <Col lg={8} md={8} sm={12} xs={12} className="pl-2"> 
          <div hidden={IdIDP===0}>
            <span>{t('hr:PlanCreatedby')}</span>&nbsp;
            <span><b>{IDPClase.NombreCompleto}</b> </span>
            <span>{t('hr:On')}</span>&nbsp;
            <span><b>{moment(IDPClase.FechaCreacion).format("LL")} </b> </span>&nbsp;
            <span><b>{IDPClase.HoraCreacion} </b> </span>
          </div>
        </Col>
        <Col lg={4} md={4} sm={12} xs={12} className="pl-2  text-right"> 
          <div hidden={IdIDP===0}>
            <span>{t('hr:LastUpdate')}</span>&nbsp;
            <span><b> {moment(IDPClase.FechaModificacion).format("LL")}</b></span>
              &nbsp;
            <span><b>{IDPClase.HoraModificacion} </b> </span>
          </div>
        </Col>
      </Row>  
    </>
  );

  const feedbackTemplate = (
    <>
      {
        (
          ClaIDPEstatus === idpStatus.IN_PROGRESS ||
          ClaIDPEstatus === idpStatus.ADDITIONAL_INPUT_NEEDED ||
          ClaIDPEstatus === idpStatus.ACCEPTED
        ) && (
          <>
            <div className='card info mt-2'> 
              <div className='card-body'>
                <div className='pb-3'>
                  <b>{t('hr:ManagerFeedback')}</b>
                </div>
                <span className="text-left">{ComentariosRevisado}</span> 
              </div>
            </div>              
          </>
        )
      }    
      {
        (
          (
            ClaIDPEstatus === idpStatus.DRAFT ||
            ClaIDPEstatus === idpStatus.PENDING_REVIEW             
          ) &&
          (
            ComentariosRevisadoEmpleadoOriginal && ComentariosRevisadoEmpleadoOriginal !== ''
          )
        ) && (
          <>
            <Row className='pt-2'>
              <Col>
                <div className='card cool-gray'> 
                  <div className='card-body'>
                    <div className='pb-3'>
                      <b>{t('iDPV2:Step4ManagerEmployeeFeedback')}</b>
                    </div>
                    <span className="text-left">{ComentariosRevisadoEmpleadoOriginal}</span> 
                  </div>
                </div>                                  
              </Col>                              
            </Row>                                
          </>
        )
      }   
    </>
  );

  const selfReflectionSectionTemplate = (
    <>
      <Card>
        <CardBody>
          <Row className='align-items-start'>
            <Col lg={3} md={12} sm={12} xs={12}>
              <Row>
                <Col>
                  <h2 className="text-center"> 
                    {t('iDPV2:SelfReflection')}              
                  </h2> 
                  <p className="text-center">
                    <b>
                      {`${t('common:Instructions')}:`}                      
                    </b>&nbsp;
                    {t('iDPV2:SelfReflectionInstructions')}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h3>
                    {`${t('common:Purpose')}:`}
                  </h3>
                  <p>
                    {`${t('iDPV2:SelfReflectionPurpose')}:`}                
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={1}>
                  <FieldRadioButton
                    inputId="selfReflectionPurpose1Input"
                    name="SelfReflectionPurpose"                   
                    value={idpSelfReflectionPurposeTypes.NEW_TO_MY_ROLE}      
                    checked={SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.NEW_TO_MY_ROLE}                       
                    onChange={(value) => onSelfReflectionPurposeChange(value)}    
                    disabled={EsDisabled}                                                      
                  />  
                </Col>
                <Col>
                  <p>
                    <b>
                      {`${t('iDPV2:SelfReflectionPurposeOption1Title')}:`}
                    </b>&nbsp;
                    {t('iDPV2:SelfReflectionPurposeOption1Description')}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={1}>
                  <FieldRadioButton
                    inputId="selfReflectionPurpose2Input"
                    name="SelfReflectionPurpose"                     
                    value={idpSelfReflectionPurposeTypes.CURRENT_DEVELOPMENT}      
                    checked={SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.CURRENT_DEVELOPMENT}                              
                    onChange={(value) => onSelfReflectionPurposeChange(value)}
                    disabled={EsDisabled}
                  />   
                </Col>
                <Col>
                  <p>
                    <b>
                      {`${t('iDPV2:SelfReflectionPurposeOption2Title')}:`}
                    </b>&nbsp;
                    {t('iDPV2:SelfReflectionPurposeOption2Description')}
                  </p>
                </Col>
              </Row>  
              <Row>
                <Col xs={1}>
                  <FieldRadioButton
                    inputId="selfReflectionPurpose3Input"
                    name="SelfReflectionPurpose"                     
                    value={idpSelfReflectionPurposeTypes.FUTURE_PREPARATION}      
                    checked={SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.FUTURE_PREPARATION}          
                    onChange={(value) => onSelfReflectionPurposeChange(value)}
                    disabled={EsDisabled}
                  />  
                </Col>
                <Col>
                  <p>
                    <b>
                      {`${t('iDPV2:SelfReflectionPurposeOption3Title')}:`}
                    </b>&nbsp;
                    {t('iDPV2:SelfReflectionPurposeOption3Description')}
                  </p>
                </Col>
              </Row>                         
              <Row>
                <Col xs={1}>
                  <FieldRadioButton
                    inputId="selfReflectionPurpose4Input"
                    name="SelfReflectionPurpose"                     
                    value={idpSelfReflectionPurposeTypes.RECOMMENDED_BY_SUPERVISOR}      
                    checked={SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.RECOMMENDED_BY_SUPERVISOR}          
                    onChange={(value) => onSelfReflectionPurposeChange(value)}
                    disabled={EsDisabled}
                  /> 
                </Col>
                <Col>
                  <p>
                    <b>
                      {`${t('iDPV2:SelfReflectionPurposeOption4Title')}:`}
                    </b>&nbsp;
                    {t('iDPV2:SelfReflectionPurposeOption4Description')}
                  </p>
                </Col>
              </Row>                      
              <Row>
                <Col xs={1}>
                  <FieldRadioButton
                    inputId="selfReflectionPurpose5Input"
                    name="SelfReflectionPurpose"                     
                    value={idpSelfReflectionPurposeTypes.NOT_PARTICIPATE}      
                    checked={SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.NOT_PARTICIPATE}          
                    onChange={(value) => onSelfReflectionPurposeChange(value)}
                    disabled={EsDisabled}
                  /> 
                </Col>
                <Col>
                  <p>
                    <b>
                      {`${t('iDPV2:SelfReflectionPurposeOption5Title')}:`}
                    </b>&nbsp;
                    {t('iDPV2:SelfReflectionPurposeOption5Description')}
                  </p>
                </Col>
              </Row>                   
            </Col>
            <Col lg={9} md={12} sm={12} xs={12} className="border-left">
              {
                SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.NEW_TO_MY_ROLE && (
                  <>           
                    <Row>
                      <Col>
                        <h3>
                          {t('iDPV2:SelfReflectionPurposeOption1Title')}
                        </h3> 
                      </Col>
                    </Row>        
                    <Row>
                      <Col>
                        <p>
                          <b>
                            {t('iDPV2:SelfReflectionPurposeOption1Question1')}
                          </b>
                        </p>
                        <div className="p-fluid"> 
                          <FieldTextArea 
                            name="SelfReflectionPurposeAnswer1_1"
                            placeholder={EsDisabled? '': t('iDPV2:YourAnswer')}
                            value={SelfReflectionPurposeAnswer1_1} 
                            onChange={onSelfReflectionPurposeAnswer1_1Change}
                            rows={2}
                            maxLength={1000}
                            disabled={EsDisabled}     
                            errors={errors}            
                          />
                        </div>                                 
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>
                          <b>
                            {t('iDPV2:SelfReflectionPurposeOption1Question2')}
                          </b>
                        </p>
                        <div className="p-fluid"> 
                          <FieldTextArea 
                            name="SelfReflectionPurposeAnswer1_2"
                            placeholder={EsDisabled? '': t('iDPV2:YourAnswer')}
                            value={SelfReflectionPurposeAnswer1_2} 
                            onChange={onSelfReflectionPurposeAnswer1_2Change}
                            rows={2}
                            maxLength={1000}
                            disabled={EsDisabled}     
                            errors={errors}                 
                          />
                        </div>                  
                      </Col>
                    </Row>                   
                  </>
                )
              } 
              {
                SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.CURRENT_DEVELOPMENT && (
                  <>   
                    <Row>
                      <Col>
                        <h3>  
                          {t('iDPV2:SelfReflectionPurposeOption2Title')}
                        </h3> 
                      </Col>
                    </Row>       
                    <Row>
                      <Col>
                        <p>
                          <b>
                            {t('iDPV2:SelfReflectionPurposeOption2Question1')}
                          </b>
                        </p>
                        <div className="p-fluid"> 
                          <FieldTextArea 
                            name="SelfReflectionPurposeAnswer2_1"
                            placeholder={EsDisabled? '': t('iDPV2:YourAnswer')}
                            value={SelfReflectionPurposeAnswer2_1} 
                            onChange={onSelfReflectionPurposeAnswer2_1Change}
                            rows={2}
                            maxLength={1000}
                            disabled={EsDisabled}        
                            errors={errors}                      
                          />
                        </div>                  
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>
                          <b>
                            {t('iDPV2:SelfReflectionPurposeOption2Question2')}
                          </b>
                        </p>                        
                        <div className="p-fluid"> 
                          <FieldTextArea 
                            name="SelfReflectionPurposeAnswer2_2"
                            placeholder={EsDisabled? '': t('iDPV2:YourAnswer')}
                            value={SelfReflectionPurposeAnswer2_2} 
                            onChange={onSelfReflectionPurposeAnswer2_2Change}
                            rows={2}
                            maxLength={1000}
                            disabled={EsDisabled}      
                            errors={errors}                        
                          />
                        </div>                  
                      </Col>
                    </Row>              
                  </>
                )
              }             
              {
                SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.FUTURE_PREPARATION && (
                  <>   
                    <Row>
                      <Col>
                        <h3>  
                          {t('iDPV2:SelfReflectionPurposeOption3Title')}
                        </h3> 
                      </Col>
                    </Row>  
                    <Row>
                      <Col>
                        <p>
                          <b>
                            {t('iDPV2:SelfReflectionPurposeOption3Question1')}
                          </b>
                        </p>                          
                        <div className="p-fluid"> 
                          <FieldTextArea 
                            name="SelfReflectionPurposeAnswer3_1"
                            placeholder={EsDisabled? '': t('iDPV2:YourAnswer')}
                            value={SelfReflectionPurposeAnswer3_1} 
                            onChange={onSelfReflectionPurposeAnswer3_1Change}
                            rows={2}
                            maxLength={1000}
                            disabled={EsDisabled}       
                            errors={errors}                       
                          />
                        </div>                  
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>
                          <b>
                            {t('iDPV2:SelfReflectionPurposeOption3Question2')}
                          </b>
                        </p>                           
                        <div className="p-fluid"> 
                          <FieldTextArea 
                            name="SelfReflectionPurposeAnswer3_2"
                            placeholder={EsDisabled? '': t('iDPV2:YourAnswer')}
                            value={SelfReflectionPurposeAnswer3_2} 
                            onChange={onSelfReflectionPurposeAnswer3_2Change}
                            rows={2}
                            maxLength={1000}
                            disabled={EsDisabled}        
                            errors={errors}                      
                          />
                        </div>                  
                      </Col>
                    </Row>                    
                  </>
                )
              }              
              {
                SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.RECOMMENDED_BY_SUPERVISOR && (
                  <>
                    <Row>
                      <Col>
                        <h3>  
                          {t('iDPV2:SelfReflectionPurposeOption4Title')}
                        </h3> 
                      </Col>
                    </Row>        
                    <Row>
                      <Col>
                        <p>
                          <b>
                            {t('iDPV2:SelfReflectionPurposeOption4Question1')}
                          </b>
                        </p>                           
                        <div className="p-fluid"> 
                          <FieldTextArea 
                            name="SelfReflectionPurposeAnswer4_1"
                            placeholder={EsDisabled? '': t('iDPV2:YourAnswer')}
                            value={SelfReflectionPurposeAnswer4_1} 
                            onChange={onSelfReflectionPurposeAnswer4_1Change}
                            rows={2}
                            maxLength={1000}
                            disabled={EsDisabled}        
                            errors={errors}                      
                          />
                        </div>                  
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>
                          <b>
                            {t('iDPV2:SelfReflectionPurposeOption4Question2')}
                          </b>
                        </p>                          
                        <div className="p-fluid"> 
                          <FieldTextArea 
                            name="SelfReflectionPurposeAnswer4_2"
                            placeholder={EsDisabled? '': t('iDPV2:YourAnswer')}
                            value={SelfReflectionPurposeAnswer4_2} 
                            onChange={onSelfReflectionPurposeAnswer4_2Change}
                            rows={2}
                            maxLength={1000}
                            disabled={EsDisabled}        
                            errors={errors}                      
                          />
                        </div>                  
                      </Col>
                    </Row>                
                  </>
                )
              }         
              {
                SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.NOT_PARTICIPATE && (
                  <>
                    <Row>
                      <Col>
                        <h3>  
                          {t('iDPV2:SelfReflectionPurposeOption5Title')}
                        </h3> 
                      </Col>
                    </Row>        
                    <Row>
                      <Col>
                        <p>
                          <b>
                            {t('iDPV2:SelfReflectionPurposeOption5Question1')}
                          </b>
                        </p>                           
                        <div className="p-fluid"> 
                          <FieldTextArea 
                            name="SelfReflectionPurposeAnswer5_1"
                            placeholder={EsDisabled? '': t('iDPV2:YourAnswer')}
                            value={SelfReflectionPurposeAnswer5_1} 
                            onChange={onSelfReflectionPurposeAnswer5_1Change}
                            rows={2}
                            maxLength={1000}
                            disabled={EsDisabled}        
                            errors={errors}                      
                          />
                        </div>                  
                      </Col>
                    </Row>                          
                  </>
                )
              }                          
            </Col>
          </Row>
        </CardBody>
      </Card>    
    </>
  );

  const step1SetYourGoalHelpDialogBodyTemplate = (
    <>
      <h2>
        {t('iDPV2:Step1SetYourGoalQuestion1HelpTitle')}
      </h2>   
      <ul>
        <li>
          {t('iDPV2:Step1SetYourGoalQuestion1Help1')}
        </li>   
        <li>
          {t('iDPV2:Step1SetYourGoalQuestion1Help2')}
        </li>   
        <li>
          {t('iDPV2:Step1SetYourGoalQuestion1Help3')}
        </li>   
        <li>
          {t('iDPV2:Step1SetYourGoalQuestion1Help4')}
        </li>   
        <li>
          {t('iDPV2:Step1SetYourGoalQuestion1Help5')}
        </li>   
        <li>
          {t('iDPV2:Step1SetYourGoalQuestion1Help6')}
        </li>   
        <li>
          {t('iDPV2:Step1SetYourGoalQuestion1Help7')}
        </li>
      </ul>     
    </>
  );

  const step1SkillsToBeDevelopedHelpDialogBodyTemplate = (
    <>
      <p>
        {t('iDPV2:Step1SetYourGoalQuestion1aHelp1')}     
      </p>   
    </>
  );

  const step1Question1aSkillsTemplate = (
    <>
      <h4>
        {t('iDPV2:Step1SetYourGoalQuestion1aTitle')}&nbsp;                    
        <FieldButton 
          icon="far fa-question-circle" 
          className="p-button-rounded p-button-text" 
          aria-label="Submit" 
          onClick={onStep1SkillsToBeDevelopedHelpPress}
        />    
      </h4>
      <p>
        <b>
          {t('iDPV2:Step1SetYourGoalQuestion1aDescription')}
        </b>
      </p>
      <div className="p-fluid">                   
        {
          !EsDisabled && (
            <p className='text-info'>
              <i className='fa fa-info-circle'></i>&nbsp;
              {`${3 - Step2SelectedCompetences.length} ${t('iDPV2:Step1SetYourGoalQuestion1aAvailable')}. ${limitOfSelectedCompetenciesReached()? t('iDPV2:Step1SetYourGoalQuestion1aLimitReached'): ''}`}
            </p>                            
          )
        }        
        <ScrollPanel style={{ 
            width: '100%', 
            height: '200px'          
          }}
        >  
          {
            OpcionesIDPCompetencia.map((competence, index) => {
              return (
                <>     
                  <div className="p-fluid">
                    <FieldCheckbox 
                      inputId={`competenceCheckbox${competence.ClaCompetencia}`} 
                      name="Step2SelectedCompetences" 
                      value={competence.ClaCompetencia.toString()} 
                      onChange={onStep2CompetencesChange} 
                      checked={Step2SelectedCompetences.indexOf(competence.ClaCompetencia.toString()) !== -1}                                                                                 
                      disabled={
                        EsDisabled || 
                        SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.NOT_PARTICIPATE ||
                        (
                          !EsDisabled &&                                             
                          Step2SelectedCompetences.indexOf(competence.ClaCompetencia.toString()) === -1 &&
                          limitOfSelectedCompetenciesReached()
                        )
                      }
                      errors={errors}
                    />&nbsp;
                    <label htmlFor={`competenceCheckbox${competence.ClaCompetencia}`}>{competence.NomCompetencia}</label>
                  </div>  
                </>
              );                                
            })
          }
        </ScrollPanel>
      </div>    
    </>
  );

  const step1SetYourGoalSectionTemplate = (
    <>
      <Card>
        <CardBody>
          <Row className='align-items-start'>
            <Col lg={3} md={12} sm={12} xs={12}>
              <Row>
                <Col className='text-center'>
                  <Avatar
                    label="1" 
                    className="ex-large secondary mb-3" 
                    size="xlarge" 
                    shape="circle"
                  />                
                </Col>
              </Row>
              <Row>
                <Col>
                  <h2 className="text-center"> 
                    {t('iDPV2:Step1SetYourGoal')}
                  </h2> 
                  <p className="text-center">
                    <b>
                      {`${t('common:Instructions')}:`}                      
                    </b>&nbsp;
                    {t('iDPV2:Step1SetYourGoalInstructions')}
                  </p>
                </Col>
              </Row>                       
            </Col>
            <Col lg={9} md={12} sm={12} xs={12} className="border-left">
              <Row>
                <Col>
                  <h3>
                    {t('iDPV2:Step1SetYourGoalQuestion1Title')}&nbsp;                    
                    <FieldButton 
                      icon="far fa-question-circle" 
                      className="p-button-rounded p-button-text" 
                      aria-label="Submit" 
                      onClick={onStep1SetYourGoalHelpPress}                      
                    />    
                  </h3>
                  <p>
                    <b>
                      {t('iDPV2:Step1SetYourGoalQuestion1Description')}
                    </b>
                  </p>
                  <div className="p-fluid"> 
                    <FieldTextArea 
                      name="Step1Answer1SetYourGoal"
                      placeholder={EsDisabled? '': t('iDPV2:YourAnswer')}
                      value={Step1Answer1SetYourGoal} 
                      onChange={onStep1Answer1SetYourGoalChange}
                      rows={2}
                      maxLength={200}
                      disabled={EsDisabled || SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.NOT_PARTICIPATE}       
                      errors={errors}
                    />
                  </div>                                 
                </Col>
              </Row>    
              <Row>
                <Col>
                  {step1Question1aSkillsTemplate}
                </Col>
              </Row>    
              <Row className='pt-2'>
                <Col>
                  <h4>
                    {t('iDPV2:Step1SetYourGoalQuestion1bTitle')}&nbsp;    
                  </h4>
                  <p>
                    <b>
                      {t('iDPV2:Step1SetYourGoalQuestion1bStartDate')}
                    </b>
                  </p>
                  <FieldCalendar
                    name="Step1Answer1bStartDate"
                    label=''
                    value={Step1Answer1bStartDate}
                    onChange={onStep1Answer1bStartDateChange}                        
                    disabled={EsDisabled || SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.NOT_PARTICIPATE}
                    minDate={moment({ year: props.year }).startOf('year').toDate()} 
                    maxDate={moment({ year: props.year }).endOf('year').toDate()}      
                    errors={errors}            
                  />   
                  <p>
                    <b>
                      {t('iDPV2:Step1SetYourGoalQuestion1bEndDate')}
                    </b>
                  </p> 
                  <FieldCalendar                    
                    name="Step1Answer1bEndDate"
                    label=''
                    value={Step1Answer1bEndDate}
                    onChange={onStep1Answer1bEndDateChange}                        
                    disabled={EsDisabled || SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.NOT_PARTICIPATE}   
                    minDate={moment({ year: props.year }).startOf('year').toDate()} 
                    maxDate={moment({ year: props.year }).endOf('year').toDate()}      
                    errors={errors}            
                  />                              
                </Col>
              </Row>                     
            </Col>
          </Row>
        </CardBody>
      </Card>    
    </>
  );

  const step2DevelopmentActionStepsHelpDialogBodyTemplate = (
    <>
      <h2>
        {t('iDPV2:Step2DevelopmentActionStepsQuestion1HelpTitle')}
      </h2>   
      <ul>
        <li>
          {t('iDPV2:Step2DevelopmentActionStepsQuestion1Help')}
        </li>   
      </ul>     
    </>
  )  

  const step2ActionColumnTemplate = (rowData) => {
    let ActionName = '';        
    if (rowData.NomAccion !== '' && rowData.NomAccion !== '') {
      ActionName = rowData.NomAccion
    } else {
      ActionName = t('iDPV2:Step2DevelopmentActionStepsQuestion1Placeholder');
    }        
    return (
      <>
        {ActionName}
      </>
    );
  };

  const step2ActionColumnEditorTemplate = (props, rowData) => {
    rowData = props.rowData;
    return (
      <div className="p-fluid">
        <FieldText   
          value={props.rowData.NomAccion} 
          onChange={(e) => onStep2ActionColumnChange(props, e.target.value, rowData)}
          disabled={EsDisabled || SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.NOT_PARTICIPATE}
          placeholder={t('iDPV2:Step2DevelopmentActionStepsQuestion1Placeholder')}
          maxLength={250}
          errors={errors}
        />  
      </div>      
    );
  }

  const step2LearningMethodColumnEditorTemplate = (props, rowData) => {
    rowData = props.rowData;
    return (
      <div className="p-fluid">
        <FieldDropdown           
          name="ClaMetodoAprendizaje"       
          value={props.rowData.ClaMetodoAprendizaje}         
          placeholder={`- ${t('iDPV2:Step2DevelopmentActionStepsQuestion1PlaceholderLearningMethod')} -`}
          options={LearningMethods}  
          optionValue="ClaMetodoAprendizaje"
          optionLabel="NomMetodoAprendizaje" 
          onChange={(e) => onStep2LearningMethodColumnChange(props, e.value, e)}          
          disabled={EsDisabled || SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.NOT_PARTICIPATE}
          errors={errors}
          showClear={false}
        />   
      </div>    
    );
  };

  const step2LearningMethodColumnTemplate = (rowData) => {
    let learningMethodName = '';    
    const learningMethodsFiltered = LearningMethods.filter((learningMethod) => {
      return (learningMethod.ClaMetodoAprendizaje === rowData.ClaMetodoAprendizaje);
    });    
    if (learningMethodsFiltered.length > 0) {
      learningMethodName = learningMethodsFiltered[0].NomMetodoAprendizaje;
    } else {
      learningMethodName = `- ${t('iDPV2:Step2DevelopmentActionStepsQuestion1PlaceholderLearningMethod')} -`
    }        
    return (
      <>
        {learningMethodName}
      </>
    );
  };

  const step2ActionsColumnTemplate = (rowData) => {
    return (
      <> 
        <FieldButton 
          icon="pi pi-trash" 
          disabled={EsDisabled || SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.NOT_PARTICIPATE} 
          onClick={() => onStep2RemoveActionClick(rowData)} 
          className="p-button-rounded p-button-warning"
        /> 
      </>
    );
  };

  const step2CompleteActionColumnEditorTemplate = (rowData, tableData) => {    
    return (
      <>
        <Row style={{margin:0}}>
          <Col xs={12}>
            <b>
              {t('iDPV2:Step2DevelopmentActionStepsQuestion1aComplete')}
            </b>
          </Col>
        </Row>   
        <Row 
          className='pt-3'
          style={{margin:0}}
        >          
          <Col xs={12} lg={4}>
            <FieldRadioButton
              inputId="EsAccionCompletada1"
              name="EsAccionCompletada" 
              value={true}
              label={t('common:Yes')}
              onChange={(e) => onChangeEsAccionCompletada(e, rowData.RowIndex)} 
              checked={rowData.EsAccionCompletada&&rowData.EsAccionCompletada!=null}
              errors={errors[`IDPAcciones[${rowData.RowIndex}]`]}
              disabled={ClaIDPEstatus===6 || !IDPClase.EsUsuarioIDP}
            >
            </FieldRadioButton>
          </Col>        
          <Col xs={12} lg={8}>
            {
              rowData.EsAccionCompletada && (
                <FieldCalendar
                  name="FechaFinalReal"
                  label={t('hr:CompletedDate')}                  
                  value={(rowData.FechaFinalReal!== null ? (new Date(rowData.FechaFinalReal)): null)}  
                  onChange={(e) => { onChangeFechaFinalReal(e, rowData.RowIndex)}}  
                  errors={errors[`IDPAcciones[${rowData.RowIndex}]`]}                                                
                  minDate={moment({ year: props.year }).startOf('year').toDate()} 
                  maxDate={moment().toDate()}      
                  disabled={ClaIDPEstatus===6 || !IDPClase.EsUsuarioIDP}
                />   
              )
            }            
          </Col>     
        </Row>
        <Row style={{margin:0}}>
          <Col xs={12}>
            <FieldRadioButton
              inputId="EsAccionCompletada2"
              name="EsAccionCompletada" 
              value={false}
              label={t('common:No')}
              onChange={(e) => onChangeEsAccionCompletada(e, rowData.RowIndex)} 
              checked={!rowData.EsAccionCompletada&&rowData.EsAccionCompletada!=null}
              errors={errors[`IDPAcciones[${rowData.RowIndex}]`]}
              disabled={ClaIDPEstatus===6 || !IDPClase.EsUsuarioIDP}
            >
            </FieldRadioButton>
          </Col>          
        </Row>
      </>
    );
  };

  const step2CompleteStatusColumnTemplate = (rowData, tableData) => {    
    return (
      <>
        <Row style={{margin:0}}>
          <Col xs={12}>
            <b>
              {t('iDPV2:Step2DevelopmentActionStepsQuestion1aComplete')}
            </b>
          </Col>
        </Row>   
        <Row 
          className='pt-2'
          style={{margin:0}}
        >       
          <Col>   
            {
              rowData.EsAccionCompletada === null && (
                <span>
                  {t('common:Pending')}
                </span>
              )
            }
            {
              rowData.EsAccionCompletada === true && (
                <span>
                  {t('common:Yes')}&nbsp;
                  <span>
                    {`(${moment(rowData.FechaFinalReal).format('LL')})`}
                  </span>
                </span>
              )
            }
            {
              rowData.EsAccionCompletada === false && (
                <span>
                  {t('common:No')}
                </span>
              )
            }                
          </Col>     
        </Row>
      </>
    );
  };

  const step2Question1AddActionsTemplate = (
    <>
      {
        (            
          !EsJefeInmediato &&
          (
            ClaIDPEstatus === idpStatus.NEW ||
            ClaIDPEstatus === idpStatus.DRAFT ||
            ClaIDPEstatus === idpStatus.ADDITIONAL_INPUT_NEEDED
          )              
        ) && (
          <>
            <Row>
              <Col xs={12} md={6}> 
                <FieldButton 
                  className="p-button p-button-info" 
                  onClick={onStep2AddDevelpmentActionClick}
                  disabled={EsDisabled || SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.NOT_PARTICIPATE}
                >
                  <i className="pi pi-plus"></i>&nbsp;
                  {t('iDPV2:Step2DevelopmentActionStepsQuestion1AddButton')}
                </FieldButton> 
              </Col>
            </Row>  
            <Row className='pt-2'>
              <Col>
                <FieldDataTable      
                  name='Step2DevelpmentAction'
                  value={showActiveRows(IDPAcciones)}
                  reference={Setep2ActionsDTRef} 
                  selectionMode="single"  
                  metaKeySelection={false}
                  editMode="cell" 
                  className="editable-cells-table"
                  paginator={false}
                  errors={errors}
                >
                  <Column 
                    field="NomAccion" 
                    body={step2ActionColumnTemplate} 
                    header={t('iDPV2:Step2DevelopmentActionStepsQuestion1ColumnActionStep')} 
                    headerStyle={{ width: '50%' }} 
                    editorValidator={onStep2ActionColumnValidator} 
                    editor={(props) => step2ActionColumnEditorTemplate(props)}
                  />            
                  <Column 
                    field="ClaMetodoAprendizaje" 
                    body={step2LearningMethodColumnTemplate} 
                    header={t('iDPV2:Step2DevelopmentActionStepsQuestion1ColumnLearningMethod')} 
                    headerStyle={{ width: '50%' }} 
                    editorValidator={onStep2LearningMethodColumnValidator} 
                    editor={(props) => step2LearningMethodColumnEditorTemplate(props)}
                  />                          
                  <Column 
                    body={step2ActionsColumnTemplate} 
                    headerStyle={{ width: '70px' }}
                  />                        
                </FieldDataTable>         
              </Col>
            </Row>               
          </>
        )                    
      }      
      {
        (            
          EsJefeInmediato || 
          (
            !EsJefeInmediato &&
            (
              ClaIDPEstatus === idpStatus.PENDING_REVIEW ||
              ClaIDPEstatus === idpStatus.CANCELLED
            )                 
          )
        ) && (                
          <Row>
            <Col>                      
              <FieldDataTable                      
                value={showActiveRows(IDPAcciones)}                                
                metaKeySelection={false}                                
                paginator={false}                
              >
                <Column 
                  field="NomAccion"                   
                  header={t('iDPV2:Step2DevelopmentActionStepsQuestion1ColumnActionStep')} 
                  headerStyle={{ width: '50%' }}                   
                />            
                <Column 
                  field="NomMetodoAprendizaje" 
                  header={t('iDPV2:Step2DevelopmentActionStepsQuestion1ColumnLearningMethod')} 
                  headerStyle={{ width: '50%' }}                   
                />                                          
              </FieldDataTable>  
            </Col>
          </Row>  
        )     
      }     
    </>
  );

  const step2Question1CompleteActionsTemplate = (
    <>
      {
        (            
          !EsJefeInmediato && 
          ( 
            ClaIDPEstatus === idpStatus.IN_PROGRESS            
          )       
        ) && (
          <>
            <Row className='align-items-start'>
              <Col>
                <FieldDataTable      
                  name='Step2DevelpmentAction'
                  value={showActiveRows(IDPAcciones)}
                  reference={Setep2ActionsDTRef} 
                  selectionMode="single"  
                  metaKeySelection={false}
                  editMode="cell" 
                  className="editable-cells-table"
                  paginator={false}
                  errors={errors}
                >
                  <Column 
                    field="NomAccion"                   
                    header={t('iDPV2:Step2DevelopmentActionStepsQuestion1ColumnActionStep')} 
                    headerStyle={{ width: '35%' }}                   
                  />            
                  <Column 
                    field="NomMetodoAprendizaje" 
                    header={t('iDPV2:Step2DevelopmentActionStepsQuestion1ColumnLearningMethod')} 
                    headerStyle={{ width: '35%' }}                   
                  />  
                  <Column 
                    body={step2CompleteActionColumnEditorTemplate} 
                    header={t('iDPV2:Step2DevelopmentActionStepsQuestion1ColumnActionStep')} 
                    headerStyle={{ width: '30%' }} 
                  />                                             
                </FieldDataTable>     
              </Col>
            </Row>          
          </>
        )                    
      }      
      {
        (            
          EsJefeInmediato || 
          (
            !EsJefeInmediato &&
            (
              ClaIDPEstatus === idpStatus.COMPLETED ||
              ClaIDPEstatus === idpStatus.PARTIAL_COMPLETED              
            )                 
          )
        ) && (                
          <Row>
            <Col>                      
              <FieldDataTable                      
                value={showActiveRows(IDPAcciones)}                                
                metaKeySelection={false}                                
                paginator={false}                
              >
                <Column 
                  field="NomAccion"                   
                  header={t('iDPV2:Step2DevelopmentActionStepsQuestion1ColumnActionStep')} 
                  headerStyle={{ width: '50%' }}                   
                />            
                <Column 
                  field="NomMetodoAprendizaje" 
                  header={t('iDPV2:Step2DevelopmentActionStepsQuestion1ColumnLearningMethod')} 
                  headerStyle={{ width: '50%' }}                   
                />        
                <Column 
                  body={step2CompleteStatusColumnTemplate} 
                  header={t('iDPV2:Step2DevelopmentActionStepsQuestion1ColumnCompleted')} 
                  headerStyle={{ width: '30%' }} 
                />                                      
              </FieldDataTable>                
            </Col>
          </Row>            
        )     
      }     
    </>
  );

  const step2Question1ActionsTemplate = (
    <>
      <Row>
        <Col>
          <h3>
            {t('iDPV2:Step2DevelopmentActionStepsQuestion1Title')}&nbsp;                    
            <FieldButton 
              icon="far fa-question-circle" 
              className="p-button-rounded p-button-text" 
              aria-label="Submit" 
              onClick={onStep2DevelopmentActionStepsHelpPress}
            />    
          </h3>
          <p>
            <b>
              {t('iDPV2:Step2DevelopmentActionStepsQuestion1Description')}
            </b>
          </p>
        </Col>
      </Row>
      <Row>
        <Col>   
          {
            ( 
              ClaIDPEstatus === idpStatus.NEW ||                         
              ClaIDPEstatus === idpStatus.DRAFT ||
              ClaIDPEstatus === idpStatus.PENDING_REVIEW ||
              ClaIDPEstatus === idpStatus.ADDITIONAL_INPUT_NEEDED ||
              ClaIDPEstatus === idpStatus.CANCELLED
            ) && step2Question1AddActionsTemplate
          }
          {
            (                          
              ClaIDPEstatus === idpStatus.IN_PROGRESS ||
              ClaIDPEstatus === idpStatus.COMPLETED ||
              ClaIDPEstatus === idpStatus.PARTIAL_COMPLETED
            ) && step2Question1CompleteActionsTemplate
          }           
        </Col>
      </Row>
    </>
  );

  const step2Question2SupportFileTemplate = (
    <>
      <Row>
        <Col className='pt-2'>
          <h3>
            {t('iDPV2:Step2DevelopmentActionStepsQuestion2Title')}&nbsp;     
          </h3>        
        </Col>
      </Row>
      {
        (
          !EsJefeInmediato &&
          (
            ClaIDPEstatus === idpStatus.NEW || 
            ClaIDPEstatus === idpStatus.DRAFT || 
            ClaIDPEstatus === idpStatus.ADDITIONAL_INPUT_NEEDED           
          )
        )?
        (
          <>
            <Row>
              <Col> 
                <b>
                  {t('iDPV2:Step2DevelopmentActionStepsQuestion2Description')}
                </b> 
              </Col>
            </Row>
            <Row>
              <Col>
                <FieldUpload 
                  name="SupportDucumentCourse"
                  files={supportDucumentCourseDatafiles} 
                  onChange={(files) => setSupportDucumentCourseDatafiles(files)}
                  errors={errors} 
                  disabled={SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.NOT_PARTICIPATE}                
                >
                </FieldUpload>
              </Col>
            </Row>
          </>
        ): (
          <> 
            {
              supportDucumentCourseDatafiles.length > 0?
              (
                <>
                  <Row>
                    <Col>                     
                      <span className="link-text info-color" onClick={()=> onDownloadDocument(supportDucumentCourseDatafiles[0])}>
                        {t('hr:DownloadSupportDocuments')}&nbsp;<i className='pi pi-download'></i>
                      </span>
                    </Col>
                  </Row> 
                </>
              ): (
                <>
                  <p>
                    {t('iDPV2:Step2DevelopmentActionStepsQuestion2WithoutDocument')}
                  </p>
                </>
              )
            }
          </>
        )
      }
    </>
  );

  const step2Question3ActionCommentsTemplate = (
    <>
      <h3>
        {t('iDPV2:Step2DevelopmentActionStepsQuestion3Title')}
      </h3>                      
      <p>
        <b>
          {t('iDPV2:Step2DevelopmentActionStepsQuestion3Description')}
        </b>
      </p>
      <div className="p-fluid"> 
        <FieldTextArea 
          name="Step2ActionsComments"
          placeholder={EsDisabled? '': t('iDPV2:YourComments')}
          value={Step2ActionsComments} 
          onChange={onStep2ActionsCommentsChange}
          rows={2}
          maxLength={1000}
          disabled={
            SelfReflectionPurposeID === idpSelfReflectionPurposeTypes.NOT_PARTICIPATE ||
            EsJefeInmediato ||
            (
              !EsJefeInmediato &&
              (
                ClaIDPEstatus === idpStatus.PENDING_REVIEW ||
                ClaIDPEstatus === idpStatus.CANCELLED ||
                ClaIDPEstatus === idpStatus.COMPLETED ||
                ClaIDPEstatus === idpStatus.PARTIAL_COMPLETED ||
                ClaIDPEstatus === idpStatus.ACCEPTED
              )
            )            
          }     
          errors={errors}                 
        />
      </div>          
    </>
  )

  const step2FooterTemplate = (
    <>
      {
        (                      
          (
            !EsJefeInmediato &&
            ClaIDPEstatus === idpStatus.IN_PROGRESS 
          )
        ) && (              
          <>
            <Row className='pt-1'>
              <Col>    
                <FieldButton                  
                  label={t('common:Update')}
                  className="p-button-raised p-button-success"                  
                  onClick={() => {saveStatus(idpStatus.IN_PROGRESS)}}
                />                 
              </Col>
            </Row> 
          </>              
        )       
      }
    </>
  )

  const step2DevelopmentActionStepsSectionTemplate = (
    <>
      <Card>
        <CardBody>
          <Row className='align-items-start'>
            <Col lg={3} md={12} sm={12} xs={12}>
              <Row>
                <Col className='text-center'>
                  <Avatar
                    label="2" 
                    className="ex-large secondary mb-3" 
                    size="xlarge" 
                    shape="circle"
                  />                
                </Col>
              </Row>
              <Row>
                <Col>
                  <h2 className="text-center"> 
                    {t('iDPV2:Step2DevelopmentActionSteps')}
                  </h2> 
                  <p className="text-center">
                    <b>
                      {`${t('common:Instructions')}:`}                      
                    </b>&nbsp;
                    {t('iDPV2:Step2DevelopmentActionStepsInstructions')}
                  </p>
                </Col>
              </Row>                       
            </Col>
            <Col lg={9} md={12} sm={12} xs={12} className="border-left">  
              <Row>
                <Col>
                  {step2Question1ActionsTemplate}
                </Col>
              </Row>
              <Row className="pt-2">
                <Col>
                  {step2Question2SupportFileTemplate}     
                </Col>
              </Row>     
              <Row className='pt-2'>
                <Col>      
                  {step2Question3ActionCommentsTemplate}
                </Col>
              </Row>       
              <Row className='pt-2'>
                <Col>      
                  {step2FooterTemplate}
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>    
    </>
  );

  const step3EmployeeReviewSectionTemplate = (
    <>
      <Card>
        <CardBody>
          <Row className='align-items-start'>
            <Col lg={3} md={12} sm={12} xs={12}>
              <Row>
                <Col className='text-center'>
                  <Avatar
                    label="3" 
                    className="ex-large secondary mb-3" 
                    size="xlarge" 
                    shape="circle"
                  />                
                </Col>
              </Row>
              <Row>
                <Col>
                  <h2 className="text-center"> 
                    {t('iDPV2:Step3EmployeeReview')}
                  </h2> 
                  <p className="text-center">
                    <b>
                      {`${t('common:Instructions')}:`}                      
                    </b>&nbsp;
                    {t('iDPV2:Step3EmployeeReviewInstructions')}
                  </p>
                </Col>
              </Row>                       
            </Col>
            <Col lg={9} md={12} sm={12} xs={12} className="border-left">  
              <Row>
                <Col>
                  <h3>
                    {t('iDPV2:Step3EmployeeReviewQuestion1Title')}
                  </h3>                      
                  <p>
                    <b>
                      {t('iDPV2:Step3EmployeeReviewQuestion1Description')}
                    </b>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>       
                  <FieldRadioButton
                    inputId="EsDiscutirGerente_draft"
                    name="EsDiscutirGerente"                   
                    value={false}    
                    label={t('iDPV2:Step3EmployeeReviewQuestion1Option1')}
                    checked={!EsDiscutirGerente}                       
                    onChange={onStep3SelectEsDiscutirGerente}    
                    disabled={EsDisabled}  
                    errors={errors}                                                    
                  />
                  <FieldRadioButton
                    inputId="EsDiscutirGerente_sendForReview"
                    name="EsDiscutirGerente"                   
                    value={true}    
                    label={t('iDPV2:Step3EmployeeReviewQuestion1Option2')}
                    checked={EsDiscutirGerente}                       
                    onChange={onStep3SelectEsDiscutirGerente}    
                    disabled={EsDisabled}  
                    errors={errors}                                                    
                  />
                </Col>
              </Row>
              {
                !EsJefeInmediato && (
                  (
                    ClaIDPEstatus === idpStatus.ADDITIONAL_INPUT_NEEDED && 
                    EsDiscutirGerente
                  ) ||
                  (
                    ClaIDPEstatus === idpStatus.DRAFT &&
                    /* IDPClase.FechaRevisado */
                    EsPlanRevisado &&
                    EsDiscutirGerente
                  ) 
                ) && (
                  <Row>
                    <Col xs={12} lg={6} className='p-fluid'>
                      <FieldTextArea 
                        name="ComentariosRevisadoEmpleado"
                        rows={4}
                        placeholder={t('iDPV2:YourComments')}
                        value={ComentariosRevisadoEmpleado} 
                        onChange={onComentariosRevisadoEmpleadoChange}
                        errors={errors}
                        maxLength={1000}                        
                      />  
                    </Col>
                  </Row>   
                )
              }         
              {
                (
                  !EsJefeInmediato &&
                  (
                    ClaIDPEstatus === idpStatus.NEW ||
                    ClaIDPEstatus === idpStatus.DRAFT ||
                    ClaIDPEstatus === idpStatus.ADDITIONAL_INPUT_NEEDED
                  )
                ) && (
                  <>                              
                    <Row className="pt-2">
                      <Col>
                        <FieldButton
                          label={t('common:Submit')}
                          className="p-button-raised p-button-success"
                          onClick={() => {save()}}                            
                        /> 
                      </Col>
                    </Row>                         
                  </>
                )                      
              }        
              {
                (
                  ClaIDPEstatus === idpStatus.PENDING_REVIEW ||
                  ClaIDPEstatus === idpStatus.IN_PROGRESS ||                  
                  ClaIDPEstatus === idpStatus.CANCELLED ||
                  ClaIDPEstatus === idpStatus.COMPLETED ||
                  ClaIDPEstatus === idpStatus.PARTIAL_COMPLETED
                ) && (
                  <>                 
                    <Row className="pt-2">
                      <Col>      
                        <div hidden={ClaIDPEstatus<2} className="p-fluid">
                          <span>{t('hr:SendRevieLegend')}</span>&nbsp;
                          <span><b>{moment(IDPClase.FechaEnviadoGerente).format("LL")}</b> </span>&nbsp;
                          <span>{t('common:At')}</span>&nbsp;
                          <span><b>{IDPClase.HoraEnviadoGerente}</b> </span> &nbsp;
                          <span>{t('common:By')}</span>&nbsp;
                          <span><b>{IDPClase.NombreCompleto}</b> </span>&nbsp;
                        </div> 
                      </Col>
                    </Row>   
                  </>
                )
              }
            </Col>
          </Row>
        </CardBody>
      </Card>    
    </>
  );

  const step4ManagerReviewSectionTemplate = (
    <>      
      {
        (
          (
            EsJefeInmediato && 
            ClaIDPEstatus === idpStatus.PENDING_REVIEW
          ) || 
          (
            ClaIDPEstatus === idpStatus.IN_PROGRESS ||
            ClaIDPEstatus === idpStatus.ADDITIONAL_INPUT_NEEDED ||
            ClaIDPEstatus === idpStatus.COMPLETED ||            
            ClaIDPEstatus === idpStatus.PARTIAL_COMPLETED ||
            ClaIDPEstatus === idpStatus.ACCEPTED            
          ) 
        ) && (
          <Card>
            <CardBody>
              <Row className='align-items-start'>
                <Col lg={3} md={12} sm={12} xs={12}>
                  <Row>
                    <Col className='text-center'>
                      <Avatar
                        label="4" 
                        className="ex-large secondary mb-3" 
                        size="xlarge" 
                        shape="circle"
                      />                
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h2 className="text-center"> 
                        {t('iDPV2:Step4ManagerReview')}
                      </h2> 
                    </Col>
                  </Row>  
                </Col>
                <Col lg={9} md={12} sm={12} xs={12} className="border-left">                  
                  <Row className="pt-3 align-items-start">          
                    <Col>
                      <h3>
                        {t('iDPV2:Step4ManagerReviewQuestion1Title')}
                      </h3>   
                    </Col>
                  </Row>  
                  <Row className="pt-3 align-items-start">                                              
                    <Col xs={12} lg={6}>
                      {
                        (
                          EsJefeInmediato && 
                          ClaIDPEstatus === idpStatus.PENDING_REVIEW
                        ) && (
                          <>
                            <Row>
                              <Col>                                                              
                                <p>
                                  <b>
                                    {t('iDPV2:Step4ManagerReviewQuestion1Description')}
                                  </b>
                                </p> 
                                <FieldRadioButton
                                  inputId="Step4ReviewOptionSelectedRequestAdditionalInformation"
                                  name="Step4ReviewOptionSelected"                   
                                  value={reviewOptions.REQUEST_ADDITIONAL_INFORMATION}    
                                  label={t('iDPV2:Step4ManagerReviewQuestion1Option2')}
                                  checked={Step4ReviewOptionSelected === reviewOptions.REQUEST_ADDITIONAL_INFORMATION}                       
                                  onChange={()=>{ setStep4ReviewOptionSelected(reviewOptions.REQUEST_ADDITIONAL_INFORMATION) }}                                      
                                />   
                                <FieldRadioButton
                                  inputId="Step4ReviewOptionSelectedApprove"
                                  name="Step4ReviewOptionSelected"                   
                                  value={reviewOptions.APPROVE}    
                                  label={t('iDPV2:Step4ManagerReviewQuestion1Option1')}
                                  checked={Step4ReviewOptionSelected === reviewOptions.APPROVE}                       
                                  onChange={()=>{ setStep4ReviewOptionSelected(reviewOptions.APPROVE) }}                                      
                                />                                                                         
                              </Col>
                            </Row>                          
                          </>                                         
                        )
                      }
                      <Row className="pt-2">
                        <Col className="p-fluid" lg={12} md={12} sm={12} xs={12}>
                          {
                            (
                              (ComentariosRevisado && ComentariosRevisado !== '') &&
                              (
                                (!EsJefeInmediato) ||
                                (EsJefeInmediato && ClaIDPEstatus !== idpStatus.PENDING_REVIEW)
                              )
                            ) &&
                            (
                              <>
                                <div className='card info'> 
                                  <div className='card-body'>
                                    <div className='pb-3'>
                                      <b>{t('iDPV2:Step4ManagerManagerFeedback')}</b>
                                    </div>
                                    <span className="text-left">{ComentariosRevisado}</span> 
                                  </div>
                                </div>                                    
                              </>
                            )                              
                          }  
                          {
                            (
                              EsJefeInmediato &&
                              ClaIDPEstatus=== idpStatus.PENDING_REVIEW 
                            ) &&
                            (
                              <>
                                <Row>
                                  <Col>
                                    <FieldTextArea 
                                      name="ComentariosRevisado"
                                      rows={4}
                                      placeholder={t('iDPV2:YourComments')}
                                      value={ComentariosRevisado} 
                                      onChange={onChangeComentariosRevisado}
                                      errors={errors}
                                      maxLength={1000}                                  
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FieldButton
                                      label={t('common:Submit')}
                                      className="p-button-raised p-button-success"
                                      onClick={onStep4ManagerReviewSubmit}
                                    />    
                                  </Col>
                                </Row>
                              </> 
                            )
                          }                          
                        </Col>
                      </Row>

                      { 
                        (
                          (
                            IDPClase.ClaIDPEstatus === idpStatus.IN_PROGRESS ||
                            IDPClase.ClaIDPEstatus === idpStatus.ADDITIONAL_INPUT_NEEDED ||
                            IDPClase.ClaIDPEstatus === idpStatus.CANCELLED ||
                            IDPClase.ClaIDPEstatus === idpStatus.COMPLETED ||
                            IDPClase.ClaIDPEstatus === idpStatus.PARTIAL_COMPLETED ||
                            IDPClase.ClaIDPEstatus === idpStatus.ACCEPTED 
                          ) &&
                          EsPlanRevisado                              
                        ) && (
                          <>
                            <Row>
                              <Col>
                                <div className="p-fluid">
                                  <span>{t('hr:SendReviewedLegend')}</span>&nbsp;
                                  <span><b>{moment(IDPClase.FechaRevisado).format("LL")}</b> </span>&nbsp;
                                  <span>{t('common:At')}</span>&nbsp;
                                  <span><b>{IDPClase.HoraRevisado}</b> </span>&nbsp;
                                  <span>{t('common:By')}</span>&nbsp;
                                  <span><b>{IDPClase.UsuarioReviso}</b> </span>&nbsp;
                                </div> 
                              </Col>
                            </Row>   
                          </>
                        )
                      }
                      {
                        MotivoDeshacer!= null && (
                          <>
                            <Row className="pt-3 align-items-start">
                              <Col>
                                <span className="bold"><h2> {t('hr:CommentsUndoAccepted')} </h2> </span> 
                                <div>
                                  {MotivoDeshacer}
                                </div> 
                              </Col>
                            </Row>
                          </>
                        )
                      }
                    </Col>
                    <Col lg={6} xs={12}>
                      {
                        (ComentariosRevisadoEmpleadoOriginal && ComentariosRevisadoEmpleadoOriginal !== '') && (
                          <>
                            <Row className='pt-2'>
                              <Col>
                                <div className='card cool-gray'> 
                                  <div className='card-body'>
                                    <div className='pb-3'>
                                      <b>{t('iDPV2:Step4ManagerEmployeeFeedback')}</b>
                                    </div>
                                    <span className="text-left">{ComentariosRevisadoEmpleadoOriginal}</span> 
                                  </div>
                                </div>                                  
                              </Col>                              
                            </Row>                                
                          </>
                        )
                      }   
                    </Col>
                  </Row>    
                </Col>
              </Row>
            </CardBody>
          </Card>
        )
      }
    </>
  );
  
  const step5Question1DidYouCompleteEditTemplate = (
    <>
      <Row>
        <Col>
          <p>
            <b>
              {t('iDPV2:Step5CloseOutIDPQuestion1Description')}
            </b>
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={2}>
          <FieldRadioButton
            inputId="Step5GoalCompletedYesInput"            
            name="Step5GoalCompleted"            
            label={t('common:Yes')}
            value={true}      
            checked={Step5GoalCompleted}                       
            onChange={onStep5GoalCompletedChange}   
            errors={errors}                                                    
          />                         
        </Col>
        <Col xs={2}>
          <FieldRadioButton
            inputId="Step5GoalCompletedNoInput"
            name="Step5GoalCompleted"          
            label={t('common:No')}          
            value={false}      
            checked={Step5GoalCompleted===false}          
            onChange={onStep5GoalCompletedChange}
            errors={errors}
          />          
        </Col>
      </Row>         
      <Row className='pt-2'>
        <Col>
          {
            Step5GoalCompleted && (
              <>          
                <FieldCalendar
                  name="Step5GoalCompletedDate"
                  label={t('iDPV2:Step5CloseOutIDPQuestion1YesChooseDate')}
                  value={Step5GoalCompletedDate}
                  onChange={onStep5GoalCompletedDateChange}
                  minDate={moment({ year: props.year }).startOf('year').toDate()} 
                  maxDate={moment().toDate()}      
                  errors={errors}
                />
              </>
            )
          }            
          {
            (Step5GoalCompleted === false) && (
              <>          
                <p>
                  {t('iDPV2:Step5CloseOutIDPQuestion1NoIndicateReason')}
                </p>
                <ScrollPanel style={{ 
                    width: '100%', 
                    height: '200px'          
                  }}
                >  
                  {
                    NotCompletedReasons.map((reason, index) => {
                      return (
                        <>     
                          <FieldRadioButton
                            inputId={`Step5NotCompletedReasonIDInput_${reason.ClaRazonNoCompletado}`}            
                            name="Step5NotCompletedReasonID"            
                            label={reason.NomRazonNoCompletado}
                            value={reason.ClaRazonNoCompletado}
                            checked={Step5NotCompletedReasonID===reason.ClaRazonNoCompletado}
                            onChange={onStep5NotCompletedReasonIDChange}
                            errors={errors}
                          />  
                        </>
                      );                                
                    })
                  }
                </ScrollPanel>                
              </>
            )
          }                             
        </Col>
      </Row>      
    </>
  );

  const step5Question1DidYouCompleteReadOnlyTemplate = (
    <>
      <Row>
        <Col>
          {
            Step5GoalCompleted === null && (
              <p>
                {t('common:Pending')}
              </p>
            )
          }        
          {
            Step5GoalCompleted === true  && (
              <p>
                {`${t('common:Yes')} (${moment(Step5GoalCompletedDate).format('LL')})`}
              </p>
            )
          }
          {            
            Step5GoalCompleted === false && (
              <>
                <p>
                  {t('common:No')}
                </p>                
                <b>
                  {t('iDPV2:Step5CloseOutIDPQuestion1NoReason')}
                </b>
                <p>
                  {IDPClase.NomRazonNoCompletado}
                </p>              
              </>
            )              
          }
        </Col>
      </Row>             
    </>
  );

  const step5Question1DidYouCompleteTemplate = (
    <>
      <Row>
        <Col>
          <h3>
            {t('iDPV2:Step5CloseOutIDPQuestion1Title')}
          </h3>
        </Col>
      </Row>  
      <Row>
        <Col>   
          {            
            (!EsJefeInmediato && ClaIDPEstatus === idpStatus.IN_PROGRESS)? (
              step5Question1DidYouCompleteEditTemplate
            ): (
              step5Question1DidYouCompleteReadOnlyTemplate
            )
          }
        </Col>
      </Row>        
    </>
  );

  const step5Question2CertificateTemplate = (
    <>
      <Row>
        <Col className='pt-2'>
          <h3>
            {t('iDPV2:Step5CloseOutIDPQuestion2Title')}&nbsp;     
          </h3>        
        </Col>
      </Row>
      {
        (
          !EsJefeInmediato &&
          ClaIDPEstatus === idpStatus.IN_PROGRESS            
        )?
        (
          <>
            <Row>
              <Col> 
                <b>
                  {t('iDPV2:Step5CloseOutIDPQuestion2Description')}
                </b> 
              </Col>
            </Row>
            <Row className='pt-3'>
              <Col xs={2}>
                <FieldRadioButton
                  inputId="Step5HasCertificateDocumentYesInput" 
                  name="Step5HasCertificateDocument"            
                  label={t('common:Yes')}
                  value={true}      
                  checked={Step5HasCertificateDocument}                       
                  onChange={onStep5HasCertificateDocumentchange}                              
                />                                     
              </Col>
              <Col xs={2}>
                <FieldRadioButton
                  inputId="Step5HasCertificateDocumentNoInput"  
                  name="Step5HasCertificateDocument"            
                  label={t('common:No')}
                  value={false}      
                  checked={Step5HasCertificateDocument===false}                       
                  onChange={onStep5HasCertificateDocumentchange}                          
                />   
              </Col>
            </Row>   
            <Row>
              <Col>
                {
                    Step5HasCertificateDocument && (
                      <>          
                        <p>
                          {t('iDPV2:Step5CloseOutIDPQuestion2Upload')}
                        </p>
                        <FieldUpload 
                          name="SupportDucumentCertificate"
                          files={supportDucumentCertificateDatafiles} 
                          onChange={onStep5SupportDucumentCertificateDatafilesChange}
                          errors={errors}                   
                        >
                        </FieldUpload>     
                      </>
                    )
                  }      
              </Col>
            </Row>  
          </>
        ): (
          <> 
            {
              supportDucumentCertificateDatafiles.length > 0?
              (
                <>
                  <Row>
                    <Col>                     
                      <span className="link-text info-color" onClick={()=> onDownloadDocument(supportDucumentCertificateDatafiles[0])}>
                        {t('iDPV2:Step5CloseOutIDPQuestion2Download')}&nbsp;<i className='pi pi-download'></i>
                      </span>
                    </Col>
                  </Row> 
                </>
              ): (
                <>
                  <p>
                    {t('iDPV2:Step5CloseOutIDPQuestion2WithoutDocument')}
                  </p>
                </>
              )
            }
          </>
        )
      }
    </>
  );

  const step5Question3HelpDialogBodyTemplate = (
    <>
      <h2>
        {t('iDPV2:Step5CloseOutIDPQuestion3HelpTitle')}
      </h2>   
      <p>
        {t('iDPV2:Step5CloseOutIDPQuestion3Help1')}
      </p>
    </>
  );

  const step5Question3LessonsLearnedEditTemplate = (
    <>
      <Row>
        <Col 
          xs={12} 
          lg={6} 
          className='p-fluid'
        >
          <FieldTextArea 
            name="Step5LessonsLearned"
            rows={4}
            placeholder={t('iDPV2:YourComments')}
            value={Step5LessonsLearned} 
            onChange={onStep5LessonsLearnedChange}
            errors={errors}
            maxLength={1000}     
            disabled={ 
              EsJefeInmediato ||
              (!EsJefeInmediato && ClaIDPEstatus !== idpStatus.IN_PROGRESS)
            }                   
          />  
        </Col>
      </Row>      
    </>
  );

  const step5Question3LessonsLearnedTemplate = (
    <>
      <Row>
        <Col>
          <h3>
            {t('iDPV2:Step5CloseOutIDPQuestion3Title')}&nbsp;                    
            <FieldButton 
              icon="far fa-question-circle" 
              className="p-button-rounded p-button-text" 
              aria-label="Submit" 
              onClick={onStep5Question3LessonsLearnedHelpPress}                      
            />  
          </h3>
          <p>
            <b>
              {t('iDPV2:Step5CloseOutIDPQuestion3Description')}
            </b>
          </p>
        </Col>
      </Row>  
      <Row>
        <Col>   
          {step5Question3LessonsLearnedEditTemplate}
        </Col>
      </Row>        
    </>
  );

  const step5FooterTemplate = (
    <>
      {
        (
          !EsJefeInmediato &&
          ClaIDPEstatus === idpStatus.IN_PROGRESS 
        ) && (
          <>
            <Row className='pt-1'>
              <Col>    
                <FieldButton                  
                  label={t('iDPV2:Step5CloseOutIDPSubmitAndClosePButton')}
                  className="p-button-raised p-button-success"                  
                  onClick={() => {saveStatus(idpStatus.COMPLETED)}}
                />                 
              </Col>
            </Row>               
          </>
        )   
      } 
    </>
  );

  const step5CloseOutIDPSectionTemplate = (
    <> 
      {
        (
          (
            ClaIDPEstatus === idpStatus.IN_PROGRESS ||            
            ClaIDPEstatus === idpStatus.COMPLETED ||            
            ClaIDPEstatus === idpStatus.PARTIAL_COMPLETED            
          ) 
        ) && (
          <Card>
            <CardBody>
              <Row className='align-items-start'>
                <Col lg={3} md={12} sm={12} xs={12}>
                  <Row>
                    <Col className='text-center'>
                      <Avatar
                        label="5" 
                        className="ex-large secondary mb-3" 
                        size="xlarge" 
                        shape="circle"
                      />                
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h2 className="text-center"> 
                        {t('iDPV2:Step5CloseOutIDP')}
                      </h2> 
                      <p className="text-center">
                        <b>
                          {`${t('common:Instructions')}:`}                      
                        </b>&nbsp;
                        {t('iDPV2:Step5CloseOutInstructions')}
                      </p>
                    </Col>
                  </Row>  
                </Col>
                <Col lg={9} md={12} sm={12} xs={12} className="border-left">                  
                  <Row>
                    <Col>
                      {step5Question1DidYouCompleteTemplate}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {step5Question2CertificateTemplate}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {step5Question3LessonsLearnedTemplate}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {step5FooterTemplate}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        )
      }
    </>
  );

  const formTemplate = (
    <>
      <Row>
        <Col>
          {feedbackTemplate}          
        </Col>
      </Row>  
      <Row>
        <Col>          
          {createdByTemplate}          
        </Col>
      </Row>     
      <Row>
        <Col className='mt-2'>
          {selfReflectionSectionTemplate}
        </Col>
      </Row>     
      <Row>
        <Col className='mt-2'>
          {step1SetYourGoalSectionTemplate}
        </Col>
      </Row>  
      <Row>
        <Col className='mt-2'>
          {step2DevelopmentActionStepsSectionTemplate}        
        </Col>
      </Row>   
      <Row>
        <Col className='mt-2'>
          {step3EmployeeReviewSectionTemplate}        
        </Col>
      </Row>    
      <Row>
        <Col className='mt-2'>
          {step4ManagerReviewSectionTemplate}
        </Col>
      </Row>      
      <Row>
        <Col className='mt-2'>
          {step5CloseOutIDPSectionTemplate}
        </Col>
      </Row>  
      <Row>
        <Col>
          {createdByTemplate}
        </Col>
      </Row>               
    </>
  );

  // #endregion

  return (
    <>      
      {formTemplate}
      {helpDialogTemplate}
    </>
  )
};

export default IndividualDevelopmentPlanFormV2;
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi, showSweetAlert, getSessionItem } from '../../../utils/utils';
import { Checkbox } from 'primereact/checkbox';
import FieldText from '../../../components/Controls/FieldText.jsx';
import FieldTextArea from '../../../components/Controls/FieldTextArea.jsx';
import FieldNumber from '../../../components/Controls/FieldNumber.jsx';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx';
import FieldDropdown from 'components/Controls/FieldDropdown.jsx';
import { RadioButton } from 'primereact/radiobutton';
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import moment from 'moment';
import { Sidebar } from 'primereact/sidebar';
import swal from '@sweetalert/with-react';
import { Tag } from 'primereact/tag';
import PageContent from 'layouts/PageContent.jsx';
import SalesPerformanceSalesRepManagementFilter from './SalesPerformanceSalesRepManagementFilter.jsx';
import SalesPerformanceSalesRepManagement from './SalesPerformanceSalesRepManagement.jsx';

const SalesPerformanceSalesRepManagementScreen = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['salesPerformanceSales', 'common']);
  const [filtersData, setfiltersData] = useState({});


  const onSearch = (filters) => {
    setfiltersData({...filters});  
  };
  const onSave = (filters) => {
    setfiltersData({...filters});  
  };

  return (
    <>
      <PageContent title={t('salesPerformanceSales:SalesSetting')}>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <SalesPerformanceSalesRepManagementFilter onSearch={onSearch} onSave={onSave}></SalesPerformanceSalesRepManagementFilter>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
          </Col>
        </Row>
        <Card>   
          <CardBody>  
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <SalesPerformanceSalesRepManagement filtersData={filtersData}></SalesPerformanceSalesRepManagement>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </PageContent>
    </>
  );
};
export default SalesPerformanceSalesRepManagementScreen;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  ListGroupItem,
  ListGroup,
  Progress,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
} from 'reactstrap';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { Badge } from 'primereact/badge';
// import FeedbackCoaching from './FeedbackCoaching.jsx';
import { Link } from 'react-router-dom';
import { Toolbar } from 'primereact/toolbar';
import Highcharts from 'highcharts';
import { config } from '../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../utils/utils';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable.jsx';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import FieldButton from 'components/Controls/FieldButton/FieldButton.jsx';
import FieldText from 'components/Controls/FieldText';
import { cssNumber } from 'jquery';
import FieldNumber from '../../../components/Controls/FieldNumber.jsx';
import KPIFormulaResultadoReview from './KPIsFormulaSetUp/KPIFormulaResultadoReview';

const PuestoIndicadorResultadoMensual = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['focusReport', 'common']);
  const [ClaPuesto, setClaPuesto] = useState(
    props.ClaPuesto === undefined ? null : props.ClaPuesto
  );
  const [ClaAnio, setClaAnio] = useState(props.ClaAnio === undefined ? null : props.ClaAnio);
  const [ClaMes, setClaMes] = useState(null);
  const [PuestoIndicadorResultado, setPuestoIndicadorResultado] = useState([]);
  const [mesLis, setmesLis] = useState([]);
  const [SelectedPuestoIndicador, setSelectedPuestoIndicador] = useState([]);
  const [OpcionesMeses, setOpcionesMeses] = useState([]);
  const [UltimoClaMesCerrado, setUltimoClaMesCerrado] = useState(null); 
  const [PrimerClaMes, setPrimerClaMes] = useState(null); 
  const [EsMostrarFormulaReview, setEsMostrarFormulaReview] = useState(false);
  const [IdKPIFormulaSelected, setIdKPIFormulaSelected] =  useState(false);

  useEffect(() => { 
    setClaPuesto(props.ClaPuesto === undefined ? null : props.ClaPuesto);
    setClaAnio(props.ClaAnio === undefined ? null : props.ClaAnio);
    getMeses(props.ClaAnio);
  }, [props.ClaPuesto, props.ClaAnio, props.refresh]);

  
 const getMeses = (ClaAnio) => {
    
  const paramsService = {
    ClaAnio, 
    MostrarSoloCerrados : 0
  }; 
    
  const GetMesesScoreCard = `${config.UrlApiProject}ScoreCard/GetMesesScoreCard`; 
      
  callApi(GetMesesScoreCard, 'Post', paramsService, (response) => {
      setOpcionesMeses(response.data.Meses); 
      setClaMes(getUltimoDisponible(response.data.Meses)); 
      GetProjectActivitiesScoreCardFocusReport(props.ClaPuesto, props.ClaAnio, getUltimoDisponible(response.data.Meses));
  });   
 }

  const GetProjectActivitiesScoreCardFocusReport = (ClaPuesto, ClaAnio, ClaMes) => {
    const getProjectActivitiesScoreCardFocusReport = `${config.UrlApiProject}ScoreCard/GetProjectActivitiesScoreCardFocusReport`;
    const paramsToService = {
      ClaPuesto,
      ClaAnio,
      ClaMes,
    };
    callApi(getProjectActivitiesScoreCardFocusReport, 'POST', paramsToService, (response) => {
      setPuestoIndicadorResultado(response.data.FocusReport);
    });
  };
  const PorcTemplate = (rowData) => {
    return (
      <>
        <label>
          <b>{rowData.Porc}</b> %
        </label>
      </>
    );
  };
  const headerScoreCardDetail = <></>;

  const AccionesTemplateExpectedTrend = (row) => {
    let className = '';
    console.log(row.ExpectedTrend);
    switch (row.ExpectedTrend) {
      case 0:
        className = 'fa fa-chart-line fa-color-chart-line-down';
        break;
      case 1:
        className = 'fa fa-chart-line fa-color-chart-line';
        break;
      default:
        className = 'fa fa-exclamation-triangle fa-color-triangle';
    }
    return (
      <>
        <div>
          <i className={className}></i>
        </div>
      </>
    );
  };
  const AccionesTemplateUoM = (row) => {
    return (
      <>
        <div>
          <span>{row.UnidadMedida}</span>
        </div>
      </>
    );
  };
  const severityClass = (idx) => {
    const d = new Date();
    const month = d.getMonth() + 1;

    if (month <= idx) {
      if (month === idx) {
        return 'score-square-yellow-gradient';
      }
      return 'score-square-default-gradient-post';
    }
    return 'score-square-default-gradient-past';
  };

  const onClickViewDetailMonth = (ClaMes, props) => {
      setClaMes(ClaMes); 
      GetProjectActivitiesScoreCardFocusReport(ClaPuesto, ClaAnio, ClaMes);
  }; 

  const getUltimoDisponible = (meses) => {
    let ClaMes = 0;

    for (let index = 0; index < meses.length; index++) {
      if (meses[index].EsMesDisponible === 1) { 
        ClaMes =  meses[index].ClaMes;
      }
    }

    return ClaMes;
  }

  const onSaveMes = () => {
      
    
    const arrayScoreCardFocusReport = [];

    let RowValues ={};
  
    for (let index = 0; index < PuestoIndicadorResultado.length; index++) {
      if (PuestoIndicadorResultado[index].IsRowEdited){ 
        RowValues =  {
            ...PuestoIndicadorResultado[index],
            PeriodoReportado: PuestoIndicadorResultado[index].FechaReporte  
        }
        arrayScoreCardFocusReport.push(RowValues);
      }

    }
 
    
     const paramsServiceOwners = {    
       EstatusPeriodoList: arrayScoreCardFocusReport
     }; 

     const urlWebServiceSaveIndicadorPuestoDetalle = `${config.UrlApiProject}ScoreCard/SaveIndicadorPuestoDetalle`; 
   
     callApi(urlWebServiceSaveIndicadorPuestoDetalle, 'POST', paramsServiceOwners, (response) => {
       showSweetAlert('success', t('common:SuccessOperation'), 'success');
       GetProjectActivitiesScoreCardFocusReport(ClaPuesto, ClaAnio, ClaMes);

       if (props.onChange !== null && props.onChange !== 'undefined' && typeof(props.onChange) === 'function'){
        props.onChange();
      } 
    });  

    // setMostrarEstatusMes(!MostrarEstatusMes);
  };

  const footer = (
    <>
      <Row>
        <Column></Column>
        <Column></Column>
        <Column></Column>
        <Column></Column>
        <Column></Column>
        <Column
          className="text-center"
          footer={() => (
            <b>
              {PuestoIndicadorResultado.reduce(
                (a, v) => (a += v.PesoCalif === null ? 0 : v.PesoCalif),
                0
              ).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </b>
          )}
        >
        </Column>
      </Row>
    </>
  );

  const onCurrentStatusChange = (props, value) => { 
    const updatedCurrentStatus = [...PuestoIndicadorResultado];
    updatedCurrentStatus[props.rowData.RowIndex][props.field] = value;
    updatedCurrentStatus[props.rowData.RowIndex].IsRowEdited = true;
    setPuestoIndicadorResultado(updatedCurrentStatus);
  };


  const calculateKPIResult = (item, props) => { 
    /* Ejecutamos proceso para calcular el kpi con la formula */
    const paramsService = {
      AnioMes: parseInt(`${ClaAnio}${ClaMes < 10? `0${ClaMes}`: ClaMes}`, 10),
      IdKPIFormula: item.IdKPIFormula,
    };

    const CalcularResultadoFormula = `${config.UrlApiProject}ScoreCard/CalcularResultadoFormula`;
    
    callApi(CalcularResultadoFormula, 'POST', paramsService, (response) => {
      const updatedEstatusPeriodo = [...props.value];         
      const value = response.data.Resultado;       
      updatedEstatusPeriodo[props.rowIndex].EstatusPeriodo = value? Number(value).toFixed(2): null;
      updatedEstatusPeriodo[props.rowIndex].EsCalculoEjecutado  = true;      
      updatedEstatusPeriodo[props.rowIndex].IsRowEdited = true;
      setPuestoIndicadorResultado(updatedEstatusPeriodo);      
    }); 
  };

  const CurrentStatusEditor = (props) => {

    let className = '';

    switch (props.rowData.ColorEstatus) {
      case 1:
        className = 'danger-color';
        break;
      case 2:
        className = 'warning-color';
        break;
      case 3:
        className = 'success-color';
        break;
      default:
        className = 'default-color';
    }

    if (props.rowData.IdKPIFormula !== null){
      return (
        <>
          <i className={`fa fa-circle ${className}`}></i>
          &nbsp;
          <span onClick={()=> calculateKPIResult(props.rowData, props)}>
            <i className="fas fa-sync Cursor"></i>
          </span>&nbsp;&nbsp;&nbsp;
          <span className='link-text' onClick={()=> onShowCalculationFormulaRevi(props.rowData.IdKPIFormula)}>
            {props.rowData.EstatusPeriodo? Number(props.rowData.EstatusPeriodo).toFixed(2) : null}
          </span>
          {
            props.rowData.EsCalculoEjecutado === true &&
            (<>&nbsp;&nbsp;&nbsp;<span title="Click here to view how we calculate" className='Cursor' onClick={()=> onShowCalculationFormulaRevi(props.rowData.IdKPIFormula)}><i className="fas fa-question-circle info-color"></i></span></>) 
          }
        </>
      )
    }

    return (
      <div className="p-fluid">
        <FieldNumber
          name="CurrentStatus"
          value={props.rowData.EstatusPeriodo}
          onChange={(e) => onCurrentStatusChange(props, e.target.value)}
          min={-99999999.99}
          max={99999999.99}
          minFractionDigits={2}
        />
      </div>
    );
  };

  const showActiveRows = (rows) => {
    const activeRows = [];
    if (rows.length > 0) {
      rows.forEach((item, index) => {
        if (item.BajaLogica === 0 || item.BajaLogica === undefined) {
          item.BajaLogica = 0;
          item.RowIndex = index;
          activeRows.push({ ...item });
        }
      });
    } else {
      return [];
    }
    return activeRows;
  };

  const onShowCalculationFormulaRevi = (IdKPIFormula) => {
    console.info("onShowCalculationFormulaRevi: kpi formula", IdKPIFormula);
    console.info("onShowCalculationFormulaRevi: year" , ClaAnio);
    console.info("onShowCalculationFormulaRevi: month", ClaMes);
    setEsMostrarFormulaReview(true);
    setIdKPIFormulaSelected(IdKPIFormula); 
  }

  const EstatusPeriodoTemplate = (rowData, props) => {
    let className = '';

    switch (rowData.ColorEstatus) {
      case 1:
        className = 'danger-color';
        break;
      case 2:
        className = 'warning-color';
        break;
      case 3:
        className = 'success-color';
        break;
      default:
        className = 'default-color';
    }

    if (rowData.IdKPIFormula !== null){
      return (
        <>
          <i className={`fa fa-circle ${className}`}></i>
          &nbsp;
          <span onClick={()=> calculateKPIResult(rowData, props)}>
            <i className="fas fa-sync Cursor"></i>
          </span>&nbsp;&nbsp;&nbsp;
          <span className='link-text' onClick={()=> onShowCalculationFormulaRevi(rowData.IdKPIFormula)}>            
            {rowData.EstatusPeriodo? Number(rowData.EstatusPeriodo).toFixed(2) : null}
          </span>          
          {
            rowData.EsCalculoEjecutado === true &&
            (<>&nbsp;&nbsp;&nbsp;<span title="Click here to view how we calculate " className='Cursor' onClick={()=> onShowCalculationFormulaRevi(rowData.IdKPIFormula)}><i className="fas fa-question-circle info-color"></i></span></>) 
          }
          
        </>
      )
    }

    return (
      <>
        <i className={`fa fa-circle ${className}`}></i>
        &nbsp;
        {rowData.EstatusPeriodo !== null? Highcharts.numberFormat(rowData.EstatusPeriodo, 2, '.', ','): null}        
      </>
    );
  };

  const MinimoTemplate = (props) => {
    return <>{props.Minimo !== null?  Highcharts.numberFormat(props.Minimo, 2, '.', ',') : null}</>;
  };
  const SatisfactorioTemplate = (props) => {
    return <>{props.Satisfactorio !== null?  Highcharts.numberFormat(props.Satisfactorio, 2, '.', ','): null}</>;
  };

  const SobreSalienteTemplate = (props) => {
    return <>{props.SobreSaliente !== null?  Highcharts.numberFormat(props.SobreSaliente, 2, '.', ','): null}</>;
  };

  const SentidoMejoraTemplate = (props) => {
    return <>{props.ClaSentidoMejora === 1 ?  (<i className="fas fa-sort-amount-up"></i>): (<i className="fas fa-sort-amount-down"></i>)}</>;
  }; 

  return (
    <> 
      <Row className="align-items-start ">
        <Col lg={8} md={8} sm={12} xs={12}>
          {OpcionesMeses.map((element, index) => {

            if (element.EsMesDisponible === 0) {
              return (
                <div key={index} style={{ paddingRight: '7px', float: 'left' }}>
                  <FieldButton 
                    label={element.NomMesCorto}  
                    disabled
                    className="light border-none btn-shadow"
                  >
                  </FieldButton>
                </div>
              )
            }
            return (
              <div key={index} style={{ paddingRight: '7px', float: 'left' }} className="relative">
                <FieldButton 
                  label={element.NomMesCorto} 
                  onClick={(props) => onClickViewDetailMonth(element.ClaMes, props)}
                  className={`${ClaMes === element.ClaMes ? "light-purple" : "light"} border-none btn-shadow`}
                >
                </FieldButton>
                <div className="right-top-badge" hidden={element.EsMesDisponible===0}>  
                  <Badge
                    value={element.EsCerrado === 0  ?
                      <i className="fa fa-unlock primary-color" title={t('common:Open')}></i>  
                        : 
                      <i className="fa fa-lock warning-color" title={t('common:Closed')}></i>}
                    className="white-badge"
                  />  
                </div> 
              </div>
            );
          })}
        </Col>
        <Col lg={4} md={4} sm={12} xs={12} className="text-right"> 
          <FieldButton
            type="button"
            onClick={() => onSaveMes()}
            label={t('common:Save')}
            icon="pi pi-save"
            className="p-button-success no-border p-mr-2 rounded-button"
            style={{ marginRight: '.25em' }}
          />  
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          &nbsp;
        </Col>
      </Row>
      <Row className="align-items-start ">
        <Col lg={12} md={12} sm={12} xs={12}>
          <FieldDataTable
            value={showActiveRows(PuestoIndicadorResultado)}
            scrollable
            scrollHeight="400px"
            className="editable-cells-table"
            editMode="cell" 
            paginator={false}
            footerColumnGroup={footer}
            selection={SelectedPuestoIndicador} 
            selectionMode="single" 
            onSelectionChange={e => setSelectedPuestoIndicador(e.value)} 
          >
            <Column
              field="IndexRole"
              header={t('focusReport:IndexRole')}
              className="text-center"
              columnKey="IndexRole"
              headerStyle={{ width: '55px', textAlign: 'center' }}
            >
            </Column>

            <Column
              field="IndexType"
              header={t('focusReport:IndexType')}
              className="text-center"
              columnKey="IndexType"
              headerStyle={{ width: '55px', textAlign: 'center' }}
            >
            </Column>
            <Column
              field="ClaveIndicador"
              className="text-center"
              header={t('focusReport:Code')}
              columnKey="Code"
              headerStyle={{ width: '55px', textAlign: 'center' }}
            >
            </Column>
            <Column
              field="ClaSentidoMejora"
              className="text-center"
              header=''
              columnKey="ClaSentidoMejora"
              headerStyle={{ width: '20px' }}
              body={SentidoMejoraTemplate}
            >
            </Column> 
            <Column
              field="Description"
              header={t('focusReport:IndexDescription')}
              columnKey="Description"
              headerStyle={{ width: '250px' }}
            >
            </Column>
            <Column
              field="PesoCalif"
              className="text-center"
              header={t('focusReport:Weighted')}
              columnKey="PesoCalif"
              headerStyle={{ width: '65px', textAlign: 'center' }}
            >
            </Column>
            <Column
              field="EstatusPeriodo" 
              header={t('focusReport:CurrentStatus')}
              columnKey="EstatusPeriodo"
              headerStyle={{ width: '100px' }}
              style={{ width: '100px'  }}
              editor={(props) => CurrentStatusEditor(props)}                            
              body={(rowData, props) => EstatusPeriodoTemplate(rowData, props)}
            >
            </Column>
            {/* body={EstatusPeriodoTemplate} */}
            {/* <Column
              field="PeriodRow"
              className="text-center"
              header={t('focusReport:PeriodRow')}
              columnKey="PeriodRow"
              headerStyle={{ width: '65px', textAlign: 'center' }}
            >
            </Column>
            <Column
              field="Trend"
              className="text-center"
              header={t('focusReport:Trend')}
              columnKey="Trend"
              headerStyle={{ width: '65px', textAlign: 'center' }}
            >
            </Column> */}
            <Column
              className="text-center"
              header={t('focusReport:Uom')}
              headerStyle={{ width: '65px', textAlign: 'center' }}
              body={AccionesTemplateUoM}
            >
            </Column>
            <Column
              field="MinimunAcceptable"
              className="text-center"
              header={t('focusReport:MinimunAcceptable')}
              columnKey="MinimunAcceptable"
              headerStyle={{ width: '100px', textAlign: 'center' }}
              body={MinimoTemplate}
            >
            </Column>
            <Column
              field="Satisfactory"
              className="text-center"
              header={t('focusReport:Satisfactory')}
              columnKey="Satisfactory"
              headerStyle={{ width: '80px', textAlign: 'center' }}
              body={SatisfactorioTemplate}
            >
            </Column>
            <Column
              field="OutStanding"
              className="text-center"
              header={t('focusReport:OutStanding')}
              columnKey="OutStanding"
              headerStyle={{ width: '80px', textAlign: 'center' }}
              body={SobreSalienteTemplate}
            >
            </Column>
            {/* 
            <Column
              className="text-center"
              header={t('focusReport:ExpectedTrend')}
              headerStyle={{ width: '65px', textAlign: 'center' }}                  
              body={AccionesTemplateExpectedTrend}
            >
            </Column> */}
          </FieldDataTable>
        </Col>
      </Row>
      {
        EsMostrarFormulaReview && 
        (  
          <KPIFormulaResultadoReview show={EsMostrarFormulaReview} onHide={() => setEsMostrarFormulaReview(false)} AnioMes={`${ClaAnio}${ClaMes<10?`0${ClaMes}`:ClaMes}`} IdKPIFormula={IdKPIFormulaSelected}></KPIFormulaResultadoReview>
        )
      }
    </>
  );
};

export default PuestoIndicadorResultadoMensual;

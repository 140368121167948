/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next';  
import { Column } from 'primereact/column';
import { Row, Col , 
    ListGroupItem,
    ListGroup,
    Progress,
    Card,
    CardHeader,
    CardBody,
    CardFooter} from 'reactstrap';   
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple'; 
import { Badge } from 'primereact/badge';
import { Link } from "react-router-dom";
import { config } from '../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../utils/utils';  
import Score from '../../../components/Cards/Score/Score.jsx'
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import Highcharts from 'highcharts';
import moment from 'moment';
import FieldButton from 'components/Controls/FieldButton/FieldButton';

const SalesDashboardDetailInvoices = ({ClaUsuarioSalesRep, AnioMes, Fecha, EstatusTipoCliente, EsShowNewOrder, TipoDetalleInformacion}) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['salesDashboard', 'common']);
   
  const [InvoicesDetailInfo, setInvoicesDetailInfo] = useState([]); 

  const InvoicesDetailGrid = useRef(null);


  useEffect(() => {     
     // consultamos la informacion
     getSalesWTWDashboardInvoiceDetail();
  }, [AnioMes, Fecha, ClaUsuarioSalesRep, EstatusTipoCliente,EsShowNewOrder, TipoDetalleInformacion]); 

  const getSalesWTWDashboardInvoiceDetail = () => {  
    const GetSalesWTWDashboardInvoiceDetail = `${config.UrlApiProject}SalesPerformanceSales/GetSalesWTWDashboardInvoiceDetail`;  
    const paramsToService = {   
      AnioMes:            AnioMes             !== undefined ? AnioMes : null,
      Fecha:              Fecha               !== undefined ? Fecha : null,
      EstatusTipoCliente: EstatusTipoCliente  !== undefined ? EstatusTipoCliente : null,
      ClaUsuarioSalesRep: ClaUsuarioSalesRep  !== undefined ? ClaUsuarioSalesRep : null ,
      EsShowNewOrder:     EsShowNewOrder      !== undefined ? EsShowNewOrder : false,
      TipoDetalleInformacion: TipoDetalleInformacion !== undefined ? TipoDetalleInformacion : null
    };     

    console.log('paramsToService', paramsToService);

    callApi(GetSalesWTWDashboardInvoiceDetail, 'POST', paramsToService, (response) => { 
          
        setInvoicesDetailInfo(response.data.InvoiceDetailInfo);   
    });
  } 
   
  const header = (
    <>&nbsp;</>
  );

  const stringColumn = (row, columnName) => {
    return (
      <>
        <span className="overflow-ellipsis" title={row[columnName]}>{row[columnName]}</span>
      </>
    );
  } 

  const integerColumn = (row, columnName) => {

    if (row[columnName] === null){
      return (<></>);
    }

    return (
      <>
        <span>{Highcharts.numberFormat(row[columnName], 0, '.', ',')}</span>
      </>
    );
  } 
 
  const numericColumn = (row, columnName) => {
    if (row[columnName] === null){
      return (<></>);
    }

    return (
      <>
        <span>{Highcharts.numberFormat(row[columnName], 2, '.', ',')}</span>
      </>
    );
  } 

  const priceColumn = (row, columnName) => {
    if (row[columnName] === null){
      return (<></>);
    }

    return (
      <>
        <span>${Highcharts.numberFormat(row[columnName], 2, '.', ',')}</span>
      </>
    );
  } 

  const dateColumn = (row, columnName) => {
    if (row[columnName] === null){
      return (<></>);
    }

    return (
      <>
        <span>{moment(row[columnName]).format('MM/DD/YYYY')}</span>
      </>
    );
  } 

  const footer  = ( 
    <> 
      <Row>
        <Column></Column>   
        <Column></Column>
        <Column></Column>       
        <Column></Column>
        <Column></Column>
        <Column></Column>
        <Column className="text-right" footer={() => (<b>{ (InvoicesDetailInfo.reduce((a,v) =>  a += v.Qty === null ? 0 : v.Qty , 0 )).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) }</b>)}></Column>     
        <Column></Column>
        <Column className="text-right" footer={() => (<b>${ (InvoicesDetailInfo.reduce((a,v) =>  a += v.Revenue === null ? 0 : v.Revenue , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>     
        <Column className="text-right" footer={() => (<b>{ (InvoicesDetailInfo.reduce((a,v) =>  a += v.MTTons === null ? 0 : v.MTTons , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>     
        <Column></Column>
        <Column></Column>
        <Column></Column>
        <Column></Column>
        <Column></Column>
        <Column></Column>
        <Column></Column>
        <Column></Column>
        <Column></Column>         
        <Column></Column>         
        <Column></Column>         
        <Column></Column>         
        <Column></Column>         
        <Column></Column>  
      </Row>
    </>
  )

  const onExportGrid = () => {  
    InvoicesDetailGrid.current.exportCSV();
 }
   
  return (
    <div className='SalesDashboardScreen'>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>  
          <Row>
            <Col>
               
            </Col>
            <Col className='text-right pb-3'>
              <FieldButton  
                className="p-button p-button-rounded p-button-secondary"
                onClick={onExportGrid}
              >
                <i className="pi pi-download"></i>&nbsp;Export to Excel
              </FieldButton>
            </Col> 
          </Row>  
          <FieldDataTable 
            refererence={InvoicesDetailGrid}
            value={InvoicesDetailInfo}   
            scrollable  
            className="p-datatable-striped p-datatable-footer-no-pading"  
            selectionMode="single"  
            metaKeySelection={false} 
            scrollHeight="360px"
            footerColumnGroup={footer}
          >      
            <Column field="IdPedido" header={t('salesDashboard:IdPedido')} headerStyle={{ width: '95px' }} className="text-left overflow-ellipsis" body={(row) => stringColumn(row, 'IdPedido')}></Column> 
            <Column field="ClaveFactura" header={t('salesDashboard:ClaveFactura')} headerStyle={{ width: '100px' }} className="text-left overflow-ellipsis" body={(row) => stringColumn(row, 'ClaveFactura')}></Column>  
            <Column field="ClaCuC" header={t('salesDashboard:ClaCuC')} headerStyle={{ width: '90px' }} className="text-left overflow-ellipsis" body={(row) => stringColumn(row, 'ClaCuC')}></Column>  
            <Column field="NomCUC" header={t('salesDashboard:NomCUC')} headerStyle={{ width: '230px' }} className="text-left overflow-ellipsis" body={(row) => stringColumn(row, 'NomCUC')}></Column>  
            {
              /*
                <Column header={t('salesDashboard:ClaveArticulo')} headerStyle={{ width: '100px' }} className="text-left" body={(row) => stringColumn(row, 'ClaveArticulo')}></Column>  
              */
            }
            <Column field="NomArticulo" header={t('salesDashboard:NomArticulo')} headerStyle={{ width: '350px' }} className="text-left overflow-ellipsis" body={(row) => stringColumn(row, 'NomArticulo')}></Column>  
            <Column field="Unidad" header={t('salesDashboard:Unidad')} headerStyle={{ width: '80px' }} className="text-left overflow-ellipsis" body={(row) => stringColumn(row, 'Unidad')}></Column>  
            <Column field="Qty" header={t('salesDashboard:Qty')} headerStyle={{ width: '90px' }} className="text-right overflow-ellipsis" body={(row) => integerColumn(row, 'Qty')}></Column>  
            <Column field="Price_qty" header={t('salesDashboard:Price_qty')} headerStyle={{ width: '100px' }} className="text-right overflow-ellipsis" body={(row) => priceColumn(row, 'Price_qty')}></Column>  
            <Column field="Revenue" header={t('salesDashboard:Revenue')} headerStyle={{ width: '100px' }} className="text-right overflow-ellipsis" body={(row) => priceColumn(row, 'Revenue')}></Column>  
            <Column field="MTTons" header={t('salesDashboard:MTTons')} headerStyle={{ width: '100px' }} className="text-right overflow-ellipsis" body={(row) => numericColumn(row, 'MTTons')}></Column>  
            <Column field="PriecTon" header={t('salesDashboard:PriecTon')} headerStyle={{ width: '100px' }} className="text-right overflow-ellipsis" body={(row) => priceColumn(row, 'PriceTon')}></Column>  
            <Column field="FreightbyTon" header={t('salesDashboard:FreightbyTon')} headerStyle={{ width: '100px' }} className="text-right overflow-ellipsis" body={(row) => priceColumn(row, 'FreightbyTon')}></Column>  
            <Column field="PriceTonGross" header={t('salesDashboard:PriceTonGross')} headerStyle={{ width: '100px' }} className="text-right overflow-ellipsis" body={(row) => priceColumn(row, 'PriceTonGross')}></Column>  
            <Column field="FechaFactura" header={t('salesDashboard:FechaFactura')} headerStyle={{ width: '100px' }} className="text-center overflow-ellipsis" body={(row) => dateColumn(row, 'FechaFactura')}></Column>  
            <Column field="ShipToName" header={t('salesDashboard:ShipToName')} headerStyle={{ width: '180px' }} className="text-left overflow-ellipsis" body={(row) => stringColumn(row, 'ShipToName')}></Column>  
            <Column field="NomAgenteVenta" header={t('salesDashboard:NomAgenteVenta')} headerStyle={{ width: '180px' }} className="text-left overflow-ellipsis" body={(row) => stringColumn(row, 'NomAgenteVenta')}></Column>  
            <Column field="NomGrupoEstadistico1" header={t('salesDashboard:NomGrupoEstadistico1')} headerStyle={{ width: '140px' }} className="text-left overflow-ellipsis" body={(row) => stringColumn(row, 'NomGrupoEstadistico1')}></Column> 
            <Column field="NomGrupoEstadistico2" header={t('salesDashboard:NomGrupoEstadistico2')} headerStyle={{ width: '140px' }} className="text-left overflow-ellipsis" body={(row) => stringColumn(row, 'NomGrupoEstadistico2')}></Column> 
            <Column field="NomGrupoEstadistico3" header={t('salesDashboard:NomGrupoEstadistico3')} headerStyle={{ width: '140px' }} className="text-left overflow-ellipsis" body={(row) => stringColumn(row, 'NomGrupoEstadistico3')}></Column> 
            <Column field="NomSegmento" header={t('salesDashboard:NomSegmento')} headerStyle={{ width: '140px' }} className="text-left overflow-ellipsis" body={(row) => stringColumn(row, 'NomSegmento')}></Column> 
            <Column field="IdFactura" header={t('salesDashboard:IdFactura')} headerStyle={{ width: '140px' }} className="text-left overflow-ellipsis" body={(row) => stringColumn(row, 'IdFactura')}></Column>  
            <Column field="FechaPedido" header={t('salesDashboard:FechaPedido')} headerStyle={{ width: '100px' }} className="text-center overflow-ellipsis" body={(row) => dateColumn(row, 'FechaPedido')}></Column>  
            <Column field="NeededDate" header={t('salesDashboard:NeededDate')} headerStyle={{ width: '100px' }} className="text-center overflow-ellipsis" body={(row) => dateColumn(row, 'NeededDate')}></Column>   
            <Column field="PromiseDate" header={t('salesDashboard:PromiseDate')} headerStyle={{ width: '100px' }} className="text-center overflow-ellipsis" body={(row) => dateColumn(row, 'PromiseDate')}></Column> 
          </FieldDataTable>  
        </Col>
      </Row> 
    </div>  
        ); 
};

export default SalesDashboardDetailInvoices;

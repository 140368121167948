/* eslint-disable object-shorthand */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useState , useEffect }  from 'react';    
import CardSparkLine from '../../../components/Charts/Card-SparkLine/CardSparkLine';
import { 
  Row, 
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter
} from 'reactstrap';
import Highcharts from 'highcharts'; 
import HighchartsReact from 'highcharts-react-official';
import HighchartsHeatmap from "highcharts/modules/heatmap";
import { config } from '../../../utils/config';
import { callApi } from '../../../utils/utils'; 
import queryString from 'query-string'  
import moment from 'moment'
import { useTranslation } from 'react-i18next';
import SparkLine from '../../../components/Charts/SparkLine/SparkLine.jsx'
import { Tag } from 'primereact/tag';
 
HighchartsHeatmap(Highcharts);

const OperationScrapDashboard =  (props) => {

    
   const { t, i18n } = useTranslation(['productionDashboard', 'common']);
   
   let defaultZona;
   let defaultFechaInicial = null;
   let defaultFechaFinal= null;

   if(props.location!==undefined){       
    const params = queryString.parse(props.location.search);
    defaultZona = params !== undefined ? params.ClaZona : 0;
   }
   else {
    defaultZona = props.filters.ClaZona  !== undefined ? props.filters.ClaZona : 0;
    defaultFechaInicial = props.filters.FechaInicial  !== undefined ? props.filters.FechaInicial : null;
    defaultFechaFinal = props.filters.FechaFinal  !== undefined ? props.filters.FechaFinal : null;
   }
 
   const [claZona, setClaZona] = useState(defaultZona);
   const [fechaInicial, setFechaInicial] = useState(null);
   const [fechaFinal, setFechaFinal] = useState(null);
   const [nomZona, setNomZona] = useState('');
   const [totalDias, setTotalDias] = useState('');
   const [scrapData, setScrapData] = useState([]);
   const [scrapTotal, setScrapTotal] = useState(0);
   const [scrapControllable, setScrapControllableData] = useState([]);
   const [scrapControllableCategories, setScrapControllableCategories] = useState(0);
   const [scrapSeries, setScrapSeries] = useState([]);
   const [scrapXCategories, setScrapXCategories] = useState([]);
   const [scrapYCategories, setScrapYCategories] = useState([]);
   const [sparkLinesChartValues, setSparkLinesChartValues] = useState([]);

    const onSearch = (filters) => {
        let urlWebService = `${config.UrlApiProject}Produccion/GetGraficoScrapHeatMapData`; 
        
        const paramsService = {
            ...filters,
          }; 

        callApi(urlWebService, 'POST', paramsService, (response) => { 
            setScrapSeries(response.data.series);
            setScrapYCategories(response.data.yAxisCategories);
            setScrapXCategories(response.data.xAxisCategories); 
        }, () => {
          // no hacemos nada solo  para evitar de mandar el mensaje si algun momento se pierde la conexion...
        });

        urlWebService = `${config.UrlApiProject}Produccion/GetGraficoScrapData`; 
    
        callApi(urlWebService, 'POST', paramsService, (response) => {
            setSparkLinesChartValues(response.data.series);
            setScrapData(response.data.series);
            setScrapTotal(response.data.total != null ? response.data.total.toFixed(1) : 0); 
        }, () => {
          // no hacemos nada solo  para evitar de mandar el mensaje si algun momento se pierde la conexion...
        });

        urlWebService = `${config.UrlApiProject}Produccion/GetGraficoControllableData`; 
    
        callApi(urlWebService, 'POST', paramsService, (response) => {
            setScrapControllableData(response.data.series); 
        }, () => {
          // no hacemos nada solo  para evitar de mandar el mensaje si algun momento se pierde la conexion...
        });
         
      };
       
    const cfgCtrvsUnCtr = { 
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        title: {
            text: null
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        }, 
        legend: {    
            align: 'left',
            layout: 'vertical',
            verticalAlign: 'mididle',
            itemMarginTop: 1,
            itemMarginBottom: 1, 
            labelFormatter: function () {
                 const { y, percentage, name } = this; 
 
                return  `${   name  } <br><i> ${ Highcharts.numberFormat(y,1,'.',',') } ${t('common:pounds')} - ${Highcharts.numberFormat(percentage,1,'.',',') } %</i>`;
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer', 
                size: '100%',
                dataLabels: {
                    enabled: false  
                },
                showInLegend: true
            }
        },
    
        xAxis: { 
            categories: scrapControllableCategories
        },
        series: [{
            name: 'Scrap',
            colorByPoint: true,
            data: scrapControllable
        }],
        credits: {
            enabled: false
        }
    }

    const scrapHeatMap = {

        chart: {
            type: 'heatmap',
            marginTop: 40,
            marginBottom: 80,
            plotBorderWidth: 1
        }, 
         
        title: {
            text: t('productionDashboard:chartScrapByCellTitle')
        },
    
        xAxis: { 
            categories: scrapXCategories
        },
    
        yAxis: { 
            title:"",
            categories: scrapYCategories,
            reversed: true
        },

        tooltip: {
          formatter: function() {  
            const { x, y, point, series } = this; 
            return `<b>${t('productionDashboard:chartScrapByCellTitle') }</b> <br/> <b>${t('formulario:Cell')}:</b> ${  series.yAxis.categories[y]  }</b><br/><b>${t('formulario:Day')}:</b> ${  series.xAxis.categories[point.x]  }   <br/><b>${t('formulario:Value')}:</b>  ${Highcharts.numberFormat(point.value,1)  }`;
          }
        },
       
    
        legend: {
            align: 'right',
            layout: 'vertical',
            margin: 0,
            verticalAlign: 'top',
            y: 25,
            symbolHeight: 280
        }, 
    
        series: [{
            name: t('productionDashboard:chartScrapByCellTitle'),
            borderWidth: 1,
            color: '#e4e4e4',
            data: scrapSeries,
            showInLegend:false,
            dataLabels: {
                enabled: true,
                color: '#FFFFFF'
            }
        }], 
    
    }
 

    useEffect(() => {
      const filter = {}
      filter.ClaZona = defaultZona
      filter.FechaInicial = defaultFechaInicial
      filter.FechaFinal = defaultFechaFinal

      const urlWebService = `${config.UrlApiProject}Produccion/GetOperacionDashboardHeaderInfo`;
      const paramsService = {
          ...filter,
        }; 
    
        callApi(urlWebService, 'POST', paramsService, (response) => 
             { 
                setFechaInicial(response.data.FechaInicial);
                setFechaFinal(response.data.FechaFinal); 
                setNomZona(response.data.NomZona); 
                setTotalDias(response.data.TotalDias); 
                const filter = {}
                filter.ClaZona      = response.data.ClaZona;
                filter.FechaInicial = response.data.FechaInicial;
                filter.FechaFinal = response.data.FechaFinal;
                onSearch(filter);
            
            }, () => {
              // no hacemos nada solo  para evitar de mandar el mensaje si algun momento se pierde la conexion...
            }
        ); 
        
      }, [props.filters]);

    return (  
      <>     
        <Row>  
          <Col xs={12} md={12} sm={12} lg={12}>
            <div className="header-dashboard">
              <Row> 
                <Col xs={12} md={12} sm={12} lg={12} className="header-dashboard-title"> 
                  {t('productionDashboard:scrapDashboardTitle') } 
                </Col> 
                <Col xs={12} md={12} sm={12} lg={12}> 
                  <Row> 
                    <Col xs={12} md={6} sm={12} lg={6}> 
                      <div className="header-dashboard-sub-title">
                        {t('productionDashboard:Last')} {totalDias} {t('productionDashboard:Daysfrom') } <b> { moment(fechaInicial).format('MMMM Do YYYY')  } </b> {t('common:to') }  <b> {moment(fechaFinal).format('MMMM Do YYYY') }  </b> 
                      </div>
                    </Col> 
                    <Col xs={12} md={6} sm={12} lg={6}> 
                      <div className="header-dashboard-sub-title-2">
                        {nomZona}
                      </div> 
                    </Col> 
                  </Row> 
                </Col> 
              </Row>
            </div> 
          </Col> 
        </Row> 
        <Row>  
          <Col xs={12} md={12} sm={12} lg={12}>
                &nbsp;
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={6} sm={6} lg={6}> 
            {sparkLinesChartValues.map((bucket) => { 
              return (
                <Col lg={12} md={4} sm={6} xs={6} key={bucket.name}> 
                  <CardSparkLine theme="blue" categories={bucket.categories} title={t('productionDashboard:cardScrapTrendTitle')} series={bucket.serieReal} footerValue={bucket.name}></CardSparkLine>
                </Col>
            );
          })} 
          </Col>
          <Col xs={6} md={6} sm={6} lg={6}>
            <HighchartsReact containerProps={{ style: { height: "150px" } }} highcharts={Highcharts} options={cfgCtrvsUnCtr} />
          </Col>
        </Row> 
        <Row>  
          <Col xs={12} md={12} sm={12} lg={12}>
            <div className="paddingLeft10 paddignTop10">
              <Tag style={{ background: '#d9d9d9', color:"#777777" }}>0%</Tag>&nbsp;
              <Tag style={{ background: '#00b050' }}>0.1-1.9%</Tag>&nbsp;
              <Tag style={{ background: '#fb927c' }}>2-5%</Tag>&nbsp;
              <Tag style={{ background: '#e74545' }}>5.1-8%</Tag>&nbsp;
              <Tag style={{ background: '#ae123a' }}>8.1% +</Tag> 
            </div>
            <HighchartsReact containerProps={{ style: { height: "500px" } }} highcharts={Highcharts} options={scrapHeatMap} />
          </Col>
        </Row>

      </> 
    )
}   

    
export default OperationScrapDashboard; 

/* eslint-disable object-shorthand */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-template */

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import { config } from 'utils/config';
import { callApi, showSweetAlert, showNotify } from 'utils/utils';
import FieldSelectButton from 'components/Controls/FieldSelectButton';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import YupValidator from 'utils/YupValidator';
import * as yup from 'yup';
// import 'assets/css/karmaBase.css'
// import 'assets/css/DataViewBase.css';

const SolicitudMaterialCelda = (props) => {
  const { t, i18n } = useTranslation(['operations', 'common']);
  const [errors, setErrors] = useState([]);
  const validationScheme = yup.object().shape({
    celda: yup.object().required(t('common:RequiredField')).nullable(),
    turno: yup.object().required(t('common:RequiredField')).nullable(),
    departamento: yup.object().required(t('common:RequiredField')).nullable(),
    material: yup.object().required(t('common:RequiredField')).nullable(),
    quantity: yup.number().required(t('common:RequiredField')).positive().integer().nullable(),
  });
  const validationSchemeIsDevice = yup.object().shape({
    material: yup.object().required(t('common:RequiredField')).nullable(),
    quantity: yup.number().required(t('common:RequiredField')).positive().integer().nullable(),
  });

  const [departamentos, setDepartamentos] = useState([]); // Departments
  const [turnos, setTurnos] = useState([]); // Shifts
  const [celdas, setCeldas] = useState([]); // Cells
  const [materials, setMaterials] = useState([]); // Materials
  const [departamento, setDepartamento] = useState([]); // Selected department
  const [turno, setTurno] = useState([]); // Selected Shift
  const [celda, setCelda] = useState([]); // Selected cell
  const [material, setMaterial] = useState([]); // Selected material
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [quantities, setQuantities] = useState([]);
  const [quantity, setQuantity] = useState(null);
  const [IsInBundle, setIsInBundle] = useState(false);
  const [IsNewHomeVersion, setIsNewHomeVersion] = useState(false);

  useEffect(() => {
    setTurno(props.turno === null || props.turno === undefined ? null : props.turno);
    setCelda(props.celda === null || props.celda === undefined ? null : props.celda);
    setDepartamento(
      props.departamento === null || props.departamento === undefined ? null : props.departamento
    );
    setIsNewHomeVersion(
      props.IsNewHomeVersion === null || props.IsNewHomeVersion === undefined
        ? false
        :  Boolean(props.IsNewHomeVersion)
    );
  }, [props.turno, props.celda, props.departamento, props.IsNewHomeVersion]);

  // Get departments
  const getDepartamentos = () => {
    const urlWebService = `${config.UrlApiProject}departamento/getDepartamentos`;
    const paramsService = null;

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setDepartamentos(response.data.Departamentos);
    });
  };

  // Get materials
  const getMaterials = () => {
    const urlWebService = `${config.UrlApiProject}operations/getMaterials`;
    const paramsService = null;

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setMaterials(response.data.Materials);
    });
  };

  // Get shifts
  const getTurnos = () => {
    const urlWebService = `${config.UrlApiProject}turno/getTurnos`;
    const paramsService = null;

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setTurnos(response.data.Turnos);
    });
  };

  // Get cells
  const getCeldas = (claDepartamento) => {
    const urlWebService = `${config.UrlApiProject}celda/GetCeldas?ClaDepartamento=${claDepartamento}`;
    const paramsService = null;

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setCeldas(response.data.Celdas);
    });
  };

  // Submit material request
  const postRequest = (claDepartamento, claTurno, claCelda, claTipoMaterial, numQuantity) => {
    const urlWebService = `${config.UrlApiProject}Operations/SaveSolicitudMaterialCelda`;
    const paramsService = {
      ClaDepartamento: claDepartamento,
      ClaTurno: claTurno,
      ClaCelda: claCelda,
      ClaTipoMaterial: claTipoMaterial,
      Quantity: numQuantity,
    };

    callApi(urlWebService, 'POST', paramsService, (response) => {
      if (props.onAddNewHide != null) {
        props.onAddNewHide();
      } else {
        // showSweetAlert("Success", "Request submitted successfuly", 'success');
        setDepartamento([]);
        setTurno([]);
        setCelda([]);
        setCeldas([]);
        setMaterial([]);
        setQuantity(null);

        if (typeof props.onSubmitMaterialRequest === 'function') {
          showNotify('Request submitted successfully', 'centerTop', 'success');
          props.onSubmitMaterialRequest();
        } else {
          showSweetAlert('success', 'Request submitted successfully', 'success');
        }
      }
    });
  };

  // Handle department click
  const onDepartamentoClick = (e) => {
    const newErrors = { ...errors };
    delete newErrors.departamento;
    setErrors(newErrors);
    setDepartamento(e.value);
    if (e.value != null) {
      getCeldas(e.value.ClaDepartamento4);
    }
  };

  // Handle shift click
  const onTurnoClick = (e) => {
    const newErrors = { ...errors };
    delete newErrors.turno;
    setErrors(newErrors);
    setTurno(e.value);
  };

  // Handle cell click
  const onCeldaClick = (e) => {
    const newErrors = { ...errors };
    delete newErrors.celda;
    setErrors(newErrors);
    setCelda(e.value);
  };

  const onMaterialClick = (e) => {
    const newErrors = { ...errors };
    delete newErrors.material;
    setErrors(newErrors);
    setMaterial(e.value);

    const q = [];
    if (e.value.Quantity != null) {
      for (let i = 1; i <= e.value.Quantity; i++) {
        q.push(i);
      }
      setQuantities(q);
    }

    setIsInBundle(e.value.IsInBundle);
  };

  const onQuantityClick = (e) => {
    const newErrors = { ...errors };
    delete newErrors.quantity;
    setErrors(newErrors);
    setQuantity(e.value);
  };

  // Handle submit click
  const onSubmitClick = async (e) => {
    if (IsNewHomeVersion) {
      const value = {
        material,
        quantity,
      };
      const validator = new YupValidator(validationSchemeIsDevice);
      const isValid = await validator.validate(value);
      if (!isValid) {
        setErrors(validator.errors);
      } else {
        postRequest(          
          departamento,
          turno,
          celda,
          material.ClaTipoMaterial,
          quantity
        );
      }
    } else {
      const value = {
        departamento,
        turno,
        celda,
        material,
        quantity,
      };
      const validator = new YupValidator(validationScheme);
      const isValid = await validator.validate(value);

      if (!isValid) {
        setErrors(validator.errors);
      } else {
        postRequest(
          departamento.ClaDepartamento4,
          turno.ClaTurno,
          celda.ClaCelda,
          material.ClaTipoMaterial,
          quantity
        );
      }
    }
  };

  useEffect(() => {
    getDepartamentos();
    getTurnos();
    getMaterials();
  }, [isSubmitted]);

  return (
    <>
      <Row>
        <Col xs={12} md={12} sm={12} lg={12}>
          {!IsNewHomeVersion ? (
            <>
              <FormGroup>
                <Label>{t('operations:departmentLabel')}</Label>
                <FieldSelectButton
                  name="departamento"
                  value={departamento}
                  options={departamentos}
                  optionLabel="NomDepartamento4"
                  optionvalue="ClaDepartamento4"
                  onChange={onDepartamentoClick}
                  errors={errors}
                >
                </FieldSelectButton>
              </FormGroup>
              <FormGroup className="mt-4">
                <Label>{t('operations:shiftLabel')}</Label>
                <FieldSelectButton
                  name="turno"
                  value={turno}
                  options={turnos}
                  optionLabel="NomTurno"
                  optionvalue="ClaTurno"
                  onChange={onTurnoClick}
                  errors={errors}
                >
                </FieldSelectButton>
              </FormGroup>
              <FormGroup className="mt-4">
                {celdas.length > 0 ? (
                  <>
                    <Label>{t('operations:cellLabel')}</Label>
                    <FieldSelectButton
                      name="celda"
                      className="cellList scrollbar-primary scroll-y"
                      value={celda}
                      options={celdas}
                      optionLabel="ClaveCelda"
                      optionvalue="ClaCelda"
                      onChange={onCeldaClick}
                      errors={errors}
                    >
                    </FieldSelectButton>
                  </>
                ) : (
                  <></>
                )}
              </FormGroup>
            </>
          ) : (
            <></>
          )}
          <FormGroup className="mt-4">
            <Label>{t('operations:materialLabel')}</Label>
            <FieldSelectButton
              name="material"
              value={material}
              options={materials}
              optionLabel="NomTipoMaterial"
              optionvalue="ClaTipoMaterial"
              onChange={onMaterialClick}
              errors={errors}
              className="buttonListSurveyTablet"
            >
            </FieldSelectButton>
          </FormGroup>
          <FormGroup className="mt-4">
            {quantities.length > 0 ? (
              <>
                <Label>{t('operations:quantityLabel')} {IsInBundle?(<>{t('operations:inBundle')}</>):(<></>)}</Label>
                <FieldSelectButton
                  name="quantity"
                  value={quantity}
                  options={quantities}
                  onChange={onQuantityClick}
                  errors={errors}
                  className="buttonListSurveyTablet"
                >
                </FieldSelectButton>
              </>
            ) : (
              <></>
            )}
          </FormGroup>
          <FormGroup className="mt-4">
            <div className="p-fluid">
              <FieldButton
                label={t('operations:submitButtonLabel')}
                onClick={onSubmitClick}
                icon="pi"
                className="light-green no-border rounded-button p-3 pl-5 pr-5  font-weight-bold btntablet"
              >
              </FieldButton>
            </div>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default SolicitudMaterialCelda;

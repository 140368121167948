
import React from 'react';

const StepsTourCostAnalysisSummary = {
    steps: [
      {
        target: '.AC-Step-1',
        content: (
          <>
            <h1>Filters Section</h1>
            Use this section to filter your data. Remember, you will need to fill in all required fields. 
            <br></br>
            <br></br>
            Do not forget to click the <b>Search Icon</b> when you are ready.  
          </>
        ),
        disableBeacon: true
      },
      {
        target: '.AC-Step-2',
        content: (
          <>
            <h1>Tab Section</h1>
            Click the tab to change between tabs. 
          </>
        )
        /* ,
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: true,
        placement: 'bottom',
        spotlightClicks: true,
        */
      } ,
      {
        target: '.AC-Step-3',
        content: (
          <>
            <h1>Customize Drilldown</h1>
            The system sets a drill down by default, but you can customize it here.
          </>
        ),
        styles: {
            options: {
              zIndex: 10000,
            },
          }
      } ,
      {
        target: '.AC-Step-4',
        content: (
          <>
            <h1>Cost Analysist Results</h1>
            <div style={{ textAlign:'left'}}> 
              The months displayed will depend on the month you selected in the filters. For example, if you selected March it will display results from January to March of the selected year.     
              <br></br> 
              <br></br> 
              You can expand / collapse the row by clicking the arrow at the beginning of the row.
              <br></br> 
              <br></br>
            </div> 
            <small><b>Remember, the drill down depends on what you selected in the customizations.</b></small> 
            
          </>
        ),
        styles: {
          options: {
            zIndex: 10000,
          },
        } 
      }  
    ]
  };

  
export default StepsTourCostAnalysisSummary;
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col, 
  Card,
  CardHeader,
  CardBody 
} from 'reactstrap'; 
// import FeedbackCoaching from './FeedbackCoaching.jsx'; 
import Highcharts from 'highcharts';
import { config } from "../../../utils/config";
import { callApi,showSweetAlert } from '../../../utils/utils';  
import { Dialog } from 'primereact/dialog'; 
import { TreeList, Column, Editing, Scrolling, Paging, Pager, SearchPanel, Selection, Format } from 'devextreme-react/tree-list';
import 'devextreme/dist/css/dx.light.css'; 
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldButton from 'components/Controls/FieldButton/FieldButton'; 
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import FieldMultiSelect from 'components/Controls/FieldMultiSelect';
import { TreeTable } from 'primereact/treetable';  
import FieldCheckbox from 'components/Controls/FieldCheckbox';
import MonthlyProductionSummarySkeleton from './MonthlyProductionSummarySkeleton';
import { TabView, TabPanel } from 'primereact/tabview';
import MonthlyProductionSummaryChart from './MonthlyProductionSummaryChart';
import MonthlyProductionSummaryDetail from './MonthlyProductionSummaryDetail';
import moment from 'moment';

const MonthlyProductionSummary = (props) => {
  /* ################## useState area start ################## */
const { t } = useTranslation(['wtw', 'common']); 
const [TrendList, setTrendList] = useState([]);
const [TrendDetail, setTrendDetail] = useState([]);
const [EsVerDetalleChart, setEsVerDetalleChart] = useState(false);
const [ProduccionSeguimientoReporteList, setProduccionSeguimientoReporteList] = useState([]);
const [IsLoading, setIsLoading] = useState(false);
const [IsExporting, setIsExporting] = useState(false);
const [Anio, setAnio] = useState(null);
const [Mes, setMes] = useState();
const [ClaMes, setClaMes] = useState();
const [ClaCelda, setClaCelda] = useState(props.ClaCelda === undefined ? null : props.ClaCelda); 
const [ClaUbicacion, setClaUbicacion] = useState(null);
const [PlantasList, setPlantasList] = useState([]);
const [OpcionesAnios, setOpcionesAnios] = useState([]);
const [OpcionesMeses, setOpcionesMeses] = useState([]);
const [OpcionesMesesList, setOpcionesMesesList] = useState([]);
const [OpcionesDepartamentos, setOpcionesDepartamentos] = useState([]);
const [OpcionesTurnos, setOpcionesTurnos] = useState([]);
const [OpcionesCelda, setOpcionesCelda] = useState([]);
const [errors, setErrors] = useState({});  
const [SelectedDepartamentos, setSelectedDepartamentos] = useState(null);  
const [SelectedTurnos, setSelectedTurnos] = useState(null); 
const [SelectedDepartamentosString, setSelectedDepartamentosString] = useState(null); 
const [SelectedTurnosString, setSelectedTurnosString] = useState(null);  
const [showPromedioAnio, setShowPromedioAnio] = useState(true);
const [showTendenciaAnio, setShowTendenciaAnio] = useState(true);
const [showTendenciaUltimoMes, setShowTendenciaUltimoMes] = useState(false);
const [showPreviousMonths, setShowPreviousMonths] = useState(true);
const [anioPrevio1, setAnioPrevio1] = useState(true);
const [anioPrevio2, setAnioPrevio2] = useState(true);
const [anioPrevio3, setAnioPrevio3] = useState(true);  
const [isInitialized, setIsInitialized] = useState(false);  
const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
const [TabActiveIndex, setTabActiveIndex] = useState(0);
const [ClaDepartamento, setClaDepartamento] = useState(null);
const [TrendData, setTrendData] = useState(null); 
const [Refresh, setRefresh] = useState(0);  
const [NomDepartamento, setNomDepartamento] = useState(null);  
const [NomCelda, setNomCelda] = useState(null);  
const [EsShowDialogDetail, setEsShowDialogDetail] = useState(false);  
const [FechaDetalle, setFechaDetalle] = useState(null);  
const [ClaArea, setClaArea] = useState(null);  
const [ClaMaquina, setClaMaquina] = useState(null);  
const [NomMaquina, setNomMaquina] = useState(null); 
const [MaquinaValue, setMaquinaValue] = useState(null); 

const [expandedKeys, setExpandedKeys] = useState({});

  const onToggle = (e) => {
      setExpandedKeys(e.value);  // e.value contains the updated expanded state
  };
 
useEffect(() => {
  getAnios() 
  getMeses(0) 
  getPlantas() 
  getTurnos(props.ClaUbicacion) 
  getDepartamentos(props.ClaUbicacion, true)
}, []); // Add props to dependency array if needed

 // Cuando la pantalla se inicializa y recibimos Fecha forzamos el onSearch...
 useEffect(() => {
  if (props.Fecha !== null && SelectedTurnos && SelectedDepartamentos && !isInitialized) { 
    const fecha = props.Fecha ? new Date(props.Fecha) : null;
    const anio = fecha ? fecha.getFullYear() : undefined;
    const mes = fecha ? fecha.getMonth() + 1 : undefined; 
    onSearch(false, props.ClaUbicacion, SelectedDepartamentos, props.ClaCelda, anio, mes, SelectedTurnos);
    setIsInitialized(true); // Set initialized to true to prevent future runs
  }
}, [SelectedDepartamentos, SelectedTurnos, isInitialized]);  


const getPlantas = () => {  
  const urlWebService = `${config.UrlApiProject}WTW/GetPlantasProduccionSeguimientoCmb`; 
  const paramsService= {};  
  callApi(urlWebService, 'GET', paramsService, (response) => {
    setPlantasList(response.data.PlantasList);
    setClaUbicacion(props.ClaUbicacion);
  }, null, false, false);
}

const getAnios = () => {  
  const nClaUbicacion = (ClaUbicacion===null?props.ClaUbicacion:ClaUbicacion);
  const urlWebService = `${config.UrlApiProject}WTW/GetProduccionSeguimientoDiarioAnios?ClaUbicacion=${nClaUbicacion}`;
  const paramsService= {};  
  callApi(urlWebService, 'GET', paramsService, (response) => {
    setOpcionesAnios (response.data.Anios); 
    if(props.Fecha!==null){ 
      const anio = new Date(props.Fecha).getFullYear();
      setAnio(anio);
    }else {
      setAnio(response.data.Anios[0].ClaAnio);
    }
  }, null, false, false);
}

const getMeses = (anio) => {  
  const panio = anio;
  if(anio===0&&props.Fecha!==null){
    anio = new Date(props.Fecha).getFullYear();
  }
  const paramsService = null; 
  const nClaUbicacion = (ClaUbicacion===null?props.ClaUbicacion:ClaUbicacion);
  const urlWebService = `${config.UrlApiProject}WTW/GetProduccionSeguimientoDiarioMeses?ClaUbicacion=${nClaUbicacion}`;
  callApi(urlWebService, 'GET', paramsService, (response) => {
    setOpcionesMeses(response.data.Meses);
    setOpcionesMesesList(response.data.Meses);
    if(props.Fecha!==null&&panio===0){
      const mes = new Date(props.Fecha).getMonth()+1  
      setMes(mes);
    }else{ 
      setMes(new Date().getMonth()+1);
    }
  }, null, false, false);
};

const getDepartamentos = (nClaUbicacion, esBuscar, turnosSelected) => {  
  const urlWebService = `${config.UrlApiProject}WTW/GetProduccionSeguimientoDiarioDepartamentos?ClaUbicacion=${nClaUbicacion}`;
  const paramsService= {};  
  callApi(urlWebService, 'GET', paramsService, (response) => {
    setOpcionesDepartamentos(response.data.Departamentos);
    let opcionesSelected = response.data.Departamentos.map(ubicacion => (ubicacion));   

    if (props.ClaArea !== null){
      opcionesSelected = response.data.Departamentos.filter(item => item.ClaDepartamento === props.ClaArea).map(ubicacion => (ubicacion));
    }
    setSelectedDepartamentos(opcionesSelected);
    setClaDepartamento((opcionesSelected.length>0?opcionesSelected[0].ClaDepartamento:null))
    getCeldas(nClaUbicacion,opcionesSelected); 
  }, null, false, false);
}

const getTurnos = (nClaUbicacion) => {
  const urlWebService = `${config.UrlApiProject}turno/getTurnos?ClaUbicacion=${nClaUbicacion}`; 
  const paramsService= {};  
  callApi(urlWebService, 'GET', paramsService, (response) => {
    setOpcionesTurnos(response.data.Turnos);  
    let opcionesSelected = response.data.Turnos.map(turno => (turno));
    if (props.ClaTurno !== null){
      opcionesSelected = response.data.Turnos.filter(item => item.ClaTurno === props.ClaTurno).map(turno => (turno));
    }
    setSelectedTurnos(opcionesSelected);
  }, null, false, false);
}

const getCeldas = (nClaUbicacion,departamentos) => {  
  let selectedDepartamentos = "";
  if (Array.isArray(departamentos) && departamentos.length > 0) {
    selectedDepartamentos = departamentos.map(selected => selected.ClaDepartamento);
    selectedDepartamentos = selectedDepartamentos.join(',');
    setSelectedDepartamentosString(selectedDepartamentos);
  }
  const urlWebService = `${config.UrlApiProject}WTW/GetProduccionSeguimientoDiarioCeldas`; 
  const paramsService = {
    ClaUbicacion:nClaUbicacion,
    ClaAreaList:selectedDepartamentos, 
    Fecha:props.Fecha
  }; 
  callApi(urlWebService, 'POST', paramsService, (response) => {  
    setOpcionesCelda(response.data.ProduccionCeldas);
  }, null, false, false);
}

const onSearch = async (validate, nClaUbicacion, dptos, nCelda, nAnio, nMes, turnosSeleccionados) => {  
  setNomDepartamento(null);
  setNomCelda(null);
  setTrendList([]);
  setTrendDetail([]); 
  setEsVerDetalleChart(false);
  setTabActiveIndex(0);
  if(validate){
    const value = {
      ClaUbicacion,
      SelectedDepartamentos,
      SelectedTurnos,
      Anio,
      Mes
    };
    const validator = new YupValidator(valitationScheme);
    const isValid = await validator.validate(value);
    if (!isValid){             
      setErrors(validator.errors); 
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
      return
    }  
  }
  let selectedDepartamentos = "";
  let selectedTurnos = "";
  let selectedCeldas = "";
  if (Array.isArray(dptos) && dptos.length > 0) {
    selectedDepartamentos = dptos.map(selected => selected.ClaDepartamento);
    selectedDepartamentos = selectedDepartamentos.join(',');
    setSelectedDepartamentosString(selectedDepartamentos);
  }
  if (Array.isArray(turnosSeleccionados) && turnosSeleccionados.length > 0) {
    selectedTurnos = turnosSeleccionados.map(selected => selected.ClaTurno);
    selectedTurnos = selectedTurnos.join(',');
    setSelectedTurnosString(selectedTurnos); 
  }
  if (Array.isArray(nCelda) && nCelda.length > 0) {
    console.log('nCelda', nCelda, turnosSeleccionados);
    selectedCeldas = nCelda.map(selected => selected.ClaMaquina);
    selectedCeldas = selectedCeldas.join(','); 
  }else {
    selectedCeldas = '';
  }
  const urlWebService = `${config.UrlApiProject}WTW/GetProduccionSeguimientoTendenciaMensual`; 
  const paramsService = {
    ClaUbicacion:nClaUbicacion,
    ClaAreaList:selectedDepartamentos, 
    ClaTurnoList:selectedTurnos, 
    Fecha:props.Fecha,
    ClaCeldaList:selectedCeldas,
    ClaMes:nMes,
    ClaAnio:nAnio
  };  

  setIsLoading(true);
  callApi(urlWebService, 'POST', paramsService, (response) => {  
    setIsLoading(false);
    const data = (response.data.ProduccionSeguimientoReporteList);
    const transforrm = transformToTreeData(data);
    setProduccionSeguimientoReporteList(transforrm);
    setTrendList([]);
    console.log('transforrm',transforrm);
    obtenerTituloAnioPrevios(nAnio);
    }, () => { },false,false);
};

const obtenerTituloAnioPrevios = (anio) => {
  const headerMapping = {
    '1': t('common:Jan'),
    '2': t('common:Feb'),
    '3': t('common:Mar'),
    '4': t('common:Apr'), 
    '5': t('common:May'),  
    '6': t('common:Jun'),  
    '7': t('common:Jul'),  
    '8': t('common:Aug') ,  
    '9': t('common:Sep'),
    '10': t('common:Oct'),  
    '11': t('common:Nov'),  
    '12': t('common:Dec')  
  };
 
  const titulos = [];

  for (let i = 1; i <= 3; i++) {
    const anioTitulo = (anio - i).toString(); 
    titulos.unshift(anioTitulo);
  }

  setAnioPrevio3(titulos[0]);
  setAnioPrevio2(titulos[1]);
  setAnioPrevio1(titulos[2]);

  return titulos;
};


const transformToTreeData = (data) => {
  const tree = [];
  const lookup = {};
  if(data.length===0){
    return tree;
  }
  data.forEach(item => {
    lookup[item.id] = { key: item.id.toString(), data: item, children: [], parent: null };
  });

  data.forEach(item => {
    if (item.parentId === null || item.parentId === 0) {
      tree.push(lookup[item.id]);
    } else {
      const parent = lookup[item.parentId];
      const childIndex = parent.children.length;
      lookup[item.id].key = `${parent.key}-${childIndex}`;
      lookup[item.id].parent = parent; // Establecer referencia al padre
      parent.children.push(lookup[item.id]);
    }
  });

  return tree;
};

const valitationScheme = yup.object().shape({
  ClaUbicacion: yup.string().required(t('common:RequiredField')).nullable(),
  Anio: yup.string().required(t('common:RequiredField')).nullable(),
  Mes: yup.string().required(t('common:RequiredField')).nullable(),
  // SelectedDepartamentos: yup.mixed().required('').nullable(),
});

const onClaDepartamentoChange = (nDepartamento) => {
  setTrendList([]);
  setNomDepartamento(null);
  setNomCelda(null);
  setEsVerDetalleChart(false);
  setClaDepartamento(nDepartamento);

  const departamentoSeleccionado = OpcionesDepartamentos.find(
    (departamento) => departamento.ClaDepartamento === nDepartamento
  );
  
  if (departamentoSeleccionado) {
    const descripcionDepartamento = departamentoSeleccionado.NomDepartamento4;
    setNomDepartamento(descripcionDepartamento);
    setNomCelda(null);
    const resultados = [];
    
    // Recorremos lista principal
    ProduccionSeguimientoReporteList.forEach(root => {
      // Buscamos si en el nivel 1 está el departamento en nivel 2
      const childrenNivel2 = root.children.filter(
        child => child.data.Descripcion === descripcionDepartamento
      );
      // Si encuentra coincidencias, agregar el nivel 1 completo con sus hijos
      if (childrenNivel2.length > 0) {
        resultados.push({
          ...root,
          children: childrenNivel2 // Solo los hijos de nivel 2 que coincidan
        });
      }
    });

    // Estructura por Nivel 1
    const estructuraFinal = resultados.map(root => {
      // Recorremos Nivel 2 (departamentos)
      const childrenNivel2 = root.children.map(nivel2 => {
        const info = Object.keys(nivel2.data)
        .filter(key => !isNaN(Number(key)) && !["13", "14", "15", "16"].includes(key)) // Filtramos numéricas y excluimos 13, 14, 15, 16 (Trimestres)
        .map(columna => {
            const dia = Number(columna); // Convertir la columna a número
            const fecha = new Date(Anio, Mes - 1, dia); // 
            return {
              Date: fecha, // Fecha
              Valor: nivel2.data[columna],
              TendenciaUltimoMes: nivel2.data.TendenciaUltimoMes,
              PromedioAnio: nivel2.data.PromedioAnio  ,
              AnioPrevioPromedio1: nivel2.data.AnioPrevioPromedio1  ,
              AnioPrevioPromedio2: nivel2.data.AnioPrevioPromedio2  ,
              AnioPrevioPromedio3: nivel2.data.AnioPrevioPromedio3  
            };
          });
        return {
          Titulo: nivel2.data.Descripcion, // Nombre Nivel 2
          Data: info                       // Data de Nivel 2
        };
      });

      // Reestructuramos para que `Data` esté directamente accesible en el primer departamento
      return {
        Titulo: root.data.Descripcion,       // Nombre Nivel 1
        NomIndicador: root.data.Descripcion, // Nombre Nivel 1
        TipoValorIndicador: root.data.TipoValorIndicador, // Tipo Valor Indicador
        Departamento: childrenNivel2.length > 0 ? childrenNivel2[0].Data : [] // Accedemos directo al primer hijo y su data
      };
    });

    setRefresh(Refresh + 1);
    setTimeout(()=> {
      setTrendList(estructuraFinal); 
    }, 1)
     
  }
};

const onViewDetail = (sNivel1) => {
  setNomCelda(sNivel1);
  setEsVerDetalleChart(true);
  setTrendDetail({});  // Inicializamos como objeto vacío

  // Buscar el departamento seleccionado en el Nivel 1
  const departamentoSeleccionado = OpcionesDepartamentos.find(
    (departamento) => departamento.ClaDepartamento === ClaDepartamento
  );

  if (departamentoSeleccionado) {
    const descripcionDepartamento = departamentoSeleccionado.NomDepartamento4;

    let resultado = null;
    // Recorremos lista principal (Nivel 1)
    ProduccionSeguimientoReporteList.forEach(root => {
      if (root.data.Descripcion === sNivel1) {
        const hijosNivel2 = root.children.filter(
          child => child.data.Descripcion === descripcionDepartamento
        );
        if (hijosNivel2.length > 0) {
          resultado = {
            ...root,
            children: hijosNivel2 // Solo los hijos de Nivel 2 que coincidan
          };
        }
      }
    });

    if (resultado) {
      // Generamos la estructura final como un objeto en lugar de un array
      const hijosConDatos = resultado.children.map(nivel2 => {
        // Recorremos Nivel 3 (celdas)
        const hijosNivel3 = nivel2.children.map(nivel3 => {
          // Generar la estructura por cada columna numerada de 'data'
          const columnasNivel3 = Object.keys(nivel3.data)
            .filter(key => !isNaN(Number(key))) // Filtrar solo las columnas numéricas
            .map(columna => {
              const dia = Number(columna); // Convertir la columna a número
              const fecha = new Date(Anio, Mes - 1, dia); // 
              return {
                Date: fecha, // Fecha
                Valor: nivel3.data[columna],
                TendenciaUltimoMes: nivel3.data.TendenciaUltimoMes,
                PromedioAnio: nivel3.data.PromedioAnio  ,
                AnioPrevioPromedio1: nivel3.data.AnioPrevioPromedio1  ,
                AnioPrevioPromedio2: nivel3.data.AnioPrevioPromedio2  ,
                AnioPrevioPromedio3: nivel3.data.AnioPrevioPromedio3  
              };
            }) 
          return {
            Titulo: nivel3.data.Descripcion,      // `${resultado.data.Descripcion  }-${  nivel3.data.Descripcion}`,  // Nombre del nivel 3 (Celda)
            Data: columnasNivel3                  // Arreglo con DateName, Valor y Goal
          };
        });

        return {
          Titulo: nivel2.data.Descripcion,                        // Nombre del departamento (nivel 2)
          TipoValorIndicador: resultado.data.TipoValorIndicador, // TipoValorIndicador para acceso directo
          NomIndicador: resultado.data.Descripcion,              // TipoValorIndicador para acceso directo
          Celdas: hijosNivel3                                   // Agregar los hijos del nivel 3 (celdas)
        };
      });

      const estructuraFinal = {
        Titulo: resultado.data.Descripcion,   // Nombre Nivel 1
        Departamento: hijosConDatos[0]        // Eliminamos el array, solo accedemos al primer departamento
      };

      setTrendDetail(estructuraFinal);
      setEsVerDetalleChart(true);
    }
  }
};

const onViewChart = () =>{
  setNomCelda(null);
  setEsVerDetalleChart(false);
}

const onYearChange = (yearId) => {  
  const _errors = { ...errors } 
  delete _errors.Anio;   
  delete _errors.Mes;   
  setErrors(_errors); 
  setAnio(yearId);

  // si el anio seleccionado es igual al actual y el mes actual seleccionado en el combo no es un mes valido cambiamos y seleccionamos currentmont
  if (yearId === currentYear && Mes > currentMonth)
  {
    setMes(currentMonth);
  }

  if(yearId!==undefined){
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; 
    if (yearId !== undefined) {
        if (yearId === currentYear) {  
          const filteredMonths = OpcionesMesesList.filter(mes => mes.ClaMes <= currentMonth);
          setOpcionesMeses(filteredMonths); 
          // Si no esta el mes lo limpiamos
          const isCurrentMonthInList = filteredMonths.some(mes => mes.ClaMes === currentMonth);
          if (!isCurrentMonthInList) {
              setMes(null);
          }
        }else{
          setOpcionesMeses(OpcionesMesesList); 
        }
    }
  }
  setProduccionSeguimientoReporteList([]);
};

const onChangeMultiDepartament = (e) => {       
  const _errors = { ...errors } 
  delete _errors.SelectedDepartamentos;   
  setErrors(_errors)
  setSelectedDepartamentos(e.value.length < 1? null: e.value);  
  if(e.value.length===0){
    setOpcionesCelda([]);
    setClaCelda(null);
  }else{
    getCeldas(ClaUbicacion,e.value);
  }
  setProduccionSeguimientoReporteList([]);
};

const onChangeMultiTurnos = (e) => {       
  const _errors = { ...errors } 
  delete _errors.SelectedTurnos;   
  setErrors(_errors)
  setSelectedTurnos(e.value.length < 1? null: e.value);  
  setProduccionSeguimientoReporteList([]);
};

const onChangeCelda = (e) => {   
  setClaCelda(e.value.length < 1? null: e.value);  
  setProduccionSeguimientoReporteList([]);
};

const onChangeClaUbicacion = (e) => {  
  const newErrors = { ...errors } 
  delete newErrors.ClaUbicacion;
  setClaCelda(null); 
  if(e.value!==undefined){
    delete newErrors.SelectedDepartamentos; 
    delete newErrors.SelectedTurnos;   
    setClaUbicacion(e.value); 
    getDepartamentos(e.value);
    getTurnos(e.value);
  }else{
    setClaUbicacion(null);
    setOpcionesDepartamentos([]);
    setSelectedDepartamentos(null)
    setOpcionesTurnos([]);
    setSelectedTurnos(null);
    setOpcionesCelda([]);
  }
  setErrors(newErrors)  
  setProduccionSeguimientoReporteList([]);
};

function formatValue(item, column, applyColorRule, applyClick) {
  const result = item.data[column];
  if (result === 0||result===undefined||result===null) {
    return <span>&nbsp;</span>;
  }
 
  let formattedResult;
  switch (item.data.TipoValorIndicador) {
    case 1:
      formattedResult = result.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
      break;
    case 2:
      formattedResult = result.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
      break;
    case 3:  
      formattedResult = (typeof result === 'number' && !isNaN(result)) ? `${result.toFixed(1)}%` : "";  
      break;
    default:
      formattedResult = result;
  }

  let classClick = ''
  let onMaquinaAreaClick = null;
  if(applyClick && item.data.ClaMaquina!==null){
    classClick = 'cursor link-text'
    onMaquinaAreaClick = () => onMaquinaClick(item, column, formattedResult);
  }
  else {
    onMaquinaAreaClick = () => {};
  }
 
  if (column==='TendenciaAnio') {
    const valueAnioPrevio1 = item.data.AnioPrevio1 ?? 0;
    const valueTendenciaAnio = item.data.TendenciaAnio ?? 0;
   
    if (applyColorRule === true && (column!=='13' && column!=='14' && column!=='15' && column!=='16')){
      if (item.data.ClaIndicador === 4) // Scrap
      {
        if (valueTendenciaAnio > valueAnioPrevio1 ){
          return <div onClick={() => applyClick && onMaquinaAreaClick()} className={`orange-color ${applyClick ? classClick : ''}`}>{formattedResult}</div>;
        }
      }
      else if (valueTendenciaAnio < valueAnioPrevio1 ){
          return <div onClick={() => applyClick && onMaquinaAreaClick()} className={`orange-color ${applyClick ? classClick : ''}`}>{formattedResult}</div>;
      } 
    }

  }else if (applyColorRule === true && (column!=='13' && column!=='14' && column!=='15' && column!=='16')){
      // si el indicador es top -> bottom:
      if (item.data.ClaIndicador === 4) // Scrap
      {
        if (result > item.data.PromedioAnio ){
          return <div onClick={() => applyClick && onMaquinaAreaClick()} className={` ${applyClick ? classClick : ''}`}>{formattedResult}</div>;
        }
      }
      else if (result < item.data.PromedioAnio ){
          return <div onClick={() => applyClick && onMaquinaAreaClick()} className={`orange-color ${applyClick ? classClick : ''}`}>{formattedResult}</div>;
      } 
    }
  
  return <div onClick={() => applyClick && (column!=='13' && column!=='14' && column!=='15' && column!=='16') && onMaquinaAreaClick()} className={`${applyClick && (column!=='13' && column!=='14' && column!=='15' && column!=='16') ? classClick : ''}`}>{formattedResult}</div>;
}


const onMaquinaClick = (item,column) => { 
  // const fecha = new Date(Anio, Mes - 1, 1); 
  setFechaDetalle(null);
  setMes(column);
  setClaArea(item.data.ClaArea);
  setClaMaquina(item.data.ClaMaquina);
  setNomMaquina(item.data.Descripcion); 
  setMaquinaValue('');
  setEsShowDialogDetail(true);
};

 // Función para obtener todos los nodos, incluyendo los hijos
 const flattenNodes = (nodes) => {
  let flatData = [];
  nodes.forEach((node) => {
    flatData.push(node.data); // Agrega el nodo actual
    if (node.children) {
      flatData = flatData.concat(flattenNodes(node.children)); // Agrega los hijos de manera recursiva
    }
  });
  return flatData;
};

const exportToCSV = () => {
  if (ProduccionSeguimientoReporteList.length === 0) {
    return;
  }
  setIsExporting(true);
  const allColumns = generateColumns(ProduccionSeguimientoReporteList);
  const filteredColumns = allColumns.filter(col => !columnsToOmit.includes(col.field))
  .sort((a, b) => a.order - b.order);

  const visibleColumns = [
    { field: 'Descripcion', header: t('wtw:Concept') },
    showPreviousMonths && { field: 'AnioPrevio3', header: anioPrevio3 },
    showPreviousMonths && { field: 'AnioPrevio2', header: anioPrevio2 },
    showPreviousMonths && { field: 'AnioPrevio1', header: anioPrevio1 },
    showTendenciaUltimoMes && { field: 'TendenciaUltimoMes', header: t('wtw:TendenciaUltimoMes') },
    showTendenciaAnio && { field: 'TendenciaAnio', header: t('wtw:TendenciaAnio') },
    showPromedioAnio && { field: 'PromedioAnio', header: t('wtw:PromedioAnio') },
    ...filteredColumns.map((col) => ({ field: col.field, header: col.header }))
  ].filter(Boolean);  

  const allNodes = flattenNodes(ProduccionSeguimientoReporteList);  
  const headers = visibleColumns.map(col => col.header).join(',');
  const rows = allNodes.map((data) => {
    return visibleColumns.map((col) => {
      const value = data[col.field];
      return value !== undefined ? value : ''; 
    }).join(',');
  });
 

  const csvContent = [headers, ...rows].join('\n');
   

  // Crear y descargar el archivo CSV
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', 'treetable_export.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  setIsExporting(false);
};  

const filters = (
  <> 
    <Row className="pt-3 align-items-start">
      <Col>
        <div className="p-fluid">
          <FieldDropdown  
            name="ClaUbicacion"
            value={ClaUbicacion} 
            options={PlantasList}  
            optionValue="ClaUbicacion"
            optionLabel="NombreUbicacion" 
            onChange={(e) => onChangeClaUbicacion(e)}
            label={t('wtw:Plant')}
            errors={errors}
            disabled={props.DisableFilters===true}
          />   
        </div>
      </Col>
      <Col>
        <div className="p-fluid">
          <FieldDropdown  
            name="Anio"
            value={Anio} 
            options={OpcionesAnios}  
            optionValue="ClaAnio"
            optionLabel="NomAnio" 
            onChange={(e) => onYearChange(e.value)}
            label={t('common:Year')}
            errors={errors}
            disabled={props.DisableFilters===true}
          />   
        </div>
      </Col>
      <Col>
        <div className="p-fluid">
          <FieldMultiSelect
            name="SelectedDepartamentos"
            label={t('wtw:Department')}  
            value={SelectedDepartamentos}
            optionLabel="NomDepartamento4"
            onChange={(e) => onChangeMultiDepartament(e)}
            options={OpcionesDepartamentos}
            errors={errors}    
            disabled={props.DisableFilters===true}
          >
          </FieldMultiSelect>
        </div>
      </Col>
      <Col>
        <div className="p-fluid">
          <FieldMultiSelect
            name="SelectedTurnos"
            label={t('wtw:Shift')}  
            value={SelectedTurnos}
            optionLabel="NomTurno"
            onChange={(e) => onChangeMultiTurnos(e)}
            options={OpcionesTurnos}
            errors={errors}
            disabled={props.DisableFilters===true}
          >
          </FieldMultiSelect>
        </div>
      </Col>
      <Col>
        <div className="p-fluid">
          <FieldMultiSelect  
            value={ClaCelda} 
            options={OpcionesCelda}  
            // optionValue="ClaMaquina"
            optionLabel="NomMaquina" 
            onChange={(e) => onChangeCelda(e)}
            label={t('wtw:Cell')}
            disabled={props.DisableFilters===true}
          />      
        </div>
      </Col>
      <Col lg={2} md={2} sm={2} xs={2}>  
        <FieldButton
          label={t('common:Search')} 
          className="p-button p-highlight no-borde" 
          icon={IsLoading ? 'pi pi-spin pi-spinner' : "pi pi-search"}
          disabled={IsLoading}
          onClick={() => onSearch(true,ClaUbicacion,SelectedDepartamentos,ClaCelda,Anio,Mes, SelectedTurnos)}
          hidden={props.DisableFilters===true}
        > 
        </FieldButton>&nbsp;
        <FieldButton
          label={t('common:Export')} 
          icon={IsExporting ? 'pi pi-spin pi-spinner' : "fa fa-file-excel"}
          className="p-button p-highlight warning no-border" 
          onClick={() => exportToCSV()}
        >              
        </FieldButton> 
        &nbsp; 

      </Col> 
    </Row>   
  </>
);

const filtersCheckbox = (
  <> 
    <Row className="pt-3 align-items-start">
      <Col lg={8} md={8} sm={8} xs={8}>
        <table>
          <thead></thead>
          <tr> 
            <td> 
              <div className="p-fluid">
                <FieldCheckbox
                  inputId={showPromedioAnio}
                  checked={showPromedioAnio}
                  onChange={(e) => setShowPromedioAnio(e.checked)}
                />
                <label className='mx-2' htmlFor={showPreviousMonths}>{t('wtw:Show')} {t('wtw:PromedioAnio')}</label>
              </div>
            </td>
            <td>
              <div className="p-fluid">
                <FieldCheckbox
                  inputId={showTendenciaAnio}
                  checked={showTendenciaAnio}
                  onChange={(e) => setShowTendenciaAnio(e.checked)}
                />
                <label className='mx-2' htmlFor={showTendenciaAnio}>{t('wtw:Show')} {t('wtw:TendenciaAnio')}</label>
              </div>
            </td> 
            <td>
              <div className="p-fluid">
                <FieldCheckbox
                  inputId={showPreviousMonths}
                  checked={showPreviousMonths}
                  onChange={(e) => setShowPreviousMonths(e.checked)}
                />
                <label className='mx-2' htmlFor={showPreviousMonths}>{t('wtw:Show')}  {t('wtw:PreviousYears')}</label>
              </div>
            </td> 
          </tr>
        </table>
      </Col> 
      <Col className='text-right' lg={4} md={4} sm={4} xs={4}>
        <span className='orange-color'>{t('wtw:ResultadosResaltadosPorDesviacionVsPromedio')}.</span>
      </Col>
    </Row>
  </>
);

const filtersChart = (
  <> 
    <Row className="pt-3 align-items-start"> 
      <Col lg={8} md={8} sm={8} xs={8}>  
        <span className='bold cursor link-text' onClick={() => onViewChart()}>
          {NomDepartamento}
        </span>
        {NomCelda && (
        <>
          <span>{' > '}</span> {NomCelda}
        </>
        )}
      </Col>
      <Col lg={2} md={2} sm={2} xs={2}></Col> 
      <Col lg={2} md={2} sm={2} xs={2} className="d-flex justify-content-end">  
        <div className="p-fluid" hidden={NomCelda!==null}>
          <FieldDropdown  
            name="ClaDepartamento"
            value={ClaDepartamento} 
            options={SelectedDepartamentos} 
            showClear={false}
            optionValue="ClaDepartamento"
            optionLabel="NomDepartamento4" 
            onChange={(e) => onClaDepartamentoChange(e.value)}
            label={t('wtw:Department')}  
            errors={errors}
          />   
        </div>
      </Col>
    </Row>   
  </>
); 

const columnsToOmit = [
  'id', 'parentId', 'ClaIndicador', 'TipoValorIndicador', 'Descripcion', 
  'PromedioAnio','TendenciaAnio', 'TendenciaUltimoMes','TendenciaMes',
  'AnioPrevio1', 'AnioPrevio2', 'AnioPrevio3', 'ClaArea', 'ClaMaquina', 
  'AnioPrevioPromedio1', 'AnioPrevioPromedio2', 'AnioPrevioPromedio3'
]; 

  
const generateColumns = (data) => {
  if (!data || data.length === 0) return [];
  const fields = Object.keys(data[0].data);
  
  const headerMapping = {
    '1': t('common:Jan'),
    '2': t('common:Feb'),
    '3': t('common:Mar'),
    '13': t('wtw:Q1'), 
    '4': t('common:Apr'), 
    '5': t('common:May'),  
    '6': t('common:Jun'),  
    '14': t('wtw:Q2') ,  
    '7': t('common:Jul'),  
    '8': t('common:Aug') ,  
    '9': t('common:Sep'),   
    '15': t('wtw:Q3'), 
    '10': t('common:Oct'),  
    '11': t('common:Nov'),  
    '12': t('common:Dec'),  
    '16': t('wtw:Q4')     
  };
  const orderMapping = {
    '1': 1,
    '2': 2,
    '3': 3,
    '13': 4, 
    '4': 5, 
    '5': 6,  
    '6': 7,  
    '14': 8 ,  
    '7': 9,  
    '8': 10 ,  
    '9': 11,   
    '15': 12, 
    '10': 13,  
    '11': 14,  
    '12': 15,  
    '16': 16       
  };
 
  const columns = fields.map(field => ({
      field,
      header: headerMapping[field],
      order: orderMapping[field]
    }));
 
 return columns;
};


function bodyTemplate (node) {
 
  if (node.data.ClaMaquina != null){
    return (<span onClick={() => null} className=""><b>{node.data.Descripcion}</b></span>);
  }
  

  return (<span><b>{node.data.Descripcion}</b></span>);
}

const DynamicTreeTable = ({ data }) => {
    const allColumns = generateColumns(data);
    let filteredColumns = [];
    if(allColumns.length>0){
      filteredColumns = allColumns.filter(col => !columnsToOmit.includes(col.field)).sort((a, b) => a.order - b.order);
    }
    return (
      <>
        {ProduccionSeguimientoReporteList.length>0 ? (
          <>
            <div>
              {filtersCheckbox}
              <TreeTable
                value={data}
                scrollable
                frozenWidth="300px"
                scrollHeight="75vh" 
                expandedKeys={expandedKeys}
                onToggle={onToggle}  // Updates expanded state when user interacts with the tree 
              >
                <Column
                  field="Descripcion" 
                  header={t('wtw:Concept')}
                  body={(rowData) => bodyTemplate(rowData)}
                  expander 
                  frozen 
                  style={{ width: '300px'  }}
                >
                </Column> 
                {showPreviousMonths && <Column field="AnioPrevio3" className="table-header-background-ligth-green text-right" body={(rowData) => formatValue(rowData,'AnioPrevio3')} style={{ width: '90px' }} header={anioPrevio3} />}
                {showPreviousMonths && <Column field="AnioPrevio2" className="table-header-background-ligth-green text-right" body={(rowData) => formatValue(rowData,'AnioPrevio2')} style={{ width: '90px' }} header={anioPrevio2} />}
                {showPreviousMonths && <Column field="AnioPrevio1" className="table-header-background-ligth-green text-right" body={(rowData) => formatValue(rowData,'AnioPrevio1')} style={{ width: '90px' }} header={anioPrevio1} />}
                {showTendenciaAnio && <Column field="TendenciaAnio" className="table-header-background-ligth-green text-right" body={(rowData) => formatValue(rowData,'TendenciaAnio',true)} style={{ width: '90px' }} header={t('wtw:TendenciaAnio')} />}
                {showPromedioAnio && <Column field="PromedioAnio" className="table-header-background-ligth-green text-right" body={(rowData) => formatValue(rowData,'PromedioAnio')} style={{ width: '90px' }} header={t('wtw:PromedioAnio')} />}
                {filteredColumns.map((col) => (
                  <Column
                    key={col.field}
                    field={col.field}
                    header={col.header}   
                    className={`text-right ${['Q1', 'Q2', 'Q3', 'Q4'].includes(col.header) ? 'table-header-background-ligth-orange' : ''}`}
                    style={{ width: '65px' }}
                    body={(rowData) => formatValue(rowData,col.field.toString(), true, true)}
                  />
                  ))}
              </TreeTable>
            </div>
          </>
            ) : (
              <>
                <Card className='card-message-no-data-found'>
                  <CardBody className="" style={{paddingTop: "5px"}}>
                    <Row className='pt-2'>
                      <Col lg={12} md={12} sm={12} xs={12}>  
                        {t('common:MessageNoDataFound')}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </>
          )}   
      </>
    );
};

const headerDialogProductionTrendDetail = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <span>
        {NomMaquina}
      </span>
      <span className='pr-2'>
        {
          FechaDetalle !== null 
            ? moment(FechaDetalle).format('MMMM, D, YYYY') 
            : moment(new Date(Anio, Mes - 1, 1)).format('MMMM, YYYY')
        }
      </span>
    </div>
  );
}


  return (
    <> 
      <div> 
        <Row className='align-items-start pt-3 pb-3'>
          <Col lg={12} md={12} sm={12} xs={12}>  
            {filters}
          </Col> 
        </Row> 
        <>
          {IsLoading ? (
            <>
              <MonthlyProductionSummarySkeleton></MonthlyProductionSummarySkeleton>
            </>
            ) : (
              <>
                <Row className='align-items-start pt-3'>
                  <Col lg={12} md={12} sm={12} xs={12}>  
                    <TabView
                      activeIndex={TabActiveIndex} 
                      onTabChange={(e) => {
                      if (e.index === 1) {
                        onClaDepartamentoChange(ClaDepartamento);
                        setTabActiveIndex(e.index); 
                      } else {
                        setTabActiveIndex(e.index);
                      }
                    }}
                    > 
                      <TabPanel header={t('wtw:TreeView')}>
                        <DynamicTreeTable data={ProduccionSeguimientoReporteList} />
                      </TabPanel>
                      <TabPanel header={t('wtw:ChartView')}>
                        {filtersChart}
                        <>
                          {TrendList.length > 0 ? (
                            !EsVerDetalleChart ? (
                              <>
                                <div>
                                  <Row className='align-items-start pt-3'>
                                    {TrendList.map((col) => (
                                      <Col lg={6} md={6} sm={6} xs={12} key={col.Titulo}> 
                                        <MonthlyProductionSummaryChart 
                                          nomIndicador={col.NomIndicador}
                                          title={NomDepartamento}
                                          data={col.Departamento}
                                          tipoIndicador={col.TipoValorIndicador}
                                          onViewDetail={onViewDetail}
                                          refresh={Refresh}
                                        />
                                      </Col>
                                    ))}
                                  </Row>
                                </div>
                              </>
                            ) : (
                              <>
                                <div>
                                  <Row className='align-items-start pt-3'>
                                    {TrendDetail.Departamento && TrendDetail.Departamento.Celdas.map((celda) => (
                                      <Col lg={6} md={6} sm={6} xs={12} key={celda.Titulo}>
                                        <MonthlyProductionSummaryChart
                                          title={celda.Titulo}
                                          nomIndicador={TrendDetail.Departamento.NomIndicador}
                                          data={celda.Data}
                                          tipoIndicador={TrendDetail.Departamento.TipoValorIndicador}
                                          refresh={Refresh}
                                        />
                                      </Col>
                                     ))}
                                  </Row>
                                </div>
                              </>
                            )
                          ) : (
                            <>
                              {/* TrendList Sin datos */}
                            </>
                          )}
                        </>
                      </TabPanel>
                    </TabView>  
                  </Col> 
                </Row> 
                {EsShowDialogDetail ? (
                  <>
                    <Dialog
                      visible={EsShowDialogDetail}
                      modal 
                      header={headerDialogProductionTrendDetail}
                      className="DailyOperationAssetDetailTree dialog-header-background-blue"
                      style={{ width: '85vw' }}
                      maximized={false}
                      draggable={false}
                      blockScroll={true} 
                      onHide={() => setEsShowDialogDetail(false)}
                    >
                      <div className='pt-3'>
                        <MonthlyProductionSummaryDetail
                          ClaUbicacion={ClaUbicacion}
                          Anio={Anio}
                          Mes={Mes}
                          ClaArea={ClaArea} 
                          ClaMaquina={ClaMaquina}
                          Fecha={FechaDetalle}
                        >
                        </MonthlyProductionSummaryDetail>
                      </div>
                    </Dialog>
                  </>
                      ) : (
                        <></>
                      )} 
              </>
            )}
        </>  
      </div>  
    </>
  );
};

export default MonthlyProductionSummary;
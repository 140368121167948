/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from '../../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../../utils/utils';
import FieldDataTable from '../../../../components/Controls/FieldDataTable/FieldDataTable.jsx'; 
import FieldText from 'components/Controls/FieldText';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx'; 
import * as yup from 'yup';
import YupValidator from '../../../../utils/YupValidator';
import moment from 'moment'; 
import { Toolbar } from 'primereact/toolbar';
import FieldTextArea from 'components/Controls/FieldTextArea';
import FieldAutoComplete from '../../../../components/Controls/FieldAutoComplete.jsx'; 
import FieldNumber from '../../../../components/Controls/FieldNumber.jsx';
import FieldRadioButton from '../../../../components/Controls/FieldRadioButton/FieldRadioButton.jsx'; 
import ActionPlanAttachment from './ActionPlanAttachment.jsx';
import { Dialog } from 'primereact/dialog';
import { Badge } from 'primereact/badge';
import ActionPlanComments from './ActionPlanComments.jsx';
import { Tooltip } from 'primereact/tooltip';
import Highcharts from 'highcharts'; 
import InstructionsCard from '../../../../components/Cards/InstructionsCard/InstructionsCard.jsx';
import { Steps } from 'primereact/steps';
import FieldSelectButton from '../../../../components/Controls/FieldSelectButton.jsx'  
import ActionPlanQuickViewItem from './ActionPlanQuickViewItem.jsx'

const ActionPlanQuickview = (props) => {  
   
  /* ################## useState area start ################## */
  const { t } = useTranslation(['actionPlan', 'common']); 

  const [ActionPlanList, setActionPlanList] = useState([]);
  const [itemRow, setItemRow] = useState(null);
  const [currentElement, setCurrentElement] = useState({});
 
  useEffect(() => {  
    const selectedItem = {
      IdCSF: props.IdCSF,
      ClaPeriodo: props.ClaPeriodo,
      ClaFechaReporte: props.ClaFechaReporte
    }

    const defaultItems = []; 
    defaultItems.push(selectedItem); 
    setActionPlanList(props.ActionPlanList === undefined ? defaultItems : props.ActionPlanList);
    setCurrentElement(selectedItem);  
    UpdateDefaultItemSelected(props.ActionPlanList === undefined ? defaultItems : props.ActionPlanList);
  }, [props.IdCSF, props.ClaPeriodo, props.ClaFechaReporte, props.show]);

   
  const UpdateDefaultItemSelected = (items) => {  
    let isItemDefaulFounded = false; 
    if (items.length > 0) {
       for (let index = 0; index < items.length; index++) {
         const element = items[index];
         if (element.IdCSF === props.IdCSF && element.ClaPeriodo === props.ClaPeriodo && element.ClaFechaReporte === props.ClaFechaReporte)
         {
           setItemRow(index); 
           isItemDefaulFounded = true;
         }
       }
    }  

    if (!isItemDefaulFounded){
      setItemRow(items.length);
    }
  }

  const nextItem = () => { 
    let index = itemRow; 
    if (ActionPlanList.length > index+1) {   
      index += 1;
      setItemRow(index);
    }
    else { 
      index = 0
      setItemRow(0);
    }
     
    setCurrentElement(ActionPlanList[index]);

  }

  const prevItem = () => {
    let index = itemRow; 
    if (index-1 >= 0) {   
      index -= 1;
      setItemRow(index);
    }
    else { 
      index = ActionPlanList.length -1;
      setItemRow(index);
    }
     
    setCurrentElement(ActionPlanList[index]);

  }
  

  const renderHeader = () => {
    return (
      <div>
        <Row>
          <Col lg={4} md={4} sm={12} xs={12}>
            <span>{t('actionPlan:APReviewTitle')}</span>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="text-center">
            &nbsp;
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="text-right">
            <div className="w-100 relative">
              <i className='fas fa-times fa-1x Cursor' hidden={true} title={t('common:Close')} onClick={onHideAP}></i>&nbsp;
              <FieldButton
                label={t('common:Close')}
                icon="fas fa-times"
                className="warning no-border p-mr-2 rounded-button"
                onClick={onHideAP}
              />

            </div>
          </Col>
        </Row> 
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <>
        {
        // la navegacion esta visible unicamente cuando son mas de 1
        ActionPlanList.length > 1
        ?
        (
          <Toolbar
            left={leftToolbarFooterNewAPTemplate}
            right={rightToolbarFooterNewAPTemplate}
          >
          </Toolbar>
        )
        :
        (<></>)
      }
        
      </>
    );
  };

  const leftToolbarFooterNewAPTemplate = () => {  
    return (
      <>    
        <FieldButton icon="fas fa-angle-left fa-2x" className="light-gray border-none bold btn-circle btn-lg" onClick={prevItem}  /> 
      </>
    );
  };
  const rightToolbarFooterNewAPTemplate = () => {
     
    return (
      <>   
        <FieldButton icon="fas fa-angle-right fa-2x" className="light-gray border-none bold btn-circle btn-lg" onClick={nextItem} /> 
      </> 
    );
  }; 

  const onHideAP = () => {  
    if (props.onHide !== null && typeof props.onHide === 'function') {
      props.onHide();
    }
  }; 
 
  return (
    <>
      <Dialog
        visible={props.show}
        modal
        style={{ width: '100vw' }}
        footer={renderFooter}
        header={renderHeader}
        onHide={onHideAP}
        closable={false}
        className="dialog-header-background-blue"
        maximized={true}
        draggable={false}
        blockScroll={true}
      >
        <ActionPlanQuickViewItem IdCSF={currentElement.IdCSF} ClaPeriodo={currentElement.ClaPeriodo} ClaFechaReporte={currentElement.ClaFechaReporte}></ActionPlanQuickViewItem>
      </Dialog>
    </>
  );
};
export default ActionPlanQuickview;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState }  from 'react';     
import { InputText } from 'primereact/inputtext';
import { Button } from 'reactstrap'; 
import FieldKeyboard from "./Keyboard/FieldKeyboard";

const FieldText =  (props) => {

    const [ShowKeyboard, setShowKeyboard] = useState(false);

    const onChange = (value) => {  
        if (props.onChange !== null && props.onChange !== undefined){
            props.onChange(value);
        } 
    }  
    const onBlur = (value) => {  
      if (props.onBlur !== null && props.onBlur !== undefined){
          props.onBlur(value);
      } 
    }  
    const onKeyDown = (value) => {  
      if (props.onKeyDown !== null && props.onKeyDown !== undefined){
          props.onKeyDown(value);
      } 
    }  
    const onClick = () => {  
      if (props.onClick !== null && props.onClick !== undefined){
          props.onClick(); 
      } 
  }  

  const onFocus = (event) => {
    event.target.select();
    if (props.onFocus !== null && typeof(props.onFocus) === 'function'){
      props.onFocus(event);
    } 
  }

    const isFormFieldInValid = (name) => !!(typeof(props.errors) !== 'undefined' && props.errors !== null && props.errors[name]);
    
    const getFormErrorMessage = (name) => { 
        return isFormFieldInValid(name) && <small className="p-error">{props.errors[name].message}</small>;
    };

    const onClickKeyboard = () => {
      setShowKeyboard(true);
    }

    const onChangekeyboard = (text) => {
      if (props.onChange !== null && props.onChange !== undefined){
        const e = {
          target: {
            value: text
          }
        }

        console.log('value', e);
        props.onChange(e);
      } 
    }
 
    if (props.IsShowKeyboard){
      return (
        <>
          <div className="p-inputgroup">
            <InputText 
              value={props.value === null ? '' : props.value} 
              onChange={onChange}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
              className={isFormFieldInValid(props.name) === true ? 'p-invalid' : ''}
              disabled={props.disabled}
              maxLength={props.maxLength}
              placeholder={props.placeholder}
              ref={props.reference} 
              onFocus={onClickKeyboard}
            />  
            <Button
              disabled={props.disabled} 
              className={props.classGroup} 
              onClick={onClickKeyboard}
            > 
              <i className="fas fa-keyboard"></i>
            </Button>
          </div>
          <span className="p-float-label">
            <label className={isFormFieldInValid(props.name) === true ? 'p-error' : ''}>{props.label}</label>
          </span>
          { getFormErrorMessage(props.name) }   
          {
            ShowKeyboard ?
            (<FieldKeyboard show={ShowKeyboard} label={props.title} value={props.value} onHide={() => setShowKeyboard(!ShowKeyboard)} onChange={(text) => onChangekeyboard(text)} />)
            :
            (<></>)
          }          
        </> 
      )
    }

    if(props.isInputGroup){
      return ( 
        <>
          <div className="p-inputgroup">
            <InputText 
              value={props.value === null ? '' : props.value} 
              onChange={onChange}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
              className={isFormFieldInValid(props.name) === true ? 'p-invalid' : ''}
              disabled={props.disabled}
              maxLength={props.maxLength}
              placeholder={props.placeholder}
              ref={props.reference}
              autoFocus={props.autoFocus}
              onFocus={onFocus}
            />  
            <Button
              disabled={props.disabled} 
              className={props.classGroup} 
              onClick={onClick}
            >
              <i className={props.iconGroup}></i>
            </Button>
          </div>
          <span className="p-float-label">
            <label className={isFormFieldInValid(props.name) === true ? 'p-error' : ''}>{props.label}</label>
          </span>
          { getFormErrorMessage(props.name) } 
        </>
        )
      }
    return (  
      <>  
        <span className="p-float-label">
          <InputText 
            value={props.value === null ? '' : props.value} 
            onChange={onChange}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            className={isFormFieldInValid(props.name) === true ? 'p-invalid' : ''}
            disabled={props.disabled}
            maxLength={props.maxLength}
            placeholder={props.placeholder}
            autoFocus={props.autoFocus}
            ref={props.reference}
            onFocus={onFocus}
            keyfilter={props.keyfilter}
          />  
          <label className={isFormFieldInValid(props.name) === true ? 'p-error' : ''}>{props.label}</label>
        </span>
        { getFormErrorMessage(props.name) } 
      </> 
    )
}   

    
export default FieldText; 

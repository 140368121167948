/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
import React, { useState, useRef  }  from 'react';  
import { Editor } from 'primereact/editor';
import { Toast } from 'primereact/toast';

const EditorText =  (props) => {
    
    const warningMessageCopyPaste = useRef(null);
    const onTextChange = (value, secondValue) => {
        console.log('value', value);  
        console.log('value', secondValue);
        if (props.onTextChange !== null && props.onTextChange !== undefined){
            props.onTextChange(value);
        } 
    }  

    const renderHeader = () => {
      return (
        <>
          <span className="ql-formats">
            <select className="ql-size"></select>
            <select className="ql-font"></select>
          </span>
          <span className="ql-formats">
            <button className="ql-bold"></button>
            <button className="ql-italic"></button>
            <button className="ql-underline"></button>
            <button className="ql-strike"></button>
          </span>
          <span className="ql-formats">
            <select className="ql-color"></select>
            <select className="ql-background"></select>
          </span>
          <span className="ql-formats"> 
            <button type="button" className="ql-list" value="ordered" aria-label="Ordered List"></button>
            <button type="button" className="ql-list" value="bullet" aria-label="Unordered List"></button>
            <select className="ql-align"></select>
          </span>
        </>
      );
  }

  const header = renderHeader();
 
  const onPaste = (e) => {
    warningMessageCopyPaste.current.show({severity:'warn', summary: 'Warn Message', detail:'Copy paste is not allowed!!!', life: 3000});
    e.preventDefault();
  }

      return (  
        <>   
          <div onPaste={onPaste}>
            <Editor    
              id={props.id}
              value={props.value}
              style={props.style}
              className={props.className}
              placeholder={props.placeholder}
              readOnly={props.readOnly}
              modules={props.modules}
              formats={props.formats}             
              headerTemplate={header}   
              onTextChange={onTextChange}
            >
            </Editor>   
          </div>
          <Toast ref={warningMessageCopyPaste} position="bottom-center" />
        </> 
      )
}   
    
export default EditorText; 

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next';   
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';
import { Row,Col} from 'reactstrap';   
import { config } from '../../../utils/config';
import { callApi, showSweetAlert, tracker} from '../../../utils/utils'; 
import FieldToogleSwitch from '../../../components/Controls/FieldToogleSwitch/FieldToogleSwitch'; 
import FieldText  from '../../../components/Controls/FieldText.jsx' 
import FieldTextArea  from '../../../components/Controls/FieldTextArea.jsx' 
import FieldNumber from '../../../components/Controls/FieldNumber.jsx'
import FieldCalendar from 'components/Controls/FieldCalendar.jsx' 
import FieldDropdown from 'components/Controls/FieldDropdown.jsx' 
import FasesActividadesAnexo from "./FasesActividadesAnexo.jsx"; 
import { Dialog } from 'primereact/dialog'; 
import { Sidebar } from 'primereact/sidebar'; 
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import MoverActividadNuevaFase from './MoverActividadNuevaFase.jsx';

const FasesActividades = (props) => { 
   
  /* ################## useState area start ################## */
  const { t } = useTranslation(['scoreCard', 'common', 'misActividades']);
  const [errors, setErrors] = useState({}); 
  const [ClaProyecto, setClaProyecto] = useState(props.ClaProyecto === undefined ? 0 : props.ClaProyecto); 
  const [ClaFase, setClaFase] = useState(props.ClaFase === undefined ? 0 : props.ClaFase); 
  const [ClaActividad, setClaActividad] = useState(props.ClaActividad === undefined ? 0 : props.ClaActividad); 
  const [ClaEstatus, setClaEstatus] = useState(null);
  const [BajaLogica, setBajaLogica] = useState(null);  
  const [NomProyecto, setNomProyecto] = useState(null); 
  const [NomFase, setNomFase] = useState(null); 
  const [PorcAvance, setPorcAvance] = useState(null); 
  const [NomActividad, setNomActividad] = useState(null); 
  const [yesNoOptions, setYesNoOptions] = useState([`${t('common:Yes')}`, `${t('common:No')}`]);
  const [OpcionesResponsables, setOpcionesResponsables] = useState([]);
  const [Milestone, setMilestone] = useState(false); 
  const [Descripcion, setDescripcion] = useState(null); 
  const [ClaResponsable , setClaResponsable ] = useState(null); 
  const [ClaAutorizador, setClaAutorizador] = useState(null); 
  const [NomResponsable, setNomResponsable] = useState(null); 
  const [NomAutorizador, setNomAutorizador] = useState(null); 
  const [FechaEstimadaInicio , setFechaEstimadaInicio ] = useState(null); 
  const [FechaEstimadaFin, setFechaEstimadaFin] = useState(null);  
  const [DiasEstimados, setDiasEstimados] = useState(null); 
  const [Entregable, setEntregable] = useState(null); 
  const [OpcionesFaseEntregable, setOpcionesFaseEntregable] = useState([]);
  const [DisabledProyectoFaseEntregable, setDisabledProyectoFaseEntregable] = useState(true);
  const [IdProyectoFaseEntregable, setIdProyectoFaseEntregable] = useState(null); 
  const [ClaResponsableFaseEntregable, setClaResponsableFaseEntregable] = useState(null); 
  const [DescripcionFaseEntregable, setDescripcionFaseEntregable] = useState(null); 
  const [FechaCompromisoFaseEntregable, setFechaCompromisoFaseEntregable] = useState(null); 
  const [FechaRealFin, setFechaRealFin] = useState(null); 
  const [StatusFasesActividad, setStatusFasesActividad] = useState([]);
  const [EsNuevoDelivery, setEsNuevoDelivery] = useState(true); 
  const [inplaceActive, setInplaceActive] = useState(false);
  const [EsVerAnexo, setEsVerAnexo] = useState(false);
  const [ClaActividadSelected, setClaActividadSelected] = useState(null); 
  const [EsMostrarMoverActividad, setEsMostrarMoverActividad] = useState(false); 
  useEffect(() => {     
    // if(props.ClaActividad >0){
      setClaActividad(props.ClaActividad);
      setClaFase(props.ClaFase);
      setClaProyecto(props.ClaProyecto); 
      obtenerResponsablesProyecto(); 
    // }
  }, [props.ClaActividad, props.ClaFase, props.ClaProyecto]); 

  const obtenerResponsablesProyecto = () => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/GetMswBSCColaboradorProyectosTeam`;  
    const paramsToService = { 
      ClaProyecto: props.ClaProyecto
    };   
    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => { 
      if (response.data[0].length >0)
      {  
        setOpcionesResponsables(response.data[0]);
        obtenerEstatusFaseActividad();
      }  
    });
  }
  
  const obtenerEstatusFaseActividad = () => { 
    const GetStatusFasesActividad = `${config.UrlApiProject}ScoreCard/GetMSWEstatusFasesCmb`;  
    const paramsToService = {};   
    callApi(GetStatusFasesActividad, 'POST', paramsToService, (response) => { 
      if (response.data[0].length >0)
      {  
        setStatusFasesActividad(response.data[0]);
        obtenerProyectoFaseEntregable();
      }  
    });
  }
  const obtenerProyectoFaseEntregable = () => { 
    const GetUrlFaseEntregable = `${config.UrlApiProject}ScoreCard/GetProyectoFaseEntregable`;  
    const paramsToService = { 
      ClaProyecto: props.ClaProyecto,
      ClaFase: props.ClaFase
    };   
    callApi(GetUrlFaseEntregable, 'POST', paramsToService, (response) => { 
      setOpcionesFaseEntregable(response.data[0]);
      obtenerInformacionActividadFase();
    });
  }

  const obtenerInformacionActividadFase = (claActividad) => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/GetBSCActividadesFases`;  
    const paramsToService = { 
      ClaProyecto: props.ClaProyecto,
      ClaFase:props.ClaFase,
      ClaActividad: claActividad === null || typeof(claActividad) === 'undefined' ? props.ClaActividad : claActividad
    };   
    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => { 
      const ClaseActividad = response.data.ActividadFase[0];
      if (ClaseActividad !== undefined)
      {  
        setNomActividad(ClaseActividad.NomActividad); 
        setDescripcion(ClaseActividad.Descripcion); 
        setClaResponsable(ClaseActividad.ClaResponsable); 
        setNomResponsable(ClaseActividad.NomResponsable); 
        setClaAutorizador(ClaseActividad.ClaAutorizador); 
        setNomAutorizador(ClaseActividad.NomAutorizador); 
        setFechaEstimadaInicio((ClaseActividad.FechaEstimadaInicio!== null ? (new Date(ClaseActividad.FechaEstimadaInicio)): null)); 
        setFechaEstimadaFin((ClaseActividad.FechaEstimadaFin!== null ? (new Date(ClaseActividad.FechaEstimadaFin)): null)); 
        setFechaRealFin((ClaseActividad.FechaRealFin!== null ? (new Date(ClaseActividad.FechaRealFin)): null)); 
        setDiasEstimados(ClaseActividad.DiasEstimados); 
        setEntregable(ClaseActividad.Entregable); 
        setMilestone(ClaseActividad.Milestone);
        setClaEstatus(ClaseActividad.ClaEstatus);
        setBajaLogica(ClaseActividad.Bajalogica);
        setNomProyecto(ClaseActividad.NomProyecto);
        setNomFase(ClaseActividad.NomFase);
        setPorcAvance(ClaseActividad.PorcAvance);
        setIdProyectoFaseEntregable(ClaseActividad.IdProyectoFaseEntregable);
        onEsNuevoDeliveryChange(true);
        if(ClaseActividad.ProyectoFaseEntregable!==undefined && ClaseActividad.ProyectoFaseEntregable!==null){
          onEsNuevoDeliveryChange(false);
          setIdProyectoFaseEntregable(ClaseActividad.IdProyectoFaseEntregable);
          setClaResponsableFaseEntregable(ClaseActividad.ProyectoFaseEntregable.ClaResponsable);
          setDescripcionFaseEntregable(ClaseActividad.ProyectoFaseEntregable.Descripcion);
          setFechaCompromisoFaseEntregable((ClaseActividad.ProyectoFaseEntregable.FechaCompromiso!== null ? (new Date(ClaseActividad.ProyectoFaseEntregable.FechaCompromiso)): null));
        }

        if (ClaseActividad.NomActividad === null || ClaseActividad.NomActividad === ''){
          setInplaceActive(true);
        }else{
          setInplaceActive(false);
        }
      }  
    });
  }

  const onChangeDescripcion = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.Descripcion;   
    setErrors(newErrors) 
    setDescripcion(e.target.value)
  } 

  const onClaResponsableFaseEntregableChange = (props, e ) => {
    const newErrors = { ...errors } 
    delete newErrors.ClaResponsableFaseEntregable;   
    setErrors(newErrors) 
    setClaResponsableFaseEntregable(e.value)
  }; 

  const onFechaCompromisoFaseEntregableChange = ( e ) => {  
    const newErrors = { ...errors } 
    delete newErrors.FechaCompromisoFaseEntregable;   
    setErrors(newErrors) 
    setFechaCompromisoFaseEntregable(e.value)
  }; 

 const onFechaRealFinChange = ( e ) => {  
    const newErrors = { ...errors } 
    delete newErrors.FechaRealFin;   
    setErrors(newErrors) 
    setFechaRealFin(e.value)
  }; 

  const onIdProyectoFaseEntregableChange = (e) => {
    const newErrors = { ...errors } 
    delete newErrors.IdProyectoFaseEntregable;   
    setErrors(newErrors) 
    setDisabledProyectoFaseEntregable(true);
    setIdProyectoFaseEntregable(e.value);
    if(e.value===undefined){
      setClaResponsableFaseEntregable(null);
      setDescripcionFaseEntregable(null);
      setFechaCompromisoFaseEntregable(null);
    }else{
      const GetUrlFaseEntregable = `${config.UrlApiProject}ScoreCard/GetProyectoFaseEntregableId`;  
      const paramsToService = { 
        IdProyectoFaseEntregable:e.value
      };   
      callApi(GetUrlFaseEntregable, 'POST', paramsToService, (response) => { 
        const proyectoFaseEntregable = response.data[0];
        if (proyectoFaseEntregable.length >0)
        {  
          if(proyectoFaseEntregable[0]!==undefined){
            setClaResponsableFaseEntregable(proyectoFaseEntregable[0].ClaResponsable);
            setDescripcionFaseEntregable(proyectoFaseEntregable[0].Descripcion);
            setFechaCompromisoFaseEntregable((proyectoFaseEntregable[0].FechaCompromiso!== null ? (new Date(proyectoFaseEntregable[0].FechaCompromiso)): null));
          }
        }  
      }); 
    }
  };

  const onClaResponsableChange = (props, e ) => {
    const newErrors = { ...errors } 
    delete newErrors.ClaResponsable;
    delete newErrors.ClaAutorizador;   
    setErrors(newErrors); 
    setClaResponsable(e.value);
    setClaAutorizador(e.value);
  }; 
  
  const onClaAutorizadorChange = (props, e ) => {
    const newErrors = { ...errors } 
    delete newErrors.ClaAutorizador;   
    setErrors(newErrors); 
    setClaAutorizador(e.value);
  }; 

  const onFechaEstimadaInicioChange = ( e ) => {  
    const newErrors = { ...errors } 
    delete newErrors.FechaEstimadaInicio;   
    setErrors(newErrors) 
    setFechaEstimadaInicio(e.value); 
    obtenerDiasFecha(DiasEstimados,e.value,FechaEstimadaFin,2);
  }; 

  const onFechaEstimadaFinChange = ( e ) => {  
    const newErrors = { ...errors } 
    delete newErrors.FechaEstimadaFin;   
    setErrors(newErrors) 
    setFechaEstimadaFin(e.value);
    obtenerDiasFecha(DiasEstimados,FechaEstimadaInicio,e.value,3);
  }; 
  
  const onEstatusChange = (value) => { 
    const newErrors = { ...errors } 
    delete newErrors.ClaEstatus;   
    setErrors(newErrors) 
    setClaEstatus(value)
  }; 

  const onDiasEstimadosChange = (e) => {  
    const newErrors = { ...errors }
    delete newErrors.DiasEstimados;   
    setErrors(newErrors);
    setDiasEstimados(e.target.value);
    obtenerDiasFecha(e.target.value,FechaEstimadaInicio,FechaEstimadaFin,1);
  }

  const obtenerDiasFecha = (nDias,dFechaInicio, dFechaFin, CasoCambio) => { 
    const GetUrlService = `${config.UrlApiProject}ScoreCard/GetCalculoFechaDias`;  
    const paramsToService = {
      DiasEstimados:nDias,
      FechaEstimadaInicio:(dFechaInicio!== null ? (new Date(dFechaInicio)): null),
      FechaEstimadaFin:(dFechaFin!== null ? (new Date(dFechaFin)): null),
      EsCasoCambio:CasoCambio
    };   
    callApi(GetUrlService, 'POST', paramsToService, (response) => { 
      const infoData = response.data[0][0];
      if(infoData!==undefined){
        setFechaEstimadaInicio((infoData.FechaEstIn!== null ? (new Date(infoData.FechaEstIn)): null)); 
        setFechaEstimadaFin((infoData.FechaEstFin!== null ? (new Date(infoData.FechaEstFin)): null)); 
        setDiasEstimados(infoData.DiasEstimados);
      }
    }); 
  }

  const onDescripcionFaseEntregableChange = (e) => {  
    const newErrors = { ...errors }
    delete newErrors.DescripcionFaseEntregable;   
    setErrors(newErrors);
    setDescripcionFaseEntregable(e.target.value);
  }

  const onMilestoneChange = (EsMilestone) => {  
    onEsNuevoDeliveryChange(true);
    if(EsMilestone === false){
      onEsNuevoDeliveryChange(false);
      setIdProyectoFaseEntregable(null);
    }
    setMilestone(EsMilestone);
  }
  
  const onEsNuevoDeliveryChange = (esNuevo) => {   
    const newErrors = { ...errors } 
    delete newErrors.DescripcionFaseEntregable;   
    delete newErrors.ClaResponsableFaseEntregable;   
    delete newErrors.FechaCompromisoFaseEntregable;   
    setErrors(newErrors) 
    if(esNuevo){
      setIdProyectoFaseEntregable(0);
      setClaResponsableFaseEntregable(null);
      setDescripcionFaseEntregable(null);
      setFechaCompromisoFaseEntregable(null);
      setDisabledProyectoFaseEntregable(false);
    }else{
      setDisabledProyectoFaseEntregable(true);
      setIdProyectoFaseEntregable(null);
    }
    setEsNuevoDelivery(esNuevo);
  }

 const onNomActividadChange = (value) => {   
    setNomActividad(value);
  }
  
  const valitationSchemeSave = yup.object().shape({
      NomActividad:yup.string().required(t('common:RequiredField')).nullable(),
      ClaResponsable:yup.mixed().required(t('common:RequiredField')).nullable(),
      ClaAutorizador:yup.mixed().required(t('common:RequiredField')).nullable(),
      FechaEstimadaInicio:yup.date().required(t('common:RequiredField')).nullable(), 
      FechaEstimadaFin:yup.date().required(t('common:RequiredField')).nullable() ,
      DiasEstimados:yup.mixed().required(t('common:RequiredField')).nullable(),
      ClaEstatus:yup.mixed().required(t('common:RequiredField')).nullable(),
      FechaRealFin: (
        ClaEstatus ===2
        ? yup.date().required(t('common:RequiredField')).nullable()
        : yup.date().nullable()
        ),
      IdProyectoFaseEntregable: (
                        !EsNuevoDelivery && Milestone
                        ? yup.mixed().required(t('common:RequiredField')).nullable()
                        : yup.mixed().nullable()
                        ),
      ClaResponsableFaseEntregable: (
                          EsNuevoDelivery && Milestone
                          ? yup.mixed().required(t('common:RequiredField')).nullable() 
                          : yup.mixed().nullable()
                          ), 
      DescripcionFaseEntregable: (
                          EsNuevoDelivery && Milestone
                          ? yup.string().required(t('common:RequiredField')).nullable() 
                          : yup.string().nullable()
                          ), 
      FechaCompromisoFaseEntregable: (
                          EsNuevoDelivery && Milestone
                          ? yup.date().required(t('common:RequiredField')).nullable() 
                          : yup.date().nullable()
                          )
  });  

 const save = async () => {
  const value = {
    NomActividad,
    IdProyectoFaseEntregable,
    ClaResponsableFaseEntregable,
    DescripcionFaseEntregable,
    FechaCompromisoFaseEntregable,
    ClaResponsable,
    ClaAutorizador,
    FechaEstimadaInicio,
    FechaEstimadaFin,
    FechaRealFin,
    DiasEstimados,
    ClaEstatus
  }
  const validator = new YupValidator(valitationSchemeSave);
  const esValido = await validator.validate(value);
  if (!esValido){ 
    setErrors(validator.errors); 
    showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
    return;
  }
  const urlWebServiceSave = `${config.UrlApiProject}ScoreCard/SaveFaseProyectoActividad`;
  const FaseEntregable={
    IdProyectoFaseEntregable,
    ClaProyecto,
    ClaFase,
    ClaResponsable:ClaResponsableFaseEntregable,
    Descripcion:DescripcionFaseEntregable,
    FechaCompromiso:(FechaCompromisoFaseEntregable!== null ? (new Date(FechaCompromisoFaseEntregable)): null)
  }
  const paramsService = {
      ClaProyecto,
      ClaFase,
      ClaActividad,
      NomActividad,
      Descripcion,
      ClaResponsable,
      ClaAutorizador,
      FechaEstimadaInicio:(FechaEstimadaInicio!== null ? (new Date(FechaEstimadaInicio)): null),
      FechaEstimadaFin:(FechaEstimadaFin!== null ? (new Date(FechaEstimadaFin)): null),
      FechaRealFin:(FechaRealFin!== null ? (new Date(FechaRealFin)): null),
      ClaEstatus,
      BajaLogica,
      Milestone:(Milestone?1:0),
      DiasEstimados,
      IdProyectoFaseEntregable,
      ProyectoFaseEntregable:FaseEntregable
  }; 
 
  callApi(urlWebServiceSave, 'POST', paramsService, (response) => { 
    showSweetAlert('success', t('common:SuccessOperation'), 'success');
    tracker.trackEvent({
      type: 'FasesActividades', /* component name */
      action: 'SAVE_ACTIVITY'
    })

    // console.log('response', response);
    // ya no la vamos a cerrar ahora vamos a mandar mensaje, refrescar pantalla y lo van a tener que cerrar manualmente...
    if (ClaActividad === null || typeof(ClaActividad) === 'undefined' || ClaActividad <= 0){
      setClaActividad(response.data.ClaActividad);
      obtenerInformacionActividadFase (response.data.ClaActividad);
    }
    else {
      close();
    }
    // 
  });
 }

 const onBlurNomActividad = (value) => {   
  if (value === '' || value === null){
    setInplaceActive(true);
  }
  else{
    setInplaceActive(false);
  }
}

const handleHeaderMoverActividadClick = () => {
  // document.body.classList.add('bodynotscroll');
  setClaActividadSelected(ClaActividad);
  setEsMostrarMoverActividad(true);
};


 const customIcons = (
   <> 
     <div>
       <Row className="align-items-start border-bottom-red">
         <Col>
           <Row>
             <Col lg={8} md={12} sm={12} xs={12}>
               <Inplace
                 closable={false}
                 active={inplaceActive}
                 onToggle={(e) => setInplaceActive(e.value)}
                 className="p-fluid"
               >
                 <InplaceDisplay>
                   <span className="fa fa-pencil-alt"></span> <b>{NomActividad}</b>
                 </InplaceDisplay>
                 <InplaceContent>
                   <FieldText
                     name="NomActividad"
                     value={NomActividad} 
                     onBlur={(e) => onBlurNomActividad(e.target.value)}
                     onChange={(e) => onNomActividadChange(e.target.value)}
                     autoFocus
                     maxLength={250}
                     errors={errors} 
                   /> 
                 </InplaceContent>
               </Inplace> 
             </Col>
             <Col lg={4} md={12} sm={12} xs={12}>
               <Row className="align-items-start"> 
                 <Col lg={6} md={12} sm={12} xs={12} className="text-right"> 
                   <div className="p-fluid pb-2"> 
                     <FieldButton
                       type="button" 
                       onClick={(e) => close(e)}
                       label={t('common:Close')}
                       className="p-button-secondary no-border p-mr-2 rounded-button"
                       style={{ marginRight: '.25em' }}
                     /> 
                   </div>
                 </Col>
                 <Col lg={6} md={12} sm={12} xs={12}> 
                   <div className="p-fluid pb-2">
                     <FieldButton
                       type="button"
                       onClick={save} 
                       label={t('common:Save')}
                       className="p-button-success no-border p-mr-2 rounded-button"
                       style={{ marginRight: '.25em' }}
                     /> 
                   </div>
                 </Col>
               </Row>
             </Col>
           </Row>
           <Row>
             <Col className="secondary-color">
               <span className="fa fa-folder"> </span> {NomProyecto} - {NomFase}
             </Col>  
             <Col lg={5} md={12} sm={12} xs={12}> 
               <Row> 
                 <Col lg={12} md={12} sm={12} xs={12} className="text-right"> 
                   {ClaActividad > 0 ?
                   (
                     <div className="p-fluid pb-2">
                       <table style={{widt:'100%'}}>
                         <tr>
                           <td><FieldButton icon="fas fa-exchange-alt" title={t('misActividades:Move')} onClick={() => handleHeaderMoverActividadClick()} className="p-button-rounded p-button-secondary" /> 
                           </td>
                           <td><FieldButton
                             type="button" 
                             onClick={() => anexo()}
                             icon="fas fa-file-upload"
                             className="primary p-button-rounded p-button-outlined"
                             label={t('misActividades:AttachSupportDocument')}
                           /> 
                           </td>
                         </tr>
                       </table>
                        
                       
                     </div>
                   )
                   :
                   (<></>)}
                  
                 </Col>
               </Row>
             </Col>  
           </Row> 
         </Col> 
        
       </Row>  
     </div>
   </>
);

const onHideActividad = () => {
  // document.body.classList.remove('bodynotscroll');
  if (props.onClickClose !== null){
    props.onClickClose();
  }   
}
 const anexo = async () => {
  setEsVerAnexo(!EsVerAnexo);
}
 const close = async () => {
  setErrors({});
  if (props.onClickClose !== null){
    props.onClickClose();
  }   
}
const dialogStyle  = ( 
   { 
    width: '80vw', 
    borderLeft:"solid 2px #2791FF", 
    borderTop:"solid 2px #2791FF"
  }
)
const closeModal = () => {
  setEsVerAnexo(false);
} 

const onHideMoverActividad = () => {
  setEsMostrarMoverActividad(false);
  // refrescamos la informacion 
  if (props.onClickClose !== null){
    props.onClickClose();
  } 
}
 
    return (
      <>   
        <Sidebar blockScroll={true} icons={customIcons} showCloseIcon={false} visible={props.IsVisible} className="p-sidebar-lg" position="right" dismissable={false} baseZIndex={1000000} onHide={(e) => onHideActividad()}>
          <Row hidden={true}>
            <Col className="secondary-color">
               &nbsp;
            </Col>  
            <Col lg={4} md={12} sm={12} xs={12}> 
              <Row> 
                <Col lg={12} md={12} sm={12} xs={12} className="text-right"> 
                  <div className="p-fluid pb-2">
                    <FieldButton
                      type="button" 
                      icon="fas fa-copy" 
                      title={t('misActividades:CopyActivity')}
                      onClick={() => anexo()} 
                      className="p-button-rounded p-button-secondary"
                      label={t('misActividades:CopyActivity')}
                    /> 
                  </div>  
                </Col>
              </Row>
            </Col>  
          </Row>
          <Row className="pb-1 pt-1">
            <Col className="secondary-color" lg={12} md={12} sm={12} xs={12}>
              <div className="p-fluid">
                <Row className="align-items-start">
                  <Col lg={4} md={12} sm={12} xs={12}> 
                    <b style={{color:"#666666"}}>{t('scoreCard:RequireDeliverable')}</b>&nbsp;
                    <FieldToogleSwitch
                      id="Milestone"
                      checked={Milestone}  
                      onChange={onMilestoneChange} 
                      optionLabels={yesNoOptions}
                      small
                    />
                  </Col>  
                  <Col lg={8} md={12} sm={12} xs={12} hidden={!Milestone}> 
                    <b style={{color:"#666666"}}>{t('scoreCard:NewDeliverable')}</b>&nbsp;
                    <FieldToogleSwitch
                      id="EsNuevoDelivery"
                      checked={EsNuevoDelivery}   
                      onChange={(e) => onEsNuevoDeliveryChange(e)}
                      optionLabels={yesNoOptions}
                      small
                    />
                  </Col>
                </Row> 
              </div>
            </Col>  
          </Row> 
          <Row hidden={!Milestone}>
            <Col>
            &nbsp;
            </Col>
            <Col lg={8} md={12} sm={12} xs={12} className="pull-right">
              <div className="p-fluid pb-4" hidden={EsNuevoDelivery}>
                <FieldDropdown
                  name="IdProyectoFaseEntregable"
                  value={IdProyectoFaseEntregable}
                  options={OpcionesFaseEntregable}  
                  optionValue="IdProyectoFaseEntregable"
                  optionLabel="Descripcion" 
                  placeholder={t('scoreCard:Deliverable')}
                  onChange={(e) => onIdProyectoFaseEntregableChange(e)}
                  errors={errors}
                />   
              </div>
            </Col>
          </Row>
          <Row className="pb-3 pt-1" hidden={!Milestone}>
            <Col className="secondary-color" lg={12} md={12} sm={12} xs={12}>
              <div className="p-fluid">
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}> 
                    <div className="p-fluid">
                      <FieldText 
                        name="DescripcionFaseEntregable"
                        value={DescripcionFaseEntregable} 
                        label={t('scoreCard:Deliverable')}
                        onChange={onDescripcionFaseEntregableChange}
                        disabled={DisabledProyectoFaseEntregable}
                        errors={errors} 
                        maxLength={150}
                      />
                    </div> 
                  </Col>
                </Row>  
              </div>
            </Col>  
          </Row> 
          {
          Milestone? (
            <>
              <Row className="border-bottom-dashed pb-3">
                <Col lg={6} md={12} sm={12} xs={12}>
                  <div className="p-fluid pb-1 pt-3">
                    <FieldCalendar
                      name="FechaCompromisoFaseEntregable" 
                      label={t('scoreCard:EstimatedDate')}
                      value={(FechaCompromisoFaseEntregable!== null ? (new Date(FechaCompromisoFaseEntregable)): null)}
                      onChange={(e) => onFechaCompromisoFaseEntregableChange(e)}
                      disabled={DisabledProyectoFaseEntregable}
                      errors={errors}
                    />  
                  </div> 
                </Col>  
                <Col lg={6} md={12} sm={12} xs={12}>
                  <div className="p-fluid pb-1 pt-3">
                    <FieldDropdown  
                      name="ClaResponsableFaseEntregable"
                      value={ClaResponsableFaseEntregable}
                      options={OpcionesResponsables}  
                      optionValue="ClaColaborador"
                      optionLabel="NomColaborador" 
                      label={t('scoreCard:Responsible')}
                      disabled={DisabledProyectoFaseEntregable}
                      onChange={(e) => onClaResponsableFaseEntregableChange(props,e)}
                      errors={errors}
                    />
                  </div> 
                </Col>
              </Row> 
            </>
          ):(
            <>
            </>
          )
        }
          <Row className="pb-2 pt-3">
            <Col>
              <div className="p-fluid">
                <FieldTextArea 
                  name="Descripcion"
                  rows={4}
                  placeholder={t('scoreCard:Description')}
                  value={Descripcion} 
                  onChange={onChangeDescripcion}
                  errors={errors} 
                  maxLength={8000}
                />
              </div> 
            </Col>  
          </Row> 
          <Row className="pb-3 pt-2">
            <Col lg={6} md={12} sm={12} xs={12}>
              <div className="p-fluid pt-3 pb-3">
                <FieldDropdown  
                  name="ClaResponsable"
                  value={ClaResponsable}
                  options={OpcionesResponsables}  
                  optionValue="ClaColaborador"
                  optionLabel="NomColaborador" 
                  label={t('scoreCard:Responsible')}
                  className="pt-3"
                  onChange={(e) => onClaResponsableChange(props,e)}
                  errors={errors}
                />  
              </div> 
            </Col>  
            <Col lg={6} md={12} sm={12} xs={12}>
              <div className="p-fluid pt-3 pb-3">
                <FieldDropdown  
                  name="ClaAutorizador"
                  value={ClaAutorizador}
                  options={OpcionesResponsables}  
                  optionValue="ClaColaborador"
                  optionLabel="NomColaborador" 
                  label={t('scoreCard:Authorizer')}
                  onChange={(e) => onClaAutorizadorChange(props,e)}
                  errors={errors}
                />  
              </div> 
            </Col>  
          </Row> 
          <Row className="pt-2">
            <Col lg={6} md={12} sm={12} xs={12}>
              <div className="p-fluid pb-3 pt-2">
                <FieldCalendar
                  name="FechaEstimadaInicio" 
                  label={t('scoreCard:EstimatedDateStart')}
                  value={(FechaEstimadaInicio!== null ? (new Date(FechaEstimadaInicio)): null)}
                  onChange={(e) => onFechaEstimadaInicioChange(e)}
                  errors={errors}
                />  
              </div> 
            </Col>  
            <Col lg={6} md={12} sm={12} xs={12}>
              <div className="p-fluid pb-3 pt-2">
                <FieldCalendar
                  name="FechaEstimadaFin"
                  label={t('scoreCard:EstimatedDateEnd')}
                  value={(FechaEstimadaFin!== null ? (new Date(FechaEstimadaFin)): null)}
                  onChange={(e) => onFechaEstimadaFinChange(e)}
                  errors={errors}
                />   
              </div> 
            </Col>  
          </Row> 
          <Row className="pb-2">
            <Col lg={6} md={12} sm={12} xs={12}>
              <div className="p-fluid pb-3 pt-2">
                <span className="spanController">{t('scoreCard:EstimatedDuration')}</span>  
                <FieldNumber 
                  name="DiasEstimados" 
                  value={DiasEstimados}
                  onChange={onDiasEstimadosChange}
                  min={0}
                  errors={errors}
                  isInputGroup 
                  isGroupRight
                  labelGroupRight={t('scoreCard:Days')}
                />  
              </div>
            </Col>  
            <Col lg={6} md={12} sm={12} xs={12}>
              &nbsp;
            </Col>
          </Row> 
          <Row className="pb-3 pt-3">
            <Col lg={6} md={12} sm={12} xs={12}>
              <div className="p-fluid">
                <FieldDropdown  
                  name="ClaEstatus"
                  value={ClaEstatus} 
                  options={StatusFasesActividad}  
                  optionValue="ClaEstatusFase"
                  optionLabel="NomEstatusFase" 
                  onChange={(e) => onEstatusChange(e.value)}
                  label={t('scoreCard:Status')}
                  errors={errors}
                />   
              </div>
            </Col>
            <Col>
              <div hidden={ClaEstatus!==2} className="p-fluid">
                <FieldCalendar
                  name="FechaRealFin" 
                  label={t('scoreCard:RealEndDate')}
                  value={(FechaRealFin!== null ? (new Date(FechaRealFin)): null)}
                  onChange={(e) => onFechaRealFinChange(e)}
                  errors={errors}
                />  
              </div> 
            </Col>
          </Row>
        </Sidebar>
        <Dialog
          visible={EsVerAnexo}
          header={t('scoreCard:ActivityDocuments')}
          style={dialogStyle} 
          modal
          className="dialog-custom"
          onHide={closeModal}
        >
          <FasesActividadesAnexo IsVisible={EsVerAnexo} ClaProyecto={ClaProyecto} ClaFase={ClaFase} ClaActividad={ClaActividad}  />
        </Dialog>

        {
        EsMostrarMoverActividad !== null ? (
          <> 
            <MoverActividadNuevaFase IsVisible={EsMostrarMoverActividad} onClickClose={onHideMoverActividad} onChange={onHideMoverActividad} ClaProyecto={props.ClaProyecto} ClaFase={props.ClaFase} ClaActividad={ClaActividad} />
          </>
          ):(<></>)
       }
      </>  
        ); 
};
export default FasesActividades;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle  } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { PickList } from 'primereact/picklist';
import { TreeList, Scrolling, Paging, Pager, Column, Selection, Format, SearchPanel, Sorting } from 'devextreme-react/tree-list';
import { config } from '../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../utils/utils';
import moment from 'moment'; 
import { Link } from "react-router-dom";
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import './PLByBucketTreeCorporateView.css';
import Highcharts from 'highcharts';

const PLByBucketTreeCorporateView = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['common']);  
  const [errors, setErrors] = useState({}); 
  const expandedRowKeys = [0];
  const [matriz, setMatriz] = useState([]);    
  const editProjectLnk = useRef(null); 
  const [Abrir5Year, setAbrir5Year] = useState(true); 
  const [AbrirPrimerYear, setAbrirPrimerYear] = useState(false); 
  const [AbrirSegundoYear, setAbrirSegundoYear] = useState(false); 
  const [AbrirAVGYear, setAbrirAVGYear] = useState(true); 
  const [ColumnasNombreDinamico, setColumnasNombreDinamico] = useState({}); 
  const [EsVisibleJan, setEsVisibleJan] = useState(false);  
  const [EsVisibleFeb, setEsVisibleFeb] = useState(false);  
  const [EsVisibleMar, setEsVisibleMar] = useState(false);  
  const [EsVisibleApr, setEsVisibleApr] = useState(false);  
  const [EsVisibleMay, setEsVisibleMay] = useState(false);  
  const [EsVisibleJun, setEsVisibleJun] = useState(false);  
  const [EsVisibleJul, setEsVisibleJul] = useState(false);  
  const [EsVisibleAug, setEsVisibleAug] = useState(false);  
  const [EsVisibleSep, setEsVisibleSep] = useState(false);  
  const [EsVisibleOct, setEsVisibleOct] = useState(false);  
  const [EsVisibleNov, setEsVisibleNov] = useState(false);  
  const [EsVisibleDec, setEsVisibleDec] = useState(false);  
  const [EsVisibleJan2, setEsVisibleJan2] = useState(false);  
  const [EsVisibleFeb2, setEsVisibleFeb2] = useState(false);  
  const [EsVisibleMar2, setEsVisibleMar2] = useState(false);  
  const [EsVisibleApr2, setEsVisibleApr2] = useState(false);  
  const [EsVisibleMay2, setEsVisibleMay2] = useState(false);  
  const [EsVisibleJun2, setEsVisibleJun2] = useState(false);  
  const [EsVisibleJul2, setEsVisibleJul2] = useState(false);  
  const [EsVisibleAug2, setEsVisibleAug2] = useState(false);  
  const [EsVisibleSep2, setEsVisibleSep2] = useState(false);  
  const [EsVisibleOct2, setEsVisibleOct2] = useState(false);  
  const [EsVisibleNov2, setEsVisibleNov2] = useState(false);  
  const [EsVisibleDec2, setEsVisibleDec2] = useState(false);  

  useEffect(() => {
    setMatriz(props.Rows === null || typeof(props.Rows) === 'undefined' ? []: props.Rows);
    setColumnasNombreDinamico( {
      Seg1ClaAnio             : props.filters === null || props.filters.Seg1ClaAnio=== undefined ? '0' : props.filters.Seg1ClaAnio
     ,Seg1NomMesFrom          : props.filters === null || props.filters.Seg1NomMesFrom === undefined ? '0' : props.filters.Seg1NomMesFrom  
     ,Seg1NomMesTo            : props.filters === null || props.filters.Seg1NomMesTo === undefined ? '0' : props.filters.Seg1NomMesTo
     ,Seg1NomEscenarioInfo    : props.filters === null || props.filters.Seg1NomEscenarioInfo === undefined ? '0' : props.filters.Seg1NomEscenarioInfo
     ,Seg2ClaAnio             : props.filters === null || props.filters.Seg2ClaAnio === undefined ? '0' : props.filters.Seg2ClaAnio
     ,Seg2NomMesFrom          : props.filters === null || props.filters.Seg2NomMesFrom === undefined ? '0' : props.filters.Seg2NomMesFrom
     ,Seg2NomMesTo            : props.filters === null || props.filters.Seg2NomMesTo === undefined ? '0' : props.filters.Seg2NomMesTo
     ,Seg2NomEscenarioInfo    : props.filters === null || props.filters.Seg2NomEscenarioInfo === undefined ? '0' : props.filters.Seg2NomEscenarioInfo   
     ,EsPromedio              : props.filters === null || props.filters.EsPromedio === undefined ? false : props.filters.EsPromedio
     ,EsAcumulado             : props.filters === null || props.filters.EsAcumulado  === undefined ? false : props.filters.EsAcumulado 
    } );    

    setEsVisibleJan(IsNumberBetween(1, props.filters.Seg1ClaMesFrom, props.filters.Seg1ClaMesTo));  
    setEsVisibleFeb(IsNumberBetween(2, props.filters.Seg1ClaMesFrom, props.filters.Seg1ClaMesTo)); 
    setEsVisibleMar(IsNumberBetween(3, props.filters.Seg1ClaMesFrom, props.filters.Seg1ClaMesTo)); 
    setEsVisibleApr(IsNumberBetween(4, props.filters.Seg1ClaMesFrom, props.filters.Seg1ClaMesTo));  
    setEsVisibleMay(IsNumberBetween(5, props.filters.Seg1ClaMesFrom, props.filters.Seg1ClaMesTo));  
    setEsVisibleJun(IsNumberBetween(6, props.filters.Seg1ClaMesFrom, props.filters.Seg1ClaMesTo));  
    setEsVisibleJul(IsNumberBetween(7, props.filters.Seg1ClaMesFrom, props.filters.Seg1ClaMesTo));  
    setEsVisibleAug(IsNumberBetween(8, props.filters.Seg1ClaMesFrom, props.filters.Seg1ClaMesTo));  
    setEsVisibleSep(IsNumberBetween(9, props.filters.Seg1ClaMesFrom, props.filters.Seg1ClaMesTo));  
    setEsVisibleOct(IsNumberBetween(10, props.filters.Seg1ClaMesFrom, props.filters.Seg1ClaMesTo)); 
    setEsVisibleNov(IsNumberBetween(11, props.filters.Seg1ClaMesFrom, props.filters.Seg1ClaMesTo));  
    setEsVisibleDec(IsNumberBetween(12, props.filters.Seg1ClaMesFrom, props.filters.Seg1ClaMesTo));  
    setEsVisibleJan2(IsNumberBetween(1, props.filters.Seg2ClaMesFrom, props.filters.Seg2ClaMesTo));  
    setEsVisibleFeb2(IsNumberBetween(2, props.filters.Seg2ClaMesFrom, props.filters.Seg2ClaMesTo));  
    setEsVisibleMar2(IsNumberBetween(3, props.filters.Seg2ClaMesFrom, props.filters.Seg2ClaMesTo));  
    setEsVisibleApr2(IsNumberBetween(4, props.filters.Seg2ClaMesFrom, props.filters.Seg2ClaMesTo));  
    setEsVisibleMay2(IsNumberBetween(5, props.filters.Seg2ClaMesFrom, props.filters.Seg2ClaMesTo)); 
    setEsVisibleJun2(IsNumberBetween(6, props.filters.Seg2ClaMesFrom, props.filters.Seg2ClaMesTo));  
    setEsVisibleJul2(IsNumberBetween(7, props.filters.Seg2ClaMesFrom, props.filters.Seg2ClaMesTo));  
    setEsVisibleAug2(IsNumberBetween(8, props.filters.Seg2ClaMesFrom, props.filters.Seg2ClaMesTo)); 
    setEsVisibleSep2(IsNumberBetween(9, props.filters.Seg2ClaMesFrom, props.filters.Seg2ClaMesTo));  
    setEsVisibleOct2(IsNumberBetween(10, props.filters.Seg2ClaMesFrom, props.filters.Seg2ClaMesTo));  
    setEsVisibleNov2(IsNumberBetween(11, props.filters.Seg2ClaMesFrom, props.filters.Seg2ClaMesTo));  
    setEsVisibleDec2(IsNumberBetween(12, props.filters.Seg2ClaMesFrom, props.filters.Seg2ClaMesTo));

  }, [props.filtersData, props.Rows]);


  const IsNumberBetween = (x, min, max) => {
    return x >= min && x <= max;
  }

 
     
const ShowPrimerGroup = (row) =>  {            
    return (  
      <>    
        <a className='Cursor' onClick={onActionAbrirPrimerGroupAMostrar}>{Abrir5Year?'-':'+'}</a>           
      </>
     );
  }
  const ShowSecondGroup = (row) =>  {            
    return (  
      <>    
        <a className='Cursor' onClick={onActionAbrirSecondGroup}>{AbrirPrimerYear?'-':'+'}</a>          
      </>
     );
  }  

  const ShowTercerGroup = (row) =>  {            
    return (  
      <>    
        <a className='Cursor' onClick={onActionAbrirTercerGroup}>{AbrirSegundoYear?'-':'+'}</a>           
      </>
     );
  }
  const ShowCuartoGroup = (row) =>  {            
    return (  
      <>    
        <a className='Cursor' onClick={onActionAbrirCuartoGroup}>{AbrirAVGYear?'-':'+'}</a>           
      </>
     );
  }

  const onActionAbrirPrimerGroupAMostrar = () => {
    setAbrir5Year(!Abrir5Year);
  }
  const onActionAbrirSecondGroup = () => {
    setAbrirPrimerYear(!AbrirPrimerYear);
  }
  const onActionAbrirTercerGroup = () => {
    setAbrirSegundoYear(!AbrirSegundoYear);
  }
  const onActionAbrirCuartoGroup = () => {
    setAbrirAVGYear(!AbrirAVGYear);
  } 

  const emptyCellRender = (row) => {
    return (  
      <>     
        <div
          className='TreeListcell'
          style={
           { 
             color: row.data.fontColor === null || row.data.fontColor === undefined || row.data.fontColor === '' ? "" : row.data.fontColor,
             backgroundColor: row.data.backgroundColor === null ? "" : row.data.backgroundColor,
             fontWeight : row.data.fontColor === null || row.data.fontColor === undefined || row.data.fontColor === ''  ? "normal" : "bold"
           }
           }
        >  
          &nbsp;  
        </div>       
      </>
    );

  }
  const DescriptionRender = (row) => {   
     
     return (  
       <>     
         <div
           className='TreeListcell'
           style={
            { 
              color: row.data.fontColor === null || row.data.fontColor === undefined || row.data.fontColor === '' ? "" : row.data.fontColor,
              fontWeight : row.data.fontColor === null || row.data.fontColor === undefined || row.data.fontColor === ''  ? "normal" : "bold"
            }
            }
         >  
           { row.data.Descripcion}  
         </div>       
       </>
     );

  }

  const ForamtCell = (row, value) => {
    return (  
      <>     
        <div
          className='TreeListcell'
          style={
            {
              backgroundColor: row.data.backgroundColor === null ? "" : row.data.backgroundColor, 
              color: row.data.fontColor === null ? "" : row.data.fontColor,
              fontWeight : row.data.fontColor === null || row.data.fontColor === undefined || row.data.fontColor === ''  ? "normal" : "bold"
            }
            }
        > 
          
          {
            (row.data.NegativeNumbers === 1 || row.data.NegativeNumbers === true) ?
            (<span style={{color: value < 0 ? "red" : "black" }}>{value !== null? Highcharts.numberFormat(value, row.data.decimalPlaces === null ? 0 : row.data.decimalPlaces, '.', ','): (<>&nbsp;</>)} </span>)
            :
            (<>{value !== null? Highcharts.numberFormat(value, row.data.decimalPlaces === null || row.data.decimalPlaces === undefined  ? 0 : row.data.decimalPlaces, '.', ','): (<>&nbsp;</>)} </>)
          } 
       
        </div>       
      </>
     );
  }
   
  const JanRender = (row) => { 
      return ForamtCell(row, row.data.Jan);
  }

  const FebRender = (row) => { 
    return ForamtCell(row, row.data.Feb);
  }

  const MarRender = (row) => { 
    return ForamtCell(row, row.data.Mar);
  }

  const AprRender = (row) => { 
    return ForamtCell(row, row.data.Apr);
  }

  const MayRender = (row) => { 
    return ForamtCell(row, row.data.May);
  }

  const JunRender = (row) => { 
    return ForamtCell(row, row.data.Jun);
  }

  const JulRender = (row) => { 
    return ForamtCell(row, row.data.Jul);
  }

  const AugRender = (row) => { 
    return ForamtCell(row, row.data.Aug);
  }

  const SepRender = (row) => { 
    return ForamtCell(row, row.data.Sep);
  }

  const OctRender = (row) => { 
    return ForamtCell(row, row.data.Oct);
  }

  const NovRender = (row) => { 
    return ForamtCell(row, row.data.Nov);
  }

  const DecRender = (row) => { 
    return ForamtCell(row, row.data.Dec);
  }   

  const AnnualYear1Render = (row) => { 
    return ForamtCell(row, row.data.AnnualYear1);
  }  


  const Jan2Render = (row) => { 
    return ForamtCell(row, row.data.Jan2);
  }

  const Feb2Render = (row) => { 
    return ForamtCell(row, row.data.Feb2);
  }

  const Mar2Render = (row) => { 
    return ForamtCell(row, row.data.Mar2);
  }

  const Apr2Render = (row) => { 
    return ForamtCell(row, row.data.Apr2);
  }

  const May2Render = (row) => { 
    return ForamtCell(row, row.data.May2);
  }

  const Jun2Render = (row) => { 
    return ForamtCell(row, row.data.Jun2);
  }

  const Jul2Render = (row) => { 
    return ForamtCell(row, row.data.Jul2);
  }

  const Aug2Render = (row) => { 
    return ForamtCell(row, row.data.Aug2);
  }

  const Sep2Render = (row) => { 
    return ForamtCell(row, row.data.Sep2);
  }

  const Oct2Render = (row) => { 
    return ForamtCell(row, row.data.Oct2);
  }

  const Nov2Render = (row) => { 
    return ForamtCell(row, row.data.Nov2);
  }

  const Dec2Render = (row) => { 
    return ForamtCell(row, row.data.Dec2);
  }  
  
  const AnnualYear2Render = (row) => { 
    return ForamtCell(row, row.data.AnnualYear2);
  }  

  const Annual1YearsBeforeRender = (row) => { 
    return ForamtCell(row, row.data.Annual1YearsBefore);
  }  

  const Annual2YearsBeforeRender = (row) => { 
    return ForamtCell(row, row.data.Annual2YearsBefore);
  }  

  const Annual3YearsBeforeRender = (row) => { 
    return ForamtCell(row, row.data.Annual3YearsBefore);
  }  

  const Annual4YearsBeforeRender = (row) => { 
    return ForamtCell(row, row.data.Annual4YearsBefore);
  }  

  const Annual5YearsBeforeRender = (row) => { 
    return ForamtCell(row, row.data.Annual5YearsBefore);
  }  
  
  const Q1Render = (row) => { 
    return ForamtCell(row, row.data["1Q"]);
  }  
  
  const Q2Render = (row) => { 
    return ForamtCell(row, row.data["2Q"]);
  }  

  const Q3Render = (row) => { 
    return ForamtCell(row, row.data["3Q"]);
  }  

  const Q4Render = (row) => { 
    return ForamtCell(row, row.data["4Q"]);
  }  
  
  const AnnualAvgYear1Render = (row) => { 
    return ForamtCell(row, row.data.AnnualAvgYear1);
  }  

  return (
    <> 
      <TreeList
        id="matriz"
        dataSource={matriz}
        rootValue={-1}
        defaultExpandedRowKeys={expandedRowKeys}
        showRowLines={true}
        showBorders={false}
        columnAutoWidth={true}  
        keyExpr="Id"
        height="75vh"
        parentIdExpr="PadreId"
        noDataText={t('common:NoData')}
        className='TreeCorporateView'         
      >
        <Sorting mode="none"  /> 
        <Selection mode="single" />
        <Scrolling
          mode="standard"
        />
        <Paging
          enabled={true}
          defaultPageSize={150}
        />
        <Pager
          showPageSizeSelector={false}
          showInfo={false}
        />
        <Column 
          fixed={true} 
          dataField="Descripcion"
          caption={t('common:Description')}
          width={300}    
          className="TreeCorporateView-light-gray-column"    
          cellRender={DescriptionRender}    
        >  
        </Column>
        
        <Column 
          caption={`${ColumnasNombreDinamico.Seg1NomEscenarioInfo} - ${ColumnasNombreDinamico.Seg1NomMesFrom} to ${ColumnasNombreDinamico.Seg1NomMesTo} - ${ColumnasNombreDinamico.Seg1ClaAnio}`} 
          alignment="center" 
          visible={Abrir5Year}
          cssClass="expand-column-info-color"
        >
          <Column           
            dataField="Annual5YearsBefore" 
            caption={`${ColumnasNombreDinamico.Seg1ClaAnio-5}`}  
            width={110}
            visible={Abrir5Year} 
            alignment="center" 
            cssClass="expand-column-info-color TreeCorporateView-Total-Column"   
            cellRender={Annual5YearsBeforeRender}  
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
          <Column            
            dataField="Annual4YearsBefore"
            caption={`${ColumnasNombreDinamico.Seg1ClaAnio-4}`} 
            width={110}
            visible={Abrir5Year}
            alignment="center" 
            cssClass="expand-column-info-color TreeCorporateView-Total-Column"
            cellRender={Annual4YearsBeforeRender}
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
          <Column            
            dataField="Annual3YearsBefore"
            caption={`${ColumnasNombreDinamico.Seg1ClaAnio-3}`} 
            width={110}
            visible={Abrir5Year}
            alignment="center" 
            cssClass="expand-column-info-color TreeCorporateView-Total-Column"
            cellRender={Annual3YearsBeforeRender}
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
          <Column
            dataField="Annual2YearsBefore"
            caption={`${ColumnasNombreDinamico.Seg1ClaAnio-2}`} 
            width={110}
            visible={Abrir5Year}
            alignment="center" 
            cssClass="expand-column-info-color TreeCorporateView-Total-Column"
            cellRender={Annual2YearsBeforeRender}
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
          <Column
            dataField="Annual1YearsBefore"
            caption={`${ColumnasNombreDinamico.Seg1ClaAnio-1}`} 
            width={110}
            visible={Abrir5Year}
            alignment="center" 
            cssClass="expand-column-info-color "
            cellRender={Annual1YearsBeforeRender}
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
        </Column>
        <Column 
          width={15}    
          headerCellRender={ShowPrimerGroup}  
          cssClass="empty-column-space expand-column-info-color"
          cellRender={emptyCellRender} 
        >  
        </Column> 
        <Column  
          caption={`${ColumnasNombreDinamico.Seg1ClaAnio}`} 
          alignment="center" 
          visible={AbrirPrimerYear}
          cssClass="expand-column-secondary"  
        >
          <Column  
            dataField="Jan"
            caption="Jan"
            width={110}
            visible={AbrirPrimerYear && EsVisibleJan}
            alignment="center" 
            cssClass="expand-column-secondary"  
            cellRender={JanRender}
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
          <Column  
            dataField="Feb"
            caption="Feb"
            width={110}
            visible={AbrirPrimerYear && EsVisibleFeb}
            alignment="center" 
            cssClass="expand-column-secondary"    
            cellRender={FebRender}
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
          <Column  
            dataField="Mar"
            caption="Mar"
            width={110}
            visible={AbrirPrimerYear && EsVisibleMar}
            alignment="center" 
            cssClass="expand-column-secondary"   
            cellRender={MarRender}
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
          <Column  
            dataField="Apr"
            caption="Apr"
            width={110}
            visible={AbrirPrimerYear && EsVisibleApr}
            alignment="center" 
            cssClass="expand-column-secondary"  
            cellRender={AprRender}
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
          <Column  
            dataField="May"
            caption="May"
            width={110}
            visible={AbrirPrimerYear && EsVisibleMay}
            alignment="center" 
            cssClass="expand-column-secondary"  
            cellRender={MayRender}
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
          <Column  
            dataField="Jun"
            caption="Jun"
            width={110}
            visible={AbrirPrimerYear && EsVisibleJun}
            alignment="center" 
            cssClass="expand-column-secondary"  
            cellRender={JunRender}
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
          <Column  
            dataField="Jul"
            caption="Jul"
            width={110}
            visible={AbrirPrimerYear && EsVisibleJul}
            alignment="center" 
            cssClass="expand-column-secondary"  
            cellRender={JulRender}
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
          <Column  
            dataField="Aug"
            caption="Aug"
            width={110}
            visible={AbrirPrimerYear && EsVisibleAug}
            alignment="center" 
            cssClass="expand-column-secondary"  
            cellRender={AugRender}
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
          <Column  
            dataField="Sep"
            caption="Sep"
            width={110}
            visible={AbrirPrimerYear && EsVisibleSep}
            alignment="center" 
            cssClass="expand-column-secondary"  
            cellRender={SepRender}
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
          <Column  
            dataField="Oct"
            caption="Oct"
            width={110}
            visible={AbrirPrimerYear && EsVisibleOct}
            alignment="center" 
            cssClass="expand-column-secondary"   
            cellRender={OctRender}
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
          <Column  
            dataField="Nov"
            caption="Nov"
            width={110}
            visible={AbrirPrimerYear && EsVisibleNov}
            alignment="center" 
            cssClass="expand-column-secondary"  
            cellRender={NovRender}
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
          <Column  
            dataField="Dec"
            caption="Dec"
            width={110}
            visible={AbrirPrimerYear && EsVisibleDec}
            alignment="center" 
            cssClass="expand-column-secondary"   
            cellRender={DecRender}
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
        </Column>
        <Column 
          caption={`${ColumnasNombreDinamico.Seg1NomEscenarioInfo}`} 
          alignment="center" 
          cssClass="expand-column-secondary"  
        >
          <Column  
            dataField="AnnualYear1"
            caption={`${ColumnasNombreDinamico.Seg1ClaAnio}`} 
            width={110}
            alignment="center" 
            cssClass="expand-column-secondary TreeCorporateView-Total-Column"    
            cellRender={AnnualYear1Render}
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
        </Column>
        <Column 
          width={15}    
          headerCellRender={ShowSecondGroup}  
          cssClass="empty-column-space expand-column-secondary" 
          cellRender={emptyCellRender} 
        >  

        </Column> 
        <Column  
          dataField="Abs"
          caption="Abs"
          width={110} 
          cssClass="TreeCorporateView-light-gray-column TreeCorporateView-Total-Column" 
          alignment="center"   
        >
          <Format type="fixedPoint" precision={0} /> 
        </Column>
        <Column  
          dataField="Percentage"
          caption="%"
          width={110} 
          cssClass="TreeCorporateView-light-gray-column TreeCorporateView-Total-Column" 
          alignment="center" 
        >
          <Format type="percent" precision={1} /> 
        </Column>

        <Column 
          width={15}    
          headerCellRender={ShowTercerGroup}  
          cssClass="empty-column-space expand-column-light-gray" 
          cellRender={emptyCellRender} 
        >  
        </Column>
        <Column 
          caption={`${ColumnasNombreDinamico.Seg2ClaAnio}`} 
          alignment="center" 
          visible={AbrirSegundoYear}
          cssClass="expand-column-light-gray"  
        >
          <Column  
            dataField="Jan2"
            caption="Jan"
            width={110}
            visible={AbrirSegundoYear && EsVisibleJan2}
            alignment="center" 
            cssClass="expand-column-light-gray"  
            cellRender={Jan2Render}
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
          <Column  
            dataField="Feb2"
            caption="Feb"
            width={110}
            visible={AbrirSegundoYear && EsVisibleFeb2}
            alignment="center" 
            cssClass="expand-column-light-gray"  
            cellRender={Feb2Render}
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
          <Column  
            dataField="Mar2"
            caption="Mar"
            width={110}
            visible={AbrirSegundoYear && EsVisibleMar2}
            alignment="center" 
            cssClass="expand-column-light-gray"  
            cellRender={Mar2Render}
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
          <Column  
            dataField="Apr2"
            caption="Apr"
            width={110}
            visible={AbrirSegundoYear && EsVisibleApr2}
            alignment="center" 
            cssClass="expand-column-light-gray"  
            cellRender={Apr2Render}
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
          <Column  
            dataField="May2"
            caption="May"
            width={110}
            visible={AbrirSegundoYear && EsVisibleMay2}
            alignment="center" 
            cssClass="expand-column-light-gray"  
            cellRender={May2Render}
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
          <Column  
            dataField="Jun2"
            caption="Jun"
            width={110}
            visible={AbrirSegundoYear && EsVisibleJun2}
            alignment="center" 
            cssClass="expand-column-light-gray"   
            cellRender={Jun2Render}
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
          <Column  
            dataField="Jul2"
            caption="Jul"
            width={110}
            visible={AbrirSegundoYear && EsVisibleJul2}
            alignment="center" 
            cssClass="expand-column-light-gray" 
            cellRender={Jul2Render} 
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
          <Column  
            dataField="Aug2"
            caption="Aug"
            width={110}
            visible={AbrirSegundoYear && EsVisibleAug2}
            alignment="center" 
            cssClass="expand-column-light-gray"  
            cellRender={Aug2Render} 
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
          <Column  
            dataField="Sep2"
            caption="Sep"
            width={110}
            visible={AbrirSegundoYear && EsVisibleSep2}
            alignment="center" 
            cssClass="expand-column-light-gray"  
            cellRender={Sep2Render} 
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
          <Column  
            dataField="Oct2"
            caption="Oct"
            width={110}
            visible={AbrirSegundoYear && EsVisibleOct2}
            alignment="center" 
            cssClass="expand-column-light-gray"
            cellRender={Oct2Render}   
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
          <Column  
            dataField="Nov2"
            caption="Nov"
            width={110}
            visible={AbrirSegundoYear && EsVisibleNov2}
            alignment="center" 
            cssClass="expand-column-light-gray"  
            cellRender={Nov2Render}   
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
          <Column  
            dataField="Dec2"
            caption="Dec"
            width={110}
            visible={AbrirSegundoYear && EsVisibleDec2}
            alignment="center" 
            cssClass="expand-column-light-gray"  
            cellRender={Dec2Render}   
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column> 
        </Column>
        <Column 
          caption={`${ColumnasNombreDinamico.Seg2NomEscenarioInfo}`} 
          alignment="center" 
          cssClass="expand-column-light-gray"  
        >
          <Column  
            dataField="AnnualYear2"
            caption={`${ColumnasNombreDinamico.Seg2ClaAnio}`} 
            width={110}
            alignment="center" 
            cssClass="expand-column-light-gray TreeCorporateView-Total-Column"  
            cellRender={AnnualYear2Render}   
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
        </Column>
        <Column 
          caption={`${ColumnasNombreDinamico.Seg1ClaAnio}`}
          alignment="center" 
          visible={AbrirAVGYear}
          cssClass="expand-column-info-color"  
        >
          <Column  
            dataField="1Q"
            caption="1Q"
            width={110}
            visible={AbrirAVGYear}
            alignment="center" 
            cssClass="expand-column-info-color TreeCorporateView-Total-Column"  
            cellRender={Q1Render}
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
          <Column  
            dataField="2Q"
            caption="2Q"
            width={110}
            visible={AbrirAVGYear}
            alignment="center" 
            cssClass="expand-column-info-color TreeCorporateView-Total-Column" 
            cellRender={Q2Render}
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
          <Column  
            dataField="3Q"
            caption="3Q"
            width={110}
            visible={AbrirAVGYear}
            alignment="center" 
            cssClass="expand-column-info-color TreeCorporateView-Total-Column"  
            cellRender={Q3Render}
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>
          <Column  
            dataField="4Q"
            caption="4Q"
            width={110}
            visible={AbrirAVGYear}
            alignment="center" 
            cssClass="expand-column-info-color TreeCorporateView-Total-Column"
            cellRender={Q4Render}  
          >
            <Format type="fixedPoint" precision={0} /> 
          </Column>  
          <Column  
            dataField="AnnualAvgYear1"
            caption={ColumnasNombreDinamico.EsPromedio ? 'Avg' : 'Accum'}
            width={110}
            alignment="center" 
            cssClass="expand-column-info-color TreeCorporateView-Total-Column" 
            cellRender={AnnualAvgYear1Render} 
          >  
            <Format type="fixedPoint" precision={0} /> 
          </Column>
        </Column>
        <Column 
          width={15}    
          headerCellRender={ShowCuartoGroup}  
          cssClass="empty-column-space expand-column-info-color TreeCorporateView-Total-Column"   
          cellRender={emptyCellRender} 
        >  
        </Column>
      </TreeList>
    </>
  );
};
export default PLByBucketTreeCorporateView;

import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardHeader, CardBody, CardFooter, Label } from 'reactstrap';
import { callApi, showSweetAlert, formatDate } from "utils/utils";
import { config } from 'utils/config';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import ViewPODFilters from './ViewPODFilters';
import PDFViewer from 'components/Controls/PDFViewer/PDFViewer';
import ViewPODDetails from './ViewPODDetails';
import PODStatistics from './PODStatistics';

const ViewPODs = (props) => {
  const {t, i18n} = useTranslation(['operations', 'common']);

  const [pods, setPODs] = useState([]);
  const [selectedPod, setSelectedPod] = useState([]);
  const [showPodDetails, setShowPodDetails] = useState(false);
  const [podExtractedData, setPodExtractedData] = useState([]);
  const [prevFilters, setPrevFilters] = useState([]);
  const [pdfLink, setPDFLink] = useState(null);
  const [pages, setPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState(0);
  const [showNoPODsError, setShowNoPODsError] = useState(false);


  // useEffect(() => {
  //   const filters = {};
  //   getPODs(filters);
  // }, [])

  const getPODs = (filters) => {
    setPrevFilters(filters);

    let urlWebService = `${config.UrlApiProject}POD/GetPODs?`;

    if (filters.soNumber !== undefined && filters.soNumber !== null) {
      urlWebService = `${urlWebService}&SoNumber=${filters.soNumber}`;
    }
    if (filters.invoiceNumber !== undefined && filters.invoiceNumber !== null) {
      urlWebService = `${urlWebService}&NumFactura=${filters.invoiceNumber}`;
    }
    if (filters.claClienteCuenta !== undefined && filters.claClienteCuenta !== null) {
      urlWebService = `${urlWebService}&ClaClienteCuenta=${filters.claClienteCuenta}`;
    }
    if (filters.claTipoPOD !== undefined && filters.claTipoPOD !== null) {
      urlWebService = `${urlWebService}&ClaTipoPOD=${filters.claTipoPOD}`;
    }
    if (filters.company !== undefined && filters.company !== null) {
      urlWebService = `${urlWebService}&ClaEmpresa=${filters.company}`;
    }
    if (filters.releaseNumber !== undefined && filters.releaseNumber !== null) {
      urlWebService = `${urlWebService}&ReleaseNumber=${filters.releaseNumber}`;
    } 

    const paramsService = {};
    callApi(urlWebService, 'GET', paramsService, (response) => {
      setPODs(response.data);

      if (response.data.length === 0)
      {
        setShowNoPODsError(true);
        // showSweetAlert(t('pod:PODNotFound') , t('pod:NoPODsFoundUseManualSearch'), 'warning');
      }
      else {
        setShowNoPODsError(false);
      } 
    });
  }

  const downloadFile = (row) => { 
    const url = `${config.UrlApiProject}POD/DownloadPOD?IdPod=${row.IdPod}`;  
    const paramsService= {};  
    callApi(url, 'GET', paramsService, (response) => {
      const downloadFile = response.data.File;
      if(downloadFile !== undefined){
        const linkSource = `data:${response.data.FileExtension === 'pdf' || response.data.FileExtension === 'zip' ? 'application' : 'image'}/${response.data.FileExtension};base64,${downloadFile}`
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = `POD-${row.NumFactura}`;
        downloadLink.click();
      }
    });
 }

  const fechaFacturaColumn = (row) => {
    return row.FechaFactura !== null ? formatDate(row.FechaFactura) : '';
  }

  const onViewDetailsClick = (row) => {

    // // Pivot row
    // const values = [
    //   { Field: t('pod:Company'), Value: row.NombreEmpresa},
    //   { Field: t('pod:InvoiceNum'), Value: row.NumFactura},
    //   { Field: t('pod:SONum'), Value: row.SoNum },
    //   { Field: t('pod:InvoiceDate'), Value: formatDate(row.FechaFactura) },
    //   { Field: t('pod:Vendor'), Value: row.NomProveedor},
    //   // { Field: t('pod:BillToNum'), Value: row.BillToNum },
    //   { Field: t('pod:BillTo'), Value: `${row.BillToNum} - ${row.BillToName}` },
    //   // { Field: t('pod:ShipToNum'), Value: row.ShipToNum },
    //   { Field: t('pod:ShipTo'), Value: `${row.ShipToName}` }
    //   // { Field: 'Entry Date', Value: formatDate(row.EntryDate) },
    // ];
    // setSelectedPod(values);

    // const urlWebService = `${config.UrlApiProject}POD/GetExtractedPodData?IdPod=${row.IdPod}`;
    // const paramsService = {};
    // callApi(urlWebService, 'GET', paramsService, (response) => {
    //   setPodExtractedData(response.data);

    //   const urlWebService2 = `${config.UrlApiProject}POD/GetPODPages?IdPod=${row.IdPod}`;
    //   const paramsService2 = {};
    //   callApi(urlWebService2, 'GET', paramsService2, (response) => {
    //     const newPages = [];
    //     for (let i = 0; i < response.data.length; i++) {
    //       const newPage = response.data[i];
    //       newPage.PageNumber = i + 1;
    //       newPages.push(newPage);
    //     }

    //     if (newPages.length > 0) {
    //       setSelectedPage(newPages[0]);
    //       onSelectedPageChange({value: newPages[0]});
    //     }

    //     setPages(newPages);
    //   });
    // });

    setSelectedPod(row);
    setShowPodDetails(true);
  }

  const onViewDetailsHide = () => {
    setShowPodDetails(false);
    setSelectedPod([]);
  }

  const optionsColumn = (row) => {
    return (
      <>
        <FieldButton className='p-button-rounded p-button-success mr-1' icon='pi pi-list' tooltip={t('pod:ViewDetails')} onClick={() => onViewDetailsClick(row)} />
        <FieldButton className='p-button-rounded p-button-warning' icon='pi pi-download' tooltip={t('pod:DownloadAll')} onClick={() => downloadFile(row)} />
      </>

    )
  }

  const pageNumberTemplate = (row) => {
    return (
      <>
        {`Page ${row.PageNumber}`}
      </>
    )
  }

  const primaryPODTemplate = (row) => {
    return (
      <>
        {row.IsPrimary === 1 ? t('pod:MainPOD') : ''}
      </>
    )
  }

  const onSelectedPageChange = (e) => {
    const row = e.value;
    setSelectedPage(e.value);
    setPDFLink(`data:application/pdf;base64,${row.FilePOD}`)
  }

  // const onShowPODDetailsHide = () => {
  //   setSelectedPage(null);
  //   setPages([]);
  //   setPDFLink(null);
  //   setPodExtractedData([]);
  //   setShowPodDetails(false);
  // }

  return (
    <>
      <Row className='mt-4'>
        <Col>
          <ViewPODFilters onFiltersChange={getPODs} />
        </Col>
      </Row>
      <Card className='mt-4'>
        <CardBody>
          <Row>
            <Col>
              {showNoPODsError ?
                (
                  <div className='mb-2'>
                    <span className='text-warning'><i className='pi pi-exclamation-triangle mr-2'></i>{t('pod:NoPODsFoundUseManualSearch')}</span>
                  </div>
                ) : <></>}
              <FieldDataTable 
                value={pods} 
                header={t('pod:PODs')}  
                scrollable 
                className="p-datatable-striped"
              >  
                <Column field="NombreEmpresa" header={t('pod:Company')} sortable headerStyle={{ width: '60px' }}></Column>
                <Column field="NumFactura" header={t('pod:InvoiceNum')} sortable headerStyle={{ width: '60px' }}></Column>
                <Column field="SoNum" header={t('pod:SONum')} sortable headerStyle={{ width: '50px' }}></Column>
                {/* <Column field="ReleaseNumber" header={t('pod:ReleaseNum')} sortable headerStyle={{ width: '50px' }}></Column> */}
                <Column body={fechaFacturaColumn} header={t('pod:InvoiceDate')} sortable sortField='FechaFactura' headerStyle={{ width: '60px' }}></Column>
                {/* <Column field="NomProveedor" header={t('pod:Vendor')} sortable headerStyle={{ width: '120px' }}></Column> */}
                <Column field="BillToName" header={t('pod:BillTo')} sortable headerStyle={{ width: '170px' }}></Column> 
                <Column field="ShipToName" header={t('pod:ShipTo')} sortable headerStyle={{ width: '170px' }}></Column> 
                <Column body={optionsColumn} header={t('pod:Options')} headerStyle={{ width: '110px' }}></Column>
              </FieldDataTable>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Dialog
        visible={showPodDetails}
        onHide={onViewDetailsHide}
        header={t('pod:PODDetails')}
        className='p-grid p-fluid'
        maximized={true}
        resizable={false}
      >
        <ViewPODDetails pod={selectedPod} />
      
      </Dialog>
    </>
  )
}

export default ViewPODs;
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

// Props:
// - rawStep
const DAISeerThought = (props) => {
  const reprTypes = {    
    NONE: 0,    
    VALUE: 1,    
    ARRAY: 2,
    OBJECT: 3
  };
  const {t, i18n} = useTranslation(['daiSeer', 'common']);  
  const [thoughtRepr, setThoughtRepr] = useState('');
  const [thought, setThought] = useState('');
  const [repr, setRepr] = useState(null);
  const [reprType, setReprType] = useState(reprTypes.NONE);  
    
  const parseStep = (rawStep) => {
    try {
      console.group("Parsing step");      
      console.info("Raw step:", rawStep);        
      if (rawStep.length === 2) {
        const _thoughtRepr = rawStep[0].repr;        
        setThoughtRepr(_thoughtRepr)        
        parseThought(_thoughtRepr);
        parseRepr(rawStep[1]);
      }                   
      console.groupEnd();
    } catch(exception) {
      console.error("Error parsing step.");      
    } finally {
      console.groupEnd();
    }     
  };

  const parseThought = (thoughtRepr) => {        
    console.group("Parsing thought");  
    console.info("Thought repr:", thoughtRepr);
    let thought = thoughtRepr.substring(
      thoughtRepr.indexOf('log=')+5, 
      thoughtRepr.lastIndexOf("Action:") - 2);
      if (thought.indexOf('Thought: ') !==  -1) {
        thought = thought.substring(
          thought.indexOf('Thought: ') + 9, 
          thought.length);  
      }      
    console.info("Thought:", thought);  
    setThought(thought);
    console.groupEnd();    
  };

  const parseRepr = (rawRepr) => {        
    console.group("Parsing repr");  
    console.info("Raw repr:", rawRepr);
    let _reprType = null;
    let _repr = null;
    if (Array.isArray(rawRepr)) {
      console.log("Repr type is an Array");  
      _reprType = reprTypes.ARRAY
      _repr = rawRepr;
    } else if (typeof rawRepr === "object") {
      console.log("Repr type is an Object"); 
      _reprType = reprTypes.OBJECT
      _repr = rawRepr;
    } else {
      console.log("Repr type is a Value"); 
      _reprType = reprTypes.VALUE
      _repr = rawRepr;
    }
    setRepr(_repr);
    setReprType(_reprType);
    console.info("Repr type:", _reprType);  
    console.info("Repr:", _repr);  
    console.groupEnd();  
  };

  const valueReprTemplate = (
    <>
      <b>
        {repr}        
      </b>
    </>
  );
  
  const objectReprTemplate = (
    <>
      {
        repr && (
          <b style={{whiteSpace:'pre-line'}}>
            {repr.repr}
          </b>
        )
      }
    </>
  );

  const arrayReprTemplate = (
    <>
      {
        repr && Array.isArray(repr) && (
          <ul>
            {
              repr.map((item) => {
                return (
                  <>
                    <li>
                      <b>
                        {item}
                      </b>
                    </li>
                  </>
                )
              })
            }
          </ul> 
        )
      }    
    </>
  );

  const reprTemplate = (
    <>
      {reprType === reprTypes.VALUE && valueReprTemplate}
      {reprType === reprTypes.OBJECT && objectReprTemplate}      
      {reprType === reprTypes.ARRAY && arrayReprTemplate}      
    </>
  );

  const thoughtTemplate = (
    <>
      <Row>
        <Col style={{whiteSpace:'pre-line'}}>
          <p>
            <i className='fa fa-brain' />&nbsp;
            <b>
              {thought}
            </b>
          </p>
          <p>
            {thoughtRepr}
          </p>                  
        </Col>
      </Row>  
      <Row>
        <Col>
          {reprTemplate}
        </Col>
      </Row>
    </>
  );

  useEffect(() => {
    parseStep(props.rawStep);
  }, [props.rawStep]);

  return (
    <>
      {thoughtTemplate}
    </>
  )
}

export default DAISeerThought
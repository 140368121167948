/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useState, useEffect } from 'react';   
import { config } from '../../utils/config';
import { callApi } from '../../utils/utils';
import '../../assets/css/PivotGridBase.css';   
 
import DataGrid, {
    Column, 
    ColumnFixing,  
    StateStoring,
    Paging
  } from 'devextreme-react/data-grid'; 
import { useTranslation } from 'react-i18next'; 
import { Row, Col } from 'reactstrap'; 
import FieldDropdown from '../../components/Controls/FieldDropdown.jsx' 
import IconButton from '@material-ui/core/IconButton'; 
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import FieldSelectButton from '../../components/Controls/FieldSelectButton.jsx' 

const PocketInconsistenciesSummary =  (props) => { 
    
    const { t } = useTranslation(['formulario','common']);  

    const [Anio, setAnio] = useState(props.Anio !== null ? props.Anio : null); 
    const [OpcionesAnios, setOpcionesAnios] = useState([]);
    const [Mes, setMes] = useState(props.AnioMes !== null ? props.AnioMes : null);
    const [OpcionesMeses, setOpcionesMeses] = useState([]);
    const [RowsData, setRowsData] = useState(props.dataSource === null ? [] : props.dataSource);
    const [Division, setDivision] = useState(props.ClaGerenciaRegional !== null ? props.ClaGerenciaRegional : null);
    const [OpcionesDivision, setOpcionesDivision] = useState([]); 
    const [EsMostrarSoloConfiables, setEsMostrarSoloConfiables] = useState(props.filters.EsMostrarSoloConfiables !== undefined ? props.filters.EsMostrarSoloConfiables : 0);
    
    function onCellPrepared(e) { 
        if(e.rowType === "data" && e.data.ClaGerenciaRegional !== null && e.data.ClaGrupoEstadistico3 === null) {
          e.cellElement.style.backgroundColor  = "#C0C6CF";
          e.cellElement.style.color = "#777";
          e.cellElement.style.fontWeight = "bold";
        }
        if(e.rowType === "data" && e.data.ClaMarketSegment !== null && e.data.ClaGrupoEstadistico3 === null) {
          e.cellElement.style.backgroundColor  = "#EDEDED"; 
          e.cellElement.style.color = "#777"; 
        }
        if(e.rowType === "data" && e.data.ClaGrupoEstadistico3 !== null) {
          e.cellElement.style.backgroundColor  = "#FFFFFF";
          e.cellElement.style.color = "#888888";
        }
      } 
    
      useEffect(() => {  
        let urlWebService = `${config.UrlApiProject}Rentabilidad/GetAnios`;  
        let paramsService = null; 
        callApi(urlWebService, 'GET', paramsService, (response) => {
          setOpcionesAnios (response.data.Anios);
          paramsService = {Anio : Anio === null ? response.data.Anios[0].ClaAnio : Anio };
          if (response.data.Anios.length > 0 && Anio == null){
            setAnio(response.data.Anios[0].ClaAnio);
            paramsService.Anio = response.data.Anios[0].ClaAnio;
          } 
            urlWebService = `${config.UrlApiProject}Rentabilidad/GetMeses`; 
            callApi(urlWebService, 'POST', paramsService, (response) => {
              setOpcionesMeses(response.data.Meses); 
              
              if (Mes !== null){
                urlWebService = `${config.UrlApiProject}Rentabilidad/GetDivisions`;  
                paramsService = {AnioMes:Mes};
                callApi(urlWebService, 'POST', paramsService, (response) => {
                  setOpcionesDivision (response.data.Divisiones);   
 
                  if (typeof(Division) !== "undefined" && Division !== null){ 
                    refreshInconsitencies();
                  }
                }); 
              }

            });    
        });  
    }, [props.ClaGerenciaRegional]); 


    const onMonthChange = (anioMes) => {   
      let paramsService = null; 
      let urlWebService = ``;  
      paramsService = {AnioMes:anioMes};
      urlWebService = `${config.UrlApiProject}Rentabilidad/GetDivisions`;  
      callApi(urlWebService, 'POST', paramsService, (response) => {  
        setMes(anioMes);  
        setOpcionesDivision (response.data.Divisiones); 
        setDivision(null); 
        setRowsData([]);  

        const filters = {
          Anio,
          AnioMes: anioMes
        }
        if (props.onSelectionChange !== null){
          props.onSelectionChange(filters); 
        }
      });    
      
    }; 

    const onYearChange = (yearId) => {   
        let paramsService = null; 
        let urlWebService = ``;  
        paramsService = {Anio:yearId};
        urlWebService = `${config.UrlApiProject}Rentabilidad/GetMeses`;  
        // Meses
        callApi(urlWebService, 'POST', paramsService, (response) => {
          setAnio(yearId);
          
          setMes(null); 
          setOpcionesMeses(response.data.Meses);  
          setOpcionesDivision([]);  
          setRowsData([]);  
          
          const filters = {
            Anio: yearId
          }
          if (props.onSelectionChange !== null){
            props.onSelectionChange(filters); 
          }
        });  
    };

    const onDivisionChange = (divisionId) => {    
      const filters = {
          ClaGerenciaRegional: divisionId, 
          AnioMes : Mes,
          Anio
      }
      if (props.onSelectionChange !== null){
        props.onSelectionChange(filters); 
      }
      setDivision(divisionId);
      
      // refreshInconsitencies (divisionId) ;
    };


    const refreshInconsitencies = (divisionId) => {  
      const urlWebService = `${config.UrlApiProject}Rentabilidad/GetDetalleInconsistenciesPM`;

      const paramsService = {
        Anio,
        AnioMes: Mes,
        ClaGerenciaRegional: Division === null ? divisionId : Division,
        EsMostrarSoloConfiables
      };
 
      callApi(urlWebService, 'POST', paramsService, (response) => {
        setRowsData(response.data.RdSDetalleInconsistenciesPM);  
      }); 
    };   

    const descriptionColumn = (row) =>  {   
      
      if (row.data.ClaGrupoEstadistico3 === null){
        return <>{row.data.Description}</>
      }

      return (  
        <>{row.data.Description}<IconButton title={t('common:GoToDetail')} size="small" onClick={(e) => onClickDetailProduct(row.data)}><OpenInNewIcon /></IconButton></>
       );
    } 

    const onClickDetailProduct = (row) => { 
      if (props.onClickDetailProduct !== null){
        props.onClickDetailProduct(row);
      } 
    }

    return (   
      <>      
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>  
            <div className="p-fluid p-grid">
              <div className="p-field p-col-12 p-md-3"> 
                <FieldDropdown  
                  value={Anio} 
                  options={OpcionesAnios}  
                  optionValue="ClaAnio"
                  optionLabel="NomAnio" 
                  onChange={(e) => onYearChange(e.value)}
                  label={t('common:Year')}
                />   
              </div>
              <div className="p-field p-col-12 p-md-3"> 
                <FieldDropdown  
                  value={Mes} 
                  options={OpcionesMeses}  
                  optionValue="AnioMes"
                  optionLabel="NomMes" 
                  onChange={(e) => onMonthChange(e.value)}
                  label={t('common:Month')}
                />   
              </div>
              <div className="p-field p-col-12 p-md-3"> 
                <FieldDropdown  
                  value={Division} 
                  options={OpcionesDivision} 
                  onChange={(e) => onDivisionChange(e.value)} 
                  optionValue="ClaGerenciaRegional"
                  optionLabel="NomGerenciaRegional"
                  label={t('common:Division')}
                />   
              </div> 
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>  
            <DataGrid 
              dataSource={RowsData} 
              allowColumnReordering={true}
              showBorders={true}
              height={470} 
              noDataText={t('common:NoData')}
              onCellPrepared={onCellPrepared} 
              sorting={false}
              defaultPageSize={100}
            >
              <Column
                caption={t('formulario:Description')}
                dataField="Description"
                width={230}
                fixed={true}  
                allowSorting={false}
                cellRender={descriptionColumn}
              /> 
              <Column
                caption={t('formulario:RawMaterial')}
                alignment="center"
              >
                <Column
                  caption={t('formulario:Std')}
                  dataField="RawMaterialStd" 
                  alignment="center"
                  width={65}
                  allowSorting={false}
                  dataType="number"
                  format="#,###"
                />
                <Column
                  caption={t('formulario:Real')}
                  dataField="RawMaterialReal" 
                  alignment="center"
                  width={65}
                  allowSorting={false}
                  dataType="number"
                  format="#,###"
                />

              </Column>
              <Column
                caption={t('formulario:ConvCost')}
                alignment="center"
              >
                <Column
                  caption={t('formulario:Std')}
                  dataField="ConvCostStd"
                  alignment="center"
                  dataType="number"
                  format="#,###"
                  width={65}
                />
                <Column
                  caption={t('formulario:Real')}
                  dataField="ConvCostReal"
                  alignment="center"
                  dataType="number"
                  format="#,###"
                  width={65}
                />
              </Column>
              <Column 
                caption={t('formulario:FreightInbound')}
                alignment="center"
              >
                <Column
                  caption={t('formulario:Std')}
                  dataField="FreightInboundStd"
                  alignment="center"
                  width={80}
                  dataType="number"
                  format="#,###"
                />
                <Column
                  caption={t('formulario:Real')}
                  dataField="FreightInboundReal"
                  alignment="center"
                  width={80}
                  dataType="number"
                  format="#,###"
                />
              </Column>

              <Column
                caption={t('formulario:FreightOutbound')}
                alignment="center"
              >
                <Column
                  caption={t('formulario:Std')}
                  dataField="FreightOutboundStd"
                  alignment="center"
                  width={80}
                  dataType="number"
                  format="#,###"
                />
                <Column
                  caption={t('formulario:Real')}
                  dataField="FreightOutboundReal"
                  alignment="center"
                  width={80}
                  dataType="number"
                  format="#,###"
                />
              </Column>
          
              <Column
                caption={t('formulario:SGAFinancials')}
                alignment="center"
              >
                <Column
                  caption={t('formulario:Std')}
                  dataField="SGFinancialStd" 
                  alignment="center"
                  width={80} 
                  allowSorting={false}
                  dataType="number"
                  format="#,###"
                />
                <Column
                  caption={t('formulario:Real')}
                  dataField="SGFinancialReal" 
                  alignment="center"
                  width={80}
                  allowSorting={false}
                  dataType="number"
                  format="#,###"
                />
              </Column> 
          
              <Column
                caption={t('formulario:SH')}
                alignment="center"
              >
                <Column
                  caption={t('formulario:Std')}
                  dataField="SHStd" 
                  alignment="center"
                  width={65}
                  allowSorting={false}
                  dataType="number"
                  format="#,###"
                />
                <Column
                  caption={t('formulario:Real')}
                  dataField="SHReal" 
                  alignment="center"
                  width={65}
                  allowSorting={false}
                  dataType="number"
                  format="#,###"
                />

              </Column>
              <Column
                caption={t('formulario:Price')}
                alignment="center"
              >
                <Column
                  caption={t('formulario:Std')}
                  dataField="PriceStd" 
                  alignment="center"
                  width={65}
                  allowSorting={false}
                  dataType="number"
                  format="#,###"
                />
                <Column
                  caption={t('formulario:Real')}
                  dataField="PriceReal" 
                  alignment="center"
                  width={65}
                  allowSorting={false}
                  dataType="number"
                  format="#,###"
                />

              </Column>
              <Column
                caption={t('formulario:PM')}
                dataField="PMPrice"
                alignment="center"
                width={80}
              /> 
              <Column
                caption={t('formulario:PMPorc')}
                dataField="PMPorc"
                alignment="center"
                width={80}
              />
              <ColumnFixing enabled={true} /> 
              <StateStoring enabled={true} type="localStorage" storageKey="gInconsistenciesSummary" />    
          
              <Paging defaultPageSize={150} />
            </DataGrid>
          </Col>
        </Row>
      </> 
    )
    }   
     
        
    export default PocketInconsistenciesSummary; 

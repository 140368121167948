/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';  
import { Row, Col , 
    ListGroupItem,
    ListGroup,
    Progress,} from 'reactstrap';  
import HomeMenuCard from '../../../components/Cards/HomeMenuCard/HomeMenuCard.jsx'; 
import PoliciesMenuImg from '../../../assets/img/policies_procedure_blue.png';
import ListItem from '../../../components/ListGroup/ListItem.jsx';
import FieldTag from '../../../components/Controls/FieldTag.jsx';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton';

import { config } from '../../../utils/config';
import { callApi, showSweetAlert } from '../../../utils/utils';  
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import moment from 'moment';  
import PDFViewer from 'components/Controls/PDFViewer/PDFViewer';
import VideoPlayer from 'components/Controls/VideoPlayer/VideoPlayer.jsx';  
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';
import { useHistory, Link } from "react-router-dom";
import FileLibraryFileByUserList from 'views/DMS/Library/FileLibraryFileByUserList.jsx';


const HrDocumentsMenu = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);
   
  const [LibraryFileByUserList, setLibraryFileByUserList] = useState([]); 
  const [LibraryAdmonFileByUserList, setLibraryAdmonFileByUserList] = useState([]); 
  const [showVisorPDF, setShowVisorPDF] = useState(false);
  const [file, setFile] = useState(null);
  const VideoPlayerViewer  = useRef();     
  const [EsShowDialog, setEsShowDialog] = useState(false);
  const [fileExtension, setfileExtension] = useState('');
  const hrLibraryLink  = useRef(null);
  const hrLibraryAdministrationLink  = useRef(null);
  const [EsShowDialogFileLibrary, setEsShowDialogFileLibrary] = useState(false);
  const [filtersData, setfiltersData] = useState(0);
  const [titleFileLibrary, settitleFileLibrary] = useState('');
  const [ListAttributeAdmon, setListAttributeAdmon] = useState([]);
  const [ListAttributeRiskAndIC, setListAttributeRiskAndIC] = useState([]);
  const [LibraryRICFileByUserList, setLibraryRICFileByUserList] = useState([]);
  
  useEffect(() => { 
    loadFileLibraryByUserList(
      {
        ClaLibrary: 1,  /* ESTA LIBRERIAS SON MANTENIDAS POR DESARROLLO LA PRIMERA QUE CREAMOS FUE LA DE HR */ 
        AttributeList: []
      });  
      loadFileAdmonLibraryByUserList(
      {
        ClaLibrary: 4,  /* ESTA LIBRERIAS SON MANTENIDAS POR DESARROLLO LA PRIMERA QUE CREAMOS FUE LA DE HR */ 
        AttributeList: []
      });

      loadFileRICLibraryByUserList(
        {
          ClaLibrary: 5,  /* ESTA LIBRERIAS SON MANTENIDAS POR DESARROLLO LA PRIMERA QUE CREAMOS FUE LA DE HR */ 
          AttributeList: []
        });
  }, []);

  const onhrLibraryLinkClick = () => {
     settitleFileLibrary(t('hr:lnkHrLibrary'));
     setfiltersData({ClaLibrary:1});
     setEsShowDialogFileLibrary(true);
    // hrLibraryLink.current.click();
  }
  
  
  const onhrLibraryLinkRICClick = () => {
    settitleFileLibrary(t('hr:lnkHrRiskAndInternalControl'));
    setfiltersData({ClaLibrary:5});
    setEsShowDialogFileLibrary(true);
    // hrLibraryAdministrationLink.current.click();
  }

  const onhrLibraryLinkAdministrationClick = () => {
    settitleFileLibrary(t('hr:lnkHrLibraryAdministration'));
    setfiltersData({ClaLibrary:4});
    setEsShowDialogFileLibrary(true);
    // hrLibraryAdministrationLink.current.click();
  }

  const onLibraryLinkClick = (title, filters, listAttributes) => {
    settitleFileLibrary(title);
 
    const item =  listAttributes.filter((item) => item.NomLibraryAttributeOption === title)

    if (item.length >0 ){ 
      item[0].RespuestaAttribute = item[0].ClaLibraryAttributeOption; 
    }

    filters.AttributeList = item; 

    setfiltersData(filters);
    setEsShowDialogFileLibrary(true);
   // hrLibraryLink.current.click();
 }

  const loadFileLibraryByUserList = (filtersData) => {
    const GetFileLibraryByUserList = `${config.UrlApiProject}DMS/GetFileLibraryByUserList`;
    console.log(`filtersData:${filtersData}`);
    const paramsToService = {
      ClaLibrary:filtersData.ClaLibrary,
      LibraryAttributeListDTO: filtersData.AttributeList,
      ShowInHome: true
    };

    callApi(GetFileLibraryByUserList, 'POST', paramsToService, (response) => {
      setLibraryFileByUserList(response.data.LibraryFileByUser);      
    });
  };
  

  const loadFileRICLibraryByUserList = (filtersData) => {
    const GetFileLibraryByUserList = `${config.UrlApiProject}DMS/GetFileLibraryByUserList`;
     
    const paramsToService = {
      ClaLibrary:filtersData.ClaLibrary,
      LibraryAttributeListDTO: filtersData.AttributeList,
      ShowInHome: false
    };
    
    callApi(GetFileLibraryByUserList, 'POST', paramsToService, (response) => { 
      setLibraryRICFileByUserList(response.data.LibraryFileByUser); 
      setListAttributeRiskAndIC(response.data.ListAttibute); 
    });
  };

  const loadFileAdmonLibraryByUserList = (filtersData) => {
    const GetFileLibraryByUserList = `${config.UrlApiProject}DMS/GetFileLibraryByUserList`;
     
    const paramsToService = {
      ClaLibrary:filtersData.ClaLibrary,
      LibraryAttributeListDTO: filtersData.AttributeList,
      ShowInHome: false
    };
    
    callApi(GetFileLibraryByUserList, 'POST', paramsToService, (response) => { 
      setLibraryAdmonFileByUserList(response.data.LibraryFileByUser); 
      setListAttributeAdmon(response.data.ListAttibute); 
    });
  };
 
  const listCategoryElement = (item, index, listAttributes) => {
    const element = (
      <>  
        <div className='relative'> 
          <span className="Cursor" onClick={() => onLibraryLinkClick(item.Category, {ClaLibrary: item.ClaLibrary, Category: item.Category}, listAttributes)}>{item.Category}</span>&nbsp;  
           
          {
            item.IsNewFile ?
            (
              <FieldTag 
                label="New"
                className="small-tag light-green"
              />
            )
            :
            (<></>)
          }
        </div> 
    
      </>
    )
 

    return ( 
      <ListItem
        key={index}
        title={element}
        color="#9bdee3"
      >
      </ListItem>  
    );
  }

  const listItemElement = (item, index) => {

    const element = (
      <>  
        <div className='relative'> 
          <span className="Cursor" onClick={() => viewFile(item)}>{item.TitleFile}</span>&nbsp;  
          
          <Tooltip target="#infoFile" />
          
          {
            item.DescriptionFile !== null && item.DescriptionFile.length > 0
            ?
            (
              <i
                className="fas fa-info-circle new-option-choice info-color"
                id="infoFile"  
                data-pr-tooltip={item.DescriptionFile}
                tooltip={item.DescriptionFile}
              >
              </i>
)
            :
            (<></>)
          } 
          &nbsp; 
          {
            item.IsNewFile ?
            (
              <FieldTag 
                label="New"
                className="small-tag light-green"
              />
            )
            :
            (<></>)
          }
        </div> 
    
      </>
    )
 

    return ( 
      <ListItem
        key={index}
        title={element}
        color="#9bdee3"
        icon={(<><i className="pi pi-minus" style={{color: "#9bdee3"}}></i>&nbsp;</>)}
      >
      </ListItem>  
    );
  }
 
  /*
  const headerStrategicTons  = ( 
    <>  
      <div className='relative'> 
        <span style={{textTransform:'capitalize'}}>CELL PHONE POLICY FOR QUALIFIED PERSONNEL</span>&nbsp;  
        <i
          className="fas fa-info-circle new-option-choice info-color"
          tooltip={t('survey:QuestionRequiredHelp')}
        >
        </i>
         &nbsp;
        <FieldTag 
          label="New"
          className="small-tag light-green"
        />  
      </div> 
      
    </>
      )  
  */ 

  const renderHeader = () => {
    return (
      <div>
        <Row className="text-center">
          <Col lg={12} md={12} sm={12} xs={12} className="text-left">
            {t('dms:Viewer')}
          </Col>
        </Row>
      </div>
    );
  };

  const renderHeaderFileLibrary = () => {
    return (
      <div>
        <Row className="text-center">
          <Col lg={12} md={12} sm={12} xs={12} className="text-left">
            {titleFileLibrary}
          </Col>
        </Row>
      </div>
    );
  };

  const renderFooterFileLibrary = () => {
    return (
      <div>
        <Row className="text-center">
          <Col lg={12} md={12} sm={12} xs={12}>
            <FieldButton
              label={t('common:Close')}
              icon="fas fa-times"
              className="warning no-border p-mr-2 rounded-button"
              onClick={() => setEsShowDialogFileLibrary(false)}
            />
          </Col>
        </Row>
      </div>
    );
  };

  const viewFile = (rowData) => { 
    const GetFileData = `${config.UrlApiProject}DMS/GetLibraryFileById`;  
    // const GetFileData = `${config.UrlApiProject}DMS/GetFileFromServer`;  
    const paramsToService = { 
      IdLibraryFile:rowData.IdLibraryFile
    };   
    callApi(GetFileData, 'POST', paramsToService, (response) => {
      const AnexoDetalle = response.data.AnexoDetalle[0];
      const AnexoImg = response.data.ImgDownLoad;
        console.log(response);
      if(AnexoDetalle!==undefined){
        
        let linkSource = ''

        switch (AnexoDetalle.TypeExtension) {
          case 'mp4':
          case 'webm':
            linkSource = `data:video/${AnexoDetalle.TypeExtension};base64,${AnexoImg}`;
            setEsShowDialog(true); 
            break;
          case 'pdf':
              linkSource = `data:application/${AnexoDetalle.TypeExtension};base64,${AnexoImg}`;
              setShowVisorPDF(true); 
              break;
          case 'jpg':
          case 'gif':
          case 'jpeg':
          case 'jfif':
          case 'pjpeg':
          case 'png':
          case 'svg':
          case 'webp':
          case 'bmp':
          case 'ico':
          case 'cur':
                linkSource = `data:image/${AnexoDetalle.TypeExtension};base64,${AnexoImg}`;
                setEsShowDialog(true); 
                break;
          default:
            break;
        } 
        
        // SI NO FORMAMOS EL LINK QUE LO INTENTE DESCARGAR
        if (linkSource === ''){
          const linkSource = `data:application/${AnexoDetalle.TypeExtension};base64,${AnexoImg}`;
          const downloadLink = document.createElement("a");
          const fileName = `${AnexoDetalle.NomLibraryFile}`;
          downloadLink.href = linkSource;
          downloadLink.download = `${fileName}.${AnexoDetalle.TypeExtension}`;
          downloadLink.click();
        }
        else{  
        setFile(linkSource);
        setfileExtension(AnexoDetalle.TypeExtension);
        }

      }
    });  
  }

  const calculateUniqueCategories = (array) => {
    const uniqueValues = Array.from(new Set(array.map(obj => obj.Category))).map(Category => {
      return array.find(obj => obj.Category === Category);
    });

    return uniqueValues;

  }

    return (
      <> 
        <HomeMenuCard
          className='HomeMenuCard-Other' 
          title={t('hr:PoliciesProceduresMCSW')} 
          imageSrc={PoliciesMenuImg}
          color="#17A2B8"
          isImgHeader={true}
        >  
          <Row className='align-items-start p-fluid'>
            <Col lg={6} md={6} sm={12} xs={12}>   
              <h2>
                <span 
                  title={t('hr:lnkHrLibrary')} 
                  className="Hr-subtitle"
                  onClick={onhrLibraryLinkClick}
                >
                  {t('hr:lnkHrLibrary')}
                </span>
              </h2>
              <div className='pl-3'>
                {
                  LibraryFileByUserList.map((item, index) => {
                      return listItemElement(item, index);
                      }
                  )
                }
                <ListItem
                  key={2}
                  title={(<span className="Cursor" onClick={onhrLibraryLinkClick}>Other</span>)}
                  color="#9bdee3"
                  icon={(<i className="pi pi-angle-right" style={{color: "#9bdee3"}}></i>)}
                >
                </ListItem>
              </div>

              <br></br>
              { /* NEW LIBRARY */ }
              <h2>
                <span 
                  title={t('hr:lnkHrRiskAndInternalControl')} 
                  className="Hr-subtitle"
                  onClick={onhrLibraryLinkRICClick}
                >
                  {t('hr:lnkHrRiskAndInternalControl')}
                </span>
              </h2>
              <div className='pl-3'>
                {
                  LibraryRICFileByUserList.filter((item) => item.ShowInHome === true).map((item, index) => {
                          return listItemElement(item, index);
                          }
                      )
                }
                { /* Pintamos las diferentes categorias que obtenemos */ }
                {
                  calculateUniqueCategories(LibraryRICFileByUserList).map((item, index) => {
                          return listCategoryElement(item, index, ListAttributeRiskAndIC);
                          }
                      )
                }
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}> 
             
              <h2>
                <span 
                  title={t('hr:lnkHrLibraryAdministration')} 
                  className="Hr-subtitle"
                  onClick={onhrLibraryLinkAdministrationClick}
                >
                  {t('hr:lnkHrLibraryAdministration')}
                </span>
              </h2>
              <div className='pl-3'>
                {
                  LibraryAdmonFileByUserList.filter((item) => item.ShowInHome === true).map((item, index) => {
                          return listItemElement(item, index);
                          }
                      )
                }
                { /* Pintamos las diferentes categorias que obtenemos */ }
                {
                  calculateUniqueCategories(LibraryAdmonFileByUserList).map((item, index) => {
                          return listCategoryElement(item, index, ListAttributeAdmon);
                          }
                      )
                }
              </div>
            
            </Col>
          </Row>
        </HomeMenuCard>
        <PDFViewer
          file={file} 
          show={showVisorPDF}
          onHide={() => { setShowVisorPDF(false) }}
        > 
        </PDFViewer> 
        <Dialog
          visible={EsShowDialog}
          modal
          style={{ width: '100vw' }}
          footer=""
          header={renderHeader} 
          closable={true}
          className="dialog-header-background-blue"
          maximized={true}
          draggable={false}
          blockScroll={true}
          onHide={() => setEsShowDialog(false)}
        >

          {
              fileExtension === 'mp4'
              ||
              fileExtension === 'webm'
              ?
              (
                <Row className='h-100'>
                  <Col className='h-100 video-visor'>  
                    <VideoPlayer reference={VideoPlayerViewer} url={file} height="100%"></VideoPlayer>  
                  </Col>
                </Row>
              )
              :
              (<></>)
            }

          {
              fileExtension === 'jpg'   || fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'jfif' ||
              fileExtension === 'pjpeg' || fileExtension === 'png' || fileExtension === 'svg' || fileExtension === 'webp'  ||
              fileExtension === 'bmp'   || fileExtension === 'ico' || fileExtension === 'cur' 
              ?
              (
                <Row className='h-100'>
                  <Col className='h-100'>  
                    <img
                      src={file} 
                      width="100%"                   
                    />
                  </Col>
                </Row>
              )
              :
              (<></>)
            }  
        </Dialog>
        <Dialog
          visible={EsShowDialogFileLibrary} 
          style={{ width: '80vw' }}
          modal
          closable={false} 
          onHide={() => setEsShowDialogFileLibrary(false)}
          header={renderHeaderFileLibrary} 
          footer={renderFooterFileLibrary} 
        >
          <Row>
            <Col>
              <FileLibraryFileByUserList filtersData={filtersData}></FileLibraryFileByUserList>
            </Col>
          </Row>          
        </Dialog>
        <Link
          ref={hrLibraryLink}
          to={{   
              pathname: "FileLibraryByUserScreen", 
              search: `?ClaLibrary=1` 
              }}
        >
        </Link> 
        <Link
          ref={hrLibraryAdministrationLink}
          to={{   
              pathname: "FileLibraryByUserScreen", 
              search: `?ClaLibrary=4` 
              }}
        >
        </Link> 
      </>  
        ); 
};

export default HrDocumentsMenu;

import { React, UseState, UseEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PageContent from 'layouts/PageContent';
import VolumeArchitectureOverview from './VolumeArchitectureOverview';

const VolumeArchitectureOverviewScreen = (props) => {
  const {t, i18n} = useTranslation(['salesPerformance', 'common']);

  return (
    <PageContent title={t('salesPerformance:VolumeArchitectureOverviewYTD')}>
      <VolumeArchitectureOverview />
    </PageContent>
  )
}

export default VolumeArchitectureOverviewScreen;
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi, showSweetAlert } from '../../../utils/utils';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon';
import moment from 'moment';
import swal from '@sweetalert/with-react';
import PageContent from 'layouts/PageContent';
import { Toolbar } from 'primereact/toolbar';
import { ProgressBar } from 'primereact/progressbar';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldText from 'components/Controls/FieldText';
import Highcharts from 'highcharts'; 
import FieldNumber from '../../../components/Controls/FieldNumber.jsx';
import KPIFormulaSetUp from './KPIsFormulaSetUp/KPIFormulaSetUp.jsx';
import KPIManagementByPositionCompositionDialog from './KPIManagementByPositionCompositionDialog/KPIManagementByPositionCompositionDialog.jsx';
import { set } from 'date-fns';

const PuestoIndicadorObjetivos = (props) => {
  const { t } = useTranslation(['position', 'common']);
  const [PuestoIndicadorList, setPuestoIndicadorList] = useState([]);
  const [PuestoIndicadorDetList, setIndicadorPuestoDetalleList] = useState([]);
  const [nomIndicadorSelected, setNomIndicadorSelected] = useState(null);
  const [descripcionIndicadorSelected, setDescripcionIndicadorSelected] = useState(null);
  const [ClaPuesto, setClaPuesto] = useState(props.ClaPuesto === undefined ? 0 : props.ClaPuesto);
  const [ClaAnio, setClaAnio] = useState(props.ClaAnio === undefined ? 0 : props.ClaAnio);
  const [ClaDepartamento, setClaDepartamento] = useState(null);
  const [ClaArea, setClaArea] = useState(null);
  const [ClaTipoIndicador, setClaTipoIndicador] = useState(null);
  const [ClaIndicador, setClaIndicador] = useState(null);
  const [ClaPeriodo, setClaPeriodo] = useState(null);
  const [SelectedPuestoIndicador, setSelectedPuestoIndicador] = useState(null); 
  const [OpcionesMeses, setOpcionesMeses] = useState([]);
  const [UlitmoClaMesDisponible, setUlitmoClaMesDisponible] = useState(null);
  const [formulaIndicadorSelected, setformulaIndicadorSelected] = useState(null);
  const [esMostrarCfgFormula, setEsMostrarCfgFormula] = useState(false);
  const [IdKPIFormulaSelected, setIdKPIFormulaSelected] = useState(null);
  const [KPIDescriptionSelected, setKPIDescriptionSelected] = useState('');

  const [IsKPIManagementDialogVisible, setIsKPIManagementDialogVisible] = useState(false);

  useEffect(() => {
    setSelectedPuestoIndicador(null);
    
    ObtenerIndicadorPuesto(
      props.ClaAnio === undefined ? null : props.ClaAnio,
      props.ClaPuesto === undefined ? 0 : props.ClaPuesto
    );
    onDetailPuesto({
      NomIndicador: null,
      NomCSF: null,
      ClaDepartamento: null,
      ClaArea: null,
      ClaTipoIndicador: null,
      ClaIndicador: null,
      ClaPuesto: null,
      ClaPeriodo: null,
    });
    setClaPuesto(props.ClaPuesto === undefined ? 0 : props.ClaPuesto);
    setClaAnio(props.ClaAnio === undefined ? null : props.ClaAnio);
    getMeses(props.ClaAnio === undefined ? null : props.ClaAnio);
  }, [props.ClaPuesto, props.ClaAnio, props.refresh]);

  const ObtenerIndicadorPuesto = (ClaAnio, ClaPuesto) => {
    const GetIndicadorPuesto = `${config.UrlApiProject}ScoreCard/GetIndicadorPuestoObjetivo`;
    const paramsToService = {
      ClaAnio,
      ClaPuesto,
    };
    callApi(GetIndicadorPuesto, 'POST', paramsToService, (response) => {
      setPuestoIndicadorList(response.data.PuestoIndicador);
    });
  };

  const getMeses = (ClaAnio) => {
    
    const paramsService = {
      ClaAnio, 
      MostrarSoloCerrados : 0
    }; 
      
    const GetMesesScoreCard = `${config.UrlApiProject}ScoreCard/GetMesesScoreCard`; 
        
    callApi(GetMesesScoreCard, 'Post', paramsService, (response) => {
        setOpcionesMeses(response.data.Meses); 
        setUlitmoClaMesDisponible(getUltimoDisponible(response.data.Meses)); 
    });   
   }

   const getUltimoDisponible = (meses) => {
    let ClaMes = 0;

    for (let index = 0; index < meses.length; index++) {
      if (meses[index].EsMesDisponible === 1) { 
        ClaMes =  meses[index].ClaMes;
      }
    }

    return ClaMes;
  }

  const onRowSelect = (event) => {
    onDetailPuesto(event.data);
  };
  const onDetailPuesto = (row) => {
    // Invocamos el metodo para llenar el grid de detalle.
    const detailRow = row;
    setNomIndicadorSelected(row.NomIndicador);
    setformulaIndicadorSelected(row.FormulaIndicador);
    setDescripcionIndicadorSelected(row.NomCSF);

    setClaDepartamento(row.ClaDepartamento);
    setClaArea(row.ClaArea);
    setClaTipoIndicador(row.ClaTipoIndicador);
    setClaIndicador(row.ClaIndicador);
    setClaPuesto(row.ClaPuesto);
    setClaPeriodo(row.ClaPeriodo);

    const GetIndicadorPuesto = `${config.UrlApiProject}ScoreCard/GetIndicadorPuestoDetalle`;
    const paramsToService = {
      ClaDepartamento: row.ClaDepartamento,
      ClaArea: row.ClaArea,
      ClaTipoIndicador: row.ClaTipoIndicador,
      ClaIndicador: row.ClaIndicador,
      ClaPuesto: row.ClaPuesto,
      ClaAnio,
    };
    callApi(GetIndicadorPuesto, 'POST', paramsToService, (response) => {
      setIndicadorPuestoDetalleList(response.data.IndicadorPuestoDetalle);
    });
  };

  const headerPuestoIndicadorDet = <>{nomIndicadorSelected} {t('position:ByPeriod')}</>;

  const headerPuestoIndicador = <>{t('position:headerPuestoIndicador')}</>;
   

  const onSaveObjetivo = () => {
    const arrayPuestoIndicador = [];
    let RowValues = {};

    for (let index = 0; index < PuestoIndicadorDetList.length; index++) {

      if (PuestoIndicadorDetList[index].IsRowEdited){ 
        RowValues = {
          ClaDepartamento: PuestoIndicadorDetList[index].ClaDepartamento,
          ClaArea: PuestoIndicadorDetList[index].ClaArea,
          ClaTipoIndicador: PuestoIndicadorDetList[index].ClaTipoIndicador,
          ClaIndicador: PuestoIndicadorDetList[index].ClaIndicador,
          ClaPuesto: PuestoIndicadorDetList[index].ClaPuesto,
          ClaPeriodo: PuestoIndicadorDetList[index].ClaPeriodo,
          IdCSF: PuestoIndicadorDetList[index].IdCSF,
          PeriodoReportado: PuestoIndicadorDetList[index].PeriodoReportado,
          EstatusPeriodo: PuestoIndicadorDetList[index].EstatusPeriodo,
          Minimo: PuestoIndicadorDetList[index].Minimo,
          Satisfactorio: PuestoIndicadorDetList[index].Satisfactorio,
          SobreSaliente: PuestoIndicadorDetList[index].SobreSaliente,
          BajaLogica: PuestoIndicadorDetList[index].BajaLogica
        };
        arrayPuestoIndicador.push(RowValues); 
      }
    }

    const paramsServiceOwners = {
      EstatusPeriodoList: arrayPuestoIndicador,
    };

    const urlWebServiceSaveIndicadorPuestoDetalle = `${config.UrlApiProject}ScoreCard/SaveIndicadorPuestoDetalle`;

    callApi(urlWebServiceSaveIndicadorPuestoDetalle, 'POST', paramsServiceOwners, (response) => {
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      onDetailPuesto(SelectedPuestoIndicador);

      if (props.onChange !== null && props.onChange !== 'undefined' && typeof(props.onChange) === 'function'){
        props.onChange();
      } 
    });

    // setMostrarEstatusMes(!MostrarEstatusMes);
  };

  
  const onSyncObjetives = () => {
    
    const paramsToService = {
      ...SelectedPuestoIndicador
      ,ClaAnio
    };

    console.log(paramsToService);

    const SincronizarObjetivosIndicador = `${config.UrlApiProject}ScoreCard/SincronizarObjetivosIndicador`;
    callApi(SincronizarObjetivosIndicador, 'POST', paramsToService, (response) => {
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      onDetailPuesto(SelectedPuestoIndicador);
    });
     
    // setMostrarEstatusMes(!MostrarEstatusMes);
  };

  const confirmacionSincronizacionObjetivos = () => {
    swal({ 
      buttons: {
        cancelar: {
          text: t('common:Cancel'),
          value: "cancel",
          className: "rounded-button danger"
        },
        continuar: {
          text: t('common:Continue'),
          value: "continue",
          className: "rounded-button light-green"
        }
      },
      content: confirmationMessageHTML
    }).then((value) => {
      switch (value) { 
        case "continue":
          onSyncObjetives();
          break; 
        default:
          break;
      }
    });
  }

  const confirmationMessageHTML = (
    <div>
      <SweatAlertCustomIcon title={t('position:confirmacionSincronizar')} letter={t('common:QuestionMark')}></SweatAlertCustomIcon> 
      <div>{t('position:confirmacionSincronizarDet')}</div> 
    </div>  
   ) 

   const BotonesTemplate = (rowData, e) =>  {   
    return (
      <>
        <FieldButton
          icon="pi pi-trash" 
          onClick={() => onremovePuestoIndicadorDet(rowData,props)} 
          className="p-button-rounded p-button-warning"
        />
      </>
    );
  }  

  const onremovePuestoIndicadorDet = (row, props) => {
    swal({ 
      buttons: {
        cancelar: {
          text: t('common:Cancel'),
          value: "cancel",
          className: "rounded-button danger"
        },
        continuar: {
          text: t('common:Continue'),
          value: "continue",
          className: "rounded-button light-green"
        }
      },
      content: confirmationRemovePuestoIndicadorDetHTML
    }).then((value) => {
      switch (value) { 
        case "continue":
          onRemovePuestoIndicadorDet(row, props);
          break; 
        default:
          break;
      }
    });
  }

  const confirmationRemovePuestoIndicadorDetHTML = (
    <div>
      <SweatAlertCustomIcon title={t('common:SubmitConfirmation')} letter={t('common:QuestionMark')}></SweatAlertCustomIcon> 
      <div>{t('position:confirmacionRemovePeriodo')}.</div> 
    </div>  
   ) 
  
  const onRemovePuestoIndicadorDet = (row, props) => {
     
    const arrayPuestoIndicador = []; 
    row.BajaLogica = 1;

    arrayPuestoIndicador.push(row);  
     

    const paramsServiceOwners = {
      EstatusPeriodoList: arrayPuestoIndicador,
    };

    const urlWebServiceSaveIndicadorPuestoDetalle = `${config.UrlApiProject}ScoreCard/SaveIndicadorPuestoDetalle`;

    callApi(urlWebServiceSaveIndicadorPuestoDetalle, 'POST', paramsServiceOwners, (response) => {
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      onDetailPuesto(SelectedPuestoIndicador);
    });
    
  };

  const showActiveRows = (rows) => {
    const activeRows = [];
    if (rows.length > 0) {
      rows.forEach((item, index) => {
        if (item.BajaLogica === 0 || item.BajaLogica === undefined) {
          item.BajaLogica = 0;
          item.RowIndex = index;
          activeRows.push({ ...item });
        }
      });
    } else {
      return [];
    }
    return activeRows;
  };

  const onOutStandingChange = (props, value) => {
    const updatedOutStanding = [...PuestoIndicadorDetList];
    updatedOutStanding[props.rowData.RowIndex][props.field] = value;
    updatedOutStanding[props.rowData.RowIndex].IsRowEdited = true;
    setIndicadorPuestoDetalleList(updatedOutStanding);
  };

  const onSatisfactorioChange = (props, value) => {
    const updatedSatisfactorio = [...PuestoIndicadorDetList];
    updatedSatisfactorio[props.rowData.RowIndex][props.field] = value;
    updatedSatisfactorio[props.rowData.RowIndex].IsRowEdited = true;
    setIndicadorPuestoDetalleList(updatedSatisfactorio);
  };

  const onMinimoChange = (props, value) => {
    const updatedMinimo = [...PuestoIndicadorDetList];
    updatedMinimo[props.rowData.RowIndex][props.field] = value;
    updatedMinimo[props.rowData.RowIndex].IsRowEdited = true;
    setIndicadorPuestoDetalleList(updatedMinimo);
  };

  const onEstatusPeriodoChange = (props, value) => {
    const updatedEstatusPeriodo = [...PuestoIndicadorDetList];
    updatedEstatusPeriodo[props.rowData.RowIndex][props.field] = value;
    updatedEstatusPeriodo[props.rowData.RowIndex].IsRowEdited = true;
    setIndicadorPuestoDetalleList(updatedEstatusPeriodo);
  };

  const onClickCfgFormulaKPI = (row) => {    
    setEsMostrarCfgFormula(true);
    setIdKPIFormulaSelected(row.IdKPIFormula);
    setKPIDescriptionSelected(row.NomIndicador);
  }

  const onFormulaCreated = (formula) => { 
     
    const RelacionarFormulaPuestoIndicador = `${config.UrlApiProject}ScoreCard/RelacionarFormulaPuestoIndicador`;  
    const paramsToService = {  
         IdKPIFormula: formula.IdKPIFormula,
         ClaPeriodo: SelectedPuestoIndicador.ClaPeriodo,
         ClaTipoIndicador: SelectedPuestoIndicador.ClaTipoIndicador,
         ClaIndicador: SelectedPuestoIndicador.ClaIndicador,
         ClaPuesto: SelectedPuestoIndicador.ClaPuesto
    };   
    
    callApi(RelacionarFormulaPuestoIndicador, 'POST', paramsToService, (response) => {  
      showSweetAlert('success', t('common:SuccessOperation'), 'success'); 
      setEsMostrarCfgFormula(false);       
      setIdKPIFormulaSelected(null);              
      ObtenerIndicadorPuesto (ClaAnio, ClaPuesto);
      if (props.onChange !== null && props.onChange !== 'undefined' && typeof(props.onChange) === 'function'){
        props.onChange();
      }       
    });    
  }  

  const onFormulaRemoved = (formula) => { 
     
    const RelacionarFormulaPuestoIndicador = `${config.UrlApiProject}ScoreCard/RelacionarFormulaPuestoIndicador`;  
    const paramsToService = {  
      IdKPIFormula: null,
      ClaPeriodo: SelectedPuestoIndicador.ClaPeriodo,
      ClaTipoIndicador: SelectedPuestoIndicador.ClaTipoIndicador,
      ClaIndicador: SelectedPuestoIndicador.ClaIndicador,
      ClaPuesto: SelectedPuestoIndicador.ClaPuesto
 };   
    
    callApi(RelacionarFormulaPuestoIndicador, 'POST', paramsToService, (response) => {  
       showSweetAlert('success', t('common:SuccessOperation'), 'success'); 
       setEsMostrarCfgFormula(false);       
       setIdKPIFormulaSelected(null);
       ObtenerIndicadorPuesto (ClaAnio, ClaPuesto);
      if (props.onChange !== null && props.onChange !== 'undefined' && typeof(props.onChange) === 'function'){
        props.onChange();
      }       
    });
    
  }

  const onKPIManagementDialogHide = () => {
    setIsKPIManagementDialogVisible(false);
  };

  const onKPIManagementClick = (row) => {
    console.log("Row", row);
    setIdKPIFormulaSelected(row.IdKPIFormula);
    setKPIDescriptionSelected(row.NomIndicador);
    setIsKPIManagementDialogVisible(true);
  };

  const onKPIManagementRemoveItem = () => {
    ObtenerIndicadorPuesto (ClaAnio, ClaPuesto); 
    if (props.onChange !== null && props.onChange !== 'undefined' && typeof(props.onChange) === 'function'){
      props.onChange();
    }
  };

  const onKPIManagementUpdated = () => {
    ObtenerIndicadorPuesto (ClaAnio, ClaPuesto);    
    setIsKPIManagementDialogVisible(false); 
    if (props.onChange !== null && props.onChange !== 'undefined' && typeof(props.onChange) === 'function'){
      props.onChange();
    }
  };

  const OutStandingEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldNumber
          name="SobreSaliente"
          value={props.rowData.SobreSaliente}
          onChange={(e) => onOutStandingChange(props, e.target.value)}
          min={-99999999.99}
          max={99999999.99}
          minFractionDigits={2}
        />
      </div>
    );
  };
  const SatisfactorioEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldNumber
          name="Satisfactorio"
          value={props.rowData.Satisfactorio}
          onChange={(e) => onSatisfactorioChange(props, e.target.value)} 
          min={-99999999.99}
          max={99999999.99}
          minFractionDigits={2}
        />
      </div>
    );
  };

  const MinimoEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldNumber
          name="Minimo"
          value={props.rowData.Minimo}
          onChange={(e) => onMinimoChange(props, e.target.value)}
          min={-99999999.99}
          max={99999999.99}
          minFractionDigits={2}
        />
      </div>
    );
  };

  const EstatusPeriodoEditor = (props) => { 
    if (props.rowData.ClaMes > UlitmoClaMesDisponible){
      return (
        <div className="p-fluid" title={t('position:TooltipPeriodoNOActivo')}>
          <FieldNumber
            name="EstatusPeriodo"
            value={props.rowData.EstatusPeriodo}
            onChange={(e) => onEstatusPeriodoChange(props, e.target.value)}
            disabled
            min={-99999999.99}
            max={99999999.99}
            minFractionDigits={2}
          />
        </div>
      );
    }

    return (
      <div className="p-fluid">
        <FieldNumber
          name="EstatusPeriodo"
          value={props.rowData.EstatusPeriodo}
          onChange={(e) => onEstatusPeriodoChange(props, e.target.value)}
          min={-99999999.99}
          max={99999999.99}
          minFractionDigits={2}
        />
      </div>
    );
  };

  const DueDateTemplate = (rowData) => {
    return (
      <>
        {rowData.PeriodoReportado !== null}
        &nbsp;
        <i className="pi pi-exclamation-triangle red"></i>
      </>
    );
  };

  const confirmationRecalculaKPIMessageHTML = (
    <div>
      <SweatAlertCustomIcon title={t('position:confirmacionRecalcularKPITitle')} letter={t('common:QuestionMark')}></SweatAlertCustomIcon> 
      <div>{t('position:confirmacionRecalculaKPI')}</div> 
    </div>  
   ) 

  const confirmacionRecalcultarEstatusKPI = (indicador) => {
    swal({ 
      buttons: {
        cancelar: {
          text: t('common:Cancel'),
          value: "cancel",
          className: "rounded-button danger"
        },
        continuar: {
          text: t('common:Continue'),
          value: "continue",
          className: "rounded-button light-green"
        }
      },
      content: confirmationRecalculaKPIMessageHTML
    }).then((value) => {
      switch (value) { 
        case "continue":
          recalculateKPI(indicador);
          break; 
        default:
          break;
      }
    });
  }

  const recalculateKPI = (indicador) => { 
    const RecalcularCurrentStatusKPIAutomatizado = `${config.UrlApiProject}ScoreCard/RecalcularCurrentStatusKPIAutomatizado`;  
      const paramsToService = {   
        ClaPeriodo: indicador.ClaPeriodo,
        ClaTipoIndicador: indicador.ClaTipoIndicador,
        ClaIndicador: indicador.ClaIndicador,
        ClaPuesto: indicador.ClaPuesto,
        ClaFechaReporte: indicador.ClaFechaReporte
     };   
      
      callApi(RecalcularCurrentStatusKPIAutomatizado, 'POST', paramsToService, (response) => {  
        showSweetAlert('success', t('common:SuccessOperation'), 'success'); 
        onDetailPuesto(SelectedPuestoIndicador);  
      });
      
  }  
  
  const NomMesTemplate = (props) => {

    if (props.EsMostrarBotonActualizarResultado === true) {
      return (
        <div title={t('position:TooltipBotonActualizaEstatusKPI')} onClick={() => confirmacionRecalcultarEstatusKPI(props)}>
          <table>
            <tbody>
              <tr>
                <td width="70%">{props.NomMes}</td>
                <td>&nbsp;<i className="fas fa-sync cursor"></i></td>
              </tr>
            </tbody>
          </table>
        </div>
      )
   }

   return (<>{props.NomMes}</>);

    
  }

  const EstatusPeriodoTemplate = (props) => {
    let className = '';

    switch (props.ColorEstatus) {
      case 1:
        className = 'danger-color';
        break;
      case 2:
        className = 'warning-color';
        break;
      case 3:
        className = 'success-color';
        break;
      default:
        className = 'default-color';
    }
    return (
      <>
        <i className={`fa fa-circle ${className}`}></i>
        &nbsp;
        {props.EstatusPeriodo !== null? Highcharts.numberFormat(props.EstatusPeriodo, 2, '.', ','): null}
      </>
    );
  };
  const MinimoTemplate = (props) => {
    return <>{props.Minimo !== null?  Highcharts.numberFormat(props.Minimo, 2, '.', ',') : null}</>;
  };
  const SatisfactorioTemplate = (props) => {
    return <>{props.Satisfactorio !== null?  Highcharts.numberFormat(props.Satisfactorio, 2, '.', ','): null}</>;
  };

  const SobreSalienteTemplate = (props) => {
    return <>{props.SobreSaliente !== null?  Highcharts.numberFormat(props.SobreSaliente, 2, '.', ','): null}</>;
  };

  const SentidoMejoraTemplate = (props) => {
    return <>{props.ClaSentidoMejora === 1 ?  (<i className="fas fa-sort-amount-up"></i>): (<i className="fas fa-sort-amount-down"></i>)}</>;
  }; 

  const CfgFormulaTemplate = (props) => {  

    // console.log("test:props", props);
    
    if (props.IdKPIFormula !== null){
      return (<><i className='fas fa-calculator info-color' onClick={() => onClickCfgFormulaKPI(props)} title='Edit Formula'></i></>);
    }

    return (<><i className='fas fa-calculator light-gray-color' onClick={() => onClickCfgFormulaKPI(props)} title='Click here to create a formula for this KPI'></i></>);
  } 

  const kpiManagementColumnTemplate = (props) => { 
    const flagColor = props.ClaSeguimientoKPIPosicionRelacionada || props.HasKPIComposition? 'text-warning': 'light-gray-color';  
    
    if (!props.EsMostrarConfiguracionComposicion){
      return (<></>);
    }

    return (
      <>
        <i 
          className={`fas fa-flag ${flagColor}`}
          title={t('position:ClickHereKPIManagementByPosition')}
          onClick={() => onKPIManagementClick(props)}           
        />
      </>
    );
  } 
          
  return (
    <> 
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          &nbsp; 
        </Col>
      </Row>
      <Row className="align-items-start">
        <Col lg={6} md={6} sm={12} xs={12}>
          <FieldDataTable
            value={PuestoIndicadorList}
            scrollable
            scrollHeight="330px"
            selection={SelectedPuestoIndicador} 
            selectionMode="single" 
            onSelectionChange={e => setSelectedPuestoIndicador(e.value)} 
            onRowSelect={onRowSelect}
            paginator={false}
            header={headerPuestoIndicador}
          >
            <Column
              field="NomTipoIndicador"
              className="text-center"
              header={t('position:IndexType')}
              columnKey="NomTipoIndicador"
              headerStyle={{ width: '50px' }}
            >
            </Column>
            <Column
              field="ClaveIndicador"
              className="text-center"
              header={t('position:Code')}
              columnKey="ClaveIndicador"
              headerStyle={{ width: '30px' }}
            >
            </Column>
            <Column
              field="ClaSentidoMejora"
              className="text-center"
              header=''
              columnKey="ClaSentidoMejora"
              headerStyle={{ width: '20px' }}
              body={SentidoMejoraTemplate}
            >
            </Column> 
            <Column
              field="NomIndicador"
              header={t('position:IndexDesc')}
              columnKey="NomIndicador"
              headerStyle={{ width: '300px' }}
            >
            </Column>
            <Column              
              className="text-center"
              header=''
              columnKey="cfgFormula"
              headerStyle={{ width: '20px' }}
              body={CfgFormulaTemplate}
            >
            </Column> 
            <Column              
              className="text-center"
              header=''
              columnKey="kpiManagement"
              headerStyle={{ width: '20px' }}
              body={kpiManagementColumnTemplate}
            >
            </Column> 
            {/* <Column
              field="Weighted"
              className="text-center"
              header={t('position:Weighted')}
              columnKey="Weighted"
              headerStyle={{ width: '50px' }}
            >
            </Column> */}
            <Column
              field="IndexRole"
              className="text-center"
              header={t('position:IndexRol')}
              columnKey="IndexRole"
              headerStyle={{ width: '50px' }}
            >
            </Column>
            {/* <Column
              field="Comments"
              className="text-center"
              header={t('position:Comments')}
              columnKey="Comments"
              headerStyle={{ width: '200px' }}
            >
            </Column> */}
            <Column
              field="ClaIndicador"
              className="text-center"
              header={t('position:Id')}
              columnKey="ClaIndicador"
              headerStyle={{ width: '40px' }}
            >
            </Column>
            {/* Column field="ClaIndicador" className="text-center" header={t('position:Id')} columnKey="ClaIndicador" headerStyle={{ width: '105px' }}></Column> */}
          </FieldDataTable>
        </Col>
        <Col lg={6} md={6} sm={12} xs={12}>
          <div className="card">
            <div className="card-body">
              <div className="pb-3" hidden={nomIndicadorSelected!==null&&nomIndicadorSelected!==''}>
                <i className="fas fa-info-circle"></i> {t('position:SelectAnIndex')} 
              </div> 
              <div className="pb-3" hidden={nomIndicadorSelected===null||nomIndicadorSelected===''}>
                <b>{nomIndicadorSelected}</b>
                <br></br>
                {descripcionIndicadorSelected}
                {
                  formulaIndicadorSelected !== '' && formulaIndicadorSelected !== null ?
                  (
                    <>
                      <br></br>
                      <br></br>
                      <b>{t('position:Formula')}:</b> <br></br>{formulaIndicadorSelected}
                    </>
                  )
                  : (<></>)
                }
                
                <div className="text-right"> 
                  { SelectedPuestoIndicador !== null ? 
                    (
                      <FieldButton
                        type="button"
                        onClick={() => confirmacionSincronizacionObjetivos()}
                        label={t('position:btnSincronizarObjetivos')}
                        icon="fas fa-sync"
                        className="secondary no-border p-mr-2 rounded-button"
                        style={{ marginRight: '.25em' }}
                        hidden={SelectedPuestoIndicador.EsSoportaSincronizacionObjetivos===0}
                      />
                    )
                    :
                    (<></>)} 
                  &nbsp;
                  <FieldButton
                    type="button"
                    onClick={() => onSaveObjetivo()}
                    label={t('common:Save')}
                    icon="pi pi-save"
                    className="p-button-success no-border p-mr-2 rounded-button"
                    style={{ marginRight: '.25em' }}
                  />
                </div>
                <br></br>
                <FieldDataTable
                  value={showActiveRows(PuestoIndicadorDetList)}
                  scrollable
                  scrollHeight="480px"
                  selectionMode="single"
                  paginator={false}
                  header={headerPuestoIndicadorDet}
                >
                  <Column body={BotonesTemplate} style={{ textAlign: 'center' }} headerStyle={{ width: '30px' }}></Column>  
                  <Column
                    field="NomMes"
                    header={t('position:ReportingPeriod')} 
                    columnKey="NomMes"
                    headerStyle={{ width: '100px' }}
                    body={NomMesTemplate}
                  >
                  </Column>
                  <Column
                    field="NomUnidadIndicador"
                    className="text-center"
                    header={t('position:UOM')}
                    columnKey="NomUnidadIndicador"
                    headerStyle={{ width: '50px' }}
                  >
                  </Column>
                  <Column
                    field="EstatusPeriodo"
                    header={t('position:CurrentStatus')}
                    columnKey="EstatusPeriodo"
                    headerStyle={{ width: '80px' }}
                    editor={(props) => EstatusPeriodoEditor(props)}
                    body={EstatusPeriodoTemplate}
                  >
                  </Column>
                  <Column
                    field="Minimo"
                    header={t('position:MinimunAcceptable')}
                    columnKey="Minimo"
                    headerStyle={{ width: '75px' }}
                    editor={(props) => MinimoEditor(props)}
                    body={MinimoTemplate}
                  >
                  </Column>
                  <Column
                    field="Satisfactorio"
                    header={t('position:Satisfactory')}
                    columnKey="Satisfactorio"
                    headerStyle={{ width: '75px' }}
                    editor={(props) => SatisfactorioEditor(props)}
                    body={SatisfactorioTemplate}
                  >
                  </Column>
                  <Column
                    field="SobreSaliente"
                    header={t('position:OutStanding')}
                    columnKey="SobreSaliente"
                    headerStyle={{ width: '75px' }}
                    editor={(props) => OutStandingEditor(props)}
                    body={SobreSalienteTemplate}
                  >
                  </Column>
                </FieldDataTable> 
              </div> 
             
            </div>
          </div>
          
        </Col>
      </Row>
      {
        esMostrarCfgFormula && 
        (  
          <KPIFormulaSetUp show={esMostrarCfgFormula} onHide={() => setEsMostrarCfgFormula(false)} Description={KPIDescriptionSelected} onFormulaCreated={onFormulaCreated} onFormulaRemoved={onFormulaRemoved} IdKPIFormula={IdKPIFormulaSelected}></KPIFormulaSetUp>
        )
      }
      {
        IsKPIManagementDialogVisible && (
          <KPIManagementByPositionCompositionDialog                   
            IsVisible={IsKPIManagementDialogVisible}             
            PuestoIndicador={SelectedPuestoIndicador}
            OnHide={onKPIManagementDialogHide}
            OnSaved={onKPIManagementUpdated}
            OnRemoved={onKPIManagementRemoveItem}
          />
        )        
      }      
    </>
  );
};
export default PuestoIndicadorObjetivos;

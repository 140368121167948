/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import swal from '@sweetalert/with-react';
import moment from "moment";
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import { SplitButton } from 'primereact/splitbutton';
import React,{ useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import * as yup from 'yup';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import FieldTextArea from '../../../components/Controls/FieldTextArea.jsx';
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon';
import YupValidator from '../../../utils/YupValidator';
import { config } from '../../../utils/config';
import { callApi, showSweetAlert } from '../../../utils/utils';
import IndividualDevelopmentPlanDetailDialog from './IndividualDevelopmentPlanDetailDialog';
import { idpStatus, idpVersions } from './IndividualDevelopmentPlanConstants.js';

/**
 * 
 * @param {*} props 
 * props:
 * - refresh
 * - year
 * - type (M = Myself, T = Team)
 * @returns 
 */
const IndividualDevelopmentPlanList = (props) => {  
    const { t } = useTranslation(['hr', 'common']);
    const [errors, setErrors] = useState({}); 
    const [RowsGrid, setRowsGrid] = useState([]);     
    const [IDPAcciones, setIDPAcciones] = useState([]);     
    const [IdIDP, setIdIDP] = useState(null); 
    const [IDPStatusName, setIDPStatusName] = useState(''); 
    const [MotivoDeshacer, setMotivoDeshacer] = useState(null); 
    const [ComentariosRevisado, setComentariosRevisado] = useState(null);  
    const dt = useRef(null); 
    const [supportDucumentCertificateDatafiles, setSupportDucumentCertificateDatafiles] = useState([]); 
    const [EsDialogUndo, setEsDialogUndo] = useState(false); 
    const [ShowDetail, setShowDetail] = useState(false); 
    const [IDPVersion, setIDPVersion] = useState(null);
    const reportTypes = {
      MY_SELF: 'M',
      TEAM: 'T'
    };

  useEffect(() => {         
    if (props.year) {
      getIDP(props.year, props.type);
    }
  }, [props.year, props.type, props.refresh]); 
  
  const getIDP = (year, reportType) => { 
    const getMySelfIndividualDevelopmentPlanURL = `${config.UrlApiProject}hr/GetIndividualDevelopmentPlan`;  
    const paramsToService = { 
      IdIDP: null,      
      Idioma: '',      
      Anio: year,
      TipoReporte: reportType
    };   
    callApi(getMySelfIndividualDevelopmentPlanURL, 'POST', paramsToService, (response) => {       
      setRowsGrid(response.data.IDPLista);
    }, (error) => {
      console.error("Error getting Myself IDP", error);
    });
  };
  
  const onHideIDPDetail = () => {
    setShowDetail(false);
  };

  const onIDPSaved = () => {      
    getIDP(props.year, props.type);    
    setShowDetail(false);
  };

  const onIDPStatusSaved = () => {      
    getIDP(props.year, props.type);
    setShowDetail(false);
  };

  const onChangeMotivoDeshacer = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.MotivoDeshacer;   
    setErrors(newErrors) 
    setMotivoDeshacer(e.target.value)
  } 

  const AccionesTemplate = (row) =>  {
    let className = '';
    switch(true) {
      case row.Porcentaje<50:
        className = "danger-color";
        break;
      case row.Porcentaje<75:
        className = "orange-color";
        break;
      case row.Porcentaje<99:
        className = "warning-color";
        break;
      case row.Porcentaje===100:
        className = "success-color";
        break;
      default:
        className = "default-color";
    }
    return (
      <>
        {
          row.ClaIDPEstatus !== idpStatus.ACCEPTED && (
            <>
              <div className="text-center">
                <small>
                  <span>{row.AccionesTerminada}</span> &nbsp;
                  <span className="bold">{t('hr:Of')}</span> &nbsp;
                  <span>{row.Acciones}</span>&nbsp;
                  <span className="bold">{t('hr:Activities')}</span> 
                </small>
              </div>
              <div>
                <ProgressBar className={className} value={row.Porcentaje}></ProgressBar>
              </div>
            </>
          )          
        }
      </>
    );
  }
  
  const sIcon = (EstatusId) => {
    let sclass = '';
    switch (EstatusId) {
      case 1:
        sclass = 'pi pi-pencil'; 
        break;
      case 2:
        sclass = 'pi pi-eye'; 
        break;
      case 3:
        sclass = 'pi pi-eye'; 
        break;
      case 5:
          sclass = 'pi pi-ban'; 
        break;
      case 7:
        sclass = 'pi pi-eye'; 
        break;
      case -1:
        sclass = 'pi pi-undo'; 
        break;
      default:
        sclass = 'pi pi-send'; 
    }
    return (
      sclass
    );
  }

  const dataInfo = (rowData) => {
    // console.log('dataInfo',rowData);
    const arreglo = [];
    rowData.Opciones.forEach((item) => { 
      const arrItem =   { 
        label: item.NomIDPEstatus,
        icon: sIcon(item.ClaIDPEstatus),
        command: () => { 
          switch (item.ClaIDPEstatus) {
            case -1:
              setIdIDP(rowData.IdIDP);
              if(rowData.EsjefeInmediato){
                onConfirmUndoSubmit(rowData.IdIDP, MotivoDeshacer); 
              }else{
                setEsDialogUndo(true);
              }
              break;
            case 5:
              onConfirmSubmit(5, item.IdIDP, rowData.Version);  
              break;
            default:
              console.log("default");
              // getIdpDetalle(item);                                 
              setIdIDP(item.IdIDP);
              setIDPStatusName(rowData.Estatus)
              setIDPVersion(rowData.Version);
              setShowDetail(true);
          }
        }
      }
      arreglo.push(arrItem);
    })
    return (
      arreglo
    );
  }

  const goalTableColumnTemplate = (rowData) =>  {    
    return (
      <span title={rowData.ClaIDPEstatus === idpStatus.ACCEPTED? t('hr:NotParticipatingAtThisTime'): rowData.ObjetivoSmart}>
        {rowData.ClaIDPEstatus === idpStatus.ACCEPTED? t('hr:NotParticipatingAtThisTime'): rowData.ObjetivoSmart}        
      </span>
    );
  };

  const statusTableColumnTemplate = (rowData) =>  {    
    let className = '';
    if (
      (props.type === reportTypes.MY_SELF && rowData.ClaIDPEstatus === idpStatus.ADDITIONAL_INPUT_NEEDED) ||
      (props.type === reportTypes.TEAM && rowData.ClaIDPEstatus === idpStatus.PENDING_REVIEW) 
    ) {
      className = 'text-warning';
    }
    return (
      <>
        <span className={className}>
          {rowData.Estatus}
        </span>
      </>
    );
  };  
  
  const BotonesTemplate = (rowData) =>  {    
    return (
      <>
        <SplitButton className="hidebutton" buttonTemplate="danger" dropdownIcon="pi pi-bars" model={dataInfo(rowData)}></SplitButton>
      </>
    );
  }

  const valitationSchemeRejected = yup.object().shape({
    ComentariosRevisado: yup.string().required(t('common:RequiredField')).nullable()
  });

  const valitationSchemeCompleted = yup.object().shape({
    IDPAcciones: yup.array().of(
        yup.object().shape({  
          FechaFinalReal: yup.date().when('EsAccionCompletada', {is: true,
            then: yup.date().required(t('common:RequiredField')).nullable(),
            otherwise: yup.date().nullable()
          }), 
          ComentariosSeguimiento:  yup.string().when('EsAccionCompletada', {is: false,
            then: yup.string().required(t('common:RequiredField')).nullable(),
            otherwise: yup.string().nullable()
          }) 
          // ,EsAccionCompletada: yup.boolean().required(t('common:RequiredField'))
        })
      )
  });  

  const confirmationMessageHTML = (
    <div>
      <SweatAlertCustomIcon title={t('common:SubmitConfirmation')} letter={t('common:QuestionMark')}></SweatAlertCustomIcon> 
      <div>{t('hr:SubmitCancelled')}.</div> 
    </div>  
  ) 

  const confirmationUndoMessageHTML = (
    <div>
      <SweatAlertCustomIcon title={t('common:SubmitConfirmation')} letter={t('common:QuestionMark')}></SweatAlertCustomIcon> 
      <div>{t('hr:SubmitUndo')}</div> 
    </div>  
  ) 
  
  const onConfirmSubmit = (claEstatus, nIdIDP, version) => {  
    swal({ 
      buttons: {
        cancelar: {
          text: t('common:Cancel'),
          value: "cancel",
          className: "rounded-button danger"
        },
        continuar: {
          text: t('common:Continue'),
          value: "continue",
          className: "rounded-button light-green"
        }
      },
      content: confirmationMessageHTML
    }).then((value) => {
      switch (value) { 
        case "continue":
          saveStatus(claEstatus,nIdIDP);
          break; 
        default:
          break;
      }
    });
  }

  const onConfirmUndoSubmit = (nIdIDP, motive) => {  
    swal({ 
      buttons: {
        cancelar: {
          text: t('common:Cancel'),
          value: "cancel",
          className: "rounded-button danger"
        },
        continuar: {
          text: t('common:Continue'),
          value: "continue",
          className: "rounded-button light-green"
        }
      },
      content: confirmationUndoMessageHTML
    }).then((value) => {
      switch (value) { 
        case "continue":
          acceptUndo(nIdIDP, motive);
          break; 
        default:
          break;
      }
    });
  }

  const onAcceptUndoClick = () => {  
    acceptUndo(IdIDP, MotivoDeshacer);
  };  
  
  const saveStatus = async (ClaEstatus,Id, version) => {
    let value = {
      ComentariosRevisado
    }
    let validator;
    let esValido;
    if(ClaEstatus===4){
      validator = new YupValidator(valitationSchemeRejected);
      esValido = await validator.validate(value);
      if (!esValido){ 
        setErrors(validator.errors);
        showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
        return;
      }
    }
    if(ClaEstatus===6){
      value = {
        IDPAcciones
      }
      validator = new YupValidator(valitationSchemeCompleted);
      esValido = await validator.validate(value);
      if (!esValido){ 
        setErrors(validator.errors);
        showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
        return;
      }
    }
    let urlWebServiceSave = '';
    if (version === idpVersions.V1) {
      urlWebServiceSave = `${config.UrlApiProject}hr/SaveStatusIndividualDevelopmentPersonal`;
    } else if (version === idpVersions.V2) {
      urlWebServiceSave = `${config.UrlApiProject}hr/SaveStatusIndividualDevelopmentPlanV2`;
    }
      
    const paramsService = {
      IdIDP: Id,
      ClaIDPEstatus:ClaEstatus,
      ComentariosRevisado,
      IndividualDevelopmentPersonalAcciones:IDPAcciones,
      SupportDocumentCertificateFileData:(supportDucumentCertificateDatafiles.length > 0 ? supportDucumentCertificateDatafiles[0].FileData : null),
      SupportDocumentCertificateNomArchivo:(supportDucumentCertificateDatafiles.length > 0 ? supportDucumentCertificateDatafiles[0].FileName: null),
      SupportDocumentCertificateExtension:(supportDucumentCertificateDatafiles.length > 0 ? supportDucumentCertificateDatafiles[0].FileExtension: null) 
    }; 
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {
      showSweetAlert(t('common:Success'), t('common:SaveSuccess'), 'success');
      getIDP(props.year, props.type);
    });
  }

  const acceptUndo = async (idpID, motive) => {
    const urlWebServiceSave = `${config.UrlApiProject}hr/AcceptUndoIndividualDevelopmentPersonal`;
    const paramsService = {
      IdIDP: idpID,
      MotivoDeshacer: motive
    }; 
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {
      showSweetAlert(t('common:Success'), t('common:SaveSuccess'), 'success');
      closeDialogUndo();
      getIDP(props.year, props.type);
    });
  }

  const FechaRevisionTemplate = (rowData) => {
    return (
      <>
        <div hidden={rowData.FechaRevisado === null}>
          { moment(rowData.FechaRevisado).format('MM/DD/YYYY h:mm a') }
        </div> 
      </>
    );
  }

  const closeDialogUndo = () => {  
    setIdIDP(null);
    setIDPStatusName('');
    setMotivoDeshacer(null);
    setEsDialogUndo(false);
  };

  const dialogStyle = {
    width: '60vw',
  };

  const renderFooterUndo = () => {
    return (
      <div>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
            <div className='text-right'>
              <FieldButton
                label={t('hr:Accept')}
                icon="fa fa-"
                className="light-green no-border p-mr-2"
                onClick={onAcceptUndoClick}
              />
            </div>
          </Col> 
        </Row>
      </div>
    );
  }; 

  const undoDialogTemplate = (
    <>
      <Dialog
        visible={EsDialogUndo}
        header={t('hr:UndoAccepted')}
        style={dialogStyle}
        modal
        className="dialog-custom"
        onHide={closeDialogUndo}
        footer={renderFooterUndo}
      >
        <div>
          <Row className="pt-2">
            <Col> 
              <span>{t('hr:MotivoDeshacer')}</span> 
            </Col>
          </Row>
          <Row className="pt-2">
            <Col>
              <div className="p-fluid"> 
                <FieldTextArea 
                  name="MotivoDeshacer"
                  rows={4} 
                  value={MotivoDeshacer} 
                  onChange={onChangeMotivoDeshacer}
                  errors={errors} 
                  maxLength={1000}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Dialog>     
    </>
  );

  const detailDialogTemplate = (
    <>
      <IndividualDevelopmentPlanDetailDialog
        isVisible={ShowDetail}       
        version={IDPVersion}        
        idIDP={IdIDP}
        iDPStatusName={IDPStatusName}
        reportType={props.type}
        year={props.year}
        onHide={onHideIDPDetail}        
        onSaved={onIDPSaved}
        onStatusSaved={onIDPStatusSaved}        
      />    
    </>
  );

  const tableTemplate = (
    <>
      <FieldDataTable
        value={RowsGrid} 
        scrollable  
        className="p-datatable-striped"
        selectionMode="single"
        paginator={false}
      >  
          
        <Column body={BotonesTemplate} style={{ textAlign: 'center' }} headerStyle={{ width: '40px' }}></Column>           
        { 
          props.type === reportTypes.TEAM && (
            <Column field="NombreCompleto" columnKey="NombreCompleto" header={t('hr:Responsible')} headerStyle={{ width: '150px' }} className='overflow-ellipsis'></Column> 
          )          
        }
        <Column body={goalTableColumnTemplate} columnKey="ObjetivoSmart" header={t('hr:Goal')} headerStyle={{ width: '200px' }} className='overflow-ellipsis'></Column> 
        {/* <Column field="Version" columnKey="Version" header="V" headerStyle={{ width: '20px' }}></Column> */}
        <Column body={statusTableColumnTemplate} header={t('hr:Status')} headerStyle={{ width: '90px' }}></Column>
        <Column body={FechaRevisionTemplate} header={t('hr:FechaRevisado')} columnKey="FechaRevisado" headerStyle={{ width: '140px' }}></Column> 
        <Column body={AccionesTemplate} style={{ textAlign: 'center' }} headerStyle={{ width: '100px' }}></Column> 
      </FieldDataTable>   
    </>
  ); 

  return (
    <>  
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>   
          {props.year && tableTemplate}
          {detailDialogTemplate}
          {undoDialogTemplate}
        </Col> 
      </Row> 
    </>  
  ); 
};

export default IndividualDevelopmentPlanList;

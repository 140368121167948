/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next';  
import { Row, Col , 
    ListGroupItem,
    ListGroup,
    Progress,
    Card,
    CardHeader,
    CardBody,
    CardFooter} from 'reactstrap';   
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple'; 
import { Badge } from 'primereact/badge';
import FeedbackCoaching from './FeedbackCoaching.jsx';
import Score from '../../../components/Cards/Score/Score.jsx'
import { config } from '../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../utils/utils'; 
import { Link } from "react-router-dom";

const ScoreSummary = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);
  
  const [claUsuarioSeleccionado, setClaUsuarioSeleccionado] = useState(props.ClaUsuario === undefined ? getSessionItem('ClaUsuarioMod', '') : props.ClaUsuario); 
  const [anio, setAnio] = useState(props.Anio ? props.Anio : null);  
  const [EstatusContribucionReview, setEstatusContribucionReview] = useState(0); 
  const [ActivaEmployeeSelfReview, setActivaEmployeeSelfReview] = useState(0); 
  const [ScoreCardSummaryInfo, setScoreCardSummaryInfo] = useState({});  
  
  useEffect(() => {     
    obtenerInformacionAnnualUsuario(claUsuarioSeleccionado);
  }, [props.Anio]); 

  
  const obtenerInformacionAnnualUsuario = (ClaUsuarioSeleccionado) => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}hr/GetSummaryScorecardUser`;  
    const paramsToService = { 
      ClaUsuario: ClaUsuarioSeleccionado,
      Anio: props.Anio  !== undefined ? props.Anio : null

    };   
    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => { 

      if (response.data.Encabezado.length > 0)
      {  
        setEstatusContribucionReview(response.data.Encabezado[0].EstatusContribucionReview);  
        setActivaEmployeeSelfReview(response.data.Encabezado[0].ActivaEmployeeSelfReview);
        setScoreCardSummaryInfo(response.data.Encabezado[0]) 
      }  
    });
  }

    return (
      <>    
        <Card> 
          <CardBody>
            <h1>{t('hr:ScoreCardSummary')}</h1> 
            <div className="right-top-badge">  
              <Badge
                value={EstatusContribucionReview === 1 && ActivaEmployeeSelfReview
            ?  <i className="fa fa-check light-green-color"></i>
            : 
            ActivaEmployeeSelfReview === 1 ? 
              <i className="fa fa-unlock primary-color"></i>  
              : 
              <i className="fa fa-lock warning-color"></i>}
                className="white-badge"
              />  
            </div> 
          
            <Row className="align-items-start">
              <Col lg={6} md={6} sm={6} xs={6} className="dashed-border-right" style={{height:'100px'}}> 
                <Link
                  to={{ pathname: "AnnualReview",
                    search: `?Anio=${props.Anio}&ActiveStep=1`
                  }}
                >
                  <Score title={t('hr:ScoreCard')} range={`of ${typeof(ScoreCardSummaryInfo.PonderacionScoreCard) === 'undefined' ? '' : ScoreCardSummaryInfo.PonderacionScoreCard}`} score={ScoreCardSummaryInfo.ScoreCard} severity="blue"></Score>  
                </Link> 
                
                <i className="pi pi-plus primary-color font-weight-bold plus-icon-middle-right"></i>
                <i className="pi pi-plus primary-color font-weight-bold plus-icon-center"></i>
              </Col>
              <Col lg={6} md={6} sm={6} xs={6} style={{height:'100px'}}> 
                <Link
                  to={{ pathname: "AnnualReview",
                    search: `?Anio=${props.Anio}&ActiveStep=1`
                  }}
                >
                  <Score title={t('hr:ActionPlans')} range={`of ${typeof(ScoreCardSummaryInfo.PonderacionActionPlans) === 'undefined' ? '' : ScoreCardSummaryInfo.PonderacionActionPlans }`} score={ScoreCardSummaryInfo.ActionPlans} severity="blue"></Score>  
                </Link> 
              </Col>
            </Row> 
            <Row className="align-items-start dashed-border-top">
              <Col lg={6} md={6} sm={6} xs={6} className="dashed-border-right">  
                 &nbsp;
              </Col>
              <Col lg={6} md={6} sm={6} xs={6}> 
                            
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={6} xs={6} className="dashed-border-right" style={{height:'100px'}}> 
                <Link
                  to={{ pathname: "AnnualReview",
                    search: `?Anio=${props.Anio}&ActiveStep=1`
                  }}
                >   <Score title={t('hr:ProjectActivities')} range={`of ${typeof(ScoreCardSummaryInfo.PonderacionProjectActivities) === 'undefined' ? '' : ScoreCardSummaryInfo.PonderacionProjectActivities }`} score={ScoreCardSummaryInfo.ProjectActivities} severity="blue"></Score> 
                  <i className="fas fa-equals primary-color font-weight-bold plus-icon-middle-right"></i>
                </Link> 
              </Col>
              <Col lg={6} md={6} sm={6} xs={6} style={{height:'100px'}}> 
                <Link
                  to={{ pathname: "AnnualReview",
                    search: `?Anio=${props.Anio}&ActiveStep=1`
                  }}
                >   
                  <Score title={t('hr:Points')} range="of 100" score={ScoreCardSummaryInfo.Score} severity="darkBlue"></Score> 
                </Link> 
              </Col>
            </Row> 
          </CardBody>
        </Card>   
      </>  
        ); 
};

export default ScoreSummary;

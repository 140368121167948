/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next'; 
import PageContent from '../../../layouts/PageContent'; 
import { Toolbar } from 'primereact/toolbar'; 
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row, Col , 
    ListGroupItem,
    ListGroup,
    Progress,} from 'reactstrap';  
import HomeMenuCard from '../../../components/Cards/HomeMenuCard/HomeMenuCard.jsx';
import CommunicationMenuImg from '../../../assets/img/MCSW-plant employee.png';
import ListItem from '../../../components/ListGroup/ListItem.jsx'
import { useHistory, Link } from "react-router-dom";

const CommunicationMenu = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);
  
  const newsletterLnk = useRef(null);
  const facebookLnk = useRef(null);

  const onNewsletterClick = () => { 
    newsletterLnk.current.click();
  }

  const onFacebookLnkClick = () => { 
    facebookLnk.current.click();
  }

    return (
      <> 
        <HomeMenuCard title={t('hr:Communications')} className='HomeMenuCard' imageSrc={CommunicationMenuImg} color="#68cc95"> 
          <ListItem title={t('hr:Newsletter')} color="#d0323a" onClick={onNewsletterClick}></ListItem> 
          <ListItem title={t('hr:FacebookPage')} color="#d0323a" onClick={onFacebookLnkClick}></ListItem>
        </HomeMenuCard>
        <Link ref={newsletterLnk} to={{ pathname: "MCSWNewsletter" }}></Link>
        <Link ref={facebookLnk} to={{ pathname: "https://www.facebook.com/groups/800468120658347/" }} target="_blank"></Link>  
      </>  
        ); 
};

export default CommunicationMenu;

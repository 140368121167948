/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row, Col } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi, showSweetAlert, getSessionItem } from '../../../utils/utils';
import { Checkbox } from 'primereact/checkbox';
import FieldText from '../../../components/Controls/FieldText.jsx';
import FieldTextArea from '../../../components/Controls/FieldTextArea.jsx';
import FieldNumber from '../../../components/Controls/FieldNumber.jsx';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx';
import FieldDropdown from 'components/Controls/FieldDropdown.jsx';
import { RadioButton } from 'primereact/radiobutton';
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import moment from 'moment';
import { Sidebar } from 'primereact/sidebar';
import swal from '@sweetalert/with-react';
import { Tag } from 'primereact/tag';
import PageContent from 'layouts/PageContent.jsx';
import SalesPerformanceSalesRepManagementFilter from './SalesPerformanceSalesRepManagementFilter.jsx';

const SalesPerformanceSalesRepManagement = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['salesPerformanceSales', 'common']);
  const [errors, setErrors] = useState({});
  const [CompensationAgStpList, setCompensationAgStpList] = useState([]);
  const [OpcionesClaAgenteCom, setOpcionesClaAgenteCom] = useState([]);
  const [OpcionesClaAgenteMgr, setOpcionesClaAgenteMgr] = useState([]);
  const [OpcionesClaDivision, setOpcionesClaDivision] = useState([]);

  useEffect(() => {
    obtenerCompensationAgStp(props.filtersData.ShowOnlyPending);
    GetAgenteCmb();
    GetDivisionCmb();  
    if (props.filtersData.flagSave === 1) {
      onSaveClick();
    }
  }, [props.filtersData]);

  const obtenerCompensationAgStp = (pShowOnlyPending) => {
    const getCompensationAgStp = `${config.UrlApiProject}SalesPerformanceSales/GetCompensationAgStp`;
    const paramsToService = {
         ShowOnlyPending : props.filtersData.ShowOnlyPending,
         ClaAgenteCom: props.filtersData.ClaAgenteSalesRep,
         ClaAgenteMgr: props.filtersData.ClaAgenteSalesManager,
         ClaDivision: props.filtersData.ClaDivision 
    }; 
 
    callApi(getCompensationAgStp, 'POST', paramsToService, (response) => {
      setCompensationAgStpList(response.data.CompensationAgStp);
    });
  };

  const GetAgenteCmb = () => {
    const getAgenteCmb = `${config.UrlApiProject}SalesPerformanceSales/GetAgenteCmb`;
    const paramsToService = {};
    callApi(getAgenteCmb, 'GET', paramsToService, (response) => {
      setOpcionesClaAgenteCom(response.data.Agente);
      setOpcionesClaAgenteMgr(response.data.Agente);
    });
  };

  const GetDivisionCmb = () => {
    const getDivisionCmb = `${config.UrlApiProject}SalesPerformanceSales/GetDivisionCmb`;
    const paramsToService = {};
    callApi(getDivisionCmb, 'GET', paramsToService, (response) => {
      setOpcionesClaDivision(response.data.Division);
    });
  }; 
 
  const ClaAgenteComEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldDropdown
          name="ClaAgenteCom"
          value={props.rowData.ClaAgenteCom}
          options={OpcionesClaAgenteCom}
          optionValue="ClaAgente"
          optionLabel="NomAgente"
          onChange={(e) => onClaAgenteComChange(props, e)}
          // errors={errors[`FaseProyectoEntregables[${props.rowData.RowIndex}]`]}
        />
      </div>
    );
  };

  const onClaAgenteComChange = (props, e) => {
    const compensationAgStpListAgenteCom = [...CompensationAgStpList];
    const ClaAgente = OpcionesClaAgenteCom.filter((item) => item.ClaAgente === e.value)[0];
    if (ClaAgente !== undefined) {
      compensationAgStpListAgenteCom[props.rowData.RowIndex].ClaAgenteCom = ClaAgente.ClaAgente;
      compensationAgStpListAgenteCom[props.rowData.RowIndex].NomAgenteComercial =
        ClaAgente.NomAgente;
      compensationAgStpListAgenteCom[props.rowData.RowIndex].Configurado = 1;
    }
    setCompensationAgStpList(compensationAgStpListAgenteCom);
  };

  const ClaAgenteMgrEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldDropdown
          name="ClaAgenteMgr"
          value={props.rowData.ClaAgenteMgr}
          options={OpcionesClaAgenteMgr}
          optionValue="ClaAgente"
          optionLabel="NomAgente"
          onChange={(e) => onClaAgenteMgrChange(props, e)}
          // errors={errors[`FaseProyectoEntregables[${props.rowData.RowIndex}]`]}
        />
      </div>
    );
  };

  const onClaAgenteMgrChange = (props, e) => {
    const compensationAgStpListAgente = [...CompensationAgStpList];
    const ClaAgente = OpcionesClaAgenteMgr.filter((item) => item.ClaAgente === e.value)[0];
    if (ClaAgente !== undefined) {
      compensationAgStpListAgente[props.rowData.RowIndex].ClaAgenteMgr = ClaAgente.ClaAgente;
      compensationAgStpListAgente[props.rowData.RowIndex].NomAgenteMgr = ClaAgente.NomAgente;
      compensationAgStpListAgente[props.rowData.RowIndex].Configurado = 1;
    }
    setCompensationAgStpList(compensationAgStpListAgente);
  };

  const ClaDivisionEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldDropdown
          name="ClaDivision"
          value={props.rowData.ClaDivision}
          options={OpcionesClaDivision}
          optionValue="ClaDivision"
          optionLabel="NomDivision"
          onChange={(e) => onClaDivisionChange(props, e)}
          // errors={errors[`FaseProyectoEntregables[${props.rowData.RowIndex}]`]}
        />
      </div>
    );
  };

  const onClaDivisionChange = (props, e) => {
    const compensationAgStpListDivision = [...CompensationAgStpList];
    const ClaDivision = OpcionesClaDivision.filter((item) => item.ClaDivision === e.value)[0];
    if (ClaDivision !== undefined) {
      compensationAgStpListDivision[props.rowData.RowIndex].ClaDivision = ClaDivision.ClaDivision;
      compensationAgStpListDivision[props.rowData.RowIndex].NomDivision = ClaDivision.NomDivision;
      compensationAgStpListDivision[props.rowData.RowIndex].Configurado = 1;
    }
    setCompensationAgStpList(compensationAgStpListDivision);
  };

  const onSaveClick = async () => {
    const ListConfigurado = CompensationAgStpList.filter((opc) => opc.Configurado === 1);

    const paramsServiceOwners = {
      CompensationAgStpListDTO: ListConfigurado,
    };

    const urlWebServiceSaveCompensationAgStp = `${config.UrlApiProject}SalesPerformanceSales/SaveCompensationAgStp`;

    callApi(urlWebServiceSaveCompensationAgStp, 'POST', paramsServiceOwners, (response) => {
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      obtenerCompensationAgStp();
    });
  };

  const showActiveRows = (rows) => {
    const activeRows = [];
    if (rows !== undefined) {
      if (rows.length > 0) {
        rows.forEach((item, index) => {
          if (!item.BajaLogica || item.BajaLogica === 0 || item.BajaLogica === undefined) {
            item.BajaLogica = 0;
            item.RowIndex = index;
            item.RowIndexCount = index + 1;
            // item.Configurado = 0;
            activeRows.push({ ...item });
          }
        });
      } else {
        return [];
      }
    }

    return activeRows;
  };

  return (
    <>      
      <Row>
        <Col>
          <FieldDataTable
            value={showActiveRows(CompensationAgStpList)}
            header={null}                            
            className="editable-cells-table"
            editMode="cell"
          >
            <Column
              field="NomAgenteFac"
              header={t('salesPerformanceSales:AgenteInvoice')}
              columnKey="NomAgenteFac"
              headerStyle={{ width: '250px' }}
            >
            </Column>
            <Column
              field="NomAgenteComercial"
              columnKey="NomAgenteComercial"
              header={t('salesPerformanceSales:AgenteCompesation')}
              headerStyle={{ width: '150px' }}
              editor={(props) => ClaAgenteComEditor(props)}
            >
            </Column>
            <Column
              field="NomAgenteMgr"
              columnKey="NomAgenteMgr"
              header={t('salesPerformanceSales:Manager')}
              headerStyle={{ width: '150px' }}
              editor={(props) => ClaAgenteMgrEditor(props)}
            >
            </Column>
            <Column
              field="NomDivision"
              columnKey="NomDivision"
              header={t('salesPerformanceSales:Sales')}
              headerStyle={{ width: '150px' }}
              editor={(props) => ClaDivisionEditor(props)}
            >
            </Column>
          </FieldDataTable>
        </Col>
      </Row>
    </>
  );
};
export default SalesPerformanceSalesRepManagement;

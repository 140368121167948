/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next';  
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row,Col,Card, CardHeader, CardTitle, CardBody} from 'reactstrap';   
import { config } from '../../../utils/config';
import { callApi,showSweetAlert,tracker} from '../../../utils/utils.js'; 
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx';
import FieldToogleSwitch from 'components/Controls/FieldToogleSwitch/FieldToogleSwitch.jsx';
import MisActividadesPendientes from "./MisActividadesPendientes.jsx"; 
import MisActividadesEnProceso from "./MisActividadesEnProceso.jsx"; 
import MisActividadesTerminadas from "./MisActividadesTerminadas.jsx"; 
import EdicionProyecto from '../Projects/EdicionProyecto.jsx';
import { Dialog } from 'primereact/dialog';

const MisActividades = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['misActividades', 'common']);
  const [errors, setErrors] = useState({}); 
  const [filters, setfilters] = useState({});  
  const [TipoMinuta, setTipoMinuta] = useState({}); 
  const [ClaTipoMinuta, setClaTipoMinuta] = useState(null);   
  const [TipoInformacion, setTipoInformacion] = useState([]); 
  const [ClaSeguimiento, setClaSeguimiento] = useState(1); 
  const [ClaProyectoNoMaestro, setClaProyectoNoMaestro] = useState(null); 
  const [ProyectoNoMaestro, setProyectoNoMaestro] = useState(null); 
  const [EsMostrarSoloAtrasadas, setEsMostrarSoloAtrasadas] = useState(false); 
  const [yesNoOptions, setYesNoOptions] = useState([`${t('common:On')}`, `${t('common:Off')}`]);
  const [ColaboradorDependiente, setColaboradorDependiente] = useState([]); 
  const [ClaColaborador, setClaColaborador] = useState(-1); 
  const [IsMyTeamActivities, setIsMyTeamActivities] = useState(false); 
  const [EsMostrarKPIRedSinAP, setEsMostrarKPIRedSinAP] = useState(false);
  const [ClaFechaReporte, setClaFechaReporte] = useState(null);
  const [ClaPeriodo, setClaPeriodo] = useState(null);
  const [FechaReporte, setFechaReporte] = useState(null); 
  const [Periodo, setPeriodo] = useState(null);
  const [FechaEstimadaTermina, setFechaEstimadaTermina] = useState(null);
  const [OpcionesResponsables, setOpcionesResponsables] = useState([]);
  const [ClaResponsableActividad, setClaResponsableActividad] = useState(null);
  const [ClaFase, setClaFase] = useState(null);
  const [OpcionesFase, setOpcionesFase] = useState([]);
  const [ClaProyectoSelected, setClaProyectoSelected] = useState(null); 
  const [IsProjectDialogVisible, setIsProjectDialogVisible] = useState(false); 

  useEffect(() => {    
    setIsMyTeamActivities(props.IsMyTeamActivities === undefined ? false : props.IsMyTeamActivities);
    const GetProyectoSeguimiento = `${config.UrlApiProject}ScoreCard/GetProyectoSeguimientoCmb`;  
    const paramsToService = {
      ClaUsuarioFilter: props.ClaUsuarioFilter === undefined ? null : props.ClaUsuarioFilter
    };

    callApi(GetProyectoSeguimiento, 'POST', paramsToService, (response) => { 
      setTipoInformacion(response.data.ProyectoSeguimiento);
      obtenerTipoMinuta();   
      obtenerPeriodo();    
    });
    obtenerMisActividades(null, 0, 1, null, -1, null, null, null, null, null);
    setClaProyectoNoMaestro(null);
    setClaResponsableActividad(null);
    setEsMostrarSoloAtrasadas(0);
    setClaSeguimiento(1);
    setClaTipoMinuta(null);
    setClaColaborador(-1);
    setClaPeriodo(null);
    setFechaEstimadaTermina(null); 
    ObtenerColaboradorDependiente();
    // const date = new Date();
    // date.setDate(date.getDate() - 1);
    setFechaEstimadaTermina(null);
  }, [props.IsMyTeamActivities, props.ClaUsuarioFilter]); 

  const obtenerTipoMinuta = () => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/GetMswBSCTipoMinuta`;  
    const paramsToService = { };   
    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => { 
        setTipoMinuta(response.data[0]);  
        obtenerProyectoNoMaestros(); 
    });
  }

  const obtenerProyectoNoMaestros = () => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/GetMswBSCProyectoNoMaestros`;  
    const paramsToService = {  
      ClaUsuarioFilter : props.ClaUsuarioFilter === undefined ? null : props.ClaUsuarioFilter
    };   

    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => { 
        setProyectoNoMaestro(response.data[0]);     
    });
  }

  const ObtenerColaboradorDependiente = () => { 
    const GetColaboradorDependiente = `${config.UrlApiProject}ScoreCard/GetColaboradorDependienteCmb`;  
    const paramsToService = {  
      ClaUsuarioFilter : props.ClaUsuarioFilter === undefined ? null : props.ClaUsuarioFilter
    };   

    callApi(GetColaboradorDependiente, 'POST', paramsToService, (response) => { 
      setColaboradorDependiente(response.data.ColaboradorDependiente);     
    });
  }
  const onChangeClaSeguimiento = (value) => {
    setClaSeguimiento(value);
    setClaTipoMinuta(null);
    setClaProyectoNoMaestro(null);
    setClaResponsableActividad(null);
    obtenerMisActividades(null, EsMostrarSoloAtrasadas, value, null, ClaColaborador, ClaFechaReporte, ClaPeriodo, FechaEstimadaTermina, null, ClaFase);
  }; 

  const onChangeMeetingType = (e) => {  
    setClaTipoMinuta(e.value);
    obtenerMisActividades(ClaProyectoNoMaestro, EsMostrarSoloAtrasadas, ClaSeguimiento, e.value, ClaColaborador, ClaFechaReporte, ClaPeriodo, FechaEstimadaTermina, ClaResponsableActividad, ClaFase);
  }

  const onMostrarSoloAtrasadas = () => {   
    setEsMostrarSoloAtrasadas(!EsMostrarSoloAtrasadas)
    obtenerMisActividades(ClaProyectoNoMaestro, !EsMostrarSoloAtrasadas, ClaSeguimiento, ClaTipoMinuta, ClaColaborador, ClaFechaReporte, ClaPeriodo, FechaEstimadaTermina, ClaResponsableActividad, ClaFase);
  } 

  const onChangeProyectoNoMaestro = (e) => {  
    const newErrors = { ...errors }
    delete newErrors.ClaProyecto;
    setErrors(newErrors);
    setClaProyectoNoMaestro(e.value);   
    setClaResponsableActividad(null);
    setClaFase(null);
    obtenerMisActividades(e.value, EsMostrarSoloAtrasadas, ClaSeguimiento, ClaTipoMinuta, ClaColaborador, ClaFechaReporte, ClaPeriodo, FechaEstimadaTermina, null, null);
    obtenerResponsablesProyecto(e.value);  
    obtenerFasesProyecto(e.value);
  }

  const onChangeClaColaborador = (value) => {
    setClaColaborador(value);
    obtenerMisActividades(ClaProyectoNoMaestro, EsMostrarSoloAtrasadas, ClaSeguimiento, ClaTipoMinuta, value, ClaFechaReporte, ClaPeriodo, FechaEstimadaTermina, ClaResponsableActividad, ClaFase);
  };

  const onMostrarKPISinAp = () => {   
    setEsMostrarKPIRedSinAP(!EsMostrarKPIRedSinAP)
    obtenerMisActividades(ClaProyectoNoMaestro, EsMostrarSoloAtrasadas, ClaSeguimiento, ClaTipoMinuta, ClaColaborador, ClaFechaReporte, ClaPeriodo, FechaEstimadaTermina, ClaResponsableActividad, ClaFase);
  }

  const onChangeFechaReporte = (e) => {  
    setClaFechaReporte(e.value);
    obtenerMisActividades(ClaProyectoNoMaestro, EsMostrarSoloAtrasadas, ClaSeguimiento, ClaTipoMinuta, ClaColaborador, e.value, ClaPeriodo, FechaEstimadaTermina, ClaResponsableActividad, ClaFase);
  }
  const onChangePeriodo = (e) => {   
    setClaFechaReporte(null);
    setClaPeriodo(e.value);   
    obtenerFechaReporte(e.value);  
    obtenerMisActividades(ClaProyectoNoMaestro, EsMostrarSoloAtrasadas, ClaSeguimiento, ClaTipoMinuta, ClaColaborador, null, e.value, FechaEstimadaTermina, ClaResponsableActividad, ClaFase);
  }

  const onChangeFechaEstimadaTermina = (props, e) => {
    setFechaEstimadaTermina(e.target.value);
    obtenerMisActividades(ClaProyectoNoMaestro, EsMostrarSoloAtrasadas, ClaSeguimiento, ClaTipoMinuta, ClaColaborador, ClaFechaReporte, ClaPeriodo, e.target.value, ClaResponsableActividad, ClaFase);
  };

  const onClaResponsableActividadChange = (props, e ) => { 
    setClaResponsableActividad(e.value) 
    obtenerMisActividades(ClaProyectoNoMaestro, EsMostrarSoloAtrasadas, ClaSeguimiento, ClaTipoMinuta, ClaColaborador, ClaFechaReporte, ClaPeriodo, FechaEstimadaTermina, e.value, ClaFase);
   
  }; 

  const onClaFaseChange = (props, e ) => { 
    setClaFase(e.value) 
    obtenerMisActividades(ClaProyectoNoMaestro, EsMostrarSoloAtrasadas, ClaSeguimiento, ClaTipoMinuta, ClaColaborador, ClaFechaReporte, ClaPeriodo, FechaEstimadaTermina, ClaResponsableActividad, e.value);    
  }; 

  const ExportExcelMisActividades = () => {  
    const GetConsultaProyGral = `${config.UrlApiProject}ScoreCard/ExportarActividades`;   
    tracker.trackEvent({
      type: 'MisActividades', /* component name */
      action: 'EXPORT_MY_ACTIVITIES'
    }) 

    callApi(GetConsultaProyGral, 'POST', filters, (response) => {
      const linkSource = `data:application/${response.data.FileExtension};base64,${response.data.FileData}`;
      const downloadLink = document.createElement("a");
      const fileName = `${response.data.FileName}`;
      downloadLink.href = linkSource;
      downloadLink.download = `${fileName  }.${  response.data.FileExtension}`;
      downloadLink.click();
    });    
  };

  const obtenerMisActividades = (ClaProyecto, EsMostrarSoloAtrasadas, ClaSeguimiento, ClaTipoMinuta, ClaColaborador, ClaFechaReporte, ClaPeriodo, FechaEstimadaTermina, ClaResponsableActividad, ClaFase) => { 
    const filtersData = {};
    filtersData.ClaProyecto = ClaProyecto;
    filtersData.EsMostrarSoloAtrasadas = EsMostrarSoloAtrasadas;
    filtersData.EsMostrarSoloTareasMinutas = (ClaSeguimiento===3?1:0);
    filtersData.EsMostrarSoloTareasAP = (ClaSeguimiento===4?1:0)
    filtersData.EsMostrarSoloTareasProyectos = (ClaSeguimiento===2?1:0)
    filtersData.EsSoloMostrarTareasProgressReport = (ClaSeguimiento===5?1:0)
    filtersData.EsMostrarSoloMisTareas = 1;
    filtersData.EsMostrarSoloMisDependientes = 0; 
    filtersData.ClaTipoMinuta = ClaTipoMinuta;
    // Cuando tiene asociado un ClaResponsableActividad manda ese incluso si esta en My Team Activities, ya que el combo se desaparece...
    filtersData.ClaResponsable = props.IsMyTeamActivities ? ClaResponsableActividad>0 ? ClaResponsableActividad : ClaColaborador : ClaResponsableActividad;
    filtersData.EsMostrarKPIRedSinAP = props.IsMyTeamActivities ? EsMostrarKPIRedSinAP : false;
    filtersData.EsMostrarDependientes = props.IsMyTeamActivities ? 1 : 0; 
    filtersData.ClaFechaReporte = props.IsMyTeamActivities ? ClaFechaReporte :null;
    filtersData.ClaPeriodo = props.IsMyTeamActivities ? ClaPeriodo :null;
    filtersData.FechaEstimadaTermina = FechaEstimadaTermina === null ? null : FechaEstimadaTermina;
    filtersData.ClaUsuarioFilter = props.ClaUsuarioFilter === undefined ? null : props.ClaUsuarioFilter;
    filtersData.ClaFase = ClaFase;
     
    setfilters(filtersData);
  }

  const RefrescarInformacion = () => { 
    obtenerMisActividades(ClaProyectoNoMaestro, EsMostrarSoloAtrasadas, ClaSeguimiento, ClaTipoMinuta, ClaColaborador, ClaFechaReporte, ClaPeriodo, FechaEstimadaTermina, ClaResponsableActividad, ClaFase);
  }

  const onShowProjectDialog = (claProyecto) => {    
    setClaProyectoSelected(claProyecto);
    setIsProjectDialogVisible(true);
  };

  const onHideProjectDialog = () => {
    setIsProjectDialogVisible(false);
  }

  const renderProjectDialogFooter = () => {
    return (
      <>
        <FieldButton
          label={t('common:Close')}
          icon="fas fa-times"
          className="warning no-border p-mr-2 rounded-button"
          onClick={onHideProjectDialog}
        />
      </>
    );
  };

  const renderProjectDialogHeader = () => {
    return (
      <div>
        <Row>
          <Col lg={4} md={4} sm={12} xs={12}>            
            <div>
              <span>{t('common:EditProject')}</span>
            </div>
          </Col>          
        </Row>
      </div>
    );
  };

  const obtenerFechaReporte = (claPeriodo) => { 

    if (claPeriodo === null || typeof(claPeriodo) === 'undefined'){
      claPeriodo = -1
    }
    
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/GetMswBSCFechaReporte?ClaPeriodo=${claPeriodo}`;  
    const paramsToService = { 
      
    };   
    callApi(GetSummaryScorecardUser, 'GET', paramsToService, (response) => {  
        setFechaReporte(response.data[0]);   
    });
  }
  const obtenerPeriodo = () => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/GetMswBSCPeriodo`;  
    const paramsToService = { 
      
    }; 
    callApi(GetSummaryScorecardUser, 'GET', paramsToService, (response) => {   
        setPeriodo(response.data[0]);  
    });
  }

  const obtenerResponsablesProyecto = (claProyecto) => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/GetMswBSCColaboradorProyectosTeam`;  
    const paramsToService = { 
      ClaProyecto: claProyecto
    };   
    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => { 
      if (response.data[0].length >0)
      {  
        setOpcionesResponsables(response.data[0]);
      }  
    });
  }
  const obtenerFasesProyecto = (claProyecto) => { 
    const getMswFasesCmb = `${config.UrlApiProject}ScoreCard/GetMswFasesCmb?ClaProyecto=${claProyecto===null?0:claProyecto}`;
    const paramsToService = {};
    callApi(getMswFasesCmb, 'GET', paramsToService, (response) => {
      setOpcionesFase(response.data[0]);  
    });    
  }


    return (
      <>  
        {
        IsMyTeamActivities ?
        (
          <>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12} className="text-right">
                <div className="">           
                  <FieldButton label={t('misActividades:Export')} icon="fa fa-file-excel" className="secondary no-border" onClick={ExportExcelMisActividades}>              
                  </FieldButton> 
                </div>
              </Col>   
            </Row>
            <Row className="pt-3">
              <Col lg={4} md={4} sm={12} xs={12} hidden={ClaResponsableActividad>0}>
                <div className="p-fluid">
                  <FieldDropdown  
                    options={ColaboradorDependiente}  
                    value={ClaColaborador} 
                    optionValue="ClaColaborador"
                    optionLabel="NomColaborador" 
                    onChange={(e) => onChangeClaColaborador(e.value)}
                    label={t('misActividades:Responsible')}
                  />   
                </div> 
              </Col>
              <Col lg={4} md={4} sm={12} xs={12} hidden>   
                <div className="p-fluid">
                  <FieldDropdown  
                    name="ClaPeriodo" 
                    label={t('misActividades:Period')}
                    value={ClaPeriodo} 
                    options={Periodo}  
                    optionValue="ClaPeriodo"
                    optionLabel="NomPeriodo" 
                    onChange={(e) => onChangePeriodo(e)}
                    errors={errors}
                  />  
                </div>
              </Col> 
              <Col lg={4} md={4} sm={12} xs={12} hidden>   
                <div className="p-fluid">
                  <FieldDropdown  
                    name="ClaFechaReporte" 
                    label={t('misActividades:StatusDate')}
                    value={ClaFechaReporte} 
                    options={FechaReporte}  
                    optionValue="ClaFechaReporte"
                    optionLabel="NomFechaReporte" 
                    onChange={(e) => onChangeFechaReporte(e)}
                    errors={errors}
                  />  
                </div>
              </Col>   
            </Row> 
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}> 
          &nbsp;
              </Col> 
            </Row> 
          </>
        ):
        (
          <>
          </>
        )
      } 
        <Row hidden={IsMyTeamActivities}>
          <Col lg={12} md={12} sm={12} xs={12} className="text-right">
            <div className="">           
              <FieldButton label={t('misActividades:Export')} icon="fa fa-file-excel" className="secondary no-border" onClick={ExportExcelMisActividades}>              
              </FieldButton> 
            </div>
          </Col>    
        </Row>           
        <Row className="pt-3"> 
          <Col lg={3} md={3} sm={12} xs={12}>
            <div className="p-fluid">
              <FieldDropdown  
                options={TipoInformacion}  
                value={ClaSeguimiento} 
                optionValue="ClaSeguimiento"
                optionLabel="NombreSeguimiento" 
                onChange={(e) => onChangeClaSeguimiento(e.value)}
                label={t('misActividades:From')}
              />   
            </div> 
          </Col>
          {
              ClaSeguimiento===3 ?
              ( 
                <Col lg={3} md={3} sm={3} xs={3}>
                  <div className="p-fluid">
                    <FieldDropdown  
                      name="ClaTipoMinuta" 
                      label={t('misActividades:MeetingType')}
                      value={ClaTipoMinuta} 
                      options={TipoMinuta}  
                      optionValue="ClaTipoMinuta"
                      optionLabel="NomTipoMinuta" 
                      onChange={(e) => onChangeMeetingType(e)}
                      errors={errors}
                    />  
                  </div>
                </Col> 
              )
              :
              (<></>)
          }
          {
              ClaTipoMinuta===1 || ClaSeguimiento === 2 || ClaSeguimiento === 5 ?
              (
                <Col lg={3} md={3} sm={3} xs={3}>
                  <div className="p-fluid">
                    <FieldDropdown  
                      name="ClaProyecto" 
                      label={t('misActividades:Project')}
                      value={ClaProyectoNoMaestro} 
                      options={ProyectoNoMaestro}  
                      optionValue="ClaProyecto"
                      optionLabel="NomProyecto" 
                      onChange={(e) => onChangeProyectoNoMaestro(e)}
                      errors={errors}
                    />  
                  </div> 
                </Col>
              )
              :
              (<></>) 
          }
          <Col lg={3} md={3} sm={3} xs={3} hidden={true}>
            <div className="text-center">
              <FieldButton
                label={t('common:Search')}
                icon="pi pi-search" 
                className="light-green no-border p-mr-2 rounded-button"
                onClick={obtenerMisActividades}
              /> 
            </div> 
          </Col>  
          <Col lg={3} md={3} sm={12} xs={12}>
            <div className="p-fluid">
              <FieldCalendar
                name="FechaEstimadaTermina"
                value={FechaEstimadaTermina}
                label={t('misActividades:FechaEstimadaTermina')}
                onChange={(e) => onChangeFechaEstimadaTermina(props, e)}
              />
            </div>  
          </Col>   
        </Row>
        <Row className="pt-4">
          <Col lg={3} md={3} sm={3} xs={3}>
            <label>{t('misActividades:OnlyDelayed')}</label>&nbsp;
            <FieldToogleSwitch
              id="EsMostrarSoloAtrasadas"
              checked={EsMostrarSoloAtrasadas}
              onChange={onMostrarSoloAtrasadas} 
              optionLabels={yesNoOptions}
              small
            />
          </Col> 
          <Col lg={9} md={9} sm={3} xs={3} className="text-right" hidden>
            <label>{t('misActividades:ViewKPIRed')}</label>&nbsp;
            <FieldToogleSwitch
              id="EsMostrarKPIRedSinAP"
              checked={EsMostrarKPIRedSinAP}
              onChange={onMostrarKPISinAp} 
              optionLabels={yesNoOptions}
              small
            />
          </Col> 
          { 
            // Si esta seleccionado un proyecto en especifico.
            ClaSeguimiento === 2 && ClaProyectoNoMaestro > 0 ?
            (
              <>
                <Col lg={3} md={3} sm={3} xs={3}>
                  <div className="p-fluid">
                    <FieldDropdown  
                      name="ClaResponsableActividad"
                      value={ClaResponsableActividad}
                      options={OpcionesResponsables}  
                      optionValue="ClaColaborador"
                      optionLabel="NomColaborador" 
                      label={t('misActividades:Responsible')} 
                      onChange={(e) => onClaResponsableActividadChange(props,e)}
                      errors={errors}
                    />
                  </div> 
                </Col>
                <Col lg={3} md={3} sm={3} xs={3}>
                  <div className="p-fluid">
                    <FieldDropdown  
                      name="ClaFase"
                      value={ClaFase}
                      options={OpcionesFase}  
                      optionValue="ClaFase"
                      optionLabel="NomFase" 
                      label={t('misActividades:Phase')} 
                      onChange={(e) => onClaFaseChange(props,e)}
                      errors={errors}
                    />
                  </div> 
                </Col>
              </>

            )
            :
            (<></>)
          }       
           
        </Row>
        <Row className="pt-3 align-items-start">
          <Col lg={6} md={6} sm={12} xs={12}>
            <MisActividadesPendientes filters={filters} onChange={RefrescarInformacion} onOpenProject={onShowProjectDialog}></MisActividadesPendientes>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <MisActividadesEnProceso filters={filters} onChange={RefrescarInformacion} onOpenProject={onShowProjectDialog}></MisActividadesEnProceso>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <MisActividadesTerminadas filters={filters} onChange={RefrescarInformacion} onOpenProject={onShowProjectDialog}></MisActividadesTerminadas>
          </Col>
        </Row>
        <Dialog
          visible={IsProjectDialogVisible}
          modal
          style={{ width: '100vw' }}
          footer={renderProjectDialogFooter}
          header={renderProjectDialogHeader}
          onHide={onHideProjectDialog}
          closable={false}
          className="dialog-header-background-blue"
          maximized={true}
          draggable={false}
          blockScroll={true}
        >
          <EdicionProyecto
            ClaProyecto={ClaProyectoSelected} 
            EsMostrarEditarProyec={IsProjectDialogVisible}
          >
          </EdicionProyecto>
        </Dialog>
      </> 
        ); 
};
export default MisActividades;

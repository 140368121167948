/*eslint-disable*/
import React from 'react';
import sirius_star from '../../assets/img/sirius_star.png';

class Calendar extends React.Component {
  render() {
    return (
      <> 
        <div className="copyright">
         © {new Date().getFullYear()}. <b>Made for You</b> by DeAcero USA Digital Solutions <img alt="..." src={sirius_star} style={{ width: '18px', height: '15px' }}  />  
        </div>  
      </>
    );
  }
}

export default Calendar;

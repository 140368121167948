/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next';   
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row,Col, TabContent, TabPane , Nav, NavItem, NavLink} from 'reactstrap';   
import { config } from '../../../utils/config';
import { callApi,showSweetAlert} from '../../../utils/utils.js'; 
import FieldTextArea  from '../../../components/Controls/FieldTextArea.jsx';
import FieldText  from '../../../components/Controls/FieldText.jsx';
import FieldDropdown from 'components/Controls/FieldDropdown';
import { Checkbox } from 'primereact/checkbox';
import FieldCalendar  from 'components/Controls/FieldCalendar.jsx' 
import moment from "moment"; 
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import { Link } from 'react-router-dom';

const MisActividadesRevision = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['misActividades', 'common']);
  const [errors, setErrors] = useState({}); 
  const [NomProyecto, setNomProyecto] = useState(null); 
  const [NomFase, setNomFase] = useState(null); 
  const [NomActividad, setNomActividad] = useState(null); 
  const [Descripcion, setDescripcion] = useState(null); 
  const [FechaEstimadaInicio, setFechaEstimadaInicio] = useState(null); 
  const [FechaEstimadaFin, setFechaEstimadaFin] = useState(null); 
  const [FechaRealInicio, setFechaRealInicio] = useState(null); 
  const [FechaRealFin, setFechaRealFin] = useState(null); 
  const [ClaEstatus, setClaEstatus] = useState(null); 
  const [Comentarios, setComentarios] = useState(null); 
  const [ComentariosPM, setComentariosPM] = useState(null); 
  const [EsRevision, setEsRevision] = useState(null); 
  const [EstatusFasesActividad, setEstatusFasesActividad] = useState([]);

  useEffect(() => {    
    obtenerEstatusFaseActividad();
  }, []); 

  const obtenerEstatusFaseActividad = () => { 
    const GetStatusFasesActividad = `${config.UrlApiProject}ScoreCard/GetMSWEstatusFasesCmb`;  
    const paramsToService = {};   
    callApi(GetStatusFasesActividad, 'POST', paramsToService, (response) => { 
      if (response.data[0].length >0)
      {  
        setEstatusFasesActividad(response.data[0]);
        obtenerActividad();
      }  
    });
  }

  const obtenerActividad = () => { 
    const GetActivityURL = `${config.UrlApiProject}ScoreCard/GetFinalizarPendienteModalInfo`;  
    const paramsToService = { 
      ClaProyecto:props.ClaProyecto,
      ClaFase:props.ClaFase, 
      ClaActividad:props.ClaActividad
    };   
    callApi(GetActivityURL, 'POST', paramsToService, (response) => { 
      const ClaseActividad = response.data.Info[0];
        setNomActividad(ClaseActividad.NomActividad); 
        setDescripcion(ClaseActividad.Descripcion); 
        setFechaEstimadaInicio((ClaseActividad.FechaEstimadaInicio!== null ? (new Date(ClaseActividad.FechaEstimadaInicio)): null)); 
        setFechaEstimadaFin((ClaseActividad.FechaEstimadaFin!== null ? (new Date(ClaseActividad.FechaEstimadaFin)): null)); 
        setFechaRealInicio((ClaseActividad.FechaRealInicio!== null ? (new Date(ClaseActividad.FechaRealInicio)): null)); 
        setFechaRealFin((ClaseActividad.FechaRealFin!== null ? (new Date(ClaseActividad.FechaRealFin)): null)); 
        setComentarios(ClaseActividad.Comentarios);
        setComentariosPM(ClaseActividad.ComentariosPM);
        setClaEstatus(2); // por default dejamos seleccionado Finished
        setNomProyecto(ClaseActividad.NomProyecto);
        setNomFase(ClaseActividad.NomFase);
    });
  }

  const onChangeComentarios = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.Comentarios;   
    setErrors(newErrors) 
    setComentarios(e.target.value)
  }

  const onChangeComentariosPM = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.ComentariosPM;   
    setErrors(newErrors) 
    setComentariosPM(e.target.value)
  }


  const onFechaRealInicioChange = ( e ) => {  
    const newErrors = { ...errors }
    delete newErrors.FechaRealInicio;   
    setErrors(newErrors);
    setFechaRealInicio(e.value);
  }; 

  const onFechaRealFinChange = ( e ) => {  
    const newErrors = { ...errors }
    delete newErrors.FechaRealFin;   
    setErrors(newErrors);
    setFechaRealFin(e.value);
  }; 

  const valitationSchemeFinish = yup.object().shape({
    ClaEstatus: yup.mixed().required(t('common:RequiredField')).nullable(),
    ComentariosPM: (
      (ClaEstatus !== 2) ? 
      yup.string().required(t('common:RequiredField')).nullable() 
      : yup.string().nullable()
      ),
  });  

  const FinalizarActividad = async () => {
    const value = {
      ClaEstatus,
      ComentariosPM
    }
    const validator = new YupValidator(valitationSchemeFinish);
    const esValido = await validator.validate(value);
    if (!esValido){ 
      setErrors(validator.errors); 
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      return;
    }
    const urlFinalizarService = `${config.UrlApiProject}ScoreCard/RevisionActividadesFases`;
    const paramsService = {
      ClaProyecto:props.ClaProyecto,
      ClaFase:props.ClaFase, 
      ClaActividad:props.ClaActividad,
      ClaEstatus,
      ComentariosPM,
      EsRevision: EsRevision ? 1 : 0
    }; 

    console.log(paramsService);
    callApi(urlFinalizarService, 'POST', paramsService, (response) => { 
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      if(props.onClickCloseFinalizar){
        props.onClickCloseFinalizar();
      }
    });
  }

  const onEsRevisionChange = (e) => {  
    setEsRevision(e.checked);
 }

 const onEstatusChange = (value) => { 
  const newErrors = { ...errors } 
  delete newErrors.ClaEstatus;   
  if(value===2){
    delete newErrors.ComentariosPM;   
  }
  setErrors(newErrors);
  setClaEstatus(value);
}; 

    return (
      <>     
        <Row className="pt-1 pb-1 bold">
          <Col lg={6} md={6} sm={6} xs={6}>
            <Row className="pt-1 bold">
              <Col lg={12} md={12} sm={12} xs={12}>
                {NomActividad} 
              </Col>   
            </Row> 
            <Row className="pt-1 bold">
              <Col lg={12} md={12} sm={12} xs={12}> 
                <span className="fa fa-folder"> </span> {NomProyecto} 
                <span hidden={NomFase===null || NomFase === ''}>- {NomFase}</span>
              </Col>  
            </Row> 
            <Row className="pt-1 bold">
              <Col lg={12} md={12} sm={12} xs={12}> 
                <span className="bold"> 
                  <i className="pi pi-calendar"></i> &nbsp; 
                  {moment(FechaEstimadaInicio).format("ll")} 
                </span>
                <span>
                  - {moment(FechaEstimadaFin).format("ll")}
                </span>
              </Col>
            </Row> 
          </Col>  
          <Col lg={6} md={6} sm={6} xs={6}> 
            <Row className="pt-2">
              <Col lg={6} md={6} sm={6} xs={6} className="secondary-color">
                <div className="p-fluid">
                  <div className="p-field"> 
                    <FieldCalendar
                      name="FechaRealInicio" 
                      label={t('misActividades:StartDate')}
                      value={(FechaRealInicio!== null ? (new Date(FechaRealInicio)): null)} 
                      onChange={(e) => onFechaRealInicioChange(e)} 
                      disabled={true}
                      errors={errors}
                    />
                  </div> 
                </div> 
              </Col>  
              <Col lg={6} md={6} sm={6} xs={6}> 
                <div className="p-fluid">
                  <div className="p-field"> 
                    <FieldCalendar
                      name="FechaRealFin" 
                      label={t('misActividades:FechaFin')}
                      value={(FechaRealFin!== null ? (new Date(FechaRealFin)): null)} 
                      onChange={(e) => onFechaRealFinChange(e)} 
                      disabled={true}
                      errors={errors}
                    />
                  </div> 
                </div> 
              </Col>  
            </Row>
          </Col>  
        </Row>   
        <Row className="pt-1">
          <Col>
            <div className="p-fluid">
              <span className="pb-1">
                {t('misActividades:Comments')}
              </span>
              <FieldTextArea 
                name="Comentarios"
                rows={2}
                value={Comentarios} 
                onChange={onChangeComentarios}
                disabled={true}
                errors={errors}
              /> 
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <hr></hr>
          </Col>
        </Row>
        <Row className="pt-1">
          <Col>
            <div className="p-fluid">
              <span className="pb-1">
                {t('misActividades:PMComments')}
              </span>
              <FieldTextArea 
                name="ComentariosPM"
                rows={2}
                value={ComentariosPM} 
                onChange={onChangeComentariosPM}
                errors={errors}
                maxLength={500}
              />
            </div>
          </Col>
        </Row>
        <Row className="pt-1 pb-2">
          <Col>
            <div className="p-fluid">
              <span className="pb-1">
                {t('misActividades:Status')}
              </span>
              <FieldDropdown  
                name="ClaEstatus"
                value={ClaEstatus} 
                options={EstatusFasesActividad}  
                optionValue="ClaEstatusFase"
                optionLabel="NomEstatusFase" 
                onChange={(e) => onEstatusChange(e.value)}
                errors={errors}
              />   
            </div>
          </Col>
        </Row>
        <Row className="pt-1">
          <Col lg={3} md={3} sm={3} xs={3}>
            <div className="p-field-checkbox">
              <Checkbox
                inputId="EsRevision" 
                checked={EsRevision} 
                onChange={(e) => onEsRevisionChange(e)}
              />
              <label htmlFor="EsRevision"> {t('misActividades:Revision')}</label>
            </div>
          </Col>
        </Row>
        <Row className="pt-1">
          <Col className="text-right">
            <FieldButton label={t('misActividades:Finish')} onClick={() => FinalizarActividad()} className="success no-border pr-3 pl-3 btn-min-width"  /> 
              
          </Col>
        </Row>
      </> 
        ); 
};
export default MisActividadesRevision;

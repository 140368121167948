/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState, useEffect }  from 'react';
import PropTypes from 'prop-types';  
import { Row, Col, Card, CardBody, CardHeader, CardTitle, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';   
import { useTranslation } from 'react-i18next';  
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import FieldTextArea from 'components/Controls/FieldTextArea.jsx';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import { Dialog } from 'primereact/dialog';  
import './FieldKeyboard.css';

const FieldKeyboard =  (props) => {  
    
    const [layoutName, setLayoutName] = useState('');
    const [FieldKeyboardValue, setFieldKeyboardValue] = useState(props.value !== null ? props.value.toString() : '');
    const keyboardReference = useRef();
    const {t, i18n} = useTranslation(['common']); 
    const [IsNeedReadInitialValue, setIsNeedReadInitialValue] = useState(true);

    
    useEffect(() => {
      console.log("useEffect FieldKeyboard");
      keyboardReference.current.setInput(FieldKeyboardValue);
    }, [FieldKeyboardValue]);

    const onAcceptClick = () => { 
      if (props.onChange !== null && typeof props.onChange === 'function') { 
        props.onChange(props.isNumeric === true ? FieldKeyboardValue === '' ? null : FieldKeyboardValue : FieldKeyboardValue);
      }

      if (props.onHide !== null && typeof props.onHide === 'function') {
        props.onHide();
      }
    }

    const onClearClick = () => {
      setFieldKeyboardValue('');  
    }

    const onChangeKeyboard = (input) => {  

      let result = null;

      if (props.isNumeric){ 
         result = input.replace(/[^0-9.]/g, '');
        // no more than one dot is accepted;
         result = result.split(".", 2).join("."); 
      }else {
        result = input;
      }
      setFieldKeyboardValue(result);   
    };
  
    const onKeyPressKeyboard = (button) => {  
      /**
       * If you want to handle the shift and caps lock buttons
       */
      /*
      if (button === "{shift}" || button === "{lock}") 
      {
        handleShift();
      }
  
      if (button === "{numbers}" || button === "{abc}") {
        handleNumbers();
      } 
      */  
      if (button.includes("{") && button.includes("}")) {
        handleLayoutChange(button);
      } 
    };

    const handleShift = () => {  
      const currentLayout = keyboardReference.current.options.layoutName;
      const shiftToggle = currentLayout === "default" ? "shift" : "default";
  
       
      setLayoutName(shiftToggle);
  
      keyboard.current.setOptions({
        layoutName: shiftToggle
      }); 
    };
  
    const handleNumbers = () => {
      const currentLayout = keyboardReference.current.options.layoutName;
      const numbersToggle = currentLayout !== "numbers" ? "numbers" : "default";
    
      setLayoutName(numbersToggle);
      keyboardReference.current.setOptions({
        layoutName: numbersToggle
      });
    }

    const onChangeFieldKeyboardValue = (e) => {  
        let result = null;

        if (props.isNumeric){ 
          result = e.target.value.replace(/[^0-9.]/g, '');
          // no more than one dot is accepted;
           result = result.split(".", 2).join("."); 
        }else {
          result = e.target.value;
        }
        setFieldKeyboardValue(result);   
    } 
    
const handleLayoutChange = (button) => {
  const currentLayout = keyboardReference.current.options.layoutName;
  let newLayoutName;

  switch (button) {
    case "{shift}":
    case "{shiftactivated}":
    case "{default}":
      newLayoutName = currentLayout === "default" ? "shift" : "default";
      break;

    case "{alt}":
    case "{altright}":
      newLayoutName = currentLayout === "alt" ? "default" : "alt";
      break;

    case "{smileys}":
      newLayoutName = currentLayout === "smileys" ? "default" : "smileys";
      break;

    default:
      break;
  }

  if (newLayoutName) {
    setLayoutName(newLayoutName);
    keyboardReference.current.setOptions({
      layoutName: newLayoutName
    });
  }
}

const renderHeader = (
  <div>
    <Row>
      <Col lg={10} md={10} sm={10} xs={10}>
        <span>{props.label}</span>
      </Col> 
      <Col lg={2} md={2} sm={2} xs={2} className='text-right'>
        <FieldButton
          type="button"
          onClick={() => onHideKeyboard()} 
          className="no-border white-color pi pi-times"
          style={{ marginRight: '.25em', background: "transparent", width: '30px' }}
        />
      </Col>
    </Row>
  </div>
)

const renderFooter = ( 
  <div>
    <Row>
      <Col>
        <div className="p-fluid p-field p-col-12"> 
          <FieldButton
            type="button"
            onClick={() => onClearClick()}
            label={t('common:Clear')}
            className="p-button-danger no-border p-mr-2 rounded-button"
            style={{ marginRight: '.25em', width: '100%' }}
          />
        </div>
      </Col>
      <Col>
        <div className="p-fluid p-field p-col-12"> 
          <FieldButton
            type="button"
            onClick={() => onAcceptClick()}
            label={t('common:Accept')}
            className="p-button-success no-border p-mr-2 rounded-button"
            style={{ marginRight: '.25em', width: '100%' }}
          />
        </div>
      </Col>
    </Row> 
  </div>
  )

const onHideKeyboard = () => { 
  if (props.onHide !== null && typeof props.onHide === 'function') {
    props.onHide();
  }
};  
 
    return (  
      <>   
        <div className={`p-dialog-mask p-component-overlay p-component-overlay-enter p-dialog-visible p-dialog-resizable p-dialog-center ${!props.show ? "keyboard-hidden" : ""}`} style={{zIndex: "1101"}}>
          <div className='p-dialog p-component dialog-header-background-blue p-dialog-maximized p-dialog-enter-done'>

            <div className='p-dialog-header'>
              <div className='p-dialog-title'>
                {renderHeader}
              </div>
            </div>
            <div className="p-dialog-content">
              <div className="p-fluid p-field p-col-12"> 
                {
            /*
              <FieldTextArea  
                name="FieldKeyboardValue"
                value={FieldKeyboardValue} 
                onChange={onChangeFieldKeyboardValue}
                maxLength={250}
                rows={8} 
              />  
            */
          }
          
                <textarea 
                  value={FieldKeyboardValue} 
                  onChange={onChangeFieldKeyboardValue}
                  className="p-inputtextarea p-inputtext p-component p-filled"
                  rows={8} 
                  autoFocus={true} 
                />
              </div>
              {
            props.isNumeric === true ?
            (
              <>
                <Keyboard 
                  keyboardRef={r => (keyboardReference.current = r)}  
                  layoutName={layoutName}
                  mergeDisplay={true}
                  newLineOnEnter={true}   
                  theme="hg-theme-default hg-layout-numeric numeric-theme"
                  layout={{
                      default: ["1 2 3", "4 5 6", "7 8 9", ". 0 {bksp}"],
                      shift: ["! / #", "$ % ^", "& * (", "{shift} ) +", "{bksp}"],
                       
                    }}
                  onChange={onChangeKeyboard}
                  onKeyPress={onKeyPressKeyboard} 
                  display={{
                      "{alt}": ".?123",
                      "{smileys}": "\uD83D\uDE03",
                      "{shift}": "Shift",
                      "{shiftactivated}": "⇧",
                      "{enter}": "Enter",
                      "{bksp}": "⌫",
                      "{altright}": ".?123",
                      "{downkeyboard}": "🞃",
                      "{space}": "Space",
                      "{default}": "ABC",
                      "{back}": "⇦"
                    }}
                /> 
              </>
            )
            :
            (
              <>
                <Keyboard 
                  keyboardRef={r => (keyboardReference.current = r)} 
                  layoutName={layoutName}
                  mergeDisplay={true}
                  newLineOnEnter={true} 
                  layout={{
                        default: [
                          "q w e r t y u i o p {bksp}",
                          "a s d f g h j k l {enter}",
                          "{shift} z x c v b n m , . {alt} ",
                          "{space}"
                        ],
                        shift: [
                          "Q W E R T Y U I O P {bksp}",
                          "A S D F G H J K L {enter}",
                          "{shiftactivated} Z X C V B N M , . {alt}",
                          "{space}"
                        ],
                        alt: [
                          "1 2 3 4 5 6 7 8 9 0 {bksp}",
                          `@ # $ & * ( ) ' " {enter}`,
                          "{shift} % - + = / ; : ! ? {shift}",
                          "{default} {space} "
                        ],
                        smileys: [
                          "😀 😊 😅 😂 🙂 😉 😍 😛 😠 😎 {bksp}",
                          `😏 😬 😭 😓 😱 😪 😬 😴 😯 {enter}`,
                          "😐 😇 🤣 😘 😚 😆 😡 😥 😓 🙄 {shift}",
                          "{default} {space} {altright} {downkeyboard}"
                        ]
                      }}
                  onChange={onChangeKeyboard}
                  onKeyPress={onKeyPressKeyboard} 
                  display={{
            "{alt}": ".?123",
            "{smileys}": "\uD83D\uDE03",
            "{shift}": "⇧",
            "{shiftactivated}": "⇧",
            "{enter}": "Enter",
            "{bksp}": "⌫",
            "{altright}": ".?123",
            "{downkeyboard}": "🞃",
            "{space}": "Space",
            "{default}": "ABC",
            "{back}": "⇦"
          }}
                /> 
              </>
)
          } 
            </div>
            <div className="p-dialog-footer">
              {renderFooter}
            </div>
          </div>
        </div>
      </> 
    )
}    

export default FieldKeyboard; 

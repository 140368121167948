/* eslint-disable import/named */
import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
// react library for routing
import { Router, Route, Switch, useLocation } from 'react-router-dom';
import ErrorHandler from './utils/errorHandler';

// plugins styles from node_modules
 import 'react-notification-alert/dist/animate.css';
 import 'react-perfect-scrollbar/dist/css/styles.css';
// plugins styles downloaded
 import 'assets/vendor/fullcalendar/dist/fullcalendar.min.css';
 import 'assets/vendor/sweetalert2/dist/sweetalert2.min.css';
 import 'assets/vendor/select2/dist/css/select2.min.css';
 import 'assets/vendor/quill/dist/quill.core.css';
 import 'assets/vendor/nucleo/css/nucleo.css';
 import 'assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
// core styles
 import 'assets/scss/argon-dashboard-pro-react.scss?v1.1.0';
// DevExtreme
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.material.blue.light.css';
import './assets/css/dx.material.identidad-deacero.css'; 
import 'devexpress-gantt/dist/dx-gantt.min.css';  
// primereact
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css'; 
import 'primeflex/primeflex.css';

// Propias
 import './assets/css/karmaBase.css';
 import './assets/css/app.css';

// Componentes
import Login from './views/Login/Login';

// Context
import AppContextProvider from './context/AppContext';
import useInitialState from './hooks/useInitialState'; 
import './i18n'; 
import Session from './components/Session/Session.jsx';
import { tracker } from 'utils/utils';

const hist = createBrowserHistory();

function usePageViews() {
  const location = useLocation();
  React.useEffect(() => {    
    tracker.trackEvent({ 
      type: location.pathname, /* component name */
      action: 'PAGE_VIEW'
    });
  }, [location.pathname]);
}

function AppRoutes() {
  usePageViews();
  return (
    <Switch>        
      <Route path="/" render={(props) => <Login {...props} />} />       
    </Switch>
  )
}

const App = () => {
  const initialState = useInitialState(); 
  
  return (
    <div>
      <Session></Session>
      <AppContextProvider value={initialState}>
        <ErrorHandler>
          <Router history={hist}>
            <AppRoutes></AppRoutes>
          </Router>
        </ErrorHandler>
      </AppContextProvider>
    </div>
    
  );
};

ReactDOM.render(<App />, document.getElementById('root'));  
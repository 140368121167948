/* eslint-disable jsx-a11y/label-has-associated-control */
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import FieldDateRange from 'components/Controls/FieldDateRange/FieldDateRange';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldMultiSelect from 'components/Controls/FieldMultiSelect';
import PageContent from 'layouts/PageContent';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { config } from 'utils/config';
import { callApi, showSweetAlert } from 'utils/utils';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import CompanyOverviewActiveCellsChart from './CompanyOverviewActiveCellsChart/CompanyOverviewActiveCellsChart';
import CompanyOverviewAvailabilityChart from './CompanyOverviewAvailabilityChart/CompanyOverviewAvailabilityChart';
import CompanyOverviewOEEChart from './CompanyOverviewOEEChart/CompanyOverviewOEEChart';
import CompanyOverviewOverallStatusChart from './CompanyOverviewOverallStatusChart/CompanyOverviewOverallStatusChart';
import CompanyOverviewPerformanceChart from './CompanyOverviewPerformanceChart/CompanyOverviewPerformanceChart';
import CompanyOverviewProductionRateChart from './CompanyOverviewProductionRateChart/CompanyOverviewProductionRateChart';
import './CompanyOverviewScreen.scss';
import CompanyOverviewUtilizationChart from './CompanyOverviewUtilizationChart/CompanyOverviewUtilizationChart';

const CompanyOverviewScreen = (props) => {
  const {t, i18n} = useTranslation(['formulario', 'companyOverview', 'common']);  
 
  const [ShiftsList, setShiftsList] = useState([]);
  const [SelectedShifts, setSelectedShifts] = useState(null);  
  const [SelectedShiftsString, setSelectedShiftsString] = useState(null);    
  const [Refresh, setRefresh] = useState(0);  
  const [errors, setErrors] = useState({}); 
  
  // Get today's date
  const today = new Date();

  // Get yesterday's date by subtracting 1 day from today
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const [StartDate, setStartDate] = useState(yesterday);
  const [EndDate, setEndDate] = useState(yesterday);

  const ReportTypes = [
    { type: 1, name: t('dgt:BULKPLANT') },
    { type: 2, name: t('dgt:COLLATED') },
    { type: 4, name: t('dgt:PAPERTAPE') }
  ];
  const [ReportType, setReportType] = useState(ReportTypes[0].type);
  const valitationScheme = yup.object().shape({
    SelectedShifts: yup.mixed().required('').nullable(),
    ReportType: yup.mixed().required('').nullable()
  });

  const getShifts = () => {
    const url = `${config.UrlApiProject}turno/getTurnos`;
    const params = {};
    callApi(url, 'GET', params, (response) => {
      const shifts = response.data.Turnos;      
      setShiftsList(shifts); 
      setSelectedShifts(shifts);
    });      
  };

  const onReportTypeChange = (value) => {
    const _errors = { ...errors } 
    delete _errors.ReportType;   
    setErrors(_errors)
    setReportType(value);
  };

  const onDateRangeChange = (startDate, endDate) => {    
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const onShiftChange = (e) => {       
    const _errors = { ...errors } 
    delete _errors.SelectedShifts;   
    setErrors(_errors)
    setSelectedShifts(e.value.length < 1? null: e.value);  
  };

  const onSearchPress = async (e) => {    
    const value = {
      SelectedShifts,
      ReportType
    };
    const validator = new YupValidator(valitationScheme);
    const isValid = await validator.validate(value);
    if (!isValid){             
      setErrors(validator.errors); 
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
      return
    }  
    if (Array.isArray(SelectedShifts) && SelectedShifts.length > 0) {
      const selectedShifts = SelectedShifts.map(selectedShift => selectedShift.ClaTurno);
      setSelectedShiftsString(selectedShifts.join(','));
    }

    setRefresh(Refresh + 1);
  };
  
  const toolbarTemplate = (
    <>
      
      <div className="p-fluid pr-2"> 
        <FieldDateRange           
          startDate={StartDate}
          endDate={EndDate}
          onDateRangeChange={onDateRangeChange}
          maxDate={yesterday}
        >            
        </FieldDateRange> 
      </div>  
      <div className="CompanyOverviewScreen-filterWrapper p-fluid pr-2">
        <FieldMultiSelect
          name="SelectedShifts"
          optionLabel="NomTurno"
          className='CompanyOverviewScreen-multiSelect'
          value={SelectedShifts}
          options={ShiftsList}
          onChange={(e) => onShiftChange(e)}
          label={t('formulario:Shift')}  
          errors={errors}    
        >
        </FieldMultiSelect>
      </div>       
      <div className="CompanyOverviewScreen-filterWrapper p-fluid pr-2">
        <FieldDropdown
          name="ReportType"
          value={ReportType} 
          options={ReportTypes}                
          optionValue="type"
          optionLabel="name" 
          onChange={(e) => onReportTypeChange(e.value)}              
          editable={false}
          showClear={false}
          errors={errors}    
        />   
      </div>   
      <div className="p-fluid">
        <FieldButton
          onClick={() => onSearchPress()}
          className="p-button"
          icon="pi pi-search"
          label="Search"          
        />
      </div>  
    </>
  );

  useEffect(() => {          
    setStartDate(yesterday);
    setEndDate(yesterday);
    getShifts();
  }, []);

  return (
    <PageContent       
      title={t('companyOverview:CompanyOverview')}      
      toolBar={toolbarTemplate}
    >
      {
        Refresh > 0 && (
          <>
            <Row className='align-items-start'>  
              <Col xs={12} sm={12} md={12} lg={10}>
                <Row className='align-items-start'>
                  <Col xs={12} sm={12} md={12} lg={6}>  
                    <CompanyOverviewUtilizationChart
                      reportType={ReportType}
                      shifts={SelectedShiftsString}
                      startDate={StartDate}
                      endDate={EndDate}
                      refresh={Refresh}
                    />
                  </Col> 
                  <Col xs={12} sm={12} md={12} lg={6}>  
                    <CompanyOverviewOEEChart
                      reportType={ReportType}
                      shifts={SelectedShiftsString}
                      startDate={StartDate}
                      endDate={EndDate}
                      refresh={Refresh}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={6}>  
                    <CompanyOverviewPerformanceChart
                      reportType={ReportType}
                      shifts={SelectedShiftsString}
                      startDate={StartDate}
                      endDate={EndDate}
                      refresh={Refresh}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={6}>  
                    <CompanyOverviewAvailabilityChart
                      reportType={ReportType}
                      shifts={SelectedShiftsString}
                      startDate={StartDate}
                      endDate={EndDate}
                      refresh={Refresh}
                    />
                  </Col> 
                  <Col xs={12} sm={12} md={12} lg={{size: 6, offset: 6}}>
                    <CompanyOverviewProductionRateChart
                      reportType={ReportType}
                      shifts={SelectedShiftsString}
                      startDate={StartDate}
                      endDate={EndDate}
                      refresh={Refresh}
                    />
                  </Col>  
                </Row>   
              </Col>
              <Col xs={12} sm={12} md={12} lg={2} style={{height:'100%'}}>
                <Row className='align-items-start'>
                  <Col xs={12} sm={6} md={6} lg={12}>
                    <CompanyOverviewActiveCellsChart
                      reportType={ReportType}
                      shifts={SelectedShiftsString}
                      startDate={StartDate}
                      endDate={EndDate}
                      refresh={Refresh}
                    />   
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={12}>
                    <CompanyOverviewOverallStatusChart
                      reportType={ReportType}
                      shifts={SelectedShiftsString}
                      startDate={StartDate}
                      endDate={EndDate}
                      refresh={Refresh}
                    />
                  </Col>  
                </Row>                         
              </Col>
            </Row>
          </>
        )
      }
    </PageContent>
  )
}

export default CompanyOverviewScreen
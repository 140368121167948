/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row, Col, Card, CardBody } from 'reactstrap'; 
import PageContent from 'layouts/PageContent.jsx'; 
import PriceTracker from "./PriceTracker";
import './PriceTrackerScreen.scss';   
import PriceTrackerMap from './PriceTrackerMap.jsx';

const PriceTrackerScreen = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['priceTracker', 'common']);
 
  const [ClaUsuario, setClaUsuario] = useState(null);  
  const [IsLoading, setIsLoading] = useState(true);

  useEffect(() => { 
     
    setIsLoading(false);
  }, []); 
  
 

  if (IsLoading){
    return (<>Loading...</>);
  }

  return (
    <>
      <div className='PriceTrackerScreen'>
        <PageContent title={t('priceTracker:PriceTracker')}>
          <Row className="align-items-start">
            <Col lg={12} md={12} sm={12} xs={12}>
              <div className='h-100'> 
                <PriceTracker></PriceTracker>
              </div>
            </Col> 
          </Row>   
        </PageContent>
      </div>
    </>
  );
};
export default PriceTrackerScreen;

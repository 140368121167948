/* eslint-disable jsx-a11y/label-has-associated-control */
import React , {useEffect, useState} from 'react';  
import { config } from '../../utils/config';
import { callApi, callKrakenApi } from '../../utils/utils';  
import '../../assets/css/PivotGridBase.css';
import { Dropdown } from 'primereact/dropdown';  
import FieldButton from '../../components/Controls/FieldButton/FieldButton.jsx';
import { MultiSelect } from 'primereact/multiselect'; 
import { ToggleButton } from 'primereact/togglebutton';  
import { useTranslation } from 'react-i18next'; 
import FieldDropdown from '../../components/Controls/FieldDropdown.jsx' 
import FieldSelectButton from '../../components/Controls/FieldSelectButton.jsx' 
import * as yup from 'yup';
import YupValidator from '../../utils/YupValidator';

const PocketMarginFilters =  (props) => {
     
    const { t, i18n } = useTranslation(['formulario','common']);
    const [Anio, setAnio] = useState(props.defultFilters.Anio !== undefined ? props.defultFilters.Anio : null);
    const [Divisiones, setDivisiones] = useState(props.defultFilters.Divisiones !== undefined ? props.defultFilters.Divisiones : null);
    const [DivisionesList, setDivisionesList] = useState(props.defultFilters.DivisionesList !== undefined ? props.defultFilters.DivisionesList : null); 
    const [EsUltimos12Meses, setEsUltimos12Meses] = useState(props.defultFilters.EsUltimos12Meses !== undefined ? props.defultFilters.EsUltimos12Meses : false);
    const [OpcionesAnios, setOpcionesAnios] = useState([]);
    const [OpcionesDivision, setOpcionesDivision] = useState([]);
    const [OpcionesYesNo, setOpcionesYesNo] = useState([{ value: 1, name: "YES"}, { value: 0, name: "NO" }]);
    // de momento se requiere que EsMostrarSoloConfiables este apagado....
    const [EsMostrarSoloConfiables, setEsMostrarSoloConfiables] = useState(props.defultFilters.EsMostrarSoloConfiables !== undefined ? props.defultFilters.EsMostrarSoloConfiables : 0);
    
    const [errors, setErrors] = useState({});   
     
    useEffect(() => {
        let urlWebService = `${config.UrlApiProject}Rentabilidad/GetDivisions`; 

        let paramsService = null; 
        paramsService = {AnioMes:0};
        
        callApi(urlWebService, 'POST', paramsService, (response) => {
            setOpcionesDivision (response.data.Divisiones);
            // obtenemos los departamentos 
            urlWebService = `${config.UrlApiProject}Rentabilidad/GetAnios`; 
            
            callApi(urlWebService, 'GET', paramsService, (response) => {
                setOpcionesAnios(response.data.Anios);  
                
            });    
        });  
    }, []); 
    
    const setSelectedDivision = ( value ) => {  
        const newErrors = { ...errors } 
        delete newErrors.Divisiones;   
        setErrors(newErrors)

        setDivisiones(value);
        setDivisionesList(value !== undefined ? value : ''); 
    }; 

    const setSelectOnlyReliable = ( value ) => {  
      const newErrors = { ...errors } 
      delete newErrors.EsMostrarSoloConfiables;   
      setErrors(newErrors)

      setEsMostrarSoloConfiables(value); 
  }; 

    const onAnioChange = ( value ) => {  
      const newErrors = { ...errors } 
      delete newErrors.Anio;   
      setErrors(newErrors)

      setAnio(value); 
  }; 

    const valitationScheme = yup.object().shape({
      Anio: yup.string().required(t('common:RequiredField')).nullable(),
      Divisiones: yup.string().required(t('common:RequiredField')).nullable() ,
      EsMostrarSoloConfiables: yup.string().required(t('common:RequiredField')).nullable() 
    });
   
    
    const onApplyFilters = async ( ) => {   
        // ejecutamos validaciones....
        const value = {
          Anio,
          Divisiones,
          EsMostrarSoloConfiables
        }
        const validator = new YupValidator(valitationScheme);
        const esValido = await validator.validate(value);
        if (!esValido){ 
          setErrors(validator.errors); 
          return;
        }
        
        if (props.onSearch){  
             
            const filtersData = {}
            filtersData.Anio             = Anio;
            filtersData.Divisiones       = Divisiones;
            filtersData.DivisionesList   = DivisionesList;
            filtersData.EsUltimos12Meses = EsUltimos12Meses;
            filtersData.EsMostrarSoloConfiables = EsMostrarSoloConfiables;

            console.log(filtersData);

            props.onSearch( filtersData );
        } 
     };
      
    return (
      <>   
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-12 p-md-12">
            <h1>{t('common:Filters')}</h1>
          </div>
          <div className="p-field p-col-5">
            <label>{t('common:Year')}</label>
            <FieldDropdown 
              name="Anio"
              value={Anio} 
              options={OpcionesAnios} 
              onChange={(e) => onAnioChange(e.value)} 
              optionValue="ClaAnio"
              optionLabel="NomAnio" 
              placeholder={t('common:SelectYear')}
              errors={errors}
            /> 
          </div> 
          <div className="p-field p-col-7">
            <label>{t('formulario:ShowLast12Month')}</label>
            <ToggleButton checked={EsUltimos12Meses} onChange={(e) => setEsUltimos12Meses(e.value)} onIcon="pi pi-check" offIcon="pi pi-times" />
          </div>
          <div className="p-field p-col-12">
            <label>{t('formulario:Division')}</label>
            <FieldDropdown 
              name="Divisiones"
              value={Divisiones} 
              options={OpcionesDivision} 
              onChange={(e) => setSelectedDivision(e.value)} 
              optionValue="ClaGerenciaRegional"
              optionLabel="NomGerenciaRegional" 
              placeholder={t('formulario:FilterByDivision')}
              errors={errors}
            /> 
          </div> 
          <div className="p-field p-col-12" style={{ display: "none"}}>
            <label>{t('formulario:ShowOnlyReliable')}</label>
            <FieldSelectButton 
              name="EsMostrarSoloConfiables"
              value={EsMostrarSoloConfiables} 
              options={OpcionesYesNo} 
              onChange={(e) => setSelectOnlyReliable(e.value)} 
              optionValue="value"
              optionLabel="name"  
              errors={errors}
            /> 
          </div> 
          <div className="p-field p-col-12 p-md-12">
            <FieldButton type="button" onClick={(e) => onApplyFilters()} label={t('common:ApplyFilter')} className="p-button-success" style={{ marginRight: '.25em' }} /> 
            
          </div>
        </div>  
      </>
)
}  
 
export default PocketMarginFilters;   
 

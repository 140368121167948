/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'; 
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardBody } from 'reactstrap';
import './DigitalViewDashboard.scss';
import { Skeleton } from 'primereact/skeleton';

const DigitalViewDashboardMachineCardSkeleton = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);
 
  return (
    <>
      <Row>
        <Col>
          <Card className='cell-card'>
            <CardBody>
              <Row className='align-items-start'>
                <Col xs={props.ShowAssetView? 6: 12}>
                  <div className='card-cell-name cell-header-title-skeleton'>            
                    <Skeleton width="10rem" className="p-mb-2 m-2"></Skeleton>
                  </div>
                  <div className='cell-header-container-skelethon card-cell-sub-header-skeleton'>
                    <Skeleton className='m-2' width="100%" height="4rem"></Skeleton>
                  </div>
                  <div 
                    className='cell-header-container-skelethon card-cell-sub-header-skeleton'
                    style={{height: '200px'}}
                  >
                    <Skeleton className='mr-2 ml-2' width="100%" height="100%"></Skeleton>
                  </div>
                  <div className='cell-header-container-skelethon card-cell-sub-header-skeleton'>                    
                    <Skeleton className='m-2' width="100%" height="4rem"></Skeleton> 
                  </div>
                </Col>
                {
                  props.ShowAssetView && (
                    <>
                      <Col xs={6}>
                        <div className='card-assets-title text-center'>                          
                          <Skeleton width="10rem" className="p-mb-2 m-2"></Skeleton>
                        </div>
                        <div className='assets-container'>
                          <div className='assets-list'>                            
                            <Skeleton className='mb-2' width="100%" height="4rem"></Skeleton> 
                            <Skeleton className='mb-2' width="100%" height="4rem"></Skeleton> 
                            <Skeleton className='mb-2' width="100%" height="4rem"></Skeleton> 
                          </div>
                        </div>                  
                      </Col>                    
                    </>
                  )
                }
              </Row>
            </CardBody>          
          </Card>
        </Col>
      </Row>
    </>  
  ); 
};

export default DigitalViewDashboardMachineCardSkeleton;

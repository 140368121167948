import React, { useState, useRef, useEffect  } from 'react'; 
import { useTranslation } from 'react-i18next';   
import { config } from '../../../../utils/config';
import { callApi, showSweetAlert} from '../../../../utils/utils';   
import PageContent from 'layouts/PageContent';   
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { Row,Col,Card, CardHeader, CardTitle, CardBody, CardFooter} from 'reactstrap';   
import FieldDataView from 'components/Controls/FieldDataView/FieldDataView.jsx';
import FieldDropdown from 'components/Controls/FieldDropdown.jsx' 
import FieldButton from 'components/Controls/FieldButton/FieldButton.jsx';
import FieldTextArea  from 'components/Controls/FieldTextArea.jsx'
import FieldCalendar from 'components/Controls/FieldCalendar.jsx'
import './DailyOperationTrackerScreen.scss';  
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import * as yup from 'yup';
import YupValidator from '../../../../utils/YupValidator';
import InstructionsCard from 'components/Cards/InstructionsCard/InstructionsCard';
import { setDate } from 'date-fns';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { Dialog } from 'primereact/dialog';
import moment from "moment";
import FieldText from 'components/Controls/FieldText';
import DailyOperationAssetDetail from './DailyOperationAssetDetail';
import FasesActividadesAnexo from 'views/ScoreCard/Projects/FasesActividadesAnexo';
import DailyOperationTrackerTask from './DailyOperationTrackerTask';
import DailyOperationProductionTrend from './DailyOperationProductionTrend';

const DailyOperationTrackerScreen = (props) => { 
  const { t } = useTranslation(['wtw', 'common']);
  
  const [errors, setErrors] = useState({});    
  const [OperacionSeguimientoList, setOperacionSeguimientoList] = useState([]);
  const [OperacionSeguimientoTareaList, setOperacionSeguimientoTareaList] = useState([]);
  const [OperacionSeguimientoFuncionalAreaList, setOperacionSeguimientoFuncionalAreaList] = useState([]);
  const [ClaZona, setClaZona] = useState(null); 
  const [Fecha, setFecha] = useState(); 
  const [EsApply, setEsApply] = useState(false); 
  const [PlantasList, setPlantasList] = useState([]);
  const [ComentarioOperacionFuncionAreaHistory, setComentarioOperacionFuncionAreaHistory] = useState([]);
  const [ComentarioRazonPrincipalHistory, setComentarioRazonPrincipalHistory] = useState([]);
  const [ClaUbicacion, setClaUbicacion] = useState(null);
  const [NomUbicacion, setNomUbicacion] = useState(null);
  const [EsCargandoListado, setEsCargandoListado] = useState(false);
  const [EsMostrarDialogMain, setEsMostrarDialogMain] = useState(false);
  const [EsMostrarDialogFunctional, setEsMostrarDialogFunctional] = useState(false);
  const [TitleDialog, setTitleDialog] = useState(null);
  const [ClaArea, setClaArea] = useState(null);
  const [ClaUbicacionSelected, setClaUbicacionSelected] = useState(null);
  const [ClaOperacionFuncionArea, setClaOperacionFuncionArea] = useState(null);
  const [RazonPrincipalProblema, setRazonPrincipalProblema] = useState(null); 
  const [ComentarioProblema, setComentarioProblema] = useState(null);
  const [PosibleSolucionProblema, setPosibleSolucionProblema] = useState(null);
  const [IdOperacionSeguimientoDiario, setIdOperacionSeguimientoDiario] = useState(null);
  const [EsAutofocusComentario, setEsAutofocusComentario] = useState(false);
  const [EsAutofocusSolucion, setEsAutofocusSolucion] = useState(false);
  const [EsAgregarTarea, setEsAgregarTarea] = useState(false);
  const [NomActividadTarea, setNomActividadTarea] = useState(null);
  const [DescripcionTarea, setDescripcionTarea] = useState(null);
  const [OpcionesResponsables, setOpcionesResponsables] = useState([]);
  const [ClaResponsable , setClaResponsable] = useState(null); 
  const [IdOperacionSeguimientoDiarioTarea , setIdOperacionSeguimientoDiarioTarea] = useState(null); 
  const [ClaActividad , setClaActividad] = useState(null); 
  const [FechaTarea, setFechaTarea] = useState(null); 
  const [FechaTareaCompletada, setFechaTareaCompletada] = useState(null); 
  const [EsCompletarTarea, setEsCompletarTarea] = useState(false);
  const [EsVerDetalleArea, setEsVerDetalleArea] = useState(false);
  const [SelectedArea, setSelectedArea] = useState(null);
  const [SelectedAreaName, setSelectedAreaName] = useState(''); 
  const [ClaProyecto, setClaProyecto] = useState(null);
  const [ClaFase, setClaFase] = useState(null);
  const [EsMostrarProduccionTendencia, setEsMostrarProduccionTendencia] = useState(false);
  const [NomArea, setNomArea] = useState('');
  
  useEffect(() => {
    setFecha(getDefaultDateValue());
    getPlantas(); 
    obtenerResponsablesProyecto();
  }, [])

  const getDefaultDateValue = () => { 
    const today = new Date();
    const day = today.getDay();
    let offset = 1; // default to yesterday
    
    // If today is Monday, we need to go back to Friday
    if (day === 1) {
        offset = 3;
    } 
    // If today is Sunday, we need to go back to Friday
    else if (day === 0) {
        offset = 2;
    }

    // Subtract the necessary number of days
    const previousBusinessDate = new Date(today);
    previousBusinessDate.setDate(today.getDate() - offset);

    return previousBusinessDate;
  }

  const obtenerResponsablesProyecto = () => { 
    const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/GetMswBSCColaboradorProyectosTeam`;  
    const paramsToService = { 
      ClaProyecto:93
    };   
    callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => { 
      if (response.data[0].length >0)
      {  
        setOpcionesResponsables(response.data[0]);
      }  
    });
  }

  const onChangeFecha = (e) => {  
    const newErrors = { ...errors }
    delete newErrors.Fecha;   
    setErrors(newErrors);
    setFecha(e.target.value);
    setEsApply(false);
    setOperacionSeguimientoList([]);
    setOperacionSeguimientoTareaList([]);
  } 

  const onChangeFechaTarea = (e) => {  
    const newErrors = { ...errors }
    delete newErrors.FechaTarea;   
    setErrors(newErrors);
    setFechaTarea(e.target.value); 
  } 

  const onChangeFechaTareaCompletada = (e) => {  
    const newErrors = { ...errors }
    delete newErrors.FechaTareaCompletada;   
    setErrors(newErrors);
    setFechaTareaCompletada(e.target.value); 
  } 

  const valitationSchemeApply = yup.object().shape({
    ClaUbicacion: yup.mixed().required(t('common:RequiredField')).nullable(),
    Fecha: yup.date().required(t('common:RequiredField')).nullable()
  }); 

 
const onSearch = async (isload) => {  
  const value = {
    ClaUbicacion,
    Fecha
  } 
  const validator = new YupValidator(valitationSchemeApply);
  const esValido = await validator.validate(value);
  
  if (!esValido){ 
    showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
    setErrors(validator.errors); 
    return;
  }
const urlWebService = `${config.UrlApiProject}WTW/GetOperacionSeguimientoDiario`; 
const paramsService = {
  Fecha,
  ClaUbicacion
}; 
if(isload){
  setEsCargandoListado(true);
}
callApi(urlWebService, 'POST', paramsService, (response) => {  
    setEsCargandoListado(false);
    setOperacionSeguimientoList(response.data.OperacionSeguimientoList);
    setOperacionSeguimientoTareaList(response.data.OperacionSeguimientoTareaList); 
    setEsApply(true);
    setErrors({});
  }, () => {
 },null,false, false);
}; 

const onAddTask = async () => {  
  const value = {
    ClaUbicacion,
    Fecha
  } 
  const validator = new YupValidator(valitationSchemeApply);
  const esValido = await validator.validate(value);
  
  if (!esValido){ 
    showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
    setErrors(validator.errors); 
    return;
  }
  setClaResponsable(null);
  setNomActividadTarea(null);
  setDescripcionTarea(null);
  setFechaTarea(null);
  setClaActividad(null);
  setFechaTareaCompletada(null);
  setIdOperacionSeguimientoDiarioTarea(null);
  setErrors({});
  setEsCompletarTarea(false);
  setEsAgregarTarea(true);
}; 

const getOperacionSeguimientoDiarioTarea = (item) => { 
  console.log('GetOperacionSeguimientoDiarioTarea',item); 
  const urlWebService = `${config.UrlApiProject}WTW/GetOperacionSeguimientoDiarioTarea`; 
  const paramsService = {
    ClaUbicacion:item.ClaUbicacion,
    ClaProyecto:item.ClaProyecto,
    ClaFase:item.ClaFase,
    ClaActividad:item.ClaActividad,
    Fecha,
    Language:''
  };  
  callApi(urlWebService, 'POST', paramsService, (response) => {
    const data=response.data.OperacionSeguimientoDiarioTarea[0];
    setErrors(null);
    if(data!==undefined){
      setIdOperacionSeguimientoDiarioTarea(data.IdOperacionSeguimientoDiarioTarea);
      setClaActividad(data.ClaActividad);
      setClaResponsable(data.ClaResponsable);
      setNomActividadTarea(data.NomActividad);
      setDescripcionTarea(data.Descripcion);
      setFechaTarea(new Date(data.DueDate)); 
      setFechaTareaCompletada(null);
      setEsCompletarTarea(true);
      setClaProyecto(item.ClaProyecto);
      setClaFase(item.ClaFase);
      setErrors({});
    }
    setEsAgregarTarea(true);
  }, null, false, true);
}

const onChangeNomActividadTarea = (e) => {      
  const newErrors = { ...errors } 
  delete newErrors.NomActividadTarea;   
  setErrors(newErrors)     
  setNomActividadTarea(e.target.value); 
}

const onChangeDescripcionTarea = (e) => {    
  const newErrors = { ...errors } 
  delete newErrors.DescripcionTarea;   
  setErrors(newErrors)  
  setDescripcionTarea(e.target.value); 
}

const onClaResponsableChange = (props, e ) => {
  const newErrors = { ...errors } 
  delete newErrors.ClaResponsable;   
  setErrors(newErrors) 
  setClaResponsable(e.value)
}; 

const onChangeRazonPrincipalProblema = (e) => {     
  setRazonPrincipalProblema(e.target.value); 
}

const onChangePosibleSolucionProblema = (e) => {     
  setPosibleSolucionProblema(e.target.value); 
  setEsAutofocusComentario(true);
  setEsAutofocusSolucion(false);
}

const onChangeComentarioProblema = (e) => {     
  setComentarioProblema(e.target.value); 
  setEsAutofocusComentario(false);
  setEsAutofocusSolucion(true);
}

 const onChangeClaUbicacion = (e) => {  
  const newErrors = { ...errors } 
  delete newErrors.ClaUbicacion;
  setErrors(newErrors)  
  setClaUbicacion(e.value);
  setEsApply(false);
  const UbicacionList = [...PlantasList];
  const UbicacionName = UbicacionList.filter((item) => item.ClaUbicacion === e.value)[0];
  if(UbicacionName!==undefined){
    setNomUbicacion(UbicacionName.NombreUbicacion);  
  }  

  setOperacionSeguimientoList([]);
  setOperacionSeguimientoTareaList([]);
  setClaZona(null);
};

const getPlantas = () => {  
  const urlWebService = `${config.UrlApiProject}WTW/GetPlantasProduccionSeguimientoCmb`; 
  const paramsService= {};  
  callApi(urlWebService, 'GET', paramsService, (response) => {
    setPlantasList(response.data.PlantasList);
  }, null, false, true);
}
 
const valitationSchemeSave = yup.object().shape({
  RazonPrincipalProblema: yup.string().trim().required(t('common:RequiredField')).nullable(),
}); 

const valitationSchemeSaveFuncionArea = yup.object().shape({
  ComentarioProblema: yup.string().trim().required(t('common:RequiredField')).nullable(),
}); 

const valitationSchemeSaveTask = yup.object().shape({
  NomActividadTarea: yup.string().trim().required(t('common:RequiredField')).nullable(),
  DescripcionTarea: yup.string().trim().required(t('common:RequiredField')).nullable(),
  FechaTarea: yup.date().required(t('common:RequiredField')).nullable(), 
  ClaResponsable: yup.mixed().required(t('common:RequiredField')).nullable(),
  FechaTareaCompletada: (
    EsCompletarTarea
    ? yup.date().required(t('common:RequiredField')).nullable() 
    : yup.date().nullable()
  )
}); 

const onSaveTask = async () => {  
  const value = {
    NomActividadTarea,
    DescripcionTarea,
    FechaTarea,
    ClaResponsable
  }
  if(EsCompletarTarea){
    value.FechaTareaCompletada=FechaTareaCompletada;
  }
  const validator = new YupValidator(valitationSchemeSaveTask);
  const esValido = await validator.validate(value);
  if (!esValido){ 
    setErrors(validator.errors);
    showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
    return;
  }
  const urlWebService = `${config.UrlApiProject}WTW/SaveOperacionSeguimientoDiarioTarea`; 
  const paramsService = {
    IdOperacionSeguimientoDiarioTarea,
    ClaUbicacion,
    ClaActividad,
    NomActividadTarea,
    DescripcionTarea,
    Fecha,
    FechaTarea,
    FechaTareaCompletada,
    ClaResponsable,
    Language:''
  }; 
  callApi(urlWebService, 'POST', paramsService, (response) => {
    showSweetAlert(t('common:Exito'), t('common:SaveSuccess'), 'success');
    onCloseDialogTask();
    onSearch(false);
    }, () => {
  });
};

const onSaveRazonPrincipal = async () => {  
  const value = {RazonPrincipalProblema}
  const validator = new YupValidator(valitationSchemeSave);
  const esValido = await validator.validate(value);
  if (!esValido){ 
    setErrors(validator.errors);
    showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
    return;
  }
  const urlWebService = `${config.UrlApiProject}WTW/SaveOperacionSeguimientoDiarioComentarioRazonPrincipal`; 
  const paramsService = {
    ClaUbicacion,
    ClaArea,
    RazonPrincipalProblema,
    IdOperacionSeguimientoDiario,
    Fecha,
    Language:''
  }; 
  callApi(urlWebService, 'POST', paramsService, (response) => {
    showSweetAlert(t('common:Exito'), t('common:SaveSuccess'), 'success');
    onCloseDialogMain();
    onSearch(false);
    }, () => {
  });
};

const onSaveFuncionArea = async () => {  
  const value = {ComentarioProblema}
  const validator = new YupValidator(valitationSchemeSaveFuncionArea);
  const esValido = await validator.validate(value);
  if (!esValido){ 
    setErrors(validator.errors);
    showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
    return;
  }
  const urlWebService = `${config.UrlApiProject}WTW/SaveOperacionSeguimientoDiarioComentarioOperacionFuncionArea`; 
  const paramsService = {
    ClaUbicacion,
    ClaArea,
    ClaOperacionFuncionArea,
    ComentarioProblema,
    PosibleSolucionProblema,
    IdOperacionSeguimientoDiario,
    Fecha,
    Language:''
  }; 
  callApi(urlWebService, 'POST', paramsService, (response) => {
      showSweetAlert(t('common:Exito'), t('common:SaveSuccess'), 'success');
      onCloseDialogFunctional();
      onSearch(false);
    }, () => {
  });
};

const filters = (
  <> 
    <Row className="pt-3 pb-3 align-items-start">
      <Col>
        <div className="p-fluid">
          <FieldDropdown  
            name="ClaUbicacion"
            value={ClaUbicacion} 
            options={PlantasList}  
            optionValue="ClaUbicacion"
            optionLabel="NombreUbicacion" 
            onChange={(e) => onChangeClaUbicacion(e)}
            label={t('wtw:Plant')}
            errors={errors}
          />   
        </div>
      </Col>
      <Col>
        <div className="p-fluid">
          <FieldCalendar
            name="Fecha"
            label={t('wtw:Fecha')}
            value={Fecha}
            onChange={(e) => onChangeFecha(e)}
            errors={errors}
            maxDate={getDefaultDateValue()}
          />   
        </div>
      </Col>
      <Col>
        <FieldButton
          className="p-button p-highlight no-borde" 
          onClick={() => onSearch(true)}
        >
          <i className="pi pi-search"></i>&nbsp;{t('wtw:Search')}
        </FieldButton>&nbsp;
        <FieldButton
          className="p-button warning no-border" 
          onClick={() => onAddTask()}
        >
          <i className="pi pi-plus"></i>&nbsp;{t('wtw:AddNewTask')}
        </FieldButton>
      </Col>
      <Col>
        <div className="p-fluid">
          &nbsp;
        </div>
      </Col>
      <Col>
        <div className="p-fluid">
          &nbsp;
        </div>
      </Col>
    </Row>   
  </>
);
 
const AreaDepartamentoColumna = (row) => {  
  return (
    <>  
      <div onClick={() =>openDialogDepartament(row)}>
        <span className='link-text primary-color'> 
          {row.NomArea}
        </span>
      </div>
    </>
);
}

const openDialogDepartament = (item) => {
  setSelectedArea(item.ClaArea);  
  setSelectedAreaName(item.NomArea);
  setEsVerDetalleArea(true); 
}


  
const NomActividadColumna = (row) => {  
  return (
    <> 
      <span className='font-bold'> 
        {row.NomActividad}
      </span> 
    </>
);
}

const AssignedToColumna = (row) => {  
  return (
    <> 
      <span> 
        {row.AssignedTo}
      </span> 
    </>
);
}

const CreatedByColumna = (row) => {  
  return (
    <> 
      <span> 
        {row.CreatedBy}
      </span> 
    </>
);
}

const DateTemplate = (item, columnName) => {
  const dateValue = item[columnName];
  return (
    <> 
      <span>
        {/* moment(item[columnName]).format('MMMM DD, YYYY h:mm:ss a') */}
        <span>
          { dateValue ? moment(dateValue).format('MMMM DD, YYYY') : '' }
        </span>
      </span>
    </>
    );
};  

const CompletedDateTemplate = (item) => {
  const dateValue = item.CompletedDate;
  return (
    <> 
      <span>
        {/* moment(item[columnName]).format('MMMM DD, YYYY h:mm:ss a') */}
        <span>
          { dateValue ? moment(dateValue).format('MMMM DD, YYYY') : (
            <>
              <FieldButton 
                label={t('wtw:CompleteTask')}
                icon="pi pi-check-circle"
                onClick={() => getOperacionSeguimientoDiarioTarea(item)}
                size="small"
                className="p-button p-button-success"
                text 
              />
            </>
        )}
        </span>
      </span>
    </>
    );
};  

const getOperacionSeguimientoDiarioComentarioRazonPrincipal = (row) => { 
  console.log('getOperacionSeguimientoDiarioComentarioRazonPrincipal',row); 
  const urlWebService = `${config.UrlApiProject}WTW/GetOperacionSeguimientoDiarioComentarioRazonPrincipal`; 
  const paramsService = {
    ClaUbicacion:row.ClaUbicacion,
    ClaArea:row.ClaArea,
    Fecha,
    Language:''
  };  
  callApi(urlWebService, 'POST', paramsService, (response) => {
    const data=response.data.ComentarioRazonPrincipal[0];
    setComentarioRazonPrincipalHistory([]); 
    setComentarioRazonPrincipalHistory(response.data.ComentarioRazonPrincipalHistory);
    setErrors(null);
    if(data!==undefined){
      setClaOperacionFuncionArea(data.ClaOperacionFuncionArea);
      setClaUbicacionSelected(data.ClaUbicacion);
      setClaArea(data.ClaArea); 
      setPosibleSolucionProblema(null);
      setComentarioProblema(null);
      setIdOperacionSeguimientoDiario(data.IdOperacionSeguimientoDiario);
      setRazonPrincipalProblema(data.RazonPrincipalProblema); 
    }else{
      setClaUbicacionSelected(row.ClaUbicacion);
      setClaArea(row.ClaArea); 
      setClaOperacionFuncionArea(-1);
      setPosibleSolucionProblema(null);
      setComentarioProblema(null);
      setIdOperacionSeguimientoDiario(0);
      setRazonPrincipalProblema(null); 
    }
    setTitleDialog(row.NomArea);
    setEsMostrarDialogMain(true);
  }, null, false, true);
}

const getOperacionSeguimientoDiarioComentarioOperacionFuncionArea = (item,row) => {
  console.log('getOperacionSeguimientoDiarioComentarioRazonPrincipal',item,'row',row);   
  const urlWebService = `${config.UrlApiProject}WTW/GetOperacionSeguimientoDiarioComentarioOperacionFuncionArea`; 
  const paramsService = {
    ClaUbicacion:item.ClaUbicacion,
    ClaArea:item.ClaArea,
    ClaOperacionFuncionArea:row.ClaOperacionFuncionArea,
    Fecha,
    Language:''
  };  
  callApi(urlWebService, 'POST', paramsService, (response) => {
    setComentarioOperacionFuncionAreaHistory([]);
    const data = response.data.ComentarioOperacionFuncionArea[0];
    setComentarioOperacionFuncionAreaHistory(response.data.ComentarioOperacionFuncionAreaHistory);
    setErrors(null);
    if(data!==undefined){
      setClaUbicacionSelected(data.ClaUbicacion);
      setClaArea(data.ClaArea); 
      setIdOperacionSeguimientoDiario(data.IdOperacionSeguimientoDiario);
      setClaOperacionFuncionArea(data.ClaOperacionFuncionArea);
      setPosibleSolucionProblema(data.PosibleSolucionProblema);
      setComentarioProblema(data.ComentarioProblema);
      setRazonPrincipalProblema(null);
    }else{
      setClaUbicacionSelected(item.ClaUbicacion);
      setClaArea(item.ClaArea); 
      setClaOperacionFuncionArea(row.ClaOperacionFuncionArea);
      setIdOperacionSeguimientoDiario(0);
      setPosibleSolucionProblema(null);
      setComentarioProblema(null);
      setRazonPrincipalProblema(null);
    }
    setTitleDialog(`${item.NomArea}-${row.NomOperacionFuncionArea}`);
    setEsMostrarDialogFunctional(true);
  }, null, false, true);
}

const onCloseDialogFunctional = () => {  
  setEsMostrarDialogFunctional(false);
}

const onCloseDialogMain = () => {  
  setEsMostrarDialogMain(false);
}

const onCloseDialogTask = () => { 
  setEsAgregarTarea(false);
}

const onAfterSaveTask = () => { 
  setEsAgregarTarea(false);
  onSearch(false);
}

const CajasTemplate = (item, columnName, decimals) => {
  return (
    <> 
      <span>{Highcharts.numberFormat(item[columnName],decimals, '.', ',')} </span>
    </>
    );
};  

const DecimalTemplate = (item, columnName, decimals) => {
  
  return (
    <> 
      <span>{Highcharts.numberFormat(item[columnName],decimals, '.', ',')}</span>
    </>
    );
};  

const PorcentajeTemplate = (item, columnName) => {
  
  return (
    <> 
      <span>{Highcharts.numberFormat(item[columnName],2, '.', ',')}%</span>
    </>
    );
};  

const skeletonTemplate = () =>{
  return (<Skeleton height="1rem" width="100%" className="mb-2"></Skeleton>);
}

const rowClass = (data) => {
  return {
      'light-gray': data.ClaArea === -1
  }
}

const headerDialog = () => {
  return (
    <>  
      {TitleDialog} - {t('wtw:ProductionDate')} : {moment(Fecha).format('MMMM Do, YYYY') } 
    </>
  )
}

const headerDialogTask = () => {
  return (
    <>
      {t('wtw:AddNewTask')}
    </>
  )
}

const footerDialogTask = () => {
  return (
    <>
    
      <FieldButton 
        label={t('common:Close ')}
        icon="pi pi-times"
        onClick={() => onCloseDialogTask()}
        className="p-button p-button-warning"
      />
      <FieldButton 
        label={t('common:Save ')}
        icon="pi pi-save"
        onClick={() => onSaveTask()}
        className="p-button p-button-success"
      />
    </>
  )
}


const headerDialogDetalleMaquinas = () => {
  return (
    <>
      {SelectedAreaName} - {t('wtw:ProductionDate')} : {moment(Fecha).format('MMMM Do, YYYY') } - {t('wtw:DetailByAsset')} 
    </>
  )
}

const footerDialogDetalleMaquinas = () => {
  return (
    <>
      <FieldButton 
        label={t('common:Close ')}
        icon="pi pi-times"
        onClick={() => setEsVerDetalleArea(false)}
        className="p-button p-button-warning"
      /> 
    </>
  )
}

const footerDialogFunctional = () => {
  return (
    <>
      <FieldButton 
        label={t('common:Close ')}
        icon="pi pi-times"
        onClick={() => onCloseDialogFunctional()}
        className="p-button p-button-warning"
      />
      <FieldButton 
        label={t('common:Save ')}
        icon="pi pi-save"
        onClick={() => onSaveFuncionArea()}
        className="p-button p-button-success"
      />
    </>
  )
}

const footerDialogMain = () => {
  return (
    <>
      <FieldButton 
        label={t('common:Close ')}
        icon="pi pi-times"
        onClick={() => onCloseDialogMain()}
        className="p-button p-button-warning"
      />
      <FieldButton 
        label={t('common:Save ')}
        icon="pi pi-save"
        onClick={() => onSaveRazonPrincipal()}
        className="p-button p-button-success"
      />
    </>
  )
}

const headerDialogProductionTrendDetail = () => {
  return (
    <>
      {t('wtw:ProductionTrendDetail')} - {moment(Fecha).format('MMMM, YYYY') } - {NomArea} - {t('wtw:Shift')} : {t('wtw:All')} - {t('wtw:Cell')}: {t('wtw:All')}
    </>
  )
}

const showProductionTrend = (item) => {   
  setNomArea(item.NomArea);
  setClaArea(item.ClaArea);
  setEsMostrarProduccionTendencia(true);
}



const ListadoDepartamentos = () => {
  if (EsCargandoListado){
    return (
      <> 
        <FieldDataTable 
          value={[{},{},{},{}]}
          paginator={false} 
        >     
          <Column
            body={skeletonTemplate}
            header={t('formulario:Departament')} 
            columnKey=""
            headerStyle={{ width: '150px' }}
          >
          </Column>    
          <Column header={t('wtw:Units')} className='text-center' headerStyle={{ width: '100px' }} body={skeletonTemplate}></Column>
          <Column header={t('wtw:UnitsByHr')} className='text-center' headerStyle={{ width: '100px' }} body={skeletonTemplate}></Column>
          <Column header={t('wtw:Tons')} className='text-center' headerStyle={{ width: '100px' }} body={skeletonTemplate}></Column>
          <Column header={t('wtw:Scrap')} className='text-center' headerStyle={{ width: '100px' }} body={skeletonTemplate}></Column>
          <Column header={t('wtw:Utilizacion')} className='text-center' headerStyle={{ width: '80px' }} body={skeletonTemplate}></Column> 
          <Column header={t('wtw:Disponibilidad')} className='text-center' headerStyle={{ width: '80px' }} body={skeletonTemplate}></Column>
          <Column header={t('wtw:Efficiencia')} className='text-center' headerStyle={{ width: '80px' }} body={skeletonTemplate}></Column>
          <Column header={t('wtw:OEE')} className='text-center' headerStyle={{ width: '80px' }} body={skeletonTemplate}></Column>
          <Column
            body={skeletonTemplate}
            header={t('formulario:Main')} 
            className='text-center'
            columnKey=""
            headerStyle={{ width: '150px' }}
          >
          </Column> 

        </FieldDataTable>
        <br></br>
        <FieldDataTable 
          value={[{},{},{},{}]}
          paginator={false} 
        >     
          <Column
            body={skeletonTemplate}
            header={t('wtw:Task')} 
            columnKey=""
            headerStyle={{ width: '150px' }}
          >
          </Column>    
          <Column header={t('wtw:CreatedBy')} className='text-right' headerStyle={{ width: '100px' }} body={skeletonTemplate}></Column>
          <Column header={t('wtw:AssignedTo')} className='text-right' headerStyle={{ width: '100px' }} body={skeletonTemplate}></Column>
          <Column header={t('wtw:CreationDate')} className='text-right' headerStyle={{ width: '100px' }} body={skeletonTemplate}></Column>
          <Column header={t('wtw:DueDate')} className='text-right' headerStyle={{ width: '80px' }} body={skeletonTemplate}></Column> 
          <Column header={t('wtw:CompletedDate')} className='text-right' headerStyle={{ width: '80px' }} body={skeletonTemplate}></Column>
        </FieldDataTable>
      </>  
    )
  }

  return (
    <div>
      {
        OperacionSeguimientoList.length > 0 &&
        (
          <Row>
            <Col className='pb-2 text-right'>
              <div className='' onClick={() => showProductionTrend({ClaArea: null, NomArea:  t('wtw:AllDepatments') })}><span className='bold link-text primary-color'>View Production Trend <i className="fas fa-external-link-alt"></i></span></div> 
            </Col> 
          </Row>
        )
      }
      <FieldDataTable 
        value={OperacionSeguimientoList}    
        paginator={false}
        rowClassName={rowClass}
      > 
        <Column
          body={AreaDepartamentoColumna}
          header={t('wtw:Departament')} 
          columnKey="NomArea"
          headerStyle={{ width: '150px' }}
          editMode="row" 
        >
        </Column>   
        <Column header={t('wtw:Units')} className='text-right' headerStyle={{ width: '90px' }} body={(rowData)=> CajasTemplate (rowData, 'Cajas', 0)}></Column>
        <Column header={t('wtw:UnitsByHr')} className='text-center' headerStyle={{ width: '90px' }} body={(rowData)=> DecimalTemplate (rowData, 'UnitsByHr', 2)}></Column>
        <Column header={t('wtw:Tons')} className='text-right' headerStyle={{ width: '90px' }} body={(rowData)=> DecimalTemplate (rowData, 'Tons', 2)}></Column>
        <Column header={t('wtw:Scrap')} className='text-right' headerStyle={{ width: '90px' }} body={(rowData)=> PorcentajeTemplate (rowData, 'Scrap')}></Column>
        <Column header={t('wtw:Utilizacion')} className='text-right' headerStyle={{ width: '80px' }} body={(rowData)=> PorcentajeTemplate (rowData, 'Utilizacion')}></Column> 
        <Column header={t('wtw:Disponibilidad')} className='text-right' headerStyle={{ width: '80px' }} body={(rowData)=> PorcentajeTemplate (rowData, 'Disponibilidad')}></Column>
        <Column header={t('wtw:Efficiencia')} className='text-right' headerStyle={{ width: '80px' }} body={(rowData)=> PorcentajeTemplate (rowData, 'Efficiencia')}></Column>
        <Column header={t('wtw:OEE')} className='text-right' headerStyle={{ width: '80px' }} body={(rowData)=> PorcentajeTemplate (rowData, 'OEE')}></Column>
        <Column
          field="RazonPrincipalProblema"
          header={t('wtw:MainCause')}
          headerStyle={{ width: '300px', textAlign: 'center', paddingLeft: '5px' }}
          columnKey="RazonPrincipalProblema"
          body={(rowData) => (
            <div className="p-fluid" onClick={(e) => getOperacionSeguimientoDiarioComentarioRazonPrincipal(rowData)}>
              <FieldTextArea 
                name="RazonPrincipalProblema"
                autoResize={true}
                rows={1}
                value={rowData.RazonPrincipalProblema} 
                onClick={(e) => getOperacionSeguimientoDiarioComentarioRazonPrincipal(rowData)} 
                onFocus={(e) => getOperacionSeguimientoDiarioComentarioRazonPrincipal(rowData)}  
              />
            </div>
        )}
        >
        </Column>
      </FieldDataTable>
      <br></br>
      
      <FieldDataTable 
        value={OperacionSeguimientoTareaList}    
        paginator={false}
        rowClassName={rowClass}
      > 
        <Column
          body={NomActividadColumna}
          header={t('wtw:Task')} 
          columnKey="NomActividad"
          headerStyle={{ width: '300px' }}
          editMode="row" 
        >
        </Column>  
        <Column header={t('wtw:CreatedBy')} className='text-left' headerStyle={{ width: '150px' }} body={(rowData)=> CreatedByColumna (rowData)}></Column>
        <Column header={t('wtw:AssignedTo')} className='text-left' headerStyle={{ width: '150px' }} body={(rowData)=> AssignedToColumna (rowData)}></Column>
        <Column header={t('wtw:CreationDate')} className='text-left' headerStyle={{ width: '120px' }} body={(rowData)=> DateTemplate (rowData, 'CreationDate')}></Column> 
        <Column header={t('wtw:DueDate')} className='text-left' headerStyle={{ width: '120px' }} body={(rowData)=> DateTemplate (rowData, 'DueDate')}></Column>
        <Column header={t('wtw:CompletedDate')} className='text-left' headerStyle={{ width: '120px' }} body={(rowData)=> CompletedDateTemplate(rowData)}></Column> 
      </FieldDataTable>
      <br></br>
      {
        OperacionSeguimientoList.length>0 ? (
          <>  
            {OperacionSeguimientoList.map((item, index) => { 
                    return ( 
                      <Card key={index}>
                        <CardHeader className='bg-card-header-gray'>
                          <Row className="align-items-start p-3">
                            <Col>
                              <Row> 
                                <Col lg={4} md={4} sm={12} xs={12}>
                                  <div className='' onClick={() => showProductionTrend(item)}><span className='bold link-text primary-color'>{`${item.NomArea}`}</span></div> 
                                </Col>
                                <Col lg={8} md={8} sm={12} xs={12} className='text-right'>
                                  <span className='bold'>{`${t('wtw:Units')}:`}</span> 
                                  <span>  {`${Highcharts.numberFormat(item.Cajas,1, '.', ',')}`}</span>
                                  &nbsp;
                                  <span className='bold'>{`${t('wtw:UnitsByHr')}:`}</span> 
                                  <span>  {`${Highcharts.numberFormat(item.UnitsByHr, 2,'.', ',')}`}</span>
                                  &nbsp;
                                  <span className='bold'>{`${t('wtw:Tons')}:`}</span> 
                                  <span>  {`${Highcharts.numberFormat(item.Tons,1, '.', ',')}`}</span>
                                  &nbsp;
                                  <span className='bold'>{`${t('wtw:Scrap')}:`}</span> 
                                  <span>  {`${Highcharts.numberFormat(item.Scrap,1,'.', ',')}`} %</span>
                                  &nbsp;
                                  <span className='bold'>{`${t('wtw:Utilizacion')}:`}</span> 
                                  <span>  {`${Highcharts.numberFormat(item.Utilizacion,1, '.', ',')}`} %</span>
                                  &nbsp;
                                  <span className='bold'>{`${t('wtw:Disponibilidad')}:`}</span> 
                                  <span>  {`${Highcharts.numberFormat(item.Disponibilidad,1,'.', ',')}`} %</span>
                                  &nbsp;
                                  <span className='bold'>{`${t('wtw:Efficiencia')}:`}</span> 
                                  <span>  {`${Highcharts.numberFormat(item.Efficiencia, 1,'.', ',')}`} %</span>
                                  &nbsp;
                                  <span className='bold'>{`${t('wtw:OEE')}:`}</span> 
                                  <span>  {`${Highcharts.numberFormat(item.OEE, 1,'.', ',')}`} %</span>
                                </Col>
                              </Row> 
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody style={{ padding: "0px", paddingTop: "5px"}}>  
                          <Row>
                            <Col>
                              <div className="grid">
                                <div className="col-12">
                                  <div className="shadow-2 p-3 p-mb-3"> 
                                    <div className="datatable">
                                      <table className="datatable-table" width="100%">
                                        <thead className='pb-2'>
                                          <tr>
                                            <td>
                                              <b>{`${t('wtw:FunctionalArea')}`}</b>
                                            </td>
                                            <td>
                                              <b>
                                                {`${t('wtw:ExplanationIssues')}`}
                                              </b> 
                                            </td>
                                            <td>
                                              <b>
                                                {`${t('wtw:ExplanationIssuesAddressed')}`}
                                              </b> 
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>&nbsp;</td>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {
                                            item.OperacionAreaFuncionalDet.map((itemData, indexData) => { 
                                              return (
                                                <tr className='pb-2' key={indexData}>
                                                  <td style={{verticalAlign:"top"}}>
                                                    <div className='font-bold'>
                                                      {`${itemData.NomOperacionFuncionArea}`}
                                                    </div>
                                                  </td>
                                                  {/* <td><b>{`${t('wtw:Hours')}`}</b></td> */}
                                                  <td width="40%" style={{verticalAlign:"top"}}>
                                                    <div className="p-fluid" onClick={(e) => getOperacionSeguimientoDiarioComentarioOperacionFuncionArea(item,itemData)}>
                                                      <FieldTextArea 
                                                        name="ComentarioProblema"
                                                        autoResize={true}
                                                        rows={1}
                                                        value={itemData.ComentarioProblema}  
                                                        onClick={(e) => getOperacionSeguimientoDiarioComentarioOperacionFuncionArea(item,itemData)}
                                                        onFocus={(e) => getOperacionSeguimientoDiarioComentarioOperacionFuncionArea(item,itemData)} 
                                                      />
                                                    </div>
                                                  </td>
                                                  <td width="40%" style={{verticalAlign:"top"}}> 
                                                    <div className="p-fluid" onClick={(e) => getOperacionSeguimientoDiarioComentarioOperacionFuncionArea(item,itemData)}>
                                                      <FieldTextArea 
                                                        name="PosibleSolucionProblema"
                                                        autoResize={true}
                                                        rows={1}
                                                        value={itemData.PosibleSolucionProblema}  
                                                        onClick={(e) => getOperacionSeguimientoDiarioComentarioOperacionFuncionArea(item,itemData)}
                                                        onFocus={(e) => getOperacionSeguimientoDiarioComentarioOperacionFuncionArea(item,itemData)} 
                                                      />
                                                    </div>
                                                  </td>
                                                </tr>   
                                            );
                                            })
                                          }
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row> 
                        </CardBody>
                      </Card>
                  );
                })}  
            <div className="">
                &nbsp;
            </div> 
          </>
        ):(
          <>
            <>&nbsp;</>
          </>
        )
      }
    </div>
  
  )
}

  return (
    <>   
      <PageContent title={t('wtw:DailyOperationTracker')} className="DailyOperationTrackerScreen">  
        <Row className='align-items-start pb-3'>
          <Col lg={12} md={12} sm={12} xs={12}>  
            {filters}
          </Col> 
        </Row> 
        <Row className='align-items-start pb-3'>
          <Col lg={12} md={12} sm={12} xs={12}>  
            {ListadoDepartamentos()} 
          </Col> 
        </Row> 
        
        <Dialog
          visible={EsMostrarDialogFunctional}         
          modal 
          maximized={true}
          draggable={false}
          blockScroll={true}
          onHide={onCloseDialogFunctional}
          header={headerDialog} 
          className='DailyOperationTrackerScreen dialog-header-background-blue'
        > 
          <Row className='align-items-start'>
            <Col lg={8} md={8} sm={8} xs={8}>  
              <Row className='align-items-start pb-3'>
                <Col> 
                  <h2>{`${t('wtw:CommentsHistory')}`}</h2>
                  <div className="p-datatable table-header-background-ligth-green">
                    <table className='p-datatable-scrollable-header-table'>
                      <thead className='p-datatable-thead'>
                        <tr>
                          <th style={{maxWidth:'150px', width:'150px'}}>
                            <b>{`${t('wtw:Fecha')}`}</b>
                          </th>
                          <th width="40%">
                            <b>
                              {`${t('wtw:ExplanationIssues')}`}
                            </b> 
                          </th>
                          <th width="40%">
                            <b>
                              {`${t('wtw:ExplanationIssuesAddressed')}`}
                            </b> 
                          </th>
                        </tr> 
                      </thead>
                      <tbody className='p-datatable-tbody'>
                        {
                                ComentarioOperacionFuncionAreaHistory.map((item) => { 
                                  return (
                                    <tr className='pb-2' key={item.IdOperacionSeguimientoDiario}>
                                      <td style={{verticalAlign:"top"}}>
                                        <div className='font-bold'>
                                          {moment(item.Fecha).format('ll')}
                                        </div>
                                      </td> 
                                      <td width="40%" style={{verticalAlign:"top"}}>
                                        <div className="p-fluid">
                                          <span className='label'>
                                            {item.ComentarioProblema} 
                                          </span> 
                                        </div>
                                      </td>
                                      <td width="40%" style={{verticalAlign:"top"}}> 
                                        <div className="p-fluid">
                                          <span className='label'>
                                            {item.PosibleSolucionProblema} 
                                          </span>  
                                        </div>
                                      </td>
                                    </tr>   
                                );
                                })
                              }
                      </tbody>
                    </table>  
                  </div>
                </Col>
              </Row> 
            </Col> 
            <Col lg={4} md={4} sm={4} xs={4} className='sticky-top comment-section'>  
              <Row className='align-items-start pb-3'>
                <Col lg={12} md={12} sm={12} xs={12}>  
                  <h2>{`${t('wtw:AddEditYourComment')}`}</h2>

                  {t('wtw:ExplanationIssues')}
                </Col> 
              </Row> 
              <Row>
                <Col>
                  <div className="p-fluid">
                    <FieldTextArea 
                      name="ComentarioProblema"
                      rows={15}
                      value={ComentarioProblema}  
                      onChange={(e) => onChangeComentarioProblema(e)}  
                      errors={errors}
                      maxLength={5000}
                      autoFocus={!!EsAutofocusComentario} 
                      placeholder={`${t('wtw:PlaceHolderComentarioProblema')}`} 
                    />  
                    <div className='text-right'><span><small>{ComentarioProblema === null ? 0 : ComentarioProblema.toString().length } / 5000</small></span> </div>
             
                  </div>
                </Col>
              </Row>
              <Row className='align-items-start pb-3'>
                <Col lg={12} md={12} sm={12} xs={12}>  
                  {t('wtw:ExplanationIssuesAddressed')}
                </Col> 
              </Row> 
              <Row>
                <Col>
                  <div className="p-fluid">
                    <FieldTextArea 
                      name="PosibleSolucionProblema"
                      rows={15}
                      value={PosibleSolucionProblema}  
                      onChange={(e) => onChangePosibleSolucionProblema(e)}  
                      errors={errors}
                      maxLength={5000} 
                      autoFocus={!!EsAutofocusSolucion}
                      placeholder={`${t('wtw:PlaceHolderPosibleSolucionProblema')}`}  
                    />
                    <div className='text-right'><span><small>{PosibleSolucionProblema === null ? 0 : PosibleSolucionProblema.toString().length } / 5000</small></span> </div>
                  </div>
                </Col>
              </Row> 
              <Row>
                <Col className='text-right pt-1'>
                  <FieldButton 
                    label={t('common:Save ')}
                    icon="pi pi-save"
                    onClick={() => onSaveFuncionArea()}
                    className="p-button p-button-success"
                  />
                </Col>
              </Row> 
            </Col> 
          </Row> 
         
        </Dialog>
        
        <Dialog
          visible={EsMostrarDialogMain}         
          modal
          maximized={true}
          draggable={false}
          blockScroll={true}
          onHide={onCloseDialogMain}
          header={headerDialog} 
          className='DailyOperationTrackerScreen dialog-header-background-blue'
        > 
          <Row className='align-items-start h-100'>
            <Col lg={8} md={8} sm={8} xs={8} className='h-100'>  
              <Row className='align-items-start pb-3'>
                <Col>
                  <h2>{`${t('wtw:CommentsHistory')}`}</h2>
                  <div className="p-datatable table-header-background-ligth-green">
                    <table className='p-datatable-scrollable-header-table'>
                      <thead className='p-datatable-thead'>
                        <tr>
                          <th style={{maxWidth:'150px', width:'150px'}}>
                            <b>{`${t('wtw:Fecha')}`}</b>
                          </th>
                          <th>
                            <b>
                              {`${t('wtw:MainCause')}`}
                            </b> 
                          </th>
                        </tr> 
                      </thead>
                      <tbody className='p-datatable-tbody'>
                        {
                                  ComentarioRazonPrincipalHistory.map((item) => { 
                                    return (
                                      <tr className='pb-2' key={item.IdOperacionSeguimientoDiario}>
                                        <td style={{verticalAlign:"top"}}>
                                          <div className='font-bold'>
                                            {moment(item.Fecha).format('ll')}
                                          </div>
                                        </td> 
                                        <td style={{verticalAlign:"top"}}>
                                          <div className="p-fluid">
                                            <span className='label'>
                                              {item.RazonPrincipalProblema}  
                                            </span> 
                                          </div>
                                        </td>
                                      </tr>   
                                  );
                                  })
                                }
                      </tbody>
                    </table>  
                  </div>
                </Col>
              </Row> 
            </Col> 
            <Col lg={4} md={4} sm={4} xs={4} className='sticky-top comment-section'>  
              <div className='sticky-top'>  
                <Row className='align-items-start pb-3'>
                  <Col lg={12} md={12} sm={12} xs={12}>  
                    <h2>{`${t('wtw:AddEditYourComment')}`}</h2>

                    {t('wtw:MainCause')}
                  </Col> 
                </Row> 
                <Row>
                  <Col>
                    <div className="p-fluid">
                      <FieldTextArea 
                        name="RazonPrincipalProblema"
                        rows={15}
                        value={RazonPrincipalProblema}  
                        onChange={(e) => onChangeRazonPrincipalProblema(e)}  
                        errors={errors}
                        maxLength={5000}
                        placeholder={`${t('wtw:RazonPrincipalProblemaPlaceHolder')}`} 
                      />
                      <div className='text-right'><span><small>{RazonPrincipalProblema === null ? 0 : RazonPrincipalProblema.toString().length } / 5000</small></span> </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className='text-right'>
                    <FieldButton 
                      label={t('common:Save ')}
                      icon="pi pi-save"
                      onClick={() => onSaveRazonPrincipal()}
                      className="p-button p-button-success"
                    />
                  </Col>
                </Row> 
              </div>  
            </Col> 
          </Row> 
        </Dialog>

        {EsAgregarTarea ? (
          <> 
            <DailyOperationTrackerTask
              visible={EsAgregarTarea}
              IdOperacionSeguimientoDiarioTarea={IdOperacionSeguimientoDiarioTarea}
              ClaUbicacion={ClaUbicacion}
              ClaProyecto={ClaProyecto}
              ClaFase={ClaFase}
              ClaActividad={ClaActividad}
              Fecha={Fecha}
              onAfterReturn={onCloseDialogTask}
              onAferSaveTask={onAfterSaveTask}
            > 
            </DailyOperationTrackerTask>
          </>
            ) : (
              <></>
            )}  
       
        
       

        <Dialog
          visible={EsVerDetalleArea}         
          modal
          style={{ width: '80vw' }}
          maximized={false}
          draggable={false}
          onHide={()=> { setEsVerDetalleArea(false)}}
          header={headerDialogDetalleMaquinas} 
          className='DailyOperationTrackerScreen dialog-header-background-blue'
          blockScroll={true} 
        >  
          <Row className='align-items-start'>
            <Col lg={12} md={12} sm={12} xs={12}>  
              <DailyOperationAssetDetail
                ClaUbicacion={ClaUbicacion}
                ClaArea={SelectedArea}
                Fecha={Fecha}
              >
              </DailyOperationAssetDetail>
            </Col> 
          </Row> 
        </Dialog>

        {EsMostrarProduccionTendencia ? (
          <>
            <Dialog
              visible={EsMostrarProduccionTendencia}
              header={headerDialogProductionTrendDetail} 
              draggable={false}
              modal 
              maximized={true}
              className="DailyOperationAssetDetailTree dialog-header-background-blue"
              onHide={() => setEsMostrarProduccionTendencia(false)}
              position="top"
              blockScroll={true}
            > 
              <DailyOperationProductionTrend
                visible={EsMostrarProduccionTendencia} 
                Fecha={Fecha}
                ClaUbicacion={ClaUbicacion}
                ClaArea={ClaArea}
                ClaCelda={null}
                ClaTurno={null}
                DisableFilters={true}
              >
              </DailyOperationProductionTrend>
            </Dialog>  
          </>
        ) : (
          <></>
        )}   
        
      </PageContent> 
    </>
  );
};

export default DailyOperationTrackerScreen;
/* eslint-disable no-array-constructor */
/* eslint-disable react/no-find-dom-node */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useState, useEffect, useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { getSessionItem, callApi, showSweetAlert } from 'utils/utils';
import { Label, Row, Col, Button } from 'reactstrap'; 
import { config } from 'utils/config'; 
import * as yup from 'yup'; 
import moment from 'moment'; 
import { Dialog } from "primereact/dialog"
import './HelpCenter.css';
import VideoPlayer from '../Controls/VideoPlayer/VideoPlayer.jsx';  
import PDFViewer from '../Controls/PDFViewer/PDFViewer';
import screenfull from 'screenfull'
import { findDOMNode } from 'react-dom'

const HelpCenterItem = (props) => {
  const {t, i18n} = useTranslation(['it', 'common']);  
 
  const [showVisorPDF, setShowVisorPDF] = useState(false);
  const [file, setFile] = useState(null);
  const VideoPlayerViewer  = useRef();    
   
  const onClickViewDetail = (document) => {
     
    setFile(document.FileURL);

    // PDF
    if (document.FileType === 2) {  
      setShowVisorPDF(true);
    }
    
    if (document.FileType === 1) { 
       
      if (screenfull.isEnabled) { 
         screenfull.request(VideoPlayerViewer.current.wrapper); 
      }
    }  
  }
   
   
  return (
    
    <>  
      <div 
        style={{ paddingRight: '10px', float: 'left' }}
        className="helpCenter-continer p-4"
      >
        <div className="helpCenter-preview">  
          <div className='helpCenter-thumbnail'>
            {
                                    props.FileType === 1 ?
                                    (
                                      <>
                                        <VideoPlayer reference={VideoPlayerViewer} url={props.FileURL}></VideoPlayer> 
                                      </>
                                    )
                                    :
                                    props.FileType === 2 ?
                                    (
                                      <div className='helpCenter-pdf'>
                                        <i className="fas fa-file-pdf "></i>
                                      </div>
                                    )
                                    :
                                    (<></>)
                                  }
                                  
          </div> 
          <div className="helpCenter-info">
            <div className='' title={props.FileName}>
              <span className='helpCenter-title '>{props.FileName}</span> 
            </div>
            <div className='' title={props.FileDescription}>
              <span className='helpCenter-description'>{props.FileDescription}</span> 
            </div>
            <div className=' helpCenter-view-detail Cursor' onClick={()=> { onClickViewDetail(props) }}> 
              View
            </div>
          </div>
        </div> 
      </div>   
 
      <PDFViewer
        file={file} 
        show={showVisorPDF}
        onHide={() => { setShowVisorPDF(false) }}
      > 
      </PDFViewer> 
    </>
  )
}

export default HelpCenterItem;
import React from 'react'; 
import {  Card, CardHeader, CardBody, Row, Col, CardTitle, Button } from 'reactstrap';
import { config } from '../../utils/config'; 
import { callApi } from '../../utils/utils';  
import { Skeleton } from 'primereact/skeleton';
import FormularioResuelto from './FormularioResuelto.js'; 
import { withTranslation } from 'react-i18next';
import { responsiveFontSizes } from '@material-ui/core';

class ResultadoFormularioDet extends React.Component {
    constructor(props) {
      super(props); 
      this.state = {
            IdFormularioResuelto: props.IdFormularioResuelto ? props.IdFormularioResuelto : null,
            IsLoading:true
      }
    }

    componentDidMount()   
    { 
        if(this.state.IdFormularioResuelto === null)
          return;
          
        const urlWebService = `${config.UrlApiProject}formulario/getFormularioResuelto?IdFormularioResuelto=${this.state.IdFormularioResuelto}`; 
        const paramsService = null;

        callApi(urlWebService, 'GET', paramsService, (response) => {
          this.setState({
              
            ClaFormulario: response.data.ClaFormulario,
            NomFormulario: response.data.NomFormulario,
            FechaCaptura				: response.data.FechaCaptura,
            ClaDepartamento			: response.data.ClaDepartamento,
            NomDepartamento			: response.data.NomDepartamento,
            ClaCelda					: response.data.ClaCelda, 
            ClaTurno					: response.data.ClaTurno, 
            NomUsuarioResuelve		: response.data.NomUsuarioResuelve, 
            ClaveCelda		: response.data.ClaveCelda,
            ABCMachine: response.data.ABCMachine,
            NomTurno		: response.data.NomTurno, 
            DefaultCantidadPreguntas: response.data.DefaultCantidadPreguntas,
            FirmaDigital: response.data.FirmaDigital,
            EsRequiereRevision: response.data.EsRequiereRevision,
            preguntas: response.data.preguntas, 
            Shifts:response.data.Turnos,
            Departments:response.data.Departamentos, 
            IsLoading:false
          });
  
  
        });  
    }


    render(){  

        if (this.state.IsLoading){
            return (
              <>    
                <Row>  
                  <Col xs={12} md={4} sm={6} lg={4}>
                    <Card>
                      <CardHeader>
                        <CardTitle><Skeleton className="p-mb-2" height="2rem"></Skeleton></CardTitle>
                      </CardHeader>    
                      <CardBody>    
                        <Row>
                          <div className="col ml--2">
                            <Skeleton width="10rem" className="p-mb-2"></Skeleton>  
                            <small><Skeleton width="2rem" className="p-mb-2"></Skeleton> </small>
                          </div>
                          <Col className="col-auto">  
                            <Button color="primary" className="btn-icon btn-3" size="sm" type="button"> 
                              <span className="btn-inner--text"><Skeleton width="2rem" className="p-mb-2"></Skeleton> </span>  
                            </Button> 
                          </Col>
                        </Row>
                      </CardBody>
                    </Card> 
                  </Col> 
                </Row> 
            
              </>
  )
          }

           
      return (
        <>
          <Row> 
            <Col lg={12} md={12} sm={12} xs={12}>
              <h2>{this.props.t('formulario:QuestionnaireResultTitle')}</h2>  
            </Col>
          </Row> 
          <Row> 
            <Col lg={12} md={12} sm={12} xs={12}>
              <FormularioResuelto formularioResuelto={this.state} returnResultados={this.props.returnResultados}></FormularioResuelto>
            </Col>  
          </Row>    
        </> 
        );
    }

}

export default  withTranslation(['formulario','common'])(ResultadoFormularioDet);
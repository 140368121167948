/* eslint-disable object-shorthand */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React  from 'react';    
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter, 
    CardTitle, 
    Row,
    Col 
  } from "reactstrap"; 
import Highcharts from 'highcharts'
 import HighchartsReact from 'highcharts-react-official';

const SparkLine =  (props) => {
    
    
    const defaultOptions = {
        chart: {
        backgroundColor: null,
        borderWidth: 0,
        type: 'area',
        margin: [2, 0, 2, 0],
        /* width: '100%', */ 
        height: props.height == null ? 40 : props.height,
        style: {
            overflow: 'visible'
        },
    
        // small optimalization, saves 1-2 ms each sparkline
        skipClone: true
        },
        title: {
        text: ''
        },
        credits: {
        enabled: false
        },
        xAxis: {
        lineWidth: 0,
        minorGridLineWidth: 0,
        labels: {
            enabled: false
        },
        title: {
            text: null
        },
        startOnTick: false,
        endOnTick: false,
        tickPositions: []
        },
        yAxis: {
        gridLineWidth:0,
        minorGridLineWidth: 0,
        lineWidth: 0,
        endOnTick: false,
        startOnTick: false,
        labels: {
            enabled: false
        },
        title: {
            text: null
        },
        tickPositions: [0]
        },
        legend: {
        enabled: false
        },
        tooltip: {
            useHTML:true,
            backgroundColor: 'white',
            borderWidth: 1,
            hideDelay: 0,
            shared: true,
            padding: 8,
            borderColor: 'silver',
            borderRadius: 3, 
            positioner: function (w, h, point) {
                return { x: point.plotX - w / 2, y: point.plotY - h };
            },
            valueDecimals: 2,
        },
        plotOptions: {
        series: {
            animation: false,
            lineWidth: 1,
            shadow: false,
            color: props.color != null ? props.color : "#ff3594",
            states: {
            hover: {
                lineWidth: 1
            }
            },
            marker: {
            radius: 1,
            states: {
                hover: {
                radius: 2
                }
            }
            },
            fillOpacity: 0.05
        },
        column: {
            negativeColor: '#910000',
            borderColor: 'silver'
        }
        },
    
        series: [{
        data: [1,2,3]
        }]
    };

    const options = Highcharts.merge(defaultOptions, props.options) 
 
     
    return (  
      <>  
        <HighchartsReact highcharts={Highcharts} options={options} />
      </> 
    )
}   

    
export default SparkLine; 

      
import React, { useState } from "react";
import { Galleria } from "primereact/galleria";
import "./FieldGalleria.scss"

const FieldGalleria = ({
  value,
  header,
  footer,
  className,
  item,
  thumbnail,
  indicator,
  caption,
  circular,
  autoplay,
  transitionInterval,
  numVisible,
  responsiveOptions,
  thumbnailsPosition,
  indicatorsPosition,
  verticalThumbnailViewPortHeight,
  showItemNavigators,
  showThumbnailNavigators,
  showThumbnails,
  showIndicators,
  showIndicatorsOnItem,
  showItemNavigatorsOnHover,
  changeItemOnIndicatorHover,
  fullScreen
}) => {
  return (
    <>
      <Galleria
        value={value}
        header={header}
        footer={footer}
        className={className}
        item={item}
        thumbnail={thumbnail}
        indicator={indicator}
        caption={caption}
        circular={circular}
        autoplay={autoplay}
        transitionInterval={transitionInterval}
        numVisible={numVisible}
        responsiveOptions={responsiveOptions}
        thumbnailsPosition={thumbnailsPosition}
        indicatorsPosition={indicatorsPosition}
        verticalThumbnailViewPortHeight={verticalThumbnailViewPortHeight}
        showItemNavigators={showItemNavigators}
        showThumbnailNavigators={showThumbnailNavigators}
        showThumbnails={showThumbnails}
        showIndicators={showIndicators}
        showIndicatorsOnItem={showIndicatorsOnItem}
        showItemNavigatorsOnHover={showItemNavigatorsOnHover}
        changeItemOnIndicatorHover={changeItemOnIndicatorHover}
        fullScreen={fullScreen}
      />
    </>
  )
}

export default FieldGalleria;
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PageContent from '../../../layouts/PageContent';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Panel } from 'primereact/panel';
import queryString from 'query-string';
import LibraryList from './LibraryList';
import LibraryAddNew from './LibraryAddNew';
import LibraryFilter from './LibraryFilter';

const LibraryScreen = (props) => {
  /* ################## useState area start ################## */
  const [EsListado, setEsListado] = useState(true);
  const [EsNuevo, setEsNuevo] = useState(true);
  const [ClaLibrary, setClaLibrary] = useState(null);
  const [ClaFolder, setClaFolder] = useState(null);
  const [ClaFolderPadre, setClaFolderPadre] = useState(null);
  const [EsShowDialog, setEsShowDialog] = useState(false);
  const [refresh, setrefresh] = useState(0);
  const [filtersData, setfiltersData] = useState({});
  const { t } = useTranslation(['dms', 'common']);

  useEffect(() => {}, []);

  const onAfterSelect = (data) => {
    setClaLibrary(data.ClaLibrary);
    // setEsNuevo(data.EsNuevo);
    setEsShowDialog(true);
  };

  const onAfterReturn = () => {
    setEsShowDialog(false);
    setClaFolder(0);
    setClaFolderPadre(0);
    setrefresh(refresh + 1);
  };

  const onCloseDialog = () => {
    setEsShowDialog(false);
  };

  const onSearch = (filters) => {
    setfiltersData({ ...filters });
  };
  return (
    <>
      <PageContent title={t('dms:TitleLibraryScreen')}>
        {!EsShowDialog ? (
          <>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <LibraryFilter onSearch={onSearch} onAfterSelect={onAfterSelect}></LibraryFilter>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                &nbsp;
              </Col>
            </Row> 
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <LibraryList
                  onAfterSelect={onAfterSelect}
                  refresh={refresh}
                  filtersData={filtersData}
                  EsEditar={false}
                >
                </LibraryList>
              </Col>
            </Row> 
          </>
        ) : (
          <></>
        )}
        {/* <Row>
            <Col>
              { !EsShowDialog ? <LibraryList onAfterSelect={onAfterSelect} refresh={refresh} filtersData={filtersData}></LibraryList> : <></>}
            </Col>
          </Row>       */}
        {EsShowDialog ? (
          <LibraryAddNew
            show={EsShowDialog}
            onHide={() => onCloseDialog()}
            onAfterReturn={onAfterReturn}
            EsNuevo={EsNuevo}
            ClaLibrary={ClaLibrary}
          >
          </LibraryAddNew>
        ) : (
          <></>
        )}
      </PageContent>
    </>
  );
};

export default LibraryScreen;

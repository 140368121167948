import { React, UseState, UseEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PageContent from 'layouts/PageContent';
import ARAgingDash from './ARAgingDash';

const ARAgingDashScreen = (props) => {
  const {t, i18n} = useTranslation(['salesPerformance', 'common']);
  
  return (
    <PageContent title={t('salesPerformance:ARAgingDashScreen')}>
      <ARAgingDash />
    </PageContent>
  )
}

export default ARAgingDashScreen;
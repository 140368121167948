import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardHeader, CardBody, CardFooter, Label } from 'reactstrap';
import { callApi, showSweetAlert } from "utils/utils";
import { config } from 'utils/config';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import FixPODForm from './FixPODForm';
import FieldText from 'components/Controls/FieldText';
import { TabView, TabPanel } from 'primereact/tabview';
import FixUnidentifiedDocuments from './FixUnidentifiedDocuments';
import FixPODType from './FixPODTypes';

const FixPODsWithErrors = (props) => {
  const {t, i18n} = useTranslation(['pod', 'common']);

  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
      <TabPanel header={t('pod:Emails')}>
        <FixUnidentifiedDocuments docType={FixPODType.Email} />
      </TabPanel>
      <TabPanel header={t('pod:CxPDeacero')}>
        <FixUnidentifiedDocuments docType={FixPODType.Deacero} />
      </TabPanel>
    </TabView>
  )
}

export default FixPODsWithErrors;
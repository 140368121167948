import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Label } from 'reactstrap';
import { callApi, showSweetAlert } from "utils/utils";
import { config } from 'utils/config';
import FieldText from 'components/Controls/FieldText';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import YupValidator from 'utils/YupValidator';
import { object, string, number } from 'yup';
import FixPODForm from './FixPODForm';
import FixPODType from './FixPODTypes';

const ManualPODSearch = (props) => {
  const {t, i18n} = useTranslation(['pod', 'common']);
  const [soNumber, setSoNumber] = useState(null);
  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [errors, setErrors] = useState([]);
  const [tiposPOD, setTiposPOD] = useState([]); // POD types
  const [tipoPOD, setTipoPOD] = useState(2); // Selected POD type
  const [pods, setPODs] = useState([]); // Returned PODs
  const [fixDocument, setFixDocument] = useState({}); // Document to send to the FixPODForm
  const [showFixManualPODSearch, setShowFixManualPODSearch] = useState(false); // Show the dialog to fix the POD

  useEffect(() => {
    getTiposPOD();
  }, [])

  const getTiposPOD = () => {
    const urlWebService = `${config.UrlApiProject}POD/GetTiposPOD`;

    const paramsService = {
    };

    callApi(urlWebService, 'GET', paramsService, (response) => {
      setTiposPOD(response.data);
    });
  }

  const onSoNumberChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.soNumber;
    setErrors(newErrors);
    setSoNumber(e.target.value);
  }

  const onInvoiceNumberChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.invoiceNumber;
    setErrors(newErrors);
    setInvoiceNumber(e.target.value);
  }

  const onTipoPODChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.priority;
    setErrors(newErrors);
    setTipoPOD(e.value);
  }

  const onSearchClick = async (e) => {
    const validationScheme = object({
      invoiceNumber: string().required(t('common:RequiredField')).min(1).nullable(),
      tipoPOD: number().required(t('common:RequiredField')).positive().integer().nullable(),
    })

    const value = {
      invoiceNumber,
      tipoPOD
    }

    const validator = new YupValidator(validationScheme);
    const isValid = await validator.validate(value);
    if (!isValid) {
      setErrors(validator.errors);
    }
    else {
      const urlWebService = `${config.UrlApiProject}POD/ManualPODSearch`;

      const paramsService = {
        NumFactura: invoiceNumber,
        ClaTipoPOD: tipoPOD
      };

      callApi(urlWebService, 'POST', paramsService, (response) => {
        const {data} = response;

        const podArr = [];

        if (data.length > 0)
        {
          // Loop through the returned PODs and add them to the array
          if (Object.values(data[0].Pages).length > 0)
          {
            for (const podPage of Object.values(data[0].Pages))
            {
              for (const pod of podPage)
              {
                // Check for duplicates and remove errors
                let exists = false;
                for (const existingPod of podArr)
                {
                  exists = existingPod.IdPod === pod.IdPod || !Object.hasOwn(pod, 'IdPod');

                  if (exists)
                  {
                    break;
                  }
                }

                if (!exists && Object.hasOwn(pod, 'IdPod'))
                {
                  podArr.push(pod);
                }
              }
            }
          }
        }

        if (podArr.length === 0)
        {
          // If no POD is found in the document get all the pages to show the user
          if (data[0].AllPages !== undefined && data[0].AllPages.length > 0)
          {
            const allPages = data[0].AllPages;
            const pages = []
            for (let i = 0; i < allPages.length; i++) {
              pages.push(
                {
                  PageNumber: allPages[i].Page.pageNum,
                  PageFile: allPages[i].Page.pageFile,
                  ExtractedData: allPages[i].ExtractedData
                }
              )
            }

            setFixDocument({Pages: pages});
            setShowFixManualPODSearch(true);
          }
          else {
            showSweetAlert('Invoice Not Found', `No invoice was found in CxP for ${invoiceNumber}`, 'warning');
          }
        }
        else
        {
          props.setInvoiceNum(invoiceNumber);
          showSweetAlert('Success', t('common:Success'), 'success');
        }
        
        // Log for troubleshooting
        console.log('Response: ', response);
        console.log('Data: ', data);
        console.log('podArr: ', podArr);

        setPODs(podArr);
        setErrors([]);
      });
    }
  }

  const onFixManualPODSearchHide = () => {
    setShowFixManualPODSearch(false);
    props.setInvoiceNum(invoiceNumber);
    
  }

  const submitManualPODAsFixed = (newDocPages) => {
    const completedDoc = {
      InvoiceNumber: invoiceNumber,
      ClaTipoPOD: tipoPOD,
      Pages: newDocPages
    }

    const url = `${config.UrlApiProject}POD/FixManualPOD`;
    const paramsService = completedDoc; 
    callApi(url, 'POST', paramsService, (response) => {
      showSweetAlert("Success", t('common:Success'), 'success');
      onFixManualPODSearchHide();
    });   
  }

  const downloadFile = (row) => { 
    const url = `${config.UrlApiProject}POD/DownloadPOD?IdPod=${row.IdPod}`;  
    const paramsService= {};  
    callApi(url, 'GET', paramsService, (response) => {
      const downloadFile = response.data.File;
      if(downloadFile !== undefined){
        const linkSource = `data:application/${response.data.FileExtension};base64,${downloadFile}`
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = `POD-${row.NumFactura}`;
        downloadLink.click();
      }
    });
 }

  
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString(
      'en-us',
      {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }
    )
  }

  const fechaFacturaColumn = (row) => {
    return row.FechaFactura !== null ? formatDate(row.FechaFactura) : '';
  }

  const optionsColumn = (row) => {
    return (
      <>
        <FieldButton
          className='p-button-rounded p-button-warning'
          icon='pi pi-download'
          tooltip={t('pod:DownloadPDF')}
          onClick={() => downloadFile(row)}
        />
      </>
    )
  }

  return (
    <>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} className='mb-2'>
          <span className='text-warning'>
            <i className='pi pi-info-circle mr-2' />
            {t('pod:ManualPODSearchWarning')}
          </span>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} className='p-field p-fluid'>
          <Label>{t('pod:PODType')}</Label>
          <FieldDropdown
            name='tipoPOD'
            value={tipoPOD}
            optionValue="ClaTipoPOD"
            optionLabel="NomTipoPOD"
            options={tiposPOD}
            onChange={onTipoPODChange}
            errors={errors}
            className='mb-2'
            disabled
          />
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} className='p-field p-fluid'>
          <Label>{t('pod:InvoiceNum')}</Label>
          <FieldText
            name='invoiceNumber'
            value={invoiceNumber}
            onChange={onInvoiceNumberChange}
            errors={errors}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={6} lg={6} className='p-field'>
          <FieldButton
            label={t('common:Search')}
            onClick={() => onSearchClick()}
            icon='pi pi-search'
          />
        </Col>
      </Row>
      <Row>
        { pods.length > 0 ?
          (
            <>
              <Col xs={12} sm={12} md={12} lg={12} className='mb-2'>
                <span className='text-warning'>
                  <i className='pi pi-info-circle mr-2' />
                  {t('pod:ManualPODsFoundWarning')}
                </span>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} className=''>
                <FieldDataTable 
                  value={pods} 
                  header={t('pod:PODs')}  
                  scrollable 
                  className="p-datatable-striped"
                >  
                  <Column field="PODType" header={t('pod:PODType')} sortable headerStyle={{ width: '60px' }}></Column>
                  <Column field="NumFactura" header={t('pod:InvoiceNum')} sortable headerStyle={{ width: '60px' }}></Column>
                  <Column field="NomProveedor" header={t('pod:Vendor')} sortable headerStyle={{ width: '120px' }}></Column>
                  <Column field="SoNum" header={t('pod:SONum')} sortable headerStyle={{ width: '50px' }}></Column>
                  <Column body={fechaFacturaColumn} header={t('pod:FechaFactura')} sortable sortField='ShipDate' headerStyle={{ width: '60px' }}></Column>
                  <Column field="BillToName" header={t('pod:BillTo')} sortable headerStyle={{ width: '170px' }}></Column>
                  <Column field="ShipToName" header={t('pod:ShipTo')} sortable headerStyle={{ width: '170px' }}></Column>
                  <Column body={optionsColumn} header={t('pod:Options')} headerStyle={{ width: '110px' }}></Column>
                </FieldDataTable>
              </Col>
            </>
          ) 
          : <></> }
      </Row>
      <Dialog
        visible={showFixManualPODSearch}
        onHide={() => onFixManualPODSearchHide()}
        maximized={true}
        modal
      >
        <FixPODForm 
          document={fixDocument}
          documentTitle={`Manual Search: ${invoiceNumber}`}
          submitDocumentAsFixedToAPI={submitManualPODAsFixed}
          docType={FixPODType.Manual}
        />
      </Dialog>
    </>
  )
}

export default ManualPODSearch;
/* eslint-disable radix */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'; 
import { useTranslation } from 'react-i18next'; 
import PageContent from '../../../layouts/PageContent';  
import { Row, Col } from 'reactstrap';   
import { Button } from 'primereact/button'; 
import EdicionProyecto  from "./EdicionProyecto.jsx";
import queryString from 'query-string'  

const EditarProyecto = (props) => { 
  
 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['myactivities', 'common']); 
    
   let claProyecto; 

   if(props.location!==undefined){
    const params = queryString.parse(props.location.search);
    claProyecto = params !== undefined ? parseInt(params.ClaProyecto) : 0;
   }

    return (
      <>  
        <PageContent title={t('common:EditProject')}>  
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}> 
              { claProyecto > 0 ? (<EdicionProyecto ClaProyecto={claProyecto}></EdicionProyecto>) : (<></>)}   
              
            </Col>  
          </Row> 
        </PageContent>
      </>  
        ); 
};

export default EditarProyecto;

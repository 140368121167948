/* eslint-disable jsx-a11y/anchor-is-valid */
import { config } from '../../../utils/config';
import { callApi, showSweetAlert } from '../../../utils/utils';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import moment from 'moment';
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon';
import swal from '@sweetalert/with-react';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import { Column } from 'primereact/column';

const LibraryList = (props) => {
  const { t } = useTranslation(['dms', 'common']);
  const [FolderLibraryList, setFolderLibraryList] = useState([]);
  const expandedRowKeys = [0];

  useEffect(() => {
    loadLibraryList(props.filtersData.ClaLibrary, null, null, null, null);
  }, [props.refresh, props.filtersData]);

  const loadLibraryList = (pClaLibrary, pClaFolder, pClaFolderPadre, pClaRole, pClaLibraryAttribute) => {
    const GetTeamPerformanceDevelopmentInfo = `${config.UrlApiProject}DMS/GetFolderLibraryList`;
    const paramsToService = {
      ClaLibrary: pClaLibrary,
      ClaFolder: pClaFolder,
      ClaFolderPadre: pClaFolderPadre,
      ClaRole: pClaRole,
      ClaLibraryAttribute: pClaLibraryAttribute,
    };

    callApi(GetTeamPerformanceDevelopmentInfo, 'POST', paramsToService, (response) => {
      setFolderLibraryList(response.data.Library);
    });
  };

  const onSearch = () => {
    loadLibraryList(null, null, null, null, null);
  };

  const onAddFolder = () => {
    if (props.onAfterSelect != null) {
      const data = { ClaLibrary: null };
      props.onAfterSelect(data);
    }
  };

  const onAdd = (row) => {
    console.log('onAdd', row);
    if (props.onAfterSelect != null) {
      row.data.EsNuevo = true;
      props.onAfterSelect(row.data);
    }
  };

  const onEdit = (row) => {
    if (props.onAfterSelect != null) {
      props.onAfterSelect(row);
    }
  };

  const confirmarEliminar = (ClaLibrary) => {
    const urlWebServiceSave = `${config.UrlApiProject}DMS/PostCatFolderLibraryInactive`;
    const paramsService = {
      ClaLibrary,
    };
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {
      showSweetAlert(t('common:Exito'), t('common:SaveSuccess'), 'success');
      loadLibraryList(null, null, null, null, null);
    });
  };

  const onConfirmarEliminar = (Obj) => {    
    swal({
      buttons: {
        cancelar: {
          text: 'Cancel',
          value: 'cancel',
          className: 'rounded-button danger',
        },
        continuar: {
          text: 'Continue',
          value: 'continue',
          className: 'rounded-button light-green',
        },
      },
      content: confirmationMessageHTML,
    }).then((value) => {
      switch (value) {
        case 'continue':
          // run function
          confirmarEliminar(Obj.ClaLibrary);
          break;
        default:
          break;
      }
    });
  };

  const confirmationMessageHTML = (
    <div>
      <SweatAlertCustomIcon
        title={t('dms:DeleteLibraryConfirmation')}
        letter={t('common:QuestionMark')}
      >
      </SweatAlertCustomIcon>
      <div>
        <b>{t('dms:MessageConfirm')}</b>
      </div>
    </div>
  );

  const FechaUltimaModTemplate = (rowData) => {
    return (
      <>
        {rowData.FechaUltimaMod !== null
          ? moment(rowData.FechaUltimaMod).format('MM/DD/YYYY')
          : null}
      </>
    );
  }; 

  const LibraryColumn = (rowData, props) => {
    return (
      <>
        <Button
          // label={t('common:Edit')}
          icon="pi pi-pencil"
          className="p-highlight p-button-rounded no-border"
          // className="p-button-sm p-1 info"
          onClick={(e) => onEdit(rowData)}
        />
        &nbsp;
        <Button
          icon="pi pi-trash"
          className="p-button-rounded no-border p-button-warning"
          // label={t('common:Remove')}
          // icon="fa fa-ban"
          // className="light-red no-border p-mr-2 rounded-button"
          // className="p-button-sm p-1 danger"
          onClick={(e) => onConfirmarEliminar(rowData)}
        />
      </>
    );
  };

  return (
    <>
      {/* <Row className="pb-2">
        <Col lg={10} md={10} sm={10} xs={10}>
          <h2>{t('dms:Library')}</h2>
        </Col>
        <Col lg={2} md={2} sm={2} xs={2} className="text-right">
          <Button
            label={t('common:New')}
            icon="pi pi-plus"
            onClick={(e) => onAddFolder()}
            className="light-green no-border p-mr-2"
          />{' '}
          &nbsp;
          <Button
            label={t('common:Refresh')}
            icon="pi pi-refresh"
            onClick={(e) => onSearch()}
            className="p-button p-highlight"
          />
        </Col>
      </Row> */}
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <FieldDataTable
            value={FolderLibraryList}
            scrollable
            className="editable-cells-table"
            editMode="cell"
            selectionMode="single"
            paginator={false}
          >
            <Column
              field="NomLibrary"
              columnKey="NomLibrary"
              header={t('dms:NomLibrary')}
              headerStyle={{ width: '350px' }}
            >
            </Column>
            {
            /* 
            <Column
              field="NomFolder"
              columnKey="NomFolder"
              header={t('dms:Folder')}
              headerStyle={{ width: '250px' }}
            >
            </Column>
            */
            }
            <Column
              field="NomColaborador"
              columnKey="NomColaborador"
              header={t('dms:CreateBy')}
              headerStyle={{ width: '250px' }}
            >
            </Column>
            <Column
              field="FechaUltimaMod"
              columnKey="FechaUltimaMod"
              header={t('dms:LastUpd')}
              headerStyle={{ width: '115px' }}
              body={FechaUltimaModTemplate}
            >
            </Column>
            <Column                
              body={(rowData, props) => LibraryColumn(rowData, props)}
              headerStyle={{ width: '150px' }}
              className="text-right"
            >
            </Column>
          </FieldDataTable>
        </Col>
      </Row>
    </>
  );
};

export default LibraryList;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'; 
import { useTranslation } from 'react-i18next'; 
import { config } from '../../../utils/config';
import { callApi, showSweetAlert,tracker} from '../../../utils/utils';  
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Dialog } from 'primereact/dialog'; 
import { Column } from 'primereact/column';
import { Row,Col, TabContent, TabPane , Nav, NavItem, NavLink} from 'reactstrap';   
import FieldText from '../../../components/Controls/FieldText.jsx';
import FieldCalendar from '../../../components/Controls/FieldCalendar.jsx';
import FieldToogleSwitch from '../../../components/Controls/FieldToogleSwitch/FieldToogleSwitch.jsx';
import FieldRadioButton from '../../../components/Controls/FieldRadioButton/FieldRadioButton.jsx';
import FieldDropdown from '../../../components/Controls/FieldDropdown.jsx';
import FieldAutoComplete from '../../../components/Controls/FieldAutoComplete.jsx';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon';
import swal from '@sweetalert/with-react'; 
import { useHistory, Link } from "react-router-dom";
import FieldCheckbox from '../../../components/Controls/FieldCheckbox.jsx';


const AltaProyecto = (props) => {  
    

   /* ################## useState area start ################## */
   const { t } = useTranslation(['caratulaproyecto', 'common']); 
   const [TabActiveIndex, setTabActiveIndex] = useState(1);
   const [errors, setErrors] = useState({}); 
   const [NomProyecto, setNomProyecto] = useState(null);
   const [FechaEstimadaFin , setFechaEstimadaFin ] = useState(null); 
   const [FechaEstimadaInicio , setFechaEstimadaInicio ] = useState(null);
   const [EsProyectoMaestro , setEsProyectoMaestro ] = useState(false);
   const [yesNoOptions, setYesNoOptions] = useState([`${t('common:On')}`, `${t('common:Off')}`]);
   const [ClaTipoProyecto, setClaTipoProyecto] = useState(null);
   const [IndicadorIniciativa, setIndicadorIniciativa] = useState([]); 
   const [ClaIndicador, setClaIndicador]= useState(null);
   const [EsScorecardAdmin, setScorecardAdmin]= useState(false);
   const [filteredResponsible, setfilteredResponsible] = useState([]);
   const [ClaInvolucrado, setClaInvolucrado] = useState(null);   
   const [OpcionesRoleProyecto, setOpcionesRoleProyecto] = useState([]); 
   const [ClaRolProyecto, setClaRolProyecto] = useState(null);   
   const [ProyectoInvolucrados, setProyectoInvolucrados] = useState([]);
   const [EsMostrarSoloUsuarioMSW, setEsMostrarSoloUsuarioMSW] = useState(false);
   
   const [OpcionesTipoProyecto, setOpcionesTipoProyecto] = useState([]);
   const [ClaProyecto, setClaProyecto] = useState(null);   

    useEffect(() => {   
      obtenerTipoProyecto();
        obtenerIndicadorIniciativa();
        obtenerRolesProyecto();
        inicializaInformacionDefault();
        setScorecardAdmin(true);
    }, []); 

   
    const inicializaInformacionDefault = () => { 
    
        const GetNewProjectDefaultInfo = `${config.UrlApiProject}ScoreCard/GetNewProjectDefaultInfo`;  
        const paramsToService = { 
            
        };   
        callApi(GetNewProjectDefaultInfo, 'POST', paramsToService, (response) => {  
            const defaultInvolucrado = {  
                ClaRolProyecto: response.data.InformacionDefault[0].ClaRolProyecto,
                NomRolProyecto: response.data.InformacionDefault[0].NomRolProyecto,       
                ClaUsuario	 : response.data.InformacionDefault[0].ClaUsuario	,
                NomInvolucrado: response.data.InformacionDefault[0].NomUsuario,
                BajaLogica: 0 
              }; 

            setProyectoInvolucrados([...ProyectoInvolucrados, defaultInvolucrado]);
        });

     
    }

   const switchTab = tab => {
    if(TabActiveIndex !== tab){
      setTabActiveIndex(tab);
    }
  }
  
  const valitationSchemeSave = yup.object().shape({
    NomProyecto:yup.string().required(t('common:RequiredField')).nullable(), 
    FechaEstimadaInicio:yup.date().required(t('common:RequiredField')).nullable(), 
    FechaEstimadaFin:yup.date().required(t('common:RequiredField')).nullable() , 
    ClaTipoProyecto: yup.mixed().required(t('common:RequiredField')).nullable() /* ,
    ClaIndicador: (
        EsScorecardAdmin && ClaTipoProyecto === 1
      ? yup.mixed().required(t('common:RequiredField')).nullable()
      : yup.mixed().nullable()
      ) 
    */
});  
  
const valitationSchemeAgregarInvolucrado = yup.object().shape({
    ClaInvolucrado:yup.object({ ClaInvolucrado: yup.string().required(t('common:RequiredField')) }).required(t('common:RequiredField')).nullable(), 
    ClaRolProyecto:yup.mixed().required(t('common:RequiredField')).nullable() 
});  

  const addNewMember = async (e) => {
      const value = {
        ClaInvolucrado,
        ClaRolProyecto 
      }

      const validator = new YupValidator(valitationSchemeAgregarInvolucrado);
      const esValido = await validator.validate(value);
      if (!esValido){  
        setErrors(validator.errors); 
        showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');  
        return;
      }

      
      const rolProyecto = OpcionesRoleProyecto.filter((item) => { return item.ClaRolProyecto === ClaRolProyecto; })[0];
 

      const nuevoInvolucrado = {  
        ClaRolProyecto: rolProyecto.ClaRolProyecto,
        NomRolProyecto: rolProyecto.NomRolProyecto,       
        ClaUsuario	 : ClaInvolucrado.ClaUsuario	,
        NomInvolucrado: ClaInvolucrado.NomUsuario,
        BajaLogica: 0 
      }; 

      setClaInvolucrado(null);
      setProyectoInvolucrados([...ProyectoInvolucrados, nuevoInvolucrado]);
  }

  const onSave = async (e) => {
    const value = {
        NomProyecto,
        FechaEstimadaInicio,
        FechaEstimadaFin,
        ClaTipoProyecto/* ,
        ClaIndicador */
      }

      const validator = new YupValidator(valitationSchemeSave);
      const esValido = await validator.validate(value);
      if (!esValido){  
        setErrors(validator.errors); 
        showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
        setTabActiveIndex(1);
        return;
      }

      // enviamos a guardar la informacion del proyecto... 
      const saveProyecto = `${config.UrlApiProject}ScoreCard/SaveNewProject`;

      const paramsService={
        NomProyecto, 
        FechaEstimadaInicio, 
        FechaEstimadaFin, 
        ClaTipoProyecto,
        ClaIndicador,
        EsProyectoMaestro: ClaTipoProyecto === 4 ? 1 : 0, // ClaTipoProyecto 4 = Proyecto Maestro
        ProyectoInvolucrado: ProyectoInvolucrados
      } 

       
      callApi(saveProyecto, 'POST', paramsService, (response) => { 
        tracker.trackEvent({
          type: 'AltaProyecto', /* component name */
          action: 'CREATE_NEW_PROJECT'
        }) 

        // showSweetAlert('success', t('common:SuccessOperation'), 'success');
        setClaProyecto(response.data.claProyecto);

        swal({ 
          buttons: {
            /*
            cancelar: {
              text: t('common:keepCurrentScreen'),
              value: "cancel",
              className: "rounded-button secondary"
            },
            */
            continuar: {
              text: t('common:Continue'),
              value: "continue",
              className: "rounded-button light-green"
            }
          },
          content: confirmationMessageHTML
        }).then((value) => {
          switch (value) { 
            case "continue":
              // redirect...       
              onEditProjectClick();  
              props.onHide(); 
              break; 
            default:
              props.onHide(true); 
              break;
          }
        });  
      });
      
      
  }
    
  const confirmationMessageHTML = (
    <div>
      <SweatAlertCustomIcon title={t('caratulaproyecto:NewProjectCreated')} letter={t('common:ExclamationMark')}></SweatAlertCustomIcon> 
      <div>{t('caratulaproyecto:ContinueEditingProject')}.</div> 
    </div>  
   ) 

   
    const onNomProyectoChange = (e) => {  
        const newErrors = { ...errors }
        delete newErrors.NomProyecto;   
        setErrors(newErrors);
        setNomProyecto(e.target.value);
      }
    
      const onFechaEstimadaInicio = ( e ) => {  
        const newErrors = { ...errors } 
        delete newErrors.FechaEstimadaInicio;   
        setErrors(newErrors) 
        setFechaEstimadaInicio(e.value)
      }; 

      const onFechaEstimadaFin = ( e ) => {  
        const newErrors = { ...errors } 
        delete newErrors.FechaEstimadaFin;   
        setErrors(newErrors) 
        setFechaEstimadaFin(e.value)
      }; 

      const onChangeClaTipoProyecto = (value) => {
        const newErrors = { ...errors } 
        delete newErrors.ClaTipoProyecto;   
        setErrors(newErrors) 
        setClaTipoProyecto(value);
        setClaIndicador(null);
      }

      const onChangeClaIndicador= (value) => {
        const newErrors = { ...errors } 
        delete newErrors.ClaIndicador;   
        setErrors(newErrors)  
        setClaIndicador(value);
      }
 
    
      const obtenerIndicadorIniciativa = () => { 
        const MSWBSCIndicadorIniciativa = `${config.UrlApiProject}ScoreCard/MSWBSCIndicadorIniciativa`;  
        const paramsToService = { 
          
        };   
        callApi(MSWBSCIndicadorIniciativa, 'POST', paramsToService, (response) => {  
            setIndicadorIniciativa(response.data[0]); 
        });
      }

      const obtenerTipoProyecto = () => { 
        const MSWBSCTipoProyecto = `${config.UrlApiProject}ScoreCard/MSWBSCTipoProyecto`;  
        const paramsToService = { 
          
        };   
        callApi(MSWBSCTipoProyecto, 'POST', paramsToService, (response) => {  
            setOpcionesTipoProyecto(response.data[0]); 
        });
      }

      const obtenerRolesProyecto = () => { 
        const GetRolProyectoCmb = `${config.UrlApiProject}ScoreCard/GetRolProyectoCmb`;  
        const paramsToService = { 
          
        };   
        callApi(GetRolProyectoCmb, 'GET', paramsToService, (response) => {  
            setOpcionesRoleProyecto(response.data.RolesProyecto); 
        });
      }
     
      const onChangeInvolucrado = (e) => {  
        const newErrors = { ...errors }
        delete newErrors.ClaInvolucrado;   
        setErrors(newErrors)
        setClaInvolucrado(e.value)
     }

     
     const onChangeClaRolProyecto = (value) => {  
        const newErrors = { ...errors }
        delete newErrors.ClaRolProyecto;   
        setErrors(newErrors)
        setClaRolProyecto(value)
     }
     

      const filterResponsible = (event) => {
        let valor = ''; 
        if (ClaInvolucrado !== null && ClaInvolucrado !== '' &&  event.query === "")
        {
         valor = ClaInvolucrado.NomUsuario;
        }
        else{
         valor = event.query;
        }
        const paramsServiceOwners = {};
        // event.query 
        const urlWebServiceOwners = `${config.UrlApiProject}ScoreCard/GetUsuariosCmb?descriptionFilter=${valor}&EsMostrarSoloUsuarioMSW=${!EsMostrarSoloUsuarioMSW?1:0}`;
        // Get owner
        callApi(urlWebServiceOwners, 'GET', paramsServiceOwners, (response) => { 
           setfilteredResponsible(response.data.Usuarios);
        });    
        
      }

      const renderHeader = () => {
        return (
          <> 
            { t('caratulaproyecto:NuevoProyecto')}    
          </>
        );
    }

    const renderFooter = () => {
        return (
          <>  
            <div className="text-center">
              <FieldButton label={t('common:Cancel')} className="secondary no-border pr-3 pl-3 rounded-button btn-min-width" onClick={props.onHide}  /> 
              <FieldButton label={t('common:Save')} className="light-green no-border pr-3 pl-3 rounded-button btn-min-width" onClick={onSave}  /> 
            </div>
          </>
        );
    }

    const removeColumn = (rowData, props) => {
        return (
          <> 
            <FieldButton icon="pi pi-trash" onClick={() => onConfirmBaja(rowData,props)} className="p-button-rounded p-button-warning" /> 
          </>
        );
      } 

      const onConfirmBaja = (rowData, props) => { 
        // aqui lo borro de mi arreglo y hago el ProyectoInvolucrados
        const proyectoInvolucradosNew = [...ProyectoInvolucrados];
        proyectoInvolucradosNew.splice(props.rowIndex, 1);
        setProyectoInvolucrados(proyectoInvolucradosNew); 
    }

    const editProjectLink = useRef(null);

    const onEditProjectClick = () => {
      editProjectLink.current.click();
    }

    const onChangeIncluirSoloUsuariosMSW = (props, e) => {
      setEsMostrarSoloUsuarioMSW(e.checked ? 1 : 0); 
    };
  

    return (
      <>  
        <Dialog visible={props.show} modal style={{width: '80vw' }} footer={renderFooter} header={renderHeader} onHide={props.onHide}>    
          <div className="p-tabview p-component pb-3">
            <Nav className="p-tabview-nav">
              <NavItem className={TabActiveIndex === 1 ? "p-tabview-selected p-highlight p-unselectable-text nav-item flex-sm-fill" : "p-unselectable-text nav-item flex-sm-fill"}>
                <NavLink className="p-tabview-nav-link" onClick={() => { switchTab(1); }}>
                  {t('caratulaproyecto:Detail')}
                </NavLink>
              </NavItem>
              <NavItem className={TabActiveIndex === 2 ? "p-tabview-selected p-highlight p-unselectable-text nav-item flex-sm-fill" : "p-unselectable-text nav-item flex-sm-fill"}>
                <NavLink className="p-tabview-nav-link" onClick={() => { switchTab(2); }}>
                  {t('caratulaproyecto:Members')}
                </NavLink>
              </NavItem>
            </Nav> 
          </div>
          <TabContent className="p-tabview-panels" activeTab={TabActiveIndex}>
            <TabPane tabId={1}>  
              <Row className="align-items-start">
                <Col lg={6} md={6} sm={12} xs={12}> 
                  <Row>
                    <Col lg={12}>
                       &nbsp; 
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <div className="p-fluid ">
                        <div className="p-field"> 
                          <FieldText
                            name="NomProyecto"
                            value={NomProyecto} 
                            label={t('caratulaproyecto:NomProyecto')}
                            onChange={onNomProyectoChange} 
                            errors={errors} 
                            maxLength={1000}
                            autoFocus 
                          /> 
                        </div> 
                      </div> 
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                       &nbsp; 
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="p-fluid ">
                        <div className="p-field"> 
                          <FieldCalendar
                            name="FechaEstimadaInicio" 
                            label={t('caratulaproyecto:FechaEstimadaInicio')}
                            value={(FechaEstimadaInicio!== null ? (new Date(FechaEstimadaInicio)): null)}
                            onChange={(e) => onFechaEstimadaInicio(e)} 
                            errors={errors}
                          />  
                        </div> 
                      </div> 
                    </Col>
                    <Col lg={6}>
                      <div className="p-fluid ">
                        <div className="p-field"> 
                          <FieldCalendar
                            name="FechaEstimadaFin" 
                            label={t('caratulaproyecto:FechaEstimadaFin')}
                            value={(FechaEstimadaFin!== null ? (new Date(FechaEstimadaFin)): null)}
                            onChange={(e) => onFechaEstimadaFin(e)} 
                            errors={errors}
                          />  
                        </div> 
                      </div> 
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}> 
                  <Row className="align-items-start" hidden={true}>
                    <Col lg={8}>
                      <span className="h2 mb-0"><label className="h2 mb-0">{t('caratulaproyecto:EsProyectoMaestro')}</label></span> 
                      <div><small>{t('caratulaproyecto:EsProyectoMaestroAyuda')}</small></div>
                     
                      
                    </Col>
                    <Col lg={2} className="text-right">
                      <FieldToogleSwitch
                        id="EsProyectoMaestro"
                        checked={EsProyectoMaestro}
                        onChange={setEsProyectoMaestro} 
                        optionLabels={yesNoOptions}
                        small
                      />
                    </Col> 
                  </Row>
                  <Row>
                    <Col lg={12}>
                       &nbsp; 
                    </Col>
                  </Row>
                  <Row className="align-items-start">
                    <Col lg={12}>
                      <h2><label>{t('caratulaproyecto:TipoProyecto')}?</label> </h2>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      {
                        OpcionesTipoProyecto.map((itemTipoProyecto) => {
                            return ( 
                              <div key={itemTipoProyecto.ClaTipoProyecto}>
                                <FieldRadioButton
                                  inputId={`tipoProyecto-${itemTipoProyecto.ClaTipoProyecto}`}
                                  name="ClaTipoProyecto" 
                                  value={itemTipoProyecto.ClaTipoProyecto}
                                  label={itemTipoProyecto.NomTipoProyecto}
                                  onChange={(value) => onChangeClaTipoProyecto(value)} 
                                  checked={ClaTipoProyecto === itemTipoProyecto.ClaTipoProyecto}
                                  errors={errors}
                                >
                                </FieldRadioButton>
                                {
                                  /*
                                
                                {
                                    itemTipoProyecto.ClaTipoProyecto === 1 ? 
                                    (
                                      <>
                                        {
                                           EsScorecardAdmin && ClaTipoProyecto === 1 ?
                                           (
                                             <>
                                               <div><label>{t('caratulaproyecto:SeleccionIniciativaAyuda')}</label></div>
                                               <div className="p-fluid">
                                                 <FieldDropdown  
                                                   name="ClaIndicador"  
                                                   value={ClaIndicador} 
                                                   options={IndicadorIniciativa}  
                                                   optionValue="ClaIndicador"
                                                   optionLabel="NomIndicador" 
                                                   onChange={(e) => onChangeClaIndicador(e.value)}
                                                   errors={errors}
                                                 />  
                                               </div>
                                             </>
                                           )
                                           :
                                             <></>
                                       }
                                        
                                      </>
                                    )
                                    :
                                      <></>
                                }

                                */
                                }
                              </div> 
                            )
                            })
                        } 
                    </Col>
                  </Row>
                </Col>
              </Row> 
            </TabPane>
            <TabPane tabId={2}>
              <Row>
                <Col lg={12}>
                  &nbsp;
                </Col>
              </Row>
              <Row>
                <Col lg={2} md={2} sm={12} xs={12}>
                  <div className="p-fluid">
                    <span className="mr-2">{t('misProyectosScreen:EsMostrarSoloUsuarioMSW')}</span>
                    <FieldCheckbox
                      inputId={EsMostrarSoloUsuarioMSW}
                      checked={EsMostrarSoloUsuarioMSW}
                      onChange={(e) => onChangeIncluirSoloUsuariosMSW(props, e)}
                    />
                  </div> 
                </Col>
                <Col lg={5} md={5} sm={12} xs={12}> 
                  <Row> 
                    <Col>
                      <div className="p-fluid"> 
                        <FieldAutoComplete
                          dropdown
                          name="ClaInvolucrado"
                          label={t('caratulaproyecto:SeleccionaInvolucrado')}
                          value={ClaInvolucrado} 
                          onChange={onChangeInvolucrado}  
                          suggestions={filteredResponsible}
                          field="NomUsuario"
                          completeMethod={filterResponsible}
                          errors={errors}
                        /> 
                      </div>      
                    </Col>
                  </Row>
                </Col>
                <Col lg={3} md={3} sm={12} xs={12}> 
                  <div className="p-fluid">
                    <FieldDropdown  
                      name="ClaRolProyecto"  
                      value={ClaRolProyecto} 
                      options={OpcionesRoleProyecto}  
                      optionValue="ClaRolProyecto"
                      optionLabel="NomRolProyecto" 
                      onChange={(e) => onChangeClaRolProyecto(e.value)}
                      label={t('caratulaproyecto:SeleccionaRole')}
                      errors={errors}
                    />  
                  </div>
                </Col>
                <Col lg={2} md={2} sm={12} xs={12}> 
                  <FieldButton label={t('common:Add')} className="primary no-border pr-3 pl-3 rounded-button" onClick={addNewMember}  /> 
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                       &nbsp; 
                </Col>
              </Row>
              <Row>
                <Col>
                  <FieldDataTable 
                    value={ProyectoInvolucrados}   
                    scrollable  
                    className="p-datatable-striped"  
                    selectionMode="single"  
                    scrollHeight="240px"
                    metaKeySelection={false} 
                  >     
                    <Column body={(rowData, props) => removeColumn(rowData, props)} headerStyle={{ width: '70px' }}></Column>
                    <Column field="NomInvolucrado" header={t('caratulaproyecto:Involucrado')} headerStyle={{ width: '80%' }} className="text-left"></Column>  
                    <Column field="NomRolProyecto" header={t('caratulaproyecto:ProyectoRol')} headerStyle={{ width: '20%' }} className="text-left"></Column>   
                  </FieldDataTable>  
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Dialog>
        <Link 
          ref={editProjectLink} 
          to={
                  { pathname: "EditProject" 
                  , search: `?ClaProyecto=${ClaProyecto}`
                  }
          }
        >
        </Link> 
      </>  
        ); 
};

export default AltaProyecto;

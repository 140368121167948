/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PageContent from '../../../../layouts/PageContent';
import { Row, Col, Card, CardBody, CardHeader, CardTitle, CardFooter } from 'reactstrap';
import InstructionsCard from '../../../../components/Cards/InstructionsCard/InstructionsCard';
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../../utils/utils';
import * as yup from 'yup';
import YupValidator from '../../../../utils/YupValidator';
import swal from '@sweetalert/with-react';
import SweatAlertCustomIcon from '../../../../components/SweatAlert/SweatAlertCustomIcon';
import moment from 'moment';
import { Badge } from 'primereact/badge';
import Score from '../../../../components/Cards/Score/Score.jsx';
import FieldButton from '../../../../components/Controls/FieldButton/FieldButton.jsx';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldText from 'components/Controls/FieldText';
import EditorText from 'components/Controls/EditorText/EditorText';
import FieldCheckbox from 'components/Controls/FieldCheckbox';
import FieldSelectButton from 'components/Controls/FieldSelectButton';
import FieldMultiSelect from 'components/Controls/FieldMultiSelect';
import FieldTextArea from 'components/Controls/FieldTextArea';
import FieldCalendar from 'components/Controls/FieldCalendar';
import FieldNumber from 'components/Controls/FieldNumber';
import './Survey.css';
import { Tooltip } from 'primereact/tooltip';
import { Divider } from 'primereact/divider';

const SurveyManagementContestarPreguntas = (props) => {
  /* ################## useState area start ################## */
  const [IdEncuesta, setIdEncuesta] = useState(null);
  const [IdEncuestaPagina, setIdEncuestaPagina] = useState(null);
  const [IdEncuestaPaginaPregunta, setIdEncuestaPaginaPregunta] = useState(null);
  const [ClaFormularioTipoCampo, setClaFormularioTipoCampo] = useState(null);
  const [Descripcion, setDescripcion] = useState(null);
  const [Index, setIndex] = useState(null);  

  useEffect(() => {
    setIdEncuesta(props.IdEncuesta === undefined ? null : props.IdEncuesta);
    setIdEncuestaPagina(props.IdEncuestaPagina === undefined ? null : props.IdEncuestaPagina);
    setIdEncuestaPaginaPregunta(
      props.IdEncuestaPaginaPregunta === undefined ? null : props.IdEncuestaPaginaPregunta
    );
    setIndex(props.Index === undefined ? null : props.Index);
    setDescripcion(props.Descripcion === undefined ? null : props.Descripcion);
    setClaFormularioTipoCampo(props.ClaFormularioTipoCampo === undefined ? null : props.ClaFormularioTipoCampo);
    // obtenerSurveyPageQuestion(
    //   props.IdEncuesta,
    //   props.IdEncuestaPagina,
    //   props.IdEncuestaPaginaPregunta
    // ); 

  }, [
    props.IdEncuesta,
    props.IdEncuestaPagina,
    props.IdEncuestaPaginaPregunta,
    props.RespuestaPregunta,
    props.PosiblesRespuestas,
    props.Index,
    props.Descripcion,
    props.ClaFormularioTipoCampo
  ]);

  const obtenerSurveyPageQuestion = (pIdEncuesta, pIdEncuestaPagina, pIdEncuestaPaginaPregunta) => {
    const GetHRSurveyManagementQuestionForPage = `${config.UrlApiProject}HR/GetHRSurveyManagementQuestionForPage`;
    const paramsToService = {
      IdEncuesta: pIdEncuesta,
      IdEncuestaPagina: pIdEncuestaPagina,
      IdEncuestaPaginaPregunta: pIdEncuestaPaginaPregunta,
    };
    callApi(GetHRSurveyManagementQuestionForPage, 'POST', paramsToService, (response) => {
      const [preguntasPorPagina] = response.data.EncuestaPaginaPregunta;
      setDescripcion(preguntasPorPagina.Descripcion);
      setClaFormularioTipoCampo(preguntasPorPagina.ClaFormularioTipoCampo);
    });
  };

  const onchangeControlSingle = (value) => {
    props.onValueChangedQuestion(
      props.Index,
      value,      
      value
    );
  };

  const onChangeControlPlano = (value) => {
    props.onValueChangedQuestion(props.Index, null, value);
  };
  const TipodeCampoPregunta = (pClaFormularioTipoCampo) => {
    if (pClaFormularioTipoCampo === 1) {
      return (
        <div className="p-fluid">
          <FieldText
            name="ftIdEncuestaPaginaPregunta"
            value={props.RespuestaPregunta}
            onChange={(e) => {
              onChangeControlPlano(e.target.value);
            }}
            maxLength={7000}
          />
        </div>
      );
    }
    if (pClaFormularioTipoCampo === 2) {
      return (
        <div className="p-fluid">
          <FieldNumber
            name="fnIdEncuestaPaginaPregunta"
            value={props.RespuestaPregunta}
            onChange={(e) => {
              onChangeControlPlano(e.target.value);
            }}
          />
        </div>
      );
    }
    if (pClaFormularioTipoCampo === 3) {
      return (
        <div className="p-fluid">
          <FieldNumber
            name="fnIdEncuestaPaginaPregunta"
            minFractionDigits={2}
            value={props.RespuestaPregunta}
            onChange={(e) => {
              onChangeControlPlano(e.target.value);
            }}
          />
        </div>
      );
    }
    if (pClaFormularioTipoCampo === 4) {
      return (
        <div className="p-fluid">
          <FieldCalendar
            name="fcIdEncuestaPaginaPregunta"
            value={props.RespuestaPregunta !== '' && props.RespuestaPregunta !== null  ? new Date(props.RespuestaPregunta) : props.RespuestaPregunta}
            onChange={(e) => {
              onChangeControlPlano(e.target.value);
            }}
          />
        </div>
      );
    }
    if (pClaFormularioTipoCampo === 5) {
      return (
        <div className="p-fluid">
          <FieldCalendar
            name="fcIdEncuestaPaginaPregunta"
            value={props.RespuestaPregunta !== '' && props.RespuestaPregunta !== null  ? new Date(props.RespuestaPregunta) : props.RespuestaPregunta}
            showTime={true}
            onChange={(e) => {
              onChangeControlPlano(e.target.value);
            }}
          />
        </div>
      );
    }
    if (pClaFormularioTipoCampo === 7) {
      return (
        <div className="p-fluid">
          <FieldTextArea
            name="ftaIdEncuestaPaginaPregunta"
            rows={5}
            placeholder=""
            value={props.RespuestaPregunta}
            onChange={(e) => {
              onChangeControlPlano(e.target.value);
            }} 
            maxLength={7000}
          />
        </div>
      );
    }
    if (pClaFormularioTipoCampo === 8) {
      return (
        <div className="p-fluid">
          <FieldDropdown
            name="fdIdEncuestaPaginaPreguntaRespuesta"
            value={props.RespuestaPregunta}
            optionValue="IdEncuestaPaginaPreguntaRespuesta"
            optionLabel="DescripcionRespuesta"
            options={props.PosiblesRespuestas}
            onChange={(e) => {
              onchangeControlSingle(e.value);
            }}
          >
          </FieldDropdown>
        </div>
      );
    }
    if (pClaFormularioTipoCampo === 9) {
      return (
        <div className="p-fluid">
          <FieldMultiSelect
            name="MultiSelectIdEncuestaPaginaPreguntaRespuesta"
            optionLabel="DescripcionRespuesta"
            value={props.RespuestaPregunta}
            options={props.PosiblesRespuestas}
            onChange={(e) => onchangeControlSingle(e.value)}
          >
          </FieldMultiSelect>
        </div>
      );
    }
    if (pClaFormularioTipoCampo === 10) {
      return (
        <div className="p-fluid">
          <FieldSelectButton
            name="FSIdEncuestaPaginaPreguntaRespuesta"
            value={props.RespuestaPregunta}
            options={props.PosiblesRespuestas}
            onChange={(e) => onchangeControlSingle(e.value)}
            optionValue="IdEncuestaPaginaPreguntaRespuesta"
            optionLabel="DescripcionRespuesta"
            className="buttonListSurvey"
            multiple={false}
            errors={props.errors}
          />
        </div>
      );
    }
    if (pClaFormularioTipoCampo === 11) {
      return (
        <div className="p-fluid">
          <FieldSelectButton
            name="FSMultipleIdEncuestaPaginaPreguntaRespuesta"
            value={Array.isArray(props.RespuestaPregunta) ? props.RespuestaPregunta : (props.RespuestaPregunta !== null ? props.RespuestaPregunta.split(',') : props.RespuestaPregunta)}
            options={props.PosiblesRespuestas}
            onChange={(e) => onchangeControlSingle(e.value)}
            optionValue="IdEncuestaPaginaPreguntaRespuesta"
            optionLabel="DescripcionRespuesta"
            className="buttonListSurvey"
            multiple={true}
          />
        </div>
      );
    }
  };

  return (
    <>
      <div>
        <div>
          <div className="Pregunta">
            {
              /* {Index + 1}.- {props.Descripcion} */ 
            }
            <b>{props.Descripcion}</b>
          </div>
        </div>
        <br></br>
        <div>{TipodeCampoPregunta(ClaFormularioTipoCampo)}</div>
        <div>&nbsp;</div>
      </div>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          &nbsp;
        </Col>
      </Row>
    </>
  );
};
export default SurveyManagementContestarPreguntas;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import swal from '@sweetalert/with-react';
import moment from "moment";
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Panel } from 'primereact/panel';
import { ProgressBar } from 'primereact/progressbar';
import { Ripple } from 'primereact/ripple';
import { SplitButton } from 'primereact/splitbutton';
import React,{ useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { idpStatus, idpVersions } from './IndividualDevelopmentPlanConstants.js';
import * as yup from 'yup';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import FieldCalendar from '../../../components/Controls/FieldCalendar.jsx';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import FieldDropdown from '../../../components/Controls/FieldDropdown.jsx';
import FieldTextArea from '../../../components/Controls/FieldTextArea.jsx';
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon';
import PageContent from '../../../layouts/PageContent';
import YupValidator from '../../../utils/YupValidator';
import { config } from '../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../utils/utils';
import OrganizationalView from '../OrganizationalView';
import IndividualDevelopmentPlanDetailDialog from './IndividualDevelopmentPlanDetailDialog';

const IndividualDevelopmentHome = (props) => {  
    const { t } = useTranslation(['hr', 'common']);
    const [errors, setErrors] = useState({}); 
    const [RowsGrid, setRowsGrid] = useState([]); 
    const [StartDateFilter, setStartDateFilter] = useState(null); 
    const [EndDateFilter, setEndDateFilter] = useState(null); 
    const [ClaIDPEstatusFilter, setClaIDPEstatusFilter] = useState(null); 
    const [OpcionesIDPEstatus, setOpcionesIDPEstatus] = useState([]);     
    const [EsListado, setEsListado] = useState(true); 
    const [IDPAcciones, setIDPAcciones] = useState([]); 
    const [IdIDP, setIdIDP] = useState(null); 
    const [IDPStatusName, setIDPStatusName] = useState(''); 
    const [MotivoDeshacer, setMotivoDeshacer] = useState(null);             
    const [ComentariosRevisado, setComentariosRevisado] = useState(null);      
    const dt = useRef(null); 
    const [claUsuarioSeleccionado, setClaUsuarioSeleccionado] = useState(props.ClaUsuarioSeleccionado === undefined ? getSessionItem('ClaUsuarioMod', '') : props.ClaUsuarioSeleccionado);     
    const [claUsuarioRowSeleccionado, setClaUsuarioRowSeleccionado] = useState(null);     
    const [supportDucumentCertificateDatafiles, setSupportDucumentCertificateDatafiles] = useState([]); 
    const [EsDialogUndo, setEsDialogUndo] = useState(false); 
    const defaultDateStart = moment(new Date()).format("YYYY/01/01");
    const defaultDateEnd = moment(new Date()).format("YYYY/MM/DD"); 
    const [IDPVersion, setIDPVersion] = useState(idpVersions.V1); 
    
    const reportTypes = {
      MY_SELF: 'M',
      TEAM: 'T'
    };

    useEffect(() => {     
      setStartDateFilter(new Date(defaultDateStart));
      setEndDateFilter(new Date(defaultDateEnd));
      loadEstatus();   
    }, []); 

    useEffect(() => {    
    }, [props.Filters]); 


    const loadEstatus = () => { 
        const GetServiceIDP = `${config.UrlApiProject}hr/GetEstatusIndividualDevelopmentPersonal?Idioma=""}`;  
        const paramsToService = { };   
        callApi(GetServiceIDP, 'GET', paramsToService, (response) => { 
          if (response.data.EstatusIDPInfo.length > 0)
          { 
            setOpcionesIDPEstatus(response.data.EstatusIDPInfo);
           }
           getIDP()
        });
    }

    const getIDP = (dStartDateFilter,dEndDateFilter) => { 
      const GetServiceIDP = `${config.UrlApiProject}hr/GetIndividualDevelopmentPersonal`;  
      const paramsToService = { 
        IdIDP:null,
        ClaIDPEstatus:ClaIDPEstatusFilter,
        Idioma: '',
        ClaUsuario:claUsuarioSeleccionado,
        FechaInicio:dStartDateFilter,
        FechaFinal:dEndDateFilter
      };   
      callApi(GetServiceIDP, 'POST', paramsToService, (response) => { 
        setEsListado(true);
        setRowsGrid(response.data.IDPLista);
      });
    }

    const onApply = async ( ) => {
      getIDP(StartDateFilter,EndDateFilter);
    }

    const onEstatusIDPChange = (value) => {  
        setClaIDPEstatusFilter(value);
    };

    const onChangeStartDateFilter = (e) => {  
        setStartDateFilter(e.target.value)
    }

    const onChangeEndDateFilter = (e) => {  
        setEndDateFilter(e.target.value)
      } 

    const filterHeaderTemplate = (options) => {
        const toggleIcon = options.collapsed ? 'pi pi-chevron-down text-white' : 'pi pi-chevron-up text-white';
        const className = `${options.className} p-jc-start`;
        const titleClassName = `${options.titleClassName} p-pl-1`;

        return (
          <div className="card-header">
            <div className="p-toolbar p-component">
              <div className="p-toolbar-group-left">
                <div className="card-title">
                  <FieldButton className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                  </FieldButton>
                  <span className={titleClassName}>
                    {t('common:Filters')}
                  </span>
                </div>  
              </div>
             
              <div className="p-toolbar-group-right">
                <FieldButton className="p-button p-button-icon-only p-highlight" onClick={onApply}>
                  <i className="pi pi-search"></i>
                </FieldButton>   &nbsp;
                {/* <FieldButton className="p-button p-button-icon-only p-button-success" onClick={nuevaAccion}>
                  <i className="pi pi-plus"></i>
                </FieldButton>  */}
              </div>
            </div> 
          </div>
        )
    }

    const onChangeMotivoDeshacer = (e) => { 
      const newErrors = { ...errors } 
      delete newErrors.MotivoDeshacer;   
      setErrors(newErrors) 
      setMotivoDeshacer(e.target.value)
    } 

    const AccionesTemplate = (row) =>  {
      let className = '';
      switch(true) {
        case row.Porcentaje<50:
          className = "danger-color";
          break;
        case row.Porcentaje<75:
          className = "orange-color";
          break;
        case row.Porcentaje<99:
          className = "warning-color";
          break;
        case row.Porcentaje===100:
          className = "success-color";
          break;
        default:
          className = "default-color";
      }
      return (
        <>
          {
            row.ClaIDPEstatus !== idpStatus.ACCEPTED && (
              <>
                <div className="text-center">
                  <span>{row.AccionesTerminada}</span> &nbsp;
                  <span className="bold">{t('hr:Of')}</span> &nbsp;
                  <span>{row.Acciones}</span>&nbsp;
                  <span className="bold">{t('hr:Activities')}</span> 
                </div>
                <div>
                  <ProgressBar className={className} value={row.Porcentaje}></ProgressBar>
                </div>
              </>
            )          
          }        
        </>
      );
    }
  
    const sIcon = (EstatusId) => {
      let sclass = '';
      switch (EstatusId) {
        case 1:
          sclass = 'pi pi-pencil'; 
          break;
        case 2:
          sclass = 'pi pi-eye'; 
          break;
        case 3:
          sclass = 'pi pi-eye'; 
          break;
        case 5:
            sclass = 'pi pi-ban'; 
          break;
        case 7:
          sclass = 'pi pi-eye'; 
          break;
        case -1:
          sclass = 'pi pi-undo'; 
          break;
        default:
          sclass = 'pi pi-send'; 
      }
      return (
        sclass
      );
    }

    const dataInfo = (rowData) => {
      // console.log('dataInfo',rowData);
      const arreglo = [];
      rowData.Opciones.forEach((item) => { 
        const arrItem =   { 
          label: item.NomIDPEstatus,
          icon: sIcon(item.ClaIDPEstatus),
          command: () => { 
            switch (item.ClaIDPEstatus) {
              case -1:
                setIdIDP(rowData.IdIDP);
                if(rowData.EsjefeInmediato){
                  onConfirmUndoSubmit(rowData.IdIDP, MotivoDeshacer); 
                }else{
                  setEsDialogUndo(true);
                }
                break;
              case 5:
                onConfirmSubmit(5, item.IdIDP, rowData.Version);  
                break;
              default:               
                setClaUsuarioRowSeleccionado(rowData.ClaUsuario);                
                setIdIDP(item.IdIDP);        
                setIDPStatusName(rowData.Estatus);        
                setIDPVersion(rowData.Version);
                setEsListado(false);
            }
          }
        }
        arreglo.push(arrItem);
      })
      return (
        arreglo
      );
    }
     
    const goalTableColumnTemplate = (rowData) =>  {    
      return (
        <>
          {rowData.ClaIDPEstatus === idpStatus.ACCEPTED? t('hr:NotParticipatingAtThisTime'): rowData.ObjetivoSmart}        
        </>
      );
    };

    const statusTableColumnTemplate = (rowData) =>  {
      let className = '';
      if (
        (
          getSessionItem('ClaUsuarioMod', '') === rowData.ClaUsuario &&
          rowData.ClaIDPEstatus === idpStatus.ADDITIONAL_INPUT_NEEDED
        ) ||
        (
          getSessionItem('ClaUsuarioMod', '') !== rowData.ClaUsuario &&
          rowData.ClaIDPEstatus === idpStatus.PENDING_REVIEW
        ) 
      ) {
        className = 'text-warning';
      }
      return (
        <>
          <p className={className}>
            {rowData.Estatus}
          </p>
        </>
      );
    };  

    const BotonesTemplate = (rowData) =>  {    
      return (
        <>
          <SplitButton className="hidebutton" buttonTemplate="danger" dropdownIcon="pi pi-bars" model={dataInfo(rowData)}></SplitButton>
        </>
      );
    }

  const nuevaAccion = () => {    
    setIdIDP(0);    
    setIDPStatusName('');        
    setIDPVersion(idpVersions.V1);
    setComentariosRevisado('');
    setIDPAcciones([]);
    setErrors({});
    setEsListado(false);
    setSupportDucumentCertificateDatafiles([]);
  }
  
const valitationSchemeRejected = yup.object().shape({
  ComentariosRevisado: yup.string().required(t('common:RequiredField')).nullable()
});

const valitationSchemeCompleted = yup.object().shape({
  IDPAcciones: yup.array().of(
      yup.object().shape({  
        FechaFinalReal: yup.date().when('EsAccionCompletada', {is: true,
          then: yup.date().required(t('common:RequiredField')).nullable(),
          otherwise: yup.date().nullable()
        }), 
        ComentariosSeguimiento:  yup.string().when('EsAccionCompletada', {is: false,
          then: yup.string().required(t('common:RequiredField')).nullable(),
          otherwise: yup.string().nullable()
        }) 
        // ,EsAccionCompletada: yup.boolean().required(t('common:RequiredField'))
      })
    )
});  
const confirmationMessageHTML = (
  <div>
    <SweatAlertCustomIcon title={t('common:SubmitConfirmation')} letter={t('common:QuestionMark')}></SweatAlertCustomIcon> 
    <div>{t('hr:SubmitCancelled')}.</div> 
  </div>  
 ) 

  const confirmationUndoMessageHTML = (
    <div>
      <SweatAlertCustomIcon title={t('common:SubmitConfirmation')} letter={t('common:QuestionMark')}></SweatAlertCustomIcon> 
      <div>{t('hr:SubmitUndo')}</div> 
    </div>  
  ) 
 
const onConfirmSubmit = (claEstatus, nIdIDP, version) => {  
  swal({ 
    buttons: {
      cancelar: {
        text: t('common:Cancel'),
        value: "cancel",
        className: "rounded-button danger"
      },
      continuar: {
        text: t('common:Continue'),
        value: "continue",
        className: "rounded-button light-green"
      }
    },
    content: confirmationMessageHTML
  }).then((value) => {
    switch (value) { 
      case "continue":
        saveStatus(claEstatus, nIdIDP, version);
        break; 
      default:
        break;
    }
  });
}

const onConfirmUndoSubmit = (nIdIDP, motive) => {  
  swal({ 
    buttons: {
      cancelar: {
        text: t('common:Cancel'),
        value: "cancel",
        className: "rounded-button danger"
      },
      continuar: {
        text: t('common:Continue'),
        value: "continue",
        className: "rounded-button light-green"
      }
    },
    content: confirmationUndoMessageHTML
  }).then((value) => {
    switch (value) { 
      case "continue":
        acceptUndo(nIdIDP, motive);
        break; 
      default:
        break;
    }
  });
}

const onAcceptUndoClick = () => {  
  acceptUndo(IdIDP, MotivoDeshacer);
};
 
const saveStatus = async (ClaEstatus, Id, version) => {
  let value = {
    ComentariosRevisado
  }
  let validator;
  let esValido;
  if(ClaEstatus===4){
    validator = new YupValidator(valitationSchemeRejected);
    esValido = await validator.validate(value);
    if (!esValido){ 
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
      return;
    }
  }
  if(ClaEstatus===6){
    value = {
      IDPAcciones
    }
    validator = new YupValidator(valitationSchemeCompleted);
    esValido = await validator.validate(value);
    if (!esValido){ 
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
      return;
    }
  }
  
  let urlWebServiceSave = '';
  if (version === idpVersions.V1) {
    urlWebServiceSave = `${config.UrlApiProject}hr/SaveStatusIndividualDevelopmentPersonal`;
  } else if (version === idpVersions.V2) {
    urlWebServiceSave = `${config.UrlApiProject}hr/SaveStatusIndividualDevelopmentPlanV2`;
  }

  const paramsService = {    
    IdIDP:Id,
    ClaIDPEstatus:ClaEstatus,
    ComentariosRevisado,
    IndividualDevelopmentPersonalAcciones:IDPAcciones,
    SupportDocumentCertificateFileData:(supportDucumentCertificateDatafiles.length > 0 ? supportDucumentCertificateDatafiles[0].FileData : null),
    SupportDocumentCertificateNomArchivo:(supportDucumentCertificateDatafiles.length > 0 ? supportDucumentCertificateDatafiles[0].FileName: null),
    SupportDocumentCertificateExtension:(supportDucumentCertificateDatafiles.length > 0 ? supportDucumentCertificateDatafiles[0].FileExtension: null) 
  }; 
  callApi(urlWebServiceSave, 'POST', paramsService, (response) => {
    showSweetAlert(t('common:Success'), t('common:SaveSuccess'), 'success');
    getIDP(StartDateFilter,EndDateFilter);
  });
 }

 const acceptUndo = async (idpID, motive) => {
  const urlWebServiceSave = `${config.UrlApiProject}hr/AcceptUndoIndividualDevelopmentPersonal`;
  const paramsService = {
    IdIDP: idpID,
    MotivoDeshacer: motive
  }; 
  callApi(urlWebServiceSave, 'POST', paramsService, (response) => {
    showSweetAlert(t('common:Success'), t('common:SaveSuccess'), 'success');
    closeDialogUndo();
      getIDP(StartDateFilter,EndDateFilter);
  });
};

const onChange = (node) => { 
  setClaUsuarioSeleccionado(node);
}

const CreationDateTemplate = (rowData) => {
  return (
    <>
      <div>
        { moment(rowData.FechaCreacion).format('MMMM DD, YYYY h:mm:ss a') }
      </div> 
    </>
  );
}

const FechaRevisionTemplate = (rowData) => {
  return (
    <>
      <div hidden={rowData.FechaRevisado === null}>
        { moment(rowData.FechaRevisado).format('MMMM DD, YYYY h:mm:ss a') }
      </div> 
    </>
  );
}

const headerTable = ( 
  <>
    <Row>
      <Col>{t('hr:IndividualDevelopmentPlanList')}</Col>
      <Col className='text-right'>
        <FieldButton  
          className="p-button p-button-rounded p-button-secondary"
          onClick={() => onExportGrid()}
        >
          <i className="pi pi-download"></i>&nbsp;&nbsp;{t('common:Export')}
        </FieldButton>
      </Col>
    </Row>
  </>
) 

const onExportGrid = () => {  
  const ExportarIDP = `${config.UrlApiProject}HR/ExportarIDP`;        
  const filters = { 
    IdIDP:null,
    ClaIDPEstatus:ClaIDPEstatusFilter,
    Idioma: '',
    ClaUsuario:claUsuarioSeleccionado,
    FechaInicio:StartDateFilter,
    FechaFinal:EndDateFilter
  };   

  console.log('filters', filters);

  callApi(ExportarIDP, 'POST', filters, (response) => {
    const linkSource = `data:application/${response.data.FileExtension};base64,${response.data.FileData}`;
    const downloadLink = document.createElement("a");
    const fileName = `${response.data.FileName}`;
    downloadLink.href = linkSource;
    downloadLink.download = `${fileName  }.${  response.data.FileExtension}`;
    downloadLink.click();
  });    
} 

const closeDialogUndo = () => {  
  setIdIDP(null);
  setIDPStatusName('');     
  setMotivoDeshacer(null);
  setEsDialogUndo(false);
};

const dialogStyle = {
  width: '60vw',
};

const renderFooterUndo = () => {
  return (
    <div>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}> 
          <div className='text-right'>
            <FieldButton
              label={t('hr:Accept')}
              icon="fa fa-"
              className="light-green no-border p-mr-2"
              onClick={onAcceptUndoClick}
            />
          </div>
        </Col> 
      </Row>
    </div>
  );
}; 

  const onHideIDPDetail = () => {
    setEsListado(true);
  };

  const onIDPSaved = () => {      
    getIDP(StartDateFilter, EndDateFilter);        
  };

  const onIDPStatusSaved = () => {      
    getIDP(StartDateFilter, EndDateFilter);    
  };

  const idpDetailDialogTemplate = (
    <>
      <IndividualDevelopmentPlanDetailDialog        
        isVisible={!EsListado}        
        version={IDPVersion}
        idIDP={IdIDP}        
        iDPStatusName={IDPStatusName}
        reportType={getSessionItem('ClaUsuarioMod', '') === claUsuarioRowSeleccionado? reportTypes.MY_SELF: reportTypes.TEAM}
        onHide={onHideIDPDetail}        
        onSaved={onIDPSaved}
        onStatusSaved={onIDPStatusSaved}        
      />    
    </>
  );

  const idpListTableTemplate = (
    <>  
      <Row>
        <Col>
          <Panel header="Filters" toggleable headerTemplate={filterHeaderTemplate} className="AC-Step-1"> 
            
            <div className="p-fluid p-grid">
              <div className="p-grid p-field col-lg-3 p-md-3">  
                <div className="p-field col-lg-12 ">
                  <FieldCalendar
                    name="StartDateFilter"
                    label={t('hr:StartDate')}
                    value={StartDateFilter}
                    onChange={onChangeStartDateFilter}
                  />    
                </div>
              </div>
              <div className="p-grid p-field  col-lg-3 p-md-3"> 
                <div className="p-field col-lg-12">
                  <FieldCalendar
                    name="EndDateFilter"
                    label={t('hr:EndDate')}
                    value={EndDateFilter}
                    onChange={onChangeEndDateFilter}
                  />    
                </div> 
              </div> 
              <div className="p-grid p-field  col-lg-3 p-md-3"> 
                <div className="p-field col-lg-12">
                  <FieldDropdown  
                    name="ClaIDPEstatusFilter"
                    value={ClaIDPEstatusFilter} 
                    options={OpcionesIDPEstatus}  
                    optionValue="ClaIDPEstatus"
                    optionLabel="NomIDPEstatus" 
                    onChange={(e) => onEstatusIDPChange(e.value)}
                    label={t('hr:Status')}
                  />   
                </div>
              </div>
              <div className="p-grid p-field  col-lg-3 p-md-3"> 
                <div className="p-field col-lg-12">
                  <OrganizationalView onChange={onChange} showAsTreeSelect value={claUsuarioSeleccionado}></OrganizationalView>
                </div>
              </div>  
            </div> 
          </Panel>
        </Col>
      </Row>
      <Row><Col>&nbsp;</Col></Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>   
          <FieldDataTable 
            value={RowsGrid} 
            header={headerTable}
            scrollable  
            className="p-datatable-striped"
            selectionMode="single"
          >    
            <Column field="Anio" columnKey="Anio" header={t('hr:Year')} headerStyle={{ width: '50px' }}></Column> 
            <Column body={CreationDateTemplate} header={t('hr:FechaCreacion')} columnKey="FechaCreacion" headerStyle={{ width: '130px' }}></Column> 
            <Column field="NombreCompleto" columnKey="NombreCompleto" header={t('hr:Responsible')} headerStyle={{ width: '150px' }}></Column> 
            <Column body={goalTableColumnTemplate} columnKey="ObjetivoSmart" header={t('hr:Goal')} headerStyle={{ width: '200px' }}></Column>             
            {/* <Column field="ClaIDPEstatus" columnKey="ClaIDPEstatus" header='' headerStyle={{ width: '20px' }}></Column> */}             
            <Column body={statusTableColumnTemplate} header={t('hr:Status')} headerStyle={{ width: '90px' }}></Column>             
            <Column body={FechaRevisionTemplate} header={t('hr:FechaRevisado')} columnKey="FechaRevisado" headerStyle={{ width: '130px' }}></Column> 
            <Column body={AccionesTemplate} style={{ textAlign: 'center' }} headerStyle={{ width: '100px' }}></Column>  
            <Column body={BotonesTemplate} style={{ textAlign: 'center' }} headerStyle={{ width: '30px' }}></Column>  
          </FieldDataTable>
          <Dialog
            visible={EsDialogUndo}
            header={t('hr:UndoAccepted')}
            style={dialogStyle}
            modal
            className="dialog-custom"
            onHide={closeDialogUndo}
            footer={renderFooterUndo}
          >
            <div>
              <Row className="pt-2">
                <Col> 
                  <span>{t('hr:MotivoDeshacer')}</span> 
                </Col>
              </Row>
              <Row className="pt-2">
                <Col>
                  <div className="p-fluid"> 
                    <FieldTextArea 
                      name="MotivoDeshacer"
                      rows={4} 
                      value={MotivoDeshacer} 
                      onChange={onChangeMotivoDeshacer}
                      errors={errors} 
                      maxLength={1000}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Dialog> 
        </Col> 
      </Row> 
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>  
          &nbsp;
        </Col>
      </Row>
    </>
  );

  return (
    <>  
      <PageContent title={t('hr:IndividualDevelopmentPlan')}>  
        {idpListTableTemplate}
        {idpDetailDialogTemplate}
      </PageContent>
    </>  
  ); 
};

export default IndividualDevelopmentHome;

import React, { useState, useRef, useEffect  } from 'react'; 
import { useTranslation } from 'react-i18next';   
import { config } from '../../../../utils/config';
import { callApi, showSweetAlert} from '../../../../utils/utils';   
import PageContent from 'layouts/PageContent';   
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { Row,Col,Card, CardHeader, CardTitle, CardBody, CardFooter} from 'reactstrap';   
import FieldDataView from 'components/Controls/FieldDataView/FieldDataView.jsx';
import FieldDropdown from 'components/Controls/FieldDropdown.jsx' 
import FieldButton from 'components/Controls/FieldButton/FieldButton.jsx';
import FieldTextArea  from 'components/Controls/FieldTextArea.jsx'
import FieldCalendar from 'components/Controls/FieldCalendar.jsx'
import './DailyOperationTrackerScreen.scss';  
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import * as yup from 'yup';
import YupValidator from '../../../../utils/YupValidator';
import InstructionsCard from 'components/Cards/InstructionsCard/InstructionsCard';
import { setDate } from 'date-fns';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { Dialog } from 'primereact/dialog';
import moment from "moment";
import FieldText from 'components/Controls/FieldText';
import DailyOperationAssetDetail from './DailyOperationAssetDetail';
import FasesActividadesAnexo from 'views/ScoreCard/Projects/FasesActividadesAnexo';

const DailyOperationTrackerTask = (props) => { 
  const { t } = useTranslation(['wtw', 'common']);  
  const [errors, setErrors] = useState({});     
  const [PlantasList, setPlantasList] = useState([]); 
  const [EsAgregarTarea, setEsAgregarTarea] = useState(false);
  const [NomActividadTarea, setNomActividadTarea] = useState(null);
  const [DescripcionTarea, setDescripcionTarea] = useState(null);
  const [OpcionesResponsables, setOpcionesResponsables] = useState([]);
  const [ClaResponsable , setClaResponsable] = useState(null); 
  const [IdOperacionSeguimientoDiarioTarea , setIdOperacionSeguimientoDiarioTarea] = useState(null); 
  const [ClaActividad , setClaActividad] = useState(null); 
  const [FechaTarea, setFechaTarea] = useState(null); 
  const [FechaTareaCompletada, setFechaTareaCompletada] = useState(null); 
  const [EsCompletarTarea, setEsCompletarTarea] = useState(false); 
  const [EsVerAnexo, setEsVerAnexo] = useState(false);
  const [ClaProyecto, setClaProyecto] = useState(null);
  const [ClaFase, setClaFase] = useState(null);
  
  useEffect(() => {
    console.log('DailyOperationTrackerTask',props);
    getPlantas(); 
    obtenerResponsablesProyecto();
    if(props.IdOperacionSeguimientoDiarioTarea>0){
      getOperacionSeguimientoDiarioTarea();
    }
  }, [])


const obtenerResponsablesProyecto = () => { 
  const GetSummaryScorecardUser = `${config.UrlApiProject}ScoreCard/GetMswBSCColaboradorProyectosTeam`;  
  const paramsToService = { 
    ClaProyecto:93
  };   
  callApi(GetSummaryScorecardUser, 'POST', paramsToService, (response) => { 
    if (response.data[0].length >0)
    {  
      setOpcionesResponsables(response.data[0]);
    }  
  });
}

const onChangeFechaTarea = (e) => {  
  const newErrors = { ...errors }
  delete newErrors.FechaTarea;   
  setErrors(newErrors);
  setFechaTarea(e.target.value); 
} 

const onChangeFechaTareaCompletada = (e) => {  
  const newErrors = { ...errors }
  delete newErrors.FechaTareaCompletada;   
  setErrors(newErrors);
  setFechaTareaCompletada(e.target.value); 
} 

const getOperacionSeguimientoDiarioTarea = () => { 
  const urlWebService = `${config.UrlApiProject}WTW/GetOperacionSeguimientoDiarioTarea`; 
  const paramsService = {
    ClaUbicacion:props.ClaUbicacion,
    ClaProyecto:props.ClaProyecto,
    ClaFase:props.ClaFase,
    ClaActividad:props.ClaActividad,
    Fecha:props.Fecha,
    Language:''
  };  
  callApi(urlWebService, 'POST', paramsService, (response) => {
    const data=response.data.OperacionSeguimientoDiarioTarea[0];
    setErrors(null);
    if(data!==undefined){
      setIdOperacionSeguimientoDiarioTarea(data.IdOperacionSeguimientoDiarioTarea);
      setClaActividad(data.ClaActividad);
      setClaResponsable(data.ClaResponsable);
      setNomActividadTarea(data.NomActividad);
      setDescripcionTarea(data.Descripcion);
      setFechaTarea(new Date(data.DueDate)); 
      setFechaTareaCompletada(null);
      setEsCompletarTarea(true);
      setClaProyecto(props.ClaProyecto);
      setClaFase(props.ClaFase);
      setErrors({});
    }
    setEsAgregarTarea(true);
  }, null, false, true);
}

const onChangeNomActividadTarea = (e) => {      
  const newErrors = { ...errors } 
  delete newErrors.NomActividadTarea;   
  setErrors(newErrors)     
  setNomActividadTarea(e.target.value); 
}

const onChangeDescripcionTarea = (e) => {    
  const newErrors = { ...errors } 
  delete newErrors.DescripcionTarea;   
  setErrors(newErrors)  
  setDescripcionTarea(e.target.value); 
}

const onClaResponsableChange = (props, e ) => {
  const newErrors = { ...errors } 
  delete newErrors.ClaResponsable;   
  setErrors(newErrors) 
  setClaResponsable(e.value)
}; 
 

const getPlantas = () => {  
  const urlWebService = `${config.UrlApiProject}WTW/GetPlantasProduccionSeguimientoCmb`; 
  const paramsService= {};  
  callApi(urlWebService, 'GET', paramsService, (response) => {
    setPlantasList(response.data.PlantasList);
  }, null, false, true);
}
 
 
const valitationSchemeSaveTask = yup.object().shape({
  NomActividadTarea: yup.string().trim().required(t('common:RequiredField')).nullable(),
  DescripcionTarea: yup.string().trim().required(t('common:RequiredField')).nullable(),
  FechaTarea: yup.date().required(t('common:RequiredField')).nullable(), 
  ClaResponsable: yup.mixed().required(t('common:RequiredField')).nullable(),
  FechaTareaCompletada: (
    EsCompletarTarea
    ? yup.date().required(t('common:RequiredField')).nullable() 
    : yup.date().nullable()
  )
}); 

const onSaveTask = async () => {  
  const value = {
    NomActividadTarea,
    DescripcionTarea,
    FechaTarea,
    ClaResponsable
  }
  if(EsCompletarTarea){
    value.FechaTareaCompletada=FechaTareaCompletada;
  }
  const validator = new YupValidator(valitationSchemeSaveTask);
  const esValido = await validator.validate(value);
  if (!esValido){ 
    setErrors(validator.errors);
    showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
    return;
  }
  const urlWebService = `${config.UrlApiProject}WTW/SaveOperacionSeguimientoDiarioTarea`; 
  const paramsService = {
    IdOperacionSeguimientoDiarioTarea:props.IdOperacionSeguimientoDiarioTarea,
    ClaUbicacion:props.ClaUbicacion,
    ClaActividad:props.ClaActividad,
    NomActividadTarea,
    DescripcionTarea,
    Fecha:props.Fecha,
    FechaTarea,
    FechaTareaCompletada,
    ClaResponsable,
    Language:''
  }; 
  callApi(urlWebService, 'POST', paramsService, (response) => {
    showSweetAlert(t('common:Exito'), t('common:SaveSuccess'), 'success');
    if (props.onAferSaveTask != null) {
      props.onAferSaveTask();
    }
    }, () => {
  });
};
 
const onCloseDialogTask = () => {  
  if (props.onAfterReturn != null) {
    props.onAfterReturn();
  }
}
 
const headerDialogTask = () => {
  return (
    <>
      {t('wtw:AddNewTask')}
    </>
  )
}

const footerDialogTask = () => {
  return (
    <>
    
      <FieldButton 
        label={t('common:Close ')}
        icon="pi pi-times"
        onClick={() => onCloseDialogTask()}
        className="p-button p-button-warning"
      />
      <FieldButton 
        label={t('common:Save ')}
        icon="pi pi-save"
        onClick={() => onSaveTask()}
        className="p-button p-button-success"
      />
    </>
  )
}

const dialogStyle  = ( 
  { 
   width: '80vw', 
   borderLeft:"solid 2px #2791FF", 
   borderTop:"solid 2px #2791FF"
 }
)
const anexo = async () => {
  setEsVerAnexo(!EsVerAnexo);
}
const closeAnexo = () => {
  setEsVerAnexo(false);
} 



  return (
    <>   
      <Dialog
        visible={props.visible}         
        modal
        style={{ width: '50vw' }}
        maximized={false}
        draggable={false}
        onHide={onCloseDialogTask}
        header={headerDialogTask}
        footer={footerDialogTask}
        className='DailyOperationTrackerScreen dialog-header-background-blue'
        blockScroll={true}
      > 
        <Row className='align-items-start'>
          <Col lg={12} md={12} sm={12} xs={12}>  
              &nbsp;  
          </Col> 
        </Row> 
        <Row className='align-items-start pb-2'>
          <Col lg={12} md={12} sm={12} xs={12}>  
            {t('wtw:Title')}
          </Col> 
        </Row> 
        <Row className='align-items-start pb-2'>
          <Col>
            <div className="p-fluid">
              <FieldText 
                name="NomActividadTarea"
                value={NomActividadTarea}  
                onChange={(e) => onChangeNomActividadTarea(e)}  
                errors={errors}
                maxLength={250}
              />
            </div>
          </Col>
        </Row>
        <Row className='align-items-start pb-2'>
          <Col lg={12} md={12} sm={12} xs={12}>  
            {t('wtw:Description')}
          </Col> 
        </Row> 
        <Row>
          <Col>
            <div className="p-fluid">
              <FieldTextArea 
                name="DescripcionTarea"
                rows={3}
                value={DescripcionTarea}  
                onChange={(e) => onChangeDescripcionTarea(e)}  
                errors={errors}
                maxLength={5000}
              />
              <div className='text-right'><span><small>{DescripcionTarea === null ? 0 : DescripcionTarea.toString().length } / 5000</small></span> </div>
            </div>
          </Col>
        </Row>
        <Row className='align-items-start pb-2'>
          <Col lg={12} md={12} sm={12} xs={12}>  
            {t('wtw:Responsible')}
          </Col> 
        </Row> 
        <Row className='align-items-start pb-2'>
          <Col>
            <div className="p-fluid">
              <FieldDropdown  
                name="ClaResponsable"
                value={ClaResponsable}
                options={OpcionesResponsables}  
                optionValue="ClaColaborador"
                optionLabel="NomColaborador"
                onChange={(e) => onClaResponsableChange(props,e)}
                errors={errors}
              />  
            </div> 
          </Col>
        </Row>
        <Row className='align-items-start pb-2'>
          <Col lg={12} md={12} sm={12} xs={12}>  
            {t('wtw:DueDate')}
          </Col> 
        </Row> 
        <Row className='align-items-start pb-2'>
          <Col>
            <div className="p-fluid">
              <FieldCalendar
                name="FechaTarea" 
                value={FechaTarea}
                minDate={new Date()}
                onChange={(e) => onChangeFechaTarea(e)}
                errors={errors}
              />   
            </div>
          </Col>
        </Row>
        {EsCompletarTarea ? (
          <>
            <Row className='align-items-start pb-2'>
              <Col lg={12} md={12} sm={12} xs={12}>  
                {t('wtw:CompletedDate')}
              </Col> 
            </Row> 
            <Row className='align-items-start pb-2'>
              <Col>
                <div className="p-fluid">
                  <FieldCalendar
                    name="FechaTareaCompletada" 
                    value={FechaTareaCompletada}
                    onChange={(e) => onChangeFechaTareaCompletada(e)}
                    errors={errors}
                  />   
                </div>
              </Col>
            </Row>
            <Row className='align-items-start pt-2 pb-2'>
              <Col>
                <FieldButton 
                  label={t('wtw:AttachSupportDocument')}
                  icon="fas fa-file-upload"
                  onClick={() => anexo()}
                  className="primary p-button-rounded p-button-outlined"
                />
              </Col>
            </Row>
          </>
            ) : (
              <></>
        )}    
      </Dialog>
      <Dialog
        visible={EsVerAnexo}
        header={t('scoreCard:ActivityDocuments')}
        style={dialogStyle} 
        modal
        className="dialog-custom"
        onHide={closeAnexo}
      > 
        <FasesActividadesAnexo IsVisible={EsVerAnexo} ClaProyecto={props.ClaProyecto} ClaFase={props.ClaFase} ClaActividad={props.ClaActividad}  />
      </Dialog>   
    </>
  );
};

export default DailyOperationTrackerTask;
import { React, UseState, UseEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PageContent from 'layouts/PageContent';
import SalesPerformanceDashboard from './SalesPerformanceDashboard';

const SalesPerformanceDashboardScreen = (props) => {
  const {t, i18n} = useTranslation(['salesPerformance', 'common']);

  return (
    <PageContent title={t('salesPerformance:SalesPerformanceDashboard')}>
      <SalesPerformanceDashboard />
    </PageContent>
  )
}

export default SalesPerformanceDashboardScreen;
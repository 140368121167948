/* eslint-disable react/static-property-placement */
import React from 'react';
// nodejs library that concatenates classes
import classnames from 'classnames';
// nodejs library to set properties for components 
// import images
import {  Collapse,   Navbar,  Nav,  Container, } from 'reactstrap'; 
import logo from '../../assets/img/Sirius_logo.png'; 
import { useTranslation } from 'react-i18next';  
 
const ExternalNavBar = (props) => {  
   
    const { t } = useTranslation(['common']); 

    return (
      <>   
        <Navbar className={classnames('navbar-top navbar-expand border-bottom bg-header')}>
          <Container fluid className="layout-container">
            <Collapse navbar>
              <Nav className="align-items-right mr-auto " navbar>   
                <img alt="..." src={logo} style={{ width: '160px', height: '30px' }}  />
              </Nav> 
            </Collapse>
          </Container> 
        </Navbar>  
      </>
        ); 
};

export default ExternalNavBar;

import React, { useState, useEffect } from 'react';
import {
  Card, 
  CardBody, 
  Row,
  Col, 
  label,
} from 'reactstrap';  
import { useTranslation } from 'react-i18next';   
import DailyOperationProductionTrend from 'views/Operations/WTW/DailyOperationTracker/DailyOperationProductionTrend';
import PageContent from 'layouts/PageContent';

const DailyProductionSummaryScreen = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['productionDashboard', 'common']);
   
    /* ################## End useState area start ################## */
 
 
  return (
    <>
      <PageContent title={t('productionDashboard:DailyProductionSummaryScreen')}>   
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>  
            <DailyOperationProductionTrend 
              Fecha={null}
              ClaUbicacion={68} // default mid continent
              ClaArea={null}
              ClaCelda={null}
              ClaTurno={null}
              DisableFilters={false}
            >
            </DailyOperationProductionTrend>
          </Col> 
        </Row>  
      </PageContent>
    </>
  );
};

export default DailyProductionSummaryScreen;

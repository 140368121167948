/* eslint-disable jsx-a11y/label-has-associated-control */
import React  from 'react';     
import Select, { InputActionMeta } from 'react-select';
import './FieldDropDownMultiSelect.css';


const FieldDropdownMultiSelect =  (props) => {
    
    const onChange = (value) => {  
        if (props.onChange !== null){
            props.onChange(value);
        } 
    }  

    const isFormFieldInValid = (name) => !!(typeof(props.errors) !== 'undefined' && props.errors !== null && props.errors[name]);
    
    const getFormErrorMessage = (name) => {  
        return isFormFieldInValid(name) && <small className="p-error">{props.errors[name].message}</small>;
    };
      return (  
        <> 
          {
            props.label !== null && typeof(props.label) !== 'undefined' && props.label !== ''  ?
            (
              <div className="p-float-label" style={{top:-9}}>
                <label className={isFormFieldInValid(props.name) === true ? 'p-error' : ''}>{props.label}</label>
              </div>
)
            :
            (<></>)
          } 
          
          <span>
            <Select  
              isMulti={props.isMulti}
              options={props.options}
              isClearable={props.isClearable}
              isSearchable={props.isSearchable}
              value={props.value}
              name={props.name}
              onChange={onChange}
              className={`react-select ${ typeof(props.className) === 'undefined' ? '' : props.className} ${isFormFieldInValid(props.name) === true ? 'p-invalid' : ''}`}
              placeholder={props.placeholder}  
              classNamePrefix="react-select-custom" 
              hideSelectedOptions={true}                           
            /> 
          </span>
          { getFormErrorMessage(props.name) } 
        </> 
      )
}   
    
export default FieldDropdownMultiSelect; 

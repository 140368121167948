/* eslint-disable jsx-a11y/label-has-associated-control */
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import React, { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { config } from 'utils/config';
import { callApi } from 'utils/utils';

// Props:
// - onTopicSelected
const DAISeerTopicsSelector = (props) => {
  const {t, i18n} = useTranslation(['daiSeer', 'common']);
  const [topics, setTopics] = useState([]);
  const [topic, setTopic] = useState(null);

  const getTopics = () => {
    const url = `${config.UrlApiProject}DAISeer/GetDAISeerTopicCmb`;       
    const paramsService = {
      Valor: null,
      Tipo: null,
      ModoSel: 1,
      BajasSn: 0
    };  
    callApi(url, 'POST', paramsService, (response) => {      
      setTopics(response.data.topics);       
    });
  }

  const onTopicPress = (topic) => {        
    setTopic(topic);
    if (props.onTopicSelected) {
      props.onTopicSelected(topic);
    }
  };

  const topicSelectorTemplate = (
    <>
      <Row>
        <Col>           
          <p>
            {t('daiSeer:WeAreImproving')}
          </p>        
        </Col> 
      </Row>  
      <Row className='align-items-start'>
        <Col>           
          {
            topics.map((topic) => {
              return (
                <>
                  <FieldButton                      
                    keyName={`topicButton${topic.ClaDAISeerTopic}`}
                    label={topic.NomDAISeerTopic}                      
                    className="p-button-primary"
                    onClick={() => {onTopicPress(topic)}} 
                  />   
                </>
              )
            })
          }
        </Col> 
      </Row>  
    </>
  );

  useEffect(() => {         
    getTopics(); // [{"ClaDAISeerTopic":1,"NomDAISeerTopic":"1 - Sales"}]
  }, []);

  return (
    <>
      {topicSelectorTemplate}
    </>
  )
}

export default DAISeerTopicsSelector
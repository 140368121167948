/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PageContent from '../../../../layouts/PageContent';
import { Row, Col, Card, CardBody, CardHeader, CardTitle, CardFooter } from 'reactstrap';
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert, getDeviceUniqueID } from '../../../../utils/utils';
import * as yup from 'yup';
import YupValidator from '../../../../utils/YupValidator';
import swal from '@sweetalert/with-react';
import SweatAlertCustomIcon from '../../../../components/SweatAlert/SweatAlertCustomIcon';
import moment from 'moment';
import FieldButton from '../../../../components/Controls/FieldButton/FieldButton.jsx';
import { Panel } from 'primereact/panel';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Tree } from 'primereact/tree';
import { SplitButton } from 'primereact/splitbutton';
import FieldChips from 'components/Controls/FieldChips/FieldChips.jsx';
import { Route, Redirect, Link, useHistory } from 'react-router-dom';

const VisitLogList = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['visitLog', 'common']);
  const [Ubicacion, setUbicacion] = useState({});
  const [RegistroVisitanteList, setRegistroVisitanteList] = useState([]);
  const [ClaUbicacion, setClaUbicacion] = useState(null);
  const [DeviceUniqueID, setDeviceUniqueID] = useState(getDeviceUniqueID());
  const refreshList = useRef();

  useEffect(() => {
    GetRegistroVisitante(props.ClaUbicacion);
    setClaUbicacion(props.ClaUbicacion === undefined ? 0 : props.ClaUbicacion);
    console.log(props.Reflesh);
  }, [props.ClaUbicacion, props.refresh]);

  useEffect(() => { 
    window.addEventListener('visibilitychange', () => {
      if(document.hidden) { 
        clearInterval(refreshList.current); 
      }
      else {   
        refreshList.current = setInterval(() => { 
          GetRegistroVisitante(props.ClaUbicacion);
        }, 60000);
      }
    });
      
    refreshList.current = setInterval(() => { 
      GetRegistroVisitante(props.ClaUbicacion);
    }, 60000);
    
    return () => {
      if (refreshList.current) {
        clearTimeout(refreshList.current);  
      }  
    }


  }, []);

  const GetRegistroVisitante = (pClaUbicacion) => {
    const urlWebService = `${config.UrlApiProject}VisitLog/GetRegistroVisitante`;
    const paramsToService = {
      ClaUbicacion: pClaUbicacion,
      Accion: 0
    };
    callApi(urlWebService, 'POST', paramsToService, (response) => {
      setRegistroVisitanteList(response.data.RegistroVisitante);
    }, null, false, false);
  };

  const EditarTemplate = (row) => {
    return (
      <>
        {row.FechaSalida === null ? (
          <>
            <FieldButton
              onClick={() => onClickCheckedOut(row)}
              className="p-button-rounded p-button-primary no-border"
              label={t(row.EstatusLink)}
            />
          </>
        ) : (
          <>
            <span>{row.FechaSalida}</span>
          </>
        )}
      </>
    );
  };

  const onClickCheckedOut = (row) => {
    const urlWebServiceSave = `${config.UrlApiProject}VisitLog/RegistroVisitante`;
    const paramsService = {
      ClaUbicacion: row.ClaUbicacion,
      IdEncuesta: row.IdEncuesta,
      Accion: 3,
      IdRegistroVisitante: row.IdRegistroVisitante,
      DeviceUniqueID
    };
    callApi(urlWebServiceSave, 'POST', paramsService, (response) => {
      GetRegistroVisitante(ClaUbicacion);
    });
  };

  return (
    <>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          &nbsp;
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <FieldDataTable
            value={RegistroVisitanteList}
            scrollable
            className="editable-cells-table"
            editMode="cell"
            selectionMode="single"
          >
            <Column
              body={EditarTemplate}
              className="text-center"
              header={t('visitLog:CheckOut')}
              headerStyle={{ width: '80px', textAlign: 'center' }}
              style={{ textAlign: 'center' }}
            >
            </Column>
            <Column
              field="FechaEntrada"
              header={t('visitLog:CheckIn')}
              columnKey="FechaEntrada"
              headerStyle={{ width: '120px', textAlign: 'center' }}
              style={{ textAlign: 'center' }}
            >
            </Column>
            <Column
              field="GuestName"
              header={t('visitLog:GuestName')}
              columnKey="GuestName"
              headerStyle={{ width: '180px' }}
            >
            </Column>
            <Column
              field="VisitingTo"
              header={t('visitLog:VisitingTo')}
              columnKey="VisitingTo"
              headerStyle={{ width: '180px' }}
            >
            </Column>
            <Column
              field="Estatus"
              header={t('visitLog:Status')}
              columnKey="Estatus"
              headerStyle={{ width: '120px', textAlign: 'center' }}
              style={{ textAlign: 'center' }}
            >
            </Column>
          </FieldDataTable>
        </Col>
      </Row>
    </>
  );
};

export default VisitLogList;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter, Label } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../utils/utils';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon';
import moment from 'moment';
import Highcharts from 'highcharts';
import swal from '@sweetalert/with-react';
import PageContent from 'layouts/PageContent';
import { Toolbar } from 'primereact/toolbar';
import { ProgressBar } from 'primereact/progressbar';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldDropdownMultiSelect from '../../../components/Controls/FieldDropDownMultiSelect/FieldDropdownMultiSelect.jsx';
import FieldText from 'components/Controls/FieldText.jsx';
import FieldNumber from 'components/Controls/FieldNumber.jsx';
import FieldCheckbox from 'components/Controls/FieldCheckbox.jsx';
import FieldAutoComplete from 'components/Controls/FieldAutoComplete.jsx';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import { Dialog } from 'primereact/dialog';
import Select, { InputActionMeta } from 'react-select';
import { Panel } from 'primereact/panel';

const LibraryFilter = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['salesPerformanceSales', 'common']);
  const [ClaTipoProyecto, setClaTipoProyecto] = useState(1);
  const [FechaEstimadaIni, setFechaEstimadaIni] = useState(null);
  const [FechaEstimadaFin, setFechaEstimadaFin] = useState(null);
  const [ClaProyecto, setClaProyecto] = useState(null);
  const [AdvancedSearchText, setAdvancedSearchText] = useState(null);
  const [ClaEstatusActividad, setClaEstatusActividad] = useState([]);
  const [errors, setErrors] = useState({});
  const [ShowOnlyPending, setShowOnlyPending] = useState(0);
  const [OpcionesLibrary, setOpcionesLibrary] = useState([]);
  const [ClaLibrary, setClaLibrary] = useState(null);

  useEffect(() => {
    obtenerLibrary();
    setErrors({});
  }, []);

  const obtenerLibrary = () => {
    const getLibraryCmb = `${config.UrlApiProject}DMS/GetLibraryCmb`;
    const paramsToService = {};
    callApi(getLibraryCmb, 'GET', paramsToService, (response) => {
      setOpcionesLibrary(response.data.Library);
    });
  };

  const onApplyFilters = async () => {
    if (props.onSearch) {
      const filtersData = {
        ClaLibrary
      };
    //   filtersData.flagSave = 0;
    //   filtersData.ShowOnlyPending = ShowOnlyPending;
      props.onSearch(filtersData);
    }
  };

  const onAddFolderLibrary = () => {
    if (props.onAfterSelect != null) {
      const data = { ClaLibrary: null };
      props.onAfterSelect(data);
    }
  };

  const onClickSaveSettins = () => {
    if (props.onSave) {
      const filtersData = {};
      filtersData.flagSave = 1;
      props.onSave(filtersData);
    }
  };

  const filterHeaderTemplate = (options) => {
    const toggleIcon = options.collapsed
      ? 'pi pi-chevron-down text-white'
      : 'pi pi-chevron-up text-white';
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className="card-header">
        <div className="p-toolbar p-component">
          <div className="p-toolbar-group-left">
            <div className="card-title">
              <FieldButton className={options.togglerClassName} onClick={options.onTogglerClick}>
                <span className={toggleIcon}></span>
              </FieldButton>
              <span className={titleClassName}>{t('common:Filters')}</span>
            </div>
          </div>

          <div className="p-toolbar-group-right">
            <FieldButton
              label={t('myactivities:Search')}
              icon="pi pi-search"
              className="p-button p-highlight"
              onClick={onApplyFilters}
            >
            </FieldButton>
            &nbsp;
            <FieldButton
              label={t('common:New')}
              icon="pi pi-plus"
              onClick={(e) => onAddFolderLibrary()}
              className="light-green no-border p-mr-2"
            />
            &nbsp;
            {/* <FieldButton label={t('myactivities:Export')} icon="fa fa-file-excel" className="secondary no-border" onClick={onExportExcelMyProject}>              
            </FieldButton>             */}
          </div>
        </div>
      </div>
    );
  };

  const onChangePendingSearch = (props, e) => {
    setShowOnlyPending(e.checked ? 1 : 0);
  };

  const onClaLibraryChange = (props, e) => {
    const newErrors = { ...errors };
    delete newErrors.ClaLibrary;
    setErrors(newErrors);
    setErrors({});

    const clalibrary =  e.value === undefined ? null : e.value;
    setClaLibrary(clalibrary);    
  };

  return (
    <>
      <Panel header={t('common:Filters')} toggleable headerTemplate={filterHeaderTemplate}>    
        <Row>
          <Col lg={4} md={4} sm={12} xs={12} className="p-3">
            <div className="p-fluid" hidden={true}>
              <FieldDropdown
                name="ClaLibrary"
                value={ClaLibrary}
                options={OpcionesLibrary}
                optionValue="ClaLibrary"
                optionLabel="NomLibrary"
                onChange={(e) => onClaLibraryChange(props, e)}
                errors={errors}
                label={t('dms:NomLibrary')}
              />
            </div>
          </Col>
        </Row>    
      </Panel>
    </>
  );
};
export default LibraryFilter;

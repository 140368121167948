import React , { useEffect } from 'react'; 
import { Alert, Form, FormGroup, Label, Input, Card, CardHeader, CardBody, Row, Col, CardTitle, Button } from 'reactstrap';
import DateBox from 'devextreme-react/date-box';
import CardFormulario from './CardFormulario.js'; 
import { config } from '../../utils/config';
import { callApi, callKrakenApi } from '../../utils/utils'; 
import { ButtonGroup } from 'devextreme-react/button-group';
import { Wizard, WizardStep } from "react-wizard-primitive";
import ReactDOM from "react-dom";
import { Skeleton } from 'primereact/skeleton';
import { Background } from 'devextreme-react/vector-map';
import PageContent from '../../layouts/PageContent'; 


class Formularios extends React.Component {

    constructor(props) {
        super(props); 
      
        this.state = { 
           Formularios: [],
           IsLoading:false
        }

        
    
      };
    
    
    
    componentDidMount()   
    { 
        const urlWebService = `${config.UrlApiProject}formulario/getFormularios`; 

        const paramsService = null;

        this.setState({
          IsLoading: true, 
        });
        
        callApi(urlWebService, 'GET', paramsService, (response) => {
        this.setState({
            Formularios: response.data.Formularios, 
            IsLoading:false
        });


        });  
    }      
    
    

    
    render () {    
        
        if (this.state.IsLoading){
          return (
            <>    
              <PageContent>  
                {/* <div style={{ width: "18rem" }}>
                <Card className="card-stats mb-4 mb-lg-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle className="text-uppercase text-muted mb-0">
                          Total traffic
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">350,897</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-chart-bar" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" />
                        3.48%
                      </span>
                      <span className="text-nowrap">Since last month</span>
                    </p>
                  </CardBody>
                </Card>
              </div>             */}
                <Row>  
                  <Col xs={12} md={4} sm={6} lg={4}>
                    <Card>
                      <CardHeader>
                        <CardTitle><Skeleton className="p-mb-2" height="2rem"></Skeleton></CardTitle>
                      </CardHeader>    
                      <CardBody>    
                        <Row>
                          <div className="col ml--2">
                            <Skeleton width="10rem" className="p-mb-2"></Skeleton>  
                            <small><Skeleton width="2rem" className="p-mb-2"></Skeleton> </small>
                          </div>
                          <Col className="col-auto">  
                            <Button color="primary" className="btn-icon btn-3" size="sm" type="button"> 
                              <span className="btn-inner--text"><Skeleton width="2rem" className="p-mb-2"></Skeleton> </span>  
                            </Button> 
                          </Col>
                        </Row>
                      </CardBody>
                    </Card> 
                  </Col> 
                </Row>
              </PageContent>  
            </>
)
        }

        return (
          <>   
            <PageContent>  
              {/* <div style={{ width: "18rem" }} className="home-card">
              <Card className="card-stats mb-4 mb-lg-0 cardbody-color">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle className="text-uppercase text-muted mb-0 text-white">
                        QUESTIONARIES
                        <div className="form-line">
                          _______________
                        </div>
                      </CardTitle>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                        <i className="fas fa-arrow-right" />
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-muted text-sm">

                    <span className="text-nowrap text-white">Start questionary</span>
                  </p>
                </CardBody>
              </Card>
            </div>   */}        
              {/* <div style={{ width: "18rem" }} className="home-card">
              <Card className="card-stats mb-4 mb-lg-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle className="text-uppercase text-muted mb-0">
                        QUESTIONARIES
                        <div className="form-line">
                          _______________
                        </div>
                      </CardTitle>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                        <i className="fas fa-arrow-right" />
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-muted text-sm">
                    <span className="text-nowrap">Questionary results</span>
                  </p>
                </CardBody>
              </Card>
            </div>      */}     
              {/* <div style={{ width: "18rem" }} className="home-card">
              <Card className="card-stats mb-4 mb-lg-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle className="text-uppercase text-muted mb-0">
                        PROFITABILITY
                        <div className="form-line">
                          _______________
                        </div>
                      </CardTitle>

                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                        <i className="fas fa-arrow-right" />
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-muted text-sm">
     
                    <span className="text-nowrap">Summary report</span>
                  </p>
                </CardBody>
              </Card>
            </div>     */}      
              <Row className="align-items-start"> 
                {this.state.Formularios.map((formulario) => (
                  <Col xs={12} md={4} sm={6} lg={4} key={`formulario-${formulario.ClaFormulario}`}>
                    <CardFormulario formulario={formulario} />
                  </Col>
                ))} 
              </Row>
            </PageContent>  
          </>
           )
    }
}

export default Formularios; 
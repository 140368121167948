/* global google */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, useEffect, useState, useMemo, useRef } from 'react';
// import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps';
import { compose, withProps } from 'recompose';
import { config } from '../../../../../utils/config';
import { callApi } from '../../../../../utils/utils';
import { Tag } from 'primereact/tag';
import { InputSwitch } from 'primereact/inputswitch';
import { Card, CardBody, Col, Collapse, Container, Label, Nav, Navbar, Row } from 'reactstrap';
import FieldText from 'components/Controls/FieldText';
import FieldDropdownMultiSelect from 'components/Controls/FieldDropDownMultiSelect/FieldDropdownMultiSelect';
import FieldCalendar from 'components/Controls/FieldCalendar';
import { useTranslation } from 'react-i18next';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import FieldCheckbox from 'components/Controls/FieldCheckbox';
import { Badge } from 'primereact/badge';
import { Dialog } from 'primereact/dialog';
import FieldDataTable from '../../../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import { Column } from 'primereact/column';
// import Geocode from 'react-geocode';
import { GoogleMap, useJsApiLoader, Polyline, Marker, InfoWindow } from '@react-google-maps/api';
import icon_sucess from '../../../../../assets/img/icons/marker_success.png';
import icon_ligh_green from '../../../../../assets/img/icons/marker_light_green.png';
import icon_yellow from '../../../../../assets/img/icons/marker_yellow.png';
import icon_orange from '../../../../../assets/img/icons/marker_orange.png';
import icon_default from '../../../../../assets/img/icons/marker_default.png';
import FieldMultiSelect from 'components/Controls/FieldMultiSelect';
import FieldToogleSwitch from 'components/Controls/FieldToogleSwitch/FieldToogleSwitch';
import "./PlanningMap.css";

const PlanningMapSegment = (props) => {
  const { t } = useTranslation(['map', 'common']);
  const [TextFilterPlannerOpenOrders, setTextFilterPlannerOpenOrders] = useState(null);
  const [SegmentoMercadoList, setSegmentoMercadoList] = useState([]);
  const [ClaSegmentoMercado, setClaSegmentoMercado] = useState([]);
  const [SegmentoMercadoRange, setSegmentoMercadoRange] = useState([]);
  const [ReadyToShip, setReadyToShip] = useState(true);
  const [Delayed, setDelayed] = useState(true);
  const [NotReady, setNotReady] = useState(true);
  const mapRef = useRef();
  const [EsDialogPlanning, setEsDialogPlanning] = useState(false);
  const [EsDialogPlanningBtn, setEsDialogPlanningBtn] = useState(true);
  const [Tons, setTons] = useState(0);
  const [EsMiPlan, setEsMiPlan] = useState(false);
  const [zoom, setZoom] = useState(5);
  const [selected, setSelected] = useState(null);
  const [OrdersList, setOrdersList] = useState([]);
  const [OrdersPendintToShipmentList, setOrdersPendintToShipmentList] = useState([]);
  const [SegmentoLeyendasList, setSegmentoLeyendas] = useState([]);
  const [sidenavOpen, setsidenavOpen] = useState(true);
  // Geocode.setApiKey(config.GoogleKey);
  let arratItem = [];
  const buttonRef = React.useRef();

  const [center, setcenter] = useState({
    lat: 29.74059776564501,
    lng: -95.32716633074375,
  });
  const containerStyle = {
    top: '0px',
    width: '100%',
    height: '100%',
    flex: '1 1 0'
  };

  const mapOptions = {
    scrollwheel: true,
    zoomControlOptions: {
      style: 'SMALL',
    },
    mapTypeControlOptions: {
      position: 'BOTTOM_RIGHT',
    },
    draggable: true,
    rotateControl: false,
    scaleControl: true,
    streetViewControl: false,
    panControl: true,
    styles: [
      {
        featureType: 'poi',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
    ],
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: config.GoogleKey,
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback((map) => {
    setZoom(6);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback((map) => {
    setMap(null);
  }, []);

  useEffect(() => {
    // useEffect por si se quiere realizar una accion al mover el mapa
  }, [selected]);

  useEffect(() => {
    // useEffect para obtener datos de la dirección
    getOperationPlannerSegmentoMercadoCbm();
    GetOperationPlannerSegmentoLeyendas(); 
  }, []);

  const onChangeTextFilterPlannerOpenOrders = (value) => {
    setTextFilterPlannerOpenOrders(value);
  };

  const onSearchTextFilter = () => {
    /*
    const GetPlanningService = `${config.UrlApiProject}operations/GetOperationPlannerOpenOrdersList`;
    const paramsToService = {
      TextSearch: TextFilterPlannerOpenOrders,
    };
    callApi(GetPlanningService, 'POST', paramsToService, (response) => {
      // const markersList = getMarkerList(response.data.OpenOrdersList);
      // setOrdersList(markersList);
      setOrdersList([]);
      getCoordinatesList(response.data.OpenOrdersList);
      setOrdersPendintToShipmentList(response.data.OpenOrdersList);
    });
    */
  };

  const getOperationPlannerSegmentoMercadoCbm = () => {
    const GetPlanningService = `${config.UrlApiProject}operations/GetOperationPlannerSegmentoMercadoCbm`;
    const paramsToService = {};
    callApi(GetPlanningService, 'GET', paramsToService, (response) => {
      const options = response.data.SegmentoMercado.map((d) => ({
        value: d.ClaSegmentoMercado,
        label: d.NomSegmentoMercado,
      }));
      setSegmentoMercadoList(response.data.SegmentoMercado);
    });
  };

  const GetOperationPlannerSegmentoLeyendas = () => { 
      setSegmentoLeyendas([{
        Id: 1,
        Leyenda: 'Ready (FTL)'
      },
      {
        Id: 2,
        Leyenda: 'Ready (LTL)'
      },
      {
        Id: 3,
        Leyenda: 'Delayed (Ready to ship)'
      },
      {
        Id: 4,
        Leyenda: 'Not ready'
      }
     ]); 
  };

  const onChangeSegmentoMercado = (e) => {
    setClaSegmentoMercado(e.value);
  };

  const onSegmentoMercadoRangeChange = (value) => {
    setSegmentoMercadoRange(value);
  };

  const onPlanning = () => {
    setEsDialogPlanning(true);
    setEsDialogPlanningBtn(false);
  };

  const renderHeaderPlanning = () => {
    return (
      <>
        <div>
          <Row className="">
            <Col>
              <h2>{t('map:OrdersList')}</h2>
            </Col>
          </Row>
          <Row hidden={true}>
            <Col xs={8} sm={8} md={8} lg={8}>
              <FieldCheckbox
                inputId={EsMiPlan}
                checked={EsMiPlan}
                onChange={(e) => onChangeEsMiPlan(props, e)}
              />
              &nbsp;
              <small>{t('maps:Show only my plan')}</small>
            </Col>
            <Col xs={4} sm={4} md={4} lg={4}>
              <div className="text-right">
                <span>
                  {' '}
                  {t('maps:Tons')} &nbsp; <Badge value={Tons}></Badge>
                </span>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  };

  const onChangeEsMiPlan = (props, e) => {
    setEsMiPlan(!!e.checked);
  };

  const onCloseDialogPlanning = () => {
    setEsDialogPlanning(false);
    setEsDialogPlanningBtn(true);
  };

  const OrderPlanningTemplate = (rowData) => {
    return (
      <>
        <Row>
          <Col onClick={(e) => onZoomDialogPlanning(rowData)}>{rowData.IdPedido}</Col>
        </Row>
      </>
    );
  };

  const FechaDeseada = (rowData) => {
    return (
      <> 
        {rowData.FechaDeseada}
      </>
    );
  };

  const FechaFabricacion = (rowData) => {
    return (
      <> 
        {rowData.FechaFabricacion}
      </>
    );
  };

  const FechaPromesa = (rowData) => {
    return (
      <> 
        {rowData.FechaPromesa}
      </>
    );
  };

  const DescripcionPlanningTemplate = (rowData) => {
    return (
      <>
        <Row>
          <Col>{rowData.ShipToName}</Col>
        </Row>
        <Row>
          <Col className="bold">
            <Row>
              <Col>
                <small>{rowData.AddressInfo}</small>
              </Col> 
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  const StatusPlanningTemplate = (rowData) => {
    let objeto = '';
    switch (rowData.StatusID) {
      case 1:
        objeto = <i className="fa fa-circle success-color  "></i>;
        break;
      case 2:
        objeto = <i className="fa fa-circle light-green-color "></i>;
        break;
        case 3:
        objeto = <i className="fa fa-circle light-yellow-color "></i>;
        break;
      case 4:
        objeto = <i className="fa fa-circle orange-dark-color  "></i>;
        break;
      default:
        objeto = <i className="fa fa-circle primary-color"></i>;
    }
    return objeto;
  };

  const leyendaReturn = (id) => {
    let objeto;
    switch (id) {
      case 1:
        objeto = 'fa fa-circle success-color ';
        break;
      case 2:
        objeto = 'fa fa-circle light-green-color ';
        break;
      case 3:
        objeto = 'fa fa-circle light-yellow-color ';
        break;
      case 4:
          objeto = 'fa fa-circle orange-dark-color ';
        break; 
      default:
        objeto = 'fa fa-circle primary-color ';
    }
    return objeto;
  };
 

  const icoReturn = (clase) => {
    let objeto;
    switch (clase) {
      case 1:
        objeto = icon_sucess;
        break;
      case 2:
        objeto = icon_ligh_green;
        break;
      case 3:
        objeto = icon_yellow;
        break;
      case 4:
        objeto = icon_orange;
        break;
      default:
        objeto = icon_default;
    }
    return objeto;
  };

  const onZoomDialogPlanning = (dataItem) => {
    const nZoom = 15;
    if (mapRef.current !== undefined) {
      mapRef.current.setZoom(nZoom);
      mapRef.current.panTo({ lat: dataItem.Lat, lng: dataItem.Lng });
    }
    setZoom(nZoom);
    onSelectedMarker(dataItem);
  };

  const onSelectedMarker = async (itemData) => {
    const index = OrdersList.findIndex((item) => item.IdPedido === itemData.IdPedido);
    const markerselect = OrdersList[index];
    setSelected(markerselect);
  };
  

  const onBoundsChanged = () => {
    // console.log('onBoundsChanged',map.getCenter());
    // setcenter(map.getCenter());
  };

 

  const onClickSearch = async () => {
    let segmentoMercado = '';
    let fechaPromesaIni = null;
    let fechaPromesaFin = null;

    if (ClaSegmentoMercado !== null) {
      for (let index = 0; index < ClaSegmentoMercado.length; index++) {
        const element = ClaSegmentoMercado[index].ClaSegmentoMercado;
        segmentoMercado = `${segmentoMercado + element},`;
      }
    }
    if (
      SegmentoMercadoRange !== null &&
      SegmentoMercadoRange.length > 0 &&
      SegmentoMercadoRange[0] !== null &&
      SegmentoMercadoRange[1] !== null
    ) {
      fechaPromesaIni = SegmentoMercadoRange[0].toDateString();
      fechaPromesaFin = SegmentoMercadoRange[1].toDateString();
    }

    const GetPlanningService = `${config.UrlApiProject}operations/GetOperationPlannerOpenOrdersList`;
    const paramsToService = {
      TextSearch: TextFilterPlannerOpenOrders,
      ClaSegmentoMercado: segmentoMercado,
      FechaPromesaIni: fechaPromesaIni,
      FechaPromesaFin: fechaPromesaFin,
      ReadyToShip: ReadyToShip ? 1 : 0,
      Delayed: Delayed ? 1 : 0,
      NotReady: NotReady ? 1 : 0,
    };
    callApi(GetPlanningService, 'POST', paramsToService, (response) => {
      setOrdersList([]);
      // getCoordinatesList(response.data.OpenOrdersList);
      setOrdersPendintToShipmentList(response.data.OpenOrdersList);
    });
  };

  const getCoordinatesList = (lista) => {
    setOrdersList([]);
    arratItem = [];
    let i = 1;
    lista = [];
    lista.forEach(async (item) => {
      // const cordenadas = getCoordinates(item, i, lista);
      console.log('address info:', item.AddressInfo);
      console.log('apy key:', config.GoogleKey);
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${item.AddressInfo}&key=`
      )
        .then((response) => response.json())
        .then((data) => {
          i += 1;
          return data;
        })
        .catch((error) => {
          i += 1;
        });
      const result = response.status === 'OK' ? response : null;
      
      console.log('resultado google:',  result);

      if (result !== null) {
        result.results.forEach((itemAux) => {
          const newRow = {
            IdPedido: item.IdPedido,
            Tons: item.Tons,
            ShipToName: item.ShipToName,
            AddressInfo: item.AddressInfo,
            ZipCode: item.ZipCode,
            City: item.City,
            Estado: item.Estado,
            FechaDeseada: item.FechaDeseada,
            FechaFabricacion: item.FechaFabricacion,
            FechaPromesa: item.FechaPromesa,
            StatusID: item.StatusID, 
            Lat: itemAux.geometry.location.lat,
            Lng: itemAux.geometry.location.lng,
          };
          arratItem.push(newRow);
        });
        if (i === lista.length) {
          setOrdersList(arratItem);
        }
      } else if (i === lista.length) {
        setOrdersList(arratItem);
      }
    });
  };

  

  return isLoaded ? (
    <>  
      <Row className='planning-content'>
        <Col>
          <Row className="pt-4 pb-2 pl-2 planningMap planning-map-filters">
            <Col xs={12} sm={12} md={2} lg={2}>
              <div className="p-fluid">
                <FieldText
                  name="TextFilterPlannerOpenOrders"
                  value={TextFilterPlannerOpenOrders}
                  onChange={(e) => onChangeTextFilterPlannerOpenOrders(e.target.value)}
                  maxLength={250}
              // isInputGroup
              // classGroup="p-inputgroup-addon info Cursor"
              // iconGroup="pi pi-search"
                  onClick={(e) => onSearchTextFilter()}
                  label={t('map:Search')}
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={2} lg={2}>
              <div className="p-fluid">
                <FieldMultiSelect
                  name="ClaSegmentoMercado"
                  optionLabel="NomSegmentoMercado"
                  value={ClaSegmentoMercado}
                  options={SegmentoMercadoList}
                  onChange={(e) => onChangeSegmentoMercado(e)}
                  label={t('map:SegmentoMercado')}
                >
                </FieldMultiSelect>
              </div>
            </Col>
            <Col xs={12} sm={12} md={3} lg={3}>
              <Row>
                <Col xs={12} sm={12} md={8} lg={8}>
                  <div className="p-fluid">
                    <FieldCalendar
                      name="SegmentoMercadoRange"
                      value={SegmentoMercadoRange}
                      onChange={onSegmentoMercadoRangeChange}
                      selectionMode="range"
                      label={t('map:Date')}
                    />
                  </div>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4}>
                  {OrdersPendintToShipmentList.length > 0 && EsDialogPlanningBtn ? (
                    <>
                      <FieldButton onClick={(e) => onPlanning()}>
                        <span className="fa fa-table"></span>
                      </FieldButton>
                    </>
              ) : (
                <></>
              )}
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={5} lg={5}>
              <Row>
                <Col xs={12} sm={12} md={4} lg={4}>
                  <div className="p-toolbar-group-right">
                    <InputSwitch
                      className="checksuccess"
                      checked={ReadyToShip}
                      onChange={() => setReadyToShip(!ReadyToShip)}
                    >
                    </InputSwitch>
                &nbsp;
                    {t('map:ReadyToShip')}
                  </div>
                </Col>
                <Col xs={12} sm={12} md={3} lg={3}>
                  <div className="p-toolbar-group-right">
                    <InputSwitch
                      className="checkinfoDelayed"
                      checked={Delayed}
                      onChange={() => setDelayed(!Delayed)}
                    >
                    </InputSwitch>
                &nbsp;
                    {t('map:Delayed')}
                  </div>
                </Col>
                <Col xs={12} sm={12} md={3} lg={3}>
                  <div className="p-toolbar-group-right">
                    <InputSwitch
                      className="checkdanger"
                      checked={NotReady}
                      onChange={() => setNotReady(!NotReady)}
                    >
                    </InputSwitch>
                &nbsp;
                    {t('map:NotReady')}
                  </div>
                </Col>
                <Col xs={12} sm={12} md={2} lg={2}>
                  <div className="p-fluid">
                    <FieldButton
                      onClick={() => onClickSearch()}
                      className="p-button"
                  // icon="pi pi-search"
                      label="Search"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <div className="planningMap planningmap-leyendas">
              <table className="table">
                <tbody>
                  <tr>
                    <td>
                      {SegmentoLeyendasList.map((element, index) => {
                  return (
                    <>
                      <div id={index}>
                        <i className={leyendaReturn(element.Id)}></i> {element.Tipo}{' '}
                        {element.Leyenda}
                      </div>
                    </>
                  );
                })}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Row>
        </Col>
      </Row>
      

      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        defaultZoom={zoom}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={mapOptions}
        onBoundsChanged={onBoundsChanged}
        onCenterChanged={() => {}}
      >
        {OrdersList.map((marker) => {
          const image = {
            url: icoReturn(marker.StatusID), // (marker.EsPedido?icoReturn(marker.Clase):icoDriverMarker),
            size: new google.maps.Size(90, 90),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(17, 34),
            scaledSize: new google.maps.Size(25, 25),
          };
          return (
            <Marker
              key={marker.id}
              position={{ lat: marker.Lat, lng: marker.Lng }}
              icon={image}
            >
            </Marker>
          );
        })}
        <></>
      </GoogleMap>

      {EsDialogPlanning ? (
        <Dialog
          position="bottom-left"  
          visible={EsDialogPlanning}
          modal={false} 
          header={renderHeaderPlanning}
          onHide={() => onCloseDialogPlanning()}
          blockScroll={true}
          draggable={false}
        > 
          <FieldDataTable
            value={OrdersPendintToShipmentList}
            scrollable
            scrollHeight="400px"
          >
            <Column
              field="Class"
              header=""
              body={StatusPlanningTemplate}
              headerStyle={{ width: '30px' }}
              className="text-center"
            >
            </Column>
            <Column
              field="IdPedido"
              className="text-center"
              header={t('map:OrderID')}
              body={OrderPlanningTemplate}
              headerStyle={{ width: '80px' }}
            >
            </Column>
            <Column
              field="ShipToName"
              header={t('map:ShipToName')}
              // body={DescripcionPlanningTemplate}
              className="overflow-ellipsis"
              headerStyle={{ width: '365px' }}
            >
            </Column>
            <Column
              field="Tons"
              header={t('map:Weight')}
              className="text-center"
              headerStyle={{ width: '80px' }}
            >
            </Column>
            <Column
              field="FechaDeseada"
              className="text-center"
              header={t('map:FechaDeseada')} 
              headerStyle={{ width: '110px' }}
              body={FechaDeseada}
            >
            </Column>
            <Column
              field="FechaFabricacion"
              className="text-center"
              header={t('map:FechaFabricacion')} 
              headerStyle={{ width: '110px' }}
              body={FechaFabricacion}
            >
            </Column>
            <Column
              field="FechaPromesa"
              className="text-center"
              header={t('map:FechaPromesa')} 
              headerStyle={{ width: '110px' }}
              body={FechaPromesa}
            >
            </Column>
            <Column
              field="AddressInfo"
              header={t('map:Address')}
              className="overflow-ellipsis"
              headerStyle={{ width: '180px' }}
            >
            </Column>
            <Column field="City" header={t('map:City')} headerStyle={{ width: '180px' }}></Column>
            <Column
              field="Estado"
              header={t('map:State')}
              headerStyle={{ width: '180px' }}
            >
            </Column>
            <Column
              field="NomPais"
              header={t('map:Country')}
              headerStyle={{ width: '180px' }}
            >
            </Column>
          </FieldDataTable>
        </Dialog>
      ) : (
        <></>
      )} 
    </>
  ) : (
    <></>
  );
};
export default PlanningMapSegment;

import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, CardBody, Card } from 'reactstrap';
import { callApi, showSweetAlert, formatDate } from "utils/utils";
import { config } from 'utils/config';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import { Column } from 'primereact/column';
import PDFViewer from 'components/Controls/PDFViewer/PDFViewer';

const ViewPODDetails = (props) => {
  const {t, i18n} = useTranslation(['operations', 'common']);

  const [selectedPod, setSelectedPod] = useState([]  );
  const [podExtractedData, setPodExtractedData] = useState([]);
  const [pdfLink, setPDFLink] = useState(null);
  const [pages, setPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState({FileExtension: ""});

  useEffect(() => {
    if (props.pod !== undefined) {
      const {pod} = props;
      setSelectedPod(
        [
          { Field: t('pod:Company'), Value: pod.NombreEmpresa},
          { Field: t('pod:InvoiceNum'), Value: pod.NumFactura},
          { Field: t('pod:SONum'), Value: pod.SoNum },
          // { Field: t('pod:ReleaseNum'), Value: pod.ReleaseNumber },
          { Field: t('pod:InvoiceDate'), Value: formatDate(pod.FechaFactura) },
          { Field: t('pod:Vendor'), Value: pod.NomProveedor},
          { Field: t('pod:BillTo'), Value: `${pod.BillToNum} - ${pod.BillToName}` },
          { Field: t('pod:ShipTo'), Value: `${pod.ShipToName}` }
        ]
      )

      const urlWebService = `${config.UrlApiProject}POD/GetExtractedPodData?IdPod=${pod.IdPod}`;
      const paramsService = {};
      callApi(urlWebService, 'GET', paramsService, (response) => {
        setPodExtractedData(response.data);
  
        const urlWebService2 = `${config.UrlApiProject}POD/GetPODPages?IdPod=${pod.IdPod}`;
        const paramsService2 = {};
        callApi(urlWebService2, 'GET', paramsService2, (response) => {
          const newPages = [];
          for (let i = 0; i < response.data.length; i++) {
            const newPage = response.data[i];
            newPage.PageNumber = i + 1;
            newPages.push(newPage);
          }
  
          if (newPages.length > 0) {
            setSelectedPage(newPages[0]);
            onSelectedPageChange({value: newPages[0]});
          }
  
          setPages(newPages);
        });
      });
    }
  }, [])
  
  const pageNumberTemplate = (row) => {
    return (
      <>
        {`Page ${row.PageNumber}`}
      </>
    )
  }

  const primaryPODTemplate = (row) => {
    return (
      <>
        {row.IsPrimary === 1 ? t('pod:MainPOD') : ''}
      </>
    )
  }

  const onSelectedPageChange = (e) => {
    console.log(e)
    const row = e.value;
    setSelectedPage(e.value);

    setPDFLink(`data:${e.FileExtension === 'pdf' ? 'application' : 'image'}/${e.FileExtension};base64,${row.FilePOD}`);
  }

  return (
    <>
      <Row className='mt-2 align-items-start'>
        <Col xs={12} sm={12} md={6} lg={6}>
          <FieldDataTable
            value={selectedPod}
            header={t('pod:SOInformation')} 
            scrollable
            paginator={false}
            className='mt-2'
          >
            <Column field='Field' header={t('pod:Field')} headerStyle={{ width: '120px' }} />
            <Column field='Value' header={t('pod:Value')} />
          </FieldDataTable>
          <div className='mt-4'>
            <span className='text-info'><i className='pi pi-info-circle mr-2' />{t('pod:DataWeExtracted')}</span>
          </div>
          <FieldDataTable
            value={podExtractedData}
            header={t('pod:DataExtracted')}  
            scrollable
            className='mt-1'
            paginator={false}
          >
            <Column field='NomTipoPODCampo' header={t('pod:Field')} headerStyle={{ width: '120px' }} />
            <Column field='Valor' header={t('pod:Value')} />
          </FieldDataTable>
          <div className='mt-4'>
            <span className='text-info'><i className='pi pi-info-circle mr-2' />{t('pod:SelectPageToView')}</span>
          </div>
          <FieldDataTable
            value={pages} 
            header={t('pod:Pages')}  
            scrollable
            selectionMode='single'
            selection={selectedPage}
            onSelectionChange={e => onSelectedPageChange(e)}
            paginator={false}
            className='mt-2'
          >
            <Column body={pageNumberTemplate} header={t('pod:PageNumber')} headerStyle={{ width: '90px' }} />
            <Column body={primaryPODTemplate} header='' />
          </FieldDataTable>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={6} className='mt-2' style={{height: '90vh'}}>
          {(selectedPage.FileExtension).toLowerCase() === 'pdf' ? (
            <PDFViewer
              file={pdfLink}
              ShowDialog={false}
            />
            ) : (
              <Card style={{background: '#e0e0e0'}}>
                <CardBody>
                  <img alt='POD Document' src={pdfLink} style={{maxWidth: '99%', maxHeight: '100%', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} />
                </CardBody>
              </Card>
            )}
        </Col>
      </Row>
    </>
  )
}

export default ViewPODDetails;
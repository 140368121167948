/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../../../utils/config';
import { callApi } from '../../../utils/utils';
import { Dialog } from 'primereact/dialog';
import { Row, Col } from 'reactstrap';
import dgt_bulk from '../../../assets/img/dgt_bulk-plant.png';
import dgt_collated from '../../../assets/img/dgt_collated.jpg';
import dgt_paper from '../../../assets/img/dgt_paper.png';
import dgt_houston from '../../../assets/img/dgt_houston.png';
import './DigitalViewDashboard.scss';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import DigitalViewDashboardMachineCard from './DigitalViewDashboardMachineCard';

const DigitalViewDashboard = (props) => { 
 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);
  const [DGTDashboard, setDGTDashboard] = useState([]);
  const [DGTDetail, setDGTDetail] = useState([]);
  const [DGTDetailModal, setDGTDetailModal] = useState({});
  const [EsDialogDGTDashboard, setEsDialogDGTDashboard] = useState(false);
  const [DGTDashboardDetail, setDGTDashboardDetail] = useState([]);
  const [ClaCelda, setClaCelda] = useState([]);
  const [TodayDateBegin, setTodayDateBegin] = useState(null);
  const [TodayDateEnd, setTodayDateEnd] = useState(null);
  const [IdCelda, setIdCelda] = useState(null);
  const [LastTipoPlano, setLastTipoPlano] = useState(null);
  
  useEffect(() => {    
    getDigitalViewDashboard(props.TipoPlano);
    const start = new Date();
    start.setUTCHours(0,0,0,0);

    const end = new Date();
    end.setUTCHours(23,59,59,999);

    setTodayDateBegin(start.getTime())
    setTodayDateEnd(end.getTime())
  }, [props.TipoPlano, props.forceRefresh])

  const getDigitalViewDashboard = (tipoPlano) => {
    if (props.OnLoadStarts) props.OnLoadStarts();
    if (tipoPlano !== LastTipoPlano) {
      setDGTDashboard([]);
      setDGTDetail([]);
      setLastTipoPlano(tipoPlano);
    }
    console.log('getDigitalViewDashboard',tipoPlano);
    const url = `${config.UrlApiProject}DGT/GetDGTDigitalViewDashboard?TipoPlano=${tipoPlano}`;   
    const paramsService= {};  
    callApi(url, 'GET', paramsService, (response) => {          
      const list = response.data.DGTDashboard;
      const detail = response.data.DGTDetail;
      setDGTDashboard(list);
      setDGTDetail(detail);
      if (typeof(props.LastRefresInfo) === 'function'){
        props.LastRefresInfo(detail.LastUpdateDate);
      }
      if (props.OnLoadEnds) props.OnLoadEnds();
    }, (error) => {
      console.error("Error loading digital layout view.", error);
      if (props.OnLoadEnds) props.OnLoadEnds();
    }, false, false);
  }
  
const closeDialogDGTDashboard = () => {   
  setEsDialogDGTDashboard(false);
  setDGTDetailModal({});
  setDGTDashboardDetail([]); 
};

const openDialogDGTDashboard = (item) => {  
  if (props.TipoPlano === 4){
    console.log('No detail available in HOUSTON')
  }
  else{
    setIdCelda(item.ClaCelda);
    // GetDGTDigitalViewDashboardDetail(item.ClaCelda);
    setEsDialogDGTDashboard(true);
  }
   
};

const returnImage = (TipoPlano)=> {
  let iconDGT = null; 
  switch (TipoPlano) {
    case 1:
      iconDGT = dgt_bulk
      break;
    case 2:
      iconDGT = dgt_collated
      break;       
    case 3: 
      iconDGT = dgt_paper
      break;             
    case 4: 
     iconDGT = dgt_houston
    break;  
    default:
      iconDGT = dgt_bulk
    break;
  }
  return iconDGT;
}

const dialogStyle = {
  width: '600px', 
};
 

const renderHeaderDGTDashboard = () => {
  return (
    <div>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}> 
          <span>{t('dgt:Celda')} #{ClaCelda}</span> &nbsp; <a target="_blank" href={`http://scadapopmc:3000/d/4IIJ0fjnk/main-dashboard?orgId=1&var-Cell=${IdCelda}&var-ClaUbicacion=68&from=${TodayDateBegin}&to=${TodayDateEnd}`}>View in Grafana</a>
        </Col> 
      </Row>
    </div>
  );
}; 

const getBackGroundColorCell = (EstatusProduccionCelda) => {
   /*
    EstatusProduccionCelda
    1 -> red-status
    2 -> yello-status
    3 -> green-status 
  */ 
   // console.log('EstatusProduccionCelda', EstatusProduccionCelda);
    if (EstatusProduccionCelda === 1){
      return '#c40101'
    }
    
    if (EstatusProduccionCelda === 2){
      return '#f69708'
    }
  
    if (EstatusProduccionCelda === 3){
      return '#01ad0f'
    }
  
    return '#989898';
}

const getBackGroundColorMaquina = (StatusSenial) => {
    /*
			Status Senial 
			0 -> cuando no hay check in															   (gray-status)
			1 -> cuando hay check in y esta corriendo											   (green-status)
			2 -> cuando hay check in y esta detenida y no tiene paro y lleva menos de 30 minutos   (yellow-blink-status)
			3 -> cuando hay check in y esta detenida y no tiene paro y lleva mas   de 30 minutos   (red-blink-status)
			4 -> cuando hay check in y esta detenida y tiene paro								   (red-status)
			5 -> cuando hay check in y no tiene senial											   (blue-status) 
		  */   
    if (StatusSenial === 0){
      return 'gray-status'
    }
    
    if (StatusSenial === 1){
      return 'green-status'
    }
  
    if (StatusSenial === 2){
      return 'yellow-blink-status'
    }

    if (StatusSenial === 3){
      return 'red-blink-status'
    }

    if (StatusSenial === 4){
      return 'red-status'
    }

    if (StatusSenial === 5){
      return 'blue-status'
    }

    return 'gray-status';
}

    return (
      <>          
        <div className='dvdashboard' style={{height:'100%'}}>
          <img
            src={returnImage(props.TipoPlano)} 
            className='imgdvd'
            alt="Images"
            style={{display:'block'}}
            height="100%"
            width="100%"
          />
          {
                DGTDashboard.map((item, index) => { 
                 
                  return (
                    <div key={index} onClick={() => openDialogDGTDashboard(item)}>
                      <div
                        style={{                                                        
                          left: `${item.PosicionX}%`,    
                          top: `${item.PosicionY}%`,
                          transform: `rotate(${item.GradosRotacion}deg)`,
                          background: getBackGroundColorCell(item.EstatusProduccionCelda)
                        }}
                        className='cell-icon'             
                      >  
                      </div> 
                    </div>
                  );
                })
          } 

          {
                DGTDetail.map((item, index) => { 
                   
                  return (
                    <div key={index} onClick={() => openDialogDGTDashboard(item)}>
                      <div
                        style={{                                                        
                          left: `${item.PosicionX}%`,    
                          top: `${item.PosicionY}%`,
                          // transform: `rotate(${item.GradosRotacion}deg)`, 
                        }}    
                        className={`header-icon ${getBackGroundColorMaquina(item.StatusSenial)}`}   
                        title={`${item.DisplayName} ${item.ClaveMaquina}`}     
                      >  
                      </div> 
                    </div>
                  );
                })
          } 
        </div>
        <Dialog
          visible={EsDialogDGTDashboard}
          header={renderHeaderDGTDashboard}
          style={dialogStyle}
          modal
          className="dialog-transparent"
          contentClassName="dialog-content-transparent"
          onHide={closeDialogDGTDashboard} 
          closable={false} 
          dismissableMask={true}
        >           
          <Row>
            <Col>
              <DigitalViewDashboardMachineCard
                TipoPlano={props.TipoPlano}
                ClaCelda={IdCelda}
                forceRefresh={props.forceRefresh}
                ShowAssetView={props.ShowAssetView}
              />
            </Col>
          </Row>   
          <Row>
            <Col className='text-center'>
              <FieldButton
                label={t('common:Close')}
                icon="fas fa-times"
                className="warning no-border p-mr-2 rounded-button"
                onClick={closeDialogDGTDashboard}
              />
            </Col>
          </Row>
        </Dialog> 
      </>  
    ); 
};

export default DigitalViewDashboard;

/* eslint-disable radix */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PageContent from '../../../../layouts/PageContent';
import { Row, Col, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { Steps } from 'primereact/steps';
import EmployeeSelfReview from '../../CompanyCulture/EmployeeSelfReview/EmployeeSelfReview.jsx';
import ManagerReview from '../../CompanyCulture/ManagerReview/ManagerReview.jsx';
import TreeviewButton from '../../../../components/Controls/TreeViewButton/TreeviewButton.jsx';
import { config } from '../../../../utils/config';
import { callApi, getSessionItem } from '../../../../utils/utils';
import { Badge } from 'primereact/badge';
import OrganizationalView from '../../OrganizationalView';
import queryString from 'query-string';
import ScoreCardSummaryDet from './ScoreCardSummaryDet.jsx';
import ReviewOfInformation from './ReviewOfInformation.jsx';

const AnnualReview = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);

  const params = queryString.parse(props.location.search);

  const [claUsuarioSeleccionado, setClaUsuarioSeleccionado] = useState(
    params.ClaUsuarioSeleccionado === undefined
      ? getSessionItem('ClaUsuarioMod', '')
      : params.ClaUsuarioSeleccionado
  );
  const [activeIndex, setActiveIndex] = useState(
    params.ActiveStep ? parseInt(params.ActiveStep) : 0
  );
  const [anio, setAnio] = useState(params.Anio ? params.Anio : null);
  const [EstatusEmployeeSelfReview, setEstatusEmployeeSelfReview] = useState(0);
  const [EstatusManagerReview, setEstatusManagerReview] = useState(0);
  const [EstatusContribucionReview, setEstatusContribucionReview] = useState(0);
  const [EstatusAnnualReview, setEstatusAnnualReview] = useState(0);
  const [Activa180DegreeReview, setActiva180DegreeReview] = useState(0);
  const [ActivaEmployeeSelfReview, setActivaEmployeeSelfReview] = useState(0);
  const [ActivaManagerReview, setActivaManagerReview] = useState(0);
  const [ActivaAnnualReview, seActivaAnnualReview] = useState(0);
  const [defaultAnio, setdefaultAnio] = useState(2021);

  useEffect(() => {
    obtenerInformacionAnnualUsuario(claUsuarioSeleccionado);
  }, []);

  const obtenerInformacionAnnualUsuario = (ClaUsuarioSeleccionado) => {
    const GetPerformanceDevelopmentByUser = `${config.UrlApiProject}hr/GetPerformanceDevelopmentByUser?Anio=${anio}&ClaUsuario=${ClaUsuarioSeleccionado}`;
    const paramsToService = {};
    callApi(GetPerformanceDevelopmentByUser, 'GET', paramsToService, (response) => {
      if (response.data.PerformanceDevelopmentInfo.length > 0) {
        setEstatusEmployeeSelfReview(
          response.data.PerformanceDevelopmentInfo[0].EstatusEmployeeSelfReview
        );
        setEstatusManagerReview(response.data.PerformanceDevelopmentInfo[0].EstatusManagerReview);
        setEstatusContribucionReview(
          response.data.PerformanceDevelopmentInfo[0].EstatusContribucionReview
        );
        setEstatusAnnualReview(response.data.PerformanceDevelopmentInfo[0].EstatusAnnualReview);

        setActiva180DegreeReview(response.data.PerformanceDevelopmentInfo[0].Activa180DegreeReview);
        setActivaEmployeeSelfReview(
          response.data.PerformanceDevelopmentInfo[0].ActivaEmployeeSelfReview
        );
        setActivaManagerReview(response.data.PerformanceDevelopmentInfo[0].ActivaManagerReview);
        seActivaAnnualReview(response.data.PerformanceDevelopmentInfo[0].ActivaAnnualReview);
      }

      /*
       : Array(1) 
          Activa180DegreeReview: 1
          Anio: 2021
          Comentarios: null
          EsCompanyCultureRevisado: 0
          EsContribucionRevisado: 0
          EsEmployeeSelfReviewRevisado: 0
          EsManagerReviewRevisado: 0
          Estatus180DegreeManagerReview: 0
          Estatus180DegreeSelfReview: 0
          EstatusAnnualReview: 0
          EstatusContribucionReview: 0
          EstatusEmployeeSelfReview: 1
          EstatusManagerReview: 0
          IdPerformanceDev: 1 
      */
    });
  };

  const stepItems = [
    {
      label: (
        <>
          {EstatusEmployeeSelfReview === 1 && ActivaEmployeeSelfReview === 1 ? (
            <div className="text-center">
              <i className="fa fa-check light-green-color"></i>
            </div>
          ) : (
            <>
              <div className="text-center">
                {ActivaEmployeeSelfReview === 1 ? (
                  <i className="fa fa-unlock primary-color"></i>
                ) : (
                  <i className="fa fa-lock warning-color"></i>
                )}
              </div>
            </>
          )}

          <div>{t('hr:EmployeeSelfReview')}</div>
        </>
      ),
      className: 'itemInicio',
      command: (event) => {},
    },
    {
      label: (
        <>
          {EstatusContribucionReview === 1 && ActivaEmployeeSelfReview === 1 ? (
            <div className="text-center">
              <i className="fa fa-check light-green-color"></i>
            </div>
          ) : (
            <>
              <div className="text-center">
                {ActivaEmployeeSelfReview === 1 ? (
                  <i className="fa fa-unlock primary-color"></i>
                ) : (
                  <i className="fa fa-lock warning-color"></i>
                )}
              </div>
            </>
          )}

          <div>{t('hr:ScoreCardSummary')}</div>
        </>
      ),
      command: (event) => {},
    },
    {
      label: (
        <>
          {EstatusManagerReview === 1 && ActivaManagerReview === 1 ? (
            <div className="text-center">
              <i className="fa fa-check light-green-color"></i>
            </div>
          ) : (
            <>
              <div className="text-center">
                {ActivaManagerReview === 1 ? (
                  <i className="fa fa-unlock primary-color"></i>
                ) : (
                  <i className="fa fa-lock warning-color"></i>
                )}
              </div>
            </>
          )}

          <div>{t('hr:ManagerReview')}</div>
        </>
      ),
      command: (event) => {},
    },
    {
      label: (
        <>
          {EstatusAnnualReview === 1 && ActivaAnnualReview === 1 ? (
            <div className="text-center">
              <i className="fa fa-check light-green-color"></i>
            </div>
          ) : (
            <>
              <div className="text-center">
                {ActivaAnnualReview === 1 ? (
                  <i className="fa fa-unlock primary-color"></i>
                ) : (
                  <i className="fa fa-lock warning-color"></i>
                )}
              </div>
            </>
          )}

          <div>{t('hr:ReviewInformation')}</div>
        </>
      ),
      className: 'itemFinal',
      command: (event) => {},
    },
  ];

  const stepItemsNew = [
    {
      label: (
        <>
          {EstatusEmployeeSelfReview === 1 && ActivaEmployeeSelfReview === 1 ? (
            <div className="text-center">
              <i className="fa fa-check light-green-color"></i>
            </div>
          ) : (
            <>
              <div className="text-center">
                {ActivaEmployeeSelfReview === 1 ? (
                  <i className="fa fa-unlock primary-color"></i>
                ) : (
                  <i className="fa fa-lock warning-color"></i>
                )}
              </div>
            </>
          )}

          <div>{t('hr:EmployeeSelfReview')}</div>
        </>
      ),
      className: 'itemInicio',
      command: (event) => {},
    },
    {
      label: (
        <>
          {EstatusManagerReview === 1 && ActivaManagerReview === 1 ? (
            <div className="text-center">
              <i className="fa fa-check light-green-color"></i>
            </div>
          ) : (
            <>
              <div className="text-center">
                {ActivaManagerReview === 1 ? (
                  <i className="fa fa-unlock primary-color"></i>
                ) : (
                  <i className="fa fa-lock warning-color"></i>
                )}
              </div>
            </>
          )}

          <div>{t('hr:ManagerReview')}</div>
        </>
      ),
      command: (event) => {},
    },
    {
      label: (
        <>
          {EstatusAnnualReview === 1 && ActivaAnnualReview === 1 ? (
            <div className="text-center">
              <i className="fa fa-check light-green-color"></i>
            </div>
          ) : (
            <>
              <div className="text-center">
                {ActivaAnnualReview === 1 ? (
                  <i className="fa fa-unlock primary-color"></i>
                ) : (
                  <i className="fa fa-lock warning-color"></i>
                )}
              </div>
            </>
          )}

          <div>{t('hr:1-on-ReviewInformation')}</div>
        </>
      ),
      className: 'itemFinal',
      command: (event) => {},
    },
  ];

  const StepRender = (index) => {
    switch (index + 1) {
      case 1:
        return (
          <>
            <EmployeeSelfReview
              Anio={anio}
              ClaUsuario={claUsuarioSeleccionado}
            >
            </EmployeeSelfReview>
          </>
        );
      case 2:
        return (
          <>
            {anio > defaultAnio ? (
              <>
                <ManagerReview Anio={anio} ClaUsuario={claUsuarioSeleccionado}></ManagerReview>
              </>
            ) : (
              <>
                <ScoreCardSummaryDet
                  Anio={anio}
                  ClaUsuario={claUsuarioSeleccionado}
                >
                </ScoreCardSummaryDet>
              </>
            )}
          </>
        );
      case 3:
        return (
          <>
            {anio > defaultAnio ? (
              <ReviewOfInformation
                Anio={anio}
                ClaUsuario={claUsuarioSeleccionado}
              >
              </ReviewOfInformation>
            ) : (
              <>
                <ManagerReview Anio={anio} ClaUsuario={claUsuarioSeleccionado}></ManagerReview>
              </>
            )}
          </>
        );
      case 4:
        return (
          <>
            <ReviewOfInformation
              Anio={anio}
              ClaUsuario={claUsuarioSeleccionado}
            >
            </ReviewOfInformation>
          </>
        );
      default:
        return <></>;
    }
  };

  const onChange = (node) => {
    setClaUsuarioSeleccionado(node);
    obtenerInformacionAnnualUsuario(node);
  };

  return (
    <>
      <PageContent title={t('hr:AnnualReview')}>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}></Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Card>
              <CardHeader className="light-blue">
                <CardTitle className="text-center p-2">
                  <div className="p-panel-header">
                    <span className="p-panel-title p-pl-1">{anio}</span>
                    <div style={{ width: '450px' }}>
                      <OrganizationalView
                        onChange={onChange}
                        value={claUsuarioSeleccionado}
                      >
                      </OrganizationalView>
                    </div>
                    <div className=""></div>
                  </div>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Steps
                  model={anio > defaultAnio ? stepItemsNew : stepItems}
                  activeIndex={activeIndex}
                  onSelect={(e) => setActiveIndex(e.index)}
                  readOnly={false}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <br></br>
        {StepRender(activeIndex)}
      </PageContent>
    </>
  );
};

export default AnnualReview;

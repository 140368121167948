/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  ListGroupItem,
  ListGroup,
  Progress,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle
} from 'reactstrap';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { Badge } from 'primereact/badge';
// import FeedbackCoaching from './FeedbackCoaching.jsx';
import { Link } from 'react-router-dom';
import ActionPlanScoreSquare from './ActionPlanScoreSquare.jsx';
import { Toolbar } from 'primereact/toolbar';
import Highcharts from 'highcharts';
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert, tracker } from '../../../../utils/utils';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable.jsx';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import FieldButton from 'components/Controls/FieldButton/FieldButton.jsx';
import ActionPlan from '../ActionPlans/ActionPlan.jsx';
import KPIPositionIndexRole from './KPIPositionIndexRole.jsx';
import KPITrendGraph from './KPITrendGraph.jsx';
import { Dialog } from 'primereact/dialog';
import TeamPerformanceCalculationDet from './TeamPerformanceCalculationDet.jsx';
import EdicionProyecto from '../../Projects/EdicionProyecto';
import KPIProgressEvaluation from './KPIProgressEvaluation.jsx';
import KPIObjectivesDetail from './KPIObjectivesDetail.jsx';

const ScoreCardReport = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['focusReport', 'common']);  
  const [ScoreCardFocusReportList, setScoreCardFocusReportList] = useState([]);
  const [EsAbrirActionPlan, setEsAbrirActionPlan] = useState(false);
  const [EsAbrirKPIPositionIndexRole, setEsAbrirKPIPositionIndexRole] = useState(false);
  const [selectedAPRow, setselectedAPRow] = useState({}); 
  const [ClaPeriodo, setClaPeriodo] = useState(null); 
  const [ClaFechaReporte, setClaFechaReporte] = useState(null); 
  const [IdCSF, setIdCSF] = useState(null); 
  const [EsVerGraficaTendenciaKPI, setEsVerGraficaTendenciaKPI] = useState(false);
  const [EsVerTeamPerformance, setEsVerTeamPerformance] = useState(false);
  const [EsMostrarEditarProyecto, setEsMostrarEditarProyecto] = useState(false);
  const [ClaProyecto, setClaProyecto] = useState(null);
  const [EsDialogModalKPI, setEsDialogModalKPI] = useState(false);
  const [ClaPuesto, setClaPuesto] = useState(null); 
  const [ClaAnio, setClaAnio] = useState(null); 
  const [ClaMes, setClaMes] = useState(null); 
  const [EsAdmin, setEsAdmin] = useState(null); 
  const [showCurrentStatusNewVersion, setshowCurrentStatusNewVersion] = useState(false);
  const [EsMostrarDetalleObjetivos, setEsMostrarDetalleObjetivos] =  useState(false);
  const [KPISelected, setKPISelected] = useState({});

  useEffect(() => {  
    setClaPuesto(props.ClaPuesto === undefined ? 0 : props.ClaPuesto);
    setClaAnio(props.ClaAnio === undefined ? 0 : props.ClaAnio);
    setClaMes(props.ClaMes === undefined ? 0 : props.ClaMes);
    setEsAdmin(props.EsAdmin === undefined ? 0 : props.EsAdmin);
    GetProjectActivitiesScoreCardFocusReport(); 
  }, [props.ClaPuesto, props.ClaAnio, props.ClaMes, props.EsAdmin]);

  const GetProjectActivitiesScoreCardFocusReport = () => {
    const getProjectActivitiesScoreCardFocusReport = `${config.UrlApiProject}ScoreCard/GetProjectActivitiesScoreCardFocusReport`;
    const paramsToService = {
      ClaPuesto : props.ClaPuesto,
      ClaAnio : props.ClaAnio,
      ClaMes : props.ClaMes,
    };
    callApi(getProjectActivitiesScoreCardFocusReport, 'POST', paramsToService, (response) => {          
      setScoreCardFocusReportList(response.data.FocusReport);
      
      // A partir de Septiembre del 2023 se inicio con el nuevo calculo para las iniciativas relacionadas a un proyecto
      // en donde su progreso se mide con avance y su cumplimiento de los KPIs del proyecto. 
      if ((props.ClaAnio === 2023 && props.ClaMes >= 9) || props.ClaAnio >= 2024)
      { 
        setshowCurrentStatusNewVersion(true);  
      }
    });
  };
  const PorcTemplate = (rowData) => {
    return (
      <>
        <label>
          <b>{rowData.Porc}</b> %
        </label>
      </>
    );
  };
  const headerScoreCardDetail = <></>;

  const AccionesTemplateAp = (row) => {
    let className = '';

    switch (row.PlanAccionBandera) {  
      case 0:
        className = "fas fa-flag-checkered Cursor";
        break;
      case 1:
        className = "fa fa-flag danger-color Cursor";
        break;
      case 2:
        className = "fa fa-flag warning-color Cursor";
        break;
      case 3:
        className = "fa fa-flag success-color Cursor";        
        break;
      default:
        className = "fa fa-flag default-color Cursor";
    }
    return (
      <>
        <div onClick={() => onClickActionPlan(row)}>          
          <i className={className}></i>
        </div>
      </>
    );
  }

  const onClickActionPlan = (row) => { 
    setselectedAPRow(row);
    setClaPeriodo(row.ClaPeriodo);
    setClaFechaReporte(row.ClaFechaReporte);
    setIdCSF(row.IdCSF);
    setEsAbrirActionPlan(true);

    tracker.trackEvent({
      type: 'ScoreCardReport', /* component name */
      action: 'CREATE_ACTION_PLAN'
    }) 
  }

  const onClickIndexRole = (row) => {  
    setClaPeriodo(row.ClaPeriodo);
    setClaFechaReporte(row.ClaFechaReporte);
    setIdCSF(row.IdCSF);
    setEsAbrirKPIPositionIndexRole(true);

    tracker.trackEvent({
      type: 'ScoreCardReport', /* component name */
      action: 'CLICK_INDEX_ROLE'
    }) 
  }

  const onClickIndexTendencia = (row) => {  
    setClaPeriodo(row.ClaPeriodo);
    setClaFechaReporte(row.ClaFechaReporte);
    setIdCSF(row.IdCSF);
    setEsVerGraficaTendenciaKPI(true);

    tracker.trackEvent({
      type: 'ScoreCardReport', /* component name */
      action: 'CLICK_VIEW_TREND_DETAIIL'
    }) 
  }

  const onClickDescription = (row) => {      
    setEsVerTeamPerformance(true);
  }


  const AccionesTemplateExpectedTrend = (row) => {
    let className = '';
    switch (row.ExpectedTrend) {
      case 0:
        className = "fa fa-chart-line fa-color-chart-line-down";
        break;
      case 1:
        className = "fa fa-chart-line fa-color-chart-line";
        break;
      default:
        className = "fa fa-exclamation-triangle fa-color-triangle";
    }
    return (
      <>
        <div>          
          <i className={className}></i>
        </div>
      </>
    );
  }
  const AccionesTemplateUoM = (row) => {    
    return (
      <>
        <div>          
          <span>{row.UnidadMedida}</span>
        </div>
      </>
    );
  }
  const footer  = ( 
    <> 
      <Row>
                
        <Column></Column>         
        <Column></Column>     
        <Column className="text-center" footer={() => (<b>{ (ScoreCardFocusReportList.reduce((a,v) =>  a += v.PesoCalif === null ? 0 : v.PesoCalif , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>     
        <Column 
          style={{ display : (!showCurrentStatusNewVersion ?'':'none')}} 
          // headerStyle={{ width: '80px', display : (!showCurrentStatusNewVersion ?'':'none')}} 
        > 
        </Column>
        <Column
          className="text-left" 
          style={{ display : (showCurrentStatusNewVersion ?'':'none')}} 
          // headerStyle={{ width: '80px', display : (showCurrentStatusNewVersion ?'':'none')}} 
          footer={() => (<b>{ (ScoreCardFocusReportList.reduce((a,v) =>  a += v.Score === null ? 0 : v.Score , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}
        >
        </Column>     
        <Column className="text-left" footer={() => (<b>{ (ScoreCardFocusReportList.reduce((a,v) =>  a += v.Score === null ? 0 : v.Score , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>     
        <Column></Column>
        <Column className="text-right" footer={() => (<b>{ (ScoreCardFocusReportList.reduce((a,v) =>  a += v.ScoreMin === null ? 0 : v.ScoreMin , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>     
        <Column className="text-right" footer={() => (<b>{ (ScoreCardFocusReportList.reduce((a,v) =>  a += v.ScoreSatisfactory === null ? 0 : v.ScoreSatisfactory , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>     
        <Column className="text-right" footer={() => (<b>{ (ScoreCardFocusReportList.reduce((a,v) =>  a += v.ScoreOutstanding === null ? 0 : v.ScoreOutstanding , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>     
        <Column></Column>          
        <Column></Column>  
        <Column></Column> 
        <Column></Column> 
        <Column></Column>
      </Row>
    </>
  )
  
  const EstatusPeriodoTemplate = (props) => {
    let className = '';

    switch (props.ColorEstatus) {
      case 1:
        className = 'danger-color';
        break;
      case 2:
        className = 'warning-color';
        break;
      case 3:
        className = 'success-color';
        break;
      default:
        className = 'default-color';
    }
    return (
      <>
        <span className={className}>
          <b>{props.EstatusPeriodo !== null? Highcharts.numberFormat(props.EstatusPeriodo, 2, '.', ','): null}</b>
        </span> 
      </>
    );
  };
  

  const IndexRoleTemplate = (row) => {
    return (<div className='Cursor text-underline' onClick={() => onClickIndexRole(row)}><span>{row.IndexRole}</span></div>);
  } 

  const DescriptionTemplate = (props) => {

    if (props.ClaProyecto !== null){
      return (<><div className='Cursor text-underline' onClick={() => onClickViewProjectDetail(props.ClaProyecto)}><a href='#'><span title={`${t('focusReport:ViewProjectDetail')} ${  props.Description}`}>{props.Description}</span></a></div></>);
    }
    
    return (
    props.ClaTipoIndicador === 1 && props.ClaIndicador === 276 ? 
    (<><div className='Cursor text-underline' onClick={() => onClickDescription(props)}><a href='#'><span title={props.Description}>{props.Description}</span></a></div></>)
    :(<><span title={props.Description}>{props.Description}</span></>)
      )
  
  }
  const MinimoTemplate = (props) => {
    return <>{props.Minimo !== null?  Highcharts.numberFormat(props.Minimo, 2, '.', ',') : null}</>;
  };
  const SatisfactorioTemplate = (props) => {
    return <>{props.Satisfactorio !== null?  Highcharts.numberFormat(props.Satisfactorio, 2, '.', ','): null}</>;
  };

  const SobreSalienteTemplate = (props) => {
    return <>{props.SobreSaliente !== null?  Highcharts.numberFormat(props.SobreSaliente, 2, '.', ','): null}</>;
  };

  const ScoreTemplate = (props) => {
    return <><b>{props.Score !== null?  Highcharts.numberFormat(props.Score, 2, '.', ','): null}</b></>;
  };

  const ScoreKpiTemplate = (props) => {
    let className = '';
    switch (props.ColorEstatus) {
      case 1:
        className = 'danger-color';
        break;
      case 2:
        className = 'warning-color';
        break;
      case 3:
        className = 'success-color';
        break;
      default:
        className = 'default-color';
    }
    if (props.ClaProyecto !== null){
      return (
        <>
          <div
            className='Cursor' 
            onClick={() => onClickViewKpi(props.ClaProyecto)}
          >
            <span className={`${className  } text-underline`} title={`${t('focusReport:ViewProgressEvaluation')}`}>
              <b>{props.EstatusPeriodo !== null? Highcharts.numberFormat(props.EstatusPeriodo, 2, '.', ','): null}</b>
            </span> 
          </div>
        </>
      );
    }

    
    return (
      <span className={`Cursor text-underline ${className}`} title={`${t('focusReport:ViewKPIByPeriod')}`} onClick={() => openObjetivosKPIDetalle(props)}>
        <b>{props.EstatusPeriodo !== null? Highcharts.numberFormat(props.EstatusPeriodo, 2, '.', ','): null}</b>
      </span> 
      )
  
  }



  const SentidoMejoraTemplate = (props) => {
    return (
      <div>
        {props.ClaSentidoMejora === 1 ?  (<i className="fas fa-sort-amount-up"></i>): (<i className="fas fa-sort-amount-down"></i>)}
        &nbsp;<i className="fas fa-chart-line Cursor light-blue-color" onClick={() => onClickIndexTendencia(props)}></i>
      </div>
);
  }; 

  const PeriodsInaRowTemplate = (props) => {
    return (
      <div>
        {props.PeriodsInaRow} {props.Tendencia} 
      </div>
);
  }; 

  

  const onHideActionPlan = () => {
    setEsAbrirActionPlan(false);
    GetProjectActivitiesScoreCardFocusReport();
  }

  const onHideKPIPositionIndexRole = () => {
    setEsAbrirKPIPositionIndexRole(false);
  }

  const dialogStyle = {
    width: '80vw',
  };

  const closeModalTendenciaKPI = () => {
    setEsVerGraficaTendenciaKPI(false);
  };

  const closeModalTeamPerformance = () => {
    setEsVerTeamPerformance(false);
  };

  const renderEditProjectFooter = () => {
    return (
      <>
        <FieldButton
          label={t('common:Close')}
          icon="fas fa-times"
          className="warning no-border p-mr-2 rounded-button"
          onClick={onHideEditarProyecto}
        />
      </>
    );
  };

  const onClickViewProjectDetail = (ClaProyecto)=>{ 
    setClaProyecto(ClaProyecto);
    setEsMostrarEditarProyecto(true);

    tracker.trackEvent({
      type: 'ScoreCardReport', /* component name */
      action: 'OPEN_EDIT_PROJECT'
    }) 
  }

  const onClickViewKpi = (ClaProyecto)=>{ 
    openDialogModalKPI(ClaProyecto);

    tracker.trackEvent({
      type: 'ScoreCardReport', /* component name */
      action: 'VIEW_KPI_EVALUATION'
    }) 
  }

  const onHideEditarProyecto = () => {
    setEsMostrarEditarProyecto(false);
  }

  const renderEditProjectHeader = () => {
    return (
      <div>
        <Row>
          <Col lg={4} md={4} sm={12} xs={12}>            
            <div>
              <span>{t('common:EditProject')}</span>
            </div>
          </Col>          
        </Row>
      </div>
    );
  };

  const openDialogModalKPI = (ClaProyecto) => {
    setClaProyecto(ClaProyecto);
    setEsDialogModalKPI(true); 
   } 

  const openObjetivosKPIDetalle = (item) => {  
    setKPISelected(item);
    setEsMostrarDetalleObjetivos(true)
  }

  const closeDialogModalKPI = () => {
    setEsDialogModalKPI(false); 
   } 
  
  const getSeverityClass = () => {
    let severityClass = ''; 
  
    let severity = '';
    
    const value          =  (ScoreCardFocusReportList.reduce((a,v) =>  a += v.Score === null ? 0 : v.Score , 0 )); 
    const nMinimo        =	(ScoreCardFocusReportList.reduce((a,v) =>  a += v.ScoreMin === null ? 0 : v.ScoreMin , 0 ));
    const	nSatisfactorio =	(ScoreCardFocusReportList.reduce((a,v) =>  a += v.ScoreSatisfactory === null ? 0 : v.ScoreSatisfactory , 0 ));
    const	nSobreSaliente =	(ScoreCardFocusReportList.reduce((a,v) =>  a += v.ScoreOutstanding === null ? 0 : v.ScoreOutstanding , 0 )); 
    /*
     0   - 70    --NO CUMPLE
     71  - 90   --MINIMO
     91  - 100  --SATISFACTORIO
     101 - 120 --SOBRESALIENTE
    */ 
    
    if (value < nMinimo)
    {
        severity = "red";
    }
    else if (value >= nMinimo && value < nSatisfactorio){
      severity = "yellow";
    } else if (value >= nSatisfactorio && value < nSobreSaliente) { 
      severity = "green"
    } else if (value >= nSobreSaliente) {
      severity = "green";
    } else {
      severity = "";
    }    
  
    switch (severity) {
        case "blue":
            severityClass = "score-square-blue-gradient";
            break;
        case "green":
            severityClass = "score-square-green-gradient";
            break;
        case "yellow":
            severityClass = "score-square-yellow-gradient";
            break;
        case "red":
            severityClass = "score-square-red-gradient";
            break;        
        default:
            severityClass = "score-square-default-gradient";
            break;
    }
    
    return severityClass;
  }

  return (
    <>
      <Card>
        <CardBody>   
          <Row>
            <Col className='text-center'>
              <h2><span className="score-square-FocusReporInfo text-center">{t('focusReport:ScoreCardReport')}</span></h2>
            </Col>            
          </Row>   
          <Row className="align-items-start ">
            <Col lg={8} md={8} sm={12} xs={12} className="text-left">
              <i className="fa fa-flag success-color"></i>&nbsp;<span>{t('focusReport:APNotNeeded')}</span>&nbsp;
              <i className="fa fa-flag warning-color"></i>&nbsp;<span>{t('focusReport:APInProcess')}</span>&nbsp;
              <i className="fa fa-flag danger-color"></i>&nbsp;<span>{t('focusReport:APRequired')}</span>&nbsp;
              <i className="fas fa-flag-checkered"></i>&nbsp;<span>{t('focusReport:APFinished')}</span>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12} className="text-right">
              <h3><span className="score-square-FocusReporInfo">{t('focusReport:Score')}</span>: <span className={`${getSeverityClass()} no-border score-square-month-result font-weight-bold score-cursor text-center p-2`}>{ (ScoreCardFocusReportList.reduce((a,v) =>  a += v.Score === null ? 0 : v.Score , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) } % </span> </h3>
              
            </Col>
          </Row>    
          <Row className="align-items-start ">
            <Col lg={12} md={12} sm={12} xs={12}>
              <FieldDataTable
                value={ScoreCardFocusReportList} 
                className="table-header-background-light-blue"  
                footerColumnGroup={footer}     
                paginator={false} 
              > 
                
                <Column
                  field="Description"
                  header={t('focusReport:IndexDescription')}
                  columnKey="Description"
                  className='overflow-ellipsis'
                  headerStyle={{ width: '170px' }}
                  body={DescriptionTemplate}
                >
                </Column>
                <Column
                  className="text-center"
                  header={t('focusReport:Uom')}
                  headerStyle={{ width: '45px', textAlign: 'center' }} 
                  field="UnidadMedida"
                  columnKey="UnidadMedida"
                >
                </Column>
                <Column
                  field="PesoCalif"
                  className="text-center"
                  header={t('focusReport:Weighted')}
                  columnKey="PesoCalif"
                  headerStyle={{ width: '50px', textAlign: 'center' }}
                >
                </Column>
                <Column
                  field="EstatusPeriodo" 
                  header={t('focusReport:CurrentStatus')}
                  columnKey="EstatusPeriodo"
                  style={{ display : (!showCurrentStatusNewVersion ?'':'none')}} 
                  headerStyle={{ width: '80px', display : (!showCurrentStatusNewVersion?'':'none')}} 
                  body={EstatusPeriodoTemplate}
                >
                </Column>
                <Column
                  field="Score" 
                  header={t('focusReport:CurrentStatus')}
                  columnKey="Score" 
                  style={{ display : (showCurrentStatusNewVersion ?'':'none')}} 
                  headerStyle={{ width: '80px', display : (showCurrentStatusNewVersion?'':'none')}} 
                  body={ScoreKpiTemplate}
                >
                </Column>
                <Column
                  field="Score" 
                  header={t('focusReport:ScoreLower')}
                  columnKey="Score"
                  headerStyle={{ width: '50px' }}
                  body={ScoreTemplate}
                >
                </Column> 
                <Column
                  className="text-center"
                  header={t('focusReport:Ap')}
                  headerStyle={{ width: '25px', textAlign: 'center' }}                  
                  body={AccionesTemplateAp} 
                >
                </Column>
                <Column
                  field="Minimo" 
                  header={t('focusReport:MinimunAcceptable')}
                  columnKey="Minimo"
                  className='text-right'
                  headerStyle={{ width: '83px', textAlign: 'center' }}
                  body={MinimoTemplate}
                >
                </Column>
                <Column
                  field="Satisfactorio" 
                  header={t('focusReport:Satisfactory')}
                  columnKey="Satisfactorio"
                  className='text-right'
                  headerStyle={{ width: '83px', textAlign: 'center' }}
                  body={SatisfactorioTemplate}
                >
                </Column>
                <Column
                  field="SobreSaliente" 
                  header={t('focusReport:OutStanding')}
                  columnKey="SobreSaliente"
                  className='text-right'
                  headerStyle={{ width: '85px', textAlign: 'center' }}
                  body={SobreSalienteTemplate}
                >
                </Column> 
                <Column
                  field="Tendencia"
                  className="text-center"
                  header={t('focusReport:Trend')}
                  columnKey="Tendencia"
                  headerStyle={{ width: '65px', textAlign: 'center' }}                
                  body={SentidoMejoraTemplate} 
                >
                </Column> 
                <Column
                  field="PeriodsInaRow"
                  className="text-center"
                  header={t('focusReport:PeriodRow')}
                  columnKey="PeriodsInaRow"
                  headerStyle={{ width: '68px', textAlign: 'center' }}
                  body={PeriodsInaRowTemplate}
                >
                </Column>
                {
                  /*
                  <Column
                    className="text-center"
                    header={t('focusReport:ExpectedTrend')}
                    headerStyle={{ width: '65px', textAlign: 'center' }}                  
                    body={SentidoMejoraTemplate} 
                  >
                  </Column>
                  */
                }
                <Column
                  field="IndexRole"
                  header={t('focusReport:IndexRole')}
                  className="text-center"
                  columnKey="IndexRole"
                  headerStyle={{ width: '45px', textAlign: 'center' }}
                  body={IndexRoleTemplate}
                >
                </Column>

                <Column
                  field="IndexType"
                  header={t('focusReport:IndexType')}
                  className="text-center"
                  columnKey="IndexType"
                  headerStyle={{ width: '45px', textAlign: 'center' }}
                >
                </Column>
                <Column
                  field="ClaveIndicador"
                  className="text-center"
                  header={t('focusReport:Code')}
                  columnKey="ClaveIndicador"
                  headerStyle={{ width: '45px', textAlign: 'center' }}
                >                  
                </Column> 
                
              </FieldDataTable>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Dialog
        visible={EsDialogModalKPI}
        header=""
        style={dialogStyle} 
        modal
        className="dialog-transparent"
        contentClassName="dialog-content-transparent"
        closable={false} 
        onHide={closeDialogModalKPI}
      >
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
                      &nbsp;
          </Col> 
        </Row> 
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
            <KPIProgressEvaluation 
              ClaPuesto={ClaPuesto} 
              ClaMes={ClaMes} 
              ClaAnio={ClaAnio}
              ClaProyecto={ClaProyecto}
              showCloseButton={true}
              onCloseClick={closeDialogModalKPI}
            >
            </KPIProgressEvaluation>
          </Col>
        </Row>
      </Dialog>
      <Dialog
        visible={EsMostrarDetalleObjetivos}
        header=""
        style={dialogStyle} 
        modal
        className="dialog-transparent"
        contentClassName="dialog-content-transparent"
        closable={false} 
        onHide={()=> setEsMostrarDetalleObjetivos(false)}
      >
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
                      &nbsp;
          </Col> 
        </Row> 
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
            <KPIObjectivesDetail
              ClaDepartamento={KPISelected.ClaDepartamento} 
              ClaArea={KPISelected.ClaArea}
              ClaTipoIndicador={KPISelected.ClaTipoIndicador}
              ClaIndicador={KPISelected.ClaIndicador}
              ClaPuesto={KPISelected.ClaPuesto}
              ClaAnio={ClaAnio}
              showCloseButton={true}
              NomKPI={KPISelected.Description}
              onCloseClick={()=> setEsMostrarDetalleObjetivos(false)} 
            >
            </KPIObjectivesDetail>
          </Col>
        </Row>
      </Dialog>
      {
        EsAbrirActionPlan ?
        (  
          <ActionPlan show={EsAbrirActionPlan} onHide={onHideActionPlan} IdCSF={IdCSF} ClaPeriodo={ClaPeriodo} ClaFechaReporte={ClaFechaReporte}></ActionPlan>
        )
        :
        (<></>)
      }

      {
        EsAbrirKPIPositionIndexRole ?
        (  
          <KPIPositionIndexRole show={EsAbrirKPIPositionIndexRole} onHide={onHideKPIPositionIndexRole} IdCSF={IdCSF} ClaPeriodo={ClaPeriodo} ClaFechaReporte={ClaFechaReporte}></KPIPositionIndexRole>
        )
        :
        (<></>)
      }

      <Dialog
        visible={EsVerGraficaTendenciaKPI} 
        style={dialogStyle}
        modal
        className="dialog-transparent"
        contentClassName="dialog-content-transparent"
        closable={false}
        blockScroll={true}
        onHide={closeModalTendenciaKPI}
      > 
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
            <KPITrendGraph
              IdCSF={IdCSF}
              ClaPeriodo={ClaPeriodo}
              ClaFechaReporte={ClaFechaReporte} 
              onCloseClick={closeModalTendenciaKPI}
              showCloseButton={true}
            >
            </KPITrendGraph>
          </Col>
        </Row>
      </Dialog>
      <Dialog
        visible={EsVerTeamPerformance} 
        style={dialogStyle}
        modal
        className="dialog-transparent"
        contentClassName="dialog-content-transparent"
        closable={false}
        onHide={closeModalTeamPerformance}
      > 
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}> 
            <TeamPerformanceCalculationDet
              ClaPuesto={ClaPuesto} 
              ClaMes={ClaMes} 
              ClaAnio={ClaAnio}
              onCloseClick={closeModalTeamPerformance}
              showCloseButton={true}
            >
            </TeamPerformanceCalculationDet>
          </Col>
        </Row>
      </Dialog>
      <Dialog
        visible={EsMostrarEditarProyecto}
        modal
        style={{ width: '100vw' }}
        footer={renderEditProjectFooter}
        header={renderEditProjectHeader}
        onHide={onHideEditarProyecto}
        closable={false}
        className="dialog-header-background-blue"
        maximized={true}
        draggable={false}
        blockScroll={true}
      >
        <EdicionProyecto ClaProyecto={ClaProyecto} EsMostrarEditarProyec={EsMostrarEditarProyecto}></EdicionProyecto>
      </Dialog>
    </>
  );
};

export default ScoreCardReport;

 /* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PageContent from '../../../../layouts/PageContent';
import { Row, Col, Card, CardBody, CardHeader, CardTitle, CardFooter } from 'reactstrap'; 
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../../utils/utils';
import * as yup from 'yup';
import YupValidator from '../../../../utils/YupValidator';
import swal from '@sweetalert/with-react';
import SweatAlertCustomIcon from '../../../../components/SweatAlert/SweatAlertCustomIcon';
import moment from 'moment';
import FieldButton from '../../../../components/Controls/FieldButton/FieldButton.jsx';
import { Panel } from 'primereact/panel';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import SurveyManagementNew from './SurveyManagementNew';
import SurveyManagementEdit from './SurveyManagementEdit'; 
import { Tree } from 'primereact/tree';
import { SplitButton } from 'primereact/splitbutton';
import SurveyInsights from '../SurveyAnalyze/SurveyInsights';
import FieldChips from 'components/Controls/FieldChips/FieldChips.jsx';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Toast } from 'primereact/toast';

const SurveyManagementCollectResponses = ({
    IdEncuesta,
    Show,
    onHide
}) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['survey', 'common']);
  const [expandedKeys, setExpandedKeys] = useState({});
  const [nodes, setNodes] = useState([]);
  const [EsCollect, setEsCollect] = useState(false);
  const [EsCollectTeam, setEsCollectTeam] = useState(false);
  const [Emails, setEmails] = useState([]);
  const [selectedUser, setselectedUser] = useState(null);
  const [CodigoUnicoEncuesta, setCodigoUnicoEncuesta] = useState('');
  const [shareURL, setShareURL] = useState('');
  
  const [selectedUserFinal, setselectedUserFinal] = useState([]);
  const [errors, setErrors] = useState({}); 
  const toast = useRef(null);
 
  useEffect(() => { 
    setEmails([]);
    setselectedUser([]);
    obtenerOrganizationalDataByUser();
    getHRSurveyManagementInfo(); 
  }, [Show]);

  const getHRSurveyManagementInfo = () => {
    const GetHRSurveyManagementInfo = `${config.UrlApiProject}HR/GetHRSurveyManagementInfo`;
    const paramsToService = {
      IdEncuesta,
    };
    callApi(GetHRSurveyManagementInfo, 'POST', paramsToService, (response) => {  
      setCodigoUnicoEncuesta(response.data.Encuesta[0].CodigoUnicoEncuesta);
      setShareURL(`${config.UrlProject}r/Survey/${response.data.Encuesta[0].CodigoUnicoEncuesta}`);
    });
  };

  const obtenerOrganizationalDataByUser = () => {
    const GetOrganizationalDataInfo = `${config.UrlApiProject}hr/GetOrganizationalDataInfo`;
    const paramsToService = {};

    callApi(GetOrganizationalDataInfo, 'GET', paramsToService, (response) => {
      expandAll(response.data);
      setNodes(response.data);
    });
  }; 
  

  const expandAll = (nodes) => {
    const _expandedKeys = {};
    for (const node of nodes) {
      expandNode(node, _expandedKeys);
      break;
    }

    setExpandedKeys(_expandedKeys);
  };

  const expandNode = (node, _expandedKeys) => {
    if (node.children && node.children.length) {
      _expandedKeys[node.key] = true;
    }
  };

  const onSendMailCollector = async () => { 
      
    setErrors({});
    const arrayMail = [];
    if (EsCollect) {
      for (let index = 0; index < Emails.length; index++) {
        const element = Emails[index];
        const correoFromArray = {
          Correo: element,
        };
        arrayMail.push(correoFromArray);
      }

      if (arrayMail.length === 0) {
        setErrors({Emails : {
          message: t('common:RequiredField'),
          type: "required"
        }}); 
        showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');  
        return;
      }
    }
    if (EsCollectTeam) {
      for (let index = 0; index < selectedUserFinal.length; index++) {
        const element = selectedUserFinal[index];
        const elementArrayChild = element[1];
        if (elementArrayChild.checked) {
          const correoFromArray = {
            ClaUsuario: element[0],
          };
          arrayMail.push(correoFromArray);
        }
      }

      if (arrayMail.length === 0) {
        setErrors({Users : {
          message: t('common:RequiredField'),
          type: "required"
        }}); 
        showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');  
        return;
      }
    }

    const sendMailManager = `${config.UrlApiProject}HR/SurveyCollectResponses`;

    const paramsToService = {
      IdEncuesta,
      TipoCollectResponses: EsCollect ? 0 : 1,
      SurveyInsightsDTOList: arrayMail,
    };
    callApi(sendMailManager, 'POST', paramsToService, (response) => {
      showSweetAlert(t('common:Success'), t('common:SuccessOperation'), 'success'); 
      onHide();
    });
  };

  const renderFooterCollectResponses = () => {
    return (
      <div>
        <Row>
          <Col lg={4} md={4} sm={12} xs={12}> 
             
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="text-center">
            <FieldButton
              label={t('survey:btnCollect')}
              icon="fa fa-paper-plane"
              className="light-green no-border p-mr-2"
              onClick={onSendMailCollector}
            />
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="text-right">
             
          </Col>
        </Row>
      </div>
    );
  }; 

  const onSendCollect = () => {
    setEsCollect(true);
    setEsCollectTeam(false);
  };
  const onSendCollectTeam = () => {
    setEsCollect(false);
    setEsCollectTeam(true);
  };

  const onChange = (node) => {
    const newErrors = { ...errors };
    delete newErrors.Users;
    setErrors(newErrors);

    const result = Object.keys(node).map((key) => {
      return [Number(key), node[key]];
    });
    setselectedUserFinal(result);
    setselectedUser(node);
  };

  

  const valitationSchemeSave = yup.object().shape({
    Emails:yup.string().required(t('common:RequiredField')).nullable() 
  });  

  
  const onEmailsChange = (value) => {
    const newErrors = { ...errors };
    delete newErrors.Emails;
    setErrors(newErrors);
    setEmails(value)
  }

  const onCopyClipBoard = () => {
    console.log('eee');
    toast.current.show({severity:'success', summary: '', detail:'Link copied to clipboard.', life: 3000});
  }


  return (
    <>
      <Dialog
        visible={Show}  
        modal 
        onHide={onHide}
        maximized={true}
        draggable={false}
        blockScroll={true}
        footer={renderFooterCollectResponses}
      > 
        <Toast ref={toast} />
        <Row className='pb-5'>
          <Col lg={12} md={12} sm={12} xs={12} className="text-center">
            <span className='CollectorTitle'>{t('survey:TitleMail')}</span>
          </Col>
        </Row>
        <Row className="align-items-start"> 
          <Col lg={4} md={4} sm={12} xs={12}>
            <Card className={`CollectorCard Cursor ${ EsCollect ? 'CollectorCardSelected' : '' }`} onClick={onSendCollect}> 
              <CardBody>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className='TitleCollectResponses'>
                      {t('survey:TitleType1')}
                    </div>
                    <div className='InstructionsCollectResponses'>
                      {t('survey:InstructionType1Desc')}
                    </div>
                    <br></br>
                    <div className='InstructionsCollectResponses'>
                      <b>{t('survey:InstructionType1Desc2')}:</b>&nbsp; {t('survey:InstructionType1Desc3')}
                    </div> 
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <Card className={`CollectorCard Cursor ${ EsCollectTeam ? 'CollectorCardSelected' : '' }`} onClick={onSendCollectTeam}> 
              <CardBody>
                <Row> 
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className='TitleCollectResponses'>
                      {t('survey:TitleType2')}
                    </div>
                    <div className='InstructionsCollectResponses'>
                      {t('survey:InstructionType2Desc')}
                    </div>
                  </Col>

                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <Card className="CollectorCard"> 
              <CardBody>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className='TitleCollectResponses'>
                      {t('survey:TitleType3')}
                    </div>
                    <div className='InstructionsCollectResponses'>
                      {t('survey:InstructionType3Desc')}
                    </div> 
                    
                    <CopyToClipboard text={shareURL} onCopy={() => onCopyClipBoard()}> 
                      <div className='text-center pt-5 Cursor'>  
                        <i className="fas fa-link fa-lg"></i>&nbsp;{shareURL} 
                      </div> 
                    </CopyToClipboard>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          {EsCollect ? (
            <>
              <Col lg={12} md={12} sm={12} xs={12}>
                <div className="p-fluid"> 
                  <FieldChips
                    name="Emails"
                    value={Emails}
                    onChange={(e) => onEmailsChange(e.value)}
                    separator=";"
                    className="survey-chips"
                    errors={errors}
                  />
                </div>
              </Col>
            </>
            ) : (
              <></>
            )}
        </Row>

        <Row>
          {EsCollectTeam ? (
            <>
              <Col lg={12} md={12} sm={12} xs={12}>
                <div className="user-name" title=""> 
                  <Tree
                    value={nodes}
                    selectionMode="checkbox"
                    selectionKeys={selectedUser}
                    onSelectionChange={(e) => onChange(e.value)}
                    expandedKeys={expandedKeys}
                    className={`${!!(typeof(errors) !== 'undefined' && errors !== null && errors.Users) === true ? 'p-invalid-tree' : ''}`}
                  /> 
                </div>
              </Col>
            </>
            ) : (
              <></>
            )}
        </Row> 
      </Dialog>
    </>
  );
};

export default SurveyManagementCollectResponses;

/* eslint-disable react/no-this-in-sfc */
/* eslint-disable object-shorthand */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  ListGroupItem,
  ListGroup,
  Progress,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from 'reactstrap';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { Badge } from 'primereact/badge';
import { Link } from 'react-router-dom';
import { Toolbar } from 'primereact/toolbar';
import Highcharts from 'highcharts';
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../../utils/utils';
import HighchartsReact from 'highcharts-react-official';
import FieldButton from '../../../../components/Controls/FieldButton/FieldButton.jsx';
import PropType from 'prop-types';
import FieldCalendar from 'components/Controls/FieldCalendar';
import { Inplace, InplaceContent, InplaceDisplay } from 'primereact/inplace';
import moment from 'moment';

const SurveyInsightsTrend = ({ IdEncuesta }) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['survey', 'common']);
  const [PosicionesRelacionadasList, setPosicionesRelacionadasList] = useState([]);
  const [ClaPeriodo, setClaPeriodo] = useState(null);
  const [ClaFechaReporte, setClaFechaReporte] = useState(null);
  const [IdCSF, setIdCSF] = useState(null);
  const [Titulo, setTitulo] = useState('');
  const [Subtitulo, setSubtitulo] = useState('');
  const [Serie1, setSerie1] = useState('');
  const [ColorSerie1, setColorSerie1] = useState('');
  const [SerieValues1, setSerieValues1] = useState([]);
  const [xAxisCategories, setxAxisCategories] = useState([]);
  const [xAxisName, setxAxisName] = useState('');
  const [trendReviewRange, settrendReviewRange] = useState([]);
  const [InplaceActiveCalendar, setInplaceActiveCalendar] = useState(false);
  const [fechaInicial, setFechaInicial] = useState(null);
  const [fechaFinal, setFechaFinal] = useState(null);

  useEffect(() => {
    GetSurveyInsightsTrend(IdEncuesta, null, null);
  }, [IdEncuesta]);

  const GetSurveyInsightsTrend = (pIdEncuesta, pFechaRango1, pFechaRango2) => {
    const GetSurveyInsightsTrend = `${config.UrlApiProject}hr/GetSurveyInsightsTrend`;
    const paramsToService = {
      IdEncuesta: pIdEncuesta,
      FechaRango1: pFechaRango1,
      FechaRango2: pFechaRango2,
    };

    callApi(GetSurveyInsightsTrend, 'POST', paramsToService, (response) => {
      setTitulo(response.data.ConfiguracionGrafica.Titulo);
      setSubtitulo(response.data.ConfiguracionGrafica.Subtitulo);
      setxAxisName(response.data.ConfiguracionGrafica.xAxisName);
      setSerie1(response.data.ConfiguracionGrafica.Serie1);
      setColorSerie1(response.data.ConfiguracionGrafica.ColorSerie1);
      setSerieValues1(response.data.Serie1);
      setxAxisCategories(response.data.xAxisCategories); 
      setFechaInicial(response.data.Fechas.FechaIni);
      setFechaFinal(response.data.Fechas.FechaFin);
    });
  };

  Highcharts.wrap(Highcharts.Series.prototype, 'drawPoints', function (p) {
    const { options } = this;
    const symbolCallback = options.marker && options.marker.symbolCallback;
    const { points } = this;
    if (symbolCallback && points.length) {
      points.forEach((point) => {
        const symbol = symbolCallback.call(point);
        if (symbol) {
          point.update(
            {
              marker: {
                fillColor: symbol,
              },
            },
            false
          );
        }
      });
    }
    p.call(this);
  });

  const kpiTrendChart = {
    title: {
      text: '',
    },

    subtitle: {
      text: Subtitulo,
    },
    xAxis: {
      title: {
        text: '',
      },
      categories: xAxisCategories,
    },
    yAxis: {
      title: {
        text: xAxisName,
      },
    },
    tooltip: {
      crosshairs: true,
      shared: true,
    },  
    series: [
      {
        type: 'column',
        name: Serie1,
        color: ColorSerie1,
        data: SerieValues1,
        zIndex: 1,
        marker: {
          fillColor: 'white',
          lineWidth: 2,
          lineColor: ColorSerie1,
          symbolCallback: function () {
            return 'white';
          },
        },
      },
    ],

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
            },
          },
        },
      ],
    },
  };

  const onTrendRangeChange = (value) => {
    settrendReviewRange(value);
    if (value[1] !== null) {
      GetSurveyInsightsTrend(IdEncuesta, value[0], value[1])
    }
  };

  const onFechaInicialChange = ( e ) => {  
    setFechaInicial(e.value);
    if(fechaFinal!==null && e.value!==null){ 
        setInplaceActiveCalendar(false);        
        GetSurveyInsightsTrend(IdEncuesta, e.value, fechaFinal);
    }
  }; 
  const onFechaFinalChange = ( e ) => {  
    setFechaFinal(e.value);
    if(fechaInicial!==null && e.value!==null){ 
        setInplaceActiveCalendar(false);     
        GetSurveyInsightsTrend(IdEncuesta, fechaInicial , e.value);   
    }
  }; 

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <span className='survey-chart-name'>{t('survey:ResponsesByWeek')}</span>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <div className="p-fluid">
                <Inplace
                  closable={true}
                  active={InplaceActiveCalendar}
                  onToggle={(e) => setInplaceActiveCalendar(e.value)}
                  className="p-fluid"
                >
                  <InplaceDisplay>
                    {
                       // <span className="fa fa-pencil-alt"></span>
                      } 
                    {t('survey:InformationFrom')}&nbsp;
                    <span className="pi pi-calendar"></span> &nbsp;
                    {moment(fechaInicial).format("ll")}-{moment(fechaFinal).format("ll")} 
                  </InplaceDisplay>
                  <InplaceContent>
                    <b>{t('scoreCard:InformationFrom')}</b> &nbsp;
                    <FieldCalendar
                      name="FechaInicial" 
                      value={(fechaInicial!== null ? (new Date(fechaInicial)): null)}
                      onChange={(e) => onFechaInicialChange(e)}
                    />
                          &nbsp;
                    <FieldCalendar
                      name="FechaFinal" 
                      value={(fechaFinal!== null ? (new Date(fechaFinal)): null)}
                      onChange={(e) => onFechaFinalChange(e)}
                    />
                  </InplaceContent>
                </Inplace> 
              </div>
            </Col>
            {/* <Col lg={4} md={6} sm={12} xs={12} className="text-right">
              
              <div className="p-fluid">
                <FieldCalendar
                  name="trendReviewRange"
                  value={trendReviewRange}
                  onChange={onTrendRangeChange}
                  selectionMode="range"
                />
              </div>
            </Col> */}
          </Row>
          <HighchartsReact
            containerProps={{ style: { height: '420px' } }}
            highcharts={Highcharts}
            options={kpiTrendChart}
          />
        </CardBody>
        {/* <CardFooter hidden={!props.showCloseButton}>
          <Row>
            <Col className='text-center'>
              <FieldButton
                label={t('common:Close')}
                icon="fas fa-times"
                className="warning no-border p-mr-2 rounded-button"
                onClick={typeof(props.onCloseClick) !== 'undefined' && typeof(props.onCloseClick) === 'function' ? props.onCloseClick : null}
              />
            </Col>
          </Row>
        </CardFooter> */}
      </Card>
    </>
  );
};

SurveyInsightsTrend.prototype = {
  IdEncuesta: PropType.number.isRequired,
};

export default SurveyInsightsTrend;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  ListGroupItem,
  ListGroup,
  Progress,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from 'reactstrap';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { Badge } from 'primereact/badge';
import { Link } from 'react-router-dom';
import ActionPlanScoreSquare from './ActionPlanScoreSquare.jsx';
import { Toolbar } from 'primereact/toolbar';
import Highcharts from 'highcharts';
import { config } from '../../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../../utils/utils';
import FieldButton from 'components/Controls/FieldButton/FieldButton.jsx';

const FocusReportAnnualSummary = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);

  const [ClaPuesto, setClaPuesto] = useState(null); 
  const [ClaAnio, setClaAnio] = useState(null);
    
  const [AnnualResult, setAnnualResult] = useState(null);
  const [AnnualScoreCard, setAnnualScoreCard] = useState(null);
  const [AnnualActionPlan, setAnnualActionPlan] = useState(null);
  const [AnnualProjectActivities, setAnnualProjectActivities] = useState(null);

  useEffect(() => {
    setClaPuesto(props.ClaPuesto === undefined ? 0 : props.ClaPuesto);
    setClaAnio(props.ClaAnio === undefined ? 0 : props.ClaAnio);
    GetResultadoAnual();  
  }, [props.ClaPuesto, props.ClaAnio]);

  const GetResultadoAnual = () => {
    const getResultadoAnual = `${config.UrlApiProject}ScoreCard/GetResultadoAnualPuesto`;
    const paramsToService = {
      ClaPuesto: props.ClaPuesto === undefined ? 0 : props.ClaPuesto,
      ClaAnio: props.ClaAnio === undefined ? 0 : props.ClaAnio,
    };
 
    callApi(getResultadoAnual, 'POST', paramsToService, (response) => {
      setAnnualResult(response.data.ResultadoAnual[0].Score);
      setAnnualScoreCard(response.data.ResultadoAnual[0].ScoreCard);
      setAnnualActionPlan(response.data.ResultadoAnual[0].ActionPlans);
      setAnnualProjectActivities(response.data.ResultadoAnual[0].ProjectActivities); 
    });
  };

  const getSeverityClass = (value) => {
    let severityClass = ''; 
  
    let severity = '';
  
    const nPorcMinimo        =	70.0;
    const	nPorcSatisfactorio =	91.0;
    const	nPorcSobreSaliente =	101.0; 
    /*
     0   - 70    --NO CUMPLE
     71  - 90   --MINIMO
     91  - 100  --SATISFACTORIO
     101 - 120 --SOBRESALIENTE
    */ 
    
    if (value < nPorcMinimo)
    {
        severity = "red";
    }
    else if (value >= nPorcMinimo && value < nPorcSatisfactorio){
      severity = "yellow";
    } else if (value >= nPorcSatisfactorio && value < nPorcSobreSaliente) { 
      severity = "green"
    } else if (value >= nPorcSobreSaliente) {
      severity = "green";
    } else {
      severity = "";
    }    
  
    switch (severity) {
        case "blue":
            severityClass = "score-square-blue-gradient";
            break;
        case "green":
            severityClass = "score-square-green-gradient";
            break;
        case "yellow":
            severityClass = "score-square-yellow-gradient";
            break;
        case "red":
            severityClass = "score-square-red-gradient";
            break;        
        default:
            severityClass = "score-square-default-gradient";
            break;
    }
    
    return severityClass;
  }

  return (
    <>
      <Card>
        <CardBody>
          <Row> 
            <Col lg={6} md={6} sm={12} xs={12} className="score-square-annualinfo text-center">
              <span className="h1"> {t('focusReport:Annual')} {t('focusReport:Result')}</span>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12} className="text-center">
              <FieldButton 
                className={`${getSeverityClass(AnnualResult)} no-border score-square-annual font-weight-bold score-cursor text-center`}
              >
                <span className="h1 w-100"><b>{Highcharts.numberFormat(AnnualResult,2)}</b></span>
              </FieldButton> 
            </Col>  
          </Row>
          <Row className="align-items-start ">
            <Col lg={12} md={12} sm={12} xs={12}>              
              <div style={{paddingRight:'18px', float:'left'}}>
                <h3 className="score-square-annualinfo-scorecard">{t('focusReport:AnnualScoreCard')}</h3>
              </div> 
              <div className="text-right">
                <h3><b>{Highcharts.numberFormat(AnnualScoreCard,2)}</b></h3>
              </div>              
            </Col>
          </Row>
          <Row className="align-items-start ">
            <Col lg={12} md={12} sm={12} xs={12}>              
              <div style={{paddingRight:'18px', float:'left'}}>
                <h3 className="score-square-annualinfo-actionplan">{t('focusReport:AnnualActionPlans')}</h3>
              </div> 
              <div className="text-right">
                <h3><b>{Highcharts.numberFormat(AnnualActionPlan,2)}</b></h3>
              </div>              
            </Col>
          </Row>
          <Row className="align-items-start ">
            <Col lg={12} md={12} sm={12} xs={12}>              
              <div style={{paddingRight:'18px', float:'left'}}>
                <h3 className="score-square-annualinfo-activities">{t('focusReport:AnnualProjectActivities')}</h3>
              </div> 
              <div className="text-right">
                <h3><b>{Highcharts.numberFormat(AnnualProjectActivities,2)}</b></h3>
              </div>              
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default FocusReportAnnualSummary;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from 'components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { Avatar } from 'primereact/avatar';
import { config } from '../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../utils/utils';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable.jsx';
import { ProgressBar } from 'primereact/progressbar';
import SweatAlertCustomIcon from 'components/SweatAlert/SweatAlertCustomIcon';
import FieldText from 'components/Controls/FieldText';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx';
import FieldDropdown from 'components/Controls/FieldDropdown.jsx';
import * as yup from 'yup';
import YupValidator from 'utils/YupValidator';
import moment from 'moment';
import swal from '@sweetalert/with-react';
import PageContent from 'layouts/PageContent';
import { Toolbar } from 'primereact/toolbar';
import FieldTextArea from 'components/Controls/FieldTextArea';
import FieldAutoComplete from 'components/Controls/FieldAutoComplete.jsx';
import FieldTime from 'components/Controls/FieldTime';
import { cssNumber } from 'jquery';
import FieldCheckbox from 'components/Controls/FieldCheckbox.jsx';
import FieldSelectButton from 'components/Controls/FieldSelectButton';
import { Dialog } from 'primereact/dialog';
import SurveyManagementContestar from '../Survey/SurveyManagement/SurveyManagementContestar';
import InstructionsCard from '../../../components/Cards/InstructionsCard/InstructionsCard.jsx';


const CultureSurveyGivingFeedback = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['survey', 'common']);
  const [IdPerformanceDev, setIdPerformanceDev] = useState(null);
  const [CultureFeedBackChoosedList, setCultureFeedBackChoosedList] = useState([]);
  const [CultureFeedBackRequestedList, setCultureFeedBackRequestedList] = useState([]);
  const [EsIniciarEncuesta, setEsIniciarEncuesta] = useState(false);
  const [IdEncuesta, setIdEncuesta] = useState(null);
  const [IdEncuestaContestada, setIdEncuestaContestada] = useState(null);
  const [CultureSurveyFeedbackSetUp, setCultureSurveyFeedbackSetUp] = useState({});

  useEffect(() => {
    loadTeamPerformanceData();
  }, [props.Anio]);

  const loadTeamPerformanceData = () => {
    const GetGivingCultureFeedback = `${config.UrlApiProject}hr/GetGivingCultureFeedback?ClaAnio=${props.Anio}`;
     

    callApi(GetGivingCultureFeedback, 'GET', {}, (response) => {
      const cultureSurveyFeedbackSetUp = response.data.CultureSurveyFeedbackSetUp.length > 0 ? response.data.CultureSurveyFeedbackSetUp[0] : {}
      setCultureSurveyFeedbackSetUp(cultureSurveyFeedbackSetUp);
      setIdPerformanceDev(cultureSurveyFeedbackSetUp.IdPerformanceDev !== undefined ? cultureSurveyFeedbackSetUp.IdPerformanceDev : null);
      setCultureFeedBackChoosedList(response.data.GivingFeedbackChoosed);
      setCultureFeedBackRequestedList(response.data.GivingFeedbackRequested);
    });
  };
  const showActiveRows = (rows) => {
    const activeRows = [];
    if (rows !== undefined) {
      if (rows.length > 0) {
        rows.forEach((item, index) => {
          if (!item.BajaLogica || item.BajaLogica === 0 || item.BajaLogica === undefined) {
            item.BajaLogica = 0;
            item.RowIndex = index;
            item.RowIndexCount = index + 1;
            activeRows.push({ ...item });
          }
        });
      } else {
        return [];
      }
    }

    return activeRows;
  };

  const FechaIniciaEncuestaTemplate = (row) => {
    return (
      <>
        {row.FechaIniciaEncuesta !== null
          ? moment(row.FechaIniciaEncuesta).format('MMMM DD, YYYY h:mm:ss a')
          : row.FechaIniciaEncuesta}
      </>
    );
  };

  const FechaCompletadaTemplate = (row) => {
    return (
      <>
        {row.FechaCompletada !== null
          ? moment(row.FechaCompletada).format('MMMM DD, YYYY h:mm:ss a')
          : row.FechaCompletada}
      </>
    );
  };  

  const SurveyTemplate = (row) => {    
    return (
      <>
        <FieldButton
          hidden={row.EsCompletado === 1}
          icon="fas fa-poll"
          className="p-button-rounded p-button-success btn-min-width no-border"
          label={t('survey:InitContestarEncuesta')}
          onClick={(e) => onClickStarSurvey(row)}
        />
      </>
    );
  };

  const dialogStyle = {
    width: '80vw',
  };

  const onClickStarSurvey = (row) =>
  { 
    setIdEncuestaContestada(row.IdEncuestaContestada);
    setIdEncuesta(row.IdEncuesta);
    setEsIniciarEncuesta(true);
  }
  
  const onCloseSurvey = () => {
    setEsIniciarEncuesta(false);
  };

  const closeModalEsIniciarEncuesta = () => {
    setEsIniciarEncuesta(false);
  };

  const onEncuestaTerminada = () => {
    setEsIniciarEncuesta(false);
    loadTeamPerformanceData();
  };


  const NomUsuarioTemplate = (props) => {
    
    return (
      <>
        <span className="default-color">
          {props.NomUsuario} <b>{props.EsSolicitoEvaluacion === 1 ? "(Requesting your feedback)" : ""}</b>
        </span> 
      </>
    );
  };

  const renderHeader = () => {
    return (
      <div>
        <Row>
          <Col lg={4} md={4} sm={12} xs={12}>
            <span>{ IdEncuesta === null ? t('focusReport:NewMeeting') :  t('focusReport:EditMeeting') }</span>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="text-center">
            &nbsp;
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="text-right">
            <div className="w-100 relative">
              <i className='fas fa-times fa-1x Cursor' title={t('common:Close')} onClick={onCloseSurvey}></i> 
            </div>
          </Col>
        </Row> 
      </div>
    );
  };

  if(!CultureSurveyFeedbackSetUp.Activa180DegreeReview){
    return (
      <>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="text-center p-7">  
            <h2>{t('hr:Culture180DegreeReviewPeriodClosed')}</h2> 
          </Col>
        </Row>
      </> 
    );
  } 

  if(!CultureSurveyFeedbackSetUp.ActivaDarFeedbackCultureSurvey){
    return (
      <>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="text-center p-7">  
            <h2>{t('hr:ActivaDarFeedbackCultureSurveyGivingClosed')}</h2> 
          </Col>
        </Row>
      </> 
    );
  } 

  return (
    <>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12} className="pb-2">   
          <InstructionsCard>
            <p>
              {t('survey:CultureSurveyGiveInstructions')}.
            </p>
          </InstructionsCard>
        </Col>
      </Row>
      <Row className="align-items-start">
        <Col lg={6} md={6} sm={12} xs={12}>
          <Card>
            <CardBody>
              <Row> 
                <Col lg={12} md={12} sm={12} xs={12}>
                  <Row className="pt-3">
                    <Col>
                      <span className="bold">
                        <h2> {t('survey:GivingFeedbackTitleQuestion1')} </h2>{' '}
                      </span>
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col>
                      <span className="bold">{t('survey:GivingFeedbackSubTitleQuestion1')}</span>
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col>
                      <div className='p-datatable table-header-background-ligth-green'>
                        <table className='p-datatable-scrollable-header-table'>
                          <thead className='p-datatable-thead'>
                            <tr> 
                              <th style={{width: '60%'}}>
                                {t('survey:ParticipantTobeEvaluated')}
                              </th> 
                              <th style={{width: '20%'}}>
                                &nbsp;
                              </th>
                              <th style={{width: '10%'}}>
                                &nbsp;
                              </th>
                            </tr>
                          </thead>
                          <tbody className='p-datatable-tbody'>
                            {showActiveRows(CultureFeedBackChoosedList).map((element, index) => {  
                          return (  
                            <tr key={index}> 
                              <td>
                                <div className="p-fluid">
                                  {element.NomUsuario}
                                  <div className='d-block d-lg-none d-md-none'>
                                    <FieldButton
                                      hidden={element.EsCompletado === 1}
                                      icon="fas fa-poll"
                                      className="p-button-rounded p-button-success btn-min-width no-border"
                                      label={t('survey:InitContestarEncuesta')}
                                      onClick={(e) => onClickStarSurvey(element)}
                                    />
                                  </div>
                                </div>
                              </td> 
                              <td>
                                <div className='d-none d-lg-block d-md-block'>
                                  <FieldButton
                                    hidden={element.EsCompletado === 1}
                                    icon="fas fa-poll"
                                    className="p-button-rounded p-button-success btn-min-width no-border"
                                    label={t('survey:InitContestarEncuesta')}
                                    onClick={(e) => onClickStarSurvey(element)}
                                  />
                                </div>
                              </td>
                              <td>
                                &nbsp;
                              </td> 
                            </tr>  
                          );
                      })}   
                          
                          </tbody>
                        </table>
                      </div>
                      {
                        /*
                     
                      <FieldDataTable
                        value={showActiveRows(TeamPerformanceInfoList)}
                        scrollable
                        scrollHeight="330px"
                        className="editable-cells-table"
                        editMode="cell"
                        selectionMode="single"
                        header={null}
                        paginator={false} 
                      >
                        <Column field="RowIndexCount" headerStyle={{ width: '15px' }}></Column>
                        <Column
                          field="NomUsuario"
                          header={t('survey:Participant')}
                          headerStyle={{ width: '280px' }}
                          columnKey="ClaUsuario"
                          editor={(props) => TeamPerformanceEditor(props)}
                        >
                        </Column>
                      </FieldDataTable>
                       */
                     }
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col> 
        <Col lg={6} md={6} sm={12} xs={12}>
          <Card>
            <CardBody>
              <Row> 
                <Col lg={12} md={12} sm={12} xs={12}>
                  <Row className="pt-3">
                    <Col>
                      <span className="bold">
                        <h2> {t('survey:GivingFeedbackTitleQuestion2')} </h2>{' '}
                      </span>
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col>
                      <span className="bold">{t('survey:GivingFeedbackSubTitleQuestion2')}</span>
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col>
                      <div className='p-datatable table-header-background-blue'>
                        <table className='p-datatable-scrollable-header-table'>
                          <thead className='p-datatable-thead'>
                            <tr> 
                              <th style={{width: '60%'}}>
                                {t('survey:ParticipantRequestingFeedback')}
                              </th> 
                              <th style={{width: '20%'}}>
                                &nbsp;
                              </th>
                              <th style={{width: '10%'}}>
                                &nbsp;
                              </th>
                            </tr>
                          </thead>
                          <tbody className='p-datatable-tbody'>
                            {showActiveRows(CultureFeedBackRequestedList).map((element, index) => {  
                          return (  
                            <tr key={index}> 
                              <td>
                                <div className="p-fluid">
                                  {element.NomUsuario}
                                </div>
                              </td> 
                              <td>
                                <FieldButton
                                  hidden={element.EsCompletado === 1}
                                  icon="fas fa-poll"
                                  className="p-button-rounded p-button-success btn-min-width no-border"
                                  label={t('survey:InitContestarEncuesta')}
                                  onClick={(e) => onClickStarSurvey(element)}
                                />
                              </td>
                              <td>
                                &nbsp;
                              </td>
                            </tr>  
                          );
                      })}   
                          
                          </tbody>
                        </table>
                      </div>
                      {
                        /*
                      <FieldDataTable
                        value={showActiveRows(TeamPerformanceManagerInfoList)}
                        scrollable
                        className="editable-cells-table"
                        editMode=""
                        selectionMode="single"
                        header={null}
                        paginator={false} 
                      >
                        <Column field="RowIndexCount" headerStyle={{ width: '9px' }}></Column>
                        <Column
                          field="NomUsuario"
                          header={t('survey:Participant')}
                          headerStyle={{ width: '280px' }}
                          columnKey="ClaUsuario"                          
                          editor={(props) => TeamPerformanceManagerEditor(props)}
                        >
                        </Column>
                      </FieldDataTable>
                        */
                      }
                      
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row> 
      <Row>
        <Col>
          {
            EsIniciarEncuesta ?
            ( <SurveyManagementContestar IdEncuesta={IdEncuesta} onClose={closeModalEsIniciarEncuesta} onFinishSubmit={onEncuestaTerminada} showAsModal={true} isOpen={EsIniciarEncuesta} IdEncuestaContestada={IdEncuestaContestada}></SurveyManagementContestar>
            ):
            (<></>)
          }
        </Col>
      </Row> 
    </>
  );
};
export default CultureSurveyGivingFeedback;

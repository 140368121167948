import React, { useState, useRef, useEffect  } from 'react'; 
import { useTranslation } from 'react-i18next';   
import { config } from '../../../utils/config';
import { callApi, showSweetAlert} from '../../../utils/utils';   
import PageContent from 'layouts/PageContent';    
import { Row,Col,Card, CardHeader, CardTitle, CardBody, CardFooter} from 'reactstrap';    
import './ProductionDailyFollowUPScreen.scss';   
import Highcharts from 'highcharts';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator'; 
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton'; 
import moment from "moment"; 
import { Dialog } from 'primereact/dialog';
import DailyOperationAssetDetail from './DailyOperationTracker/DailyOperationAssetDetail';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import DailyOperationTrackerTask from './DailyOperationTracker/DailyOperationTrackerTask';

const ProductionWeeklyFollowUPTracker = (props) => { 
  const { t } = useTranslation(['wtw', 'common']);
  
  const [errors, setErrors] = useState({});    
  const [ProduccionSeguimientoSemanalList, setProduccionSeguimientoSemanalList] = useState({Tasks:[],CommentsDetail:[]}); 
  const [EsCargandoListado, setEsCargandoListado] = useState(false); 
  const [EsVerDetalleArea, setEsVerDetalleArea] = useState(false);  
  const [EsAgregarTarea, setEsAgregarTarea] = useState(false);  
  const [Fecha, setFecha] = useState(); 
  
  useEffect(() => {
    obtenerProduccionSeguimientoSemanal();
  }, [])


  const obtenerProduccionSeguimientoSemanal = () => { 
    const GetProduccionSeguimientoSemanalSeguimiento = `${config.UrlApiProject}WTW/GetProduccionSeguimientoSemanalSeguimiento`;  
    const paramsService = {
      ClaUbicacion:props.ClaUbicacion,
      ClaArea:props.ClaArea,
      FechaInicio:props.FechaInicio,
      FechaFin:props.FechaFin
    }; 
    setEsCargandoListado(true);
    callApi(GetProduccionSeguimientoSemanalSeguimiento, 'POST', paramsService, (response) => { 
      setEsCargandoListado(false); 
      setProduccionSeguimientoSemanalList(response.data.OperacionSeguimientoList);
    });
  }

  const NomActividadColumna = (row) => {  
    return (
      <> 
        <div className='overflow-ellipsis' title={row.NomActividad}>
          {row.NomActividad}
        </div> 
      </>
  );
  }
  
  const AssignedToColumna = (row) => {  
    return (
      <> 
        <div className='overflow-ellipsis' title={row.AssignedTo}>
          {row.AssignedTo}
        </div>  
      </>
  );
  }
  
  const CreatedByColumna = (row) => {  
    return (
      <> 
        <div className='overflow-ellipsis' title={row.CreatedBy}>
          {row.CreatedBy}
        </div>   
      </>
  );
  }
  
  const DateTemplate = (item, columnName) => {
    const dateValue = item[columnName];
    return (
      <> 
        <span>
          {/* moment(item[columnName]).format('MMMM DD, YYYY h:mm:ss a') */}
          <span>
            { dateValue ? moment(dateValue).format('MMMM DD, YYYY') : '' }
          </span>
        </span>
      </>
      );
  };  
 
const skeletonTemplate = () =>{
  return (<Skeleton height="1rem" width="100%" className="mb-2"></Skeleton>);
}

const openDialogDepartament = (item) => {
  setFecha(item.FechaProduccion);
  setEsVerDetalleArea(true); 
}

const headerDialogDetalleMaquinas = () => {
  return (
    <>
      {props.NomArea} - {t('wtw:ProductionDate')} : {moment(Fecha).format('MMMM Do, YYYY') } - {t('wtw:DetailByAsset')} 
    </>
  )
}

const footerDialogDetalleMaquinas = () => {
  return (
    <>
      <FieldButton 
        label={t('common:Close ')}
        icon="pi pi-times"
        onClick={() => setEsVerDetalleArea(false)}
        className="p-button p-button-warning"
      /> 
    </>
  )
}

const onAddTask = async () => {   
  setEsAgregarTarea(true);
}; 


const onCloseDialogTask = () => { 
  setEsAgregarTarea(false);
}

const onAfterSaveTask = () => { 
  setEsAgregarTarea(false);
  obtenerProduccionSeguimientoSemanal();
}
 
const ListadoDepartamentos = () => {
  if (EsCargandoListado){
    return (
      <> 
        <FieldDataTable 
          value={[{},{},{},{}]}
          paginator={false} 
        >     
          <Column
            body={skeletonTemplate}
            header={t('formulario:Departament')} 
            columnKey=""
            headerStyle={{ width: '150px' }}
          >
          </Column>    
          <Column header={t('wtw:Units')} className='text-right' headerStyle={{ width: '100px' }} body={skeletonTemplate}></Column>
          <Column header={t('wtw:Tons')} className='text-right' headerStyle={{ width: '100px' }} body={skeletonTemplate}></Column>
          <Column header={t('wtw:Scrap')} className='text-right' headerStyle={{ width: '100px' }} body={skeletonTemplate}></Column>
          <Column header={t('wtw:Utilizacion')} className='text-right' headerStyle={{ width: '80px' }} body={skeletonTemplate}></Column> 
          <Column header={t('wtw:Disponibilidad')} className='text-right' headerStyle={{ width: '80px' }} body={skeletonTemplate}></Column>
          <Column header={t('wtw:Efficiencia')} className='text-right' headerStyle={{ width: '80px' }} body={skeletonTemplate}></Column>
          <Column header={t('wtw:OEE')} className='text-right' headerStyle={{ width: '80px' }} body={skeletonTemplate}></Column>
          <Column
            body={skeletonTemplate}
            header={t('formulario:Main')} 
            columnKey=""
            headerStyle={{ width: '150px' }}
          >
          </Column> 

        </FieldDataTable>
        <br></br>
      </>  
    )
  }

  return (
    <div>
      {
        ProduccionSeguimientoSemanalList!==null ? (
          <>  
            <Row>
              <Col>
                <h2>{`${t('wtw:CommentsHistory')}`}</h2>
              </Col>
              <Col>
                <Row>
                  <Col> 
                  </Col>
                  <Col className="text-right"> 
                  </Col>
                </Row>  
              </Col>
            </Row>
            <Row>
              <Col>
                <h2 className='text-gray'> 
                  {t('wtw:Tasks')}
                </h2> 
              </Col>
              <Col className="text-right  pb-2"> 
                <FieldButton
                  className="p-button warning no-border" 
                  onClick={() => onAddTask()}
                >
                  <i className="pi pi-plus"></i>&nbsp;{t('wtw:AddNewTask')}
                </FieldButton>
              </Col>
            </Row>
            {
              ProduccionSeguimientoSemanalList.Tasks.length >0
                &&
                ( 
                <Row className='pb-2'>
                  <Col>
                    <FieldDataTable 
                      value={ProduccionSeguimientoSemanalList.Tasks}    
                      paginator={false}
                    > 
                      <Column
                        body={NomActividadColumna}
                        header={t('wtw:Task')} 
                        columnKey="NomActividad"
                        headerStyle={{ width: '200px' }}
                        editMode="row"
                      >
                      </Column>  
                      <Column header={t('wtw:CreatedBy')} className='text-left' headerStyle={{ width: '150px' }} body={(rowData)=> CreatedByColumna (rowData)}></Column>
                      <Column header={t('wtw:CreationDate')} className='text-left' headerStyle={{ width: '120px' }} body={(rowData)=> DateTemplate (rowData, 'CreationDate')}></Column> 
                      <Column header={t('wtw:AssignedTo')} className='text-left' headerStyle={{ width: '150px' }} body={(rowData)=> AssignedToColumna (rowData)}></Column>
                      <Column header={t('wtw:DueDate')} className='text-left' headerStyle={{ width: '120px' }} body={(rowData)=> DateTemplate (rowData, 'DueDate')}></Column> 
                    </FieldDataTable>
                  </Col> 
                </Row> 
              )
            }
            {
              ProduccionSeguimientoSemanalList.CommentsDetail.length >0
                &&
                (
                  <div>
                    <h2 className='text-gray pb-3'> {t('wtw:CommentsDetail')}</h2>
                    {ProduccionSeguimientoSemanalList.CommentsDetail.map((item, index) => { 
                            return ( 
                              <div key={index} className='pb-3'> 
                                <Row> 
                                  <Col lg={12} md={12} sm={12} xs={12} onClick={() =>openDialogDepartament(item)}>
                                    <h3 className='link-text'>
                                      {moment(item.FechaProduccion).format('MMMM DD, YYYY')}
                                    </h3> 
                                  </Col> 
                                </Row> 
                                <Row className='pb-2' hidden={item.MainCause===""}>
                                  <Col lg={12} md={12} sm={12} xs={12}>
                                    <span className='bold'>{t('wtw:MainCause')}:</span>  <span className='label'>{`${item.MainCause}`}</span> 
                                  </Col>
                                </Row>
                                {
                                  item.ComentariosPorAreaFuncional.length >0
                                    &&
                                    (
                                      <div>
                                        <Row className='pb-2'>
                                          <Col>
                                            <FieldDataTable 
                                              value={item.ComentariosPorAreaFuncional}    
                                              paginator={false}
                                            > 
                                              <Column field="AreaFuncional" header={t('wtw:FunctionalArea')} headerStyle={{ width: '150px' }} columnKey="AreaFuncional"></Column> 
                                              <Column field="ComentarioProblema" header={t('wtw:ExplanationIssues')} headerStyle={{ width: '350px' }} columnKey="ComentarioProblema"></Column> 
                                              <Column field="PosibleSolucionProblema" header={t('wtw:ExplanationIssuesAddressed')} headerStyle={{ width: '350px' }} columnKey="PosibleSolucionProblema"></Column> 
                                            </FieldDataTable>
                                          </Col> 
                                        </Row>
                                      </div>
                                  )
                                }
                             
                              </div>
                          );
                        })}     
                  </div>
              )
            }
          </>
        ):(
          <>
            <>Sin datos</>
          </>
        )
      }
      <Dialog
        visible={EsVerDetalleArea}         
        modal
        style={{ width: '80vw' }}
        className='dialog-header-background-blue'
        maximized={false}
        draggable={false}
        blockScroll={true}
        onHide={()=> { setEsVerDetalleArea(false)}}
        header={headerDialogDetalleMaquinas}
        footer={footerDialogDetalleMaquinas}
      >  
        <Row className='align-items-start'>
          <Col lg={12} md={12} sm={12} xs={12}>  
            <DailyOperationAssetDetail
              ClaUbicacion={props.ClaUbicacion}
              ClaArea={props.ClaArea}
              Fecha={Fecha}
            >
            </DailyOperationAssetDetail>
          </Col> 
        </Row> 
      </Dialog>
      {EsAgregarTarea ? (
        <> 
          <DailyOperationTrackerTask
            visible={EsAgregarTarea}
            IdOperacionSeguimientoDiarioTarea={0}
            ClaUbicacion={props.ClaUbicacion}
            Fecha={props.FechaInicio}
            onAfterReturn={onCloseDialogTask}
            onAferSaveTask={onAfterSaveTask}
          > 
          </DailyOperationTrackerTask>
        </>
          ) : (
            <></>
          )}  
    </div>
  
  )
}

  return (
    <>   
      <Row className='ProductionDailyFollowUPScreen align-items-start pb-3'>
        <Col lg={12} md={12} sm={12} xs={12}>  
          {ListadoDepartamentos()} 
        </Col> 
      </Row> 
    </>
  );
};

export default ProductionWeeklyFollowUPTracker;

import React from "react";
import { Wizard, WizardStep, useWizardStep } from "react-wizard-primitive";
import { Button, Row, Col } from 'reactstrap';

const WizardLayout = (props) => {
    const stepCount = React.Children.count(props.children);
  
    

    return (
      <Wizard onChange={({newStepIndex, previousStepIndex}) => { 
      }}
      > 
        {
        

        ({ activeStepIndex, previousStep, nextStep }
            , onBeforeNextStep = (  ) => {   
                 
                // si traemos defindos los props mandamos verificar con el controlador padre si puede continuar al siguiente paso o no....
                if (props.children[activeStepIndex].props !== undefined)
                {
                  if (props.children[activeStepIndex].props.items === undefined && props.children[activeStepIndex].props.onBeforeNextStep === undefined)
                  {
                    nextStep();  
                  }

                  if (props.children[activeStepIndex].props.items !== undefined){
                         
                        if (props.children[activeStepIndex].props.onBeforeNextStep !== undefined && props.children[activeStepIndex].props.onBeforeNextStep(props.children[activeStepIndex].props.items))
                        {
                            nextStep();
                        }
                    }
                  else if (props.children[activeStepIndex].props.onBeforeNextStep(null)){
                        nextStep();  
                  }
                }
                else
                { 
                    nextStep();
                }
            }
            ) => (  
              
              <>
                <div className="wizard-wrapper">
                  <h2>
                    Step {activeStepIndex + 1} of {stepCount}
                  </h2>
                  <Row>
                    <Col md={6} xs={6} className="text-left"> 
                      {activeStepIndex > 0 && (
                      <Button
                        color="secondary"
                        disabled={activeStepIndex === 0}
                        onClick={previousStep}
                        className="btn-circle btn-lg"
                      >
                        <i className="fas fa-chevron-left" />
                      </Button>
              ) }
                    </Col> 
                    <Col md={6} xs={6} className="text-right">  
                      {activeStepIndex !== stepCount - 1 && (

                      <Button
                        color="primary"
                        disabled={activeStepIndex === stepCount - 1}
                        onClick={onBeforeNextStep}
                        className="btn-circle btn-lg"
                      >    
                        <i className="fas fa-chevron-right" />
                      </Button>
                      ) }
                    </Col>
                  </Row>
                  <div>{props.children} 
                  </div>
                  { props.children[activeStepIndex].props.showNavigation === 'both' && (
                    <Row>
                      <Col md={6} xs={6} className="text-left"> 
                        {activeStepIndex > 0 && (
                        <Button
                          color="secondary"
                          disabled={activeStepIndex === 0}
                          onClick={previousStep}
                          className="btn-circle btn-lg"
                        >
                          <i className="fas fa-chevron-left" />
                        </Button>
                        ) }
                      </Col> 
                      <Col md={6} xs={6} className="text-right">  
                        {activeStepIndex !== stepCount - 1 && (
                      
                    
                        <Button
                          color="primary"
                          disabled={activeStepIndex === stepCount - 1}
                          onClick={onBeforeNextStep}
                          className="btn-circle btn-lg"
                        >    
                          <i className="fas fa-chevron-right" />
                        </Button>
                        ) }
                      </Col>
                    </Row>

                  ) }
                </div>
              </>
        )
} 
      </Wizard>
    ); 

    
  };

  WizardLayout.Step = props => ( 
     
    <WizardStep> 
      {
        ({ isActive }) => isActive && (
        <div className="step"> 
            {props.children} 
        </div>
                            )
            } 
    </WizardStep>  
  ); 
   
  export default WizardLayout; 
  
/* eslint-disable object-shorthand */
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { config } from 'utils/config';
import { callApi } from 'utils/utils';
import CompanyOverviewProductionRateChartSkeleton from './CompanyOverviewProductionRateChartSkeleton';

const CompanyOverviewProductionRateChart = (props) => {
  const {t, i18n} = useTranslation(['companyOverview', 'common']);   
  const [Loading, setLoading] = useState(true);
  const [ChartCategories, setChartCategories] = useState([]);
  const [ChartSeries, setChartSeries] = useState([]);
  const [TotalBoxesHr, setTotalBoxesHr] = useState(0);
  const [TotalDifference, setTotalDifference] = useState(0);
  const [ChartStep, setChartStep] = useState(0);
  const chartOptions = {
    chart: {
      type: 'column',
      height: 112
    },  
    title: {
      text: ''      
    },  
    xAxis: {
      categories: ChartCategories
    },
    yAxis: {
      min: 0,
      tickInterval: 100,  
      title: {
        text: ''
      }
    }, 
    tooltip: {
      formatter: function () {
        const { point, series } = this; 
        return `<b>${series.name}</b>: ${Highcharts.numberFormat(point.y, 0, '.', ',')}`;
      },
    },    
    legend: {
      enabled: false,
    },
    series: [{
      name: t('companyOverview:Boxes'),
      data: ChartSeries,
      color: 'grey'  
    }]
  };

  const getReport = (
    ReportType,
    Shifts,
    StartDate,
    EndDate
  ) => {
    const url = `${config.UrlApiProject}Operations/GetCompanyOverviewProductionRate`;       
    const paramsService = {
      ReportType,
      Shifts,
      StartDate: StartDate.toDateString(),
      EndDate: EndDate.toDateString()
    };
    setLoading(true);
    callApi(url, 'POST', paramsService, (response) => {            
      setTotalBoxesHr(response.data.CompanyOverviewProductionRateSummary.TotalBoxesHr);
      setTotalDifference(response.data.CompanyOverviewProductionRateSummary.TotalDifference);
      setupChart(response.data.CompanyOverviewProductionRate, response.data.CompanyOverviewProductionRateSummary.ChartStep);      
      setLoading(false);
    }, (error) => {
      setLoading(false);
      console.error("Error getting company overview production rate.", error);    
    }, false, false);
  };

  const setupChart = (report, step) => {
    let categories = [];
    let series = [];    
    if (report) {      
      categories = report.map(({ DateName }) => DateName);          
      series = report.map(({ ProductionRate }) => ProductionRate);          
    }        
    setChartCategories(categories);
    setChartSeries(series);    
    setChartStep(step);  
  };

  useEffect(() => {      
    if (props.reportType && props.shifts && props.startDate && props.endDate) {
      getReport(props.reportType, props.shifts, props.startDate, props.endDate);   
    }    
  }, [
    props.refresh
  ]);

  const summaryTemplate = (
    <>
      <Row>
        <Col xs={4}>
          <h5>
            {t('companyOverview:ProductionRate')}
          </h5>              
        </Col>
        <Col 
          xs={5}
          className='text-center'
        >
          {/* <h1>
            {`${TotalBoxesHr} %`}&nbsp;
            <small>
              {t('companyOverview:BoxesHr')}
            </small>
          </h1>  */}
        </Col>        
        <Col xs={3}>
          {/* <span className={`p-badge ${TotalDifference < 0? 'danger': 'success'}`}> 
            {`${TotalDifference < 0? TotalDifference*-1: TotalDifference} %`}&nbsp;
            <i className={`fas fa-arrow-${TotalDifference < 0? 'down': 'up'}`}></i>
          </span> */}
        </Col>
      </Row>    
    </>
  );

  const chartTemplate = (
    <>     
      <HighchartsReact
        highcharts={Highcharts} 
        options={chartOptions} 
      />        
    </>
  );

  return (
    <>
      {
        Loading? <CompanyOverviewProductionRateChartSkeleton /> : (
          <Card style={{borderRadius: '1%'}}>
            <CardBody>
              <Row className='align-items-start'>
                <Col> 
                  {summaryTemplate}
                </Col>            
              </Row>
              <Row className='align-items-start'>            
                <Col>
                  {chartTemplate}                     
                </Col>
              </Row>
            </CardBody>
          </Card>   
        )
      } 
    </>
  )
}

export default CompanyOverviewProductionRateChart
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row, Col, Card, CardBody, CardFooter } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi, showSweetAlert } from '../../../utils/utils';
import FieldNumber from '../../../components/Controls/FieldNumber.jsx';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import FieldDropdown from 'components/Controls/FieldDropdown.jsx';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import { ColumnGroup } from 'primereact/columngroup';
import Highcharts from 'highcharts';

const IncentiveProgramManualAdjustmentsRep = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['incentiveProgramManualAdjustments', 'common']); 

  const [errors, setErrors] = useState({});
  const [IncentiveGoals, setIncentiveGoals] = useState([]);  
  const [OpcionesAgente, setOpcionesAgente] = useState([]); 
  const [IsReadOnly, setIsReadOnly] = useState(false);
  
  useEffect(() => {     
    obtenerAgentes();  
    setIsReadOnly(typeof(props.IsReadOnly) === 'undefined' || props.IsReadOnly === null ? false : props.IsReadOnly);
  }, [props.IsReadOnly]);

  useEffect(() => { 
    obtieneIncentiveSalesRepInfo(); 
    setIsReadOnly(typeof(props.IsReadOnly) === 'undefined' || props.IsReadOnly === null ? false : props.IsReadOnly);
  }, [props.ClaAnio, props.IsReadOnly]); 

  const obtieneIncentiveSalesRepInfo = () => {
    const GetIncentiveProgramManualAdjustmentsRep = `${config.UrlApiProject}SalesPerformanceSales/GetIncentiveProgramManualAdjustmentsRep?ClaAnio=${props.ClaAnio === null || props.ClaAnio === undefined ? '' : props.ClaAnio}`;; 
    callApi(GetIncentiveProgramManualAdjustmentsRep, 'GET', {}, (response) => {        
        setIncentiveGoals (response.data.IncentiveProgram); 
    });  
  }

  const obtenerAgentes = () => {
    const getAgenteCmb = `${config.UrlApiProject}SalesPerformanceSales/GetAgenteCmb`;    
    callApi(getAgenteCmb, 'GET', {}, (response) => {
      setOpcionesAgente(response.data.Agente);       
    });
  };

  const headerScoreCardDetailGroup  = ( 
    <ColumnGroup>
      <Row>   
        {/* 
        <Column header="" className="text-center table-header-background-dark-green" colSpan={3}></Column>  
        */
        }     
        <Column header={t('incentiveProgramManualAdjustments:January')} className="text-center" colSpan={3}></Column> 
        <Column header={t('incentiveProgramManualAdjustments:February')} className="text-center table-header-background-dark-green" colSpan={3}></Column>       
        <Column header={t('incentiveProgramManualAdjustments:March')} className="text-center" colSpan={3}></Column>       
        <Column header={t('incentiveProgramManualAdjustments:April')} className="text-center table-header-background-dark-green" colSpan={3}></Column>       
        <Column header={t('incentiveProgramManualAdjustments:May')} className="text-center" colSpan={3}></Column>       
        <Column header={t('incentiveProgramManualAdjustments:June')} className="text-center table-header-background-dark-green" colSpan={3}></Column>       
        <Column header={t('incentiveProgramManualAdjustments:July')} className="text-center" colSpan={3}></Column>       
        <Column header={t('incentiveProgramManualAdjustments:August')} className="text-center table-header-background-dark-green" colSpan={3}></Column>       
        <Column header={t('incentiveProgramManualAdjustments:September')} className="text-center" colSpan={3}></Column>       
        <Column header={t('incentiveProgramManualAdjustments:Octuber')} className="text-center table-header-background-dark-green" colSpan={3}></Column>       
        <Column header={t('incentiveProgramManualAdjustments:November')} className="text-center" colSpan={3}></Column>       
        <Column header={t('incentiveProgramManualAdjustments:December')} className="text-center table-header-background-dark-green" colSpan={3}></Column>       
        
      </Row>
      <Row> 
        {
        /*
        <Column header="Division" className="text-center table-header-background-dark-green"></Column>    
        <Column header="Statistical Group 3" className="text-center table-header-background-dark-green"></Column>  
        <Column header="Sgement" className="text-center table-header-background-dark-green"></Column>
         */
        }
        <Column header={t('incentiveProgramManualAdjustments:TonsGoal')} className="text-center"></Column> 
        <Column header={t('incentiveProgramManualAdjustments:StrategicTonsGoal')} className="text-center"></Column> 
        <Column header={t('incentiveProgramManualAdjustments:SpreadGoal')} className="text-center"></Column>  

        
        <Column header={t('incentiveProgramManualAdjustments:TonsGoal')} className="text-center table-header-background-dark-green"></Column> 
        <Column header={t('incentiveProgramManualAdjustments:StrategicTonsGoal')} className="text-center table-header-background-dark-green"></Column> 
        <Column header={t('incentiveProgramManualAdjustments:SpreadGoal')} className="text-center table-header-background-dark-green"></Column>  
      
        
        <Column header={t('incentiveProgramManualAdjustments:TonsGoal')} className="text-center"></Column> 
        <Column header={t('incentiveProgramManualAdjustments:StrategicTonsGoal')} className="text-center"></Column> 
        <Column header={t('incentiveProgramManualAdjustments:SpreadGoal')} className="text-center"></Column>  

        <Column header={t('incentiveProgramManualAdjustments:TonsGoal')} className="text-center table-header-background-dark-green"></Column> 
        <Column header={t('incentiveProgramManualAdjustments:StrategicTonsGoal')} className="text-center table-header-background-dark-green"></Column> 
        <Column header={t('incentiveProgramManualAdjustments:SpreadGoal')} className="text-center table-header-background-dark-green"></Column>  

        <Column header={t('incentiveProgramManualAdjustments:TonsGoal')} className="text-center"></Column> 
        <Column header={t('incentiveProgramManualAdjustments:StrategicTonsGoal')} className="text-center"></Column> 
        <Column header={t('incentiveProgramManualAdjustments:SpreadGoal')} className="text-center"></Column>  

        <Column header={t('incentiveProgramManualAdjustments:TonsGoal')} className="text-center table-header-background-dark-green"></Column> 
        <Column header={t('incentiveProgramManualAdjustments:StrategicTonsGoal')} className="text-center table-header-background-dark-green"></Column> 
        <Column header={t('incentiveProgramManualAdjustments:SpreadGoal')} className="text-center table-header-background-dark-green"></Column>  

        <Column header={t('incentiveProgramManualAdjustments:TonsGoal')} className="text-center"></Column> 
        <Column header={t('incentiveProgramManualAdjustments:StrategicTonsGoal')} className="text-center"></Column> 
        <Column header={t('incentiveProgramManualAdjustments:SpreadGoal')} className="text-center"></Column>  

        <Column header={t('incentiveProgramManualAdjustments:TonsGoal')} className="text-center table-header-background-dark-green"></Column> 
        <Column header={t('incentiveProgramManualAdjustments:StrategicTonsGoal')} className="text-center table-header-background-dark-green"></Column> 
        <Column header={t('incentiveProgramManualAdjustments:SpreadGoal')} className="text-center table-header-background-dark-green"></Column>  

        <Column header={t('incentiveProgramManualAdjustments:TonsGoal')} className="text-center"></Column> 
        <Column header={t('incentiveProgramManualAdjustments:StrategicTonsGoal')} className="text-center"></Column> 
        <Column header={t('incentiveProgramManualAdjustments:SpreadGoal')} className="text-center"></Column>  

        <Column header={t('incentiveProgramManualAdjustments:TonsGoal')} className="text-center table-header-background-dark-green"></Column> 
        <Column header={t('incentiveProgramManualAdjustments:StrategicTonsGoal')} className="text-center table-header-background-dark-green"></Column> 
        <Column header={t('incentiveProgramManualAdjustments:SpreadGoal')} className="text-center table-header-background-dark-green"></Column>  

        <Column header={t('incentiveProgramManualAdjustments:TonsGoal')} className="text-center"></Column> 
        <Column header={t('incentiveProgramManualAdjustments:StrategicTonsGoal')} className="text-center"></Column> 
        <Column header={t('incentiveProgramManualAdjustments:SpreadGoal')} className="text-center"></Column>  

        <Column header={t('incentiveProgramManualAdjustments:TonsGoal')} className="text-center table-header-background-dark-green"></Column> 
        <Column header={t('incentiveProgramManualAdjustments:StrategicTonsGoal')} className="text-center table-header-background-dark-green"></Column> 
        <Column header={t('incentiveProgramManualAdjustments:SpreadGoal')} className="text-center table-header-background-dark-green"></Column>  

      </Row>
    </ColumnGroup>
  )

  const frozenHeaderColumnGroup  = (  
    <ColumnGroup>
      <Row> 
        <Column header="" rowSpan={2} headerStyle={{ height:'52px'}} className="table-header-background-dark-green"></Column> 
        <Column header="" rowSpan={2} headerStyle={{ height:'52px'}} className="table-header-background-dark-green"></Column> 
        <Column header={t('incentiveProgramManualAdjustments:SalesRep')} rowSpan={2} headerStyle={{ height:'52px'}} className="table-header-background-dark-green"></Column>  
        
      </Row>
    </ColumnGroup> 
  ) 

  const BotonesTemplate = (rowData, props) =>  {   
    if (IsReadOnly === true || !rowData.IsNew){
      return (<></>);
    }

    return (
      <>
        <FieldButton
          icon="pi pi-trash"  
          className="p-button-rounded p-button-warning"
          onClick={() => onConfirmBajaIncentiveGoal(rowData,props)}
        />
      </>
    );
  }  

  const onConfirmBajaIncentiveGoal = (row, props) => {        
    const incentiveGoalsNew = [...IncentiveGoals];
    if (incentiveGoalsNew[props.rowIndex].IsNew === true) {
      incentiveGoalsNew.splice(props.rowIndex, 1);
    } 
    setIncentiveGoals(incentiveGoalsNew);    
  }
 
  const onNewIncentiveProgramGoal = () => { 
    /* vamos a setear algunos valores por default??? */
    const newRow = { 
        IsNew: true,
        ClaAnio: props.ClaAnio,
        ClaAgente: null,        
        NomAgente: null,
        DecMTonAdj: null,
        DecSpreadAdj: null,
        DecMtStratAdj: null,
        CommentAdjDec: null,
        JanMTonAdj: null,
        JanSpreadAdj: null,
        JanMtStratAdj: null,
        CommentAdjJan: null,
        FebMTonAdj: null,
        FebSpreadAdj: null,
        FebMtStratAdj: null,
        CommentAdjFeb: null,
        MarMTonAdj: null,
        MarSpreadAdj: null,
        MarMtStratAdj: null,
        CommentAdjMar: null,
        AprMTonAdj: null,
        AprSpreadAdj: null,
        AprMtStratAdj: null,
        CommentAdjApr: null,
        MayMTonAdj: null,
        MaySpreadAdj: null,
        MayMtStratAdj: null,
        CommentAdjMay: null,
        JunMTonAdj: null,
        JunSpreadAdj: null,
        JunMtStratAdj: null,
        CommentAdjJun: null,
        JulMTonAdj: null,
        JulSpreadAdj: null,
        JulMtStratAdj: null,
        CommentAdjJul: null,
        AugMTonAdj: null,
        AugSpreadAdj: null,
        AugMtStratAdj: null,
        CommentAdjAug: null,
        SepMTonAdj: null,
        SepSpreadAdj: null,
        SepMtStratAdj: null,
        CommentAdjSep: null,
        OctMTonAdj: null,
        OctSpreadAdj: null,
        OctMtStratAdj: null,
        CommentAdjOct: null,
        NovMTonAdj: null,
        NovSpreadAdj: null,
        NovMtStratAdj: null,
        CommentAdjNov: null
    };    
    setIncentiveGoals([...IncentiveGoals, newRow]);
  }

  const onClaAgenteChange = (props, e ) => {
    const alreadyAdded = IncentiveGoals.filter(item => item.ClaAgente ===e.value)[0];    
    if (alreadyAdded) {
      showSweetAlert(t('common:Warning'), t('incentiveProgramManualAdjustments:SalesRepAlreadyAdded'), 'warning'); 
      return;
    } 
    // TODO: Validate agente    
    const updateRow = [...IncentiveGoals];
    const agente = OpcionesAgente.filter(item => item.ClaAgente ===e.value)[0];    
    updateRow[props.rowIndex].NomAgente = null;
    if(agente!==undefined){
        updateRow[props.rowIndex].NomAgente = agente.NomAgente;
    }
    updateRow[props.rowIndex].ClaAgente = e.value;
    updateRow[props.rowIndex].IsRowEdited = true; 
    setIncentiveGoals(updateRow);
  };

  const ClaAgenteEditor = ( props ) => {
    if (IsReadOnly === true || !props.rowData.IsNew) { 
      return NomAgenteTemplate(props.rowData);
    }

    return (
      <div className="p-fluid">
        <FieldDropdown  
          name="ClaAgente"
          value={props.rowData.ClaAgente}
          options={OpcionesAgente}  
          optionValue="ClaAgente"
          optionLabel="NomAgente" 
          onChange={(e) => onClaAgenteChange(props,e)}
          errors={errors[`IncentiveGoals[${props.rowIndex}]`]}   
        />  
      </div>
    )
  };

  const NomAgenteTemplate = (props) => {
    return (
      <> 
        <div className='overflow-ellipsis' title={props.NomAgente}>{props.NomAgente}</div> 
      </>
      );
  };

  const onNumberChange = (props, value) => {    
    const updatedRow = [...IncentiveGoals];
    updatedRow[props.rowIndex][props.field] = value;
    updatedRow[props.rowIndex].IsRowEdited = true; 
    setIncentiveGoals(updatedRow);
  };

  const NumberEditor = (columnName, props) => {
    if (IsReadOnly === true){ 
      return NumberTemplate(columnName, props.rowData);
    }

    return (
      <div className="p-fluid">
        <FieldNumber
          name={columnName}
          value={props.rowData[columnName]}
          onChange={(e) => onNumberChange(props, e.target.value)}
          min={-99999999.99}
          max={99999999.99}
          minFractionDigits={2}
          maxFractionDigits={2}
        />
      </div>
    );
  }; 

  const NumberTemplate = (columnName, props) => { 
    return <div className='text-right'>{props[columnName] !== null && props[columnName] !== undefined ?  Highcharts.numberFormat(props[columnName], 2, '.', ',') : null}</div>;
  };  

  const fieldRequiredFlag = (rowData, props) =>  {  
    return (
      <>
        {errors[`IncentiveGoals[${props.rowIndex}]`] != null ? (<i className="fas fa-exclamation-triangle invalid-color"></i>) : ""}
      </>
    );
  }

  const valitationScheme = yup.object().shape({ 
    IncentiveGoals: yup.array().of(
      yup.object().shape({
      
        ClaAgente: yup.number().required(t('common:RequiredField')).nullable()

        // ClaAgente: yup.string().when('BajaLogica', {is: 0,
        //   then: yup.string().trim().required(t('common:RequiredField')).nullable(),
        //   otherwise: yup.string().nullable()
        // }),

        // ClaDivision: yup.string().when('BajaLogica', {is: 0,
        //   then: yup.string().trim().required(t('common:RequiredField')).nullable(),
        //   otherwise: yup.string().nullable()
        // }),
        
        // ClaGrupoEstadistico3: yup.string().when('BajaLogica', {is: 0,
        //     then: yup.string().trim().required(t('common:RequiredField')).nullable(),
        //     otherwise: yup.string().nullable()
        // }) 
    })) 
  });

  const onSaveClick = async () => {
    setErrors({}); 

    const value = {    
        IncentiveGoals  
    };

    const validator = new YupValidator(valitationScheme);
    
    const esValido = await validator.validate(value);
    if (!esValido){ 
        setErrors(validator.errors); 
        showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning'); 
        return;
    }
    
    const incentiveGoalsModified = [];    
    
    for (let index = 0; index < IncentiveGoals.length; index++) {
        if (IncentiveGoals[index].IsRowEdited){     
          incentiveGoalsModified.push(IncentiveGoals[index]);           
        }
    }
        
    /* mandamos la informacion de los renglones que se modificaron para guardar la informacion */
    const paramsService = {
      IncentiveProgramManualAdjustmentList: incentiveGoalsModified,
    };
  
    const SaveIncentiveProgramManualAdjustmentsRep = `${config.UrlApiProject}SalesPerformanceSales/SaveIncentiveProgramManualAdjustmentsRep`;
  
    callApi(SaveIncentiveProgramManualAdjustmentsRep, 'POST', paramsService, (response) => {
        showSweetAlert('success', t('common:SuccessOperation'), 'success');
        // mandamos refrescar la informacion del grid....
        obtieneIncentiveSalesRepInfo(); 
    });
  }
 
  return (
    <>
      <Row>
        <Col>            
          <Card>
            <CardBody>                 
              <Row className='pb-2'>                
                <Col className="text-right"> 
                  {
                    IsReadOnly === true ?
                    (<></>)
                    :
                    (
                      <>
                        <FieldButton label={t('common:New')} icon="pi pi-plus" className="secondary no-border p-mr-2" onClick={onNewIncentiveProgramGoal} /> 
                        <FieldButton label={t('common:Save')} icon="fas fa-save" className="light-green no-border p-mr-2" onClick={onSaveClick} />                 
                      </>
                    )
                  }
                </Col>
              </Row>  
              <Row className="align-items-start ">
                <Col lg={12} md={12} sm={12} xs={12}>
                  <FieldDataTable
                    value={IncentiveGoals}
                    scrollable  
                    paginator={false}
                    className="table-header-background-ligth-green frozen-editable"  
                    headerColumnGroup={headerScoreCardDetailGroup}
                    frozenHeaderColumnGroup={frozenHeaderColumnGroup}
                    frozenWidth="400px"   
                    scrollHeight="63vh"
                  > 
                    <Column body={(rowData, props) => fieldRequiredFlag(rowData, props)} headerStyle={{ width: '10px' }} frozen={true}></Column>
                    <Column body={(rowData, props) => BotonesTemplate(rowData, props)} style={{ textAlign: 'center' }} headerStyle={{ width: '20px' }} frozen={true}></Column>  
                    <Column
                      field="NomAgente" 
                      columnKey="NomAgente" 
                      headerStyle={{ width: '100px' }}   
                      frozen={true}
                      editor={(props) => ClaAgenteEditor(props)}
                      body={NomAgenteTemplate} 
                      className="table-header-background-dark-green"
                    >
                    </Column>
                    <Column
                      field="JanMTonAdj" 
                      columnKey="JanMTonAdj"
                      headerStyle={{ width: '100px' }}   
                      body={(rowData, props) => NumberTemplate("JanMTonAdj", rowData)}
                      editor={(props) => NumberEditor("JanMTonAdj", props)}
                    >
                    </Column>
                    <Column
                      field="JanMtStratAdj" 
                      columnKey="JanMtStratAdj"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("JanMtStratAdj", rowData)}
                      editor={(props) => NumberEditor("JanMtStratAdj", props)}
                    >
                    </Column>
                    <Column
                      field="JanSpreadAdj" 
                      columnKey="JanSpreadAdj"
                      headerStyle={{ width: '100px' }}   
                      className="table-header-background-dark-green border-right text-center"
                      body={(rowData, props) => NumberTemplate("JanSpreadAdj", rowData)}
                      editor={(props) => NumberEditor("JanSpreadAdj", props)}
                    >
                    </Column>
                    <Column
                      field="FebMTonAdj" 
                      columnKey="FebMTonAdj"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("FebMTonAdj", rowData)}
                      editor={(props) => NumberEditor("FebMTonAdj", props)}
                    >
                    </Column>
                    <Column
                      field="FebMtStratAdj" 
                      columnKey="FebMtStratAdj"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("FebMtStratAdj", rowData)}
                      editor={(props) => NumberEditor("FebMtStratAdj", props)}
                    >
                    </Column>
                    <Column
                      field="FebSpreadAdj" 
                      columnKey="FebSpreadAdj"
                      headerStyle={{ width: '100px' }}   
                      className="table-header-background-dark-green border-right text-center"
                      body={(rowData, props) => NumberTemplate("FebSpreadAdj", rowData)}
                      editor={(props) => NumberEditor("FebSpreadAdj", props)}
                    >
                    </Column>
                    <Column
                      field="MarMTonAdj" 
                      columnKey="MarMTonAdj"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("MarMTonAdj", rowData)}
                      editor={(props) => NumberEditor("MarMTonAdj", props)}
                    >
                    </Column>
                    <Column
                      field="MarMtStratAdj" 
                      columnKey="MarMtStratAdj"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("MarMtStratAdj", rowData)}
                      editor={(props) => NumberEditor("MarMtStratAdj", props)}
                    >
                    </Column>
                    <Column
                      field="MarSpreadAdj" 
                      columnKey="MarSpreadAdj"
                      headerStyle={{ width: '100px' }}   
                      className="table-header-background-dark-green border-right text-center"
                      body={(rowData, props) => NumberTemplate("MarSpreadAdj", rowData)}
                      editor={(props) => NumberEditor("MarSpreadAdj", props)}
                    >
                    </Column>
                    <Column
                      field="AprMTonAdj" 
                      columnKey="AprMTonAdj"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("AprMTonAdj", rowData)}
                      editor={(props) => NumberEditor("AprMTonAdj", props)}
                    >
                    </Column>
                    <Column
                      field="AprMtStratAdj" 
                      columnKey="AprMtStratAdj"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("AprMtStratAdj", rowData)}
                      editor={(props) => NumberEditor("AprMtStratAdj", props)}
                    >
                    </Column>
                    <Column
                      field="AprSpreadAdj" 
                      columnKey="AprSpreadAdj"
                      headerStyle={{ width: '100px' }}   
                      className="table-header-background-dark-green border-right text-center"
                      body={(rowData, props) => NumberTemplate("AprSpreadAdj", rowData)}
                      editor={(props) => NumberEditor("AprSpreadAdj", props)}
                    >
                    </Column>
                    <Column
                      field="MayMTonAdj" 
                      columnKey="MayMTonAdj"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("MayMTonAdj", rowData)}
                      editor={(props) => NumberEditor("MayMTonAdj", props)}
                    >
                    </Column>
                    <Column
                      field="MayMtStratAdj" 
                      columnKey="MayMtStratAdj"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("MayMtStratAdj", rowData)}
                      editor={(props) => NumberEditor("MayMtStratAdj", props)}
                    >
                    </Column>
                    <Column
                      field="MaySpreadAdj" 
                      columnKey="MaySpreadAdj"
                      headerStyle={{ width: '100px' }}   
                      className="table-header-background-dark-green border-right text-center"
                      body={(rowData, props) => NumberTemplate("MaySpreadAdj", rowData)}
                      editor={(props) => NumberEditor("MaySpreadAdj", props)}
                    >
                    </Column>
                    <Column
                      field="JunMTonAdj" 
                      columnKey="JunMTonAdj"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("JunMTonAdj", rowData)}
                      editor={(props) => NumberEditor("JunMTonAdj", props)}
                    >
                    </Column>
                    <Column
                      field="JunMtStratAdj" 
                      columnKey="JunMtStratAdj"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("JunMtStratAdj", rowData)}
                      editor={(props) => NumberEditor("JunMtStratAdj", props)}
                    >
                    </Column>
                    <Column
                      field="JunSpreadAdj" 
                      columnKey="JunSpreadAdj"
                      headerStyle={{ width: '100px' }}   
                      className="table-header-background-dark-green border-right text-center"
                      body={(rowData, props) => NumberTemplate("JunSpreadAdj", rowData)}
                      editor={(props) => NumberEditor("JunSpreadAdj", props)}
                    >
                    </Column>
                    <Column
                      field="JulMTonAdj" 
                      columnKey="JulMTonAdj"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("JulMTonAdj", rowData)}
                      editor={(props) => NumberEditor("JulMTonAdj", props)}
                    >
                    </Column>
                    <Column
                      field="JulMtStratAdj" 
                      columnKey="JulMtStratAdj"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("JulMtStratAdj", rowData)}
                      editor={(props) => NumberEditor("JulMtStratAdj", props)}
                    >
                    </Column>
                    <Column
                      field="JulSpreadAdj" 
                      columnKey="JulSpreadAdj"
                      headerStyle={{ width: '100px' }}   
                      className="table-header-background-dark-green border-right text-center"
                      body={(rowData, props) => NumberTemplate("JulSpreadAdj", rowData)}
                      editor={(props) => NumberEditor("JulSpreadAdj", props)}
                    >
                    </Column>
                    <Column
                      field="AugMTonAdj" 
                      columnKey="AugMTonAdj"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("AugMTonAdj", rowData)}
                      editor={(props) => NumberEditor("AugMTonAdj", props)}
                    >
                    </Column>
                    <Column
                      field="AugMtStratAdj" 
                      columnKey="AugMtStratAdj"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("AugMtStratAdj", rowData)}
                      editor={(props) => NumberEditor("AugMtStratAdj", props)}
                    >
                    </Column>
                    <Column
                      field="AugSpreadAdj" 
                      columnKey="AugSpreadAdj"
                      headerStyle={{ width: '100px' }}   
                      className="table-header-background-dark-green border-right text-center"
                      body={(rowData, props) => NumberTemplate("AugSpreadAdj", rowData)}
                      editor={(props) => NumberEditor("AugSpreadAdj", props)}
                    >
                    </Column>
                    <Column
                      field="SepMTonAdj" 
                      columnKey="SepMTonAdj"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("SepMTonAdj", rowData)}
                      editor={(props) => NumberEditor("SepMTonAdj", props)}
                    >
                    </Column>
                    <Column
                      field="SepMtStratAdj" 
                      columnKey="SepMtStratAdj"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("SepMtStratAdj", rowData)}
                      editor={(props) => NumberEditor("SepMtStratAdj", props)}
                    >
                    </Column>
                    <Column
                      field="SepSpreadAdj" 
                      columnKey="SepSpreadAdj"
                      headerStyle={{ width: '100px' }}   
                      className="table-header-background-dark-green border-right text-center"
                      body={(rowData, props) => NumberTemplate("SepSpreadAdj", rowData)}
                      editor={(props) => NumberEditor("SepSpreadAdj", props)}
                    >
                    </Column>
                    <Column
                      field="OctMTonAdj" 
                      columnKey="OctMTonAdj"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("OctMTonAdj", rowData)}
                      editor={(props) => NumberEditor("OctMTonAdj", props)}
                    >
                    </Column>
                    <Column
                      field="OctMtStratAdj" 
                      columnKey="OctMtStratAdj"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("OctMtStratAdj", rowData)}
                      editor={(props) => NumberEditor("OctMtStratAdj", props)}
                    >
                    </Column>
                    <Column
                      field="OctSpreadAdj" 
                      columnKey="OctSpreadAdj"
                      headerStyle={{ width: '100px' }}   
                      className="table-header-background-dark-green border-right text-center"
                      body={(rowData, props) => NumberTemplate("OctSpreadAdj", rowData)}
                      editor={(props) => NumberEditor("OctSpreadAdj", props)}
                    >
                    </Column>
                    <Column
                      field="NovMTonAdj" 
                      columnKey="NovMTonAdj"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("NovMTonAdj", rowData)}
                      editor={(props) => NumberEditor("NovMTonAdj", props)}
                    >
                    </Column>
                    <Column
                      field="NovMtStratAdj" 
                      columnKey="NovMtStratAdj"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("NovMtStratAdj", rowData)}
                      editor={(props) => NumberEditor("NovMtStratAdj", props)}
                    >
                    </Column>
                    <Column
                      field="NovSpreadAdj" 
                      columnKey="NovSpreadAdj"
                      headerStyle={{ width: '100px' }}   
                      className="table-header-background-dark-green border-right text-center"
                      body={(rowData, props) => NumberTemplate("NovSpreadAdj", rowData)}
                      editor={(props) => NumberEditor("NovSpreadAdj", props)}
                    >
                    </Column>
                    <Column
                      field="DecMTonAdj" 
                      columnKey="DecMTonAdj"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("DecMTonAdj", rowData)}
                      editor={(props) => NumberEditor("DecMTonAdj", props)}
                    >
                    </Column>
                    <Column
                      field="DecMtStratAdj" 
                      columnKey="DecMtStratAdj"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("DecMtStratAdj", rowData)}
                      editor={(props) => NumberEditor("DecMtStratAdj", props)}
                    >
                    </Column>
                    <Column
                      field="DecSpreadAdj" 
                      columnKey="DecSpreadAdj"
                      headerStyle={{ width: '100px' }}
                      body={(rowData, props) => NumberTemplate("DecSpreadAdj", rowData)}
                      editor={(props) => NumberEditor("DecSpreadAdj", props)}
                    >
                    </Column>
                  </FieldDataTable> 
                </Col>
              </Row> 
            </CardBody>
            {
              typeof(props.showCloseButton) !== 'undefined'
              ?
              (
                <CardFooter hidden={!props.showCloseButton}>
                  <Row>
                    <Col className='text-center'>
                      <FieldButton
                        label={t('common:Close')}
                        icon="fas fa-times"
                        className="warning no-border p-mr-2 rounded-button"
                        onClick={typeof(props.onCloseClick) !== 'undefined' && typeof(props.onCloseClick) === 'function' ? props.onCloseClick : null}
                      />
                    </Col>
                  </Row>
                </CardFooter>
              )
              :
              (<></>)
            }            
          </Card>
        </Col>
      </Row>  
    </>
  );
};

export default IncentiveProgramManualAdjustmentsRep;

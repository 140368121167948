/* eslint-disable radix */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PageContent from '../../../layouts/PageContent';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Panel } from 'primereact/panel';
import queryString from 'query-string';
import FileLibraryFileByUserFilter from './FileLibraryFileByUserFilter';
import FileLibraryFileByUserList from './FileLibraryFileByUserList';

const FileLibraryByUserScreen = (props) => {
  
  const params = queryString.parse(props.location.search);
  /* ################## useState area start ################## */
  const [EsShowDialog, setEsShowDialog] = useState(false);
  const [ClaLibrary, setClaLibrary] = useState(params.ClaLibrary ? parseInt(params.ClaLibrary) : 0);
  const [IdLibraryFile, setIdLibraryFile] = useState(null);
  const [refresh, setrefresh] = useState(0);
  const [filtersData, setfiltersData] = useState({});
  const { t } = useTranslation(['dms', 'common']); 
  
  useEffect(() => {}, []);

  const onAfterSelect = (data) => {
    setClaLibrary(data.ClaLibrary);
    setIdLibraryFile(data.IdLibraryFile);
    setEsShowDialog(true);
    setrefresh(refresh + 1);
    console.log(data);
    // setfiltersData({
    //   ClaLibrary: data.ClaLibrary,
    //   IdLibraryFile: data.IdLibraryFile,
    // });
  };

  const onAfterReturn = () => {
    setrefresh(refresh + 1);
  };

  const onSearch = (filters) => {
    setfiltersData({ ...filters });
    setrefresh(refresh + 1);
  };
  return (
    <>
      <PageContent title={t('dms:FilesRepository')}>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <FileLibraryFileByUserFilter
              onSearch={onSearch}
              onAfterSelect={onAfterSelect}
              IdLibraryFile={IdLibraryFile}
              EsShowDialog={EsShowDialog}
              refresh={refresh}
              ClaLibrary={ClaLibrary}
            >
            </FileLibraryFileByUserFilter>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            &nbsp;
          </Col>
        </Row>  
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <FileLibraryFileByUserList
              onAfterSelect={onAfterSelect}
              refresh={refresh}
              filtersData={filtersData}
              EsEditar={false}
            >
            </FileLibraryFileByUserList>
          </Col> 
        </Row>        
      </PageContent>
    </>
  );
};

export default FileLibraryByUserScreen;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';
import PageContent from 'layouts/PageContent'; 
import ScoreCardReport from './ScoreCardReport';
import FocusReportMonthSummary from './FocusReportMonthSummary';
import ActionPlanMonthSummary from './ActionPlanMonthSummary';
import ProjectActivitiesMonthSummary from './ProjectActivitiesMonthSummary';
import ScoreCardAnnualDetail from './ScoreCardAnnualDetail';
import FocusReportAnnualSummary from './FocusReportAnnualSummary';
import ProjectActivitiesAnnualSummary from './ProjectActivitiesAnnualSummary';
import ActionPlanAnnualSummary from './ActionPlanAnnualSummary';
import { Panel } from 'primereact/panel';
import FieldButton from '../../../../components/Controls/FieldButton/FieldButton.jsx';
import FieldDropdown from '../../../../components/Controls/FieldDropdown.jsx'; 
import { config } from '../../../../utils/config';
import { callApi, tracker } from '../../../../utils/utils';  
import OrganizationalPositionView from "../OrganizationalPositionView.jsx";
import ActionPlantMonthKPIsPrev from './ActionPlantMonthKPIsPrev';


const FocusReportScreen = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['misProyectosScreen', 'common', 'focusReport']);  
  const [ProyectosScreenData, setProyectosScreenData] = useState({});
  const [filtersData, setfiltersData] = useState({});
  const [OpcionesAnios, setOpcionesAnios] = useState([]);
  const [OpcionesMeses, setOpcionesMeses] = useState([]);
  const [ClaAnio, setClaAnio] = useState(null);
  const [ClaMes, setClaMes] = useState(null); 
  const [UltimoClaMesCerrado, setUltimoClaMesCerrado] = useState(null); 
  const [PrimerClaMes, setPrimerClaMes] = useState(null); 
  const [ClaPuesto, setClaPuesto] = useState(null);
  const [EsAdmin, setEsAdmin] = useState(null);

  
    
  useEffect(() => { 
    const paramsService = {}; 
    
    const GetCurrentPeriodScoreCard = `${config.UrlApiProject}ScoreCard/GetScorecardUserDefaultInfo`; 
        
    callApi(GetCurrentPeriodScoreCard, 'GET', paramsService, (response) => { 
        getYears(response.data.ClaAnio);
        getMeses(response.data.ClaAnio); 
        setClaPuesto(response.data.ClaPuesto);
        setClaAnio(response.data.ClaAnio); 
        setClaMes(response.data.ClaMes); 
        setEsAdmin(response.data.EsAdmin); 
    });   
}, []);
 
 const getYears = (ClaAnio) => {
    const paramsService = {
      MostrarSoloCerrados : 1
    }; 
      
    const GetAniosScoreCard = `${config.UrlApiProject}ScoreCard/GetAniosScoreCard`; 
        
    callApi(GetAniosScoreCard, 'Post', paramsService, (response) => {
        setOpcionesAnios(response.data.Anios);  
        getMeses(ClaAnio); 
    });   
 }

 const getMeses = (ClaAnio) => {
    
  const paramsService = {
    ClaAnio, 
    MostrarSoloCerrados : 1
  }; 
    
  const GetMesesScoreCard = `${config.UrlApiProject}ScoreCard/GetMesesScoreCard`; 
      
  callApi(GetMesesScoreCard, 'Post', paramsService, (response) => {
      setOpcionesMeses(response.data.Meses);    
      setUltimoClaMesCerrado(getUltimoMesCerrado(response.data.Meses));
      
      console.log(ClaMes);

      if (ClaMes > getUltimoMesCerrado(response.data.Meses) || ClaMes === 0 || ClaMes === null){
        setClaMes(getUltimoMesCerrado(response.data.Meses)); 
      }
      else if (response.data.Meses.length > 0) { 
        if (response.data.Meses[0].ClaMes > ClaMes){ 
          setClaMes(response.data.Meses[0].ClaMes); 
        } 
      }

      if (response.data.Meses.length > 0){
          setPrimerClaMes(response.data.Meses[0].ClaMes); 
      } 
  });   
 }

  const onSearch = (filters) => {
    setfiltersData({...filters});   
  };

  
  const onAnioChange = ( value ) => {    
    setClaAnio(value); 
    getMeses(value);
    tracker.trackEvent({
      type: 'FocusReportScreen', /* component name */
      action: 'CHANGE_YEAR'
    }) 
  }; 

  const onClaMesChange = ( value ) => {    
    setClaMes(value);  
    tracker.trackEvent({
      type: 'FocusReportScreen', /* component name */
      action: 'CHANGE_MONTH'
    }) 
  }; 

  const getNomMes = (ClaMes) => {  
      for (let index = 0; index < OpcionesMeses.length; index++) {
        if (ClaMes === OpcionesMeses[index].ClaMes) { 
           return OpcionesMeses[index].NomMes;
        } 
    }

    return '';
  }

  const getUltimoMesCerrado = (meses) => {
    let ClaMes = 0;

    for (let index = 0; index < meses.length; index++) {
       ClaMes =  meses[index].ClaMes;
    }
 
    return ClaMes;
  }
  
  const onNextClaMesChange = (ClaMes) => { 
      setClaMes(ClaMes+1)
      tracker.trackEvent({
        type: 'FocusReportScreen', /* component name */
        action: 'CHANGE_MONTH'
      }) 
  }

  const onBeforeClaMesChange = (ClaMes) => {  
    if (ClaMes > PrimerClaMes) {
      setClaMes(ClaMes-1); 
    }

    tracker.trackEvent({
      type: 'FocusReportScreen', /* component name */
      action: 'CHANGE_MONTH'
    }) 
 }

  const panelMonthHeaderTemplate = (options) => { 
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
    const className = `${options.className} ${options.collapsed ? ' collapsed-header' : ''}`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}> 
        <span className={titleClassName}>
          <FieldDropdown 
            name="ClaAnio"
            value={ClaAnio} 
            className="border-none background-none title info-dark-color"
            options={OpcionesAnios} 
            onChange={(e) => onAnioChange(e.value)} 
            optionValue="ClaAnio"
            optionLabel="NomAnio" 
            placeholder={t('common:SelectYear')} 
            showClear={false}
          /> 
        </span>
        <div>
          <div style={{width:'200px', margin: 'auto'}}>
            <div className="p-inputgroup round white">
              {
                ClaMes === PrimerClaMes ? (<><FieldButton icon="pi pi-angle-left" className="white border-none bold" disabled onClick={(e) => { onBeforeClaMesChange(ClaMes)}} /></>)
                :
                (<><FieldButton icon="pi pi-angle-left" className="white border-none bold" onClick={(e) => { onBeforeClaMesChange(ClaMes)}} /></>)
            }
              <div style={{width:'100%'}} className="text-center">
                <span className='h2'> 
                  <FieldDropdown 
                    name="ClaMes"
                    value={ClaMes} 
                    className="border-none background-none title info-dark-color hide_dropdown_btn"
                    options={OpcionesMeses} 
                    onChange={(e) => onClaMesChange(e.value)} 
                    optionValue="ClaMes"
                    optionLabel="NomMes"  
                    showClear={false}
                  />  
                </span>
              </div>
              {
                ClaMes === UltimoClaMesCerrado ? (<><FieldButton icon="pi pi-angle-right" className="white border-none" disabled onClick={(e) => { onNextClaMesChange(ClaMes)}} /></>)
                : (<><FieldButton icon="pi pi-angle-right" className="white border-none" onClick={(e) => { onNextClaMesChange(ClaMes)}} /></>)
              } 
            </div>
          </div>
        </div>
        <div className="p-panel-icons">
          <FieldButton className={options.togglerClassName} onClick={options.onTogglerClick}>
            <span className={toggleIcon}></span> 
          </FieldButton>
        </div>
        
      </div>
    )
}

const panelAnnualHeaderTemplate = (options) => { 
  const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
  const className = `${options.className} ${options.collapsed ? ' collapsed-header' : ''} Cursor`;
  const titleClassName = `${options.titleClassName} p-pl-1`;

  return (
    <div className={className} onClick={options.onTogglerClick} title={t('focusReport:AnnualDetailsTooltip')}> 
      <span className={titleClassName}>
        {t('focusReport:OverallProgress')} {ClaAnio}
      </span>
      <div className="p-panel-icons">
        <FieldButton className={options.togglerClassName}>
          <span className={toggleIcon}></span> 
        </FieldButton>
      </div>
      
    </div>
  )
}

const organizationalView = (
  <div className='organization-default-width'>
    <OrganizationalPositionView showPositionName={true} onChange={(itemValue, itemNode) => onChangeOrganizationalValue(itemValue, itemNode)}></OrganizationalPositionView> 
  </div>
)

const onChangeOrganizationalValue = (itemValue, itemNode)=> { 
  // console.log(itemNode);
  setClaPuesto(itemNode.claPuesto);

  tracker.trackEvent({
    type: 'FocusReportScreen', /* component name */
    action: 'PICK_NEW_POSITION'
  }) 
}

  // no carga nada hasta que no se carguen los defaults....
  if (ClaPuesto === null || ClaMes === null || ClaAnio === null) {
    return (<></>);
  }

  return (
    <>     
      <PageContent title={t('focusReport:TitleFocusReport')} toolBar={organizationalView}>   
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>   
            <Panel headerTemplate={panelMonthHeaderTemplate} className="panel-skyblue" collapsed={false} toggleable>
              <Row className="mb-4">
                <Col lg={12} md={12} sm={12} xs={12} className="text-center">
                  
                </Col>
              </Row>
              <Row className="align-items-start">
                <Col lg={12} md={12} sm={12} xs={12}>  
                  <Row className="align-items-start ">
                    <Col lg={8} md={8} sm={12} xs={12}>  
                      <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <FocusReportMonthSummary ClaPuesto={ClaPuesto} ClaMes={ClaMes} ClaAnio={ClaAnio}></FocusReportMonthSummary> 
                        </Col>
                      </Row>    
                      <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <ScoreCardReport ClaPuesto={ClaPuesto} ClaMes={ClaMes} ClaAnio={ClaAnio} EsAdmin={EsAdmin}></ScoreCardReport> 
                        </Col>
                      </Row>          
                    </Col>
                    <Col lg={4} md={4} sm={12} xs={12}>  
                      <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <ActionPlanMonthSummary ClaPuesto={ClaPuesto} ClaMes={ClaMes} ClaAnio={ClaAnio}></ActionPlanMonthSummary>
                        </Col>
                      </Row>    
                      <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                          <ProjectActivitiesMonthSummary ClaPuesto={ClaPuesto} ClaMes={ClaMes} ClaAnio={ClaAnio}></ProjectActivitiesMonthSummary>
                        </Col>
                      </Row>          
                    </Col>
                  </Row>   
                </Col>  
              </Row> 
            </Panel> 
          </Col> 
        </Row> 
        <Row>
          <Col>
          &nbsp;
          </Col>
        </Row>
        
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>   
            <Panel headerTemplate={panelAnnualHeaderTemplate} className="panel-skyblue" collapsed={true} toggleable>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <ScoreCardAnnualDetail ClaPuesto={ClaPuesto} ClaAnio={ClaAnio}></ScoreCardAnnualDetail>
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
              &nbsp;
                </Col>
              </Row>   
              <Row className="align-items-start ">
                <Col lg={4} md={4} sm={12} xs={12}>
                  <ActionPlanAnnualSummary ClaPuesto={ClaPuesto} ClaAnio={ClaAnio}></ActionPlanAnnualSummary>
                </Col>
                <Col lg={4} md={4} sm={12} xs={12}>
                  <ProjectActivitiesAnnualSummary ClaPuesto={ClaPuesto} ClaAnio={ClaAnio}></ProjectActivitiesAnnualSummary>
                </Col>
                <Col lg={4} md={4} sm={12} xs={12}>
                  <FocusReportAnnualSummary ClaPuesto={ClaPuesto} ClaAnio={ClaAnio}></FocusReportAnnualSummary>
                </Col>
              </Row>
            </Panel> 
          </Col> 
        </Row>          
      </PageContent> 
    </>
  );
};
export default FocusReportScreen;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from 'utils/config';
import { callApi } from 'utils/utils';
import { Dialog } from 'primereact/dialog';
import { Label, Row, Col } from 'reactstrap';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import FieldAutoComplete from 'components/Controls/FieldAutoComplete';
import FieldTextArea from 'components/Controls/FieldTextArea';
import YupValidator from 'utils/YupValidator';
import { object, string, number } from 'yup';
import FieldText from 'components/Controls/FieldText';

const ITAssetsAssign = (props) => {
  const {t, i18n} = useTranslation(['it', 'common']);

  const [user, setUser] = useState(null); // User to assign
  const [users, setUsers] = useState([]); // List of users
  const [errors, setErrors] = useState([]); // Errors
  const [notes, setNotes] = useState(''); // Notes
  const [location, setLocation] = useState(null); // ClaUbicacion
  const [locations, setLocations] = useState(null);
  const [assignedTo, setAssignedTo] = useState(null); // Assigned to Description
  const [adpEmployee, setAdpEmployee] = useState(null);
  const [adpEmployees, setAdpEmployees] = useState([]);
  const {selectedAsset} = props;

  const onUserChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.user;
    setErrors(newErrors);
    setUser(e.value);
  }

  const filterUser = (e) => {
    let valor = ''; 
    if (user.NomUsuario !== null && user.NomUsuario !== '' &&  e.query === "")
    {
     valor = user.NomUsuario;
    }
    else{
     valor = e.query;
    }
    const paramsServiceOwners = {};
    // event.query 
    const urlWebServiceOwners = `${config.UrlApiProject}IT/GetUsers?valor=${valor}`;
    // Get owner
    callApi(urlWebServiceOwners, 'GET', paramsServiceOwners, (response) => {  
       setUsers(response.data.Users);
    });    
  }

  const filterAdpEmployee = (e) => {
    let valor = ''; 
    if (adpEmployee.FullName !== null && adpEmployee.FullName !== '' &&  e.query === "")
    {
     valor = adpEmployee.FullName;
    }
    else{
     valor = e.query;
    }
    const paramsServiceOwners = {};
    // event.query 
    const urlWebServiceOwners = `${config.UrlApiProject}HR/CmbADPEmployee?valor=${valor}`;
    // Get owner
    callApi(urlWebServiceOwners, 'GET', paramsServiceOwners, (response) => {  
       setAdpEmployees(response.data);
    });    
  }

  
  // Filter for location autocomplete
  const filterLocations = (e) => {
    let valor = ''; 
    if (location.NomUbicacion !== null && location.NomUbicacion !== '' &&  e.query === "")
    {
     valor = location.NomUbicacion;
    }
    else{
     valor = e.query;
    }
    const paramsServiceOwners = {};
    
    const urlWebServiceOwners = `${config.UrlApiProject}IT/GetUbicaciones?valor=${valor}`;

    callApi(urlWebServiceOwners, 'GET', paramsServiceOwners, (response) => {  
       setLocations(response.data.Locations);
    });    
  }

  // When the locations changes
  const onLocationChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.location;
    setErrors(newErrors);
    setLocation(e.value);
  }

  const onAdpEmployeeChange = (e) => {
    const newErrors = {...errors};
    delete newErrors.adpEmployee;
    setErrors(newErrors);
    setAdpEmployee(e.value);
  }

  // Handle model change
  const onChangeAssignedTo = (e) => {
    const newErrors = {...errors};
    delete newErrors.assignnedTo;
    setErrors(newErrors);
    setAssignedTo(e.target.value);
  }

  // Handle Notes change
  const onChangeNotes = (e) => { 
    const newErrors = { ...errors } 
    delete newErrors.notes;   
    setErrors(newErrors)  
    setNotes(e.target.value)
  }

  // Handle user assignment
  const onClickAssign = async () => {
    const validationScheme = object({
      location: object({ ClaUbicacion: number().integer().required('') }).required('').nullable(),
      assignedTo: string().required('').nullable(),
      adpEmployee: object({ ClaADPEmployee: number().integer() } ).nullable(),
      user: object({ ClaUsuario: number().integer() }).nullable(),
      notes: string().required('').nullable()
    });
    
    const value = {
      location,
      assignedTo,
      user,
      notes
    };

    const validator = new YupValidator(validationScheme);
    const isValid = await validator.validate(value);

    if (!isValid) {
      setErrors(validator.errors);
    }
    else {
      console.log(user, selectedAsset);

      const urlWebService = `${config.UrlApiProject}IT/AssignITAsset`;
      const paramsService = {
        IdITEquipo: selectedAsset.IdITEquipo,
        ClaUbicacion: location.ClaUbicacion,
        AssignedTo: assignedTo,
        ClaUsuarioAsignado: user !== undefined && user !== null ? user.ClaUsuario : null,
        Notas: notes,
        ClaADPEmployee: adpEmployee !== undefined && adpEmployee !== null ? adpEmployee.ClaADPEmployee : null
      };
      
      console.log(paramsService)

      callApi(urlWebService, 'POST', paramsService, (response) => {
        props.onHide();
      })
    }
  }

  return (
    <>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} className='p-field p-fluid'>
          <Label>{`${t('it:Location')} *`}</Label>
          <FieldAutoComplete
            dropdown
            id="location"
            name="location"
            value={location}
            onChange={onLocationChange}
            suggestions={locations}
            field="NomUbicacion"
            completeMethod={filterLocations}
            errors={errors}
          /> 
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} className='p-field p-fluid'>
          <Label>{`${t('it:Assigned To')} *`}</Label>
          <FieldText
            id='assignedTo'
            name='assignedTo'
            value={assignedTo}
            onChange={onChangeAssignedTo}
            errors={errors}
          >
          </FieldText>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} className='p-field p-fluid'>
          <Label>{t("it:EmployeeID")}</Label>
          <FieldAutoComplete
            dropdown
            id="adpEmployee"
            name="adpEmployee"
            value={adpEmployee}
            onChange={onAdpEmployeeChange}
            suggestions={adpEmployees}
            field="IdFullName"
            completeMethod={filterAdpEmployee}
            errors={errors}
          /> 
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} className='p-field p-fluid'>
          <Label>{t("common:User")}</Label>
          <FieldAutoComplete
            dropdown
            id="user"
            name="user"
            value={user}
            onChange={onUserChange}
            suggestions={users}
            field="NomUsuario"
            completeMethod={filterUser}
            errors={errors}
          /> 
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} className='p-field p-fluid'>
          <Label>{`${t("common:Notes")} *`}</Label>
          <FieldTextArea
            name="notes"
            rows={3}
            placeholder=''
            maxLength={200}
            value={notes}
            onChange={onChangeNotes}
            errors={errors}
          /> 
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} className='p-field p-d-flex p-jc-end'>
          <FieldButton
            icon='pi pi-user'
            label={t('it:Assign')}
            onClick={onClickAssign}
            className='p-button-warning'
          />
        </Col>
      </Row>
    </>
  )
}

export default ITAssetsAssign;
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'; 
import { useTranslation } from 'react-i18next'; 
import PageContent from '../../../layouts/PageContent';  
import { Row, Col} from 'reactstrap';  
import { Panel } from 'primereact/panel';
import queryString from 'query-string';
import FolderDetail from './FolderDetail';
import FolderList from './FolderList';

const FolderScreen = (props) => { 
  /* ################## useState area start ################## */
  const [EsListado, setEsListado] = useState(true); 
  const [EsNuevo, setEsNuevo] = useState(true); 
  const [ClaFolder, setClaFolder] = useState(null); 
  const [ClaFolderPadre, setClaFolderPadre] = useState(null); 
  const [EsShowDialog, setEsShowDialog] = useState(false); 
  const [refresh, setrefresh] = useState(0);
  const { t } = useTranslation(['dms', 'common']);

  useEffect(() => {   
  }, []); 

  const onAfterSelect = (data) => {  
    setClaFolder(data.ClaFolder);
    setEsNuevo(data.EsNuevo);
    setEsShowDialog(true);
  }

  const onAfterReturn = () => {  
    console.log('onAfterReturn Screen',props);
    setEsShowDialog(false);
    setClaFolder(0);
    setClaFolderPadre(0);
    setrefresh(refresh+1);
  }

  const onCloseDialog = () => { 
    setEsShowDialog(false);
  } 

    return (
      <>  
        <PageContent title={t('dms:Folder')}>  
          <Row>
            <Col>
              <FolderList onAfterSelect={onAfterSelect} refresh={refresh} ClaFolderPadre={ClaFolderPadre}></FolderList>
            </Col>
          </Row>
          { EsShowDialog ? <FolderDetail show={EsShowDialog} onHide={() => onCloseDialog()} onAfterReturn={onAfterReturn} EsNuevo={EsNuevo} ClaFolder={ClaFolder}></FolderDetail> : <></>}
        </PageContent>
      </>  
        ); 
};

export default FolderScreen;

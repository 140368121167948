/* eslint-disable no-const-assign */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { config } from 'utils/config';
import { callApi, showSweetAlert, showNotify } from 'utils/utils';
import FieldSelectButton from 'components/Controls/FieldSelectButton';
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import YupValidator from 'utils/YupValidator';
import * as yup from 'yup';
import { Dialog } from 'primereact/dialog';
import FieldDataTable from 'components/Controls/FieldDataTable/FieldDataTable';
import { Column } from 'primereact/column';
import FieldText from 'components/Controls/FieldText';
import FieldNumber from 'components/Controls/FieldNumber';
import moment from 'moment';
import FieldDataView from 'components/Controls/FieldDataView/FieldDataView';
import { KeyboardReturnOutlined } from '@material-ui/icons';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  ListGroupItem,
  ListGroup,
  Row,
  Col,
  FormGroup,
  Label,
} from 'reactstrap';
import { Badge } from 'primereact/badge';
import FieldDropdown from 'components/Controls/FieldDropdown';
import PageContent from 'layouts/PageContent';
import { set } from 'date-fns/esm';
import '../Header/HeaderTablet.css';
import HeaderTablet from '../Header/HeaderTablet';
import ScrapDetail from './ScrapDetail';
import PrincipalHeader from '../Header/PrincipalHeader';
import { Link } from 'react-router-dom';

const ScrapScreen = (props) => {
  const { t, i18n } = useTranslation(['tablets', 'common']);
  const [ClaveArticulo, setClaveArticulo] = useState(null);
  const [ClaArticulo, setClaArticulo] = useState(null);
  const [NomArticulo, setNomArticulo] = useState(null);
  const [Cajas, setCajas] = useState(null);
  const [ClaTurno, setClaTurno] = useState(null);
  const [NomTurno, setNomTurno] = useState(null);
  const [ClaAreaProduccion, setClaAreaProduccion] = useState(null);
  const [NomAreaProduccion, setNomAreaProduccion] = useState(null);
  const [NomCelda, setNomCelda] = useState(null);
  const [ClaTipoScrapCelda, setClaTipoScrapCelda] = useState(null);
  const [ClaRazonScrapCelda, setClaRazonScrapCelda] = useState(null);
  const [ScrapLbl, setScrapLbl] = useState(null);
  const [TipoScrapCeldaOptions, setTipoScrapCeldaOptions] = useState([]);
  const [RazonScrapCeldaOptions, setRazonScrapCeldaOptions] = useState([]);
  const [EsAbrirScrapDetail, setEsAbrirScrapDetail] = useState(false);
  const [ClaveCelda, setClaveCelda] = useState(null);
  const [errors, setErrors] = useState({});
  const homeScreenLink = useRef();

  // const IdCelda = props.IdCelda !== undefined ? props.IdCelda : null;
  let IdCelda = null

  if(props.location!==undefined){
    const params = queryString.parse(props.location.search);
    IdCelda = params !== undefined ? params.IdCelda : null;
  }

  useEffect(() => {
    OnloadScrapScreen(IdCelda);
    GetTipoScrapCeldaCmb();
    GetRazonScrapCeldaCmb();
  }, [props.IdCelda]);

  const OnloadScrapScreen = (pIdCelda) => {
    setClaveArticulo(null);
    const paramsService = {};
    const urlWebService = `${
      config.UrlApiProject
    }Operations/GetOperationOnloadScrapScreen?IdCelda=${pIdCelda}`;
    callApi(urlWebService, 'GET', paramsService, (response) => {
      const operationCheckInHead =
        response.data.OperationCheckInHead.length > 0 ? response.data.OperationCheckInHead[0] : {}; 
      setClaTurno(operationCheckInHead.ClaTurno);
      setNomTurno(operationCheckInHead.NomTurno);
      setClaAreaProduccion(operationCheckInHead.ClaAreaProduccion);
      setNomAreaProduccion(operationCheckInHead.NomAreaProduccion);
      setNomCelda(operationCheckInHead.NomCelda);
      setClaveCelda(operationCheckInHead.ClaveCelda);
    });
  };

  const GetTipoScrapCeldaCmb = () => {
    const paramsService = {};
    const urlWebService = `${config.UrlApiProject}Operations/GetTipoScrapCeldaCmb`;
    callApi(urlWebService, 'GET', paramsService, (response) => {
      setTipoScrapCeldaOptions(response.data.TipoScrapCelda);
    });
  };

  const GetRazonScrapCeldaCmb = () => {
    const paramsService = {};
    const urlWebService = `${config.UrlApiProject}Operations/GetRazonScrapCeldaCmb`;
    callApi(urlWebService, 'GET', paramsService, (response) => {
      setRazonScrapCeldaOptions(response.data.RazonScrapCelda);
    });
  };

  const onTipoScrapCeldaClick = (e) => {
    const newErrors = { ...errors };
    delete newErrors.ClaTipoScrapCelda;
    setErrors(newErrors);
    setClaTipoScrapCelda(e);
  };

  const onRazonScrapCeldaClick = (e)=>{
    const newErrors = { ...errors };
    delete newErrors.ClaRazonScrapCelda;
    setErrors(newErrors);
    setClaRazonScrapCelda(e);
  }

  const onScrapDetailClick = ()=> {
    setEsAbrirScrapDetail(true);
  }

  const onHideScrapDetail = () => {
    setEsAbrirScrapDetail(false);
  }
  const onChangeScrapLbl = (e)=>{
    const newErrors = { ...errors };
    delete newErrors.ScrapLbl;
    setScrapLbl(e.target.value !== null ? Math.floor(e.target.value) : null);
  }

  const subHeader = (  
    <HeaderTablet
      ClaTurno={ClaTurno}
      NomTurno={NomTurno}
      ClaAreaProduccion={ClaAreaProduccion}
      NomAreaProduccion={NomAreaProduccion}
      IdCelda={IdCelda}
      NomCelda={NomCelda}
      ClaveCelda={ClaveCelda}
    >
    </HeaderTablet> 
  )

  const onAddScrapClick = async () => {
    setErrors({});

    const value = {
      ClaTipoScrapCelda,
      ScrapLbl,
      ClaRazonScrapCelda
    };

    const validator = new YupValidator(valitationSchemeSave);
    const esValido = await validator.validate(value);
    if (!esValido) {
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      return;
    }

    const paramsService = {
      ClaCelda: IdCelda,
      ClaTipoScrapCelda,
      ScrapLbl,
      ClaRazonScrapCelda,
      ClaTurno,
      ClaAreaProduccion,
    };

    const urlWebService = `${config.UrlApiProject}Operations/SaveOperationsScrap`;
    callApi(urlWebService, 'POST', paramsService, (response) => { 
      showNotify(t('common:SaveSuccess'), 'centerTop', 'success'); 
      homeScreenLink.current.click();
      setErrors({});
    });  

  };

  const valitationSchemeSave = yup.object().shape({
    ClaTipoScrapCelda: yup.mixed().required(t('common:RequiredField')).nullable(),
    ScrapLbl: yup.number().required(t('common:RequiredField')).nullable(),    
    ClaRazonScrapCelda: yup.mixed().required(t('common:RequiredField')).nullable(),
  });

  return (
    <>
      <PrincipalHeader IsHomeScreen={false}></PrincipalHeader> 
      <PageContent title="Add Scrap" subheader={subHeader}>        
        <Row className='pb-2'>
          <Col lg={12} md={12} sm={12} xs={12}>
            <div className="p-fluid">
              <FieldButton
                label={t('tablets:ScrapDetail')}
                className="secondary no-border btntablet"
                onClick={onScrapDetailClick}
              />
            </div> 
          </Col>
        </Row>     
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Card>
              <CardBody>
                <Row className="align-items-start">
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <Row>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <span className="tablet-label">{t('tablets:ScrapType')}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <div className="p-fluid">
                          <FieldSelectButton
                            name="ClaTipoScrapCelda"
                            value={ClaTipoScrapCelda}
                            options={TipoScrapCeldaOptions}
                            className="buttonListSurveyTablet"
                            onChange={(e) => onTipoScrapCeldaClick(e.value)}
                            optionValue="ClaTipoScrapCelda"
                            optionLabel="NomTipoScrapCelda"
                            multiple={false}
                            errors={errors}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>&nbsp;</Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>  
            <Card>
              <CardBody>                
                <Row className="align-items-start">
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <Row>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <span className="tablet-label">{t('tablets:SCRAP')}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <div className="p-fluid">
                          <FieldNumber
                            name="ScrapLbl"
                            value={ScrapLbl}
                            onChange={onChangeScrapLbl}
                            min={-99999999}
                            max={99999999}
                            minFractionDigits={0}
                            maxFractionDigits={0}
                            mode="decimal"     
                            IsShowKeyboard={true}                        
                            title={t('tablets:SCRAP')}
                            errors={errors}
                          />
                        </div>
                      </Col>
                    </Row>                        
                  </Col>       
                </Row>                             
              </CardBody> 
            </Card>     
          </Col>       
        </Row>
        <Row>&nbsp;</Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Card>
              <CardBody>
                <Row className="align-items-start">
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <Row>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <span className="tablet-label">{t('tablets:Reason')}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <div className="p-fluid">
                          <FieldSelectButton
                            name="ClaRazonScrapCelda"
                            value={ClaRazonScrapCelda}
                            options={RazonScrapCeldaOptions}
                            onChange={(e) => onRazonScrapCeldaClick(e.value)}
                            optionValue="ClaRazonScrapCelda"
                            optionLabel="NomRazonScrapCelda"
                            className="buttonListSurveyTablet"
                            multiple={false}
                            errors={errors}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="text-center">
            <div className="p-fluid">
              <FieldButton
                label={t('tablets:AddScrap')}
                className="light-green no-border p-mr-2 btntablet"
                onClick={onAddScrapClick}
              />
            </div>
          </Col>
        </Row>
        <Link 
          ref={homeScreenLink} 
          to={
               { pathname: "CellHomeMenuScreen", search: `?IsDeviceAssignedOnCell=true` }
             }
        >
        </Link> 
      </PageContent>
      {
        EsAbrirScrapDetail ?
        (  
          <ScrapDetail show={EsAbrirScrapDetail} onHide={onHideScrapDetail} ClaCelda={IdCelda}></ScrapDetail>
        )
        :
        (<></>)
      }
    </>
  );
};

export default ScrapScreen;

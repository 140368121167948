/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'; 
import { useTranslation } from 'react-i18next';  
import TreeviewButton from '../../components/Controls/TreeViewButton/TreeviewButton.jsx';
import { config } from '../../utils/config';
import { callApi, getSessionItem } from '../../utils/utils';  


const OrganizationalView = (props) => { 
  /* ################## useState area start ################## */
  const { t } = useTranslation(['hr', 'common']);
     
  const [selectedUser, setSelectedUser] = useState(props.value !== undefined ? props.value : getSessionItem('ClaUsuarioMod', '')); 
  const [expandedKeys, setExpandedKeys] = useState({}); 
  const [nodes, setNodes] = useState([]);
  
  const expandAll = (nodes) => {
      const _expandedKeys = {};
      for (const node of nodes) {
          expandNode(node, _expandedKeys);
          break;
      }

      setExpandedKeys(_expandedKeys);
  }
 

  const expandNode = (node, _expandedKeys) => {
      if (node.children && node.children.length) {
          _expandedKeys[node.key] = true;

          /*
          for (const child of node.children) {
              expandNode(child, _expandedKeys);
          }
          */
      }
  }

  useEffect(() => {    

    const Get180DegreeFeedbackUser = `${config.UrlApiProject}hr/GetOrganizationalDataByUser`;  
    const paramsToService = {};   

    callApi(Get180DegreeFeedbackUser, 'GET', paramsToService, (response) => {  
      expandAll(response.data);
      setNodes(response.data);
    });


   
  }, []); 
 
    const nodeTemplate = (node, options) => { 
      
      return (
        <span className={options.className}>
          {node.label}
        </span>
      )
    }

    const onChange = (node) => { 
        setSelectedUser(node);
         
        if(props.onChange !== undefined && typeof(props.onChange) === 'function'){
            props.onChange(node);
        }
      }
    
      const onToggle = (event) => {
        setExpandedKeys(event.value);
      };
  

    return (
      <>     
        <TreeviewButton
          nodes={nodes}
          showAsTreeSelect={props.showAsTreeSelect}
          optionLabel="label"
          expandedKeys={expandedKeys}
          nodeTemplate={nodeTemplate}
          icon="pi pi-users"
          selectedKey={selectedUser}
          onChange={onChange} 
          onToggle={onToggle}
        >
        </TreeviewButton>                      
      </>  
        ); 
};

export default OrganizationalView;

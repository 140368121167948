/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React  from 'react';
import PropTypes from 'prop-types';
import "./ActionPlan.scss"; 
import { Badge } from 'primereact/badge'; 
import FieldButton from 'components/Controls/FieldButton/FieldButton';
import Highcharts from 'highcharts';
  

function ActionPlanScoreSquare ({ 
     title
    ,score 
    ,range
    ,severity
    ,small
  }) { 

    let severityClass = ''; 

    switch (severity) {
        case "blue":
            severityClass = "score-square-blue-gradient";
            break;
        case "green":
            severityClass = "score-square-green-gradient";
            break;
        case "yellow":
            severityClass = "score-square-yellow-gradient";
            break;
        case "red":
            severityClass = "score-square-red-gradient";
            break;        
        default:
            severityClass = "score-square-default-gradient";
            break;
    }
    
    if (small){
      return ( 
        <span className={`${severityClass} p-badge score-square-mini`}> 
          {score !== null && typeof(score) === 'number' ?  Highcharts.numberFormat(score, 1, '.', ',') : score}
        </span>
 );
    }

    return (  
      <>
        <div className="score-square-continer">  
          <div>
            <FieldButton label={score !== null && typeof(score) === 'number' ?  Highcharts.numberFormat(score, 1, '.', ',') : score} className={`${severityClass} no-border score-square font-weight-bold score-cursor-square`} style={{textAlign:'center', padding: '0px'}}> 
            </FieldButton> 
          </div> 
          <div className="text-center">
            <label className="score-square-title"><b>{title}</b></label> 
          </div>
        </div> 
      </> 
    )
}   

ActionPlanScoreSquare.propTypes = { 
    title: PropTypes.string,
    score: PropTypes.any,
    range: PropTypes.string,
    severity: PropTypes.oneOf(['green', 'yellow', 'red'])
}

export default ActionPlanScoreSquare; 
